import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../App";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

export function FishingAsWaterUse({datax=[]}){
    const { state, dispatch } = useContext(AuthContext);

    const [data, setData] = useState([]);

    const fetchData = () => {
        
        let filteredData = datax.filter((obj) => {
            if(obj.water_use === "Fishing"){
                return obj;
            }
        });

        setData(filteredData)
    }

    useEffect(() => {
        if(datax.length > 0){
            fetchData();
        }
    }, [])

    
    const customIcon = new L.Icon({
        iconUrl: "https://www.svgrepo.com/show/489677/fish.svg", // URL to your custom icon image
        iconSize: [32, 32], // Icon size (width, height)
    });

    return (
        data.map((item, idx) => {
            return (
                <Marker
                key={idx}
                position={[item.coordinates[1], item.coordinates[0]]}
                icon={customIcon}
                >
                <Popup>{item.name}</Popup>
                </Marker>
            )
        })
    )
}

export function GasExtractionAsWaterUse({datax=[]}){
    const { state, dispatch } = useContext(AuthContext);

    const [data, setData] = useState([]);

    const fetchData = () => {
        
        let filteredData = datax.filter((obj) => {
            if(obj.water_use === "Gas Extraction"){
                return obj;
            }
        });

        setData(filteredData)
    }

    useEffect(() => {
        if(datax.length > 0){
            fetchData();
        }
    }, [])

    
    const customIcon = new L.Icon({
        iconUrl: "https://www.svgrepo.com/show/530230/gas-station.svg", // URL to your custom icon image
        iconSize: [32, 32], // Icon size (width, height)
    });

    return (
        data.map((item, idx) => {
            return (
                <Marker
                key={idx}
                position={[item.coordinates[1], item.coordinates[0]]}
                icon={customIcon}
                >
                <Popup>{item.name}</Popup>
                </Marker>
            )
        })
    )
}

export function TourismAsWaterUse({datax=[]}){
    const { state, dispatch } = useContext(AuthContext);

    const [data, setData] = useState([]);

    const fetchData = () => {
        let filteredData = datax.filter((obj) => {
            if(obj.water_use === "Tourism/Recreation"){
                return obj;
            }
        });

        setData(filteredData)
    }

    useEffect(() => {
        if(datax.length > 0){
            fetchData();
        }
    }, [])
    
    const customIcon = new L.Icon({
        iconUrl: "https://www.svgrepo.com/show/484862/south-island.svg", // URL to your custom icon image
        iconSize: [32, 32], // Icon size (width, height)
    });

    return (
        data.map((item, idx) => {
            return (
                <Marker
                key={idx}
                position={[item.coordinates[1], item.coordinates[0]]}
                icon={customIcon}
                >
                <Popup>{item.name}</Popup>
                </Marker>
            )
        })
    )
}