import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  createStyles,
} from '@mantine/core';
import {  IconChevronRight } from '@tabler/icons-react';
import { useContext } from 'react';
import { Link } from "react-router-dom";
import { AuthContext } from '../../../App';

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor: "transparent"
    },
  },
}));

export function UserInfo({ image, name, email, icon, ...others }) {
  const { classes, theme } = useStyles();
  const { state, dispatch } = useContext(AuthContext);

  return (
    <Group position='center'>
    <UnstyledButton className={classes.user} {...others} component={Link} to={`/dashboard/settings`} >
      <Group noWrap spacing={3} sx={(theme) => ({ [theme.fn.smallerThan('lg')]: { display: 'none' } })} >
      <Avatar radius="xl" >{state.userData.name.slice(0, 2)}</Avatar>
      </Group>
      <Group sx={(theme) => ({ [theme.fn.largerThan('md')]: { display: 'none' } })} >
        <Avatar radius="xl" variant='filled' >{state.userData.name.slice(0, 2)}</Avatar>
      </Group>
    </UnstyledButton>
    </Group>
  );
}