const ruhondo_bathymetry = {
"type": "FeatureCollection",
"name": "ruhondo",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "fid": 1, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.477731630769231 ], [ 29.749522758620689, -1.478001107692308 ], [ 29.750061724137932, -1.478001107692308 ], [ 29.750061724137932, -1.477731630769231 ], [ 29.749522758620689, -1.477731630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.478001107692308 ], [ 29.748714310344827, -1.478270584615385 ], [ 29.749522758620689, -1.478270584615385 ], [ 29.749522758620689, -1.478001107692308 ], [ 29.748714310344827, -1.478001107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.478001107692308 ], [ 29.749522758620689, -1.478270584615385 ], [ 29.750061724137932, -1.478270584615385 ], [ 29.750061724137932, -1.478001107692308 ], [ 29.749522758620689, -1.478001107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.478001107692308 ], [ 29.750061724137932, -1.478270584615385 ], [ 29.750331206896551, -1.478270584615385 ], [ 29.750331206896551, -1.478001107692308 ], [ 29.750061724137932, -1.478001107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.478270584615385 ], [ 29.748175344827587, -1.478540061538461 ], [ 29.748714310344827, -1.478540061538461 ], [ 29.748714310344827, -1.478270584615385 ], [ 29.748175344827587, -1.478270584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.478270584615385 ], [ 29.748714310344827, -1.478540061538461 ], [ 29.748983793103449, -1.478540061538461 ], [ 29.748983793103449, -1.478270584615385 ], [ 29.748714310344827, -1.478270584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.478270584615385 ], [ 29.748983793103449, -1.478540061538461 ], [ 29.750061724137932, -1.478540061538461 ], [ 29.750061724137932, -1.478270584615385 ], [ 29.748983793103449, -1.478270584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.478270584615385 ], [ 29.750061724137932, -1.478540061538461 ], [ 29.750331206896551, -1.478540061538461 ], [ 29.750331206896551, -1.478270584615385 ], [ 29.750061724137932, -1.478270584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.478270584615385 ], [ 29.750331206896551, -1.478809538461539 ], [ 29.750600689655172, -1.478809538461539 ], [ 29.750600689655172, -1.478270584615385 ], [ 29.750331206896551, -1.478270584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.478540061538461 ], [ 29.747905862068965, -1.479079015384615 ], [ 29.748175344827587, -1.479079015384615 ], [ 29.748175344827587, -1.478540061538461 ], [ 29.747905862068965, -1.478540061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.478540061538461 ], [ 29.748175344827587, -1.478809538461539 ], [ 29.748444827586209, -1.478809538461539 ], [ 29.748444827586209, -1.478540061538461 ], [ 29.748175344827587, -1.478540061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.478540061538461 ], [ 29.748444827586209, -1.478809538461539 ], [ 29.748983793103449, -1.478809538461539 ], [ 29.748983793103449, -1.478540061538461 ], [ 29.748444827586209, -1.478540061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.478540061538461 ], [ 29.748983793103449, -1.478809538461539 ], [ 29.749792241379311, -1.478809538461539 ], [ 29.749792241379311, -1.479079015384615 ], [ 29.750061724137932, -1.479079015384615 ], [ 29.750061724137932, -1.478540061538461 ], [ 29.748983793103449, -1.478540061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.478540061538461 ], [ 29.750061724137932, -1.479079015384615 ], [ 29.750331206896551, -1.479079015384615 ], [ 29.750331206896551, -1.478540061538461 ], [ 29.750061724137932, -1.478540061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.478809538461539 ], [ 29.748983793103449, -1.479079015384615 ], [ 29.74925327586207, -1.479079015384615 ], [ 29.74925327586207, -1.478809538461539 ], [ 29.748983793103449, -1.478809538461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.478809538461539 ], [ 29.74925327586207, -1.479079015384615 ], [ 29.749522758620689, -1.479079015384615 ], [ 29.749522758620689, -1.478809538461539 ], [ 29.74925327586207, -1.478809538461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.478809538461539 ], [ 29.750331206896551, -1.479079015384615 ], [ 29.750600689655172, -1.479079015384615 ], [ 29.750600689655172, -1.478809538461539 ], [ 29.750331206896551, -1.478809538461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.478809538461539 ], [ 29.748175344827587, -1.479348492307692 ], [ 29.748444827586209, -1.479348492307692 ], [ 29.748444827586209, -1.478809538461539 ], [ 29.748175344827587, -1.478809538461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.478809538461539 ], [ 29.748444827586209, -1.479348492307692 ], [ 29.748714310344827, -1.479348492307692 ], [ 29.748714310344827, -1.479079015384615 ], [ 29.748983793103449, -1.479079015384615 ], [ 29.748983793103449, -1.478809538461539 ], [ 29.748444827586209, -1.478809538461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.478809538461539 ], [ 29.749522758620689, -1.479348492307692 ], [ 29.749792241379311, -1.479348492307692 ], [ 29.749792241379311, -1.478809538461539 ], [ 29.749522758620689, -1.478809538461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.478809538461539 ], [ 29.750600689655172, -1.4804264 ], [ 29.750870172413794, -1.4804264 ], [ 29.750870172413794, -1.478809538461539 ], [ 29.750600689655172, -1.478809538461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.479079015384615 ], [ 29.747366896551725, -1.479617969230769 ], [ 29.747905862068965, -1.479617969230769 ], [ 29.747905862068965, -1.479079015384615 ], [ 29.747366896551725, -1.479079015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.479079015384615 ], [ 29.747905862068965, -1.479348492307692 ], [ 29.748175344827587, -1.479348492307692 ], [ 29.748175344827587, -1.479079015384615 ], [ 29.747905862068965, -1.479079015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.479079015384615 ], [ 29.748714310344827, -1.479617969230769 ], [ 29.748983793103449, -1.479617969230769 ], [ 29.748983793103449, -1.479348492307692 ], [ 29.74925327586207, -1.479348492307692 ], [ 29.74925327586207, -1.479079015384615 ], [ 29.748714310344827, -1.479079015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.479079015384615 ], [ 29.74925327586207, -1.479348492307692 ], [ 29.749522758620689, -1.479348492307692 ], [ 29.749522758620689, -1.479079015384615 ], [ 29.74925327586207, -1.479079015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.479079015384615 ], [ 29.749792241379311, -1.479348492307692 ], [ 29.750061724137932, -1.479348492307692 ], [ 29.750061724137932, -1.479079015384615 ], [ 29.749792241379311, -1.479079015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.479079015384615 ], [ 29.750061724137932, -1.480965353846154 ], [ 29.750331206896551, -1.480965353846154 ], [ 29.750331206896551, -1.479079015384615 ], [ 29.750061724137932, -1.479079015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.479079015384615 ], [ 29.750331206896551, -1.480965353846154 ], [ 29.750600689655172, -1.480965353846154 ], [ 29.750600689655172, -1.479079015384615 ], [ 29.750331206896551, -1.479079015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.479348492307692 ], [ 29.741168793103448, -1.479617969230769 ], [ 29.74143827586207, -1.479617969230769 ], [ 29.74143827586207, -1.479348492307692 ], [ 29.741168793103448, -1.479348492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.479348492307692 ], [ 29.747905862068965, -1.479887446153846 ], [ 29.748175344827587, -1.479887446153846 ], [ 29.748175344827587, -1.479348492307692 ], [ 29.747905862068965, -1.479348492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.479348492307692 ], [ 29.748175344827587, -1.479887446153846 ], [ 29.748444827586209, -1.479887446153846 ], [ 29.748444827586209, -1.479348492307692 ], [ 29.748175344827587, -1.479348492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.479348492307692 ], [ 29.748444827586209, -1.479617969230769 ], [ 29.748714310344827, -1.479617969230769 ], [ 29.748714310344827, -1.479348492307692 ], [ 29.748444827586209, -1.479348492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.479348492307692 ], [ 29.748983793103449, -1.479617969230769 ], [ 29.74925327586207, -1.479617969230769 ], [ 29.74925327586207, -1.479348492307692 ], [ 29.748983793103449, -1.479348492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.479348492307692 ], [ 29.749522758620689, -1.480965353846154 ], [ 29.749792241379311, -1.480965353846154 ], [ 29.749792241379311, -1.479348492307692 ], [ 29.749522758620689, -1.479348492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 35, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.479348492307692 ], [ 29.749792241379311, -1.4804264 ], [ 29.750061724137932, -1.4804264 ], [ 29.750061724137932, -1.479348492307692 ], [ 29.749792241379311, -1.479348492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 36, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.479617969230769 ], [ 29.74089931034483, -1.479887446153846 ], [ 29.741168793103448, -1.479887446153846 ], [ 29.741168793103448, -1.479617969230769 ], [ 29.74089931034483, -1.479617969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 37, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.479617969230769 ], [ 29.741168793103448, -1.479887446153846 ], [ 29.74143827586207, -1.479887446153846 ], [ 29.74143827586207, -1.479617969230769 ], [ 29.741168793103448, -1.479617969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 38, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.479617969230769 ], [ 29.74143827586207, -1.479887446153846 ], [ 29.74197724137931, -1.479887446153846 ], [ 29.74197724137931, -1.479617969230769 ], [ 29.74143827586207, -1.479617969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 39, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.479617969230769 ], [ 29.747097413793103, -1.479887446153846 ], [ 29.747366896551725, -1.479887446153846 ], [ 29.747366896551725, -1.479617969230769 ], [ 29.747097413793103, -1.479617969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 40, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.479617969230769 ], [ 29.747366896551725, -1.479887446153846 ], [ 29.747905862068965, -1.479887446153846 ], [ 29.747905862068965, -1.479617969230769 ], [ 29.747366896551725, -1.479617969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 41, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.479617969230769 ], [ 29.748444827586209, -1.480156923076923 ], [ 29.748714310344827, -1.480156923076923 ], [ 29.748714310344827, -1.479617969230769 ], [ 29.748444827586209, -1.479617969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 42, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.479617969230769 ], [ 29.748714310344827, -1.479887446153846 ], [ 29.748983793103449, -1.479887446153846 ], [ 29.748983793103449, -1.479617969230769 ], [ 29.748714310344827, -1.479617969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 43, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.479348492307692 ], [ 29.749522758620689, -1.479348492307692 ], [ 29.749522758620689, -1.480965353846154 ], [ 29.74925327586207, -1.480965353846154 ], [ 29.74925327586207, -1.479887446153846 ], [ 29.748983793103449, -1.479887446153846 ], [ 29.748983793103449, -1.479617969230769 ], [ 29.74925327586207, -1.479617969230769 ], [ 29.74925327586207, -1.479348492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 44, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.479887446153846 ], [ 29.740629827586208, -1.481234830769231 ], [ 29.74089931034483, -1.481234830769231 ], [ 29.74089931034483, -1.479887446153846 ], [ 29.740629827586208, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 45, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.479887446153846 ], [ 29.74089931034483, -1.480156923076923 ], [ 29.741168793103448, -1.480156923076923 ], [ 29.741168793103448, -1.479887446153846 ], [ 29.74089931034483, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 46, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.479887446153846 ], [ 29.741168793103448, -1.480156923076923 ], [ 29.741707758620691, -1.480156923076923 ], [ 29.741707758620691, -1.479887446153846 ], [ 29.741168793103448, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 47, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.479887446153846 ], [ 29.741707758620691, -1.480156923076923 ], [ 29.74197724137931, -1.480156923076923 ], [ 29.74197724137931, -1.479887446153846 ], [ 29.741707758620691, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 48, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.479887446153846 ], [ 29.74197724137931, -1.480156923076923 ], [ 29.742516206896553, -1.480156923076923 ], [ 29.742516206896553, -1.479887446153846 ], [ 29.74197724137931, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 49, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.479887446153846 ], [ 29.746827931034485, -1.480156923076923 ], [ 29.747097413793103, -1.480156923076923 ], [ 29.747097413793103, -1.479887446153846 ], [ 29.746827931034485, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 50, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.479887446153846 ], [ 29.747097413793103, -1.480156923076923 ], [ 29.747366896551725, -1.480156923076923 ], [ 29.747366896551725, -1.479887446153846 ], [ 29.747097413793103, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 51, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.479887446153846 ], [ 29.747366896551725, -1.480156923076923 ], [ 29.747905862068965, -1.480156923076923 ], [ 29.747905862068965, -1.479887446153846 ], [ 29.747366896551725, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 52, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.479887446153846 ], [ 29.747905862068965, -1.480156923076923 ], [ 29.748175344827587, -1.480156923076923 ], [ 29.748175344827587, -1.479887446153846 ], [ 29.747905862068965, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 53, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.479887446153846 ], [ 29.748175344827587, -1.480156923076923 ], [ 29.748444827586209, -1.480156923076923 ], [ 29.748444827586209, -1.479887446153846 ], [ 29.748175344827587, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 54, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.479887446153846 ], [ 29.748714310344827, -1.480156923076923 ], [ 29.748983793103449, -1.480156923076923 ], [ 29.748983793103449, -1.479887446153846 ], [ 29.748714310344827, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 55, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.479887446153846 ], [ 29.748983793103449, -1.480156923076923 ], [ 29.74925327586207, -1.480156923076923 ], [ 29.74925327586207, -1.479887446153846 ], [ 29.748983793103449, -1.479887446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 56, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.480156923076923 ], [ 29.741707758620691, -1.4804264 ], [ 29.742246724137932, -1.4804264 ], [ 29.742246724137932, -1.480156923076923 ], [ 29.741707758620691, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 57, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.480156923076923 ], [ 29.742246724137932, -1.4804264 ], [ 29.742516206896553, -1.4804264 ], [ 29.742516206896553, -1.480156923076923 ], [ 29.742246724137932, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 58, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.480156923076923 ], [ 29.742516206896553, -1.4804264 ], [ 29.742785689655172, -1.4804264 ], [ 29.742785689655172, -1.480156923076923 ], [ 29.742516206896553, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 59, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.480156923076923 ], [ 29.746288965517241, -1.4804264 ], [ 29.746827931034485, -1.4804264 ], [ 29.746827931034485, -1.480156923076923 ], [ 29.746288965517241, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 60, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.480156923076923 ], [ 29.746827931034485, -1.4804264 ], [ 29.747097413793103, -1.4804264 ], [ 29.747097413793103, -1.480156923076923 ], [ 29.746827931034485, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 61, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.480156923076923 ], [ 29.747097413793103, -1.4804264 ], [ 29.747366896551725, -1.4804264 ], [ 29.747366896551725, -1.480156923076923 ], [ 29.747097413793103, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 62, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.480156923076923 ], [ 29.747366896551725, -1.4804264 ], [ 29.747905862068965, -1.4804264 ], [ 29.747905862068965, -1.480156923076923 ], [ 29.747366896551725, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 63, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.480156923076923 ], [ 29.747905862068965, -1.4804264 ], [ 29.748175344827587, -1.4804264 ], [ 29.748175344827587, -1.480156923076923 ], [ 29.747905862068965, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 64, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.480156923076923 ], [ 29.748175344827587, -1.4804264 ], [ 29.748444827586209, -1.4804264 ], [ 29.748444827586209, -1.480156923076923 ], [ 29.748175344827587, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 65, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.480156923076923 ], [ 29.748444827586209, -1.4804264 ], [ 29.748714310344827, -1.4804264 ], [ 29.748714310344827, -1.480156923076923 ], [ 29.748444827586209, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 66, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.480156923076923 ], [ 29.748714310344827, -1.4804264 ], [ 29.748983793103449, -1.4804264 ], [ 29.748983793103449, -1.480156923076923 ], [ 29.748714310344827, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 67, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.480156923076923 ], [ 29.748983793103449, -1.480965353846154 ], [ 29.74925327586207, -1.480965353846154 ], [ 29.74925327586207, -1.480156923076923 ], [ 29.748983793103449, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 68, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.4804264 ], [ 29.74143827586207, -1.480965353846154 ], [ 29.741707758620691, -1.480965353846154 ], [ 29.741707758620691, -1.4804264 ], [ 29.74143827586207, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 69, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.4804264 ], [ 29.741707758620691, -1.480965353846154 ], [ 29.742246724137932, -1.480965353846154 ], [ 29.742246724137932, -1.4804264 ], [ 29.741707758620691, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 70, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.4804264 ], [ 29.742246724137932, -1.480965353846154 ], [ 29.742516206896553, -1.480965353846154 ], [ 29.742516206896553, -1.4804264 ], [ 29.742246724137932, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 71, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.4804264 ], [ 29.742516206896553, -1.480965353846154 ], [ 29.742785689655172, -1.480965353846154 ], [ 29.742785689655172, -1.4804264 ], [ 29.742516206896553, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 72, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.4804264 ], [ 29.742785689655172, -1.480965353846154 ], [ 29.744402586206895, -1.480965353846154 ], [ 29.744402586206895, -1.4804264 ], [ 29.742785689655172, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 73, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.4804264 ], [ 29.74575, -1.480965353846154 ], [ 29.746288965517241, -1.480965353846154 ], [ 29.746288965517241, -1.4804264 ], [ 29.74575, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 74, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.4804264 ], [ 29.746288965517241, -1.480965353846154 ], [ 29.746558448275863, -1.480965353846154 ], [ 29.746558448275863, -1.4804264 ], [ 29.746288965517241, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 75, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.4804264 ], [ 29.746558448275863, -1.480965353846154 ], [ 29.747097413793103, -1.480965353846154 ], [ 29.747097413793103, -1.4804264 ], [ 29.746558448275863, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 76, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.4804264 ], [ 29.747097413793103, -1.480965353846154 ], [ 29.747366896551725, -1.480965353846154 ], [ 29.747366896551725, -1.4804264 ], [ 29.747097413793103, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 77, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.4804264 ], [ 29.747366896551725, -1.480965353846154 ], [ 29.747905862068965, -1.480965353846154 ], [ 29.747905862068965, -1.4804264 ], [ 29.747366896551725, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 78, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.4804264 ], [ 29.747905862068965, -1.480965353846154 ], [ 29.748175344827587, -1.480965353846154 ], [ 29.748175344827587, -1.4804264 ], [ 29.747905862068965, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 79, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.4804264 ], [ 29.748175344827587, -1.480965353846154 ], [ 29.748444827586209, -1.480965353846154 ], [ 29.748444827586209, -1.4804264 ], [ 29.748175344827587, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 80, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.4804264 ], [ 29.748444827586209, -1.480965353846154 ], [ 29.748714310344827, -1.480965353846154 ], [ 29.748714310344827, -1.4804264 ], [ 29.748444827586209, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 81, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.4804264 ], [ 29.748714310344827, -1.480965353846154 ], [ 29.748983793103449, -1.480965353846154 ], [ 29.748983793103449, -1.4804264 ], [ 29.748714310344827, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 82, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.4804264 ], [ 29.749792241379311, -1.481234830769231 ], [ 29.750061724137932, -1.481234830769231 ], [ 29.750061724137932, -1.4804264 ], [ 29.749792241379311, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 83, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.4804264 ], [ 29.750600689655172, -1.480965353846154 ], [ 29.750870172413794, -1.480965353846154 ], [ 29.750870172413794, -1.4804264 ], [ 29.750600689655172, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 84, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.4804264 ], [ 29.750870172413794, -1.480965353846154 ], [ 29.751409137931034, -1.480965353846154 ], [ 29.751409137931034, -1.4804264 ], [ 29.750870172413794, -1.4804264 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 85, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.480965353846154 ], [ 29.733353793103447, -1.481234830769231 ], [ 29.735509655172415, -1.481234830769231 ], [ 29.735509655172415, -1.480965353846154 ], [ 29.733353793103447, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 86, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.480965353846154 ], [ 29.74143827586207, -1.481234830769231 ], [ 29.741707758620691, -1.481234830769231 ], [ 29.741707758620691, -1.480965353846154 ], [ 29.74143827586207, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 87, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.480965353846154 ], [ 29.741707758620691, -1.481234830769231 ], [ 29.74197724137931, -1.481234830769231 ], [ 29.74197724137931, -1.480965353846154 ], [ 29.741707758620691, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 88, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.480965353846154 ], [ 29.74197724137931, -1.481234830769231 ], [ 29.742246724137932, -1.481234830769231 ], [ 29.742246724137932, -1.480965353846154 ], [ 29.74197724137931, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 89, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.480965353846154 ], [ 29.742246724137932, -1.481234830769231 ], [ 29.742516206896553, -1.481234830769231 ], [ 29.742516206896553, -1.480965353846154 ], [ 29.742246724137932, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 90, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.480965353846154 ], [ 29.742516206896553, -1.481234830769231 ], [ 29.744133103448277, -1.481234830769231 ], [ 29.744133103448277, -1.480965353846154 ], [ 29.742516206896553, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 91, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.480965353846154 ], [ 29.744133103448277, -1.481234830769231 ], [ 29.744402586206895, -1.481234830769231 ], [ 29.744402586206895, -1.480965353846154 ], [ 29.744133103448277, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 92, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.480965353846154 ], [ 29.744402586206895, -1.481234830769231 ], [ 29.74575, -1.481234830769231 ], [ 29.74575, -1.480965353846154 ], [ 29.744402586206895, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 93, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.480965353846154 ], [ 29.74575, -1.481234830769231 ], [ 29.746019482758619, -1.481234830769231 ], [ 29.746019482758619, -1.480965353846154 ], [ 29.74575, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 94, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.480965353846154 ], [ 29.746019482758619, -1.481234830769231 ], [ 29.746558448275863, -1.481234830769231 ], [ 29.746558448275863, -1.480965353846154 ], [ 29.746019482758619, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 95, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.480965353846154 ], [ 29.746558448275863, -1.481234830769231 ], [ 29.747097413793103, -1.481234830769231 ], [ 29.747097413793103, -1.480965353846154 ], [ 29.746558448275863, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 96, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.480965353846154 ], [ 29.747097413793103, -1.481234830769231 ], [ 29.747366896551725, -1.481234830769231 ], [ 29.747366896551725, -1.480965353846154 ], [ 29.747097413793103, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 97, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.480965353846154 ], [ 29.747366896551725, -1.481234830769231 ], [ 29.747905862068965, -1.481234830769231 ], [ 29.747905862068965, -1.480965353846154 ], [ 29.747366896551725, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 98, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.480965353846154 ], [ 29.747905862068965, -1.481234830769231 ], [ 29.748175344827587, -1.481234830769231 ], [ 29.748175344827587, -1.480965353846154 ], [ 29.747905862068965, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 99, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.480965353846154 ], [ 29.748175344827587, -1.481234830769231 ], [ 29.748444827586209, -1.481234830769231 ], [ 29.748444827586209, -1.480965353846154 ], [ 29.748175344827587, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 100, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.480965353846154 ], [ 29.748444827586209, -1.481234830769231 ], [ 29.748714310344827, -1.481234830769231 ], [ 29.748714310344827, -1.480965353846154 ], [ 29.748444827586209, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 101, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.480965353846154 ], [ 29.748714310344827, -1.481234830769231 ], [ 29.748983793103449, -1.481234830769231 ], [ 29.748983793103449, -1.480965353846154 ], [ 29.748714310344827, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 102, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.480965353846154 ], [ 29.748983793103449, -1.481234830769231 ], [ 29.74925327586207, -1.481234830769231 ], [ 29.74925327586207, -1.480965353846154 ], [ 29.748983793103449, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 103, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.480965353846154 ], [ 29.74925327586207, -1.481234830769231 ], [ 29.749522758620689, -1.481234830769231 ], [ 29.749522758620689, -1.480965353846154 ], [ 29.74925327586207, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 104, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.480965353846154 ], [ 29.749522758620689, -1.481234830769231 ], [ 29.749792241379311, -1.481234830769231 ], [ 29.749792241379311, -1.480965353846154 ], [ 29.749522758620689, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 105, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.480965353846154 ], [ 29.750061724137932, -1.481234830769231 ], [ 29.750331206896551, -1.481234830769231 ], [ 29.750331206896551, -1.480965353846154 ], [ 29.750061724137932, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 106, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.480965353846154 ], [ 29.750331206896551, -1.481234830769231 ], [ 29.750600689655172, -1.481234830769231 ], [ 29.750600689655172, -1.480965353846154 ], [ 29.750331206896551, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 107, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.480965353846154 ], [ 29.750600689655172, -1.481234830769231 ], [ 29.751139655172413, -1.481234830769231 ], [ 29.751139655172413, -1.480965353846154 ], [ 29.750600689655172, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 108, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.480965353846154 ], [ 29.751139655172413, -1.481234830769231 ], [ 29.751409137931034, -1.481234830769231 ], [ 29.751409137931034, -1.480965353846154 ], [ 29.751139655172413, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 109, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.480965353846154 ], [ 29.751409137931034, -1.481234830769231 ], [ 29.751678620689656, -1.481234830769231 ], [ 29.751678620689656, -1.480965353846154 ], [ 29.751409137931034, -1.480965353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 110, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.480156923076923 ], [ 29.74089931034483, -1.481504307692308 ], [ 29.741168793103448, -1.481504307692308 ], [ 29.741168793103448, -1.480156923076923 ], [ 29.74089931034483, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 111, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.480156923076923 ], [ 29.741168793103448, -1.481504307692308 ], [ 29.74143827586207, -1.481504307692308 ], [ 29.74143827586207, -1.4804264 ], [ 29.741707758620691, -1.4804264 ], [ 29.741707758620691, -1.480156923076923 ], [ 29.741168793103448, -1.480156923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 112, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.481234830769231 ], [ 29.732814827586207, -1.481504307692308 ], [ 29.733353793103447, -1.481504307692308 ], [ 29.733353793103447, -1.481234830769231 ], [ 29.732814827586207, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 113, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.481234830769231 ], [ 29.733353793103447, -1.481504307692308 ], [ 29.733623275862069, -1.481504307692308 ], [ 29.733623275862069, -1.481234830769231 ], [ 29.733353793103447, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 114, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.481234830769231 ], [ 29.733623275862069, -1.481504307692308 ], [ 29.735240172413793, -1.481504307692308 ], [ 29.735240172413793, -1.481234830769231 ], [ 29.733623275862069, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 115, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.481234830769231 ], [ 29.735240172413793, -1.481504307692308 ], [ 29.735509655172415, -1.481504307692308 ], [ 29.735509655172415, -1.481234830769231 ], [ 29.735240172413793, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 116, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.481234830769231 ], [ 29.735509655172415, -1.481504307692308 ], [ 29.736587586206898, -1.481504307692308 ], [ 29.736587586206898, -1.481234830769231 ], [ 29.735509655172415, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 117, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.481234830769231 ], [ 29.739012931034484, -1.481504307692308 ], [ 29.740629827586208, -1.481504307692308 ], [ 29.740629827586208, -1.481234830769231 ], [ 29.739012931034484, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 118, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.481234830769231 ], [ 29.740629827586208, -1.481504307692308 ], [ 29.74089931034483, -1.481504307692308 ], [ 29.74089931034483, -1.481234830769231 ], [ 29.740629827586208, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 119, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.481234830769231 ], [ 29.74143827586207, -1.481773784615385 ], [ 29.741707758620691, -1.481773784615385 ], [ 29.741707758620691, -1.481234830769231 ], [ 29.74143827586207, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 120, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.481234830769231 ], [ 29.741707758620691, -1.481504307692308 ], [ 29.74197724137931, -1.481504307692308 ], [ 29.74197724137931, -1.481234830769231 ], [ 29.741707758620691, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 121, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.481234830769231 ], [ 29.74197724137931, -1.481504307692308 ], [ 29.742246724137932, -1.481504307692308 ], [ 29.742246724137932, -1.481234830769231 ], [ 29.74197724137931, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 122, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.481234830769231 ], [ 29.742246724137932, -1.481504307692308 ], [ 29.742516206896553, -1.481504307692308 ], [ 29.742516206896553, -1.481234830769231 ], [ 29.742246724137932, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 123, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.481234830769231 ], [ 29.742516206896553, -1.481504307692308 ], [ 29.744133103448277, -1.481504307692308 ], [ 29.744133103448277, -1.481234830769231 ], [ 29.742516206896553, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 124, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.481234830769231 ], [ 29.744133103448277, -1.481504307692308 ], [ 29.746019482758619, -1.481504307692308 ], [ 29.746019482758619, -1.481234830769231 ], [ 29.744133103448277, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 125, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.481234830769231 ], [ 29.746019482758619, -1.481504307692308 ], [ 29.746558448275863, -1.481504307692308 ], [ 29.746558448275863, -1.481234830769231 ], [ 29.746019482758619, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 126, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.481234830769231 ], [ 29.746558448275863, -1.481504307692308 ], [ 29.746827931034485, -1.481504307692308 ], [ 29.746827931034485, -1.481234830769231 ], [ 29.746558448275863, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 127, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.481234830769231 ], [ 29.746827931034485, -1.481504307692308 ], [ 29.747366896551725, -1.481504307692308 ], [ 29.747366896551725, -1.481234830769231 ], [ 29.746827931034485, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 128, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.481234830769231 ], [ 29.747366896551725, -1.481504307692308 ], [ 29.747905862068965, -1.481504307692308 ], [ 29.747905862068965, -1.481234830769231 ], [ 29.747366896551725, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 129, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.481234830769231 ], [ 29.747905862068965, -1.481504307692308 ], [ 29.748175344827587, -1.481504307692308 ], [ 29.748175344827587, -1.481234830769231 ], [ 29.747905862068965, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 130, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.481234830769231 ], [ 29.748175344827587, -1.481504307692308 ], [ 29.748444827586209, -1.481504307692308 ], [ 29.748444827586209, -1.481234830769231 ], [ 29.748175344827587, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 131, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.481234830769231 ], [ 29.748444827586209, -1.481504307692308 ], [ 29.748714310344827, -1.481504307692308 ], [ 29.748714310344827, -1.481234830769231 ], [ 29.748444827586209, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 132, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.481234830769231 ], [ 29.748714310344827, -1.481504307692308 ], [ 29.74925327586207, -1.481504307692308 ], [ 29.74925327586207, -1.481234830769231 ], [ 29.748714310344827, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 133, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.481234830769231 ], [ 29.74925327586207, -1.481504307692308 ], [ 29.749522758620689, -1.481504307692308 ], [ 29.749522758620689, -1.481234830769231 ], [ 29.74925327586207, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 134, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.481234830769231 ], [ 29.749522758620689, -1.481504307692308 ], [ 29.749792241379311, -1.481504307692308 ], [ 29.749792241379311, -1.481234830769231 ], [ 29.749522758620689, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 135, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.481234830769231 ], [ 29.749792241379311, -1.481504307692308 ], [ 29.750061724137932, -1.481504307692308 ], [ 29.750061724137932, -1.481234830769231 ], [ 29.749792241379311, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 136, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.481234830769231 ], [ 29.750061724137932, -1.481504307692308 ], [ 29.750331206896551, -1.481504307692308 ], [ 29.750331206896551, -1.481234830769231 ], [ 29.750061724137932, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 137, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.481234830769231 ], [ 29.750331206896551, -1.481504307692308 ], [ 29.750600689655172, -1.481504307692308 ], [ 29.750600689655172, -1.481234830769231 ], [ 29.750331206896551, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 138, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.481234830769231 ], [ 29.750600689655172, -1.481504307692308 ], [ 29.751139655172413, -1.481504307692308 ], [ 29.751139655172413, -1.481234830769231 ], [ 29.750600689655172, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 139, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.481234830769231 ], [ 29.751139655172413, -1.481504307692308 ], [ 29.751409137931034, -1.481504307692308 ], [ 29.751409137931034, -1.481234830769231 ], [ 29.751139655172413, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 140, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.481234830769231 ], [ 29.751409137931034, -1.481504307692308 ], [ 29.751678620689656, -1.481504307692308 ], [ 29.751678620689656, -1.481234830769231 ], [ 29.751409137931034, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 141, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.481234830769231 ], [ 29.751678620689656, -1.481773784615385 ], [ 29.751948103448278, -1.481773784615385 ], [ 29.751948103448278, -1.481234830769231 ], [ 29.751678620689656, -1.481234830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 142, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.481504307692308 ], [ 29.732545344827585, -1.481773784615385 ], [ 29.732814827586207, -1.481773784615385 ], [ 29.732814827586207, -1.481504307692308 ], [ 29.732545344827585, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 143, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.481504307692308 ], [ 29.732814827586207, -1.481773784615385 ], [ 29.733084310344829, -1.481773784615385 ], [ 29.733084310344829, -1.481504307692308 ], [ 29.732814827586207, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 144, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.481504307692308 ], [ 29.733084310344829, -1.481773784615385 ], [ 29.733623275862069, -1.481773784615385 ], [ 29.733623275862069, -1.481504307692308 ], [ 29.733084310344829, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 145, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.481504307692308 ], [ 29.733623275862069, -1.481773784615385 ], [ 29.735240172413793, -1.481773784615385 ], [ 29.735240172413793, -1.481504307692308 ], [ 29.733623275862069, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 146, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.481504307692308 ], [ 29.735240172413793, -1.481773784615385 ], [ 29.736318103448276, -1.481773784615385 ], [ 29.736318103448276, -1.481504307692308 ], [ 29.735240172413793, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 147, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.481504307692308 ], [ 29.736318103448276, -1.481773784615385 ], [ 29.736587586206898, -1.481773784615385 ], [ 29.736587586206898, -1.481504307692308 ], [ 29.736318103448276, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 148, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.481504307692308 ], [ 29.736587586206898, -1.481773784615385 ], [ 29.739012931034484, -1.481773784615385 ], [ 29.739012931034484, -1.481504307692308 ], [ 29.736587586206898, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 149, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.481504307692308 ], [ 29.739012931034484, -1.481773784615385 ], [ 29.739282413793102, -1.481773784615385 ], [ 29.739282413793102, -1.481504307692308 ], [ 29.739012931034484, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 150, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.481504307692308 ], [ 29.739282413793102, -1.481773784615385 ], [ 29.74089931034483, -1.481773784615385 ], [ 29.74089931034483, -1.481504307692308 ], [ 29.739282413793102, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 151, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.481504307692308 ], [ 29.74089931034483, -1.481773784615385 ], [ 29.741168793103448, -1.481773784615385 ], [ 29.741168793103448, -1.481504307692308 ], [ 29.74089931034483, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 152, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.481504307692308 ], [ 29.741168793103448, -1.481773784615385 ], [ 29.74143827586207, -1.481773784615385 ], [ 29.74143827586207, -1.481504307692308 ], [ 29.741168793103448, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 153, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.481504307692308 ], [ 29.741707758620691, -1.481773784615385 ], [ 29.74197724137931, -1.481773784615385 ], [ 29.74197724137931, -1.481504307692308 ], [ 29.741707758620691, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 154, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.481504307692308 ], [ 29.74197724137931, -1.481773784615385 ], [ 29.742246724137932, -1.481773784615385 ], [ 29.742246724137932, -1.481504307692308 ], [ 29.74197724137931, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 155, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.481504307692308 ], [ 29.742246724137932, -1.481773784615385 ], [ 29.742785689655172, -1.481773784615385 ], [ 29.742785689655172, -1.481504307692308 ], [ 29.742246724137932, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 156, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.481504307692308 ], [ 29.742785689655172, -1.481773784615385 ], [ 29.743863620689655, -1.481773784615385 ], [ 29.743863620689655, -1.481504307692308 ], [ 29.742785689655172, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 157, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.481504307692308 ], [ 29.743863620689655, -1.481773784615385 ], [ 29.744133103448277, -1.481773784615385 ], [ 29.744133103448277, -1.481504307692308 ], [ 29.743863620689655, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 158, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.481504307692308 ], [ 29.744133103448277, -1.481773784615385 ], [ 29.746019482758619, -1.481773784615385 ], [ 29.746019482758619, -1.481504307692308 ], [ 29.744133103448277, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 159, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.481504307692308 ], [ 29.746019482758619, -1.481773784615385 ], [ 29.746288965517241, -1.481773784615385 ], [ 29.746288965517241, -1.481504307692308 ], [ 29.746019482758619, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 160, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.481504307692308 ], [ 29.746288965517241, -1.481773784615385 ], [ 29.746827931034485, -1.481773784615385 ], [ 29.746827931034485, -1.481504307692308 ], [ 29.746288965517241, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 161, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.481504307692308 ], [ 29.746827931034485, -1.481773784615385 ], [ 29.747097413793103, -1.481773784615385 ], [ 29.747097413793103, -1.481504307692308 ], [ 29.746827931034485, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 162, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.481504307692308 ], [ 29.747097413793103, -1.481773784615385 ], [ 29.747366896551725, -1.481773784615385 ], [ 29.747366896551725, -1.481504307692308 ], [ 29.747097413793103, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 163, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.481504307692308 ], [ 29.747366896551725, -1.481773784615385 ], [ 29.747905862068965, -1.481773784615385 ], [ 29.747905862068965, -1.481504307692308 ], [ 29.747366896551725, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 164, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.481504307692308 ], [ 29.747905862068965, -1.481773784615385 ], [ 29.748175344827587, -1.481773784615385 ], [ 29.748175344827587, -1.481504307692308 ], [ 29.747905862068965, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 165, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.481504307692308 ], [ 29.748175344827587, -1.481773784615385 ], [ 29.748444827586209, -1.481773784615385 ], [ 29.748444827586209, -1.481504307692308 ], [ 29.748175344827587, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 166, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.481504307692308 ], [ 29.748444827586209, -1.481773784615385 ], [ 29.748714310344827, -1.481773784615385 ], [ 29.748714310344827, -1.481504307692308 ], [ 29.748444827586209, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 167, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.481504307692308 ], [ 29.748714310344827, -1.481773784615385 ], [ 29.748983793103449, -1.481773784615385 ], [ 29.748983793103449, -1.481504307692308 ], [ 29.748714310344827, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 168, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.481504307692308 ], [ 29.748983793103449, -1.481773784615385 ], [ 29.74925327586207, -1.481773784615385 ], [ 29.74925327586207, -1.481504307692308 ], [ 29.748983793103449, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 169, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.481504307692308 ], [ 29.74925327586207, -1.481773784615385 ], [ 29.749522758620689, -1.481773784615385 ], [ 29.749522758620689, -1.481504307692308 ], [ 29.74925327586207, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 170, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.481504307692308 ], [ 29.749522758620689, -1.481773784615385 ], [ 29.749792241379311, -1.481773784615385 ], [ 29.749792241379311, -1.481504307692308 ], [ 29.749522758620689, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 171, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.481504307692308 ], [ 29.749792241379311, -1.481773784615385 ], [ 29.750061724137932, -1.481773784615385 ], [ 29.750061724137932, -1.481504307692308 ], [ 29.749792241379311, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 172, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.481504307692308 ], [ 29.750061724137932, -1.481773784615385 ], [ 29.750331206896551, -1.481773784615385 ], [ 29.750331206896551, -1.481504307692308 ], [ 29.750061724137932, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 173, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.481504307692308 ], [ 29.750331206896551, -1.481773784615385 ], [ 29.750870172413794, -1.481773784615385 ], [ 29.750870172413794, -1.481504307692308 ], [ 29.750331206896551, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 174, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.481504307692308 ], [ 29.750870172413794, -1.481773784615385 ], [ 29.751139655172413, -1.481773784615385 ], [ 29.751139655172413, -1.481504307692308 ], [ 29.750870172413794, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 175, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.481504307692308 ], [ 29.751139655172413, -1.482043261538462 ], [ 29.751409137931034, -1.482043261538462 ], [ 29.751409137931034, -1.481504307692308 ], [ 29.751139655172413, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 176, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.481504307692308 ], [ 29.751409137931034, -1.482043261538462 ], [ 29.751678620689656, -1.482043261538462 ], [ 29.751678620689656, -1.481504307692308 ], [ 29.751409137931034, -1.481504307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 177, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.481773784615385 ], [ 29.732275862068967, -1.482312738461538 ], [ 29.732545344827585, -1.482312738461538 ], [ 29.732545344827585, -1.481773784615385 ], [ 29.732275862068967, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 178, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.481773784615385 ], [ 29.732545344827585, -1.482043261538462 ], [ 29.732814827586207, -1.482043261538462 ], [ 29.732814827586207, -1.481773784615385 ], [ 29.732545344827585, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 179, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.481773784615385 ], [ 29.732814827586207, -1.482043261538462 ], [ 29.733084310344829, -1.482043261538462 ], [ 29.733084310344829, -1.481773784615385 ], [ 29.732814827586207, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 180, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.481773784615385 ], [ 29.733084310344829, -1.482043261538462 ], [ 29.733623275862069, -1.482043261538462 ], [ 29.733623275862069, -1.481773784615385 ], [ 29.733084310344829, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 181, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.481773784615385 ], [ 29.733623275862069, -1.482043261538462 ], [ 29.733892758620691, -1.482043261538462 ], [ 29.733892758620691, -1.481773784615385 ], [ 29.733623275862069, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 182, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.481773784615385 ], [ 29.733892758620691, -1.482043261538462 ], [ 29.734970689655171, -1.482043261538462 ], [ 29.734970689655171, -1.481773784615385 ], [ 29.733892758620691, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 183, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.481773784615385 ], [ 29.734970689655171, -1.482043261538462 ], [ 29.735240172413793, -1.482043261538462 ], [ 29.735240172413793, -1.481773784615385 ], [ 29.734970689655171, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 184, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.481773784615385 ], [ 29.735240172413793, -1.482043261538462 ], [ 29.736318103448276, -1.482043261538462 ], [ 29.736318103448276, -1.481773784615385 ], [ 29.735240172413793, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 185, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.481773784615385 ], [ 29.736318103448276, -1.482043261538462 ], [ 29.739282413793102, -1.482043261538462 ], [ 29.739282413793102, -1.481773784615385 ], [ 29.736318103448276, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 186, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.481773784615385 ], [ 29.739282413793102, -1.482043261538462 ], [ 29.74089931034483, -1.482043261538462 ], [ 29.74089931034483, -1.481773784615385 ], [ 29.739282413793102, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 187, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.481773784615385 ], [ 29.74089931034483, -1.482043261538462 ], [ 29.741168793103448, -1.482043261538462 ], [ 29.741168793103448, -1.481773784615385 ], [ 29.74089931034483, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 188, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.481773784615385 ], [ 29.741168793103448, -1.482043261538462 ], [ 29.74143827586207, -1.482043261538462 ], [ 29.74143827586207, -1.481773784615385 ], [ 29.741168793103448, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 189, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.481773784615385 ], [ 29.74143827586207, -1.482043261538462 ], [ 29.741707758620691, -1.482043261538462 ], [ 29.741707758620691, -1.481773784615385 ], [ 29.74143827586207, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 190, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.481773784615385 ], [ 29.741707758620691, -1.482043261538462 ], [ 29.742246724137932, -1.482043261538462 ], [ 29.742246724137932, -1.481773784615385 ], [ 29.741707758620691, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 191, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.481773784615385 ], [ 29.742246724137932, -1.482043261538462 ], [ 29.742516206896553, -1.482043261538462 ], [ 29.742516206896553, -1.481773784615385 ], [ 29.742246724137932, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 192, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.481773784615385 ], [ 29.742516206896553, -1.482043261538462 ], [ 29.743863620689655, -1.482043261538462 ], [ 29.743863620689655, -1.481773784615385 ], [ 29.742516206896553, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 193, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.481773784615385 ], [ 29.743863620689655, -1.482043261538462 ], [ 29.744402586206895, -1.482043261538462 ], [ 29.744402586206895, -1.481773784615385 ], [ 29.743863620689655, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 194, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.481773784615385 ], [ 29.744402586206895, -1.482043261538462 ], [ 29.74575, -1.482043261538462 ], [ 29.74575, -1.481773784615385 ], [ 29.744402586206895, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 195, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.481773784615385 ], [ 29.74575, -1.482043261538462 ], [ 29.746288965517241, -1.482043261538462 ], [ 29.746288965517241, -1.481773784615385 ], [ 29.74575, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 196, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.481773784615385 ], [ 29.746288965517241, -1.482043261538462 ], [ 29.746558448275863, -1.482043261538462 ], [ 29.746558448275863, -1.481773784615385 ], [ 29.746288965517241, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 197, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.481773784615385 ], [ 29.746558448275863, -1.482043261538462 ], [ 29.746827931034485, -1.482043261538462 ], [ 29.746827931034485, -1.481773784615385 ], [ 29.746558448275863, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 198, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.481773784615385 ], [ 29.746827931034485, -1.482043261538462 ], [ 29.747097413793103, -1.482043261538462 ], [ 29.747097413793103, -1.481773784615385 ], [ 29.746827931034485, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 199, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.481773784615385 ], [ 29.747097413793103, -1.482043261538462 ], [ 29.747366896551725, -1.482043261538462 ], [ 29.747366896551725, -1.481773784615385 ], [ 29.747097413793103, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 200, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.481773784615385 ], [ 29.747366896551725, -1.482043261538462 ], [ 29.747905862068965, -1.482043261538462 ], [ 29.747905862068965, -1.481773784615385 ], [ 29.747366896551725, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 201, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.481773784615385 ], [ 29.747905862068965, -1.482043261538462 ], [ 29.748175344827587, -1.482043261538462 ], [ 29.748175344827587, -1.481773784615385 ], [ 29.747905862068965, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 202, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.481773784615385 ], [ 29.748175344827587, -1.482043261538462 ], [ 29.748444827586209, -1.482043261538462 ], [ 29.748444827586209, -1.481773784615385 ], [ 29.748175344827587, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 203, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.481773784615385 ], [ 29.748444827586209, -1.482043261538462 ], [ 29.748714310344827, -1.482043261538462 ], [ 29.748714310344827, -1.481773784615385 ], [ 29.748444827586209, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 204, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.481773784615385 ], [ 29.748714310344827, -1.482043261538462 ], [ 29.748983793103449, -1.482043261538462 ], [ 29.748983793103449, -1.481773784615385 ], [ 29.748714310344827, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 205, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.481773784615385 ], [ 29.748983793103449, -1.482043261538462 ], [ 29.74925327586207, -1.482043261538462 ], [ 29.74925327586207, -1.481773784615385 ], [ 29.748983793103449, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 206, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.481773784615385 ], [ 29.74925327586207, -1.482043261538462 ], [ 29.749522758620689, -1.482043261538462 ], [ 29.749522758620689, -1.481773784615385 ], [ 29.74925327586207, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 207, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.481773784615385 ], [ 29.749522758620689, -1.482043261538462 ], [ 29.749792241379311, -1.482043261538462 ], [ 29.749792241379311, -1.481773784615385 ], [ 29.749522758620689, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 208, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.481773784615385 ], [ 29.749792241379311, -1.482043261538462 ], [ 29.750061724137932, -1.482043261538462 ], [ 29.750061724137932, -1.481773784615385 ], [ 29.749792241379311, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 209, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.481773784615385 ], [ 29.750061724137932, -1.482043261538462 ], [ 29.750331206896551, -1.482043261538462 ], [ 29.750331206896551, -1.481773784615385 ], [ 29.750061724137932, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 210, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.481773784615385 ], [ 29.750331206896551, -1.482043261538462 ], [ 29.750600689655172, -1.482043261538462 ], [ 29.750600689655172, -1.481773784615385 ], [ 29.750331206896551, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 211, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.481773784615385 ], [ 29.750600689655172, -1.482043261538462 ], [ 29.750870172413794, -1.482043261538462 ], [ 29.750870172413794, -1.481773784615385 ], [ 29.750600689655172, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 212, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.481773784615385 ], [ 29.750870172413794, -1.482312738461538 ], [ 29.751139655172413, -1.482312738461538 ], [ 29.751139655172413, -1.481773784615385 ], [ 29.750870172413794, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 213, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.481773784615385 ], [ 29.751678620689656, -1.482043261538462 ], [ 29.751948103448278, -1.482043261538462 ], [ 29.751948103448278, -1.481773784615385 ], [ 29.751678620689656, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 214, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.481773784615385 ], [ 29.751948103448278, -1.482312738461538 ], [ 29.752217586206896, -1.482312738461538 ], [ 29.752217586206896, -1.481773784615385 ], [ 29.751948103448278, -1.481773784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 215, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.482043261538462 ], [ 29.723652413793104, -1.482312738461538 ], [ 29.724730344827588, -1.482312738461538 ], [ 29.724730344827588, -1.482582215384615 ], [ 29.724999827586206, -1.482582215384615 ], [ 29.724999827586206, -1.482043261538462 ], [ 29.723652413793104, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 216, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.482043261538462 ], [ 29.732545344827585, -1.482582215384615 ], [ 29.732814827586207, -1.482582215384615 ], [ 29.732814827586207, -1.482043261538462 ], [ 29.732545344827585, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 217, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.482043261538462 ], [ 29.732814827586207, -1.482582215384615 ], [ 29.733084310344829, -1.482582215384615 ], [ 29.733084310344829, -1.482043261538462 ], [ 29.732814827586207, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 218, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.482043261538462 ], [ 29.733084310344829, -1.482312738461538 ], [ 29.733353793103447, -1.482312738461538 ], [ 29.733353793103447, -1.482043261538462 ], [ 29.733084310344829, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 219, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.482043261538462 ], [ 29.733353793103447, -1.482312738461538 ], [ 29.733892758620691, -1.482312738461538 ], [ 29.733892758620691, -1.482043261538462 ], [ 29.733353793103447, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 220, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.482043261538462 ], [ 29.733892758620691, -1.482312738461538 ], [ 29.734970689655171, -1.482312738461538 ], [ 29.734970689655171, -1.482043261538462 ], [ 29.733892758620691, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 221, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.482043261538462 ], [ 29.734970689655171, -1.482312738461538 ], [ 29.735509655172415, -1.482312738461538 ], [ 29.735509655172415, -1.482043261538462 ], [ 29.734970689655171, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 222, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.482043261538462 ], [ 29.735509655172415, -1.482312738461538 ], [ 29.736048620689655, -1.482312738461538 ], [ 29.736048620689655, -1.482043261538462 ], [ 29.735509655172415, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 223, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.482043261538462 ], [ 29.736048620689655, -1.482312738461538 ], [ 29.736318103448276, -1.482312738461538 ], [ 29.736318103448276, -1.482043261538462 ], [ 29.736048620689655, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 224, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.482043261538462 ], [ 29.736318103448276, -1.482312738461538 ], [ 29.739282413793102, -1.482312738461538 ], [ 29.739282413793102, -1.482043261538462 ], [ 29.736318103448276, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 225, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.482043261538462 ], [ 29.739282413793102, -1.482312738461538 ], [ 29.739551896551724, -1.482312738461538 ], [ 29.739551896551724, -1.482043261538462 ], [ 29.739282413793102, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 226, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.482043261538462 ], [ 29.739551896551724, -1.482312738461538 ], [ 29.740629827586208, -1.482312738461538 ], [ 29.740629827586208, -1.482043261538462 ], [ 29.739551896551724, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 227, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.482043261538462 ], [ 29.740629827586208, -1.482312738461538 ], [ 29.741168793103448, -1.482312738461538 ], [ 29.741168793103448, -1.482043261538462 ], [ 29.740629827586208, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 228, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.482043261538462 ], [ 29.741168793103448, -1.482312738461538 ], [ 29.74143827586207, -1.482312738461538 ], [ 29.74143827586207, -1.482043261538462 ], [ 29.741168793103448, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 229, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.482043261538462 ], [ 29.74143827586207, -1.482312738461538 ], [ 29.741707758620691, -1.482312738461538 ], [ 29.741707758620691, -1.482043261538462 ], [ 29.74143827586207, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 230, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.482043261538462 ], [ 29.741707758620691, -1.482312738461538 ], [ 29.74197724137931, -1.482312738461538 ], [ 29.74197724137931, -1.482043261538462 ], [ 29.741707758620691, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 231, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.482043261538462 ], [ 29.74197724137931, -1.482312738461538 ], [ 29.742246724137932, -1.482312738461538 ], [ 29.742246724137932, -1.482043261538462 ], [ 29.74197724137931, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 232, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.482043261538462 ], [ 29.742246724137932, -1.482312738461538 ], [ 29.742516206896553, -1.482312738461538 ], [ 29.742516206896553, -1.482043261538462 ], [ 29.742246724137932, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 233, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.482043261538462 ], [ 29.742516206896553, -1.482312738461538 ], [ 29.743863620689655, -1.482312738461538 ], [ 29.743863620689655, -1.482043261538462 ], [ 29.742516206896553, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 234, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.482043261538462 ], [ 29.743863620689655, -1.482312738461538 ], [ 29.744133103448277, -1.482312738461538 ], [ 29.744133103448277, -1.482043261538462 ], [ 29.743863620689655, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 235, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.482043261538462 ], [ 29.744133103448277, -1.482312738461538 ], [ 29.746019482758619, -1.482312738461538 ], [ 29.746019482758619, -1.482043261538462 ], [ 29.744133103448277, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 236, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.482043261538462 ], [ 29.746019482758619, -1.482312738461538 ], [ 29.746288965517241, -1.482312738461538 ], [ 29.746288965517241, -1.482043261538462 ], [ 29.746019482758619, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 237, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.482043261538462 ], [ 29.746288965517241, -1.482312738461538 ], [ 29.746558448275863, -1.482312738461538 ], [ 29.746558448275863, -1.482043261538462 ], [ 29.746288965517241, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 238, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.482043261538462 ], [ 29.746558448275863, -1.482312738461538 ], [ 29.746827931034485, -1.482312738461538 ], [ 29.746827931034485, -1.482043261538462 ], [ 29.746558448275863, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 239, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.482043261538462 ], [ 29.746827931034485, -1.482312738461538 ], [ 29.747097413793103, -1.482312738461538 ], [ 29.747097413793103, -1.482043261538462 ], [ 29.746827931034485, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 240, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.482043261538462 ], [ 29.747097413793103, -1.482312738461538 ], [ 29.747366896551725, -1.482312738461538 ], [ 29.747366896551725, -1.482043261538462 ], [ 29.747097413793103, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 241, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.482043261538462 ], [ 29.747366896551725, -1.482312738461538 ], [ 29.747905862068965, -1.482312738461538 ], [ 29.747905862068965, -1.482043261538462 ], [ 29.747366896551725, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 242, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.482043261538462 ], [ 29.747905862068965, -1.482312738461538 ], [ 29.748175344827587, -1.482312738461538 ], [ 29.748175344827587, -1.482043261538462 ], [ 29.747905862068965, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 243, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.482043261538462 ], [ 29.748175344827587, -1.482312738461538 ], [ 29.748444827586209, -1.482312738461538 ], [ 29.748444827586209, -1.482043261538462 ], [ 29.748175344827587, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 244, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.482043261538462 ], [ 29.748444827586209, -1.482312738461538 ], [ 29.748714310344827, -1.482312738461538 ], [ 29.748714310344827, -1.482043261538462 ], [ 29.748444827586209, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 245, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.482043261538462 ], [ 29.748714310344827, -1.482312738461538 ], [ 29.748983793103449, -1.482312738461538 ], [ 29.748983793103449, -1.482043261538462 ], [ 29.748714310344827, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 246, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.482043261538462 ], [ 29.748983793103449, -1.482312738461538 ], [ 29.74925327586207, -1.482312738461538 ], [ 29.74925327586207, -1.482043261538462 ], [ 29.748983793103449, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 247, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.482043261538462 ], [ 29.74925327586207, -1.482312738461538 ], [ 29.749522758620689, -1.482312738461538 ], [ 29.749522758620689, -1.482043261538462 ], [ 29.74925327586207, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 248, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.482043261538462 ], [ 29.749522758620689, -1.482312738461538 ], [ 29.749792241379311, -1.482312738461538 ], [ 29.749792241379311, -1.482043261538462 ], [ 29.749522758620689, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 249, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.482043261538462 ], [ 29.749792241379311, -1.482312738461538 ], [ 29.750061724137932, -1.482312738461538 ], [ 29.750061724137932, -1.482043261538462 ], [ 29.749792241379311, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 250, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.482043261538462 ], [ 29.750061724137932, -1.482312738461538 ], [ 29.750331206896551, -1.482312738461538 ], [ 29.750331206896551, -1.482043261538462 ], [ 29.750061724137932, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 251, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.482043261538462 ], [ 29.750331206896551, -1.482312738461538 ], [ 29.750600689655172, -1.482312738461538 ], [ 29.750600689655172, -1.482043261538462 ], [ 29.750331206896551, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 252, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.482043261538462 ], [ 29.750600689655172, -1.482312738461538 ], [ 29.750870172413794, -1.482312738461538 ], [ 29.750870172413794, -1.482043261538462 ], [ 29.750600689655172, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 253, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.482043261538462 ], [ 29.751139655172413, -1.482312738461538 ], [ 29.751409137931034, -1.482312738461538 ], [ 29.751409137931034, -1.482043261538462 ], [ 29.751139655172413, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 254, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.482043261538462 ], [ 29.751409137931034, -1.482582215384615 ], [ 29.751678620689656, -1.482582215384615 ], [ 29.751678620689656, -1.482043261538462 ], [ 29.751409137931034, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 255, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.482043261538462 ], [ 29.751678620689656, -1.482582215384615 ], [ 29.751948103448278, -1.482582215384615 ], [ 29.751948103448278, -1.482043261538462 ], [ 29.751678620689656, -1.482043261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 256, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.482312738461538 ], [ 29.723382931034482, -1.482582215384615 ], [ 29.723652413793104, -1.482582215384615 ], [ 29.723652413793104, -1.482312738461538 ], [ 29.723382931034482, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 257, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.482312738461538 ], [ 29.723652413793104, -1.482582215384615 ], [ 29.723921896551722, -1.482582215384615 ], [ 29.723921896551722, -1.482312738461538 ], [ 29.723652413793104, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 258, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.482312738461538 ], [ 29.723921896551722, -1.482582215384615 ], [ 29.724191379310344, -1.482582215384615 ], [ 29.724191379310344, -1.483390646153846 ], [ 29.724460862068966, -1.483390646153846 ], [ 29.724460862068966, -1.482312738461538 ], [ 29.723921896551722, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 259, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.482312738461538 ], [ 29.724460862068966, -1.482582215384615 ], [ 29.724730344827588, -1.482582215384615 ], [ 29.724730344827588, -1.482312738461538 ], [ 29.724460862068966, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 260, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.482312738461538 ], [ 29.732006379310345, -1.482582215384615 ], [ 29.732275862068967, -1.482582215384615 ], [ 29.732275862068967, -1.482312738461538 ], [ 29.732006379310345, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 261, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.482312738461538 ], [ 29.732275862068967, -1.482582215384615 ], [ 29.732545344827585, -1.482582215384615 ], [ 29.732545344827585, -1.482312738461538 ], [ 29.732275862068967, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 262, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.482312738461538 ], [ 29.733084310344829, -1.482851692307692 ], [ 29.733353793103447, -1.482851692307692 ], [ 29.733353793103447, -1.482312738461538 ], [ 29.733084310344829, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 263, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.482312738461538 ], [ 29.733353793103447, -1.482582215384615 ], [ 29.733623275862069, -1.482582215384615 ], [ 29.733623275862069, -1.482312738461538 ], [ 29.733353793103447, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 264, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.482312738461538 ], [ 29.733623275862069, -1.482582215384615 ], [ 29.733892758620691, -1.482582215384615 ], [ 29.733892758620691, -1.482312738461538 ], [ 29.733623275862069, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 265, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.482312738461538 ], [ 29.733892758620691, -1.482582215384615 ], [ 29.734970689655171, -1.482582215384615 ], [ 29.734970689655171, -1.482312738461538 ], [ 29.733892758620691, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 266, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.482312738461538 ], [ 29.734970689655171, -1.482582215384615 ], [ 29.735240172413793, -1.482582215384615 ], [ 29.735240172413793, -1.482312738461538 ], [ 29.734970689655171, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 267, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.482312738461538 ], [ 29.735240172413793, -1.482582215384615 ], [ 29.736048620689655, -1.482582215384615 ], [ 29.736048620689655, -1.482312738461538 ], [ 29.735240172413793, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 268, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.482312738461538 ], [ 29.736048620689655, -1.482582215384615 ], [ 29.736587586206898, -1.482582215384615 ], [ 29.736587586206898, -1.482312738461538 ], [ 29.736048620689655, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 269, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.482312738461538 ], [ 29.736587586206898, -1.482582215384615 ], [ 29.739012931034484, -1.482582215384615 ], [ 29.739012931034484, -1.482312738461538 ], [ 29.736587586206898, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 270, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.482312738461538 ], [ 29.739012931034484, -1.482582215384615 ], [ 29.739551896551724, -1.482582215384615 ], [ 29.739551896551724, -1.482312738461538 ], [ 29.739012931034484, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 271, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.482312738461538 ], [ 29.739551896551724, -1.482582215384615 ], [ 29.74089931034483, -1.482582215384615 ], [ 29.74089931034483, -1.482312738461538 ], [ 29.739551896551724, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 272, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.482312738461538 ], [ 29.74089931034483, -1.482582215384615 ], [ 29.741168793103448, -1.482582215384615 ], [ 29.741168793103448, -1.482312738461538 ], [ 29.74089931034483, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 273, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.482312738461538 ], [ 29.741168793103448, -1.482582215384615 ], [ 29.74143827586207, -1.482582215384615 ], [ 29.74143827586207, -1.482312738461538 ], [ 29.741168793103448, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 274, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.482312738461538 ], [ 29.74143827586207, -1.482582215384615 ], [ 29.741707758620691, -1.482582215384615 ], [ 29.741707758620691, -1.482312738461538 ], [ 29.74143827586207, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 275, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.482312738461538 ], [ 29.741707758620691, -1.482582215384615 ], [ 29.74197724137931, -1.482582215384615 ], [ 29.74197724137931, -1.482312738461538 ], [ 29.741707758620691, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 276, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.482312738461538 ], [ 29.74197724137931, -1.482582215384615 ], [ 29.742246724137932, -1.482582215384615 ], [ 29.742246724137932, -1.482312738461538 ], [ 29.74197724137931, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 277, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.482312738461538 ], [ 29.742246724137932, -1.482582215384615 ], [ 29.742516206896553, -1.482582215384615 ], [ 29.742516206896553, -1.482312738461538 ], [ 29.742246724137932, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 278, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.482312738461538 ], [ 29.742516206896553, -1.482582215384615 ], [ 29.743863620689655, -1.482582215384615 ], [ 29.743863620689655, -1.482312738461538 ], [ 29.742516206896553, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 279, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.482312738461538 ], [ 29.743863620689655, -1.482582215384615 ], [ 29.744133103448277, -1.482582215384615 ], [ 29.744133103448277, -1.482312738461538 ], [ 29.743863620689655, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 280, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.482312738461538 ], [ 29.744133103448277, -1.482582215384615 ], [ 29.746019482758619, -1.482582215384615 ], [ 29.746019482758619, -1.482312738461538 ], [ 29.744133103448277, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 281, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.482312738461538 ], [ 29.746019482758619, -1.482582215384615 ], [ 29.746288965517241, -1.482582215384615 ], [ 29.746288965517241, -1.482312738461538 ], [ 29.746019482758619, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 282, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.482312738461538 ], [ 29.746288965517241, -1.482582215384615 ], [ 29.746558448275863, -1.482582215384615 ], [ 29.746558448275863, -1.482312738461538 ], [ 29.746288965517241, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 283, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.482312738461538 ], [ 29.746558448275863, -1.482582215384615 ], [ 29.746827931034485, -1.482582215384615 ], [ 29.746827931034485, -1.482312738461538 ], [ 29.746558448275863, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 284, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.482312738461538 ], [ 29.746827931034485, -1.482582215384615 ], [ 29.747097413793103, -1.482582215384615 ], [ 29.747097413793103, -1.482312738461538 ], [ 29.746827931034485, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 285, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.482312738461538 ], [ 29.747097413793103, -1.482582215384615 ], [ 29.747366896551725, -1.482582215384615 ], [ 29.747366896551725, -1.482312738461538 ], [ 29.747097413793103, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 286, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.482312738461538 ], [ 29.747366896551725, -1.482582215384615 ], [ 29.747905862068965, -1.482582215384615 ], [ 29.747905862068965, -1.482312738461538 ], [ 29.747366896551725, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 287, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.482312738461538 ], [ 29.747905862068965, -1.482582215384615 ], [ 29.748175344827587, -1.482582215384615 ], [ 29.748175344827587, -1.482312738461538 ], [ 29.747905862068965, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 288, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.482312738461538 ], [ 29.748175344827587, -1.482582215384615 ], [ 29.748444827586209, -1.482582215384615 ], [ 29.748444827586209, -1.482312738461538 ], [ 29.748175344827587, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 289, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.482312738461538 ], [ 29.748444827586209, -1.482582215384615 ], [ 29.748714310344827, -1.482582215384615 ], [ 29.748714310344827, -1.482312738461538 ], [ 29.748444827586209, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 290, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.482312738461538 ], [ 29.748714310344827, -1.482582215384615 ], [ 29.748983793103449, -1.482582215384615 ], [ 29.748983793103449, -1.482312738461538 ], [ 29.748714310344827, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 291, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.482312738461538 ], [ 29.748983793103449, -1.482582215384615 ], [ 29.74925327586207, -1.482582215384615 ], [ 29.74925327586207, -1.482312738461538 ], [ 29.748983793103449, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 292, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.482312738461538 ], [ 29.74925327586207, -1.482582215384615 ], [ 29.749522758620689, -1.482582215384615 ], [ 29.749522758620689, -1.482312738461538 ], [ 29.74925327586207, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 293, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.482312738461538 ], [ 29.749522758620689, -1.482582215384615 ], [ 29.749792241379311, -1.482582215384615 ], [ 29.749792241379311, -1.482312738461538 ], [ 29.749522758620689, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 294, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.482312738461538 ], [ 29.749792241379311, -1.482582215384615 ], [ 29.750061724137932, -1.482582215384615 ], [ 29.750061724137932, -1.482312738461538 ], [ 29.749792241379311, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 295, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.482312738461538 ], [ 29.750061724137932, -1.482582215384615 ], [ 29.750331206896551, -1.482582215384615 ], [ 29.750331206896551, -1.482312738461538 ], [ 29.750061724137932, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 296, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.482312738461538 ], [ 29.750331206896551, -1.482582215384615 ], [ 29.750600689655172, -1.482582215384615 ], [ 29.750600689655172, -1.482312738461538 ], [ 29.750331206896551, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 297, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.482312738461538 ], [ 29.750600689655172, -1.482582215384615 ], [ 29.750870172413794, -1.482582215384615 ], [ 29.750870172413794, -1.482312738461538 ], [ 29.750600689655172, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 298, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.482312738461538 ], [ 29.750870172413794, -1.482582215384615 ], [ 29.751139655172413, -1.482582215384615 ], [ 29.751139655172413, -1.482312738461538 ], [ 29.750870172413794, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 299, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.482312738461538 ], [ 29.751139655172413, -1.482851692307692 ], [ 29.751409137931034, -1.482851692307692 ], [ 29.751409137931034, -1.482312738461538 ], [ 29.751139655172413, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 300, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.482312738461538 ], [ 29.751948103448278, -1.482582215384615 ], [ 29.752217586206896, -1.482582215384615 ], [ 29.752217586206896, -1.482312738461538 ], [ 29.751948103448278, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 301, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.482312738461538 ], [ 29.752217586206896, -1.482851692307692 ], [ 29.752487068965518, -1.482851692307692 ], [ 29.752487068965518, -1.482312738461538 ], [ 29.752217586206896, -1.482312738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 302, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.482582215384615 ], [ 29.722843965517242, -1.482851692307692 ], [ 29.723382931034482, -1.482851692307692 ], [ 29.723382931034482, -1.482582215384615 ], [ 29.722843965517242, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 303, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.482582215384615 ], [ 29.723382931034482, -1.482851692307692 ], [ 29.723652413793104, -1.482851692307692 ], [ 29.723652413793104, -1.482582215384615 ], [ 29.723382931034482, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 304, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.482582215384615 ], [ 29.723652413793104, -1.482851692307692 ], [ 29.723921896551722, -1.482851692307692 ], [ 29.723921896551722, -1.482582215384615 ], [ 29.723652413793104, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 305, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.482582215384615 ], [ 29.732006379310345, -1.482851692307692 ], [ 29.732275862068967, -1.482851692307692 ], [ 29.732275862068967, -1.482582215384615 ], [ 29.732006379310345, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 306, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.482582215384615 ], [ 29.732275862068967, -1.482851692307692 ], [ 29.732545344827585, -1.482851692307692 ], [ 29.732545344827585, -1.482582215384615 ], [ 29.732275862068967, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 307, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.482582215384615 ], [ 29.732545344827585, -1.482851692307692 ], [ 29.732814827586207, -1.482851692307692 ], [ 29.732814827586207, -1.482582215384615 ], [ 29.732545344827585, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 308, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.482582215384615 ], [ 29.732814827586207, -1.482851692307692 ], [ 29.733084310344829, -1.482851692307692 ], [ 29.733084310344829, -1.482582215384615 ], [ 29.732814827586207, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 309, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.482582215384615 ], [ 29.733353793103447, -1.482851692307692 ], [ 29.733623275862069, -1.482851692307692 ], [ 29.733623275862069, -1.482582215384615 ], [ 29.733353793103447, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 310, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.482582215384615 ], [ 29.733623275862069, -1.482851692307692 ], [ 29.733892758620691, -1.482851692307692 ], [ 29.733892758620691, -1.482582215384615 ], [ 29.733623275862069, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 311, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.482582215384615 ], [ 29.733892758620691, -1.482851692307692 ], [ 29.734970689655171, -1.482851692307692 ], [ 29.734970689655171, -1.482582215384615 ], [ 29.733892758620691, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 312, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.482582215384615 ], [ 29.734970689655171, -1.482851692307692 ], [ 29.735240172413793, -1.482851692307692 ], [ 29.735240172413793, -1.482582215384615 ], [ 29.734970689655171, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 313, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.482582215384615 ], [ 29.735240172413793, -1.482851692307692 ], [ 29.736048620689655, -1.482851692307692 ], [ 29.736048620689655, -1.482582215384615 ], [ 29.735240172413793, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 314, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.482582215384615 ], [ 29.736048620689655, -1.482851692307692 ], [ 29.736318103448276, -1.482851692307692 ], [ 29.736318103448276, -1.482582215384615 ], [ 29.736048620689655, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 315, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.482582215384615 ], [ 29.736318103448276, -1.482851692307692 ], [ 29.739282413793102, -1.482851692307692 ], [ 29.739282413793102, -1.482582215384615 ], [ 29.736318103448276, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 316, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.482582215384615 ], [ 29.739282413793102, -1.482851692307692 ], [ 29.739551896551724, -1.482851692307692 ], [ 29.739551896551724, -1.482582215384615 ], [ 29.739282413793102, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 317, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.482582215384615 ], [ 29.739551896551724, -1.482851692307692 ], [ 29.74089931034483, -1.482851692307692 ], [ 29.74089931034483, -1.482582215384615 ], [ 29.739551896551724, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 318, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.482582215384615 ], [ 29.74089931034483, -1.482851692307692 ], [ 29.741168793103448, -1.482851692307692 ], [ 29.741168793103448, -1.482582215384615 ], [ 29.74089931034483, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 319, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.482582215384615 ], [ 29.741168793103448, -1.482851692307692 ], [ 29.74143827586207, -1.482851692307692 ], [ 29.74143827586207, -1.482582215384615 ], [ 29.741168793103448, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 320, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.482582215384615 ], [ 29.74143827586207, -1.482851692307692 ], [ 29.741707758620691, -1.482851692307692 ], [ 29.741707758620691, -1.482582215384615 ], [ 29.74143827586207, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 321, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.482582215384615 ], [ 29.741707758620691, -1.482851692307692 ], [ 29.74197724137931, -1.482851692307692 ], [ 29.74197724137931, -1.482582215384615 ], [ 29.741707758620691, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 322, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.482582215384615 ], [ 29.74197724137931, -1.482851692307692 ], [ 29.742246724137932, -1.482851692307692 ], [ 29.742246724137932, -1.482582215384615 ], [ 29.74197724137931, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 323, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.482582215384615 ], [ 29.742246724137932, -1.482851692307692 ], [ 29.743594137931034, -1.482851692307692 ], [ 29.743594137931034, -1.482582215384615 ], [ 29.742246724137932, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 324, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.482582215384615 ], [ 29.743594137931034, -1.482851692307692 ], [ 29.743863620689655, -1.482851692307692 ], [ 29.743863620689655, -1.482582215384615 ], [ 29.743594137931034, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 325, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.482582215384615 ], [ 29.743863620689655, -1.482851692307692 ], [ 29.744133103448277, -1.482851692307692 ], [ 29.744133103448277, -1.482582215384615 ], [ 29.743863620689655, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 326, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.482582215384615 ], [ 29.744133103448277, -1.482851692307692 ], [ 29.746019482758619, -1.482851692307692 ], [ 29.746019482758619, -1.482582215384615 ], [ 29.744133103448277, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 327, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.482582215384615 ], [ 29.746019482758619, -1.482851692307692 ], [ 29.746288965517241, -1.482851692307692 ], [ 29.746288965517241, -1.482582215384615 ], [ 29.746019482758619, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 328, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.482582215384615 ], [ 29.746288965517241, -1.482851692307692 ], [ 29.746558448275863, -1.482851692307692 ], [ 29.746558448275863, -1.482582215384615 ], [ 29.746288965517241, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 329, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.482582215384615 ], [ 29.746558448275863, -1.482851692307692 ], [ 29.746827931034485, -1.482851692307692 ], [ 29.746827931034485, -1.482582215384615 ], [ 29.746558448275863, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 330, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.482582215384615 ], [ 29.746827931034485, -1.482851692307692 ], [ 29.747097413793103, -1.482851692307692 ], [ 29.747097413793103, -1.482582215384615 ], [ 29.746827931034485, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 331, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.482582215384615 ], [ 29.747097413793103, -1.482851692307692 ], [ 29.747366896551725, -1.482851692307692 ], [ 29.747366896551725, -1.482582215384615 ], [ 29.747097413793103, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 332, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.482582215384615 ], [ 29.747366896551725, -1.482851692307692 ], [ 29.747905862068965, -1.482851692307692 ], [ 29.747905862068965, -1.482582215384615 ], [ 29.747366896551725, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 333, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.482582215384615 ], [ 29.747905862068965, -1.482851692307692 ], [ 29.748175344827587, -1.482851692307692 ], [ 29.748175344827587, -1.482582215384615 ], [ 29.747905862068965, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 334, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.482582215384615 ], [ 29.748175344827587, -1.482851692307692 ], [ 29.748444827586209, -1.482851692307692 ], [ 29.748444827586209, -1.482582215384615 ], [ 29.748175344827587, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 335, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.482582215384615 ], [ 29.748444827586209, -1.482851692307692 ], [ 29.748714310344827, -1.482851692307692 ], [ 29.748714310344827, -1.482582215384615 ], [ 29.748444827586209, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 336, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.482582215384615 ], [ 29.748714310344827, -1.482851692307692 ], [ 29.748983793103449, -1.482851692307692 ], [ 29.748983793103449, -1.482582215384615 ], [ 29.748714310344827, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 337, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.482582215384615 ], [ 29.748983793103449, -1.482851692307692 ], [ 29.74925327586207, -1.482851692307692 ], [ 29.74925327586207, -1.482582215384615 ], [ 29.748983793103449, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 338, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.482582215384615 ], [ 29.74925327586207, -1.482851692307692 ], [ 29.749522758620689, -1.482851692307692 ], [ 29.749522758620689, -1.482582215384615 ], [ 29.74925327586207, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 339, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.482582215384615 ], [ 29.749522758620689, -1.482851692307692 ], [ 29.749792241379311, -1.482851692307692 ], [ 29.749792241379311, -1.482582215384615 ], [ 29.749522758620689, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 340, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.482582215384615 ], [ 29.749792241379311, -1.482851692307692 ], [ 29.750061724137932, -1.482851692307692 ], [ 29.750061724137932, -1.482582215384615 ], [ 29.749792241379311, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 341, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.482582215384615 ], [ 29.750061724137932, -1.482851692307692 ], [ 29.750331206896551, -1.482851692307692 ], [ 29.750331206896551, -1.482582215384615 ], [ 29.750061724137932, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 342, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.482582215384615 ], [ 29.750331206896551, -1.482851692307692 ], [ 29.750600689655172, -1.482851692307692 ], [ 29.750600689655172, -1.482582215384615 ], [ 29.750331206896551, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 343, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.482582215384615 ], [ 29.750600689655172, -1.482851692307692 ], [ 29.750870172413794, -1.482851692307692 ], [ 29.750870172413794, -1.482582215384615 ], [ 29.750600689655172, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 344, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.482582215384615 ], [ 29.750870172413794, -1.482851692307692 ], [ 29.751139655172413, -1.482851692307692 ], [ 29.751139655172413, -1.482582215384615 ], [ 29.750870172413794, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 345, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.482582215384615 ], [ 29.751409137931034, -1.482851692307692 ], [ 29.751678620689656, -1.482851692307692 ], [ 29.751678620689656, -1.482582215384615 ], [ 29.751409137931034, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 346, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.482582215384615 ], [ 29.751678620689656, -1.483121169230769 ], [ 29.751948103448278, -1.483121169230769 ], [ 29.751948103448278, -1.482582215384615 ], [ 29.751678620689656, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 347, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.482582215384615 ], [ 29.751948103448278, -1.483121169230769 ], [ 29.752217586206896, -1.483121169230769 ], [ 29.752217586206896, -1.482582215384615 ], [ 29.751948103448278, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 348, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.482851692307692 ], [ 29.72257448275862, -1.483121169230769 ], [ 29.722843965517242, -1.483121169230769 ], [ 29.722843965517242, -1.482851692307692 ], [ 29.72257448275862, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 349, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.482851692307692 ], [ 29.722843965517242, -1.483121169230769 ], [ 29.723113448275861, -1.483121169230769 ], [ 29.723113448275861, -1.482851692307692 ], [ 29.722843965517242, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 350, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.482851692307692 ], [ 29.723113448275861, -1.483121169230769 ], [ 29.723652413793104, -1.483121169230769 ], [ 29.723652413793104, -1.482851692307692 ], [ 29.723113448275861, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 351, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.482582215384615 ], [ 29.724191379310344, -1.482582215384615 ], [ 29.724191379310344, -1.483390646153846 ], [ 29.723921896551722, -1.483390646153846 ], [ 29.723921896551722, -1.483121169230769 ], [ 29.723652413793104, -1.483121169230769 ], [ 29.723652413793104, -1.482851692307692 ], [ 29.723921896551722, -1.482851692307692 ], [ 29.723921896551722, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 352, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.482851692307692 ], [ 29.732275862068967, -1.483121169230769 ], [ 29.732545344827585, -1.483121169230769 ], [ 29.732545344827585, -1.482851692307692 ], [ 29.732275862068967, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 353, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.482851692307692 ], [ 29.732545344827585, -1.483121169230769 ], [ 29.732814827586207, -1.483121169230769 ], [ 29.732814827586207, -1.482851692307692 ], [ 29.732545344827585, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 354, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.482851692307692 ], [ 29.732814827586207, -1.483121169230769 ], [ 29.733084310344829, -1.483121169230769 ], [ 29.733084310344829, -1.482851692307692 ], [ 29.732814827586207, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 355, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.482851692307692 ], [ 29.733084310344829, -1.483121169230769 ], [ 29.733353793103447, -1.483121169230769 ], [ 29.733353793103447, -1.482851692307692 ], [ 29.733084310344829, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 356, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.482851692307692 ], [ 29.733353793103447, -1.483121169230769 ], [ 29.733623275862069, -1.483121169230769 ], [ 29.733623275862069, -1.482851692307692 ], [ 29.733353793103447, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 357, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.482851692307692 ], [ 29.733623275862069, -1.483121169230769 ], [ 29.733892758620691, -1.483121169230769 ], [ 29.733892758620691, -1.482851692307692 ], [ 29.733623275862069, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 358, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.482851692307692 ], [ 29.733892758620691, -1.483121169230769 ], [ 29.734162241379309, -1.483121169230769 ], [ 29.734162241379309, -1.482851692307692 ], [ 29.733892758620691, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 359, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.482851692307692 ], [ 29.734162241379309, -1.483121169230769 ], [ 29.734701206896553, -1.483121169230769 ], [ 29.734701206896553, -1.482851692307692 ], [ 29.734162241379309, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 360, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.482851692307692 ], [ 29.734701206896553, -1.483121169230769 ], [ 29.734970689655171, -1.483121169230769 ], [ 29.734970689655171, -1.482851692307692 ], [ 29.734701206896553, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 361, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.482851692307692 ], [ 29.734970689655171, -1.483121169230769 ], [ 29.735240172413793, -1.483121169230769 ], [ 29.735240172413793, -1.482851692307692 ], [ 29.734970689655171, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 362, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.482851692307692 ], [ 29.735240172413793, -1.483121169230769 ], [ 29.736048620689655, -1.483121169230769 ], [ 29.736048620689655, -1.482851692307692 ], [ 29.735240172413793, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 363, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.482851692307692 ], [ 29.736048620689655, -1.483121169230769 ], [ 29.736318103448276, -1.483121169230769 ], [ 29.736318103448276, -1.482851692307692 ], [ 29.736048620689655, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 364, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.482851692307692 ], [ 29.736318103448276, -1.483121169230769 ], [ 29.739282413793102, -1.483121169230769 ], [ 29.739282413793102, -1.482851692307692 ], [ 29.736318103448276, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 365, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.482851692307692 ], [ 29.739282413793102, -1.483121169230769 ], [ 29.739551896551724, -1.483121169230769 ], [ 29.739551896551724, -1.482851692307692 ], [ 29.739282413793102, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 366, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.482851692307692 ], [ 29.739551896551724, -1.483121169230769 ], [ 29.74089931034483, -1.483121169230769 ], [ 29.74089931034483, -1.482851692307692 ], [ 29.739551896551724, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 367, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.482851692307692 ], [ 29.74089931034483, -1.483121169230769 ], [ 29.741168793103448, -1.483121169230769 ], [ 29.741168793103448, -1.482851692307692 ], [ 29.74089931034483, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 368, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.482851692307692 ], [ 29.741168793103448, -1.483121169230769 ], [ 29.74143827586207, -1.483121169230769 ], [ 29.74143827586207, -1.482851692307692 ], [ 29.741168793103448, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 369, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.482851692307692 ], [ 29.74143827586207, -1.483121169230769 ], [ 29.741707758620691, -1.483121169230769 ], [ 29.741707758620691, -1.482851692307692 ], [ 29.74143827586207, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 370, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.482851692307692 ], [ 29.741707758620691, -1.483121169230769 ], [ 29.74197724137931, -1.483121169230769 ], [ 29.74197724137931, -1.482851692307692 ], [ 29.741707758620691, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 371, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.482851692307692 ], [ 29.74197724137931, -1.483121169230769 ], [ 29.743594137931034, -1.483121169230769 ], [ 29.743594137931034, -1.482851692307692 ], [ 29.74197724137931, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 372, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.482851692307692 ], [ 29.743594137931034, -1.483121169230769 ], [ 29.743863620689655, -1.483121169230769 ], [ 29.743863620689655, -1.482851692307692 ], [ 29.743594137931034, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 373, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.482851692307692 ], [ 29.743863620689655, -1.483121169230769 ], [ 29.746288965517241, -1.483121169230769 ], [ 29.746288965517241, -1.482851692307692 ], [ 29.743863620689655, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 374, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.482851692307692 ], [ 29.746288965517241, -1.483121169230769 ], [ 29.746558448275863, -1.483121169230769 ], [ 29.746558448275863, -1.482851692307692 ], [ 29.746288965517241, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 375, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.482851692307692 ], [ 29.746558448275863, -1.483121169230769 ], [ 29.746827931034485, -1.483121169230769 ], [ 29.746827931034485, -1.482851692307692 ], [ 29.746558448275863, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 376, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.482851692307692 ], [ 29.746827931034485, -1.483121169230769 ], [ 29.747097413793103, -1.483121169230769 ], [ 29.747097413793103, -1.482851692307692 ], [ 29.746827931034485, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 377, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.482851692307692 ], [ 29.747097413793103, -1.483121169230769 ], [ 29.747366896551725, -1.483121169230769 ], [ 29.747366896551725, -1.482851692307692 ], [ 29.747097413793103, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 378, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.482851692307692 ], [ 29.747366896551725, -1.483121169230769 ], [ 29.747905862068965, -1.483121169230769 ], [ 29.747905862068965, -1.482851692307692 ], [ 29.747366896551725, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 379, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.482851692307692 ], [ 29.747905862068965, -1.483121169230769 ], [ 29.748175344827587, -1.483121169230769 ], [ 29.748175344827587, -1.482851692307692 ], [ 29.747905862068965, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 380, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.482851692307692 ], [ 29.748175344827587, -1.483121169230769 ], [ 29.748444827586209, -1.483121169230769 ], [ 29.748444827586209, -1.482851692307692 ], [ 29.748175344827587, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 381, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.482851692307692 ], [ 29.748444827586209, -1.483121169230769 ], [ 29.748714310344827, -1.483121169230769 ], [ 29.748714310344827, -1.482851692307692 ], [ 29.748444827586209, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 382, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.482851692307692 ], [ 29.748714310344827, -1.483121169230769 ], [ 29.748983793103449, -1.483121169230769 ], [ 29.748983793103449, -1.482851692307692 ], [ 29.748714310344827, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 383, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.482851692307692 ], [ 29.748983793103449, -1.483121169230769 ], [ 29.74925327586207, -1.483121169230769 ], [ 29.74925327586207, -1.482851692307692 ], [ 29.748983793103449, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 384, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.482851692307692 ], [ 29.74925327586207, -1.483121169230769 ], [ 29.749522758620689, -1.483121169230769 ], [ 29.749522758620689, -1.482851692307692 ], [ 29.74925327586207, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 385, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.482851692307692 ], [ 29.749522758620689, -1.483121169230769 ], [ 29.749792241379311, -1.483121169230769 ], [ 29.749792241379311, -1.482851692307692 ], [ 29.749522758620689, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 386, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.482851692307692 ], [ 29.749792241379311, -1.483121169230769 ], [ 29.750061724137932, -1.483121169230769 ], [ 29.750061724137932, -1.482851692307692 ], [ 29.749792241379311, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 387, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.482851692307692 ], [ 29.750061724137932, -1.483121169230769 ], [ 29.750331206896551, -1.483121169230769 ], [ 29.750331206896551, -1.482851692307692 ], [ 29.750061724137932, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 388, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.482851692307692 ], [ 29.750331206896551, -1.483121169230769 ], [ 29.750600689655172, -1.483121169230769 ], [ 29.750600689655172, -1.482851692307692 ], [ 29.750331206896551, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 389, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.482851692307692 ], [ 29.750600689655172, -1.483121169230769 ], [ 29.750870172413794, -1.483121169230769 ], [ 29.750870172413794, -1.482851692307692 ], [ 29.750600689655172, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 390, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.482851692307692 ], [ 29.750870172413794, -1.483121169230769 ], [ 29.751139655172413, -1.483121169230769 ], [ 29.751139655172413, -1.482851692307692 ], [ 29.750870172413794, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 391, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.482851692307692 ], [ 29.751139655172413, -1.483121169230769 ], [ 29.751409137931034, -1.483121169230769 ], [ 29.751409137931034, -1.482851692307692 ], [ 29.751139655172413, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 392, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.482851692307692 ], [ 29.751409137931034, -1.483390646153846 ], [ 29.751678620689656, -1.483390646153846 ], [ 29.751678620689656, -1.482851692307692 ], [ 29.751409137931034, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 393, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.482851692307692 ], [ 29.752217586206896, -1.483121169230769 ], [ 29.752487068965518, -1.483121169230769 ], [ 29.752487068965518, -1.482851692307692 ], [ 29.752217586206896, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 394, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.482851692307692 ], [ 29.752487068965518, -1.483121169230769 ], [ 29.75275655172414, -1.483121169230769 ], [ 29.75275655172414, -1.482851692307692 ], [ 29.752487068965518, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 395, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.483121169230769 ], [ 29.72203551724138, -1.483390646153846 ], [ 29.72257448275862, -1.483390646153846 ], [ 29.72257448275862, -1.483121169230769 ], [ 29.72203551724138, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 396, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.483121169230769 ], [ 29.72257448275862, -1.483390646153846 ], [ 29.722843965517242, -1.483390646153846 ], [ 29.722843965517242, -1.483121169230769 ], [ 29.72257448275862, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 397, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.483121169230769 ], [ 29.722843965517242, -1.483390646153846 ], [ 29.723113448275861, -1.483390646153846 ], [ 29.723113448275861, -1.483121169230769 ], [ 29.722843965517242, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 398, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.483121169230769 ], [ 29.723113448275861, -1.483390646153846 ], [ 29.723652413793104, -1.483390646153846 ], [ 29.723652413793104, -1.483121169230769 ], [ 29.723113448275861, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 399, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.483121169230769 ], [ 29.723652413793104, -1.483390646153846 ], [ 29.723921896551722, -1.483390646153846 ], [ 29.723921896551722, -1.483121169230769 ], [ 29.723652413793104, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 400, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.483121169230769 ], [ 29.732275862068967, -1.483390646153846 ], [ 29.732545344827585, -1.483390646153846 ], [ 29.732545344827585, -1.483121169230769 ], [ 29.732275862068967, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 401, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.483121169230769 ], [ 29.732545344827585, -1.483390646153846 ], [ 29.732814827586207, -1.483390646153846 ], [ 29.732814827586207, -1.483121169230769 ], [ 29.732545344827585, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 402, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.483121169230769 ], [ 29.732814827586207, -1.483390646153846 ], [ 29.733084310344829, -1.483390646153846 ], [ 29.733084310344829, -1.483121169230769 ], [ 29.732814827586207, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 403, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.483121169230769 ], [ 29.733084310344829, -1.483390646153846 ], [ 29.733353793103447, -1.483390646153846 ], [ 29.733353793103447, -1.483121169230769 ], [ 29.733084310344829, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 404, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.483121169230769 ], [ 29.733353793103447, -1.483390646153846 ], [ 29.733623275862069, -1.483390646153846 ], [ 29.733623275862069, -1.483121169230769 ], [ 29.733353793103447, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 405, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.483121169230769 ], [ 29.733623275862069, -1.483390646153846 ], [ 29.733892758620691, -1.483390646153846 ], [ 29.733892758620691, -1.483121169230769 ], [ 29.733623275862069, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 406, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.483121169230769 ], [ 29.733892758620691, -1.483390646153846 ], [ 29.734162241379309, -1.483390646153846 ], [ 29.734162241379309, -1.483121169230769 ], [ 29.733892758620691, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 407, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.483121169230769 ], [ 29.734162241379309, -1.483390646153846 ], [ 29.734701206896553, -1.483390646153846 ], [ 29.734701206896553, -1.483121169230769 ], [ 29.734162241379309, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 408, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.483121169230769 ], [ 29.734701206896553, -1.483390646153846 ], [ 29.734970689655171, -1.483390646153846 ], [ 29.734970689655171, -1.483121169230769 ], [ 29.734701206896553, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 409, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.483121169230769 ], [ 29.734970689655171, -1.483390646153846 ], [ 29.735779137931036, -1.483390646153846 ], [ 29.735779137931036, -1.483121169230769 ], [ 29.734970689655171, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 410, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.483121169230769 ], [ 29.735779137931036, -1.483390646153846 ], [ 29.736048620689655, -1.483390646153846 ], [ 29.736048620689655, -1.483121169230769 ], [ 29.735779137931036, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 411, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.483121169230769 ], [ 29.736048620689655, -1.483390646153846 ], [ 29.736318103448276, -1.483390646153846 ], [ 29.736318103448276, -1.483121169230769 ], [ 29.736048620689655, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 412, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.483121169230769 ], [ 29.736318103448276, -1.483390646153846 ], [ 29.739282413793102, -1.483390646153846 ], [ 29.739282413793102, -1.483121169230769 ], [ 29.736318103448276, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 413, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.483121169230769 ], [ 29.739282413793102, -1.483390646153846 ], [ 29.739551896551724, -1.483390646153846 ], [ 29.739551896551724, -1.483121169230769 ], [ 29.739282413793102, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 414, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.483121169230769 ], [ 29.739551896551724, -1.483390646153846 ], [ 29.739821379310346, -1.483390646153846 ], [ 29.739821379310346, -1.483121169230769 ], [ 29.739551896551724, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 415, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.483121169230769 ], [ 29.739821379310346, -1.483390646153846 ], [ 29.741168793103448, -1.483390646153846 ], [ 29.741168793103448, -1.483121169230769 ], [ 29.739821379310346, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 416, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.483121169230769 ], [ 29.741168793103448, -1.483390646153846 ], [ 29.74143827586207, -1.483390646153846 ], [ 29.74143827586207, -1.483121169230769 ], [ 29.741168793103448, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 417, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.483121169230769 ], [ 29.74143827586207, -1.483390646153846 ], [ 29.741707758620691, -1.483390646153846 ], [ 29.741707758620691, -1.483121169230769 ], [ 29.74143827586207, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 418, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.483121169230769 ], [ 29.741707758620691, -1.483390646153846 ], [ 29.74197724137931, -1.483390646153846 ], [ 29.74197724137931, -1.483121169230769 ], [ 29.741707758620691, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 419, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.483121169230769 ], [ 29.74197724137931, -1.483390646153846 ], [ 29.742246724137932, -1.483390646153846 ], [ 29.742246724137932, -1.483121169230769 ], [ 29.74197724137931, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 420, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.483121169230769 ], [ 29.742246724137932, -1.483390646153846 ], [ 29.742516206896553, -1.483390646153846 ], [ 29.742516206896553, -1.483121169230769 ], [ 29.742246724137932, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 421, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.483121169230769 ], [ 29.742516206896553, -1.483390646153846 ], [ 29.743594137931034, -1.483390646153846 ], [ 29.743594137931034, -1.483121169230769 ], [ 29.742516206896553, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 422, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.483121169230769 ], [ 29.743594137931034, -1.483390646153846 ], [ 29.743863620689655, -1.483390646153846 ], [ 29.743863620689655, -1.483121169230769 ], [ 29.743594137931034, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 423, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.483121169230769 ], [ 29.743863620689655, -1.483390646153846 ], [ 29.746288965517241, -1.483390646153846 ], [ 29.746288965517241, -1.483121169230769 ], [ 29.743863620689655, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 424, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.483121169230769 ], [ 29.746288965517241, -1.483390646153846 ], [ 29.746558448275863, -1.483390646153846 ], [ 29.746558448275863, -1.483121169230769 ], [ 29.746288965517241, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 425, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.483121169230769 ], [ 29.746558448275863, -1.483390646153846 ], [ 29.746827931034485, -1.483390646153846 ], [ 29.746827931034485, -1.483121169230769 ], [ 29.746558448275863, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 426, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.483121169230769 ], [ 29.746827931034485, -1.483390646153846 ], [ 29.747097413793103, -1.483390646153846 ], [ 29.747097413793103, -1.483121169230769 ], [ 29.746827931034485, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 427, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.483121169230769 ], [ 29.747097413793103, -1.483390646153846 ], [ 29.747366896551725, -1.483390646153846 ], [ 29.747366896551725, -1.483121169230769 ], [ 29.747097413793103, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 428, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.483121169230769 ], [ 29.747366896551725, -1.483390646153846 ], [ 29.747905862068965, -1.483390646153846 ], [ 29.747905862068965, -1.483121169230769 ], [ 29.747366896551725, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 429, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.483121169230769 ], [ 29.747905862068965, -1.483390646153846 ], [ 29.748175344827587, -1.483390646153846 ], [ 29.748175344827587, -1.483121169230769 ], [ 29.747905862068965, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 430, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.483121169230769 ], [ 29.748175344827587, -1.483390646153846 ], [ 29.748444827586209, -1.483390646153846 ], [ 29.748444827586209, -1.483121169230769 ], [ 29.748175344827587, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 431, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.483121169230769 ], [ 29.748444827586209, -1.483390646153846 ], [ 29.748714310344827, -1.483390646153846 ], [ 29.748714310344827, -1.483121169230769 ], [ 29.748444827586209, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 432, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.483121169230769 ], [ 29.748714310344827, -1.483390646153846 ], [ 29.748983793103449, -1.483390646153846 ], [ 29.748983793103449, -1.483121169230769 ], [ 29.748714310344827, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 433, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.483121169230769 ], [ 29.748983793103449, -1.483390646153846 ], [ 29.74925327586207, -1.483390646153846 ], [ 29.74925327586207, -1.483121169230769 ], [ 29.748983793103449, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 434, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.483121169230769 ], [ 29.74925327586207, -1.483390646153846 ], [ 29.749522758620689, -1.483390646153846 ], [ 29.749522758620689, -1.483121169230769 ], [ 29.74925327586207, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 435, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.483121169230769 ], [ 29.749522758620689, -1.483390646153846 ], [ 29.749792241379311, -1.483390646153846 ], [ 29.749792241379311, -1.483121169230769 ], [ 29.749522758620689, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 436, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.483121169230769 ], [ 29.749792241379311, -1.483390646153846 ], [ 29.750061724137932, -1.483390646153846 ], [ 29.750061724137932, -1.483121169230769 ], [ 29.749792241379311, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 437, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.483121169230769 ], [ 29.750061724137932, -1.483390646153846 ], [ 29.750331206896551, -1.483390646153846 ], [ 29.750331206896551, -1.483121169230769 ], [ 29.750061724137932, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 438, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.483121169230769 ], [ 29.750331206896551, -1.483390646153846 ], [ 29.750600689655172, -1.483390646153846 ], [ 29.750600689655172, -1.483121169230769 ], [ 29.750331206896551, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 439, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.483121169230769 ], [ 29.750600689655172, -1.483390646153846 ], [ 29.750870172413794, -1.483390646153846 ], [ 29.750870172413794, -1.483121169230769 ], [ 29.750600689655172, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 440, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.483121169230769 ], [ 29.750870172413794, -1.483390646153846 ], [ 29.751139655172413, -1.483390646153846 ], [ 29.751139655172413, -1.483121169230769 ], [ 29.750870172413794, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 441, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.483121169230769 ], [ 29.751139655172413, -1.483390646153846 ], [ 29.751409137931034, -1.483390646153846 ], [ 29.751409137931034, -1.483121169230769 ], [ 29.751139655172413, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 442, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.483121169230769 ], [ 29.751678620689656, -1.483390646153846 ], [ 29.751948103448278, -1.483390646153846 ], [ 29.751948103448278, -1.483121169230769 ], [ 29.751678620689656, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 443, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.483121169230769 ], [ 29.751948103448278, -1.483390646153846 ], [ 29.752217586206896, -1.483390646153846 ], [ 29.752217586206896, -1.483121169230769 ], [ 29.751948103448278, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 444, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.483121169230769 ], [ 29.752217586206896, -1.483390646153846 ], [ 29.752487068965518, -1.483390646153846 ], [ 29.752487068965518, -1.483121169230769 ], [ 29.752217586206896, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 445, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.483121169230769 ], [ 29.752487068965518, -1.483390646153846 ], [ 29.75275655172414, -1.483390646153846 ], [ 29.75275655172414, -1.483121169230769 ], [ 29.752487068965518, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 446, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.483121169230769 ], [ 29.75275655172414, -1.483390646153846 ], [ 29.753026034482758, -1.483390646153846 ], [ 29.753026034482758, -1.483121169230769 ], [ 29.75275655172414, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 447, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.482582215384615 ], [ 29.724460862068966, -1.483660123076923 ], [ 29.724730344827588, -1.483660123076923 ], [ 29.724730344827588, -1.482582215384615 ], [ 29.724460862068966, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 448, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.482582215384615 ], [ 29.730658965517243, -1.485007507692308 ], [ 29.730928448275861, -1.485007507692308 ], [ 29.730928448275861, -1.482851692307692 ], [ 29.732006379310345, -1.482851692307692 ], [ 29.732006379310345, -1.482582215384615 ], [ 29.730658965517243, -1.482582215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 449, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.482851692307692 ], [ 29.730928448275861, -1.485276984615385 ], [ 29.731197931034483, -1.485276984615385 ], [ 29.731197931034483, -1.483121169230769 ], [ 29.732275862068967, -1.483121169230769 ], [ 29.732275862068967, -1.482851692307692 ], [ 29.730928448275861, -1.482851692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 450, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.483121169230769 ], [ 29.731197931034483, -1.485276984615385 ], [ 29.731467413793105, -1.485276984615385 ], [ 29.731467413793105, -1.483390646153846 ], [ 29.732275862068967, -1.483390646153846 ], [ 29.732275862068967, -1.483121169230769 ], [ 29.731197931034483, -1.483121169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 451, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.483390646153846 ], [ 29.721766034482759, -1.483660123076923 ], [ 29.72203551724138, -1.483660123076923 ], [ 29.72203551724138, -1.483390646153846 ], [ 29.721766034482759, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 452, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.483390646153846 ], [ 29.72203551724138, -1.483660123076923 ], [ 29.722305, -1.483660123076923 ], [ 29.722305, -1.483390646153846 ], [ 29.72203551724138, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 453, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.483390646153846 ], [ 29.722305, -1.483660123076923 ], [ 29.722843965517242, -1.483660123076923 ], [ 29.722843965517242, -1.483390646153846 ], [ 29.722305, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 454, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.483390646153846 ], [ 29.722843965517242, -1.483660123076923 ], [ 29.723113448275861, -1.483660123076923 ], [ 29.723113448275861, -1.483390646153846 ], [ 29.722843965517242, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 455, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.483390646153846 ], [ 29.723113448275861, -1.483660123076923 ], [ 29.723382931034482, -1.483660123076923 ], [ 29.723382931034482, -1.483390646153846 ], [ 29.723113448275861, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 456, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.483390646153846 ], [ 29.723382931034482, -1.483660123076923 ], [ 29.723652413793104, -1.483660123076923 ], [ 29.723652413793104, -1.483390646153846 ], [ 29.723382931034482, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 457, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.483390646153846 ], [ 29.724191379310344, -1.483660123076923 ], [ 29.724460862068966, -1.483660123076923 ], [ 29.724460862068966, -1.483390646153846 ], [ 29.724191379310344, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 458, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.483390646153846 ], [ 29.731467413793105, -1.485007507692308 ], [ 29.731736896551723, -1.485007507692308 ], [ 29.731736896551723, -1.483660123076923 ], [ 29.732006379310345, -1.483660123076923 ], [ 29.732006379310345, -1.483390646153846 ], [ 29.731467413793105, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 459, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.483390646153846 ], [ 29.732006379310345, -1.483660123076923 ], [ 29.732545344827585, -1.483660123076923 ], [ 29.732545344827585, -1.483390646153846 ], [ 29.732006379310345, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 460, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.483390646153846 ], [ 29.732545344827585, -1.483660123076923 ], [ 29.732814827586207, -1.483660123076923 ], [ 29.732814827586207, -1.483390646153846 ], [ 29.732545344827585, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 461, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.483390646153846 ], [ 29.732814827586207, -1.483660123076923 ], [ 29.733084310344829, -1.483660123076923 ], [ 29.733084310344829, -1.483390646153846 ], [ 29.732814827586207, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 462, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.483390646153846 ], [ 29.733084310344829, -1.483660123076923 ], [ 29.733353793103447, -1.483660123076923 ], [ 29.733353793103447, -1.483390646153846 ], [ 29.733084310344829, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 463, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.483390646153846 ], [ 29.733353793103447, -1.483660123076923 ], [ 29.733623275862069, -1.483660123076923 ], [ 29.733623275862069, -1.483390646153846 ], [ 29.733353793103447, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 464, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.483390646153846 ], [ 29.733623275862069, -1.483660123076923 ], [ 29.733892758620691, -1.483660123076923 ], [ 29.733892758620691, -1.483390646153846 ], [ 29.733623275862069, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 465, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.483390646153846 ], [ 29.733892758620691, -1.483660123076923 ], [ 29.734162241379309, -1.483660123076923 ], [ 29.734162241379309, -1.483390646153846 ], [ 29.733892758620691, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 466, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.483390646153846 ], [ 29.734162241379309, -1.483660123076923 ], [ 29.734431724137931, -1.483660123076923 ], [ 29.734431724137931, -1.483390646153846 ], [ 29.734162241379309, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 467, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.483390646153846 ], [ 29.734431724137931, -1.483660123076923 ], [ 29.734701206896553, -1.483660123076923 ], [ 29.734701206896553, -1.483390646153846 ], [ 29.734431724137931, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 468, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.483390646153846 ], [ 29.734701206896553, -1.483660123076923 ], [ 29.734970689655171, -1.483660123076923 ], [ 29.734970689655171, -1.483390646153846 ], [ 29.734701206896553, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 469, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.483390646153846 ], [ 29.734970689655171, -1.483660123076923 ], [ 29.735779137931036, -1.483660123076923 ], [ 29.735779137931036, -1.483390646153846 ], [ 29.734970689655171, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 470, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.483390646153846 ], [ 29.735779137931036, -1.483660123076923 ], [ 29.736048620689655, -1.483660123076923 ], [ 29.736048620689655, -1.483390646153846 ], [ 29.735779137931036, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 471, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.483390646153846 ], [ 29.736048620689655, -1.483660123076923 ], [ 29.739551896551724, -1.483660123076923 ], [ 29.739551896551724, -1.483390646153846 ], [ 29.736048620689655, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 472, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.483390646153846 ], [ 29.739551896551724, -1.483660123076923 ], [ 29.739821379310346, -1.483660123076923 ], [ 29.739821379310346, -1.483390646153846 ], [ 29.739551896551724, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 473, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.483390646153846 ], [ 29.739821379310346, -1.483660123076923 ], [ 29.741168793103448, -1.483660123076923 ], [ 29.741168793103448, -1.483390646153846 ], [ 29.739821379310346, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 474, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.483390646153846 ], [ 29.741168793103448, -1.483660123076923 ], [ 29.74143827586207, -1.483660123076923 ], [ 29.74143827586207, -1.483390646153846 ], [ 29.741168793103448, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 475, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.483390646153846 ], [ 29.74143827586207, -1.483660123076923 ], [ 29.741707758620691, -1.483660123076923 ], [ 29.741707758620691, -1.483390646153846 ], [ 29.74143827586207, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 476, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.483390646153846 ], [ 29.741707758620691, -1.483660123076923 ], [ 29.74197724137931, -1.483660123076923 ], [ 29.74197724137931, -1.483390646153846 ], [ 29.741707758620691, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 477, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.483390646153846 ], [ 29.74197724137931, -1.483660123076923 ], [ 29.742246724137932, -1.483660123076923 ], [ 29.742246724137932, -1.483390646153846 ], [ 29.74197724137931, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 478, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.483390646153846 ], [ 29.742246724137932, -1.483660123076923 ], [ 29.743324655172415, -1.483660123076923 ], [ 29.743324655172415, -1.483390646153846 ], [ 29.742246724137932, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 479, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.483390646153846 ], [ 29.743324655172415, -1.483660123076923 ], [ 29.743594137931034, -1.483660123076923 ], [ 29.743594137931034, -1.483390646153846 ], [ 29.743324655172415, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 480, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.483390646153846 ], [ 29.743594137931034, -1.483660123076923 ], [ 29.744133103448277, -1.483660123076923 ], [ 29.744133103448277, -1.483390646153846 ], [ 29.743594137931034, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 481, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.483390646153846 ], [ 29.744133103448277, -1.483660123076923 ], [ 29.746019482758619, -1.483660123076923 ], [ 29.746019482758619, -1.483390646153846 ], [ 29.744133103448277, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 482, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.483390646153846 ], [ 29.746019482758619, -1.483660123076923 ], [ 29.746558448275863, -1.483660123076923 ], [ 29.746558448275863, -1.483390646153846 ], [ 29.746019482758619, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 483, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.483390646153846 ], [ 29.746558448275863, -1.483660123076923 ], [ 29.746827931034485, -1.483660123076923 ], [ 29.746827931034485, -1.483390646153846 ], [ 29.746558448275863, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 484, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.483390646153846 ], [ 29.746827931034485, -1.483660123076923 ], [ 29.747097413793103, -1.483660123076923 ], [ 29.747097413793103, -1.483390646153846 ], [ 29.746827931034485, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 485, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.483390646153846 ], [ 29.747097413793103, -1.483660123076923 ], [ 29.747366896551725, -1.483660123076923 ], [ 29.747366896551725, -1.483390646153846 ], [ 29.747097413793103, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 486, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.483390646153846 ], [ 29.747366896551725, -1.483660123076923 ], [ 29.747905862068965, -1.483660123076923 ], [ 29.747905862068965, -1.483390646153846 ], [ 29.747366896551725, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 487, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.483390646153846 ], [ 29.747905862068965, -1.483660123076923 ], [ 29.748175344827587, -1.483660123076923 ], [ 29.748175344827587, -1.483390646153846 ], [ 29.747905862068965, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 488, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.483390646153846 ], [ 29.748175344827587, -1.483660123076923 ], [ 29.748444827586209, -1.483660123076923 ], [ 29.748444827586209, -1.483390646153846 ], [ 29.748175344827587, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 489, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.483390646153846 ], [ 29.748444827586209, -1.483660123076923 ], [ 29.748714310344827, -1.483660123076923 ], [ 29.748714310344827, -1.483390646153846 ], [ 29.748444827586209, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 490, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.483390646153846 ], [ 29.748714310344827, -1.483660123076923 ], [ 29.748983793103449, -1.483660123076923 ], [ 29.748983793103449, -1.483390646153846 ], [ 29.748714310344827, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 491, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.483390646153846 ], [ 29.748983793103449, -1.483660123076923 ], [ 29.74925327586207, -1.483660123076923 ], [ 29.74925327586207, -1.483390646153846 ], [ 29.748983793103449, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 492, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.483390646153846 ], [ 29.74925327586207, -1.483660123076923 ], [ 29.749522758620689, -1.483660123076923 ], [ 29.749522758620689, -1.483390646153846 ], [ 29.74925327586207, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 493, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.483390646153846 ], [ 29.749522758620689, -1.483660123076923 ], [ 29.749792241379311, -1.483660123076923 ], [ 29.749792241379311, -1.483390646153846 ], [ 29.749522758620689, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 494, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.483390646153846 ], [ 29.749792241379311, -1.483660123076923 ], [ 29.750061724137932, -1.483660123076923 ], [ 29.750061724137932, -1.483390646153846 ], [ 29.749792241379311, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 495, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.483390646153846 ], [ 29.750061724137932, -1.483660123076923 ], [ 29.750331206896551, -1.483660123076923 ], [ 29.750331206896551, -1.483390646153846 ], [ 29.750061724137932, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 496, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.483390646153846 ], [ 29.750331206896551, -1.483660123076923 ], [ 29.750600689655172, -1.483660123076923 ], [ 29.750600689655172, -1.483390646153846 ], [ 29.750331206896551, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 497, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.483390646153846 ], [ 29.750600689655172, -1.483660123076923 ], [ 29.750870172413794, -1.483660123076923 ], [ 29.750870172413794, -1.483390646153846 ], [ 29.750600689655172, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 498, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.483390646153846 ], [ 29.750870172413794, -1.483660123076923 ], [ 29.751139655172413, -1.483660123076923 ], [ 29.751139655172413, -1.483390646153846 ], [ 29.750870172413794, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 499, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.483390646153846 ], [ 29.751139655172413, -1.483660123076923 ], [ 29.751409137931034, -1.483660123076923 ], [ 29.751409137931034, -1.483390646153846 ], [ 29.751139655172413, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 500, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.483390646153846 ], [ 29.751409137931034, -1.483660123076923 ], [ 29.751678620689656, -1.483660123076923 ], [ 29.751678620689656, -1.483390646153846 ], [ 29.751409137931034, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 501, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.483390646153846 ], [ 29.751678620689656, -1.483660123076923 ], [ 29.751948103448278, -1.483660123076923 ], [ 29.751948103448278, -1.483390646153846 ], [ 29.751678620689656, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 502, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.483390646153846 ], [ 29.751948103448278, -1.483660123076923 ], [ 29.752217586206896, -1.483660123076923 ], [ 29.752217586206896, -1.483390646153846 ], [ 29.751948103448278, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 503, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.483390646153846 ], [ 29.752217586206896, -1.483660123076923 ], [ 29.752487068965518, -1.483660123076923 ], [ 29.752487068965518, -1.483390646153846 ], [ 29.752217586206896, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 504, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.483390646153846 ], [ 29.752487068965518, -1.483660123076923 ], [ 29.75275655172414, -1.483660123076923 ], [ 29.75275655172414, -1.483390646153846 ], [ 29.752487068965518, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 505, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.483390646153846 ], [ 29.75275655172414, -1.483660123076923 ], [ 29.753026034482758, -1.483660123076923 ], [ 29.753026034482758, -1.483390646153846 ], [ 29.75275655172414, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 506, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.483390646153846 ], [ 29.753026034482758, -1.484199076923077 ], [ 29.75329551724138, -1.484199076923077 ], [ 29.75329551724138, -1.483390646153846 ], [ 29.753026034482758, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 507, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.483660123076923 ], [ 29.721227068965518, -1.4839296 ], [ 29.721766034482759, -1.4839296 ], [ 29.721766034482759, -1.483660123076923 ], [ 29.721227068965518, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 508, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.483660123076923 ], [ 29.721766034482759, -1.4839296 ], [ 29.72203551724138, -1.4839296 ], [ 29.72203551724138, -1.483660123076923 ], [ 29.721766034482759, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 509, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.483660123076923 ], [ 29.72203551724138, -1.4839296 ], [ 29.722305, -1.4839296 ], [ 29.722305, -1.483660123076923 ], [ 29.72203551724138, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 510, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.483660123076923 ], [ 29.722305, -1.4839296 ], [ 29.722843965517242, -1.4839296 ], [ 29.722843965517242, -1.483660123076923 ], [ 29.722305, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 511, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.483660123076923 ], [ 29.722843965517242, -1.4839296 ], [ 29.723113448275861, -1.4839296 ], [ 29.723113448275861, -1.483660123076923 ], [ 29.722843965517242, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 512, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.483660123076923 ], [ 29.723113448275861, -1.4839296 ], [ 29.723382931034482, -1.4839296 ], [ 29.723382931034482, -1.483660123076923 ], [ 29.723113448275861, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 513, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.483660123076923 ], [ 29.731736896551723, -1.485276984615385 ], [ 29.732006379310345, -1.485276984615385 ], [ 29.732006379310345, -1.4839296 ], [ 29.732275862068967, -1.4839296 ], [ 29.732275862068967, -1.483660123076923 ], [ 29.731736896551723, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 514, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.483660123076923 ], [ 29.732275862068967, -1.4839296 ], [ 29.732545344827585, -1.4839296 ], [ 29.732545344827585, -1.483660123076923 ], [ 29.732275862068967, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 515, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.483660123076923 ], [ 29.732545344827585, -1.4839296 ], [ 29.732814827586207, -1.4839296 ], [ 29.732814827586207, -1.483660123076923 ], [ 29.732545344827585, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 516, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.483660123076923 ], [ 29.732814827586207, -1.4839296 ], [ 29.733084310344829, -1.4839296 ], [ 29.733084310344829, -1.483660123076923 ], [ 29.732814827586207, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 517, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.483660123076923 ], [ 29.733084310344829, -1.4839296 ], [ 29.733353793103447, -1.4839296 ], [ 29.733353793103447, -1.483660123076923 ], [ 29.733084310344829, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 518, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.483660123076923 ], [ 29.733353793103447, -1.4839296 ], [ 29.733623275862069, -1.4839296 ], [ 29.733623275862069, -1.483660123076923 ], [ 29.733353793103447, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 519, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.483660123076923 ], [ 29.733623275862069, -1.4839296 ], [ 29.733892758620691, -1.4839296 ], [ 29.733892758620691, -1.483660123076923 ], [ 29.733623275862069, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 520, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.483660123076923 ], [ 29.733892758620691, -1.4839296 ], [ 29.734162241379309, -1.4839296 ], [ 29.734162241379309, -1.483660123076923 ], [ 29.733892758620691, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 521, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.483660123076923 ], [ 29.734162241379309, -1.4839296 ], [ 29.734431724137931, -1.4839296 ], [ 29.734431724137931, -1.483660123076923 ], [ 29.734162241379309, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 522, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.483660123076923 ], [ 29.734431724137931, -1.4839296 ], [ 29.734701206896553, -1.4839296 ], [ 29.734701206896553, -1.483660123076923 ], [ 29.734431724137931, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 523, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.483660123076923 ], [ 29.734701206896553, -1.4839296 ], [ 29.735240172413793, -1.4839296 ], [ 29.735240172413793, -1.483660123076923 ], [ 29.734701206896553, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 524, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.483660123076923 ], [ 29.735240172413793, -1.4839296 ], [ 29.735779137931036, -1.4839296 ], [ 29.735779137931036, -1.483660123076923 ], [ 29.735240172413793, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 525, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.483660123076923 ], [ 29.735779137931036, -1.4839296 ], [ 29.736048620689655, -1.4839296 ], [ 29.736048620689655, -1.483660123076923 ], [ 29.735779137931036, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 526, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.483660123076923 ], [ 29.736048620689655, -1.4839296 ], [ 29.739551896551724, -1.4839296 ], [ 29.739551896551724, -1.483660123076923 ], [ 29.736048620689655, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 527, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.483660123076923 ], [ 29.739551896551724, -1.4839296 ], [ 29.739821379310346, -1.4839296 ], [ 29.739821379310346, -1.483660123076923 ], [ 29.739551896551724, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 528, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.483660123076923 ], [ 29.739821379310346, -1.4839296 ], [ 29.74089931034483, -1.4839296 ], [ 29.74089931034483, -1.483660123076923 ], [ 29.739821379310346, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 529, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.483660123076923 ], [ 29.74089931034483, -1.4839296 ], [ 29.74143827586207, -1.4839296 ], [ 29.74143827586207, -1.483660123076923 ], [ 29.74089931034483, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 530, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.483660123076923 ], [ 29.74143827586207, -1.4839296 ], [ 29.741707758620691, -1.4839296 ], [ 29.741707758620691, -1.483660123076923 ], [ 29.74143827586207, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 531, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.483660123076923 ], [ 29.741707758620691, -1.4839296 ], [ 29.74197724137931, -1.4839296 ], [ 29.74197724137931, -1.483660123076923 ], [ 29.741707758620691, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 532, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.483660123076923 ], [ 29.74197724137931, -1.4839296 ], [ 29.742246724137932, -1.4839296 ], [ 29.742246724137932, -1.483660123076923 ], [ 29.74197724137931, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 533, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.483660123076923 ], [ 29.742246724137932, -1.4839296 ], [ 29.743324655172415, -1.4839296 ], [ 29.743324655172415, -1.483660123076923 ], [ 29.742246724137932, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 534, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.483660123076923 ], [ 29.743324655172415, -1.4839296 ], [ 29.743594137931034, -1.4839296 ], [ 29.743594137931034, -1.483660123076923 ], [ 29.743324655172415, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 535, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.483660123076923 ], [ 29.743594137931034, -1.4839296 ], [ 29.746558448275863, -1.4839296 ], [ 29.746558448275863, -1.483660123076923 ], [ 29.743594137931034, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 536, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.483660123076923 ], [ 29.746558448275863, -1.4839296 ], [ 29.746827931034485, -1.4839296 ], [ 29.746827931034485, -1.483660123076923 ], [ 29.746558448275863, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 537, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.483660123076923 ], [ 29.746827931034485, -1.4839296 ], [ 29.747097413793103, -1.4839296 ], [ 29.747097413793103, -1.483660123076923 ], [ 29.746827931034485, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 538, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.483660123076923 ], [ 29.747097413793103, -1.4839296 ], [ 29.747366896551725, -1.4839296 ], [ 29.747366896551725, -1.483660123076923 ], [ 29.747097413793103, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 539, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.483660123076923 ], [ 29.747366896551725, -1.4839296 ], [ 29.747905862068965, -1.4839296 ], [ 29.747905862068965, -1.483660123076923 ], [ 29.747366896551725, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 540, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.483660123076923 ], [ 29.747905862068965, -1.4839296 ], [ 29.748175344827587, -1.4839296 ], [ 29.748175344827587, -1.483660123076923 ], [ 29.747905862068965, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 541, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.483660123076923 ], [ 29.748175344827587, -1.4839296 ], [ 29.748444827586209, -1.4839296 ], [ 29.748444827586209, -1.483660123076923 ], [ 29.748175344827587, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 542, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.483660123076923 ], [ 29.748444827586209, -1.4839296 ], [ 29.748983793103449, -1.4839296 ], [ 29.748983793103449, -1.483660123076923 ], [ 29.748444827586209, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 543, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.483660123076923 ], [ 29.748983793103449, -1.4839296 ], [ 29.74925327586207, -1.4839296 ], [ 29.74925327586207, -1.483660123076923 ], [ 29.748983793103449, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 544, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.483660123076923 ], [ 29.74925327586207, -1.4839296 ], [ 29.749522758620689, -1.4839296 ], [ 29.749522758620689, -1.483660123076923 ], [ 29.74925327586207, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 545, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.483660123076923 ], [ 29.749522758620689, -1.4839296 ], [ 29.749792241379311, -1.4839296 ], [ 29.749792241379311, -1.483660123076923 ], [ 29.749522758620689, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 546, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.483660123076923 ], [ 29.749792241379311, -1.4839296 ], [ 29.750061724137932, -1.4839296 ], [ 29.750061724137932, -1.483660123076923 ], [ 29.749792241379311, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 547, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.483660123076923 ], [ 29.750061724137932, -1.4839296 ], [ 29.750331206896551, -1.4839296 ], [ 29.750331206896551, -1.483660123076923 ], [ 29.750061724137932, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 548, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.483660123076923 ], [ 29.750331206896551, -1.4839296 ], [ 29.750600689655172, -1.4839296 ], [ 29.750600689655172, -1.483660123076923 ], [ 29.750331206896551, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 549, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.483660123076923 ], [ 29.750600689655172, -1.4839296 ], [ 29.750870172413794, -1.4839296 ], [ 29.750870172413794, -1.483660123076923 ], [ 29.750600689655172, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 550, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.483660123076923 ], [ 29.750870172413794, -1.4839296 ], [ 29.751139655172413, -1.4839296 ], [ 29.751139655172413, -1.483660123076923 ], [ 29.750870172413794, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 551, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.483660123076923 ], [ 29.751139655172413, -1.4839296 ], [ 29.751409137931034, -1.4839296 ], [ 29.751409137931034, -1.483660123076923 ], [ 29.751139655172413, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 552, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.483660123076923 ], [ 29.751409137931034, -1.4839296 ], [ 29.751678620689656, -1.4839296 ], [ 29.751678620689656, -1.483660123076923 ], [ 29.751409137931034, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 553, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.483660123076923 ], [ 29.751678620689656, -1.4839296 ], [ 29.751948103448278, -1.4839296 ], [ 29.751948103448278, -1.483660123076923 ], [ 29.751678620689656, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 554, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.483660123076923 ], [ 29.751948103448278, -1.4839296 ], [ 29.752217586206896, -1.4839296 ], [ 29.752217586206896, -1.483660123076923 ], [ 29.751948103448278, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 555, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.483660123076923 ], [ 29.752217586206896, -1.4839296 ], [ 29.752487068965518, -1.4839296 ], [ 29.752487068965518, -1.483660123076923 ], [ 29.752217586206896, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 556, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.483660123076923 ], [ 29.752487068965518, -1.484468553846154 ], [ 29.75275655172414, -1.484468553846154 ], [ 29.75275655172414, -1.483660123076923 ], [ 29.752487068965518, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 557, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.483660123076923 ], [ 29.75275655172414, -1.484468553846154 ], [ 29.753026034482758, -1.484468553846154 ], [ 29.753026034482758, -1.483660123076923 ], [ 29.75275655172414, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 558, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.4839296 ], [ 29.720957586206897, -1.484199076923077 ], [ 29.721227068965518, -1.484199076923077 ], [ 29.721227068965518, -1.4839296 ], [ 29.720957586206897, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 559, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.4839296 ], [ 29.721227068965518, -1.484199076923077 ], [ 29.721496551724137, -1.484199076923077 ], [ 29.721496551724137, -1.4839296 ], [ 29.721227068965518, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 560, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.4839296 ], [ 29.721496551724137, -1.484199076923077 ], [ 29.72203551724138, -1.484199076923077 ], [ 29.72203551724138, -1.4839296 ], [ 29.721496551724137, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 561, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.4839296 ], [ 29.72203551724138, -1.484199076923077 ], [ 29.722305, -1.484199076923077 ], [ 29.722305, -1.4839296 ], [ 29.72203551724138, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 562, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.4839296 ], [ 29.722305, -1.484199076923077 ], [ 29.72257448275862, -1.484199076923077 ], [ 29.72257448275862, -1.4839296 ], [ 29.722305, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 563, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.4839296 ], [ 29.72257448275862, -1.484199076923077 ], [ 29.723113448275861, -1.484199076923077 ], [ 29.723113448275861, -1.4839296 ], [ 29.72257448275862, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 564, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.4839296 ], [ 29.732006379310345, -1.485276984615385 ], [ 29.732275862068967, -1.485276984615385 ], [ 29.732275862068967, -1.4839296 ], [ 29.732006379310345, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 565, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.4839296 ], [ 29.732275862068967, -1.484199076923077 ], [ 29.732545344827585, -1.484199076923077 ], [ 29.732545344827585, -1.4839296 ], [ 29.732275862068967, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 566, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.4839296 ], [ 29.732545344827585, -1.484199076923077 ], [ 29.732814827586207, -1.484199076923077 ], [ 29.732814827586207, -1.4839296 ], [ 29.732545344827585, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 567, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.4839296 ], [ 29.732814827586207, -1.484199076923077 ], [ 29.733084310344829, -1.484199076923077 ], [ 29.733084310344829, -1.4839296 ], [ 29.732814827586207, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 568, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.4839296 ], [ 29.733084310344829, -1.484199076923077 ], [ 29.733353793103447, -1.484199076923077 ], [ 29.733353793103447, -1.4839296 ], [ 29.733084310344829, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 569, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.4839296 ], [ 29.733353793103447, -1.484199076923077 ], [ 29.733623275862069, -1.484199076923077 ], [ 29.733623275862069, -1.4839296 ], [ 29.733353793103447, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 570, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.4839296 ], [ 29.733623275862069, -1.484199076923077 ], [ 29.733892758620691, -1.484199076923077 ], [ 29.733892758620691, -1.4839296 ], [ 29.733623275862069, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 571, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.4839296 ], [ 29.733892758620691, -1.484199076923077 ], [ 29.734162241379309, -1.484199076923077 ], [ 29.734162241379309, -1.4839296 ], [ 29.733892758620691, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 572, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.4839296 ], [ 29.734162241379309, -1.484199076923077 ], [ 29.734431724137931, -1.484199076923077 ], [ 29.734431724137931, -1.4839296 ], [ 29.734162241379309, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 573, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.4839296 ], [ 29.734431724137931, -1.484199076923077 ], [ 29.735509655172415, -1.484199076923077 ], [ 29.735509655172415, -1.4839296 ], [ 29.734431724137931, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 574, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.4839296 ], [ 29.735509655172415, -1.484199076923077 ], [ 29.735779137931036, -1.484199076923077 ], [ 29.735779137931036, -1.4839296 ], [ 29.735509655172415, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 575, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.4839296 ], [ 29.735779137931036, -1.484199076923077 ], [ 29.736318103448276, -1.484199076923077 ], [ 29.736318103448276, -1.4839296 ], [ 29.735779137931036, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 576, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.4839296 ], [ 29.736318103448276, -1.484199076923077 ], [ 29.739282413793102, -1.484199076923077 ], [ 29.739282413793102, -1.4839296 ], [ 29.736318103448276, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 577, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.4839296 ], [ 29.739282413793102, -1.484199076923077 ], [ 29.739821379310346, -1.484199076923077 ], [ 29.739821379310346, -1.4839296 ], [ 29.739282413793102, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 578, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.4839296 ], [ 29.739821379310346, -1.484199076923077 ], [ 29.740090862068964, -1.484199076923077 ], [ 29.740090862068964, -1.4839296 ], [ 29.739821379310346, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 579, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.4839296 ], [ 29.740090862068964, -1.484199076923077 ], [ 29.74143827586207, -1.484199076923077 ], [ 29.74143827586207, -1.4839296 ], [ 29.740090862068964, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 580, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.4839296 ], [ 29.74143827586207, -1.484199076923077 ], [ 29.741707758620691, -1.484199076923077 ], [ 29.741707758620691, -1.4839296 ], [ 29.74143827586207, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 581, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.4839296 ], [ 29.741707758620691, -1.484199076923077 ], [ 29.74197724137931, -1.484199076923077 ], [ 29.74197724137931, -1.4839296 ], [ 29.741707758620691, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 582, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.4839296 ], [ 29.74197724137931, -1.484199076923077 ], [ 29.742246724137932, -1.484199076923077 ], [ 29.742246724137932, -1.4839296 ], [ 29.74197724137931, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 583, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.4839296 ], [ 29.742246724137932, -1.484199076923077 ], [ 29.743324655172415, -1.484199076923077 ], [ 29.743324655172415, -1.4839296 ], [ 29.742246724137932, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 584, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.4839296 ], [ 29.743324655172415, -1.484199076923077 ], [ 29.743863620689655, -1.484199076923077 ], [ 29.743863620689655, -1.4839296 ], [ 29.743324655172415, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 585, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.4839296 ], [ 29.743863620689655, -1.484199076923077 ], [ 29.746288965517241, -1.484199076923077 ], [ 29.746288965517241, -1.4839296 ], [ 29.743863620689655, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 586, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.4839296 ], [ 29.746288965517241, -1.484199076923077 ], [ 29.746827931034485, -1.484199076923077 ], [ 29.746827931034485, -1.4839296 ], [ 29.746288965517241, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 587, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.4839296 ], [ 29.746827931034485, -1.484199076923077 ], [ 29.747097413793103, -1.484199076923077 ], [ 29.747097413793103, -1.4839296 ], [ 29.746827931034485, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 588, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.4839296 ], [ 29.747097413793103, -1.484199076923077 ], [ 29.747366896551725, -1.484199076923077 ], [ 29.747366896551725, -1.4839296 ], [ 29.747097413793103, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 589, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.4839296 ], [ 29.747366896551725, -1.484199076923077 ], [ 29.747905862068965, -1.484199076923077 ], [ 29.747905862068965, -1.4839296 ], [ 29.747366896551725, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 590, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.4839296 ], [ 29.747905862068965, -1.484199076923077 ], [ 29.748175344827587, -1.484199076923077 ], [ 29.748175344827587, -1.4839296 ], [ 29.747905862068965, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 591, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.4839296 ], [ 29.748175344827587, -1.484199076923077 ], [ 29.748444827586209, -1.484199076923077 ], [ 29.748444827586209, -1.4839296 ], [ 29.748175344827587, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 592, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.4839296 ], [ 29.748444827586209, -1.484199076923077 ], [ 29.748714310344827, -1.484199076923077 ], [ 29.748714310344827, -1.4839296 ], [ 29.748444827586209, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 593, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.4839296 ], [ 29.748714310344827, -1.484199076923077 ], [ 29.748983793103449, -1.484199076923077 ], [ 29.748983793103449, -1.4839296 ], [ 29.748714310344827, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 594, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.4839296 ], [ 29.748983793103449, -1.484199076923077 ], [ 29.74925327586207, -1.484199076923077 ], [ 29.74925327586207, -1.4839296 ], [ 29.748983793103449, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 595, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.4839296 ], [ 29.74925327586207, -1.484199076923077 ], [ 29.749522758620689, -1.484199076923077 ], [ 29.749522758620689, -1.4839296 ], [ 29.74925327586207, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 596, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.4839296 ], [ 29.749522758620689, -1.484199076923077 ], [ 29.749792241379311, -1.484199076923077 ], [ 29.749792241379311, -1.4839296 ], [ 29.749522758620689, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 597, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.4839296 ], [ 29.749792241379311, -1.484199076923077 ], [ 29.750061724137932, -1.484199076923077 ], [ 29.750061724137932, -1.4839296 ], [ 29.749792241379311, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 598, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.4839296 ], [ 29.750061724137932, -1.484199076923077 ], [ 29.750331206896551, -1.484199076923077 ], [ 29.750331206896551, -1.4839296 ], [ 29.750061724137932, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 599, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.4839296 ], [ 29.750331206896551, -1.484199076923077 ], [ 29.750600689655172, -1.484199076923077 ], [ 29.750600689655172, -1.4839296 ], [ 29.750331206896551, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 600, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.4839296 ], [ 29.750600689655172, -1.484199076923077 ], [ 29.750870172413794, -1.484199076923077 ], [ 29.750870172413794, -1.4839296 ], [ 29.750600689655172, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 601, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.4839296 ], [ 29.750870172413794, -1.484199076923077 ], [ 29.751139655172413, -1.484199076923077 ], [ 29.751139655172413, -1.4839296 ], [ 29.750870172413794, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 602, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.4839296 ], [ 29.751139655172413, -1.484199076923077 ], [ 29.751409137931034, -1.484199076923077 ], [ 29.751409137931034, -1.4839296 ], [ 29.751139655172413, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 603, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.4839296 ], [ 29.751409137931034, -1.484199076923077 ], [ 29.751678620689656, -1.484199076923077 ], [ 29.751678620689656, -1.4839296 ], [ 29.751409137931034, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 604, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.4839296 ], [ 29.751678620689656, -1.484199076923077 ], [ 29.751948103448278, -1.484199076923077 ], [ 29.751948103448278, -1.4839296 ], [ 29.751678620689656, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 605, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.4839296 ], [ 29.751948103448278, -1.484468553846154 ], [ 29.752217586206896, -1.484468553846154 ], [ 29.752217586206896, -1.4839296 ], [ 29.751948103448278, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 606, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.4839296 ], [ 29.752217586206896, -1.484199076923077 ], [ 29.752487068965518, -1.484199076923077 ], [ 29.752487068965518, -1.4839296 ], [ 29.752217586206896, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 607, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.484199076923077 ], [ 29.720149137931035, -1.484468553846154 ], [ 29.720957586206897, -1.484468553846154 ], [ 29.720957586206897, -1.484199076923077 ], [ 29.720149137931035, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 608, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.484199076923077 ], [ 29.720957586206897, -1.484468553846154 ], [ 29.721227068965518, -1.484468553846154 ], [ 29.721227068965518, -1.484199076923077 ], [ 29.720957586206897, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 609, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.484199076923077 ], [ 29.721227068965518, -1.484468553846154 ], [ 29.721496551724137, -1.484468553846154 ], [ 29.721496551724137, -1.484199076923077 ], [ 29.721227068965518, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 610, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.484199076923077 ], [ 29.721496551724137, -1.484468553846154 ], [ 29.72203551724138, -1.484468553846154 ], [ 29.72203551724138, -1.484199076923077 ], [ 29.721496551724137, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 611, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.484199076923077 ], [ 29.72203551724138, -1.484468553846154 ], [ 29.722305, -1.484468553846154 ], [ 29.722305, -1.484199076923077 ], [ 29.72203551724138, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 612, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.484199076923077 ], [ 29.722305, -1.484468553846154 ], [ 29.72257448275862, -1.484468553846154 ], [ 29.72257448275862, -1.484199076923077 ], [ 29.722305, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 613, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.484199076923077 ], [ 29.72257448275862, -1.484468553846154 ], [ 29.722843965517242, -1.484468553846154 ], [ 29.722843965517242, -1.484199076923077 ], [ 29.72257448275862, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 614, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.484199076923077 ], [ 29.722843965517242, -1.484468553846154 ], [ 29.723113448275861, -1.484468553846154 ], [ 29.723113448275861, -1.484199076923077 ], [ 29.722843965517242, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 615, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.484199076923077 ], [ 29.732275862068967, -1.485276984615385 ], [ 29.732545344827585, -1.485276984615385 ], [ 29.732545344827585, -1.484199076923077 ], [ 29.732275862068967, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 616, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.484199076923077 ], [ 29.732545344827585, -1.484468553846154 ], [ 29.732814827586207, -1.484468553846154 ], [ 29.732814827586207, -1.484199076923077 ], [ 29.732545344827585, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 617, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.484199076923077 ], [ 29.732814827586207, -1.484468553846154 ], [ 29.733084310344829, -1.484468553846154 ], [ 29.733084310344829, -1.484199076923077 ], [ 29.732814827586207, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 618, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.484199076923077 ], [ 29.733084310344829, -1.484468553846154 ], [ 29.733353793103447, -1.484468553846154 ], [ 29.733353793103447, -1.484199076923077 ], [ 29.733084310344829, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 619, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.484199076923077 ], [ 29.733353793103447, -1.484468553846154 ], [ 29.733623275862069, -1.484468553846154 ], [ 29.733623275862069, -1.484199076923077 ], [ 29.733353793103447, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 620, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.484199076923077 ], [ 29.733623275862069, -1.484468553846154 ], [ 29.733892758620691, -1.484468553846154 ], [ 29.733892758620691, -1.484199076923077 ], [ 29.733623275862069, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 621, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.484199076923077 ], [ 29.733892758620691, -1.484468553846154 ], [ 29.734162241379309, -1.484468553846154 ], [ 29.734162241379309, -1.484199076923077 ], [ 29.733892758620691, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 622, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.484199076923077 ], [ 29.734162241379309, -1.484468553846154 ], [ 29.734431724137931, -1.484468553846154 ], [ 29.734431724137931, -1.484199076923077 ], [ 29.734162241379309, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 623, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.484199076923077 ], [ 29.734431724137931, -1.484468553846154 ], [ 29.734701206896553, -1.484468553846154 ], [ 29.734701206896553, -1.484199076923077 ], [ 29.734431724137931, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 624, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.484199076923077 ], [ 29.734701206896553, -1.484468553846154 ], [ 29.734970689655171, -1.484468553846154 ], [ 29.734970689655171, -1.484199076923077 ], [ 29.734701206896553, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 625, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.484199076923077 ], [ 29.734970689655171, -1.484468553846154 ], [ 29.735509655172415, -1.484468553846154 ], [ 29.735509655172415, -1.484199076923077 ], [ 29.734970689655171, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 626, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.484199076923077 ], [ 29.735509655172415, -1.484468553846154 ], [ 29.735779137931036, -1.484468553846154 ], [ 29.735779137931036, -1.484199076923077 ], [ 29.735509655172415, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 627, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.484199076923077 ], [ 29.735779137931036, -1.484468553846154 ], [ 29.739821379310346, -1.484468553846154 ], [ 29.739821379310346, -1.484199076923077 ], [ 29.735779137931036, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 628, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.484199076923077 ], [ 29.739821379310346, -1.484468553846154 ], [ 29.740090862068964, -1.484468553846154 ], [ 29.740090862068964, -1.484199076923077 ], [ 29.739821379310346, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 629, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.484199076923077 ], [ 29.740090862068964, -1.484468553846154 ], [ 29.741168793103448, -1.484468553846154 ], [ 29.741168793103448, -1.484199076923077 ], [ 29.740090862068964, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 630, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.484199076923077 ], [ 29.741168793103448, -1.484468553846154 ], [ 29.741707758620691, -1.484468553846154 ], [ 29.741707758620691, -1.484199076923077 ], [ 29.741168793103448, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 631, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.484199076923077 ], [ 29.741707758620691, -1.484468553846154 ], [ 29.74197724137931, -1.484468553846154 ], [ 29.74197724137931, -1.484199076923077 ], [ 29.741707758620691, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 632, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.484199076923077 ], [ 29.74197724137931, -1.484468553846154 ], [ 29.742246724137932, -1.484468553846154 ], [ 29.742246724137932, -1.484199076923077 ], [ 29.74197724137931, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 633, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.484199076923077 ], [ 29.742246724137932, -1.484468553846154 ], [ 29.742516206896553, -1.484468553846154 ], [ 29.742516206896553, -1.484199076923077 ], [ 29.742246724137932, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 634, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.484199076923077 ], [ 29.742516206896553, -1.484468553846154 ], [ 29.743055172413793, -1.484468553846154 ], [ 29.743055172413793, -1.484199076923077 ], [ 29.742516206896553, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 635, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.484199076923077 ], [ 29.743055172413793, -1.484468553846154 ], [ 29.743594137931034, -1.484468553846154 ], [ 29.743594137931034, -1.484199076923077 ], [ 29.743055172413793, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 636, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.484199076923077 ], [ 29.743594137931034, -1.484468553846154 ], [ 29.744133103448277, -1.484468553846154 ], [ 29.744133103448277, -1.484199076923077 ], [ 29.743594137931034, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 637, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.484199076923077 ], [ 29.744133103448277, -1.484468553846154 ], [ 29.746019482758619, -1.484468553846154 ], [ 29.746019482758619, -1.484199076923077 ], [ 29.744133103448277, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 638, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.484199076923077 ], [ 29.746019482758619, -1.484468553846154 ], [ 29.746558448275863, -1.484468553846154 ], [ 29.746558448275863, -1.484199076923077 ], [ 29.746019482758619, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 639, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.484199076923077 ], [ 29.746558448275863, -1.484468553846154 ], [ 29.747097413793103, -1.484468553846154 ], [ 29.747097413793103, -1.484199076923077 ], [ 29.746558448275863, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 640, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.484199076923077 ], [ 29.747097413793103, -1.484468553846154 ], [ 29.747366896551725, -1.484468553846154 ], [ 29.747366896551725, -1.484199076923077 ], [ 29.747097413793103, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 641, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.484199076923077 ], [ 29.747366896551725, -1.484468553846154 ], [ 29.747905862068965, -1.484468553846154 ], [ 29.747905862068965, -1.484199076923077 ], [ 29.747366896551725, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 642, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.484199076923077 ], [ 29.747905862068965, -1.484468553846154 ], [ 29.748175344827587, -1.484468553846154 ], [ 29.748175344827587, -1.484199076923077 ], [ 29.747905862068965, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 643, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.484199076923077 ], [ 29.748175344827587, -1.484468553846154 ], [ 29.748444827586209, -1.484468553846154 ], [ 29.748444827586209, -1.484199076923077 ], [ 29.748175344827587, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 644, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.484199076923077 ], [ 29.748444827586209, -1.484468553846154 ], [ 29.748714310344827, -1.484468553846154 ], [ 29.748714310344827, -1.484199076923077 ], [ 29.748444827586209, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 645, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.484199076923077 ], [ 29.748714310344827, -1.484468553846154 ], [ 29.748983793103449, -1.484468553846154 ], [ 29.748983793103449, -1.484199076923077 ], [ 29.748714310344827, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 646, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.484199076923077 ], [ 29.748983793103449, -1.484468553846154 ], [ 29.74925327586207, -1.484468553846154 ], [ 29.74925327586207, -1.484199076923077 ], [ 29.748983793103449, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 647, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.484199076923077 ], [ 29.74925327586207, -1.484468553846154 ], [ 29.749522758620689, -1.484468553846154 ], [ 29.749522758620689, -1.484199076923077 ], [ 29.74925327586207, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 648, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.484199076923077 ], [ 29.749522758620689, -1.484468553846154 ], [ 29.749792241379311, -1.484468553846154 ], [ 29.749792241379311, -1.484199076923077 ], [ 29.749522758620689, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 649, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.484199076923077 ], [ 29.749792241379311, -1.484468553846154 ], [ 29.750061724137932, -1.484468553846154 ], [ 29.750061724137932, -1.484199076923077 ], [ 29.749792241379311, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 650, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.484199076923077 ], [ 29.750061724137932, -1.484468553846154 ], [ 29.750331206896551, -1.484468553846154 ], [ 29.750331206896551, -1.484199076923077 ], [ 29.750061724137932, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 651, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.484199076923077 ], [ 29.750331206896551, -1.484468553846154 ], [ 29.750600689655172, -1.484468553846154 ], [ 29.750600689655172, -1.484199076923077 ], [ 29.750331206896551, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 652, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.484199076923077 ], [ 29.750600689655172, -1.484468553846154 ], [ 29.750870172413794, -1.484468553846154 ], [ 29.750870172413794, -1.484199076923077 ], [ 29.750600689655172, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 653, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.484199076923077 ], [ 29.750870172413794, -1.484468553846154 ], [ 29.751139655172413, -1.484468553846154 ], [ 29.751139655172413, -1.484199076923077 ], [ 29.750870172413794, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 654, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.484199076923077 ], [ 29.751139655172413, -1.484468553846154 ], [ 29.751409137931034, -1.484468553846154 ], [ 29.751409137931034, -1.484199076923077 ], [ 29.751139655172413, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 655, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.484199076923077 ], [ 29.751409137931034, -1.484468553846154 ], [ 29.751678620689656, -1.484468553846154 ], [ 29.751678620689656, -1.484199076923077 ], [ 29.751409137931034, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 656, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.484199076923077 ], [ 29.751678620689656, -1.484468553846154 ], [ 29.751948103448278, -1.484468553846154 ], [ 29.751948103448278, -1.484199076923077 ], [ 29.751678620689656, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 657, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.484199076923077 ], [ 29.752217586206896, -1.484738030769231 ], [ 29.752487068965518, -1.484738030769231 ], [ 29.752487068965518, -1.484199076923077 ], [ 29.752217586206896, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 658, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.484199076923077 ], [ 29.753026034482758, -1.484468553846154 ], [ 29.75329551724138, -1.484468553846154 ], [ 29.75329551724138, -1.484199076923077 ], [ 29.753026034482758, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 659, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.484199076923077 ], [ 29.75329551724138, -1.485276984615385 ], [ 29.753565, -1.485276984615385 ], [ 29.753565, -1.484199076923077 ], [ 29.75329551724138, -1.484199076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 660, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.484468553846154 ], [ 29.719879655172413, -1.484738030769231 ], [ 29.720149137931035, -1.484738030769231 ], [ 29.720149137931035, -1.484468553846154 ], [ 29.719879655172413, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 661, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.484468553846154 ], [ 29.720149137931035, -1.484738030769231 ], [ 29.720688103448275, -1.484738030769231 ], [ 29.720688103448275, -1.484468553846154 ], [ 29.720149137931035, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 662, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.484468553846154 ], [ 29.720688103448275, -1.484738030769231 ], [ 29.721227068965518, -1.484738030769231 ], [ 29.721227068965518, -1.484468553846154 ], [ 29.720688103448275, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 663, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.484468553846154 ], [ 29.721227068965518, -1.484738030769231 ], [ 29.721496551724137, -1.484738030769231 ], [ 29.721496551724137, -1.484468553846154 ], [ 29.721227068965518, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 664, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.484468553846154 ], [ 29.721496551724137, -1.484738030769231 ], [ 29.721766034482759, -1.484738030769231 ], [ 29.721766034482759, -1.484468553846154 ], [ 29.721496551724137, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 665, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.484468553846154 ], [ 29.721766034482759, -1.484738030769231 ], [ 29.722305, -1.484738030769231 ], [ 29.722305, -1.484468553846154 ], [ 29.721766034482759, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 666, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.484468553846154 ], [ 29.722305, -1.484738030769231 ], [ 29.72257448275862, -1.484738030769231 ], [ 29.72257448275862, -1.484468553846154 ], [ 29.722305, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 667, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.484468553846154 ], [ 29.72257448275862, -1.484738030769231 ], [ 29.722843965517242, -1.484738030769231 ], [ 29.722843965517242, -1.484468553846154 ], [ 29.72257448275862, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 668, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.484468553846154 ], [ 29.732545344827585, -1.485546461538461 ], [ 29.732814827586207, -1.485546461538461 ], [ 29.732814827586207, -1.484468553846154 ], [ 29.732545344827585, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 669, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.484468553846154 ], [ 29.732814827586207, -1.485546461538461 ], [ 29.733084310344829, -1.485546461538461 ], [ 29.733084310344829, -1.484468553846154 ], [ 29.732814827586207, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 670, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.484468553846154 ], [ 29.733084310344829, -1.484738030769231 ], [ 29.733353793103447, -1.484738030769231 ], [ 29.733353793103447, -1.484468553846154 ], [ 29.733084310344829, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 671, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.484468553846154 ], [ 29.733353793103447, -1.484738030769231 ], [ 29.733623275862069, -1.484738030769231 ], [ 29.733623275862069, -1.484468553846154 ], [ 29.733353793103447, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 672, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.484468553846154 ], [ 29.733623275862069, -1.484738030769231 ], [ 29.733892758620691, -1.484738030769231 ], [ 29.733892758620691, -1.484468553846154 ], [ 29.733623275862069, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 673, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.484468553846154 ], [ 29.733892758620691, -1.484738030769231 ], [ 29.734162241379309, -1.484738030769231 ], [ 29.734162241379309, -1.484468553846154 ], [ 29.733892758620691, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 674, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.484468553846154 ], [ 29.734162241379309, -1.484738030769231 ], [ 29.734431724137931, -1.484738030769231 ], [ 29.734431724137931, -1.484468553846154 ], [ 29.734162241379309, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 675, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.484468553846154 ], [ 29.734431724137931, -1.484738030769231 ], [ 29.734701206896553, -1.484738030769231 ], [ 29.734701206896553, -1.484468553846154 ], [ 29.734431724137931, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 676, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.484468553846154 ], [ 29.734701206896553, -1.484738030769231 ], [ 29.735240172413793, -1.484738030769231 ], [ 29.735240172413793, -1.484468553846154 ], [ 29.734701206896553, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 677, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.484468553846154 ], [ 29.735240172413793, -1.484738030769231 ], [ 29.735509655172415, -1.484738030769231 ], [ 29.735509655172415, -1.484468553846154 ], [ 29.735240172413793, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 678, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.484468553846154 ], [ 29.735509655172415, -1.484738030769231 ], [ 29.736048620689655, -1.484738030769231 ], [ 29.736048620689655, -1.484468553846154 ], [ 29.735509655172415, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 679, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.484468553846154 ], [ 29.736048620689655, -1.484738030769231 ], [ 29.739551896551724, -1.484738030769231 ], [ 29.739551896551724, -1.484468553846154 ], [ 29.736048620689655, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 680, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.484468553846154 ], [ 29.739551896551724, -1.484738030769231 ], [ 29.740090862068964, -1.484738030769231 ], [ 29.740090862068964, -1.484468553846154 ], [ 29.739551896551724, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 681, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.484468553846154 ], [ 29.740090862068964, -1.484738030769231 ], [ 29.74089931034483, -1.484738030769231 ], [ 29.74089931034483, -1.484468553846154 ], [ 29.740090862068964, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 682, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.484468553846154 ], [ 29.74089931034483, -1.484738030769231 ], [ 29.74143827586207, -1.484738030769231 ], [ 29.74143827586207, -1.484468553846154 ], [ 29.74089931034483, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 683, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.484468553846154 ], [ 29.74143827586207, -1.484738030769231 ], [ 29.74197724137931, -1.484738030769231 ], [ 29.74197724137931, -1.484468553846154 ], [ 29.74143827586207, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 684, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.484468553846154 ], [ 29.74197724137931, -1.484738030769231 ], [ 29.742246724137932, -1.484738030769231 ], [ 29.742246724137932, -1.484468553846154 ], [ 29.74197724137931, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 685, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.484468553846154 ], [ 29.742246724137932, -1.484738030769231 ], [ 29.742516206896553, -1.484738030769231 ], [ 29.742516206896553, -1.484468553846154 ], [ 29.742246724137932, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 686, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.484468553846154 ], [ 29.742516206896553, -1.484738030769231 ], [ 29.743324655172415, -1.484738030769231 ], [ 29.743324655172415, -1.484468553846154 ], [ 29.742516206896553, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 687, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.484468553846154 ], [ 29.743324655172415, -1.484738030769231 ], [ 29.743594137931034, -1.484738030769231 ], [ 29.743594137931034, -1.484468553846154 ], [ 29.743324655172415, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 688, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.484468553846154 ], [ 29.743594137931034, -1.484738030769231 ], [ 29.746558448275863, -1.484738030769231 ], [ 29.746558448275863, -1.484468553846154 ], [ 29.743594137931034, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 689, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.484468553846154 ], [ 29.746558448275863, -1.484738030769231 ], [ 29.746827931034485, -1.484738030769231 ], [ 29.746827931034485, -1.484468553846154 ], [ 29.746558448275863, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 690, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.484468553846154 ], [ 29.746827931034485, -1.484738030769231 ], [ 29.747366896551725, -1.484738030769231 ], [ 29.747366896551725, -1.484468553846154 ], [ 29.746827931034485, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 691, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.484468553846154 ], [ 29.747366896551725, -1.484738030769231 ], [ 29.747905862068965, -1.484738030769231 ], [ 29.747905862068965, -1.484468553846154 ], [ 29.747366896551725, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 692, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.484468553846154 ], [ 29.747905862068965, -1.484738030769231 ], [ 29.748175344827587, -1.484738030769231 ], [ 29.748175344827587, -1.484468553846154 ], [ 29.747905862068965, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 693, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.484468553846154 ], [ 29.748175344827587, -1.484738030769231 ], [ 29.748714310344827, -1.484738030769231 ], [ 29.748714310344827, -1.484468553846154 ], [ 29.748175344827587, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 694, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.484468553846154 ], [ 29.748714310344827, -1.484738030769231 ], [ 29.748983793103449, -1.484738030769231 ], [ 29.748983793103449, -1.484468553846154 ], [ 29.748714310344827, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 695, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.484468553846154 ], [ 29.748983793103449, -1.484738030769231 ], [ 29.74925327586207, -1.484738030769231 ], [ 29.74925327586207, -1.484468553846154 ], [ 29.748983793103449, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 696, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.484468553846154 ], [ 29.74925327586207, -1.484738030769231 ], [ 29.749522758620689, -1.484738030769231 ], [ 29.749522758620689, -1.484468553846154 ], [ 29.74925327586207, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 697, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.484468553846154 ], [ 29.749522758620689, -1.484738030769231 ], [ 29.749792241379311, -1.484738030769231 ], [ 29.749792241379311, -1.484468553846154 ], [ 29.749522758620689, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 698, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.484468553846154 ], [ 29.749792241379311, -1.484738030769231 ], [ 29.750061724137932, -1.484738030769231 ], [ 29.750061724137932, -1.484468553846154 ], [ 29.749792241379311, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 699, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.484468553846154 ], [ 29.750061724137932, -1.484738030769231 ], [ 29.750331206896551, -1.484738030769231 ], [ 29.750331206896551, -1.484468553846154 ], [ 29.750061724137932, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 700, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.484468553846154 ], [ 29.750331206896551, -1.484738030769231 ], [ 29.750600689655172, -1.484738030769231 ], [ 29.750600689655172, -1.484468553846154 ], [ 29.750331206896551, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 701, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.484468553846154 ], [ 29.750600689655172, -1.484738030769231 ], [ 29.750870172413794, -1.484738030769231 ], [ 29.750870172413794, -1.484468553846154 ], [ 29.750600689655172, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 702, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.484468553846154 ], [ 29.750870172413794, -1.484738030769231 ], [ 29.751139655172413, -1.484738030769231 ], [ 29.751139655172413, -1.484468553846154 ], [ 29.750870172413794, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 703, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.484468553846154 ], [ 29.751139655172413, -1.484738030769231 ], [ 29.751409137931034, -1.484738030769231 ], [ 29.751409137931034, -1.484468553846154 ], [ 29.751139655172413, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 704, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.484468553846154 ], [ 29.751409137931034, -1.484738030769231 ], [ 29.751678620689656, -1.484738030769231 ], [ 29.751678620689656, -1.484468553846154 ], [ 29.751409137931034, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 705, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.484468553846154 ], [ 29.751678620689656, -1.484738030769231 ], [ 29.751948103448278, -1.484738030769231 ], [ 29.751948103448278, -1.484468553846154 ], [ 29.751678620689656, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 706, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.484468553846154 ], [ 29.751948103448278, -1.484738030769231 ], [ 29.752217586206896, -1.484738030769231 ], [ 29.752217586206896, -1.484468553846154 ], [ 29.751948103448278, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 707, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.484468553846154 ], [ 29.752487068965518, -1.484738030769231 ], [ 29.75275655172414, -1.484738030769231 ], [ 29.75275655172414, -1.484468553846154 ], [ 29.752487068965518, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 708, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.484468553846154 ], [ 29.75275655172414, -1.485546461538461 ], [ 29.753026034482758, -1.485546461538461 ], [ 29.753026034482758, -1.484468553846154 ], [ 29.75275655172414, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 709, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.484468553846154 ], [ 29.753026034482758, -1.485546461538461 ], [ 29.75329551724138, -1.485546461538461 ], [ 29.75329551724138, -1.484468553846154 ], [ 29.753026034482758, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 710, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.484738030769231 ], [ 29.719610172413795, -1.485007507692308 ], [ 29.719879655172413, -1.485007507692308 ], [ 29.719879655172413, -1.484738030769231 ], [ 29.719610172413795, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 711, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.484738030769231 ], [ 29.719879655172413, -1.485007507692308 ], [ 29.720149137931035, -1.485007507692308 ], [ 29.720149137931035, -1.484738030769231 ], [ 29.719879655172413, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 712, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.484738030769231 ], [ 29.720149137931035, -1.485007507692308 ], [ 29.720688103448275, -1.485007507692308 ], [ 29.720688103448275, -1.484738030769231 ], [ 29.720149137931035, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 713, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.484738030769231 ], [ 29.720688103448275, -1.485007507692308 ], [ 29.721227068965518, -1.485007507692308 ], [ 29.721227068965518, -1.484738030769231 ], [ 29.720688103448275, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 714, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.484738030769231 ], [ 29.721227068965518, -1.485007507692308 ], [ 29.721496551724137, -1.485007507692308 ], [ 29.721496551724137, -1.484738030769231 ], [ 29.721227068965518, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 715, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.484738030769231 ], [ 29.721496551724137, -1.485007507692308 ], [ 29.721766034482759, -1.485007507692308 ], [ 29.721766034482759, -1.484738030769231 ], [ 29.721496551724137, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 716, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.484738030769231 ], [ 29.721766034482759, -1.485007507692308 ], [ 29.72203551724138, -1.485007507692308 ], [ 29.72203551724138, -1.484738030769231 ], [ 29.721766034482759, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 717, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.484738030769231 ], [ 29.72203551724138, -1.485007507692308 ], [ 29.722305, -1.485007507692308 ], [ 29.722305, -1.484738030769231 ], [ 29.72203551724138, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 718, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.484738030769231 ], [ 29.722305, -1.485007507692308 ], [ 29.72257448275862, -1.485007507692308 ], [ 29.72257448275862, -1.484738030769231 ], [ 29.722305, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 719, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.484738030769231 ], [ 29.72257448275862, -1.485007507692308 ], [ 29.722843965517242, -1.485007507692308 ], [ 29.722843965517242, -1.484738030769231 ], [ 29.72257448275862, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 720, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.484738030769231 ], [ 29.733084310344829, -1.485276984615385 ], [ 29.733353793103447, -1.485276984615385 ], [ 29.733353793103447, -1.484738030769231 ], [ 29.733084310344829, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 721, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.484738030769231 ], [ 29.733353793103447, -1.485007507692308 ], [ 29.733623275862069, -1.485007507692308 ], [ 29.733623275862069, -1.484738030769231 ], [ 29.733353793103447, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 722, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.484738030769231 ], [ 29.733623275862069, -1.485007507692308 ], [ 29.733892758620691, -1.485007507692308 ], [ 29.733892758620691, -1.484738030769231 ], [ 29.733623275862069, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 723, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.484738030769231 ], [ 29.733892758620691, -1.485007507692308 ], [ 29.734162241379309, -1.485007507692308 ], [ 29.734162241379309, -1.484738030769231 ], [ 29.733892758620691, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 724, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.484738030769231 ], [ 29.734162241379309, -1.485007507692308 ], [ 29.734431724137931, -1.485007507692308 ], [ 29.734431724137931, -1.484738030769231 ], [ 29.734162241379309, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 725, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.484738030769231 ], [ 29.734431724137931, -1.485007507692308 ], [ 29.734701206896553, -1.485007507692308 ], [ 29.734701206896553, -1.484738030769231 ], [ 29.734431724137931, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 726, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.484738030769231 ], [ 29.734701206896553, -1.485007507692308 ], [ 29.735240172413793, -1.485007507692308 ], [ 29.735240172413793, -1.484738030769231 ], [ 29.734701206896553, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 727, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.484738030769231 ], [ 29.735240172413793, -1.485007507692308 ], [ 29.735779137931036, -1.485007507692308 ], [ 29.735779137931036, -1.484738030769231 ], [ 29.735240172413793, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 728, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.484738030769231 ], [ 29.735779137931036, -1.485007507692308 ], [ 29.736318103448276, -1.485007507692308 ], [ 29.736318103448276, -1.484738030769231 ], [ 29.735779137931036, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 729, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.484738030769231 ], [ 29.736318103448276, -1.485007507692308 ], [ 29.739282413793102, -1.485007507692308 ], [ 29.739282413793102, -1.484738030769231 ], [ 29.736318103448276, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 730, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.484738030769231 ], [ 29.739282413793102, -1.485007507692308 ], [ 29.739821379310346, -1.485007507692308 ], [ 29.739821379310346, -1.484738030769231 ], [ 29.739282413793102, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 731, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.484738030769231 ], [ 29.739821379310346, -1.485007507692308 ], [ 29.740360344827586, -1.485007507692308 ], [ 29.740360344827586, -1.484738030769231 ], [ 29.739821379310346, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 732, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.484738030769231 ], [ 29.740360344827586, -1.485007507692308 ], [ 29.74143827586207, -1.485007507692308 ], [ 29.74143827586207, -1.484738030769231 ], [ 29.740360344827586, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 733, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.484738030769231 ], [ 29.74143827586207, -1.485007507692308 ], [ 29.741707758620691, -1.485007507692308 ], [ 29.741707758620691, -1.484738030769231 ], [ 29.74143827586207, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 734, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.484738030769231 ], [ 29.741707758620691, -1.485007507692308 ], [ 29.742246724137932, -1.485007507692308 ], [ 29.742246724137932, -1.484738030769231 ], [ 29.741707758620691, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 735, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.484738030769231 ], [ 29.742246724137932, -1.485007507692308 ], [ 29.742516206896553, -1.485007507692308 ], [ 29.742516206896553, -1.484738030769231 ], [ 29.742246724137932, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 736, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.484738030769231 ], [ 29.742516206896553, -1.485007507692308 ], [ 29.743324655172415, -1.485007507692308 ], [ 29.743324655172415, -1.484738030769231 ], [ 29.742516206896553, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 737, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.484738030769231 ], [ 29.743324655172415, -1.485007507692308 ], [ 29.743863620689655, -1.485007507692308 ], [ 29.743863620689655, -1.484738030769231 ], [ 29.743324655172415, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 738, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.484738030769231 ], [ 29.743863620689655, -1.485007507692308 ], [ 29.746288965517241, -1.485007507692308 ], [ 29.746288965517241, -1.484738030769231 ], [ 29.743863620689655, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 739, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.484738030769231 ], [ 29.746288965517241, -1.485007507692308 ], [ 29.746827931034485, -1.485007507692308 ], [ 29.746827931034485, -1.484738030769231 ], [ 29.746288965517241, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 740, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.484738030769231 ], [ 29.746827931034485, -1.485007507692308 ], [ 29.747097413793103, -1.485007507692308 ], [ 29.747097413793103, -1.484738030769231 ], [ 29.746827931034485, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 741, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.484738030769231 ], [ 29.747097413793103, -1.485007507692308 ], [ 29.747905862068965, -1.485007507692308 ], [ 29.747905862068965, -1.484738030769231 ], [ 29.747097413793103, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 742, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.484738030769231 ], [ 29.747905862068965, -1.485007507692308 ], [ 29.748175344827587, -1.485007507692308 ], [ 29.748175344827587, -1.484738030769231 ], [ 29.747905862068965, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 743, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.484738030769231 ], [ 29.748175344827587, -1.485007507692308 ], [ 29.748444827586209, -1.485007507692308 ], [ 29.748444827586209, -1.484738030769231 ], [ 29.748175344827587, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 744, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.484738030769231 ], [ 29.748444827586209, -1.485007507692308 ], [ 29.748714310344827, -1.485007507692308 ], [ 29.748714310344827, -1.484738030769231 ], [ 29.748444827586209, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 745, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.484738030769231 ], [ 29.748714310344827, -1.485007507692308 ], [ 29.748983793103449, -1.485007507692308 ], [ 29.748983793103449, -1.484738030769231 ], [ 29.748714310344827, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 746, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.484738030769231 ], [ 29.748983793103449, -1.485007507692308 ], [ 29.74925327586207, -1.485007507692308 ], [ 29.74925327586207, -1.484738030769231 ], [ 29.748983793103449, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 747, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.484738030769231 ], [ 29.74925327586207, -1.485007507692308 ], [ 29.749522758620689, -1.485007507692308 ], [ 29.749522758620689, -1.484738030769231 ], [ 29.74925327586207, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 748, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.484738030769231 ], [ 29.749522758620689, -1.485007507692308 ], [ 29.749792241379311, -1.485007507692308 ], [ 29.749792241379311, -1.484738030769231 ], [ 29.749522758620689, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 749, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.484738030769231 ], [ 29.749792241379311, -1.485007507692308 ], [ 29.750061724137932, -1.485007507692308 ], [ 29.750061724137932, -1.484738030769231 ], [ 29.749792241379311, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 750, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.484738030769231 ], [ 29.750061724137932, -1.485007507692308 ], [ 29.750331206896551, -1.485007507692308 ], [ 29.750331206896551, -1.484738030769231 ], [ 29.750061724137932, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 751, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.484738030769231 ], [ 29.750331206896551, -1.485007507692308 ], [ 29.750600689655172, -1.485007507692308 ], [ 29.750600689655172, -1.484738030769231 ], [ 29.750331206896551, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 752, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.484738030769231 ], [ 29.750600689655172, -1.485007507692308 ], [ 29.750870172413794, -1.485007507692308 ], [ 29.750870172413794, -1.484738030769231 ], [ 29.750600689655172, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 753, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.484738030769231 ], [ 29.750870172413794, -1.485007507692308 ], [ 29.751139655172413, -1.485007507692308 ], [ 29.751139655172413, -1.484738030769231 ], [ 29.750870172413794, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 754, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.484738030769231 ], [ 29.751139655172413, -1.485007507692308 ], [ 29.751409137931034, -1.485007507692308 ], [ 29.751409137931034, -1.484738030769231 ], [ 29.751139655172413, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 755, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.484738030769231 ], [ 29.751409137931034, -1.485007507692308 ], [ 29.751678620689656, -1.485007507692308 ], [ 29.751678620689656, -1.484738030769231 ], [ 29.751409137931034, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 756, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.484738030769231 ], [ 29.751678620689656, -1.485546461538461 ], [ 29.751948103448278, -1.485546461538461 ], [ 29.751948103448278, -1.484738030769231 ], [ 29.751678620689656, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 757, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.484738030769231 ], [ 29.751948103448278, -1.485546461538461 ], [ 29.752217586206896, -1.485546461538461 ], [ 29.752217586206896, -1.484738030769231 ], [ 29.751948103448278, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 758, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.484738030769231 ], [ 29.752217586206896, -1.485546461538461 ], [ 29.752487068965518, -1.485546461538461 ], [ 29.752487068965518, -1.484738030769231 ], [ 29.752217586206896, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 759, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.484738030769231 ], [ 29.752487068965518, -1.485276984615385 ], [ 29.75275655172414, -1.485276984615385 ], [ 29.75275655172414, -1.484738030769231 ], [ 29.752487068965518, -1.484738030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 760, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.485007507692308 ], [ 29.719340689655173, -1.485546461538461 ], [ 29.719610172413795, -1.485546461538461 ], [ 29.719610172413795, -1.485007507692308 ], [ 29.719340689655173, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 761, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.485007507692308 ], [ 29.719610172413795, -1.485276984615385 ], [ 29.719879655172413, -1.485276984615385 ], [ 29.719879655172413, -1.485007507692308 ], [ 29.719610172413795, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 762, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.485007507692308 ], [ 29.719879655172413, -1.485276984615385 ], [ 29.720149137931035, -1.485276984615385 ], [ 29.720149137931035, -1.485007507692308 ], [ 29.719879655172413, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 763, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.485007507692308 ], [ 29.720149137931035, -1.485276984615385 ], [ 29.720688103448275, -1.485276984615385 ], [ 29.720688103448275, -1.485007507692308 ], [ 29.720149137931035, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 764, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.485007507692308 ], [ 29.720688103448275, -1.485276984615385 ], [ 29.720957586206897, -1.485276984615385 ], [ 29.720957586206897, -1.485007507692308 ], [ 29.720688103448275, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 765, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.485007507692308 ], [ 29.720957586206897, -1.485276984615385 ], [ 29.721496551724137, -1.485276984615385 ], [ 29.721496551724137, -1.485007507692308 ], [ 29.720957586206897, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 766, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.485007507692308 ], [ 29.721496551724137, -1.485276984615385 ], [ 29.721766034482759, -1.485276984615385 ], [ 29.721766034482759, -1.485007507692308 ], [ 29.721496551724137, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 767, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.485007507692308 ], [ 29.721766034482759, -1.485276984615385 ], [ 29.72203551724138, -1.485276984615385 ], [ 29.72203551724138, -1.485007507692308 ], [ 29.721766034482759, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 768, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.485007507692308 ], [ 29.72203551724138, -1.485276984615385 ], [ 29.722305, -1.485276984615385 ], [ 29.722305, -1.485007507692308 ], [ 29.72203551724138, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 769, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.485007507692308 ], [ 29.730389482758621, -1.485276984615385 ], [ 29.730658965517243, -1.485276984615385 ], [ 29.730658965517243, -1.485007507692308 ], [ 29.730389482758621, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 770, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.485007507692308 ], [ 29.730658965517243, -1.485276984615385 ], [ 29.730928448275861, -1.485276984615385 ], [ 29.730928448275861, -1.485007507692308 ], [ 29.730658965517243, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 771, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.485007507692308 ], [ 29.731467413793105, -1.485546461538461 ], [ 29.731736896551723, -1.485546461538461 ], [ 29.731736896551723, -1.485007507692308 ], [ 29.731467413793105, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 772, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.485007507692308 ], [ 29.733623275862069, -1.485546461538461 ], [ 29.733892758620691, -1.485546461538461 ], [ 29.733892758620691, -1.485007507692308 ], [ 29.733623275862069, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 773, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.485007507692308 ], [ 29.733892758620691, -1.485276984615385 ], [ 29.734162241379309, -1.485276984615385 ], [ 29.734162241379309, -1.485007507692308 ], [ 29.733892758620691, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 774, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.485007507692308 ], [ 29.734162241379309, -1.485276984615385 ], [ 29.734431724137931, -1.485276984615385 ], [ 29.734431724137931, -1.485007507692308 ], [ 29.734162241379309, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 775, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.485007507692308 ], [ 29.734431724137931, -1.485276984615385 ], [ 29.734701206896553, -1.485276984615385 ], [ 29.734701206896553, -1.485007507692308 ], [ 29.734431724137931, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 776, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.485007507692308 ], [ 29.734701206896553, -1.485276984615385 ], [ 29.735509655172415, -1.485276984615385 ], [ 29.735509655172415, -1.485007507692308 ], [ 29.734701206896553, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 777, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.485007507692308 ], [ 29.735509655172415, -1.485276984615385 ], [ 29.735779137931036, -1.485276984615385 ], [ 29.735779137931036, -1.485007507692308 ], [ 29.735509655172415, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 778, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.485007507692308 ], [ 29.735779137931036, -1.485276984615385 ], [ 29.739821379310346, -1.485276984615385 ], [ 29.739821379310346, -1.485007507692308 ], [ 29.735779137931036, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 779, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.485007507692308 ], [ 29.739821379310346, -1.485276984615385 ], [ 29.740090862068964, -1.485276984615385 ], [ 29.740090862068964, -1.485007507692308 ], [ 29.739821379310346, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 780, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.485007507692308 ], [ 29.740090862068964, -1.485276984615385 ], [ 29.741168793103448, -1.485276984615385 ], [ 29.741168793103448, -1.485007507692308 ], [ 29.740090862068964, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 781, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.485007507692308 ], [ 29.741168793103448, -1.485276984615385 ], [ 29.741707758620691, -1.485276984615385 ], [ 29.741707758620691, -1.485007507692308 ], [ 29.741168793103448, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 782, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.485007507692308 ], [ 29.741707758620691, -1.485276984615385 ], [ 29.74197724137931, -1.485276984615385 ], [ 29.74197724137931, -1.485007507692308 ], [ 29.741707758620691, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 783, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.485007507692308 ], [ 29.74197724137931, -1.485276984615385 ], [ 29.742516206896553, -1.485276984615385 ], [ 29.742516206896553, -1.485007507692308 ], [ 29.74197724137931, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 784, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.485007507692308 ], [ 29.742516206896553, -1.485276984615385 ], [ 29.743324655172415, -1.485276984615385 ], [ 29.743324655172415, -1.485007507692308 ], [ 29.742516206896553, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 785, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.485007507692308 ], [ 29.743324655172415, -1.485276984615385 ], [ 29.743863620689655, -1.485276984615385 ], [ 29.743863620689655, -1.485007507692308 ], [ 29.743324655172415, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 786, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.485007507692308 ], [ 29.743863620689655, -1.485276984615385 ], [ 29.746288965517241, -1.485276984615385 ], [ 29.746288965517241, -1.485007507692308 ], [ 29.743863620689655, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 787, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.485007507692308 ], [ 29.746288965517241, -1.485276984615385 ], [ 29.746827931034485, -1.485276984615385 ], [ 29.746827931034485, -1.485007507692308 ], [ 29.746288965517241, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 788, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.485007507692308 ], [ 29.746827931034485, -1.485276984615385 ], [ 29.747097413793103, -1.485276984615385 ], [ 29.747097413793103, -1.485007507692308 ], [ 29.746827931034485, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 789, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.485007507692308 ], [ 29.747097413793103, -1.485276984615385 ], [ 29.747366896551725, -1.485276984615385 ], [ 29.747366896551725, -1.485007507692308 ], [ 29.747097413793103, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 790, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.485007507692308 ], [ 29.747366896551725, -1.485276984615385 ], [ 29.747905862068965, -1.485276984615385 ], [ 29.747905862068965, -1.485007507692308 ], [ 29.747366896551725, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 791, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.485007507692308 ], [ 29.747905862068965, -1.485276984615385 ], [ 29.748175344827587, -1.485276984615385 ], [ 29.748175344827587, -1.485007507692308 ], [ 29.747905862068965, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 792, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.485007507692308 ], [ 29.748175344827587, -1.485276984615385 ], [ 29.748444827586209, -1.485276984615385 ], [ 29.748444827586209, -1.485007507692308 ], [ 29.748175344827587, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 793, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.485007507692308 ], [ 29.748444827586209, -1.485276984615385 ], [ 29.748714310344827, -1.485276984615385 ], [ 29.748714310344827, -1.485007507692308 ], [ 29.748444827586209, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 794, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.485007507692308 ], [ 29.748714310344827, -1.485276984615385 ], [ 29.748983793103449, -1.485276984615385 ], [ 29.748983793103449, -1.485007507692308 ], [ 29.748714310344827, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 795, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.485007507692308 ], [ 29.748983793103449, -1.485276984615385 ], [ 29.74925327586207, -1.485276984615385 ], [ 29.74925327586207, -1.485007507692308 ], [ 29.748983793103449, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 796, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.485007507692308 ], [ 29.74925327586207, -1.485276984615385 ], [ 29.749522758620689, -1.485276984615385 ], [ 29.749522758620689, -1.485007507692308 ], [ 29.74925327586207, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 797, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.485007507692308 ], [ 29.749522758620689, -1.485276984615385 ], [ 29.749792241379311, -1.485276984615385 ], [ 29.749792241379311, -1.485007507692308 ], [ 29.749522758620689, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 798, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.485007507692308 ], [ 29.749792241379311, -1.485276984615385 ], [ 29.750061724137932, -1.485276984615385 ], [ 29.750061724137932, -1.485007507692308 ], [ 29.749792241379311, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 799, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.485007507692308 ], [ 29.750061724137932, -1.485276984615385 ], [ 29.750331206896551, -1.485276984615385 ], [ 29.750331206896551, -1.485007507692308 ], [ 29.750061724137932, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 800, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.485007507692308 ], [ 29.750331206896551, -1.485276984615385 ], [ 29.750600689655172, -1.485276984615385 ], [ 29.750600689655172, -1.485007507692308 ], [ 29.750331206896551, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 801, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.485007507692308 ], [ 29.750600689655172, -1.485276984615385 ], [ 29.750870172413794, -1.485276984615385 ], [ 29.750870172413794, -1.485007507692308 ], [ 29.750600689655172, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 802, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.485007507692308 ], [ 29.750870172413794, -1.485546461538461 ], [ 29.751139655172413, -1.485546461538461 ], [ 29.751139655172413, -1.485007507692308 ], [ 29.750870172413794, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 803, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.485276984615385 ], [ 29.720149137931035, -1.485546461538461 ], [ 29.720688103448275, -1.485546461538461 ], [ 29.720688103448275, -1.485276984615385 ], [ 29.720149137931035, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 804, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.485276984615385 ], [ 29.720688103448275, -1.485546461538461 ], [ 29.720957586206897, -1.485546461538461 ], [ 29.720957586206897, -1.485276984615385 ], [ 29.720688103448275, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 805, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.485276984615385 ], [ 29.720957586206897, -1.485546461538461 ], [ 29.721227068965518, -1.485546461538461 ], [ 29.721227068965518, -1.485276984615385 ], [ 29.720957586206897, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 806, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.485276984615385 ], [ 29.721227068965518, -1.485546461538461 ], [ 29.721496551724137, -1.485546461538461 ], [ 29.721496551724137, -1.485276984615385 ], [ 29.721227068965518, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 807, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.485276984615385 ], [ 29.721496551724137, -1.485546461538461 ], [ 29.721766034482759, -1.485546461538461 ], [ 29.721766034482759, -1.485276984615385 ], [ 29.721496551724137, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 808, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.485276984615385 ], [ 29.721766034482759, -1.485546461538461 ], [ 29.72203551724138, -1.485546461538461 ], [ 29.72203551724138, -1.485276984615385 ], [ 29.721766034482759, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 809, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.485276984615385 ], [ 29.72203551724138, -1.485546461538461 ], [ 29.722305, -1.485546461538461 ], [ 29.722305, -1.485276984615385 ], [ 29.72203551724138, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 810, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.485276984615385 ], [ 29.722305, -1.485546461538461 ], [ 29.72257448275862, -1.485546461538461 ], [ 29.72257448275862, -1.485276984615385 ], [ 29.722305, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 811, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.485276984615385 ], [ 29.73012, -1.485546461538461 ], [ 29.730389482758621, -1.485546461538461 ], [ 29.730389482758621, -1.485276984615385 ], [ 29.73012, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 812, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.485276984615385 ], [ 29.730389482758621, -1.485546461538461 ], [ 29.730658965517243, -1.485546461538461 ], [ 29.730658965517243, -1.485276984615385 ], [ 29.730389482758621, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 813, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.485276984615385 ], [ 29.730658965517243, -1.485546461538461 ], [ 29.730928448275861, -1.485546461538461 ], [ 29.730928448275861, -1.485276984615385 ], [ 29.730658965517243, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 814, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.485276984615385 ], [ 29.730928448275861, -1.485546461538461 ], [ 29.731197931034483, -1.485546461538461 ], [ 29.731197931034483, -1.485276984615385 ], [ 29.730928448275861, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 815, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.485276984615385 ], [ 29.731197931034483, -1.485546461538461 ], [ 29.731467413793105, -1.485546461538461 ], [ 29.731467413793105, -1.485276984615385 ], [ 29.731197931034483, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 816, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.485276984615385 ], [ 29.731736896551723, -1.485546461538461 ], [ 29.732006379310345, -1.485546461538461 ], [ 29.732006379310345, -1.485276984615385 ], [ 29.731736896551723, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 817, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.485276984615385 ], [ 29.732006379310345, -1.485546461538461 ], [ 29.732275862068967, -1.485546461538461 ], [ 29.732275862068967, -1.485276984615385 ], [ 29.732006379310345, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 818, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.485276984615385 ], [ 29.732275862068967, -1.485546461538461 ], [ 29.732545344827585, -1.485546461538461 ], [ 29.732545344827585, -1.485276984615385 ], [ 29.732275862068967, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 819, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.485276984615385 ], [ 29.734431724137931, -1.485546461538461 ], [ 29.734701206896553, -1.485546461538461 ], [ 29.734701206896553, -1.485276984615385 ], [ 29.734431724137931, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 820, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.485276984615385 ], [ 29.734701206896553, -1.485546461538461 ], [ 29.734970689655171, -1.485546461538461 ], [ 29.734970689655171, -1.485276984615385 ], [ 29.734701206896553, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 821, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.485276984615385 ], [ 29.734970689655171, -1.485546461538461 ], [ 29.735509655172415, -1.485546461538461 ], [ 29.735509655172415, -1.485276984615385 ], [ 29.734970689655171, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 822, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.485276984615385 ], [ 29.735509655172415, -1.485546461538461 ], [ 29.736048620689655, -1.485546461538461 ], [ 29.736048620689655, -1.485276984615385 ], [ 29.735509655172415, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 823, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.485276984615385 ], [ 29.736048620689655, -1.485546461538461 ], [ 29.739551896551724, -1.485546461538461 ], [ 29.739551896551724, -1.485276984615385 ], [ 29.736048620689655, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 824, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.485276984615385 ], [ 29.739551896551724, -1.485546461538461 ], [ 29.740090862068964, -1.485546461538461 ], [ 29.740090862068964, -1.485276984615385 ], [ 29.739551896551724, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 825, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.485276984615385 ], [ 29.740090862068964, -1.485546461538461 ], [ 29.741168793103448, -1.485546461538461 ], [ 29.741168793103448, -1.485276984615385 ], [ 29.740090862068964, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 826, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.485276984615385 ], [ 29.741168793103448, -1.485546461538461 ], [ 29.741707758620691, -1.485546461538461 ], [ 29.741707758620691, -1.485276984615385 ], [ 29.741168793103448, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 827, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.485276984615385 ], [ 29.741707758620691, -1.485546461538461 ], [ 29.74197724137931, -1.485546461538461 ], [ 29.74197724137931, -1.485276984615385 ], [ 29.741707758620691, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 828, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.485276984615385 ], [ 29.74197724137931, -1.485546461538461 ], [ 29.742246724137932, -1.485546461538461 ], [ 29.742246724137932, -1.485276984615385 ], [ 29.74197724137931, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 829, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.485276984615385 ], [ 29.742246724137932, -1.485546461538461 ], [ 29.742516206896553, -1.485546461538461 ], [ 29.742516206896553, -1.485276984615385 ], [ 29.742246724137932, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 830, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.485276984615385 ], [ 29.742516206896553, -1.485546461538461 ], [ 29.743055172413793, -1.485546461538461 ], [ 29.743055172413793, -1.485276984615385 ], [ 29.742516206896553, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 831, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.485276984615385 ], [ 29.743055172413793, -1.485546461538461 ], [ 29.743594137931034, -1.485546461538461 ], [ 29.743594137931034, -1.485276984615385 ], [ 29.743055172413793, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 832, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.485276984615385 ], [ 29.743594137931034, -1.485546461538461 ], [ 29.744402586206895, -1.485546461538461 ], [ 29.744402586206895, -1.485276984615385 ], [ 29.743594137931034, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 833, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.485276984615385 ], [ 29.744402586206895, -1.485546461538461 ], [ 29.74575, -1.485546461538461 ], [ 29.74575, -1.485276984615385 ], [ 29.744402586206895, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 834, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.485276984615385 ], [ 29.74575, -1.485546461538461 ], [ 29.746558448275863, -1.485546461538461 ], [ 29.746558448275863, -1.485276984615385 ], [ 29.74575, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 835, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.485276984615385 ], [ 29.746558448275863, -1.485546461538461 ], [ 29.747097413793103, -1.485546461538461 ], [ 29.747097413793103, -1.485276984615385 ], [ 29.746558448275863, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 836, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.485276984615385 ], [ 29.747097413793103, -1.485546461538461 ], [ 29.747366896551725, -1.485546461538461 ], [ 29.747366896551725, -1.485276984615385 ], [ 29.747097413793103, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 837, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.485276984615385 ], [ 29.747366896551725, -1.485546461538461 ], [ 29.747905862068965, -1.485546461538461 ], [ 29.747905862068965, -1.485276984615385 ], [ 29.747366896551725, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 838, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.485276984615385 ], [ 29.747905862068965, -1.485546461538461 ], [ 29.748444827586209, -1.485546461538461 ], [ 29.748444827586209, -1.485276984615385 ], [ 29.747905862068965, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 839, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.485276984615385 ], [ 29.748444827586209, -1.485546461538461 ], [ 29.748714310344827, -1.485546461538461 ], [ 29.748714310344827, -1.485276984615385 ], [ 29.748444827586209, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 840, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.485276984615385 ], [ 29.748714310344827, -1.485546461538461 ], [ 29.748983793103449, -1.485546461538461 ], [ 29.748983793103449, -1.485276984615385 ], [ 29.748714310344827, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 841, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.485276984615385 ], [ 29.748983793103449, -1.485546461538461 ], [ 29.74925327586207, -1.485546461538461 ], [ 29.74925327586207, -1.485276984615385 ], [ 29.748983793103449, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 842, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.485276984615385 ], [ 29.74925327586207, -1.485546461538461 ], [ 29.749522758620689, -1.485546461538461 ], [ 29.749522758620689, -1.485276984615385 ], [ 29.74925327586207, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 843, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.485276984615385 ], [ 29.749792241379311, -1.485546461538461 ], [ 29.750061724137932, -1.485546461538461 ], [ 29.750061724137932, -1.485276984615385 ], [ 29.749792241379311, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 844, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.485276984615385 ], [ 29.750061724137932, -1.485546461538461 ], [ 29.750331206896551, -1.485546461538461 ], [ 29.750331206896551, -1.485276984615385 ], [ 29.750061724137932, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 845, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.485276984615385 ], [ 29.75329551724138, -1.485546461538461 ], [ 29.753565, -1.485546461538461 ], [ 29.753565, -1.485276984615385 ], [ 29.75329551724138, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 846, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.483390646153846 ], [ 29.723652413793104, -1.486354892307692 ], [ 29.723921896551722, -1.486354892307692 ], [ 29.723921896551722, -1.483390646153846 ], [ 29.723652413793104, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 847, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.483390646153846 ], [ 29.723921896551722, -1.486354892307692 ], [ 29.724191379310344, -1.486354892307692 ], [ 29.724191379310344, -1.483390646153846 ], [ 29.723921896551722, -1.483390646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 848, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.483660123076923 ], [ 29.723382931034482, -1.486085415384615 ], [ 29.723652413793104, -1.486085415384615 ], [ 29.723652413793104, -1.483660123076923 ], [ 29.723382931034482, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 849, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.483660123076923 ], [ 29.724191379310344, -1.486085415384615 ], [ 29.724460862068966, -1.486085415384615 ], [ 29.724460862068966, -1.483660123076923 ], [ 29.724191379310344, -1.483660123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 850, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.4839296 ], [ 29.723113448275861, -1.486354892307692 ], [ 29.723382931034482, -1.486354892307692 ], [ 29.723382931034482, -1.4839296 ], [ 29.723113448275861, -1.4839296 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 851, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.484468553846154 ], [ 29.722843965517242, -1.486354892307692 ], [ 29.723113448275861, -1.486354892307692 ], [ 29.723113448275861, -1.484468553846154 ], [ 29.722843965517242, -1.484468553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 852, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.485007507692308 ], [ 29.722305, -1.485276984615385 ], [ 29.72257448275862, -1.485276984615385 ], [ 29.72257448275862, -1.486354892307692 ], [ 29.722843965517242, -1.486354892307692 ], [ 29.722843965517242, -1.485007507692308 ], [ 29.722305, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 853, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.485007507692308 ], [ 29.733353793103447, -1.485815938461538 ], [ 29.733623275862069, -1.485815938461538 ], [ 29.733623275862069, -1.485007507692308 ], [ 29.733353793103447, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 854, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.485007507692308 ], [ 29.751139655172413, -1.485815938461538 ], [ 29.751409137931034, -1.485815938461538 ], [ 29.751409137931034, -1.485007507692308 ], [ 29.751139655172413, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 855, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.485007507692308 ], [ 29.751409137931034, -1.485815938461538 ], [ 29.751678620689656, -1.485815938461538 ], [ 29.751678620689656, -1.485007507692308 ], [ 29.751409137931034, -1.485007507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 856, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.485276984615385 ], [ 29.719610172413795, -1.485815938461538 ], [ 29.719879655172413, -1.485815938461538 ], [ 29.719879655172413, -1.485276984615385 ], [ 29.719610172413795, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 857, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.485276984615385 ], [ 29.719879655172413, -1.485815938461538 ], [ 29.720149137931035, -1.485815938461538 ], [ 29.720149137931035, -1.485276984615385 ], [ 29.719879655172413, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 858, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.485276984615385 ], [ 29.733084310344829, -1.485815938461538 ], [ 29.733353793103447, -1.485815938461538 ], [ 29.733353793103447, -1.485276984615385 ], [ 29.733084310344829, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 859, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.485276984615385 ], [ 29.733892758620691, -1.485815938461538 ], [ 29.734162241379309, -1.485815938461538 ], [ 29.734162241379309, -1.485276984615385 ], [ 29.733892758620691, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 860, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.485276984615385 ], [ 29.734162241379309, -1.485815938461538 ], [ 29.734431724137931, -1.485815938461538 ], [ 29.734431724137931, -1.485276984615385 ], [ 29.734162241379309, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 861, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.485276984615385 ], [ 29.749522758620689, -1.485815938461538 ], [ 29.749792241379311, -1.485815938461538 ], [ 29.749792241379311, -1.485276984615385 ], [ 29.749522758620689, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 862, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.485276984615385 ], [ 29.750331206896551, -1.485815938461538 ], [ 29.750600689655172, -1.485815938461538 ], [ 29.750600689655172, -1.485276984615385 ], [ 29.750331206896551, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 863, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.485276984615385 ], [ 29.750600689655172, -1.486085415384615 ], [ 29.750870172413794, -1.486085415384615 ], [ 29.750870172413794, -1.485276984615385 ], [ 29.750600689655172, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 864, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.485276984615385 ], [ 29.752487068965518, -1.485815938461538 ], [ 29.75275655172414, -1.485815938461538 ], [ 29.75275655172414, -1.485276984615385 ], [ 29.752487068965518, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 865, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.485276984615385 ], [ 29.753565, -1.485815938461538 ], [ 29.75383448275862, -1.485815938461538 ], [ 29.75383448275862, -1.485276984615385 ], [ 29.753565, -1.485276984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 866, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.485546461538461 ], [ 29.719071206896551, -1.486085415384615 ], [ 29.719340689655173, -1.486085415384615 ], [ 29.719340689655173, -1.485546461538461 ], [ 29.719071206896551, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 867, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.485546461538461 ], [ 29.719340689655173, -1.485815938461538 ], [ 29.719610172413795, -1.485815938461538 ], [ 29.719610172413795, -1.485546461538461 ], [ 29.719340689655173, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 868, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.485546461538461 ], [ 29.720149137931035, -1.486085415384615 ], [ 29.720688103448275, -1.486085415384615 ], [ 29.720688103448275, -1.485546461538461 ], [ 29.720149137931035, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 869, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.485546461538461 ], [ 29.720688103448275, -1.485815938461538 ], [ 29.720957586206897, -1.485815938461538 ], [ 29.720957586206897, -1.485546461538461 ], [ 29.720688103448275, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 870, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.485546461538461 ], [ 29.720957586206897, -1.485815938461538 ], [ 29.721227068965518, -1.485815938461538 ], [ 29.721227068965518, -1.485546461538461 ], [ 29.720957586206897, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 871, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.485546461538461 ], [ 29.721227068965518, -1.485815938461538 ], [ 29.721496551724137, -1.485815938461538 ], [ 29.721496551724137, -1.485546461538461 ], [ 29.721227068965518, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 872, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.485546461538461 ], [ 29.721496551724137, -1.485815938461538 ], [ 29.721766034482759, -1.485815938461538 ], [ 29.721766034482759, -1.485546461538461 ], [ 29.721496551724137, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 873, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.485546461538461 ], [ 29.721766034482759, -1.485815938461538 ], [ 29.72203551724138, -1.485815938461538 ], [ 29.72203551724138, -1.485546461538461 ], [ 29.721766034482759, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 874, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.485546461538461 ], [ 29.72203551724138, -1.485815938461538 ], [ 29.722305, -1.485815938461538 ], [ 29.722305, -1.485546461538461 ], [ 29.72203551724138, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 875, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.485546461538461 ], [ 29.722305, -1.486624369230769 ], [ 29.72257448275862, -1.486624369230769 ], [ 29.72257448275862, -1.485546461538461 ], [ 29.722305, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 876, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.485546461538461 ], [ 29.729850517241381, -1.486085415384615 ], [ 29.73012, -1.486085415384615 ], [ 29.73012, -1.485546461538461 ], [ 29.729850517241381, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 877, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.485546461538461 ], [ 29.73012, -1.485815938461538 ], [ 29.730389482758621, -1.485815938461538 ], [ 29.730389482758621, -1.485546461538461 ], [ 29.73012, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 878, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.485546461538461 ], [ 29.730389482758621, -1.485815938461538 ], [ 29.730658965517243, -1.485815938461538 ], [ 29.730658965517243, -1.485546461538461 ], [ 29.730389482758621, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 879, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.485546461538461 ], [ 29.730658965517243, -1.485815938461538 ], [ 29.730928448275861, -1.485815938461538 ], [ 29.730928448275861, -1.485546461538461 ], [ 29.730658965517243, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 880, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.485546461538461 ], [ 29.730928448275861, -1.485815938461538 ], [ 29.731197931034483, -1.485815938461538 ], [ 29.731197931034483, -1.485546461538461 ], [ 29.730928448275861, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 881, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.485546461538461 ], [ 29.731197931034483, -1.485815938461538 ], [ 29.731467413793105, -1.485815938461538 ], [ 29.731467413793105, -1.485546461538461 ], [ 29.731197931034483, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 882, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.485546461538461 ], [ 29.731467413793105, -1.485815938461538 ], [ 29.731736896551723, -1.485815938461538 ], [ 29.731736896551723, -1.485546461538461 ], [ 29.731467413793105, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 883, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.485546461538461 ], [ 29.731736896551723, -1.485815938461538 ], [ 29.732006379310345, -1.485815938461538 ], [ 29.732006379310345, -1.485546461538461 ], [ 29.731736896551723, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 884, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.485546461538461 ], [ 29.732006379310345, -1.485815938461538 ], [ 29.732275862068967, -1.485815938461538 ], [ 29.732275862068967, -1.485546461538461 ], [ 29.732006379310345, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 885, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.485546461538461 ], [ 29.732275862068967, -1.485815938461538 ], [ 29.732545344827585, -1.485815938461538 ], [ 29.732545344827585, -1.485546461538461 ], [ 29.732275862068967, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 886, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.485546461538461 ], [ 29.732545344827585, -1.485815938461538 ], [ 29.732814827586207, -1.485815938461538 ], [ 29.732814827586207, -1.485546461538461 ], [ 29.732545344827585, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 887, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.485546461538461 ], [ 29.732814827586207, -1.485815938461538 ], [ 29.733084310344829, -1.485815938461538 ], [ 29.733084310344829, -1.485546461538461 ], [ 29.732814827586207, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 888, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.485546461538461 ], [ 29.733623275862069, -1.486085415384615 ], [ 29.733892758620691, -1.486085415384615 ], [ 29.733892758620691, -1.485546461538461 ], [ 29.733623275862069, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 889, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.485546461538461 ], [ 29.734431724137931, -1.486085415384615 ], [ 29.734701206896553, -1.486085415384615 ], [ 29.734701206896553, -1.485546461538461 ], [ 29.734431724137931, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 890, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.485546461538461 ], [ 29.734701206896553, -1.486085415384615 ], [ 29.734970689655171, -1.486085415384615 ], [ 29.734970689655171, -1.485546461538461 ], [ 29.734701206896553, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 891, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.485546461538461 ], [ 29.734970689655171, -1.485815938461538 ], [ 29.735509655172415, -1.485815938461538 ], [ 29.735509655172415, -1.485546461538461 ], [ 29.734970689655171, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 892, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.485546461538461 ], [ 29.735509655172415, -1.485815938461538 ], [ 29.736048620689655, -1.485815938461538 ], [ 29.736048620689655, -1.485546461538461 ], [ 29.735509655172415, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 893, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.485546461538461 ], [ 29.736048620689655, -1.485815938461538 ], [ 29.739551896551724, -1.485815938461538 ], [ 29.739551896551724, -1.485546461538461 ], [ 29.736048620689655, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 894, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.485546461538461 ], [ 29.739551896551724, -1.485815938461538 ], [ 29.740090862068964, -1.485815938461538 ], [ 29.740090862068964, -1.485546461538461 ], [ 29.739551896551724, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 895, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.485546461538461 ], [ 29.740090862068964, -1.485815938461538 ], [ 29.740629827586208, -1.485815938461538 ], [ 29.740629827586208, -1.485546461538461 ], [ 29.740090862068964, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 896, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.485546461538461 ], [ 29.740629827586208, -1.485815938461538 ], [ 29.74143827586207, -1.485815938461538 ], [ 29.74143827586207, -1.485546461538461 ], [ 29.740629827586208, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 897, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.485546461538461 ], [ 29.74143827586207, -1.485815938461538 ], [ 29.74197724137931, -1.485815938461538 ], [ 29.74197724137931, -1.485546461538461 ], [ 29.74143827586207, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 898, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.485546461538461 ], [ 29.74197724137931, -1.485815938461538 ], [ 29.742246724137932, -1.485815938461538 ], [ 29.742246724137932, -1.485546461538461 ], [ 29.74197724137931, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 899, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.485546461538461 ], [ 29.742246724137932, -1.485815938461538 ], [ 29.742516206896553, -1.485815938461538 ], [ 29.742516206896553, -1.485546461538461 ], [ 29.742246724137932, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 900, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.485546461538461 ], [ 29.742516206896553, -1.485815938461538 ], [ 29.743055172413793, -1.485815938461538 ], [ 29.743055172413793, -1.485546461538461 ], [ 29.742516206896553, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 901, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.485546461538461 ], [ 29.743055172413793, -1.485815938461538 ], [ 29.743594137931034, -1.485815938461538 ], [ 29.743594137931034, -1.485546461538461 ], [ 29.743055172413793, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 902, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.485546461538461 ], [ 29.743594137931034, -1.485815938461538 ], [ 29.746558448275863, -1.485815938461538 ], [ 29.746558448275863, -1.485546461538461 ], [ 29.743594137931034, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 903, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.485546461538461 ], [ 29.746558448275863, -1.485815938461538 ], [ 29.747097413793103, -1.485815938461538 ], [ 29.747097413793103, -1.485546461538461 ], [ 29.746558448275863, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 904, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.485546461538461 ], [ 29.747097413793103, -1.485815938461538 ], [ 29.747366896551725, -1.485815938461538 ], [ 29.747366896551725, -1.485546461538461 ], [ 29.747097413793103, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 905, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.485546461538461 ], [ 29.747366896551725, -1.485815938461538 ], [ 29.747905862068965, -1.485815938461538 ], [ 29.747905862068965, -1.485546461538461 ], [ 29.747366896551725, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 906, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.485546461538461 ], [ 29.747905862068965, -1.485815938461538 ], [ 29.748175344827587, -1.485815938461538 ], [ 29.748175344827587, -1.485546461538461 ], [ 29.747905862068965, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 907, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.485546461538461 ], [ 29.748175344827587, -1.485815938461538 ], [ 29.748444827586209, -1.485815938461538 ], [ 29.748444827586209, -1.485546461538461 ], [ 29.748175344827587, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 908, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.485546461538461 ], [ 29.748444827586209, -1.485815938461538 ], [ 29.748714310344827, -1.485815938461538 ], [ 29.748714310344827, -1.485546461538461 ], [ 29.748444827586209, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 909, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.485546461538461 ], [ 29.748714310344827, -1.485815938461538 ], [ 29.748983793103449, -1.485815938461538 ], [ 29.748983793103449, -1.485546461538461 ], [ 29.748714310344827, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 910, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.485546461538461 ], [ 29.748983793103449, -1.486085415384615 ], [ 29.74925327586207, -1.486085415384615 ], [ 29.74925327586207, -1.485546461538461 ], [ 29.748983793103449, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 911, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.485546461538461 ], [ 29.74925327586207, -1.485815938461538 ], [ 29.749522758620689, -1.485815938461538 ], [ 29.749522758620689, -1.485546461538461 ], [ 29.74925327586207, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 912, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.485546461538461 ], [ 29.749792241379311, -1.486085415384615 ], [ 29.750061724137932, -1.486085415384615 ], [ 29.750061724137932, -1.485546461538461 ], [ 29.749792241379311, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 913, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.485546461538461 ], [ 29.750061724137932, -1.486085415384615 ], [ 29.750331206896551, -1.486085415384615 ], [ 29.750331206896551, -1.485546461538461 ], [ 29.750061724137932, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 914, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.485546461538461 ], [ 29.750870172413794, -1.486085415384615 ], [ 29.751139655172413, -1.486085415384615 ], [ 29.751139655172413, -1.485546461538461 ], [ 29.750870172413794, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 915, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.485546461538461 ], [ 29.751678620689656, -1.485815938461538 ], [ 29.751948103448278, -1.485815938461538 ], [ 29.751948103448278, -1.485546461538461 ], [ 29.751678620689656, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 916, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.485546461538461 ], [ 29.751948103448278, -1.485815938461538 ], [ 29.752217586206896, -1.485815938461538 ], [ 29.752217586206896, -1.485546461538461 ], [ 29.751948103448278, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 917, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.485546461538461 ], [ 29.752217586206896, -1.485815938461538 ], [ 29.752487068965518, -1.485815938461538 ], [ 29.752487068965518, -1.485546461538461 ], [ 29.752217586206896, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 918, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.485546461538461 ], [ 29.75275655172414, -1.485815938461538 ], [ 29.753026034482758, -1.485815938461538 ], [ 29.753026034482758, -1.485546461538461 ], [ 29.75275655172414, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 919, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.485546461538461 ], [ 29.753026034482758, -1.486085415384615 ], [ 29.75329551724138, -1.486085415384615 ], [ 29.75329551724138, -1.485546461538461 ], [ 29.753026034482758, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 920, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.485546461538461 ], [ 29.75329551724138, -1.486085415384615 ], [ 29.753565, -1.486085415384615 ], [ 29.753565, -1.485546461538461 ], [ 29.75329551724138, -1.485546461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 921, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.485815938461538 ], [ 29.719340689655173, -1.487163323076923 ], [ 29.719610172413795, -1.487163323076923 ], [ 29.719610172413795, -1.485815938461538 ], [ 29.719340689655173, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 922, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.485815938461538 ], [ 29.719610172413795, -1.487163323076923 ], [ 29.720149137931035, -1.487163323076923 ], [ 29.720149137931035, -1.486893846153846 ], [ 29.719879655172413, -1.486893846153846 ], [ 29.719879655172413, -1.485815938461538 ], [ 29.719610172413795, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 923, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.485815938461538 ], [ 29.719879655172413, -1.486085415384615 ], [ 29.720149137931035, -1.486085415384615 ], [ 29.720149137931035, -1.485815938461538 ], [ 29.719879655172413, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 924, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.485815938461538 ], [ 29.720688103448275, -1.486085415384615 ], [ 29.720957586206897, -1.486085415384615 ], [ 29.720957586206897, -1.485815938461538 ], [ 29.720688103448275, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 925, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.485815938461538 ], [ 29.720957586206897, -1.486085415384615 ], [ 29.721227068965518, -1.486085415384615 ], [ 29.721227068965518, -1.485815938461538 ], [ 29.720957586206897, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 926, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.485815938461538 ], [ 29.721227068965518, -1.486085415384615 ], [ 29.721496551724137, -1.486085415384615 ], [ 29.721496551724137, -1.485815938461538 ], [ 29.721227068965518, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 927, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.485815938461538 ], [ 29.721496551724137, -1.486085415384615 ], [ 29.721766034482759, -1.486085415384615 ], [ 29.721766034482759, -1.485815938461538 ], [ 29.721496551724137, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 928, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.485815938461538 ], [ 29.721766034482759, -1.486085415384615 ], [ 29.72203551724138, -1.486085415384615 ], [ 29.72203551724138, -1.485815938461538 ], [ 29.721766034482759, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 929, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.485815938461538 ], [ 29.72203551724138, -1.486085415384615 ], [ 29.722305, -1.486085415384615 ], [ 29.722305, -1.485815938461538 ], [ 29.72203551724138, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 930, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.485815938461538 ], [ 29.73012, -1.486354892307692 ], [ 29.730389482758621, -1.486354892307692 ], [ 29.730389482758621, -1.485815938461538 ], [ 29.73012, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 931, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.485815938461538 ], [ 29.730389482758621, -1.486354892307692 ], [ 29.730658965517243, -1.486354892307692 ], [ 29.730658965517243, -1.485815938461538 ], [ 29.730389482758621, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 932, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.485815938461538 ], [ 29.730658965517243, -1.486085415384615 ], [ 29.730928448275861, -1.486085415384615 ], [ 29.730928448275861, -1.485815938461538 ], [ 29.730658965517243, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 933, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.485815938461538 ], [ 29.730928448275861, -1.486085415384615 ], [ 29.731197931034483, -1.486085415384615 ], [ 29.731197931034483, -1.485815938461538 ], [ 29.730928448275861, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 934, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.485815938461538 ], [ 29.731197931034483, -1.486085415384615 ], [ 29.731467413793105, -1.486085415384615 ], [ 29.731467413793105, -1.485815938461538 ], [ 29.731197931034483, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 935, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.485815938461538 ], [ 29.731467413793105, -1.486085415384615 ], [ 29.731736896551723, -1.486085415384615 ], [ 29.731736896551723, -1.485815938461538 ], [ 29.731467413793105, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 936, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.485815938461538 ], [ 29.731736896551723, -1.486085415384615 ], [ 29.732006379310345, -1.486085415384615 ], [ 29.732006379310345, -1.485815938461538 ], [ 29.731736896551723, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 937, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.485815938461538 ], [ 29.732006379310345, -1.486085415384615 ], [ 29.732275862068967, -1.486085415384615 ], [ 29.732275862068967, -1.485815938461538 ], [ 29.732006379310345, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 938, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.485815938461538 ], [ 29.732275862068967, -1.486085415384615 ], [ 29.732545344827585, -1.486085415384615 ], [ 29.732545344827585, -1.485815938461538 ], [ 29.732275862068967, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 939, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.485815938461538 ], [ 29.732545344827585, -1.486085415384615 ], [ 29.732814827586207, -1.486085415384615 ], [ 29.732814827586207, -1.485815938461538 ], [ 29.732545344827585, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 940, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.485815938461538 ], [ 29.732814827586207, -1.486085415384615 ], [ 29.733084310344829, -1.486085415384615 ], [ 29.733084310344829, -1.485815938461538 ], [ 29.732814827586207, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 941, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.485815938461538 ], [ 29.733084310344829, -1.486085415384615 ], [ 29.733353793103447, -1.486085415384615 ], [ 29.733353793103447, -1.485815938461538 ], [ 29.733084310344829, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 942, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.485815938461538 ], [ 29.733353793103447, -1.486085415384615 ], [ 29.733623275862069, -1.486085415384615 ], [ 29.733623275862069, -1.485815938461538 ], [ 29.733353793103447, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 943, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.485815938461538 ], [ 29.733892758620691, -1.486354892307692 ], [ 29.734162241379309, -1.486354892307692 ], [ 29.734162241379309, -1.485815938461538 ], [ 29.733892758620691, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 944, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.485815938461538 ], [ 29.734162241379309, -1.486085415384615 ], [ 29.734431724137931, -1.486085415384615 ], [ 29.734431724137931, -1.485815938461538 ], [ 29.734162241379309, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 945, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.485815938461538 ], [ 29.734970689655171, -1.486354892307692 ], [ 29.735240172413793, -1.486354892307692 ], [ 29.735240172413793, -1.486085415384615 ], [ 29.735779137931036, -1.486085415384615 ], [ 29.735779137931036, -1.485815938461538 ], [ 29.734970689655171, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 946, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.485815938461538 ], [ 29.735779137931036, -1.486085415384615 ], [ 29.736587586206898, -1.486085415384615 ], [ 29.736587586206898, -1.485815938461538 ], [ 29.735779137931036, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 947, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.485815938461538 ], [ 29.736587586206898, -1.486085415384615 ], [ 29.739012931034484, -1.486085415384615 ], [ 29.739012931034484, -1.485815938461538 ], [ 29.736587586206898, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 948, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.485815938461538 ], [ 29.739012931034484, -1.486085415384615 ], [ 29.739821379310346, -1.486085415384615 ], [ 29.739821379310346, -1.485815938461538 ], [ 29.739012931034484, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 949, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.485815938461538 ], [ 29.739821379310346, -1.486085415384615 ], [ 29.740360344827586, -1.486085415384615 ], [ 29.740360344827586, -1.485815938461538 ], [ 29.739821379310346, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 950, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.485815938461538 ], [ 29.740360344827586, -1.486085415384615 ], [ 29.74143827586207, -1.486085415384615 ], [ 29.74143827586207, -1.485815938461538 ], [ 29.740360344827586, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 951, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.485815938461538 ], [ 29.74143827586207, -1.486085415384615 ], [ 29.74197724137931, -1.486085415384615 ], [ 29.74197724137931, -1.485815938461538 ], [ 29.74143827586207, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 952, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.485815938461538 ], [ 29.74197724137931, -1.486085415384615 ], [ 29.742246724137932, -1.486085415384615 ], [ 29.742246724137932, -1.485815938461538 ], [ 29.74197724137931, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 953, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.485815938461538 ], [ 29.742246724137932, -1.486085415384615 ], [ 29.742516206896553, -1.486085415384615 ], [ 29.742516206896553, -1.485815938461538 ], [ 29.742246724137932, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 954, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.485815938461538 ], [ 29.742516206896553, -1.486085415384615 ], [ 29.743324655172415, -1.486085415384615 ], [ 29.743324655172415, -1.485815938461538 ], [ 29.742516206896553, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 955, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.485815938461538 ], [ 29.743324655172415, -1.486085415384615 ], [ 29.743863620689655, -1.486085415384615 ], [ 29.743863620689655, -1.485815938461538 ], [ 29.743324655172415, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 956, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.485815938461538 ], [ 29.743863620689655, -1.486085415384615 ], [ 29.746288965517241, -1.486085415384615 ], [ 29.746288965517241, -1.485815938461538 ], [ 29.743863620689655, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 957, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.485815938461538 ], [ 29.746288965517241, -1.486085415384615 ], [ 29.746827931034485, -1.486085415384615 ], [ 29.746827931034485, -1.485815938461538 ], [ 29.746288965517241, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 958, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.485815938461538 ], [ 29.746827931034485, -1.486085415384615 ], [ 29.747366896551725, -1.486085415384615 ], [ 29.747366896551725, -1.485815938461538 ], [ 29.746827931034485, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 959, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.485815938461538 ], [ 29.747366896551725, -1.486085415384615 ], [ 29.747905862068965, -1.486085415384615 ], [ 29.747905862068965, -1.485815938461538 ], [ 29.747366896551725, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 960, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.485815938461538 ], [ 29.747905862068965, -1.486085415384615 ], [ 29.748175344827587, -1.486085415384615 ], [ 29.748175344827587, -1.485815938461538 ], [ 29.747905862068965, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 961, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.485815938461538 ], [ 29.748175344827587, -1.486085415384615 ], [ 29.748444827586209, -1.486085415384615 ], [ 29.748444827586209, -1.485815938461538 ], [ 29.748175344827587, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 962, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.485815938461538 ], [ 29.748444827586209, -1.486354892307692 ], [ 29.748714310344827, -1.486354892307692 ], [ 29.748714310344827, -1.485815938461538 ], [ 29.748444827586209, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 963, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.485815938461538 ], [ 29.748714310344827, -1.486085415384615 ], [ 29.748983793103449, -1.486085415384615 ], [ 29.748983793103449, -1.485815938461538 ], [ 29.748714310344827, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 964, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.485815938461538 ], [ 29.74925327586207, -1.486354892307692 ], [ 29.749522758620689, -1.486354892307692 ], [ 29.749522758620689, -1.485815938461538 ], [ 29.74925327586207, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 965, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.485815938461538 ], [ 29.749522758620689, -1.486354892307692 ], [ 29.749792241379311, -1.486354892307692 ], [ 29.749792241379311, -1.485815938461538 ], [ 29.749522758620689, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 966, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.485815938461538 ], [ 29.750331206896551, -1.486354892307692 ], [ 29.750600689655172, -1.486354892307692 ], [ 29.750600689655172, -1.485815938461538 ], [ 29.750331206896551, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 967, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.485815938461538 ], [ 29.751139655172413, -1.486085415384615 ], [ 29.751409137931034, -1.486085415384615 ], [ 29.751409137931034, -1.485815938461538 ], [ 29.751139655172413, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 968, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.485815938461538 ], [ 29.751409137931034, -1.486085415384615 ], [ 29.751678620689656, -1.486085415384615 ], [ 29.751678620689656, -1.485815938461538 ], [ 29.751409137931034, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 969, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.485815938461538 ], [ 29.751678620689656, -1.486085415384615 ], [ 29.751948103448278, -1.486085415384615 ], [ 29.751948103448278, -1.485815938461538 ], [ 29.751678620689656, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 970, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.485815938461538 ], [ 29.751948103448278, -1.486085415384615 ], [ 29.752217586206896, -1.486085415384615 ], [ 29.752217586206896, -1.485815938461538 ], [ 29.751948103448278, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 971, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.485815938461538 ], [ 29.752217586206896, -1.486085415384615 ], [ 29.752487068965518, -1.486085415384615 ], [ 29.752487068965518, -1.485815938461538 ], [ 29.752217586206896, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 972, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.485815938461538 ], [ 29.752487068965518, -1.486085415384615 ], [ 29.75275655172414, -1.486085415384615 ], [ 29.75275655172414, -1.485815938461538 ], [ 29.752487068965518, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 973, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.485815938461538 ], [ 29.75275655172414, -1.486354892307692 ], [ 29.753026034482758, -1.486354892307692 ], [ 29.753026034482758, -1.485815938461538 ], [ 29.75275655172414, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 974, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.485815938461538 ], [ 29.753565, -1.486085415384615 ], [ 29.75383448275862, -1.486085415384615 ], [ 29.75383448275862, -1.485815938461538 ], [ 29.753565, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 975, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.485815938461538 ], [ 29.75383448275862, -1.486085415384615 ], [ 29.754103965517242, -1.486085415384615 ], [ 29.754103965517242, -1.485815938461538 ], [ 29.75383448275862, -1.485815938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 976, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.486085415384615 ], [ 29.718801724137933, -1.486624369230769 ], [ 29.719071206896551, -1.486624369230769 ], [ 29.719071206896551, -1.486085415384615 ], [ 29.718801724137933, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 977, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.486085415384615 ], [ 29.719071206896551, -1.486624369230769 ], [ 29.719340689655173, -1.486624369230769 ], [ 29.719340689655173, -1.486085415384615 ], [ 29.719071206896551, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 978, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.486085415384615 ], [ 29.719879655172413, -1.486624369230769 ], [ 29.720149137931035, -1.486624369230769 ], [ 29.720149137931035, -1.486085415384615 ], [ 29.719879655172413, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 979, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.486085415384615 ], [ 29.720149137931035, -1.486624369230769 ], [ 29.720688103448275, -1.486624369230769 ], [ 29.720688103448275, -1.486085415384615 ], [ 29.720149137931035, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 980, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.486085415384615 ], [ 29.720688103448275, -1.486624369230769 ], [ 29.720957586206897, -1.486624369230769 ], [ 29.720957586206897, -1.486085415384615 ], [ 29.720688103448275, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 981, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.486085415384615 ], [ 29.720957586206897, -1.486354892307692 ], [ 29.721227068965518, -1.486354892307692 ], [ 29.721227068965518, -1.486085415384615 ], [ 29.720957586206897, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 982, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.486085415384615 ], [ 29.721227068965518, -1.486354892307692 ], [ 29.721496551724137, -1.486354892307692 ], [ 29.721496551724137, -1.486085415384615 ], [ 29.721227068965518, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 983, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.486085415384615 ], [ 29.721496551724137, -1.486354892307692 ], [ 29.721766034482759, -1.486354892307692 ], [ 29.721766034482759, -1.486085415384615 ], [ 29.721496551724137, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 984, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.486085415384615 ], [ 29.721766034482759, -1.486354892307692 ], [ 29.72203551724138, -1.486354892307692 ], [ 29.72203551724138, -1.486624369230769 ], [ 29.722305, -1.486624369230769 ], [ 29.722305, -1.486085415384615 ], [ 29.721766034482759, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 985, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.486085415384615 ], [ 29.723382931034482, -1.486624369230769 ], [ 29.723652413793104, -1.486624369230769 ], [ 29.723652413793104, -1.486085415384615 ], [ 29.723382931034482, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 986, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.486085415384615 ], [ 29.724191379310344, -1.486354892307692 ], [ 29.724460862068966, -1.486354892307692 ], [ 29.724460862068966, -1.486085415384615 ], [ 29.724191379310344, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 987, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.486085415384615 ], [ 29.724460862068966, -1.486354892307692 ], [ 29.725808275862068, -1.486354892307692 ], [ 29.725808275862068, -1.486085415384615 ], [ 29.724460862068966, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 988, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.486085415384615 ], [ 29.729311551724138, -1.486354892307692 ], [ 29.729850517241381, -1.486354892307692 ], [ 29.729850517241381, -1.486085415384615 ], [ 29.729311551724138, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 989, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.486085415384615 ], [ 29.729850517241381, -1.486354892307692 ], [ 29.73012, -1.486354892307692 ], [ 29.73012, -1.486085415384615 ], [ 29.729850517241381, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 990, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.486085415384615 ], [ 29.730658965517243, -1.486624369230769 ], [ 29.730928448275861, -1.486624369230769 ], [ 29.730928448275861, -1.486085415384615 ], [ 29.730658965517243, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 991, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.486085415384615 ], [ 29.730928448275861, -1.486354892307692 ], [ 29.731197931034483, -1.486354892307692 ], [ 29.731197931034483, -1.486085415384615 ], [ 29.730928448275861, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 992, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.486085415384615 ], [ 29.731197931034483, -1.486354892307692 ], [ 29.731467413793105, -1.486354892307692 ], [ 29.731467413793105, -1.486085415384615 ], [ 29.731197931034483, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 993, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.486085415384615 ], [ 29.731467413793105, -1.486354892307692 ], [ 29.731736896551723, -1.486354892307692 ], [ 29.731736896551723, -1.486085415384615 ], [ 29.731467413793105, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 994, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.486085415384615 ], [ 29.731736896551723, -1.486354892307692 ], [ 29.732006379310345, -1.486354892307692 ], [ 29.732006379310345, -1.486085415384615 ], [ 29.731736896551723, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 995, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.486085415384615 ], [ 29.732006379310345, -1.486354892307692 ], [ 29.732275862068967, -1.486354892307692 ], [ 29.732275862068967, -1.486085415384615 ], [ 29.732006379310345, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 996, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.486085415384615 ], [ 29.732275862068967, -1.486354892307692 ], [ 29.732545344827585, -1.486354892307692 ], [ 29.732545344827585, -1.486085415384615 ], [ 29.732275862068967, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 997, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.486085415384615 ], [ 29.732545344827585, -1.486354892307692 ], [ 29.732814827586207, -1.486354892307692 ], [ 29.732814827586207, -1.486085415384615 ], [ 29.732545344827585, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 998, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.486085415384615 ], [ 29.732814827586207, -1.486354892307692 ], [ 29.733084310344829, -1.486354892307692 ], [ 29.733084310344829, -1.486085415384615 ], [ 29.732814827586207, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 999, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.486085415384615 ], [ 29.733084310344829, -1.486354892307692 ], [ 29.733353793103447, -1.486354892307692 ], [ 29.733353793103447, -1.486085415384615 ], [ 29.733084310344829, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1000, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.486085415384615 ], [ 29.733353793103447, -1.486354892307692 ], [ 29.733623275862069, -1.486354892307692 ], [ 29.733623275862069, -1.486085415384615 ], [ 29.733353793103447, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1001, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.486085415384615 ], [ 29.733623275862069, -1.486354892307692 ], [ 29.733892758620691, -1.486354892307692 ], [ 29.733892758620691, -1.486085415384615 ], [ 29.733623275862069, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1002, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.486085415384615 ], [ 29.734162241379309, -1.486624369230769 ], [ 29.734431724137931, -1.486624369230769 ], [ 29.734431724137931, -1.486085415384615 ], [ 29.734162241379309, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1003, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.486085415384615 ], [ 29.734431724137931, -1.486354892307692 ], [ 29.734701206896553, -1.486354892307692 ], [ 29.734701206896553, -1.486085415384615 ], [ 29.734431724137931, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1004, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.486085415384615 ], [ 29.734701206896553, -1.486354892307692 ], [ 29.734970689655171, -1.486354892307692 ], [ 29.734970689655171, -1.486085415384615 ], [ 29.734701206896553, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1005, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.486085415384615 ], [ 29.735240172413793, -1.486354892307692 ], [ 29.735779137931036, -1.486354892307692 ], [ 29.735779137931036, -1.486085415384615 ], [ 29.735240172413793, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1006, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.486085415384615 ], [ 29.735779137931036, -1.486354892307692 ], [ 29.739821379310346, -1.486354892307692 ], [ 29.739821379310346, -1.486085415384615 ], [ 29.735779137931036, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1007, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.486085415384615 ], [ 29.739821379310346, -1.486354892307692 ], [ 29.740360344827586, -1.486354892307692 ], [ 29.740360344827586, -1.486085415384615 ], [ 29.739821379310346, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1008, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.486085415384615 ], [ 29.740360344827586, -1.486354892307692 ], [ 29.741168793103448, -1.486354892307692 ], [ 29.741168793103448, -1.486085415384615 ], [ 29.740360344827586, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1009, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.486085415384615 ], [ 29.741168793103448, -1.486354892307692 ], [ 29.741707758620691, -1.486354892307692 ], [ 29.741707758620691, -1.486085415384615 ], [ 29.741168793103448, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1010, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.486085415384615 ], [ 29.741707758620691, -1.486354892307692 ], [ 29.742246724137932, -1.486354892307692 ], [ 29.742246724137932, -1.486085415384615 ], [ 29.741707758620691, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1011, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.486085415384615 ], [ 29.742246724137932, -1.486354892307692 ], [ 29.742516206896553, -1.486354892307692 ], [ 29.742516206896553, -1.486085415384615 ], [ 29.742246724137932, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1012, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.486085415384615 ], [ 29.742516206896553, -1.486354892307692 ], [ 29.743324655172415, -1.486354892307692 ], [ 29.743324655172415, -1.486085415384615 ], [ 29.742516206896553, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1013, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.486085415384615 ], [ 29.743324655172415, -1.486354892307692 ], [ 29.744133103448277, -1.486354892307692 ], [ 29.744133103448277, -1.486085415384615 ], [ 29.743324655172415, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1014, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.486085415384615 ], [ 29.744133103448277, -1.486354892307692 ], [ 29.746019482758619, -1.486354892307692 ], [ 29.746019482758619, -1.486085415384615 ], [ 29.744133103448277, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1015, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.486085415384615 ], [ 29.746019482758619, -1.486354892307692 ], [ 29.746827931034485, -1.486354892307692 ], [ 29.746827931034485, -1.486085415384615 ], [ 29.746019482758619, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1016, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.486085415384615 ], [ 29.746827931034485, -1.486354892307692 ], [ 29.747366896551725, -1.486354892307692 ], [ 29.747366896551725, -1.486085415384615 ], [ 29.746827931034485, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1017, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747366896551725, -1.486085415384615 ], [ 29.747366896551725, -1.486354892307692 ], [ 29.747905862068965, -1.486354892307692 ], [ 29.747905862068965, -1.486085415384615 ], [ 29.747366896551725, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1018, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.486085415384615 ], [ 29.747905862068965, -1.486624369230769 ], [ 29.748175344827587, -1.486624369230769 ], [ 29.748175344827587, -1.486085415384615 ], [ 29.747905862068965, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1019, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.486085415384615 ], [ 29.748175344827587, -1.486354892307692 ], [ 29.748444827586209, -1.486354892307692 ], [ 29.748444827586209, -1.486085415384615 ], [ 29.748175344827587, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1020, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.486085415384615 ], [ 29.748714310344827, -1.486624369230769 ], [ 29.748983793103449, -1.486624369230769 ], [ 29.748983793103449, -1.486085415384615 ], [ 29.748714310344827, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1021, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.486085415384615 ], [ 29.748983793103449, -1.486624369230769 ], [ 29.74925327586207, -1.486624369230769 ], [ 29.74925327586207, -1.486085415384615 ], [ 29.748983793103449, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1022, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.486085415384615 ], [ 29.749792241379311, -1.486354892307692 ], [ 29.750061724137932, -1.486354892307692 ], [ 29.750061724137932, -1.486085415384615 ], [ 29.749792241379311, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1023, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.486085415384615 ], [ 29.750061724137932, -1.486624369230769 ], [ 29.750331206896551, -1.486624369230769 ], [ 29.750331206896551, -1.486085415384615 ], [ 29.750061724137932, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1024, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.486085415384615 ], [ 29.750600689655172, -1.486354892307692 ], [ 29.750870172413794, -1.486354892307692 ], [ 29.750870172413794, -1.486085415384615 ], [ 29.750600689655172, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1025, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.486085415384615 ], [ 29.750870172413794, -1.486354892307692 ], [ 29.751139655172413, -1.486354892307692 ], [ 29.751139655172413, -1.486085415384615 ], [ 29.750870172413794, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1026, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.486085415384615 ], [ 29.751139655172413, -1.486354892307692 ], [ 29.751409137931034, -1.486354892307692 ], [ 29.751409137931034, -1.486085415384615 ], [ 29.751139655172413, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1027, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.486085415384615 ], [ 29.751409137931034, -1.486354892307692 ], [ 29.751678620689656, -1.486354892307692 ], [ 29.751678620689656, -1.486085415384615 ], [ 29.751409137931034, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1028, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.486085415384615 ], [ 29.751678620689656, -1.486354892307692 ], [ 29.751948103448278, -1.486354892307692 ], [ 29.751948103448278, -1.486085415384615 ], [ 29.751678620689656, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1029, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.486085415384615 ], [ 29.751948103448278, -1.486354892307692 ], [ 29.752217586206896, -1.486354892307692 ], [ 29.752217586206896, -1.486085415384615 ], [ 29.751948103448278, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1030, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.486085415384615 ], [ 29.752217586206896, -1.486354892307692 ], [ 29.752487068965518, -1.486354892307692 ], [ 29.752487068965518, -1.486085415384615 ], [ 29.752217586206896, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1031, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.486085415384615 ], [ 29.752487068965518, -1.486354892307692 ], [ 29.75275655172414, -1.486354892307692 ], [ 29.75275655172414, -1.486085415384615 ], [ 29.752487068965518, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1032, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.486085415384615 ], [ 29.753026034482758, -1.486354892307692 ], [ 29.75329551724138, -1.486354892307692 ], [ 29.75329551724138, -1.486085415384615 ], [ 29.753026034482758, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1033, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.486085415384615 ], [ 29.75329551724138, -1.486354892307692 ], [ 29.753565, -1.486354892307692 ], [ 29.753565, -1.486085415384615 ], [ 29.75329551724138, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1034, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.486085415384615 ], [ 29.753565, -1.486354892307692 ], [ 29.75383448275862, -1.486354892307692 ], [ 29.75383448275862, -1.486085415384615 ], [ 29.753565, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1035, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.486085415384615 ], [ 29.75383448275862, -1.486354892307692 ], [ 29.754103965517242, -1.486354892307692 ], [ 29.754103965517242, -1.486085415384615 ], [ 29.75383448275862, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1036, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.486085415384615 ], [ 29.754103965517242, -1.486354892307692 ], [ 29.754373448275864, -1.486354892307692 ], [ 29.754373448275864, -1.486085415384615 ], [ 29.754103965517242, -1.486085415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1037, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.486354892307692 ], [ 29.720957586206897, -1.486624369230769 ], [ 29.721227068965518, -1.486624369230769 ], [ 29.721227068965518, -1.486354892307692 ], [ 29.720957586206897, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1038, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.486354892307692 ], [ 29.721227068965518, -1.486624369230769 ], [ 29.721496551724137, -1.486624369230769 ], [ 29.721496551724137, -1.486354892307692 ], [ 29.721227068965518, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1039, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.486354892307692 ], [ 29.721496551724137, -1.486624369230769 ], [ 29.721766034482759, -1.486624369230769 ], [ 29.721766034482759, -1.486354892307692 ], [ 29.721496551724137, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1040, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.486354892307692 ], [ 29.721766034482759, -1.486624369230769 ], [ 29.72203551724138, -1.486624369230769 ], [ 29.72203551724138, -1.486354892307692 ], [ 29.721766034482759, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1041, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.486354892307692 ], [ 29.72257448275862, -1.486624369230769 ], [ 29.722843965517242, -1.486624369230769 ], [ 29.722843965517242, -1.486354892307692 ], [ 29.72257448275862, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1042, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.486354892307692 ], [ 29.722843965517242, -1.486624369230769 ], [ 29.723113448275861, -1.486624369230769 ], [ 29.723113448275861, -1.486354892307692 ], [ 29.722843965517242, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1043, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.486354892307692 ], [ 29.723113448275861, -1.486624369230769 ], [ 29.723382931034482, -1.486624369230769 ], [ 29.723382931034482, -1.486354892307692 ], [ 29.723113448275861, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1044, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.486354892307692 ], [ 29.723652413793104, -1.486624369230769 ], [ 29.723921896551722, -1.486624369230769 ], [ 29.723921896551722, -1.486354892307692 ], [ 29.723652413793104, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1045, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.486354892307692 ], [ 29.723921896551722, -1.486624369230769 ], [ 29.724191379310344, -1.486624369230769 ], [ 29.724191379310344, -1.486354892307692 ], [ 29.723921896551722, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1046, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.486354892307692 ], [ 29.724191379310344, -1.486624369230769 ], [ 29.72553879310345, -1.486624369230769 ], [ 29.72553879310345, -1.486354892307692 ], [ 29.724191379310344, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1047, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.486354892307692 ], [ 29.72553879310345, -1.486624369230769 ], [ 29.725808275862068, -1.486624369230769 ], [ 29.725808275862068, -1.486354892307692 ], [ 29.72553879310345, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1048, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.486354892307692 ], [ 29.725808275862068, -1.486624369230769 ], [ 29.726886206896552, -1.486624369230769 ], [ 29.726886206896552, -1.486354892307692 ], [ 29.725808275862068, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1049, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.486354892307692 ], [ 29.729042068965516, -1.487702276923077 ], [ 29.729311551724138, -1.487702276923077 ], [ 29.729311551724138, -1.486354892307692 ], [ 29.729042068965516, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1050, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.486354892307692 ], [ 29.729311551724138, -1.486624369230769 ], [ 29.729850517241381, -1.486624369230769 ], [ 29.729850517241381, -1.486354892307692 ], [ 29.729311551724138, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1051, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.486354892307692 ], [ 29.729850517241381, -1.486624369230769 ], [ 29.73012, -1.486624369230769 ], [ 29.73012, -1.486354892307692 ], [ 29.729850517241381, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1052, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.486354892307692 ], [ 29.73012, -1.486624369230769 ], [ 29.730389482758621, -1.486624369230769 ], [ 29.730389482758621, -1.486354892307692 ], [ 29.73012, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1053, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.486354892307692 ], [ 29.730389482758621, -1.486624369230769 ], [ 29.730658965517243, -1.486624369230769 ], [ 29.730658965517243, -1.486354892307692 ], [ 29.730389482758621, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1054, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.486354892307692 ], [ 29.730928448275861, -1.486624369230769 ], [ 29.731197931034483, -1.486624369230769 ], [ 29.731197931034483, -1.486354892307692 ], [ 29.730928448275861, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1055, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.486354892307692 ], [ 29.731197931034483, -1.486624369230769 ], [ 29.731467413793105, -1.486624369230769 ], [ 29.731467413793105, -1.486354892307692 ], [ 29.731197931034483, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1056, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.486354892307692 ], [ 29.731467413793105, -1.486624369230769 ], [ 29.731736896551723, -1.486624369230769 ], [ 29.731736896551723, -1.486354892307692 ], [ 29.731467413793105, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1057, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.486354892307692 ], [ 29.731736896551723, -1.486624369230769 ], [ 29.732006379310345, -1.486624369230769 ], [ 29.732006379310345, -1.486354892307692 ], [ 29.731736896551723, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1058, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.486354892307692 ], [ 29.732006379310345, -1.486624369230769 ], [ 29.732275862068967, -1.486624369230769 ], [ 29.732275862068967, -1.486354892307692 ], [ 29.732006379310345, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1059, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.486354892307692 ], [ 29.732275862068967, -1.486624369230769 ], [ 29.732545344827585, -1.486624369230769 ], [ 29.732545344827585, -1.486354892307692 ], [ 29.732275862068967, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1060, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.486354892307692 ], [ 29.732545344827585, -1.486624369230769 ], [ 29.732814827586207, -1.486624369230769 ], [ 29.732814827586207, -1.486354892307692 ], [ 29.732545344827585, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1061, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.486354892307692 ], [ 29.732814827586207, -1.486624369230769 ], [ 29.733084310344829, -1.486624369230769 ], [ 29.733084310344829, -1.486354892307692 ], [ 29.732814827586207, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1062, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.486354892307692 ], [ 29.733084310344829, -1.486624369230769 ], [ 29.733353793103447, -1.486624369230769 ], [ 29.733353793103447, -1.486354892307692 ], [ 29.733084310344829, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1063, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.486354892307692 ], [ 29.733353793103447, -1.486624369230769 ], [ 29.733623275862069, -1.486624369230769 ], [ 29.733623275862069, -1.486354892307692 ], [ 29.733353793103447, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1064, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.486354892307692 ], [ 29.733623275862069, -1.486624369230769 ], [ 29.733892758620691, -1.486624369230769 ], [ 29.733892758620691, -1.486354892307692 ], [ 29.733623275862069, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1065, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.486354892307692 ], [ 29.733892758620691, -1.486624369230769 ], [ 29.734162241379309, -1.486624369230769 ], [ 29.734162241379309, -1.486354892307692 ], [ 29.733892758620691, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1066, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.486354892307692 ], [ 29.734431724137931, -1.486893846153846 ], [ 29.734701206896553, -1.486893846153846 ], [ 29.734701206896553, -1.486354892307692 ], [ 29.734431724137931, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1067, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.486354892307692 ], [ 29.734701206896553, -1.486624369230769 ], [ 29.734970689655171, -1.486624369230769 ], [ 29.734970689655171, -1.486354892307692 ], [ 29.734701206896553, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1068, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.486354892307692 ], [ 29.734970689655171, -1.486624369230769 ], [ 29.735240172413793, -1.486624369230769 ], [ 29.735240172413793, -1.486354892307692 ], [ 29.734970689655171, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1069, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.486354892307692 ], [ 29.735240172413793, -1.486624369230769 ], [ 29.735509655172415, -1.486624369230769 ], [ 29.735509655172415, -1.486354892307692 ], [ 29.735240172413793, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1070, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.486354892307692 ], [ 29.735509655172415, -1.486624369230769 ], [ 29.736048620689655, -1.486624369230769 ], [ 29.736048620689655, -1.486354892307692 ], [ 29.735509655172415, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1071, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.486354892307692 ], [ 29.736048620689655, -1.486624369230769 ], [ 29.739551896551724, -1.486624369230769 ], [ 29.739551896551724, -1.486354892307692 ], [ 29.736048620689655, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1072, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.486354892307692 ], [ 29.739551896551724, -1.486624369230769 ], [ 29.740090862068964, -1.486624369230769 ], [ 29.740090862068964, -1.486354892307692 ], [ 29.739551896551724, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1073, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.486354892307692 ], [ 29.740090862068964, -1.486624369230769 ], [ 29.74089931034483, -1.486624369230769 ], [ 29.74089931034483, -1.486354892307692 ], [ 29.740090862068964, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1074, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.486354892307692 ], [ 29.74089931034483, -1.486624369230769 ], [ 29.741707758620691, -1.486624369230769 ], [ 29.741707758620691, -1.486354892307692 ], [ 29.74089931034483, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1075, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.486354892307692 ], [ 29.741707758620691, -1.486624369230769 ], [ 29.742246724137932, -1.486624369230769 ], [ 29.742246724137932, -1.486354892307692 ], [ 29.741707758620691, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1076, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.486354892307692 ], [ 29.742246724137932, -1.486624369230769 ], [ 29.742516206896553, -1.486624369230769 ], [ 29.742516206896553, -1.486354892307692 ], [ 29.742246724137932, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1077, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.486354892307692 ], [ 29.742516206896553, -1.486624369230769 ], [ 29.742785689655172, -1.486624369230769 ], [ 29.742785689655172, -1.486354892307692 ], [ 29.742516206896553, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1078, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.486354892307692 ], [ 29.742785689655172, -1.486624369230769 ], [ 29.743055172413793, -1.486624369230769 ], [ 29.743055172413793, -1.486354892307692 ], [ 29.742785689655172, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1079, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.486354892307692 ], [ 29.743055172413793, -1.486624369230769 ], [ 29.743594137931034, -1.486624369230769 ], [ 29.743594137931034, -1.486354892307692 ], [ 29.743055172413793, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1080, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.486354892307692 ], [ 29.743594137931034, -1.486624369230769 ], [ 29.746019482758619, -1.486624369230769 ], [ 29.746019482758619, -1.486893846153846 ], [ 29.748175344827587, -1.486893846153846 ], [ 29.748175344827587, -1.486624369230769 ], [ 29.746558448275863, -1.486624369230769 ], [ 29.746558448275863, -1.486354892307692 ], [ 29.743594137931034, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1081, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.486354892307692 ], [ 29.746558448275863, -1.486624369230769 ], [ 29.747097413793103, -1.486624369230769 ], [ 29.747097413793103, -1.486354892307692 ], [ 29.746558448275863, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1082, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747097413793103, -1.486354892307692 ], [ 29.747097413793103, -1.486624369230769 ], [ 29.747905862068965, -1.486624369230769 ], [ 29.747905862068965, -1.486354892307692 ], [ 29.747097413793103, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1083, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.486354892307692 ], [ 29.748175344827587, -1.486893846153846 ], [ 29.748444827586209, -1.486893846153846 ], [ 29.748444827586209, -1.486354892307692 ], [ 29.748175344827587, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1084, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.486354892307692 ], [ 29.748444827586209, -1.486893846153846 ], [ 29.748714310344827, -1.486893846153846 ], [ 29.748714310344827, -1.486354892307692 ], [ 29.748444827586209, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1085, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.486354892307692 ], [ 29.74925327586207, -1.486624369230769 ], [ 29.749522758620689, -1.486624369230769 ], [ 29.749522758620689, -1.486354892307692 ], [ 29.74925327586207, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1086, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.486354892307692 ], [ 29.749522758620689, -1.486624369230769 ], [ 29.749792241379311, -1.486624369230769 ], [ 29.749792241379311, -1.486354892307692 ], [ 29.749522758620689, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1087, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.486354892307692 ], [ 29.749792241379311, -1.486893846153846 ], [ 29.750061724137932, -1.486893846153846 ], [ 29.750061724137932, -1.486354892307692 ], [ 29.749792241379311, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1088, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.486354892307692 ], [ 29.750331206896551, -1.486624369230769 ], [ 29.750600689655172, -1.486624369230769 ], [ 29.750600689655172, -1.486354892307692 ], [ 29.750331206896551, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1089, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.486354892307692 ], [ 29.750600689655172, -1.486624369230769 ], [ 29.750870172413794, -1.486624369230769 ], [ 29.750870172413794, -1.486354892307692 ], [ 29.750600689655172, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1090, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.486354892307692 ], [ 29.750870172413794, -1.486624369230769 ], [ 29.751139655172413, -1.486624369230769 ], [ 29.751139655172413, -1.486354892307692 ], [ 29.750870172413794, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1091, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.486354892307692 ], [ 29.751139655172413, -1.486624369230769 ], [ 29.751409137931034, -1.486624369230769 ], [ 29.751409137931034, -1.486354892307692 ], [ 29.751139655172413, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1092, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.486354892307692 ], [ 29.751409137931034, -1.486624369230769 ], [ 29.751678620689656, -1.486624369230769 ], [ 29.751678620689656, -1.486354892307692 ], [ 29.751409137931034, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1093, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.486354892307692 ], [ 29.751678620689656, -1.486624369230769 ], [ 29.751948103448278, -1.486624369230769 ], [ 29.751948103448278, -1.486354892307692 ], [ 29.751678620689656, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1094, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.486354892307692 ], [ 29.751948103448278, -1.486624369230769 ], [ 29.752217586206896, -1.486624369230769 ], [ 29.752217586206896, -1.486354892307692 ], [ 29.751948103448278, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1095, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.486354892307692 ], [ 29.752217586206896, -1.486624369230769 ], [ 29.752487068965518, -1.486624369230769 ], [ 29.752487068965518, -1.486354892307692 ], [ 29.752217586206896, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1096, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.486354892307692 ], [ 29.752487068965518, -1.486624369230769 ], [ 29.75275655172414, -1.486624369230769 ], [ 29.75275655172414, -1.486354892307692 ], [ 29.752487068965518, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1097, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.486354892307692 ], [ 29.75275655172414, -1.486624369230769 ], [ 29.753026034482758, -1.486624369230769 ], [ 29.753026034482758, -1.486354892307692 ], [ 29.75275655172414, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1098, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.486354892307692 ], [ 29.753026034482758, -1.486624369230769 ], [ 29.75329551724138, -1.486624369230769 ], [ 29.75329551724138, -1.486354892307692 ], [ 29.753026034482758, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1099, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.486354892307692 ], [ 29.75329551724138, -1.486624369230769 ], [ 29.753565, -1.486624369230769 ], [ 29.753565, -1.486354892307692 ], [ 29.75329551724138, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1100, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.486354892307692 ], [ 29.753565, -1.486624369230769 ], [ 29.75383448275862, -1.486624369230769 ], [ 29.75383448275862, -1.486354892307692 ], [ 29.753565, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1101, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.486354892307692 ], [ 29.75383448275862, -1.486624369230769 ], [ 29.754103965517242, -1.486624369230769 ], [ 29.754103965517242, -1.486354892307692 ], [ 29.75383448275862, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1102, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.486354892307692 ], [ 29.754103965517242, -1.486624369230769 ], [ 29.754373448275864, -1.486624369230769 ], [ 29.754373448275864, -1.486354892307692 ], [ 29.754103965517242, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1103, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.486354892307692 ], [ 29.754373448275864, -1.486624369230769 ], [ 29.754642931034482, -1.486624369230769 ], [ 29.754642931034482, -1.486893846153846 ], [ 29.754912413793104, -1.486893846153846 ], [ 29.754912413793104, -1.486354892307692 ], [ 29.754373448275864, -1.486354892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1104, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.486624369230769 ], [ 29.719071206896551, -1.4874328 ], [ 29.719340689655173, -1.4874328 ], [ 29.719340689655173, -1.486624369230769 ], [ 29.719071206896551, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1105, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.486624369230769 ], [ 29.719879655172413, -1.486893846153846 ], [ 29.720149137931035, -1.486893846153846 ], [ 29.720149137931035, -1.486624369230769 ], [ 29.719879655172413, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1106, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.486624369230769 ], [ 29.720149137931035, -1.486893846153846 ], [ 29.720688103448275, -1.486893846153846 ], [ 29.720688103448275, -1.486624369230769 ], [ 29.720149137931035, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1107, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.486624369230769 ], [ 29.720688103448275, -1.486893846153846 ], [ 29.720957586206897, -1.486893846153846 ], [ 29.720957586206897, -1.486624369230769 ], [ 29.720688103448275, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1108, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.486624369230769 ], [ 29.720957586206897, -1.486893846153846 ], [ 29.721227068965518, -1.486893846153846 ], [ 29.721227068965518, -1.486624369230769 ], [ 29.720957586206897, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1109, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.486624369230769 ], [ 29.721227068965518, -1.486893846153846 ], [ 29.721496551724137, -1.486893846153846 ], [ 29.721496551724137, -1.486624369230769 ], [ 29.721227068965518, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1110, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.486624369230769 ], [ 29.721496551724137, -1.486893846153846 ], [ 29.721766034482759, -1.486893846153846 ], [ 29.721766034482759, -1.486624369230769 ], [ 29.721496551724137, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1111, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.486624369230769 ], [ 29.721766034482759, -1.486893846153846 ], [ 29.72203551724138, -1.486893846153846 ], [ 29.72203551724138, -1.486624369230769 ], [ 29.721766034482759, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1112, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.486624369230769 ], [ 29.72203551724138, -1.486893846153846 ], [ 29.72257448275862, -1.486893846153846 ], [ 29.72257448275862, -1.486624369230769 ], [ 29.72203551724138, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1113, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.486624369230769 ], [ 29.72257448275862, -1.486893846153846 ], [ 29.722843965517242, -1.486893846153846 ], [ 29.722843965517242, -1.486624369230769 ], [ 29.72257448275862, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1114, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.486624369230769 ], [ 29.722843965517242, -1.486893846153846 ], [ 29.723113448275861, -1.486893846153846 ], [ 29.723113448275861, -1.486624369230769 ], [ 29.722843965517242, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1115, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.486624369230769 ], [ 29.723113448275861, -1.486893846153846 ], [ 29.723382931034482, -1.486893846153846 ], [ 29.723382931034482, -1.486624369230769 ], [ 29.723113448275861, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1116, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.486624369230769 ], [ 29.723382931034482, -1.486893846153846 ], [ 29.723652413793104, -1.486893846153846 ], [ 29.723652413793104, -1.486624369230769 ], [ 29.723382931034482, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1117, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.486624369230769 ], [ 29.723652413793104, -1.486893846153846 ], [ 29.723921896551722, -1.486893846153846 ], [ 29.723921896551722, -1.486624369230769 ], [ 29.723652413793104, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1118, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.486624369230769 ], [ 29.723921896551722, -1.486893846153846 ], [ 29.724191379310344, -1.486893846153846 ], [ 29.724191379310344, -1.486624369230769 ], [ 29.723921896551722, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1119, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.486624369230769 ], [ 29.724191379310344, -1.486893846153846 ], [ 29.72553879310345, -1.486893846153846 ], [ 29.72553879310345, -1.486624369230769 ], [ 29.724191379310344, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1120, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.486624369230769 ], [ 29.72553879310345, -1.486893846153846 ], [ 29.72661672413793, -1.486893846153846 ], [ 29.72661672413793, -1.486624369230769 ], [ 29.72553879310345, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1121, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.486624369230769 ], [ 29.72661672413793, -1.486893846153846 ], [ 29.726886206896552, -1.486893846153846 ], [ 29.726886206896552, -1.486624369230769 ], [ 29.72661672413793, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1122, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.486624369230769 ], [ 29.726886206896552, -1.486893846153846 ], [ 29.727155689655174, -1.486893846153846 ], [ 29.727155689655174, -1.486624369230769 ], [ 29.726886206896552, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1123, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.486624369230769 ], [ 29.73012, -1.486893846153846 ], [ 29.730389482758621, -1.486893846153846 ], [ 29.730389482758621, -1.486624369230769 ], [ 29.73012, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1124, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.486624369230769 ], [ 29.730389482758621, -1.486893846153846 ], [ 29.730658965517243, -1.486893846153846 ], [ 29.730658965517243, -1.486624369230769 ], [ 29.730389482758621, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1125, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.486624369230769 ], [ 29.730658965517243, -1.486893846153846 ], [ 29.730928448275861, -1.486893846153846 ], [ 29.730928448275861, -1.486624369230769 ], [ 29.730658965517243, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1126, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.486624369230769 ], [ 29.730928448275861, -1.486893846153846 ], [ 29.731197931034483, -1.486893846153846 ], [ 29.731197931034483, -1.486624369230769 ], [ 29.730928448275861, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1127, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.486624369230769 ], [ 29.731197931034483, -1.486893846153846 ], [ 29.731467413793105, -1.486893846153846 ], [ 29.731467413793105, -1.486624369230769 ], [ 29.731197931034483, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1128, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.486624369230769 ], [ 29.731467413793105, -1.486893846153846 ], [ 29.731736896551723, -1.486893846153846 ], [ 29.731736896551723, -1.486624369230769 ], [ 29.731467413793105, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1129, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.486624369230769 ], [ 29.731736896551723, -1.486893846153846 ], [ 29.732006379310345, -1.486893846153846 ], [ 29.732006379310345, -1.486624369230769 ], [ 29.731736896551723, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1130, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.486624369230769 ], [ 29.732006379310345, -1.486893846153846 ], [ 29.732275862068967, -1.486893846153846 ], [ 29.732275862068967, -1.486624369230769 ], [ 29.732006379310345, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1131, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.486624369230769 ], [ 29.732275862068967, -1.486893846153846 ], [ 29.732545344827585, -1.486893846153846 ], [ 29.732545344827585, -1.486624369230769 ], [ 29.732275862068967, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1132, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.486624369230769 ], [ 29.732545344827585, -1.486893846153846 ], [ 29.732814827586207, -1.486893846153846 ], [ 29.732814827586207, -1.486624369230769 ], [ 29.732545344827585, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1133, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.486624369230769 ], [ 29.732814827586207, -1.486893846153846 ], [ 29.733084310344829, -1.486893846153846 ], [ 29.733084310344829, -1.486624369230769 ], [ 29.732814827586207, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1134, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.486624369230769 ], [ 29.733084310344829, -1.486893846153846 ], [ 29.733353793103447, -1.486893846153846 ], [ 29.733353793103447, -1.486624369230769 ], [ 29.733084310344829, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1135, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.486624369230769 ], [ 29.733353793103447, -1.486893846153846 ], [ 29.733623275862069, -1.486893846153846 ], [ 29.733623275862069, -1.486624369230769 ], [ 29.733353793103447, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1136, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.486624369230769 ], [ 29.733623275862069, -1.486893846153846 ], [ 29.733892758620691, -1.486893846153846 ], [ 29.733892758620691, -1.486624369230769 ], [ 29.733623275862069, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1137, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.486624369230769 ], [ 29.733892758620691, -1.486893846153846 ], [ 29.734162241379309, -1.486893846153846 ], [ 29.734162241379309, -1.486624369230769 ], [ 29.733892758620691, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1138, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.486624369230769 ], [ 29.734162241379309, -1.486893846153846 ], [ 29.734431724137931, -1.486893846153846 ], [ 29.734431724137931, -1.486624369230769 ], [ 29.734162241379309, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1139, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.486624369230769 ], [ 29.734701206896553, -1.486893846153846 ], [ 29.734970689655171, -1.486893846153846 ], [ 29.734970689655171, -1.486624369230769 ], [ 29.734701206896553, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1140, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.486624369230769 ], [ 29.734970689655171, -1.486893846153846 ], [ 29.735240172413793, -1.486893846153846 ], [ 29.735240172413793, -1.486624369230769 ], [ 29.734970689655171, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1141, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.486624369230769 ], [ 29.735240172413793, -1.486893846153846 ], [ 29.735509655172415, -1.486893846153846 ], [ 29.735509655172415, -1.486624369230769 ], [ 29.735240172413793, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1142, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.486624369230769 ], [ 29.735509655172415, -1.486893846153846 ], [ 29.736318103448276, -1.486893846153846 ], [ 29.736318103448276, -1.486624369230769 ], [ 29.735509655172415, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1143, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.486624369230769 ], [ 29.736318103448276, -1.486893846153846 ], [ 29.739282413793102, -1.486893846153846 ], [ 29.739282413793102, -1.486624369230769 ], [ 29.736318103448276, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1144, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.486624369230769 ], [ 29.739282413793102, -1.486893846153846 ], [ 29.740090862068964, -1.486893846153846 ], [ 29.740090862068964, -1.486624369230769 ], [ 29.739282413793102, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1145, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.486624369230769 ], [ 29.740090862068964, -1.486893846153846 ], [ 29.740629827586208, -1.486893846153846 ], [ 29.740629827586208, -1.486624369230769 ], [ 29.740090862068964, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1146, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.486624369230769 ], [ 29.740629827586208, -1.486893846153846 ], [ 29.74143827586207, -1.486893846153846 ], [ 29.74143827586207, -1.486624369230769 ], [ 29.740629827586208, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1147, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.486624369230769 ], [ 29.74143827586207, -1.486893846153846 ], [ 29.74197724137931, -1.486893846153846 ], [ 29.74197724137931, -1.486624369230769 ], [ 29.74143827586207, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1148, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.486624369230769 ], [ 29.74197724137931, -1.486893846153846 ], [ 29.742516206896553, -1.486893846153846 ], [ 29.742516206896553, -1.486624369230769 ], [ 29.74197724137931, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1149, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.486624369230769 ], [ 29.742516206896553, -1.486893846153846 ], [ 29.742785689655172, -1.486893846153846 ], [ 29.742785689655172, -1.486624369230769 ], [ 29.742516206896553, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1150, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.486624369230769 ], [ 29.742785689655172, -1.487163323076923 ], [ 29.743055172413793, -1.487163323076923 ], [ 29.743055172413793, -1.486624369230769 ], [ 29.742785689655172, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1151, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.486624369230769 ], [ 29.743055172413793, -1.487163323076923 ], [ 29.743863620689655, -1.487163323076923 ], [ 29.743863620689655, -1.486624369230769 ], [ 29.743055172413793, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1152, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.486624369230769 ], [ 29.743863620689655, -1.487163323076923 ], [ 29.744402586206895, -1.487163323076923 ], [ 29.744402586206895, -1.486893846153846 ], [ 29.745480517241379, -1.486893846153846 ], [ 29.745480517241379, -1.486624369230769 ], [ 29.743863620689655, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1153, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.486624369230769 ], [ 29.745480517241379, -1.486893846153846 ], [ 29.746019482758619, -1.486893846153846 ], [ 29.746019482758619, -1.486624369230769 ], [ 29.745480517241379, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1154, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.486624369230769 ], [ 29.748714310344827, -1.486893846153846 ], [ 29.748983793103449, -1.486893846153846 ], [ 29.748983793103449, -1.486624369230769 ], [ 29.748714310344827, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1155, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.486624369230769 ], [ 29.748983793103449, -1.486893846153846 ], [ 29.74925327586207, -1.486893846153846 ], [ 29.74925327586207, -1.486624369230769 ], [ 29.748983793103449, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1156, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.486624369230769 ], [ 29.74925327586207, -1.486893846153846 ], [ 29.749522758620689, -1.486893846153846 ], [ 29.749522758620689, -1.486624369230769 ], [ 29.74925327586207, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1157, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.486624369230769 ], [ 29.749522758620689, -1.487163323076923 ], [ 29.749792241379311, -1.487163323076923 ], [ 29.749792241379311, -1.486624369230769 ], [ 29.749522758620689, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1158, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.486624369230769 ], [ 29.750061724137932, -1.486893846153846 ], [ 29.750331206896551, -1.486893846153846 ], [ 29.750331206896551, -1.486624369230769 ], [ 29.750061724137932, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1159, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.486624369230769 ], [ 29.750331206896551, -1.486893846153846 ], [ 29.750600689655172, -1.486893846153846 ], [ 29.750600689655172, -1.486624369230769 ], [ 29.750331206896551, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1160, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.486624369230769 ], [ 29.750600689655172, -1.486893846153846 ], [ 29.750870172413794, -1.486893846153846 ], [ 29.750870172413794, -1.486624369230769 ], [ 29.750600689655172, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1161, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.486624369230769 ], [ 29.750870172413794, -1.486893846153846 ], [ 29.751139655172413, -1.486893846153846 ], [ 29.751139655172413, -1.486624369230769 ], [ 29.750870172413794, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1162, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.486624369230769 ], [ 29.751139655172413, -1.486893846153846 ], [ 29.751409137931034, -1.486893846153846 ], [ 29.751409137931034, -1.486624369230769 ], [ 29.751139655172413, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1163, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.486624369230769 ], [ 29.751409137931034, -1.486893846153846 ], [ 29.751678620689656, -1.486893846153846 ], [ 29.751678620689656, -1.486624369230769 ], [ 29.751409137931034, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1164, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.486624369230769 ], [ 29.751678620689656, -1.486893846153846 ], [ 29.751948103448278, -1.486893846153846 ], [ 29.751948103448278, -1.486624369230769 ], [ 29.751678620689656, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1165, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.486624369230769 ], [ 29.751948103448278, -1.486893846153846 ], [ 29.752217586206896, -1.486893846153846 ], [ 29.752217586206896, -1.486624369230769 ], [ 29.751948103448278, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1166, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.486624369230769 ], [ 29.752217586206896, -1.486893846153846 ], [ 29.752487068965518, -1.486893846153846 ], [ 29.752487068965518, -1.486624369230769 ], [ 29.752217586206896, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1167, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.486624369230769 ], [ 29.752487068965518, -1.486893846153846 ], [ 29.75275655172414, -1.486893846153846 ], [ 29.75275655172414, -1.486624369230769 ], [ 29.752487068965518, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1168, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.486624369230769 ], [ 29.75275655172414, -1.486893846153846 ], [ 29.753026034482758, -1.486893846153846 ], [ 29.753026034482758, -1.486624369230769 ], [ 29.75275655172414, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1169, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.486624369230769 ], [ 29.753026034482758, -1.486893846153846 ], [ 29.75329551724138, -1.486893846153846 ], [ 29.75329551724138, -1.486624369230769 ], [ 29.753026034482758, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1170, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.486624369230769 ], [ 29.75329551724138, -1.486893846153846 ], [ 29.753565, -1.486893846153846 ], [ 29.753565, -1.486624369230769 ], [ 29.75329551724138, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1171, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.486624369230769 ], [ 29.753565, -1.486893846153846 ], [ 29.75383448275862, -1.486893846153846 ], [ 29.75383448275862, -1.486624369230769 ], [ 29.753565, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1172, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.486624369230769 ], [ 29.75383448275862, -1.486893846153846 ], [ 29.754103965517242, -1.486893846153846 ], [ 29.754103965517242, -1.486624369230769 ], [ 29.75383448275862, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1173, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.486624369230769 ], [ 29.754103965517242, -1.486893846153846 ], [ 29.754373448275864, -1.486893846153846 ], [ 29.754373448275864, -1.487163323076923 ], [ 29.754642931034482, -1.487163323076923 ], [ 29.754642931034482, -1.486624369230769 ], [ 29.754103965517242, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1174, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.486893846153846 ], [ 29.720149137931035, -1.487163323076923 ], [ 29.720688103448275, -1.487163323076923 ], [ 29.720688103448275, -1.486893846153846 ], [ 29.720149137931035, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1175, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.486893846153846 ], [ 29.720688103448275, -1.487163323076923 ], [ 29.720957586206897, -1.487163323076923 ], [ 29.720957586206897, -1.486893846153846 ], [ 29.720688103448275, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1176, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.486893846153846 ], [ 29.720957586206897, -1.487163323076923 ], [ 29.721227068965518, -1.487163323076923 ], [ 29.721227068965518, -1.486893846153846 ], [ 29.720957586206897, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1177, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.486893846153846 ], [ 29.721227068965518, -1.487163323076923 ], [ 29.721496551724137, -1.487163323076923 ], [ 29.721496551724137, -1.486893846153846 ], [ 29.721227068965518, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1178, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.486893846153846 ], [ 29.721496551724137, -1.487163323076923 ], [ 29.721766034482759, -1.487163323076923 ], [ 29.721766034482759, -1.486893846153846 ], [ 29.721496551724137, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1179, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.486893846153846 ], [ 29.721766034482759, -1.487163323076923 ], [ 29.72203551724138, -1.487163323076923 ], [ 29.72203551724138, -1.486893846153846 ], [ 29.721766034482759, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1180, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.486893846153846 ], [ 29.72203551724138, -1.487163323076923 ], [ 29.722305, -1.487163323076923 ], [ 29.722305, -1.486893846153846 ], [ 29.72203551724138, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1181, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.486893846153846 ], [ 29.722305, -1.487163323076923 ], [ 29.72257448275862, -1.487163323076923 ], [ 29.72257448275862, -1.486893846153846 ], [ 29.722305, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1182, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.486893846153846 ], [ 29.72257448275862, -1.487163323076923 ], [ 29.722843965517242, -1.487163323076923 ], [ 29.722843965517242, -1.486893846153846 ], [ 29.72257448275862, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1183, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.486893846153846 ], [ 29.722843965517242, -1.487163323076923 ], [ 29.723113448275861, -1.487163323076923 ], [ 29.723113448275861, -1.486893846153846 ], [ 29.722843965517242, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1184, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.486893846153846 ], [ 29.723113448275861, -1.487163323076923 ], [ 29.723382931034482, -1.487163323076923 ], [ 29.723382931034482, -1.486893846153846 ], [ 29.723113448275861, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1185, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.486893846153846 ], [ 29.723382931034482, -1.487163323076923 ], [ 29.723652413793104, -1.487163323076923 ], [ 29.723652413793104, -1.486893846153846 ], [ 29.723382931034482, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1186, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.486893846153846 ], [ 29.723652413793104, -1.487163323076923 ], [ 29.723921896551722, -1.487163323076923 ], [ 29.723921896551722, -1.486893846153846 ], [ 29.723652413793104, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1187, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.486893846153846 ], [ 29.723921896551722, -1.487163323076923 ], [ 29.724460862068966, -1.487163323076923 ], [ 29.724460862068966, -1.486893846153846 ], [ 29.723921896551722, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1188, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.486893846153846 ], [ 29.724460862068966, -1.487163323076923 ], [ 29.725269310344828, -1.487163323076923 ], [ 29.725269310344828, -1.486893846153846 ], [ 29.724460862068966, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1189, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.486893846153846 ], [ 29.725269310344828, -1.487163323076923 ], [ 29.72553879310345, -1.487163323076923 ], [ 29.72553879310345, -1.486893846153846 ], [ 29.725269310344828, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1190, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.486893846153846 ], [ 29.72553879310345, -1.487163323076923 ], [ 29.72661672413793, -1.487163323076923 ], [ 29.72661672413793, -1.486893846153846 ], [ 29.72553879310345, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1191, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.486893846153846 ], [ 29.72661672413793, -1.487163323076923 ], [ 29.726886206896552, -1.487163323076923 ], [ 29.726886206896552, -1.486893846153846 ], [ 29.72661672413793, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1192, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.486893846153846 ], [ 29.726886206896552, -1.487163323076923 ], [ 29.727155689655174, -1.487163323076923 ], [ 29.727155689655174, -1.486893846153846 ], [ 29.726886206896552, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1193, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.486893846153846 ], [ 29.727155689655174, -1.487163323076923 ], [ 29.727694655172414, -1.487163323076923 ], [ 29.727694655172414, -1.486893846153846 ], [ 29.727155689655174, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1194, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.486893846153846 ], [ 29.73012, -1.487702276923077 ], [ 29.730389482758621, -1.487702276923077 ], [ 29.730389482758621, -1.486893846153846 ], [ 29.73012, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1195, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.486893846153846 ], [ 29.730658965517243, -1.487163323076923 ], [ 29.730928448275861, -1.487163323076923 ], [ 29.730928448275861, -1.486893846153846 ], [ 29.730658965517243, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1196, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.486893846153846 ], [ 29.730928448275861, -1.487163323076923 ], [ 29.731197931034483, -1.487163323076923 ], [ 29.731197931034483, -1.486893846153846 ], [ 29.730928448275861, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1197, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.486893846153846 ], [ 29.731197931034483, -1.487163323076923 ], [ 29.731467413793105, -1.487163323076923 ], [ 29.731467413793105, -1.486893846153846 ], [ 29.731197931034483, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1198, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.486893846153846 ], [ 29.731467413793105, -1.487163323076923 ], [ 29.731736896551723, -1.487163323076923 ], [ 29.731736896551723, -1.486893846153846 ], [ 29.731467413793105, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1199, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.486893846153846 ], [ 29.731736896551723, -1.487163323076923 ], [ 29.732006379310345, -1.487163323076923 ], [ 29.732006379310345, -1.486893846153846 ], [ 29.731736896551723, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1200, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.486893846153846 ], [ 29.732006379310345, -1.487163323076923 ], [ 29.732275862068967, -1.487163323076923 ], [ 29.732275862068967, -1.486893846153846 ], [ 29.732006379310345, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1201, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.486893846153846 ], [ 29.732275862068967, -1.487163323076923 ], [ 29.732545344827585, -1.487163323076923 ], [ 29.732545344827585, -1.486893846153846 ], [ 29.732275862068967, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1202, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.486893846153846 ], [ 29.732545344827585, -1.487163323076923 ], [ 29.732814827586207, -1.487163323076923 ], [ 29.732814827586207, -1.486893846153846 ], [ 29.732545344827585, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1203, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.486893846153846 ], [ 29.732814827586207, -1.487163323076923 ], [ 29.733084310344829, -1.487163323076923 ], [ 29.733084310344829, -1.486893846153846 ], [ 29.732814827586207, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1204, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.486893846153846 ], [ 29.733084310344829, -1.487163323076923 ], [ 29.733353793103447, -1.487163323076923 ], [ 29.733353793103447, -1.486893846153846 ], [ 29.733084310344829, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1205, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.486893846153846 ], [ 29.733353793103447, -1.487163323076923 ], [ 29.733623275862069, -1.487163323076923 ], [ 29.733623275862069, -1.486893846153846 ], [ 29.733353793103447, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1206, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.486893846153846 ], [ 29.733623275862069, -1.487163323076923 ], [ 29.733892758620691, -1.487163323076923 ], [ 29.733892758620691, -1.486893846153846 ], [ 29.733623275862069, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1207, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.486893846153846 ], [ 29.733892758620691, -1.487163323076923 ], [ 29.734162241379309, -1.487163323076923 ], [ 29.734162241379309, -1.486893846153846 ], [ 29.733892758620691, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1208, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.486893846153846 ], [ 29.734162241379309, -1.487163323076923 ], [ 29.734431724137931, -1.487163323076923 ], [ 29.734431724137931, -1.486893846153846 ], [ 29.734162241379309, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1209, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.486893846153846 ], [ 29.734431724137931, -1.487163323076923 ], [ 29.734701206896553, -1.487163323076923 ], [ 29.734701206896553, -1.486893846153846 ], [ 29.734431724137931, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1210, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.486893846153846 ], [ 29.734701206896553, -1.487163323076923 ], [ 29.734970689655171, -1.487163323076923 ], [ 29.734970689655171, -1.486893846153846 ], [ 29.734701206896553, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1211, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.486893846153846 ], [ 29.734970689655171, -1.487163323076923 ], [ 29.735240172413793, -1.487163323076923 ], [ 29.735240172413793, -1.486893846153846 ], [ 29.734970689655171, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1212, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.486893846153846 ], [ 29.735240172413793, -1.487163323076923 ], [ 29.735509655172415, -1.487163323076923 ], [ 29.735509655172415, -1.486893846153846 ], [ 29.735240172413793, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1213, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.486893846153846 ], [ 29.735509655172415, -1.487163323076923 ], [ 29.735779137931036, -1.487163323076923 ], [ 29.735779137931036, -1.486893846153846 ], [ 29.735509655172415, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1214, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.486893846153846 ], [ 29.735779137931036, -1.487163323076923 ], [ 29.739821379310346, -1.487163323076923 ], [ 29.739821379310346, -1.486893846153846 ], [ 29.735779137931036, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1215, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.486893846153846 ], [ 29.739821379310346, -1.487163323076923 ], [ 29.740360344827586, -1.487163323076923 ], [ 29.740360344827586, -1.486893846153846 ], [ 29.739821379310346, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1216, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.486893846153846 ], [ 29.740360344827586, -1.487163323076923 ], [ 29.741168793103448, -1.487163323076923 ], [ 29.741168793103448, -1.486893846153846 ], [ 29.740360344827586, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1217, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.486893846153846 ], [ 29.741168793103448, -1.487163323076923 ], [ 29.74197724137931, -1.487163323076923 ], [ 29.74197724137931, -1.486893846153846 ], [ 29.741168793103448, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1218, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.486893846153846 ], [ 29.74197724137931, -1.487163323076923 ], [ 29.742246724137932, -1.487163323076923 ], [ 29.742246724137932, -1.486893846153846 ], [ 29.74197724137931, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1219, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.486893846153846 ], [ 29.742246724137932, -1.487163323076923 ], [ 29.742785689655172, -1.487163323076923 ], [ 29.742785689655172, -1.486893846153846 ], [ 29.742246724137932, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1220, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.486893846153846 ], [ 29.744402586206895, -1.487163323076923 ], [ 29.744941551724139, -1.487163323076923 ], [ 29.744941551724139, -1.486893846153846 ], [ 29.744402586206895, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1221, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.486893846153846 ], [ 29.744941551724139, -1.487163323076923 ], [ 29.745211034482757, -1.487163323076923 ], [ 29.745211034482757, -1.486893846153846 ], [ 29.744941551724139, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1222, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.486893846153846 ], [ 29.745211034482757, -1.487163323076923 ], [ 29.74575, -1.487163323076923 ], [ 29.74575, -1.486893846153846 ], [ 29.745211034482757, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1223, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.486893846153846 ], [ 29.74575, -1.487163323076923 ], [ 29.746558448275863, -1.487163323076923 ], [ 29.746558448275863, -1.486893846153846 ], [ 29.74575, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1224, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.486893846153846 ], [ 29.746558448275863, -1.487163323076923 ], [ 29.748175344827587, -1.487163323076923 ], [ 29.748175344827587, -1.486893846153846 ], [ 29.746558448275863, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1225, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.486893846153846 ], [ 29.748175344827587, -1.487163323076923 ], [ 29.748714310344827, -1.487163323076923 ], [ 29.748714310344827, -1.486893846153846 ], [ 29.748175344827587, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1226, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.486893846153846 ], [ 29.748983793103449, -1.487163323076923 ], [ 29.74925327586207, -1.487163323076923 ], [ 29.74925327586207, -1.486893846153846 ], [ 29.748983793103449, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1227, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.486893846153846 ], [ 29.74925327586207, -1.487163323076923 ], [ 29.749522758620689, -1.487163323076923 ], [ 29.749522758620689, -1.486893846153846 ], [ 29.74925327586207, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1228, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.486893846153846 ], [ 29.749792241379311, -1.487163323076923 ], [ 29.750061724137932, -1.487163323076923 ], [ 29.750061724137932, -1.486893846153846 ], [ 29.749792241379311, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1229, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.486893846153846 ], [ 29.750061724137932, -1.487163323076923 ], [ 29.750331206896551, -1.487163323076923 ], [ 29.750331206896551, -1.486893846153846 ], [ 29.750061724137932, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1230, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.486893846153846 ], [ 29.750331206896551, -1.487163323076923 ], [ 29.750600689655172, -1.487163323076923 ], [ 29.750600689655172, -1.486893846153846 ], [ 29.750331206896551, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1231, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.486893846153846 ], [ 29.750600689655172, -1.487163323076923 ], [ 29.750870172413794, -1.487163323076923 ], [ 29.750870172413794, -1.486893846153846 ], [ 29.750600689655172, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1232, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.486893846153846 ], [ 29.750870172413794, -1.487163323076923 ], [ 29.751139655172413, -1.487163323076923 ], [ 29.751139655172413, -1.486893846153846 ], [ 29.750870172413794, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1233, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.486893846153846 ], [ 29.751139655172413, -1.487163323076923 ], [ 29.751409137931034, -1.487163323076923 ], [ 29.751409137931034, -1.486893846153846 ], [ 29.751139655172413, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1234, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.486893846153846 ], [ 29.751409137931034, -1.487163323076923 ], [ 29.751678620689656, -1.487163323076923 ], [ 29.751678620689656, -1.486893846153846 ], [ 29.751409137931034, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1235, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.486893846153846 ], [ 29.751678620689656, -1.487163323076923 ], [ 29.751948103448278, -1.487163323076923 ], [ 29.751948103448278, -1.486893846153846 ], [ 29.751678620689656, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1236, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.486893846153846 ], [ 29.751948103448278, -1.487163323076923 ], [ 29.752217586206896, -1.487163323076923 ], [ 29.752217586206896, -1.486893846153846 ], [ 29.751948103448278, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1237, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.486893846153846 ], [ 29.752217586206896, -1.487163323076923 ], [ 29.752487068965518, -1.487163323076923 ], [ 29.752487068965518, -1.486893846153846 ], [ 29.752217586206896, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1238, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.486893846153846 ], [ 29.752487068965518, -1.487163323076923 ], [ 29.75275655172414, -1.487163323076923 ], [ 29.75275655172414, -1.486893846153846 ], [ 29.752487068965518, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1239, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.486893846153846 ], [ 29.75275655172414, -1.487163323076923 ], [ 29.753026034482758, -1.487163323076923 ], [ 29.753026034482758, -1.486893846153846 ], [ 29.75275655172414, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1240, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.486893846153846 ], [ 29.753026034482758, -1.487163323076923 ], [ 29.75329551724138, -1.487163323076923 ], [ 29.75329551724138, -1.486893846153846 ], [ 29.753026034482758, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1241, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.486893846153846 ], [ 29.75329551724138, -1.487163323076923 ], [ 29.753565, -1.487163323076923 ], [ 29.753565, -1.486893846153846 ], [ 29.75329551724138, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1242, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.486893846153846 ], [ 29.753565, -1.487163323076923 ], [ 29.75383448275862, -1.487163323076923 ], [ 29.75383448275862, -1.486893846153846 ], [ 29.753565, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1243, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.486893846153846 ], [ 29.75383448275862, -1.487163323076923 ], [ 29.754103965517242, -1.487163323076923 ], [ 29.754103965517242, -1.486893846153846 ], [ 29.75383448275862, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1244, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.486893846153846 ], [ 29.754103965517242, -1.487163323076923 ], [ 29.754373448275864, -1.487163323076923 ], [ 29.754373448275864, -1.486893846153846 ], [ 29.754103965517242, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1245, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.486893846153846 ], [ 29.754642931034482, -1.487163323076923 ], [ 29.754912413793104, -1.487163323076923 ], [ 29.754912413793104, -1.486893846153846 ], [ 29.754642931034482, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1246, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.486893846153846 ], [ 29.754912413793104, -1.487702276923077 ], [ 29.755181896551726, -1.487702276923077 ], [ 29.755181896551726, -1.486893846153846 ], [ 29.754912413793104, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1247, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.487163323076923 ], [ 29.719340689655173, -1.4874328 ], [ 29.719610172413795, -1.4874328 ], [ 29.719610172413795, -1.487163323076923 ], [ 29.719340689655173, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1248, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.487163323076923 ], [ 29.719610172413795, -1.4874328 ], [ 29.720149137931035, -1.4874328 ], [ 29.720149137931035, -1.487163323076923 ], [ 29.719610172413795, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1249, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.487163323076923 ], [ 29.720149137931035, -1.4874328 ], [ 29.720688103448275, -1.4874328 ], [ 29.720688103448275, -1.487163323076923 ], [ 29.720149137931035, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1250, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.487163323076923 ], [ 29.720688103448275, -1.4874328 ], [ 29.720957586206897, -1.4874328 ], [ 29.720957586206897, -1.487163323076923 ], [ 29.720688103448275, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1251, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.487163323076923 ], [ 29.720957586206897, -1.4874328 ], [ 29.721227068965518, -1.4874328 ], [ 29.721227068965518, -1.487163323076923 ], [ 29.720957586206897, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1252, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.487163323076923 ], [ 29.721227068965518, -1.4874328 ], [ 29.721496551724137, -1.4874328 ], [ 29.721496551724137, -1.487163323076923 ], [ 29.721227068965518, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1253, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.487163323076923 ], [ 29.721496551724137, -1.4874328 ], [ 29.721766034482759, -1.4874328 ], [ 29.721766034482759, -1.487163323076923 ], [ 29.721496551724137, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1254, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.487163323076923 ], [ 29.721766034482759, -1.4874328 ], [ 29.72203551724138, -1.4874328 ], [ 29.72203551724138, -1.487163323076923 ], [ 29.721766034482759, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1255, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.487163323076923 ], [ 29.72203551724138, -1.4874328 ], [ 29.722305, -1.4874328 ], [ 29.722305, -1.487163323076923 ], [ 29.72203551724138, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1256, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.487163323076923 ], [ 29.722305, -1.4874328 ], [ 29.722843965517242, -1.4874328 ], [ 29.722843965517242, -1.487163323076923 ], [ 29.722305, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1257, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.487163323076923 ], [ 29.722843965517242, -1.4874328 ], [ 29.723113448275861, -1.4874328 ], [ 29.723113448275861, -1.487163323076923 ], [ 29.722843965517242, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1258, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.487163323076923 ], [ 29.723113448275861, -1.4874328 ], [ 29.723382931034482, -1.4874328 ], [ 29.723382931034482, -1.487163323076923 ], [ 29.723113448275861, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1259, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.487163323076923 ], [ 29.723382931034482, -1.4874328 ], [ 29.723652413793104, -1.4874328 ], [ 29.723652413793104, -1.487163323076923 ], [ 29.723382931034482, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1260, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.487163323076923 ], [ 29.723652413793104, -1.4874328 ], [ 29.723921896551722, -1.4874328 ], [ 29.723921896551722, -1.487163323076923 ], [ 29.723652413793104, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1261, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.487163323076923 ], [ 29.723921896551722, -1.4874328 ], [ 29.724191379310344, -1.4874328 ], [ 29.724191379310344, -1.487163323076923 ], [ 29.723921896551722, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1262, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.487163323076923 ], [ 29.724191379310344, -1.4874328 ], [ 29.725269310344828, -1.4874328 ], [ 29.725269310344828, -1.487163323076923 ], [ 29.724191379310344, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1263, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.487163323076923 ], [ 29.725269310344828, -1.4874328 ], [ 29.725808275862068, -1.4874328 ], [ 29.725808275862068, -1.487163323076923 ], [ 29.725269310344828, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1264, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.487163323076923 ], [ 29.725808275862068, -1.4874328 ], [ 29.726347241379312, -1.4874328 ], [ 29.726347241379312, -1.487163323076923 ], [ 29.725808275862068, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1265, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.487163323076923 ], [ 29.726347241379312, -1.4874328 ], [ 29.72661672413793, -1.4874328 ], [ 29.72661672413793, -1.487163323076923 ], [ 29.726347241379312, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1266, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.487163323076923 ], [ 29.72661672413793, -1.4874328 ], [ 29.726886206896552, -1.4874328 ], [ 29.726886206896552, -1.487163323076923 ], [ 29.72661672413793, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1267, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.487163323076923 ], [ 29.726886206896552, -1.4874328 ], [ 29.727425172413792, -1.4874328 ], [ 29.727425172413792, -1.487163323076923 ], [ 29.726886206896552, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1268, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.487163323076923 ], [ 29.727425172413792, -1.4874328 ], [ 29.727694655172414, -1.4874328 ], [ 29.727694655172414, -1.487163323076923 ], [ 29.727425172413792, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1269, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.487163323076923 ], [ 29.727694655172414, -1.4874328 ], [ 29.727964137931036, -1.4874328 ], [ 29.727964137931036, -1.487163323076923 ], [ 29.727694655172414, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1270, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.487163323076923 ], [ 29.731197931034483, -1.4874328 ], [ 29.731467413793105, -1.4874328 ], [ 29.731467413793105, -1.487163323076923 ], [ 29.731197931034483, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1271, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.487163323076923 ], [ 29.731467413793105, -1.4874328 ], [ 29.731736896551723, -1.4874328 ], [ 29.731736896551723, -1.487163323076923 ], [ 29.731467413793105, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1272, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.487163323076923 ], [ 29.731736896551723, -1.4874328 ], [ 29.732006379310345, -1.4874328 ], [ 29.732006379310345, -1.487163323076923 ], [ 29.731736896551723, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1273, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.487163323076923 ], [ 29.732006379310345, -1.4874328 ], [ 29.732275862068967, -1.4874328 ], [ 29.732275862068967, -1.487163323076923 ], [ 29.732006379310345, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1274, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.487163323076923 ], [ 29.732275862068967, -1.4874328 ], [ 29.732545344827585, -1.4874328 ], [ 29.732545344827585, -1.487163323076923 ], [ 29.732275862068967, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1275, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.487163323076923 ], [ 29.732545344827585, -1.4874328 ], [ 29.732814827586207, -1.4874328 ], [ 29.732814827586207, -1.487163323076923 ], [ 29.732545344827585, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1276, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.487163323076923 ], [ 29.732814827586207, -1.4874328 ], [ 29.733084310344829, -1.4874328 ], [ 29.733084310344829, -1.487163323076923 ], [ 29.732814827586207, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1277, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.487163323076923 ], [ 29.733084310344829, -1.4874328 ], [ 29.733353793103447, -1.4874328 ], [ 29.733353793103447, -1.487163323076923 ], [ 29.733084310344829, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1278, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.487163323076923 ], [ 29.733353793103447, -1.4874328 ], [ 29.733623275862069, -1.4874328 ], [ 29.733623275862069, -1.487163323076923 ], [ 29.733353793103447, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1279, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.487163323076923 ], [ 29.733623275862069, -1.4874328 ], [ 29.733892758620691, -1.4874328 ], [ 29.733892758620691, -1.487163323076923 ], [ 29.733623275862069, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1280, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.487163323076923 ], [ 29.733892758620691, -1.4874328 ], [ 29.734162241379309, -1.4874328 ], [ 29.734162241379309, -1.487163323076923 ], [ 29.733892758620691, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1281, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.487163323076923 ], [ 29.734162241379309, -1.4874328 ], [ 29.734431724137931, -1.4874328 ], [ 29.734431724137931, -1.487163323076923 ], [ 29.734162241379309, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1282, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.487163323076923 ], [ 29.734431724137931, -1.4874328 ], [ 29.734701206896553, -1.4874328 ], [ 29.734701206896553, -1.487163323076923 ], [ 29.734431724137931, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1283, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.487163323076923 ], [ 29.734701206896553, -1.4874328 ], [ 29.734970689655171, -1.4874328 ], [ 29.734970689655171, -1.487163323076923 ], [ 29.734701206896553, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1284, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.487163323076923 ], [ 29.734970689655171, -1.4874328 ], [ 29.735240172413793, -1.4874328 ], [ 29.735240172413793, -1.487163323076923 ], [ 29.734970689655171, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1285, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.487163323076923 ], [ 29.735240172413793, -1.4874328 ], [ 29.735509655172415, -1.4874328 ], [ 29.735509655172415, -1.487163323076923 ], [ 29.735240172413793, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1286, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.487163323076923 ], [ 29.735509655172415, -1.4874328 ], [ 29.735779137931036, -1.4874328 ], [ 29.735779137931036, -1.487163323076923 ], [ 29.735509655172415, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1287, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.487163323076923 ], [ 29.735779137931036, -1.4874328 ], [ 29.739551896551724, -1.4874328 ], [ 29.739551896551724, -1.487163323076923 ], [ 29.735779137931036, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1288, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.487163323076923 ], [ 29.739551896551724, -1.4874328 ], [ 29.739821379310346, -1.4874328 ], [ 29.739821379310346, -1.487702276923077 ], [ 29.740629827586208, -1.487702276923077 ], [ 29.740629827586208, -1.4874328 ], [ 29.740360344827586, -1.4874328 ], [ 29.740360344827586, -1.487163323076923 ], [ 29.739551896551724, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1289, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.487163323076923 ], [ 29.740360344827586, -1.4874328 ], [ 29.74197724137931, -1.4874328 ], [ 29.74197724137931, -1.487163323076923 ], [ 29.740360344827586, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1290, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.487163323076923 ], [ 29.74197724137931, -1.4874328 ], [ 29.742516206896553, -1.4874328 ], [ 29.742516206896553, -1.487163323076923 ], [ 29.74197724137931, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1291, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.487163323076923 ], [ 29.742516206896553, -1.4874328 ], [ 29.743055172413793, -1.4874328 ], [ 29.743055172413793, -1.487163323076923 ], [ 29.742516206896553, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1292, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.487163323076923 ], [ 29.743055172413793, -1.4874328 ], [ 29.743594137931034, -1.4874328 ], [ 29.743594137931034, -1.487163323076923 ], [ 29.743055172413793, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1293, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.487163323076923 ], [ 29.743594137931034, -1.4874328 ], [ 29.744133103448277, -1.4874328 ], [ 29.744133103448277, -1.487163323076923 ], [ 29.743594137931034, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1294, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.487163323076923 ], [ 29.744133103448277, -1.4874328 ], [ 29.744672068965517, -1.4874328 ], [ 29.744672068965517, -1.487163323076923 ], [ 29.744133103448277, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1295, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.487163323076923 ], [ 29.744672068965517, -1.4874328 ], [ 29.745211034482757, -1.4874328 ], [ 29.745211034482757, -1.487163323076923 ], [ 29.744672068965517, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1296, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.487163323076923 ], [ 29.745211034482757, -1.4874328 ], [ 29.74575, -1.4874328 ], [ 29.74575, -1.487163323076923 ], [ 29.745211034482757, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1297, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.487163323076923 ], [ 29.74575, -1.4874328 ], [ 29.746288965517241, -1.4874328 ], [ 29.746288965517241, -1.487163323076923 ], [ 29.74575, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1298, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.487163323076923 ], [ 29.746288965517241, -1.4874328 ], [ 29.748444827586209, -1.4874328 ], [ 29.748444827586209, -1.487163323076923 ], [ 29.746288965517241, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1299, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.486893846153846 ], [ 29.748983793103449, -1.486893846153846 ], [ 29.748983793103449, -1.4874328 ], [ 29.748444827586209, -1.4874328 ], [ 29.748444827586209, -1.487163323076923 ], [ 29.748714310344827, -1.487163323076923 ], [ 29.748714310344827, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1300, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.487163323076923 ], [ 29.748983793103449, -1.4874328 ], [ 29.74925327586207, -1.4874328 ], [ 29.74925327586207, -1.487163323076923 ], [ 29.748983793103449, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1301, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.487163323076923 ], [ 29.74925327586207, -1.4874328 ], [ 29.749522758620689, -1.4874328 ], [ 29.749522758620689, -1.487163323076923 ], [ 29.74925327586207, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1302, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.487163323076923 ], [ 29.749522758620689, -1.4874328 ], [ 29.749792241379311, -1.4874328 ], [ 29.749792241379311, -1.487163323076923 ], [ 29.749522758620689, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1303, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.487163323076923 ], [ 29.749792241379311, -1.4874328 ], [ 29.750061724137932, -1.4874328 ], [ 29.750061724137932, -1.487163323076923 ], [ 29.749792241379311, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1304, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.487163323076923 ], [ 29.750061724137932, -1.4874328 ], [ 29.750331206896551, -1.4874328 ], [ 29.750331206896551, -1.487163323076923 ], [ 29.750061724137932, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1305, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.487163323076923 ], [ 29.750331206896551, -1.4874328 ], [ 29.750600689655172, -1.4874328 ], [ 29.750600689655172, -1.487163323076923 ], [ 29.750331206896551, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1306, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.487163323076923 ], [ 29.750600689655172, -1.4874328 ], [ 29.750870172413794, -1.4874328 ], [ 29.750870172413794, -1.487163323076923 ], [ 29.750600689655172, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1307, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.487163323076923 ], [ 29.750870172413794, -1.4874328 ], [ 29.751139655172413, -1.4874328 ], [ 29.751139655172413, -1.487163323076923 ], [ 29.750870172413794, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1308, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.487163323076923 ], [ 29.751139655172413, -1.4874328 ], [ 29.751409137931034, -1.4874328 ], [ 29.751409137931034, -1.487163323076923 ], [ 29.751139655172413, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1309, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.487163323076923 ], [ 29.751409137931034, -1.4874328 ], [ 29.751678620689656, -1.4874328 ], [ 29.751678620689656, -1.487163323076923 ], [ 29.751409137931034, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1310, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.487163323076923 ], [ 29.751678620689656, -1.4874328 ], [ 29.751948103448278, -1.4874328 ], [ 29.751948103448278, -1.487163323076923 ], [ 29.751678620689656, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1311, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.487163323076923 ], [ 29.751948103448278, -1.4874328 ], [ 29.752217586206896, -1.4874328 ], [ 29.752217586206896, -1.487163323076923 ], [ 29.751948103448278, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1312, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.487163323076923 ], [ 29.752217586206896, -1.4874328 ], [ 29.752487068965518, -1.4874328 ], [ 29.752487068965518, -1.487163323076923 ], [ 29.752217586206896, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1313, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.487163323076923 ], [ 29.752487068965518, -1.4874328 ], [ 29.75275655172414, -1.4874328 ], [ 29.75275655172414, -1.487163323076923 ], [ 29.752487068965518, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1314, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.487163323076923 ], [ 29.75275655172414, -1.4874328 ], [ 29.753026034482758, -1.4874328 ], [ 29.753026034482758, -1.487163323076923 ], [ 29.75275655172414, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1315, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.487163323076923 ], [ 29.753026034482758, -1.4874328 ], [ 29.75329551724138, -1.4874328 ], [ 29.75329551724138, -1.487163323076923 ], [ 29.753026034482758, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1316, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.487163323076923 ], [ 29.75329551724138, -1.4874328 ], [ 29.753565, -1.4874328 ], [ 29.753565, -1.487163323076923 ], [ 29.75329551724138, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1317, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.487163323076923 ], [ 29.753565, -1.4874328 ], [ 29.75383448275862, -1.4874328 ], [ 29.75383448275862, -1.487163323076923 ], [ 29.753565, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1318, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.487163323076923 ], [ 29.75383448275862, -1.4874328 ], [ 29.754103965517242, -1.4874328 ], [ 29.754103965517242, -1.487163323076923 ], [ 29.75383448275862, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1319, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.487163323076923 ], [ 29.754103965517242, -1.4874328 ], [ 29.754373448275864, -1.4874328 ], [ 29.754373448275864, -1.487163323076923 ], [ 29.754103965517242, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1320, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.4874328 ], [ 29.719340689655173, -1.487702276923077 ], [ 29.719879655172413, -1.487702276923077 ], [ 29.719879655172413, -1.4874328 ], [ 29.719340689655173, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1321, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.4874328 ], [ 29.719879655172413, -1.487702276923077 ], [ 29.720149137931035, -1.487702276923077 ], [ 29.720149137931035, -1.4874328 ], [ 29.719879655172413, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1322, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.4874328 ], [ 29.720149137931035, -1.487702276923077 ], [ 29.720688103448275, -1.487702276923077 ], [ 29.720688103448275, -1.4874328 ], [ 29.720149137931035, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1323, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.4874328 ], [ 29.720688103448275, -1.487702276923077 ], [ 29.720957586206897, -1.487702276923077 ], [ 29.720957586206897, -1.4874328 ], [ 29.720688103448275, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1324, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.4874328 ], [ 29.720957586206897, -1.487702276923077 ], [ 29.721227068965518, -1.487702276923077 ], [ 29.721227068965518, -1.4874328 ], [ 29.720957586206897, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1325, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.4874328 ], [ 29.721496551724137, -1.487702276923077 ], [ 29.721766034482759, -1.487702276923077 ], [ 29.721766034482759, -1.4874328 ], [ 29.721496551724137, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1326, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.4874328 ], [ 29.721766034482759, -1.487702276923077 ], [ 29.72203551724138, -1.487702276923077 ], [ 29.72203551724138, -1.4874328 ], [ 29.721766034482759, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1327, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.4874328 ], [ 29.72203551724138, -1.487702276923077 ], [ 29.722305, -1.487702276923077 ], [ 29.722305, -1.4874328 ], [ 29.72203551724138, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1328, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.4874328 ], [ 29.722843965517242, -1.487702276923077 ], [ 29.723113448275861, -1.487702276923077 ], [ 29.723113448275861, -1.4874328 ], [ 29.722843965517242, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1329, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.4874328 ], [ 29.723113448275861, -1.487702276923077 ], [ 29.723382931034482, -1.487702276923077 ], [ 29.723382931034482, -1.4874328 ], [ 29.723113448275861, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1330, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.4874328 ], [ 29.723382931034482, -1.487702276923077 ], [ 29.723652413793104, -1.487702276923077 ], [ 29.723652413793104, -1.4874328 ], [ 29.723382931034482, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1331, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.4874328 ], [ 29.723652413793104, -1.487702276923077 ], [ 29.723921896551722, -1.487702276923077 ], [ 29.723921896551722, -1.4874328 ], [ 29.723652413793104, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1332, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.4874328 ], [ 29.723921896551722, -1.487702276923077 ], [ 29.724191379310344, -1.487702276923077 ], [ 29.724191379310344, -1.4874328 ], [ 29.723921896551722, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1333, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.4874328 ], [ 29.724191379310344, -1.487702276923077 ], [ 29.725269310344828, -1.487702276923077 ], [ 29.725269310344828, -1.4874328 ], [ 29.724191379310344, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1334, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.4874328 ], [ 29.725269310344828, -1.487702276923077 ], [ 29.72553879310345, -1.487702276923077 ], [ 29.72553879310345, -1.4874328 ], [ 29.725269310344828, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1335, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.4874328 ], [ 29.72553879310345, -1.487702276923077 ], [ 29.726347241379312, -1.487702276923077 ], [ 29.726347241379312, -1.4874328 ], [ 29.72553879310345, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1336, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.4874328 ], [ 29.726347241379312, -1.487702276923077 ], [ 29.72661672413793, -1.487702276923077 ], [ 29.72661672413793, -1.4874328 ], [ 29.726347241379312, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1337, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.4874328 ], [ 29.72661672413793, -1.487702276923077 ], [ 29.726886206896552, -1.487702276923077 ], [ 29.726886206896552, -1.4874328 ], [ 29.72661672413793, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1338, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.4874328 ], [ 29.726886206896552, -1.487702276923077 ], [ 29.727425172413792, -1.487702276923077 ], [ 29.727425172413792, -1.4874328 ], [ 29.726886206896552, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1339, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.4874328 ], [ 29.727425172413792, -1.487702276923077 ], [ 29.727694655172414, -1.487702276923077 ], [ 29.727694655172414, -1.4874328 ], [ 29.727425172413792, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1340, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.4874328 ], [ 29.727694655172414, -1.487702276923077 ], [ 29.727964137931036, -1.487702276923077 ], [ 29.727964137931036, -1.4874328 ], [ 29.727694655172414, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1341, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.4874328 ], [ 29.727964137931036, -1.487702276923077 ], [ 29.728233620689654, -1.487702276923077 ], [ 29.728233620689654, -1.4874328 ], [ 29.727964137931036, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1342, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.4874328 ], [ 29.732006379310345, -1.487702276923077 ], [ 29.732275862068967, -1.487702276923077 ], [ 29.732275862068967, -1.4874328 ], [ 29.732006379310345, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1343, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.4874328 ], [ 29.732275862068967, -1.487702276923077 ], [ 29.732545344827585, -1.487702276923077 ], [ 29.732545344827585, -1.4874328 ], [ 29.732275862068967, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1344, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.4874328 ], [ 29.732545344827585, -1.487702276923077 ], [ 29.732814827586207, -1.487702276923077 ], [ 29.732814827586207, -1.4874328 ], [ 29.732545344827585, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1345, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.4874328 ], [ 29.732814827586207, -1.487702276923077 ], [ 29.733084310344829, -1.487702276923077 ], [ 29.733084310344829, -1.4874328 ], [ 29.732814827586207, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1346, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.4874328 ], [ 29.733084310344829, -1.487702276923077 ], [ 29.733353793103447, -1.487702276923077 ], [ 29.733353793103447, -1.4874328 ], [ 29.733084310344829, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1347, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.4874328 ], [ 29.733353793103447, -1.487702276923077 ], [ 29.733623275862069, -1.487702276923077 ], [ 29.733623275862069, -1.4874328 ], [ 29.733353793103447, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1348, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.4874328 ], [ 29.733623275862069, -1.487702276923077 ], [ 29.733892758620691, -1.487702276923077 ], [ 29.733892758620691, -1.4874328 ], [ 29.733623275862069, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1349, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.4874328 ], [ 29.733892758620691, -1.487702276923077 ], [ 29.734162241379309, -1.487702276923077 ], [ 29.734162241379309, -1.4874328 ], [ 29.733892758620691, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1350, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.4874328 ], [ 29.734162241379309, -1.487702276923077 ], [ 29.734431724137931, -1.487702276923077 ], [ 29.734431724137931, -1.4874328 ], [ 29.734162241379309, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1351, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.4874328 ], [ 29.734431724137931, -1.487702276923077 ], [ 29.734701206896553, -1.487702276923077 ], [ 29.734701206896553, -1.4874328 ], [ 29.734431724137931, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1352, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.4874328 ], [ 29.734701206896553, -1.487702276923077 ], [ 29.734970689655171, -1.487702276923077 ], [ 29.734970689655171, -1.4874328 ], [ 29.734701206896553, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1353, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.4874328 ], [ 29.734970689655171, -1.487702276923077 ], [ 29.735240172413793, -1.487702276923077 ], [ 29.735240172413793, -1.4874328 ], [ 29.734970689655171, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1354, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.4874328 ], [ 29.735240172413793, -1.487702276923077 ], [ 29.735509655172415, -1.487702276923077 ], [ 29.735509655172415, -1.4874328 ], [ 29.735240172413793, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1355, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.4874328 ], [ 29.735509655172415, -1.487702276923077 ], [ 29.735779137931036, -1.487702276923077 ], [ 29.735779137931036, -1.4874328 ], [ 29.735509655172415, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1356, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.4874328 ], [ 29.735779137931036, -1.487702276923077 ], [ 29.736048620689655, -1.487702276923077 ], [ 29.736048620689655, -1.4874328 ], [ 29.735779137931036, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1357, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.4874328 ], [ 29.736048620689655, -1.487702276923077 ], [ 29.736318103448276, -1.487702276923077 ], [ 29.736318103448276, -1.4874328 ], [ 29.736048620689655, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1358, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.4874328 ], [ 29.739282413793102, -1.487702276923077 ], [ 29.739551896551724, -1.487702276923077 ], [ 29.739551896551724, -1.4874328 ], [ 29.739282413793102, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1359, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.4874328 ], [ 29.739551896551724, -1.487702276923077 ], [ 29.739821379310346, -1.487702276923077 ], [ 29.739821379310346, -1.4874328 ], [ 29.739551896551724, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1360, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.4874328 ], [ 29.740629827586208, -1.487702276923077 ], [ 29.74197724137931, -1.487702276923077 ], [ 29.74197724137931, -1.4874328 ], [ 29.740629827586208, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1361, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.4874328 ], [ 29.74197724137931, -1.487702276923077 ], [ 29.742516206896553, -1.487702276923077 ], [ 29.742516206896553, -1.4874328 ], [ 29.74197724137931, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1362, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.4874328 ], [ 29.742516206896553, -1.487702276923077 ], [ 29.742785689655172, -1.487702276923077 ], [ 29.742785689655172, -1.4874328 ], [ 29.742516206896553, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1363, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.4874328 ], [ 29.742785689655172, -1.487702276923077 ], [ 29.743324655172415, -1.487702276923077 ], [ 29.743324655172415, -1.4874328 ], [ 29.742785689655172, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1364, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.4874328 ], [ 29.743324655172415, -1.487702276923077 ], [ 29.744133103448277, -1.487702276923077 ], [ 29.744133103448277, -1.4874328 ], [ 29.743324655172415, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1365, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.4874328 ], [ 29.744133103448277, -1.487702276923077 ], [ 29.744672068965517, -1.487702276923077 ], [ 29.744672068965517, -1.4874328 ], [ 29.744133103448277, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1366, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.4874328 ], [ 29.744672068965517, -1.487702276923077 ], [ 29.745211034482757, -1.487702276923077 ], [ 29.745211034482757, -1.4874328 ], [ 29.744672068965517, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1367, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.4874328 ], [ 29.745211034482757, -1.487702276923077 ], [ 29.745480517241379, -1.487702276923077 ], [ 29.745480517241379, -1.4874328 ], [ 29.745211034482757, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1368, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.4874328 ], [ 29.745480517241379, -1.487702276923077 ], [ 29.746019482758619, -1.487702276923077 ], [ 29.746019482758619, -1.4874328 ], [ 29.745480517241379, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1369, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.4874328 ], [ 29.746019482758619, -1.487702276923077 ], [ 29.748714310344827, -1.487702276923077 ], [ 29.748714310344827, -1.4874328 ], [ 29.746019482758619, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1370, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.4874328 ], [ 29.748714310344827, -1.487702276923077 ], [ 29.749522758620689, -1.487702276923077 ], [ 29.749522758620689, -1.4874328 ], [ 29.748714310344827, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1371, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.4874328 ], [ 29.749522758620689, -1.487702276923077 ], [ 29.749792241379311, -1.487702276923077 ], [ 29.749792241379311, -1.4874328 ], [ 29.749522758620689, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1372, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.4874328 ], [ 29.749792241379311, -1.487702276923077 ], [ 29.750061724137932, -1.487702276923077 ], [ 29.750061724137932, -1.4874328 ], [ 29.749792241379311, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1373, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.4874328 ], [ 29.750061724137932, -1.487702276923077 ], [ 29.750331206896551, -1.487702276923077 ], [ 29.750331206896551, -1.4874328 ], [ 29.750061724137932, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1374, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.4874328 ], [ 29.750331206896551, -1.487702276923077 ], [ 29.750600689655172, -1.487702276923077 ], [ 29.750600689655172, -1.4874328 ], [ 29.750331206896551, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1375, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.4874328 ], [ 29.750600689655172, -1.487702276923077 ], [ 29.750870172413794, -1.487702276923077 ], [ 29.750870172413794, -1.4874328 ], [ 29.750600689655172, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1376, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.4874328 ], [ 29.750870172413794, -1.487702276923077 ], [ 29.751139655172413, -1.487702276923077 ], [ 29.751139655172413, -1.4874328 ], [ 29.750870172413794, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1377, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.4874328 ], [ 29.751139655172413, -1.487702276923077 ], [ 29.751409137931034, -1.487702276923077 ], [ 29.751409137931034, -1.4874328 ], [ 29.751139655172413, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1378, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.4874328 ], [ 29.751409137931034, -1.487702276923077 ], [ 29.751678620689656, -1.487702276923077 ], [ 29.751678620689656, -1.4874328 ], [ 29.751409137931034, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1379, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.4874328 ], [ 29.751678620689656, -1.487702276923077 ], [ 29.751948103448278, -1.487702276923077 ], [ 29.751948103448278, -1.4874328 ], [ 29.751678620689656, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1380, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.4874328 ], [ 29.751948103448278, -1.487702276923077 ], [ 29.752217586206896, -1.487702276923077 ], [ 29.752217586206896, -1.4874328 ], [ 29.751948103448278, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1381, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.4874328 ], [ 29.752217586206896, -1.487702276923077 ], [ 29.752487068965518, -1.487702276923077 ], [ 29.752487068965518, -1.4874328 ], [ 29.752217586206896, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1382, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.4874328 ], [ 29.752487068965518, -1.487702276923077 ], [ 29.75275655172414, -1.487702276923077 ], [ 29.75275655172414, -1.4874328 ], [ 29.752487068965518, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1383, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.4874328 ], [ 29.75275655172414, -1.487702276923077 ], [ 29.753026034482758, -1.487702276923077 ], [ 29.753026034482758, -1.4874328 ], [ 29.75275655172414, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1384, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.4874328 ], [ 29.753026034482758, -1.487702276923077 ], [ 29.75329551724138, -1.487702276923077 ], [ 29.75329551724138, -1.4874328 ], [ 29.753026034482758, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1385, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.4874328 ], [ 29.75329551724138, -1.487702276923077 ], [ 29.753565, -1.487702276923077 ], [ 29.753565, -1.4874328 ], [ 29.75329551724138, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1386, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.4874328 ], [ 29.754103965517242, -1.487702276923077 ], [ 29.754373448275864, -1.487702276923077 ], [ 29.754373448275864, -1.4874328 ], [ 29.754103965517242, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1387, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.486624369230769 ], [ 29.729311551724138, -1.487971753846154 ], [ 29.729850517241381, -1.487971753846154 ], [ 29.729850517241381, -1.486624369230769 ], [ 29.729311551724138, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1388, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.486624369230769 ], [ 29.729850517241381, -1.487971753846154 ], [ 29.73012, -1.487971753846154 ], [ 29.73012, -1.486624369230769 ], [ 29.729850517241381, -1.486624369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1389, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.486893846153846 ], [ 29.730389482758621, -1.487971753846154 ], [ 29.730658965517243, -1.487971753846154 ], [ 29.730658965517243, -1.486893846153846 ], [ 29.730389482758621, -1.486893846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1390, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.487163323076923 ], [ 29.730658965517243, -1.487971753846154 ], [ 29.730928448275861, -1.487971753846154 ], [ 29.730928448275861, -1.487163323076923 ], [ 29.730658965517243, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1391, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.487163323076923 ], [ 29.730928448275861, -1.487971753846154 ], [ 29.731197931034483, -1.487971753846154 ], [ 29.731197931034483, -1.487163323076923 ], [ 29.730928448275861, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1392, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.487163323076923 ], [ 29.754373448275864, -1.487971753846154 ], [ 29.754642931034482, -1.487971753846154 ], [ 29.754642931034482, -1.487163323076923 ], [ 29.754373448275864, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1393, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.487163323076923 ], [ 29.754642931034482, -1.487971753846154 ], [ 29.754912413793104, -1.487971753846154 ], [ 29.754912413793104, -1.487163323076923 ], [ 29.754642931034482, -1.487163323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1394, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.4874328 ], [ 29.721227068965518, -1.487971753846154 ], [ 29.721496551724137, -1.487971753846154 ], [ 29.721496551724137, -1.4874328 ], [ 29.721227068965518, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1395, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.4874328 ], [ 29.722305, -1.488780184615385 ], [ 29.72257448275862, -1.488780184615385 ], [ 29.72257448275862, -1.4874328 ], [ 29.722305, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1396, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.4874328 ], [ 29.72257448275862, -1.488780184615385 ], [ 29.722843965517242, -1.488780184615385 ], [ 29.722843965517242, -1.487971753846154 ], [ 29.723113448275861, -1.487971753846154 ], [ 29.723113448275861, -1.487702276923077 ], [ 29.722843965517242, -1.487702276923077 ], [ 29.722843965517242, -1.4874328 ], [ 29.72257448275862, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1397, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.4874328 ], [ 29.731197931034483, -1.488241230769231 ], [ 29.731467413793105, -1.488241230769231 ], [ 29.731467413793105, -1.4874328 ], [ 29.731197931034483, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1398, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.4874328 ], [ 29.731467413793105, -1.488241230769231 ], [ 29.731736896551723, -1.488241230769231 ], [ 29.731736896551723, -1.4874328 ], [ 29.731467413793105, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1399, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.4874328 ], [ 29.731736896551723, -1.487971753846154 ], [ 29.732006379310345, -1.487971753846154 ], [ 29.732006379310345, -1.4874328 ], [ 29.731736896551723, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1400, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.4874328 ], [ 29.736318103448276, -1.487702276923077 ], [ 29.738743448275862, -1.487702276923077 ], [ 29.738743448275862, -1.487971753846154 ], [ 29.739012931034484, -1.487971753846154 ], [ 29.739012931034484, -1.487702276923077 ], [ 29.739282413793102, -1.487702276923077 ], [ 29.739282413793102, -1.4874328 ], [ 29.736318103448276, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1401, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.4874328 ], [ 29.753565, -1.487971753846154 ], [ 29.75383448275862, -1.487971753846154 ], [ 29.75383448275862, -1.4874328 ], [ 29.753565, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1402, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.4874328 ], [ 29.75383448275862, -1.487971753846154 ], [ 29.754103965517242, -1.487971753846154 ], [ 29.754103965517242, -1.4874328 ], [ 29.75383448275862, -1.4874328 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1403, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.487702276923077 ], [ 29.719879655172413, -1.487971753846154 ], [ 29.720149137931035, -1.487971753846154 ], [ 29.720149137931035, -1.487702276923077 ], [ 29.719879655172413, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1404, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.487702276923077 ], [ 29.720149137931035, -1.487971753846154 ], [ 29.720688103448275, -1.487971753846154 ], [ 29.720688103448275, -1.487702276923077 ], [ 29.720149137931035, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1405, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.487702276923077 ], [ 29.720688103448275, -1.488510707692308 ], [ 29.720957586206897, -1.488510707692308 ], [ 29.720957586206897, -1.487702276923077 ], [ 29.720688103448275, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1406, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.487702276923077 ], [ 29.720957586206897, -1.488510707692308 ], [ 29.721227068965518, -1.488510707692308 ], [ 29.721227068965518, -1.487702276923077 ], [ 29.720957586206897, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1407, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.487702276923077 ], [ 29.721496551724137, -1.488510707692308 ], [ 29.721766034482759, -1.488510707692308 ], [ 29.721766034482759, -1.487702276923077 ], [ 29.721496551724137, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1408, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.487702276923077 ], [ 29.721766034482759, -1.488510707692308 ], [ 29.72203551724138, -1.488510707692308 ], [ 29.72203551724138, -1.487702276923077 ], [ 29.721766034482759, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1409, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.487702276923077 ], [ 29.72203551724138, -1.488510707692308 ], [ 29.722305, -1.488510707692308 ], [ 29.722305, -1.487702276923077 ], [ 29.72203551724138, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1410, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.487702276923077 ], [ 29.723113448275861, -1.487971753846154 ], [ 29.723382931034482, -1.487971753846154 ], [ 29.723382931034482, -1.487702276923077 ], [ 29.723113448275861, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1411, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.487702276923077 ], [ 29.723382931034482, -1.487971753846154 ], [ 29.723652413793104, -1.487971753846154 ], [ 29.723652413793104, -1.487702276923077 ], [ 29.723382931034482, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1412, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.487702276923077 ], [ 29.723652413793104, -1.487971753846154 ], [ 29.723921896551722, -1.487971753846154 ], [ 29.723921896551722, -1.487702276923077 ], [ 29.723652413793104, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1413, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.487702276923077 ], [ 29.723921896551722, -1.487971753846154 ], [ 29.724191379310344, -1.487971753846154 ], [ 29.724191379310344, -1.487702276923077 ], [ 29.723921896551722, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1414, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.487702276923077 ], [ 29.724191379310344, -1.487971753846154 ], [ 29.725269310344828, -1.487971753846154 ], [ 29.725269310344828, -1.487702276923077 ], [ 29.724191379310344, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1415, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.487702276923077 ], [ 29.725269310344828, -1.487971753846154 ], [ 29.72553879310345, -1.487971753846154 ], [ 29.72553879310345, -1.487702276923077 ], [ 29.725269310344828, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1416, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.487702276923077 ], [ 29.72553879310345, -1.487971753846154 ], [ 29.72607775862069, -1.487971753846154 ], [ 29.72607775862069, -1.487702276923077 ], [ 29.72553879310345, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1417, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.487702276923077 ], [ 29.72607775862069, -1.487971753846154 ], [ 29.726347241379312, -1.487971753846154 ], [ 29.726347241379312, -1.487702276923077 ], [ 29.72607775862069, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1418, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.487702276923077 ], [ 29.726347241379312, -1.487971753846154 ], [ 29.72661672413793, -1.487971753846154 ], [ 29.72661672413793, -1.487702276923077 ], [ 29.726347241379312, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1419, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.487702276923077 ], [ 29.72661672413793, -1.487971753846154 ], [ 29.727155689655174, -1.487971753846154 ], [ 29.727155689655174, -1.487702276923077 ], [ 29.72661672413793, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1420, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.487702276923077 ], [ 29.727155689655174, -1.487971753846154 ], [ 29.727425172413792, -1.487971753846154 ], [ 29.727425172413792, -1.487702276923077 ], [ 29.727155689655174, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1421, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.487702276923077 ], [ 29.727425172413792, -1.487971753846154 ], [ 29.727694655172414, -1.487971753846154 ], [ 29.727694655172414, -1.487702276923077 ], [ 29.727425172413792, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1422, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.487702276923077 ], [ 29.727694655172414, -1.487971753846154 ], [ 29.727964137931036, -1.487971753846154 ], [ 29.727964137931036, -1.487702276923077 ], [ 29.727694655172414, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1423, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.487702276923077 ], [ 29.727964137931036, -1.487971753846154 ], [ 29.728233620689654, -1.487971753846154 ], [ 29.728233620689654, -1.487702276923077 ], [ 29.727964137931036, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1424, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.487702276923077 ], [ 29.728233620689654, -1.487971753846154 ], [ 29.729042068965516, -1.487971753846154 ], [ 29.729042068965516, -1.487702276923077 ], [ 29.728233620689654, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1425, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.487702276923077 ], [ 29.729042068965516, -1.487971753846154 ], [ 29.729311551724138, -1.487971753846154 ], [ 29.729311551724138, -1.487702276923077 ], [ 29.729042068965516, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1426, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.487702276923077 ], [ 29.73012, -1.488241230769231 ], [ 29.730389482758621, -1.488241230769231 ], [ 29.730389482758621, -1.487702276923077 ], [ 29.73012, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1427, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.487702276923077 ], [ 29.732006379310345, -1.488510707692308 ], [ 29.732275862068967, -1.488510707692308 ], [ 29.732275862068967, -1.487702276923077 ], [ 29.732006379310345, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1428, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.487702276923077 ], [ 29.732275862068967, -1.488241230769231 ], [ 29.732545344827585, -1.488241230769231 ], [ 29.732545344827585, -1.487702276923077 ], [ 29.732275862068967, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1429, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.487702276923077 ], [ 29.732545344827585, -1.487971753846154 ], [ 29.732814827586207, -1.487971753846154 ], [ 29.732814827586207, -1.487702276923077 ], [ 29.732545344827585, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1430, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.487702276923077 ], [ 29.732814827586207, -1.487971753846154 ], [ 29.733084310344829, -1.487971753846154 ], [ 29.733084310344829, -1.487702276923077 ], [ 29.732814827586207, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1431, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.487702276923077 ], [ 29.733084310344829, -1.487971753846154 ], [ 29.733353793103447, -1.487971753846154 ], [ 29.733353793103447, -1.487702276923077 ], [ 29.733084310344829, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1432, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.487702276923077 ], [ 29.733353793103447, -1.487971753846154 ], [ 29.733623275862069, -1.487971753846154 ], [ 29.733623275862069, -1.487702276923077 ], [ 29.733353793103447, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1433, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.487702276923077 ], [ 29.733623275862069, -1.487971753846154 ], [ 29.733892758620691, -1.487971753846154 ], [ 29.733892758620691, -1.487702276923077 ], [ 29.733623275862069, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1434, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.487702276923077 ], [ 29.733892758620691, -1.487971753846154 ], [ 29.734162241379309, -1.487971753846154 ], [ 29.734162241379309, -1.487702276923077 ], [ 29.733892758620691, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1435, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.487702276923077 ], [ 29.734162241379309, -1.487971753846154 ], [ 29.734431724137931, -1.487971753846154 ], [ 29.734431724137931, -1.487702276923077 ], [ 29.734162241379309, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1436, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.487702276923077 ], [ 29.734431724137931, -1.487971753846154 ], [ 29.734701206896553, -1.487971753846154 ], [ 29.734701206896553, -1.487702276923077 ], [ 29.734431724137931, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1437, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.487702276923077 ], [ 29.734701206896553, -1.487971753846154 ], [ 29.734970689655171, -1.487971753846154 ], [ 29.734970689655171, -1.487702276923077 ], [ 29.734701206896553, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1438, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.487702276923077 ], [ 29.734970689655171, -1.487971753846154 ], [ 29.735240172413793, -1.487971753846154 ], [ 29.735240172413793, -1.487702276923077 ], [ 29.734970689655171, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1439, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.487702276923077 ], [ 29.735240172413793, -1.487971753846154 ], [ 29.735509655172415, -1.487971753846154 ], [ 29.735509655172415, -1.487702276923077 ], [ 29.735240172413793, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1440, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.487702276923077 ], [ 29.735509655172415, -1.487971753846154 ], [ 29.735779137931036, -1.487971753846154 ], [ 29.735779137931036, -1.487702276923077 ], [ 29.735509655172415, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1441, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.487702276923077 ], [ 29.735779137931036, -1.487971753846154 ], [ 29.736048620689655, -1.487971753846154 ], [ 29.736048620689655, -1.487702276923077 ], [ 29.735779137931036, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1442, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.487702276923077 ], [ 29.736048620689655, -1.487971753846154 ], [ 29.736318103448276, -1.487971753846154 ], [ 29.736318103448276, -1.487702276923077 ], [ 29.736048620689655, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1443, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.487702276923077 ], [ 29.736318103448276, -1.487971753846154 ], [ 29.737935, -1.487971753846154 ], [ 29.737935, -1.487702276923077 ], [ 29.736318103448276, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1444, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.487702276923077 ], [ 29.737935, -1.487971753846154 ], [ 29.738204482758622, -1.487971753846154 ], [ 29.738204482758622, -1.487702276923077 ], [ 29.737935, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1445, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.487702276923077 ], [ 29.738204482758622, -1.487971753846154 ], [ 29.738743448275862, -1.487971753846154 ], [ 29.738743448275862, -1.487702276923077 ], [ 29.738204482758622, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1446, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.487702276923077 ], [ 29.739012931034484, -1.487971753846154 ], [ 29.739282413793102, -1.487971753846154 ], [ 29.739282413793102, -1.487702276923077 ], [ 29.739012931034484, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1447, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.487702276923077 ], [ 29.739282413793102, -1.487971753846154 ], [ 29.739821379310346, -1.487971753846154 ], [ 29.739821379310346, -1.487702276923077 ], [ 29.739282413793102, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1448, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.487702276923077 ], [ 29.739821379310346, -1.487971753846154 ], [ 29.740360344827586, -1.487971753846154 ], [ 29.740360344827586, -1.487702276923077 ], [ 29.739821379310346, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1449, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.487702276923077 ], [ 29.740360344827586, -1.487971753846154 ], [ 29.741707758620691, -1.487971753846154 ], [ 29.741707758620691, -1.487702276923077 ], [ 29.740360344827586, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1450, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.487702276923077 ], [ 29.741707758620691, -1.487971753846154 ], [ 29.742246724137932, -1.487971753846154 ], [ 29.742246724137932, -1.487702276923077 ], [ 29.741707758620691, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1451, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.487702276923077 ], [ 29.742246724137932, -1.487971753846154 ], [ 29.742785689655172, -1.487971753846154 ], [ 29.742785689655172, -1.487702276923077 ], [ 29.742246724137932, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1452, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.487702276923077 ], [ 29.742785689655172, -1.487971753846154 ], [ 29.743324655172415, -1.487971753846154 ], [ 29.743324655172415, -1.487702276923077 ], [ 29.742785689655172, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1453, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.487702276923077 ], [ 29.743324655172415, -1.487971753846154 ], [ 29.743863620689655, -1.487971753846154 ], [ 29.743863620689655, -1.487702276923077 ], [ 29.743324655172415, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1454, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.487702276923077 ], [ 29.743863620689655, -1.487971753846154 ], [ 29.744402586206895, -1.487971753846154 ], [ 29.744402586206895, -1.487702276923077 ], [ 29.743863620689655, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1455, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.487702276923077 ], [ 29.744402586206895, -1.487971753846154 ], [ 29.744941551724139, -1.487971753846154 ], [ 29.744941551724139, -1.487702276923077 ], [ 29.744402586206895, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1456, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.487702276923077 ], [ 29.744941551724139, -1.487971753846154 ], [ 29.745480517241379, -1.487971753846154 ], [ 29.745480517241379, -1.487702276923077 ], [ 29.744941551724139, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1457, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.487702276923077 ], [ 29.745480517241379, -1.487971753846154 ], [ 29.746019482758619, -1.487971753846154 ], [ 29.746019482758619, -1.487702276923077 ], [ 29.745480517241379, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1458, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.487702276923077 ], [ 29.746019482758619, -1.487971753846154 ], [ 29.746827931034485, -1.487971753846154 ], [ 29.746827931034485, -1.487702276923077 ], [ 29.746019482758619, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1459, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.487702276923077 ], [ 29.746827931034485, -1.487971753846154 ], [ 29.747905862068965, -1.487971753846154 ], [ 29.747905862068965, -1.487702276923077 ], [ 29.746827931034485, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1460, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.487702276923077 ], [ 29.747905862068965, -1.487971753846154 ], [ 29.748714310344827, -1.487971753846154 ], [ 29.748714310344827, -1.487702276923077 ], [ 29.747905862068965, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1461, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.487702276923077 ], [ 29.748714310344827, -1.487971753846154 ], [ 29.74925327586207, -1.487971753846154 ], [ 29.74925327586207, -1.487702276923077 ], [ 29.748714310344827, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1462, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.487702276923077 ], [ 29.74925327586207, -1.487971753846154 ], [ 29.749792241379311, -1.487971753846154 ], [ 29.749792241379311, -1.487702276923077 ], [ 29.74925327586207, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1463, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.487702276923077 ], [ 29.749792241379311, -1.487971753846154 ], [ 29.750061724137932, -1.487971753846154 ], [ 29.750061724137932, -1.487702276923077 ], [ 29.749792241379311, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1464, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.487702276923077 ], [ 29.750061724137932, -1.487971753846154 ], [ 29.750331206896551, -1.487971753846154 ], [ 29.750331206896551, -1.487702276923077 ], [ 29.750061724137932, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1465, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.487702276923077 ], [ 29.750331206896551, -1.487971753846154 ], [ 29.750600689655172, -1.487971753846154 ], [ 29.750600689655172, -1.487702276923077 ], [ 29.750331206896551, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1466, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.487702276923077 ], [ 29.750600689655172, -1.487971753846154 ], [ 29.750870172413794, -1.487971753846154 ], [ 29.750870172413794, -1.487702276923077 ], [ 29.750600689655172, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1467, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.487702276923077 ], [ 29.750870172413794, -1.487971753846154 ], [ 29.751139655172413, -1.487971753846154 ], [ 29.751139655172413, -1.487702276923077 ], [ 29.750870172413794, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1468, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.487702276923077 ], [ 29.751139655172413, -1.487971753846154 ], [ 29.751409137931034, -1.487971753846154 ], [ 29.751409137931034, -1.487702276923077 ], [ 29.751139655172413, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1469, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.487702276923077 ], [ 29.751409137931034, -1.487971753846154 ], [ 29.751678620689656, -1.487971753846154 ], [ 29.751678620689656, -1.487702276923077 ], [ 29.751409137931034, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1470, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.487702276923077 ], [ 29.751678620689656, -1.487971753846154 ], [ 29.751948103448278, -1.487971753846154 ], [ 29.751948103448278, -1.487702276923077 ], [ 29.751678620689656, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1471, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.487702276923077 ], [ 29.751948103448278, -1.487971753846154 ], [ 29.752217586206896, -1.487971753846154 ], [ 29.752217586206896, -1.487702276923077 ], [ 29.751948103448278, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1472, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.487702276923077 ], [ 29.752217586206896, -1.487971753846154 ], [ 29.752487068965518, -1.487971753846154 ], [ 29.752487068965518, -1.487702276923077 ], [ 29.752217586206896, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1473, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.487702276923077 ], [ 29.752487068965518, -1.487971753846154 ], [ 29.75275655172414, -1.487971753846154 ], [ 29.75275655172414, -1.487702276923077 ], [ 29.752487068965518, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1474, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.487702276923077 ], [ 29.75275655172414, -1.487971753846154 ], [ 29.753026034482758, -1.487971753846154 ], [ 29.753026034482758, -1.487702276923077 ], [ 29.75275655172414, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1475, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.487702276923077 ], [ 29.753026034482758, -1.487971753846154 ], [ 29.75329551724138, -1.487971753846154 ], [ 29.75329551724138, -1.487702276923077 ], [ 29.753026034482758, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1476, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.487702276923077 ], [ 29.75329551724138, -1.487971753846154 ], [ 29.753565, -1.487971753846154 ], [ 29.753565, -1.487702276923077 ], [ 29.75329551724138, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1477, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.487702276923077 ], [ 29.754103965517242, -1.488241230769231 ], [ 29.754373448275864, -1.488241230769231 ], [ 29.754373448275864, -1.487702276923077 ], [ 29.754103965517242, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1478, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.487702276923077 ], [ 29.754912413793104, -1.487971753846154 ], [ 29.755181896551726, -1.487971753846154 ], [ 29.755181896551726, -1.487702276923077 ], [ 29.754912413793104, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1479, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.487702276923077 ], [ 29.755181896551726, -1.488241230769231 ], [ 29.755451379310344, -1.488241230769231 ], [ 29.755451379310344, -1.487702276923077 ], [ 29.755181896551726, -1.487702276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1480, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.487971753846154 ], [ 29.720149137931035, -1.488241230769231 ], [ 29.720688103448275, -1.488241230769231 ], [ 29.720688103448275, -1.487971753846154 ], [ 29.720149137931035, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1481, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.487971753846154 ], [ 29.721227068965518, -1.488241230769231 ], [ 29.721496551724137, -1.488241230769231 ], [ 29.721496551724137, -1.487971753846154 ], [ 29.721227068965518, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1482, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.487971753846154 ], [ 29.722843965517242, -1.488510707692308 ], [ 29.723113448275861, -1.488510707692308 ], [ 29.723113448275861, -1.487971753846154 ], [ 29.722843965517242, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1483, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.487971753846154 ], [ 29.723113448275861, -1.488241230769231 ], [ 29.723382931034482, -1.488241230769231 ], [ 29.723382931034482, -1.487971753846154 ], [ 29.723113448275861, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1484, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.487971753846154 ], [ 29.723382931034482, -1.488241230769231 ], [ 29.723652413793104, -1.488241230769231 ], [ 29.723652413793104, -1.487971753846154 ], [ 29.723382931034482, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1485, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.487971753846154 ], [ 29.723652413793104, -1.488241230769231 ], [ 29.723921896551722, -1.488241230769231 ], [ 29.723921896551722, -1.487971753846154 ], [ 29.723652413793104, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1486, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.487971753846154 ], [ 29.723921896551722, -1.488241230769231 ], [ 29.724999827586206, -1.488241230769231 ], [ 29.724999827586206, -1.487971753846154 ], [ 29.723921896551722, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1487, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.487971753846154 ], [ 29.724999827586206, -1.488241230769231 ], [ 29.725269310344828, -1.488241230769231 ], [ 29.725269310344828, -1.487971753846154 ], [ 29.724999827586206, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1488, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.487971753846154 ], [ 29.725269310344828, -1.488241230769231 ], [ 29.72553879310345, -1.488241230769231 ], [ 29.72553879310345, -1.487971753846154 ], [ 29.725269310344828, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1489, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.487971753846154 ], [ 29.72553879310345, -1.488241230769231 ], [ 29.72607775862069, -1.488241230769231 ], [ 29.72607775862069, -1.487971753846154 ], [ 29.72553879310345, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1490, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.487971753846154 ], [ 29.72607775862069, -1.488241230769231 ], [ 29.726347241379312, -1.488241230769231 ], [ 29.726347241379312, -1.487971753846154 ], [ 29.72607775862069, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1491, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.487971753846154 ], [ 29.726347241379312, -1.488241230769231 ], [ 29.72661672413793, -1.488241230769231 ], [ 29.72661672413793, -1.487971753846154 ], [ 29.726347241379312, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1492, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.487971753846154 ], [ 29.72661672413793, -1.488241230769231 ], [ 29.726886206896552, -1.488241230769231 ], [ 29.726886206896552, -1.487971753846154 ], [ 29.72661672413793, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1493, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.487971753846154 ], [ 29.726886206896552, -1.488241230769231 ], [ 29.727155689655174, -1.488241230769231 ], [ 29.727155689655174, -1.487971753846154 ], [ 29.726886206896552, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1494, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.487971753846154 ], [ 29.727155689655174, -1.488241230769231 ], [ 29.727425172413792, -1.488241230769231 ], [ 29.727425172413792, -1.487971753846154 ], [ 29.727155689655174, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1495, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.487971753846154 ], [ 29.727425172413792, -1.488241230769231 ], [ 29.727694655172414, -1.488241230769231 ], [ 29.727694655172414, -1.487971753846154 ], [ 29.727425172413792, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1496, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.487971753846154 ], [ 29.727694655172414, -1.488241230769231 ], [ 29.727964137931036, -1.488241230769231 ], [ 29.727964137931036, -1.487971753846154 ], [ 29.727694655172414, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1497, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.487971753846154 ], [ 29.727964137931036, -1.488241230769231 ], [ 29.729311551724138, -1.488241230769231 ], [ 29.729311551724138, -1.487971753846154 ], [ 29.727964137931036, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1498, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.487971753846154 ], [ 29.729311551724138, -1.488241230769231 ], [ 29.729850517241381, -1.488241230769231 ], [ 29.729850517241381, -1.487971753846154 ], [ 29.729311551724138, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1499, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.487971753846154 ], [ 29.729850517241381, -1.488241230769231 ], [ 29.73012, -1.488241230769231 ], [ 29.73012, -1.487971753846154 ], [ 29.729850517241381, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1500, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.487971753846154 ], [ 29.730389482758621, -1.488241230769231 ], [ 29.730658965517243, -1.488241230769231 ], [ 29.730658965517243, -1.487971753846154 ], [ 29.730389482758621, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1501, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.487971753846154 ], [ 29.730658965517243, -1.488241230769231 ], [ 29.730928448275861, -1.488241230769231 ], [ 29.730928448275861, -1.487971753846154 ], [ 29.730658965517243, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1502, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.487971753846154 ], [ 29.730928448275861, -1.488241230769231 ], [ 29.731197931034483, -1.488241230769231 ], [ 29.731197931034483, -1.487971753846154 ], [ 29.730928448275861, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1503, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.487971753846154 ], [ 29.731736896551723, -1.488510707692308 ], [ 29.732006379310345, -1.488510707692308 ], [ 29.732006379310345, -1.487971753846154 ], [ 29.731736896551723, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1504, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.487971753846154 ], [ 29.732545344827585, -1.488510707692308 ], [ 29.732814827586207, -1.488510707692308 ], [ 29.732814827586207, -1.487971753846154 ], [ 29.732545344827585, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1505, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.487971753846154 ], [ 29.732814827586207, -1.488510707692308 ], [ 29.733084310344829, -1.488510707692308 ], [ 29.733084310344829, -1.487971753846154 ], [ 29.732814827586207, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1506, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.487971753846154 ], [ 29.733084310344829, -1.488241230769231 ], [ 29.733353793103447, -1.488241230769231 ], [ 29.733353793103447, -1.487971753846154 ], [ 29.733084310344829, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1507, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.487971753846154 ], [ 29.733353793103447, -1.488241230769231 ], [ 29.733623275862069, -1.488241230769231 ], [ 29.733623275862069, -1.487971753846154 ], [ 29.733353793103447, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1508, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.487971753846154 ], [ 29.733623275862069, -1.488241230769231 ], [ 29.733892758620691, -1.488241230769231 ], [ 29.733892758620691, -1.487971753846154 ], [ 29.733623275862069, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1509, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.487971753846154 ], [ 29.733892758620691, -1.488241230769231 ], [ 29.734162241379309, -1.488241230769231 ], [ 29.734162241379309, -1.487971753846154 ], [ 29.733892758620691, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1510, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.487971753846154 ], [ 29.734162241379309, -1.488241230769231 ], [ 29.734431724137931, -1.488241230769231 ], [ 29.734431724137931, -1.487971753846154 ], [ 29.734162241379309, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1511, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.487971753846154 ], [ 29.734431724137931, -1.488241230769231 ], [ 29.734701206896553, -1.488241230769231 ], [ 29.734701206896553, -1.487971753846154 ], [ 29.734431724137931, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1512, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.487971753846154 ], [ 29.734701206896553, -1.488241230769231 ], [ 29.734970689655171, -1.488241230769231 ], [ 29.734970689655171, -1.487971753846154 ], [ 29.734701206896553, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1513, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.487971753846154 ], [ 29.734970689655171, -1.488241230769231 ], [ 29.735240172413793, -1.488241230769231 ], [ 29.735240172413793, -1.487971753846154 ], [ 29.734970689655171, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1514, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.487971753846154 ], [ 29.735240172413793, -1.488241230769231 ], [ 29.735509655172415, -1.488241230769231 ], [ 29.735509655172415, -1.487971753846154 ], [ 29.735240172413793, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1515, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.487971753846154 ], [ 29.735509655172415, -1.488241230769231 ], [ 29.735779137931036, -1.488241230769231 ], [ 29.735779137931036, -1.487971753846154 ], [ 29.735509655172415, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1516, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.487971753846154 ], [ 29.735779137931036, -1.488241230769231 ], [ 29.736048620689655, -1.488241230769231 ], [ 29.736048620689655, -1.487971753846154 ], [ 29.735779137931036, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1517, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.487971753846154 ], [ 29.736048620689655, -1.488241230769231 ], [ 29.736318103448276, -1.488241230769231 ], [ 29.736318103448276, -1.487971753846154 ], [ 29.736048620689655, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1518, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.487971753846154 ], [ 29.736318103448276, -1.488241230769231 ], [ 29.736587586206898, -1.488241230769231 ], [ 29.736587586206898, -1.488510707692308 ], [ 29.736857068965517, -1.488510707692308 ], [ 29.736857068965517, -1.488241230769231 ], [ 29.737126551724138, -1.488241230769231 ], [ 29.737126551724138, -1.487971753846154 ], [ 29.736318103448276, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1519, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.487971753846154 ], [ 29.737126551724138, -1.488241230769231 ], [ 29.73739603448276, -1.488241230769231 ], [ 29.73739603448276, -1.487971753846154 ], [ 29.737126551724138, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1520, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.487971753846154 ], [ 29.73739603448276, -1.488241230769231 ], [ 29.737665517241378, -1.488241230769231 ], [ 29.737665517241378, -1.487971753846154 ], [ 29.73739603448276, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1521, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.487971753846154 ], [ 29.737665517241378, -1.488241230769231 ], [ 29.737935, -1.488241230769231 ], [ 29.737935, -1.487971753846154 ], [ 29.737665517241378, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1522, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.487971753846154 ], [ 29.737935, -1.488241230769231 ], [ 29.738204482758622, -1.488241230769231 ], [ 29.738204482758622, -1.487971753846154 ], [ 29.737935, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1523, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.487971753846154 ], [ 29.738204482758622, -1.488241230769231 ], [ 29.738743448275862, -1.488241230769231 ], [ 29.738743448275862, -1.487971753846154 ], [ 29.738204482758622, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1524, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.487971753846154 ], [ 29.738743448275862, -1.488241230769231 ], [ 29.739012931034484, -1.488241230769231 ], [ 29.739012931034484, -1.487971753846154 ], [ 29.738743448275862, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1525, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.487971753846154 ], [ 29.739012931034484, -1.488241230769231 ], [ 29.739282413793102, -1.488241230769231 ], [ 29.739282413793102, -1.487971753846154 ], [ 29.739012931034484, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1526, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.487971753846154 ], [ 29.739282413793102, -1.488241230769231 ], [ 29.739551896551724, -1.488241230769231 ], [ 29.739551896551724, -1.487971753846154 ], [ 29.739282413793102, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1527, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.487971753846154 ], [ 29.739551896551724, -1.488241230769231 ], [ 29.740090862068964, -1.488241230769231 ], [ 29.740090862068964, -1.487971753846154 ], [ 29.739551896551724, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1528, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.487971753846154 ], [ 29.740090862068964, -1.488241230769231 ], [ 29.74089931034483, -1.488241230769231 ], [ 29.74089931034483, -1.487971753846154 ], [ 29.740090862068964, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1529, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.487971753846154 ], [ 29.74089931034483, -1.488241230769231 ], [ 29.742246724137932, -1.488241230769231 ], [ 29.742246724137932, -1.487971753846154 ], [ 29.74089931034483, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1530, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.487971753846154 ], [ 29.742246724137932, -1.488241230769231 ], [ 29.742785689655172, -1.488241230769231 ], [ 29.742785689655172, -1.487971753846154 ], [ 29.742246724137932, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1531, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.487971753846154 ], [ 29.742785689655172, -1.488241230769231 ], [ 29.743055172413793, -1.488241230769231 ], [ 29.743055172413793, -1.487971753846154 ], [ 29.742785689655172, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1532, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.487971753846154 ], [ 29.743055172413793, -1.488241230769231 ], [ 29.743594137931034, -1.488241230769231 ], [ 29.743594137931034, -1.487971753846154 ], [ 29.743055172413793, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1533, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.487971753846154 ], [ 29.743594137931034, -1.488241230769231 ], [ 29.744402586206895, -1.488241230769231 ], [ 29.744402586206895, -1.487971753846154 ], [ 29.743594137931034, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1534, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.487971753846154 ], [ 29.744402586206895, -1.488241230769231 ], [ 29.744941551724139, -1.488241230769231 ], [ 29.744941551724139, -1.487971753846154 ], [ 29.744402586206895, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1535, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.487971753846154 ], [ 29.744941551724139, -1.488241230769231 ], [ 29.745480517241379, -1.488241230769231 ], [ 29.745480517241379, -1.487971753846154 ], [ 29.744941551724139, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1536, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.487971753846154 ], [ 29.745480517241379, -1.488241230769231 ], [ 29.74575, -1.488241230769231 ], [ 29.74575, -1.487971753846154 ], [ 29.745480517241379, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1537, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.487971753846154 ], [ 29.74575, -1.488241230769231 ], [ 29.746288965517241, -1.488241230769231 ], [ 29.746288965517241, -1.487971753846154 ], [ 29.74575, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1538, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.487971753846154 ], [ 29.746288965517241, -1.488241230769231 ], [ 29.748444827586209, -1.488241230769231 ], [ 29.748444827586209, -1.487971753846154 ], [ 29.746288965517241, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1539, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.487971753846154 ], [ 29.748444827586209, -1.488241230769231 ], [ 29.748983793103449, -1.488241230769231 ], [ 29.748983793103449, -1.487971753846154 ], [ 29.748444827586209, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1540, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.487971753846154 ], [ 29.748983793103449, -1.488241230769231 ], [ 29.74925327586207, -1.488241230769231 ], [ 29.74925327586207, -1.487971753846154 ], [ 29.748983793103449, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1541, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.487971753846154 ], [ 29.74925327586207, -1.488241230769231 ], [ 29.749522758620689, -1.488241230769231 ], [ 29.749522758620689, -1.487971753846154 ], [ 29.74925327586207, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1542, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.487971753846154 ], [ 29.749522758620689, -1.488241230769231 ], [ 29.750061724137932, -1.488241230769231 ], [ 29.750061724137932, -1.487971753846154 ], [ 29.749522758620689, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1543, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.487971753846154 ], [ 29.750061724137932, -1.488241230769231 ], [ 29.750331206896551, -1.488241230769231 ], [ 29.750331206896551, -1.487971753846154 ], [ 29.750061724137932, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1544, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.487971753846154 ], [ 29.750331206896551, -1.488241230769231 ], [ 29.750600689655172, -1.488241230769231 ], [ 29.750600689655172, -1.487971753846154 ], [ 29.750331206896551, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1545, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.487971753846154 ], [ 29.750600689655172, -1.488241230769231 ], [ 29.750870172413794, -1.488241230769231 ], [ 29.750870172413794, -1.487971753846154 ], [ 29.750600689655172, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1546, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.487971753846154 ], [ 29.750870172413794, -1.488241230769231 ], [ 29.751139655172413, -1.488241230769231 ], [ 29.751139655172413, -1.487971753846154 ], [ 29.750870172413794, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1547, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.487971753846154 ], [ 29.751139655172413, -1.488241230769231 ], [ 29.751409137931034, -1.488241230769231 ], [ 29.751409137931034, -1.487971753846154 ], [ 29.751139655172413, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1548, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.487971753846154 ], [ 29.751409137931034, -1.488241230769231 ], [ 29.751678620689656, -1.488241230769231 ], [ 29.751678620689656, -1.487971753846154 ], [ 29.751409137931034, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1549, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.487971753846154 ], [ 29.751678620689656, -1.488241230769231 ], [ 29.751948103448278, -1.488241230769231 ], [ 29.751948103448278, -1.487971753846154 ], [ 29.751678620689656, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1550, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.487971753846154 ], [ 29.751948103448278, -1.488241230769231 ], [ 29.752217586206896, -1.488241230769231 ], [ 29.752217586206896, -1.487971753846154 ], [ 29.751948103448278, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1551, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.487971753846154 ], [ 29.752217586206896, -1.488241230769231 ], [ 29.752487068965518, -1.488241230769231 ], [ 29.752487068965518, -1.487971753846154 ], [ 29.752217586206896, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1552, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.487971753846154 ], [ 29.752487068965518, -1.488241230769231 ], [ 29.75275655172414, -1.488241230769231 ], [ 29.75275655172414, -1.487971753846154 ], [ 29.752487068965518, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1553, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.487971753846154 ], [ 29.75275655172414, -1.488241230769231 ], [ 29.753026034482758, -1.488241230769231 ], [ 29.753026034482758, -1.487971753846154 ], [ 29.75275655172414, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1554, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.487971753846154 ], [ 29.753026034482758, -1.488241230769231 ], [ 29.75329551724138, -1.488241230769231 ], [ 29.75329551724138, -1.487971753846154 ], [ 29.753026034482758, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1555, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.487971753846154 ], [ 29.75329551724138, -1.488241230769231 ], [ 29.753565, -1.488241230769231 ], [ 29.753565, -1.487971753846154 ], [ 29.75329551724138, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1556, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.487971753846154 ], [ 29.753565, -1.488241230769231 ], [ 29.75383448275862, -1.488241230769231 ], [ 29.75383448275862, -1.487971753846154 ], [ 29.753565, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1557, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.487971753846154 ], [ 29.75383448275862, -1.488241230769231 ], [ 29.754103965517242, -1.488241230769231 ], [ 29.754103965517242, -1.487971753846154 ], [ 29.75383448275862, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1558, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.487971753846154 ], [ 29.754373448275864, -1.488241230769231 ], [ 29.754642931034482, -1.488241230769231 ], [ 29.754642931034482, -1.487971753846154 ], [ 29.754373448275864, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1559, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.487971753846154 ], [ 29.754642931034482, -1.488510707692308 ], [ 29.754912413793104, -1.488510707692308 ], [ 29.754912413793104, -1.487971753846154 ], [ 29.754642931034482, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1560, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.487971753846154 ], [ 29.754912413793104, -1.488510707692308 ], [ 29.755181896551726, -1.488510707692308 ], [ 29.755181896551726, -1.487971753846154 ], [ 29.754912413793104, -1.487971753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1561, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.488241230769231 ], [ 29.719879655172413, -1.488510707692308 ], [ 29.720149137931035, -1.488510707692308 ], [ 29.720149137931035, -1.488241230769231 ], [ 29.719879655172413, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1562, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.488241230769231 ], [ 29.720149137931035, -1.488510707692308 ], [ 29.720688103448275, -1.488510707692308 ], [ 29.720688103448275, -1.488241230769231 ], [ 29.720149137931035, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1563, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.488241230769231 ], [ 29.721227068965518, -1.488780184615385 ], [ 29.721496551724137, -1.488780184615385 ], [ 29.721496551724137, -1.488241230769231 ], [ 29.721227068965518, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1564, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.488241230769231 ], [ 29.723113448275861, -1.488510707692308 ], [ 29.723382931034482, -1.488510707692308 ], [ 29.723382931034482, -1.488241230769231 ], [ 29.723113448275861, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1565, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.488241230769231 ], [ 29.723382931034482, -1.488510707692308 ], [ 29.723652413793104, -1.488510707692308 ], [ 29.723652413793104, -1.488241230769231 ], [ 29.723382931034482, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1566, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.488241230769231 ], [ 29.723652413793104, -1.488510707692308 ], [ 29.723921896551722, -1.488510707692308 ], [ 29.723921896551722, -1.488241230769231 ], [ 29.723652413793104, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1567, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.488241230769231 ], [ 29.723921896551722, -1.488510707692308 ], [ 29.724999827586206, -1.488510707692308 ], [ 29.724999827586206, -1.488241230769231 ], [ 29.723921896551722, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1568, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.488241230769231 ], [ 29.724999827586206, -1.488510707692308 ], [ 29.725269310344828, -1.488510707692308 ], [ 29.725269310344828, -1.488241230769231 ], [ 29.724999827586206, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1569, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.488241230769231 ], [ 29.725269310344828, -1.488510707692308 ], [ 29.725808275862068, -1.488510707692308 ], [ 29.725808275862068, -1.488241230769231 ], [ 29.725269310344828, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1570, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.488241230769231 ], [ 29.725808275862068, -1.488510707692308 ], [ 29.72607775862069, -1.488510707692308 ], [ 29.72607775862069, -1.488241230769231 ], [ 29.725808275862068, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1571, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.488241230769231 ], [ 29.72607775862069, -1.488510707692308 ], [ 29.726347241379312, -1.488510707692308 ], [ 29.726347241379312, -1.488241230769231 ], [ 29.72607775862069, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1572, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.488241230769231 ], [ 29.726347241379312, -1.488510707692308 ], [ 29.72661672413793, -1.488510707692308 ], [ 29.72661672413793, -1.488241230769231 ], [ 29.726347241379312, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1573, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.488241230769231 ], [ 29.72661672413793, -1.488510707692308 ], [ 29.726886206896552, -1.488510707692308 ], [ 29.726886206896552, -1.488241230769231 ], [ 29.72661672413793, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1574, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.488241230769231 ], [ 29.726886206896552, -1.488510707692308 ], [ 29.727155689655174, -1.488510707692308 ], [ 29.727155689655174, -1.488241230769231 ], [ 29.726886206896552, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1575, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.488241230769231 ], [ 29.727155689655174, -1.488510707692308 ], [ 29.727425172413792, -1.488510707692308 ], [ 29.727425172413792, -1.488241230769231 ], [ 29.727155689655174, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1576, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.488241230769231 ], [ 29.727425172413792, -1.488510707692308 ], [ 29.727694655172414, -1.488510707692308 ], [ 29.727694655172414, -1.488241230769231 ], [ 29.727425172413792, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1577, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.488241230769231 ], [ 29.727694655172414, -1.488510707692308 ], [ 29.727964137931036, -1.488510707692308 ], [ 29.727964137931036, -1.488241230769231 ], [ 29.727694655172414, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1578, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.488241230769231 ], [ 29.727964137931036, -1.488510707692308 ], [ 29.729311551724138, -1.488510707692308 ], [ 29.729311551724138, -1.488241230769231 ], [ 29.727964137931036, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1579, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.488241230769231 ], [ 29.729311551724138, -1.488510707692308 ], [ 29.729850517241381, -1.488510707692308 ], [ 29.729850517241381, -1.488241230769231 ], [ 29.729311551724138, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1580, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.488241230769231 ], [ 29.729850517241381, -1.488510707692308 ], [ 29.73012, -1.488510707692308 ], [ 29.73012, -1.488241230769231 ], [ 29.729850517241381, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1581, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.488241230769231 ], [ 29.73012, -1.488510707692308 ], [ 29.730389482758621, -1.488510707692308 ], [ 29.730389482758621, -1.488241230769231 ], [ 29.73012, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1582, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.488241230769231 ], [ 29.730389482758621, -1.488510707692308 ], [ 29.730658965517243, -1.488510707692308 ], [ 29.730658965517243, -1.488241230769231 ], [ 29.730389482758621, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1583, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.488241230769231 ], [ 29.730658965517243, -1.488510707692308 ], [ 29.730928448275861, -1.488510707692308 ], [ 29.730928448275861, -1.488241230769231 ], [ 29.730658965517243, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1584, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.488241230769231 ], [ 29.730928448275861, -1.488510707692308 ], [ 29.731197931034483, -1.488510707692308 ], [ 29.731197931034483, -1.488241230769231 ], [ 29.730928448275861, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1585, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.488241230769231 ], [ 29.731197931034483, -1.488510707692308 ], [ 29.731467413793105, -1.488510707692308 ], [ 29.731467413793105, -1.488241230769231 ], [ 29.731197931034483, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1586, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.488241230769231 ], [ 29.731467413793105, -1.488510707692308 ], [ 29.731736896551723, -1.488510707692308 ], [ 29.731736896551723, -1.488241230769231 ], [ 29.731467413793105, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1587, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.488241230769231 ], [ 29.732275862068967, -1.488780184615385 ], [ 29.732545344827585, -1.488780184615385 ], [ 29.732545344827585, -1.488241230769231 ], [ 29.732275862068967, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1588, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.488241230769231 ], [ 29.733084310344829, -1.488780184615385 ], [ 29.733353793103447, -1.488780184615385 ], [ 29.733353793103447, -1.488241230769231 ], [ 29.733084310344829, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1589, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.488241230769231 ], [ 29.733353793103447, -1.488780184615385 ], [ 29.733623275862069, -1.488780184615385 ], [ 29.733623275862069, -1.488241230769231 ], [ 29.733353793103447, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1590, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.488241230769231 ], [ 29.733623275862069, -1.488510707692308 ], [ 29.733892758620691, -1.488510707692308 ], [ 29.733892758620691, -1.488241230769231 ], [ 29.733623275862069, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1591, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.488241230769231 ], [ 29.733892758620691, -1.488510707692308 ], [ 29.734162241379309, -1.488510707692308 ], [ 29.734162241379309, -1.488241230769231 ], [ 29.733892758620691, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1592, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.488241230769231 ], [ 29.734162241379309, -1.488510707692308 ], [ 29.734431724137931, -1.488510707692308 ], [ 29.734431724137931, -1.488241230769231 ], [ 29.734162241379309, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1593, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.488241230769231 ], [ 29.734431724137931, -1.488510707692308 ], [ 29.734701206896553, -1.488510707692308 ], [ 29.734701206896553, -1.488241230769231 ], [ 29.734431724137931, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1594, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.488241230769231 ], [ 29.734701206896553, -1.488510707692308 ], [ 29.734970689655171, -1.488510707692308 ], [ 29.734970689655171, -1.488241230769231 ], [ 29.734701206896553, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1595, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.488241230769231 ], [ 29.734970689655171, -1.488510707692308 ], [ 29.735240172413793, -1.488510707692308 ], [ 29.735240172413793, -1.488241230769231 ], [ 29.734970689655171, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1596, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.488241230769231 ], [ 29.735240172413793, -1.488510707692308 ], [ 29.735509655172415, -1.488510707692308 ], [ 29.735509655172415, -1.488241230769231 ], [ 29.735240172413793, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1597, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.488241230769231 ], [ 29.735509655172415, -1.488510707692308 ], [ 29.735779137931036, -1.488510707692308 ], [ 29.735779137931036, -1.488241230769231 ], [ 29.735509655172415, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1598, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.488241230769231 ], [ 29.735779137931036, -1.488510707692308 ], [ 29.736048620689655, -1.488510707692308 ], [ 29.736048620689655, -1.488241230769231 ], [ 29.735779137931036, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1599, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.488241230769231 ], [ 29.736048620689655, -1.488510707692308 ], [ 29.736318103448276, -1.488510707692308 ], [ 29.736318103448276, -1.488241230769231 ], [ 29.736048620689655, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1600, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.488241230769231 ], [ 29.736318103448276, -1.488510707692308 ], [ 29.736587586206898, -1.488510707692308 ], [ 29.736587586206898, -1.488241230769231 ], [ 29.736318103448276, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1601, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.488241230769231 ], [ 29.736857068965517, -1.488510707692308 ], [ 29.737126551724138, -1.488510707692308 ], [ 29.737126551724138, -1.488241230769231 ], [ 29.736857068965517, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1602, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.488241230769231 ], [ 29.737126551724138, -1.488510707692308 ], [ 29.73739603448276, -1.488510707692308 ], [ 29.73739603448276, -1.488241230769231 ], [ 29.737126551724138, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1603, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.488241230769231 ], [ 29.73739603448276, -1.488510707692308 ], [ 29.737665517241378, -1.488510707692308 ], [ 29.737665517241378, -1.488241230769231 ], [ 29.73739603448276, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1604, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.488241230769231 ], [ 29.737665517241378, -1.488510707692308 ], [ 29.737935, -1.488510707692308 ], [ 29.737935, -1.488241230769231 ], [ 29.737665517241378, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1605, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.488241230769231 ], [ 29.737935, -1.488510707692308 ], [ 29.738204482758622, -1.488510707692308 ], [ 29.738204482758622, -1.488241230769231 ], [ 29.737935, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1606, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.488241230769231 ], [ 29.738204482758622, -1.488510707692308 ], [ 29.738743448275862, -1.488510707692308 ], [ 29.738743448275862, -1.488241230769231 ], [ 29.738204482758622, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1607, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.488241230769231 ], [ 29.738743448275862, -1.488510707692308 ], [ 29.739012931034484, -1.488510707692308 ], [ 29.739012931034484, -1.488241230769231 ], [ 29.738743448275862, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1608, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.488241230769231 ], [ 29.739012931034484, -1.488510707692308 ], [ 29.739282413793102, -1.488510707692308 ], [ 29.739282413793102, -1.488241230769231 ], [ 29.739012931034484, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1609, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.488241230769231 ], [ 29.739282413793102, -1.488510707692308 ], [ 29.739551896551724, -1.488510707692308 ], [ 29.739551896551724, -1.488241230769231 ], [ 29.739282413793102, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1610, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.488241230769231 ], [ 29.739551896551724, -1.488510707692308 ], [ 29.740090862068964, -1.488510707692308 ], [ 29.740090862068964, -1.488241230769231 ], [ 29.739551896551724, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1611, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.488241230769231 ], [ 29.740090862068964, -1.488510707692308 ], [ 29.740629827586208, -1.488510707692308 ], [ 29.740629827586208, -1.488241230769231 ], [ 29.740090862068964, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1612, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.488241230769231 ], [ 29.740629827586208, -1.488510707692308 ], [ 29.74197724137931, -1.488510707692308 ], [ 29.74197724137931, -1.488241230769231 ], [ 29.740629827586208, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1613, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.488241230769231 ], [ 29.74197724137931, -1.488510707692308 ], [ 29.742516206896553, -1.488510707692308 ], [ 29.742516206896553, -1.488241230769231 ], [ 29.74197724137931, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1614, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.488241230769231 ], [ 29.742516206896553, -1.488510707692308 ], [ 29.743055172413793, -1.488510707692308 ], [ 29.743055172413793, -1.488241230769231 ], [ 29.742516206896553, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1615, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.488241230769231 ], [ 29.743055172413793, -1.488510707692308 ], [ 29.743594137931034, -1.488510707692308 ], [ 29.743594137931034, -1.488241230769231 ], [ 29.743055172413793, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1616, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.488241230769231 ], [ 29.743594137931034, -1.488510707692308 ], [ 29.744402586206895, -1.488510707692308 ], [ 29.744402586206895, -1.488241230769231 ], [ 29.743594137931034, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1617, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.488241230769231 ], [ 29.744402586206895, -1.488510707692308 ], [ 29.744672068965517, -1.488510707692308 ], [ 29.744672068965517, -1.488241230769231 ], [ 29.744402586206895, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1618, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.488241230769231 ], [ 29.744672068965517, -1.488510707692308 ], [ 29.745211034482757, -1.488510707692308 ], [ 29.745211034482757, -1.488241230769231 ], [ 29.744672068965517, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1619, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.488241230769231 ], [ 29.745211034482757, -1.488510707692308 ], [ 29.74575, -1.488510707692308 ], [ 29.74575, -1.488241230769231 ], [ 29.745211034482757, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1620, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.488241230769231 ], [ 29.74575, -1.488510707692308 ], [ 29.746288965517241, -1.488510707692308 ], [ 29.746288965517241, -1.488241230769231 ], [ 29.74575, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1621, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.488241230769231 ], [ 29.746288965517241, -1.488510707692308 ], [ 29.748444827586209, -1.488510707692308 ], [ 29.748444827586209, -1.488241230769231 ], [ 29.746288965517241, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1622, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.488241230769231 ], [ 29.748444827586209, -1.488510707692308 ], [ 29.748983793103449, -1.488510707692308 ], [ 29.748983793103449, -1.488241230769231 ], [ 29.748444827586209, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1623, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.488241230769231 ], [ 29.748983793103449, -1.488510707692308 ], [ 29.74925327586207, -1.488510707692308 ], [ 29.74925327586207, -1.488241230769231 ], [ 29.748983793103449, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1624, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.488241230769231 ], [ 29.74925327586207, -1.488510707692308 ], [ 29.749792241379311, -1.488510707692308 ], [ 29.749792241379311, -1.488241230769231 ], [ 29.74925327586207, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1625, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.488241230769231 ], [ 29.749792241379311, -1.488510707692308 ], [ 29.750061724137932, -1.488510707692308 ], [ 29.750061724137932, -1.488241230769231 ], [ 29.749792241379311, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1626, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.488241230769231 ], [ 29.750061724137932, -1.488510707692308 ], [ 29.750331206896551, -1.488510707692308 ], [ 29.750331206896551, -1.488241230769231 ], [ 29.750061724137932, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1627, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.488241230769231 ], [ 29.750331206896551, -1.488510707692308 ], [ 29.750600689655172, -1.488510707692308 ], [ 29.750600689655172, -1.488241230769231 ], [ 29.750331206896551, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1628, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.488241230769231 ], [ 29.750600689655172, -1.488510707692308 ], [ 29.750870172413794, -1.488510707692308 ], [ 29.750870172413794, -1.488241230769231 ], [ 29.750600689655172, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1629, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.488241230769231 ], [ 29.750870172413794, -1.488510707692308 ], [ 29.751139655172413, -1.488510707692308 ], [ 29.751139655172413, -1.488241230769231 ], [ 29.750870172413794, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1630, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.488241230769231 ], [ 29.751139655172413, -1.488510707692308 ], [ 29.751409137931034, -1.488510707692308 ], [ 29.751409137931034, -1.488241230769231 ], [ 29.751139655172413, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1631, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.488241230769231 ], [ 29.751409137931034, -1.488510707692308 ], [ 29.751678620689656, -1.488510707692308 ], [ 29.751678620689656, -1.488241230769231 ], [ 29.751409137931034, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1632, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.488241230769231 ], [ 29.751678620689656, -1.488510707692308 ], [ 29.751948103448278, -1.488510707692308 ], [ 29.751948103448278, -1.488241230769231 ], [ 29.751678620689656, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1633, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.488241230769231 ], [ 29.751948103448278, -1.488510707692308 ], [ 29.752217586206896, -1.488510707692308 ], [ 29.752217586206896, -1.488241230769231 ], [ 29.751948103448278, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1634, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.488241230769231 ], [ 29.752217586206896, -1.488510707692308 ], [ 29.752487068965518, -1.488510707692308 ], [ 29.752487068965518, -1.488241230769231 ], [ 29.752217586206896, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1635, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.488241230769231 ], [ 29.752487068965518, -1.488510707692308 ], [ 29.75275655172414, -1.488510707692308 ], [ 29.75275655172414, -1.488241230769231 ], [ 29.752487068965518, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1636, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.488241230769231 ], [ 29.75275655172414, -1.488510707692308 ], [ 29.753026034482758, -1.488510707692308 ], [ 29.753026034482758, -1.488241230769231 ], [ 29.75275655172414, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1637, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.488241230769231 ], [ 29.753026034482758, -1.488510707692308 ], [ 29.75329551724138, -1.488510707692308 ], [ 29.75329551724138, -1.488241230769231 ], [ 29.753026034482758, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1638, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.488241230769231 ], [ 29.75329551724138, -1.488510707692308 ], [ 29.753565, -1.488510707692308 ], [ 29.753565, -1.488241230769231 ], [ 29.75329551724138, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1639, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.488241230769231 ], [ 29.753565, -1.488510707692308 ], [ 29.75383448275862, -1.488510707692308 ], [ 29.75383448275862, -1.488241230769231 ], [ 29.753565, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1640, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.488241230769231 ], [ 29.75383448275862, -1.488510707692308 ], [ 29.754103965517242, -1.488510707692308 ], [ 29.754103965517242, -1.488241230769231 ], [ 29.75383448275862, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1641, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.488241230769231 ], [ 29.754103965517242, -1.488510707692308 ], [ 29.754373448275864, -1.488510707692308 ], [ 29.754373448275864, -1.488241230769231 ], [ 29.754103965517242, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1642, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.488241230769231 ], [ 29.754373448275864, -1.488780184615385 ], [ 29.754642931034482, -1.488780184615385 ], [ 29.754642931034482, -1.488241230769231 ], [ 29.754373448275864, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1643, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.488241230769231 ], [ 29.755181896551726, -1.488510707692308 ], [ 29.755451379310344, -1.488510707692308 ], [ 29.755451379310344, -1.488241230769231 ], [ 29.755181896551726, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1644, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.488241230769231 ], [ 29.755451379310344, -1.488510707692308 ], [ 29.755720862068966, -1.488510707692308 ], [ 29.755720862068966, -1.488241230769231 ], [ 29.755451379310344, -1.488241230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1645, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.488510707692308 ], [ 29.719340689655173, -1.488780184615385 ], [ 29.719879655172413, -1.488780184615385 ], [ 29.719879655172413, -1.488510707692308 ], [ 29.719340689655173, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1646, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.488510707692308 ], [ 29.719879655172413, -1.488780184615385 ], [ 29.720149137931035, -1.488780184615385 ], [ 29.720149137931035, -1.488510707692308 ], [ 29.719879655172413, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1647, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.488510707692308 ], [ 29.720149137931035, -1.488780184615385 ], [ 29.720688103448275, -1.488780184615385 ], [ 29.720688103448275, -1.488510707692308 ], [ 29.720149137931035, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1648, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.488510707692308 ], [ 29.720688103448275, -1.488780184615385 ], [ 29.720957586206897, -1.488780184615385 ], [ 29.720957586206897, -1.488510707692308 ], [ 29.720688103448275, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1649, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.488510707692308 ], [ 29.720957586206897, -1.488780184615385 ], [ 29.721227068965518, -1.488780184615385 ], [ 29.721227068965518, -1.488510707692308 ], [ 29.720957586206897, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1650, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.488510707692308 ], [ 29.721496551724137, -1.488780184615385 ], [ 29.721766034482759, -1.488780184615385 ], [ 29.721766034482759, -1.488510707692308 ], [ 29.721496551724137, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1651, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.488510707692308 ], [ 29.721766034482759, -1.488780184615385 ], [ 29.72203551724138, -1.488780184615385 ], [ 29.72203551724138, -1.488510707692308 ], [ 29.721766034482759, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1652, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.488510707692308 ], [ 29.72203551724138, -1.488780184615385 ], [ 29.722305, -1.488780184615385 ], [ 29.722305, -1.488510707692308 ], [ 29.72203551724138, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1653, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.488510707692308 ], [ 29.722843965517242, -1.489049661538461 ], [ 29.723113448275861, -1.489049661538461 ], [ 29.723113448275861, -1.488510707692308 ], [ 29.722843965517242, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1654, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.488510707692308 ], [ 29.723113448275861, -1.489049661538461 ], [ 29.723382931034482, -1.489049661538461 ], [ 29.723382931034482, -1.488510707692308 ], [ 29.723113448275861, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1655, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.488510707692308 ], [ 29.723382931034482, -1.488780184615385 ], [ 29.723652413793104, -1.488780184615385 ], [ 29.723652413793104, -1.488510707692308 ], [ 29.723382931034482, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1656, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.488510707692308 ], [ 29.723652413793104, -1.488780184615385 ], [ 29.724191379310344, -1.488780184615385 ], [ 29.724191379310344, -1.488510707692308 ], [ 29.723652413793104, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1657, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.488510707692308 ], [ 29.724191379310344, -1.488780184615385 ], [ 29.724999827586206, -1.488780184615385 ], [ 29.724999827586206, -1.488510707692308 ], [ 29.724191379310344, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1658, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.488510707692308 ], [ 29.724999827586206, -1.488780184615385 ], [ 29.725269310344828, -1.488780184615385 ], [ 29.725269310344828, -1.488510707692308 ], [ 29.724999827586206, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1659, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.488510707692308 ], [ 29.725269310344828, -1.488780184615385 ], [ 29.725808275862068, -1.488780184615385 ], [ 29.725808275862068, -1.488510707692308 ], [ 29.725269310344828, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1660, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.488510707692308 ], [ 29.725808275862068, -1.488780184615385 ], [ 29.72607775862069, -1.488780184615385 ], [ 29.72607775862069, -1.488510707692308 ], [ 29.725808275862068, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1661, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.488510707692308 ], [ 29.72607775862069, -1.488780184615385 ], [ 29.726347241379312, -1.488780184615385 ], [ 29.726347241379312, -1.488510707692308 ], [ 29.72607775862069, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1662, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.488510707692308 ], [ 29.726347241379312, -1.488780184615385 ], [ 29.72661672413793, -1.488780184615385 ], [ 29.72661672413793, -1.488510707692308 ], [ 29.726347241379312, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1663, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.488510707692308 ], [ 29.72661672413793, -1.488780184615385 ], [ 29.726886206896552, -1.488780184615385 ], [ 29.726886206896552, -1.488510707692308 ], [ 29.72661672413793, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1664, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.488510707692308 ], [ 29.726886206896552, -1.488780184615385 ], [ 29.727155689655174, -1.488780184615385 ], [ 29.727155689655174, -1.488510707692308 ], [ 29.726886206896552, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1665, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.488510707692308 ], [ 29.727155689655174, -1.488780184615385 ], [ 29.727425172413792, -1.488780184615385 ], [ 29.727425172413792, -1.488510707692308 ], [ 29.727155689655174, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1666, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.488510707692308 ], [ 29.727425172413792, -1.488780184615385 ], [ 29.727694655172414, -1.488780184615385 ], [ 29.727694655172414, -1.488510707692308 ], [ 29.727425172413792, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1667, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.488510707692308 ], [ 29.727694655172414, -1.488780184615385 ], [ 29.728233620689654, -1.488780184615385 ], [ 29.728233620689654, -1.488510707692308 ], [ 29.727694655172414, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1668, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.488510707692308 ], [ 29.728233620689654, -1.488780184615385 ], [ 29.729042068965516, -1.488780184615385 ], [ 29.729042068965516, -1.488510707692308 ], [ 29.728233620689654, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1669, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.488510707692308 ], [ 29.729042068965516, -1.488780184615385 ], [ 29.729850517241381, -1.488780184615385 ], [ 29.729850517241381, -1.488510707692308 ], [ 29.729042068965516, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1670, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.488510707692308 ], [ 29.729850517241381, -1.488780184615385 ], [ 29.73012, -1.488780184615385 ], [ 29.73012, -1.488510707692308 ], [ 29.729850517241381, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1671, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.488510707692308 ], [ 29.73012, -1.488780184615385 ], [ 29.730389482758621, -1.488780184615385 ], [ 29.730389482758621, -1.488510707692308 ], [ 29.73012, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1672, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.488510707692308 ], [ 29.730389482758621, -1.488780184615385 ], [ 29.730658965517243, -1.488780184615385 ], [ 29.730658965517243, -1.488510707692308 ], [ 29.730389482758621, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1673, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.488510707692308 ], [ 29.730658965517243, -1.488780184615385 ], [ 29.730928448275861, -1.488780184615385 ], [ 29.730928448275861, -1.488510707692308 ], [ 29.730658965517243, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1674, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.488510707692308 ], [ 29.730928448275861, -1.488780184615385 ], [ 29.731197931034483, -1.488780184615385 ], [ 29.731197931034483, -1.488510707692308 ], [ 29.730928448275861, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1675, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.488510707692308 ], [ 29.731197931034483, -1.488780184615385 ], [ 29.731467413793105, -1.488780184615385 ], [ 29.731467413793105, -1.488510707692308 ], [ 29.731197931034483, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1676, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.488510707692308 ], [ 29.731467413793105, -1.488780184615385 ], [ 29.731736896551723, -1.488780184615385 ], [ 29.731736896551723, -1.488510707692308 ], [ 29.731467413793105, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1677, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.488510707692308 ], [ 29.731736896551723, -1.488780184615385 ], [ 29.732006379310345, -1.488780184615385 ], [ 29.732006379310345, -1.488510707692308 ], [ 29.731736896551723, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1678, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.488510707692308 ], [ 29.732006379310345, -1.488780184615385 ], [ 29.732275862068967, -1.488780184615385 ], [ 29.732275862068967, -1.488510707692308 ], [ 29.732006379310345, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1679, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.488510707692308 ], [ 29.732545344827585, -1.489049661538461 ], [ 29.732814827586207, -1.489049661538461 ], [ 29.732814827586207, -1.488510707692308 ], [ 29.732545344827585, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1680, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.488510707692308 ], [ 29.732814827586207, -1.488780184615385 ], [ 29.733084310344829, -1.488780184615385 ], [ 29.733084310344829, -1.488510707692308 ], [ 29.732814827586207, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1681, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.488510707692308 ], [ 29.733623275862069, -1.489049661538461 ], [ 29.733892758620691, -1.489049661538461 ], [ 29.733892758620691, -1.488510707692308 ], [ 29.733623275862069, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1682, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.488510707692308 ], [ 29.733892758620691, -1.489049661538461 ], [ 29.734162241379309, -1.489049661538461 ], [ 29.734162241379309, -1.488510707692308 ], [ 29.733892758620691, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1683, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.488510707692308 ], [ 29.734162241379309, -1.488780184615385 ], [ 29.734431724137931, -1.488780184615385 ], [ 29.734431724137931, -1.488510707692308 ], [ 29.734162241379309, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1684, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.488510707692308 ], [ 29.734431724137931, -1.488780184615385 ], [ 29.734701206896553, -1.488780184615385 ], [ 29.734701206896553, -1.488510707692308 ], [ 29.734431724137931, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1685, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.488510707692308 ], [ 29.734701206896553, -1.488780184615385 ], [ 29.734970689655171, -1.488780184615385 ], [ 29.734970689655171, -1.488510707692308 ], [ 29.734701206896553, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1686, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.488510707692308 ], [ 29.734970689655171, -1.488780184615385 ], [ 29.735240172413793, -1.488780184615385 ], [ 29.735240172413793, -1.488510707692308 ], [ 29.734970689655171, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1687, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.488510707692308 ], [ 29.735240172413793, -1.488780184615385 ], [ 29.735509655172415, -1.488780184615385 ], [ 29.735509655172415, -1.488510707692308 ], [ 29.735240172413793, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1688, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.488510707692308 ], [ 29.735509655172415, -1.489049661538461 ], [ 29.735779137931036, -1.489049661538461 ], [ 29.735779137931036, -1.488510707692308 ], [ 29.735509655172415, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1689, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.488510707692308 ], [ 29.735779137931036, -1.488780184615385 ], [ 29.736048620689655, -1.488780184615385 ], [ 29.736048620689655, -1.488510707692308 ], [ 29.735779137931036, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1690, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.488510707692308 ], [ 29.736048620689655, -1.488780184615385 ], [ 29.736318103448276, -1.488780184615385 ], [ 29.736318103448276, -1.488510707692308 ], [ 29.736048620689655, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1691, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.488510707692308 ], [ 29.736318103448276, -1.488780184615385 ], [ 29.736587586206898, -1.488780184615385 ], [ 29.736587586206898, -1.488510707692308 ], [ 29.736318103448276, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1692, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.488510707692308 ], [ 29.736587586206898, -1.488780184615385 ], [ 29.736857068965517, -1.488780184615385 ], [ 29.736857068965517, -1.488510707692308 ], [ 29.736587586206898, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1693, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.488510707692308 ], [ 29.736857068965517, -1.488780184615385 ], [ 29.737126551724138, -1.488780184615385 ], [ 29.737126551724138, -1.488510707692308 ], [ 29.736857068965517, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1694, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.488510707692308 ], [ 29.737126551724138, -1.488780184615385 ], [ 29.73739603448276, -1.488780184615385 ], [ 29.73739603448276, -1.488510707692308 ], [ 29.737126551724138, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1695, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.488510707692308 ], [ 29.73739603448276, -1.488780184615385 ], [ 29.737665517241378, -1.488780184615385 ], [ 29.737665517241378, -1.488510707692308 ], [ 29.73739603448276, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1696, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.488510707692308 ], [ 29.737665517241378, -1.488780184615385 ], [ 29.737935, -1.488780184615385 ], [ 29.737935, -1.488510707692308 ], [ 29.737665517241378, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1697, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.488510707692308 ], [ 29.737935, -1.488780184615385 ], [ 29.738204482758622, -1.488780184615385 ], [ 29.738204482758622, -1.488510707692308 ], [ 29.737935, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1698, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.488510707692308 ], [ 29.738204482758622, -1.488780184615385 ], [ 29.738743448275862, -1.488780184615385 ], [ 29.738743448275862, -1.488510707692308 ], [ 29.738204482758622, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1699, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.488510707692308 ], [ 29.738743448275862, -1.488780184615385 ], [ 29.739012931034484, -1.488780184615385 ], [ 29.739012931034484, -1.488510707692308 ], [ 29.738743448275862, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1700, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.488510707692308 ], [ 29.739012931034484, -1.488780184615385 ], [ 29.739282413793102, -1.488780184615385 ], [ 29.739282413793102, -1.488510707692308 ], [ 29.739012931034484, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1701, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.488510707692308 ], [ 29.739282413793102, -1.488780184615385 ], [ 29.739551896551724, -1.488780184615385 ], [ 29.739551896551724, -1.488510707692308 ], [ 29.739282413793102, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1702, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.488510707692308 ], [ 29.739551896551724, -1.488780184615385 ], [ 29.739821379310346, -1.488780184615385 ], [ 29.739821379310346, -1.488510707692308 ], [ 29.739551896551724, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1703, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.488510707692308 ], [ 29.739821379310346, -1.488780184615385 ], [ 29.740360344827586, -1.488780184615385 ], [ 29.740360344827586, -1.488510707692308 ], [ 29.739821379310346, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1704, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.488510707692308 ], [ 29.740360344827586, -1.488780184615385 ], [ 29.74197724137931, -1.488780184615385 ], [ 29.74197724137931, -1.488510707692308 ], [ 29.740360344827586, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1705, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.488510707692308 ], [ 29.74197724137931, -1.488780184615385 ], [ 29.742516206896553, -1.488780184615385 ], [ 29.742516206896553, -1.488510707692308 ], [ 29.74197724137931, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1706, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.488510707692308 ], [ 29.742516206896553, -1.488780184615385 ], [ 29.743055172413793, -1.488780184615385 ], [ 29.743055172413793, -1.488510707692308 ], [ 29.742516206896553, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1707, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.488510707692308 ], [ 29.743055172413793, -1.488780184615385 ], [ 29.743324655172415, -1.488780184615385 ], [ 29.743324655172415, -1.488510707692308 ], [ 29.743055172413793, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1708, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.488510707692308 ], [ 29.743324655172415, -1.488780184615385 ], [ 29.743863620689655, -1.488780184615385 ], [ 29.743863620689655, -1.488510707692308 ], [ 29.743324655172415, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1709, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.488510707692308 ], [ 29.743863620689655, -1.488780184615385 ], [ 29.744672068965517, -1.488780184615385 ], [ 29.744672068965517, -1.488510707692308 ], [ 29.743863620689655, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1710, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.488510707692308 ], [ 29.744672068965517, -1.488780184615385 ], [ 29.745211034482757, -1.488780184615385 ], [ 29.745211034482757, -1.488510707692308 ], [ 29.744672068965517, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1711, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.488510707692308 ], [ 29.745211034482757, -1.488780184615385 ], [ 29.74575, -1.488780184615385 ], [ 29.74575, -1.488510707692308 ], [ 29.745211034482757, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1712, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.488510707692308 ], [ 29.74575, -1.488780184615385 ], [ 29.746019482758619, -1.488780184615385 ], [ 29.746019482758619, -1.488510707692308 ], [ 29.74575, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1713, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.488510707692308 ], [ 29.746019482758619, -1.488780184615385 ], [ 29.748714310344827, -1.488780184615385 ], [ 29.748714310344827, -1.488510707692308 ], [ 29.746019482758619, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1714, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.488510707692308 ], [ 29.748714310344827, -1.488780184615385 ], [ 29.748983793103449, -1.488780184615385 ], [ 29.748983793103449, -1.488510707692308 ], [ 29.748714310344827, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1715, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.488510707692308 ], [ 29.748983793103449, -1.488780184615385 ], [ 29.749522758620689, -1.488780184615385 ], [ 29.749522758620689, -1.488510707692308 ], [ 29.748983793103449, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1716, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.488510707692308 ], [ 29.749522758620689, -1.488780184615385 ], [ 29.749792241379311, -1.488780184615385 ], [ 29.749792241379311, -1.488510707692308 ], [ 29.749522758620689, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1717, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.488510707692308 ], [ 29.749792241379311, -1.488780184615385 ], [ 29.750061724137932, -1.488780184615385 ], [ 29.750061724137932, -1.488510707692308 ], [ 29.749792241379311, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1718, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.488510707692308 ], [ 29.750061724137932, -1.488780184615385 ], [ 29.750331206896551, -1.488780184615385 ], [ 29.750331206896551, -1.488510707692308 ], [ 29.750061724137932, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1719, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.488510707692308 ], [ 29.750331206896551, -1.488780184615385 ], [ 29.750600689655172, -1.488780184615385 ], [ 29.750600689655172, -1.488510707692308 ], [ 29.750331206896551, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1720, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.488510707692308 ], [ 29.750600689655172, -1.488780184615385 ], [ 29.750870172413794, -1.488780184615385 ], [ 29.750870172413794, -1.488510707692308 ], [ 29.750600689655172, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1721, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.488510707692308 ], [ 29.750870172413794, -1.488780184615385 ], [ 29.751139655172413, -1.488780184615385 ], [ 29.751139655172413, -1.488510707692308 ], [ 29.750870172413794, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1722, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.488510707692308 ], [ 29.751139655172413, -1.488780184615385 ], [ 29.751409137931034, -1.488780184615385 ], [ 29.751409137931034, -1.488510707692308 ], [ 29.751139655172413, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1723, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.488510707692308 ], [ 29.751409137931034, -1.488780184615385 ], [ 29.751678620689656, -1.488780184615385 ], [ 29.751678620689656, -1.488510707692308 ], [ 29.751409137931034, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1724, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.488510707692308 ], [ 29.751678620689656, -1.488780184615385 ], [ 29.751948103448278, -1.488780184615385 ], [ 29.751948103448278, -1.488510707692308 ], [ 29.751678620689656, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1725, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.488510707692308 ], [ 29.751948103448278, -1.488780184615385 ], [ 29.752217586206896, -1.488780184615385 ], [ 29.752217586206896, -1.488510707692308 ], [ 29.751948103448278, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1726, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.488510707692308 ], [ 29.752217586206896, -1.488780184615385 ], [ 29.752487068965518, -1.488780184615385 ], [ 29.752487068965518, -1.488510707692308 ], [ 29.752217586206896, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1727, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.488510707692308 ], [ 29.752487068965518, -1.488780184615385 ], [ 29.75275655172414, -1.488780184615385 ], [ 29.75275655172414, -1.488510707692308 ], [ 29.752487068965518, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1728, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.488510707692308 ], [ 29.75275655172414, -1.488780184615385 ], [ 29.753026034482758, -1.488780184615385 ], [ 29.753026034482758, -1.488510707692308 ], [ 29.75275655172414, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1729, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.488510707692308 ], [ 29.753026034482758, -1.488780184615385 ], [ 29.75329551724138, -1.488780184615385 ], [ 29.75329551724138, -1.488510707692308 ], [ 29.753026034482758, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1730, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.488510707692308 ], [ 29.75329551724138, -1.488780184615385 ], [ 29.753565, -1.488780184615385 ], [ 29.753565, -1.488510707692308 ], [ 29.75329551724138, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1731, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.488510707692308 ], [ 29.753565, -1.488780184615385 ], [ 29.75383448275862, -1.488780184615385 ], [ 29.75383448275862, -1.488510707692308 ], [ 29.753565, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1732, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.488510707692308 ], [ 29.75383448275862, -1.488780184615385 ], [ 29.754103965517242, -1.488780184615385 ], [ 29.754103965517242, -1.488510707692308 ], [ 29.75383448275862, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1733, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.488510707692308 ], [ 29.754103965517242, -1.488780184615385 ], [ 29.754373448275864, -1.488780184615385 ], [ 29.754373448275864, -1.488510707692308 ], [ 29.754103965517242, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1734, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.488510707692308 ], [ 29.754642931034482, -1.488780184615385 ], [ 29.754912413793104, -1.488780184615385 ], [ 29.754912413793104, -1.488510707692308 ], [ 29.754642931034482, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1735, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.488510707692308 ], [ 29.754912413793104, -1.488780184615385 ], [ 29.755181896551726, -1.488780184615385 ], [ 29.755181896551726, -1.488510707692308 ], [ 29.754912413793104, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1736, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.488510707692308 ], [ 29.755181896551726, -1.488780184615385 ], [ 29.755451379310344, -1.488780184615385 ], [ 29.755451379310344, -1.488510707692308 ], [ 29.755181896551726, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1737, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.488510707692308 ], [ 29.755451379310344, -1.488780184615385 ], [ 29.755720862068966, -1.488780184615385 ], [ 29.755720862068966, -1.488510707692308 ], [ 29.755451379310344, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1738, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.488510707692308 ], [ 29.755720862068966, -1.488780184615385 ], [ 29.755990344827588, -1.488780184615385 ], [ 29.755990344827588, -1.488510707692308 ], [ 29.755720862068966, -1.488510707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1739, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.488780184615385 ], [ 29.719071206896551, -1.489049661538461 ], [ 29.719340689655173, -1.489049661538461 ], [ 29.719340689655173, -1.488780184615385 ], [ 29.719071206896551, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1740, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.488780184615385 ], [ 29.719340689655173, -1.489049661538461 ], [ 29.719610172413795, -1.489049661538461 ], [ 29.719610172413795, -1.488780184615385 ], [ 29.719340689655173, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1741, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.488780184615385 ], [ 29.719610172413795, -1.489049661538461 ], [ 29.720149137931035, -1.489049661538461 ], [ 29.720149137931035, -1.488780184615385 ], [ 29.719610172413795, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1742, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.488780184615385 ], [ 29.720149137931035, -1.489049661538461 ], [ 29.720688103448275, -1.489049661538461 ], [ 29.720688103448275, -1.488780184615385 ], [ 29.720149137931035, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1743, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.488780184615385 ], [ 29.720688103448275, -1.489049661538461 ], [ 29.720957586206897, -1.489049661538461 ], [ 29.720957586206897, -1.488780184615385 ], [ 29.720688103448275, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1744, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.488780184615385 ], [ 29.720957586206897, -1.489049661538461 ], [ 29.721227068965518, -1.489049661538461 ], [ 29.721227068965518, -1.488780184615385 ], [ 29.720957586206897, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1745, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.488780184615385 ], [ 29.721227068965518, -1.489049661538461 ], [ 29.721496551724137, -1.489049661538461 ], [ 29.721496551724137, -1.488780184615385 ], [ 29.721227068965518, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1746, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.488780184615385 ], [ 29.721496551724137, -1.489049661538461 ], [ 29.721766034482759, -1.489049661538461 ], [ 29.721766034482759, -1.488780184615385 ], [ 29.721496551724137, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1747, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.488780184615385 ], [ 29.721766034482759, -1.489049661538461 ], [ 29.72203551724138, -1.489049661538461 ], [ 29.72203551724138, -1.488780184615385 ], [ 29.721766034482759, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1748, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.488780184615385 ], [ 29.72203551724138, -1.489049661538461 ], [ 29.722305, -1.489049661538461 ], [ 29.722305, -1.488780184615385 ], [ 29.72203551724138, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1749, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.488780184615385 ], [ 29.722305, -1.489049661538461 ], [ 29.72257448275862, -1.489049661538461 ], [ 29.72257448275862, -1.488780184615385 ], [ 29.722305, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1750, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.488780184615385 ], [ 29.72257448275862, -1.489049661538461 ], [ 29.722843965517242, -1.489049661538461 ], [ 29.722843965517242, -1.488780184615385 ], [ 29.72257448275862, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1751, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.488780184615385 ], [ 29.723382931034482, -1.489049661538461 ], [ 29.723652413793104, -1.489049661538461 ], [ 29.723652413793104, -1.488780184615385 ], [ 29.723382931034482, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1752, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.488780184615385 ], [ 29.723652413793104, -1.489049661538461 ], [ 29.724730344827588, -1.489049661538461 ], [ 29.724730344827588, -1.488780184615385 ], [ 29.723652413793104, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1753, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.488780184615385 ], [ 29.724730344827588, -1.489049661538461 ], [ 29.724999827586206, -1.489049661538461 ], [ 29.724999827586206, -1.488780184615385 ], [ 29.724730344827588, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1754, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.488780184615385 ], [ 29.724999827586206, -1.489049661538461 ], [ 29.72553879310345, -1.489049661538461 ], [ 29.72553879310345, -1.488780184615385 ], [ 29.724999827586206, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1755, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.488780184615385 ], [ 29.72553879310345, -1.489049661538461 ], [ 29.725808275862068, -1.489049661538461 ], [ 29.725808275862068, -1.488780184615385 ], [ 29.72553879310345, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1756, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.488780184615385 ], [ 29.725808275862068, -1.489049661538461 ], [ 29.72607775862069, -1.489049661538461 ], [ 29.72607775862069, -1.488780184615385 ], [ 29.725808275862068, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1757, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.488780184615385 ], [ 29.72607775862069, -1.489049661538461 ], [ 29.726347241379312, -1.489049661538461 ], [ 29.726347241379312, -1.488780184615385 ], [ 29.72607775862069, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1758, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.488780184615385 ], [ 29.726347241379312, -1.489049661538461 ], [ 29.72661672413793, -1.489049661538461 ], [ 29.72661672413793, -1.488780184615385 ], [ 29.726347241379312, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1759, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.488780184615385 ], [ 29.72661672413793, -1.489049661538461 ], [ 29.726886206896552, -1.489049661538461 ], [ 29.726886206896552, -1.488780184615385 ], [ 29.72661672413793, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1760, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.488780184615385 ], [ 29.726886206896552, -1.489049661538461 ], [ 29.727155689655174, -1.489049661538461 ], [ 29.727155689655174, -1.488780184615385 ], [ 29.726886206896552, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1761, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.488780184615385 ], [ 29.727155689655174, -1.489049661538461 ], [ 29.727425172413792, -1.489049661538461 ], [ 29.727425172413792, -1.488780184615385 ], [ 29.727155689655174, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1762, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.488780184615385 ], [ 29.727425172413792, -1.489049661538461 ], [ 29.727694655172414, -1.489049661538461 ], [ 29.727694655172414, -1.488780184615385 ], [ 29.727425172413792, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1763, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.488780184615385 ], [ 29.727694655172414, -1.489049661538461 ], [ 29.727964137931036, -1.489049661538461 ], [ 29.727964137931036, -1.488780184615385 ], [ 29.727694655172414, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1764, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.488780184615385 ], [ 29.727964137931036, -1.489049661538461 ], [ 29.729311551724138, -1.489049661538461 ], [ 29.729311551724138, -1.488780184615385 ], [ 29.727964137931036, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1765, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.488780184615385 ], [ 29.729311551724138, -1.489049661538461 ], [ 29.729850517241381, -1.489049661538461 ], [ 29.729850517241381, -1.488780184615385 ], [ 29.729311551724138, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1766, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.488780184615385 ], [ 29.729850517241381, -1.489049661538461 ], [ 29.73012, -1.489049661538461 ], [ 29.73012, -1.488780184615385 ], [ 29.729850517241381, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1767, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.488780184615385 ], [ 29.73012, -1.489049661538461 ], [ 29.730389482758621, -1.489049661538461 ], [ 29.730389482758621, -1.488780184615385 ], [ 29.73012, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1768, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.488780184615385 ], [ 29.730389482758621, -1.489049661538461 ], [ 29.730658965517243, -1.489049661538461 ], [ 29.730658965517243, -1.488780184615385 ], [ 29.730389482758621, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1769, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.488780184615385 ], [ 29.730658965517243, -1.489049661538461 ], [ 29.730928448275861, -1.489049661538461 ], [ 29.730928448275861, -1.488780184615385 ], [ 29.730658965517243, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1770, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.488780184615385 ], [ 29.730928448275861, -1.489049661538461 ], [ 29.731197931034483, -1.489049661538461 ], [ 29.731197931034483, -1.488780184615385 ], [ 29.730928448275861, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1771, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.488780184615385 ], [ 29.731197931034483, -1.489049661538461 ], [ 29.731467413793105, -1.489049661538461 ], [ 29.731467413793105, -1.488780184615385 ], [ 29.731197931034483, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1772, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.488780184615385 ], [ 29.731467413793105, -1.489049661538461 ], [ 29.731736896551723, -1.489049661538461 ], [ 29.731736896551723, -1.488780184615385 ], [ 29.731467413793105, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1773, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.488780184615385 ], [ 29.731736896551723, -1.489049661538461 ], [ 29.732006379310345, -1.489049661538461 ], [ 29.732006379310345, -1.488780184615385 ], [ 29.731736896551723, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1774, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.488780184615385 ], [ 29.732006379310345, -1.489049661538461 ], [ 29.732275862068967, -1.489049661538461 ], [ 29.732275862068967, -1.488780184615385 ], [ 29.732006379310345, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1775, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.488780184615385 ], [ 29.732275862068967, -1.489049661538461 ], [ 29.732545344827585, -1.489049661538461 ], [ 29.732545344827585, -1.488780184615385 ], [ 29.732275862068967, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1776, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.488780184615385 ], [ 29.732814827586207, -1.489319138461539 ], [ 29.733084310344829, -1.489319138461539 ], [ 29.733084310344829, -1.488780184615385 ], [ 29.732814827586207, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1777, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.488780184615385 ], [ 29.733084310344829, -1.489049661538461 ], [ 29.733353793103447, -1.489049661538461 ], [ 29.733353793103447, -1.488780184615385 ], [ 29.733084310344829, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1778, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.488780184615385 ], [ 29.733353793103447, -1.489049661538461 ], [ 29.733623275862069, -1.489049661538461 ], [ 29.733623275862069, -1.488780184615385 ], [ 29.733353793103447, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1779, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.488780184615385 ], [ 29.734162241379309, -1.489319138461539 ], [ 29.734431724137931, -1.489319138461539 ], [ 29.734431724137931, -1.488780184615385 ], [ 29.734162241379309, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1780, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.488780184615385 ], [ 29.734431724137931, -1.489319138461539 ], [ 29.734701206896553, -1.489319138461539 ], [ 29.734701206896553, -1.488780184615385 ], [ 29.734431724137931, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1781, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.488780184615385 ], [ 29.734701206896553, -1.489049661538461 ], [ 29.734970689655171, -1.489049661538461 ], [ 29.734970689655171, -1.488780184615385 ], [ 29.734701206896553, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1782, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.488780184615385 ], [ 29.734970689655171, -1.489049661538461 ], [ 29.735240172413793, -1.489049661538461 ], [ 29.735240172413793, -1.488780184615385 ], [ 29.734970689655171, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1783, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.488780184615385 ], [ 29.735240172413793, -1.489049661538461 ], [ 29.735509655172415, -1.489049661538461 ], [ 29.735509655172415, -1.488780184615385 ], [ 29.735240172413793, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1784, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.488780184615385 ], [ 29.735779137931036, -1.489049661538461 ], [ 29.736048620689655, -1.489049661538461 ], [ 29.736048620689655, -1.488780184615385 ], [ 29.735779137931036, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1785, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.488780184615385 ], [ 29.736048620689655, -1.489049661538461 ], [ 29.736318103448276, -1.489049661538461 ], [ 29.736318103448276, -1.488780184615385 ], [ 29.736048620689655, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1786, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.488780184615385 ], [ 29.736318103448276, -1.489049661538461 ], [ 29.736587586206898, -1.489049661538461 ], [ 29.736587586206898, -1.488780184615385 ], [ 29.736318103448276, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1787, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.488780184615385 ], [ 29.736587586206898, -1.489049661538461 ], [ 29.736857068965517, -1.489049661538461 ], [ 29.736857068965517, -1.488780184615385 ], [ 29.736587586206898, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1788, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.488780184615385 ], [ 29.736857068965517, -1.489049661538461 ], [ 29.737126551724138, -1.489049661538461 ], [ 29.737126551724138, -1.488780184615385 ], [ 29.736857068965517, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1789, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.488780184615385 ], [ 29.737126551724138, -1.489049661538461 ], [ 29.73739603448276, -1.489049661538461 ], [ 29.73739603448276, -1.488780184615385 ], [ 29.737126551724138, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1790, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.488780184615385 ], [ 29.73739603448276, -1.489049661538461 ], [ 29.737665517241378, -1.489049661538461 ], [ 29.737665517241378, -1.488780184615385 ], [ 29.73739603448276, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1791, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.488780184615385 ], [ 29.737665517241378, -1.489049661538461 ], [ 29.737935, -1.489049661538461 ], [ 29.737935, -1.488780184615385 ], [ 29.737665517241378, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1792, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.488780184615385 ], [ 29.737935, -1.489049661538461 ], [ 29.738204482758622, -1.489049661538461 ], [ 29.738204482758622, -1.488780184615385 ], [ 29.737935, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1793, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.488780184615385 ], [ 29.738204482758622, -1.489049661538461 ], [ 29.738743448275862, -1.489049661538461 ], [ 29.738743448275862, -1.488780184615385 ], [ 29.738204482758622, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1794, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.488780184615385 ], [ 29.738743448275862, -1.489049661538461 ], [ 29.739012931034484, -1.489049661538461 ], [ 29.739012931034484, -1.488780184615385 ], [ 29.738743448275862, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1795, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.488780184615385 ], [ 29.739012931034484, -1.489049661538461 ], [ 29.739282413793102, -1.489049661538461 ], [ 29.739282413793102, -1.488780184615385 ], [ 29.739012931034484, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1796, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.488780184615385 ], [ 29.739282413793102, -1.489049661538461 ], [ 29.739551896551724, -1.489049661538461 ], [ 29.739551896551724, -1.488780184615385 ], [ 29.739282413793102, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1797, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.488780184615385 ], [ 29.739551896551724, -1.489049661538461 ], [ 29.739821379310346, -1.489049661538461 ], [ 29.739821379310346, -1.488780184615385 ], [ 29.739551896551724, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1798, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.488780184615385 ], [ 29.739821379310346, -1.489049661538461 ], [ 29.740360344827586, -1.489049661538461 ], [ 29.740360344827586, -1.488780184615385 ], [ 29.739821379310346, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1799, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.488780184615385 ], [ 29.740360344827586, -1.489049661538461 ], [ 29.741168793103448, -1.489049661538461 ], [ 29.741168793103448, -1.488780184615385 ], [ 29.740360344827586, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1800, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.488780184615385 ], [ 29.741168793103448, -1.489049661538461 ], [ 29.742246724137932, -1.489049661538461 ], [ 29.742246724137932, -1.488780184615385 ], [ 29.741168793103448, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1801, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.488780184615385 ], [ 29.742246724137932, -1.489049661538461 ], [ 29.742785689655172, -1.489049661538461 ], [ 29.742785689655172, -1.488780184615385 ], [ 29.742246724137932, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1802, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.488780184615385 ], [ 29.742785689655172, -1.489049661538461 ], [ 29.743324655172415, -1.489049661538461 ], [ 29.743324655172415, -1.488780184615385 ], [ 29.742785689655172, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1803, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.488780184615385 ], [ 29.743324655172415, -1.489049661538461 ], [ 29.743863620689655, -1.489049661538461 ], [ 29.743863620689655, -1.488780184615385 ], [ 29.743324655172415, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1804, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.488780184615385 ], [ 29.743863620689655, -1.489049661538461 ], [ 29.744672068965517, -1.489049661538461 ], [ 29.744672068965517, -1.488780184615385 ], [ 29.743863620689655, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1805, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.488780184615385 ], [ 29.744672068965517, -1.489049661538461 ], [ 29.744941551724139, -1.489049661538461 ], [ 29.744941551724139, -1.488780184615385 ], [ 29.744672068965517, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1806, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.488780184615385 ], [ 29.744941551724139, -1.489049661538461 ], [ 29.745480517241379, -1.489049661538461 ], [ 29.745480517241379, -1.488780184615385 ], [ 29.744941551724139, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1807, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.488780184615385 ], [ 29.745480517241379, -1.489049661538461 ], [ 29.746019482758619, -1.489049661538461 ], [ 29.746019482758619, -1.488780184615385 ], [ 29.745480517241379, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1808, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.488780184615385 ], [ 29.746019482758619, -1.489049661538461 ], [ 29.746558448275863, -1.489049661538461 ], [ 29.746558448275863, -1.488780184615385 ], [ 29.746019482758619, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1809, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.488780184615385 ], [ 29.746558448275863, -1.489049661538461 ], [ 29.748175344827587, -1.489049661538461 ], [ 29.748175344827587, -1.488780184615385 ], [ 29.746558448275863, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1810, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.488780184615385 ], [ 29.748175344827587, -1.489049661538461 ], [ 29.748714310344827, -1.489049661538461 ], [ 29.748714310344827, -1.488780184615385 ], [ 29.748175344827587, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1811, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.488780184615385 ], [ 29.748714310344827, -1.489049661538461 ], [ 29.74925327586207, -1.489049661538461 ], [ 29.74925327586207, -1.488780184615385 ], [ 29.748714310344827, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1812, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.488780184615385 ], [ 29.74925327586207, -1.489049661538461 ], [ 29.749522758620689, -1.489049661538461 ], [ 29.749522758620689, -1.488780184615385 ], [ 29.74925327586207, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1813, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.488780184615385 ], [ 29.749522758620689, -1.489049661538461 ], [ 29.749792241379311, -1.489049661538461 ], [ 29.749792241379311, -1.488780184615385 ], [ 29.749522758620689, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1814, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.488780184615385 ], [ 29.749792241379311, -1.489049661538461 ], [ 29.750061724137932, -1.489049661538461 ], [ 29.750061724137932, -1.488780184615385 ], [ 29.749792241379311, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1815, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.488780184615385 ], [ 29.750061724137932, -1.489049661538461 ], [ 29.750331206896551, -1.489049661538461 ], [ 29.750331206896551, -1.488780184615385 ], [ 29.750061724137932, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1816, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.488780184615385 ], [ 29.750331206896551, -1.489049661538461 ], [ 29.750600689655172, -1.489049661538461 ], [ 29.750600689655172, -1.488780184615385 ], [ 29.750331206896551, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1817, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.488780184615385 ], [ 29.750600689655172, -1.489049661538461 ], [ 29.750870172413794, -1.489049661538461 ], [ 29.750870172413794, -1.488780184615385 ], [ 29.750600689655172, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1818, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.488780184615385 ], [ 29.750870172413794, -1.489049661538461 ], [ 29.751139655172413, -1.489049661538461 ], [ 29.751139655172413, -1.488780184615385 ], [ 29.750870172413794, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1819, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.488780184615385 ], [ 29.751139655172413, -1.489049661538461 ], [ 29.751409137931034, -1.489049661538461 ], [ 29.751409137931034, -1.488780184615385 ], [ 29.751139655172413, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1820, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.488780184615385 ], [ 29.751409137931034, -1.489319138461539 ], [ 29.751678620689656, -1.489319138461539 ], [ 29.751678620689656, -1.488780184615385 ], [ 29.751409137931034, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1821, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.488780184615385 ], [ 29.751678620689656, -1.489049661538461 ], [ 29.751948103448278, -1.489049661538461 ], [ 29.751948103448278, -1.488780184615385 ], [ 29.751678620689656, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1822, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.488780184615385 ], [ 29.751948103448278, -1.489049661538461 ], [ 29.752217586206896, -1.489049661538461 ], [ 29.752217586206896, -1.488780184615385 ], [ 29.751948103448278, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1823, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.488780184615385 ], [ 29.752217586206896, -1.489049661538461 ], [ 29.752487068965518, -1.489049661538461 ], [ 29.752487068965518, -1.488780184615385 ], [ 29.752217586206896, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1824, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.488780184615385 ], [ 29.752487068965518, -1.489049661538461 ], [ 29.75275655172414, -1.489049661538461 ], [ 29.75275655172414, -1.488780184615385 ], [ 29.752487068965518, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1825, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.488780184615385 ], [ 29.75275655172414, -1.489049661538461 ], [ 29.753026034482758, -1.489049661538461 ], [ 29.753026034482758, -1.488780184615385 ], [ 29.75275655172414, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1826, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.488780184615385 ], [ 29.753026034482758, -1.489049661538461 ], [ 29.75329551724138, -1.489049661538461 ], [ 29.75329551724138, -1.488780184615385 ], [ 29.753026034482758, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1827, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.488780184615385 ], [ 29.75329551724138, -1.489049661538461 ], [ 29.753565, -1.489049661538461 ], [ 29.753565, -1.488780184615385 ], [ 29.75329551724138, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1828, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.488780184615385 ], [ 29.753565, -1.489049661538461 ], [ 29.75383448275862, -1.489049661538461 ], [ 29.75383448275862, -1.488780184615385 ], [ 29.753565, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1829, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.488780184615385 ], [ 29.75383448275862, -1.489049661538461 ], [ 29.754103965517242, -1.489049661538461 ], [ 29.754103965517242, -1.488780184615385 ], [ 29.75383448275862, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1830, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.488780184615385 ], [ 29.754103965517242, -1.489049661538461 ], [ 29.754373448275864, -1.489049661538461 ], [ 29.754373448275864, -1.488780184615385 ], [ 29.754103965517242, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1831, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.488780184615385 ], [ 29.754373448275864, -1.489049661538461 ], [ 29.754642931034482, -1.489049661538461 ], [ 29.754642931034482, -1.488780184615385 ], [ 29.754373448275864, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1832, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.488780184615385 ], [ 29.754642931034482, -1.489049661538461 ], [ 29.754912413793104, -1.489049661538461 ], [ 29.754912413793104, -1.488780184615385 ], [ 29.754642931034482, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1833, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.488780184615385 ], [ 29.754912413793104, -1.489049661538461 ], [ 29.755181896551726, -1.489049661538461 ], [ 29.755181896551726, -1.488780184615385 ], [ 29.754912413793104, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1834, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.488780184615385 ], [ 29.755181896551726, -1.489049661538461 ], [ 29.755451379310344, -1.489049661538461 ], [ 29.755451379310344, -1.488780184615385 ], [ 29.755181896551726, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1835, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.488780184615385 ], [ 29.755451379310344, -1.489049661538461 ], [ 29.755720862068966, -1.489049661538461 ], [ 29.755720862068966, -1.488780184615385 ], [ 29.755451379310344, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1836, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.488780184615385 ], [ 29.755720862068966, -1.489049661538461 ], [ 29.755990344827588, -1.489049661538461 ], [ 29.755990344827588, -1.488780184615385 ], [ 29.755720862068966, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1837, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.489049661538461 ], [ 29.718532241379311, -1.489319138461539 ], [ 29.719071206896551, -1.489319138461539 ], [ 29.719071206896551, -1.489049661538461 ], [ 29.718532241379311, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1838, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.489049661538461 ], [ 29.719071206896551, -1.489319138461539 ], [ 29.719340689655173, -1.489319138461539 ], [ 29.719340689655173, -1.489049661538461 ], [ 29.719071206896551, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1839, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.489049661538461 ], [ 29.719340689655173, -1.489319138461539 ], [ 29.719610172413795, -1.489319138461539 ], [ 29.719610172413795, -1.489049661538461 ], [ 29.719340689655173, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1840, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.489049661538461 ], [ 29.719610172413795, -1.489319138461539 ], [ 29.720149137931035, -1.489319138461539 ], [ 29.720149137931035, -1.489049661538461 ], [ 29.719610172413795, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1841, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.489049661538461 ], [ 29.720149137931035, -1.489319138461539 ], [ 29.720688103448275, -1.489319138461539 ], [ 29.720688103448275, -1.489049661538461 ], [ 29.720149137931035, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1842, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.489049661538461 ], [ 29.720688103448275, -1.489319138461539 ], [ 29.720957586206897, -1.489319138461539 ], [ 29.720957586206897, -1.489049661538461 ], [ 29.720688103448275, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1843, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.489049661538461 ], [ 29.720957586206897, -1.489319138461539 ], [ 29.721227068965518, -1.489319138461539 ], [ 29.721227068965518, -1.489049661538461 ], [ 29.720957586206897, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1844, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.489049661538461 ], [ 29.721227068965518, -1.489319138461539 ], [ 29.721496551724137, -1.489319138461539 ], [ 29.721496551724137, -1.489049661538461 ], [ 29.721227068965518, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1845, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.489049661538461 ], [ 29.721496551724137, -1.489319138461539 ], [ 29.721766034482759, -1.489319138461539 ], [ 29.721766034482759, -1.489049661538461 ], [ 29.721496551724137, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1846, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.489049661538461 ], [ 29.721766034482759, -1.489319138461539 ], [ 29.72203551724138, -1.489319138461539 ], [ 29.72203551724138, -1.489049661538461 ], [ 29.721766034482759, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1847, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.489049661538461 ], [ 29.72203551724138, -1.489319138461539 ], [ 29.722305, -1.489319138461539 ], [ 29.722305, -1.489049661538461 ], [ 29.72203551724138, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1848, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.489049661538461 ], [ 29.722305, -1.489319138461539 ], [ 29.72257448275862, -1.489319138461539 ], [ 29.72257448275862, -1.489049661538461 ], [ 29.722305, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1849, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.489049661538461 ], [ 29.72257448275862, -1.489319138461539 ], [ 29.722843965517242, -1.489319138461539 ], [ 29.722843965517242, -1.489049661538461 ], [ 29.72257448275862, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1850, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.489049661538461 ], [ 29.722843965517242, -1.489319138461539 ], [ 29.723113448275861, -1.489319138461539 ], [ 29.723113448275861, -1.489049661538461 ], [ 29.722843965517242, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1851, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.489049661538461 ], [ 29.723113448275861, -1.489319138461539 ], [ 29.723382931034482, -1.489319138461539 ], [ 29.723382931034482, -1.489049661538461 ], [ 29.723113448275861, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1852, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.489049661538461 ], [ 29.723382931034482, -1.489319138461539 ], [ 29.723921896551722, -1.489319138461539 ], [ 29.723921896551722, -1.489049661538461 ], [ 29.723382931034482, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1853, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.489049661538461 ], [ 29.723921896551722, -1.489319138461539 ], [ 29.724730344827588, -1.489319138461539 ], [ 29.724730344827588, -1.489049661538461 ], [ 29.723921896551722, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1854, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.489049661538461 ], [ 29.724730344827588, -1.489319138461539 ], [ 29.724999827586206, -1.489319138461539 ], [ 29.724999827586206, -1.489049661538461 ], [ 29.724730344827588, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1855, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.489049661538461 ], [ 29.724999827586206, -1.489319138461539 ], [ 29.72553879310345, -1.489319138461539 ], [ 29.72553879310345, -1.489049661538461 ], [ 29.724999827586206, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1856, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.489049661538461 ], [ 29.72553879310345, -1.489319138461539 ], [ 29.725808275862068, -1.489319138461539 ], [ 29.725808275862068, -1.489049661538461 ], [ 29.72553879310345, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1857, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.489049661538461 ], [ 29.725808275862068, -1.489319138461539 ], [ 29.72607775862069, -1.489319138461539 ], [ 29.72607775862069, -1.489049661538461 ], [ 29.725808275862068, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1858, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.489049661538461 ], [ 29.72607775862069, -1.489319138461539 ], [ 29.726347241379312, -1.489319138461539 ], [ 29.726347241379312, -1.489049661538461 ], [ 29.72607775862069, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1859, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.489049661538461 ], [ 29.726347241379312, -1.489319138461539 ], [ 29.72661672413793, -1.489319138461539 ], [ 29.72661672413793, -1.489049661538461 ], [ 29.726347241379312, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1860, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.489049661538461 ], [ 29.72661672413793, -1.489319138461539 ], [ 29.726886206896552, -1.489319138461539 ], [ 29.726886206896552, -1.489049661538461 ], [ 29.72661672413793, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1861, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.489049661538461 ], [ 29.726886206896552, -1.489319138461539 ], [ 29.727155689655174, -1.489319138461539 ], [ 29.727155689655174, -1.489049661538461 ], [ 29.726886206896552, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1862, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.489049661538461 ], [ 29.727155689655174, -1.489319138461539 ], [ 29.727425172413792, -1.489319138461539 ], [ 29.727425172413792, -1.489049661538461 ], [ 29.727155689655174, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1863, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.489049661538461 ], [ 29.727425172413792, -1.489319138461539 ], [ 29.727694655172414, -1.489319138461539 ], [ 29.727694655172414, -1.489049661538461 ], [ 29.727425172413792, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1864, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.489049661538461 ], [ 29.727694655172414, -1.489319138461539 ], [ 29.727964137931036, -1.489319138461539 ], [ 29.727964137931036, -1.489049661538461 ], [ 29.727694655172414, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1865, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.489049661538461 ], [ 29.727964137931036, -1.489319138461539 ], [ 29.729311551724138, -1.489319138461539 ], [ 29.729311551724138, -1.489049661538461 ], [ 29.727964137931036, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1866, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.489049661538461 ], [ 29.729311551724138, -1.489319138461539 ], [ 29.729850517241381, -1.489319138461539 ], [ 29.729850517241381, -1.489049661538461 ], [ 29.729311551724138, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1867, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.489049661538461 ], [ 29.729850517241381, -1.489319138461539 ], [ 29.73012, -1.489319138461539 ], [ 29.73012, -1.489049661538461 ], [ 29.729850517241381, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1868, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.489049661538461 ], [ 29.73012, -1.489319138461539 ], [ 29.730389482758621, -1.489319138461539 ], [ 29.730389482758621, -1.489049661538461 ], [ 29.73012, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1869, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.489049661538461 ], [ 29.730389482758621, -1.489319138461539 ], [ 29.730658965517243, -1.489319138461539 ], [ 29.730658965517243, -1.489049661538461 ], [ 29.730389482758621, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1870, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.489049661538461 ], [ 29.730658965517243, -1.489319138461539 ], [ 29.730928448275861, -1.489319138461539 ], [ 29.730928448275861, -1.489049661538461 ], [ 29.730658965517243, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1871, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.489049661538461 ], [ 29.730928448275861, -1.489319138461539 ], [ 29.731197931034483, -1.489319138461539 ], [ 29.731197931034483, -1.489049661538461 ], [ 29.730928448275861, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1872, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.489049661538461 ], [ 29.731197931034483, -1.489319138461539 ], [ 29.731467413793105, -1.489319138461539 ], [ 29.731467413793105, -1.489049661538461 ], [ 29.731197931034483, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1873, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.489049661538461 ], [ 29.731467413793105, -1.489319138461539 ], [ 29.731736896551723, -1.489319138461539 ], [ 29.731736896551723, -1.489049661538461 ], [ 29.731467413793105, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1874, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.489049661538461 ], [ 29.731736896551723, -1.489319138461539 ], [ 29.732006379310345, -1.489319138461539 ], [ 29.732006379310345, -1.489049661538461 ], [ 29.731736896551723, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1875, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.489049661538461 ], [ 29.732006379310345, -1.489319138461539 ], [ 29.732275862068967, -1.489319138461539 ], [ 29.732275862068967, -1.489049661538461 ], [ 29.732006379310345, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1876, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.489049661538461 ], [ 29.732275862068967, -1.489319138461539 ], [ 29.732545344827585, -1.489319138461539 ], [ 29.732545344827585, -1.489049661538461 ], [ 29.732275862068967, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1877, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.489049661538461 ], [ 29.732545344827585, -1.489319138461539 ], [ 29.732814827586207, -1.489319138461539 ], [ 29.732814827586207, -1.489049661538461 ], [ 29.732545344827585, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1878, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.489049661538461 ], [ 29.733084310344829, -1.489858092307692 ], [ 29.733353793103447, -1.489858092307692 ], [ 29.733353793103447, -1.489049661538461 ], [ 29.733084310344829, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1879, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.489049661538461 ], [ 29.733353793103447, -1.489319138461539 ], [ 29.733623275862069, -1.489319138461539 ], [ 29.733623275862069, -1.489049661538461 ], [ 29.733353793103447, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1880, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.489049661538461 ], [ 29.733623275862069, -1.489858092307692 ], [ 29.733892758620691, -1.489858092307692 ], [ 29.733892758620691, -1.489049661538461 ], [ 29.733623275862069, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1881, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.489049661538461 ], [ 29.733892758620691, -1.489319138461539 ], [ 29.734162241379309, -1.489319138461539 ], [ 29.734162241379309, -1.489049661538461 ], [ 29.733892758620691, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1882, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.489049661538461 ], [ 29.734701206896553, -1.489319138461539 ], [ 29.734970689655171, -1.489319138461539 ], [ 29.734970689655171, -1.489049661538461 ], [ 29.734701206896553, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1883, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.489049661538461 ], [ 29.734970689655171, -1.489319138461539 ], [ 29.735240172413793, -1.489319138461539 ], [ 29.735240172413793, -1.489049661538461 ], [ 29.734970689655171, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1884, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.489049661538461 ], [ 29.735240172413793, -1.489319138461539 ], [ 29.735509655172415, -1.489319138461539 ], [ 29.735509655172415, -1.489049661538461 ], [ 29.735240172413793, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1885, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.489049661538461 ], [ 29.735509655172415, -1.489319138461539 ], [ 29.735779137931036, -1.489319138461539 ], [ 29.735779137931036, -1.489049661538461 ], [ 29.735509655172415, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1886, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.489049661538461 ], [ 29.735779137931036, -1.489319138461539 ], [ 29.736048620689655, -1.489319138461539 ], [ 29.736048620689655, -1.489049661538461 ], [ 29.735779137931036, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1887, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.489049661538461 ], [ 29.736048620689655, -1.489319138461539 ], [ 29.736318103448276, -1.489319138461539 ], [ 29.736318103448276, -1.489049661538461 ], [ 29.736048620689655, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1888, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.489049661538461 ], [ 29.736318103448276, -1.489319138461539 ], [ 29.736587586206898, -1.489319138461539 ], [ 29.736587586206898, -1.489049661538461 ], [ 29.736318103448276, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1889, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.489049661538461 ], [ 29.736587586206898, -1.489319138461539 ], [ 29.736857068965517, -1.489319138461539 ], [ 29.736857068965517, -1.489049661538461 ], [ 29.736587586206898, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1890, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.489049661538461 ], [ 29.736857068965517, -1.489319138461539 ], [ 29.737126551724138, -1.489319138461539 ], [ 29.737126551724138, -1.489049661538461 ], [ 29.736857068965517, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1891, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.489049661538461 ], [ 29.737126551724138, -1.489319138461539 ], [ 29.73739603448276, -1.489319138461539 ], [ 29.73739603448276, -1.489049661538461 ], [ 29.737126551724138, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1892, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.489049661538461 ], [ 29.73739603448276, -1.489319138461539 ], [ 29.737665517241378, -1.489319138461539 ], [ 29.737665517241378, -1.489049661538461 ], [ 29.73739603448276, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1893, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.489049661538461 ], [ 29.737665517241378, -1.489319138461539 ], [ 29.737935, -1.489319138461539 ], [ 29.737935, -1.489049661538461 ], [ 29.737665517241378, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1894, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.489049661538461 ], [ 29.737935, -1.489319138461539 ], [ 29.738204482758622, -1.489319138461539 ], [ 29.738204482758622, -1.489049661538461 ], [ 29.737935, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1895, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.489049661538461 ], [ 29.738204482758622, -1.489319138461539 ], [ 29.738743448275862, -1.489319138461539 ], [ 29.738743448275862, -1.489049661538461 ], [ 29.738204482758622, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1896, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.489049661538461 ], [ 29.738743448275862, -1.489319138461539 ], [ 29.739012931034484, -1.489319138461539 ], [ 29.739012931034484, -1.489049661538461 ], [ 29.738743448275862, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1897, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.489049661538461 ], [ 29.739012931034484, -1.489319138461539 ], [ 29.739282413793102, -1.489319138461539 ], [ 29.739282413793102, -1.489049661538461 ], [ 29.739012931034484, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1898, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.489049661538461 ], [ 29.739282413793102, -1.489319138461539 ], [ 29.739551896551724, -1.489319138461539 ], [ 29.739551896551724, -1.489049661538461 ], [ 29.739282413793102, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1899, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.489049661538461 ], [ 29.739551896551724, -1.489319138461539 ], [ 29.739821379310346, -1.489319138461539 ], [ 29.739821379310346, -1.489049661538461 ], [ 29.739551896551724, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1900, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.489049661538461 ], [ 29.739821379310346, -1.489319138461539 ], [ 29.740090862068964, -1.489319138461539 ], [ 29.740090862068964, -1.489049661538461 ], [ 29.739821379310346, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1901, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.489049661538461 ], [ 29.740090862068964, -1.489319138461539 ], [ 29.740629827586208, -1.489319138461539 ], [ 29.740629827586208, -1.489049661538461 ], [ 29.740090862068964, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1902, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.489049661538461 ], [ 29.740629827586208, -1.489319138461539 ], [ 29.742246724137932, -1.489319138461539 ], [ 29.742246724137932, -1.489049661538461 ], [ 29.740629827586208, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1903, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.489049661538461 ], [ 29.742246724137932, -1.489319138461539 ], [ 29.742785689655172, -1.489319138461539 ], [ 29.742785689655172, -1.489049661538461 ], [ 29.742246724137932, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1904, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.489049661538461 ], [ 29.742785689655172, -1.489319138461539 ], [ 29.743324655172415, -1.489319138461539 ], [ 29.743324655172415, -1.489049661538461 ], [ 29.742785689655172, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1905, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.489049661538461 ], [ 29.743324655172415, -1.489319138461539 ], [ 29.743594137931034, -1.489319138461539 ], [ 29.743594137931034, -1.489049661538461 ], [ 29.743324655172415, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1906, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.489049661538461 ], [ 29.743594137931034, -1.489319138461539 ], [ 29.744402586206895, -1.489319138461539 ], [ 29.744402586206895, -1.489049661538461 ], [ 29.743594137931034, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1907, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.489049661538461 ], [ 29.744402586206895, -1.489319138461539 ], [ 29.744941551724139, -1.489319138461539 ], [ 29.744941551724139, -1.489049661538461 ], [ 29.744402586206895, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1908, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.489049661538461 ], [ 29.744941551724139, -1.489319138461539 ], [ 29.745480517241379, -1.489319138461539 ], [ 29.745480517241379, -1.489049661538461 ], [ 29.744941551724139, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1909, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.489049661538461 ], [ 29.745480517241379, -1.489319138461539 ], [ 29.74575, -1.489319138461539 ], [ 29.74575, -1.489049661538461 ], [ 29.745480517241379, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1910, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.489049661538461 ], [ 29.74575, -1.489319138461539 ], [ 29.746288965517241, -1.489319138461539 ], [ 29.746288965517241, -1.489049661538461 ], [ 29.74575, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1911, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.489049661538461 ], [ 29.746288965517241, -1.489319138461539 ], [ 29.748444827586209, -1.489319138461539 ], [ 29.748444827586209, -1.489049661538461 ], [ 29.746288965517241, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1912, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.489049661538461 ], [ 29.748444827586209, -1.489319138461539 ], [ 29.748983793103449, -1.489319138461539 ], [ 29.748983793103449, -1.489049661538461 ], [ 29.748444827586209, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1913, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.489049661538461 ], [ 29.748983793103449, -1.489319138461539 ], [ 29.74925327586207, -1.489319138461539 ], [ 29.74925327586207, -1.489049661538461 ], [ 29.748983793103449, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1914, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.489049661538461 ], [ 29.74925327586207, -1.489319138461539 ], [ 29.749522758620689, -1.489319138461539 ], [ 29.749522758620689, -1.489049661538461 ], [ 29.74925327586207, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1915, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.489049661538461 ], [ 29.749522758620689, -1.489319138461539 ], [ 29.749792241379311, -1.489319138461539 ], [ 29.749792241379311, -1.489049661538461 ], [ 29.749522758620689, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1916, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.489049661538461 ], [ 29.749792241379311, -1.489319138461539 ], [ 29.750061724137932, -1.489319138461539 ], [ 29.750061724137932, -1.489049661538461 ], [ 29.749792241379311, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1917, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.489049661538461 ], [ 29.750061724137932, -1.489319138461539 ], [ 29.750331206896551, -1.489319138461539 ], [ 29.750331206896551, -1.489049661538461 ], [ 29.750061724137932, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1918, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.489049661538461 ], [ 29.750331206896551, -1.489319138461539 ], [ 29.750600689655172, -1.489319138461539 ], [ 29.750600689655172, -1.489049661538461 ], [ 29.750331206896551, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1919, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.489049661538461 ], [ 29.750600689655172, -1.489319138461539 ], [ 29.750870172413794, -1.489319138461539 ], [ 29.750870172413794, -1.489049661538461 ], [ 29.750600689655172, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1920, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.489049661538461 ], [ 29.750870172413794, -1.489319138461539 ], [ 29.751139655172413, -1.489319138461539 ], [ 29.751139655172413, -1.489049661538461 ], [ 29.750870172413794, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1921, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.489049661538461 ], [ 29.751678620689656, -1.489319138461539 ], [ 29.751948103448278, -1.489319138461539 ], [ 29.751948103448278, -1.489049661538461 ], [ 29.751678620689656, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1922, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.489049661538461 ], [ 29.751948103448278, -1.489319138461539 ], [ 29.752217586206896, -1.489319138461539 ], [ 29.752217586206896, -1.489049661538461 ], [ 29.751948103448278, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1923, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.489049661538461 ], [ 29.752217586206896, -1.489319138461539 ], [ 29.752487068965518, -1.489319138461539 ], [ 29.752487068965518, -1.489049661538461 ], [ 29.752217586206896, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1924, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.489049661538461 ], [ 29.752487068965518, -1.489319138461539 ], [ 29.75275655172414, -1.489319138461539 ], [ 29.75275655172414, -1.489049661538461 ], [ 29.752487068965518, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1925, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.489049661538461 ], [ 29.75275655172414, -1.489319138461539 ], [ 29.753026034482758, -1.489319138461539 ], [ 29.753026034482758, -1.489049661538461 ], [ 29.75275655172414, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1926, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.489049661538461 ], [ 29.753026034482758, -1.489319138461539 ], [ 29.75329551724138, -1.489319138461539 ], [ 29.75329551724138, -1.489049661538461 ], [ 29.753026034482758, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1927, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.489049661538461 ], [ 29.75329551724138, -1.489319138461539 ], [ 29.753565, -1.489319138461539 ], [ 29.753565, -1.489049661538461 ], [ 29.75329551724138, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1928, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.489049661538461 ], [ 29.753565, -1.489319138461539 ], [ 29.75383448275862, -1.489319138461539 ], [ 29.75383448275862, -1.489049661538461 ], [ 29.753565, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1929, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.489049661538461 ], [ 29.75383448275862, -1.489319138461539 ], [ 29.754103965517242, -1.489319138461539 ], [ 29.754103965517242, -1.489049661538461 ], [ 29.75383448275862, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1930, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.489049661538461 ], [ 29.754103965517242, -1.489319138461539 ], [ 29.754373448275864, -1.489319138461539 ], [ 29.754373448275864, -1.489049661538461 ], [ 29.754103965517242, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1931, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.489049661538461 ], [ 29.754373448275864, -1.489319138461539 ], [ 29.754642931034482, -1.489319138461539 ], [ 29.754642931034482, -1.489049661538461 ], [ 29.754373448275864, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1932, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.489049661538461 ], [ 29.754642931034482, -1.489319138461539 ], [ 29.754912413793104, -1.489319138461539 ], [ 29.754912413793104, -1.489049661538461 ], [ 29.754642931034482, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1933, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.489049661538461 ], [ 29.754912413793104, -1.489319138461539 ], [ 29.755181896551726, -1.489319138461539 ], [ 29.755181896551726, -1.489049661538461 ], [ 29.754912413793104, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1934, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.489049661538461 ], [ 29.755181896551726, -1.489319138461539 ], [ 29.755451379310344, -1.489319138461539 ], [ 29.755451379310344, -1.489049661538461 ], [ 29.755181896551726, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1935, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.489319138461539 ], [ 29.718262758620689, -1.489858092307692 ], [ 29.718532241379311, -1.489858092307692 ], [ 29.718532241379311, -1.489319138461539 ], [ 29.718262758620689, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1936, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.489319138461539 ], [ 29.718532241379311, -1.489858092307692 ], [ 29.718801724137933, -1.489858092307692 ], [ 29.718801724137933, -1.489319138461539 ], [ 29.718532241379311, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1937, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.489319138461539 ], [ 29.718801724137933, -1.489858092307692 ], [ 29.719340689655173, -1.489858092307692 ], [ 29.719340689655173, -1.489319138461539 ], [ 29.718801724137933, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1938, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.489319138461539 ], [ 29.719340689655173, -1.489858092307692 ], [ 29.719610172413795, -1.489858092307692 ], [ 29.719610172413795, -1.489319138461539 ], [ 29.719340689655173, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1939, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.489319138461539 ], [ 29.719610172413795, -1.489858092307692 ], [ 29.719879655172413, -1.489858092307692 ], [ 29.719879655172413, -1.489319138461539 ], [ 29.719610172413795, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1940, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.489319138461539 ], [ 29.719879655172413, -1.489858092307692 ], [ 29.720688103448275, -1.489858092307692 ], [ 29.720688103448275, -1.489319138461539 ], [ 29.719879655172413, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1941, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.489319138461539 ], [ 29.720688103448275, -1.489858092307692 ], [ 29.720957586206897, -1.489858092307692 ], [ 29.720957586206897, -1.489319138461539 ], [ 29.720688103448275, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1942, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.489319138461539 ], [ 29.720957586206897, -1.489858092307692 ], [ 29.721227068965518, -1.489858092307692 ], [ 29.721227068965518, -1.489319138461539 ], [ 29.720957586206897, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1943, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.489319138461539 ], [ 29.721227068965518, -1.489858092307692 ], [ 29.721496551724137, -1.489858092307692 ], [ 29.721496551724137, -1.489319138461539 ], [ 29.721227068965518, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1944, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.489319138461539 ], [ 29.721496551724137, -1.489858092307692 ], [ 29.721766034482759, -1.489858092307692 ], [ 29.721766034482759, -1.489319138461539 ], [ 29.721496551724137, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1945, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.489319138461539 ], [ 29.721766034482759, -1.489858092307692 ], [ 29.72203551724138, -1.489858092307692 ], [ 29.72203551724138, -1.489319138461539 ], [ 29.721766034482759, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1946, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.489319138461539 ], [ 29.72203551724138, -1.489858092307692 ], [ 29.722305, -1.489858092307692 ], [ 29.722305, -1.489319138461539 ], [ 29.72203551724138, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1947, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.489319138461539 ], [ 29.722305, -1.489858092307692 ], [ 29.72257448275862, -1.489858092307692 ], [ 29.72257448275862, -1.489319138461539 ], [ 29.722305, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1948, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.489319138461539 ], [ 29.72257448275862, -1.489858092307692 ], [ 29.722843965517242, -1.489858092307692 ], [ 29.722843965517242, -1.489319138461539 ], [ 29.72257448275862, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1949, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.489319138461539 ], [ 29.722843965517242, -1.489858092307692 ], [ 29.723113448275861, -1.489858092307692 ], [ 29.723113448275861, -1.489319138461539 ], [ 29.722843965517242, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1950, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.489319138461539 ], [ 29.723113448275861, -1.489858092307692 ], [ 29.723382931034482, -1.489858092307692 ], [ 29.723382931034482, -1.489319138461539 ], [ 29.723113448275861, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1951, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.489319138461539 ], [ 29.723382931034482, -1.489858092307692 ], [ 29.723652413793104, -1.489858092307692 ], [ 29.723652413793104, -1.489319138461539 ], [ 29.723382931034482, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1952, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.489319138461539 ], [ 29.723652413793104, -1.489858092307692 ], [ 29.724191379310344, -1.489858092307692 ], [ 29.724191379310344, -1.489319138461539 ], [ 29.723652413793104, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1953, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.489319138461539 ], [ 29.724191379310344, -1.489858092307692 ], [ 29.724730344827588, -1.489858092307692 ], [ 29.724730344827588, -1.489319138461539 ], [ 29.724191379310344, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1954, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.489319138461539 ], [ 29.724730344827588, -1.489858092307692 ], [ 29.725269310344828, -1.489858092307692 ], [ 29.725269310344828, -1.489319138461539 ], [ 29.724730344827588, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1955, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.489319138461539 ], [ 29.725269310344828, -1.489858092307692 ], [ 29.72553879310345, -1.489858092307692 ], [ 29.72553879310345, -1.489319138461539 ], [ 29.725269310344828, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1956, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.489319138461539 ], [ 29.72553879310345, -1.489858092307692 ], [ 29.725808275862068, -1.489858092307692 ], [ 29.725808275862068, -1.489319138461539 ], [ 29.72553879310345, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1957, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.489319138461539 ], [ 29.725808275862068, -1.489858092307692 ], [ 29.72607775862069, -1.489858092307692 ], [ 29.72607775862069, -1.489319138461539 ], [ 29.725808275862068, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1958, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.489319138461539 ], [ 29.72607775862069, -1.489858092307692 ], [ 29.726347241379312, -1.489858092307692 ], [ 29.726347241379312, -1.489319138461539 ], [ 29.72607775862069, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1959, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.489319138461539 ], [ 29.726347241379312, -1.489858092307692 ], [ 29.72661672413793, -1.489858092307692 ], [ 29.72661672413793, -1.489319138461539 ], [ 29.726347241379312, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1960, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.489319138461539 ], [ 29.72661672413793, -1.489858092307692 ], [ 29.726886206896552, -1.489858092307692 ], [ 29.726886206896552, -1.489319138461539 ], [ 29.72661672413793, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1961, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.489319138461539 ], [ 29.726886206896552, -1.489858092307692 ], [ 29.727155689655174, -1.489858092307692 ], [ 29.727155689655174, -1.489319138461539 ], [ 29.726886206896552, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1962, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.489319138461539 ], [ 29.727155689655174, -1.489858092307692 ], [ 29.727425172413792, -1.489858092307692 ], [ 29.727425172413792, -1.489319138461539 ], [ 29.727155689655174, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1963, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.489319138461539 ], [ 29.727425172413792, -1.489858092307692 ], [ 29.727694655172414, -1.489858092307692 ], [ 29.727694655172414, -1.489319138461539 ], [ 29.727425172413792, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1964, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.489319138461539 ], [ 29.727694655172414, -1.489858092307692 ], [ 29.727964137931036, -1.489858092307692 ], [ 29.727964137931036, -1.489319138461539 ], [ 29.727694655172414, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1965, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.489319138461539 ], [ 29.727964137931036, -1.489858092307692 ], [ 29.729311551724138, -1.489858092307692 ], [ 29.729311551724138, -1.489319138461539 ], [ 29.727964137931036, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1966, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.489319138461539 ], [ 29.729311551724138, -1.489858092307692 ], [ 29.729850517241381, -1.489858092307692 ], [ 29.729850517241381, -1.489319138461539 ], [ 29.729311551724138, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1967, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.489319138461539 ], [ 29.729850517241381, -1.489858092307692 ], [ 29.73012, -1.489858092307692 ], [ 29.73012, -1.489319138461539 ], [ 29.729850517241381, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1968, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.489319138461539 ], [ 29.73012, -1.489858092307692 ], [ 29.730389482758621, -1.489858092307692 ], [ 29.730389482758621, -1.489319138461539 ], [ 29.73012, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1969, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.489319138461539 ], [ 29.730389482758621, -1.489858092307692 ], [ 29.730658965517243, -1.489858092307692 ], [ 29.730658965517243, -1.489319138461539 ], [ 29.730389482758621, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1970, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.489319138461539 ], [ 29.730658965517243, -1.489858092307692 ], [ 29.730928448275861, -1.489858092307692 ], [ 29.730928448275861, -1.489319138461539 ], [ 29.730658965517243, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1971, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.489319138461539 ], [ 29.730928448275861, -1.489858092307692 ], [ 29.731197931034483, -1.489858092307692 ], [ 29.731197931034483, -1.489319138461539 ], [ 29.730928448275861, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1972, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.489319138461539 ], [ 29.731197931034483, -1.489858092307692 ], [ 29.731467413793105, -1.489858092307692 ], [ 29.731467413793105, -1.489319138461539 ], [ 29.731197931034483, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1973, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.489319138461539 ], [ 29.731467413793105, -1.489858092307692 ], [ 29.731736896551723, -1.489858092307692 ], [ 29.731736896551723, -1.489319138461539 ], [ 29.731467413793105, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1974, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.489319138461539 ], [ 29.731736896551723, -1.489858092307692 ], [ 29.732006379310345, -1.489858092307692 ], [ 29.732006379310345, -1.489319138461539 ], [ 29.731736896551723, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1975, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.489319138461539 ], [ 29.732006379310345, -1.489858092307692 ], [ 29.732275862068967, -1.489858092307692 ], [ 29.732275862068967, -1.489319138461539 ], [ 29.732006379310345, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1976, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.489319138461539 ], [ 29.732275862068967, -1.489858092307692 ], [ 29.732545344827585, -1.489858092307692 ], [ 29.732545344827585, -1.489319138461539 ], [ 29.732275862068967, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1977, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.489319138461539 ], [ 29.732545344827585, -1.489858092307692 ], [ 29.732814827586207, -1.489858092307692 ], [ 29.732814827586207, -1.489319138461539 ], [ 29.732545344827585, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1978, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.489319138461539 ], [ 29.733353793103447, -1.489858092307692 ], [ 29.733623275862069, -1.489858092307692 ], [ 29.733623275862069, -1.489319138461539 ], [ 29.733353793103447, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1979, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.489319138461539 ], [ 29.733892758620691, -1.489858092307692 ], [ 29.734162241379309, -1.489858092307692 ], [ 29.734162241379309, -1.489319138461539 ], [ 29.733892758620691, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1980, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.489319138461539 ], [ 29.734162241379309, -1.489858092307692 ], [ 29.734431724137931, -1.489858092307692 ], [ 29.734431724137931, -1.489319138461539 ], [ 29.734162241379309, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1981, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.489319138461539 ], [ 29.734431724137931, -1.489858092307692 ], [ 29.734701206896553, -1.489858092307692 ], [ 29.734701206896553, -1.489319138461539 ], [ 29.734431724137931, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1982, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.489319138461539 ], [ 29.734701206896553, -1.489858092307692 ], [ 29.734970689655171, -1.489858092307692 ], [ 29.734970689655171, -1.489319138461539 ], [ 29.734701206896553, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1983, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.489319138461539 ], [ 29.734970689655171, -1.489858092307692 ], [ 29.735240172413793, -1.489858092307692 ], [ 29.735240172413793, -1.489319138461539 ], [ 29.734970689655171, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1984, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.489319138461539 ], [ 29.735240172413793, -1.489858092307692 ], [ 29.735509655172415, -1.489858092307692 ], [ 29.735509655172415, -1.489319138461539 ], [ 29.735240172413793, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1985, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.489319138461539 ], [ 29.735509655172415, -1.489858092307692 ], [ 29.735779137931036, -1.489858092307692 ], [ 29.735779137931036, -1.489319138461539 ], [ 29.735509655172415, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1986, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.489319138461539 ], [ 29.735779137931036, -1.489858092307692 ], [ 29.736048620689655, -1.489858092307692 ], [ 29.736048620689655, -1.489319138461539 ], [ 29.735779137931036, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1987, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.489319138461539 ], [ 29.736048620689655, -1.489858092307692 ], [ 29.736587586206898, -1.489858092307692 ], [ 29.736587586206898, -1.489319138461539 ], [ 29.736048620689655, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1988, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.489319138461539 ], [ 29.736587586206898, -1.489858092307692 ], [ 29.736857068965517, -1.489858092307692 ], [ 29.736857068965517, -1.489319138461539 ], [ 29.736587586206898, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1989, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.489319138461539 ], [ 29.736857068965517, -1.489858092307692 ], [ 29.737126551724138, -1.489858092307692 ], [ 29.737126551724138, -1.489319138461539 ], [ 29.736857068965517, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1990, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.489319138461539 ], [ 29.737126551724138, -1.489858092307692 ], [ 29.73739603448276, -1.489858092307692 ], [ 29.73739603448276, -1.489319138461539 ], [ 29.737126551724138, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1991, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.489319138461539 ], [ 29.73739603448276, -1.489858092307692 ], [ 29.737665517241378, -1.489858092307692 ], [ 29.737665517241378, -1.489319138461539 ], [ 29.73739603448276, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1992, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.489319138461539 ], [ 29.737665517241378, -1.489858092307692 ], [ 29.737935, -1.489858092307692 ], [ 29.737935, -1.489319138461539 ], [ 29.737665517241378, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1993, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.489319138461539 ], [ 29.737935, -1.489858092307692 ], [ 29.738204482758622, -1.489858092307692 ], [ 29.738204482758622, -1.489319138461539 ], [ 29.737935, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1994, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.489319138461539 ], [ 29.738204482758622, -1.489858092307692 ], [ 29.738743448275862, -1.489858092307692 ], [ 29.738743448275862, -1.489319138461539 ], [ 29.738204482758622, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1995, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.489319138461539 ], [ 29.738743448275862, -1.489858092307692 ], [ 29.739012931034484, -1.489858092307692 ], [ 29.739012931034484, -1.489319138461539 ], [ 29.738743448275862, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1996, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.489319138461539 ], [ 29.739012931034484, -1.489858092307692 ], [ 29.739282413793102, -1.489858092307692 ], [ 29.739282413793102, -1.489319138461539 ], [ 29.739012931034484, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1997, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.489319138461539 ], [ 29.739282413793102, -1.489858092307692 ], [ 29.739821379310346, -1.489858092307692 ], [ 29.739821379310346, -1.489319138461539 ], [ 29.739282413793102, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1998, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.489319138461539 ], [ 29.739821379310346, -1.489858092307692 ], [ 29.740090862068964, -1.489858092307692 ], [ 29.740090862068964, -1.489319138461539 ], [ 29.739821379310346, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1999, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.489319138461539 ], [ 29.740090862068964, -1.489858092307692 ], [ 29.740629827586208, -1.489858092307692 ], [ 29.740629827586208, -1.489319138461539 ], [ 29.740090862068964, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2000, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.489319138461539 ], [ 29.740629827586208, -1.489858092307692 ], [ 29.742246724137932, -1.489858092307692 ], [ 29.742246724137932, -1.489319138461539 ], [ 29.740629827586208, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2001, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.489319138461539 ], [ 29.742246724137932, -1.489858092307692 ], [ 29.742516206896553, -1.489858092307692 ], [ 29.742516206896553, -1.489319138461539 ], [ 29.742246724137932, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2002, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.489319138461539 ], [ 29.742516206896553, -1.489858092307692 ], [ 29.743055172413793, -1.489858092307692 ], [ 29.743055172413793, -1.489319138461539 ], [ 29.742516206896553, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2003, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.489319138461539 ], [ 29.743055172413793, -1.489858092307692 ], [ 29.743594137931034, -1.489858092307692 ], [ 29.743594137931034, -1.489319138461539 ], [ 29.743055172413793, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2004, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.489319138461539 ], [ 29.743594137931034, -1.489858092307692 ], [ 29.744133103448277, -1.489858092307692 ], [ 29.744133103448277, -1.489319138461539 ], [ 29.743594137931034, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2005, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.489319138461539 ], [ 29.744133103448277, -1.489858092307692 ], [ 29.744672068965517, -1.489858092307692 ], [ 29.744672068965517, -1.489319138461539 ], [ 29.744133103448277, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2006, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.489319138461539 ], [ 29.744672068965517, -1.489858092307692 ], [ 29.745211034482757, -1.489858092307692 ], [ 29.745211034482757, -1.489319138461539 ], [ 29.744672068965517, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2007, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.489319138461539 ], [ 29.745211034482757, -1.489858092307692 ], [ 29.74575, -1.489858092307692 ], [ 29.74575, -1.489319138461539 ], [ 29.745211034482757, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2008, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.489319138461539 ], [ 29.74575, -1.489858092307692 ], [ 29.746019482758619, -1.489858092307692 ], [ 29.746019482758619, -1.489319138461539 ], [ 29.74575, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2009, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.489319138461539 ], [ 29.746019482758619, -1.489858092307692 ], [ 29.748714310344827, -1.489858092307692 ], [ 29.748714310344827, -1.489319138461539 ], [ 29.746019482758619, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2010, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.489319138461539 ], [ 29.748714310344827, -1.489858092307692 ], [ 29.748983793103449, -1.489858092307692 ], [ 29.748983793103449, -1.489319138461539 ], [ 29.748714310344827, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2011, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.489319138461539 ], [ 29.748983793103449, -1.489858092307692 ], [ 29.74925327586207, -1.489858092307692 ], [ 29.74925327586207, -1.489319138461539 ], [ 29.748983793103449, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2012, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.489319138461539 ], [ 29.74925327586207, -1.489858092307692 ], [ 29.749522758620689, -1.489858092307692 ], [ 29.749522758620689, -1.489319138461539 ], [ 29.74925327586207, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2013, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.489319138461539 ], [ 29.749522758620689, -1.489858092307692 ], [ 29.749792241379311, -1.489858092307692 ], [ 29.749792241379311, -1.489319138461539 ], [ 29.749522758620689, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2014, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.489319138461539 ], [ 29.749792241379311, -1.489858092307692 ], [ 29.750061724137932, -1.489858092307692 ], [ 29.750061724137932, -1.489319138461539 ], [ 29.749792241379311, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2015, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.489319138461539 ], [ 29.750061724137932, -1.489858092307692 ], [ 29.750331206896551, -1.489858092307692 ], [ 29.750331206896551, -1.489319138461539 ], [ 29.750061724137932, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2016, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.489319138461539 ], [ 29.750331206896551, -1.489858092307692 ], [ 29.750600689655172, -1.489858092307692 ], [ 29.750600689655172, -1.489319138461539 ], [ 29.750331206896551, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2017, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.489319138461539 ], [ 29.750600689655172, -1.489858092307692 ], [ 29.750870172413794, -1.489858092307692 ], [ 29.750870172413794, -1.489319138461539 ], [ 29.750600689655172, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2018, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.489319138461539 ], [ 29.751409137931034, -1.489858092307692 ], [ 29.751678620689656, -1.489858092307692 ], [ 29.751678620689656, -1.489319138461539 ], [ 29.751409137931034, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2019, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.489319138461539 ], [ 29.751678620689656, -1.489858092307692 ], [ 29.751948103448278, -1.489858092307692 ], [ 29.751948103448278, -1.489319138461539 ], [ 29.751678620689656, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2020, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.489319138461539 ], [ 29.751948103448278, -1.489858092307692 ], [ 29.752217586206896, -1.489858092307692 ], [ 29.752217586206896, -1.489319138461539 ], [ 29.751948103448278, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2021, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.489319138461539 ], [ 29.752217586206896, -1.489858092307692 ], [ 29.752487068965518, -1.489858092307692 ], [ 29.752487068965518, -1.489319138461539 ], [ 29.752217586206896, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2022, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.489319138461539 ], [ 29.752487068965518, -1.489858092307692 ], [ 29.75275655172414, -1.489858092307692 ], [ 29.75275655172414, -1.489319138461539 ], [ 29.752487068965518, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2023, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.489319138461539 ], [ 29.75275655172414, -1.489858092307692 ], [ 29.753026034482758, -1.489858092307692 ], [ 29.753026034482758, -1.489319138461539 ], [ 29.75275655172414, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2024, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.489319138461539 ], [ 29.753026034482758, -1.489858092307692 ], [ 29.75329551724138, -1.489858092307692 ], [ 29.75329551724138, -1.489319138461539 ], [ 29.753026034482758, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2025, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.489319138461539 ], [ 29.75329551724138, -1.489858092307692 ], [ 29.753565, -1.489858092307692 ], [ 29.753565, -1.489319138461539 ], [ 29.75329551724138, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2026, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.489319138461539 ], [ 29.753565, -1.489858092307692 ], [ 29.75383448275862, -1.489858092307692 ], [ 29.75383448275862, -1.489319138461539 ], [ 29.753565, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2027, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.489319138461539 ], [ 29.75383448275862, -1.489858092307692 ], [ 29.754103965517242, -1.489858092307692 ], [ 29.754103965517242, -1.489319138461539 ], [ 29.75383448275862, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2028, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.489319138461539 ], [ 29.754103965517242, -1.489858092307692 ], [ 29.754373448275864, -1.489858092307692 ], [ 29.754373448275864, -1.489319138461539 ], [ 29.754103965517242, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2029, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.489319138461539 ], [ 29.754373448275864, -1.489858092307692 ], [ 29.754642931034482, -1.489858092307692 ], [ 29.754642931034482, -1.489319138461539 ], [ 29.754373448275864, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2030, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.489319138461539 ], [ 29.754642931034482, -1.489858092307692 ], [ 29.754912413793104, -1.489858092307692 ], [ 29.754912413793104, -1.489319138461539 ], [ 29.754642931034482, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2031, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.488780184615385 ], [ 29.755990344827588, -1.490127569230769 ], [ 29.756259827586206, -1.490127569230769 ], [ 29.756259827586206, -1.488780184615385 ], [ 29.755990344827588, -1.488780184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2032, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.489049661538461 ], [ 29.755451379310344, -1.490397046153846 ], [ 29.755720862068966, -1.490397046153846 ], [ 29.755720862068966, -1.489049661538461 ], [ 29.755451379310344, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2033, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.489049661538461 ], [ 29.755720862068966, -1.490397046153846 ], [ 29.755990344827588, -1.490397046153846 ], [ 29.755990344827588, -1.489049661538461 ], [ 29.755720862068966, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2034, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.489319138461539 ], [ 29.732814827586207, -1.490127569230769 ], [ 29.733084310344829, -1.490127569230769 ], [ 29.733084310344829, -1.489319138461539 ], [ 29.732814827586207, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2035, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.489049661538461 ], [ 29.751409137931034, -1.489049661538461 ], [ 29.751409137931034, -1.490127569230769 ], [ 29.751139655172413, -1.490127569230769 ], [ 29.751139655172413, -1.489858092307692 ], [ 29.750870172413794, -1.489858092307692 ], [ 29.750870172413794, -1.489319138461539 ], [ 29.751139655172413, -1.489319138461539 ], [ 29.751139655172413, -1.489049661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2036, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.489319138461539 ], [ 29.754912413793104, -1.490397046153846 ], [ 29.755181896551726, -1.490397046153846 ], [ 29.755181896551726, -1.489319138461539 ], [ 29.754912413793104, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2037, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.489319138461539 ], [ 29.755181896551726, -1.490127569230769 ], [ 29.755451379310344, -1.490127569230769 ], [ 29.755451379310344, -1.489319138461539 ], [ 29.755181896551726, -1.489319138461539 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2038, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.489858092307692 ], [ 29.717723793103449, -1.490127569230769 ], [ 29.718262758620689, -1.490127569230769 ], [ 29.718262758620689, -1.489858092307692 ], [ 29.717723793103449, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2039, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.489858092307692 ], [ 29.718262758620689, -1.490127569230769 ], [ 29.718532241379311, -1.490127569230769 ], [ 29.718532241379311, -1.489858092307692 ], [ 29.718262758620689, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2040, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.489858092307692 ], [ 29.718532241379311, -1.490127569230769 ], [ 29.718801724137933, -1.490127569230769 ], [ 29.718801724137933, -1.489858092307692 ], [ 29.718532241379311, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2041, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.489858092307692 ], [ 29.718801724137933, -1.490127569230769 ], [ 29.719340689655173, -1.490127569230769 ], [ 29.719340689655173, -1.489858092307692 ], [ 29.718801724137933, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2042, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.489858092307692 ], [ 29.719340689655173, -1.490127569230769 ], [ 29.719610172413795, -1.490127569230769 ], [ 29.719610172413795, -1.489858092307692 ], [ 29.719340689655173, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2043, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.489858092307692 ], [ 29.719610172413795, -1.490127569230769 ], [ 29.719879655172413, -1.490127569230769 ], [ 29.719879655172413, -1.489858092307692 ], [ 29.719610172413795, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2044, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.489858092307692 ], [ 29.719879655172413, -1.490127569230769 ], [ 29.720149137931035, -1.490127569230769 ], [ 29.720149137931035, -1.489858092307692 ], [ 29.719879655172413, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2045, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.489858092307692 ], [ 29.720149137931035, -1.490127569230769 ], [ 29.720688103448275, -1.490127569230769 ], [ 29.720688103448275, -1.489858092307692 ], [ 29.720149137931035, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2046, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.489858092307692 ], [ 29.720688103448275, -1.490127569230769 ], [ 29.720957586206897, -1.490127569230769 ], [ 29.720957586206897, -1.489858092307692 ], [ 29.720688103448275, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2047, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.489858092307692 ], [ 29.720957586206897, -1.490127569230769 ], [ 29.721227068965518, -1.490127569230769 ], [ 29.721227068965518, -1.489858092307692 ], [ 29.720957586206897, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2048, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.489858092307692 ], [ 29.721227068965518, -1.490127569230769 ], [ 29.721496551724137, -1.490127569230769 ], [ 29.721496551724137, -1.489858092307692 ], [ 29.721227068965518, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2049, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.489858092307692 ], [ 29.721496551724137, -1.490127569230769 ], [ 29.721766034482759, -1.490127569230769 ], [ 29.721766034482759, -1.489858092307692 ], [ 29.721496551724137, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2050, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.489858092307692 ], [ 29.721766034482759, -1.490127569230769 ], [ 29.72203551724138, -1.490127569230769 ], [ 29.72203551724138, -1.489858092307692 ], [ 29.721766034482759, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2051, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.489858092307692 ], [ 29.72203551724138, -1.490127569230769 ], [ 29.722305, -1.490127569230769 ], [ 29.722305, -1.489858092307692 ], [ 29.72203551724138, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2052, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.489858092307692 ], [ 29.722305, -1.490127569230769 ], [ 29.72257448275862, -1.490127569230769 ], [ 29.72257448275862, -1.489858092307692 ], [ 29.722305, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2053, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.489858092307692 ], [ 29.72257448275862, -1.490127569230769 ], [ 29.722843965517242, -1.490127569230769 ], [ 29.722843965517242, -1.489858092307692 ], [ 29.72257448275862, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2054, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.489858092307692 ], [ 29.722843965517242, -1.490127569230769 ], [ 29.723113448275861, -1.490127569230769 ], [ 29.723113448275861, -1.489858092307692 ], [ 29.722843965517242, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2055, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.489858092307692 ], [ 29.723113448275861, -1.490127569230769 ], [ 29.723382931034482, -1.490127569230769 ], [ 29.723382931034482, -1.489858092307692 ], [ 29.723113448275861, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2056, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.489858092307692 ], [ 29.723382931034482, -1.490127569230769 ], [ 29.723652413793104, -1.490127569230769 ], [ 29.723652413793104, -1.489858092307692 ], [ 29.723382931034482, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2057, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.489858092307692 ], [ 29.723652413793104, -1.490127569230769 ], [ 29.724460862068966, -1.490127569230769 ], [ 29.724460862068966, -1.489858092307692 ], [ 29.723652413793104, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2058, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.489858092307692 ], [ 29.724460862068966, -1.490127569230769 ], [ 29.724999827586206, -1.490127569230769 ], [ 29.724999827586206, -1.489858092307692 ], [ 29.724460862068966, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2059, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.489858092307692 ], [ 29.724999827586206, -1.490127569230769 ], [ 29.725269310344828, -1.490127569230769 ], [ 29.725269310344828, -1.489858092307692 ], [ 29.724999827586206, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2060, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.489858092307692 ], [ 29.725269310344828, -1.490127569230769 ], [ 29.72553879310345, -1.490127569230769 ], [ 29.72553879310345, -1.489858092307692 ], [ 29.725269310344828, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2061, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.489858092307692 ], [ 29.72553879310345, -1.490127569230769 ], [ 29.725808275862068, -1.490127569230769 ], [ 29.725808275862068, -1.489858092307692 ], [ 29.72553879310345, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2062, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.489858092307692 ], [ 29.725808275862068, -1.490127569230769 ], [ 29.72607775862069, -1.490127569230769 ], [ 29.72607775862069, -1.489858092307692 ], [ 29.725808275862068, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2063, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.489858092307692 ], [ 29.72607775862069, -1.490127569230769 ], [ 29.726347241379312, -1.490127569230769 ], [ 29.726347241379312, -1.489858092307692 ], [ 29.72607775862069, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2064, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.489858092307692 ], [ 29.726347241379312, -1.490127569230769 ], [ 29.72661672413793, -1.490127569230769 ], [ 29.72661672413793, -1.489858092307692 ], [ 29.726347241379312, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2065, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.489858092307692 ], [ 29.72661672413793, -1.490127569230769 ], [ 29.726886206896552, -1.490127569230769 ], [ 29.726886206896552, -1.489858092307692 ], [ 29.72661672413793, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2066, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.489858092307692 ], [ 29.726886206896552, -1.490127569230769 ], [ 29.727155689655174, -1.490127569230769 ], [ 29.727155689655174, -1.489858092307692 ], [ 29.726886206896552, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2067, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.489858092307692 ], [ 29.727155689655174, -1.490127569230769 ], [ 29.727425172413792, -1.490127569230769 ], [ 29.727425172413792, -1.489858092307692 ], [ 29.727155689655174, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2068, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.489858092307692 ], [ 29.727425172413792, -1.490127569230769 ], [ 29.727694655172414, -1.490127569230769 ], [ 29.727694655172414, -1.489858092307692 ], [ 29.727425172413792, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2069, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.489858092307692 ], [ 29.727694655172414, -1.490127569230769 ], [ 29.729850517241381, -1.490127569230769 ], [ 29.729850517241381, -1.489858092307692 ], [ 29.727694655172414, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2070, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.489858092307692 ], [ 29.729850517241381, -1.490127569230769 ], [ 29.73012, -1.490127569230769 ], [ 29.73012, -1.489858092307692 ], [ 29.729850517241381, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2071, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.489858092307692 ], [ 29.73012, -1.490127569230769 ], [ 29.730389482758621, -1.490127569230769 ], [ 29.730389482758621, -1.489858092307692 ], [ 29.73012, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2072, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.489858092307692 ], [ 29.730389482758621, -1.490127569230769 ], [ 29.730658965517243, -1.490127569230769 ], [ 29.730658965517243, -1.489858092307692 ], [ 29.730389482758621, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2073, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.489858092307692 ], [ 29.730658965517243, -1.490127569230769 ], [ 29.730928448275861, -1.490127569230769 ], [ 29.730928448275861, -1.489858092307692 ], [ 29.730658965517243, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2074, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.489858092307692 ], [ 29.730928448275861, -1.490127569230769 ], [ 29.731197931034483, -1.490127569230769 ], [ 29.731197931034483, -1.489858092307692 ], [ 29.730928448275861, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2075, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.489858092307692 ], [ 29.731197931034483, -1.490127569230769 ], [ 29.731467413793105, -1.490127569230769 ], [ 29.731467413793105, -1.489858092307692 ], [ 29.731197931034483, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2076, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.489858092307692 ], [ 29.731467413793105, -1.490127569230769 ], [ 29.731736896551723, -1.490127569230769 ], [ 29.731736896551723, -1.489858092307692 ], [ 29.731467413793105, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2077, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.489858092307692 ], [ 29.731736896551723, -1.490127569230769 ], [ 29.732006379310345, -1.490127569230769 ], [ 29.732006379310345, -1.489858092307692 ], [ 29.731736896551723, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2078, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.489858092307692 ], [ 29.732006379310345, -1.490397046153846 ], [ 29.732545344827585, -1.490397046153846 ], [ 29.732545344827585, -1.490127569230769 ], [ 29.732275862068967, -1.490127569230769 ], [ 29.732275862068967, -1.489858092307692 ], [ 29.732006379310345, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2079, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.489858092307692 ], [ 29.732275862068967, -1.490127569230769 ], [ 29.732545344827585, -1.490127569230769 ], [ 29.732545344827585, -1.489858092307692 ], [ 29.732275862068967, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2080, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.489858092307692 ], [ 29.732545344827585, -1.490127569230769 ], [ 29.732814827586207, -1.490127569230769 ], [ 29.732814827586207, -1.489858092307692 ], [ 29.732545344827585, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2081, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.489858092307692 ], [ 29.733084310344829, -1.490127569230769 ], [ 29.733353793103447, -1.490127569230769 ], [ 29.733353793103447, -1.489858092307692 ], [ 29.733084310344829, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2082, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.489858092307692 ], [ 29.733353793103447, -1.490127569230769 ], [ 29.733623275862069, -1.490127569230769 ], [ 29.733623275862069, -1.489858092307692 ], [ 29.733353793103447, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2083, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.489858092307692 ], [ 29.733623275862069, -1.490127569230769 ], [ 29.733892758620691, -1.490127569230769 ], [ 29.733892758620691, -1.489858092307692 ], [ 29.733623275862069, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2084, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.489858092307692 ], [ 29.733892758620691, -1.490127569230769 ], [ 29.734162241379309, -1.490127569230769 ], [ 29.734162241379309, -1.489858092307692 ], [ 29.733892758620691, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2085, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.489858092307692 ], [ 29.734162241379309, -1.490127569230769 ], [ 29.734431724137931, -1.490127569230769 ], [ 29.734431724137931, -1.489858092307692 ], [ 29.734162241379309, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2086, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.489858092307692 ], [ 29.734431724137931, -1.490127569230769 ], [ 29.734701206896553, -1.490127569230769 ], [ 29.734701206896553, -1.489858092307692 ], [ 29.734431724137931, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2087, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.489858092307692 ], [ 29.734701206896553, -1.490127569230769 ], [ 29.734970689655171, -1.490127569230769 ], [ 29.734970689655171, -1.489858092307692 ], [ 29.734701206896553, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2088, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.489858092307692 ], [ 29.734970689655171, -1.490127569230769 ], [ 29.735240172413793, -1.490127569230769 ], [ 29.735240172413793, -1.489858092307692 ], [ 29.734970689655171, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2089, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.489858092307692 ], [ 29.735240172413793, -1.490127569230769 ], [ 29.735509655172415, -1.490127569230769 ], [ 29.735509655172415, -1.489858092307692 ], [ 29.735240172413793, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2090, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.489858092307692 ], [ 29.735509655172415, -1.490127569230769 ], [ 29.735779137931036, -1.490127569230769 ], [ 29.735779137931036, -1.489858092307692 ], [ 29.735509655172415, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2091, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.489858092307692 ], [ 29.735779137931036, -1.490127569230769 ], [ 29.736048620689655, -1.490127569230769 ], [ 29.736048620689655, -1.489858092307692 ], [ 29.735779137931036, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2092, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.489858092307692 ], [ 29.736048620689655, -1.490127569230769 ], [ 29.736318103448276, -1.490127569230769 ], [ 29.736318103448276, -1.489858092307692 ], [ 29.736048620689655, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2093, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.489858092307692 ], [ 29.736318103448276, -1.490127569230769 ], [ 29.736587586206898, -1.490127569230769 ], [ 29.736587586206898, -1.489858092307692 ], [ 29.736318103448276, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2094, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.489858092307692 ], [ 29.736587586206898, -1.490127569230769 ], [ 29.736857068965517, -1.490127569230769 ], [ 29.736857068965517, -1.489858092307692 ], [ 29.736587586206898, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2095, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.489858092307692 ], [ 29.736857068965517, -1.490127569230769 ], [ 29.737126551724138, -1.490127569230769 ], [ 29.737126551724138, -1.489858092307692 ], [ 29.736857068965517, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2096, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.489858092307692 ], [ 29.737126551724138, -1.490127569230769 ], [ 29.73739603448276, -1.490127569230769 ], [ 29.73739603448276, -1.489858092307692 ], [ 29.737126551724138, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2097, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.489858092307692 ], [ 29.73739603448276, -1.490127569230769 ], [ 29.737665517241378, -1.490127569230769 ], [ 29.737665517241378, -1.489858092307692 ], [ 29.73739603448276, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2098, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.489858092307692 ], [ 29.737665517241378, -1.490127569230769 ], [ 29.737935, -1.490127569230769 ], [ 29.737935, -1.489858092307692 ], [ 29.737665517241378, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2099, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.489858092307692 ], [ 29.737935, -1.490127569230769 ], [ 29.738204482758622, -1.490127569230769 ], [ 29.738204482758622, -1.489858092307692 ], [ 29.737935, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2100, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.489858092307692 ], [ 29.738204482758622, -1.490127569230769 ], [ 29.738743448275862, -1.490127569230769 ], [ 29.738743448275862, -1.489858092307692 ], [ 29.738204482758622, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2101, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.489858092307692 ], [ 29.738743448275862, -1.490127569230769 ], [ 29.739012931034484, -1.490127569230769 ], [ 29.739012931034484, -1.489858092307692 ], [ 29.738743448275862, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2102, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.489858092307692 ], [ 29.739012931034484, -1.490127569230769 ], [ 29.739282413793102, -1.490127569230769 ], [ 29.739282413793102, -1.489858092307692 ], [ 29.739012931034484, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2103, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.489858092307692 ], [ 29.739282413793102, -1.490127569230769 ], [ 29.739551896551724, -1.490127569230769 ], [ 29.739551896551724, -1.489858092307692 ], [ 29.739282413793102, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2104, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.489858092307692 ], [ 29.739551896551724, -1.490127569230769 ], [ 29.740090862068964, -1.490127569230769 ], [ 29.740090862068964, -1.489858092307692 ], [ 29.739551896551724, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2105, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.489858092307692 ], [ 29.740090862068964, -1.490127569230769 ], [ 29.740360344827586, -1.490127569230769 ], [ 29.740360344827586, -1.489858092307692 ], [ 29.740090862068964, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2106, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.489858092307692 ], [ 29.740360344827586, -1.490127569230769 ], [ 29.74197724137931, -1.490127569230769 ], [ 29.74197724137931, -1.489858092307692 ], [ 29.740360344827586, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2107, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.489858092307692 ], [ 29.74197724137931, -1.490127569230769 ], [ 29.742516206896553, -1.490127569230769 ], [ 29.742516206896553, -1.489858092307692 ], [ 29.74197724137931, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2108, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.489858092307692 ], [ 29.742516206896553, -1.490127569230769 ], [ 29.743055172413793, -1.490127569230769 ], [ 29.743055172413793, -1.489858092307692 ], [ 29.742516206896553, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2109, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.489858092307692 ], [ 29.743055172413793, -1.490127569230769 ], [ 29.743324655172415, -1.490127569230769 ], [ 29.743324655172415, -1.489858092307692 ], [ 29.743055172413793, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2110, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.489858092307692 ], [ 29.743324655172415, -1.490127569230769 ], [ 29.743863620689655, -1.490127569230769 ], [ 29.743863620689655, -1.489858092307692 ], [ 29.743324655172415, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2111, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.489858092307692 ], [ 29.743863620689655, -1.490127569230769 ], [ 29.744672068965517, -1.490127569230769 ], [ 29.744672068965517, -1.489858092307692 ], [ 29.743863620689655, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2112, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.489858092307692 ], [ 29.744672068965517, -1.490127569230769 ], [ 29.744941551724139, -1.490127569230769 ], [ 29.744941551724139, -1.489858092307692 ], [ 29.744672068965517, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2113, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.489858092307692 ], [ 29.744941551724139, -1.490127569230769 ], [ 29.74575, -1.490127569230769 ], [ 29.74575, -1.489858092307692 ], [ 29.744941551724139, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2114, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.489858092307692 ], [ 29.74575, -1.490127569230769 ], [ 29.746019482758619, -1.490127569230769 ], [ 29.746019482758619, -1.489858092307692 ], [ 29.74575, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2115, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.489858092307692 ], [ 29.746019482758619, -1.490127569230769 ], [ 29.746558448275863, -1.490127569230769 ], [ 29.746558448275863, -1.489858092307692 ], [ 29.746019482758619, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2116, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.489858092307692 ], [ 29.746558448275863, -1.490127569230769 ], [ 29.748175344827587, -1.490127569230769 ], [ 29.748175344827587, -1.489858092307692 ], [ 29.746558448275863, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2117, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.489858092307692 ], [ 29.748175344827587, -1.490127569230769 ], [ 29.748714310344827, -1.490127569230769 ], [ 29.748714310344827, -1.489858092307692 ], [ 29.748175344827587, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2118, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.489858092307692 ], [ 29.748714310344827, -1.490127569230769 ], [ 29.748983793103449, -1.490127569230769 ], [ 29.748983793103449, -1.489858092307692 ], [ 29.748714310344827, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2119, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.489858092307692 ], [ 29.748983793103449, -1.490127569230769 ], [ 29.74925327586207, -1.490127569230769 ], [ 29.74925327586207, -1.489858092307692 ], [ 29.748983793103449, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2120, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.489858092307692 ], [ 29.74925327586207, -1.490127569230769 ], [ 29.749522758620689, -1.490127569230769 ], [ 29.749522758620689, -1.489858092307692 ], [ 29.74925327586207, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2121, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.489858092307692 ], [ 29.749522758620689, -1.490127569230769 ], [ 29.749792241379311, -1.490127569230769 ], [ 29.749792241379311, -1.489858092307692 ], [ 29.749522758620689, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2122, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.489858092307692 ], [ 29.749792241379311, -1.490127569230769 ], [ 29.750061724137932, -1.490127569230769 ], [ 29.750061724137932, -1.489858092307692 ], [ 29.749792241379311, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2123, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.489858092307692 ], [ 29.750061724137932, -1.490127569230769 ], [ 29.750331206896551, -1.490127569230769 ], [ 29.750331206896551, -1.489858092307692 ], [ 29.750061724137932, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2124, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.489858092307692 ], [ 29.750331206896551, -1.490127569230769 ], [ 29.750600689655172, -1.490127569230769 ], [ 29.750600689655172, -1.489858092307692 ], [ 29.750331206896551, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2125, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.489858092307692 ], [ 29.750600689655172, -1.490127569230769 ], [ 29.750870172413794, -1.490127569230769 ], [ 29.750870172413794, -1.489858092307692 ], [ 29.750600689655172, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2126, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.489858092307692 ], [ 29.750870172413794, -1.490127569230769 ], [ 29.751139655172413, -1.490127569230769 ], [ 29.751139655172413, -1.489858092307692 ], [ 29.750870172413794, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2127, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.489858092307692 ], [ 29.751409137931034, -1.490127569230769 ], [ 29.751678620689656, -1.490127569230769 ], [ 29.751678620689656, -1.489858092307692 ], [ 29.751409137931034, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2128, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.489858092307692 ], [ 29.751678620689656, -1.490127569230769 ], [ 29.751948103448278, -1.490127569230769 ], [ 29.751948103448278, -1.489858092307692 ], [ 29.751678620689656, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2129, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.489858092307692 ], [ 29.751948103448278, -1.490127569230769 ], [ 29.752217586206896, -1.490127569230769 ], [ 29.752217586206896, -1.489858092307692 ], [ 29.751948103448278, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2130, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.489858092307692 ], [ 29.752217586206896, -1.490127569230769 ], [ 29.752487068965518, -1.490127569230769 ], [ 29.752487068965518, -1.489858092307692 ], [ 29.752217586206896, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2131, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.489858092307692 ], [ 29.752487068965518, -1.490127569230769 ], [ 29.75275655172414, -1.490127569230769 ], [ 29.75275655172414, -1.489858092307692 ], [ 29.752487068965518, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2132, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.489858092307692 ], [ 29.75275655172414, -1.490127569230769 ], [ 29.753026034482758, -1.490127569230769 ], [ 29.753026034482758, -1.489858092307692 ], [ 29.75275655172414, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2133, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.489858092307692 ], [ 29.753026034482758, -1.490127569230769 ], [ 29.75329551724138, -1.490127569230769 ], [ 29.75329551724138, -1.489858092307692 ], [ 29.753026034482758, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2134, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.489858092307692 ], [ 29.75329551724138, -1.490127569230769 ], [ 29.753565, -1.490127569230769 ], [ 29.753565, -1.489858092307692 ], [ 29.75329551724138, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2135, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.489858092307692 ], [ 29.753565, -1.490127569230769 ], [ 29.75383448275862, -1.490127569230769 ], [ 29.75383448275862, -1.489858092307692 ], [ 29.753565, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2136, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.489858092307692 ], [ 29.75383448275862, -1.490127569230769 ], [ 29.754103965517242, -1.490127569230769 ], [ 29.754103965517242, -1.489858092307692 ], [ 29.75383448275862, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2137, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.489858092307692 ], [ 29.754103965517242, -1.490127569230769 ], [ 29.754373448275864, -1.490127569230769 ], [ 29.754373448275864, -1.489858092307692 ], [ 29.754103965517242, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2138, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.489858092307692 ], [ 29.754373448275864, -1.490397046153846 ], [ 29.754642931034482, -1.490397046153846 ], [ 29.754642931034482, -1.489858092307692 ], [ 29.754373448275864, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2139, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.489858092307692 ], [ 29.754642931034482, -1.490397046153846 ], [ 29.754912413793104, -1.490397046153846 ], [ 29.754912413793104, -1.489858092307692 ], [ 29.754642931034482, -1.489858092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2140, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.490127569230769 ], [ 29.717454310344827, -1.490397046153846 ], [ 29.717723793103449, -1.490397046153846 ], [ 29.717723793103449, -1.490127569230769 ], [ 29.717454310344827, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2141, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.490127569230769 ], [ 29.717723793103449, -1.490397046153846 ], [ 29.717993275862067, -1.490397046153846 ], [ 29.717993275862067, -1.490127569230769 ], [ 29.717723793103449, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2142, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.490127569230769 ], [ 29.717993275862067, -1.490397046153846 ], [ 29.718532241379311, -1.490397046153846 ], [ 29.718532241379311, -1.490127569230769 ], [ 29.717993275862067, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2143, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.490127569230769 ], [ 29.718532241379311, -1.490397046153846 ], [ 29.718801724137933, -1.490397046153846 ], [ 29.718801724137933, -1.490127569230769 ], [ 29.718532241379311, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2144, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.490127569230769 ], [ 29.718801724137933, -1.490397046153846 ], [ 29.719071206896551, -1.490397046153846 ], [ 29.719071206896551, -1.490127569230769 ], [ 29.718801724137933, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2145, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.490127569230769 ], [ 29.719071206896551, -1.490397046153846 ], [ 29.719610172413795, -1.490397046153846 ], [ 29.719610172413795, -1.490127569230769 ], [ 29.719071206896551, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2146, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.490127569230769 ], [ 29.719610172413795, -1.490397046153846 ], [ 29.719879655172413, -1.490397046153846 ], [ 29.719879655172413, -1.490127569230769 ], [ 29.719610172413795, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2147, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.490127569230769 ], [ 29.719879655172413, -1.490397046153846 ], [ 29.720149137931035, -1.490397046153846 ], [ 29.720149137931035, -1.490127569230769 ], [ 29.719879655172413, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2148, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.490127569230769 ], [ 29.720149137931035, -1.490397046153846 ], [ 29.720688103448275, -1.490397046153846 ], [ 29.720688103448275, -1.490127569230769 ], [ 29.720149137931035, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2149, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.490127569230769 ], [ 29.720688103448275, -1.490397046153846 ], [ 29.720957586206897, -1.490397046153846 ], [ 29.720957586206897, -1.490127569230769 ], [ 29.720688103448275, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2150, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.490127569230769 ], [ 29.720957586206897, -1.490397046153846 ], [ 29.721227068965518, -1.490397046153846 ], [ 29.721227068965518, -1.490127569230769 ], [ 29.720957586206897, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2151, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.490127569230769 ], [ 29.721227068965518, -1.490397046153846 ], [ 29.721496551724137, -1.490397046153846 ], [ 29.721496551724137, -1.490127569230769 ], [ 29.721227068965518, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2152, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.490127569230769 ], [ 29.721496551724137, -1.490397046153846 ], [ 29.721766034482759, -1.490397046153846 ], [ 29.721766034482759, -1.490127569230769 ], [ 29.721496551724137, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2153, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.490127569230769 ], [ 29.721766034482759, -1.490397046153846 ], [ 29.72203551724138, -1.490397046153846 ], [ 29.72203551724138, -1.490127569230769 ], [ 29.721766034482759, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2154, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.490127569230769 ], [ 29.72203551724138, -1.490397046153846 ], [ 29.722305, -1.490397046153846 ], [ 29.722305, -1.490127569230769 ], [ 29.72203551724138, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2155, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.490127569230769 ], [ 29.722305, -1.490397046153846 ], [ 29.72257448275862, -1.490397046153846 ], [ 29.72257448275862, -1.490127569230769 ], [ 29.722305, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2156, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.490127569230769 ], [ 29.72257448275862, -1.490397046153846 ], [ 29.722843965517242, -1.490397046153846 ], [ 29.722843965517242, -1.490127569230769 ], [ 29.72257448275862, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2157, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.490127569230769 ], [ 29.722843965517242, -1.490397046153846 ], [ 29.723113448275861, -1.490397046153846 ], [ 29.723113448275861, -1.490127569230769 ], [ 29.722843965517242, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2158, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.490127569230769 ], [ 29.723113448275861, -1.490397046153846 ], [ 29.723382931034482, -1.490397046153846 ], [ 29.723382931034482, -1.490127569230769 ], [ 29.723113448275861, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2159, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.490127569230769 ], [ 29.723382931034482, -1.490397046153846 ], [ 29.723652413793104, -1.490397046153846 ], [ 29.723652413793104, -1.490127569230769 ], [ 29.723382931034482, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2160, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.490127569230769 ], [ 29.723652413793104, -1.490397046153846 ], [ 29.723921896551722, -1.490397046153846 ], [ 29.723921896551722, -1.490127569230769 ], [ 29.723652413793104, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2161, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.490127569230769 ], [ 29.723921896551722, -1.490397046153846 ], [ 29.724730344827588, -1.490397046153846 ], [ 29.724730344827588, -1.490127569230769 ], [ 29.723921896551722, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2162, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.490127569230769 ], [ 29.724730344827588, -1.490397046153846 ], [ 29.724999827586206, -1.490397046153846 ], [ 29.724999827586206, -1.490127569230769 ], [ 29.724730344827588, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2163, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.490127569230769 ], [ 29.724999827586206, -1.490397046153846 ], [ 29.725269310344828, -1.490397046153846 ], [ 29.725269310344828, -1.490127569230769 ], [ 29.724999827586206, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2164, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.490127569230769 ], [ 29.725269310344828, -1.490397046153846 ], [ 29.72553879310345, -1.490397046153846 ], [ 29.72553879310345, -1.490127569230769 ], [ 29.725269310344828, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2165, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.490127569230769 ], [ 29.72553879310345, -1.490397046153846 ], [ 29.725808275862068, -1.490397046153846 ], [ 29.725808275862068, -1.490127569230769 ], [ 29.72553879310345, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2166, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.490127569230769 ], [ 29.725808275862068, -1.490397046153846 ], [ 29.72607775862069, -1.490397046153846 ], [ 29.72607775862069, -1.490127569230769 ], [ 29.725808275862068, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2167, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.490127569230769 ], [ 29.72607775862069, -1.490397046153846 ], [ 29.726347241379312, -1.490397046153846 ], [ 29.726347241379312, -1.490127569230769 ], [ 29.72607775862069, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2168, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.490127569230769 ], [ 29.726347241379312, -1.490397046153846 ], [ 29.72661672413793, -1.490397046153846 ], [ 29.72661672413793, -1.490127569230769 ], [ 29.726347241379312, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2169, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.490127569230769 ], [ 29.72661672413793, -1.490397046153846 ], [ 29.726886206896552, -1.490397046153846 ], [ 29.726886206896552, -1.490127569230769 ], [ 29.72661672413793, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2170, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.490127569230769 ], [ 29.726886206896552, -1.490397046153846 ], [ 29.727155689655174, -1.490397046153846 ], [ 29.727155689655174, -1.490127569230769 ], [ 29.726886206896552, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2171, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.490127569230769 ], [ 29.727155689655174, -1.490397046153846 ], [ 29.727425172413792, -1.490397046153846 ], [ 29.727425172413792, -1.490127569230769 ], [ 29.727155689655174, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2172, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.490127569230769 ], [ 29.727425172413792, -1.490397046153846 ], [ 29.727694655172414, -1.490397046153846 ], [ 29.727694655172414, -1.490127569230769 ], [ 29.727425172413792, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2173, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.490127569230769 ], [ 29.727694655172414, -1.490397046153846 ], [ 29.729850517241381, -1.490397046153846 ], [ 29.729850517241381, -1.490127569230769 ], [ 29.727694655172414, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2174, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.490127569230769 ], [ 29.729850517241381, -1.490397046153846 ], [ 29.73012, -1.490397046153846 ], [ 29.73012, -1.490127569230769 ], [ 29.729850517241381, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2175, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.490127569230769 ], [ 29.73012, -1.490397046153846 ], [ 29.730389482758621, -1.490397046153846 ], [ 29.730389482758621, -1.490127569230769 ], [ 29.73012, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2176, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.490127569230769 ], [ 29.730389482758621, -1.490397046153846 ], [ 29.730658965517243, -1.490397046153846 ], [ 29.730658965517243, -1.490127569230769 ], [ 29.730389482758621, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2177, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.490127569230769 ], [ 29.730658965517243, -1.490397046153846 ], [ 29.730928448275861, -1.490397046153846 ], [ 29.730928448275861, -1.490127569230769 ], [ 29.730658965517243, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2178, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.490127569230769 ], [ 29.730928448275861, -1.490397046153846 ], [ 29.731197931034483, -1.490397046153846 ], [ 29.731197931034483, -1.490127569230769 ], [ 29.730928448275861, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2179, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.490127569230769 ], [ 29.731467413793105, -1.490397046153846 ], [ 29.731736896551723, -1.490397046153846 ], [ 29.731736896551723, -1.490127569230769 ], [ 29.731467413793105, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2180, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.490127569230769 ], [ 29.731736896551723, -1.490397046153846 ], [ 29.732006379310345, -1.490397046153846 ], [ 29.732006379310345, -1.490127569230769 ], [ 29.731736896551723, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2181, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.490127569230769 ], [ 29.732545344827585, -1.490397046153846 ], [ 29.732814827586207, -1.490397046153846 ], [ 29.732814827586207, -1.490127569230769 ], [ 29.732545344827585, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2182, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.490127569230769 ], [ 29.732814827586207, -1.490397046153846 ], [ 29.733084310344829, -1.490397046153846 ], [ 29.733084310344829, -1.490127569230769 ], [ 29.732814827586207, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2183, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.490127569230769 ], [ 29.733084310344829, -1.490397046153846 ], [ 29.733353793103447, -1.490397046153846 ], [ 29.733353793103447, -1.490127569230769 ], [ 29.733084310344829, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2184, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.490127569230769 ], [ 29.733353793103447, -1.490397046153846 ], [ 29.733623275862069, -1.490397046153846 ], [ 29.733623275862069, -1.490127569230769 ], [ 29.733353793103447, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2185, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.490127569230769 ], [ 29.733623275862069, -1.490397046153846 ], [ 29.733892758620691, -1.490397046153846 ], [ 29.733892758620691, -1.490127569230769 ], [ 29.733623275862069, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2186, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.490127569230769 ], [ 29.733892758620691, -1.490397046153846 ], [ 29.734162241379309, -1.490397046153846 ], [ 29.734162241379309, -1.490127569230769 ], [ 29.733892758620691, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2187, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.490127569230769 ], [ 29.734162241379309, -1.490397046153846 ], [ 29.734431724137931, -1.490397046153846 ], [ 29.734431724137931, -1.490127569230769 ], [ 29.734162241379309, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2188, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.490127569230769 ], [ 29.734431724137931, -1.490397046153846 ], [ 29.734701206896553, -1.490397046153846 ], [ 29.734701206896553, -1.490127569230769 ], [ 29.734431724137931, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2189, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.490127569230769 ], [ 29.734701206896553, -1.490397046153846 ], [ 29.734970689655171, -1.490397046153846 ], [ 29.734970689655171, -1.490127569230769 ], [ 29.734701206896553, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2190, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.490127569230769 ], [ 29.734970689655171, -1.490397046153846 ], [ 29.735240172413793, -1.490397046153846 ], [ 29.735240172413793, -1.490127569230769 ], [ 29.734970689655171, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2191, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.490127569230769 ], [ 29.735240172413793, -1.490397046153846 ], [ 29.735509655172415, -1.490397046153846 ], [ 29.735509655172415, -1.490127569230769 ], [ 29.735240172413793, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2192, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.490127569230769 ], [ 29.735509655172415, -1.490397046153846 ], [ 29.735779137931036, -1.490397046153846 ], [ 29.735779137931036, -1.490127569230769 ], [ 29.735509655172415, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2193, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.490127569230769 ], [ 29.735779137931036, -1.490397046153846 ], [ 29.736048620689655, -1.490397046153846 ], [ 29.736048620689655, -1.490127569230769 ], [ 29.735779137931036, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2194, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.490127569230769 ], [ 29.736048620689655, -1.490397046153846 ], [ 29.736587586206898, -1.490397046153846 ], [ 29.736587586206898, -1.490127569230769 ], [ 29.736048620689655, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2195, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.490127569230769 ], [ 29.736587586206898, -1.490397046153846 ], [ 29.736857068965517, -1.490397046153846 ], [ 29.736857068965517, -1.490127569230769 ], [ 29.736587586206898, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2196, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.490127569230769 ], [ 29.736857068965517, -1.490397046153846 ], [ 29.737126551724138, -1.490397046153846 ], [ 29.737126551724138, -1.490127569230769 ], [ 29.736857068965517, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2197, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.490127569230769 ], [ 29.737126551724138, -1.490397046153846 ], [ 29.73739603448276, -1.490397046153846 ], [ 29.73739603448276, -1.490127569230769 ], [ 29.737126551724138, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2198, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.490127569230769 ], [ 29.73739603448276, -1.490397046153846 ], [ 29.737665517241378, -1.490397046153846 ], [ 29.737665517241378, -1.490127569230769 ], [ 29.73739603448276, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2199, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.490127569230769 ], [ 29.737665517241378, -1.490397046153846 ], [ 29.737935, -1.490397046153846 ], [ 29.737935, -1.490127569230769 ], [ 29.737665517241378, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2200, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.490127569230769 ], [ 29.737935, -1.490397046153846 ], [ 29.738204482758622, -1.490397046153846 ], [ 29.738204482758622, -1.490127569230769 ], [ 29.737935, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2201, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.490127569230769 ], [ 29.738204482758622, -1.490397046153846 ], [ 29.738743448275862, -1.490397046153846 ], [ 29.738743448275862, -1.490127569230769 ], [ 29.738204482758622, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2202, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.490127569230769 ], [ 29.738743448275862, -1.490397046153846 ], [ 29.739012931034484, -1.490397046153846 ], [ 29.739012931034484, -1.490127569230769 ], [ 29.738743448275862, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2203, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.490127569230769 ], [ 29.739012931034484, -1.490397046153846 ], [ 29.739282413793102, -1.490397046153846 ], [ 29.739282413793102, -1.490127569230769 ], [ 29.739012931034484, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2204, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.490127569230769 ], [ 29.739282413793102, -1.490397046153846 ], [ 29.739551896551724, -1.490397046153846 ], [ 29.739551896551724, -1.490127569230769 ], [ 29.739282413793102, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2205, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.490127569230769 ], [ 29.739551896551724, -1.490397046153846 ], [ 29.739821379310346, -1.490397046153846 ], [ 29.739821379310346, -1.490127569230769 ], [ 29.739551896551724, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2206, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.490127569230769 ], [ 29.739821379310346, -1.490397046153846 ], [ 29.740360344827586, -1.490397046153846 ], [ 29.740360344827586, -1.490127569230769 ], [ 29.739821379310346, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2207, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.490127569230769 ], [ 29.740360344827586, -1.490397046153846 ], [ 29.74089931034483, -1.490397046153846 ], [ 29.74089931034483, -1.490127569230769 ], [ 29.740360344827586, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2208, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.490127569230769 ], [ 29.74089931034483, -1.490397046153846 ], [ 29.742246724137932, -1.490397046153846 ], [ 29.742246724137932, -1.490127569230769 ], [ 29.74089931034483, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2209, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.490127569230769 ], [ 29.742246724137932, -1.490397046153846 ], [ 29.742785689655172, -1.490397046153846 ], [ 29.742785689655172, -1.490127569230769 ], [ 29.742246724137932, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2210, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.490127569230769 ], [ 29.742785689655172, -1.490397046153846 ], [ 29.743324655172415, -1.490397046153846 ], [ 29.743324655172415, -1.490127569230769 ], [ 29.742785689655172, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2211, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.490127569230769 ], [ 29.743324655172415, -1.490397046153846 ], [ 29.743594137931034, -1.490397046153846 ], [ 29.743594137931034, -1.490127569230769 ], [ 29.743324655172415, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2212, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.490127569230769 ], [ 29.743594137931034, -1.490397046153846 ], [ 29.744672068965517, -1.490397046153846 ], [ 29.744672068965517, -1.490127569230769 ], [ 29.743594137931034, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2213, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.490127569230769 ], [ 29.744672068965517, -1.490397046153846 ], [ 29.744941551724139, -1.490397046153846 ], [ 29.744941551724139, -1.490127569230769 ], [ 29.744672068965517, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2214, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.490127569230769 ], [ 29.744941551724139, -1.490397046153846 ], [ 29.745480517241379, -1.490397046153846 ], [ 29.745480517241379, -1.490127569230769 ], [ 29.744941551724139, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2215, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.490127569230769 ], [ 29.745480517241379, -1.490397046153846 ], [ 29.746019482758619, -1.490397046153846 ], [ 29.746019482758619, -1.490127569230769 ], [ 29.745480517241379, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2216, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.490127569230769 ], [ 29.746019482758619, -1.490397046153846 ], [ 29.746288965517241, -1.490397046153846 ], [ 29.746288965517241, -1.490127569230769 ], [ 29.746019482758619, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2217, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.490127569230769 ], [ 29.746288965517241, -1.490397046153846 ], [ 29.748444827586209, -1.490397046153846 ], [ 29.748444827586209, -1.490127569230769 ], [ 29.746288965517241, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2218, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.490127569230769 ], [ 29.748444827586209, -1.490397046153846 ], [ 29.748714310344827, -1.490397046153846 ], [ 29.748714310344827, -1.490127569230769 ], [ 29.748444827586209, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2219, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.490127569230769 ], [ 29.748714310344827, -1.490397046153846 ], [ 29.748983793103449, -1.490397046153846 ], [ 29.748983793103449, -1.490127569230769 ], [ 29.748714310344827, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2220, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.490127569230769 ], [ 29.748983793103449, -1.490397046153846 ], [ 29.74925327586207, -1.490397046153846 ], [ 29.74925327586207, -1.490127569230769 ], [ 29.748983793103449, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2221, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.490127569230769 ], [ 29.74925327586207, -1.490397046153846 ], [ 29.749522758620689, -1.490397046153846 ], [ 29.749522758620689, -1.490127569230769 ], [ 29.74925327586207, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2222, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.490127569230769 ], [ 29.749522758620689, -1.490397046153846 ], [ 29.749792241379311, -1.490397046153846 ], [ 29.749792241379311, -1.490127569230769 ], [ 29.749522758620689, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2223, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.490127569230769 ], [ 29.749792241379311, -1.490397046153846 ], [ 29.750061724137932, -1.490397046153846 ], [ 29.750061724137932, -1.490127569230769 ], [ 29.749792241379311, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2224, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.490127569230769 ], [ 29.750061724137932, -1.490397046153846 ], [ 29.750331206896551, -1.490397046153846 ], [ 29.750331206896551, -1.490127569230769 ], [ 29.750061724137932, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2225, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.490127569230769 ], [ 29.750331206896551, -1.490397046153846 ], [ 29.750600689655172, -1.490397046153846 ], [ 29.750600689655172, -1.490127569230769 ], [ 29.750331206896551, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2226, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.490127569230769 ], [ 29.750600689655172, -1.490397046153846 ], [ 29.750870172413794, -1.490397046153846 ], [ 29.750870172413794, -1.490127569230769 ], [ 29.750600689655172, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2227, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.490127569230769 ], [ 29.750870172413794, -1.490397046153846 ], [ 29.751139655172413, -1.490397046153846 ], [ 29.751139655172413, -1.490127569230769 ], [ 29.750870172413794, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2228, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.490127569230769 ], [ 29.751139655172413, -1.490397046153846 ], [ 29.751409137931034, -1.490397046153846 ], [ 29.751409137931034, -1.490127569230769 ], [ 29.751139655172413, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2229, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.490127569230769 ], [ 29.751409137931034, -1.490397046153846 ], [ 29.751678620689656, -1.490397046153846 ], [ 29.751678620689656, -1.490127569230769 ], [ 29.751409137931034, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2230, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.490127569230769 ], [ 29.751678620689656, -1.490397046153846 ], [ 29.751948103448278, -1.490397046153846 ], [ 29.751948103448278, -1.490127569230769 ], [ 29.751678620689656, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2231, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.490127569230769 ], [ 29.751948103448278, -1.490397046153846 ], [ 29.752217586206896, -1.490397046153846 ], [ 29.752217586206896, -1.490127569230769 ], [ 29.751948103448278, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2232, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.490127569230769 ], [ 29.752217586206896, -1.490397046153846 ], [ 29.752487068965518, -1.490397046153846 ], [ 29.752487068965518, -1.490127569230769 ], [ 29.752217586206896, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2233, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.490127569230769 ], [ 29.752487068965518, -1.490397046153846 ], [ 29.75275655172414, -1.490397046153846 ], [ 29.75275655172414, -1.490127569230769 ], [ 29.752487068965518, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2234, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.490127569230769 ], [ 29.75275655172414, -1.490397046153846 ], [ 29.753026034482758, -1.490397046153846 ], [ 29.753026034482758, -1.490127569230769 ], [ 29.75275655172414, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2235, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.490127569230769 ], [ 29.753026034482758, -1.490397046153846 ], [ 29.75329551724138, -1.490397046153846 ], [ 29.75329551724138, -1.490127569230769 ], [ 29.753026034482758, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2236, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.490127569230769 ], [ 29.75329551724138, -1.490397046153846 ], [ 29.753565, -1.490397046153846 ], [ 29.753565, -1.490127569230769 ], [ 29.75329551724138, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2237, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.490127569230769 ], [ 29.753565, -1.490397046153846 ], [ 29.75383448275862, -1.490397046153846 ], [ 29.75383448275862, -1.490127569230769 ], [ 29.753565, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2238, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.490127569230769 ], [ 29.75383448275862, -1.490666523076923 ], [ 29.754103965517242, -1.490666523076923 ], [ 29.754103965517242, -1.490127569230769 ], [ 29.75383448275862, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2239, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.490127569230769 ], [ 29.754103965517242, -1.490666523076923 ], [ 29.754373448275864, -1.490666523076923 ], [ 29.754373448275864, -1.490127569230769 ], [ 29.754103965517242, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2240, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.490127569230769 ], [ 29.755181896551726, -1.490666523076923 ], [ 29.755451379310344, -1.490666523076923 ], [ 29.755451379310344, -1.490127569230769 ], [ 29.755181896551726, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2241, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.490127569230769 ], [ 29.755990344827588, -1.490397046153846 ], [ 29.756259827586206, -1.490397046153846 ], [ 29.756259827586206, -1.490127569230769 ], [ 29.755990344827588, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2242, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.490127569230769 ], [ 29.756259827586206, -1.490936 ], [ 29.756798793103449, -1.490936 ], [ 29.756798793103449, -1.490127569230769 ], [ 29.756259827586206, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2243, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.490397046153846 ], [ 29.717184827586205, -1.490936 ], [ 29.717454310344827, -1.490936 ], [ 29.717454310344827, -1.490397046153846 ], [ 29.717184827586205, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2244, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.490397046153846 ], [ 29.717454310344827, -1.490666523076923 ], [ 29.717723793103449, -1.490666523076923 ], [ 29.717723793103449, -1.490397046153846 ], [ 29.717454310344827, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2245, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.490397046153846 ], [ 29.717723793103449, -1.490666523076923 ], [ 29.717993275862067, -1.490666523076923 ], [ 29.717993275862067, -1.490397046153846 ], [ 29.717723793103449, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2246, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.490397046153846 ], [ 29.717993275862067, -1.490666523076923 ], [ 29.718532241379311, -1.490666523076923 ], [ 29.718532241379311, -1.490397046153846 ], [ 29.717993275862067, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2247, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.490397046153846 ], [ 29.718532241379311, -1.490666523076923 ], [ 29.718801724137933, -1.490666523076923 ], [ 29.718801724137933, -1.490397046153846 ], [ 29.718532241379311, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2248, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.490397046153846 ], [ 29.718801724137933, -1.490666523076923 ], [ 29.719071206896551, -1.490666523076923 ], [ 29.719071206896551, -1.490397046153846 ], [ 29.718801724137933, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2249, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.490397046153846 ], [ 29.719071206896551, -1.490666523076923 ], [ 29.719340689655173, -1.490666523076923 ], [ 29.719340689655173, -1.490397046153846 ], [ 29.719071206896551, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2250, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.490397046153846 ], [ 29.719340689655173, -1.490666523076923 ], [ 29.719610172413795, -1.490666523076923 ], [ 29.719610172413795, -1.490397046153846 ], [ 29.719340689655173, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2251, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.490397046153846 ], [ 29.719610172413795, -1.490666523076923 ], [ 29.719879655172413, -1.490666523076923 ], [ 29.719879655172413, -1.490397046153846 ], [ 29.719610172413795, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2252, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.490397046153846 ], [ 29.719879655172413, -1.490666523076923 ], [ 29.720149137931035, -1.490666523076923 ], [ 29.720149137931035, -1.490397046153846 ], [ 29.719879655172413, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2253, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.490397046153846 ], [ 29.720149137931035, -1.490666523076923 ], [ 29.720688103448275, -1.490666523076923 ], [ 29.720688103448275, -1.490397046153846 ], [ 29.720149137931035, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2254, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.490397046153846 ], [ 29.720688103448275, -1.490666523076923 ], [ 29.720957586206897, -1.490666523076923 ], [ 29.720957586206897, -1.490397046153846 ], [ 29.720688103448275, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2255, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.490397046153846 ], [ 29.720957586206897, -1.490666523076923 ], [ 29.721227068965518, -1.490666523076923 ], [ 29.721227068965518, -1.490397046153846 ], [ 29.720957586206897, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2256, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.490397046153846 ], [ 29.721227068965518, -1.490666523076923 ], [ 29.721496551724137, -1.490666523076923 ], [ 29.721496551724137, -1.490397046153846 ], [ 29.721227068965518, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2257, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.490397046153846 ], [ 29.721496551724137, -1.490666523076923 ], [ 29.721766034482759, -1.490666523076923 ], [ 29.721766034482759, -1.490397046153846 ], [ 29.721496551724137, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2258, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.490397046153846 ], [ 29.721766034482759, -1.490666523076923 ], [ 29.72203551724138, -1.490666523076923 ], [ 29.72203551724138, -1.490397046153846 ], [ 29.721766034482759, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2259, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.490397046153846 ], [ 29.72203551724138, -1.490666523076923 ], [ 29.722305, -1.490666523076923 ], [ 29.722305, -1.490397046153846 ], [ 29.72203551724138, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2260, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.490397046153846 ], [ 29.722305, -1.490666523076923 ], [ 29.72257448275862, -1.490666523076923 ], [ 29.72257448275862, -1.490397046153846 ], [ 29.722305, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2261, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.490397046153846 ], [ 29.72257448275862, -1.490666523076923 ], [ 29.722843965517242, -1.490666523076923 ], [ 29.722843965517242, -1.490397046153846 ], [ 29.72257448275862, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2262, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.490397046153846 ], [ 29.722843965517242, -1.490666523076923 ], [ 29.723113448275861, -1.490666523076923 ], [ 29.723113448275861, -1.490397046153846 ], [ 29.722843965517242, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2263, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.490397046153846 ], [ 29.723113448275861, -1.490666523076923 ], [ 29.723382931034482, -1.490666523076923 ], [ 29.723382931034482, -1.490397046153846 ], [ 29.723113448275861, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2264, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.490397046153846 ], [ 29.723382931034482, -1.490666523076923 ], [ 29.723652413793104, -1.490666523076923 ], [ 29.723652413793104, -1.490397046153846 ], [ 29.723382931034482, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2265, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.490397046153846 ], [ 29.723652413793104, -1.490666523076923 ], [ 29.723921896551722, -1.490666523076923 ], [ 29.723921896551722, -1.490397046153846 ], [ 29.723652413793104, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2266, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.490397046153846 ], [ 29.723921896551722, -1.490666523076923 ], [ 29.724730344827588, -1.490666523076923 ], [ 29.724730344827588, -1.490397046153846 ], [ 29.723921896551722, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2267, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.490397046153846 ], [ 29.724730344827588, -1.490666523076923 ], [ 29.724999827586206, -1.490666523076923 ], [ 29.724999827586206, -1.490397046153846 ], [ 29.724730344827588, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2268, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.490397046153846 ], [ 29.724999827586206, -1.490666523076923 ], [ 29.725269310344828, -1.490666523076923 ], [ 29.725269310344828, -1.490397046153846 ], [ 29.724999827586206, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2269, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.490397046153846 ], [ 29.725269310344828, -1.490666523076923 ], [ 29.72553879310345, -1.490666523076923 ], [ 29.72553879310345, -1.490397046153846 ], [ 29.725269310344828, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2270, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.490397046153846 ], [ 29.72553879310345, -1.490666523076923 ], [ 29.725808275862068, -1.490666523076923 ], [ 29.725808275862068, -1.490397046153846 ], [ 29.72553879310345, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2271, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.490397046153846 ], [ 29.725808275862068, -1.490666523076923 ], [ 29.72607775862069, -1.490666523076923 ], [ 29.72607775862069, -1.490397046153846 ], [ 29.725808275862068, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2272, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.490397046153846 ], [ 29.72607775862069, -1.490666523076923 ], [ 29.726347241379312, -1.490666523076923 ], [ 29.726347241379312, -1.490397046153846 ], [ 29.72607775862069, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2273, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.490397046153846 ], [ 29.726347241379312, -1.490666523076923 ], [ 29.72661672413793, -1.490666523076923 ], [ 29.72661672413793, -1.490397046153846 ], [ 29.726347241379312, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2274, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.490397046153846 ], [ 29.72661672413793, -1.490666523076923 ], [ 29.726886206896552, -1.490666523076923 ], [ 29.726886206896552, -1.490397046153846 ], [ 29.72661672413793, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2275, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.490397046153846 ], [ 29.726886206896552, -1.490666523076923 ], [ 29.727155689655174, -1.490666523076923 ], [ 29.727155689655174, -1.490397046153846 ], [ 29.726886206896552, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2276, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.490397046153846 ], [ 29.727155689655174, -1.490666523076923 ], [ 29.727425172413792, -1.490666523076923 ], [ 29.727425172413792, -1.490397046153846 ], [ 29.727155689655174, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2277, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.490397046153846 ], [ 29.727425172413792, -1.490666523076923 ], [ 29.727964137931036, -1.490666523076923 ], [ 29.727964137931036, -1.490397046153846 ], [ 29.727425172413792, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2278, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.490397046153846 ], [ 29.727964137931036, -1.490666523076923 ], [ 29.729311551724138, -1.490666523076923 ], [ 29.729311551724138, -1.490397046153846 ], [ 29.727964137931036, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2279, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.490397046153846 ], [ 29.729311551724138, -1.490666523076923 ], [ 29.73012, -1.490666523076923 ], [ 29.73012, -1.490397046153846 ], [ 29.729311551724138, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2280, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.490397046153846 ], [ 29.73012, -1.490666523076923 ], [ 29.730389482758621, -1.490666523076923 ], [ 29.730389482758621, -1.490397046153846 ], [ 29.73012, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2281, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.490397046153846 ], [ 29.730658965517243, -1.490666523076923 ], [ 29.730928448275861, -1.490666523076923 ], [ 29.730928448275861, -1.490397046153846 ], [ 29.730658965517243, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2282, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.490127569230769 ], [ 29.731467413793105, -1.490127569230769 ], [ 29.731467413793105, -1.490397046153846 ], [ 29.731736896551723, -1.490397046153846 ], [ 29.731736896551723, -1.490666523076923 ], [ 29.730928448275861, -1.490666523076923 ], [ 29.730928448275861, -1.490397046153846 ], [ 29.731197931034483, -1.490397046153846 ], [ 29.731197931034483, -1.490127569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2283, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.490397046153846 ], [ 29.731736896551723, -1.490666523076923 ], [ 29.732275862068967, -1.490666523076923 ], [ 29.732275862068967, -1.490397046153846 ], [ 29.731736896551723, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2284, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.490397046153846 ], [ 29.732275862068967, -1.490666523076923 ], [ 29.732545344827585, -1.490666523076923 ], [ 29.732545344827585, -1.490397046153846 ], [ 29.732275862068967, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2285, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.490397046153846 ], [ 29.732545344827585, -1.490666523076923 ], [ 29.732814827586207, -1.490666523076923 ], [ 29.732814827586207, -1.490397046153846 ], [ 29.732545344827585, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2286, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.490397046153846 ], [ 29.732814827586207, -1.490666523076923 ], [ 29.733084310344829, -1.490666523076923 ], [ 29.733084310344829, -1.490397046153846 ], [ 29.732814827586207, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2287, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.490397046153846 ], [ 29.733084310344829, -1.490666523076923 ], [ 29.733353793103447, -1.490666523076923 ], [ 29.733353793103447, -1.490397046153846 ], [ 29.733084310344829, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2288, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.490397046153846 ], [ 29.733353793103447, -1.490666523076923 ], [ 29.733623275862069, -1.490666523076923 ], [ 29.733623275862069, -1.490397046153846 ], [ 29.733353793103447, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2289, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.490397046153846 ], [ 29.733623275862069, -1.490666523076923 ], [ 29.733892758620691, -1.490666523076923 ], [ 29.733892758620691, -1.490397046153846 ], [ 29.733623275862069, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2290, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.490397046153846 ], [ 29.733892758620691, -1.490666523076923 ], [ 29.734162241379309, -1.490666523076923 ], [ 29.734162241379309, -1.490397046153846 ], [ 29.733892758620691, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2291, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.490397046153846 ], [ 29.734162241379309, -1.490666523076923 ], [ 29.734431724137931, -1.490666523076923 ], [ 29.734431724137931, -1.490397046153846 ], [ 29.734162241379309, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2292, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.490397046153846 ], [ 29.734431724137931, -1.490666523076923 ], [ 29.734701206896553, -1.490666523076923 ], [ 29.734701206896553, -1.490397046153846 ], [ 29.734431724137931, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2293, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.490397046153846 ], [ 29.734701206896553, -1.490666523076923 ], [ 29.734970689655171, -1.490666523076923 ], [ 29.734970689655171, -1.490397046153846 ], [ 29.734701206896553, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2294, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.490397046153846 ], [ 29.734970689655171, -1.490666523076923 ], [ 29.735240172413793, -1.490666523076923 ], [ 29.735240172413793, -1.490397046153846 ], [ 29.734970689655171, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2295, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.490397046153846 ], [ 29.735240172413793, -1.490666523076923 ], [ 29.735509655172415, -1.490666523076923 ], [ 29.735509655172415, -1.490397046153846 ], [ 29.735240172413793, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2296, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.490397046153846 ], [ 29.735509655172415, -1.490666523076923 ], [ 29.735779137931036, -1.490666523076923 ], [ 29.735779137931036, -1.490397046153846 ], [ 29.735509655172415, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2297, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.490397046153846 ], [ 29.735779137931036, -1.490666523076923 ], [ 29.736048620689655, -1.490666523076923 ], [ 29.736048620689655, -1.490397046153846 ], [ 29.735779137931036, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2298, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.490397046153846 ], [ 29.736048620689655, -1.490666523076923 ], [ 29.736587586206898, -1.490666523076923 ], [ 29.736587586206898, -1.490397046153846 ], [ 29.736048620689655, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2299, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.490397046153846 ], [ 29.736587586206898, -1.490666523076923 ], [ 29.736857068965517, -1.490666523076923 ], [ 29.736857068965517, -1.490397046153846 ], [ 29.736587586206898, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2300, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.490397046153846 ], [ 29.736857068965517, -1.490666523076923 ], [ 29.737126551724138, -1.490666523076923 ], [ 29.737126551724138, -1.490397046153846 ], [ 29.736857068965517, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2301, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.490397046153846 ], [ 29.737126551724138, -1.490666523076923 ], [ 29.73739603448276, -1.490666523076923 ], [ 29.73739603448276, -1.490397046153846 ], [ 29.737126551724138, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2302, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.490397046153846 ], [ 29.73739603448276, -1.490666523076923 ], [ 29.737665517241378, -1.490666523076923 ], [ 29.737665517241378, -1.490397046153846 ], [ 29.73739603448276, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2303, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.490397046153846 ], [ 29.737665517241378, -1.490666523076923 ], [ 29.737935, -1.490666523076923 ], [ 29.737935, -1.490397046153846 ], [ 29.737665517241378, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2304, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.490397046153846 ], [ 29.737935, -1.490666523076923 ], [ 29.738204482758622, -1.490666523076923 ], [ 29.738204482758622, -1.490397046153846 ], [ 29.737935, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2305, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.490397046153846 ], [ 29.738204482758622, -1.490666523076923 ], [ 29.738743448275862, -1.490666523076923 ], [ 29.738743448275862, -1.490397046153846 ], [ 29.738204482758622, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2306, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.490397046153846 ], [ 29.738743448275862, -1.490666523076923 ], [ 29.739012931034484, -1.490666523076923 ], [ 29.739012931034484, -1.490397046153846 ], [ 29.738743448275862, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2307, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.490397046153846 ], [ 29.739012931034484, -1.490666523076923 ], [ 29.739282413793102, -1.490666523076923 ], [ 29.739282413793102, -1.490397046153846 ], [ 29.739012931034484, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2308, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.490397046153846 ], [ 29.739282413793102, -1.490666523076923 ], [ 29.739551896551724, -1.490666523076923 ], [ 29.739551896551724, -1.490397046153846 ], [ 29.739282413793102, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2309, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.490397046153846 ], [ 29.739551896551724, -1.490666523076923 ], [ 29.739821379310346, -1.490666523076923 ], [ 29.739821379310346, -1.490397046153846 ], [ 29.739551896551724, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2310, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.490397046153846 ], [ 29.739821379310346, -1.490666523076923 ], [ 29.740090862068964, -1.490666523076923 ], [ 29.740090862068964, -1.490397046153846 ], [ 29.739821379310346, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2311, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.490397046153846 ], [ 29.740090862068964, -1.490666523076923 ], [ 29.740629827586208, -1.490666523076923 ], [ 29.740629827586208, -1.490397046153846 ], [ 29.740090862068964, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2312, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.490397046153846 ], [ 29.740629827586208, -1.490666523076923 ], [ 29.742246724137932, -1.490666523076923 ], [ 29.742246724137932, -1.490397046153846 ], [ 29.740629827586208, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2313, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.490397046153846 ], [ 29.742246724137932, -1.490666523076923 ], [ 29.742516206896553, -1.490666523076923 ], [ 29.742516206896553, -1.490397046153846 ], [ 29.742246724137932, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2314, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.490397046153846 ], [ 29.742516206896553, -1.490666523076923 ], [ 29.743324655172415, -1.490666523076923 ], [ 29.743324655172415, -1.490397046153846 ], [ 29.742516206896553, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2315, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.490397046153846 ], [ 29.743324655172415, -1.490666523076923 ], [ 29.743594137931034, -1.490666523076923 ], [ 29.743594137931034, -1.490397046153846 ], [ 29.743324655172415, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2316, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.490397046153846 ], [ 29.743594137931034, -1.490666523076923 ], [ 29.744133103448277, -1.490666523076923 ], [ 29.744133103448277, -1.490397046153846 ], [ 29.743594137931034, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2317, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.490397046153846 ], [ 29.744133103448277, -1.490666523076923 ], [ 29.744941551724139, -1.490666523076923 ], [ 29.744941551724139, -1.490397046153846 ], [ 29.744133103448277, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2318, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.490397046153846 ], [ 29.744941551724139, -1.490666523076923 ], [ 29.745211034482757, -1.490666523076923 ], [ 29.745211034482757, -1.490397046153846 ], [ 29.744941551724139, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2319, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.490397046153846 ], [ 29.745211034482757, -1.490666523076923 ], [ 29.746019482758619, -1.490666523076923 ], [ 29.746019482758619, -1.490397046153846 ], [ 29.745211034482757, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2320, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.490397046153846 ], [ 29.746019482758619, -1.490666523076923 ], [ 29.746288965517241, -1.490666523076923 ], [ 29.746288965517241, -1.490397046153846 ], [ 29.746019482758619, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2321, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.490397046153846 ], [ 29.746288965517241, -1.490666523076923 ], [ 29.748444827586209, -1.490666523076923 ], [ 29.748444827586209, -1.490397046153846 ], [ 29.746288965517241, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2322, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.490397046153846 ], [ 29.748444827586209, -1.490666523076923 ], [ 29.748714310344827, -1.490666523076923 ], [ 29.748714310344827, -1.490397046153846 ], [ 29.748444827586209, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2323, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.490397046153846 ], [ 29.748714310344827, -1.490666523076923 ], [ 29.748983793103449, -1.490666523076923 ], [ 29.748983793103449, -1.490397046153846 ], [ 29.748714310344827, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2324, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.490397046153846 ], [ 29.748983793103449, -1.490666523076923 ], [ 29.74925327586207, -1.490666523076923 ], [ 29.74925327586207, -1.490397046153846 ], [ 29.748983793103449, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2325, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.490397046153846 ], [ 29.74925327586207, -1.490666523076923 ], [ 29.749522758620689, -1.490666523076923 ], [ 29.749522758620689, -1.490397046153846 ], [ 29.74925327586207, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2326, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.490397046153846 ], [ 29.749522758620689, -1.490666523076923 ], [ 29.749792241379311, -1.490666523076923 ], [ 29.749792241379311, -1.490397046153846 ], [ 29.749522758620689, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2327, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.490397046153846 ], [ 29.749792241379311, -1.490666523076923 ], [ 29.750061724137932, -1.490666523076923 ], [ 29.750061724137932, -1.490397046153846 ], [ 29.749792241379311, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2328, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.490397046153846 ], [ 29.750061724137932, -1.490666523076923 ], [ 29.750331206896551, -1.490666523076923 ], [ 29.750331206896551, -1.490397046153846 ], [ 29.750061724137932, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2329, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.490397046153846 ], [ 29.750331206896551, -1.490666523076923 ], [ 29.750600689655172, -1.490666523076923 ], [ 29.750600689655172, -1.490397046153846 ], [ 29.750331206896551, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2330, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.490397046153846 ], [ 29.750600689655172, -1.490666523076923 ], [ 29.750870172413794, -1.490666523076923 ], [ 29.750870172413794, -1.490397046153846 ], [ 29.750600689655172, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2331, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.490397046153846 ], [ 29.750870172413794, -1.490666523076923 ], [ 29.751139655172413, -1.490666523076923 ], [ 29.751139655172413, -1.490397046153846 ], [ 29.750870172413794, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2332, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.490397046153846 ], [ 29.751139655172413, -1.490666523076923 ], [ 29.751409137931034, -1.490666523076923 ], [ 29.751409137931034, -1.490397046153846 ], [ 29.751139655172413, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2333, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.490397046153846 ], [ 29.751409137931034, -1.490666523076923 ], [ 29.751678620689656, -1.490666523076923 ], [ 29.751678620689656, -1.490936 ], [ 29.752217586206896, -1.490936 ], [ 29.752217586206896, -1.490666523076923 ], [ 29.751948103448278, -1.490666523076923 ], [ 29.751948103448278, -1.490397046153846 ], [ 29.751409137931034, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2334, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.490397046153846 ], [ 29.751948103448278, -1.490666523076923 ], [ 29.752217586206896, -1.490666523076923 ], [ 29.752217586206896, -1.490397046153846 ], [ 29.751948103448278, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2335, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.490397046153846 ], [ 29.752217586206896, -1.490666523076923 ], [ 29.752487068965518, -1.490666523076923 ], [ 29.752487068965518, -1.490397046153846 ], [ 29.752217586206896, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2336, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.490397046153846 ], [ 29.752487068965518, -1.490666523076923 ], [ 29.75275655172414, -1.490666523076923 ], [ 29.75275655172414, -1.490397046153846 ], [ 29.752487068965518, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2337, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.490397046153846 ], [ 29.75275655172414, -1.490666523076923 ], [ 29.753026034482758, -1.490666523076923 ], [ 29.753026034482758, -1.490397046153846 ], [ 29.75275655172414, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2338, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.490397046153846 ], [ 29.753026034482758, -1.490666523076923 ], [ 29.75329551724138, -1.490666523076923 ], [ 29.75329551724138, -1.490397046153846 ], [ 29.753026034482758, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2339, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.490397046153846 ], [ 29.75329551724138, -1.490936 ], [ 29.753565, -1.490936 ], [ 29.753565, -1.490397046153846 ], [ 29.75329551724138, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2340, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.490397046153846 ], [ 29.753565, -1.490936 ], [ 29.75383448275862, -1.490936 ], [ 29.75383448275862, -1.490397046153846 ], [ 29.753565, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2341, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.490397046153846 ], [ 29.754373448275864, -1.490666523076923 ], [ 29.754642931034482, -1.490666523076923 ], [ 29.754642931034482, -1.490397046153846 ], [ 29.754373448275864, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2342, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.490397046153846 ], [ 29.754642931034482, -1.490666523076923 ], [ 29.754912413793104, -1.490666523076923 ], [ 29.754912413793104, -1.490397046153846 ], [ 29.754642931034482, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2343, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.490397046153846 ], [ 29.754912413793104, -1.490666523076923 ], [ 29.755181896551726, -1.490666523076923 ], [ 29.755181896551726, -1.490397046153846 ], [ 29.754912413793104, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2344, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.490397046153846 ], [ 29.755451379310344, -1.490666523076923 ], [ 29.755720862068966, -1.490666523076923 ], [ 29.755720862068966, -1.490397046153846 ], [ 29.755451379310344, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2345, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.490397046153846 ], [ 29.755720862068966, -1.491205476923077 ], [ 29.755990344827588, -1.491205476923077 ], [ 29.755990344827588, -1.490397046153846 ], [ 29.755720862068966, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2346, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.490397046153846 ], [ 29.755990344827588, -1.491205476923077 ], [ 29.756259827586206, -1.491205476923077 ], [ 29.756259827586206, -1.490397046153846 ], [ 29.755990344827588, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2347, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.490666523076923 ], [ 29.717454310344827, -1.491205476923077 ], [ 29.717723793103449, -1.491205476923077 ], [ 29.717723793103449, -1.490666523076923 ], [ 29.717454310344827, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2348, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.490666523076923 ], [ 29.717723793103449, -1.491205476923077 ], [ 29.717993275862067, -1.491205476923077 ], [ 29.717993275862067, -1.490666523076923 ], [ 29.717723793103449, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2349, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.490666523076923 ], [ 29.717993275862067, -1.490936 ], [ 29.718262758620689, -1.490936 ], [ 29.718262758620689, -1.490666523076923 ], [ 29.717993275862067, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2350, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.490666523076923 ], [ 29.718262758620689, -1.490936 ], [ 29.718801724137933, -1.490936 ], [ 29.718801724137933, -1.490666523076923 ], [ 29.718262758620689, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2351, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.490666523076923 ], [ 29.718801724137933, -1.490936 ], [ 29.719071206896551, -1.490936 ], [ 29.719071206896551, -1.490666523076923 ], [ 29.718801724137933, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2352, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.490666523076923 ], [ 29.719071206896551, -1.490936 ], [ 29.719340689655173, -1.490936 ], [ 29.719340689655173, -1.490666523076923 ], [ 29.719071206896551, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2353, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.490666523076923 ], [ 29.719340689655173, -1.490936 ], [ 29.719610172413795, -1.490936 ], [ 29.719610172413795, -1.490666523076923 ], [ 29.719340689655173, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2354, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.490666523076923 ], [ 29.719610172413795, -1.490936 ], [ 29.719879655172413, -1.490936 ], [ 29.719879655172413, -1.490666523076923 ], [ 29.719610172413795, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2355, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.490666523076923 ], [ 29.719879655172413, -1.490936 ], [ 29.720149137931035, -1.490936 ], [ 29.720149137931035, -1.490666523076923 ], [ 29.719879655172413, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2356, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.490666523076923 ], [ 29.720149137931035, -1.490936 ], [ 29.720688103448275, -1.490936 ], [ 29.720688103448275, -1.490666523076923 ], [ 29.720149137931035, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2357, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.490666523076923 ], [ 29.720688103448275, -1.490936 ], [ 29.720957586206897, -1.490936 ], [ 29.720957586206897, -1.490666523076923 ], [ 29.720688103448275, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2358, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.490666523076923 ], [ 29.720957586206897, -1.490936 ], [ 29.721227068965518, -1.490936 ], [ 29.721227068965518, -1.490666523076923 ], [ 29.720957586206897, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2359, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.490666523076923 ], [ 29.721227068965518, -1.490936 ], [ 29.721496551724137, -1.490936 ], [ 29.721496551724137, -1.490666523076923 ], [ 29.721227068965518, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2360, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.490666523076923 ], [ 29.721496551724137, -1.490936 ], [ 29.721766034482759, -1.490936 ], [ 29.721766034482759, -1.490666523076923 ], [ 29.721496551724137, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2361, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.490666523076923 ], [ 29.721766034482759, -1.490936 ], [ 29.72203551724138, -1.490936 ], [ 29.72203551724138, -1.490666523076923 ], [ 29.721766034482759, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2362, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.490666523076923 ], [ 29.72203551724138, -1.490936 ], [ 29.722305, -1.490936 ], [ 29.722305, -1.490666523076923 ], [ 29.72203551724138, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2363, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.490666523076923 ], [ 29.722305, -1.490936 ], [ 29.72257448275862, -1.490936 ], [ 29.72257448275862, -1.490666523076923 ], [ 29.722305, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2364, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.490666523076923 ], [ 29.72257448275862, -1.490936 ], [ 29.722843965517242, -1.490936 ], [ 29.722843965517242, -1.490666523076923 ], [ 29.72257448275862, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2365, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.490666523076923 ], [ 29.722843965517242, -1.490936 ], [ 29.723113448275861, -1.490936 ], [ 29.723113448275861, -1.490666523076923 ], [ 29.722843965517242, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2366, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.490666523076923 ], [ 29.723113448275861, -1.490936 ], [ 29.723382931034482, -1.490936 ], [ 29.723382931034482, -1.490666523076923 ], [ 29.723113448275861, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2367, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.490666523076923 ], [ 29.723382931034482, -1.490936 ], [ 29.723652413793104, -1.490936 ], [ 29.723652413793104, -1.490666523076923 ], [ 29.723382931034482, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2368, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.490666523076923 ], [ 29.723652413793104, -1.490936 ], [ 29.723921896551722, -1.490936 ], [ 29.723921896551722, -1.490666523076923 ], [ 29.723652413793104, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2369, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.490666523076923 ], [ 29.723921896551722, -1.490936 ], [ 29.724191379310344, -1.490936 ], [ 29.724191379310344, -1.490666523076923 ], [ 29.723921896551722, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2370, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.490666523076923 ], [ 29.724191379310344, -1.490936 ], [ 29.724460862068966, -1.490936 ], [ 29.724460862068966, -1.490666523076923 ], [ 29.724191379310344, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2371, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.490666523076923 ], [ 29.724460862068966, -1.490936 ], [ 29.724730344827588, -1.490936 ], [ 29.724730344827588, -1.490666523076923 ], [ 29.724460862068966, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2372, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.490666523076923 ], [ 29.724730344827588, -1.490936 ], [ 29.724999827586206, -1.490936 ], [ 29.724999827586206, -1.490666523076923 ], [ 29.724730344827588, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2373, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.490666523076923 ], [ 29.724999827586206, -1.490936 ], [ 29.725269310344828, -1.490936 ], [ 29.725269310344828, -1.490666523076923 ], [ 29.724999827586206, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2374, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.490666523076923 ], [ 29.725269310344828, -1.490936 ], [ 29.72553879310345, -1.490936 ], [ 29.72553879310345, -1.490666523076923 ], [ 29.725269310344828, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2375, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.490666523076923 ], [ 29.72553879310345, -1.490936 ], [ 29.725808275862068, -1.490936 ], [ 29.725808275862068, -1.490666523076923 ], [ 29.72553879310345, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2376, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.490666523076923 ], [ 29.725808275862068, -1.490936 ], [ 29.72607775862069, -1.490936 ], [ 29.72607775862069, -1.490666523076923 ], [ 29.725808275862068, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2377, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.490666523076923 ], [ 29.72607775862069, -1.490936 ], [ 29.726347241379312, -1.490936 ], [ 29.726347241379312, -1.490666523076923 ], [ 29.72607775862069, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2378, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.490666523076923 ], [ 29.726347241379312, -1.490936 ], [ 29.72661672413793, -1.490936 ], [ 29.72661672413793, -1.490666523076923 ], [ 29.726347241379312, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2379, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.490666523076923 ], [ 29.72661672413793, -1.490936 ], [ 29.726886206896552, -1.490936 ], [ 29.726886206896552, -1.490666523076923 ], [ 29.72661672413793, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2380, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.490666523076923 ], [ 29.726886206896552, -1.490936 ], [ 29.727155689655174, -1.490936 ], [ 29.727155689655174, -1.490666523076923 ], [ 29.726886206896552, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2381, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.490666523076923 ], [ 29.727155689655174, -1.490936 ], [ 29.727425172413792, -1.490936 ], [ 29.727425172413792, -1.490666523076923 ], [ 29.727155689655174, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2382, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.490397046153846 ], [ 29.730658965517243, -1.490397046153846 ], [ 29.730658965517243, -1.490666523076923 ], [ 29.732006379310345, -1.490666523076923 ], [ 29.732006379310345, -1.490936 ], [ 29.729042068965516, -1.490936 ], [ 29.729042068965516, -1.491205476923077 ], [ 29.728772586206897, -1.491205476923077 ], [ 29.728772586206897, -1.490936 ], [ 29.727425172413792, -1.490936 ], [ 29.727425172413792, -1.490666523076923 ], [ 29.730389482758621, -1.490666523076923 ], [ 29.730389482758621, -1.490397046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2383, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.490666523076923 ], [ 29.732006379310345, -1.490936 ], [ 29.732275862068967, -1.490936 ], [ 29.732275862068967, -1.490666523076923 ], [ 29.732006379310345, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2384, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.490666523076923 ], [ 29.732275862068967, -1.490936 ], [ 29.732545344827585, -1.490936 ], [ 29.732545344827585, -1.490666523076923 ], [ 29.732275862068967, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2385, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.490666523076923 ], [ 29.732545344827585, -1.490936 ], [ 29.732814827586207, -1.490936 ], [ 29.732814827586207, -1.490666523076923 ], [ 29.732545344827585, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2386, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.490666523076923 ], [ 29.732814827586207, -1.490936 ], [ 29.733084310344829, -1.490936 ], [ 29.733084310344829, -1.490666523076923 ], [ 29.732814827586207, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2387, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.490666523076923 ], [ 29.733084310344829, -1.490936 ], [ 29.733353793103447, -1.490936 ], [ 29.733353793103447, -1.490666523076923 ], [ 29.733084310344829, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2388, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.490666523076923 ], [ 29.733353793103447, -1.490936 ], [ 29.733623275862069, -1.490936 ], [ 29.733623275862069, -1.490666523076923 ], [ 29.733353793103447, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2389, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.490666523076923 ], [ 29.733623275862069, -1.490936 ], [ 29.733892758620691, -1.490936 ], [ 29.733892758620691, -1.490666523076923 ], [ 29.733623275862069, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2390, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.490666523076923 ], [ 29.733892758620691, -1.490936 ], [ 29.734162241379309, -1.490936 ], [ 29.734162241379309, -1.490666523076923 ], [ 29.733892758620691, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2391, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.490666523076923 ], [ 29.734162241379309, -1.490936 ], [ 29.734431724137931, -1.490936 ], [ 29.734431724137931, -1.490666523076923 ], [ 29.734162241379309, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2392, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.490666523076923 ], [ 29.734431724137931, -1.490936 ], [ 29.734701206896553, -1.490936 ], [ 29.734701206896553, -1.490666523076923 ], [ 29.734431724137931, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2393, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.490666523076923 ], [ 29.734701206896553, -1.490936 ], [ 29.734970689655171, -1.490936 ], [ 29.734970689655171, -1.490666523076923 ], [ 29.734701206896553, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2394, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.490666523076923 ], [ 29.734970689655171, -1.490936 ], [ 29.735240172413793, -1.490936 ], [ 29.735240172413793, -1.490666523076923 ], [ 29.734970689655171, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2395, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.490666523076923 ], [ 29.735240172413793, -1.490936 ], [ 29.735509655172415, -1.490936 ], [ 29.735509655172415, -1.490666523076923 ], [ 29.735240172413793, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2396, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.490666523076923 ], [ 29.735509655172415, -1.490936 ], [ 29.735779137931036, -1.490936 ], [ 29.735779137931036, -1.490666523076923 ], [ 29.735509655172415, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2397, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.490666523076923 ], [ 29.735779137931036, -1.490936 ], [ 29.736048620689655, -1.490936 ], [ 29.736048620689655, -1.490666523076923 ], [ 29.735779137931036, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2398, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.490666523076923 ], [ 29.736048620689655, -1.490936 ], [ 29.736318103448276, -1.490936 ], [ 29.736318103448276, -1.490666523076923 ], [ 29.736048620689655, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2399, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.490666523076923 ], [ 29.736318103448276, -1.490936 ], [ 29.736587586206898, -1.490936 ], [ 29.736587586206898, -1.490666523076923 ], [ 29.736318103448276, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2400, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.490666523076923 ], [ 29.736587586206898, -1.490936 ], [ 29.736857068965517, -1.490936 ], [ 29.736857068965517, -1.490666523076923 ], [ 29.736587586206898, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2401, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.490666523076923 ], [ 29.736857068965517, -1.490936 ], [ 29.737126551724138, -1.490936 ], [ 29.737126551724138, -1.490666523076923 ], [ 29.736857068965517, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2402, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.490666523076923 ], [ 29.737126551724138, -1.490936 ], [ 29.73739603448276, -1.490936 ], [ 29.73739603448276, -1.490666523076923 ], [ 29.737126551724138, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2403, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.490666523076923 ], [ 29.73739603448276, -1.490936 ], [ 29.737665517241378, -1.490936 ], [ 29.737665517241378, -1.490666523076923 ], [ 29.73739603448276, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2404, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.490666523076923 ], [ 29.737665517241378, -1.490936 ], [ 29.737935, -1.490936 ], [ 29.737935, -1.490666523076923 ], [ 29.737665517241378, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2405, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.490666523076923 ], [ 29.737935, -1.490936 ], [ 29.738204482758622, -1.490936 ], [ 29.738204482758622, -1.490666523076923 ], [ 29.737935, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2406, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.490666523076923 ], [ 29.738204482758622, -1.490936 ], [ 29.738743448275862, -1.490936 ], [ 29.738743448275862, -1.490666523076923 ], [ 29.738204482758622, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2407, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.490666523076923 ], [ 29.738743448275862, -1.490936 ], [ 29.739012931034484, -1.490936 ], [ 29.739012931034484, -1.490666523076923 ], [ 29.738743448275862, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2408, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.490666523076923 ], [ 29.739012931034484, -1.490936 ], [ 29.739282413793102, -1.490936 ], [ 29.739282413793102, -1.490666523076923 ], [ 29.739012931034484, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2409, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.490666523076923 ], [ 29.739282413793102, -1.490936 ], [ 29.739551896551724, -1.490936 ], [ 29.739551896551724, -1.490666523076923 ], [ 29.739282413793102, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2410, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.490666523076923 ], [ 29.739551896551724, -1.490936 ], [ 29.739821379310346, -1.490936 ], [ 29.739821379310346, -1.490666523076923 ], [ 29.739551896551724, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2411, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.490666523076923 ], [ 29.739821379310346, -1.490936 ], [ 29.740090862068964, -1.490936 ], [ 29.740090862068964, -1.490666523076923 ], [ 29.739821379310346, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2412, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.490666523076923 ], [ 29.740090862068964, -1.490936 ], [ 29.740360344827586, -1.490936 ], [ 29.740360344827586, -1.490666523076923 ], [ 29.740090862068964, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2413, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.490666523076923 ], [ 29.740360344827586, -1.490936 ], [ 29.742246724137932, -1.490936 ], [ 29.742246724137932, -1.490666523076923 ], [ 29.740360344827586, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2414, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.490666523076923 ], [ 29.742246724137932, -1.490936 ], [ 29.742516206896553, -1.490936 ], [ 29.742516206896553, -1.490666523076923 ], [ 29.742246724137932, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2415, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.490666523076923 ], [ 29.742516206896553, -1.490936 ], [ 29.743055172413793, -1.490936 ], [ 29.743055172413793, -1.490666523076923 ], [ 29.742516206896553, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2416, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.490666523076923 ], [ 29.743055172413793, -1.490936 ], [ 29.743594137931034, -1.490936 ], [ 29.743594137931034, -1.490666523076923 ], [ 29.743055172413793, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2417, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.490666523076923 ], [ 29.743594137931034, -1.490936 ], [ 29.743863620689655, -1.490936 ], [ 29.743863620689655, -1.490666523076923 ], [ 29.743594137931034, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2418, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.490666523076923 ], [ 29.743863620689655, -1.490936 ], [ 29.744941551724139, -1.490936 ], [ 29.744941551724139, -1.490666523076923 ], [ 29.743863620689655, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2419, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.490666523076923 ], [ 29.744941551724139, -1.490936 ], [ 29.745211034482757, -1.490936 ], [ 29.745211034482757, -1.490666523076923 ], [ 29.744941551724139, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2420, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.490666523076923 ], [ 29.745211034482757, -1.490936 ], [ 29.746019482758619, -1.490936 ], [ 29.746019482758619, -1.490666523076923 ], [ 29.745211034482757, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2421, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746019482758619, -1.490666523076923 ], [ 29.746019482758619, -1.490936 ], [ 29.746288965517241, -1.490936 ], [ 29.746288965517241, -1.490666523076923 ], [ 29.746019482758619, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2422, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.490666523076923 ], [ 29.746288965517241, -1.490936 ], [ 29.746558448275863, -1.490936 ], [ 29.746558448275863, -1.490666523076923 ], [ 29.746288965517241, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2423, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.490666523076923 ], [ 29.746558448275863, -1.490936 ], [ 29.748175344827587, -1.490936 ], [ 29.748175344827587, -1.490666523076923 ], [ 29.746558448275863, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2424, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.490666523076923 ], [ 29.748175344827587, -1.490936 ], [ 29.748444827586209, -1.490936 ], [ 29.748444827586209, -1.490666523076923 ], [ 29.748175344827587, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2425, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.490666523076923 ], [ 29.748444827586209, -1.490936 ], [ 29.748714310344827, -1.490936 ], [ 29.748714310344827, -1.490666523076923 ], [ 29.748444827586209, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2426, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.490666523076923 ], [ 29.748714310344827, -1.490936 ], [ 29.748983793103449, -1.490936 ], [ 29.748983793103449, -1.490666523076923 ], [ 29.748714310344827, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2427, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.490666523076923 ], [ 29.748983793103449, -1.490936 ], [ 29.74925327586207, -1.490936 ], [ 29.74925327586207, -1.490666523076923 ], [ 29.748983793103449, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2428, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.490666523076923 ], [ 29.74925327586207, -1.490936 ], [ 29.749522758620689, -1.490936 ], [ 29.749522758620689, -1.490666523076923 ], [ 29.74925327586207, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2429, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.490666523076923 ], [ 29.749522758620689, -1.490936 ], [ 29.749792241379311, -1.490936 ], [ 29.749792241379311, -1.490666523076923 ], [ 29.749522758620689, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2430, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.490666523076923 ], [ 29.749792241379311, -1.490936 ], [ 29.750061724137932, -1.490936 ], [ 29.750061724137932, -1.490666523076923 ], [ 29.749792241379311, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2431, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.490666523076923 ], [ 29.750061724137932, -1.490936 ], [ 29.750331206896551, -1.490936 ], [ 29.750331206896551, -1.490666523076923 ], [ 29.750061724137932, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2432, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.490666523076923 ], [ 29.750331206896551, -1.490936 ], [ 29.750600689655172, -1.490936 ], [ 29.750600689655172, -1.490666523076923 ], [ 29.750331206896551, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2433, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.490666523076923 ], [ 29.750600689655172, -1.490936 ], [ 29.750870172413794, -1.490936 ], [ 29.750870172413794, -1.490666523076923 ], [ 29.750600689655172, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2434, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.490666523076923 ], [ 29.750870172413794, -1.490936 ], [ 29.751139655172413, -1.490936 ], [ 29.751139655172413, -1.490666523076923 ], [ 29.750870172413794, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2435, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.490666523076923 ], [ 29.751139655172413, -1.490936 ], [ 29.751409137931034, -1.490936 ], [ 29.751409137931034, -1.490666523076923 ], [ 29.751139655172413, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2436, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.490666523076923 ], [ 29.751409137931034, -1.490936 ], [ 29.751678620689656, -1.490936 ], [ 29.751678620689656, -1.490666523076923 ], [ 29.751409137931034, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2437, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.490666523076923 ], [ 29.752217586206896, -1.490936 ], [ 29.752487068965518, -1.490936 ], [ 29.752487068965518, -1.490666523076923 ], [ 29.752217586206896, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2438, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.490666523076923 ], [ 29.752487068965518, -1.490936 ], [ 29.75275655172414, -1.490936 ], [ 29.75275655172414, -1.490666523076923 ], [ 29.752487068965518, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2439, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.490666523076923 ], [ 29.75275655172414, -1.490936 ], [ 29.753026034482758, -1.490936 ], [ 29.753026034482758, -1.490666523076923 ], [ 29.75275655172414, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2440, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.490666523076923 ], [ 29.753026034482758, -1.491205476923077 ], [ 29.75329551724138, -1.491205476923077 ], [ 29.75329551724138, -1.490666523076923 ], [ 29.753026034482758, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2441, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.490666523076923 ], [ 29.75383448275862, -1.490936 ], [ 29.754103965517242, -1.490936 ], [ 29.754103965517242, -1.490666523076923 ], [ 29.75383448275862, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2442, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.490666523076923 ], [ 29.754103965517242, -1.490936 ], [ 29.754373448275864, -1.490936 ], [ 29.754373448275864, -1.490666523076923 ], [ 29.754103965517242, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2443, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.490666523076923 ], [ 29.754373448275864, -1.490936 ], [ 29.754642931034482, -1.490936 ], [ 29.754642931034482, -1.490666523076923 ], [ 29.754373448275864, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2444, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.490666523076923 ], [ 29.754642931034482, -1.491205476923077 ], [ 29.754912413793104, -1.491205476923077 ], [ 29.754912413793104, -1.490666523076923 ], [ 29.754642931034482, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2445, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.490666523076923 ], [ 29.754912413793104, -1.491205476923077 ], [ 29.755181896551726, -1.491205476923077 ], [ 29.755181896551726, -1.490666523076923 ], [ 29.754912413793104, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2446, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.490666523076923 ], [ 29.755181896551726, -1.491205476923077 ], [ 29.755451379310344, -1.491205476923077 ], [ 29.755451379310344, -1.490666523076923 ], [ 29.755181896551726, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2447, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.490666523076923 ], [ 29.755451379310344, -1.490936 ], [ 29.755720862068966, -1.490936 ], [ 29.755720862068966, -1.490666523076923 ], [ 29.755451379310344, -1.490666523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2448, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.490936 ], [ 29.716915344827587, -1.491205476923077 ], [ 29.717184827586205, -1.491205476923077 ], [ 29.717184827586205, -1.490936 ], [ 29.716915344827587, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2449, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.490936 ], [ 29.717184827586205, -1.491205476923077 ], [ 29.717454310344827, -1.491205476923077 ], [ 29.717454310344827, -1.490936 ], [ 29.717184827586205, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2450, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.490936 ], [ 29.717993275862067, -1.491474953846154 ], [ 29.718262758620689, -1.491474953846154 ], [ 29.718262758620689, -1.490936 ], [ 29.717993275862067, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2451, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.490936 ], [ 29.718262758620689, -1.491205476923077 ], [ 29.718532241379311, -1.491205476923077 ], [ 29.718532241379311, -1.490936 ], [ 29.718262758620689, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2452, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.490936 ], [ 29.718532241379311, -1.491205476923077 ], [ 29.718801724137933, -1.491205476923077 ], [ 29.718801724137933, -1.490936 ], [ 29.718532241379311, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2453, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.490936 ], [ 29.718801724137933, -1.491205476923077 ], [ 29.719071206896551, -1.491205476923077 ], [ 29.719071206896551, -1.490936 ], [ 29.718801724137933, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2454, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.490936 ], [ 29.719071206896551, -1.491205476923077 ], [ 29.719340689655173, -1.491205476923077 ], [ 29.719340689655173, -1.490936 ], [ 29.719071206896551, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2455, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.490936 ], [ 29.719340689655173, -1.491205476923077 ], [ 29.719610172413795, -1.491205476923077 ], [ 29.719610172413795, -1.490936 ], [ 29.719340689655173, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2456, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.490936 ], [ 29.719610172413795, -1.491205476923077 ], [ 29.719879655172413, -1.491205476923077 ], [ 29.719879655172413, -1.490936 ], [ 29.719610172413795, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2457, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.490936 ], [ 29.719879655172413, -1.491205476923077 ], [ 29.720149137931035, -1.491205476923077 ], [ 29.720149137931035, -1.490936 ], [ 29.719879655172413, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2458, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.490936 ], [ 29.720149137931035, -1.491205476923077 ], [ 29.720688103448275, -1.491205476923077 ], [ 29.720688103448275, -1.490936 ], [ 29.720149137931035, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2459, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.490936 ], [ 29.720688103448275, -1.491205476923077 ], [ 29.720957586206897, -1.491205476923077 ], [ 29.720957586206897, -1.490936 ], [ 29.720688103448275, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2460, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.490936 ], [ 29.720957586206897, -1.491205476923077 ], [ 29.721227068965518, -1.491205476923077 ], [ 29.721227068965518, -1.490936 ], [ 29.720957586206897, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2461, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.490936 ], [ 29.721227068965518, -1.491205476923077 ], [ 29.721496551724137, -1.491205476923077 ], [ 29.721496551724137, -1.490936 ], [ 29.721227068965518, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2462, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.490936 ], [ 29.721496551724137, -1.491205476923077 ], [ 29.721766034482759, -1.491205476923077 ], [ 29.721766034482759, -1.490936 ], [ 29.721496551724137, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2463, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.490936 ], [ 29.721766034482759, -1.491205476923077 ], [ 29.72203551724138, -1.491205476923077 ], [ 29.72203551724138, -1.490936 ], [ 29.721766034482759, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2464, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.490936 ], [ 29.72203551724138, -1.491205476923077 ], [ 29.722305, -1.491205476923077 ], [ 29.722305, -1.490936 ], [ 29.72203551724138, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2465, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.490936 ], [ 29.722305, -1.491205476923077 ], [ 29.72257448275862, -1.491205476923077 ], [ 29.72257448275862, -1.490936 ], [ 29.722305, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2466, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.490936 ], [ 29.72257448275862, -1.491205476923077 ], [ 29.722843965517242, -1.491205476923077 ], [ 29.722843965517242, -1.490936 ], [ 29.72257448275862, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2467, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.490936 ], [ 29.722843965517242, -1.491205476923077 ], [ 29.723113448275861, -1.491205476923077 ], [ 29.723113448275861, -1.490936 ], [ 29.722843965517242, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2468, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.490936 ], [ 29.723113448275861, -1.491205476923077 ], [ 29.723382931034482, -1.491205476923077 ], [ 29.723382931034482, -1.490936 ], [ 29.723113448275861, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2469, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.490936 ], [ 29.723382931034482, -1.491205476923077 ], [ 29.723652413793104, -1.491205476923077 ], [ 29.723652413793104, -1.490936 ], [ 29.723382931034482, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2470, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.490936 ], [ 29.723652413793104, -1.491205476923077 ], [ 29.723921896551722, -1.491205476923077 ], [ 29.723921896551722, -1.490936 ], [ 29.723652413793104, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2471, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.490936 ], [ 29.723921896551722, -1.491205476923077 ], [ 29.724191379310344, -1.491205476923077 ], [ 29.724191379310344, -1.490936 ], [ 29.723921896551722, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2472, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.490936 ], [ 29.724191379310344, -1.491205476923077 ], [ 29.724460862068966, -1.491205476923077 ], [ 29.724460862068966, -1.490936 ], [ 29.724191379310344, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2473, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.490936 ], [ 29.724460862068966, -1.491205476923077 ], [ 29.724999827586206, -1.491205476923077 ], [ 29.724999827586206, -1.490936 ], [ 29.724460862068966, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2474, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.490936 ], [ 29.724999827586206, -1.491205476923077 ], [ 29.725269310344828, -1.491205476923077 ], [ 29.725269310344828, -1.490936 ], [ 29.724999827586206, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2475, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.490936 ], [ 29.725269310344828, -1.491205476923077 ], [ 29.72553879310345, -1.491205476923077 ], [ 29.72553879310345, -1.490936 ], [ 29.725269310344828, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2476, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.490936 ], [ 29.72553879310345, -1.491205476923077 ], [ 29.725808275862068, -1.491205476923077 ], [ 29.725808275862068, -1.490936 ], [ 29.72553879310345, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2477, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.490936 ], [ 29.725808275862068, -1.491205476923077 ], [ 29.72607775862069, -1.491205476923077 ], [ 29.72607775862069, -1.490936 ], [ 29.725808275862068, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2478, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.490936 ], [ 29.72607775862069, -1.491205476923077 ], [ 29.726347241379312, -1.491205476923077 ], [ 29.726347241379312, -1.490936 ], [ 29.72607775862069, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2479, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.490936 ], [ 29.726347241379312, -1.491205476923077 ], [ 29.72661672413793, -1.491205476923077 ], [ 29.72661672413793, -1.490936 ], [ 29.726347241379312, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2480, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.490936 ], [ 29.72661672413793, -1.491205476923077 ], [ 29.726886206896552, -1.491205476923077 ], [ 29.726886206896552, -1.490936 ], [ 29.72661672413793, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2481, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.490936 ], [ 29.726886206896552, -1.491205476923077 ], [ 29.727155689655174, -1.491205476923077 ], [ 29.727155689655174, -1.490936 ], [ 29.726886206896552, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2482, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.490936 ], [ 29.727155689655174, -1.491205476923077 ], [ 29.727694655172414, -1.491205476923077 ], [ 29.727694655172414, -1.490936 ], [ 29.727155689655174, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2483, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.490936 ], [ 29.727694655172414, -1.491205476923077 ], [ 29.727964137931036, -1.491205476923077 ], [ 29.727964137931036, -1.491474953846154 ], [ 29.728233620689654, -1.491474953846154 ], [ 29.728233620689654, -1.491205476923077 ], [ 29.728503103448276, -1.491205476923077 ], [ 29.728503103448276, -1.490936 ], [ 29.727694655172414, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2484, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.490936 ], [ 29.728503103448276, -1.491205476923077 ], [ 29.728772586206897, -1.491205476923077 ], [ 29.728772586206897, -1.490936 ], [ 29.728503103448276, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2485, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.490936 ], [ 29.729042068965516, -1.491205476923077 ], [ 29.729311551724138, -1.491205476923077 ], [ 29.729311551724138, -1.490936 ], [ 29.729042068965516, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2486, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.490936 ], [ 29.729311551724138, -1.491205476923077 ], [ 29.73012, -1.491205476923077 ], [ 29.73012, -1.490936 ], [ 29.729311551724138, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2487, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.490936 ], [ 29.73012, -1.491205476923077 ], [ 29.731467413793105, -1.491205476923077 ], [ 29.731467413793105, -1.490936 ], [ 29.73012, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2488, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.490936 ], [ 29.731467413793105, -1.491205476923077 ], [ 29.732006379310345, -1.491205476923077 ], [ 29.732006379310345, -1.490936 ], [ 29.731467413793105, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2489, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.490936 ], [ 29.732006379310345, -1.491205476923077 ], [ 29.732275862068967, -1.491205476923077 ], [ 29.732275862068967, -1.490936 ], [ 29.732006379310345, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2490, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.490936 ], [ 29.732275862068967, -1.491205476923077 ], [ 29.732545344827585, -1.491205476923077 ], [ 29.732545344827585, -1.490936 ], [ 29.732275862068967, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2491, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.490936 ], [ 29.732545344827585, -1.491205476923077 ], [ 29.732814827586207, -1.491205476923077 ], [ 29.732814827586207, -1.490936 ], [ 29.732545344827585, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2492, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.490936 ], [ 29.732814827586207, -1.491205476923077 ], [ 29.733084310344829, -1.491205476923077 ], [ 29.733084310344829, -1.490936 ], [ 29.732814827586207, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2493, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.490936 ], [ 29.733084310344829, -1.491205476923077 ], [ 29.733353793103447, -1.491205476923077 ], [ 29.733353793103447, -1.490936 ], [ 29.733084310344829, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2494, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.490936 ], [ 29.733353793103447, -1.491205476923077 ], [ 29.733623275862069, -1.491205476923077 ], [ 29.733623275862069, -1.490936 ], [ 29.733353793103447, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2495, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.490936 ], [ 29.733623275862069, -1.491205476923077 ], [ 29.733892758620691, -1.491205476923077 ], [ 29.733892758620691, -1.490936 ], [ 29.733623275862069, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2496, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.490936 ], [ 29.733892758620691, -1.491205476923077 ], [ 29.734162241379309, -1.491205476923077 ], [ 29.734162241379309, -1.490936 ], [ 29.733892758620691, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2497, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.490936 ], [ 29.734162241379309, -1.491205476923077 ], [ 29.734431724137931, -1.491205476923077 ], [ 29.734431724137931, -1.490936 ], [ 29.734162241379309, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2498, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.490936 ], [ 29.734431724137931, -1.491205476923077 ], [ 29.734701206896553, -1.491205476923077 ], [ 29.734701206896553, -1.490936 ], [ 29.734431724137931, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2499, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.490936 ], [ 29.734701206896553, -1.491205476923077 ], [ 29.734970689655171, -1.491205476923077 ], [ 29.734970689655171, -1.490936 ], [ 29.734701206896553, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2500, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.490936 ], [ 29.734970689655171, -1.491205476923077 ], [ 29.735240172413793, -1.491205476923077 ], [ 29.735240172413793, -1.490936 ], [ 29.734970689655171, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2501, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.490936 ], [ 29.735240172413793, -1.491205476923077 ], [ 29.735509655172415, -1.491205476923077 ], [ 29.735509655172415, -1.490936 ], [ 29.735240172413793, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2502, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.490936 ], [ 29.735509655172415, -1.491205476923077 ], [ 29.735779137931036, -1.491205476923077 ], [ 29.735779137931036, -1.490936 ], [ 29.735509655172415, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2503, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.490936 ], [ 29.735779137931036, -1.491205476923077 ], [ 29.736048620689655, -1.491205476923077 ], [ 29.736048620689655, -1.490936 ], [ 29.735779137931036, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2504, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.490936 ], [ 29.736048620689655, -1.491205476923077 ], [ 29.736318103448276, -1.491205476923077 ], [ 29.736318103448276, -1.490936 ], [ 29.736048620689655, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2505, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.490936 ], [ 29.736318103448276, -1.491205476923077 ], [ 29.736587586206898, -1.491205476923077 ], [ 29.736587586206898, -1.490936 ], [ 29.736318103448276, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2506, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.490936 ], [ 29.736587586206898, -1.491205476923077 ], [ 29.736857068965517, -1.491205476923077 ], [ 29.736857068965517, -1.490936 ], [ 29.736587586206898, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2507, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.490936 ], [ 29.736857068965517, -1.491205476923077 ], [ 29.737126551724138, -1.491205476923077 ], [ 29.737126551724138, -1.490936 ], [ 29.736857068965517, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2508, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.490936 ], [ 29.737126551724138, -1.491205476923077 ], [ 29.73739603448276, -1.491205476923077 ], [ 29.73739603448276, -1.490936 ], [ 29.737126551724138, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2509, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.490936 ], [ 29.73739603448276, -1.491205476923077 ], [ 29.737665517241378, -1.491205476923077 ], [ 29.737665517241378, -1.490936 ], [ 29.73739603448276, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2510, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.490936 ], [ 29.737665517241378, -1.491205476923077 ], [ 29.737935, -1.491205476923077 ], [ 29.737935, -1.490936 ], [ 29.737665517241378, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2511, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.490936 ], [ 29.737935, -1.491205476923077 ], [ 29.738204482758622, -1.491205476923077 ], [ 29.738204482758622, -1.490936 ], [ 29.737935, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2512, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.490936 ], [ 29.738204482758622, -1.491205476923077 ], [ 29.738743448275862, -1.491205476923077 ], [ 29.738743448275862, -1.490936 ], [ 29.738204482758622, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2513, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.490936 ], [ 29.738743448275862, -1.491205476923077 ], [ 29.739012931034484, -1.491205476923077 ], [ 29.739012931034484, -1.490936 ], [ 29.738743448275862, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2514, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.490936 ], [ 29.739012931034484, -1.491205476923077 ], [ 29.739282413793102, -1.491205476923077 ], [ 29.739282413793102, -1.490936 ], [ 29.739012931034484, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2515, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.490936 ], [ 29.739282413793102, -1.491205476923077 ], [ 29.739551896551724, -1.491205476923077 ], [ 29.739551896551724, -1.490936 ], [ 29.739282413793102, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2516, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.490936 ], [ 29.739551896551724, -1.491205476923077 ], [ 29.739821379310346, -1.491205476923077 ], [ 29.739821379310346, -1.490936 ], [ 29.739551896551724, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2517, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.490936 ], [ 29.739821379310346, -1.491205476923077 ], [ 29.740090862068964, -1.491205476923077 ], [ 29.740090862068964, -1.490936 ], [ 29.739821379310346, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2518, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.490936 ], [ 29.740090862068964, -1.491205476923077 ], [ 29.740360344827586, -1.491205476923077 ], [ 29.740360344827586, -1.490936 ], [ 29.740090862068964, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2519, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.490936 ], [ 29.740360344827586, -1.491205476923077 ], [ 29.74089931034483, -1.491205476923077 ], [ 29.74089931034483, -1.490936 ], [ 29.740360344827586, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2520, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.490936 ], [ 29.74089931034483, -1.491205476923077 ], [ 29.742516206896553, -1.491205476923077 ], [ 29.742516206896553, -1.490936 ], [ 29.74089931034483, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2521, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.490936 ], [ 29.742516206896553, -1.491205476923077 ], [ 29.742785689655172, -1.491205476923077 ], [ 29.742785689655172, -1.490936 ], [ 29.742516206896553, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2522, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.490936 ], [ 29.742785689655172, -1.491205476923077 ], [ 29.743594137931034, -1.491205476923077 ], [ 29.743594137931034, -1.490936 ], [ 29.742785689655172, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2523, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.490936 ], [ 29.743594137931034, -1.491205476923077 ], [ 29.743863620689655, -1.491205476923077 ], [ 29.743863620689655, -1.490936 ], [ 29.743594137931034, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2524, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.490936 ], [ 29.743863620689655, -1.491205476923077 ], [ 29.744941551724139, -1.491205476923077 ], [ 29.744941551724139, -1.490936 ], [ 29.743863620689655, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2525, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.490936 ], [ 29.744941551724139, -1.491205476923077 ], [ 29.745211034482757, -1.491205476923077 ], [ 29.745211034482757, -1.490936 ], [ 29.744941551724139, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2526, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.490936 ], [ 29.745211034482757, -1.491205476923077 ], [ 29.745480517241379, -1.491205476923077 ], [ 29.745480517241379, -1.490936 ], [ 29.745211034482757, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2527, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.490936 ], [ 29.745480517241379, -1.491205476923077 ], [ 29.746288965517241, -1.491205476923077 ], [ 29.746288965517241, -1.490936 ], [ 29.745480517241379, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2528, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.490936 ], [ 29.746288965517241, -1.491205476923077 ], [ 29.746558448275863, -1.491205476923077 ], [ 29.746558448275863, -1.490936 ], [ 29.746288965517241, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2529, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.490936 ], [ 29.746558448275863, -1.491205476923077 ], [ 29.748175344827587, -1.491205476923077 ], [ 29.748175344827587, -1.490936 ], [ 29.746558448275863, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2530, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.490936 ], [ 29.748175344827587, -1.491205476923077 ], [ 29.748444827586209, -1.491205476923077 ], [ 29.748444827586209, -1.490936 ], [ 29.748175344827587, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2531, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.490936 ], [ 29.748444827586209, -1.491205476923077 ], [ 29.748714310344827, -1.491205476923077 ], [ 29.748714310344827, -1.490936 ], [ 29.748444827586209, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2532, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.490936 ], [ 29.748714310344827, -1.491205476923077 ], [ 29.748983793103449, -1.491205476923077 ], [ 29.748983793103449, -1.490936 ], [ 29.748714310344827, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2533, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.490936 ], [ 29.748983793103449, -1.491205476923077 ], [ 29.74925327586207, -1.491205476923077 ], [ 29.74925327586207, -1.490936 ], [ 29.748983793103449, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2534, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.490936 ], [ 29.74925327586207, -1.491205476923077 ], [ 29.749522758620689, -1.491205476923077 ], [ 29.749522758620689, -1.490936 ], [ 29.74925327586207, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2535, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.490936 ], [ 29.749522758620689, -1.491205476923077 ], [ 29.749792241379311, -1.491205476923077 ], [ 29.749792241379311, -1.490936 ], [ 29.749522758620689, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2536, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.490936 ], [ 29.749792241379311, -1.491205476923077 ], [ 29.750061724137932, -1.491205476923077 ], [ 29.750061724137932, -1.490936 ], [ 29.749792241379311, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2537, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.490936 ], [ 29.750061724137932, -1.491205476923077 ], [ 29.750331206896551, -1.491205476923077 ], [ 29.750331206896551, -1.490936 ], [ 29.750061724137932, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2538, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.490936 ], [ 29.750331206896551, -1.491205476923077 ], [ 29.750600689655172, -1.491205476923077 ], [ 29.750600689655172, -1.490936 ], [ 29.750331206896551, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2539, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.490936 ], [ 29.750600689655172, -1.491205476923077 ], [ 29.750870172413794, -1.491205476923077 ], [ 29.750870172413794, -1.490936 ], [ 29.750600689655172, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2540, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.490936 ], [ 29.750870172413794, -1.491205476923077 ], [ 29.751139655172413, -1.491205476923077 ], [ 29.751139655172413, -1.490936 ], [ 29.750870172413794, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2541, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.490936 ], [ 29.751139655172413, -1.491205476923077 ], [ 29.751409137931034, -1.491205476923077 ], [ 29.751409137931034, -1.490936 ], [ 29.751139655172413, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2542, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.490936 ], [ 29.751409137931034, -1.491205476923077 ], [ 29.751678620689656, -1.491205476923077 ], [ 29.751678620689656, -1.490936 ], [ 29.751409137931034, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2543, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.490936 ], [ 29.751678620689656, -1.491205476923077 ], [ 29.751948103448278, -1.491205476923077 ], [ 29.751948103448278, -1.490936 ], [ 29.751678620689656, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2544, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.490936 ], [ 29.751948103448278, -1.491205476923077 ], [ 29.752217586206896, -1.491205476923077 ], [ 29.752217586206896, -1.490936 ], [ 29.751948103448278, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2545, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.490936 ], [ 29.752217586206896, -1.491205476923077 ], [ 29.752487068965518, -1.491205476923077 ], [ 29.752487068965518, -1.490936 ], [ 29.752217586206896, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2546, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.490936 ], [ 29.752487068965518, -1.491205476923077 ], [ 29.75275655172414, -1.491205476923077 ], [ 29.75275655172414, -1.490936 ], [ 29.752487068965518, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2547, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.490936 ], [ 29.75275655172414, -1.491474953846154 ], [ 29.753026034482758, -1.491474953846154 ], [ 29.753026034482758, -1.490936 ], [ 29.75275655172414, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2548, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.490936 ], [ 29.75329551724138, -1.491205476923077 ], [ 29.753565, -1.491205476923077 ], [ 29.753565, -1.490936 ], [ 29.75329551724138, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2549, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.490936 ], [ 29.753565, -1.491205476923077 ], [ 29.75383448275862, -1.491205476923077 ], [ 29.75383448275862, -1.490936 ], [ 29.753565, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2550, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.490936 ], [ 29.75383448275862, -1.491205476923077 ], [ 29.754103965517242, -1.491205476923077 ], [ 29.754103965517242, -1.490936 ], [ 29.75383448275862, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2551, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.490936 ], [ 29.754103965517242, -1.491474953846154 ], [ 29.754373448275864, -1.491474953846154 ], [ 29.754373448275864, -1.490936 ], [ 29.754103965517242, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2552, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.490936 ], [ 29.754373448275864, -1.491474953846154 ], [ 29.754642931034482, -1.491474953846154 ], [ 29.754642931034482, -1.490936 ], [ 29.754373448275864, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2553, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.490936 ], [ 29.755451379310344, -1.491474953846154 ], [ 29.755720862068966, -1.491474953846154 ], [ 29.755720862068966, -1.490936 ], [ 29.755451379310344, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2554, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.490936 ], [ 29.756259827586206, -1.491205476923077 ], [ 29.756798793103449, -1.491205476923077 ], [ 29.756798793103449, -1.490936 ], [ 29.756259827586206, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2555, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.490936 ], [ 29.756798793103449, -1.491205476923077 ], [ 29.75733775862069, -1.491205476923077 ], [ 29.75733775862069, -1.490936 ], [ 29.756798793103449, -1.490936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2556, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716645862068965, -1.491205476923077 ], [ 29.716645862068965, -1.491744430769231 ], [ 29.716915344827587, -1.491744430769231 ], [ 29.716915344827587, -1.491205476923077 ], [ 29.716645862068965, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2557, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.491205476923077 ], [ 29.716915344827587, -1.491474953846154 ], [ 29.717184827586205, -1.491474953846154 ], [ 29.717184827586205, -1.491205476923077 ], [ 29.716915344827587, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2558, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.491205476923077 ], [ 29.717184827586205, -1.491474953846154 ], [ 29.717454310344827, -1.491474953846154 ], [ 29.717454310344827, -1.491205476923077 ], [ 29.717184827586205, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2559, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.491205476923077 ], [ 29.717454310344827, -1.491474953846154 ], [ 29.717723793103449, -1.491474953846154 ], [ 29.717723793103449, -1.491205476923077 ], [ 29.717454310344827, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2560, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.491205476923077 ], [ 29.717723793103449, -1.491474953846154 ], [ 29.717993275862067, -1.491474953846154 ], [ 29.717993275862067, -1.491205476923077 ], [ 29.717723793103449, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2561, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.491205476923077 ], [ 29.718262758620689, -1.491474953846154 ], [ 29.718532241379311, -1.491474953846154 ], [ 29.718532241379311, -1.491205476923077 ], [ 29.718262758620689, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2562, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.491205476923077 ], [ 29.718532241379311, -1.491474953846154 ], [ 29.718801724137933, -1.491474953846154 ], [ 29.718801724137933, -1.491205476923077 ], [ 29.718532241379311, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2563, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.491205476923077 ], [ 29.718801724137933, -1.491474953846154 ], [ 29.719071206896551, -1.491474953846154 ], [ 29.719071206896551, -1.491205476923077 ], [ 29.718801724137933, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2564, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.491205476923077 ], [ 29.719071206896551, -1.491474953846154 ], [ 29.719340689655173, -1.491474953846154 ], [ 29.719340689655173, -1.491205476923077 ], [ 29.719071206896551, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2565, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.491205476923077 ], [ 29.719340689655173, -1.491474953846154 ], [ 29.719610172413795, -1.491474953846154 ], [ 29.719610172413795, -1.491205476923077 ], [ 29.719340689655173, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2566, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.491205476923077 ], [ 29.719610172413795, -1.491474953846154 ], [ 29.719879655172413, -1.491474953846154 ], [ 29.719879655172413, -1.491205476923077 ], [ 29.719610172413795, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2567, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.491205476923077 ], [ 29.719879655172413, -1.491474953846154 ], [ 29.720149137931035, -1.491474953846154 ], [ 29.720149137931035, -1.491205476923077 ], [ 29.719879655172413, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2568, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.491205476923077 ], [ 29.720149137931035, -1.491474953846154 ], [ 29.720688103448275, -1.491474953846154 ], [ 29.720688103448275, -1.491205476923077 ], [ 29.720149137931035, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2569, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.491205476923077 ], [ 29.720688103448275, -1.491474953846154 ], [ 29.720957586206897, -1.491474953846154 ], [ 29.720957586206897, -1.491205476923077 ], [ 29.720688103448275, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2570, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.491205476923077 ], [ 29.720957586206897, -1.491474953846154 ], [ 29.721227068965518, -1.491474953846154 ], [ 29.721227068965518, -1.491205476923077 ], [ 29.720957586206897, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2571, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.491205476923077 ], [ 29.721227068965518, -1.491474953846154 ], [ 29.721496551724137, -1.491474953846154 ], [ 29.721496551724137, -1.491205476923077 ], [ 29.721227068965518, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2572, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.491205476923077 ], [ 29.721496551724137, -1.491474953846154 ], [ 29.721766034482759, -1.491474953846154 ], [ 29.721766034482759, -1.491205476923077 ], [ 29.721496551724137, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2573, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.491205476923077 ], [ 29.721766034482759, -1.491474953846154 ], [ 29.72203551724138, -1.491474953846154 ], [ 29.72203551724138, -1.491205476923077 ], [ 29.721766034482759, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2574, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.491205476923077 ], [ 29.72203551724138, -1.491474953846154 ], [ 29.722305, -1.491474953846154 ], [ 29.722305, -1.491205476923077 ], [ 29.72203551724138, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2575, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.491205476923077 ], [ 29.722305, -1.491474953846154 ], [ 29.72257448275862, -1.491474953846154 ], [ 29.72257448275862, -1.491205476923077 ], [ 29.722305, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2576, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.491205476923077 ], [ 29.72257448275862, -1.491474953846154 ], [ 29.722843965517242, -1.491474953846154 ], [ 29.722843965517242, -1.491205476923077 ], [ 29.72257448275862, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2577, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.491205476923077 ], [ 29.722843965517242, -1.491474953846154 ], [ 29.723113448275861, -1.491474953846154 ], [ 29.723113448275861, -1.491205476923077 ], [ 29.722843965517242, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2578, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.491205476923077 ], [ 29.723113448275861, -1.491474953846154 ], [ 29.723382931034482, -1.491474953846154 ], [ 29.723382931034482, -1.491205476923077 ], [ 29.723113448275861, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2579, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.491205476923077 ], [ 29.723382931034482, -1.491474953846154 ], [ 29.723652413793104, -1.491474953846154 ], [ 29.723652413793104, -1.491205476923077 ], [ 29.723382931034482, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2580, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.491205476923077 ], [ 29.723652413793104, -1.491474953846154 ], [ 29.723921896551722, -1.491474953846154 ], [ 29.723921896551722, -1.491205476923077 ], [ 29.723652413793104, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2581, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.491205476923077 ], [ 29.723921896551722, -1.491474953846154 ], [ 29.724191379310344, -1.491474953846154 ], [ 29.724191379310344, -1.491205476923077 ], [ 29.723921896551722, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2582, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.491205476923077 ], [ 29.724191379310344, -1.491474953846154 ], [ 29.724999827586206, -1.491474953846154 ], [ 29.724999827586206, -1.491205476923077 ], [ 29.724191379310344, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2583, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.491205476923077 ], [ 29.724999827586206, -1.491474953846154 ], [ 29.725269310344828, -1.491474953846154 ], [ 29.725269310344828, -1.491205476923077 ], [ 29.724999827586206, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2584, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.491205476923077 ], [ 29.725269310344828, -1.491474953846154 ], [ 29.72553879310345, -1.491474953846154 ], [ 29.72553879310345, -1.491205476923077 ], [ 29.725269310344828, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2585, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.491205476923077 ], [ 29.72553879310345, -1.491474953846154 ], [ 29.725808275862068, -1.491474953846154 ], [ 29.725808275862068, -1.491205476923077 ], [ 29.72553879310345, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2586, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.491205476923077 ], [ 29.725808275862068, -1.491474953846154 ], [ 29.72607775862069, -1.491474953846154 ], [ 29.72607775862069, -1.491205476923077 ], [ 29.725808275862068, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2587, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.491205476923077 ], [ 29.72607775862069, -1.491474953846154 ], [ 29.726347241379312, -1.491474953846154 ], [ 29.726347241379312, -1.491205476923077 ], [ 29.72607775862069, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2588, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.491205476923077 ], [ 29.726347241379312, -1.491474953846154 ], [ 29.72661672413793, -1.491474953846154 ], [ 29.72661672413793, -1.491205476923077 ], [ 29.726347241379312, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2589, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.491205476923077 ], [ 29.72661672413793, -1.491474953846154 ], [ 29.726886206896552, -1.491474953846154 ], [ 29.726886206896552, -1.491205476923077 ], [ 29.72661672413793, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2590, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.491205476923077 ], [ 29.726886206896552, -1.491474953846154 ], [ 29.727155689655174, -1.491474953846154 ], [ 29.727155689655174, -1.491744430769231 ], [ 29.727425172413792, -1.491744430769231 ], [ 29.727425172413792, -1.491205476923077 ], [ 29.726886206896552, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2591, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.491205476923077 ], [ 29.727425172413792, -1.491474953846154 ], [ 29.727694655172414, -1.491474953846154 ], [ 29.727694655172414, -1.491205476923077 ], [ 29.727425172413792, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2592, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.491205476923077 ], [ 29.727694655172414, -1.491474953846154 ], [ 29.727964137931036, -1.491474953846154 ], [ 29.727964137931036, -1.491205476923077 ], [ 29.727694655172414, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2593, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.491205476923077 ], [ 29.728233620689654, -1.491474953846154 ], [ 29.728503103448276, -1.491474953846154 ], [ 29.728503103448276, -1.491205476923077 ], [ 29.728233620689654, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2594, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.491205476923077 ], [ 29.728503103448276, -1.491474953846154 ], [ 29.728772586206897, -1.491474953846154 ], [ 29.728772586206897, -1.491205476923077 ], [ 29.728503103448276, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2595, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.491205476923077 ], [ 29.728772586206897, -1.491474953846154 ], [ 29.729042068965516, -1.491474953846154 ], [ 29.729042068965516, -1.491205476923077 ], [ 29.728772586206897, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2596, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.491205476923077 ], [ 29.729042068965516, -1.491474953846154 ], [ 29.729311551724138, -1.491474953846154 ], [ 29.729311551724138, -1.491205476923077 ], [ 29.729042068965516, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2597, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.491205476923077 ], [ 29.729311551724138, -1.491474953846154 ], [ 29.729850517241381, -1.491474953846154 ], [ 29.729850517241381, -1.491205476923077 ], [ 29.729311551724138, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2598, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.491205476923077 ], [ 29.729850517241381, -1.491474953846154 ], [ 29.731736896551723, -1.491474953846154 ], [ 29.731736896551723, -1.491205476923077 ], [ 29.729850517241381, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2599, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.491205476923077 ], [ 29.731736896551723, -1.491474953846154 ], [ 29.732006379310345, -1.491474953846154 ], [ 29.732006379310345, -1.491205476923077 ], [ 29.731736896551723, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2600, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.491205476923077 ], [ 29.732006379310345, -1.491474953846154 ], [ 29.732275862068967, -1.491474953846154 ], [ 29.732275862068967, -1.491205476923077 ], [ 29.732006379310345, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2601, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.491205476923077 ], [ 29.732275862068967, -1.491474953846154 ], [ 29.732545344827585, -1.491474953846154 ], [ 29.732545344827585, -1.491205476923077 ], [ 29.732275862068967, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2602, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.491205476923077 ], [ 29.732545344827585, -1.491474953846154 ], [ 29.732814827586207, -1.491474953846154 ], [ 29.732814827586207, -1.491205476923077 ], [ 29.732545344827585, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2603, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.491205476923077 ], [ 29.732814827586207, -1.491474953846154 ], [ 29.733084310344829, -1.491474953846154 ], [ 29.733084310344829, -1.491205476923077 ], [ 29.732814827586207, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2604, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.491205476923077 ], [ 29.733084310344829, -1.491474953846154 ], [ 29.733353793103447, -1.491474953846154 ], [ 29.733353793103447, -1.491205476923077 ], [ 29.733084310344829, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2605, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.491205476923077 ], [ 29.733353793103447, -1.491474953846154 ], [ 29.733623275862069, -1.491474953846154 ], [ 29.733623275862069, -1.491205476923077 ], [ 29.733353793103447, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2606, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.491205476923077 ], [ 29.733623275862069, -1.491474953846154 ], [ 29.733892758620691, -1.491474953846154 ], [ 29.733892758620691, -1.491205476923077 ], [ 29.733623275862069, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2607, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.491205476923077 ], [ 29.733892758620691, -1.491474953846154 ], [ 29.734162241379309, -1.491474953846154 ], [ 29.734162241379309, -1.491205476923077 ], [ 29.733892758620691, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2608, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.491205476923077 ], [ 29.734162241379309, -1.491474953846154 ], [ 29.734431724137931, -1.491474953846154 ], [ 29.734431724137931, -1.491205476923077 ], [ 29.734162241379309, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2609, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.491205476923077 ], [ 29.734431724137931, -1.491474953846154 ], [ 29.734701206896553, -1.491474953846154 ], [ 29.734701206896553, -1.491205476923077 ], [ 29.734431724137931, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2610, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.491205476923077 ], [ 29.734701206896553, -1.491474953846154 ], [ 29.734970689655171, -1.491474953846154 ], [ 29.734970689655171, -1.491205476923077 ], [ 29.734701206896553, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2611, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.491205476923077 ], [ 29.734970689655171, -1.491474953846154 ], [ 29.735240172413793, -1.491474953846154 ], [ 29.735240172413793, -1.491205476923077 ], [ 29.734970689655171, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2612, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.491205476923077 ], [ 29.735240172413793, -1.491474953846154 ], [ 29.735509655172415, -1.491474953846154 ], [ 29.735509655172415, -1.491205476923077 ], [ 29.735240172413793, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2613, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.491205476923077 ], [ 29.735509655172415, -1.491474953846154 ], [ 29.735779137931036, -1.491474953846154 ], [ 29.735779137931036, -1.491205476923077 ], [ 29.735509655172415, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2614, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.491205476923077 ], [ 29.735779137931036, -1.491474953846154 ], [ 29.736048620689655, -1.491474953846154 ], [ 29.736048620689655, -1.491205476923077 ], [ 29.735779137931036, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2615, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.491205476923077 ], [ 29.736048620689655, -1.491474953846154 ], [ 29.736318103448276, -1.491474953846154 ], [ 29.736318103448276, -1.491205476923077 ], [ 29.736048620689655, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2616, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.491205476923077 ], [ 29.736318103448276, -1.491474953846154 ], [ 29.736587586206898, -1.491474953846154 ], [ 29.736587586206898, -1.491205476923077 ], [ 29.736318103448276, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2617, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.491205476923077 ], [ 29.736587586206898, -1.491474953846154 ], [ 29.736857068965517, -1.491474953846154 ], [ 29.736857068965517, -1.491205476923077 ], [ 29.736587586206898, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2618, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.491205476923077 ], [ 29.736857068965517, -1.491474953846154 ], [ 29.737126551724138, -1.491474953846154 ], [ 29.737126551724138, -1.491205476923077 ], [ 29.736857068965517, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2619, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.491205476923077 ], [ 29.737126551724138, -1.491474953846154 ], [ 29.73739603448276, -1.491474953846154 ], [ 29.73739603448276, -1.491205476923077 ], [ 29.737126551724138, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2620, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.491205476923077 ], [ 29.73739603448276, -1.491474953846154 ], [ 29.737665517241378, -1.491474953846154 ], [ 29.737665517241378, -1.491205476923077 ], [ 29.73739603448276, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2621, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.491205476923077 ], [ 29.737665517241378, -1.491474953846154 ], [ 29.737935, -1.491474953846154 ], [ 29.737935, -1.491205476923077 ], [ 29.737665517241378, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2622, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.491205476923077 ], [ 29.737935, -1.491474953846154 ], [ 29.738204482758622, -1.491474953846154 ], [ 29.738204482758622, -1.491205476923077 ], [ 29.737935, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2623, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.491205476923077 ], [ 29.738204482758622, -1.491474953846154 ], [ 29.738743448275862, -1.491474953846154 ], [ 29.738743448275862, -1.491205476923077 ], [ 29.738204482758622, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2624, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.491205476923077 ], [ 29.738743448275862, -1.491474953846154 ], [ 29.739012931034484, -1.491474953846154 ], [ 29.739012931034484, -1.491205476923077 ], [ 29.738743448275862, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2625, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.491205476923077 ], [ 29.739012931034484, -1.491474953846154 ], [ 29.739282413793102, -1.491474953846154 ], [ 29.739282413793102, -1.491205476923077 ], [ 29.739012931034484, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2626, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.491205476923077 ], [ 29.739282413793102, -1.491474953846154 ], [ 29.739551896551724, -1.491474953846154 ], [ 29.739551896551724, -1.491205476923077 ], [ 29.739282413793102, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2627, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.491205476923077 ], [ 29.739551896551724, -1.491474953846154 ], [ 29.739821379310346, -1.491474953846154 ], [ 29.739821379310346, -1.491205476923077 ], [ 29.739551896551724, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2628, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.491205476923077 ], [ 29.739821379310346, -1.491474953846154 ], [ 29.740090862068964, -1.491474953846154 ], [ 29.740090862068964, -1.491205476923077 ], [ 29.739821379310346, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2629, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.491205476923077 ], [ 29.740090862068964, -1.491474953846154 ], [ 29.740360344827586, -1.491474953846154 ], [ 29.740360344827586, -1.491205476923077 ], [ 29.740090862068964, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2630, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.491205476923077 ], [ 29.740360344827586, -1.491474953846154 ], [ 29.740629827586208, -1.491474953846154 ], [ 29.740629827586208, -1.491205476923077 ], [ 29.740360344827586, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2631, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.491205476923077 ], [ 29.740629827586208, -1.491474953846154 ], [ 29.742516206896553, -1.491474953846154 ], [ 29.742516206896553, -1.491205476923077 ], [ 29.740629827586208, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2632, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.491205476923077 ], [ 29.742516206896553, -1.491474953846154 ], [ 29.742785689655172, -1.491474953846154 ], [ 29.742785689655172, -1.491205476923077 ], [ 29.742516206896553, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2633, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.491205476923077 ], [ 29.742785689655172, -1.491474953846154 ], [ 29.743594137931034, -1.491474953846154 ], [ 29.743594137931034, -1.491205476923077 ], [ 29.742785689655172, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2634, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.491205476923077 ], [ 29.743594137931034, -1.491474953846154 ], [ 29.743863620689655, -1.491474953846154 ], [ 29.743863620689655, -1.491205476923077 ], [ 29.743594137931034, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2635, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.491205476923077 ], [ 29.743863620689655, -1.491474953846154 ], [ 29.744133103448277, -1.491474953846154 ], [ 29.744133103448277, -1.491205476923077 ], [ 29.743863620689655, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2636, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.491205476923077 ], [ 29.744133103448277, -1.491474953846154 ], [ 29.745211034482757, -1.491474953846154 ], [ 29.745211034482757, -1.491205476923077 ], [ 29.744133103448277, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2637, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.491205476923077 ], [ 29.745211034482757, -1.491474953846154 ], [ 29.745480517241379, -1.491474953846154 ], [ 29.745480517241379, -1.491205476923077 ], [ 29.745211034482757, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2638, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.491205476923077 ], [ 29.745480517241379, -1.491474953846154 ], [ 29.746288965517241, -1.491474953846154 ], [ 29.746288965517241, -1.491205476923077 ], [ 29.745480517241379, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2639, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.491205476923077 ], [ 29.746288965517241, -1.491474953846154 ], [ 29.746558448275863, -1.491474953846154 ], [ 29.746558448275863, -1.491205476923077 ], [ 29.746288965517241, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2640, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.491205476923077 ], [ 29.746558448275863, -1.491474953846154 ], [ 29.748175344827587, -1.491474953846154 ], [ 29.748175344827587, -1.491205476923077 ], [ 29.746558448275863, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2641, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.491205476923077 ], [ 29.748175344827587, -1.491474953846154 ], [ 29.748444827586209, -1.491474953846154 ], [ 29.748444827586209, -1.491205476923077 ], [ 29.748175344827587, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2642, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.491205476923077 ], [ 29.748444827586209, -1.491474953846154 ], [ 29.748714310344827, -1.491474953846154 ], [ 29.748714310344827, -1.491205476923077 ], [ 29.748444827586209, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2643, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.491205476923077 ], [ 29.748714310344827, -1.491474953846154 ], [ 29.748983793103449, -1.491474953846154 ], [ 29.748983793103449, -1.491205476923077 ], [ 29.748714310344827, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2644, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.491205476923077 ], [ 29.748983793103449, -1.491474953846154 ], [ 29.74925327586207, -1.491474953846154 ], [ 29.74925327586207, -1.491205476923077 ], [ 29.748983793103449, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2645, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.491205476923077 ], [ 29.74925327586207, -1.491474953846154 ], [ 29.749522758620689, -1.491474953846154 ], [ 29.749522758620689, -1.491205476923077 ], [ 29.74925327586207, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2646, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.491205476923077 ], [ 29.749522758620689, -1.491474953846154 ], [ 29.749792241379311, -1.491474953846154 ], [ 29.749792241379311, -1.491205476923077 ], [ 29.749522758620689, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2647, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.491205476923077 ], [ 29.749792241379311, -1.491474953846154 ], [ 29.750061724137932, -1.491474953846154 ], [ 29.750061724137932, -1.491205476923077 ], [ 29.749792241379311, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2648, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.491205476923077 ], [ 29.750061724137932, -1.491474953846154 ], [ 29.750331206896551, -1.491474953846154 ], [ 29.750331206896551, -1.491205476923077 ], [ 29.750061724137932, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2649, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.491205476923077 ], [ 29.750331206896551, -1.491474953846154 ], [ 29.750600689655172, -1.491474953846154 ], [ 29.750600689655172, -1.491205476923077 ], [ 29.750331206896551, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2650, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.491205476923077 ], [ 29.750600689655172, -1.491474953846154 ], [ 29.750870172413794, -1.491474953846154 ], [ 29.750870172413794, -1.491205476923077 ], [ 29.750600689655172, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2651, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.491205476923077 ], [ 29.750870172413794, -1.491474953846154 ], [ 29.751139655172413, -1.491474953846154 ], [ 29.751139655172413, -1.491205476923077 ], [ 29.750870172413794, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2652, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.491205476923077 ], [ 29.751139655172413, -1.491474953846154 ], [ 29.751409137931034, -1.491474953846154 ], [ 29.751409137931034, -1.491205476923077 ], [ 29.751139655172413, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2653, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.491205476923077 ], [ 29.751409137931034, -1.491474953846154 ], [ 29.751678620689656, -1.491474953846154 ], [ 29.751678620689656, -1.491205476923077 ], [ 29.751409137931034, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2654, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.491205476923077 ], [ 29.751678620689656, -1.491474953846154 ], [ 29.751948103448278, -1.491474953846154 ], [ 29.751948103448278, -1.491205476923077 ], [ 29.751678620689656, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2655, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.491205476923077 ], [ 29.751948103448278, -1.491474953846154 ], [ 29.752487068965518, -1.491474953846154 ], [ 29.752487068965518, -1.491205476923077 ], [ 29.751948103448278, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2656, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.491205476923077 ], [ 29.752487068965518, -1.491744430769231 ], [ 29.75275655172414, -1.491744430769231 ], [ 29.75275655172414, -1.491205476923077 ], [ 29.752487068965518, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2657, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.491205476923077 ], [ 29.753026034482758, -1.491474953846154 ], [ 29.75329551724138, -1.491474953846154 ], [ 29.75329551724138, -1.491205476923077 ], [ 29.753026034482758, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2658, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.491205476923077 ], [ 29.75329551724138, -1.491474953846154 ], [ 29.753565, -1.491474953846154 ], [ 29.753565, -1.491205476923077 ], [ 29.75329551724138, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2659, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.491205476923077 ], [ 29.753565, -1.491744430769231 ], [ 29.75383448275862, -1.491744430769231 ], [ 29.75383448275862, -1.491205476923077 ], [ 29.753565, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2660, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.491205476923077 ], [ 29.75383448275862, -1.491744430769231 ], [ 29.754103965517242, -1.491744430769231 ], [ 29.754103965517242, -1.491205476923077 ], [ 29.75383448275862, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2661, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.491205476923077 ], [ 29.754642931034482, -1.491474953846154 ], [ 29.754912413793104, -1.491474953846154 ], [ 29.754912413793104, -1.491205476923077 ], [ 29.754642931034482, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2662, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.491205476923077 ], [ 29.754912413793104, -1.491474953846154 ], [ 29.755181896551726, -1.491474953846154 ], [ 29.755181896551726, -1.491205476923077 ], [ 29.754912413793104, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2663, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.491205476923077 ], [ 29.755181896551726, -1.491474953846154 ], [ 29.755451379310344, -1.491474953846154 ], [ 29.755451379310344, -1.491205476923077 ], [ 29.755181896551726, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2664, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.491205476923077 ], [ 29.755720862068966, -1.491474953846154 ], [ 29.755990344827588, -1.491474953846154 ], [ 29.755990344827588, -1.491205476923077 ], [ 29.755720862068966, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2665, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.491205476923077 ], [ 29.755990344827588, -1.491474953846154 ], [ 29.756259827586206, -1.491474953846154 ], [ 29.756259827586206, -1.491205476923077 ], [ 29.755990344827588, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2666, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.491205476923077 ], [ 29.756259827586206, -1.491474953846154 ], [ 29.757068275862068, -1.491474953846154 ], [ 29.757068275862068, -1.491205476923077 ], [ 29.756259827586206, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2667, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.491205476923077 ], [ 29.757068275862068, -1.491474953846154 ], [ 29.75733775862069, -1.491474953846154 ], [ 29.75733775862069, -1.491205476923077 ], [ 29.757068275862068, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2668, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.491205476923077 ], [ 29.75733775862069, -1.491474953846154 ], [ 29.757607241379311, -1.491474953846154 ], [ 29.757607241379311, -1.491205476923077 ], [ 29.75733775862069, -1.491205476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2669, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.491474953846154 ], [ 29.716915344827587, -1.492013907692308 ], [ 29.717184827586205, -1.492013907692308 ], [ 29.717184827586205, -1.491474953846154 ], [ 29.716915344827587, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2670, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.491474953846154 ], [ 29.717184827586205, -1.492013907692308 ], [ 29.717454310344827, -1.492013907692308 ], [ 29.717454310344827, -1.491474953846154 ], [ 29.717184827586205, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2671, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.491474953846154 ], [ 29.717454310344827, -1.491744430769231 ], [ 29.717723793103449, -1.491744430769231 ], [ 29.717723793103449, -1.491474953846154 ], [ 29.717454310344827, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2672, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.491474953846154 ], [ 29.717723793103449, -1.491744430769231 ], [ 29.717993275862067, -1.491744430769231 ], [ 29.717993275862067, -1.491474953846154 ], [ 29.717723793103449, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2673, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.491474953846154 ], [ 29.717993275862067, -1.491744430769231 ], [ 29.718262758620689, -1.491744430769231 ], [ 29.718262758620689, -1.491474953846154 ], [ 29.717993275862067, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2674, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.491474953846154 ], [ 29.718262758620689, -1.491744430769231 ], [ 29.718532241379311, -1.491744430769231 ], [ 29.718532241379311, -1.491474953846154 ], [ 29.718262758620689, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2675, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.491474953846154 ], [ 29.718532241379311, -1.491744430769231 ], [ 29.718801724137933, -1.491744430769231 ], [ 29.718801724137933, -1.491474953846154 ], [ 29.718532241379311, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2676, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.491474953846154 ], [ 29.718801724137933, -1.492013907692308 ], [ 29.719071206896551, -1.492013907692308 ], [ 29.719071206896551, -1.491474953846154 ], [ 29.718801724137933, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2677, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.491474953846154 ], [ 29.719071206896551, -1.491744430769231 ], [ 29.719340689655173, -1.491744430769231 ], [ 29.719340689655173, -1.491474953846154 ], [ 29.719071206896551, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2678, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.491474953846154 ], [ 29.719340689655173, -1.491744430769231 ], [ 29.719610172413795, -1.491744430769231 ], [ 29.719610172413795, -1.491474953846154 ], [ 29.719340689655173, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2679, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.491474953846154 ], [ 29.719610172413795, -1.491744430769231 ], [ 29.719879655172413, -1.491744430769231 ], [ 29.719879655172413, -1.491474953846154 ], [ 29.719610172413795, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2680, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.491474953846154 ], [ 29.719879655172413, -1.492013907692308 ], [ 29.720149137931035, -1.492013907692308 ], [ 29.720149137931035, -1.491474953846154 ], [ 29.719879655172413, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2681, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.491474953846154 ], [ 29.720149137931035, -1.491744430769231 ], [ 29.720688103448275, -1.491744430769231 ], [ 29.720688103448275, -1.491474953846154 ], [ 29.720149137931035, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2682, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.491474953846154 ], [ 29.720688103448275, -1.491744430769231 ], [ 29.720957586206897, -1.491744430769231 ], [ 29.720957586206897, -1.491474953846154 ], [ 29.720688103448275, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2683, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.491474953846154 ], [ 29.720957586206897, -1.491744430769231 ], [ 29.721227068965518, -1.491744430769231 ], [ 29.721227068965518, -1.491474953846154 ], [ 29.720957586206897, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2684, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.491474953846154 ], [ 29.721227068965518, -1.491744430769231 ], [ 29.721496551724137, -1.491744430769231 ], [ 29.721496551724137, -1.491474953846154 ], [ 29.721227068965518, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2685, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.491474953846154 ], [ 29.721496551724137, -1.491744430769231 ], [ 29.721766034482759, -1.491744430769231 ], [ 29.721766034482759, -1.491474953846154 ], [ 29.721496551724137, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2686, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.491474953846154 ], [ 29.721766034482759, -1.491744430769231 ], [ 29.72203551724138, -1.491744430769231 ], [ 29.72203551724138, -1.491474953846154 ], [ 29.721766034482759, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2687, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.491474953846154 ], [ 29.72203551724138, -1.491744430769231 ], [ 29.722305, -1.491744430769231 ], [ 29.722305, -1.491474953846154 ], [ 29.72203551724138, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2688, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.491474953846154 ], [ 29.722305, -1.491744430769231 ], [ 29.72257448275862, -1.491744430769231 ], [ 29.72257448275862, -1.491474953846154 ], [ 29.722305, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2689, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.491474953846154 ], [ 29.72257448275862, -1.491744430769231 ], [ 29.722843965517242, -1.491744430769231 ], [ 29.722843965517242, -1.491474953846154 ], [ 29.72257448275862, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2690, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.491474953846154 ], [ 29.722843965517242, -1.491744430769231 ], [ 29.723113448275861, -1.491744430769231 ], [ 29.723113448275861, -1.491474953846154 ], [ 29.722843965517242, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2691, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.491474953846154 ], [ 29.723113448275861, -1.491744430769231 ], [ 29.723382931034482, -1.491744430769231 ], [ 29.723382931034482, -1.491474953846154 ], [ 29.723113448275861, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2692, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.491474953846154 ], [ 29.723382931034482, -1.491744430769231 ], [ 29.723652413793104, -1.491744430769231 ], [ 29.723652413793104, -1.491474953846154 ], [ 29.723382931034482, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2693, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.491474953846154 ], [ 29.723652413793104, -1.491744430769231 ], [ 29.723921896551722, -1.491744430769231 ], [ 29.723921896551722, -1.491474953846154 ], [ 29.723652413793104, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2694, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.491474953846154 ], [ 29.723921896551722, -1.491744430769231 ], [ 29.724191379310344, -1.491744430769231 ], [ 29.724191379310344, -1.491474953846154 ], [ 29.723921896551722, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2695, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.491474953846154 ], [ 29.724191379310344, -1.491744430769231 ], [ 29.724460862068966, -1.491744430769231 ], [ 29.724460862068966, -1.491474953846154 ], [ 29.724191379310344, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2696, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.491474953846154 ], [ 29.724460862068966, -1.491744430769231 ], [ 29.724730344827588, -1.491744430769231 ], [ 29.724730344827588, -1.491474953846154 ], [ 29.724460862068966, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2697, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.491474953846154 ], [ 29.724730344827588, -1.491744430769231 ], [ 29.724999827586206, -1.491744430769231 ], [ 29.724999827586206, -1.491474953846154 ], [ 29.724730344827588, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2698, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.491474953846154 ], [ 29.724999827586206, -1.491744430769231 ], [ 29.725269310344828, -1.491744430769231 ], [ 29.725269310344828, -1.491474953846154 ], [ 29.724999827586206, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2699, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.491474953846154 ], [ 29.725269310344828, -1.491744430769231 ], [ 29.72553879310345, -1.491744430769231 ], [ 29.72553879310345, -1.491474953846154 ], [ 29.725269310344828, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2700, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.491474953846154 ], [ 29.72553879310345, -1.491744430769231 ], [ 29.725808275862068, -1.491744430769231 ], [ 29.725808275862068, -1.491474953846154 ], [ 29.72553879310345, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2701, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.491474953846154 ], [ 29.725808275862068, -1.491744430769231 ], [ 29.72607775862069, -1.491744430769231 ], [ 29.72607775862069, -1.491474953846154 ], [ 29.725808275862068, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2702, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.491474953846154 ], [ 29.72607775862069, -1.491744430769231 ], [ 29.726347241379312, -1.491744430769231 ], [ 29.726347241379312, -1.491474953846154 ], [ 29.72607775862069, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2703, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.491474953846154 ], [ 29.726347241379312, -1.492013907692308 ], [ 29.72661672413793, -1.492013907692308 ], [ 29.72661672413793, -1.491474953846154 ], [ 29.726347241379312, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2704, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.491474953846154 ], [ 29.72661672413793, -1.491744430769231 ], [ 29.726886206896552, -1.491744430769231 ], [ 29.726886206896552, -1.491474953846154 ], [ 29.72661672413793, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2705, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.491474953846154 ], [ 29.726886206896552, -1.491744430769231 ], [ 29.727155689655174, -1.491744430769231 ], [ 29.727155689655174, -1.491474953846154 ], [ 29.726886206896552, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2706, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.491474953846154 ], [ 29.727425172413792, -1.491744430769231 ], [ 29.727694655172414, -1.491744430769231 ], [ 29.727694655172414, -1.491474953846154 ], [ 29.727425172413792, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2707, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.491474953846154 ], [ 29.727694655172414, -1.491744430769231 ], [ 29.727964137931036, -1.491744430769231 ], [ 29.727964137931036, -1.491474953846154 ], [ 29.727694655172414, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2708, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.491474953846154 ], [ 29.727964137931036, -1.491744430769231 ], [ 29.728233620689654, -1.491744430769231 ], [ 29.728233620689654, -1.491474953846154 ], [ 29.727964137931036, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2709, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.491474953846154 ], [ 29.728233620689654, -1.491744430769231 ], [ 29.728503103448276, -1.491744430769231 ], [ 29.728503103448276, -1.491474953846154 ], [ 29.728233620689654, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2710, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.491474953846154 ], [ 29.728503103448276, -1.491744430769231 ], [ 29.728772586206897, -1.491744430769231 ], [ 29.728772586206897, -1.491474953846154 ], [ 29.728503103448276, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2711, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.491474953846154 ], [ 29.728772586206897, -1.491744430769231 ], [ 29.729042068965516, -1.491744430769231 ], [ 29.729042068965516, -1.491474953846154 ], [ 29.728772586206897, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2712, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.491474953846154 ], [ 29.729042068965516, -1.491744430769231 ], [ 29.729311551724138, -1.491744430769231 ], [ 29.729311551724138, -1.491474953846154 ], [ 29.729042068965516, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2713, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.491474953846154 ], [ 29.729311551724138, -1.491744430769231 ], [ 29.729850517241381, -1.491744430769231 ], [ 29.729850517241381, -1.491474953846154 ], [ 29.729311551724138, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2714, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.491474953846154 ], [ 29.729850517241381, -1.491744430769231 ], [ 29.731736896551723, -1.491744430769231 ], [ 29.731736896551723, -1.491474953846154 ], [ 29.729850517241381, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2715, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.491474953846154 ], [ 29.731736896551723, -1.491744430769231 ], [ 29.732006379310345, -1.491744430769231 ], [ 29.732006379310345, -1.491474953846154 ], [ 29.731736896551723, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2716, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.491474953846154 ], [ 29.732006379310345, -1.491744430769231 ], [ 29.732275862068967, -1.491744430769231 ], [ 29.732275862068967, -1.491474953846154 ], [ 29.732006379310345, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2717, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.491474953846154 ], [ 29.732275862068967, -1.491744430769231 ], [ 29.732545344827585, -1.491744430769231 ], [ 29.732545344827585, -1.491474953846154 ], [ 29.732275862068967, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2718, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.491474953846154 ], [ 29.732545344827585, -1.491744430769231 ], [ 29.732814827586207, -1.491744430769231 ], [ 29.732814827586207, -1.491474953846154 ], [ 29.732545344827585, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2719, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.491474953846154 ], [ 29.732814827586207, -1.491744430769231 ], [ 29.733084310344829, -1.491744430769231 ], [ 29.733084310344829, -1.491474953846154 ], [ 29.732814827586207, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2720, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.491474953846154 ], [ 29.733084310344829, -1.491744430769231 ], [ 29.733353793103447, -1.491744430769231 ], [ 29.733353793103447, -1.491474953846154 ], [ 29.733084310344829, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2721, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.491474953846154 ], [ 29.733353793103447, -1.491744430769231 ], [ 29.733623275862069, -1.491744430769231 ], [ 29.733623275862069, -1.491474953846154 ], [ 29.733353793103447, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2722, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.491474953846154 ], [ 29.733623275862069, -1.491744430769231 ], [ 29.733892758620691, -1.491744430769231 ], [ 29.733892758620691, -1.491474953846154 ], [ 29.733623275862069, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2723, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.491474953846154 ], [ 29.733892758620691, -1.491744430769231 ], [ 29.734162241379309, -1.491744430769231 ], [ 29.734162241379309, -1.491474953846154 ], [ 29.733892758620691, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2724, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.491474953846154 ], [ 29.734162241379309, -1.491744430769231 ], [ 29.734431724137931, -1.491744430769231 ], [ 29.734431724137931, -1.491474953846154 ], [ 29.734162241379309, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2725, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.491474953846154 ], [ 29.734431724137931, -1.491744430769231 ], [ 29.734701206896553, -1.491744430769231 ], [ 29.734701206896553, -1.491474953846154 ], [ 29.734431724137931, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2726, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.491474953846154 ], [ 29.734701206896553, -1.491744430769231 ], [ 29.734970689655171, -1.491744430769231 ], [ 29.734970689655171, -1.491474953846154 ], [ 29.734701206896553, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2727, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.491474953846154 ], [ 29.734970689655171, -1.491744430769231 ], [ 29.735240172413793, -1.491744430769231 ], [ 29.735240172413793, -1.491474953846154 ], [ 29.734970689655171, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2728, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.491474953846154 ], [ 29.735240172413793, -1.491744430769231 ], [ 29.735509655172415, -1.491744430769231 ], [ 29.735509655172415, -1.491474953846154 ], [ 29.735240172413793, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2729, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.491474953846154 ], [ 29.735509655172415, -1.491744430769231 ], [ 29.735779137931036, -1.491744430769231 ], [ 29.735779137931036, -1.491474953846154 ], [ 29.735509655172415, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2730, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.491474953846154 ], [ 29.735779137931036, -1.491744430769231 ], [ 29.736048620689655, -1.491744430769231 ], [ 29.736048620689655, -1.491474953846154 ], [ 29.735779137931036, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2731, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.491474953846154 ], [ 29.736048620689655, -1.491744430769231 ], [ 29.736318103448276, -1.491744430769231 ], [ 29.736318103448276, -1.491474953846154 ], [ 29.736048620689655, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2732, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.491474953846154 ], [ 29.736318103448276, -1.491744430769231 ], [ 29.736587586206898, -1.491744430769231 ], [ 29.736587586206898, -1.491474953846154 ], [ 29.736318103448276, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2733, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.491474953846154 ], [ 29.736587586206898, -1.491744430769231 ], [ 29.736857068965517, -1.491744430769231 ], [ 29.736857068965517, -1.491474953846154 ], [ 29.736587586206898, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2734, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.491474953846154 ], [ 29.736857068965517, -1.491744430769231 ], [ 29.737126551724138, -1.491744430769231 ], [ 29.737126551724138, -1.491474953846154 ], [ 29.736857068965517, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2735, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.491474953846154 ], [ 29.737126551724138, -1.491744430769231 ], [ 29.73739603448276, -1.491744430769231 ], [ 29.73739603448276, -1.491474953846154 ], [ 29.737126551724138, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2736, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.491474953846154 ], [ 29.73739603448276, -1.491744430769231 ], [ 29.737665517241378, -1.491744430769231 ], [ 29.737665517241378, -1.491474953846154 ], [ 29.73739603448276, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2737, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.491474953846154 ], [ 29.737665517241378, -1.491744430769231 ], [ 29.737935, -1.491744430769231 ], [ 29.737935, -1.491474953846154 ], [ 29.737665517241378, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2738, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.491474953846154 ], [ 29.737935, -1.491744430769231 ], [ 29.738204482758622, -1.491744430769231 ], [ 29.738204482758622, -1.491474953846154 ], [ 29.737935, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2739, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.491474953846154 ], [ 29.738204482758622, -1.491744430769231 ], [ 29.738743448275862, -1.491744430769231 ], [ 29.738743448275862, -1.491474953846154 ], [ 29.738204482758622, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2740, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.491474953846154 ], [ 29.738743448275862, -1.491744430769231 ], [ 29.739012931034484, -1.491744430769231 ], [ 29.739012931034484, -1.491474953846154 ], [ 29.738743448275862, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2741, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.491474953846154 ], [ 29.739012931034484, -1.491744430769231 ], [ 29.739282413793102, -1.491744430769231 ], [ 29.739282413793102, -1.491474953846154 ], [ 29.739012931034484, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2742, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.491474953846154 ], [ 29.739282413793102, -1.491744430769231 ], [ 29.739551896551724, -1.491744430769231 ], [ 29.739551896551724, -1.491474953846154 ], [ 29.739282413793102, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2743, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.491474953846154 ], [ 29.739551896551724, -1.491744430769231 ], [ 29.739821379310346, -1.491744430769231 ], [ 29.739821379310346, -1.491474953846154 ], [ 29.739551896551724, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2744, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.491474953846154 ], [ 29.739821379310346, -1.491744430769231 ], [ 29.740090862068964, -1.491744430769231 ], [ 29.740090862068964, -1.491474953846154 ], [ 29.739821379310346, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2745, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.491474953846154 ], [ 29.740090862068964, -1.491744430769231 ], [ 29.740360344827586, -1.491744430769231 ], [ 29.740360344827586, -1.491474953846154 ], [ 29.740090862068964, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2746, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.491474953846154 ], [ 29.740360344827586, -1.491744430769231 ], [ 29.740629827586208, -1.491744430769231 ], [ 29.740629827586208, -1.491474953846154 ], [ 29.740360344827586, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2747, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.491474953846154 ], [ 29.740629827586208, -1.491744430769231 ], [ 29.742516206896553, -1.491744430769231 ], [ 29.742516206896553, -1.491474953846154 ], [ 29.740629827586208, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2748, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.491474953846154 ], [ 29.742516206896553, -1.491744430769231 ], [ 29.742785689655172, -1.491744430769231 ], [ 29.742785689655172, -1.491474953846154 ], [ 29.742516206896553, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2749, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.491474953846154 ], [ 29.742785689655172, -1.491744430769231 ], [ 29.743055172413793, -1.491744430769231 ], [ 29.743055172413793, -1.491474953846154 ], [ 29.742785689655172, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2750, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.491474953846154 ], [ 29.743055172413793, -1.491744430769231 ], [ 29.743863620689655, -1.491744430769231 ], [ 29.743863620689655, -1.491474953846154 ], [ 29.743055172413793, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2751, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.491474953846154 ], [ 29.743863620689655, -1.491744430769231 ], [ 29.744133103448277, -1.491744430769231 ], [ 29.744133103448277, -1.491474953846154 ], [ 29.743863620689655, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2752, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.491474953846154 ], [ 29.744133103448277, -1.491744430769231 ], [ 29.745211034482757, -1.491744430769231 ], [ 29.745211034482757, -1.491474953846154 ], [ 29.744133103448277, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2753, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.491474953846154 ], [ 29.745211034482757, -1.491744430769231 ], [ 29.745480517241379, -1.491744430769231 ], [ 29.745480517241379, -1.491474953846154 ], [ 29.745211034482757, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2754, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.491474953846154 ], [ 29.745480517241379, -1.491744430769231 ], [ 29.746288965517241, -1.491744430769231 ], [ 29.746288965517241, -1.491474953846154 ], [ 29.745480517241379, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2755, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.491474953846154 ], [ 29.746288965517241, -1.491744430769231 ], [ 29.746827931034485, -1.491744430769231 ], [ 29.746827931034485, -1.491474953846154 ], [ 29.746288965517241, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2756, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.491474953846154 ], [ 29.746827931034485, -1.491744430769231 ], [ 29.747905862068965, -1.491744430769231 ], [ 29.747905862068965, -1.491474953846154 ], [ 29.746827931034485, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2757, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.491474953846154 ], [ 29.747905862068965, -1.491744430769231 ], [ 29.748444827586209, -1.491744430769231 ], [ 29.748444827586209, -1.491474953846154 ], [ 29.747905862068965, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2758, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.491474953846154 ], [ 29.748444827586209, -1.491744430769231 ], [ 29.748714310344827, -1.491744430769231 ], [ 29.748714310344827, -1.491474953846154 ], [ 29.748444827586209, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2759, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.491474953846154 ], [ 29.748714310344827, -1.491744430769231 ], [ 29.748983793103449, -1.491744430769231 ], [ 29.748983793103449, -1.491474953846154 ], [ 29.748714310344827, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2760, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.491474953846154 ], [ 29.748983793103449, -1.491744430769231 ], [ 29.74925327586207, -1.491744430769231 ], [ 29.74925327586207, -1.491474953846154 ], [ 29.748983793103449, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2761, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.491474953846154 ], [ 29.74925327586207, -1.491744430769231 ], [ 29.749522758620689, -1.491744430769231 ], [ 29.749522758620689, -1.491474953846154 ], [ 29.74925327586207, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2762, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.491474953846154 ], [ 29.749522758620689, -1.491744430769231 ], [ 29.749792241379311, -1.491744430769231 ], [ 29.749792241379311, -1.491474953846154 ], [ 29.749522758620689, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2763, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.491474953846154 ], [ 29.749792241379311, -1.491744430769231 ], [ 29.750061724137932, -1.491744430769231 ], [ 29.750061724137932, -1.491474953846154 ], [ 29.749792241379311, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2764, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.491474953846154 ], [ 29.750061724137932, -1.491744430769231 ], [ 29.750331206896551, -1.491744430769231 ], [ 29.750331206896551, -1.491474953846154 ], [ 29.750061724137932, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2765, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.491474953846154 ], [ 29.750331206896551, -1.491744430769231 ], [ 29.750600689655172, -1.491744430769231 ], [ 29.750600689655172, -1.491474953846154 ], [ 29.750331206896551, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2766, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.491474953846154 ], [ 29.750600689655172, -1.491744430769231 ], [ 29.750870172413794, -1.491744430769231 ], [ 29.750870172413794, -1.491474953846154 ], [ 29.750600689655172, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2767, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.491474953846154 ], [ 29.750870172413794, -1.491744430769231 ], [ 29.751139655172413, -1.491744430769231 ], [ 29.751139655172413, -1.491474953846154 ], [ 29.750870172413794, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2768, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.491474953846154 ], [ 29.751139655172413, -1.491744430769231 ], [ 29.751409137931034, -1.491744430769231 ], [ 29.751409137931034, -1.491474953846154 ], [ 29.751139655172413, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2769, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.491474953846154 ], [ 29.751409137931034, -1.491744430769231 ], [ 29.751678620689656, -1.491744430769231 ], [ 29.751678620689656, -1.491474953846154 ], [ 29.751409137931034, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2770, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.491474953846154 ], [ 29.751678620689656, -1.491744430769231 ], [ 29.751948103448278, -1.491744430769231 ], [ 29.751948103448278, -1.491474953846154 ], [ 29.751678620689656, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2771, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.491474953846154 ], [ 29.751948103448278, -1.491744430769231 ], [ 29.752217586206896, -1.491744430769231 ], [ 29.752217586206896, -1.491474953846154 ], [ 29.751948103448278, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2772, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.491474953846154 ], [ 29.752217586206896, -1.492013907692308 ], [ 29.752487068965518, -1.492013907692308 ], [ 29.752487068965518, -1.491474953846154 ], [ 29.752217586206896, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2773, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.491474953846154 ], [ 29.75275655172414, -1.491744430769231 ], [ 29.753026034482758, -1.491744430769231 ], [ 29.753026034482758, -1.491474953846154 ], [ 29.75275655172414, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2774, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.491474953846154 ], [ 29.753026034482758, -1.491744430769231 ], [ 29.75329551724138, -1.491744430769231 ], [ 29.75329551724138, -1.491474953846154 ], [ 29.753026034482758, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2775, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.491474953846154 ], [ 29.75329551724138, -1.492013907692308 ], [ 29.753565, -1.492013907692308 ], [ 29.753565, -1.491474953846154 ], [ 29.75329551724138, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2776, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.491474953846154 ], [ 29.754103965517242, -1.491744430769231 ], [ 29.754373448275864, -1.491744430769231 ], [ 29.754373448275864, -1.491474953846154 ], [ 29.754103965517242, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2777, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.491474953846154 ], [ 29.754373448275864, -1.491744430769231 ], [ 29.754642931034482, -1.491744430769231 ], [ 29.754642931034482, -1.491474953846154 ], [ 29.754373448275864, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2778, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.491474953846154 ], [ 29.754642931034482, -1.491744430769231 ], [ 29.754912413793104, -1.491744430769231 ], [ 29.754912413793104, -1.491474953846154 ], [ 29.754642931034482, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2779, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.491474953846154 ], [ 29.754912413793104, -1.491744430769231 ], [ 29.755181896551726, -1.491744430769231 ], [ 29.755181896551726, -1.491474953846154 ], [ 29.754912413793104, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2780, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.491474953846154 ], [ 29.755181896551726, -1.491744430769231 ], [ 29.755451379310344, -1.491744430769231 ], [ 29.755451379310344, -1.491474953846154 ], [ 29.755181896551726, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2781, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.491474953846154 ], [ 29.755451379310344, -1.491744430769231 ], [ 29.755720862068966, -1.491744430769231 ], [ 29.755720862068966, -1.491474953846154 ], [ 29.755451379310344, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2782, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.491474953846154 ], [ 29.755720862068966, -1.491744430769231 ], [ 29.755990344827588, -1.491744430769231 ], [ 29.755990344827588, -1.491474953846154 ], [ 29.755720862068966, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2783, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.491474953846154 ], [ 29.755990344827588, -1.491744430769231 ], [ 29.756259827586206, -1.491744430769231 ], [ 29.756259827586206, -1.491474953846154 ], [ 29.755990344827588, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2784, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.491474953846154 ], [ 29.756259827586206, -1.491744430769231 ], [ 29.757068275862068, -1.491744430769231 ], [ 29.757068275862068, -1.491474953846154 ], [ 29.756259827586206, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2785, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.491474953846154 ], [ 29.757068275862068, -1.491744430769231 ], [ 29.75733775862069, -1.491744430769231 ], [ 29.75733775862069, -1.491474953846154 ], [ 29.757068275862068, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2786, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.491474953846154 ], [ 29.75733775862069, -1.491744430769231 ], [ 29.757607241379311, -1.491744430769231 ], [ 29.757607241379311, -1.491474953846154 ], [ 29.75733775862069, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2787, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.491474953846154 ], [ 29.757607241379311, -1.491744430769231 ], [ 29.757876724137933, -1.491744430769231 ], [ 29.757876724137933, -1.491474953846154 ], [ 29.757607241379311, -1.491474953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2788, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716645862068965, -1.491744430769231 ], [ 29.716645862068965, -1.492013907692308 ], [ 29.716915344827587, -1.492013907692308 ], [ 29.716915344827587, -1.491744430769231 ], [ 29.716645862068965, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2789, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.491744430769231 ], [ 29.718262758620689, -1.492013907692308 ], [ 29.718532241379311, -1.492013907692308 ], [ 29.718532241379311, -1.491744430769231 ], [ 29.718262758620689, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2790, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.491744430769231 ], [ 29.718532241379311, -1.492013907692308 ], [ 29.718801724137933, -1.492013907692308 ], [ 29.718801724137933, -1.491744430769231 ], [ 29.718532241379311, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2791, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.491744430769231 ], [ 29.719071206896551, -1.492013907692308 ], [ 29.719340689655173, -1.492013907692308 ], [ 29.719340689655173, -1.491744430769231 ], [ 29.719071206896551, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2792, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.491744430769231 ], [ 29.719340689655173, -1.492013907692308 ], [ 29.719610172413795, -1.492013907692308 ], [ 29.719610172413795, -1.491744430769231 ], [ 29.719340689655173, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2793, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.491744430769231 ], [ 29.719610172413795, -1.492013907692308 ], [ 29.719879655172413, -1.492013907692308 ], [ 29.719879655172413, -1.491744430769231 ], [ 29.719610172413795, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2794, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.491744430769231 ], [ 29.720149137931035, -1.492013907692308 ], [ 29.720688103448275, -1.492013907692308 ], [ 29.720688103448275, -1.491744430769231 ], [ 29.720149137931035, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2795, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.491744430769231 ], [ 29.720688103448275, -1.492013907692308 ], [ 29.720957586206897, -1.492013907692308 ], [ 29.720957586206897, -1.491744430769231 ], [ 29.720688103448275, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2796, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.491744430769231 ], [ 29.720957586206897, -1.492013907692308 ], [ 29.721227068965518, -1.492013907692308 ], [ 29.721227068965518, -1.491744430769231 ], [ 29.720957586206897, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2797, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.491744430769231 ], [ 29.721227068965518, -1.492013907692308 ], [ 29.721496551724137, -1.492013907692308 ], [ 29.721496551724137, -1.491744430769231 ], [ 29.721227068965518, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2798, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.491744430769231 ], [ 29.721496551724137, -1.492013907692308 ], [ 29.721766034482759, -1.492013907692308 ], [ 29.721766034482759, -1.491744430769231 ], [ 29.721496551724137, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2799, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.491744430769231 ], [ 29.72203551724138, -1.492013907692308 ], [ 29.722305, -1.492013907692308 ], [ 29.722305, -1.491744430769231 ], [ 29.72203551724138, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2800, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.491744430769231 ], [ 29.722305, -1.492013907692308 ], [ 29.72257448275862, -1.492013907692308 ], [ 29.72257448275862, -1.491744430769231 ], [ 29.722305, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2801, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.491744430769231 ], [ 29.72257448275862, -1.492013907692308 ], [ 29.722843965517242, -1.492013907692308 ], [ 29.722843965517242, -1.491744430769231 ], [ 29.72257448275862, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2802, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.491744430769231 ], [ 29.722843965517242, -1.492013907692308 ], [ 29.723113448275861, -1.492013907692308 ], [ 29.723113448275861, -1.491744430769231 ], [ 29.722843965517242, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2803, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.491744430769231 ], [ 29.723113448275861, -1.492013907692308 ], [ 29.723382931034482, -1.492013907692308 ], [ 29.723382931034482, -1.491744430769231 ], [ 29.723113448275861, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2804, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.491744430769231 ], [ 29.723382931034482, -1.492013907692308 ], [ 29.723652413793104, -1.492013907692308 ], [ 29.723652413793104, -1.491744430769231 ], [ 29.723382931034482, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2805, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.491744430769231 ], [ 29.723652413793104, -1.492013907692308 ], [ 29.723921896551722, -1.492013907692308 ], [ 29.723921896551722, -1.491744430769231 ], [ 29.723652413793104, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2806, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.491744430769231 ], [ 29.723921896551722, -1.492013907692308 ], [ 29.724191379310344, -1.492013907692308 ], [ 29.724191379310344, -1.491744430769231 ], [ 29.723921896551722, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2807, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.491744430769231 ], [ 29.724191379310344, -1.492013907692308 ], [ 29.724460862068966, -1.492013907692308 ], [ 29.724460862068966, -1.491744430769231 ], [ 29.724191379310344, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2808, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.491744430769231 ], [ 29.724460862068966, -1.492013907692308 ], [ 29.724730344827588, -1.492013907692308 ], [ 29.724730344827588, -1.491744430769231 ], [ 29.724460862068966, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2809, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.491744430769231 ], [ 29.724730344827588, -1.492013907692308 ], [ 29.724999827586206, -1.492013907692308 ], [ 29.724999827586206, -1.491744430769231 ], [ 29.724730344827588, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2810, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.491744430769231 ], [ 29.724999827586206, -1.492013907692308 ], [ 29.725269310344828, -1.492013907692308 ], [ 29.725269310344828, -1.491744430769231 ], [ 29.724999827586206, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2811, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.491744430769231 ], [ 29.725269310344828, -1.492013907692308 ], [ 29.72553879310345, -1.492013907692308 ], [ 29.72553879310345, -1.491744430769231 ], [ 29.725269310344828, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2812, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.491744430769231 ], [ 29.725808275862068, -1.492013907692308 ], [ 29.72607775862069, -1.492013907692308 ], [ 29.72607775862069, -1.491744430769231 ], [ 29.725808275862068, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2813, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.491744430769231 ], [ 29.72661672413793, -1.492013907692308 ], [ 29.726886206896552, -1.492013907692308 ], [ 29.726886206896552, -1.491744430769231 ], [ 29.72661672413793, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2814, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.491744430769231 ], [ 29.726886206896552, -1.492013907692308 ], [ 29.727155689655174, -1.492013907692308 ], [ 29.727155689655174, -1.491744430769231 ], [ 29.726886206896552, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2815, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.491744430769231 ], [ 29.727155689655174, -1.492013907692308 ], [ 29.727425172413792, -1.492013907692308 ], [ 29.727425172413792, -1.491744430769231 ], [ 29.727155689655174, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2816, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.491744430769231 ], [ 29.727425172413792, -1.492013907692308 ], [ 29.727694655172414, -1.492013907692308 ], [ 29.727694655172414, -1.491744430769231 ], [ 29.727425172413792, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2817, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.491744430769231 ], [ 29.727694655172414, -1.492013907692308 ], [ 29.727964137931036, -1.492013907692308 ], [ 29.727964137931036, -1.491744430769231 ], [ 29.727694655172414, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2818, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.491744430769231 ], [ 29.727964137931036, -1.492013907692308 ], [ 29.728233620689654, -1.492013907692308 ], [ 29.728233620689654, -1.491744430769231 ], [ 29.727964137931036, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2819, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.491744430769231 ], [ 29.728233620689654, -1.492013907692308 ], [ 29.728503103448276, -1.492013907692308 ], [ 29.728503103448276, -1.491744430769231 ], [ 29.728233620689654, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2820, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.491744430769231 ], [ 29.728503103448276, -1.492013907692308 ], [ 29.728772586206897, -1.492013907692308 ], [ 29.728772586206897, -1.491744430769231 ], [ 29.728503103448276, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2821, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.491744430769231 ], [ 29.728772586206897, -1.492013907692308 ], [ 29.729042068965516, -1.492013907692308 ], [ 29.729042068965516, -1.491744430769231 ], [ 29.728772586206897, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2822, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.491744430769231 ], [ 29.729042068965516, -1.492013907692308 ], [ 29.729311551724138, -1.492013907692308 ], [ 29.729311551724138, -1.491744430769231 ], [ 29.729042068965516, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2823, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.491744430769231 ], [ 29.729311551724138, -1.492013907692308 ], [ 29.729850517241381, -1.492013907692308 ], [ 29.729850517241381, -1.491744430769231 ], [ 29.729311551724138, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2824, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.491744430769231 ], [ 29.729850517241381, -1.492013907692308 ], [ 29.73012, -1.492013907692308 ], [ 29.73012, -1.491744430769231 ], [ 29.729850517241381, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2825, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.491744430769231 ], [ 29.73012, -1.492013907692308 ], [ 29.731467413793105, -1.492013907692308 ], [ 29.731467413793105, -1.491744430769231 ], [ 29.73012, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2826, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.491744430769231 ], [ 29.731467413793105, -1.492013907692308 ], [ 29.731736896551723, -1.492013907692308 ], [ 29.731736896551723, -1.491744430769231 ], [ 29.731467413793105, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2827, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.491744430769231 ], [ 29.731736896551723, -1.492013907692308 ], [ 29.732006379310345, -1.492013907692308 ], [ 29.732006379310345, -1.491744430769231 ], [ 29.731736896551723, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2828, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.491744430769231 ], [ 29.732006379310345, -1.492013907692308 ], [ 29.732275862068967, -1.492013907692308 ], [ 29.732275862068967, -1.491744430769231 ], [ 29.732006379310345, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2829, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.491744430769231 ], [ 29.732275862068967, -1.492013907692308 ], [ 29.732545344827585, -1.492013907692308 ], [ 29.732545344827585, -1.491744430769231 ], [ 29.732275862068967, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2830, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.491744430769231 ], [ 29.732545344827585, -1.492013907692308 ], [ 29.732814827586207, -1.492013907692308 ], [ 29.732814827586207, -1.491744430769231 ], [ 29.732545344827585, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2831, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.491744430769231 ], [ 29.732814827586207, -1.492013907692308 ], [ 29.733084310344829, -1.492013907692308 ], [ 29.733084310344829, -1.491744430769231 ], [ 29.732814827586207, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2832, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.491744430769231 ], [ 29.733084310344829, -1.492013907692308 ], [ 29.733353793103447, -1.492013907692308 ], [ 29.733353793103447, -1.491744430769231 ], [ 29.733084310344829, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2833, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.491744430769231 ], [ 29.733353793103447, -1.492013907692308 ], [ 29.733623275862069, -1.492013907692308 ], [ 29.733623275862069, -1.491744430769231 ], [ 29.733353793103447, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2834, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.491744430769231 ], [ 29.733623275862069, -1.492013907692308 ], [ 29.733892758620691, -1.492013907692308 ], [ 29.733892758620691, -1.491744430769231 ], [ 29.733623275862069, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2835, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.491744430769231 ], [ 29.733892758620691, -1.492013907692308 ], [ 29.734162241379309, -1.492013907692308 ], [ 29.734162241379309, -1.491744430769231 ], [ 29.733892758620691, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2836, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.491744430769231 ], [ 29.734162241379309, -1.492013907692308 ], [ 29.734431724137931, -1.492013907692308 ], [ 29.734431724137931, -1.491744430769231 ], [ 29.734162241379309, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2837, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.491744430769231 ], [ 29.734431724137931, -1.492013907692308 ], [ 29.734701206896553, -1.492013907692308 ], [ 29.734701206896553, -1.491744430769231 ], [ 29.734431724137931, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2838, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.491744430769231 ], [ 29.734701206896553, -1.492013907692308 ], [ 29.734970689655171, -1.492013907692308 ], [ 29.734970689655171, -1.491744430769231 ], [ 29.734701206896553, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2839, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.491744430769231 ], [ 29.734970689655171, -1.492013907692308 ], [ 29.735240172413793, -1.492013907692308 ], [ 29.735240172413793, -1.491744430769231 ], [ 29.734970689655171, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2840, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.491744430769231 ], [ 29.735240172413793, -1.492013907692308 ], [ 29.735509655172415, -1.492013907692308 ], [ 29.735509655172415, -1.491744430769231 ], [ 29.735240172413793, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2841, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.491744430769231 ], [ 29.735509655172415, -1.492013907692308 ], [ 29.735779137931036, -1.492013907692308 ], [ 29.735779137931036, -1.491744430769231 ], [ 29.735509655172415, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2842, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.491744430769231 ], [ 29.735779137931036, -1.492013907692308 ], [ 29.736048620689655, -1.492013907692308 ], [ 29.736048620689655, -1.491744430769231 ], [ 29.735779137931036, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2843, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.491744430769231 ], [ 29.736048620689655, -1.492013907692308 ], [ 29.736318103448276, -1.492013907692308 ], [ 29.736318103448276, -1.491744430769231 ], [ 29.736048620689655, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2844, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.491744430769231 ], [ 29.736318103448276, -1.492013907692308 ], [ 29.736857068965517, -1.492013907692308 ], [ 29.736857068965517, -1.491744430769231 ], [ 29.736318103448276, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2845, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.491744430769231 ], [ 29.737126551724138, -1.492013907692308 ], [ 29.73739603448276, -1.492013907692308 ], [ 29.73739603448276, -1.491744430769231 ], [ 29.737126551724138, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2846, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.491744430769231 ], [ 29.73739603448276, -1.492013907692308 ], [ 29.737665517241378, -1.492013907692308 ], [ 29.737665517241378, -1.491744430769231 ], [ 29.73739603448276, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2847, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.491744430769231 ], [ 29.737665517241378, -1.492013907692308 ], [ 29.737935, -1.492013907692308 ], [ 29.737935, -1.491744430769231 ], [ 29.737665517241378, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2848, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.491744430769231 ], [ 29.737935, -1.492013907692308 ], [ 29.738204482758622, -1.492013907692308 ], [ 29.738204482758622, -1.491744430769231 ], [ 29.737935, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2849, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.491744430769231 ], [ 29.738204482758622, -1.492013907692308 ], [ 29.738743448275862, -1.492013907692308 ], [ 29.738743448275862, -1.491744430769231 ], [ 29.738204482758622, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2850, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.491744430769231 ], [ 29.738743448275862, -1.492013907692308 ], [ 29.739012931034484, -1.492013907692308 ], [ 29.739012931034484, -1.491744430769231 ], [ 29.738743448275862, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2851, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.491744430769231 ], [ 29.739012931034484, -1.492013907692308 ], [ 29.739282413793102, -1.492013907692308 ], [ 29.739282413793102, -1.491744430769231 ], [ 29.739012931034484, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2852, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.491744430769231 ], [ 29.739282413793102, -1.492013907692308 ], [ 29.739551896551724, -1.492013907692308 ], [ 29.739551896551724, -1.491744430769231 ], [ 29.739282413793102, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2853, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.491744430769231 ], [ 29.739551896551724, -1.492013907692308 ], [ 29.739821379310346, -1.492013907692308 ], [ 29.739821379310346, -1.491744430769231 ], [ 29.739551896551724, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2854, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.491744430769231 ], [ 29.739821379310346, -1.492013907692308 ], [ 29.740090862068964, -1.492013907692308 ], [ 29.740090862068964, -1.491744430769231 ], [ 29.739821379310346, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2855, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.491744430769231 ], [ 29.740090862068964, -1.492013907692308 ], [ 29.740360344827586, -1.492013907692308 ], [ 29.740360344827586, -1.491744430769231 ], [ 29.740090862068964, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2856, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.491744430769231 ], [ 29.740360344827586, -1.492013907692308 ], [ 29.740629827586208, -1.492013907692308 ], [ 29.740629827586208, -1.491744430769231 ], [ 29.740360344827586, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2857, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.491744430769231 ], [ 29.740629827586208, -1.492013907692308 ], [ 29.74089931034483, -1.492013907692308 ], [ 29.74089931034483, -1.491744430769231 ], [ 29.740629827586208, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2858, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.491744430769231 ], [ 29.74089931034483, -1.492013907692308 ], [ 29.742785689655172, -1.492013907692308 ], [ 29.742785689655172, -1.491744430769231 ], [ 29.74089931034483, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2859, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.491744430769231 ], [ 29.742785689655172, -1.492013907692308 ], [ 29.743055172413793, -1.492013907692308 ], [ 29.743055172413793, -1.491744430769231 ], [ 29.742785689655172, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2860, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.491744430769231 ], [ 29.743055172413793, -1.492013907692308 ], [ 29.743863620689655, -1.492013907692308 ], [ 29.743863620689655, -1.491744430769231 ], [ 29.743055172413793, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2861, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.491744430769231 ], [ 29.743863620689655, -1.492013907692308 ], [ 29.744133103448277, -1.492013907692308 ], [ 29.744133103448277, -1.491744430769231 ], [ 29.743863620689655, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2862, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.491744430769231 ], [ 29.744133103448277, -1.492013907692308 ], [ 29.745211034482757, -1.492013907692308 ], [ 29.745211034482757, -1.491744430769231 ], [ 29.744133103448277, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2863, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.491744430769231 ], [ 29.745211034482757, -1.492013907692308 ], [ 29.74575, -1.492013907692308 ], [ 29.74575, -1.491744430769231 ], [ 29.745211034482757, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2864, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.491744430769231 ], [ 29.74575, -1.492013907692308 ], [ 29.746288965517241, -1.492013907692308 ], [ 29.746288965517241, -1.491744430769231 ], [ 29.74575, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2865, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746288965517241, -1.491744430769231 ], [ 29.746288965517241, -1.492013907692308 ], [ 29.746558448275863, -1.492013907692308 ], [ 29.746558448275863, -1.491744430769231 ], [ 29.746288965517241, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2866, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.491744430769231 ], [ 29.746558448275863, -1.492013907692308 ], [ 29.748175344827587, -1.492013907692308 ], [ 29.748175344827587, -1.491744430769231 ], [ 29.746558448275863, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2867, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.491744430769231 ], [ 29.748175344827587, -1.492013907692308 ], [ 29.748444827586209, -1.492013907692308 ], [ 29.748444827586209, -1.491744430769231 ], [ 29.748175344827587, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2868, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.491744430769231 ], [ 29.748444827586209, -1.492013907692308 ], [ 29.748714310344827, -1.492013907692308 ], [ 29.748714310344827, -1.491744430769231 ], [ 29.748444827586209, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2869, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.491744430769231 ], [ 29.748714310344827, -1.492013907692308 ], [ 29.748983793103449, -1.492013907692308 ], [ 29.748983793103449, -1.491744430769231 ], [ 29.748714310344827, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2870, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.491744430769231 ], [ 29.748983793103449, -1.492013907692308 ], [ 29.74925327586207, -1.492013907692308 ], [ 29.74925327586207, -1.491744430769231 ], [ 29.748983793103449, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2871, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.491744430769231 ], [ 29.74925327586207, -1.492013907692308 ], [ 29.749522758620689, -1.492013907692308 ], [ 29.749522758620689, -1.491744430769231 ], [ 29.74925327586207, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2872, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.491744430769231 ], [ 29.749522758620689, -1.492013907692308 ], [ 29.749792241379311, -1.492013907692308 ], [ 29.749792241379311, -1.491744430769231 ], [ 29.749522758620689, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2873, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.491744430769231 ], [ 29.749792241379311, -1.492013907692308 ], [ 29.750061724137932, -1.492013907692308 ], [ 29.750061724137932, -1.491744430769231 ], [ 29.749792241379311, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2874, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.491744430769231 ], [ 29.750061724137932, -1.492013907692308 ], [ 29.750331206896551, -1.492013907692308 ], [ 29.750331206896551, -1.491744430769231 ], [ 29.750061724137932, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2875, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.491744430769231 ], [ 29.750331206896551, -1.492013907692308 ], [ 29.750600689655172, -1.492013907692308 ], [ 29.750600689655172, -1.491744430769231 ], [ 29.750331206896551, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2876, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.491744430769231 ], [ 29.750600689655172, -1.492013907692308 ], [ 29.750870172413794, -1.492013907692308 ], [ 29.750870172413794, -1.491744430769231 ], [ 29.750600689655172, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2877, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.491744430769231 ], [ 29.750870172413794, -1.492013907692308 ], [ 29.751139655172413, -1.492013907692308 ], [ 29.751139655172413, -1.491744430769231 ], [ 29.750870172413794, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2878, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.491744430769231 ], [ 29.751139655172413, -1.492013907692308 ], [ 29.751409137931034, -1.492013907692308 ], [ 29.751409137931034, -1.491744430769231 ], [ 29.751139655172413, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2879, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.491744430769231 ], [ 29.751409137931034, -1.492013907692308 ], [ 29.751678620689656, -1.492013907692308 ], [ 29.751678620689656, -1.491744430769231 ], [ 29.751409137931034, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2880, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.491744430769231 ], [ 29.751678620689656, -1.492013907692308 ], [ 29.751948103448278, -1.492013907692308 ], [ 29.751948103448278, -1.491744430769231 ], [ 29.751678620689656, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2881, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.491744430769231 ], [ 29.752487068965518, -1.492013907692308 ], [ 29.75275655172414, -1.492013907692308 ], [ 29.75275655172414, -1.491744430769231 ], [ 29.752487068965518, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2882, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.491744430769231 ], [ 29.75275655172414, -1.492013907692308 ], [ 29.753026034482758, -1.492013907692308 ], [ 29.753026034482758, -1.491744430769231 ], [ 29.75275655172414, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2883, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.491744430769231 ], [ 29.753565, -1.492013907692308 ], [ 29.75383448275862, -1.492013907692308 ], [ 29.75383448275862, -1.491744430769231 ], [ 29.753565, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2884, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.491744430769231 ], [ 29.75383448275862, -1.492013907692308 ], [ 29.754103965517242, -1.492013907692308 ], [ 29.754103965517242, -1.491744430769231 ], [ 29.75383448275862, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2885, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.491744430769231 ], [ 29.754103965517242, -1.492013907692308 ], [ 29.754373448275864, -1.492013907692308 ], [ 29.754373448275864, -1.491744430769231 ], [ 29.754103965517242, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2886, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.491744430769231 ], [ 29.754373448275864, -1.492013907692308 ], [ 29.754642931034482, -1.492013907692308 ], [ 29.754642931034482, -1.491744430769231 ], [ 29.754373448275864, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2887, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.491744430769231 ], [ 29.754642931034482, -1.492013907692308 ], [ 29.754912413793104, -1.492013907692308 ], [ 29.754912413793104, -1.491744430769231 ], [ 29.754642931034482, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2888, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.491744430769231 ], [ 29.754912413793104, -1.492013907692308 ], [ 29.755181896551726, -1.492013907692308 ], [ 29.755181896551726, -1.491744430769231 ], [ 29.754912413793104, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2889, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.491744430769231 ], [ 29.755181896551726, -1.492013907692308 ], [ 29.755451379310344, -1.492013907692308 ], [ 29.755451379310344, -1.491744430769231 ], [ 29.755181896551726, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2890, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.491744430769231 ], [ 29.755451379310344, -1.492013907692308 ], [ 29.755720862068966, -1.492013907692308 ], [ 29.755720862068966, -1.491744430769231 ], [ 29.755451379310344, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2891, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.491744430769231 ], [ 29.755720862068966, -1.492013907692308 ], [ 29.755990344827588, -1.492013907692308 ], [ 29.755990344827588, -1.491744430769231 ], [ 29.755720862068966, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2892, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.491744430769231 ], [ 29.755990344827588, -1.492013907692308 ], [ 29.756798793103449, -1.492013907692308 ], [ 29.756798793103449, -1.491744430769231 ], [ 29.755990344827588, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2893, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.491744430769231 ], [ 29.756798793103449, -1.492013907692308 ], [ 29.757068275862068, -1.492013907692308 ], [ 29.757068275862068, -1.491744430769231 ], [ 29.756798793103449, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2894, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.491744430769231 ], [ 29.757068275862068, -1.492013907692308 ], [ 29.75733775862069, -1.492013907692308 ], [ 29.75733775862069, -1.491744430769231 ], [ 29.757068275862068, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2895, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.491744430769231 ], [ 29.75733775862069, -1.492013907692308 ], [ 29.757607241379311, -1.492013907692308 ], [ 29.757607241379311, -1.491744430769231 ], [ 29.75733775862069, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2896, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.491744430769231 ], [ 29.757607241379311, -1.492013907692308 ], [ 29.757876724137933, -1.492013907692308 ], [ 29.757876724137933, -1.491744430769231 ], [ 29.757607241379311, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2897, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716376379310343, -1.491744430769231 ], [ 29.716376379310343, -1.492822338461538 ], [ 29.716915344827587, -1.492822338461538 ], [ 29.716915344827587, -1.492552861538462 ], [ 29.716645862068965, -1.492552861538462 ], [ 29.716645862068965, -1.491744430769231 ], [ 29.716376379310343, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2898, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.491744430769231 ], [ 29.717454310344827, -1.492283384615384 ], [ 29.717723793103449, -1.492283384615384 ], [ 29.717723793103449, -1.491744430769231 ], [ 29.717454310344827, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2899, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.491744430769231 ], [ 29.717723793103449, -1.492283384615384 ], [ 29.717993275862067, -1.492283384615384 ], [ 29.717993275862067, -1.491744430769231 ], [ 29.717723793103449, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2900, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.491744430769231 ], [ 29.717993275862067, -1.492283384615384 ], [ 29.718262758620689, -1.492283384615384 ], [ 29.718262758620689, -1.491744430769231 ], [ 29.717993275862067, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2901, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.491744430769231 ], [ 29.721766034482759, -1.492283384615384 ], [ 29.72203551724138, -1.492283384615384 ], [ 29.72203551724138, -1.491744430769231 ], [ 29.721766034482759, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2902, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.491744430769231 ], [ 29.72553879310345, -1.492283384615384 ], [ 29.725808275862068, -1.492283384615384 ], [ 29.725808275862068, -1.491744430769231 ], [ 29.72553879310345, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2903, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.491744430769231 ], [ 29.72607775862069, -1.492283384615384 ], [ 29.726347241379312, -1.492283384615384 ], [ 29.726347241379312, -1.491744430769231 ], [ 29.72607775862069, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2904, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.491744430769231 ], [ 29.736857068965517, -1.492283384615384 ], [ 29.737126551724138, -1.492283384615384 ], [ 29.737126551724138, -1.491744430769231 ], [ 29.736857068965517, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2905, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.491744430769231 ], [ 29.751948103448278, -1.492283384615384 ], [ 29.752217586206896, -1.492283384615384 ], [ 29.752217586206896, -1.491744430769231 ], [ 29.751948103448278, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2906, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.491744430769231 ], [ 29.753026034482758, -1.492283384615384 ], [ 29.75329551724138, -1.492283384615384 ], [ 29.75329551724138, -1.491744430769231 ], [ 29.753026034482758, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2907, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.491744430769231 ], [ 29.757876724137933, -1.492283384615384 ], [ 29.758146206896551, -1.492283384615384 ], [ 29.758146206896551, -1.491744430769231 ], [ 29.757876724137933, -1.491744430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2908, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716645862068965, -1.492013907692308 ], [ 29.716645862068965, -1.492552861538462 ], [ 29.717184827586205, -1.492552861538462 ], [ 29.717184827586205, -1.492283384615384 ], [ 29.716915344827587, -1.492283384615384 ], [ 29.716915344827587, -1.492013907692308 ], [ 29.716645862068965, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2909, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.492013907692308 ], [ 29.716915344827587, -1.492283384615384 ], [ 29.717184827586205, -1.492283384615384 ], [ 29.717184827586205, -1.492013907692308 ], [ 29.716915344827587, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2910, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.492013907692308 ], [ 29.717184827586205, -1.492283384615384 ], [ 29.717454310344827, -1.492283384615384 ], [ 29.717454310344827, -1.492013907692308 ], [ 29.717184827586205, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2911, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.492013907692308 ], [ 29.718262758620689, -1.492283384615384 ], [ 29.718532241379311, -1.492283384615384 ], [ 29.718532241379311, -1.492013907692308 ], [ 29.718262758620689, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2912, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.492013907692308 ], [ 29.718532241379311, -1.492283384615384 ], [ 29.718801724137933, -1.492283384615384 ], [ 29.718801724137933, -1.492013907692308 ], [ 29.718532241379311, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2913, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.492013907692308 ], [ 29.718801724137933, -1.492283384615384 ], [ 29.719071206896551, -1.492283384615384 ], [ 29.719071206896551, -1.492013907692308 ], [ 29.718801724137933, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2914, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.492013907692308 ], [ 29.719071206896551, -1.492283384615384 ], [ 29.719340689655173, -1.492283384615384 ], [ 29.719340689655173, -1.492013907692308 ], [ 29.719071206896551, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2915, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.492013907692308 ], [ 29.719340689655173, -1.492283384615384 ], [ 29.719610172413795, -1.492283384615384 ], [ 29.719610172413795, -1.492013907692308 ], [ 29.719340689655173, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2916, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.492013907692308 ], [ 29.719610172413795, -1.492283384615384 ], [ 29.719879655172413, -1.492283384615384 ], [ 29.719879655172413, -1.492013907692308 ], [ 29.719610172413795, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2917, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.492013907692308 ], [ 29.719879655172413, -1.492283384615384 ], [ 29.720149137931035, -1.492283384615384 ], [ 29.720149137931035, -1.492013907692308 ], [ 29.719879655172413, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2918, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.492013907692308 ], [ 29.720149137931035, -1.492283384615384 ], [ 29.720688103448275, -1.492283384615384 ], [ 29.720688103448275, -1.492013907692308 ], [ 29.720149137931035, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2919, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.492013907692308 ], [ 29.720688103448275, -1.492283384615384 ], [ 29.720957586206897, -1.492283384615384 ], [ 29.720957586206897, -1.492013907692308 ], [ 29.720688103448275, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2920, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.492013907692308 ], [ 29.720957586206897, -1.492283384615384 ], [ 29.721227068965518, -1.492283384615384 ], [ 29.721227068965518, -1.492013907692308 ], [ 29.720957586206897, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2921, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.492013907692308 ], [ 29.721227068965518, -1.492283384615384 ], [ 29.721496551724137, -1.492283384615384 ], [ 29.721496551724137, -1.492013907692308 ], [ 29.721227068965518, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2922, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.492013907692308 ], [ 29.721496551724137, -1.492283384615384 ], [ 29.721766034482759, -1.492283384615384 ], [ 29.721766034482759, -1.492013907692308 ], [ 29.721496551724137, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2923, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.492013907692308 ], [ 29.72203551724138, -1.492283384615384 ], [ 29.722305, -1.492283384615384 ], [ 29.722305, -1.492013907692308 ], [ 29.72203551724138, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2924, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.492013907692308 ], [ 29.722305, -1.492283384615384 ], [ 29.72257448275862, -1.492283384615384 ], [ 29.72257448275862, -1.492013907692308 ], [ 29.722305, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2925, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.492013907692308 ], [ 29.72257448275862, -1.492283384615384 ], [ 29.722843965517242, -1.492283384615384 ], [ 29.722843965517242, -1.492013907692308 ], [ 29.72257448275862, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2926, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.492013907692308 ], [ 29.722843965517242, -1.492283384615384 ], [ 29.723113448275861, -1.492283384615384 ], [ 29.723113448275861, -1.492013907692308 ], [ 29.722843965517242, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2927, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.492013907692308 ], [ 29.723113448275861, -1.492283384615384 ], [ 29.723382931034482, -1.492283384615384 ], [ 29.723382931034482, -1.492013907692308 ], [ 29.723113448275861, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2928, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.492013907692308 ], [ 29.723382931034482, -1.492283384615384 ], [ 29.723652413793104, -1.492283384615384 ], [ 29.723652413793104, -1.492013907692308 ], [ 29.723382931034482, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2929, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.492013907692308 ], [ 29.723652413793104, -1.492283384615384 ], [ 29.723921896551722, -1.492283384615384 ], [ 29.723921896551722, -1.492013907692308 ], [ 29.723652413793104, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2930, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.492013907692308 ], [ 29.723921896551722, -1.492283384615384 ], [ 29.724191379310344, -1.492283384615384 ], [ 29.724191379310344, -1.492013907692308 ], [ 29.723921896551722, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2931, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.492013907692308 ], [ 29.724191379310344, -1.492283384615384 ], [ 29.724460862068966, -1.492283384615384 ], [ 29.724460862068966, -1.492013907692308 ], [ 29.724191379310344, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2932, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.492013907692308 ], [ 29.724460862068966, -1.492283384615384 ], [ 29.724730344827588, -1.492283384615384 ], [ 29.724730344827588, -1.492013907692308 ], [ 29.724460862068966, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2933, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.492013907692308 ], [ 29.724730344827588, -1.492552861538462 ], [ 29.724999827586206, -1.492552861538462 ], [ 29.724999827586206, -1.492013907692308 ], [ 29.724730344827588, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2934, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.492013907692308 ], [ 29.724999827586206, -1.492552861538462 ], [ 29.725269310344828, -1.492552861538462 ], [ 29.725269310344828, -1.492013907692308 ], [ 29.724999827586206, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2935, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.492013907692308 ], [ 29.725269310344828, -1.492283384615384 ], [ 29.72553879310345, -1.492283384615384 ], [ 29.72553879310345, -1.492013907692308 ], [ 29.725269310344828, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2936, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.492013907692308 ], [ 29.725808275862068, -1.492283384615384 ], [ 29.72607775862069, -1.492283384615384 ], [ 29.72607775862069, -1.492013907692308 ], [ 29.725808275862068, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2937, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.492013907692308 ], [ 29.726347241379312, -1.492552861538462 ], [ 29.72661672413793, -1.492552861538462 ], [ 29.72661672413793, -1.492013907692308 ], [ 29.726347241379312, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2938, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.492013907692308 ], [ 29.72661672413793, -1.492283384615384 ], [ 29.726886206896552, -1.492283384615384 ], [ 29.726886206896552, -1.492013907692308 ], [ 29.72661672413793, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2939, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.492013907692308 ], [ 29.726886206896552, -1.492283384615384 ], [ 29.727155689655174, -1.492283384615384 ], [ 29.727155689655174, -1.492013907692308 ], [ 29.726886206896552, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2940, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.492013907692308 ], [ 29.727155689655174, -1.492283384615384 ], [ 29.727425172413792, -1.492283384615384 ], [ 29.727425172413792, -1.492013907692308 ], [ 29.727155689655174, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2941, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.492013907692308 ], [ 29.727425172413792, -1.492283384615384 ], [ 29.727694655172414, -1.492283384615384 ], [ 29.727694655172414, -1.492013907692308 ], [ 29.727425172413792, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2942, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.492013907692308 ], [ 29.727694655172414, -1.492283384615384 ], [ 29.727964137931036, -1.492283384615384 ], [ 29.727964137931036, -1.492013907692308 ], [ 29.727694655172414, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2943, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.492013907692308 ], [ 29.727964137931036, -1.492283384615384 ], [ 29.728233620689654, -1.492283384615384 ], [ 29.728233620689654, -1.492013907692308 ], [ 29.727964137931036, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2944, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.492013907692308 ], [ 29.728233620689654, -1.492283384615384 ], [ 29.728503103448276, -1.492283384615384 ], [ 29.728503103448276, -1.492013907692308 ], [ 29.728233620689654, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2945, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.492013907692308 ], [ 29.728503103448276, -1.492283384615384 ], [ 29.728772586206897, -1.492283384615384 ], [ 29.728772586206897, -1.492013907692308 ], [ 29.728503103448276, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2946, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.492013907692308 ], [ 29.728772586206897, -1.492283384615384 ], [ 29.729042068965516, -1.492283384615384 ], [ 29.729042068965516, -1.492013907692308 ], [ 29.728772586206897, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2947, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.492013907692308 ], [ 29.729042068965516, -1.492283384615384 ], [ 29.729311551724138, -1.492283384615384 ], [ 29.729311551724138, -1.492013907692308 ], [ 29.729042068965516, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2948, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.492013907692308 ], [ 29.729311551724138, -1.492283384615384 ], [ 29.729850517241381, -1.492283384615384 ], [ 29.729850517241381, -1.492013907692308 ], [ 29.729311551724138, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2949, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.492013907692308 ], [ 29.729850517241381, -1.492283384615384 ], [ 29.73012, -1.492283384615384 ], [ 29.73012, -1.492013907692308 ], [ 29.729850517241381, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2950, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.492013907692308 ], [ 29.73012, -1.492283384615384 ], [ 29.731467413793105, -1.492283384615384 ], [ 29.731467413793105, -1.492013907692308 ], [ 29.73012, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2951, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.492013907692308 ], [ 29.731467413793105, -1.492283384615384 ], [ 29.731736896551723, -1.492283384615384 ], [ 29.731736896551723, -1.492013907692308 ], [ 29.731467413793105, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2952, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.492013907692308 ], [ 29.731736896551723, -1.492283384615384 ], [ 29.732006379310345, -1.492283384615384 ], [ 29.732006379310345, -1.492013907692308 ], [ 29.731736896551723, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2953, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.492013907692308 ], [ 29.732006379310345, -1.492283384615384 ], [ 29.732275862068967, -1.492283384615384 ], [ 29.732275862068967, -1.492013907692308 ], [ 29.732006379310345, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2954, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.492013907692308 ], [ 29.732275862068967, -1.492283384615384 ], [ 29.732545344827585, -1.492283384615384 ], [ 29.732545344827585, -1.492013907692308 ], [ 29.732275862068967, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2955, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.492013907692308 ], [ 29.732545344827585, -1.492283384615384 ], [ 29.732814827586207, -1.492283384615384 ], [ 29.732814827586207, -1.492013907692308 ], [ 29.732545344827585, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2956, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.492013907692308 ], [ 29.732814827586207, -1.492283384615384 ], [ 29.733084310344829, -1.492283384615384 ], [ 29.733084310344829, -1.492013907692308 ], [ 29.732814827586207, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2957, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.492013907692308 ], [ 29.733084310344829, -1.492283384615384 ], [ 29.733353793103447, -1.492283384615384 ], [ 29.733353793103447, -1.492013907692308 ], [ 29.733084310344829, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2958, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.492013907692308 ], [ 29.733353793103447, -1.492283384615384 ], [ 29.733623275862069, -1.492283384615384 ], [ 29.733623275862069, -1.492013907692308 ], [ 29.733353793103447, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2959, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.492013907692308 ], [ 29.733623275862069, -1.492283384615384 ], [ 29.733892758620691, -1.492283384615384 ], [ 29.733892758620691, -1.492013907692308 ], [ 29.733623275862069, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2960, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.492013907692308 ], [ 29.733892758620691, -1.492283384615384 ], [ 29.734162241379309, -1.492283384615384 ], [ 29.734162241379309, -1.492013907692308 ], [ 29.733892758620691, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2961, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.492013907692308 ], [ 29.734162241379309, -1.492283384615384 ], [ 29.734431724137931, -1.492283384615384 ], [ 29.734431724137931, -1.492013907692308 ], [ 29.734162241379309, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2962, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.492013907692308 ], [ 29.734431724137931, -1.492283384615384 ], [ 29.734701206896553, -1.492283384615384 ], [ 29.734701206896553, -1.492013907692308 ], [ 29.734431724137931, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2963, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.492013907692308 ], [ 29.734701206896553, -1.492283384615384 ], [ 29.734970689655171, -1.492283384615384 ], [ 29.734970689655171, -1.492013907692308 ], [ 29.734701206896553, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2964, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.492013907692308 ], [ 29.734970689655171, -1.492283384615384 ], [ 29.735240172413793, -1.492283384615384 ], [ 29.735240172413793, -1.492013907692308 ], [ 29.734970689655171, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2965, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.492013907692308 ], [ 29.735240172413793, -1.492283384615384 ], [ 29.735509655172415, -1.492283384615384 ], [ 29.735509655172415, -1.492013907692308 ], [ 29.735240172413793, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2966, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.492013907692308 ], [ 29.735509655172415, -1.492283384615384 ], [ 29.735779137931036, -1.492283384615384 ], [ 29.735779137931036, -1.492013907692308 ], [ 29.735509655172415, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2967, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.492013907692308 ], [ 29.735779137931036, -1.492283384615384 ], [ 29.736048620689655, -1.492283384615384 ], [ 29.736048620689655, -1.492013907692308 ], [ 29.735779137931036, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2968, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.492013907692308 ], [ 29.736048620689655, -1.492283384615384 ], [ 29.736318103448276, -1.492283384615384 ], [ 29.736318103448276, -1.492013907692308 ], [ 29.736048620689655, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2969, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.492013907692308 ], [ 29.736318103448276, -1.492283384615384 ], [ 29.736857068965517, -1.492283384615384 ], [ 29.736857068965517, -1.492013907692308 ], [ 29.736318103448276, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2970, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.492013907692308 ], [ 29.737126551724138, -1.492552861538462 ], [ 29.73739603448276, -1.492552861538462 ], [ 29.73739603448276, -1.492013907692308 ], [ 29.737126551724138, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2971, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.492013907692308 ], [ 29.73739603448276, -1.492283384615384 ], [ 29.737665517241378, -1.492283384615384 ], [ 29.737665517241378, -1.492013907692308 ], [ 29.73739603448276, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2972, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.492013907692308 ], [ 29.737665517241378, -1.492283384615384 ], [ 29.737935, -1.492283384615384 ], [ 29.737935, -1.492013907692308 ], [ 29.737665517241378, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2973, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.492013907692308 ], [ 29.737935, -1.492283384615384 ], [ 29.738204482758622, -1.492283384615384 ], [ 29.738204482758622, -1.492013907692308 ], [ 29.737935, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2974, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.492013907692308 ], [ 29.738204482758622, -1.492283384615384 ], [ 29.738743448275862, -1.492283384615384 ], [ 29.738743448275862, -1.492013907692308 ], [ 29.738204482758622, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2975, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.492013907692308 ], [ 29.738743448275862, -1.492283384615384 ], [ 29.739012931034484, -1.492283384615384 ], [ 29.739012931034484, -1.492013907692308 ], [ 29.738743448275862, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2976, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.492013907692308 ], [ 29.739012931034484, -1.492283384615384 ], [ 29.739282413793102, -1.492283384615384 ], [ 29.739282413793102, -1.492013907692308 ], [ 29.739012931034484, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2977, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.492013907692308 ], [ 29.739282413793102, -1.492283384615384 ], [ 29.739551896551724, -1.492283384615384 ], [ 29.739551896551724, -1.492013907692308 ], [ 29.739282413793102, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2978, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.492013907692308 ], [ 29.739551896551724, -1.492283384615384 ], [ 29.739821379310346, -1.492283384615384 ], [ 29.739821379310346, -1.492013907692308 ], [ 29.739551896551724, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2979, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.492013907692308 ], [ 29.739821379310346, -1.492283384615384 ], [ 29.740090862068964, -1.492283384615384 ], [ 29.740090862068964, -1.492013907692308 ], [ 29.739821379310346, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2980, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.492013907692308 ], [ 29.740090862068964, -1.492283384615384 ], [ 29.740360344827586, -1.492283384615384 ], [ 29.740360344827586, -1.492013907692308 ], [ 29.740090862068964, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2981, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.492013907692308 ], [ 29.740360344827586, -1.492283384615384 ], [ 29.740629827586208, -1.492283384615384 ], [ 29.740629827586208, -1.492013907692308 ], [ 29.740360344827586, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2982, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.492013907692308 ], [ 29.740629827586208, -1.492283384615384 ], [ 29.74089931034483, -1.492283384615384 ], [ 29.74089931034483, -1.492013907692308 ], [ 29.740629827586208, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2983, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.492013907692308 ], [ 29.74089931034483, -1.492283384615384 ], [ 29.742785689655172, -1.492283384615384 ], [ 29.742785689655172, -1.492013907692308 ], [ 29.74089931034483, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2984, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.492013907692308 ], [ 29.742785689655172, -1.492283384615384 ], [ 29.743055172413793, -1.492283384615384 ], [ 29.743055172413793, -1.492013907692308 ], [ 29.742785689655172, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2985, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.492013907692308 ], [ 29.743055172413793, -1.492283384615384 ], [ 29.743863620689655, -1.492283384615384 ], [ 29.743863620689655, -1.492013907692308 ], [ 29.743055172413793, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2986, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.492013907692308 ], [ 29.743863620689655, -1.492283384615384 ], [ 29.744402586206895, -1.492283384615384 ], [ 29.744402586206895, -1.492013907692308 ], [ 29.743863620689655, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2987, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.492013907692308 ], [ 29.744402586206895, -1.492283384615384 ], [ 29.745211034482757, -1.492283384615384 ], [ 29.745211034482757, -1.492013907692308 ], [ 29.744402586206895, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2988, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.492013907692308 ], [ 29.745211034482757, -1.492283384615384 ], [ 29.745480517241379, -1.492283384615384 ], [ 29.745480517241379, -1.492013907692308 ], [ 29.745211034482757, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2989, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.492013907692308 ], [ 29.745480517241379, -1.492283384615384 ], [ 29.746558448275863, -1.492283384615384 ], [ 29.746558448275863, -1.492013907692308 ], [ 29.745480517241379, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2990, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.492013907692308 ], [ 29.746558448275863, -1.492283384615384 ], [ 29.748175344827587, -1.492283384615384 ], [ 29.748175344827587, -1.492013907692308 ], [ 29.746558448275863, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2991, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.492013907692308 ], [ 29.748175344827587, -1.492283384615384 ], [ 29.748444827586209, -1.492283384615384 ], [ 29.748444827586209, -1.492013907692308 ], [ 29.748175344827587, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2992, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.492013907692308 ], [ 29.748444827586209, -1.492283384615384 ], [ 29.748714310344827, -1.492283384615384 ], [ 29.748714310344827, -1.492013907692308 ], [ 29.748444827586209, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2993, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.492013907692308 ], [ 29.748714310344827, -1.492283384615384 ], [ 29.748983793103449, -1.492283384615384 ], [ 29.748983793103449, -1.492013907692308 ], [ 29.748714310344827, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2994, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.492013907692308 ], [ 29.748983793103449, -1.492283384615384 ], [ 29.74925327586207, -1.492283384615384 ], [ 29.74925327586207, -1.492013907692308 ], [ 29.748983793103449, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2995, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.492013907692308 ], [ 29.74925327586207, -1.492283384615384 ], [ 29.749522758620689, -1.492283384615384 ], [ 29.749522758620689, -1.492013907692308 ], [ 29.74925327586207, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2996, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.492013907692308 ], [ 29.749522758620689, -1.492283384615384 ], [ 29.749792241379311, -1.492283384615384 ], [ 29.749792241379311, -1.492013907692308 ], [ 29.749522758620689, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2997, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.492013907692308 ], [ 29.749792241379311, -1.492283384615384 ], [ 29.750061724137932, -1.492283384615384 ], [ 29.750061724137932, -1.492013907692308 ], [ 29.749792241379311, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2998, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.492013907692308 ], [ 29.750061724137932, -1.492283384615384 ], [ 29.750331206896551, -1.492283384615384 ], [ 29.750331206896551, -1.492013907692308 ], [ 29.750061724137932, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2999, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.492013907692308 ], [ 29.750331206896551, -1.492283384615384 ], [ 29.750600689655172, -1.492283384615384 ], [ 29.750600689655172, -1.492013907692308 ], [ 29.750331206896551, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3000, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.492013907692308 ], [ 29.750600689655172, -1.492283384615384 ], [ 29.750870172413794, -1.492283384615384 ], [ 29.750870172413794, -1.492013907692308 ], [ 29.750600689655172, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3001, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.492013907692308 ], [ 29.750870172413794, -1.492283384615384 ], [ 29.751139655172413, -1.492283384615384 ], [ 29.751139655172413, -1.492013907692308 ], [ 29.750870172413794, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3002, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.492013907692308 ], [ 29.751139655172413, -1.492283384615384 ], [ 29.751409137931034, -1.492283384615384 ], [ 29.751409137931034, -1.492013907692308 ], [ 29.751139655172413, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3003, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.492013907692308 ], [ 29.751409137931034, -1.492283384615384 ], [ 29.751678620689656, -1.492283384615384 ], [ 29.751678620689656, -1.492013907692308 ], [ 29.751409137931034, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3004, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.492013907692308 ], [ 29.751678620689656, -1.492552861538462 ], [ 29.751948103448278, -1.492552861538462 ], [ 29.751948103448278, -1.492013907692308 ], [ 29.751678620689656, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3005, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.492013907692308 ], [ 29.752217586206896, -1.492283384615384 ], [ 29.75275655172414, -1.492283384615384 ], [ 29.75275655172414, -1.492013907692308 ], [ 29.752217586206896, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3006, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.492013907692308 ], [ 29.75275655172414, -1.492552861538462 ], [ 29.753026034482758, -1.492552861538462 ], [ 29.753026034482758, -1.492013907692308 ], [ 29.75275655172414, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3007, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.492013907692308 ], [ 29.75329551724138, -1.492283384615384 ], [ 29.753565, -1.492283384615384 ], [ 29.753565, -1.492013907692308 ], [ 29.75329551724138, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3008, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.492013907692308 ], [ 29.753565, -1.492283384615384 ], [ 29.75383448275862, -1.492283384615384 ], [ 29.75383448275862, -1.492013907692308 ], [ 29.753565, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3009, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.492013907692308 ], [ 29.75383448275862, -1.492283384615384 ], [ 29.754103965517242, -1.492283384615384 ], [ 29.754103965517242, -1.492013907692308 ], [ 29.75383448275862, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3010, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.492013907692308 ], [ 29.754103965517242, -1.492283384615384 ], [ 29.754373448275864, -1.492283384615384 ], [ 29.754373448275864, -1.492013907692308 ], [ 29.754103965517242, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3011, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.492013907692308 ], [ 29.754373448275864, -1.492283384615384 ], [ 29.754642931034482, -1.492283384615384 ], [ 29.754642931034482, -1.492013907692308 ], [ 29.754373448275864, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3012, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.492013907692308 ], [ 29.754642931034482, -1.492283384615384 ], [ 29.754912413793104, -1.492283384615384 ], [ 29.754912413793104, -1.492013907692308 ], [ 29.754642931034482, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3013, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.492013907692308 ], [ 29.754912413793104, -1.492283384615384 ], [ 29.755181896551726, -1.492283384615384 ], [ 29.755181896551726, -1.492013907692308 ], [ 29.754912413793104, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3014, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.492013907692308 ], [ 29.755181896551726, -1.492283384615384 ], [ 29.755451379310344, -1.492283384615384 ], [ 29.755451379310344, -1.492013907692308 ], [ 29.755181896551726, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3015, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.492013907692308 ], [ 29.755451379310344, -1.492283384615384 ], [ 29.755720862068966, -1.492283384615384 ], [ 29.755720862068966, -1.492013907692308 ], [ 29.755451379310344, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3016, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.492013907692308 ], [ 29.755720862068966, -1.492283384615384 ], [ 29.755990344827588, -1.492283384615384 ], [ 29.755990344827588, -1.492013907692308 ], [ 29.755720862068966, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3017, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.492013907692308 ], [ 29.755990344827588, -1.492283384615384 ], [ 29.756259827586206, -1.492283384615384 ], [ 29.756259827586206, -1.492013907692308 ], [ 29.755990344827588, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3018, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.492013907692308 ], [ 29.756259827586206, -1.492283384615384 ], [ 29.756798793103449, -1.492283384615384 ], [ 29.756798793103449, -1.492013907692308 ], [ 29.756259827586206, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3019, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.492013907692308 ], [ 29.756798793103449, -1.492283384615384 ], [ 29.757068275862068, -1.492283384615384 ], [ 29.757068275862068, -1.492013907692308 ], [ 29.756798793103449, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3020, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.492013907692308 ], [ 29.757068275862068, -1.492283384615384 ], [ 29.75733775862069, -1.492283384615384 ], [ 29.75733775862069, -1.492013907692308 ], [ 29.757068275862068, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3021, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.492013907692308 ], [ 29.75733775862069, -1.492552861538462 ], [ 29.757607241379311, -1.492552861538462 ], [ 29.757607241379311, -1.492013907692308 ], [ 29.75733775862069, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3022, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.492013907692308 ], [ 29.757607241379311, -1.492552861538462 ], [ 29.757876724137933, -1.492552861538462 ], [ 29.757876724137933, -1.492013907692308 ], [ 29.757607241379311, -1.492013907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3023, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.492283384615384 ], [ 29.717184827586205, -1.492552861538462 ], [ 29.717454310344827, -1.492552861538462 ], [ 29.717454310344827, -1.492283384615384 ], [ 29.717184827586205, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3024, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.492283384615384 ], [ 29.717454310344827, -1.492552861538462 ], [ 29.717723793103449, -1.492552861538462 ], [ 29.717723793103449, -1.492283384615384 ], [ 29.717454310344827, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3025, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.492283384615384 ], [ 29.717723793103449, -1.492552861538462 ], [ 29.718262758620689, -1.492552861538462 ], [ 29.718262758620689, -1.492283384615384 ], [ 29.717723793103449, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3026, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.492283384615384 ], [ 29.718262758620689, -1.492552861538462 ], [ 29.718532241379311, -1.492552861538462 ], [ 29.718532241379311, -1.492283384615384 ], [ 29.718262758620689, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3027, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.492283384615384 ], [ 29.718532241379311, -1.492552861538462 ], [ 29.718801724137933, -1.492552861538462 ], [ 29.718801724137933, -1.492283384615384 ], [ 29.718532241379311, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3028, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.492283384615384 ], [ 29.718801724137933, -1.492552861538462 ], [ 29.719071206896551, -1.492552861538462 ], [ 29.719071206896551, -1.492283384615384 ], [ 29.718801724137933, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3029, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.492283384615384 ], [ 29.719071206896551, -1.492552861538462 ], [ 29.719340689655173, -1.492552861538462 ], [ 29.719340689655173, -1.492283384615384 ], [ 29.719071206896551, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3030, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.492283384615384 ], [ 29.719340689655173, -1.492552861538462 ], [ 29.719610172413795, -1.492552861538462 ], [ 29.719610172413795, -1.492283384615384 ], [ 29.719340689655173, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3031, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.492283384615384 ], [ 29.719610172413795, -1.492552861538462 ], [ 29.719879655172413, -1.492552861538462 ], [ 29.719879655172413, -1.492283384615384 ], [ 29.719610172413795, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3032, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.492283384615384 ], [ 29.719879655172413, -1.492552861538462 ], [ 29.720149137931035, -1.492552861538462 ], [ 29.720149137931035, -1.492283384615384 ], [ 29.719879655172413, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3033, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.492283384615384 ], [ 29.720149137931035, -1.492552861538462 ], [ 29.720688103448275, -1.492552861538462 ], [ 29.720688103448275, -1.492283384615384 ], [ 29.720149137931035, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3034, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.492283384615384 ], [ 29.720688103448275, -1.492552861538462 ], [ 29.720957586206897, -1.492552861538462 ], [ 29.720957586206897, -1.492283384615384 ], [ 29.720688103448275, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3035, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.492283384615384 ], [ 29.720957586206897, -1.492552861538462 ], [ 29.721227068965518, -1.492552861538462 ], [ 29.721227068965518, -1.492283384615384 ], [ 29.720957586206897, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3036, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.492283384615384 ], [ 29.721227068965518, -1.492552861538462 ], [ 29.721496551724137, -1.492552861538462 ], [ 29.721496551724137, -1.492283384615384 ], [ 29.721227068965518, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3037, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.492283384615384 ], [ 29.721496551724137, -1.492552861538462 ], [ 29.721766034482759, -1.492552861538462 ], [ 29.721766034482759, -1.492283384615384 ], [ 29.721496551724137, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3038, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.492283384615384 ], [ 29.721766034482759, -1.492552861538462 ], [ 29.72203551724138, -1.492552861538462 ], [ 29.72203551724138, -1.492283384615384 ], [ 29.721766034482759, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3039, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.492283384615384 ], [ 29.72203551724138, -1.492552861538462 ], [ 29.722305, -1.492552861538462 ], [ 29.722305, -1.492283384615384 ], [ 29.72203551724138, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3040, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.492283384615384 ], [ 29.722305, -1.492552861538462 ], [ 29.72257448275862, -1.492552861538462 ], [ 29.72257448275862, -1.492283384615384 ], [ 29.722305, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3041, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.492283384615384 ], [ 29.72257448275862, -1.492552861538462 ], [ 29.722843965517242, -1.492552861538462 ], [ 29.722843965517242, -1.492283384615384 ], [ 29.72257448275862, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3042, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.492283384615384 ], [ 29.722843965517242, -1.492552861538462 ], [ 29.723113448275861, -1.492552861538462 ], [ 29.723113448275861, -1.492283384615384 ], [ 29.722843965517242, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3043, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.492283384615384 ], [ 29.723113448275861, -1.492552861538462 ], [ 29.723382931034482, -1.492552861538462 ], [ 29.723382931034482, -1.492283384615384 ], [ 29.723113448275861, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3044, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.492283384615384 ], [ 29.723382931034482, -1.492552861538462 ], [ 29.723652413793104, -1.492552861538462 ], [ 29.723652413793104, -1.492283384615384 ], [ 29.723382931034482, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3045, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.492283384615384 ], [ 29.723652413793104, -1.492552861538462 ], [ 29.723921896551722, -1.492552861538462 ], [ 29.723921896551722, -1.492283384615384 ], [ 29.723652413793104, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3046, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.492283384615384 ], [ 29.723921896551722, -1.492552861538462 ], [ 29.724191379310344, -1.492552861538462 ], [ 29.724191379310344, -1.492283384615384 ], [ 29.723921896551722, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3047, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.492283384615384 ], [ 29.724191379310344, -1.492822338461538 ], [ 29.724460862068966, -1.492822338461538 ], [ 29.724460862068966, -1.492283384615384 ], [ 29.724191379310344, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3048, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.492283384615384 ], [ 29.724460862068966, -1.492822338461538 ], [ 29.724730344827588, -1.492822338461538 ], [ 29.724730344827588, -1.492283384615384 ], [ 29.724460862068966, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3049, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.492283384615384 ], [ 29.725269310344828, -1.492822338461538 ], [ 29.72553879310345, -1.492822338461538 ], [ 29.72553879310345, -1.492283384615384 ], [ 29.725269310344828, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3050, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.492283384615384 ], [ 29.72553879310345, -1.492822338461538 ], [ 29.725808275862068, -1.492822338461538 ], [ 29.725808275862068, -1.492283384615384 ], [ 29.72553879310345, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3051, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.492283384615384 ], [ 29.725808275862068, -1.492552861538462 ], [ 29.72607775862069, -1.492552861538462 ], [ 29.72607775862069, -1.492283384615384 ], [ 29.725808275862068, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3052, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.492283384615384 ], [ 29.72607775862069, -1.492552861538462 ], [ 29.726347241379312, -1.492552861538462 ], [ 29.726347241379312, -1.492283384615384 ], [ 29.72607775862069, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3053, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.492283384615384 ], [ 29.72661672413793, -1.492822338461538 ], [ 29.726886206896552, -1.492822338461538 ], [ 29.726886206896552, -1.492283384615384 ], [ 29.72661672413793, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3054, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.492283384615384 ], [ 29.726886206896552, -1.492552861538462 ], [ 29.727155689655174, -1.492552861538462 ], [ 29.727155689655174, -1.492283384615384 ], [ 29.726886206896552, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3055, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.492283384615384 ], [ 29.727155689655174, -1.492552861538462 ], [ 29.727425172413792, -1.492552861538462 ], [ 29.727425172413792, -1.492283384615384 ], [ 29.727155689655174, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3056, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.492283384615384 ], [ 29.727425172413792, -1.492552861538462 ], [ 29.727694655172414, -1.492552861538462 ], [ 29.727694655172414, -1.492283384615384 ], [ 29.727425172413792, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3057, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.492283384615384 ], [ 29.727694655172414, -1.492552861538462 ], [ 29.727964137931036, -1.492552861538462 ], [ 29.727964137931036, -1.492283384615384 ], [ 29.727694655172414, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3058, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.492283384615384 ], [ 29.727964137931036, -1.492552861538462 ], [ 29.728233620689654, -1.492552861538462 ], [ 29.728233620689654, -1.492283384615384 ], [ 29.727964137931036, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3059, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.492283384615384 ], [ 29.728233620689654, -1.492552861538462 ], [ 29.728503103448276, -1.492552861538462 ], [ 29.728503103448276, -1.492283384615384 ], [ 29.728233620689654, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3060, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.492283384615384 ], [ 29.728503103448276, -1.492552861538462 ], [ 29.728772586206897, -1.492552861538462 ], [ 29.728772586206897, -1.492283384615384 ], [ 29.728503103448276, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3061, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.492283384615384 ], [ 29.728772586206897, -1.492552861538462 ], [ 29.729042068965516, -1.492552861538462 ], [ 29.729042068965516, -1.492283384615384 ], [ 29.728772586206897, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3062, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.492283384615384 ], [ 29.729042068965516, -1.492552861538462 ], [ 29.729311551724138, -1.492552861538462 ], [ 29.729311551724138, -1.492283384615384 ], [ 29.729042068965516, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3063, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.492283384615384 ], [ 29.729311551724138, -1.492552861538462 ], [ 29.729850517241381, -1.492552861538462 ], [ 29.729850517241381, -1.492283384615384 ], [ 29.729311551724138, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3064, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.492283384615384 ], [ 29.729850517241381, -1.492552861538462 ], [ 29.73012, -1.492552861538462 ], [ 29.73012, -1.492283384615384 ], [ 29.729850517241381, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3065, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.492283384615384 ], [ 29.73012, -1.492552861538462 ], [ 29.731467413793105, -1.492552861538462 ], [ 29.731467413793105, -1.492283384615384 ], [ 29.73012, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3066, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.492283384615384 ], [ 29.731467413793105, -1.492552861538462 ], [ 29.731736896551723, -1.492552861538462 ], [ 29.731736896551723, -1.492283384615384 ], [ 29.731467413793105, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3067, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.492283384615384 ], [ 29.731736896551723, -1.492552861538462 ], [ 29.732006379310345, -1.492552861538462 ], [ 29.732006379310345, -1.492283384615384 ], [ 29.731736896551723, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3068, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.492283384615384 ], [ 29.732006379310345, -1.492552861538462 ], [ 29.732275862068967, -1.492552861538462 ], [ 29.732275862068967, -1.492283384615384 ], [ 29.732006379310345, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3069, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.492283384615384 ], [ 29.732275862068967, -1.492552861538462 ], [ 29.732545344827585, -1.492552861538462 ], [ 29.732545344827585, -1.492283384615384 ], [ 29.732275862068967, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3070, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.492283384615384 ], [ 29.732545344827585, -1.492552861538462 ], [ 29.732814827586207, -1.492552861538462 ], [ 29.732814827586207, -1.492283384615384 ], [ 29.732545344827585, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3071, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.492283384615384 ], [ 29.732814827586207, -1.492552861538462 ], [ 29.733084310344829, -1.492552861538462 ], [ 29.733084310344829, -1.492283384615384 ], [ 29.732814827586207, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3072, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.492283384615384 ], [ 29.733084310344829, -1.492552861538462 ], [ 29.733353793103447, -1.492552861538462 ], [ 29.733353793103447, -1.492283384615384 ], [ 29.733084310344829, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3073, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.492283384615384 ], [ 29.733353793103447, -1.492552861538462 ], [ 29.733623275862069, -1.492552861538462 ], [ 29.733623275862069, -1.492283384615384 ], [ 29.733353793103447, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3074, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.492283384615384 ], [ 29.733623275862069, -1.492552861538462 ], [ 29.733892758620691, -1.492552861538462 ], [ 29.733892758620691, -1.492283384615384 ], [ 29.733623275862069, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3075, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.492283384615384 ], [ 29.733892758620691, -1.492552861538462 ], [ 29.734162241379309, -1.492552861538462 ], [ 29.734162241379309, -1.492283384615384 ], [ 29.733892758620691, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3076, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.492283384615384 ], [ 29.734162241379309, -1.492552861538462 ], [ 29.734431724137931, -1.492552861538462 ], [ 29.734431724137931, -1.492283384615384 ], [ 29.734162241379309, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3077, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.492283384615384 ], [ 29.734431724137931, -1.492552861538462 ], [ 29.734701206896553, -1.492552861538462 ], [ 29.734701206896553, -1.492283384615384 ], [ 29.734431724137931, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3078, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.492283384615384 ], [ 29.734701206896553, -1.492552861538462 ], [ 29.734970689655171, -1.492552861538462 ], [ 29.734970689655171, -1.492283384615384 ], [ 29.734701206896553, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3079, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.492283384615384 ], [ 29.734970689655171, -1.492552861538462 ], [ 29.735240172413793, -1.492552861538462 ], [ 29.735240172413793, -1.492283384615384 ], [ 29.734970689655171, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3080, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.492283384615384 ], [ 29.735240172413793, -1.492552861538462 ], [ 29.735509655172415, -1.492552861538462 ], [ 29.735509655172415, -1.492283384615384 ], [ 29.735240172413793, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3081, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.492283384615384 ], [ 29.735509655172415, -1.492552861538462 ], [ 29.735779137931036, -1.492552861538462 ], [ 29.735779137931036, -1.492283384615384 ], [ 29.735509655172415, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3082, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.492283384615384 ], [ 29.735779137931036, -1.492552861538462 ], [ 29.736048620689655, -1.492552861538462 ], [ 29.736048620689655, -1.492283384615384 ], [ 29.735779137931036, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3083, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.492283384615384 ], [ 29.736048620689655, -1.492552861538462 ], [ 29.736318103448276, -1.492552861538462 ], [ 29.736318103448276, -1.492283384615384 ], [ 29.736048620689655, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3084, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.492283384615384 ], [ 29.736318103448276, -1.492552861538462 ], [ 29.736857068965517, -1.492552861538462 ], [ 29.736857068965517, -1.492283384615384 ], [ 29.736318103448276, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3085, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.492283384615384 ], [ 29.736857068965517, -1.492552861538462 ], [ 29.737126551724138, -1.492552861538462 ], [ 29.737126551724138, -1.492283384615384 ], [ 29.736857068965517, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3086, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.492283384615384 ], [ 29.73739603448276, -1.492822338461538 ], [ 29.737665517241378, -1.492822338461538 ], [ 29.737665517241378, -1.492283384615384 ], [ 29.73739603448276, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3087, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.492283384615384 ], [ 29.737665517241378, -1.492552861538462 ], [ 29.737935, -1.492552861538462 ], [ 29.737935, -1.492283384615384 ], [ 29.737665517241378, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3088, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.492283384615384 ], [ 29.737935, -1.492552861538462 ], [ 29.738204482758622, -1.492552861538462 ], [ 29.738204482758622, -1.492283384615384 ], [ 29.737935, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3089, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.492283384615384 ], [ 29.738204482758622, -1.492552861538462 ], [ 29.738743448275862, -1.492552861538462 ], [ 29.738743448275862, -1.492283384615384 ], [ 29.738204482758622, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3090, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.492283384615384 ], [ 29.738743448275862, -1.492552861538462 ], [ 29.739012931034484, -1.492552861538462 ], [ 29.739012931034484, -1.492283384615384 ], [ 29.738743448275862, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3091, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.492283384615384 ], [ 29.739012931034484, -1.492552861538462 ], [ 29.739282413793102, -1.492552861538462 ], [ 29.739282413793102, -1.492283384615384 ], [ 29.739012931034484, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3092, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.492283384615384 ], [ 29.739282413793102, -1.492552861538462 ], [ 29.739551896551724, -1.492552861538462 ], [ 29.739551896551724, -1.492283384615384 ], [ 29.739282413793102, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3093, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.492283384615384 ], [ 29.739551896551724, -1.492552861538462 ], [ 29.739821379310346, -1.492552861538462 ], [ 29.739821379310346, -1.492283384615384 ], [ 29.739551896551724, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3094, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.492283384615384 ], [ 29.739821379310346, -1.492552861538462 ], [ 29.740090862068964, -1.492552861538462 ], [ 29.740090862068964, -1.492283384615384 ], [ 29.739821379310346, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3095, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.492283384615384 ], [ 29.740090862068964, -1.492552861538462 ], [ 29.740360344827586, -1.492552861538462 ], [ 29.740360344827586, -1.492283384615384 ], [ 29.740090862068964, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3096, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.492283384615384 ], [ 29.740360344827586, -1.492552861538462 ], [ 29.740629827586208, -1.492552861538462 ], [ 29.740629827586208, -1.492283384615384 ], [ 29.740360344827586, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3097, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.492283384615384 ], [ 29.740629827586208, -1.492552861538462 ], [ 29.74089931034483, -1.492552861538462 ], [ 29.74089931034483, -1.492283384615384 ], [ 29.740629827586208, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3098, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.492283384615384 ], [ 29.74089931034483, -1.492552861538462 ], [ 29.742785689655172, -1.492552861538462 ], [ 29.742785689655172, -1.492283384615384 ], [ 29.74089931034483, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3099, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.492283384615384 ], [ 29.742785689655172, -1.492552861538462 ], [ 29.743324655172415, -1.492552861538462 ], [ 29.743324655172415, -1.492283384615384 ], [ 29.742785689655172, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3100, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.492283384615384 ], [ 29.743324655172415, -1.492552861538462 ], [ 29.743863620689655, -1.492552861538462 ], [ 29.743863620689655, -1.492283384615384 ], [ 29.743324655172415, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3101, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.492283384615384 ], [ 29.743863620689655, -1.492552861538462 ], [ 29.744133103448277, -1.492552861538462 ], [ 29.744133103448277, -1.492283384615384 ], [ 29.743863620689655, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3102, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.492283384615384 ], [ 29.744133103448277, -1.492552861538462 ], [ 29.745480517241379, -1.492552861538462 ], [ 29.745480517241379, -1.492283384615384 ], [ 29.744133103448277, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3103, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.492283384615384 ], [ 29.745480517241379, -1.492552861538462 ], [ 29.746558448275863, -1.492552861538462 ], [ 29.746558448275863, -1.492283384615384 ], [ 29.745480517241379, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3104, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746558448275863, -1.492283384615384 ], [ 29.746558448275863, -1.492552861538462 ], [ 29.746827931034485, -1.492552861538462 ], [ 29.746827931034485, -1.492283384615384 ], [ 29.746558448275863, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3105, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746827931034485, -1.492283384615384 ], [ 29.746827931034485, -1.492552861538462 ], [ 29.747905862068965, -1.492552861538462 ], [ 29.747905862068965, -1.492283384615384 ], [ 29.746827931034485, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3106, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.492283384615384 ], [ 29.747905862068965, -1.492552861538462 ], [ 29.748175344827587, -1.492552861538462 ], [ 29.748175344827587, -1.492283384615384 ], [ 29.747905862068965, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3107, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.492283384615384 ], [ 29.748175344827587, -1.492552861538462 ], [ 29.748444827586209, -1.492552861538462 ], [ 29.748444827586209, -1.492283384615384 ], [ 29.748175344827587, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3108, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.492283384615384 ], [ 29.748444827586209, -1.492552861538462 ], [ 29.748714310344827, -1.492552861538462 ], [ 29.748714310344827, -1.492283384615384 ], [ 29.748444827586209, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3109, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.492283384615384 ], [ 29.748714310344827, -1.492552861538462 ], [ 29.748983793103449, -1.492552861538462 ], [ 29.748983793103449, -1.492283384615384 ], [ 29.748714310344827, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3110, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.492283384615384 ], [ 29.748983793103449, -1.492822338461538 ], [ 29.74925327586207, -1.492822338461538 ], [ 29.74925327586207, -1.492283384615384 ], [ 29.748983793103449, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3111, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.492283384615384 ], [ 29.74925327586207, -1.492552861538462 ], [ 29.749522758620689, -1.492552861538462 ], [ 29.749522758620689, -1.492283384615384 ], [ 29.74925327586207, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3112, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.492283384615384 ], [ 29.749522758620689, -1.492552861538462 ], [ 29.749792241379311, -1.492552861538462 ], [ 29.749792241379311, -1.492283384615384 ], [ 29.749522758620689, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3113, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.492283384615384 ], [ 29.749792241379311, -1.492552861538462 ], [ 29.750061724137932, -1.492552861538462 ], [ 29.750061724137932, -1.492283384615384 ], [ 29.749792241379311, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3114, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.492283384615384 ], [ 29.750061724137932, -1.492552861538462 ], [ 29.750331206896551, -1.492552861538462 ], [ 29.750331206896551, -1.492283384615384 ], [ 29.750061724137932, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3115, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.492283384615384 ], [ 29.750331206896551, -1.492552861538462 ], [ 29.750600689655172, -1.492552861538462 ], [ 29.750600689655172, -1.492283384615384 ], [ 29.750331206896551, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3116, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.492283384615384 ], [ 29.750600689655172, -1.492552861538462 ], [ 29.750870172413794, -1.492552861538462 ], [ 29.750870172413794, -1.492283384615384 ], [ 29.750600689655172, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3117, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.492283384615384 ], [ 29.750870172413794, -1.492552861538462 ], [ 29.751139655172413, -1.492552861538462 ], [ 29.751139655172413, -1.492283384615384 ], [ 29.750870172413794, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3118, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.492283384615384 ], [ 29.751139655172413, -1.492552861538462 ], [ 29.751409137931034, -1.492552861538462 ], [ 29.751409137931034, -1.492283384615384 ], [ 29.751139655172413, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3119, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.492283384615384 ], [ 29.751409137931034, -1.492822338461538 ], [ 29.751678620689656, -1.492822338461538 ], [ 29.751678620689656, -1.492283384615384 ], [ 29.751409137931034, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3120, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.492283384615384 ], [ 29.751948103448278, -1.492552861538462 ], [ 29.752217586206896, -1.492552861538462 ], [ 29.752217586206896, -1.492283384615384 ], [ 29.751948103448278, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3121, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.492283384615384 ], [ 29.752217586206896, -1.492552861538462 ], [ 29.752487068965518, -1.492552861538462 ], [ 29.752487068965518, -1.492283384615384 ], [ 29.752217586206896, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3122, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.492283384615384 ], [ 29.752487068965518, -1.492822338461538 ], [ 29.75275655172414, -1.492822338461538 ], [ 29.75275655172414, -1.492283384615384 ], [ 29.752487068965518, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3123, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.492283384615384 ], [ 29.753026034482758, -1.492552861538462 ], [ 29.75329551724138, -1.492552861538462 ], [ 29.75329551724138, -1.492283384615384 ], [ 29.753026034482758, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3124, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.492283384615384 ], [ 29.75329551724138, -1.492552861538462 ], [ 29.753565, -1.492552861538462 ], [ 29.753565, -1.492283384615384 ], [ 29.75329551724138, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3125, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.492283384615384 ], [ 29.753565, -1.492552861538462 ], [ 29.75383448275862, -1.492552861538462 ], [ 29.75383448275862, -1.492283384615384 ], [ 29.753565, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3126, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.492283384615384 ], [ 29.75383448275862, -1.492552861538462 ], [ 29.754103965517242, -1.492552861538462 ], [ 29.754103965517242, -1.492283384615384 ], [ 29.75383448275862, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3127, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.492283384615384 ], [ 29.754103965517242, -1.492552861538462 ], [ 29.754373448275864, -1.492552861538462 ], [ 29.754373448275864, -1.492283384615384 ], [ 29.754103965517242, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3128, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.492283384615384 ], [ 29.754373448275864, -1.492552861538462 ], [ 29.754642931034482, -1.492552861538462 ], [ 29.754642931034482, -1.492283384615384 ], [ 29.754373448275864, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3129, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.492283384615384 ], [ 29.754642931034482, -1.492552861538462 ], [ 29.754912413793104, -1.492552861538462 ], [ 29.754912413793104, -1.492283384615384 ], [ 29.754642931034482, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3130, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.492283384615384 ], [ 29.754912413793104, -1.492552861538462 ], [ 29.755181896551726, -1.492552861538462 ], [ 29.755181896551726, -1.492283384615384 ], [ 29.754912413793104, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3131, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.492283384615384 ], [ 29.755181896551726, -1.492552861538462 ], [ 29.755451379310344, -1.492552861538462 ], [ 29.755451379310344, -1.492283384615384 ], [ 29.755181896551726, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3132, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.492283384615384 ], [ 29.755451379310344, -1.492552861538462 ], [ 29.755720862068966, -1.492552861538462 ], [ 29.755720862068966, -1.492283384615384 ], [ 29.755451379310344, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3133, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.492283384615384 ], [ 29.755720862068966, -1.492552861538462 ], [ 29.755990344827588, -1.492552861538462 ], [ 29.755990344827588, -1.492283384615384 ], [ 29.755720862068966, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3134, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.492283384615384 ], [ 29.755990344827588, -1.492552861538462 ], [ 29.756259827586206, -1.492552861538462 ], [ 29.756259827586206, -1.492283384615384 ], [ 29.755990344827588, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3135, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.492283384615384 ], [ 29.756259827586206, -1.492552861538462 ], [ 29.756798793103449, -1.492552861538462 ], [ 29.756798793103449, -1.492283384615384 ], [ 29.756259827586206, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3136, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.492283384615384 ], [ 29.756798793103449, -1.492552861538462 ], [ 29.757068275862068, -1.492552861538462 ], [ 29.757068275862068, -1.492283384615384 ], [ 29.756798793103449, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3137, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.492283384615384 ], [ 29.757068275862068, -1.492822338461538 ], [ 29.75733775862069, -1.492822338461538 ], [ 29.75733775862069, -1.492283384615384 ], [ 29.757068275862068, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3138, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.492283384615384 ], [ 29.757876724137933, -1.492552861538462 ], [ 29.758146206896551, -1.492552861538462 ], [ 29.758146206896551, -1.492283384615384 ], [ 29.757876724137933, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3139, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.492283384615384 ], [ 29.758146206896551, -1.492822338461538 ], [ 29.758415689655173, -1.492822338461538 ], [ 29.758415689655173, -1.492283384615384 ], [ 29.758146206896551, -1.492283384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3140, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.492552861538462 ], [ 29.716915344827587, -1.492822338461538 ], [ 29.717184827586205, -1.492822338461538 ], [ 29.717184827586205, -1.492552861538462 ], [ 29.716915344827587, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3141, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.492552861538462 ], [ 29.717184827586205, -1.492822338461538 ], [ 29.717454310344827, -1.492822338461538 ], [ 29.717454310344827, -1.492552861538462 ], [ 29.717184827586205, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3142, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.492552861538462 ], [ 29.717454310344827, -1.492822338461538 ], [ 29.717993275862067, -1.492822338461538 ], [ 29.717993275862067, -1.492552861538462 ], [ 29.717454310344827, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3143, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.492552861538462 ], [ 29.717993275862067, -1.492822338461538 ], [ 29.718262758620689, -1.492822338461538 ], [ 29.718262758620689, -1.492552861538462 ], [ 29.717993275862067, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3144, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.492552861538462 ], [ 29.718262758620689, -1.492822338461538 ], [ 29.718532241379311, -1.492822338461538 ], [ 29.718532241379311, -1.492552861538462 ], [ 29.718262758620689, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3145, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.492552861538462 ], [ 29.718532241379311, -1.492822338461538 ], [ 29.718801724137933, -1.492822338461538 ], [ 29.718801724137933, -1.492552861538462 ], [ 29.718532241379311, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3146, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.492552861538462 ], [ 29.718801724137933, -1.492822338461538 ], [ 29.719071206896551, -1.492822338461538 ], [ 29.719071206896551, -1.492552861538462 ], [ 29.718801724137933, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3147, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.492552861538462 ], [ 29.719071206896551, -1.492822338461538 ], [ 29.719340689655173, -1.492822338461538 ], [ 29.719340689655173, -1.492552861538462 ], [ 29.719071206896551, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3148, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.492552861538462 ], [ 29.719340689655173, -1.492822338461538 ], [ 29.719610172413795, -1.492822338461538 ], [ 29.719610172413795, -1.492552861538462 ], [ 29.719340689655173, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3149, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.492552861538462 ], [ 29.719610172413795, -1.492822338461538 ], [ 29.719879655172413, -1.492822338461538 ], [ 29.719879655172413, -1.492552861538462 ], [ 29.719610172413795, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3150, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.492552861538462 ], [ 29.719879655172413, -1.492822338461538 ], [ 29.720149137931035, -1.492822338461538 ], [ 29.720149137931035, -1.492552861538462 ], [ 29.719879655172413, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3151, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.492552861538462 ], [ 29.720149137931035, -1.492822338461538 ], [ 29.720688103448275, -1.492822338461538 ], [ 29.720688103448275, -1.492552861538462 ], [ 29.720149137931035, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3152, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.492552861538462 ], [ 29.720688103448275, -1.492822338461538 ], [ 29.720957586206897, -1.492822338461538 ], [ 29.720957586206897, -1.492552861538462 ], [ 29.720688103448275, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3153, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.492552861538462 ], [ 29.720957586206897, -1.492822338461538 ], [ 29.721227068965518, -1.492822338461538 ], [ 29.721227068965518, -1.492552861538462 ], [ 29.720957586206897, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3154, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.492552861538462 ], [ 29.721227068965518, -1.492822338461538 ], [ 29.721496551724137, -1.492822338461538 ], [ 29.721496551724137, -1.492552861538462 ], [ 29.721227068965518, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3155, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.492552861538462 ], [ 29.721496551724137, -1.492822338461538 ], [ 29.721766034482759, -1.492822338461538 ], [ 29.721766034482759, -1.492552861538462 ], [ 29.721496551724137, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3156, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.492552861538462 ], [ 29.721766034482759, -1.492822338461538 ], [ 29.72203551724138, -1.492822338461538 ], [ 29.72203551724138, -1.492552861538462 ], [ 29.721766034482759, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3157, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.492552861538462 ], [ 29.72203551724138, -1.492822338461538 ], [ 29.722305, -1.492822338461538 ], [ 29.722305, -1.492552861538462 ], [ 29.72203551724138, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3158, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.492552861538462 ], [ 29.722305, -1.492822338461538 ], [ 29.72257448275862, -1.492822338461538 ], [ 29.72257448275862, -1.492552861538462 ], [ 29.722305, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3159, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.492552861538462 ], [ 29.72257448275862, -1.492822338461538 ], [ 29.722843965517242, -1.492822338461538 ], [ 29.722843965517242, -1.492552861538462 ], [ 29.72257448275862, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3160, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.492552861538462 ], [ 29.722843965517242, -1.492822338461538 ], [ 29.723113448275861, -1.492822338461538 ], [ 29.723113448275861, -1.492552861538462 ], [ 29.722843965517242, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3161, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.492552861538462 ], [ 29.723113448275861, -1.492822338461538 ], [ 29.723382931034482, -1.492822338461538 ], [ 29.723382931034482, -1.492552861538462 ], [ 29.723113448275861, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3162, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.492552861538462 ], [ 29.723382931034482, -1.492822338461538 ], [ 29.723652413793104, -1.492822338461538 ], [ 29.723652413793104, -1.492552861538462 ], [ 29.723382931034482, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3163, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.492552861538462 ], [ 29.723652413793104, -1.492822338461538 ], [ 29.723921896551722, -1.492822338461538 ], [ 29.723921896551722, -1.492552861538462 ], [ 29.723652413793104, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3164, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.492552861538462 ], [ 29.723921896551722, -1.492822338461538 ], [ 29.724191379310344, -1.492822338461538 ], [ 29.724191379310344, -1.492552861538462 ], [ 29.723921896551722, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3165, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.492552861538462 ], [ 29.724730344827588, -1.493091815384615 ], [ 29.724999827586206, -1.493091815384615 ], [ 29.724999827586206, -1.492552861538462 ], [ 29.724730344827588, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3166, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.492552861538462 ], [ 29.724999827586206, -1.493091815384615 ], [ 29.725269310344828, -1.493091815384615 ], [ 29.725269310344828, -1.492552861538462 ], [ 29.724999827586206, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3167, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.492552861538462 ], [ 29.725808275862068, -1.493091815384615 ], [ 29.72607775862069, -1.493091815384615 ], [ 29.72607775862069, -1.492552861538462 ], [ 29.725808275862068, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3168, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.492552861538462 ], [ 29.72607775862069, -1.493091815384615 ], [ 29.726347241379312, -1.493091815384615 ], [ 29.726347241379312, -1.492552861538462 ], [ 29.72607775862069, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3169, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.492552861538462 ], [ 29.726347241379312, -1.492822338461538 ], [ 29.72661672413793, -1.492822338461538 ], [ 29.72661672413793, -1.492552861538462 ], [ 29.726347241379312, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3170, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.492552861538462 ], [ 29.726886206896552, -1.493091815384615 ], [ 29.727155689655174, -1.493091815384615 ], [ 29.727155689655174, -1.492552861538462 ], [ 29.726886206896552, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3171, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.492552861538462 ], [ 29.727155689655174, -1.492822338461538 ], [ 29.727425172413792, -1.492822338461538 ], [ 29.727425172413792, -1.492552861538462 ], [ 29.727155689655174, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3172, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.492552861538462 ], [ 29.727425172413792, -1.492822338461538 ], [ 29.727694655172414, -1.492822338461538 ], [ 29.727694655172414, -1.492552861538462 ], [ 29.727425172413792, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3173, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.492552861538462 ], [ 29.727694655172414, -1.492822338461538 ], [ 29.727964137931036, -1.492822338461538 ], [ 29.727964137931036, -1.492552861538462 ], [ 29.727694655172414, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3174, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.492552861538462 ], [ 29.727964137931036, -1.492822338461538 ], [ 29.728233620689654, -1.492822338461538 ], [ 29.728233620689654, -1.492552861538462 ], [ 29.727964137931036, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3175, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.492552861538462 ], [ 29.728233620689654, -1.492822338461538 ], [ 29.728503103448276, -1.492822338461538 ], [ 29.728503103448276, -1.492552861538462 ], [ 29.728233620689654, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3176, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.492552861538462 ], [ 29.728503103448276, -1.492822338461538 ], [ 29.728772586206897, -1.492822338461538 ], [ 29.728772586206897, -1.492552861538462 ], [ 29.728503103448276, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3177, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.492552861538462 ], [ 29.728772586206897, -1.492822338461538 ], [ 29.729042068965516, -1.492822338461538 ], [ 29.729042068965516, -1.492552861538462 ], [ 29.728772586206897, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3178, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.492552861538462 ], [ 29.729042068965516, -1.492822338461538 ], [ 29.729311551724138, -1.492822338461538 ], [ 29.729311551724138, -1.492552861538462 ], [ 29.729042068965516, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3179, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.492552861538462 ], [ 29.729311551724138, -1.492822338461538 ], [ 29.729850517241381, -1.492822338461538 ], [ 29.729850517241381, -1.492552861538462 ], [ 29.729311551724138, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3180, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.492552861538462 ], [ 29.729850517241381, -1.492822338461538 ], [ 29.730389482758621, -1.492822338461538 ], [ 29.730389482758621, -1.492552861538462 ], [ 29.729850517241381, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3181, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.492552861538462 ], [ 29.730389482758621, -1.492822338461538 ], [ 29.731197931034483, -1.492822338461538 ], [ 29.731197931034483, -1.492552861538462 ], [ 29.730389482758621, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3182, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.492552861538462 ], [ 29.731197931034483, -1.492822338461538 ], [ 29.731736896551723, -1.492822338461538 ], [ 29.731736896551723, -1.492552861538462 ], [ 29.731197931034483, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3183, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.492552861538462 ], [ 29.731736896551723, -1.492822338461538 ], [ 29.732006379310345, -1.492822338461538 ], [ 29.732006379310345, -1.492552861538462 ], [ 29.731736896551723, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3184, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.492552861538462 ], [ 29.732006379310345, -1.492822338461538 ], [ 29.732275862068967, -1.492822338461538 ], [ 29.732275862068967, -1.492552861538462 ], [ 29.732006379310345, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3185, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.492552861538462 ], [ 29.732275862068967, -1.492822338461538 ], [ 29.732545344827585, -1.492822338461538 ], [ 29.732545344827585, -1.492552861538462 ], [ 29.732275862068967, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3186, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.492552861538462 ], [ 29.732545344827585, -1.492822338461538 ], [ 29.732814827586207, -1.492822338461538 ], [ 29.732814827586207, -1.492552861538462 ], [ 29.732545344827585, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3187, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.492552861538462 ], [ 29.732814827586207, -1.492822338461538 ], [ 29.733084310344829, -1.492822338461538 ], [ 29.733084310344829, -1.492552861538462 ], [ 29.732814827586207, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3188, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.492552861538462 ], [ 29.733084310344829, -1.492822338461538 ], [ 29.733353793103447, -1.492822338461538 ], [ 29.733353793103447, -1.492552861538462 ], [ 29.733084310344829, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3189, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.492552861538462 ], [ 29.733353793103447, -1.492822338461538 ], [ 29.733623275862069, -1.492822338461538 ], [ 29.733623275862069, -1.492552861538462 ], [ 29.733353793103447, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3190, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.492552861538462 ], [ 29.733623275862069, -1.492822338461538 ], [ 29.733892758620691, -1.492822338461538 ], [ 29.733892758620691, -1.492552861538462 ], [ 29.733623275862069, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3191, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.492552861538462 ], [ 29.733892758620691, -1.492822338461538 ], [ 29.734162241379309, -1.492822338461538 ], [ 29.734162241379309, -1.492552861538462 ], [ 29.733892758620691, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3192, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.492552861538462 ], [ 29.734162241379309, -1.492822338461538 ], [ 29.734431724137931, -1.492822338461538 ], [ 29.734431724137931, -1.492552861538462 ], [ 29.734162241379309, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3193, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.492552861538462 ], [ 29.734431724137931, -1.492822338461538 ], [ 29.734701206896553, -1.492822338461538 ], [ 29.734701206896553, -1.492552861538462 ], [ 29.734431724137931, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3194, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.492552861538462 ], [ 29.734701206896553, -1.492822338461538 ], [ 29.734970689655171, -1.492822338461538 ], [ 29.734970689655171, -1.492552861538462 ], [ 29.734701206896553, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3195, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.492552861538462 ], [ 29.734970689655171, -1.492822338461538 ], [ 29.735240172413793, -1.492822338461538 ], [ 29.735240172413793, -1.492552861538462 ], [ 29.734970689655171, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3196, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.492552861538462 ], [ 29.735240172413793, -1.492822338461538 ], [ 29.735509655172415, -1.492822338461538 ], [ 29.735509655172415, -1.492552861538462 ], [ 29.735240172413793, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3197, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.492552861538462 ], [ 29.735509655172415, -1.492822338461538 ], [ 29.735779137931036, -1.492822338461538 ], [ 29.735779137931036, -1.492552861538462 ], [ 29.735509655172415, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3198, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.492552861538462 ], [ 29.735779137931036, -1.492822338461538 ], [ 29.736048620689655, -1.492822338461538 ], [ 29.736048620689655, -1.492552861538462 ], [ 29.735779137931036, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3199, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.492552861538462 ], [ 29.736048620689655, -1.492822338461538 ], [ 29.736318103448276, -1.492822338461538 ], [ 29.736318103448276, -1.492552861538462 ], [ 29.736048620689655, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3200, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.492552861538462 ], [ 29.736318103448276, -1.492822338461538 ], [ 29.736587586206898, -1.492822338461538 ], [ 29.736587586206898, -1.492552861538462 ], [ 29.736318103448276, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3201, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.492552861538462 ], [ 29.736587586206898, -1.493091815384615 ], [ 29.736857068965517, -1.493091815384615 ], [ 29.736857068965517, -1.492552861538462 ], [ 29.736587586206898, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3202, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.492552861538462 ], [ 29.736857068965517, -1.493091815384615 ], [ 29.737126551724138, -1.493091815384615 ], [ 29.737126551724138, -1.492552861538462 ], [ 29.736857068965517, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3203, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.492552861538462 ], [ 29.737126551724138, -1.492822338461538 ], [ 29.73739603448276, -1.492822338461538 ], [ 29.73739603448276, -1.492552861538462 ], [ 29.737126551724138, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3204, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.492552861538462 ], [ 29.737665517241378, -1.493091815384615 ], [ 29.737935, -1.493091815384615 ], [ 29.737935, -1.492552861538462 ], [ 29.737665517241378, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3205, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.492552861538462 ], [ 29.737935, -1.492822338461538 ], [ 29.738204482758622, -1.492822338461538 ], [ 29.738204482758622, -1.492552861538462 ], [ 29.737935, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3206, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.492552861538462 ], [ 29.738204482758622, -1.492822338461538 ], [ 29.738743448275862, -1.492822338461538 ], [ 29.738743448275862, -1.492552861538462 ], [ 29.738204482758622, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3207, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.492552861538462 ], [ 29.738743448275862, -1.492822338461538 ], [ 29.739012931034484, -1.492822338461538 ], [ 29.739012931034484, -1.492552861538462 ], [ 29.738743448275862, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3208, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.492552861538462 ], [ 29.739012931034484, -1.492822338461538 ], [ 29.739282413793102, -1.492822338461538 ], [ 29.739282413793102, -1.492552861538462 ], [ 29.739012931034484, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3209, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.492552861538462 ], [ 29.739282413793102, -1.492822338461538 ], [ 29.739551896551724, -1.492822338461538 ], [ 29.739551896551724, -1.492552861538462 ], [ 29.739282413793102, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3210, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.492552861538462 ], [ 29.739551896551724, -1.492822338461538 ], [ 29.739821379310346, -1.492822338461538 ], [ 29.739821379310346, -1.492552861538462 ], [ 29.739551896551724, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3211, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.492552861538462 ], [ 29.739821379310346, -1.492822338461538 ], [ 29.740090862068964, -1.492822338461538 ], [ 29.740090862068964, -1.492552861538462 ], [ 29.739821379310346, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3212, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.492552861538462 ], [ 29.740090862068964, -1.492822338461538 ], [ 29.740360344827586, -1.492822338461538 ], [ 29.740360344827586, -1.492552861538462 ], [ 29.740090862068964, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3213, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.492552861538462 ], [ 29.740360344827586, -1.492822338461538 ], [ 29.740629827586208, -1.492822338461538 ], [ 29.740629827586208, -1.492552861538462 ], [ 29.740360344827586, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3214, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.492552861538462 ], [ 29.740629827586208, -1.492822338461538 ], [ 29.741168793103448, -1.492822338461538 ], [ 29.741168793103448, -1.492552861538462 ], [ 29.740629827586208, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3215, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.492552861538462 ], [ 29.741168793103448, -1.492822338461538 ], [ 29.742785689655172, -1.492822338461538 ], [ 29.742785689655172, -1.492552861538462 ], [ 29.741168793103448, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3216, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.492552861538462 ], [ 29.742785689655172, -1.492822338461538 ], [ 29.743055172413793, -1.492822338461538 ], [ 29.743055172413793, -1.492552861538462 ], [ 29.742785689655172, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3217, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.492552861538462 ], [ 29.743055172413793, -1.492822338461538 ], [ 29.744133103448277, -1.492822338461538 ], [ 29.744133103448277, -1.492552861538462 ], [ 29.743055172413793, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3218, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.492552861538462 ], [ 29.744133103448277, -1.492822338461538 ], [ 29.745480517241379, -1.492822338461538 ], [ 29.745480517241379, -1.492552861538462 ], [ 29.744133103448277, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3219, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.492552861538462 ], [ 29.745480517241379, -1.492822338461538 ], [ 29.74575, -1.492822338461538 ], [ 29.74575, -1.492552861538462 ], [ 29.745480517241379, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3220, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74575, -1.492552861538462 ], [ 29.74575, -1.492822338461538 ], [ 29.746827931034485, -1.492822338461538 ], [ 29.746827931034485, -1.492552861538462 ], [ 29.74575, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3221, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747905862068965, -1.492552861538462 ], [ 29.747905862068965, -1.492822338461538 ], [ 29.748175344827587, -1.492822338461538 ], [ 29.748175344827587, -1.492552861538462 ], [ 29.747905862068965, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3222, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.492552861538462 ], [ 29.748175344827587, -1.492822338461538 ], [ 29.748444827586209, -1.492822338461538 ], [ 29.748444827586209, -1.492552861538462 ], [ 29.748175344827587, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3223, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.492552861538462 ], [ 29.748444827586209, -1.493091815384615 ], [ 29.748714310344827, -1.493091815384615 ], [ 29.748714310344827, -1.492552861538462 ], [ 29.748444827586209, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3224, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.492552861538462 ], [ 29.748714310344827, -1.493091815384615 ], [ 29.748983793103449, -1.493091815384615 ], [ 29.748983793103449, -1.492552861538462 ], [ 29.748714310344827, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3225, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.492552861538462 ], [ 29.74925327586207, -1.492822338461538 ], [ 29.749522758620689, -1.492822338461538 ], [ 29.749522758620689, -1.492552861538462 ], [ 29.74925327586207, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3226, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.492552861538462 ], [ 29.749522758620689, -1.492822338461538 ], [ 29.749792241379311, -1.492822338461538 ], [ 29.749792241379311, -1.492552861538462 ], [ 29.749522758620689, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3227, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.492552861538462 ], [ 29.749792241379311, -1.492822338461538 ], [ 29.750061724137932, -1.492822338461538 ], [ 29.750061724137932, -1.492552861538462 ], [ 29.749792241379311, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3228, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.492552861538462 ], [ 29.750061724137932, -1.492822338461538 ], [ 29.750331206896551, -1.492822338461538 ], [ 29.750331206896551, -1.492552861538462 ], [ 29.750061724137932, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3229, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.492552861538462 ], [ 29.750331206896551, -1.492822338461538 ], [ 29.750600689655172, -1.492822338461538 ], [ 29.750600689655172, -1.492552861538462 ], [ 29.750331206896551, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3230, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.492552861538462 ], [ 29.750600689655172, -1.492822338461538 ], [ 29.750870172413794, -1.492822338461538 ], [ 29.750870172413794, -1.492552861538462 ], [ 29.750600689655172, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3231, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.492552861538462 ], [ 29.750870172413794, -1.493091815384615 ], [ 29.751139655172413, -1.493091815384615 ], [ 29.751139655172413, -1.492552861538462 ], [ 29.750870172413794, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3232, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.492552861538462 ], [ 29.751139655172413, -1.493091815384615 ], [ 29.751409137931034, -1.493091815384615 ], [ 29.751409137931034, -1.492552861538462 ], [ 29.751139655172413, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3233, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.492552861538462 ], [ 29.751678620689656, -1.492822338461538 ], [ 29.751948103448278, -1.492822338461538 ], [ 29.751948103448278, -1.492552861538462 ], [ 29.751678620689656, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3234, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.492552861538462 ], [ 29.751948103448278, -1.492822338461538 ], [ 29.752217586206896, -1.492822338461538 ], [ 29.752217586206896, -1.492552861538462 ], [ 29.751948103448278, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3235, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.492552861538462 ], [ 29.752217586206896, -1.493091815384615 ], [ 29.752487068965518, -1.493091815384615 ], [ 29.752487068965518, -1.492552861538462 ], [ 29.752217586206896, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3236, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.492552861538462 ], [ 29.75275655172414, -1.492822338461538 ], [ 29.753026034482758, -1.492822338461538 ], [ 29.753026034482758, -1.492552861538462 ], [ 29.75275655172414, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3237, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.492552861538462 ], [ 29.753026034482758, -1.492822338461538 ], [ 29.75329551724138, -1.492822338461538 ], [ 29.75329551724138, -1.492552861538462 ], [ 29.753026034482758, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3238, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.492552861538462 ], [ 29.75329551724138, -1.492822338461538 ], [ 29.753565, -1.492822338461538 ], [ 29.753565, -1.492552861538462 ], [ 29.75329551724138, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3239, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.492552861538462 ], [ 29.753565, -1.492822338461538 ], [ 29.75383448275862, -1.492822338461538 ], [ 29.75383448275862, -1.492552861538462 ], [ 29.753565, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3240, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.492552861538462 ], [ 29.75383448275862, -1.492822338461538 ], [ 29.754103965517242, -1.492822338461538 ], [ 29.754103965517242, -1.492552861538462 ], [ 29.75383448275862, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3241, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.492552861538462 ], [ 29.754103965517242, -1.492822338461538 ], [ 29.754373448275864, -1.492822338461538 ], [ 29.754373448275864, -1.492552861538462 ], [ 29.754103965517242, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3242, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.492552861538462 ], [ 29.754373448275864, -1.492822338461538 ], [ 29.754642931034482, -1.492822338461538 ], [ 29.754642931034482, -1.492552861538462 ], [ 29.754373448275864, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3243, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.492552861538462 ], [ 29.754642931034482, -1.492822338461538 ], [ 29.754912413793104, -1.492822338461538 ], [ 29.754912413793104, -1.492552861538462 ], [ 29.754642931034482, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3244, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.492552861538462 ], [ 29.754912413793104, -1.492822338461538 ], [ 29.755181896551726, -1.492822338461538 ], [ 29.755181896551726, -1.492552861538462 ], [ 29.754912413793104, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3245, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.492552861538462 ], [ 29.755181896551726, -1.492822338461538 ], [ 29.755451379310344, -1.492822338461538 ], [ 29.755451379310344, -1.492552861538462 ], [ 29.755181896551726, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3246, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.492552861538462 ], [ 29.755451379310344, -1.492822338461538 ], [ 29.755720862068966, -1.492822338461538 ], [ 29.755720862068966, -1.492552861538462 ], [ 29.755451379310344, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3247, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.492552861538462 ], [ 29.755720862068966, -1.492822338461538 ], [ 29.755990344827588, -1.492822338461538 ], [ 29.755990344827588, -1.492552861538462 ], [ 29.755720862068966, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3248, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.492552861538462 ], [ 29.755990344827588, -1.492822338461538 ], [ 29.756259827586206, -1.492822338461538 ], [ 29.756259827586206, -1.492552861538462 ], [ 29.755990344827588, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3249, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.492552861538462 ], [ 29.756259827586206, -1.492822338461538 ], [ 29.756798793103449, -1.492822338461538 ], [ 29.756798793103449, -1.492552861538462 ], [ 29.756259827586206, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3250, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.492552861538462 ], [ 29.756798793103449, -1.492822338461538 ], [ 29.757068275862068, -1.492822338461538 ], [ 29.757068275862068, -1.492552861538462 ], [ 29.756798793103449, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3251, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.492552861538462 ], [ 29.75733775862069, -1.492822338461538 ], [ 29.757607241379311, -1.492822338461538 ], [ 29.757607241379311, -1.492552861538462 ], [ 29.75733775862069, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3252, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.492552861538462 ], [ 29.757607241379311, -1.493091815384615 ], [ 29.757876724137933, -1.493091815384615 ], [ 29.757876724137933, -1.492552861538462 ], [ 29.757607241379311, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3253, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.492552861538462 ], [ 29.757876724137933, -1.493091815384615 ], [ 29.758146206896551, -1.493091815384615 ], [ 29.758146206896551, -1.492552861538462 ], [ 29.757876724137933, -1.492552861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3254, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.492822338461538 ], [ 29.716915344827587, -1.493091815384615 ], [ 29.717184827586205, -1.493091815384615 ], [ 29.717184827586205, -1.492822338461538 ], [ 29.716915344827587, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3255, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.492822338461538 ], [ 29.717184827586205, -1.493091815384615 ], [ 29.717454310344827, -1.493091815384615 ], [ 29.717454310344827, -1.492822338461538 ], [ 29.717184827586205, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3256, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.492822338461538 ], [ 29.717454310344827, -1.493091815384615 ], [ 29.717993275862067, -1.493091815384615 ], [ 29.717993275862067, -1.492822338461538 ], [ 29.717454310344827, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3257, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.492822338461538 ], [ 29.717993275862067, -1.493091815384615 ], [ 29.718262758620689, -1.493091815384615 ], [ 29.718262758620689, -1.492822338461538 ], [ 29.717993275862067, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3258, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.492822338461538 ], [ 29.718262758620689, -1.493091815384615 ], [ 29.718532241379311, -1.493091815384615 ], [ 29.718532241379311, -1.492822338461538 ], [ 29.718262758620689, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3259, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.492822338461538 ], [ 29.718532241379311, -1.493091815384615 ], [ 29.719071206896551, -1.493091815384615 ], [ 29.719071206896551, -1.492822338461538 ], [ 29.718532241379311, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3260, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.492822338461538 ], [ 29.719071206896551, -1.493091815384615 ], [ 29.719340689655173, -1.493091815384615 ], [ 29.719340689655173, -1.492822338461538 ], [ 29.719071206896551, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3261, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.492822338461538 ], [ 29.719340689655173, -1.493091815384615 ], [ 29.719610172413795, -1.493091815384615 ], [ 29.719610172413795, -1.492822338461538 ], [ 29.719340689655173, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3262, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.492822338461538 ], [ 29.719610172413795, -1.493091815384615 ], [ 29.719879655172413, -1.493091815384615 ], [ 29.719879655172413, -1.492822338461538 ], [ 29.719610172413795, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3263, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.492822338461538 ], [ 29.719879655172413, -1.493091815384615 ], [ 29.720149137931035, -1.493091815384615 ], [ 29.720149137931035, -1.492822338461538 ], [ 29.719879655172413, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3264, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.492822338461538 ], [ 29.720149137931035, -1.493091815384615 ], [ 29.720688103448275, -1.493091815384615 ], [ 29.720688103448275, -1.492822338461538 ], [ 29.720149137931035, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3265, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.492822338461538 ], [ 29.720688103448275, -1.493091815384615 ], [ 29.720957586206897, -1.493091815384615 ], [ 29.720957586206897, -1.492822338461538 ], [ 29.720688103448275, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3266, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.492822338461538 ], [ 29.720957586206897, -1.493091815384615 ], [ 29.721227068965518, -1.493091815384615 ], [ 29.721227068965518, -1.492822338461538 ], [ 29.720957586206897, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3267, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.492822338461538 ], [ 29.721227068965518, -1.493091815384615 ], [ 29.721496551724137, -1.493091815384615 ], [ 29.721496551724137, -1.492822338461538 ], [ 29.721227068965518, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3268, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.492822338461538 ], [ 29.721496551724137, -1.493091815384615 ], [ 29.721766034482759, -1.493091815384615 ], [ 29.721766034482759, -1.492822338461538 ], [ 29.721496551724137, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3269, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.492822338461538 ], [ 29.721766034482759, -1.493091815384615 ], [ 29.72203551724138, -1.493091815384615 ], [ 29.72203551724138, -1.492822338461538 ], [ 29.721766034482759, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3270, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.492822338461538 ], [ 29.72203551724138, -1.493091815384615 ], [ 29.722305, -1.493091815384615 ], [ 29.722305, -1.492822338461538 ], [ 29.72203551724138, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3271, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.492822338461538 ], [ 29.722305, -1.493091815384615 ], [ 29.72257448275862, -1.493091815384615 ], [ 29.72257448275862, -1.492822338461538 ], [ 29.722305, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3272, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.492822338461538 ], [ 29.72257448275862, -1.493091815384615 ], [ 29.722843965517242, -1.493091815384615 ], [ 29.722843965517242, -1.492822338461538 ], [ 29.72257448275862, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3273, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.492822338461538 ], [ 29.722843965517242, -1.493091815384615 ], [ 29.723113448275861, -1.493091815384615 ], [ 29.723113448275861, -1.492822338461538 ], [ 29.722843965517242, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3274, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.492822338461538 ], [ 29.723113448275861, -1.493091815384615 ], [ 29.723382931034482, -1.493091815384615 ], [ 29.723382931034482, -1.492822338461538 ], [ 29.723113448275861, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3275, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.492822338461538 ], [ 29.723382931034482, -1.493091815384615 ], [ 29.723652413793104, -1.493091815384615 ], [ 29.723652413793104, -1.492822338461538 ], [ 29.723382931034482, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3276, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.492822338461538 ], [ 29.723652413793104, -1.493091815384615 ], [ 29.723921896551722, -1.493091815384615 ], [ 29.723921896551722, -1.492822338461538 ], [ 29.723652413793104, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3277, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.492822338461538 ], [ 29.723921896551722, -1.493091815384615 ], [ 29.724191379310344, -1.493091815384615 ], [ 29.724191379310344, -1.492822338461538 ], [ 29.723921896551722, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3278, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.492822338461538 ], [ 29.724191379310344, -1.493091815384615 ], [ 29.724460862068966, -1.493091815384615 ], [ 29.724460862068966, -1.492822338461538 ], [ 29.724191379310344, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3279, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.492822338461538 ], [ 29.724460862068966, -1.493091815384615 ], [ 29.724730344827588, -1.493091815384615 ], [ 29.724730344827588, -1.492822338461538 ], [ 29.724460862068966, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3280, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.492822338461538 ], [ 29.725269310344828, -1.493361292307692 ], [ 29.72553879310345, -1.493361292307692 ], [ 29.72553879310345, -1.492822338461538 ], [ 29.725269310344828, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3281, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.492822338461538 ], [ 29.72553879310345, -1.493361292307692 ], [ 29.725808275862068, -1.493361292307692 ], [ 29.725808275862068, -1.492822338461538 ], [ 29.72553879310345, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3282, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.492822338461538 ], [ 29.726347241379312, -1.493361292307692 ], [ 29.72661672413793, -1.493361292307692 ], [ 29.72661672413793, -1.492822338461538 ], [ 29.726347241379312, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3283, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.492822338461538 ], [ 29.72661672413793, -1.493361292307692 ], [ 29.726886206896552, -1.493361292307692 ], [ 29.726886206896552, -1.492822338461538 ], [ 29.72661672413793, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3284, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.492822338461538 ], [ 29.727155689655174, -1.493630769230769 ], [ 29.727425172413792, -1.493630769230769 ], [ 29.727425172413792, -1.492822338461538 ], [ 29.727155689655174, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3285, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.492822338461538 ], [ 29.727425172413792, -1.493361292307692 ], [ 29.727694655172414, -1.493361292307692 ], [ 29.727694655172414, -1.492822338461538 ], [ 29.727425172413792, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3286, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.492822338461538 ], [ 29.727694655172414, -1.493091815384615 ], [ 29.727964137931036, -1.493091815384615 ], [ 29.727964137931036, -1.492822338461538 ], [ 29.727694655172414, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3287, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.492822338461538 ], [ 29.727964137931036, -1.493091815384615 ], [ 29.728233620689654, -1.493091815384615 ], [ 29.728233620689654, -1.492822338461538 ], [ 29.727964137931036, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3288, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.492822338461538 ], [ 29.728233620689654, -1.493091815384615 ], [ 29.728503103448276, -1.493091815384615 ], [ 29.728503103448276, -1.492822338461538 ], [ 29.728233620689654, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3289, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.492822338461538 ], [ 29.728503103448276, -1.493091815384615 ], [ 29.728772586206897, -1.493091815384615 ], [ 29.728772586206897, -1.492822338461538 ], [ 29.728503103448276, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3290, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.492822338461538 ], [ 29.728772586206897, -1.493091815384615 ], [ 29.729042068965516, -1.493091815384615 ], [ 29.729042068965516, -1.492822338461538 ], [ 29.728772586206897, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3291, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.492822338461538 ], [ 29.729042068965516, -1.493091815384615 ], [ 29.729311551724138, -1.493091815384615 ], [ 29.729311551724138, -1.492822338461538 ], [ 29.729042068965516, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3292, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.492822338461538 ], [ 29.729311551724138, -1.493091815384615 ], [ 29.729850517241381, -1.493091815384615 ], [ 29.729850517241381, -1.492822338461538 ], [ 29.729311551724138, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3293, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.492822338461538 ], [ 29.729850517241381, -1.493091815384615 ], [ 29.73012, -1.493091815384615 ], [ 29.73012, -1.492822338461538 ], [ 29.729850517241381, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3294, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.492822338461538 ], [ 29.73012, -1.493091815384615 ], [ 29.731467413793105, -1.493091815384615 ], [ 29.731467413793105, -1.492822338461538 ], [ 29.73012, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3295, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.492822338461538 ], [ 29.731467413793105, -1.493091815384615 ], [ 29.731736896551723, -1.493091815384615 ], [ 29.731736896551723, -1.492822338461538 ], [ 29.731467413793105, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3296, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.492822338461538 ], [ 29.731736896551723, -1.493091815384615 ], [ 29.732006379310345, -1.493091815384615 ], [ 29.732006379310345, -1.492822338461538 ], [ 29.731736896551723, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3297, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.492822338461538 ], [ 29.732006379310345, -1.493091815384615 ], [ 29.732275862068967, -1.493091815384615 ], [ 29.732275862068967, -1.492822338461538 ], [ 29.732006379310345, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3298, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.492822338461538 ], [ 29.732275862068967, -1.493091815384615 ], [ 29.732545344827585, -1.493091815384615 ], [ 29.732545344827585, -1.492822338461538 ], [ 29.732275862068967, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3299, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.492822338461538 ], [ 29.732545344827585, -1.493091815384615 ], [ 29.732814827586207, -1.493091815384615 ], [ 29.732814827586207, -1.492822338461538 ], [ 29.732545344827585, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3300, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.492822338461538 ], [ 29.732814827586207, -1.493091815384615 ], [ 29.733084310344829, -1.493091815384615 ], [ 29.733084310344829, -1.492822338461538 ], [ 29.732814827586207, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3301, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.492822338461538 ], [ 29.733084310344829, -1.493091815384615 ], [ 29.733353793103447, -1.493091815384615 ], [ 29.733353793103447, -1.492822338461538 ], [ 29.733084310344829, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3302, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.492822338461538 ], [ 29.733353793103447, -1.493091815384615 ], [ 29.733623275862069, -1.493091815384615 ], [ 29.733623275862069, -1.492822338461538 ], [ 29.733353793103447, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3303, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.492822338461538 ], [ 29.733623275862069, -1.493091815384615 ], [ 29.733892758620691, -1.493091815384615 ], [ 29.733892758620691, -1.492822338461538 ], [ 29.733623275862069, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3304, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.492822338461538 ], [ 29.733892758620691, -1.493091815384615 ], [ 29.734162241379309, -1.493091815384615 ], [ 29.734162241379309, -1.492822338461538 ], [ 29.733892758620691, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3305, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.492822338461538 ], [ 29.734162241379309, -1.493091815384615 ], [ 29.734431724137931, -1.493091815384615 ], [ 29.734431724137931, -1.492822338461538 ], [ 29.734162241379309, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3306, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.492822338461538 ], [ 29.734431724137931, -1.493091815384615 ], [ 29.734701206896553, -1.493091815384615 ], [ 29.734701206896553, -1.492822338461538 ], [ 29.734431724137931, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3307, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.492822338461538 ], [ 29.734701206896553, -1.493091815384615 ], [ 29.734970689655171, -1.493091815384615 ], [ 29.734970689655171, -1.492822338461538 ], [ 29.734701206896553, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3308, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.492822338461538 ], [ 29.734970689655171, -1.493091815384615 ], [ 29.735240172413793, -1.493091815384615 ], [ 29.735240172413793, -1.492822338461538 ], [ 29.734970689655171, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3309, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.492822338461538 ], [ 29.735240172413793, -1.493091815384615 ], [ 29.735509655172415, -1.493091815384615 ], [ 29.735509655172415, -1.492822338461538 ], [ 29.735240172413793, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3310, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.492822338461538 ], [ 29.735509655172415, -1.493091815384615 ], [ 29.735779137931036, -1.493091815384615 ], [ 29.735779137931036, -1.492822338461538 ], [ 29.735509655172415, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3311, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.492822338461538 ], [ 29.735779137931036, -1.493091815384615 ], [ 29.736048620689655, -1.493091815384615 ], [ 29.736048620689655, -1.492822338461538 ], [ 29.735779137931036, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3312, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.492822338461538 ], [ 29.736048620689655, -1.493091815384615 ], [ 29.736318103448276, -1.493091815384615 ], [ 29.736318103448276, -1.492822338461538 ], [ 29.736048620689655, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3313, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.492822338461538 ], [ 29.736318103448276, -1.493091815384615 ], [ 29.736587586206898, -1.493091815384615 ], [ 29.736587586206898, -1.492822338461538 ], [ 29.736318103448276, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3314, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.492822338461538 ], [ 29.737126551724138, -1.493361292307692 ], [ 29.73739603448276, -1.493361292307692 ], [ 29.73739603448276, -1.492822338461538 ], [ 29.737126551724138, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3315, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.492822338461538 ], [ 29.73739603448276, -1.493361292307692 ], [ 29.737665517241378, -1.493361292307692 ], [ 29.737665517241378, -1.492822338461538 ], [ 29.73739603448276, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3316, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.492822338461538 ], [ 29.737935, -1.493630769230769 ], [ 29.738204482758622, -1.493630769230769 ], [ 29.738204482758622, -1.492822338461538 ], [ 29.737935, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3317, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.492822338461538 ], [ 29.738204482758622, -1.493361292307692 ], [ 29.738743448275862, -1.493361292307692 ], [ 29.738743448275862, -1.492822338461538 ], [ 29.738204482758622, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3318, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.492822338461538 ], [ 29.738743448275862, -1.493091815384615 ], [ 29.739012931034484, -1.493091815384615 ], [ 29.739012931034484, -1.492822338461538 ], [ 29.738743448275862, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3319, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.492822338461538 ], [ 29.739012931034484, -1.493091815384615 ], [ 29.739282413793102, -1.493091815384615 ], [ 29.739282413793102, -1.492822338461538 ], [ 29.739012931034484, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3320, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.492822338461538 ], [ 29.739282413793102, -1.493091815384615 ], [ 29.739551896551724, -1.493091815384615 ], [ 29.739551896551724, -1.492822338461538 ], [ 29.739282413793102, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3321, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.492822338461538 ], [ 29.739551896551724, -1.493091815384615 ], [ 29.739821379310346, -1.493091815384615 ], [ 29.739821379310346, -1.492822338461538 ], [ 29.739551896551724, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3322, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.492822338461538 ], [ 29.739821379310346, -1.493091815384615 ], [ 29.740090862068964, -1.493091815384615 ], [ 29.740090862068964, -1.492822338461538 ], [ 29.739821379310346, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3323, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.492822338461538 ], [ 29.740090862068964, -1.493091815384615 ], [ 29.740360344827586, -1.493091815384615 ], [ 29.740360344827586, -1.492822338461538 ], [ 29.740090862068964, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3324, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.492822338461538 ], [ 29.740360344827586, -1.493091815384615 ], [ 29.740629827586208, -1.493091815384615 ], [ 29.740629827586208, -1.492822338461538 ], [ 29.740360344827586, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3325, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.492822338461538 ], [ 29.740629827586208, -1.493091815384615 ], [ 29.74089931034483, -1.493091815384615 ], [ 29.74089931034483, -1.492822338461538 ], [ 29.740629827586208, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3326, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.492822338461538 ], [ 29.74089931034483, -1.493091815384615 ], [ 29.743055172413793, -1.493091815384615 ], [ 29.743055172413793, -1.492822338461538 ], [ 29.74089931034483, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3327, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.492822338461538 ], [ 29.743055172413793, -1.493091815384615 ], [ 29.744133103448277, -1.493091815384615 ], [ 29.744133103448277, -1.492822338461538 ], [ 29.743055172413793, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3328, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.492822338461538 ], [ 29.744133103448277, -1.493091815384615 ], [ 29.744402586206895, -1.493091815384615 ], [ 29.744402586206895, -1.492822338461538 ], [ 29.744133103448277, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3329, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.492822338461538 ], [ 29.744402586206895, -1.493091815384615 ], [ 29.74575, -1.493091815384615 ], [ 29.74575, -1.492822338461538 ], [ 29.744402586206895, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3330, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748175344827587, -1.492822338461538 ], [ 29.748175344827587, -1.493361292307692 ], [ 29.748444827586209, -1.493361292307692 ], [ 29.748444827586209, -1.492822338461538 ], [ 29.748175344827587, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3331, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.492822338461538 ], [ 29.748983793103449, -1.493091815384615 ], [ 29.74925327586207, -1.493091815384615 ], [ 29.74925327586207, -1.492822338461538 ], [ 29.748983793103449, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3332, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.492822338461538 ], [ 29.74925327586207, -1.493361292307692 ], [ 29.749522758620689, -1.493361292307692 ], [ 29.749522758620689, -1.492822338461538 ], [ 29.74925327586207, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3333, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.492822338461538 ], [ 29.749522758620689, -1.493091815384615 ], [ 29.749792241379311, -1.493091815384615 ], [ 29.749792241379311, -1.492822338461538 ], [ 29.749522758620689, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3334, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.492822338461538 ], [ 29.749792241379311, -1.493091815384615 ], [ 29.750061724137932, -1.493091815384615 ], [ 29.750061724137932, -1.492822338461538 ], [ 29.749792241379311, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3335, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.492822338461538 ], [ 29.750061724137932, -1.493091815384615 ], [ 29.750331206896551, -1.493091815384615 ], [ 29.750331206896551, -1.492822338461538 ], [ 29.750061724137932, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3336, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.492822338461538 ], [ 29.750331206896551, -1.493361292307692 ], [ 29.750600689655172, -1.493361292307692 ], [ 29.750600689655172, -1.492822338461538 ], [ 29.750331206896551, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3337, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.492822338461538 ], [ 29.750600689655172, -1.493361292307692 ], [ 29.750870172413794, -1.493361292307692 ], [ 29.750870172413794, -1.492822338461538 ], [ 29.750600689655172, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3338, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.492822338461538 ], [ 29.751409137931034, -1.493091815384615 ], [ 29.751678620689656, -1.493091815384615 ], [ 29.751678620689656, -1.492822338461538 ], [ 29.751409137931034, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3339, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.492822338461538 ], [ 29.751678620689656, -1.493091815384615 ], [ 29.751948103448278, -1.493091815384615 ], [ 29.751948103448278, -1.492822338461538 ], [ 29.751678620689656, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3340, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.492822338461538 ], [ 29.751948103448278, -1.493361292307692 ], [ 29.752217586206896, -1.493361292307692 ], [ 29.752217586206896, -1.492822338461538 ], [ 29.751948103448278, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3341, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.492822338461538 ], [ 29.752487068965518, -1.493091815384615 ], [ 29.753026034482758, -1.493091815384615 ], [ 29.753026034482758, -1.492822338461538 ], [ 29.752487068965518, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3342, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.492822338461538 ], [ 29.753026034482758, -1.493091815384615 ], [ 29.75329551724138, -1.493091815384615 ], [ 29.75329551724138, -1.492822338461538 ], [ 29.753026034482758, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3343, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.492822338461538 ], [ 29.75329551724138, -1.493091815384615 ], [ 29.753565, -1.493091815384615 ], [ 29.753565, -1.492822338461538 ], [ 29.75329551724138, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3344, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.492822338461538 ], [ 29.753565, -1.493091815384615 ], [ 29.75383448275862, -1.493091815384615 ], [ 29.75383448275862, -1.492822338461538 ], [ 29.753565, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3345, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.492822338461538 ], [ 29.75383448275862, -1.493091815384615 ], [ 29.754103965517242, -1.493091815384615 ], [ 29.754103965517242, -1.492822338461538 ], [ 29.75383448275862, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3346, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.492822338461538 ], [ 29.754103965517242, -1.493091815384615 ], [ 29.754373448275864, -1.493091815384615 ], [ 29.754373448275864, -1.492822338461538 ], [ 29.754103965517242, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3347, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.492822338461538 ], [ 29.754373448275864, -1.493091815384615 ], [ 29.754642931034482, -1.493091815384615 ], [ 29.754642931034482, -1.492822338461538 ], [ 29.754373448275864, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3348, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.492822338461538 ], [ 29.754642931034482, -1.493091815384615 ], [ 29.754912413793104, -1.493091815384615 ], [ 29.754912413793104, -1.492822338461538 ], [ 29.754642931034482, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3349, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.492822338461538 ], [ 29.754912413793104, -1.493091815384615 ], [ 29.755181896551726, -1.493091815384615 ], [ 29.755181896551726, -1.492822338461538 ], [ 29.754912413793104, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3350, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.492822338461538 ], [ 29.755181896551726, -1.493091815384615 ], [ 29.755451379310344, -1.493091815384615 ], [ 29.755451379310344, -1.492822338461538 ], [ 29.755181896551726, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3351, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.492822338461538 ], [ 29.755451379310344, -1.493091815384615 ], [ 29.755720862068966, -1.493091815384615 ], [ 29.755720862068966, -1.492822338461538 ], [ 29.755451379310344, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3352, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.492822338461538 ], [ 29.755720862068966, -1.493091815384615 ], [ 29.755990344827588, -1.493091815384615 ], [ 29.755990344827588, -1.492822338461538 ], [ 29.755720862068966, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3353, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.492822338461538 ], [ 29.755990344827588, -1.493091815384615 ], [ 29.756259827586206, -1.493091815384615 ], [ 29.756259827586206, -1.492822338461538 ], [ 29.755990344827588, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3354, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.492822338461538 ], [ 29.756259827586206, -1.493091815384615 ], [ 29.756798793103449, -1.493091815384615 ], [ 29.756798793103449, -1.492822338461538 ], [ 29.756259827586206, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3355, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.492822338461538 ], [ 29.756798793103449, -1.493091815384615 ], [ 29.757068275862068, -1.493091815384615 ], [ 29.757068275862068, -1.492822338461538 ], [ 29.756798793103449, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3356, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.492822338461538 ], [ 29.757068275862068, -1.493091815384615 ], [ 29.75733775862069, -1.493091815384615 ], [ 29.75733775862069, -1.492822338461538 ], [ 29.757068275862068, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3357, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.492822338461538 ], [ 29.75733775862069, -1.493361292307692 ], [ 29.757607241379311, -1.493361292307692 ], [ 29.757607241379311, -1.492822338461538 ], [ 29.75733775862069, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3358, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.492822338461538 ], [ 29.758146206896551, -1.493091815384615 ], [ 29.758415689655173, -1.493091815384615 ], [ 29.758415689655173, -1.492822338461538 ], [ 29.758146206896551, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3359, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.492822338461538 ], [ 29.758415689655173, -1.493091815384615 ], [ 29.758954655172413, -1.493091815384615 ], [ 29.758954655172413, -1.492822338461538 ], [ 29.758415689655173, -1.492822338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3360, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.493091815384615 ], [ 29.717184827586205, -1.493361292307692 ], [ 29.717723793103449, -1.493361292307692 ], [ 29.717723793103449, -1.493091815384615 ], [ 29.717184827586205, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3361, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.493091815384615 ], [ 29.717723793103449, -1.493361292307692 ], [ 29.717993275862067, -1.493361292307692 ], [ 29.717993275862067, -1.493091815384615 ], [ 29.717723793103449, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3362, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.493091815384615 ], [ 29.717993275862067, -1.493361292307692 ], [ 29.718262758620689, -1.493361292307692 ], [ 29.718262758620689, -1.493091815384615 ], [ 29.717993275862067, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3363, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.493091815384615 ], [ 29.718262758620689, -1.493361292307692 ], [ 29.718801724137933, -1.493361292307692 ], [ 29.718801724137933, -1.493091815384615 ], [ 29.718262758620689, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3364, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.493091815384615 ], [ 29.718801724137933, -1.493361292307692 ], [ 29.719071206896551, -1.493361292307692 ], [ 29.719071206896551, -1.493091815384615 ], [ 29.718801724137933, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3365, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.493091815384615 ], [ 29.719071206896551, -1.493361292307692 ], [ 29.719340689655173, -1.493361292307692 ], [ 29.719340689655173, -1.493091815384615 ], [ 29.719071206896551, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3366, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.493091815384615 ], [ 29.719340689655173, -1.493361292307692 ], [ 29.719610172413795, -1.493361292307692 ], [ 29.719610172413795, -1.493091815384615 ], [ 29.719340689655173, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3367, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.493091815384615 ], [ 29.719610172413795, -1.493361292307692 ], [ 29.719879655172413, -1.493361292307692 ], [ 29.719879655172413, -1.493091815384615 ], [ 29.719610172413795, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3368, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.493091815384615 ], [ 29.719879655172413, -1.493361292307692 ], [ 29.720149137931035, -1.493361292307692 ], [ 29.720149137931035, -1.493091815384615 ], [ 29.719879655172413, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3369, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.493091815384615 ], [ 29.720149137931035, -1.493361292307692 ], [ 29.720688103448275, -1.493361292307692 ], [ 29.720688103448275, -1.493091815384615 ], [ 29.720149137931035, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3370, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.493091815384615 ], [ 29.720688103448275, -1.493361292307692 ], [ 29.720957586206897, -1.493361292307692 ], [ 29.720957586206897, -1.493091815384615 ], [ 29.720688103448275, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3371, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.493091815384615 ], [ 29.720957586206897, -1.493361292307692 ], [ 29.721227068965518, -1.493361292307692 ], [ 29.721227068965518, -1.493091815384615 ], [ 29.720957586206897, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3372, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.493091815384615 ], [ 29.721227068965518, -1.493361292307692 ], [ 29.721496551724137, -1.493361292307692 ], [ 29.721496551724137, -1.493091815384615 ], [ 29.721227068965518, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3373, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.493091815384615 ], [ 29.721496551724137, -1.493361292307692 ], [ 29.721766034482759, -1.493361292307692 ], [ 29.721766034482759, -1.493091815384615 ], [ 29.721496551724137, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3374, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.493091815384615 ], [ 29.721766034482759, -1.493361292307692 ], [ 29.72203551724138, -1.493361292307692 ], [ 29.72203551724138, -1.493091815384615 ], [ 29.721766034482759, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3375, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.493091815384615 ], [ 29.72203551724138, -1.493361292307692 ], [ 29.722305, -1.493361292307692 ], [ 29.722305, -1.493091815384615 ], [ 29.72203551724138, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3376, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.493091815384615 ], [ 29.722305, -1.493361292307692 ], [ 29.72257448275862, -1.493361292307692 ], [ 29.72257448275862, -1.493091815384615 ], [ 29.722305, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3377, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.493091815384615 ], [ 29.72257448275862, -1.493361292307692 ], [ 29.722843965517242, -1.493361292307692 ], [ 29.722843965517242, -1.493091815384615 ], [ 29.72257448275862, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3378, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.493091815384615 ], [ 29.722843965517242, -1.493361292307692 ], [ 29.723113448275861, -1.493361292307692 ], [ 29.723113448275861, -1.493091815384615 ], [ 29.722843965517242, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3379, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.493091815384615 ], [ 29.723113448275861, -1.493630769230769 ], [ 29.723382931034482, -1.493630769230769 ], [ 29.723382931034482, -1.493091815384615 ], [ 29.723113448275861, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3380, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.493091815384615 ], [ 29.723382931034482, -1.493361292307692 ], [ 29.723652413793104, -1.493361292307692 ], [ 29.723652413793104, -1.493091815384615 ], [ 29.723382931034482, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3381, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.493091815384615 ], [ 29.723652413793104, -1.493361292307692 ], [ 29.723921896551722, -1.493361292307692 ], [ 29.723921896551722, -1.493091815384615 ], [ 29.723652413793104, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3382, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.493091815384615 ], [ 29.723921896551722, -1.493361292307692 ], [ 29.724191379310344, -1.493361292307692 ], [ 29.724191379310344, -1.493091815384615 ], [ 29.723921896551722, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3383, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.493091815384615 ], [ 29.724191379310344, -1.493361292307692 ], [ 29.724460862068966, -1.493361292307692 ], [ 29.724460862068966, -1.493091815384615 ], [ 29.724191379310344, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3384, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.493091815384615 ], [ 29.724460862068966, -1.493630769230769 ], [ 29.724730344827588, -1.493630769230769 ], [ 29.724730344827588, -1.493091815384615 ], [ 29.724460862068966, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3385, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.493091815384615 ], [ 29.724730344827588, -1.493630769230769 ], [ 29.724999827586206, -1.493630769230769 ], [ 29.724999827586206, -1.493091815384615 ], [ 29.724730344827588, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3386, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.493091815384615 ], [ 29.724999827586206, -1.493361292307692 ], [ 29.725269310344828, -1.493361292307692 ], [ 29.725269310344828, -1.493091815384615 ], [ 29.724999827586206, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3387, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.493091815384615 ], [ 29.725808275862068, -1.493630769230769 ], [ 29.72607775862069, -1.493630769230769 ], [ 29.72607775862069, -1.493091815384615 ], [ 29.725808275862068, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3388, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.493091815384615 ], [ 29.72607775862069, -1.493630769230769 ], [ 29.726347241379312, -1.493630769230769 ], [ 29.726347241379312, -1.493091815384615 ], [ 29.72607775862069, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3389, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.493091815384615 ], [ 29.726886206896552, -1.493630769230769 ], [ 29.727155689655174, -1.493630769230769 ], [ 29.727155689655174, -1.493091815384615 ], [ 29.726886206896552, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3390, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.493091815384615 ], [ 29.727694655172414, -1.493900246153846 ], [ 29.727964137931036, -1.493900246153846 ], [ 29.727964137931036, -1.493091815384615 ], [ 29.727694655172414, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3391, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.493091815384615 ], [ 29.727964137931036, -1.493900246153846 ], [ 29.728233620689654, -1.493900246153846 ], [ 29.728233620689654, -1.493091815384615 ], [ 29.727964137931036, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3392, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.493091815384615 ], [ 29.728233620689654, -1.493361292307692 ], [ 29.728503103448276, -1.493361292307692 ], [ 29.728503103448276, -1.493091815384615 ], [ 29.728233620689654, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3393, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.493091815384615 ], [ 29.728503103448276, -1.493361292307692 ], [ 29.728772586206897, -1.493361292307692 ], [ 29.728772586206897, -1.493091815384615 ], [ 29.728503103448276, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3394, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.493091815384615 ], [ 29.728772586206897, -1.493361292307692 ], [ 29.729042068965516, -1.493361292307692 ], [ 29.729042068965516, -1.493091815384615 ], [ 29.728772586206897, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3395, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.493091815384615 ], [ 29.729042068965516, -1.493630769230769 ], [ 29.729311551724138, -1.493630769230769 ], [ 29.729311551724138, -1.493091815384615 ], [ 29.729042068965516, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3396, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.493091815384615 ], [ 29.729311551724138, -1.493361292307692 ], [ 29.729850517241381, -1.493361292307692 ], [ 29.729850517241381, -1.493091815384615 ], [ 29.729311551724138, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3397, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.493091815384615 ], [ 29.729850517241381, -1.493361292307692 ], [ 29.73012, -1.493361292307692 ], [ 29.73012, -1.493091815384615 ], [ 29.729850517241381, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3398, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.493091815384615 ], [ 29.73012, -1.493361292307692 ], [ 29.731467413793105, -1.493361292307692 ], [ 29.731467413793105, -1.493091815384615 ], [ 29.73012, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3399, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.493091815384615 ], [ 29.731467413793105, -1.493361292307692 ], [ 29.731736896551723, -1.493361292307692 ], [ 29.731736896551723, -1.493091815384615 ], [ 29.731467413793105, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3400, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.493091815384615 ], [ 29.731736896551723, -1.493361292307692 ], [ 29.732006379310345, -1.493361292307692 ], [ 29.732006379310345, -1.493091815384615 ], [ 29.731736896551723, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3401, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.493091815384615 ], [ 29.732006379310345, -1.493361292307692 ], [ 29.732275862068967, -1.493361292307692 ], [ 29.732275862068967, -1.493091815384615 ], [ 29.732006379310345, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3402, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.493091815384615 ], [ 29.732275862068967, -1.493361292307692 ], [ 29.732545344827585, -1.493361292307692 ], [ 29.732545344827585, -1.493091815384615 ], [ 29.732275862068967, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3403, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.493091815384615 ], [ 29.732545344827585, -1.493361292307692 ], [ 29.732814827586207, -1.493361292307692 ], [ 29.732814827586207, -1.493091815384615 ], [ 29.732545344827585, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3404, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.493091815384615 ], [ 29.732814827586207, -1.493361292307692 ], [ 29.733084310344829, -1.493361292307692 ], [ 29.733084310344829, -1.493091815384615 ], [ 29.732814827586207, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3405, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.493091815384615 ], [ 29.733084310344829, -1.493361292307692 ], [ 29.733353793103447, -1.493361292307692 ], [ 29.733353793103447, -1.493091815384615 ], [ 29.733084310344829, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3406, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.493091815384615 ], [ 29.733353793103447, -1.493361292307692 ], [ 29.733623275862069, -1.493361292307692 ], [ 29.733623275862069, -1.493091815384615 ], [ 29.733353793103447, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3407, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.493091815384615 ], [ 29.733623275862069, -1.493361292307692 ], [ 29.733892758620691, -1.493361292307692 ], [ 29.733892758620691, -1.493091815384615 ], [ 29.733623275862069, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3408, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.493091815384615 ], [ 29.733892758620691, -1.493361292307692 ], [ 29.734162241379309, -1.493361292307692 ], [ 29.734162241379309, -1.493091815384615 ], [ 29.733892758620691, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3409, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.493091815384615 ], [ 29.734162241379309, -1.493361292307692 ], [ 29.734431724137931, -1.493361292307692 ], [ 29.734431724137931, -1.493091815384615 ], [ 29.734162241379309, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3410, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.493091815384615 ], [ 29.734431724137931, -1.493361292307692 ], [ 29.734701206896553, -1.493361292307692 ], [ 29.734701206896553, -1.493091815384615 ], [ 29.734431724137931, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3411, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.493091815384615 ], [ 29.734701206896553, -1.493361292307692 ], [ 29.734970689655171, -1.493361292307692 ], [ 29.734970689655171, -1.493091815384615 ], [ 29.734701206896553, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3412, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.493091815384615 ], [ 29.734970689655171, -1.493361292307692 ], [ 29.735240172413793, -1.493361292307692 ], [ 29.735240172413793, -1.493091815384615 ], [ 29.734970689655171, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3413, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.493091815384615 ], [ 29.735240172413793, -1.493361292307692 ], [ 29.735509655172415, -1.493361292307692 ], [ 29.735509655172415, -1.493091815384615 ], [ 29.735240172413793, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3414, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.493091815384615 ], [ 29.735509655172415, -1.493361292307692 ], [ 29.735779137931036, -1.493361292307692 ], [ 29.735779137931036, -1.493091815384615 ], [ 29.735509655172415, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3415, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.493091815384615 ], [ 29.735779137931036, -1.493361292307692 ], [ 29.736048620689655, -1.493361292307692 ], [ 29.736048620689655, -1.493091815384615 ], [ 29.735779137931036, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3416, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.493091815384615 ], [ 29.736048620689655, -1.493361292307692 ], [ 29.736318103448276, -1.493361292307692 ], [ 29.736318103448276, -1.493091815384615 ], [ 29.736048620689655, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3417, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.493091815384615 ], [ 29.736318103448276, -1.493630769230769 ], [ 29.736587586206898, -1.493630769230769 ], [ 29.736587586206898, -1.493091815384615 ], [ 29.736318103448276, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3418, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.493091815384615 ], [ 29.736587586206898, -1.493630769230769 ], [ 29.736857068965517, -1.493630769230769 ], [ 29.736857068965517, -1.493091815384615 ], [ 29.736587586206898, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3419, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.493091815384615 ], [ 29.736857068965517, -1.493630769230769 ], [ 29.737126551724138, -1.493630769230769 ], [ 29.737126551724138, -1.493091815384615 ], [ 29.736857068965517, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3420, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.493091815384615 ], [ 29.737665517241378, -1.493630769230769 ], [ 29.737935, -1.493630769230769 ], [ 29.737935, -1.493091815384615 ], [ 29.737665517241378, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3421, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.493091815384615 ], [ 29.738743448275862, -1.493900246153846 ], [ 29.739012931034484, -1.493900246153846 ], [ 29.739012931034484, -1.493091815384615 ], [ 29.738743448275862, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3422, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.493091815384615 ], [ 29.739012931034484, -1.493900246153846 ], [ 29.739282413793102, -1.493900246153846 ], [ 29.739282413793102, -1.493091815384615 ], [ 29.739012931034484, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3423, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.493091815384615 ], [ 29.739282413793102, -1.493361292307692 ], [ 29.739551896551724, -1.493361292307692 ], [ 29.739551896551724, -1.493091815384615 ], [ 29.739282413793102, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3424, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.493091815384615 ], [ 29.739551896551724, -1.493361292307692 ], [ 29.739821379310346, -1.493361292307692 ], [ 29.739821379310346, -1.493091815384615 ], [ 29.739551896551724, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3425, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.493091815384615 ], [ 29.739821379310346, -1.493361292307692 ], [ 29.740090862068964, -1.493361292307692 ], [ 29.740090862068964, -1.493091815384615 ], [ 29.739821379310346, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3426, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.493091815384615 ], [ 29.740090862068964, -1.493630769230769 ], [ 29.740360344827586, -1.493630769230769 ], [ 29.740360344827586, -1.493091815384615 ], [ 29.740090862068964, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3427, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.493091815384615 ], [ 29.740360344827586, -1.493361292307692 ], [ 29.740629827586208, -1.493361292307692 ], [ 29.740629827586208, -1.493091815384615 ], [ 29.740360344827586, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3428, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.493091815384615 ], [ 29.740629827586208, -1.493361292307692 ], [ 29.74089931034483, -1.493361292307692 ], [ 29.74089931034483, -1.493091815384615 ], [ 29.740629827586208, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3429, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.493091815384615 ], [ 29.74089931034483, -1.493361292307692 ], [ 29.743055172413793, -1.493361292307692 ], [ 29.743055172413793, -1.493091815384615 ], [ 29.74089931034483, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3430, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.493091815384615 ], [ 29.743055172413793, -1.493361292307692 ], [ 29.743324655172415, -1.493361292307692 ], [ 29.743324655172415, -1.493091815384615 ], [ 29.743055172413793, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3431, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.493091815384615 ], [ 29.743324655172415, -1.493361292307692 ], [ 29.744402586206895, -1.493361292307692 ], [ 29.744402586206895, -1.493091815384615 ], [ 29.743324655172415, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3432, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.493091815384615 ], [ 29.748444827586209, -1.493361292307692 ], [ 29.748714310344827, -1.493361292307692 ], [ 29.748714310344827, -1.493091815384615 ], [ 29.748444827586209, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3433, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.493091815384615 ], [ 29.748714310344827, -1.493900246153846 ], [ 29.748983793103449, -1.493900246153846 ], [ 29.748983793103449, -1.493091815384615 ], [ 29.748714310344827, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3434, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.493091815384615 ], [ 29.748983793103449, -1.493900246153846 ], [ 29.74925327586207, -1.493900246153846 ], [ 29.74925327586207, -1.493091815384615 ], [ 29.748983793103449, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3435, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.493091815384615 ], [ 29.749522758620689, -1.493630769230769 ], [ 29.749792241379311, -1.493630769230769 ], [ 29.749792241379311, -1.493091815384615 ], [ 29.749522758620689, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3436, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.493091815384615 ], [ 29.749792241379311, -1.493630769230769 ], [ 29.750061724137932, -1.493630769230769 ], [ 29.750061724137932, -1.493091815384615 ], [ 29.749792241379311, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3437, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.493091815384615 ], [ 29.750061724137932, -1.493630769230769 ], [ 29.750331206896551, -1.493630769230769 ], [ 29.750331206896551, -1.493091815384615 ], [ 29.750061724137932, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3438, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.493091815384615 ], [ 29.750870172413794, -1.493361292307692 ], [ 29.751139655172413, -1.493361292307692 ], [ 29.751139655172413, -1.493091815384615 ], [ 29.750870172413794, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3439, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.493091815384615 ], [ 29.751139655172413, -1.493361292307692 ], [ 29.751409137931034, -1.493361292307692 ], [ 29.751409137931034, -1.493091815384615 ], [ 29.751139655172413, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3440, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.493091815384615 ], [ 29.751409137931034, -1.493361292307692 ], [ 29.751678620689656, -1.493361292307692 ], [ 29.751678620689656, -1.493091815384615 ], [ 29.751409137931034, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3441, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.493091815384615 ], [ 29.751678620689656, -1.493630769230769 ], [ 29.751948103448278, -1.493630769230769 ], [ 29.751948103448278, -1.493091815384615 ], [ 29.751678620689656, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3442, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.493091815384615 ], [ 29.752217586206896, -1.493361292307692 ], [ 29.752487068965518, -1.493361292307692 ], [ 29.752487068965518, -1.493091815384615 ], [ 29.752217586206896, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3443, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.493091815384615 ], [ 29.752487068965518, -1.493361292307692 ], [ 29.75275655172414, -1.493361292307692 ], [ 29.75275655172414, -1.493091815384615 ], [ 29.752487068965518, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3444, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.493091815384615 ], [ 29.75275655172414, -1.493361292307692 ], [ 29.753026034482758, -1.493361292307692 ], [ 29.753026034482758, -1.493091815384615 ], [ 29.75275655172414, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3445, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.493091815384615 ], [ 29.753026034482758, -1.493361292307692 ], [ 29.75329551724138, -1.493361292307692 ], [ 29.75329551724138, -1.493091815384615 ], [ 29.753026034482758, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3446, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.493091815384615 ], [ 29.75329551724138, -1.493361292307692 ], [ 29.753565, -1.493361292307692 ], [ 29.753565, -1.493091815384615 ], [ 29.75329551724138, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3447, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.493091815384615 ], [ 29.753565, -1.493361292307692 ], [ 29.75383448275862, -1.493361292307692 ], [ 29.75383448275862, -1.493091815384615 ], [ 29.753565, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3448, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.493091815384615 ], [ 29.75383448275862, -1.493361292307692 ], [ 29.754103965517242, -1.493361292307692 ], [ 29.754103965517242, -1.493091815384615 ], [ 29.75383448275862, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3449, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.493091815384615 ], [ 29.754103965517242, -1.493361292307692 ], [ 29.754373448275864, -1.493361292307692 ], [ 29.754373448275864, -1.493091815384615 ], [ 29.754103965517242, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3450, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.493091815384615 ], [ 29.754373448275864, -1.493361292307692 ], [ 29.754642931034482, -1.493361292307692 ], [ 29.754642931034482, -1.493091815384615 ], [ 29.754373448275864, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3451, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.493091815384615 ], [ 29.754642931034482, -1.493361292307692 ], [ 29.754912413793104, -1.493361292307692 ], [ 29.754912413793104, -1.493091815384615 ], [ 29.754642931034482, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3452, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.493091815384615 ], [ 29.754912413793104, -1.493361292307692 ], [ 29.755181896551726, -1.493361292307692 ], [ 29.755181896551726, -1.493091815384615 ], [ 29.754912413793104, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3453, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.493091815384615 ], [ 29.755181896551726, -1.493361292307692 ], [ 29.755451379310344, -1.493361292307692 ], [ 29.755451379310344, -1.493091815384615 ], [ 29.755181896551726, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3454, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.493091815384615 ], [ 29.755451379310344, -1.493361292307692 ], [ 29.755720862068966, -1.493361292307692 ], [ 29.755720862068966, -1.493091815384615 ], [ 29.755451379310344, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3455, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.493091815384615 ], [ 29.755720862068966, -1.493361292307692 ], [ 29.755990344827588, -1.493361292307692 ], [ 29.755990344827588, -1.493091815384615 ], [ 29.755720862068966, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3456, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.493091815384615 ], [ 29.755990344827588, -1.493361292307692 ], [ 29.756259827586206, -1.493361292307692 ], [ 29.756259827586206, -1.493091815384615 ], [ 29.755990344827588, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3457, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.493091815384615 ], [ 29.756259827586206, -1.493361292307692 ], [ 29.756798793103449, -1.493361292307692 ], [ 29.756798793103449, -1.493091815384615 ], [ 29.756259827586206, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3458, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.493091815384615 ], [ 29.756798793103449, -1.493361292307692 ], [ 29.757068275862068, -1.493361292307692 ], [ 29.757068275862068, -1.493091815384615 ], [ 29.756798793103449, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3459, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.493091815384615 ], [ 29.757068275862068, -1.493361292307692 ], [ 29.75733775862069, -1.493361292307692 ], [ 29.75733775862069, -1.493091815384615 ], [ 29.757068275862068, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3460, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.493091815384615 ], [ 29.757607241379311, -1.493361292307692 ], [ 29.757876724137933, -1.493361292307692 ], [ 29.757876724137933, -1.493091815384615 ], [ 29.757607241379311, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3461, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.493091815384615 ], [ 29.757876724137933, -1.493361292307692 ], [ 29.758146206896551, -1.493361292307692 ], [ 29.758146206896551, -1.493091815384615 ], [ 29.757876724137933, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3462, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.493091815384615 ], [ 29.758146206896551, -1.493361292307692 ], [ 29.758685172413795, -1.493361292307692 ], [ 29.758685172413795, -1.493091815384615 ], [ 29.758146206896551, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3463, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.493091815384615 ], [ 29.758685172413795, -1.493361292307692 ], [ 29.758954655172413, -1.493361292307692 ], [ 29.758954655172413, -1.493091815384615 ], [ 29.758685172413795, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3464, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.493091815384615 ], [ 29.758954655172413, -1.493630769230769 ], [ 29.759224137931035, -1.493630769230769 ], [ 29.759224137931035, -1.493091815384615 ], [ 29.758954655172413, -1.493091815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3465, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.493361292307692 ], [ 29.717723793103449, -1.493630769230769 ], [ 29.717993275862067, -1.493630769230769 ], [ 29.717993275862067, -1.493361292307692 ], [ 29.717723793103449, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3466, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.493361292307692 ], [ 29.717993275862067, -1.493630769230769 ], [ 29.718262758620689, -1.493630769230769 ], [ 29.718262758620689, -1.493361292307692 ], [ 29.717993275862067, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3467, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.493361292307692 ], [ 29.718262758620689, -1.493630769230769 ], [ 29.718801724137933, -1.493630769230769 ], [ 29.718801724137933, -1.493361292307692 ], [ 29.718262758620689, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3468, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.493361292307692 ], [ 29.718801724137933, -1.493630769230769 ], [ 29.719071206896551, -1.493630769230769 ], [ 29.719071206896551, -1.493361292307692 ], [ 29.718801724137933, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3469, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.493361292307692 ], [ 29.719071206896551, -1.493630769230769 ], [ 29.719340689655173, -1.493630769230769 ], [ 29.719340689655173, -1.493361292307692 ], [ 29.719071206896551, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3470, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.493361292307692 ], [ 29.719340689655173, -1.493900246153846 ], [ 29.719610172413795, -1.493900246153846 ], [ 29.719610172413795, -1.493361292307692 ], [ 29.719340689655173, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3471, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.493361292307692 ], [ 29.719610172413795, -1.493630769230769 ], [ 29.719879655172413, -1.493630769230769 ], [ 29.719879655172413, -1.493361292307692 ], [ 29.719610172413795, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3472, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.493361292307692 ], [ 29.719879655172413, -1.493630769230769 ], [ 29.720149137931035, -1.493630769230769 ], [ 29.720149137931035, -1.493361292307692 ], [ 29.719879655172413, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3473, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.493361292307692 ], [ 29.720149137931035, -1.493630769230769 ], [ 29.720688103448275, -1.493630769230769 ], [ 29.720688103448275, -1.493361292307692 ], [ 29.720149137931035, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3474, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.493361292307692 ], [ 29.720688103448275, -1.493630769230769 ], [ 29.720957586206897, -1.493630769230769 ], [ 29.720957586206897, -1.493361292307692 ], [ 29.720688103448275, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3475, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.493361292307692 ], [ 29.720957586206897, -1.493630769230769 ], [ 29.721227068965518, -1.493630769230769 ], [ 29.721227068965518, -1.493361292307692 ], [ 29.720957586206897, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3476, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.493361292307692 ], [ 29.721227068965518, -1.493630769230769 ], [ 29.721496551724137, -1.493630769230769 ], [ 29.721496551724137, -1.493361292307692 ], [ 29.721227068965518, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3477, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.493361292307692 ], [ 29.721496551724137, -1.493630769230769 ], [ 29.721766034482759, -1.493630769230769 ], [ 29.721766034482759, -1.493361292307692 ], [ 29.721496551724137, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3478, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.493361292307692 ], [ 29.721766034482759, -1.493630769230769 ], [ 29.72203551724138, -1.493630769230769 ], [ 29.72203551724138, -1.493361292307692 ], [ 29.721766034482759, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3479, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.493361292307692 ], [ 29.72203551724138, -1.493630769230769 ], [ 29.722305, -1.493630769230769 ], [ 29.722305, -1.493361292307692 ], [ 29.72203551724138, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3480, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.493361292307692 ], [ 29.722305, -1.493630769230769 ], [ 29.72257448275862, -1.493630769230769 ], [ 29.72257448275862, -1.493361292307692 ], [ 29.722305, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3481, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.493361292307692 ], [ 29.72257448275862, -1.493630769230769 ], [ 29.722843965517242, -1.493630769230769 ], [ 29.722843965517242, -1.493361292307692 ], [ 29.72257448275862, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3482, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.493361292307692 ], [ 29.722843965517242, -1.493900246153846 ], [ 29.723113448275861, -1.493900246153846 ], [ 29.723113448275861, -1.493361292307692 ], [ 29.722843965517242, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3483, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.493361292307692 ], [ 29.723382931034482, -1.493630769230769 ], [ 29.723652413793104, -1.493630769230769 ], [ 29.723652413793104, -1.493361292307692 ], [ 29.723382931034482, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3484, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.493361292307692 ], [ 29.723652413793104, -1.493630769230769 ], [ 29.723921896551722, -1.493630769230769 ], [ 29.723921896551722, -1.493361292307692 ], [ 29.723652413793104, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3485, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.493361292307692 ], [ 29.723921896551722, -1.493630769230769 ], [ 29.724191379310344, -1.493630769230769 ], [ 29.724191379310344, -1.493361292307692 ], [ 29.723921896551722, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3486, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.493361292307692 ], [ 29.724191379310344, -1.493900246153846 ], [ 29.724460862068966, -1.493900246153846 ], [ 29.724460862068966, -1.493361292307692 ], [ 29.724191379310344, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3487, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.493361292307692 ], [ 29.724999827586206, -1.493900246153846 ], [ 29.725269310344828, -1.493900246153846 ], [ 29.725269310344828, -1.493361292307692 ], [ 29.724999827586206, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3488, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.493361292307692 ], [ 29.725269310344828, -1.493900246153846 ], [ 29.72553879310345, -1.493900246153846 ], [ 29.72553879310345, -1.493361292307692 ], [ 29.725269310344828, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3489, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.493361292307692 ], [ 29.72553879310345, -1.493630769230769 ], [ 29.725808275862068, -1.493630769230769 ], [ 29.725808275862068, -1.493361292307692 ], [ 29.72553879310345, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3490, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.493361292307692 ], [ 29.726347241379312, -1.493900246153846 ], [ 29.72661672413793, -1.493900246153846 ], [ 29.72661672413793, -1.493361292307692 ], [ 29.726347241379312, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3491, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.493361292307692 ], [ 29.72661672413793, -1.493630769230769 ], [ 29.726886206896552, -1.493630769230769 ], [ 29.726886206896552, -1.493361292307692 ], [ 29.72661672413793, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3492, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.493361292307692 ], [ 29.727425172413792, -1.493900246153846 ], [ 29.727694655172414, -1.493900246153846 ], [ 29.727694655172414, -1.493361292307692 ], [ 29.727425172413792, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3493, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.493361292307692 ], [ 29.728233620689654, -1.494169723076923 ], [ 29.728503103448276, -1.494169723076923 ], [ 29.728503103448276, -1.493361292307692 ], [ 29.728233620689654, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3494, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.493361292307692 ], [ 29.728503103448276, -1.494169723076923 ], [ 29.728772586206897, -1.494169723076923 ], [ 29.728772586206897, -1.493361292307692 ], [ 29.728503103448276, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3495, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.493361292307692 ], [ 29.728772586206897, -1.494169723076923 ], [ 29.729042068965516, -1.494169723076923 ], [ 29.729042068965516, -1.493361292307692 ], [ 29.728772586206897, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3496, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.493361292307692 ], [ 29.73012, -1.493630769230769 ], [ 29.730389482758621, -1.493630769230769 ], [ 29.730389482758621, -1.493361292307692 ], [ 29.73012, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3497, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.493361292307692 ], [ 29.730389482758621, -1.493630769230769 ], [ 29.731197931034483, -1.493630769230769 ], [ 29.731197931034483, -1.493361292307692 ], [ 29.730389482758621, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3498, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.493361292307692 ], [ 29.731197931034483, -1.493630769230769 ], [ 29.731467413793105, -1.493630769230769 ], [ 29.731467413793105, -1.493361292307692 ], [ 29.731197931034483, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3499, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.493361292307692 ], [ 29.731467413793105, -1.493630769230769 ], [ 29.731736896551723, -1.493630769230769 ], [ 29.731736896551723, -1.493361292307692 ], [ 29.731467413793105, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3500, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.493361292307692 ], [ 29.731736896551723, -1.493630769230769 ], [ 29.732006379310345, -1.493630769230769 ], [ 29.732006379310345, -1.493361292307692 ], [ 29.731736896551723, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3501, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.493361292307692 ], [ 29.732006379310345, -1.493630769230769 ], [ 29.732275862068967, -1.493630769230769 ], [ 29.732275862068967, -1.493361292307692 ], [ 29.732006379310345, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3502, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.493361292307692 ], [ 29.732275862068967, -1.493630769230769 ], [ 29.732814827586207, -1.493630769230769 ], [ 29.732814827586207, -1.493361292307692 ], [ 29.732275862068967, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3503, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.493361292307692 ], [ 29.732814827586207, -1.493630769230769 ], [ 29.733084310344829, -1.493630769230769 ], [ 29.733084310344829, -1.493361292307692 ], [ 29.732814827586207, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3504, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.493361292307692 ], [ 29.733084310344829, -1.493630769230769 ], [ 29.733353793103447, -1.493630769230769 ], [ 29.733353793103447, -1.493361292307692 ], [ 29.733084310344829, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3505, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.493361292307692 ], [ 29.733353793103447, -1.493630769230769 ], [ 29.733623275862069, -1.493630769230769 ], [ 29.733623275862069, -1.493361292307692 ], [ 29.733353793103447, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3506, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.493361292307692 ], [ 29.733623275862069, -1.493630769230769 ], [ 29.733892758620691, -1.493630769230769 ], [ 29.733892758620691, -1.493361292307692 ], [ 29.733623275862069, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3507, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.493361292307692 ], [ 29.733892758620691, -1.493630769230769 ], [ 29.734162241379309, -1.493630769230769 ], [ 29.734162241379309, -1.493361292307692 ], [ 29.733892758620691, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3508, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.493361292307692 ], [ 29.734162241379309, -1.493630769230769 ], [ 29.734431724137931, -1.493630769230769 ], [ 29.734431724137931, -1.493361292307692 ], [ 29.734162241379309, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3509, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.493361292307692 ], [ 29.734431724137931, -1.493630769230769 ], [ 29.734701206896553, -1.493630769230769 ], [ 29.734701206896553, -1.493361292307692 ], [ 29.734431724137931, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3510, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.493361292307692 ], [ 29.734701206896553, -1.493630769230769 ], [ 29.734970689655171, -1.493630769230769 ], [ 29.734970689655171, -1.493361292307692 ], [ 29.734701206896553, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3511, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.493361292307692 ], [ 29.734970689655171, -1.493630769230769 ], [ 29.735240172413793, -1.493630769230769 ], [ 29.735240172413793, -1.493361292307692 ], [ 29.734970689655171, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3512, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.493361292307692 ], [ 29.735240172413793, -1.493630769230769 ], [ 29.735509655172415, -1.493630769230769 ], [ 29.735509655172415, -1.493361292307692 ], [ 29.735240172413793, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3513, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.493361292307692 ], [ 29.735509655172415, -1.493630769230769 ], [ 29.735779137931036, -1.493630769230769 ], [ 29.735779137931036, -1.493361292307692 ], [ 29.735509655172415, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3514, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.493361292307692 ], [ 29.735779137931036, -1.493630769230769 ], [ 29.736048620689655, -1.493630769230769 ], [ 29.736048620689655, -1.493361292307692 ], [ 29.735779137931036, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3515, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.493361292307692 ], [ 29.736048620689655, -1.493900246153846 ], [ 29.736318103448276, -1.493900246153846 ], [ 29.736318103448276, -1.493361292307692 ], [ 29.736048620689655, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3516, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.493361292307692 ], [ 29.737126551724138, -1.493900246153846 ], [ 29.73739603448276, -1.493900246153846 ], [ 29.73739603448276, -1.493361292307692 ], [ 29.737126551724138, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3517, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.493361292307692 ], [ 29.73739603448276, -1.493630769230769 ], [ 29.737665517241378, -1.493630769230769 ], [ 29.737665517241378, -1.493361292307692 ], [ 29.73739603448276, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3518, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.493361292307692 ], [ 29.738204482758622, -1.493900246153846 ], [ 29.738743448275862, -1.493900246153846 ], [ 29.738743448275862, -1.493361292307692 ], [ 29.738204482758622, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3519, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.493361292307692 ], [ 29.739282413793102, -1.494169723076923 ], [ 29.739551896551724, -1.494169723076923 ], [ 29.739551896551724, -1.493361292307692 ], [ 29.739282413793102, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3520, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.493361292307692 ], [ 29.739551896551724, -1.494169723076923 ], [ 29.739821379310346, -1.494169723076923 ], [ 29.739821379310346, -1.493361292307692 ], [ 29.739551896551724, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3521, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.493361292307692 ], [ 29.739821379310346, -1.494169723076923 ], [ 29.740090862068964, -1.494169723076923 ], [ 29.740090862068964, -1.493361292307692 ], [ 29.739821379310346, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3522, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.493361292307692 ], [ 29.74089931034483, -1.493630769230769 ], [ 29.741168793103448, -1.493630769230769 ], [ 29.741168793103448, -1.493361292307692 ], [ 29.74089931034483, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3523, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.493361292307692 ], [ 29.741168793103448, -1.493630769230769 ], [ 29.743324655172415, -1.493630769230769 ], [ 29.743324655172415, -1.493361292307692 ], [ 29.741168793103448, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3524, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.493361292307692 ], [ 29.748444827586209, -1.494169723076923 ], [ 29.748714310344827, -1.494169723076923 ], [ 29.748714310344827, -1.493361292307692 ], [ 29.748444827586209, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3525, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.493361292307692 ], [ 29.74925327586207, -1.493630769230769 ], [ 29.749522758620689, -1.493630769230769 ], [ 29.749522758620689, -1.493361292307692 ], [ 29.74925327586207, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3526, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.493361292307692 ], [ 29.750331206896551, -1.493630769230769 ], [ 29.750600689655172, -1.493630769230769 ], [ 29.750600689655172, -1.493361292307692 ], [ 29.750331206896551, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3527, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.493361292307692 ], [ 29.750600689655172, -1.493630769230769 ], [ 29.750870172413794, -1.493630769230769 ], [ 29.750870172413794, -1.493361292307692 ], [ 29.750600689655172, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3528, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.493361292307692 ], [ 29.750870172413794, -1.493630769230769 ], [ 29.751139655172413, -1.493630769230769 ], [ 29.751139655172413, -1.493361292307692 ], [ 29.750870172413794, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3529, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.493361292307692 ], [ 29.751139655172413, -1.493900246153846 ], [ 29.751409137931034, -1.493900246153846 ], [ 29.751409137931034, -1.493361292307692 ], [ 29.751139655172413, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3530, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.493361292307692 ], [ 29.751409137931034, -1.493900246153846 ], [ 29.751678620689656, -1.493900246153846 ], [ 29.751678620689656, -1.493361292307692 ], [ 29.751409137931034, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3531, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.493361292307692 ], [ 29.751948103448278, -1.493630769230769 ], [ 29.752217586206896, -1.493630769230769 ], [ 29.752217586206896, -1.493361292307692 ], [ 29.751948103448278, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3532, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.493361292307692 ], [ 29.752217586206896, -1.493630769230769 ], [ 29.752487068965518, -1.493630769230769 ], [ 29.752487068965518, -1.493361292307692 ], [ 29.752217586206896, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3533, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.493361292307692 ], [ 29.752487068965518, -1.493630769230769 ], [ 29.75275655172414, -1.493630769230769 ], [ 29.75275655172414, -1.493361292307692 ], [ 29.752487068965518, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3534, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.493361292307692 ], [ 29.75275655172414, -1.493630769230769 ], [ 29.753026034482758, -1.493630769230769 ], [ 29.753026034482758, -1.493361292307692 ], [ 29.75275655172414, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3535, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.493361292307692 ], [ 29.753026034482758, -1.493900246153846 ], [ 29.753565, -1.493900246153846 ], [ 29.753565, -1.493630769230769 ], [ 29.75329551724138, -1.493630769230769 ], [ 29.75329551724138, -1.493361292307692 ], [ 29.753026034482758, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3536, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.493361292307692 ], [ 29.75329551724138, -1.493630769230769 ], [ 29.753565, -1.493630769230769 ], [ 29.753565, -1.493361292307692 ], [ 29.75329551724138, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3537, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.493361292307692 ], [ 29.753565, -1.493630769230769 ], [ 29.75383448275862, -1.493630769230769 ], [ 29.75383448275862, -1.493361292307692 ], [ 29.753565, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3538, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.493361292307692 ], [ 29.75383448275862, -1.493630769230769 ], [ 29.754103965517242, -1.493630769230769 ], [ 29.754103965517242, -1.493361292307692 ], [ 29.75383448275862, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3539, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.493361292307692 ], [ 29.754103965517242, -1.493630769230769 ], [ 29.754373448275864, -1.493630769230769 ], [ 29.754373448275864, -1.493361292307692 ], [ 29.754103965517242, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3540, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.493361292307692 ], [ 29.754373448275864, -1.493630769230769 ], [ 29.754642931034482, -1.493630769230769 ], [ 29.754642931034482, -1.493361292307692 ], [ 29.754373448275864, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3541, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.493361292307692 ], [ 29.754642931034482, -1.493630769230769 ], [ 29.754912413793104, -1.493630769230769 ], [ 29.754912413793104, -1.493361292307692 ], [ 29.754642931034482, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3542, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.493361292307692 ], [ 29.754912413793104, -1.493630769230769 ], [ 29.755181896551726, -1.493630769230769 ], [ 29.755181896551726, -1.493361292307692 ], [ 29.754912413793104, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3543, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.493361292307692 ], [ 29.755181896551726, -1.493630769230769 ], [ 29.755451379310344, -1.493630769230769 ], [ 29.755451379310344, -1.493361292307692 ], [ 29.755181896551726, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3544, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.493361292307692 ], [ 29.755451379310344, -1.493630769230769 ], [ 29.755720862068966, -1.493630769230769 ], [ 29.755720862068966, -1.493361292307692 ], [ 29.755451379310344, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3545, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.493361292307692 ], [ 29.755720862068966, -1.493630769230769 ], [ 29.755990344827588, -1.493630769230769 ], [ 29.755990344827588, -1.493361292307692 ], [ 29.755720862068966, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3546, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.493361292307692 ], [ 29.755990344827588, -1.493630769230769 ], [ 29.756259827586206, -1.493630769230769 ], [ 29.756259827586206, -1.493361292307692 ], [ 29.755990344827588, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3547, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.493361292307692 ], [ 29.756259827586206, -1.493630769230769 ], [ 29.756798793103449, -1.493630769230769 ], [ 29.756798793103449, -1.493361292307692 ], [ 29.756259827586206, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3548, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.493361292307692 ], [ 29.756798793103449, -1.493630769230769 ], [ 29.757068275862068, -1.493630769230769 ], [ 29.757068275862068, -1.493361292307692 ], [ 29.756798793103449, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3549, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.493361292307692 ], [ 29.757068275862068, -1.493630769230769 ], [ 29.75733775862069, -1.493630769230769 ], [ 29.75733775862069, -1.493361292307692 ], [ 29.757068275862068, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3550, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.493361292307692 ], [ 29.75733775862069, -1.493630769230769 ], [ 29.757607241379311, -1.493630769230769 ], [ 29.757607241379311, -1.493361292307692 ], [ 29.75733775862069, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3551, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.493361292307692 ], [ 29.757607241379311, -1.493630769230769 ], [ 29.757876724137933, -1.493630769230769 ], [ 29.757876724137933, -1.493361292307692 ], [ 29.757607241379311, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3552, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.493361292307692 ], [ 29.757876724137933, -1.493630769230769 ], [ 29.758146206896551, -1.493630769230769 ], [ 29.758146206896551, -1.493361292307692 ], [ 29.757876724137933, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3553, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.493361292307692 ], [ 29.758146206896551, -1.493630769230769 ], [ 29.758415689655173, -1.493630769230769 ], [ 29.758415689655173, -1.493900246153846 ], [ 29.758685172413795, -1.493900246153846 ], [ 29.758685172413795, -1.493361292307692 ], [ 29.758146206896551, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3554, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.493361292307692 ], [ 29.758685172413795, -1.493900246153846 ], [ 29.758954655172413, -1.493900246153846 ], [ 29.758954655172413, -1.493361292307692 ], [ 29.758685172413795, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3555, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.493630769230769 ], [ 29.717993275862067, -1.493900246153846 ], [ 29.718532241379311, -1.493900246153846 ], [ 29.718532241379311, -1.493630769230769 ], [ 29.717993275862067, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3556, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.493630769230769 ], [ 29.718532241379311, -1.493900246153846 ], [ 29.718801724137933, -1.493900246153846 ], [ 29.718801724137933, -1.493630769230769 ], [ 29.718532241379311, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3557, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.493630769230769 ], [ 29.718801724137933, -1.494169723076923 ], [ 29.719071206896551, -1.494169723076923 ], [ 29.719071206896551, -1.493630769230769 ], [ 29.718801724137933, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3558, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.493630769230769 ], [ 29.719071206896551, -1.494169723076923 ], [ 29.719340689655173, -1.494169723076923 ], [ 29.719340689655173, -1.493630769230769 ], [ 29.719071206896551, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3559, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.493630769230769 ], [ 29.719610172413795, -1.493900246153846 ], [ 29.719879655172413, -1.493900246153846 ], [ 29.719879655172413, -1.493630769230769 ], [ 29.719610172413795, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3560, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.493630769230769 ], [ 29.719879655172413, -1.493900246153846 ], [ 29.720149137931035, -1.493900246153846 ], [ 29.720149137931035, -1.493630769230769 ], [ 29.719879655172413, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3561, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.493630769230769 ], [ 29.720149137931035, -1.493900246153846 ], [ 29.720688103448275, -1.493900246153846 ], [ 29.720688103448275, -1.493630769230769 ], [ 29.720149137931035, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3562, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.493630769230769 ], [ 29.720688103448275, -1.493900246153846 ], [ 29.720957586206897, -1.493900246153846 ], [ 29.720957586206897, -1.493630769230769 ], [ 29.720688103448275, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3563, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.493630769230769 ], [ 29.720957586206897, -1.493900246153846 ], [ 29.721227068965518, -1.493900246153846 ], [ 29.721227068965518, -1.493630769230769 ], [ 29.720957586206897, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3564, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.493630769230769 ], [ 29.721227068965518, -1.493900246153846 ], [ 29.721496551724137, -1.493900246153846 ], [ 29.721496551724137, -1.493630769230769 ], [ 29.721227068965518, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3565, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.493630769230769 ], [ 29.721496551724137, -1.493900246153846 ], [ 29.721766034482759, -1.493900246153846 ], [ 29.721766034482759, -1.493630769230769 ], [ 29.721496551724137, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3566, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.493630769230769 ], [ 29.721766034482759, -1.493900246153846 ], [ 29.72203551724138, -1.493900246153846 ], [ 29.72203551724138, -1.493630769230769 ], [ 29.721766034482759, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3567, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.493630769230769 ], [ 29.72203551724138, -1.493900246153846 ], [ 29.722305, -1.493900246153846 ], [ 29.722305, -1.493630769230769 ], [ 29.72203551724138, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3568, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.493630769230769 ], [ 29.722305, -1.493900246153846 ], [ 29.72257448275862, -1.493900246153846 ], [ 29.72257448275862, -1.493630769230769 ], [ 29.722305, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3569, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.493630769230769 ], [ 29.72257448275862, -1.494169723076923 ], [ 29.722843965517242, -1.494169723076923 ], [ 29.722843965517242, -1.493630769230769 ], [ 29.72257448275862, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3570, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.493630769230769 ], [ 29.723113448275861, -1.493900246153846 ], [ 29.723382931034482, -1.493900246153846 ], [ 29.723382931034482, -1.493630769230769 ], [ 29.723113448275861, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3571, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.493630769230769 ], [ 29.723382931034482, -1.493900246153846 ], [ 29.723652413793104, -1.493900246153846 ], [ 29.723652413793104, -1.493630769230769 ], [ 29.723382931034482, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3572, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.493630769230769 ], [ 29.723652413793104, -1.494169723076923 ], [ 29.723921896551722, -1.494169723076923 ], [ 29.723921896551722, -1.493630769230769 ], [ 29.723652413793104, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3573, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.493630769230769 ], [ 29.723921896551722, -1.494169723076923 ], [ 29.724191379310344, -1.494169723076923 ], [ 29.724191379310344, -1.493630769230769 ], [ 29.723921896551722, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3574, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.493630769230769 ], [ 29.724730344827588, -1.494169723076923 ], [ 29.724999827586206, -1.494169723076923 ], [ 29.724999827586206, -1.493630769230769 ], [ 29.724730344827588, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3575, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.493630769230769 ], [ 29.72553879310345, -1.494169723076923 ], [ 29.725808275862068, -1.494169723076923 ], [ 29.725808275862068, -1.493630769230769 ], [ 29.72553879310345, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3576, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.493630769230769 ], [ 29.725808275862068, -1.494169723076923 ], [ 29.72607775862069, -1.494169723076923 ], [ 29.72607775862069, -1.493630769230769 ], [ 29.725808275862068, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3577, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.493630769230769 ], [ 29.72607775862069, -1.493900246153846 ], [ 29.726347241379312, -1.493900246153846 ], [ 29.726347241379312, -1.493630769230769 ], [ 29.72607775862069, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3578, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.493630769230769 ], [ 29.72661672413793, -1.494169723076923 ], [ 29.726886206896552, -1.494169723076923 ], [ 29.726886206896552, -1.493630769230769 ], [ 29.72661672413793, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3579, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.493630769230769 ], [ 29.726886206896552, -1.493900246153846 ], [ 29.727155689655174, -1.493900246153846 ], [ 29.727155689655174, -1.493630769230769 ], [ 29.726886206896552, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3580, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.493630769230769 ], [ 29.727155689655174, -1.493900246153846 ], [ 29.727425172413792, -1.493900246153846 ], [ 29.727425172413792, -1.493630769230769 ], [ 29.727155689655174, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3581, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.493630769230769 ], [ 29.729042068965516, -1.494169723076923 ], [ 29.729311551724138, -1.494169723076923 ], [ 29.729311551724138, -1.493630769230769 ], [ 29.729042068965516, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3582, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.493630769230769 ], [ 29.731197931034483, -1.493900246153846 ], [ 29.731467413793105, -1.493900246153846 ], [ 29.731467413793105, -1.493630769230769 ], [ 29.731197931034483, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3583, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.493630769230769 ], [ 29.731467413793105, -1.493900246153846 ], [ 29.731736896551723, -1.493900246153846 ], [ 29.731736896551723, -1.493630769230769 ], [ 29.731467413793105, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3584, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.493630769230769 ], [ 29.731736896551723, -1.493900246153846 ], [ 29.732006379310345, -1.493900246153846 ], [ 29.732006379310345, -1.493630769230769 ], [ 29.731736896551723, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3585, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.493630769230769 ], [ 29.732006379310345, -1.493900246153846 ], [ 29.732545344827585, -1.493900246153846 ], [ 29.732545344827585, -1.493630769230769 ], [ 29.732006379310345, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3586, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.493630769230769 ], [ 29.732545344827585, -1.493900246153846 ], [ 29.732814827586207, -1.493900246153846 ], [ 29.732814827586207, -1.493630769230769 ], [ 29.732545344827585, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3587, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.493630769230769 ], [ 29.732814827586207, -1.493900246153846 ], [ 29.733353793103447, -1.493900246153846 ], [ 29.733353793103447, -1.493630769230769 ], [ 29.732814827586207, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3588, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.493630769230769 ], [ 29.733353793103447, -1.493900246153846 ], [ 29.733623275862069, -1.493900246153846 ], [ 29.733623275862069, -1.493630769230769 ], [ 29.733353793103447, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3589, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.493630769230769 ], [ 29.733623275862069, -1.493900246153846 ], [ 29.733892758620691, -1.493900246153846 ], [ 29.733892758620691, -1.493630769230769 ], [ 29.733623275862069, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3590, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.493630769230769 ], [ 29.733892758620691, -1.493900246153846 ], [ 29.734162241379309, -1.493900246153846 ], [ 29.734162241379309, -1.493630769230769 ], [ 29.733892758620691, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3591, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.493630769230769 ], [ 29.734162241379309, -1.493900246153846 ], [ 29.734431724137931, -1.493900246153846 ], [ 29.734431724137931, -1.493630769230769 ], [ 29.734162241379309, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3592, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.493630769230769 ], [ 29.734431724137931, -1.493900246153846 ], [ 29.734701206896553, -1.493900246153846 ], [ 29.734701206896553, -1.493630769230769 ], [ 29.734431724137931, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3593, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.493630769230769 ], [ 29.734701206896553, -1.493900246153846 ], [ 29.734970689655171, -1.493900246153846 ], [ 29.734970689655171, -1.493630769230769 ], [ 29.734701206896553, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3594, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.493630769230769 ], [ 29.734970689655171, -1.493900246153846 ], [ 29.735240172413793, -1.493900246153846 ], [ 29.735240172413793, -1.493630769230769 ], [ 29.734970689655171, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3595, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.493630769230769 ], [ 29.735240172413793, -1.493900246153846 ], [ 29.735509655172415, -1.493900246153846 ], [ 29.735509655172415, -1.493630769230769 ], [ 29.735240172413793, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3596, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.493630769230769 ], [ 29.735509655172415, -1.493900246153846 ], [ 29.735779137931036, -1.493900246153846 ], [ 29.735779137931036, -1.493630769230769 ], [ 29.735509655172415, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3597, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.493630769230769 ], [ 29.735779137931036, -1.494169723076923 ], [ 29.736048620689655, -1.494169723076923 ], [ 29.736048620689655, -1.493630769230769 ], [ 29.735779137931036, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3598, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.493630769230769 ], [ 29.736318103448276, -1.493900246153846 ], [ 29.736587586206898, -1.493900246153846 ], [ 29.736587586206898, -1.493630769230769 ], [ 29.736318103448276, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3599, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.493630769230769 ], [ 29.736587586206898, -1.493900246153846 ], [ 29.736857068965517, -1.493900246153846 ], [ 29.736857068965517, -1.493630769230769 ], [ 29.736587586206898, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3600, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.493630769230769 ], [ 29.736857068965517, -1.493900246153846 ], [ 29.737126551724138, -1.493900246153846 ], [ 29.737126551724138, -1.493630769230769 ], [ 29.736857068965517, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3601, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.493630769230769 ], [ 29.73739603448276, -1.494169723076923 ], [ 29.737665517241378, -1.494169723076923 ], [ 29.737665517241378, -1.493630769230769 ], [ 29.73739603448276, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3602, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.493630769230769 ], [ 29.737665517241378, -1.493900246153846 ], [ 29.737935, -1.493900246153846 ], [ 29.737935, -1.493630769230769 ], [ 29.737665517241378, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3603, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.493630769230769 ], [ 29.737935, -1.493900246153846 ], [ 29.738204482758622, -1.493900246153846 ], [ 29.738204482758622, -1.493630769230769 ], [ 29.737935, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3604, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.493630769230769 ], [ 29.740090862068964, -1.494169723076923 ], [ 29.740360344827586, -1.494169723076923 ], [ 29.740360344827586, -1.493630769230769 ], [ 29.740090862068964, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3605, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.493630769230769 ], [ 29.74925327586207, -1.493900246153846 ], [ 29.749522758620689, -1.493900246153846 ], [ 29.749522758620689, -1.493630769230769 ], [ 29.74925327586207, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3606, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.493630769230769 ], [ 29.749522758620689, -1.494169723076923 ], [ 29.749792241379311, -1.494169723076923 ], [ 29.749792241379311, -1.493630769230769 ], [ 29.749522758620689, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3607, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.493630769230769 ], [ 29.749792241379311, -1.493900246153846 ], [ 29.750061724137932, -1.493900246153846 ], [ 29.750061724137932, -1.493630769230769 ], [ 29.749792241379311, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3608, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.493630769230769 ], [ 29.750061724137932, -1.493900246153846 ], [ 29.750331206896551, -1.493900246153846 ], [ 29.750331206896551, -1.493630769230769 ], [ 29.750061724137932, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3609, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.493630769230769 ], [ 29.750331206896551, -1.493900246153846 ], [ 29.750600689655172, -1.493900246153846 ], [ 29.750600689655172, -1.493630769230769 ], [ 29.750331206896551, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3610, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.493630769230769 ], [ 29.750600689655172, -1.494169723076923 ], [ 29.750870172413794, -1.494169723076923 ], [ 29.750870172413794, -1.493630769230769 ], [ 29.750600689655172, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3611, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.493630769230769 ], [ 29.750870172413794, -1.494169723076923 ], [ 29.751139655172413, -1.494169723076923 ], [ 29.751139655172413, -1.493630769230769 ], [ 29.750870172413794, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3612, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.493630769230769 ], [ 29.751678620689656, -1.493900246153846 ], [ 29.751948103448278, -1.493900246153846 ], [ 29.751948103448278, -1.493630769230769 ], [ 29.751678620689656, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3613, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.493630769230769 ], [ 29.751948103448278, -1.493900246153846 ], [ 29.752217586206896, -1.493900246153846 ], [ 29.752217586206896, -1.493630769230769 ], [ 29.751948103448278, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3614, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.493630769230769 ], [ 29.752217586206896, -1.493900246153846 ], [ 29.752487068965518, -1.493900246153846 ], [ 29.752487068965518, -1.493630769230769 ], [ 29.752217586206896, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3615, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.493630769230769 ], [ 29.752487068965518, -1.493900246153846 ], [ 29.75275655172414, -1.493900246153846 ], [ 29.75275655172414, -1.493630769230769 ], [ 29.752487068965518, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3616, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.493630769230769 ], [ 29.75275655172414, -1.493900246153846 ], [ 29.753026034482758, -1.493900246153846 ], [ 29.753026034482758, -1.493630769230769 ], [ 29.75275655172414, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3617, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.493630769230769 ], [ 29.753565, -1.493900246153846 ], [ 29.75383448275862, -1.493900246153846 ], [ 29.75383448275862, -1.493630769230769 ], [ 29.753565, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3618, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.493630769230769 ], [ 29.75383448275862, -1.493900246153846 ], [ 29.754103965517242, -1.493900246153846 ], [ 29.754103965517242, -1.493630769230769 ], [ 29.75383448275862, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3619, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.493630769230769 ], [ 29.754103965517242, -1.493900246153846 ], [ 29.754373448275864, -1.493900246153846 ], [ 29.754373448275864, -1.493630769230769 ], [ 29.754103965517242, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3620, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.493630769230769 ], [ 29.754373448275864, -1.493900246153846 ], [ 29.754642931034482, -1.493900246153846 ], [ 29.754642931034482, -1.493630769230769 ], [ 29.754373448275864, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3621, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.493630769230769 ], [ 29.754642931034482, -1.493900246153846 ], [ 29.754912413793104, -1.493900246153846 ], [ 29.754912413793104, -1.493630769230769 ], [ 29.754642931034482, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3622, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.493630769230769 ], [ 29.754912413793104, -1.493900246153846 ], [ 29.755181896551726, -1.493900246153846 ], [ 29.755181896551726, -1.493630769230769 ], [ 29.754912413793104, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3623, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.493630769230769 ], [ 29.755181896551726, -1.493900246153846 ], [ 29.755451379310344, -1.493900246153846 ], [ 29.755451379310344, -1.493630769230769 ], [ 29.755181896551726, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3624, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.493630769230769 ], [ 29.755451379310344, -1.493900246153846 ], [ 29.755720862068966, -1.493900246153846 ], [ 29.755720862068966, -1.493630769230769 ], [ 29.755451379310344, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3625, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.493630769230769 ], [ 29.755720862068966, -1.493900246153846 ], [ 29.755990344827588, -1.493900246153846 ], [ 29.755990344827588, -1.493630769230769 ], [ 29.755720862068966, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3626, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.493630769230769 ], [ 29.755990344827588, -1.493900246153846 ], [ 29.756259827586206, -1.493900246153846 ], [ 29.756259827586206, -1.493630769230769 ], [ 29.755990344827588, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3627, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.493630769230769 ], [ 29.756259827586206, -1.493900246153846 ], [ 29.756798793103449, -1.493900246153846 ], [ 29.756798793103449, -1.493630769230769 ], [ 29.756259827586206, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3628, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.493630769230769 ], [ 29.756798793103449, -1.493900246153846 ], [ 29.757068275862068, -1.493900246153846 ], [ 29.757068275862068, -1.493630769230769 ], [ 29.756798793103449, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3629, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.493630769230769 ], [ 29.757068275862068, -1.493900246153846 ], [ 29.75733775862069, -1.493900246153846 ], [ 29.75733775862069, -1.493630769230769 ], [ 29.757068275862068, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3630, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.493630769230769 ], [ 29.75733775862069, -1.493900246153846 ], [ 29.757607241379311, -1.493900246153846 ], [ 29.757607241379311, -1.493630769230769 ], [ 29.75733775862069, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3631, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.493630769230769 ], [ 29.757607241379311, -1.493900246153846 ], [ 29.757876724137933, -1.493900246153846 ], [ 29.757876724137933, -1.493630769230769 ], [ 29.757607241379311, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3632, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.493630769230769 ], [ 29.757876724137933, -1.493900246153846 ], [ 29.758146206896551, -1.493900246153846 ], [ 29.758146206896551, -1.494169723076923 ], [ 29.758415689655173, -1.494169723076923 ], [ 29.758415689655173, -1.493630769230769 ], [ 29.757876724137933, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3633, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.493630769230769 ], [ 29.758954655172413, -1.493900246153846 ], [ 29.759224137931035, -1.493900246153846 ], [ 29.759224137931035, -1.493630769230769 ], [ 29.758954655172413, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3634, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.493630769230769 ], [ 29.759224137931035, -1.494169723076923 ], [ 29.759493620689657, -1.494169723076923 ], [ 29.759493620689657, -1.493630769230769 ], [ 29.759224137931035, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3635, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.493900246153846 ], [ 29.719340689655173, -1.494169723076923 ], [ 29.719610172413795, -1.494169723076923 ], [ 29.719610172413795, -1.493900246153846 ], [ 29.719340689655173, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3636, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.493900246153846 ], [ 29.719879655172413, -1.494169723076923 ], [ 29.720149137931035, -1.494169723076923 ], [ 29.720149137931035, -1.493900246153846 ], [ 29.719879655172413, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3637, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.493900246153846 ], [ 29.720149137931035, -1.494169723076923 ], [ 29.720688103448275, -1.494169723076923 ], [ 29.720688103448275, -1.493900246153846 ], [ 29.720149137931035, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3638, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.493900246153846 ], [ 29.720688103448275, -1.494169723076923 ], [ 29.720957586206897, -1.494169723076923 ], [ 29.720957586206897, -1.493900246153846 ], [ 29.720688103448275, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3639, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.493900246153846 ], [ 29.720957586206897, -1.494169723076923 ], [ 29.721227068965518, -1.494169723076923 ], [ 29.721227068965518, -1.493900246153846 ], [ 29.720957586206897, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3640, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.493900246153846 ], [ 29.721227068965518, -1.494169723076923 ], [ 29.721496551724137, -1.494169723076923 ], [ 29.721496551724137, -1.493900246153846 ], [ 29.721227068965518, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3641, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.493900246153846 ], [ 29.721496551724137, -1.494169723076923 ], [ 29.721766034482759, -1.494169723076923 ], [ 29.721766034482759, -1.493900246153846 ], [ 29.721496551724137, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3642, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.493900246153846 ], [ 29.721766034482759, -1.494169723076923 ], [ 29.72203551724138, -1.494169723076923 ], [ 29.72203551724138, -1.493900246153846 ], [ 29.721766034482759, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3643, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.493900246153846 ], [ 29.72203551724138, -1.494169723076923 ], [ 29.722305, -1.494169723076923 ], [ 29.722305, -1.493900246153846 ], [ 29.72203551724138, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3644, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.493900246153846 ], [ 29.722843965517242, -1.494169723076923 ], [ 29.723113448275861, -1.494169723076923 ], [ 29.723113448275861, -1.493900246153846 ], [ 29.722843965517242, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3645, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.493900246153846 ], [ 29.727425172413792, -1.494169723076923 ], [ 29.727694655172414, -1.494169723076923 ], [ 29.727694655172414, -1.493900246153846 ], [ 29.727425172413792, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3646, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.493900246153846 ], [ 29.727694655172414, -1.494169723076923 ], [ 29.727964137931036, -1.494169723076923 ], [ 29.727964137931036, -1.493900246153846 ], [ 29.727694655172414, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3647, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.493900246153846 ], [ 29.727964137931036, -1.494169723076923 ], [ 29.728233620689654, -1.494169723076923 ], [ 29.728233620689654, -1.493900246153846 ], [ 29.727964137931036, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3648, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.493900246153846 ], [ 29.731467413793105, -1.494169723076923 ], [ 29.731736896551723, -1.494169723076923 ], [ 29.731736896551723, -1.493900246153846 ], [ 29.731467413793105, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3649, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.493900246153846 ], [ 29.731736896551723, -1.494169723076923 ], [ 29.732006379310345, -1.494169723076923 ], [ 29.732006379310345, -1.493900246153846 ], [ 29.731736896551723, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3650, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.493900246153846 ], [ 29.732006379310345, -1.494169723076923 ], [ 29.732545344827585, -1.494169723076923 ], [ 29.732545344827585, -1.493900246153846 ], [ 29.732006379310345, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3651, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.493900246153846 ], [ 29.732545344827585, -1.494169723076923 ], [ 29.733084310344829, -1.494169723076923 ], [ 29.733084310344829, -1.493900246153846 ], [ 29.732545344827585, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3652, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.493900246153846 ], [ 29.733084310344829, -1.494169723076923 ], [ 29.733353793103447, -1.494169723076923 ], [ 29.733353793103447, -1.493900246153846 ], [ 29.733084310344829, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3653, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.493900246153846 ], [ 29.733353793103447, -1.494169723076923 ], [ 29.733623275862069, -1.494169723076923 ], [ 29.733623275862069, -1.493900246153846 ], [ 29.733353793103447, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3654, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.493900246153846 ], [ 29.733623275862069, -1.494169723076923 ], [ 29.733892758620691, -1.494169723076923 ], [ 29.733892758620691, -1.493900246153846 ], [ 29.733623275862069, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3655, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.493900246153846 ], [ 29.733892758620691, -1.494169723076923 ], [ 29.734162241379309, -1.494169723076923 ], [ 29.734162241379309, -1.493900246153846 ], [ 29.733892758620691, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3656, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.493900246153846 ], [ 29.734162241379309, -1.494169723076923 ], [ 29.734431724137931, -1.494169723076923 ], [ 29.734431724137931, -1.493900246153846 ], [ 29.734162241379309, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3657, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.493900246153846 ], [ 29.734431724137931, -1.494169723076923 ], [ 29.734701206896553, -1.494169723076923 ], [ 29.734701206896553, -1.493900246153846 ], [ 29.734431724137931, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3658, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.493900246153846 ], [ 29.734701206896553, -1.494169723076923 ], [ 29.734970689655171, -1.494169723076923 ], [ 29.734970689655171, -1.493900246153846 ], [ 29.734701206896553, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3659, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.493900246153846 ], [ 29.734970689655171, -1.494169723076923 ], [ 29.735240172413793, -1.494169723076923 ], [ 29.735240172413793, -1.493900246153846 ], [ 29.734970689655171, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3660, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.493900246153846 ], [ 29.736587586206898, -1.494169723076923 ], [ 29.736857068965517, -1.494169723076923 ], [ 29.736857068965517, -1.493900246153846 ], [ 29.736587586206898, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3661, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.493900246153846 ], [ 29.736857068965517, -1.494169723076923 ], [ 29.737126551724138, -1.494169723076923 ], [ 29.737126551724138, -1.493900246153846 ], [ 29.736857068965517, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3662, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.493900246153846 ], [ 29.737126551724138, -1.494169723076923 ], [ 29.73739603448276, -1.494169723076923 ], [ 29.73739603448276, -1.493900246153846 ], [ 29.737126551724138, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3663, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.493900246153846 ], [ 29.738204482758622, -1.494169723076923 ], [ 29.738743448275862, -1.494169723076923 ], [ 29.738743448275862, -1.493900246153846 ], [ 29.738204482758622, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3664, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.493900246153846 ], [ 29.738743448275862, -1.494169723076923 ], [ 29.739012931034484, -1.494169723076923 ], [ 29.739012931034484, -1.493900246153846 ], [ 29.738743448275862, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3665, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.493900246153846 ], [ 29.739012931034484, -1.494169723076923 ], [ 29.739282413793102, -1.494169723076923 ], [ 29.739282413793102, -1.493900246153846 ], [ 29.739012931034484, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3666, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.493900246153846 ], [ 29.748714310344827, -1.494169723076923 ], [ 29.748983793103449, -1.494169723076923 ], [ 29.748983793103449, -1.493900246153846 ], [ 29.748714310344827, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3667, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.493900246153846 ], [ 29.751139655172413, -1.494169723076923 ], [ 29.751409137931034, -1.494169723076923 ], [ 29.751409137931034, -1.493900246153846 ], [ 29.751139655172413, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3668, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.493900246153846 ], [ 29.751409137931034, -1.494169723076923 ], [ 29.751678620689656, -1.494169723076923 ], [ 29.751678620689656, -1.493900246153846 ], [ 29.751409137931034, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3669, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.493900246153846 ], [ 29.751678620689656, -1.494169723076923 ], [ 29.751948103448278, -1.494169723076923 ], [ 29.751948103448278, -1.493900246153846 ], [ 29.751678620689656, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3670, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.493900246153846 ], [ 29.751948103448278, -1.494169723076923 ], [ 29.752217586206896, -1.494169723076923 ], [ 29.752217586206896, -1.493900246153846 ], [ 29.751948103448278, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3671, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.493900246153846 ], [ 29.752217586206896, -1.494169723076923 ], [ 29.752487068965518, -1.494169723076923 ], [ 29.752487068965518, -1.493900246153846 ], [ 29.752217586206896, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3672, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.493900246153846 ], [ 29.752487068965518, -1.494169723076923 ], [ 29.75275655172414, -1.494169723076923 ], [ 29.75275655172414, -1.493900246153846 ], [ 29.752487068965518, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3673, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.493900246153846 ], [ 29.75275655172414, -1.494169723076923 ], [ 29.753026034482758, -1.494169723076923 ], [ 29.753026034482758, -1.493900246153846 ], [ 29.75275655172414, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3674, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.493900246153846 ], [ 29.753026034482758, -1.494169723076923 ], [ 29.75329551724138, -1.494169723076923 ], [ 29.75329551724138, -1.493900246153846 ], [ 29.753026034482758, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3675, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.493900246153846 ], [ 29.75329551724138, -1.494169723076923 ], [ 29.753565, -1.494169723076923 ], [ 29.753565, -1.493900246153846 ], [ 29.75329551724138, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3676, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.493900246153846 ], [ 29.753565, -1.494169723076923 ], [ 29.75383448275862, -1.494169723076923 ], [ 29.75383448275862, -1.493900246153846 ], [ 29.753565, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3677, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.493900246153846 ], [ 29.75383448275862, -1.494169723076923 ], [ 29.754103965517242, -1.494169723076923 ], [ 29.754103965517242, -1.493900246153846 ], [ 29.75383448275862, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3678, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.493900246153846 ], [ 29.754103965517242, -1.494169723076923 ], [ 29.754373448275864, -1.494169723076923 ], [ 29.754373448275864, -1.493900246153846 ], [ 29.754103965517242, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3679, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.493900246153846 ], [ 29.754373448275864, -1.494169723076923 ], [ 29.754642931034482, -1.494169723076923 ], [ 29.754642931034482, -1.493900246153846 ], [ 29.754373448275864, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3680, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.493900246153846 ], [ 29.754642931034482, -1.494169723076923 ], [ 29.754912413793104, -1.494169723076923 ], [ 29.754912413793104, -1.493900246153846 ], [ 29.754642931034482, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3681, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.493900246153846 ], [ 29.754912413793104, -1.494169723076923 ], [ 29.755181896551726, -1.494169723076923 ], [ 29.755181896551726, -1.493900246153846 ], [ 29.754912413793104, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3682, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.493900246153846 ], [ 29.755181896551726, -1.494169723076923 ], [ 29.755451379310344, -1.494169723076923 ], [ 29.755451379310344, -1.493900246153846 ], [ 29.755181896551726, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3683, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.493900246153846 ], [ 29.755451379310344, -1.494169723076923 ], [ 29.755720862068966, -1.494169723076923 ], [ 29.755720862068966, -1.493900246153846 ], [ 29.755451379310344, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3684, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.493900246153846 ], [ 29.755720862068966, -1.494169723076923 ], [ 29.755990344827588, -1.494169723076923 ], [ 29.755990344827588, -1.493900246153846 ], [ 29.755720862068966, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3685, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.493900246153846 ], [ 29.755990344827588, -1.494169723076923 ], [ 29.756259827586206, -1.494169723076923 ], [ 29.756259827586206, -1.493900246153846 ], [ 29.755990344827588, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3686, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.493900246153846 ], [ 29.756259827586206, -1.494169723076923 ], [ 29.756798793103449, -1.494169723076923 ], [ 29.756798793103449, -1.493900246153846 ], [ 29.756259827586206, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3687, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.493900246153846 ], [ 29.756798793103449, -1.494169723076923 ], [ 29.757068275862068, -1.494169723076923 ], [ 29.757068275862068, -1.493900246153846 ], [ 29.756798793103449, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3688, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.493900246153846 ], [ 29.757068275862068, -1.494169723076923 ], [ 29.75733775862069, -1.494169723076923 ], [ 29.75733775862069, -1.493900246153846 ], [ 29.757068275862068, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3689, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.493900246153846 ], [ 29.75733775862069, -1.494169723076923 ], [ 29.757607241379311, -1.494169723076923 ], [ 29.757607241379311, -1.493900246153846 ], [ 29.75733775862069, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3690, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.493900246153846 ], [ 29.757607241379311, -1.494169723076923 ], [ 29.757876724137933, -1.494169723076923 ], [ 29.757876724137933, -1.493900246153846 ], [ 29.757607241379311, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3691, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.493900246153846 ], [ 29.757876724137933, -1.494169723076923 ], [ 29.758146206896551, -1.494169723076923 ], [ 29.758146206896551, -1.493900246153846 ], [ 29.757876724137933, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3692, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.493900246153846 ], [ 29.758415689655173, -1.494169723076923 ], [ 29.758685172413795, -1.494169723076923 ], [ 29.758685172413795, -1.493900246153846 ], [ 29.758415689655173, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3693, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.493361292307692 ], [ 29.729311551724138, -1.4944392 ], [ 29.729850517241381, -1.4944392 ], [ 29.729850517241381, -1.493361292307692 ], [ 29.729311551724138, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3694, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.493361292307692 ], [ 29.729850517241381, -1.4944392 ], [ 29.73012, -1.4944392 ], [ 29.73012, -1.493361292307692 ], [ 29.729850517241381, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3695, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.493361292307692 ], [ 29.740360344827586, -1.4944392 ], [ 29.740629827586208, -1.4944392 ], [ 29.740629827586208, -1.493361292307692 ], [ 29.740360344827586, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3696, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.493361292307692 ], [ 29.740629827586208, -1.4944392 ], [ 29.74089931034483, -1.4944392 ], [ 29.74089931034483, -1.493361292307692 ], [ 29.740629827586208, -1.493361292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3697, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.493630769230769 ], [ 29.724460862068966, -1.4944392 ], [ 29.724730344827588, -1.4944392 ], [ 29.724730344827588, -1.493630769230769 ], [ 29.724460862068966, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3698, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.493630769230769 ], [ 29.73012, -1.494708676923077 ], [ 29.730389482758621, -1.494708676923077 ], [ 29.730389482758621, -1.493630769230769 ], [ 29.73012, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3699, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.493630769230769 ], [ 29.74089931034483, -1.494708676923077 ], [ 29.741168793103448, -1.494708676923077 ], [ 29.741168793103448, -1.493630769230769 ], [ 29.74089931034483, -1.493630769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3700, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.493900246153846 ], [ 29.718532241379311, -1.4944392 ], [ 29.718801724137933, -1.4944392 ], [ 29.718801724137933, -1.493900246153846 ], [ 29.718532241379311, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3701, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.493900246153846 ], [ 29.719610172413795, -1.4944392 ], [ 29.719879655172413, -1.4944392 ], [ 29.719879655172413, -1.493900246153846 ], [ 29.719610172413795, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3702, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.493900246153846 ], [ 29.722305, -1.4944392 ], [ 29.72257448275862, -1.4944392 ], [ 29.72257448275862, -1.493900246153846 ], [ 29.722305, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3703, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.493900246153846 ], [ 29.723113448275861, -1.4944392 ], [ 29.723382931034482, -1.4944392 ], [ 29.723382931034482, -1.493900246153846 ], [ 29.723113448275861, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3704, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.493900246153846 ], [ 29.723382931034482, -1.4944392 ], [ 29.723652413793104, -1.4944392 ], [ 29.723652413793104, -1.493900246153846 ], [ 29.723382931034482, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3705, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.493900246153846 ], [ 29.724191379310344, -1.4944392 ], [ 29.724460862068966, -1.4944392 ], [ 29.724460862068966, -1.493900246153846 ], [ 29.724191379310344, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3706, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.493900246153846 ], [ 29.725269310344828, -1.494708676923077 ], [ 29.72553879310345, -1.494708676923077 ], [ 29.72553879310345, -1.493900246153846 ], [ 29.725269310344828, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3707, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.493900246153846 ], [ 29.726347241379312, -1.4944392 ], [ 29.72661672413793, -1.4944392 ], [ 29.72661672413793, -1.493900246153846 ], [ 29.726347241379312, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3708, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.493900246153846 ], [ 29.727155689655174, -1.4944392 ], [ 29.727425172413792, -1.4944392 ], [ 29.727425172413792, -1.493900246153846 ], [ 29.727155689655174, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3709, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.493900246153846 ], [ 29.735240172413793, -1.4944392 ], [ 29.735509655172415, -1.4944392 ], [ 29.735509655172415, -1.493900246153846 ], [ 29.735240172413793, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3710, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.493900246153846 ], [ 29.735509655172415, -1.495786584615385 ], [ 29.735779137931036, -1.495786584615385 ], [ 29.735779137931036, -1.493900246153846 ], [ 29.735509655172415, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3711, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.493900246153846 ], [ 29.736048620689655, -1.4944392 ], [ 29.736318103448276, -1.4944392 ], [ 29.736318103448276, -1.493900246153846 ], [ 29.736048620689655, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3712, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.493900246153846 ], [ 29.736318103448276, -1.495247630769231 ], [ 29.736587586206898, -1.495247630769231 ], [ 29.736587586206898, -1.494708676923077 ], [ 29.736857068965517, -1.494708676923077 ], [ 29.736857068965517, -1.4944392 ], [ 29.736587586206898, -1.4944392 ], [ 29.736587586206898, -1.493900246153846 ], [ 29.736318103448276, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3713, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.493900246153846 ], [ 29.737665517241378, -1.4944392 ], [ 29.737935, -1.4944392 ], [ 29.737935, -1.493900246153846 ], [ 29.737665517241378, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3714, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.493900246153846 ], [ 29.737935, -1.4944392 ], [ 29.738204482758622, -1.4944392 ], [ 29.738204482758622, -1.493900246153846 ], [ 29.737935, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3715, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.493900246153846 ], [ 29.748983793103449, -1.494708676923077 ], [ 29.74925327586207, -1.494708676923077 ], [ 29.74925327586207, -1.493900246153846 ], [ 29.748983793103449, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3716, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.493900246153846 ], [ 29.74925327586207, -1.494708676923077 ], [ 29.749522758620689, -1.494708676923077 ], [ 29.749522758620689, -1.493900246153846 ], [ 29.74925327586207, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3717, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.493900246153846 ], [ 29.749792241379311, -1.4944392 ], [ 29.750061724137932, -1.4944392 ], [ 29.750061724137932, -1.493900246153846 ], [ 29.749792241379311, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3718, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.493900246153846 ], [ 29.750061724137932, -1.4944392 ], [ 29.750331206896551, -1.4944392 ], [ 29.750331206896551, -1.493900246153846 ], [ 29.750061724137932, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3719, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.493900246153846 ], [ 29.750331206896551, -1.4944392 ], [ 29.750600689655172, -1.4944392 ], [ 29.750600689655172, -1.493900246153846 ], [ 29.750331206896551, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3720, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.493900246153846 ], [ 29.758685172413795, -1.4944392 ], [ 29.758954655172413, -1.4944392 ], [ 29.758954655172413, -1.493900246153846 ], [ 29.758685172413795, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3721, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.493900246153846 ], [ 29.758954655172413, -1.4944392 ], [ 29.759224137931035, -1.4944392 ], [ 29.759224137931035, -1.493900246153846 ], [ 29.758954655172413, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3722, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.494169723076923 ], [ 29.718801724137933, -1.4944392 ], [ 29.719071206896551, -1.4944392 ], [ 29.719071206896551, -1.494169723076923 ], [ 29.718801724137933, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3723, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.494169723076923 ], [ 29.719071206896551, -1.494708676923077 ], [ 29.719340689655173, -1.494708676923077 ], [ 29.719340689655173, -1.494169723076923 ], [ 29.719071206896551, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3724, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.494169723076923 ], [ 29.719340689655173, -1.494708676923077 ], [ 29.719610172413795, -1.494708676923077 ], [ 29.719610172413795, -1.494169723076923 ], [ 29.719340689655173, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3725, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.494169723076923 ], [ 29.719879655172413, -1.4944392 ], [ 29.720149137931035, -1.4944392 ], [ 29.720149137931035, -1.494169723076923 ], [ 29.719879655172413, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3726, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.494169723076923 ], [ 29.720149137931035, -1.4944392 ], [ 29.720688103448275, -1.4944392 ], [ 29.720688103448275, -1.494169723076923 ], [ 29.720149137931035, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3727, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.494169723076923 ], [ 29.720688103448275, -1.4944392 ], [ 29.720957586206897, -1.4944392 ], [ 29.720957586206897, -1.494169723076923 ], [ 29.720688103448275, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3728, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.494169723076923 ], [ 29.720957586206897, -1.4944392 ], [ 29.721227068965518, -1.4944392 ], [ 29.721227068965518, -1.494169723076923 ], [ 29.720957586206897, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3729, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.494169723076923 ], [ 29.721227068965518, -1.4944392 ], [ 29.721496551724137, -1.4944392 ], [ 29.721496551724137, -1.494169723076923 ], [ 29.721227068965518, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3730, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.494169723076923 ], [ 29.721496551724137, -1.4944392 ], [ 29.721766034482759, -1.4944392 ], [ 29.721766034482759, -1.494169723076923 ], [ 29.721496551724137, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3731, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.494169723076923 ], [ 29.721766034482759, -1.494708676923077 ], [ 29.72203551724138, -1.494708676923077 ], [ 29.72203551724138, -1.494169723076923 ], [ 29.721766034482759, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3732, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.494169723076923 ], [ 29.72257448275862, -1.494708676923077 ], [ 29.722843965517242, -1.494708676923077 ], [ 29.722843965517242, -1.494169723076923 ], [ 29.72257448275862, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3733, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.494169723076923 ], [ 29.723652413793104, -1.494978153846154 ], [ 29.723921896551722, -1.494978153846154 ], [ 29.723921896551722, -1.494169723076923 ], [ 29.723652413793104, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3734, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.494169723076923 ], [ 29.727964137931036, -1.4944392 ], [ 29.728233620689654, -1.4944392 ], [ 29.728233620689654, -1.494169723076923 ], [ 29.727964137931036, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3735, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.494169723076923 ], [ 29.728233620689654, -1.4944392 ], [ 29.728503103448276, -1.4944392 ], [ 29.728503103448276, -1.494169723076923 ], [ 29.728233620689654, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3736, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.494169723076923 ], [ 29.728503103448276, -1.4944392 ], [ 29.728772586206897, -1.4944392 ], [ 29.728772586206897, -1.494169723076923 ], [ 29.728503103448276, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3737, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.494169723076923 ], [ 29.728772586206897, -1.494708676923077 ], [ 29.729042068965516, -1.494708676923077 ], [ 29.729042068965516, -1.494169723076923 ], [ 29.728772586206897, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3738, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.494169723076923 ], [ 29.729042068965516, -1.4944392 ], [ 29.729311551724138, -1.4944392 ], [ 29.729311551724138, -1.494169723076923 ], [ 29.729042068965516, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3739, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.494169723076923 ], [ 29.731736896551723, -1.4944392 ], [ 29.732275862068967, -1.4944392 ], [ 29.732275862068967, -1.494169723076923 ], [ 29.731736896551723, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3740, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.494169723076923 ], [ 29.732275862068967, -1.4944392 ], [ 29.732545344827585, -1.4944392 ], [ 29.732545344827585, -1.494169723076923 ], [ 29.732275862068967, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3741, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.494169723076923 ], [ 29.732545344827585, -1.4944392 ], [ 29.733084310344829, -1.4944392 ], [ 29.733084310344829, -1.494169723076923 ], [ 29.732545344827585, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3742, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.494169723076923 ], [ 29.733084310344829, -1.4944392 ], [ 29.733353793103447, -1.4944392 ], [ 29.733353793103447, -1.494169723076923 ], [ 29.733084310344829, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3743, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.494169723076923 ], [ 29.733353793103447, -1.4944392 ], [ 29.733623275862069, -1.4944392 ], [ 29.733623275862069, -1.494169723076923 ], [ 29.733353793103447, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3744, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.494169723076923 ], [ 29.733623275862069, -1.494708676923077 ], [ 29.733892758620691, -1.494708676923077 ], [ 29.733892758620691, -1.494169723076923 ], [ 29.733623275862069, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3745, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.494169723076923 ], [ 29.733892758620691, -1.4944392 ], [ 29.734162241379309, -1.4944392 ], [ 29.734162241379309, -1.494169723076923 ], [ 29.733892758620691, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3746, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.494169723076923 ], [ 29.734162241379309, -1.4944392 ], [ 29.734431724137931, -1.4944392 ], [ 29.734431724137931, -1.494169723076923 ], [ 29.734162241379309, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3747, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.494169723076923 ], [ 29.734431724137931, -1.4944392 ], [ 29.734701206896553, -1.4944392 ], [ 29.734701206896553, -1.494169723076923 ], [ 29.734431724137931, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3748, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.494169723076923 ], [ 29.734701206896553, -1.496325538461538 ], [ 29.734970689655171, -1.496325538461538 ], [ 29.734970689655171, -1.494169723076923 ], [ 29.734701206896553, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3749, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.494169723076923 ], [ 29.734970689655171, -1.496056061538461 ], [ 29.735240172413793, -1.496056061538461 ], [ 29.735240172413793, -1.494169723076923 ], [ 29.734970689655171, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3750, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.494169723076923 ], [ 29.735779137931036, -1.495786584615385 ], [ 29.736048620689655, -1.495786584615385 ], [ 29.736048620689655, -1.494169723076923 ], [ 29.735779137931036, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3751, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.494169723076923 ], [ 29.736587586206898, -1.4944392 ], [ 29.736857068965517, -1.4944392 ], [ 29.736857068965517, -1.494169723076923 ], [ 29.736587586206898, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3752, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.494169723076923 ], [ 29.736857068965517, -1.4944392 ], [ 29.737126551724138, -1.4944392 ], [ 29.737126551724138, -1.494169723076923 ], [ 29.736857068965517, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3753, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.494169723076923 ], [ 29.737126551724138, -1.4944392 ], [ 29.73739603448276, -1.4944392 ], [ 29.73739603448276, -1.494169723076923 ], [ 29.737126551724138, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3754, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.494169723076923 ], [ 29.73739603448276, -1.4944392 ], [ 29.737665517241378, -1.4944392 ], [ 29.737665517241378, -1.494169723076923 ], [ 29.73739603448276, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3755, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.494169723076923 ], [ 29.738204482758622, -1.494708676923077 ], [ 29.738743448275862, -1.494708676923077 ], [ 29.738743448275862, -1.494169723076923 ], [ 29.738204482758622, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3756, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.494169723076923 ], [ 29.738743448275862, -1.494708676923077 ], [ 29.739012931034484, -1.494708676923077 ], [ 29.739012931034484, -1.494169723076923 ], [ 29.738743448275862, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3757, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.494169723076923 ], [ 29.739012931034484, -1.4944392 ], [ 29.739282413793102, -1.4944392 ], [ 29.739282413793102, -1.494169723076923 ], [ 29.739012931034484, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3758, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.494169723076923 ], [ 29.739282413793102, -1.4944392 ], [ 29.739551896551724, -1.4944392 ], [ 29.739551896551724, -1.494169723076923 ], [ 29.739282413793102, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3759, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.494169723076923 ], [ 29.739551896551724, -1.4944392 ], [ 29.739821379310346, -1.4944392 ], [ 29.739821379310346, -1.494169723076923 ], [ 29.739551896551724, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3760, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.494169723076923 ], [ 29.739821379310346, -1.494708676923077 ], [ 29.740090862068964, -1.494708676923077 ], [ 29.740090862068964, -1.494169723076923 ], [ 29.739821379310346, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3761, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.494169723076923 ], [ 29.740090862068964, -1.4944392 ], [ 29.740360344827586, -1.4944392 ], [ 29.740360344827586, -1.494169723076923 ], [ 29.740090862068964, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3762, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.494169723076923 ], [ 29.748714310344827, -1.494978153846154 ], [ 29.748983793103449, -1.494978153846154 ], [ 29.748983793103449, -1.494169723076923 ], [ 29.748714310344827, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3763, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.494169723076923 ], [ 29.749522758620689, -1.4944392 ], [ 29.749792241379311, -1.4944392 ], [ 29.749792241379311, -1.494169723076923 ], [ 29.749522758620689, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3764, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.494169723076923 ], [ 29.750600689655172, -1.4944392 ], [ 29.750870172413794, -1.4944392 ], [ 29.750870172413794, -1.494169723076923 ], [ 29.750600689655172, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3765, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.494169723076923 ], [ 29.750870172413794, -1.4944392 ], [ 29.751139655172413, -1.4944392 ], [ 29.751139655172413, -1.494169723076923 ], [ 29.750870172413794, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3766, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.494169723076923 ], [ 29.751139655172413, -1.4944392 ], [ 29.751409137931034, -1.4944392 ], [ 29.751409137931034, -1.494169723076923 ], [ 29.751139655172413, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3767, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.494169723076923 ], [ 29.751409137931034, -1.4944392 ], [ 29.751678620689656, -1.4944392 ], [ 29.751678620689656, -1.494169723076923 ], [ 29.751409137931034, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3768, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.494169723076923 ], [ 29.751678620689656, -1.4944392 ], [ 29.751948103448278, -1.4944392 ], [ 29.751948103448278, -1.494169723076923 ], [ 29.751678620689656, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3769, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.494169723076923 ], [ 29.751948103448278, -1.4944392 ], [ 29.752217586206896, -1.4944392 ], [ 29.752217586206896, -1.494169723076923 ], [ 29.751948103448278, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3770, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.494169723076923 ], [ 29.752217586206896, -1.4944392 ], [ 29.752487068965518, -1.4944392 ], [ 29.752487068965518, -1.494169723076923 ], [ 29.752217586206896, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3771, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.494169723076923 ], [ 29.752487068965518, -1.4944392 ], [ 29.75275655172414, -1.4944392 ], [ 29.75275655172414, -1.494169723076923 ], [ 29.752487068965518, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3772, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.494169723076923 ], [ 29.75275655172414, -1.4944392 ], [ 29.753026034482758, -1.4944392 ], [ 29.753026034482758, -1.494169723076923 ], [ 29.75275655172414, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3773, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.494169723076923 ], [ 29.753026034482758, -1.4944392 ], [ 29.75329551724138, -1.4944392 ], [ 29.75329551724138, -1.494169723076923 ], [ 29.753026034482758, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3774, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.494169723076923 ], [ 29.75329551724138, -1.494708676923077 ], [ 29.753565, -1.494708676923077 ], [ 29.753565, -1.494169723076923 ], [ 29.75329551724138, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3775, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.494169723076923 ], [ 29.753565, -1.494708676923077 ], [ 29.754103965517242, -1.494708676923077 ], [ 29.754103965517242, -1.4944392 ], [ 29.75383448275862, -1.4944392 ], [ 29.75383448275862, -1.494169723076923 ], [ 29.753565, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3776, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.494169723076923 ], [ 29.75383448275862, -1.4944392 ], [ 29.754103965517242, -1.4944392 ], [ 29.754103965517242, -1.494169723076923 ], [ 29.75383448275862, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3777, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.494169723076923 ], [ 29.754103965517242, -1.4944392 ], [ 29.754373448275864, -1.4944392 ], [ 29.754373448275864, -1.494169723076923 ], [ 29.754103965517242, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3778, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.494169723076923 ], [ 29.754373448275864, -1.4944392 ], [ 29.754642931034482, -1.4944392 ], [ 29.754642931034482, -1.494169723076923 ], [ 29.754373448275864, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3779, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.494169723076923 ], [ 29.754642931034482, -1.4944392 ], [ 29.754912413793104, -1.4944392 ], [ 29.754912413793104, -1.494169723076923 ], [ 29.754642931034482, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3780, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.494169723076923 ], [ 29.754912413793104, -1.4944392 ], [ 29.755181896551726, -1.4944392 ], [ 29.755181896551726, -1.494169723076923 ], [ 29.754912413793104, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3781, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.494169723076923 ], [ 29.755181896551726, -1.4944392 ], [ 29.755451379310344, -1.4944392 ], [ 29.755451379310344, -1.494169723076923 ], [ 29.755181896551726, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3782, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.494169723076923 ], [ 29.755451379310344, -1.4944392 ], [ 29.755720862068966, -1.4944392 ], [ 29.755720862068966, -1.494169723076923 ], [ 29.755451379310344, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3783, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.494169723076923 ], [ 29.755720862068966, -1.4944392 ], [ 29.755990344827588, -1.4944392 ], [ 29.755990344827588, -1.494169723076923 ], [ 29.755720862068966, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3784, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.494169723076923 ], [ 29.755990344827588, -1.4944392 ], [ 29.756259827586206, -1.4944392 ], [ 29.756259827586206, -1.494169723076923 ], [ 29.755990344827588, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3785, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.494169723076923 ], [ 29.756259827586206, -1.4944392 ], [ 29.756798793103449, -1.4944392 ], [ 29.756798793103449, -1.494169723076923 ], [ 29.756259827586206, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3786, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.494169723076923 ], [ 29.756798793103449, -1.4944392 ], [ 29.757068275862068, -1.4944392 ], [ 29.757068275862068, -1.494169723076923 ], [ 29.756798793103449, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3787, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.494169723076923 ], [ 29.757068275862068, -1.4944392 ], [ 29.75733775862069, -1.4944392 ], [ 29.75733775862069, -1.494169723076923 ], [ 29.757068275862068, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3788, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.494169723076923 ], [ 29.75733775862069, -1.4944392 ], [ 29.757607241379311, -1.4944392 ], [ 29.757607241379311, -1.494169723076923 ], [ 29.75733775862069, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3789, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.494169723076923 ], [ 29.757607241379311, -1.4944392 ], [ 29.757876724137933, -1.4944392 ], [ 29.757876724137933, -1.494169723076923 ], [ 29.757607241379311, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3790, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.494169723076923 ], [ 29.757876724137933, -1.4944392 ], [ 29.758146206896551, -1.4944392 ], [ 29.758146206896551, -1.494169723076923 ], [ 29.757876724137933, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3791, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.494169723076923 ], [ 29.758146206896551, -1.4944392 ], [ 29.758415689655173, -1.4944392 ], [ 29.758415689655173, -1.494169723076923 ], [ 29.758146206896551, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3792, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.494169723076923 ], [ 29.758415689655173, -1.494708676923077 ], [ 29.758685172413795, -1.494708676923077 ], [ 29.758685172413795, -1.494169723076923 ], [ 29.758415689655173, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3793, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.494169723076923 ], [ 29.759224137931035, -1.4944392 ], [ 29.759493620689657, -1.4944392 ], [ 29.759493620689657, -1.494169723076923 ], [ 29.759224137931035, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3794, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.494169723076923 ], [ 29.759493620689657, -1.494978153846154 ], [ 29.759763103448275, -1.494978153846154 ], [ 29.759763103448275, -1.494169723076923 ], [ 29.759493620689657, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3795, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.4944392 ], [ 29.718801724137933, -1.494978153846154 ], [ 29.719071206896551, -1.494978153846154 ], [ 29.719071206896551, -1.4944392 ], [ 29.718801724137933, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3796, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.4944392 ], [ 29.719610172413795, -1.494708676923077 ], [ 29.719879655172413, -1.494708676923077 ], [ 29.719879655172413, -1.4944392 ], [ 29.719610172413795, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3797, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.4944392 ], [ 29.719879655172413, -1.494978153846154 ], [ 29.720149137931035, -1.494978153846154 ], [ 29.720149137931035, -1.4944392 ], [ 29.719879655172413, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3798, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.4944392 ], [ 29.720149137931035, -1.494708676923077 ], [ 29.720688103448275, -1.494708676923077 ], [ 29.720688103448275, -1.4944392 ], [ 29.720149137931035, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3799, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.4944392 ], [ 29.720688103448275, -1.494708676923077 ], [ 29.720957586206897, -1.494708676923077 ], [ 29.720957586206897, -1.4944392 ], [ 29.720688103448275, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3800, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.4944392 ], [ 29.720957586206897, -1.494708676923077 ], [ 29.721227068965518, -1.494708676923077 ], [ 29.721227068965518, -1.4944392 ], [ 29.720957586206897, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3801, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.4944392 ], [ 29.721227068965518, -1.496325538461538 ], [ 29.721496551724137, -1.496325538461538 ], [ 29.721496551724137, -1.4944392 ], [ 29.721227068965518, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3802, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.4944392 ], [ 29.721496551724137, -1.496325538461538 ], [ 29.721766034482759, -1.496325538461538 ], [ 29.721766034482759, -1.4944392 ], [ 29.721496551724137, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3803, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.4944392 ], [ 29.722305, -1.496325538461538 ], [ 29.72257448275862, -1.496325538461538 ], [ 29.72257448275862, -1.4944392 ], [ 29.722305, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3804, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.4944392 ], [ 29.723113448275861, -1.496325538461538 ], [ 29.723382931034482, -1.496325538461538 ], [ 29.723382931034482, -1.4944392 ], [ 29.723113448275861, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3805, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.4944392 ], [ 29.723382931034482, -1.496325538461538 ], [ 29.723652413793104, -1.496325538461538 ], [ 29.723652413793104, -1.4944392 ], [ 29.723382931034482, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3806, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.4944392 ], [ 29.724191379310344, -1.496325538461538 ], [ 29.724460862068966, -1.496325538461538 ], [ 29.724460862068966, -1.4944392 ], [ 29.724191379310344, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3807, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.4944392 ], [ 29.729850517241381, -1.494708676923077 ], [ 29.73012, -1.494708676923077 ], [ 29.73012, -1.4944392 ], [ 29.729850517241381, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3808, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.4944392 ], [ 29.732275862068967, -1.494708676923077 ], [ 29.732814827586207, -1.494708676923077 ], [ 29.732814827586207, -1.4944392 ], [ 29.732275862068967, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3809, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.4944392 ], [ 29.732814827586207, -1.494708676923077 ], [ 29.733084310344829, -1.494708676923077 ], [ 29.733084310344829, -1.4944392 ], [ 29.732814827586207, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3810, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.4944392 ], [ 29.735240172413793, -1.496056061538461 ], [ 29.735509655172415, -1.496056061538461 ], [ 29.735509655172415, -1.4944392 ], [ 29.735240172413793, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3811, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.4944392 ], [ 29.736048620689655, -1.495786584615385 ], [ 29.736318103448276, -1.495786584615385 ], [ 29.736318103448276, -1.4944392 ], [ 29.736048620689655, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3812, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.4944392 ], [ 29.736857068965517, -1.494708676923077 ], [ 29.737126551724138, -1.494708676923077 ], [ 29.737126551724138, -1.4944392 ], [ 29.736857068965517, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3813, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.4944392 ], [ 29.737126551724138, -1.494708676923077 ], [ 29.73739603448276, -1.494708676923077 ], [ 29.73739603448276, -1.4944392 ], [ 29.737126551724138, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3814, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.4944392 ], [ 29.73739603448276, -1.494708676923077 ], [ 29.737665517241378, -1.494708676923077 ], [ 29.737665517241378, -1.4944392 ], [ 29.73739603448276, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3815, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.4944392 ], [ 29.737665517241378, -1.494708676923077 ], [ 29.737935, -1.494708676923077 ], [ 29.737935, -1.4944392 ], [ 29.737665517241378, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3816, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.4944392 ], [ 29.737935, -1.494708676923077 ], [ 29.738204482758622, -1.494708676923077 ], [ 29.738204482758622, -1.4944392 ], [ 29.737935, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3817, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.4944392 ], [ 29.739012931034484, -1.494978153846154 ], [ 29.739282413793102, -1.494978153846154 ], [ 29.739282413793102, -1.4944392 ], [ 29.739012931034484, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3818, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.4944392 ], [ 29.739282413793102, -1.494978153846154 ], [ 29.739551896551724, -1.494978153846154 ], [ 29.739551896551724, -1.4944392 ], [ 29.739282413793102, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3819, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.4944392 ], [ 29.739551896551724, -1.494978153846154 ], [ 29.739821379310346, -1.494978153846154 ], [ 29.739821379310346, -1.4944392 ], [ 29.739551896551724, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3820, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.4944392 ], [ 29.740090862068964, -1.495247630769231 ], [ 29.740360344827586, -1.495247630769231 ], [ 29.740360344827586, -1.4944392 ], [ 29.740090862068964, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3821, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.4944392 ], [ 29.740360344827586, -1.495247630769231 ], [ 29.740629827586208, -1.495247630769231 ], [ 29.740629827586208, -1.4944392 ], [ 29.740360344827586, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3822, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.4944392 ], [ 29.740629827586208, -1.494708676923077 ], [ 29.74089931034483, -1.494708676923077 ], [ 29.74089931034483, -1.4944392 ], [ 29.740629827586208, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3823, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.4944392 ], [ 29.749522758620689, -1.494708676923077 ], [ 29.749792241379311, -1.494708676923077 ], [ 29.749792241379311, -1.4944392 ], [ 29.749522758620689, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3824, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.4944392 ], [ 29.749792241379311, -1.494978153846154 ], [ 29.750061724137932, -1.494978153846154 ], [ 29.750061724137932, -1.4944392 ], [ 29.749792241379311, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3825, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.4944392 ], [ 29.750061724137932, -1.494708676923077 ], [ 29.750331206896551, -1.494708676923077 ], [ 29.750331206896551, -1.4944392 ], [ 29.750061724137932, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3826, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.4944392 ], [ 29.750331206896551, -1.494708676923077 ], [ 29.750600689655172, -1.494708676923077 ], [ 29.750600689655172, -1.4944392 ], [ 29.750331206896551, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3827, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.4944392 ], [ 29.750600689655172, -1.494708676923077 ], [ 29.750870172413794, -1.494708676923077 ], [ 29.750870172413794, -1.4944392 ], [ 29.750600689655172, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3828, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.4944392 ], [ 29.750870172413794, -1.494708676923077 ], [ 29.751139655172413, -1.494708676923077 ], [ 29.751139655172413, -1.4944392 ], [ 29.750870172413794, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3829, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.4944392 ], [ 29.751139655172413, -1.494708676923077 ], [ 29.751409137931034, -1.494708676923077 ], [ 29.751409137931034, -1.4944392 ], [ 29.751139655172413, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3830, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.4944392 ], [ 29.751409137931034, -1.494708676923077 ], [ 29.751678620689656, -1.494708676923077 ], [ 29.751678620689656, -1.4944392 ], [ 29.751409137931034, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3831, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.4944392 ], [ 29.751678620689656, -1.494708676923077 ], [ 29.751948103448278, -1.494708676923077 ], [ 29.751948103448278, -1.4944392 ], [ 29.751678620689656, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3832, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.4944392 ], [ 29.751948103448278, -1.494708676923077 ], [ 29.752217586206896, -1.494708676923077 ], [ 29.752217586206896, -1.4944392 ], [ 29.751948103448278, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3833, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.4944392 ], [ 29.752217586206896, -1.494708676923077 ], [ 29.752487068965518, -1.494708676923077 ], [ 29.752487068965518, -1.4944392 ], [ 29.752217586206896, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3834, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.4944392 ], [ 29.752487068965518, -1.494708676923077 ], [ 29.75275655172414, -1.494708676923077 ], [ 29.75275655172414, -1.4944392 ], [ 29.752487068965518, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3835, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.4944392 ], [ 29.75275655172414, -1.494708676923077 ], [ 29.753026034482758, -1.494708676923077 ], [ 29.753026034482758, -1.4944392 ], [ 29.75275655172414, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3836, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.4944392 ], [ 29.753026034482758, -1.494978153846154 ], [ 29.75329551724138, -1.494978153846154 ], [ 29.75329551724138, -1.4944392 ], [ 29.753026034482758, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3837, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.4944392 ], [ 29.754103965517242, -1.494708676923077 ], [ 29.754373448275864, -1.494708676923077 ], [ 29.754373448275864, -1.4944392 ], [ 29.754103965517242, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3838, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.4944392 ], [ 29.754373448275864, -1.494708676923077 ], [ 29.754642931034482, -1.494708676923077 ], [ 29.754642931034482, -1.4944392 ], [ 29.754373448275864, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3839, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.4944392 ], [ 29.754642931034482, -1.494708676923077 ], [ 29.754912413793104, -1.494708676923077 ], [ 29.754912413793104, -1.4944392 ], [ 29.754642931034482, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3840, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.4944392 ], [ 29.754912413793104, -1.494708676923077 ], [ 29.755181896551726, -1.494708676923077 ], [ 29.755181896551726, -1.4944392 ], [ 29.754912413793104, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3841, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.4944392 ], [ 29.755181896551726, -1.494708676923077 ], [ 29.755451379310344, -1.494708676923077 ], [ 29.755451379310344, -1.4944392 ], [ 29.755181896551726, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3842, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.4944392 ], [ 29.755451379310344, -1.494708676923077 ], [ 29.755720862068966, -1.494708676923077 ], [ 29.755720862068966, -1.4944392 ], [ 29.755451379310344, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3843, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.4944392 ], [ 29.755720862068966, -1.494708676923077 ], [ 29.755990344827588, -1.494708676923077 ], [ 29.755990344827588, -1.4944392 ], [ 29.755720862068966, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3844, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.4944392 ], [ 29.755990344827588, -1.494708676923077 ], [ 29.756259827586206, -1.494708676923077 ], [ 29.756259827586206, -1.4944392 ], [ 29.755990344827588, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3845, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.4944392 ], [ 29.756259827586206, -1.494708676923077 ], [ 29.756798793103449, -1.494708676923077 ], [ 29.756798793103449, -1.4944392 ], [ 29.756259827586206, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3846, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.4944392 ], [ 29.756798793103449, -1.494708676923077 ], [ 29.757068275862068, -1.494708676923077 ], [ 29.757068275862068, -1.4944392 ], [ 29.756798793103449, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3847, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.4944392 ], [ 29.757068275862068, -1.494708676923077 ], [ 29.75733775862069, -1.494708676923077 ], [ 29.75733775862069, -1.4944392 ], [ 29.757068275862068, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3848, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.4944392 ], [ 29.75733775862069, -1.494708676923077 ], [ 29.757607241379311, -1.494708676923077 ], [ 29.757607241379311, -1.4944392 ], [ 29.75733775862069, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3849, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.4944392 ], [ 29.757607241379311, -1.494708676923077 ], [ 29.757876724137933, -1.494708676923077 ], [ 29.757876724137933, -1.4944392 ], [ 29.757607241379311, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3850, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.4944392 ], [ 29.757876724137933, -1.494708676923077 ], [ 29.758146206896551, -1.494708676923077 ], [ 29.758146206896551, -1.4944392 ], [ 29.757876724137933, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3851, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.4944392 ], [ 29.758146206896551, -1.494708676923077 ], [ 29.758415689655173, -1.494708676923077 ], [ 29.758415689655173, -1.4944392 ], [ 29.758146206896551, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3852, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.4944392 ], [ 29.758685172413795, -1.494708676923077 ], [ 29.758954655172413, -1.494708676923077 ], [ 29.758954655172413, -1.4944392 ], [ 29.758685172413795, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3853, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.4944392 ], [ 29.758954655172413, -1.495247630769231 ], [ 29.759224137931035, -1.495247630769231 ], [ 29.759224137931035, -1.4944392 ], [ 29.758954655172413, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3854, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.4944392 ], [ 29.759224137931035, -1.495247630769231 ], [ 29.759493620689657, -1.495247630769231 ], [ 29.759493620689657, -1.4944392 ], [ 29.759224137931035, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3855, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.494708676923077 ], [ 29.719071206896551, -1.494978153846154 ], [ 29.719340689655173, -1.494978153846154 ], [ 29.719340689655173, -1.494708676923077 ], [ 29.719071206896551, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3856, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.494708676923077 ], [ 29.719340689655173, -1.496056061538461 ], [ 29.719610172413795, -1.496056061538461 ], [ 29.719610172413795, -1.494708676923077 ], [ 29.719340689655173, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3857, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.494708676923077 ], [ 29.719610172413795, -1.496056061538461 ], [ 29.719879655172413, -1.496056061538461 ], [ 29.719879655172413, -1.494708676923077 ], [ 29.719610172413795, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3858, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.494708676923077 ], [ 29.720149137931035, -1.496056061538461 ], [ 29.720688103448275, -1.496056061538461 ], [ 29.720688103448275, -1.494708676923077 ], [ 29.720149137931035, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3859, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.494708676923077 ], [ 29.720688103448275, -1.496056061538461 ], [ 29.720957586206897, -1.496056061538461 ], [ 29.720957586206897, -1.494708676923077 ], [ 29.720688103448275, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3860, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.494708676923077 ], [ 29.720957586206897, -1.496056061538461 ], [ 29.721227068965518, -1.496056061538461 ], [ 29.721227068965518, -1.494708676923077 ], [ 29.720957586206897, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3861, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.494708676923077 ], [ 29.721766034482759, -1.496056061538461 ], [ 29.72203551724138, -1.496056061538461 ], [ 29.72203551724138, -1.494708676923077 ], [ 29.721766034482759, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3862, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.494708676923077 ], [ 29.72257448275862, -1.496056061538461 ], [ 29.722843965517242, -1.496056061538461 ], [ 29.722843965517242, -1.494708676923077 ], [ 29.72257448275862, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3863, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.494708676923077 ], [ 29.725269310344828, -1.496325538461538 ], [ 29.72553879310345, -1.496325538461538 ], [ 29.72553879310345, -1.494708676923077 ], [ 29.725269310344828, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3864, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.494708676923077 ], [ 29.728772586206897, -1.496325538461538 ], [ 29.729042068965516, -1.496325538461538 ], [ 29.729042068965516, -1.494708676923077 ], [ 29.728772586206897, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3865, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.494708676923077 ], [ 29.729850517241381, -1.496325538461538 ], [ 29.73012, -1.496325538461538 ], [ 29.73012, -1.494708676923077 ], [ 29.729850517241381, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3866, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.494708676923077 ], [ 29.736587586206898, -1.494978153846154 ], [ 29.736857068965517, -1.494978153846154 ], [ 29.736857068965517, -1.494708676923077 ], [ 29.736587586206898, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3867, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.494708676923077 ], [ 29.736857068965517, -1.494978153846154 ], [ 29.737126551724138, -1.494978153846154 ], [ 29.737126551724138, -1.494708676923077 ], [ 29.736857068965517, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3868, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.494708676923077 ], [ 29.737126551724138, -1.494978153846154 ], [ 29.73739603448276, -1.494978153846154 ], [ 29.73739603448276, -1.494708676923077 ], [ 29.737126551724138, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3869, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.494708676923077 ], [ 29.73739603448276, -1.494978153846154 ], [ 29.737665517241378, -1.494978153846154 ], [ 29.737665517241378, -1.494708676923077 ], [ 29.73739603448276, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3870, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.494708676923077 ], [ 29.737665517241378, -1.494978153846154 ], [ 29.737935, -1.494978153846154 ], [ 29.737935, -1.494708676923077 ], [ 29.737665517241378, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3871, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.494708676923077 ], [ 29.737935, -1.494978153846154 ], [ 29.738204482758622, -1.494978153846154 ], [ 29.738204482758622, -1.494708676923077 ], [ 29.737935, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3872, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.494708676923077 ], [ 29.738204482758622, -1.494978153846154 ], [ 29.738743448275862, -1.494978153846154 ], [ 29.738743448275862, -1.494708676923077 ], [ 29.738204482758622, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3873, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.494708676923077 ], [ 29.738743448275862, -1.494978153846154 ], [ 29.739012931034484, -1.494978153846154 ], [ 29.739012931034484, -1.494708676923077 ], [ 29.738743448275862, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3874, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.494708676923077 ], [ 29.739821379310346, -1.494978153846154 ], [ 29.740090862068964, -1.494978153846154 ], [ 29.740090862068964, -1.494708676923077 ], [ 29.739821379310346, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3875, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.494708676923077 ], [ 29.740629827586208, -1.495517107692308 ], [ 29.74089931034483, -1.495517107692308 ], [ 29.74089931034483, -1.494708676923077 ], [ 29.740629827586208, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3876, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.494708676923077 ], [ 29.748983793103449, -1.494978153846154 ], [ 29.74925327586207, -1.494978153846154 ], [ 29.74925327586207, -1.494708676923077 ], [ 29.748983793103449, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3877, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.494708676923077 ], [ 29.74925327586207, -1.495247630769231 ], [ 29.749522758620689, -1.495247630769231 ], [ 29.749522758620689, -1.494708676923077 ], [ 29.74925327586207, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3878, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.494708676923077 ], [ 29.749522758620689, -1.495247630769231 ], [ 29.749792241379311, -1.495247630769231 ], [ 29.749792241379311, -1.494708676923077 ], [ 29.749522758620689, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3879, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.494708676923077 ], [ 29.750061724137932, -1.494978153846154 ], [ 29.750331206896551, -1.494978153846154 ], [ 29.750331206896551, -1.494708676923077 ], [ 29.750061724137932, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3880, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.494708676923077 ], [ 29.750331206896551, -1.494978153846154 ], [ 29.750600689655172, -1.494978153846154 ], [ 29.750600689655172, -1.494708676923077 ], [ 29.750331206896551, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3881, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.494708676923077 ], [ 29.750600689655172, -1.494978153846154 ], [ 29.750870172413794, -1.494978153846154 ], [ 29.750870172413794, -1.494708676923077 ], [ 29.750600689655172, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3882, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.494708676923077 ], [ 29.750870172413794, -1.494978153846154 ], [ 29.751139655172413, -1.494978153846154 ], [ 29.751139655172413, -1.494708676923077 ], [ 29.750870172413794, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3883, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.494708676923077 ], [ 29.751139655172413, -1.494978153846154 ], [ 29.751409137931034, -1.494978153846154 ], [ 29.751409137931034, -1.494708676923077 ], [ 29.751139655172413, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3884, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.494708676923077 ], [ 29.751409137931034, -1.494978153846154 ], [ 29.751678620689656, -1.494978153846154 ], [ 29.751678620689656, -1.494708676923077 ], [ 29.751409137931034, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3885, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.494708676923077 ], [ 29.751678620689656, -1.494978153846154 ], [ 29.751948103448278, -1.494978153846154 ], [ 29.751948103448278, -1.494708676923077 ], [ 29.751678620689656, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3886, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.494708676923077 ], [ 29.751948103448278, -1.494978153846154 ], [ 29.752217586206896, -1.494978153846154 ], [ 29.752217586206896, -1.494708676923077 ], [ 29.751948103448278, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3887, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.494708676923077 ], [ 29.752217586206896, -1.494978153846154 ], [ 29.752487068965518, -1.494978153846154 ], [ 29.752487068965518, -1.494708676923077 ], [ 29.752217586206896, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3888, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.494708676923077 ], [ 29.752487068965518, -1.494978153846154 ], [ 29.75275655172414, -1.494978153846154 ], [ 29.75275655172414, -1.494708676923077 ], [ 29.752487068965518, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3889, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.494708676923077 ], [ 29.75275655172414, -1.495247630769231 ], [ 29.753026034482758, -1.495247630769231 ], [ 29.753026034482758, -1.494708676923077 ], [ 29.75275655172414, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3890, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.494708676923077 ], [ 29.75329551724138, -1.494978153846154 ], [ 29.753565, -1.494978153846154 ], [ 29.753565, -1.494708676923077 ], [ 29.75329551724138, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3891, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.494708676923077 ], [ 29.753565, -1.494978153846154 ], [ 29.75383448275862, -1.494978153846154 ], [ 29.75383448275862, -1.494708676923077 ], [ 29.753565, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3892, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.494708676923077 ], [ 29.75383448275862, -1.495247630769231 ], [ 29.754373448275864, -1.495247630769231 ], [ 29.754373448275864, -1.494978153846154 ], [ 29.754103965517242, -1.494978153846154 ], [ 29.754103965517242, -1.494708676923077 ], [ 29.75383448275862, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3893, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.494708676923077 ], [ 29.754103965517242, -1.494978153846154 ], [ 29.754373448275864, -1.494978153846154 ], [ 29.754373448275864, -1.494708676923077 ], [ 29.754103965517242, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3894, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.494708676923077 ], [ 29.754373448275864, -1.494978153846154 ], [ 29.754642931034482, -1.494978153846154 ], [ 29.754642931034482, -1.494708676923077 ], [ 29.754373448275864, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3895, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.494708676923077 ], [ 29.754642931034482, -1.494978153846154 ], [ 29.754912413793104, -1.494978153846154 ], [ 29.754912413793104, -1.494708676923077 ], [ 29.754642931034482, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3896, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.494708676923077 ], [ 29.754912413793104, -1.494978153846154 ], [ 29.755181896551726, -1.494978153846154 ], [ 29.755181896551726, -1.494708676923077 ], [ 29.754912413793104, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3897, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.494708676923077 ], [ 29.755181896551726, -1.494978153846154 ], [ 29.755451379310344, -1.494978153846154 ], [ 29.755451379310344, -1.494708676923077 ], [ 29.755181896551726, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3898, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.494708676923077 ], [ 29.755451379310344, -1.494978153846154 ], [ 29.755720862068966, -1.494978153846154 ], [ 29.755720862068966, -1.494708676923077 ], [ 29.755451379310344, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3899, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.494708676923077 ], [ 29.755720862068966, -1.494978153846154 ], [ 29.755990344827588, -1.494978153846154 ], [ 29.755990344827588, -1.494708676923077 ], [ 29.755720862068966, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3900, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.494708676923077 ], [ 29.755990344827588, -1.494978153846154 ], [ 29.756259827586206, -1.494978153846154 ], [ 29.756259827586206, -1.494708676923077 ], [ 29.755990344827588, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3901, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.494708676923077 ], [ 29.756259827586206, -1.494978153846154 ], [ 29.756798793103449, -1.494978153846154 ], [ 29.756798793103449, -1.494708676923077 ], [ 29.756259827586206, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3902, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.494708676923077 ], [ 29.756798793103449, -1.494978153846154 ], [ 29.757068275862068, -1.494978153846154 ], [ 29.757068275862068, -1.494708676923077 ], [ 29.756798793103449, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3903, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.494708676923077 ], [ 29.757068275862068, -1.494978153846154 ], [ 29.75733775862069, -1.494978153846154 ], [ 29.75733775862069, -1.494708676923077 ], [ 29.757068275862068, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3904, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.494708676923077 ], [ 29.75733775862069, -1.494978153846154 ], [ 29.757607241379311, -1.494978153846154 ], [ 29.757607241379311, -1.494708676923077 ], [ 29.75733775862069, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3905, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.494708676923077 ], [ 29.757607241379311, -1.494978153846154 ], [ 29.757876724137933, -1.494978153846154 ], [ 29.757876724137933, -1.494708676923077 ], [ 29.757607241379311, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3906, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.494708676923077 ], [ 29.757876724137933, -1.495247630769231 ], [ 29.758146206896551, -1.495247630769231 ], [ 29.758146206896551, -1.494708676923077 ], [ 29.757876724137933, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3907, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.494708676923077 ], [ 29.758146206896551, -1.495247630769231 ], [ 29.758415689655173, -1.495247630769231 ], [ 29.758415689655173, -1.494708676923077 ], [ 29.758146206896551, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3908, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.494708676923077 ], [ 29.758415689655173, -1.495247630769231 ], [ 29.758685172413795, -1.495247630769231 ], [ 29.758685172413795, -1.494708676923077 ], [ 29.758415689655173, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3909, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.494708676923077 ], [ 29.758685172413795, -1.494978153846154 ], [ 29.758954655172413, -1.494978153846154 ], [ 29.758954655172413, -1.494708676923077 ], [ 29.758685172413795, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3910, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.494978153846154 ], [ 29.719071206896551, -1.495786584615385 ], [ 29.719340689655173, -1.495786584615385 ], [ 29.719340689655173, -1.494978153846154 ], [ 29.719071206896551, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3911, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.494978153846154 ], [ 29.719879655172413, -1.495786584615385 ], [ 29.720149137931035, -1.495786584615385 ], [ 29.720149137931035, -1.494978153846154 ], [ 29.719879655172413, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3912, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.494978153846154 ], [ 29.723652413793104, -1.495786584615385 ], [ 29.723921896551722, -1.495786584615385 ], [ 29.723921896551722, -1.494978153846154 ], [ 29.723652413793104, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3913, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.494978153846154 ], [ 29.736587586206898, -1.495247630769231 ], [ 29.736857068965517, -1.495247630769231 ], [ 29.736857068965517, -1.494978153846154 ], [ 29.736587586206898, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3914, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.494978153846154 ], [ 29.736857068965517, -1.495247630769231 ], [ 29.737126551724138, -1.495247630769231 ], [ 29.737126551724138, -1.494978153846154 ], [ 29.736857068965517, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3915, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.494978153846154 ], [ 29.737126551724138, -1.495247630769231 ], [ 29.73739603448276, -1.495247630769231 ], [ 29.73739603448276, -1.494978153846154 ], [ 29.737126551724138, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3916, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.494978153846154 ], [ 29.73739603448276, -1.495247630769231 ], [ 29.737665517241378, -1.495247630769231 ], [ 29.737665517241378, -1.494978153846154 ], [ 29.73739603448276, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3917, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.494978153846154 ], [ 29.737665517241378, -1.495247630769231 ], [ 29.737935, -1.495247630769231 ], [ 29.737935, -1.494978153846154 ], [ 29.737665517241378, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3918, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.494978153846154 ], [ 29.737935, -1.495247630769231 ], [ 29.738204482758622, -1.495247630769231 ], [ 29.738204482758622, -1.494978153846154 ], [ 29.737935, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3919, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.494978153846154 ], [ 29.738204482758622, -1.495247630769231 ], [ 29.738743448275862, -1.495247630769231 ], [ 29.738743448275862, -1.494978153846154 ], [ 29.738204482758622, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3920, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.494978153846154 ], [ 29.738743448275862, -1.495247630769231 ], [ 29.739012931034484, -1.495247630769231 ], [ 29.739012931034484, -1.494978153846154 ], [ 29.738743448275862, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3921, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.494978153846154 ], [ 29.739012931034484, -1.495247630769231 ], [ 29.739282413793102, -1.495247630769231 ], [ 29.739282413793102, -1.494978153846154 ], [ 29.739012931034484, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3922, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.494978153846154 ], [ 29.739282413793102, -1.495247630769231 ], [ 29.739551896551724, -1.495247630769231 ], [ 29.739551896551724, -1.494978153846154 ], [ 29.739282413793102, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3923, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.494978153846154 ], [ 29.739551896551724, -1.495517107692308 ], [ 29.739821379310346, -1.495517107692308 ], [ 29.739821379310346, -1.494978153846154 ], [ 29.739551896551724, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3924, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.494978153846154 ], [ 29.739821379310346, -1.495247630769231 ], [ 29.740090862068964, -1.495247630769231 ], [ 29.740090862068964, -1.494978153846154 ], [ 29.739821379310346, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3925, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.494978153846154 ], [ 29.748983793103449, -1.495517107692308 ], [ 29.74925327586207, -1.495517107692308 ], [ 29.74925327586207, -1.494978153846154 ], [ 29.748983793103449, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3926, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.494978153846154 ], [ 29.749792241379311, -1.495247630769231 ], [ 29.750061724137932, -1.495247630769231 ], [ 29.750061724137932, -1.494978153846154 ], [ 29.749792241379311, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3927, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.494978153846154 ], [ 29.750061724137932, -1.495517107692308 ], [ 29.750331206896551, -1.495517107692308 ], [ 29.750331206896551, -1.494978153846154 ], [ 29.750061724137932, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3928, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.494978153846154 ], [ 29.750331206896551, -1.495247630769231 ], [ 29.750600689655172, -1.495247630769231 ], [ 29.750600689655172, -1.494978153846154 ], [ 29.750331206896551, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3929, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.494978153846154 ], [ 29.750600689655172, -1.495247630769231 ], [ 29.750870172413794, -1.495247630769231 ], [ 29.750870172413794, -1.494978153846154 ], [ 29.750600689655172, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3930, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.494978153846154 ], [ 29.750870172413794, -1.495247630769231 ], [ 29.751139655172413, -1.495247630769231 ], [ 29.751139655172413, -1.494978153846154 ], [ 29.750870172413794, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3931, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.494978153846154 ], [ 29.751139655172413, -1.495247630769231 ], [ 29.751409137931034, -1.495247630769231 ], [ 29.751409137931034, -1.494978153846154 ], [ 29.751139655172413, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3932, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.494978153846154 ], [ 29.751409137931034, -1.495247630769231 ], [ 29.751678620689656, -1.495247630769231 ], [ 29.751678620689656, -1.494978153846154 ], [ 29.751409137931034, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3933, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.494978153846154 ], [ 29.751678620689656, -1.495247630769231 ], [ 29.751948103448278, -1.495247630769231 ], [ 29.751948103448278, -1.494978153846154 ], [ 29.751678620689656, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3934, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.494978153846154 ], [ 29.751948103448278, -1.495247630769231 ], [ 29.752217586206896, -1.495247630769231 ], [ 29.752217586206896, -1.494978153846154 ], [ 29.751948103448278, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3935, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.494978153846154 ], [ 29.752217586206896, -1.495247630769231 ], [ 29.752487068965518, -1.495247630769231 ], [ 29.752487068965518, -1.494978153846154 ], [ 29.752217586206896, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3936, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.494978153846154 ], [ 29.752487068965518, -1.495517107692308 ], [ 29.75275655172414, -1.495517107692308 ], [ 29.75275655172414, -1.494978153846154 ], [ 29.752487068965518, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3937, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.494978153846154 ], [ 29.753026034482758, -1.495247630769231 ], [ 29.75329551724138, -1.495247630769231 ], [ 29.75329551724138, -1.494978153846154 ], [ 29.753026034482758, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3938, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.494978153846154 ], [ 29.75329551724138, -1.495517107692308 ], [ 29.753565, -1.495517107692308 ], [ 29.753565, -1.494978153846154 ], [ 29.75329551724138, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3939, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.494978153846154 ], [ 29.753565, -1.495517107692308 ], [ 29.75383448275862, -1.495517107692308 ], [ 29.75383448275862, -1.494978153846154 ], [ 29.753565, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3940, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.494978153846154 ], [ 29.754373448275864, -1.495247630769231 ], [ 29.754642931034482, -1.495247630769231 ], [ 29.754642931034482, -1.494978153846154 ], [ 29.754373448275864, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3941, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.494978153846154 ], [ 29.754642931034482, -1.495247630769231 ], [ 29.754912413793104, -1.495247630769231 ], [ 29.754912413793104, -1.494978153846154 ], [ 29.754642931034482, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3942, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.494978153846154 ], [ 29.754912413793104, -1.495247630769231 ], [ 29.755181896551726, -1.495247630769231 ], [ 29.755181896551726, -1.494978153846154 ], [ 29.754912413793104, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3943, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.494978153846154 ], [ 29.755181896551726, -1.495247630769231 ], [ 29.755451379310344, -1.495247630769231 ], [ 29.755451379310344, -1.494978153846154 ], [ 29.755181896551726, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3944, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.494978153846154 ], [ 29.755451379310344, -1.495247630769231 ], [ 29.755720862068966, -1.495247630769231 ], [ 29.755720862068966, -1.494978153846154 ], [ 29.755451379310344, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3945, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.494978153846154 ], [ 29.755720862068966, -1.495247630769231 ], [ 29.755990344827588, -1.495247630769231 ], [ 29.755990344827588, -1.494978153846154 ], [ 29.755720862068966, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3946, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.494978153846154 ], [ 29.755990344827588, -1.495247630769231 ], [ 29.756259827586206, -1.495247630769231 ], [ 29.756259827586206, -1.494978153846154 ], [ 29.755990344827588, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3947, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.494978153846154 ], [ 29.756259827586206, -1.495247630769231 ], [ 29.756798793103449, -1.495247630769231 ], [ 29.756798793103449, -1.494978153846154 ], [ 29.756259827586206, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3948, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.494978153846154 ], [ 29.756798793103449, -1.495247630769231 ], [ 29.757068275862068, -1.495247630769231 ], [ 29.757068275862068, -1.494978153846154 ], [ 29.756798793103449, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3949, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.494978153846154 ], [ 29.757068275862068, -1.495247630769231 ], [ 29.75733775862069, -1.495247630769231 ], [ 29.75733775862069, -1.494978153846154 ], [ 29.757068275862068, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3950, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.494978153846154 ], [ 29.75733775862069, -1.495517107692308 ], [ 29.757607241379311, -1.495517107692308 ], [ 29.757607241379311, -1.494978153846154 ], [ 29.75733775862069, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3951, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.494978153846154 ], [ 29.757607241379311, -1.495517107692308 ], [ 29.757876724137933, -1.495517107692308 ], [ 29.757876724137933, -1.494978153846154 ], [ 29.757607241379311, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3952, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.494978153846154 ], [ 29.758685172413795, -1.495517107692308 ], [ 29.758954655172413, -1.495517107692308 ], [ 29.758954655172413, -1.494978153846154 ], [ 29.758685172413795, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3953, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.494978153846154 ], [ 29.759493620689657, -1.495247630769231 ], [ 29.759763103448275, -1.495247630769231 ], [ 29.759763103448275, -1.494978153846154 ], [ 29.759493620689657, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3954, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.494978153846154 ], [ 29.759763103448275, -1.495517107692308 ], [ 29.760032586206897, -1.495517107692308 ], [ 29.760032586206897, -1.494978153846154 ], [ 29.759763103448275, -1.494978153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3955, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.495247630769231 ], [ 29.736318103448276, -1.495517107692308 ], [ 29.736587586206898, -1.495517107692308 ], [ 29.736587586206898, -1.495247630769231 ], [ 29.736318103448276, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3956, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.495247630769231 ], [ 29.736587586206898, -1.495517107692308 ], [ 29.736857068965517, -1.495517107692308 ], [ 29.736857068965517, -1.495247630769231 ], [ 29.736587586206898, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3957, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.495247630769231 ], [ 29.736857068965517, -1.495517107692308 ], [ 29.737126551724138, -1.495517107692308 ], [ 29.737126551724138, -1.495247630769231 ], [ 29.736857068965517, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3958, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.495247630769231 ], [ 29.737126551724138, -1.495517107692308 ], [ 29.73739603448276, -1.495517107692308 ], [ 29.73739603448276, -1.495247630769231 ], [ 29.737126551724138, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3959, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.495247630769231 ], [ 29.73739603448276, -1.495517107692308 ], [ 29.737665517241378, -1.495517107692308 ], [ 29.737665517241378, -1.495247630769231 ], [ 29.73739603448276, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3960, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.495247630769231 ], [ 29.737665517241378, -1.495517107692308 ], [ 29.737935, -1.495517107692308 ], [ 29.737935, -1.495247630769231 ], [ 29.737665517241378, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3961, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.495247630769231 ], [ 29.737935, -1.495517107692308 ], [ 29.738204482758622, -1.495517107692308 ], [ 29.738204482758622, -1.495247630769231 ], [ 29.737935, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3962, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.495247630769231 ], [ 29.738204482758622, -1.495517107692308 ], [ 29.738743448275862, -1.495517107692308 ], [ 29.738743448275862, -1.495247630769231 ], [ 29.738204482758622, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3963, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.495247630769231 ], [ 29.738743448275862, -1.495517107692308 ], [ 29.739012931034484, -1.495517107692308 ], [ 29.739012931034484, -1.495247630769231 ], [ 29.738743448275862, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3964, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.495247630769231 ], [ 29.739012931034484, -1.495517107692308 ], [ 29.739282413793102, -1.495517107692308 ], [ 29.739282413793102, -1.495247630769231 ], [ 29.739012931034484, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3965, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.495247630769231 ], [ 29.739282413793102, -1.495517107692308 ], [ 29.739551896551724, -1.495517107692308 ], [ 29.739551896551724, -1.495247630769231 ], [ 29.739282413793102, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3966, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.495247630769231 ], [ 29.739821379310346, -1.495786584615385 ], [ 29.740090862068964, -1.495786584615385 ], [ 29.740090862068964, -1.495247630769231 ], [ 29.739821379310346, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3967, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.495247630769231 ], [ 29.740090862068964, -1.495786584615385 ], [ 29.740360344827586, -1.495786584615385 ], [ 29.740360344827586, -1.495247630769231 ], [ 29.740090862068964, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3968, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.495247630769231 ], [ 29.740360344827586, -1.495517107692308 ], [ 29.740629827586208, -1.495517107692308 ], [ 29.740629827586208, -1.495247630769231 ], [ 29.740360344827586, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3969, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.495247630769231 ], [ 29.74925327586207, -1.495517107692308 ], [ 29.749522758620689, -1.495517107692308 ], [ 29.749522758620689, -1.495247630769231 ], [ 29.74925327586207, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3970, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.495247630769231 ], [ 29.749522758620689, -1.495786584615385 ], [ 29.749792241379311, -1.495786584615385 ], [ 29.749792241379311, -1.495247630769231 ], [ 29.749522758620689, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3971, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.495247630769231 ], [ 29.749792241379311, -1.495786584615385 ], [ 29.750061724137932, -1.495786584615385 ], [ 29.750061724137932, -1.495247630769231 ], [ 29.749792241379311, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3972, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.495247630769231 ], [ 29.750331206896551, -1.495517107692308 ], [ 29.750600689655172, -1.495517107692308 ], [ 29.750600689655172, -1.495247630769231 ], [ 29.750331206896551, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3973, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.495247630769231 ], [ 29.750600689655172, -1.495517107692308 ], [ 29.750870172413794, -1.495517107692308 ], [ 29.750870172413794, -1.495247630769231 ], [ 29.750600689655172, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3974, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.495247630769231 ], [ 29.750870172413794, -1.495517107692308 ], [ 29.751139655172413, -1.495517107692308 ], [ 29.751139655172413, -1.495247630769231 ], [ 29.750870172413794, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3975, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.495247630769231 ], [ 29.751139655172413, -1.495517107692308 ], [ 29.751409137931034, -1.495517107692308 ], [ 29.751409137931034, -1.495247630769231 ], [ 29.751139655172413, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3976, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.495247630769231 ], [ 29.751409137931034, -1.495517107692308 ], [ 29.751678620689656, -1.495517107692308 ], [ 29.751678620689656, -1.495247630769231 ], [ 29.751409137931034, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3977, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.495247630769231 ], [ 29.751678620689656, -1.495517107692308 ], [ 29.751948103448278, -1.495517107692308 ], [ 29.751948103448278, -1.495247630769231 ], [ 29.751678620689656, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3978, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.495247630769231 ], [ 29.751948103448278, -1.495786584615385 ], [ 29.752217586206896, -1.495786584615385 ], [ 29.752217586206896, -1.495247630769231 ], [ 29.751948103448278, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3979, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.495247630769231 ], [ 29.75275655172414, -1.495786584615385 ], [ 29.753026034482758, -1.495786584615385 ], [ 29.753026034482758, -1.495247630769231 ], [ 29.75275655172414, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3980, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.495247630769231 ], [ 29.75383448275862, -1.496056061538461 ], [ 29.754103965517242, -1.496056061538461 ], [ 29.754103965517242, -1.495247630769231 ], [ 29.75383448275862, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3981, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.495247630769231 ], [ 29.754373448275864, -1.495517107692308 ], [ 29.754642931034482, -1.495517107692308 ], [ 29.754642931034482, -1.495247630769231 ], [ 29.754373448275864, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3982, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.495247630769231 ], [ 29.754642931034482, -1.495517107692308 ], [ 29.754912413793104, -1.495517107692308 ], [ 29.754912413793104, -1.495247630769231 ], [ 29.754642931034482, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3983, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.495247630769231 ], [ 29.754912413793104, -1.495517107692308 ], [ 29.755181896551726, -1.495517107692308 ], [ 29.755181896551726, -1.495247630769231 ], [ 29.754912413793104, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3984, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.495247630769231 ], [ 29.755181896551726, -1.495517107692308 ], [ 29.755451379310344, -1.495517107692308 ], [ 29.755451379310344, -1.495247630769231 ], [ 29.755181896551726, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3985, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.495247630769231 ], [ 29.755451379310344, -1.495517107692308 ], [ 29.755720862068966, -1.495517107692308 ], [ 29.755720862068966, -1.495247630769231 ], [ 29.755451379310344, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3986, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.495247630769231 ], [ 29.755720862068966, -1.495517107692308 ], [ 29.755990344827588, -1.495517107692308 ], [ 29.755990344827588, -1.495247630769231 ], [ 29.755720862068966, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3987, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.495247630769231 ], [ 29.755990344827588, -1.495517107692308 ], [ 29.756259827586206, -1.495517107692308 ], [ 29.756259827586206, -1.495247630769231 ], [ 29.755990344827588, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3988, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.495247630769231 ], [ 29.756259827586206, -1.495517107692308 ], [ 29.756798793103449, -1.495517107692308 ], [ 29.756798793103449, -1.495247630769231 ], [ 29.756259827586206, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3989, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.495247630769231 ], [ 29.756798793103449, -1.495786584615385 ], [ 29.757068275862068, -1.495786584615385 ], [ 29.757068275862068, -1.495247630769231 ], [ 29.756798793103449, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3990, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.495247630769231 ], [ 29.757068275862068, -1.495786584615385 ], [ 29.75733775862069, -1.495786584615385 ], [ 29.75733775862069, -1.495247630769231 ], [ 29.757068275862068, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3991, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.495247630769231 ], [ 29.757876724137933, -1.495517107692308 ], [ 29.758146206896551, -1.495517107692308 ], [ 29.758146206896551, -1.495247630769231 ], [ 29.757876724137933, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3992, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.495247630769231 ], [ 29.758146206896551, -1.495517107692308 ], [ 29.758415689655173, -1.495517107692308 ], [ 29.758415689655173, -1.495247630769231 ], [ 29.758146206896551, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3993, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.495247630769231 ], [ 29.758415689655173, -1.495517107692308 ], [ 29.758685172413795, -1.495517107692308 ], [ 29.758685172413795, -1.495247630769231 ], [ 29.758415689655173, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3994, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.495247630769231 ], [ 29.758954655172413, -1.495517107692308 ], [ 29.759224137931035, -1.495517107692308 ], [ 29.759224137931035, -1.495247630769231 ], [ 29.758954655172413, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3995, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.495247630769231 ], [ 29.759224137931035, -1.495786584615385 ], [ 29.759493620689657, -1.495786584615385 ], [ 29.759493620689657, -1.495247630769231 ], [ 29.759224137931035, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3996, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.495247630769231 ], [ 29.759493620689657, -1.495786584615385 ], [ 29.759763103448275, -1.495786584615385 ], [ 29.759763103448275, -1.495247630769231 ], [ 29.759493620689657, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3997, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.495517107692308 ], [ 29.736318103448276, -1.495786584615385 ], [ 29.736587586206898, -1.495786584615385 ], [ 29.736587586206898, -1.495517107692308 ], [ 29.736318103448276, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3998, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.495517107692308 ], [ 29.736587586206898, -1.495786584615385 ], [ 29.736857068965517, -1.495786584615385 ], [ 29.736857068965517, -1.495517107692308 ], [ 29.736587586206898, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3999, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.495517107692308 ], [ 29.736857068965517, -1.495786584615385 ], [ 29.737126551724138, -1.495786584615385 ], [ 29.737126551724138, -1.495517107692308 ], [ 29.736857068965517, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4000, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.495517107692308 ], [ 29.737126551724138, -1.495786584615385 ], [ 29.73739603448276, -1.495786584615385 ], [ 29.73739603448276, -1.495517107692308 ], [ 29.737126551724138, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4001, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.495517107692308 ], [ 29.73739603448276, -1.495786584615385 ], [ 29.737665517241378, -1.495786584615385 ], [ 29.737665517241378, -1.495517107692308 ], [ 29.73739603448276, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4002, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.495517107692308 ], [ 29.737665517241378, -1.495786584615385 ], [ 29.737935, -1.495786584615385 ], [ 29.737935, -1.495517107692308 ], [ 29.737665517241378, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4003, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.495517107692308 ], [ 29.737935, -1.495786584615385 ], [ 29.738204482758622, -1.495786584615385 ], [ 29.738204482758622, -1.495517107692308 ], [ 29.737935, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4004, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.495517107692308 ], [ 29.738204482758622, -1.495786584615385 ], [ 29.738743448275862, -1.495786584615385 ], [ 29.738743448275862, -1.495517107692308 ], [ 29.738204482758622, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4005, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.495517107692308 ], [ 29.738743448275862, -1.495786584615385 ], [ 29.739012931034484, -1.495786584615385 ], [ 29.739012931034484, -1.495517107692308 ], [ 29.738743448275862, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4006, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.495517107692308 ], [ 29.739012931034484, -1.495786584615385 ], [ 29.739282413793102, -1.495786584615385 ], [ 29.739282413793102, -1.495517107692308 ], [ 29.739012931034484, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4007, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.495517107692308 ], [ 29.739282413793102, -1.496056061538461 ], [ 29.739551896551724, -1.496056061538461 ], [ 29.739551896551724, -1.495517107692308 ], [ 29.739282413793102, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4008, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.495517107692308 ], [ 29.739551896551724, -1.495786584615385 ], [ 29.739821379310346, -1.495786584615385 ], [ 29.739821379310346, -1.495517107692308 ], [ 29.739551896551724, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4009, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.495517107692308 ], [ 29.740360344827586, -1.496056061538461 ], [ 29.740629827586208, -1.496056061538461 ], [ 29.740629827586208, -1.495517107692308 ], [ 29.740360344827586, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4010, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.495517107692308 ], [ 29.74925327586207, -1.496056061538461 ], [ 29.749522758620689, -1.496056061538461 ], [ 29.749522758620689, -1.495517107692308 ], [ 29.74925327586207, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4011, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.495517107692308 ], [ 29.750061724137932, -1.495786584615385 ], [ 29.750331206896551, -1.495786584615385 ], [ 29.750331206896551, -1.495517107692308 ], [ 29.750061724137932, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4012, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.495517107692308 ], [ 29.750331206896551, -1.496056061538461 ], [ 29.750600689655172, -1.496056061538461 ], [ 29.750600689655172, -1.495517107692308 ], [ 29.750331206896551, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4013, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.495517107692308 ], [ 29.750600689655172, -1.495786584615385 ], [ 29.750870172413794, -1.495786584615385 ], [ 29.750870172413794, -1.495517107692308 ], [ 29.750600689655172, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4014, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.495517107692308 ], [ 29.750870172413794, -1.495786584615385 ], [ 29.751139655172413, -1.495786584615385 ], [ 29.751139655172413, -1.495517107692308 ], [ 29.750870172413794, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4015, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.495517107692308 ], [ 29.751139655172413, -1.495786584615385 ], [ 29.751409137931034, -1.495786584615385 ], [ 29.751409137931034, -1.495517107692308 ], [ 29.751139655172413, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4016, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.495517107692308 ], [ 29.754373448275864, -1.495786584615385 ], [ 29.754642931034482, -1.495786584615385 ], [ 29.754642931034482, -1.495517107692308 ], [ 29.754373448275864, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4017, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.495517107692308 ], [ 29.754642931034482, -1.495786584615385 ], [ 29.754912413793104, -1.495786584615385 ], [ 29.754912413793104, -1.495517107692308 ], [ 29.754642931034482, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4018, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.495517107692308 ], [ 29.754912413793104, -1.495786584615385 ], [ 29.755181896551726, -1.495786584615385 ], [ 29.755181896551726, -1.495517107692308 ], [ 29.754912413793104, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4019, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.495517107692308 ], [ 29.755181896551726, -1.495786584615385 ], [ 29.755451379310344, -1.495786584615385 ], [ 29.755451379310344, -1.495517107692308 ], [ 29.755181896551726, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4020, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.495517107692308 ], [ 29.755451379310344, -1.495786584615385 ], [ 29.755720862068966, -1.495786584615385 ], [ 29.755720862068966, -1.495517107692308 ], [ 29.755451379310344, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4021, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.495517107692308 ], [ 29.755720862068966, -1.495786584615385 ], [ 29.755990344827588, -1.495786584615385 ], [ 29.755990344827588, -1.495517107692308 ], [ 29.755720862068966, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4022, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.495517107692308 ], [ 29.755990344827588, -1.495786584615385 ], [ 29.756259827586206, -1.495786584615385 ], [ 29.756259827586206, -1.495517107692308 ], [ 29.755990344827588, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4023, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.495517107692308 ], [ 29.756259827586206, -1.496056061538461 ], [ 29.756798793103449, -1.496056061538461 ], [ 29.756798793103449, -1.495517107692308 ], [ 29.756259827586206, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4024, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.495517107692308 ], [ 29.75733775862069, -1.495786584615385 ], [ 29.757607241379311, -1.495786584615385 ], [ 29.757607241379311, -1.495517107692308 ], [ 29.75733775862069, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4025, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.495517107692308 ], [ 29.757607241379311, -1.495786584615385 ], [ 29.757876724137933, -1.495786584615385 ], [ 29.757876724137933, -1.495517107692308 ], [ 29.757607241379311, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4026, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.495517107692308 ], [ 29.757876724137933, -1.495786584615385 ], [ 29.758146206896551, -1.495786584615385 ], [ 29.758146206896551, -1.495517107692308 ], [ 29.757876724137933, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4027, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.495517107692308 ], [ 29.758146206896551, -1.495786584615385 ], [ 29.758415689655173, -1.495786584615385 ], [ 29.758415689655173, -1.495517107692308 ], [ 29.758146206896551, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4028, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.495517107692308 ], [ 29.758415689655173, -1.495786584615385 ], [ 29.758685172413795, -1.495786584615385 ], [ 29.758685172413795, -1.495517107692308 ], [ 29.758415689655173, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4029, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.495517107692308 ], [ 29.758685172413795, -1.495786584615385 ], [ 29.758954655172413, -1.495786584615385 ], [ 29.758954655172413, -1.495517107692308 ], [ 29.758685172413795, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4030, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.495517107692308 ], [ 29.758954655172413, -1.496056061538461 ], [ 29.759224137931035, -1.496056061538461 ], [ 29.759224137931035, -1.495517107692308 ], [ 29.758954655172413, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4031, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.495517107692308 ], [ 29.759763103448275, -1.495786584615385 ], [ 29.760032586206897, -1.495786584615385 ], [ 29.760032586206897, -1.495517107692308 ], [ 29.759763103448275, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4032, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.495517107692308 ], [ 29.760032586206897, -1.495786584615385 ], [ 29.760302068965519, -1.495786584615385 ], [ 29.760302068965519, -1.495517107692308 ], [ 29.760032586206897, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4033, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.495786584615385 ], [ 29.718801724137933, -1.496056061538461 ], [ 29.719071206896551, -1.496056061538461 ], [ 29.719071206896551, -1.495786584615385 ], [ 29.718801724137933, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4034, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.495786584615385 ], [ 29.719071206896551, -1.496056061538461 ], [ 29.719340689655173, -1.496056061538461 ], [ 29.719340689655173, -1.495786584615385 ], [ 29.719071206896551, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4035, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.495786584615385 ], [ 29.719879655172413, -1.496325538461538 ], [ 29.720149137931035, -1.496325538461538 ], [ 29.720149137931035, -1.495786584615385 ], [ 29.719879655172413, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4036, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.495786584615385 ], [ 29.735509655172415, -1.496056061538461 ], [ 29.735779137931036, -1.496056061538461 ], [ 29.735779137931036, -1.495786584615385 ], [ 29.735509655172415, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4037, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.495786584615385 ], [ 29.735779137931036, -1.496056061538461 ], [ 29.736048620689655, -1.496056061538461 ], [ 29.736048620689655, -1.495786584615385 ], [ 29.735779137931036, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4038, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.495786584615385 ], [ 29.736048620689655, -1.496056061538461 ], [ 29.736318103448276, -1.496056061538461 ], [ 29.736318103448276, -1.495786584615385 ], [ 29.736048620689655, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4039, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.495786584615385 ], [ 29.736318103448276, -1.496056061538461 ], [ 29.736587586206898, -1.496056061538461 ], [ 29.736587586206898, -1.495786584615385 ], [ 29.736318103448276, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4040, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.495786584615385 ], [ 29.736587586206898, -1.496056061538461 ], [ 29.736857068965517, -1.496056061538461 ], [ 29.736857068965517, -1.495786584615385 ], [ 29.736587586206898, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4041, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.495786584615385 ], [ 29.736857068965517, -1.496056061538461 ], [ 29.737126551724138, -1.496056061538461 ], [ 29.737126551724138, -1.495786584615385 ], [ 29.736857068965517, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4042, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.495786584615385 ], [ 29.737126551724138, -1.496056061538461 ], [ 29.73739603448276, -1.496056061538461 ], [ 29.73739603448276, -1.495786584615385 ], [ 29.737126551724138, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4043, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.495786584615385 ], [ 29.73739603448276, -1.496056061538461 ], [ 29.737665517241378, -1.496056061538461 ], [ 29.737665517241378, -1.495786584615385 ], [ 29.73739603448276, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4044, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.495786584615385 ], [ 29.737665517241378, -1.496056061538461 ], [ 29.737935, -1.496056061538461 ], [ 29.737935, -1.495786584615385 ], [ 29.737665517241378, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4045, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.495786584615385 ], [ 29.737935, -1.496056061538461 ], [ 29.738204482758622, -1.496056061538461 ], [ 29.738204482758622, -1.495786584615385 ], [ 29.737935, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4046, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.495786584615385 ], [ 29.738204482758622, -1.496056061538461 ], [ 29.738743448275862, -1.496056061538461 ], [ 29.738743448275862, -1.495786584615385 ], [ 29.738204482758622, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4047, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.495786584615385 ], [ 29.738743448275862, -1.496056061538461 ], [ 29.739012931034484, -1.496056061538461 ], [ 29.739012931034484, -1.495786584615385 ], [ 29.738743448275862, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4048, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.495786584615385 ], [ 29.739012931034484, -1.496056061538461 ], [ 29.739282413793102, -1.496056061538461 ], [ 29.739282413793102, -1.495786584615385 ], [ 29.739012931034484, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4049, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.495786584615385 ], [ 29.739551896551724, -1.496325538461538 ], [ 29.739821379310346, -1.496325538461538 ], [ 29.739821379310346, -1.495786584615385 ], [ 29.739551896551724, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4050, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.495786584615385 ], [ 29.739821379310346, -1.496325538461538 ], [ 29.740090862068964, -1.496325538461538 ], [ 29.740090862068964, -1.495786584615385 ], [ 29.739821379310346, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4051, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.495786584615385 ], [ 29.740090862068964, -1.496056061538461 ], [ 29.740360344827586, -1.496056061538461 ], [ 29.740360344827586, -1.495786584615385 ], [ 29.740090862068964, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4052, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.495786584615385 ], [ 29.749522758620689, -1.496056061538461 ], [ 29.749792241379311, -1.496056061538461 ], [ 29.749792241379311, -1.495786584615385 ], [ 29.749522758620689, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4053, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.495786584615385 ], [ 29.754373448275864, -1.496056061538461 ], [ 29.754642931034482, -1.496056061538461 ], [ 29.754642931034482, -1.495786584615385 ], [ 29.754373448275864, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4054, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.495786584615385 ], [ 29.754642931034482, -1.496056061538461 ], [ 29.754912413793104, -1.496056061538461 ], [ 29.754912413793104, -1.495786584615385 ], [ 29.754642931034482, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4055, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.495786584615385 ], [ 29.754912413793104, -1.496056061538461 ], [ 29.755181896551726, -1.496056061538461 ], [ 29.755181896551726, -1.495786584615385 ], [ 29.754912413793104, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4056, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.495786584615385 ], [ 29.755181896551726, -1.496056061538461 ], [ 29.755451379310344, -1.496056061538461 ], [ 29.755451379310344, -1.495786584615385 ], [ 29.755181896551726, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4057, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.495786584615385 ], [ 29.755451379310344, -1.496056061538461 ], [ 29.755720862068966, -1.496056061538461 ], [ 29.755720862068966, -1.495786584615385 ], [ 29.755451379310344, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4058, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.495786584615385 ], [ 29.755720862068966, -1.496056061538461 ], [ 29.755990344827588, -1.496056061538461 ], [ 29.755990344827588, -1.495786584615385 ], [ 29.755720862068966, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4059, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.495786584615385 ], [ 29.755990344827588, -1.496325538461538 ], [ 29.756259827586206, -1.496325538461538 ], [ 29.756259827586206, -1.495786584615385 ], [ 29.755990344827588, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4060, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.495786584615385 ], [ 29.756798793103449, -1.496056061538461 ], [ 29.757068275862068, -1.496056061538461 ], [ 29.757068275862068, -1.495786584615385 ], [ 29.756798793103449, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4061, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.495786584615385 ], [ 29.757068275862068, -1.496056061538461 ], [ 29.75733775862069, -1.496056061538461 ], [ 29.75733775862069, -1.495786584615385 ], [ 29.757068275862068, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4062, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.495786584615385 ], [ 29.75733775862069, -1.496056061538461 ], [ 29.757607241379311, -1.496056061538461 ], [ 29.757607241379311, -1.495786584615385 ], [ 29.75733775862069, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4063, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.495786584615385 ], [ 29.757607241379311, -1.496056061538461 ], [ 29.757876724137933, -1.496056061538461 ], [ 29.757876724137933, -1.495786584615385 ], [ 29.757607241379311, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4064, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.495786584615385 ], [ 29.757876724137933, -1.496056061538461 ], [ 29.758146206896551, -1.496056061538461 ], [ 29.758146206896551, -1.495786584615385 ], [ 29.757876724137933, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4065, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.495786584615385 ], [ 29.758146206896551, -1.496056061538461 ], [ 29.758415689655173, -1.496056061538461 ], [ 29.758415689655173, -1.495786584615385 ], [ 29.758146206896551, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4066, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.495786584615385 ], [ 29.758415689655173, -1.496056061538461 ], [ 29.758685172413795, -1.496056061538461 ], [ 29.758685172413795, -1.495786584615385 ], [ 29.758415689655173, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4067, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.495786584615385 ], [ 29.758685172413795, -1.496056061538461 ], [ 29.758954655172413, -1.496056061538461 ], [ 29.758954655172413, -1.495786584615385 ], [ 29.758685172413795, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4068, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.495786584615385 ], [ 29.759224137931035, -1.496056061538461 ], [ 29.759493620689657, -1.496056061538461 ], [ 29.759493620689657, -1.495786584615385 ], [ 29.759224137931035, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4069, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.495786584615385 ], [ 29.759493620689657, -1.496056061538461 ], [ 29.759763103448275, -1.496056061538461 ], [ 29.759763103448275, -1.495786584615385 ], [ 29.759493620689657, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4070, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.495786584615385 ], [ 29.759763103448275, -1.496056061538461 ], [ 29.760032586206897, -1.496056061538461 ], [ 29.760032586206897, -1.495786584615385 ], [ 29.759763103448275, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4071, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.495786584615385 ], [ 29.760032586206897, -1.496056061538461 ], [ 29.760302068965519, -1.496056061538461 ], [ 29.760302068965519, -1.495786584615385 ], [ 29.760032586206897, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4072, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.495786584615385 ], [ 29.760302068965519, -1.496056061538461 ], [ 29.760841034482759, -1.496056061538461 ], [ 29.760841034482759, -1.495786584615385 ], [ 29.760302068965519, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4073, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.496056061538461 ], [ 29.718262758620689, -1.496325538461538 ], [ 29.718801724137933, -1.496325538461538 ], [ 29.718801724137933, -1.496056061538461 ], [ 29.718262758620689, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4074, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.496056061538461 ], [ 29.718801724137933, -1.496325538461538 ], [ 29.719071206896551, -1.496325538461538 ], [ 29.719071206896551, -1.496056061538461 ], [ 29.718801724137933, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4075, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.496056061538461 ], [ 29.719071206896551, -1.496325538461538 ], [ 29.719340689655173, -1.496325538461538 ], [ 29.719340689655173, -1.496056061538461 ], [ 29.719071206896551, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4076, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.496056061538461 ], [ 29.719340689655173, -1.496325538461538 ], [ 29.719610172413795, -1.496325538461538 ], [ 29.719610172413795, -1.496056061538461 ], [ 29.719340689655173, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4077, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.496056061538461 ], [ 29.719610172413795, -1.496325538461538 ], [ 29.719879655172413, -1.496325538461538 ], [ 29.719879655172413, -1.496056061538461 ], [ 29.719610172413795, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4078, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.496056061538461 ], [ 29.720149137931035, -1.496325538461538 ], [ 29.720688103448275, -1.496325538461538 ], [ 29.720688103448275, -1.496056061538461 ], [ 29.720149137931035, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4079, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.496056061538461 ], [ 29.720688103448275, -1.496325538461538 ], [ 29.720957586206897, -1.496325538461538 ], [ 29.720957586206897, -1.496056061538461 ], [ 29.720688103448275, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4080, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.496056061538461 ], [ 29.720957586206897, -1.496325538461538 ], [ 29.721227068965518, -1.496325538461538 ], [ 29.721227068965518, -1.496056061538461 ], [ 29.720957586206897, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4081, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.496056061538461 ], [ 29.734970689655171, -1.496325538461538 ], [ 29.735240172413793, -1.496325538461538 ], [ 29.735240172413793, -1.496056061538461 ], [ 29.734970689655171, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4082, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.496056061538461 ], [ 29.735240172413793, -1.496325538461538 ], [ 29.735509655172415, -1.496325538461538 ], [ 29.735509655172415, -1.496056061538461 ], [ 29.735240172413793, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4083, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.496056061538461 ], [ 29.735509655172415, -1.496325538461538 ], [ 29.735779137931036, -1.496325538461538 ], [ 29.735779137931036, -1.496056061538461 ], [ 29.735509655172415, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4084, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.496056061538461 ], [ 29.735779137931036, -1.496325538461538 ], [ 29.736048620689655, -1.496325538461538 ], [ 29.736048620689655, -1.496056061538461 ], [ 29.735779137931036, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4085, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.496056061538461 ], [ 29.736048620689655, -1.496325538461538 ], [ 29.736318103448276, -1.496325538461538 ], [ 29.736318103448276, -1.496056061538461 ], [ 29.736048620689655, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4086, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.496056061538461 ], [ 29.736318103448276, -1.496325538461538 ], [ 29.736587586206898, -1.496325538461538 ], [ 29.736587586206898, -1.496056061538461 ], [ 29.736318103448276, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4087, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.496056061538461 ], [ 29.736587586206898, -1.496325538461538 ], [ 29.736857068965517, -1.496325538461538 ], [ 29.736857068965517, -1.496056061538461 ], [ 29.736587586206898, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4088, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.496056061538461 ], [ 29.736857068965517, -1.496325538461538 ], [ 29.737126551724138, -1.496325538461538 ], [ 29.737126551724138, -1.496056061538461 ], [ 29.736857068965517, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4089, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.496056061538461 ], [ 29.737126551724138, -1.496325538461538 ], [ 29.73739603448276, -1.496325538461538 ], [ 29.73739603448276, -1.496056061538461 ], [ 29.737126551724138, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4090, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.496056061538461 ], [ 29.73739603448276, -1.496325538461538 ], [ 29.737665517241378, -1.496325538461538 ], [ 29.737665517241378, -1.496056061538461 ], [ 29.73739603448276, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4091, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.496056061538461 ], [ 29.737665517241378, -1.496325538461538 ], [ 29.737935, -1.496325538461538 ], [ 29.737935, -1.496056061538461 ], [ 29.737665517241378, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4092, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.496056061538461 ], [ 29.737935, -1.496325538461538 ], [ 29.738204482758622, -1.496325538461538 ], [ 29.738204482758622, -1.496056061538461 ], [ 29.737935, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4093, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.496056061538461 ], [ 29.738204482758622, -1.496325538461538 ], [ 29.738743448275862, -1.496325538461538 ], [ 29.738743448275862, -1.496056061538461 ], [ 29.738204482758622, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4094, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.496056061538461 ], [ 29.738743448275862, -1.496325538461538 ], [ 29.739012931034484, -1.496325538461538 ], [ 29.739012931034484, -1.496056061538461 ], [ 29.738743448275862, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4095, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.496056061538461 ], [ 29.739282413793102, -1.496325538461538 ], [ 29.739551896551724, -1.496325538461538 ], [ 29.739551896551724, -1.496056061538461 ], [ 29.739282413793102, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4096, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.496056061538461 ], [ 29.754642931034482, -1.496325538461538 ], [ 29.754912413793104, -1.496325538461538 ], [ 29.754912413793104, -1.496056061538461 ], [ 29.754642931034482, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4097, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.496056061538461 ], [ 29.754912413793104, -1.496325538461538 ], [ 29.755181896551726, -1.496325538461538 ], [ 29.755181896551726, -1.496056061538461 ], [ 29.754912413793104, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4098, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.496056061538461 ], [ 29.755181896551726, -1.496325538461538 ], [ 29.755451379310344, -1.496325538461538 ], [ 29.755451379310344, -1.496056061538461 ], [ 29.755181896551726, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4099, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.496056061538461 ], [ 29.755451379310344, -1.496325538461538 ], [ 29.755720862068966, -1.496325538461538 ], [ 29.755720862068966, -1.496056061538461 ], [ 29.755451379310344, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4100, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.496056061538461 ], [ 29.756259827586206, -1.496325538461538 ], [ 29.756798793103449, -1.496325538461538 ], [ 29.756798793103449, -1.496056061538461 ], [ 29.756259827586206, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4101, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.496056061538461 ], [ 29.756798793103449, -1.496325538461538 ], [ 29.757068275862068, -1.496325538461538 ], [ 29.757068275862068, -1.496056061538461 ], [ 29.756798793103449, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4102, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.496056061538461 ], [ 29.757068275862068, -1.496325538461538 ], [ 29.75733775862069, -1.496325538461538 ], [ 29.75733775862069, -1.496056061538461 ], [ 29.757068275862068, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4103, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.496056061538461 ], [ 29.75733775862069, -1.496325538461538 ], [ 29.757607241379311, -1.496325538461538 ], [ 29.757607241379311, -1.496056061538461 ], [ 29.75733775862069, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4104, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.496056061538461 ], [ 29.757607241379311, -1.496325538461538 ], [ 29.757876724137933, -1.496325538461538 ], [ 29.757876724137933, -1.496056061538461 ], [ 29.757607241379311, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4105, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.496056061538461 ], [ 29.757876724137933, -1.496325538461538 ], [ 29.758146206896551, -1.496325538461538 ], [ 29.758146206896551, -1.496056061538461 ], [ 29.757876724137933, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4106, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.496056061538461 ], [ 29.758146206896551, -1.496325538461538 ], [ 29.758415689655173, -1.496325538461538 ], [ 29.758415689655173, -1.496056061538461 ], [ 29.758146206896551, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4107, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.496056061538461 ], [ 29.758415689655173, -1.496325538461538 ], [ 29.758685172413795, -1.496325538461538 ], [ 29.758685172413795, -1.496056061538461 ], [ 29.758415689655173, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4108, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.496056061538461 ], [ 29.758685172413795, -1.496325538461538 ], [ 29.758954655172413, -1.496325538461538 ], [ 29.758954655172413, -1.496056061538461 ], [ 29.758685172413795, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4109, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.496056061538461 ], [ 29.758954655172413, -1.496325538461538 ], [ 29.759224137931035, -1.496325538461538 ], [ 29.759224137931035, -1.496056061538461 ], [ 29.758954655172413, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4110, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.496056061538461 ], [ 29.759224137931035, -1.496325538461538 ], [ 29.759493620689657, -1.496325538461538 ], [ 29.759493620689657, -1.496056061538461 ], [ 29.759224137931035, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4111, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.496056061538461 ], [ 29.759493620689657, -1.496325538461538 ], [ 29.759763103448275, -1.496325538461538 ], [ 29.759763103448275, -1.496056061538461 ], [ 29.759493620689657, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4112, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.496056061538461 ], [ 29.759763103448275, -1.496325538461538 ], [ 29.760032586206897, -1.496325538461538 ], [ 29.760032586206897, -1.496056061538461 ], [ 29.759763103448275, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4113, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.496056061538461 ], [ 29.760032586206897, -1.496325538461538 ], [ 29.760571551724137, -1.496325538461538 ], [ 29.760571551724137, -1.496056061538461 ], [ 29.760032586206897, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4114, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.496056061538461 ], [ 29.760571551724137, -1.496325538461538 ], [ 29.760841034482759, -1.496325538461538 ], [ 29.760841034482759, -1.496056061538461 ], [ 29.760571551724137, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4115, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.493900246153846 ], [ 29.724999827586206, -1.497133969230769 ], [ 29.725269310344828, -1.497133969230769 ], [ 29.725269310344828, -1.493900246153846 ], [ 29.724999827586206, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4116, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.493900246153846 ], [ 29.72607775862069, -1.497133969230769 ], [ 29.726347241379312, -1.497133969230769 ], [ 29.726347241379312, -1.493900246153846 ], [ 29.72607775862069, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4117, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.493900246153846 ], [ 29.726886206896552, -1.497133969230769 ], [ 29.727155689655174, -1.497133969230769 ], [ 29.727155689655174, -1.493900246153846 ], [ 29.726886206896552, -1.493900246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4118, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.494169723076923 ], [ 29.72203551724138, -1.496595015384615 ], [ 29.722305, -1.496595015384615 ], [ 29.722305, -1.494169723076923 ], [ 29.72203551724138, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4119, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.494169723076923 ], [ 29.722843965517242, -1.496595015384615 ], [ 29.723113448275861, -1.496595015384615 ], [ 29.723113448275861, -1.494169723076923 ], [ 29.722843965517242, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4120, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.494169723076923 ], [ 29.723921896551722, -1.496595015384615 ], [ 29.724191379310344, -1.496595015384615 ], [ 29.724191379310344, -1.494169723076923 ], [ 29.723921896551722, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4121, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.494169723076923 ], [ 29.724730344827588, -1.496864492307692 ], [ 29.724999827586206, -1.496864492307692 ], [ 29.724999827586206, -1.494169723076923 ], [ 29.724730344827588, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4122, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.494169723076923 ], [ 29.72553879310345, -1.496864492307692 ], [ 29.725808275862068, -1.496864492307692 ], [ 29.725808275862068, -1.494169723076923 ], [ 29.72553879310345, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4123, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.494169723076923 ], [ 29.725808275862068, -1.496864492307692 ], [ 29.72607775862069, -1.496864492307692 ], [ 29.72607775862069, -1.494169723076923 ], [ 29.725808275862068, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4124, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.494169723076923 ], [ 29.72661672413793, -1.496864492307692 ], [ 29.726886206896552, -1.496864492307692 ], [ 29.726886206896552, -1.494169723076923 ], [ 29.72661672413793, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4125, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.494169723076923 ], [ 29.727425172413792, -1.496864492307692 ], [ 29.727694655172414, -1.496864492307692 ], [ 29.727694655172414, -1.494169723076923 ], [ 29.727425172413792, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4126, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.494169723076923 ], [ 29.727694655172414, -1.496864492307692 ], [ 29.727964137931036, -1.496864492307692 ], [ 29.727964137931036, -1.494169723076923 ], [ 29.727694655172414, -1.494169723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4127, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.4944392 ], [ 29.724460862068966, -1.496595015384615 ], [ 29.724730344827588, -1.496595015384615 ], [ 29.724730344827588, -1.4944392 ], [ 29.724460862068966, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4128, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.4944392 ], [ 29.726347241379312, -1.496595015384615 ], [ 29.72661672413793, -1.496595015384615 ], [ 29.72661672413793, -1.4944392 ], [ 29.726347241379312, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4129, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.4944392 ], [ 29.727155689655174, -1.496595015384615 ], [ 29.727425172413792, -1.496595015384615 ], [ 29.727425172413792, -1.4944392 ], [ 29.727155689655174, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4130, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.4944392 ], [ 29.727964137931036, -1.496595015384615 ], [ 29.728233620689654, -1.496595015384615 ], [ 29.728233620689654, -1.4944392 ], [ 29.727964137931036, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4131, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.4944392 ], [ 29.728233620689654, -1.496595015384615 ], [ 29.728503103448276, -1.496595015384615 ], [ 29.728503103448276, -1.4944392 ], [ 29.728233620689654, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4132, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.4944392 ], [ 29.728503103448276, -1.496595015384615 ], [ 29.728772586206897, -1.496595015384615 ], [ 29.728772586206897, -1.4944392 ], [ 29.728503103448276, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4133, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.4944392 ], [ 29.729042068965516, -1.496595015384615 ], [ 29.729311551724138, -1.496595015384615 ], [ 29.729311551724138, -1.4944392 ], [ 29.729042068965516, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4134, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.4944392 ], [ 29.729311551724138, -1.496595015384615 ], [ 29.729850517241381, -1.496595015384615 ], [ 29.729850517241381, -1.4944392 ], [ 29.729311551724138, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4135, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.4944392 ], [ 29.733084310344829, -1.497133969230769 ], [ 29.733353793103447, -1.497133969230769 ], [ 29.733353793103447, -1.4944392 ], [ 29.733084310344829, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4136, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.4944392 ], [ 29.733353793103447, -1.497133969230769 ], [ 29.733623275862069, -1.497133969230769 ], [ 29.733623275862069, -1.4944392 ], [ 29.733353793103447, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4137, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.4944392 ], [ 29.733892758620691, -1.496864492307692 ], [ 29.734162241379309, -1.496864492307692 ], [ 29.734162241379309, -1.4944392 ], [ 29.733892758620691, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4138, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.4944392 ], [ 29.734162241379309, -1.496595015384615 ], [ 29.734431724137931, -1.496595015384615 ], [ 29.734431724137931, -1.4944392 ], [ 29.734162241379309, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4139, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.4944392 ], [ 29.734431724137931, -1.496595015384615 ], [ 29.734701206896553, -1.496595015384615 ], [ 29.734701206896553, -1.4944392 ], [ 29.734431724137931, -1.4944392 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4140, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.494708676923077 ], [ 29.732814827586207, -1.496595015384615 ], [ 29.733084310344829, -1.496595015384615 ], [ 29.733084310344829, -1.494708676923077 ], [ 29.732814827586207, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4141, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.494708676923077 ], [ 29.733623275862069, -1.496595015384615 ], [ 29.733892758620691, -1.496595015384615 ], [ 29.733892758620691, -1.494708676923077 ], [ 29.733623275862069, -1.494708676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4142, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.495247630769231 ], [ 29.752217586206896, -1.497403446153846 ], [ 29.752487068965518, -1.497403446153846 ], [ 29.752487068965518, -1.495247630769231 ], [ 29.752217586206896, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4143, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.495247630769231 ], [ 29.753026034482758, -1.497403446153846 ], [ 29.75329551724138, -1.497403446153846 ], [ 29.75329551724138, -1.495247630769231 ], [ 29.753026034482758, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4144, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.495247630769231 ], [ 29.754103965517242, -1.497403446153846 ], [ 29.754373448275864, -1.497403446153846 ], [ 29.754373448275864, -1.495247630769231 ], [ 29.754103965517242, -1.495247630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4145, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.495517107692308 ], [ 29.751409137931034, -1.497133969230769 ], [ 29.751678620689656, -1.497133969230769 ], [ 29.751678620689656, -1.495517107692308 ], [ 29.751409137931034, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4146, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.495517107692308 ], [ 29.751678620689656, -1.497133969230769 ], [ 29.751948103448278, -1.497133969230769 ], [ 29.751948103448278, -1.495517107692308 ], [ 29.751678620689656, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4147, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.495517107692308 ], [ 29.752487068965518, -1.497133969230769 ], [ 29.75275655172414, -1.497133969230769 ], [ 29.75275655172414, -1.495517107692308 ], [ 29.752487068965518, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4148, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.495517107692308 ], [ 29.75329551724138, -1.497133969230769 ], [ 29.753565, -1.497133969230769 ], [ 29.753565, -1.495517107692308 ], [ 29.75329551724138, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4149, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.495517107692308 ], [ 29.753565, -1.497133969230769 ], [ 29.75383448275862, -1.497133969230769 ], [ 29.75383448275862, -1.495517107692308 ], [ 29.753565, -1.495517107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4150, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.495786584615385 ], [ 29.723652413793104, -1.496595015384615 ], [ 29.723921896551722, -1.496595015384615 ], [ 29.723921896551722, -1.495786584615385 ], [ 29.723652413793104, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4151, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.495786584615385 ], [ 29.749792241379311, -1.496864492307692 ], [ 29.750061724137932, -1.496864492307692 ], [ 29.750061724137932, -1.495786584615385 ], [ 29.749792241379311, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4152, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.495786584615385 ], [ 29.750061724137932, -1.496864492307692 ], [ 29.750331206896551, -1.496864492307692 ], [ 29.750331206896551, -1.495786584615385 ], [ 29.750061724137932, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4153, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.495786584615385 ], [ 29.750600689655172, -1.496864492307692 ], [ 29.750870172413794, -1.496864492307692 ], [ 29.750870172413794, -1.495786584615385 ], [ 29.750600689655172, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4154, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.495786584615385 ], [ 29.750870172413794, -1.496864492307692 ], [ 29.751139655172413, -1.496864492307692 ], [ 29.751139655172413, -1.495786584615385 ], [ 29.750870172413794, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4155, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.495786584615385 ], [ 29.751139655172413, -1.496864492307692 ], [ 29.751409137931034, -1.496864492307692 ], [ 29.751409137931034, -1.495786584615385 ], [ 29.751139655172413, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4156, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.495786584615385 ], [ 29.751948103448278, -1.496864492307692 ], [ 29.752217586206896, -1.496864492307692 ], [ 29.752217586206896, -1.495786584615385 ], [ 29.751948103448278, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4157, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.495786584615385 ], [ 29.75275655172414, -1.496864492307692 ], [ 29.753026034482758, -1.496864492307692 ], [ 29.753026034482758, -1.495786584615385 ], [ 29.75275655172414, -1.495786584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4158, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.496056061538461 ], [ 29.721766034482759, -1.496595015384615 ], [ 29.72203551724138, -1.496595015384615 ], [ 29.72203551724138, -1.496056061538461 ], [ 29.721766034482759, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4159, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.496056061538461 ], [ 29.72257448275862, -1.496595015384615 ], [ 29.722843965517242, -1.496595015384615 ], [ 29.722843965517242, -1.496056061538461 ], [ 29.72257448275862, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4160, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.496056061538461 ], [ 29.739012931034484, -1.496595015384615 ], [ 29.739282413793102, -1.496595015384615 ], [ 29.739282413793102, -1.496056061538461 ], [ 29.739012931034484, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4161, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.496056061538461 ], [ 29.740090862068964, -1.496595015384615 ], [ 29.740360344827586, -1.496595015384615 ], [ 29.740360344827586, -1.496056061538461 ], [ 29.740090862068964, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4162, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.496056061538461 ], [ 29.749522758620689, -1.496595015384615 ], [ 29.749792241379311, -1.496595015384615 ], [ 29.749792241379311, -1.496056061538461 ], [ 29.749522758620689, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4163, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.496056061538461 ], [ 29.750331206896551, -1.496595015384615 ], [ 29.750600689655172, -1.496595015384615 ], [ 29.750600689655172, -1.496056061538461 ], [ 29.750331206896551, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4164, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.496056061538461 ], [ 29.75383448275862, -1.496595015384615 ], [ 29.754103965517242, -1.496595015384615 ], [ 29.754103965517242, -1.496056061538461 ], [ 29.75383448275862, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4165, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.496056061538461 ], [ 29.754373448275864, -1.497133969230769 ], [ 29.754642931034482, -1.497133969230769 ], [ 29.754642931034482, -1.496595015384615 ], [ 29.754912413793104, -1.496595015384615 ], [ 29.754912413793104, -1.496325538461538 ], [ 29.754642931034482, -1.496325538461538 ], [ 29.754642931034482, -1.496056061538461 ], [ 29.754373448275864, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4166, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.496056061538461 ], [ 29.755720862068966, -1.496595015384615 ], [ 29.755990344827588, -1.496595015384615 ], [ 29.755990344827588, -1.496056061538461 ], [ 29.755720862068966, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4167, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.496056061538461 ], [ 29.760841034482759, -1.496864492307692 ], [ 29.761110517241381, -1.496864492307692 ], [ 29.761110517241381, -1.496056061538461 ], [ 29.760841034482759, -1.496056061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4168, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.496325538461538 ], [ 29.717993275862067, -1.496595015384615 ], [ 29.718262758620689, -1.496595015384615 ], [ 29.718262758620689, -1.496325538461538 ], [ 29.717993275862067, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4169, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.496325538461538 ], [ 29.718262758620689, -1.496595015384615 ], [ 29.718532241379311, -1.496595015384615 ], [ 29.718532241379311, -1.496325538461538 ], [ 29.718262758620689, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4170, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.496325538461538 ], [ 29.718532241379311, -1.496595015384615 ], [ 29.719071206896551, -1.496595015384615 ], [ 29.719071206896551, -1.496325538461538 ], [ 29.718532241379311, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4171, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.496325538461538 ], [ 29.719071206896551, -1.496595015384615 ], [ 29.719340689655173, -1.496595015384615 ], [ 29.719340689655173, -1.496325538461538 ], [ 29.719071206896551, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4172, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.496325538461538 ], [ 29.719340689655173, -1.496595015384615 ], [ 29.719610172413795, -1.496595015384615 ], [ 29.719610172413795, -1.496325538461538 ], [ 29.719340689655173, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4173, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.496325538461538 ], [ 29.719610172413795, -1.496595015384615 ], [ 29.719879655172413, -1.496595015384615 ], [ 29.719879655172413, -1.496325538461538 ], [ 29.719610172413795, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4174, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.496325538461538 ], [ 29.719879655172413, -1.496595015384615 ], [ 29.720149137931035, -1.496595015384615 ], [ 29.720149137931035, -1.496325538461538 ], [ 29.719879655172413, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4175, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.496325538461538 ], [ 29.720149137931035, -1.496595015384615 ], [ 29.720688103448275, -1.496595015384615 ], [ 29.720688103448275, -1.496325538461538 ], [ 29.720149137931035, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4176, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.496325538461538 ], [ 29.720688103448275, -1.496595015384615 ], [ 29.720957586206897, -1.496595015384615 ], [ 29.720957586206897, -1.496325538461538 ], [ 29.720688103448275, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4177, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.496325538461538 ], [ 29.720957586206897, -1.496595015384615 ], [ 29.721227068965518, -1.496595015384615 ], [ 29.721227068965518, -1.496325538461538 ], [ 29.720957586206897, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4178, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.496325538461538 ], [ 29.721227068965518, -1.496595015384615 ], [ 29.721496551724137, -1.496595015384615 ], [ 29.721496551724137, -1.496325538461538 ], [ 29.721227068965518, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4179, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.496325538461538 ], [ 29.721496551724137, -1.496595015384615 ], [ 29.721766034482759, -1.496595015384615 ], [ 29.721766034482759, -1.496325538461538 ], [ 29.721496551724137, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4180, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.496325538461538 ], [ 29.722305, -1.496864492307692 ], [ 29.72257448275862, -1.496864492307692 ], [ 29.72257448275862, -1.496325538461538 ], [ 29.722305, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4181, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.496325538461538 ], [ 29.723113448275861, -1.496864492307692 ], [ 29.723382931034482, -1.496864492307692 ], [ 29.723382931034482, -1.496325538461538 ], [ 29.723113448275861, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4182, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.496325538461538 ], [ 29.723382931034482, -1.496864492307692 ], [ 29.723652413793104, -1.496864492307692 ], [ 29.723652413793104, -1.496325538461538 ], [ 29.723382931034482, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4183, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.496325538461538 ], [ 29.724191379310344, -1.496864492307692 ], [ 29.724460862068966, -1.496864492307692 ], [ 29.724460862068966, -1.496325538461538 ], [ 29.724191379310344, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4184, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.496325538461538 ], [ 29.725269310344828, -1.497133969230769 ], [ 29.72553879310345, -1.497133969230769 ], [ 29.72553879310345, -1.496325538461538 ], [ 29.725269310344828, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4185, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.496325538461538 ], [ 29.728772586206897, -1.496864492307692 ], [ 29.729042068965516, -1.496864492307692 ], [ 29.729042068965516, -1.496325538461538 ], [ 29.728772586206897, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4186, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.496325538461538 ], [ 29.729850517241381, -1.496595015384615 ], [ 29.73012, -1.496595015384615 ], [ 29.73012, -1.496325538461538 ], [ 29.729850517241381, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4187, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.496325538461538 ], [ 29.73012, -1.496595015384615 ], [ 29.730389482758621, -1.496595015384615 ], [ 29.730389482758621, -1.496325538461538 ], [ 29.73012, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4188, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.496325538461538 ], [ 29.734701206896553, -1.496595015384615 ], [ 29.734970689655171, -1.496595015384615 ], [ 29.734970689655171, -1.496325538461538 ], [ 29.734701206896553, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4189, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.496325538461538 ], [ 29.734970689655171, -1.496595015384615 ], [ 29.735240172413793, -1.496595015384615 ], [ 29.735240172413793, -1.496325538461538 ], [ 29.734970689655171, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4190, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.496325538461538 ], [ 29.735240172413793, -1.496595015384615 ], [ 29.735509655172415, -1.496595015384615 ], [ 29.735509655172415, -1.496325538461538 ], [ 29.735240172413793, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4191, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.496325538461538 ], [ 29.735509655172415, -1.496595015384615 ], [ 29.735779137931036, -1.496595015384615 ], [ 29.735779137931036, -1.496325538461538 ], [ 29.735509655172415, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4192, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.496325538461538 ], [ 29.735779137931036, -1.496595015384615 ], [ 29.736048620689655, -1.496595015384615 ], [ 29.736048620689655, -1.496325538461538 ], [ 29.735779137931036, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4193, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.496325538461538 ], [ 29.736048620689655, -1.496595015384615 ], [ 29.736318103448276, -1.496595015384615 ], [ 29.736318103448276, -1.496325538461538 ], [ 29.736048620689655, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4194, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.496325538461538 ], [ 29.736318103448276, -1.496595015384615 ], [ 29.736587586206898, -1.496595015384615 ], [ 29.736587586206898, -1.497133969230769 ], [ 29.736857068965517, -1.497133969230769 ], [ 29.736857068965517, -1.496325538461538 ], [ 29.736318103448276, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4195, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.496325538461538 ], [ 29.736857068965517, -1.496864492307692 ], [ 29.737126551724138, -1.496864492307692 ], [ 29.737126551724138, -1.496325538461538 ], [ 29.736857068965517, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4196, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.496325538461538 ], [ 29.737126551724138, -1.496595015384615 ], [ 29.73739603448276, -1.496595015384615 ], [ 29.73739603448276, -1.496325538461538 ], [ 29.737126551724138, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4197, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.496325538461538 ], [ 29.73739603448276, -1.496595015384615 ], [ 29.737665517241378, -1.496595015384615 ], [ 29.737665517241378, -1.496325538461538 ], [ 29.73739603448276, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4198, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.496325538461538 ], [ 29.737665517241378, -1.496595015384615 ], [ 29.737935, -1.496595015384615 ], [ 29.737935, -1.496325538461538 ], [ 29.737665517241378, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4199, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.496325538461538 ], [ 29.737935, -1.496595015384615 ], [ 29.738204482758622, -1.496595015384615 ], [ 29.738204482758622, -1.496325538461538 ], [ 29.737935, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4200, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.496325538461538 ], [ 29.738204482758622, -1.496595015384615 ], [ 29.738743448275862, -1.496595015384615 ], [ 29.738743448275862, -1.496325538461538 ], [ 29.738204482758622, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4201, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.496325538461538 ], [ 29.738743448275862, -1.496595015384615 ], [ 29.739012931034484, -1.496595015384615 ], [ 29.739012931034484, -1.496325538461538 ], [ 29.738743448275862, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4202, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.496325538461538 ], [ 29.739282413793102, -1.496864492307692 ], [ 29.739551896551724, -1.496864492307692 ], [ 29.739551896551724, -1.496325538461538 ], [ 29.739282413793102, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4203, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.496325538461538 ], [ 29.739551896551724, -1.496864492307692 ], [ 29.739821379310346, -1.496864492307692 ], [ 29.739821379310346, -1.496325538461538 ], [ 29.739551896551724, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4204, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.496325538461538 ], [ 29.739821379310346, -1.496595015384615 ], [ 29.740090862068964, -1.496595015384615 ], [ 29.740090862068964, -1.496325538461538 ], [ 29.739821379310346, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4205, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.496325538461538 ], [ 29.754912413793104, -1.496595015384615 ], [ 29.755181896551726, -1.496595015384615 ], [ 29.755181896551726, -1.496325538461538 ], [ 29.754912413793104, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4206, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.496325538461538 ], [ 29.755181896551726, -1.496595015384615 ], [ 29.755451379310344, -1.496595015384615 ], [ 29.755451379310344, -1.496325538461538 ], [ 29.755181896551726, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4207, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.496325538461538 ], [ 29.755451379310344, -1.496864492307692 ], [ 29.755720862068966, -1.496864492307692 ], [ 29.755720862068966, -1.496325538461538 ], [ 29.755451379310344, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4208, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.496325538461538 ], [ 29.755990344827588, -1.496595015384615 ], [ 29.756259827586206, -1.496595015384615 ], [ 29.756259827586206, -1.496325538461538 ], [ 29.755990344827588, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4209, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.496325538461538 ], [ 29.756259827586206, -1.496595015384615 ], [ 29.756798793103449, -1.496595015384615 ], [ 29.756798793103449, -1.496325538461538 ], [ 29.756259827586206, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4210, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.496325538461538 ], [ 29.756798793103449, -1.496595015384615 ], [ 29.757068275862068, -1.496595015384615 ], [ 29.757068275862068, -1.496325538461538 ], [ 29.756798793103449, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4211, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.496325538461538 ], [ 29.757068275862068, -1.496595015384615 ], [ 29.75733775862069, -1.496595015384615 ], [ 29.75733775862069, -1.496325538461538 ], [ 29.757068275862068, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4212, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.496325538461538 ], [ 29.75733775862069, -1.496595015384615 ], [ 29.757607241379311, -1.496595015384615 ], [ 29.757607241379311, -1.496325538461538 ], [ 29.75733775862069, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4213, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.496325538461538 ], [ 29.757607241379311, -1.496595015384615 ], [ 29.757876724137933, -1.496595015384615 ], [ 29.757876724137933, -1.496325538461538 ], [ 29.757607241379311, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4214, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.496325538461538 ], [ 29.757876724137933, -1.496595015384615 ], [ 29.758146206896551, -1.496595015384615 ], [ 29.758146206896551, -1.496325538461538 ], [ 29.757876724137933, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4215, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.496325538461538 ], [ 29.758146206896551, -1.496595015384615 ], [ 29.758415689655173, -1.496595015384615 ], [ 29.758415689655173, -1.496325538461538 ], [ 29.758146206896551, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4216, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.496325538461538 ], [ 29.758415689655173, -1.496595015384615 ], [ 29.758685172413795, -1.496595015384615 ], [ 29.758685172413795, -1.496325538461538 ], [ 29.758415689655173, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4217, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.496325538461538 ], [ 29.758685172413795, -1.496595015384615 ], [ 29.758954655172413, -1.496595015384615 ], [ 29.758954655172413, -1.496325538461538 ], [ 29.758685172413795, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4218, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.496325538461538 ], [ 29.758954655172413, -1.496595015384615 ], [ 29.759224137931035, -1.496595015384615 ], [ 29.759224137931035, -1.496325538461538 ], [ 29.758954655172413, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4219, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.496325538461538 ], [ 29.759224137931035, -1.496595015384615 ], [ 29.759493620689657, -1.496595015384615 ], [ 29.759493620689657, -1.496325538461538 ], [ 29.759224137931035, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4220, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.496325538461538 ], [ 29.759493620689657, -1.496595015384615 ], [ 29.759763103448275, -1.496595015384615 ], [ 29.759763103448275, -1.496325538461538 ], [ 29.759493620689657, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4221, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.496325538461538 ], [ 29.759763103448275, -1.496595015384615 ], [ 29.760032586206897, -1.496595015384615 ], [ 29.760032586206897, -1.496325538461538 ], [ 29.759763103448275, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4222, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.496325538461538 ], [ 29.760032586206897, -1.496595015384615 ], [ 29.760302068965519, -1.496595015384615 ], [ 29.760302068965519, -1.497133969230769 ], [ 29.760571551724137, -1.497133969230769 ], [ 29.760571551724137, -1.496325538461538 ], [ 29.760032586206897, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4223, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.496325538461538 ], [ 29.760571551724137, -1.497133969230769 ], [ 29.760841034482759, -1.497133969230769 ], [ 29.760841034482759, -1.496325538461538 ], [ 29.760571551724137, -1.496325538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4224, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.496595015384615 ], [ 29.717723793103449, -1.496864492307692 ], [ 29.717993275862067, -1.496864492307692 ], [ 29.717993275862067, -1.496595015384615 ], [ 29.717723793103449, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4225, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.496595015384615 ], [ 29.717993275862067, -1.496864492307692 ], [ 29.718262758620689, -1.496864492307692 ], [ 29.718262758620689, -1.496595015384615 ], [ 29.717993275862067, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4226, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.496595015384615 ], [ 29.718262758620689, -1.496864492307692 ], [ 29.718532241379311, -1.496864492307692 ], [ 29.718532241379311, -1.496595015384615 ], [ 29.718262758620689, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4227, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.496595015384615 ], [ 29.718532241379311, -1.496864492307692 ], [ 29.719071206896551, -1.496864492307692 ], [ 29.719071206896551, -1.496595015384615 ], [ 29.718532241379311, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4228, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.496595015384615 ], [ 29.719071206896551, -1.496864492307692 ], [ 29.719340689655173, -1.496864492307692 ], [ 29.719340689655173, -1.496595015384615 ], [ 29.719071206896551, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4229, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.496595015384615 ], [ 29.719340689655173, -1.496864492307692 ], [ 29.719610172413795, -1.496864492307692 ], [ 29.719610172413795, -1.496595015384615 ], [ 29.719340689655173, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4230, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.496595015384615 ], [ 29.719610172413795, -1.496864492307692 ], [ 29.719879655172413, -1.496864492307692 ], [ 29.719879655172413, -1.496595015384615 ], [ 29.719610172413795, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4231, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.496595015384615 ], [ 29.719879655172413, -1.496864492307692 ], [ 29.720149137931035, -1.496864492307692 ], [ 29.720149137931035, -1.496595015384615 ], [ 29.719879655172413, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4232, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.496595015384615 ], [ 29.720149137931035, -1.496864492307692 ], [ 29.720688103448275, -1.496864492307692 ], [ 29.720688103448275, -1.496595015384615 ], [ 29.720149137931035, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4233, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.496595015384615 ], [ 29.720688103448275, -1.496864492307692 ], [ 29.720957586206897, -1.496864492307692 ], [ 29.720957586206897, -1.496595015384615 ], [ 29.720688103448275, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4234, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.496595015384615 ], [ 29.720957586206897, -1.496864492307692 ], [ 29.721227068965518, -1.496864492307692 ], [ 29.721227068965518, -1.496595015384615 ], [ 29.720957586206897, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4235, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.496595015384615 ], [ 29.721227068965518, -1.496864492307692 ], [ 29.721496551724137, -1.496864492307692 ], [ 29.721496551724137, -1.496595015384615 ], [ 29.721227068965518, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4236, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.496595015384615 ], [ 29.721496551724137, -1.496864492307692 ], [ 29.721766034482759, -1.496864492307692 ], [ 29.721766034482759, -1.496595015384615 ], [ 29.721496551724137, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4237, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.496595015384615 ], [ 29.721766034482759, -1.496864492307692 ], [ 29.72203551724138, -1.496864492307692 ], [ 29.72203551724138, -1.496595015384615 ], [ 29.721766034482759, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4238, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.496595015384615 ], [ 29.72203551724138, -1.496864492307692 ], [ 29.722305, -1.496864492307692 ], [ 29.722305, -1.496595015384615 ], [ 29.72203551724138, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4239, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.496595015384615 ], [ 29.72257448275862, -1.497133969230769 ], [ 29.722843965517242, -1.497133969230769 ], [ 29.722843965517242, -1.496595015384615 ], [ 29.72257448275862, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4240, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.496595015384615 ], [ 29.722843965517242, -1.496864492307692 ], [ 29.723113448275861, -1.496864492307692 ], [ 29.723113448275861, -1.496595015384615 ], [ 29.722843965517242, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4241, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.496595015384615 ], [ 29.723652413793104, -1.497133969230769 ], [ 29.723921896551722, -1.497133969230769 ], [ 29.723921896551722, -1.496595015384615 ], [ 29.723652413793104, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4242, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.496595015384615 ], [ 29.723921896551722, -1.497133969230769 ], [ 29.724191379310344, -1.497133969230769 ], [ 29.724191379310344, -1.496595015384615 ], [ 29.723921896551722, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4243, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.496595015384615 ], [ 29.724460862068966, -1.497403446153846 ], [ 29.724730344827588, -1.497403446153846 ], [ 29.724730344827588, -1.496595015384615 ], [ 29.724460862068966, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4244, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.496595015384615 ], [ 29.726347241379312, -1.497133969230769 ], [ 29.72661672413793, -1.497133969230769 ], [ 29.72661672413793, -1.496595015384615 ], [ 29.726347241379312, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4245, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.496595015384615 ], [ 29.727155689655174, -1.497133969230769 ], [ 29.727425172413792, -1.497133969230769 ], [ 29.727425172413792, -1.496595015384615 ], [ 29.727155689655174, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4246, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.496595015384615 ], [ 29.727964137931036, -1.496864492307692 ], [ 29.728233620689654, -1.496864492307692 ], [ 29.728233620689654, -1.496595015384615 ], [ 29.727964137931036, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4247, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.496595015384615 ], [ 29.728233620689654, -1.496864492307692 ], [ 29.728503103448276, -1.496864492307692 ], [ 29.728503103448276, -1.496595015384615 ], [ 29.728233620689654, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4248, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.496595015384615 ], [ 29.728503103448276, -1.496864492307692 ], [ 29.728772586206897, -1.496864492307692 ], [ 29.728772586206897, -1.496595015384615 ], [ 29.728503103448276, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4249, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.496595015384615 ], [ 29.729042068965516, -1.496864492307692 ], [ 29.729311551724138, -1.496864492307692 ], [ 29.729311551724138, -1.496595015384615 ], [ 29.729042068965516, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4250, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.496595015384615 ], [ 29.729311551724138, -1.496864492307692 ], [ 29.729850517241381, -1.496864492307692 ], [ 29.729850517241381, -1.496595015384615 ], [ 29.729311551724138, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4251, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.496595015384615 ], [ 29.729850517241381, -1.496864492307692 ], [ 29.73012, -1.496864492307692 ], [ 29.73012, -1.496595015384615 ], [ 29.729850517241381, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4252, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.496595015384615 ], [ 29.73012, -1.496864492307692 ], [ 29.730389482758621, -1.496864492307692 ], [ 29.730389482758621, -1.496595015384615 ], [ 29.73012, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4253, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.496595015384615 ], [ 29.730389482758621, -1.497133969230769 ], [ 29.730658965517243, -1.497133969230769 ], [ 29.730658965517243, -1.496595015384615 ], [ 29.730389482758621, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4254, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.496595015384615 ], [ 29.732545344827585, -1.497133969230769 ], [ 29.732814827586207, -1.497133969230769 ], [ 29.732814827586207, -1.496595015384615 ], [ 29.732545344827585, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4255, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.496595015384615 ], [ 29.732814827586207, -1.497133969230769 ], [ 29.733084310344829, -1.497133969230769 ], [ 29.733084310344829, -1.496595015384615 ], [ 29.732814827586207, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4256, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.496595015384615 ], [ 29.733623275862069, -1.496864492307692 ], [ 29.733892758620691, -1.496864492307692 ], [ 29.733892758620691, -1.496595015384615 ], [ 29.733623275862069, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4257, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.496595015384615 ], [ 29.734162241379309, -1.496864492307692 ], [ 29.734431724137931, -1.496864492307692 ], [ 29.734431724137931, -1.496595015384615 ], [ 29.734162241379309, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4258, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.496595015384615 ], [ 29.734431724137931, -1.496864492307692 ], [ 29.734701206896553, -1.496864492307692 ], [ 29.734701206896553, -1.496595015384615 ], [ 29.734431724137931, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4259, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.496595015384615 ], [ 29.734701206896553, -1.496864492307692 ], [ 29.734970689655171, -1.496864492307692 ], [ 29.734970689655171, -1.496595015384615 ], [ 29.734701206896553, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4260, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.496595015384615 ], [ 29.734970689655171, -1.496864492307692 ], [ 29.735240172413793, -1.496864492307692 ], [ 29.735240172413793, -1.496595015384615 ], [ 29.734970689655171, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4261, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.496595015384615 ], [ 29.735240172413793, -1.496864492307692 ], [ 29.735509655172415, -1.496864492307692 ], [ 29.735509655172415, -1.496595015384615 ], [ 29.735240172413793, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4262, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.496595015384615 ], [ 29.735509655172415, -1.496864492307692 ], [ 29.735779137931036, -1.496864492307692 ], [ 29.735779137931036, -1.496595015384615 ], [ 29.735509655172415, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4263, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.496595015384615 ], [ 29.735779137931036, -1.496864492307692 ], [ 29.736048620689655, -1.496864492307692 ], [ 29.736048620689655, -1.496595015384615 ], [ 29.735779137931036, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4264, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.496595015384615 ], [ 29.736048620689655, -1.496864492307692 ], [ 29.736318103448276, -1.496864492307692 ], [ 29.736318103448276, -1.496595015384615 ], [ 29.736048620689655, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4265, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.496595015384615 ], [ 29.736318103448276, -1.496864492307692 ], [ 29.736587586206898, -1.496864492307692 ], [ 29.736587586206898, -1.496595015384615 ], [ 29.736318103448276, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4266, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.496595015384615 ], [ 29.737126551724138, -1.4979424 ], [ 29.73739603448276, -1.4979424 ], [ 29.73739603448276, -1.496595015384615 ], [ 29.737126551724138, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4267, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.496595015384615 ], [ 29.73739603448276, -1.4979424 ], [ 29.737665517241378, -1.4979424 ], [ 29.737665517241378, -1.496595015384615 ], [ 29.73739603448276, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4268, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.496595015384615 ], [ 29.737665517241378, -1.496864492307692 ], [ 29.737935, -1.496864492307692 ], [ 29.737935, -1.496595015384615 ], [ 29.737665517241378, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4269, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.496595015384615 ], [ 29.737935, -1.496864492307692 ], [ 29.738204482758622, -1.496864492307692 ], [ 29.738204482758622, -1.496595015384615 ], [ 29.737935, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4270, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.496595015384615 ], [ 29.738204482758622, -1.496864492307692 ], [ 29.738743448275862, -1.496864492307692 ], [ 29.738743448275862, -1.496595015384615 ], [ 29.738204482758622, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4271, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.496595015384615 ], [ 29.738743448275862, -1.497133969230769 ], [ 29.739012931034484, -1.497133969230769 ], [ 29.739012931034484, -1.496595015384615 ], [ 29.738743448275862, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4272, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.496595015384615 ], [ 29.739012931034484, -1.496864492307692 ], [ 29.739282413793102, -1.496864492307692 ], [ 29.739282413793102, -1.496595015384615 ], [ 29.739012931034484, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4273, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.496595015384615 ], [ 29.739821379310346, -1.497133969230769 ], [ 29.740090862068964, -1.497133969230769 ], [ 29.740090862068964, -1.496595015384615 ], [ 29.739821379310346, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4274, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.496595015384615 ], [ 29.74925327586207, -1.496864492307692 ], [ 29.749522758620689, -1.496864492307692 ], [ 29.749522758620689, -1.496595015384615 ], [ 29.74925327586207, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4275, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.496595015384615 ], [ 29.749522758620689, -1.496864492307692 ], [ 29.749792241379311, -1.496864492307692 ], [ 29.749792241379311, -1.496595015384615 ], [ 29.749522758620689, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4276, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.496595015384615 ], [ 29.750331206896551, -1.497133969230769 ], [ 29.750600689655172, -1.497133969230769 ], [ 29.750600689655172, -1.496595015384615 ], [ 29.750331206896551, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4277, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.496595015384615 ], [ 29.75383448275862, -1.497403446153846 ], [ 29.754103965517242, -1.497403446153846 ], [ 29.754103965517242, -1.496595015384615 ], [ 29.75383448275862, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4278, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.496595015384615 ], [ 29.754642931034482, -1.496864492307692 ], [ 29.754912413793104, -1.496864492307692 ], [ 29.754912413793104, -1.496595015384615 ], [ 29.754642931034482, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4279, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.496595015384615 ], [ 29.754912413793104, -1.496864492307692 ], [ 29.755181896551726, -1.496864492307692 ], [ 29.755181896551726, -1.496595015384615 ], [ 29.754912413793104, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4280, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.496595015384615 ], [ 29.755181896551726, -1.496864492307692 ], [ 29.755451379310344, -1.496864492307692 ], [ 29.755451379310344, -1.496595015384615 ], [ 29.755181896551726, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4281, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.496595015384615 ], [ 29.755720862068966, -1.496864492307692 ], [ 29.755990344827588, -1.496864492307692 ], [ 29.755990344827588, -1.496595015384615 ], [ 29.755720862068966, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4282, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.496595015384615 ], [ 29.755990344827588, -1.496864492307692 ], [ 29.756259827586206, -1.496864492307692 ], [ 29.756259827586206, -1.496595015384615 ], [ 29.755990344827588, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4283, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.496595015384615 ], [ 29.756259827586206, -1.496864492307692 ], [ 29.756798793103449, -1.496864492307692 ], [ 29.756798793103449, -1.496595015384615 ], [ 29.756259827586206, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4284, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.496595015384615 ], [ 29.756798793103449, -1.496864492307692 ], [ 29.757068275862068, -1.496864492307692 ], [ 29.757068275862068, -1.496595015384615 ], [ 29.756798793103449, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4285, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.496595015384615 ], [ 29.757068275862068, -1.496864492307692 ], [ 29.75733775862069, -1.496864492307692 ], [ 29.75733775862069, -1.496595015384615 ], [ 29.757068275862068, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4286, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.496595015384615 ], [ 29.75733775862069, -1.496864492307692 ], [ 29.757607241379311, -1.496864492307692 ], [ 29.757607241379311, -1.496595015384615 ], [ 29.75733775862069, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4287, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.496595015384615 ], [ 29.757607241379311, -1.496864492307692 ], [ 29.757876724137933, -1.496864492307692 ], [ 29.757876724137933, -1.496595015384615 ], [ 29.757607241379311, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4288, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.496595015384615 ], [ 29.757876724137933, -1.496864492307692 ], [ 29.758146206896551, -1.496864492307692 ], [ 29.758146206896551, -1.496595015384615 ], [ 29.757876724137933, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4289, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.496595015384615 ], [ 29.758146206896551, -1.496864492307692 ], [ 29.758415689655173, -1.496864492307692 ], [ 29.758415689655173, -1.496595015384615 ], [ 29.758146206896551, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4290, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.496595015384615 ], [ 29.758415689655173, -1.496864492307692 ], [ 29.758685172413795, -1.496864492307692 ], [ 29.758685172413795, -1.496595015384615 ], [ 29.758415689655173, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4291, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.496595015384615 ], [ 29.758685172413795, -1.496864492307692 ], [ 29.758954655172413, -1.496864492307692 ], [ 29.758954655172413, -1.496595015384615 ], [ 29.758685172413795, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4292, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.496595015384615 ], [ 29.758954655172413, -1.496864492307692 ], [ 29.759224137931035, -1.496864492307692 ], [ 29.759224137931035, -1.496595015384615 ], [ 29.758954655172413, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4293, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.496595015384615 ], [ 29.759224137931035, -1.496864492307692 ], [ 29.759493620689657, -1.496864492307692 ], [ 29.759493620689657, -1.496595015384615 ], [ 29.759224137931035, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4294, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.496595015384615 ], [ 29.759493620689657, -1.496864492307692 ], [ 29.759763103448275, -1.496864492307692 ], [ 29.759763103448275, -1.496595015384615 ], [ 29.759493620689657, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4295, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.496595015384615 ], [ 29.759763103448275, -1.496864492307692 ], [ 29.760032586206897, -1.496864492307692 ], [ 29.760032586206897, -1.496595015384615 ], [ 29.759763103448275, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4296, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.496595015384615 ], [ 29.760032586206897, -1.496864492307692 ], [ 29.760302068965519, -1.496864492307692 ], [ 29.760302068965519, -1.496595015384615 ], [ 29.760032586206897, -1.496595015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4297, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.496864492307692 ], [ 29.717454310344827, -1.497133969230769 ], [ 29.717723793103449, -1.497133969230769 ], [ 29.717723793103449, -1.496864492307692 ], [ 29.717454310344827, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4298, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.496864492307692 ], [ 29.717723793103449, -1.497133969230769 ], [ 29.717993275862067, -1.497133969230769 ], [ 29.717993275862067, -1.496864492307692 ], [ 29.717723793103449, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4299, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.496864492307692 ], [ 29.717993275862067, -1.497133969230769 ], [ 29.718262758620689, -1.497133969230769 ], [ 29.718262758620689, -1.496864492307692 ], [ 29.717993275862067, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4300, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.496864492307692 ], [ 29.718262758620689, -1.497133969230769 ], [ 29.718532241379311, -1.497133969230769 ], [ 29.718532241379311, -1.496864492307692 ], [ 29.718262758620689, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4301, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.496864492307692 ], [ 29.718532241379311, -1.497133969230769 ], [ 29.718801724137933, -1.497133969230769 ], [ 29.718801724137933, -1.496864492307692 ], [ 29.718532241379311, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4302, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.496864492307692 ], [ 29.718801724137933, -1.497133969230769 ], [ 29.719340689655173, -1.497133969230769 ], [ 29.719340689655173, -1.496864492307692 ], [ 29.718801724137933, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4303, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.496864492307692 ], [ 29.719340689655173, -1.497133969230769 ], [ 29.719610172413795, -1.497133969230769 ], [ 29.719610172413795, -1.496864492307692 ], [ 29.719340689655173, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4304, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.496864492307692 ], [ 29.719610172413795, -1.497133969230769 ], [ 29.719879655172413, -1.497133969230769 ], [ 29.719879655172413, -1.496864492307692 ], [ 29.719610172413795, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4305, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.496864492307692 ], [ 29.719879655172413, -1.497133969230769 ], [ 29.720149137931035, -1.497133969230769 ], [ 29.720149137931035, -1.496864492307692 ], [ 29.719879655172413, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4306, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.496864492307692 ], [ 29.720149137931035, -1.497133969230769 ], [ 29.720688103448275, -1.497133969230769 ], [ 29.720688103448275, -1.496864492307692 ], [ 29.720149137931035, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4307, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.496864492307692 ], [ 29.720688103448275, -1.497133969230769 ], [ 29.720957586206897, -1.497133969230769 ], [ 29.720957586206897, -1.496864492307692 ], [ 29.720688103448275, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4308, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.496864492307692 ], [ 29.720957586206897, -1.497133969230769 ], [ 29.721227068965518, -1.497133969230769 ], [ 29.721227068965518, -1.496864492307692 ], [ 29.720957586206897, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4309, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.496864492307692 ], [ 29.721227068965518, -1.497133969230769 ], [ 29.721496551724137, -1.497133969230769 ], [ 29.721496551724137, -1.496864492307692 ], [ 29.721227068965518, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4310, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.496864492307692 ], [ 29.721496551724137, -1.497133969230769 ], [ 29.721766034482759, -1.497133969230769 ], [ 29.721766034482759, -1.496864492307692 ], [ 29.721496551724137, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4311, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.496864492307692 ], [ 29.721766034482759, -1.497133969230769 ], [ 29.72203551724138, -1.497133969230769 ], [ 29.72203551724138, -1.496864492307692 ], [ 29.721766034482759, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4312, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.496864492307692 ], [ 29.72203551724138, -1.497133969230769 ], [ 29.722305, -1.497133969230769 ], [ 29.722305, -1.496864492307692 ], [ 29.72203551724138, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4313, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.496864492307692 ], [ 29.722305, -1.497133969230769 ], [ 29.72257448275862, -1.497133969230769 ], [ 29.72257448275862, -1.496864492307692 ], [ 29.722305, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4314, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.496864492307692 ], [ 29.722843965517242, -1.497403446153846 ], [ 29.723113448275861, -1.497403446153846 ], [ 29.723113448275861, -1.496864492307692 ], [ 29.722843965517242, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4315, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.496864492307692 ], [ 29.723113448275861, -1.497133969230769 ], [ 29.723382931034482, -1.497133969230769 ], [ 29.723382931034482, -1.496864492307692 ], [ 29.723113448275861, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4316, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.496864492307692 ], [ 29.723382931034482, -1.497133969230769 ], [ 29.723652413793104, -1.497133969230769 ], [ 29.723652413793104, -1.496864492307692 ], [ 29.723382931034482, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4317, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.496864492307692 ], [ 29.724191379310344, -1.497403446153846 ], [ 29.724460862068966, -1.497403446153846 ], [ 29.724460862068966, -1.496864492307692 ], [ 29.724191379310344, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4318, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.496864492307692 ], [ 29.724730344827588, -1.497403446153846 ], [ 29.724999827586206, -1.497403446153846 ], [ 29.724999827586206, -1.496864492307692 ], [ 29.724730344827588, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4319, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.496864492307692 ], [ 29.72553879310345, -1.497403446153846 ], [ 29.725808275862068, -1.497403446153846 ], [ 29.725808275862068, -1.496864492307692 ], [ 29.72553879310345, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4320, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.496864492307692 ], [ 29.725808275862068, -1.497403446153846 ], [ 29.72607775862069, -1.497403446153846 ], [ 29.72607775862069, -1.496864492307692 ], [ 29.725808275862068, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4321, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.496864492307692 ], [ 29.72661672413793, -1.497403446153846 ], [ 29.726886206896552, -1.497403446153846 ], [ 29.726886206896552, -1.496864492307692 ], [ 29.72661672413793, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4322, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.496864492307692 ], [ 29.727425172413792, -1.497133969230769 ], [ 29.727694655172414, -1.497133969230769 ], [ 29.727694655172414, -1.496864492307692 ], [ 29.727425172413792, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4323, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.496864492307692 ], [ 29.727694655172414, -1.497133969230769 ], [ 29.727964137931036, -1.497133969230769 ], [ 29.727964137931036, -1.496864492307692 ], [ 29.727694655172414, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4324, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.496864492307692 ], [ 29.727964137931036, -1.497133969230769 ], [ 29.728233620689654, -1.497133969230769 ], [ 29.728233620689654, -1.496864492307692 ], [ 29.727964137931036, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4325, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.496864492307692 ], [ 29.728233620689654, -1.497133969230769 ], [ 29.728503103448276, -1.497133969230769 ], [ 29.728503103448276, -1.496864492307692 ], [ 29.728233620689654, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4326, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.496864492307692 ], [ 29.728503103448276, -1.497133969230769 ], [ 29.728772586206897, -1.497133969230769 ], [ 29.728772586206897, -1.496864492307692 ], [ 29.728503103448276, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4327, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.496864492307692 ], [ 29.728772586206897, -1.497133969230769 ], [ 29.729042068965516, -1.497133969230769 ], [ 29.729042068965516, -1.496864492307692 ], [ 29.728772586206897, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4328, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.496864492307692 ], [ 29.729042068965516, -1.497133969230769 ], [ 29.729311551724138, -1.497133969230769 ], [ 29.729311551724138, -1.496864492307692 ], [ 29.729042068965516, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4329, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.496864492307692 ], [ 29.729311551724138, -1.497133969230769 ], [ 29.729850517241381, -1.497133969230769 ], [ 29.729850517241381, -1.496864492307692 ], [ 29.729311551724138, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4330, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.496864492307692 ], [ 29.729850517241381, -1.497403446153846 ], [ 29.73012, -1.497403446153846 ], [ 29.73012, -1.496864492307692 ], [ 29.729850517241381, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4331, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.496864492307692 ], [ 29.73012, -1.497403446153846 ], [ 29.730389482758621, -1.497403446153846 ], [ 29.730389482758621, -1.496864492307692 ], [ 29.73012, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4332, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.496864492307692 ], [ 29.733623275862069, -1.497133969230769 ], [ 29.733892758620691, -1.497133969230769 ], [ 29.733892758620691, -1.496864492307692 ], [ 29.733623275862069, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4333, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.496864492307692 ], [ 29.733892758620691, -1.497133969230769 ], [ 29.734431724137931, -1.497133969230769 ], [ 29.734431724137931, -1.496864492307692 ], [ 29.733892758620691, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4334, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.496864492307692 ], [ 29.734431724137931, -1.497133969230769 ], [ 29.734701206896553, -1.497133969230769 ], [ 29.734701206896553, -1.496864492307692 ], [ 29.734431724137931, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4335, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.496864492307692 ], [ 29.734701206896553, -1.497133969230769 ], [ 29.734970689655171, -1.497133969230769 ], [ 29.734970689655171, -1.496864492307692 ], [ 29.734701206896553, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4336, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.496864492307692 ], [ 29.734970689655171, -1.497133969230769 ], [ 29.735240172413793, -1.497133969230769 ], [ 29.735240172413793, -1.496864492307692 ], [ 29.734970689655171, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4337, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.496864492307692 ], [ 29.735240172413793, -1.497133969230769 ], [ 29.735509655172415, -1.497133969230769 ], [ 29.735509655172415, -1.496864492307692 ], [ 29.735240172413793, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4338, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.496864492307692 ], [ 29.735509655172415, -1.497133969230769 ], [ 29.735779137931036, -1.497133969230769 ], [ 29.735779137931036, -1.496864492307692 ], [ 29.735509655172415, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4339, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.496864492307692 ], [ 29.735779137931036, -1.497133969230769 ], [ 29.736048620689655, -1.497133969230769 ], [ 29.736048620689655, -1.496864492307692 ], [ 29.735779137931036, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4340, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.496864492307692 ], [ 29.736048620689655, -1.497133969230769 ], [ 29.736318103448276, -1.497133969230769 ], [ 29.736318103448276, -1.496864492307692 ], [ 29.736048620689655, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4341, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.496864492307692 ], [ 29.736318103448276, -1.497133969230769 ], [ 29.736587586206898, -1.497133969230769 ], [ 29.736587586206898, -1.496864492307692 ], [ 29.736318103448276, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4342, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.496864492307692 ], [ 29.736857068965517, -1.497672923076923 ], [ 29.737126551724138, -1.497672923076923 ], [ 29.737126551724138, -1.496864492307692 ], [ 29.736857068965517, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4343, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.496864492307692 ], [ 29.737665517241378, -1.497672923076923 ], [ 29.737935, -1.497672923076923 ], [ 29.737935, -1.496864492307692 ], [ 29.737665517241378, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4344, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.496864492307692 ], [ 29.737935, -1.497672923076923 ], [ 29.738204482758622, -1.497672923076923 ], [ 29.738204482758622, -1.496864492307692 ], [ 29.737935, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4345, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.496864492307692 ], [ 29.738204482758622, -1.497672923076923 ], [ 29.738743448275862, -1.497672923076923 ], [ 29.738743448275862, -1.496864492307692 ], [ 29.738204482758622, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4346, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.496864492307692 ], [ 29.739012931034484, -1.497672923076923 ], [ 29.739282413793102, -1.497672923076923 ], [ 29.739282413793102, -1.496864492307692 ], [ 29.739012931034484, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4347, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.496864492307692 ], [ 29.739282413793102, -1.497672923076923 ], [ 29.739551896551724, -1.497672923076923 ], [ 29.739551896551724, -1.496864492307692 ], [ 29.739282413793102, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4348, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.496864492307692 ], [ 29.739551896551724, -1.497133969230769 ], [ 29.739821379310346, -1.497133969230769 ], [ 29.739821379310346, -1.496864492307692 ], [ 29.739551896551724, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4349, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.496864492307692 ], [ 29.748983793103449, -1.497133969230769 ], [ 29.74925327586207, -1.497133969230769 ], [ 29.74925327586207, -1.496864492307692 ], [ 29.748983793103449, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4350, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.496864492307692 ], [ 29.74925327586207, -1.497133969230769 ], [ 29.749522758620689, -1.497133969230769 ], [ 29.749522758620689, -1.496864492307692 ], [ 29.74925327586207, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4351, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.496864492307692 ], [ 29.749522758620689, -1.497133969230769 ], [ 29.749792241379311, -1.497133969230769 ], [ 29.749792241379311, -1.496864492307692 ], [ 29.749522758620689, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4352, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.496864492307692 ], [ 29.749792241379311, -1.497133969230769 ], [ 29.750061724137932, -1.497133969230769 ], [ 29.750061724137932, -1.496864492307692 ], [ 29.749792241379311, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4353, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.496864492307692 ], [ 29.750061724137932, -1.497133969230769 ], [ 29.750331206896551, -1.497133969230769 ], [ 29.750331206896551, -1.496864492307692 ], [ 29.750061724137932, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4354, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.496864492307692 ], [ 29.750600689655172, -1.497133969230769 ], [ 29.750870172413794, -1.497133969230769 ], [ 29.750870172413794, -1.496864492307692 ], [ 29.750600689655172, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4355, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.496864492307692 ], [ 29.750870172413794, -1.497133969230769 ], [ 29.751139655172413, -1.497133969230769 ], [ 29.751139655172413, -1.496864492307692 ], [ 29.750870172413794, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4356, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.496864492307692 ], [ 29.751139655172413, -1.497133969230769 ], [ 29.751409137931034, -1.497133969230769 ], [ 29.751409137931034, -1.496864492307692 ], [ 29.751139655172413, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4357, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.496864492307692 ], [ 29.751948103448278, -1.497403446153846 ], [ 29.752217586206896, -1.497403446153846 ], [ 29.752217586206896, -1.496864492307692 ], [ 29.751948103448278, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4358, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.496864492307692 ], [ 29.75275655172414, -1.497403446153846 ], [ 29.753026034482758, -1.497403446153846 ], [ 29.753026034482758, -1.496864492307692 ], [ 29.75275655172414, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4359, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.496864492307692 ], [ 29.754642931034482, -1.497133969230769 ], [ 29.754912413793104, -1.497133969230769 ], [ 29.754912413793104, -1.496864492307692 ], [ 29.754642931034482, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4360, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.496864492307692 ], [ 29.754912413793104, -1.497133969230769 ], [ 29.755181896551726, -1.497133969230769 ], [ 29.755181896551726, -1.496864492307692 ], [ 29.754912413793104, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4361, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.496864492307692 ], [ 29.755181896551726, -1.497133969230769 ], [ 29.755451379310344, -1.497133969230769 ], [ 29.755451379310344, -1.496864492307692 ], [ 29.755181896551726, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4362, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.496864492307692 ], [ 29.755451379310344, -1.497133969230769 ], [ 29.755720862068966, -1.497133969230769 ], [ 29.755720862068966, -1.496864492307692 ], [ 29.755451379310344, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4363, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.496864492307692 ], [ 29.755720862068966, -1.497133969230769 ], [ 29.755990344827588, -1.497133969230769 ], [ 29.755990344827588, -1.496864492307692 ], [ 29.755720862068966, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4364, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.496864492307692 ], [ 29.755990344827588, -1.497133969230769 ], [ 29.756259827586206, -1.497133969230769 ], [ 29.756259827586206, -1.496864492307692 ], [ 29.755990344827588, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4365, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.496864492307692 ], [ 29.756259827586206, -1.497133969230769 ], [ 29.756798793103449, -1.497133969230769 ], [ 29.756798793103449, -1.496864492307692 ], [ 29.756259827586206, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4366, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.496864492307692 ], [ 29.756798793103449, -1.497133969230769 ], [ 29.757068275862068, -1.497133969230769 ], [ 29.757068275862068, -1.496864492307692 ], [ 29.756798793103449, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4367, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.496864492307692 ], [ 29.757068275862068, -1.497133969230769 ], [ 29.75733775862069, -1.497133969230769 ], [ 29.75733775862069, -1.496864492307692 ], [ 29.757068275862068, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4368, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.496864492307692 ], [ 29.75733775862069, -1.497133969230769 ], [ 29.757607241379311, -1.497133969230769 ], [ 29.757607241379311, -1.496864492307692 ], [ 29.75733775862069, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4369, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.496864492307692 ], [ 29.757607241379311, -1.497133969230769 ], [ 29.757876724137933, -1.497133969230769 ], [ 29.757876724137933, -1.496864492307692 ], [ 29.757607241379311, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4370, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.496864492307692 ], [ 29.757876724137933, -1.497133969230769 ], [ 29.758146206896551, -1.497133969230769 ], [ 29.758146206896551, -1.496864492307692 ], [ 29.757876724137933, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4371, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.496864492307692 ], [ 29.758146206896551, -1.497133969230769 ], [ 29.758415689655173, -1.497133969230769 ], [ 29.758415689655173, -1.496864492307692 ], [ 29.758146206896551, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4372, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.496864492307692 ], [ 29.758415689655173, -1.497133969230769 ], [ 29.758685172413795, -1.497133969230769 ], [ 29.758685172413795, -1.496864492307692 ], [ 29.758415689655173, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4373, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.496864492307692 ], [ 29.758685172413795, -1.497133969230769 ], [ 29.758954655172413, -1.497133969230769 ], [ 29.758954655172413, -1.496864492307692 ], [ 29.758685172413795, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4374, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.496864492307692 ], [ 29.758954655172413, -1.497133969230769 ], [ 29.759224137931035, -1.497133969230769 ], [ 29.759224137931035, -1.496864492307692 ], [ 29.758954655172413, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4375, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.496864492307692 ], [ 29.759224137931035, -1.497133969230769 ], [ 29.759493620689657, -1.497133969230769 ], [ 29.759493620689657, -1.496864492307692 ], [ 29.759224137931035, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4376, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.496864492307692 ], [ 29.759493620689657, -1.497133969230769 ], [ 29.759763103448275, -1.497133969230769 ], [ 29.759763103448275, -1.496864492307692 ], [ 29.759493620689657, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4377, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.496864492307692 ], [ 29.759763103448275, -1.497133969230769 ], [ 29.760032586206897, -1.497133969230769 ], [ 29.760032586206897, -1.496864492307692 ], [ 29.759763103448275, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4378, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.496864492307692 ], [ 29.760032586206897, -1.497403446153846 ], [ 29.760302068965519, -1.497403446153846 ], [ 29.760302068965519, -1.496864492307692 ], [ 29.760032586206897, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4379, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.496864492307692 ], [ 29.760841034482759, -1.497133969230769 ], [ 29.761110517241381, -1.497133969230769 ], [ 29.761110517241381, -1.496864492307692 ], [ 29.760841034482759, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4380, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.496864492307692 ], [ 29.761110517241381, -1.497672923076923 ], [ 29.76138, -1.497672923076923 ], [ 29.76138, -1.496864492307692 ], [ 29.761110517241381, -1.496864492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4381, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.497133969230769 ], [ 29.717454310344827, -1.497403446153846 ], [ 29.717723793103449, -1.497403446153846 ], [ 29.717723793103449, -1.497133969230769 ], [ 29.717454310344827, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4382, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.497133969230769 ], [ 29.717723793103449, -1.497403446153846 ], [ 29.717993275862067, -1.497403446153846 ], [ 29.717993275862067, -1.497133969230769 ], [ 29.717723793103449, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4383, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.497133969230769 ], [ 29.717993275862067, -1.497403446153846 ], [ 29.718262758620689, -1.497403446153846 ], [ 29.718262758620689, -1.497133969230769 ], [ 29.717993275862067, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4384, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.497133969230769 ], [ 29.718262758620689, -1.497403446153846 ], [ 29.718532241379311, -1.497403446153846 ], [ 29.718532241379311, -1.497133969230769 ], [ 29.718262758620689, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4385, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.497133969230769 ], [ 29.718532241379311, -1.497403446153846 ], [ 29.718801724137933, -1.497403446153846 ], [ 29.718801724137933, -1.497133969230769 ], [ 29.718532241379311, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4386, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.497133969230769 ], [ 29.718801724137933, -1.497403446153846 ], [ 29.719071206896551, -1.497403446153846 ], [ 29.719071206896551, -1.497133969230769 ], [ 29.718801724137933, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4387, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.497133969230769 ], [ 29.719071206896551, -1.497403446153846 ], [ 29.719340689655173, -1.497403446153846 ], [ 29.719340689655173, -1.497133969230769 ], [ 29.719071206896551, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4388, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.497133969230769 ], [ 29.719340689655173, -1.497403446153846 ], [ 29.719610172413795, -1.497403446153846 ], [ 29.719610172413795, -1.497133969230769 ], [ 29.719340689655173, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4389, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.497133969230769 ], [ 29.719610172413795, -1.497403446153846 ], [ 29.719879655172413, -1.497403446153846 ], [ 29.719879655172413, -1.497133969230769 ], [ 29.719610172413795, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4390, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.497133969230769 ], [ 29.719879655172413, -1.497403446153846 ], [ 29.720149137931035, -1.497403446153846 ], [ 29.720149137931035, -1.497133969230769 ], [ 29.719879655172413, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4391, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.497133969230769 ], [ 29.720149137931035, -1.497403446153846 ], [ 29.720688103448275, -1.497403446153846 ], [ 29.720688103448275, -1.497133969230769 ], [ 29.720149137931035, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4392, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.497133969230769 ], [ 29.720688103448275, -1.497403446153846 ], [ 29.720957586206897, -1.497403446153846 ], [ 29.720957586206897, -1.497133969230769 ], [ 29.720688103448275, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4393, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.497133969230769 ], [ 29.720957586206897, -1.497403446153846 ], [ 29.721227068965518, -1.497403446153846 ], [ 29.721227068965518, -1.497133969230769 ], [ 29.720957586206897, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4394, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.497133969230769 ], [ 29.721227068965518, -1.497403446153846 ], [ 29.721496551724137, -1.497403446153846 ], [ 29.721496551724137, -1.497133969230769 ], [ 29.721227068965518, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4395, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.497133969230769 ], [ 29.721496551724137, -1.497403446153846 ], [ 29.721766034482759, -1.497403446153846 ], [ 29.721766034482759, -1.497133969230769 ], [ 29.721496551724137, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4396, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.497133969230769 ], [ 29.721766034482759, -1.497403446153846 ], [ 29.72203551724138, -1.497403446153846 ], [ 29.72203551724138, -1.497133969230769 ], [ 29.721766034482759, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4397, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.497133969230769 ], [ 29.72203551724138, -1.497403446153846 ], [ 29.722305, -1.497403446153846 ], [ 29.722305, -1.497133969230769 ], [ 29.72203551724138, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4398, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.497133969230769 ], [ 29.722305, -1.497403446153846 ], [ 29.72257448275862, -1.497403446153846 ], [ 29.72257448275862, -1.497133969230769 ], [ 29.722305, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4399, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.497133969230769 ], [ 29.72257448275862, -1.497403446153846 ], [ 29.722843965517242, -1.497403446153846 ], [ 29.722843965517242, -1.497133969230769 ], [ 29.72257448275862, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4400, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.497133969230769 ], [ 29.723113448275861, -1.497672923076923 ], [ 29.723382931034482, -1.497672923076923 ], [ 29.723382931034482, -1.497133969230769 ], [ 29.723113448275861, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4401, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.497133969230769 ], [ 29.723382931034482, -1.497403446153846 ], [ 29.723652413793104, -1.497403446153846 ], [ 29.723652413793104, -1.497133969230769 ], [ 29.723382931034482, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4402, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.497133969230769 ], [ 29.723652413793104, -1.497403446153846 ], [ 29.723921896551722, -1.497403446153846 ], [ 29.723921896551722, -1.497133969230769 ], [ 29.723652413793104, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4403, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.497133969230769 ], [ 29.723921896551722, -1.497672923076923 ], [ 29.724191379310344, -1.497672923076923 ], [ 29.724191379310344, -1.497133969230769 ], [ 29.723921896551722, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4404, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.497133969230769 ], [ 29.724999827586206, -1.497672923076923 ], [ 29.725269310344828, -1.497672923076923 ], [ 29.725269310344828, -1.497133969230769 ], [ 29.724999827586206, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4405, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.497133969230769 ], [ 29.725269310344828, -1.497672923076923 ], [ 29.72553879310345, -1.497672923076923 ], [ 29.72553879310345, -1.497133969230769 ], [ 29.725269310344828, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4406, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.497133969230769 ], [ 29.72607775862069, -1.497403446153846 ], [ 29.726347241379312, -1.497403446153846 ], [ 29.726347241379312, -1.497133969230769 ], [ 29.72607775862069, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4407, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.497133969230769 ], [ 29.726347241379312, -1.497672923076923 ], [ 29.72661672413793, -1.497672923076923 ], [ 29.72661672413793, -1.497133969230769 ], [ 29.726347241379312, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4408, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.497133969230769 ], [ 29.726886206896552, -1.497403446153846 ], [ 29.727155689655174, -1.497403446153846 ], [ 29.727155689655174, -1.497133969230769 ], [ 29.726886206896552, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4409, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.497133969230769 ], [ 29.727155689655174, -1.497403446153846 ], [ 29.727425172413792, -1.497403446153846 ], [ 29.727425172413792, -1.497133969230769 ], [ 29.727155689655174, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4410, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.497133969230769 ], [ 29.727425172413792, -1.497403446153846 ], [ 29.727694655172414, -1.497403446153846 ], [ 29.727694655172414, -1.497133969230769 ], [ 29.727425172413792, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4411, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.497133969230769 ], [ 29.727694655172414, -1.497403446153846 ], [ 29.727964137931036, -1.497403446153846 ], [ 29.727964137931036, -1.497133969230769 ], [ 29.727694655172414, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4412, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.497133969230769 ], [ 29.727964137931036, -1.497403446153846 ], [ 29.728233620689654, -1.497403446153846 ], [ 29.728233620689654, -1.497133969230769 ], [ 29.727964137931036, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4413, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.497133969230769 ], [ 29.728233620689654, -1.497403446153846 ], [ 29.728503103448276, -1.497403446153846 ], [ 29.728503103448276, -1.497133969230769 ], [ 29.728233620689654, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4414, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.497133969230769 ], [ 29.728503103448276, -1.497403446153846 ], [ 29.728772586206897, -1.497403446153846 ], [ 29.728772586206897, -1.497133969230769 ], [ 29.728503103448276, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4415, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.497133969230769 ], [ 29.728772586206897, -1.497403446153846 ], [ 29.729042068965516, -1.497403446153846 ], [ 29.729042068965516, -1.497133969230769 ], [ 29.728772586206897, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4416, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.497133969230769 ], [ 29.729042068965516, -1.497403446153846 ], [ 29.729311551724138, -1.497403446153846 ], [ 29.729311551724138, -1.497133969230769 ], [ 29.729042068965516, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4417, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.497133969230769 ], [ 29.729311551724138, -1.497672923076923 ], [ 29.729850517241381, -1.497672923076923 ], [ 29.729850517241381, -1.497133969230769 ], [ 29.729311551724138, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4418, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.497133969230769 ], [ 29.730389482758621, -1.497403446153846 ], [ 29.730658965517243, -1.497403446153846 ], [ 29.730658965517243, -1.497133969230769 ], [ 29.730389482758621, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4419, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.497133969230769 ], [ 29.730658965517243, -1.497672923076923 ], [ 29.730928448275861, -1.497672923076923 ], [ 29.730928448275861, -1.497133969230769 ], [ 29.730658965517243, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4420, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.497133969230769 ], [ 29.732814827586207, -1.497403446153846 ], [ 29.733084310344829, -1.497403446153846 ], [ 29.733084310344829, -1.497133969230769 ], [ 29.732814827586207, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4421, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.497133969230769 ], [ 29.733084310344829, -1.497403446153846 ], [ 29.733353793103447, -1.497403446153846 ], [ 29.733353793103447, -1.497133969230769 ], [ 29.733084310344829, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4422, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.497133969230769 ], [ 29.733353793103447, -1.497403446153846 ], [ 29.733623275862069, -1.497403446153846 ], [ 29.733623275862069, -1.497133969230769 ], [ 29.733353793103447, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4423, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.497133969230769 ], [ 29.733623275862069, -1.497403446153846 ], [ 29.734162241379309, -1.497403446153846 ], [ 29.734162241379309, -1.497133969230769 ], [ 29.733623275862069, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4424, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.497133969230769 ], [ 29.734162241379309, -1.497403446153846 ], [ 29.734431724137931, -1.497403446153846 ], [ 29.734431724137931, -1.497133969230769 ], [ 29.734162241379309, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4425, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.497133969230769 ], [ 29.734431724137931, -1.497403446153846 ], [ 29.734701206896553, -1.497403446153846 ], [ 29.734701206896553, -1.497133969230769 ], [ 29.734431724137931, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4426, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.497133969230769 ], [ 29.734701206896553, -1.497403446153846 ], [ 29.734970689655171, -1.497403446153846 ], [ 29.734970689655171, -1.497133969230769 ], [ 29.734701206896553, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4427, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.497133969230769 ], [ 29.734970689655171, -1.497403446153846 ], [ 29.735240172413793, -1.497403446153846 ], [ 29.735240172413793, -1.497133969230769 ], [ 29.734970689655171, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4428, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.497133969230769 ], [ 29.735240172413793, -1.497403446153846 ], [ 29.735509655172415, -1.497403446153846 ], [ 29.735509655172415, -1.497133969230769 ], [ 29.735240172413793, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4429, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.497133969230769 ], [ 29.735509655172415, -1.497403446153846 ], [ 29.735779137931036, -1.497403446153846 ], [ 29.735779137931036, -1.497133969230769 ], [ 29.735509655172415, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4430, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.497133969230769 ], [ 29.735779137931036, -1.497403446153846 ], [ 29.736048620689655, -1.497403446153846 ], [ 29.736048620689655, -1.497133969230769 ], [ 29.735779137931036, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4431, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.497133969230769 ], [ 29.736048620689655, -1.497403446153846 ], [ 29.736318103448276, -1.497403446153846 ], [ 29.736318103448276, -1.497133969230769 ], [ 29.736048620689655, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4432, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.497133969230769 ], [ 29.736318103448276, -1.497403446153846 ], [ 29.736587586206898, -1.497403446153846 ], [ 29.736587586206898, -1.497133969230769 ], [ 29.736318103448276, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4433, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.497133969230769 ], [ 29.736587586206898, -1.497403446153846 ], [ 29.736857068965517, -1.497403446153846 ], [ 29.736857068965517, -1.497133969230769 ], [ 29.736587586206898, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4434, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.497133969230769 ], [ 29.738743448275862, -1.497403446153846 ], [ 29.739012931034484, -1.497403446153846 ], [ 29.739012931034484, -1.497133969230769 ], [ 29.738743448275862, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4435, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.497133969230769 ], [ 29.739551896551724, -1.497403446153846 ], [ 29.739821379310346, -1.497403446153846 ], [ 29.739821379310346, -1.497133969230769 ], [ 29.739551896551724, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4436, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.497133969230769 ], [ 29.748714310344827, -1.497403446153846 ], [ 29.748983793103449, -1.497403446153846 ], [ 29.748983793103449, -1.497133969230769 ], [ 29.748714310344827, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4437, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.497133969230769 ], [ 29.748983793103449, -1.497403446153846 ], [ 29.74925327586207, -1.497403446153846 ], [ 29.74925327586207, -1.497133969230769 ], [ 29.748983793103449, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4438, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.497133969230769 ], [ 29.74925327586207, -1.497403446153846 ], [ 29.749522758620689, -1.497403446153846 ], [ 29.749522758620689, -1.497133969230769 ], [ 29.74925327586207, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4439, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.497133969230769 ], [ 29.749522758620689, -1.497403446153846 ], [ 29.749792241379311, -1.497403446153846 ], [ 29.749792241379311, -1.497133969230769 ], [ 29.749522758620689, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4440, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.497133969230769 ], [ 29.749792241379311, -1.497403446153846 ], [ 29.750061724137932, -1.497403446153846 ], [ 29.750061724137932, -1.497133969230769 ], [ 29.749792241379311, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4441, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.497133969230769 ], [ 29.750061724137932, -1.497403446153846 ], [ 29.750331206896551, -1.497403446153846 ], [ 29.750331206896551, -1.497133969230769 ], [ 29.750061724137932, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4442, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.497133969230769 ], [ 29.750331206896551, -1.497403446153846 ], [ 29.750600689655172, -1.497403446153846 ], [ 29.750600689655172, -1.497133969230769 ], [ 29.750331206896551, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4443, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.497133969230769 ], [ 29.750600689655172, -1.497403446153846 ], [ 29.750870172413794, -1.497403446153846 ], [ 29.750870172413794, -1.497133969230769 ], [ 29.750600689655172, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4444, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.497133969230769 ], [ 29.750870172413794, -1.497403446153846 ], [ 29.751139655172413, -1.497403446153846 ], [ 29.751139655172413, -1.497133969230769 ], [ 29.750870172413794, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4445, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.497133969230769 ], [ 29.751139655172413, -1.497403446153846 ], [ 29.751409137931034, -1.497403446153846 ], [ 29.751409137931034, -1.497133969230769 ], [ 29.751139655172413, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4446, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.497133969230769 ], [ 29.751409137931034, -1.497403446153846 ], [ 29.751678620689656, -1.497403446153846 ], [ 29.751678620689656, -1.497133969230769 ], [ 29.751409137931034, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4447, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.497133969230769 ], [ 29.751678620689656, -1.497403446153846 ], [ 29.751948103448278, -1.497403446153846 ], [ 29.751948103448278, -1.497133969230769 ], [ 29.751678620689656, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4448, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.497133969230769 ], [ 29.752487068965518, -1.497672923076923 ], [ 29.75275655172414, -1.497672923076923 ], [ 29.75275655172414, -1.497133969230769 ], [ 29.752487068965518, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4449, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.497133969230769 ], [ 29.75329551724138, -1.497672923076923 ], [ 29.753565, -1.497672923076923 ], [ 29.753565, -1.497133969230769 ], [ 29.75329551724138, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4450, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.497133969230769 ], [ 29.753565, -1.497672923076923 ], [ 29.75383448275862, -1.497672923076923 ], [ 29.75383448275862, -1.497133969230769 ], [ 29.753565, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4451, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.497133969230769 ], [ 29.754373448275864, -1.497672923076923 ], [ 29.754642931034482, -1.497672923076923 ], [ 29.754642931034482, -1.497133969230769 ], [ 29.754373448275864, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4452, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.497133969230769 ], [ 29.754642931034482, -1.497672923076923 ], [ 29.754912413793104, -1.497672923076923 ], [ 29.754912413793104, -1.497133969230769 ], [ 29.754642931034482, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4453, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.497133969230769 ], [ 29.754912413793104, -1.497403446153846 ], [ 29.755181896551726, -1.497403446153846 ], [ 29.755181896551726, -1.497133969230769 ], [ 29.754912413793104, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4454, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.497133969230769 ], [ 29.755181896551726, -1.497403446153846 ], [ 29.755451379310344, -1.497403446153846 ], [ 29.755451379310344, -1.497133969230769 ], [ 29.755181896551726, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4455, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.497133969230769 ], [ 29.755451379310344, -1.497403446153846 ], [ 29.755720862068966, -1.497403446153846 ], [ 29.755720862068966, -1.497133969230769 ], [ 29.755451379310344, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4456, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.497133969230769 ], [ 29.755720862068966, -1.497403446153846 ], [ 29.755990344827588, -1.497403446153846 ], [ 29.755990344827588, -1.497133969230769 ], [ 29.755720862068966, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4457, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.497133969230769 ], [ 29.755990344827588, -1.497403446153846 ], [ 29.756259827586206, -1.497403446153846 ], [ 29.756259827586206, -1.497133969230769 ], [ 29.755990344827588, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4458, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.497133969230769 ], [ 29.756259827586206, -1.497403446153846 ], [ 29.756798793103449, -1.497403446153846 ], [ 29.756798793103449, -1.497133969230769 ], [ 29.756259827586206, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4459, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.497133969230769 ], [ 29.756798793103449, -1.497403446153846 ], [ 29.757068275862068, -1.497403446153846 ], [ 29.757068275862068, -1.497133969230769 ], [ 29.756798793103449, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4460, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.497133969230769 ], [ 29.757068275862068, -1.497403446153846 ], [ 29.75733775862069, -1.497403446153846 ], [ 29.75733775862069, -1.497133969230769 ], [ 29.757068275862068, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4461, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.497133969230769 ], [ 29.75733775862069, -1.497403446153846 ], [ 29.757607241379311, -1.497403446153846 ], [ 29.757607241379311, -1.497133969230769 ], [ 29.75733775862069, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4462, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.497133969230769 ], [ 29.757607241379311, -1.497403446153846 ], [ 29.757876724137933, -1.497403446153846 ], [ 29.757876724137933, -1.497133969230769 ], [ 29.757607241379311, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4463, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.497133969230769 ], [ 29.757876724137933, -1.497403446153846 ], [ 29.758146206896551, -1.497403446153846 ], [ 29.758146206896551, -1.497133969230769 ], [ 29.757876724137933, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4464, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.497133969230769 ], [ 29.758146206896551, -1.497403446153846 ], [ 29.758415689655173, -1.497403446153846 ], [ 29.758415689655173, -1.497133969230769 ], [ 29.758146206896551, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4465, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.497133969230769 ], [ 29.758415689655173, -1.497403446153846 ], [ 29.758685172413795, -1.497403446153846 ], [ 29.758685172413795, -1.497133969230769 ], [ 29.758415689655173, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4466, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.497133969230769 ], [ 29.758685172413795, -1.497403446153846 ], [ 29.758954655172413, -1.497403446153846 ], [ 29.758954655172413, -1.497133969230769 ], [ 29.758685172413795, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4467, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.497133969230769 ], [ 29.758954655172413, -1.497403446153846 ], [ 29.759224137931035, -1.497403446153846 ], [ 29.759224137931035, -1.497133969230769 ], [ 29.758954655172413, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4468, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.497133969230769 ], [ 29.759224137931035, -1.497403446153846 ], [ 29.759493620689657, -1.497403446153846 ], [ 29.759493620689657, -1.497133969230769 ], [ 29.759224137931035, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4469, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.497133969230769 ], [ 29.759493620689657, -1.497403446153846 ], [ 29.759763103448275, -1.497403446153846 ], [ 29.759763103448275, -1.497133969230769 ], [ 29.759493620689657, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4470, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.497133969230769 ], [ 29.759763103448275, -1.497403446153846 ], [ 29.760032586206897, -1.497403446153846 ], [ 29.760032586206897, -1.497133969230769 ], [ 29.759763103448275, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4471, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.497133969230769 ], [ 29.760302068965519, -1.497403446153846 ], [ 29.760571551724137, -1.497403446153846 ], [ 29.760571551724137, -1.497133969230769 ], [ 29.760302068965519, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4472, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.497133969230769 ], [ 29.760571551724137, -1.4979424 ], [ 29.760841034482759, -1.4979424 ], [ 29.760841034482759, -1.497133969230769 ], [ 29.760571551724137, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4473, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.497133969230769 ], [ 29.760841034482759, -1.4979424 ], [ 29.761110517241381, -1.4979424 ], [ 29.761110517241381, -1.497133969230769 ], [ 29.760841034482759, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4474, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.497403446153846 ], [ 29.717454310344827, -1.498481353846154 ], [ 29.717723793103449, -1.498481353846154 ], [ 29.717723793103449, -1.497403446153846 ], [ 29.717454310344827, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4475, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.497403446153846 ], [ 29.717723793103449, -1.498481353846154 ], [ 29.717993275862067, -1.498481353846154 ], [ 29.717993275862067, -1.497403446153846 ], [ 29.717723793103449, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4476, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.497403446153846 ], [ 29.717993275862067, -1.497672923076923 ], [ 29.718262758620689, -1.497672923076923 ], [ 29.718262758620689, -1.497403446153846 ], [ 29.717993275862067, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4477, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.497403446153846 ], [ 29.718262758620689, -1.497672923076923 ], [ 29.718532241379311, -1.497672923076923 ], [ 29.718532241379311, -1.497403446153846 ], [ 29.718262758620689, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4478, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.497403446153846 ], [ 29.718532241379311, -1.497672923076923 ], [ 29.718801724137933, -1.497672923076923 ], [ 29.718801724137933, -1.497403446153846 ], [ 29.718532241379311, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4479, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.497403446153846 ], [ 29.718801724137933, -1.497672923076923 ], [ 29.719071206896551, -1.497672923076923 ], [ 29.719071206896551, -1.497403446153846 ], [ 29.718801724137933, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4480, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.497403446153846 ], [ 29.719071206896551, -1.497672923076923 ], [ 29.719340689655173, -1.497672923076923 ], [ 29.719340689655173, -1.497403446153846 ], [ 29.719071206896551, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4481, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.497403446153846 ], [ 29.719340689655173, -1.497672923076923 ], [ 29.719610172413795, -1.497672923076923 ], [ 29.719610172413795, -1.497403446153846 ], [ 29.719340689655173, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4482, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.497403446153846 ], [ 29.719610172413795, -1.497672923076923 ], [ 29.719879655172413, -1.497672923076923 ], [ 29.719879655172413, -1.497403446153846 ], [ 29.719610172413795, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4483, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.497403446153846 ], [ 29.719879655172413, -1.497672923076923 ], [ 29.720149137931035, -1.497672923076923 ], [ 29.720149137931035, -1.497403446153846 ], [ 29.719879655172413, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4484, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.497403446153846 ], [ 29.720149137931035, -1.497672923076923 ], [ 29.720688103448275, -1.497672923076923 ], [ 29.720688103448275, -1.497403446153846 ], [ 29.720149137931035, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4485, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.497403446153846 ], [ 29.720688103448275, -1.497672923076923 ], [ 29.720957586206897, -1.497672923076923 ], [ 29.720957586206897, -1.497403446153846 ], [ 29.720688103448275, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4486, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.497403446153846 ], [ 29.720957586206897, -1.497672923076923 ], [ 29.721227068965518, -1.497672923076923 ], [ 29.721227068965518, -1.497403446153846 ], [ 29.720957586206897, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4487, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.497403446153846 ], [ 29.721227068965518, -1.497672923076923 ], [ 29.721496551724137, -1.497672923076923 ], [ 29.721496551724137, -1.497403446153846 ], [ 29.721227068965518, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4488, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.497403446153846 ], [ 29.721496551724137, -1.497672923076923 ], [ 29.721766034482759, -1.497672923076923 ], [ 29.721766034482759, -1.497403446153846 ], [ 29.721496551724137, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4489, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.497403446153846 ], [ 29.721766034482759, -1.497672923076923 ], [ 29.72203551724138, -1.497672923076923 ], [ 29.72203551724138, -1.497403446153846 ], [ 29.721766034482759, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4490, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.497403446153846 ], [ 29.72203551724138, -1.497672923076923 ], [ 29.722305, -1.497672923076923 ], [ 29.722305, -1.497403446153846 ], [ 29.72203551724138, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4491, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.497403446153846 ], [ 29.722305, -1.497672923076923 ], [ 29.72257448275862, -1.497672923076923 ], [ 29.72257448275862, -1.497403446153846 ], [ 29.722305, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4492, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.497403446153846 ], [ 29.72257448275862, -1.497672923076923 ], [ 29.722843965517242, -1.497672923076923 ], [ 29.722843965517242, -1.497403446153846 ], [ 29.72257448275862, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4493, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.497403446153846 ], [ 29.722843965517242, -1.497672923076923 ], [ 29.723113448275861, -1.497672923076923 ], [ 29.723113448275861, -1.497403446153846 ], [ 29.722843965517242, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4494, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.497403446153846 ], [ 29.723382931034482, -1.497672923076923 ], [ 29.723652413793104, -1.497672923076923 ], [ 29.723652413793104, -1.497403446153846 ], [ 29.723382931034482, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4495, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.497403446153846 ], [ 29.723652413793104, -1.497672923076923 ], [ 29.723921896551722, -1.497672923076923 ], [ 29.723921896551722, -1.497403446153846 ], [ 29.723652413793104, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4496, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.497403446153846 ], [ 29.724191379310344, -1.497672923076923 ], [ 29.724460862068966, -1.497672923076923 ], [ 29.724460862068966, -1.497403446153846 ], [ 29.724191379310344, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4497, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.497403446153846 ], [ 29.724460862068966, -1.497672923076923 ], [ 29.724999827586206, -1.497672923076923 ], [ 29.724999827586206, -1.497403446153846 ], [ 29.724460862068966, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4498, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.497403446153846 ], [ 29.72553879310345, -1.497672923076923 ], [ 29.725808275862068, -1.497672923076923 ], [ 29.725808275862068, -1.497403446153846 ], [ 29.72553879310345, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4499, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.497403446153846 ], [ 29.725808275862068, -1.497672923076923 ], [ 29.72607775862069, -1.497672923076923 ], [ 29.72607775862069, -1.497403446153846 ], [ 29.725808275862068, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4500, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.497403446153846 ], [ 29.72607775862069, -1.4979424 ], [ 29.726347241379312, -1.4979424 ], [ 29.726347241379312, -1.497403446153846 ], [ 29.72607775862069, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4501, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.497403446153846 ], [ 29.72661672413793, -1.497672923076923 ], [ 29.726886206896552, -1.497672923076923 ], [ 29.726886206896552, -1.497403446153846 ], [ 29.72661672413793, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4502, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.497403446153846 ], [ 29.726886206896552, -1.497672923076923 ], [ 29.727155689655174, -1.497672923076923 ], [ 29.727155689655174, -1.497403446153846 ], [ 29.726886206896552, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4503, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.497403446153846 ], [ 29.727155689655174, -1.497672923076923 ], [ 29.727425172413792, -1.497672923076923 ], [ 29.727425172413792, -1.497403446153846 ], [ 29.727155689655174, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4504, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.497403446153846 ], [ 29.727425172413792, -1.497672923076923 ], [ 29.727694655172414, -1.497672923076923 ], [ 29.727694655172414, -1.497403446153846 ], [ 29.727425172413792, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4505, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.497403446153846 ], [ 29.727694655172414, -1.497672923076923 ], [ 29.727964137931036, -1.497672923076923 ], [ 29.727964137931036, -1.497403446153846 ], [ 29.727694655172414, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4506, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.497403446153846 ], [ 29.727964137931036, -1.497672923076923 ], [ 29.728233620689654, -1.497672923076923 ], [ 29.728233620689654, -1.497403446153846 ], [ 29.727964137931036, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4507, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.497403446153846 ], [ 29.728233620689654, -1.497672923076923 ], [ 29.728503103448276, -1.497672923076923 ], [ 29.728503103448276, -1.497403446153846 ], [ 29.728233620689654, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4508, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.497403446153846 ], [ 29.728503103448276, -1.497672923076923 ], [ 29.728772586206897, -1.497672923076923 ], [ 29.728772586206897, -1.497403446153846 ], [ 29.728503103448276, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4509, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.497403446153846 ], [ 29.728772586206897, -1.497672923076923 ], [ 29.729042068965516, -1.497672923076923 ], [ 29.729042068965516, -1.497403446153846 ], [ 29.728772586206897, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4510, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.497403446153846 ], [ 29.729042068965516, -1.497672923076923 ], [ 29.729311551724138, -1.497672923076923 ], [ 29.729311551724138, -1.497403446153846 ], [ 29.729042068965516, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4511, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.497403446153846 ], [ 29.729850517241381, -1.497672923076923 ], [ 29.73012, -1.497672923076923 ], [ 29.73012, -1.497403446153846 ], [ 29.729850517241381, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4512, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.497403446153846 ], [ 29.73012, -1.4979424 ], [ 29.730389482758621, -1.4979424 ], [ 29.730389482758621, -1.497403446153846 ], [ 29.73012, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4513, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.497403446153846 ], [ 29.730389482758621, -1.4979424 ], [ 29.730658965517243, -1.4979424 ], [ 29.730658965517243, -1.497403446153846 ], [ 29.730389482758621, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4514, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.497403446153846 ], [ 29.733084310344829, -1.497672923076923 ], [ 29.733353793103447, -1.497672923076923 ], [ 29.733353793103447, -1.497403446153846 ], [ 29.733084310344829, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4515, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.497403446153846 ], [ 29.733353793103447, -1.497672923076923 ], [ 29.733623275862069, -1.497672923076923 ], [ 29.733623275862069, -1.497403446153846 ], [ 29.733353793103447, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4516, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.497403446153846 ], [ 29.733623275862069, -1.497672923076923 ], [ 29.734162241379309, -1.497672923076923 ], [ 29.734162241379309, -1.497403446153846 ], [ 29.733623275862069, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4517, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.497403446153846 ], [ 29.734162241379309, -1.497672923076923 ], [ 29.734431724137931, -1.497672923076923 ], [ 29.734431724137931, -1.497403446153846 ], [ 29.734162241379309, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4518, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.497403446153846 ], [ 29.734431724137931, -1.497672923076923 ], [ 29.734701206896553, -1.497672923076923 ], [ 29.734701206896553, -1.497403446153846 ], [ 29.734431724137931, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4519, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.497403446153846 ], [ 29.734701206896553, -1.497672923076923 ], [ 29.734970689655171, -1.497672923076923 ], [ 29.734970689655171, -1.497403446153846 ], [ 29.734701206896553, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4520, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.497403446153846 ], [ 29.734970689655171, -1.497672923076923 ], [ 29.735240172413793, -1.497672923076923 ], [ 29.735240172413793, -1.497403446153846 ], [ 29.734970689655171, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4521, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.497403446153846 ], [ 29.735240172413793, -1.497672923076923 ], [ 29.735509655172415, -1.497672923076923 ], [ 29.735509655172415, -1.497403446153846 ], [ 29.735240172413793, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4522, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.497403446153846 ], [ 29.735509655172415, -1.497672923076923 ], [ 29.735779137931036, -1.497672923076923 ], [ 29.735779137931036, -1.497403446153846 ], [ 29.735509655172415, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4523, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.497403446153846 ], [ 29.735779137931036, -1.497672923076923 ], [ 29.736048620689655, -1.497672923076923 ], [ 29.736048620689655, -1.497403446153846 ], [ 29.735779137931036, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4524, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.497403446153846 ], [ 29.736048620689655, -1.497672923076923 ], [ 29.736318103448276, -1.497672923076923 ], [ 29.736318103448276, -1.497403446153846 ], [ 29.736048620689655, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4525, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.497403446153846 ], [ 29.736318103448276, -1.497672923076923 ], [ 29.736587586206898, -1.497672923076923 ], [ 29.736587586206898, -1.497403446153846 ], [ 29.736318103448276, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4526, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.497403446153846 ], [ 29.736587586206898, -1.4979424 ], [ 29.736857068965517, -1.4979424 ], [ 29.736857068965517, -1.498211876923077 ], [ 29.737126551724138, -1.498211876923077 ], [ 29.737126551724138, -1.497672923076923 ], [ 29.736857068965517, -1.497672923076923 ], [ 29.736857068965517, -1.497403446153846 ], [ 29.736587586206898, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4527, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.497403446153846 ], [ 29.738743448275862, -1.4979424 ], [ 29.739012931034484, -1.4979424 ], [ 29.739012931034484, -1.497403446153846 ], [ 29.738743448275862, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4528, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.497403446153846 ], [ 29.739551896551724, -1.497672923076923 ], [ 29.739821379310346, -1.497672923076923 ], [ 29.739821379310346, -1.497403446153846 ], [ 29.739551896551724, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4529, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.497403446153846 ], [ 29.739821379310346, -1.497672923076923 ], [ 29.74089931034483, -1.497672923076923 ], [ 29.74089931034483, -1.497403446153846 ], [ 29.739821379310346, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4530, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.497403446153846 ], [ 29.748714310344827, -1.497672923076923 ], [ 29.748983793103449, -1.497672923076923 ], [ 29.748983793103449, -1.497403446153846 ], [ 29.748714310344827, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4531, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.497403446153846 ], [ 29.748983793103449, -1.497672923076923 ], [ 29.74925327586207, -1.497672923076923 ], [ 29.74925327586207, -1.497403446153846 ], [ 29.748983793103449, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4532, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.497403446153846 ], [ 29.74925327586207, -1.497672923076923 ], [ 29.749522758620689, -1.497672923076923 ], [ 29.749522758620689, -1.497403446153846 ], [ 29.74925327586207, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4533, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.497403446153846 ], [ 29.749522758620689, -1.497672923076923 ], [ 29.749792241379311, -1.497672923076923 ], [ 29.749792241379311, -1.497403446153846 ], [ 29.749522758620689, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4534, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.497403446153846 ], [ 29.749792241379311, -1.497672923076923 ], [ 29.750061724137932, -1.497672923076923 ], [ 29.750061724137932, -1.497403446153846 ], [ 29.749792241379311, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4535, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.497403446153846 ], [ 29.750061724137932, -1.497672923076923 ], [ 29.750331206896551, -1.497672923076923 ], [ 29.750331206896551, -1.497403446153846 ], [ 29.750061724137932, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4536, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.497403446153846 ], [ 29.750331206896551, -1.497672923076923 ], [ 29.750600689655172, -1.497672923076923 ], [ 29.750600689655172, -1.497403446153846 ], [ 29.750331206896551, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4537, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.497403446153846 ], [ 29.750600689655172, -1.497672923076923 ], [ 29.750870172413794, -1.497672923076923 ], [ 29.750870172413794, -1.497403446153846 ], [ 29.750600689655172, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4538, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.497403446153846 ], [ 29.750870172413794, -1.497672923076923 ], [ 29.751139655172413, -1.497672923076923 ], [ 29.751139655172413, -1.497403446153846 ], [ 29.750870172413794, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4539, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.497403446153846 ], [ 29.751139655172413, -1.497672923076923 ], [ 29.751409137931034, -1.497672923076923 ], [ 29.751409137931034, -1.497403446153846 ], [ 29.751139655172413, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4540, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.497403446153846 ], [ 29.751409137931034, -1.497672923076923 ], [ 29.751678620689656, -1.497672923076923 ], [ 29.751678620689656, -1.497403446153846 ], [ 29.751409137931034, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4541, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.497403446153846 ], [ 29.751678620689656, -1.497672923076923 ], [ 29.751948103448278, -1.497672923076923 ], [ 29.751948103448278, -1.497403446153846 ], [ 29.751678620689656, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4542, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.497403446153846 ], [ 29.751948103448278, -1.497672923076923 ], [ 29.752217586206896, -1.497672923076923 ], [ 29.752217586206896, -1.497403446153846 ], [ 29.751948103448278, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4543, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.497403446153846 ], [ 29.752217586206896, -1.497672923076923 ], [ 29.752487068965518, -1.497672923076923 ], [ 29.752487068965518, -1.497403446153846 ], [ 29.752217586206896, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4544, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.497403446153846 ], [ 29.75275655172414, -1.4979424 ], [ 29.753026034482758, -1.4979424 ], [ 29.753026034482758, -1.497403446153846 ], [ 29.75275655172414, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4545, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.497403446153846 ], [ 29.753026034482758, -1.497672923076923 ], [ 29.75329551724138, -1.497672923076923 ], [ 29.75329551724138, -1.497403446153846 ], [ 29.753026034482758, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4546, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.497403446153846 ], [ 29.75383448275862, -1.4979424 ], [ 29.754103965517242, -1.4979424 ], [ 29.754103965517242, -1.497403446153846 ], [ 29.75383448275862, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4547, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.497403446153846 ], [ 29.754103965517242, -1.4979424 ], [ 29.754373448275864, -1.4979424 ], [ 29.754373448275864, -1.497403446153846 ], [ 29.754103965517242, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4548, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.497403446153846 ], [ 29.754912413793104, -1.497672923076923 ], [ 29.755181896551726, -1.497672923076923 ], [ 29.755181896551726, -1.497403446153846 ], [ 29.754912413793104, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4549, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.497403446153846 ], [ 29.755181896551726, -1.497672923076923 ], [ 29.755451379310344, -1.497672923076923 ], [ 29.755451379310344, -1.497403446153846 ], [ 29.755181896551726, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4550, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.497403446153846 ], [ 29.755451379310344, -1.497672923076923 ], [ 29.755720862068966, -1.497672923076923 ], [ 29.755720862068966, -1.497403446153846 ], [ 29.755451379310344, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4551, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.497403446153846 ], [ 29.755720862068966, -1.497672923076923 ], [ 29.755990344827588, -1.497672923076923 ], [ 29.755990344827588, -1.497403446153846 ], [ 29.755720862068966, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4552, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.497403446153846 ], [ 29.755990344827588, -1.497672923076923 ], [ 29.756259827586206, -1.497672923076923 ], [ 29.756259827586206, -1.497403446153846 ], [ 29.755990344827588, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4553, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.497403446153846 ], [ 29.756259827586206, -1.497672923076923 ], [ 29.756798793103449, -1.497672923076923 ], [ 29.756798793103449, -1.497403446153846 ], [ 29.756259827586206, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4554, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.497403446153846 ], [ 29.756798793103449, -1.497672923076923 ], [ 29.757068275862068, -1.497672923076923 ], [ 29.757068275862068, -1.497403446153846 ], [ 29.756798793103449, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4555, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.497403446153846 ], [ 29.757068275862068, -1.497672923076923 ], [ 29.75733775862069, -1.497672923076923 ], [ 29.75733775862069, -1.497403446153846 ], [ 29.757068275862068, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4556, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.497403446153846 ], [ 29.75733775862069, -1.497672923076923 ], [ 29.757607241379311, -1.497672923076923 ], [ 29.757607241379311, -1.497403446153846 ], [ 29.75733775862069, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4557, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.497403446153846 ], [ 29.757607241379311, -1.497672923076923 ], [ 29.757876724137933, -1.497672923076923 ], [ 29.757876724137933, -1.497403446153846 ], [ 29.757607241379311, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4558, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.497403446153846 ], [ 29.757876724137933, -1.497672923076923 ], [ 29.758146206896551, -1.497672923076923 ], [ 29.758146206896551, -1.497403446153846 ], [ 29.757876724137933, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4559, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.497403446153846 ], [ 29.758146206896551, -1.497672923076923 ], [ 29.758415689655173, -1.497672923076923 ], [ 29.758415689655173, -1.497403446153846 ], [ 29.758146206896551, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4560, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.497403446153846 ], [ 29.758415689655173, -1.497672923076923 ], [ 29.758685172413795, -1.497672923076923 ], [ 29.758685172413795, -1.497403446153846 ], [ 29.758415689655173, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4561, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.497403446153846 ], [ 29.758685172413795, -1.497672923076923 ], [ 29.758954655172413, -1.497672923076923 ], [ 29.758954655172413, -1.497403446153846 ], [ 29.758685172413795, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4562, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.497403446153846 ], [ 29.758954655172413, -1.497672923076923 ], [ 29.759224137931035, -1.497672923076923 ], [ 29.759224137931035, -1.497403446153846 ], [ 29.758954655172413, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4563, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.497403446153846 ], [ 29.759224137931035, -1.497672923076923 ], [ 29.759493620689657, -1.497672923076923 ], [ 29.759493620689657, -1.497403446153846 ], [ 29.759224137931035, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4564, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.497403446153846 ], [ 29.759493620689657, -1.4979424 ], [ 29.759763103448275, -1.4979424 ], [ 29.759763103448275, -1.497403446153846 ], [ 29.759493620689657, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4565, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.497403446153846 ], [ 29.759763103448275, -1.4979424 ], [ 29.760032586206897, -1.4979424 ], [ 29.760032586206897, -1.497403446153846 ], [ 29.759763103448275, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4566, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.497403446153846 ], [ 29.760032586206897, -1.4979424 ], [ 29.760302068965519, -1.4979424 ], [ 29.760302068965519, -1.497403446153846 ], [ 29.760032586206897, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4567, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.497403446153846 ], [ 29.760302068965519, -1.497672923076923 ], [ 29.760571551724137, -1.497672923076923 ], [ 29.760571551724137, -1.497403446153846 ], [ 29.760302068965519, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4568, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.497672923076923 ], [ 29.717993275862067, -1.498211876923077 ], [ 29.718262758620689, -1.498211876923077 ], [ 29.718262758620689, -1.497672923076923 ], [ 29.717993275862067, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4569, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.497672923076923 ], [ 29.718262758620689, -1.498211876923077 ], [ 29.718532241379311, -1.498211876923077 ], [ 29.718532241379311, -1.497672923076923 ], [ 29.718262758620689, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4570, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.497672923076923 ], [ 29.718532241379311, -1.498211876923077 ], [ 29.718801724137933, -1.498211876923077 ], [ 29.718801724137933, -1.497672923076923 ], [ 29.718532241379311, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4571, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.497672923076923 ], [ 29.718801724137933, -1.498211876923077 ], [ 29.719071206896551, -1.498211876923077 ], [ 29.719071206896551, -1.497672923076923 ], [ 29.718801724137933, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4572, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.497672923076923 ], [ 29.719071206896551, -1.498211876923077 ], [ 29.719340689655173, -1.498211876923077 ], [ 29.719340689655173, -1.497672923076923 ], [ 29.719071206896551, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4573, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.497672923076923 ], [ 29.719340689655173, -1.498211876923077 ], [ 29.719610172413795, -1.498211876923077 ], [ 29.719610172413795, -1.497672923076923 ], [ 29.719340689655173, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4574, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.497672923076923 ], [ 29.719610172413795, -1.4979424 ], [ 29.719879655172413, -1.4979424 ], [ 29.719879655172413, -1.497672923076923 ], [ 29.719610172413795, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4575, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.497672923076923 ], [ 29.719879655172413, -1.4979424 ], [ 29.720149137931035, -1.4979424 ], [ 29.720149137931035, -1.497672923076923 ], [ 29.719879655172413, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4576, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.497672923076923 ], [ 29.720149137931035, -1.4979424 ], [ 29.720688103448275, -1.4979424 ], [ 29.720688103448275, -1.497672923076923 ], [ 29.720149137931035, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4577, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.497672923076923 ], [ 29.720688103448275, -1.4979424 ], [ 29.720957586206897, -1.4979424 ], [ 29.720957586206897, -1.497672923076923 ], [ 29.720688103448275, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4578, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.497672923076923 ], [ 29.720957586206897, -1.4979424 ], [ 29.721227068965518, -1.4979424 ], [ 29.721227068965518, -1.497672923076923 ], [ 29.720957586206897, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4579, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.497672923076923 ], [ 29.721227068965518, -1.4979424 ], [ 29.721496551724137, -1.4979424 ], [ 29.721496551724137, -1.497672923076923 ], [ 29.721227068965518, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4580, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.497672923076923 ], [ 29.721496551724137, -1.4979424 ], [ 29.721766034482759, -1.4979424 ], [ 29.721766034482759, -1.497672923076923 ], [ 29.721496551724137, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4581, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.497672923076923 ], [ 29.721766034482759, -1.4979424 ], [ 29.72203551724138, -1.4979424 ], [ 29.72203551724138, -1.497672923076923 ], [ 29.721766034482759, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4582, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.497672923076923 ], [ 29.72203551724138, -1.4979424 ], [ 29.722305, -1.4979424 ], [ 29.722305, -1.497672923076923 ], [ 29.72203551724138, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4583, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.497672923076923 ], [ 29.722305, -1.4979424 ], [ 29.72257448275862, -1.4979424 ], [ 29.72257448275862, -1.497672923076923 ], [ 29.722305, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4584, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.497672923076923 ], [ 29.72257448275862, -1.4979424 ], [ 29.722843965517242, -1.4979424 ], [ 29.722843965517242, -1.497672923076923 ], [ 29.72257448275862, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4585, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.497672923076923 ], [ 29.722843965517242, -1.4979424 ], [ 29.723113448275861, -1.4979424 ], [ 29.723113448275861, -1.497672923076923 ], [ 29.722843965517242, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4586, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.497672923076923 ], [ 29.723113448275861, -1.4979424 ], [ 29.723382931034482, -1.4979424 ], [ 29.723382931034482, -1.497672923076923 ], [ 29.723113448275861, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4587, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.497672923076923 ], [ 29.723382931034482, -1.4979424 ], [ 29.723652413793104, -1.4979424 ], [ 29.723652413793104, -1.497672923076923 ], [ 29.723382931034482, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4588, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.497672923076923 ], [ 29.723652413793104, -1.4979424 ], [ 29.723921896551722, -1.4979424 ], [ 29.723921896551722, -1.497672923076923 ], [ 29.723652413793104, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4589, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.497672923076923 ], [ 29.723921896551722, -1.4979424 ], [ 29.724191379310344, -1.4979424 ], [ 29.724191379310344, -1.497672923076923 ], [ 29.723921896551722, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4590, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.497672923076923 ], [ 29.724191379310344, -1.4979424 ], [ 29.724460862068966, -1.4979424 ], [ 29.724460862068966, -1.497672923076923 ], [ 29.724191379310344, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4591, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.497672923076923 ], [ 29.724460862068966, -1.4979424 ], [ 29.724730344827588, -1.4979424 ], [ 29.724730344827588, -1.497672923076923 ], [ 29.724460862068966, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4592, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.497672923076923 ], [ 29.724730344827588, -1.4979424 ], [ 29.724999827586206, -1.4979424 ], [ 29.724999827586206, -1.497672923076923 ], [ 29.724730344827588, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4593, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.497672923076923 ], [ 29.724999827586206, -1.4979424 ], [ 29.725269310344828, -1.4979424 ], [ 29.725269310344828, -1.497672923076923 ], [ 29.724999827586206, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4594, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.497672923076923 ], [ 29.725269310344828, -1.4979424 ], [ 29.72553879310345, -1.4979424 ], [ 29.72553879310345, -1.497672923076923 ], [ 29.725269310344828, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4595, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.497672923076923 ], [ 29.72553879310345, -1.4979424 ], [ 29.725808275862068, -1.4979424 ], [ 29.725808275862068, -1.497672923076923 ], [ 29.72553879310345, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4596, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.497672923076923 ], [ 29.725808275862068, -1.498211876923077 ], [ 29.72607775862069, -1.498211876923077 ], [ 29.72607775862069, -1.497672923076923 ], [ 29.725808275862068, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4597, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.497672923076923 ], [ 29.726347241379312, -1.4979424 ], [ 29.72661672413793, -1.4979424 ], [ 29.72661672413793, -1.497672923076923 ], [ 29.726347241379312, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4598, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.497672923076923 ], [ 29.72661672413793, -1.4979424 ], [ 29.726886206896552, -1.4979424 ], [ 29.726886206896552, -1.497672923076923 ], [ 29.72661672413793, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4599, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.497672923076923 ], [ 29.726886206896552, -1.4979424 ], [ 29.727155689655174, -1.4979424 ], [ 29.727155689655174, -1.497672923076923 ], [ 29.726886206896552, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4600, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.497672923076923 ], [ 29.727155689655174, -1.4979424 ], [ 29.727425172413792, -1.4979424 ], [ 29.727425172413792, -1.497672923076923 ], [ 29.727155689655174, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4601, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.497672923076923 ], [ 29.727425172413792, -1.4979424 ], [ 29.727694655172414, -1.4979424 ], [ 29.727694655172414, -1.497672923076923 ], [ 29.727425172413792, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4602, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.497672923076923 ], [ 29.727694655172414, -1.4979424 ], [ 29.727964137931036, -1.4979424 ], [ 29.727964137931036, -1.497672923076923 ], [ 29.727694655172414, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4603, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.497672923076923 ], [ 29.727964137931036, -1.4979424 ], [ 29.728233620689654, -1.4979424 ], [ 29.728233620689654, -1.497672923076923 ], [ 29.727964137931036, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4604, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.497672923076923 ], [ 29.728233620689654, -1.4979424 ], [ 29.728503103448276, -1.4979424 ], [ 29.728503103448276, -1.497672923076923 ], [ 29.728233620689654, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4605, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.497672923076923 ], [ 29.728503103448276, -1.4979424 ], [ 29.728772586206897, -1.4979424 ], [ 29.728772586206897, -1.497672923076923 ], [ 29.728503103448276, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4606, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.497672923076923 ], [ 29.728772586206897, -1.4979424 ], [ 29.729042068965516, -1.4979424 ], [ 29.729042068965516, -1.497672923076923 ], [ 29.728772586206897, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4607, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.497672923076923 ], [ 29.729042068965516, -1.4979424 ], [ 29.729311551724138, -1.4979424 ], [ 29.729311551724138, -1.497672923076923 ], [ 29.729042068965516, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4608, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.497672923076923 ], [ 29.729311551724138, -1.4979424 ], [ 29.729850517241381, -1.4979424 ], [ 29.729850517241381, -1.497672923076923 ], [ 29.729311551724138, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4609, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.497672923076923 ], [ 29.729850517241381, -1.498211876923077 ], [ 29.73012, -1.498211876923077 ], [ 29.73012, -1.497672923076923 ], [ 29.729850517241381, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4610, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.497672923076923 ], [ 29.730658965517243, -1.4979424 ], [ 29.730928448275861, -1.4979424 ], [ 29.730928448275861, -1.497672923076923 ], [ 29.730658965517243, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4611, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.497672923076923 ], [ 29.733353793103447, -1.4979424 ], [ 29.733892758620691, -1.4979424 ], [ 29.733892758620691, -1.497672923076923 ], [ 29.733353793103447, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4612, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.497672923076923 ], [ 29.733892758620691, -1.4979424 ], [ 29.734162241379309, -1.4979424 ], [ 29.734162241379309, -1.497672923076923 ], [ 29.733892758620691, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4613, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.497672923076923 ], [ 29.734162241379309, -1.4979424 ], [ 29.734431724137931, -1.4979424 ], [ 29.734431724137931, -1.497672923076923 ], [ 29.734162241379309, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4614, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.497672923076923 ], [ 29.734431724137931, -1.4979424 ], [ 29.734701206896553, -1.4979424 ], [ 29.734701206896553, -1.497672923076923 ], [ 29.734431724137931, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4615, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.497672923076923 ], [ 29.734701206896553, -1.4979424 ], [ 29.734970689655171, -1.4979424 ], [ 29.734970689655171, -1.497672923076923 ], [ 29.734701206896553, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4616, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.497672923076923 ], [ 29.734970689655171, -1.498211876923077 ], [ 29.735240172413793, -1.498211876923077 ], [ 29.735240172413793, -1.497672923076923 ], [ 29.734970689655171, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4617, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.497672923076923 ], [ 29.735240172413793, -1.4979424 ], [ 29.735509655172415, -1.4979424 ], [ 29.735509655172415, -1.497672923076923 ], [ 29.735240172413793, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4618, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.497672923076923 ], [ 29.735509655172415, -1.4979424 ], [ 29.735779137931036, -1.4979424 ], [ 29.735779137931036, -1.497672923076923 ], [ 29.735509655172415, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4619, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.497672923076923 ], [ 29.735779137931036, -1.4979424 ], [ 29.736048620689655, -1.4979424 ], [ 29.736048620689655, -1.497672923076923 ], [ 29.735779137931036, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4620, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.497672923076923 ], [ 29.737665517241378, -1.4979424 ], [ 29.737935, -1.4979424 ], [ 29.737935, -1.497672923076923 ], [ 29.737665517241378, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4621, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.497672923076923 ], [ 29.737935, -1.4979424 ], [ 29.738204482758622, -1.4979424 ], [ 29.738204482758622, -1.497672923076923 ], [ 29.737935, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4622, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.497672923076923 ], [ 29.738204482758622, -1.4979424 ], [ 29.738743448275862, -1.4979424 ], [ 29.738743448275862, -1.497672923076923 ], [ 29.738204482758622, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4623, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.497672923076923 ], [ 29.739012931034484, -1.4979424 ], [ 29.739282413793102, -1.4979424 ], [ 29.739282413793102, -1.497672923076923 ], [ 29.739012931034484, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4624, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.497672923076923 ], [ 29.739282413793102, -1.4979424 ], [ 29.739551896551724, -1.4979424 ], [ 29.739551896551724, -1.497672923076923 ], [ 29.739282413793102, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4625, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.497672923076923 ], [ 29.739551896551724, -1.4979424 ], [ 29.740629827586208, -1.4979424 ], [ 29.740629827586208, -1.497672923076923 ], [ 29.739551896551724, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4626, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.497672923076923 ], [ 29.740629827586208, -1.4979424 ], [ 29.74089931034483, -1.4979424 ], [ 29.74089931034483, -1.497672923076923 ], [ 29.740629827586208, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4627, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.497672923076923 ], [ 29.74089931034483, -1.4979424 ], [ 29.741168793103448, -1.4979424 ], [ 29.741168793103448, -1.498481353846154 ], [ 29.74143827586207, -1.498481353846154 ], [ 29.74143827586207, -1.4979424 ], [ 29.741707758620691, -1.4979424 ], [ 29.741707758620691, -1.497672923076923 ], [ 29.74089931034483, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4628, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.497672923076923 ], [ 29.748714310344827, -1.498481353846154 ], [ 29.748983793103449, -1.498481353846154 ], [ 29.748983793103449, -1.497672923076923 ], [ 29.748714310344827, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4629, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.497672923076923 ], [ 29.748983793103449, -1.498481353846154 ], [ 29.74925327586207, -1.498481353846154 ], [ 29.74925327586207, -1.497672923076923 ], [ 29.748983793103449, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4630, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.497672923076923 ], [ 29.74925327586207, -1.4979424 ], [ 29.749522758620689, -1.4979424 ], [ 29.749522758620689, -1.497672923076923 ], [ 29.74925327586207, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4631, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.497672923076923 ], [ 29.749522758620689, -1.4979424 ], [ 29.749792241379311, -1.4979424 ], [ 29.749792241379311, -1.497672923076923 ], [ 29.749522758620689, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4632, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.497672923076923 ], [ 29.749792241379311, -1.4979424 ], [ 29.750061724137932, -1.4979424 ], [ 29.750061724137932, -1.497672923076923 ], [ 29.749792241379311, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4633, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.497672923076923 ], [ 29.750061724137932, -1.4979424 ], [ 29.750331206896551, -1.4979424 ], [ 29.750331206896551, -1.497672923076923 ], [ 29.750061724137932, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4634, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.497672923076923 ], [ 29.750331206896551, -1.4979424 ], [ 29.750600689655172, -1.4979424 ], [ 29.750600689655172, -1.497672923076923 ], [ 29.750331206896551, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4635, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.497672923076923 ], [ 29.750600689655172, -1.4979424 ], [ 29.750870172413794, -1.4979424 ], [ 29.750870172413794, -1.497672923076923 ], [ 29.750600689655172, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4636, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.497672923076923 ], [ 29.750870172413794, -1.4979424 ], [ 29.751139655172413, -1.4979424 ], [ 29.751139655172413, -1.497672923076923 ], [ 29.750870172413794, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4637, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.497672923076923 ], [ 29.751139655172413, -1.4979424 ], [ 29.751409137931034, -1.4979424 ], [ 29.751409137931034, -1.497672923076923 ], [ 29.751139655172413, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4638, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.497672923076923 ], [ 29.751409137931034, -1.4979424 ], [ 29.751678620689656, -1.4979424 ], [ 29.751678620689656, -1.497672923076923 ], [ 29.751409137931034, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4639, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.497672923076923 ], [ 29.751678620689656, -1.4979424 ], [ 29.751948103448278, -1.4979424 ], [ 29.751948103448278, -1.497672923076923 ], [ 29.751678620689656, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4640, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.497672923076923 ], [ 29.751948103448278, -1.4979424 ], [ 29.752217586206896, -1.4979424 ], [ 29.752217586206896, -1.497672923076923 ], [ 29.751948103448278, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4641, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.497672923076923 ], [ 29.752217586206896, -1.4979424 ], [ 29.752487068965518, -1.4979424 ], [ 29.752487068965518, -1.497672923076923 ], [ 29.752217586206896, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4642, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.497672923076923 ], [ 29.752487068965518, -1.4979424 ], [ 29.75275655172414, -1.4979424 ], [ 29.75275655172414, -1.497672923076923 ], [ 29.752487068965518, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4643, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.497672923076923 ], [ 29.753026034482758, -1.498211876923077 ], [ 29.75329551724138, -1.498211876923077 ], [ 29.75329551724138, -1.497672923076923 ], [ 29.753026034482758, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4644, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.497672923076923 ], [ 29.75329551724138, -1.4979424 ], [ 29.753565, -1.4979424 ], [ 29.753565, -1.497672923076923 ], [ 29.75329551724138, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4645, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.497672923076923 ], [ 29.753565, -1.4979424 ], [ 29.75383448275862, -1.4979424 ], [ 29.75383448275862, -1.497672923076923 ], [ 29.753565, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4646, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.497672923076923 ], [ 29.754373448275864, -1.498481353846154 ], [ 29.754642931034482, -1.498481353846154 ], [ 29.754642931034482, -1.497672923076923 ], [ 29.754373448275864, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4647, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.497672923076923 ], [ 29.754642931034482, -1.498481353846154 ], [ 29.754912413793104, -1.498481353846154 ], [ 29.754912413793104, -1.498211876923077 ], [ 29.755181896551726, -1.498211876923077 ], [ 29.755181896551726, -1.4979424 ], [ 29.754912413793104, -1.4979424 ], [ 29.754912413793104, -1.497672923076923 ], [ 29.754642931034482, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4648, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.497672923076923 ], [ 29.754912413793104, -1.4979424 ], [ 29.755181896551726, -1.4979424 ], [ 29.755181896551726, -1.497672923076923 ], [ 29.754912413793104, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4649, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.497672923076923 ], [ 29.755181896551726, -1.4979424 ], [ 29.755451379310344, -1.4979424 ], [ 29.755451379310344, -1.497672923076923 ], [ 29.755181896551726, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4650, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.497672923076923 ], [ 29.755451379310344, -1.4979424 ], [ 29.755720862068966, -1.4979424 ], [ 29.755720862068966, -1.497672923076923 ], [ 29.755451379310344, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4651, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.497672923076923 ], [ 29.755720862068966, -1.4979424 ], [ 29.755990344827588, -1.4979424 ], [ 29.755990344827588, -1.497672923076923 ], [ 29.755720862068966, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4652, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.497672923076923 ], [ 29.755990344827588, -1.4979424 ], [ 29.756259827586206, -1.4979424 ], [ 29.756259827586206, -1.497672923076923 ], [ 29.755990344827588, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4653, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.497672923076923 ], [ 29.756259827586206, -1.4979424 ], [ 29.756798793103449, -1.4979424 ], [ 29.756798793103449, -1.497672923076923 ], [ 29.756259827586206, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4654, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.497672923076923 ], [ 29.756798793103449, -1.4979424 ], [ 29.757068275862068, -1.4979424 ], [ 29.757068275862068, -1.497672923076923 ], [ 29.756798793103449, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4655, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.497672923076923 ], [ 29.757068275862068, -1.4979424 ], [ 29.75733775862069, -1.4979424 ], [ 29.75733775862069, -1.497672923076923 ], [ 29.757068275862068, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4656, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.497672923076923 ], [ 29.75733775862069, -1.4979424 ], [ 29.757607241379311, -1.4979424 ], [ 29.757607241379311, -1.497672923076923 ], [ 29.75733775862069, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4657, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.497672923076923 ], [ 29.757607241379311, -1.4979424 ], [ 29.757876724137933, -1.4979424 ], [ 29.757876724137933, -1.497672923076923 ], [ 29.757607241379311, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4658, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.497672923076923 ], [ 29.757876724137933, -1.4979424 ], [ 29.758146206896551, -1.4979424 ], [ 29.758146206896551, -1.497672923076923 ], [ 29.757876724137933, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4659, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.497672923076923 ], [ 29.758146206896551, -1.4979424 ], [ 29.758415689655173, -1.4979424 ], [ 29.758415689655173, -1.497672923076923 ], [ 29.758146206896551, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4660, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.497672923076923 ], [ 29.758415689655173, -1.4979424 ], [ 29.758685172413795, -1.4979424 ], [ 29.758685172413795, -1.497672923076923 ], [ 29.758415689655173, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4661, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.497672923076923 ], [ 29.758685172413795, -1.4979424 ], [ 29.758954655172413, -1.4979424 ], [ 29.758954655172413, -1.497672923076923 ], [ 29.758685172413795, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4662, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.497672923076923 ], [ 29.758954655172413, -1.498211876923077 ], [ 29.759224137931035, -1.498211876923077 ], [ 29.759224137931035, -1.497672923076923 ], [ 29.758954655172413, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4663, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.497672923076923 ], [ 29.759224137931035, -1.498211876923077 ], [ 29.759493620689657, -1.498211876923077 ], [ 29.759493620689657, -1.497672923076923 ], [ 29.759224137931035, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4664, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.497672923076923 ], [ 29.760302068965519, -1.498211876923077 ], [ 29.760571551724137, -1.498211876923077 ], [ 29.760571551724137, -1.497672923076923 ], [ 29.760302068965519, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4665, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.497672923076923 ], [ 29.761110517241381, -1.4979424 ], [ 29.76138, -1.4979424 ], [ 29.76138, -1.497672923076923 ], [ 29.761110517241381, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4666, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.497672923076923 ], [ 29.76138, -1.498481353846154 ], [ 29.761649482758621, -1.498481353846154 ], [ 29.761649482758621, -1.497672923076923 ], [ 29.76138, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4667, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.4979424 ], [ 29.719610172413795, -1.498211876923077 ], [ 29.719879655172413, -1.498211876923077 ], [ 29.719879655172413, -1.4979424 ], [ 29.719610172413795, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4668, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.4979424 ], [ 29.719879655172413, -1.498211876923077 ], [ 29.720149137931035, -1.498211876923077 ], [ 29.720149137931035, -1.4979424 ], [ 29.719879655172413, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4669, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.4979424 ], [ 29.720149137931035, -1.498481353846154 ], [ 29.720688103448275, -1.498481353846154 ], [ 29.720688103448275, -1.4979424 ], [ 29.720149137931035, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4670, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.4979424 ], [ 29.720688103448275, -1.498481353846154 ], [ 29.720957586206897, -1.498481353846154 ], [ 29.720957586206897, -1.4979424 ], [ 29.720688103448275, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4671, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.4979424 ], [ 29.720957586206897, -1.498211876923077 ], [ 29.721227068965518, -1.498211876923077 ], [ 29.721227068965518, -1.4979424 ], [ 29.720957586206897, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4672, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.4979424 ], [ 29.721227068965518, -1.498211876923077 ], [ 29.721496551724137, -1.498211876923077 ], [ 29.721496551724137, -1.4979424 ], [ 29.721227068965518, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4673, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.4979424 ], [ 29.721496551724137, -1.498211876923077 ], [ 29.721766034482759, -1.498211876923077 ], [ 29.721766034482759, -1.4979424 ], [ 29.721496551724137, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4674, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.4979424 ], [ 29.721766034482759, -1.498211876923077 ], [ 29.72203551724138, -1.498211876923077 ], [ 29.72203551724138, -1.4979424 ], [ 29.721766034482759, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4675, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.4979424 ], [ 29.722305, -1.498211876923077 ], [ 29.72257448275862, -1.498211876923077 ], [ 29.72257448275862, -1.4979424 ], [ 29.722305, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4676, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.4979424 ], [ 29.72257448275862, -1.498211876923077 ], [ 29.723113448275861, -1.498211876923077 ], [ 29.723113448275861, -1.4979424 ], [ 29.72257448275862, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4677, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.4979424 ], [ 29.723113448275861, -1.498211876923077 ], [ 29.723382931034482, -1.498211876923077 ], [ 29.723382931034482, -1.4979424 ], [ 29.723113448275861, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4678, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.4979424 ], [ 29.723382931034482, -1.498211876923077 ], [ 29.723921896551722, -1.498211876923077 ], [ 29.723921896551722, -1.4979424 ], [ 29.723382931034482, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4679, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.4979424 ], [ 29.723921896551722, -1.498211876923077 ], [ 29.724191379310344, -1.498211876923077 ], [ 29.724191379310344, -1.4979424 ], [ 29.723921896551722, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4680, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.4979424 ], [ 29.724191379310344, -1.498211876923077 ], [ 29.724460862068966, -1.498211876923077 ], [ 29.724460862068966, -1.4979424 ], [ 29.724191379310344, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4681, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.4979424 ], [ 29.724460862068966, -1.498211876923077 ], [ 29.724730344827588, -1.498211876923077 ], [ 29.724730344827588, -1.4979424 ], [ 29.724460862068966, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4682, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.4979424 ], [ 29.724730344827588, -1.498211876923077 ], [ 29.724999827586206, -1.498211876923077 ], [ 29.724999827586206, -1.4979424 ], [ 29.724730344827588, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4683, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.4979424 ], [ 29.724999827586206, -1.498211876923077 ], [ 29.725269310344828, -1.498211876923077 ], [ 29.725269310344828, -1.4979424 ], [ 29.724999827586206, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4684, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.4979424 ], [ 29.725269310344828, -1.498211876923077 ], [ 29.72553879310345, -1.498211876923077 ], [ 29.72553879310345, -1.4979424 ], [ 29.725269310344828, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4685, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.4979424 ], [ 29.72553879310345, -1.498211876923077 ], [ 29.725808275862068, -1.498211876923077 ], [ 29.725808275862068, -1.4979424 ], [ 29.72553879310345, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4686, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.4979424 ], [ 29.72607775862069, -1.498211876923077 ], [ 29.726347241379312, -1.498211876923077 ], [ 29.726347241379312, -1.4979424 ], [ 29.72607775862069, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4687, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.4979424 ], [ 29.726347241379312, -1.498211876923077 ], [ 29.72661672413793, -1.498211876923077 ], [ 29.72661672413793, -1.4979424 ], [ 29.726347241379312, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4688, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.4979424 ], [ 29.72661672413793, -1.498211876923077 ], [ 29.726886206896552, -1.498211876923077 ], [ 29.726886206896552, -1.4979424 ], [ 29.72661672413793, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4689, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.4979424 ], [ 29.726886206896552, -1.498211876923077 ], [ 29.727155689655174, -1.498211876923077 ], [ 29.727155689655174, -1.4979424 ], [ 29.726886206896552, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4690, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.4979424 ], [ 29.727155689655174, -1.498211876923077 ], [ 29.727425172413792, -1.498211876923077 ], [ 29.727425172413792, -1.4979424 ], [ 29.727155689655174, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4691, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.4979424 ], [ 29.727425172413792, -1.498211876923077 ], [ 29.727694655172414, -1.498211876923077 ], [ 29.727694655172414, -1.4979424 ], [ 29.727425172413792, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4692, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.4979424 ], [ 29.727694655172414, -1.498211876923077 ], [ 29.727964137931036, -1.498211876923077 ], [ 29.727964137931036, -1.4979424 ], [ 29.727694655172414, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4693, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.4979424 ], [ 29.727964137931036, -1.498211876923077 ], [ 29.728233620689654, -1.498211876923077 ], [ 29.728233620689654, -1.4979424 ], [ 29.727964137931036, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4694, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.4979424 ], [ 29.728233620689654, -1.498211876923077 ], [ 29.728503103448276, -1.498211876923077 ], [ 29.728503103448276, -1.4979424 ], [ 29.728233620689654, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4695, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.4979424 ], [ 29.728503103448276, -1.498211876923077 ], [ 29.728772586206897, -1.498211876923077 ], [ 29.728772586206897, -1.4979424 ], [ 29.728503103448276, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4696, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.4979424 ], [ 29.728772586206897, -1.498211876923077 ], [ 29.729042068965516, -1.498211876923077 ], [ 29.729042068965516, -1.4979424 ], [ 29.728772586206897, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4697, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.4979424 ], [ 29.729042068965516, -1.498211876923077 ], [ 29.729311551724138, -1.498211876923077 ], [ 29.729311551724138, -1.4979424 ], [ 29.729042068965516, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4698, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.4979424 ], [ 29.729311551724138, -1.498211876923077 ], [ 29.729850517241381, -1.498211876923077 ], [ 29.729850517241381, -1.4979424 ], [ 29.729311551724138, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4699, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.4979424 ], [ 29.73012, -1.498211876923077 ], [ 29.730389482758621, -1.498211876923077 ], [ 29.730389482758621, -1.4979424 ], [ 29.73012, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4700, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.4979424 ], [ 29.733892758620691, -1.498211876923077 ], [ 29.734162241379309, -1.498211876923077 ], [ 29.734162241379309, -1.4979424 ], [ 29.733892758620691, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4701, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.4979424 ], [ 29.734162241379309, -1.498211876923077 ], [ 29.734431724137931, -1.498211876923077 ], [ 29.734431724137931, -1.4979424 ], [ 29.734162241379309, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4702, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.4979424 ], [ 29.737126551724138, -1.498211876923077 ], [ 29.73739603448276, -1.498211876923077 ], [ 29.73739603448276, -1.4979424 ], [ 29.737126551724138, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4703, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.4979424 ], [ 29.73739603448276, -1.498211876923077 ], [ 29.737665517241378, -1.498211876923077 ], [ 29.737665517241378, -1.4979424 ], [ 29.73739603448276, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4704, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.4979424 ], [ 29.737665517241378, -1.498211876923077 ], [ 29.737935, -1.498211876923077 ], [ 29.737935, -1.4979424 ], [ 29.737665517241378, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4705, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.4979424 ], [ 29.737935, -1.498211876923077 ], [ 29.738204482758622, -1.498211876923077 ], [ 29.738204482758622, -1.4979424 ], [ 29.737935, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4706, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.4979424 ], [ 29.738204482758622, -1.498211876923077 ], [ 29.738743448275862, -1.498211876923077 ], [ 29.738743448275862, -1.4979424 ], [ 29.738204482758622, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4707, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.4979424 ], [ 29.738743448275862, -1.498211876923077 ], [ 29.739012931034484, -1.498211876923077 ], [ 29.739012931034484, -1.4979424 ], [ 29.738743448275862, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4708, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.4979424 ], [ 29.739012931034484, -1.498211876923077 ], [ 29.739282413793102, -1.498211876923077 ], [ 29.739282413793102, -1.4979424 ], [ 29.739012931034484, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4709, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.4979424 ], [ 29.739282413793102, -1.498211876923077 ], [ 29.739551896551724, -1.498211876923077 ], [ 29.739551896551724, -1.4979424 ], [ 29.739282413793102, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4710, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.4979424 ], [ 29.74925327586207, -1.498211876923077 ], [ 29.749522758620689, -1.498211876923077 ], [ 29.749522758620689, -1.4979424 ], [ 29.74925327586207, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4711, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.4979424 ], [ 29.749522758620689, -1.498211876923077 ], [ 29.749792241379311, -1.498211876923077 ], [ 29.749792241379311, -1.4979424 ], [ 29.749522758620689, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4712, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.4979424 ], [ 29.749792241379311, -1.498211876923077 ], [ 29.750061724137932, -1.498211876923077 ], [ 29.750061724137932, -1.4979424 ], [ 29.749792241379311, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4713, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.4979424 ], [ 29.750061724137932, -1.498211876923077 ], [ 29.750331206896551, -1.498211876923077 ], [ 29.750331206896551, -1.4979424 ], [ 29.750061724137932, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4714, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.4979424 ], [ 29.750331206896551, -1.498481353846154 ], [ 29.750600689655172, -1.498481353846154 ], [ 29.750600689655172, -1.4979424 ], [ 29.750331206896551, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4715, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.4979424 ], [ 29.750600689655172, -1.498481353846154 ], [ 29.750870172413794, -1.498481353846154 ], [ 29.750870172413794, -1.4979424 ], [ 29.750600689655172, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4716, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.4979424 ], [ 29.750870172413794, -1.498481353846154 ], [ 29.751139655172413, -1.498481353846154 ], [ 29.751139655172413, -1.4979424 ], [ 29.750870172413794, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4717, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.4979424 ], [ 29.751139655172413, -1.498211876923077 ], [ 29.751409137931034, -1.498211876923077 ], [ 29.751409137931034, -1.4979424 ], [ 29.751139655172413, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4718, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.4979424 ], [ 29.751409137931034, -1.498211876923077 ], [ 29.751678620689656, -1.498211876923077 ], [ 29.751678620689656, -1.4979424 ], [ 29.751409137931034, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4719, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.4979424 ], [ 29.751678620689656, -1.498211876923077 ], [ 29.751948103448278, -1.498211876923077 ], [ 29.751948103448278, -1.4979424 ], [ 29.751678620689656, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4720, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.4979424 ], [ 29.751948103448278, -1.498211876923077 ], [ 29.752217586206896, -1.498211876923077 ], [ 29.752217586206896, -1.4979424 ], [ 29.751948103448278, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4721, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.4979424 ], [ 29.752217586206896, -1.498211876923077 ], [ 29.752487068965518, -1.498211876923077 ], [ 29.752487068965518, -1.4979424 ], [ 29.752217586206896, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4722, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.4979424 ], [ 29.752487068965518, -1.498211876923077 ], [ 29.75275655172414, -1.498211876923077 ], [ 29.75275655172414, -1.4979424 ], [ 29.752487068965518, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4723, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.4979424 ], [ 29.75275655172414, -1.498211876923077 ], [ 29.753026034482758, -1.498211876923077 ], [ 29.753026034482758, -1.4979424 ], [ 29.75275655172414, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4724, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.4979424 ], [ 29.753565, -1.498211876923077 ], [ 29.75383448275862, -1.498211876923077 ], [ 29.75383448275862, -1.4979424 ], [ 29.753565, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4725, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.4979424 ], [ 29.75383448275862, -1.498211876923077 ], [ 29.754103965517242, -1.498211876923077 ], [ 29.754103965517242, -1.4979424 ], [ 29.75383448275862, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4726, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.4979424 ], [ 29.754103965517242, -1.498481353846154 ], [ 29.754373448275864, -1.498481353846154 ], [ 29.754373448275864, -1.4979424 ], [ 29.754103965517242, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4727, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.4979424 ], [ 29.755181896551726, -1.498211876923077 ], [ 29.755451379310344, -1.498211876923077 ], [ 29.755451379310344, -1.4979424 ], [ 29.755181896551726, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4728, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.4979424 ], [ 29.755451379310344, -1.498211876923077 ], [ 29.755720862068966, -1.498211876923077 ], [ 29.755720862068966, -1.4979424 ], [ 29.755451379310344, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4729, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.4979424 ], [ 29.755720862068966, -1.498211876923077 ], [ 29.755990344827588, -1.498211876923077 ], [ 29.755990344827588, -1.4979424 ], [ 29.755720862068966, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4730, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.4979424 ], [ 29.755990344827588, -1.498211876923077 ], [ 29.756259827586206, -1.498211876923077 ], [ 29.756259827586206, -1.4979424 ], [ 29.755990344827588, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4731, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.4979424 ], [ 29.756259827586206, -1.498211876923077 ], [ 29.756798793103449, -1.498211876923077 ], [ 29.756798793103449, -1.4979424 ], [ 29.756259827586206, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4732, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.4979424 ], [ 29.756798793103449, -1.498211876923077 ], [ 29.757068275862068, -1.498211876923077 ], [ 29.757068275862068, -1.4979424 ], [ 29.756798793103449, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4733, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.4979424 ], [ 29.757068275862068, -1.498211876923077 ], [ 29.75733775862069, -1.498211876923077 ], [ 29.75733775862069, -1.4979424 ], [ 29.757068275862068, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4734, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.4979424 ], [ 29.75733775862069, -1.498211876923077 ], [ 29.757607241379311, -1.498211876923077 ], [ 29.757607241379311, -1.4979424 ], [ 29.75733775862069, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4735, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.4979424 ], [ 29.757607241379311, -1.498211876923077 ], [ 29.757876724137933, -1.498211876923077 ], [ 29.757876724137933, -1.4979424 ], [ 29.757607241379311, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4736, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.4979424 ], [ 29.757876724137933, -1.498211876923077 ], [ 29.758146206896551, -1.498211876923077 ], [ 29.758146206896551, -1.4979424 ], [ 29.757876724137933, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4737, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.4979424 ], [ 29.758146206896551, -1.498211876923077 ], [ 29.758415689655173, -1.498211876923077 ], [ 29.758415689655173, -1.4979424 ], [ 29.758146206896551, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4738, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.4979424 ], [ 29.758415689655173, -1.498481353846154 ], [ 29.758685172413795, -1.498481353846154 ], [ 29.758685172413795, -1.4979424 ], [ 29.758415689655173, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4739, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.4979424 ], [ 29.758685172413795, -1.498481353846154 ], [ 29.758954655172413, -1.498481353846154 ], [ 29.758954655172413, -1.4979424 ], [ 29.758685172413795, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4740, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.4979424 ], [ 29.759493620689657, -1.498211876923077 ], [ 29.759763103448275, -1.498211876923077 ], [ 29.759763103448275, -1.4979424 ], [ 29.759493620689657, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4741, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.4979424 ], [ 29.759763103448275, -1.498211876923077 ], [ 29.760032586206897, -1.498211876923077 ], [ 29.760032586206897, -1.4979424 ], [ 29.759763103448275, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4742, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.4979424 ], [ 29.760032586206897, -1.498211876923077 ], [ 29.760302068965519, -1.498211876923077 ], [ 29.760302068965519, -1.4979424 ], [ 29.760032586206897, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4743, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.4979424 ], [ 29.760571551724137, -1.498211876923077 ], [ 29.760841034482759, -1.498211876923077 ], [ 29.760841034482759, -1.4979424 ], [ 29.760571551724137, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4744, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.498211876923077 ], [ 29.717993275862067, -1.498481353846154 ], [ 29.718262758620689, -1.498481353846154 ], [ 29.718262758620689, -1.498211876923077 ], [ 29.717993275862067, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4745, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.498211876923077 ], [ 29.718262758620689, -1.498481353846154 ], [ 29.718532241379311, -1.498481353846154 ], [ 29.718532241379311, -1.498211876923077 ], [ 29.718262758620689, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4746, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.498211876923077 ], [ 29.718532241379311, -1.498481353846154 ], [ 29.718801724137933, -1.498481353846154 ], [ 29.718801724137933, -1.498211876923077 ], [ 29.718532241379311, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4747, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.498211876923077 ], [ 29.718801724137933, -1.498481353846154 ], [ 29.719071206896551, -1.498481353846154 ], [ 29.719071206896551, -1.498211876923077 ], [ 29.718801724137933, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4748, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.498211876923077 ], [ 29.719071206896551, -1.498481353846154 ], [ 29.719340689655173, -1.498481353846154 ], [ 29.719340689655173, -1.498211876923077 ], [ 29.719071206896551, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4749, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.498211876923077 ], [ 29.719340689655173, -1.498481353846154 ], [ 29.719610172413795, -1.498481353846154 ], [ 29.719610172413795, -1.498211876923077 ], [ 29.719340689655173, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4750, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.498211876923077 ], [ 29.719610172413795, -1.498481353846154 ], [ 29.719879655172413, -1.498481353846154 ], [ 29.719879655172413, -1.498211876923077 ], [ 29.719610172413795, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4751, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.498211876923077 ], [ 29.719879655172413, -1.498481353846154 ], [ 29.720149137931035, -1.498481353846154 ], [ 29.720149137931035, -1.498211876923077 ], [ 29.719879655172413, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4752, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.498211876923077 ], [ 29.720957586206897, -1.498481353846154 ], [ 29.721227068965518, -1.498481353846154 ], [ 29.721227068965518, -1.498211876923077 ], [ 29.720957586206897, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4753, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.498211876923077 ], [ 29.721227068965518, -1.498481353846154 ], [ 29.721496551724137, -1.498481353846154 ], [ 29.721496551724137, -1.498211876923077 ], [ 29.721227068965518, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4754, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.498211876923077 ], [ 29.721496551724137, -1.498481353846154 ], [ 29.721766034482759, -1.498481353846154 ], [ 29.721766034482759, -1.498211876923077 ], [ 29.721496551724137, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4755, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.4979424 ], [ 29.722305, -1.4979424 ], [ 29.722305, -1.498211876923077 ], [ 29.722843965517242, -1.498211876923077 ], [ 29.722843965517242, -1.498481353846154 ], [ 29.721766034482759, -1.498481353846154 ], [ 29.721766034482759, -1.498211876923077 ], [ 29.72203551724138, -1.498211876923077 ], [ 29.72203551724138, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4756, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.498211876923077 ], [ 29.722843965517242, -1.498481353846154 ], [ 29.723113448275861, -1.498481353846154 ], [ 29.723113448275861, -1.498211876923077 ], [ 29.722843965517242, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4757, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.498211876923077 ], [ 29.723113448275861, -1.498481353846154 ], [ 29.723652413793104, -1.498481353846154 ], [ 29.723652413793104, -1.498211876923077 ], [ 29.723113448275861, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4758, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.498211876923077 ], [ 29.723652413793104, -1.498481353846154 ], [ 29.723921896551722, -1.498481353846154 ], [ 29.723921896551722, -1.498211876923077 ], [ 29.723652413793104, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4759, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.498211876923077 ], [ 29.723921896551722, -1.498481353846154 ], [ 29.724191379310344, -1.498481353846154 ], [ 29.724191379310344, -1.498211876923077 ], [ 29.723921896551722, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4760, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.498211876923077 ], [ 29.724191379310344, -1.498481353846154 ], [ 29.724460862068966, -1.498481353846154 ], [ 29.724460862068966, -1.498211876923077 ], [ 29.724191379310344, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4761, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.498211876923077 ], [ 29.724460862068966, -1.498481353846154 ], [ 29.724730344827588, -1.498481353846154 ], [ 29.724730344827588, -1.498211876923077 ], [ 29.724460862068966, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4762, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.498211876923077 ], [ 29.724730344827588, -1.498481353846154 ], [ 29.724999827586206, -1.498481353846154 ], [ 29.724999827586206, -1.498211876923077 ], [ 29.724730344827588, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4763, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.498211876923077 ], [ 29.724999827586206, -1.498481353846154 ], [ 29.725269310344828, -1.498481353846154 ], [ 29.725269310344828, -1.498211876923077 ], [ 29.724999827586206, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4764, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.498211876923077 ], [ 29.725269310344828, -1.498481353846154 ], [ 29.72553879310345, -1.498481353846154 ], [ 29.72553879310345, -1.498211876923077 ], [ 29.725269310344828, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4765, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.498211876923077 ], [ 29.72553879310345, -1.498481353846154 ], [ 29.725808275862068, -1.498481353846154 ], [ 29.725808275862068, -1.498211876923077 ], [ 29.72553879310345, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4766, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.498211876923077 ], [ 29.725808275862068, -1.498481353846154 ], [ 29.72607775862069, -1.498481353846154 ], [ 29.72607775862069, -1.498211876923077 ], [ 29.725808275862068, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4767, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.498211876923077 ], [ 29.72607775862069, -1.498481353846154 ], [ 29.726347241379312, -1.498481353846154 ], [ 29.726347241379312, -1.498211876923077 ], [ 29.72607775862069, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4768, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.498211876923077 ], [ 29.726347241379312, -1.498481353846154 ], [ 29.72661672413793, -1.498481353846154 ], [ 29.72661672413793, -1.498211876923077 ], [ 29.726347241379312, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4769, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.498211876923077 ], [ 29.72661672413793, -1.498481353846154 ], [ 29.726886206896552, -1.498481353846154 ], [ 29.726886206896552, -1.498211876923077 ], [ 29.72661672413793, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4770, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.498211876923077 ], [ 29.726886206896552, -1.498481353846154 ], [ 29.727155689655174, -1.498481353846154 ], [ 29.727155689655174, -1.498211876923077 ], [ 29.726886206896552, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4771, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.498211876923077 ], [ 29.727155689655174, -1.498481353846154 ], [ 29.727425172413792, -1.498481353846154 ], [ 29.727425172413792, -1.498211876923077 ], [ 29.727155689655174, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4772, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.498211876923077 ], [ 29.727425172413792, -1.498481353846154 ], [ 29.727694655172414, -1.498481353846154 ], [ 29.727694655172414, -1.498211876923077 ], [ 29.727425172413792, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4773, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.498211876923077 ], [ 29.727694655172414, -1.498481353846154 ], [ 29.727964137931036, -1.498481353846154 ], [ 29.727964137931036, -1.498211876923077 ], [ 29.727694655172414, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4774, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.498211876923077 ], [ 29.727964137931036, -1.498481353846154 ], [ 29.728233620689654, -1.498481353846154 ], [ 29.728233620689654, -1.498211876923077 ], [ 29.727964137931036, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4775, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.498211876923077 ], [ 29.728233620689654, -1.498481353846154 ], [ 29.728503103448276, -1.498481353846154 ], [ 29.728503103448276, -1.498211876923077 ], [ 29.728233620689654, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4776, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.498211876923077 ], [ 29.728503103448276, -1.498481353846154 ], [ 29.728772586206897, -1.498481353846154 ], [ 29.728772586206897, -1.498211876923077 ], [ 29.728503103448276, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4777, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.498211876923077 ], [ 29.728772586206897, -1.498481353846154 ], [ 29.729042068965516, -1.498481353846154 ], [ 29.729042068965516, -1.498211876923077 ], [ 29.728772586206897, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4778, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.498211876923077 ], [ 29.734162241379309, -1.498481353846154 ], [ 29.734431724137931, -1.498481353846154 ], [ 29.734431724137931, -1.498211876923077 ], [ 29.734162241379309, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4779, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.498211876923077 ], [ 29.734970689655171, -1.498481353846154 ], [ 29.735240172413793, -1.498481353846154 ], [ 29.735240172413793, -1.498211876923077 ], [ 29.734970689655171, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4780, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.498211876923077 ], [ 29.736857068965517, -1.498481353846154 ], [ 29.737126551724138, -1.498481353846154 ], [ 29.737126551724138, -1.498211876923077 ], [ 29.736857068965517, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4781, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.498211876923077 ], [ 29.737126551724138, -1.498481353846154 ], [ 29.73739603448276, -1.498481353846154 ], [ 29.73739603448276, -1.498211876923077 ], [ 29.737126551724138, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4782, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.498211876923077 ], [ 29.73739603448276, -1.498481353846154 ], [ 29.737665517241378, -1.498481353846154 ], [ 29.737665517241378, -1.498211876923077 ], [ 29.73739603448276, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4783, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.498211876923077 ], [ 29.737665517241378, -1.498481353846154 ], [ 29.737935, -1.498481353846154 ], [ 29.737935, -1.498211876923077 ], [ 29.737665517241378, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4784, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.498211876923077 ], [ 29.737935, -1.498481353846154 ], [ 29.738204482758622, -1.498481353846154 ], [ 29.738204482758622, -1.498211876923077 ], [ 29.737935, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4785, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.498211876923077 ], [ 29.738204482758622, -1.498481353846154 ], [ 29.738743448275862, -1.498481353846154 ], [ 29.738743448275862, -1.498211876923077 ], [ 29.738204482758622, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4786, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.498211876923077 ], [ 29.738743448275862, -1.498481353846154 ], [ 29.739012931034484, -1.498481353846154 ], [ 29.739012931034484, -1.498211876923077 ], [ 29.738743448275862, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4787, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.498211876923077 ], [ 29.739012931034484, -1.498481353846154 ], [ 29.739282413793102, -1.498481353846154 ], [ 29.739282413793102, -1.498211876923077 ], [ 29.739012931034484, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4788, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.498211876923077 ], [ 29.739282413793102, -1.498481353846154 ], [ 29.739821379310346, -1.498481353846154 ], [ 29.739821379310346, -1.498211876923077 ], [ 29.739282413793102, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4789, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.498211876923077 ], [ 29.74089931034483, -1.498481353846154 ], [ 29.741168793103448, -1.498481353846154 ], [ 29.741168793103448, -1.498211876923077 ], [ 29.74089931034483, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4790, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.498211876923077 ], [ 29.74925327586207, -1.498481353846154 ], [ 29.749522758620689, -1.498481353846154 ], [ 29.749522758620689, -1.498211876923077 ], [ 29.74925327586207, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4791, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.498211876923077 ], [ 29.749522758620689, -1.498481353846154 ], [ 29.749792241379311, -1.498481353846154 ], [ 29.749792241379311, -1.498211876923077 ], [ 29.749522758620689, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4792, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.498211876923077 ], [ 29.749792241379311, -1.498481353846154 ], [ 29.750061724137932, -1.498481353846154 ], [ 29.750061724137932, -1.498211876923077 ], [ 29.749792241379311, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4793, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.498211876923077 ], [ 29.750061724137932, -1.498481353846154 ], [ 29.750331206896551, -1.498481353846154 ], [ 29.750331206896551, -1.498211876923077 ], [ 29.750061724137932, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4794, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.498211876923077 ], [ 29.751139655172413, -1.498481353846154 ], [ 29.751409137931034, -1.498481353846154 ], [ 29.751409137931034, -1.498211876923077 ], [ 29.751139655172413, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4795, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.498211876923077 ], [ 29.751409137931034, -1.498481353846154 ], [ 29.751678620689656, -1.498481353846154 ], [ 29.751678620689656, -1.498211876923077 ], [ 29.751409137931034, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4796, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.498211876923077 ], [ 29.751678620689656, -1.498481353846154 ], [ 29.751948103448278, -1.498481353846154 ], [ 29.751948103448278, -1.498211876923077 ], [ 29.751678620689656, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4797, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.498211876923077 ], [ 29.751948103448278, -1.498481353846154 ], [ 29.752217586206896, -1.498481353846154 ], [ 29.752217586206896, -1.498211876923077 ], [ 29.751948103448278, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4798, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.498211876923077 ], [ 29.752217586206896, -1.498481353846154 ], [ 29.752487068965518, -1.498481353846154 ], [ 29.752487068965518, -1.498211876923077 ], [ 29.752217586206896, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4799, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.498211876923077 ], [ 29.753565, -1.498481353846154 ], [ 29.75383448275862, -1.498481353846154 ], [ 29.75383448275862, -1.498211876923077 ], [ 29.753565, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4800, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.498211876923077 ], [ 29.75383448275862, -1.498481353846154 ], [ 29.754103965517242, -1.498481353846154 ], [ 29.754103965517242, -1.498211876923077 ], [ 29.75383448275862, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4801, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.498211876923077 ], [ 29.754912413793104, -1.498481353846154 ], [ 29.755181896551726, -1.498481353846154 ], [ 29.755181896551726, -1.498211876923077 ], [ 29.754912413793104, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4802, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.498211876923077 ], [ 29.755181896551726, -1.498481353846154 ], [ 29.755451379310344, -1.498481353846154 ], [ 29.755451379310344, -1.498211876923077 ], [ 29.755181896551726, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4803, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.498211876923077 ], [ 29.755451379310344, -1.498481353846154 ], [ 29.755720862068966, -1.498481353846154 ], [ 29.755720862068966, -1.498211876923077 ], [ 29.755451379310344, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4804, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.498211876923077 ], [ 29.755720862068966, -1.498481353846154 ], [ 29.755990344827588, -1.498481353846154 ], [ 29.755990344827588, -1.498211876923077 ], [ 29.755720862068966, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4805, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.498211876923077 ], [ 29.755990344827588, -1.498481353846154 ], [ 29.756259827586206, -1.498481353846154 ], [ 29.756259827586206, -1.498211876923077 ], [ 29.755990344827588, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4806, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.498211876923077 ], [ 29.756259827586206, -1.498481353846154 ], [ 29.756798793103449, -1.498481353846154 ], [ 29.756798793103449, -1.498211876923077 ], [ 29.756259827586206, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4807, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.498211876923077 ], [ 29.756798793103449, -1.498481353846154 ], [ 29.757068275862068, -1.498481353846154 ], [ 29.757068275862068, -1.498211876923077 ], [ 29.756798793103449, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4808, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.498211876923077 ], [ 29.757068275862068, -1.498481353846154 ], [ 29.75733775862069, -1.498481353846154 ], [ 29.75733775862069, -1.498211876923077 ], [ 29.757068275862068, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4809, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.498211876923077 ], [ 29.75733775862069, -1.498481353846154 ], [ 29.757607241379311, -1.498481353846154 ], [ 29.757607241379311, -1.498211876923077 ], [ 29.75733775862069, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4810, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.498211876923077 ], [ 29.757607241379311, -1.498481353846154 ], [ 29.757876724137933, -1.498481353846154 ], [ 29.757876724137933, -1.498211876923077 ], [ 29.757607241379311, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4811, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.498211876923077 ], [ 29.757876724137933, -1.498481353846154 ], [ 29.758146206896551, -1.498481353846154 ], [ 29.758146206896551, -1.498211876923077 ], [ 29.757876724137933, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4812, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.498211876923077 ], [ 29.758954655172413, -1.498481353846154 ], [ 29.759224137931035, -1.498481353846154 ], [ 29.759224137931035, -1.498211876923077 ], [ 29.758954655172413, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4813, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.498211876923077 ], [ 29.759224137931035, -1.498481353846154 ], [ 29.759493620689657, -1.498481353846154 ], [ 29.759493620689657, -1.498211876923077 ], [ 29.759224137931035, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4814, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.498211876923077 ], [ 29.759493620689657, -1.498481353846154 ], [ 29.759763103448275, -1.498481353846154 ], [ 29.759763103448275, -1.498211876923077 ], [ 29.759493620689657, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4815, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.498211876923077 ], [ 29.760571551724137, -1.498481353846154 ], [ 29.760841034482759, -1.498481353846154 ], [ 29.760841034482759, -1.498211876923077 ], [ 29.760571551724137, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4816, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.497133969230769 ], [ 29.717184827586205, -1.499020307692308 ], [ 29.717454310344827, -1.499020307692308 ], [ 29.717454310344827, -1.497133969230769 ], [ 29.717184827586205, -1.497133969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4817, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748444827586209, -1.497403446153846 ], [ 29.748444827586209, -1.499020307692308 ], [ 29.748714310344827, -1.499020307692308 ], [ 29.748714310344827, -1.497403446153846 ], [ 29.748444827586209, -1.497403446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4818, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.497672923076923 ], [ 29.730928448275861, -1.499559261538461 ], [ 29.731197931034483, -1.499559261538461 ], [ 29.731197931034483, -1.497672923076923 ], [ 29.730928448275861, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4819, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.497672923076923 ], [ 29.736048620689655, -1.499289784615385 ], [ 29.736318103448276, -1.499289784615385 ], [ 29.736318103448276, -1.497672923076923 ], [ 29.736048620689655, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4820, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.497672923076923 ], [ 29.736318103448276, -1.499289784615385 ], [ 29.736587586206898, -1.499289784615385 ], [ 29.736587586206898, -1.497672923076923 ], [ 29.736318103448276, -1.497672923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4821, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.4979424 ], [ 29.730389482758621, -1.499828738461538 ], [ 29.730658965517243, -1.499828738461538 ], [ 29.730658965517243, -1.4979424 ], [ 29.730389482758621, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4822, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.4979424 ], [ 29.730658965517243, -1.499828738461538 ], [ 29.730928448275861, -1.499828738461538 ], [ 29.730928448275861, -1.4979424 ], [ 29.730658965517243, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4823, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.4979424 ], [ 29.734431724137931, -1.499020307692308 ], [ 29.734701206896553, -1.499020307692308 ], [ 29.734701206896553, -1.4979424 ], [ 29.734431724137931, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4824, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.4979424 ], [ 29.734701206896553, -1.499020307692308 ], [ 29.734970689655171, -1.499020307692308 ], [ 29.734970689655171, -1.4979424 ], [ 29.734701206896553, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4825, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.4979424 ], [ 29.735240172413793, -1.499020307692308 ], [ 29.735509655172415, -1.499020307692308 ], [ 29.735509655172415, -1.4979424 ], [ 29.735240172413793, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4826, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.4979424 ], [ 29.735509655172415, -1.499020307692308 ], [ 29.735779137931036, -1.499020307692308 ], [ 29.735779137931036, -1.4979424 ], [ 29.735509655172415, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4827, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.4979424 ], [ 29.735779137931036, -1.499020307692308 ], [ 29.736048620689655, -1.499020307692308 ], [ 29.736048620689655, -1.4979424 ], [ 29.735779137931036, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4828, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.4979424 ], [ 29.736587586206898, -1.499020307692308 ], [ 29.736857068965517, -1.499020307692308 ], [ 29.736857068965517, -1.4979424 ], [ 29.736587586206898, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4829, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.4979424 ], [ 29.739551896551724, -1.498211876923077 ], [ 29.740360344827586, -1.498211876923077 ], [ 29.740360344827586, -1.499289784615385 ], [ 29.740629827586208, -1.499289784615385 ], [ 29.740629827586208, -1.4979424 ], [ 29.739551896551724, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4830, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.4979424 ], [ 29.740629827586208, -1.499289784615385 ], [ 29.74089931034483, -1.499289784615385 ], [ 29.74089931034483, -1.498211876923077 ], [ 29.741168793103448, -1.498211876923077 ], [ 29.741168793103448, -1.4979424 ], [ 29.740629827586208, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4831, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.4979424 ], [ 29.75329551724138, -1.499020307692308 ], [ 29.753565, -1.499020307692308 ], [ 29.753565, -1.4979424 ], [ 29.75329551724138, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4832, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.4979424 ], [ 29.760841034482759, -1.499020307692308 ], [ 29.761110517241381, -1.499020307692308 ], [ 29.761110517241381, -1.4979424 ], [ 29.760841034482759, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4833, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.4979424 ], [ 29.761110517241381, -1.499020307692308 ], [ 29.76138, -1.499020307692308 ], [ 29.76138, -1.4979424 ], [ 29.761110517241381, -1.4979424 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4834, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.498211876923077 ], [ 29.729042068965516, -1.499828738461538 ], [ 29.729311551724138, -1.499828738461538 ], [ 29.729311551724138, -1.498211876923077 ], [ 29.729042068965516, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4835, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.498211876923077 ], [ 29.729311551724138, -1.499828738461538 ], [ 29.729850517241381, -1.499828738461538 ], [ 29.729850517241381, -1.498211876923077 ], [ 29.729311551724138, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4836, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.498211876923077 ], [ 29.729850517241381, -1.499828738461538 ], [ 29.73012, -1.499828738461538 ], [ 29.73012, -1.498211876923077 ], [ 29.729850517241381, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4837, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.498211876923077 ], [ 29.73012, -1.499559261538461 ], [ 29.730389482758621, -1.499559261538461 ], [ 29.730389482758621, -1.498211876923077 ], [ 29.73012, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4838, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.498211876923077 ], [ 29.739821379310346, -1.498481353846154 ], [ 29.740090862068964, -1.498481353846154 ], [ 29.740090862068964, -1.499020307692308 ], [ 29.740360344827586, -1.499020307692308 ], [ 29.740360344827586, -1.498211876923077 ], [ 29.739821379310346, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4839, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.498211876923077 ], [ 29.752487068965518, -1.499020307692308 ], [ 29.75275655172414, -1.499020307692308 ], [ 29.75275655172414, -1.498211876923077 ], [ 29.752487068965518, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4840, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.498211876923077 ], [ 29.75275655172414, -1.499020307692308 ], [ 29.753026034482758, -1.499020307692308 ], [ 29.753026034482758, -1.498211876923077 ], [ 29.75275655172414, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4841, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.498211876923077 ], [ 29.753026034482758, -1.499020307692308 ], [ 29.75329551724138, -1.499020307692308 ], [ 29.75329551724138, -1.498211876923077 ], [ 29.753026034482758, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4842, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.498211876923077 ], [ 29.758146206896551, -1.499020307692308 ], [ 29.758415689655173, -1.499020307692308 ], [ 29.758415689655173, -1.498211876923077 ], [ 29.758146206896551, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4843, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.498211876923077 ], [ 29.759763103448275, -1.499020307692308 ], [ 29.760032586206897, -1.499020307692308 ], [ 29.760032586206897, -1.498211876923077 ], [ 29.759763103448275, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4844, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.498211876923077 ], [ 29.760032586206897, -1.499020307692308 ], [ 29.760302068965519, -1.499020307692308 ], [ 29.760302068965519, -1.498211876923077 ], [ 29.760032586206897, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4845, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.498211876923077 ], [ 29.760302068965519, -1.499020307692308 ], [ 29.760571551724137, -1.499020307692308 ], [ 29.760571551724137, -1.498211876923077 ], [ 29.760302068965519, -1.498211876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4846, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.498481353846154 ], [ 29.717454310344827, -1.499020307692308 ], [ 29.717723793103449, -1.499020307692308 ], [ 29.717723793103449, -1.498481353846154 ], [ 29.717454310344827, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4847, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.498481353846154 ], [ 29.717723793103449, -1.499020307692308 ], [ 29.717993275862067, -1.499020307692308 ], [ 29.717993275862067, -1.498481353846154 ], [ 29.717723793103449, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4848, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.498481353846154 ], [ 29.717993275862067, -1.499020307692308 ], [ 29.718262758620689, -1.499020307692308 ], [ 29.718262758620689, -1.498481353846154 ], [ 29.717993275862067, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4849, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.498481353846154 ], [ 29.718262758620689, -1.499020307692308 ], [ 29.718532241379311, -1.499020307692308 ], [ 29.718532241379311, -1.498481353846154 ], [ 29.718262758620689, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4850, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.498481353846154 ], [ 29.718532241379311, -1.499020307692308 ], [ 29.718801724137933, -1.499020307692308 ], [ 29.718801724137933, -1.498481353846154 ], [ 29.718532241379311, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4851, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.498481353846154 ], [ 29.718801724137933, -1.499020307692308 ], [ 29.719071206896551, -1.499020307692308 ], [ 29.719071206896551, -1.498481353846154 ], [ 29.718801724137933, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4852, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.498481353846154 ], [ 29.719071206896551, -1.499020307692308 ], [ 29.719340689655173, -1.499020307692308 ], [ 29.719340689655173, -1.498481353846154 ], [ 29.719071206896551, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4853, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.498481353846154 ], [ 29.719340689655173, -1.499020307692308 ], [ 29.719610172413795, -1.499020307692308 ], [ 29.719610172413795, -1.498481353846154 ], [ 29.719340689655173, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4854, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.498481353846154 ], [ 29.719610172413795, -1.499020307692308 ], [ 29.719879655172413, -1.499020307692308 ], [ 29.719879655172413, -1.498481353846154 ], [ 29.719610172413795, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4855, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.498481353846154 ], [ 29.719879655172413, -1.499020307692308 ], [ 29.720149137931035, -1.499020307692308 ], [ 29.720149137931035, -1.498481353846154 ], [ 29.719879655172413, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4856, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.498481353846154 ], [ 29.720149137931035, -1.499020307692308 ], [ 29.720688103448275, -1.499020307692308 ], [ 29.720688103448275, -1.498481353846154 ], [ 29.720149137931035, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4857, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.498481353846154 ], [ 29.720688103448275, -1.499020307692308 ], [ 29.720957586206897, -1.499020307692308 ], [ 29.720957586206897, -1.498481353846154 ], [ 29.720688103448275, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4858, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.498481353846154 ], [ 29.720957586206897, -1.499020307692308 ], [ 29.721227068965518, -1.499020307692308 ], [ 29.721227068965518, -1.498481353846154 ], [ 29.720957586206897, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4859, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.498481353846154 ], [ 29.721227068965518, -1.499020307692308 ], [ 29.721496551724137, -1.499020307692308 ], [ 29.721496551724137, -1.498481353846154 ], [ 29.721227068965518, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4860, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.498481353846154 ], [ 29.721496551724137, -1.499020307692308 ], [ 29.722305, -1.499020307692308 ], [ 29.722305, -1.498481353846154 ], [ 29.721496551724137, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4861, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.498481353846154 ], [ 29.722305, -1.499020307692308 ], [ 29.722843965517242, -1.499020307692308 ], [ 29.722843965517242, -1.498481353846154 ], [ 29.722305, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4862, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.498481353846154 ], [ 29.722843965517242, -1.499020307692308 ], [ 29.723382931034482, -1.499020307692308 ], [ 29.723382931034482, -1.498481353846154 ], [ 29.722843965517242, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4863, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.498481353846154 ], [ 29.723382931034482, -1.499020307692308 ], [ 29.723652413793104, -1.499020307692308 ], [ 29.723652413793104, -1.498481353846154 ], [ 29.723382931034482, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4864, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.498481353846154 ], [ 29.723652413793104, -1.499020307692308 ], [ 29.723921896551722, -1.499020307692308 ], [ 29.723921896551722, -1.498481353846154 ], [ 29.723652413793104, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4865, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.498481353846154 ], [ 29.723921896551722, -1.499020307692308 ], [ 29.724191379310344, -1.499020307692308 ], [ 29.724191379310344, -1.498481353846154 ], [ 29.723921896551722, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4866, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.498481353846154 ], [ 29.724191379310344, -1.499020307692308 ], [ 29.724460862068966, -1.499020307692308 ], [ 29.724460862068966, -1.498481353846154 ], [ 29.724191379310344, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4867, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.498481353846154 ], [ 29.724460862068966, -1.499020307692308 ], [ 29.724730344827588, -1.499020307692308 ], [ 29.724730344827588, -1.498481353846154 ], [ 29.724460862068966, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4868, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.498481353846154 ], [ 29.724730344827588, -1.499020307692308 ], [ 29.724999827586206, -1.499020307692308 ], [ 29.724999827586206, -1.498481353846154 ], [ 29.724730344827588, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4869, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.498481353846154 ], [ 29.724999827586206, -1.499020307692308 ], [ 29.725269310344828, -1.499020307692308 ], [ 29.725269310344828, -1.498481353846154 ], [ 29.724999827586206, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4870, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.498481353846154 ], [ 29.725269310344828, -1.499020307692308 ], [ 29.72553879310345, -1.499020307692308 ], [ 29.72553879310345, -1.499289784615385 ], [ 29.725808275862068, -1.499289784615385 ], [ 29.725808275862068, -1.498481353846154 ], [ 29.725269310344828, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4871, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.498481353846154 ], [ 29.725808275862068, -1.499020307692308 ], [ 29.72607775862069, -1.499020307692308 ], [ 29.72607775862069, -1.498481353846154 ], [ 29.725808275862068, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4872, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.498481353846154 ], [ 29.72607775862069, -1.499020307692308 ], [ 29.726347241379312, -1.499020307692308 ], [ 29.726347241379312, -1.498481353846154 ], [ 29.72607775862069, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4873, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.498481353846154 ], [ 29.726347241379312, -1.499020307692308 ], [ 29.72661672413793, -1.499020307692308 ], [ 29.72661672413793, -1.498481353846154 ], [ 29.726347241379312, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4874, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.498481353846154 ], [ 29.72661672413793, -1.499020307692308 ], [ 29.726886206896552, -1.499020307692308 ], [ 29.726886206896552, -1.498481353846154 ], [ 29.72661672413793, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4875, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.498481353846154 ], [ 29.726886206896552, -1.499020307692308 ], [ 29.727155689655174, -1.499020307692308 ], [ 29.727155689655174, -1.498481353846154 ], [ 29.726886206896552, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4876, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.498481353846154 ], [ 29.727155689655174, -1.499020307692308 ], [ 29.727425172413792, -1.499020307692308 ], [ 29.727425172413792, -1.498481353846154 ], [ 29.727155689655174, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4877, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.498481353846154 ], [ 29.727425172413792, -1.499020307692308 ], [ 29.727694655172414, -1.499020307692308 ], [ 29.727694655172414, -1.498481353846154 ], [ 29.727425172413792, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4878, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.498481353846154 ], [ 29.727694655172414, -1.499020307692308 ], [ 29.727964137931036, -1.499020307692308 ], [ 29.727964137931036, -1.498481353846154 ], [ 29.727694655172414, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4879, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.498481353846154 ], [ 29.727964137931036, -1.499020307692308 ], [ 29.728233620689654, -1.499020307692308 ], [ 29.728233620689654, -1.498481353846154 ], [ 29.727964137931036, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4880, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.498481353846154 ], [ 29.728233620689654, -1.499828738461538 ], [ 29.728503103448276, -1.499828738461538 ], [ 29.728503103448276, -1.498481353846154 ], [ 29.728233620689654, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4881, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.498481353846154 ], [ 29.728503103448276, -1.500098215384615 ], [ 29.728772586206897, -1.500098215384615 ], [ 29.728772586206897, -1.498481353846154 ], [ 29.728503103448276, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4882, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.498481353846154 ], [ 29.728772586206897, -1.500098215384615 ], [ 29.729042068965516, -1.500098215384615 ], [ 29.729042068965516, -1.498481353846154 ], [ 29.728772586206897, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4883, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.498481353846154 ], [ 29.733623275862069, -1.499020307692308 ], [ 29.734162241379309, -1.499020307692308 ], [ 29.734162241379309, -1.498481353846154 ], [ 29.733623275862069, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4884, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.498481353846154 ], [ 29.734162241379309, -1.499020307692308 ], [ 29.734431724137931, -1.499020307692308 ], [ 29.734431724137931, -1.498481353846154 ], [ 29.734162241379309, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4885, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.498481353846154 ], [ 29.734970689655171, -1.499289784615385 ], [ 29.735240172413793, -1.499289784615385 ], [ 29.735240172413793, -1.498481353846154 ], [ 29.734970689655171, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4886, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.498481353846154 ], [ 29.736857068965517, -1.499559261538461 ], [ 29.73739603448276, -1.499559261538461 ], [ 29.73739603448276, -1.499289784615385 ], [ 29.737126551724138, -1.499289784615385 ], [ 29.737126551724138, -1.498481353846154 ], [ 29.736857068965517, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4887, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.498481353846154 ], [ 29.737126551724138, -1.499020307692308 ], [ 29.73739603448276, -1.499020307692308 ], [ 29.73739603448276, -1.498481353846154 ], [ 29.737126551724138, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4888, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.498481353846154 ], [ 29.73739603448276, -1.499020307692308 ], [ 29.737665517241378, -1.499020307692308 ], [ 29.737665517241378, -1.498481353846154 ], [ 29.73739603448276, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4889, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.498481353846154 ], [ 29.737665517241378, -1.499020307692308 ], [ 29.737935, -1.499020307692308 ], [ 29.737935, -1.498481353846154 ], [ 29.737665517241378, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4890, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.498481353846154 ], [ 29.737935, -1.499020307692308 ], [ 29.738204482758622, -1.499020307692308 ], [ 29.738204482758622, -1.498481353846154 ], [ 29.737935, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4891, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.498481353846154 ], [ 29.738204482758622, -1.499020307692308 ], [ 29.738743448275862, -1.499020307692308 ], [ 29.738743448275862, -1.498481353846154 ], [ 29.738204482758622, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4892, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.498481353846154 ], [ 29.738743448275862, -1.499020307692308 ], [ 29.739012931034484, -1.499020307692308 ], [ 29.739012931034484, -1.498481353846154 ], [ 29.738743448275862, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4893, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.498481353846154 ], [ 29.739012931034484, -1.499020307692308 ], [ 29.739282413793102, -1.499020307692308 ], [ 29.739282413793102, -1.498481353846154 ], [ 29.739012931034484, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4894, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.498481353846154 ], [ 29.739282413793102, -1.499020307692308 ], [ 29.739551896551724, -1.499020307692308 ], [ 29.739551896551724, -1.498481353846154 ], [ 29.739282413793102, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4895, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.498481353846154 ], [ 29.739551896551724, -1.499020307692308 ], [ 29.740090862068964, -1.499020307692308 ], [ 29.740090862068964, -1.498481353846154 ], [ 29.739551896551724, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4896, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.498481353846154 ], [ 29.74089931034483, -1.499559261538461 ], [ 29.741168793103448, -1.499559261538461 ], [ 29.741168793103448, -1.498481353846154 ], [ 29.74089931034483, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4897, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.498481353846154 ], [ 29.748714310344827, -1.499020307692308 ], [ 29.748983793103449, -1.499020307692308 ], [ 29.748983793103449, -1.498481353846154 ], [ 29.748714310344827, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4898, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.498481353846154 ], [ 29.748983793103449, -1.499020307692308 ], [ 29.74925327586207, -1.499020307692308 ], [ 29.74925327586207, -1.498481353846154 ], [ 29.748983793103449, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4899, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.498481353846154 ], [ 29.74925327586207, -1.499020307692308 ], [ 29.749522758620689, -1.499020307692308 ], [ 29.749522758620689, -1.498481353846154 ], [ 29.74925327586207, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4900, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.498481353846154 ], [ 29.749522758620689, -1.499020307692308 ], [ 29.749792241379311, -1.499020307692308 ], [ 29.749792241379311, -1.498481353846154 ], [ 29.749522758620689, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4901, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.498481353846154 ], [ 29.749792241379311, -1.499289784615385 ], [ 29.750061724137932, -1.499289784615385 ], [ 29.750061724137932, -1.498481353846154 ], [ 29.749792241379311, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4902, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.498481353846154 ], [ 29.750061724137932, -1.499020307692308 ], [ 29.750331206896551, -1.499020307692308 ], [ 29.750331206896551, -1.498481353846154 ], [ 29.750061724137932, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4903, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.498481353846154 ], [ 29.750331206896551, -1.499020307692308 ], [ 29.750600689655172, -1.499020307692308 ], [ 29.750600689655172, -1.498481353846154 ], [ 29.750331206896551, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4904, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.498481353846154 ], [ 29.750600689655172, -1.499020307692308 ], [ 29.750870172413794, -1.499020307692308 ], [ 29.750870172413794, -1.498481353846154 ], [ 29.750600689655172, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4905, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.498481353846154 ], [ 29.750870172413794, -1.499020307692308 ], [ 29.751139655172413, -1.499020307692308 ], [ 29.751139655172413, -1.498481353846154 ], [ 29.750870172413794, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4906, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.498481353846154 ], [ 29.751139655172413, -1.499020307692308 ], [ 29.751409137931034, -1.499020307692308 ], [ 29.751409137931034, -1.498481353846154 ], [ 29.751139655172413, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4907, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.498481353846154 ], [ 29.751409137931034, -1.499020307692308 ], [ 29.751678620689656, -1.499020307692308 ], [ 29.751678620689656, -1.498481353846154 ], [ 29.751409137931034, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4908, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.498481353846154 ], [ 29.751678620689656, -1.499020307692308 ], [ 29.751948103448278, -1.499020307692308 ], [ 29.751948103448278, -1.498481353846154 ], [ 29.751678620689656, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4909, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.498481353846154 ], [ 29.751948103448278, -1.499020307692308 ], [ 29.752217586206896, -1.499020307692308 ], [ 29.752217586206896, -1.498481353846154 ], [ 29.751948103448278, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4910, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.498481353846154 ], [ 29.752217586206896, -1.499289784615385 ], [ 29.752487068965518, -1.499289784615385 ], [ 29.752487068965518, -1.498481353846154 ], [ 29.752217586206896, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4911, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.498481353846154 ], [ 29.753565, -1.499020307692308 ], [ 29.75383448275862, -1.499020307692308 ], [ 29.75383448275862, -1.498481353846154 ], [ 29.753565, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4912, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.498481353846154 ], [ 29.75383448275862, -1.499020307692308 ], [ 29.754103965517242, -1.499020307692308 ], [ 29.754103965517242, -1.498481353846154 ], [ 29.75383448275862, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4913, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.498481353846154 ], [ 29.754103965517242, -1.499020307692308 ], [ 29.754373448275864, -1.499020307692308 ], [ 29.754373448275864, -1.498481353846154 ], [ 29.754103965517242, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4914, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.498481353846154 ], [ 29.754373448275864, -1.499020307692308 ], [ 29.754642931034482, -1.499020307692308 ], [ 29.754642931034482, -1.498481353846154 ], [ 29.754373448275864, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4915, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.498481353846154 ], [ 29.754642931034482, -1.499020307692308 ], [ 29.754912413793104, -1.499020307692308 ], [ 29.754912413793104, -1.498481353846154 ], [ 29.754642931034482, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4916, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.498481353846154 ], [ 29.754912413793104, -1.499020307692308 ], [ 29.755181896551726, -1.499020307692308 ], [ 29.755181896551726, -1.499289784615385 ], [ 29.755451379310344, -1.499289784615385 ], [ 29.755451379310344, -1.499559261538461 ], [ 29.755990344827588, -1.499559261538461 ], [ 29.755990344827588, -1.499289784615385 ], [ 29.755720862068966, -1.499289784615385 ], [ 29.755720862068966, -1.499020307692308 ], [ 29.755451379310344, -1.499020307692308 ], [ 29.755451379310344, -1.498481353846154 ], [ 29.754912413793104, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4917, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.498481353846154 ], [ 29.755451379310344, -1.499020307692308 ], [ 29.755720862068966, -1.499020307692308 ], [ 29.755720862068966, -1.498481353846154 ], [ 29.755451379310344, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4918, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.498481353846154 ], [ 29.755720862068966, -1.499020307692308 ], [ 29.755990344827588, -1.499020307692308 ], [ 29.755990344827588, -1.498481353846154 ], [ 29.755720862068966, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4919, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.498481353846154 ], [ 29.755990344827588, -1.499020307692308 ], [ 29.756259827586206, -1.499020307692308 ], [ 29.756259827586206, -1.498481353846154 ], [ 29.755990344827588, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4920, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.498481353846154 ], [ 29.756259827586206, -1.499020307692308 ], [ 29.756798793103449, -1.499020307692308 ], [ 29.756798793103449, -1.498481353846154 ], [ 29.756259827586206, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4921, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.498481353846154 ], [ 29.756798793103449, -1.499020307692308 ], [ 29.757068275862068, -1.499020307692308 ], [ 29.757068275862068, -1.498481353846154 ], [ 29.756798793103449, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4922, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.498481353846154 ], [ 29.757068275862068, -1.499020307692308 ], [ 29.75733775862069, -1.499020307692308 ], [ 29.75733775862069, -1.498481353846154 ], [ 29.757068275862068, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4923, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.498481353846154 ], [ 29.75733775862069, -1.499020307692308 ], [ 29.757607241379311, -1.499020307692308 ], [ 29.757607241379311, -1.498481353846154 ], [ 29.75733775862069, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4924, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.498481353846154 ], [ 29.757607241379311, -1.499020307692308 ], [ 29.757876724137933, -1.499020307692308 ], [ 29.757876724137933, -1.498481353846154 ], [ 29.757607241379311, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4925, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.498481353846154 ], [ 29.757876724137933, -1.499289784615385 ], [ 29.758146206896551, -1.499289784615385 ], [ 29.758146206896551, -1.498481353846154 ], [ 29.757876724137933, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4926, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.498481353846154 ], [ 29.758415689655173, -1.499020307692308 ], [ 29.758685172413795, -1.499020307692308 ], [ 29.758685172413795, -1.498481353846154 ], [ 29.758415689655173, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4927, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.498481353846154 ], [ 29.758685172413795, -1.499020307692308 ], [ 29.758954655172413, -1.499020307692308 ], [ 29.758954655172413, -1.498481353846154 ], [ 29.758685172413795, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4928, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.498481353846154 ], [ 29.758954655172413, -1.499020307692308 ], [ 29.759224137931035, -1.499020307692308 ], [ 29.759224137931035, -1.498481353846154 ], [ 29.758954655172413, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4929, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.498481353846154 ], [ 29.759224137931035, -1.499289784615385 ], [ 29.759493620689657, -1.499289784615385 ], [ 29.759493620689657, -1.498481353846154 ], [ 29.759224137931035, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4930, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.498481353846154 ], [ 29.759493620689657, -1.499289784615385 ], [ 29.759763103448275, -1.499289784615385 ], [ 29.759763103448275, -1.498481353846154 ], [ 29.759493620689657, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4931, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.498481353846154 ], [ 29.760571551724137, -1.499289784615385 ], [ 29.760841034482759, -1.499289784615385 ], [ 29.760841034482759, -1.498481353846154 ], [ 29.760571551724137, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4932, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.498481353846154 ], [ 29.76138, -1.499020307692308 ], [ 29.761649482758621, -1.499020307692308 ], [ 29.761649482758621, -1.498481353846154 ], [ 29.76138, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4933, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.498481353846154 ], [ 29.761649482758621, -1.499020307692308 ], [ 29.761918965517243, -1.499020307692308 ], [ 29.761918965517243, -1.498481353846154 ], [ 29.761649482758621, -1.498481353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4934, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.499020307692308 ], [ 29.717454310344827, -1.499289784615385 ], [ 29.717723793103449, -1.499289784615385 ], [ 29.717723793103449, -1.499020307692308 ], [ 29.717454310344827, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4935, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.499020307692308 ], [ 29.717723793103449, -1.499289784615385 ], [ 29.717993275862067, -1.499289784615385 ], [ 29.717993275862067, -1.499020307692308 ], [ 29.717723793103449, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4936, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.499020307692308 ], [ 29.717993275862067, -1.499289784615385 ], [ 29.718262758620689, -1.499289784615385 ], [ 29.718262758620689, -1.499020307692308 ], [ 29.717993275862067, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4937, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.499020307692308 ], [ 29.718262758620689, -1.499289784615385 ], [ 29.718532241379311, -1.499289784615385 ], [ 29.718532241379311, -1.499020307692308 ], [ 29.718262758620689, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4938, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.499020307692308 ], [ 29.718532241379311, -1.499289784615385 ], [ 29.718801724137933, -1.499289784615385 ], [ 29.718801724137933, -1.499020307692308 ], [ 29.718532241379311, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4939, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.499020307692308 ], [ 29.718801724137933, -1.499559261538461 ], [ 29.719071206896551, -1.499559261538461 ], [ 29.719071206896551, -1.499020307692308 ], [ 29.718801724137933, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4940, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.499020307692308 ], [ 29.719071206896551, -1.499289784615385 ], [ 29.719340689655173, -1.499289784615385 ], [ 29.719340689655173, -1.499020307692308 ], [ 29.719071206896551, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4941, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.499020307692308 ], [ 29.719340689655173, -1.499289784615385 ], [ 29.719610172413795, -1.499289784615385 ], [ 29.719610172413795, -1.499020307692308 ], [ 29.719340689655173, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4942, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.499020307692308 ], [ 29.719610172413795, -1.499289784615385 ], [ 29.719879655172413, -1.499289784615385 ], [ 29.719879655172413, -1.499020307692308 ], [ 29.719610172413795, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4943, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.499020307692308 ], [ 29.719879655172413, -1.499289784615385 ], [ 29.720149137931035, -1.499289784615385 ], [ 29.720149137931035, -1.499020307692308 ], [ 29.719879655172413, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4944, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.499020307692308 ], [ 29.720149137931035, -1.499289784615385 ], [ 29.720688103448275, -1.499289784615385 ], [ 29.720688103448275, -1.499020307692308 ], [ 29.720149137931035, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4945, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.499020307692308 ], [ 29.720688103448275, -1.499289784615385 ], [ 29.720957586206897, -1.499289784615385 ], [ 29.720957586206897, -1.499020307692308 ], [ 29.720688103448275, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4946, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.499020307692308 ], [ 29.720957586206897, -1.499289784615385 ], [ 29.721227068965518, -1.499289784615385 ], [ 29.721227068965518, -1.499020307692308 ], [ 29.720957586206897, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4947, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.499020307692308 ], [ 29.721227068965518, -1.499289784615385 ], [ 29.721496551724137, -1.499289784615385 ], [ 29.721496551724137, -1.499020307692308 ], [ 29.721227068965518, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4948, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.499020307692308 ], [ 29.721496551724137, -1.499289784615385 ], [ 29.72257448275862, -1.499289784615385 ], [ 29.72257448275862, -1.499020307692308 ], [ 29.721496551724137, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4949, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.499020307692308 ], [ 29.72257448275862, -1.499289784615385 ], [ 29.723113448275861, -1.499289784615385 ], [ 29.723113448275861, -1.499020307692308 ], [ 29.72257448275862, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4950, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.499020307692308 ], [ 29.723113448275861, -1.499289784615385 ], [ 29.723382931034482, -1.499289784615385 ], [ 29.723382931034482, -1.499020307692308 ], [ 29.723113448275861, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4951, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.499020307692308 ], [ 29.723382931034482, -1.499289784615385 ], [ 29.723652413793104, -1.499289784615385 ], [ 29.723652413793104, -1.499020307692308 ], [ 29.723382931034482, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4952, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.499020307692308 ], [ 29.723652413793104, -1.499289784615385 ], [ 29.723921896551722, -1.499289784615385 ], [ 29.723921896551722, -1.499020307692308 ], [ 29.723652413793104, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4953, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.499020307692308 ], [ 29.723921896551722, -1.499289784615385 ], [ 29.724191379310344, -1.499289784615385 ], [ 29.724191379310344, -1.499020307692308 ], [ 29.723921896551722, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4954, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.499020307692308 ], [ 29.724191379310344, -1.499289784615385 ], [ 29.724460862068966, -1.499289784615385 ], [ 29.724460862068966, -1.499020307692308 ], [ 29.724191379310344, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4955, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.499020307692308 ], [ 29.724460862068966, -1.499289784615385 ], [ 29.724730344827588, -1.499289784615385 ], [ 29.724730344827588, -1.499020307692308 ], [ 29.724460862068966, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4956, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.499020307692308 ], [ 29.724730344827588, -1.499289784615385 ], [ 29.724999827586206, -1.499289784615385 ], [ 29.724999827586206, -1.499020307692308 ], [ 29.724730344827588, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4957, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.499020307692308 ], [ 29.724999827586206, -1.499289784615385 ], [ 29.725269310344828, -1.499289784615385 ], [ 29.725269310344828, -1.499020307692308 ], [ 29.724999827586206, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4958, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.499020307692308 ], [ 29.725269310344828, -1.499289784615385 ], [ 29.72553879310345, -1.499289784615385 ], [ 29.72553879310345, -1.499020307692308 ], [ 29.725269310344828, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4959, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.499020307692308 ], [ 29.725808275862068, -1.499289784615385 ], [ 29.72607775862069, -1.499289784615385 ], [ 29.72607775862069, -1.499020307692308 ], [ 29.725808275862068, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4960, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.499020307692308 ], [ 29.72607775862069, -1.499289784615385 ], [ 29.726347241379312, -1.499289784615385 ], [ 29.726347241379312, -1.499020307692308 ], [ 29.72607775862069, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4961, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.499020307692308 ], [ 29.726347241379312, -1.499289784615385 ], [ 29.72661672413793, -1.499289784615385 ], [ 29.72661672413793, -1.499020307692308 ], [ 29.726347241379312, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4962, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.499020307692308 ], [ 29.72661672413793, -1.499289784615385 ], [ 29.726886206896552, -1.499289784615385 ], [ 29.726886206896552, -1.499020307692308 ], [ 29.72661672413793, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4963, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.499020307692308 ], [ 29.726886206896552, -1.499289784615385 ], [ 29.727155689655174, -1.499289784615385 ], [ 29.727155689655174, -1.499020307692308 ], [ 29.726886206896552, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4964, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.499020307692308 ], [ 29.727155689655174, -1.499289784615385 ], [ 29.727425172413792, -1.499289784615385 ], [ 29.727425172413792, -1.499020307692308 ], [ 29.727155689655174, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4965, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.499020307692308 ], [ 29.727425172413792, -1.499828738461538 ], [ 29.727694655172414, -1.499828738461538 ], [ 29.727694655172414, -1.499020307692308 ], [ 29.727425172413792, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4966, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.499020307692308 ], [ 29.727694655172414, -1.500098215384615 ], [ 29.727964137931036, -1.500098215384615 ], [ 29.727964137931036, -1.499020307692308 ], [ 29.727694655172414, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4967, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.499020307692308 ], [ 29.727964137931036, -1.500367692307692 ], [ 29.728233620689654, -1.500367692307692 ], [ 29.728233620689654, -1.499020307692308 ], [ 29.727964137931036, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4968, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.499020307692308 ], [ 29.732814827586207, -1.499289784615385 ], [ 29.733623275862069, -1.499289784615385 ], [ 29.733623275862069, -1.499020307692308 ], [ 29.732814827586207, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4969, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.499020307692308 ], [ 29.733623275862069, -1.499289784615385 ], [ 29.733892758620691, -1.499289784615385 ], [ 29.733892758620691, -1.499020307692308 ], [ 29.733623275862069, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4970, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.499020307692308 ], [ 29.733892758620691, -1.499289784615385 ], [ 29.734431724137931, -1.499289784615385 ], [ 29.734431724137931, -1.499020307692308 ], [ 29.733892758620691, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4971, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.499020307692308 ], [ 29.734431724137931, -1.499289784615385 ], [ 29.734701206896553, -1.499289784615385 ], [ 29.734701206896553, -1.499020307692308 ], [ 29.734431724137931, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4972, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.499020307692308 ], [ 29.734701206896553, -1.499289784615385 ], [ 29.734970689655171, -1.499289784615385 ], [ 29.734970689655171, -1.499020307692308 ], [ 29.734701206896553, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4973, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.499020307692308 ], [ 29.735240172413793, -1.499289784615385 ], [ 29.735509655172415, -1.499289784615385 ], [ 29.735509655172415, -1.499020307692308 ], [ 29.735240172413793, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4974, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.499020307692308 ], [ 29.735509655172415, -1.499289784615385 ], [ 29.735779137931036, -1.499289784615385 ], [ 29.735779137931036, -1.499020307692308 ], [ 29.735509655172415, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4975, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.499020307692308 ], [ 29.735779137931036, -1.499289784615385 ], [ 29.736048620689655, -1.499289784615385 ], [ 29.736048620689655, -1.499020307692308 ], [ 29.735779137931036, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4976, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.499020307692308 ], [ 29.736587586206898, -1.499559261538461 ], [ 29.736857068965517, -1.499559261538461 ], [ 29.736857068965517, -1.499020307692308 ], [ 29.736587586206898, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4977, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.499020307692308 ], [ 29.737126551724138, -1.499289784615385 ], [ 29.73739603448276, -1.499289784615385 ], [ 29.73739603448276, -1.499020307692308 ], [ 29.737126551724138, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4978, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.499020307692308 ], [ 29.73739603448276, -1.499289784615385 ], [ 29.737665517241378, -1.499289784615385 ], [ 29.737665517241378, -1.499020307692308 ], [ 29.73739603448276, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4979, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.499020307692308 ], [ 29.737665517241378, -1.499289784615385 ], [ 29.737935, -1.499289784615385 ], [ 29.737935, -1.499020307692308 ], [ 29.737665517241378, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4980, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.499020307692308 ], [ 29.737935, -1.499289784615385 ], [ 29.738204482758622, -1.499289784615385 ], [ 29.738204482758622, -1.499020307692308 ], [ 29.737935, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4981, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.499020307692308 ], [ 29.738204482758622, -1.499289784615385 ], [ 29.738743448275862, -1.499289784615385 ], [ 29.738743448275862, -1.499020307692308 ], [ 29.738204482758622, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4982, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.499020307692308 ], [ 29.738743448275862, -1.499289784615385 ], [ 29.739012931034484, -1.499289784615385 ], [ 29.739012931034484, -1.499020307692308 ], [ 29.738743448275862, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4983, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.499020307692308 ], [ 29.739012931034484, -1.499559261538461 ], [ 29.739282413793102, -1.499559261538461 ], [ 29.739282413793102, -1.499020307692308 ], [ 29.739012931034484, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4984, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.499020307692308 ], [ 29.739282413793102, -1.499289784615385 ], [ 29.739551896551724, -1.499289784615385 ], [ 29.739551896551724, -1.499020307692308 ], [ 29.739282413793102, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4985, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.499020307692308 ], [ 29.739551896551724, -1.499289784615385 ], [ 29.739821379310346, -1.499289784615385 ], [ 29.739821379310346, -1.499020307692308 ], [ 29.739551896551724, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4986, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.499020307692308 ], [ 29.739821379310346, -1.499559261538461 ], [ 29.740090862068964, -1.499559261538461 ], [ 29.740090862068964, -1.499020307692308 ], [ 29.739821379310346, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4987, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.499020307692308 ], [ 29.740090862068964, -1.499289784615385 ], [ 29.740360344827586, -1.499289784615385 ], [ 29.740360344827586, -1.499020307692308 ], [ 29.740090862068964, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4988, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748714310344827, -1.499020307692308 ], [ 29.748714310344827, -1.499289784615385 ], [ 29.748983793103449, -1.499289784615385 ], [ 29.748983793103449, -1.499020307692308 ], [ 29.748714310344827, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4989, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.499020307692308 ], [ 29.748983793103449, -1.499289784615385 ], [ 29.74925327586207, -1.499289784615385 ], [ 29.74925327586207, -1.499020307692308 ], [ 29.748983793103449, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4990, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.499020307692308 ], [ 29.74925327586207, -1.499559261538461 ], [ 29.749522758620689, -1.499559261538461 ], [ 29.749522758620689, -1.499020307692308 ], [ 29.74925327586207, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4991, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.499020307692308 ], [ 29.749522758620689, -1.499559261538461 ], [ 29.749792241379311, -1.499559261538461 ], [ 29.749792241379311, -1.499020307692308 ], [ 29.749522758620689, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4992, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.499020307692308 ], [ 29.750061724137932, -1.499289784615385 ], [ 29.750331206896551, -1.499289784615385 ], [ 29.750331206896551, -1.499020307692308 ], [ 29.750061724137932, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4993, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.499020307692308 ], [ 29.750331206896551, -1.499289784615385 ], [ 29.750600689655172, -1.499289784615385 ], [ 29.750600689655172, -1.499020307692308 ], [ 29.750331206896551, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4994, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.499020307692308 ], [ 29.750600689655172, -1.499289784615385 ], [ 29.750870172413794, -1.499289784615385 ], [ 29.750870172413794, -1.499020307692308 ], [ 29.750600689655172, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4995, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.499020307692308 ], [ 29.750870172413794, -1.499289784615385 ], [ 29.751139655172413, -1.499289784615385 ], [ 29.751139655172413, -1.499020307692308 ], [ 29.750870172413794, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4996, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.499020307692308 ], [ 29.751139655172413, -1.499289784615385 ], [ 29.751409137931034, -1.499289784615385 ], [ 29.751409137931034, -1.499020307692308 ], [ 29.751139655172413, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4997, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.499020307692308 ], [ 29.751409137931034, -1.499289784615385 ], [ 29.751678620689656, -1.499289784615385 ], [ 29.751678620689656, -1.499020307692308 ], [ 29.751409137931034, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4998, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.499020307692308 ], [ 29.751678620689656, -1.499559261538461 ], [ 29.751948103448278, -1.499559261538461 ], [ 29.751948103448278, -1.499020307692308 ], [ 29.751678620689656, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4999, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.499020307692308 ], [ 29.751948103448278, -1.499559261538461 ], [ 29.752217586206896, -1.499559261538461 ], [ 29.752217586206896, -1.499020307692308 ], [ 29.751948103448278, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5000, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.499020307692308 ], [ 29.752487068965518, -1.499289784615385 ], [ 29.75275655172414, -1.499289784615385 ], [ 29.75275655172414, -1.499020307692308 ], [ 29.752487068965518, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5001, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.499020307692308 ], [ 29.75275655172414, -1.499289784615385 ], [ 29.753026034482758, -1.499289784615385 ], [ 29.753026034482758, -1.499020307692308 ], [ 29.75275655172414, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5002, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.499020307692308 ], [ 29.753026034482758, -1.499289784615385 ], [ 29.75329551724138, -1.499289784615385 ], [ 29.75329551724138, -1.499020307692308 ], [ 29.753026034482758, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5003, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.499020307692308 ], [ 29.75329551724138, -1.499289784615385 ], [ 29.753565, -1.499289784615385 ], [ 29.753565, -1.499020307692308 ], [ 29.75329551724138, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5004, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.499020307692308 ], [ 29.753565, -1.499289784615385 ], [ 29.75383448275862, -1.499289784615385 ], [ 29.75383448275862, -1.499020307692308 ], [ 29.753565, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5005, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.499020307692308 ], [ 29.75383448275862, -1.499289784615385 ], [ 29.754103965517242, -1.499289784615385 ], [ 29.754103965517242, -1.499020307692308 ], [ 29.75383448275862, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5006, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.499020307692308 ], [ 29.754103965517242, -1.499289784615385 ], [ 29.754373448275864, -1.499289784615385 ], [ 29.754373448275864, -1.499020307692308 ], [ 29.754103965517242, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5007, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.499020307692308 ], [ 29.754373448275864, -1.499289784615385 ], [ 29.754642931034482, -1.499289784615385 ], [ 29.754642931034482, -1.499020307692308 ], [ 29.754373448275864, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5008, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.499020307692308 ], [ 29.754642931034482, -1.499289784615385 ], [ 29.754912413793104, -1.499289784615385 ], [ 29.754912413793104, -1.499020307692308 ], [ 29.754642931034482, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5009, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.499020307692308 ], [ 29.754912413793104, -1.499289784615385 ], [ 29.755181896551726, -1.499289784615385 ], [ 29.755181896551726, -1.499020307692308 ], [ 29.754912413793104, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5010, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.499020307692308 ], [ 29.755720862068966, -1.499289784615385 ], [ 29.755990344827588, -1.499289784615385 ], [ 29.755990344827588, -1.499020307692308 ], [ 29.755720862068966, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5011, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.499020307692308 ], [ 29.755990344827588, -1.499289784615385 ], [ 29.756259827586206, -1.499289784615385 ], [ 29.756259827586206, -1.499020307692308 ], [ 29.755990344827588, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5012, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.499020307692308 ], [ 29.756259827586206, -1.499289784615385 ], [ 29.756798793103449, -1.499289784615385 ], [ 29.756798793103449, -1.499020307692308 ], [ 29.756259827586206, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5013, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.499020307692308 ], [ 29.756798793103449, -1.499289784615385 ], [ 29.757068275862068, -1.499289784615385 ], [ 29.757068275862068, -1.499020307692308 ], [ 29.756798793103449, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5014, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.499020307692308 ], [ 29.757068275862068, -1.499289784615385 ], [ 29.75733775862069, -1.499289784615385 ], [ 29.75733775862069, -1.499020307692308 ], [ 29.757068275862068, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5015, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.499020307692308 ], [ 29.75733775862069, -1.499289784615385 ], [ 29.757607241379311, -1.499289784615385 ], [ 29.757607241379311, -1.499020307692308 ], [ 29.75733775862069, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5016, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.499020307692308 ], [ 29.757607241379311, -1.499559261538461 ], [ 29.757876724137933, -1.499559261538461 ], [ 29.757876724137933, -1.499020307692308 ], [ 29.757607241379311, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5017, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.499020307692308 ], [ 29.758146206896551, -1.499289784615385 ], [ 29.758415689655173, -1.499289784615385 ], [ 29.758415689655173, -1.499020307692308 ], [ 29.758146206896551, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5018, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.499020307692308 ], [ 29.758415689655173, -1.499289784615385 ], [ 29.758685172413795, -1.499289784615385 ], [ 29.758685172413795, -1.499020307692308 ], [ 29.758415689655173, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5019, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.499020307692308 ], [ 29.758685172413795, -1.499559261538461 ], [ 29.758954655172413, -1.499559261538461 ], [ 29.758954655172413, -1.499020307692308 ], [ 29.758685172413795, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5020, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.499020307692308 ], [ 29.758954655172413, -1.499559261538461 ], [ 29.759224137931035, -1.499559261538461 ], [ 29.759224137931035, -1.499020307692308 ], [ 29.758954655172413, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5021, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.499020307692308 ], [ 29.759763103448275, -1.499289784615385 ], [ 29.760032586206897, -1.499289784615385 ], [ 29.760032586206897, -1.499020307692308 ], [ 29.759763103448275, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5022, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.499020307692308 ], [ 29.760032586206897, -1.499289784615385 ], [ 29.760302068965519, -1.499289784615385 ], [ 29.760302068965519, -1.499020307692308 ], [ 29.760032586206897, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5023, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.499020307692308 ], [ 29.760302068965519, -1.499289784615385 ], [ 29.760571551724137, -1.499289784615385 ], [ 29.760571551724137, -1.499020307692308 ], [ 29.760302068965519, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5024, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.499020307692308 ], [ 29.760841034482759, -1.499289784615385 ], [ 29.761110517241381, -1.499289784615385 ], [ 29.761110517241381, -1.499020307692308 ], [ 29.760841034482759, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5025, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.499020307692308 ], [ 29.761110517241381, -1.499289784615385 ], [ 29.76138, -1.499289784615385 ], [ 29.76138, -1.499020307692308 ], [ 29.761110517241381, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5026, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.499020307692308 ], [ 29.76138, -1.499289784615385 ], [ 29.761649482758621, -1.499289784615385 ], [ 29.761649482758621, -1.499020307692308 ], [ 29.76138, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5027, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.499020307692308 ], [ 29.761649482758621, -1.499289784615385 ], [ 29.761918965517243, -1.499289784615385 ], [ 29.761918965517243, -1.499020307692308 ], [ 29.761649482758621, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5028, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.499020307692308 ], [ 29.761918965517243, -1.500098215384615 ], [ 29.762188448275861, -1.500098215384615 ], [ 29.762188448275861, -1.499020307692308 ], [ 29.761918965517243, -1.499020307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5029, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.499289784615385 ], [ 29.717723793103449, -1.499559261538461 ], [ 29.717993275862067, -1.499559261538461 ], [ 29.717993275862067, -1.499289784615385 ], [ 29.717723793103449, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5030, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.499289784615385 ], [ 29.717993275862067, -1.499559261538461 ], [ 29.718262758620689, -1.499559261538461 ], [ 29.718262758620689, -1.499289784615385 ], [ 29.717993275862067, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5031, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.499289784615385 ], [ 29.718262758620689, -1.499828738461538 ], [ 29.718532241379311, -1.499828738461538 ], [ 29.718532241379311, -1.499289784615385 ], [ 29.718262758620689, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5032, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.499289784615385 ], [ 29.718532241379311, -1.499828738461538 ], [ 29.718801724137933, -1.499828738461538 ], [ 29.718801724137933, -1.499289784615385 ], [ 29.718532241379311, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5033, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.499289784615385 ], [ 29.719071206896551, -1.499559261538461 ], [ 29.719340689655173, -1.499559261538461 ], [ 29.719340689655173, -1.499289784615385 ], [ 29.719071206896551, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5034, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.499289784615385 ], [ 29.719340689655173, -1.499559261538461 ], [ 29.719610172413795, -1.499559261538461 ], [ 29.719610172413795, -1.499289784615385 ], [ 29.719340689655173, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5035, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.499289784615385 ], [ 29.719610172413795, -1.499559261538461 ], [ 29.719879655172413, -1.499559261538461 ], [ 29.719879655172413, -1.499289784615385 ], [ 29.719610172413795, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5036, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.499289784615385 ], [ 29.719879655172413, -1.499559261538461 ], [ 29.720149137931035, -1.499559261538461 ], [ 29.720149137931035, -1.499289784615385 ], [ 29.719879655172413, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5037, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.499289784615385 ], [ 29.720149137931035, -1.499559261538461 ], [ 29.720688103448275, -1.499559261538461 ], [ 29.720688103448275, -1.499289784615385 ], [ 29.720149137931035, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5038, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.499289784615385 ], [ 29.720688103448275, -1.499559261538461 ], [ 29.720957586206897, -1.499559261538461 ], [ 29.720957586206897, -1.499289784615385 ], [ 29.720688103448275, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5039, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.499289784615385 ], [ 29.720957586206897, -1.499559261538461 ], [ 29.721227068965518, -1.499559261538461 ], [ 29.721227068965518, -1.499289784615385 ], [ 29.720957586206897, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5040, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.499289784615385 ], [ 29.721227068965518, -1.499559261538461 ], [ 29.722843965517242, -1.499559261538461 ], [ 29.722843965517242, -1.499289784615385 ], [ 29.721227068965518, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5041, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.499289784615385 ], [ 29.722843965517242, -1.499559261538461 ], [ 29.723113448275861, -1.499559261538461 ], [ 29.723113448275861, -1.499289784615385 ], [ 29.722843965517242, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5042, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.499289784615385 ], [ 29.723113448275861, -1.499559261538461 ], [ 29.723382931034482, -1.499559261538461 ], [ 29.723382931034482, -1.499289784615385 ], [ 29.723113448275861, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5043, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.499289784615385 ], [ 29.723382931034482, -1.499559261538461 ], [ 29.723652413793104, -1.499559261538461 ], [ 29.723652413793104, -1.499289784615385 ], [ 29.723382931034482, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5044, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.499289784615385 ], [ 29.723652413793104, -1.499559261538461 ], [ 29.723921896551722, -1.499559261538461 ], [ 29.723921896551722, -1.499289784615385 ], [ 29.723652413793104, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5045, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.499289784615385 ], [ 29.723921896551722, -1.499559261538461 ], [ 29.724191379310344, -1.499559261538461 ], [ 29.724191379310344, -1.499289784615385 ], [ 29.723921896551722, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5046, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.499289784615385 ], [ 29.724191379310344, -1.499559261538461 ], [ 29.724460862068966, -1.499559261538461 ], [ 29.724460862068966, -1.499289784615385 ], [ 29.724191379310344, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5047, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.499289784615385 ], [ 29.724460862068966, -1.499559261538461 ], [ 29.724730344827588, -1.499559261538461 ], [ 29.724730344827588, -1.499289784615385 ], [ 29.724460862068966, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5048, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.499289784615385 ], [ 29.724730344827588, -1.499559261538461 ], [ 29.724999827586206, -1.499559261538461 ], [ 29.724999827586206, -1.499289784615385 ], [ 29.724730344827588, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5049, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.499289784615385 ], [ 29.724999827586206, -1.499559261538461 ], [ 29.725269310344828, -1.499559261538461 ], [ 29.725269310344828, -1.499289784615385 ], [ 29.724999827586206, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5050, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.499289784615385 ], [ 29.725269310344828, -1.499559261538461 ], [ 29.72553879310345, -1.499559261538461 ], [ 29.72553879310345, -1.499289784615385 ], [ 29.725269310344828, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5051, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.499289784615385 ], [ 29.72553879310345, -1.499559261538461 ], [ 29.725808275862068, -1.499559261538461 ], [ 29.725808275862068, -1.499289784615385 ], [ 29.72553879310345, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5052, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.499289784615385 ], [ 29.725808275862068, -1.499559261538461 ], [ 29.72607775862069, -1.499559261538461 ], [ 29.72607775862069, -1.499289784615385 ], [ 29.725808275862068, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5053, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.499289784615385 ], [ 29.72607775862069, -1.499559261538461 ], [ 29.726347241379312, -1.499559261538461 ], [ 29.726347241379312, -1.499289784615385 ], [ 29.72607775862069, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5054, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.499289784615385 ], [ 29.726347241379312, -1.499559261538461 ], [ 29.72661672413793, -1.499559261538461 ], [ 29.72661672413793, -1.499289784615385 ], [ 29.726347241379312, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5055, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.499289784615385 ], [ 29.72661672413793, -1.500098215384615 ], [ 29.726886206896552, -1.500098215384615 ], [ 29.726886206896552, -1.499289784615385 ], [ 29.72661672413793, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5056, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.499289784615385 ], [ 29.726886206896552, -1.500098215384615 ], [ 29.727155689655174, -1.500098215384615 ], [ 29.727155689655174, -1.499289784615385 ], [ 29.726886206896552, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5057, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.499289784615385 ], [ 29.727155689655174, -1.500367692307692 ], [ 29.727425172413792, -1.500367692307692 ], [ 29.727425172413792, -1.499289784615385 ], [ 29.727155689655174, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5058, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.499289784615385 ], [ 29.732275862068967, -1.499828738461538 ], [ 29.732545344827585, -1.499828738461538 ], [ 29.732545344827585, -1.499559261538461 ], [ 29.732814827586207, -1.499559261538461 ], [ 29.732814827586207, -1.499289784615385 ], [ 29.732275862068967, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5059, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.499289784615385 ], [ 29.732814827586207, -1.499559261538461 ], [ 29.733084310344829, -1.499559261538461 ], [ 29.733084310344829, -1.499289784615385 ], [ 29.732814827586207, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5060, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.499289784615385 ], [ 29.733084310344829, -1.499559261538461 ], [ 29.733892758620691, -1.499559261538461 ], [ 29.733892758620691, -1.499289784615385 ], [ 29.733084310344829, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5061, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.499289784615385 ], [ 29.733892758620691, -1.499559261538461 ], [ 29.734431724137931, -1.499559261538461 ], [ 29.734431724137931, -1.499289784615385 ], [ 29.733892758620691, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5062, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.499289784615385 ], [ 29.734431724137931, -1.499559261538461 ], [ 29.734701206896553, -1.499559261538461 ], [ 29.734701206896553, -1.499289784615385 ], [ 29.734431724137931, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5063, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.499289784615385 ], [ 29.734701206896553, -1.499559261538461 ], [ 29.734970689655171, -1.499559261538461 ], [ 29.734970689655171, -1.499289784615385 ], [ 29.734701206896553, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5064, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.499289784615385 ], [ 29.734970689655171, -1.499559261538461 ], [ 29.735240172413793, -1.499559261538461 ], [ 29.735240172413793, -1.499289784615385 ], [ 29.734970689655171, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5065, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.499289784615385 ], [ 29.735240172413793, -1.499559261538461 ], [ 29.735509655172415, -1.499559261538461 ], [ 29.735509655172415, -1.499289784615385 ], [ 29.735240172413793, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5066, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.499289784615385 ], [ 29.735509655172415, -1.499559261538461 ], [ 29.735779137931036, -1.499559261538461 ], [ 29.735779137931036, -1.499289784615385 ], [ 29.735509655172415, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5067, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.499289784615385 ], [ 29.735779137931036, -1.499559261538461 ], [ 29.736048620689655, -1.499559261538461 ], [ 29.736048620689655, -1.499289784615385 ], [ 29.735779137931036, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5068, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.499289784615385 ], [ 29.736048620689655, -1.499559261538461 ], [ 29.736318103448276, -1.499559261538461 ], [ 29.736318103448276, -1.499289784615385 ], [ 29.736048620689655, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5069, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.499289784615385 ], [ 29.736318103448276, -1.499559261538461 ], [ 29.736587586206898, -1.499559261538461 ], [ 29.736587586206898, -1.499289784615385 ], [ 29.736318103448276, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5070, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.499289784615385 ], [ 29.73739603448276, -1.499559261538461 ], [ 29.737665517241378, -1.499559261538461 ], [ 29.737665517241378, -1.499289784615385 ], [ 29.73739603448276, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5071, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.499289784615385 ], [ 29.737665517241378, -1.499559261538461 ], [ 29.737935, -1.499559261538461 ], [ 29.737935, -1.499289784615385 ], [ 29.737665517241378, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5072, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.499289784615385 ], [ 29.738204482758622, -1.499559261538461 ], [ 29.738743448275862, -1.499559261538461 ], [ 29.738743448275862, -1.499289784615385 ], [ 29.738204482758622, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5073, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.499289784615385 ], [ 29.738743448275862, -1.499559261538461 ], [ 29.739012931034484, -1.499559261538461 ], [ 29.739012931034484, -1.499289784615385 ], [ 29.738743448275862, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5074, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.499289784615385 ], [ 29.739282413793102, -1.499559261538461 ], [ 29.739551896551724, -1.499559261538461 ], [ 29.739551896551724, -1.499289784615385 ], [ 29.739282413793102, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5075, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.499289784615385 ], [ 29.739551896551724, -1.499559261538461 ], [ 29.739821379310346, -1.499559261538461 ], [ 29.739821379310346, -1.499289784615385 ], [ 29.739551896551724, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5076, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.499289784615385 ], [ 29.740090862068964, -1.499828738461538 ], [ 29.740360344827586, -1.499828738461538 ], [ 29.740360344827586, -1.499289784615385 ], [ 29.740090862068964, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5077, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.499289784615385 ], [ 29.740360344827586, -1.499828738461538 ], [ 29.740629827586208, -1.499828738461538 ], [ 29.740629827586208, -1.499289784615385 ], [ 29.740360344827586, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5078, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.499289784615385 ], [ 29.740629827586208, -1.499559261538461 ], [ 29.74089931034483, -1.499559261538461 ], [ 29.74089931034483, -1.499289784615385 ], [ 29.740629827586208, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5079, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748983793103449, -1.499289784615385 ], [ 29.748983793103449, -1.499828738461538 ], [ 29.74925327586207, -1.499828738461538 ], [ 29.74925327586207, -1.499289784615385 ], [ 29.748983793103449, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5080, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.499289784615385 ], [ 29.749792241379311, -1.499559261538461 ], [ 29.750061724137932, -1.499559261538461 ], [ 29.750061724137932, -1.499289784615385 ], [ 29.749792241379311, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5081, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.499289784615385 ], [ 29.750061724137932, -1.499828738461538 ], [ 29.750331206896551, -1.499828738461538 ], [ 29.750331206896551, -1.499289784615385 ], [ 29.750061724137932, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5082, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.499289784615385 ], [ 29.750331206896551, -1.499559261538461 ], [ 29.750600689655172, -1.499559261538461 ], [ 29.750600689655172, -1.499289784615385 ], [ 29.750331206896551, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5083, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.499289784615385 ], [ 29.750600689655172, -1.499559261538461 ], [ 29.750870172413794, -1.499559261538461 ], [ 29.750870172413794, -1.499289784615385 ], [ 29.750600689655172, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5084, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.499289784615385 ], [ 29.750870172413794, -1.499559261538461 ], [ 29.751139655172413, -1.499559261538461 ], [ 29.751139655172413, -1.499289784615385 ], [ 29.750870172413794, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5085, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.499289784615385 ], [ 29.751139655172413, -1.499828738461538 ], [ 29.751409137931034, -1.499828738461538 ], [ 29.751409137931034, -1.499289784615385 ], [ 29.751139655172413, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5086, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.499289784615385 ], [ 29.751409137931034, -1.499828738461538 ], [ 29.751678620689656, -1.499828738461538 ], [ 29.751678620689656, -1.499289784615385 ], [ 29.751409137931034, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5087, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.499289784615385 ], [ 29.752217586206896, -1.499559261538461 ], [ 29.752487068965518, -1.499559261538461 ], [ 29.752487068965518, -1.499289784615385 ], [ 29.752217586206896, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5088, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.499289784615385 ], [ 29.752487068965518, -1.499559261538461 ], [ 29.75275655172414, -1.499559261538461 ], [ 29.75275655172414, -1.499289784615385 ], [ 29.752487068965518, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5089, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.499289784615385 ], [ 29.75275655172414, -1.499559261538461 ], [ 29.753026034482758, -1.499559261538461 ], [ 29.753026034482758, -1.499289784615385 ], [ 29.75275655172414, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5090, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.499289784615385 ], [ 29.753026034482758, -1.499559261538461 ], [ 29.75329551724138, -1.499559261538461 ], [ 29.75329551724138, -1.499289784615385 ], [ 29.753026034482758, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5091, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.499289784615385 ], [ 29.75329551724138, -1.499559261538461 ], [ 29.753565, -1.499559261538461 ], [ 29.753565, -1.499289784615385 ], [ 29.75329551724138, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5092, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.499289784615385 ], [ 29.753565, -1.499559261538461 ], [ 29.75383448275862, -1.499559261538461 ], [ 29.75383448275862, -1.499289784615385 ], [ 29.753565, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5093, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.499289784615385 ], [ 29.75383448275862, -1.499559261538461 ], [ 29.754103965517242, -1.499559261538461 ], [ 29.754103965517242, -1.499289784615385 ], [ 29.75383448275862, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5094, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.499289784615385 ], [ 29.754103965517242, -1.499559261538461 ], [ 29.754373448275864, -1.499559261538461 ], [ 29.754373448275864, -1.499289784615385 ], [ 29.754103965517242, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5095, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.499289784615385 ], [ 29.754373448275864, -1.499559261538461 ], [ 29.754642931034482, -1.499559261538461 ], [ 29.754642931034482, -1.499289784615385 ], [ 29.754373448275864, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5096, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.499289784615385 ], [ 29.754642931034482, -1.499559261538461 ], [ 29.754912413793104, -1.499559261538461 ], [ 29.754912413793104, -1.499289784615385 ], [ 29.754642931034482, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5097, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.499289784615385 ], [ 29.754912413793104, -1.499559261538461 ], [ 29.755181896551726, -1.499559261538461 ], [ 29.755181896551726, -1.499289784615385 ], [ 29.754912413793104, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5098, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.499289784615385 ], [ 29.755181896551726, -1.499559261538461 ], [ 29.755451379310344, -1.499559261538461 ], [ 29.755451379310344, -1.499289784615385 ], [ 29.755181896551726, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5099, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.499289784615385 ], [ 29.755990344827588, -1.499559261538461 ], [ 29.756259827586206, -1.499559261538461 ], [ 29.756259827586206, -1.499289784615385 ], [ 29.755990344827588, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5100, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.499289784615385 ], [ 29.756259827586206, -1.499559261538461 ], [ 29.756798793103449, -1.499559261538461 ], [ 29.756798793103449, -1.499289784615385 ], [ 29.756259827586206, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5101, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.499289784615385 ], [ 29.756798793103449, -1.499559261538461 ], [ 29.757068275862068, -1.499559261538461 ], [ 29.757068275862068, -1.499289784615385 ], [ 29.756798793103449, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5102, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.499289784615385 ], [ 29.757068275862068, -1.499559261538461 ], [ 29.75733775862069, -1.499559261538461 ], [ 29.75733775862069, -1.499289784615385 ], [ 29.757068275862068, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5103, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.499289784615385 ], [ 29.75733775862069, -1.499828738461538 ], [ 29.757607241379311, -1.499828738461538 ], [ 29.757607241379311, -1.499289784615385 ], [ 29.75733775862069, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5104, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.499289784615385 ], [ 29.757876724137933, -1.499559261538461 ], [ 29.758146206896551, -1.499559261538461 ], [ 29.758146206896551, -1.499289784615385 ], [ 29.757876724137933, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5105, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.499289784615385 ], [ 29.758146206896551, -1.499559261538461 ], [ 29.758415689655173, -1.499559261538461 ], [ 29.758415689655173, -1.499289784615385 ], [ 29.758146206896551, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5106, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.499289784615385 ], [ 29.758415689655173, -1.499828738461538 ], [ 29.758685172413795, -1.499828738461538 ], [ 29.758685172413795, -1.499289784615385 ], [ 29.758415689655173, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5107, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.499289784615385 ], [ 29.759224137931035, -1.499559261538461 ], [ 29.759493620689657, -1.499559261538461 ], [ 29.759493620689657, -1.499289784615385 ], [ 29.759224137931035, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5108, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.499289784615385 ], [ 29.759493620689657, -1.499559261538461 ], [ 29.759763103448275, -1.499559261538461 ], [ 29.759763103448275, -1.499289784615385 ], [ 29.759493620689657, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5109, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.499289784615385 ], [ 29.759763103448275, -1.499559261538461 ], [ 29.760032586206897, -1.499559261538461 ], [ 29.760032586206897, -1.499289784615385 ], [ 29.759763103448275, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5110, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.499289784615385 ], [ 29.760032586206897, -1.499559261538461 ], [ 29.760302068965519, -1.499559261538461 ], [ 29.760302068965519, -1.499289784615385 ], [ 29.760032586206897, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5111, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.499289784615385 ], [ 29.760302068965519, -1.499559261538461 ], [ 29.760571551724137, -1.499559261538461 ], [ 29.760571551724137, -1.499289784615385 ], [ 29.760302068965519, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5112, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.499289784615385 ], [ 29.760571551724137, -1.499559261538461 ], [ 29.760841034482759, -1.499559261538461 ], [ 29.760841034482759, -1.499289784615385 ], [ 29.760571551724137, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5113, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.499289784615385 ], [ 29.760841034482759, -1.499559261538461 ], [ 29.761110517241381, -1.499559261538461 ], [ 29.761110517241381, -1.499289784615385 ], [ 29.760841034482759, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5114, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.499289784615385 ], [ 29.761110517241381, -1.499559261538461 ], [ 29.76138, -1.499559261538461 ], [ 29.76138, -1.499289784615385 ], [ 29.761110517241381, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5115, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.499289784615385 ], [ 29.76138, -1.500367692307692 ], [ 29.761649482758621, -1.500367692307692 ], [ 29.761649482758621, -1.499289784615385 ], [ 29.76138, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5116, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.499289784615385 ], [ 29.761649482758621, -1.500367692307692 ], [ 29.761918965517243, -1.500367692307692 ], [ 29.761918965517243, -1.499289784615385 ], [ 29.761649482758621, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5117, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.499559261538461 ], [ 29.717993275862067, -1.500098215384615 ], [ 29.718262758620689, -1.500098215384615 ], [ 29.718262758620689, -1.499559261538461 ], [ 29.717993275862067, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5118, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.499559261538461 ], [ 29.718801724137933, -1.499828738461538 ], [ 29.719071206896551, -1.499828738461538 ], [ 29.719071206896551, -1.499559261538461 ], [ 29.718801724137933, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5119, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.499559261538461 ], [ 29.719071206896551, -1.500098215384615 ], [ 29.719340689655173, -1.500098215384615 ], [ 29.719340689655173, -1.499559261538461 ], [ 29.719071206896551, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5120, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.499559261538461 ], [ 29.719340689655173, -1.499828738461538 ], [ 29.719610172413795, -1.499828738461538 ], [ 29.719610172413795, -1.499559261538461 ], [ 29.719340689655173, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5121, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.499559261538461 ], [ 29.719610172413795, -1.499828738461538 ], [ 29.719879655172413, -1.499828738461538 ], [ 29.719879655172413, -1.499559261538461 ], [ 29.719610172413795, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5122, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.499559261538461 ], [ 29.719879655172413, -1.499828738461538 ], [ 29.720149137931035, -1.499828738461538 ], [ 29.720149137931035, -1.499559261538461 ], [ 29.719879655172413, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5123, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.499559261538461 ], [ 29.720149137931035, -1.499828738461538 ], [ 29.720688103448275, -1.499828738461538 ], [ 29.720688103448275, -1.499559261538461 ], [ 29.720149137931035, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5124, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.499559261538461 ], [ 29.720688103448275, -1.499828738461538 ], [ 29.720957586206897, -1.499828738461538 ], [ 29.720957586206897, -1.499559261538461 ], [ 29.720688103448275, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5125, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.499559261538461 ], [ 29.720957586206897, -1.499828738461538 ], [ 29.722305, -1.499828738461538 ], [ 29.722305, -1.499559261538461 ], [ 29.720957586206897, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5126, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.499559261538461 ], [ 29.722305, -1.499828738461538 ], [ 29.722843965517242, -1.499828738461538 ], [ 29.722843965517242, -1.499559261538461 ], [ 29.722305, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5127, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.499559261538461 ], [ 29.722843965517242, -1.499828738461538 ], [ 29.723113448275861, -1.499828738461538 ], [ 29.723113448275861, -1.499559261538461 ], [ 29.722843965517242, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5128, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.499559261538461 ], [ 29.723113448275861, -1.499828738461538 ], [ 29.723382931034482, -1.499828738461538 ], [ 29.723382931034482, -1.499559261538461 ], [ 29.723113448275861, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5129, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.499559261538461 ], [ 29.723382931034482, -1.499828738461538 ], [ 29.723652413793104, -1.499828738461538 ], [ 29.723652413793104, -1.499559261538461 ], [ 29.723382931034482, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5130, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.499559261538461 ], [ 29.723652413793104, -1.499828738461538 ], [ 29.723921896551722, -1.499828738461538 ], [ 29.723921896551722, -1.499559261538461 ], [ 29.723652413793104, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5131, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.499559261538461 ], [ 29.723921896551722, -1.499828738461538 ], [ 29.724191379310344, -1.499828738461538 ], [ 29.724191379310344, -1.499559261538461 ], [ 29.723921896551722, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5132, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.499559261538461 ], [ 29.724191379310344, -1.499828738461538 ], [ 29.724460862068966, -1.499828738461538 ], [ 29.724460862068966, -1.499559261538461 ], [ 29.724191379310344, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5133, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.499559261538461 ], [ 29.724460862068966, -1.499828738461538 ], [ 29.724730344827588, -1.499828738461538 ], [ 29.724730344827588, -1.499559261538461 ], [ 29.724460862068966, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5134, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.499559261538461 ], [ 29.724730344827588, -1.499828738461538 ], [ 29.724999827586206, -1.499828738461538 ], [ 29.724999827586206, -1.499559261538461 ], [ 29.724730344827588, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5135, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.499559261538461 ], [ 29.724999827586206, -1.499828738461538 ], [ 29.725269310344828, -1.499828738461538 ], [ 29.725269310344828, -1.499559261538461 ], [ 29.724999827586206, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5136, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.499559261538461 ], [ 29.725269310344828, -1.499828738461538 ], [ 29.72553879310345, -1.499828738461538 ], [ 29.72553879310345, -1.499559261538461 ], [ 29.725269310344828, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5137, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.499559261538461 ], [ 29.72553879310345, -1.499828738461538 ], [ 29.725808275862068, -1.499828738461538 ], [ 29.725808275862068, -1.499559261538461 ], [ 29.72553879310345, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5138, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.499559261538461 ], [ 29.725808275862068, -1.499828738461538 ], [ 29.72607775862069, -1.499828738461538 ], [ 29.72607775862069, -1.500367692307692 ], [ 29.726347241379312, -1.500367692307692 ], [ 29.726347241379312, -1.499559261538461 ], [ 29.725808275862068, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5139, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.499559261538461 ], [ 29.726347241379312, -1.500367692307692 ], [ 29.72661672413793, -1.500367692307692 ], [ 29.72661672413793, -1.499559261538461 ], [ 29.726347241379312, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5140, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.499559261538461 ], [ 29.73012, -1.500098215384615 ], [ 29.730389482758621, -1.500098215384615 ], [ 29.730389482758621, -1.499559261538461 ], [ 29.73012, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5141, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.499559261538461 ], [ 29.730928448275861, -1.499828738461538 ], [ 29.731197931034483, -1.499828738461538 ], [ 29.731197931034483, -1.499559261538461 ], [ 29.730928448275861, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5142, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.499559261538461 ], [ 29.732545344827585, -1.499828738461538 ], [ 29.732814827586207, -1.499828738461538 ], [ 29.732814827586207, -1.499559261538461 ], [ 29.732545344827585, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5143, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.499559261538461 ], [ 29.732814827586207, -1.499828738461538 ], [ 29.733084310344829, -1.499828738461538 ], [ 29.733084310344829, -1.499559261538461 ], [ 29.732814827586207, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5144, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.499559261538461 ], [ 29.733892758620691, -1.499828738461538 ], [ 29.734162241379309, -1.499828738461538 ], [ 29.734162241379309, -1.499559261538461 ], [ 29.733892758620691, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5145, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.499559261538461 ], [ 29.734162241379309, -1.499828738461538 ], [ 29.734701206896553, -1.499828738461538 ], [ 29.734701206896553, -1.499559261538461 ], [ 29.734162241379309, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5146, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.499559261538461 ], [ 29.734701206896553, -1.499828738461538 ], [ 29.734970689655171, -1.499828738461538 ], [ 29.734970689655171, -1.499559261538461 ], [ 29.734701206896553, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5147, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.499559261538461 ], [ 29.734970689655171, -1.499828738461538 ], [ 29.735240172413793, -1.499828738461538 ], [ 29.735240172413793, -1.499559261538461 ], [ 29.734970689655171, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5148, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.499559261538461 ], [ 29.735240172413793, -1.499828738461538 ], [ 29.735509655172415, -1.499828738461538 ], [ 29.735509655172415, -1.499559261538461 ], [ 29.735240172413793, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5149, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.499559261538461 ], [ 29.735509655172415, -1.499828738461538 ], [ 29.735779137931036, -1.499828738461538 ], [ 29.735779137931036, -1.499559261538461 ], [ 29.735509655172415, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5150, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.499559261538461 ], [ 29.735779137931036, -1.499828738461538 ], [ 29.736048620689655, -1.499828738461538 ], [ 29.736048620689655, -1.499559261538461 ], [ 29.735779137931036, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5151, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.499559261538461 ], [ 29.736048620689655, -1.499828738461538 ], [ 29.736318103448276, -1.499828738461538 ], [ 29.736318103448276, -1.499559261538461 ], [ 29.736048620689655, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5152, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.499559261538461 ], [ 29.736318103448276, -1.499828738461538 ], [ 29.736587586206898, -1.499828738461538 ], [ 29.736587586206898, -1.499559261538461 ], [ 29.736318103448276, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5153, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.499559261538461 ], [ 29.736587586206898, -1.499828738461538 ], [ 29.736857068965517, -1.499828738461538 ], [ 29.736857068965517, -1.499559261538461 ], [ 29.736587586206898, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5154, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.499559261538461 ], [ 29.736857068965517, -1.499828738461538 ], [ 29.737126551724138, -1.499828738461538 ], [ 29.737126551724138, -1.499559261538461 ], [ 29.736857068965517, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5155, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.499559261538461 ], [ 29.737665517241378, -1.499828738461538 ], [ 29.737935, -1.499828738461538 ], [ 29.737935, -1.499559261538461 ], [ 29.737665517241378, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5156, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.499559261538461 ], [ 29.738743448275862, -1.499828738461538 ], [ 29.739012931034484, -1.499828738461538 ], [ 29.739012931034484, -1.499559261538461 ], [ 29.738743448275862, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5157, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.499559261538461 ], [ 29.739012931034484, -1.499828738461538 ], [ 29.739282413793102, -1.499828738461538 ], [ 29.739282413793102, -1.499559261538461 ], [ 29.739012931034484, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5158, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.499559261538461 ], [ 29.739282413793102, -1.499828738461538 ], [ 29.739551896551724, -1.499828738461538 ], [ 29.739551896551724, -1.499559261538461 ], [ 29.739282413793102, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5159, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.499559261538461 ], [ 29.739551896551724, -1.500098215384615 ], [ 29.739821379310346, -1.500098215384615 ], [ 29.739821379310346, -1.499559261538461 ], [ 29.739551896551724, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5160, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.499559261538461 ], [ 29.739821379310346, -1.499828738461538 ], [ 29.740090862068964, -1.499828738461538 ], [ 29.740090862068964, -1.499559261538461 ], [ 29.739821379310346, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5161, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.499559261538461 ], [ 29.740629827586208, -1.500098215384615 ], [ 29.74089931034483, -1.500098215384615 ], [ 29.74089931034483, -1.499559261538461 ], [ 29.740629827586208, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5162, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.499559261538461 ], [ 29.74925327586207, -1.499828738461538 ], [ 29.749522758620689, -1.499828738461538 ], [ 29.749522758620689, -1.499559261538461 ], [ 29.74925327586207, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5163, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.499559261538461 ], [ 29.749522758620689, -1.500367692307692 ], [ 29.749792241379311, -1.500367692307692 ], [ 29.749792241379311, -1.499559261538461 ], [ 29.749522758620689, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5164, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.499559261538461 ], [ 29.749792241379311, -1.500367692307692 ], [ 29.750061724137932, -1.500367692307692 ], [ 29.750061724137932, -1.499559261538461 ], [ 29.749792241379311, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5165, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.499559261538461 ], [ 29.750331206896551, -1.500098215384615 ], [ 29.750600689655172, -1.500098215384615 ], [ 29.750600689655172, -1.499559261538461 ], [ 29.750331206896551, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5166, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.499559261538461 ], [ 29.750600689655172, -1.500098215384615 ], [ 29.750870172413794, -1.500098215384615 ], [ 29.750870172413794, -1.499559261538461 ], [ 29.750600689655172, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5167, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.499559261538461 ], [ 29.750870172413794, -1.500098215384615 ], [ 29.751139655172413, -1.500098215384615 ], [ 29.751139655172413, -1.499559261538461 ], [ 29.750870172413794, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5168, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.499559261538461 ], [ 29.751678620689656, -1.499828738461538 ], [ 29.751948103448278, -1.499828738461538 ], [ 29.751948103448278, -1.499559261538461 ], [ 29.751678620689656, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5169, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.499559261538461 ], [ 29.751948103448278, -1.499828738461538 ], [ 29.752217586206896, -1.499828738461538 ], [ 29.752217586206896, -1.499559261538461 ], [ 29.751948103448278, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5170, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.499559261538461 ], [ 29.752217586206896, -1.499828738461538 ], [ 29.752487068965518, -1.499828738461538 ], [ 29.752487068965518, -1.499559261538461 ], [ 29.752217586206896, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5171, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.499559261538461 ], [ 29.752487068965518, -1.499828738461538 ], [ 29.75275655172414, -1.499828738461538 ], [ 29.75275655172414, -1.499559261538461 ], [ 29.752487068965518, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5172, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.499559261538461 ], [ 29.75275655172414, -1.499828738461538 ], [ 29.753026034482758, -1.499828738461538 ], [ 29.753026034482758, -1.499559261538461 ], [ 29.75275655172414, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5173, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.499559261538461 ], [ 29.753026034482758, -1.499828738461538 ], [ 29.75329551724138, -1.499828738461538 ], [ 29.75329551724138, -1.499559261538461 ], [ 29.753026034482758, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5174, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.499559261538461 ], [ 29.75329551724138, -1.499828738461538 ], [ 29.753565, -1.499828738461538 ], [ 29.753565, -1.499559261538461 ], [ 29.75329551724138, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5175, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.499559261538461 ], [ 29.753565, -1.499828738461538 ], [ 29.75383448275862, -1.499828738461538 ], [ 29.75383448275862, -1.499559261538461 ], [ 29.753565, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5176, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.499559261538461 ], [ 29.75383448275862, -1.499828738461538 ], [ 29.754103965517242, -1.499828738461538 ], [ 29.754103965517242, -1.499559261538461 ], [ 29.75383448275862, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5177, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.499559261538461 ], [ 29.754103965517242, -1.499828738461538 ], [ 29.754373448275864, -1.499828738461538 ], [ 29.754373448275864, -1.499559261538461 ], [ 29.754103965517242, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5178, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.499559261538461 ], [ 29.754373448275864, -1.499828738461538 ], [ 29.754642931034482, -1.499828738461538 ], [ 29.754642931034482, -1.499559261538461 ], [ 29.754373448275864, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5179, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.499559261538461 ], [ 29.754642931034482, -1.499828738461538 ], [ 29.754912413793104, -1.499828738461538 ], [ 29.754912413793104, -1.499559261538461 ], [ 29.754642931034482, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5180, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.499559261538461 ], [ 29.754912413793104, -1.499828738461538 ], [ 29.755181896551726, -1.499828738461538 ], [ 29.755181896551726, -1.499559261538461 ], [ 29.754912413793104, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5181, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.499559261538461 ], [ 29.755181896551726, -1.499828738461538 ], [ 29.755451379310344, -1.499828738461538 ], [ 29.755451379310344, -1.499559261538461 ], [ 29.755181896551726, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5182, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.499559261538461 ], [ 29.755451379310344, -1.499828738461538 ], [ 29.755720862068966, -1.499828738461538 ], [ 29.755720862068966, -1.499559261538461 ], [ 29.755451379310344, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5183, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.499559261538461 ], [ 29.755720862068966, -1.499828738461538 ], [ 29.755990344827588, -1.499828738461538 ], [ 29.755990344827588, -1.499559261538461 ], [ 29.755720862068966, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5184, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.499559261538461 ], [ 29.755990344827588, -1.499828738461538 ], [ 29.756259827586206, -1.499828738461538 ], [ 29.756259827586206, -1.499559261538461 ], [ 29.755990344827588, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5185, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.499559261538461 ], [ 29.756259827586206, -1.499828738461538 ], [ 29.756798793103449, -1.499828738461538 ], [ 29.756798793103449, -1.499559261538461 ], [ 29.756259827586206, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5186, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.499559261538461 ], [ 29.756798793103449, -1.500098215384615 ], [ 29.757068275862068, -1.500098215384615 ], [ 29.757068275862068, -1.499559261538461 ], [ 29.756798793103449, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5187, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.499559261538461 ], [ 29.757068275862068, -1.500098215384615 ], [ 29.75733775862069, -1.500098215384615 ], [ 29.75733775862069, -1.499559261538461 ], [ 29.757068275862068, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5188, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.499559261538461 ], [ 29.757607241379311, -1.499828738461538 ], [ 29.757876724137933, -1.499828738461538 ], [ 29.757876724137933, -1.499559261538461 ], [ 29.757607241379311, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5189, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.499559261538461 ], [ 29.757876724137933, -1.499828738461538 ], [ 29.758146206896551, -1.499828738461538 ], [ 29.758146206896551, -1.499559261538461 ], [ 29.757876724137933, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5190, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.499559261538461 ], [ 29.758146206896551, -1.500098215384615 ], [ 29.758415689655173, -1.500098215384615 ], [ 29.758415689655173, -1.499559261538461 ], [ 29.758146206896551, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5191, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.499559261538461 ], [ 29.758685172413795, -1.499828738461538 ], [ 29.758954655172413, -1.499828738461538 ], [ 29.758954655172413, -1.499559261538461 ], [ 29.758685172413795, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5192, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.499559261538461 ], [ 29.758954655172413, -1.499828738461538 ], [ 29.759224137931035, -1.499828738461538 ], [ 29.759224137931035, -1.499559261538461 ], [ 29.758954655172413, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5193, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.499559261538461 ], [ 29.759224137931035, -1.499828738461538 ], [ 29.759493620689657, -1.499828738461538 ], [ 29.759493620689657, -1.499559261538461 ], [ 29.759224137931035, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5194, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.499559261538461 ], [ 29.759493620689657, -1.499828738461538 ], [ 29.759763103448275, -1.499828738461538 ], [ 29.759763103448275, -1.499559261538461 ], [ 29.759493620689657, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5195, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.499559261538461 ], [ 29.759763103448275, -1.499828738461538 ], [ 29.760032586206897, -1.499828738461538 ], [ 29.760032586206897, -1.499559261538461 ], [ 29.759763103448275, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5196, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.499559261538461 ], [ 29.760032586206897, -1.499828738461538 ], [ 29.760302068965519, -1.499828738461538 ], [ 29.760302068965519, -1.499559261538461 ], [ 29.760032586206897, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5197, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.499559261538461 ], [ 29.760302068965519, -1.499828738461538 ], [ 29.760571551724137, -1.499828738461538 ], [ 29.760571551724137, -1.499559261538461 ], [ 29.760302068965519, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5198, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.499559261538461 ], [ 29.760571551724137, -1.499828738461538 ], [ 29.760841034482759, -1.499828738461538 ], [ 29.760841034482759, -1.499559261538461 ], [ 29.760571551724137, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5199, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.499559261538461 ], [ 29.760841034482759, -1.500367692307692 ], [ 29.761110517241381, -1.500367692307692 ], [ 29.761110517241381, -1.499559261538461 ], [ 29.760841034482759, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5200, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.499559261538461 ], [ 29.761110517241381, -1.500098215384615 ], [ 29.76138, -1.500098215384615 ], [ 29.76138, -1.499559261538461 ], [ 29.761110517241381, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5201, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.499828738461538 ], [ 29.718262758620689, -1.500098215384615 ], [ 29.718532241379311, -1.500098215384615 ], [ 29.718532241379311, -1.499828738461538 ], [ 29.718262758620689, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5202, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.499828738461538 ], [ 29.718532241379311, -1.500367692307692 ], [ 29.718801724137933, -1.500367692307692 ], [ 29.718801724137933, -1.499828738461538 ], [ 29.718532241379311, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5203, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.499828738461538 ], [ 29.718801724137933, -1.500367692307692 ], [ 29.719071206896551, -1.500367692307692 ], [ 29.719071206896551, -1.499828738461538 ], [ 29.718801724137933, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5204, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.499828738461538 ], [ 29.719340689655173, -1.500098215384615 ], [ 29.719610172413795, -1.500098215384615 ], [ 29.719610172413795, -1.499828738461538 ], [ 29.719340689655173, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5205, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.499828738461538 ], [ 29.719610172413795, -1.500098215384615 ], [ 29.719879655172413, -1.500098215384615 ], [ 29.719879655172413, -1.499828738461538 ], [ 29.719610172413795, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5206, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.499828738461538 ], [ 29.719879655172413, -1.500098215384615 ], [ 29.720149137931035, -1.500098215384615 ], [ 29.720149137931035, -1.499828738461538 ], [ 29.719879655172413, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5207, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.499828738461538 ], [ 29.720149137931035, -1.500098215384615 ], [ 29.720688103448275, -1.500098215384615 ], [ 29.720688103448275, -1.499828738461538 ], [ 29.720149137931035, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5208, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.499828738461538 ], [ 29.720688103448275, -1.500098215384615 ], [ 29.720957586206897, -1.500098215384615 ], [ 29.720957586206897, -1.499828738461538 ], [ 29.720688103448275, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5209, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.499828738461538 ], [ 29.720957586206897, -1.500098215384615 ], [ 29.72257448275862, -1.500098215384615 ], [ 29.72257448275862, -1.499828738461538 ], [ 29.720957586206897, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5210, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.499828738461538 ], [ 29.72257448275862, -1.500098215384615 ], [ 29.722843965517242, -1.500098215384615 ], [ 29.722843965517242, -1.499828738461538 ], [ 29.72257448275862, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5211, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.499828738461538 ], [ 29.722843965517242, -1.500098215384615 ], [ 29.723113448275861, -1.500098215384615 ], [ 29.723113448275861, -1.499828738461538 ], [ 29.722843965517242, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5212, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.499828738461538 ], [ 29.723113448275861, -1.500098215384615 ], [ 29.723382931034482, -1.500098215384615 ], [ 29.723382931034482, -1.499828738461538 ], [ 29.723113448275861, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5213, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.499828738461538 ], [ 29.723382931034482, -1.500098215384615 ], [ 29.723652413793104, -1.500098215384615 ], [ 29.723652413793104, -1.499828738461538 ], [ 29.723382931034482, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5214, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.499828738461538 ], [ 29.723652413793104, -1.500098215384615 ], [ 29.723921896551722, -1.500098215384615 ], [ 29.723921896551722, -1.499828738461538 ], [ 29.723652413793104, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5215, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.499828738461538 ], [ 29.723921896551722, -1.500098215384615 ], [ 29.724191379310344, -1.500098215384615 ], [ 29.724191379310344, -1.499828738461538 ], [ 29.723921896551722, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5216, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.499828738461538 ], [ 29.724191379310344, -1.500098215384615 ], [ 29.724460862068966, -1.500098215384615 ], [ 29.724460862068966, -1.499828738461538 ], [ 29.724191379310344, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5217, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.499828738461538 ], [ 29.724460862068966, -1.500098215384615 ], [ 29.724730344827588, -1.500098215384615 ], [ 29.724730344827588, -1.499828738461538 ], [ 29.724460862068966, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5218, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.499828738461538 ], [ 29.724730344827588, -1.500098215384615 ], [ 29.724999827586206, -1.500098215384615 ], [ 29.724999827586206, -1.499828738461538 ], [ 29.724730344827588, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5219, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.499828738461538 ], [ 29.724999827586206, -1.500098215384615 ], [ 29.725269310344828, -1.500098215384615 ], [ 29.725269310344828, -1.499828738461538 ], [ 29.724999827586206, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5220, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.499828738461538 ], [ 29.725269310344828, -1.500098215384615 ], [ 29.72553879310345, -1.500098215384615 ], [ 29.72553879310345, -1.499828738461538 ], [ 29.725269310344828, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5221, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.499828738461538 ], [ 29.72553879310345, -1.500098215384615 ], [ 29.725808275862068, -1.500098215384615 ], [ 29.725808275862068, -1.499828738461538 ], [ 29.72553879310345, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5222, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.499828738461538 ], [ 29.725808275862068, -1.500098215384615 ], [ 29.72607775862069, -1.500098215384615 ], [ 29.72607775862069, -1.499828738461538 ], [ 29.725808275862068, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5223, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.499828738461538 ], [ 29.727425172413792, -1.500367692307692 ], [ 29.727694655172414, -1.500367692307692 ], [ 29.727694655172414, -1.499828738461538 ], [ 29.727425172413792, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5224, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.499828738461538 ], [ 29.728233620689654, -1.500367692307692 ], [ 29.728503103448276, -1.500367692307692 ], [ 29.728503103448276, -1.499828738461538 ], [ 29.728233620689654, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5225, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.499828738461538 ], [ 29.729042068965516, -1.500098215384615 ], [ 29.729311551724138, -1.500098215384615 ], [ 29.729311551724138, -1.499828738461538 ], [ 29.729042068965516, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5226, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.499828738461538 ], [ 29.729311551724138, -1.500098215384615 ], [ 29.729850517241381, -1.500098215384615 ], [ 29.729850517241381, -1.499828738461538 ], [ 29.729311551724138, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5227, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.499828738461538 ], [ 29.729850517241381, -1.500098215384615 ], [ 29.73012, -1.500098215384615 ], [ 29.73012, -1.499828738461538 ], [ 29.729850517241381, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5228, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.499828738461538 ], [ 29.730389482758621, -1.500098215384615 ], [ 29.730658965517243, -1.500098215384615 ], [ 29.730658965517243, -1.499828738461538 ], [ 29.730389482758621, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5229, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.499828738461538 ], [ 29.730658965517243, -1.500098215384615 ], [ 29.730928448275861, -1.500098215384615 ], [ 29.730928448275861, -1.499828738461538 ], [ 29.730658965517243, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5230, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.499828738461538 ], [ 29.732545344827585, -1.500098215384615 ], [ 29.732814827586207, -1.500098215384615 ], [ 29.732814827586207, -1.499828738461538 ], [ 29.732545344827585, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5231, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.499828738461538 ], [ 29.732814827586207, -1.500098215384615 ], [ 29.733084310344829, -1.500098215384615 ], [ 29.733084310344829, -1.499828738461538 ], [ 29.732814827586207, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5232, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.499828738461538 ], [ 29.733623275862069, -1.500098215384615 ], [ 29.734162241379309, -1.500098215384615 ], [ 29.734162241379309, -1.499828738461538 ], [ 29.733623275862069, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5233, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.499828738461538 ], [ 29.734162241379309, -1.500098215384615 ], [ 29.734431724137931, -1.500098215384615 ], [ 29.734431724137931, -1.499828738461538 ], [ 29.734162241379309, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5234, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.499828738461538 ], [ 29.734431724137931, -1.500098215384615 ], [ 29.734701206896553, -1.500098215384615 ], [ 29.734701206896553, -1.499828738461538 ], [ 29.734431724137931, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5235, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.499828738461538 ], [ 29.734701206896553, -1.500098215384615 ], [ 29.734970689655171, -1.500098215384615 ], [ 29.734970689655171, -1.499828738461538 ], [ 29.734701206896553, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5236, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.499828738461538 ], [ 29.734970689655171, -1.500098215384615 ], [ 29.735240172413793, -1.500098215384615 ], [ 29.735240172413793, -1.499828738461538 ], [ 29.734970689655171, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5237, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.499828738461538 ], [ 29.735240172413793, -1.500098215384615 ], [ 29.735509655172415, -1.500098215384615 ], [ 29.735509655172415, -1.499828738461538 ], [ 29.735240172413793, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5238, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.499828738461538 ], [ 29.735509655172415, -1.500098215384615 ], [ 29.735779137931036, -1.500098215384615 ], [ 29.735779137931036, -1.499828738461538 ], [ 29.735509655172415, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5239, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.499828738461538 ], [ 29.735779137931036, -1.500098215384615 ], [ 29.736048620689655, -1.500098215384615 ], [ 29.736048620689655, -1.499828738461538 ], [ 29.735779137931036, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5240, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.499828738461538 ], [ 29.736048620689655, -1.500098215384615 ], [ 29.736318103448276, -1.500098215384615 ], [ 29.736318103448276, -1.499828738461538 ], [ 29.736048620689655, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5241, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.499828738461538 ], [ 29.736318103448276, -1.500098215384615 ], [ 29.736587586206898, -1.500098215384615 ], [ 29.736587586206898, -1.499828738461538 ], [ 29.736318103448276, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5242, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.499828738461538 ], [ 29.736587586206898, -1.500098215384615 ], [ 29.736857068965517, -1.500098215384615 ], [ 29.736857068965517, -1.499828738461538 ], [ 29.736587586206898, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5243, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.499828738461538 ], [ 29.740360344827586, -1.500098215384615 ], [ 29.740629827586208, -1.500098215384615 ], [ 29.740629827586208, -1.499828738461538 ], [ 29.740360344827586, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5244, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74925327586207, -1.499828738461538 ], [ 29.74925327586207, -1.500637169230769 ], [ 29.749522758620689, -1.500637169230769 ], [ 29.749522758620689, -1.499828738461538 ], [ 29.74925327586207, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5245, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.499828738461538 ], [ 29.750061724137932, -1.500098215384615 ], [ 29.750331206896551, -1.500098215384615 ], [ 29.750331206896551, -1.499828738461538 ], [ 29.750061724137932, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5246, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.499828738461538 ], [ 29.751139655172413, -1.500098215384615 ], [ 29.751409137931034, -1.500098215384615 ], [ 29.751409137931034, -1.499828738461538 ], [ 29.751139655172413, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5247, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.499828738461538 ], [ 29.751409137931034, -1.500098215384615 ], [ 29.751678620689656, -1.500098215384615 ], [ 29.751678620689656, -1.499828738461538 ], [ 29.751409137931034, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5248, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.499828738461538 ], [ 29.751678620689656, -1.500098215384615 ], [ 29.751948103448278, -1.500098215384615 ], [ 29.751948103448278, -1.499828738461538 ], [ 29.751678620689656, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5249, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.499828738461538 ], [ 29.751948103448278, -1.500098215384615 ], [ 29.752217586206896, -1.500098215384615 ], [ 29.752217586206896, -1.499828738461538 ], [ 29.751948103448278, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5250, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.499828738461538 ], [ 29.752217586206896, -1.500098215384615 ], [ 29.752487068965518, -1.500098215384615 ], [ 29.752487068965518, -1.499828738461538 ], [ 29.752217586206896, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5251, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.499828738461538 ], [ 29.752487068965518, -1.500098215384615 ], [ 29.75275655172414, -1.500098215384615 ], [ 29.75275655172414, -1.499828738461538 ], [ 29.752487068965518, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5252, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.499828738461538 ], [ 29.75275655172414, -1.500098215384615 ], [ 29.753026034482758, -1.500098215384615 ], [ 29.753026034482758, -1.499828738461538 ], [ 29.75275655172414, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5253, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.499828738461538 ], [ 29.753026034482758, -1.500098215384615 ], [ 29.75329551724138, -1.500098215384615 ], [ 29.75329551724138, -1.499828738461538 ], [ 29.753026034482758, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5254, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.499828738461538 ], [ 29.75329551724138, -1.500098215384615 ], [ 29.753565, -1.500098215384615 ], [ 29.753565, -1.499828738461538 ], [ 29.75329551724138, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5255, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.499828738461538 ], [ 29.753565, -1.500098215384615 ], [ 29.75383448275862, -1.500098215384615 ], [ 29.75383448275862, -1.499828738461538 ], [ 29.753565, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5256, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.499828738461538 ], [ 29.75383448275862, -1.500098215384615 ], [ 29.754103965517242, -1.500098215384615 ], [ 29.754103965517242, -1.499828738461538 ], [ 29.75383448275862, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5257, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.499828738461538 ], [ 29.754103965517242, -1.500098215384615 ], [ 29.754373448275864, -1.500098215384615 ], [ 29.754373448275864, -1.499828738461538 ], [ 29.754103965517242, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5258, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.499828738461538 ], [ 29.754373448275864, -1.500098215384615 ], [ 29.754642931034482, -1.500098215384615 ], [ 29.754642931034482, -1.499828738461538 ], [ 29.754373448275864, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5259, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.499828738461538 ], [ 29.754642931034482, -1.500098215384615 ], [ 29.754912413793104, -1.500098215384615 ], [ 29.754912413793104, -1.499828738461538 ], [ 29.754642931034482, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5260, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.499828738461538 ], [ 29.754912413793104, -1.500098215384615 ], [ 29.755181896551726, -1.500098215384615 ], [ 29.755181896551726, -1.499828738461538 ], [ 29.754912413793104, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5261, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.499828738461538 ], [ 29.755181896551726, -1.500098215384615 ], [ 29.755451379310344, -1.500098215384615 ], [ 29.755451379310344, -1.499828738461538 ], [ 29.755181896551726, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5262, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.499828738461538 ], [ 29.755451379310344, -1.500098215384615 ], [ 29.755720862068966, -1.500098215384615 ], [ 29.755720862068966, -1.499828738461538 ], [ 29.755451379310344, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5263, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.499828738461538 ], [ 29.755720862068966, -1.500098215384615 ], [ 29.755990344827588, -1.500098215384615 ], [ 29.755990344827588, -1.500637169230769 ], [ 29.756259827586206, -1.500637169230769 ], [ 29.756259827586206, -1.499828738461538 ], [ 29.755720862068966, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5264, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.499828738461538 ], [ 29.756259827586206, -1.500367692307692 ], [ 29.756798793103449, -1.500367692307692 ], [ 29.756798793103449, -1.499828738461538 ], [ 29.756259827586206, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5265, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.499828738461538 ], [ 29.75733775862069, -1.500098215384615 ], [ 29.757607241379311, -1.500098215384615 ], [ 29.757607241379311, -1.499828738461538 ], [ 29.75733775862069, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5266, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.499828738461538 ], [ 29.757607241379311, -1.500098215384615 ], [ 29.757876724137933, -1.500098215384615 ], [ 29.757876724137933, -1.499828738461538 ], [ 29.757607241379311, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5267, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.499828738461538 ], [ 29.757876724137933, -1.500367692307692 ], [ 29.758146206896551, -1.500367692307692 ], [ 29.758146206896551, -1.499828738461538 ], [ 29.757876724137933, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5268, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.499828738461538 ], [ 29.758415689655173, -1.500098215384615 ], [ 29.758685172413795, -1.500098215384615 ], [ 29.758685172413795, -1.499828738461538 ], [ 29.758415689655173, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5269, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.499828738461538 ], [ 29.758685172413795, -1.500098215384615 ], [ 29.758954655172413, -1.500098215384615 ], [ 29.758954655172413, -1.499828738461538 ], [ 29.758685172413795, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5270, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.499828738461538 ], [ 29.758954655172413, -1.500098215384615 ], [ 29.759224137931035, -1.500098215384615 ], [ 29.759224137931035, -1.499828738461538 ], [ 29.758954655172413, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5271, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.499828738461538 ], [ 29.759224137931035, -1.500098215384615 ], [ 29.759493620689657, -1.500098215384615 ], [ 29.759493620689657, -1.499828738461538 ], [ 29.759224137931035, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5272, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.499828738461538 ], [ 29.759493620689657, -1.500098215384615 ], [ 29.759763103448275, -1.500098215384615 ], [ 29.759763103448275, -1.499828738461538 ], [ 29.759493620689657, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5273, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.499828738461538 ], [ 29.759763103448275, -1.500098215384615 ], [ 29.760032586206897, -1.500098215384615 ], [ 29.760032586206897, -1.499828738461538 ], [ 29.759763103448275, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5274, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.499828738461538 ], [ 29.760032586206897, -1.500098215384615 ], [ 29.760302068965519, -1.500098215384615 ], [ 29.760302068965519, -1.499828738461538 ], [ 29.760032586206897, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5275, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.499828738461538 ], [ 29.760302068965519, -1.500367692307692 ], [ 29.760571551724137, -1.500367692307692 ], [ 29.760571551724137, -1.499828738461538 ], [ 29.760302068965519, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5276, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.499828738461538 ], [ 29.760571551724137, -1.500367692307692 ], [ 29.760841034482759, -1.500367692307692 ], [ 29.760841034482759, -1.499828738461538 ], [ 29.760571551724137, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5277, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.500098215384615 ], [ 29.718262758620689, -1.500637169230769 ], [ 29.718532241379311, -1.500637169230769 ], [ 29.718532241379311, -1.500098215384615 ], [ 29.718262758620689, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5278, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.500098215384615 ], [ 29.719071206896551, -1.500367692307692 ], [ 29.719340689655173, -1.500367692307692 ], [ 29.719340689655173, -1.500098215384615 ], [ 29.719071206896551, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5279, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.500098215384615 ], [ 29.719340689655173, -1.500637169230769 ], [ 29.719610172413795, -1.500637169230769 ], [ 29.719610172413795, -1.500098215384615 ], [ 29.719340689655173, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5280, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.500098215384615 ], [ 29.719610172413795, -1.500367692307692 ], [ 29.719879655172413, -1.500367692307692 ], [ 29.719879655172413, -1.500098215384615 ], [ 29.719610172413795, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5281, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.500098215384615 ], [ 29.719879655172413, -1.500367692307692 ], [ 29.720149137931035, -1.500367692307692 ], [ 29.720149137931035, -1.500098215384615 ], [ 29.719879655172413, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5282, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.500098215384615 ], [ 29.720149137931035, -1.500367692307692 ], [ 29.720688103448275, -1.500367692307692 ], [ 29.720688103448275, -1.500098215384615 ], [ 29.720149137931035, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5283, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.500098215384615 ], [ 29.720688103448275, -1.500367692307692 ], [ 29.720957586206897, -1.500367692307692 ], [ 29.720957586206897, -1.500098215384615 ], [ 29.720688103448275, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5284, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.500098215384615 ], [ 29.720957586206897, -1.500367692307692 ], [ 29.72257448275862, -1.500367692307692 ], [ 29.72257448275862, -1.500098215384615 ], [ 29.720957586206897, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5285, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.500098215384615 ], [ 29.72257448275862, -1.500367692307692 ], [ 29.722843965517242, -1.500367692307692 ], [ 29.722843965517242, -1.500098215384615 ], [ 29.72257448275862, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5286, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.500098215384615 ], [ 29.722843965517242, -1.500367692307692 ], [ 29.723113448275861, -1.500367692307692 ], [ 29.723113448275861, -1.500098215384615 ], [ 29.722843965517242, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5287, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.500098215384615 ], [ 29.723113448275861, -1.500367692307692 ], [ 29.723382931034482, -1.500367692307692 ], [ 29.723382931034482, -1.500098215384615 ], [ 29.723113448275861, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5288, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.500098215384615 ], [ 29.723382931034482, -1.500367692307692 ], [ 29.723652413793104, -1.500367692307692 ], [ 29.723652413793104, -1.500098215384615 ], [ 29.723382931034482, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5289, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.500098215384615 ], [ 29.723652413793104, -1.500367692307692 ], [ 29.723921896551722, -1.500367692307692 ], [ 29.723921896551722, -1.500098215384615 ], [ 29.723652413793104, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5290, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.500098215384615 ], [ 29.723921896551722, -1.500367692307692 ], [ 29.724191379310344, -1.500367692307692 ], [ 29.724191379310344, -1.500098215384615 ], [ 29.723921896551722, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5291, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.500098215384615 ], [ 29.724191379310344, -1.500367692307692 ], [ 29.724460862068966, -1.500367692307692 ], [ 29.724460862068966, -1.500098215384615 ], [ 29.724191379310344, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5292, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.500098215384615 ], [ 29.724460862068966, -1.500367692307692 ], [ 29.724730344827588, -1.500367692307692 ], [ 29.724730344827588, -1.500098215384615 ], [ 29.724460862068966, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5293, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.500098215384615 ], [ 29.724730344827588, -1.500367692307692 ], [ 29.724999827586206, -1.500367692307692 ], [ 29.724999827586206, -1.500098215384615 ], [ 29.724730344827588, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5294, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.500098215384615 ], [ 29.724999827586206, -1.500367692307692 ], [ 29.725269310344828, -1.500367692307692 ], [ 29.725269310344828, -1.500098215384615 ], [ 29.724999827586206, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5295, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.500098215384615 ], [ 29.725269310344828, -1.500367692307692 ], [ 29.72553879310345, -1.500367692307692 ], [ 29.72553879310345, -1.500098215384615 ], [ 29.725269310344828, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5296, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.500098215384615 ], [ 29.72553879310345, -1.500367692307692 ], [ 29.725808275862068, -1.500367692307692 ], [ 29.725808275862068, -1.500098215384615 ], [ 29.72553879310345, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5297, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.500098215384615 ], [ 29.725808275862068, -1.500367692307692 ], [ 29.72607775862069, -1.500367692307692 ], [ 29.72607775862069, -1.500098215384615 ], [ 29.725808275862068, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5298, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.500098215384615 ], [ 29.72661672413793, -1.500637169230769 ], [ 29.726886206896552, -1.500637169230769 ], [ 29.726886206896552, -1.500098215384615 ], [ 29.72661672413793, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5299, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.500098215384615 ], [ 29.726886206896552, -1.500637169230769 ], [ 29.727155689655174, -1.500637169230769 ], [ 29.727155689655174, -1.500098215384615 ], [ 29.726886206896552, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5300, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.500098215384615 ], [ 29.727694655172414, -1.500637169230769 ], [ 29.727964137931036, -1.500637169230769 ], [ 29.727964137931036, -1.500098215384615 ], [ 29.727694655172414, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5301, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.500098215384615 ], [ 29.728503103448276, -1.500367692307692 ], [ 29.728772586206897, -1.500367692307692 ], [ 29.728772586206897, -1.500098215384615 ], [ 29.728503103448276, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5302, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.500098215384615 ], [ 29.728772586206897, -1.500367692307692 ], [ 29.729042068965516, -1.500367692307692 ], [ 29.729042068965516, -1.500098215384615 ], [ 29.728772586206897, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5303, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.500098215384615 ], [ 29.729042068965516, -1.500367692307692 ], [ 29.729311551724138, -1.500367692307692 ], [ 29.729311551724138, -1.500098215384615 ], [ 29.729042068965516, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5304, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.500098215384615 ], [ 29.729311551724138, -1.500367692307692 ], [ 29.729850517241381, -1.500367692307692 ], [ 29.729850517241381, -1.500098215384615 ], [ 29.729311551724138, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5305, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.500098215384615 ], [ 29.729850517241381, -1.500367692307692 ], [ 29.73012, -1.500367692307692 ], [ 29.73012, -1.500098215384615 ], [ 29.729850517241381, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5306, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.500098215384615 ], [ 29.73012, -1.500367692307692 ], [ 29.730389482758621, -1.500367692307692 ], [ 29.730389482758621, -1.500098215384615 ], [ 29.73012, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5307, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.500098215384615 ], [ 29.730389482758621, -1.500367692307692 ], [ 29.730658965517243, -1.500367692307692 ], [ 29.730658965517243, -1.500098215384615 ], [ 29.730389482758621, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5308, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.500098215384615 ], [ 29.730658965517243, -1.500367692307692 ], [ 29.730928448275861, -1.500367692307692 ], [ 29.730928448275861, -1.500098215384615 ], [ 29.730658965517243, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5309, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.500098215384615 ], [ 29.734431724137931, -1.500367692307692 ], [ 29.734701206896553, -1.500367692307692 ], [ 29.734701206896553, -1.500098215384615 ], [ 29.734431724137931, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5310, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.500098215384615 ], [ 29.734701206896553, -1.500367692307692 ], [ 29.734970689655171, -1.500367692307692 ], [ 29.734970689655171, -1.500098215384615 ], [ 29.734701206896553, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5311, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.500098215384615 ], [ 29.734970689655171, -1.500367692307692 ], [ 29.735240172413793, -1.500367692307692 ], [ 29.735240172413793, -1.500098215384615 ], [ 29.734970689655171, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5312, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.500098215384615 ], [ 29.735240172413793, -1.500367692307692 ], [ 29.735509655172415, -1.500367692307692 ], [ 29.735509655172415, -1.500098215384615 ], [ 29.735240172413793, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5313, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.500098215384615 ], [ 29.735509655172415, -1.500367692307692 ], [ 29.735779137931036, -1.500367692307692 ], [ 29.735779137931036, -1.500098215384615 ], [ 29.735509655172415, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5314, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.500098215384615 ], [ 29.735779137931036, -1.500367692307692 ], [ 29.736048620689655, -1.500367692307692 ], [ 29.736048620689655, -1.500098215384615 ], [ 29.735779137931036, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5315, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.500098215384615 ], [ 29.736048620689655, -1.500367692307692 ], [ 29.736318103448276, -1.500367692307692 ], [ 29.736318103448276, -1.500098215384615 ], [ 29.736048620689655, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5316, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.500098215384615 ], [ 29.736318103448276, -1.500367692307692 ], [ 29.736587586206898, -1.500367692307692 ], [ 29.736587586206898, -1.500098215384615 ], [ 29.736318103448276, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5317, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.500098215384615 ], [ 29.736587586206898, -1.500367692307692 ], [ 29.736857068965517, -1.500367692307692 ], [ 29.736857068965517, -1.500098215384615 ], [ 29.736587586206898, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5318, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.500098215384615 ], [ 29.736857068965517, -1.500367692307692 ], [ 29.737126551724138, -1.500367692307692 ], [ 29.737126551724138, -1.500098215384615 ], [ 29.736857068965517, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5319, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.500098215384615 ], [ 29.739551896551724, -1.500637169230769 ], [ 29.739821379310346, -1.500637169230769 ], [ 29.739821379310346, -1.500098215384615 ], [ 29.739551896551724, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5320, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.500098215384615 ], [ 29.740360344827586, -1.500637169230769 ], [ 29.740629827586208, -1.500637169230769 ], [ 29.740629827586208, -1.500098215384615 ], [ 29.740360344827586, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5321, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.500098215384615 ], [ 29.750061724137932, -1.500367692307692 ], [ 29.750331206896551, -1.500367692307692 ], [ 29.750331206896551, -1.500098215384615 ], [ 29.750061724137932, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5322, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.500098215384615 ], [ 29.750331206896551, -1.500637169230769 ], [ 29.750600689655172, -1.500637169230769 ], [ 29.750600689655172, -1.500098215384615 ], [ 29.750331206896551, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5323, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.500098215384615 ], [ 29.750600689655172, -1.500367692307692 ], [ 29.750870172413794, -1.500367692307692 ], [ 29.750870172413794, -1.500098215384615 ], [ 29.750600689655172, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5324, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.500098215384615 ], [ 29.750870172413794, -1.500367692307692 ], [ 29.751139655172413, -1.500367692307692 ], [ 29.751139655172413, -1.500098215384615 ], [ 29.750870172413794, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5325, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.500098215384615 ], [ 29.751139655172413, -1.500367692307692 ], [ 29.751409137931034, -1.500367692307692 ], [ 29.751409137931034, -1.500098215384615 ], [ 29.751139655172413, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5326, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.500098215384615 ], [ 29.751409137931034, -1.500367692307692 ], [ 29.751678620689656, -1.500367692307692 ], [ 29.751678620689656, -1.500098215384615 ], [ 29.751409137931034, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5327, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.500098215384615 ], [ 29.751678620689656, -1.500367692307692 ], [ 29.751948103448278, -1.500367692307692 ], [ 29.751948103448278, -1.500098215384615 ], [ 29.751678620689656, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5328, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.500098215384615 ], [ 29.751948103448278, -1.500367692307692 ], [ 29.752217586206896, -1.500367692307692 ], [ 29.752217586206896, -1.500098215384615 ], [ 29.751948103448278, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5329, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.500098215384615 ], [ 29.752217586206896, -1.500367692307692 ], [ 29.752487068965518, -1.500367692307692 ], [ 29.752487068965518, -1.500098215384615 ], [ 29.752217586206896, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5330, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.500098215384615 ], [ 29.752487068965518, -1.500367692307692 ], [ 29.75275655172414, -1.500367692307692 ], [ 29.75275655172414, -1.500098215384615 ], [ 29.752487068965518, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5331, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.500098215384615 ], [ 29.75275655172414, -1.500367692307692 ], [ 29.753026034482758, -1.500367692307692 ], [ 29.753026034482758, -1.500098215384615 ], [ 29.75275655172414, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5332, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.500098215384615 ], [ 29.753026034482758, -1.500367692307692 ], [ 29.75329551724138, -1.500367692307692 ], [ 29.75329551724138, -1.500098215384615 ], [ 29.753026034482758, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5333, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.500098215384615 ], [ 29.75329551724138, -1.500367692307692 ], [ 29.753565, -1.500367692307692 ], [ 29.753565, -1.500098215384615 ], [ 29.75329551724138, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5334, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.500098215384615 ], [ 29.753565, -1.500367692307692 ], [ 29.75383448275862, -1.500367692307692 ], [ 29.75383448275862, -1.500098215384615 ], [ 29.753565, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5335, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.500098215384615 ], [ 29.75383448275862, -1.500367692307692 ], [ 29.754103965517242, -1.500367692307692 ], [ 29.754103965517242, -1.500098215384615 ], [ 29.75383448275862, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5336, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.500098215384615 ], [ 29.754103965517242, -1.500367692307692 ], [ 29.754373448275864, -1.500367692307692 ], [ 29.754373448275864, -1.500098215384615 ], [ 29.754103965517242, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5337, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.500098215384615 ], [ 29.754373448275864, -1.500367692307692 ], [ 29.754642931034482, -1.500367692307692 ], [ 29.754642931034482, -1.500098215384615 ], [ 29.754373448275864, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5338, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.500098215384615 ], [ 29.754642931034482, -1.500367692307692 ], [ 29.754912413793104, -1.500367692307692 ], [ 29.754912413793104, -1.500098215384615 ], [ 29.754642931034482, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5339, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.500098215384615 ], [ 29.754912413793104, -1.500367692307692 ], [ 29.755181896551726, -1.500367692307692 ], [ 29.755181896551726, -1.500098215384615 ], [ 29.754912413793104, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5340, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.500098215384615 ], [ 29.755181896551726, -1.500367692307692 ], [ 29.755451379310344, -1.500367692307692 ], [ 29.755451379310344, -1.500098215384615 ], [ 29.755181896551726, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5341, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.500098215384615 ], [ 29.755451379310344, -1.500367692307692 ], [ 29.755720862068966, -1.500367692307692 ], [ 29.755720862068966, -1.500098215384615 ], [ 29.755451379310344, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5342, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.500098215384615 ], [ 29.755720862068966, -1.500367692307692 ], [ 29.755990344827588, -1.500367692307692 ], [ 29.755990344827588, -1.500098215384615 ], [ 29.755720862068966, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5343, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.500098215384615 ], [ 29.756798793103449, -1.500367692307692 ], [ 29.757068275862068, -1.500367692307692 ], [ 29.757068275862068, -1.500098215384615 ], [ 29.756798793103449, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5344, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.500098215384615 ], [ 29.757068275862068, -1.500367692307692 ], [ 29.75733775862069, -1.500367692307692 ], [ 29.75733775862069, -1.500098215384615 ], [ 29.757068275862068, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5345, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.500098215384615 ], [ 29.75733775862069, -1.500367692307692 ], [ 29.757607241379311, -1.500367692307692 ], [ 29.757607241379311, -1.500098215384615 ], [ 29.75733775862069, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5346, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.500098215384615 ], [ 29.757607241379311, -1.500637169230769 ], [ 29.757876724137933, -1.500637169230769 ], [ 29.757876724137933, -1.500098215384615 ], [ 29.757607241379311, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5347, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.500098215384615 ], [ 29.758146206896551, -1.500367692307692 ], [ 29.758415689655173, -1.500367692307692 ], [ 29.758415689655173, -1.500098215384615 ], [ 29.758146206896551, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5348, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.500098215384615 ], [ 29.758415689655173, -1.500367692307692 ], [ 29.758685172413795, -1.500367692307692 ], [ 29.758685172413795, -1.500098215384615 ], [ 29.758415689655173, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5349, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.500098215384615 ], [ 29.758685172413795, -1.500367692307692 ], [ 29.758954655172413, -1.500367692307692 ], [ 29.758954655172413, -1.500098215384615 ], [ 29.758685172413795, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5350, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.500098215384615 ], [ 29.758954655172413, -1.500367692307692 ], [ 29.759224137931035, -1.500367692307692 ], [ 29.759224137931035, -1.500098215384615 ], [ 29.758954655172413, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5351, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.500098215384615 ], [ 29.759224137931035, -1.500367692307692 ], [ 29.759493620689657, -1.500367692307692 ], [ 29.759493620689657, -1.500098215384615 ], [ 29.759224137931035, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5352, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.500098215384615 ], [ 29.759493620689657, -1.500367692307692 ], [ 29.759763103448275, -1.500367692307692 ], [ 29.759763103448275, -1.500098215384615 ], [ 29.759493620689657, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5353, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.500098215384615 ], [ 29.759763103448275, -1.500637169230769 ], [ 29.760032586206897, -1.500637169230769 ], [ 29.760032586206897, -1.500098215384615 ], [ 29.759763103448275, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5354, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.500098215384615 ], [ 29.760032586206897, -1.500637169230769 ], [ 29.760302068965519, -1.500637169230769 ], [ 29.760302068965519, -1.500098215384615 ], [ 29.760032586206897, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5355, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.500098215384615 ], [ 29.761110517241381, -1.500637169230769 ], [ 29.76138, -1.500637169230769 ], [ 29.76138, -1.500098215384615 ], [ 29.761110517241381, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5356, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.500098215384615 ], [ 29.761918965517243, -1.500367692307692 ], [ 29.762188448275861, -1.500367692307692 ], [ 29.762188448275861, -1.500098215384615 ], [ 29.761918965517243, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5357, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.500367692307692 ], [ 29.718532241379311, -1.500637169230769 ], [ 29.718801724137933, -1.500637169230769 ], [ 29.718801724137933, -1.500367692307692 ], [ 29.718532241379311, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5358, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.500367692307692 ], [ 29.719610172413795, -1.500637169230769 ], [ 29.719879655172413, -1.500637169230769 ], [ 29.719879655172413, -1.500367692307692 ], [ 29.719610172413795, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5359, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.500367692307692 ], [ 29.719879655172413, -1.500637169230769 ], [ 29.720149137931035, -1.500637169230769 ], [ 29.720149137931035, -1.500367692307692 ], [ 29.719879655172413, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5360, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.500367692307692 ], [ 29.720149137931035, -1.500637169230769 ], [ 29.720688103448275, -1.500637169230769 ], [ 29.720688103448275, -1.500367692307692 ], [ 29.720149137931035, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5361, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.500367692307692 ], [ 29.720688103448275, -1.500637169230769 ], [ 29.720957586206897, -1.500637169230769 ], [ 29.720957586206897, -1.500367692307692 ], [ 29.720688103448275, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5362, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.500367692307692 ], [ 29.720957586206897, -1.500637169230769 ], [ 29.721227068965518, -1.500637169230769 ], [ 29.721227068965518, -1.500367692307692 ], [ 29.720957586206897, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5363, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.500367692307692 ], [ 29.721227068965518, -1.500637169230769 ], [ 29.722305, -1.500637169230769 ], [ 29.722305, -1.500367692307692 ], [ 29.721227068965518, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5364, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.500367692307692 ], [ 29.722305, -1.500637169230769 ], [ 29.72257448275862, -1.500637169230769 ], [ 29.72257448275862, -1.500367692307692 ], [ 29.722305, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5365, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.500367692307692 ], [ 29.72257448275862, -1.500637169230769 ], [ 29.722843965517242, -1.500637169230769 ], [ 29.722843965517242, -1.500367692307692 ], [ 29.72257448275862, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5366, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.500367692307692 ], [ 29.722843965517242, -1.500637169230769 ], [ 29.723113448275861, -1.500637169230769 ], [ 29.723113448275861, -1.500367692307692 ], [ 29.722843965517242, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5367, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.500367692307692 ], [ 29.723113448275861, -1.500637169230769 ], [ 29.723382931034482, -1.500637169230769 ], [ 29.723382931034482, -1.500367692307692 ], [ 29.723113448275861, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5368, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.500367692307692 ], [ 29.723382931034482, -1.500637169230769 ], [ 29.723652413793104, -1.500637169230769 ], [ 29.723652413793104, -1.500367692307692 ], [ 29.723382931034482, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5369, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.500367692307692 ], [ 29.723652413793104, -1.500637169230769 ], [ 29.723921896551722, -1.500637169230769 ], [ 29.723921896551722, -1.500367692307692 ], [ 29.723652413793104, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5370, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.500367692307692 ], [ 29.723921896551722, -1.500637169230769 ], [ 29.724191379310344, -1.500637169230769 ], [ 29.724191379310344, -1.500367692307692 ], [ 29.723921896551722, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5371, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.500367692307692 ], [ 29.724191379310344, -1.500637169230769 ], [ 29.724460862068966, -1.500637169230769 ], [ 29.724460862068966, -1.500367692307692 ], [ 29.724191379310344, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5372, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.500367692307692 ], [ 29.724460862068966, -1.500637169230769 ], [ 29.724730344827588, -1.500637169230769 ], [ 29.724730344827588, -1.500367692307692 ], [ 29.724460862068966, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5373, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.500367692307692 ], [ 29.724730344827588, -1.500637169230769 ], [ 29.724999827586206, -1.500637169230769 ], [ 29.724999827586206, -1.500367692307692 ], [ 29.724730344827588, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5374, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.500367692307692 ], [ 29.724999827586206, -1.500637169230769 ], [ 29.725269310344828, -1.500637169230769 ], [ 29.725269310344828, -1.500367692307692 ], [ 29.724999827586206, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5375, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.500367692307692 ], [ 29.725269310344828, -1.500637169230769 ], [ 29.72553879310345, -1.500637169230769 ], [ 29.72553879310345, -1.500367692307692 ], [ 29.725269310344828, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5376, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.500367692307692 ], [ 29.72553879310345, -1.500637169230769 ], [ 29.725808275862068, -1.500637169230769 ], [ 29.725808275862068, -1.500367692307692 ], [ 29.72553879310345, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5377, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.500367692307692 ], [ 29.727155689655174, -1.500637169230769 ], [ 29.727425172413792, -1.500637169230769 ], [ 29.727425172413792, -1.500367692307692 ], [ 29.727155689655174, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5378, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.500367692307692 ], [ 29.727964137931036, -1.500637169230769 ], [ 29.728233620689654, -1.500637169230769 ], [ 29.728233620689654, -1.500367692307692 ], [ 29.727964137931036, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5379, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.500367692307692 ], [ 29.728233620689654, -1.500637169230769 ], [ 29.728503103448276, -1.500637169230769 ], [ 29.728503103448276, -1.500367692307692 ], [ 29.728233620689654, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5380, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.500367692307692 ], [ 29.728503103448276, -1.500637169230769 ], [ 29.728772586206897, -1.500637169230769 ], [ 29.728772586206897, -1.500367692307692 ], [ 29.728503103448276, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5381, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.500367692307692 ], [ 29.728772586206897, -1.500637169230769 ], [ 29.729042068965516, -1.500637169230769 ], [ 29.729042068965516, -1.500367692307692 ], [ 29.728772586206897, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5382, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.500367692307692 ], [ 29.729042068965516, -1.500637169230769 ], [ 29.729311551724138, -1.500637169230769 ], [ 29.729311551724138, -1.500367692307692 ], [ 29.729042068965516, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5383, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.500367692307692 ], [ 29.729311551724138, -1.500637169230769 ], [ 29.729850517241381, -1.500637169230769 ], [ 29.729850517241381, -1.500367692307692 ], [ 29.729311551724138, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5384, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.500367692307692 ], [ 29.729850517241381, -1.500637169230769 ], [ 29.73012, -1.500637169230769 ], [ 29.73012, -1.500367692307692 ], [ 29.729850517241381, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5385, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.500367692307692 ], [ 29.73012, -1.500637169230769 ], [ 29.730389482758621, -1.500637169230769 ], [ 29.730389482758621, -1.500367692307692 ], [ 29.73012, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5386, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.500367692307692 ], [ 29.734701206896553, -1.500637169230769 ], [ 29.734970689655171, -1.500637169230769 ], [ 29.734970689655171, -1.500367692307692 ], [ 29.734701206896553, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5387, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.500367692307692 ], [ 29.734970689655171, -1.500637169230769 ], [ 29.735240172413793, -1.500637169230769 ], [ 29.735240172413793, -1.500367692307692 ], [ 29.734970689655171, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5388, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.500367692307692 ], [ 29.735240172413793, -1.500637169230769 ], [ 29.735509655172415, -1.500637169230769 ], [ 29.735509655172415, -1.500367692307692 ], [ 29.735240172413793, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5389, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.500367692307692 ], [ 29.735509655172415, -1.500637169230769 ], [ 29.735779137931036, -1.500637169230769 ], [ 29.735779137931036, -1.500367692307692 ], [ 29.735509655172415, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5390, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.500367692307692 ], [ 29.735779137931036, -1.500637169230769 ], [ 29.736048620689655, -1.500637169230769 ], [ 29.736048620689655, -1.500367692307692 ], [ 29.735779137931036, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5391, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.500367692307692 ], [ 29.736048620689655, -1.500637169230769 ], [ 29.736318103448276, -1.500637169230769 ], [ 29.736318103448276, -1.500367692307692 ], [ 29.736048620689655, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5392, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.500367692307692 ], [ 29.736318103448276, -1.500637169230769 ], [ 29.736587586206898, -1.500637169230769 ], [ 29.736587586206898, -1.500367692307692 ], [ 29.736318103448276, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5393, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.500367692307692 ], [ 29.736587586206898, -1.500637169230769 ], [ 29.736857068965517, -1.500637169230769 ], [ 29.736857068965517, -1.500367692307692 ], [ 29.736587586206898, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5394, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.500367692307692 ], [ 29.749522758620689, -1.500637169230769 ], [ 29.749792241379311, -1.500637169230769 ], [ 29.749792241379311, -1.500367692307692 ], [ 29.749522758620689, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5395, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.500367692307692 ], [ 29.750870172413794, -1.500637169230769 ], [ 29.751139655172413, -1.500637169230769 ], [ 29.751139655172413, -1.500367692307692 ], [ 29.750870172413794, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5396, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.500367692307692 ], [ 29.751139655172413, -1.500637169230769 ], [ 29.751409137931034, -1.500637169230769 ], [ 29.751409137931034, -1.500367692307692 ], [ 29.751139655172413, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5397, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.500367692307692 ], [ 29.751409137931034, -1.500637169230769 ], [ 29.751678620689656, -1.500637169230769 ], [ 29.751678620689656, -1.500367692307692 ], [ 29.751409137931034, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5398, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.500367692307692 ], [ 29.751678620689656, -1.500637169230769 ], [ 29.751948103448278, -1.500637169230769 ], [ 29.751948103448278, -1.500367692307692 ], [ 29.751678620689656, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5399, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.500367692307692 ], [ 29.751948103448278, -1.500637169230769 ], [ 29.752217586206896, -1.500637169230769 ], [ 29.752217586206896, -1.500367692307692 ], [ 29.751948103448278, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5400, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.500367692307692 ], [ 29.752217586206896, -1.500637169230769 ], [ 29.752487068965518, -1.500637169230769 ], [ 29.752487068965518, -1.500367692307692 ], [ 29.752217586206896, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5401, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.500367692307692 ], [ 29.752487068965518, -1.500637169230769 ], [ 29.75275655172414, -1.500637169230769 ], [ 29.75275655172414, -1.500367692307692 ], [ 29.752487068965518, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5402, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.500367692307692 ], [ 29.75275655172414, -1.500637169230769 ], [ 29.753026034482758, -1.500637169230769 ], [ 29.753026034482758, -1.500367692307692 ], [ 29.75275655172414, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5403, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.500367692307692 ], [ 29.753026034482758, -1.500637169230769 ], [ 29.75329551724138, -1.500637169230769 ], [ 29.75329551724138, -1.500367692307692 ], [ 29.753026034482758, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5404, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.500367692307692 ], [ 29.75329551724138, -1.500637169230769 ], [ 29.753565, -1.500637169230769 ], [ 29.753565, -1.500367692307692 ], [ 29.75329551724138, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5405, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.500367692307692 ], [ 29.753565, -1.500637169230769 ], [ 29.75383448275862, -1.500637169230769 ], [ 29.75383448275862, -1.500367692307692 ], [ 29.753565, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5406, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.500367692307692 ], [ 29.75383448275862, -1.500637169230769 ], [ 29.754103965517242, -1.500637169230769 ], [ 29.754103965517242, -1.500367692307692 ], [ 29.75383448275862, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5407, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.500367692307692 ], [ 29.754103965517242, -1.500637169230769 ], [ 29.754373448275864, -1.500637169230769 ], [ 29.754373448275864, -1.500367692307692 ], [ 29.754103965517242, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5408, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.500367692307692 ], [ 29.754373448275864, -1.500637169230769 ], [ 29.754642931034482, -1.500637169230769 ], [ 29.754642931034482, -1.500367692307692 ], [ 29.754373448275864, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5409, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.500367692307692 ], [ 29.754642931034482, -1.500637169230769 ], [ 29.754912413793104, -1.500637169230769 ], [ 29.754912413793104, -1.500367692307692 ], [ 29.754642931034482, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5410, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.500367692307692 ], [ 29.754912413793104, -1.500637169230769 ], [ 29.755181896551726, -1.500637169230769 ], [ 29.755181896551726, -1.500367692307692 ], [ 29.754912413793104, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5411, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.500367692307692 ], [ 29.755181896551726, -1.500637169230769 ], [ 29.755451379310344, -1.500637169230769 ], [ 29.755451379310344, -1.500367692307692 ], [ 29.755181896551726, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5412, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.500367692307692 ], [ 29.755451379310344, -1.500637169230769 ], [ 29.755720862068966, -1.500637169230769 ], [ 29.755720862068966, -1.500367692307692 ], [ 29.755451379310344, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5413, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.500367692307692 ], [ 29.755720862068966, -1.500637169230769 ], [ 29.755990344827588, -1.500637169230769 ], [ 29.755990344827588, -1.500367692307692 ], [ 29.755720862068966, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5414, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.500367692307692 ], [ 29.756259827586206, -1.500637169230769 ], [ 29.756798793103449, -1.500637169230769 ], [ 29.756798793103449, -1.500367692307692 ], [ 29.756259827586206, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5415, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.500367692307692 ], [ 29.756798793103449, -1.500637169230769 ], [ 29.757068275862068, -1.500637169230769 ], [ 29.757068275862068, -1.500367692307692 ], [ 29.756798793103449, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5416, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.500367692307692 ], [ 29.757068275862068, -1.500637169230769 ], [ 29.75733775862069, -1.500637169230769 ], [ 29.75733775862069, -1.500367692307692 ], [ 29.757068275862068, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5417, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.500367692307692 ], [ 29.75733775862069, -1.500637169230769 ], [ 29.757607241379311, -1.500637169230769 ], [ 29.757607241379311, -1.500367692307692 ], [ 29.75733775862069, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5418, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.500367692307692 ], [ 29.757876724137933, -1.500637169230769 ], [ 29.758146206896551, -1.500637169230769 ], [ 29.758146206896551, -1.500367692307692 ], [ 29.757876724137933, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5419, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.500367692307692 ], [ 29.758146206896551, -1.500637169230769 ], [ 29.758415689655173, -1.500637169230769 ], [ 29.758415689655173, -1.500367692307692 ], [ 29.758146206896551, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5420, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.500367692307692 ], [ 29.758415689655173, -1.500637169230769 ], [ 29.758685172413795, -1.500637169230769 ], [ 29.758685172413795, -1.500367692307692 ], [ 29.758415689655173, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5421, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.500367692307692 ], [ 29.758685172413795, -1.500637169230769 ], [ 29.758954655172413, -1.500637169230769 ], [ 29.758954655172413, -1.500367692307692 ], [ 29.758685172413795, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5422, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.500367692307692 ], [ 29.758954655172413, -1.500637169230769 ], [ 29.759224137931035, -1.500637169230769 ], [ 29.759224137931035, -1.500367692307692 ], [ 29.758954655172413, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5423, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.500367692307692 ], [ 29.760302068965519, -1.500637169230769 ], [ 29.760571551724137, -1.500637169230769 ], [ 29.760571551724137, -1.500367692307692 ], [ 29.760302068965519, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5424, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.500367692307692 ], [ 29.760571551724137, -1.500637169230769 ], [ 29.760841034482759, -1.500637169230769 ], [ 29.760841034482759, -1.500367692307692 ], [ 29.760571551724137, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5425, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.500367692307692 ], [ 29.760841034482759, -1.500637169230769 ], [ 29.761110517241381, -1.500637169230769 ], [ 29.761110517241381, -1.500367692307692 ], [ 29.760841034482759, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5426, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.500367692307692 ], [ 29.76138, -1.500637169230769 ], [ 29.761649482758621, -1.500637169230769 ], [ 29.761649482758621, -1.500367692307692 ], [ 29.76138, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5428, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.499289784615385 ], [ 29.737935, -1.501176123076923 ], [ 29.738204482758622, -1.501176123076923 ], [ 29.738204482758622, -1.499289784615385 ], [ 29.737935, -1.499289784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5429, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.499559261538461 ], [ 29.731197931034483, -1.499828738461538 ], [ 29.731467413793105, -1.499828738461538 ], [ 29.731467413793105, -1.5014456 ], [ 29.731736896551723, -1.5014456 ], [ 29.731736896551723, -1.499559261538461 ], [ 29.731197931034483, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5430, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.499559261538461 ], [ 29.733084310344829, -1.499828738461538 ], [ 29.733353793103447, -1.499828738461538 ], [ 29.733353793103447, -1.501715076923077 ], [ 29.733623275862069, -1.501715076923077 ], [ 29.733623275862069, -1.499828738461538 ], [ 29.733892758620691, -1.499828738461538 ], [ 29.733892758620691, -1.499559261538461 ], [ 29.733084310344829, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5431, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.499559261538461 ], [ 29.73739603448276, -1.5014456 ], [ 29.737665517241378, -1.5014456 ], [ 29.737665517241378, -1.499559261538461 ], [ 29.73739603448276, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5432, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.499559261538461 ], [ 29.738204482758622, -1.501176123076923 ], [ 29.738743448275862, -1.501176123076923 ], [ 29.738743448275862, -1.499559261538461 ], [ 29.738204482758622, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5433, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.499828738461538 ], [ 29.730928448275861, -1.500098215384615 ], [ 29.731197931034483, -1.500098215384615 ], [ 29.731197931034483, -1.501715076923077 ], [ 29.731467413793105, -1.501715076923077 ], [ 29.731467413793105, -1.499828738461538 ], [ 29.730928448275861, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5434, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.499828738461538 ], [ 29.733084310344829, -1.501715076923077 ], [ 29.733353793103447, -1.501715076923077 ], [ 29.733353793103447, -1.499828738461538 ], [ 29.733084310344829, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5435, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.499559261538461 ], [ 29.73739603448276, -1.499559261538461 ], [ 29.73739603448276, -1.501176123076923 ], [ 29.737126551724138, -1.501176123076923 ], [ 29.737126551724138, -1.500098215384615 ], [ 29.736857068965517, -1.500098215384615 ], [ 29.736857068965517, -1.499828738461538 ], [ 29.737126551724138, -1.499828738461538 ], [ 29.737126551724138, -1.499559261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5436, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.499828738461538 ], [ 29.737665517241378, -1.500906646153846 ], [ 29.737935, -1.500906646153846 ], [ 29.737935, -1.499828738461538 ], [ 29.737665517241378, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5437, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.499828738461538 ], [ 29.738743448275862, -1.500906646153846 ], [ 29.739012931034484, -1.500906646153846 ], [ 29.739012931034484, -1.499828738461538 ], [ 29.738743448275862, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5438, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.499828738461538 ], [ 29.739012931034484, -1.500906646153846 ], [ 29.739282413793102, -1.500906646153846 ], [ 29.739282413793102, -1.499828738461538 ], [ 29.739012931034484, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5439, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.499828738461538 ], [ 29.739282413793102, -1.500906646153846 ], [ 29.739551896551724, -1.500906646153846 ], [ 29.739551896551724, -1.499828738461538 ], [ 29.739282413793102, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5440, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.499828738461538 ], [ 29.739821379310346, -1.500906646153846 ], [ 29.740090862068964, -1.500906646153846 ], [ 29.740090862068964, -1.499828738461538 ], [ 29.739821379310346, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5441, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.499828738461538 ], [ 29.740090862068964, -1.500906646153846 ], [ 29.740360344827586, -1.500906646153846 ], [ 29.740360344827586, -1.499828738461538 ], [ 29.740090862068964, -1.499828738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5442, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.500098215384615 ], [ 29.730928448275861, -1.501715076923077 ], [ 29.731197931034483, -1.501715076923077 ], [ 29.731197931034483, -1.500098215384615 ], [ 29.730928448275861, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5443, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.500098215384615 ], [ 29.732814827586207, -1.5014456 ], [ 29.733084310344829, -1.5014456 ], [ 29.733084310344829, -1.500098215384615 ], [ 29.732814827586207, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5444, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.500098215384615 ], [ 29.733623275862069, -1.5014456 ], [ 29.733892758620691, -1.5014456 ], [ 29.733892758620691, -1.500098215384615 ], [ 29.733623275862069, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5445, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.500098215384615 ], [ 29.733892758620691, -1.501715076923077 ], [ 29.734162241379309, -1.501715076923077 ], [ 29.734162241379309, -1.500098215384615 ], [ 29.733892758620691, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5446, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.500098215384615 ], [ 29.734162241379309, -1.501715076923077 ], [ 29.734431724137931, -1.501715076923077 ], [ 29.734431724137931, -1.500098215384615 ], [ 29.734162241379309, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5447, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.500098215384615 ], [ 29.762188448275861, -1.500906646153846 ], [ 29.762457931034483, -1.500906646153846 ], [ 29.762457931034483, -1.500098215384615 ], [ 29.762188448275861, -1.500098215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5448, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.500367692307692 ], [ 29.718801724137933, -1.500906646153846 ], [ 29.719071206896551, -1.500906646153846 ], [ 29.719071206896551, -1.500367692307692 ], [ 29.718801724137933, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5449, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.500367692307692 ], [ 29.719071206896551, -1.500906646153846 ], [ 29.719340689655173, -1.500906646153846 ], [ 29.719340689655173, -1.500367692307692 ], [ 29.719071206896551, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5450, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.500367692307692 ], [ 29.725808275862068, -1.500906646153846 ], [ 29.72607775862069, -1.500906646153846 ], [ 29.72607775862069, -1.500367692307692 ], [ 29.725808275862068, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5451, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.500367692307692 ], [ 29.72607775862069, -1.500637169230769 ], [ 29.726347241379312, -1.500637169230769 ], [ 29.726347241379312, -1.5014456 ], [ 29.726886206896552, -1.5014456 ], [ 29.726886206896552, -1.501176123076923 ], [ 29.72661672413793, -1.501176123076923 ], [ 29.72661672413793, -1.500367692307692 ], [ 29.72607775862069, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5452, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.500367692307692 ], [ 29.727425172413792, -1.500906646153846 ], [ 29.727694655172414, -1.500906646153846 ], [ 29.727694655172414, -1.500367692307692 ], [ 29.727425172413792, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5453, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.500367692307692 ], [ 29.730389482758621, -1.501715076923077 ], [ 29.730658965517243, -1.501715076923077 ], [ 29.730658965517243, -1.500367692307692 ], [ 29.730389482758621, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5454, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.500367692307692 ], [ 29.730658965517243, -1.5014456 ], [ 29.730928448275861, -1.5014456 ], [ 29.730928448275861, -1.500367692307692 ], [ 29.730658965517243, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5455, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.500367692307692 ], [ 29.734431724137931, -1.501715076923077 ], [ 29.734701206896553, -1.501715076923077 ], [ 29.734701206896553, -1.500367692307692 ], [ 29.734431724137931, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5456, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.500367692307692 ], [ 29.749792241379311, -1.501176123076923 ], [ 29.750061724137932, -1.501176123076923 ], [ 29.750061724137932, -1.500367692307692 ], [ 29.749792241379311, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5457, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.500367692307692 ], [ 29.750061724137932, -1.501176123076923 ], [ 29.750331206896551, -1.501176123076923 ], [ 29.750331206896551, -1.500367692307692 ], [ 29.750061724137932, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5458, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.500367692307692 ], [ 29.750600689655172, -1.500906646153846 ], [ 29.750870172413794, -1.500906646153846 ], [ 29.750870172413794, -1.500367692307692 ], [ 29.750600689655172, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5459, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.500367692307692 ], [ 29.759224137931035, -1.500906646153846 ], [ 29.759493620689657, -1.500906646153846 ], [ 29.759493620689657, -1.500367692307692 ], [ 29.759224137931035, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5460, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.500367692307692 ], [ 29.759493620689657, -1.500906646153846 ], [ 29.759763103448275, -1.500906646153846 ], [ 29.759763103448275, -1.500367692307692 ], [ 29.759493620689657, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5461, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.500367692307692 ], [ 29.761649482758621, -1.501176123076923 ], [ 29.761918965517243, -1.501176123076923 ], [ 29.761918965517243, -1.500367692307692 ], [ 29.761649482758621, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5462, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.500367692307692 ], [ 29.761918965517243, -1.501176123076923 ], [ 29.762188448275861, -1.501176123076923 ], [ 29.762188448275861, -1.500367692307692 ], [ 29.761918965517243, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5463, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.500637169230769 ], [ 29.718532241379311, -1.501176123076923 ], [ 29.718801724137933, -1.501176123076923 ], [ 29.718801724137933, -1.500637169230769 ], [ 29.718532241379311, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5464, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.500637169230769 ], [ 29.719340689655173, -1.500906646153846 ], [ 29.719610172413795, -1.500906646153846 ], [ 29.719610172413795, -1.500637169230769 ], [ 29.719340689655173, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5465, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.500637169230769 ], [ 29.719610172413795, -1.500906646153846 ], [ 29.719879655172413, -1.500906646153846 ], [ 29.719879655172413, -1.500637169230769 ], [ 29.719610172413795, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5466, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.500637169230769 ], [ 29.719879655172413, -1.500906646153846 ], [ 29.720149137931035, -1.500906646153846 ], [ 29.720149137931035, -1.500637169230769 ], [ 29.719879655172413, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5467, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.500637169230769 ], [ 29.720149137931035, -1.500906646153846 ], [ 29.720688103448275, -1.500906646153846 ], [ 29.720688103448275, -1.500637169230769 ], [ 29.720149137931035, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5468, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.500637169230769 ], [ 29.720688103448275, -1.500906646153846 ], [ 29.721227068965518, -1.500906646153846 ], [ 29.721227068965518, -1.500637169230769 ], [ 29.720688103448275, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5469, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.500637169230769 ], [ 29.721227068965518, -1.500906646153846 ], [ 29.722305, -1.500906646153846 ], [ 29.722305, -1.500637169230769 ], [ 29.721227068965518, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5470, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.500637169230769 ], [ 29.722305, -1.500906646153846 ], [ 29.72257448275862, -1.500906646153846 ], [ 29.72257448275862, -1.500637169230769 ], [ 29.722305, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5471, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.500637169230769 ], [ 29.72257448275862, -1.500906646153846 ], [ 29.722843965517242, -1.500906646153846 ], [ 29.722843965517242, -1.500637169230769 ], [ 29.72257448275862, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5472, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.500637169230769 ], [ 29.722843965517242, -1.500906646153846 ], [ 29.723113448275861, -1.500906646153846 ], [ 29.723113448275861, -1.500637169230769 ], [ 29.722843965517242, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5473, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.500637169230769 ], [ 29.723113448275861, -1.500906646153846 ], [ 29.723382931034482, -1.500906646153846 ], [ 29.723382931034482, -1.500637169230769 ], [ 29.723113448275861, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5474, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.500637169230769 ], [ 29.723382931034482, -1.500906646153846 ], [ 29.723652413793104, -1.500906646153846 ], [ 29.723652413793104, -1.500637169230769 ], [ 29.723382931034482, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5475, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.500637169230769 ], [ 29.723652413793104, -1.500906646153846 ], [ 29.723921896551722, -1.500906646153846 ], [ 29.723921896551722, -1.500637169230769 ], [ 29.723652413793104, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5476, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.500637169230769 ], [ 29.723921896551722, -1.500906646153846 ], [ 29.724191379310344, -1.500906646153846 ], [ 29.724191379310344, -1.500637169230769 ], [ 29.723921896551722, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5477, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.500637169230769 ], [ 29.724191379310344, -1.500906646153846 ], [ 29.724460862068966, -1.500906646153846 ], [ 29.724460862068966, -1.500637169230769 ], [ 29.724191379310344, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5478, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.500637169230769 ], [ 29.724460862068966, -1.500906646153846 ], [ 29.724730344827588, -1.500906646153846 ], [ 29.724730344827588, -1.500637169230769 ], [ 29.724460862068966, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5479, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.500637169230769 ], [ 29.724730344827588, -1.500906646153846 ], [ 29.724999827586206, -1.500906646153846 ], [ 29.724999827586206, -1.500637169230769 ], [ 29.724730344827588, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5480, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.500637169230769 ], [ 29.724999827586206, -1.500906646153846 ], [ 29.725269310344828, -1.500906646153846 ], [ 29.725269310344828, -1.500637169230769 ], [ 29.724999827586206, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5481, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.500637169230769 ], [ 29.725269310344828, -1.500906646153846 ], [ 29.72553879310345, -1.500906646153846 ], [ 29.72553879310345, -1.500637169230769 ], [ 29.725269310344828, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5482, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.500637169230769 ], [ 29.72553879310345, -1.501176123076923 ], [ 29.725808275862068, -1.501176123076923 ], [ 29.725808275862068, -1.500637169230769 ], [ 29.72553879310345, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5483, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.500637169230769 ], [ 29.72607775862069, -1.500906646153846 ], [ 29.726347241379312, -1.500906646153846 ], [ 29.726347241379312, -1.500637169230769 ], [ 29.72607775862069, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5484, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.500637169230769 ], [ 29.72661672413793, -1.500906646153846 ], [ 29.726886206896552, -1.500906646153846 ], [ 29.726886206896552, -1.500637169230769 ], [ 29.72661672413793, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5485, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.500637169230769 ], [ 29.726886206896552, -1.500906646153846 ], [ 29.727155689655174, -1.500906646153846 ], [ 29.727155689655174, -1.500637169230769 ], [ 29.726886206896552, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5486, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.500637169230769 ], [ 29.727155689655174, -1.501176123076923 ], [ 29.727425172413792, -1.501176123076923 ], [ 29.727425172413792, -1.500637169230769 ], [ 29.727155689655174, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5487, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.500637169230769 ], [ 29.727694655172414, -1.500906646153846 ], [ 29.727964137931036, -1.500906646153846 ], [ 29.727964137931036, -1.500637169230769 ], [ 29.727694655172414, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5488, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.500637169230769 ], [ 29.727964137931036, -1.500906646153846 ], [ 29.728233620689654, -1.500906646153846 ], [ 29.728233620689654, -1.500637169230769 ], [ 29.727964137931036, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5489, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.500637169230769 ], [ 29.728233620689654, -1.500906646153846 ], [ 29.728503103448276, -1.500906646153846 ], [ 29.728503103448276, -1.500637169230769 ], [ 29.728233620689654, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5490, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.500637169230769 ], [ 29.728503103448276, -1.500906646153846 ], [ 29.728772586206897, -1.500906646153846 ], [ 29.728772586206897, -1.500637169230769 ], [ 29.728503103448276, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5491, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.500637169230769 ], [ 29.728772586206897, -1.500906646153846 ], [ 29.729042068965516, -1.500906646153846 ], [ 29.729042068965516, -1.500637169230769 ], [ 29.728772586206897, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5492, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.500637169230769 ], [ 29.729042068965516, -1.500906646153846 ], [ 29.729311551724138, -1.500906646153846 ], [ 29.729311551724138, -1.500637169230769 ], [ 29.729042068965516, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5493, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.500637169230769 ], [ 29.729311551724138, -1.500906646153846 ], [ 29.729850517241381, -1.500906646153846 ], [ 29.729850517241381, -1.500637169230769 ], [ 29.729311551724138, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5494, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.500637169230769 ], [ 29.729850517241381, -1.501715076923077 ], [ 29.73012, -1.501715076923077 ], [ 29.73012, -1.500637169230769 ], [ 29.729850517241381, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5495, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.500637169230769 ], [ 29.73012, -1.501715076923077 ], [ 29.730389482758621, -1.501715076923077 ], [ 29.730389482758621, -1.500637169230769 ], [ 29.73012, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5496, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.500637169230769 ], [ 29.734701206896553, -1.501984553846154 ], [ 29.734970689655171, -1.501984553846154 ], [ 29.734970689655171, -1.500637169230769 ], [ 29.734701206896553, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5497, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.500637169230769 ], [ 29.734970689655171, -1.501984553846154 ], [ 29.735240172413793, -1.501984553846154 ], [ 29.735240172413793, -1.500637169230769 ], [ 29.734970689655171, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5498, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.500637169230769 ], [ 29.735240172413793, -1.500906646153846 ], [ 29.735509655172415, -1.500906646153846 ], [ 29.735509655172415, -1.500637169230769 ], [ 29.735240172413793, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5499, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.500637169230769 ], [ 29.735509655172415, -1.500906646153846 ], [ 29.735779137931036, -1.500906646153846 ], [ 29.735779137931036, -1.500637169230769 ], [ 29.735509655172415, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5500, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.500637169230769 ], [ 29.735779137931036, -1.500906646153846 ], [ 29.736048620689655, -1.500906646153846 ], [ 29.736048620689655, -1.500637169230769 ], [ 29.735779137931036, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5501, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.500637169230769 ], [ 29.736048620689655, -1.500906646153846 ], [ 29.736318103448276, -1.500906646153846 ], [ 29.736318103448276, -1.500637169230769 ], [ 29.736048620689655, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5502, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.500637169230769 ], [ 29.736318103448276, -1.500906646153846 ], [ 29.736587586206898, -1.500906646153846 ], [ 29.736587586206898, -1.500637169230769 ], [ 29.736318103448276, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5503, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.500367692307692 ], [ 29.737126551724138, -1.500367692307692 ], [ 29.737126551724138, -1.500906646153846 ], [ 29.736587586206898, -1.500906646153846 ], [ 29.736587586206898, -1.500637169230769 ], [ 29.736857068965517, -1.500637169230769 ], [ 29.736857068965517, -1.500367692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5504, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.500637169230769 ], [ 29.739551896551724, -1.501176123076923 ], [ 29.739821379310346, -1.501176123076923 ], [ 29.739821379310346, -1.500637169230769 ], [ 29.739551896551724, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5505, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.500637169230769 ], [ 29.740360344827586, -1.500906646153846 ], [ 29.740629827586208, -1.500906646153846 ], [ 29.740629827586208, -1.500637169230769 ], [ 29.740360344827586, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5506, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.500637169230769 ], [ 29.740629827586208, -1.500906646153846 ], [ 29.74089931034483, -1.500906646153846 ], [ 29.74089931034483, -1.500637169230769 ], [ 29.740629827586208, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5507, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749522758620689, -1.500637169230769 ], [ 29.749522758620689, -1.5014456 ], [ 29.749792241379311, -1.5014456 ], [ 29.749792241379311, -1.500637169230769 ], [ 29.749522758620689, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5508, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.500637169230769 ], [ 29.750331206896551, -1.500906646153846 ], [ 29.750600689655172, -1.500906646153846 ], [ 29.750600689655172, -1.500637169230769 ], [ 29.750331206896551, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5509, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.500637169230769 ], [ 29.750870172413794, -1.500906646153846 ], [ 29.751139655172413, -1.500906646153846 ], [ 29.751139655172413, -1.500637169230769 ], [ 29.750870172413794, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5510, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.500637169230769 ], [ 29.751139655172413, -1.500906646153846 ], [ 29.751409137931034, -1.500906646153846 ], [ 29.751409137931034, -1.500637169230769 ], [ 29.751139655172413, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5511, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.500637169230769 ], [ 29.751409137931034, -1.500906646153846 ], [ 29.751678620689656, -1.500906646153846 ], [ 29.751678620689656, -1.500637169230769 ], [ 29.751409137931034, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5512, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.500637169230769 ], [ 29.751678620689656, -1.500906646153846 ], [ 29.751948103448278, -1.500906646153846 ], [ 29.751948103448278, -1.500637169230769 ], [ 29.751678620689656, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5513, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.500637169230769 ], [ 29.751948103448278, -1.500906646153846 ], [ 29.752217586206896, -1.500906646153846 ], [ 29.752217586206896, -1.500637169230769 ], [ 29.751948103448278, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5514, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.500637169230769 ], [ 29.752217586206896, -1.500906646153846 ], [ 29.752487068965518, -1.500906646153846 ], [ 29.752487068965518, -1.500637169230769 ], [ 29.752217586206896, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5515, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.500637169230769 ], [ 29.752487068965518, -1.500906646153846 ], [ 29.75275655172414, -1.500906646153846 ], [ 29.75275655172414, -1.500637169230769 ], [ 29.752487068965518, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5516, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.500637169230769 ], [ 29.75275655172414, -1.500906646153846 ], [ 29.753026034482758, -1.500906646153846 ], [ 29.753026034482758, -1.500637169230769 ], [ 29.75275655172414, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5517, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.500637169230769 ], [ 29.753026034482758, -1.500906646153846 ], [ 29.75329551724138, -1.500906646153846 ], [ 29.75329551724138, -1.500637169230769 ], [ 29.753026034482758, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5518, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.500637169230769 ], [ 29.75329551724138, -1.500906646153846 ], [ 29.753565, -1.500906646153846 ], [ 29.753565, -1.500637169230769 ], [ 29.75329551724138, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5519, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.500637169230769 ], [ 29.753565, -1.500906646153846 ], [ 29.75383448275862, -1.500906646153846 ], [ 29.75383448275862, -1.500637169230769 ], [ 29.753565, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5520, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.500637169230769 ], [ 29.75383448275862, -1.500906646153846 ], [ 29.754103965517242, -1.500906646153846 ], [ 29.754103965517242, -1.500637169230769 ], [ 29.75383448275862, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5521, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.500637169230769 ], [ 29.754103965517242, -1.500906646153846 ], [ 29.754373448275864, -1.500906646153846 ], [ 29.754373448275864, -1.500637169230769 ], [ 29.754103965517242, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5522, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.500637169230769 ], [ 29.754373448275864, -1.500906646153846 ], [ 29.754642931034482, -1.500906646153846 ], [ 29.754642931034482, -1.500637169230769 ], [ 29.754373448275864, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5523, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.500637169230769 ], [ 29.754642931034482, -1.500906646153846 ], [ 29.754912413793104, -1.500906646153846 ], [ 29.754912413793104, -1.500637169230769 ], [ 29.754642931034482, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5524, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.500637169230769 ], [ 29.754912413793104, -1.501176123076923 ], [ 29.755181896551726, -1.501176123076923 ], [ 29.755181896551726, -1.500637169230769 ], [ 29.754912413793104, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5525, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.500637169230769 ], [ 29.755181896551726, -1.500906646153846 ], [ 29.755451379310344, -1.500906646153846 ], [ 29.755451379310344, -1.500637169230769 ], [ 29.755181896551726, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5526, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.500637169230769 ], [ 29.755451379310344, -1.500906646153846 ], [ 29.755720862068966, -1.500906646153846 ], [ 29.755720862068966, -1.500637169230769 ], [ 29.755451379310344, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5527, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.500637169230769 ], [ 29.755720862068966, -1.500906646153846 ], [ 29.755990344827588, -1.500906646153846 ], [ 29.755990344827588, -1.500637169230769 ], [ 29.755720862068966, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5528, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.500637169230769 ], [ 29.755990344827588, -1.500906646153846 ], [ 29.756259827586206, -1.500906646153846 ], [ 29.756259827586206, -1.500637169230769 ], [ 29.755990344827588, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5529, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.500637169230769 ], [ 29.756259827586206, -1.5014456 ], [ 29.756798793103449, -1.5014456 ], [ 29.756798793103449, -1.501715076923077 ], [ 29.757068275862068, -1.501715076923077 ], [ 29.757068275862068, -1.501176123076923 ], [ 29.756798793103449, -1.501176123076923 ], [ 29.756798793103449, -1.500637169230769 ], [ 29.756259827586206, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5530, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.500637169230769 ], [ 29.756798793103449, -1.500906646153846 ], [ 29.757068275862068, -1.500906646153846 ], [ 29.757068275862068, -1.500637169230769 ], [ 29.756798793103449, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5531, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.500637169230769 ], [ 29.757068275862068, -1.500906646153846 ], [ 29.75733775862069, -1.500906646153846 ], [ 29.75733775862069, -1.500637169230769 ], [ 29.757068275862068, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5532, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.500637169230769 ], [ 29.75733775862069, -1.500906646153846 ], [ 29.757607241379311, -1.500906646153846 ], [ 29.757607241379311, -1.500637169230769 ], [ 29.75733775862069, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5533, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.500637169230769 ], [ 29.757607241379311, -1.500906646153846 ], [ 29.757876724137933, -1.500906646153846 ], [ 29.757876724137933, -1.500637169230769 ], [ 29.757607241379311, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5534, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.500637169230769 ], [ 29.757876724137933, -1.500906646153846 ], [ 29.758146206896551, -1.500906646153846 ], [ 29.758146206896551, -1.500637169230769 ], [ 29.757876724137933, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5535, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.500637169230769 ], [ 29.758146206896551, -1.500906646153846 ], [ 29.758415689655173, -1.500906646153846 ], [ 29.758415689655173, -1.500637169230769 ], [ 29.758146206896551, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5536, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.500637169230769 ], [ 29.758415689655173, -1.500906646153846 ], [ 29.758685172413795, -1.500906646153846 ], [ 29.758685172413795, -1.500637169230769 ], [ 29.758415689655173, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5537, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.500637169230769 ], [ 29.758685172413795, -1.500906646153846 ], [ 29.758954655172413, -1.500906646153846 ], [ 29.758954655172413, -1.500637169230769 ], [ 29.758685172413795, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5538, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.500637169230769 ], [ 29.758954655172413, -1.501176123076923 ], [ 29.759224137931035, -1.501176123076923 ], [ 29.759224137931035, -1.500637169230769 ], [ 29.758954655172413, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5539, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.500637169230769 ], [ 29.759763103448275, -1.500906646153846 ], [ 29.760032586206897, -1.500906646153846 ], [ 29.760032586206897, -1.500637169230769 ], [ 29.759763103448275, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5540, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.500637169230769 ], [ 29.760032586206897, -1.500906646153846 ], [ 29.760302068965519, -1.500906646153846 ], [ 29.760302068965519, -1.500637169230769 ], [ 29.760032586206897, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5541, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.500637169230769 ], [ 29.760302068965519, -1.500906646153846 ], [ 29.760571551724137, -1.500906646153846 ], [ 29.760571551724137, -1.500637169230769 ], [ 29.760302068965519, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5542, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.500637169230769 ], [ 29.760571551724137, -1.501176123076923 ], [ 29.760841034482759, -1.501176123076923 ], [ 29.760841034482759, -1.500637169230769 ], [ 29.760571551724137, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5543, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.500637169230769 ], [ 29.760841034482759, -1.501176123076923 ], [ 29.761110517241381, -1.501176123076923 ], [ 29.761110517241381, -1.500637169230769 ], [ 29.760841034482759, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5544, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.500637169230769 ], [ 29.761110517241381, -1.501176123076923 ], [ 29.76138, -1.501176123076923 ], [ 29.76138, -1.500637169230769 ], [ 29.761110517241381, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5545, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.500637169230769 ], [ 29.76138, -1.500906646153846 ], [ 29.761649482758621, -1.500906646153846 ], [ 29.761649482758621, -1.500637169230769 ], [ 29.76138, -1.500637169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5546, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.500906646153846 ], [ 29.718801724137933, -1.501176123076923 ], [ 29.719071206896551, -1.501176123076923 ], [ 29.719071206896551, -1.500906646153846 ], [ 29.718801724137933, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5547, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.500906646153846 ], [ 29.719071206896551, -1.501176123076923 ], [ 29.719340689655173, -1.501176123076923 ], [ 29.719340689655173, -1.500906646153846 ], [ 29.719071206896551, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5548, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.500906646153846 ], [ 29.719340689655173, -1.501176123076923 ], [ 29.719610172413795, -1.501176123076923 ], [ 29.719610172413795, -1.500906646153846 ], [ 29.719340689655173, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5549, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.500906646153846 ], [ 29.719610172413795, -1.501176123076923 ], [ 29.719879655172413, -1.501176123076923 ], [ 29.719879655172413, -1.500906646153846 ], [ 29.719610172413795, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5550, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.500906646153846 ], [ 29.719879655172413, -1.501176123076923 ], [ 29.720149137931035, -1.501176123076923 ], [ 29.720149137931035, -1.500906646153846 ], [ 29.719879655172413, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5551, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.500906646153846 ], [ 29.720149137931035, -1.501176123076923 ], [ 29.720688103448275, -1.501176123076923 ], [ 29.720688103448275, -1.500906646153846 ], [ 29.720149137931035, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5552, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.500906646153846 ], [ 29.720688103448275, -1.501176123076923 ], [ 29.721227068965518, -1.501176123076923 ], [ 29.721227068965518, -1.500906646153846 ], [ 29.720688103448275, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5553, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.500906646153846 ], [ 29.721227068965518, -1.501176123076923 ], [ 29.722305, -1.501176123076923 ], [ 29.722305, -1.500906646153846 ], [ 29.721227068965518, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5554, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.500906646153846 ], [ 29.722305, -1.501176123076923 ], [ 29.72257448275862, -1.501176123076923 ], [ 29.72257448275862, -1.500906646153846 ], [ 29.722305, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5555, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.500906646153846 ], [ 29.72257448275862, -1.501176123076923 ], [ 29.722843965517242, -1.501176123076923 ], [ 29.722843965517242, -1.500906646153846 ], [ 29.72257448275862, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5556, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.500906646153846 ], [ 29.722843965517242, -1.501176123076923 ], [ 29.723113448275861, -1.501176123076923 ], [ 29.723113448275861, -1.500906646153846 ], [ 29.722843965517242, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5557, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.500906646153846 ], [ 29.723113448275861, -1.501176123076923 ], [ 29.723382931034482, -1.501176123076923 ], [ 29.723382931034482, -1.500906646153846 ], [ 29.723113448275861, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5558, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.500906646153846 ], [ 29.723382931034482, -1.501176123076923 ], [ 29.723652413793104, -1.501176123076923 ], [ 29.723652413793104, -1.500906646153846 ], [ 29.723382931034482, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5559, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.500906646153846 ], [ 29.723652413793104, -1.501176123076923 ], [ 29.723921896551722, -1.501176123076923 ], [ 29.723921896551722, -1.500906646153846 ], [ 29.723652413793104, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5560, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.500906646153846 ], [ 29.723921896551722, -1.501176123076923 ], [ 29.724191379310344, -1.501176123076923 ], [ 29.724191379310344, -1.500906646153846 ], [ 29.723921896551722, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5561, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.500906646153846 ], [ 29.724191379310344, -1.501176123076923 ], [ 29.724460862068966, -1.501176123076923 ], [ 29.724460862068966, -1.500906646153846 ], [ 29.724191379310344, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5562, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.500906646153846 ], [ 29.724460862068966, -1.501176123076923 ], [ 29.724730344827588, -1.501176123076923 ], [ 29.724730344827588, -1.500906646153846 ], [ 29.724460862068966, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5563, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.500906646153846 ], [ 29.724730344827588, -1.501176123076923 ], [ 29.724999827586206, -1.501176123076923 ], [ 29.724999827586206, -1.500906646153846 ], [ 29.724730344827588, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5564, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.500906646153846 ], [ 29.724999827586206, -1.501176123076923 ], [ 29.725269310344828, -1.501176123076923 ], [ 29.725269310344828, -1.500906646153846 ], [ 29.724999827586206, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5565, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.500906646153846 ], [ 29.725269310344828, -1.5014456 ], [ 29.72553879310345, -1.5014456 ], [ 29.72553879310345, -1.500906646153846 ], [ 29.725269310344828, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5566, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.500906646153846 ], [ 29.725808275862068, -1.501176123076923 ], [ 29.72607775862069, -1.501176123076923 ], [ 29.72607775862069, -1.500906646153846 ], [ 29.725808275862068, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5567, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.500906646153846 ], [ 29.72607775862069, -1.501176123076923 ], [ 29.726347241379312, -1.501176123076923 ], [ 29.726347241379312, -1.500906646153846 ], [ 29.72607775862069, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5568, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.500906646153846 ], [ 29.72661672413793, -1.501176123076923 ], [ 29.726886206896552, -1.501176123076923 ], [ 29.726886206896552, -1.500906646153846 ], [ 29.72661672413793, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5569, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.500906646153846 ], [ 29.726886206896552, -1.5014456 ], [ 29.727155689655174, -1.5014456 ], [ 29.727155689655174, -1.500906646153846 ], [ 29.726886206896552, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5570, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.500906646153846 ], [ 29.727425172413792, -1.501176123076923 ], [ 29.727694655172414, -1.501176123076923 ], [ 29.727694655172414, -1.500906646153846 ], [ 29.727425172413792, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5571, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.500906646153846 ], [ 29.727694655172414, -1.501176123076923 ], [ 29.727964137931036, -1.501176123076923 ], [ 29.727964137931036, -1.500906646153846 ], [ 29.727694655172414, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5572, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.500906646153846 ], [ 29.727964137931036, -1.501176123076923 ], [ 29.728233620689654, -1.501176123076923 ], [ 29.728233620689654, -1.500906646153846 ], [ 29.727964137931036, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5573, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.500906646153846 ], [ 29.728233620689654, -1.501176123076923 ], [ 29.728503103448276, -1.501176123076923 ], [ 29.728503103448276, -1.500906646153846 ], [ 29.728233620689654, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5574, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.500906646153846 ], [ 29.728503103448276, -1.501176123076923 ], [ 29.728772586206897, -1.501176123076923 ], [ 29.728772586206897, -1.500906646153846 ], [ 29.728503103448276, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5575, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.500906646153846 ], [ 29.728772586206897, -1.501715076923077 ], [ 29.729042068965516, -1.501715076923077 ], [ 29.729042068965516, -1.500906646153846 ], [ 29.728772586206897, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5576, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.500906646153846 ], [ 29.729042068965516, -1.501984553846154 ], [ 29.729311551724138, -1.501984553846154 ], [ 29.729311551724138, -1.500906646153846 ], [ 29.729042068965516, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5577, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.500906646153846 ], [ 29.729311551724138, -1.501984553846154 ], [ 29.729850517241381, -1.501984553846154 ], [ 29.729850517241381, -1.500906646153846 ], [ 29.729311551724138, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5578, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.500906646153846 ], [ 29.735240172413793, -1.501715076923077 ], [ 29.735509655172415, -1.501715076923077 ], [ 29.735509655172415, -1.500906646153846 ], [ 29.735240172413793, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5579, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.500906646153846 ], [ 29.735509655172415, -1.501176123076923 ], [ 29.735779137931036, -1.501176123076923 ], [ 29.735779137931036, -1.500906646153846 ], [ 29.735509655172415, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5580, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.500906646153846 ], [ 29.735779137931036, -1.501176123076923 ], [ 29.736048620689655, -1.501176123076923 ], [ 29.736048620689655, -1.500906646153846 ], [ 29.735779137931036, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5581, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.500906646153846 ], [ 29.736048620689655, -1.501176123076923 ], [ 29.736318103448276, -1.501176123076923 ], [ 29.736318103448276, -1.500906646153846 ], [ 29.736048620689655, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5582, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.500906646153846 ], [ 29.736318103448276, -1.501176123076923 ], [ 29.736587586206898, -1.501176123076923 ], [ 29.736587586206898, -1.500906646153846 ], [ 29.736318103448276, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5583, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.500906646153846 ], [ 29.736587586206898, -1.501176123076923 ], [ 29.736857068965517, -1.501176123076923 ], [ 29.736857068965517, -1.5014456 ], [ 29.737126551724138, -1.5014456 ], [ 29.737126551724138, -1.500906646153846 ], [ 29.736587586206898, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5584, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.500906646153846 ], [ 29.737665517241378, -1.5014456 ], [ 29.737935, -1.5014456 ], [ 29.737935, -1.500906646153846 ], [ 29.737665517241378, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5585, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.500906646153846 ], [ 29.738743448275862, -1.501176123076923 ], [ 29.739012931034484, -1.501176123076923 ], [ 29.739012931034484, -1.500906646153846 ], [ 29.738743448275862, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5586, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.500906646153846 ], [ 29.739012931034484, -1.501176123076923 ], [ 29.739282413793102, -1.501176123076923 ], [ 29.739282413793102, -1.500906646153846 ], [ 29.739012931034484, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5587, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.500906646153846 ], [ 29.739282413793102, -1.501176123076923 ], [ 29.739551896551724, -1.501176123076923 ], [ 29.739551896551724, -1.500906646153846 ], [ 29.739282413793102, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5588, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.500906646153846 ], [ 29.739821379310346, -1.501176123076923 ], [ 29.740090862068964, -1.501176123076923 ], [ 29.740090862068964, -1.500906646153846 ], [ 29.739821379310346, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5589, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.500906646153846 ], [ 29.740090862068964, -1.501176123076923 ], [ 29.740360344827586, -1.501176123076923 ], [ 29.740360344827586, -1.500906646153846 ], [ 29.740090862068964, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5590, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.500906646153846 ], [ 29.740360344827586, -1.501176123076923 ], [ 29.740629827586208, -1.501176123076923 ], [ 29.740629827586208, -1.500906646153846 ], [ 29.740360344827586, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5591, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.500906646153846 ], [ 29.740629827586208, -1.501176123076923 ], [ 29.74089931034483, -1.501176123076923 ], [ 29.74089931034483, -1.500906646153846 ], [ 29.740629827586208, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5592, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.500906646153846 ], [ 29.74089931034483, -1.501176123076923 ], [ 29.74143827586207, -1.501176123076923 ], [ 29.74143827586207, -1.500906646153846 ], [ 29.74089931034483, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5593, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.500906646153846 ], [ 29.750331206896551, -1.501176123076923 ], [ 29.750600689655172, -1.501176123076923 ], [ 29.750600689655172, -1.500906646153846 ], [ 29.750331206896551, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5594, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.500906646153846 ], [ 29.750600689655172, -1.501176123076923 ], [ 29.751139655172413, -1.501176123076923 ], [ 29.751139655172413, -1.500906646153846 ], [ 29.750600689655172, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5595, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.500906646153846 ], [ 29.751139655172413, -1.501176123076923 ], [ 29.751409137931034, -1.501176123076923 ], [ 29.751409137931034, -1.500906646153846 ], [ 29.751139655172413, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5596, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.500906646153846 ], [ 29.751409137931034, -1.501176123076923 ], [ 29.751678620689656, -1.501176123076923 ], [ 29.751678620689656, -1.500906646153846 ], [ 29.751409137931034, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5597, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.500906646153846 ], [ 29.751678620689656, -1.501176123076923 ], [ 29.751948103448278, -1.501176123076923 ], [ 29.751948103448278, -1.500906646153846 ], [ 29.751678620689656, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5598, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.500906646153846 ], [ 29.751948103448278, -1.501176123076923 ], [ 29.752217586206896, -1.501176123076923 ], [ 29.752217586206896, -1.500906646153846 ], [ 29.751948103448278, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5599, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.500906646153846 ], [ 29.752217586206896, -1.501176123076923 ], [ 29.752487068965518, -1.501176123076923 ], [ 29.752487068965518, -1.500906646153846 ], [ 29.752217586206896, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5600, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.500906646153846 ], [ 29.752487068965518, -1.501176123076923 ], [ 29.75275655172414, -1.501176123076923 ], [ 29.75275655172414, -1.500906646153846 ], [ 29.752487068965518, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5601, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.500906646153846 ], [ 29.75275655172414, -1.501176123076923 ], [ 29.753026034482758, -1.501176123076923 ], [ 29.753026034482758, -1.500906646153846 ], [ 29.75275655172414, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5602, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.500906646153846 ], [ 29.753026034482758, -1.501176123076923 ], [ 29.75329551724138, -1.501176123076923 ], [ 29.75329551724138, -1.500906646153846 ], [ 29.753026034482758, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5603, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.500906646153846 ], [ 29.75329551724138, -1.501176123076923 ], [ 29.753565, -1.501176123076923 ], [ 29.753565, -1.500906646153846 ], [ 29.75329551724138, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5604, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.500906646153846 ], [ 29.753565, -1.501176123076923 ], [ 29.75383448275862, -1.501176123076923 ], [ 29.75383448275862, -1.500906646153846 ], [ 29.753565, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5605, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.500906646153846 ], [ 29.75383448275862, -1.501176123076923 ], [ 29.754103965517242, -1.501176123076923 ], [ 29.754103965517242, -1.500906646153846 ], [ 29.75383448275862, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5606, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.500906646153846 ], [ 29.754103965517242, -1.501176123076923 ], [ 29.754373448275864, -1.501176123076923 ], [ 29.754373448275864, -1.500906646153846 ], [ 29.754103965517242, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5607, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.500906646153846 ], [ 29.754373448275864, -1.501176123076923 ], [ 29.754642931034482, -1.501176123076923 ], [ 29.754642931034482, -1.500906646153846 ], [ 29.754373448275864, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5608, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.500906646153846 ], [ 29.754642931034482, -1.5014456 ], [ 29.754912413793104, -1.5014456 ], [ 29.754912413793104, -1.500906646153846 ], [ 29.754642931034482, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5609, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.500906646153846 ], [ 29.755181896551726, -1.501176123076923 ], [ 29.755451379310344, -1.501176123076923 ], [ 29.755451379310344, -1.500906646153846 ], [ 29.755181896551726, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5610, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.500906646153846 ], [ 29.755451379310344, -1.501176123076923 ], [ 29.755720862068966, -1.501176123076923 ], [ 29.755720862068966, -1.500906646153846 ], [ 29.755451379310344, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5611, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.500906646153846 ], [ 29.755720862068966, -1.501176123076923 ], [ 29.755990344827588, -1.501176123076923 ], [ 29.755990344827588, -1.500906646153846 ], [ 29.755720862068966, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5612, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.500906646153846 ], [ 29.755990344827588, -1.5014456 ], [ 29.756259827586206, -1.5014456 ], [ 29.756259827586206, -1.500906646153846 ], [ 29.755990344827588, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5613, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.500906646153846 ], [ 29.756798793103449, -1.501176123076923 ], [ 29.757068275862068, -1.501176123076923 ], [ 29.757068275862068, -1.500906646153846 ], [ 29.756798793103449, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5614, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.500906646153846 ], [ 29.757068275862068, -1.501176123076923 ], [ 29.75733775862069, -1.501176123076923 ], [ 29.75733775862069, -1.500906646153846 ], [ 29.757068275862068, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5615, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.500906646153846 ], [ 29.75733775862069, -1.5014456 ], [ 29.757607241379311, -1.5014456 ], [ 29.757607241379311, -1.500906646153846 ], [ 29.75733775862069, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5616, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.500906646153846 ], [ 29.757607241379311, -1.5014456 ], [ 29.757876724137933, -1.5014456 ], [ 29.757876724137933, -1.500906646153846 ], [ 29.757607241379311, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5617, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.500906646153846 ], [ 29.757876724137933, -1.501176123076923 ], [ 29.758146206896551, -1.501176123076923 ], [ 29.758146206896551, -1.500906646153846 ], [ 29.757876724137933, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5618, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.500906646153846 ], [ 29.758146206896551, -1.501176123076923 ], [ 29.758415689655173, -1.501176123076923 ], [ 29.758415689655173, -1.500906646153846 ], [ 29.758146206896551, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5619, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.500906646153846 ], [ 29.758415689655173, -1.501176123076923 ], [ 29.758685172413795, -1.501176123076923 ], [ 29.758685172413795, -1.500906646153846 ], [ 29.758415689655173, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5620, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.500906646153846 ], [ 29.758685172413795, -1.5014456 ], [ 29.758954655172413, -1.5014456 ], [ 29.758954655172413, -1.500906646153846 ], [ 29.758685172413795, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5621, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.500906646153846 ], [ 29.759224137931035, -1.501176123076923 ], [ 29.759493620689657, -1.501176123076923 ], [ 29.759493620689657, -1.500906646153846 ], [ 29.759224137931035, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5622, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.500906646153846 ], [ 29.759493620689657, -1.501176123076923 ], [ 29.759763103448275, -1.501176123076923 ], [ 29.759763103448275, -1.500906646153846 ], [ 29.759493620689657, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5623, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.500906646153846 ], [ 29.759763103448275, -1.501176123076923 ], [ 29.760032586206897, -1.501176123076923 ], [ 29.760032586206897, -1.500906646153846 ], [ 29.759763103448275, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5624, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.500906646153846 ], [ 29.760032586206897, -1.5014456 ], [ 29.760302068965519, -1.5014456 ], [ 29.760302068965519, -1.500906646153846 ], [ 29.760032586206897, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5625, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.500906646153846 ], [ 29.760302068965519, -1.5014456 ], [ 29.760571551724137, -1.5014456 ], [ 29.760571551724137, -1.500906646153846 ], [ 29.760302068965519, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5626, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.500906646153846 ], [ 29.76138, -1.5014456 ], [ 29.761649482758621, -1.5014456 ], [ 29.761649482758621, -1.500906646153846 ], [ 29.76138, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5627, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.500906646153846 ], [ 29.762188448275861, -1.501176123076923 ], [ 29.762457931034483, -1.501176123076923 ], [ 29.762457931034483, -1.500906646153846 ], [ 29.762188448275861, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5628, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.500906646153846 ], [ 29.762457931034483, -1.501176123076923 ], [ 29.762727413793105, -1.501176123076923 ], [ 29.762727413793105, -1.500906646153846 ], [ 29.762457931034483, -1.500906646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5629, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.501176123076923 ], [ 29.718801724137933, -1.5014456 ], [ 29.719071206896551, -1.5014456 ], [ 29.719071206896551, -1.501176123076923 ], [ 29.718801724137933, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5630, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.501176123076923 ], [ 29.719071206896551, -1.5014456 ], [ 29.719340689655173, -1.5014456 ], [ 29.719340689655173, -1.501176123076923 ], [ 29.719071206896551, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5631, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.501176123076923 ], [ 29.719340689655173, -1.5014456 ], [ 29.719610172413795, -1.5014456 ], [ 29.719610172413795, -1.501176123076923 ], [ 29.719340689655173, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5632, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.501176123076923 ], [ 29.719610172413795, -1.5014456 ], [ 29.719879655172413, -1.5014456 ], [ 29.719879655172413, -1.501176123076923 ], [ 29.719610172413795, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5633, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.501176123076923 ], [ 29.719879655172413, -1.5014456 ], [ 29.720149137931035, -1.5014456 ], [ 29.720149137931035, -1.501176123076923 ], [ 29.719879655172413, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5634, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.501176123076923 ], [ 29.720149137931035, -1.5014456 ], [ 29.720688103448275, -1.5014456 ], [ 29.720688103448275, -1.501176123076923 ], [ 29.720149137931035, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5635, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.501176123076923 ], [ 29.720688103448275, -1.5014456 ], [ 29.720957586206897, -1.5014456 ], [ 29.720957586206897, -1.501176123076923 ], [ 29.720688103448275, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5636, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.501176123076923 ], [ 29.720957586206897, -1.5014456 ], [ 29.721496551724137, -1.5014456 ], [ 29.721496551724137, -1.501176123076923 ], [ 29.720957586206897, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5637, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.501176123076923 ], [ 29.721496551724137, -1.5014456 ], [ 29.72203551724138, -1.5014456 ], [ 29.72203551724138, -1.501176123076923 ], [ 29.721496551724137, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5638, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.501176123076923 ], [ 29.72203551724138, -1.5014456 ], [ 29.72257448275862, -1.5014456 ], [ 29.72257448275862, -1.501176123076923 ], [ 29.72203551724138, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5639, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.501176123076923 ], [ 29.72257448275862, -1.5014456 ], [ 29.722843965517242, -1.5014456 ], [ 29.722843965517242, -1.501176123076923 ], [ 29.72257448275862, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5640, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.501176123076923 ], [ 29.722843965517242, -1.5014456 ], [ 29.723113448275861, -1.5014456 ], [ 29.723113448275861, -1.501176123076923 ], [ 29.722843965517242, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5641, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.501176123076923 ], [ 29.723113448275861, -1.5014456 ], [ 29.723382931034482, -1.5014456 ], [ 29.723382931034482, -1.501176123076923 ], [ 29.723113448275861, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5642, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.501176123076923 ], [ 29.723382931034482, -1.5014456 ], [ 29.723652413793104, -1.5014456 ], [ 29.723652413793104, -1.501176123076923 ], [ 29.723382931034482, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5643, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.501176123076923 ], [ 29.723652413793104, -1.5014456 ], [ 29.723921896551722, -1.5014456 ], [ 29.723921896551722, -1.501176123076923 ], [ 29.723652413793104, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5644, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.501176123076923 ], [ 29.723921896551722, -1.5014456 ], [ 29.724191379310344, -1.5014456 ], [ 29.724191379310344, -1.501176123076923 ], [ 29.723921896551722, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5645, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.501176123076923 ], [ 29.724191379310344, -1.5014456 ], [ 29.724460862068966, -1.5014456 ], [ 29.724460862068966, -1.501176123076923 ], [ 29.724191379310344, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5646, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.501176123076923 ], [ 29.724460862068966, -1.5014456 ], [ 29.724730344827588, -1.5014456 ], [ 29.724730344827588, -1.501176123076923 ], [ 29.724460862068966, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5647, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.501176123076923 ], [ 29.724730344827588, -1.5014456 ], [ 29.724999827586206, -1.5014456 ], [ 29.724999827586206, -1.501176123076923 ], [ 29.724730344827588, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5648, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.501176123076923 ], [ 29.724999827586206, -1.501715076923077 ], [ 29.725269310344828, -1.501715076923077 ], [ 29.725269310344828, -1.501176123076923 ], [ 29.724999827586206, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5649, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.501176123076923 ], [ 29.72553879310345, -1.5014456 ], [ 29.725808275862068, -1.5014456 ], [ 29.725808275862068, -1.501176123076923 ], [ 29.72553879310345, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5650, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.501176123076923 ], [ 29.725808275862068, -1.501715076923077 ], [ 29.72607775862069, -1.501715076923077 ], [ 29.72607775862069, -1.501176123076923 ], [ 29.725808275862068, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5651, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.501176123076923 ], [ 29.72607775862069, -1.501715076923077 ], [ 29.726347241379312, -1.501715076923077 ], [ 29.726347241379312, -1.501176123076923 ], [ 29.72607775862069, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5652, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.501176123076923 ], [ 29.727155689655174, -1.5014456 ], [ 29.727425172413792, -1.5014456 ], [ 29.727425172413792, -1.501176123076923 ], [ 29.727155689655174, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5653, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.501176123076923 ], [ 29.727425172413792, -1.5014456 ], [ 29.727694655172414, -1.5014456 ], [ 29.727694655172414, -1.501176123076923 ], [ 29.727425172413792, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5654, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.501176123076923 ], [ 29.727694655172414, -1.5014456 ], [ 29.727964137931036, -1.5014456 ], [ 29.727964137931036, -1.501176123076923 ], [ 29.727694655172414, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5655, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.501176123076923 ], [ 29.727964137931036, -1.501715076923077 ], [ 29.728233620689654, -1.501715076923077 ], [ 29.728233620689654, -1.501176123076923 ], [ 29.727964137931036, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5656, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.501176123076923 ], [ 29.728233620689654, -1.501984553846154 ], [ 29.728503103448276, -1.501984553846154 ], [ 29.728503103448276, -1.501176123076923 ], [ 29.728233620689654, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5657, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.501176123076923 ], [ 29.728503103448276, -1.502254030769231 ], [ 29.728772586206897, -1.502254030769231 ], [ 29.728772586206897, -1.501176123076923 ], [ 29.728503103448276, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5658, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.501176123076923 ], [ 29.735509655172415, -1.502254030769231 ], [ 29.735779137931036, -1.502254030769231 ], [ 29.735779137931036, -1.501176123076923 ], [ 29.735509655172415, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5659, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.501176123076923 ], [ 29.735779137931036, -1.501984553846154 ], [ 29.736048620689655, -1.501984553846154 ], [ 29.736048620689655, -1.501176123076923 ], [ 29.735779137931036, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5660, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.501176123076923 ], [ 29.736048620689655, -1.501715076923077 ], [ 29.736318103448276, -1.501715076923077 ], [ 29.736318103448276, -1.501176123076923 ], [ 29.736048620689655, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5661, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.501176123076923 ], [ 29.736318103448276, -1.5014456 ], [ 29.736587586206898, -1.5014456 ], [ 29.736587586206898, -1.501176123076923 ], [ 29.736318103448276, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5662, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.501176123076923 ], [ 29.736587586206898, -1.5014456 ], [ 29.736857068965517, -1.5014456 ], [ 29.736857068965517, -1.501176123076923 ], [ 29.736587586206898, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5663, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.501176123076923 ], [ 29.737126551724138, -1.501715076923077 ], [ 29.73739603448276, -1.501715076923077 ], [ 29.73739603448276, -1.501176123076923 ], [ 29.737126551724138, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5664, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.501176123076923 ], [ 29.737935, -1.5014456 ], [ 29.738204482758622, -1.5014456 ], [ 29.738204482758622, -1.501176123076923 ], [ 29.737935, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5665, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.501176123076923 ], [ 29.738204482758622, -1.5014456 ], [ 29.738743448275862, -1.5014456 ], [ 29.738743448275862, -1.501176123076923 ], [ 29.738204482758622, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5666, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.501176123076923 ], [ 29.738743448275862, -1.5014456 ], [ 29.739012931034484, -1.5014456 ], [ 29.739012931034484, -1.501176123076923 ], [ 29.738743448275862, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5667, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.501176123076923 ], [ 29.739012931034484, -1.5014456 ], [ 29.739282413793102, -1.5014456 ], [ 29.739282413793102, -1.501176123076923 ], [ 29.739012931034484, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5668, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.501176123076923 ], [ 29.739282413793102, -1.5014456 ], [ 29.739551896551724, -1.5014456 ], [ 29.739551896551724, -1.501176123076923 ], [ 29.739282413793102, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5669, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.501176123076923 ], [ 29.739551896551724, -1.5014456 ], [ 29.739821379310346, -1.5014456 ], [ 29.739821379310346, -1.501176123076923 ], [ 29.739551896551724, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5670, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.501176123076923 ], [ 29.739821379310346, -1.5014456 ], [ 29.740090862068964, -1.5014456 ], [ 29.740090862068964, -1.501176123076923 ], [ 29.739821379310346, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5671, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.501176123076923 ], [ 29.740090862068964, -1.5014456 ], [ 29.740360344827586, -1.5014456 ], [ 29.740360344827586, -1.501176123076923 ], [ 29.740090862068964, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5672, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.501176123076923 ], [ 29.740360344827586, -1.5014456 ], [ 29.740629827586208, -1.5014456 ], [ 29.740629827586208, -1.501176123076923 ], [ 29.740360344827586, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5673, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.501176123076923 ], [ 29.740629827586208, -1.5014456 ], [ 29.741168793103448, -1.5014456 ], [ 29.741168793103448, -1.501176123076923 ], [ 29.740629827586208, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5674, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.501176123076923 ], [ 29.741168793103448, -1.5014456 ], [ 29.74143827586207, -1.5014456 ], [ 29.74143827586207, -1.501176123076923 ], [ 29.741168793103448, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5675, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.501176123076923 ], [ 29.74143827586207, -1.5014456 ], [ 29.742516206896553, -1.5014456 ], [ 29.742516206896553, -1.501176123076923 ], [ 29.74143827586207, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5676, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.501176123076923 ], [ 29.749792241379311, -1.5014456 ], [ 29.750061724137932, -1.5014456 ], [ 29.750061724137932, -1.501176123076923 ], [ 29.749792241379311, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5677, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.501176123076923 ], [ 29.750061724137932, -1.5014456 ], [ 29.750331206896551, -1.5014456 ], [ 29.750331206896551, -1.501176123076923 ], [ 29.750061724137932, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5678, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.501176123076923 ], [ 29.750331206896551, -1.5014456 ], [ 29.750870172413794, -1.5014456 ], [ 29.750870172413794, -1.501176123076923 ], [ 29.750331206896551, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5679, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.501176123076923 ], [ 29.750870172413794, -1.5014456 ], [ 29.751139655172413, -1.5014456 ], [ 29.751139655172413, -1.501176123076923 ], [ 29.750870172413794, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5680, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.501176123076923 ], [ 29.751139655172413, -1.5014456 ], [ 29.751409137931034, -1.5014456 ], [ 29.751409137931034, -1.501176123076923 ], [ 29.751139655172413, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5681, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.501176123076923 ], [ 29.751409137931034, -1.5014456 ], [ 29.751678620689656, -1.5014456 ], [ 29.751678620689656, -1.501176123076923 ], [ 29.751409137931034, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5682, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.501176123076923 ], [ 29.751678620689656, -1.5014456 ], [ 29.751948103448278, -1.5014456 ], [ 29.751948103448278, -1.501176123076923 ], [ 29.751678620689656, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5683, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.501176123076923 ], [ 29.751948103448278, -1.5014456 ], [ 29.752217586206896, -1.5014456 ], [ 29.752217586206896, -1.501176123076923 ], [ 29.751948103448278, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5684, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.501176123076923 ], [ 29.752217586206896, -1.5014456 ], [ 29.752487068965518, -1.5014456 ], [ 29.752487068965518, -1.501176123076923 ], [ 29.752217586206896, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5685, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.501176123076923 ], [ 29.752487068965518, -1.5014456 ], [ 29.75275655172414, -1.5014456 ], [ 29.75275655172414, -1.501176123076923 ], [ 29.752487068965518, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5686, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.501176123076923 ], [ 29.75275655172414, -1.5014456 ], [ 29.753026034482758, -1.5014456 ], [ 29.753026034482758, -1.501176123076923 ], [ 29.75275655172414, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5687, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.501176123076923 ], [ 29.753026034482758, -1.5014456 ], [ 29.75329551724138, -1.5014456 ], [ 29.75329551724138, -1.501176123076923 ], [ 29.753026034482758, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5688, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.501176123076923 ], [ 29.75329551724138, -1.5014456 ], [ 29.753565, -1.5014456 ], [ 29.753565, -1.501176123076923 ], [ 29.75329551724138, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5689, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.501176123076923 ], [ 29.753565, -1.5014456 ], [ 29.75383448275862, -1.5014456 ], [ 29.75383448275862, -1.501176123076923 ], [ 29.753565, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5690, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.501176123076923 ], [ 29.75383448275862, -1.5014456 ], [ 29.754103965517242, -1.5014456 ], [ 29.754103965517242, -1.501176123076923 ], [ 29.75383448275862, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5691, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.501176123076923 ], [ 29.754103965517242, -1.5014456 ], [ 29.754373448275864, -1.5014456 ], [ 29.754373448275864, -1.501176123076923 ], [ 29.754103965517242, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5692, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.501176123076923 ], [ 29.754373448275864, -1.501715076923077 ], [ 29.754642931034482, -1.501715076923077 ], [ 29.754642931034482, -1.501176123076923 ], [ 29.754373448275864, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5693, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.501176123076923 ], [ 29.754912413793104, -1.5014456 ], [ 29.755181896551726, -1.5014456 ], [ 29.755181896551726, -1.501176123076923 ], [ 29.754912413793104, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5694, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.501176123076923 ], [ 29.755181896551726, -1.5014456 ], [ 29.755451379310344, -1.5014456 ], [ 29.755451379310344, -1.501176123076923 ], [ 29.755181896551726, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5695, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.501176123076923 ], [ 29.755451379310344, -1.501715076923077 ], [ 29.755720862068966, -1.501715076923077 ], [ 29.755720862068966, -1.501176123076923 ], [ 29.755451379310344, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5696, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.501176123076923 ], [ 29.755720862068966, -1.501715076923077 ], [ 29.755990344827588, -1.501715076923077 ], [ 29.755990344827588, -1.501176123076923 ], [ 29.755720862068966, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5697, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.501176123076923 ], [ 29.757068275862068, -1.501715076923077 ], [ 29.75733775862069, -1.501715076923077 ], [ 29.75733775862069, -1.501176123076923 ], [ 29.757068275862068, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5698, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.501176123076923 ], [ 29.757876724137933, -1.5014456 ], [ 29.758146206896551, -1.5014456 ], [ 29.758146206896551, -1.501176123076923 ], [ 29.757876724137933, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5699, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.501176123076923 ], [ 29.758146206896551, -1.5014456 ], [ 29.758415689655173, -1.5014456 ], [ 29.758415689655173, -1.501176123076923 ], [ 29.758146206896551, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5700, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.501176123076923 ], [ 29.758415689655173, -1.501715076923077 ], [ 29.758685172413795, -1.501715076923077 ], [ 29.758685172413795, -1.501176123076923 ], [ 29.758415689655173, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5701, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.501176123076923 ], [ 29.758954655172413, -1.5014456 ], [ 29.759224137931035, -1.5014456 ], [ 29.759224137931035, -1.501176123076923 ], [ 29.758954655172413, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5702, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.501176123076923 ], [ 29.759224137931035, -1.5014456 ], [ 29.759493620689657, -1.5014456 ], [ 29.759493620689657, -1.501176123076923 ], [ 29.759224137931035, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5703, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.501176123076923 ], [ 29.759493620689657, -1.501715076923077 ], [ 29.759763103448275, -1.501715076923077 ], [ 29.759763103448275, -1.501176123076923 ], [ 29.759493620689657, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5704, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.501176123076923 ], [ 29.759763103448275, -1.501715076923077 ], [ 29.760032586206897, -1.501715076923077 ], [ 29.760032586206897, -1.501176123076923 ], [ 29.759763103448275, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5705, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.501176123076923 ], [ 29.760571551724137, -1.5014456 ], [ 29.760841034482759, -1.5014456 ], [ 29.760841034482759, -1.501176123076923 ], [ 29.760571551724137, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5706, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.501176123076923 ], [ 29.760841034482759, -1.5014456 ], [ 29.761110517241381, -1.5014456 ], [ 29.761110517241381, -1.501176123076923 ], [ 29.760841034482759, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5707, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.501176123076923 ], [ 29.761110517241381, -1.5014456 ], [ 29.76138, -1.5014456 ], [ 29.76138, -1.501176123076923 ], [ 29.761110517241381, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5708, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.501176123076923 ], [ 29.761649482758621, -1.5014456 ], [ 29.761918965517243, -1.5014456 ], [ 29.761918965517243, -1.501176123076923 ], [ 29.761649482758621, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5709, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.501176123076923 ], [ 29.761918965517243, -1.5014456 ], [ 29.762188448275861, -1.5014456 ], [ 29.762188448275861, -1.501176123076923 ], [ 29.761918965517243, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5710, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.501176123076923 ], [ 29.762188448275861, -1.5014456 ], [ 29.762457931034483, -1.5014456 ], [ 29.762457931034483, -1.501176123076923 ], [ 29.762188448275861, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5711, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.501176123076923 ], [ 29.762457931034483, -1.5014456 ], [ 29.762727413793105, -1.5014456 ], [ 29.762727413793105, -1.501176123076923 ], [ 29.762457931034483, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5712, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.501176123076923 ], [ 29.762727413793105, -1.5014456 ], [ 29.762996896551723, -1.5014456 ], [ 29.762996896551723, -1.501176123076923 ], [ 29.762727413793105, -1.501176123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5713, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.5014456 ], [ 29.719071206896551, -1.501715076923077 ], [ 29.719340689655173, -1.501715076923077 ], [ 29.719340689655173, -1.5014456 ], [ 29.719071206896551, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5714, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.5014456 ], [ 29.719340689655173, -1.501715076923077 ], [ 29.719610172413795, -1.501715076923077 ], [ 29.719610172413795, -1.5014456 ], [ 29.719340689655173, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5715, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.5014456 ], [ 29.719610172413795, -1.501715076923077 ], [ 29.719879655172413, -1.501715076923077 ], [ 29.719879655172413, -1.5014456 ], [ 29.719610172413795, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5716, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.5014456 ], [ 29.719879655172413, -1.501715076923077 ], [ 29.720149137931035, -1.501715076923077 ], [ 29.720149137931035, -1.5014456 ], [ 29.719879655172413, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5717, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.5014456 ], [ 29.720149137931035, -1.501715076923077 ], [ 29.720688103448275, -1.501715076923077 ], [ 29.720688103448275, -1.5014456 ], [ 29.720149137931035, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5718, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.5014456 ], [ 29.720688103448275, -1.501715076923077 ], [ 29.720957586206897, -1.501715076923077 ], [ 29.720957586206897, -1.5014456 ], [ 29.720688103448275, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5719, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.5014456 ], [ 29.720957586206897, -1.501715076923077 ], [ 29.721227068965518, -1.501715076923077 ], [ 29.721227068965518, -1.5014456 ], [ 29.720957586206897, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5720, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.5014456 ], [ 29.721227068965518, -1.501715076923077 ], [ 29.722305, -1.501715076923077 ], [ 29.722305, -1.5014456 ], [ 29.721227068965518, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5721, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.5014456 ], [ 29.722305, -1.501715076923077 ], [ 29.72257448275862, -1.501715076923077 ], [ 29.72257448275862, -1.5014456 ], [ 29.722305, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5722, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.5014456 ], [ 29.72257448275862, -1.501715076923077 ], [ 29.722843965517242, -1.501715076923077 ], [ 29.722843965517242, -1.5014456 ], [ 29.72257448275862, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5723, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.5014456 ], [ 29.722843965517242, -1.501715076923077 ], [ 29.723113448275861, -1.501715076923077 ], [ 29.723113448275861, -1.5014456 ], [ 29.722843965517242, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5724, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.5014456 ], [ 29.723113448275861, -1.501715076923077 ], [ 29.723382931034482, -1.501715076923077 ], [ 29.723382931034482, -1.5014456 ], [ 29.723113448275861, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5725, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.5014456 ], [ 29.723382931034482, -1.501715076923077 ], [ 29.723652413793104, -1.501715076923077 ], [ 29.723652413793104, -1.5014456 ], [ 29.723382931034482, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5726, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.5014456 ], [ 29.723652413793104, -1.501715076923077 ], [ 29.723921896551722, -1.501715076923077 ], [ 29.723921896551722, -1.5014456 ], [ 29.723652413793104, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5727, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.5014456 ], [ 29.723921896551722, -1.501715076923077 ], [ 29.724191379310344, -1.501715076923077 ], [ 29.724191379310344, -1.5014456 ], [ 29.723921896551722, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5728, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.5014456 ], [ 29.724191379310344, -1.501715076923077 ], [ 29.724460862068966, -1.501715076923077 ], [ 29.724460862068966, -1.5014456 ], [ 29.724191379310344, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5729, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.5014456 ], [ 29.724460862068966, -1.501984553846154 ], [ 29.724730344827588, -1.501984553846154 ], [ 29.724730344827588, -1.5014456 ], [ 29.724460862068966, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5730, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.5014456 ], [ 29.725269310344828, -1.501984553846154 ], [ 29.72553879310345, -1.501984553846154 ], [ 29.72553879310345, -1.5014456 ], [ 29.725269310344828, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5731, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.5014456 ], [ 29.726347241379312, -1.502254030769231 ], [ 29.72661672413793, -1.502254030769231 ], [ 29.72661672413793, -1.5014456 ], [ 29.726347241379312, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5732, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.5014456 ], [ 29.726886206896552, -1.501715076923077 ], [ 29.727155689655174, -1.501715076923077 ], [ 29.727155689655174, -1.5014456 ], [ 29.726886206896552, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5733, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.5014456 ], [ 29.727155689655174, -1.501984553846154 ], [ 29.727425172413792, -1.501984553846154 ], [ 29.727425172413792, -1.5014456 ], [ 29.727155689655174, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5734, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.5014456 ], [ 29.727425172413792, -1.501984553846154 ], [ 29.727694655172414, -1.501984553846154 ], [ 29.727694655172414, -1.5014456 ], [ 29.727425172413792, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5735, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.5014456 ], [ 29.727694655172414, -1.502254030769231 ], [ 29.727964137931036, -1.502254030769231 ], [ 29.727964137931036, -1.5014456 ], [ 29.727694655172414, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5736, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.5014456 ], [ 29.730658965517243, -1.501984553846154 ], [ 29.730928448275861, -1.501984553846154 ], [ 29.730928448275861, -1.5014456 ], [ 29.730658965517243, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5737, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.5014456 ], [ 29.731467413793105, -1.501715076923077 ], [ 29.731736896551723, -1.501715076923077 ], [ 29.731736896551723, -1.5014456 ], [ 29.731467413793105, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5738, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.5014456 ], [ 29.731736896551723, -1.501715076923077 ], [ 29.732814827586207, -1.501715076923077 ], [ 29.732814827586207, -1.5014456 ], [ 29.731736896551723, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5739, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.5014456 ], [ 29.732814827586207, -1.501715076923077 ], [ 29.733084310344829, -1.501715076923077 ], [ 29.733084310344829, -1.5014456 ], [ 29.732814827586207, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5740, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.5014456 ], [ 29.733623275862069, -1.501984553846154 ], [ 29.733892758620691, -1.501984553846154 ], [ 29.733892758620691, -1.5014456 ], [ 29.733623275862069, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5741, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.5014456 ], [ 29.736318103448276, -1.502254030769231 ], [ 29.736587586206898, -1.502254030769231 ], [ 29.736587586206898, -1.5014456 ], [ 29.736318103448276, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5742, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.5014456 ], [ 29.736587586206898, -1.501715076923077 ], [ 29.736857068965517, -1.501715076923077 ], [ 29.736857068965517, -1.5014456 ], [ 29.736587586206898, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5743, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.5014456 ], [ 29.736857068965517, -1.501984553846154 ], [ 29.737126551724138, -1.501984553846154 ], [ 29.737126551724138, -1.5014456 ], [ 29.736857068965517, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5744, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.5014456 ], [ 29.73739603448276, -1.501715076923077 ], [ 29.737665517241378, -1.501715076923077 ], [ 29.737665517241378, -1.5014456 ], [ 29.73739603448276, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5745, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.5014456 ], [ 29.737665517241378, -1.501715076923077 ], [ 29.737935, -1.501715076923077 ], [ 29.737935, -1.5014456 ], [ 29.737665517241378, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5746, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.5014456 ], [ 29.737935, -1.501715076923077 ], [ 29.738204482758622, -1.501715076923077 ], [ 29.738204482758622, -1.5014456 ], [ 29.737935, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5747, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.5014456 ], [ 29.738204482758622, -1.501715076923077 ], [ 29.738743448275862, -1.501715076923077 ], [ 29.738743448275862, -1.5014456 ], [ 29.738204482758622, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5748, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.5014456 ], [ 29.738743448275862, -1.501715076923077 ], [ 29.739012931034484, -1.501715076923077 ], [ 29.739012931034484, -1.5014456 ], [ 29.738743448275862, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5749, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.5014456 ], [ 29.739012931034484, -1.501715076923077 ], [ 29.739282413793102, -1.501715076923077 ], [ 29.739282413793102, -1.5014456 ], [ 29.739012931034484, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5750, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.5014456 ], [ 29.739282413793102, -1.501715076923077 ], [ 29.739551896551724, -1.501715076923077 ], [ 29.739551896551724, -1.5014456 ], [ 29.739282413793102, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5751, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.5014456 ], [ 29.739551896551724, -1.501715076923077 ], [ 29.739821379310346, -1.501715076923077 ], [ 29.739821379310346, -1.5014456 ], [ 29.739551896551724, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5752, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.5014456 ], [ 29.739821379310346, -1.501715076923077 ], [ 29.740090862068964, -1.501715076923077 ], [ 29.740090862068964, -1.5014456 ], [ 29.739821379310346, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5753, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.5014456 ], [ 29.740090862068964, -1.501715076923077 ], [ 29.740360344827586, -1.501715076923077 ], [ 29.740360344827586, -1.5014456 ], [ 29.740090862068964, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5754, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.5014456 ], [ 29.740360344827586, -1.501715076923077 ], [ 29.740629827586208, -1.501715076923077 ], [ 29.740629827586208, -1.5014456 ], [ 29.740360344827586, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5755, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.5014456 ], [ 29.740629827586208, -1.501715076923077 ], [ 29.741168793103448, -1.501715076923077 ], [ 29.741168793103448, -1.5014456 ], [ 29.740629827586208, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5756, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.5014456 ], [ 29.741168793103448, -1.501715076923077 ], [ 29.742246724137932, -1.501715076923077 ], [ 29.742246724137932, -1.5014456 ], [ 29.741168793103448, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5757, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.5014456 ], [ 29.742246724137932, -1.501715076923077 ], [ 29.742516206896553, -1.501715076923077 ], [ 29.742516206896553, -1.5014456 ], [ 29.742246724137932, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5758, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.5014456 ], [ 29.742516206896553, -1.501715076923077 ], [ 29.743594137931034, -1.501715076923077 ], [ 29.743594137931034, -1.5014456 ], [ 29.742516206896553, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5759, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749792241379311, -1.5014456 ], [ 29.749792241379311, -1.501715076923077 ], [ 29.750061724137932, -1.501715076923077 ], [ 29.750061724137932, -1.5014456 ], [ 29.749792241379311, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5760, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.5014456 ], [ 29.750061724137932, -1.501715076923077 ], [ 29.750331206896551, -1.501715076923077 ], [ 29.750331206896551, -1.5014456 ], [ 29.750061724137932, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5761, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750331206896551, -1.5014456 ], [ 29.750331206896551, -1.501715076923077 ], [ 29.750870172413794, -1.501715076923077 ], [ 29.750870172413794, -1.5014456 ], [ 29.750331206896551, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5762, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.5014456 ], [ 29.750870172413794, -1.501715076923077 ], [ 29.751139655172413, -1.501715076923077 ], [ 29.751139655172413, -1.5014456 ], [ 29.750870172413794, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5763, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.5014456 ], [ 29.751139655172413, -1.501715076923077 ], [ 29.751409137931034, -1.501715076923077 ], [ 29.751409137931034, -1.5014456 ], [ 29.751139655172413, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5764, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.5014456 ], [ 29.751409137931034, -1.501715076923077 ], [ 29.751678620689656, -1.501715076923077 ], [ 29.751678620689656, -1.5014456 ], [ 29.751409137931034, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5765, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.5014456 ], [ 29.751678620689656, -1.501715076923077 ], [ 29.751948103448278, -1.501715076923077 ], [ 29.751948103448278, -1.5014456 ], [ 29.751678620689656, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5766, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.5014456 ], [ 29.751948103448278, -1.501715076923077 ], [ 29.752217586206896, -1.501715076923077 ], [ 29.752217586206896, -1.5014456 ], [ 29.751948103448278, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5767, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.5014456 ], [ 29.752217586206896, -1.501715076923077 ], [ 29.752487068965518, -1.501715076923077 ], [ 29.752487068965518, -1.5014456 ], [ 29.752217586206896, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5768, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.5014456 ], [ 29.752487068965518, -1.501715076923077 ], [ 29.75275655172414, -1.501715076923077 ], [ 29.75275655172414, -1.5014456 ], [ 29.752487068965518, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5769, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.5014456 ], [ 29.75275655172414, -1.501715076923077 ], [ 29.753026034482758, -1.501715076923077 ], [ 29.753026034482758, -1.5014456 ], [ 29.75275655172414, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5770, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.5014456 ], [ 29.753026034482758, -1.501715076923077 ], [ 29.75329551724138, -1.501715076923077 ], [ 29.75329551724138, -1.5014456 ], [ 29.753026034482758, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5771, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.5014456 ], [ 29.75329551724138, -1.501715076923077 ], [ 29.753565, -1.501715076923077 ], [ 29.753565, -1.5014456 ], [ 29.75329551724138, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5772, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.5014456 ], [ 29.753565, -1.501715076923077 ], [ 29.75383448275862, -1.501715076923077 ], [ 29.75383448275862, -1.5014456 ], [ 29.753565, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5773, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.5014456 ], [ 29.75383448275862, -1.501715076923077 ], [ 29.754103965517242, -1.501715076923077 ], [ 29.754103965517242, -1.5014456 ], [ 29.75383448275862, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5774, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.5014456 ], [ 29.754103965517242, -1.501984553846154 ], [ 29.754373448275864, -1.501984553846154 ], [ 29.754373448275864, -1.5014456 ], [ 29.754103965517242, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5775, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.5014456 ], [ 29.754642931034482, -1.501715076923077 ], [ 29.754912413793104, -1.501715076923077 ], [ 29.754912413793104, -1.5014456 ], [ 29.754642931034482, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5776, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.5014456 ], [ 29.754912413793104, -1.501984553846154 ], [ 29.755181896551726, -1.501984553846154 ], [ 29.755181896551726, -1.5014456 ], [ 29.754912413793104, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5777, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.5014456 ], [ 29.755181896551726, -1.501984553846154 ], [ 29.755451379310344, -1.501984553846154 ], [ 29.755451379310344, -1.5014456 ], [ 29.755181896551726, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5778, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.5014456 ], [ 29.755990344827588, -1.501984553846154 ], [ 29.756259827586206, -1.501984553846154 ], [ 29.756259827586206, -1.5014456 ], [ 29.755990344827588, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5779, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.5014456 ], [ 29.756259827586206, -1.502254030769231 ], [ 29.756798793103449, -1.502254030769231 ], [ 29.756798793103449, -1.5014456 ], [ 29.756259827586206, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5780, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.5014456 ], [ 29.75733775862069, -1.501715076923077 ], [ 29.757607241379311, -1.501715076923077 ], [ 29.757607241379311, -1.5014456 ], [ 29.75733775862069, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5781, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.5014456 ], [ 29.757607241379311, -1.501715076923077 ], [ 29.757876724137933, -1.501715076923077 ], [ 29.757876724137933, -1.5014456 ], [ 29.757607241379311, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5782, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.5014456 ], [ 29.757876724137933, -1.501715076923077 ], [ 29.758146206896551, -1.501715076923077 ], [ 29.758146206896551, -1.5014456 ], [ 29.757876724137933, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5783, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.5014456 ], [ 29.758146206896551, -1.501984553846154 ], [ 29.758415689655173, -1.501984553846154 ], [ 29.758415689655173, -1.5014456 ], [ 29.758146206896551, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5784, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5014456 ], [ 29.758685172413795, -1.501715076923077 ], [ 29.758954655172413, -1.501715076923077 ], [ 29.758954655172413, -1.5014456 ], [ 29.758685172413795, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5785, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.5014456 ], [ 29.758954655172413, -1.501715076923077 ], [ 29.759224137931035, -1.501715076923077 ], [ 29.759224137931035, -1.5014456 ], [ 29.758954655172413, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5786, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.5014456 ], [ 29.759224137931035, -1.501984553846154 ], [ 29.759493620689657, -1.501984553846154 ], [ 29.759493620689657, -1.5014456 ], [ 29.759224137931035, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5787, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.5014456 ], [ 29.760032586206897, -1.501715076923077 ], [ 29.760302068965519, -1.501715076923077 ], [ 29.760302068965519, -1.5014456 ], [ 29.760032586206897, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5788, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.5014456 ], [ 29.760302068965519, -1.501715076923077 ], [ 29.760571551724137, -1.501715076923077 ], [ 29.760571551724137, -1.5014456 ], [ 29.760302068965519, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5789, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.5014456 ], [ 29.760571551724137, -1.501715076923077 ], [ 29.760841034482759, -1.501715076923077 ], [ 29.760841034482759, -1.5014456 ], [ 29.760571551724137, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5790, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5014456 ], [ 29.760841034482759, -1.501715076923077 ], [ 29.761110517241381, -1.501715076923077 ], [ 29.761110517241381, -1.5014456 ], [ 29.760841034482759, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5791, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5014456 ], [ 29.761110517241381, -1.501715076923077 ], [ 29.76138, -1.501715076923077 ], [ 29.76138, -1.5014456 ], [ 29.761110517241381, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5792, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5014456 ], [ 29.76138, -1.501715076923077 ], [ 29.761649482758621, -1.501715076923077 ], [ 29.761649482758621, -1.5014456 ], [ 29.76138, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5793, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.5014456 ], [ 29.761649482758621, -1.501715076923077 ], [ 29.761918965517243, -1.501715076923077 ], [ 29.761918965517243, -1.5014456 ], [ 29.761649482758621, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5794, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5014456 ], [ 29.761918965517243, -1.501715076923077 ], [ 29.762188448275861, -1.501715076923077 ], [ 29.762188448275861, -1.5014456 ], [ 29.761918965517243, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5795, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5014456 ], [ 29.762188448275861, -1.501715076923077 ], [ 29.762457931034483, -1.501715076923077 ], [ 29.762457931034483, -1.5014456 ], [ 29.762188448275861, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5796, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5014456 ], [ 29.762457931034483, -1.501715076923077 ], [ 29.762727413793105, -1.501715076923077 ], [ 29.762727413793105, -1.5014456 ], [ 29.762457931034483, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5797, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5014456 ], [ 29.762727413793105, -1.501715076923077 ], [ 29.762996896551723, -1.501715076923077 ], [ 29.762996896551723, -1.5014456 ], [ 29.762727413793105, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5798, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.5014456 ], [ 29.762996896551723, -1.501715076923077 ], [ 29.763266379310345, -1.501715076923077 ], [ 29.763266379310345, -1.5014456 ], [ 29.762996896551723, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5799, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.501715076923077 ], [ 29.719340689655173, -1.501984553846154 ], [ 29.719610172413795, -1.501984553846154 ], [ 29.719610172413795, -1.501715076923077 ], [ 29.719340689655173, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5800, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.501715076923077 ], [ 29.719610172413795, -1.501984553846154 ], [ 29.719879655172413, -1.501984553846154 ], [ 29.719879655172413, -1.501715076923077 ], [ 29.719610172413795, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5801, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.501715076923077 ], [ 29.719879655172413, -1.502523507692308 ], [ 29.720957586206897, -1.502523507692308 ], [ 29.720957586206897, -1.502254030769231 ], [ 29.720149137931035, -1.502254030769231 ], [ 29.720149137931035, -1.501715076923077 ], [ 29.719879655172413, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5802, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.501715076923077 ], [ 29.720149137931035, -1.502254030769231 ], [ 29.720957586206897, -1.502254030769231 ], [ 29.720957586206897, -1.501984553846154 ], [ 29.720688103448275, -1.501984553846154 ], [ 29.720688103448275, -1.501715076923077 ], [ 29.720149137931035, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5803, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.501715076923077 ], [ 29.720688103448275, -1.501984553846154 ], [ 29.720957586206897, -1.501984553846154 ], [ 29.720957586206897, -1.501715076923077 ], [ 29.720688103448275, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5804, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.501715076923077 ], [ 29.720957586206897, -1.501984553846154 ], [ 29.721227068965518, -1.501984553846154 ], [ 29.721227068965518, -1.501715076923077 ], [ 29.720957586206897, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5805, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.501715076923077 ], [ 29.721227068965518, -1.501984553846154 ], [ 29.722305, -1.501984553846154 ], [ 29.722305, -1.501715076923077 ], [ 29.721227068965518, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5806, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.501715076923077 ], [ 29.722305, -1.501984553846154 ], [ 29.72257448275862, -1.501984553846154 ], [ 29.72257448275862, -1.501715076923077 ], [ 29.722305, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5807, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.501715076923077 ], [ 29.72257448275862, -1.501984553846154 ], [ 29.722843965517242, -1.501984553846154 ], [ 29.722843965517242, -1.501715076923077 ], [ 29.72257448275862, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5808, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.501715076923077 ], [ 29.722843965517242, -1.502254030769231 ], [ 29.723113448275861, -1.502254030769231 ], [ 29.723113448275861, -1.501715076923077 ], [ 29.722843965517242, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5809, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.501715076923077 ], [ 29.723113448275861, -1.501984553846154 ], [ 29.723382931034482, -1.501984553846154 ], [ 29.723382931034482, -1.501715076923077 ], [ 29.723113448275861, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5810, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.501715076923077 ], [ 29.723382931034482, -1.501984553846154 ], [ 29.723652413793104, -1.501984553846154 ], [ 29.723652413793104, -1.501715076923077 ], [ 29.723382931034482, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5811, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.501715076923077 ], [ 29.723652413793104, -1.501984553846154 ], [ 29.723921896551722, -1.501984553846154 ], [ 29.723921896551722, -1.501715076923077 ], [ 29.723652413793104, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5812, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.501715076923077 ], [ 29.726886206896552, -1.502254030769231 ], [ 29.727155689655174, -1.502254030769231 ], [ 29.727155689655174, -1.501715076923077 ], [ 29.726886206896552, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5813, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.501715076923077 ], [ 29.727964137931036, -1.502254030769231 ], [ 29.728233620689654, -1.502254030769231 ], [ 29.728233620689654, -1.501715076923077 ], [ 29.727964137931036, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5814, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.501715076923077 ], [ 29.728772586206897, -1.502254030769231 ], [ 29.729042068965516, -1.502254030769231 ], [ 29.729042068965516, -1.501715076923077 ], [ 29.728772586206897, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5815, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.501715076923077 ], [ 29.729850517241381, -1.501984553846154 ], [ 29.73012, -1.501984553846154 ], [ 29.73012, -1.501715076923077 ], [ 29.729850517241381, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5816, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.501715076923077 ], [ 29.73012, -1.501984553846154 ], [ 29.730389482758621, -1.501984553846154 ], [ 29.730389482758621, -1.501715076923077 ], [ 29.73012, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5817, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.501715076923077 ], [ 29.730389482758621, -1.501984553846154 ], [ 29.730658965517243, -1.501984553846154 ], [ 29.730658965517243, -1.501715076923077 ], [ 29.730389482758621, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5818, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.501715076923077 ], [ 29.730928448275861, -1.501984553846154 ], [ 29.731197931034483, -1.501984553846154 ], [ 29.731197931034483, -1.501715076923077 ], [ 29.730928448275861, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5819, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.501715076923077 ], [ 29.731197931034483, -1.501984553846154 ], [ 29.731467413793105, -1.501984553846154 ], [ 29.731467413793105, -1.501715076923077 ], [ 29.731197931034483, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5820, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.501715076923077 ], [ 29.731467413793105, -1.501984553846154 ], [ 29.733084310344829, -1.501984553846154 ], [ 29.733084310344829, -1.501715076923077 ], [ 29.731467413793105, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5821, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.501715076923077 ], [ 29.733084310344829, -1.501984553846154 ], [ 29.733353793103447, -1.501984553846154 ], [ 29.733353793103447, -1.501715076923077 ], [ 29.733084310344829, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5822, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.501715076923077 ], [ 29.733353793103447, -1.501984553846154 ], [ 29.733623275862069, -1.501984553846154 ], [ 29.733623275862069, -1.501715076923077 ], [ 29.733353793103447, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5823, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.501715076923077 ], [ 29.733892758620691, -1.501984553846154 ], [ 29.734162241379309, -1.501984553846154 ], [ 29.734162241379309, -1.501715076923077 ], [ 29.733892758620691, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5824, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.501715076923077 ], [ 29.734162241379309, -1.501984553846154 ], [ 29.734431724137931, -1.501984553846154 ], [ 29.734431724137931, -1.501715076923077 ], [ 29.734162241379309, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5825, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.501715076923077 ], [ 29.734431724137931, -1.501984553846154 ], [ 29.734701206896553, -1.501984553846154 ], [ 29.734701206896553, -1.501715076923077 ], [ 29.734431724137931, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5826, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.501715076923077 ], [ 29.735240172413793, -1.502254030769231 ], [ 29.735509655172415, -1.502254030769231 ], [ 29.735509655172415, -1.501715076923077 ], [ 29.735240172413793, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5827, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.501715076923077 ], [ 29.736048620689655, -1.502254030769231 ], [ 29.736318103448276, -1.502254030769231 ], [ 29.736318103448276, -1.501715076923077 ], [ 29.736048620689655, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5828, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.501715076923077 ], [ 29.736587586206898, -1.501984553846154 ], [ 29.736857068965517, -1.501984553846154 ], [ 29.736857068965517, -1.501715076923077 ], [ 29.736587586206898, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5829, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.501715076923077 ], [ 29.737126551724138, -1.501984553846154 ], [ 29.73739603448276, -1.501984553846154 ], [ 29.73739603448276, -1.501715076923077 ], [ 29.737126551724138, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5830, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.501715076923077 ], [ 29.73739603448276, -1.501984553846154 ], [ 29.737665517241378, -1.501984553846154 ], [ 29.737665517241378, -1.501715076923077 ], [ 29.73739603448276, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5831, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.501715076923077 ], [ 29.737665517241378, -1.501984553846154 ], [ 29.737935, -1.501984553846154 ], [ 29.737935, -1.501715076923077 ], [ 29.737665517241378, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5832, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.501715076923077 ], [ 29.737935, -1.501984553846154 ], [ 29.738204482758622, -1.501984553846154 ], [ 29.738204482758622, -1.501715076923077 ], [ 29.737935, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5833, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.501715076923077 ], [ 29.738204482758622, -1.501984553846154 ], [ 29.738743448275862, -1.501984553846154 ], [ 29.738743448275862, -1.501715076923077 ], [ 29.738204482758622, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5834, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.501715076923077 ], [ 29.738743448275862, -1.501984553846154 ], [ 29.739012931034484, -1.501984553846154 ], [ 29.739012931034484, -1.501715076923077 ], [ 29.738743448275862, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5835, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.501715076923077 ], [ 29.739012931034484, -1.501984553846154 ], [ 29.739282413793102, -1.501984553846154 ], [ 29.739282413793102, -1.501715076923077 ], [ 29.739012931034484, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5836, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.501715076923077 ], [ 29.739282413793102, -1.501984553846154 ], [ 29.739551896551724, -1.501984553846154 ], [ 29.739551896551724, -1.501715076923077 ], [ 29.739282413793102, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5837, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.501715076923077 ], [ 29.739551896551724, -1.501984553846154 ], [ 29.739821379310346, -1.501984553846154 ], [ 29.739821379310346, -1.501715076923077 ], [ 29.739551896551724, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5838, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.501715076923077 ], [ 29.739821379310346, -1.501984553846154 ], [ 29.740090862068964, -1.501984553846154 ], [ 29.740090862068964, -1.501715076923077 ], [ 29.739821379310346, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5839, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.501715076923077 ], [ 29.740090862068964, -1.501984553846154 ], [ 29.740360344827586, -1.501984553846154 ], [ 29.740360344827586, -1.501715076923077 ], [ 29.740090862068964, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5840, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.501715076923077 ], [ 29.740360344827586, -1.501984553846154 ], [ 29.74089931034483, -1.501984553846154 ], [ 29.74089931034483, -1.501715076923077 ], [ 29.740360344827586, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5841, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.501715076923077 ], [ 29.74089931034483, -1.501984553846154 ], [ 29.741168793103448, -1.501984553846154 ], [ 29.741168793103448, -1.501715076923077 ], [ 29.74089931034483, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5842, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.501715076923077 ], [ 29.741168793103448, -1.501984553846154 ], [ 29.742246724137932, -1.501984553846154 ], [ 29.742246724137932, -1.501715076923077 ], [ 29.741168793103448, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5843, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.501715076923077 ], [ 29.742246724137932, -1.501984553846154 ], [ 29.743324655172415, -1.501984553846154 ], [ 29.743324655172415, -1.501715076923077 ], [ 29.742246724137932, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5844, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.501715076923077 ], [ 29.743324655172415, -1.501984553846154 ], [ 29.743594137931034, -1.501984553846154 ], [ 29.743594137931034, -1.501715076923077 ], [ 29.743324655172415, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5845, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750061724137932, -1.501715076923077 ], [ 29.750061724137932, -1.501984553846154 ], [ 29.750600689655172, -1.501984553846154 ], [ 29.750600689655172, -1.501715076923077 ], [ 29.750061724137932, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5846, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.501715076923077 ], [ 29.750600689655172, -1.501984553846154 ], [ 29.750870172413794, -1.501984553846154 ], [ 29.750870172413794, -1.501715076923077 ], [ 29.750600689655172, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5847, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.501715076923077 ], [ 29.750870172413794, -1.501984553846154 ], [ 29.751139655172413, -1.501984553846154 ], [ 29.751139655172413, -1.501715076923077 ], [ 29.750870172413794, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5848, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.501715076923077 ], [ 29.751139655172413, -1.501984553846154 ], [ 29.751409137931034, -1.501984553846154 ], [ 29.751409137931034, -1.501715076923077 ], [ 29.751139655172413, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5849, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.501715076923077 ], [ 29.751409137931034, -1.501984553846154 ], [ 29.751678620689656, -1.501984553846154 ], [ 29.751678620689656, -1.501715076923077 ], [ 29.751409137931034, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5850, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.501715076923077 ], [ 29.751678620689656, -1.501984553846154 ], [ 29.751948103448278, -1.501984553846154 ], [ 29.751948103448278, -1.501715076923077 ], [ 29.751678620689656, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5851, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.501715076923077 ], [ 29.751948103448278, -1.501984553846154 ], [ 29.752217586206896, -1.501984553846154 ], [ 29.752217586206896, -1.501715076923077 ], [ 29.751948103448278, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5852, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.501715076923077 ], [ 29.752217586206896, -1.501984553846154 ], [ 29.752487068965518, -1.501984553846154 ], [ 29.752487068965518, -1.501715076923077 ], [ 29.752217586206896, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5853, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.501715076923077 ], [ 29.752487068965518, -1.501984553846154 ], [ 29.75275655172414, -1.501984553846154 ], [ 29.75275655172414, -1.501715076923077 ], [ 29.752487068965518, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5854, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.501715076923077 ], [ 29.75275655172414, -1.501984553846154 ], [ 29.753026034482758, -1.501984553846154 ], [ 29.753026034482758, -1.501715076923077 ], [ 29.75275655172414, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5855, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.501715076923077 ], [ 29.753026034482758, -1.501984553846154 ], [ 29.75329551724138, -1.501984553846154 ], [ 29.75329551724138, -1.501715076923077 ], [ 29.753026034482758, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5856, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.501715076923077 ], [ 29.75329551724138, -1.501984553846154 ], [ 29.753565, -1.501984553846154 ], [ 29.753565, -1.501715076923077 ], [ 29.75329551724138, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5857, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.501715076923077 ], [ 29.753565, -1.502254030769231 ], [ 29.75383448275862, -1.502254030769231 ], [ 29.75383448275862, -1.501715076923077 ], [ 29.753565, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5858, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.501715076923077 ], [ 29.754373448275864, -1.502254030769231 ], [ 29.754642931034482, -1.502254030769231 ], [ 29.754642931034482, -1.501715076923077 ], [ 29.754373448275864, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5859, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.501715076923077 ], [ 29.755451379310344, -1.502523507692308 ], [ 29.755720862068966, -1.502523507692308 ], [ 29.755720862068966, -1.501715076923077 ], [ 29.755451379310344, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5860, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.501715076923077 ], [ 29.757068275862068, -1.501984553846154 ], [ 29.75733775862069, -1.501984553846154 ], [ 29.75733775862069, -1.501715076923077 ], [ 29.757068275862068, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5861, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.501715076923077 ], [ 29.75733775862069, -1.501984553846154 ], [ 29.757607241379311, -1.501984553846154 ], [ 29.757607241379311, -1.501715076923077 ], [ 29.75733775862069, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5862, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.501715076923077 ], [ 29.757607241379311, -1.502254030769231 ], [ 29.757876724137933, -1.502254030769231 ], [ 29.757876724137933, -1.501715076923077 ], [ 29.757607241379311, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5863, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.501715076923077 ], [ 29.757876724137933, -1.502254030769231 ], [ 29.758146206896551, -1.502254030769231 ], [ 29.758146206896551, -1.501715076923077 ], [ 29.757876724137933, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5864, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.501715076923077 ], [ 29.758415689655173, -1.501984553846154 ], [ 29.758685172413795, -1.501984553846154 ], [ 29.758685172413795, -1.501715076923077 ], [ 29.758415689655173, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5865, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.501715076923077 ], [ 29.758685172413795, -1.501984553846154 ], [ 29.758954655172413, -1.501984553846154 ], [ 29.758954655172413, -1.501715076923077 ], [ 29.758685172413795, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5866, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.501715076923077 ], [ 29.758954655172413, -1.502254030769231 ], [ 29.759224137931035, -1.502254030769231 ], [ 29.759224137931035, -1.501715076923077 ], [ 29.758954655172413, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5867, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.501715076923077 ], [ 29.759493620689657, -1.501984553846154 ], [ 29.759763103448275, -1.501984553846154 ], [ 29.759763103448275, -1.501715076923077 ], [ 29.759493620689657, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5868, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.501715076923077 ], [ 29.759763103448275, -1.501984553846154 ], [ 29.760032586206897, -1.501984553846154 ], [ 29.760032586206897, -1.501715076923077 ], [ 29.759763103448275, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5869, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.501715076923077 ], [ 29.760032586206897, -1.501984553846154 ], [ 29.760302068965519, -1.501984553846154 ], [ 29.760302068965519, -1.501715076923077 ], [ 29.760032586206897, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5870, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.501715076923077 ], [ 29.760302068965519, -1.501984553846154 ], [ 29.760571551724137, -1.501984553846154 ], [ 29.760571551724137, -1.501715076923077 ], [ 29.760302068965519, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5871, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.501715076923077 ], [ 29.760571551724137, -1.501984553846154 ], [ 29.760841034482759, -1.501984553846154 ], [ 29.760841034482759, -1.501715076923077 ], [ 29.760571551724137, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5872, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.501715076923077 ], [ 29.760841034482759, -1.501984553846154 ], [ 29.761110517241381, -1.501984553846154 ], [ 29.761110517241381, -1.501715076923077 ], [ 29.760841034482759, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5873, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.501715076923077 ], [ 29.761110517241381, -1.501984553846154 ], [ 29.76138, -1.501984553846154 ], [ 29.76138, -1.501715076923077 ], [ 29.761110517241381, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5874, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.501715076923077 ], [ 29.76138, -1.501984553846154 ], [ 29.761649482758621, -1.501984553846154 ], [ 29.761649482758621, -1.501715076923077 ], [ 29.76138, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5875, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.501715076923077 ], [ 29.761649482758621, -1.501984553846154 ], [ 29.761918965517243, -1.501984553846154 ], [ 29.761918965517243, -1.501715076923077 ], [ 29.761649482758621, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5876, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.501715076923077 ], [ 29.761918965517243, -1.501984553846154 ], [ 29.762188448275861, -1.501984553846154 ], [ 29.762188448275861, -1.501715076923077 ], [ 29.761918965517243, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5877, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.501715076923077 ], [ 29.762188448275861, -1.501984553846154 ], [ 29.762457931034483, -1.501984553846154 ], [ 29.762457931034483, -1.501715076923077 ], [ 29.762188448275861, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5878, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.501715076923077 ], [ 29.762457931034483, -1.501984553846154 ], [ 29.762727413793105, -1.501984553846154 ], [ 29.762727413793105, -1.501715076923077 ], [ 29.762457931034483, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5879, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.501715076923077 ], [ 29.762727413793105, -1.501984553846154 ], [ 29.762996896551723, -1.501984553846154 ], [ 29.762996896551723, -1.501715076923077 ], [ 29.762727413793105, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5880, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.501715076923077 ], [ 29.762996896551723, -1.501984553846154 ], [ 29.763266379310345, -1.501984553846154 ], [ 29.763266379310345, -1.501715076923077 ], [ 29.762996896551723, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5881, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.501715076923077 ], [ 29.763266379310345, -1.502792984615384 ], [ 29.763535862068967, -1.502792984615384 ], [ 29.763535862068967, -1.501715076923077 ], [ 29.763266379310345, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5882, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.501984553846154 ], [ 29.719610172413795, -1.502254030769231 ], [ 29.719879655172413, -1.502254030769231 ], [ 29.719879655172413, -1.501984553846154 ], [ 29.719610172413795, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5883, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.501984553846154 ], [ 29.720957586206897, -1.502254030769231 ], [ 29.721227068965518, -1.502254030769231 ], [ 29.721227068965518, -1.501984553846154 ], [ 29.720957586206897, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5884, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.501984553846154 ], [ 29.721227068965518, -1.502254030769231 ], [ 29.721496551724137, -1.502254030769231 ], [ 29.721496551724137, -1.501984553846154 ], [ 29.721227068965518, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5885, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.501984553846154 ], [ 29.721496551724137, -1.502254030769231 ], [ 29.72203551724138, -1.502254030769231 ], [ 29.72203551724138, -1.501984553846154 ], [ 29.721496551724137, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5886, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.501984553846154 ], [ 29.72203551724138, -1.502254030769231 ], [ 29.722305, -1.502254030769231 ], [ 29.722305, -1.501984553846154 ], [ 29.72203551724138, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5887, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.501984553846154 ], [ 29.727155689655174, -1.502523507692308 ], [ 29.727425172413792, -1.502523507692308 ], [ 29.727425172413792, -1.501984553846154 ], [ 29.727155689655174, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5888, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.501984553846154 ], [ 29.727425172413792, -1.502523507692308 ], [ 29.727694655172414, -1.502523507692308 ], [ 29.727694655172414, -1.501984553846154 ], [ 29.727425172413792, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5889, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.501984553846154 ], [ 29.728233620689654, -1.502523507692308 ], [ 29.728503103448276, -1.502523507692308 ], [ 29.728503103448276, -1.501984553846154 ], [ 29.728233620689654, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5890, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.501984553846154 ], [ 29.729042068965516, -1.502254030769231 ], [ 29.729311551724138, -1.502254030769231 ], [ 29.729311551724138, -1.501984553846154 ], [ 29.729042068965516, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5891, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.501984553846154 ], [ 29.729311551724138, -1.502254030769231 ], [ 29.729850517241381, -1.502254030769231 ], [ 29.729850517241381, -1.501984553846154 ], [ 29.729311551724138, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5892, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.501984553846154 ], [ 29.729850517241381, -1.502254030769231 ], [ 29.73012, -1.502254030769231 ], [ 29.73012, -1.501984553846154 ], [ 29.729850517241381, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5893, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.501984553846154 ], [ 29.73012, -1.502254030769231 ], [ 29.730389482758621, -1.502254030769231 ], [ 29.730389482758621, -1.501984553846154 ], [ 29.73012, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5894, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.501984553846154 ], [ 29.730389482758621, -1.502254030769231 ], [ 29.730658965517243, -1.502254030769231 ], [ 29.730658965517243, -1.501984553846154 ], [ 29.730389482758621, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5895, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.501984553846154 ], [ 29.730658965517243, -1.502254030769231 ], [ 29.730928448275861, -1.502254030769231 ], [ 29.730928448275861, -1.501984553846154 ], [ 29.730658965517243, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5896, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.501984553846154 ], [ 29.730928448275861, -1.502254030769231 ], [ 29.731197931034483, -1.502254030769231 ], [ 29.731197931034483, -1.501984553846154 ], [ 29.730928448275861, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5897, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.501984553846154 ], [ 29.731197931034483, -1.502254030769231 ], [ 29.731467413793105, -1.502254030769231 ], [ 29.731467413793105, -1.501984553846154 ], [ 29.731197931034483, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5898, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.501984553846154 ], [ 29.731467413793105, -1.502254030769231 ], [ 29.733084310344829, -1.502254030769231 ], [ 29.733084310344829, -1.501984553846154 ], [ 29.731467413793105, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5899, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.501984553846154 ], [ 29.733084310344829, -1.502254030769231 ], [ 29.733353793103447, -1.502254030769231 ], [ 29.733353793103447, -1.501984553846154 ], [ 29.733084310344829, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5900, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.501984553846154 ], [ 29.733353793103447, -1.502254030769231 ], [ 29.733623275862069, -1.502254030769231 ], [ 29.733623275862069, -1.501984553846154 ], [ 29.733353793103447, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5901, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.501984553846154 ], [ 29.733623275862069, -1.502254030769231 ], [ 29.733892758620691, -1.502254030769231 ], [ 29.733892758620691, -1.501984553846154 ], [ 29.733623275862069, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5902, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.501984553846154 ], [ 29.733892758620691, -1.502254030769231 ], [ 29.734162241379309, -1.502254030769231 ], [ 29.734162241379309, -1.501984553846154 ], [ 29.733892758620691, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5903, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.501984553846154 ], [ 29.734162241379309, -1.502254030769231 ], [ 29.734431724137931, -1.502254030769231 ], [ 29.734431724137931, -1.501984553846154 ], [ 29.734162241379309, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5904, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.501984553846154 ], [ 29.734431724137931, -1.502254030769231 ], [ 29.734701206896553, -1.502254030769231 ], [ 29.734701206896553, -1.501984553846154 ], [ 29.734431724137931, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5905, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.501984553846154 ], [ 29.734701206896553, -1.502254030769231 ], [ 29.734970689655171, -1.502254030769231 ], [ 29.734970689655171, -1.501984553846154 ], [ 29.734701206896553, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5906, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.501984553846154 ], [ 29.734970689655171, -1.502254030769231 ], [ 29.735240172413793, -1.502254030769231 ], [ 29.735240172413793, -1.501984553846154 ], [ 29.734970689655171, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5907, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.501984553846154 ], [ 29.735779137931036, -1.502523507692308 ], [ 29.736048620689655, -1.502523507692308 ], [ 29.736048620689655, -1.501984553846154 ], [ 29.735779137931036, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5908, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.501984553846154 ], [ 29.736587586206898, -1.502254030769231 ], [ 29.736857068965517, -1.502254030769231 ], [ 29.736857068965517, -1.501984553846154 ], [ 29.736587586206898, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5909, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.501984553846154 ], [ 29.736857068965517, -1.502523507692308 ], [ 29.737126551724138, -1.502523507692308 ], [ 29.737126551724138, -1.501984553846154 ], [ 29.736857068965517, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5910, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.501984553846154 ], [ 29.737126551724138, -1.502254030769231 ], [ 29.73739603448276, -1.502254030769231 ], [ 29.73739603448276, -1.501984553846154 ], [ 29.737126551724138, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5911, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.501984553846154 ], [ 29.73739603448276, -1.502254030769231 ], [ 29.737665517241378, -1.502254030769231 ], [ 29.737665517241378, -1.501984553846154 ], [ 29.73739603448276, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5912, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.501984553846154 ], [ 29.737665517241378, -1.502254030769231 ], [ 29.737935, -1.502254030769231 ], [ 29.737935, -1.501984553846154 ], [ 29.737665517241378, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5913, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.501984553846154 ], [ 29.737935, -1.502254030769231 ], [ 29.738204482758622, -1.502254030769231 ], [ 29.738204482758622, -1.501984553846154 ], [ 29.737935, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5914, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.501984553846154 ], [ 29.738204482758622, -1.502254030769231 ], [ 29.738743448275862, -1.502254030769231 ], [ 29.738743448275862, -1.501984553846154 ], [ 29.738204482758622, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5915, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.501984553846154 ], [ 29.738743448275862, -1.502254030769231 ], [ 29.739012931034484, -1.502254030769231 ], [ 29.739012931034484, -1.501984553846154 ], [ 29.738743448275862, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5916, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.501984553846154 ], [ 29.739012931034484, -1.502254030769231 ], [ 29.739282413793102, -1.502254030769231 ], [ 29.739282413793102, -1.501984553846154 ], [ 29.739012931034484, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5917, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.501984553846154 ], [ 29.739282413793102, -1.502254030769231 ], [ 29.739551896551724, -1.502254030769231 ], [ 29.739551896551724, -1.501984553846154 ], [ 29.739282413793102, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5918, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.501984553846154 ], [ 29.739551896551724, -1.502254030769231 ], [ 29.739821379310346, -1.502254030769231 ], [ 29.739821379310346, -1.501984553846154 ], [ 29.739551896551724, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5919, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.501984553846154 ], [ 29.739821379310346, -1.502254030769231 ], [ 29.740090862068964, -1.502254030769231 ], [ 29.740090862068964, -1.501984553846154 ], [ 29.739821379310346, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5920, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.501984553846154 ], [ 29.740090862068964, -1.502254030769231 ], [ 29.740360344827586, -1.502254030769231 ], [ 29.740360344827586, -1.501984553846154 ], [ 29.740090862068964, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5921, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.501984553846154 ], [ 29.740360344827586, -1.502254030769231 ], [ 29.740629827586208, -1.502254030769231 ], [ 29.740629827586208, -1.501984553846154 ], [ 29.740360344827586, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5922, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.501984553846154 ], [ 29.740629827586208, -1.502254030769231 ], [ 29.74089931034483, -1.502254030769231 ], [ 29.74089931034483, -1.501984553846154 ], [ 29.740629827586208, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5923, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.501984553846154 ], [ 29.74089931034483, -1.502254030769231 ], [ 29.74143827586207, -1.502254030769231 ], [ 29.74143827586207, -1.501984553846154 ], [ 29.74089931034483, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5924, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.501984553846154 ], [ 29.74143827586207, -1.502254030769231 ], [ 29.74197724137931, -1.502254030769231 ], [ 29.74197724137931, -1.501984553846154 ], [ 29.74143827586207, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5925, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.501984553846154 ], [ 29.74197724137931, -1.502254030769231 ], [ 29.742246724137932, -1.502254030769231 ], [ 29.742246724137932, -1.501984553846154 ], [ 29.74197724137931, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5926, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.501984553846154 ], [ 29.742246724137932, -1.502254030769231 ], [ 29.743324655172415, -1.502254030769231 ], [ 29.743324655172415, -1.501984553846154 ], [ 29.742246724137932, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5927, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.501984553846154 ], [ 29.743324655172415, -1.502254030769231 ], [ 29.743594137931034, -1.502254030769231 ], [ 29.743594137931034, -1.502792984615384 ], [ 29.743863620689655, -1.502792984615384 ], [ 29.743863620689655, -1.501984553846154 ], [ 29.743324655172415, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5928, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750600689655172, -1.501984553846154 ], [ 29.750600689655172, -1.502254030769231 ], [ 29.750870172413794, -1.502254030769231 ], [ 29.750870172413794, -1.501984553846154 ], [ 29.750600689655172, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5929, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.501984553846154 ], [ 29.750870172413794, -1.502254030769231 ], [ 29.751139655172413, -1.502254030769231 ], [ 29.751139655172413, -1.501984553846154 ], [ 29.750870172413794, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5930, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.501984553846154 ], [ 29.751139655172413, -1.502254030769231 ], [ 29.751409137931034, -1.502254030769231 ], [ 29.751409137931034, -1.501984553846154 ], [ 29.751139655172413, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5931, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.501984553846154 ], [ 29.751409137931034, -1.502254030769231 ], [ 29.751678620689656, -1.502254030769231 ], [ 29.751678620689656, -1.501984553846154 ], [ 29.751409137931034, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5932, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.501984553846154 ], [ 29.751678620689656, -1.502254030769231 ], [ 29.751948103448278, -1.502254030769231 ], [ 29.751948103448278, -1.501984553846154 ], [ 29.751678620689656, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5933, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.501984553846154 ], [ 29.751948103448278, -1.502523507692308 ], [ 29.752217586206896, -1.502523507692308 ], [ 29.752217586206896, -1.501984553846154 ], [ 29.751948103448278, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5934, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.501984553846154 ], [ 29.752217586206896, -1.502254030769231 ], [ 29.752487068965518, -1.502254030769231 ], [ 29.752487068965518, -1.501984553846154 ], [ 29.752217586206896, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5935, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.501984553846154 ], [ 29.752487068965518, -1.502254030769231 ], [ 29.75275655172414, -1.502254030769231 ], [ 29.75275655172414, -1.501984553846154 ], [ 29.752487068965518, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5936, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.501984553846154 ], [ 29.75275655172414, -1.502254030769231 ], [ 29.753026034482758, -1.502254030769231 ], [ 29.753026034482758, -1.501984553846154 ], [ 29.75275655172414, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5937, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.501984553846154 ], [ 29.757068275862068, -1.502523507692308 ], [ 29.75733775862069, -1.502523507692308 ], [ 29.75733775862069, -1.501984553846154 ], [ 29.757068275862068, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5938, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.501984553846154 ], [ 29.75733775862069, -1.502523507692308 ], [ 29.757607241379311, -1.502523507692308 ], [ 29.757607241379311, -1.501984553846154 ], [ 29.75733775862069, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5939, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.501984553846154 ], [ 29.758146206896551, -1.502254030769231 ], [ 29.758415689655173, -1.502254030769231 ], [ 29.758415689655173, -1.501984553846154 ], [ 29.758146206896551, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5940, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.501984553846154 ], [ 29.758415689655173, -1.502254030769231 ], [ 29.758685172413795, -1.502254030769231 ], [ 29.758685172413795, -1.501984553846154 ], [ 29.758415689655173, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5941, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.501984553846154 ], [ 29.758685172413795, -1.502523507692308 ], [ 29.758954655172413, -1.502523507692308 ], [ 29.758954655172413, -1.501984553846154 ], [ 29.758685172413795, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5942, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.501984553846154 ], [ 29.759224137931035, -1.502254030769231 ], [ 29.759493620689657, -1.502254030769231 ], [ 29.759493620689657, -1.501984553846154 ], [ 29.759224137931035, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5943, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.501984553846154 ], [ 29.759493620689657, -1.502254030769231 ], [ 29.759763103448275, -1.502254030769231 ], [ 29.759763103448275, -1.501984553846154 ], [ 29.759493620689657, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5944, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.501984553846154 ], [ 29.759763103448275, -1.502254030769231 ], [ 29.760032586206897, -1.502254030769231 ], [ 29.760032586206897, -1.501984553846154 ], [ 29.759763103448275, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5945, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.501984553846154 ], [ 29.760032586206897, -1.502254030769231 ], [ 29.760302068965519, -1.502254030769231 ], [ 29.760302068965519, -1.501984553846154 ], [ 29.760032586206897, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5946, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.501984553846154 ], [ 29.760302068965519, -1.502254030769231 ], [ 29.760571551724137, -1.502254030769231 ], [ 29.760571551724137, -1.501984553846154 ], [ 29.760302068965519, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5947, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.501984553846154 ], [ 29.760571551724137, -1.502254030769231 ], [ 29.760841034482759, -1.502254030769231 ], [ 29.760841034482759, -1.501984553846154 ], [ 29.760571551724137, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5948, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.501984553846154 ], [ 29.760841034482759, -1.502254030769231 ], [ 29.761110517241381, -1.502254030769231 ], [ 29.761110517241381, -1.501984553846154 ], [ 29.760841034482759, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5949, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.501984553846154 ], [ 29.761110517241381, -1.502254030769231 ], [ 29.76138, -1.502254030769231 ], [ 29.76138, -1.501984553846154 ], [ 29.761110517241381, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5950, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.501984553846154 ], [ 29.76138, -1.502254030769231 ], [ 29.761649482758621, -1.502254030769231 ], [ 29.761649482758621, -1.501984553846154 ], [ 29.76138, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5951, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.501984553846154 ], [ 29.761649482758621, -1.502254030769231 ], [ 29.761918965517243, -1.502254030769231 ], [ 29.761918965517243, -1.501984553846154 ], [ 29.761649482758621, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5952, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.501984553846154 ], [ 29.761918965517243, -1.502254030769231 ], [ 29.762188448275861, -1.502254030769231 ], [ 29.762188448275861, -1.501984553846154 ], [ 29.761918965517243, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5953, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.501984553846154 ], [ 29.762188448275861, -1.502254030769231 ], [ 29.762457931034483, -1.502254030769231 ], [ 29.762457931034483, -1.501984553846154 ], [ 29.762188448275861, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5954, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.501984553846154 ], [ 29.762457931034483, -1.502254030769231 ], [ 29.762727413793105, -1.502254030769231 ], [ 29.762727413793105, -1.501984553846154 ], [ 29.762457931034483, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5955, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.502254030769231 ], [ 29.719340689655173, -1.502792984615384 ], [ 29.721227068965518, -1.502792984615384 ], [ 29.721227068965518, -1.502523507692308 ], [ 29.719610172413795, -1.502523507692308 ], [ 29.719610172413795, -1.502254030769231 ], [ 29.719340689655173, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5956, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.502254030769231 ], [ 29.719610172413795, -1.502523507692308 ], [ 29.719879655172413, -1.502523507692308 ], [ 29.719879655172413, -1.502254030769231 ], [ 29.719610172413795, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5957, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.502254030769231 ], [ 29.720957586206897, -1.502523507692308 ], [ 29.721227068965518, -1.502523507692308 ], [ 29.721227068965518, -1.502254030769231 ], [ 29.720957586206897, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5958, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.502254030769231 ], [ 29.721227068965518, -1.502523507692308 ], [ 29.721496551724137, -1.502523507692308 ], [ 29.721496551724137, -1.502254030769231 ], [ 29.721227068965518, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5959, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.502254030769231 ], [ 29.726886206896552, -1.502792984615384 ], [ 29.727155689655174, -1.502792984615384 ], [ 29.727155689655174, -1.502254030769231 ], [ 29.726886206896552, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5960, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.502254030769231 ], [ 29.727694655172414, -1.502523507692308 ], [ 29.727964137931036, -1.502523507692308 ], [ 29.727964137931036, -1.502254030769231 ], [ 29.727694655172414, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5961, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.502254030769231 ], [ 29.727964137931036, -1.502792984615384 ], [ 29.728233620689654, -1.502792984615384 ], [ 29.728233620689654, -1.502254030769231 ], [ 29.727964137931036, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5962, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.502254030769231 ], [ 29.728503103448276, -1.502523507692308 ], [ 29.728772586206897, -1.502523507692308 ], [ 29.728772586206897, -1.502254030769231 ], [ 29.728503103448276, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5963, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.502254030769231 ], [ 29.728772586206897, -1.502523507692308 ], [ 29.729042068965516, -1.502523507692308 ], [ 29.729042068965516, -1.502254030769231 ], [ 29.728772586206897, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5964, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.502254030769231 ], [ 29.729042068965516, -1.502523507692308 ], [ 29.729311551724138, -1.502523507692308 ], [ 29.729311551724138, -1.502254030769231 ], [ 29.729042068965516, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5965, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.502254030769231 ], [ 29.729311551724138, -1.502523507692308 ], [ 29.729850517241381, -1.502523507692308 ], [ 29.729850517241381, -1.502254030769231 ], [ 29.729311551724138, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5966, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.502254030769231 ], [ 29.729850517241381, -1.502523507692308 ], [ 29.73012, -1.502523507692308 ], [ 29.73012, -1.502254030769231 ], [ 29.729850517241381, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5967, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.502254030769231 ], [ 29.73012, -1.502523507692308 ], [ 29.730389482758621, -1.502523507692308 ], [ 29.730389482758621, -1.502254030769231 ], [ 29.73012, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5968, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.502254030769231 ], [ 29.730389482758621, -1.502523507692308 ], [ 29.730658965517243, -1.502523507692308 ], [ 29.730658965517243, -1.502254030769231 ], [ 29.730389482758621, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5969, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.502254030769231 ], [ 29.730658965517243, -1.502523507692308 ], [ 29.730928448275861, -1.502523507692308 ], [ 29.730928448275861, -1.502254030769231 ], [ 29.730658965517243, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5970, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.502254030769231 ], [ 29.730928448275861, -1.502523507692308 ], [ 29.731197931034483, -1.502523507692308 ], [ 29.731197931034483, -1.502254030769231 ], [ 29.730928448275861, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5971, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.502254030769231 ], [ 29.731197931034483, -1.502523507692308 ], [ 29.731736896551723, -1.502523507692308 ], [ 29.731736896551723, -1.502254030769231 ], [ 29.731197931034483, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5972, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.502254030769231 ], [ 29.731736896551723, -1.502523507692308 ], [ 29.732814827586207, -1.502523507692308 ], [ 29.732814827586207, -1.502254030769231 ], [ 29.731736896551723, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5973, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.502254030769231 ], [ 29.732814827586207, -1.502523507692308 ], [ 29.733353793103447, -1.502523507692308 ], [ 29.733353793103447, -1.502254030769231 ], [ 29.732814827586207, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5974, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.502254030769231 ], [ 29.733353793103447, -1.502523507692308 ], [ 29.733623275862069, -1.502523507692308 ], [ 29.733623275862069, -1.502254030769231 ], [ 29.733353793103447, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5975, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.502254030769231 ], [ 29.733623275862069, -1.502523507692308 ], [ 29.733892758620691, -1.502523507692308 ], [ 29.733892758620691, -1.502254030769231 ], [ 29.733623275862069, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5976, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.502254030769231 ], [ 29.733892758620691, -1.502523507692308 ], [ 29.734162241379309, -1.502523507692308 ], [ 29.734162241379309, -1.502254030769231 ], [ 29.733892758620691, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5977, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.502254030769231 ], [ 29.734162241379309, -1.502523507692308 ], [ 29.734431724137931, -1.502523507692308 ], [ 29.734431724137931, -1.502254030769231 ], [ 29.734162241379309, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5978, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.502254030769231 ], [ 29.734431724137931, -1.502523507692308 ], [ 29.734701206896553, -1.502523507692308 ], [ 29.734701206896553, -1.502254030769231 ], [ 29.734431724137931, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5979, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.502254030769231 ], [ 29.734701206896553, -1.502523507692308 ], [ 29.734970689655171, -1.502523507692308 ], [ 29.734970689655171, -1.502254030769231 ], [ 29.734701206896553, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5980, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.502254030769231 ], [ 29.734970689655171, -1.502523507692308 ], [ 29.735240172413793, -1.502523507692308 ], [ 29.735240172413793, -1.502254030769231 ], [ 29.734970689655171, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5981, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.502254030769231 ], [ 29.735240172413793, -1.502792984615384 ], [ 29.735509655172415, -1.502792984615384 ], [ 29.735509655172415, -1.502254030769231 ], [ 29.735240172413793, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5982, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.502254030769231 ], [ 29.735509655172415, -1.502523507692308 ], [ 29.735779137931036, -1.502523507692308 ], [ 29.735779137931036, -1.502254030769231 ], [ 29.735509655172415, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5983, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.502254030769231 ], [ 29.736048620689655, -1.502523507692308 ], [ 29.736318103448276, -1.502523507692308 ], [ 29.736318103448276, -1.502254030769231 ], [ 29.736048620689655, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5984, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.502254030769231 ], [ 29.736318103448276, -1.502523507692308 ], [ 29.736587586206898, -1.502523507692308 ], [ 29.736587586206898, -1.502254030769231 ], [ 29.736318103448276, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5985, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.502254030769231 ], [ 29.736587586206898, -1.502523507692308 ], [ 29.736857068965517, -1.502523507692308 ], [ 29.736857068965517, -1.502254030769231 ], [ 29.736587586206898, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5986, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.502254030769231 ], [ 29.737126551724138, -1.502523507692308 ], [ 29.73739603448276, -1.502523507692308 ], [ 29.73739603448276, -1.502254030769231 ], [ 29.737126551724138, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5987, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.502254030769231 ], [ 29.73739603448276, -1.502523507692308 ], [ 29.737665517241378, -1.502523507692308 ], [ 29.737665517241378, -1.502254030769231 ], [ 29.73739603448276, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5988, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.502254030769231 ], [ 29.737665517241378, -1.502523507692308 ], [ 29.737935, -1.502523507692308 ], [ 29.737935, -1.502254030769231 ], [ 29.737665517241378, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5989, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.502254030769231 ], [ 29.737935, -1.502523507692308 ], [ 29.738204482758622, -1.502523507692308 ], [ 29.738204482758622, -1.502254030769231 ], [ 29.737935, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5990, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.502254030769231 ], [ 29.738204482758622, -1.502523507692308 ], [ 29.738743448275862, -1.502523507692308 ], [ 29.738743448275862, -1.502254030769231 ], [ 29.738204482758622, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5991, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.502254030769231 ], [ 29.738743448275862, -1.502523507692308 ], [ 29.739012931034484, -1.502523507692308 ], [ 29.739012931034484, -1.502254030769231 ], [ 29.738743448275862, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5992, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.502254030769231 ], [ 29.739012931034484, -1.502523507692308 ], [ 29.739282413793102, -1.502523507692308 ], [ 29.739282413793102, -1.502254030769231 ], [ 29.739012931034484, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5993, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.502254030769231 ], [ 29.739282413793102, -1.502523507692308 ], [ 29.739551896551724, -1.502523507692308 ], [ 29.739551896551724, -1.502254030769231 ], [ 29.739282413793102, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5994, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.502254030769231 ], [ 29.739551896551724, -1.502523507692308 ], [ 29.739821379310346, -1.502523507692308 ], [ 29.739821379310346, -1.502254030769231 ], [ 29.739551896551724, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5995, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.502254030769231 ], [ 29.739821379310346, -1.502523507692308 ], [ 29.740090862068964, -1.502523507692308 ], [ 29.740090862068964, -1.502254030769231 ], [ 29.739821379310346, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5996, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.502254030769231 ], [ 29.740090862068964, -1.502523507692308 ], [ 29.740360344827586, -1.502523507692308 ], [ 29.740360344827586, -1.502254030769231 ], [ 29.740090862068964, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5997, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.502254030769231 ], [ 29.740360344827586, -1.502523507692308 ], [ 29.740629827586208, -1.502523507692308 ], [ 29.740629827586208, -1.502254030769231 ], [ 29.740360344827586, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5998, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.502254030769231 ], [ 29.740629827586208, -1.502523507692308 ], [ 29.74089931034483, -1.502523507692308 ], [ 29.74089931034483, -1.502254030769231 ], [ 29.740629827586208, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5999, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.502254030769231 ], [ 29.74089931034483, -1.502523507692308 ], [ 29.741168793103448, -1.502523507692308 ], [ 29.741168793103448, -1.502254030769231 ], [ 29.74089931034483, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6000, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.502254030769231 ], [ 29.741168793103448, -1.502523507692308 ], [ 29.74197724137931, -1.502523507692308 ], [ 29.74197724137931, -1.502254030769231 ], [ 29.741168793103448, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6001, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.502254030769231 ], [ 29.74197724137931, -1.502523507692308 ], [ 29.742516206896553, -1.502523507692308 ], [ 29.742516206896553, -1.502254030769231 ], [ 29.74197724137931, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6002, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.502254030769231 ], [ 29.742516206896553, -1.502523507692308 ], [ 29.743055172413793, -1.502523507692308 ], [ 29.743055172413793, -1.502254030769231 ], [ 29.742516206896553, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6003, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.502254030769231 ], [ 29.743055172413793, -1.502792984615384 ], [ 29.743324655172415, -1.502792984615384 ], [ 29.743324655172415, -1.502254030769231 ], [ 29.743055172413793, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6004, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.502254030769231 ], [ 29.743324655172415, -1.502792984615384 ], [ 29.743594137931034, -1.502792984615384 ], [ 29.743594137931034, -1.502254030769231 ], [ 29.743324655172415, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6005, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750870172413794, -1.502254030769231 ], [ 29.750870172413794, -1.502523507692308 ], [ 29.751139655172413, -1.502523507692308 ], [ 29.751139655172413, -1.502254030769231 ], [ 29.750870172413794, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6006, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.502254030769231 ], [ 29.751139655172413, -1.502523507692308 ], [ 29.751409137931034, -1.502523507692308 ], [ 29.751409137931034, -1.502254030769231 ], [ 29.751139655172413, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6007, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.502254030769231 ], [ 29.757607241379311, -1.502523507692308 ], [ 29.757876724137933, -1.502523507692308 ], [ 29.757876724137933, -1.502254030769231 ], [ 29.757607241379311, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6008, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.502254030769231 ], [ 29.757876724137933, -1.502523507692308 ], [ 29.758146206896551, -1.502523507692308 ], [ 29.758146206896551, -1.502254030769231 ], [ 29.757876724137933, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6009, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.502254030769231 ], [ 29.758146206896551, -1.502523507692308 ], [ 29.758415689655173, -1.502523507692308 ], [ 29.758415689655173, -1.502254030769231 ], [ 29.758146206896551, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6010, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.502254030769231 ], [ 29.758415689655173, -1.502792984615384 ], [ 29.758685172413795, -1.502792984615384 ], [ 29.758685172413795, -1.502254030769231 ], [ 29.758415689655173, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6011, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.502254030769231 ], [ 29.758954655172413, -1.502523507692308 ], [ 29.759224137931035, -1.502523507692308 ], [ 29.759224137931035, -1.502254030769231 ], [ 29.758954655172413, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6012, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.502254030769231 ], [ 29.759224137931035, -1.502523507692308 ], [ 29.759493620689657, -1.502523507692308 ], [ 29.759493620689657, -1.502254030769231 ], [ 29.759224137931035, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6013, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.502254030769231 ], [ 29.759493620689657, -1.502523507692308 ], [ 29.759763103448275, -1.502523507692308 ], [ 29.759763103448275, -1.502254030769231 ], [ 29.759493620689657, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6014, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.502254030769231 ], [ 29.759763103448275, -1.502523507692308 ], [ 29.760032586206897, -1.502523507692308 ], [ 29.760032586206897, -1.502254030769231 ], [ 29.759763103448275, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6015, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.502254030769231 ], [ 29.760032586206897, -1.502523507692308 ], [ 29.760302068965519, -1.502523507692308 ], [ 29.760302068965519, -1.502254030769231 ], [ 29.760032586206897, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6016, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.502254030769231 ], [ 29.760302068965519, -1.502523507692308 ], [ 29.760571551724137, -1.502523507692308 ], [ 29.760571551724137, -1.502254030769231 ], [ 29.760302068965519, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6017, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.502254030769231 ], [ 29.760571551724137, -1.502523507692308 ], [ 29.760841034482759, -1.502523507692308 ], [ 29.760841034482759, -1.502254030769231 ], [ 29.760571551724137, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6018, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.502254030769231 ], [ 29.760841034482759, -1.502523507692308 ], [ 29.761110517241381, -1.502523507692308 ], [ 29.761110517241381, -1.502254030769231 ], [ 29.760841034482759, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6019, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.502254030769231 ], [ 29.761110517241381, -1.502523507692308 ], [ 29.76138, -1.502523507692308 ], [ 29.76138, -1.502254030769231 ], [ 29.761110517241381, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6020, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.502254030769231 ], [ 29.76138, -1.502523507692308 ], [ 29.761649482758621, -1.502523507692308 ], [ 29.761649482758621, -1.502254030769231 ], [ 29.76138, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6021, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.502254030769231 ], [ 29.761649482758621, -1.502523507692308 ], [ 29.761918965517243, -1.502523507692308 ], [ 29.761918965517243, -1.502254030769231 ], [ 29.761649482758621, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6022, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.502254030769231 ], [ 29.761918965517243, -1.502523507692308 ], [ 29.762188448275861, -1.502523507692308 ], [ 29.762188448275861, -1.502254030769231 ], [ 29.761918965517243, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6023, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.502254030769231 ], [ 29.762457931034483, -1.502792984615384 ], [ 29.762727413793105, -1.502792984615384 ], [ 29.762727413793105, -1.502254030769231 ], [ 29.762457931034483, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6024, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.502523507692308 ], [ 29.727155689655174, -1.502792984615384 ], [ 29.727425172413792, -1.502792984615384 ], [ 29.727425172413792, -1.502523507692308 ], [ 29.727155689655174, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6025, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.502523507692308 ], [ 29.727425172413792, -1.502792984615384 ], [ 29.727694655172414, -1.502792984615384 ], [ 29.727694655172414, -1.502523507692308 ], [ 29.727425172413792, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6026, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.502523507692308 ], [ 29.728233620689654, -1.502792984615384 ], [ 29.728503103448276, -1.502792984615384 ], [ 29.728503103448276, -1.502523507692308 ], [ 29.728233620689654, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6027, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.502523507692308 ], [ 29.728503103448276, -1.502792984615384 ], [ 29.728772586206897, -1.502792984615384 ], [ 29.728772586206897, -1.502523507692308 ], [ 29.728503103448276, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6028, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.502523507692308 ], [ 29.728772586206897, -1.502792984615384 ], [ 29.729042068965516, -1.502792984615384 ], [ 29.729042068965516, -1.502523507692308 ], [ 29.728772586206897, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6029, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.502523507692308 ], [ 29.729042068965516, -1.502792984615384 ], [ 29.729311551724138, -1.502792984615384 ], [ 29.729311551724138, -1.502523507692308 ], [ 29.729042068965516, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6030, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.502523507692308 ], [ 29.729311551724138, -1.502792984615384 ], [ 29.729850517241381, -1.502792984615384 ], [ 29.729850517241381, -1.502523507692308 ], [ 29.729311551724138, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6031, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.502523507692308 ], [ 29.729850517241381, -1.502792984615384 ], [ 29.73012, -1.502792984615384 ], [ 29.73012, -1.502523507692308 ], [ 29.729850517241381, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6032, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.502523507692308 ], [ 29.73012, -1.502792984615384 ], [ 29.730389482758621, -1.502792984615384 ], [ 29.730389482758621, -1.502523507692308 ], [ 29.73012, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6033, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.502523507692308 ], [ 29.730389482758621, -1.502792984615384 ], [ 29.730658965517243, -1.502792984615384 ], [ 29.730658965517243, -1.502523507692308 ], [ 29.730389482758621, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6034, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.502523507692308 ], [ 29.730658965517243, -1.502792984615384 ], [ 29.730928448275861, -1.502792984615384 ], [ 29.730928448275861, -1.502523507692308 ], [ 29.730658965517243, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6035, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.502523507692308 ], [ 29.730928448275861, -1.502792984615384 ], [ 29.731197931034483, -1.502792984615384 ], [ 29.731197931034483, -1.502523507692308 ], [ 29.730928448275861, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6036, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.502523507692308 ], [ 29.731197931034483, -1.502792984615384 ], [ 29.731467413793105, -1.502792984615384 ], [ 29.731467413793105, -1.502523507692308 ], [ 29.731197931034483, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6037, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.502523507692308 ], [ 29.731467413793105, -1.502792984615384 ], [ 29.733084310344829, -1.502792984615384 ], [ 29.733084310344829, -1.502523507692308 ], [ 29.731467413793105, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6038, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.502523507692308 ], [ 29.733084310344829, -1.502792984615384 ], [ 29.733353793103447, -1.502792984615384 ], [ 29.733353793103447, -1.502523507692308 ], [ 29.733084310344829, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6039, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.502523507692308 ], [ 29.733353793103447, -1.502792984615384 ], [ 29.733623275862069, -1.502792984615384 ], [ 29.733623275862069, -1.502523507692308 ], [ 29.733353793103447, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6040, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.502523507692308 ], [ 29.733623275862069, -1.502792984615384 ], [ 29.733892758620691, -1.502792984615384 ], [ 29.733892758620691, -1.502523507692308 ], [ 29.733623275862069, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6041, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.502523507692308 ], [ 29.733892758620691, -1.502792984615384 ], [ 29.734162241379309, -1.502792984615384 ], [ 29.734162241379309, -1.502523507692308 ], [ 29.733892758620691, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6042, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.502523507692308 ], [ 29.734162241379309, -1.502792984615384 ], [ 29.734431724137931, -1.502792984615384 ], [ 29.734431724137931, -1.502523507692308 ], [ 29.734162241379309, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6043, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.502523507692308 ], [ 29.734431724137931, -1.502792984615384 ], [ 29.734701206896553, -1.502792984615384 ], [ 29.734701206896553, -1.502523507692308 ], [ 29.734431724137931, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6044, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.502523507692308 ], [ 29.734701206896553, -1.502792984615384 ], [ 29.734970689655171, -1.502792984615384 ], [ 29.734970689655171, -1.502523507692308 ], [ 29.734701206896553, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6045, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.502523507692308 ], [ 29.734970689655171, -1.502792984615384 ], [ 29.735240172413793, -1.502792984615384 ], [ 29.735240172413793, -1.502523507692308 ], [ 29.734970689655171, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6046, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.502523507692308 ], [ 29.735509655172415, -1.502792984615384 ], [ 29.735779137931036, -1.502792984615384 ], [ 29.735779137931036, -1.502523507692308 ], [ 29.735509655172415, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6047, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.502523507692308 ], [ 29.735779137931036, -1.502792984615384 ], [ 29.736048620689655, -1.502792984615384 ], [ 29.736048620689655, -1.502523507692308 ], [ 29.735779137931036, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6048, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.502523507692308 ], [ 29.736048620689655, -1.502792984615384 ], [ 29.736318103448276, -1.502792984615384 ], [ 29.736318103448276, -1.502523507692308 ], [ 29.736048620689655, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6049, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.502523507692308 ], [ 29.736318103448276, -1.502792984615384 ], [ 29.736587586206898, -1.502792984615384 ], [ 29.736587586206898, -1.502523507692308 ], [ 29.736318103448276, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6050, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.502523507692308 ], [ 29.736587586206898, -1.502792984615384 ], [ 29.736857068965517, -1.502792984615384 ], [ 29.736857068965517, -1.502523507692308 ], [ 29.736587586206898, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6051, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.502523507692308 ], [ 29.736857068965517, -1.502792984615384 ], [ 29.737126551724138, -1.502792984615384 ], [ 29.737126551724138, -1.502523507692308 ], [ 29.736857068965517, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6052, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.502523507692308 ], [ 29.737126551724138, -1.502792984615384 ], [ 29.73739603448276, -1.502792984615384 ], [ 29.73739603448276, -1.502523507692308 ], [ 29.737126551724138, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6053, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.502523507692308 ], [ 29.73739603448276, -1.502792984615384 ], [ 29.737665517241378, -1.502792984615384 ], [ 29.737665517241378, -1.502523507692308 ], [ 29.73739603448276, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6054, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.502523507692308 ], [ 29.737665517241378, -1.502792984615384 ], [ 29.737935, -1.502792984615384 ], [ 29.737935, -1.502523507692308 ], [ 29.737665517241378, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6055, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.502523507692308 ], [ 29.737935, -1.502792984615384 ], [ 29.738204482758622, -1.502792984615384 ], [ 29.738204482758622, -1.502523507692308 ], [ 29.737935, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6056, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.502523507692308 ], [ 29.738204482758622, -1.502792984615384 ], [ 29.738743448275862, -1.502792984615384 ], [ 29.738743448275862, -1.502523507692308 ], [ 29.738204482758622, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6057, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.502523507692308 ], [ 29.738743448275862, -1.502792984615384 ], [ 29.739012931034484, -1.502792984615384 ], [ 29.739012931034484, -1.502523507692308 ], [ 29.738743448275862, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6058, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.502523507692308 ], [ 29.739012931034484, -1.502792984615384 ], [ 29.739282413793102, -1.502792984615384 ], [ 29.739282413793102, -1.502523507692308 ], [ 29.739012931034484, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6059, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.502523507692308 ], [ 29.739282413793102, -1.502792984615384 ], [ 29.739551896551724, -1.502792984615384 ], [ 29.739551896551724, -1.502523507692308 ], [ 29.739282413793102, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6060, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.502523507692308 ], [ 29.739551896551724, -1.502792984615384 ], [ 29.739821379310346, -1.502792984615384 ], [ 29.739821379310346, -1.502523507692308 ], [ 29.739551896551724, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6061, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.502523507692308 ], [ 29.739821379310346, -1.502792984615384 ], [ 29.740090862068964, -1.502792984615384 ], [ 29.740090862068964, -1.502523507692308 ], [ 29.739821379310346, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6062, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.502523507692308 ], [ 29.740090862068964, -1.502792984615384 ], [ 29.740360344827586, -1.502792984615384 ], [ 29.740360344827586, -1.502523507692308 ], [ 29.740090862068964, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6063, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.502523507692308 ], [ 29.740360344827586, -1.502792984615384 ], [ 29.740629827586208, -1.502792984615384 ], [ 29.740629827586208, -1.502523507692308 ], [ 29.740360344827586, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6064, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.502523507692308 ], [ 29.740629827586208, -1.502792984615384 ], [ 29.74089931034483, -1.502792984615384 ], [ 29.74089931034483, -1.502523507692308 ], [ 29.740629827586208, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6065, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.502523507692308 ], [ 29.74089931034483, -1.502792984615384 ], [ 29.741168793103448, -1.502792984615384 ], [ 29.741168793103448, -1.502523507692308 ], [ 29.74089931034483, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6066, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.502523507692308 ], [ 29.74197724137931, -1.502792984615384 ], [ 29.742246724137932, -1.502792984615384 ], [ 29.742246724137932, -1.502523507692308 ], [ 29.74197724137931, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6067, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.502523507692308 ], [ 29.742246724137932, -1.502792984615384 ], [ 29.742516206896553, -1.502792984615384 ], [ 29.742516206896553, -1.502523507692308 ], [ 29.742246724137932, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6068, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.502523507692308 ], [ 29.742516206896553, -1.502792984615384 ], [ 29.743055172413793, -1.502792984615384 ], [ 29.743055172413793, -1.502523507692308 ], [ 29.742516206896553, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6069, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.502523507692308 ], [ 29.75733775862069, -1.502792984615384 ], [ 29.757607241379311, -1.502792984615384 ], [ 29.757607241379311, -1.502523507692308 ], [ 29.75733775862069, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6070, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.502523507692308 ], [ 29.757607241379311, -1.502792984615384 ], [ 29.757876724137933, -1.502792984615384 ], [ 29.757876724137933, -1.502523507692308 ], [ 29.757607241379311, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6071, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.502523507692308 ], [ 29.757876724137933, -1.502792984615384 ], [ 29.758146206896551, -1.502792984615384 ], [ 29.758146206896551, -1.502523507692308 ], [ 29.757876724137933, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6072, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.502523507692308 ], [ 29.758146206896551, -1.502792984615384 ], [ 29.758415689655173, -1.502792984615384 ], [ 29.758415689655173, -1.502523507692308 ], [ 29.758146206896551, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6073, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.502523507692308 ], [ 29.758685172413795, -1.502792984615384 ], [ 29.758954655172413, -1.502792984615384 ], [ 29.758954655172413, -1.502523507692308 ], [ 29.758685172413795, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6074, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.502523507692308 ], [ 29.758954655172413, -1.502792984615384 ], [ 29.759224137931035, -1.502792984615384 ], [ 29.759224137931035, -1.502523507692308 ], [ 29.758954655172413, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6075, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.502523507692308 ], [ 29.759224137931035, -1.502792984615384 ], [ 29.759493620689657, -1.502792984615384 ], [ 29.759493620689657, -1.502523507692308 ], [ 29.759224137931035, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6076, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.502523507692308 ], [ 29.759493620689657, -1.502792984615384 ], [ 29.759763103448275, -1.502792984615384 ], [ 29.759763103448275, -1.502523507692308 ], [ 29.759493620689657, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6077, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.502523507692308 ], [ 29.759763103448275, -1.502792984615384 ], [ 29.760032586206897, -1.502792984615384 ], [ 29.760032586206897, -1.502523507692308 ], [ 29.759763103448275, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6078, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.502523507692308 ], [ 29.760032586206897, -1.502792984615384 ], [ 29.760302068965519, -1.502792984615384 ], [ 29.760302068965519, -1.502523507692308 ], [ 29.760032586206897, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6079, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.502523507692308 ], [ 29.760302068965519, -1.502792984615384 ], [ 29.760571551724137, -1.502792984615384 ], [ 29.760571551724137, -1.502523507692308 ], [ 29.760302068965519, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6080, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.502523507692308 ], [ 29.760571551724137, -1.502792984615384 ], [ 29.760841034482759, -1.502792984615384 ], [ 29.760841034482759, -1.502523507692308 ], [ 29.760571551724137, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6081, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.502523507692308 ], [ 29.760841034482759, -1.502792984615384 ], [ 29.761110517241381, -1.502792984615384 ], [ 29.761110517241381, -1.502523507692308 ], [ 29.760841034482759, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6082, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.502523507692308 ], [ 29.761110517241381, -1.502792984615384 ], [ 29.76138, -1.502792984615384 ], [ 29.76138, -1.502523507692308 ], [ 29.761110517241381, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6083, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.502523507692308 ], [ 29.76138, -1.502792984615384 ], [ 29.761649482758621, -1.502792984615384 ], [ 29.761649482758621, -1.502523507692308 ], [ 29.76138, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6084, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.5014456 ], [ 29.724730344827588, -1.504679323076923 ], [ 29.724999827586206, -1.504679323076923 ], [ 29.724999827586206, -1.5014456 ], [ 29.724730344827588, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6085, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.5014456 ], [ 29.72553879310345, -1.504679323076923 ], [ 29.725808275862068, -1.504679323076923 ], [ 29.725808275862068, -1.5014456 ], [ 29.72553879310345, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6086, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.5014456 ], [ 29.72661672413793, -1.504679323076923 ], [ 29.726886206896552, -1.504679323076923 ], [ 29.726886206896552, -1.503062461538462 ], [ 29.727155689655174, -1.503062461538462 ], [ 29.727155689655174, -1.502792984615384 ], [ 29.726886206896552, -1.502792984615384 ], [ 29.726886206896552, -1.5014456 ], [ 29.72661672413793, -1.5014456 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6087, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.501715076923077 ], [ 29.723921896551722, -1.504409846153846 ], [ 29.724191379310344, -1.504409846153846 ], [ 29.724191379310344, -1.501715076923077 ], [ 29.723921896551722, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6088, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.501715076923077 ], [ 29.724191379310344, -1.504409846153846 ], [ 29.724460862068966, -1.504409846153846 ], [ 29.724460862068966, -1.501715076923077 ], [ 29.724191379310344, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6089, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.501715076923077 ], [ 29.724999827586206, -1.504409846153846 ], [ 29.725269310344828, -1.504409846153846 ], [ 29.725269310344828, -1.501715076923077 ], [ 29.724999827586206, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6090, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.501715076923077 ], [ 29.725808275862068, -1.504409846153846 ], [ 29.72607775862069, -1.504409846153846 ], [ 29.72607775862069, -1.501715076923077 ], [ 29.725808275862068, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6091, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.501715076923077 ], [ 29.72607775862069, -1.504409846153846 ], [ 29.726347241379312, -1.504409846153846 ], [ 29.726347241379312, -1.501715076923077 ], [ 29.72607775862069, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6092, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.501715076923077 ], [ 29.743594137931034, -1.501984553846154 ], [ 29.743863620689655, -1.501984553846154 ], [ 29.743863620689655, -1.503062461538462 ], [ 29.744133103448277, -1.503062461538462 ], [ 29.744133103448277, -1.501715076923077 ], [ 29.743594137931034, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6093, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.501984553846154 ], [ 29.722305, -1.504140369230769 ], [ 29.72257448275862, -1.504140369230769 ], [ 29.72257448275862, -1.501984553846154 ], [ 29.722305, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6094, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.501984553846154 ], [ 29.72257448275862, -1.504140369230769 ], [ 29.722843965517242, -1.504140369230769 ], [ 29.722843965517242, -1.501984553846154 ], [ 29.72257448275862, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6095, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.501984553846154 ], [ 29.723113448275861, -1.504140369230769 ], [ 29.723382931034482, -1.504140369230769 ], [ 29.723382931034482, -1.501984553846154 ], [ 29.723113448275861, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6096, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.501984553846154 ], [ 29.723382931034482, -1.504140369230769 ], [ 29.723652413793104, -1.504140369230769 ], [ 29.723652413793104, -1.501984553846154 ], [ 29.723382931034482, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6097, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.501984553846154 ], [ 29.723652413793104, -1.504140369230769 ], [ 29.723921896551722, -1.504140369230769 ], [ 29.723921896551722, -1.501984553846154 ], [ 29.723652413793104, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6098, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.501984553846154 ], [ 29.724460862068966, -1.504140369230769 ], [ 29.724730344827588, -1.504140369230769 ], [ 29.724730344827588, -1.501984553846154 ], [ 29.724460862068966, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6099, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.501984553846154 ], [ 29.725269310344828, -1.504140369230769 ], [ 29.72553879310345, -1.504140369230769 ], [ 29.72553879310345, -1.501984553846154 ], [ 29.725269310344828, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6100, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.501984553846154 ], [ 29.762727413793105, -1.503062461538462 ], [ 29.762996896551723, -1.503062461538462 ], [ 29.762996896551723, -1.501984553846154 ], [ 29.762727413793105, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6101, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.501984553846154 ], [ 29.762996896551723, -1.503062461538462 ], [ 29.763266379310345, -1.503062461538462 ], [ 29.763266379310345, -1.501984553846154 ], [ 29.762996896551723, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6102, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.502254030769231 ], [ 29.72203551724138, -1.503870892307692 ], [ 29.722305, -1.503870892307692 ], [ 29.722305, -1.502254030769231 ], [ 29.72203551724138, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6103, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.502254030769231 ], [ 29.722843965517242, -1.503870892307692 ], [ 29.723113448275861, -1.503870892307692 ], [ 29.723113448275861, -1.502254030769231 ], [ 29.722843965517242, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6104, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.502254030769231 ], [ 29.726347241379312, -1.503870892307692 ], [ 29.72661672413793, -1.503870892307692 ], [ 29.72661672413793, -1.502254030769231 ], [ 29.726347241379312, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6105, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.502254030769231 ], [ 29.762188448275861, -1.503062461538462 ], [ 29.762457931034483, -1.503062461538462 ], [ 29.762457931034483, -1.502254030769231 ], [ 29.762188448275861, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6106, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.502523507692308 ], [ 29.727694655172414, -1.503062461538462 ], [ 29.727964137931036, -1.503062461538462 ], [ 29.727964137931036, -1.502523507692308 ], [ 29.727694655172414, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6107, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.502523507692308 ], [ 29.741168793103448, -1.502792984615384 ], [ 29.741707758620691, -1.502792984615384 ], [ 29.741707758620691, -1.503062461538462 ], [ 29.74197724137931, -1.503062461538462 ], [ 29.74197724137931, -1.502523507692308 ], [ 29.741168793103448, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6108, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.502523507692308 ], [ 29.761649482758621, -1.503062461538462 ], [ 29.761918965517243, -1.503062461538462 ], [ 29.761918965517243, -1.502523507692308 ], [ 29.761649482758621, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6109, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.502523507692308 ], [ 29.761918965517243, -1.503062461538462 ], [ 29.762188448275861, -1.503062461538462 ], [ 29.762188448275861, -1.502523507692308 ], [ 29.761918965517243, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6110, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.502792984615384 ], [ 29.727155689655174, -1.503062461538462 ], [ 29.727425172413792, -1.503062461538462 ], [ 29.727425172413792, -1.502792984615384 ], [ 29.727155689655174, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6111, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.502792984615384 ], [ 29.727425172413792, -1.503331938461538 ], [ 29.727694655172414, -1.503331938461538 ], [ 29.727694655172414, -1.502792984615384 ], [ 29.727425172413792, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6112, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.502792984615384 ], [ 29.727964137931036, -1.503062461538462 ], [ 29.728233620689654, -1.503062461538462 ], [ 29.728233620689654, -1.502792984615384 ], [ 29.727964137931036, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6113, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.502792984615384 ], [ 29.728233620689654, -1.503062461538462 ], [ 29.728503103448276, -1.503062461538462 ], [ 29.728503103448276, -1.502792984615384 ], [ 29.728233620689654, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6114, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.502792984615384 ], [ 29.728503103448276, -1.503062461538462 ], [ 29.728772586206897, -1.503062461538462 ], [ 29.728772586206897, -1.502792984615384 ], [ 29.728503103448276, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6115, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.502792984615384 ], [ 29.728772586206897, -1.503062461538462 ], [ 29.729042068965516, -1.503062461538462 ], [ 29.729042068965516, -1.502792984615384 ], [ 29.728772586206897, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6116, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.502792984615384 ], [ 29.729042068965516, -1.503062461538462 ], [ 29.729311551724138, -1.503062461538462 ], [ 29.729311551724138, -1.502792984615384 ], [ 29.729042068965516, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6117, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.502792984615384 ], [ 29.729311551724138, -1.503062461538462 ], [ 29.729850517241381, -1.503062461538462 ], [ 29.729850517241381, -1.502792984615384 ], [ 29.729311551724138, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6118, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.502792984615384 ], [ 29.729850517241381, -1.503062461538462 ], [ 29.73012, -1.503062461538462 ], [ 29.73012, -1.502792984615384 ], [ 29.729850517241381, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6119, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.502792984615384 ], [ 29.73012, -1.503062461538462 ], [ 29.730389482758621, -1.503062461538462 ], [ 29.730389482758621, -1.502792984615384 ], [ 29.73012, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6120, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.502792984615384 ], [ 29.730389482758621, -1.503062461538462 ], [ 29.730658965517243, -1.503062461538462 ], [ 29.730658965517243, -1.502792984615384 ], [ 29.730389482758621, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6121, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.502792984615384 ], [ 29.730658965517243, -1.503062461538462 ], [ 29.730928448275861, -1.503062461538462 ], [ 29.730928448275861, -1.502792984615384 ], [ 29.730658965517243, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6122, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.502792984615384 ], [ 29.730928448275861, -1.503062461538462 ], [ 29.731197931034483, -1.503062461538462 ], [ 29.731197931034483, -1.502792984615384 ], [ 29.730928448275861, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6123, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.502792984615384 ], [ 29.731197931034483, -1.503062461538462 ], [ 29.731467413793105, -1.503062461538462 ], [ 29.731467413793105, -1.502792984615384 ], [ 29.731197931034483, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6124, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.502792984615384 ], [ 29.731467413793105, -1.503062461538462 ], [ 29.733084310344829, -1.503062461538462 ], [ 29.733084310344829, -1.502792984615384 ], [ 29.731467413793105, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6125, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.502792984615384 ], [ 29.733084310344829, -1.503062461538462 ], [ 29.733353793103447, -1.503062461538462 ], [ 29.733353793103447, -1.502792984615384 ], [ 29.733084310344829, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6126, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.502792984615384 ], [ 29.733623275862069, -1.503331938461538 ], [ 29.733892758620691, -1.503331938461538 ], [ 29.733892758620691, -1.502792984615384 ], [ 29.733623275862069, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6127, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.502792984615384 ], [ 29.733892758620691, -1.503331938461538 ], [ 29.734162241379309, -1.503331938461538 ], [ 29.734162241379309, -1.502792984615384 ], [ 29.733892758620691, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6128, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.502792984615384 ], [ 29.734162241379309, -1.503062461538462 ], [ 29.734431724137931, -1.503062461538462 ], [ 29.734431724137931, -1.502792984615384 ], [ 29.734162241379309, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6129, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.502792984615384 ], [ 29.734431724137931, -1.503062461538462 ], [ 29.734970689655171, -1.503062461538462 ], [ 29.734970689655171, -1.502792984615384 ], [ 29.734431724137931, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6130, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.502792984615384 ], [ 29.734970689655171, -1.503062461538462 ], [ 29.735240172413793, -1.503062461538462 ], [ 29.735240172413793, -1.502792984615384 ], [ 29.734970689655171, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6131, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.502792984615384 ], [ 29.735240172413793, -1.503062461538462 ], [ 29.735509655172415, -1.503062461538462 ], [ 29.735509655172415, -1.502792984615384 ], [ 29.735240172413793, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6132, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.502792984615384 ], [ 29.735509655172415, -1.503062461538462 ], [ 29.735779137931036, -1.503062461538462 ], [ 29.735779137931036, -1.502792984615384 ], [ 29.735509655172415, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6133, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.502792984615384 ], [ 29.735779137931036, -1.503062461538462 ], [ 29.736048620689655, -1.503062461538462 ], [ 29.736048620689655, -1.502792984615384 ], [ 29.735779137931036, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6134, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.502792984615384 ], [ 29.736048620689655, -1.503062461538462 ], [ 29.736318103448276, -1.503062461538462 ], [ 29.736318103448276, -1.502792984615384 ], [ 29.736048620689655, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6135, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.502792984615384 ], [ 29.736318103448276, -1.503062461538462 ], [ 29.736587586206898, -1.503062461538462 ], [ 29.736587586206898, -1.502792984615384 ], [ 29.736318103448276, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6136, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.502792984615384 ], [ 29.736587586206898, -1.503062461538462 ], [ 29.736857068965517, -1.503062461538462 ], [ 29.736857068965517, -1.502792984615384 ], [ 29.736587586206898, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6137, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.502792984615384 ], [ 29.736857068965517, -1.503062461538462 ], [ 29.737126551724138, -1.503062461538462 ], [ 29.737126551724138, -1.502792984615384 ], [ 29.736857068965517, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6138, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.502792984615384 ], [ 29.737126551724138, -1.503062461538462 ], [ 29.73739603448276, -1.503062461538462 ], [ 29.73739603448276, -1.502792984615384 ], [ 29.737126551724138, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6139, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.502792984615384 ], [ 29.73739603448276, -1.503062461538462 ], [ 29.737665517241378, -1.503062461538462 ], [ 29.737665517241378, -1.502792984615384 ], [ 29.73739603448276, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6140, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.502792984615384 ], [ 29.737935, -1.503062461538462 ], [ 29.738204482758622, -1.503062461538462 ], [ 29.738204482758622, -1.502792984615384 ], [ 29.737935, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6141, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.502792984615384 ], [ 29.738204482758622, -1.503062461538462 ], [ 29.738743448275862, -1.503062461538462 ], [ 29.738743448275862, -1.502792984615384 ], [ 29.738204482758622, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6142, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.502792984615384 ], [ 29.738743448275862, -1.503062461538462 ], [ 29.739012931034484, -1.503062461538462 ], [ 29.739012931034484, -1.502792984615384 ], [ 29.738743448275862, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6143, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.502792984615384 ], [ 29.739012931034484, -1.503062461538462 ], [ 29.739282413793102, -1.503062461538462 ], [ 29.739282413793102, -1.502792984615384 ], [ 29.739012931034484, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6144, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.502792984615384 ], [ 29.739282413793102, -1.503062461538462 ], [ 29.739551896551724, -1.503062461538462 ], [ 29.739551896551724, -1.502792984615384 ], [ 29.739282413793102, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6145, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.502792984615384 ], [ 29.739551896551724, -1.503062461538462 ], [ 29.739821379310346, -1.503062461538462 ], [ 29.739821379310346, -1.502792984615384 ], [ 29.739551896551724, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6146, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.502792984615384 ], [ 29.739821379310346, -1.503062461538462 ], [ 29.740090862068964, -1.503062461538462 ], [ 29.740090862068964, -1.502792984615384 ], [ 29.739821379310346, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6147, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.502792984615384 ], [ 29.740090862068964, -1.503062461538462 ], [ 29.740360344827586, -1.503062461538462 ], [ 29.740360344827586, -1.502792984615384 ], [ 29.740090862068964, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6148, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.502792984615384 ], [ 29.740360344827586, -1.503062461538462 ], [ 29.740629827586208, -1.503062461538462 ], [ 29.740629827586208, -1.502792984615384 ], [ 29.740360344827586, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6149, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.502792984615384 ], [ 29.740629827586208, -1.503062461538462 ], [ 29.74089931034483, -1.503062461538462 ], [ 29.74089931034483, -1.502792984615384 ], [ 29.740629827586208, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6150, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.502792984615384 ], [ 29.74089931034483, -1.503062461538462 ], [ 29.741168793103448, -1.503062461538462 ], [ 29.741168793103448, -1.502792984615384 ], [ 29.74089931034483, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6151, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.502792984615384 ], [ 29.741168793103448, -1.503062461538462 ], [ 29.741707758620691, -1.503062461538462 ], [ 29.741707758620691, -1.502792984615384 ], [ 29.741168793103448, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6152, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.502792984615384 ], [ 29.74197724137931, -1.503062461538462 ], [ 29.742246724137932, -1.503062461538462 ], [ 29.742246724137932, -1.502792984615384 ], [ 29.74197724137931, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6153, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.502792984615384 ], [ 29.742246724137932, -1.503062461538462 ], [ 29.742516206896553, -1.503062461538462 ], [ 29.742516206896553, -1.502792984615384 ], [ 29.742246724137932, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6154, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.502792984615384 ], [ 29.742516206896553, -1.503062461538462 ], [ 29.742785689655172, -1.503062461538462 ], [ 29.742785689655172, -1.502792984615384 ], [ 29.742516206896553, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6155, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.502792984615384 ], [ 29.742785689655172, -1.503062461538462 ], [ 29.743324655172415, -1.503062461538462 ], [ 29.743324655172415, -1.502792984615384 ], [ 29.742785689655172, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6156, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.502792984615384 ], [ 29.743324655172415, -1.503062461538462 ], [ 29.743594137931034, -1.503062461538462 ], [ 29.743594137931034, -1.502792984615384 ], [ 29.743324655172415, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6157, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.502792984615384 ], [ 29.743594137931034, -1.503062461538462 ], [ 29.743863620689655, -1.503062461538462 ], [ 29.743863620689655, -1.502792984615384 ], [ 29.743594137931034, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6158, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.502792984615384 ], [ 29.757068275862068, -1.503062461538462 ], [ 29.75733775862069, -1.503062461538462 ], [ 29.75733775862069, -1.502792984615384 ], [ 29.757068275862068, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6159, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.502792984615384 ], [ 29.75733775862069, -1.503062461538462 ], [ 29.757607241379311, -1.503062461538462 ], [ 29.757607241379311, -1.502792984615384 ], [ 29.75733775862069, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6160, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.502792984615384 ], [ 29.757607241379311, -1.503062461538462 ], [ 29.757876724137933, -1.503062461538462 ], [ 29.757876724137933, -1.502792984615384 ], [ 29.757607241379311, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6161, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.502792984615384 ], [ 29.757876724137933, -1.503062461538462 ], [ 29.758146206896551, -1.503062461538462 ], [ 29.758146206896551, -1.502792984615384 ], [ 29.757876724137933, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6162, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.502792984615384 ], [ 29.758146206896551, -1.503062461538462 ], [ 29.758415689655173, -1.503062461538462 ], [ 29.758415689655173, -1.502792984615384 ], [ 29.758146206896551, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6163, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.502792984615384 ], [ 29.758415689655173, -1.503062461538462 ], [ 29.758685172413795, -1.503062461538462 ], [ 29.758685172413795, -1.502792984615384 ], [ 29.758415689655173, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6164, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.502792984615384 ], [ 29.758685172413795, -1.503062461538462 ], [ 29.758954655172413, -1.503062461538462 ], [ 29.758954655172413, -1.502792984615384 ], [ 29.758685172413795, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6165, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.502792984615384 ], [ 29.758954655172413, -1.503062461538462 ], [ 29.759224137931035, -1.503062461538462 ], [ 29.759224137931035, -1.502792984615384 ], [ 29.758954655172413, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6166, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.502792984615384 ], [ 29.759224137931035, -1.503062461538462 ], [ 29.759493620689657, -1.503062461538462 ], [ 29.759493620689657, -1.502792984615384 ], [ 29.759224137931035, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6167, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.502792984615384 ], [ 29.759493620689657, -1.503062461538462 ], [ 29.759763103448275, -1.503062461538462 ], [ 29.759763103448275, -1.502792984615384 ], [ 29.759493620689657, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6168, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.502792984615384 ], [ 29.759763103448275, -1.503062461538462 ], [ 29.760032586206897, -1.503062461538462 ], [ 29.760032586206897, -1.502792984615384 ], [ 29.759763103448275, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6169, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.502792984615384 ], [ 29.760032586206897, -1.503062461538462 ], [ 29.760302068965519, -1.503062461538462 ], [ 29.760302068965519, -1.502792984615384 ], [ 29.760032586206897, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6170, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.502792984615384 ], [ 29.760302068965519, -1.503062461538462 ], [ 29.760571551724137, -1.503062461538462 ], [ 29.760571551724137, -1.502792984615384 ], [ 29.760302068965519, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6171, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.502792984615384 ], [ 29.760571551724137, -1.503062461538462 ], [ 29.760841034482759, -1.503062461538462 ], [ 29.760841034482759, -1.502792984615384 ], [ 29.760571551724137, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6172, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.502792984615384 ], [ 29.760841034482759, -1.503062461538462 ], [ 29.761110517241381, -1.503062461538462 ], [ 29.761110517241381, -1.502792984615384 ], [ 29.760841034482759, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6173, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.502792984615384 ], [ 29.761110517241381, -1.503331938461538 ], [ 29.76138, -1.503331938461538 ], [ 29.76138, -1.502792984615384 ], [ 29.761110517241381, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6174, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.502792984615384 ], [ 29.76138, -1.503331938461538 ], [ 29.761649482758621, -1.503331938461538 ], [ 29.761649482758621, -1.502792984615384 ], [ 29.76138, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6175, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.502792984615384 ], [ 29.762457931034483, -1.503331938461538 ], [ 29.762727413793105, -1.503331938461538 ], [ 29.762727413793105, -1.502792984615384 ], [ 29.762457931034483, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6176, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.502792984615384 ], [ 29.763266379310345, -1.503062461538462 ], [ 29.763535862068967, -1.503062461538462 ], [ 29.763535862068967, -1.502792984615384 ], [ 29.763266379310345, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6177, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.502792984615384 ], [ 29.763535862068967, -1.503870892307692 ], [ 29.763805344827588, -1.503870892307692 ], [ 29.763805344827588, -1.502792984615384 ], [ 29.763535862068967, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6178, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.503062461538462 ], [ 29.726886206896552, -1.503331938461538 ], [ 29.727155689655174, -1.503331938461538 ], [ 29.727155689655174, -1.503062461538462 ], [ 29.726886206896552, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6179, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.503062461538462 ], [ 29.727155689655174, -1.503331938461538 ], [ 29.727425172413792, -1.503331938461538 ], [ 29.727425172413792, -1.503062461538462 ], [ 29.727155689655174, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6180, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.503062461538462 ], [ 29.727694655172414, -1.503331938461538 ], [ 29.727964137931036, -1.503331938461538 ], [ 29.727964137931036, -1.503062461538462 ], [ 29.727694655172414, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6181, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.503062461538462 ], [ 29.727964137931036, -1.503331938461538 ], [ 29.728233620689654, -1.503331938461538 ], [ 29.728233620689654, -1.503062461538462 ], [ 29.727964137931036, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6182, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.503062461538462 ], [ 29.728233620689654, -1.503331938461538 ], [ 29.728503103448276, -1.503331938461538 ], [ 29.728503103448276, -1.503062461538462 ], [ 29.728233620689654, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6183, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.503062461538462 ], [ 29.728503103448276, -1.503331938461538 ], [ 29.728772586206897, -1.503331938461538 ], [ 29.728772586206897, -1.503062461538462 ], [ 29.728503103448276, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6184, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.503062461538462 ], [ 29.728772586206897, -1.503331938461538 ], [ 29.729042068965516, -1.503331938461538 ], [ 29.729042068965516, -1.503062461538462 ], [ 29.728772586206897, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6185, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.503062461538462 ], [ 29.729042068965516, -1.503331938461538 ], [ 29.729311551724138, -1.503331938461538 ], [ 29.729311551724138, -1.503062461538462 ], [ 29.729042068965516, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6186, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.503062461538462 ], [ 29.729311551724138, -1.503331938461538 ], [ 29.729850517241381, -1.503331938461538 ], [ 29.729850517241381, -1.503062461538462 ], [ 29.729311551724138, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6187, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.503062461538462 ], [ 29.729850517241381, -1.503331938461538 ], [ 29.73012, -1.503331938461538 ], [ 29.73012, -1.503062461538462 ], [ 29.729850517241381, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6188, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.503062461538462 ], [ 29.73012, -1.503331938461538 ], [ 29.730389482758621, -1.503331938461538 ], [ 29.730389482758621, -1.503062461538462 ], [ 29.73012, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6189, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.503062461538462 ], [ 29.730389482758621, -1.503331938461538 ], [ 29.730658965517243, -1.503331938461538 ], [ 29.730658965517243, -1.503062461538462 ], [ 29.730389482758621, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6190, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.503062461538462 ], [ 29.730658965517243, -1.503331938461538 ], [ 29.730928448275861, -1.503331938461538 ], [ 29.730928448275861, -1.503062461538462 ], [ 29.730658965517243, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6191, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.503062461538462 ], [ 29.730928448275861, -1.503331938461538 ], [ 29.731197931034483, -1.503331938461538 ], [ 29.731197931034483, -1.503062461538462 ], [ 29.730928448275861, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6192, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.503062461538462 ], [ 29.731197931034483, -1.503331938461538 ], [ 29.731467413793105, -1.503331938461538 ], [ 29.731467413793105, -1.503062461538462 ], [ 29.731197931034483, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6193, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.502792984615384 ], [ 29.733623275862069, -1.502792984615384 ], [ 29.733623275862069, -1.503331938461538 ], [ 29.732006379310345, -1.503331938461538 ], [ 29.732006379310345, -1.503601415384615 ], [ 29.731736896551723, -1.503601415384615 ], [ 29.731736896551723, -1.503331938461538 ], [ 29.731467413793105, -1.503331938461538 ], [ 29.731467413793105, -1.503062461538462 ], [ 29.733353793103447, -1.503062461538462 ], [ 29.733353793103447, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6194, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.503062461538462 ], [ 29.734162241379309, -1.503331938461538 ], [ 29.734970689655171, -1.503331938461538 ], [ 29.734970689655171, -1.503062461538462 ], [ 29.734162241379309, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6195, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.503062461538462 ], [ 29.734970689655171, -1.503331938461538 ], [ 29.735240172413793, -1.503331938461538 ], [ 29.735240172413793, -1.503062461538462 ], [ 29.734970689655171, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6196, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.503062461538462 ], [ 29.735240172413793, -1.503331938461538 ], [ 29.735509655172415, -1.503331938461538 ], [ 29.735509655172415, -1.503062461538462 ], [ 29.735240172413793, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6197, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.503062461538462 ], [ 29.735509655172415, -1.503331938461538 ], [ 29.735779137931036, -1.503331938461538 ], [ 29.735779137931036, -1.503062461538462 ], [ 29.735509655172415, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6198, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.503062461538462 ], [ 29.735779137931036, -1.503331938461538 ], [ 29.736048620689655, -1.503331938461538 ], [ 29.736048620689655, -1.503062461538462 ], [ 29.735779137931036, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6199, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.503062461538462 ], [ 29.736048620689655, -1.503331938461538 ], [ 29.736318103448276, -1.503331938461538 ], [ 29.736318103448276, -1.503062461538462 ], [ 29.736048620689655, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6200, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.503062461538462 ], [ 29.736318103448276, -1.503331938461538 ], [ 29.736587586206898, -1.503331938461538 ], [ 29.736587586206898, -1.503062461538462 ], [ 29.736318103448276, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6201, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.503062461538462 ], [ 29.736587586206898, -1.503331938461538 ], [ 29.736857068965517, -1.503331938461538 ], [ 29.736857068965517, -1.503062461538462 ], [ 29.736587586206898, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6202, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.503062461538462 ], [ 29.736857068965517, -1.503331938461538 ], [ 29.737126551724138, -1.503331938461538 ], [ 29.737126551724138, -1.503062461538462 ], [ 29.736857068965517, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6203, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.503062461538462 ], [ 29.737126551724138, -1.503331938461538 ], [ 29.73739603448276, -1.503331938461538 ], [ 29.73739603448276, -1.503062461538462 ], [ 29.737126551724138, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6204, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.502792984615384 ], [ 29.737935, -1.502792984615384 ], [ 29.737935, -1.503062461538462 ], [ 29.738204482758622, -1.503062461538462 ], [ 29.738204482758622, -1.503331938461538 ], [ 29.73739603448276, -1.503331938461538 ], [ 29.73739603448276, -1.503062461538462 ], [ 29.737665517241378, -1.503062461538462 ], [ 29.737665517241378, -1.502792984615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6205, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.503062461538462 ], [ 29.738204482758622, -1.503601415384615 ], [ 29.739012931034484, -1.503601415384615 ], [ 29.739012931034484, -1.503331938461538 ], [ 29.738743448275862, -1.503331938461538 ], [ 29.738743448275862, -1.503062461538462 ], [ 29.738204482758622, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6206, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.503062461538462 ], [ 29.738743448275862, -1.503331938461538 ], [ 29.739012931034484, -1.503331938461538 ], [ 29.739012931034484, -1.503062461538462 ], [ 29.738743448275862, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6207, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.503062461538462 ], [ 29.739012931034484, -1.503331938461538 ], [ 29.739282413793102, -1.503331938461538 ], [ 29.739282413793102, -1.503062461538462 ], [ 29.739012931034484, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6208, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.503062461538462 ], [ 29.739282413793102, -1.503331938461538 ], [ 29.739551896551724, -1.503331938461538 ], [ 29.739551896551724, -1.503062461538462 ], [ 29.739282413793102, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6209, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.503062461538462 ], [ 29.739551896551724, -1.503331938461538 ], [ 29.739821379310346, -1.503331938461538 ], [ 29.739821379310346, -1.503062461538462 ], [ 29.739551896551724, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6210, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.503062461538462 ], [ 29.739821379310346, -1.503331938461538 ], [ 29.740090862068964, -1.503331938461538 ], [ 29.740090862068964, -1.503062461538462 ], [ 29.739821379310346, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6211, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.503062461538462 ], [ 29.740090862068964, -1.503331938461538 ], [ 29.740360344827586, -1.503331938461538 ], [ 29.740360344827586, -1.503062461538462 ], [ 29.740090862068964, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6212, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.503062461538462 ], [ 29.740360344827586, -1.503331938461538 ], [ 29.740629827586208, -1.503331938461538 ], [ 29.740629827586208, -1.503062461538462 ], [ 29.740360344827586, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6213, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.503062461538462 ], [ 29.740629827586208, -1.503331938461538 ], [ 29.74089931034483, -1.503331938461538 ], [ 29.74089931034483, -1.503062461538462 ], [ 29.740629827586208, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6214, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.503062461538462 ], [ 29.741168793103448, -1.503331938461538 ], [ 29.74143827586207, -1.503331938461538 ], [ 29.74143827586207, -1.503062461538462 ], [ 29.741168793103448, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6215, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.503062461538462 ], [ 29.74143827586207, -1.503331938461538 ], [ 29.741707758620691, -1.503331938461538 ], [ 29.741707758620691, -1.503062461538462 ], [ 29.74143827586207, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6216, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.503062461538462 ], [ 29.741707758620691, -1.503331938461538 ], [ 29.74197724137931, -1.503331938461538 ], [ 29.74197724137931, -1.503062461538462 ], [ 29.741707758620691, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6217, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.503062461538462 ], [ 29.74197724137931, -1.503601415384615 ], [ 29.742246724137932, -1.503601415384615 ], [ 29.742246724137932, -1.503062461538462 ], [ 29.74197724137931, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6218, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.503062461538462 ], [ 29.742246724137932, -1.503331938461538 ], [ 29.742516206896553, -1.503331938461538 ], [ 29.742516206896553, -1.503062461538462 ], [ 29.742246724137932, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6219, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.503062461538462 ], [ 29.742516206896553, -1.503331938461538 ], [ 29.742785689655172, -1.503331938461538 ], [ 29.742785689655172, -1.503062461538462 ], [ 29.742516206896553, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6220, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.503062461538462 ], [ 29.742785689655172, -1.503331938461538 ], [ 29.743324655172415, -1.503331938461538 ], [ 29.743324655172415, -1.503062461538462 ], [ 29.742785689655172, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6221, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.503062461538462 ], [ 29.743324655172415, -1.503331938461538 ], [ 29.743594137931034, -1.503331938461538 ], [ 29.743594137931034, -1.503062461538462 ], [ 29.743324655172415, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6222, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.503062461538462 ], [ 29.743594137931034, -1.503331938461538 ], [ 29.743863620689655, -1.503331938461538 ], [ 29.743863620689655, -1.503062461538462 ], [ 29.743594137931034, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6223, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.503062461538462 ], [ 29.75733775862069, -1.503331938461538 ], [ 29.757607241379311, -1.503331938461538 ], [ 29.757607241379311, -1.503062461538462 ], [ 29.75733775862069, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6224, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.503062461538462 ], [ 29.757607241379311, -1.503331938461538 ], [ 29.757876724137933, -1.503331938461538 ], [ 29.757876724137933, -1.503062461538462 ], [ 29.757607241379311, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6225, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.503062461538462 ], [ 29.757876724137933, -1.503331938461538 ], [ 29.758146206896551, -1.503331938461538 ], [ 29.758146206896551, -1.503062461538462 ], [ 29.757876724137933, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6226, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.503062461538462 ], [ 29.758146206896551, -1.503331938461538 ], [ 29.758415689655173, -1.503331938461538 ], [ 29.758415689655173, -1.503062461538462 ], [ 29.758146206896551, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6227, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.503062461538462 ], [ 29.758415689655173, -1.503331938461538 ], [ 29.758685172413795, -1.503331938461538 ], [ 29.758685172413795, -1.503062461538462 ], [ 29.758415689655173, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6228, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.503062461538462 ], [ 29.758685172413795, -1.503331938461538 ], [ 29.758954655172413, -1.503331938461538 ], [ 29.758954655172413, -1.503062461538462 ], [ 29.758685172413795, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6229, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.503062461538462 ], [ 29.758954655172413, -1.503331938461538 ], [ 29.759224137931035, -1.503331938461538 ], [ 29.759224137931035, -1.503062461538462 ], [ 29.758954655172413, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6230, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.503062461538462 ], [ 29.759224137931035, -1.503331938461538 ], [ 29.759493620689657, -1.503331938461538 ], [ 29.759493620689657, -1.503062461538462 ], [ 29.759224137931035, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6231, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.503062461538462 ], [ 29.759493620689657, -1.503331938461538 ], [ 29.759763103448275, -1.503331938461538 ], [ 29.759763103448275, -1.503062461538462 ], [ 29.759493620689657, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6232, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.503062461538462 ], [ 29.759763103448275, -1.503331938461538 ], [ 29.760032586206897, -1.503331938461538 ], [ 29.760032586206897, -1.503062461538462 ], [ 29.759763103448275, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6233, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.503062461538462 ], [ 29.760032586206897, -1.503331938461538 ], [ 29.760302068965519, -1.503331938461538 ], [ 29.760302068965519, -1.503062461538462 ], [ 29.760032586206897, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6234, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.503062461538462 ], [ 29.760302068965519, -1.503331938461538 ], [ 29.760571551724137, -1.503331938461538 ], [ 29.760571551724137, -1.503062461538462 ], [ 29.760302068965519, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6235, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.503062461538462 ], [ 29.760571551724137, -1.503601415384615 ], [ 29.760841034482759, -1.503601415384615 ], [ 29.760841034482759, -1.503062461538462 ], [ 29.760571551724137, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6236, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.503062461538462 ], [ 29.760841034482759, -1.503601415384615 ], [ 29.761110517241381, -1.503601415384615 ], [ 29.761110517241381, -1.503062461538462 ], [ 29.760841034482759, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6237, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.503062461538462 ], [ 29.761649482758621, -1.503331938461538 ], [ 29.761918965517243, -1.503331938461538 ], [ 29.761918965517243, -1.503062461538462 ], [ 29.761649482758621, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6238, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.503062461538462 ], [ 29.761918965517243, -1.503331938461538 ], [ 29.762188448275861, -1.503331938461538 ], [ 29.762188448275861, -1.503062461538462 ], [ 29.761918965517243, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6239, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.503062461538462 ], [ 29.762188448275861, -1.503331938461538 ], [ 29.762457931034483, -1.503331938461538 ], [ 29.762457931034483, -1.503062461538462 ], [ 29.762188448275861, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6240, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.503062461538462 ], [ 29.762727413793105, -1.503331938461538 ], [ 29.762996896551723, -1.503331938461538 ], [ 29.762996896551723, -1.503062461538462 ], [ 29.762727413793105, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6241, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.503062461538462 ], [ 29.762996896551723, -1.504140369230769 ], [ 29.763266379310345, -1.504140369230769 ], [ 29.763266379310345, -1.503062461538462 ], [ 29.762996896551723, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6242, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.503062461538462 ], [ 29.763266379310345, -1.504140369230769 ], [ 29.763535862068967, -1.504140369230769 ], [ 29.763535862068967, -1.503062461538462 ], [ 29.763266379310345, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6243, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.503331938461538 ], [ 29.726886206896552, -1.504140369230769 ], [ 29.727155689655174, -1.504140369230769 ], [ 29.727155689655174, -1.503331938461538 ], [ 29.726886206896552, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6244, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.503331938461538 ], [ 29.727155689655174, -1.503601415384615 ], [ 29.727425172413792, -1.503601415384615 ], [ 29.727425172413792, -1.503331938461538 ], [ 29.727155689655174, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6245, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.503331938461538 ], [ 29.727425172413792, -1.503601415384615 ], [ 29.727694655172414, -1.503601415384615 ], [ 29.727694655172414, -1.503331938461538 ], [ 29.727425172413792, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6246, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.503331938461538 ], [ 29.727694655172414, -1.503601415384615 ], [ 29.727964137931036, -1.503601415384615 ], [ 29.727964137931036, -1.503331938461538 ], [ 29.727694655172414, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6247, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.503331938461538 ], [ 29.727964137931036, -1.503601415384615 ], [ 29.728233620689654, -1.503601415384615 ], [ 29.728233620689654, -1.503331938461538 ], [ 29.727964137931036, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6248, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.503331938461538 ], [ 29.728233620689654, -1.503601415384615 ], [ 29.728503103448276, -1.503601415384615 ], [ 29.728503103448276, -1.503331938461538 ], [ 29.728233620689654, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6249, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.503331938461538 ], [ 29.728503103448276, -1.503870892307692 ], [ 29.728772586206897, -1.503870892307692 ], [ 29.728772586206897, -1.503331938461538 ], [ 29.728503103448276, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6250, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.503331938461538 ], [ 29.728772586206897, -1.503870892307692 ], [ 29.729042068965516, -1.503870892307692 ], [ 29.729042068965516, -1.503331938461538 ], [ 29.728772586206897, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6251, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.503331938461538 ], [ 29.729042068965516, -1.503870892307692 ], [ 29.729311551724138, -1.503870892307692 ], [ 29.729311551724138, -1.503331938461538 ], [ 29.729042068965516, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6252, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.503331938461538 ], [ 29.729311551724138, -1.503870892307692 ], [ 29.729850517241381, -1.503870892307692 ], [ 29.729850517241381, -1.503331938461538 ], [ 29.729311551724138, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6253, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.503331938461538 ], [ 29.729850517241381, -1.503870892307692 ], [ 29.73012, -1.503870892307692 ], [ 29.73012, -1.503331938461538 ], [ 29.729850517241381, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6254, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.503331938461538 ], [ 29.73012, -1.503870892307692 ], [ 29.730389482758621, -1.503870892307692 ], [ 29.730389482758621, -1.503331938461538 ], [ 29.73012, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6255, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.503331938461538 ], [ 29.730389482758621, -1.503601415384615 ], [ 29.730658965517243, -1.503601415384615 ], [ 29.730658965517243, -1.503331938461538 ], [ 29.730389482758621, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6256, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.503331938461538 ], [ 29.730658965517243, -1.503601415384615 ], [ 29.730928448275861, -1.503601415384615 ], [ 29.730928448275861, -1.503331938461538 ], [ 29.730658965517243, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6257, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.503331938461538 ], [ 29.730928448275861, -1.503601415384615 ], [ 29.731197931034483, -1.503601415384615 ], [ 29.731197931034483, -1.503331938461538 ], [ 29.730928448275861, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6258, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.503331938461538 ], [ 29.731197931034483, -1.503601415384615 ], [ 29.731467413793105, -1.503601415384615 ], [ 29.731467413793105, -1.503331938461538 ], [ 29.731197931034483, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6259, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.503331938461538 ], [ 29.731467413793105, -1.503601415384615 ], [ 29.731736896551723, -1.503601415384615 ], [ 29.731736896551723, -1.503331938461538 ], [ 29.731467413793105, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6260, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.503331938461538 ], [ 29.732006379310345, -1.503601415384615 ], [ 29.732275862068967, -1.503601415384615 ], [ 29.732275862068967, -1.503331938461538 ], [ 29.732006379310345, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6261, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.503331938461538 ], [ 29.732275862068967, -1.503601415384615 ], [ 29.733623275862069, -1.503601415384615 ], [ 29.733623275862069, -1.503331938461538 ], [ 29.732275862068967, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6262, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.503331938461538 ], [ 29.733623275862069, -1.503601415384615 ], [ 29.733892758620691, -1.503601415384615 ], [ 29.733892758620691, -1.503331938461538 ], [ 29.733623275862069, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6263, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.503331938461538 ], [ 29.733892758620691, -1.503601415384615 ], [ 29.734701206896553, -1.503601415384615 ], [ 29.734701206896553, -1.503331938461538 ], [ 29.733892758620691, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6264, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.503331938461538 ], [ 29.734701206896553, -1.503601415384615 ], [ 29.734970689655171, -1.503601415384615 ], [ 29.734970689655171, -1.503331938461538 ], [ 29.734701206896553, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6265, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.503331938461538 ], [ 29.734970689655171, -1.503601415384615 ], [ 29.735240172413793, -1.503601415384615 ], [ 29.735240172413793, -1.503331938461538 ], [ 29.734970689655171, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6266, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.503331938461538 ], [ 29.735240172413793, -1.503601415384615 ], [ 29.735509655172415, -1.503601415384615 ], [ 29.735509655172415, -1.503331938461538 ], [ 29.735240172413793, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6267, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.503331938461538 ], [ 29.735509655172415, -1.503601415384615 ], [ 29.735779137931036, -1.503601415384615 ], [ 29.735779137931036, -1.503331938461538 ], [ 29.735509655172415, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6268, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.503331938461538 ], [ 29.735779137931036, -1.503601415384615 ], [ 29.736048620689655, -1.503601415384615 ], [ 29.736048620689655, -1.503331938461538 ], [ 29.735779137931036, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6269, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.503331938461538 ], [ 29.736048620689655, -1.503601415384615 ], [ 29.736318103448276, -1.503601415384615 ], [ 29.736318103448276, -1.503331938461538 ], [ 29.736048620689655, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6270, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.503331938461538 ], [ 29.736318103448276, -1.503601415384615 ], [ 29.736587586206898, -1.503601415384615 ], [ 29.736587586206898, -1.503331938461538 ], [ 29.736318103448276, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6271, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.503331938461538 ], [ 29.736587586206898, -1.503601415384615 ], [ 29.736857068965517, -1.503601415384615 ], [ 29.736857068965517, -1.503331938461538 ], [ 29.736587586206898, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6272, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.503331938461538 ], [ 29.736857068965517, -1.503601415384615 ], [ 29.737126551724138, -1.503601415384615 ], [ 29.737126551724138, -1.503331938461538 ], [ 29.736857068965517, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6273, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.503331938461538 ], [ 29.737126551724138, -1.503601415384615 ], [ 29.737665517241378, -1.503601415384615 ], [ 29.737665517241378, -1.503331938461538 ], [ 29.737126551724138, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6274, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.503331938461538 ], [ 29.737665517241378, -1.503601415384615 ], [ 29.738204482758622, -1.503601415384615 ], [ 29.738204482758622, -1.503331938461538 ], [ 29.737665517241378, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6275, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.503331938461538 ], [ 29.739012931034484, -1.503601415384615 ], [ 29.739282413793102, -1.503601415384615 ], [ 29.739282413793102, -1.503331938461538 ], [ 29.739012931034484, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6276, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.503331938461538 ], [ 29.739282413793102, -1.503601415384615 ], [ 29.739551896551724, -1.503601415384615 ], [ 29.739551896551724, -1.503331938461538 ], [ 29.739282413793102, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6277, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.503331938461538 ], [ 29.739551896551724, -1.503601415384615 ], [ 29.739821379310346, -1.503601415384615 ], [ 29.739821379310346, -1.503331938461538 ], [ 29.739551896551724, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6278, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.503331938461538 ], [ 29.739821379310346, -1.503601415384615 ], [ 29.740090862068964, -1.503601415384615 ], [ 29.740090862068964, -1.503331938461538 ], [ 29.739821379310346, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6279, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.503331938461538 ], [ 29.740090862068964, -1.503601415384615 ], [ 29.740360344827586, -1.503601415384615 ], [ 29.740360344827586, -1.503331938461538 ], [ 29.740090862068964, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6280, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.503331938461538 ], [ 29.742785689655172, -1.503601415384615 ], [ 29.743055172413793, -1.503601415384615 ], [ 29.743055172413793, -1.503331938461538 ], [ 29.742785689655172, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6281, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.503331938461538 ], [ 29.743055172413793, -1.503601415384615 ], [ 29.743594137931034, -1.503601415384615 ], [ 29.743594137931034, -1.503331938461538 ], [ 29.743055172413793, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6282, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.503331938461538 ], [ 29.75733775862069, -1.503601415384615 ], [ 29.757607241379311, -1.503601415384615 ], [ 29.757607241379311, -1.503331938461538 ], [ 29.75733775862069, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6283, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.503331938461538 ], [ 29.757607241379311, -1.503601415384615 ], [ 29.757876724137933, -1.503601415384615 ], [ 29.757876724137933, -1.503331938461538 ], [ 29.757607241379311, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6284, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.503331938461538 ], [ 29.757876724137933, -1.503601415384615 ], [ 29.758146206896551, -1.503601415384615 ], [ 29.758146206896551, -1.503331938461538 ], [ 29.757876724137933, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6285, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.503331938461538 ], [ 29.758146206896551, -1.503601415384615 ], [ 29.758415689655173, -1.503601415384615 ], [ 29.758415689655173, -1.503331938461538 ], [ 29.758146206896551, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6286, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.503331938461538 ], [ 29.758415689655173, -1.503601415384615 ], [ 29.758685172413795, -1.503601415384615 ], [ 29.758685172413795, -1.503331938461538 ], [ 29.758415689655173, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6287, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.503331938461538 ], [ 29.758685172413795, -1.503601415384615 ], [ 29.758954655172413, -1.503601415384615 ], [ 29.758954655172413, -1.503331938461538 ], [ 29.758685172413795, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6288, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.503331938461538 ], [ 29.758954655172413, -1.503601415384615 ], [ 29.759224137931035, -1.503601415384615 ], [ 29.759224137931035, -1.503331938461538 ], [ 29.758954655172413, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6289, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.503331938461538 ], [ 29.759224137931035, -1.503601415384615 ], [ 29.759493620689657, -1.503601415384615 ], [ 29.759493620689657, -1.503331938461538 ], [ 29.759224137931035, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6290, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.503331938461538 ], [ 29.759493620689657, -1.503601415384615 ], [ 29.759763103448275, -1.503601415384615 ], [ 29.759763103448275, -1.503331938461538 ], [ 29.759493620689657, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6291, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.503331938461538 ], [ 29.759763103448275, -1.503601415384615 ], [ 29.760032586206897, -1.503601415384615 ], [ 29.760032586206897, -1.503331938461538 ], [ 29.759763103448275, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6292, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.503331938461538 ], [ 29.760032586206897, -1.503601415384615 ], [ 29.760302068965519, -1.503601415384615 ], [ 29.760302068965519, -1.503331938461538 ], [ 29.760032586206897, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6293, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.503331938461538 ], [ 29.760302068965519, -1.503870892307692 ], [ 29.760571551724137, -1.503870892307692 ], [ 29.760571551724137, -1.503331938461538 ], [ 29.760302068965519, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6294, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.503331938461538 ], [ 29.761110517241381, -1.503601415384615 ], [ 29.76138, -1.503601415384615 ], [ 29.76138, -1.503331938461538 ], [ 29.761110517241381, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6295, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.503331938461538 ], [ 29.76138, -1.503601415384615 ], [ 29.761649482758621, -1.503601415384615 ], [ 29.761649482758621, -1.503331938461538 ], [ 29.76138, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6296, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.503331938461538 ], [ 29.761649482758621, -1.503601415384615 ], [ 29.761918965517243, -1.503601415384615 ], [ 29.761918965517243, -1.503331938461538 ], [ 29.761649482758621, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6297, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.503331938461538 ], [ 29.761918965517243, -1.504140369230769 ], [ 29.762188448275861, -1.504140369230769 ], [ 29.762188448275861, -1.503331938461538 ], [ 29.761918965517243, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6298, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.503331938461538 ], [ 29.762188448275861, -1.504140369230769 ], [ 29.762457931034483, -1.504140369230769 ], [ 29.762457931034483, -1.503331938461538 ], [ 29.762188448275861, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6299, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.503331938461538 ], [ 29.762457931034483, -1.504140369230769 ], [ 29.762727413793105, -1.504140369230769 ], [ 29.762727413793105, -1.503331938461538 ], [ 29.762457931034483, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6300, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.503331938461538 ], [ 29.762727413793105, -1.503870892307692 ], [ 29.762996896551723, -1.503870892307692 ], [ 29.762996896551723, -1.503331938461538 ], [ 29.762727413793105, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6301, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.503601415384615 ], [ 29.727155689655174, -1.503870892307692 ], [ 29.727425172413792, -1.503870892307692 ], [ 29.727425172413792, -1.503601415384615 ], [ 29.727155689655174, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6302, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.503601415384615 ], [ 29.727425172413792, -1.503870892307692 ], [ 29.727694655172414, -1.503870892307692 ], [ 29.727694655172414, -1.503601415384615 ], [ 29.727425172413792, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6303, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.503601415384615 ], [ 29.727694655172414, -1.504140369230769 ], [ 29.727964137931036, -1.504140369230769 ], [ 29.727964137931036, -1.503601415384615 ], [ 29.727694655172414, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6304, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.503601415384615 ], [ 29.727964137931036, -1.503870892307692 ], [ 29.728233620689654, -1.503870892307692 ], [ 29.728233620689654, -1.503601415384615 ], [ 29.727964137931036, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6305, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.503601415384615 ], [ 29.728233620689654, -1.503870892307692 ], [ 29.728503103448276, -1.503870892307692 ], [ 29.728503103448276, -1.503601415384615 ], [ 29.728233620689654, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6306, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.503601415384615 ], [ 29.730389482758621, -1.503870892307692 ], [ 29.730658965517243, -1.503870892307692 ], [ 29.730658965517243, -1.503601415384615 ], [ 29.730389482758621, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6307, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.503601415384615 ], [ 29.730658965517243, -1.503870892307692 ], [ 29.730928448275861, -1.503870892307692 ], [ 29.730928448275861, -1.503601415384615 ], [ 29.730658965517243, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6308, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.503601415384615 ], [ 29.730928448275861, -1.503870892307692 ], [ 29.731197931034483, -1.503870892307692 ], [ 29.731197931034483, -1.503601415384615 ], [ 29.730928448275861, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6309, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.503601415384615 ], [ 29.731197931034483, -1.503870892307692 ], [ 29.731467413793105, -1.503870892307692 ], [ 29.731467413793105, -1.503601415384615 ], [ 29.731197931034483, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6310, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.503601415384615 ], [ 29.731467413793105, -1.503870892307692 ], [ 29.731736896551723, -1.503870892307692 ], [ 29.731736896551723, -1.503601415384615 ], [ 29.731467413793105, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6311, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.503601415384615 ], [ 29.731736896551723, -1.503870892307692 ], [ 29.732006379310345, -1.503870892307692 ], [ 29.732006379310345, -1.503601415384615 ], [ 29.731736896551723, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6312, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.503601415384615 ], [ 29.732006379310345, -1.503870892307692 ], [ 29.732275862068967, -1.503870892307692 ], [ 29.732275862068967, -1.503601415384615 ], [ 29.732006379310345, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6313, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.503601415384615 ], [ 29.732275862068967, -1.503870892307692 ], [ 29.733623275862069, -1.503870892307692 ], [ 29.733623275862069, -1.503601415384615 ], [ 29.732275862068967, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6314, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.503601415384615 ], [ 29.733623275862069, -1.503870892307692 ], [ 29.733892758620691, -1.503870892307692 ], [ 29.733892758620691, -1.503601415384615 ], [ 29.733623275862069, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6315, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.503601415384615 ], [ 29.733892758620691, -1.503870892307692 ], [ 29.734701206896553, -1.503870892307692 ], [ 29.734701206896553, -1.503601415384615 ], [ 29.733892758620691, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6316, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.503601415384615 ], [ 29.734701206896553, -1.503870892307692 ], [ 29.734970689655171, -1.503870892307692 ], [ 29.734970689655171, -1.503601415384615 ], [ 29.734701206896553, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6317, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.503601415384615 ], [ 29.734970689655171, -1.503870892307692 ], [ 29.735240172413793, -1.503870892307692 ], [ 29.735240172413793, -1.503601415384615 ], [ 29.734970689655171, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6318, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.503601415384615 ], [ 29.735240172413793, -1.503870892307692 ], [ 29.735509655172415, -1.503870892307692 ], [ 29.735509655172415, -1.503601415384615 ], [ 29.735240172413793, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6319, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.503601415384615 ], [ 29.735509655172415, -1.503870892307692 ], [ 29.735779137931036, -1.503870892307692 ], [ 29.735779137931036, -1.503601415384615 ], [ 29.735509655172415, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6320, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.503601415384615 ], [ 29.735779137931036, -1.503870892307692 ], [ 29.736048620689655, -1.503870892307692 ], [ 29.736048620689655, -1.503601415384615 ], [ 29.735779137931036, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6321, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.503601415384615 ], [ 29.736048620689655, -1.503870892307692 ], [ 29.736318103448276, -1.503870892307692 ], [ 29.736318103448276, -1.503601415384615 ], [ 29.736048620689655, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6322, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.503601415384615 ], [ 29.736318103448276, -1.503870892307692 ], [ 29.736587586206898, -1.503870892307692 ], [ 29.736587586206898, -1.503601415384615 ], [ 29.736318103448276, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6323, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.503601415384615 ], [ 29.736587586206898, -1.503870892307692 ], [ 29.736857068965517, -1.503870892307692 ], [ 29.736857068965517, -1.503601415384615 ], [ 29.736587586206898, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6324, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.503601415384615 ], [ 29.736857068965517, -1.503870892307692 ], [ 29.737126551724138, -1.503870892307692 ], [ 29.737126551724138, -1.503601415384615 ], [ 29.736857068965517, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6325, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.503601415384615 ], [ 29.737126551724138, -1.503870892307692 ], [ 29.737935, -1.503870892307692 ], [ 29.737935, -1.503601415384615 ], [ 29.737126551724138, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6326, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.503601415384615 ], [ 29.737935, -1.503870892307692 ], [ 29.738204482758622, -1.503870892307692 ], [ 29.738204482758622, -1.503601415384615 ], [ 29.737935, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6327, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.503601415384615 ], [ 29.738204482758622, -1.503870892307692 ], [ 29.738743448275862, -1.503870892307692 ], [ 29.738743448275862, -1.503601415384615 ], [ 29.738204482758622, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6328, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.503601415384615 ], [ 29.738743448275862, -1.503870892307692 ], [ 29.739012931034484, -1.503870892307692 ], [ 29.739012931034484, -1.503601415384615 ], [ 29.738743448275862, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6329, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.503601415384615 ], [ 29.739012931034484, -1.503870892307692 ], [ 29.739282413793102, -1.503870892307692 ], [ 29.739282413793102, -1.503601415384615 ], [ 29.739012931034484, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6330, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.503601415384615 ], [ 29.739282413793102, -1.503870892307692 ], [ 29.739551896551724, -1.503870892307692 ], [ 29.739551896551724, -1.503601415384615 ], [ 29.739282413793102, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6331, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.503601415384615 ], [ 29.739551896551724, -1.503870892307692 ], [ 29.739821379310346, -1.503870892307692 ], [ 29.739821379310346, -1.503601415384615 ], [ 29.739551896551724, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6332, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.503601415384615 ], [ 29.739821379310346, -1.503870892307692 ], [ 29.740090862068964, -1.503870892307692 ], [ 29.740090862068964, -1.503601415384615 ], [ 29.739821379310346, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6333, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.503601415384615 ], [ 29.74197724137931, -1.5049488 ], [ 29.742246724137932, -1.5049488 ], [ 29.742246724137932, -1.503601415384615 ], [ 29.74197724137931, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6334, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.503601415384615 ], [ 29.742785689655172, -1.5049488 ], [ 29.743055172413793, -1.5049488 ], [ 29.743055172413793, -1.503601415384615 ], [ 29.742785689655172, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6335, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.503601415384615 ], [ 29.757607241379311, -1.503870892307692 ], [ 29.757876724137933, -1.503870892307692 ], [ 29.757876724137933, -1.503601415384615 ], [ 29.757607241379311, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6336, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.503601415384615 ], [ 29.757876724137933, -1.503870892307692 ], [ 29.758146206896551, -1.503870892307692 ], [ 29.758146206896551, -1.503601415384615 ], [ 29.757876724137933, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6337, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.503601415384615 ], [ 29.758146206896551, -1.503870892307692 ], [ 29.758415689655173, -1.503870892307692 ], [ 29.758415689655173, -1.503601415384615 ], [ 29.758146206896551, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6338, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.503601415384615 ], [ 29.758415689655173, -1.503870892307692 ], [ 29.758685172413795, -1.503870892307692 ], [ 29.758685172413795, -1.503601415384615 ], [ 29.758415689655173, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6339, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.503601415384615 ], [ 29.758685172413795, -1.503870892307692 ], [ 29.758954655172413, -1.503870892307692 ], [ 29.758954655172413, -1.503601415384615 ], [ 29.758685172413795, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6340, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.503601415384615 ], [ 29.758954655172413, -1.503870892307692 ], [ 29.759224137931035, -1.503870892307692 ], [ 29.759224137931035, -1.503601415384615 ], [ 29.758954655172413, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6341, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.503601415384615 ], [ 29.759224137931035, -1.503870892307692 ], [ 29.759493620689657, -1.503870892307692 ], [ 29.759493620689657, -1.503601415384615 ], [ 29.759224137931035, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6342, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.503601415384615 ], [ 29.759493620689657, -1.503870892307692 ], [ 29.759763103448275, -1.503870892307692 ], [ 29.759763103448275, -1.503601415384615 ], [ 29.759493620689657, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6343, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.503601415384615 ], [ 29.759763103448275, -1.503870892307692 ], [ 29.760032586206897, -1.503870892307692 ], [ 29.760032586206897, -1.503601415384615 ], [ 29.759763103448275, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6344, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.503601415384615 ], [ 29.760032586206897, -1.504140369230769 ], [ 29.760302068965519, -1.504140369230769 ], [ 29.760302068965519, -1.503601415384615 ], [ 29.760032586206897, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6345, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.503601415384615 ], [ 29.760571551724137, -1.503870892307692 ], [ 29.760841034482759, -1.503870892307692 ], [ 29.760841034482759, -1.503601415384615 ], [ 29.760571551724137, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6346, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.503601415384615 ], [ 29.760841034482759, -1.503870892307692 ], [ 29.761110517241381, -1.503870892307692 ], [ 29.761110517241381, -1.503601415384615 ], [ 29.760841034482759, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6347, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.503601415384615 ], [ 29.761110517241381, -1.504140369230769 ], [ 29.76138, -1.504140369230769 ], [ 29.76138, -1.503601415384615 ], [ 29.761110517241381, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6348, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.503601415384615 ], [ 29.76138, -1.504409846153846 ], [ 29.761649482758621, -1.504409846153846 ], [ 29.761649482758621, -1.503601415384615 ], [ 29.76138, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6349, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.503601415384615 ], [ 29.761649482758621, -1.504409846153846 ], [ 29.761918965517243, -1.504409846153846 ], [ 29.761918965517243, -1.503601415384615 ], [ 29.761649482758621, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6350, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.503870892307692 ], [ 29.721766034482759, -1.504679323076923 ], [ 29.72203551724138, -1.504679323076923 ], [ 29.72203551724138, -1.503870892307692 ], [ 29.721766034482759, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6351, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.503870892307692 ], [ 29.72203551724138, -1.504140369230769 ], [ 29.722305, -1.504140369230769 ], [ 29.722305, -1.503870892307692 ], [ 29.72203551724138, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6352, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.503870892307692 ], [ 29.722843965517242, -1.504409846153846 ], [ 29.723113448275861, -1.504409846153846 ], [ 29.723113448275861, -1.503870892307692 ], [ 29.722843965517242, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6353, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.503870892307692 ], [ 29.726347241379312, -1.504679323076923 ], [ 29.72661672413793, -1.504679323076923 ], [ 29.72661672413793, -1.503870892307692 ], [ 29.726347241379312, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6354, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.503870892307692 ], [ 29.727155689655174, -1.504140369230769 ], [ 29.727425172413792, -1.504140369230769 ], [ 29.727425172413792, -1.503870892307692 ], [ 29.727155689655174, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6355, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.503870892307692 ], [ 29.727425172413792, -1.504140369230769 ], [ 29.727694655172414, -1.504140369230769 ], [ 29.727694655172414, -1.503870892307692 ], [ 29.727425172413792, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6356, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.503870892307692 ], [ 29.727964137931036, -1.504409846153846 ], [ 29.728233620689654, -1.504409846153846 ], [ 29.728233620689654, -1.503870892307692 ], [ 29.727964137931036, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6357, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.503870892307692 ], [ 29.728233620689654, -1.504140369230769 ], [ 29.728503103448276, -1.504140369230769 ], [ 29.728503103448276, -1.503870892307692 ], [ 29.728233620689654, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6358, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.503870892307692 ], [ 29.728503103448276, -1.504140369230769 ], [ 29.728772586206897, -1.504140369230769 ], [ 29.728772586206897, -1.503870892307692 ], [ 29.728503103448276, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6359, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.503870892307692 ], [ 29.728772586206897, -1.504140369230769 ], [ 29.729042068965516, -1.504140369230769 ], [ 29.729042068965516, -1.503870892307692 ], [ 29.728772586206897, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6360, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.503870892307692 ], [ 29.729042068965516, -1.504140369230769 ], [ 29.729311551724138, -1.504140369230769 ], [ 29.729311551724138, -1.503870892307692 ], [ 29.729042068965516, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6361, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.503870892307692 ], [ 29.729311551724138, -1.504140369230769 ], [ 29.729850517241381, -1.504140369230769 ], [ 29.729850517241381, -1.503870892307692 ], [ 29.729311551724138, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6362, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.503870892307692 ], [ 29.729850517241381, -1.504140369230769 ], [ 29.73012, -1.504140369230769 ], [ 29.73012, -1.503870892307692 ], [ 29.729850517241381, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6363, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.503870892307692 ], [ 29.73012, -1.504140369230769 ], [ 29.730389482758621, -1.504140369230769 ], [ 29.730389482758621, -1.503870892307692 ], [ 29.73012, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6364, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.503870892307692 ], [ 29.730389482758621, -1.504140369230769 ], [ 29.730658965517243, -1.504140369230769 ], [ 29.730658965517243, -1.503870892307692 ], [ 29.730389482758621, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6365, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.503870892307692 ], [ 29.730658965517243, -1.504140369230769 ], [ 29.730928448275861, -1.504140369230769 ], [ 29.730928448275861, -1.503870892307692 ], [ 29.730658965517243, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6366, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.503870892307692 ], [ 29.730928448275861, -1.504140369230769 ], [ 29.731197931034483, -1.504140369230769 ], [ 29.731197931034483, -1.503870892307692 ], [ 29.730928448275861, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6367, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.503870892307692 ], [ 29.731197931034483, -1.504140369230769 ], [ 29.731467413793105, -1.504140369230769 ], [ 29.731467413793105, -1.503870892307692 ], [ 29.731197931034483, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6368, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.503870892307692 ], [ 29.731467413793105, -1.504140369230769 ], [ 29.731736896551723, -1.504140369230769 ], [ 29.731736896551723, -1.503870892307692 ], [ 29.731467413793105, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6369, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.503870892307692 ], [ 29.731736896551723, -1.504140369230769 ], [ 29.732006379310345, -1.504140369230769 ], [ 29.732006379310345, -1.503870892307692 ], [ 29.731736896551723, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6370, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.503870892307692 ], [ 29.732006379310345, -1.504140369230769 ], [ 29.732545344827585, -1.504140369230769 ], [ 29.732545344827585, -1.503870892307692 ], [ 29.732006379310345, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6371, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.503870892307692 ], [ 29.732545344827585, -1.504140369230769 ], [ 29.733353793103447, -1.504140369230769 ], [ 29.733353793103447, -1.503870892307692 ], [ 29.732545344827585, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6372, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.503870892307692 ], [ 29.733353793103447, -1.504140369230769 ], [ 29.733892758620691, -1.504140369230769 ], [ 29.733892758620691, -1.503870892307692 ], [ 29.733353793103447, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6373, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.503870892307692 ], [ 29.733892758620691, -1.504140369230769 ], [ 29.734701206896553, -1.504140369230769 ], [ 29.734701206896553, -1.503870892307692 ], [ 29.733892758620691, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6374, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.503870892307692 ], [ 29.734701206896553, -1.504140369230769 ], [ 29.734970689655171, -1.504140369230769 ], [ 29.734970689655171, -1.503870892307692 ], [ 29.734701206896553, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6375, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.503870892307692 ], [ 29.734970689655171, -1.504140369230769 ], [ 29.735240172413793, -1.504140369230769 ], [ 29.735240172413793, -1.503870892307692 ], [ 29.734970689655171, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6376, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.503870892307692 ], [ 29.735240172413793, -1.504140369230769 ], [ 29.735509655172415, -1.504140369230769 ], [ 29.735509655172415, -1.503870892307692 ], [ 29.735240172413793, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6377, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.503870892307692 ], [ 29.735509655172415, -1.504140369230769 ], [ 29.735779137931036, -1.504140369230769 ], [ 29.735779137931036, -1.503870892307692 ], [ 29.735509655172415, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6378, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.503870892307692 ], [ 29.735779137931036, -1.504140369230769 ], [ 29.736048620689655, -1.504140369230769 ], [ 29.736048620689655, -1.503870892307692 ], [ 29.735779137931036, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6379, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.503870892307692 ], [ 29.736048620689655, -1.504140369230769 ], [ 29.736318103448276, -1.504140369230769 ], [ 29.736318103448276, -1.503870892307692 ], [ 29.736048620689655, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6380, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.503870892307692 ], [ 29.736318103448276, -1.504140369230769 ], [ 29.736587586206898, -1.504140369230769 ], [ 29.736587586206898, -1.503870892307692 ], [ 29.736318103448276, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6381, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.503870892307692 ], [ 29.736587586206898, -1.504140369230769 ], [ 29.736857068965517, -1.504140369230769 ], [ 29.736857068965517, -1.503870892307692 ], [ 29.736587586206898, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6382, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.503870892307692 ], [ 29.736857068965517, -1.504140369230769 ], [ 29.73739603448276, -1.504140369230769 ], [ 29.73739603448276, -1.503870892307692 ], [ 29.736857068965517, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6383, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.503870892307692 ], [ 29.73739603448276, -1.504140369230769 ], [ 29.737935, -1.504140369230769 ], [ 29.737935, -1.503870892307692 ], [ 29.73739603448276, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6384, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.503870892307692 ], [ 29.737935, -1.504140369230769 ], [ 29.738204482758622, -1.504140369230769 ], [ 29.738204482758622, -1.503870892307692 ], [ 29.737935, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6385, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.503870892307692 ], [ 29.738204482758622, -1.504140369230769 ], [ 29.738743448275862, -1.504140369230769 ], [ 29.738743448275862, -1.503870892307692 ], [ 29.738204482758622, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6386, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.503870892307692 ], [ 29.738743448275862, -1.504140369230769 ], [ 29.739012931034484, -1.504140369230769 ], [ 29.739012931034484, -1.503870892307692 ], [ 29.738743448275862, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6387, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.503870892307692 ], [ 29.739012931034484, -1.504140369230769 ], [ 29.739282413793102, -1.504140369230769 ], [ 29.739282413793102, -1.503870892307692 ], [ 29.739012931034484, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6388, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.503870892307692 ], [ 29.739282413793102, -1.504140369230769 ], [ 29.739551896551724, -1.504140369230769 ], [ 29.739551896551724, -1.504409846153846 ], [ 29.739821379310346, -1.504409846153846 ], [ 29.739821379310346, -1.503870892307692 ], [ 29.739282413793102, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6389, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.503870892307692 ], [ 29.75733775862069, -1.504140369230769 ], [ 29.757607241379311, -1.504140369230769 ], [ 29.757607241379311, -1.503870892307692 ], [ 29.75733775862069, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6390, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.503870892307692 ], [ 29.757607241379311, -1.504140369230769 ], [ 29.757876724137933, -1.504140369230769 ], [ 29.757876724137933, -1.503870892307692 ], [ 29.757607241379311, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6391, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.503870892307692 ], [ 29.757876724137933, -1.504140369230769 ], [ 29.758146206896551, -1.504140369230769 ], [ 29.758146206896551, -1.503870892307692 ], [ 29.757876724137933, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6392, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.503870892307692 ], [ 29.758146206896551, -1.504140369230769 ], [ 29.758415689655173, -1.504140369230769 ], [ 29.758415689655173, -1.503870892307692 ], [ 29.758146206896551, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6393, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.503870892307692 ], [ 29.758415689655173, -1.504140369230769 ], [ 29.758685172413795, -1.504140369230769 ], [ 29.758685172413795, -1.503870892307692 ], [ 29.758415689655173, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6394, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.503870892307692 ], [ 29.758685172413795, -1.504140369230769 ], [ 29.758954655172413, -1.504140369230769 ], [ 29.758954655172413, -1.503870892307692 ], [ 29.758685172413795, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6395, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.503870892307692 ], [ 29.758954655172413, -1.504140369230769 ], [ 29.759224137931035, -1.504140369230769 ], [ 29.759224137931035, -1.503870892307692 ], [ 29.758954655172413, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6396, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.503870892307692 ], [ 29.759224137931035, -1.504140369230769 ], [ 29.759493620689657, -1.504140369230769 ], [ 29.759493620689657, -1.503870892307692 ], [ 29.759224137931035, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6397, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.503870892307692 ], [ 29.759493620689657, -1.504409846153846 ], [ 29.759763103448275, -1.504409846153846 ], [ 29.759763103448275, -1.503870892307692 ], [ 29.759493620689657, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6398, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.503870892307692 ], [ 29.759763103448275, -1.504409846153846 ], [ 29.760032586206897, -1.504409846153846 ], [ 29.760032586206897, -1.503870892307692 ], [ 29.759763103448275, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6399, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.503870892307692 ], [ 29.760302068965519, -1.504140369230769 ], [ 29.760571551724137, -1.504140369230769 ], [ 29.760571551724137, -1.503870892307692 ], [ 29.760302068965519, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6400, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.503870892307692 ], [ 29.760571551724137, -1.504409846153846 ], [ 29.760841034482759, -1.504409846153846 ], [ 29.760841034482759, -1.503870892307692 ], [ 29.760571551724137, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6401, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.503870892307692 ], [ 29.760841034482759, -1.504679323076923 ], [ 29.761110517241381, -1.504679323076923 ], [ 29.761110517241381, -1.503870892307692 ], [ 29.760841034482759, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6402, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.503870892307692 ], [ 29.762727413793105, -1.504409846153846 ], [ 29.762996896551723, -1.504409846153846 ], [ 29.762996896551723, -1.503870892307692 ], [ 29.762727413793105, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6403, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.503870892307692 ], [ 29.763535862068967, -1.504140369230769 ], [ 29.763805344827588, -1.504140369230769 ], [ 29.763805344827588, -1.503870892307692 ], [ 29.763535862068967, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6404, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.503870892307692 ], [ 29.763805344827588, -1.504140369230769 ], [ 29.764074827586207, -1.504140369230769 ], [ 29.764074827586207, -1.503870892307692 ], [ 29.763805344827588, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6405, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.504140369230769 ], [ 29.72203551724138, -1.5049488 ], [ 29.722305, -1.5049488 ], [ 29.722305, -1.504140369230769 ], [ 29.72203551724138, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6406, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.504140369230769 ], [ 29.722305, -1.5049488 ], [ 29.72257448275862, -1.5049488 ], [ 29.72257448275862, -1.504140369230769 ], [ 29.722305, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6407, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.504140369230769 ], [ 29.72257448275862, -1.504409846153846 ], [ 29.722843965517242, -1.504409846153846 ], [ 29.722843965517242, -1.504140369230769 ], [ 29.72257448275862, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6408, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.504140369230769 ], [ 29.723113448275861, -1.504409846153846 ], [ 29.723382931034482, -1.504409846153846 ], [ 29.723382931034482, -1.504140369230769 ], [ 29.723113448275861, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6409, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.504140369230769 ], [ 29.723382931034482, -1.504409846153846 ], [ 29.723652413793104, -1.504409846153846 ], [ 29.723652413793104, -1.504140369230769 ], [ 29.723382931034482, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6410, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.504140369230769 ], [ 29.723652413793104, -1.504409846153846 ], [ 29.723921896551722, -1.504409846153846 ], [ 29.723921896551722, -1.504140369230769 ], [ 29.723652413793104, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6411, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.504140369230769 ], [ 29.724460862068966, -1.504679323076923 ], [ 29.724730344827588, -1.504679323076923 ], [ 29.724730344827588, -1.504140369230769 ], [ 29.724460862068966, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6412, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.504140369230769 ], [ 29.725269310344828, -1.504679323076923 ], [ 29.72553879310345, -1.504679323076923 ], [ 29.72553879310345, -1.504140369230769 ], [ 29.725269310344828, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6413, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.504140369230769 ], [ 29.726886206896552, -1.504679323076923 ], [ 29.727155689655174, -1.504679323076923 ], [ 29.727155689655174, -1.504140369230769 ], [ 29.726886206896552, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6414, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.504140369230769 ], [ 29.727155689655174, -1.504679323076923 ], [ 29.727425172413792, -1.504679323076923 ], [ 29.727425172413792, -1.504140369230769 ], [ 29.727155689655174, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6415, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.504140369230769 ], [ 29.727425172413792, -1.504409846153846 ], [ 29.727694655172414, -1.504409846153846 ], [ 29.727694655172414, -1.504140369230769 ], [ 29.727425172413792, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6416, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.504140369230769 ], [ 29.727694655172414, -1.504409846153846 ], [ 29.727964137931036, -1.504409846153846 ], [ 29.727964137931036, -1.504140369230769 ], [ 29.727694655172414, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6417, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.504140369230769 ], [ 29.728233620689654, -1.504679323076923 ], [ 29.728503103448276, -1.504679323076923 ], [ 29.728503103448276, -1.504140369230769 ], [ 29.728233620689654, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6418, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.504140369230769 ], [ 29.728503103448276, -1.504409846153846 ], [ 29.728772586206897, -1.504409846153846 ], [ 29.728772586206897, -1.504140369230769 ], [ 29.728503103448276, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6419, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.504140369230769 ], [ 29.728772586206897, -1.504409846153846 ], [ 29.729042068965516, -1.504409846153846 ], [ 29.729042068965516, -1.504140369230769 ], [ 29.728772586206897, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6420, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.504140369230769 ], [ 29.729042068965516, -1.504409846153846 ], [ 29.729311551724138, -1.504409846153846 ], [ 29.729311551724138, -1.504140369230769 ], [ 29.729042068965516, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6421, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.504140369230769 ], [ 29.729311551724138, -1.504409846153846 ], [ 29.729850517241381, -1.504409846153846 ], [ 29.729850517241381, -1.504140369230769 ], [ 29.729311551724138, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6422, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.504140369230769 ], [ 29.729850517241381, -1.504409846153846 ], [ 29.73012, -1.504409846153846 ], [ 29.73012, -1.504140369230769 ], [ 29.729850517241381, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6423, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.504140369230769 ], [ 29.73012, -1.504409846153846 ], [ 29.730389482758621, -1.504409846153846 ], [ 29.730389482758621, -1.504140369230769 ], [ 29.73012, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6424, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.504140369230769 ], [ 29.730389482758621, -1.504409846153846 ], [ 29.730658965517243, -1.504409846153846 ], [ 29.730658965517243, -1.504140369230769 ], [ 29.730389482758621, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6425, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.504140369230769 ], [ 29.730658965517243, -1.504409846153846 ], [ 29.730928448275861, -1.504409846153846 ], [ 29.730928448275861, -1.504140369230769 ], [ 29.730658965517243, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6426, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.504140369230769 ], [ 29.730928448275861, -1.504409846153846 ], [ 29.731197931034483, -1.504409846153846 ], [ 29.731197931034483, -1.504140369230769 ], [ 29.730928448275861, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6427, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.504140369230769 ], [ 29.731197931034483, -1.504409846153846 ], [ 29.731467413793105, -1.504409846153846 ], [ 29.731467413793105, -1.504140369230769 ], [ 29.731197931034483, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6428, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.504140369230769 ], [ 29.731467413793105, -1.504409846153846 ], [ 29.731736896551723, -1.504409846153846 ], [ 29.731736896551723, -1.504140369230769 ], [ 29.731467413793105, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6429, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.504140369230769 ], [ 29.731736896551723, -1.504409846153846 ], [ 29.732006379310345, -1.504409846153846 ], [ 29.732006379310345, -1.504140369230769 ], [ 29.731736896551723, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6430, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.504140369230769 ], [ 29.732006379310345, -1.504409846153846 ], [ 29.732275862068967, -1.504409846153846 ], [ 29.732275862068967, -1.504140369230769 ], [ 29.732006379310345, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6431, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.504140369230769 ], [ 29.732275862068967, -1.504409846153846 ], [ 29.733623275862069, -1.504409846153846 ], [ 29.733623275862069, -1.504140369230769 ], [ 29.732275862068967, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6432, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.504140369230769 ], [ 29.733623275862069, -1.504409846153846 ], [ 29.733892758620691, -1.504409846153846 ], [ 29.733892758620691, -1.504140369230769 ], [ 29.733623275862069, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6433, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.504140369230769 ], [ 29.733892758620691, -1.504409846153846 ], [ 29.734431724137931, -1.504409846153846 ], [ 29.734431724137931, -1.504140369230769 ], [ 29.733892758620691, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6434, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.504140369230769 ], [ 29.734431724137931, -1.504409846153846 ], [ 29.734970689655171, -1.504409846153846 ], [ 29.734970689655171, -1.504140369230769 ], [ 29.734431724137931, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6435, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.504140369230769 ], [ 29.734970689655171, -1.504409846153846 ], [ 29.735240172413793, -1.504409846153846 ], [ 29.735240172413793, -1.504140369230769 ], [ 29.734970689655171, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6436, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.504140369230769 ], [ 29.735240172413793, -1.504409846153846 ], [ 29.735509655172415, -1.504409846153846 ], [ 29.735509655172415, -1.504140369230769 ], [ 29.735240172413793, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6437, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.504140369230769 ], [ 29.735509655172415, -1.504409846153846 ], [ 29.735779137931036, -1.504409846153846 ], [ 29.735779137931036, -1.504140369230769 ], [ 29.735509655172415, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6438, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.504140369230769 ], [ 29.735779137931036, -1.504409846153846 ], [ 29.736048620689655, -1.504409846153846 ], [ 29.736048620689655, -1.504140369230769 ], [ 29.735779137931036, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6439, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.504140369230769 ], [ 29.736048620689655, -1.504409846153846 ], [ 29.736318103448276, -1.504409846153846 ], [ 29.736318103448276, -1.504140369230769 ], [ 29.736048620689655, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6440, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.504140369230769 ], [ 29.736318103448276, -1.504409846153846 ], [ 29.736587586206898, -1.504409846153846 ], [ 29.736587586206898, -1.504140369230769 ], [ 29.736318103448276, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6441, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.504140369230769 ], [ 29.736587586206898, -1.504409846153846 ], [ 29.736857068965517, -1.504409846153846 ], [ 29.736857068965517, -1.504140369230769 ], [ 29.736587586206898, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6442, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.504140369230769 ], [ 29.736857068965517, -1.504409846153846 ], [ 29.737665517241378, -1.504409846153846 ], [ 29.737665517241378, -1.504140369230769 ], [ 29.736857068965517, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6443, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.504140369230769 ], [ 29.737665517241378, -1.504409846153846 ], [ 29.737935, -1.504409846153846 ], [ 29.737935, -1.504140369230769 ], [ 29.737665517241378, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6444, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.504140369230769 ], [ 29.737935, -1.504409846153846 ], [ 29.738204482758622, -1.504409846153846 ], [ 29.738204482758622, -1.504140369230769 ], [ 29.737935, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6445, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.504140369230769 ], [ 29.738204482758622, -1.504409846153846 ], [ 29.738743448275862, -1.504409846153846 ], [ 29.738743448275862, -1.504140369230769 ], [ 29.738204482758622, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6446, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.504140369230769 ], [ 29.738743448275862, -1.504409846153846 ], [ 29.739012931034484, -1.504409846153846 ], [ 29.739012931034484, -1.504140369230769 ], [ 29.738743448275862, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6447, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.504140369230769 ], [ 29.739012931034484, -1.504409846153846 ], [ 29.739282413793102, -1.504409846153846 ], [ 29.739282413793102, -1.504140369230769 ], [ 29.739012931034484, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6448, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.504140369230769 ], [ 29.739282413793102, -1.504409846153846 ], [ 29.739551896551724, -1.504409846153846 ], [ 29.739551896551724, -1.504140369230769 ], [ 29.739282413793102, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6449, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.504140369230769 ], [ 29.757607241379311, -1.504409846153846 ], [ 29.757876724137933, -1.504409846153846 ], [ 29.757876724137933, -1.504140369230769 ], [ 29.757607241379311, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6450, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.504140369230769 ], [ 29.757876724137933, -1.504409846153846 ], [ 29.758146206896551, -1.504409846153846 ], [ 29.758146206896551, -1.504140369230769 ], [ 29.757876724137933, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6451, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.504140369230769 ], [ 29.758146206896551, -1.504409846153846 ], [ 29.758415689655173, -1.504409846153846 ], [ 29.758415689655173, -1.504140369230769 ], [ 29.758146206896551, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6452, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.504140369230769 ], [ 29.758415689655173, -1.504409846153846 ], [ 29.758685172413795, -1.504409846153846 ], [ 29.758685172413795, -1.504140369230769 ], [ 29.758415689655173, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6453, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.504140369230769 ], [ 29.758685172413795, -1.504409846153846 ], [ 29.758954655172413, -1.504409846153846 ], [ 29.758954655172413, -1.504140369230769 ], [ 29.758685172413795, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6454, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.504140369230769 ], [ 29.758954655172413, -1.504679323076923 ], [ 29.759224137931035, -1.504679323076923 ], [ 29.759224137931035, -1.504140369230769 ], [ 29.758954655172413, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6455, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.504140369230769 ], [ 29.759224137931035, -1.504679323076923 ], [ 29.759493620689657, -1.504679323076923 ], [ 29.759493620689657, -1.504140369230769 ], [ 29.759224137931035, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6456, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.504140369230769 ], [ 29.760032586206897, -1.504679323076923 ], [ 29.760302068965519, -1.504679323076923 ], [ 29.760302068965519, -1.504140369230769 ], [ 29.760032586206897, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6457, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.504140369230769 ], [ 29.760302068965519, -1.504679323076923 ], [ 29.760571551724137, -1.504679323076923 ], [ 29.760571551724137, -1.504140369230769 ], [ 29.760302068965519, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6458, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.504140369230769 ], [ 29.761110517241381, -1.504679323076923 ], [ 29.76138, -1.504679323076923 ], [ 29.76138, -1.504140369230769 ], [ 29.761110517241381, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6459, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.504140369230769 ], [ 29.761918965517243, -1.504409846153846 ], [ 29.762188448275861, -1.504409846153846 ], [ 29.762188448275861, -1.504140369230769 ], [ 29.761918965517243, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6460, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.504140369230769 ], [ 29.762188448275861, -1.504409846153846 ], [ 29.762457931034483, -1.504409846153846 ], [ 29.762457931034483, -1.504140369230769 ], [ 29.762188448275861, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6461, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.504140369230769 ], [ 29.762457931034483, -1.504409846153846 ], [ 29.762727413793105, -1.504409846153846 ], [ 29.762727413793105, -1.504140369230769 ], [ 29.762457931034483, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6462, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.504140369230769 ], [ 29.762996896551723, -1.504409846153846 ], [ 29.763266379310345, -1.504409846153846 ], [ 29.763266379310345, -1.504140369230769 ], [ 29.762996896551723, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6463, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.504140369230769 ], [ 29.763266379310345, -1.504409846153846 ], [ 29.763535862068967, -1.504409846153846 ], [ 29.763535862068967, -1.504140369230769 ], [ 29.763266379310345, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6464, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.504140369230769 ], [ 29.763535862068967, -1.504409846153846 ], [ 29.763805344827588, -1.504409846153846 ], [ 29.763805344827588, -1.504140369230769 ], [ 29.763535862068967, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6465, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.504140369230769 ], [ 29.763805344827588, -1.504409846153846 ], [ 29.764074827586207, -1.504409846153846 ], [ 29.764074827586207, -1.504140369230769 ], [ 29.763805344827588, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6466, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.504140369230769 ], [ 29.764074827586207, -1.504409846153846 ], [ 29.764344310344828, -1.504409846153846 ], [ 29.764344310344828, -1.504140369230769 ], [ 29.764074827586207, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6467, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.504409846153846 ], [ 29.72257448275862, -1.504679323076923 ], [ 29.722843965517242, -1.504679323076923 ], [ 29.722843965517242, -1.504409846153846 ], [ 29.72257448275862, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6468, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.504409846153846 ], [ 29.722843965517242, -1.5049488 ], [ 29.723113448275861, -1.5049488 ], [ 29.723113448275861, -1.504409846153846 ], [ 29.722843965517242, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6469, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.504409846153846 ], [ 29.723113448275861, -1.5049488 ], [ 29.723382931034482, -1.5049488 ], [ 29.723382931034482, -1.504409846153846 ], [ 29.723113448275861, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6470, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.504409846153846 ], [ 29.723382931034482, -1.5049488 ], [ 29.723652413793104, -1.5049488 ], [ 29.723652413793104, -1.504409846153846 ], [ 29.723382931034482, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6471, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.504409846153846 ], [ 29.723652413793104, -1.504679323076923 ], [ 29.723921896551722, -1.504679323076923 ], [ 29.723921896551722, -1.504409846153846 ], [ 29.723652413793104, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6472, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.504409846153846 ], [ 29.723921896551722, -1.504679323076923 ], [ 29.724191379310344, -1.504679323076923 ], [ 29.724191379310344, -1.504409846153846 ], [ 29.723921896551722, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6473, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.504409846153846 ], [ 29.724191379310344, -1.504679323076923 ], [ 29.724460862068966, -1.504679323076923 ], [ 29.724460862068966, -1.504409846153846 ], [ 29.724191379310344, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6474, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.504409846153846 ], [ 29.724999827586206, -1.5049488 ], [ 29.725269310344828, -1.5049488 ], [ 29.725269310344828, -1.504409846153846 ], [ 29.724999827586206, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6475, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.504409846153846 ], [ 29.725808275862068, -1.5049488 ], [ 29.72607775862069, -1.5049488 ], [ 29.72607775862069, -1.504409846153846 ], [ 29.725808275862068, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6476, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.504409846153846 ], [ 29.72607775862069, -1.5049488 ], [ 29.726347241379312, -1.5049488 ], [ 29.726347241379312, -1.504409846153846 ], [ 29.72607775862069, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6477, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.504409846153846 ], [ 29.727425172413792, -1.5049488 ], [ 29.727694655172414, -1.5049488 ], [ 29.727694655172414, -1.504409846153846 ], [ 29.727425172413792, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6478, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.504409846153846 ], [ 29.727694655172414, -1.5049488 ], [ 29.727964137931036, -1.5049488 ], [ 29.727964137931036, -1.504409846153846 ], [ 29.727694655172414, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6479, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.504409846153846 ], [ 29.727964137931036, -1.504679323076923 ], [ 29.728233620689654, -1.504679323076923 ], [ 29.728233620689654, -1.504409846153846 ], [ 29.727964137931036, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6480, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.504409846153846 ], [ 29.728503103448276, -1.5049488 ], [ 29.728772586206897, -1.5049488 ], [ 29.728772586206897, -1.504409846153846 ], [ 29.728503103448276, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6481, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.504409846153846 ], [ 29.728772586206897, -1.504679323076923 ], [ 29.729042068965516, -1.504679323076923 ], [ 29.729042068965516, -1.504409846153846 ], [ 29.728772586206897, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6482, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.504409846153846 ], [ 29.729042068965516, -1.504679323076923 ], [ 29.729311551724138, -1.504679323076923 ], [ 29.729311551724138, -1.504409846153846 ], [ 29.729042068965516, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6483, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.504409846153846 ], [ 29.729311551724138, -1.504679323076923 ], [ 29.729850517241381, -1.504679323076923 ], [ 29.729850517241381, -1.504409846153846 ], [ 29.729311551724138, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6484, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.504409846153846 ], [ 29.729850517241381, -1.504679323076923 ], [ 29.73012, -1.504679323076923 ], [ 29.73012, -1.504409846153846 ], [ 29.729850517241381, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6485, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.504409846153846 ], [ 29.73012, -1.504679323076923 ], [ 29.730389482758621, -1.504679323076923 ], [ 29.730389482758621, -1.504409846153846 ], [ 29.73012, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6486, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.504409846153846 ], [ 29.730389482758621, -1.504679323076923 ], [ 29.730658965517243, -1.504679323076923 ], [ 29.730658965517243, -1.504409846153846 ], [ 29.730389482758621, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6487, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.504409846153846 ], [ 29.730658965517243, -1.504679323076923 ], [ 29.730928448275861, -1.504679323076923 ], [ 29.730928448275861, -1.504409846153846 ], [ 29.730658965517243, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6488, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.504409846153846 ], [ 29.730928448275861, -1.504679323076923 ], [ 29.731197931034483, -1.504679323076923 ], [ 29.731197931034483, -1.504409846153846 ], [ 29.730928448275861, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6489, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.504409846153846 ], [ 29.731197931034483, -1.504679323076923 ], [ 29.731467413793105, -1.504679323076923 ], [ 29.731467413793105, -1.504409846153846 ], [ 29.731197931034483, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6490, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.504409846153846 ], [ 29.731467413793105, -1.504679323076923 ], [ 29.731736896551723, -1.504679323076923 ], [ 29.731736896551723, -1.504409846153846 ], [ 29.731467413793105, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6491, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.504409846153846 ], [ 29.731736896551723, -1.504679323076923 ], [ 29.732006379310345, -1.504679323076923 ], [ 29.732006379310345, -1.504409846153846 ], [ 29.731736896551723, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6492, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.504409846153846 ], [ 29.732006379310345, -1.504679323076923 ], [ 29.732275862068967, -1.504679323076923 ], [ 29.732275862068967, -1.504409846153846 ], [ 29.732006379310345, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6493, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.504409846153846 ], [ 29.732275862068967, -1.504679323076923 ], [ 29.733623275862069, -1.504679323076923 ], [ 29.733623275862069, -1.504409846153846 ], [ 29.732275862068967, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6494, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.504409846153846 ], [ 29.733623275862069, -1.504679323076923 ], [ 29.734701206896553, -1.504679323076923 ], [ 29.734701206896553, -1.504409846153846 ], [ 29.733623275862069, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6495, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.504409846153846 ], [ 29.734701206896553, -1.504679323076923 ], [ 29.734970689655171, -1.504679323076923 ], [ 29.734970689655171, -1.504409846153846 ], [ 29.734701206896553, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6496, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.504409846153846 ], [ 29.734970689655171, -1.504679323076923 ], [ 29.735509655172415, -1.504679323076923 ], [ 29.735509655172415, -1.504409846153846 ], [ 29.734970689655171, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6497, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.504409846153846 ], [ 29.735509655172415, -1.504679323076923 ], [ 29.735779137931036, -1.504679323076923 ], [ 29.735779137931036, -1.504409846153846 ], [ 29.735509655172415, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6498, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.504409846153846 ], [ 29.735779137931036, -1.504679323076923 ], [ 29.736048620689655, -1.504679323076923 ], [ 29.736048620689655, -1.504409846153846 ], [ 29.735779137931036, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6499, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.504409846153846 ], [ 29.736048620689655, -1.504679323076923 ], [ 29.736318103448276, -1.504679323076923 ], [ 29.736318103448276, -1.504409846153846 ], [ 29.736048620689655, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6500, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.504409846153846 ], [ 29.736318103448276, -1.504679323076923 ], [ 29.736587586206898, -1.504679323076923 ], [ 29.736587586206898, -1.504409846153846 ], [ 29.736318103448276, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6501, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.504409846153846 ], [ 29.736587586206898, -1.504679323076923 ], [ 29.737665517241378, -1.504679323076923 ], [ 29.737665517241378, -1.504409846153846 ], [ 29.736587586206898, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6502, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.504409846153846 ], [ 29.737665517241378, -1.504679323076923 ], [ 29.737935, -1.504679323076923 ], [ 29.737935, -1.504409846153846 ], [ 29.737665517241378, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6503, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.504409846153846 ], [ 29.737935, -1.504679323076923 ], [ 29.738204482758622, -1.504679323076923 ], [ 29.738204482758622, -1.504409846153846 ], [ 29.737935, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6504, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.504409846153846 ], [ 29.738204482758622, -1.504679323076923 ], [ 29.738743448275862, -1.504679323076923 ], [ 29.738743448275862, -1.504409846153846 ], [ 29.738204482758622, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6505, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.504409846153846 ], [ 29.738743448275862, -1.504679323076923 ], [ 29.739012931034484, -1.504679323076923 ], [ 29.739012931034484, -1.504409846153846 ], [ 29.738743448275862, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6506, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.504409846153846 ], [ 29.739012931034484, -1.504679323076923 ], [ 29.739282413793102, -1.504679323076923 ], [ 29.739282413793102, -1.504409846153846 ], [ 29.739012931034484, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6507, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.504409846153846 ], [ 29.739282413793102, -1.504679323076923 ], [ 29.739551896551724, -1.504679323076923 ], [ 29.739551896551724, -1.504409846153846 ], [ 29.739282413793102, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6508, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.503870892307692 ], [ 29.740090862068964, -1.503870892307692 ], [ 29.740090862068964, -1.5049488 ], [ 29.739821379310346, -1.5049488 ], [ 29.739821379310346, -1.504679323076923 ], [ 29.739551896551724, -1.504679323076923 ], [ 29.739551896551724, -1.504409846153846 ], [ 29.739821379310346, -1.504409846153846 ], [ 29.739821379310346, -1.503870892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6509, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.504409846153846 ], [ 29.757876724137933, -1.504679323076923 ], [ 29.758146206896551, -1.504679323076923 ], [ 29.758146206896551, -1.504409846153846 ], [ 29.757876724137933, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6510, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.504409846153846 ], [ 29.758146206896551, -1.504679323076923 ], [ 29.758415689655173, -1.504679323076923 ], [ 29.758415689655173, -1.504409846153846 ], [ 29.758146206896551, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6511, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.504409846153846 ], [ 29.758415689655173, -1.5049488 ], [ 29.758685172413795, -1.5049488 ], [ 29.758685172413795, -1.504409846153846 ], [ 29.758415689655173, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6512, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.504409846153846 ], [ 29.758685172413795, -1.5049488 ], [ 29.758954655172413, -1.5049488 ], [ 29.758954655172413, -1.504409846153846 ], [ 29.758685172413795, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6513, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.504409846153846 ], [ 29.759493620689657, -1.5049488 ], [ 29.759763103448275, -1.5049488 ], [ 29.759763103448275, -1.504409846153846 ], [ 29.759493620689657, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6514, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.504409846153846 ], [ 29.759763103448275, -1.5049488 ], [ 29.760032586206897, -1.5049488 ], [ 29.760032586206897, -1.504409846153846 ], [ 29.759763103448275, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6515, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.504409846153846 ], [ 29.760571551724137, -1.5049488 ], [ 29.760841034482759, -1.5049488 ], [ 29.760841034482759, -1.504409846153846 ], [ 29.760571551724137, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6516, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.504409846153846 ], [ 29.76138, -1.504679323076923 ], [ 29.761649482758621, -1.504679323076923 ], [ 29.761649482758621, -1.504409846153846 ], [ 29.76138, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6517, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.504409846153846 ], [ 29.761649482758621, -1.504679323076923 ], [ 29.761918965517243, -1.504679323076923 ], [ 29.761918965517243, -1.504409846153846 ], [ 29.761649482758621, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6518, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.504409846153846 ], [ 29.761918965517243, -1.504679323076923 ], [ 29.762188448275861, -1.504679323076923 ], [ 29.762188448275861, -1.504409846153846 ], [ 29.761918965517243, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6519, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.504409846153846 ], [ 29.762188448275861, -1.504679323076923 ], [ 29.762457931034483, -1.504679323076923 ], [ 29.762457931034483, -1.504409846153846 ], [ 29.762188448275861, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6520, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.504409846153846 ], [ 29.762457931034483, -1.504679323076923 ], [ 29.762727413793105, -1.504679323076923 ], [ 29.762727413793105, -1.504409846153846 ], [ 29.762457931034483, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6521, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.504409846153846 ], [ 29.762727413793105, -1.504679323076923 ], [ 29.762996896551723, -1.504679323076923 ], [ 29.762996896551723, -1.504409846153846 ], [ 29.762727413793105, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6522, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.504409846153846 ], [ 29.762996896551723, -1.504679323076923 ], [ 29.763266379310345, -1.504679323076923 ], [ 29.763266379310345, -1.504409846153846 ], [ 29.762996896551723, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6523, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.504409846153846 ], [ 29.763266379310345, -1.504679323076923 ], [ 29.763535862068967, -1.504679323076923 ], [ 29.763535862068967, -1.504409846153846 ], [ 29.763266379310345, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6524, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.504409846153846 ], [ 29.763535862068967, -1.504679323076923 ], [ 29.763805344827588, -1.504679323076923 ], [ 29.763805344827588, -1.504409846153846 ], [ 29.763535862068967, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6525, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.504409846153846 ], [ 29.763805344827588, -1.504679323076923 ], [ 29.764074827586207, -1.504679323076923 ], [ 29.764074827586207, -1.504409846153846 ], [ 29.763805344827588, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6526, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.504409846153846 ], [ 29.764074827586207, -1.504679323076923 ], [ 29.764344310344828, -1.504679323076923 ], [ 29.764344310344828, -1.504409846153846 ], [ 29.764074827586207, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6527, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.504409846153846 ], [ 29.764344310344828, -1.5049488 ], [ 29.76461379310345, -1.5049488 ], [ 29.76461379310345, -1.504409846153846 ], [ 29.764344310344828, -1.504409846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6528, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.504679323076923 ], [ 29.721766034482759, -1.5049488 ], [ 29.72203551724138, -1.5049488 ], [ 29.72203551724138, -1.504679323076923 ], [ 29.721766034482759, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6529, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.504679323076923 ], [ 29.724191379310344, -1.5049488 ], [ 29.724460862068966, -1.5049488 ], [ 29.724460862068966, -1.504679323076923 ], [ 29.724191379310344, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6530, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.504679323076923 ], [ 29.724460862068966, -1.5049488 ], [ 29.724730344827588, -1.5049488 ], [ 29.724730344827588, -1.504679323076923 ], [ 29.724460862068966, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6531, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.504679323076923 ], [ 29.724730344827588, -1.5049488 ], [ 29.724999827586206, -1.5049488 ], [ 29.724999827586206, -1.504679323076923 ], [ 29.724730344827588, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6532, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.504679323076923 ], [ 29.72553879310345, -1.5049488 ], [ 29.725808275862068, -1.5049488 ], [ 29.725808275862068, -1.504679323076923 ], [ 29.72553879310345, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6533, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.504679323076923 ], [ 29.729311551724138, -1.5049488 ], [ 29.729850517241381, -1.5049488 ], [ 29.729850517241381, -1.504679323076923 ], [ 29.729311551724138, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6534, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.504679323076923 ], [ 29.729850517241381, -1.5049488 ], [ 29.73012, -1.5049488 ], [ 29.73012, -1.504679323076923 ], [ 29.729850517241381, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6535, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.504679323076923 ], [ 29.73012, -1.5049488 ], [ 29.730389482758621, -1.5049488 ], [ 29.730389482758621, -1.504679323076923 ], [ 29.73012, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6536, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.504679323076923 ], [ 29.730389482758621, -1.5049488 ], [ 29.730658965517243, -1.5049488 ], [ 29.730658965517243, -1.504679323076923 ], [ 29.730389482758621, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6537, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.504679323076923 ], [ 29.730658965517243, -1.5049488 ], [ 29.730928448275861, -1.5049488 ], [ 29.730928448275861, -1.504679323076923 ], [ 29.730658965517243, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6538, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.504679323076923 ], [ 29.730928448275861, -1.5049488 ], [ 29.731197931034483, -1.5049488 ], [ 29.731197931034483, -1.504679323076923 ], [ 29.730928448275861, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6539, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.504679323076923 ], [ 29.731197931034483, -1.5049488 ], [ 29.731467413793105, -1.5049488 ], [ 29.731467413793105, -1.504679323076923 ], [ 29.731197931034483, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6540, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.504679323076923 ], [ 29.731467413793105, -1.5049488 ], [ 29.731736896551723, -1.5049488 ], [ 29.731736896551723, -1.504679323076923 ], [ 29.731467413793105, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6541, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.504679323076923 ], [ 29.731736896551723, -1.5049488 ], [ 29.732006379310345, -1.5049488 ], [ 29.732006379310345, -1.504679323076923 ], [ 29.731736896551723, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6542, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.504679323076923 ], [ 29.732006379310345, -1.5049488 ], [ 29.732275862068967, -1.5049488 ], [ 29.732275862068967, -1.504679323076923 ], [ 29.732006379310345, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6543, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.504679323076923 ], [ 29.732275862068967, -1.5049488 ], [ 29.732545344827585, -1.5049488 ], [ 29.732545344827585, -1.504679323076923 ], [ 29.732275862068967, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6544, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.504679323076923 ], [ 29.732545344827585, -1.5049488 ], [ 29.733353793103447, -1.5049488 ], [ 29.733353793103447, -1.504679323076923 ], [ 29.732545344827585, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6545, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.504679323076923 ], [ 29.733353793103447, -1.5049488 ], [ 29.733623275862069, -1.5049488 ], [ 29.733623275862069, -1.504679323076923 ], [ 29.733353793103447, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6546, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.504679323076923 ], [ 29.733623275862069, -1.5049488 ], [ 29.734701206896553, -1.5049488 ], [ 29.734701206896553, -1.504679323076923 ], [ 29.733623275862069, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6547, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.504679323076923 ], [ 29.734701206896553, -1.5049488 ], [ 29.735240172413793, -1.5049488 ], [ 29.735240172413793, -1.504679323076923 ], [ 29.734701206896553, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6548, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.504679323076923 ], [ 29.735240172413793, -1.5049488 ], [ 29.735509655172415, -1.5049488 ], [ 29.735509655172415, -1.504679323076923 ], [ 29.735240172413793, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6549, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.504679323076923 ], [ 29.735509655172415, -1.5049488 ], [ 29.735779137931036, -1.5049488 ], [ 29.735779137931036, -1.504679323076923 ], [ 29.735509655172415, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6550, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.504679323076923 ], [ 29.735779137931036, -1.5049488 ], [ 29.736048620689655, -1.5049488 ], [ 29.736048620689655, -1.504679323076923 ], [ 29.735779137931036, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6551, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.504679323076923 ], [ 29.736048620689655, -1.5049488 ], [ 29.736318103448276, -1.5049488 ], [ 29.736318103448276, -1.504679323076923 ], [ 29.736048620689655, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6552, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.504679323076923 ], [ 29.736318103448276, -1.5049488 ], [ 29.73739603448276, -1.5049488 ], [ 29.73739603448276, -1.504679323076923 ], [ 29.736318103448276, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6553, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.504679323076923 ], [ 29.73739603448276, -1.5049488 ], [ 29.737665517241378, -1.5049488 ], [ 29.737665517241378, -1.504679323076923 ], [ 29.73739603448276, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6554, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.504679323076923 ], [ 29.737665517241378, -1.5049488 ], [ 29.737935, -1.5049488 ], [ 29.737935, -1.504679323076923 ], [ 29.737665517241378, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6555, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.504679323076923 ], [ 29.737935, -1.5049488 ], [ 29.738204482758622, -1.5049488 ], [ 29.738204482758622, -1.504679323076923 ], [ 29.737935, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6556, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.504679323076923 ], [ 29.738204482758622, -1.5049488 ], [ 29.738743448275862, -1.5049488 ], [ 29.738743448275862, -1.504679323076923 ], [ 29.738204482758622, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6557, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.504679323076923 ], [ 29.738743448275862, -1.5049488 ], [ 29.739012931034484, -1.5049488 ], [ 29.739012931034484, -1.504679323076923 ], [ 29.738743448275862, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6558, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.504679323076923 ], [ 29.739012931034484, -1.5049488 ], [ 29.739282413793102, -1.5049488 ], [ 29.739282413793102, -1.504679323076923 ], [ 29.739012931034484, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6559, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.504679323076923 ], [ 29.739282413793102, -1.5049488 ], [ 29.739551896551724, -1.5049488 ], [ 29.739551896551724, -1.504679323076923 ], [ 29.739282413793102, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6560, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.504679323076923 ], [ 29.739551896551724, -1.5049488 ], [ 29.739821379310346, -1.5049488 ], [ 29.739821379310346, -1.504679323076923 ], [ 29.739551896551724, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6561, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.504679323076923 ], [ 29.757607241379311, -1.5049488 ], [ 29.757876724137933, -1.5049488 ], [ 29.757876724137933, -1.504679323076923 ], [ 29.757607241379311, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6562, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.504679323076923 ], [ 29.760032586206897, -1.5049488 ], [ 29.760302068965519, -1.5049488 ], [ 29.760302068965519, -1.504679323076923 ], [ 29.760032586206897, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6563, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.504679323076923 ], [ 29.760841034482759, -1.5049488 ], [ 29.761110517241381, -1.5049488 ], [ 29.761110517241381, -1.504679323076923 ], [ 29.760841034482759, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6564, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.504679323076923 ], [ 29.761110517241381, -1.5049488 ], [ 29.76138, -1.5049488 ], [ 29.76138, -1.504679323076923 ], [ 29.761110517241381, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6565, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.504679323076923 ], [ 29.76138, -1.5049488 ], [ 29.761649482758621, -1.5049488 ], [ 29.761649482758621, -1.504679323076923 ], [ 29.76138, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6566, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.504679323076923 ], [ 29.761649482758621, -1.5049488 ], [ 29.761918965517243, -1.5049488 ], [ 29.761918965517243, -1.504679323076923 ], [ 29.761649482758621, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6567, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.504679323076923 ], [ 29.761918965517243, -1.5049488 ], [ 29.762188448275861, -1.5049488 ], [ 29.762188448275861, -1.504679323076923 ], [ 29.761918965517243, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6568, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.504679323076923 ], [ 29.762188448275861, -1.5049488 ], [ 29.762457931034483, -1.5049488 ], [ 29.762457931034483, -1.504679323076923 ], [ 29.762188448275861, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6569, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.504679323076923 ], [ 29.762457931034483, -1.5049488 ], [ 29.762727413793105, -1.5049488 ], [ 29.762727413793105, -1.504679323076923 ], [ 29.762457931034483, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6570, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.504679323076923 ], [ 29.762727413793105, -1.5049488 ], [ 29.762996896551723, -1.5049488 ], [ 29.762996896551723, -1.504679323076923 ], [ 29.762727413793105, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6571, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.504679323076923 ], [ 29.762996896551723, -1.5049488 ], [ 29.763266379310345, -1.5049488 ], [ 29.763266379310345, -1.504679323076923 ], [ 29.762996896551723, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6572, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.504679323076923 ], [ 29.763266379310345, -1.5049488 ], [ 29.763535862068967, -1.5049488 ], [ 29.763535862068967, -1.504679323076923 ], [ 29.763266379310345, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6573, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.504679323076923 ], [ 29.763535862068967, -1.5049488 ], [ 29.763805344827588, -1.5049488 ], [ 29.763805344827588, -1.504679323076923 ], [ 29.763535862068967, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6574, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.501715076923077 ], [ 29.75383448275862, -1.506565661538461 ], [ 29.754103965517242, -1.506565661538461 ], [ 29.754103965517242, -1.501715076923077 ], [ 29.75383448275862, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6575, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.501715076923077 ], [ 29.754642931034482, -1.506296184615385 ], [ 29.754912413793104, -1.506296184615385 ], [ 29.754912413793104, -1.501715076923077 ], [ 29.754642931034482, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6576, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.501715076923077 ], [ 29.755720862068966, -1.506296184615385 ], [ 29.755990344827588, -1.506296184615385 ], [ 29.755990344827588, -1.501715076923077 ], [ 29.755720862068966, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6577, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.501715076923077 ], [ 29.756798793103449, -1.506026707692308 ], [ 29.757068275862068, -1.506026707692308 ], [ 29.757068275862068, -1.502792984615384 ], [ 29.75733775862069, -1.502792984615384 ], [ 29.75733775862069, -1.502523507692308 ], [ 29.757068275862068, -1.502523507692308 ], [ 29.757068275862068, -1.501715076923077 ], [ 29.756798793103449, -1.501715076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6578, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.501984553846154 ], [ 29.753026034482758, -1.506835138461538 ], [ 29.75329551724138, -1.506835138461538 ], [ 29.75329551724138, -1.501984553846154 ], [ 29.753026034482758, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6579, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.501984553846154 ], [ 29.75329551724138, -1.506835138461538 ], [ 29.753565, -1.506835138461538 ], [ 29.753565, -1.501984553846154 ], [ 29.75329551724138, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6580, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.501984553846154 ], [ 29.754103965517242, -1.506565661538461 ], [ 29.754373448275864, -1.506565661538461 ], [ 29.754373448275864, -1.501984553846154 ], [ 29.754103965517242, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6581, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.501984553846154 ], [ 29.754912413793104, -1.506565661538461 ], [ 29.755181896551726, -1.506565661538461 ], [ 29.755181896551726, -1.501984553846154 ], [ 29.754912413793104, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6582, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.501984553846154 ], [ 29.755181896551726, -1.506565661538461 ], [ 29.755451379310344, -1.506565661538461 ], [ 29.755451379310344, -1.501984553846154 ], [ 29.755181896551726, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6583, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.501984553846154 ], [ 29.755990344827588, -1.506296184615385 ], [ 29.756259827586206, -1.506296184615385 ], [ 29.756259827586206, -1.501984553846154 ], [ 29.755990344827588, -1.501984553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6584, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.502254030769231 ], [ 29.752217586206896, -1.507104615384615 ], [ 29.752487068965518, -1.507104615384615 ], [ 29.752487068965518, -1.502254030769231 ], [ 29.752217586206896, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6585, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.502254030769231 ], [ 29.752487068965518, -1.507104615384615 ], [ 29.75275655172414, -1.507104615384615 ], [ 29.75275655172414, -1.502254030769231 ], [ 29.752487068965518, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6586, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.502254030769231 ], [ 29.75275655172414, -1.507104615384615 ], [ 29.753026034482758, -1.507104615384615 ], [ 29.753026034482758, -1.502254030769231 ], [ 29.75275655172414, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6587, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.502254030769231 ], [ 29.753565, -1.506835138461538 ], [ 29.75383448275862, -1.506835138461538 ], [ 29.75383448275862, -1.502254030769231 ], [ 29.753565, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6588, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.502254030769231 ], [ 29.754373448275864, -1.506565661538461 ], [ 29.754642931034482, -1.506565661538461 ], [ 29.754642931034482, -1.502254030769231 ], [ 29.754373448275864, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6589, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.502254030769231 ], [ 29.756259827586206, -1.506565661538461 ], [ 29.756798793103449, -1.506565661538461 ], [ 29.756798793103449, -1.502254030769231 ], [ 29.756259827586206, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6590, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.502523507692308 ], [ 29.751948103448278, -1.507104615384615 ], [ 29.752217586206896, -1.507104615384615 ], [ 29.752217586206896, -1.502523507692308 ], [ 29.751948103448278, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6591, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.502523507692308 ], [ 29.755451379310344, -1.506565661538461 ], [ 29.755720862068966, -1.506565661538461 ], [ 29.755720862068966, -1.502523507692308 ], [ 29.755451379310344, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6592, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.503062461538462 ], [ 29.74089931034483, -1.505757230769231 ], [ 29.741168793103448, -1.505757230769231 ], [ 29.741168793103448, -1.503062461538462 ], [ 29.74089931034483, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6593, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.503062461538462 ], [ 29.757068275862068, -1.506026707692308 ], [ 29.75733775862069, -1.506026707692308 ], [ 29.75733775862069, -1.503870892307692 ], [ 29.757607241379311, -1.503870892307692 ], [ 29.757607241379311, -1.503601415384615 ], [ 29.75733775862069, -1.503601415384615 ], [ 29.75733775862069, -1.503062461538462 ], [ 29.757068275862068, -1.503062461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6594, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.503331938461538 ], [ 29.740360344827586, -1.505218276923077 ], [ 29.740629827586208, -1.505218276923077 ], [ 29.740629827586208, -1.503331938461538 ], [ 29.740360344827586, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6595, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.503331938461538 ], [ 29.740629827586208, -1.505757230769231 ], [ 29.74089931034483, -1.505757230769231 ], [ 29.74089931034483, -1.503331938461538 ], [ 29.740629827586208, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6596, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.503331938461538 ], [ 29.741168793103448, -1.505218276923077 ], [ 29.74143827586207, -1.505218276923077 ], [ 29.74143827586207, -1.503331938461538 ], [ 29.741168793103448, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6597, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.503331938461538 ], [ 29.74143827586207, -1.505218276923077 ], [ 29.741707758620691, -1.505218276923077 ], [ 29.741707758620691, -1.503331938461538 ], [ 29.74143827586207, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6598, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.503331938461538 ], [ 29.741707758620691, -1.505218276923077 ], [ 29.74197724137931, -1.505218276923077 ], [ 29.74197724137931, -1.503331938461538 ], [ 29.741707758620691, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6599, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.503331938461538 ], [ 29.742246724137932, -1.505218276923077 ], [ 29.742516206896553, -1.505218276923077 ], [ 29.742516206896553, -1.503331938461538 ], [ 29.742246724137932, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6600, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.503331938461538 ], [ 29.742516206896553, -1.505218276923077 ], [ 29.742785689655172, -1.505218276923077 ], [ 29.742785689655172, -1.503331938461538 ], [ 29.742516206896553, -1.503331938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6601, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.504140369230769 ], [ 29.75733775862069, -1.506026707692308 ], [ 29.757607241379311, -1.506026707692308 ], [ 29.757607241379311, -1.504679323076923 ], [ 29.757876724137933, -1.504679323076923 ], [ 29.757876724137933, -1.504409846153846 ], [ 29.757607241379311, -1.504409846153846 ], [ 29.757607241379311, -1.504140369230769 ], [ 29.75733775862069, -1.504140369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6602, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.504679323076923 ], [ 29.721496551724137, -1.505487753846154 ], [ 29.721766034482759, -1.505487753846154 ], [ 29.721766034482759, -1.504679323076923 ], [ 29.721496551724137, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6603, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.504679323076923 ], [ 29.72257448275862, -1.505218276923077 ], [ 29.722843965517242, -1.505218276923077 ], [ 29.722843965517242, -1.504679323076923 ], [ 29.72257448275862, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6604, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.504679323076923 ], [ 29.723652413793104, -1.505218276923077 ], [ 29.723921896551722, -1.505218276923077 ], [ 29.723921896551722, -1.504679323076923 ], [ 29.723652413793104, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6605, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.504679323076923 ], [ 29.723921896551722, -1.505218276923077 ], [ 29.724191379310344, -1.505218276923077 ], [ 29.724191379310344, -1.504679323076923 ], [ 29.723921896551722, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6606, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.504679323076923 ], [ 29.725269310344828, -1.505218276923077 ], [ 29.72553879310345, -1.505218276923077 ], [ 29.72553879310345, -1.504679323076923 ], [ 29.725269310344828, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6607, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.504679323076923 ], [ 29.726347241379312, -1.505218276923077 ], [ 29.72661672413793, -1.505218276923077 ], [ 29.72661672413793, -1.504679323076923 ], [ 29.726347241379312, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6608, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.504679323076923 ], [ 29.72661672413793, -1.505487753846154 ], [ 29.726886206896552, -1.505487753846154 ], [ 29.726886206896552, -1.504679323076923 ], [ 29.72661672413793, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6609, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.504679323076923 ], [ 29.726886206896552, -1.505218276923077 ], [ 29.727155689655174, -1.505218276923077 ], [ 29.727155689655174, -1.504679323076923 ], [ 29.726886206896552, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6610, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.504679323076923 ], [ 29.727155689655174, -1.505487753846154 ], [ 29.727425172413792, -1.505487753846154 ], [ 29.727425172413792, -1.504679323076923 ], [ 29.727155689655174, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6611, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.504679323076923 ], [ 29.727964137931036, -1.505757230769231 ], [ 29.728233620689654, -1.505757230769231 ], [ 29.728233620689654, -1.504679323076923 ], [ 29.727964137931036, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6612, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.504679323076923 ], [ 29.728233620689654, -1.505218276923077 ], [ 29.728503103448276, -1.505218276923077 ], [ 29.728503103448276, -1.504679323076923 ], [ 29.728233620689654, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6613, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.504679323076923 ], [ 29.728772586206897, -1.506026707692308 ], [ 29.729042068965516, -1.506026707692308 ], [ 29.729042068965516, -1.504679323076923 ], [ 29.728772586206897, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6614, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.504679323076923 ], [ 29.729042068965516, -1.505218276923077 ], [ 29.729311551724138, -1.505218276923077 ], [ 29.729311551724138, -1.504679323076923 ], [ 29.729042068965516, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6615, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.504679323076923 ], [ 29.757876724137933, -1.505218276923077 ], [ 29.758146206896551, -1.505218276923077 ], [ 29.758146206896551, -1.504679323076923 ], [ 29.757876724137933, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6616, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.504679323076923 ], [ 29.758146206896551, -1.505218276923077 ], [ 29.758415689655173, -1.505218276923077 ], [ 29.758415689655173, -1.504679323076923 ], [ 29.758146206896551, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6617, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.504679323076923 ], [ 29.758954655172413, -1.505218276923077 ], [ 29.759224137931035, -1.505218276923077 ], [ 29.759224137931035, -1.504679323076923 ], [ 29.758954655172413, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6618, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.504679323076923 ], [ 29.759224137931035, -1.505218276923077 ], [ 29.759493620689657, -1.505218276923077 ], [ 29.759493620689657, -1.504679323076923 ], [ 29.759224137931035, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6619, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.504679323076923 ], [ 29.760302068965519, -1.505218276923077 ], [ 29.760571551724137, -1.505218276923077 ], [ 29.760571551724137, -1.504679323076923 ], [ 29.760302068965519, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6620, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.504679323076923 ], [ 29.763805344827588, -1.505218276923077 ], [ 29.764074827586207, -1.505218276923077 ], [ 29.764074827586207, -1.504679323076923 ], [ 29.763805344827588, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6621, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.504679323076923 ], [ 29.764074827586207, -1.505218276923077 ], [ 29.764344310344828, -1.505218276923077 ], [ 29.764344310344828, -1.504679323076923 ], [ 29.764074827586207, -1.504679323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6622, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.5049488 ], [ 29.721766034482759, -1.505757230769231 ], [ 29.72203551724138, -1.505757230769231 ], [ 29.72203551724138, -1.5049488 ], [ 29.721766034482759, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6623, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.5049488 ], [ 29.72203551724138, -1.505757230769231 ], [ 29.722305, -1.505757230769231 ], [ 29.722305, -1.5049488 ], [ 29.72203551724138, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6624, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.5049488 ], [ 29.722305, -1.505218276923077 ], [ 29.72257448275862, -1.505218276923077 ], [ 29.72257448275862, -1.5049488 ], [ 29.722305, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6625, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.5049488 ], [ 29.722843965517242, -1.505218276923077 ], [ 29.723113448275861, -1.505218276923077 ], [ 29.723113448275861, -1.5049488 ], [ 29.722843965517242, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6626, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.5049488 ], [ 29.723113448275861, -1.505218276923077 ], [ 29.723382931034482, -1.505218276923077 ], [ 29.723382931034482, -1.5049488 ], [ 29.723113448275861, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6627, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.5049488 ], [ 29.723382931034482, -1.505218276923077 ], [ 29.723652413793104, -1.505218276923077 ], [ 29.723652413793104, -1.5049488 ], [ 29.723382931034482, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6628, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.5049488 ], [ 29.724191379310344, -1.505487753846154 ], [ 29.724460862068966, -1.505487753846154 ], [ 29.724460862068966, -1.5049488 ], [ 29.724191379310344, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6629, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.5049488 ], [ 29.724460862068966, -1.505487753846154 ], [ 29.724730344827588, -1.505487753846154 ], [ 29.724730344827588, -1.5049488 ], [ 29.724460862068966, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6630, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.5049488 ], [ 29.724730344827588, -1.505218276923077 ], [ 29.724999827586206, -1.505218276923077 ], [ 29.724999827586206, -1.5049488 ], [ 29.724730344827588, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6631, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.5049488 ], [ 29.724999827586206, -1.505218276923077 ], [ 29.725269310344828, -1.505218276923077 ], [ 29.725269310344828, -1.5049488 ], [ 29.724999827586206, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6632, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.5049488 ], [ 29.72553879310345, -1.505487753846154 ], [ 29.725808275862068, -1.505487753846154 ], [ 29.725808275862068, -1.5049488 ], [ 29.72553879310345, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6633, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.5049488 ], [ 29.725808275862068, -1.505218276923077 ], [ 29.72607775862069, -1.505218276923077 ], [ 29.72607775862069, -1.5049488 ], [ 29.725808275862068, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6634, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.5049488 ], [ 29.72607775862069, -1.505218276923077 ], [ 29.726347241379312, -1.505218276923077 ], [ 29.726347241379312, -1.5049488 ], [ 29.72607775862069, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6635, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.5049488 ], [ 29.727425172413792, -1.505487753846154 ], [ 29.727694655172414, -1.505487753846154 ], [ 29.727694655172414, -1.5049488 ], [ 29.727425172413792, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6636, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.5049488 ], [ 29.727694655172414, -1.505757230769231 ], [ 29.727964137931036, -1.505757230769231 ], [ 29.727964137931036, -1.5049488 ], [ 29.727694655172414, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6637, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.5049488 ], [ 29.728503103448276, -1.506026707692308 ], [ 29.728772586206897, -1.506026707692308 ], [ 29.728772586206897, -1.5049488 ], [ 29.728503103448276, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6638, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.5049488 ], [ 29.729311551724138, -1.506296184615385 ], [ 29.729850517241381, -1.506296184615385 ], [ 29.729850517241381, -1.5049488 ], [ 29.729311551724138, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6639, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.5049488 ], [ 29.729850517241381, -1.506296184615385 ], [ 29.73012, -1.506296184615385 ], [ 29.73012, -1.5049488 ], [ 29.729850517241381, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6640, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.5049488 ], [ 29.73012, -1.505218276923077 ], [ 29.730389482758621, -1.505218276923077 ], [ 29.730389482758621, -1.5049488 ], [ 29.73012, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6641, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.5049488 ], [ 29.730389482758621, -1.505218276923077 ], [ 29.730658965517243, -1.505218276923077 ], [ 29.730658965517243, -1.5049488 ], [ 29.730389482758621, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6642, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.5049488 ], [ 29.730658965517243, -1.505218276923077 ], [ 29.730928448275861, -1.505218276923077 ], [ 29.730928448275861, -1.5049488 ], [ 29.730658965517243, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6643, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.5049488 ], [ 29.730928448275861, -1.505487753846154 ], [ 29.731197931034483, -1.505487753846154 ], [ 29.731197931034483, -1.5049488 ], [ 29.730928448275861, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6644, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.5049488 ], [ 29.731197931034483, -1.505218276923077 ], [ 29.731467413793105, -1.505218276923077 ], [ 29.731467413793105, -1.5049488 ], [ 29.731197931034483, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6645, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.5049488 ], [ 29.731467413793105, -1.505218276923077 ], [ 29.731736896551723, -1.505218276923077 ], [ 29.731736896551723, -1.5049488 ], [ 29.731467413793105, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6646, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.5049488 ], [ 29.731736896551723, -1.505218276923077 ], [ 29.732006379310345, -1.505218276923077 ], [ 29.732006379310345, -1.5049488 ], [ 29.731736896551723, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6647, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.5049488 ], [ 29.732006379310345, -1.505218276923077 ], [ 29.732275862068967, -1.505218276923077 ], [ 29.732275862068967, -1.5049488 ], [ 29.732006379310345, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6648, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.5049488 ], [ 29.732275862068967, -1.505218276923077 ], [ 29.732545344827585, -1.505218276923077 ], [ 29.732545344827585, -1.5049488 ], [ 29.732275862068967, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6649, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.5049488 ], [ 29.733353793103447, -1.505218276923077 ], [ 29.734431724137931, -1.505218276923077 ], [ 29.734431724137931, -1.5049488 ], [ 29.733353793103447, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6650, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.5049488 ], [ 29.734431724137931, -1.505218276923077 ], [ 29.734701206896553, -1.505218276923077 ], [ 29.734701206896553, -1.5049488 ], [ 29.734431724137931, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6651, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.5049488 ], [ 29.734701206896553, -1.505218276923077 ], [ 29.735240172413793, -1.505218276923077 ], [ 29.735240172413793, -1.5049488 ], [ 29.734701206896553, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6652, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.5049488 ], [ 29.735240172413793, -1.505218276923077 ], [ 29.735509655172415, -1.505218276923077 ], [ 29.735509655172415, -1.5049488 ], [ 29.735240172413793, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6653, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.5049488 ], [ 29.735509655172415, -1.505218276923077 ], [ 29.735779137931036, -1.505218276923077 ], [ 29.735779137931036, -1.5049488 ], [ 29.735509655172415, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6654, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.5049488 ], [ 29.735779137931036, -1.505487753846154 ], [ 29.736048620689655, -1.505487753846154 ], [ 29.736048620689655, -1.5049488 ], [ 29.735779137931036, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6655, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.5049488 ], [ 29.736048620689655, -1.505218276923077 ], [ 29.736318103448276, -1.505218276923077 ], [ 29.736318103448276, -1.5049488 ], [ 29.736048620689655, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6656, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.5049488 ], [ 29.736318103448276, -1.505218276923077 ], [ 29.73739603448276, -1.505218276923077 ], [ 29.73739603448276, -1.5049488 ], [ 29.736318103448276, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6657, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.5049488 ], [ 29.73739603448276, -1.505218276923077 ], [ 29.737665517241378, -1.505218276923077 ], [ 29.737665517241378, -1.5049488 ], [ 29.73739603448276, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6658, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.5049488 ], [ 29.737665517241378, -1.505218276923077 ], [ 29.737935, -1.505218276923077 ], [ 29.737935, -1.5049488 ], [ 29.737665517241378, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6659, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.5049488 ], [ 29.737935, -1.505218276923077 ], [ 29.738204482758622, -1.505218276923077 ], [ 29.738204482758622, -1.5049488 ], [ 29.737935, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6660, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.5049488 ], [ 29.738204482758622, -1.505218276923077 ], [ 29.738743448275862, -1.505218276923077 ], [ 29.738743448275862, -1.5049488 ], [ 29.738204482758622, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6661, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.5049488 ], [ 29.738743448275862, -1.505218276923077 ], [ 29.739012931034484, -1.505218276923077 ], [ 29.739012931034484, -1.5049488 ], [ 29.738743448275862, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6662, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.5049488 ], [ 29.739012931034484, -1.505218276923077 ], [ 29.739282413793102, -1.505218276923077 ], [ 29.739282413793102, -1.5049488 ], [ 29.739012931034484, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6663, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.5049488 ], [ 29.739282413793102, -1.505218276923077 ], [ 29.739551896551724, -1.505218276923077 ], [ 29.739551896551724, -1.5049488 ], [ 29.739282413793102, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6664, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.5049488 ], [ 29.739551896551724, -1.505218276923077 ], [ 29.739821379310346, -1.505218276923077 ], [ 29.739821379310346, -1.5049488 ], [ 29.739551896551724, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6665, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.5049488 ], [ 29.739821379310346, -1.505218276923077 ], [ 29.740090862068964, -1.505218276923077 ], [ 29.740090862068964, -1.5049488 ], [ 29.739821379310346, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6666, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.5049488 ], [ 29.74197724137931, -1.505487753846154 ], [ 29.742246724137932, -1.505487753846154 ], [ 29.742246724137932, -1.5049488 ], [ 29.74197724137931, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6667, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.5049488 ], [ 29.742785689655172, -1.505218276923077 ], [ 29.743055172413793, -1.505218276923077 ], [ 29.743055172413793, -1.5049488 ], [ 29.742785689655172, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6668, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.5049488 ], [ 29.743055172413793, -1.505218276923077 ], [ 29.743594137931034, -1.505218276923077 ], [ 29.743594137931034, -1.5049488 ], [ 29.743055172413793, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6669, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.5049488 ], [ 29.757607241379311, -1.505487753846154 ], [ 29.757876724137933, -1.505487753846154 ], [ 29.757876724137933, -1.5049488 ], [ 29.757607241379311, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6670, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.5049488 ], [ 29.758415689655173, -1.505487753846154 ], [ 29.758685172413795, -1.505487753846154 ], [ 29.758685172413795, -1.5049488 ], [ 29.758415689655173, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6671, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5049488 ], [ 29.758685172413795, -1.505487753846154 ], [ 29.758954655172413, -1.505487753846154 ], [ 29.758954655172413, -1.5049488 ], [ 29.758685172413795, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6672, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.5049488 ], [ 29.759493620689657, -1.505218276923077 ], [ 29.759763103448275, -1.505218276923077 ], [ 29.759763103448275, -1.5049488 ], [ 29.759493620689657, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6673, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.5049488 ], [ 29.759763103448275, -1.505218276923077 ], [ 29.760032586206897, -1.505218276923077 ], [ 29.760032586206897, -1.5049488 ], [ 29.759763103448275, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6674, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.5049488 ], [ 29.760032586206897, -1.505487753846154 ], [ 29.760302068965519, -1.505487753846154 ], [ 29.760302068965519, -1.5049488 ], [ 29.760032586206897, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6675, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.5049488 ], [ 29.760571551724137, -1.505218276923077 ], [ 29.760841034482759, -1.505218276923077 ], [ 29.760841034482759, -1.5049488 ], [ 29.760571551724137, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6676, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5049488 ], [ 29.760841034482759, -1.505218276923077 ], [ 29.761110517241381, -1.505218276923077 ], [ 29.761110517241381, -1.5049488 ], [ 29.760841034482759, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6677, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5049488 ], [ 29.761110517241381, -1.505218276923077 ], [ 29.76138, -1.505218276923077 ], [ 29.76138, -1.5049488 ], [ 29.761110517241381, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6678, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5049488 ], [ 29.76138, -1.505218276923077 ], [ 29.761649482758621, -1.505218276923077 ], [ 29.761649482758621, -1.5049488 ], [ 29.76138, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6679, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.5049488 ], [ 29.761649482758621, -1.505218276923077 ], [ 29.761918965517243, -1.505218276923077 ], [ 29.761918965517243, -1.5049488 ], [ 29.761649482758621, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6680, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5049488 ], [ 29.761918965517243, -1.505218276923077 ], [ 29.762188448275861, -1.505218276923077 ], [ 29.762188448275861, -1.5049488 ], [ 29.761918965517243, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6681, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5049488 ], [ 29.762188448275861, -1.505218276923077 ], [ 29.762457931034483, -1.505218276923077 ], [ 29.762457931034483, -1.5049488 ], [ 29.762188448275861, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6682, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5049488 ], [ 29.762457931034483, -1.505218276923077 ], [ 29.762727413793105, -1.505218276923077 ], [ 29.762727413793105, -1.5049488 ], [ 29.762457931034483, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6683, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5049488 ], [ 29.762727413793105, -1.505218276923077 ], [ 29.762996896551723, -1.505218276923077 ], [ 29.762996896551723, -1.5049488 ], [ 29.762727413793105, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6684, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.5049488 ], [ 29.762996896551723, -1.505218276923077 ], [ 29.763266379310345, -1.505218276923077 ], [ 29.763266379310345, -1.5049488 ], [ 29.762996896551723, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6685, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5049488 ], [ 29.763266379310345, -1.505218276923077 ], [ 29.763535862068967, -1.505218276923077 ], [ 29.763535862068967, -1.5049488 ], [ 29.763266379310345, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6686, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5049488 ], [ 29.763535862068967, -1.505487753846154 ], [ 29.763805344827588, -1.505487753846154 ], [ 29.763805344827588, -1.5049488 ], [ 29.763535862068967, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6687, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.5049488 ], [ 29.764344310344828, -1.505218276923077 ], [ 29.76461379310345, -1.505218276923077 ], [ 29.76461379310345, -1.5049488 ], [ 29.764344310344828, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6688, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.5049488 ], [ 29.76461379310345, -1.505218276923077 ], [ 29.764883275862069, -1.505218276923077 ], [ 29.764883275862069, -1.5049488 ], [ 29.76461379310345, -1.5049488 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6689, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.505218276923077 ], [ 29.722305, -1.505487753846154 ], [ 29.72257448275862, -1.505487753846154 ], [ 29.72257448275862, -1.505218276923077 ], [ 29.722305, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6690, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.505218276923077 ], [ 29.72257448275862, -1.505757230769231 ], [ 29.722843965517242, -1.505757230769231 ], [ 29.722843965517242, -1.505218276923077 ], [ 29.72257448275862, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6691, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.505218276923077 ], [ 29.722843965517242, -1.505757230769231 ], [ 29.723113448275861, -1.505757230769231 ], [ 29.723113448275861, -1.505218276923077 ], [ 29.722843965517242, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6692, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.505218276923077 ], [ 29.723113448275861, -1.505757230769231 ], [ 29.723382931034482, -1.505757230769231 ], [ 29.723382931034482, -1.505218276923077 ], [ 29.723113448275861, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6693, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.505218276923077 ], [ 29.723382931034482, -1.505487753846154 ], [ 29.723652413793104, -1.505487753846154 ], [ 29.723652413793104, -1.505218276923077 ], [ 29.723382931034482, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6694, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.505218276923077 ], [ 29.723652413793104, -1.505487753846154 ], [ 29.723921896551722, -1.505487753846154 ], [ 29.723921896551722, -1.505218276923077 ], [ 29.723652413793104, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6695, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.505218276923077 ], [ 29.723921896551722, -1.505487753846154 ], [ 29.724191379310344, -1.505487753846154 ], [ 29.724191379310344, -1.505218276923077 ], [ 29.723921896551722, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6696, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.505218276923077 ], [ 29.724730344827588, -1.505757230769231 ], [ 29.724999827586206, -1.505757230769231 ], [ 29.724999827586206, -1.505218276923077 ], [ 29.724730344827588, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6697, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.505218276923077 ], [ 29.724999827586206, -1.505487753846154 ], [ 29.725269310344828, -1.505487753846154 ], [ 29.725269310344828, -1.505218276923077 ], [ 29.724999827586206, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6698, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.505218276923077 ], [ 29.725269310344828, -1.505487753846154 ], [ 29.72553879310345, -1.505487753846154 ], [ 29.72553879310345, -1.505218276923077 ], [ 29.725269310344828, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6699, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.505218276923077 ], [ 29.725808275862068, -1.505757230769231 ], [ 29.72607775862069, -1.505757230769231 ], [ 29.72607775862069, -1.505218276923077 ], [ 29.725808275862068, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6700, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.505218276923077 ], [ 29.72607775862069, -1.505487753846154 ], [ 29.726347241379312, -1.505487753846154 ], [ 29.726347241379312, -1.505218276923077 ], [ 29.72607775862069, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6701, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.505218276923077 ], [ 29.726347241379312, -1.505487753846154 ], [ 29.72661672413793, -1.505487753846154 ], [ 29.72661672413793, -1.505218276923077 ], [ 29.726347241379312, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6702, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.505218276923077 ], [ 29.726886206896552, -1.505487753846154 ], [ 29.727155689655174, -1.505487753846154 ], [ 29.727155689655174, -1.505218276923077 ], [ 29.726886206896552, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6703, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.505218276923077 ], [ 29.728233620689654, -1.506026707692308 ], [ 29.728503103448276, -1.506026707692308 ], [ 29.728503103448276, -1.505218276923077 ], [ 29.728233620689654, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6704, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.505218276923077 ], [ 29.729042068965516, -1.506296184615385 ], [ 29.729311551724138, -1.506296184615385 ], [ 29.729311551724138, -1.505218276923077 ], [ 29.729042068965516, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6705, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.505218276923077 ], [ 29.73012, -1.506565661538461 ], [ 29.730389482758621, -1.506565661538461 ], [ 29.730389482758621, -1.505218276923077 ], [ 29.73012, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6706, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.505218276923077 ], [ 29.730389482758621, -1.506565661538461 ], [ 29.730658965517243, -1.506565661538461 ], [ 29.730658965517243, -1.505218276923077 ], [ 29.730389482758621, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6707, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.505218276923077 ], [ 29.730658965517243, -1.506835138461538 ], [ 29.730928448275861, -1.506835138461538 ], [ 29.730928448275861, -1.505218276923077 ], [ 29.730658965517243, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6708, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.505218276923077 ], [ 29.731197931034483, -1.507104615384615 ], [ 29.731467413793105, -1.507104615384615 ], [ 29.731467413793105, -1.505218276923077 ], [ 29.731197931034483, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6709, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.505218276923077 ], [ 29.731467413793105, -1.507104615384615 ], [ 29.731736896551723, -1.507104615384615 ], [ 29.731736896551723, -1.505218276923077 ], [ 29.731467413793105, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6710, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.505218276923077 ], [ 29.731736896551723, -1.505487753846154 ], [ 29.732006379310345, -1.505487753846154 ], [ 29.732006379310345, -1.505218276923077 ], [ 29.731736896551723, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6711, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.505218276923077 ], [ 29.732006379310345, -1.505487753846154 ], [ 29.732275862068967, -1.505487753846154 ], [ 29.732275862068967, -1.505218276923077 ], [ 29.732006379310345, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6712, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.505218276923077 ], [ 29.734431724137931, -1.505487753846154 ], [ 29.734970689655171, -1.505487753846154 ], [ 29.734970689655171, -1.505218276923077 ], [ 29.734431724137931, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6713, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.505218276923077 ], [ 29.734970689655171, -1.505487753846154 ], [ 29.735240172413793, -1.505487753846154 ], [ 29.735240172413793, -1.505218276923077 ], [ 29.734970689655171, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6714, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.505218276923077 ], [ 29.735240172413793, -1.505757230769231 ], [ 29.735509655172415, -1.505757230769231 ], [ 29.735509655172415, -1.505218276923077 ], [ 29.735240172413793, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6715, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.505218276923077 ], [ 29.735509655172415, -1.505757230769231 ], [ 29.735779137931036, -1.505757230769231 ], [ 29.735779137931036, -1.505218276923077 ], [ 29.735509655172415, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6716, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.505218276923077 ], [ 29.736048620689655, -1.505487753846154 ], [ 29.73739603448276, -1.505487753846154 ], [ 29.73739603448276, -1.505218276923077 ], [ 29.736048620689655, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6717, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.505218276923077 ], [ 29.73739603448276, -1.505487753846154 ], [ 29.737665517241378, -1.505487753846154 ], [ 29.737665517241378, -1.505218276923077 ], [ 29.73739603448276, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6718, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.505218276923077 ], [ 29.737665517241378, -1.505487753846154 ], [ 29.737935, -1.505487753846154 ], [ 29.737935, -1.505218276923077 ], [ 29.737665517241378, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6719, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.505218276923077 ], [ 29.737935, -1.505487753846154 ], [ 29.738204482758622, -1.505487753846154 ], [ 29.738204482758622, -1.505218276923077 ], [ 29.737935, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6720, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.505218276923077 ], [ 29.738204482758622, -1.505487753846154 ], [ 29.738743448275862, -1.505487753846154 ], [ 29.738743448275862, -1.505218276923077 ], [ 29.738204482758622, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6721, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.505218276923077 ], [ 29.738743448275862, -1.505487753846154 ], [ 29.739012931034484, -1.505487753846154 ], [ 29.739012931034484, -1.505218276923077 ], [ 29.738743448275862, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6722, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.505218276923077 ], [ 29.739012931034484, -1.505487753846154 ], [ 29.739282413793102, -1.505487753846154 ], [ 29.739282413793102, -1.505218276923077 ], [ 29.739012931034484, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6723, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.505218276923077 ], [ 29.739282413793102, -1.505487753846154 ], [ 29.739551896551724, -1.505487753846154 ], [ 29.739551896551724, -1.505218276923077 ], [ 29.739282413793102, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6724, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.505218276923077 ], [ 29.739551896551724, -1.505487753846154 ], [ 29.739821379310346, -1.505487753846154 ], [ 29.739821379310346, -1.505218276923077 ], [ 29.739551896551724, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6725, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.503601415384615 ], [ 29.740360344827586, -1.503601415384615 ], [ 29.740360344827586, -1.505487753846154 ], [ 29.739821379310346, -1.505487753846154 ], [ 29.739821379310346, -1.505218276923077 ], [ 29.740090862068964, -1.505218276923077 ], [ 29.740090862068964, -1.503601415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6726, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.505218276923077 ], [ 29.740360344827586, -1.505487753846154 ], [ 29.740629827586208, -1.505487753846154 ], [ 29.740629827586208, -1.505218276923077 ], [ 29.740360344827586, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6727, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.505218276923077 ], [ 29.741168793103448, -1.505757230769231 ], [ 29.74143827586207, -1.505757230769231 ], [ 29.74143827586207, -1.505218276923077 ], [ 29.741168793103448, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6728, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.505218276923077 ], [ 29.74143827586207, -1.505757230769231 ], [ 29.741707758620691, -1.505757230769231 ], [ 29.741707758620691, -1.505218276923077 ], [ 29.74143827586207, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6729, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.505218276923077 ], [ 29.741707758620691, -1.505757230769231 ], [ 29.74197724137931, -1.505757230769231 ], [ 29.74197724137931, -1.505218276923077 ], [ 29.741707758620691, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6730, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.505218276923077 ], [ 29.742246724137932, -1.505487753846154 ], [ 29.742516206896553, -1.505487753846154 ], [ 29.742516206896553, -1.505218276923077 ], [ 29.742246724137932, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6731, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.505218276923077 ], [ 29.742516206896553, -1.505487753846154 ], [ 29.742785689655172, -1.505487753846154 ], [ 29.742785689655172, -1.505218276923077 ], [ 29.742516206896553, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6732, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.505218276923077 ], [ 29.742785689655172, -1.505487753846154 ], [ 29.743055172413793, -1.505487753846154 ], [ 29.743055172413793, -1.505757230769231 ], [ 29.743324655172415, -1.505757230769231 ], [ 29.743324655172415, -1.505218276923077 ], [ 29.742785689655172, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6733, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.505218276923077 ], [ 29.743324655172415, -1.505757230769231 ], [ 29.743594137931034, -1.505757230769231 ], [ 29.743594137931034, -1.505218276923077 ], [ 29.743324655172415, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6734, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.505218276923077 ], [ 29.743594137931034, -1.505757230769231 ], [ 29.743863620689655, -1.505757230769231 ], [ 29.743863620689655, -1.505218276923077 ], [ 29.743594137931034, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6735, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.505218276923077 ], [ 29.757876724137933, -1.505487753846154 ], [ 29.758146206896551, -1.505487753846154 ], [ 29.758146206896551, -1.505218276923077 ], [ 29.757876724137933, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6736, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.505218276923077 ], [ 29.758146206896551, -1.505757230769231 ], [ 29.758415689655173, -1.505757230769231 ], [ 29.758415689655173, -1.505218276923077 ], [ 29.758146206896551, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6737, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.505218276923077 ], [ 29.758954655172413, -1.505487753846154 ], [ 29.759224137931035, -1.505487753846154 ], [ 29.759224137931035, -1.505218276923077 ], [ 29.758954655172413, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6738, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.505218276923077 ], [ 29.759224137931035, -1.505487753846154 ], [ 29.759493620689657, -1.505487753846154 ], [ 29.759493620689657, -1.505218276923077 ], [ 29.759224137931035, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6739, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.505218276923077 ], [ 29.759493620689657, -1.505487753846154 ], [ 29.759763103448275, -1.505487753846154 ], [ 29.759763103448275, -1.505218276923077 ], [ 29.759493620689657, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6740, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.505218276923077 ], [ 29.759763103448275, -1.505757230769231 ], [ 29.760032586206897, -1.505757230769231 ], [ 29.760032586206897, -1.505218276923077 ], [ 29.759763103448275, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6741, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.505218276923077 ], [ 29.760302068965519, -1.505487753846154 ], [ 29.760571551724137, -1.505487753846154 ], [ 29.760571551724137, -1.505218276923077 ], [ 29.760302068965519, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6742, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.505218276923077 ], [ 29.760571551724137, -1.505487753846154 ], [ 29.760841034482759, -1.505487753846154 ], [ 29.760841034482759, -1.505218276923077 ], [ 29.760571551724137, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6743, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.505218276923077 ], [ 29.760841034482759, -1.505487753846154 ], [ 29.761110517241381, -1.505487753846154 ], [ 29.761110517241381, -1.505218276923077 ], [ 29.760841034482759, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6744, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.505218276923077 ], [ 29.761110517241381, -1.505487753846154 ], [ 29.76138, -1.505487753846154 ], [ 29.76138, -1.505218276923077 ], [ 29.761110517241381, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6745, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.505218276923077 ], [ 29.76138, -1.505487753846154 ], [ 29.761649482758621, -1.505487753846154 ], [ 29.761649482758621, -1.505218276923077 ], [ 29.76138, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6746, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.505218276923077 ], [ 29.761649482758621, -1.505487753846154 ], [ 29.761918965517243, -1.505487753846154 ], [ 29.761918965517243, -1.505218276923077 ], [ 29.761649482758621, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6747, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.505218276923077 ], [ 29.761918965517243, -1.505487753846154 ], [ 29.762188448275861, -1.505487753846154 ], [ 29.762188448275861, -1.505218276923077 ], [ 29.761918965517243, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6748, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.505218276923077 ], [ 29.762188448275861, -1.505487753846154 ], [ 29.762457931034483, -1.505487753846154 ], [ 29.762457931034483, -1.505218276923077 ], [ 29.762188448275861, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6749, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.505218276923077 ], [ 29.762457931034483, -1.505487753846154 ], [ 29.762727413793105, -1.505487753846154 ], [ 29.762727413793105, -1.505218276923077 ], [ 29.762457931034483, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6750, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.505218276923077 ], [ 29.762727413793105, -1.505487753846154 ], [ 29.762996896551723, -1.505487753846154 ], [ 29.762996896551723, -1.505218276923077 ], [ 29.762727413793105, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6751, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.505218276923077 ], [ 29.762996896551723, -1.505487753846154 ], [ 29.763266379310345, -1.505487753846154 ], [ 29.763266379310345, -1.505218276923077 ], [ 29.762996896551723, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6752, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.505218276923077 ], [ 29.763266379310345, -1.505487753846154 ], [ 29.763535862068967, -1.505487753846154 ], [ 29.763535862068967, -1.505218276923077 ], [ 29.763266379310345, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6753, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.505218276923077 ], [ 29.763805344827588, -1.505487753846154 ], [ 29.764074827586207, -1.505487753846154 ], [ 29.764074827586207, -1.505218276923077 ], [ 29.763805344827588, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6754, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.505218276923077 ], [ 29.764074827586207, -1.506565661538461 ], [ 29.764344310344828, -1.506565661538461 ], [ 29.764344310344828, -1.505218276923077 ], [ 29.764074827586207, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6755, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.505218276923077 ], [ 29.764344310344828, -1.506565661538461 ], [ 29.76461379310345, -1.506565661538461 ], [ 29.76461379310345, -1.505218276923077 ], [ 29.764344310344828, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6756, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.505218276923077 ], [ 29.76461379310345, -1.505757230769231 ], [ 29.764883275862069, -1.505757230769231 ], [ 29.764883275862069, -1.505218276923077 ], [ 29.76461379310345, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6757, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.505218276923077 ], [ 29.764883275862069, -1.505757230769231 ], [ 29.76515275862069, -1.505757230769231 ], [ 29.76515275862069, -1.505218276923077 ], [ 29.764883275862069, -1.505218276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6758, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.505487753846154 ], [ 29.721227068965518, -1.506296184615385 ], [ 29.721496551724137, -1.506296184615385 ], [ 29.721496551724137, -1.505487753846154 ], [ 29.721227068965518, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6759, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.505487753846154 ], [ 29.721496551724137, -1.505757230769231 ], [ 29.721766034482759, -1.505757230769231 ], [ 29.721766034482759, -1.505487753846154 ], [ 29.721496551724137, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6760, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.505487753846154 ], [ 29.722305, -1.506026707692308 ], [ 29.72257448275862, -1.506026707692308 ], [ 29.72257448275862, -1.505487753846154 ], [ 29.722305, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6761, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.505487753846154 ], [ 29.723382931034482, -1.506026707692308 ], [ 29.723652413793104, -1.506026707692308 ], [ 29.723652413793104, -1.505487753846154 ], [ 29.723382931034482, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6762, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.505487753846154 ], [ 29.723652413793104, -1.506026707692308 ], [ 29.723921896551722, -1.506026707692308 ], [ 29.723921896551722, -1.505487753846154 ], [ 29.723652413793104, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6763, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.505487753846154 ], [ 29.723921896551722, -1.505757230769231 ], [ 29.724191379310344, -1.505757230769231 ], [ 29.724191379310344, -1.505487753846154 ], [ 29.723921896551722, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6764, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.505487753846154 ], [ 29.724191379310344, -1.505757230769231 ], [ 29.724460862068966, -1.505757230769231 ], [ 29.724460862068966, -1.505487753846154 ], [ 29.724191379310344, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6765, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.505487753846154 ], [ 29.724460862068966, -1.505757230769231 ], [ 29.724730344827588, -1.505757230769231 ], [ 29.724730344827588, -1.505487753846154 ], [ 29.724460862068966, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6766, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.505487753846154 ], [ 29.724999827586206, -1.506026707692308 ], [ 29.725269310344828, -1.506026707692308 ], [ 29.725269310344828, -1.505487753846154 ], [ 29.724999827586206, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6767, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.505487753846154 ], [ 29.725269310344828, -1.505757230769231 ], [ 29.72553879310345, -1.505757230769231 ], [ 29.72553879310345, -1.505487753846154 ], [ 29.725269310344828, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6768, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.505487753846154 ], [ 29.72553879310345, -1.505757230769231 ], [ 29.725808275862068, -1.505757230769231 ], [ 29.725808275862068, -1.505487753846154 ], [ 29.72553879310345, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6769, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.505487753846154 ], [ 29.72607775862069, -1.506026707692308 ], [ 29.726347241379312, -1.506026707692308 ], [ 29.726347241379312, -1.505487753846154 ], [ 29.72607775862069, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6770, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.505487753846154 ], [ 29.726347241379312, -1.506026707692308 ], [ 29.72661672413793, -1.506026707692308 ], [ 29.72661672413793, -1.505487753846154 ], [ 29.726347241379312, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6771, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.505487753846154 ], [ 29.72661672413793, -1.505757230769231 ], [ 29.726886206896552, -1.505757230769231 ], [ 29.726886206896552, -1.505487753846154 ], [ 29.72661672413793, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6772, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.505487753846154 ], [ 29.726886206896552, -1.505757230769231 ], [ 29.727155689655174, -1.505757230769231 ], [ 29.727155689655174, -1.505487753846154 ], [ 29.726886206896552, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6773, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.505487753846154 ], [ 29.727155689655174, -1.505757230769231 ], [ 29.727425172413792, -1.505757230769231 ], [ 29.727425172413792, -1.505487753846154 ], [ 29.727155689655174, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6774, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.505487753846154 ], [ 29.727425172413792, -1.505757230769231 ], [ 29.727694655172414, -1.505757230769231 ], [ 29.727694655172414, -1.505487753846154 ], [ 29.727425172413792, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6775, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.505487753846154 ], [ 29.730928448275861, -1.506835138461538 ], [ 29.731197931034483, -1.506835138461538 ], [ 29.731197931034483, -1.505487753846154 ], [ 29.730928448275861, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6776, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.505487753846154 ], [ 29.734970689655171, -1.506026707692308 ], [ 29.735240172413793, -1.506026707692308 ], [ 29.735240172413793, -1.505487753846154 ], [ 29.734970689655171, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6777, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.505487753846154 ], [ 29.735779137931036, -1.505757230769231 ], [ 29.736048620689655, -1.505757230769231 ], [ 29.736048620689655, -1.505487753846154 ], [ 29.735779137931036, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6778, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.505487753846154 ], [ 29.736048620689655, -1.505757230769231 ], [ 29.737126551724138, -1.505757230769231 ], [ 29.737126551724138, -1.505487753846154 ], [ 29.736048620689655, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6779, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.505487753846154 ], [ 29.737126551724138, -1.505757230769231 ], [ 29.737665517241378, -1.505757230769231 ], [ 29.737665517241378, -1.505487753846154 ], [ 29.737126551724138, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6780, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.505487753846154 ], [ 29.737665517241378, -1.505757230769231 ], [ 29.737935, -1.505757230769231 ], [ 29.737935, -1.505487753846154 ], [ 29.737665517241378, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6781, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.505487753846154 ], [ 29.737935, -1.505757230769231 ], [ 29.738204482758622, -1.505757230769231 ], [ 29.738204482758622, -1.505487753846154 ], [ 29.737935, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6782, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.505487753846154 ], [ 29.738204482758622, -1.505757230769231 ], [ 29.738743448275862, -1.505757230769231 ], [ 29.738743448275862, -1.505487753846154 ], [ 29.738204482758622, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6783, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.505487753846154 ], [ 29.738743448275862, -1.505757230769231 ], [ 29.739012931034484, -1.505757230769231 ], [ 29.739012931034484, -1.505487753846154 ], [ 29.738743448275862, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6784, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.505487753846154 ], [ 29.739012931034484, -1.505757230769231 ], [ 29.739282413793102, -1.505757230769231 ], [ 29.739282413793102, -1.505487753846154 ], [ 29.739012931034484, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6785, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.505487753846154 ], [ 29.739282413793102, -1.505757230769231 ], [ 29.739551896551724, -1.505757230769231 ], [ 29.739551896551724, -1.505487753846154 ], [ 29.739282413793102, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6786, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.505487753846154 ], [ 29.739551896551724, -1.505757230769231 ], [ 29.739821379310346, -1.505757230769231 ], [ 29.739821379310346, -1.505487753846154 ], [ 29.739551896551724, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6787, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.505487753846154 ], [ 29.739821379310346, -1.505757230769231 ], [ 29.740360344827586, -1.505757230769231 ], [ 29.740360344827586, -1.505487753846154 ], [ 29.739821379310346, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6788, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.505487753846154 ], [ 29.740360344827586, -1.505757230769231 ], [ 29.740629827586208, -1.505757230769231 ], [ 29.740629827586208, -1.505487753846154 ], [ 29.740360344827586, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6789, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.505487753846154 ], [ 29.74197724137931, -1.505757230769231 ], [ 29.742246724137932, -1.505757230769231 ], [ 29.742246724137932, -1.505487753846154 ], [ 29.74197724137931, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6790, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.505487753846154 ], [ 29.742246724137932, -1.505757230769231 ], [ 29.742516206896553, -1.505757230769231 ], [ 29.742516206896553, -1.505487753846154 ], [ 29.742246724137932, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6791, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.505487753846154 ], [ 29.742516206896553, -1.505757230769231 ], [ 29.742785689655172, -1.505757230769231 ], [ 29.742785689655172, -1.505487753846154 ], [ 29.742516206896553, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6792, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.505487753846154 ], [ 29.742785689655172, -1.505757230769231 ], [ 29.743055172413793, -1.505757230769231 ], [ 29.743055172413793, -1.505487753846154 ], [ 29.742785689655172, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6793, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.505487753846154 ], [ 29.757607241379311, -1.505757230769231 ], [ 29.757876724137933, -1.505757230769231 ], [ 29.757876724137933, -1.505487753846154 ], [ 29.757607241379311, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6794, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.505487753846154 ], [ 29.757876724137933, -1.506296184615385 ], [ 29.758146206896551, -1.506296184615385 ], [ 29.758146206896551, -1.505487753846154 ], [ 29.757876724137933, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6795, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.505487753846154 ], [ 29.758415689655173, -1.505757230769231 ], [ 29.758685172413795, -1.505757230769231 ], [ 29.758685172413795, -1.505487753846154 ], [ 29.758415689655173, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6796, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.505487753846154 ], [ 29.758685172413795, -1.505757230769231 ], [ 29.758954655172413, -1.505757230769231 ], [ 29.758954655172413, -1.505487753846154 ], [ 29.758685172413795, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6797, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.505487753846154 ], [ 29.758954655172413, -1.505757230769231 ], [ 29.759224137931035, -1.505757230769231 ], [ 29.759224137931035, -1.505487753846154 ], [ 29.758954655172413, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6798, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.505487753846154 ], [ 29.759224137931035, -1.505757230769231 ], [ 29.759493620689657, -1.505757230769231 ], [ 29.759493620689657, -1.505487753846154 ], [ 29.759224137931035, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6799, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.505487753846154 ], [ 29.759493620689657, -1.505757230769231 ], [ 29.759763103448275, -1.505757230769231 ], [ 29.759763103448275, -1.505487753846154 ], [ 29.759493620689657, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6800, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.505487753846154 ], [ 29.760032586206897, -1.505757230769231 ], [ 29.760302068965519, -1.505757230769231 ], [ 29.760302068965519, -1.505487753846154 ], [ 29.760032586206897, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6801, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.505487753846154 ], [ 29.760302068965519, -1.505757230769231 ], [ 29.760571551724137, -1.505757230769231 ], [ 29.760571551724137, -1.505487753846154 ], [ 29.760302068965519, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6802, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.505487753846154 ], [ 29.760571551724137, -1.505757230769231 ], [ 29.760841034482759, -1.505757230769231 ], [ 29.760841034482759, -1.505487753846154 ], [ 29.760571551724137, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6803, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.505487753846154 ], [ 29.760841034482759, -1.505757230769231 ], [ 29.761110517241381, -1.505757230769231 ], [ 29.761110517241381, -1.505487753846154 ], [ 29.760841034482759, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6804, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.505487753846154 ], [ 29.761110517241381, -1.505757230769231 ], [ 29.76138, -1.505757230769231 ], [ 29.76138, -1.505487753846154 ], [ 29.761110517241381, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6805, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.505487753846154 ], [ 29.76138, -1.505757230769231 ], [ 29.761649482758621, -1.505757230769231 ], [ 29.761649482758621, -1.505487753846154 ], [ 29.76138, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6806, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.505487753846154 ], [ 29.761649482758621, -1.505757230769231 ], [ 29.761918965517243, -1.505757230769231 ], [ 29.761918965517243, -1.505487753846154 ], [ 29.761649482758621, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6807, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.505487753846154 ], [ 29.761918965517243, -1.505757230769231 ], [ 29.762188448275861, -1.505757230769231 ], [ 29.762188448275861, -1.505487753846154 ], [ 29.761918965517243, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6808, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.505487753846154 ], [ 29.762188448275861, -1.505757230769231 ], [ 29.762457931034483, -1.505757230769231 ], [ 29.762457931034483, -1.505487753846154 ], [ 29.762188448275861, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6809, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.505487753846154 ], [ 29.762457931034483, -1.505757230769231 ], [ 29.762727413793105, -1.505757230769231 ], [ 29.762727413793105, -1.505487753846154 ], [ 29.762457931034483, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6810, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.505487753846154 ], [ 29.762727413793105, -1.505757230769231 ], [ 29.762996896551723, -1.505757230769231 ], [ 29.762996896551723, -1.505487753846154 ], [ 29.762727413793105, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6811, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.505487753846154 ], [ 29.762996896551723, -1.506565661538461 ], [ 29.763266379310345, -1.506565661538461 ], [ 29.763266379310345, -1.505487753846154 ], [ 29.762996896551723, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6812, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.505487753846154 ], [ 29.763266379310345, -1.506565661538461 ], [ 29.763535862068967, -1.506565661538461 ], [ 29.763535862068967, -1.505487753846154 ], [ 29.763266379310345, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6813, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.505487753846154 ], [ 29.763535862068967, -1.506565661538461 ], [ 29.763805344827588, -1.506565661538461 ], [ 29.763805344827588, -1.505487753846154 ], [ 29.763535862068967, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6814, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.505487753846154 ], [ 29.763805344827588, -1.505757230769231 ], [ 29.764074827586207, -1.505757230769231 ], [ 29.764074827586207, -1.505487753846154 ], [ 29.763805344827588, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6815, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.505757230769231 ], [ 29.721496551724137, -1.506565661538461 ], [ 29.721766034482759, -1.506565661538461 ], [ 29.721766034482759, -1.505757230769231 ], [ 29.721496551724137, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6816, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.505757230769231 ], [ 29.721766034482759, -1.506565661538461 ], [ 29.72203551724138, -1.506565661538461 ], [ 29.72203551724138, -1.505757230769231 ], [ 29.721766034482759, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6817, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.505757230769231 ], [ 29.72203551724138, -1.506026707692308 ], [ 29.722305, -1.506026707692308 ], [ 29.722305, -1.505757230769231 ], [ 29.72203551724138, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6818, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.505757230769231 ], [ 29.72257448275862, -1.506026707692308 ], [ 29.722843965517242, -1.506026707692308 ], [ 29.722843965517242, -1.505757230769231 ], [ 29.72257448275862, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6819, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.505757230769231 ], [ 29.722843965517242, -1.506026707692308 ], [ 29.723113448275861, -1.506026707692308 ], [ 29.723113448275861, -1.505757230769231 ], [ 29.722843965517242, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6820, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.505757230769231 ], [ 29.723113448275861, -1.506026707692308 ], [ 29.723382931034482, -1.506026707692308 ], [ 29.723382931034482, -1.505757230769231 ], [ 29.723113448275861, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6821, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.505757230769231 ], [ 29.723921896551722, -1.506296184615385 ], [ 29.724191379310344, -1.506296184615385 ], [ 29.724191379310344, -1.505757230769231 ], [ 29.723921896551722, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6822, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.505757230769231 ], [ 29.724191379310344, -1.506296184615385 ], [ 29.724460862068966, -1.506296184615385 ], [ 29.724460862068966, -1.505757230769231 ], [ 29.724191379310344, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6823, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.505757230769231 ], [ 29.724460862068966, -1.506026707692308 ], [ 29.724730344827588, -1.506026707692308 ], [ 29.724730344827588, -1.505757230769231 ], [ 29.724460862068966, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6824, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.505757230769231 ], [ 29.724730344827588, -1.506026707692308 ], [ 29.724999827586206, -1.506026707692308 ], [ 29.724999827586206, -1.505757230769231 ], [ 29.724730344827588, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6825, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.505757230769231 ], [ 29.725269310344828, -1.506296184615385 ], [ 29.72553879310345, -1.506296184615385 ], [ 29.72553879310345, -1.505757230769231 ], [ 29.725269310344828, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6826, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.505757230769231 ], [ 29.72553879310345, -1.506026707692308 ], [ 29.725808275862068, -1.506026707692308 ], [ 29.725808275862068, -1.505757230769231 ], [ 29.72553879310345, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6827, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.505757230769231 ], [ 29.725808275862068, -1.506026707692308 ], [ 29.72607775862069, -1.506026707692308 ], [ 29.72607775862069, -1.505757230769231 ], [ 29.725808275862068, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6828, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.505757230769231 ], [ 29.72661672413793, -1.506026707692308 ], [ 29.726886206896552, -1.506026707692308 ], [ 29.726886206896552, -1.505757230769231 ], [ 29.72661672413793, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6829, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.505757230769231 ], [ 29.726886206896552, -1.506026707692308 ], [ 29.727155689655174, -1.506026707692308 ], [ 29.727155689655174, -1.505757230769231 ], [ 29.726886206896552, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6830, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.505757230769231 ], [ 29.727155689655174, -1.506026707692308 ], [ 29.727425172413792, -1.506026707692308 ], [ 29.727425172413792, -1.505757230769231 ], [ 29.727155689655174, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6831, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.505757230769231 ], [ 29.727425172413792, -1.506026707692308 ], [ 29.727694655172414, -1.506026707692308 ], [ 29.727694655172414, -1.505757230769231 ], [ 29.727425172413792, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6832, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.505757230769231 ], [ 29.727694655172414, -1.506026707692308 ], [ 29.727964137931036, -1.506026707692308 ], [ 29.727964137931036, -1.505757230769231 ], [ 29.727694655172414, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6833, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.505757230769231 ], [ 29.727964137931036, -1.506026707692308 ], [ 29.728233620689654, -1.506026707692308 ], [ 29.728233620689654, -1.505757230769231 ], [ 29.727964137931036, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6834, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.505757230769231 ], [ 29.735240172413793, -1.506026707692308 ], [ 29.735509655172415, -1.506026707692308 ], [ 29.735509655172415, -1.505757230769231 ], [ 29.735240172413793, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6835, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.505757230769231 ], [ 29.735509655172415, -1.506296184615385 ], [ 29.73739603448276, -1.506296184615385 ], [ 29.73739603448276, -1.506026707692308 ], [ 29.735779137931036, -1.506026707692308 ], [ 29.735779137931036, -1.505757230769231 ], [ 29.735509655172415, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6836, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.505757230769231 ], [ 29.735779137931036, -1.506026707692308 ], [ 29.73739603448276, -1.506026707692308 ], [ 29.73739603448276, -1.505757230769231 ], [ 29.735779137931036, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6837, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.505757230769231 ], [ 29.73739603448276, -1.506026707692308 ], [ 29.737665517241378, -1.506026707692308 ], [ 29.737665517241378, -1.505757230769231 ], [ 29.73739603448276, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6838, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.505757230769231 ], [ 29.737665517241378, -1.506026707692308 ], [ 29.737935, -1.506026707692308 ], [ 29.737935, -1.505757230769231 ], [ 29.737665517241378, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6839, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.505757230769231 ], [ 29.737935, -1.506026707692308 ], [ 29.738204482758622, -1.506026707692308 ], [ 29.738204482758622, -1.505757230769231 ], [ 29.737935, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6840, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.505757230769231 ], [ 29.738204482758622, -1.506026707692308 ], [ 29.738743448275862, -1.506026707692308 ], [ 29.738743448275862, -1.505757230769231 ], [ 29.738204482758622, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6841, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.505757230769231 ], [ 29.738743448275862, -1.506026707692308 ], [ 29.739012931034484, -1.506026707692308 ], [ 29.739012931034484, -1.505757230769231 ], [ 29.738743448275862, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6842, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.505757230769231 ], [ 29.739012931034484, -1.506026707692308 ], [ 29.739282413793102, -1.506026707692308 ], [ 29.739282413793102, -1.505757230769231 ], [ 29.739012931034484, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6843, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.505757230769231 ], [ 29.739282413793102, -1.506026707692308 ], [ 29.739551896551724, -1.506026707692308 ], [ 29.739551896551724, -1.505757230769231 ], [ 29.739282413793102, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6844, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.505757230769231 ], [ 29.739551896551724, -1.506026707692308 ], [ 29.739821379310346, -1.506026707692308 ], [ 29.739821379310346, -1.505757230769231 ], [ 29.739551896551724, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6845, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.505757230769231 ], [ 29.739821379310346, -1.506026707692308 ], [ 29.740360344827586, -1.506026707692308 ], [ 29.740360344827586, -1.505757230769231 ], [ 29.739821379310346, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6846, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.505757230769231 ], [ 29.740360344827586, -1.506026707692308 ], [ 29.740629827586208, -1.506026707692308 ], [ 29.740629827586208, -1.505757230769231 ], [ 29.740360344827586, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6847, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.505757230769231 ], [ 29.740629827586208, -1.506026707692308 ], [ 29.74089931034483, -1.506026707692308 ], [ 29.74089931034483, -1.505757230769231 ], [ 29.740629827586208, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6848, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.505757230769231 ], [ 29.74089931034483, -1.506026707692308 ], [ 29.741168793103448, -1.506026707692308 ], [ 29.741168793103448, -1.505757230769231 ], [ 29.74089931034483, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6849, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.505757230769231 ], [ 29.741168793103448, -1.506026707692308 ], [ 29.74143827586207, -1.506026707692308 ], [ 29.74143827586207, -1.505757230769231 ], [ 29.741168793103448, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6850, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.505757230769231 ], [ 29.74143827586207, -1.506026707692308 ], [ 29.741707758620691, -1.506026707692308 ], [ 29.741707758620691, -1.505757230769231 ], [ 29.74143827586207, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6851, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.505757230769231 ], [ 29.741707758620691, -1.506026707692308 ], [ 29.74197724137931, -1.506026707692308 ], [ 29.74197724137931, -1.505757230769231 ], [ 29.741707758620691, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6852, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.505757230769231 ], [ 29.74197724137931, -1.506026707692308 ], [ 29.742246724137932, -1.506026707692308 ], [ 29.742246724137932, -1.505757230769231 ], [ 29.74197724137931, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6853, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.505757230769231 ], [ 29.742246724137932, -1.506026707692308 ], [ 29.742516206896553, -1.506026707692308 ], [ 29.742516206896553, -1.505757230769231 ], [ 29.742246724137932, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6854, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.505757230769231 ], [ 29.742516206896553, -1.506026707692308 ], [ 29.742785689655172, -1.506026707692308 ], [ 29.742785689655172, -1.505757230769231 ], [ 29.742516206896553, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6855, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.505757230769231 ], [ 29.742785689655172, -1.506026707692308 ], [ 29.743055172413793, -1.506026707692308 ], [ 29.743055172413793, -1.505757230769231 ], [ 29.742785689655172, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6856, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.505757230769231 ], [ 29.743055172413793, -1.506026707692308 ], [ 29.743324655172415, -1.506026707692308 ], [ 29.743324655172415, -1.505757230769231 ], [ 29.743055172413793, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6857, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.505757230769231 ], [ 29.743324655172415, -1.506026707692308 ], [ 29.743594137931034, -1.506026707692308 ], [ 29.743594137931034, -1.505757230769231 ], [ 29.743324655172415, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6858, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.505757230769231 ], [ 29.757607241379311, -1.506026707692308 ], [ 29.757876724137933, -1.506026707692308 ], [ 29.757876724137933, -1.505757230769231 ], [ 29.757607241379311, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6859, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.505757230769231 ], [ 29.758146206896551, -1.506026707692308 ], [ 29.758415689655173, -1.506026707692308 ], [ 29.758415689655173, -1.505757230769231 ], [ 29.758146206896551, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6860, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.505757230769231 ], [ 29.758415689655173, -1.506026707692308 ], [ 29.758685172413795, -1.506026707692308 ], [ 29.758685172413795, -1.505757230769231 ], [ 29.758415689655173, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6861, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.505757230769231 ], [ 29.758685172413795, -1.506026707692308 ], [ 29.758954655172413, -1.506026707692308 ], [ 29.758954655172413, -1.505757230769231 ], [ 29.758685172413795, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6862, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.505757230769231 ], [ 29.758954655172413, -1.506026707692308 ], [ 29.759224137931035, -1.506026707692308 ], [ 29.759224137931035, -1.505757230769231 ], [ 29.758954655172413, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6863, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.505757230769231 ], [ 29.759224137931035, -1.506026707692308 ], [ 29.759493620689657, -1.506026707692308 ], [ 29.759493620689657, -1.505757230769231 ], [ 29.759224137931035, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6864, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.505757230769231 ], [ 29.759493620689657, -1.506026707692308 ], [ 29.759763103448275, -1.506026707692308 ], [ 29.759763103448275, -1.505757230769231 ], [ 29.759493620689657, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6865, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.505757230769231 ], [ 29.759763103448275, -1.506026707692308 ], [ 29.760032586206897, -1.506026707692308 ], [ 29.760032586206897, -1.505757230769231 ], [ 29.759763103448275, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6866, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.505757230769231 ], [ 29.760032586206897, -1.506026707692308 ], [ 29.760302068965519, -1.506026707692308 ], [ 29.760302068965519, -1.505757230769231 ], [ 29.760032586206897, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6867, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.505757230769231 ], [ 29.760302068965519, -1.506026707692308 ], [ 29.760571551724137, -1.506026707692308 ], [ 29.760571551724137, -1.505757230769231 ], [ 29.760302068965519, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6868, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.505757230769231 ], [ 29.760571551724137, -1.506026707692308 ], [ 29.760841034482759, -1.506026707692308 ], [ 29.760841034482759, -1.505757230769231 ], [ 29.760571551724137, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6869, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.505757230769231 ], [ 29.760841034482759, -1.506026707692308 ], [ 29.761110517241381, -1.506026707692308 ], [ 29.761110517241381, -1.505757230769231 ], [ 29.760841034482759, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6870, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.505757230769231 ], [ 29.761110517241381, -1.506026707692308 ], [ 29.76138, -1.506026707692308 ], [ 29.76138, -1.505757230769231 ], [ 29.761110517241381, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6871, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.505757230769231 ], [ 29.76138, -1.506026707692308 ], [ 29.761649482758621, -1.506026707692308 ], [ 29.761649482758621, -1.505757230769231 ], [ 29.76138, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6872, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.505757230769231 ], [ 29.761649482758621, -1.506026707692308 ], [ 29.761918965517243, -1.506026707692308 ], [ 29.761918965517243, -1.505757230769231 ], [ 29.761649482758621, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6873, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.505757230769231 ], [ 29.761918965517243, -1.506026707692308 ], [ 29.762188448275861, -1.506026707692308 ], [ 29.762188448275861, -1.505757230769231 ], [ 29.761918965517243, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6874, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.505757230769231 ], [ 29.762188448275861, -1.506565661538461 ], [ 29.762457931034483, -1.506565661538461 ], [ 29.762457931034483, -1.505757230769231 ], [ 29.762188448275861, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6875, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.505757230769231 ], [ 29.762457931034483, -1.506835138461538 ], [ 29.762727413793105, -1.506835138461538 ], [ 29.762727413793105, -1.505757230769231 ], [ 29.762457931034483, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6876, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.505757230769231 ], [ 29.762727413793105, -1.506835138461538 ], [ 29.762996896551723, -1.506835138461538 ], [ 29.762996896551723, -1.505757230769231 ], [ 29.762727413793105, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6877, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.505757230769231 ], [ 29.763805344827588, -1.506296184615385 ], [ 29.764074827586207, -1.506296184615385 ], [ 29.764074827586207, -1.505757230769231 ], [ 29.763805344827588, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6878, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.505757230769231 ], [ 29.76461379310345, -1.506296184615385 ], [ 29.764883275862069, -1.506296184615385 ], [ 29.764883275862069, -1.505757230769231 ], [ 29.76461379310345, -1.505757230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6879, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.506026707692308 ], [ 29.72203551724138, -1.506296184615385 ], [ 29.722305, -1.506296184615385 ], [ 29.722305, -1.506026707692308 ], [ 29.72203551724138, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6880, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.506026707692308 ], [ 29.722305, -1.506565661538461 ], [ 29.72257448275862, -1.506565661538461 ], [ 29.72257448275862, -1.506026707692308 ], [ 29.722305, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6881, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.506026707692308 ], [ 29.72257448275862, -1.506565661538461 ], [ 29.722843965517242, -1.506565661538461 ], [ 29.722843965517242, -1.506026707692308 ], [ 29.72257448275862, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6882, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.506026707692308 ], [ 29.722843965517242, -1.506565661538461 ], [ 29.723113448275861, -1.506565661538461 ], [ 29.723113448275861, -1.506026707692308 ], [ 29.722843965517242, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6883, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.506026707692308 ], [ 29.723113448275861, -1.506296184615385 ], [ 29.723382931034482, -1.506296184615385 ], [ 29.723382931034482, -1.506026707692308 ], [ 29.723113448275861, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6884, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.506026707692308 ], [ 29.723382931034482, -1.506296184615385 ], [ 29.723652413793104, -1.506296184615385 ], [ 29.723652413793104, -1.506026707692308 ], [ 29.723382931034482, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6885, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.506026707692308 ], [ 29.723652413793104, -1.506296184615385 ], [ 29.723921896551722, -1.506296184615385 ], [ 29.723921896551722, -1.506026707692308 ], [ 29.723652413793104, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6886, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.506026707692308 ], [ 29.724460862068966, -1.506565661538461 ], [ 29.724730344827588, -1.506565661538461 ], [ 29.724730344827588, -1.506026707692308 ], [ 29.724460862068966, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6887, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.506026707692308 ], [ 29.724730344827588, -1.506296184615385 ], [ 29.724999827586206, -1.506296184615385 ], [ 29.724999827586206, -1.506026707692308 ], [ 29.724730344827588, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6888, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.506026707692308 ], [ 29.724999827586206, -1.506296184615385 ], [ 29.725269310344828, -1.506296184615385 ], [ 29.725269310344828, -1.506026707692308 ], [ 29.724999827586206, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6889, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.506026707692308 ], [ 29.72553879310345, -1.506565661538461 ], [ 29.725808275862068, -1.506565661538461 ], [ 29.725808275862068, -1.506026707692308 ], [ 29.72553879310345, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6890, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.506026707692308 ], [ 29.725808275862068, -1.506296184615385 ], [ 29.72607775862069, -1.506296184615385 ], [ 29.72607775862069, -1.506026707692308 ], [ 29.725808275862068, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6891, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.506026707692308 ], [ 29.72607775862069, -1.506296184615385 ], [ 29.72661672413793, -1.506296184615385 ], [ 29.72661672413793, -1.506026707692308 ], [ 29.72607775862069, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6892, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.506026707692308 ], [ 29.72661672413793, -1.506296184615385 ], [ 29.726886206896552, -1.506296184615385 ], [ 29.726886206896552, -1.506026707692308 ], [ 29.72661672413793, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6893, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.506026707692308 ], [ 29.726886206896552, -1.506296184615385 ], [ 29.727155689655174, -1.506296184615385 ], [ 29.727155689655174, -1.506026707692308 ], [ 29.726886206896552, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6894, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.506026707692308 ], [ 29.727155689655174, -1.506296184615385 ], [ 29.727425172413792, -1.506296184615385 ], [ 29.727425172413792, -1.506026707692308 ], [ 29.727155689655174, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6895, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.506026707692308 ], [ 29.727425172413792, -1.506296184615385 ], [ 29.727694655172414, -1.506296184615385 ], [ 29.727694655172414, -1.506026707692308 ], [ 29.727425172413792, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6896, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.506026707692308 ], [ 29.727694655172414, -1.506296184615385 ], [ 29.727964137931036, -1.506296184615385 ], [ 29.727964137931036, -1.506026707692308 ], [ 29.727694655172414, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6897, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.506026707692308 ], [ 29.727964137931036, -1.506296184615385 ], [ 29.728233620689654, -1.506296184615385 ], [ 29.728233620689654, -1.506026707692308 ], [ 29.727964137931036, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6898, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.506026707692308 ], [ 29.728233620689654, -1.506296184615385 ], [ 29.728503103448276, -1.506296184615385 ], [ 29.728503103448276, -1.506026707692308 ], [ 29.728233620689654, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6899, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.506026707692308 ], [ 29.728503103448276, -1.506296184615385 ], [ 29.728772586206897, -1.506296184615385 ], [ 29.728772586206897, -1.506026707692308 ], [ 29.728503103448276, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6900, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.506026707692308 ], [ 29.728772586206897, -1.506296184615385 ], [ 29.729042068965516, -1.506296184615385 ], [ 29.729042068965516, -1.506026707692308 ], [ 29.728772586206897, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6901, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.506026707692308 ], [ 29.735240172413793, -1.506565661538461 ], [ 29.737126551724138, -1.506565661538461 ], [ 29.737126551724138, -1.506296184615385 ], [ 29.735509655172415, -1.506296184615385 ], [ 29.735509655172415, -1.506026707692308 ], [ 29.735240172413793, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6902, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.506026707692308 ], [ 29.73739603448276, -1.506296184615385 ], [ 29.737665517241378, -1.506296184615385 ], [ 29.737665517241378, -1.506026707692308 ], [ 29.73739603448276, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6903, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.506026707692308 ], [ 29.737665517241378, -1.506296184615385 ], [ 29.737935, -1.506296184615385 ], [ 29.737935, -1.506026707692308 ], [ 29.737665517241378, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6904, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.506026707692308 ], [ 29.737935, -1.506565661538461 ], [ 29.738204482758622, -1.506565661538461 ], [ 29.738204482758622, -1.506026707692308 ], [ 29.737935, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6905, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.506026707692308 ], [ 29.738204482758622, -1.506296184615385 ], [ 29.738743448275862, -1.506296184615385 ], [ 29.738743448275862, -1.506026707692308 ], [ 29.738204482758622, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6906, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.506026707692308 ], [ 29.738743448275862, -1.506296184615385 ], [ 29.739012931034484, -1.506296184615385 ], [ 29.739012931034484, -1.506026707692308 ], [ 29.738743448275862, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6907, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.506026707692308 ], [ 29.739012931034484, -1.506296184615385 ], [ 29.739282413793102, -1.506296184615385 ], [ 29.739282413793102, -1.506026707692308 ], [ 29.739012931034484, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6908, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.506026707692308 ], [ 29.739282413793102, -1.506835138461538 ], [ 29.740629827586208, -1.506835138461538 ], [ 29.740629827586208, -1.506565661538461 ], [ 29.739551896551724, -1.506565661538461 ], [ 29.739551896551724, -1.506026707692308 ], [ 29.739282413793102, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6909, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.506026707692308 ], [ 29.739551896551724, -1.506565661538461 ], [ 29.740360344827586, -1.506565661538461 ], [ 29.740360344827586, -1.506296184615385 ], [ 29.739821379310346, -1.506296184615385 ], [ 29.739821379310346, -1.506026707692308 ], [ 29.739551896551724, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6910, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.506026707692308 ], [ 29.739821379310346, -1.506296184615385 ], [ 29.740360344827586, -1.506296184615385 ], [ 29.740360344827586, -1.506026707692308 ], [ 29.739821379310346, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6911, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.506026707692308 ], [ 29.740360344827586, -1.506296184615385 ], [ 29.740629827586208, -1.506296184615385 ], [ 29.740629827586208, -1.506026707692308 ], [ 29.740360344827586, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6912, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.506026707692308 ], [ 29.740629827586208, -1.506296184615385 ], [ 29.74089931034483, -1.506296184615385 ], [ 29.74089931034483, -1.506026707692308 ], [ 29.740629827586208, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6913, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.506026707692308 ], [ 29.74089931034483, -1.506296184615385 ], [ 29.741168793103448, -1.506296184615385 ], [ 29.741168793103448, -1.506026707692308 ], [ 29.74089931034483, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6914, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.506026707692308 ], [ 29.741168793103448, -1.506296184615385 ], [ 29.74143827586207, -1.506296184615385 ], [ 29.74143827586207, -1.506026707692308 ], [ 29.741168793103448, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6915, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.506026707692308 ], [ 29.74143827586207, -1.506296184615385 ], [ 29.741707758620691, -1.506296184615385 ], [ 29.741707758620691, -1.506026707692308 ], [ 29.74143827586207, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6916, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.506026707692308 ], [ 29.741707758620691, -1.506565661538461 ], [ 29.74197724137931, -1.506565661538461 ], [ 29.74197724137931, -1.506026707692308 ], [ 29.741707758620691, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6917, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.506026707692308 ], [ 29.74197724137931, -1.506296184615385 ], [ 29.742246724137932, -1.506296184615385 ], [ 29.742246724137932, -1.506026707692308 ], [ 29.74197724137931, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6918, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.506026707692308 ], [ 29.742246724137932, -1.506296184615385 ], [ 29.742516206896553, -1.506296184615385 ], [ 29.742516206896553, -1.506026707692308 ], [ 29.742246724137932, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6919, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.506026707692308 ], [ 29.742516206896553, -1.506296184615385 ], [ 29.742785689655172, -1.506296184615385 ], [ 29.742785689655172, -1.506026707692308 ], [ 29.742516206896553, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6920, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.506026707692308 ], [ 29.742785689655172, -1.506296184615385 ], [ 29.743055172413793, -1.506296184615385 ], [ 29.743055172413793, -1.506026707692308 ], [ 29.742785689655172, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6921, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.506026707692308 ], [ 29.743055172413793, -1.506296184615385 ], [ 29.743324655172415, -1.506296184615385 ], [ 29.743324655172415, -1.506026707692308 ], [ 29.743055172413793, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6922, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.506026707692308 ], [ 29.756798793103449, -1.506296184615385 ], [ 29.757068275862068, -1.506296184615385 ], [ 29.757068275862068, -1.506026707692308 ], [ 29.756798793103449, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6923, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.506026707692308 ], [ 29.757068275862068, -1.506296184615385 ], [ 29.75733775862069, -1.506296184615385 ], [ 29.75733775862069, -1.506026707692308 ], [ 29.757068275862068, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6924, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.506026707692308 ], [ 29.75733775862069, -1.506296184615385 ], [ 29.757607241379311, -1.506296184615385 ], [ 29.757607241379311, -1.506026707692308 ], [ 29.75733775862069, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6925, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.506026707692308 ], [ 29.757607241379311, -1.506296184615385 ], [ 29.757876724137933, -1.506296184615385 ], [ 29.757876724137933, -1.506026707692308 ], [ 29.757607241379311, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6926, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.506026707692308 ], [ 29.758146206896551, -1.506565661538461 ], [ 29.758415689655173, -1.506565661538461 ], [ 29.758415689655173, -1.506026707692308 ], [ 29.758146206896551, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6927, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.506026707692308 ], [ 29.758415689655173, -1.506296184615385 ], [ 29.758685172413795, -1.506296184615385 ], [ 29.758685172413795, -1.506026707692308 ], [ 29.758415689655173, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6928, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.506026707692308 ], [ 29.758685172413795, -1.506296184615385 ], [ 29.758954655172413, -1.506296184615385 ], [ 29.758954655172413, -1.506026707692308 ], [ 29.758685172413795, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6929, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.506026707692308 ], [ 29.758954655172413, -1.506296184615385 ], [ 29.759224137931035, -1.506296184615385 ], [ 29.759224137931035, -1.506026707692308 ], [ 29.758954655172413, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6930, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.506026707692308 ], [ 29.759224137931035, -1.506296184615385 ], [ 29.759493620689657, -1.506296184615385 ], [ 29.759493620689657, -1.506026707692308 ], [ 29.759224137931035, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6931, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.506026707692308 ], [ 29.759493620689657, -1.506296184615385 ], [ 29.759763103448275, -1.506296184615385 ], [ 29.759763103448275, -1.506026707692308 ], [ 29.759493620689657, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6932, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.506026707692308 ], [ 29.759763103448275, -1.506296184615385 ], [ 29.760032586206897, -1.506296184615385 ], [ 29.760032586206897, -1.506026707692308 ], [ 29.759763103448275, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6933, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.506026707692308 ], [ 29.760032586206897, -1.506296184615385 ], [ 29.760302068965519, -1.506296184615385 ], [ 29.760302068965519, -1.506026707692308 ], [ 29.760032586206897, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6934, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.506026707692308 ], [ 29.760302068965519, -1.506296184615385 ], [ 29.760571551724137, -1.506296184615385 ], [ 29.760571551724137, -1.506026707692308 ], [ 29.760302068965519, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6935, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.506026707692308 ], [ 29.760571551724137, -1.506296184615385 ], [ 29.760841034482759, -1.506296184615385 ], [ 29.760841034482759, -1.506026707692308 ], [ 29.760571551724137, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6936, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.506026707692308 ], [ 29.760841034482759, -1.506296184615385 ], [ 29.761110517241381, -1.506296184615385 ], [ 29.761110517241381, -1.506026707692308 ], [ 29.760841034482759, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6937, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.506026707692308 ], [ 29.761110517241381, -1.506296184615385 ], [ 29.76138, -1.506296184615385 ], [ 29.76138, -1.506026707692308 ], [ 29.761110517241381, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6938, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.506026707692308 ], [ 29.76138, -1.506565661538461 ], [ 29.761649482758621, -1.506565661538461 ], [ 29.761649482758621, -1.506026707692308 ], [ 29.76138, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6939, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.506026707692308 ], [ 29.761649482758621, -1.506835138461538 ], [ 29.761918965517243, -1.506835138461538 ], [ 29.761918965517243, -1.506026707692308 ], [ 29.761649482758621, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6940, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.506026707692308 ], [ 29.761918965517243, -1.507104615384615 ], [ 29.762188448275861, -1.507104615384615 ], [ 29.762188448275861, -1.506026707692308 ], [ 29.761918965517243, -1.506026707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6941, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.506296184615385 ], [ 29.720957586206897, -1.506835138461538 ], [ 29.721227068965518, -1.506835138461538 ], [ 29.721227068965518, -1.506296184615385 ], [ 29.720957586206897, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6942, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.506296184615385 ], [ 29.721227068965518, -1.506565661538461 ], [ 29.721496551724137, -1.506565661538461 ], [ 29.721496551724137, -1.506296184615385 ], [ 29.721227068965518, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6943, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.506296184615385 ], [ 29.72203551724138, -1.506835138461538 ], [ 29.722305, -1.506835138461538 ], [ 29.722305, -1.506296184615385 ], [ 29.72203551724138, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6944, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.506296184615385 ], [ 29.723113448275861, -1.506835138461538 ], [ 29.723382931034482, -1.506835138461538 ], [ 29.723382931034482, -1.506296184615385 ], [ 29.723113448275861, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6945, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.506296184615385 ], [ 29.723382931034482, -1.506835138461538 ], [ 29.723652413793104, -1.506835138461538 ], [ 29.723652413793104, -1.506296184615385 ], [ 29.723382931034482, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6946, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.506296184615385 ], [ 29.723652413793104, -1.506565661538461 ], [ 29.723921896551722, -1.506565661538461 ], [ 29.723921896551722, -1.506296184615385 ], [ 29.723652413793104, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6947, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.506296184615385 ], [ 29.723921896551722, -1.506565661538461 ], [ 29.724191379310344, -1.506565661538461 ], [ 29.724191379310344, -1.506296184615385 ], [ 29.723921896551722, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6948, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.506296184615385 ], [ 29.724191379310344, -1.506565661538461 ], [ 29.724460862068966, -1.506565661538461 ], [ 29.724460862068966, -1.506296184615385 ], [ 29.724191379310344, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6949, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.506296184615385 ], [ 29.724730344827588, -1.506835138461538 ], [ 29.724999827586206, -1.506835138461538 ], [ 29.724999827586206, -1.506296184615385 ], [ 29.724730344827588, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6950, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.506296184615385 ], [ 29.724999827586206, -1.506565661538461 ], [ 29.725269310344828, -1.506565661538461 ], [ 29.725269310344828, -1.506296184615385 ], [ 29.724999827586206, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6951, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.506296184615385 ], [ 29.725269310344828, -1.506565661538461 ], [ 29.72553879310345, -1.506565661538461 ], [ 29.72553879310345, -1.506296184615385 ], [ 29.725269310344828, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6952, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.506296184615385 ], [ 29.725808275862068, -1.506835138461538 ], [ 29.72607775862069, -1.506835138461538 ], [ 29.72607775862069, -1.506296184615385 ], [ 29.725808275862068, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6953, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.506296184615385 ], [ 29.72607775862069, -1.506565661538461 ], [ 29.726347241379312, -1.506565661538461 ], [ 29.726347241379312, -1.506296184615385 ], [ 29.72607775862069, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6954, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.506296184615385 ], [ 29.726347241379312, -1.506565661538461 ], [ 29.72661672413793, -1.506565661538461 ], [ 29.72661672413793, -1.506296184615385 ], [ 29.726347241379312, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6955, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.506296184615385 ], [ 29.72661672413793, -1.506835138461538 ], [ 29.726886206896552, -1.506835138461538 ], [ 29.726886206896552, -1.506296184615385 ], [ 29.72661672413793, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6956, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.506296184615385 ], [ 29.726886206896552, -1.506565661538461 ], [ 29.727155689655174, -1.506565661538461 ], [ 29.727155689655174, -1.506296184615385 ], [ 29.726886206896552, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6957, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.506296184615385 ], [ 29.727155689655174, -1.506565661538461 ], [ 29.727425172413792, -1.506565661538461 ], [ 29.727425172413792, -1.506296184615385 ], [ 29.727155689655174, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6958, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.506296184615385 ], [ 29.727425172413792, -1.506565661538461 ], [ 29.727694655172414, -1.506565661538461 ], [ 29.727694655172414, -1.506296184615385 ], [ 29.727425172413792, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6959, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.506296184615385 ], [ 29.727694655172414, -1.506565661538461 ], [ 29.727964137931036, -1.506565661538461 ], [ 29.727964137931036, -1.506296184615385 ], [ 29.727694655172414, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6960, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.506296184615385 ], [ 29.727964137931036, -1.506565661538461 ], [ 29.728233620689654, -1.506565661538461 ], [ 29.728233620689654, -1.506296184615385 ], [ 29.727964137931036, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6961, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.506296184615385 ], [ 29.728233620689654, -1.506565661538461 ], [ 29.728503103448276, -1.506565661538461 ], [ 29.728503103448276, -1.506296184615385 ], [ 29.728233620689654, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6962, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.506296184615385 ], [ 29.728503103448276, -1.506565661538461 ], [ 29.728772586206897, -1.506565661538461 ], [ 29.728772586206897, -1.506296184615385 ], [ 29.728503103448276, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6963, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.506296184615385 ], [ 29.728772586206897, -1.506565661538461 ], [ 29.729042068965516, -1.506565661538461 ], [ 29.729042068965516, -1.506296184615385 ], [ 29.728772586206897, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6964, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.506296184615385 ], [ 29.729042068965516, -1.506565661538461 ], [ 29.729311551724138, -1.506565661538461 ], [ 29.729311551724138, -1.506296184615385 ], [ 29.729042068965516, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6965, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.506296184615385 ], [ 29.729311551724138, -1.506565661538461 ], [ 29.729850517241381, -1.506565661538461 ], [ 29.729850517241381, -1.506296184615385 ], [ 29.729311551724138, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6966, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.506296184615385 ], [ 29.729850517241381, -1.506565661538461 ], [ 29.73012, -1.506565661538461 ], [ 29.73012, -1.506296184615385 ], [ 29.729850517241381, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6967, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.506296184615385 ], [ 29.737126551724138, -1.506565661538461 ], [ 29.73739603448276, -1.506565661538461 ], [ 29.73739603448276, -1.506296184615385 ], [ 29.737126551724138, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6968, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.506296184615385 ], [ 29.740360344827586, -1.506565661538461 ], [ 29.740629827586208, -1.506565661538461 ], [ 29.740629827586208, -1.506296184615385 ], [ 29.740360344827586, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6969, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.506296184615385 ], [ 29.740629827586208, -1.506565661538461 ], [ 29.74089931034483, -1.506565661538461 ], [ 29.74089931034483, -1.506296184615385 ], [ 29.740629827586208, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6970, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.506296184615385 ], [ 29.74089931034483, -1.506565661538461 ], [ 29.741168793103448, -1.506565661538461 ], [ 29.741168793103448, -1.506296184615385 ], [ 29.74089931034483, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6971, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.506296184615385 ], [ 29.741168793103448, -1.506565661538461 ], [ 29.74143827586207, -1.506565661538461 ], [ 29.74143827586207, -1.506296184615385 ], [ 29.741168793103448, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6972, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.506296184615385 ], [ 29.74143827586207, -1.506565661538461 ], [ 29.741707758620691, -1.506565661538461 ], [ 29.741707758620691, -1.506296184615385 ], [ 29.74143827586207, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6973, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.506296184615385 ], [ 29.74197724137931, -1.506835138461538 ], [ 29.742246724137932, -1.506835138461538 ], [ 29.742246724137932, -1.506296184615385 ], [ 29.74197724137931, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6974, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.506296184615385 ], [ 29.742246724137932, -1.506835138461538 ], [ 29.742516206896553, -1.506835138461538 ], [ 29.742516206896553, -1.506296184615385 ], [ 29.742246724137932, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6975, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.506296184615385 ], [ 29.742516206896553, -1.506565661538461 ], [ 29.742785689655172, -1.506565661538461 ], [ 29.742785689655172, -1.506296184615385 ], [ 29.742516206896553, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6976, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.506296184615385 ], [ 29.742785689655172, -1.506565661538461 ], [ 29.743055172413793, -1.506565661538461 ], [ 29.743055172413793, -1.506296184615385 ], [ 29.742785689655172, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6977, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.506296184615385 ], [ 29.754642931034482, -1.506835138461538 ], [ 29.754912413793104, -1.506835138461538 ], [ 29.754912413793104, -1.506296184615385 ], [ 29.754642931034482, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6978, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.506296184615385 ], [ 29.755720862068966, -1.506835138461538 ], [ 29.755990344827588, -1.506835138461538 ], [ 29.755990344827588, -1.506296184615385 ], [ 29.755720862068966, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6979, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.506296184615385 ], [ 29.755990344827588, -1.506835138461538 ], [ 29.756259827586206, -1.506835138461538 ], [ 29.756259827586206, -1.506296184615385 ], [ 29.755990344827588, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6980, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.506296184615385 ], [ 29.756798793103449, -1.506565661538461 ], [ 29.757068275862068, -1.506565661538461 ], [ 29.757068275862068, -1.506296184615385 ], [ 29.756798793103449, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6981, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.506296184615385 ], [ 29.757068275862068, -1.506565661538461 ], [ 29.75733775862069, -1.506565661538461 ], [ 29.75733775862069, -1.506296184615385 ], [ 29.757068275862068, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6982, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.506296184615385 ], [ 29.75733775862069, -1.506565661538461 ], [ 29.757607241379311, -1.506565661538461 ], [ 29.757607241379311, -1.506296184615385 ], [ 29.75733775862069, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6983, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.506296184615385 ], [ 29.757607241379311, -1.506565661538461 ], [ 29.757876724137933, -1.506565661538461 ], [ 29.757876724137933, -1.506296184615385 ], [ 29.757607241379311, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6984, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.506296184615385 ], [ 29.757876724137933, -1.506565661538461 ], [ 29.758146206896551, -1.506565661538461 ], [ 29.758146206896551, -1.506296184615385 ], [ 29.757876724137933, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6985, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.506296184615385 ], [ 29.758415689655173, -1.506565661538461 ], [ 29.758685172413795, -1.506565661538461 ], [ 29.758685172413795, -1.506296184615385 ], [ 29.758415689655173, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6986, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.506296184615385 ], [ 29.758685172413795, -1.506565661538461 ], [ 29.758954655172413, -1.506565661538461 ], [ 29.758954655172413, -1.506296184615385 ], [ 29.758685172413795, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6987, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.506296184615385 ], [ 29.758954655172413, -1.506565661538461 ], [ 29.759224137931035, -1.506565661538461 ], [ 29.759224137931035, -1.506296184615385 ], [ 29.758954655172413, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6988, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.506296184615385 ], [ 29.759224137931035, -1.506565661538461 ], [ 29.759493620689657, -1.506565661538461 ], [ 29.759493620689657, -1.506296184615385 ], [ 29.759224137931035, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6989, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.506296184615385 ], [ 29.759493620689657, -1.506565661538461 ], [ 29.759763103448275, -1.506565661538461 ], [ 29.759763103448275, -1.506296184615385 ], [ 29.759493620689657, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6990, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.506296184615385 ], [ 29.759763103448275, -1.506565661538461 ], [ 29.760032586206897, -1.506565661538461 ], [ 29.760032586206897, -1.506296184615385 ], [ 29.759763103448275, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6991, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.506296184615385 ], [ 29.760032586206897, -1.506565661538461 ], [ 29.760302068965519, -1.506565661538461 ], [ 29.760302068965519, -1.506296184615385 ], [ 29.760032586206897, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6992, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.506296184615385 ], [ 29.760302068965519, -1.506565661538461 ], [ 29.760571551724137, -1.506565661538461 ], [ 29.760571551724137, -1.506296184615385 ], [ 29.760302068965519, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6993, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.506296184615385 ], [ 29.760571551724137, -1.506565661538461 ], [ 29.760841034482759, -1.506565661538461 ], [ 29.760841034482759, -1.506296184615385 ], [ 29.760571551724137, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6994, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.506296184615385 ], [ 29.760841034482759, -1.506835138461538 ], [ 29.761110517241381, -1.506835138461538 ], [ 29.761110517241381, -1.506296184615385 ], [ 29.760841034482759, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6995, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.506296184615385 ], [ 29.761110517241381, -1.507104615384615 ], [ 29.76138, -1.507104615384615 ], [ 29.76138, -1.506296184615385 ], [ 29.761110517241381, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6996, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.506296184615385 ], [ 29.763805344827588, -1.506835138461538 ], [ 29.764074827586207, -1.506835138461538 ], [ 29.764074827586207, -1.506296184615385 ], [ 29.763805344827588, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6997, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.506296184615385 ], [ 29.76461379310345, -1.506565661538461 ], [ 29.764883275862069, -1.506565661538461 ], [ 29.764883275862069, -1.506296184615385 ], [ 29.76461379310345, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6998, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.506296184615385 ], [ 29.764883275862069, -1.506565661538461 ], [ 29.76515275862069, -1.506565661538461 ], [ 29.76515275862069, -1.506296184615385 ], [ 29.764883275862069, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6999, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.506565661538461 ], [ 29.721227068965518, -1.507104615384615 ], [ 29.721496551724137, -1.507104615384615 ], [ 29.721496551724137, -1.506565661538461 ], [ 29.721227068965518, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7000, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.506565661538461 ], [ 29.721496551724137, -1.507104615384615 ], [ 29.721766034482759, -1.507104615384615 ], [ 29.721766034482759, -1.506565661538461 ], [ 29.721496551724137, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7001, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.506565661538461 ], [ 29.721766034482759, -1.506835138461538 ], [ 29.72203551724138, -1.506835138461538 ], [ 29.72203551724138, -1.506565661538461 ], [ 29.721766034482759, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7002, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.506565661538461 ], [ 29.722305, -1.506835138461538 ], [ 29.72257448275862, -1.506835138461538 ], [ 29.72257448275862, -1.506565661538461 ], [ 29.722305, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7003, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.506565661538461 ], [ 29.72257448275862, -1.506835138461538 ], [ 29.722843965517242, -1.506835138461538 ], [ 29.722843965517242, -1.506565661538461 ], [ 29.72257448275862, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7004, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.506565661538461 ], [ 29.722843965517242, -1.506835138461538 ], [ 29.723113448275861, -1.506835138461538 ], [ 29.723113448275861, -1.506565661538461 ], [ 29.722843965517242, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7005, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.506565661538461 ], [ 29.723652413793104, -1.507104615384615 ], [ 29.723921896551722, -1.507104615384615 ], [ 29.723921896551722, -1.506565661538461 ], [ 29.723652413793104, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7006, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.506565661538461 ], [ 29.723921896551722, -1.507104615384615 ], [ 29.724191379310344, -1.507104615384615 ], [ 29.724191379310344, -1.506565661538461 ], [ 29.723921896551722, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7007, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.506565661538461 ], [ 29.724191379310344, -1.506835138461538 ], [ 29.724460862068966, -1.506835138461538 ], [ 29.724460862068966, -1.506565661538461 ], [ 29.724191379310344, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7008, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.506565661538461 ], [ 29.724460862068966, -1.506835138461538 ], [ 29.724730344827588, -1.506835138461538 ], [ 29.724730344827588, -1.506565661538461 ], [ 29.724460862068966, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7009, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.506565661538461 ], [ 29.724999827586206, -1.507104615384615 ], [ 29.725269310344828, -1.507104615384615 ], [ 29.725269310344828, -1.506565661538461 ], [ 29.724999827586206, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7010, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.506565661538461 ], [ 29.725269310344828, -1.506835138461538 ], [ 29.72553879310345, -1.506835138461538 ], [ 29.72553879310345, -1.506565661538461 ], [ 29.725269310344828, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7011, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.506565661538461 ], [ 29.72553879310345, -1.506835138461538 ], [ 29.725808275862068, -1.506835138461538 ], [ 29.725808275862068, -1.506565661538461 ], [ 29.72553879310345, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7012, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.506565661538461 ], [ 29.72607775862069, -1.506835138461538 ], [ 29.726347241379312, -1.506835138461538 ], [ 29.726347241379312, -1.506565661538461 ], [ 29.72607775862069, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7013, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.506565661538461 ], [ 29.726347241379312, -1.506835138461538 ], [ 29.72661672413793, -1.506835138461538 ], [ 29.72661672413793, -1.506565661538461 ], [ 29.726347241379312, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7014, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.506565661538461 ], [ 29.726886206896552, -1.507104615384615 ], [ 29.727155689655174, -1.507104615384615 ], [ 29.727155689655174, -1.506565661538461 ], [ 29.726886206896552, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7015, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.506565661538461 ], [ 29.727155689655174, -1.506835138461538 ], [ 29.727425172413792, -1.506835138461538 ], [ 29.727425172413792, -1.506565661538461 ], [ 29.727155689655174, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7016, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.506565661538461 ], [ 29.727425172413792, -1.506835138461538 ], [ 29.727694655172414, -1.506835138461538 ], [ 29.727694655172414, -1.506565661538461 ], [ 29.727425172413792, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7017, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.506565661538461 ], [ 29.727694655172414, -1.506835138461538 ], [ 29.727964137931036, -1.506835138461538 ], [ 29.727964137931036, -1.506565661538461 ], [ 29.727694655172414, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7018, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.506565661538461 ], [ 29.727964137931036, -1.506835138461538 ], [ 29.728233620689654, -1.506835138461538 ], [ 29.728233620689654, -1.506565661538461 ], [ 29.727964137931036, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7019, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.506565661538461 ], [ 29.728233620689654, -1.506835138461538 ], [ 29.728503103448276, -1.506835138461538 ], [ 29.728503103448276, -1.506565661538461 ], [ 29.728233620689654, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7020, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.506565661538461 ], [ 29.728503103448276, -1.506835138461538 ], [ 29.728772586206897, -1.506835138461538 ], [ 29.728772586206897, -1.506565661538461 ], [ 29.728503103448276, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7021, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.506565661538461 ], [ 29.728772586206897, -1.506835138461538 ], [ 29.729042068965516, -1.506835138461538 ], [ 29.729042068965516, -1.506565661538461 ], [ 29.728772586206897, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7022, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.506565661538461 ], [ 29.729042068965516, -1.506835138461538 ], [ 29.729311551724138, -1.506835138461538 ], [ 29.729311551724138, -1.506565661538461 ], [ 29.729042068965516, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7023, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.506565661538461 ], [ 29.729311551724138, -1.506835138461538 ], [ 29.729850517241381, -1.506835138461538 ], [ 29.729850517241381, -1.506565661538461 ], [ 29.729311551724138, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7024, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.506565661538461 ], [ 29.729850517241381, -1.506835138461538 ], [ 29.73012, -1.506835138461538 ], [ 29.73012, -1.506565661538461 ], [ 29.729850517241381, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7025, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.506565661538461 ], [ 29.73012, -1.506835138461538 ], [ 29.730389482758621, -1.506835138461538 ], [ 29.730389482758621, -1.506565661538461 ], [ 29.73012, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7026, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.506565661538461 ], [ 29.730389482758621, -1.506835138461538 ], [ 29.730658965517243, -1.506835138461538 ], [ 29.730658965517243, -1.506565661538461 ], [ 29.730389482758621, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7027, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.506565661538461 ], [ 29.740629827586208, -1.506835138461538 ], [ 29.74089931034483, -1.506835138461538 ], [ 29.74089931034483, -1.506565661538461 ], [ 29.740629827586208, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7028, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.506565661538461 ], [ 29.74089931034483, -1.506835138461538 ], [ 29.741168793103448, -1.506835138461538 ], [ 29.741168793103448, -1.506565661538461 ], [ 29.74089931034483, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7029, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.506565661538461 ], [ 29.741168793103448, -1.506835138461538 ], [ 29.74143827586207, -1.506835138461538 ], [ 29.74143827586207, -1.506565661538461 ], [ 29.741168793103448, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7030, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.506565661538461 ], [ 29.74143827586207, -1.507104615384615 ], [ 29.741707758620691, -1.507104615384615 ], [ 29.741707758620691, -1.506565661538461 ], [ 29.74143827586207, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7031, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.506565661538461 ], [ 29.741707758620691, -1.506835138461538 ], [ 29.74197724137931, -1.506835138461538 ], [ 29.74197724137931, -1.506565661538461 ], [ 29.741707758620691, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7032, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.506565661538461 ], [ 29.742516206896553, -1.507104615384615 ], [ 29.742785689655172, -1.507104615384615 ], [ 29.742785689655172, -1.506565661538461 ], [ 29.742516206896553, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7033, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.506565661538461 ], [ 29.75383448275862, -1.506835138461538 ], [ 29.754103965517242, -1.506835138461538 ], [ 29.754103965517242, -1.506565661538461 ], [ 29.75383448275862, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7034, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.506565661538461 ], [ 29.754103965517242, -1.506835138461538 ], [ 29.754373448275864, -1.506835138461538 ], [ 29.754373448275864, -1.506565661538461 ], [ 29.754103965517242, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7035, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.506565661538461 ], [ 29.754373448275864, -1.507104615384615 ], [ 29.754642931034482, -1.507104615384615 ], [ 29.754642931034482, -1.506565661538461 ], [ 29.754373448275864, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7036, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.506565661538461 ], [ 29.754912413793104, -1.506835138461538 ], [ 29.755181896551726, -1.506835138461538 ], [ 29.755181896551726, -1.506565661538461 ], [ 29.754912413793104, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7037, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.506565661538461 ], [ 29.755181896551726, -1.506835138461538 ], [ 29.755451379310344, -1.506835138461538 ], [ 29.755451379310344, -1.506565661538461 ], [ 29.755181896551726, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7038, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.506565661538461 ], [ 29.755451379310344, -1.507104615384615 ], [ 29.755720862068966, -1.507104615384615 ], [ 29.755720862068966, -1.506565661538461 ], [ 29.755451379310344, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7039, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.506565661538461 ], [ 29.756259827586206, -1.506835138461538 ], [ 29.756798793103449, -1.506835138461538 ], [ 29.756798793103449, -1.506565661538461 ], [ 29.756259827586206, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7040, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.506565661538461 ], [ 29.756798793103449, -1.506835138461538 ], [ 29.757068275862068, -1.506835138461538 ], [ 29.757068275862068, -1.506565661538461 ], [ 29.756798793103449, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7041, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.506565661538461 ], [ 29.757068275862068, -1.506835138461538 ], [ 29.75733775862069, -1.506835138461538 ], [ 29.75733775862069, -1.506565661538461 ], [ 29.757068275862068, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7042, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.506565661538461 ], [ 29.75733775862069, -1.506835138461538 ], [ 29.757607241379311, -1.506835138461538 ], [ 29.757607241379311, -1.506565661538461 ], [ 29.75733775862069, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7043, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.506565661538461 ], [ 29.757607241379311, -1.506835138461538 ], [ 29.757876724137933, -1.506835138461538 ], [ 29.757876724137933, -1.506565661538461 ], [ 29.757607241379311, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7044, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.506565661538461 ], [ 29.757876724137933, -1.506835138461538 ], [ 29.758146206896551, -1.506835138461538 ], [ 29.758146206896551, -1.506565661538461 ], [ 29.757876724137933, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7045, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.506565661538461 ], [ 29.758146206896551, -1.506835138461538 ], [ 29.758415689655173, -1.506835138461538 ], [ 29.758415689655173, -1.506565661538461 ], [ 29.758146206896551, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7046, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.506565661538461 ], [ 29.758415689655173, -1.506835138461538 ], [ 29.758685172413795, -1.506835138461538 ], [ 29.758685172413795, -1.506565661538461 ], [ 29.758415689655173, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7047, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.506565661538461 ], [ 29.758685172413795, -1.506835138461538 ], [ 29.758954655172413, -1.506835138461538 ], [ 29.758954655172413, -1.506565661538461 ], [ 29.758685172413795, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7048, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.506565661538461 ], [ 29.758954655172413, -1.506835138461538 ], [ 29.759224137931035, -1.506835138461538 ], [ 29.759224137931035, -1.506565661538461 ], [ 29.758954655172413, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7049, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.506565661538461 ], [ 29.759224137931035, -1.506835138461538 ], [ 29.759493620689657, -1.506835138461538 ], [ 29.759493620689657, -1.506565661538461 ], [ 29.759224137931035, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7050, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.506565661538461 ], [ 29.759493620689657, -1.506835138461538 ], [ 29.759763103448275, -1.506835138461538 ], [ 29.759763103448275, -1.506565661538461 ], [ 29.759493620689657, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7051, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.506565661538461 ], [ 29.759763103448275, -1.506835138461538 ], [ 29.760032586206897, -1.506835138461538 ], [ 29.760032586206897, -1.506565661538461 ], [ 29.759763103448275, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7052, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.506565661538461 ], [ 29.760032586206897, -1.506835138461538 ], [ 29.760302068965519, -1.506835138461538 ], [ 29.760302068965519, -1.506565661538461 ], [ 29.760032586206897, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7053, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.506565661538461 ], [ 29.760302068965519, -1.507104615384615 ], [ 29.760571551724137, -1.507104615384615 ], [ 29.760571551724137, -1.506565661538461 ], [ 29.760302068965519, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7054, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.506565661538461 ], [ 29.760571551724137, -1.506835138461538 ], [ 29.760841034482759, -1.506835138461538 ], [ 29.760841034482759, -1.506565661538461 ], [ 29.760571551724137, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7055, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.506565661538461 ], [ 29.76138, -1.507104615384615 ], [ 29.761649482758621, -1.507104615384615 ], [ 29.761649482758621, -1.506565661538461 ], [ 29.76138, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7056, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.506565661538461 ], [ 29.762188448275861, -1.507104615384615 ], [ 29.762457931034483, -1.507104615384615 ], [ 29.762457931034483, -1.506565661538461 ], [ 29.762188448275861, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7057, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.506565661538461 ], [ 29.762996896551723, -1.506835138461538 ], [ 29.763266379310345, -1.506835138461538 ], [ 29.763266379310345, -1.506565661538461 ], [ 29.762996896551723, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7058, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.506565661538461 ], [ 29.763266379310345, -1.506835138461538 ], [ 29.763535862068967, -1.506835138461538 ], [ 29.763535862068967, -1.506565661538461 ], [ 29.763266379310345, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7059, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.506565661538461 ], [ 29.763535862068967, -1.506835138461538 ], [ 29.763805344827588, -1.506835138461538 ], [ 29.763805344827588, -1.506565661538461 ], [ 29.763535862068967, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7060, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.506565661538461 ], [ 29.764074827586207, -1.506835138461538 ], [ 29.764344310344828, -1.506835138461538 ], [ 29.764344310344828, -1.506565661538461 ], [ 29.764074827586207, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7061, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.506565661538461 ], [ 29.764344310344828, -1.506835138461538 ], [ 29.76461379310345, -1.506835138461538 ], [ 29.76461379310345, -1.506565661538461 ], [ 29.764344310344828, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7062, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.506565661538461 ], [ 29.76461379310345, -1.506835138461538 ], [ 29.764883275862069, -1.506835138461538 ], [ 29.764883275862069, -1.506565661538461 ], [ 29.76461379310345, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7063, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.506565661538461 ], [ 29.764883275862069, -1.506835138461538 ], [ 29.76515275862069, -1.506835138461538 ], [ 29.76515275862069, -1.506565661538461 ], [ 29.764883275862069, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7064, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.506565661538461 ], [ 29.76515275862069, -1.506835138461538 ], [ 29.765961206896552, -1.506835138461538 ], [ 29.765961206896552, -1.506565661538461 ], [ 29.76515275862069, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7065, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.506835138461538 ], [ 29.720957586206897, -1.507104615384615 ], [ 29.721227068965518, -1.507104615384615 ], [ 29.721227068965518, -1.506835138461538 ], [ 29.720957586206897, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7066, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.506835138461538 ], [ 29.72203551724138, -1.507104615384615 ], [ 29.722305, -1.507104615384615 ], [ 29.722305, -1.506835138461538 ], [ 29.72203551724138, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7067, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.506835138461538 ], [ 29.722305, -1.507104615384615 ], [ 29.72257448275862, -1.507104615384615 ], [ 29.72257448275862, -1.506835138461538 ], [ 29.722305, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7068, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.506835138461538 ], [ 29.72257448275862, -1.507104615384615 ], [ 29.722843965517242, -1.507104615384615 ], [ 29.722843965517242, -1.506835138461538 ], [ 29.72257448275862, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7069, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.506835138461538 ], [ 29.722843965517242, -1.507104615384615 ], [ 29.723113448275861, -1.507104615384615 ], [ 29.723113448275861, -1.506835138461538 ], [ 29.722843965517242, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7070, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.506835138461538 ], [ 29.723113448275861, -1.507104615384615 ], [ 29.723382931034482, -1.507104615384615 ], [ 29.723382931034482, -1.506835138461538 ], [ 29.723113448275861, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7071, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.506835138461538 ], [ 29.723382931034482, -1.507104615384615 ], [ 29.723652413793104, -1.507104615384615 ], [ 29.723652413793104, -1.506835138461538 ], [ 29.723382931034482, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7072, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.506835138461538 ], [ 29.724460862068966, -1.507104615384615 ], [ 29.724730344827588, -1.507104615384615 ], [ 29.724730344827588, -1.506835138461538 ], [ 29.724460862068966, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7073, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.506835138461538 ], [ 29.724730344827588, -1.507104615384615 ], [ 29.724999827586206, -1.507104615384615 ], [ 29.724999827586206, -1.506835138461538 ], [ 29.724730344827588, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7074, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.506835138461538 ], [ 29.72553879310345, -1.507104615384615 ], [ 29.725808275862068, -1.507104615384615 ], [ 29.725808275862068, -1.506835138461538 ], [ 29.72553879310345, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7075, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.506835138461538 ], [ 29.725808275862068, -1.507104615384615 ], [ 29.72607775862069, -1.507104615384615 ], [ 29.72607775862069, -1.506835138461538 ], [ 29.725808275862068, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7076, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.506835138461538 ], [ 29.726347241379312, -1.507104615384615 ], [ 29.72661672413793, -1.507104615384615 ], [ 29.72661672413793, -1.506835138461538 ], [ 29.726347241379312, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7077, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.506835138461538 ], [ 29.72661672413793, -1.507104615384615 ], [ 29.726886206896552, -1.507104615384615 ], [ 29.726886206896552, -1.506835138461538 ], [ 29.72661672413793, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7078, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.506835138461538 ], [ 29.727425172413792, -1.507104615384615 ], [ 29.727694655172414, -1.507104615384615 ], [ 29.727694655172414, -1.506835138461538 ], [ 29.727425172413792, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7079, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.506835138461538 ], [ 29.727694655172414, -1.507104615384615 ], [ 29.727964137931036, -1.507104615384615 ], [ 29.727964137931036, -1.506835138461538 ], [ 29.727694655172414, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7080, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.506835138461538 ], [ 29.727964137931036, -1.507104615384615 ], [ 29.728233620689654, -1.507104615384615 ], [ 29.728233620689654, -1.506835138461538 ], [ 29.727964137931036, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7081, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.506835138461538 ], [ 29.728233620689654, -1.507104615384615 ], [ 29.728503103448276, -1.507104615384615 ], [ 29.728503103448276, -1.506835138461538 ], [ 29.728233620689654, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7082, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.506835138461538 ], [ 29.728503103448276, -1.507104615384615 ], [ 29.728772586206897, -1.507104615384615 ], [ 29.728772586206897, -1.506835138461538 ], [ 29.728503103448276, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7083, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.506835138461538 ], [ 29.728772586206897, -1.507104615384615 ], [ 29.729042068965516, -1.507104615384615 ], [ 29.729042068965516, -1.506835138461538 ], [ 29.728772586206897, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7084, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.506835138461538 ], [ 29.729042068965516, -1.507104615384615 ], [ 29.729311551724138, -1.507104615384615 ], [ 29.729311551724138, -1.506835138461538 ], [ 29.729042068965516, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7085, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.506835138461538 ], [ 29.729311551724138, -1.507104615384615 ], [ 29.729850517241381, -1.507104615384615 ], [ 29.729850517241381, -1.506835138461538 ], [ 29.729311551724138, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7086, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.506835138461538 ], [ 29.729850517241381, -1.507104615384615 ], [ 29.73012, -1.507104615384615 ], [ 29.73012, -1.506835138461538 ], [ 29.729850517241381, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7087, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.506835138461538 ], [ 29.73012, -1.507104615384615 ], [ 29.730389482758621, -1.507104615384615 ], [ 29.730389482758621, -1.506835138461538 ], [ 29.73012, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7088, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.506835138461538 ], [ 29.730389482758621, -1.507104615384615 ], [ 29.730658965517243, -1.507104615384615 ], [ 29.730658965517243, -1.506835138461538 ], [ 29.730389482758621, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7089, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.506835138461538 ], [ 29.730658965517243, -1.507104615384615 ], [ 29.730928448275861, -1.507104615384615 ], [ 29.730928448275861, -1.506835138461538 ], [ 29.730658965517243, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7090, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.506835138461538 ], [ 29.730928448275861, -1.507104615384615 ], [ 29.731197931034483, -1.507104615384615 ], [ 29.731197931034483, -1.506835138461538 ], [ 29.730928448275861, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7091, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.506835138461538 ], [ 29.739282413793102, -1.507104615384615 ], [ 29.739551896551724, -1.507104615384615 ], [ 29.739551896551724, -1.506835138461538 ], [ 29.739282413793102, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7092, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.506835138461538 ], [ 29.739551896551724, -1.507104615384615 ], [ 29.74089931034483, -1.507104615384615 ], [ 29.74089931034483, -1.506835138461538 ], [ 29.739551896551724, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7093, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.506835138461538 ], [ 29.742246724137932, -1.507104615384615 ], [ 29.742516206896553, -1.507104615384615 ], [ 29.742516206896553, -1.506835138461538 ], [ 29.742246724137932, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7094, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.506835138461538 ], [ 29.753026034482758, -1.507104615384615 ], [ 29.75329551724138, -1.507104615384615 ], [ 29.75329551724138, -1.506835138461538 ], [ 29.753026034482758, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7095, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.506835138461538 ], [ 29.75329551724138, -1.507104615384615 ], [ 29.753565, -1.507104615384615 ], [ 29.753565, -1.506835138461538 ], [ 29.75329551724138, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7096, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.506835138461538 ], [ 29.753565, -1.507104615384615 ], [ 29.75383448275862, -1.507104615384615 ], [ 29.75383448275862, -1.506835138461538 ], [ 29.753565, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7097, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.506835138461538 ], [ 29.754642931034482, -1.507104615384615 ], [ 29.754912413793104, -1.507104615384615 ], [ 29.754912413793104, -1.506835138461538 ], [ 29.754642931034482, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7098, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.506835138461538 ], [ 29.754912413793104, -1.507104615384615 ], [ 29.755181896551726, -1.507104615384615 ], [ 29.755181896551726, -1.506835138461538 ], [ 29.754912413793104, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7099, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.506835138461538 ], [ 29.755720862068966, -1.507104615384615 ], [ 29.755990344827588, -1.507104615384615 ], [ 29.755990344827588, -1.506835138461538 ], [ 29.755720862068966, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7100, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.506835138461538 ], [ 29.755990344827588, -1.507104615384615 ], [ 29.756259827586206, -1.507104615384615 ], [ 29.756259827586206, -1.506835138461538 ], [ 29.755990344827588, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7101, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.506835138461538 ], [ 29.756259827586206, -1.507104615384615 ], [ 29.756798793103449, -1.507104615384615 ], [ 29.756798793103449, -1.506835138461538 ], [ 29.756259827586206, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7102, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.506835138461538 ], [ 29.756798793103449, -1.507104615384615 ], [ 29.757068275862068, -1.507104615384615 ], [ 29.757068275862068, -1.506835138461538 ], [ 29.756798793103449, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7103, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.506835138461538 ], [ 29.757068275862068, -1.507104615384615 ], [ 29.75733775862069, -1.507104615384615 ], [ 29.75733775862069, -1.506835138461538 ], [ 29.757068275862068, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7104, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.506835138461538 ], [ 29.75733775862069, -1.507104615384615 ], [ 29.757607241379311, -1.507104615384615 ], [ 29.757607241379311, -1.506835138461538 ], [ 29.75733775862069, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7105, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.506835138461538 ], [ 29.757607241379311, -1.507104615384615 ], [ 29.757876724137933, -1.507104615384615 ], [ 29.757876724137933, -1.506835138461538 ], [ 29.757607241379311, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7106, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.506835138461538 ], [ 29.757876724137933, -1.507104615384615 ], [ 29.758146206896551, -1.507104615384615 ], [ 29.758146206896551, -1.506835138461538 ], [ 29.757876724137933, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7107, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.506835138461538 ], [ 29.758146206896551, -1.507104615384615 ], [ 29.758415689655173, -1.507104615384615 ], [ 29.758415689655173, -1.506835138461538 ], [ 29.758146206896551, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7108, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.506835138461538 ], [ 29.758415689655173, -1.507104615384615 ], [ 29.758685172413795, -1.507104615384615 ], [ 29.758685172413795, -1.506835138461538 ], [ 29.758415689655173, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7109, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.506835138461538 ], [ 29.758685172413795, -1.507104615384615 ], [ 29.758954655172413, -1.507104615384615 ], [ 29.758954655172413, -1.506835138461538 ], [ 29.758685172413795, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7110, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.506835138461538 ], [ 29.758954655172413, -1.507104615384615 ], [ 29.759224137931035, -1.507104615384615 ], [ 29.759224137931035, -1.506835138461538 ], [ 29.758954655172413, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7111, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.506835138461538 ], [ 29.759224137931035, -1.507104615384615 ], [ 29.759493620689657, -1.507104615384615 ], [ 29.759493620689657, -1.506835138461538 ], [ 29.759224137931035, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7112, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.506835138461538 ], [ 29.759493620689657, -1.507104615384615 ], [ 29.759763103448275, -1.507104615384615 ], [ 29.759763103448275, -1.506835138461538 ], [ 29.759493620689657, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7113, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.506835138461538 ], [ 29.760032586206897, -1.507104615384615 ], [ 29.760302068965519, -1.507104615384615 ], [ 29.760302068965519, -1.506835138461538 ], [ 29.760032586206897, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7114, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.506835138461538 ], [ 29.762457931034483, -1.507104615384615 ], [ 29.762727413793105, -1.507104615384615 ], [ 29.762727413793105, -1.506835138461538 ], [ 29.762457931034483, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7115, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.506835138461538 ], [ 29.762727413793105, -1.507104615384615 ], [ 29.762996896551723, -1.507104615384615 ], [ 29.762996896551723, -1.506835138461538 ], [ 29.762727413793105, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7116, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.506835138461538 ], [ 29.762996896551723, -1.507104615384615 ], [ 29.763266379310345, -1.507104615384615 ], [ 29.763266379310345, -1.506835138461538 ], [ 29.762996896551723, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7117, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.506835138461538 ], [ 29.763266379310345, -1.507104615384615 ], [ 29.763535862068967, -1.507104615384615 ], [ 29.763535862068967, -1.506835138461538 ], [ 29.763266379310345, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7118, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.506835138461538 ], [ 29.763535862068967, -1.507104615384615 ], [ 29.763805344827588, -1.507104615384615 ], [ 29.763805344827588, -1.506835138461538 ], [ 29.763535862068967, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7119, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.506835138461538 ], [ 29.763805344827588, -1.507104615384615 ], [ 29.764074827586207, -1.507104615384615 ], [ 29.764074827586207, -1.506835138461538 ], [ 29.763805344827588, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7120, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.506835138461538 ], [ 29.764074827586207, -1.507104615384615 ], [ 29.764344310344828, -1.507104615384615 ], [ 29.764344310344828, -1.506835138461538 ], [ 29.764074827586207, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7121, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.506835138461538 ], [ 29.764344310344828, -1.507104615384615 ], [ 29.76461379310345, -1.507104615384615 ], [ 29.76461379310345, -1.506835138461538 ], [ 29.764344310344828, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7122, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.506835138461538 ], [ 29.76461379310345, -1.507104615384615 ], [ 29.764883275862069, -1.507104615384615 ], [ 29.764883275862069, -1.506835138461538 ], [ 29.76461379310345, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7123, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.506835138461538 ], [ 29.764883275862069, -1.507104615384615 ], [ 29.765422241379312, -1.507104615384615 ], [ 29.765422241379312, -1.506835138461538 ], [ 29.764883275862069, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7124, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.506835138461538 ], [ 29.765422241379312, -1.507104615384615 ], [ 29.765961206896552, -1.507104615384615 ], [ 29.765961206896552, -1.506835138461538 ], [ 29.765422241379312, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7125, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.506835138461538 ], [ 29.765961206896552, -1.507104615384615 ], [ 29.766230689655174, -1.507104615384615 ], [ 29.766230689655174, -1.506835138461538 ], [ 29.765961206896552, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7126, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.502254030769231 ], [ 29.751409137931034, -1.507374092307692 ], [ 29.751678620689656, -1.507374092307692 ], [ 29.751678620689656, -1.502254030769231 ], [ 29.751409137931034, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7127, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.502254030769231 ], [ 29.751678620689656, -1.507374092307692 ], [ 29.751948103448278, -1.507374092307692 ], [ 29.751948103448278, -1.502254030769231 ], [ 29.751678620689656, -1.502254030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7128, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751139655172413, -1.502523507692308 ], [ 29.751139655172413, -1.507913046153846 ], [ 29.751409137931034, -1.507913046153846 ], [ 29.751409137931034, -1.502523507692308 ], [ 29.751139655172413, -1.502523507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7129, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.505487753846154 ], [ 29.731736896551723, -1.507374092307692 ], [ 29.732006379310345, -1.507374092307692 ], [ 29.732006379310345, -1.505487753846154 ], [ 29.731736896551723, -1.505487753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7130, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.506296184615385 ], [ 29.73739603448276, -1.508182523076923 ], [ 29.737665517241378, -1.508182523076923 ], [ 29.737665517241378, -1.506296184615385 ], [ 29.73739603448276, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7131, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.506296184615385 ], [ 29.737665517241378, -1.508182523076923 ], [ 29.737935, -1.508182523076923 ], [ 29.737935, -1.506296184615385 ], [ 29.737665517241378, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7132, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.506296184615385 ], [ 29.738204482758622, -1.507913046153846 ], [ 29.738743448275862, -1.507913046153846 ], [ 29.738743448275862, -1.506296184615385 ], [ 29.738204482758622, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7133, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.506296184615385 ], [ 29.738743448275862, -1.507913046153846 ], [ 29.739282413793102, -1.507913046153846 ], [ 29.739282413793102, -1.507374092307692 ], [ 29.739012931034484, -1.507374092307692 ], [ 29.739012931034484, -1.506296184615385 ], [ 29.738743448275862, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7134, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.506296184615385 ], [ 29.739012931034484, -1.507374092307692 ], [ 29.739282413793102, -1.507374092307692 ], [ 29.739282413793102, -1.506296184615385 ], [ 29.739012931034484, -1.506296184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7135, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.506565661538461 ], [ 29.737126551724138, -1.508452 ], [ 29.73739603448276, -1.508452 ], [ 29.73739603448276, -1.506565661538461 ], [ 29.737126551724138, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7136, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.506565661538461 ], [ 29.737935, -1.507913046153846 ], [ 29.738204482758622, -1.507913046153846 ], [ 29.738204482758622, -1.506565661538461 ], [ 29.737935, -1.506565661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7137, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.506835138461538 ], [ 29.720688103448275, -1.507374092307692 ], [ 29.720957586206897, -1.507374092307692 ], [ 29.720957586206897, -1.506835138461538 ], [ 29.720688103448275, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7138, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.506835138461538 ], [ 29.721766034482759, -1.507374092307692 ], [ 29.72203551724138, -1.507374092307692 ], [ 29.72203551724138, -1.506835138461538 ], [ 29.721766034482759, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7139, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.506835138461538 ], [ 29.724191379310344, -1.507374092307692 ], [ 29.724460862068966, -1.507374092307692 ], [ 29.724460862068966, -1.506835138461538 ], [ 29.724191379310344, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7140, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.506835138461538 ], [ 29.725269310344828, -1.507374092307692 ], [ 29.72553879310345, -1.507374092307692 ], [ 29.72553879310345, -1.506835138461538 ], [ 29.725269310344828, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7141, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.506835138461538 ], [ 29.72607775862069, -1.507374092307692 ], [ 29.726347241379312, -1.507374092307692 ], [ 29.726347241379312, -1.506835138461538 ], [ 29.72607775862069, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7142, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.506835138461538 ], [ 29.727155689655174, -1.507374092307692 ], [ 29.727425172413792, -1.507374092307692 ], [ 29.727425172413792, -1.506835138461538 ], [ 29.727155689655174, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7143, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.506835138461538 ], [ 29.74197724137931, -1.508721476923077 ], [ 29.742246724137932, -1.508721476923077 ], [ 29.742246724137932, -1.506835138461538 ], [ 29.74197724137931, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7144, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.506835138461538 ], [ 29.75383448275862, -1.507374092307692 ], [ 29.754103965517242, -1.507374092307692 ], [ 29.754103965517242, -1.506835138461538 ], [ 29.75383448275862, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7145, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.506835138461538 ], [ 29.754103965517242, -1.507374092307692 ], [ 29.754373448275864, -1.507374092307692 ], [ 29.754373448275864, -1.506835138461538 ], [ 29.754103965517242, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7146, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.506835138461538 ], [ 29.755181896551726, -1.507374092307692 ], [ 29.755451379310344, -1.507374092307692 ], [ 29.755451379310344, -1.506835138461538 ], [ 29.755181896551726, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7147, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.506835138461538 ], [ 29.759763103448275, -1.507374092307692 ], [ 29.760032586206897, -1.507374092307692 ], [ 29.760032586206897, -1.506835138461538 ], [ 29.759763103448275, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7148, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.506835138461538 ], [ 29.760571551724137, -1.507374092307692 ], [ 29.760841034482759, -1.507374092307692 ], [ 29.760841034482759, -1.506835138461538 ], [ 29.760571551724137, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7149, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.506835138461538 ], [ 29.760841034482759, -1.507374092307692 ], [ 29.761110517241381, -1.507374092307692 ], [ 29.761110517241381, -1.506835138461538 ], [ 29.760841034482759, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7150, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.506835138461538 ], [ 29.761649482758621, -1.507374092307692 ], [ 29.761918965517243, -1.507374092307692 ], [ 29.761918965517243, -1.506835138461538 ], [ 29.761649482758621, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7151, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.507104615384615 ], [ 29.720957586206897, -1.507913046153846 ], [ 29.721227068965518, -1.507913046153846 ], [ 29.721227068965518, -1.507104615384615 ], [ 29.720957586206897, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7152, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.507104615384615 ], [ 29.721227068965518, -1.507913046153846 ], [ 29.721496551724137, -1.507913046153846 ], [ 29.721496551724137, -1.507104615384615 ], [ 29.721227068965518, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7153, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.507104615384615 ], [ 29.721496551724137, -1.507374092307692 ], [ 29.721766034482759, -1.507374092307692 ], [ 29.721766034482759, -1.507104615384615 ], [ 29.721496551724137, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7154, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.507104615384615 ], [ 29.72203551724138, -1.507374092307692 ], [ 29.722305, -1.507374092307692 ], [ 29.722305, -1.507104615384615 ], [ 29.72203551724138, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7155, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.507104615384615 ], [ 29.722305, -1.507374092307692 ], [ 29.72257448275862, -1.507374092307692 ], [ 29.72257448275862, -1.507104615384615 ], [ 29.722305, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7156, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.507104615384615 ], [ 29.72257448275862, -1.507374092307692 ], [ 29.722843965517242, -1.507374092307692 ], [ 29.722843965517242, -1.507104615384615 ], [ 29.72257448275862, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7157, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.507104615384615 ], [ 29.722843965517242, -1.507374092307692 ], [ 29.723113448275861, -1.507374092307692 ], [ 29.723113448275861, -1.507104615384615 ], [ 29.722843965517242, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7158, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.507104615384615 ], [ 29.723113448275861, -1.507374092307692 ], [ 29.723382931034482, -1.507374092307692 ], [ 29.723382931034482, -1.507104615384615 ], [ 29.723113448275861, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7159, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.507104615384615 ], [ 29.723382931034482, -1.507374092307692 ], [ 29.723652413793104, -1.507374092307692 ], [ 29.723652413793104, -1.507104615384615 ], [ 29.723382931034482, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7160, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.507104615384615 ], [ 29.723652413793104, -1.507374092307692 ], [ 29.723921896551722, -1.507374092307692 ], [ 29.723921896551722, -1.507104615384615 ], [ 29.723652413793104, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7161, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.507104615384615 ], [ 29.723921896551722, -1.507374092307692 ], [ 29.724191379310344, -1.507374092307692 ], [ 29.724191379310344, -1.507104615384615 ], [ 29.723921896551722, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7162, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.507104615384615 ], [ 29.724460862068966, -1.507913046153846 ], [ 29.724730344827588, -1.507913046153846 ], [ 29.724730344827588, -1.507104615384615 ], [ 29.724460862068966, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7163, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.507104615384615 ], [ 29.724730344827588, -1.507374092307692 ], [ 29.724999827586206, -1.507374092307692 ], [ 29.724999827586206, -1.507104615384615 ], [ 29.724730344827588, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7164, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.507104615384615 ], [ 29.724999827586206, -1.507374092307692 ], [ 29.725269310344828, -1.507374092307692 ], [ 29.725269310344828, -1.507104615384615 ], [ 29.724999827586206, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7165, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.507104615384615 ], [ 29.72553879310345, -1.507913046153846 ], [ 29.725808275862068, -1.507913046153846 ], [ 29.725808275862068, -1.507104615384615 ], [ 29.72553879310345, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7166, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.507104615384615 ], [ 29.725808275862068, -1.507374092307692 ], [ 29.72607775862069, -1.507374092307692 ], [ 29.72607775862069, -1.507104615384615 ], [ 29.725808275862068, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7167, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.507104615384615 ], [ 29.726347241379312, -1.507913046153846 ], [ 29.72661672413793, -1.507913046153846 ], [ 29.72661672413793, -1.507104615384615 ], [ 29.726347241379312, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7168, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.507104615384615 ], [ 29.72661672413793, -1.507913046153846 ], [ 29.726886206896552, -1.507913046153846 ], [ 29.726886206896552, -1.507104615384615 ], [ 29.72661672413793, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7169, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.507104615384615 ], [ 29.726886206896552, -1.507374092307692 ], [ 29.727155689655174, -1.507374092307692 ], [ 29.727155689655174, -1.507104615384615 ], [ 29.726886206896552, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7170, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.507104615384615 ], [ 29.727425172413792, -1.507913046153846 ], [ 29.727694655172414, -1.507913046153846 ], [ 29.727694655172414, -1.507104615384615 ], [ 29.727425172413792, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7171, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.507104615384615 ], [ 29.727694655172414, -1.507374092307692 ], [ 29.727964137931036, -1.507374092307692 ], [ 29.727964137931036, -1.507104615384615 ], [ 29.727694655172414, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7172, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.507104615384615 ], [ 29.727964137931036, -1.507374092307692 ], [ 29.728233620689654, -1.507374092307692 ], [ 29.728233620689654, -1.507104615384615 ], [ 29.727964137931036, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7173, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.507104615384615 ], [ 29.728233620689654, -1.507374092307692 ], [ 29.728503103448276, -1.507374092307692 ], [ 29.728503103448276, -1.507104615384615 ], [ 29.728233620689654, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7174, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.507104615384615 ], [ 29.728503103448276, -1.507374092307692 ], [ 29.728772586206897, -1.507374092307692 ], [ 29.728772586206897, -1.507104615384615 ], [ 29.728503103448276, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7175, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.507104615384615 ], [ 29.728772586206897, -1.507374092307692 ], [ 29.729042068965516, -1.507374092307692 ], [ 29.729042068965516, -1.507104615384615 ], [ 29.728772586206897, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7176, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.507104615384615 ], [ 29.729042068965516, -1.507374092307692 ], [ 29.729311551724138, -1.507374092307692 ], [ 29.729311551724138, -1.507104615384615 ], [ 29.729042068965516, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7177, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.507104615384615 ], [ 29.729311551724138, -1.507374092307692 ], [ 29.729850517241381, -1.507374092307692 ], [ 29.729850517241381, -1.507104615384615 ], [ 29.729311551724138, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7178, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.507104615384615 ], [ 29.729850517241381, -1.507374092307692 ], [ 29.73012, -1.507374092307692 ], [ 29.73012, -1.507104615384615 ], [ 29.729850517241381, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7179, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.507104615384615 ], [ 29.73012, -1.507374092307692 ], [ 29.730389482758621, -1.507374092307692 ], [ 29.730389482758621, -1.507104615384615 ], [ 29.73012, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7180, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.507104615384615 ], [ 29.730389482758621, -1.507374092307692 ], [ 29.730658965517243, -1.507374092307692 ], [ 29.730658965517243, -1.507104615384615 ], [ 29.730389482758621, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7181, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.507104615384615 ], [ 29.730658965517243, -1.507374092307692 ], [ 29.730928448275861, -1.507374092307692 ], [ 29.730928448275861, -1.507104615384615 ], [ 29.730658965517243, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7182, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.507104615384615 ], [ 29.730928448275861, -1.507374092307692 ], [ 29.731197931034483, -1.507374092307692 ], [ 29.731197931034483, -1.507104615384615 ], [ 29.730928448275861, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7183, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.507104615384615 ], [ 29.731197931034483, -1.507374092307692 ], [ 29.731467413793105, -1.507374092307692 ], [ 29.731467413793105, -1.507104615384615 ], [ 29.731197931034483, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7184, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.507104615384615 ], [ 29.731467413793105, -1.507374092307692 ], [ 29.731736896551723, -1.507374092307692 ], [ 29.731736896551723, -1.507104615384615 ], [ 29.731467413793105, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7185, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.507104615384615 ], [ 29.739282413793102, -1.507374092307692 ], [ 29.739551896551724, -1.507374092307692 ], [ 29.739551896551724, -1.507104615384615 ], [ 29.739282413793102, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7186, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.506835138461538 ], [ 29.741168793103448, -1.506835138461538 ], [ 29.741168793103448, -1.507374092307692 ], [ 29.739551896551724, -1.507374092307692 ], [ 29.739551896551724, -1.507104615384615 ], [ 29.74089931034483, -1.507104615384615 ], [ 29.74089931034483, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7187, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.507104615384615 ], [ 29.742246724137932, -1.508452 ], [ 29.742516206896553, -1.508452 ], [ 29.742516206896553, -1.507104615384615 ], [ 29.742246724137932, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7188, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.507104615384615 ], [ 29.751948103448278, -1.507374092307692 ], [ 29.752217586206896, -1.507374092307692 ], [ 29.752217586206896, -1.507104615384615 ], [ 29.751948103448278, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7189, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.507104615384615 ], [ 29.752217586206896, -1.507913046153846 ], [ 29.752487068965518, -1.507913046153846 ], [ 29.752487068965518, -1.507104615384615 ], [ 29.752217586206896, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7190, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.507104615384615 ], [ 29.752487068965518, -1.507374092307692 ], [ 29.75275655172414, -1.507374092307692 ], [ 29.75275655172414, -1.507104615384615 ], [ 29.752487068965518, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7191, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.507104615384615 ], [ 29.75275655172414, -1.507374092307692 ], [ 29.753026034482758, -1.507374092307692 ], [ 29.753026034482758, -1.507104615384615 ], [ 29.75275655172414, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7192, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.507104615384615 ], [ 29.753026034482758, -1.507374092307692 ], [ 29.75329551724138, -1.507374092307692 ], [ 29.75329551724138, -1.507104615384615 ], [ 29.753026034482758, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7193, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.507104615384615 ], [ 29.75329551724138, -1.507913046153846 ], [ 29.753565, -1.507913046153846 ], [ 29.753565, -1.507104615384615 ], [ 29.75329551724138, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7194, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.507104615384615 ], [ 29.753565, -1.507913046153846 ], [ 29.75383448275862, -1.507913046153846 ], [ 29.75383448275862, -1.507104615384615 ], [ 29.753565, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7195, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.507104615384615 ], [ 29.754373448275864, -1.507374092307692 ], [ 29.754642931034482, -1.507374092307692 ], [ 29.754642931034482, -1.507104615384615 ], [ 29.754373448275864, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7196, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.507104615384615 ], [ 29.754642931034482, -1.507374092307692 ], [ 29.754912413793104, -1.507374092307692 ], [ 29.754912413793104, -1.507104615384615 ], [ 29.754642931034482, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7197, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.507104615384615 ], [ 29.754912413793104, -1.507913046153846 ], [ 29.755181896551726, -1.507913046153846 ], [ 29.755181896551726, -1.507104615384615 ], [ 29.754912413793104, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7198, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.507104615384615 ], [ 29.755451379310344, -1.507374092307692 ], [ 29.755720862068966, -1.507374092307692 ], [ 29.755720862068966, -1.507104615384615 ], [ 29.755451379310344, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7199, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.507104615384615 ], [ 29.755720862068966, -1.507374092307692 ], [ 29.755990344827588, -1.507374092307692 ], [ 29.755990344827588, -1.507104615384615 ], [ 29.755720862068966, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7200, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.507104615384615 ], [ 29.755990344827588, -1.507374092307692 ], [ 29.756259827586206, -1.507374092307692 ], [ 29.756259827586206, -1.507104615384615 ], [ 29.755990344827588, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7201, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.507104615384615 ], [ 29.756259827586206, -1.507374092307692 ], [ 29.756798793103449, -1.507374092307692 ], [ 29.756798793103449, -1.507104615384615 ], [ 29.756259827586206, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7202, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.507104615384615 ], [ 29.756798793103449, -1.507374092307692 ], [ 29.757068275862068, -1.507374092307692 ], [ 29.757068275862068, -1.507104615384615 ], [ 29.756798793103449, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7203, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.507104615384615 ], [ 29.757068275862068, -1.507374092307692 ], [ 29.75733775862069, -1.507374092307692 ], [ 29.75733775862069, -1.507104615384615 ], [ 29.757068275862068, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7204, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.507104615384615 ], [ 29.75733775862069, -1.507374092307692 ], [ 29.757607241379311, -1.507374092307692 ], [ 29.757607241379311, -1.507104615384615 ], [ 29.75733775862069, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7205, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.507104615384615 ], [ 29.757607241379311, -1.507374092307692 ], [ 29.757876724137933, -1.507374092307692 ], [ 29.757876724137933, -1.507104615384615 ], [ 29.757607241379311, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7206, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.507104615384615 ], [ 29.757876724137933, -1.507374092307692 ], [ 29.758146206896551, -1.507374092307692 ], [ 29.758146206896551, -1.507104615384615 ], [ 29.757876724137933, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7207, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.507104615384615 ], [ 29.758146206896551, -1.507374092307692 ], [ 29.758415689655173, -1.507374092307692 ], [ 29.758415689655173, -1.507104615384615 ], [ 29.758146206896551, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7208, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.507104615384615 ], [ 29.758415689655173, -1.507374092307692 ], [ 29.758685172413795, -1.507374092307692 ], [ 29.758685172413795, -1.507104615384615 ], [ 29.758415689655173, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7209, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.507104615384615 ], [ 29.758685172413795, -1.507374092307692 ], [ 29.758954655172413, -1.507374092307692 ], [ 29.758954655172413, -1.507104615384615 ], [ 29.758685172413795, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7210, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.507104615384615 ], [ 29.758954655172413, -1.507374092307692 ], [ 29.759224137931035, -1.507374092307692 ], [ 29.759224137931035, -1.507104615384615 ], [ 29.758954655172413, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7211, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.507104615384615 ], [ 29.759224137931035, -1.507913046153846 ], [ 29.759493620689657, -1.507913046153846 ], [ 29.759493620689657, -1.507104615384615 ], [ 29.759224137931035, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7212, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.507104615384615 ], [ 29.759493620689657, -1.507374092307692 ], [ 29.759763103448275, -1.507374092307692 ], [ 29.759763103448275, -1.507104615384615 ], [ 29.759493620689657, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7213, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.507104615384615 ], [ 29.760032586206897, -1.507913046153846 ], [ 29.760302068965519, -1.507913046153846 ], [ 29.760302068965519, -1.507104615384615 ], [ 29.760032586206897, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7214, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.507104615384615 ], [ 29.760302068965519, -1.507913046153846 ], [ 29.760571551724137, -1.507913046153846 ], [ 29.760571551724137, -1.507104615384615 ], [ 29.760302068965519, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7215, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.507104615384615 ], [ 29.761110517241381, -1.507374092307692 ], [ 29.76138, -1.507374092307692 ], [ 29.76138, -1.507104615384615 ], [ 29.761110517241381, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7216, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.507104615384615 ], [ 29.76138, -1.507913046153846 ], [ 29.761649482758621, -1.507913046153846 ], [ 29.761649482758621, -1.507104615384615 ], [ 29.76138, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7217, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.507104615384615 ], [ 29.761918965517243, -1.507374092307692 ], [ 29.762188448275861, -1.507374092307692 ], [ 29.762188448275861, -1.507104615384615 ], [ 29.761918965517243, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7218, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.507104615384615 ], [ 29.762188448275861, -1.507374092307692 ], [ 29.762457931034483, -1.507374092307692 ], [ 29.762457931034483, -1.507104615384615 ], [ 29.762188448275861, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7219, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.507104615384615 ], [ 29.762457931034483, -1.507374092307692 ], [ 29.762727413793105, -1.507374092307692 ], [ 29.762727413793105, -1.507104615384615 ], [ 29.762457931034483, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7220, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.507104615384615 ], [ 29.762727413793105, -1.507374092307692 ], [ 29.762996896551723, -1.507374092307692 ], [ 29.762996896551723, -1.507104615384615 ], [ 29.762727413793105, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7221, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.507104615384615 ], [ 29.762996896551723, -1.507374092307692 ], [ 29.763266379310345, -1.507374092307692 ], [ 29.763266379310345, -1.507104615384615 ], [ 29.762996896551723, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7222, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.507104615384615 ], [ 29.763266379310345, -1.507374092307692 ], [ 29.763535862068967, -1.507374092307692 ], [ 29.763535862068967, -1.507104615384615 ], [ 29.763266379310345, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7223, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.507104615384615 ], [ 29.763535862068967, -1.507374092307692 ], [ 29.763805344827588, -1.507374092307692 ], [ 29.763805344827588, -1.507104615384615 ], [ 29.763535862068967, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7224, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.507104615384615 ], [ 29.763805344827588, -1.507374092307692 ], [ 29.764074827586207, -1.507374092307692 ], [ 29.764074827586207, -1.507104615384615 ], [ 29.763805344827588, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7225, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.507104615384615 ], [ 29.764074827586207, -1.507374092307692 ], [ 29.764344310344828, -1.507374092307692 ], [ 29.764344310344828, -1.507104615384615 ], [ 29.764074827586207, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7226, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.507104615384615 ], [ 29.764344310344828, -1.507374092307692 ], [ 29.76461379310345, -1.507374092307692 ], [ 29.76461379310345, -1.507104615384615 ], [ 29.764344310344828, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7227, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.507104615384615 ], [ 29.76461379310345, -1.507374092307692 ], [ 29.764883275862069, -1.507374092307692 ], [ 29.764883275862069, -1.507104615384615 ], [ 29.76461379310345, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7228, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.507104615384615 ], [ 29.764883275862069, -1.507374092307692 ], [ 29.765422241379312, -1.507374092307692 ], [ 29.765422241379312, -1.507104615384615 ], [ 29.764883275862069, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7229, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.507104615384615 ], [ 29.765422241379312, -1.507374092307692 ], [ 29.765961206896552, -1.507374092307692 ], [ 29.765961206896552, -1.507104615384615 ], [ 29.765422241379312, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7230, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.507104615384615 ], [ 29.765961206896552, -1.507374092307692 ], [ 29.766230689655174, -1.507374092307692 ], [ 29.766230689655174, -1.507104615384615 ], [ 29.765961206896552, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7231, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.507104615384615 ], [ 29.766230689655174, -1.507374092307692 ], [ 29.766500172413792, -1.507374092307692 ], [ 29.766500172413792, -1.507104615384615 ], [ 29.766230689655174, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7232, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.507374092307692 ], [ 29.720149137931035, -1.508182523076923 ], [ 29.720688103448275, -1.508182523076923 ], [ 29.720688103448275, -1.507374092307692 ], [ 29.720149137931035, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7233, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.507374092307692 ], [ 29.720688103448275, -1.507913046153846 ], [ 29.720957586206897, -1.507913046153846 ], [ 29.720957586206897, -1.507374092307692 ], [ 29.720688103448275, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7234, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.507374092307692 ], [ 29.721496551724137, -1.508182523076923 ], [ 29.721766034482759, -1.508182523076923 ], [ 29.721766034482759, -1.507374092307692 ], [ 29.721496551724137, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7235, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.507374092307692 ], [ 29.721766034482759, -1.507913046153846 ], [ 29.72203551724138, -1.507913046153846 ], [ 29.72203551724138, -1.507374092307692 ], [ 29.721766034482759, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7236, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.507374092307692 ], [ 29.72203551724138, -1.507913046153846 ], [ 29.722305, -1.507913046153846 ], [ 29.722305, -1.507374092307692 ], [ 29.72203551724138, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7237, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.507374092307692 ], [ 29.722305, -1.507913046153846 ], [ 29.72257448275862, -1.507913046153846 ], [ 29.72257448275862, -1.507374092307692 ], [ 29.722305, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7238, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.507374092307692 ], [ 29.72257448275862, -1.507913046153846 ], [ 29.722843965517242, -1.507913046153846 ], [ 29.722843965517242, -1.507374092307692 ], [ 29.72257448275862, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7239, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.507374092307692 ], [ 29.722843965517242, -1.507913046153846 ], [ 29.723113448275861, -1.507913046153846 ], [ 29.723113448275861, -1.507374092307692 ], [ 29.722843965517242, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7240, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.507374092307692 ], [ 29.723113448275861, -1.507913046153846 ], [ 29.723382931034482, -1.507913046153846 ], [ 29.723382931034482, -1.507374092307692 ], [ 29.723113448275861, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7241, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.507374092307692 ], [ 29.723382931034482, -1.507913046153846 ], [ 29.723652413793104, -1.507913046153846 ], [ 29.723652413793104, -1.507374092307692 ], [ 29.723382931034482, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7242, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.507374092307692 ], [ 29.723652413793104, -1.507913046153846 ], [ 29.723921896551722, -1.507913046153846 ], [ 29.723921896551722, -1.507374092307692 ], [ 29.723652413793104, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7243, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.507374092307692 ], [ 29.723921896551722, -1.507913046153846 ], [ 29.724191379310344, -1.507913046153846 ], [ 29.724191379310344, -1.507374092307692 ], [ 29.723921896551722, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7244, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.507374092307692 ], [ 29.724191379310344, -1.507913046153846 ], [ 29.724460862068966, -1.507913046153846 ], [ 29.724460862068966, -1.507374092307692 ], [ 29.724191379310344, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7245, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.507374092307692 ], [ 29.724730344827588, -1.508182523076923 ], [ 29.724999827586206, -1.508182523076923 ], [ 29.724999827586206, -1.507374092307692 ], [ 29.724730344827588, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7246, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.507374092307692 ], [ 29.724999827586206, -1.507913046153846 ], [ 29.725269310344828, -1.507913046153846 ], [ 29.725269310344828, -1.507374092307692 ], [ 29.724999827586206, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7247, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.507374092307692 ], [ 29.725269310344828, -1.507913046153846 ], [ 29.72553879310345, -1.507913046153846 ], [ 29.72553879310345, -1.507374092307692 ], [ 29.725269310344828, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7248, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.507374092307692 ], [ 29.72607775862069, -1.508452 ], [ 29.726347241379312, -1.508452 ], [ 29.726347241379312, -1.507374092307692 ], [ 29.72607775862069, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7249, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.507374092307692 ], [ 29.727155689655174, -1.508182523076923 ], [ 29.727425172413792, -1.508182523076923 ], [ 29.727425172413792, -1.507374092307692 ], [ 29.727155689655174, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7250, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.507374092307692 ], [ 29.727964137931036, -1.508182523076923 ], [ 29.728233620689654, -1.508182523076923 ], [ 29.728233620689654, -1.507374092307692 ], [ 29.727964137931036, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7251, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.507374092307692 ], [ 29.728233620689654, -1.507913046153846 ], [ 29.728503103448276, -1.507913046153846 ], [ 29.728503103448276, -1.507374092307692 ], [ 29.728233620689654, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7252, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.507374092307692 ], [ 29.728503103448276, -1.507913046153846 ], [ 29.728772586206897, -1.507913046153846 ], [ 29.728772586206897, -1.507374092307692 ], [ 29.728503103448276, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7253, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.507374092307692 ], [ 29.728772586206897, -1.507913046153846 ], [ 29.729042068965516, -1.507913046153846 ], [ 29.729042068965516, -1.507374092307692 ], [ 29.728772586206897, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7254, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.507374092307692 ], [ 29.729042068965516, -1.507913046153846 ], [ 29.729311551724138, -1.507913046153846 ], [ 29.729311551724138, -1.507374092307692 ], [ 29.729042068965516, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7255, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.507374092307692 ], [ 29.729311551724138, -1.507913046153846 ], [ 29.729850517241381, -1.507913046153846 ], [ 29.729850517241381, -1.507374092307692 ], [ 29.729311551724138, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7256, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.507374092307692 ], [ 29.729850517241381, -1.507913046153846 ], [ 29.73012, -1.507913046153846 ], [ 29.73012, -1.507374092307692 ], [ 29.729850517241381, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7257, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.507374092307692 ], [ 29.73012, -1.507913046153846 ], [ 29.730389482758621, -1.507913046153846 ], [ 29.730389482758621, -1.507374092307692 ], [ 29.73012, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7258, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.507374092307692 ], [ 29.730389482758621, -1.507913046153846 ], [ 29.730658965517243, -1.507913046153846 ], [ 29.730658965517243, -1.507374092307692 ], [ 29.730389482758621, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7259, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.507374092307692 ], [ 29.730658965517243, -1.507913046153846 ], [ 29.730928448275861, -1.507913046153846 ], [ 29.730928448275861, -1.507374092307692 ], [ 29.730658965517243, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7260, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.507374092307692 ], [ 29.730928448275861, -1.507913046153846 ], [ 29.731197931034483, -1.507913046153846 ], [ 29.731197931034483, -1.507374092307692 ], [ 29.730928448275861, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7261, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.507374092307692 ], [ 29.731197931034483, -1.507913046153846 ], [ 29.731467413793105, -1.507913046153846 ], [ 29.731467413793105, -1.507374092307692 ], [ 29.731197931034483, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7262, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.507374092307692 ], [ 29.731467413793105, -1.507913046153846 ], [ 29.731736896551723, -1.507913046153846 ], [ 29.731736896551723, -1.507374092307692 ], [ 29.731467413793105, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7263, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.507374092307692 ], [ 29.739282413793102, -1.507913046153846 ], [ 29.739551896551724, -1.507913046153846 ], [ 29.739551896551724, -1.507374092307692 ], [ 29.739282413793102, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7264, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.506835138461538 ], [ 29.74143827586207, -1.506835138461538 ], [ 29.74143827586207, -1.507913046153846 ], [ 29.739551896551724, -1.507913046153846 ], [ 29.739551896551724, -1.507374092307692 ], [ 29.741168793103448, -1.507374092307692 ], [ 29.741168793103448, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7265, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.507374092307692 ], [ 29.751409137931034, -1.507913046153846 ], [ 29.751678620689656, -1.507913046153846 ], [ 29.751678620689656, -1.507374092307692 ], [ 29.751409137931034, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7266, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.507374092307692 ], [ 29.751678620689656, -1.508452 ], [ 29.751948103448278, -1.508452 ], [ 29.751948103448278, -1.507374092307692 ], [ 29.751678620689656, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7267, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.507374092307692 ], [ 29.751948103448278, -1.508452 ], [ 29.752217586206896, -1.508452 ], [ 29.752217586206896, -1.507374092307692 ], [ 29.751948103448278, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7268, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.507374092307692 ], [ 29.752487068965518, -1.508182523076923 ], [ 29.75275655172414, -1.508182523076923 ], [ 29.75275655172414, -1.507374092307692 ], [ 29.752487068965518, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7269, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.507374092307692 ], [ 29.75275655172414, -1.508182523076923 ], [ 29.753026034482758, -1.508182523076923 ], [ 29.753026034482758, -1.507374092307692 ], [ 29.75275655172414, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7270, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.507374092307692 ], [ 29.753026034482758, -1.508182523076923 ], [ 29.75329551724138, -1.508182523076923 ], [ 29.75329551724138, -1.507374092307692 ], [ 29.753026034482758, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7271, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.507374092307692 ], [ 29.75383448275862, -1.507913046153846 ], [ 29.754103965517242, -1.507913046153846 ], [ 29.754103965517242, -1.507374092307692 ], [ 29.75383448275862, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7272, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.507374092307692 ], [ 29.754103965517242, -1.507913046153846 ], [ 29.754373448275864, -1.507913046153846 ], [ 29.754373448275864, -1.507374092307692 ], [ 29.754103965517242, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7273, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.507374092307692 ], [ 29.754373448275864, -1.507913046153846 ], [ 29.754642931034482, -1.507913046153846 ], [ 29.754642931034482, -1.507374092307692 ], [ 29.754373448275864, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7274, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.507374092307692 ], [ 29.754642931034482, -1.508182523076923 ], [ 29.754912413793104, -1.508182523076923 ], [ 29.754912413793104, -1.507374092307692 ], [ 29.754642931034482, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7275, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.507374092307692 ], [ 29.755181896551726, -1.507913046153846 ], [ 29.755451379310344, -1.507913046153846 ], [ 29.755451379310344, -1.507374092307692 ], [ 29.755181896551726, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7276, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.507374092307692 ], [ 29.755451379310344, -1.507913046153846 ], [ 29.755720862068966, -1.507913046153846 ], [ 29.755720862068966, -1.507374092307692 ], [ 29.755451379310344, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7277, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.507374092307692 ], [ 29.755720862068966, -1.507913046153846 ], [ 29.755990344827588, -1.507913046153846 ], [ 29.755990344827588, -1.507374092307692 ], [ 29.755720862068966, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7278, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.507374092307692 ], [ 29.755990344827588, -1.507913046153846 ], [ 29.756259827586206, -1.507913046153846 ], [ 29.756259827586206, -1.507374092307692 ], [ 29.755990344827588, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7279, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.507374092307692 ], [ 29.756259827586206, -1.507913046153846 ], [ 29.756798793103449, -1.507913046153846 ], [ 29.756798793103449, -1.507374092307692 ], [ 29.756259827586206, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7280, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.507374092307692 ], [ 29.756798793103449, -1.507913046153846 ], [ 29.757068275862068, -1.507913046153846 ], [ 29.757068275862068, -1.507374092307692 ], [ 29.756798793103449, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7281, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.507374092307692 ], [ 29.757068275862068, -1.507913046153846 ], [ 29.75733775862069, -1.507913046153846 ], [ 29.75733775862069, -1.507374092307692 ], [ 29.757068275862068, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7282, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.507374092307692 ], [ 29.75733775862069, -1.507913046153846 ], [ 29.757607241379311, -1.507913046153846 ], [ 29.757607241379311, -1.507374092307692 ], [ 29.75733775862069, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7283, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.507374092307692 ], [ 29.757607241379311, -1.507913046153846 ], [ 29.757876724137933, -1.507913046153846 ], [ 29.757876724137933, -1.507374092307692 ], [ 29.757607241379311, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7284, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.507374092307692 ], [ 29.757876724137933, -1.507913046153846 ], [ 29.758146206896551, -1.507913046153846 ], [ 29.758146206896551, -1.507374092307692 ], [ 29.757876724137933, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7285, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.507374092307692 ], [ 29.758146206896551, -1.507913046153846 ], [ 29.758415689655173, -1.507913046153846 ], [ 29.758415689655173, -1.507374092307692 ], [ 29.758146206896551, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7286, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.507374092307692 ], [ 29.758415689655173, -1.507913046153846 ], [ 29.758685172413795, -1.507913046153846 ], [ 29.758685172413795, -1.507374092307692 ], [ 29.758415689655173, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7287, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.507374092307692 ], [ 29.758685172413795, -1.507913046153846 ], [ 29.758954655172413, -1.507913046153846 ], [ 29.758954655172413, -1.507374092307692 ], [ 29.758685172413795, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7288, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.507374092307692 ], [ 29.758954655172413, -1.507913046153846 ], [ 29.759224137931035, -1.507913046153846 ], [ 29.759224137931035, -1.507374092307692 ], [ 29.758954655172413, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7289, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.507374092307692 ], [ 29.759493620689657, -1.508182523076923 ], [ 29.759763103448275, -1.508182523076923 ], [ 29.759763103448275, -1.507374092307692 ], [ 29.759493620689657, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7290, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.507374092307692 ], [ 29.759763103448275, -1.508182523076923 ], [ 29.760032586206897, -1.508182523076923 ], [ 29.760032586206897, -1.507374092307692 ], [ 29.759763103448275, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7291, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.507374092307692 ], [ 29.760571551724137, -1.507913046153846 ], [ 29.760841034482759, -1.507913046153846 ], [ 29.760841034482759, -1.507374092307692 ], [ 29.760571551724137, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7292, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.507374092307692 ], [ 29.760841034482759, -1.507913046153846 ], [ 29.761110517241381, -1.507913046153846 ], [ 29.761110517241381, -1.507374092307692 ], [ 29.760841034482759, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7293, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.507374092307692 ], [ 29.761110517241381, -1.508182523076923 ], [ 29.76138, -1.508182523076923 ], [ 29.76138, -1.507374092307692 ], [ 29.761110517241381, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7294, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.507374092307692 ], [ 29.761649482758621, -1.507913046153846 ], [ 29.761918965517243, -1.507913046153846 ], [ 29.761918965517243, -1.507374092307692 ], [ 29.761649482758621, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7295, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.507374092307692 ], [ 29.761918965517243, -1.507913046153846 ], [ 29.762188448275861, -1.507913046153846 ], [ 29.762188448275861, -1.507374092307692 ], [ 29.761918965517243, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7296, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.507374092307692 ], [ 29.762188448275861, -1.507913046153846 ], [ 29.762457931034483, -1.507913046153846 ], [ 29.762457931034483, -1.507374092307692 ], [ 29.762188448275861, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7297, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.507374092307692 ], [ 29.762457931034483, -1.507913046153846 ], [ 29.762727413793105, -1.507913046153846 ], [ 29.762727413793105, -1.507374092307692 ], [ 29.762457931034483, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7298, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.507374092307692 ], [ 29.762727413793105, -1.507913046153846 ], [ 29.762996896551723, -1.507913046153846 ], [ 29.762996896551723, -1.507374092307692 ], [ 29.762727413793105, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7299, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.507374092307692 ], [ 29.762996896551723, -1.507913046153846 ], [ 29.763266379310345, -1.507913046153846 ], [ 29.763266379310345, -1.507374092307692 ], [ 29.762996896551723, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7300, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.507374092307692 ], [ 29.763266379310345, -1.507913046153846 ], [ 29.763535862068967, -1.507913046153846 ], [ 29.763535862068967, -1.507374092307692 ], [ 29.763266379310345, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7301, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.507374092307692 ], [ 29.763535862068967, -1.507913046153846 ], [ 29.763805344827588, -1.507913046153846 ], [ 29.763805344827588, -1.507374092307692 ], [ 29.763535862068967, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7302, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.507374092307692 ], [ 29.763805344827588, -1.507913046153846 ], [ 29.764074827586207, -1.507913046153846 ], [ 29.764074827586207, -1.507374092307692 ], [ 29.763805344827588, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7303, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.507374092307692 ], [ 29.764074827586207, -1.507913046153846 ], [ 29.764344310344828, -1.507913046153846 ], [ 29.764344310344828, -1.507374092307692 ], [ 29.764074827586207, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7304, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.507374092307692 ], [ 29.764344310344828, -1.507913046153846 ], [ 29.76461379310345, -1.507913046153846 ], [ 29.76461379310345, -1.507374092307692 ], [ 29.764344310344828, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7305, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.507374092307692 ], [ 29.76461379310345, -1.507913046153846 ], [ 29.76515275862069, -1.507913046153846 ], [ 29.76515275862069, -1.507374092307692 ], [ 29.76461379310345, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7306, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.507374092307692 ], [ 29.76515275862069, -1.507913046153846 ], [ 29.765422241379312, -1.507913046153846 ], [ 29.765422241379312, -1.507374092307692 ], [ 29.76515275862069, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7307, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.507374092307692 ], [ 29.765422241379312, -1.507913046153846 ], [ 29.765961206896552, -1.507913046153846 ], [ 29.765961206896552, -1.507374092307692 ], [ 29.765422241379312, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7308, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.507374092307692 ], [ 29.765961206896552, -1.507913046153846 ], [ 29.766230689655174, -1.507913046153846 ], [ 29.766230689655174, -1.507374092307692 ], [ 29.765961206896552, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7309, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.507374092307692 ], [ 29.766230689655174, -1.507913046153846 ], [ 29.766500172413792, -1.507913046153846 ], [ 29.766500172413792, -1.507374092307692 ], [ 29.766230689655174, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7310, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.507374092307692 ], [ 29.766500172413792, -1.507913046153846 ], [ 29.766769655172414, -1.507913046153846 ], [ 29.766769655172414, -1.507374092307692 ], [ 29.766500172413792, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7311, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.507913046153846 ], [ 29.720688103448275, -1.508452 ], [ 29.720957586206897, -1.508452 ], [ 29.720957586206897, -1.507913046153846 ], [ 29.720688103448275, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7312, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.507913046153846 ], [ 29.720957586206897, -1.508452 ], [ 29.721227068965518, -1.508452 ], [ 29.721227068965518, -1.507913046153846 ], [ 29.720957586206897, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7313, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.507913046153846 ], [ 29.721227068965518, -1.508182523076923 ], [ 29.721496551724137, -1.508182523076923 ], [ 29.721496551724137, -1.507913046153846 ], [ 29.721227068965518, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7314, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.507913046153846 ], [ 29.721766034482759, -1.508182523076923 ], [ 29.72203551724138, -1.508182523076923 ], [ 29.72203551724138, -1.507913046153846 ], [ 29.721766034482759, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7315, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.507913046153846 ], [ 29.72203551724138, -1.508182523076923 ], [ 29.722305, -1.508182523076923 ], [ 29.722305, -1.507913046153846 ], [ 29.72203551724138, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7316, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.507913046153846 ], [ 29.722305, -1.508182523076923 ], [ 29.72257448275862, -1.508182523076923 ], [ 29.72257448275862, -1.507913046153846 ], [ 29.722305, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7317, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.507913046153846 ], [ 29.72257448275862, -1.508182523076923 ], [ 29.722843965517242, -1.508182523076923 ], [ 29.722843965517242, -1.507913046153846 ], [ 29.72257448275862, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7318, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.507913046153846 ], [ 29.722843965517242, -1.508182523076923 ], [ 29.723113448275861, -1.508182523076923 ], [ 29.723113448275861, -1.507913046153846 ], [ 29.722843965517242, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7319, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.507913046153846 ], [ 29.723113448275861, -1.508182523076923 ], [ 29.723382931034482, -1.508182523076923 ], [ 29.723382931034482, -1.507913046153846 ], [ 29.723113448275861, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7320, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.507913046153846 ], [ 29.723382931034482, -1.508182523076923 ], [ 29.723652413793104, -1.508182523076923 ], [ 29.723652413793104, -1.507913046153846 ], [ 29.723382931034482, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7321, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.507913046153846 ], [ 29.723652413793104, -1.508182523076923 ], [ 29.723921896551722, -1.508182523076923 ], [ 29.723921896551722, -1.507913046153846 ], [ 29.723652413793104, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7322, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.507913046153846 ], [ 29.723921896551722, -1.508182523076923 ], [ 29.724191379310344, -1.508182523076923 ], [ 29.724191379310344, -1.507913046153846 ], [ 29.723921896551722, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7323, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.507913046153846 ], [ 29.724191379310344, -1.508182523076923 ], [ 29.724460862068966, -1.508182523076923 ], [ 29.724460862068966, -1.507913046153846 ], [ 29.724191379310344, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7324, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.507913046153846 ], [ 29.724460862068966, -1.508182523076923 ], [ 29.724730344827588, -1.508182523076923 ], [ 29.724730344827588, -1.507913046153846 ], [ 29.724460862068966, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7325, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.507913046153846 ], [ 29.724999827586206, -1.508452 ], [ 29.725269310344828, -1.508452 ], [ 29.725269310344828, -1.507913046153846 ], [ 29.724999827586206, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7326, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.507913046153846 ], [ 29.725269310344828, -1.508182523076923 ], [ 29.72553879310345, -1.508182523076923 ], [ 29.72553879310345, -1.507913046153846 ], [ 29.725269310344828, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7327, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.507913046153846 ], [ 29.726347241379312, -1.509260430769231 ], [ 29.72661672413793, -1.509260430769231 ], [ 29.72661672413793, -1.507913046153846 ], [ 29.726347241379312, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7328, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.507913046153846 ], [ 29.727425172413792, -1.509260430769231 ], [ 29.727694655172414, -1.509260430769231 ], [ 29.727694655172414, -1.507913046153846 ], [ 29.727425172413792, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7329, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.507913046153846 ], [ 29.728233620689654, -1.509260430769231 ], [ 29.728503103448276, -1.509260430769231 ], [ 29.728503103448276, -1.507913046153846 ], [ 29.728233620689654, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7330, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.507913046153846 ], [ 29.728503103448276, -1.509260430769231 ], [ 29.728772586206897, -1.509260430769231 ], [ 29.728772586206897, -1.507913046153846 ], [ 29.728503103448276, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7331, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.507913046153846 ], [ 29.728772586206897, -1.508182523076923 ], [ 29.729042068965516, -1.508182523076923 ], [ 29.729042068965516, -1.507913046153846 ], [ 29.728772586206897, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7332, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.507913046153846 ], [ 29.729042068965516, -1.508182523076923 ], [ 29.729311551724138, -1.508182523076923 ], [ 29.729311551724138, -1.507913046153846 ], [ 29.729042068965516, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7333, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.507913046153846 ], [ 29.729311551724138, -1.508182523076923 ], [ 29.729850517241381, -1.508182523076923 ], [ 29.729850517241381, -1.507913046153846 ], [ 29.729311551724138, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7334, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.507913046153846 ], [ 29.729850517241381, -1.508452 ], [ 29.73012, -1.508452 ], [ 29.73012, -1.507913046153846 ], [ 29.729850517241381, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7335, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.507913046153846 ], [ 29.73012, -1.508182523076923 ], [ 29.730389482758621, -1.508182523076923 ], [ 29.730389482758621, -1.507913046153846 ], [ 29.73012, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7336, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.507913046153846 ], [ 29.730389482758621, -1.508182523076923 ], [ 29.730658965517243, -1.508182523076923 ], [ 29.730658965517243, -1.507913046153846 ], [ 29.730389482758621, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7337, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.507913046153846 ], [ 29.730658965517243, -1.508182523076923 ], [ 29.730928448275861, -1.508182523076923 ], [ 29.730928448275861, -1.507913046153846 ], [ 29.730658965517243, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7338, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.507913046153846 ], [ 29.730928448275861, -1.508182523076923 ], [ 29.731197931034483, -1.508182523076923 ], [ 29.731197931034483, -1.507913046153846 ], [ 29.730928448275861, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7339, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.507913046153846 ], [ 29.731197931034483, -1.508182523076923 ], [ 29.731467413793105, -1.508182523076923 ], [ 29.731467413793105, -1.507913046153846 ], [ 29.731197931034483, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7340, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.507913046153846 ], [ 29.737935, -1.508182523076923 ], [ 29.738204482758622, -1.508182523076923 ], [ 29.738204482758622, -1.507913046153846 ], [ 29.737935, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7341, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.507913046153846 ], [ 29.738204482758622, -1.508452 ], [ 29.738743448275862, -1.508452 ], [ 29.738743448275862, -1.507913046153846 ], [ 29.738204482758622, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7342, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.507913046153846 ], [ 29.738743448275862, -1.508182523076923 ], [ 29.739012931034484, -1.508182523076923 ], [ 29.739012931034484, -1.507913046153846 ], [ 29.738743448275862, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7343, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.507913046153846 ], [ 29.739012931034484, -1.508182523076923 ], [ 29.739282413793102, -1.508182523076923 ], [ 29.739282413793102, -1.507913046153846 ], [ 29.739012931034484, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7344, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.507913046153846 ], [ 29.739282413793102, -1.508182523076923 ], [ 29.739821379310346, -1.508182523076923 ], [ 29.739821379310346, -1.507913046153846 ], [ 29.739282413793102, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7345, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.507104615384615 ], [ 29.741707758620691, -1.507104615384615 ], [ 29.741707758620691, -1.508182523076923 ], [ 29.739821379310346, -1.508182523076923 ], [ 29.739821379310346, -1.507913046153846 ], [ 29.74143827586207, -1.507913046153846 ], [ 29.74143827586207, -1.507104615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7346, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751409137931034, -1.507913046153846 ], [ 29.751409137931034, -1.508721476923077 ], [ 29.751678620689656, -1.508721476923077 ], [ 29.751678620689656, -1.507913046153846 ], [ 29.751409137931034, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7347, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.507913046153846 ], [ 29.752217586206896, -1.508182523076923 ], [ 29.752487068965518, -1.508182523076923 ], [ 29.752487068965518, -1.507913046153846 ], [ 29.752217586206896, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7348, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.507913046153846 ], [ 29.75329551724138, -1.508182523076923 ], [ 29.753565, -1.508182523076923 ], [ 29.753565, -1.507913046153846 ], [ 29.75329551724138, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7349, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.507913046153846 ], [ 29.753565, -1.508182523076923 ], [ 29.75383448275862, -1.508182523076923 ], [ 29.75383448275862, -1.507913046153846 ], [ 29.753565, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7350, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.507913046153846 ], [ 29.75383448275862, -1.508182523076923 ], [ 29.754103965517242, -1.508182523076923 ], [ 29.754103965517242, -1.507913046153846 ], [ 29.75383448275862, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7351, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.507913046153846 ], [ 29.754103965517242, -1.508452 ], [ 29.754373448275864, -1.508452 ], [ 29.754373448275864, -1.507913046153846 ], [ 29.754103965517242, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7352, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.507913046153846 ], [ 29.754373448275864, -1.508452 ], [ 29.754642931034482, -1.508452 ], [ 29.754642931034482, -1.507913046153846 ], [ 29.754373448275864, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7353, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.507913046153846 ], [ 29.754912413793104, -1.508182523076923 ], [ 29.755181896551726, -1.508182523076923 ], [ 29.755181896551726, -1.507913046153846 ], [ 29.754912413793104, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7354, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.507913046153846 ], [ 29.755181896551726, -1.508182523076923 ], [ 29.755451379310344, -1.508182523076923 ], [ 29.755451379310344, -1.507913046153846 ], [ 29.755181896551726, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7355, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.507913046153846 ], [ 29.755451379310344, -1.508182523076923 ], [ 29.755720862068966, -1.508182523076923 ], [ 29.755720862068966, -1.507913046153846 ], [ 29.755451379310344, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7356, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.507913046153846 ], [ 29.755720862068966, -1.508182523076923 ], [ 29.755990344827588, -1.508182523076923 ], [ 29.755990344827588, -1.507913046153846 ], [ 29.755720862068966, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7357, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.507913046153846 ], [ 29.755990344827588, -1.508182523076923 ], [ 29.756259827586206, -1.508182523076923 ], [ 29.756259827586206, -1.507913046153846 ], [ 29.755990344827588, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7358, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.507913046153846 ], [ 29.756259827586206, -1.508182523076923 ], [ 29.756798793103449, -1.508182523076923 ], [ 29.756798793103449, -1.507913046153846 ], [ 29.756259827586206, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7359, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.507913046153846 ], [ 29.756798793103449, -1.508182523076923 ], [ 29.757068275862068, -1.508182523076923 ], [ 29.757068275862068, -1.507913046153846 ], [ 29.756798793103449, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7360, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.507913046153846 ], [ 29.757068275862068, -1.508182523076923 ], [ 29.75733775862069, -1.508182523076923 ], [ 29.75733775862069, -1.507913046153846 ], [ 29.757068275862068, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7361, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.507913046153846 ], [ 29.75733775862069, -1.508182523076923 ], [ 29.757607241379311, -1.508182523076923 ], [ 29.757607241379311, -1.507913046153846 ], [ 29.75733775862069, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7362, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.507913046153846 ], [ 29.757607241379311, -1.508182523076923 ], [ 29.757876724137933, -1.508182523076923 ], [ 29.757876724137933, -1.507913046153846 ], [ 29.757607241379311, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7363, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.507913046153846 ], [ 29.757876724137933, -1.508182523076923 ], [ 29.758146206896551, -1.508182523076923 ], [ 29.758146206896551, -1.507913046153846 ], [ 29.757876724137933, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7364, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.507913046153846 ], [ 29.758146206896551, -1.508182523076923 ], [ 29.758415689655173, -1.508182523076923 ], [ 29.758415689655173, -1.507913046153846 ], [ 29.758146206896551, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7365, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.507913046153846 ], [ 29.758415689655173, -1.508182523076923 ], [ 29.758685172413795, -1.508182523076923 ], [ 29.758685172413795, -1.507913046153846 ], [ 29.758415689655173, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7366, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.507913046153846 ], [ 29.758685172413795, -1.508182523076923 ], [ 29.758954655172413, -1.508182523076923 ], [ 29.758954655172413, -1.507913046153846 ], [ 29.758685172413795, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7367, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.507913046153846 ], [ 29.758954655172413, -1.508182523076923 ], [ 29.759224137931035, -1.508182523076923 ], [ 29.759224137931035, -1.507913046153846 ], [ 29.758954655172413, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7368, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.507913046153846 ], [ 29.759224137931035, -1.508182523076923 ], [ 29.759493620689657, -1.508182523076923 ], [ 29.759493620689657, -1.507913046153846 ], [ 29.759224137931035, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7369, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.507913046153846 ], [ 29.760032586206897, -1.508182523076923 ], [ 29.760302068965519, -1.508182523076923 ], [ 29.760302068965519, -1.507913046153846 ], [ 29.760032586206897, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7370, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.507913046153846 ], [ 29.760302068965519, -1.508182523076923 ], [ 29.760571551724137, -1.508182523076923 ], [ 29.760571551724137, -1.507913046153846 ], [ 29.760302068965519, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7371, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.507913046153846 ], [ 29.760571551724137, -1.508182523076923 ], [ 29.760841034482759, -1.508182523076923 ], [ 29.760841034482759, -1.507913046153846 ], [ 29.760571551724137, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7372, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.507913046153846 ], [ 29.760841034482759, -1.508452 ], [ 29.761110517241381, -1.508452 ], [ 29.761110517241381, -1.507913046153846 ], [ 29.760841034482759, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7373, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.507913046153846 ], [ 29.76138, -1.508182523076923 ], [ 29.761649482758621, -1.508182523076923 ], [ 29.761649482758621, -1.507913046153846 ], [ 29.76138, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7374, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.507913046153846 ], [ 29.761649482758621, -1.508182523076923 ], [ 29.761918965517243, -1.508182523076923 ], [ 29.761918965517243, -1.507913046153846 ], [ 29.761649482758621, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7375, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.507913046153846 ], [ 29.761918965517243, -1.508182523076923 ], [ 29.762188448275861, -1.508182523076923 ], [ 29.762188448275861, -1.507913046153846 ], [ 29.761918965517243, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7376, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.507913046153846 ], [ 29.762188448275861, -1.508182523076923 ], [ 29.762457931034483, -1.508182523076923 ], [ 29.762457931034483, -1.507913046153846 ], [ 29.762188448275861, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7377, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.507913046153846 ], [ 29.762457931034483, -1.508182523076923 ], [ 29.762727413793105, -1.508182523076923 ], [ 29.762727413793105, -1.507913046153846 ], [ 29.762457931034483, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7378, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.507913046153846 ], [ 29.762727413793105, -1.508182523076923 ], [ 29.762996896551723, -1.508182523076923 ], [ 29.762996896551723, -1.507913046153846 ], [ 29.762727413793105, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7379, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.507913046153846 ], [ 29.762996896551723, -1.508182523076923 ], [ 29.763266379310345, -1.508182523076923 ], [ 29.763266379310345, -1.507913046153846 ], [ 29.762996896551723, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7380, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.507913046153846 ], [ 29.763266379310345, -1.508182523076923 ], [ 29.763535862068967, -1.508182523076923 ], [ 29.763535862068967, -1.507913046153846 ], [ 29.763266379310345, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7381, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.507913046153846 ], [ 29.763535862068967, -1.508182523076923 ], [ 29.763805344827588, -1.508182523076923 ], [ 29.763805344827588, -1.507913046153846 ], [ 29.763535862068967, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7382, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.507913046153846 ], [ 29.763805344827588, -1.508182523076923 ], [ 29.764074827586207, -1.508182523076923 ], [ 29.764074827586207, -1.507913046153846 ], [ 29.763805344827588, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7383, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.507913046153846 ], [ 29.764074827586207, -1.508182523076923 ], [ 29.764344310344828, -1.508182523076923 ], [ 29.764344310344828, -1.507913046153846 ], [ 29.764074827586207, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7384, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.507913046153846 ], [ 29.764344310344828, -1.508182523076923 ], [ 29.76461379310345, -1.508182523076923 ], [ 29.76461379310345, -1.507913046153846 ], [ 29.764344310344828, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7385, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.507913046153846 ], [ 29.76461379310345, -1.508182523076923 ], [ 29.764883275862069, -1.508182523076923 ], [ 29.764883275862069, -1.507913046153846 ], [ 29.76461379310345, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7386, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.507913046153846 ], [ 29.764883275862069, -1.508182523076923 ], [ 29.76515275862069, -1.508182523076923 ], [ 29.76515275862069, -1.507913046153846 ], [ 29.764883275862069, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7387, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.507913046153846 ], [ 29.76515275862069, -1.508182523076923 ], [ 29.765422241379312, -1.508182523076923 ], [ 29.765422241379312, -1.507913046153846 ], [ 29.76515275862069, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7388, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.507913046153846 ], [ 29.765422241379312, -1.508182523076923 ], [ 29.765961206896552, -1.508182523076923 ], [ 29.765961206896552, -1.507913046153846 ], [ 29.765422241379312, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7389, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.507913046153846 ], [ 29.765961206896552, -1.508182523076923 ], [ 29.766230689655174, -1.508182523076923 ], [ 29.766230689655174, -1.507913046153846 ], [ 29.765961206896552, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7390, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.507913046153846 ], [ 29.766230689655174, -1.508182523076923 ], [ 29.766500172413792, -1.508182523076923 ], [ 29.766500172413792, -1.507913046153846 ], [ 29.766230689655174, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7391, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.507913046153846 ], [ 29.766500172413792, -1.508182523076923 ], [ 29.766769655172414, -1.508182523076923 ], [ 29.766769655172414, -1.507913046153846 ], [ 29.766500172413792, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7392, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.507913046153846 ], [ 29.766769655172414, -1.508721476923077 ], [ 29.767039137931036, -1.508721476923077 ], [ 29.767039137931036, -1.507913046153846 ], [ 29.766769655172414, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7393, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.508182523076923 ], [ 29.719879655172413, -1.508721476923077 ], [ 29.720149137931035, -1.508721476923077 ], [ 29.720149137931035, -1.508182523076923 ], [ 29.719879655172413, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7394, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.508182523076923 ], [ 29.720149137931035, -1.508452 ], [ 29.720688103448275, -1.508452 ], [ 29.720688103448275, -1.508182523076923 ], [ 29.720149137931035, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7395, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.508182523076923 ], [ 29.721227068965518, -1.508721476923077 ], [ 29.721496551724137, -1.508721476923077 ], [ 29.721496551724137, -1.508182523076923 ], [ 29.721227068965518, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7396, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.508182523076923 ], [ 29.721496551724137, -1.508452 ], [ 29.721766034482759, -1.508452 ], [ 29.721766034482759, -1.508182523076923 ], [ 29.721496551724137, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7397, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.508182523076923 ], [ 29.721766034482759, -1.508452 ], [ 29.72203551724138, -1.508452 ], [ 29.72203551724138, -1.508182523076923 ], [ 29.721766034482759, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7398, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.508182523076923 ], [ 29.72203551724138, -1.508452 ], [ 29.722305, -1.508452 ], [ 29.722305, -1.508182523076923 ], [ 29.72203551724138, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7399, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.508182523076923 ], [ 29.722305, -1.508452 ], [ 29.72257448275862, -1.508452 ], [ 29.72257448275862, -1.508182523076923 ], [ 29.722305, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7400, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.508182523076923 ], [ 29.72257448275862, -1.508452 ], [ 29.722843965517242, -1.508452 ], [ 29.722843965517242, -1.508182523076923 ], [ 29.72257448275862, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7401, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.508182523076923 ], [ 29.722843965517242, -1.508452 ], [ 29.723113448275861, -1.508452 ], [ 29.723113448275861, -1.508182523076923 ], [ 29.722843965517242, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7402, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.508182523076923 ], [ 29.723113448275861, -1.508452 ], [ 29.723382931034482, -1.508452 ], [ 29.723382931034482, -1.508182523076923 ], [ 29.723113448275861, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7403, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.508182523076923 ], [ 29.723382931034482, -1.508452 ], [ 29.723652413793104, -1.508452 ], [ 29.723652413793104, -1.508182523076923 ], [ 29.723382931034482, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7404, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.508182523076923 ], [ 29.723652413793104, -1.508452 ], [ 29.723921896551722, -1.508452 ], [ 29.723921896551722, -1.508182523076923 ], [ 29.723652413793104, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7405, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.508182523076923 ], [ 29.723921896551722, -1.508452 ], [ 29.724191379310344, -1.508452 ], [ 29.724191379310344, -1.508182523076923 ], [ 29.723921896551722, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7406, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.508182523076923 ], [ 29.724191379310344, -1.508452 ], [ 29.724460862068966, -1.508452 ], [ 29.724460862068966, -1.508182523076923 ], [ 29.724191379310344, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7407, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.508182523076923 ], [ 29.724460862068966, -1.508452 ], [ 29.724730344827588, -1.508452 ], [ 29.724730344827588, -1.508182523076923 ], [ 29.724460862068966, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7408, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.508182523076923 ], [ 29.724730344827588, -1.508452 ], [ 29.724999827586206, -1.508452 ], [ 29.724999827586206, -1.508182523076923 ], [ 29.724730344827588, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7409, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.508182523076923 ], [ 29.725269310344828, -1.508452 ], [ 29.72553879310345, -1.508452 ], [ 29.72553879310345, -1.508182523076923 ], [ 29.725269310344828, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7410, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.508182523076923 ], [ 29.72553879310345, -1.508452 ], [ 29.725808275862068, -1.508452 ], [ 29.725808275862068, -1.508182523076923 ], [ 29.72553879310345, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7411, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.508182523076923 ], [ 29.727155689655174, -1.508990953846154 ], [ 29.727425172413792, -1.508990953846154 ], [ 29.727425172413792, -1.508182523076923 ], [ 29.727155689655174, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7412, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.508182523076923 ], [ 29.727964137931036, -1.508990953846154 ], [ 29.728233620689654, -1.508990953846154 ], [ 29.728233620689654, -1.508182523076923 ], [ 29.727964137931036, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7413, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.508182523076923 ], [ 29.728772586206897, -1.508990953846154 ], [ 29.729042068965516, -1.508990953846154 ], [ 29.729042068965516, -1.508182523076923 ], [ 29.728772586206897, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7414, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.508182523076923 ], [ 29.729042068965516, -1.508990953846154 ], [ 29.729311551724138, -1.508990953846154 ], [ 29.729311551724138, -1.508182523076923 ], [ 29.729042068965516, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7415, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.508182523076923 ], [ 29.729311551724138, -1.508990953846154 ], [ 29.729850517241381, -1.508990953846154 ], [ 29.729850517241381, -1.508182523076923 ], [ 29.729311551724138, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7416, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.508182523076923 ], [ 29.73012, -1.508990953846154 ], [ 29.730389482758621, -1.508990953846154 ], [ 29.730389482758621, -1.508182523076923 ], [ 29.73012, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7417, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.508182523076923 ], [ 29.730389482758621, -1.508990953846154 ], [ 29.730658965517243, -1.508990953846154 ], [ 29.730658965517243, -1.508182523076923 ], [ 29.730389482758621, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7418, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.508182523076923 ], [ 29.730658965517243, -1.508452 ], [ 29.730928448275861, -1.508452 ], [ 29.730928448275861, -1.508182523076923 ], [ 29.730658965517243, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7419, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.508182523076923 ], [ 29.730928448275861, -1.508452 ], [ 29.731197931034483, -1.508452 ], [ 29.731197931034483, -1.508182523076923 ], [ 29.730928448275861, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7420, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.508182523076923 ], [ 29.73739603448276, -1.508452 ], [ 29.737665517241378, -1.508452 ], [ 29.737665517241378, -1.508182523076923 ], [ 29.73739603448276, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7421, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.508182523076923 ], [ 29.737665517241378, -1.508721476923077 ], [ 29.737935, -1.508721476923077 ], [ 29.737935, -1.508182523076923 ], [ 29.737665517241378, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7422, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.508182523076923 ], [ 29.737935, -1.508721476923077 ], [ 29.738204482758622, -1.508721476923077 ], [ 29.738204482758622, -1.508182523076923 ], [ 29.737935, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7423, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.508182523076923 ], [ 29.738743448275862, -1.508452 ], [ 29.739012931034484, -1.508452 ], [ 29.739012931034484, -1.508182523076923 ], [ 29.738743448275862, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7424, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.508182523076923 ], [ 29.739012931034484, -1.508452 ], [ 29.739282413793102, -1.508452 ], [ 29.739282413793102, -1.508182523076923 ], [ 29.739012931034484, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7425, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.508182523076923 ], [ 29.739282413793102, -1.508452 ], [ 29.739551896551724, -1.508452 ], [ 29.739551896551724, -1.508182523076923 ], [ 29.739282413793102, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7426, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.506835138461538 ], [ 29.74197724137931, -1.506835138461538 ], [ 29.74197724137931, -1.508721476923077 ], [ 29.741707758620691, -1.508721476923077 ], [ 29.741707758620691, -1.508452 ], [ 29.739551896551724, -1.508452 ], [ 29.739551896551724, -1.508182523076923 ], [ 29.741707758620691, -1.508182523076923 ], [ 29.741707758620691, -1.506835138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7427, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.508182523076923 ], [ 29.752217586206896, -1.508452 ], [ 29.752487068965518, -1.508452 ], [ 29.752487068965518, -1.508182523076923 ], [ 29.752217586206896, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7428, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.508182523076923 ], [ 29.752487068965518, -1.508721476923077 ], [ 29.75275655172414, -1.508721476923077 ], [ 29.75275655172414, -1.508182523076923 ], [ 29.752487068965518, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7429, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.508182523076923 ], [ 29.75275655172414, -1.508452 ], [ 29.753026034482758, -1.508452 ], [ 29.753026034482758, -1.508182523076923 ], [ 29.75275655172414, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7430, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.508182523076923 ], [ 29.753026034482758, -1.508452 ], [ 29.75329551724138, -1.508452 ], [ 29.75329551724138, -1.508182523076923 ], [ 29.753026034482758, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7431, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.508182523076923 ], [ 29.75329551724138, -1.508452 ], [ 29.753565, -1.508452 ], [ 29.753565, -1.508182523076923 ], [ 29.75329551724138, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7432, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.508182523076923 ], [ 29.753565, -1.508721476923077 ], [ 29.75383448275862, -1.508721476923077 ], [ 29.75383448275862, -1.508182523076923 ], [ 29.753565, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7433, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.508182523076923 ], [ 29.75383448275862, -1.508721476923077 ], [ 29.754103965517242, -1.508721476923077 ], [ 29.754103965517242, -1.508182523076923 ], [ 29.75383448275862, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7434, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.508182523076923 ], [ 29.754642931034482, -1.508452 ], [ 29.754912413793104, -1.508452 ], [ 29.754912413793104, -1.508182523076923 ], [ 29.754642931034482, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7435, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.508182523076923 ], [ 29.754912413793104, -1.508452 ], [ 29.755181896551726, -1.508452 ], [ 29.755181896551726, -1.508182523076923 ], [ 29.754912413793104, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7436, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.508182523076923 ], [ 29.755181896551726, -1.508452 ], [ 29.755451379310344, -1.508452 ], [ 29.755451379310344, -1.508182523076923 ], [ 29.755181896551726, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7437, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.508182523076923 ], [ 29.755451379310344, -1.508452 ], [ 29.755720862068966, -1.508452 ], [ 29.755720862068966, -1.508182523076923 ], [ 29.755451379310344, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7438, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.508182523076923 ], [ 29.755720862068966, -1.508452 ], [ 29.755990344827588, -1.508452 ], [ 29.755990344827588, -1.508182523076923 ], [ 29.755720862068966, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7439, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.508182523076923 ], [ 29.755990344827588, -1.508452 ], [ 29.756259827586206, -1.508452 ], [ 29.756259827586206, -1.508182523076923 ], [ 29.755990344827588, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7440, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.508182523076923 ], [ 29.756259827586206, -1.508452 ], [ 29.756798793103449, -1.508452 ], [ 29.756798793103449, -1.508182523076923 ], [ 29.756259827586206, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7441, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.508182523076923 ], [ 29.756798793103449, -1.508452 ], [ 29.757068275862068, -1.508452 ], [ 29.757068275862068, -1.508182523076923 ], [ 29.756798793103449, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7442, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.508182523076923 ], [ 29.757068275862068, -1.508452 ], [ 29.75733775862069, -1.508452 ], [ 29.75733775862069, -1.508182523076923 ], [ 29.757068275862068, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7443, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.508182523076923 ], [ 29.75733775862069, -1.508452 ], [ 29.757607241379311, -1.508452 ], [ 29.757607241379311, -1.508182523076923 ], [ 29.75733775862069, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7444, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.508182523076923 ], [ 29.757607241379311, -1.508452 ], [ 29.757876724137933, -1.508452 ], [ 29.757876724137933, -1.508182523076923 ], [ 29.757607241379311, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7445, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.508182523076923 ], [ 29.757876724137933, -1.508452 ], [ 29.758146206896551, -1.508452 ], [ 29.758146206896551, -1.508182523076923 ], [ 29.757876724137933, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7446, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.508182523076923 ], [ 29.758146206896551, -1.508452 ], [ 29.758415689655173, -1.508452 ], [ 29.758415689655173, -1.508182523076923 ], [ 29.758146206896551, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7447, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.508182523076923 ], [ 29.758415689655173, -1.508452 ], [ 29.758685172413795, -1.508452 ], [ 29.758685172413795, -1.508182523076923 ], [ 29.758415689655173, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7448, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.508182523076923 ], [ 29.758685172413795, -1.508452 ], [ 29.758954655172413, -1.508452 ], [ 29.758954655172413, -1.508182523076923 ], [ 29.758685172413795, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7449, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.508182523076923 ], [ 29.758954655172413, -1.508452 ], [ 29.759224137931035, -1.508452 ], [ 29.759224137931035, -1.508182523076923 ], [ 29.758954655172413, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7450, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.508182523076923 ], [ 29.759224137931035, -1.508452 ], [ 29.759763103448275, -1.508452 ], [ 29.759763103448275, -1.508182523076923 ], [ 29.759224137931035, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7451, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.508182523076923 ], [ 29.759763103448275, -1.508452 ], [ 29.760032586206897, -1.508452 ], [ 29.760032586206897, -1.508182523076923 ], [ 29.759763103448275, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7452, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.508182523076923 ], [ 29.760032586206897, -1.508452 ], [ 29.760302068965519, -1.508452 ], [ 29.760302068965519, -1.508182523076923 ], [ 29.760032586206897, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7453, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.508182523076923 ], [ 29.760302068965519, -1.508452 ], [ 29.760571551724137, -1.508452 ], [ 29.760571551724137, -1.508182523076923 ], [ 29.760302068965519, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7454, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.508182523076923 ], [ 29.760571551724137, -1.508452 ], [ 29.760841034482759, -1.508452 ], [ 29.760841034482759, -1.508182523076923 ], [ 29.760571551724137, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7455, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.508182523076923 ], [ 29.761110517241381, -1.508452 ], [ 29.76138, -1.508452 ], [ 29.76138, -1.508182523076923 ], [ 29.761110517241381, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7456, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.508182523076923 ], [ 29.76138, -1.508452 ], [ 29.761649482758621, -1.508452 ], [ 29.761649482758621, -1.508182523076923 ], [ 29.76138, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7457, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.508182523076923 ], [ 29.761649482758621, -1.508452 ], [ 29.761918965517243, -1.508452 ], [ 29.761918965517243, -1.508182523076923 ], [ 29.761649482758621, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7458, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.508182523076923 ], [ 29.761918965517243, -1.508452 ], [ 29.762188448275861, -1.508452 ], [ 29.762188448275861, -1.508182523076923 ], [ 29.761918965517243, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7459, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.508182523076923 ], [ 29.762188448275861, -1.508452 ], [ 29.762457931034483, -1.508452 ], [ 29.762457931034483, -1.508182523076923 ], [ 29.762188448275861, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7460, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.508182523076923 ], [ 29.762457931034483, -1.508452 ], [ 29.762727413793105, -1.508452 ], [ 29.762727413793105, -1.508182523076923 ], [ 29.762457931034483, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7461, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.508182523076923 ], [ 29.762727413793105, -1.508452 ], [ 29.762996896551723, -1.508452 ], [ 29.762996896551723, -1.508182523076923 ], [ 29.762727413793105, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7462, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.508182523076923 ], [ 29.762996896551723, -1.508452 ], [ 29.763266379310345, -1.508452 ], [ 29.763266379310345, -1.508182523076923 ], [ 29.762996896551723, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7463, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.508182523076923 ], [ 29.763266379310345, -1.508452 ], [ 29.763535862068967, -1.508452 ], [ 29.763535862068967, -1.508182523076923 ], [ 29.763266379310345, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7464, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.508182523076923 ], [ 29.763535862068967, -1.508452 ], [ 29.763805344827588, -1.508452 ], [ 29.763805344827588, -1.508182523076923 ], [ 29.763535862068967, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7465, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.508182523076923 ], [ 29.763805344827588, -1.508452 ], [ 29.764074827586207, -1.508452 ], [ 29.764074827586207, -1.508182523076923 ], [ 29.763805344827588, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7466, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.508182523076923 ], [ 29.764074827586207, -1.508452 ], [ 29.764344310344828, -1.508452 ], [ 29.764344310344828, -1.508182523076923 ], [ 29.764074827586207, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7467, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.508182523076923 ], [ 29.764344310344828, -1.508452 ], [ 29.76461379310345, -1.508452 ], [ 29.76461379310345, -1.508182523076923 ], [ 29.764344310344828, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7468, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.508182523076923 ], [ 29.76461379310345, -1.508452 ], [ 29.764883275862069, -1.508452 ], [ 29.764883275862069, -1.508182523076923 ], [ 29.76461379310345, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7469, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.508182523076923 ], [ 29.764883275862069, -1.508452 ], [ 29.76515275862069, -1.508452 ], [ 29.76515275862069, -1.508182523076923 ], [ 29.764883275862069, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7470, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.508182523076923 ], [ 29.76515275862069, -1.508721476923077 ], [ 29.765422241379312, -1.508721476923077 ], [ 29.765422241379312, -1.508182523076923 ], [ 29.76515275862069, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7471, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.508182523076923 ], [ 29.765422241379312, -1.508721476923077 ], [ 29.765961206896552, -1.508721476923077 ], [ 29.765961206896552, -1.508182523076923 ], [ 29.765422241379312, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7472, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.508182523076923 ], [ 29.765961206896552, -1.508452 ], [ 29.766230689655174, -1.508452 ], [ 29.766230689655174, -1.508182523076923 ], [ 29.765961206896552, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7473, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.508182523076923 ], [ 29.766230689655174, -1.508452 ], [ 29.766500172413792, -1.508452 ], [ 29.766500172413792, -1.508182523076923 ], [ 29.766230689655174, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7474, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.508182523076923 ], [ 29.766500172413792, -1.508452 ], [ 29.766769655172414, -1.508452 ], [ 29.766769655172414, -1.508182523076923 ], [ 29.766500172413792, -1.508182523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7475, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.508452 ], [ 29.720149137931035, -1.508990953846154 ], [ 29.720688103448275, -1.508990953846154 ], [ 29.720688103448275, -1.508452 ], [ 29.720149137931035, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7476, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.508452 ], [ 29.720688103448275, -1.508990953846154 ], [ 29.720957586206897, -1.508990953846154 ], [ 29.720957586206897, -1.508452 ], [ 29.720688103448275, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7477, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.508452 ], [ 29.720957586206897, -1.508721476923077 ], [ 29.721227068965518, -1.508721476923077 ], [ 29.721227068965518, -1.508452 ], [ 29.720957586206897, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7478, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.508452 ], [ 29.721496551724137, -1.508721476923077 ], [ 29.721766034482759, -1.508721476923077 ], [ 29.721766034482759, -1.508452 ], [ 29.721496551724137, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7479, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.508452 ], [ 29.721766034482759, -1.508721476923077 ], [ 29.72203551724138, -1.508721476923077 ], [ 29.72203551724138, -1.508452 ], [ 29.721766034482759, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7480, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.508452 ], [ 29.72203551724138, -1.508721476923077 ], [ 29.722305, -1.508721476923077 ], [ 29.722305, -1.508452 ], [ 29.72203551724138, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7481, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.508452 ], [ 29.722305, -1.508721476923077 ], [ 29.72257448275862, -1.508721476923077 ], [ 29.72257448275862, -1.508452 ], [ 29.722305, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7482, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.508452 ], [ 29.72257448275862, -1.508721476923077 ], [ 29.722843965517242, -1.508721476923077 ], [ 29.722843965517242, -1.508452 ], [ 29.72257448275862, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7483, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.508452 ], [ 29.722843965517242, -1.508721476923077 ], [ 29.723113448275861, -1.508721476923077 ], [ 29.723113448275861, -1.508452 ], [ 29.722843965517242, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7484, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.508452 ], [ 29.723113448275861, -1.508721476923077 ], [ 29.723382931034482, -1.508721476923077 ], [ 29.723382931034482, -1.508452 ], [ 29.723113448275861, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7485, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.508452 ], [ 29.723382931034482, -1.508721476923077 ], [ 29.723652413793104, -1.508721476923077 ], [ 29.723652413793104, -1.508452 ], [ 29.723382931034482, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7486, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.508452 ], [ 29.723652413793104, -1.508721476923077 ], [ 29.723921896551722, -1.508721476923077 ], [ 29.723921896551722, -1.508452 ], [ 29.723652413793104, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7487, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.508452 ], [ 29.723921896551722, -1.508721476923077 ], [ 29.724191379310344, -1.508721476923077 ], [ 29.724191379310344, -1.508452 ], [ 29.723921896551722, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7488, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.508452 ], [ 29.724191379310344, -1.508721476923077 ], [ 29.724460862068966, -1.508721476923077 ], [ 29.724460862068966, -1.508452 ], [ 29.724191379310344, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7489, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.508452 ], [ 29.724460862068966, -1.508721476923077 ], [ 29.724730344827588, -1.508721476923077 ], [ 29.724730344827588, -1.508452 ], [ 29.724460862068966, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7490, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.508452 ], [ 29.724730344827588, -1.508721476923077 ], [ 29.724999827586206, -1.508721476923077 ], [ 29.724999827586206, -1.508452 ], [ 29.724730344827588, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7491, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.508452 ], [ 29.724999827586206, -1.508721476923077 ], [ 29.725269310344828, -1.508721476923077 ], [ 29.725269310344828, -1.508452 ], [ 29.724999827586206, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7492, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.508452 ], [ 29.725269310344828, -1.508721476923077 ], [ 29.72553879310345, -1.508721476923077 ], [ 29.72553879310345, -1.508452 ], [ 29.725269310344828, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7493, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.508452 ], [ 29.72607775862069, -1.508721476923077 ], [ 29.726347241379312, -1.508721476923077 ], [ 29.726347241379312, -1.508452 ], [ 29.72607775862069, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7494, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.508452 ], [ 29.729850517241381, -1.508721476923077 ], [ 29.73012, -1.508721476923077 ], [ 29.73012, -1.508452 ], [ 29.729850517241381, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7495, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.508452 ], [ 29.730658965517243, -1.508721476923077 ], [ 29.730928448275861, -1.508721476923077 ], [ 29.730928448275861, -1.508452 ], [ 29.730658965517243, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7496, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.508452 ], [ 29.73739603448276, -1.508990953846154 ], [ 29.737665517241378, -1.508990953846154 ], [ 29.737665517241378, -1.508452 ], [ 29.73739603448276, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7497, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.508452 ], [ 29.738204482758622, -1.508721476923077 ], [ 29.738743448275862, -1.508721476923077 ], [ 29.738743448275862, -1.508452 ], [ 29.738204482758622, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7498, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.508452 ], [ 29.738743448275862, -1.508990953846154 ], [ 29.739012931034484, -1.508990953846154 ], [ 29.739012931034484, -1.508452 ], [ 29.738743448275862, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7499, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.508452 ], [ 29.739012931034484, -1.508721476923077 ], [ 29.739282413793102, -1.508721476923077 ], [ 29.739282413793102, -1.508452 ], [ 29.739012931034484, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7500, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.508452 ], [ 29.739282413793102, -1.508721476923077 ], [ 29.739551896551724, -1.508721476923077 ], [ 29.739551896551724, -1.508452 ], [ 29.739282413793102, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7501, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.508452 ], [ 29.739551896551724, -1.508721476923077 ], [ 29.741707758620691, -1.508721476923077 ], [ 29.741707758620691, -1.508452 ], [ 29.739551896551724, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7502, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.508452 ], [ 29.742246724137932, -1.508721476923077 ], [ 29.742516206896553, -1.508721476923077 ], [ 29.742516206896553, -1.508452 ], [ 29.742246724137932, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7503, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.508452 ], [ 29.742516206896553, -1.508990953846154 ], [ 29.742785689655172, -1.508990953846154 ], [ 29.742785689655172, -1.508452 ], [ 29.742516206896553, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7504, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.508452 ], [ 29.751678620689656, -1.508721476923077 ], [ 29.751948103448278, -1.508721476923077 ], [ 29.751948103448278, -1.508452 ], [ 29.751678620689656, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7505, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.508452 ], [ 29.75275655172414, -1.509260430769231 ], [ 29.753026034482758, -1.509260430769231 ], [ 29.753026034482758, -1.508452 ], [ 29.75275655172414, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7506, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.508452 ], [ 29.753026034482758, -1.508990953846154 ], [ 29.75329551724138, -1.508990953846154 ], [ 29.75329551724138, -1.508452 ], [ 29.753026034482758, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7507, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.508452 ], [ 29.75329551724138, -1.508990953846154 ], [ 29.753565, -1.508990953846154 ], [ 29.753565, -1.508452 ], [ 29.75329551724138, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7508, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.508452 ], [ 29.754103965517242, -1.508721476923077 ], [ 29.754373448275864, -1.508721476923077 ], [ 29.754373448275864, -1.508452 ], [ 29.754103965517242, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7509, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.508452 ], [ 29.754373448275864, -1.508721476923077 ], [ 29.754642931034482, -1.508721476923077 ], [ 29.754642931034482, -1.508452 ], [ 29.754373448275864, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7510, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.508452 ], [ 29.754642931034482, -1.508721476923077 ], [ 29.754912413793104, -1.508721476923077 ], [ 29.754912413793104, -1.508452 ], [ 29.754642931034482, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7511, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.508452 ], [ 29.754912413793104, -1.508721476923077 ], [ 29.755181896551726, -1.508721476923077 ], [ 29.755181896551726, -1.508452 ], [ 29.754912413793104, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7512, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.508452 ], [ 29.755181896551726, -1.508721476923077 ], [ 29.755451379310344, -1.508721476923077 ], [ 29.755451379310344, -1.508452 ], [ 29.755181896551726, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7513, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.508452 ], [ 29.755451379310344, -1.508721476923077 ], [ 29.755720862068966, -1.508721476923077 ], [ 29.755720862068966, -1.508452 ], [ 29.755451379310344, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7514, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.508452 ], [ 29.755720862068966, -1.508721476923077 ], [ 29.755990344827588, -1.508721476923077 ], [ 29.755990344827588, -1.508452 ], [ 29.755720862068966, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7515, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.508452 ], [ 29.755990344827588, -1.508721476923077 ], [ 29.756259827586206, -1.508721476923077 ], [ 29.756259827586206, -1.508452 ], [ 29.755990344827588, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7516, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.508452 ], [ 29.756259827586206, -1.508721476923077 ], [ 29.756798793103449, -1.508721476923077 ], [ 29.756798793103449, -1.508452 ], [ 29.756259827586206, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7517, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.508452 ], [ 29.756798793103449, -1.508721476923077 ], [ 29.757068275862068, -1.508721476923077 ], [ 29.757068275862068, -1.508452 ], [ 29.756798793103449, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7518, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.508452 ], [ 29.757068275862068, -1.508721476923077 ], [ 29.75733775862069, -1.508721476923077 ], [ 29.75733775862069, -1.508452 ], [ 29.757068275862068, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7519, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.508452 ], [ 29.75733775862069, -1.508721476923077 ], [ 29.757607241379311, -1.508721476923077 ], [ 29.757607241379311, -1.508452 ], [ 29.75733775862069, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7520, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.508452 ], [ 29.757607241379311, -1.508721476923077 ], [ 29.757876724137933, -1.508721476923077 ], [ 29.757876724137933, -1.508452 ], [ 29.757607241379311, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7521, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.508452 ], [ 29.757876724137933, -1.508721476923077 ], [ 29.758146206896551, -1.508721476923077 ], [ 29.758146206896551, -1.508452 ], [ 29.757876724137933, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7522, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.508452 ], [ 29.758146206896551, -1.508721476923077 ], [ 29.758415689655173, -1.508721476923077 ], [ 29.758415689655173, -1.508452 ], [ 29.758146206896551, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7523, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.508452 ], [ 29.758415689655173, -1.508721476923077 ], [ 29.758685172413795, -1.508721476923077 ], [ 29.758685172413795, -1.508452 ], [ 29.758415689655173, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7524, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.508452 ], [ 29.758685172413795, -1.508721476923077 ], [ 29.758954655172413, -1.508721476923077 ], [ 29.758954655172413, -1.508452 ], [ 29.758685172413795, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7525, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.508452 ], [ 29.758954655172413, -1.508721476923077 ], [ 29.759224137931035, -1.508721476923077 ], [ 29.759224137931035, -1.508452 ], [ 29.758954655172413, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7526, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.508452 ], [ 29.759224137931035, -1.508721476923077 ], [ 29.759493620689657, -1.508721476923077 ], [ 29.759493620689657, -1.508452 ], [ 29.759224137931035, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7527, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.508452 ], [ 29.759493620689657, -1.508721476923077 ], [ 29.759763103448275, -1.508721476923077 ], [ 29.759763103448275, -1.508452 ], [ 29.759493620689657, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7528, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.508452 ], [ 29.759763103448275, -1.508721476923077 ], [ 29.760032586206897, -1.508721476923077 ], [ 29.760032586206897, -1.508452 ], [ 29.759763103448275, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7529, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.508452 ], [ 29.760032586206897, -1.508721476923077 ], [ 29.760302068965519, -1.508721476923077 ], [ 29.760302068965519, -1.508452 ], [ 29.760032586206897, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7530, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.508452 ], [ 29.760302068965519, -1.508721476923077 ], [ 29.760571551724137, -1.508721476923077 ], [ 29.760571551724137, -1.508452 ], [ 29.760302068965519, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7531, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.508452 ], [ 29.760571551724137, -1.508721476923077 ], [ 29.760841034482759, -1.508721476923077 ], [ 29.760841034482759, -1.508452 ], [ 29.760571551724137, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7532, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.508452 ], [ 29.760841034482759, -1.508721476923077 ], [ 29.761110517241381, -1.508721476923077 ], [ 29.761110517241381, -1.508452 ], [ 29.760841034482759, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7533, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.508452 ], [ 29.761110517241381, -1.508721476923077 ], [ 29.76138, -1.508721476923077 ], [ 29.76138, -1.508452 ], [ 29.761110517241381, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7534, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.508452 ], [ 29.76138, -1.508721476923077 ], [ 29.761649482758621, -1.508721476923077 ], [ 29.761649482758621, -1.508452 ], [ 29.76138, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7535, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.508452 ], [ 29.761649482758621, -1.508721476923077 ], [ 29.761918965517243, -1.508721476923077 ], [ 29.761918965517243, -1.508452 ], [ 29.761649482758621, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7536, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.508452 ], [ 29.761918965517243, -1.508721476923077 ], [ 29.762188448275861, -1.508721476923077 ], [ 29.762188448275861, -1.508452 ], [ 29.761918965517243, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7537, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.508452 ], [ 29.762188448275861, -1.508721476923077 ], [ 29.762457931034483, -1.508721476923077 ], [ 29.762457931034483, -1.508452 ], [ 29.762188448275861, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7538, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.508452 ], [ 29.762457931034483, -1.508721476923077 ], [ 29.762727413793105, -1.508721476923077 ], [ 29.762727413793105, -1.508452 ], [ 29.762457931034483, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7539, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.508452 ], [ 29.762727413793105, -1.508721476923077 ], [ 29.762996896551723, -1.508721476923077 ], [ 29.762996896551723, -1.508452 ], [ 29.762727413793105, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7540, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.508452 ], [ 29.762996896551723, -1.508721476923077 ], [ 29.763266379310345, -1.508721476923077 ], [ 29.763266379310345, -1.508452 ], [ 29.762996896551723, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7541, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.508452 ], [ 29.763266379310345, -1.508721476923077 ], [ 29.763535862068967, -1.508721476923077 ], [ 29.763535862068967, -1.508452 ], [ 29.763266379310345, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7542, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.508452 ], [ 29.763535862068967, -1.508721476923077 ], [ 29.763805344827588, -1.508721476923077 ], [ 29.763805344827588, -1.508452 ], [ 29.763535862068967, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7543, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.508452 ], [ 29.763805344827588, -1.508721476923077 ], [ 29.764074827586207, -1.508721476923077 ], [ 29.764074827586207, -1.508452 ], [ 29.763805344827588, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7544, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.508452 ], [ 29.764344310344828, -1.508721476923077 ], [ 29.76461379310345, -1.508721476923077 ], [ 29.76461379310345, -1.508452 ], [ 29.764344310344828, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7545, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.508452 ], [ 29.76461379310345, -1.508721476923077 ], [ 29.764883275862069, -1.508721476923077 ], [ 29.764883275862069, -1.508452 ], [ 29.76461379310345, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7546, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.508452 ], [ 29.764883275862069, -1.508721476923077 ], [ 29.76515275862069, -1.508721476923077 ], [ 29.76515275862069, -1.508452 ], [ 29.764883275862069, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7547, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.508452 ], [ 29.765961206896552, -1.508990953846154 ], [ 29.766230689655174, -1.508990953846154 ], [ 29.766230689655174, -1.508452 ], [ 29.765961206896552, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7548, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.508452 ], [ 29.766230689655174, -1.508990953846154 ], [ 29.766500172413792, -1.508990953846154 ], [ 29.766500172413792, -1.508452 ], [ 29.766230689655174, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7549, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.508452 ], [ 29.766500172413792, -1.508721476923077 ], [ 29.766769655172414, -1.508721476923077 ], [ 29.766769655172414, -1.508452 ], [ 29.766500172413792, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7550, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.508721476923077 ], [ 29.719610172413795, -1.509260430769231 ], [ 29.719879655172413, -1.509260430769231 ], [ 29.719879655172413, -1.508721476923077 ], [ 29.719610172413795, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7551, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.508721476923077 ], [ 29.719879655172413, -1.508990953846154 ], [ 29.720149137931035, -1.508990953846154 ], [ 29.720149137931035, -1.508721476923077 ], [ 29.719879655172413, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7552, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.508721476923077 ], [ 29.720957586206897, -1.509260430769231 ], [ 29.721227068965518, -1.509260430769231 ], [ 29.721227068965518, -1.508721476923077 ], [ 29.720957586206897, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7553, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.508721476923077 ], [ 29.721227068965518, -1.508990953846154 ], [ 29.721496551724137, -1.508990953846154 ], [ 29.721496551724137, -1.508721476923077 ], [ 29.721227068965518, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7554, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.508721476923077 ], [ 29.721496551724137, -1.508990953846154 ], [ 29.721766034482759, -1.508990953846154 ], [ 29.721766034482759, -1.508721476923077 ], [ 29.721496551724137, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7555, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.508721476923077 ], [ 29.721766034482759, -1.508990953846154 ], [ 29.72203551724138, -1.508990953846154 ], [ 29.72203551724138, -1.508721476923077 ], [ 29.721766034482759, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7556, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.508721476923077 ], [ 29.72203551724138, -1.508990953846154 ], [ 29.722305, -1.508990953846154 ], [ 29.722305, -1.508721476923077 ], [ 29.72203551724138, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7557, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.508721476923077 ], [ 29.722305, -1.508990953846154 ], [ 29.72257448275862, -1.508990953846154 ], [ 29.72257448275862, -1.508721476923077 ], [ 29.722305, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7558, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.508721476923077 ], [ 29.72257448275862, -1.508990953846154 ], [ 29.722843965517242, -1.508990953846154 ], [ 29.722843965517242, -1.508721476923077 ], [ 29.72257448275862, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7559, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.508721476923077 ], [ 29.722843965517242, -1.508990953846154 ], [ 29.723113448275861, -1.508990953846154 ], [ 29.723113448275861, -1.508721476923077 ], [ 29.722843965517242, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7560, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.508721476923077 ], [ 29.723113448275861, -1.508990953846154 ], [ 29.723382931034482, -1.508990953846154 ], [ 29.723382931034482, -1.508721476923077 ], [ 29.723113448275861, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7561, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.508721476923077 ], [ 29.723382931034482, -1.508990953846154 ], [ 29.723652413793104, -1.508990953846154 ], [ 29.723652413793104, -1.508721476923077 ], [ 29.723382931034482, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7562, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.508721476923077 ], [ 29.723652413793104, -1.508990953846154 ], [ 29.723921896551722, -1.508990953846154 ], [ 29.723921896551722, -1.508721476923077 ], [ 29.723652413793104, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7563, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.508721476923077 ], [ 29.723921896551722, -1.508990953846154 ], [ 29.724191379310344, -1.508990953846154 ], [ 29.724191379310344, -1.508721476923077 ], [ 29.723921896551722, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7564, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.508721476923077 ], [ 29.724191379310344, -1.508990953846154 ], [ 29.724460862068966, -1.508990953846154 ], [ 29.724460862068966, -1.508721476923077 ], [ 29.724191379310344, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7565, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.508721476923077 ], [ 29.724460862068966, -1.508990953846154 ], [ 29.724730344827588, -1.508990953846154 ], [ 29.724730344827588, -1.508721476923077 ], [ 29.724460862068966, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7566, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.508721476923077 ], [ 29.724730344827588, -1.508990953846154 ], [ 29.724999827586206, -1.508990953846154 ], [ 29.724999827586206, -1.508721476923077 ], [ 29.724730344827588, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7567, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.508721476923077 ], [ 29.724999827586206, -1.508990953846154 ], [ 29.725269310344828, -1.508990953846154 ], [ 29.725269310344828, -1.508721476923077 ], [ 29.724999827586206, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7568, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.508721476923077 ], [ 29.725269310344828, -1.508990953846154 ], [ 29.72553879310345, -1.508990953846154 ], [ 29.72553879310345, -1.508721476923077 ], [ 29.725269310344828, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7569, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.508721476923077 ], [ 29.729850517241381, -1.509260430769231 ], [ 29.73012, -1.509260430769231 ], [ 29.73012, -1.508721476923077 ], [ 29.729850517241381, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7570, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.508721476923077 ], [ 29.730658965517243, -1.508990953846154 ], [ 29.730928448275861, -1.508990953846154 ], [ 29.730928448275861, -1.508721476923077 ], [ 29.730658965517243, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7571, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.508721476923077 ], [ 29.730928448275861, -1.508990953846154 ], [ 29.731467413793105, -1.508990953846154 ], [ 29.731467413793105, -1.508721476923077 ], [ 29.730928448275861, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7572, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.508721476923077 ], [ 29.734162241379309, -1.508990953846154 ], [ 29.734970689655171, -1.508990953846154 ], [ 29.734970689655171, -1.508721476923077 ], [ 29.734162241379309, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7573, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.508721476923077 ], [ 29.737665517241378, -1.508990953846154 ], [ 29.737935, -1.508990953846154 ], [ 29.737935, -1.508721476923077 ], [ 29.737665517241378, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7574, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.508721476923077 ], [ 29.737935, -1.509260430769231 ], [ 29.738204482758622, -1.509260430769231 ], [ 29.738204482758622, -1.508721476923077 ], [ 29.737935, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7575, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.508721476923077 ], [ 29.738204482758622, -1.509260430769231 ], [ 29.738743448275862, -1.509260430769231 ], [ 29.738743448275862, -1.508721476923077 ], [ 29.738204482758622, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7576, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.508721476923077 ], [ 29.739551896551724, -1.508990953846154 ], [ 29.739821379310346, -1.508990953846154 ], [ 29.739821379310346, -1.508721476923077 ], [ 29.739551896551724, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7577, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.508721476923077 ], [ 29.739821379310346, -1.508990953846154 ], [ 29.74143827586207, -1.508990953846154 ], [ 29.74143827586207, -1.508721476923077 ], [ 29.739821379310346, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7578, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.508721476923077 ], [ 29.74143827586207, -1.508990953846154 ], [ 29.741707758620691, -1.508990953846154 ], [ 29.741707758620691, -1.508721476923077 ], [ 29.74143827586207, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7579, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.508721476923077 ], [ 29.741707758620691, -1.508990953846154 ], [ 29.74197724137931, -1.508990953846154 ], [ 29.74197724137931, -1.508721476923077 ], [ 29.741707758620691, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7580, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.508721476923077 ], [ 29.74197724137931, -1.508990953846154 ], [ 29.742246724137932, -1.508990953846154 ], [ 29.742246724137932, -1.508721476923077 ], [ 29.74197724137931, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7581, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.508721476923077 ], [ 29.752487068965518, -1.509260430769231 ], [ 29.75275655172414, -1.509260430769231 ], [ 29.75275655172414, -1.508721476923077 ], [ 29.752487068965518, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7582, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.508721476923077 ], [ 29.753565, -1.508990953846154 ], [ 29.75383448275862, -1.508990953846154 ], [ 29.75383448275862, -1.508721476923077 ], [ 29.753565, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7583, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.508721476923077 ], [ 29.75383448275862, -1.508990953846154 ], [ 29.754103965517242, -1.508990953846154 ], [ 29.754103965517242, -1.508721476923077 ], [ 29.75383448275862, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7584, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.508721476923077 ], [ 29.754103965517242, -1.508990953846154 ], [ 29.754373448275864, -1.508990953846154 ], [ 29.754373448275864, -1.508721476923077 ], [ 29.754103965517242, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7585, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.508721476923077 ], [ 29.754373448275864, -1.508990953846154 ], [ 29.754642931034482, -1.508990953846154 ], [ 29.754642931034482, -1.508721476923077 ], [ 29.754373448275864, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7586, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.508721476923077 ], [ 29.754642931034482, -1.508990953846154 ], [ 29.754912413793104, -1.508990953846154 ], [ 29.754912413793104, -1.508721476923077 ], [ 29.754642931034482, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7587, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.508721476923077 ], [ 29.754912413793104, -1.508990953846154 ], [ 29.755181896551726, -1.508990953846154 ], [ 29.755181896551726, -1.508721476923077 ], [ 29.754912413793104, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7588, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.508721476923077 ], [ 29.755181896551726, -1.508990953846154 ], [ 29.755451379310344, -1.508990953846154 ], [ 29.755451379310344, -1.508721476923077 ], [ 29.755181896551726, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7589, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.508721476923077 ], [ 29.755451379310344, -1.508990953846154 ], [ 29.755720862068966, -1.508990953846154 ], [ 29.755720862068966, -1.508721476923077 ], [ 29.755451379310344, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7590, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.508721476923077 ], [ 29.755720862068966, -1.508990953846154 ], [ 29.755990344827588, -1.508990953846154 ], [ 29.755990344827588, -1.508721476923077 ], [ 29.755720862068966, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7591, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.508721476923077 ], [ 29.755990344827588, -1.508990953846154 ], [ 29.756259827586206, -1.508990953846154 ], [ 29.756259827586206, -1.508721476923077 ], [ 29.755990344827588, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7592, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.508721476923077 ], [ 29.756259827586206, -1.508990953846154 ], [ 29.756798793103449, -1.508990953846154 ], [ 29.756798793103449, -1.508721476923077 ], [ 29.756259827586206, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7593, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.508721476923077 ], [ 29.756798793103449, -1.508990953846154 ], [ 29.757068275862068, -1.508990953846154 ], [ 29.757068275862068, -1.508721476923077 ], [ 29.756798793103449, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7594, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.508721476923077 ], [ 29.757068275862068, -1.508990953846154 ], [ 29.75733775862069, -1.508990953846154 ], [ 29.75733775862069, -1.508721476923077 ], [ 29.757068275862068, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7595, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.508721476923077 ], [ 29.75733775862069, -1.508990953846154 ], [ 29.757607241379311, -1.508990953846154 ], [ 29.757607241379311, -1.508721476923077 ], [ 29.75733775862069, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7596, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.508721476923077 ], [ 29.757607241379311, -1.508990953846154 ], [ 29.757876724137933, -1.508990953846154 ], [ 29.757876724137933, -1.508721476923077 ], [ 29.757607241379311, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7597, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.508721476923077 ], [ 29.757876724137933, -1.508990953846154 ], [ 29.758146206896551, -1.508990953846154 ], [ 29.758146206896551, -1.508721476923077 ], [ 29.757876724137933, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7598, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.508721476923077 ], [ 29.758146206896551, -1.508990953846154 ], [ 29.758415689655173, -1.508990953846154 ], [ 29.758415689655173, -1.508721476923077 ], [ 29.758146206896551, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7599, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.508721476923077 ], [ 29.758415689655173, -1.508990953846154 ], [ 29.758685172413795, -1.508990953846154 ], [ 29.758685172413795, -1.508721476923077 ], [ 29.758415689655173, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7600, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.508721476923077 ], [ 29.758685172413795, -1.508990953846154 ], [ 29.758954655172413, -1.508990953846154 ], [ 29.758954655172413, -1.508721476923077 ], [ 29.758685172413795, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7601, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.508721476923077 ], [ 29.758954655172413, -1.508990953846154 ], [ 29.759224137931035, -1.508990953846154 ], [ 29.759224137931035, -1.508721476923077 ], [ 29.758954655172413, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7602, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.508721476923077 ], [ 29.759224137931035, -1.508990953846154 ], [ 29.759493620689657, -1.508990953846154 ], [ 29.759493620689657, -1.508721476923077 ], [ 29.759224137931035, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7603, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.508721476923077 ], [ 29.759493620689657, -1.508990953846154 ], [ 29.759763103448275, -1.508990953846154 ], [ 29.759763103448275, -1.508721476923077 ], [ 29.759493620689657, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7604, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.508721476923077 ], [ 29.759763103448275, -1.509260430769231 ], [ 29.760032586206897, -1.509260430769231 ], [ 29.760032586206897, -1.508721476923077 ], [ 29.759763103448275, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7605, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.508721476923077 ], [ 29.760032586206897, -1.508990953846154 ], [ 29.760302068965519, -1.508990953846154 ], [ 29.760302068965519, -1.508721476923077 ], [ 29.760032586206897, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7606, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.508721476923077 ], [ 29.760302068965519, -1.508990953846154 ], [ 29.760571551724137, -1.508990953846154 ], [ 29.760571551724137, -1.508721476923077 ], [ 29.760302068965519, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7607, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.508721476923077 ], [ 29.760571551724137, -1.508990953846154 ], [ 29.760841034482759, -1.508990953846154 ], [ 29.760841034482759, -1.508721476923077 ], [ 29.760571551724137, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7608, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.508721476923077 ], [ 29.760841034482759, -1.508990953846154 ], [ 29.761110517241381, -1.508990953846154 ], [ 29.761110517241381, -1.508721476923077 ], [ 29.760841034482759, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7609, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.508721476923077 ], [ 29.761110517241381, -1.508990953846154 ], [ 29.76138, -1.508990953846154 ], [ 29.76138, -1.508721476923077 ], [ 29.761110517241381, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7610, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.508721476923077 ], [ 29.76138, -1.508990953846154 ], [ 29.761649482758621, -1.508990953846154 ], [ 29.761649482758621, -1.508721476923077 ], [ 29.76138, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7611, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.508721476923077 ], [ 29.761649482758621, -1.508990953846154 ], [ 29.761918965517243, -1.508990953846154 ], [ 29.761918965517243, -1.508721476923077 ], [ 29.761649482758621, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7612, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.508721476923077 ], [ 29.761918965517243, -1.508990953846154 ], [ 29.762188448275861, -1.508990953846154 ], [ 29.762188448275861, -1.508721476923077 ], [ 29.761918965517243, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7613, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.508721476923077 ], [ 29.762188448275861, -1.508990953846154 ], [ 29.762457931034483, -1.508990953846154 ], [ 29.762457931034483, -1.508721476923077 ], [ 29.762188448275861, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7614, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.508721476923077 ], [ 29.762457931034483, -1.508990953846154 ], [ 29.762727413793105, -1.508990953846154 ], [ 29.762727413793105, -1.508721476923077 ], [ 29.762457931034483, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7615, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.508721476923077 ], [ 29.762727413793105, -1.508990953846154 ], [ 29.762996896551723, -1.508990953846154 ], [ 29.762996896551723, -1.508721476923077 ], [ 29.762727413793105, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7616, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.508721476923077 ], [ 29.762996896551723, -1.508990953846154 ], [ 29.763266379310345, -1.508990953846154 ], [ 29.763266379310345, -1.508721476923077 ], [ 29.762996896551723, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7617, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.508721476923077 ], [ 29.763266379310345, -1.508990953846154 ], [ 29.763535862068967, -1.508990953846154 ], [ 29.763535862068967, -1.508721476923077 ], [ 29.763266379310345, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7618, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.508721476923077 ], [ 29.764344310344828, -1.508990953846154 ], [ 29.76461379310345, -1.508990953846154 ], [ 29.76461379310345, -1.508721476923077 ], [ 29.764344310344828, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7619, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.508721476923077 ], [ 29.76461379310345, -1.508990953846154 ], [ 29.764883275862069, -1.508990953846154 ], [ 29.764883275862069, -1.508721476923077 ], [ 29.76461379310345, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7620, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.508721476923077 ], [ 29.764883275862069, -1.508990953846154 ], [ 29.76515275862069, -1.508990953846154 ], [ 29.76515275862069, -1.508721476923077 ], [ 29.764883275862069, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7621, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.508721476923077 ], [ 29.76515275862069, -1.509260430769231 ], [ 29.765422241379312, -1.509260430769231 ], [ 29.765422241379312, -1.508721476923077 ], [ 29.76515275862069, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7622, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.508721476923077 ], [ 29.765422241379312, -1.508990953846154 ], [ 29.765961206896552, -1.508990953846154 ], [ 29.765961206896552, -1.508721476923077 ], [ 29.765422241379312, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7623, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.508721476923077 ], [ 29.766500172413792, -1.509260430769231 ], [ 29.766769655172414, -1.509260430769231 ], [ 29.766769655172414, -1.508721476923077 ], [ 29.766500172413792, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7624, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.508990953846154 ], [ 29.720688103448275, -1.509260430769231 ], [ 29.720957586206897, -1.509260430769231 ], [ 29.720957586206897, -1.508990953846154 ], [ 29.720688103448275, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7625, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.508990953846154 ], [ 29.721227068965518, -1.509260430769231 ], [ 29.721496551724137, -1.509260430769231 ], [ 29.721496551724137, -1.508990953846154 ], [ 29.721227068965518, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7626, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.508990953846154 ], [ 29.721496551724137, -1.509260430769231 ], [ 29.721766034482759, -1.509260430769231 ], [ 29.721766034482759, -1.508990953846154 ], [ 29.721496551724137, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7627, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.508990953846154 ], [ 29.721766034482759, -1.509260430769231 ], [ 29.72203551724138, -1.509260430769231 ], [ 29.72203551724138, -1.508990953846154 ], [ 29.721766034482759, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7628, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.508990953846154 ], [ 29.72203551724138, -1.509260430769231 ], [ 29.722305, -1.509260430769231 ], [ 29.722305, -1.508990953846154 ], [ 29.72203551724138, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7629, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.508990953846154 ], [ 29.722305, -1.509260430769231 ], [ 29.72257448275862, -1.509260430769231 ], [ 29.72257448275862, -1.508990953846154 ], [ 29.722305, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7630, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.508990953846154 ], [ 29.72257448275862, -1.509260430769231 ], [ 29.722843965517242, -1.509260430769231 ], [ 29.722843965517242, -1.508990953846154 ], [ 29.72257448275862, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7631, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.508990953846154 ], [ 29.722843965517242, -1.509260430769231 ], [ 29.723113448275861, -1.509260430769231 ], [ 29.723113448275861, -1.508990953846154 ], [ 29.722843965517242, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7632, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.508990953846154 ], [ 29.723113448275861, -1.509260430769231 ], [ 29.723382931034482, -1.509260430769231 ], [ 29.723382931034482, -1.508990953846154 ], [ 29.723113448275861, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7633, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.508990953846154 ], [ 29.723382931034482, -1.509260430769231 ], [ 29.723652413793104, -1.509260430769231 ], [ 29.723652413793104, -1.508990953846154 ], [ 29.723382931034482, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7634, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.508990953846154 ], [ 29.723652413793104, -1.509260430769231 ], [ 29.723921896551722, -1.509260430769231 ], [ 29.723921896551722, -1.508990953846154 ], [ 29.723652413793104, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7635, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.508990953846154 ], [ 29.723921896551722, -1.509260430769231 ], [ 29.724191379310344, -1.509260430769231 ], [ 29.724191379310344, -1.508990953846154 ], [ 29.723921896551722, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7636, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.508990953846154 ], [ 29.724191379310344, -1.509260430769231 ], [ 29.724460862068966, -1.509260430769231 ], [ 29.724460862068966, -1.508990953846154 ], [ 29.724191379310344, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7637, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.508990953846154 ], [ 29.724460862068966, -1.509260430769231 ], [ 29.724730344827588, -1.509260430769231 ], [ 29.724730344827588, -1.508990953846154 ], [ 29.724460862068966, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7638, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.508990953846154 ], [ 29.724730344827588, -1.509260430769231 ], [ 29.724999827586206, -1.509260430769231 ], [ 29.724999827586206, -1.508990953846154 ], [ 29.724730344827588, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7639, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.508990953846154 ], [ 29.724999827586206, -1.509260430769231 ], [ 29.725269310344828, -1.509260430769231 ], [ 29.725269310344828, -1.508990953846154 ], [ 29.724999827586206, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7640, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.508990953846154 ], [ 29.728772586206897, -1.509260430769231 ], [ 29.729042068965516, -1.509260430769231 ], [ 29.729042068965516, -1.508990953846154 ], [ 29.728772586206897, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7641, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.508990953846154 ], [ 29.729042068965516, -1.509260430769231 ], [ 29.729311551724138, -1.509260430769231 ], [ 29.729311551724138, -1.508990953846154 ], [ 29.729042068965516, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7642, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.508990953846154 ], [ 29.729311551724138, -1.509260430769231 ], [ 29.729850517241381, -1.509260430769231 ], [ 29.729850517241381, -1.508990953846154 ], [ 29.729311551724138, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7643, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.508990953846154 ], [ 29.73012, -1.509260430769231 ], [ 29.730389482758621, -1.509260430769231 ], [ 29.730389482758621, -1.508990953846154 ], [ 29.73012, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7644, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.508990953846154 ], [ 29.730389482758621, -1.509260430769231 ], [ 29.730658965517243, -1.509260430769231 ], [ 29.730658965517243, -1.508990953846154 ], [ 29.730389482758621, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7645, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.508990953846154 ], [ 29.730658965517243, -1.509260430769231 ], [ 29.731197931034483, -1.509260430769231 ], [ 29.731197931034483, -1.508990953846154 ], [ 29.730658965517243, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7646, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.508990953846154 ], [ 29.731197931034483, -1.509260430769231 ], [ 29.731467413793105, -1.509260430769231 ], [ 29.731467413793105, -1.508990953846154 ], [ 29.731197931034483, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7647, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.508990953846154 ], [ 29.734162241379309, -1.509260430769231 ], [ 29.734431724137931, -1.509260430769231 ], [ 29.734431724137931, -1.508990953846154 ], [ 29.734162241379309, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7648, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.508990953846154 ], [ 29.734431724137931, -1.509260430769231 ], [ 29.734701206896553, -1.509260430769231 ], [ 29.734701206896553, -1.508990953846154 ], [ 29.734431724137931, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7649, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.508990953846154 ], [ 29.734701206896553, -1.509260430769231 ], [ 29.734970689655171, -1.509260430769231 ], [ 29.734970689655171, -1.508990953846154 ], [ 29.734701206896553, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7650, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.508990953846154 ], [ 29.734970689655171, -1.509260430769231 ], [ 29.735509655172415, -1.509260430769231 ], [ 29.735509655172415, -1.508990953846154 ], [ 29.734970689655171, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7651, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.508990953846154 ], [ 29.738743448275862, -1.509260430769231 ], [ 29.739012931034484, -1.509260430769231 ], [ 29.739012931034484, -1.508990953846154 ], [ 29.738743448275862, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7652, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.508990953846154 ], [ 29.74143827586207, -1.509260430769231 ], [ 29.741707758620691, -1.509260430769231 ], [ 29.741707758620691, -1.508990953846154 ], [ 29.74143827586207, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7653, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.508990953846154 ], [ 29.741707758620691, -1.509260430769231 ], [ 29.74197724137931, -1.509260430769231 ], [ 29.74197724137931, -1.508990953846154 ], [ 29.741707758620691, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7654, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.508990953846154 ], [ 29.742516206896553, -1.509260430769231 ], [ 29.742785689655172, -1.509260430769231 ], [ 29.742785689655172, -1.508990953846154 ], [ 29.742516206896553, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7655, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.508990953846154 ], [ 29.742785689655172, -1.509260430769231 ], [ 29.743863620689655, -1.509260430769231 ], [ 29.743863620689655, -1.508990953846154 ], [ 29.742785689655172, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7656, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.508990953846154 ], [ 29.753026034482758, -1.509260430769231 ], [ 29.75329551724138, -1.509260430769231 ], [ 29.75329551724138, -1.508990953846154 ], [ 29.753026034482758, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7657, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.508990953846154 ], [ 29.75329551724138, -1.509260430769231 ], [ 29.753565, -1.509260430769231 ], [ 29.753565, -1.508990953846154 ], [ 29.75329551724138, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7658, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.508990953846154 ], [ 29.753565, -1.509260430769231 ], [ 29.75383448275862, -1.509260430769231 ], [ 29.75383448275862, -1.508990953846154 ], [ 29.753565, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7659, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.508990953846154 ], [ 29.75383448275862, -1.509260430769231 ], [ 29.754103965517242, -1.509260430769231 ], [ 29.754103965517242, -1.508990953846154 ], [ 29.75383448275862, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7660, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.508990953846154 ], [ 29.754103965517242, -1.509260430769231 ], [ 29.754373448275864, -1.509260430769231 ], [ 29.754373448275864, -1.508990953846154 ], [ 29.754103965517242, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7661, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.508990953846154 ], [ 29.754373448275864, -1.509260430769231 ], [ 29.754642931034482, -1.509260430769231 ], [ 29.754642931034482, -1.508990953846154 ], [ 29.754373448275864, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7662, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.508990953846154 ], [ 29.754642931034482, -1.509260430769231 ], [ 29.754912413793104, -1.509260430769231 ], [ 29.754912413793104, -1.508990953846154 ], [ 29.754642931034482, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7663, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.508990953846154 ], [ 29.754912413793104, -1.509260430769231 ], [ 29.755181896551726, -1.509260430769231 ], [ 29.755181896551726, -1.508990953846154 ], [ 29.754912413793104, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7664, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.508990953846154 ], [ 29.755181896551726, -1.509260430769231 ], [ 29.755451379310344, -1.509260430769231 ], [ 29.755451379310344, -1.508990953846154 ], [ 29.755181896551726, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7665, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.508990953846154 ], [ 29.755451379310344, -1.509260430769231 ], [ 29.755720862068966, -1.509260430769231 ], [ 29.755720862068966, -1.508990953846154 ], [ 29.755451379310344, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7666, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.508990953846154 ], [ 29.755720862068966, -1.509260430769231 ], [ 29.755990344827588, -1.509260430769231 ], [ 29.755990344827588, -1.508990953846154 ], [ 29.755720862068966, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7667, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.508990953846154 ], [ 29.755990344827588, -1.509260430769231 ], [ 29.756259827586206, -1.509260430769231 ], [ 29.756259827586206, -1.508990953846154 ], [ 29.755990344827588, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7668, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.508990953846154 ], [ 29.756259827586206, -1.509260430769231 ], [ 29.756798793103449, -1.509260430769231 ], [ 29.756798793103449, -1.508990953846154 ], [ 29.756259827586206, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7669, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.508990953846154 ], [ 29.756798793103449, -1.509260430769231 ], [ 29.757068275862068, -1.509260430769231 ], [ 29.757068275862068, -1.508990953846154 ], [ 29.756798793103449, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7670, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.508990953846154 ], [ 29.757068275862068, -1.509260430769231 ], [ 29.75733775862069, -1.509260430769231 ], [ 29.75733775862069, -1.508990953846154 ], [ 29.757068275862068, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7671, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.508990953846154 ], [ 29.75733775862069, -1.509260430769231 ], [ 29.757607241379311, -1.509260430769231 ], [ 29.757607241379311, -1.508990953846154 ], [ 29.75733775862069, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7672, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.508990953846154 ], [ 29.757607241379311, -1.509260430769231 ], [ 29.757876724137933, -1.509260430769231 ], [ 29.757876724137933, -1.508990953846154 ], [ 29.757607241379311, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7673, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.508990953846154 ], [ 29.757876724137933, -1.509260430769231 ], [ 29.758146206896551, -1.509260430769231 ], [ 29.758146206896551, -1.508990953846154 ], [ 29.757876724137933, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7674, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.508990953846154 ], [ 29.758146206896551, -1.509260430769231 ], [ 29.758415689655173, -1.509260430769231 ], [ 29.758415689655173, -1.508990953846154 ], [ 29.758146206896551, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7675, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.508990953846154 ], [ 29.758415689655173, -1.509260430769231 ], [ 29.758685172413795, -1.509260430769231 ], [ 29.758685172413795, -1.508990953846154 ], [ 29.758415689655173, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7676, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.508990953846154 ], [ 29.758685172413795, -1.509260430769231 ], [ 29.758954655172413, -1.509260430769231 ], [ 29.758954655172413, -1.508990953846154 ], [ 29.758685172413795, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7677, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.508990953846154 ], [ 29.758954655172413, -1.509260430769231 ], [ 29.759224137931035, -1.509260430769231 ], [ 29.759224137931035, -1.508990953846154 ], [ 29.758954655172413, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7678, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.508990953846154 ], [ 29.759224137931035, -1.509260430769231 ], [ 29.759493620689657, -1.509260430769231 ], [ 29.759493620689657, -1.508990953846154 ], [ 29.759224137931035, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7679, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.508990953846154 ], [ 29.759493620689657, -1.509260430769231 ], [ 29.759763103448275, -1.509260430769231 ], [ 29.759763103448275, -1.508990953846154 ], [ 29.759493620689657, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7680, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.508990953846154 ], [ 29.760032586206897, -1.509260430769231 ], [ 29.760302068965519, -1.509260430769231 ], [ 29.760302068965519, -1.508990953846154 ], [ 29.760032586206897, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7681, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.508990953846154 ], [ 29.760302068965519, -1.509260430769231 ], [ 29.760571551724137, -1.509260430769231 ], [ 29.760571551724137, -1.508990953846154 ], [ 29.760302068965519, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7682, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.508990953846154 ], [ 29.760571551724137, -1.509260430769231 ], [ 29.760841034482759, -1.509260430769231 ], [ 29.760841034482759, -1.508990953846154 ], [ 29.760571551724137, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7683, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.508990953846154 ], [ 29.760841034482759, -1.509260430769231 ], [ 29.761110517241381, -1.509260430769231 ], [ 29.761110517241381, -1.508990953846154 ], [ 29.760841034482759, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7684, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.508990953846154 ], [ 29.761110517241381, -1.509260430769231 ], [ 29.76138, -1.509260430769231 ], [ 29.76138, -1.508990953846154 ], [ 29.761110517241381, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7685, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.508990953846154 ], [ 29.76138, -1.509260430769231 ], [ 29.761649482758621, -1.509260430769231 ], [ 29.761649482758621, -1.508990953846154 ], [ 29.76138, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7686, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.508990953846154 ], [ 29.761649482758621, -1.509260430769231 ], [ 29.761918965517243, -1.509260430769231 ], [ 29.761918965517243, -1.508990953846154 ], [ 29.761649482758621, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7687, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.508990953846154 ], [ 29.761918965517243, -1.509260430769231 ], [ 29.762188448275861, -1.509260430769231 ], [ 29.762188448275861, -1.508990953846154 ], [ 29.761918965517243, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7688, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.508990953846154 ], [ 29.762188448275861, -1.509260430769231 ], [ 29.762457931034483, -1.509260430769231 ], [ 29.762457931034483, -1.508990953846154 ], [ 29.762188448275861, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7689, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.508990953846154 ], [ 29.762457931034483, -1.509260430769231 ], [ 29.762727413793105, -1.509260430769231 ], [ 29.762727413793105, -1.508990953846154 ], [ 29.762457931034483, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7690, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.508990953846154 ], [ 29.766230689655174, -1.509260430769231 ], [ 29.766500172413792, -1.509260430769231 ], [ 29.766500172413792, -1.508990953846154 ], [ 29.766230689655174, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7691, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.507374092307692 ], [ 29.726886206896552, -1.509529907692308 ], [ 29.727155689655174, -1.509529907692308 ], [ 29.727155689655174, -1.507374092307692 ], [ 29.726886206896552, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7692, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.507374092307692 ], [ 29.727694655172414, -1.509799384615385 ], [ 29.727964137931036, -1.509799384615385 ], [ 29.727964137931036, -1.507374092307692 ], [ 29.727694655172414, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7693, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.507374092307692 ], [ 29.72607775862069, -1.507374092307692 ], [ 29.72607775862069, -1.509529907692308 ], [ 29.725808275862068, -1.509529907692308 ], [ 29.725808275862068, -1.508182523076923 ], [ 29.72553879310345, -1.508182523076923 ], [ 29.72553879310345, -1.507913046153846 ], [ 29.725808275862068, -1.507913046153846 ], [ 29.725808275862068, -1.507374092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7694, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.507913046153846 ], [ 29.72661672413793, -1.509799384615385 ], [ 29.726886206896552, -1.509799384615385 ], [ 29.726886206896552, -1.507913046153846 ], [ 29.72661672413793, -1.507913046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7695, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.508452 ], [ 29.751948103448278, -1.509529907692308 ], [ 29.752217586206896, -1.509529907692308 ], [ 29.752217586206896, -1.508452 ], [ 29.751948103448278, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7696, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.508452 ], [ 29.752217586206896, -1.509529907692308 ], [ 29.752487068965518, -1.509529907692308 ], [ 29.752487068965518, -1.508452 ], [ 29.752217586206896, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7697, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.508452 ], [ 29.764074827586207, -1.510338338461538 ], [ 29.764344310344828, -1.510338338461538 ], [ 29.764344310344828, -1.508452 ], [ 29.764074827586207, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7698, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.508721476923077 ], [ 29.72607775862069, -1.509529907692308 ], [ 29.726347241379312, -1.509529907692308 ], [ 29.726347241379312, -1.508721476923077 ], [ 29.72607775862069, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7699, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.508721476923077 ], [ 29.739282413793102, -1.510068861538461 ], [ 29.739551896551724, -1.510068861538461 ], [ 29.739551896551724, -1.508721476923077 ], [ 29.739282413793102, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7700, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751678620689656, -1.508721476923077 ], [ 29.751678620689656, -1.509799384615385 ], [ 29.751948103448278, -1.509799384615385 ], [ 29.751948103448278, -1.508721476923077 ], [ 29.751678620689656, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7701, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.508721476923077 ], [ 29.763535862068967, -1.510068861538461 ], [ 29.763805344827588, -1.510068861538461 ], [ 29.763805344827588, -1.508721476923077 ], [ 29.763535862068967, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7702, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.508721476923077 ], [ 29.763805344827588, -1.510338338461538 ], [ 29.764074827586207, -1.510338338461538 ], [ 29.764074827586207, -1.508721476923077 ], [ 29.763805344827588, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7703, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716376379310343, -1.508990953846154 ], [ 29.716376379310343, -1.509529907692308 ], [ 29.717454310344827, -1.509529907692308 ], [ 29.717454310344827, -1.509260430769231 ], [ 29.716645862068965, -1.509260430769231 ], [ 29.716645862068965, -1.508990953846154 ], [ 29.716376379310343, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7704, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.508990953846154 ], [ 29.719879655172413, -1.509529907692308 ], [ 29.720149137931035, -1.509529907692308 ], [ 29.720149137931035, -1.508990953846154 ], [ 29.719879655172413, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7705, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.508990953846154 ], [ 29.720149137931035, -1.509529907692308 ], [ 29.720688103448275, -1.509529907692308 ], [ 29.720688103448275, -1.508990953846154 ], [ 29.720149137931035, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7706, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.508452 ], [ 29.725808275862068, -1.508452 ], [ 29.725808275862068, -1.509529907692308 ], [ 29.72553879310345, -1.509529907692308 ], [ 29.72553879310345, -1.509260430769231 ], [ 29.725269310344828, -1.509260430769231 ], [ 29.725269310344828, -1.508990953846154 ], [ 29.72553879310345, -1.508990953846154 ], [ 29.72553879310345, -1.508452 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7707, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.508990953846154 ], [ 29.727155689655174, -1.509529907692308 ], [ 29.727425172413792, -1.509529907692308 ], [ 29.727425172413792, -1.508990953846154 ], [ 29.727155689655174, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7708, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.508990953846154 ], [ 29.727964137931036, -1.509799384615385 ], [ 29.728233620689654, -1.509799384615385 ], [ 29.728233620689654, -1.508990953846154 ], [ 29.727964137931036, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7709, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.508990953846154 ], [ 29.731467413793105, -1.509260430769231 ], [ 29.731736896551723, -1.509260430769231 ], [ 29.731736896551723, -1.509529907692308 ], [ 29.732006379310345, -1.509529907692308 ], [ 29.732006379310345, -1.508990953846154 ], [ 29.731467413793105, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7710, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.508990953846154 ], [ 29.733084310344829, -1.509260430769231 ], [ 29.733353793103447, -1.509260430769231 ], [ 29.733353793103447, -1.510068861538461 ], [ 29.733623275862069, -1.510068861538461 ], [ 29.733623275862069, -1.509260430769231 ], [ 29.734162241379309, -1.509260430769231 ], [ 29.734162241379309, -1.508990953846154 ], [ 29.733084310344829, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7711, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.508990953846154 ], [ 29.737665517241378, -1.509529907692308 ], [ 29.737935, -1.509529907692308 ], [ 29.737935, -1.508990953846154 ], [ 29.737665517241378, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7712, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.508990953846154 ], [ 29.739551896551724, -1.510338338461538 ], [ 29.739821379310346, -1.510338338461538 ], [ 29.739821379310346, -1.508990953846154 ], [ 29.739551896551724, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7713, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.508721476923077 ], [ 29.742516206896553, -1.508721476923077 ], [ 29.742516206896553, -1.509260430769231 ], [ 29.743594137931034, -1.509260430769231 ], [ 29.743594137931034, -1.509529907692308 ], [ 29.743055172413793, -1.509529907692308 ], [ 29.743055172413793, -1.509799384615385 ], [ 29.742516206896553, -1.509799384615385 ], [ 29.742516206896553, -1.509529907692308 ], [ 29.742246724137932, -1.509529907692308 ], [ 29.742246724137932, -1.509260430769231 ], [ 29.74197724137931, -1.509260430769231 ], [ 29.74197724137931, -1.508990953846154 ], [ 29.742246724137932, -1.508990953846154 ], [ 29.742246724137932, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7714, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.508990953846154 ], [ 29.762727413793105, -1.510068861538461 ], [ 29.762996896551723, -1.510068861538461 ], [ 29.762996896551723, -1.508990953846154 ], [ 29.762727413793105, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7715, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.508990953846154 ], [ 29.762996896551723, -1.510338338461538 ], [ 29.763266379310345, -1.510338338461538 ], [ 29.763266379310345, -1.508990953846154 ], [ 29.762996896551723, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7716, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.508990953846154 ], [ 29.763266379310345, -1.510607815384615 ], [ 29.763535862068967, -1.510607815384615 ], [ 29.763535862068967, -1.508990953846154 ], [ 29.763266379310345, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7717, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.508990953846154 ], [ 29.764344310344828, -1.510068861538461 ], [ 29.76461379310345, -1.510068861538461 ], [ 29.76461379310345, -1.508990953846154 ], [ 29.764344310344828, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7718, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.508990953846154 ], [ 29.76461379310345, -1.510068861538461 ], [ 29.764883275862069, -1.510068861538461 ], [ 29.764883275862069, -1.508990953846154 ], [ 29.76461379310345, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7719, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.508990953846154 ], [ 29.764883275862069, -1.510068861538461 ], [ 29.76515275862069, -1.510068861538461 ], [ 29.76515275862069, -1.508990953846154 ], [ 29.764883275862069, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7720, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.508990953846154 ], [ 29.765422241379312, -1.510068861538461 ], [ 29.765961206896552, -1.510068861538461 ], [ 29.765961206896552, -1.508990953846154 ], [ 29.765422241379312, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7721, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.508990953846154 ], [ 29.765961206896552, -1.510068861538461 ], [ 29.766230689655174, -1.510068861538461 ], [ 29.766230689655174, -1.508990953846154 ], [ 29.765961206896552, -1.508990953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7722, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.509260430769231 ], [ 29.718801724137933, -1.509529907692308 ], [ 29.719610172413795, -1.509529907692308 ], [ 29.719610172413795, -1.509260430769231 ], [ 29.718801724137933, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7723, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.509260430769231 ], [ 29.719610172413795, -1.509529907692308 ], [ 29.719879655172413, -1.509529907692308 ], [ 29.719879655172413, -1.509260430769231 ], [ 29.719610172413795, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7724, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.509260430769231 ], [ 29.720688103448275, -1.509799384615385 ], [ 29.720957586206897, -1.509799384615385 ], [ 29.720957586206897, -1.509260430769231 ], [ 29.720688103448275, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7725, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.509260430769231 ], [ 29.720957586206897, -1.509529907692308 ], [ 29.721227068965518, -1.509529907692308 ], [ 29.721227068965518, -1.509260430769231 ], [ 29.720957586206897, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7726, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.509260430769231 ], [ 29.721227068965518, -1.509529907692308 ], [ 29.721496551724137, -1.509529907692308 ], [ 29.721496551724137, -1.509260430769231 ], [ 29.721227068965518, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7727, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.509260430769231 ], [ 29.721496551724137, -1.509529907692308 ], [ 29.721766034482759, -1.509529907692308 ], [ 29.721766034482759, -1.509260430769231 ], [ 29.721496551724137, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7728, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.509260430769231 ], [ 29.721766034482759, -1.509529907692308 ], [ 29.72203551724138, -1.509529907692308 ], [ 29.72203551724138, -1.509260430769231 ], [ 29.721766034482759, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7729, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.509260430769231 ], [ 29.72203551724138, -1.509529907692308 ], [ 29.722305, -1.509529907692308 ], [ 29.722305, -1.509260430769231 ], [ 29.72203551724138, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7730, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.509260430769231 ], [ 29.722305, -1.509529907692308 ], [ 29.72257448275862, -1.509529907692308 ], [ 29.72257448275862, -1.509260430769231 ], [ 29.722305, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7731, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.509260430769231 ], [ 29.72257448275862, -1.509529907692308 ], [ 29.722843965517242, -1.509529907692308 ], [ 29.722843965517242, -1.509260430769231 ], [ 29.72257448275862, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7732, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.509260430769231 ], [ 29.722843965517242, -1.509529907692308 ], [ 29.723113448275861, -1.509529907692308 ], [ 29.723113448275861, -1.509260430769231 ], [ 29.722843965517242, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7733, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.509260430769231 ], [ 29.723113448275861, -1.509529907692308 ], [ 29.723382931034482, -1.509529907692308 ], [ 29.723382931034482, -1.509260430769231 ], [ 29.723113448275861, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7734, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.509260430769231 ], [ 29.723382931034482, -1.509529907692308 ], [ 29.723652413793104, -1.509529907692308 ], [ 29.723652413793104, -1.509260430769231 ], [ 29.723382931034482, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7735, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.509260430769231 ], [ 29.723652413793104, -1.509529907692308 ], [ 29.723921896551722, -1.509529907692308 ], [ 29.723921896551722, -1.509260430769231 ], [ 29.723652413793104, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7736, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.509260430769231 ], [ 29.723921896551722, -1.509529907692308 ], [ 29.724191379310344, -1.509529907692308 ], [ 29.724191379310344, -1.509260430769231 ], [ 29.723921896551722, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7737, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.509260430769231 ], [ 29.724191379310344, -1.509529907692308 ], [ 29.724460862068966, -1.509529907692308 ], [ 29.724460862068966, -1.509260430769231 ], [ 29.724191379310344, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7738, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.509260430769231 ], [ 29.724460862068966, -1.509529907692308 ], [ 29.724730344827588, -1.509529907692308 ], [ 29.724730344827588, -1.509260430769231 ], [ 29.724460862068966, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7739, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.509260430769231 ], [ 29.724730344827588, -1.509529907692308 ], [ 29.724999827586206, -1.509529907692308 ], [ 29.724999827586206, -1.509260430769231 ], [ 29.724730344827588, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7740, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.509260430769231 ], [ 29.724999827586206, -1.509529907692308 ], [ 29.725269310344828, -1.509529907692308 ], [ 29.725269310344828, -1.509260430769231 ], [ 29.724999827586206, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7741, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.509260430769231 ], [ 29.725269310344828, -1.509529907692308 ], [ 29.72553879310345, -1.509529907692308 ], [ 29.72553879310345, -1.509260430769231 ], [ 29.725269310344828, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7742, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.509260430769231 ], [ 29.726347241379312, -1.509529907692308 ], [ 29.72661672413793, -1.509529907692308 ], [ 29.72661672413793, -1.509260430769231 ], [ 29.726347241379312, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7743, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.509260430769231 ], [ 29.727425172413792, -1.509529907692308 ], [ 29.727694655172414, -1.509529907692308 ], [ 29.727694655172414, -1.509260430769231 ], [ 29.727425172413792, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7744, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.509260430769231 ], [ 29.728233620689654, -1.509799384615385 ], [ 29.728503103448276, -1.509799384615385 ], [ 29.728503103448276, -1.509260430769231 ], [ 29.728233620689654, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7745, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.509260430769231 ], [ 29.728503103448276, -1.509799384615385 ], [ 29.728772586206897, -1.509799384615385 ], [ 29.728772586206897, -1.509260430769231 ], [ 29.728503103448276, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7746, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.509260430769231 ], [ 29.728772586206897, -1.509799384615385 ], [ 29.729042068965516, -1.509799384615385 ], [ 29.729042068965516, -1.509260430769231 ], [ 29.728772586206897, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7747, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.509260430769231 ], [ 29.729042068965516, -1.509799384615385 ], [ 29.729311551724138, -1.509799384615385 ], [ 29.729311551724138, -1.509260430769231 ], [ 29.729042068965516, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7748, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.509260430769231 ], [ 29.729311551724138, -1.509529907692308 ], [ 29.729850517241381, -1.509529907692308 ], [ 29.729850517241381, -1.509260430769231 ], [ 29.729311551724138, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7749, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.509260430769231 ], [ 29.729850517241381, -1.509529907692308 ], [ 29.73012, -1.509529907692308 ], [ 29.73012, -1.509260430769231 ], [ 29.729850517241381, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7750, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.509260430769231 ], [ 29.73012, -1.509529907692308 ], [ 29.730389482758621, -1.509529907692308 ], [ 29.730389482758621, -1.509260430769231 ], [ 29.73012, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7751, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.509260430769231 ], [ 29.730389482758621, -1.509529907692308 ], [ 29.730658965517243, -1.509529907692308 ], [ 29.730658965517243, -1.509260430769231 ], [ 29.730389482758621, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7752, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.509260430769231 ], [ 29.730658965517243, -1.509529907692308 ], [ 29.731197931034483, -1.509529907692308 ], [ 29.731197931034483, -1.509260430769231 ], [ 29.730658965517243, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7753, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.509260430769231 ], [ 29.731197931034483, -1.509529907692308 ], [ 29.731467413793105, -1.509529907692308 ], [ 29.731467413793105, -1.509799384615385 ], [ 29.731736896551723, -1.509799384615385 ], [ 29.731736896551723, -1.509260430769231 ], [ 29.731197931034483, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7754, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.509260430769231 ], [ 29.733623275862069, -1.509799384615385 ], [ 29.733892758620691, -1.509799384615385 ], [ 29.733892758620691, -1.509529907692308 ], [ 29.734431724137931, -1.509529907692308 ], [ 29.734431724137931, -1.509260430769231 ], [ 29.733623275862069, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7755, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.509260430769231 ], [ 29.734431724137931, -1.509529907692308 ], [ 29.734701206896553, -1.509529907692308 ], [ 29.734701206896553, -1.509260430769231 ], [ 29.734431724137931, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7756, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.509260430769231 ], [ 29.734701206896553, -1.509529907692308 ], [ 29.735240172413793, -1.509529907692308 ], [ 29.735240172413793, -1.509260430769231 ], [ 29.734701206896553, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7757, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.509260430769231 ], [ 29.735240172413793, -1.509529907692308 ], [ 29.735509655172415, -1.509529907692308 ], [ 29.735509655172415, -1.509260430769231 ], [ 29.735240172413793, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7758, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.509260430769231 ], [ 29.735509655172415, -1.509529907692308 ], [ 29.736048620689655, -1.509529907692308 ], [ 29.736048620689655, -1.509260430769231 ], [ 29.735509655172415, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7759, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.509260430769231 ], [ 29.737935, -1.509529907692308 ], [ 29.738204482758622, -1.509529907692308 ], [ 29.738204482758622, -1.509260430769231 ], [ 29.737935, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7760, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.509260430769231 ], [ 29.738204482758622, -1.510068861538461 ], [ 29.738743448275862, -1.510068861538461 ], [ 29.738743448275862, -1.509260430769231 ], [ 29.738204482758622, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7761, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.509260430769231 ], [ 29.741707758620691, -1.509529907692308 ], [ 29.74197724137931, -1.509529907692308 ], [ 29.74197724137931, -1.509260430769231 ], [ 29.741707758620691, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7762, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.509260430769231 ], [ 29.74197724137931, -1.509529907692308 ], [ 29.742246724137932, -1.509529907692308 ], [ 29.742246724137932, -1.509260430769231 ], [ 29.74197724137931, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7763, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.509260430769231 ], [ 29.743594137931034, -1.509529907692308 ], [ 29.743863620689655, -1.509529907692308 ], [ 29.743863620689655, -1.509260430769231 ], [ 29.743594137931034, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7764, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.509260430769231 ], [ 29.743863620689655, -1.509529907692308 ], [ 29.745211034482757, -1.509529907692308 ], [ 29.745211034482757, -1.509260430769231 ], [ 29.743863620689655, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7765, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.509260430769231 ], [ 29.752487068965518, -1.509529907692308 ], [ 29.75275655172414, -1.509529907692308 ], [ 29.75275655172414, -1.509260430769231 ], [ 29.752487068965518, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7766, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.509260430769231 ], [ 29.75275655172414, -1.509529907692308 ], [ 29.753026034482758, -1.509529907692308 ], [ 29.753026034482758, -1.509260430769231 ], [ 29.75275655172414, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7767, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.509260430769231 ], [ 29.753026034482758, -1.509529907692308 ], [ 29.75329551724138, -1.509529907692308 ], [ 29.75329551724138, -1.509260430769231 ], [ 29.753026034482758, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7768, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.509260430769231 ], [ 29.75329551724138, -1.509529907692308 ], [ 29.753565, -1.509529907692308 ], [ 29.753565, -1.509260430769231 ], [ 29.75329551724138, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7769, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.509260430769231 ], [ 29.753565, -1.509529907692308 ], [ 29.75383448275862, -1.509529907692308 ], [ 29.75383448275862, -1.509260430769231 ], [ 29.753565, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7770, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.509260430769231 ], [ 29.75383448275862, -1.509529907692308 ], [ 29.754103965517242, -1.509529907692308 ], [ 29.754103965517242, -1.509260430769231 ], [ 29.75383448275862, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7771, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.509260430769231 ], [ 29.754103965517242, -1.509529907692308 ], [ 29.754373448275864, -1.509529907692308 ], [ 29.754373448275864, -1.509260430769231 ], [ 29.754103965517242, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7772, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.509260430769231 ], [ 29.754373448275864, -1.509529907692308 ], [ 29.754642931034482, -1.509529907692308 ], [ 29.754642931034482, -1.509260430769231 ], [ 29.754373448275864, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7773, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.509260430769231 ], [ 29.754642931034482, -1.509529907692308 ], [ 29.754912413793104, -1.509529907692308 ], [ 29.754912413793104, -1.509260430769231 ], [ 29.754642931034482, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7774, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.509260430769231 ], [ 29.754912413793104, -1.509529907692308 ], [ 29.755181896551726, -1.509529907692308 ], [ 29.755181896551726, -1.509260430769231 ], [ 29.754912413793104, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7775, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.509260430769231 ], [ 29.755181896551726, -1.509529907692308 ], [ 29.755451379310344, -1.509529907692308 ], [ 29.755451379310344, -1.509260430769231 ], [ 29.755181896551726, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7776, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.509260430769231 ], [ 29.755451379310344, -1.509529907692308 ], [ 29.755720862068966, -1.509529907692308 ], [ 29.755720862068966, -1.509260430769231 ], [ 29.755451379310344, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7777, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.509260430769231 ], [ 29.755720862068966, -1.509529907692308 ], [ 29.755990344827588, -1.509529907692308 ], [ 29.755990344827588, -1.509260430769231 ], [ 29.755720862068966, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7778, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.509260430769231 ], [ 29.755990344827588, -1.509529907692308 ], [ 29.756259827586206, -1.509529907692308 ], [ 29.756259827586206, -1.509260430769231 ], [ 29.755990344827588, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7779, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.509260430769231 ], [ 29.756259827586206, -1.509529907692308 ], [ 29.756798793103449, -1.509529907692308 ], [ 29.756798793103449, -1.509260430769231 ], [ 29.756259827586206, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7780, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.509260430769231 ], [ 29.756798793103449, -1.509529907692308 ], [ 29.757068275862068, -1.509529907692308 ], [ 29.757068275862068, -1.509260430769231 ], [ 29.756798793103449, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7781, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.509260430769231 ], [ 29.757068275862068, -1.509529907692308 ], [ 29.75733775862069, -1.509529907692308 ], [ 29.75733775862069, -1.509260430769231 ], [ 29.757068275862068, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7782, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.509260430769231 ], [ 29.75733775862069, -1.509529907692308 ], [ 29.757607241379311, -1.509529907692308 ], [ 29.757607241379311, -1.509260430769231 ], [ 29.75733775862069, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7783, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.509260430769231 ], [ 29.757607241379311, -1.509529907692308 ], [ 29.757876724137933, -1.509529907692308 ], [ 29.757876724137933, -1.509260430769231 ], [ 29.757607241379311, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7784, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.509260430769231 ], [ 29.757876724137933, -1.509529907692308 ], [ 29.758146206896551, -1.509529907692308 ], [ 29.758146206896551, -1.509260430769231 ], [ 29.757876724137933, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7785, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.509260430769231 ], [ 29.758146206896551, -1.509529907692308 ], [ 29.758415689655173, -1.509529907692308 ], [ 29.758415689655173, -1.509260430769231 ], [ 29.758146206896551, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7786, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.509260430769231 ], [ 29.758415689655173, -1.509529907692308 ], [ 29.758685172413795, -1.509529907692308 ], [ 29.758685172413795, -1.509260430769231 ], [ 29.758415689655173, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7787, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.509260430769231 ], [ 29.758685172413795, -1.509529907692308 ], [ 29.758954655172413, -1.509529907692308 ], [ 29.758954655172413, -1.509260430769231 ], [ 29.758685172413795, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7788, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.509260430769231 ], [ 29.758954655172413, -1.509529907692308 ], [ 29.759224137931035, -1.509529907692308 ], [ 29.759224137931035, -1.509260430769231 ], [ 29.758954655172413, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7789, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.509260430769231 ], [ 29.759224137931035, -1.509529907692308 ], [ 29.759493620689657, -1.509529907692308 ], [ 29.759493620689657, -1.509260430769231 ], [ 29.759224137931035, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7790, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.509260430769231 ], [ 29.759493620689657, -1.509529907692308 ], [ 29.759763103448275, -1.509529907692308 ], [ 29.759763103448275, -1.509260430769231 ], [ 29.759493620689657, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7791, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.509260430769231 ], [ 29.759763103448275, -1.509529907692308 ], [ 29.760032586206897, -1.509529907692308 ], [ 29.760032586206897, -1.509260430769231 ], [ 29.759763103448275, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7792, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.509260430769231 ], [ 29.760032586206897, -1.509799384615385 ], [ 29.760302068965519, -1.509799384615385 ], [ 29.760302068965519, -1.509260430769231 ], [ 29.760032586206897, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7793, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.509260430769231 ], [ 29.760302068965519, -1.509529907692308 ], [ 29.760571551724137, -1.509529907692308 ], [ 29.760571551724137, -1.509260430769231 ], [ 29.760302068965519, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7794, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.509260430769231 ], [ 29.760571551724137, -1.509529907692308 ], [ 29.760841034482759, -1.509529907692308 ], [ 29.760841034482759, -1.509260430769231 ], [ 29.760571551724137, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7795, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.509260430769231 ], [ 29.760841034482759, -1.509529907692308 ], [ 29.761110517241381, -1.509529907692308 ], [ 29.761110517241381, -1.509260430769231 ], [ 29.760841034482759, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7796, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.509260430769231 ], [ 29.761110517241381, -1.509529907692308 ], [ 29.76138, -1.509529907692308 ], [ 29.76138, -1.509260430769231 ], [ 29.761110517241381, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7797, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.509260430769231 ], [ 29.76138, -1.509529907692308 ], [ 29.761649482758621, -1.509529907692308 ], [ 29.761649482758621, -1.509260430769231 ], [ 29.76138, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7798, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.509260430769231 ], [ 29.761649482758621, -1.509529907692308 ], [ 29.761918965517243, -1.509529907692308 ], [ 29.761918965517243, -1.509260430769231 ], [ 29.761649482758621, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7799, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.509260430769231 ], [ 29.761918965517243, -1.509529907692308 ], [ 29.762188448275861, -1.509529907692308 ], [ 29.762188448275861, -1.509260430769231 ], [ 29.761918965517243, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7800, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.509260430769231 ], [ 29.762188448275861, -1.510338338461538 ], [ 29.762457931034483, -1.510338338461538 ], [ 29.762457931034483, -1.509260430769231 ], [ 29.762188448275861, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7801, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.509260430769231 ], [ 29.762457931034483, -1.510607815384615 ], [ 29.762727413793105, -1.510607815384615 ], [ 29.762727413793105, -1.509260430769231 ], [ 29.762457931034483, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7802, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.509260430769231 ], [ 29.76515275862069, -1.509799384615385 ], [ 29.765422241379312, -1.509799384615385 ], [ 29.765422241379312, -1.509260430769231 ], [ 29.76515275862069, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7803, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.509260430769231 ], [ 29.766230689655174, -1.509799384615385 ], [ 29.766500172413792, -1.509799384615385 ], [ 29.766500172413792, -1.509260430769231 ], [ 29.766230689655174, -1.509260430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7804, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716106896551725, -1.509529907692308 ], [ 29.716106896551725, -1.509799384615385 ], [ 29.716376379310343, -1.509799384615385 ], [ 29.716376379310343, -1.509529907692308 ], [ 29.716106896551725, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7805, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716376379310343, -1.509529907692308 ], [ 29.716376379310343, -1.509799384615385 ], [ 29.716645862068965, -1.509799384615385 ], [ 29.716645862068965, -1.509529907692308 ], [ 29.716376379310343, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7806, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716645862068965, -1.509529907692308 ], [ 29.716645862068965, -1.509799384615385 ], [ 29.717184827586205, -1.509799384615385 ], [ 29.717184827586205, -1.509529907692308 ], [ 29.716645862068965, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7807, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.509529907692308 ], [ 29.717184827586205, -1.509799384615385 ], [ 29.717454310344827, -1.509799384615385 ], [ 29.717454310344827, -1.509529907692308 ], [ 29.717184827586205, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7808, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.509529907692308 ], [ 29.717454310344827, -1.509799384615385 ], [ 29.718801724137933, -1.509799384615385 ], [ 29.718801724137933, -1.509529907692308 ], [ 29.717454310344827, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7809, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.509529907692308 ], [ 29.718801724137933, -1.509799384615385 ], [ 29.719071206896551, -1.509799384615385 ], [ 29.719071206896551, -1.509529907692308 ], [ 29.718801724137933, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7810, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.509529907692308 ], [ 29.719071206896551, -1.509799384615385 ], [ 29.719879655172413, -1.509799384615385 ], [ 29.719879655172413, -1.509529907692308 ], [ 29.719071206896551, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7811, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.509529907692308 ], [ 29.719879655172413, -1.509799384615385 ], [ 29.720149137931035, -1.509799384615385 ], [ 29.720149137931035, -1.509529907692308 ], [ 29.719879655172413, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7812, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.509529907692308 ], [ 29.720149137931035, -1.509799384615385 ], [ 29.720688103448275, -1.509799384615385 ], [ 29.720688103448275, -1.509529907692308 ], [ 29.720149137931035, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7813, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.509529907692308 ], [ 29.720957586206897, -1.509799384615385 ], [ 29.721227068965518, -1.509799384615385 ], [ 29.721227068965518, -1.509529907692308 ], [ 29.720957586206897, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7814, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.509529907692308 ], [ 29.721227068965518, -1.509799384615385 ], [ 29.721496551724137, -1.509799384615385 ], [ 29.721496551724137, -1.509529907692308 ], [ 29.721227068965518, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7815, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.509529907692308 ], [ 29.721496551724137, -1.509799384615385 ], [ 29.721766034482759, -1.509799384615385 ], [ 29.721766034482759, -1.509529907692308 ], [ 29.721496551724137, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7816, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.509529907692308 ], [ 29.721766034482759, -1.509799384615385 ], [ 29.72203551724138, -1.509799384615385 ], [ 29.72203551724138, -1.509529907692308 ], [ 29.721766034482759, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7817, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.509529907692308 ], [ 29.72203551724138, -1.509799384615385 ], [ 29.722305, -1.509799384615385 ], [ 29.722305, -1.509529907692308 ], [ 29.72203551724138, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7818, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.509529907692308 ], [ 29.722305, -1.509799384615385 ], [ 29.72257448275862, -1.509799384615385 ], [ 29.72257448275862, -1.509529907692308 ], [ 29.722305, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7819, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.509529907692308 ], [ 29.72257448275862, -1.509799384615385 ], [ 29.722843965517242, -1.509799384615385 ], [ 29.722843965517242, -1.509529907692308 ], [ 29.72257448275862, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7820, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.509529907692308 ], [ 29.722843965517242, -1.509799384615385 ], [ 29.723113448275861, -1.509799384615385 ], [ 29.723113448275861, -1.509529907692308 ], [ 29.722843965517242, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7821, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.509529907692308 ], [ 29.723113448275861, -1.509799384615385 ], [ 29.723382931034482, -1.509799384615385 ], [ 29.723382931034482, -1.509529907692308 ], [ 29.723113448275861, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7822, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.509529907692308 ], [ 29.723382931034482, -1.509799384615385 ], [ 29.723652413793104, -1.509799384615385 ], [ 29.723652413793104, -1.509529907692308 ], [ 29.723382931034482, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7823, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.509529907692308 ], [ 29.723652413793104, -1.509799384615385 ], [ 29.723921896551722, -1.509799384615385 ], [ 29.723921896551722, -1.509529907692308 ], [ 29.723652413793104, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7824, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.509529907692308 ], [ 29.723921896551722, -1.509799384615385 ], [ 29.724191379310344, -1.509799384615385 ], [ 29.724191379310344, -1.509529907692308 ], [ 29.723921896551722, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7825, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.509529907692308 ], [ 29.724191379310344, -1.509799384615385 ], [ 29.724460862068966, -1.509799384615385 ], [ 29.724460862068966, -1.509529907692308 ], [ 29.724191379310344, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7826, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.509529907692308 ], [ 29.724460862068966, -1.509799384615385 ], [ 29.724730344827588, -1.509799384615385 ], [ 29.724730344827588, -1.509529907692308 ], [ 29.724460862068966, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7827, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.509529907692308 ], [ 29.724730344827588, -1.509799384615385 ], [ 29.724999827586206, -1.509799384615385 ], [ 29.724999827586206, -1.509529907692308 ], [ 29.724730344827588, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7828, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.509529907692308 ], [ 29.724999827586206, -1.509799384615385 ], [ 29.725269310344828, -1.509799384615385 ], [ 29.725269310344828, -1.509529907692308 ], [ 29.724999827586206, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7829, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.509529907692308 ], [ 29.725269310344828, -1.509799384615385 ], [ 29.72553879310345, -1.509799384615385 ], [ 29.72553879310345, -1.509529907692308 ], [ 29.725269310344828, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7830, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.509529907692308 ], [ 29.72553879310345, -1.510068861538461 ], [ 29.725808275862068, -1.510068861538461 ], [ 29.725808275862068, -1.509529907692308 ], [ 29.72553879310345, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7831, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.509529907692308 ], [ 29.725808275862068, -1.510068861538461 ], [ 29.72607775862069, -1.510068861538461 ], [ 29.72607775862069, -1.509529907692308 ], [ 29.725808275862068, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7832, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.509529907692308 ], [ 29.72607775862069, -1.509799384615385 ], [ 29.726347241379312, -1.509799384615385 ], [ 29.726347241379312, -1.509529907692308 ], [ 29.72607775862069, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7833, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.509529907692308 ], [ 29.726347241379312, -1.509799384615385 ], [ 29.72661672413793, -1.509799384615385 ], [ 29.72661672413793, -1.509529907692308 ], [ 29.726347241379312, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7834, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.509529907692308 ], [ 29.726886206896552, -1.510068861538461 ], [ 29.727155689655174, -1.510068861538461 ], [ 29.727155689655174, -1.509529907692308 ], [ 29.726886206896552, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7835, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.509529907692308 ], [ 29.727155689655174, -1.509799384615385 ], [ 29.727425172413792, -1.509799384615385 ], [ 29.727425172413792, -1.509529907692308 ], [ 29.727155689655174, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7836, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.509529907692308 ], [ 29.727425172413792, -1.509799384615385 ], [ 29.727694655172414, -1.509799384615385 ], [ 29.727694655172414, -1.509529907692308 ], [ 29.727425172413792, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7837, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.509529907692308 ], [ 29.729311551724138, -1.509799384615385 ], [ 29.729850517241381, -1.509799384615385 ], [ 29.729850517241381, -1.509529907692308 ], [ 29.729311551724138, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7838, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.509529907692308 ], [ 29.729850517241381, -1.509799384615385 ], [ 29.73012, -1.509799384615385 ], [ 29.73012, -1.509529907692308 ], [ 29.729850517241381, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7839, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.509529907692308 ], [ 29.73012, -1.509799384615385 ], [ 29.730389482758621, -1.509799384615385 ], [ 29.730389482758621, -1.509529907692308 ], [ 29.73012, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7840, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.509529907692308 ], [ 29.730928448275861, -1.509799384615385 ], [ 29.731197931034483, -1.509799384615385 ], [ 29.731197931034483, -1.509529907692308 ], [ 29.730928448275861, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7841, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.509529907692308 ], [ 29.731197931034483, -1.509799384615385 ], [ 29.731467413793105, -1.509799384615385 ], [ 29.731467413793105, -1.509529907692308 ], [ 29.731197931034483, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7842, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.509529907692308 ], [ 29.731736896551723, -1.509799384615385 ], [ 29.732006379310345, -1.509799384615385 ], [ 29.732006379310345, -1.509529907692308 ], [ 29.731736896551723, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7843, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.509529907692308 ], [ 29.732006379310345, -1.509799384615385 ], [ 29.732275862068967, -1.509799384615385 ], [ 29.732275862068967, -1.509529907692308 ], [ 29.732006379310345, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7844, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.509529907692308 ], [ 29.733892758620691, -1.509799384615385 ], [ 29.734162241379309, -1.509799384615385 ], [ 29.734162241379309, -1.510877292307692 ], [ 29.734431724137931, -1.510877292307692 ], [ 29.734431724137931, -1.509529907692308 ], [ 29.733892758620691, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7845, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.509529907692308 ], [ 29.734431724137931, -1.509799384615385 ], [ 29.734701206896553, -1.509799384615385 ], [ 29.734701206896553, -1.509529907692308 ], [ 29.734431724137931, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7846, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.509529907692308 ], [ 29.734701206896553, -1.509799384615385 ], [ 29.735240172413793, -1.509799384615385 ], [ 29.735240172413793, -1.509529907692308 ], [ 29.734701206896553, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7847, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.509529907692308 ], [ 29.735240172413793, -1.509799384615385 ], [ 29.735779137931036, -1.509799384615385 ], [ 29.735779137931036, -1.509529907692308 ], [ 29.735240172413793, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7848, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.509529907692308 ], [ 29.735779137931036, -1.509799384615385 ], [ 29.736048620689655, -1.509799384615385 ], [ 29.736048620689655, -1.509529907692308 ], [ 29.735779137931036, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7849, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.509529907692308 ], [ 29.736048620689655, -1.509799384615385 ], [ 29.736857068965517, -1.509799384615385 ], [ 29.736857068965517, -1.509529907692308 ], [ 29.736048620689655, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7850, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.509529907692308 ], [ 29.737935, -1.509799384615385 ], [ 29.738204482758622, -1.509799384615385 ], [ 29.738204482758622, -1.509529907692308 ], [ 29.737935, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7851, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.509529907692308 ], [ 29.74197724137931, -1.509799384615385 ], [ 29.742246724137932, -1.509799384615385 ], [ 29.742246724137932, -1.509529907692308 ], [ 29.74197724137931, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7852, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.509529907692308 ], [ 29.742246724137932, -1.509799384615385 ], [ 29.742516206896553, -1.509799384615385 ], [ 29.742516206896553, -1.509529907692308 ], [ 29.742246724137932, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7853, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.509529907692308 ], [ 29.743055172413793, -1.509799384615385 ], [ 29.743324655172415, -1.509799384615385 ], [ 29.743324655172415, -1.510068861538461 ], [ 29.743594137931034, -1.510068861538461 ], [ 29.743594137931034, -1.511416246153846 ], [ 29.743863620689655, -1.511416246153846 ], [ 29.743863620689655, -1.511146769230769 ], [ 29.744672068965517, -1.511146769230769 ], [ 29.744672068965517, -1.510877292307692 ], [ 29.743863620689655, -1.510877292307692 ], [ 29.743863620689655, -1.510068861538461 ], [ 29.744941551724139, -1.510068861538461 ], [ 29.744941551724139, -1.509799384615385 ], [ 29.743594137931034, -1.509799384615385 ], [ 29.743594137931034, -1.509529907692308 ], [ 29.743055172413793, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7854, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.509529907692308 ], [ 29.743594137931034, -1.509799384615385 ], [ 29.744941551724139, -1.509799384615385 ], [ 29.744941551724139, -1.509529907692308 ], [ 29.743594137931034, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7855, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.509529907692308 ], [ 29.744941551724139, -1.509799384615385 ], [ 29.745211034482757, -1.509799384615385 ], [ 29.745211034482757, -1.509529907692308 ], [ 29.744941551724139, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7856, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.509529907692308 ], [ 29.751948103448278, -1.509799384615385 ], [ 29.752217586206896, -1.509799384615385 ], [ 29.752217586206896, -1.509529907692308 ], [ 29.751948103448278, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7857, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.509529907692308 ], [ 29.752217586206896, -1.509799384615385 ], [ 29.752487068965518, -1.509799384615385 ], [ 29.752487068965518, -1.509529907692308 ], [ 29.752217586206896, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7858, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.509529907692308 ], [ 29.752487068965518, -1.509799384615385 ], [ 29.75275655172414, -1.509799384615385 ], [ 29.75275655172414, -1.509529907692308 ], [ 29.752487068965518, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7859, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.509529907692308 ], [ 29.75275655172414, -1.509799384615385 ], [ 29.753026034482758, -1.509799384615385 ], [ 29.753026034482758, -1.509529907692308 ], [ 29.75275655172414, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7860, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.509529907692308 ], [ 29.753026034482758, -1.509799384615385 ], [ 29.75329551724138, -1.509799384615385 ], [ 29.75329551724138, -1.509529907692308 ], [ 29.753026034482758, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7861, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.509529907692308 ], [ 29.75329551724138, -1.509799384615385 ], [ 29.753565, -1.509799384615385 ], [ 29.753565, -1.509529907692308 ], [ 29.75329551724138, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7862, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.509529907692308 ], [ 29.753565, -1.509799384615385 ], [ 29.75383448275862, -1.509799384615385 ], [ 29.75383448275862, -1.509529907692308 ], [ 29.753565, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7863, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.509529907692308 ], [ 29.75383448275862, -1.509799384615385 ], [ 29.754103965517242, -1.509799384615385 ], [ 29.754103965517242, -1.509529907692308 ], [ 29.75383448275862, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7864, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.509529907692308 ], [ 29.754103965517242, -1.509799384615385 ], [ 29.754373448275864, -1.509799384615385 ], [ 29.754373448275864, -1.509529907692308 ], [ 29.754103965517242, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7865, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.509529907692308 ], [ 29.754373448275864, -1.509799384615385 ], [ 29.754642931034482, -1.509799384615385 ], [ 29.754642931034482, -1.509529907692308 ], [ 29.754373448275864, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7866, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.509529907692308 ], [ 29.754642931034482, -1.509799384615385 ], [ 29.754912413793104, -1.509799384615385 ], [ 29.754912413793104, -1.509529907692308 ], [ 29.754642931034482, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7867, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.509529907692308 ], [ 29.754912413793104, -1.509799384615385 ], [ 29.755181896551726, -1.509799384615385 ], [ 29.755181896551726, -1.509529907692308 ], [ 29.754912413793104, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7868, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.509529907692308 ], [ 29.755181896551726, -1.509799384615385 ], [ 29.755451379310344, -1.509799384615385 ], [ 29.755451379310344, -1.509529907692308 ], [ 29.755181896551726, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7869, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.509529907692308 ], [ 29.755451379310344, -1.509799384615385 ], [ 29.755720862068966, -1.509799384615385 ], [ 29.755720862068966, -1.509529907692308 ], [ 29.755451379310344, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7870, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.509529907692308 ], [ 29.755720862068966, -1.509799384615385 ], [ 29.755990344827588, -1.509799384615385 ], [ 29.755990344827588, -1.509529907692308 ], [ 29.755720862068966, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7871, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.509529907692308 ], [ 29.755990344827588, -1.509799384615385 ], [ 29.756259827586206, -1.509799384615385 ], [ 29.756259827586206, -1.509529907692308 ], [ 29.755990344827588, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7872, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.509529907692308 ], [ 29.756259827586206, -1.509799384615385 ], [ 29.756798793103449, -1.509799384615385 ], [ 29.756798793103449, -1.509529907692308 ], [ 29.756259827586206, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7873, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.509529907692308 ], [ 29.756798793103449, -1.509799384615385 ], [ 29.757068275862068, -1.509799384615385 ], [ 29.757068275862068, -1.509529907692308 ], [ 29.756798793103449, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7874, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.509529907692308 ], [ 29.757068275862068, -1.509799384615385 ], [ 29.75733775862069, -1.509799384615385 ], [ 29.75733775862069, -1.509529907692308 ], [ 29.757068275862068, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7875, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.509529907692308 ], [ 29.75733775862069, -1.509799384615385 ], [ 29.757607241379311, -1.509799384615385 ], [ 29.757607241379311, -1.509529907692308 ], [ 29.75733775862069, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7876, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.509529907692308 ], [ 29.757607241379311, -1.509799384615385 ], [ 29.757876724137933, -1.509799384615385 ], [ 29.757876724137933, -1.509529907692308 ], [ 29.757607241379311, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7877, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.509529907692308 ], [ 29.757876724137933, -1.509799384615385 ], [ 29.758146206896551, -1.509799384615385 ], [ 29.758146206896551, -1.509529907692308 ], [ 29.757876724137933, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7878, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.509529907692308 ], [ 29.758146206896551, -1.509799384615385 ], [ 29.758415689655173, -1.509799384615385 ], [ 29.758415689655173, -1.509529907692308 ], [ 29.758146206896551, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7879, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.509529907692308 ], [ 29.758415689655173, -1.509799384615385 ], [ 29.758685172413795, -1.509799384615385 ], [ 29.758685172413795, -1.509529907692308 ], [ 29.758415689655173, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7880, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.509529907692308 ], [ 29.758685172413795, -1.509799384615385 ], [ 29.758954655172413, -1.509799384615385 ], [ 29.758954655172413, -1.509529907692308 ], [ 29.758685172413795, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7881, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.509529907692308 ], [ 29.758954655172413, -1.509799384615385 ], [ 29.759224137931035, -1.509799384615385 ], [ 29.759224137931035, -1.509529907692308 ], [ 29.758954655172413, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7882, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.509529907692308 ], [ 29.759224137931035, -1.509799384615385 ], [ 29.759493620689657, -1.509799384615385 ], [ 29.759493620689657, -1.509529907692308 ], [ 29.759224137931035, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7883, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.509529907692308 ], [ 29.759493620689657, -1.509799384615385 ], [ 29.759763103448275, -1.509799384615385 ], [ 29.759763103448275, -1.509529907692308 ], [ 29.759493620689657, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7884, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.509529907692308 ], [ 29.759763103448275, -1.509799384615385 ], [ 29.760032586206897, -1.509799384615385 ], [ 29.760032586206897, -1.509529907692308 ], [ 29.759763103448275, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7885, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.509529907692308 ], [ 29.760302068965519, -1.509799384615385 ], [ 29.760571551724137, -1.509799384615385 ], [ 29.760571551724137, -1.509529907692308 ], [ 29.760302068965519, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7886, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.509529907692308 ], [ 29.760571551724137, -1.509799384615385 ], [ 29.760841034482759, -1.509799384615385 ], [ 29.760841034482759, -1.509529907692308 ], [ 29.760571551724137, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7887, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.509529907692308 ], [ 29.760841034482759, -1.509799384615385 ], [ 29.761110517241381, -1.509799384615385 ], [ 29.761110517241381, -1.509529907692308 ], [ 29.760841034482759, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7888, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.509529907692308 ], [ 29.761110517241381, -1.509799384615385 ], [ 29.76138, -1.509799384615385 ], [ 29.76138, -1.509529907692308 ], [ 29.761110517241381, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7889, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.509529907692308 ], [ 29.76138, -1.509799384615385 ], [ 29.761649482758621, -1.509799384615385 ], [ 29.761649482758621, -1.509529907692308 ], [ 29.76138, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7890, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.509529907692308 ], [ 29.761649482758621, -1.509799384615385 ], [ 29.761918965517243, -1.509799384615385 ], [ 29.761918965517243, -1.509529907692308 ], [ 29.761649482758621, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7891, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.509529907692308 ], [ 29.761918965517243, -1.510338338461538 ], [ 29.762188448275861, -1.510338338461538 ], [ 29.762188448275861, -1.509529907692308 ], [ 29.761918965517243, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7892, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715837413793103, -1.509799384615385 ], [ 29.715837413793103, -1.510338338461538 ], [ 29.716106896551725, -1.510338338461538 ], [ 29.716106896551725, -1.509799384615385 ], [ 29.715837413793103, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7893, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716106896551725, -1.509799384615385 ], [ 29.716106896551725, -1.510068861538461 ], [ 29.716376379310343, -1.510068861538461 ], [ 29.716376379310343, -1.509799384615385 ], [ 29.716106896551725, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7894, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716376379310343, -1.509799384615385 ], [ 29.716376379310343, -1.510068861538461 ], [ 29.716645862068965, -1.510068861538461 ], [ 29.716645862068965, -1.509799384615385 ], [ 29.716376379310343, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7895, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716645862068965, -1.509799384615385 ], [ 29.716645862068965, -1.510068861538461 ], [ 29.717184827586205, -1.510068861538461 ], [ 29.717184827586205, -1.509799384615385 ], [ 29.716645862068965, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7896, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.509799384615385 ], [ 29.717184827586205, -1.510068861538461 ], [ 29.719071206896551, -1.510068861538461 ], [ 29.719071206896551, -1.509799384615385 ], [ 29.717184827586205, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7897, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.509799384615385 ], [ 29.719071206896551, -1.510068861538461 ], [ 29.719879655172413, -1.510068861538461 ], [ 29.719879655172413, -1.509799384615385 ], [ 29.719071206896551, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7898, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.509799384615385 ], [ 29.719879655172413, -1.510068861538461 ], [ 29.720149137931035, -1.510068861538461 ], [ 29.720149137931035, -1.509799384615385 ], [ 29.719879655172413, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7899, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.509799384615385 ], [ 29.720149137931035, -1.510068861538461 ], [ 29.720688103448275, -1.510068861538461 ], [ 29.720688103448275, -1.509799384615385 ], [ 29.720149137931035, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7900, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.509799384615385 ], [ 29.720688103448275, -1.510068861538461 ], [ 29.720957586206897, -1.510068861538461 ], [ 29.720957586206897, -1.509799384615385 ], [ 29.720688103448275, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7901, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.509799384615385 ], [ 29.720957586206897, -1.510068861538461 ], [ 29.721227068965518, -1.510068861538461 ], [ 29.721227068965518, -1.509799384615385 ], [ 29.720957586206897, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7902, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.509799384615385 ], [ 29.721227068965518, -1.510068861538461 ], [ 29.721496551724137, -1.510068861538461 ], [ 29.721496551724137, -1.509799384615385 ], [ 29.721227068965518, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7903, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.509799384615385 ], [ 29.721496551724137, -1.510068861538461 ], [ 29.721766034482759, -1.510068861538461 ], [ 29.721766034482759, -1.509799384615385 ], [ 29.721496551724137, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7904, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.509799384615385 ], [ 29.721766034482759, -1.510068861538461 ], [ 29.72203551724138, -1.510068861538461 ], [ 29.72203551724138, -1.509799384615385 ], [ 29.721766034482759, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7905, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.509799384615385 ], [ 29.72203551724138, -1.510068861538461 ], [ 29.722305, -1.510068861538461 ], [ 29.722305, -1.509799384615385 ], [ 29.72203551724138, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7906, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.509799384615385 ], [ 29.722305, -1.510068861538461 ], [ 29.72257448275862, -1.510068861538461 ], [ 29.72257448275862, -1.509799384615385 ], [ 29.722305, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7907, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.509799384615385 ], [ 29.72257448275862, -1.510068861538461 ], [ 29.722843965517242, -1.510068861538461 ], [ 29.722843965517242, -1.509799384615385 ], [ 29.72257448275862, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7908, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.509799384615385 ], [ 29.722843965517242, -1.510068861538461 ], [ 29.723113448275861, -1.510068861538461 ], [ 29.723113448275861, -1.509799384615385 ], [ 29.722843965517242, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7909, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.509799384615385 ], [ 29.723113448275861, -1.510068861538461 ], [ 29.723382931034482, -1.510068861538461 ], [ 29.723382931034482, -1.509799384615385 ], [ 29.723113448275861, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7910, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.509799384615385 ], [ 29.723382931034482, -1.510068861538461 ], [ 29.723652413793104, -1.510068861538461 ], [ 29.723652413793104, -1.509799384615385 ], [ 29.723382931034482, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7911, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.509799384615385 ], [ 29.723652413793104, -1.510068861538461 ], [ 29.723921896551722, -1.510068861538461 ], [ 29.723921896551722, -1.509799384615385 ], [ 29.723652413793104, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7912, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.509799384615385 ], [ 29.723921896551722, -1.510068861538461 ], [ 29.724191379310344, -1.510068861538461 ], [ 29.724191379310344, -1.509799384615385 ], [ 29.723921896551722, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7913, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.509799384615385 ], [ 29.724191379310344, -1.510068861538461 ], [ 29.724460862068966, -1.510068861538461 ], [ 29.724460862068966, -1.509799384615385 ], [ 29.724191379310344, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7914, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.509799384615385 ], [ 29.724460862068966, -1.510068861538461 ], [ 29.724730344827588, -1.510068861538461 ], [ 29.724730344827588, -1.509799384615385 ], [ 29.724460862068966, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7915, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.509799384615385 ], [ 29.724730344827588, -1.510068861538461 ], [ 29.724999827586206, -1.510068861538461 ], [ 29.724999827586206, -1.509799384615385 ], [ 29.724730344827588, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7916, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.509799384615385 ], [ 29.724999827586206, -1.510068861538461 ], [ 29.725269310344828, -1.510068861538461 ], [ 29.725269310344828, -1.510338338461538 ], [ 29.72553879310345, -1.510338338461538 ], [ 29.72553879310345, -1.509799384615385 ], [ 29.724999827586206, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7917, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.509799384615385 ], [ 29.72607775862069, -1.510338338461538 ], [ 29.726347241379312, -1.510338338461538 ], [ 29.726347241379312, -1.509799384615385 ], [ 29.72607775862069, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7918, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.509799384615385 ], [ 29.726347241379312, -1.510338338461538 ], [ 29.72661672413793, -1.510338338461538 ], [ 29.72661672413793, -1.509799384615385 ], [ 29.726347241379312, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7919, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.509799384615385 ], [ 29.72661672413793, -1.510068861538461 ], [ 29.726886206896552, -1.510068861538461 ], [ 29.726886206896552, -1.509799384615385 ], [ 29.72661672413793, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7920, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.509799384615385 ], [ 29.727155689655174, -1.510338338461538 ], [ 29.727425172413792, -1.510338338461538 ], [ 29.727425172413792, -1.509799384615385 ], [ 29.727155689655174, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7921, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.509799384615385 ], [ 29.727425172413792, -1.510068861538461 ], [ 29.727694655172414, -1.510068861538461 ], [ 29.727694655172414, -1.509799384615385 ], [ 29.727425172413792, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7922, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.509799384615385 ], [ 29.727694655172414, -1.510068861538461 ], [ 29.727964137931036, -1.510068861538461 ], [ 29.727964137931036, -1.509799384615385 ], [ 29.727694655172414, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7923, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.509799384615385 ], [ 29.727964137931036, -1.510068861538461 ], [ 29.728233620689654, -1.510068861538461 ], [ 29.728233620689654, -1.509799384615385 ], [ 29.727964137931036, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7924, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.509799384615385 ], [ 29.728233620689654, -1.510068861538461 ], [ 29.728503103448276, -1.510068861538461 ], [ 29.728503103448276, -1.509799384615385 ], [ 29.728233620689654, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7925, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.509799384615385 ], [ 29.728503103448276, -1.510068861538461 ], [ 29.728772586206897, -1.510068861538461 ], [ 29.728772586206897, -1.509799384615385 ], [ 29.728503103448276, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7926, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.509799384615385 ], [ 29.728772586206897, -1.510068861538461 ], [ 29.729042068965516, -1.510068861538461 ], [ 29.729042068965516, -1.509799384615385 ], [ 29.728772586206897, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7927, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.509799384615385 ], [ 29.729042068965516, -1.510068861538461 ], [ 29.729311551724138, -1.510068861538461 ], [ 29.729311551724138, -1.509799384615385 ], [ 29.729042068965516, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7928, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.509799384615385 ], [ 29.729311551724138, -1.510068861538461 ], [ 29.729850517241381, -1.510068861538461 ], [ 29.729850517241381, -1.509799384615385 ], [ 29.729311551724138, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7929, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.509799384615385 ], [ 29.729850517241381, -1.510068861538461 ], [ 29.73012, -1.510068861538461 ], [ 29.73012, -1.509799384615385 ], [ 29.729850517241381, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7930, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.509529907692308 ], [ 29.730928448275861, -1.509529907692308 ], [ 29.730928448275861, -1.509799384615385 ], [ 29.730658965517243, -1.509799384615385 ], [ 29.730658965517243, -1.510068861538461 ], [ 29.73012, -1.510068861538461 ], [ 29.73012, -1.509799384615385 ], [ 29.730389482758621, -1.509799384615385 ], [ 29.730389482758621, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7931, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.509799384615385 ], [ 29.730658965517243, -1.510068861538461 ], [ 29.730928448275861, -1.510068861538461 ], [ 29.730928448275861, -1.509799384615385 ], [ 29.730658965517243, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7932, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.509799384615385 ], [ 29.730928448275861, -1.510068861538461 ], [ 29.731197931034483, -1.510068861538461 ], [ 29.731197931034483, -1.509799384615385 ], [ 29.730928448275861, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7933, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.509799384615385 ], [ 29.731197931034483, -1.510068861538461 ], [ 29.731467413793105, -1.510068861538461 ], [ 29.731467413793105, -1.509799384615385 ], [ 29.731197931034483, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7934, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.509799384615385 ], [ 29.731467413793105, -1.510068861538461 ], [ 29.731736896551723, -1.510068861538461 ], [ 29.731736896551723, -1.509799384615385 ], [ 29.731467413793105, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7935, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.509799384615385 ], [ 29.731736896551723, -1.510068861538461 ], [ 29.732006379310345, -1.510068861538461 ], [ 29.732006379310345, -1.509799384615385 ], [ 29.731736896551723, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7936, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.509799384615385 ], [ 29.733623275862069, -1.510068861538461 ], [ 29.733892758620691, -1.510068861538461 ], [ 29.733892758620691, -1.509799384615385 ], [ 29.733623275862069, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7937, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.509799384615385 ], [ 29.733892758620691, -1.510877292307692 ], [ 29.734162241379309, -1.510877292307692 ], [ 29.734162241379309, -1.509799384615385 ], [ 29.733892758620691, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7938, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.509799384615385 ], [ 29.734431724137931, -1.510068861538461 ], [ 29.734970689655171, -1.510068861538461 ], [ 29.734970689655171, -1.509799384615385 ], [ 29.734431724137931, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7939, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.509799384615385 ], [ 29.734970689655171, -1.510068861538461 ], [ 29.735240172413793, -1.510068861538461 ], [ 29.735240172413793, -1.509799384615385 ], [ 29.734970689655171, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7940, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.509799384615385 ], [ 29.735240172413793, -1.510068861538461 ], [ 29.735779137931036, -1.510068861538461 ], [ 29.735779137931036, -1.509799384615385 ], [ 29.735240172413793, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7941, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.509799384615385 ], [ 29.735779137931036, -1.510068861538461 ], [ 29.736587586206898, -1.510068861538461 ], [ 29.736587586206898, -1.509799384615385 ], [ 29.735779137931036, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7942, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.509799384615385 ], [ 29.736587586206898, -1.510068861538461 ], [ 29.736857068965517, -1.510068861538461 ], [ 29.736857068965517, -1.509799384615385 ], [ 29.736587586206898, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7943, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.509799384615385 ], [ 29.736857068965517, -1.510068861538461 ], [ 29.737935, -1.510068861538461 ], [ 29.737935, -1.509799384615385 ], [ 29.736857068965517, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7944, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.509799384615385 ], [ 29.737935, -1.510068861538461 ], [ 29.738204482758622, -1.510068861538461 ], [ 29.738204482758622, -1.509799384615385 ], [ 29.737935, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7945, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.509799384615385 ], [ 29.742246724137932, -1.510068861538461 ], [ 29.742785689655172, -1.510068861538461 ], [ 29.742785689655172, -1.509799384615385 ], [ 29.742246724137932, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7946, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.509799384615385 ], [ 29.742785689655172, -1.510068861538461 ], [ 29.743055172413793, -1.510068861538461 ], [ 29.743055172413793, -1.509799384615385 ], [ 29.742785689655172, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7947, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.509799384615385 ], [ 29.743055172413793, -1.510068861538461 ], [ 29.743324655172415, -1.510068861538461 ], [ 29.743324655172415, -1.509799384615385 ], [ 29.743055172413793, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7948, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751948103448278, -1.509799384615385 ], [ 29.751948103448278, -1.510068861538461 ], [ 29.752217586206896, -1.510068861538461 ], [ 29.752217586206896, -1.509799384615385 ], [ 29.751948103448278, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7949, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.509799384615385 ], [ 29.752217586206896, -1.510068861538461 ], [ 29.752487068965518, -1.510068861538461 ], [ 29.752487068965518, -1.509799384615385 ], [ 29.752217586206896, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7950, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.509799384615385 ], [ 29.752487068965518, -1.510068861538461 ], [ 29.75275655172414, -1.510068861538461 ], [ 29.75275655172414, -1.509799384615385 ], [ 29.752487068965518, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7951, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.509799384615385 ], [ 29.75275655172414, -1.510068861538461 ], [ 29.753026034482758, -1.510068861538461 ], [ 29.753026034482758, -1.509799384615385 ], [ 29.75275655172414, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7952, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.509799384615385 ], [ 29.753026034482758, -1.510068861538461 ], [ 29.75329551724138, -1.510068861538461 ], [ 29.75329551724138, -1.509799384615385 ], [ 29.753026034482758, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7953, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.509799384615385 ], [ 29.75329551724138, -1.510068861538461 ], [ 29.75383448275862, -1.510068861538461 ], [ 29.75383448275862, -1.509799384615385 ], [ 29.75329551724138, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7954, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.509799384615385 ], [ 29.75383448275862, -1.510068861538461 ], [ 29.754103965517242, -1.510068861538461 ], [ 29.754103965517242, -1.509799384615385 ], [ 29.75383448275862, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7955, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.509799384615385 ], [ 29.754103965517242, -1.510068861538461 ], [ 29.754373448275864, -1.510068861538461 ], [ 29.754373448275864, -1.509799384615385 ], [ 29.754103965517242, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7956, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.509799384615385 ], [ 29.754373448275864, -1.510068861538461 ], [ 29.754642931034482, -1.510068861538461 ], [ 29.754642931034482, -1.509799384615385 ], [ 29.754373448275864, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7957, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.509799384615385 ], [ 29.754642931034482, -1.510068861538461 ], [ 29.754912413793104, -1.510068861538461 ], [ 29.754912413793104, -1.509799384615385 ], [ 29.754642931034482, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7958, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.509799384615385 ], [ 29.754912413793104, -1.510068861538461 ], [ 29.755181896551726, -1.510068861538461 ], [ 29.755181896551726, -1.509799384615385 ], [ 29.754912413793104, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7959, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.509799384615385 ], [ 29.755181896551726, -1.510068861538461 ], [ 29.755451379310344, -1.510068861538461 ], [ 29.755451379310344, -1.509799384615385 ], [ 29.755181896551726, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7960, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.509799384615385 ], [ 29.755451379310344, -1.510068861538461 ], [ 29.755720862068966, -1.510068861538461 ], [ 29.755720862068966, -1.509799384615385 ], [ 29.755451379310344, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7961, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.509799384615385 ], [ 29.755720862068966, -1.510068861538461 ], [ 29.755990344827588, -1.510068861538461 ], [ 29.755990344827588, -1.509799384615385 ], [ 29.755720862068966, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7962, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.509799384615385 ], [ 29.755990344827588, -1.510068861538461 ], [ 29.756259827586206, -1.510068861538461 ], [ 29.756259827586206, -1.509799384615385 ], [ 29.755990344827588, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7963, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.509799384615385 ], [ 29.756259827586206, -1.510068861538461 ], [ 29.756798793103449, -1.510068861538461 ], [ 29.756798793103449, -1.509799384615385 ], [ 29.756259827586206, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7964, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.509799384615385 ], [ 29.756798793103449, -1.510068861538461 ], [ 29.757068275862068, -1.510068861538461 ], [ 29.757068275862068, -1.509799384615385 ], [ 29.756798793103449, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7965, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.509799384615385 ], [ 29.757068275862068, -1.510068861538461 ], [ 29.75733775862069, -1.510068861538461 ], [ 29.75733775862069, -1.509799384615385 ], [ 29.757068275862068, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7966, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.509799384615385 ], [ 29.75733775862069, -1.510068861538461 ], [ 29.757607241379311, -1.510068861538461 ], [ 29.757607241379311, -1.509799384615385 ], [ 29.75733775862069, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7967, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.509799384615385 ], [ 29.757607241379311, -1.510068861538461 ], [ 29.757876724137933, -1.510068861538461 ], [ 29.757876724137933, -1.509799384615385 ], [ 29.757607241379311, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7968, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.509799384615385 ], [ 29.757876724137933, -1.510068861538461 ], [ 29.758146206896551, -1.510068861538461 ], [ 29.758146206896551, -1.509799384615385 ], [ 29.757876724137933, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7969, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.509799384615385 ], [ 29.758146206896551, -1.510068861538461 ], [ 29.758415689655173, -1.510068861538461 ], [ 29.758415689655173, -1.509799384615385 ], [ 29.758146206896551, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7970, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.509799384615385 ], [ 29.758415689655173, -1.510068861538461 ], [ 29.758685172413795, -1.510068861538461 ], [ 29.758685172413795, -1.509799384615385 ], [ 29.758415689655173, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7971, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.509799384615385 ], [ 29.758685172413795, -1.510068861538461 ], [ 29.758954655172413, -1.510068861538461 ], [ 29.758954655172413, -1.509799384615385 ], [ 29.758685172413795, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7972, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.509799384615385 ], [ 29.758954655172413, -1.510068861538461 ], [ 29.759224137931035, -1.510068861538461 ], [ 29.759224137931035, -1.509799384615385 ], [ 29.758954655172413, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7973, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.509799384615385 ], [ 29.759224137931035, -1.510068861538461 ], [ 29.759493620689657, -1.510068861538461 ], [ 29.759493620689657, -1.509799384615385 ], [ 29.759224137931035, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7974, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.509799384615385 ], [ 29.759493620689657, -1.510068861538461 ], [ 29.759763103448275, -1.510068861538461 ], [ 29.759763103448275, -1.509799384615385 ], [ 29.759493620689657, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7975, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.509799384615385 ], [ 29.759763103448275, -1.510068861538461 ], [ 29.760032586206897, -1.510068861538461 ], [ 29.760032586206897, -1.509799384615385 ], [ 29.759763103448275, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7976, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.509799384615385 ], [ 29.760032586206897, -1.510068861538461 ], [ 29.760302068965519, -1.510068861538461 ], [ 29.760302068965519, -1.509799384615385 ], [ 29.760032586206897, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7977, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.509799384615385 ], [ 29.760302068965519, -1.510068861538461 ], [ 29.760571551724137, -1.510068861538461 ], [ 29.760571551724137, -1.509799384615385 ], [ 29.760302068965519, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7978, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.509799384615385 ], [ 29.760571551724137, -1.510068861538461 ], [ 29.760841034482759, -1.510068861538461 ], [ 29.760841034482759, -1.509799384615385 ], [ 29.760571551724137, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7979, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.509799384615385 ], [ 29.760841034482759, -1.510068861538461 ], [ 29.761110517241381, -1.510068861538461 ], [ 29.761110517241381, -1.509799384615385 ], [ 29.760841034482759, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7980, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.509799384615385 ], [ 29.761110517241381, -1.510338338461538 ], [ 29.76138, -1.510338338461538 ], [ 29.76138, -1.509799384615385 ], [ 29.761110517241381, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7981, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.509799384615385 ], [ 29.76138, -1.510607815384615 ], [ 29.761649482758621, -1.510607815384615 ], [ 29.761649482758621, -1.509799384615385 ], [ 29.76138, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7982, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.509799384615385 ], [ 29.761649482758621, -1.510607815384615 ], [ 29.761918965517243, -1.510607815384615 ], [ 29.761918965517243, -1.509799384615385 ], [ 29.761649482758621, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7983, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.509799384615385 ], [ 29.76515275862069, -1.510338338461538 ], [ 29.765422241379312, -1.510338338461538 ], [ 29.765422241379312, -1.509799384615385 ], [ 29.76515275862069, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7984, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.509799384615385 ], [ 29.766230689655174, -1.510068861538461 ], [ 29.766500172413792, -1.510068861538461 ], [ 29.766500172413792, -1.509799384615385 ], [ 29.766230689655174, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7985, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.509799384615385 ], [ 29.766500172413792, -1.510068861538461 ], [ 29.766769655172414, -1.510068861538461 ], [ 29.766769655172414, -1.509799384615385 ], [ 29.766500172413792, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7986, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716106896551725, -1.510068861538461 ], [ 29.716106896551725, -1.510607815384615 ], [ 29.716376379310343, -1.510607815384615 ], [ 29.716376379310343, -1.510068861538461 ], [ 29.716106896551725, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7987, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716376379310343, -1.510068861538461 ], [ 29.716376379310343, -1.510607815384615 ], [ 29.716645862068965, -1.510607815384615 ], [ 29.716645862068965, -1.510068861538461 ], [ 29.716376379310343, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7988, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716645862068965, -1.510068861538461 ], [ 29.716645862068965, -1.510338338461538 ], [ 29.717184827586205, -1.510338338461538 ], [ 29.717184827586205, -1.510068861538461 ], [ 29.716645862068965, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7989, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.510068861538461 ], [ 29.717184827586205, -1.510338338461538 ], [ 29.719071206896551, -1.510338338461538 ], [ 29.719071206896551, -1.510068861538461 ], [ 29.717184827586205, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7990, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.510068861538461 ], [ 29.719071206896551, -1.510338338461538 ], [ 29.719340689655173, -1.510338338461538 ], [ 29.719340689655173, -1.510068861538461 ], [ 29.719071206896551, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7991, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.510068861538461 ], [ 29.719340689655173, -1.510338338461538 ], [ 29.719610172413795, -1.510338338461538 ], [ 29.719610172413795, -1.510068861538461 ], [ 29.719340689655173, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7992, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.510068861538461 ], [ 29.719610172413795, -1.510338338461538 ], [ 29.720149137931035, -1.510338338461538 ], [ 29.720149137931035, -1.510068861538461 ], [ 29.719610172413795, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7993, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.510068861538461 ], [ 29.720149137931035, -1.510338338461538 ], [ 29.720688103448275, -1.510338338461538 ], [ 29.720688103448275, -1.510068861538461 ], [ 29.720149137931035, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7994, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.510068861538461 ], [ 29.720688103448275, -1.510338338461538 ], [ 29.720957586206897, -1.510338338461538 ], [ 29.720957586206897, -1.510068861538461 ], [ 29.720688103448275, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7995, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.510068861538461 ], [ 29.720957586206897, -1.510338338461538 ], [ 29.721227068965518, -1.510338338461538 ], [ 29.721227068965518, -1.510068861538461 ], [ 29.720957586206897, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7996, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.510068861538461 ], [ 29.721227068965518, -1.510338338461538 ], [ 29.721496551724137, -1.510338338461538 ], [ 29.721496551724137, -1.510068861538461 ], [ 29.721227068965518, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7997, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.510068861538461 ], [ 29.721496551724137, -1.510338338461538 ], [ 29.721766034482759, -1.510338338461538 ], [ 29.721766034482759, -1.510068861538461 ], [ 29.721496551724137, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7998, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.510068861538461 ], [ 29.721766034482759, -1.510338338461538 ], [ 29.72203551724138, -1.510338338461538 ], [ 29.72203551724138, -1.510068861538461 ], [ 29.721766034482759, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7999, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.510068861538461 ], [ 29.72203551724138, -1.510338338461538 ], [ 29.722305, -1.510338338461538 ], [ 29.722305, -1.510068861538461 ], [ 29.72203551724138, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8000, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.510068861538461 ], [ 29.722305, -1.510338338461538 ], [ 29.72257448275862, -1.510338338461538 ], [ 29.72257448275862, -1.510068861538461 ], [ 29.722305, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8001, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.510068861538461 ], [ 29.72257448275862, -1.510338338461538 ], [ 29.722843965517242, -1.510338338461538 ], [ 29.722843965517242, -1.510068861538461 ], [ 29.72257448275862, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8002, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.510068861538461 ], [ 29.722843965517242, -1.510338338461538 ], [ 29.723113448275861, -1.510338338461538 ], [ 29.723113448275861, -1.510068861538461 ], [ 29.722843965517242, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8003, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.510068861538461 ], [ 29.723113448275861, -1.510338338461538 ], [ 29.723382931034482, -1.510338338461538 ], [ 29.723382931034482, -1.510068861538461 ], [ 29.723113448275861, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8004, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.510068861538461 ], [ 29.723382931034482, -1.510338338461538 ], [ 29.723652413793104, -1.510338338461538 ], [ 29.723652413793104, -1.510068861538461 ], [ 29.723382931034482, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8005, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.510068861538461 ], [ 29.723652413793104, -1.510338338461538 ], [ 29.723921896551722, -1.510338338461538 ], [ 29.723921896551722, -1.510068861538461 ], [ 29.723652413793104, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8006, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.510068861538461 ], [ 29.723921896551722, -1.510338338461538 ], [ 29.724191379310344, -1.510338338461538 ], [ 29.724191379310344, -1.510068861538461 ], [ 29.723921896551722, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8007, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.510068861538461 ], [ 29.724191379310344, -1.510338338461538 ], [ 29.724460862068966, -1.510338338461538 ], [ 29.724460862068966, -1.510068861538461 ], [ 29.724191379310344, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8008, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.510068861538461 ], [ 29.724460862068966, -1.510338338461538 ], [ 29.724730344827588, -1.510338338461538 ], [ 29.724730344827588, -1.510068861538461 ], [ 29.724460862068966, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8009, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.510068861538461 ], [ 29.724730344827588, -1.510338338461538 ], [ 29.724999827586206, -1.510338338461538 ], [ 29.724999827586206, -1.510068861538461 ], [ 29.724730344827588, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8010, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.510068861538461 ], [ 29.724999827586206, -1.510607815384615 ], [ 29.725269310344828, -1.510607815384615 ], [ 29.725269310344828, -1.510068861538461 ], [ 29.724999827586206, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8011, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.510068861538461 ], [ 29.72553879310345, -1.511416246153846 ], [ 29.725808275862068, -1.511416246153846 ], [ 29.725808275862068, -1.510068861538461 ], [ 29.72553879310345, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8012, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.510068861538461 ], [ 29.725808275862068, -1.510877292307692 ], [ 29.72607775862069, -1.510877292307692 ], [ 29.72607775862069, -1.510068861538461 ], [ 29.725808275862068, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8013, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.510068861538461 ], [ 29.726886206896552, -1.510607815384615 ], [ 29.727155689655174, -1.510607815384615 ], [ 29.727155689655174, -1.510068861538461 ], [ 29.726886206896552, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8014, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.510068861538461 ], [ 29.727694655172414, -1.510607815384615 ], [ 29.727964137931036, -1.510607815384615 ], [ 29.727964137931036, -1.510068861538461 ], [ 29.727694655172414, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8015, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.510068861538461 ], [ 29.727964137931036, -1.510338338461538 ], [ 29.728233620689654, -1.510338338461538 ], [ 29.728233620689654, -1.510068861538461 ], [ 29.727964137931036, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8016, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.510068861538461 ], [ 29.728233620689654, -1.510338338461538 ], [ 29.728503103448276, -1.510338338461538 ], [ 29.728503103448276, -1.510068861538461 ], [ 29.728233620689654, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8017, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.510068861538461 ], [ 29.728503103448276, -1.510338338461538 ], [ 29.728772586206897, -1.510338338461538 ], [ 29.728772586206897, -1.510068861538461 ], [ 29.728503103448276, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8018, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.510068861538461 ], [ 29.728772586206897, -1.510338338461538 ], [ 29.729042068965516, -1.510338338461538 ], [ 29.729042068965516, -1.510068861538461 ], [ 29.728772586206897, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8019, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.510068861538461 ], [ 29.729042068965516, -1.510338338461538 ], [ 29.729311551724138, -1.510338338461538 ], [ 29.729311551724138, -1.510068861538461 ], [ 29.729042068965516, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8020, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.510068861538461 ], [ 29.729311551724138, -1.510338338461538 ], [ 29.729850517241381, -1.510338338461538 ], [ 29.729850517241381, -1.510068861538461 ], [ 29.729311551724138, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8021, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.510068861538461 ], [ 29.729850517241381, -1.510338338461538 ], [ 29.73012, -1.510338338461538 ], [ 29.73012, -1.510068861538461 ], [ 29.729850517241381, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8022, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.510068861538461 ], [ 29.73012, -1.510338338461538 ], [ 29.730389482758621, -1.510338338461538 ], [ 29.730389482758621, -1.510068861538461 ], [ 29.73012, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8023, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.510068861538461 ], [ 29.730389482758621, -1.510338338461538 ], [ 29.730928448275861, -1.510338338461538 ], [ 29.730928448275861, -1.510068861538461 ], [ 29.730389482758621, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8024, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.510068861538461 ], [ 29.730928448275861, -1.510338338461538 ], [ 29.731197931034483, -1.510338338461538 ], [ 29.731197931034483, -1.510068861538461 ], [ 29.730928448275861, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8025, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.510068861538461 ], [ 29.731197931034483, -1.510338338461538 ], [ 29.731467413793105, -1.510338338461538 ], [ 29.731467413793105, -1.510068861538461 ], [ 29.731197931034483, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8026, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.510068861538461 ], [ 29.731467413793105, -1.510338338461538 ], [ 29.731736896551723, -1.510338338461538 ], [ 29.731736896551723, -1.510068861538461 ], [ 29.731467413793105, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8027, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.510068861538461 ], [ 29.733623275862069, -1.510607815384615 ], [ 29.733892758620691, -1.510607815384615 ], [ 29.733892758620691, -1.510068861538461 ], [ 29.733623275862069, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8028, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.510068861538461 ], [ 29.734431724137931, -1.510607815384615 ], [ 29.734701206896553, -1.510607815384615 ], [ 29.734701206896553, -1.510068861538461 ], [ 29.734431724137931, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8029, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.510068861538461 ], [ 29.734701206896553, -1.510877292307692 ], [ 29.734970689655171, -1.510877292307692 ], [ 29.734970689655171, -1.510068861538461 ], [ 29.734701206896553, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8030, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.510068861538461 ], [ 29.734970689655171, -1.510338338461538 ], [ 29.735509655172415, -1.510338338461538 ], [ 29.735509655172415, -1.510068861538461 ], [ 29.734970689655171, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8031, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.510068861538461 ], [ 29.735509655172415, -1.510338338461538 ], [ 29.735779137931036, -1.510338338461538 ], [ 29.735779137931036, -1.510068861538461 ], [ 29.735509655172415, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8032, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.510068861538461 ], [ 29.735779137931036, -1.510338338461538 ], [ 29.736587586206898, -1.510338338461538 ], [ 29.736587586206898, -1.510068861538461 ], [ 29.735779137931036, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8033, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.510068861538461 ], [ 29.736587586206898, -1.510338338461538 ], [ 29.738204482758622, -1.510338338461538 ], [ 29.738204482758622, -1.510068861538461 ], [ 29.736587586206898, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8034, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.510068861538461 ], [ 29.739282413793102, -1.510338338461538 ], [ 29.739551896551724, -1.510338338461538 ], [ 29.739551896551724, -1.510068861538461 ], [ 29.739282413793102, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8035, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.510068861538461 ], [ 29.742785689655172, -1.510338338461538 ], [ 29.743055172413793, -1.510338338461538 ], [ 29.743055172413793, -1.510068861538461 ], [ 29.742785689655172, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8036, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.510068861538461 ], [ 29.743055172413793, -1.510338338461538 ], [ 29.743324655172415, -1.510338338461538 ], [ 29.743324655172415, -1.510068861538461 ], [ 29.743055172413793, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8037, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.510068861538461 ], [ 29.743863620689655, -1.510877292307692 ], [ 29.744402586206895, -1.510877292307692 ], [ 29.744402586206895, -1.510607815384615 ], [ 29.744133103448277, -1.510607815384615 ], [ 29.744133103448277, -1.510338338461538 ], [ 29.744672068965517, -1.510338338461538 ], [ 29.744672068965517, -1.510068861538461 ], [ 29.743863620689655, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8038, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.510068861538461 ], [ 29.744672068965517, -1.510338338461538 ], [ 29.744941551724139, -1.510338338461538 ], [ 29.744941551724139, -1.510068861538461 ], [ 29.744672068965517, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8039, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752217586206896, -1.510068861538461 ], [ 29.752217586206896, -1.510338338461538 ], [ 29.752487068965518, -1.510338338461538 ], [ 29.752487068965518, -1.510068861538461 ], [ 29.752217586206896, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8040, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.510068861538461 ], [ 29.752487068965518, -1.510338338461538 ], [ 29.75275655172414, -1.510338338461538 ], [ 29.75275655172414, -1.510068861538461 ], [ 29.752487068965518, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8041, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.510068861538461 ], [ 29.75275655172414, -1.510338338461538 ], [ 29.753026034482758, -1.510338338461538 ], [ 29.753026034482758, -1.510068861538461 ], [ 29.75275655172414, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8042, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.510068861538461 ], [ 29.753026034482758, -1.510338338461538 ], [ 29.753565, -1.510338338461538 ], [ 29.753565, -1.510068861538461 ], [ 29.753026034482758, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8043, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.510068861538461 ], [ 29.753565, -1.510338338461538 ], [ 29.75383448275862, -1.510338338461538 ], [ 29.75383448275862, -1.510068861538461 ], [ 29.753565, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8044, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.510068861538461 ], [ 29.75383448275862, -1.510338338461538 ], [ 29.754373448275864, -1.510338338461538 ], [ 29.754373448275864, -1.510068861538461 ], [ 29.75383448275862, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8045, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.510068861538461 ], [ 29.754373448275864, -1.510338338461538 ], [ 29.754642931034482, -1.510338338461538 ], [ 29.754642931034482, -1.510068861538461 ], [ 29.754373448275864, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8046, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.510068861538461 ], [ 29.754642931034482, -1.510338338461538 ], [ 29.754912413793104, -1.510338338461538 ], [ 29.754912413793104, -1.510068861538461 ], [ 29.754642931034482, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8047, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.510068861538461 ], [ 29.754912413793104, -1.510338338461538 ], [ 29.755181896551726, -1.510338338461538 ], [ 29.755181896551726, -1.510068861538461 ], [ 29.754912413793104, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8048, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.510068861538461 ], [ 29.755181896551726, -1.510338338461538 ], [ 29.755451379310344, -1.510338338461538 ], [ 29.755451379310344, -1.510068861538461 ], [ 29.755181896551726, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8049, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.510068861538461 ], [ 29.755451379310344, -1.510338338461538 ], [ 29.755720862068966, -1.510338338461538 ], [ 29.755720862068966, -1.510068861538461 ], [ 29.755451379310344, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8050, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.510068861538461 ], [ 29.755720862068966, -1.510338338461538 ], [ 29.755990344827588, -1.510338338461538 ], [ 29.755990344827588, -1.510068861538461 ], [ 29.755720862068966, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8051, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.510068861538461 ], [ 29.755990344827588, -1.510338338461538 ], [ 29.756259827586206, -1.510338338461538 ], [ 29.756259827586206, -1.510068861538461 ], [ 29.755990344827588, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8052, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.510068861538461 ], [ 29.756259827586206, -1.510338338461538 ], [ 29.756798793103449, -1.510338338461538 ], [ 29.756798793103449, -1.510068861538461 ], [ 29.756259827586206, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8053, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.510068861538461 ], [ 29.756798793103449, -1.510338338461538 ], [ 29.757068275862068, -1.510338338461538 ], [ 29.757068275862068, -1.510068861538461 ], [ 29.756798793103449, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8054, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.510068861538461 ], [ 29.757068275862068, -1.510338338461538 ], [ 29.75733775862069, -1.510338338461538 ], [ 29.75733775862069, -1.510068861538461 ], [ 29.757068275862068, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8055, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.510068861538461 ], [ 29.75733775862069, -1.510338338461538 ], [ 29.757607241379311, -1.510338338461538 ], [ 29.757607241379311, -1.510068861538461 ], [ 29.75733775862069, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8056, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.510068861538461 ], [ 29.757607241379311, -1.510338338461538 ], [ 29.757876724137933, -1.510338338461538 ], [ 29.757876724137933, -1.510068861538461 ], [ 29.757607241379311, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8057, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.510068861538461 ], [ 29.757876724137933, -1.510338338461538 ], [ 29.758146206896551, -1.510338338461538 ], [ 29.758146206896551, -1.510068861538461 ], [ 29.757876724137933, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8058, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.510068861538461 ], [ 29.758146206896551, -1.510338338461538 ], [ 29.758415689655173, -1.510338338461538 ], [ 29.758415689655173, -1.510068861538461 ], [ 29.758146206896551, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8059, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.510068861538461 ], [ 29.758415689655173, -1.510338338461538 ], [ 29.758685172413795, -1.510338338461538 ], [ 29.758685172413795, -1.510068861538461 ], [ 29.758415689655173, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8060, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.510068861538461 ], [ 29.758685172413795, -1.510338338461538 ], [ 29.758954655172413, -1.510338338461538 ], [ 29.758954655172413, -1.510068861538461 ], [ 29.758685172413795, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8061, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.510068861538461 ], [ 29.758954655172413, -1.510607815384615 ], [ 29.759224137931035, -1.510607815384615 ], [ 29.759224137931035, -1.510068861538461 ], [ 29.758954655172413, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8062, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.510068861538461 ], [ 29.759224137931035, -1.510338338461538 ], [ 29.759493620689657, -1.510338338461538 ], [ 29.759493620689657, -1.510068861538461 ], [ 29.759224137931035, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8063, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.510068861538461 ], [ 29.759493620689657, -1.510338338461538 ], [ 29.759763103448275, -1.510338338461538 ], [ 29.759763103448275, -1.510068861538461 ], [ 29.759493620689657, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8064, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.510068861538461 ], [ 29.759763103448275, -1.510338338461538 ], [ 29.760032586206897, -1.510338338461538 ], [ 29.760032586206897, -1.510068861538461 ], [ 29.759763103448275, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8065, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.510068861538461 ], [ 29.760032586206897, -1.510338338461538 ], [ 29.760302068965519, -1.510338338461538 ], [ 29.760302068965519, -1.510068861538461 ], [ 29.760032586206897, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8066, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.510068861538461 ], [ 29.760302068965519, -1.510338338461538 ], [ 29.760571551724137, -1.510338338461538 ], [ 29.760571551724137, -1.510607815384615 ], [ 29.760841034482759, -1.510607815384615 ], [ 29.760841034482759, -1.510068861538461 ], [ 29.760302068965519, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8067, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.510068861538461 ], [ 29.760841034482759, -1.510877292307692 ], [ 29.761110517241381, -1.510877292307692 ], [ 29.761110517241381, -1.510068861538461 ], [ 29.760841034482759, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8068, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.510068861538461 ], [ 29.762727413793105, -1.510607815384615 ], [ 29.762996896551723, -1.510607815384615 ], [ 29.762996896551723, -1.510068861538461 ], [ 29.762727413793105, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8069, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.510068861538461 ], [ 29.763535862068967, -1.510607815384615 ], [ 29.763805344827588, -1.510607815384615 ], [ 29.763805344827588, -1.510068861538461 ], [ 29.763535862068967, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8070, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.510068861538461 ], [ 29.764344310344828, -1.510338338461538 ], [ 29.76461379310345, -1.510338338461538 ], [ 29.76461379310345, -1.510068861538461 ], [ 29.764344310344828, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8071, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.510068861538461 ], [ 29.76461379310345, -1.510338338461538 ], [ 29.764883275862069, -1.510338338461538 ], [ 29.764883275862069, -1.510068861538461 ], [ 29.76461379310345, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8072, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.510068861538461 ], [ 29.764883275862069, -1.510338338461538 ], [ 29.76515275862069, -1.510338338461538 ], [ 29.76515275862069, -1.510068861538461 ], [ 29.764883275862069, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8073, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.510068861538461 ], [ 29.765422241379312, -1.510338338461538 ], [ 29.765961206896552, -1.510338338461538 ], [ 29.765961206896552, -1.510068861538461 ], [ 29.765422241379312, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8074, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.510068861538461 ], [ 29.765961206896552, -1.510338338461538 ], [ 29.766230689655174, -1.510338338461538 ], [ 29.766230689655174, -1.510068861538461 ], [ 29.765961206896552, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8075, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.510068861538461 ], [ 29.766230689655174, -1.510338338461538 ], [ 29.766500172413792, -1.510338338461538 ], [ 29.766500172413792, -1.510068861538461 ], [ 29.766230689655174, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8076, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.510068861538461 ], [ 29.766500172413792, -1.510338338461538 ], [ 29.766769655172414, -1.510338338461538 ], [ 29.766769655172414, -1.510068861538461 ], [ 29.766500172413792, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8077, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.510068861538461 ], [ 29.766769655172414, -1.510607815384615 ], [ 29.767039137931036, -1.510607815384615 ], [ 29.767039137931036, -1.510068861538461 ], [ 29.766769655172414, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8078, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715567931034482, -1.510338338461538 ], [ 29.715567931034482, -1.510607815384615 ], [ 29.715837413793103, -1.510607815384615 ], [ 29.715837413793103, -1.510338338461538 ], [ 29.715567931034482, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8079, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715837413793103, -1.510338338461538 ], [ 29.715837413793103, -1.510607815384615 ], [ 29.716106896551725, -1.510607815384615 ], [ 29.716106896551725, -1.510338338461538 ], [ 29.715837413793103, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8080, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716645862068965, -1.510338338461538 ], [ 29.716645862068965, -1.510877292307692 ], [ 29.716915344827587, -1.510877292307692 ], [ 29.716915344827587, -1.510607815384615 ], [ 29.717454310344827, -1.510607815384615 ], [ 29.717454310344827, -1.510338338461538 ], [ 29.716645862068965, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8081, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.510338338461538 ], [ 29.717454310344827, -1.510607815384615 ], [ 29.718801724137933, -1.510607815384615 ], [ 29.718801724137933, -1.510338338461538 ], [ 29.717454310344827, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8082, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.510338338461538 ], [ 29.718801724137933, -1.510607815384615 ], [ 29.719340689655173, -1.510607815384615 ], [ 29.719340689655173, -1.510338338461538 ], [ 29.718801724137933, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8083, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.510338338461538 ], [ 29.719340689655173, -1.510607815384615 ], [ 29.719879655172413, -1.510607815384615 ], [ 29.719879655172413, -1.510338338461538 ], [ 29.719340689655173, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8084, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.510338338461538 ], [ 29.719879655172413, -1.510607815384615 ], [ 29.720149137931035, -1.510607815384615 ], [ 29.720149137931035, -1.510338338461538 ], [ 29.719879655172413, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8085, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.510338338461538 ], [ 29.720149137931035, -1.510607815384615 ], [ 29.720688103448275, -1.510607815384615 ], [ 29.720688103448275, -1.510338338461538 ], [ 29.720149137931035, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8086, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.510338338461538 ], [ 29.720688103448275, -1.510607815384615 ], [ 29.720957586206897, -1.510607815384615 ], [ 29.720957586206897, -1.510338338461538 ], [ 29.720688103448275, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8087, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.510338338461538 ], [ 29.720957586206897, -1.510607815384615 ], [ 29.721227068965518, -1.510607815384615 ], [ 29.721227068965518, -1.510338338461538 ], [ 29.720957586206897, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8088, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.510338338461538 ], [ 29.721227068965518, -1.510607815384615 ], [ 29.721496551724137, -1.510607815384615 ], [ 29.721496551724137, -1.510338338461538 ], [ 29.721227068965518, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8089, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.510338338461538 ], [ 29.721496551724137, -1.510607815384615 ], [ 29.721766034482759, -1.510607815384615 ], [ 29.721766034482759, -1.510338338461538 ], [ 29.721496551724137, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8090, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.510338338461538 ], [ 29.721766034482759, -1.510607815384615 ], [ 29.72203551724138, -1.510607815384615 ], [ 29.72203551724138, -1.510338338461538 ], [ 29.721766034482759, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8091, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.510338338461538 ], [ 29.72203551724138, -1.510607815384615 ], [ 29.722305, -1.510607815384615 ], [ 29.722305, -1.510338338461538 ], [ 29.72203551724138, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8092, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.510338338461538 ], [ 29.722305, -1.510607815384615 ], [ 29.72257448275862, -1.510607815384615 ], [ 29.72257448275862, -1.510338338461538 ], [ 29.722305, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8093, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.510338338461538 ], [ 29.72257448275862, -1.510607815384615 ], [ 29.722843965517242, -1.510607815384615 ], [ 29.722843965517242, -1.510338338461538 ], [ 29.72257448275862, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8094, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.510338338461538 ], [ 29.722843965517242, -1.510607815384615 ], [ 29.723113448275861, -1.510607815384615 ], [ 29.723113448275861, -1.510338338461538 ], [ 29.722843965517242, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8095, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.510338338461538 ], [ 29.723113448275861, -1.510607815384615 ], [ 29.723382931034482, -1.510607815384615 ], [ 29.723382931034482, -1.510338338461538 ], [ 29.723113448275861, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8096, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.510338338461538 ], [ 29.723382931034482, -1.510607815384615 ], [ 29.723652413793104, -1.510607815384615 ], [ 29.723652413793104, -1.510338338461538 ], [ 29.723382931034482, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8097, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.510338338461538 ], [ 29.723652413793104, -1.510607815384615 ], [ 29.723921896551722, -1.510607815384615 ], [ 29.723921896551722, -1.510338338461538 ], [ 29.723652413793104, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8098, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.510338338461538 ], [ 29.723921896551722, -1.510607815384615 ], [ 29.724191379310344, -1.510607815384615 ], [ 29.724191379310344, -1.510338338461538 ], [ 29.723921896551722, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8099, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.510338338461538 ], [ 29.724191379310344, -1.510877292307692 ], [ 29.724460862068966, -1.510877292307692 ], [ 29.724460862068966, -1.510338338461538 ], [ 29.724191379310344, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8100, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.510338338461538 ], [ 29.724460862068966, -1.510607815384615 ], [ 29.724730344827588, -1.510607815384615 ], [ 29.724730344827588, -1.510338338461538 ], [ 29.724460862068966, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8101, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.510338338461538 ], [ 29.724730344827588, -1.510607815384615 ], [ 29.724999827586206, -1.510607815384615 ], [ 29.724999827586206, -1.510338338461538 ], [ 29.724730344827588, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8102, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.510338338461538 ], [ 29.728503103448276, -1.510607815384615 ], [ 29.728772586206897, -1.510607815384615 ], [ 29.728772586206897, -1.510338338461538 ], [ 29.728503103448276, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8103, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.510338338461538 ], [ 29.728772586206897, -1.510607815384615 ], [ 29.729042068965516, -1.510607815384615 ], [ 29.729042068965516, -1.510338338461538 ], [ 29.728772586206897, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8104, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.510338338461538 ], [ 29.729042068965516, -1.510607815384615 ], [ 29.729311551724138, -1.510607815384615 ], [ 29.729311551724138, -1.510338338461538 ], [ 29.729042068965516, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8105, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.510338338461538 ], [ 29.729311551724138, -1.510877292307692 ], [ 29.729850517241381, -1.510877292307692 ], [ 29.729850517241381, -1.510338338461538 ], [ 29.729311551724138, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8106, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.510338338461538 ], [ 29.729850517241381, -1.510607815384615 ], [ 29.73012, -1.510607815384615 ], [ 29.73012, -1.510338338461538 ], [ 29.729850517241381, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8107, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.510338338461538 ], [ 29.73012, -1.510607815384615 ], [ 29.730389482758621, -1.510607815384615 ], [ 29.730389482758621, -1.510338338461538 ], [ 29.73012, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8108, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.510338338461538 ], [ 29.730389482758621, -1.510607815384615 ], [ 29.730928448275861, -1.510607815384615 ], [ 29.730928448275861, -1.510338338461538 ], [ 29.730389482758621, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8109, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.510338338461538 ], [ 29.730928448275861, -1.510607815384615 ], [ 29.731197931034483, -1.510607815384615 ], [ 29.731197931034483, -1.510338338461538 ], [ 29.730928448275861, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8110, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.510338338461538 ], [ 29.731197931034483, -1.510607815384615 ], [ 29.731467413793105, -1.510607815384615 ], [ 29.731467413793105, -1.510338338461538 ], [ 29.731197931034483, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8111, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.510338338461538 ], [ 29.734970689655171, -1.510607815384615 ], [ 29.735240172413793, -1.510607815384615 ], [ 29.735240172413793, -1.510338338461538 ], [ 29.734970689655171, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8112, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.510338338461538 ], [ 29.735240172413793, -1.510607815384615 ], [ 29.735509655172415, -1.510607815384615 ], [ 29.735509655172415, -1.510338338461538 ], [ 29.735240172413793, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8113, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.510338338461538 ], [ 29.735509655172415, -1.510607815384615 ], [ 29.736048620689655, -1.510607815384615 ], [ 29.736048620689655, -1.510338338461538 ], [ 29.735509655172415, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8114, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.510338338461538 ], [ 29.736048620689655, -1.510607815384615 ], [ 29.736318103448276, -1.510607815384615 ], [ 29.736318103448276, -1.510338338461538 ], [ 29.736048620689655, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8115, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.510338338461538 ], [ 29.736318103448276, -1.510607815384615 ], [ 29.736587586206898, -1.510607815384615 ], [ 29.736587586206898, -1.510338338461538 ], [ 29.736318103448276, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8116, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.510338338461538 ], [ 29.736587586206898, -1.510607815384615 ], [ 29.738204482758622, -1.510607815384615 ], [ 29.738204482758622, -1.510338338461538 ], [ 29.736587586206898, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8117, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.510338338461538 ], [ 29.738204482758622, -1.510607815384615 ], [ 29.738743448275862, -1.510607815384615 ], [ 29.738743448275862, -1.510338338461538 ], [ 29.738204482758622, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8118, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.510338338461538 ], [ 29.739282413793102, -1.511416246153846 ], [ 29.739551896551724, -1.511416246153846 ], [ 29.739551896551724, -1.510338338461538 ], [ 29.739282413793102, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8119, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.510338338461538 ], [ 29.744133103448277, -1.510607815384615 ], [ 29.744402586206895, -1.510607815384615 ], [ 29.744402586206895, -1.510338338461538 ], [ 29.744133103448277, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8120, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.510338338461538 ], [ 29.744402586206895, -1.510607815384615 ], [ 29.744941551724139, -1.510607815384615 ], [ 29.744941551724139, -1.510338338461538 ], [ 29.744402586206895, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8121, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.510338338461538 ], [ 29.744941551724139, -1.510607815384615 ], [ 29.745211034482757, -1.510607815384615 ], [ 29.745211034482757, -1.510338338461538 ], [ 29.744941551724139, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8122, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752487068965518, -1.510338338461538 ], [ 29.752487068965518, -1.510607815384615 ], [ 29.75275655172414, -1.510607815384615 ], [ 29.75275655172414, -1.510338338461538 ], [ 29.752487068965518, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8123, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.510338338461538 ], [ 29.75275655172414, -1.510607815384615 ], [ 29.753026034482758, -1.510607815384615 ], [ 29.753026034482758, -1.510338338461538 ], [ 29.75275655172414, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8124, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.510338338461538 ], [ 29.753026034482758, -1.510607815384615 ], [ 29.753565, -1.510607815384615 ], [ 29.753565, -1.510338338461538 ], [ 29.753026034482758, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8125, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.510338338461538 ], [ 29.753565, -1.510607815384615 ], [ 29.754103965517242, -1.510607815384615 ], [ 29.754103965517242, -1.510338338461538 ], [ 29.753565, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8126, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.510338338461538 ], [ 29.754103965517242, -1.510607815384615 ], [ 29.754373448275864, -1.510607815384615 ], [ 29.754373448275864, -1.510338338461538 ], [ 29.754103965517242, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8127, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.510338338461538 ], [ 29.754373448275864, -1.510607815384615 ], [ 29.754642931034482, -1.510607815384615 ], [ 29.754642931034482, -1.510338338461538 ], [ 29.754373448275864, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8128, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.510338338461538 ], [ 29.754642931034482, -1.510607815384615 ], [ 29.754912413793104, -1.510607815384615 ], [ 29.754912413793104, -1.510338338461538 ], [ 29.754642931034482, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8129, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.510338338461538 ], [ 29.754912413793104, -1.510607815384615 ], [ 29.755181896551726, -1.510607815384615 ], [ 29.755181896551726, -1.510338338461538 ], [ 29.754912413793104, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8130, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.510338338461538 ], [ 29.755181896551726, -1.510607815384615 ], [ 29.755451379310344, -1.510607815384615 ], [ 29.755451379310344, -1.510338338461538 ], [ 29.755181896551726, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8131, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.510338338461538 ], [ 29.755451379310344, -1.510607815384615 ], [ 29.755720862068966, -1.510607815384615 ], [ 29.755720862068966, -1.510338338461538 ], [ 29.755451379310344, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8132, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.510338338461538 ], [ 29.755720862068966, -1.510607815384615 ], [ 29.755990344827588, -1.510607815384615 ], [ 29.755990344827588, -1.510338338461538 ], [ 29.755720862068966, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8133, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.510338338461538 ], [ 29.755990344827588, -1.510607815384615 ], [ 29.756259827586206, -1.510607815384615 ], [ 29.756259827586206, -1.510338338461538 ], [ 29.755990344827588, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8134, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.510338338461538 ], [ 29.756259827586206, -1.510607815384615 ], [ 29.756798793103449, -1.510607815384615 ], [ 29.756798793103449, -1.510338338461538 ], [ 29.756259827586206, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8135, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.510338338461538 ], [ 29.756798793103449, -1.510607815384615 ], [ 29.757068275862068, -1.510607815384615 ], [ 29.757068275862068, -1.510338338461538 ], [ 29.756798793103449, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8136, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.510338338461538 ], [ 29.757068275862068, -1.510607815384615 ], [ 29.75733775862069, -1.510607815384615 ], [ 29.75733775862069, -1.510338338461538 ], [ 29.757068275862068, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8137, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.510338338461538 ], [ 29.75733775862069, -1.510607815384615 ], [ 29.757607241379311, -1.510607815384615 ], [ 29.757607241379311, -1.510338338461538 ], [ 29.75733775862069, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8138, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.510338338461538 ], [ 29.757607241379311, -1.510607815384615 ], [ 29.757876724137933, -1.510607815384615 ], [ 29.757876724137933, -1.510338338461538 ], [ 29.757607241379311, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8139, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.510338338461538 ], [ 29.757876724137933, -1.510607815384615 ], [ 29.758146206896551, -1.510607815384615 ], [ 29.758146206896551, -1.510338338461538 ], [ 29.757876724137933, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8140, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.510338338461538 ], [ 29.758146206896551, -1.510607815384615 ], [ 29.758415689655173, -1.510607815384615 ], [ 29.758415689655173, -1.510338338461538 ], [ 29.758146206896551, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8141, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.510338338461538 ], [ 29.758415689655173, -1.510607815384615 ], [ 29.758685172413795, -1.510607815384615 ], [ 29.758685172413795, -1.510338338461538 ], [ 29.758415689655173, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8142, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.510338338461538 ], [ 29.758685172413795, -1.510877292307692 ], [ 29.758954655172413, -1.510877292307692 ], [ 29.758954655172413, -1.510338338461538 ], [ 29.758685172413795, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8143, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.510338338461538 ], [ 29.759224137931035, -1.510607815384615 ], [ 29.759493620689657, -1.510607815384615 ], [ 29.759493620689657, -1.510338338461538 ], [ 29.759224137931035, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8144, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.510338338461538 ], [ 29.759493620689657, -1.510607815384615 ], [ 29.759763103448275, -1.510607815384615 ], [ 29.759763103448275, -1.510338338461538 ], [ 29.759493620689657, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8145, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.510338338461538 ], [ 29.759763103448275, -1.510607815384615 ], [ 29.760032586206897, -1.510607815384615 ], [ 29.760032586206897, -1.510338338461538 ], [ 29.759763103448275, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8146, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.510338338461538 ], [ 29.760032586206897, -1.510877292307692 ], [ 29.760302068965519, -1.510877292307692 ], [ 29.760302068965519, -1.510338338461538 ], [ 29.760032586206897, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8147, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.510338338461538 ], [ 29.760302068965519, -1.510877292307692 ], [ 29.760571551724137, -1.510877292307692 ], [ 29.760571551724137, -1.510338338461538 ], [ 29.760302068965519, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8148, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.510338338461538 ], [ 29.761110517241381, -1.510877292307692 ], [ 29.76138, -1.510877292307692 ], [ 29.76138, -1.510338338461538 ], [ 29.761110517241381, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8149, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.510338338461538 ], [ 29.761918965517243, -1.510877292307692 ], [ 29.762188448275861, -1.510877292307692 ], [ 29.762188448275861, -1.510338338461538 ], [ 29.761918965517243, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8150, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.510338338461538 ], [ 29.762188448275861, -1.510877292307692 ], [ 29.762457931034483, -1.510877292307692 ], [ 29.762457931034483, -1.510338338461538 ], [ 29.762188448275861, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8151, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.510338338461538 ], [ 29.762996896551723, -1.510877292307692 ], [ 29.763266379310345, -1.510877292307692 ], [ 29.763266379310345, -1.510338338461538 ], [ 29.762996896551723, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8152, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.510338338461538 ], [ 29.763805344827588, -1.510607815384615 ], [ 29.764074827586207, -1.510607815384615 ], [ 29.764074827586207, -1.510338338461538 ], [ 29.763805344827588, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8153, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.510338338461538 ], [ 29.764074827586207, -1.510607815384615 ], [ 29.764344310344828, -1.510607815384615 ], [ 29.764344310344828, -1.510338338461538 ], [ 29.764074827586207, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8154, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.510338338461538 ], [ 29.764344310344828, -1.510607815384615 ], [ 29.76461379310345, -1.510607815384615 ], [ 29.76461379310345, -1.510338338461538 ], [ 29.764344310344828, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8155, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.510338338461538 ], [ 29.76461379310345, -1.510607815384615 ], [ 29.764883275862069, -1.510607815384615 ], [ 29.764883275862069, -1.510338338461538 ], [ 29.76461379310345, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8156, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.510338338461538 ], [ 29.764883275862069, -1.510607815384615 ], [ 29.76515275862069, -1.510607815384615 ], [ 29.76515275862069, -1.510338338461538 ], [ 29.764883275862069, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8157, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.510338338461538 ], [ 29.76515275862069, -1.510607815384615 ], [ 29.765422241379312, -1.510607815384615 ], [ 29.765422241379312, -1.510338338461538 ], [ 29.76515275862069, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8158, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.510338338461538 ], [ 29.765422241379312, -1.510607815384615 ], [ 29.765961206896552, -1.510607815384615 ], [ 29.765961206896552, -1.510338338461538 ], [ 29.765422241379312, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8159, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.510338338461538 ], [ 29.765961206896552, -1.510607815384615 ], [ 29.766230689655174, -1.510607815384615 ], [ 29.766230689655174, -1.510338338461538 ], [ 29.765961206896552, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8160, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.510338338461538 ], [ 29.766230689655174, -1.510877292307692 ], [ 29.766500172413792, -1.510877292307692 ], [ 29.766500172413792, -1.510338338461538 ], [ 29.766230689655174, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8161, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.510338338461538 ], [ 29.766500172413792, -1.510877292307692 ], [ 29.766769655172414, -1.510877292307692 ], [ 29.766769655172414, -1.510338338461538 ], [ 29.766500172413792, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8162, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715298448275863, -1.510607815384615 ], [ 29.715298448275863, -1.511146769230769 ], [ 29.715567931034482, -1.511146769230769 ], [ 29.715567931034482, -1.510607815384615 ], [ 29.715298448275863, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8163, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715567931034482, -1.510607815384615 ], [ 29.715567931034482, -1.510877292307692 ], [ 29.715837413793103, -1.510877292307692 ], [ 29.715837413793103, -1.510607815384615 ], [ 29.715567931034482, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8164, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715837413793103, -1.510607815384615 ], [ 29.715837413793103, -1.510877292307692 ], [ 29.716106896551725, -1.510877292307692 ], [ 29.716106896551725, -1.510607815384615 ], [ 29.715837413793103, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8165, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716106896551725, -1.510607815384615 ], [ 29.716106896551725, -1.510877292307692 ], [ 29.716376379310343, -1.510877292307692 ], [ 29.716376379310343, -1.510607815384615 ], [ 29.716106896551725, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8166, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716376379310343, -1.510607815384615 ], [ 29.716376379310343, -1.510877292307692 ], [ 29.716645862068965, -1.510877292307692 ], [ 29.716645862068965, -1.510607815384615 ], [ 29.716376379310343, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8167, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.510607815384615 ], [ 29.716915344827587, -1.510877292307692 ], [ 29.717184827586205, -1.510877292307692 ], [ 29.717184827586205, -1.510607815384615 ], [ 29.716915344827587, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8168, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.510607815384615 ], [ 29.717184827586205, -1.510877292307692 ], [ 29.719071206896551, -1.510877292307692 ], [ 29.719071206896551, -1.510607815384615 ], [ 29.717184827586205, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8169, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.510607815384615 ], [ 29.719071206896551, -1.510877292307692 ], [ 29.719340689655173, -1.510877292307692 ], [ 29.719340689655173, -1.510607815384615 ], [ 29.719071206896551, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8170, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.510607815384615 ], [ 29.719340689655173, -1.510877292307692 ], [ 29.719879655172413, -1.510877292307692 ], [ 29.719879655172413, -1.510607815384615 ], [ 29.719340689655173, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8171, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.510607815384615 ], [ 29.719879655172413, -1.510877292307692 ], [ 29.720149137931035, -1.510877292307692 ], [ 29.720149137931035, -1.510607815384615 ], [ 29.719879655172413, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8172, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.510607815384615 ], [ 29.720149137931035, -1.510877292307692 ], [ 29.720688103448275, -1.510877292307692 ], [ 29.720688103448275, -1.510607815384615 ], [ 29.720149137931035, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8173, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.510607815384615 ], [ 29.720688103448275, -1.510877292307692 ], [ 29.720957586206897, -1.510877292307692 ], [ 29.720957586206897, -1.510607815384615 ], [ 29.720688103448275, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8174, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.510607815384615 ], [ 29.720957586206897, -1.510877292307692 ], [ 29.721227068965518, -1.510877292307692 ], [ 29.721227068965518, -1.510607815384615 ], [ 29.720957586206897, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8175, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.510607815384615 ], [ 29.721227068965518, -1.510877292307692 ], [ 29.721496551724137, -1.510877292307692 ], [ 29.721496551724137, -1.510607815384615 ], [ 29.721227068965518, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8176, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.510607815384615 ], [ 29.721496551724137, -1.510877292307692 ], [ 29.721766034482759, -1.510877292307692 ], [ 29.721766034482759, -1.510607815384615 ], [ 29.721496551724137, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8177, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.510607815384615 ], [ 29.721766034482759, -1.510877292307692 ], [ 29.72203551724138, -1.510877292307692 ], [ 29.72203551724138, -1.510607815384615 ], [ 29.721766034482759, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8178, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.510607815384615 ], [ 29.72203551724138, -1.510877292307692 ], [ 29.722305, -1.510877292307692 ], [ 29.722305, -1.510607815384615 ], [ 29.72203551724138, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8179, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.510607815384615 ], [ 29.722305, -1.510877292307692 ], [ 29.72257448275862, -1.510877292307692 ], [ 29.72257448275862, -1.510607815384615 ], [ 29.722305, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8180, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.510607815384615 ], [ 29.72257448275862, -1.510877292307692 ], [ 29.722843965517242, -1.510877292307692 ], [ 29.722843965517242, -1.510607815384615 ], [ 29.72257448275862, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8181, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.510607815384615 ], [ 29.722843965517242, -1.510877292307692 ], [ 29.723113448275861, -1.510877292307692 ], [ 29.723113448275861, -1.510607815384615 ], [ 29.722843965517242, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8182, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.510607815384615 ], [ 29.723113448275861, -1.510877292307692 ], [ 29.723382931034482, -1.510877292307692 ], [ 29.723382931034482, -1.510607815384615 ], [ 29.723113448275861, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8183, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.510607815384615 ], [ 29.723382931034482, -1.511146769230769 ], [ 29.723652413793104, -1.511146769230769 ], [ 29.723652413793104, -1.510607815384615 ], [ 29.723382931034482, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8184, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.510607815384615 ], [ 29.723652413793104, -1.510877292307692 ], [ 29.723921896551722, -1.510877292307692 ], [ 29.723921896551722, -1.510607815384615 ], [ 29.723652413793104, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8185, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.510607815384615 ], [ 29.723921896551722, -1.510877292307692 ], [ 29.724191379310344, -1.510877292307692 ], [ 29.724191379310344, -1.510607815384615 ], [ 29.723921896551722, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8186, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.510607815384615 ], [ 29.724460862068966, -1.510877292307692 ], [ 29.724730344827588, -1.510877292307692 ], [ 29.724730344827588, -1.510607815384615 ], [ 29.724460862068966, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8187, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.510607815384615 ], [ 29.724730344827588, -1.510877292307692 ], [ 29.724999827586206, -1.510877292307692 ], [ 29.724999827586206, -1.510607815384615 ], [ 29.724730344827588, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8188, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.510338338461538 ], [ 29.72553879310345, -1.510338338461538 ], [ 29.72553879310345, -1.510877292307692 ], [ 29.724999827586206, -1.510877292307692 ], [ 29.724999827586206, -1.510607815384615 ], [ 29.725269310344828, -1.510607815384615 ], [ 29.725269310344828, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8189, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.510607815384615 ], [ 29.730389482758621, -1.510877292307692 ], [ 29.730658965517243, -1.510877292307692 ], [ 29.730658965517243, -1.510607815384615 ], [ 29.730389482758621, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8190, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.510607815384615 ], [ 29.730658965517243, -1.510877292307692 ], [ 29.731197931034483, -1.510877292307692 ], [ 29.731197931034483, -1.510607815384615 ], [ 29.730658965517243, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8191, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.510607815384615 ], [ 29.733353793103447, -1.510877292307692 ], [ 29.733623275862069, -1.510877292307692 ], [ 29.733623275862069, -1.510607815384615 ], [ 29.733353793103447, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8192, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.510607815384615 ], [ 29.733623275862069, -1.510877292307692 ], [ 29.733892758620691, -1.510877292307692 ], [ 29.733892758620691, -1.510607815384615 ], [ 29.733623275862069, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8193, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.510607815384615 ], [ 29.734431724137931, -1.511146769230769 ], [ 29.734701206896553, -1.511146769230769 ], [ 29.734701206896553, -1.510607815384615 ], [ 29.734431724137931, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8194, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.510607815384615 ], [ 29.734970689655171, -1.510877292307692 ], [ 29.735509655172415, -1.510877292307692 ], [ 29.735509655172415, -1.510607815384615 ], [ 29.734970689655171, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8195, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.510607815384615 ], [ 29.735509655172415, -1.510877292307692 ], [ 29.735779137931036, -1.510877292307692 ], [ 29.735779137931036, -1.510607815384615 ], [ 29.735509655172415, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8196, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.510607815384615 ], [ 29.735779137931036, -1.510877292307692 ], [ 29.736318103448276, -1.510877292307692 ], [ 29.736318103448276, -1.510607815384615 ], [ 29.735779137931036, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8197, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.510607815384615 ], [ 29.736318103448276, -1.510877292307692 ], [ 29.736857068965517, -1.510877292307692 ], [ 29.736857068965517, -1.510607815384615 ], [ 29.736318103448276, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8198, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.510607815384615 ], [ 29.736857068965517, -1.510877292307692 ], [ 29.737935, -1.510877292307692 ], [ 29.737935, -1.510607815384615 ], [ 29.736857068965517, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8199, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.510607815384615 ], [ 29.737935, -1.510877292307692 ], [ 29.738204482758622, -1.510877292307692 ], [ 29.738204482758622, -1.510607815384615 ], [ 29.737935, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8200, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.510607815384615 ], [ 29.738204482758622, -1.511416246153846 ], [ 29.738743448275862, -1.511416246153846 ], [ 29.738743448275862, -1.510607815384615 ], [ 29.738204482758622, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8201, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.510607815384615 ], [ 29.744402586206895, -1.510877292307692 ], [ 29.744672068965517, -1.510877292307692 ], [ 29.744672068965517, -1.510607815384615 ], [ 29.744402586206895, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8202, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.510068861538461 ], [ 29.745480517241379, -1.510068861538461 ], [ 29.745480517241379, -1.510877292307692 ], [ 29.744672068965517, -1.510877292307692 ], [ 29.744672068965517, -1.510607815384615 ], [ 29.745211034482757, -1.510607815384615 ], [ 29.745211034482757, -1.510338338461538 ], [ 29.744941551724139, -1.510338338461538 ], [ 29.744941551724139, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8203, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.510607815384615 ], [ 29.75275655172414, -1.510877292307692 ], [ 29.75329551724138, -1.510877292307692 ], [ 29.75329551724138, -1.510607815384615 ], [ 29.75275655172414, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8204, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.510607815384615 ], [ 29.75329551724138, -1.510877292307692 ], [ 29.753565, -1.510877292307692 ], [ 29.753565, -1.510607815384615 ], [ 29.75329551724138, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8205, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.510607815384615 ], [ 29.753565, -1.510877292307692 ], [ 29.754103965517242, -1.510877292307692 ], [ 29.754103965517242, -1.510607815384615 ], [ 29.753565, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8206, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.510607815384615 ], [ 29.754103965517242, -1.510877292307692 ], [ 29.754373448275864, -1.510877292307692 ], [ 29.754373448275864, -1.510607815384615 ], [ 29.754103965517242, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8207, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.510607815384615 ], [ 29.754373448275864, -1.510877292307692 ], [ 29.754642931034482, -1.510877292307692 ], [ 29.754642931034482, -1.510607815384615 ], [ 29.754373448275864, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8208, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.510607815384615 ], [ 29.754642931034482, -1.510877292307692 ], [ 29.755181896551726, -1.510877292307692 ], [ 29.755181896551726, -1.510607815384615 ], [ 29.754642931034482, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8209, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.510607815384615 ], [ 29.755181896551726, -1.510877292307692 ], [ 29.755451379310344, -1.510877292307692 ], [ 29.755451379310344, -1.510607815384615 ], [ 29.755181896551726, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8210, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.510607815384615 ], [ 29.755451379310344, -1.510877292307692 ], [ 29.755720862068966, -1.510877292307692 ], [ 29.755720862068966, -1.510607815384615 ], [ 29.755451379310344, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8211, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.510607815384615 ], [ 29.755720862068966, -1.510877292307692 ], [ 29.755990344827588, -1.510877292307692 ], [ 29.755990344827588, -1.510607815384615 ], [ 29.755720862068966, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8212, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.510607815384615 ], [ 29.755990344827588, -1.510877292307692 ], [ 29.756259827586206, -1.510877292307692 ], [ 29.756259827586206, -1.510607815384615 ], [ 29.755990344827588, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8213, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.510607815384615 ], [ 29.756259827586206, -1.510877292307692 ], [ 29.756798793103449, -1.510877292307692 ], [ 29.756798793103449, -1.510607815384615 ], [ 29.756259827586206, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8214, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.510607815384615 ], [ 29.756798793103449, -1.510877292307692 ], [ 29.757068275862068, -1.510877292307692 ], [ 29.757068275862068, -1.510607815384615 ], [ 29.756798793103449, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8215, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.510607815384615 ], [ 29.757068275862068, -1.510877292307692 ], [ 29.75733775862069, -1.510877292307692 ], [ 29.75733775862069, -1.510607815384615 ], [ 29.757068275862068, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8216, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.510607815384615 ], [ 29.75733775862069, -1.510877292307692 ], [ 29.757607241379311, -1.510877292307692 ], [ 29.757607241379311, -1.510607815384615 ], [ 29.75733775862069, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8217, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.510607815384615 ], [ 29.757607241379311, -1.510877292307692 ], [ 29.757876724137933, -1.510877292307692 ], [ 29.757876724137933, -1.510607815384615 ], [ 29.757607241379311, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8218, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.510607815384615 ], [ 29.757876724137933, -1.510877292307692 ], [ 29.758146206896551, -1.510877292307692 ], [ 29.758146206896551, -1.510607815384615 ], [ 29.757876724137933, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8219, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.510607815384615 ], [ 29.758146206896551, -1.510877292307692 ], [ 29.758415689655173, -1.510877292307692 ], [ 29.758415689655173, -1.510607815384615 ], [ 29.758146206896551, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8220, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.510607815384615 ], [ 29.758415689655173, -1.511146769230769 ], [ 29.758685172413795, -1.511146769230769 ], [ 29.758685172413795, -1.510607815384615 ], [ 29.758415689655173, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8221, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.510607815384615 ], [ 29.758954655172413, -1.510877292307692 ], [ 29.759224137931035, -1.510877292307692 ], [ 29.759224137931035, -1.510607815384615 ], [ 29.758954655172413, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8222, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.510607815384615 ], [ 29.759224137931035, -1.510877292307692 ], [ 29.759493620689657, -1.510877292307692 ], [ 29.759493620689657, -1.510607815384615 ], [ 29.759224137931035, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8223, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.510607815384615 ], [ 29.759493620689657, -1.511146769230769 ], [ 29.759763103448275, -1.511146769230769 ], [ 29.759763103448275, -1.510607815384615 ], [ 29.759493620689657, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8224, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.510607815384615 ], [ 29.759763103448275, -1.511146769230769 ], [ 29.760032586206897, -1.511146769230769 ], [ 29.760032586206897, -1.510607815384615 ], [ 29.759763103448275, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8225, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.510607815384615 ], [ 29.760571551724137, -1.511146769230769 ], [ 29.760841034482759, -1.511146769230769 ], [ 29.760841034482759, -1.510607815384615 ], [ 29.760571551724137, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8226, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.510607815384615 ], [ 29.76138, -1.511146769230769 ], [ 29.761649482758621, -1.511146769230769 ], [ 29.761649482758621, -1.510607815384615 ], [ 29.76138, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8227, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.510607815384615 ], [ 29.761649482758621, -1.511146769230769 ], [ 29.761918965517243, -1.511146769230769 ], [ 29.761918965517243, -1.510607815384615 ], [ 29.761649482758621, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8228, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.510607815384615 ], [ 29.762457931034483, -1.510877292307692 ], [ 29.762727413793105, -1.510877292307692 ], [ 29.762727413793105, -1.510607815384615 ], [ 29.762457931034483, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8229, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.510607815384615 ], [ 29.762727413793105, -1.511146769230769 ], [ 29.762996896551723, -1.511146769230769 ], [ 29.762996896551723, -1.510607815384615 ], [ 29.762727413793105, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8230, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.510607815384615 ], [ 29.763266379310345, -1.510877292307692 ], [ 29.763535862068967, -1.510877292307692 ], [ 29.763535862068967, -1.510607815384615 ], [ 29.763266379310345, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8231, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.510607815384615 ], [ 29.763535862068967, -1.510877292307692 ], [ 29.763805344827588, -1.510877292307692 ], [ 29.763805344827588, -1.510607815384615 ], [ 29.763535862068967, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8232, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.510607815384615 ], [ 29.763805344827588, -1.510877292307692 ], [ 29.764074827586207, -1.510877292307692 ], [ 29.764074827586207, -1.510607815384615 ], [ 29.763805344827588, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8233, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.510607815384615 ], [ 29.764074827586207, -1.510877292307692 ], [ 29.764344310344828, -1.510877292307692 ], [ 29.764344310344828, -1.510607815384615 ], [ 29.764074827586207, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8234, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.510607815384615 ], [ 29.764344310344828, -1.510877292307692 ], [ 29.76461379310345, -1.510877292307692 ], [ 29.76461379310345, -1.510607815384615 ], [ 29.764344310344828, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8235, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.510607815384615 ], [ 29.76461379310345, -1.510877292307692 ], [ 29.764883275862069, -1.510877292307692 ], [ 29.764883275862069, -1.510607815384615 ], [ 29.76461379310345, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8236, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.510607815384615 ], [ 29.764883275862069, -1.510877292307692 ], [ 29.76515275862069, -1.510877292307692 ], [ 29.76515275862069, -1.510607815384615 ], [ 29.764883275862069, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8237, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.510607815384615 ], [ 29.76515275862069, -1.510877292307692 ], [ 29.765422241379312, -1.510877292307692 ], [ 29.765422241379312, -1.510607815384615 ], [ 29.76515275862069, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8238, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.510607815384615 ], [ 29.765422241379312, -1.510877292307692 ], [ 29.765961206896552, -1.510877292307692 ], [ 29.765961206896552, -1.510607815384615 ], [ 29.765422241379312, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8239, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.510607815384615 ], [ 29.765961206896552, -1.511146769230769 ], [ 29.766230689655174, -1.511146769230769 ], [ 29.766230689655174, -1.510607815384615 ], [ 29.765961206896552, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8240, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.510607815384615 ], [ 29.766769655172414, -1.510877292307692 ], [ 29.767039137931036, -1.510877292307692 ], [ 29.767039137931036, -1.510607815384615 ], [ 29.766769655172414, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8241, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.510607815384615 ], [ 29.767039137931036, -1.510877292307692 ], [ 29.767578103448276, -1.510877292307692 ], [ 29.767578103448276, -1.510607815384615 ], [ 29.767039137931036, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8242, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.510877292307692 ], [ 29.712334137931034, -1.511146769230769 ], [ 29.713142586206896, -1.511146769230769 ], [ 29.713142586206896, -1.510877292307692 ], [ 29.712334137931034, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8243, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715567931034482, -1.510877292307692 ], [ 29.715567931034482, -1.511416246153846 ], [ 29.715837413793103, -1.511416246153846 ], [ 29.715837413793103, -1.510877292307692 ], [ 29.715567931034482, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8244, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715837413793103, -1.510877292307692 ], [ 29.715837413793103, -1.511416246153846 ], [ 29.716106896551725, -1.511416246153846 ], [ 29.716106896551725, -1.510877292307692 ], [ 29.715837413793103, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8245, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716106896551725, -1.510877292307692 ], [ 29.716106896551725, -1.511146769230769 ], [ 29.716376379310343, -1.511146769230769 ], [ 29.716376379310343, -1.510877292307692 ], [ 29.716106896551725, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8246, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716376379310343, -1.510877292307692 ], [ 29.716376379310343, -1.511146769230769 ], [ 29.716645862068965, -1.511146769230769 ], [ 29.716645862068965, -1.510877292307692 ], [ 29.716376379310343, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8247, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.510877292307692 ], [ 29.716915344827587, -1.511146769230769 ], [ 29.717454310344827, -1.511146769230769 ], [ 29.717454310344827, -1.511416246153846 ], [ 29.717723793103449, -1.511416246153846 ], [ 29.717723793103449, -1.511146769230769 ], [ 29.719071206896551, -1.511146769230769 ], [ 29.719071206896551, -1.510877292307692 ], [ 29.716915344827587, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8248, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.510877292307692 ], [ 29.719071206896551, -1.511146769230769 ], [ 29.719340689655173, -1.511146769230769 ], [ 29.719340689655173, -1.510877292307692 ], [ 29.719071206896551, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8249, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.510877292307692 ], [ 29.719340689655173, -1.511146769230769 ], [ 29.719879655172413, -1.511146769230769 ], [ 29.719879655172413, -1.510877292307692 ], [ 29.719340689655173, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8250, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.510877292307692 ], [ 29.719879655172413, -1.511146769230769 ], [ 29.720149137931035, -1.511146769230769 ], [ 29.720149137931035, -1.510877292307692 ], [ 29.719879655172413, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8251, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.510877292307692 ], [ 29.720149137931035, -1.511146769230769 ], [ 29.720688103448275, -1.511146769230769 ], [ 29.720688103448275, -1.510877292307692 ], [ 29.720149137931035, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8252, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.510877292307692 ], [ 29.720688103448275, -1.511146769230769 ], [ 29.720957586206897, -1.511146769230769 ], [ 29.720957586206897, -1.510877292307692 ], [ 29.720688103448275, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8253, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.510877292307692 ], [ 29.720957586206897, -1.511146769230769 ], [ 29.721227068965518, -1.511146769230769 ], [ 29.721227068965518, -1.510877292307692 ], [ 29.720957586206897, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8254, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.510877292307692 ], [ 29.721227068965518, -1.511146769230769 ], [ 29.721496551724137, -1.511146769230769 ], [ 29.721496551724137, -1.510877292307692 ], [ 29.721227068965518, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8255, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.510877292307692 ], [ 29.721496551724137, -1.511146769230769 ], [ 29.721766034482759, -1.511146769230769 ], [ 29.721766034482759, -1.510877292307692 ], [ 29.721496551724137, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8256, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.510877292307692 ], [ 29.721766034482759, -1.511146769230769 ], [ 29.72203551724138, -1.511146769230769 ], [ 29.72203551724138, -1.510877292307692 ], [ 29.721766034482759, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8257, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.510877292307692 ], [ 29.72203551724138, -1.511146769230769 ], [ 29.722305, -1.511146769230769 ], [ 29.722305, -1.510877292307692 ], [ 29.72203551724138, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8258, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.510877292307692 ], [ 29.722305, -1.511146769230769 ], [ 29.72257448275862, -1.511146769230769 ], [ 29.72257448275862, -1.510877292307692 ], [ 29.722305, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8259, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.510877292307692 ], [ 29.72257448275862, -1.511146769230769 ], [ 29.722843965517242, -1.511146769230769 ], [ 29.722843965517242, -1.510877292307692 ], [ 29.72257448275862, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8260, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.510877292307692 ], [ 29.722843965517242, -1.511416246153846 ], [ 29.723382931034482, -1.511416246153846 ], [ 29.723382931034482, -1.511146769230769 ], [ 29.723113448275861, -1.511146769230769 ], [ 29.723113448275861, -1.510877292307692 ], [ 29.722843965517242, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8261, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.510877292307692 ], [ 29.723113448275861, -1.511146769230769 ], [ 29.723382931034482, -1.511146769230769 ], [ 29.723382931034482, -1.510877292307692 ], [ 29.723113448275861, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8262, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.510877292307692 ], [ 29.723652413793104, -1.511146769230769 ], [ 29.723921896551722, -1.511146769230769 ], [ 29.723921896551722, -1.510877292307692 ], [ 29.723652413793104, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8263, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.510877292307692 ], [ 29.723921896551722, -1.511146769230769 ], [ 29.724191379310344, -1.511146769230769 ], [ 29.724191379310344, -1.510877292307692 ], [ 29.723921896551722, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8264, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.510877292307692 ], [ 29.724191379310344, -1.511146769230769 ], [ 29.724460862068966, -1.511146769230769 ], [ 29.724460862068966, -1.510877292307692 ], [ 29.724191379310344, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8265, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.510877292307692 ], [ 29.724460862068966, -1.511146769230769 ], [ 29.724730344827588, -1.511146769230769 ], [ 29.724730344827588, -1.510877292307692 ], [ 29.724460862068966, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8266, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.510877292307692 ], [ 29.724730344827588, -1.511146769230769 ], [ 29.724999827586206, -1.511146769230769 ], [ 29.724999827586206, -1.510877292307692 ], [ 29.724730344827588, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8267, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.510877292307692 ], [ 29.724999827586206, -1.511146769230769 ], [ 29.725269310344828, -1.511146769230769 ], [ 29.725269310344828, -1.510877292307692 ], [ 29.724999827586206, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8268, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.510877292307692 ], [ 29.725269310344828, -1.511146769230769 ], [ 29.72553879310345, -1.511146769230769 ], [ 29.72553879310345, -1.510877292307692 ], [ 29.725269310344828, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8269, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.510877292307692 ], [ 29.725808275862068, -1.511416246153846 ], [ 29.72607775862069, -1.511416246153846 ], [ 29.72607775862069, -1.510877292307692 ], [ 29.725808275862068, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8270, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.510877292307692 ], [ 29.729311551724138, -1.511416246153846 ], [ 29.729850517241381, -1.511416246153846 ], [ 29.729850517241381, -1.510877292307692 ], [ 29.729311551724138, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8271, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.510877292307692 ], [ 29.730389482758621, -1.511416246153846 ], [ 29.730658965517243, -1.511416246153846 ], [ 29.730658965517243, -1.510877292307692 ], [ 29.730389482758621, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8272, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.510877292307692 ], [ 29.733084310344829, -1.511146769230769 ], [ 29.733353793103447, -1.511146769230769 ], [ 29.733353793103447, -1.510877292307692 ], [ 29.733084310344829, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8273, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.510877292307692 ], [ 29.733353793103447, -1.511146769230769 ], [ 29.733623275862069, -1.511146769230769 ], [ 29.733623275862069, -1.510877292307692 ], [ 29.733353793103447, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8274, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.510877292307692 ], [ 29.733623275862069, -1.511146769230769 ], [ 29.733892758620691, -1.511146769230769 ], [ 29.733892758620691, -1.510877292307692 ], [ 29.733623275862069, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8275, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.510877292307692 ], [ 29.733892758620691, -1.511146769230769 ], [ 29.734162241379309, -1.511146769230769 ], [ 29.734162241379309, -1.510877292307692 ], [ 29.733892758620691, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8276, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.510877292307692 ], [ 29.734162241379309, -1.511146769230769 ], [ 29.734431724137931, -1.511146769230769 ], [ 29.734431724137931, -1.510877292307692 ], [ 29.734162241379309, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8277, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.510877292307692 ], [ 29.734701206896553, -1.511146769230769 ], [ 29.734970689655171, -1.511146769230769 ], [ 29.734970689655171, -1.510877292307692 ], [ 29.734701206896553, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8278, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.510877292307692 ], [ 29.734970689655171, -1.511146769230769 ], [ 29.735240172413793, -1.511146769230769 ], [ 29.735240172413793, -1.510877292307692 ], [ 29.734970689655171, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8279, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.510877292307692 ], [ 29.735240172413793, -1.511146769230769 ], [ 29.735509655172415, -1.511146769230769 ], [ 29.735509655172415, -1.510877292307692 ], [ 29.735240172413793, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8280, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.510877292307692 ], [ 29.735509655172415, -1.511146769230769 ], [ 29.735779137931036, -1.511146769230769 ], [ 29.735779137931036, -1.510877292307692 ], [ 29.735509655172415, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8281, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.510877292307692 ], [ 29.735779137931036, -1.511146769230769 ], [ 29.736318103448276, -1.511146769230769 ], [ 29.736318103448276, -1.510877292307692 ], [ 29.735779137931036, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8282, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.510877292307692 ], [ 29.736318103448276, -1.511146769230769 ], [ 29.736587586206898, -1.511146769230769 ], [ 29.736587586206898, -1.510877292307692 ], [ 29.736318103448276, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8283, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.509799384615385 ], [ 29.74575, -1.509799384615385 ], [ 29.74575, -1.511146769230769 ], [ 29.744672068965517, -1.511146769230769 ], [ 29.744672068965517, -1.510877292307692 ], [ 29.745480517241379, -1.510877292307692 ], [ 29.745480517241379, -1.510068861538461 ], [ 29.744941551724139, -1.510068861538461 ], [ 29.744941551724139, -1.509799384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8284, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.510877292307692 ], [ 29.75329551724138, -1.511146769230769 ], [ 29.75383448275862, -1.511146769230769 ], [ 29.75383448275862, -1.510877292307692 ], [ 29.75329551724138, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8285, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.510877292307692 ], [ 29.75383448275862, -1.511146769230769 ], [ 29.754103965517242, -1.511146769230769 ], [ 29.754103965517242, -1.510877292307692 ], [ 29.75383448275862, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8286, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.510877292307692 ], [ 29.754103965517242, -1.511146769230769 ], [ 29.754373448275864, -1.511146769230769 ], [ 29.754373448275864, -1.510877292307692 ], [ 29.754103965517242, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8287, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.510877292307692 ], [ 29.754373448275864, -1.511146769230769 ], [ 29.754912413793104, -1.511146769230769 ], [ 29.754912413793104, -1.510877292307692 ], [ 29.754373448275864, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8288, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.510877292307692 ], [ 29.754912413793104, -1.511146769230769 ], [ 29.755181896551726, -1.511146769230769 ], [ 29.755181896551726, -1.510877292307692 ], [ 29.754912413793104, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8289, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.510877292307692 ], [ 29.755181896551726, -1.511146769230769 ], [ 29.755451379310344, -1.511146769230769 ], [ 29.755451379310344, -1.510877292307692 ], [ 29.755181896551726, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8290, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.510877292307692 ], [ 29.755451379310344, -1.511146769230769 ], [ 29.755720862068966, -1.511146769230769 ], [ 29.755720862068966, -1.510877292307692 ], [ 29.755451379310344, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8291, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.510877292307692 ], [ 29.755720862068966, -1.511146769230769 ], [ 29.755990344827588, -1.511146769230769 ], [ 29.755990344827588, -1.510877292307692 ], [ 29.755720862068966, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8292, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.510877292307692 ], [ 29.755990344827588, -1.511146769230769 ], [ 29.756259827586206, -1.511146769230769 ], [ 29.756259827586206, -1.510877292307692 ], [ 29.755990344827588, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8293, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.510877292307692 ], [ 29.756259827586206, -1.511146769230769 ], [ 29.756798793103449, -1.511146769230769 ], [ 29.756798793103449, -1.510877292307692 ], [ 29.756259827586206, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8294, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.510877292307692 ], [ 29.756798793103449, -1.511146769230769 ], [ 29.757068275862068, -1.511146769230769 ], [ 29.757068275862068, -1.510877292307692 ], [ 29.756798793103449, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8295, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.510877292307692 ], [ 29.757068275862068, -1.511146769230769 ], [ 29.75733775862069, -1.511146769230769 ], [ 29.75733775862069, -1.510877292307692 ], [ 29.757068275862068, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8296, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.510877292307692 ], [ 29.75733775862069, -1.511146769230769 ], [ 29.757607241379311, -1.511146769230769 ], [ 29.757607241379311, -1.510877292307692 ], [ 29.75733775862069, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8297, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.510877292307692 ], [ 29.757607241379311, -1.511146769230769 ], [ 29.757876724137933, -1.511146769230769 ], [ 29.757876724137933, -1.510877292307692 ], [ 29.757607241379311, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8298, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.510877292307692 ], [ 29.757876724137933, -1.511146769230769 ], [ 29.758146206896551, -1.511146769230769 ], [ 29.758146206896551, -1.510877292307692 ], [ 29.757876724137933, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8299, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.510877292307692 ], [ 29.758146206896551, -1.511416246153846 ], [ 29.758415689655173, -1.511416246153846 ], [ 29.758415689655173, -1.510877292307692 ], [ 29.758146206896551, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8300, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.510877292307692 ], [ 29.758685172413795, -1.511146769230769 ], [ 29.758954655172413, -1.511146769230769 ], [ 29.758954655172413, -1.510877292307692 ], [ 29.758685172413795, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8301, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.510877292307692 ], [ 29.758954655172413, -1.511416246153846 ], [ 29.759224137931035, -1.511416246153846 ], [ 29.759224137931035, -1.510877292307692 ], [ 29.758954655172413, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8302, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.510877292307692 ], [ 29.759224137931035, -1.511416246153846 ], [ 29.759493620689657, -1.511416246153846 ], [ 29.759493620689657, -1.510877292307692 ], [ 29.759224137931035, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8303, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.510877292307692 ], [ 29.760032586206897, -1.511416246153846 ], [ 29.760302068965519, -1.511416246153846 ], [ 29.760302068965519, -1.510877292307692 ], [ 29.760032586206897, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8304, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.510877292307692 ], [ 29.760841034482759, -1.511416246153846 ], [ 29.761110517241381, -1.511416246153846 ], [ 29.761110517241381, -1.510877292307692 ], [ 29.760841034482759, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8305, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.510877292307692 ], [ 29.761110517241381, -1.511416246153846 ], [ 29.76138, -1.511416246153846 ], [ 29.76138, -1.510877292307692 ], [ 29.761110517241381, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8306, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.510877292307692 ], [ 29.761918965517243, -1.511146769230769 ], [ 29.762188448275861, -1.511146769230769 ], [ 29.762188448275861, -1.510877292307692 ], [ 29.761918965517243, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8307, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.510877292307692 ], [ 29.762188448275861, -1.511146769230769 ], [ 29.762457931034483, -1.511146769230769 ], [ 29.762457931034483, -1.510877292307692 ], [ 29.762188448275861, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8308, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.510877292307692 ], [ 29.762457931034483, -1.511416246153846 ], [ 29.762727413793105, -1.511416246153846 ], [ 29.762727413793105, -1.510877292307692 ], [ 29.762457931034483, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8309, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.510877292307692 ], [ 29.762996896551723, -1.511146769230769 ], [ 29.763266379310345, -1.511146769230769 ], [ 29.763266379310345, -1.510877292307692 ], [ 29.762996896551723, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8310, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.510877292307692 ], [ 29.763266379310345, -1.511146769230769 ], [ 29.763535862068967, -1.511146769230769 ], [ 29.763535862068967, -1.510877292307692 ], [ 29.763266379310345, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8311, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.510877292307692 ], [ 29.763535862068967, -1.511146769230769 ], [ 29.763805344827588, -1.511146769230769 ], [ 29.763805344827588, -1.510877292307692 ], [ 29.763535862068967, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8312, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.510877292307692 ], [ 29.763805344827588, -1.511146769230769 ], [ 29.764074827586207, -1.511146769230769 ], [ 29.764074827586207, -1.510877292307692 ], [ 29.763805344827588, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8313, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.510877292307692 ], [ 29.764074827586207, -1.511146769230769 ], [ 29.764344310344828, -1.511146769230769 ], [ 29.764344310344828, -1.510877292307692 ], [ 29.764074827586207, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8314, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.510877292307692 ], [ 29.764344310344828, -1.511146769230769 ], [ 29.76461379310345, -1.511146769230769 ], [ 29.76461379310345, -1.510877292307692 ], [ 29.764344310344828, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8315, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.510877292307692 ], [ 29.76461379310345, -1.511146769230769 ], [ 29.764883275862069, -1.511146769230769 ], [ 29.764883275862069, -1.510877292307692 ], [ 29.76461379310345, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8316, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.510877292307692 ], [ 29.764883275862069, -1.511146769230769 ], [ 29.76515275862069, -1.511146769230769 ], [ 29.76515275862069, -1.510877292307692 ], [ 29.764883275862069, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8317, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.510877292307692 ], [ 29.76515275862069, -1.511146769230769 ], [ 29.765422241379312, -1.511146769230769 ], [ 29.765422241379312, -1.510877292307692 ], [ 29.76515275862069, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8318, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.510877292307692 ], [ 29.765422241379312, -1.511146769230769 ], [ 29.765961206896552, -1.511146769230769 ], [ 29.765961206896552, -1.510877292307692 ], [ 29.765422241379312, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8319, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.510877292307692 ], [ 29.766230689655174, -1.511146769230769 ], [ 29.766500172413792, -1.511146769230769 ], [ 29.766500172413792, -1.510877292307692 ], [ 29.766230689655174, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8320, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.510877292307692 ], [ 29.766500172413792, -1.511146769230769 ], [ 29.766769655172414, -1.511146769230769 ], [ 29.766769655172414, -1.510877292307692 ], [ 29.766500172413792, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8321, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.510877292307692 ], [ 29.766769655172414, -1.511146769230769 ], [ 29.767308620689654, -1.511146769230769 ], [ 29.767308620689654, -1.510877292307692 ], [ 29.766769655172414, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8322, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.510877292307692 ], [ 29.767308620689654, -1.511146769230769 ], [ 29.767578103448276, -1.511146769230769 ], [ 29.767578103448276, -1.510877292307692 ], [ 29.767308620689654, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8323, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.510877292307692 ], [ 29.767578103448276, -1.511146769230769 ], [ 29.767847586206898, -1.511146769230769 ], [ 29.767847586206898, -1.510877292307692 ], [ 29.767578103448276, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8324, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.511146769230769 ], [ 29.712334137931034, -1.511416246153846 ], [ 29.712603620689656, -1.511416246153846 ], [ 29.712603620689656, -1.511146769230769 ], [ 29.712334137931034, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8325, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.511146769230769 ], [ 29.712603620689656, -1.511416246153846 ], [ 29.712873103448278, -1.511416246153846 ], [ 29.712873103448278, -1.511146769230769 ], [ 29.712603620689656, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8326, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.511146769230769 ], [ 29.712873103448278, -1.511416246153846 ], [ 29.713142586206896, -1.511416246153846 ], [ 29.713142586206896, -1.511146769230769 ], [ 29.712873103448278, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8327, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.511146769230769 ], [ 29.713142586206896, -1.511416246153846 ], [ 29.713412068965518, -1.511416246153846 ], [ 29.713412068965518, -1.511146769230769 ], [ 29.713142586206896, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8328, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71449, -1.511146769230769 ], [ 29.71449, -1.511416246153846 ], [ 29.715298448275863, -1.511416246153846 ], [ 29.715298448275863, -1.511146769230769 ], [ 29.71449, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8329, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715298448275863, -1.511146769230769 ], [ 29.715298448275863, -1.511416246153846 ], [ 29.715567931034482, -1.511416246153846 ], [ 29.715567931034482, -1.511146769230769 ], [ 29.715298448275863, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8330, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716106896551725, -1.511146769230769 ], [ 29.716106896551725, -1.511416246153846 ], [ 29.716376379310343, -1.511416246153846 ], [ 29.716376379310343, -1.511146769230769 ], [ 29.716106896551725, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8331, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716645862068965, -1.510877292307692 ], [ 29.716915344827587, -1.510877292307692 ], [ 29.716915344827587, -1.511146769230769 ], [ 29.717184827586205, -1.511146769230769 ], [ 29.717184827586205, -1.511416246153846 ], [ 29.716376379310343, -1.511416246153846 ], [ 29.716376379310343, -1.511146769230769 ], [ 29.716645862068965, -1.511146769230769 ], [ 29.716645862068965, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8332, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.511146769230769 ], [ 29.717184827586205, -1.511416246153846 ], [ 29.717454310344827, -1.511416246153846 ], [ 29.717454310344827, -1.511146769230769 ], [ 29.717184827586205, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8333, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.511146769230769 ], [ 29.717723793103449, -1.511416246153846 ], [ 29.717993275862067, -1.511416246153846 ], [ 29.717993275862067, -1.511146769230769 ], [ 29.717723793103449, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8334, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.511146769230769 ], [ 29.717993275862067, -1.511416246153846 ], [ 29.719071206896551, -1.511416246153846 ], [ 29.719071206896551, -1.511146769230769 ], [ 29.717993275862067, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8335, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.511146769230769 ], [ 29.719071206896551, -1.511416246153846 ], [ 29.719340689655173, -1.511416246153846 ], [ 29.719340689655173, -1.511146769230769 ], [ 29.719071206896551, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8336, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.511146769230769 ], [ 29.719340689655173, -1.511416246153846 ], [ 29.719610172413795, -1.511416246153846 ], [ 29.719610172413795, -1.511146769230769 ], [ 29.719340689655173, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8337, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.511146769230769 ], [ 29.719610172413795, -1.511416246153846 ], [ 29.720149137931035, -1.511416246153846 ], [ 29.720149137931035, -1.511146769230769 ], [ 29.719610172413795, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8338, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.511146769230769 ], [ 29.720149137931035, -1.511416246153846 ], [ 29.720688103448275, -1.511416246153846 ], [ 29.720688103448275, -1.511146769230769 ], [ 29.720149137931035, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8339, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.511146769230769 ], [ 29.720688103448275, -1.511416246153846 ], [ 29.720957586206897, -1.511416246153846 ], [ 29.720957586206897, -1.511146769230769 ], [ 29.720688103448275, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8340, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.511146769230769 ], [ 29.720957586206897, -1.511416246153846 ], [ 29.721227068965518, -1.511416246153846 ], [ 29.721227068965518, -1.511146769230769 ], [ 29.720957586206897, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8341, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.511146769230769 ], [ 29.721227068965518, -1.511416246153846 ], [ 29.721496551724137, -1.511416246153846 ], [ 29.721496551724137, -1.511146769230769 ], [ 29.721227068965518, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8342, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.511146769230769 ], [ 29.721496551724137, -1.511416246153846 ], [ 29.721766034482759, -1.511416246153846 ], [ 29.721766034482759, -1.511146769230769 ], [ 29.721496551724137, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8343, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.511146769230769 ], [ 29.721766034482759, -1.511416246153846 ], [ 29.72203551724138, -1.511416246153846 ], [ 29.72203551724138, -1.511146769230769 ], [ 29.721766034482759, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8344, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.511146769230769 ], [ 29.722305, -1.511416246153846 ], [ 29.72257448275862, -1.511416246153846 ], [ 29.72257448275862, -1.511146769230769 ], [ 29.722305, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8345, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.511146769230769 ], [ 29.72257448275862, -1.511416246153846 ], [ 29.722843965517242, -1.511416246153846 ], [ 29.722843965517242, -1.511146769230769 ], [ 29.72257448275862, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8346, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.511146769230769 ], [ 29.723382931034482, -1.511416246153846 ], [ 29.723652413793104, -1.511416246153846 ], [ 29.723652413793104, -1.511146769230769 ], [ 29.723382931034482, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8347, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.511146769230769 ], [ 29.723652413793104, -1.511416246153846 ], [ 29.723921896551722, -1.511416246153846 ], [ 29.723921896551722, -1.511146769230769 ], [ 29.723652413793104, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8348, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.511146769230769 ], [ 29.723921896551722, -1.511416246153846 ], [ 29.724191379310344, -1.511416246153846 ], [ 29.724191379310344, -1.511146769230769 ], [ 29.723921896551722, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8349, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.511146769230769 ], [ 29.724191379310344, -1.511416246153846 ], [ 29.724460862068966, -1.511416246153846 ], [ 29.724460862068966, -1.511146769230769 ], [ 29.724191379310344, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8350, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.511146769230769 ], [ 29.724460862068966, -1.511416246153846 ], [ 29.724730344827588, -1.511416246153846 ], [ 29.724730344827588, -1.511146769230769 ], [ 29.724460862068966, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8351, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.511146769230769 ], [ 29.724730344827588, -1.511416246153846 ], [ 29.724999827586206, -1.511416246153846 ], [ 29.724999827586206, -1.511146769230769 ], [ 29.724730344827588, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8352, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.511146769230769 ], [ 29.724999827586206, -1.511416246153846 ], [ 29.725269310344828, -1.511416246153846 ], [ 29.725269310344828, -1.511146769230769 ], [ 29.724999827586206, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8353, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.511146769230769 ], [ 29.725269310344828, -1.511416246153846 ], [ 29.72553879310345, -1.511416246153846 ], [ 29.72553879310345, -1.511146769230769 ], [ 29.725269310344828, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8354, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.511146769230769 ], [ 29.732545344827585, -1.511416246153846 ], [ 29.733084310344829, -1.511416246153846 ], [ 29.733084310344829, -1.511146769230769 ], [ 29.732545344827585, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8355, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.511146769230769 ], [ 29.733084310344829, -1.511416246153846 ], [ 29.733353793103447, -1.511416246153846 ], [ 29.733353793103447, -1.511146769230769 ], [ 29.733084310344829, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8356, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.511146769230769 ], [ 29.733353793103447, -1.511416246153846 ], [ 29.733623275862069, -1.511416246153846 ], [ 29.733623275862069, -1.511146769230769 ], [ 29.733353793103447, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8357, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.511146769230769 ], [ 29.733623275862069, -1.511416246153846 ], [ 29.733892758620691, -1.511416246153846 ], [ 29.733892758620691, -1.511146769230769 ], [ 29.733623275862069, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8358, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.511146769230769 ], [ 29.733892758620691, -1.511416246153846 ], [ 29.734162241379309, -1.511416246153846 ], [ 29.734162241379309, -1.511146769230769 ], [ 29.733892758620691, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8359, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.511146769230769 ], [ 29.734162241379309, -1.511416246153846 ], [ 29.734431724137931, -1.511416246153846 ], [ 29.734431724137931, -1.511146769230769 ], [ 29.734162241379309, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8360, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.511146769230769 ], [ 29.734431724137931, -1.511416246153846 ], [ 29.734701206896553, -1.511416246153846 ], [ 29.734701206896553, -1.511146769230769 ], [ 29.734431724137931, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8361, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.511146769230769 ], [ 29.734701206896553, -1.511416246153846 ], [ 29.734970689655171, -1.511416246153846 ], [ 29.734970689655171, -1.511146769230769 ], [ 29.734701206896553, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8362, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.511146769230769 ], [ 29.734970689655171, -1.511416246153846 ], [ 29.735240172413793, -1.511416246153846 ], [ 29.735240172413793, -1.511146769230769 ], [ 29.734970689655171, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8363, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.511146769230769 ], [ 29.735240172413793, -1.511416246153846 ], [ 29.735509655172415, -1.511416246153846 ], [ 29.735509655172415, -1.511146769230769 ], [ 29.735240172413793, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8364, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.511146769230769 ], [ 29.735509655172415, -1.511416246153846 ], [ 29.735779137931036, -1.511416246153846 ], [ 29.735779137931036, -1.511146769230769 ], [ 29.735509655172415, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8365, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.511146769230769 ], [ 29.735779137931036, -1.511416246153846 ], [ 29.736318103448276, -1.511416246153846 ], [ 29.736318103448276, -1.511146769230769 ], [ 29.735779137931036, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8366, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.511146769230769 ], [ 29.736318103448276, -1.511416246153846 ], [ 29.736587586206898, -1.511416246153846 ], [ 29.736587586206898, -1.511146769230769 ], [ 29.736318103448276, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8367, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.511146769230769 ], [ 29.743863620689655, -1.511416246153846 ], [ 29.744672068965517, -1.511416246153846 ], [ 29.744672068965517, -1.511146769230769 ], [ 29.743863620689655, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8368, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.511146769230769 ], [ 29.744672068965517, -1.511416246153846 ], [ 29.744941551724139, -1.511416246153846 ], [ 29.744941551724139, -1.511146769230769 ], [ 29.744672068965517, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8369, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.509529907692308 ], [ 29.746019482758619, -1.509529907692308 ], [ 29.746019482758619, -1.511416246153846 ], [ 29.744941551724139, -1.511416246153846 ], [ 29.744941551724139, -1.511146769230769 ], [ 29.74575, -1.511146769230769 ], [ 29.74575, -1.509799384615385 ], [ 29.745211034482757, -1.509799384615385 ], [ 29.745211034482757, -1.509529907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8370, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.511146769230769 ], [ 29.75383448275862, -1.511416246153846 ], [ 29.754103965517242, -1.511416246153846 ], [ 29.754103965517242, -1.511146769230769 ], [ 29.75383448275862, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8371, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.511146769230769 ], [ 29.754103965517242, -1.511416246153846 ], [ 29.754373448275864, -1.511416246153846 ], [ 29.754373448275864, -1.511146769230769 ], [ 29.754103965517242, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8372, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.511146769230769 ], [ 29.754373448275864, -1.511416246153846 ], [ 29.754912413793104, -1.511416246153846 ], [ 29.754912413793104, -1.511146769230769 ], [ 29.754373448275864, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8373, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.511146769230769 ], [ 29.754912413793104, -1.511416246153846 ], [ 29.755181896551726, -1.511416246153846 ], [ 29.755181896551726, -1.511146769230769 ], [ 29.754912413793104, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8374, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.511146769230769 ], [ 29.755181896551726, -1.511416246153846 ], [ 29.755451379310344, -1.511416246153846 ], [ 29.755451379310344, -1.511146769230769 ], [ 29.755181896551726, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8375, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.511146769230769 ], [ 29.755451379310344, -1.511416246153846 ], [ 29.755720862068966, -1.511416246153846 ], [ 29.755720862068966, -1.511146769230769 ], [ 29.755451379310344, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8376, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.511146769230769 ], [ 29.755720862068966, -1.511416246153846 ], [ 29.755990344827588, -1.511416246153846 ], [ 29.755990344827588, -1.511146769230769 ], [ 29.755720862068966, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8377, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.511146769230769 ], [ 29.755990344827588, -1.511416246153846 ], [ 29.756259827586206, -1.511416246153846 ], [ 29.756259827586206, -1.511146769230769 ], [ 29.755990344827588, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8378, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.511146769230769 ], [ 29.756259827586206, -1.511416246153846 ], [ 29.756798793103449, -1.511416246153846 ], [ 29.756798793103449, -1.511146769230769 ], [ 29.756259827586206, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8379, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.511146769230769 ], [ 29.756798793103449, -1.511416246153846 ], [ 29.757068275862068, -1.511416246153846 ], [ 29.757068275862068, -1.511146769230769 ], [ 29.756798793103449, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8380, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.511146769230769 ], [ 29.757068275862068, -1.511416246153846 ], [ 29.75733775862069, -1.511416246153846 ], [ 29.75733775862069, -1.511146769230769 ], [ 29.757068275862068, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8381, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.511146769230769 ], [ 29.75733775862069, -1.511416246153846 ], [ 29.757607241379311, -1.511416246153846 ], [ 29.757607241379311, -1.511146769230769 ], [ 29.75733775862069, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8382, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.511146769230769 ], [ 29.76138, -1.511416246153846 ], [ 29.761649482758621, -1.511416246153846 ], [ 29.761649482758621, -1.511146769230769 ], [ 29.76138, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8383, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.511146769230769 ], [ 29.761649482758621, -1.511416246153846 ], [ 29.761918965517243, -1.511416246153846 ], [ 29.761918965517243, -1.511146769230769 ], [ 29.761649482758621, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8384, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.511146769230769 ], [ 29.761918965517243, -1.511416246153846 ], [ 29.762188448275861, -1.511416246153846 ], [ 29.762188448275861, -1.511146769230769 ], [ 29.761918965517243, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8385, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.511146769230769 ], [ 29.762727413793105, -1.511416246153846 ], [ 29.762996896551723, -1.511416246153846 ], [ 29.762996896551723, -1.511146769230769 ], [ 29.762727413793105, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8386, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.511146769230769 ], [ 29.762996896551723, -1.511416246153846 ], [ 29.763266379310345, -1.511416246153846 ], [ 29.763266379310345, -1.511146769230769 ], [ 29.762996896551723, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8387, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.511146769230769 ], [ 29.763266379310345, -1.511416246153846 ], [ 29.763535862068967, -1.511416246153846 ], [ 29.763535862068967, -1.511146769230769 ], [ 29.763266379310345, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8388, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.511146769230769 ], [ 29.763535862068967, -1.511416246153846 ], [ 29.763805344827588, -1.511416246153846 ], [ 29.763805344827588, -1.511146769230769 ], [ 29.763535862068967, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8389, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.511146769230769 ], [ 29.763805344827588, -1.511416246153846 ], [ 29.764074827586207, -1.511416246153846 ], [ 29.764074827586207, -1.511146769230769 ], [ 29.763805344827588, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8390, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.511146769230769 ], [ 29.764074827586207, -1.511416246153846 ], [ 29.764344310344828, -1.511416246153846 ], [ 29.764344310344828, -1.511146769230769 ], [ 29.764074827586207, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8391, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.511146769230769 ], [ 29.764344310344828, -1.511416246153846 ], [ 29.76461379310345, -1.511416246153846 ], [ 29.76461379310345, -1.511146769230769 ], [ 29.764344310344828, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8392, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.511146769230769 ], [ 29.76461379310345, -1.511416246153846 ], [ 29.764883275862069, -1.511416246153846 ], [ 29.764883275862069, -1.511146769230769 ], [ 29.76461379310345, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8393, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.511146769230769 ], [ 29.764883275862069, -1.511416246153846 ], [ 29.76515275862069, -1.511416246153846 ], [ 29.76515275862069, -1.511146769230769 ], [ 29.764883275862069, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8394, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.511146769230769 ], [ 29.76515275862069, -1.511416246153846 ], [ 29.765422241379312, -1.511416246153846 ], [ 29.765422241379312, -1.511146769230769 ], [ 29.76515275862069, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8395, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.511146769230769 ], [ 29.765422241379312, -1.511416246153846 ], [ 29.765961206896552, -1.511416246153846 ], [ 29.765961206896552, -1.511146769230769 ], [ 29.765422241379312, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8396, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.511146769230769 ], [ 29.765961206896552, -1.511416246153846 ], [ 29.766230689655174, -1.511416246153846 ], [ 29.766230689655174, -1.511146769230769 ], [ 29.765961206896552, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8397, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.511146769230769 ], [ 29.766230689655174, -1.511416246153846 ], [ 29.766500172413792, -1.511416246153846 ], [ 29.766500172413792, -1.511146769230769 ], [ 29.766230689655174, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8398, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.511146769230769 ], [ 29.766500172413792, -1.511416246153846 ], [ 29.766769655172414, -1.511416246153846 ], [ 29.766769655172414, -1.511146769230769 ], [ 29.766500172413792, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8399, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.511146769230769 ], [ 29.766769655172414, -1.511416246153846 ], [ 29.767308620689654, -1.511416246153846 ], [ 29.767308620689654, -1.511146769230769 ], [ 29.766769655172414, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8400, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.511146769230769 ], [ 29.767308620689654, -1.511416246153846 ], [ 29.767578103448276, -1.511416246153846 ], [ 29.767578103448276, -1.511146769230769 ], [ 29.767308620689654, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8401, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.511146769230769 ], [ 29.767578103448276, -1.511416246153846 ], [ 29.767847586206898, -1.511416246153846 ], [ 29.767847586206898, -1.511146769230769 ], [ 29.767578103448276, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8402, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.510068861538461 ], [ 29.72661672413793, -1.512224676923077 ], [ 29.726886206896552, -1.512224676923077 ], [ 29.726886206896552, -1.510068861538461 ], [ 29.72661672413793, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8403, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.510068861538461 ], [ 29.727425172413792, -1.512224676923077 ], [ 29.727694655172414, -1.512224676923077 ], [ 29.727694655172414, -1.510068861538461 ], [ 29.727425172413792, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8404, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.508721476923077 ], [ 29.739282413793102, -1.508721476923077 ], [ 29.739282413793102, -1.510068861538461 ], [ 29.739012931034484, -1.510068861538461 ], [ 29.739012931034484, -1.511685723076923 ], [ 29.738743448275862, -1.511685723076923 ], [ 29.738743448275862, -1.510338338461538 ], [ 29.738204482758622, -1.510338338461538 ], [ 29.738204482758622, -1.510068861538461 ], [ 29.738743448275862, -1.510068861538461 ], [ 29.738743448275862, -1.509260430769231 ], [ 29.739012931034484, -1.509260430769231 ], [ 29.739012931034484, -1.508721476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8405, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.510068861538461 ], [ 29.739012931034484, -1.511685723076923 ], [ 29.739282413793102, -1.511685723076923 ], [ 29.739282413793102, -1.510068861538461 ], [ 29.739012931034484, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8406, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.510068861538461 ], [ 29.743324655172415, -1.5119552 ], [ 29.743594137931034, -1.5119552 ], [ 29.743594137931034, -1.512224676923077 ], [ 29.743863620689655, -1.512224676923077 ], [ 29.743863620689655, -1.511416246153846 ], [ 29.743594137931034, -1.511416246153846 ], [ 29.743594137931034, -1.510068861538461 ], [ 29.743324655172415, -1.510068861538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8407, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.510338338461538 ], [ 29.72607775862069, -1.5119552 ], [ 29.726347241379312, -1.5119552 ], [ 29.726347241379312, -1.510338338461538 ], [ 29.72607775862069, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8408, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.510338338461538 ], [ 29.726347241379312, -1.5119552 ], [ 29.72661672413793, -1.5119552 ], [ 29.72661672413793, -1.510338338461538 ], [ 29.726347241379312, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8409, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.510338338461538 ], [ 29.727155689655174, -1.5119552 ], [ 29.727425172413792, -1.5119552 ], [ 29.727425172413792, -1.510338338461538 ], [ 29.727155689655174, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8410, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.510338338461538 ], [ 29.727964137931036, -1.5119552 ], [ 29.728233620689654, -1.5119552 ], [ 29.728233620689654, -1.510338338461538 ], [ 29.727964137931036, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8411, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.510338338461538 ], [ 29.728233620689654, -1.5119552 ], [ 29.728503103448276, -1.5119552 ], [ 29.728503103448276, -1.510338338461538 ], [ 29.728233620689654, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8412, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.510338338461538 ], [ 29.743055172413793, -1.512224676923077 ], [ 29.743324655172415, -1.512224676923077 ], [ 29.743324655172415, -1.510338338461538 ], [ 29.743055172413793, -1.510338338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8413, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.510607815384615 ], [ 29.726886206896552, -1.511685723076923 ], [ 29.727155689655174, -1.511685723076923 ], [ 29.727155689655174, -1.510607815384615 ], [ 29.726886206896552, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8414, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.510607815384615 ], [ 29.727694655172414, -1.511685723076923 ], [ 29.727964137931036, -1.511685723076923 ], [ 29.727964137931036, -1.510607815384615 ], [ 29.727694655172414, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8415, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.510607815384615 ], [ 29.728503103448276, -1.511685723076923 ], [ 29.728772586206897, -1.511685723076923 ], [ 29.728772586206897, -1.510607815384615 ], [ 29.728503103448276, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8416, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.510607815384615 ], [ 29.728772586206897, -1.511685723076923 ], [ 29.729042068965516, -1.511685723076923 ], [ 29.729042068965516, -1.510607815384615 ], [ 29.728772586206897, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8417, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.510607815384615 ], [ 29.729042068965516, -1.511685723076923 ], [ 29.729311551724138, -1.511685723076923 ], [ 29.729311551724138, -1.510607815384615 ], [ 29.729042068965516, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8418, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.510607815384615 ], [ 29.729850517241381, -1.511685723076923 ], [ 29.73012, -1.511685723076923 ], [ 29.73012, -1.510607815384615 ], [ 29.729850517241381, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8419, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.510607815384615 ], [ 29.73012, -1.511685723076923 ], [ 29.730389482758621, -1.511685723076923 ], [ 29.730389482758621, -1.510607815384615 ], [ 29.73012, -1.510607815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8421, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.510877292307692 ], [ 29.736587586206898, -1.511146769230769 ], [ 29.737935, -1.511146769230769 ], [ 29.737935, -1.511685723076923 ], [ 29.738204482758622, -1.511685723076923 ], [ 29.738204482758622, -1.510877292307692 ], [ 29.736587586206898, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8422, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.510877292307692 ], [ 29.760302068965519, -1.511685723076923 ], [ 29.760571551724137, -1.511685723076923 ], [ 29.760571551724137, -1.510877292307692 ], [ 29.760302068965519, -1.510877292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8423, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.511146769230769 ], [ 29.736587586206898, -1.511416246153846 ], [ 29.737665517241378, -1.511416246153846 ], [ 29.737665517241378, -1.511685723076923 ], [ 29.737935, -1.511685723076923 ], [ 29.737935, -1.511146769230769 ], [ 29.736587586206898, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8424, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.511146769230769 ], [ 29.757607241379311, -1.511685723076923 ], [ 29.757876724137933, -1.511685723076923 ], [ 29.757876724137933, -1.511146769230769 ], [ 29.757607241379311, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8425, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.511146769230769 ], [ 29.758415689655173, -1.511685723076923 ], [ 29.758685172413795, -1.511685723076923 ], [ 29.758685172413795, -1.511146769230769 ], [ 29.758415689655173, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8426, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.511146769230769 ], [ 29.759493620689657, -1.5119552 ], [ 29.759763103448275, -1.5119552 ], [ 29.759763103448275, -1.511146769230769 ], [ 29.759493620689657, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8427, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.511146769230769 ], [ 29.762188448275861, -1.511685723076923 ], [ 29.762457931034483, -1.511685723076923 ], [ 29.762457931034483, -1.511146769230769 ], [ 29.762188448275861, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8428, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.511416246153846 ], [ 29.712603620689656, -1.511685723076923 ], [ 29.712873103448278, -1.511685723076923 ], [ 29.712873103448278, -1.511416246153846 ], [ 29.712603620689656, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8429, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.511416246153846 ], [ 29.712873103448278, -1.511685723076923 ], [ 29.713142586206896, -1.511685723076923 ], [ 29.713142586206896, -1.511416246153846 ], [ 29.712873103448278, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8430, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.511416246153846 ], [ 29.713142586206896, -1.511685723076923 ], [ 29.713412068965518, -1.511685723076923 ], [ 29.713412068965518, -1.511416246153846 ], [ 29.713142586206896, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8431, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713412068965518, -1.511416246153846 ], [ 29.713412068965518, -1.511685723076923 ], [ 29.71449, -1.511685723076923 ], [ 29.71449, -1.511416246153846 ], [ 29.713412068965518, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8432, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71449, -1.511416246153846 ], [ 29.71449, -1.511685723076923 ], [ 29.71475948275862, -1.511685723076923 ], [ 29.71475948275862, -1.511416246153846 ], [ 29.71449, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8433, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71475948275862, -1.511416246153846 ], [ 29.71475948275862, -1.511685723076923 ], [ 29.715567931034482, -1.511685723076923 ], [ 29.715567931034482, -1.511416246153846 ], [ 29.71475948275862, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8434, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715837413793103, -1.511416246153846 ], [ 29.715837413793103, -1.511685723076923 ], [ 29.716106896551725, -1.511685723076923 ], [ 29.716106896551725, -1.511416246153846 ], [ 29.715837413793103, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8435, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716106896551725, -1.511416246153846 ], [ 29.716106896551725, -1.511685723076923 ], [ 29.716915344827587, -1.511685723076923 ], [ 29.716915344827587, -1.511416246153846 ], [ 29.716106896551725, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8436, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.511416246153846 ], [ 29.716915344827587, -1.511685723076923 ], [ 29.717454310344827, -1.511685723076923 ], [ 29.717454310344827, -1.511416246153846 ], [ 29.716915344827587, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8437, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.511416246153846 ], [ 29.717454310344827, -1.511685723076923 ], [ 29.717723793103449, -1.511685723076923 ], [ 29.717723793103449, -1.511416246153846 ], [ 29.717454310344827, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8438, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.511416246153846 ], [ 29.717723793103449, -1.511685723076923 ], [ 29.717993275862067, -1.511685723076923 ], [ 29.717993275862067, -1.511416246153846 ], [ 29.717723793103449, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8439, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.511416246153846 ], [ 29.717993275862067, -1.511685723076923 ], [ 29.718262758620689, -1.511685723076923 ], [ 29.718262758620689, -1.511416246153846 ], [ 29.717993275862067, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8440, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.511416246153846 ], [ 29.718262758620689, -1.511685723076923 ], [ 29.718532241379311, -1.511685723076923 ], [ 29.718532241379311, -1.511416246153846 ], [ 29.718262758620689, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8441, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.511416246153846 ], [ 29.718532241379311, -1.511685723076923 ], [ 29.719340689655173, -1.511685723076923 ], [ 29.719340689655173, -1.511416246153846 ], [ 29.718532241379311, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8442, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.511416246153846 ], [ 29.719340689655173, -1.511685723076923 ], [ 29.719610172413795, -1.511685723076923 ], [ 29.719610172413795, -1.511416246153846 ], [ 29.719340689655173, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8443, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.511416246153846 ], [ 29.719610172413795, -1.511685723076923 ], [ 29.720149137931035, -1.511685723076923 ], [ 29.720149137931035, -1.511416246153846 ], [ 29.719610172413795, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8444, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.511416246153846 ], [ 29.720149137931035, -1.511685723076923 ], [ 29.720688103448275, -1.511685723076923 ], [ 29.720688103448275, -1.511416246153846 ], [ 29.720149137931035, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8445, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.511416246153846 ], [ 29.720688103448275, -1.511685723076923 ], [ 29.720957586206897, -1.511685723076923 ], [ 29.720957586206897, -1.511416246153846 ], [ 29.720688103448275, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8446, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.511416246153846 ], [ 29.720957586206897, -1.511685723076923 ], [ 29.721227068965518, -1.511685723076923 ], [ 29.721227068965518, -1.511416246153846 ], [ 29.720957586206897, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8447, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.511416246153846 ], [ 29.721227068965518, -1.511685723076923 ], [ 29.721496551724137, -1.511685723076923 ], [ 29.721496551724137, -1.511416246153846 ], [ 29.721227068965518, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8448, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.511146769230769 ], [ 29.722305, -1.511146769230769 ], [ 29.722305, -1.511416246153846 ], [ 29.72257448275862, -1.511416246153846 ], [ 29.72257448275862, -1.511685723076923 ], [ 29.721766034482759, -1.511685723076923 ], [ 29.721766034482759, -1.511416246153846 ], [ 29.72203551724138, -1.511416246153846 ], [ 29.72203551724138, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8449, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.511416246153846 ], [ 29.72257448275862, -1.511685723076923 ], [ 29.723113448275861, -1.511685723076923 ], [ 29.723113448275861, -1.511416246153846 ], [ 29.72257448275862, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8450, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.511416246153846 ], [ 29.723113448275861, -1.511685723076923 ], [ 29.723382931034482, -1.511685723076923 ], [ 29.723382931034482, -1.511416246153846 ], [ 29.723113448275861, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8451, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.511416246153846 ], [ 29.723382931034482, -1.511685723076923 ], [ 29.723652413793104, -1.511685723076923 ], [ 29.723652413793104, -1.511416246153846 ], [ 29.723382931034482, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8452, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.511416246153846 ], [ 29.723652413793104, -1.511685723076923 ], [ 29.723921896551722, -1.511685723076923 ], [ 29.723921896551722, -1.511416246153846 ], [ 29.723652413793104, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8453, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.511416246153846 ], [ 29.723921896551722, -1.511685723076923 ], [ 29.724191379310344, -1.511685723076923 ], [ 29.724191379310344, -1.511416246153846 ], [ 29.723921896551722, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8454, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.511416246153846 ], [ 29.724191379310344, -1.511685723076923 ], [ 29.724460862068966, -1.511685723076923 ], [ 29.724460862068966, -1.511416246153846 ], [ 29.724191379310344, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8455, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.511416246153846 ], [ 29.724460862068966, -1.511685723076923 ], [ 29.724730344827588, -1.511685723076923 ], [ 29.724730344827588, -1.511416246153846 ], [ 29.724460862068966, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8456, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.511416246153846 ], [ 29.724730344827588, -1.511685723076923 ], [ 29.724999827586206, -1.511685723076923 ], [ 29.724999827586206, -1.511416246153846 ], [ 29.724730344827588, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8457, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.511416246153846 ], [ 29.724999827586206, -1.511685723076923 ], [ 29.725269310344828, -1.511685723076923 ], [ 29.725269310344828, -1.511416246153846 ], [ 29.724999827586206, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8458, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.511416246153846 ], [ 29.725269310344828, -1.511685723076923 ], [ 29.72553879310345, -1.511685723076923 ], [ 29.72553879310345, -1.511416246153846 ], [ 29.725269310344828, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8459, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.511416246153846 ], [ 29.72553879310345, -1.511685723076923 ], [ 29.725808275862068, -1.511685723076923 ], [ 29.725808275862068, -1.511416246153846 ], [ 29.72553879310345, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8460, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.511416246153846 ], [ 29.725808275862068, -1.5119552 ], [ 29.72607775862069, -1.5119552 ], [ 29.72607775862069, -1.511416246153846 ], [ 29.725808275862068, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8461, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.511416246153846 ], [ 29.729311551724138, -1.5119552 ], [ 29.729850517241381, -1.5119552 ], [ 29.729850517241381, -1.511416246153846 ], [ 29.729311551724138, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8462, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.511416246153846 ], [ 29.730389482758621, -1.511685723076923 ], [ 29.730658965517243, -1.511685723076923 ], [ 29.730658965517243, -1.511416246153846 ], [ 29.730389482758621, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8463, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.511416246153846 ], [ 29.730658965517243, -1.511685723076923 ], [ 29.730928448275861, -1.511685723076923 ], [ 29.730928448275861, -1.511416246153846 ], [ 29.730658965517243, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8464, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.511416246153846 ], [ 29.732275862068967, -1.511685723076923 ], [ 29.732545344827585, -1.511685723076923 ], [ 29.732545344827585, -1.511416246153846 ], [ 29.732275862068967, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8465, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.511416246153846 ], [ 29.732545344827585, -1.511685723076923 ], [ 29.732814827586207, -1.511685723076923 ], [ 29.732814827586207, -1.511416246153846 ], [ 29.732545344827585, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8466, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.511416246153846 ], [ 29.732814827586207, -1.512494153846154 ], [ 29.733084310344829, -1.512494153846154 ], [ 29.733084310344829, -1.511685723076923 ], [ 29.733353793103447, -1.511685723076923 ], [ 29.733353793103447, -1.511416246153846 ], [ 29.732814827586207, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8467, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.511416246153846 ], [ 29.733353793103447, -1.511685723076923 ], [ 29.733623275862069, -1.511685723076923 ], [ 29.733623275862069, -1.511416246153846 ], [ 29.733353793103447, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8468, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.511416246153846 ], [ 29.733623275862069, -1.511685723076923 ], [ 29.733892758620691, -1.511685723076923 ], [ 29.733892758620691, -1.511416246153846 ], [ 29.733623275862069, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8469, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.511416246153846 ], [ 29.733892758620691, -1.511685723076923 ], [ 29.734162241379309, -1.511685723076923 ], [ 29.734162241379309, -1.511416246153846 ], [ 29.733892758620691, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8470, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.511416246153846 ], [ 29.734162241379309, -1.511685723076923 ], [ 29.734431724137931, -1.511685723076923 ], [ 29.734431724137931, -1.511416246153846 ], [ 29.734162241379309, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8471, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.511416246153846 ], [ 29.734431724137931, -1.511685723076923 ], [ 29.734701206896553, -1.511685723076923 ], [ 29.734701206896553, -1.511416246153846 ], [ 29.734431724137931, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8472, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.511416246153846 ], [ 29.734701206896553, -1.511685723076923 ], [ 29.734970689655171, -1.511685723076923 ], [ 29.734970689655171, -1.511416246153846 ], [ 29.734701206896553, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8473, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.511416246153846 ], [ 29.734970689655171, -1.511685723076923 ], [ 29.735240172413793, -1.511685723076923 ], [ 29.735240172413793, -1.511416246153846 ], [ 29.734970689655171, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8474, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.511416246153846 ], [ 29.735240172413793, -1.511685723076923 ], [ 29.736048620689655, -1.511685723076923 ], [ 29.736048620689655, -1.511416246153846 ], [ 29.735240172413793, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8475, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.511416246153846 ], [ 29.736048620689655, -1.511685723076923 ], [ 29.736318103448276, -1.511685723076923 ], [ 29.736318103448276, -1.511416246153846 ], [ 29.736048620689655, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8476, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.511416246153846 ], [ 29.736318103448276, -1.511685723076923 ], [ 29.736587586206898, -1.511685723076923 ], [ 29.736587586206898, -1.511416246153846 ], [ 29.736318103448276, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8477, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.511416246153846 ], [ 29.736587586206898, -1.511685723076923 ], [ 29.737665517241378, -1.511685723076923 ], [ 29.737665517241378, -1.511416246153846 ], [ 29.736587586206898, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8478, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.511416246153846 ], [ 29.738204482758622, -1.5119552 ], [ 29.738743448275862, -1.5119552 ], [ 29.738743448275862, -1.511416246153846 ], [ 29.738204482758622, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8479, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.511416246153846 ], [ 29.739282413793102, -1.511685723076923 ], [ 29.739551896551724, -1.511685723076923 ], [ 29.739551896551724, -1.511416246153846 ], [ 29.739282413793102, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8480, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.511416246153846 ], [ 29.739551896551724, -1.511685723076923 ], [ 29.739821379310346, -1.511685723076923 ], [ 29.739821379310346, -1.511416246153846 ], [ 29.739551896551724, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8481, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.511416246153846 ], [ 29.743863620689655, -1.511685723076923 ], [ 29.744133103448277, -1.511685723076923 ], [ 29.744133103448277, -1.511416246153846 ], [ 29.743863620689655, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8482, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.511416246153846 ], [ 29.744133103448277, -1.511685723076923 ], [ 29.744941551724139, -1.511685723076923 ], [ 29.744941551724139, -1.511416246153846 ], [ 29.744133103448277, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8483, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.511416246153846 ], [ 29.754103965517242, -1.511685723076923 ], [ 29.754642931034482, -1.511685723076923 ], [ 29.754642931034482, -1.511416246153846 ], [ 29.754103965517242, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8484, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.511416246153846 ], [ 29.754642931034482, -1.511685723076923 ], [ 29.754912413793104, -1.511685723076923 ], [ 29.754912413793104, -1.511416246153846 ], [ 29.754642931034482, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8485, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.511416246153846 ], [ 29.754912413793104, -1.511685723076923 ], [ 29.755181896551726, -1.511685723076923 ], [ 29.755181896551726, -1.511416246153846 ], [ 29.754912413793104, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8486, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.511416246153846 ], [ 29.755181896551726, -1.511685723076923 ], [ 29.755451379310344, -1.511685723076923 ], [ 29.755451379310344, -1.511416246153846 ], [ 29.755181896551726, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8487, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.511416246153846 ], [ 29.755451379310344, -1.511685723076923 ], [ 29.755720862068966, -1.511685723076923 ], [ 29.755720862068966, -1.511416246153846 ], [ 29.755451379310344, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8488, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.511416246153846 ], [ 29.755720862068966, -1.5119552 ], [ 29.755990344827588, -1.5119552 ], [ 29.755990344827588, -1.511416246153846 ], [ 29.755720862068966, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8489, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.511416246153846 ], [ 29.755990344827588, -1.511685723076923 ], [ 29.756259827586206, -1.511685723076923 ], [ 29.756259827586206, -1.511416246153846 ], [ 29.755990344827588, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8490, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.511416246153846 ], [ 29.756259827586206, -1.511685723076923 ], [ 29.756798793103449, -1.511685723076923 ], [ 29.756798793103449, -1.511416246153846 ], [ 29.756259827586206, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8491, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.511416246153846 ], [ 29.756798793103449, -1.511685723076923 ], [ 29.757068275862068, -1.511685723076923 ], [ 29.757068275862068, -1.511416246153846 ], [ 29.756798793103449, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8492, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.511416246153846 ], [ 29.761110517241381, -1.511685723076923 ], [ 29.76138, -1.511685723076923 ], [ 29.76138, -1.511416246153846 ], [ 29.761110517241381, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8493, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.511416246153846 ], [ 29.76138, -1.511685723076923 ], [ 29.761649482758621, -1.511685723076923 ], [ 29.761649482758621, -1.511416246153846 ], [ 29.76138, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8494, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.511416246153846 ], [ 29.761649482758621, -1.511685723076923 ], [ 29.761918965517243, -1.511685723076923 ], [ 29.761918965517243, -1.511416246153846 ], [ 29.761649482758621, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8495, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.511416246153846 ], [ 29.761918965517243, -1.511685723076923 ], [ 29.762188448275861, -1.511685723076923 ], [ 29.762188448275861, -1.511416246153846 ], [ 29.761918965517243, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8496, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.511416246153846 ], [ 29.762457931034483, -1.511685723076923 ], [ 29.762727413793105, -1.511685723076923 ], [ 29.762727413793105, -1.511416246153846 ], [ 29.762457931034483, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8497, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.511416246153846 ], [ 29.762727413793105, -1.511685723076923 ], [ 29.762996896551723, -1.511685723076923 ], [ 29.762996896551723, -1.511416246153846 ], [ 29.762727413793105, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8498, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.511416246153846 ], [ 29.762996896551723, -1.511685723076923 ], [ 29.763266379310345, -1.511685723076923 ], [ 29.763266379310345, -1.511416246153846 ], [ 29.762996896551723, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8499, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.511416246153846 ], [ 29.763266379310345, -1.511685723076923 ], [ 29.763535862068967, -1.511685723076923 ], [ 29.763535862068967, -1.511416246153846 ], [ 29.763266379310345, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8500, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.511416246153846 ], [ 29.763535862068967, -1.511685723076923 ], [ 29.763805344827588, -1.511685723076923 ], [ 29.763805344827588, -1.511416246153846 ], [ 29.763535862068967, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8501, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.511416246153846 ], [ 29.763805344827588, -1.511685723076923 ], [ 29.764074827586207, -1.511685723076923 ], [ 29.764074827586207, -1.511416246153846 ], [ 29.763805344827588, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8502, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.511416246153846 ], [ 29.764074827586207, -1.511685723076923 ], [ 29.764344310344828, -1.511685723076923 ], [ 29.764344310344828, -1.511416246153846 ], [ 29.764074827586207, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8503, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.511416246153846 ], [ 29.764344310344828, -1.511685723076923 ], [ 29.76461379310345, -1.511685723076923 ], [ 29.76461379310345, -1.511416246153846 ], [ 29.764344310344828, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8504, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.511416246153846 ], [ 29.76461379310345, -1.511685723076923 ], [ 29.764883275862069, -1.511685723076923 ], [ 29.764883275862069, -1.511416246153846 ], [ 29.76461379310345, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8505, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.511416246153846 ], [ 29.764883275862069, -1.511685723076923 ], [ 29.76515275862069, -1.511685723076923 ], [ 29.76515275862069, -1.511416246153846 ], [ 29.764883275862069, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8506, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.511416246153846 ], [ 29.76515275862069, -1.511685723076923 ], [ 29.765422241379312, -1.511685723076923 ], [ 29.765422241379312, -1.511416246153846 ], [ 29.76515275862069, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8507, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.511416246153846 ], [ 29.765422241379312, -1.511685723076923 ], [ 29.765961206896552, -1.511685723076923 ], [ 29.765961206896552, -1.511416246153846 ], [ 29.765422241379312, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8508, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.511416246153846 ], [ 29.765961206896552, -1.511685723076923 ], [ 29.766230689655174, -1.511685723076923 ], [ 29.766230689655174, -1.511416246153846 ], [ 29.765961206896552, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8509, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.511416246153846 ], [ 29.766230689655174, -1.511685723076923 ], [ 29.766500172413792, -1.511685723076923 ], [ 29.766500172413792, -1.511416246153846 ], [ 29.766230689655174, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8510, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.511416246153846 ], [ 29.766500172413792, -1.511685723076923 ], [ 29.767039137931036, -1.511685723076923 ], [ 29.767039137931036, -1.511416246153846 ], [ 29.766500172413792, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8511, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.511416246153846 ], [ 29.767039137931036, -1.511685723076923 ], [ 29.767308620689654, -1.511685723076923 ], [ 29.767308620689654, -1.511416246153846 ], [ 29.767039137931036, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8512, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.511416246153846 ], [ 29.767308620689654, -1.512763630769231 ], [ 29.767578103448276, -1.512763630769231 ], [ 29.767578103448276, -1.511416246153846 ], [ 29.767308620689654, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8513, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.511685723076923 ], [ 29.712603620689656, -1.5119552 ], [ 29.712873103448278, -1.5119552 ], [ 29.712873103448278, -1.511685723076923 ], [ 29.712603620689656, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8514, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.511685723076923 ], [ 29.712873103448278, -1.5119552 ], [ 29.713142586206896, -1.5119552 ], [ 29.713142586206896, -1.511685723076923 ], [ 29.712873103448278, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8515, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.511685723076923 ], [ 29.713142586206896, -1.5119552 ], [ 29.71475948275862, -1.5119552 ], [ 29.71475948275862, -1.511685723076923 ], [ 29.713142586206896, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8516, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.511685723076923 ], [ 29.717184827586205, -1.5119552 ], [ 29.717454310344827, -1.5119552 ], [ 29.717454310344827, -1.511685723076923 ], [ 29.717184827586205, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8517, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.511685723076923 ], [ 29.717454310344827, -1.5119552 ], [ 29.717723793103449, -1.5119552 ], [ 29.717723793103449, -1.511685723076923 ], [ 29.717454310344827, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8518, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.511685723076923 ], [ 29.717723793103449, -1.5119552 ], [ 29.717993275862067, -1.5119552 ], [ 29.717993275862067, -1.511685723076923 ], [ 29.717723793103449, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8519, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.511685723076923 ], [ 29.717993275862067, -1.5119552 ], [ 29.718262758620689, -1.5119552 ], [ 29.718262758620689, -1.511685723076923 ], [ 29.717993275862067, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8520, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.511685723076923 ], [ 29.718262758620689, -1.5119552 ], [ 29.718532241379311, -1.5119552 ], [ 29.718532241379311, -1.511685723076923 ], [ 29.718262758620689, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8521, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.511685723076923 ], [ 29.718532241379311, -1.5119552 ], [ 29.718801724137933, -1.5119552 ], [ 29.718801724137933, -1.511685723076923 ], [ 29.718532241379311, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8522, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.511685723076923 ], [ 29.718801724137933, -1.5119552 ], [ 29.719071206896551, -1.5119552 ], [ 29.719071206896551, -1.511685723076923 ], [ 29.718801724137933, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8523, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.511685723076923 ], [ 29.719071206896551, -1.512224676923077 ], [ 29.719340689655173, -1.512224676923077 ], [ 29.719340689655173, -1.511685723076923 ], [ 29.719071206896551, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8524, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.511685723076923 ], [ 29.719340689655173, -1.5119552 ], [ 29.719610172413795, -1.5119552 ], [ 29.719610172413795, -1.511685723076923 ], [ 29.719340689655173, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8525, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.511685723076923 ], [ 29.719610172413795, -1.5119552 ], [ 29.719879655172413, -1.5119552 ], [ 29.719879655172413, -1.511685723076923 ], [ 29.719610172413795, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8526, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.511685723076923 ], [ 29.719879655172413, -1.5119552 ], [ 29.720688103448275, -1.5119552 ], [ 29.720688103448275, -1.511685723076923 ], [ 29.719879655172413, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8527, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.511685723076923 ], [ 29.720688103448275, -1.512224676923077 ], [ 29.720957586206897, -1.512224676923077 ], [ 29.720957586206897, -1.511685723076923 ], [ 29.720688103448275, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8528, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.511416246153846 ], [ 29.721766034482759, -1.511416246153846 ], [ 29.721766034482759, -1.511685723076923 ], [ 29.722843965517242, -1.511685723076923 ], [ 29.722843965517242, -1.5119552 ], [ 29.720957586206897, -1.5119552 ], [ 29.720957586206897, -1.511685723076923 ], [ 29.721496551724137, -1.511685723076923 ], [ 29.721496551724137, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8529, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.511685723076923 ], [ 29.722843965517242, -1.5119552 ], [ 29.723113448275861, -1.5119552 ], [ 29.723113448275861, -1.511685723076923 ], [ 29.722843965517242, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8530, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.511685723076923 ], [ 29.723113448275861, -1.5119552 ], [ 29.723382931034482, -1.5119552 ], [ 29.723382931034482, -1.511685723076923 ], [ 29.723113448275861, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8531, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.511685723076923 ], [ 29.723382931034482, -1.5119552 ], [ 29.723652413793104, -1.5119552 ], [ 29.723652413793104, -1.511685723076923 ], [ 29.723382931034482, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8532, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.511685723076923 ], [ 29.723652413793104, -1.5119552 ], [ 29.723921896551722, -1.5119552 ], [ 29.723921896551722, -1.511685723076923 ], [ 29.723652413793104, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8533, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.511685723076923 ], [ 29.723921896551722, -1.5119552 ], [ 29.724191379310344, -1.5119552 ], [ 29.724191379310344, -1.511685723076923 ], [ 29.723921896551722, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8534, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.511685723076923 ], [ 29.724191379310344, -1.5119552 ], [ 29.724460862068966, -1.5119552 ], [ 29.724460862068966, -1.511685723076923 ], [ 29.724191379310344, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8535, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.511685723076923 ], [ 29.724460862068966, -1.5119552 ], [ 29.724730344827588, -1.5119552 ], [ 29.724730344827588, -1.511685723076923 ], [ 29.724460862068966, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8536, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.511685723076923 ], [ 29.724730344827588, -1.5119552 ], [ 29.724999827586206, -1.5119552 ], [ 29.724999827586206, -1.511685723076923 ], [ 29.724730344827588, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8537, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.511685723076923 ], [ 29.724999827586206, -1.5119552 ], [ 29.725269310344828, -1.5119552 ], [ 29.725269310344828, -1.511685723076923 ], [ 29.724999827586206, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8538, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.511685723076923 ], [ 29.725269310344828, -1.5119552 ], [ 29.72553879310345, -1.5119552 ], [ 29.72553879310345, -1.511685723076923 ], [ 29.725269310344828, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8539, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.511685723076923 ], [ 29.72553879310345, -1.5119552 ], [ 29.725808275862068, -1.5119552 ], [ 29.725808275862068, -1.511685723076923 ], [ 29.72553879310345, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8540, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.511685723076923 ], [ 29.726886206896552, -1.512224676923077 ], [ 29.727155689655174, -1.512224676923077 ], [ 29.727155689655174, -1.511685723076923 ], [ 29.726886206896552, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8541, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.511685723076923 ], [ 29.727694655172414, -1.512224676923077 ], [ 29.727964137931036, -1.512224676923077 ], [ 29.727964137931036, -1.511685723076923 ], [ 29.727694655172414, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8542, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.511685723076923 ], [ 29.728503103448276, -1.5119552 ], [ 29.728772586206897, -1.5119552 ], [ 29.728772586206897, -1.511685723076923 ], [ 29.728503103448276, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8543, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.511685723076923 ], [ 29.728772586206897, -1.5119552 ], [ 29.729042068965516, -1.5119552 ], [ 29.729042068965516, -1.511685723076923 ], [ 29.728772586206897, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8544, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.511685723076923 ], [ 29.729042068965516, -1.5119552 ], [ 29.729311551724138, -1.5119552 ], [ 29.729311551724138, -1.511685723076923 ], [ 29.729042068965516, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8545, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.511685723076923 ], [ 29.729850517241381, -1.5119552 ], [ 29.73012, -1.5119552 ], [ 29.73012, -1.511685723076923 ], [ 29.729850517241381, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8546, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.511685723076923 ], [ 29.73012, -1.5119552 ], [ 29.730389482758621, -1.5119552 ], [ 29.730389482758621, -1.511685723076923 ], [ 29.73012, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8547, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.511685723076923 ], [ 29.730389482758621, -1.5119552 ], [ 29.730658965517243, -1.5119552 ], [ 29.730658965517243, -1.511685723076923 ], [ 29.730389482758621, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8548, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.511685723076923 ], [ 29.730658965517243, -1.5119552 ], [ 29.730928448275861, -1.5119552 ], [ 29.730928448275861, -1.511685723076923 ], [ 29.730658965517243, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8549, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.511685723076923 ], [ 29.730928448275861, -1.5119552 ], [ 29.731197931034483, -1.5119552 ], [ 29.731197931034483, -1.511685723076923 ], [ 29.730928448275861, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8550, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.511685723076923 ], [ 29.732545344827585, -1.512224676923077 ], [ 29.732814827586207, -1.512224676923077 ], [ 29.732814827586207, -1.511685723076923 ], [ 29.732545344827585, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8551, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.511685723076923 ], [ 29.733084310344829, -1.512494153846154 ], [ 29.733353793103447, -1.512494153846154 ], [ 29.733353793103447, -1.511685723076923 ], [ 29.733084310344829, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8552, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.511685723076923 ], [ 29.733353793103447, -1.5119552 ], [ 29.733623275862069, -1.5119552 ], [ 29.733623275862069, -1.511685723076923 ], [ 29.733353793103447, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8553, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.511685723076923 ], [ 29.733623275862069, -1.5119552 ], [ 29.733892758620691, -1.5119552 ], [ 29.733892758620691, -1.511685723076923 ], [ 29.733623275862069, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8554, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.511685723076923 ], [ 29.733892758620691, -1.5119552 ], [ 29.734162241379309, -1.5119552 ], [ 29.734162241379309, -1.511685723076923 ], [ 29.733892758620691, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8555, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.511685723076923 ], [ 29.734162241379309, -1.5119552 ], [ 29.734431724137931, -1.5119552 ], [ 29.734431724137931, -1.511685723076923 ], [ 29.734162241379309, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8556, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.511685723076923 ], [ 29.734431724137931, -1.5119552 ], [ 29.734701206896553, -1.5119552 ], [ 29.734701206896553, -1.511685723076923 ], [ 29.734431724137931, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8557, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.511685723076923 ], [ 29.734701206896553, -1.5119552 ], [ 29.734970689655171, -1.5119552 ], [ 29.734970689655171, -1.511685723076923 ], [ 29.734701206896553, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8558, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.511685723076923 ], [ 29.734970689655171, -1.5119552 ], [ 29.735240172413793, -1.5119552 ], [ 29.735240172413793, -1.511685723076923 ], [ 29.734970689655171, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8559, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.511685723076923 ], [ 29.735240172413793, -1.5119552 ], [ 29.735509655172415, -1.5119552 ], [ 29.735509655172415, -1.511685723076923 ], [ 29.735240172413793, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8560, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.511685723076923 ], [ 29.735509655172415, -1.5119552 ], [ 29.736048620689655, -1.5119552 ], [ 29.736048620689655, -1.511685723076923 ], [ 29.735509655172415, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8561, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.511685723076923 ], [ 29.736048620689655, -1.5119552 ], [ 29.736318103448276, -1.5119552 ], [ 29.736318103448276, -1.511685723076923 ], [ 29.736048620689655, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8562, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.511685723076923 ], [ 29.736318103448276, -1.5119552 ], [ 29.73739603448276, -1.5119552 ], [ 29.73739603448276, -1.511685723076923 ], [ 29.736318103448276, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8563, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.511685723076923 ], [ 29.73739603448276, -1.5119552 ], [ 29.737665517241378, -1.5119552 ], [ 29.737665517241378, -1.511685723076923 ], [ 29.73739603448276, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8564, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.511685723076923 ], [ 29.737665517241378, -1.5119552 ], [ 29.737935, -1.5119552 ], [ 29.737935, -1.511685723076923 ], [ 29.737665517241378, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8565, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.511685723076923 ], [ 29.737935, -1.5119552 ], [ 29.738204482758622, -1.5119552 ], [ 29.738204482758622, -1.511685723076923 ], [ 29.737935, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8566, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.511685723076923 ], [ 29.738743448275862, -1.5119552 ], [ 29.739012931034484, -1.5119552 ], [ 29.739012931034484, -1.511685723076923 ], [ 29.738743448275862, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8567, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.511685723076923 ], [ 29.739012931034484, -1.5119552 ], [ 29.739282413793102, -1.5119552 ], [ 29.739282413793102, -1.511685723076923 ], [ 29.739012931034484, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8568, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.511685723076923 ], [ 29.739282413793102, -1.5119552 ], [ 29.739551896551724, -1.5119552 ], [ 29.739551896551724, -1.511685723076923 ], [ 29.739282413793102, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8569, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.511685723076923 ], [ 29.739551896551724, -1.5119552 ], [ 29.739821379310346, -1.5119552 ], [ 29.739821379310346, -1.511685723076923 ], [ 29.739551896551724, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8570, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.511685723076923 ], [ 29.739821379310346, -1.5119552 ], [ 29.740090862068964, -1.5119552 ], [ 29.740090862068964, -1.512224676923077 ], [ 29.740360344827586, -1.512224676923077 ], [ 29.740360344827586, -1.511685723076923 ], [ 29.739821379310346, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8571, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.511685723076923 ], [ 29.743863620689655, -1.512224676923077 ], [ 29.744133103448277, -1.512224676923077 ], [ 29.744133103448277, -1.511685723076923 ], [ 29.743863620689655, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8572, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.511685723076923 ], [ 29.754642931034482, -1.5119552 ], [ 29.754912413793104, -1.5119552 ], [ 29.754912413793104, -1.511685723076923 ], [ 29.754642931034482, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8573, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.511685723076923 ], [ 29.754912413793104, -1.5119552 ], [ 29.755181896551726, -1.5119552 ], [ 29.755181896551726, -1.511685723076923 ], [ 29.754912413793104, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8574, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.511685723076923 ], [ 29.760841034482759, -1.5119552 ], [ 29.761110517241381, -1.5119552 ], [ 29.761110517241381, -1.511685723076923 ], [ 29.760841034482759, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8575, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.511685723076923 ], [ 29.761110517241381, -1.5119552 ], [ 29.76138, -1.5119552 ], [ 29.76138, -1.511685723076923 ], [ 29.761110517241381, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8576, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.511685723076923 ], [ 29.76138, -1.5119552 ], [ 29.761649482758621, -1.5119552 ], [ 29.761649482758621, -1.511685723076923 ], [ 29.76138, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8577, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.511685723076923 ], [ 29.761649482758621, -1.5119552 ], [ 29.761918965517243, -1.5119552 ], [ 29.761918965517243, -1.511685723076923 ], [ 29.761649482758621, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8578, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.511685723076923 ], [ 29.761918965517243, -1.5119552 ], [ 29.762188448275861, -1.5119552 ], [ 29.762188448275861, -1.511685723076923 ], [ 29.761918965517243, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8579, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.511685723076923 ], [ 29.762188448275861, -1.5119552 ], [ 29.762457931034483, -1.5119552 ], [ 29.762457931034483, -1.511685723076923 ], [ 29.762188448275861, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8580, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.511685723076923 ], [ 29.762457931034483, -1.5119552 ], [ 29.762727413793105, -1.5119552 ], [ 29.762727413793105, -1.511685723076923 ], [ 29.762457931034483, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8581, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.511685723076923 ], [ 29.762727413793105, -1.5119552 ], [ 29.762996896551723, -1.5119552 ], [ 29.762996896551723, -1.511685723076923 ], [ 29.762727413793105, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8582, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.511685723076923 ], [ 29.762996896551723, -1.5119552 ], [ 29.763266379310345, -1.5119552 ], [ 29.763266379310345, -1.511685723076923 ], [ 29.762996896551723, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8583, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.511685723076923 ], [ 29.763266379310345, -1.5119552 ], [ 29.763535862068967, -1.5119552 ], [ 29.763535862068967, -1.511685723076923 ], [ 29.763266379310345, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8584, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.511685723076923 ], [ 29.763535862068967, -1.5119552 ], [ 29.763805344827588, -1.5119552 ], [ 29.763805344827588, -1.511685723076923 ], [ 29.763535862068967, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8585, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.511685723076923 ], [ 29.763805344827588, -1.5119552 ], [ 29.764074827586207, -1.5119552 ], [ 29.764074827586207, -1.511685723076923 ], [ 29.763805344827588, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8586, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.511685723076923 ], [ 29.764074827586207, -1.5119552 ], [ 29.764344310344828, -1.5119552 ], [ 29.764344310344828, -1.511685723076923 ], [ 29.764074827586207, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8587, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.511685723076923 ], [ 29.764344310344828, -1.5119552 ], [ 29.76461379310345, -1.5119552 ], [ 29.76461379310345, -1.511685723076923 ], [ 29.764344310344828, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8588, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.511685723076923 ], [ 29.76461379310345, -1.5119552 ], [ 29.764883275862069, -1.5119552 ], [ 29.764883275862069, -1.511685723076923 ], [ 29.76461379310345, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8589, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.511685723076923 ], [ 29.764883275862069, -1.5119552 ], [ 29.76515275862069, -1.5119552 ], [ 29.76515275862069, -1.511685723076923 ], [ 29.764883275862069, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8590, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.511685723076923 ], [ 29.76515275862069, -1.5119552 ], [ 29.765422241379312, -1.5119552 ], [ 29.765422241379312, -1.511685723076923 ], [ 29.76515275862069, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8591, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.511685723076923 ], [ 29.765422241379312, -1.5119552 ], [ 29.765961206896552, -1.5119552 ], [ 29.765961206896552, -1.511685723076923 ], [ 29.765422241379312, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8592, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.511685723076923 ], [ 29.765961206896552, -1.5119552 ], [ 29.766230689655174, -1.5119552 ], [ 29.766230689655174, -1.511685723076923 ], [ 29.765961206896552, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8593, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.511685723076923 ], [ 29.766230689655174, -1.5119552 ], [ 29.766500172413792, -1.5119552 ], [ 29.766500172413792, -1.511685723076923 ], [ 29.766230689655174, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8594, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.511685723076923 ], [ 29.766500172413792, -1.5119552 ], [ 29.766769655172414, -1.5119552 ], [ 29.766769655172414, -1.511685723076923 ], [ 29.766500172413792, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8595, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.511685723076923 ], [ 29.766769655172414, -1.512494153846154 ], [ 29.767039137931036, -1.512494153846154 ], [ 29.767039137931036, -1.511685723076923 ], [ 29.766769655172414, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8596, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.511685723076923 ], [ 29.767039137931036, -1.512494153846154 ], [ 29.767308620689654, -1.512494153846154 ], [ 29.767308620689654, -1.511685723076923 ], [ 29.767039137931036, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8597, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.5119552 ], [ 29.712873103448278, -1.512224676923077 ], [ 29.713142586206896, -1.512224676923077 ], [ 29.713142586206896, -1.5119552 ], [ 29.712873103448278, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8598, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715567931034482, -1.511416246153846 ], [ 29.715837413793103, -1.511416246153846 ], [ 29.715837413793103, -1.511685723076923 ], [ 29.717184827586205, -1.511685723076923 ], [ 29.717184827586205, -1.5119552 ], [ 29.715028965517241, -1.5119552 ], [ 29.715028965517241, -1.512224676923077 ], [ 29.71475948275862, -1.512224676923077 ], [ 29.71475948275862, -1.512494153846154 ], [ 29.71449, -1.512494153846154 ], [ 29.71449, -1.512224676923077 ], [ 29.713142586206896, -1.512224676923077 ], [ 29.713142586206896, -1.5119552 ], [ 29.71475948275862, -1.5119552 ], [ 29.71475948275862, -1.511685723076923 ], [ 29.715567931034482, -1.511685723076923 ], [ 29.715567931034482, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8599, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715028965517241, -1.5119552 ], [ 29.715028965517241, -1.512224676923077 ], [ 29.717184827586205, -1.512224676923077 ], [ 29.717184827586205, -1.5119552 ], [ 29.715028965517241, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8600, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.5119552 ], [ 29.717184827586205, -1.512224676923077 ], [ 29.717454310344827, -1.512224676923077 ], [ 29.717454310344827, -1.5119552 ], [ 29.717184827586205, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8601, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.5119552 ], [ 29.717454310344827, -1.512224676923077 ], [ 29.717723793103449, -1.512224676923077 ], [ 29.717723793103449, -1.5119552 ], [ 29.717454310344827, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8602, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.5119552 ], [ 29.717723793103449, -1.512494153846154 ], [ 29.717993275862067, -1.512494153846154 ], [ 29.717993275862067, -1.5119552 ], [ 29.717723793103449, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8603, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.5119552 ], [ 29.717993275862067, -1.512224676923077 ], [ 29.718262758620689, -1.512224676923077 ], [ 29.718262758620689, -1.5119552 ], [ 29.717993275862067, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8604, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.5119552 ], [ 29.718262758620689, -1.512224676923077 ], [ 29.718532241379311, -1.512224676923077 ], [ 29.718532241379311, -1.5119552 ], [ 29.718262758620689, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8605, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.5119552 ], [ 29.718532241379311, -1.512224676923077 ], [ 29.718801724137933, -1.512224676923077 ], [ 29.718801724137933, -1.5119552 ], [ 29.718532241379311, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8606, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.5119552 ], [ 29.718801724137933, -1.512224676923077 ], [ 29.719071206896551, -1.512224676923077 ], [ 29.719071206896551, -1.5119552 ], [ 29.718801724137933, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8607, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.5119552 ], [ 29.719340689655173, -1.512224676923077 ], [ 29.719610172413795, -1.512224676923077 ], [ 29.719610172413795, -1.5119552 ], [ 29.719340689655173, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8608, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.5119552 ], [ 29.719610172413795, -1.512224676923077 ], [ 29.719879655172413, -1.512224676923077 ], [ 29.719879655172413, -1.5119552 ], [ 29.719610172413795, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8609, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.5119552 ], [ 29.719879655172413, -1.512224676923077 ], [ 29.720688103448275, -1.512224676923077 ], [ 29.720688103448275, -1.5119552 ], [ 29.719879655172413, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8610, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.5119552 ], [ 29.720957586206897, -1.512224676923077 ], [ 29.721496551724137, -1.512224676923077 ], [ 29.721496551724137, -1.5119552 ], [ 29.720957586206897, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8611, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.5119552 ], [ 29.721496551724137, -1.512224676923077 ], [ 29.722305, -1.512224676923077 ], [ 29.722305, -1.5119552 ], [ 29.721496551724137, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8612, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.5119552 ], [ 29.722305, -1.512224676923077 ], [ 29.722843965517242, -1.512224676923077 ], [ 29.722843965517242, -1.5119552 ], [ 29.722305, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8613, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.5119552 ], [ 29.722843965517242, -1.512224676923077 ], [ 29.723113448275861, -1.512224676923077 ], [ 29.723113448275861, -1.5119552 ], [ 29.722843965517242, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8614, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.5119552 ], [ 29.723113448275861, -1.512224676923077 ], [ 29.723382931034482, -1.512224676923077 ], [ 29.723382931034482, -1.5119552 ], [ 29.723113448275861, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8615, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.5119552 ], [ 29.723382931034482, -1.512224676923077 ], [ 29.723652413793104, -1.512224676923077 ], [ 29.723652413793104, -1.5119552 ], [ 29.723382931034482, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8616, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.5119552 ], [ 29.723652413793104, -1.512224676923077 ], [ 29.723921896551722, -1.512224676923077 ], [ 29.723921896551722, -1.5119552 ], [ 29.723652413793104, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8617, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.5119552 ], [ 29.723921896551722, -1.512224676923077 ], [ 29.724191379310344, -1.512224676923077 ], [ 29.724191379310344, -1.5119552 ], [ 29.723921896551722, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8618, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.5119552 ], [ 29.724191379310344, -1.512224676923077 ], [ 29.724460862068966, -1.512224676923077 ], [ 29.724460862068966, -1.5119552 ], [ 29.724191379310344, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8619, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.5119552 ], [ 29.724460862068966, -1.512224676923077 ], [ 29.724730344827588, -1.512224676923077 ], [ 29.724730344827588, -1.5119552 ], [ 29.724460862068966, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8620, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.5119552 ], [ 29.724730344827588, -1.512224676923077 ], [ 29.724999827586206, -1.512224676923077 ], [ 29.724999827586206, -1.5119552 ], [ 29.724730344827588, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8621, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.5119552 ], [ 29.724999827586206, -1.512224676923077 ], [ 29.725269310344828, -1.512224676923077 ], [ 29.725269310344828, -1.5119552 ], [ 29.724999827586206, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8622, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.5119552 ], [ 29.725269310344828, -1.512224676923077 ], [ 29.72553879310345, -1.512224676923077 ], [ 29.72553879310345, -1.5119552 ], [ 29.725269310344828, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8623, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.5119552 ], [ 29.72553879310345, -1.512224676923077 ], [ 29.725808275862068, -1.512224676923077 ], [ 29.725808275862068, -1.5119552 ], [ 29.72553879310345, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8624, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.5119552 ], [ 29.725808275862068, -1.512224676923077 ], [ 29.72607775862069, -1.512224676923077 ], [ 29.72607775862069, -1.5119552 ], [ 29.725808275862068, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8625, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.5119552 ], [ 29.72607775862069, -1.512494153846154 ], [ 29.726347241379312, -1.512494153846154 ], [ 29.726347241379312, -1.5119552 ], [ 29.72607775862069, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8626, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.5119552 ], [ 29.726347241379312, -1.512494153846154 ], [ 29.72661672413793, -1.512494153846154 ], [ 29.72661672413793, -1.5119552 ], [ 29.726347241379312, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8627, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.5119552 ], [ 29.727155689655174, -1.512494153846154 ], [ 29.727425172413792, -1.512494153846154 ], [ 29.727425172413792, -1.5119552 ], [ 29.727155689655174, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8628, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.5119552 ], [ 29.727964137931036, -1.512224676923077 ], [ 29.728233620689654, -1.512224676923077 ], [ 29.728233620689654, -1.5119552 ], [ 29.727964137931036, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8629, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.5119552 ], [ 29.728233620689654, -1.512224676923077 ], [ 29.728503103448276, -1.512224676923077 ], [ 29.728503103448276, -1.5119552 ], [ 29.728233620689654, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8630, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.5119552 ], [ 29.728503103448276, -1.512224676923077 ], [ 29.728772586206897, -1.512224676923077 ], [ 29.728772586206897, -1.5119552 ], [ 29.728503103448276, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8631, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.5119552 ], [ 29.728772586206897, -1.512224676923077 ], [ 29.729042068965516, -1.512224676923077 ], [ 29.729042068965516, -1.5119552 ], [ 29.728772586206897, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8632, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.5119552 ], [ 29.729042068965516, -1.512224676923077 ], [ 29.729311551724138, -1.512224676923077 ], [ 29.729311551724138, -1.5119552 ], [ 29.729042068965516, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8633, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.5119552 ], [ 29.729311551724138, -1.512224676923077 ], [ 29.729850517241381, -1.512224676923077 ], [ 29.729850517241381, -1.5119552 ], [ 29.729311551724138, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8634, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.5119552 ], [ 29.729850517241381, -1.512224676923077 ], [ 29.73012, -1.512224676923077 ], [ 29.73012, -1.5119552 ], [ 29.729850517241381, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8635, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.5119552 ], [ 29.73012, -1.512224676923077 ], [ 29.730389482758621, -1.512224676923077 ], [ 29.730389482758621, -1.5119552 ], [ 29.73012, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8636, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.5119552 ], [ 29.730389482758621, -1.512224676923077 ], [ 29.730658965517243, -1.512224676923077 ], [ 29.730658965517243, -1.5119552 ], [ 29.730389482758621, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8637, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.5119552 ], [ 29.730658965517243, -1.512224676923077 ], [ 29.730928448275861, -1.512224676923077 ], [ 29.730928448275861, -1.5119552 ], [ 29.730658965517243, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8638, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.5119552 ], [ 29.730928448275861, -1.512224676923077 ], [ 29.731197931034483, -1.512224676923077 ], [ 29.731197931034483, -1.5119552 ], [ 29.730928448275861, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8639, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.5119552 ], [ 29.731197931034483, -1.512224676923077 ], [ 29.731736896551723, -1.512224676923077 ], [ 29.731736896551723, -1.5119552 ], [ 29.731197931034483, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8640, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.5119552 ], [ 29.733353793103447, -1.512224676923077 ], [ 29.733623275862069, -1.512224676923077 ], [ 29.733623275862069, -1.5119552 ], [ 29.733353793103447, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8641, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.5119552 ], [ 29.733623275862069, -1.512494153846154 ], [ 29.733892758620691, -1.512494153846154 ], [ 29.733892758620691, -1.5119552 ], [ 29.733623275862069, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8642, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.5119552 ], [ 29.733892758620691, -1.512224676923077 ], [ 29.734162241379309, -1.512224676923077 ], [ 29.734162241379309, -1.5119552 ], [ 29.733892758620691, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8643, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.5119552 ], [ 29.734162241379309, -1.512224676923077 ], [ 29.734431724137931, -1.512224676923077 ], [ 29.734431724137931, -1.5119552 ], [ 29.734162241379309, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8644, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.5119552 ], [ 29.734431724137931, -1.512224676923077 ], [ 29.734701206896553, -1.512224676923077 ], [ 29.734701206896553, -1.5119552 ], [ 29.734431724137931, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8645, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.5119552 ], [ 29.734701206896553, -1.512224676923077 ], [ 29.734970689655171, -1.512224676923077 ], [ 29.734970689655171, -1.5119552 ], [ 29.734701206896553, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8646, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.5119552 ], [ 29.734970689655171, -1.512224676923077 ], [ 29.735240172413793, -1.512224676923077 ], [ 29.735240172413793, -1.5119552 ], [ 29.734970689655171, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8647, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.5119552 ], [ 29.735240172413793, -1.512224676923077 ], [ 29.735509655172415, -1.512224676923077 ], [ 29.735509655172415, -1.5119552 ], [ 29.735240172413793, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8648, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.5119552 ], [ 29.735509655172415, -1.512224676923077 ], [ 29.736048620689655, -1.512224676923077 ], [ 29.736048620689655, -1.5119552 ], [ 29.735509655172415, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8649, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.5119552 ], [ 29.736048620689655, -1.512224676923077 ], [ 29.736318103448276, -1.512224676923077 ], [ 29.736318103448276, -1.5119552 ], [ 29.736048620689655, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8650, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.5119552 ], [ 29.736318103448276, -1.512224676923077 ], [ 29.737126551724138, -1.512224676923077 ], [ 29.737126551724138, -1.5119552 ], [ 29.736318103448276, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8651, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.5119552 ], [ 29.737126551724138, -1.512224676923077 ], [ 29.73739603448276, -1.512224676923077 ], [ 29.73739603448276, -1.5119552 ], [ 29.737126551724138, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8652, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.5119552 ], [ 29.73739603448276, -1.512224676923077 ], [ 29.737665517241378, -1.512224676923077 ], [ 29.737665517241378, -1.5119552 ], [ 29.73739603448276, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8653, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.5119552 ], [ 29.737665517241378, -1.512224676923077 ], [ 29.737935, -1.512224676923077 ], [ 29.737935, -1.5119552 ], [ 29.737665517241378, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8654, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.5119552 ], [ 29.737935, -1.512224676923077 ], [ 29.738204482758622, -1.512224676923077 ], [ 29.738204482758622, -1.5119552 ], [ 29.737935, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8655, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.5119552 ], [ 29.738204482758622, -1.512224676923077 ], [ 29.738743448275862, -1.512224676923077 ], [ 29.738743448275862, -1.5119552 ], [ 29.738204482758622, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8656, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.5119552 ], [ 29.738743448275862, -1.512224676923077 ], [ 29.739012931034484, -1.512224676923077 ], [ 29.739012931034484, -1.5119552 ], [ 29.738743448275862, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8657, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.5119552 ], [ 29.739012931034484, -1.512224676923077 ], [ 29.739282413793102, -1.512224676923077 ], [ 29.739282413793102, -1.5119552 ], [ 29.739012931034484, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8658, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.5119552 ], [ 29.739282413793102, -1.512763630769231 ], [ 29.739551896551724, -1.512763630769231 ], [ 29.739551896551724, -1.5119552 ], [ 29.739282413793102, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8659, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.5119552 ], [ 29.739551896551724, -1.512763630769231 ], [ 29.739821379310346, -1.512763630769231 ], [ 29.739821379310346, -1.5119552 ], [ 29.739551896551724, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8660, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.5119552 ], [ 29.739821379310346, -1.512224676923077 ], [ 29.740090862068964, -1.512224676923077 ], [ 29.740090862068964, -1.5119552 ], [ 29.739821379310346, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8661, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.5119552 ], [ 29.743324655172415, -1.512224676923077 ], [ 29.743594137931034, -1.512224676923077 ], [ 29.743594137931034, -1.5119552 ], [ 29.743324655172415, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8662, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5119552 ], [ 29.761110517241381, -1.512224676923077 ], [ 29.76138, -1.512224676923077 ], [ 29.76138, -1.5119552 ], [ 29.761110517241381, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8663, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5119552 ], [ 29.76138, -1.512224676923077 ], [ 29.761649482758621, -1.512224676923077 ], [ 29.761649482758621, -1.5119552 ], [ 29.76138, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8664, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.5119552 ], [ 29.761649482758621, -1.512224676923077 ], [ 29.761918965517243, -1.512224676923077 ], [ 29.761918965517243, -1.5119552 ], [ 29.761649482758621, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8665, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5119552 ], [ 29.761918965517243, -1.512224676923077 ], [ 29.762188448275861, -1.512224676923077 ], [ 29.762188448275861, -1.5119552 ], [ 29.761918965517243, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8666, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5119552 ], [ 29.762188448275861, -1.512224676923077 ], [ 29.762457931034483, -1.512224676923077 ], [ 29.762457931034483, -1.5119552 ], [ 29.762188448275861, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8667, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5119552 ], [ 29.762457931034483, -1.512224676923077 ], [ 29.762727413793105, -1.512224676923077 ], [ 29.762727413793105, -1.5119552 ], [ 29.762457931034483, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8668, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5119552 ], [ 29.762727413793105, -1.512224676923077 ], [ 29.762996896551723, -1.512224676923077 ], [ 29.762996896551723, -1.5119552 ], [ 29.762727413793105, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8669, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.5119552 ], [ 29.762996896551723, -1.512224676923077 ], [ 29.763266379310345, -1.512224676923077 ], [ 29.763266379310345, -1.5119552 ], [ 29.762996896551723, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8670, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5119552 ], [ 29.763266379310345, -1.512224676923077 ], [ 29.763535862068967, -1.512224676923077 ], [ 29.763535862068967, -1.5119552 ], [ 29.763266379310345, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8671, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5119552 ], [ 29.763535862068967, -1.512224676923077 ], [ 29.763805344827588, -1.512224676923077 ], [ 29.763805344827588, -1.5119552 ], [ 29.763535862068967, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8672, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5119552 ], [ 29.763805344827588, -1.512224676923077 ], [ 29.764074827586207, -1.512224676923077 ], [ 29.764074827586207, -1.5119552 ], [ 29.763805344827588, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8673, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5119552 ], [ 29.764074827586207, -1.512224676923077 ], [ 29.764344310344828, -1.512224676923077 ], [ 29.764344310344828, -1.5119552 ], [ 29.764074827586207, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8674, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.5119552 ], [ 29.764344310344828, -1.512224676923077 ], [ 29.76461379310345, -1.512224676923077 ], [ 29.76461379310345, -1.5119552 ], [ 29.764344310344828, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8675, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.5119552 ], [ 29.76461379310345, -1.512224676923077 ], [ 29.764883275862069, -1.512224676923077 ], [ 29.764883275862069, -1.5119552 ], [ 29.76461379310345, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8676, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.5119552 ], [ 29.764883275862069, -1.512224676923077 ], [ 29.76515275862069, -1.512224676923077 ], [ 29.76515275862069, -1.5119552 ], [ 29.764883275862069, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8677, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.5119552 ], [ 29.76515275862069, -1.512494153846154 ], [ 29.765422241379312, -1.512494153846154 ], [ 29.765422241379312, -1.5119552 ], [ 29.76515275862069, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8678, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.5119552 ], [ 29.765422241379312, -1.512494153846154 ], [ 29.765961206896552, -1.512494153846154 ], [ 29.765961206896552, -1.5119552 ], [ 29.765422241379312, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8679, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.5119552 ], [ 29.765961206896552, -1.512224676923077 ], [ 29.766230689655174, -1.512224676923077 ], [ 29.766230689655174, -1.5119552 ], [ 29.765961206896552, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8680, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.5119552 ], [ 29.766230689655174, -1.512224676923077 ], [ 29.766500172413792, -1.512224676923077 ], [ 29.766500172413792, -1.5119552 ], [ 29.766230689655174, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8681, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5119552 ], [ 29.766500172413792, -1.512224676923077 ], [ 29.766769655172414, -1.512224676923077 ], [ 29.766769655172414, -1.5119552 ], [ 29.766500172413792, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8682, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.512224676923077 ], [ 29.712873103448278, -1.512494153846154 ], [ 29.713412068965518, -1.512494153846154 ], [ 29.713412068965518, -1.512224676923077 ], [ 29.712873103448278, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8683, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713412068965518, -1.512224676923077 ], [ 29.713412068965518, -1.512494153846154 ], [ 29.71422051724138, -1.512494153846154 ], [ 29.71422051724138, -1.512224676923077 ], [ 29.713412068965518, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8684, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71422051724138, -1.512224676923077 ], [ 29.71422051724138, -1.512494153846154 ], [ 29.71449, -1.512494153846154 ], [ 29.71449, -1.512224676923077 ], [ 29.71422051724138, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8685, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71475948275862, -1.512224676923077 ], [ 29.71475948275862, -1.512494153846154 ], [ 29.715028965517241, -1.512494153846154 ], [ 29.715028965517241, -1.512224676923077 ], [ 29.71475948275862, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8686, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715028965517241, -1.512224676923077 ], [ 29.715028965517241, -1.512494153846154 ], [ 29.715298448275863, -1.512494153846154 ], [ 29.715298448275863, -1.512224676923077 ], [ 29.715028965517241, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8687, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715298448275863, -1.512224676923077 ], [ 29.715298448275863, -1.512494153846154 ], [ 29.716915344827587, -1.512494153846154 ], [ 29.716915344827587, -1.512224676923077 ], [ 29.715298448275863, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8688, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.512224676923077 ], [ 29.716915344827587, -1.512494153846154 ], [ 29.717184827586205, -1.512494153846154 ], [ 29.717184827586205, -1.512224676923077 ], [ 29.716915344827587, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8689, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.512224676923077 ], [ 29.717184827586205, -1.512763630769231 ], [ 29.717454310344827, -1.512763630769231 ], [ 29.717454310344827, -1.512224676923077 ], [ 29.717184827586205, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8690, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.512224676923077 ], [ 29.717454310344827, -1.512763630769231 ], [ 29.717723793103449, -1.512763630769231 ], [ 29.717723793103449, -1.512224676923077 ], [ 29.717454310344827, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8691, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.512224676923077 ], [ 29.717993275862067, -1.512494153846154 ], [ 29.718262758620689, -1.512494153846154 ], [ 29.718262758620689, -1.512224676923077 ], [ 29.717993275862067, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8692, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.512224676923077 ], [ 29.718262758620689, -1.512494153846154 ], [ 29.718532241379311, -1.512494153846154 ], [ 29.718532241379311, -1.512224676923077 ], [ 29.718262758620689, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8693, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.512224676923077 ], [ 29.718532241379311, -1.512494153846154 ], [ 29.718801724137933, -1.512494153846154 ], [ 29.718801724137933, -1.512224676923077 ], [ 29.718532241379311, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8694, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.512224676923077 ], [ 29.718801724137933, -1.512494153846154 ], [ 29.719071206896551, -1.512494153846154 ], [ 29.719071206896551, -1.512224676923077 ], [ 29.718801724137933, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8695, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.512224676923077 ], [ 29.719071206896551, -1.512494153846154 ], [ 29.719340689655173, -1.512494153846154 ], [ 29.719340689655173, -1.512224676923077 ], [ 29.719071206896551, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8696, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.512224676923077 ], [ 29.719340689655173, -1.512494153846154 ], [ 29.719610172413795, -1.512494153846154 ], [ 29.719610172413795, -1.512224676923077 ], [ 29.719340689655173, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8697, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.512224676923077 ], [ 29.719610172413795, -1.512763630769231 ], [ 29.719879655172413, -1.512763630769231 ], [ 29.719879655172413, -1.512224676923077 ], [ 29.719610172413795, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8698, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.512224676923077 ], [ 29.719879655172413, -1.512494153846154 ], [ 29.720149137931035, -1.512494153846154 ], [ 29.720149137931035, -1.512224676923077 ], [ 29.719879655172413, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8699, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.512224676923077 ], [ 29.720149137931035, -1.512494153846154 ], [ 29.720688103448275, -1.512494153846154 ], [ 29.720688103448275, -1.512224676923077 ], [ 29.720149137931035, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8700, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.512224676923077 ], [ 29.720688103448275, -1.512494153846154 ], [ 29.720957586206897, -1.512494153846154 ], [ 29.720957586206897, -1.512224676923077 ], [ 29.720688103448275, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8701, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.512224676923077 ], [ 29.720957586206897, -1.512494153846154 ], [ 29.721227068965518, -1.512494153846154 ], [ 29.721227068965518, -1.512224676923077 ], [ 29.720957586206897, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8702, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.512224676923077 ], [ 29.721227068965518, -1.512494153846154 ], [ 29.72257448275862, -1.512494153846154 ], [ 29.72257448275862, -1.512224676923077 ], [ 29.721227068965518, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8703, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.512224676923077 ], [ 29.72257448275862, -1.512494153846154 ], [ 29.722843965517242, -1.512494153846154 ], [ 29.722843965517242, -1.512224676923077 ], [ 29.72257448275862, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8704, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.512224676923077 ], [ 29.722843965517242, -1.512494153846154 ], [ 29.723113448275861, -1.512494153846154 ], [ 29.723113448275861, -1.512224676923077 ], [ 29.722843965517242, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8705, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.512224676923077 ], [ 29.723113448275861, -1.512494153846154 ], [ 29.723382931034482, -1.512494153846154 ], [ 29.723382931034482, -1.512224676923077 ], [ 29.723113448275861, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8706, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.512224676923077 ], [ 29.723382931034482, -1.512494153846154 ], [ 29.723652413793104, -1.512494153846154 ], [ 29.723652413793104, -1.512224676923077 ], [ 29.723382931034482, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8707, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.512224676923077 ], [ 29.723652413793104, -1.512494153846154 ], [ 29.723921896551722, -1.512494153846154 ], [ 29.723921896551722, -1.512224676923077 ], [ 29.723652413793104, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8708, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.512224676923077 ], [ 29.723921896551722, -1.512494153846154 ], [ 29.724191379310344, -1.512494153846154 ], [ 29.724191379310344, -1.512224676923077 ], [ 29.723921896551722, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8709, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.512224676923077 ], [ 29.724191379310344, -1.512494153846154 ], [ 29.724460862068966, -1.512494153846154 ], [ 29.724460862068966, -1.512224676923077 ], [ 29.724191379310344, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8710, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.512224676923077 ], [ 29.724460862068966, -1.512494153846154 ], [ 29.724730344827588, -1.512494153846154 ], [ 29.724730344827588, -1.512224676923077 ], [ 29.724460862068966, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8711, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.512224676923077 ], [ 29.724730344827588, -1.512494153846154 ], [ 29.724999827586206, -1.512494153846154 ], [ 29.724999827586206, -1.512224676923077 ], [ 29.724730344827588, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8712, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.512224676923077 ], [ 29.724999827586206, -1.512494153846154 ], [ 29.725269310344828, -1.512494153846154 ], [ 29.725269310344828, -1.512224676923077 ], [ 29.724999827586206, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8713, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.512224676923077 ], [ 29.725269310344828, -1.512494153846154 ], [ 29.72553879310345, -1.512494153846154 ], [ 29.72553879310345, -1.512224676923077 ], [ 29.725269310344828, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8714, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.512224676923077 ], [ 29.72553879310345, -1.512494153846154 ], [ 29.725808275862068, -1.512494153846154 ], [ 29.725808275862068, -1.512224676923077 ], [ 29.72553879310345, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8715, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.512224676923077 ], [ 29.725808275862068, -1.512494153846154 ], [ 29.72607775862069, -1.512494153846154 ], [ 29.72607775862069, -1.512224676923077 ], [ 29.725808275862068, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8716, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.512224676923077 ], [ 29.72661672413793, -1.512494153846154 ], [ 29.726886206896552, -1.512494153846154 ], [ 29.726886206896552, -1.512224676923077 ], [ 29.72661672413793, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8717, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.512224676923077 ], [ 29.726886206896552, -1.512763630769231 ], [ 29.727155689655174, -1.512763630769231 ], [ 29.727155689655174, -1.512224676923077 ], [ 29.726886206896552, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8718, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.512224676923077 ], [ 29.727425172413792, -1.512494153846154 ], [ 29.727694655172414, -1.512494153846154 ], [ 29.727694655172414, -1.512224676923077 ], [ 29.727425172413792, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8719, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.512224676923077 ], [ 29.727694655172414, -1.512494153846154 ], [ 29.727964137931036, -1.512494153846154 ], [ 29.727964137931036, -1.512224676923077 ], [ 29.727694655172414, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8720, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.512224676923077 ], [ 29.727964137931036, -1.512494153846154 ], [ 29.728233620689654, -1.512494153846154 ], [ 29.728233620689654, -1.512224676923077 ], [ 29.727964137931036, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8721, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.512224676923077 ], [ 29.728233620689654, -1.512494153846154 ], [ 29.728503103448276, -1.512494153846154 ], [ 29.728503103448276, -1.512224676923077 ], [ 29.728233620689654, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8722, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.512224676923077 ], [ 29.728503103448276, -1.512494153846154 ], [ 29.728772586206897, -1.512494153846154 ], [ 29.728772586206897, -1.512224676923077 ], [ 29.728503103448276, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8723, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.512224676923077 ], [ 29.728772586206897, -1.512494153846154 ], [ 29.729042068965516, -1.512494153846154 ], [ 29.729042068965516, -1.512224676923077 ], [ 29.728772586206897, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8724, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.512224676923077 ], [ 29.729042068965516, -1.512494153846154 ], [ 29.729311551724138, -1.512494153846154 ], [ 29.729311551724138, -1.512224676923077 ], [ 29.729042068965516, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8725, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.512224676923077 ], [ 29.729311551724138, -1.512494153846154 ], [ 29.729850517241381, -1.512494153846154 ], [ 29.729850517241381, -1.512224676923077 ], [ 29.729311551724138, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8726, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.512224676923077 ], [ 29.729850517241381, -1.512494153846154 ], [ 29.73012, -1.512494153846154 ], [ 29.73012, -1.512224676923077 ], [ 29.729850517241381, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8727, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.512224676923077 ], [ 29.73012, -1.512494153846154 ], [ 29.730389482758621, -1.512494153846154 ], [ 29.730389482758621, -1.512224676923077 ], [ 29.73012, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8728, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.512224676923077 ], [ 29.730389482758621, -1.512494153846154 ], [ 29.730658965517243, -1.512494153846154 ], [ 29.730658965517243, -1.512224676923077 ], [ 29.730389482758621, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8729, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.512224676923077 ], [ 29.730658965517243, -1.512494153846154 ], [ 29.730928448275861, -1.512494153846154 ], [ 29.730928448275861, -1.512224676923077 ], [ 29.730658965517243, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8730, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.512224676923077 ], [ 29.730928448275861, -1.512494153846154 ], [ 29.731467413793105, -1.512494153846154 ], [ 29.731467413793105, -1.512224676923077 ], [ 29.730928448275861, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8731, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.512224676923077 ], [ 29.731467413793105, -1.512494153846154 ], [ 29.731736896551723, -1.512494153846154 ], [ 29.731736896551723, -1.512224676923077 ], [ 29.731467413793105, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8732, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.512224676923077 ], [ 29.731736896551723, -1.512494153846154 ], [ 29.732545344827585, -1.512494153846154 ], [ 29.732545344827585, -1.512224676923077 ], [ 29.731736896551723, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8733, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.512224676923077 ], [ 29.732545344827585, -1.512494153846154 ], [ 29.732814827586207, -1.512494153846154 ], [ 29.732814827586207, -1.512224676923077 ], [ 29.732545344827585, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8734, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.512224676923077 ], [ 29.733353793103447, -1.512763630769231 ], [ 29.733623275862069, -1.512763630769231 ], [ 29.733623275862069, -1.512224676923077 ], [ 29.733353793103447, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8735, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.512224676923077 ], [ 29.733892758620691, -1.512494153846154 ], [ 29.734162241379309, -1.512494153846154 ], [ 29.734162241379309, -1.512224676923077 ], [ 29.733892758620691, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8736, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.512224676923077 ], [ 29.734162241379309, -1.512494153846154 ], [ 29.734431724137931, -1.512494153846154 ], [ 29.734431724137931, -1.512224676923077 ], [ 29.734162241379309, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8737, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.512224676923077 ], [ 29.734431724137931, -1.512494153846154 ], [ 29.734701206896553, -1.512494153846154 ], [ 29.734701206896553, -1.512224676923077 ], [ 29.734431724137931, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8738, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.512224676923077 ], [ 29.734701206896553, -1.512494153846154 ], [ 29.734970689655171, -1.512494153846154 ], [ 29.734970689655171, -1.512224676923077 ], [ 29.734701206896553, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8739, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.512224676923077 ], [ 29.734970689655171, -1.512494153846154 ], [ 29.735240172413793, -1.512494153846154 ], [ 29.735240172413793, -1.512224676923077 ], [ 29.734970689655171, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8740, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.512224676923077 ], [ 29.735240172413793, -1.512494153846154 ], [ 29.735509655172415, -1.512494153846154 ], [ 29.735509655172415, -1.512224676923077 ], [ 29.735240172413793, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8741, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.512224676923077 ], [ 29.735509655172415, -1.512494153846154 ], [ 29.736048620689655, -1.512494153846154 ], [ 29.736048620689655, -1.512224676923077 ], [ 29.735509655172415, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8742, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.512224676923077 ], [ 29.736048620689655, -1.512494153846154 ], [ 29.736587586206898, -1.512494153846154 ], [ 29.736587586206898, -1.512224676923077 ], [ 29.736048620689655, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8743, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.512224676923077 ], [ 29.736587586206898, -1.512494153846154 ], [ 29.737126551724138, -1.512494153846154 ], [ 29.737126551724138, -1.512224676923077 ], [ 29.736587586206898, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8744, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.512224676923077 ], [ 29.737126551724138, -1.513033107692308 ], [ 29.73739603448276, -1.513033107692308 ], [ 29.73739603448276, -1.512224676923077 ], [ 29.737126551724138, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8745, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.512224676923077 ], [ 29.73739603448276, -1.513033107692308 ], [ 29.737665517241378, -1.513033107692308 ], [ 29.737665517241378, -1.512224676923077 ], [ 29.73739603448276, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8746, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.512224676923077 ], [ 29.737665517241378, -1.512763630769231 ], [ 29.737935, -1.512763630769231 ], [ 29.737935, -1.512224676923077 ], [ 29.737665517241378, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8747, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.512224676923077 ], [ 29.737935, -1.512763630769231 ], [ 29.738204482758622, -1.512763630769231 ], [ 29.738204482758622, -1.512224676923077 ], [ 29.737935, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8748, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.512224676923077 ], [ 29.738204482758622, -1.512763630769231 ], [ 29.738743448275862, -1.512763630769231 ], [ 29.738743448275862, -1.512224676923077 ], [ 29.738204482758622, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8749, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.512224676923077 ], [ 29.738743448275862, -1.512494153846154 ], [ 29.739012931034484, -1.512494153846154 ], [ 29.739012931034484, -1.512224676923077 ], [ 29.738743448275862, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8750, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.512224676923077 ], [ 29.739012931034484, -1.512494153846154 ], [ 29.739282413793102, -1.512494153846154 ], [ 29.739282413793102, -1.512224676923077 ], [ 29.739012931034484, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8751, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.512224676923077 ], [ 29.739821379310346, -1.513033107692308 ], [ 29.740090862068964, -1.513033107692308 ], [ 29.740090862068964, -1.512224676923077 ], [ 29.739821379310346, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8752, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743324655172415, -1.512224676923077 ], [ 29.743324655172415, -1.512494153846154 ], [ 29.743594137931034, -1.512494153846154 ], [ 29.743594137931034, -1.512224676923077 ], [ 29.743324655172415, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8753, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.512224676923077 ], [ 29.743594137931034, -1.512494153846154 ], [ 29.743863620689655, -1.512494153846154 ], [ 29.743863620689655, -1.512763630769231 ], [ 29.744133103448277, -1.512763630769231 ], [ 29.744133103448277, -1.513033107692308 ], [ 29.744402586206895, -1.513033107692308 ], [ 29.744402586206895, -1.512494153846154 ], [ 29.744133103448277, -1.512494153846154 ], [ 29.744133103448277, -1.512224676923077 ], [ 29.743594137931034, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8754, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.512224676923077 ], [ 29.744133103448277, -1.512494153846154 ], [ 29.744402586206895, -1.512494153846154 ], [ 29.744402586206895, -1.512224676923077 ], [ 29.744133103448277, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8755, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.512224676923077 ], [ 29.761110517241381, -1.512494153846154 ], [ 29.76138, -1.512494153846154 ], [ 29.76138, -1.512224676923077 ], [ 29.761110517241381, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8756, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.512224676923077 ], [ 29.76138, -1.512494153846154 ], [ 29.761649482758621, -1.512494153846154 ], [ 29.761649482758621, -1.512224676923077 ], [ 29.76138, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8757, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.512224676923077 ], [ 29.761649482758621, -1.512494153846154 ], [ 29.761918965517243, -1.512494153846154 ], [ 29.761918965517243, -1.512224676923077 ], [ 29.761649482758621, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8758, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.512224676923077 ], [ 29.761918965517243, -1.512494153846154 ], [ 29.762188448275861, -1.512494153846154 ], [ 29.762188448275861, -1.512224676923077 ], [ 29.761918965517243, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8759, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.512224676923077 ], [ 29.762188448275861, -1.512494153846154 ], [ 29.762457931034483, -1.512494153846154 ], [ 29.762457931034483, -1.512224676923077 ], [ 29.762188448275861, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8760, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.512224676923077 ], [ 29.762457931034483, -1.512494153846154 ], [ 29.762727413793105, -1.512494153846154 ], [ 29.762727413793105, -1.512224676923077 ], [ 29.762457931034483, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8761, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.512224676923077 ], [ 29.762727413793105, -1.513033107692308 ], [ 29.762996896551723, -1.513033107692308 ], [ 29.762996896551723, -1.512224676923077 ], [ 29.762727413793105, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8762, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.512224676923077 ], [ 29.762996896551723, -1.513033107692308 ], [ 29.763266379310345, -1.513033107692308 ], [ 29.763266379310345, -1.512224676923077 ], [ 29.762996896551723, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8763, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.512224676923077 ], [ 29.763266379310345, -1.512763630769231 ], [ 29.763535862068967, -1.512763630769231 ], [ 29.763535862068967, -1.512224676923077 ], [ 29.763266379310345, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8764, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.512224676923077 ], [ 29.763535862068967, -1.512763630769231 ], [ 29.763805344827588, -1.512763630769231 ], [ 29.763805344827588, -1.512224676923077 ], [ 29.763535862068967, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8765, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.512224676923077 ], [ 29.763805344827588, -1.512494153846154 ], [ 29.764074827586207, -1.512494153846154 ], [ 29.764074827586207, -1.512224676923077 ], [ 29.763805344827588, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8766, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.512224676923077 ], [ 29.764074827586207, -1.512494153846154 ], [ 29.764344310344828, -1.512494153846154 ], [ 29.764344310344828, -1.512224676923077 ], [ 29.764074827586207, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8767, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.512224676923077 ], [ 29.764344310344828, -1.512494153846154 ], [ 29.76461379310345, -1.512494153846154 ], [ 29.76461379310345, -1.512224676923077 ], [ 29.764344310344828, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8768, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.512224676923077 ], [ 29.76461379310345, -1.512494153846154 ], [ 29.764883275862069, -1.512494153846154 ], [ 29.764883275862069, -1.512224676923077 ], [ 29.76461379310345, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8769, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.512224676923077 ], [ 29.764883275862069, -1.512494153846154 ], [ 29.76515275862069, -1.512494153846154 ], [ 29.76515275862069, -1.512224676923077 ], [ 29.764883275862069, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8770, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.512224676923077 ], [ 29.765961206896552, -1.512494153846154 ], [ 29.766230689655174, -1.512494153846154 ], [ 29.766230689655174, -1.512224676923077 ], [ 29.765961206896552, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8771, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.512224676923077 ], [ 29.766230689655174, -1.512494153846154 ], [ 29.766500172413792, -1.512494153846154 ], [ 29.766500172413792, -1.512224676923077 ], [ 29.766230689655174, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8772, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.512224676923077 ], [ 29.766500172413792, -1.512494153846154 ], [ 29.766769655172414, -1.512494153846154 ], [ 29.766769655172414, -1.512224676923077 ], [ 29.766500172413792, -1.512224676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8773, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713951034482758, -1.512494153846154 ], [ 29.713951034482758, -1.512763630769231 ], [ 29.71422051724138, -1.512763630769231 ], [ 29.71422051724138, -1.512494153846154 ], [ 29.713951034482758, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8774, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71422051724138, -1.512494153846154 ], [ 29.71422051724138, -1.513033107692308 ], [ 29.71449, -1.513033107692308 ], [ 29.71449, -1.512494153846154 ], [ 29.71422051724138, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8775, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71449, -1.512494153846154 ], [ 29.71449, -1.513033107692308 ], [ 29.71475948275862, -1.513033107692308 ], [ 29.71475948275862, -1.512494153846154 ], [ 29.71449, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8776, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71475948275862, -1.512494153846154 ], [ 29.71475948275862, -1.512763630769231 ], [ 29.715028965517241, -1.512763630769231 ], [ 29.715028965517241, -1.512494153846154 ], [ 29.71475948275862, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8777, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715028965517241, -1.512494153846154 ], [ 29.715028965517241, -1.512763630769231 ], [ 29.715298448275863, -1.512763630769231 ], [ 29.715298448275863, -1.512494153846154 ], [ 29.715028965517241, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8778, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.716915344827587, -1.512494153846154 ], [ 29.716915344827587, -1.513033107692308 ], [ 29.717184827586205, -1.513033107692308 ], [ 29.717184827586205, -1.512494153846154 ], [ 29.716915344827587, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8779, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.512494153846154 ], [ 29.717723793103449, -1.512763630769231 ], [ 29.717993275862067, -1.512763630769231 ], [ 29.717993275862067, -1.512494153846154 ], [ 29.717723793103449, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8780, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.512494153846154 ], [ 29.717993275862067, -1.513033107692308 ], [ 29.718262758620689, -1.513033107692308 ], [ 29.718262758620689, -1.512494153846154 ], [ 29.717993275862067, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8781, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.512494153846154 ], [ 29.718262758620689, -1.512763630769231 ], [ 29.718532241379311, -1.512763630769231 ], [ 29.718532241379311, -1.512494153846154 ], [ 29.718262758620689, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8782, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.512494153846154 ], [ 29.718532241379311, -1.512763630769231 ], [ 29.718801724137933, -1.512763630769231 ], [ 29.718801724137933, -1.512494153846154 ], [ 29.718532241379311, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8783, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.512494153846154 ], [ 29.718801724137933, -1.512763630769231 ], [ 29.719071206896551, -1.512763630769231 ], [ 29.719071206896551, -1.512494153846154 ], [ 29.718801724137933, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8784, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.512494153846154 ], [ 29.719071206896551, -1.513572061538462 ], [ 29.719340689655173, -1.513572061538462 ], [ 29.719340689655173, -1.512494153846154 ], [ 29.719071206896551, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8785, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.512494153846154 ], [ 29.719340689655173, -1.513572061538462 ], [ 29.719610172413795, -1.513572061538462 ], [ 29.719610172413795, -1.513302584615384 ], [ 29.719879655172413, -1.513302584615384 ], [ 29.719879655172413, -1.513033107692308 ], [ 29.719610172413795, -1.513033107692308 ], [ 29.719610172413795, -1.512494153846154 ], [ 29.719340689655173, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8786, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.512494153846154 ], [ 29.719879655172413, -1.512763630769231 ], [ 29.720149137931035, -1.512763630769231 ], [ 29.720149137931035, -1.512494153846154 ], [ 29.719879655172413, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8787, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.512494153846154 ], [ 29.720149137931035, -1.512763630769231 ], [ 29.720688103448275, -1.512763630769231 ], [ 29.720688103448275, -1.512494153846154 ], [ 29.720149137931035, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8788, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.512494153846154 ], [ 29.720688103448275, -1.512763630769231 ], [ 29.720957586206897, -1.512763630769231 ], [ 29.720957586206897, -1.512494153846154 ], [ 29.720688103448275, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8789, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.512494153846154 ], [ 29.720957586206897, -1.512763630769231 ], [ 29.721227068965518, -1.512763630769231 ], [ 29.721227068965518, -1.512494153846154 ], [ 29.720957586206897, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8790, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.512494153846154 ], [ 29.721227068965518, -1.512763630769231 ], [ 29.72257448275862, -1.512763630769231 ], [ 29.72257448275862, -1.512494153846154 ], [ 29.721227068965518, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8791, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.512494153846154 ], [ 29.72257448275862, -1.512763630769231 ], [ 29.722843965517242, -1.512763630769231 ], [ 29.722843965517242, -1.512494153846154 ], [ 29.72257448275862, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8792, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.512494153846154 ], [ 29.722843965517242, -1.512763630769231 ], [ 29.723113448275861, -1.512763630769231 ], [ 29.723113448275861, -1.512494153846154 ], [ 29.722843965517242, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8793, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.512494153846154 ], [ 29.723113448275861, -1.512763630769231 ], [ 29.723382931034482, -1.512763630769231 ], [ 29.723382931034482, -1.512494153846154 ], [ 29.723113448275861, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8794, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.512494153846154 ], [ 29.723382931034482, -1.512763630769231 ], [ 29.723652413793104, -1.512763630769231 ], [ 29.723652413793104, -1.512494153846154 ], [ 29.723382931034482, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8795, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.512494153846154 ], [ 29.723652413793104, -1.512763630769231 ], [ 29.723921896551722, -1.512763630769231 ], [ 29.723921896551722, -1.512494153846154 ], [ 29.723652413793104, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8796, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.512494153846154 ], [ 29.723921896551722, -1.512763630769231 ], [ 29.724191379310344, -1.512763630769231 ], [ 29.724191379310344, -1.512494153846154 ], [ 29.723921896551722, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8797, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.512494153846154 ], [ 29.724191379310344, -1.512763630769231 ], [ 29.724460862068966, -1.512763630769231 ], [ 29.724460862068966, -1.512494153846154 ], [ 29.724191379310344, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8798, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.512494153846154 ], [ 29.724460862068966, -1.512763630769231 ], [ 29.724730344827588, -1.512763630769231 ], [ 29.724730344827588, -1.512494153846154 ], [ 29.724460862068966, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8799, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.512494153846154 ], [ 29.724730344827588, -1.512763630769231 ], [ 29.724999827586206, -1.512763630769231 ], [ 29.724999827586206, -1.512494153846154 ], [ 29.724730344827588, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8800, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.512494153846154 ], [ 29.724999827586206, -1.512763630769231 ], [ 29.725269310344828, -1.512763630769231 ], [ 29.725269310344828, -1.512494153846154 ], [ 29.724999827586206, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8801, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.512494153846154 ], [ 29.725269310344828, -1.512763630769231 ], [ 29.72553879310345, -1.512763630769231 ], [ 29.72553879310345, -1.512494153846154 ], [ 29.725269310344828, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8802, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.512494153846154 ], [ 29.72553879310345, -1.512763630769231 ], [ 29.725808275862068, -1.512763630769231 ], [ 29.725808275862068, -1.512494153846154 ], [ 29.72553879310345, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8803, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.512494153846154 ], [ 29.725808275862068, -1.512763630769231 ], [ 29.72607775862069, -1.512763630769231 ], [ 29.72607775862069, -1.512494153846154 ], [ 29.725808275862068, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8804, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.512494153846154 ], [ 29.72607775862069, -1.512763630769231 ], [ 29.72661672413793, -1.512763630769231 ], [ 29.72661672413793, -1.512494153846154 ], [ 29.72607775862069, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8805, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.512494153846154 ], [ 29.72661672413793, -1.513033107692308 ], [ 29.726886206896552, -1.513033107692308 ], [ 29.726886206896552, -1.512494153846154 ], [ 29.72661672413793, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8806, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.512494153846154 ], [ 29.727155689655174, -1.512763630769231 ], [ 29.727425172413792, -1.512763630769231 ], [ 29.727425172413792, -1.512494153846154 ], [ 29.727155689655174, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8807, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.512494153846154 ], [ 29.727425172413792, -1.512763630769231 ], [ 29.727694655172414, -1.512763630769231 ], [ 29.727694655172414, -1.512494153846154 ], [ 29.727425172413792, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8808, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.512494153846154 ], [ 29.727694655172414, -1.512763630769231 ], [ 29.727964137931036, -1.512763630769231 ], [ 29.727964137931036, -1.512494153846154 ], [ 29.727694655172414, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8809, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.512494153846154 ], [ 29.727964137931036, -1.512763630769231 ], [ 29.728233620689654, -1.512763630769231 ], [ 29.728233620689654, -1.512494153846154 ], [ 29.727964137931036, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8810, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.512494153846154 ], [ 29.728233620689654, -1.512763630769231 ], [ 29.728503103448276, -1.512763630769231 ], [ 29.728503103448276, -1.512494153846154 ], [ 29.728233620689654, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8811, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.512494153846154 ], [ 29.728503103448276, -1.512763630769231 ], [ 29.728772586206897, -1.512763630769231 ], [ 29.728772586206897, -1.512494153846154 ], [ 29.728503103448276, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8812, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.512494153846154 ], [ 29.728772586206897, -1.512763630769231 ], [ 29.729042068965516, -1.512763630769231 ], [ 29.729042068965516, -1.512494153846154 ], [ 29.728772586206897, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8813, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.512494153846154 ], [ 29.729042068965516, -1.512763630769231 ], [ 29.729311551724138, -1.512763630769231 ], [ 29.729311551724138, -1.512494153846154 ], [ 29.729042068965516, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8814, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.512494153846154 ], [ 29.729311551724138, -1.512763630769231 ], [ 29.729850517241381, -1.512763630769231 ], [ 29.729850517241381, -1.512494153846154 ], [ 29.729311551724138, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8815, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.512494153846154 ], [ 29.729850517241381, -1.512763630769231 ], [ 29.73012, -1.512763630769231 ], [ 29.73012, -1.512494153846154 ], [ 29.729850517241381, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8816, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.512494153846154 ], [ 29.73012, -1.512763630769231 ], [ 29.730389482758621, -1.512763630769231 ], [ 29.730389482758621, -1.512494153846154 ], [ 29.73012, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8817, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.512494153846154 ], [ 29.730389482758621, -1.512763630769231 ], [ 29.730658965517243, -1.512763630769231 ], [ 29.730658965517243, -1.512494153846154 ], [ 29.730389482758621, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8818, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.512494153846154 ], [ 29.730658965517243, -1.512763630769231 ], [ 29.730928448275861, -1.512763630769231 ], [ 29.730928448275861, -1.512494153846154 ], [ 29.730658965517243, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8819, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.512494153846154 ], [ 29.730928448275861, -1.512763630769231 ], [ 29.731467413793105, -1.512763630769231 ], [ 29.731467413793105, -1.512494153846154 ], [ 29.730928448275861, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8820, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.512494153846154 ], [ 29.731467413793105, -1.512763630769231 ], [ 29.732814827586207, -1.512763630769231 ], [ 29.732814827586207, -1.512494153846154 ], [ 29.731467413793105, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8821, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.512494153846154 ], [ 29.732814827586207, -1.512763630769231 ], [ 29.733084310344829, -1.512763630769231 ], [ 29.733084310344829, -1.512494153846154 ], [ 29.732814827586207, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8822, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.512494153846154 ], [ 29.733084310344829, -1.512763630769231 ], [ 29.733353793103447, -1.512763630769231 ], [ 29.733353793103447, -1.512494153846154 ], [ 29.733084310344829, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8823, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.512494153846154 ], [ 29.733623275862069, -1.512763630769231 ], [ 29.733892758620691, -1.512763630769231 ], [ 29.733892758620691, -1.512494153846154 ], [ 29.733623275862069, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8824, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.512494153846154 ], [ 29.733892758620691, -1.512763630769231 ], [ 29.734162241379309, -1.512763630769231 ], [ 29.734162241379309, -1.512494153846154 ], [ 29.733892758620691, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8825, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.512494153846154 ], [ 29.734162241379309, -1.512763630769231 ], [ 29.734431724137931, -1.512763630769231 ], [ 29.734431724137931, -1.512494153846154 ], [ 29.734162241379309, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8826, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.512494153846154 ], [ 29.734431724137931, -1.512763630769231 ], [ 29.734701206896553, -1.512763630769231 ], [ 29.734701206896553, -1.512494153846154 ], [ 29.734431724137931, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8827, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.512494153846154 ], [ 29.734701206896553, -1.512763630769231 ], [ 29.734970689655171, -1.512763630769231 ], [ 29.734970689655171, -1.512494153846154 ], [ 29.734701206896553, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8828, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.512494153846154 ], [ 29.734970689655171, -1.512763630769231 ], [ 29.735240172413793, -1.512763630769231 ], [ 29.735240172413793, -1.512494153846154 ], [ 29.734970689655171, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8829, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.512494153846154 ], [ 29.735240172413793, -1.512763630769231 ], [ 29.735509655172415, -1.512763630769231 ], [ 29.735509655172415, -1.512494153846154 ], [ 29.735240172413793, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8830, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.512494153846154 ], [ 29.735509655172415, -1.512763630769231 ], [ 29.735779137931036, -1.512763630769231 ], [ 29.735779137931036, -1.512494153846154 ], [ 29.735509655172415, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8831, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.512494153846154 ], [ 29.735779137931036, -1.512763630769231 ], [ 29.736048620689655, -1.512763630769231 ], [ 29.736048620689655, -1.512494153846154 ], [ 29.735779137931036, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8832, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.512494153846154 ], [ 29.736048620689655, -1.512763630769231 ], [ 29.736857068965517, -1.512763630769231 ], [ 29.736857068965517, -1.512494153846154 ], [ 29.736048620689655, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8833, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.512494153846154 ], [ 29.736857068965517, -1.512763630769231 ], [ 29.737126551724138, -1.512763630769231 ], [ 29.737126551724138, -1.512494153846154 ], [ 29.736857068965517, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8834, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.512494153846154 ], [ 29.738743448275862, -1.513033107692308 ], [ 29.739012931034484, -1.513033107692308 ], [ 29.739012931034484, -1.512494153846154 ], [ 29.738743448275862, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8835, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.512494153846154 ], [ 29.739012931034484, -1.512763630769231 ], [ 29.739282413793102, -1.512763630769231 ], [ 29.739282413793102, -1.512494153846154 ], [ 29.739012931034484, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8836, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743594137931034, -1.512494153846154 ], [ 29.743594137931034, -1.512763630769231 ], [ 29.743863620689655, -1.512763630769231 ], [ 29.743863620689655, -1.512494153846154 ], [ 29.743594137931034, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8837, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.512494153846154 ], [ 29.744402586206895, -1.512763630769231 ], [ 29.744941551724139, -1.512763630769231 ], [ 29.744941551724139, -1.512494153846154 ], [ 29.744402586206895, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8838, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.512494153846154 ], [ 29.761649482758621, -1.513302584615384 ], [ 29.761918965517243, -1.513302584615384 ], [ 29.761918965517243, -1.512494153846154 ], [ 29.761649482758621, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8839, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.512494153846154 ], [ 29.761918965517243, -1.513033107692308 ], [ 29.762188448275861, -1.513033107692308 ], [ 29.762188448275861, -1.512494153846154 ], [ 29.761918965517243, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8840, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.512494153846154 ], [ 29.762188448275861, -1.512763630769231 ], [ 29.762457931034483, -1.512763630769231 ], [ 29.762457931034483, -1.512494153846154 ], [ 29.762188448275861, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8841, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.512494153846154 ], [ 29.762457931034483, -1.512763630769231 ], [ 29.762727413793105, -1.512763630769231 ], [ 29.762727413793105, -1.512494153846154 ], [ 29.762457931034483, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8842, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.512494153846154 ], [ 29.763805344827588, -1.513033107692308 ], [ 29.764074827586207, -1.513033107692308 ], [ 29.764074827586207, -1.512494153846154 ], [ 29.763805344827588, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8843, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.512494153846154 ], [ 29.764074827586207, -1.512763630769231 ], [ 29.764344310344828, -1.512763630769231 ], [ 29.764344310344828, -1.512494153846154 ], [ 29.764074827586207, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8844, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.512494153846154 ], [ 29.764344310344828, -1.512763630769231 ], [ 29.76461379310345, -1.512763630769231 ], [ 29.76461379310345, -1.512494153846154 ], [ 29.764344310344828, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8845, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.512494153846154 ], [ 29.76461379310345, -1.512763630769231 ], [ 29.764883275862069, -1.512763630769231 ], [ 29.764883275862069, -1.512494153846154 ], [ 29.76461379310345, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8846, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.512494153846154 ], [ 29.764883275862069, -1.512763630769231 ], [ 29.76515275862069, -1.512763630769231 ], [ 29.76515275862069, -1.512494153846154 ], [ 29.764883275862069, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8847, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.512494153846154 ], [ 29.76515275862069, -1.512763630769231 ], [ 29.765422241379312, -1.512763630769231 ], [ 29.765422241379312, -1.512494153846154 ], [ 29.76515275862069, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8848, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.512494153846154 ], [ 29.765422241379312, -1.512763630769231 ], [ 29.765961206896552, -1.512763630769231 ], [ 29.765961206896552, -1.512494153846154 ], [ 29.765422241379312, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8849, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.512494153846154 ], [ 29.765961206896552, -1.512763630769231 ], [ 29.766230689655174, -1.512763630769231 ], [ 29.766230689655174, -1.512494153846154 ], [ 29.765961206896552, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8850, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.512494153846154 ], [ 29.766230689655174, -1.512763630769231 ], [ 29.766500172413792, -1.512763630769231 ], [ 29.766500172413792, -1.512494153846154 ], [ 29.766230689655174, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8851, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.512494153846154 ], [ 29.766500172413792, -1.512763630769231 ], [ 29.766769655172414, -1.512763630769231 ], [ 29.766769655172414, -1.512494153846154 ], [ 29.766500172413792, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8852, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.512494153846154 ], [ 29.766769655172414, -1.512763630769231 ], [ 29.767039137931036, -1.512763630769231 ], [ 29.767039137931036, -1.512494153846154 ], [ 29.766769655172414, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8853, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.512494153846154 ], [ 29.767039137931036, -1.512763630769231 ], [ 29.767308620689654, -1.512763630769231 ], [ 29.767308620689654, -1.512494153846154 ], [ 29.767039137931036, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8854, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713412068965518, -1.512763630769231 ], [ 29.713412068965518, -1.513033107692308 ], [ 29.713681551724139, -1.513033107692308 ], [ 29.713681551724139, -1.512763630769231 ], [ 29.713412068965518, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8855, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713681551724139, -1.512763630769231 ], [ 29.713681551724139, -1.513302584615384 ], [ 29.713951034482758, -1.513302584615384 ], [ 29.713951034482758, -1.512763630769231 ], [ 29.713681551724139, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8856, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713951034482758, -1.512763630769231 ], [ 29.713951034482758, -1.513033107692308 ], [ 29.71422051724138, -1.513033107692308 ], [ 29.71422051724138, -1.512763630769231 ], [ 29.713951034482758, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8857, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71475948275862, -1.512763630769231 ], [ 29.71475948275862, -1.513302584615384 ], [ 29.715028965517241, -1.513302584615384 ], [ 29.715028965517241, -1.512763630769231 ], [ 29.71475948275862, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8858, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.512763630769231 ], [ 29.717184827586205, -1.513033107692308 ], [ 29.717454310344827, -1.513033107692308 ], [ 29.717454310344827, -1.512763630769231 ], [ 29.717184827586205, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8859, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.512763630769231 ], [ 29.719610172413795, -1.513033107692308 ], [ 29.719879655172413, -1.513033107692308 ], [ 29.719879655172413, -1.512763630769231 ], [ 29.719610172413795, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8860, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.512763630769231 ], [ 29.719879655172413, -1.513033107692308 ], [ 29.720149137931035, -1.513033107692308 ], [ 29.720149137931035, -1.512763630769231 ], [ 29.719879655172413, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8861, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.512763630769231 ], [ 29.720149137931035, -1.513033107692308 ], [ 29.720688103448275, -1.513033107692308 ], [ 29.720688103448275, -1.512763630769231 ], [ 29.720149137931035, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8862, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.512763630769231 ], [ 29.720688103448275, -1.513033107692308 ], [ 29.720957586206897, -1.513033107692308 ], [ 29.720957586206897, -1.512763630769231 ], [ 29.720688103448275, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8863, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.512763630769231 ], [ 29.720957586206897, -1.513033107692308 ], [ 29.721227068965518, -1.513033107692308 ], [ 29.721227068965518, -1.512763630769231 ], [ 29.720957586206897, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8864, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.512763630769231 ], [ 29.721227068965518, -1.513033107692308 ], [ 29.721496551724137, -1.513033107692308 ], [ 29.721496551724137, -1.512763630769231 ], [ 29.721227068965518, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8865, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.512763630769231 ], [ 29.721496551724137, -1.513033107692308 ], [ 29.722305, -1.513033107692308 ], [ 29.722305, -1.512763630769231 ], [ 29.721496551724137, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8866, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.512763630769231 ], [ 29.722305, -1.513033107692308 ], [ 29.72257448275862, -1.513033107692308 ], [ 29.72257448275862, -1.512763630769231 ], [ 29.722305, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8867, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.512763630769231 ], [ 29.72257448275862, -1.513033107692308 ], [ 29.722843965517242, -1.513033107692308 ], [ 29.722843965517242, -1.512763630769231 ], [ 29.72257448275862, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8868, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.512763630769231 ], [ 29.722843965517242, -1.513033107692308 ], [ 29.723113448275861, -1.513033107692308 ], [ 29.723113448275861, -1.512763630769231 ], [ 29.722843965517242, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8869, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.512763630769231 ], [ 29.723113448275861, -1.513033107692308 ], [ 29.723382931034482, -1.513033107692308 ], [ 29.723382931034482, -1.512763630769231 ], [ 29.723113448275861, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8870, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.512763630769231 ], [ 29.723382931034482, -1.513033107692308 ], [ 29.723652413793104, -1.513033107692308 ], [ 29.723652413793104, -1.512763630769231 ], [ 29.723382931034482, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8871, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.512763630769231 ], [ 29.723652413793104, -1.513033107692308 ], [ 29.723921896551722, -1.513033107692308 ], [ 29.723921896551722, -1.512763630769231 ], [ 29.723652413793104, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8872, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.512763630769231 ], [ 29.723921896551722, -1.513033107692308 ], [ 29.724191379310344, -1.513033107692308 ], [ 29.724191379310344, -1.512763630769231 ], [ 29.723921896551722, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8873, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.512763630769231 ], [ 29.724191379310344, -1.513033107692308 ], [ 29.724460862068966, -1.513033107692308 ], [ 29.724460862068966, -1.512763630769231 ], [ 29.724191379310344, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8874, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.512763630769231 ], [ 29.724460862068966, -1.513033107692308 ], [ 29.724730344827588, -1.513033107692308 ], [ 29.724730344827588, -1.512763630769231 ], [ 29.724460862068966, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8875, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.512763630769231 ], [ 29.724730344827588, -1.513033107692308 ], [ 29.724999827586206, -1.513033107692308 ], [ 29.724999827586206, -1.512763630769231 ], [ 29.724730344827588, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8876, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.512763630769231 ], [ 29.724999827586206, -1.513033107692308 ], [ 29.725269310344828, -1.513033107692308 ], [ 29.725269310344828, -1.512763630769231 ], [ 29.724999827586206, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8877, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.512763630769231 ], [ 29.725269310344828, -1.513033107692308 ], [ 29.72553879310345, -1.513033107692308 ], [ 29.72553879310345, -1.512763630769231 ], [ 29.725269310344828, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8878, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.512763630769231 ], [ 29.72553879310345, -1.513033107692308 ], [ 29.725808275862068, -1.513033107692308 ], [ 29.725808275862068, -1.512763630769231 ], [ 29.72553879310345, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8879, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.512763630769231 ], [ 29.725808275862068, -1.513033107692308 ], [ 29.72607775862069, -1.513033107692308 ], [ 29.72607775862069, -1.512763630769231 ], [ 29.725808275862068, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8880, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.512763630769231 ], [ 29.72607775862069, -1.513033107692308 ], [ 29.726347241379312, -1.513033107692308 ], [ 29.726347241379312, -1.512763630769231 ], [ 29.72607775862069, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8881, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.512763630769231 ], [ 29.726347241379312, -1.513033107692308 ], [ 29.72661672413793, -1.513033107692308 ], [ 29.72661672413793, -1.512763630769231 ], [ 29.726347241379312, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8882, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.512763630769231 ], [ 29.726886206896552, -1.513033107692308 ], [ 29.727155689655174, -1.513033107692308 ], [ 29.727155689655174, -1.512763630769231 ], [ 29.726886206896552, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8883, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.512763630769231 ], [ 29.727155689655174, -1.513033107692308 ], [ 29.727425172413792, -1.513033107692308 ], [ 29.727425172413792, -1.512763630769231 ], [ 29.727155689655174, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8884, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.512763630769231 ], [ 29.727425172413792, -1.513033107692308 ], [ 29.727694655172414, -1.513033107692308 ], [ 29.727694655172414, -1.512763630769231 ], [ 29.727425172413792, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8885, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.512763630769231 ], [ 29.727694655172414, -1.513033107692308 ], [ 29.727964137931036, -1.513033107692308 ], [ 29.727964137931036, -1.512763630769231 ], [ 29.727694655172414, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8886, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.512763630769231 ], [ 29.727964137931036, -1.513033107692308 ], [ 29.728233620689654, -1.513033107692308 ], [ 29.728233620689654, -1.512763630769231 ], [ 29.727964137931036, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8887, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.512763630769231 ], [ 29.728233620689654, -1.513033107692308 ], [ 29.728503103448276, -1.513033107692308 ], [ 29.728503103448276, -1.512763630769231 ], [ 29.728233620689654, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8888, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.512763630769231 ], [ 29.728503103448276, -1.513033107692308 ], [ 29.728772586206897, -1.513033107692308 ], [ 29.728772586206897, -1.512763630769231 ], [ 29.728503103448276, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8889, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.512763630769231 ], [ 29.728772586206897, -1.513033107692308 ], [ 29.729042068965516, -1.513033107692308 ], [ 29.729042068965516, -1.512763630769231 ], [ 29.728772586206897, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8890, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.512763630769231 ], [ 29.729042068965516, -1.513033107692308 ], [ 29.729311551724138, -1.513033107692308 ], [ 29.729311551724138, -1.512763630769231 ], [ 29.729042068965516, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8891, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.512763630769231 ], [ 29.729311551724138, -1.513033107692308 ], [ 29.729850517241381, -1.513033107692308 ], [ 29.729850517241381, -1.512763630769231 ], [ 29.729311551724138, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8892, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.512763630769231 ], [ 29.729850517241381, -1.513033107692308 ], [ 29.73012, -1.513033107692308 ], [ 29.73012, -1.512763630769231 ], [ 29.729850517241381, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8893, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.512763630769231 ], [ 29.73012, -1.513033107692308 ], [ 29.730389482758621, -1.513033107692308 ], [ 29.730389482758621, -1.512763630769231 ], [ 29.73012, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8894, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.512763630769231 ], [ 29.730389482758621, -1.513033107692308 ], [ 29.730658965517243, -1.513033107692308 ], [ 29.730658965517243, -1.512763630769231 ], [ 29.730389482758621, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8895, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.512763630769231 ], [ 29.730658965517243, -1.513033107692308 ], [ 29.731197931034483, -1.513033107692308 ], [ 29.731197931034483, -1.512763630769231 ], [ 29.730658965517243, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8896, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.512763630769231 ], [ 29.731197931034483, -1.513033107692308 ], [ 29.731467413793105, -1.513033107692308 ], [ 29.731467413793105, -1.512763630769231 ], [ 29.731197931034483, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8897, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.512763630769231 ], [ 29.731467413793105, -1.513033107692308 ], [ 29.732814827586207, -1.513033107692308 ], [ 29.732814827586207, -1.512763630769231 ], [ 29.731467413793105, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8898, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.512763630769231 ], [ 29.732814827586207, -1.513033107692308 ], [ 29.733084310344829, -1.513033107692308 ], [ 29.733084310344829, -1.512763630769231 ], [ 29.732814827586207, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8899, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.512763630769231 ], [ 29.733084310344829, -1.513033107692308 ], [ 29.733353793103447, -1.513033107692308 ], [ 29.733353793103447, -1.512763630769231 ], [ 29.733084310344829, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8900, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.512763630769231 ], [ 29.733353793103447, -1.513033107692308 ], [ 29.733623275862069, -1.513033107692308 ], [ 29.733623275862069, -1.512763630769231 ], [ 29.733353793103447, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8901, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.512763630769231 ], [ 29.733623275862069, -1.513033107692308 ], [ 29.733892758620691, -1.513033107692308 ], [ 29.733892758620691, -1.512763630769231 ], [ 29.733623275862069, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8902, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.512763630769231 ], [ 29.733892758620691, -1.513033107692308 ], [ 29.734162241379309, -1.513033107692308 ], [ 29.734162241379309, -1.512763630769231 ], [ 29.733892758620691, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8903, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.512763630769231 ], [ 29.734162241379309, -1.513033107692308 ], [ 29.734431724137931, -1.513033107692308 ], [ 29.734431724137931, -1.512763630769231 ], [ 29.734162241379309, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8904, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.512763630769231 ], [ 29.734431724137931, -1.513033107692308 ], [ 29.734701206896553, -1.513033107692308 ], [ 29.734701206896553, -1.512763630769231 ], [ 29.734431724137931, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8905, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.512763630769231 ], [ 29.734701206896553, -1.513033107692308 ], [ 29.734970689655171, -1.513033107692308 ], [ 29.734970689655171, -1.512763630769231 ], [ 29.734701206896553, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8906, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.512763630769231 ], [ 29.734970689655171, -1.513033107692308 ], [ 29.735240172413793, -1.513033107692308 ], [ 29.735240172413793, -1.512763630769231 ], [ 29.734970689655171, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8907, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.512763630769231 ], [ 29.735240172413793, -1.513033107692308 ], [ 29.735509655172415, -1.513033107692308 ], [ 29.735509655172415, -1.512763630769231 ], [ 29.735240172413793, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8908, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.512763630769231 ], [ 29.735509655172415, -1.513033107692308 ], [ 29.735779137931036, -1.513033107692308 ], [ 29.735779137931036, -1.512763630769231 ], [ 29.735509655172415, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8909, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.512763630769231 ], [ 29.735779137931036, -1.513033107692308 ], [ 29.736048620689655, -1.513033107692308 ], [ 29.736048620689655, -1.513302584615384 ], [ 29.736318103448276, -1.513302584615384 ], [ 29.736318103448276, -1.512763630769231 ], [ 29.735779137931036, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8910, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.512763630769231 ], [ 29.736318103448276, -1.513033107692308 ], [ 29.736587586206898, -1.513033107692308 ], [ 29.736587586206898, -1.512763630769231 ], [ 29.736318103448276, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8911, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.512763630769231 ], [ 29.736587586206898, -1.513033107692308 ], [ 29.736857068965517, -1.513033107692308 ], [ 29.736857068965517, -1.512763630769231 ], [ 29.736587586206898, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8912, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.512763630769231 ], [ 29.736857068965517, -1.513033107692308 ], [ 29.737126551724138, -1.513033107692308 ], [ 29.737126551724138, -1.512763630769231 ], [ 29.736857068965517, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8913, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.512763630769231 ], [ 29.737665517241378, -1.513302584615384 ], [ 29.737935, -1.513302584615384 ], [ 29.737935, -1.512763630769231 ], [ 29.737665517241378, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8914, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.512763630769231 ], [ 29.737935, -1.513302584615384 ], [ 29.738204482758622, -1.513302584615384 ], [ 29.738204482758622, -1.512763630769231 ], [ 29.737935, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8915, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.512763630769231 ], [ 29.738204482758622, -1.513302584615384 ], [ 29.738743448275862, -1.513302584615384 ], [ 29.738743448275862, -1.512763630769231 ], [ 29.738204482758622, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8916, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.512763630769231 ], [ 29.739012931034484, -1.513572061538462 ], [ 29.739282413793102, -1.513572061538462 ], [ 29.739282413793102, -1.512763630769231 ], [ 29.739012931034484, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8917, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.512763630769231 ], [ 29.739282413793102, -1.513572061538462 ], [ 29.739551896551724, -1.513572061538462 ], [ 29.739551896551724, -1.512763630769231 ], [ 29.739282413793102, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8918, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.512763630769231 ], [ 29.739551896551724, -1.513033107692308 ], [ 29.739821379310346, -1.513033107692308 ], [ 29.739821379310346, -1.512763630769231 ], [ 29.739551896551724, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8919, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743863620689655, -1.512763630769231 ], [ 29.743863620689655, -1.513033107692308 ], [ 29.744133103448277, -1.513033107692308 ], [ 29.744133103448277, -1.512763630769231 ], [ 29.743863620689655, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8920, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.512763630769231 ], [ 29.744402586206895, -1.513572061538462 ], [ 29.744672068965517, -1.513572061538462 ], [ 29.744672068965517, -1.513302584615384 ], [ 29.744941551724139, -1.513302584615384 ], [ 29.744941551724139, -1.513033107692308 ], [ 29.744672068965517, -1.513033107692308 ], [ 29.744672068965517, -1.512763630769231 ], [ 29.744402586206895, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8921, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.512763630769231 ], [ 29.744672068965517, -1.513033107692308 ], [ 29.744941551724139, -1.513033107692308 ], [ 29.744941551724139, -1.512763630769231 ], [ 29.744672068965517, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8922, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.512763630769231 ], [ 29.744941551724139, -1.513033107692308 ], [ 29.745211034482757, -1.513033107692308 ], [ 29.745211034482757, -1.512763630769231 ], [ 29.744941551724139, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8923, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.512763630769231 ], [ 29.761110517241381, -1.513033107692308 ], [ 29.76138, -1.513033107692308 ], [ 29.76138, -1.512763630769231 ], [ 29.761110517241381, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8924, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.512763630769231 ], [ 29.762457931034483, -1.513572061538462 ], [ 29.762727413793105, -1.513572061538462 ], [ 29.762727413793105, -1.512763630769231 ], [ 29.762457931034483, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8925, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.512763630769231 ], [ 29.763535862068967, -1.513302584615384 ], [ 29.763805344827588, -1.513302584615384 ], [ 29.763805344827588, -1.512763630769231 ], [ 29.763535862068967, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8926, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.512763630769231 ], [ 29.764344310344828, -1.513302584615384 ], [ 29.76461379310345, -1.513302584615384 ], [ 29.76461379310345, -1.512763630769231 ], [ 29.764344310344828, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8927, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.512763630769231 ], [ 29.76461379310345, -1.513033107692308 ], [ 29.764883275862069, -1.513033107692308 ], [ 29.764883275862069, -1.512763630769231 ], [ 29.76461379310345, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8928, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.512763630769231 ], [ 29.764883275862069, -1.513033107692308 ], [ 29.76515275862069, -1.513033107692308 ], [ 29.76515275862069, -1.512763630769231 ], [ 29.764883275862069, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8929, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.512763630769231 ], [ 29.76515275862069, -1.513033107692308 ], [ 29.765422241379312, -1.513033107692308 ], [ 29.765422241379312, -1.512763630769231 ], [ 29.76515275862069, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8930, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.512763630769231 ], [ 29.765422241379312, -1.513033107692308 ], [ 29.765961206896552, -1.513033107692308 ], [ 29.765961206896552, -1.512763630769231 ], [ 29.765422241379312, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8931, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.512763630769231 ], [ 29.765961206896552, -1.513033107692308 ], [ 29.766230689655174, -1.513033107692308 ], [ 29.766230689655174, -1.512763630769231 ], [ 29.765961206896552, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8932, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.512763630769231 ], [ 29.766230689655174, -1.513033107692308 ], [ 29.766500172413792, -1.513033107692308 ], [ 29.766500172413792, -1.512763630769231 ], [ 29.766230689655174, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8933, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.512763630769231 ], [ 29.766500172413792, -1.513033107692308 ], [ 29.766769655172414, -1.513033107692308 ], [ 29.766769655172414, -1.512763630769231 ], [ 29.766500172413792, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8934, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.512763630769231 ], [ 29.766769655172414, -1.513033107692308 ], [ 29.767039137931036, -1.513033107692308 ], [ 29.767039137931036, -1.512763630769231 ], [ 29.766769655172414, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8935, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.512763630769231 ], [ 29.767039137931036, -1.513033107692308 ], [ 29.767308620689654, -1.513033107692308 ], [ 29.767308620689654, -1.512763630769231 ], [ 29.767039137931036, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8936, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.511416246153846 ], [ 29.767847586206898, -1.511416246153846 ], [ 29.767847586206898, -1.513033107692308 ], [ 29.767308620689654, -1.513033107692308 ], [ 29.767308620689654, -1.512763630769231 ], [ 29.767578103448276, -1.512763630769231 ], [ 29.767578103448276, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8937, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71449, -1.513033107692308 ], [ 29.71449, -1.513302584615384 ], [ 29.71475948275862, -1.513302584615384 ], [ 29.71475948275862, -1.513033107692308 ], [ 29.71449, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8938, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.513033107692308 ], [ 29.719879655172413, -1.513302584615384 ], [ 29.720149137931035, -1.513302584615384 ], [ 29.720149137931035, -1.513033107692308 ], [ 29.719879655172413, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8939, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.513033107692308 ], [ 29.720149137931035, -1.513302584615384 ], [ 29.720688103448275, -1.513302584615384 ], [ 29.720688103448275, -1.513033107692308 ], [ 29.720149137931035, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8940, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.513033107692308 ], [ 29.720688103448275, -1.513302584615384 ], [ 29.720957586206897, -1.513302584615384 ], [ 29.720957586206897, -1.513033107692308 ], [ 29.720688103448275, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8941, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.513033107692308 ], [ 29.720957586206897, -1.513302584615384 ], [ 29.721227068965518, -1.513302584615384 ], [ 29.721227068965518, -1.513033107692308 ], [ 29.720957586206897, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8942, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.513033107692308 ], [ 29.721227068965518, -1.513302584615384 ], [ 29.721496551724137, -1.513302584615384 ], [ 29.721496551724137, -1.513033107692308 ], [ 29.721227068965518, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8943, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.513033107692308 ], [ 29.721496551724137, -1.513302584615384 ], [ 29.722305, -1.513302584615384 ], [ 29.722305, -1.513033107692308 ], [ 29.721496551724137, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8944, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.513033107692308 ], [ 29.722305, -1.513302584615384 ], [ 29.72257448275862, -1.513302584615384 ], [ 29.72257448275862, -1.513033107692308 ], [ 29.722305, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8945, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.513033107692308 ], [ 29.72257448275862, -1.513302584615384 ], [ 29.722843965517242, -1.513302584615384 ], [ 29.722843965517242, -1.513033107692308 ], [ 29.72257448275862, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8946, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.513033107692308 ], [ 29.722843965517242, -1.513302584615384 ], [ 29.723113448275861, -1.513302584615384 ], [ 29.723113448275861, -1.513033107692308 ], [ 29.722843965517242, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8947, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.513033107692308 ], [ 29.723113448275861, -1.513302584615384 ], [ 29.723382931034482, -1.513302584615384 ], [ 29.723382931034482, -1.513033107692308 ], [ 29.723113448275861, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8948, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.513033107692308 ], [ 29.723382931034482, -1.513302584615384 ], [ 29.723652413793104, -1.513302584615384 ], [ 29.723652413793104, -1.513033107692308 ], [ 29.723382931034482, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8949, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.513033107692308 ], [ 29.723652413793104, -1.513302584615384 ], [ 29.723921896551722, -1.513302584615384 ], [ 29.723921896551722, -1.513033107692308 ], [ 29.723652413793104, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8950, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.513033107692308 ], [ 29.723921896551722, -1.513302584615384 ], [ 29.724191379310344, -1.513302584615384 ], [ 29.724191379310344, -1.513033107692308 ], [ 29.723921896551722, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8951, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.513033107692308 ], [ 29.724191379310344, -1.513302584615384 ], [ 29.724460862068966, -1.513302584615384 ], [ 29.724460862068966, -1.513033107692308 ], [ 29.724191379310344, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8952, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.513033107692308 ], [ 29.724460862068966, -1.513302584615384 ], [ 29.724730344827588, -1.513302584615384 ], [ 29.724730344827588, -1.513033107692308 ], [ 29.724460862068966, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8953, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.513033107692308 ], [ 29.724730344827588, -1.513302584615384 ], [ 29.724999827586206, -1.513302584615384 ], [ 29.724999827586206, -1.513033107692308 ], [ 29.724730344827588, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8954, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.513033107692308 ], [ 29.724999827586206, -1.513302584615384 ], [ 29.725269310344828, -1.513302584615384 ], [ 29.725269310344828, -1.513033107692308 ], [ 29.724999827586206, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8955, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.513033107692308 ], [ 29.725269310344828, -1.513302584615384 ], [ 29.72553879310345, -1.513302584615384 ], [ 29.72553879310345, -1.513033107692308 ], [ 29.725269310344828, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8956, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.513033107692308 ], [ 29.72553879310345, -1.513302584615384 ], [ 29.725808275862068, -1.513302584615384 ], [ 29.725808275862068, -1.513033107692308 ], [ 29.72553879310345, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8957, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.513033107692308 ], [ 29.725808275862068, -1.513302584615384 ], [ 29.72607775862069, -1.513302584615384 ], [ 29.72607775862069, -1.513033107692308 ], [ 29.725808275862068, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8958, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.513033107692308 ], [ 29.72607775862069, -1.513302584615384 ], [ 29.726347241379312, -1.513302584615384 ], [ 29.726347241379312, -1.513033107692308 ], [ 29.72607775862069, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8959, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.513033107692308 ], [ 29.726347241379312, -1.513302584615384 ], [ 29.72661672413793, -1.513302584615384 ], [ 29.72661672413793, -1.513033107692308 ], [ 29.726347241379312, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8960, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.513033107692308 ], [ 29.72661672413793, -1.513302584615384 ], [ 29.726886206896552, -1.513302584615384 ], [ 29.726886206896552, -1.513033107692308 ], [ 29.72661672413793, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8961, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.513033107692308 ], [ 29.726886206896552, -1.513302584615384 ], [ 29.727155689655174, -1.513302584615384 ], [ 29.727155689655174, -1.513033107692308 ], [ 29.726886206896552, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8962, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.513033107692308 ], [ 29.727155689655174, -1.513302584615384 ], [ 29.727425172413792, -1.513302584615384 ], [ 29.727425172413792, -1.513033107692308 ], [ 29.727155689655174, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8963, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.513033107692308 ], [ 29.727425172413792, -1.513302584615384 ], [ 29.727694655172414, -1.513302584615384 ], [ 29.727694655172414, -1.513033107692308 ], [ 29.727425172413792, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8964, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.513033107692308 ], [ 29.727694655172414, -1.513302584615384 ], [ 29.727964137931036, -1.513302584615384 ], [ 29.727964137931036, -1.513033107692308 ], [ 29.727694655172414, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8965, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.513033107692308 ], [ 29.727964137931036, -1.513302584615384 ], [ 29.728233620689654, -1.513302584615384 ], [ 29.728233620689654, -1.513033107692308 ], [ 29.727964137931036, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8966, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.513033107692308 ], [ 29.728233620689654, -1.513302584615384 ], [ 29.728503103448276, -1.513302584615384 ], [ 29.728503103448276, -1.513033107692308 ], [ 29.728233620689654, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8967, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.513033107692308 ], [ 29.728503103448276, -1.513302584615384 ], [ 29.728772586206897, -1.513302584615384 ], [ 29.728772586206897, -1.513033107692308 ], [ 29.728503103448276, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8968, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.513033107692308 ], [ 29.728772586206897, -1.513302584615384 ], [ 29.729042068965516, -1.513302584615384 ], [ 29.729042068965516, -1.513033107692308 ], [ 29.728772586206897, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8969, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.513033107692308 ], [ 29.729042068965516, -1.513302584615384 ], [ 29.729311551724138, -1.513302584615384 ], [ 29.729311551724138, -1.513033107692308 ], [ 29.729042068965516, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8970, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.513033107692308 ], [ 29.729311551724138, -1.513302584615384 ], [ 29.729850517241381, -1.513302584615384 ], [ 29.729850517241381, -1.513033107692308 ], [ 29.729311551724138, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8971, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.513033107692308 ], [ 29.729850517241381, -1.513302584615384 ], [ 29.73012, -1.513302584615384 ], [ 29.73012, -1.513033107692308 ], [ 29.729850517241381, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8972, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.513033107692308 ], [ 29.73012, -1.513302584615384 ], [ 29.730389482758621, -1.513302584615384 ], [ 29.730389482758621, -1.513033107692308 ], [ 29.73012, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8973, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.513033107692308 ], [ 29.730389482758621, -1.513302584615384 ], [ 29.730658965517243, -1.513302584615384 ], [ 29.730658965517243, -1.513033107692308 ], [ 29.730389482758621, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8974, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.513033107692308 ], [ 29.730658965517243, -1.513302584615384 ], [ 29.730928448275861, -1.513302584615384 ], [ 29.730928448275861, -1.513033107692308 ], [ 29.730658965517243, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8975, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.513033107692308 ], [ 29.730928448275861, -1.513302584615384 ], [ 29.731197931034483, -1.513302584615384 ], [ 29.731197931034483, -1.513033107692308 ], [ 29.730928448275861, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8976, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.513033107692308 ], [ 29.731197931034483, -1.513302584615384 ], [ 29.731736896551723, -1.513302584615384 ], [ 29.731736896551723, -1.513033107692308 ], [ 29.731197931034483, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8977, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.513033107692308 ], [ 29.731736896551723, -1.513302584615384 ], [ 29.732545344827585, -1.513302584615384 ], [ 29.732545344827585, -1.513033107692308 ], [ 29.731736896551723, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8978, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.513033107692308 ], [ 29.732545344827585, -1.513302584615384 ], [ 29.733084310344829, -1.513302584615384 ], [ 29.733084310344829, -1.513033107692308 ], [ 29.732545344827585, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8979, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.513033107692308 ], [ 29.733084310344829, -1.513302584615384 ], [ 29.733353793103447, -1.513302584615384 ], [ 29.733353793103447, -1.513033107692308 ], [ 29.733084310344829, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8980, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.513033107692308 ], [ 29.733353793103447, -1.513302584615384 ], [ 29.733623275862069, -1.513302584615384 ], [ 29.733623275862069, -1.513033107692308 ], [ 29.733353793103447, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8981, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.513033107692308 ], [ 29.733623275862069, -1.513302584615384 ], [ 29.733892758620691, -1.513302584615384 ], [ 29.733892758620691, -1.513033107692308 ], [ 29.733623275862069, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8982, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.513033107692308 ], [ 29.733892758620691, -1.513302584615384 ], [ 29.734162241379309, -1.513302584615384 ], [ 29.734162241379309, -1.513033107692308 ], [ 29.733892758620691, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8983, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.513033107692308 ], [ 29.734162241379309, -1.513302584615384 ], [ 29.734431724137931, -1.513302584615384 ], [ 29.734431724137931, -1.513033107692308 ], [ 29.734162241379309, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8984, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.513033107692308 ], [ 29.734431724137931, -1.513302584615384 ], [ 29.734701206896553, -1.513302584615384 ], [ 29.734701206896553, -1.513033107692308 ], [ 29.734431724137931, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8985, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.513033107692308 ], [ 29.734701206896553, -1.513302584615384 ], [ 29.734970689655171, -1.513302584615384 ], [ 29.734970689655171, -1.513033107692308 ], [ 29.734701206896553, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8986, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.513033107692308 ], [ 29.734970689655171, -1.513302584615384 ], [ 29.735240172413793, -1.513302584615384 ], [ 29.735240172413793, -1.513033107692308 ], [ 29.734970689655171, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8987, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.513033107692308 ], [ 29.735240172413793, -1.513302584615384 ], [ 29.735509655172415, -1.513302584615384 ], [ 29.735509655172415, -1.513033107692308 ], [ 29.735240172413793, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8988, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.513033107692308 ], [ 29.735509655172415, -1.513302584615384 ], [ 29.735779137931036, -1.513302584615384 ], [ 29.735779137931036, -1.513033107692308 ], [ 29.735509655172415, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8989, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.513033107692308 ], [ 29.735779137931036, -1.513572061538462 ], [ 29.736048620689655, -1.513572061538462 ], [ 29.736048620689655, -1.513033107692308 ], [ 29.735779137931036, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8990, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.513033107692308 ], [ 29.736587586206898, -1.513572061538462 ], [ 29.736857068965517, -1.513572061538462 ], [ 29.736857068965517, -1.513033107692308 ], [ 29.736587586206898, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8991, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.513033107692308 ], [ 29.736857068965517, -1.513302584615384 ], [ 29.737126551724138, -1.513302584615384 ], [ 29.737126551724138, -1.513033107692308 ], [ 29.736857068965517, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8992, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.513033107692308 ], [ 29.737126551724138, -1.513302584615384 ], [ 29.73739603448276, -1.513302584615384 ], [ 29.73739603448276, -1.513033107692308 ], [ 29.737126551724138, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8993, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.513033107692308 ], [ 29.73739603448276, -1.513302584615384 ], [ 29.737665517241378, -1.513302584615384 ], [ 29.737665517241378, -1.513033107692308 ], [ 29.73739603448276, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8994, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.513033107692308 ], [ 29.738743448275862, -1.513302584615384 ], [ 29.739012931034484, -1.513302584615384 ], [ 29.739012931034484, -1.513033107692308 ], [ 29.738743448275862, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8995, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.513033107692308 ], [ 29.744941551724139, -1.513302584615384 ], [ 29.745211034482757, -1.513302584615384 ], [ 29.745211034482757, -1.513033107692308 ], [ 29.744941551724139, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8996, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.513033107692308 ], [ 29.745211034482757, -1.513302584615384 ], [ 29.745480517241379, -1.513302584615384 ], [ 29.745480517241379, -1.513033107692308 ], [ 29.745211034482757, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8997, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.513033107692308 ], [ 29.76515275862069, -1.513302584615384 ], [ 29.765422241379312, -1.513302584615384 ], [ 29.765422241379312, -1.513033107692308 ], [ 29.76515275862069, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8998, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.513033107692308 ], [ 29.765422241379312, -1.513302584615384 ], [ 29.765961206896552, -1.513302584615384 ], [ 29.765961206896552, -1.513033107692308 ], [ 29.765422241379312, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8999, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.513033107692308 ], [ 29.765961206896552, -1.513302584615384 ], [ 29.766230689655174, -1.513302584615384 ], [ 29.766230689655174, -1.513033107692308 ], [ 29.765961206896552, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9000, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.513033107692308 ], [ 29.766230689655174, -1.513572061538462 ], [ 29.766500172413792, -1.513572061538462 ], [ 29.766500172413792, -1.513033107692308 ], [ 29.766230689655174, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9001, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.513033107692308 ], [ 29.766500172413792, -1.513302584615384 ], [ 29.766769655172414, -1.513302584615384 ], [ 29.766769655172414, -1.513033107692308 ], [ 29.766500172413792, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9002, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.513033107692308 ], [ 29.766769655172414, -1.513302584615384 ], [ 29.767039137931036, -1.513302584615384 ], [ 29.767039137931036, -1.513033107692308 ], [ 29.766769655172414, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9003, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.513033107692308 ], [ 29.767039137931036, -1.513302584615384 ], [ 29.767308620689654, -1.513302584615384 ], [ 29.767308620689654, -1.513033107692308 ], [ 29.767039137931036, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9004, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.513033107692308 ], [ 29.767308620689654, -1.513302584615384 ], [ 29.767578103448276, -1.513302584615384 ], [ 29.767578103448276, -1.513033107692308 ], [ 29.767308620689654, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9005, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.511146769230769 ], [ 29.768117068965516, -1.511146769230769 ], [ 29.768117068965516, -1.513302584615384 ], [ 29.767578103448276, -1.513302584615384 ], [ 29.767578103448276, -1.513033107692308 ], [ 29.767847586206898, -1.513033107692308 ], [ 29.767847586206898, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9006, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713681551724139, -1.513302584615384 ], [ 29.713681551724139, -1.513572061538462 ], [ 29.713951034482758, -1.513572061538462 ], [ 29.713951034482758, -1.513302584615384 ], [ 29.713681551724139, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9007, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71449, -1.513302584615384 ], [ 29.71449, -1.513572061538462 ], [ 29.71475948275862, -1.513572061538462 ], [ 29.71475948275862, -1.513302584615384 ], [ 29.71449, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9008, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.513302584615384 ], [ 29.719610172413795, -1.513572061538462 ], [ 29.719879655172413, -1.513572061538462 ], [ 29.719879655172413, -1.513302584615384 ], [ 29.719610172413795, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9009, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.513302584615384 ], [ 29.719879655172413, -1.513572061538462 ], [ 29.720149137931035, -1.513572061538462 ], [ 29.720149137931035, -1.513302584615384 ], [ 29.719879655172413, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9010, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.513302584615384 ], [ 29.720149137931035, -1.513572061538462 ], [ 29.720688103448275, -1.513572061538462 ], [ 29.720688103448275, -1.513302584615384 ], [ 29.720149137931035, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9011, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.513302584615384 ], [ 29.720688103448275, -1.513572061538462 ], [ 29.720957586206897, -1.513572061538462 ], [ 29.720957586206897, -1.513302584615384 ], [ 29.720688103448275, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9012, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.513302584615384 ], [ 29.720957586206897, -1.513572061538462 ], [ 29.721227068965518, -1.513572061538462 ], [ 29.721227068965518, -1.513302584615384 ], [ 29.720957586206897, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9013, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.513302584615384 ], [ 29.721227068965518, -1.513572061538462 ], [ 29.721496551724137, -1.513572061538462 ], [ 29.721496551724137, -1.513302584615384 ], [ 29.721227068965518, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9014, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.513302584615384 ], [ 29.721496551724137, -1.513572061538462 ], [ 29.722305, -1.513572061538462 ], [ 29.722305, -1.513302584615384 ], [ 29.721496551724137, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9015, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.513302584615384 ], [ 29.722305, -1.513572061538462 ], [ 29.72257448275862, -1.513572061538462 ], [ 29.72257448275862, -1.513302584615384 ], [ 29.722305, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9016, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.513302584615384 ], [ 29.72257448275862, -1.513572061538462 ], [ 29.722843965517242, -1.513572061538462 ], [ 29.722843965517242, -1.513302584615384 ], [ 29.72257448275862, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9017, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.513302584615384 ], [ 29.722843965517242, -1.513572061538462 ], [ 29.723113448275861, -1.513572061538462 ], [ 29.723113448275861, -1.513302584615384 ], [ 29.722843965517242, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9018, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.513302584615384 ], [ 29.723113448275861, -1.513572061538462 ], [ 29.723382931034482, -1.513572061538462 ], [ 29.723382931034482, -1.513302584615384 ], [ 29.723113448275861, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9019, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.513302584615384 ], [ 29.723382931034482, -1.513572061538462 ], [ 29.723652413793104, -1.513572061538462 ], [ 29.723652413793104, -1.513302584615384 ], [ 29.723382931034482, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9020, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.513302584615384 ], [ 29.723652413793104, -1.513572061538462 ], [ 29.723921896551722, -1.513572061538462 ], [ 29.723921896551722, -1.513302584615384 ], [ 29.723652413793104, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9021, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.513302584615384 ], [ 29.723921896551722, -1.513572061538462 ], [ 29.724191379310344, -1.513572061538462 ], [ 29.724191379310344, -1.513302584615384 ], [ 29.723921896551722, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9022, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.513302584615384 ], [ 29.724191379310344, -1.513572061538462 ], [ 29.724460862068966, -1.513572061538462 ], [ 29.724460862068966, -1.513302584615384 ], [ 29.724191379310344, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9023, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.513302584615384 ], [ 29.724460862068966, -1.513572061538462 ], [ 29.724730344827588, -1.513572061538462 ], [ 29.724730344827588, -1.513302584615384 ], [ 29.724460862068966, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9024, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.513302584615384 ], [ 29.724730344827588, -1.513572061538462 ], [ 29.724999827586206, -1.513572061538462 ], [ 29.724999827586206, -1.513302584615384 ], [ 29.724730344827588, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9025, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.513302584615384 ], [ 29.724999827586206, -1.513572061538462 ], [ 29.725269310344828, -1.513572061538462 ], [ 29.725269310344828, -1.513302584615384 ], [ 29.724999827586206, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9026, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.513302584615384 ], [ 29.725269310344828, -1.513572061538462 ], [ 29.72553879310345, -1.513572061538462 ], [ 29.72553879310345, -1.513302584615384 ], [ 29.725269310344828, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9027, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.513302584615384 ], [ 29.72553879310345, -1.513572061538462 ], [ 29.725808275862068, -1.513572061538462 ], [ 29.725808275862068, -1.513302584615384 ], [ 29.72553879310345, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9028, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.513302584615384 ], [ 29.725808275862068, -1.513572061538462 ], [ 29.72607775862069, -1.513572061538462 ], [ 29.72607775862069, -1.513302584615384 ], [ 29.725808275862068, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9029, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.513302584615384 ], [ 29.72607775862069, -1.513572061538462 ], [ 29.726347241379312, -1.513572061538462 ], [ 29.726347241379312, -1.513302584615384 ], [ 29.72607775862069, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9030, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.513302584615384 ], [ 29.726347241379312, -1.513572061538462 ], [ 29.72661672413793, -1.513572061538462 ], [ 29.72661672413793, -1.513302584615384 ], [ 29.726347241379312, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9031, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.513302584615384 ], [ 29.727155689655174, -1.513572061538462 ], [ 29.727425172413792, -1.513572061538462 ], [ 29.727425172413792, -1.513302584615384 ], [ 29.727155689655174, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9032, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.513302584615384 ], [ 29.727425172413792, -1.513572061538462 ], [ 29.727694655172414, -1.513572061538462 ], [ 29.727694655172414, -1.513302584615384 ], [ 29.727425172413792, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9033, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.513302584615384 ], [ 29.727694655172414, -1.513572061538462 ], [ 29.727964137931036, -1.513572061538462 ], [ 29.727964137931036, -1.513302584615384 ], [ 29.727694655172414, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9034, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.513302584615384 ], [ 29.727964137931036, -1.513572061538462 ], [ 29.728233620689654, -1.513572061538462 ], [ 29.728233620689654, -1.513302584615384 ], [ 29.727964137931036, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9035, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.513302584615384 ], [ 29.728233620689654, -1.513572061538462 ], [ 29.728503103448276, -1.513572061538462 ], [ 29.728503103448276, -1.513302584615384 ], [ 29.728233620689654, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9036, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.513302584615384 ], [ 29.728503103448276, -1.513572061538462 ], [ 29.728772586206897, -1.513572061538462 ], [ 29.728772586206897, -1.513302584615384 ], [ 29.728503103448276, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9037, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.513302584615384 ], [ 29.728772586206897, -1.513572061538462 ], [ 29.729042068965516, -1.513572061538462 ], [ 29.729042068965516, -1.513302584615384 ], [ 29.728772586206897, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9038, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.513302584615384 ], [ 29.729042068965516, -1.513572061538462 ], [ 29.729311551724138, -1.513572061538462 ], [ 29.729311551724138, -1.513302584615384 ], [ 29.729042068965516, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9039, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.513302584615384 ], [ 29.729311551724138, -1.513572061538462 ], [ 29.729850517241381, -1.513572061538462 ], [ 29.729850517241381, -1.513302584615384 ], [ 29.729311551724138, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9040, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.513302584615384 ], [ 29.729850517241381, -1.513572061538462 ], [ 29.73012, -1.513572061538462 ], [ 29.73012, -1.513302584615384 ], [ 29.729850517241381, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9041, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.513302584615384 ], [ 29.73012, -1.513572061538462 ], [ 29.730389482758621, -1.513572061538462 ], [ 29.730389482758621, -1.513302584615384 ], [ 29.73012, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9042, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.513302584615384 ], [ 29.730389482758621, -1.513572061538462 ], [ 29.730658965517243, -1.513572061538462 ], [ 29.730658965517243, -1.513302584615384 ], [ 29.730389482758621, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9043, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.513302584615384 ], [ 29.730658965517243, -1.513572061538462 ], [ 29.730928448275861, -1.513572061538462 ], [ 29.730928448275861, -1.513302584615384 ], [ 29.730658965517243, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9044, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.513302584615384 ], [ 29.730928448275861, -1.513572061538462 ], [ 29.731197931034483, -1.513572061538462 ], [ 29.731197931034483, -1.513302584615384 ], [ 29.730928448275861, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9045, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.513302584615384 ], [ 29.731197931034483, -1.513572061538462 ], [ 29.731467413793105, -1.513572061538462 ], [ 29.731467413793105, -1.513302584615384 ], [ 29.731197931034483, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9046, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.513302584615384 ], [ 29.731467413793105, -1.513572061538462 ], [ 29.732814827586207, -1.513572061538462 ], [ 29.732814827586207, -1.513302584615384 ], [ 29.731467413793105, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9047, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.513302584615384 ], [ 29.732814827586207, -1.513572061538462 ], [ 29.733084310344829, -1.513572061538462 ], [ 29.733084310344829, -1.513302584615384 ], [ 29.732814827586207, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9048, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.513302584615384 ], [ 29.733084310344829, -1.513572061538462 ], [ 29.733353793103447, -1.513572061538462 ], [ 29.733353793103447, -1.513302584615384 ], [ 29.733084310344829, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9049, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.513302584615384 ], [ 29.733353793103447, -1.513572061538462 ], [ 29.733623275862069, -1.513572061538462 ], [ 29.733623275862069, -1.513302584615384 ], [ 29.733353793103447, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9050, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.513302584615384 ], [ 29.733623275862069, -1.513572061538462 ], [ 29.733892758620691, -1.513572061538462 ], [ 29.733892758620691, -1.513302584615384 ], [ 29.733623275862069, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9051, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.513302584615384 ], [ 29.733892758620691, -1.513572061538462 ], [ 29.734162241379309, -1.513572061538462 ], [ 29.734162241379309, -1.513302584615384 ], [ 29.733892758620691, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9052, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.513302584615384 ], [ 29.734162241379309, -1.513572061538462 ], [ 29.734431724137931, -1.513572061538462 ], [ 29.734431724137931, -1.513302584615384 ], [ 29.734162241379309, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9053, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.513302584615384 ], [ 29.734431724137931, -1.513572061538462 ], [ 29.734701206896553, -1.513572061538462 ], [ 29.734701206896553, -1.513302584615384 ], [ 29.734431724137931, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9054, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.513302584615384 ], [ 29.734701206896553, -1.513572061538462 ], [ 29.734970689655171, -1.513572061538462 ], [ 29.734970689655171, -1.513302584615384 ], [ 29.734701206896553, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9055, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.513302584615384 ], [ 29.734970689655171, -1.513572061538462 ], [ 29.735240172413793, -1.513572061538462 ], [ 29.735240172413793, -1.513302584615384 ], [ 29.734970689655171, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9056, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.513302584615384 ], [ 29.735240172413793, -1.513572061538462 ], [ 29.735509655172415, -1.513572061538462 ], [ 29.735509655172415, -1.513302584615384 ], [ 29.735240172413793, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9057, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.513302584615384 ], [ 29.735509655172415, -1.513572061538462 ], [ 29.735779137931036, -1.513572061538462 ], [ 29.735779137931036, -1.513302584615384 ], [ 29.735509655172415, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9058, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.513302584615384 ], [ 29.73739603448276, -1.513572061538462 ], [ 29.737665517241378, -1.513572061538462 ], [ 29.737665517241378, -1.513302584615384 ], [ 29.73739603448276, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9059, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.513302584615384 ], [ 29.737665517241378, -1.513572061538462 ], [ 29.737935, -1.513572061538462 ], [ 29.737935, -1.513302584615384 ], [ 29.737665517241378, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9060, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.513302584615384 ], [ 29.737935, -1.513572061538462 ], [ 29.738204482758622, -1.513572061538462 ], [ 29.738204482758622, -1.513302584615384 ], [ 29.737935, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9061, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.513302584615384 ], [ 29.738743448275862, -1.513572061538462 ], [ 29.739012931034484, -1.513572061538462 ], [ 29.739012931034484, -1.513302584615384 ], [ 29.738743448275862, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9062, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744672068965517, -1.513302584615384 ], [ 29.744672068965517, -1.513572061538462 ], [ 29.744941551724139, -1.513572061538462 ], [ 29.744941551724139, -1.513302584615384 ], [ 29.744672068965517, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9063, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.513302584615384 ], [ 29.745211034482757, -1.513572061538462 ], [ 29.745480517241379, -1.513572061538462 ], [ 29.745480517241379, -1.513302584615384 ], [ 29.745211034482757, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9064, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745480517241379, -1.513302584615384 ], [ 29.745480517241379, -1.513572061538462 ], [ 29.74575, -1.513572061538462 ], [ 29.74575, -1.513302584615384 ], [ 29.745480517241379, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9065, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.513302584615384 ], [ 29.767039137931036, -1.513572061538462 ], [ 29.767308620689654, -1.513572061538462 ], [ 29.767308620689654, -1.513302584615384 ], [ 29.767039137931036, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9066, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.513302584615384 ], [ 29.767308620689654, -1.513572061538462 ], [ 29.767578103448276, -1.513572061538462 ], [ 29.767578103448276, -1.513302584615384 ], [ 29.767308620689654, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9067, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711795172413794, -1.511146769230769 ], [ 29.711795172413794, -1.5154584 ], [ 29.712064655172412, -1.5154584 ], [ 29.712064655172412, -1.511416246153846 ], [ 29.712334137931034, -1.511416246153846 ], [ 29.712334137931034, -1.511146769230769 ], [ 29.711795172413794, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9068, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.511146769230769 ], [ 29.757876724137933, -1.5154584 ], [ 29.758146206896551, -1.5154584 ], [ 29.758146206896551, -1.511146769230769 ], [ 29.757876724137933, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9069, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.511146769230769 ], [ 29.758685172413795, -1.5154584 ], [ 29.758954655172413, -1.5154584 ], [ 29.758954655172413, -1.511146769230769 ], [ 29.758685172413795, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9070, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.511146769230769 ], [ 29.759763103448275, -1.514649969230769 ], [ 29.760032586206897, -1.514649969230769 ], [ 29.760032586206897, -1.511146769230769 ], [ 29.759763103448275, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9071, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.511146769230769 ], [ 29.760571551724137, -1.514111015384615 ], [ 29.760841034482759, -1.514111015384615 ], [ 29.760841034482759, -1.511685723076923 ], [ 29.761110517241381, -1.511685723076923 ], [ 29.761110517241381, -1.511416246153846 ], [ 29.760841034482759, -1.511416246153846 ], [ 29.760841034482759, -1.511146769230769 ], [ 29.760571551724137, -1.511146769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9072, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.511416246153846 ], [ 29.757068275862068, -1.515188923076923 ], [ 29.75733775862069, -1.515188923076923 ], [ 29.75733775862069, -1.511416246153846 ], [ 29.757068275862068, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9073, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.511416246153846 ], [ 29.75733775862069, -1.515188923076923 ], [ 29.757607241379311, -1.515188923076923 ], [ 29.757607241379311, -1.511416246153846 ], [ 29.75733775862069, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9074, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.511416246153846 ], [ 29.758146206896551, -1.515188923076923 ], [ 29.758415689655173, -1.515188923076923 ], [ 29.758415689655173, -1.511416246153846 ], [ 29.758146206896551, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9075, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.511416246153846 ], [ 29.758954655172413, -1.515188923076923 ], [ 29.759224137931035, -1.515188923076923 ], [ 29.759224137931035, -1.511416246153846 ], [ 29.758954655172413, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9076, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.511416246153846 ], [ 29.759224137931035, -1.515188923076923 ], [ 29.759493620689657, -1.515188923076923 ], [ 29.759493620689657, -1.511416246153846 ], [ 29.759224137931035, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9077, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.511416246153846 ], [ 29.760032586206897, -1.514649969230769 ], [ 29.760302068965519, -1.514649969230769 ], [ 29.760302068965519, -1.511416246153846 ], [ 29.760032586206897, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9078, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.511685723076923 ], [ 29.712334137931034, -1.5154584 ], [ 29.712873103448278, -1.5154584 ], [ 29.712873103448278, -1.515188923076923 ], [ 29.712603620689656, -1.515188923076923 ], [ 29.712603620689656, -1.511685723076923 ], [ 29.712334137931034, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9079, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.511685723076923 ], [ 29.755181896551726, -1.514919446153846 ], [ 29.755451379310344, -1.514919446153846 ], [ 29.755451379310344, -1.511685723076923 ], [ 29.755181896551726, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9080, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.511685723076923 ], [ 29.755451379310344, -1.514919446153846 ], [ 29.755720862068966, -1.514919446153846 ], [ 29.755720862068966, -1.511685723076923 ], [ 29.755451379310344, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9081, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.511685723076923 ], [ 29.755990344827588, -1.514919446153846 ], [ 29.756259827586206, -1.514919446153846 ], [ 29.756259827586206, -1.511685723076923 ], [ 29.755990344827588, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9082, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.511685723076923 ], [ 29.756259827586206, -1.514919446153846 ], [ 29.756798793103449, -1.514919446153846 ], [ 29.756798793103449, -1.511685723076923 ], [ 29.756259827586206, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9083, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.511685723076923 ], [ 29.756798793103449, -1.514919446153846 ], [ 29.757068275862068, -1.514919446153846 ], [ 29.757068275862068, -1.511685723076923 ], [ 29.756798793103449, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9084, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.511685723076923 ], [ 29.757607241379311, -1.514919446153846 ], [ 29.757876724137933, -1.514919446153846 ], [ 29.757876724137933, -1.511685723076923 ], [ 29.757607241379311, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9085, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.511685723076923 ], [ 29.758415689655173, -1.514919446153846 ], [ 29.758685172413795, -1.514919446153846 ], [ 29.758685172413795, -1.511685723076923 ], [ 29.758415689655173, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9086, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.511685723076923 ], [ 29.760302068965519, -1.514380492307692 ], [ 29.760571551724137, -1.514380492307692 ], [ 29.760571551724137, -1.511685723076923 ], [ 29.760302068965519, -1.511685723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9087, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.5119552 ], [ 29.712603620689656, -1.514919446153846 ], [ 29.712873103448278, -1.514919446153846 ], [ 29.712873103448278, -1.5119552 ], [ 29.712603620689656, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9088, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.5119552 ], [ 29.754912413793104, -1.514649969230769 ], [ 29.755181896551726, -1.514649969230769 ], [ 29.755181896551726, -1.5119552 ], [ 29.754912413793104, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9089, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.5119552 ], [ 29.755720862068966, -1.514649969230769 ], [ 29.755990344827588, -1.514649969230769 ], [ 29.755990344827588, -1.5119552 ], [ 29.755720862068966, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9090, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.5119552 ], [ 29.759493620689657, -1.514649969230769 ], [ 29.759763103448275, -1.514649969230769 ], [ 29.759763103448275, -1.5119552 ], [ 29.759493620689657, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9091, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5119552 ], [ 29.760841034482759, -1.514111015384615 ], [ 29.76138, -1.514111015384615 ], [ 29.76138, -1.513033107692308 ], [ 29.761110517241381, -1.513033107692308 ], [ 29.761110517241381, -1.512763630769231 ], [ 29.76138, -1.512763630769231 ], [ 29.76138, -1.512494153846154 ], [ 29.761110517241381, -1.512494153846154 ], [ 29.761110517241381, -1.5119552 ], [ 29.760841034482759, -1.5119552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9092, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.512494153846154 ], [ 29.712873103448278, -1.514649969230769 ], [ 29.713142586206896, -1.514649969230769 ], [ 29.713142586206896, -1.512763630769231 ], [ 29.713951034482758, -1.512763630769231 ], [ 29.713951034482758, -1.512494153846154 ], [ 29.712873103448278, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9093, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.512494153846154 ], [ 29.76138, -1.514649969230769 ], [ 29.761649482758621, -1.514649969230769 ], [ 29.761649482758621, -1.512494153846154 ], [ 29.76138, -1.512494153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9094, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.512763630769231 ], [ 29.713142586206896, -1.514111015384615 ], [ 29.713412068965518, -1.514111015384615 ], [ 29.713412068965518, -1.512763630769231 ], [ 29.713142586206896, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9095, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.512763630769231 ], [ 29.717454310344827, -1.514380492307692 ], [ 29.717723793103449, -1.514380492307692 ], [ 29.717723793103449, -1.512763630769231 ], [ 29.717454310344827, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9096, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.512763630769231 ], [ 29.717723793103449, -1.514380492307692 ], [ 29.717993275862067, -1.514380492307692 ], [ 29.717993275862067, -1.512763630769231 ], [ 29.717723793103449, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9097, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.512763630769231 ], [ 29.718262758620689, -1.514111015384615 ], [ 29.718532241379311, -1.514111015384615 ], [ 29.718532241379311, -1.512763630769231 ], [ 29.718262758620689, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9098, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.512763630769231 ], [ 29.718532241379311, -1.513841538461538 ], [ 29.718801724137933, -1.513841538461538 ], [ 29.718801724137933, -1.512763630769231 ], [ 29.718532241379311, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9099, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.512763630769231 ], [ 29.718801724137933, -1.513841538461538 ], [ 29.719071206896551, -1.513841538461538 ], [ 29.719071206896551, -1.512763630769231 ], [ 29.718801724137933, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9100, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.512763630769231 ], [ 29.762188448275861, -1.514649969230769 ], [ 29.762457931034483, -1.514649969230769 ], [ 29.762457931034483, -1.512763630769231 ], [ 29.762188448275861, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9101, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.512763630769231 ], [ 29.763266379310345, -1.514649969230769 ], [ 29.763535862068967, -1.514649969230769 ], [ 29.763535862068967, -1.512763630769231 ], [ 29.763266379310345, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9102, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.512763630769231 ], [ 29.764074827586207, -1.514649969230769 ], [ 29.764344310344828, -1.514649969230769 ], [ 29.764344310344828, -1.512763630769231 ], [ 29.764074827586207, -1.512763630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9103, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713412068965518, -1.513033107692308 ], [ 29.713412068965518, -1.513841538461538 ], [ 29.713681551724139, -1.513841538461538 ], [ 29.713681551724139, -1.513033107692308 ], [ 29.713412068965518, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9104, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713951034482758, -1.513033107692308 ], [ 29.713951034482758, -1.513841538461538 ], [ 29.71422051724138, -1.513841538461538 ], [ 29.71422051724138, -1.513033107692308 ], [ 29.713951034482758, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9105, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71422051724138, -1.513033107692308 ], [ 29.71422051724138, -1.513841538461538 ], [ 29.71449, -1.513841538461538 ], [ 29.71449, -1.513033107692308 ], [ 29.71422051724138, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9106, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717184827586205, -1.513033107692308 ], [ 29.717184827586205, -1.514649969230769 ], [ 29.717454310344827, -1.514649969230769 ], [ 29.717454310344827, -1.513033107692308 ], [ 29.717184827586205, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9107, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.513033107692308 ], [ 29.717993275862067, -1.514111015384615 ], [ 29.718262758620689, -1.514111015384615 ], [ 29.718262758620689, -1.513033107692308 ], [ 29.717993275862067, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9108, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.513033107692308 ], [ 29.736318103448276, -1.515188923076923 ], [ 29.736587586206898, -1.515188923076923 ], [ 29.736587586206898, -1.513033107692308 ], [ 29.736318103448276, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9109, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.513033107692308 ], [ 29.739551896551724, -1.513841538461538 ], [ 29.739821379310346, -1.513841538461538 ], [ 29.739821379310346, -1.513033107692308 ], [ 29.739551896551724, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9110, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744133103448277, -1.513033107692308 ], [ 29.744133103448277, -1.513841538461538 ], [ 29.744402586206895, -1.513841538461538 ], [ 29.744402586206895, -1.513033107692308 ], [ 29.744133103448277, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9111, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.513033107692308 ], [ 29.761918965517243, -1.514380492307692 ], [ 29.762188448275861, -1.514380492307692 ], [ 29.762188448275861, -1.513033107692308 ], [ 29.761918965517243, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9112, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.513033107692308 ], [ 29.762727413793105, -1.514380492307692 ], [ 29.762996896551723, -1.514380492307692 ], [ 29.762996896551723, -1.513033107692308 ], [ 29.762727413793105, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9113, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.513033107692308 ], [ 29.762996896551723, -1.514380492307692 ], [ 29.763266379310345, -1.514380492307692 ], [ 29.763266379310345, -1.513033107692308 ], [ 29.762996896551723, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9114, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.513033107692308 ], [ 29.763805344827588, -1.514380492307692 ], [ 29.764074827586207, -1.514380492307692 ], [ 29.764074827586207, -1.513033107692308 ], [ 29.763805344827588, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9115, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.513033107692308 ], [ 29.76461379310345, -1.514380492307692 ], [ 29.764883275862069, -1.514380492307692 ], [ 29.764883275862069, -1.513033107692308 ], [ 29.76461379310345, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9116, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.513033107692308 ], [ 29.764883275862069, -1.514380492307692 ], [ 29.76515275862069, -1.514380492307692 ], [ 29.76515275862069, -1.513033107692308 ], [ 29.764883275862069, -1.513033107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9117, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.513302584615384 ], [ 29.72661672413793, -1.513572061538462 ], [ 29.726886206896552, -1.513572061538462 ], [ 29.726886206896552, -1.513841538461538 ], [ 29.727155689655174, -1.513841538461538 ], [ 29.727155689655174, -1.514111015384615 ], [ 29.727425172413792, -1.514111015384615 ], [ 29.727425172413792, -1.514380492307692 ], [ 29.727694655172414, -1.514380492307692 ], [ 29.727694655172414, -1.514649969230769 ], [ 29.727964137931036, -1.514649969230769 ], [ 29.727964137931036, -1.514919446153846 ], [ 29.728233620689654, -1.514919446153846 ], [ 29.728233620689654, -1.514380492307692 ], [ 29.727964137931036, -1.514380492307692 ], [ 29.727964137931036, -1.514111015384615 ], [ 29.727694655172414, -1.514111015384615 ], [ 29.727694655172414, -1.513841538461538 ], [ 29.727425172413792, -1.513841538461538 ], [ 29.727425172413792, -1.513572061538462 ], [ 29.727155689655174, -1.513572061538462 ], [ 29.727155689655174, -1.513302584615384 ], [ 29.72661672413793, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9118, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.513302584615384 ], [ 29.736048620689655, -1.514919446153846 ], [ 29.736318103448276, -1.514919446153846 ], [ 29.736318103448276, -1.513302584615384 ], [ 29.736048620689655, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9119, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.513302584615384 ], [ 29.736857068965517, -1.514919446153846 ], [ 29.737126551724138, -1.514919446153846 ], [ 29.737126551724138, -1.513302584615384 ], [ 29.736857068965517, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9120, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.513302584615384 ], [ 29.737126551724138, -1.514919446153846 ], [ 29.73739603448276, -1.514919446153846 ], [ 29.73739603448276, -1.513302584615384 ], [ 29.737126551724138, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9121, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.513302584615384 ], [ 29.738204482758622, -1.513841538461538 ], [ 29.738743448275862, -1.513841538461538 ], [ 29.738743448275862, -1.513302584615384 ], [ 29.738204482758622, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9122, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.513302584615384 ], [ 29.761649482758621, -1.514111015384615 ], [ 29.761918965517243, -1.514111015384615 ], [ 29.761918965517243, -1.513302584615384 ], [ 29.761649482758621, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9123, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.513302584615384 ], [ 29.763535862068967, -1.514111015384615 ], [ 29.763805344827588, -1.514111015384615 ], [ 29.763805344827588, -1.513302584615384 ], [ 29.763535862068967, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9124, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.513302584615384 ], [ 29.764344310344828, -1.514111015384615 ], [ 29.76461379310345, -1.514111015384615 ], [ 29.76461379310345, -1.513302584615384 ], [ 29.764344310344828, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9125, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.513302584615384 ], [ 29.76515275862069, -1.514111015384615 ], [ 29.765422241379312, -1.514111015384615 ], [ 29.765422241379312, -1.513302584615384 ], [ 29.76515275862069, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9126, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.513302584615384 ], [ 29.765422241379312, -1.514111015384615 ], [ 29.765961206896552, -1.514111015384615 ], [ 29.765961206896552, -1.513302584615384 ], [ 29.765422241379312, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9127, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.513302584615384 ], [ 29.765961206896552, -1.514111015384615 ], [ 29.766230689655174, -1.514111015384615 ], [ 29.766230689655174, -1.513302584615384 ], [ 29.765961206896552, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9128, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.513302584615384 ], [ 29.766500172413792, -1.514111015384615 ], [ 29.766769655172414, -1.514111015384615 ], [ 29.766769655172414, -1.513302584615384 ], [ 29.766500172413792, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9129, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.513302584615384 ], [ 29.766769655172414, -1.514111015384615 ], [ 29.767039137931036, -1.514111015384615 ], [ 29.767039137931036, -1.513302584615384 ], [ 29.766769655172414, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9130, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713681551724139, -1.513572061538462 ], [ 29.713681551724139, -1.514111015384615 ], [ 29.713951034482758, -1.514111015384615 ], [ 29.713951034482758, -1.513572061538462 ], [ 29.713681551724139, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9131, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71449, -1.513572061538462 ], [ 29.71449, -1.513841538461538 ], [ 29.71475948275862, -1.513841538461538 ], [ 29.71475948275862, -1.513572061538462 ], [ 29.71449, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9132, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71475948275862, -1.513572061538462 ], [ 29.71475948275862, -1.514111015384615 ], [ 29.715028965517241, -1.514111015384615 ], [ 29.715028965517241, -1.513572061538462 ], [ 29.71475948275862, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9133, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.513572061538462 ], [ 29.719071206896551, -1.513841538461538 ], [ 29.719340689655173, -1.513841538461538 ], [ 29.719340689655173, -1.513572061538462 ], [ 29.719071206896551, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9134, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.513572061538462 ], [ 29.719340689655173, -1.513841538461538 ], [ 29.719610172413795, -1.513841538461538 ], [ 29.719610172413795, -1.513572061538462 ], [ 29.719340689655173, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9135, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.513572061538462 ], [ 29.719610172413795, -1.513841538461538 ], [ 29.719879655172413, -1.513841538461538 ], [ 29.719879655172413, -1.513572061538462 ], [ 29.719610172413795, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9136, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.513572061538462 ], [ 29.719879655172413, -1.513841538461538 ], [ 29.720149137931035, -1.513841538461538 ], [ 29.720149137931035, -1.513572061538462 ], [ 29.719879655172413, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9137, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.513572061538462 ], [ 29.720149137931035, -1.513841538461538 ], [ 29.720688103448275, -1.513841538461538 ], [ 29.720688103448275, -1.513572061538462 ], [ 29.720149137931035, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9138, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.513572061538462 ], [ 29.720688103448275, -1.513841538461538 ], [ 29.720957586206897, -1.513841538461538 ], [ 29.720957586206897, -1.513572061538462 ], [ 29.720688103448275, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9139, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.513572061538462 ], [ 29.720957586206897, -1.513841538461538 ], [ 29.721227068965518, -1.513841538461538 ], [ 29.721227068965518, -1.513572061538462 ], [ 29.720957586206897, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9140, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.513572061538462 ], [ 29.721227068965518, -1.513841538461538 ], [ 29.721766034482759, -1.513841538461538 ], [ 29.721766034482759, -1.513572061538462 ], [ 29.721227068965518, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9141, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.513572061538462 ], [ 29.721766034482759, -1.513841538461538 ], [ 29.72203551724138, -1.513841538461538 ], [ 29.72203551724138, -1.513572061538462 ], [ 29.721766034482759, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9142, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.513572061538462 ], [ 29.72203551724138, -1.513841538461538 ], [ 29.72257448275862, -1.513841538461538 ], [ 29.72257448275862, -1.513572061538462 ], [ 29.72203551724138, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9143, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.513572061538462 ], [ 29.72257448275862, -1.513841538461538 ], [ 29.722843965517242, -1.513841538461538 ], [ 29.722843965517242, -1.513572061538462 ], [ 29.72257448275862, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9144, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.513572061538462 ], [ 29.722843965517242, -1.513841538461538 ], [ 29.723113448275861, -1.513841538461538 ], [ 29.723113448275861, -1.513572061538462 ], [ 29.722843965517242, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9145, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.513572061538462 ], [ 29.723113448275861, -1.513841538461538 ], [ 29.723382931034482, -1.513841538461538 ], [ 29.723382931034482, -1.513572061538462 ], [ 29.723113448275861, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9146, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.513572061538462 ], [ 29.723382931034482, -1.513841538461538 ], [ 29.723652413793104, -1.513841538461538 ], [ 29.723652413793104, -1.513572061538462 ], [ 29.723382931034482, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9147, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.513572061538462 ], [ 29.723652413793104, -1.513841538461538 ], [ 29.723921896551722, -1.513841538461538 ], [ 29.723921896551722, -1.513572061538462 ], [ 29.723652413793104, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9148, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.513572061538462 ], [ 29.723921896551722, -1.513841538461538 ], [ 29.724191379310344, -1.513841538461538 ], [ 29.724191379310344, -1.513572061538462 ], [ 29.723921896551722, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9149, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.513572061538462 ], [ 29.724191379310344, -1.513841538461538 ], [ 29.724460862068966, -1.513841538461538 ], [ 29.724460862068966, -1.513572061538462 ], [ 29.724191379310344, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9150, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.513572061538462 ], [ 29.724460862068966, -1.513841538461538 ], [ 29.724730344827588, -1.513841538461538 ], [ 29.724730344827588, -1.513572061538462 ], [ 29.724460862068966, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9151, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.513572061538462 ], [ 29.724730344827588, -1.513841538461538 ], [ 29.724999827586206, -1.513841538461538 ], [ 29.724999827586206, -1.513572061538462 ], [ 29.724730344827588, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9152, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.513572061538462 ], [ 29.724999827586206, -1.513841538461538 ], [ 29.725269310344828, -1.513841538461538 ], [ 29.725269310344828, -1.513572061538462 ], [ 29.724999827586206, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9153, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.513572061538462 ], [ 29.725269310344828, -1.513841538461538 ], [ 29.72553879310345, -1.513841538461538 ], [ 29.72553879310345, -1.513572061538462 ], [ 29.725269310344828, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9154, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.513572061538462 ], [ 29.72553879310345, -1.513841538461538 ], [ 29.725808275862068, -1.513841538461538 ], [ 29.725808275862068, -1.513572061538462 ], [ 29.72553879310345, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9155, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.513572061538462 ], [ 29.725808275862068, -1.513841538461538 ], [ 29.72607775862069, -1.513841538461538 ], [ 29.72607775862069, -1.513572061538462 ], [ 29.725808275862068, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9156, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.513572061538462 ], [ 29.72607775862069, -1.513841538461538 ], [ 29.726347241379312, -1.513841538461538 ], [ 29.726347241379312, -1.513572061538462 ], [ 29.72607775862069, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9157, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.513572061538462 ], [ 29.726347241379312, -1.513841538461538 ], [ 29.72661672413793, -1.513841538461538 ], [ 29.72661672413793, -1.513572061538462 ], [ 29.726347241379312, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9158, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.513572061538462 ], [ 29.72661672413793, -1.513841538461538 ], [ 29.726886206896552, -1.513841538461538 ], [ 29.726886206896552, -1.513572061538462 ], [ 29.72661672413793, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9159, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.513572061538462 ], [ 29.727425172413792, -1.513841538461538 ], [ 29.727694655172414, -1.513841538461538 ], [ 29.727694655172414, -1.513572061538462 ], [ 29.727425172413792, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9160, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.513572061538462 ], [ 29.727694655172414, -1.513841538461538 ], [ 29.727964137931036, -1.513841538461538 ], [ 29.727964137931036, -1.513572061538462 ], [ 29.727694655172414, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9161, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.513572061538462 ], [ 29.727964137931036, -1.513841538461538 ], [ 29.728233620689654, -1.513841538461538 ], [ 29.728233620689654, -1.513572061538462 ], [ 29.727964137931036, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9162, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.513572061538462 ], [ 29.728233620689654, -1.513841538461538 ], [ 29.728503103448276, -1.513841538461538 ], [ 29.728503103448276, -1.513572061538462 ], [ 29.728233620689654, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9163, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.513572061538462 ], [ 29.728503103448276, -1.513841538461538 ], [ 29.728772586206897, -1.513841538461538 ], [ 29.728772586206897, -1.513572061538462 ], [ 29.728503103448276, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9164, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.513572061538462 ], [ 29.728772586206897, -1.513841538461538 ], [ 29.729042068965516, -1.513841538461538 ], [ 29.729042068965516, -1.513572061538462 ], [ 29.728772586206897, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9165, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.513572061538462 ], [ 29.729042068965516, -1.513841538461538 ], [ 29.729311551724138, -1.513841538461538 ], [ 29.729311551724138, -1.513572061538462 ], [ 29.729042068965516, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9166, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.513572061538462 ], [ 29.729311551724138, -1.513841538461538 ], [ 29.729850517241381, -1.513841538461538 ], [ 29.729850517241381, -1.513572061538462 ], [ 29.729311551724138, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9167, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.513572061538462 ], [ 29.729850517241381, -1.513841538461538 ], [ 29.73012, -1.513841538461538 ], [ 29.73012, -1.513572061538462 ], [ 29.729850517241381, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9168, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.513572061538462 ], [ 29.73012, -1.513841538461538 ], [ 29.730389482758621, -1.513841538461538 ], [ 29.730389482758621, -1.513572061538462 ], [ 29.73012, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9169, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.513572061538462 ], [ 29.730389482758621, -1.513841538461538 ], [ 29.730658965517243, -1.513841538461538 ], [ 29.730658965517243, -1.513572061538462 ], [ 29.730389482758621, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9170, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.513572061538462 ], [ 29.730658965517243, -1.513841538461538 ], [ 29.730928448275861, -1.513841538461538 ], [ 29.730928448275861, -1.513572061538462 ], [ 29.730658965517243, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9171, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.513572061538462 ], [ 29.730928448275861, -1.513841538461538 ], [ 29.731197931034483, -1.513841538461538 ], [ 29.731197931034483, -1.513572061538462 ], [ 29.730928448275861, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9172, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.513572061538462 ], [ 29.731197931034483, -1.513841538461538 ], [ 29.731467413793105, -1.513841538461538 ], [ 29.731467413793105, -1.513572061538462 ], [ 29.731197931034483, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9173, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.513572061538462 ], [ 29.731467413793105, -1.513841538461538 ], [ 29.732814827586207, -1.513841538461538 ], [ 29.732814827586207, -1.513572061538462 ], [ 29.731467413793105, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9174, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.513572061538462 ], [ 29.732814827586207, -1.513841538461538 ], [ 29.733084310344829, -1.513841538461538 ], [ 29.733084310344829, -1.513572061538462 ], [ 29.732814827586207, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9175, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.513572061538462 ], [ 29.733084310344829, -1.513841538461538 ], [ 29.733353793103447, -1.513841538461538 ], [ 29.733353793103447, -1.513572061538462 ], [ 29.733084310344829, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9176, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.513572061538462 ], [ 29.733353793103447, -1.513841538461538 ], [ 29.733623275862069, -1.513841538461538 ], [ 29.733623275862069, -1.513572061538462 ], [ 29.733353793103447, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9177, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.513572061538462 ], [ 29.733623275862069, -1.513841538461538 ], [ 29.733892758620691, -1.513841538461538 ], [ 29.733892758620691, -1.513572061538462 ], [ 29.733623275862069, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9178, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.513572061538462 ], [ 29.733892758620691, -1.513841538461538 ], [ 29.734162241379309, -1.513841538461538 ], [ 29.734162241379309, -1.513572061538462 ], [ 29.733892758620691, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9179, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.513572061538462 ], [ 29.734162241379309, -1.513841538461538 ], [ 29.734431724137931, -1.513841538461538 ], [ 29.734431724137931, -1.513572061538462 ], [ 29.734162241379309, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9180, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.513572061538462 ], [ 29.734431724137931, -1.513841538461538 ], [ 29.734701206896553, -1.513841538461538 ], [ 29.734701206896553, -1.513572061538462 ], [ 29.734431724137931, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9181, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.513572061538462 ], [ 29.734701206896553, -1.513841538461538 ], [ 29.734970689655171, -1.513841538461538 ], [ 29.734970689655171, -1.513572061538462 ], [ 29.734701206896553, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9182, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.513572061538462 ], [ 29.734970689655171, -1.513841538461538 ], [ 29.735240172413793, -1.513841538461538 ], [ 29.735240172413793, -1.513572061538462 ], [ 29.734970689655171, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9183, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.513572061538462 ], [ 29.735240172413793, -1.513841538461538 ], [ 29.735509655172415, -1.513841538461538 ], [ 29.735509655172415, -1.513572061538462 ], [ 29.735240172413793, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9184, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.513572061538462 ], [ 29.735509655172415, -1.513841538461538 ], [ 29.735779137931036, -1.513841538461538 ], [ 29.735779137931036, -1.513572061538462 ], [ 29.735509655172415, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9185, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.513572061538462 ], [ 29.735779137931036, -1.514649969230769 ], [ 29.736048620689655, -1.514649969230769 ], [ 29.736048620689655, -1.513572061538462 ], [ 29.735779137931036, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9186, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.513572061538462 ], [ 29.736587586206898, -1.514649969230769 ], [ 29.736857068965517, -1.514649969230769 ], [ 29.736857068965517, -1.513572061538462 ], [ 29.736587586206898, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9187, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.513572061538462 ], [ 29.73739603448276, -1.514649969230769 ], [ 29.737665517241378, -1.514649969230769 ], [ 29.737665517241378, -1.513572061538462 ], [ 29.73739603448276, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9188, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.513572061538462 ], [ 29.737665517241378, -1.514649969230769 ], [ 29.737935, -1.514649969230769 ], [ 29.737935, -1.513572061538462 ], [ 29.737665517241378, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9189, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.513572061538462 ], [ 29.737935, -1.514649969230769 ], [ 29.738204482758622, -1.514649969230769 ], [ 29.738204482758622, -1.513572061538462 ], [ 29.737935, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9190, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.513572061538462 ], [ 29.738743448275862, -1.514649969230769 ], [ 29.739012931034484, -1.514649969230769 ], [ 29.739012931034484, -1.513572061538462 ], [ 29.738743448275862, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9191, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.513572061538462 ], [ 29.739012931034484, -1.514649969230769 ], [ 29.739282413793102, -1.514649969230769 ], [ 29.739282413793102, -1.513572061538462 ], [ 29.739012931034484, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9192, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.513572061538462 ], [ 29.739282413793102, -1.513841538461538 ], [ 29.739551896551724, -1.513841538461538 ], [ 29.739551896551724, -1.513572061538462 ], [ 29.739282413793102, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9193, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.513572061538462 ], [ 29.744402586206895, -1.513841538461538 ], [ 29.744672068965517, -1.513841538461538 ], [ 29.744672068965517, -1.513572061538462 ], [ 29.744402586206895, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9194, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.513302584615384 ], [ 29.745211034482757, -1.513302584615384 ], [ 29.745211034482757, -1.514111015384615 ], [ 29.744941551724139, -1.514111015384615 ], [ 29.744941551724139, -1.514380492307692 ], [ 29.744672068965517, -1.514380492307692 ], [ 29.744672068965517, -1.513572061538462 ], [ 29.744941551724139, -1.513572061538462 ], [ 29.744941551724139, -1.513302584615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9195, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745211034482757, -1.513572061538462 ], [ 29.745211034482757, -1.514380492307692 ], [ 29.745480517241379, -1.514380492307692 ], [ 29.745480517241379, -1.513572061538462 ], [ 29.745211034482757, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9196, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.513572061538462 ], [ 29.762457931034483, -1.513841538461538 ], [ 29.762727413793105, -1.513841538461538 ], [ 29.762727413793105, -1.513572061538462 ], [ 29.762457931034483, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9197, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.513572061538462 ], [ 29.766230689655174, -1.513841538461538 ], [ 29.766500172413792, -1.513841538461538 ], [ 29.766500172413792, -1.513572061538462 ], [ 29.766230689655174, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9198, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.513572061538462 ], [ 29.767039137931036, -1.513841538461538 ], [ 29.767308620689654, -1.513841538461538 ], [ 29.767308620689654, -1.513572061538462 ], [ 29.767039137931036, -1.513572061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9199, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713412068965518, -1.513841538461538 ], [ 29.713412068965518, -1.514111015384615 ], [ 29.713681551724139, -1.514111015384615 ], [ 29.713681551724139, -1.513841538461538 ], [ 29.713412068965518, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9200, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713951034482758, -1.513841538461538 ], [ 29.713951034482758, -1.514111015384615 ], [ 29.71422051724138, -1.514111015384615 ], [ 29.71422051724138, -1.513841538461538 ], [ 29.713951034482758, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9201, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71422051724138, -1.513841538461538 ], [ 29.71422051724138, -1.514380492307692 ], [ 29.71449, -1.514380492307692 ], [ 29.71449, -1.513841538461538 ], [ 29.71422051724138, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9202, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71449, -1.513841538461538 ], [ 29.71449, -1.514380492307692 ], [ 29.71475948275862, -1.514380492307692 ], [ 29.71475948275862, -1.513841538461538 ], [ 29.71449, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9203, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.513841538461538 ], [ 29.718532241379311, -1.514111015384615 ], [ 29.718801724137933, -1.514111015384615 ], [ 29.718801724137933, -1.513841538461538 ], [ 29.718532241379311, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9204, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.513841538461538 ], [ 29.718801724137933, -1.514111015384615 ], [ 29.719071206896551, -1.514111015384615 ], [ 29.719071206896551, -1.513841538461538 ], [ 29.718801724137933, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9205, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.513841538461538 ], [ 29.719071206896551, -1.514111015384615 ], [ 29.719340689655173, -1.514111015384615 ], [ 29.719340689655173, -1.513841538461538 ], [ 29.719071206896551, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9206, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.513841538461538 ], [ 29.719340689655173, -1.514111015384615 ], [ 29.719610172413795, -1.514111015384615 ], [ 29.719610172413795, -1.513841538461538 ], [ 29.719340689655173, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9207, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.513841538461538 ], [ 29.719610172413795, -1.514111015384615 ], [ 29.719879655172413, -1.514111015384615 ], [ 29.719879655172413, -1.513841538461538 ], [ 29.719610172413795, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9208, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.513841538461538 ], [ 29.719879655172413, -1.514111015384615 ], [ 29.720149137931035, -1.514111015384615 ], [ 29.720149137931035, -1.513841538461538 ], [ 29.719879655172413, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9209, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.513841538461538 ], [ 29.720149137931035, -1.514111015384615 ], [ 29.720688103448275, -1.514111015384615 ], [ 29.720688103448275, -1.513841538461538 ], [ 29.720149137931035, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9210, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.513841538461538 ], [ 29.720688103448275, -1.514111015384615 ], [ 29.720957586206897, -1.514111015384615 ], [ 29.720957586206897, -1.513841538461538 ], [ 29.720688103448275, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9211, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.513841538461538 ], [ 29.720957586206897, -1.514111015384615 ], [ 29.721227068965518, -1.514111015384615 ], [ 29.721227068965518, -1.513841538461538 ], [ 29.720957586206897, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9212, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.513841538461538 ], [ 29.721227068965518, -1.514111015384615 ], [ 29.721496551724137, -1.514111015384615 ], [ 29.721496551724137, -1.513841538461538 ], [ 29.721227068965518, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9213, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.513841538461538 ], [ 29.721496551724137, -1.514111015384615 ], [ 29.722305, -1.514111015384615 ], [ 29.722305, -1.513841538461538 ], [ 29.721496551724137, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9214, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.513841538461538 ], [ 29.722305, -1.514111015384615 ], [ 29.72257448275862, -1.514111015384615 ], [ 29.72257448275862, -1.513841538461538 ], [ 29.722305, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9215, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.513841538461538 ], [ 29.72257448275862, -1.514111015384615 ], [ 29.723113448275861, -1.514111015384615 ], [ 29.723113448275861, -1.513841538461538 ], [ 29.72257448275862, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9216, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.513841538461538 ], [ 29.723113448275861, -1.514111015384615 ], [ 29.723382931034482, -1.514111015384615 ], [ 29.723382931034482, -1.513841538461538 ], [ 29.723113448275861, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9217, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.513841538461538 ], [ 29.723382931034482, -1.514111015384615 ], [ 29.723652413793104, -1.514111015384615 ], [ 29.723652413793104, -1.513841538461538 ], [ 29.723382931034482, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9218, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.513841538461538 ], [ 29.723652413793104, -1.514111015384615 ], [ 29.723921896551722, -1.514111015384615 ], [ 29.723921896551722, -1.513841538461538 ], [ 29.723652413793104, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9219, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.513841538461538 ], [ 29.723921896551722, -1.514111015384615 ], [ 29.724191379310344, -1.514111015384615 ], [ 29.724191379310344, -1.513841538461538 ], [ 29.723921896551722, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9220, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.513841538461538 ], [ 29.724191379310344, -1.514111015384615 ], [ 29.724460862068966, -1.514111015384615 ], [ 29.724460862068966, -1.513841538461538 ], [ 29.724191379310344, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9221, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.513841538461538 ], [ 29.724460862068966, -1.514111015384615 ], [ 29.724730344827588, -1.514111015384615 ], [ 29.724730344827588, -1.513841538461538 ], [ 29.724460862068966, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9222, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.513841538461538 ], [ 29.724730344827588, -1.514111015384615 ], [ 29.724999827586206, -1.514111015384615 ], [ 29.724999827586206, -1.513841538461538 ], [ 29.724730344827588, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9223, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.513841538461538 ], [ 29.724999827586206, -1.514111015384615 ], [ 29.725269310344828, -1.514111015384615 ], [ 29.725269310344828, -1.513841538461538 ], [ 29.724999827586206, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9224, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.513841538461538 ], [ 29.725269310344828, -1.514111015384615 ], [ 29.72553879310345, -1.514111015384615 ], [ 29.72553879310345, -1.513841538461538 ], [ 29.725269310344828, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9225, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.513841538461538 ], [ 29.72553879310345, -1.514111015384615 ], [ 29.725808275862068, -1.514111015384615 ], [ 29.725808275862068, -1.513841538461538 ], [ 29.72553879310345, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9226, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.513841538461538 ], [ 29.725808275862068, -1.514111015384615 ], [ 29.72607775862069, -1.514111015384615 ], [ 29.72607775862069, -1.513841538461538 ], [ 29.725808275862068, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9227, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.513841538461538 ], [ 29.72607775862069, -1.514111015384615 ], [ 29.726347241379312, -1.514111015384615 ], [ 29.726347241379312, -1.513841538461538 ], [ 29.72607775862069, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9228, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.513841538461538 ], [ 29.726347241379312, -1.514111015384615 ], [ 29.72661672413793, -1.514111015384615 ], [ 29.72661672413793, -1.513841538461538 ], [ 29.726347241379312, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9229, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.513841538461538 ], [ 29.72661672413793, -1.514111015384615 ], [ 29.726886206896552, -1.514111015384615 ], [ 29.726886206896552, -1.513841538461538 ], [ 29.72661672413793, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9230, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.513841538461538 ], [ 29.726886206896552, -1.514111015384615 ], [ 29.727155689655174, -1.514111015384615 ], [ 29.727155689655174, -1.513841538461538 ], [ 29.726886206896552, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9231, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.513841538461538 ], [ 29.727694655172414, -1.514111015384615 ], [ 29.727964137931036, -1.514111015384615 ], [ 29.727964137931036, -1.513841538461538 ], [ 29.727694655172414, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9232, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.513841538461538 ], [ 29.727964137931036, -1.514111015384615 ], [ 29.728233620689654, -1.514111015384615 ], [ 29.728233620689654, -1.513841538461538 ], [ 29.727964137931036, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9233, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.513841538461538 ], [ 29.728233620689654, -1.514111015384615 ], [ 29.728503103448276, -1.514111015384615 ], [ 29.728503103448276, -1.513841538461538 ], [ 29.728233620689654, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9234, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.513841538461538 ], [ 29.728503103448276, -1.514111015384615 ], [ 29.728772586206897, -1.514111015384615 ], [ 29.728772586206897, -1.513841538461538 ], [ 29.728503103448276, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9235, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.513841538461538 ], [ 29.728772586206897, -1.514111015384615 ], [ 29.729042068965516, -1.514111015384615 ], [ 29.729042068965516, -1.513841538461538 ], [ 29.728772586206897, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9236, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.513841538461538 ], [ 29.729042068965516, -1.514111015384615 ], [ 29.729311551724138, -1.514111015384615 ], [ 29.729311551724138, -1.513841538461538 ], [ 29.729042068965516, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9237, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.513841538461538 ], [ 29.729311551724138, -1.514111015384615 ], [ 29.729850517241381, -1.514111015384615 ], [ 29.729850517241381, -1.513841538461538 ], [ 29.729311551724138, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9238, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.513841538461538 ], [ 29.729850517241381, -1.514111015384615 ], [ 29.73012, -1.514111015384615 ], [ 29.73012, -1.513841538461538 ], [ 29.729850517241381, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9239, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.513841538461538 ], [ 29.73012, -1.514111015384615 ], [ 29.730389482758621, -1.514111015384615 ], [ 29.730389482758621, -1.513841538461538 ], [ 29.73012, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9240, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.513841538461538 ], [ 29.730389482758621, -1.514111015384615 ], [ 29.730658965517243, -1.514111015384615 ], [ 29.730658965517243, -1.513841538461538 ], [ 29.730389482758621, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9241, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.513841538461538 ], [ 29.730658965517243, -1.514111015384615 ], [ 29.730928448275861, -1.514111015384615 ], [ 29.730928448275861, -1.513841538461538 ], [ 29.730658965517243, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9242, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.513841538461538 ], [ 29.730928448275861, -1.514111015384615 ], [ 29.731197931034483, -1.514111015384615 ], [ 29.731197931034483, -1.513841538461538 ], [ 29.730928448275861, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9243, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.513841538461538 ], [ 29.731197931034483, -1.514111015384615 ], [ 29.731467413793105, -1.514111015384615 ], [ 29.731467413793105, -1.513841538461538 ], [ 29.731197931034483, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9244, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.513841538461538 ], [ 29.731467413793105, -1.514111015384615 ], [ 29.732814827586207, -1.514111015384615 ], [ 29.732814827586207, -1.513841538461538 ], [ 29.731467413793105, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9245, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.513841538461538 ], [ 29.732814827586207, -1.514111015384615 ], [ 29.733084310344829, -1.514111015384615 ], [ 29.733084310344829, -1.513841538461538 ], [ 29.732814827586207, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9246, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.513841538461538 ], [ 29.733084310344829, -1.514111015384615 ], [ 29.733353793103447, -1.514111015384615 ], [ 29.733353793103447, -1.513841538461538 ], [ 29.733084310344829, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9247, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.513841538461538 ], [ 29.733353793103447, -1.514111015384615 ], [ 29.733623275862069, -1.514111015384615 ], [ 29.733623275862069, -1.513841538461538 ], [ 29.733353793103447, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9248, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.513841538461538 ], [ 29.733623275862069, -1.514111015384615 ], [ 29.733892758620691, -1.514111015384615 ], [ 29.733892758620691, -1.513841538461538 ], [ 29.733623275862069, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9249, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.513841538461538 ], [ 29.733892758620691, -1.514111015384615 ], [ 29.734162241379309, -1.514111015384615 ], [ 29.734162241379309, -1.513841538461538 ], [ 29.733892758620691, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9250, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.513841538461538 ], [ 29.734162241379309, -1.514111015384615 ], [ 29.734431724137931, -1.514111015384615 ], [ 29.734431724137931, -1.513841538461538 ], [ 29.734162241379309, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9251, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.513841538461538 ], [ 29.734431724137931, -1.514111015384615 ], [ 29.734701206896553, -1.514111015384615 ], [ 29.734701206896553, -1.513841538461538 ], [ 29.734431724137931, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9252, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.513841538461538 ], [ 29.734701206896553, -1.514111015384615 ], [ 29.734970689655171, -1.514111015384615 ], [ 29.734970689655171, -1.513841538461538 ], [ 29.734701206896553, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9253, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.513841538461538 ], [ 29.734970689655171, -1.514111015384615 ], [ 29.735240172413793, -1.514111015384615 ], [ 29.735240172413793, -1.513841538461538 ], [ 29.734970689655171, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9254, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.513841538461538 ], [ 29.735240172413793, -1.514111015384615 ], [ 29.735509655172415, -1.514111015384615 ], [ 29.735509655172415, -1.513841538461538 ], [ 29.735240172413793, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9255, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.513841538461538 ], [ 29.735509655172415, -1.514111015384615 ], [ 29.735779137931036, -1.514111015384615 ], [ 29.735779137931036, -1.513841538461538 ], [ 29.735509655172415, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9256, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.513841538461538 ], [ 29.738204482758622, -1.514380492307692 ], [ 29.738743448275862, -1.514380492307692 ], [ 29.738743448275862, -1.513841538461538 ], [ 29.738204482758622, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9257, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.513841538461538 ], [ 29.739282413793102, -1.514380492307692 ], [ 29.739551896551724, -1.514380492307692 ], [ 29.739551896551724, -1.513841538461538 ], [ 29.739282413793102, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9258, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744402586206895, -1.513841538461538 ], [ 29.744402586206895, -1.514649969230769 ], [ 29.745211034482757, -1.514649969230769 ], [ 29.745211034482757, -1.514380492307692 ], [ 29.744672068965517, -1.514380492307692 ], [ 29.744672068965517, -1.513841538461538 ], [ 29.744402586206895, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9259, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.513841538461538 ], [ 29.762457931034483, -1.514649969230769 ], [ 29.762727413793105, -1.514649969230769 ], [ 29.762727413793105, -1.513841538461538 ], [ 29.762457931034483, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9260, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.513841538461538 ], [ 29.766230689655174, -1.514380492307692 ], [ 29.766500172413792, -1.514380492307692 ], [ 29.766500172413792, -1.513841538461538 ], [ 29.766230689655174, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9261, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.513841538461538 ], [ 29.767039137931036, -1.514111015384615 ], [ 29.767308620689654, -1.514111015384615 ], [ 29.767308620689654, -1.513841538461538 ], [ 29.767039137931036, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9262, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.513841538461538 ], [ 29.767308620689654, -1.514111015384615 ], [ 29.767578103448276, -1.514111015384615 ], [ 29.767578103448276, -1.513841538461538 ], [ 29.767308620689654, -1.513841538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9263, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.514111015384615 ], [ 29.713142586206896, -1.514380492307692 ], [ 29.713412068965518, -1.514380492307692 ], [ 29.713412068965518, -1.514111015384615 ], [ 29.713142586206896, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9264, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713412068965518, -1.514111015384615 ], [ 29.713412068965518, -1.514380492307692 ], [ 29.713951034482758, -1.514380492307692 ], [ 29.713951034482758, -1.514111015384615 ], [ 29.713412068965518, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9265, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713951034482758, -1.514111015384615 ], [ 29.713951034482758, -1.514380492307692 ], [ 29.71422051724138, -1.514380492307692 ], [ 29.71422051724138, -1.514111015384615 ], [ 29.713951034482758, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9266, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71475948275862, -1.514111015384615 ], [ 29.71475948275862, -1.514380492307692 ], [ 29.715028965517241, -1.514380492307692 ], [ 29.715028965517241, -1.514111015384615 ], [ 29.71475948275862, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9267, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715028965517241, -1.514111015384615 ], [ 29.715028965517241, -1.514649969230769 ], [ 29.715298448275863, -1.514649969230769 ], [ 29.715298448275863, -1.514111015384615 ], [ 29.715028965517241, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9268, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.514111015384615 ], [ 29.717993275862067, -1.514380492307692 ], [ 29.718262758620689, -1.514380492307692 ], [ 29.718262758620689, -1.514111015384615 ], [ 29.717993275862067, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9269, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.514111015384615 ], [ 29.718262758620689, -1.514380492307692 ], [ 29.718532241379311, -1.514380492307692 ], [ 29.718532241379311, -1.514111015384615 ], [ 29.718262758620689, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9270, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.514111015384615 ], [ 29.718532241379311, -1.514380492307692 ], [ 29.718801724137933, -1.514380492307692 ], [ 29.718801724137933, -1.514111015384615 ], [ 29.718532241379311, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9271, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.514111015384615 ], [ 29.718801724137933, -1.514380492307692 ], [ 29.719071206896551, -1.514380492307692 ], [ 29.719071206896551, -1.514111015384615 ], [ 29.718801724137933, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9272, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.514111015384615 ], [ 29.719071206896551, -1.514380492307692 ], [ 29.719340689655173, -1.514380492307692 ], [ 29.719340689655173, -1.514111015384615 ], [ 29.719071206896551, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9273, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.514111015384615 ], [ 29.719340689655173, -1.514380492307692 ], [ 29.719610172413795, -1.514380492307692 ], [ 29.719610172413795, -1.514111015384615 ], [ 29.719340689655173, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9274, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.514111015384615 ], [ 29.719610172413795, -1.514380492307692 ], [ 29.719879655172413, -1.514380492307692 ], [ 29.719879655172413, -1.514111015384615 ], [ 29.719610172413795, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9275, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.514111015384615 ], [ 29.719879655172413, -1.514380492307692 ], [ 29.720149137931035, -1.514380492307692 ], [ 29.720149137931035, -1.514111015384615 ], [ 29.719879655172413, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9276, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.514111015384615 ], [ 29.720149137931035, -1.514380492307692 ], [ 29.720688103448275, -1.514380492307692 ], [ 29.720688103448275, -1.514111015384615 ], [ 29.720149137931035, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9277, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.514111015384615 ], [ 29.720688103448275, -1.514380492307692 ], [ 29.720957586206897, -1.514380492307692 ], [ 29.720957586206897, -1.514111015384615 ], [ 29.720688103448275, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9278, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.514111015384615 ], [ 29.720957586206897, -1.514380492307692 ], [ 29.721227068965518, -1.514380492307692 ], [ 29.721227068965518, -1.514111015384615 ], [ 29.720957586206897, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9279, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.514111015384615 ], [ 29.721227068965518, -1.514380492307692 ], [ 29.721496551724137, -1.514380492307692 ], [ 29.721496551724137, -1.514111015384615 ], [ 29.721227068965518, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9280, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.514111015384615 ], [ 29.721496551724137, -1.514380492307692 ], [ 29.722305, -1.514380492307692 ], [ 29.722305, -1.514111015384615 ], [ 29.721496551724137, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9281, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.514111015384615 ], [ 29.722305, -1.514380492307692 ], [ 29.722843965517242, -1.514380492307692 ], [ 29.722843965517242, -1.514111015384615 ], [ 29.722305, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9282, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.514111015384615 ], [ 29.722843965517242, -1.514380492307692 ], [ 29.723113448275861, -1.514380492307692 ], [ 29.723113448275861, -1.514111015384615 ], [ 29.722843965517242, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9283, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.514111015384615 ], [ 29.723113448275861, -1.514380492307692 ], [ 29.723382931034482, -1.514380492307692 ], [ 29.723382931034482, -1.514111015384615 ], [ 29.723113448275861, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9284, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.514111015384615 ], [ 29.723382931034482, -1.514380492307692 ], [ 29.723652413793104, -1.514380492307692 ], [ 29.723652413793104, -1.514111015384615 ], [ 29.723382931034482, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9285, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.514111015384615 ], [ 29.723652413793104, -1.514380492307692 ], [ 29.723921896551722, -1.514380492307692 ], [ 29.723921896551722, -1.514111015384615 ], [ 29.723652413793104, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9286, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.514111015384615 ], [ 29.723921896551722, -1.514380492307692 ], [ 29.724191379310344, -1.514380492307692 ], [ 29.724191379310344, -1.514111015384615 ], [ 29.723921896551722, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9287, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.514111015384615 ], [ 29.724191379310344, -1.514380492307692 ], [ 29.724460862068966, -1.514380492307692 ], [ 29.724460862068966, -1.514111015384615 ], [ 29.724191379310344, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9288, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.514111015384615 ], [ 29.724460862068966, -1.514380492307692 ], [ 29.724730344827588, -1.514380492307692 ], [ 29.724730344827588, -1.514111015384615 ], [ 29.724460862068966, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9289, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.514111015384615 ], [ 29.724730344827588, -1.514380492307692 ], [ 29.724999827586206, -1.514380492307692 ], [ 29.724999827586206, -1.514111015384615 ], [ 29.724730344827588, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9290, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.514111015384615 ], [ 29.724999827586206, -1.514380492307692 ], [ 29.725269310344828, -1.514380492307692 ], [ 29.725269310344828, -1.514111015384615 ], [ 29.724999827586206, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9291, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.514111015384615 ], [ 29.725269310344828, -1.514380492307692 ], [ 29.72553879310345, -1.514380492307692 ], [ 29.72553879310345, -1.514111015384615 ], [ 29.725269310344828, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9292, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.514111015384615 ], [ 29.72553879310345, -1.514380492307692 ], [ 29.725808275862068, -1.514380492307692 ], [ 29.725808275862068, -1.514111015384615 ], [ 29.72553879310345, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9293, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.514111015384615 ], [ 29.725808275862068, -1.514380492307692 ], [ 29.72607775862069, -1.514380492307692 ], [ 29.72607775862069, -1.514111015384615 ], [ 29.725808275862068, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9294, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.514111015384615 ], [ 29.72607775862069, -1.514380492307692 ], [ 29.726347241379312, -1.514380492307692 ], [ 29.726347241379312, -1.514111015384615 ], [ 29.72607775862069, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9295, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.514111015384615 ], [ 29.726347241379312, -1.514380492307692 ], [ 29.72661672413793, -1.514380492307692 ], [ 29.72661672413793, -1.514111015384615 ], [ 29.726347241379312, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9296, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.514111015384615 ], [ 29.72661672413793, -1.514380492307692 ], [ 29.726886206896552, -1.514380492307692 ], [ 29.726886206896552, -1.514111015384615 ], [ 29.72661672413793, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9297, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.514111015384615 ], [ 29.726886206896552, -1.514380492307692 ], [ 29.727155689655174, -1.514380492307692 ], [ 29.727155689655174, -1.514111015384615 ], [ 29.726886206896552, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9298, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.514111015384615 ], [ 29.727155689655174, -1.514380492307692 ], [ 29.727425172413792, -1.514380492307692 ], [ 29.727425172413792, -1.514111015384615 ], [ 29.727155689655174, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9299, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.514111015384615 ], [ 29.727964137931036, -1.514380492307692 ], [ 29.728233620689654, -1.514380492307692 ], [ 29.728233620689654, -1.514111015384615 ], [ 29.727964137931036, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9300, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.514111015384615 ], [ 29.728233620689654, -1.514380492307692 ], [ 29.728503103448276, -1.514380492307692 ], [ 29.728503103448276, -1.514111015384615 ], [ 29.728233620689654, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9301, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.514111015384615 ], [ 29.728503103448276, -1.514380492307692 ], [ 29.728772586206897, -1.514380492307692 ], [ 29.728772586206897, -1.514111015384615 ], [ 29.728503103448276, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9302, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.514111015384615 ], [ 29.728772586206897, -1.514380492307692 ], [ 29.729042068965516, -1.514380492307692 ], [ 29.729042068965516, -1.514111015384615 ], [ 29.728772586206897, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9303, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.514111015384615 ], [ 29.729042068965516, -1.514380492307692 ], [ 29.729311551724138, -1.514380492307692 ], [ 29.729311551724138, -1.514111015384615 ], [ 29.729042068965516, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9304, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.514111015384615 ], [ 29.729311551724138, -1.514380492307692 ], [ 29.729850517241381, -1.514380492307692 ], [ 29.729850517241381, -1.514111015384615 ], [ 29.729311551724138, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9305, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.514111015384615 ], [ 29.729850517241381, -1.514380492307692 ], [ 29.73012, -1.514380492307692 ], [ 29.73012, -1.514111015384615 ], [ 29.729850517241381, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9306, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.514111015384615 ], [ 29.73012, -1.514380492307692 ], [ 29.730389482758621, -1.514380492307692 ], [ 29.730389482758621, -1.514111015384615 ], [ 29.73012, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9307, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.514111015384615 ], [ 29.730389482758621, -1.514380492307692 ], [ 29.730658965517243, -1.514380492307692 ], [ 29.730658965517243, -1.514111015384615 ], [ 29.730389482758621, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9308, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.514111015384615 ], [ 29.730658965517243, -1.514380492307692 ], [ 29.730928448275861, -1.514380492307692 ], [ 29.730928448275861, -1.514111015384615 ], [ 29.730658965517243, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9309, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.514111015384615 ], [ 29.730928448275861, -1.514380492307692 ], [ 29.731197931034483, -1.514380492307692 ], [ 29.731197931034483, -1.514111015384615 ], [ 29.730928448275861, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9310, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.514111015384615 ], [ 29.731197931034483, -1.514380492307692 ], [ 29.733084310344829, -1.514380492307692 ], [ 29.733084310344829, -1.514111015384615 ], [ 29.731197931034483, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9311, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.514111015384615 ], [ 29.733084310344829, -1.514380492307692 ], [ 29.733353793103447, -1.514380492307692 ], [ 29.733353793103447, -1.514111015384615 ], [ 29.733084310344829, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9312, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.514111015384615 ], [ 29.733353793103447, -1.514380492307692 ], [ 29.733623275862069, -1.514380492307692 ], [ 29.733623275862069, -1.514111015384615 ], [ 29.733353793103447, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9313, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.514111015384615 ], [ 29.733623275862069, -1.514380492307692 ], [ 29.733892758620691, -1.514380492307692 ], [ 29.733892758620691, -1.514111015384615 ], [ 29.733623275862069, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9314, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.514111015384615 ], [ 29.733892758620691, -1.514380492307692 ], [ 29.734162241379309, -1.514380492307692 ], [ 29.734162241379309, -1.514111015384615 ], [ 29.733892758620691, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9315, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.514111015384615 ], [ 29.734162241379309, -1.514380492307692 ], [ 29.734431724137931, -1.514380492307692 ], [ 29.734431724137931, -1.514111015384615 ], [ 29.734162241379309, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9316, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.514111015384615 ], [ 29.734431724137931, -1.514380492307692 ], [ 29.734701206896553, -1.514380492307692 ], [ 29.734701206896553, -1.514111015384615 ], [ 29.734431724137931, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9317, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.514111015384615 ], [ 29.734701206896553, -1.514380492307692 ], [ 29.734970689655171, -1.514380492307692 ], [ 29.734970689655171, -1.514111015384615 ], [ 29.734701206896553, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9318, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.514111015384615 ], [ 29.734970689655171, -1.514380492307692 ], [ 29.735240172413793, -1.514380492307692 ], [ 29.735240172413793, -1.514111015384615 ], [ 29.734970689655171, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9319, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.514111015384615 ], [ 29.735240172413793, -1.514380492307692 ], [ 29.735509655172415, -1.514380492307692 ], [ 29.735509655172415, -1.514111015384615 ], [ 29.735240172413793, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9320, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744941551724139, -1.514111015384615 ], [ 29.744941551724139, -1.514380492307692 ], [ 29.745211034482757, -1.514380492307692 ], [ 29.745211034482757, -1.514111015384615 ], [ 29.744941551724139, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9321, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.514111015384615 ], [ 29.760571551724137, -1.514380492307692 ], [ 29.760841034482759, -1.514380492307692 ], [ 29.760841034482759, -1.514111015384615 ], [ 29.760571551724137, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9322, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.514111015384615 ], [ 29.760841034482759, -1.514380492307692 ], [ 29.761110517241381, -1.514380492307692 ], [ 29.761110517241381, -1.514111015384615 ], [ 29.760841034482759, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9323, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.514111015384615 ], [ 29.761110517241381, -1.514380492307692 ], [ 29.76138, -1.514380492307692 ], [ 29.76138, -1.514111015384615 ], [ 29.761110517241381, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9324, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.514111015384615 ], [ 29.761649482758621, -1.514919446153846 ], [ 29.761918965517243, -1.514919446153846 ], [ 29.761918965517243, -1.514111015384615 ], [ 29.761649482758621, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9325, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.514111015384615 ], [ 29.763535862068967, -1.514649969230769 ], [ 29.763805344827588, -1.514649969230769 ], [ 29.763805344827588, -1.514111015384615 ], [ 29.763535862068967, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9326, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.514111015384615 ], [ 29.764344310344828, -1.514649969230769 ], [ 29.76461379310345, -1.514649969230769 ], [ 29.76461379310345, -1.514111015384615 ], [ 29.764344310344828, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9327, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.514111015384615 ], [ 29.76515275862069, -1.514380492307692 ], [ 29.765422241379312, -1.514380492307692 ], [ 29.765422241379312, -1.514111015384615 ], [ 29.76515275862069, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9328, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.514111015384615 ], [ 29.765422241379312, -1.514380492307692 ], [ 29.765961206896552, -1.514380492307692 ], [ 29.765961206896552, -1.514111015384615 ], [ 29.765422241379312, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9329, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.514111015384615 ], [ 29.765961206896552, -1.514380492307692 ], [ 29.766230689655174, -1.514380492307692 ], [ 29.766230689655174, -1.514111015384615 ], [ 29.765961206896552, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9330, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.514111015384615 ], [ 29.766500172413792, -1.514380492307692 ], [ 29.766769655172414, -1.514380492307692 ], [ 29.766769655172414, -1.514111015384615 ], [ 29.766500172413792, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9331, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.514111015384615 ], [ 29.766769655172414, -1.514380492307692 ], [ 29.767039137931036, -1.514380492307692 ], [ 29.767039137931036, -1.514111015384615 ], [ 29.766769655172414, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9332, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.514111015384615 ], [ 29.767039137931036, -1.514380492307692 ], [ 29.767308620689654, -1.514380492307692 ], [ 29.767308620689654, -1.514111015384615 ], [ 29.767039137931036, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9333, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.514111015384615 ], [ 29.767308620689654, -1.514380492307692 ], [ 29.767578103448276, -1.514380492307692 ], [ 29.767578103448276, -1.514111015384615 ], [ 29.767308620689654, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9334, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.514111015384615 ], [ 29.767578103448276, -1.514380492307692 ], [ 29.767847586206898, -1.514380492307692 ], [ 29.767847586206898, -1.514111015384615 ], [ 29.767578103448276, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9335, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.514380492307692 ], [ 29.713142586206896, -1.514649969230769 ], [ 29.713681551724139, -1.514649969230769 ], [ 29.713681551724139, -1.514380492307692 ], [ 29.713142586206896, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9336, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713681551724139, -1.514380492307692 ], [ 29.713681551724139, -1.514649969230769 ], [ 29.71422051724138, -1.514649969230769 ], [ 29.71422051724138, -1.514380492307692 ], [ 29.713681551724139, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9337, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71422051724138, -1.514380492307692 ], [ 29.71422051724138, -1.514649969230769 ], [ 29.71449, -1.514649969230769 ], [ 29.71449, -1.514380492307692 ], [ 29.71422051724138, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9338, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.514380492307692 ], [ 29.717454310344827, -1.514649969230769 ], [ 29.717723793103449, -1.514649969230769 ], [ 29.717723793103449, -1.514380492307692 ], [ 29.717454310344827, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9339, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.514380492307692 ], [ 29.717723793103449, -1.514649969230769 ], [ 29.717993275862067, -1.514649969230769 ], [ 29.717993275862067, -1.514380492307692 ], [ 29.717723793103449, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9340, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.514380492307692 ], [ 29.717993275862067, -1.514649969230769 ], [ 29.718262758620689, -1.514649969230769 ], [ 29.718262758620689, -1.514380492307692 ], [ 29.717993275862067, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9341, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.514380492307692 ], [ 29.718262758620689, -1.514649969230769 ], [ 29.718532241379311, -1.514649969230769 ], [ 29.718532241379311, -1.514380492307692 ], [ 29.718262758620689, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9342, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.514380492307692 ], [ 29.718532241379311, -1.514919446153846 ], [ 29.718801724137933, -1.514919446153846 ], [ 29.718801724137933, -1.514380492307692 ], [ 29.718532241379311, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9343, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.514380492307692 ], [ 29.718801724137933, -1.514649969230769 ], [ 29.719071206896551, -1.514649969230769 ], [ 29.719071206896551, -1.514380492307692 ], [ 29.718801724137933, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9344, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.514380492307692 ], [ 29.719071206896551, -1.514649969230769 ], [ 29.719340689655173, -1.514649969230769 ], [ 29.719340689655173, -1.514380492307692 ], [ 29.719071206896551, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9345, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.514380492307692 ], [ 29.719340689655173, -1.514649969230769 ], [ 29.719610172413795, -1.514649969230769 ], [ 29.719610172413795, -1.514380492307692 ], [ 29.719340689655173, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9346, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.514380492307692 ], [ 29.719610172413795, -1.514649969230769 ], [ 29.719879655172413, -1.514649969230769 ], [ 29.719879655172413, -1.514380492307692 ], [ 29.719610172413795, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9347, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.514380492307692 ], [ 29.719879655172413, -1.514649969230769 ], [ 29.720149137931035, -1.514649969230769 ], [ 29.720149137931035, -1.514380492307692 ], [ 29.719879655172413, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9348, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.514380492307692 ], [ 29.720149137931035, -1.514649969230769 ], [ 29.720688103448275, -1.514649969230769 ], [ 29.720688103448275, -1.514380492307692 ], [ 29.720149137931035, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9349, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.514380492307692 ], [ 29.720688103448275, -1.514649969230769 ], [ 29.720957586206897, -1.514649969230769 ], [ 29.720957586206897, -1.514380492307692 ], [ 29.720688103448275, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9350, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.514380492307692 ], [ 29.720957586206897, -1.514649969230769 ], [ 29.721227068965518, -1.514649969230769 ], [ 29.721227068965518, -1.514380492307692 ], [ 29.720957586206897, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9351, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.514380492307692 ], [ 29.721227068965518, -1.514649969230769 ], [ 29.721496551724137, -1.514649969230769 ], [ 29.721496551724137, -1.514380492307692 ], [ 29.721227068965518, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9352, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.514380492307692 ], [ 29.721496551724137, -1.514649969230769 ], [ 29.721766034482759, -1.514649969230769 ], [ 29.721766034482759, -1.514380492307692 ], [ 29.721496551724137, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9353, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721766034482759, -1.514380492307692 ], [ 29.721766034482759, -1.514649969230769 ], [ 29.72203551724138, -1.514649969230769 ], [ 29.72203551724138, -1.514380492307692 ], [ 29.721766034482759, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9354, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.514380492307692 ], [ 29.72203551724138, -1.514649969230769 ], [ 29.722305, -1.514649969230769 ], [ 29.722305, -1.514380492307692 ], [ 29.72203551724138, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9355, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722305, -1.514380492307692 ], [ 29.722305, -1.514649969230769 ], [ 29.722843965517242, -1.514649969230769 ], [ 29.722843965517242, -1.514380492307692 ], [ 29.722305, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9356, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.514380492307692 ], [ 29.722843965517242, -1.514649969230769 ], [ 29.723113448275861, -1.514649969230769 ], [ 29.723113448275861, -1.514380492307692 ], [ 29.722843965517242, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9357, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.514380492307692 ], [ 29.723113448275861, -1.514649969230769 ], [ 29.723382931034482, -1.514649969230769 ], [ 29.723382931034482, -1.514380492307692 ], [ 29.723113448275861, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9358, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.514380492307692 ], [ 29.723382931034482, -1.514649969230769 ], [ 29.723652413793104, -1.514649969230769 ], [ 29.723652413793104, -1.514380492307692 ], [ 29.723382931034482, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9359, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.514380492307692 ], [ 29.723652413793104, -1.514649969230769 ], [ 29.723921896551722, -1.514649969230769 ], [ 29.723921896551722, -1.514380492307692 ], [ 29.723652413793104, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9360, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.514380492307692 ], [ 29.723921896551722, -1.514649969230769 ], [ 29.724191379310344, -1.514649969230769 ], [ 29.724191379310344, -1.514380492307692 ], [ 29.723921896551722, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9361, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.514380492307692 ], [ 29.724191379310344, -1.514649969230769 ], [ 29.724460862068966, -1.514649969230769 ], [ 29.724460862068966, -1.514380492307692 ], [ 29.724191379310344, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9362, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.514380492307692 ], [ 29.724460862068966, -1.514649969230769 ], [ 29.724730344827588, -1.514649969230769 ], [ 29.724730344827588, -1.514380492307692 ], [ 29.724460862068966, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9363, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.514380492307692 ], [ 29.724730344827588, -1.514649969230769 ], [ 29.724999827586206, -1.514649969230769 ], [ 29.724999827586206, -1.514380492307692 ], [ 29.724730344827588, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9364, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.514380492307692 ], [ 29.724999827586206, -1.514649969230769 ], [ 29.725269310344828, -1.514649969230769 ], [ 29.725269310344828, -1.514380492307692 ], [ 29.724999827586206, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9365, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.514380492307692 ], [ 29.725269310344828, -1.514649969230769 ], [ 29.72553879310345, -1.514649969230769 ], [ 29.72553879310345, -1.514380492307692 ], [ 29.725269310344828, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9366, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.514380492307692 ], [ 29.72553879310345, -1.514649969230769 ], [ 29.725808275862068, -1.514649969230769 ], [ 29.725808275862068, -1.514380492307692 ], [ 29.72553879310345, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9367, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.514380492307692 ], [ 29.725808275862068, -1.514649969230769 ], [ 29.72607775862069, -1.514649969230769 ], [ 29.72607775862069, -1.514380492307692 ], [ 29.725808275862068, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9368, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.514380492307692 ], [ 29.72607775862069, -1.514649969230769 ], [ 29.726347241379312, -1.514649969230769 ], [ 29.726347241379312, -1.514380492307692 ], [ 29.72607775862069, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9369, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.514380492307692 ], [ 29.726347241379312, -1.514649969230769 ], [ 29.72661672413793, -1.514649969230769 ], [ 29.72661672413793, -1.514380492307692 ], [ 29.726347241379312, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9370, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.514380492307692 ], [ 29.72661672413793, -1.514649969230769 ], [ 29.726886206896552, -1.514649969230769 ], [ 29.726886206896552, -1.514380492307692 ], [ 29.72661672413793, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9371, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.514380492307692 ], [ 29.726886206896552, -1.514649969230769 ], [ 29.727155689655174, -1.514649969230769 ], [ 29.727155689655174, -1.514380492307692 ], [ 29.726886206896552, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9372, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.514380492307692 ], [ 29.727155689655174, -1.514649969230769 ], [ 29.727425172413792, -1.514649969230769 ], [ 29.727425172413792, -1.514380492307692 ], [ 29.727155689655174, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9373, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.514380492307692 ], [ 29.727425172413792, -1.514649969230769 ], [ 29.727694655172414, -1.514649969230769 ], [ 29.727694655172414, -1.514380492307692 ], [ 29.727425172413792, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9374, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.514380492307692 ], [ 29.728233620689654, -1.514649969230769 ], [ 29.728503103448276, -1.514649969230769 ], [ 29.728503103448276, -1.514380492307692 ], [ 29.728233620689654, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9375, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.514380492307692 ], [ 29.728503103448276, -1.514649969230769 ], [ 29.728772586206897, -1.514649969230769 ], [ 29.728772586206897, -1.514380492307692 ], [ 29.728503103448276, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9376, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.514380492307692 ], [ 29.728772586206897, -1.514649969230769 ], [ 29.729042068965516, -1.514649969230769 ], [ 29.729042068965516, -1.514380492307692 ], [ 29.728772586206897, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9377, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.514380492307692 ], [ 29.729042068965516, -1.514649969230769 ], [ 29.729311551724138, -1.514649969230769 ], [ 29.729311551724138, -1.514380492307692 ], [ 29.729042068965516, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9378, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.514380492307692 ], [ 29.729311551724138, -1.514649969230769 ], [ 29.729850517241381, -1.514649969230769 ], [ 29.729850517241381, -1.514380492307692 ], [ 29.729311551724138, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9379, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.514380492307692 ], [ 29.729850517241381, -1.514649969230769 ], [ 29.73012, -1.514649969230769 ], [ 29.73012, -1.514380492307692 ], [ 29.729850517241381, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9380, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.514380492307692 ], [ 29.73012, -1.514649969230769 ], [ 29.730389482758621, -1.514649969230769 ], [ 29.730389482758621, -1.514380492307692 ], [ 29.73012, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9381, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.514380492307692 ], [ 29.730389482758621, -1.514649969230769 ], [ 29.730658965517243, -1.514649969230769 ], [ 29.730658965517243, -1.514380492307692 ], [ 29.730389482758621, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9382, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.514380492307692 ], [ 29.730658965517243, -1.514649969230769 ], [ 29.730928448275861, -1.514649969230769 ], [ 29.730928448275861, -1.514380492307692 ], [ 29.730658965517243, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9383, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.514380492307692 ], [ 29.730928448275861, -1.514649969230769 ], [ 29.731197931034483, -1.514649969230769 ], [ 29.731197931034483, -1.514380492307692 ], [ 29.730928448275861, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9384, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.514380492307692 ], [ 29.731197931034483, -1.514649969230769 ], [ 29.733084310344829, -1.514649969230769 ], [ 29.733084310344829, -1.514380492307692 ], [ 29.731197931034483, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9385, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.514380492307692 ], [ 29.733084310344829, -1.514649969230769 ], [ 29.733353793103447, -1.514649969230769 ], [ 29.733353793103447, -1.514380492307692 ], [ 29.733084310344829, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9386, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.514380492307692 ], [ 29.733353793103447, -1.514649969230769 ], [ 29.733623275862069, -1.514649969230769 ], [ 29.733623275862069, -1.514380492307692 ], [ 29.733353793103447, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9387, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.514380492307692 ], [ 29.733623275862069, -1.514649969230769 ], [ 29.733892758620691, -1.514649969230769 ], [ 29.733892758620691, -1.514380492307692 ], [ 29.733623275862069, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9388, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.514380492307692 ], [ 29.733892758620691, -1.514649969230769 ], [ 29.734162241379309, -1.514649969230769 ], [ 29.734162241379309, -1.514380492307692 ], [ 29.733892758620691, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9389, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.514380492307692 ], [ 29.734162241379309, -1.514649969230769 ], [ 29.734431724137931, -1.514649969230769 ], [ 29.734431724137931, -1.514380492307692 ], [ 29.734162241379309, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9390, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.514380492307692 ], [ 29.734431724137931, -1.514649969230769 ], [ 29.734701206896553, -1.514649969230769 ], [ 29.734701206896553, -1.514380492307692 ], [ 29.734431724137931, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9391, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.514380492307692 ], [ 29.734701206896553, -1.514649969230769 ], [ 29.734970689655171, -1.514649969230769 ], [ 29.734970689655171, -1.514380492307692 ], [ 29.734701206896553, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9392, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.514380492307692 ], [ 29.734970689655171, -1.514649969230769 ], [ 29.735240172413793, -1.514649969230769 ], [ 29.735240172413793, -1.514380492307692 ], [ 29.734970689655171, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9393, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.514111015384615 ], [ 29.735779137931036, -1.514111015384615 ], [ 29.735779137931036, -1.515188923076923 ], [ 29.735509655172415, -1.515188923076923 ], [ 29.735509655172415, -1.514649969230769 ], [ 29.735240172413793, -1.514649969230769 ], [ 29.735240172413793, -1.514380492307692 ], [ 29.735509655172415, -1.514380492307692 ], [ 29.735509655172415, -1.514111015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9394, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.514380492307692 ], [ 29.738204482758622, -1.514919446153846 ], [ 29.738743448275862, -1.514919446153846 ], [ 29.738743448275862, -1.514380492307692 ], [ 29.738204482758622, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9395, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.514380492307692 ], [ 29.739282413793102, -1.514649969230769 ], [ 29.739551896551724, -1.514649969230769 ], [ 29.739551896551724, -1.514380492307692 ], [ 29.739282413793102, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9396, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.514380492307692 ], [ 29.739551896551724, -1.514649969230769 ], [ 29.739821379310346, -1.514649969230769 ], [ 29.739821379310346, -1.514380492307692 ], [ 29.739551896551724, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9397, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.514380492307692 ], [ 29.760302068965519, -1.514649969230769 ], [ 29.760571551724137, -1.514649969230769 ], [ 29.760571551724137, -1.514380492307692 ], [ 29.760302068965519, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9398, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.514380492307692 ], [ 29.760571551724137, -1.514649969230769 ], [ 29.760841034482759, -1.514649969230769 ], [ 29.760841034482759, -1.514380492307692 ], [ 29.760571551724137, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9399, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.514380492307692 ], [ 29.760841034482759, -1.514649969230769 ], [ 29.761110517241381, -1.514649969230769 ], [ 29.761110517241381, -1.514380492307692 ], [ 29.760841034482759, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9400, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.514380492307692 ], [ 29.761110517241381, -1.514649969230769 ], [ 29.76138, -1.514649969230769 ], [ 29.76138, -1.514380492307692 ], [ 29.761110517241381, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9401, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.514380492307692 ], [ 29.761918965517243, -1.514919446153846 ], [ 29.762188448275861, -1.514919446153846 ], [ 29.762188448275861, -1.514380492307692 ], [ 29.761918965517243, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9402, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.514380492307692 ], [ 29.762727413793105, -1.514919446153846 ], [ 29.762996896551723, -1.514919446153846 ], [ 29.762996896551723, -1.514380492307692 ], [ 29.762727413793105, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9403, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.514380492307692 ], [ 29.762996896551723, -1.514919446153846 ], [ 29.763266379310345, -1.514919446153846 ], [ 29.763266379310345, -1.514380492307692 ], [ 29.762996896551723, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9404, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.514380492307692 ], [ 29.763805344827588, -1.514919446153846 ], [ 29.764074827586207, -1.514919446153846 ], [ 29.764074827586207, -1.514380492307692 ], [ 29.763805344827588, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9405, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.514380492307692 ], [ 29.76461379310345, -1.514649969230769 ], [ 29.764883275862069, -1.514649969230769 ], [ 29.764883275862069, -1.514380492307692 ], [ 29.76461379310345, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9406, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.514380492307692 ], [ 29.764883275862069, -1.514649969230769 ], [ 29.76515275862069, -1.514649969230769 ], [ 29.76515275862069, -1.514380492307692 ], [ 29.764883275862069, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9407, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.514380492307692 ], [ 29.76515275862069, -1.514649969230769 ], [ 29.765422241379312, -1.514649969230769 ], [ 29.765422241379312, -1.514380492307692 ], [ 29.76515275862069, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9408, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.514380492307692 ], [ 29.765422241379312, -1.514649969230769 ], [ 29.765961206896552, -1.514649969230769 ], [ 29.765961206896552, -1.514380492307692 ], [ 29.765422241379312, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9409, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.514380492307692 ], [ 29.765961206896552, -1.514649969230769 ], [ 29.766230689655174, -1.514649969230769 ], [ 29.766230689655174, -1.514380492307692 ], [ 29.765961206896552, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9410, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.514380492307692 ], [ 29.766230689655174, -1.514649969230769 ], [ 29.766500172413792, -1.514649969230769 ], [ 29.766500172413792, -1.514380492307692 ], [ 29.766230689655174, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9411, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.514380492307692 ], [ 29.766500172413792, -1.514649969230769 ], [ 29.766769655172414, -1.514649969230769 ], [ 29.766769655172414, -1.514380492307692 ], [ 29.766500172413792, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9412, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.514380492307692 ], [ 29.766769655172414, -1.514649969230769 ], [ 29.767039137931036, -1.514649969230769 ], [ 29.767039137931036, -1.514380492307692 ], [ 29.766769655172414, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9413, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.514380492307692 ], [ 29.767039137931036, -1.514649969230769 ], [ 29.767308620689654, -1.514649969230769 ], [ 29.767308620689654, -1.514380492307692 ], [ 29.767039137931036, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9414, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.514380492307692 ], [ 29.767308620689654, -1.514649969230769 ], [ 29.767578103448276, -1.514649969230769 ], [ 29.767578103448276, -1.514380492307692 ], [ 29.767308620689654, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9415, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.514380492307692 ], [ 29.767578103448276, -1.514649969230769 ], [ 29.767847586206898, -1.514649969230769 ], [ 29.767847586206898, -1.514380492307692 ], [ 29.767578103448276, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9416, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.514380492307692 ], [ 29.767847586206898, -1.514649969230769 ], [ 29.768386551724138, -1.514649969230769 ], [ 29.768386551724138, -1.514380492307692 ], [ 29.767847586206898, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9417, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.514649969230769 ], [ 29.712873103448278, -1.514919446153846 ], [ 29.713142586206896, -1.514919446153846 ], [ 29.713142586206896, -1.514649969230769 ], [ 29.712873103448278, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9418, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.514649969230769 ], [ 29.713142586206896, -1.514919446153846 ], [ 29.713412068965518, -1.514919446153846 ], [ 29.713412068965518, -1.514649969230769 ], [ 29.713142586206896, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9419, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71449, -1.514380492307692 ], [ 29.71475948275862, -1.514380492307692 ], [ 29.71475948275862, -1.514919446153846 ], [ 29.713412068965518, -1.514919446153846 ], [ 29.713412068965518, -1.514649969230769 ], [ 29.71449, -1.514649969230769 ], [ 29.71449, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9420, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715028965517241, -1.514649969230769 ], [ 29.715028965517241, -1.515188923076923 ], [ 29.715298448275863, -1.515188923076923 ], [ 29.715298448275863, -1.514649969230769 ], [ 29.715028965517241, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9421, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.715298448275863, -1.514649969230769 ], [ 29.715298448275863, -1.515188923076923 ], [ 29.715567931034482, -1.515188923076923 ], [ 29.715567931034482, -1.514649969230769 ], [ 29.715298448275863, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9422, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717454310344827, -1.514649969230769 ], [ 29.717454310344827, -1.514919446153846 ], [ 29.717723793103449, -1.514919446153846 ], [ 29.717723793103449, -1.514649969230769 ], [ 29.717454310344827, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9423, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.514649969230769 ], [ 29.717723793103449, -1.514919446153846 ], [ 29.717993275862067, -1.514919446153846 ], [ 29.717993275862067, -1.514649969230769 ], [ 29.717723793103449, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9424, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.514649969230769 ], [ 29.717993275862067, -1.515188923076923 ], [ 29.718262758620689, -1.515188923076923 ], [ 29.718262758620689, -1.514649969230769 ], [ 29.717993275862067, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9425, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.514649969230769 ], [ 29.718262758620689, -1.515188923076923 ], [ 29.718532241379311, -1.515188923076923 ], [ 29.718532241379311, -1.514649969230769 ], [ 29.718262758620689, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9426, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.514649969230769 ], [ 29.718801724137933, -1.514919446153846 ], [ 29.719071206896551, -1.514919446153846 ], [ 29.719071206896551, -1.514649969230769 ], [ 29.718801724137933, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9427, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.514649969230769 ], [ 29.719071206896551, -1.514919446153846 ], [ 29.719340689655173, -1.514919446153846 ], [ 29.719340689655173, -1.514649969230769 ], [ 29.719071206896551, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9428, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.514649969230769 ], [ 29.719340689655173, -1.514919446153846 ], [ 29.719610172413795, -1.514919446153846 ], [ 29.719610172413795, -1.514649969230769 ], [ 29.719340689655173, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9429, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.514649969230769 ], [ 29.719610172413795, -1.514919446153846 ], [ 29.719879655172413, -1.514919446153846 ], [ 29.719879655172413, -1.514649969230769 ], [ 29.719610172413795, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9430, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.514649969230769 ], [ 29.719879655172413, -1.515188923076923 ], [ 29.720149137931035, -1.515188923076923 ], [ 29.720149137931035, -1.514649969230769 ], [ 29.719879655172413, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9431, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.514649969230769 ], [ 29.720149137931035, -1.514919446153846 ], [ 29.720688103448275, -1.514919446153846 ], [ 29.720688103448275, -1.514649969230769 ], [ 29.720149137931035, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9432, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.514649969230769 ], [ 29.720688103448275, -1.514919446153846 ], [ 29.720957586206897, -1.514919446153846 ], [ 29.720957586206897, -1.514649969230769 ], [ 29.720688103448275, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9433, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.514649969230769 ], [ 29.720957586206897, -1.514919446153846 ], [ 29.721227068965518, -1.514919446153846 ], [ 29.721227068965518, -1.514649969230769 ], [ 29.720957586206897, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9434, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.514649969230769 ], [ 29.721227068965518, -1.514919446153846 ], [ 29.721496551724137, -1.514919446153846 ], [ 29.721496551724137, -1.514649969230769 ], [ 29.721227068965518, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9435, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721496551724137, -1.514649969230769 ], [ 29.721496551724137, -1.514919446153846 ], [ 29.721766034482759, -1.514919446153846 ], [ 29.721766034482759, -1.514649969230769 ], [ 29.721496551724137, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9436, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72203551724138, -1.514649969230769 ], [ 29.72203551724138, -1.514919446153846 ], [ 29.72257448275862, -1.514919446153846 ], [ 29.72257448275862, -1.514649969230769 ], [ 29.72203551724138, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9437, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.514649969230769 ], [ 29.72257448275862, -1.514919446153846 ], [ 29.722843965517242, -1.514919446153846 ], [ 29.722843965517242, -1.514649969230769 ], [ 29.72257448275862, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9438, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.514649969230769 ], [ 29.722843965517242, -1.514919446153846 ], [ 29.723113448275861, -1.514919446153846 ], [ 29.723113448275861, -1.514649969230769 ], [ 29.722843965517242, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9439, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.514649969230769 ], [ 29.723113448275861, -1.514919446153846 ], [ 29.723382931034482, -1.514919446153846 ], [ 29.723382931034482, -1.514649969230769 ], [ 29.723113448275861, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9440, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.514649969230769 ], [ 29.723382931034482, -1.514919446153846 ], [ 29.723652413793104, -1.514919446153846 ], [ 29.723652413793104, -1.514649969230769 ], [ 29.723382931034482, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9441, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.514649969230769 ], [ 29.723652413793104, -1.514919446153846 ], [ 29.723921896551722, -1.514919446153846 ], [ 29.723921896551722, -1.514649969230769 ], [ 29.723652413793104, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9442, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.514649969230769 ], [ 29.723921896551722, -1.514919446153846 ], [ 29.724191379310344, -1.514919446153846 ], [ 29.724191379310344, -1.514649969230769 ], [ 29.723921896551722, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9443, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.514649969230769 ], [ 29.724191379310344, -1.514919446153846 ], [ 29.724460862068966, -1.514919446153846 ], [ 29.724460862068966, -1.514649969230769 ], [ 29.724191379310344, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9444, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.514649969230769 ], [ 29.724460862068966, -1.514919446153846 ], [ 29.724730344827588, -1.514919446153846 ], [ 29.724730344827588, -1.514649969230769 ], [ 29.724460862068966, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9445, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.514649969230769 ], [ 29.724730344827588, -1.514919446153846 ], [ 29.724999827586206, -1.514919446153846 ], [ 29.724999827586206, -1.514649969230769 ], [ 29.724730344827588, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9446, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.514649969230769 ], [ 29.724999827586206, -1.514919446153846 ], [ 29.725269310344828, -1.514919446153846 ], [ 29.725269310344828, -1.514649969230769 ], [ 29.724999827586206, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9447, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.514649969230769 ], [ 29.725269310344828, -1.514919446153846 ], [ 29.72553879310345, -1.514919446153846 ], [ 29.72553879310345, -1.514649969230769 ], [ 29.725269310344828, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9448, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.514649969230769 ], [ 29.72553879310345, -1.514919446153846 ], [ 29.725808275862068, -1.514919446153846 ], [ 29.725808275862068, -1.514649969230769 ], [ 29.72553879310345, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9449, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.514649969230769 ], [ 29.725808275862068, -1.514919446153846 ], [ 29.72607775862069, -1.514919446153846 ], [ 29.72607775862069, -1.514649969230769 ], [ 29.725808275862068, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9450, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.514649969230769 ], [ 29.72607775862069, -1.514919446153846 ], [ 29.726347241379312, -1.514919446153846 ], [ 29.726347241379312, -1.514649969230769 ], [ 29.72607775862069, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9451, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.514649969230769 ], [ 29.726347241379312, -1.514919446153846 ], [ 29.72661672413793, -1.514919446153846 ], [ 29.72661672413793, -1.514649969230769 ], [ 29.726347241379312, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9452, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.514649969230769 ], [ 29.72661672413793, -1.514919446153846 ], [ 29.726886206896552, -1.514919446153846 ], [ 29.726886206896552, -1.514649969230769 ], [ 29.72661672413793, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9453, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.514649969230769 ], [ 29.726886206896552, -1.514919446153846 ], [ 29.727155689655174, -1.514919446153846 ], [ 29.727155689655174, -1.514649969230769 ], [ 29.726886206896552, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9454, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.514649969230769 ], [ 29.727155689655174, -1.514919446153846 ], [ 29.727425172413792, -1.514919446153846 ], [ 29.727425172413792, -1.514649969230769 ], [ 29.727155689655174, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9455, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.514649969230769 ], [ 29.727425172413792, -1.514919446153846 ], [ 29.727694655172414, -1.514919446153846 ], [ 29.727694655172414, -1.514649969230769 ], [ 29.727425172413792, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9456, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.514649969230769 ], [ 29.727694655172414, -1.514919446153846 ], [ 29.727964137931036, -1.514919446153846 ], [ 29.727964137931036, -1.514649969230769 ], [ 29.727694655172414, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9457, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.514649969230769 ], [ 29.728233620689654, -1.514919446153846 ], [ 29.728503103448276, -1.514919446153846 ], [ 29.728503103448276, -1.514649969230769 ], [ 29.728233620689654, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9458, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.514649969230769 ], [ 29.728503103448276, -1.514919446153846 ], [ 29.728772586206897, -1.514919446153846 ], [ 29.728772586206897, -1.514649969230769 ], [ 29.728503103448276, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9459, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.514649969230769 ], [ 29.728772586206897, -1.514919446153846 ], [ 29.729042068965516, -1.514919446153846 ], [ 29.729042068965516, -1.514649969230769 ], [ 29.728772586206897, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9460, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.514649969230769 ], [ 29.729042068965516, -1.514919446153846 ], [ 29.729311551724138, -1.514919446153846 ], [ 29.729311551724138, -1.514649969230769 ], [ 29.729042068965516, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9461, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.514649969230769 ], [ 29.729311551724138, -1.514919446153846 ], [ 29.729850517241381, -1.514919446153846 ], [ 29.729850517241381, -1.514649969230769 ], [ 29.729311551724138, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9462, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.514649969230769 ], [ 29.729850517241381, -1.514919446153846 ], [ 29.73012, -1.514919446153846 ], [ 29.73012, -1.514649969230769 ], [ 29.729850517241381, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9463, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.514649969230769 ], [ 29.73012, -1.514919446153846 ], [ 29.730389482758621, -1.514919446153846 ], [ 29.730389482758621, -1.514649969230769 ], [ 29.73012, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9464, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.514649969230769 ], [ 29.730389482758621, -1.514919446153846 ], [ 29.730658965517243, -1.514919446153846 ], [ 29.730658965517243, -1.514649969230769 ], [ 29.730389482758621, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9465, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.514649969230769 ], [ 29.730658965517243, -1.514919446153846 ], [ 29.730928448275861, -1.514919446153846 ], [ 29.730928448275861, -1.514649969230769 ], [ 29.730658965517243, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9466, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.514649969230769 ], [ 29.732814827586207, -1.514919446153846 ], [ 29.733353793103447, -1.514919446153846 ], [ 29.733353793103447, -1.514649969230769 ], [ 29.732814827586207, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9467, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.514649969230769 ], [ 29.733353793103447, -1.514919446153846 ], [ 29.733623275862069, -1.514919446153846 ], [ 29.733623275862069, -1.514649969230769 ], [ 29.733353793103447, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9468, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.514649969230769 ], [ 29.733623275862069, -1.514919446153846 ], [ 29.733892758620691, -1.514919446153846 ], [ 29.733892758620691, -1.514649969230769 ], [ 29.733623275862069, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9469, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.514649969230769 ], [ 29.733892758620691, -1.514919446153846 ], [ 29.734162241379309, -1.514919446153846 ], [ 29.734162241379309, -1.514649969230769 ], [ 29.733892758620691, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9470, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.514649969230769 ], [ 29.734162241379309, -1.514919446153846 ], [ 29.734431724137931, -1.514919446153846 ], [ 29.734431724137931, -1.514649969230769 ], [ 29.734162241379309, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9471, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.514649969230769 ], [ 29.734431724137931, -1.514919446153846 ], [ 29.734701206896553, -1.514919446153846 ], [ 29.734701206896553, -1.514649969230769 ], [ 29.734431724137931, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9472, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.514649969230769 ], [ 29.734701206896553, -1.514919446153846 ], [ 29.734970689655171, -1.514919446153846 ], [ 29.734970689655171, -1.514649969230769 ], [ 29.734701206896553, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9473, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.514649969230769 ], [ 29.734970689655171, -1.514919446153846 ], [ 29.735240172413793, -1.514919446153846 ], [ 29.735240172413793, -1.514649969230769 ], [ 29.734970689655171, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9474, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.514649969230769 ], [ 29.735240172413793, -1.514919446153846 ], [ 29.735509655172415, -1.514919446153846 ], [ 29.735509655172415, -1.514649969230769 ], [ 29.735240172413793, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9475, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.514649969230769 ], [ 29.735779137931036, -1.515188923076923 ], [ 29.736048620689655, -1.515188923076923 ], [ 29.736048620689655, -1.514649969230769 ], [ 29.735779137931036, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9476, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.514649969230769 ], [ 29.736587586206898, -1.515188923076923 ], [ 29.736857068965517, -1.515188923076923 ], [ 29.736857068965517, -1.514649969230769 ], [ 29.736587586206898, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9477, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.514649969230769 ], [ 29.73739603448276, -1.514919446153846 ], [ 29.737665517241378, -1.514919446153846 ], [ 29.737665517241378, -1.514649969230769 ], [ 29.73739603448276, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9478, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.514649969230769 ], [ 29.737665517241378, -1.514919446153846 ], [ 29.737935, -1.514919446153846 ], [ 29.737935, -1.514649969230769 ], [ 29.737665517241378, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9479, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.514649969230769 ], [ 29.737935, -1.514919446153846 ], [ 29.738204482758622, -1.514919446153846 ], [ 29.738204482758622, -1.514649969230769 ], [ 29.737935, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9480, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.514649969230769 ], [ 29.738743448275862, -1.514919446153846 ], [ 29.739012931034484, -1.514919446153846 ], [ 29.739012931034484, -1.514649969230769 ], [ 29.738743448275862, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9481, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.514649969230769 ], [ 29.739012931034484, -1.514919446153846 ], [ 29.739282413793102, -1.514919446153846 ], [ 29.739282413793102, -1.514649969230769 ], [ 29.739012931034484, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9482, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.514649969230769 ], [ 29.739282413793102, -1.514919446153846 ], [ 29.739551896551724, -1.514919446153846 ], [ 29.739551896551724, -1.514649969230769 ], [ 29.739282413793102, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9483, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.514649969230769 ], [ 29.739551896551724, -1.514919446153846 ], [ 29.739821379310346, -1.514919446153846 ], [ 29.739821379310346, -1.514649969230769 ], [ 29.739551896551724, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9484, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.514649969230769 ], [ 29.739821379310346, -1.514919446153846 ], [ 29.740090862068964, -1.514919446153846 ], [ 29.740090862068964, -1.514649969230769 ], [ 29.739821379310346, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9485, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.514649969230769 ], [ 29.754373448275864, -1.5154584 ], [ 29.754642931034482, -1.5154584 ], [ 29.754642931034482, -1.514919446153846 ], [ 29.754912413793104, -1.514919446153846 ], [ 29.754912413793104, -1.514649969230769 ], [ 29.754373448275864, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9486, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.514649969230769 ], [ 29.754912413793104, -1.514919446153846 ], [ 29.755181896551726, -1.514919446153846 ], [ 29.755181896551726, -1.514649969230769 ], [ 29.754912413793104, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9487, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.514649969230769 ], [ 29.755720862068966, -1.515188923076923 ], [ 29.755990344827588, -1.515188923076923 ], [ 29.755990344827588, -1.514649969230769 ], [ 29.755720862068966, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9488, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.514649969230769 ], [ 29.759493620689657, -1.514919446153846 ], [ 29.759763103448275, -1.514919446153846 ], [ 29.759763103448275, -1.514649969230769 ], [ 29.759493620689657, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9489, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.514649969230769 ], [ 29.759763103448275, -1.514919446153846 ], [ 29.760032586206897, -1.514919446153846 ], [ 29.760032586206897, -1.514649969230769 ], [ 29.759763103448275, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9490, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.514649969230769 ], [ 29.760032586206897, -1.514919446153846 ], [ 29.760302068965519, -1.514919446153846 ], [ 29.760302068965519, -1.514649969230769 ], [ 29.760032586206897, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9491, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.514649969230769 ], [ 29.760302068965519, -1.514919446153846 ], [ 29.760571551724137, -1.514919446153846 ], [ 29.760571551724137, -1.514649969230769 ], [ 29.760302068965519, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9492, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.514649969230769 ], [ 29.760571551724137, -1.514919446153846 ], [ 29.760841034482759, -1.514919446153846 ], [ 29.760841034482759, -1.514649969230769 ], [ 29.760571551724137, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9493, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.514649969230769 ], [ 29.760841034482759, -1.514919446153846 ], [ 29.761110517241381, -1.514919446153846 ], [ 29.761110517241381, -1.514649969230769 ], [ 29.760841034482759, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9494, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.514649969230769 ], [ 29.761110517241381, -1.514919446153846 ], [ 29.76138, -1.514919446153846 ], [ 29.76138, -1.514649969230769 ], [ 29.761110517241381, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9495, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.514649969230769 ], [ 29.76138, -1.514919446153846 ], [ 29.761649482758621, -1.514919446153846 ], [ 29.761649482758621, -1.514649969230769 ], [ 29.76138, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9496, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.514649969230769 ], [ 29.762188448275861, -1.515188923076923 ], [ 29.762457931034483, -1.515188923076923 ], [ 29.762457931034483, -1.514649969230769 ], [ 29.762188448275861, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9497, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.514649969230769 ], [ 29.762457931034483, -1.515188923076923 ], [ 29.762727413793105, -1.515188923076923 ], [ 29.762727413793105, -1.514649969230769 ], [ 29.762457931034483, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9498, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.514649969230769 ], [ 29.763266379310345, -1.514919446153846 ], [ 29.763535862068967, -1.514919446153846 ], [ 29.763535862068967, -1.514649969230769 ], [ 29.763266379310345, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9499, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.514649969230769 ], [ 29.763535862068967, -1.515188923076923 ], [ 29.763805344827588, -1.515188923076923 ], [ 29.763805344827588, -1.514649969230769 ], [ 29.763535862068967, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9500, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.514649969230769 ], [ 29.764074827586207, -1.514919446153846 ], [ 29.764344310344828, -1.514919446153846 ], [ 29.764344310344828, -1.514649969230769 ], [ 29.764074827586207, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9501, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.514649969230769 ], [ 29.764344310344828, -1.514919446153846 ], [ 29.76461379310345, -1.514919446153846 ], [ 29.76461379310345, -1.514649969230769 ], [ 29.764344310344828, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9502, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.514649969230769 ], [ 29.76461379310345, -1.514919446153846 ], [ 29.764883275862069, -1.514919446153846 ], [ 29.764883275862069, -1.514649969230769 ], [ 29.76461379310345, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9503, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.514649969230769 ], [ 29.764883275862069, -1.514919446153846 ], [ 29.76515275862069, -1.514919446153846 ], [ 29.76515275862069, -1.514649969230769 ], [ 29.764883275862069, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9504, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.514649969230769 ], [ 29.76515275862069, -1.514919446153846 ], [ 29.765422241379312, -1.514919446153846 ], [ 29.765422241379312, -1.514649969230769 ], [ 29.76515275862069, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9505, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.514649969230769 ], [ 29.765422241379312, -1.514919446153846 ], [ 29.765961206896552, -1.514919446153846 ], [ 29.765961206896552, -1.514649969230769 ], [ 29.765422241379312, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9506, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.514649969230769 ], [ 29.765961206896552, -1.514919446153846 ], [ 29.766230689655174, -1.514919446153846 ], [ 29.766230689655174, -1.514649969230769 ], [ 29.765961206896552, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9507, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.514649969230769 ], [ 29.766230689655174, -1.514919446153846 ], [ 29.766500172413792, -1.514919446153846 ], [ 29.766500172413792, -1.514649969230769 ], [ 29.766230689655174, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9508, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.514649969230769 ], [ 29.766500172413792, -1.514919446153846 ], [ 29.766769655172414, -1.514919446153846 ], [ 29.766769655172414, -1.514649969230769 ], [ 29.766500172413792, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9509, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.514649969230769 ], [ 29.766769655172414, -1.514919446153846 ], [ 29.767039137931036, -1.514919446153846 ], [ 29.767039137931036, -1.514649969230769 ], [ 29.766769655172414, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9510, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.514649969230769 ], [ 29.767039137931036, -1.514919446153846 ], [ 29.767308620689654, -1.514919446153846 ], [ 29.767308620689654, -1.514649969230769 ], [ 29.767039137931036, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9511, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.514649969230769 ], [ 29.767308620689654, -1.514919446153846 ], [ 29.767578103448276, -1.514919446153846 ], [ 29.767578103448276, -1.514649969230769 ], [ 29.767308620689654, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9512, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.514649969230769 ], [ 29.767578103448276, -1.514919446153846 ], [ 29.768117068965516, -1.514919446153846 ], [ 29.768117068965516, -1.514649969230769 ], [ 29.767578103448276, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9513, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.514649969230769 ], [ 29.768117068965516, -1.514919446153846 ], [ 29.768386551724138, -1.514919446153846 ], [ 29.768386551724138, -1.514649969230769 ], [ 29.768117068965516, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9514, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.514649969230769 ], [ 29.768386551724138, -1.514919446153846 ], [ 29.76865603448276, -1.514919446153846 ], [ 29.76865603448276, -1.514649969230769 ], [ 29.768386551724138, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9515, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.514919446153846 ], [ 29.712603620689656, -1.515188923076923 ], [ 29.712873103448278, -1.515188923076923 ], [ 29.712873103448278, -1.514919446153846 ], [ 29.712603620689656, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9516, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.514919446153846 ], [ 29.712873103448278, -1.515188923076923 ], [ 29.713412068965518, -1.515188923076923 ], [ 29.713412068965518, -1.514919446153846 ], [ 29.712873103448278, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9517, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71475948275862, -1.514380492307692 ], [ 29.715028965517241, -1.514380492307692 ], [ 29.715028965517241, -1.515188923076923 ], [ 29.713412068965518, -1.515188923076923 ], [ 29.713412068965518, -1.514919446153846 ], [ 29.71475948275862, -1.514919446153846 ], [ 29.71475948275862, -1.514380492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9518, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717723793103449, -1.514919446153846 ], [ 29.717723793103449, -1.5154584 ], [ 29.717993275862067, -1.5154584 ], [ 29.717993275862067, -1.514919446153846 ], [ 29.717723793103449, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9519, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.514919446153846 ], [ 29.718532241379311, -1.515188923076923 ], [ 29.718801724137933, -1.515188923076923 ], [ 29.718801724137933, -1.514919446153846 ], [ 29.718532241379311, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9520, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.514919446153846 ], [ 29.718801724137933, -1.515188923076923 ], [ 29.719071206896551, -1.515188923076923 ], [ 29.719071206896551, -1.514919446153846 ], [ 29.718801724137933, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9521, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.514919446153846 ], [ 29.719071206896551, -1.515188923076923 ], [ 29.719340689655173, -1.515188923076923 ], [ 29.719340689655173, -1.514919446153846 ], [ 29.719071206896551, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9522, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.514919446153846 ], [ 29.719340689655173, -1.515188923076923 ], [ 29.719610172413795, -1.515188923076923 ], [ 29.719610172413795, -1.514919446153846 ], [ 29.719340689655173, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9523, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.514919446153846 ], [ 29.719610172413795, -1.515188923076923 ], [ 29.719879655172413, -1.515188923076923 ], [ 29.719879655172413, -1.514919446153846 ], [ 29.719610172413795, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9524, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.514919446153846 ], [ 29.720149137931035, -1.5154584 ], [ 29.720688103448275, -1.5154584 ], [ 29.720688103448275, -1.514919446153846 ], [ 29.720149137931035, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9525, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.514919446153846 ], [ 29.720688103448275, -1.5154584 ], [ 29.720957586206897, -1.5154584 ], [ 29.720957586206897, -1.514919446153846 ], [ 29.720688103448275, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9526, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.514919446153846 ], [ 29.720957586206897, -1.515188923076923 ], [ 29.721227068965518, -1.515188923076923 ], [ 29.721227068965518, -1.514919446153846 ], [ 29.720957586206897, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9527, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.721227068965518, -1.514919446153846 ], [ 29.721227068965518, -1.515188923076923 ], [ 29.721496551724137, -1.515188923076923 ], [ 29.721496551724137, -1.514919446153846 ], [ 29.721227068965518, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9528, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72257448275862, -1.514919446153846 ], [ 29.72257448275862, -1.515188923076923 ], [ 29.722843965517242, -1.515188923076923 ], [ 29.722843965517242, -1.514919446153846 ], [ 29.72257448275862, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9529, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.514919446153846 ], [ 29.722843965517242, -1.515188923076923 ], [ 29.723113448275861, -1.515188923076923 ], [ 29.723113448275861, -1.514919446153846 ], [ 29.722843965517242, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9530, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.514919446153846 ], [ 29.723113448275861, -1.515188923076923 ], [ 29.723382931034482, -1.515188923076923 ], [ 29.723382931034482, -1.514919446153846 ], [ 29.723113448275861, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9531, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.514919446153846 ], [ 29.723382931034482, -1.515188923076923 ], [ 29.723652413793104, -1.515188923076923 ], [ 29.723652413793104, -1.514919446153846 ], [ 29.723382931034482, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9532, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.514919446153846 ], [ 29.723652413793104, -1.515188923076923 ], [ 29.723921896551722, -1.515188923076923 ], [ 29.723921896551722, -1.514919446153846 ], [ 29.723652413793104, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9533, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.514919446153846 ], [ 29.723921896551722, -1.515188923076923 ], [ 29.724460862068966, -1.515188923076923 ], [ 29.724460862068966, -1.514919446153846 ], [ 29.723921896551722, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9534, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.514919446153846 ], [ 29.724460862068966, -1.515188923076923 ], [ 29.724730344827588, -1.515188923076923 ], [ 29.724730344827588, -1.514919446153846 ], [ 29.724460862068966, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9535, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.514919446153846 ], [ 29.724730344827588, -1.515188923076923 ], [ 29.724999827586206, -1.515188923076923 ], [ 29.724999827586206, -1.514919446153846 ], [ 29.724730344827588, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9536, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.514919446153846 ], [ 29.724999827586206, -1.515188923076923 ], [ 29.725269310344828, -1.515188923076923 ], [ 29.725269310344828, -1.514919446153846 ], [ 29.724999827586206, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9537, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.514919446153846 ], [ 29.725269310344828, -1.515188923076923 ], [ 29.72553879310345, -1.515188923076923 ], [ 29.72553879310345, -1.514919446153846 ], [ 29.725269310344828, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9538, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.514919446153846 ], [ 29.72553879310345, -1.515188923076923 ], [ 29.725808275862068, -1.515188923076923 ], [ 29.725808275862068, -1.514919446153846 ], [ 29.72553879310345, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9539, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.514919446153846 ], [ 29.725808275862068, -1.515188923076923 ], [ 29.72607775862069, -1.515188923076923 ], [ 29.72607775862069, -1.514919446153846 ], [ 29.725808275862068, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9540, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.514919446153846 ], [ 29.72607775862069, -1.515188923076923 ], [ 29.726347241379312, -1.515188923076923 ], [ 29.726347241379312, -1.514919446153846 ], [ 29.72607775862069, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9541, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.514919446153846 ], [ 29.726347241379312, -1.515188923076923 ], [ 29.72661672413793, -1.515188923076923 ], [ 29.72661672413793, -1.514919446153846 ], [ 29.726347241379312, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9542, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.514919446153846 ], [ 29.72661672413793, -1.515188923076923 ], [ 29.726886206896552, -1.515188923076923 ], [ 29.726886206896552, -1.514919446153846 ], [ 29.72661672413793, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9543, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.514919446153846 ], [ 29.726886206896552, -1.515188923076923 ], [ 29.727155689655174, -1.515188923076923 ], [ 29.727155689655174, -1.514919446153846 ], [ 29.726886206896552, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9544, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.514919446153846 ], [ 29.727155689655174, -1.515188923076923 ], [ 29.727425172413792, -1.515188923076923 ], [ 29.727425172413792, -1.514919446153846 ], [ 29.727155689655174, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9545, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.514919446153846 ], [ 29.727425172413792, -1.515188923076923 ], [ 29.727694655172414, -1.515188923076923 ], [ 29.727694655172414, -1.514919446153846 ], [ 29.727425172413792, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9546, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.514919446153846 ], [ 29.727694655172414, -1.515188923076923 ], [ 29.727964137931036, -1.515188923076923 ], [ 29.727964137931036, -1.514919446153846 ], [ 29.727694655172414, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9547, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.514919446153846 ], [ 29.727964137931036, -1.515188923076923 ], [ 29.728233620689654, -1.515188923076923 ], [ 29.728233620689654, -1.514919446153846 ], [ 29.727964137931036, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9548, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.514919446153846 ], [ 29.728233620689654, -1.515188923076923 ], [ 29.728503103448276, -1.515188923076923 ], [ 29.728503103448276, -1.514919446153846 ], [ 29.728233620689654, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9549, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.514919446153846 ], [ 29.728503103448276, -1.515188923076923 ], [ 29.728772586206897, -1.515188923076923 ], [ 29.728772586206897, -1.514919446153846 ], [ 29.728503103448276, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9550, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.514919446153846 ], [ 29.728772586206897, -1.515188923076923 ], [ 29.729042068965516, -1.515188923076923 ], [ 29.729042068965516, -1.514919446153846 ], [ 29.728772586206897, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9551, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.514919446153846 ], [ 29.729042068965516, -1.515188923076923 ], [ 29.729311551724138, -1.515188923076923 ], [ 29.729311551724138, -1.514919446153846 ], [ 29.729042068965516, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9552, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.514919446153846 ], [ 29.729311551724138, -1.515188923076923 ], [ 29.729850517241381, -1.515188923076923 ], [ 29.729850517241381, -1.514919446153846 ], [ 29.729311551724138, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9553, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.514919446153846 ], [ 29.729850517241381, -1.515188923076923 ], [ 29.73012, -1.515188923076923 ], [ 29.73012, -1.514919446153846 ], [ 29.729850517241381, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9554, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.514919446153846 ], [ 29.73012, -1.515188923076923 ], [ 29.730389482758621, -1.515188923076923 ], [ 29.730389482758621, -1.514919446153846 ], [ 29.73012, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9555, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.514919446153846 ], [ 29.730389482758621, -1.515188923076923 ], [ 29.730658965517243, -1.515188923076923 ], [ 29.730658965517243, -1.514919446153846 ], [ 29.730389482758621, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9556, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.514649969230769 ], [ 29.731467413793105, -1.514649969230769 ], [ 29.731467413793105, -1.514919446153846 ], [ 29.731197931034483, -1.514919446153846 ], [ 29.731197931034483, -1.515188923076923 ], [ 29.730658965517243, -1.515188923076923 ], [ 29.730658965517243, -1.514919446153846 ], [ 29.730928448275861, -1.514919446153846 ], [ 29.730928448275861, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9557, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.514649969230769 ], [ 29.732814827586207, -1.514649969230769 ], [ 29.732814827586207, -1.514919446153846 ], [ 29.732006379310345, -1.514919446153846 ], [ 29.732006379310345, -1.515188923076923 ], [ 29.731197931034483, -1.515188923076923 ], [ 29.731197931034483, -1.514919446153846 ], [ 29.731467413793105, -1.514919446153846 ], [ 29.731467413793105, -1.514649969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9558, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.514919446153846 ], [ 29.732006379310345, -1.515188923076923 ], [ 29.732545344827585, -1.515188923076923 ], [ 29.732545344827585, -1.514919446153846 ], [ 29.732006379310345, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9559, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.514919446153846 ], [ 29.732545344827585, -1.515188923076923 ], [ 29.732814827586207, -1.515188923076923 ], [ 29.732814827586207, -1.514919446153846 ], [ 29.732545344827585, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9560, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.514919446153846 ], [ 29.732814827586207, -1.515188923076923 ], [ 29.733353793103447, -1.515188923076923 ], [ 29.733353793103447, -1.514919446153846 ], [ 29.732814827586207, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9561, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.514919446153846 ], [ 29.733353793103447, -1.515188923076923 ], [ 29.733623275862069, -1.515188923076923 ], [ 29.733623275862069, -1.514919446153846 ], [ 29.733353793103447, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9562, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.514919446153846 ], [ 29.733623275862069, -1.5154584 ], [ 29.733892758620691, -1.5154584 ], [ 29.733892758620691, -1.514919446153846 ], [ 29.733623275862069, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9563, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.514919446153846 ], [ 29.733892758620691, -1.515188923076923 ], [ 29.734162241379309, -1.515188923076923 ], [ 29.734162241379309, -1.514919446153846 ], [ 29.733892758620691, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9564, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.514919446153846 ], [ 29.734162241379309, -1.515188923076923 ], [ 29.734431724137931, -1.515188923076923 ], [ 29.734431724137931, -1.514919446153846 ], [ 29.734162241379309, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9565, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.514919446153846 ], [ 29.734431724137931, -1.515188923076923 ], [ 29.734701206896553, -1.515188923076923 ], [ 29.734701206896553, -1.514919446153846 ], [ 29.734431724137931, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9566, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.514919446153846 ], [ 29.734701206896553, -1.515188923076923 ], [ 29.734970689655171, -1.515188923076923 ], [ 29.734970689655171, -1.514919446153846 ], [ 29.734701206896553, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9567, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.514919446153846 ], [ 29.734970689655171, -1.515188923076923 ], [ 29.735240172413793, -1.515188923076923 ], [ 29.735240172413793, -1.514919446153846 ], [ 29.734970689655171, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9568, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.514919446153846 ], [ 29.736048620689655, -1.5154584 ], [ 29.736318103448276, -1.5154584 ], [ 29.736318103448276, -1.514919446153846 ], [ 29.736048620689655, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9569, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.514919446153846 ], [ 29.736857068965517, -1.515188923076923 ], [ 29.737126551724138, -1.515188923076923 ], [ 29.737126551724138, -1.514919446153846 ], [ 29.736857068965517, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9570, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.514919446153846 ], [ 29.737126551724138, -1.515188923076923 ], [ 29.73739603448276, -1.515188923076923 ], [ 29.73739603448276, -1.514919446153846 ], [ 29.737126551724138, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9571, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.514919446153846 ], [ 29.73739603448276, -1.515188923076923 ], [ 29.737665517241378, -1.515188923076923 ], [ 29.737665517241378, -1.514919446153846 ], [ 29.73739603448276, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9572, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.514919446153846 ], [ 29.737665517241378, -1.515188923076923 ], [ 29.737935, -1.515188923076923 ], [ 29.737935, -1.514919446153846 ], [ 29.737665517241378, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9573, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.514919446153846 ], [ 29.737935, -1.515188923076923 ], [ 29.738204482758622, -1.515188923076923 ], [ 29.738204482758622, -1.514919446153846 ], [ 29.737935, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9574, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.514919446153846 ], [ 29.738204482758622, -1.515188923076923 ], [ 29.738743448275862, -1.515188923076923 ], [ 29.738743448275862, -1.514919446153846 ], [ 29.738204482758622, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9575, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.514919446153846 ], [ 29.738743448275862, -1.515188923076923 ], [ 29.739012931034484, -1.515188923076923 ], [ 29.739012931034484, -1.514919446153846 ], [ 29.738743448275862, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9576, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.514919446153846 ], [ 29.739012931034484, -1.515188923076923 ], [ 29.739282413793102, -1.515188923076923 ], [ 29.739282413793102, -1.514919446153846 ], [ 29.739012931034484, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9577, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.514919446153846 ], [ 29.739282413793102, -1.515188923076923 ], [ 29.739551896551724, -1.515188923076923 ], [ 29.739551896551724, -1.514919446153846 ], [ 29.739282413793102, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9578, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.514919446153846 ], [ 29.739551896551724, -1.515188923076923 ], [ 29.739821379310346, -1.515188923076923 ], [ 29.739821379310346, -1.514919446153846 ], [ 29.739551896551724, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9579, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.514919446153846 ], [ 29.739821379310346, -1.515188923076923 ], [ 29.740090862068964, -1.515188923076923 ], [ 29.740090862068964, -1.514919446153846 ], [ 29.739821379310346, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9580, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.514919446153846 ], [ 29.740090862068964, -1.515188923076923 ], [ 29.740360344827586, -1.515188923076923 ], [ 29.740360344827586, -1.514919446153846 ], [ 29.740090862068964, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9581, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.514919446153846 ], [ 29.754642931034482, -1.515727876923077 ], [ 29.754912413793104, -1.515727876923077 ], [ 29.754912413793104, -1.515188923076923 ], [ 29.755181896551726, -1.515188923076923 ], [ 29.755181896551726, -1.514919446153846 ], [ 29.754642931034482, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9582, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.514919446153846 ], [ 29.755181896551726, -1.515188923076923 ], [ 29.755451379310344, -1.515188923076923 ], [ 29.755451379310344, -1.514919446153846 ], [ 29.755181896551726, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9583, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.514919446153846 ], [ 29.755451379310344, -1.515188923076923 ], [ 29.755720862068966, -1.515188923076923 ], [ 29.755720862068966, -1.514919446153846 ], [ 29.755451379310344, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9584, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.514919446153846 ], [ 29.755990344827588, -1.515188923076923 ], [ 29.756259827586206, -1.515188923076923 ], [ 29.756259827586206, -1.514919446153846 ], [ 29.755990344827588, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9585, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.514919446153846 ], [ 29.756259827586206, -1.515188923076923 ], [ 29.756798793103449, -1.515188923076923 ], [ 29.756798793103449, -1.514919446153846 ], [ 29.756259827586206, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9586, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.514919446153846 ], [ 29.756798793103449, -1.515188923076923 ], [ 29.757068275862068, -1.515188923076923 ], [ 29.757068275862068, -1.514919446153846 ], [ 29.756798793103449, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9587, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.514919446153846 ], [ 29.757607241379311, -1.5154584 ], [ 29.757876724137933, -1.5154584 ], [ 29.757876724137933, -1.514919446153846 ], [ 29.757607241379311, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9588, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.514919446153846 ], [ 29.758415689655173, -1.515727876923077 ], [ 29.758954655172413, -1.515727876923077 ], [ 29.758954655172413, -1.5154584 ], [ 29.758685172413795, -1.5154584 ], [ 29.758685172413795, -1.514919446153846 ], [ 29.758415689655173, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9589, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.514919446153846 ], [ 29.759493620689657, -1.515188923076923 ], [ 29.760032586206897, -1.515188923076923 ], [ 29.760032586206897, -1.514919446153846 ], [ 29.759493620689657, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9590, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.514919446153846 ], [ 29.760032586206897, -1.515188923076923 ], [ 29.760302068965519, -1.515188923076923 ], [ 29.760302068965519, -1.514919446153846 ], [ 29.760032586206897, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9591, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.514919446153846 ], [ 29.760302068965519, -1.515188923076923 ], [ 29.760571551724137, -1.515188923076923 ], [ 29.760571551724137, -1.514919446153846 ], [ 29.760302068965519, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9592, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.514919446153846 ], [ 29.760571551724137, -1.515188923076923 ], [ 29.760841034482759, -1.515188923076923 ], [ 29.760841034482759, -1.514919446153846 ], [ 29.760571551724137, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9593, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.514919446153846 ], [ 29.760841034482759, -1.515188923076923 ], [ 29.761110517241381, -1.515188923076923 ], [ 29.761110517241381, -1.514919446153846 ], [ 29.760841034482759, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9594, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.514919446153846 ], [ 29.761110517241381, -1.515188923076923 ], [ 29.76138, -1.515188923076923 ], [ 29.76138, -1.514919446153846 ], [ 29.761110517241381, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9595, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.514919446153846 ], [ 29.76138, -1.515188923076923 ], [ 29.761649482758621, -1.515188923076923 ], [ 29.761649482758621, -1.514919446153846 ], [ 29.76138, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9596, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.514919446153846 ], [ 29.761649482758621, -1.515188923076923 ], [ 29.761918965517243, -1.515188923076923 ], [ 29.761918965517243, -1.514919446153846 ], [ 29.761649482758621, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9597, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.514919446153846 ], [ 29.762727413793105, -1.515188923076923 ], [ 29.762996896551723, -1.515188923076923 ], [ 29.762996896551723, -1.514919446153846 ], [ 29.762727413793105, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9598, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.514919446153846 ], [ 29.762996896551723, -1.515188923076923 ], [ 29.763266379310345, -1.515188923076923 ], [ 29.763266379310345, -1.514919446153846 ], [ 29.762996896551723, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9599, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.514919446153846 ], [ 29.763266379310345, -1.5154584 ], [ 29.763535862068967, -1.5154584 ], [ 29.763535862068967, -1.514919446153846 ], [ 29.763266379310345, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9600, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.514919446153846 ], [ 29.763805344827588, -1.515188923076923 ], [ 29.764074827586207, -1.515188923076923 ], [ 29.764074827586207, -1.514919446153846 ], [ 29.763805344827588, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9601, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.514919446153846 ], [ 29.764074827586207, -1.515188923076923 ], [ 29.764344310344828, -1.515188923076923 ], [ 29.764344310344828, -1.514919446153846 ], [ 29.764074827586207, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9602, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.514919446153846 ], [ 29.764344310344828, -1.515188923076923 ], [ 29.76461379310345, -1.515188923076923 ], [ 29.76461379310345, -1.514919446153846 ], [ 29.764344310344828, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9603, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.514919446153846 ], [ 29.76461379310345, -1.515188923076923 ], [ 29.764883275862069, -1.515188923076923 ], [ 29.764883275862069, -1.514919446153846 ], [ 29.76461379310345, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9604, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.514919446153846 ], [ 29.764883275862069, -1.515188923076923 ], [ 29.76515275862069, -1.515188923076923 ], [ 29.76515275862069, -1.514919446153846 ], [ 29.764883275862069, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9605, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.514919446153846 ], [ 29.76515275862069, -1.515188923076923 ], [ 29.765422241379312, -1.515188923076923 ], [ 29.765422241379312, -1.514919446153846 ], [ 29.76515275862069, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9606, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.514919446153846 ], [ 29.765422241379312, -1.515188923076923 ], [ 29.765961206896552, -1.515188923076923 ], [ 29.765961206896552, -1.514919446153846 ], [ 29.765422241379312, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9607, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.514919446153846 ], [ 29.765961206896552, -1.515188923076923 ], [ 29.766230689655174, -1.515188923076923 ], [ 29.766230689655174, -1.514919446153846 ], [ 29.765961206896552, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9608, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.514919446153846 ], [ 29.766230689655174, -1.515188923076923 ], [ 29.766500172413792, -1.515188923076923 ], [ 29.766500172413792, -1.514919446153846 ], [ 29.766230689655174, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9609, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.514919446153846 ], [ 29.766500172413792, -1.515188923076923 ], [ 29.766769655172414, -1.515188923076923 ], [ 29.766769655172414, -1.514919446153846 ], [ 29.766500172413792, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9610, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.514919446153846 ], [ 29.766769655172414, -1.515188923076923 ], [ 29.767039137931036, -1.515188923076923 ], [ 29.767039137931036, -1.514919446153846 ], [ 29.766769655172414, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9611, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.514919446153846 ], [ 29.767039137931036, -1.515188923076923 ], [ 29.767308620689654, -1.515188923076923 ], [ 29.767308620689654, -1.514919446153846 ], [ 29.767039137931036, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9612, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.514919446153846 ], [ 29.767308620689654, -1.515188923076923 ], [ 29.767578103448276, -1.515188923076923 ], [ 29.767578103448276, -1.514919446153846 ], [ 29.767308620689654, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9613, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.514919446153846 ], [ 29.768386551724138, -1.5154584 ], [ 29.76865603448276, -1.5154584 ], [ 29.76865603448276, -1.514919446153846 ], [ 29.768386551724138, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9614, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.514919446153846 ], [ 29.76865603448276, -1.5154584 ], [ 29.768925517241382, -1.5154584 ], [ 29.768925517241382, -1.514919446153846 ], [ 29.76865603448276, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9615, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.515188923076923 ], [ 29.712873103448278, -1.5154584 ], [ 29.713412068965518, -1.5154584 ], [ 29.713412068965518, -1.515188923076923 ], [ 29.712873103448278, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9616, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713412068965518, -1.515188923076923 ], [ 29.713412068965518, -1.5154584 ], [ 29.713681551724139, -1.5154584 ], [ 29.713681551724139, -1.515188923076923 ], [ 29.713412068965518, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9617, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713681551724139, -1.515188923076923 ], [ 29.713681551724139, -1.5154584 ], [ 29.715298448275863, -1.5154584 ], [ 29.715298448275863, -1.515188923076923 ], [ 29.713681551724139, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9618, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.515188923076923 ], [ 29.717993275862067, -1.5154584 ], [ 29.718262758620689, -1.5154584 ], [ 29.718262758620689, -1.515188923076923 ], [ 29.717993275862067, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9619, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.515188923076923 ], [ 29.718262758620689, -1.5154584 ], [ 29.718532241379311, -1.5154584 ], [ 29.718532241379311, -1.515188923076923 ], [ 29.718262758620689, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9620, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.515188923076923 ], [ 29.718532241379311, -1.5154584 ], [ 29.718801724137933, -1.5154584 ], [ 29.718801724137933, -1.515188923076923 ], [ 29.718532241379311, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9621, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.515188923076923 ], [ 29.718801724137933, -1.5154584 ], [ 29.719071206896551, -1.5154584 ], [ 29.719071206896551, -1.515188923076923 ], [ 29.718801724137933, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9622, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.515188923076923 ], [ 29.719071206896551, -1.5154584 ], [ 29.719879655172413, -1.5154584 ], [ 29.719879655172413, -1.515188923076923 ], [ 29.719071206896551, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9623, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.515188923076923 ], [ 29.719879655172413, -1.5154584 ], [ 29.720149137931035, -1.5154584 ], [ 29.720149137931035, -1.515188923076923 ], [ 29.719879655172413, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9624, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720957586206897, -1.515188923076923 ], [ 29.720957586206897, -1.515727876923077 ], [ 29.721227068965518, -1.515727876923077 ], [ 29.721227068965518, -1.515188923076923 ], [ 29.720957586206897, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9625, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.722843965517242, -1.515188923076923 ], [ 29.722843965517242, -1.5154584 ], [ 29.723113448275861, -1.5154584 ], [ 29.723113448275861, -1.515188923076923 ], [ 29.722843965517242, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9626, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.515188923076923 ], [ 29.723113448275861, -1.5154584 ], [ 29.723382931034482, -1.5154584 ], [ 29.723382931034482, -1.515188923076923 ], [ 29.723113448275861, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9627, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.515188923076923 ], [ 29.723382931034482, -1.5154584 ], [ 29.723652413793104, -1.5154584 ], [ 29.723652413793104, -1.515188923076923 ], [ 29.723382931034482, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9628, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.515188923076923 ], [ 29.723652413793104, -1.5154584 ], [ 29.724191379310344, -1.5154584 ], [ 29.724191379310344, -1.515188923076923 ], [ 29.723652413793104, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9629, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.515188923076923 ], [ 29.724191379310344, -1.5154584 ], [ 29.724460862068966, -1.5154584 ], [ 29.724460862068966, -1.515188923076923 ], [ 29.724191379310344, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9630, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.515188923076923 ], [ 29.724460862068966, -1.5154584 ], [ 29.724730344827588, -1.5154584 ], [ 29.724730344827588, -1.515188923076923 ], [ 29.724460862068966, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9631, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.515188923076923 ], [ 29.724730344827588, -1.5154584 ], [ 29.724999827586206, -1.5154584 ], [ 29.724999827586206, -1.515188923076923 ], [ 29.724730344827588, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9632, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.515188923076923 ], [ 29.724999827586206, -1.5154584 ], [ 29.725269310344828, -1.5154584 ], [ 29.725269310344828, -1.515188923076923 ], [ 29.724999827586206, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9633, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.515188923076923 ], [ 29.725269310344828, -1.5154584 ], [ 29.72553879310345, -1.5154584 ], [ 29.72553879310345, -1.515188923076923 ], [ 29.725269310344828, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9634, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.515188923076923 ], [ 29.72553879310345, -1.5154584 ], [ 29.725808275862068, -1.5154584 ], [ 29.725808275862068, -1.515188923076923 ], [ 29.72553879310345, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9635, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.515188923076923 ], [ 29.725808275862068, -1.5154584 ], [ 29.72607775862069, -1.5154584 ], [ 29.72607775862069, -1.515188923076923 ], [ 29.725808275862068, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9636, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.515188923076923 ], [ 29.72607775862069, -1.5154584 ], [ 29.726347241379312, -1.5154584 ], [ 29.726347241379312, -1.515188923076923 ], [ 29.72607775862069, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9637, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.515188923076923 ], [ 29.726347241379312, -1.5154584 ], [ 29.72661672413793, -1.5154584 ], [ 29.72661672413793, -1.515188923076923 ], [ 29.726347241379312, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9638, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.515188923076923 ], [ 29.72661672413793, -1.5154584 ], [ 29.726886206896552, -1.5154584 ], [ 29.726886206896552, -1.515188923076923 ], [ 29.72661672413793, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9639, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.515188923076923 ], [ 29.726886206896552, -1.5154584 ], [ 29.727155689655174, -1.5154584 ], [ 29.727155689655174, -1.515188923076923 ], [ 29.726886206896552, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9640, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.515188923076923 ], [ 29.727155689655174, -1.5154584 ], [ 29.727425172413792, -1.5154584 ], [ 29.727425172413792, -1.515188923076923 ], [ 29.727155689655174, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9641, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.515188923076923 ], [ 29.727425172413792, -1.5154584 ], [ 29.727694655172414, -1.5154584 ], [ 29.727694655172414, -1.515188923076923 ], [ 29.727425172413792, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9642, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.515188923076923 ], [ 29.727694655172414, -1.5154584 ], [ 29.727964137931036, -1.5154584 ], [ 29.727964137931036, -1.515188923076923 ], [ 29.727694655172414, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9643, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.515188923076923 ], [ 29.727964137931036, -1.5154584 ], [ 29.728233620689654, -1.5154584 ], [ 29.728233620689654, -1.515188923076923 ], [ 29.727964137931036, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9644, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.515188923076923 ], [ 29.728233620689654, -1.5154584 ], [ 29.728503103448276, -1.5154584 ], [ 29.728503103448276, -1.515188923076923 ], [ 29.728233620689654, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9645, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.515188923076923 ], [ 29.728503103448276, -1.5154584 ], [ 29.728772586206897, -1.5154584 ], [ 29.728772586206897, -1.515188923076923 ], [ 29.728503103448276, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9646, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.515188923076923 ], [ 29.728772586206897, -1.5154584 ], [ 29.729042068965516, -1.5154584 ], [ 29.729042068965516, -1.515188923076923 ], [ 29.728772586206897, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9647, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.515188923076923 ], [ 29.729042068965516, -1.5154584 ], [ 29.729311551724138, -1.5154584 ], [ 29.729311551724138, -1.515188923076923 ], [ 29.729042068965516, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9648, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.515188923076923 ], [ 29.729311551724138, -1.5154584 ], [ 29.729850517241381, -1.5154584 ], [ 29.729850517241381, -1.515188923076923 ], [ 29.729311551724138, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9649, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.515188923076923 ], [ 29.729850517241381, -1.5154584 ], [ 29.73012, -1.5154584 ], [ 29.73012, -1.515188923076923 ], [ 29.729850517241381, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9650, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.515188923076923 ], [ 29.73012, -1.5154584 ], [ 29.730389482758621, -1.5154584 ], [ 29.730389482758621, -1.515188923076923 ], [ 29.73012, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9651, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.515188923076923 ], [ 29.730389482758621, -1.5154584 ], [ 29.730658965517243, -1.5154584 ], [ 29.730658965517243, -1.515188923076923 ], [ 29.730389482758621, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9652, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.515188923076923 ], [ 29.730658965517243, -1.5154584 ], [ 29.730928448275861, -1.5154584 ], [ 29.730928448275861, -1.515188923076923 ], [ 29.730658965517243, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9653, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.515188923076923 ], [ 29.730928448275861, -1.5154584 ], [ 29.732275862068967, -1.5154584 ], [ 29.732275862068967, -1.515188923076923 ], [ 29.730928448275861, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9654, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.515188923076923 ], [ 29.732275862068967, -1.5154584 ], [ 29.732545344827585, -1.5154584 ], [ 29.732545344827585, -1.515188923076923 ], [ 29.732275862068967, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9655, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.515188923076923 ], [ 29.732545344827585, -1.5154584 ], [ 29.732814827586207, -1.5154584 ], [ 29.732814827586207, -1.515188923076923 ], [ 29.732545344827585, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9656, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.515188923076923 ], [ 29.732814827586207, -1.5154584 ], [ 29.733084310344829, -1.5154584 ], [ 29.733084310344829, -1.515188923076923 ], [ 29.732814827586207, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9657, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.515188923076923 ], [ 29.733084310344829, -1.5154584 ], [ 29.733353793103447, -1.5154584 ], [ 29.733353793103447, -1.515188923076923 ], [ 29.733084310344829, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9658, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.515188923076923 ], [ 29.733353793103447, -1.5154584 ], [ 29.733623275862069, -1.5154584 ], [ 29.733623275862069, -1.515188923076923 ], [ 29.733353793103447, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9659, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.515188923076923 ], [ 29.733892758620691, -1.5154584 ], [ 29.734162241379309, -1.5154584 ], [ 29.734162241379309, -1.515188923076923 ], [ 29.733892758620691, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9660, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.515188923076923 ], [ 29.734162241379309, -1.5154584 ], [ 29.734431724137931, -1.5154584 ], [ 29.734431724137931, -1.515188923076923 ], [ 29.734162241379309, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9661, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.515188923076923 ], [ 29.734431724137931, -1.5154584 ], [ 29.734701206896553, -1.5154584 ], [ 29.734701206896553, -1.515188923076923 ], [ 29.734431724137931, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9662, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.515188923076923 ], [ 29.734701206896553, -1.5154584 ], [ 29.734970689655171, -1.5154584 ], [ 29.734970689655171, -1.515188923076923 ], [ 29.734701206896553, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9663, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.514919446153846 ], [ 29.735509655172415, -1.514919446153846 ], [ 29.735509655172415, -1.5154584 ], [ 29.734970689655171, -1.5154584 ], [ 29.734970689655171, -1.515188923076923 ], [ 29.735240172413793, -1.515188923076923 ], [ 29.735240172413793, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9664, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.515188923076923 ], [ 29.735509655172415, -1.5154584 ], [ 29.735779137931036, -1.5154584 ], [ 29.735779137931036, -1.515188923076923 ], [ 29.735509655172415, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9665, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.515188923076923 ], [ 29.735779137931036, -1.515727876923077 ], [ 29.736048620689655, -1.515727876923077 ], [ 29.736048620689655, -1.515188923076923 ], [ 29.735779137931036, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9666, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.515188923076923 ], [ 29.736318103448276, -1.5154584 ], [ 29.736587586206898, -1.5154584 ], [ 29.736587586206898, -1.515188923076923 ], [ 29.736318103448276, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9667, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.515188923076923 ], [ 29.736587586206898, -1.5154584 ], [ 29.736857068965517, -1.5154584 ], [ 29.736857068965517, -1.515188923076923 ], [ 29.736587586206898, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9668, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.515188923076923 ], [ 29.736857068965517, -1.5154584 ], [ 29.737126551724138, -1.5154584 ], [ 29.737126551724138, -1.515188923076923 ], [ 29.736857068965517, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9669, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.515188923076923 ], [ 29.737126551724138, -1.5154584 ], [ 29.73739603448276, -1.5154584 ], [ 29.73739603448276, -1.515188923076923 ], [ 29.737126551724138, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9670, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.515188923076923 ], [ 29.73739603448276, -1.5154584 ], [ 29.737665517241378, -1.5154584 ], [ 29.737665517241378, -1.515188923076923 ], [ 29.73739603448276, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9671, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.515188923076923 ], [ 29.737665517241378, -1.5154584 ], [ 29.737935, -1.5154584 ], [ 29.737935, -1.515188923076923 ], [ 29.737665517241378, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9672, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.515188923076923 ], [ 29.737935, -1.5154584 ], [ 29.738204482758622, -1.5154584 ], [ 29.738204482758622, -1.515188923076923 ], [ 29.737935, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9673, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.515188923076923 ], [ 29.738204482758622, -1.5154584 ], [ 29.738743448275862, -1.5154584 ], [ 29.738743448275862, -1.515188923076923 ], [ 29.738204482758622, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9674, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.515188923076923 ], [ 29.738743448275862, -1.5154584 ], [ 29.739012931034484, -1.5154584 ], [ 29.739012931034484, -1.515188923076923 ], [ 29.738743448275862, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9675, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.515188923076923 ], [ 29.739012931034484, -1.5154584 ], [ 29.739282413793102, -1.5154584 ], [ 29.739282413793102, -1.515188923076923 ], [ 29.739012931034484, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9676, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.515188923076923 ], [ 29.739282413793102, -1.5154584 ], [ 29.739551896551724, -1.5154584 ], [ 29.739551896551724, -1.515188923076923 ], [ 29.739282413793102, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9677, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.515188923076923 ], [ 29.739551896551724, -1.5154584 ], [ 29.739821379310346, -1.5154584 ], [ 29.739821379310346, -1.515188923076923 ], [ 29.739551896551724, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9678, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.515188923076923 ], [ 29.739821379310346, -1.5154584 ], [ 29.740090862068964, -1.5154584 ], [ 29.740090862068964, -1.515188923076923 ], [ 29.739821379310346, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9679, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.515188923076923 ], [ 29.740090862068964, -1.5154584 ], [ 29.740360344827586, -1.5154584 ], [ 29.740360344827586, -1.515188923076923 ], [ 29.740090862068964, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9680, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.515188923076923 ], [ 29.740360344827586, -1.5154584 ], [ 29.740629827586208, -1.5154584 ], [ 29.740629827586208, -1.515188923076923 ], [ 29.740360344827586, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9681, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.515188923076923 ], [ 29.754912413793104, -1.515727876923077 ], [ 29.755181896551726, -1.515727876923077 ], [ 29.755181896551726, -1.515188923076923 ], [ 29.754912413793104, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9682, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.515188923076923 ], [ 29.755181896551726, -1.5154584 ], [ 29.755451379310344, -1.5154584 ], [ 29.755451379310344, -1.515188923076923 ], [ 29.755181896551726, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9683, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.515188923076923 ], [ 29.755451379310344, -1.5154584 ], [ 29.755720862068966, -1.5154584 ], [ 29.755720862068966, -1.515188923076923 ], [ 29.755451379310344, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9684, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.515188923076923 ], [ 29.755720862068966, -1.5154584 ], [ 29.755990344827588, -1.5154584 ], [ 29.755990344827588, -1.515188923076923 ], [ 29.755720862068966, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9685, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.515188923076923 ], [ 29.755990344827588, -1.5154584 ], [ 29.756259827586206, -1.5154584 ], [ 29.756259827586206, -1.515188923076923 ], [ 29.755990344827588, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9686, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.515188923076923 ], [ 29.756259827586206, -1.5154584 ], [ 29.756798793103449, -1.5154584 ], [ 29.756798793103449, -1.515188923076923 ], [ 29.756259827586206, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9687, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.515188923076923 ], [ 29.756798793103449, -1.5154584 ], [ 29.757068275862068, -1.5154584 ], [ 29.757068275862068, -1.515188923076923 ], [ 29.756798793103449, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9688, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.515188923076923 ], [ 29.757068275862068, -1.5154584 ], [ 29.75733775862069, -1.5154584 ], [ 29.75733775862069, -1.515188923076923 ], [ 29.757068275862068, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9689, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.515188923076923 ], [ 29.75733775862069, -1.5154584 ], [ 29.757607241379311, -1.5154584 ], [ 29.757607241379311, -1.515188923076923 ], [ 29.75733775862069, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9690, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.515188923076923 ], [ 29.758146206896551, -1.515727876923077 ], [ 29.758415689655173, -1.515727876923077 ], [ 29.758415689655173, -1.515188923076923 ], [ 29.758146206896551, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9691, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.515188923076923 ], [ 29.758954655172413, -1.5154584 ], [ 29.759224137931035, -1.5154584 ], [ 29.759224137931035, -1.515188923076923 ], [ 29.758954655172413, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9692, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.515188923076923 ], [ 29.759224137931035, -1.5154584 ], [ 29.759763103448275, -1.5154584 ], [ 29.759763103448275, -1.515188923076923 ], [ 29.759224137931035, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9693, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.515188923076923 ], [ 29.759763103448275, -1.5154584 ], [ 29.760032586206897, -1.5154584 ], [ 29.760032586206897, -1.515188923076923 ], [ 29.759763103448275, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9694, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.515188923076923 ], [ 29.760032586206897, -1.5154584 ], [ 29.760302068965519, -1.5154584 ], [ 29.760302068965519, -1.515188923076923 ], [ 29.760032586206897, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9695, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.515188923076923 ], [ 29.760302068965519, -1.5154584 ], [ 29.760571551724137, -1.5154584 ], [ 29.760571551724137, -1.515188923076923 ], [ 29.760302068965519, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9696, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.515188923076923 ], [ 29.760571551724137, -1.5154584 ], [ 29.760841034482759, -1.5154584 ], [ 29.760841034482759, -1.515188923076923 ], [ 29.760571551724137, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9697, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.515188923076923 ], [ 29.760841034482759, -1.5154584 ], [ 29.761110517241381, -1.5154584 ], [ 29.761110517241381, -1.515188923076923 ], [ 29.760841034482759, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9698, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.515188923076923 ], [ 29.761110517241381, -1.5154584 ], [ 29.76138, -1.5154584 ], [ 29.76138, -1.515188923076923 ], [ 29.761110517241381, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9699, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.515188923076923 ], [ 29.76138, -1.5154584 ], [ 29.761649482758621, -1.5154584 ], [ 29.761649482758621, -1.515188923076923 ], [ 29.76138, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9700, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.514919446153846 ], [ 29.762188448275861, -1.514919446153846 ], [ 29.762188448275861, -1.515727876923077 ], [ 29.761918965517243, -1.515727876923077 ], [ 29.761918965517243, -1.5154584 ], [ 29.761649482758621, -1.5154584 ], [ 29.761649482758621, -1.515188923076923 ], [ 29.761918965517243, -1.515188923076923 ], [ 29.761918965517243, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9701, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.515188923076923 ], [ 29.762188448275861, -1.5154584 ], [ 29.762457931034483, -1.5154584 ], [ 29.762457931034483, -1.515188923076923 ], [ 29.762188448275861, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9702, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.515188923076923 ], [ 29.762457931034483, -1.5154584 ], [ 29.762727413793105, -1.5154584 ], [ 29.762727413793105, -1.515188923076923 ], [ 29.762457931034483, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9703, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.515188923076923 ], [ 29.762727413793105, -1.5154584 ], [ 29.762996896551723, -1.5154584 ], [ 29.762996896551723, -1.515188923076923 ], [ 29.762727413793105, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9704, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.515188923076923 ], [ 29.762996896551723, -1.515727876923077 ], [ 29.763266379310345, -1.515727876923077 ], [ 29.763266379310345, -1.515188923076923 ], [ 29.762996896551723, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9705, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.515188923076923 ], [ 29.763535862068967, -1.5154584 ], [ 29.763805344827588, -1.5154584 ], [ 29.763805344827588, -1.515188923076923 ], [ 29.763535862068967, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9706, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.515188923076923 ], [ 29.763805344827588, -1.5154584 ], [ 29.764074827586207, -1.5154584 ], [ 29.764074827586207, -1.515188923076923 ], [ 29.763805344827588, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9707, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.515188923076923 ], [ 29.764074827586207, -1.5154584 ], [ 29.764344310344828, -1.5154584 ], [ 29.764344310344828, -1.515188923076923 ], [ 29.764074827586207, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9708, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.515188923076923 ], [ 29.764344310344828, -1.5154584 ], [ 29.76461379310345, -1.5154584 ], [ 29.76461379310345, -1.515188923076923 ], [ 29.764344310344828, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9709, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.515188923076923 ], [ 29.76461379310345, -1.5154584 ], [ 29.764883275862069, -1.5154584 ], [ 29.764883275862069, -1.515188923076923 ], [ 29.76461379310345, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9710, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.515188923076923 ], [ 29.764883275862069, -1.5154584 ], [ 29.76515275862069, -1.5154584 ], [ 29.76515275862069, -1.515188923076923 ], [ 29.764883275862069, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9711, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.515188923076923 ], [ 29.76515275862069, -1.5154584 ], [ 29.765422241379312, -1.5154584 ], [ 29.765422241379312, -1.515188923076923 ], [ 29.76515275862069, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9712, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.515188923076923 ], [ 29.765422241379312, -1.5154584 ], [ 29.765961206896552, -1.5154584 ], [ 29.765961206896552, -1.515188923076923 ], [ 29.765422241379312, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9713, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.515188923076923 ], [ 29.765961206896552, -1.5154584 ], [ 29.766230689655174, -1.5154584 ], [ 29.766230689655174, -1.515188923076923 ], [ 29.765961206896552, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9714, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.515188923076923 ], [ 29.766230689655174, -1.5154584 ], [ 29.766500172413792, -1.5154584 ], [ 29.766500172413792, -1.515188923076923 ], [ 29.766230689655174, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9715, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.515188923076923 ], [ 29.766500172413792, -1.5154584 ], [ 29.766769655172414, -1.5154584 ], [ 29.766769655172414, -1.515188923076923 ], [ 29.766500172413792, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9716, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.515188923076923 ], [ 29.766769655172414, -1.5154584 ], [ 29.767039137931036, -1.5154584 ], [ 29.767039137931036, -1.515188923076923 ], [ 29.766769655172414, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9717, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.515188923076923 ], [ 29.767039137931036, -1.5154584 ], [ 29.767308620689654, -1.5154584 ], [ 29.767308620689654, -1.515188923076923 ], [ 29.767039137931036, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9718, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.515188923076923 ], [ 29.767308620689654, -1.5154584 ], [ 29.767847586206898, -1.5154584 ], [ 29.767847586206898, -1.515188923076923 ], [ 29.767308620689654, -1.515188923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9719, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711795172413794, -1.5154584 ], [ 29.711795172413794, -1.515727876923077 ], [ 29.712064655172412, -1.515727876923077 ], [ 29.712064655172412, -1.5154584 ], [ 29.711795172413794, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9720, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.5154584 ], [ 29.712873103448278, -1.515727876923077 ], [ 29.713142586206896, -1.515727876923077 ], [ 29.713142586206896, -1.5154584 ], [ 29.712873103448278, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9721, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.5154584 ], [ 29.713142586206896, -1.515727876923077 ], [ 29.713681551724139, -1.515727876923077 ], [ 29.713681551724139, -1.5154584 ], [ 29.713142586206896, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9722, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.717993275862067, -1.5154584 ], [ 29.717993275862067, -1.515727876923077 ], [ 29.718262758620689, -1.515727876923077 ], [ 29.718262758620689, -1.5154584 ], [ 29.717993275862067, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9723, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.5154584 ], [ 29.718262758620689, -1.515727876923077 ], [ 29.718532241379311, -1.515727876923077 ], [ 29.718532241379311, -1.5154584 ], [ 29.718262758620689, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9724, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.5154584 ], [ 29.718532241379311, -1.515727876923077 ], [ 29.718801724137933, -1.515727876923077 ], [ 29.718801724137933, -1.5154584 ], [ 29.718532241379311, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9725, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.5154584 ], [ 29.718801724137933, -1.515727876923077 ], [ 29.719340689655173, -1.515727876923077 ], [ 29.719340689655173, -1.5154584 ], [ 29.718801724137933, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9726, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.5154584 ], [ 29.719340689655173, -1.515727876923077 ], [ 29.719879655172413, -1.515727876923077 ], [ 29.719879655172413, -1.5154584 ], [ 29.719340689655173, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9727, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.5154584 ], [ 29.719879655172413, -1.515727876923077 ], [ 29.720149137931035, -1.515727876923077 ], [ 29.720149137931035, -1.5154584 ], [ 29.719879655172413, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9728, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.5154584 ], [ 29.720149137931035, -1.515727876923077 ], [ 29.720688103448275, -1.515727876923077 ], [ 29.720688103448275, -1.5154584 ], [ 29.720149137931035, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9729, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.5154584 ], [ 29.720688103448275, -1.515727876923077 ], [ 29.720957586206897, -1.515727876923077 ], [ 29.720957586206897, -1.5154584 ], [ 29.720688103448275, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9730, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723113448275861, -1.5154584 ], [ 29.723113448275861, -1.515727876923077 ], [ 29.723382931034482, -1.515727876923077 ], [ 29.723382931034482, -1.5154584 ], [ 29.723113448275861, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9731, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.5154584 ], [ 29.723382931034482, -1.515727876923077 ], [ 29.723652413793104, -1.515727876923077 ], [ 29.723652413793104, -1.5154584 ], [ 29.723382931034482, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9732, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723652413793104, -1.5154584 ], [ 29.723652413793104, -1.515727876923077 ], [ 29.724191379310344, -1.515727876923077 ], [ 29.724191379310344, -1.5154584 ], [ 29.723652413793104, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9733, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.5154584 ], [ 29.724191379310344, -1.515727876923077 ], [ 29.724460862068966, -1.515727876923077 ], [ 29.724460862068966, -1.5154584 ], [ 29.724191379310344, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9734, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.5154584 ], [ 29.724460862068966, -1.515727876923077 ], [ 29.724730344827588, -1.515727876923077 ], [ 29.724730344827588, -1.5154584 ], [ 29.724460862068966, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9735, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.5154584 ], [ 29.724730344827588, -1.515727876923077 ], [ 29.724999827586206, -1.515727876923077 ], [ 29.724999827586206, -1.5154584 ], [ 29.724730344827588, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9736, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.5154584 ], [ 29.724999827586206, -1.515727876923077 ], [ 29.725269310344828, -1.515727876923077 ], [ 29.725269310344828, -1.5154584 ], [ 29.724999827586206, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9737, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.5154584 ], [ 29.725269310344828, -1.515727876923077 ], [ 29.72553879310345, -1.515727876923077 ], [ 29.72553879310345, -1.5154584 ], [ 29.725269310344828, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9738, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.5154584 ], [ 29.72553879310345, -1.515727876923077 ], [ 29.725808275862068, -1.515727876923077 ], [ 29.725808275862068, -1.5154584 ], [ 29.72553879310345, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9739, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.5154584 ], [ 29.725808275862068, -1.515727876923077 ], [ 29.72607775862069, -1.515727876923077 ], [ 29.72607775862069, -1.5154584 ], [ 29.725808275862068, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9740, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.5154584 ], [ 29.72607775862069, -1.515727876923077 ], [ 29.726347241379312, -1.515727876923077 ], [ 29.726347241379312, -1.5154584 ], [ 29.72607775862069, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9741, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.5154584 ], [ 29.726347241379312, -1.515727876923077 ], [ 29.72661672413793, -1.515727876923077 ], [ 29.72661672413793, -1.5154584 ], [ 29.726347241379312, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9742, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.5154584 ], [ 29.72661672413793, -1.515727876923077 ], [ 29.726886206896552, -1.515727876923077 ], [ 29.726886206896552, -1.5154584 ], [ 29.72661672413793, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9743, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.5154584 ], [ 29.726886206896552, -1.515727876923077 ], [ 29.727155689655174, -1.515727876923077 ], [ 29.727155689655174, -1.5154584 ], [ 29.726886206896552, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9744, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.5154584 ], [ 29.727155689655174, -1.515727876923077 ], [ 29.727425172413792, -1.515727876923077 ], [ 29.727425172413792, -1.5154584 ], [ 29.727155689655174, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9745, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.5154584 ], [ 29.727425172413792, -1.515727876923077 ], [ 29.727694655172414, -1.515727876923077 ], [ 29.727694655172414, -1.5154584 ], [ 29.727425172413792, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9746, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.5154584 ], [ 29.727694655172414, -1.515727876923077 ], [ 29.728233620689654, -1.515727876923077 ], [ 29.728233620689654, -1.5154584 ], [ 29.727694655172414, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9747, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.5154584 ], [ 29.728233620689654, -1.515727876923077 ], [ 29.728503103448276, -1.515727876923077 ], [ 29.728503103448276, -1.5154584 ], [ 29.728233620689654, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9748, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.5154584 ], [ 29.728503103448276, -1.515727876923077 ], [ 29.728772586206897, -1.515727876923077 ], [ 29.728772586206897, -1.5154584 ], [ 29.728503103448276, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9749, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.5154584 ], [ 29.728772586206897, -1.515727876923077 ], [ 29.729042068965516, -1.515727876923077 ], [ 29.729042068965516, -1.5154584 ], [ 29.728772586206897, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9750, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.5154584 ], [ 29.729042068965516, -1.515727876923077 ], [ 29.729311551724138, -1.515727876923077 ], [ 29.729311551724138, -1.5154584 ], [ 29.729042068965516, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9751, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.5154584 ], [ 29.729311551724138, -1.515727876923077 ], [ 29.729850517241381, -1.515727876923077 ], [ 29.729850517241381, -1.5154584 ], [ 29.729311551724138, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9752, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.5154584 ], [ 29.729850517241381, -1.515727876923077 ], [ 29.73012, -1.515727876923077 ], [ 29.73012, -1.5154584 ], [ 29.729850517241381, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9753, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.5154584 ], [ 29.73012, -1.515727876923077 ], [ 29.730389482758621, -1.515727876923077 ], [ 29.730389482758621, -1.5154584 ], [ 29.73012, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9754, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.5154584 ], [ 29.730389482758621, -1.515727876923077 ], [ 29.730658965517243, -1.515727876923077 ], [ 29.730658965517243, -1.5154584 ], [ 29.730389482758621, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9755, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.5154584 ], [ 29.730658965517243, -1.515727876923077 ], [ 29.730928448275861, -1.515727876923077 ], [ 29.730928448275861, -1.5154584 ], [ 29.730658965517243, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9756, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.5154584 ], [ 29.730928448275861, -1.515727876923077 ], [ 29.732275862068967, -1.515727876923077 ], [ 29.732275862068967, -1.5154584 ], [ 29.730928448275861, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9757, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.5154584 ], [ 29.732275862068967, -1.515727876923077 ], [ 29.732545344827585, -1.515727876923077 ], [ 29.732545344827585, -1.5154584 ], [ 29.732275862068967, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9758, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.5154584 ], [ 29.732545344827585, -1.515727876923077 ], [ 29.732814827586207, -1.515727876923077 ], [ 29.732814827586207, -1.5154584 ], [ 29.732545344827585, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9759, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.5154584 ], [ 29.732814827586207, -1.515727876923077 ], [ 29.733084310344829, -1.515727876923077 ], [ 29.733084310344829, -1.5154584 ], [ 29.732814827586207, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9760, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.5154584 ], [ 29.733084310344829, -1.515727876923077 ], [ 29.733353793103447, -1.515727876923077 ], [ 29.733353793103447, -1.5154584 ], [ 29.733084310344829, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9761, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.5154584 ], [ 29.733353793103447, -1.515727876923077 ], [ 29.733623275862069, -1.515727876923077 ], [ 29.733623275862069, -1.5154584 ], [ 29.733353793103447, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9762, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.5154584 ], [ 29.733623275862069, -1.515727876923077 ], [ 29.733892758620691, -1.515727876923077 ], [ 29.733892758620691, -1.5154584 ], [ 29.733623275862069, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9763, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.5154584 ], [ 29.733892758620691, -1.515727876923077 ], [ 29.734162241379309, -1.515727876923077 ], [ 29.734162241379309, -1.5154584 ], [ 29.733892758620691, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9764, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.5154584 ], [ 29.734162241379309, -1.515727876923077 ], [ 29.734431724137931, -1.515727876923077 ], [ 29.734431724137931, -1.5154584 ], [ 29.734162241379309, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9765, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.5154584 ], [ 29.734431724137931, -1.515727876923077 ], [ 29.734701206896553, -1.515727876923077 ], [ 29.734701206896553, -1.5154584 ], [ 29.734431724137931, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9766, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.5154584 ], [ 29.734701206896553, -1.515727876923077 ], [ 29.734970689655171, -1.515727876923077 ], [ 29.734970689655171, -1.5154584 ], [ 29.734701206896553, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9767, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.5154584 ], [ 29.734970689655171, -1.515727876923077 ], [ 29.735240172413793, -1.515727876923077 ], [ 29.735240172413793, -1.5154584 ], [ 29.734970689655171, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9768, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.5154584 ], [ 29.736048620689655, -1.515727876923077 ], [ 29.736318103448276, -1.515727876923077 ], [ 29.736318103448276, -1.5154584 ], [ 29.736048620689655, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9769, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.5154584 ], [ 29.736318103448276, -1.515727876923077 ], [ 29.736587586206898, -1.515727876923077 ], [ 29.736587586206898, -1.5154584 ], [ 29.736318103448276, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9770, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.5154584 ], [ 29.736587586206898, -1.515727876923077 ], [ 29.736857068965517, -1.515727876923077 ], [ 29.736857068965517, -1.5154584 ], [ 29.736587586206898, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9771, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.5154584 ], [ 29.736857068965517, -1.515727876923077 ], [ 29.737126551724138, -1.515727876923077 ], [ 29.737126551724138, -1.5154584 ], [ 29.736857068965517, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9772, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.5154584 ], [ 29.737126551724138, -1.515727876923077 ], [ 29.73739603448276, -1.515727876923077 ], [ 29.73739603448276, -1.5154584 ], [ 29.737126551724138, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9773, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.5154584 ], [ 29.73739603448276, -1.515727876923077 ], [ 29.737665517241378, -1.515727876923077 ], [ 29.737665517241378, -1.5154584 ], [ 29.73739603448276, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9774, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.5154584 ], [ 29.737665517241378, -1.515727876923077 ], [ 29.737935, -1.515727876923077 ], [ 29.737935, -1.5154584 ], [ 29.737665517241378, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9775, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.5154584 ], [ 29.737935, -1.515727876923077 ], [ 29.738204482758622, -1.515727876923077 ], [ 29.738204482758622, -1.5154584 ], [ 29.737935, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9776, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.5154584 ], [ 29.738204482758622, -1.515727876923077 ], [ 29.738743448275862, -1.515727876923077 ], [ 29.738743448275862, -1.5154584 ], [ 29.738204482758622, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9777, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.5154584 ], [ 29.738743448275862, -1.515727876923077 ], [ 29.739012931034484, -1.515727876923077 ], [ 29.739012931034484, -1.5154584 ], [ 29.738743448275862, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9778, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.5154584 ], [ 29.739012931034484, -1.515727876923077 ], [ 29.739282413793102, -1.515727876923077 ], [ 29.739282413793102, -1.5154584 ], [ 29.739012931034484, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9779, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.5154584 ], [ 29.739282413793102, -1.515727876923077 ], [ 29.739551896551724, -1.515727876923077 ], [ 29.739551896551724, -1.5154584 ], [ 29.739282413793102, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9780, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.5154584 ], [ 29.739551896551724, -1.515727876923077 ], [ 29.739821379310346, -1.515727876923077 ], [ 29.739821379310346, -1.5154584 ], [ 29.739551896551724, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9781, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.5154584 ], [ 29.739821379310346, -1.515727876923077 ], [ 29.740090862068964, -1.515727876923077 ], [ 29.740090862068964, -1.5154584 ], [ 29.739821379310346, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9782, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.5154584 ], [ 29.740090862068964, -1.515727876923077 ], [ 29.740360344827586, -1.515727876923077 ], [ 29.740360344827586, -1.5154584 ], [ 29.740090862068964, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9783, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.5154584 ], [ 29.740360344827586, -1.515727876923077 ], [ 29.740629827586208, -1.515727876923077 ], [ 29.740629827586208, -1.5154584 ], [ 29.740360344827586, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9784, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.5154584 ], [ 29.740629827586208, -1.515727876923077 ], [ 29.741168793103448, -1.515727876923077 ], [ 29.741168793103448, -1.5154584 ], [ 29.740629827586208, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9785, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.5154584 ], [ 29.754373448275864, -1.515727876923077 ], [ 29.754642931034482, -1.515727876923077 ], [ 29.754642931034482, -1.5154584 ], [ 29.754373448275864, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9786, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.5154584 ], [ 29.755451379310344, -1.515727876923077 ], [ 29.755720862068966, -1.515727876923077 ], [ 29.755720862068966, -1.5154584 ], [ 29.755451379310344, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9787, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.5154584 ], [ 29.755720862068966, -1.515727876923077 ], [ 29.755990344827588, -1.515727876923077 ], [ 29.755990344827588, -1.5154584 ], [ 29.755720862068966, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9788, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.5154584 ], [ 29.755990344827588, -1.515727876923077 ], [ 29.756259827586206, -1.515727876923077 ], [ 29.756259827586206, -1.5154584 ], [ 29.755990344827588, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9789, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.5154584 ], [ 29.756259827586206, -1.515727876923077 ], [ 29.756798793103449, -1.515727876923077 ], [ 29.756798793103449, -1.5154584 ], [ 29.756259827586206, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9790, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.5154584 ], [ 29.756798793103449, -1.515727876923077 ], [ 29.757068275862068, -1.515727876923077 ], [ 29.757068275862068, -1.5154584 ], [ 29.756798793103449, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9791, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.5154584 ], [ 29.757068275862068, -1.515727876923077 ], [ 29.75733775862069, -1.515727876923077 ], [ 29.75733775862069, -1.5154584 ], [ 29.757068275862068, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9792, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.5154584 ], [ 29.75733775862069, -1.515727876923077 ], [ 29.757607241379311, -1.515727876923077 ], [ 29.757607241379311, -1.5154584 ], [ 29.75733775862069, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9793, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.5154584 ], [ 29.757607241379311, -1.515727876923077 ], [ 29.757876724137933, -1.515727876923077 ], [ 29.757876724137933, -1.5154584 ], [ 29.757607241379311, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9794, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.5154584 ], [ 29.757876724137933, -1.515727876923077 ], [ 29.758146206896551, -1.515727876923077 ], [ 29.758146206896551, -1.5154584 ], [ 29.757876724137933, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9795, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.5154584 ], [ 29.758954655172413, -1.515727876923077 ], [ 29.759493620689657, -1.515727876923077 ], [ 29.759493620689657, -1.5154584 ], [ 29.758954655172413, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9796, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.5154584 ], [ 29.759493620689657, -1.515727876923077 ], [ 29.759763103448275, -1.515727876923077 ], [ 29.759763103448275, -1.5154584 ], [ 29.759493620689657, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9797, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.5154584 ], [ 29.759763103448275, -1.515727876923077 ], [ 29.760032586206897, -1.515727876923077 ], [ 29.760032586206897, -1.5154584 ], [ 29.759763103448275, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9798, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.5154584 ], [ 29.760032586206897, -1.515727876923077 ], [ 29.760302068965519, -1.515727876923077 ], [ 29.760302068965519, -1.5154584 ], [ 29.760032586206897, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9799, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.5154584 ], [ 29.760302068965519, -1.515727876923077 ], [ 29.760571551724137, -1.515727876923077 ], [ 29.760571551724137, -1.5154584 ], [ 29.760302068965519, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9800, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.5154584 ], [ 29.760571551724137, -1.515727876923077 ], [ 29.760841034482759, -1.515727876923077 ], [ 29.760841034482759, -1.5154584 ], [ 29.760571551724137, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9801, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5154584 ], [ 29.760841034482759, -1.515727876923077 ], [ 29.761110517241381, -1.515727876923077 ], [ 29.761110517241381, -1.5154584 ], [ 29.760841034482759, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9802, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5154584 ], [ 29.761110517241381, -1.515727876923077 ], [ 29.76138, -1.515727876923077 ], [ 29.76138, -1.5154584 ], [ 29.761110517241381, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9803, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5154584 ], [ 29.76138, -1.515727876923077 ], [ 29.761649482758621, -1.515727876923077 ], [ 29.761649482758621, -1.5154584 ], [ 29.76138, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9804, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.5154584 ], [ 29.761649482758621, -1.515727876923077 ], [ 29.761918965517243, -1.515727876923077 ], [ 29.761918965517243, -1.5154584 ], [ 29.761649482758621, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9805, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5154584 ], [ 29.762188448275861, -1.515727876923077 ], [ 29.762457931034483, -1.515727876923077 ], [ 29.762457931034483, -1.5154584 ], [ 29.762188448275861, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9806, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5154584 ], [ 29.762457931034483, -1.515727876923077 ], [ 29.762727413793105, -1.515727876923077 ], [ 29.762727413793105, -1.5154584 ], [ 29.762457931034483, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9807, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5154584 ], [ 29.762727413793105, -1.515727876923077 ], [ 29.762996896551723, -1.515727876923077 ], [ 29.762996896551723, -1.5154584 ], [ 29.762727413793105, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9808, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5154584 ], [ 29.763266379310345, -1.515727876923077 ], [ 29.763535862068967, -1.515727876923077 ], [ 29.763535862068967, -1.5154584 ], [ 29.763266379310345, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9809, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5154584 ], [ 29.763535862068967, -1.515727876923077 ], [ 29.763805344827588, -1.515727876923077 ], [ 29.763805344827588, -1.5154584 ], [ 29.763535862068967, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9810, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5154584 ], [ 29.763805344827588, -1.515727876923077 ], [ 29.764074827586207, -1.515727876923077 ], [ 29.764074827586207, -1.5154584 ], [ 29.763805344827588, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9811, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5154584 ], [ 29.764074827586207, -1.515727876923077 ], [ 29.764344310344828, -1.515727876923077 ], [ 29.764344310344828, -1.5154584 ], [ 29.764074827586207, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9812, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.5154584 ], [ 29.764344310344828, -1.515727876923077 ], [ 29.76461379310345, -1.515727876923077 ], [ 29.76461379310345, -1.5154584 ], [ 29.764344310344828, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9813, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.5154584 ], [ 29.76461379310345, -1.515727876923077 ], [ 29.764883275862069, -1.515727876923077 ], [ 29.764883275862069, -1.5154584 ], [ 29.76461379310345, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9814, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.5154584 ], [ 29.764883275862069, -1.515727876923077 ], [ 29.76515275862069, -1.515727876923077 ], [ 29.76515275862069, -1.5154584 ], [ 29.764883275862069, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9815, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.5154584 ], [ 29.76515275862069, -1.515727876923077 ], [ 29.765422241379312, -1.515727876923077 ], [ 29.765422241379312, -1.5154584 ], [ 29.76515275862069, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9816, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.5154584 ], [ 29.765422241379312, -1.515727876923077 ], [ 29.765961206896552, -1.515727876923077 ], [ 29.765961206896552, -1.5154584 ], [ 29.765422241379312, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9817, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.5154584 ], [ 29.765961206896552, -1.515727876923077 ], [ 29.766230689655174, -1.515727876923077 ], [ 29.766230689655174, -1.5154584 ], [ 29.765961206896552, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9818, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.5154584 ], [ 29.766230689655174, -1.515727876923077 ], [ 29.766500172413792, -1.515727876923077 ], [ 29.766500172413792, -1.5154584 ], [ 29.766230689655174, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9819, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5154584 ], [ 29.766500172413792, -1.515727876923077 ], [ 29.766769655172414, -1.515727876923077 ], [ 29.766769655172414, -1.5154584 ], [ 29.766500172413792, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9820, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.5154584 ], [ 29.767039137931036, -1.515727876923077 ], [ 29.767308620689654, -1.515727876923077 ], [ 29.767308620689654, -1.5154584 ], [ 29.767039137931036, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9821, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.5154584 ], [ 29.767308620689654, -1.515727876923077 ], [ 29.767578103448276, -1.515727876923077 ], [ 29.767578103448276, -1.5154584 ], [ 29.767308620689654, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9822, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.5154584 ], [ 29.767578103448276, -1.515727876923077 ], [ 29.767847586206898, -1.515727876923077 ], [ 29.767847586206898, -1.5154584 ], [ 29.767578103448276, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9823, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.514919446153846 ], [ 29.767578103448276, -1.515188923076923 ], [ 29.767847586206898, -1.515188923076923 ], [ 29.767847586206898, -1.515997353846154 ], [ 29.768117068965516, -1.515997353846154 ], [ 29.768117068965516, -1.514919446153846 ], [ 29.767578103448276, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9824, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.514919446153846 ], [ 29.768117068965516, -1.515997353846154 ], [ 29.768386551724138, -1.515997353846154 ], [ 29.768386551724138, -1.514919446153846 ], [ 29.768117068965516, -1.514919446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9825, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.5154584 ], [ 29.735240172413793, -1.515727876923077 ], [ 29.735509655172415, -1.515727876923077 ], [ 29.735509655172415, -1.515997353846154 ], [ 29.735779137931036, -1.515997353846154 ], [ 29.735779137931036, -1.5154584 ], [ 29.735240172413793, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9826, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.5154584 ], [ 29.754103965517242, -1.517344738461538 ], [ 29.754373448275864, -1.517344738461538 ], [ 29.754373448275864, -1.5154584 ], [ 29.754103965517242, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9827, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.5154584 ], [ 29.755181896551726, -1.515997353846154 ], [ 29.755451379310344, -1.515997353846154 ], [ 29.755451379310344, -1.5154584 ], [ 29.755181896551726, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9828, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.5154584 ], [ 29.766769655172414, -1.515997353846154 ], [ 29.767039137931036, -1.515997353846154 ], [ 29.767039137931036, -1.5154584 ], [ 29.766769655172414, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9829, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.5154584 ], [ 29.768386551724138, -1.516266830769231 ], [ 29.76865603448276, -1.516266830769231 ], [ 29.76865603448276, -1.5154584 ], [ 29.768386551724138, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9830, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.515727876923077 ], [ 29.712334137931034, -1.515997353846154 ], [ 29.712603620689656, -1.515997353846154 ], [ 29.712603620689656, -1.515727876923077 ], [ 29.712334137931034, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9831, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.515727876923077 ], [ 29.712603620689656, -1.515997353846154 ], [ 29.713142586206896, -1.515997353846154 ], [ 29.713142586206896, -1.515727876923077 ], [ 29.712603620689656, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9832, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718262758620689, -1.515727876923077 ], [ 29.718262758620689, -1.515997353846154 ], [ 29.718532241379311, -1.515997353846154 ], [ 29.718532241379311, -1.515727876923077 ], [ 29.718262758620689, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9833, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.515727876923077 ], [ 29.718532241379311, -1.515997353846154 ], [ 29.718801724137933, -1.515997353846154 ], [ 29.718801724137933, -1.515727876923077 ], [ 29.718532241379311, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9834, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718801724137933, -1.515727876923077 ], [ 29.718801724137933, -1.515997353846154 ], [ 29.719340689655173, -1.515997353846154 ], [ 29.719340689655173, -1.515727876923077 ], [ 29.718801724137933, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9835, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.515727876923077 ], [ 29.719340689655173, -1.515997353846154 ], [ 29.719879655172413, -1.515997353846154 ], [ 29.719879655172413, -1.515727876923077 ], [ 29.719340689655173, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9836, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.515727876923077 ], [ 29.719879655172413, -1.515997353846154 ], [ 29.720149137931035, -1.515997353846154 ], [ 29.720149137931035, -1.515727876923077 ], [ 29.719879655172413, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9837, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.515727876923077 ], [ 29.720149137931035, -1.515997353846154 ], [ 29.720688103448275, -1.515997353846154 ], [ 29.720688103448275, -1.515727876923077 ], [ 29.720149137931035, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9838, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720688103448275, -1.515727876923077 ], [ 29.720688103448275, -1.515997353846154 ], [ 29.720957586206897, -1.515997353846154 ], [ 29.720957586206897, -1.515727876923077 ], [ 29.720688103448275, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9839, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723382931034482, -1.515727876923077 ], [ 29.723382931034482, -1.515997353846154 ], [ 29.723921896551722, -1.515997353846154 ], [ 29.723921896551722, -1.515727876923077 ], [ 29.723382931034482, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9840, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.515727876923077 ], [ 29.723921896551722, -1.515997353846154 ], [ 29.724191379310344, -1.515997353846154 ], [ 29.724191379310344, -1.515727876923077 ], [ 29.723921896551722, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9841, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.515727876923077 ], [ 29.724191379310344, -1.515997353846154 ], [ 29.724460862068966, -1.515997353846154 ], [ 29.724460862068966, -1.515727876923077 ], [ 29.724191379310344, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9842, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.515727876923077 ], [ 29.724460862068966, -1.515997353846154 ], [ 29.724730344827588, -1.515997353846154 ], [ 29.724730344827588, -1.515727876923077 ], [ 29.724460862068966, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9843, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.515727876923077 ], [ 29.724730344827588, -1.515997353846154 ], [ 29.724999827586206, -1.515997353846154 ], [ 29.724999827586206, -1.515727876923077 ], [ 29.724730344827588, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9844, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.515727876923077 ], [ 29.724999827586206, -1.515997353846154 ], [ 29.725269310344828, -1.515997353846154 ], [ 29.725269310344828, -1.515727876923077 ], [ 29.724999827586206, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9845, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.515727876923077 ], [ 29.725269310344828, -1.515997353846154 ], [ 29.72553879310345, -1.515997353846154 ], [ 29.72553879310345, -1.515727876923077 ], [ 29.725269310344828, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9846, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.515727876923077 ], [ 29.72553879310345, -1.515997353846154 ], [ 29.725808275862068, -1.515997353846154 ], [ 29.725808275862068, -1.515727876923077 ], [ 29.72553879310345, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9847, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.515727876923077 ], [ 29.725808275862068, -1.515997353846154 ], [ 29.72607775862069, -1.515997353846154 ], [ 29.72607775862069, -1.515727876923077 ], [ 29.725808275862068, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9848, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.515727876923077 ], [ 29.72607775862069, -1.515997353846154 ], [ 29.726347241379312, -1.515997353846154 ], [ 29.726347241379312, -1.515727876923077 ], [ 29.72607775862069, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9849, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.515727876923077 ], [ 29.726347241379312, -1.515997353846154 ], [ 29.72661672413793, -1.515997353846154 ], [ 29.72661672413793, -1.515727876923077 ], [ 29.726347241379312, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9850, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.515727876923077 ], [ 29.72661672413793, -1.515997353846154 ], [ 29.726886206896552, -1.515997353846154 ], [ 29.726886206896552, -1.515727876923077 ], [ 29.72661672413793, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9851, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.515727876923077 ], [ 29.726886206896552, -1.515997353846154 ], [ 29.727155689655174, -1.515997353846154 ], [ 29.727155689655174, -1.515727876923077 ], [ 29.726886206896552, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9852, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.515727876923077 ], [ 29.727155689655174, -1.515997353846154 ], [ 29.727425172413792, -1.515997353846154 ], [ 29.727425172413792, -1.515727876923077 ], [ 29.727155689655174, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9853, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.515727876923077 ], [ 29.727425172413792, -1.515997353846154 ], [ 29.727694655172414, -1.515997353846154 ], [ 29.727694655172414, -1.515727876923077 ], [ 29.727425172413792, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9854, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.515727876923077 ], [ 29.727694655172414, -1.515997353846154 ], [ 29.727964137931036, -1.515997353846154 ], [ 29.727964137931036, -1.515727876923077 ], [ 29.727694655172414, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9855, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.515727876923077 ], [ 29.727964137931036, -1.515997353846154 ], [ 29.728233620689654, -1.515997353846154 ], [ 29.728233620689654, -1.515727876923077 ], [ 29.727964137931036, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9856, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.515727876923077 ], [ 29.728233620689654, -1.515997353846154 ], [ 29.728503103448276, -1.515997353846154 ], [ 29.728503103448276, -1.515727876923077 ], [ 29.728233620689654, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9857, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.515727876923077 ], [ 29.728503103448276, -1.515997353846154 ], [ 29.728772586206897, -1.515997353846154 ], [ 29.728772586206897, -1.515727876923077 ], [ 29.728503103448276, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9858, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.515727876923077 ], [ 29.728772586206897, -1.515997353846154 ], [ 29.729042068965516, -1.515997353846154 ], [ 29.729042068965516, -1.515727876923077 ], [ 29.728772586206897, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9859, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.515727876923077 ], [ 29.729042068965516, -1.515997353846154 ], [ 29.729311551724138, -1.515997353846154 ], [ 29.729311551724138, -1.515727876923077 ], [ 29.729042068965516, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9860, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.515727876923077 ], [ 29.729311551724138, -1.515997353846154 ], [ 29.729850517241381, -1.515997353846154 ], [ 29.729850517241381, -1.515727876923077 ], [ 29.729311551724138, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9861, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.515727876923077 ], [ 29.729850517241381, -1.515997353846154 ], [ 29.73012, -1.515997353846154 ], [ 29.73012, -1.515727876923077 ], [ 29.729850517241381, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9862, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.515727876923077 ], [ 29.73012, -1.515997353846154 ], [ 29.730389482758621, -1.515997353846154 ], [ 29.730389482758621, -1.515727876923077 ], [ 29.73012, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9863, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.515727876923077 ], [ 29.730389482758621, -1.515997353846154 ], [ 29.730658965517243, -1.515997353846154 ], [ 29.730658965517243, -1.515727876923077 ], [ 29.730389482758621, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9864, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.515727876923077 ], [ 29.730658965517243, -1.515997353846154 ], [ 29.730928448275861, -1.515997353846154 ], [ 29.730928448275861, -1.515727876923077 ], [ 29.730658965517243, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9865, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.515727876923077 ], [ 29.730928448275861, -1.515997353846154 ], [ 29.731197931034483, -1.515997353846154 ], [ 29.731197931034483, -1.515727876923077 ], [ 29.730928448275861, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9866, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.515727876923077 ], [ 29.731197931034483, -1.515997353846154 ], [ 29.732006379310345, -1.515997353846154 ], [ 29.732006379310345, -1.515727876923077 ], [ 29.731197931034483, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9867, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.515727876923077 ], [ 29.732006379310345, -1.515997353846154 ], [ 29.732275862068967, -1.515997353846154 ], [ 29.732275862068967, -1.515727876923077 ], [ 29.732006379310345, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9868, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.515727876923077 ], [ 29.732275862068967, -1.515997353846154 ], [ 29.732545344827585, -1.515997353846154 ], [ 29.732545344827585, -1.515727876923077 ], [ 29.732275862068967, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9869, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.515727876923077 ], [ 29.732545344827585, -1.515997353846154 ], [ 29.732814827586207, -1.515997353846154 ], [ 29.732814827586207, -1.515727876923077 ], [ 29.732545344827585, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9870, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.515727876923077 ], [ 29.732814827586207, -1.515997353846154 ], [ 29.733084310344829, -1.515997353846154 ], [ 29.733084310344829, -1.515727876923077 ], [ 29.732814827586207, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9871, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.515727876923077 ], [ 29.733084310344829, -1.515997353846154 ], [ 29.733353793103447, -1.515997353846154 ], [ 29.733353793103447, -1.515727876923077 ], [ 29.733084310344829, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9872, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.515727876923077 ], [ 29.733353793103447, -1.515997353846154 ], [ 29.733623275862069, -1.515997353846154 ], [ 29.733623275862069, -1.515727876923077 ], [ 29.733353793103447, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9873, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.515727876923077 ], [ 29.733623275862069, -1.515997353846154 ], [ 29.733892758620691, -1.515997353846154 ], [ 29.733892758620691, -1.515727876923077 ], [ 29.733623275862069, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9874, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.515727876923077 ], [ 29.733892758620691, -1.515997353846154 ], [ 29.734162241379309, -1.515997353846154 ], [ 29.734162241379309, -1.515727876923077 ], [ 29.733892758620691, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9875, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.515727876923077 ], [ 29.734162241379309, -1.515997353846154 ], [ 29.734431724137931, -1.515997353846154 ], [ 29.734431724137931, -1.515727876923077 ], [ 29.734162241379309, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9876, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.515727876923077 ], [ 29.734431724137931, -1.515997353846154 ], [ 29.734701206896553, -1.515997353846154 ], [ 29.734701206896553, -1.515727876923077 ], [ 29.734431724137931, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9877, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.515727876923077 ], [ 29.734701206896553, -1.515997353846154 ], [ 29.734970689655171, -1.515997353846154 ], [ 29.734970689655171, -1.515727876923077 ], [ 29.734701206896553, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9878, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.515727876923077 ], [ 29.734970689655171, -1.515997353846154 ], [ 29.735240172413793, -1.515997353846154 ], [ 29.735240172413793, -1.515727876923077 ], [ 29.734970689655171, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9879, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.515727876923077 ], [ 29.735240172413793, -1.515997353846154 ], [ 29.735509655172415, -1.515997353846154 ], [ 29.735509655172415, -1.515727876923077 ], [ 29.735240172413793, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9880, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.515727876923077 ], [ 29.735779137931036, -1.515997353846154 ], [ 29.736048620689655, -1.515997353846154 ], [ 29.736048620689655, -1.515727876923077 ], [ 29.735779137931036, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9881, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.515727876923077 ], [ 29.736048620689655, -1.515997353846154 ], [ 29.736318103448276, -1.515997353846154 ], [ 29.736318103448276, -1.515727876923077 ], [ 29.736048620689655, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9882, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.515727876923077 ], [ 29.736318103448276, -1.515997353846154 ], [ 29.736587586206898, -1.515997353846154 ], [ 29.736587586206898, -1.515727876923077 ], [ 29.736318103448276, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9883, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.515727876923077 ], [ 29.736587586206898, -1.515997353846154 ], [ 29.736857068965517, -1.515997353846154 ], [ 29.736857068965517, -1.515727876923077 ], [ 29.736587586206898, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9884, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.515727876923077 ], [ 29.736857068965517, -1.515997353846154 ], [ 29.737126551724138, -1.515997353846154 ], [ 29.737126551724138, -1.515727876923077 ], [ 29.736857068965517, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9885, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.515727876923077 ], [ 29.737126551724138, -1.515997353846154 ], [ 29.73739603448276, -1.515997353846154 ], [ 29.73739603448276, -1.515727876923077 ], [ 29.737126551724138, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9886, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.515727876923077 ], [ 29.73739603448276, -1.515997353846154 ], [ 29.737665517241378, -1.515997353846154 ], [ 29.737665517241378, -1.515727876923077 ], [ 29.73739603448276, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9887, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.515727876923077 ], [ 29.737665517241378, -1.515997353846154 ], [ 29.737935, -1.515997353846154 ], [ 29.737935, -1.515727876923077 ], [ 29.737665517241378, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9888, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.515727876923077 ], [ 29.737935, -1.515997353846154 ], [ 29.738204482758622, -1.515997353846154 ], [ 29.738204482758622, -1.515727876923077 ], [ 29.737935, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9889, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.515727876923077 ], [ 29.738204482758622, -1.515997353846154 ], [ 29.738743448275862, -1.515997353846154 ], [ 29.738743448275862, -1.515727876923077 ], [ 29.738204482758622, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9890, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.515727876923077 ], [ 29.738743448275862, -1.515997353846154 ], [ 29.739012931034484, -1.515997353846154 ], [ 29.739012931034484, -1.515727876923077 ], [ 29.738743448275862, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9891, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.515727876923077 ], [ 29.739012931034484, -1.515997353846154 ], [ 29.739282413793102, -1.515997353846154 ], [ 29.739282413793102, -1.515727876923077 ], [ 29.739012931034484, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9892, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.515727876923077 ], [ 29.739282413793102, -1.515997353846154 ], [ 29.739551896551724, -1.515997353846154 ], [ 29.739551896551724, -1.515727876923077 ], [ 29.739282413793102, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9893, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.515727876923077 ], [ 29.739551896551724, -1.515997353846154 ], [ 29.739821379310346, -1.515997353846154 ], [ 29.739821379310346, -1.515727876923077 ], [ 29.739551896551724, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9894, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.515727876923077 ], [ 29.739821379310346, -1.515997353846154 ], [ 29.740090862068964, -1.515997353846154 ], [ 29.740090862068964, -1.515727876923077 ], [ 29.739821379310346, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9895, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.515727876923077 ], [ 29.740090862068964, -1.515997353846154 ], [ 29.740360344827586, -1.515997353846154 ], [ 29.740360344827586, -1.515727876923077 ], [ 29.740090862068964, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9896, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.515727876923077 ], [ 29.740360344827586, -1.515997353846154 ], [ 29.74089931034483, -1.515997353846154 ], [ 29.74089931034483, -1.515727876923077 ], [ 29.740360344827586, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9897, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.515727876923077 ], [ 29.74089931034483, -1.515997353846154 ], [ 29.741168793103448, -1.515997353846154 ], [ 29.741168793103448, -1.515727876923077 ], [ 29.74089931034483, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9898, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.515727876923077 ], [ 29.741168793103448, -1.515997353846154 ], [ 29.742246724137932, -1.515997353846154 ], [ 29.742246724137932, -1.515727876923077 ], [ 29.741168793103448, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9899, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.515727876923077 ], [ 29.754373448275864, -1.517075261538462 ], [ 29.754642931034482, -1.517075261538462 ], [ 29.754642931034482, -1.515727876923077 ], [ 29.754373448275864, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9900, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.515727876923077 ], [ 29.754642931034482, -1.517075261538462 ], [ 29.754912413793104, -1.517075261538462 ], [ 29.754912413793104, -1.515727876923077 ], [ 29.754642931034482, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9901, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.515727876923077 ], [ 29.754912413793104, -1.515997353846154 ], [ 29.755181896551726, -1.515997353846154 ], [ 29.755181896551726, -1.515727876923077 ], [ 29.754912413793104, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9902, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.515727876923077 ], [ 29.755451379310344, -1.515997353846154 ], [ 29.755720862068966, -1.515997353846154 ], [ 29.755720862068966, -1.515727876923077 ], [ 29.755451379310344, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9903, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.515727876923077 ], [ 29.755720862068966, -1.515997353846154 ], [ 29.755990344827588, -1.515997353846154 ], [ 29.755990344827588, -1.515727876923077 ], [ 29.755720862068966, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9904, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.515727876923077 ], [ 29.755990344827588, -1.515997353846154 ], [ 29.756259827586206, -1.515997353846154 ], [ 29.756259827586206, -1.515727876923077 ], [ 29.755990344827588, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9905, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.515727876923077 ], [ 29.756259827586206, -1.515997353846154 ], [ 29.756798793103449, -1.515997353846154 ], [ 29.756798793103449, -1.515727876923077 ], [ 29.756259827586206, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9906, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.515727876923077 ], [ 29.756798793103449, -1.515997353846154 ], [ 29.757068275862068, -1.515997353846154 ], [ 29.757068275862068, -1.515727876923077 ], [ 29.756798793103449, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9907, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.515727876923077 ], [ 29.757068275862068, -1.515997353846154 ], [ 29.75733775862069, -1.515997353846154 ], [ 29.75733775862069, -1.515727876923077 ], [ 29.757068275862068, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9908, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.515727876923077 ], [ 29.75733775862069, -1.516266830769231 ], [ 29.757607241379311, -1.516266830769231 ], [ 29.757607241379311, -1.515727876923077 ], [ 29.75733775862069, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9909, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.515727876923077 ], [ 29.757607241379311, -1.515997353846154 ], [ 29.757876724137933, -1.515997353846154 ], [ 29.757876724137933, -1.515727876923077 ], [ 29.757607241379311, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9910, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.515727876923077 ], [ 29.757876724137933, -1.515997353846154 ], [ 29.758685172413795, -1.515997353846154 ], [ 29.758685172413795, -1.515727876923077 ], [ 29.757876724137933, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9911, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.515727876923077 ], [ 29.758685172413795, -1.515997353846154 ], [ 29.759224137931035, -1.515997353846154 ], [ 29.759224137931035, -1.515727876923077 ], [ 29.758685172413795, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9912, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.515727876923077 ], [ 29.759224137931035, -1.515997353846154 ], [ 29.759493620689657, -1.515997353846154 ], [ 29.759493620689657, -1.515727876923077 ], [ 29.759224137931035, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9913, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.515727876923077 ], [ 29.759493620689657, -1.515997353846154 ], [ 29.759763103448275, -1.515997353846154 ], [ 29.759763103448275, -1.515727876923077 ], [ 29.759493620689657, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9914, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.515727876923077 ], [ 29.759763103448275, -1.515997353846154 ], [ 29.760032586206897, -1.515997353846154 ], [ 29.760032586206897, -1.515727876923077 ], [ 29.759763103448275, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9915, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.515727876923077 ], [ 29.760032586206897, -1.515997353846154 ], [ 29.760302068965519, -1.515997353846154 ], [ 29.760302068965519, -1.515727876923077 ], [ 29.760032586206897, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9916, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.515727876923077 ], [ 29.760302068965519, -1.515997353846154 ], [ 29.760571551724137, -1.515997353846154 ], [ 29.760571551724137, -1.515727876923077 ], [ 29.760302068965519, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9917, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.515727876923077 ], [ 29.760571551724137, -1.515997353846154 ], [ 29.760841034482759, -1.515997353846154 ], [ 29.760841034482759, -1.515727876923077 ], [ 29.760571551724137, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9918, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.515727876923077 ], [ 29.760841034482759, -1.515997353846154 ], [ 29.761110517241381, -1.515997353846154 ], [ 29.761110517241381, -1.515727876923077 ], [ 29.760841034482759, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9919, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.515727876923077 ], [ 29.761110517241381, -1.515997353846154 ], [ 29.76138, -1.515997353846154 ], [ 29.76138, -1.515727876923077 ], [ 29.761110517241381, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9920, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.515727876923077 ], [ 29.76138, -1.515997353846154 ], [ 29.761649482758621, -1.515997353846154 ], [ 29.761649482758621, -1.515727876923077 ], [ 29.76138, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9921, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.515727876923077 ], [ 29.761649482758621, -1.515997353846154 ], [ 29.761918965517243, -1.515997353846154 ], [ 29.761918965517243, -1.515727876923077 ], [ 29.761649482758621, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9922, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.515727876923077 ], [ 29.761918965517243, -1.515997353846154 ], [ 29.762188448275861, -1.515997353846154 ], [ 29.762188448275861, -1.515727876923077 ], [ 29.761918965517243, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9923, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.515727876923077 ], [ 29.762188448275861, -1.515997353846154 ], [ 29.762457931034483, -1.515997353846154 ], [ 29.762457931034483, -1.515727876923077 ], [ 29.762188448275861, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9924, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.515727876923077 ], [ 29.762457931034483, -1.515997353846154 ], [ 29.762727413793105, -1.515997353846154 ], [ 29.762727413793105, -1.515727876923077 ], [ 29.762457931034483, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9925, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.515727876923077 ], [ 29.762727413793105, -1.515997353846154 ], [ 29.762996896551723, -1.515997353846154 ], [ 29.762996896551723, -1.515727876923077 ], [ 29.762727413793105, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9926, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.515727876923077 ], [ 29.762996896551723, -1.515997353846154 ], [ 29.763266379310345, -1.515997353846154 ], [ 29.763266379310345, -1.515727876923077 ], [ 29.762996896551723, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9927, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.515727876923077 ], [ 29.763266379310345, -1.515997353846154 ], [ 29.763535862068967, -1.515997353846154 ], [ 29.763535862068967, -1.515727876923077 ], [ 29.763266379310345, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9928, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.515727876923077 ], [ 29.763535862068967, -1.515997353846154 ], [ 29.763805344827588, -1.515997353846154 ], [ 29.763805344827588, -1.515727876923077 ], [ 29.763535862068967, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9929, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.515727876923077 ], [ 29.763805344827588, -1.515997353846154 ], [ 29.764074827586207, -1.515997353846154 ], [ 29.764074827586207, -1.515727876923077 ], [ 29.763805344827588, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9930, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.515727876923077 ], [ 29.764074827586207, -1.515997353846154 ], [ 29.764344310344828, -1.515997353846154 ], [ 29.764344310344828, -1.515727876923077 ], [ 29.764074827586207, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9931, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.515727876923077 ], [ 29.764344310344828, -1.515997353846154 ], [ 29.76461379310345, -1.515997353846154 ], [ 29.76461379310345, -1.515727876923077 ], [ 29.764344310344828, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9932, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.515727876923077 ], [ 29.76461379310345, -1.515997353846154 ], [ 29.764883275862069, -1.515997353846154 ], [ 29.764883275862069, -1.515727876923077 ], [ 29.76461379310345, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9933, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.515727876923077 ], [ 29.764883275862069, -1.515997353846154 ], [ 29.76515275862069, -1.515997353846154 ], [ 29.76515275862069, -1.515727876923077 ], [ 29.764883275862069, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9934, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.515727876923077 ], [ 29.76515275862069, -1.516266830769231 ], [ 29.765422241379312, -1.516266830769231 ], [ 29.765422241379312, -1.515727876923077 ], [ 29.76515275862069, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9935, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.515727876923077 ], [ 29.765422241379312, -1.516266830769231 ], [ 29.765961206896552, -1.516266830769231 ], [ 29.765961206896552, -1.515727876923077 ], [ 29.765422241379312, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9936, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.515727876923077 ], [ 29.765961206896552, -1.516266830769231 ], [ 29.766230689655174, -1.516266830769231 ], [ 29.766230689655174, -1.515727876923077 ], [ 29.765961206896552, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9937, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.515727876923077 ], [ 29.766230689655174, -1.516266830769231 ], [ 29.766500172413792, -1.516266830769231 ], [ 29.766500172413792, -1.515727876923077 ], [ 29.766230689655174, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9938, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.515727876923077 ], [ 29.766500172413792, -1.515997353846154 ], [ 29.766769655172414, -1.515997353846154 ], [ 29.766769655172414, -1.515727876923077 ], [ 29.766500172413792, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9939, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.515727876923077 ], [ 29.767039137931036, -1.515997353846154 ], [ 29.767308620689654, -1.515997353846154 ], [ 29.767308620689654, -1.515727876923077 ], [ 29.767039137931036, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9940, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.515727876923077 ], [ 29.767308620689654, -1.516266830769231 ], [ 29.767578103448276, -1.516266830769231 ], [ 29.767578103448276, -1.515727876923077 ], [ 29.767308620689654, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9941, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.515727876923077 ], [ 29.767578103448276, -1.515997353846154 ], [ 29.767847586206898, -1.515997353846154 ], [ 29.767847586206898, -1.515727876923077 ], [ 29.767578103448276, -1.515727876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9942, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.515997353846154 ], [ 29.712334137931034, -1.516266830769231 ], [ 29.712603620689656, -1.516266830769231 ], [ 29.712603620689656, -1.515997353846154 ], [ 29.712334137931034, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9943, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.718532241379311, -1.515997353846154 ], [ 29.718532241379311, -1.516266830769231 ], [ 29.719071206896551, -1.516266830769231 ], [ 29.719071206896551, -1.515997353846154 ], [ 29.718532241379311, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9944, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.515997353846154 ], [ 29.719071206896551, -1.516266830769231 ], [ 29.719340689655173, -1.516266830769231 ], [ 29.719340689655173, -1.515997353846154 ], [ 29.719071206896551, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9945, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719340689655173, -1.515997353846154 ], [ 29.719340689655173, -1.516266830769231 ], [ 29.719879655172413, -1.516266830769231 ], [ 29.719879655172413, -1.515997353846154 ], [ 29.719340689655173, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9946, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.515997353846154 ], [ 29.719879655172413, -1.516266830769231 ], [ 29.720149137931035, -1.516266830769231 ], [ 29.720149137931035, -1.515997353846154 ], [ 29.719879655172413, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9947, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.720149137931035, -1.515997353846154 ], [ 29.720149137931035, -1.516266830769231 ], [ 29.720688103448275, -1.516266830769231 ], [ 29.720688103448275, -1.515997353846154 ], [ 29.720149137931035, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9948, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.723921896551722, -1.515997353846154 ], [ 29.723921896551722, -1.516266830769231 ], [ 29.724191379310344, -1.516266830769231 ], [ 29.724191379310344, -1.515997353846154 ], [ 29.723921896551722, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9949, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.515997353846154 ], [ 29.724191379310344, -1.516266830769231 ], [ 29.724460862068966, -1.516266830769231 ], [ 29.724460862068966, -1.515997353846154 ], [ 29.724191379310344, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9950, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.515997353846154 ], [ 29.724460862068966, -1.516266830769231 ], [ 29.724730344827588, -1.516266830769231 ], [ 29.724730344827588, -1.515997353846154 ], [ 29.724460862068966, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9951, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.515997353846154 ], [ 29.724730344827588, -1.516266830769231 ], [ 29.724999827586206, -1.516266830769231 ], [ 29.724999827586206, -1.515997353846154 ], [ 29.724730344827588, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9952, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.515997353846154 ], [ 29.724999827586206, -1.516266830769231 ], [ 29.725269310344828, -1.516266830769231 ], [ 29.725269310344828, -1.515997353846154 ], [ 29.724999827586206, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9953, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.515997353846154 ], [ 29.725269310344828, -1.516266830769231 ], [ 29.72553879310345, -1.516266830769231 ], [ 29.72553879310345, -1.515997353846154 ], [ 29.725269310344828, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9954, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.515997353846154 ], [ 29.72553879310345, -1.516266830769231 ], [ 29.725808275862068, -1.516266830769231 ], [ 29.725808275862068, -1.515997353846154 ], [ 29.72553879310345, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9955, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.515997353846154 ], [ 29.725808275862068, -1.516266830769231 ], [ 29.72607775862069, -1.516266830769231 ], [ 29.72607775862069, -1.515997353846154 ], [ 29.725808275862068, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9956, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.515997353846154 ], [ 29.72607775862069, -1.516266830769231 ], [ 29.726347241379312, -1.516266830769231 ], [ 29.726347241379312, -1.515997353846154 ], [ 29.72607775862069, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9957, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.515997353846154 ], [ 29.726347241379312, -1.516266830769231 ], [ 29.72661672413793, -1.516266830769231 ], [ 29.72661672413793, -1.515997353846154 ], [ 29.726347241379312, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9958, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.515997353846154 ], [ 29.72661672413793, -1.516266830769231 ], [ 29.726886206896552, -1.516266830769231 ], [ 29.726886206896552, -1.515997353846154 ], [ 29.72661672413793, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9959, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.515997353846154 ], [ 29.726886206896552, -1.516266830769231 ], [ 29.727155689655174, -1.516266830769231 ], [ 29.727155689655174, -1.515997353846154 ], [ 29.726886206896552, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9960, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.515997353846154 ], [ 29.727155689655174, -1.516536307692308 ], [ 29.727425172413792, -1.516536307692308 ], [ 29.727425172413792, -1.515997353846154 ], [ 29.727155689655174, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9961, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727425172413792, -1.515997353846154 ], [ 29.727425172413792, -1.516536307692308 ], [ 29.727694655172414, -1.516536307692308 ], [ 29.727694655172414, -1.515997353846154 ], [ 29.727425172413792, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9962, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.515997353846154 ], [ 29.727694655172414, -1.516266830769231 ], [ 29.727964137931036, -1.516266830769231 ], [ 29.727964137931036, -1.515997353846154 ], [ 29.727694655172414, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9963, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.515997353846154 ], [ 29.727964137931036, -1.516266830769231 ], [ 29.728233620689654, -1.516266830769231 ], [ 29.728233620689654, -1.515997353846154 ], [ 29.727964137931036, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9964, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.515997353846154 ], [ 29.728233620689654, -1.516266830769231 ], [ 29.728503103448276, -1.516266830769231 ], [ 29.728503103448276, -1.515997353846154 ], [ 29.728233620689654, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9965, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.515997353846154 ], [ 29.728503103448276, -1.516266830769231 ], [ 29.728772586206897, -1.516266830769231 ], [ 29.728772586206897, -1.515997353846154 ], [ 29.728503103448276, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9966, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.515997353846154 ], [ 29.728772586206897, -1.516266830769231 ], [ 29.729042068965516, -1.516266830769231 ], [ 29.729042068965516, -1.515997353846154 ], [ 29.728772586206897, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9967, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.515997353846154 ], [ 29.729042068965516, -1.516266830769231 ], [ 29.729311551724138, -1.516266830769231 ], [ 29.729311551724138, -1.515997353846154 ], [ 29.729042068965516, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9968, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.515997353846154 ], [ 29.729311551724138, -1.516266830769231 ], [ 29.729850517241381, -1.516266830769231 ], [ 29.729850517241381, -1.515997353846154 ], [ 29.729311551724138, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9969, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.515997353846154 ], [ 29.729850517241381, -1.516266830769231 ], [ 29.73012, -1.516266830769231 ], [ 29.73012, -1.515997353846154 ], [ 29.729850517241381, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9970, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.515997353846154 ], [ 29.73012, -1.516266830769231 ], [ 29.730389482758621, -1.516266830769231 ], [ 29.730389482758621, -1.515997353846154 ], [ 29.73012, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9971, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.515997353846154 ], [ 29.730389482758621, -1.516266830769231 ], [ 29.730658965517243, -1.516266830769231 ], [ 29.730658965517243, -1.515997353846154 ], [ 29.730389482758621, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9972, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.515997353846154 ], [ 29.730658965517243, -1.516266830769231 ], [ 29.730928448275861, -1.516266830769231 ], [ 29.730928448275861, -1.515997353846154 ], [ 29.730658965517243, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9973, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.515997353846154 ], [ 29.730928448275861, -1.516266830769231 ], [ 29.731197931034483, -1.516266830769231 ], [ 29.731197931034483, -1.515997353846154 ], [ 29.730928448275861, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9974, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.515997353846154 ], [ 29.731197931034483, -1.516266830769231 ], [ 29.732006379310345, -1.516266830769231 ], [ 29.732006379310345, -1.515997353846154 ], [ 29.731197931034483, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9975, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.515997353846154 ], [ 29.732006379310345, -1.516266830769231 ], [ 29.732275862068967, -1.516266830769231 ], [ 29.732275862068967, -1.515997353846154 ], [ 29.732006379310345, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9976, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.515997353846154 ], [ 29.732275862068967, -1.516266830769231 ], [ 29.732545344827585, -1.516266830769231 ], [ 29.732545344827585, -1.515997353846154 ], [ 29.732275862068967, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9977, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.515997353846154 ], [ 29.732545344827585, -1.516266830769231 ], [ 29.732814827586207, -1.516266830769231 ], [ 29.732814827586207, -1.515997353846154 ], [ 29.732545344827585, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9978, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.515997353846154 ], [ 29.732814827586207, -1.516266830769231 ], [ 29.733084310344829, -1.516266830769231 ], [ 29.733084310344829, -1.515997353846154 ], [ 29.732814827586207, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9979, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.515997353846154 ], [ 29.733084310344829, -1.516266830769231 ], [ 29.733353793103447, -1.516266830769231 ], [ 29.733353793103447, -1.515997353846154 ], [ 29.733084310344829, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9980, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.515997353846154 ], [ 29.733353793103447, -1.516266830769231 ], [ 29.733623275862069, -1.516266830769231 ], [ 29.733623275862069, -1.515997353846154 ], [ 29.733353793103447, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9981, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.515997353846154 ], [ 29.733623275862069, -1.516266830769231 ], [ 29.733892758620691, -1.516266830769231 ], [ 29.733892758620691, -1.515997353846154 ], [ 29.733623275862069, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9982, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.515997353846154 ], [ 29.733892758620691, -1.516266830769231 ], [ 29.734162241379309, -1.516266830769231 ], [ 29.734162241379309, -1.515997353846154 ], [ 29.733892758620691, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9983, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.515997353846154 ], [ 29.734162241379309, -1.516266830769231 ], [ 29.734431724137931, -1.516266830769231 ], [ 29.734431724137931, -1.515997353846154 ], [ 29.734162241379309, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9984, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.515997353846154 ], [ 29.734431724137931, -1.516266830769231 ], [ 29.734701206896553, -1.516266830769231 ], [ 29.734701206896553, -1.515997353846154 ], [ 29.734431724137931, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9985, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.515997353846154 ], [ 29.734701206896553, -1.516266830769231 ], [ 29.734970689655171, -1.516266830769231 ], [ 29.734970689655171, -1.515997353846154 ], [ 29.734701206896553, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9986, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.515997353846154 ], [ 29.734970689655171, -1.516266830769231 ], [ 29.735240172413793, -1.516266830769231 ], [ 29.735240172413793, -1.515997353846154 ], [ 29.734970689655171, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9987, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.515997353846154 ], [ 29.735240172413793, -1.516266830769231 ], [ 29.735509655172415, -1.516266830769231 ], [ 29.735509655172415, -1.515997353846154 ], [ 29.735240172413793, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9988, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.515997353846154 ], [ 29.735509655172415, -1.516266830769231 ], [ 29.735779137931036, -1.516266830769231 ], [ 29.735779137931036, -1.515997353846154 ], [ 29.735509655172415, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9989, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.515997353846154 ], [ 29.735779137931036, -1.516266830769231 ], [ 29.736048620689655, -1.516266830769231 ], [ 29.736048620689655, -1.515997353846154 ], [ 29.735779137931036, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9990, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.515997353846154 ], [ 29.736048620689655, -1.516266830769231 ], [ 29.736318103448276, -1.516266830769231 ], [ 29.736318103448276, -1.515997353846154 ], [ 29.736048620689655, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9991, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.515997353846154 ], [ 29.736318103448276, -1.516266830769231 ], [ 29.736587586206898, -1.516266830769231 ], [ 29.736587586206898, -1.515997353846154 ], [ 29.736318103448276, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9992, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.515997353846154 ], [ 29.736587586206898, -1.516266830769231 ], [ 29.736857068965517, -1.516266830769231 ], [ 29.736857068965517, -1.515997353846154 ], [ 29.736587586206898, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9993, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.515997353846154 ], [ 29.736857068965517, -1.516266830769231 ], [ 29.737126551724138, -1.516266830769231 ], [ 29.737126551724138, -1.515997353846154 ], [ 29.736857068965517, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9994, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.515997353846154 ], [ 29.737126551724138, -1.516266830769231 ], [ 29.73739603448276, -1.516266830769231 ], [ 29.73739603448276, -1.515997353846154 ], [ 29.737126551724138, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9995, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.515997353846154 ], [ 29.73739603448276, -1.516266830769231 ], [ 29.737665517241378, -1.516266830769231 ], [ 29.737665517241378, -1.515997353846154 ], [ 29.73739603448276, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9996, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.515997353846154 ], [ 29.737665517241378, -1.516266830769231 ], [ 29.737935, -1.516266830769231 ], [ 29.737935, -1.515997353846154 ], [ 29.737665517241378, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9997, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.515997353846154 ], [ 29.737935, -1.516266830769231 ], [ 29.738204482758622, -1.516266830769231 ], [ 29.738204482758622, -1.515997353846154 ], [ 29.737935, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9998, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.515997353846154 ], [ 29.738204482758622, -1.516266830769231 ], [ 29.738743448275862, -1.516266830769231 ], [ 29.738743448275862, -1.515997353846154 ], [ 29.738204482758622, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9999, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.515997353846154 ], [ 29.738743448275862, -1.516266830769231 ], [ 29.739012931034484, -1.516266830769231 ], [ 29.739012931034484, -1.515997353846154 ], [ 29.738743448275862, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10000, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.515997353846154 ], [ 29.739012931034484, -1.516266830769231 ], [ 29.739282413793102, -1.516266830769231 ], [ 29.739282413793102, -1.515997353846154 ], [ 29.739012931034484, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10001, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.515997353846154 ], [ 29.739282413793102, -1.516266830769231 ], [ 29.739551896551724, -1.516266830769231 ], [ 29.739551896551724, -1.515997353846154 ], [ 29.739282413793102, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10002, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.515997353846154 ], [ 29.739551896551724, -1.516266830769231 ], [ 29.739821379310346, -1.516266830769231 ], [ 29.739821379310346, -1.515997353846154 ], [ 29.739551896551724, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10003, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.515997353846154 ], [ 29.739821379310346, -1.516266830769231 ], [ 29.740090862068964, -1.516266830769231 ], [ 29.740090862068964, -1.515997353846154 ], [ 29.739821379310346, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10004, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.515997353846154 ], [ 29.740090862068964, -1.516266830769231 ], [ 29.740360344827586, -1.516266830769231 ], [ 29.740360344827586, -1.515997353846154 ], [ 29.740090862068964, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10005, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.515997353846154 ], [ 29.740360344827586, -1.516266830769231 ], [ 29.74089931034483, -1.516266830769231 ], [ 29.74089931034483, -1.515997353846154 ], [ 29.740360344827586, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10006, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.515997353846154 ], [ 29.74089931034483, -1.516266830769231 ], [ 29.74197724137931, -1.516266830769231 ], [ 29.74197724137931, -1.515997353846154 ], [ 29.74089931034483, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10007, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.515997353846154 ], [ 29.74197724137931, -1.516266830769231 ], [ 29.742246724137932, -1.516266830769231 ], [ 29.742246724137932, -1.515997353846154 ], [ 29.74197724137931, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10008, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.515997353846154 ], [ 29.742246724137932, -1.516266830769231 ], [ 29.742516206896553, -1.516266830769231 ], [ 29.742516206896553, -1.516536307692308 ], [ 29.742785689655172, -1.516536307692308 ], [ 29.742785689655172, -1.515997353846154 ], [ 29.742246724137932, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10009, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.515997353846154 ], [ 29.754912413793104, -1.516536307692308 ], [ 29.755181896551726, -1.516536307692308 ], [ 29.755181896551726, -1.515997353846154 ], [ 29.754912413793104, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10010, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.515997353846154 ], [ 29.755181896551726, -1.516536307692308 ], [ 29.755451379310344, -1.516536307692308 ], [ 29.755451379310344, -1.515997353846154 ], [ 29.755181896551726, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10011, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.515997353846154 ], [ 29.755451379310344, -1.516536307692308 ], [ 29.755720862068966, -1.516536307692308 ], [ 29.755720862068966, -1.515997353846154 ], [ 29.755451379310344, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10012, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.515997353846154 ], [ 29.755720862068966, -1.516536307692308 ], [ 29.755990344827588, -1.516536307692308 ], [ 29.755990344827588, -1.515997353846154 ], [ 29.755720862068966, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10013, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.515997353846154 ], [ 29.755990344827588, -1.516536307692308 ], [ 29.756259827586206, -1.516536307692308 ], [ 29.756259827586206, -1.515997353846154 ], [ 29.755990344827588, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10014, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.515997353846154 ], [ 29.756259827586206, -1.516536307692308 ], [ 29.756798793103449, -1.516536307692308 ], [ 29.756798793103449, -1.515997353846154 ], [ 29.756259827586206, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10015, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.515997353846154 ], [ 29.756798793103449, -1.516536307692308 ], [ 29.75733775862069, -1.516536307692308 ], [ 29.75733775862069, -1.516266830769231 ], [ 29.757068275862068, -1.516266830769231 ], [ 29.757068275862068, -1.515997353846154 ], [ 29.756798793103449, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10016, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.515997353846154 ], [ 29.757068275862068, -1.516266830769231 ], [ 29.75733775862069, -1.516266830769231 ], [ 29.75733775862069, -1.515997353846154 ], [ 29.757068275862068, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10017, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.515997353846154 ], [ 29.757607241379311, -1.516266830769231 ], [ 29.757876724137933, -1.516266830769231 ], [ 29.757876724137933, -1.515997353846154 ], [ 29.757607241379311, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10018, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.515997353846154 ], [ 29.757876724137933, -1.516266830769231 ], [ 29.758954655172413, -1.516266830769231 ], [ 29.758954655172413, -1.515997353846154 ], [ 29.757876724137933, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10019, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.515997353846154 ], [ 29.758954655172413, -1.516266830769231 ], [ 29.759224137931035, -1.516266830769231 ], [ 29.759224137931035, -1.515997353846154 ], [ 29.758954655172413, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10020, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.515997353846154 ], [ 29.759224137931035, -1.516266830769231 ], [ 29.759493620689657, -1.516266830769231 ], [ 29.759493620689657, -1.515997353846154 ], [ 29.759224137931035, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10021, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.515997353846154 ], [ 29.759493620689657, -1.516266830769231 ], [ 29.759763103448275, -1.516266830769231 ], [ 29.759763103448275, -1.515997353846154 ], [ 29.759493620689657, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10022, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.515997353846154 ], [ 29.759763103448275, -1.516266830769231 ], [ 29.760032586206897, -1.516266830769231 ], [ 29.760032586206897, -1.515997353846154 ], [ 29.759763103448275, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10023, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.515997353846154 ], [ 29.760032586206897, -1.516266830769231 ], [ 29.760302068965519, -1.516266830769231 ], [ 29.760302068965519, -1.515997353846154 ], [ 29.760032586206897, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10024, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.515997353846154 ], [ 29.760302068965519, -1.516266830769231 ], [ 29.760571551724137, -1.516266830769231 ], [ 29.760571551724137, -1.515997353846154 ], [ 29.760302068965519, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10025, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.515997353846154 ], [ 29.760571551724137, -1.516266830769231 ], [ 29.760841034482759, -1.516266830769231 ], [ 29.760841034482759, -1.515997353846154 ], [ 29.760571551724137, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10026, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.515997353846154 ], [ 29.760841034482759, -1.516266830769231 ], [ 29.761110517241381, -1.516266830769231 ], [ 29.761110517241381, -1.515997353846154 ], [ 29.760841034482759, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10027, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.515997353846154 ], [ 29.761110517241381, -1.516266830769231 ], [ 29.76138, -1.516266830769231 ], [ 29.76138, -1.515997353846154 ], [ 29.761110517241381, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10028, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.515997353846154 ], [ 29.76138, -1.516266830769231 ], [ 29.761649482758621, -1.516266830769231 ], [ 29.761649482758621, -1.515997353846154 ], [ 29.76138, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10029, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.515997353846154 ], [ 29.761649482758621, -1.516266830769231 ], [ 29.761918965517243, -1.516266830769231 ], [ 29.761918965517243, -1.515997353846154 ], [ 29.761649482758621, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10030, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.515997353846154 ], [ 29.761918965517243, -1.516266830769231 ], [ 29.762188448275861, -1.516266830769231 ], [ 29.762188448275861, -1.515997353846154 ], [ 29.761918965517243, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10031, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.515997353846154 ], [ 29.762188448275861, -1.516266830769231 ], [ 29.762727413793105, -1.516266830769231 ], [ 29.762727413793105, -1.515997353846154 ], [ 29.762188448275861, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10032, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.515997353846154 ], [ 29.762727413793105, -1.516266830769231 ], [ 29.762996896551723, -1.516266830769231 ], [ 29.762996896551723, -1.515997353846154 ], [ 29.762727413793105, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10033, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.515997353846154 ], [ 29.762996896551723, -1.516266830769231 ], [ 29.763266379310345, -1.516266830769231 ], [ 29.763266379310345, -1.515997353846154 ], [ 29.762996896551723, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10034, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.515997353846154 ], [ 29.763266379310345, -1.516266830769231 ], [ 29.763535862068967, -1.516266830769231 ], [ 29.763535862068967, -1.515997353846154 ], [ 29.763266379310345, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10035, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.515997353846154 ], [ 29.763535862068967, -1.516266830769231 ], [ 29.763805344827588, -1.516266830769231 ], [ 29.763805344827588, -1.515997353846154 ], [ 29.763535862068967, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10036, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.515997353846154 ], [ 29.763805344827588, -1.516266830769231 ], [ 29.764074827586207, -1.516266830769231 ], [ 29.764074827586207, -1.515997353846154 ], [ 29.763805344827588, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10037, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.515997353846154 ], [ 29.764344310344828, -1.517075261538462 ], [ 29.76461379310345, -1.517075261538462 ], [ 29.76461379310345, -1.515997353846154 ], [ 29.764344310344828, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10038, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.515997353846154 ], [ 29.76461379310345, -1.516536307692308 ], [ 29.764883275862069, -1.516536307692308 ], [ 29.764883275862069, -1.515997353846154 ], [ 29.76461379310345, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10039, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.515997353846154 ], [ 29.764883275862069, -1.516266830769231 ], [ 29.76515275862069, -1.516266830769231 ], [ 29.76515275862069, -1.515997353846154 ], [ 29.764883275862069, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10040, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.515997353846154 ], [ 29.766500172413792, -1.516536307692308 ], [ 29.766769655172414, -1.516536307692308 ], [ 29.766769655172414, -1.515997353846154 ], [ 29.766500172413792, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10041, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.515997353846154 ], [ 29.766769655172414, -1.516536307692308 ], [ 29.767039137931036, -1.516536307692308 ], [ 29.767039137931036, -1.515997353846154 ], [ 29.766769655172414, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10042, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.515997353846154 ], [ 29.767039137931036, -1.516536307692308 ], [ 29.767308620689654, -1.516536307692308 ], [ 29.767308620689654, -1.515997353846154 ], [ 29.767039137931036, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10043, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.515997353846154 ], [ 29.767578103448276, -1.517075261538462 ], [ 29.767847586206898, -1.517075261538462 ], [ 29.767847586206898, -1.515997353846154 ], [ 29.767578103448276, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10044, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.515997353846154 ], [ 29.767847586206898, -1.517075261538462 ], [ 29.768117068965516, -1.517075261538462 ], [ 29.768117068965516, -1.515997353846154 ], [ 29.767847586206898, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10045, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.515997353846154 ], [ 29.768117068965516, -1.516266830769231 ], [ 29.768386551724138, -1.516266830769231 ], [ 29.768386551724138, -1.515997353846154 ], [ 29.768117068965516, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10046, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.516266830769231 ], [ 29.712334137931034, -1.516536307692308 ], [ 29.712603620689656, -1.516536307692308 ], [ 29.712603620689656, -1.516266830769231 ], [ 29.712334137931034, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10047, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.516266830769231 ], [ 29.712603620689656, -1.517075261538462 ], [ 29.712873103448278, -1.517075261538462 ], [ 29.712873103448278, -1.516266830769231 ], [ 29.712603620689656, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10048, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719071206896551, -1.516266830769231 ], [ 29.719071206896551, -1.516536307692308 ], [ 29.719610172413795, -1.516536307692308 ], [ 29.719610172413795, -1.516266830769231 ], [ 29.719071206896551, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10049, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.516266830769231 ], [ 29.719610172413795, -1.516536307692308 ], [ 29.719879655172413, -1.516536307692308 ], [ 29.719879655172413, -1.516266830769231 ], [ 29.719610172413795, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10050, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719879655172413, -1.516266830769231 ], [ 29.719879655172413, -1.516536307692308 ], [ 29.720149137931035, -1.516536307692308 ], [ 29.720149137931035, -1.516266830769231 ], [ 29.719879655172413, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10051, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724191379310344, -1.516266830769231 ], [ 29.724191379310344, -1.516536307692308 ], [ 29.724460862068966, -1.516536307692308 ], [ 29.724460862068966, -1.516266830769231 ], [ 29.724191379310344, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10052, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.516266830769231 ], [ 29.724460862068966, -1.516536307692308 ], [ 29.724730344827588, -1.516536307692308 ], [ 29.724730344827588, -1.516266830769231 ], [ 29.724460862068966, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10053, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.516266830769231 ], [ 29.724730344827588, -1.516536307692308 ], [ 29.724999827586206, -1.516536307692308 ], [ 29.724999827586206, -1.516266830769231 ], [ 29.724730344827588, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10054, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.516266830769231 ], [ 29.724999827586206, -1.516536307692308 ], [ 29.725269310344828, -1.516536307692308 ], [ 29.725269310344828, -1.516266830769231 ], [ 29.724999827586206, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10055, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.516266830769231 ], [ 29.725269310344828, -1.516536307692308 ], [ 29.72553879310345, -1.516536307692308 ], [ 29.72553879310345, -1.516266830769231 ], [ 29.725269310344828, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10056, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.516266830769231 ], [ 29.72553879310345, -1.517075261538462 ], [ 29.725808275862068, -1.517075261538462 ], [ 29.725808275862068, -1.516266830769231 ], [ 29.72553879310345, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10057, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.516266830769231 ], [ 29.725808275862068, -1.516536307692308 ], [ 29.72607775862069, -1.516536307692308 ], [ 29.72607775862069, -1.516266830769231 ], [ 29.725808275862068, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10058, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.516266830769231 ], [ 29.72607775862069, -1.516536307692308 ], [ 29.726347241379312, -1.516536307692308 ], [ 29.726347241379312, -1.516266830769231 ], [ 29.72607775862069, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10059, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.516266830769231 ], [ 29.726347241379312, -1.516536307692308 ], [ 29.72661672413793, -1.516536307692308 ], [ 29.72661672413793, -1.516266830769231 ], [ 29.726347241379312, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10060, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.516266830769231 ], [ 29.72661672413793, -1.517344738461538 ], [ 29.726886206896552, -1.517344738461538 ], [ 29.726886206896552, -1.516266830769231 ], [ 29.72661672413793, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10061, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.516266830769231 ], [ 29.726886206896552, -1.517344738461538 ], [ 29.727964137931036, -1.517344738461538 ], [ 29.727964137931036, -1.517075261538462 ], [ 29.727155689655174, -1.517075261538462 ], [ 29.727155689655174, -1.516266830769231 ], [ 29.726886206896552, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10062, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727694655172414, -1.516266830769231 ], [ 29.727694655172414, -1.516536307692308 ], [ 29.727964137931036, -1.516536307692308 ], [ 29.727964137931036, -1.516266830769231 ], [ 29.727694655172414, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10063, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.516266830769231 ], [ 29.727964137931036, -1.516536307692308 ], [ 29.728233620689654, -1.516536307692308 ], [ 29.728233620689654, -1.516266830769231 ], [ 29.727964137931036, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10064, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.516266830769231 ], [ 29.728233620689654, -1.516536307692308 ], [ 29.728503103448276, -1.516536307692308 ], [ 29.728503103448276, -1.516266830769231 ], [ 29.728233620689654, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10065, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.516266830769231 ], [ 29.728503103448276, -1.516536307692308 ], [ 29.728772586206897, -1.516536307692308 ], [ 29.728772586206897, -1.516266830769231 ], [ 29.728503103448276, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10066, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.516266830769231 ], [ 29.728772586206897, -1.516536307692308 ], [ 29.729042068965516, -1.516536307692308 ], [ 29.729042068965516, -1.516266830769231 ], [ 29.728772586206897, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10067, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.516266830769231 ], [ 29.729042068965516, -1.516536307692308 ], [ 29.729311551724138, -1.516536307692308 ], [ 29.729311551724138, -1.516266830769231 ], [ 29.729042068965516, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10068, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.516266830769231 ], [ 29.729311551724138, -1.516536307692308 ], [ 29.729850517241381, -1.516536307692308 ], [ 29.729850517241381, -1.516266830769231 ], [ 29.729311551724138, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10069, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.516266830769231 ], [ 29.729850517241381, -1.516536307692308 ], [ 29.73012, -1.516536307692308 ], [ 29.73012, -1.516266830769231 ], [ 29.729850517241381, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10070, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.516266830769231 ], [ 29.73012, -1.516536307692308 ], [ 29.730389482758621, -1.516536307692308 ], [ 29.730389482758621, -1.516266830769231 ], [ 29.73012, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10071, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.516266830769231 ], [ 29.730389482758621, -1.516536307692308 ], [ 29.730658965517243, -1.516536307692308 ], [ 29.730658965517243, -1.516266830769231 ], [ 29.730389482758621, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10072, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.516266830769231 ], [ 29.730658965517243, -1.516536307692308 ], [ 29.730928448275861, -1.516536307692308 ], [ 29.730928448275861, -1.516266830769231 ], [ 29.730658965517243, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10073, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.516266830769231 ], [ 29.730928448275861, -1.516536307692308 ], [ 29.731197931034483, -1.516536307692308 ], [ 29.731197931034483, -1.516266830769231 ], [ 29.730928448275861, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10074, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.516266830769231 ], [ 29.731197931034483, -1.516536307692308 ], [ 29.732006379310345, -1.516536307692308 ], [ 29.732006379310345, -1.516266830769231 ], [ 29.731197931034483, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10075, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.516266830769231 ], [ 29.732006379310345, -1.516536307692308 ], [ 29.732275862068967, -1.516536307692308 ], [ 29.732275862068967, -1.516266830769231 ], [ 29.732006379310345, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10076, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.516266830769231 ], [ 29.732275862068967, -1.516536307692308 ], [ 29.732545344827585, -1.516536307692308 ], [ 29.732545344827585, -1.516266830769231 ], [ 29.732275862068967, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10077, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.516266830769231 ], [ 29.732545344827585, -1.516536307692308 ], [ 29.732814827586207, -1.516536307692308 ], [ 29.732814827586207, -1.516266830769231 ], [ 29.732545344827585, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10078, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.516266830769231 ], [ 29.732814827586207, -1.516536307692308 ], [ 29.733084310344829, -1.516536307692308 ], [ 29.733084310344829, -1.516266830769231 ], [ 29.732814827586207, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10079, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.516266830769231 ], [ 29.733084310344829, -1.516536307692308 ], [ 29.733353793103447, -1.516536307692308 ], [ 29.733353793103447, -1.516266830769231 ], [ 29.733084310344829, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10080, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.516266830769231 ], [ 29.733353793103447, -1.516536307692308 ], [ 29.733623275862069, -1.516536307692308 ], [ 29.733623275862069, -1.516266830769231 ], [ 29.733353793103447, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10081, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.516266830769231 ], [ 29.733623275862069, -1.516536307692308 ], [ 29.733892758620691, -1.516536307692308 ], [ 29.733892758620691, -1.516266830769231 ], [ 29.733623275862069, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10082, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.516266830769231 ], [ 29.733892758620691, -1.516536307692308 ], [ 29.734162241379309, -1.516536307692308 ], [ 29.734162241379309, -1.516266830769231 ], [ 29.733892758620691, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10083, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.516266830769231 ], [ 29.734162241379309, -1.516536307692308 ], [ 29.734431724137931, -1.516536307692308 ], [ 29.734431724137931, -1.516266830769231 ], [ 29.734162241379309, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10084, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.516266830769231 ], [ 29.734431724137931, -1.516536307692308 ], [ 29.734701206896553, -1.516536307692308 ], [ 29.734701206896553, -1.516266830769231 ], [ 29.734431724137931, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10085, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.516266830769231 ], [ 29.734701206896553, -1.516536307692308 ], [ 29.734970689655171, -1.516536307692308 ], [ 29.734970689655171, -1.516266830769231 ], [ 29.734701206896553, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10086, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.516266830769231 ], [ 29.734970689655171, -1.516536307692308 ], [ 29.735240172413793, -1.516536307692308 ], [ 29.735240172413793, -1.516266830769231 ], [ 29.734970689655171, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10087, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.516266830769231 ], [ 29.735240172413793, -1.516536307692308 ], [ 29.735509655172415, -1.516536307692308 ], [ 29.735509655172415, -1.516266830769231 ], [ 29.735240172413793, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10088, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.516266830769231 ], [ 29.735509655172415, -1.516536307692308 ], [ 29.735779137931036, -1.516536307692308 ], [ 29.735779137931036, -1.516266830769231 ], [ 29.735509655172415, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10089, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.516266830769231 ], [ 29.735779137931036, -1.516536307692308 ], [ 29.736048620689655, -1.516536307692308 ], [ 29.736048620689655, -1.517075261538462 ], [ 29.736318103448276, -1.517075261538462 ], [ 29.736318103448276, -1.517344738461538 ], [ 29.736857068965517, -1.517344738461538 ], [ 29.736857068965517, -1.517075261538462 ], [ 29.736587586206898, -1.517075261538462 ], [ 29.736587586206898, -1.516536307692308 ], [ 29.736318103448276, -1.516536307692308 ], [ 29.736318103448276, -1.516266830769231 ], [ 29.735779137931036, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10090, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.516266830769231 ], [ 29.736318103448276, -1.516536307692308 ], [ 29.736587586206898, -1.516536307692308 ], [ 29.736587586206898, -1.516266830769231 ], [ 29.736318103448276, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10091, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.516266830769231 ], [ 29.736587586206898, -1.516536307692308 ], [ 29.736857068965517, -1.516536307692308 ], [ 29.736857068965517, -1.516266830769231 ], [ 29.736587586206898, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10092, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.516266830769231 ], [ 29.736857068965517, -1.516536307692308 ], [ 29.737126551724138, -1.516536307692308 ], [ 29.737126551724138, -1.516266830769231 ], [ 29.736857068965517, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10093, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.516266830769231 ], [ 29.737126551724138, -1.516536307692308 ], [ 29.73739603448276, -1.516536307692308 ], [ 29.73739603448276, -1.516266830769231 ], [ 29.737126551724138, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10094, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.516266830769231 ], [ 29.73739603448276, -1.516536307692308 ], [ 29.737665517241378, -1.516536307692308 ], [ 29.737665517241378, -1.516266830769231 ], [ 29.73739603448276, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10095, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.516266830769231 ], [ 29.737665517241378, -1.516536307692308 ], [ 29.737935, -1.516536307692308 ], [ 29.737935, -1.516266830769231 ], [ 29.737665517241378, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10096, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.516266830769231 ], [ 29.737935, -1.516536307692308 ], [ 29.738204482758622, -1.516536307692308 ], [ 29.738204482758622, -1.516266830769231 ], [ 29.737935, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10097, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.516266830769231 ], [ 29.738204482758622, -1.516536307692308 ], [ 29.738743448275862, -1.516536307692308 ], [ 29.738743448275862, -1.516266830769231 ], [ 29.738204482758622, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10098, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.516266830769231 ], [ 29.738743448275862, -1.516536307692308 ], [ 29.739012931034484, -1.516536307692308 ], [ 29.739012931034484, -1.516266830769231 ], [ 29.738743448275862, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10099, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.516266830769231 ], [ 29.739012931034484, -1.516536307692308 ], [ 29.739282413793102, -1.516536307692308 ], [ 29.739282413793102, -1.516266830769231 ], [ 29.739012931034484, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10100, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.516266830769231 ], [ 29.739282413793102, -1.516536307692308 ], [ 29.739551896551724, -1.516536307692308 ], [ 29.739551896551724, -1.516266830769231 ], [ 29.739282413793102, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10101, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.516266830769231 ], [ 29.739551896551724, -1.516536307692308 ], [ 29.739821379310346, -1.516536307692308 ], [ 29.739821379310346, -1.516266830769231 ], [ 29.739551896551724, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10102, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.516266830769231 ], [ 29.739821379310346, -1.516536307692308 ], [ 29.740090862068964, -1.516536307692308 ], [ 29.740090862068964, -1.516266830769231 ], [ 29.739821379310346, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10103, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.516266830769231 ], [ 29.740090862068964, -1.516536307692308 ], [ 29.740629827586208, -1.516536307692308 ], [ 29.740629827586208, -1.516266830769231 ], [ 29.740090862068964, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10104, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.516266830769231 ], [ 29.740629827586208, -1.516536307692308 ], [ 29.74089931034483, -1.516536307692308 ], [ 29.74089931034483, -1.516266830769231 ], [ 29.740629827586208, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10105, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.516266830769231 ], [ 29.74089931034483, -1.516536307692308 ], [ 29.74197724137931, -1.516536307692308 ], [ 29.74197724137931, -1.516266830769231 ], [ 29.74089931034483, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10106, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.516266830769231 ], [ 29.74197724137931, -1.516536307692308 ], [ 29.742246724137932, -1.516536307692308 ], [ 29.742246724137932, -1.517075261538462 ], [ 29.742516206896553, -1.517075261538462 ], [ 29.742516206896553, -1.516266830769231 ], [ 29.74197724137931, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10107, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.516266830769231 ], [ 29.75733775862069, -1.516536307692308 ], [ 29.757607241379311, -1.516536307692308 ], [ 29.757607241379311, -1.516266830769231 ], [ 29.75733775862069, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10108, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.516266830769231 ], [ 29.757607241379311, -1.516536307692308 ], [ 29.758415689655173, -1.516536307692308 ], [ 29.758415689655173, -1.516266830769231 ], [ 29.757607241379311, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10109, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.516266830769231 ], [ 29.758415689655173, -1.516536307692308 ], [ 29.758954655172413, -1.516536307692308 ], [ 29.758954655172413, -1.516266830769231 ], [ 29.758415689655173, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10110, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.516266830769231 ], [ 29.758954655172413, -1.516536307692308 ], [ 29.759224137931035, -1.516536307692308 ], [ 29.759224137931035, -1.516266830769231 ], [ 29.758954655172413, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10111, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.516266830769231 ], [ 29.759224137931035, -1.516536307692308 ], [ 29.759493620689657, -1.516536307692308 ], [ 29.759493620689657, -1.516266830769231 ], [ 29.759224137931035, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10112, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.516266830769231 ], [ 29.759493620689657, -1.516536307692308 ], [ 29.759763103448275, -1.516536307692308 ], [ 29.759763103448275, -1.516266830769231 ], [ 29.759493620689657, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10113, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.516266830769231 ], [ 29.759763103448275, -1.516536307692308 ], [ 29.760032586206897, -1.516536307692308 ], [ 29.760032586206897, -1.516266830769231 ], [ 29.759763103448275, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10114, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.516266830769231 ], [ 29.760032586206897, -1.516536307692308 ], [ 29.760302068965519, -1.516536307692308 ], [ 29.760302068965519, -1.516266830769231 ], [ 29.760032586206897, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10115, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.516266830769231 ], [ 29.760302068965519, -1.516536307692308 ], [ 29.760571551724137, -1.516536307692308 ], [ 29.760571551724137, -1.516266830769231 ], [ 29.760302068965519, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10116, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.516266830769231 ], [ 29.760571551724137, -1.516536307692308 ], [ 29.760841034482759, -1.516536307692308 ], [ 29.760841034482759, -1.516266830769231 ], [ 29.760571551724137, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10117, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.516266830769231 ], [ 29.760841034482759, -1.516536307692308 ], [ 29.761110517241381, -1.516536307692308 ], [ 29.761110517241381, -1.516266830769231 ], [ 29.760841034482759, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10118, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.516266830769231 ], [ 29.761110517241381, -1.516536307692308 ], [ 29.76138, -1.516536307692308 ], [ 29.76138, -1.516266830769231 ], [ 29.761110517241381, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10119, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.516266830769231 ], [ 29.76138, -1.516536307692308 ], [ 29.761649482758621, -1.516536307692308 ], [ 29.761649482758621, -1.516266830769231 ], [ 29.76138, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10120, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.516266830769231 ], [ 29.761649482758621, -1.516536307692308 ], [ 29.761918965517243, -1.516536307692308 ], [ 29.761918965517243, -1.516266830769231 ], [ 29.761649482758621, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10121, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.516266830769231 ], [ 29.761918965517243, -1.516536307692308 ], [ 29.762188448275861, -1.516536307692308 ], [ 29.762188448275861, -1.516266830769231 ], [ 29.761918965517243, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10122, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.516266830769231 ], [ 29.762188448275861, -1.516536307692308 ], [ 29.762457931034483, -1.516536307692308 ], [ 29.762457931034483, -1.516266830769231 ], [ 29.762188448275861, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10123, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.516266830769231 ], [ 29.762457931034483, -1.516536307692308 ], [ 29.762727413793105, -1.516536307692308 ], [ 29.762727413793105, -1.516266830769231 ], [ 29.762457931034483, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10124, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.516266830769231 ], [ 29.763266379310345, -1.517344738461538 ], [ 29.763535862068967, -1.517344738461538 ], [ 29.763535862068967, -1.516266830769231 ], [ 29.763266379310345, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10125, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.516266830769231 ], [ 29.763535862068967, -1.516536307692308 ], [ 29.763805344827588, -1.516536307692308 ], [ 29.763805344827588, -1.516266830769231 ], [ 29.763535862068967, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10126, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.516266830769231 ], [ 29.763805344827588, -1.516536307692308 ], [ 29.764074827586207, -1.516536307692308 ], [ 29.764074827586207, -1.516266830769231 ], [ 29.763805344827588, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10127, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.516266830769231 ], [ 29.76515275862069, -1.517075261538462 ], [ 29.765422241379312, -1.517075261538462 ], [ 29.765422241379312, -1.516266830769231 ], [ 29.76515275862069, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10128, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.516266830769231 ], [ 29.765422241379312, -1.516536307692308 ], [ 29.765961206896552, -1.516536307692308 ], [ 29.765961206896552, -1.516266830769231 ], [ 29.765422241379312, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10129, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.516266830769231 ], [ 29.765961206896552, -1.516536307692308 ], [ 29.766230689655174, -1.516536307692308 ], [ 29.766230689655174, -1.516266830769231 ], [ 29.765961206896552, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10130, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.516266830769231 ], [ 29.766230689655174, -1.516536307692308 ], [ 29.766500172413792, -1.516536307692308 ], [ 29.766500172413792, -1.516266830769231 ], [ 29.766230689655174, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10131, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.516266830769231 ], [ 29.767308620689654, -1.516536307692308 ], [ 29.767578103448276, -1.516536307692308 ], [ 29.767578103448276, -1.516266830769231 ], [ 29.767308620689654, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10132, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.516266830769231 ], [ 29.768117068965516, -1.517344738461538 ], [ 29.768386551724138, -1.517344738461538 ], [ 29.768386551724138, -1.516266830769231 ], [ 29.768117068965516, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10133, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.516536307692308 ], [ 29.712334137931034, -1.517344738461538 ], [ 29.712603620689656, -1.517344738461538 ], [ 29.712603620689656, -1.516536307692308 ], [ 29.712334137931034, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10134, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.719610172413795, -1.516536307692308 ], [ 29.719610172413795, -1.517075261538462 ], [ 29.719879655172413, -1.517075261538462 ], [ 29.719879655172413, -1.516536307692308 ], [ 29.719610172413795, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10135, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724460862068966, -1.516536307692308 ], [ 29.724460862068966, -1.517075261538462 ], [ 29.724730344827588, -1.517075261538462 ], [ 29.724730344827588, -1.516536307692308 ], [ 29.724460862068966, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10136, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.516536307692308 ], [ 29.724730344827588, -1.517075261538462 ], [ 29.724999827586206, -1.517075261538462 ], [ 29.724999827586206, -1.516536307692308 ], [ 29.724730344827588, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10137, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.516536307692308 ], [ 29.724999827586206, -1.517883692307692 ], [ 29.725269310344828, -1.517883692307692 ], [ 29.725269310344828, -1.516536307692308 ], [ 29.724999827586206, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10138, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.516536307692308 ], [ 29.725269310344828, -1.517883692307692 ], [ 29.72553879310345, -1.517883692307692 ], [ 29.72553879310345, -1.516536307692308 ], [ 29.725269310344828, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10139, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.516536307692308 ], [ 29.725808275862068, -1.517614215384615 ], [ 29.72607775862069, -1.517614215384615 ], [ 29.72607775862069, -1.516536307692308 ], [ 29.725808275862068, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10140, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.516536307692308 ], [ 29.72607775862069, -1.517614215384615 ], [ 29.726347241379312, -1.517614215384615 ], [ 29.726347241379312, -1.516536307692308 ], [ 29.72607775862069, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10141, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.516536307692308 ], [ 29.726347241379312, -1.517614215384615 ], [ 29.72661672413793, -1.517614215384615 ], [ 29.72661672413793, -1.516536307692308 ], [ 29.726347241379312, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10142, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727155689655174, -1.516536307692308 ], [ 29.727155689655174, -1.517075261538462 ], [ 29.727964137931036, -1.517075261538462 ], [ 29.727964137931036, -1.516536307692308 ], [ 29.727155689655174, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10143, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.516536307692308 ], [ 29.727964137931036, -1.517075261538462 ], [ 29.728233620689654, -1.517075261538462 ], [ 29.728233620689654, -1.516536307692308 ], [ 29.727964137931036, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10144, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.516536307692308 ], [ 29.728233620689654, -1.517075261538462 ], [ 29.728503103448276, -1.517075261538462 ], [ 29.728503103448276, -1.516536307692308 ], [ 29.728233620689654, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10145, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.516536307692308 ], [ 29.728503103448276, -1.517075261538462 ], [ 29.728772586206897, -1.517075261538462 ], [ 29.728772586206897, -1.516536307692308 ], [ 29.728503103448276, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10146, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.516536307692308 ], [ 29.728772586206897, -1.517075261538462 ], [ 29.729042068965516, -1.517075261538462 ], [ 29.729042068965516, -1.516536307692308 ], [ 29.728772586206897, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10147, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.516536307692308 ], [ 29.729042068965516, -1.517075261538462 ], [ 29.729311551724138, -1.517075261538462 ], [ 29.729311551724138, -1.516536307692308 ], [ 29.729042068965516, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10148, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.516536307692308 ], [ 29.729311551724138, -1.517075261538462 ], [ 29.729850517241381, -1.517075261538462 ], [ 29.729850517241381, -1.516536307692308 ], [ 29.729311551724138, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10149, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.516536307692308 ], [ 29.729850517241381, -1.517075261538462 ], [ 29.73012, -1.517075261538462 ], [ 29.73012, -1.516536307692308 ], [ 29.729850517241381, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10150, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.516536307692308 ], [ 29.73012, -1.517075261538462 ], [ 29.730389482758621, -1.517075261538462 ], [ 29.730389482758621, -1.516536307692308 ], [ 29.73012, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10151, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.516536307692308 ], [ 29.730389482758621, -1.517075261538462 ], [ 29.730658965517243, -1.517075261538462 ], [ 29.730658965517243, -1.516536307692308 ], [ 29.730389482758621, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10152, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.516536307692308 ], [ 29.730658965517243, -1.517075261538462 ], [ 29.730928448275861, -1.517075261538462 ], [ 29.730928448275861, -1.516536307692308 ], [ 29.730658965517243, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10153, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.516536307692308 ], [ 29.730928448275861, -1.517075261538462 ], [ 29.731467413793105, -1.517075261538462 ], [ 29.731467413793105, -1.516536307692308 ], [ 29.730928448275861, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10154, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.516536307692308 ], [ 29.731467413793105, -1.517075261538462 ], [ 29.731736896551723, -1.517075261538462 ], [ 29.731736896551723, -1.516536307692308 ], [ 29.731467413793105, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10155, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.516536307692308 ], [ 29.731736896551723, -1.517075261538462 ], [ 29.732275862068967, -1.517075261538462 ], [ 29.732275862068967, -1.516536307692308 ], [ 29.731736896551723, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10156, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.516536307692308 ], [ 29.732275862068967, -1.517075261538462 ], [ 29.732545344827585, -1.517075261538462 ], [ 29.732545344827585, -1.516536307692308 ], [ 29.732275862068967, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10157, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.516536307692308 ], [ 29.732545344827585, -1.517075261538462 ], [ 29.732814827586207, -1.517075261538462 ], [ 29.732814827586207, -1.516536307692308 ], [ 29.732545344827585, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10158, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.516536307692308 ], [ 29.732814827586207, -1.517075261538462 ], [ 29.733084310344829, -1.517075261538462 ], [ 29.733084310344829, -1.516536307692308 ], [ 29.732814827586207, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10159, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.516536307692308 ], [ 29.733084310344829, -1.517075261538462 ], [ 29.733353793103447, -1.517075261538462 ], [ 29.733353793103447, -1.516536307692308 ], [ 29.733084310344829, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10160, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.516536307692308 ], [ 29.733353793103447, -1.517075261538462 ], [ 29.733623275862069, -1.517075261538462 ], [ 29.733623275862069, -1.516536307692308 ], [ 29.733353793103447, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10161, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.516536307692308 ], [ 29.733623275862069, -1.517075261538462 ], [ 29.733892758620691, -1.517075261538462 ], [ 29.733892758620691, -1.516536307692308 ], [ 29.733623275862069, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10162, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.516536307692308 ], [ 29.733892758620691, -1.517075261538462 ], [ 29.734162241379309, -1.517075261538462 ], [ 29.734162241379309, -1.516536307692308 ], [ 29.733892758620691, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10163, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.516536307692308 ], [ 29.734162241379309, -1.517075261538462 ], [ 29.734431724137931, -1.517075261538462 ], [ 29.734431724137931, -1.516536307692308 ], [ 29.734162241379309, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10164, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.516536307692308 ], [ 29.734431724137931, -1.517075261538462 ], [ 29.734701206896553, -1.517075261538462 ], [ 29.734701206896553, -1.516536307692308 ], [ 29.734431724137931, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10165, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.516536307692308 ], [ 29.734701206896553, -1.517075261538462 ], [ 29.734970689655171, -1.517075261538462 ], [ 29.734970689655171, -1.516536307692308 ], [ 29.734701206896553, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10166, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.516536307692308 ], [ 29.734970689655171, -1.517075261538462 ], [ 29.735240172413793, -1.517075261538462 ], [ 29.735240172413793, -1.516536307692308 ], [ 29.734970689655171, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10167, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.516536307692308 ], [ 29.735240172413793, -1.517075261538462 ], [ 29.735509655172415, -1.517075261538462 ], [ 29.735509655172415, -1.516536307692308 ], [ 29.735240172413793, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10168, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.516536307692308 ], [ 29.735509655172415, -1.517075261538462 ], [ 29.735779137931036, -1.517075261538462 ], [ 29.735779137931036, -1.516536307692308 ], [ 29.735509655172415, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10169, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.516536307692308 ], [ 29.735779137931036, -1.517075261538462 ], [ 29.736048620689655, -1.517075261538462 ], [ 29.736048620689655, -1.516536307692308 ], [ 29.735779137931036, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10170, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.516536307692308 ], [ 29.736587586206898, -1.517075261538462 ], [ 29.736857068965517, -1.517075261538462 ], [ 29.736857068965517, -1.516536307692308 ], [ 29.736587586206898, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10171, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.516536307692308 ], [ 29.736857068965517, -1.517075261538462 ], [ 29.737126551724138, -1.517075261538462 ], [ 29.737126551724138, -1.516536307692308 ], [ 29.736857068965517, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10172, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.516536307692308 ], [ 29.737126551724138, -1.517075261538462 ], [ 29.73739603448276, -1.517075261538462 ], [ 29.73739603448276, -1.516536307692308 ], [ 29.737126551724138, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10173, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.516536307692308 ], [ 29.73739603448276, -1.517075261538462 ], [ 29.737665517241378, -1.517075261538462 ], [ 29.737665517241378, -1.516536307692308 ], [ 29.73739603448276, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10174, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.516536307692308 ], [ 29.737665517241378, -1.517075261538462 ], [ 29.737935, -1.517075261538462 ], [ 29.737935, -1.516536307692308 ], [ 29.737665517241378, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10175, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.516536307692308 ], [ 29.737935, -1.517075261538462 ], [ 29.738204482758622, -1.517075261538462 ], [ 29.738204482758622, -1.516536307692308 ], [ 29.737935, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10176, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.516536307692308 ], [ 29.738204482758622, -1.517075261538462 ], [ 29.738743448275862, -1.517075261538462 ], [ 29.738743448275862, -1.516536307692308 ], [ 29.738204482758622, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10177, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.516536307692308 ], [ 29.738743448275862, -1.517075261538462 ], [ 29.739012931034484, -1.517075261538462 ], [ 29.739012931034484, -1.516536307692308 ], [ 29.738743448275862, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10178, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.516536307692308 ], [ 29.739012931034484, -1.517075261538462 ], [ 29.739282413793102, -1.517075261538462 ], [ 29.739282413793102, -1.516536307692308 ], [ 29.739012931034484, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10179, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.516536307692308 ], [ 29.739282413793102, -1.517075261538462 ], [ 29.739551896551724, -1.517075261538462 ], [ 29.739551896551724, -1.516536307692308 ], [ 29.739282413793102, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10180, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.516536307692308 ], [ 29.739551896551724, -1.517075261538462 ], [ 29.739821379310346, -1.517075261538462 ], [ 29.739821379310346, -1.516536307692308 ], [ 29.739551896551724, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10181, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.516536307692308 ], [ 29.739821379310346, -1.517075261538462 ], [ 29.740090862068964, -1.517075261538462 ], [ 29.740090862068964, -1.516536307692308 ], [ 29.739821379310346, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10182, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.516536307692308 ], [ 29.740090862068964, -1.517075261538462 ], [ 29.740360344827586, -1.517075261538462 ], [ 29.740360344827586, -1.516536307692308 ], [ 29.740090862068964, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10183, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.516536307692308 ], [ 29.740360344827586, -1.517075261538462 ], [ 29.740629827586208, -1.517075261538462 ], [ 29.740629827586208, -1.516536307692308 ], [ 29.740360344827586, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10184, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.516536307692308 ], [ 29.740629827586208, -1.517075261538462 ], [ 29.741168793103448, -1.517075261538462 ], [ 29.741168793103448, -1.516536307692308 ], [ 29.740629827586208, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10185, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.516536307692308 ], [ 29.741168793103448, -1.517075261538462 ], [ 29.741707758620691, -1.517075261538462 ], [ 29.741707758620691, -1.516536307692308 ], [ 29.741168793103448, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10186, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.516536307692308 ], [ 29.741707758620691, -1.517075261538462 ], [ 29.74197724137931, -1.517075261538462 ], [ 29.74197724137931, -1.516536307692308 ], [ 29.741707758620691, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10187, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.516536307692308 ], [ 29.74197724137931, -1.517075261538462 ], [ 29.742246724137932, -1.517075261538462 ], [ 29.742246724137932, -1.516536307692308 ], [ 29.74197724137931, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10188, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.516536307692308 ], [ 29.742516206896553, -1.517075261538462 ], [ 29.742785689655172, -1.517075261538462 ], [ 29.742785689655172, -1.516536307692308 ], [ 29.742516206896553, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10189, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.516536307692308 ], [ 29.742785689655172, -1.517075261538462 ], [ 29.743055172413793, -1.517075261538462 ], [ 29.743055172413793, -1.516536307692308 ], [ 29.742785689655172, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10190, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.516536307692308 ], [ 29.754912413793104, -1.517075261538462 ], [ 29.755181896551726, -1.517075261538462 ], [ 29.755181896551726, -1.516536307692308 ], [ 29.754912413793104, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10191, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.516536307692308 ], [ 29.755181896551726, -1.517344738461538 ], [ 29.755451379310344, -1.517344738461538 ], [ 29.755451379310344, -1.516536307692308 ], [ 29.755181896551726, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10192, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.516536307692308 ], [ 29.755451379310344, -1.517075261538462 ], [ 29.755720862068966, -1.517075261538462 ], [ 29.755720862068966, -1.516536307692308 ], [ 29.755451379310344, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10193, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.516536307692308 ], [ 29.755720862068966, -1.517075261538462 ], [ 29.755990344827588, -1.517075261538462 ], [ 29.755990344827588, -1.516536307692308 ], [ 29.755720862068966, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10194, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.516536307692308 ], [ 29.755990344827588, -1.517075261538462 ], [ 29.756259827586206, -1.517075261538462 ], [ 29.756259827586206, -1.516536307692308 ], [ 29.755990344827588, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10195, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.516536307692308 ], [ 29.756259827586206, -1.517075261538462 ], [ 29.75733775862069, -1.517075261538462 ], [ 29.75733775862069, -1.516536307692308 ], [ 29.756259827586206, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10196, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.516536307692308 ], [ 29.75733775862069, -1.517075261538462 ], [ 29.757607241379311, -1.517075261538462 ], [ 29.757607241379311, -1.516536307692308 ], [ 29.75733775862069, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10197, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.516536307692308 ], [ 29.757607241379311, -1.517075261538462 ], [ 29.758685172413795, -1.517075261538462 ], [ 29.758685172413795, -1.516536307692308 ], [ 29.757607241379311, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10198, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.516536307692308 ], [ 29.758685172413795, -1.517075261538462 ], [ 29.758954655172413, -1.517075261538462 ], [ 29.758954655172413, -1.516536307692308 ], [ 29.758685172413795, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10199, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.516536307692308 ], [ 29.758954655172413, -1.517075261538462 ], [ 29.759224137931035, -1.517075261538462 ], [ 29.759224137931035, -1.516536307692308 ], [ 29.758954655172413, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10200, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.516536307692308 ], [ 29.759224137931035, -1.517075261538462 ], [ 29.759493620689657, -1.517075261538462 ], [ 29.759493620689657, -1.516536307692308 ], [ 29.759224137931035, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10201, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.516536307692308 ], [ 29.759493620689657, -1.517075261538462 ], [ 29.759763103448275, -1.517075261538462 ], [ 29.759763103448275, -1.516536307692308 ], [ 29.759493620689657, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10202, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.516536307692308 ], [ 29.759763103448275, -1.517075261538462 ], [ 29.760032586206897, -1.517075261538462 ], [ 29.760032586206897, -1.516536307692308 ], [ 29.759763103448275, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10203, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.516536307692308 ], [ 29.760032586206897, -1.517075261538462 ], [ 29.760302068965519, -1.517075261538462 ], [ 29.760302068965519, -1.516536307692308 ], [ 29.760032586206897, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10204, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.516536307692308 ], [ 29.760302068965519, -1.517075261538462 ], [ 29.760571551724137, -1.517075261538462 ], [ 29.760571551724137, -1.516536307692308 ], [ 29.760302068965519, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10205, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.516536307692308 ], [ 29.760571551724137, -1.517075261538462 ], [ 29.760841034482759, -1.517075261538462 ], [ 29.760841034482759, -1.516536307692308 ], [ 29.760571551724137, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10206, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.516536307692308 ], [ 29.760841034482759, -1.517075261538462 ], [ 29.761110517241381, -1.517075261538462 ], [ 29.761110517241381, -1.516536307692308 ], [ 29.760841034482759, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10207, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.516536307692308 ], [ 29.761110517241381, -1.517075261538462 ], [ 29.76138, -1.517075261538462 ], [ 29.76138, -1.516536307692308 ], [ 29.761110517241381, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10208, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.516536307692308 ], [ 29.76138, -1.517075261538462 ], [ 29.761649482758621, -1.517075261538462 ], [ 29.761649482758621, -1.516536307692308 ], [ 29.76138, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10209, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.516536307692308 ], [ 29.761649482758621, -1.517075261538462 ], [ 29.761918965517243, -1.517075261538462 ], [ 29.761918965517243, -1.516536307692308 ], [ 29.761649482758621, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10210, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.516536307692308 ], [ 29.761918965517243, -1.517075261538462 ], [ 29.762188448275861, -1.517075261538462 ], [ 29.762188448275861, -1.516536307692308 ], [ 29.761918965517243, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10211, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.516536307692308 ], [ 29.762188448275861, -1.517075261538462 ], [ 29.762457931034483, -1.517075261538462 ], [ 29.762457931034483, -1.516536307692308 ], [ 29.762188448275861, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10212, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.516536307692308 ], [ 29.762457931034483, -1.517075261538462 ], [ 29.762727413793105, -1.517075261538462 ], [ 29.762727413793105, -1.516536307692308 ], [ 29.762457931034483, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10213, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.516536307692308 ], [ 29.766230689655174, -1.517075261538462 ], [ 29.766500172413792, -1.517075261538462 ], [ 29.766500172413792, -1.516536307692308 ], [ 29.766230689655174, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10214, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.516536307692308 ], [ 29.766500172413792, -1.517075261538462 ], [ 29.766769655172414, -1.517075261538462 ], [ 29.766769655172414, -1.516536307692308 ], [ 29.766500172413792, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10215, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.516536307692308 ], [ 29.766769655172414, -1.517075261538462 ], [ 29.767039137931036, -1.517075261538462 ], [ 29.767039137931036, -1.516536307692308 ], [ 29.766769655172414, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10216, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.516536307692308 ], [ 29.767039137931036, -1.517344738461538 ], [ 29.767308620689654, -1.517344738461538 ], [ 29.767308620689654, -1.516536307692308 ], [ 29.767039137931036, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10217, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.516536307692308 ], [ 29.767308620689654, -1.517075261538462 ], [ 29.767578103448276, -1.517075261538462 ], [ 29.767578103448276, -1.516536307692308 ], [ 29.767308620689654, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10218, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.517075261538462 ], [ 29.712603620689656, -1.517344738461538 ], [ 29.712873103448278, -1.517344738461538 ], [ 29.712873103448278, -1.517075261538462 ], [ 29.712603620689656, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10219, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.517075261538462 ], [ 29.72553879310345, -1.517614215384615 ], [ 29.725808275862068, -1.517614215384615 ], [ 29.725808275862068, -1.517075261538462 ], [ 29.72553879310345, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10220, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.517075261538462 ], [ 29.727964137931036, -1.517344738461538 ], [ 29.728233620689654, -1.517344738461538 ], [ 29.728233620689654, -1.517075261538462 ], [ 29.727964137931036, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10221, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.517075261538462 ], [ 29.728233620689654, -1.517344738461538 ], [ 29.728503103448276, -1.517344738461538 ], [ 29.728503103448276, -1.517075261538462 ], [ 29.728233620689654, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10222, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.517075261538462 ], [ 29.728503103448276, -1.517344738461538 ], [ 29.728772586206897, -1.517344738461538 ], [ 29.728772586206897, -1.517075261538462 ], [ 29.728503103448276, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10223, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.517075261538462 ], [ 29.728772586206897, -1.517344738461538 ], [ 29.729042068965516, -1.517344738461538 ], [ 29.729042068965516, -1.517075261538462 ], [ 29.728772586206897, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10224, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.517075261538462 ], [ 29.729042068965516, -1.517344738461538 ], [ 29.729311551724138, -1.517344738461538 ], [ 29.729311551724138, -1.517075261538462 ], [ 29.729042068965516, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10225, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.517075261538462 ], [ 29.729311551724138, -1.517344738461538 ], [ 29.729850517241381, -1.517344738461538 ], [ 29.729850517241381, -1.517075261538462 ], [ 29.729311551724138, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10226, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.517075261538462 ], [ 29.729850517241381, -1.517344738461538 ], [ 29.73012, -1.517344738461538 ], [ 29.73012, -1.517075261538462 ], [ 29.729850517241381, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10227, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.517075261538462 ], [ 29.73012, -1.517344738461538 ], [ 29.730389482758621, -1.517344738461538 ], [ 29.730389482758621, -1.517075261538462 ], [ 29.73012, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10228, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.517075261538462 ], [ 29.730389482758621, -1.517344738461538 ], [ 29.730928448275861, -1.517344738461538 ], [ 29.730928448275861, -1.517075261538462 ], [ 29.730389482758621, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10229, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.517075261538462 ], [ 29.730928448275861, -1.517344738461538 ], [ 29.731197931034483, -1.517344738461538 ], [ 29.731197931034483, -1.517075261538462 ], [ 29.730928448275861, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10230, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.517075261538462 ], [ 29.731197931034483, -1.517344738461538 ], [ 29.732006379310345, -1.517344738461538 ], [ 29.732006379310345, -1.517075261538462 ], [ 29.731197931034483, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10231, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.517075261538462 ], [ 29.732006379310345, -1.517344738461538 ], [ 29.732275862068967, -1.517344738461538 ], [ 29.732275862068967, -1.517075261538462 ], [ 29.732006379310345, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10232, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.517075261538462 ], [ 29.732275862068967, -1.517344738461538 ], [ 29.732545344827585, -1.517344738461538 ], [ 29.732545344827585, -1.517075261538462 ], [ 29.732275862068967, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10233, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.517075261538462 ], [ 29.732545344827585, -1.517344738461538 ], [ 29.732814827586207, -1.517344738461538 ], [ 29.732814827586207, -1.517075261538462 ], [ 29.732545344827585, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10234, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.517075261538462 ], [ 29.732814827586207, -1.517344738461538 ], [ 29.733084310344829, -1.517344738461538 ], [ 29.733084310344829, -1.517075261538462 ], [ 29.732814827586207, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10235, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.517075261538462 ], [ 29.733084310344829, -1.517344738461538 ], [ 29.733353793103447, -1.517344738461538 ], [ 29.733353793103447, -1.517075261538462 ], [ 29.733084310344829, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10236, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.517075261538462 ], [ 29.733353793103447, -1.517344738461538 ], [ 29.733623275862069, -1.517344738461538 ], [ 29.733623275862069, -1.517075261538462 ], [ 29.733353793103447, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10237, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.517075261538462 ], [ 29.733623275862069, -1.517344738461538 ], [ 29.733892758620691, -1.517344738461538 ], [ 29.733892758620691, -1.517075261538462 ], [ 29.733623275862069, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10238, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.517075261538462 ], [ 29.733892758620691, -1.517344738461538 ], [ 29.734162241379309, -1.517344738461538 ], [ 29.734162241379309, -1.517075261538462 ], [ 29.733892758620691, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10239, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.517075261538462 ], [ 29.734162241379309, -1.517344738461538 ], [ 29.734431724137931, -1.517344738461538 ], [ 29.734431724137931, -1.517075261538462 ], [ 29.734162241379309, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10240, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.517075261538462 ], [ 29.734431724137931, -1.517344738461538 ], [ 29.734701206896553, -1.517344738461538 ], [ 29.734701206896553, -1.517075261538462 ], [ 29.734431724137931, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10241, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.517075261538462 ], [ 29.734701206896553, -1.517344738461538 ], [ 29.734970689655171, -1.517344738461538 ], [ 29.734970689655171, -1.517075261538462 ], [ 29.734701206896553, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10242, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.517075261538462 ], [ 29.734970689655171, -1.517344738461538 ], [ 29.735240172413793, -1.517344738461538 ], [ 29.735240172413793, -1.517075261538462 ], [ 29.734970689655171, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10243, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.517075261538462 ], [ 29.735240172413793, -1.517344738461538 ], [ 29.735509655172415, -1.517344738461538 ], [ 29.735509655172415, -1.517075261538462 ], [ 29.735240172413793, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10244, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.517075261538462 ], [ 29.735509655172415, -1.517344738461538 ], [ 29.735779137931036, -1.517344738461538 ], [ 29.735779137931036, -1.517075261538462 ], [ 29.735509655172415, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10245, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.517075261538462 ], [ 29.735779137931036, -1.517344738461538 ], [ 29.736048620689655, -1.517344738461538 ], [ 29.736048620689655, -1.517075261538462 ], [ 29.735779137931036, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10246, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.517075261538462 ], [ 29.736048620689655, -1.517344738461538 ], [ 29.736318103448276, -1.517344738461538 ], [ 29.736318103448276, -1.517075261538462 ], [ 29.736048620689655, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10247, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.517075261538462 ], [ 29.736857068965517, -1.517344738461538 ], [ 29.737126551724138, -1.517344738461538 ], [ 29.737126551724138, -1.517075261538462 ], [ 29.736857068965517, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10248, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.517075261538462 ], [ 29.737126551724138, -1.517344738461538 ], [ 29.73739603448276, -1.517344738461538 ], [ 29.73739603448276, -1.517075261538462 ], [ 29.737126551724138, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10249, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.517075261538462 ], [ 29.73739603448276, -1.517344738461538 ], [ 29.737665517241378, -1.517344738461538 ], [ 29.737665517241378, -1.517075261538462 ], [ 29.73739603448276, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10250, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.517075261538462 ], [ 29.737665517241378, -1.517344738461538 ], [ 29.737935, -1.517344738461538 ], [ 29.737935, -1.517075261538462 ], [ 29.737665517241378, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10251, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.517075261538462 ], [ 29.737935, -1.517344738461538 ], [ 29.738204482758622, -1.517344738461538 ], [ 29.738204482758622, -1.517075261538462 ], [ 29.737935, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10252, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.517075261538462 ], [ 29.738204482758622, -1.517344738461538 ], [ 29.738743448275862, -1.517344738461538 ], [ 29.738743448275862, -1.517075261538462 ], [ 29.738204482758622, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10253, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.517075261538462 ], [ 29.738743448275862, -1.517344738461538 ], [ 29.739012931034484, -1.517344738461538 ], [ 29.739012931034484, -1.517075261538462 ], [ 29.738743448275862, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10254, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.517075261538462 ], [ 29.739012931034484, -1.517344738461538 ], [ 29.739282413793102, -1.517344738461538 ], [ 29.739282413793102, -1.517075261538462 ], [ 29.739012931034484, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10255, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.517075261538462 ], [ 29.739282413793102, -1.517344738461538 ], [ 29.739551896551724, -1.517344738461538 ], [ 29.739551896551724, -1.517075261538462 ], [ 29.739282413793102, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10256, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.517075261538462 ], [ 29.739551896551724, -1.517614215384615 ], [ 29.739821379310346, -1.517614215384615 ], [ 29.739821379310346, -1.517075261538462 ], [ 29.739551896551724, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10257, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.517075261538462 ], [ 29.739821379310346, -1.517344738461538 ], [ 29.740090862068964, -1.517344738461538 ], [ 29.740090862068964, -1.517075261538462 ], [ 29.739821379310346, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10258, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.517075261538462 ], [ 29.740090862068964, -1.517344738461538 ], [ 29.740360344827586, -1.517344738461538 ], [ 29.740360344827586, -1.517075261538462 ], [ 29.740090862068964, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10259, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.517075261538462 ], [ 29.740360344827586, -1.517344738461538 ], [ 29.740629827586208, -1.517344738461538 ], [ 29.740629827586208, -1.517075261538462 ], [ 29.740360344827586, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10260, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.517075261538462 ], [ 29.740629827586208, -1.517344738461538 ], [ 29.74089931034483, -1.517344738461538 ], [ 29.74089931034483, -1.517075261538462 ], [ 29.740629827586208, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10261, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.517075261538462 ], [ 29.74089931034483, -1.517344738461538 ], [ 29.741168793103448, -1.517344738461538 ], [ 29.741168793103448, -1.517075261538462 ], [ 29.74089931034483, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10262, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.517075261538462 ], [ 29.741168793103448, -1.517344738461538 ], [ 29.74143827586207, -1.517344738461538 ], [ 29.74143827586207, -1.517075261538462 ], [ 29.741168793103448, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10263, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.517075261538462 ], [ 29.74143827586207, -1.517344738461538 ], [ 29.741707758620691, -1.517344738461538 ], [ 29.741707758620691, -1.517075261538462 ], [ 29.74143827586207, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10264, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.517075261538462 ], [ 29.741707758620691, -1.517344738461538 ], [ 29.74197724137931, -1.517344738461538 ], [ 29.74197724137931, -1.517075261538462 ], [ 29.741707758620691, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10265, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.517075261538462 ], [ 29.74197724137931, -1.517344738461538 ], [ 29.742246724137932, -1.517344738461538 ], [ 29.742246724137932, -1.517075261538462 ], [ 29.74197724137931, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10266, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.517075261538462 ], [ 29.742246724137932, -1.517344738461538 ], [ 29.742516206896553, -1.517344738461538 ], [ 29.742516206896553, -1.517075261538462 ], [ 29.742246724137932, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10267, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.517075261538462 ], [ 29.742516206896553, -1.517344738461538 ], [ 29.742785689655172, -1.517344738461538 ], [ 29.742785689655172, -1.517075261538462 ], [ 29.742516206896553, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10268, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.517075261538462 ], [ 29.754373448275864, -1.517344738461538 ], [ 29.754642931034482, -1.517344738461538 ], [ 29.754642931034482, -1.517075261538462 ], [ 29.754373448275864, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10269, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.517075261538462 ], [ 29.754912413793104, -1.517883692307692 ], [ 29.755181896551726, -1.517883692307692 ], [ 29.755181896551726, -1.517075261538462 ], [ 29.754912413793104, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10270, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.517075261538462 ], [ 29.755451379310344, -1.517614215384615 ], [ 29.755720862068966, -1.517614215384615 ], [ 29.755720862068966, -1.517075261538462 ], [ 29.755451379310344, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10271, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.517075261538462 ], [ 29.755720862068966, -1.517344738461538 ], [ 29.755990344827588, -1.517344738461538 ], [ 29.755990344827588, -1.517075261538462 ], [ 29.755720862068966, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10272, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.517075261538462 ], [ 29.755990344827588, -1.517344738461538 ], [ 29.757068275862068, -1.517344738461538 ], [ 29.757068275862068, -1.517075261538462 ], [ 29.755990344827588, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10273, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.517075261538462 ], [ 29.757068275862068, -1.517344738461538 ], [ 29.75733775862069, -1.517344738461538 ], [ 29.75733775862069, -1.517075261538462 ], [ 29.757068275862068, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10274, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.517075261538462 ], [ 29.75733775862069, -1.517344738461538 ], [ 29.757607241379311, -1.517344738461538 ], [ 29.757607241379311, -1.517075261538462 ], [ 29.75733775862069, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10275, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.517075261538462 ], [ 29.757607241379311, -1.517344738461538 ], [ 29.758685172413795, -1.517344738461538 ], [ 29.758685172413795, -1.517075261538462 ], [ 29.757607241379311, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10276, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.517075261538462 ], [ 29.758685172413795, -1.517344738461538 ], [ 29.758954655172413, -1.517344738461538 ], [ 29.758954655172413, -1.517075261538462 ], [ 29.758685172413795, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10277, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.517075261538462 ], [ 29.758954655172413, -1.517344738461538 ], [ 29.759224137931035, -1.517344738461538 ], [ 29.759224137931035, -1.517075261538462 ], [ 29.758954655172413, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10278, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.517075261538462 ], [ 29.759224137931035, -1.517344738461538 ], [ 29.759493620689657, -1.517344738461538 ], [ 29.759493620689657, -1.517075261538462 ], [ 29.759224137931035, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10279, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.517075261538462 ], [ 29.759493620689657, -1.517344738461538 ], [ 29.759763103448275, -1.517344738461538 ], [ 29.759763103448275, -1.517075261538462 ], [ 29.759493620689657, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10280, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.517075261538462 ], [ 29.759763103448275, -1.517344738461538 ], [ 29.760032586206897, -1.517344738461538 ], [ 29.760032586206897, -1.517075261538462 ], [ 29.759763103448275, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10281, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.517075261538462 ], [ 29.760032586206897, -1.517344738461538 ], [ 29.760302068965519, -1.517344738461538 ], [ 29.760302068965519, -1.517075261538462 ], [ 29.760032586206897, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10282, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.517075261538462 ], [ 29.760302068965519, -1.517344738461538 ], [ 29.760571551724137, -1.517344738461538 ], [ 29.760571551724137, -1.517075261538462 ], [ 29.760302068965519, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10283, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.517075261538462 ], [ 29.760571551724137, -1.517344738461538 ], [ 29.760841034482759, -1.517344738461538 ], [ 29.760841034482759, -1.517075261538462 ], [ 29.760571551724137, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10284, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.517075261538462 ], [ 29.760841034482759, -1.517344738461538 ], [ 29.761110517241381, -1.517344738461538 ], [ 29.761110517241381, -1.517075261538462 ], [ 29.760841034482759, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10285, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.517075261538462 ], [ 29.761110517241381, -1.517344738461538 ], [ 29.76138, -1.517344738461538 ], [ 29.76138, -1.517075261538462 ], [ 29.761110517241381, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10286, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.517075261538462 ], [ 29.76138, -1.517344738461538 ], [ 29.761649482758621, -1.517344738461538 ], [ 29.761649482758621, -1.517075261538462 ], [ 29.76138, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10287, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.517075261538462 ], [ 29.761649482758621, -1.517344738461538 ], [ 29.761918965517243, -1.517344738461538 ], [ 29.761918965517243, -1.517075261538462 ], [ 29.761649482758621, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10288, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.517075261538462 ], [ 29.761918965517243, -1.517344738461538 ], [ 29.762188448275861, -1.517344738461538 ], [ 29.762188448275861, -1.517075261538462 ], [ 29.761918965517243, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10289, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.517075261538462 ], [ 29.762188448275861, -1.517344738461538 ], [ 29.762457931034483, -1.517344738461538 ], [ 29.762457931034483, -1.517075261538462 ], [ 29.762188448275861, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10290, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.516266830769231 ], [ 29.762996896551723, -1.516266830769231 ], [ 29.762996896551723, -1.517883692307692 ], [ 29.762727413793105, -1.517883692307692 ], [ 29.762727413793105, -1.517344738461538 ], [ 29.762457931034483, -1.517344738461538 ], [ 29.762457931034483, -1.517075261538462 ], [ 29.762727413793105, -1.517075261538462 ], [ 29.762727413793105, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10291, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.517075261538462 ], [ 29.767847586206898, -1.517344738461538 ], [ 29.768117068965516, -1.517344738461538 ], [ 29.768117068965516, -1.517075261538462 ], [ 29.767847586206898, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10292, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.517344738461538 ], [ 29.712603620689656, -1.517883692307692 ], [ 29.712873103448278, -1.517883692307692 ], [ 29.712873103448278, -1.517344738461538 ], [ 29.712603620689656, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10293, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.517344738461538 ], [ 29.72661672413793, -1.517614215384615 ], [ 29.726886206896552, -1.517614215384615 ], [ 29.726886206896552, -1.517344738461538 ], [ 29.72661672413793, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10294, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726886206896552, -1.517344738461538 ], [ 29.726886206896552, -1.517614215384615 ], [ 29.727964137931036, -1.517614215384615 ], [ 29.727964137931036, -1.517344738461538 ], [ 29.726886206896552, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10295, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.727964137931036, -1.517344738461538 ], [ 29.727964137931036, -1.517614215384615 ], [ 29.728233620689654, -1.517614215384615 ], [ 29.728233620689654, -1.517344738461538 ], [ 29.727964137931036, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10296, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.517344738461538 ], [ 29.728233620689654, -1.517614215384615 ], [ 29.728503103448276, -1.517614215384615 ], [ 29.728503103448276, -1.517344738461538 ], [ 29.728233620689654, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10297, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.517344738461538 ], [ 29.728503103448276, -1.517614215384615 ], [ 29.728772586206897, -1.517614215384615 ], [ 29.728772586206897, -1.517344738461538 ], [ 29.728503103448276, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10298, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.517344738461538 ], [ 29.728772586206897, -1.517614215384615 ], [ 29.729042068965516, -1.517614215384615 ], [ 29.729042068965516, -1.517344738461538 ], [ 29.728772586206897, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10299, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.517344738461538 ], [ 29.729042068965516, -1.517614215384615 ], [ 29.729311551724138, -1.517614215384615 ], [ 29.729311551724138, -1.517344738461538 ], [ 29.729042068965516, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10300, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.517344738461538 ], [ 29.729311551724138, -1.517614215384615 ], [ 29.729850517241381, -1.517614215384615 ], [ 29.729850517241381, -1.517344738461538 ], [ 29.729311551724138, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10301, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.517344738461538 ], [ 29.729850517241381, -1.517614215384615 ], [ 29.730389482758621, -1.517614215384615 ], [ 29.730389482758621, -1.517344738461538 ], [ 29.729850517241381, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10302, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.517344738461538 ], [ 29.730389482758621, -1.517614215384615 ], [ 29.730658965517243, -1.517614215384615 ], [ 29.730658965517243, -1.517344738461538 ], [ 29.730389482758621, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10303, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.517344738461538 ], [ 29.730658965517243, -1.517614215384615 ], [ 29.731197931034483, -1.517614215384615 ], [ 29.731197931034483, -1.517344738461538 ], [ 29.730658965517243, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10304, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.517344738461538 ], [ 29.731197931034483, -1.517614215384615 ], [ 29.732006379310345, -1.517614215384615 ], [ 29.732006379310345, -1.517344738461538 ], [ 29.731197931034483, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10305, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.517344738461538 ], [ 29.732006379310345, -1.517614215384615 ], [ 29.732275862068967, -1.517614215384615 ], [ 29.732275862068967, -1.517344738461538 ], [ 29.732006379310345, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10306, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.517344738461538 ], [ 29.732275862068967, -1.517614215384615 ], [ 29.732545344827585, -1.517614215384615 ], [ 29.732545344827585, -1.517344738461538 ], [ 29.732275862068967, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10307, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.517344738461538 ], [ 29.732545344827585, -1.517614215384615 ], [ 29.732814827586207, -1.517614215384615 ], [ 29.732814827586207, -1.517344738461538 ], [ 29.732545344827585, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10308, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.517344738461538 ], [ 29.732814827586207, -1.517614215384615 ], [ 29.733084310344829, -1.517614215384615 ], [ 29.733084310344829, -1.517344738461538 ], [ 29.732814827586207, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10309, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.517344738461538 ], [ 29.733084310344829, -1.517614215384615 ], [ 29.733353793103447, -1.517614215384615 ], [ 29.733353793103447, -1.517344738461538 ], [ 29.733084310344829, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10310, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.517344738461538 ], [ 29.733353793103447, -1.517614215384615 ], [ 29.733623275862069, -1.517614215384615 ], [ 29.733623275862069, -1.517344738461538 ], [ 29.733353793103447, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10311, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.517344738461538 ], [ 29.733623275862069, -1.517614215384615 ], [ 29.733892758620691, -1.517614215384615 ], [ 29.733892758620691, -1.517344738461538 ], [ 29.733623275862069, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10312, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.517344738461538 ], [ 29.733892758620691, -1.517614215384615 ], [ 29.734162241379309, -1.517614215384615 ], [ 29.734162241379309, -1.517344738461538 ], [ 29.733892758620691, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10313, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.517344738461538 ], [ 29.734162241379309, -1.517614215384615 ], [ 29.734431724137931, -1.517614215384615 ], [ 29.734431724137931, -1.517344738461538 ], [ 29.734162241379309, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10314, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.517344738461538 ], [ 29.734431724137931, -1.517614215384615 ], [ 29.734701206896553, -1.517614215384615 ], [ 29.734701206896553, -1.517344738461538 ], [ 29.734431724137931, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10315, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.517344738461538 ], [ 29.734701206896553, -1.517614215384615 ], [ 29.734970689655171, -1.517614215384615 ], [ 29.734970689655171, -1.517344738461538 ], [ 29.734701206896553, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10316, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.517344738461538 ], [ 29.734970689655171, -1.517614215384615 ], [ 29.735240172413793, -1.517614215384615 ], [ 29.735240172413793, -1.517344738461538 ], [ 29.734970689655171, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10317, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.517344738461538 ], [ 29.735240172413793, -1.517614215384615 ], [ 29.735509655172415, -1.517614215384615 ], [ 29.735509655172415, -1.517344738461538 ], [ 29.735240172413793, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10318, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.517344738461538 ], [ 29.735509655172415, -1.517614215384615 ], [ 29.735779137931036, -1.517614215384615 ], [ 29.735779137931036, -1.517344738461538 ], [ 29.735509655172415, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10319, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.517344738461538 ], [ 29.735779137931036, -1.517614215384615 ], [ 29.736048620689655, -1.517614215384615 ], [ 29.736048620689655, -1.517344738461538 ], [ 29.735779137931036, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10320, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.517344738461538 ], [ 29.736048620689655, -1.517614215384615 ], [ 29.736318103448276, -1.517614215384615 ], [ 29.736318103448276, -1.517344738461538 ], [ 29.736048620689655, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10321, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.517344738461538 ], [ 29.736318103448276, -1.517614215384615 ], [ 29.736587586206898, -1.517614215384615 ], [ 29.736587586206898, -1.517344738461538 ], [ 29.736318103448276, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10322, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.517344738461538 ], [ 29.736587586206898, -1.517614215384615 ], [ 29.736857068965517, -1.517614215384615 ], [ 29.736857068965517, -1.517344738461538 ], [ 29.736587586206898, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10323, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.517344738461538 ], [ 29.736857068965517, -1.517614215384615 ], [ 29.737126551724138, -1.517614215384615 ], [ 29.737126551724138, -1.517344738461538 ], [ 29.736857068965517, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10324, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.517344738461538 ], [ 29.737126551724138, -1.517614215384615 ], [ 29.73739603448276, -1.517614215384615 ], [ 29.73739603448276, -1.517344738461538 ], [ 29.737126551724138, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10325, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.517344738461538 ], [ 29.73739603448276, -1.517883692307692 ], [ 29.737665517241378, -1.517883692307692 ], [ 29.737665517241378, -1.517344738461538 ], [ 29.73739603448276, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10326, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.517344738461538 ], [ 29.737665517241378, -1.517883692307692 ], [ 29.737935, -1.517883692307692 ], [ 29.737935, -1.517344738461538 ], [ 29.737665517241378, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10327, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.517344738461538 ], [ 29.737935, -1.517883692307692 ], [ 29.738204482758622, -1.517883692307692 ], [ 29.738204482758622, -1.517344738461538 ], [ 29.737935, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10328, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.517344738461538 ], [ 29.738204482758622, -1.517614215384615 ], [ 29.738743448275862, -1.517614215384615 ], [ 29.738743448275862, -1.517344738461538 ], [ 29.738204482758622, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10329, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.517344738461538 ], [ 29.738743448275862, -1.517614215384615 ], [ 29.739012931034484, -1.517614215384615 ], [ 29.739012931034484, -1.517344738461538 ], [ 29.738743448275862, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10330, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.517344738461538 ], [ 29.739012931034484, -1.517614215384615 ], [ 29.739282413793102, -1.517614215384615 ], [ 29.739282413793102, -1.517344738461538 ], [ 29.739012931034484, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10331, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.517344738461538 ], [ 29.739282413793102, -1.517614215384615 ], [ 29.739551896551724, -1.517614215384615 ], [ 29.739551896551724, -1.517344738461538 ], [ 29.739282413793102, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10332, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.517344738461538 ], [ 29.739821379310346, -1.517614215384615 ], [ 29.740090862068964, -1.517614215384615 ], [ 29.740090862068964, -1.517344738461538 ], [ 29.739821379310346, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10333, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.517344738461538 ], [ 29.740090862068964, -1.517614215384615 ], [ 29.740360344827586, -1.517614215384615 ], [ 29.740360344827586, -1.517344738461538 ], [ 29.740090862068964, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10334, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.517344738461538 ], [ 29.740360344827586, -1.517614215384615 ], [ 29.740629827586208, -1.517614215384615 ], [ 29.740629827586208, -1.517344738461538 ], [ 29.740360344827586, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10335, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.517344738461538 ], [ 29.740629827586208, -1.517614215384615 ], [ 29.741168793103448, -1.517614215384615 ], [ 29.741168793103448, -1.517344738461538 ], [ 29.740629827586208, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10336, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.517344738461538 ], [ 29.741168793103448, -1.517614215384615 ], [ 29.74143827586207, -1.517614215384615 ], [ 29.74143827586207, -1.517344738461538 ], [ 29.741168793103448, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10337, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.517344738461538 ], [ 29.74143827586207, -1.517614215384615 ], [ 29.741707758620691, -1.517614215384615 ], [ 29.741707758620691, -1.517344738461538 ], [ 29.74143827586207, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10338, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.517344738461538 ], [ 29.741707758620691, -1.517614215384615 ], [ 29.74197724137931, -1.517614215384615 ], [ 29.74197724137931, -1.517344738461538 ], [ 29.741707758620691, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10339, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.517344738461538 ], [ 29.74197724137931, -1.517614215384615 ], [ 29.742246724137932, -1.517614215384615 ], [ 29.742246724137932, -1.517344738461538 ], [ 29.74197724137931, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10340, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.517344738461538 ], [ 29.742246724137932, -1.517614215384615 ], [ 29.742516206896553, -1.517614215384615 ], [ 29.742516206896553, -1.517344738461538 ], [ 29.742246724137932, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10341, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.517344738461538 ], [ 29.755181896551726, -1.517614215384615 ], [ 29.755451379310344, -1.517614215384615 ], [ 29.755451379310344, -1.517344738461538 ], [ 29.755181896551726, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10342, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.517344738461538 ], [ 29.755720862068966, -1.517614215384615 ], [ 29.755990344827588, -1.517614215384615 ], [ 29.755990344827588, -1.517344738461538 ], [ 29.755720862068966, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10343, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.517344738461538 ], [ 29.755990344827588, -1.517614215384615 ], [ 29.757068275862068, -1.517614215384615 ], [ 29.757068275862068, -1.517344738461538 ], [ 29.755990344827588, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10344, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.517344738461538 ], [ 29.757068275862068, -1.517614215384615 ], [ 29.75733775862069, -1.517614215384615 ], [ 29.75733775862069, -1.517344738461538 ], [ 29.757068275862068, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10345, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.517344738461538 ], [ 29.75733775862069, -1.517614215384615 ], [ 29.758415689655173, -1.517614215384615 ], [ 29.758415689655173, -1.517344738461538 ], [ 29.75733775862069, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10346, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.517344738461538 ], [ 29.758415689655173, -1.517614215384615 ], [ 29.758685172413795, -1.517614215384615 ], [ 29.758685172413795, -1.517344738461538 ], [ 29.758415689655173, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10347, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.517344738461538 ], [ 29.758685172413795, -1.517614215384615 ], [ 29.758954655172413, -1.517614215384615 ], [ 29.758954655172413, -1.517344738461538 ], [ 29.758685172413795, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10348, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.517344738461538 ], [ 29.758954655172413, -1.517614215384615 ], [ 29.759224137931035, -1.517614215384615 ], [ 29.759224137931035, -1.517344738461538 ], [ 29.758954655172413, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10349, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.517344738461538 ], [ 29.759224137931035, -1.517614215384615 ], [ 29.759493620689657, -1.517614215384615 ], [ 29.759493620689657, -1.517344738461538 ], [ 29.759224137931035, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10350, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.517344738461538 ], [ 29.759493620689657, -1.517614215384615 ], [ 29.759763103448275, -1.517614215384615 ], [ 29.759763103448275, -1.517344738461538 ], [ 29.759493620689657, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10351, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.517344738461538 ], [ 29.759763103448275, -1.517614215384615 ], [ 29.760032586206897, -1.517614215384615 ], [ 29.760032586206897, -1.517344738461538 ], [ 29.759763103448275, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10352, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.517344738461538 ], [ 29.760032586206897, -1.517614215384615 ], [ 29.760302068965519, -1.517614215384615 ], [ 29.760302068965519, -1.517344738461538 ], [ 29.760032586206897, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10353, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.517344738461538 ], [ 29.760302068965519, -1.517614215384615 ], [ 29.760571551724137, -1.517614215384615 ], [ 29.760571551724137, -1.517344738461538 ], [ 29.760302068965519, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10354, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.517344738461538 ], [ 29.760571551724137, -1.517614215384615 ], [ 29.760841034482759, -1.517614215384615 ], [ 29.760841034482759, -1.517344738461538 ], [ 29.760571551724137, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10355, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.517344738461538 ], [ 29.760841034482759, -1.517614215384615 ], [ 29.761110517241381, -1.517614215384615 ], [ 29.761110517241381, -1.517344738461538 ], [ 29.760841034482759, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10356, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.517344738461538 ], [ 29.761110517241381, -1.517614215384615 ], [ 29.76138, -1.517614215384615 ], [ 29.76138, -1.517344738461538 ], [ 29.761110517241381, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10357, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.517344738461538 ], [ 29.76138, -1.517614215384615 ], [ 29.761649482758621, -1.517614215384615 ], [ 29.761649482758621, -1.517344738461538 ], [ 29.76138, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10358, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.517344738461538 ], [ 29.761649482758621, -1.517614215384615 ], [ 29.761918965517243, -1.517614215384615 ], [ 29.761918965517243, -1.517344738461538 ], [ 29.761649482758621, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10359, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.517344738461538 ], [ 29.761918965517243, -1.517883692307692 ], [ 29.762188448275861, -1.517883692307692 ], [ 29.762188448275861, -1.517344738461538 ], [ 29.761918965517243, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10360, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.517344738461538 ], [ 29.762188448275861, -1.517614215384615 ], [ 29.762457931034483, -1.517614215384615 ], [ 29.762457931034483, -1.517344738461538 ], [ 29.762188448275861, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10361, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.517344738461538 ], [ 29.762457931034483, -1.517614215384615 ], [ 29.762727413793105, -1.517614215384615 ], [ 29.762727413793105, -1.517344738461538 ], [ 29.762457931034483, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10362, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.517344738461538 ], [ 29.763266379310345, -1.517883692307692 ], [ 29.763535862068967, -1.517883692307692 ], [ 29.763535862068967, -1.517344738461538 ], [ 29.763266379310345, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10363, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.517344738461538 ], [ 29.767039137931036, -1.517883692307692 ], [ 29.767308620689654, -1.517883692307692 ], [ 29.767308620689654, -1.517344738461538 ], [ 29.767039137931036, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10364, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.517344738461538 ], [ 29.767847586206898, -1.517883692307692 ], [ 29.768117068965516, -1.517883692307692 ], [ 29.768117068965516, -1.517344738461538 ], [ 29.767847586206898, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10365, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.517614215384615 ], [ 29.72553879310345, -1.517883692307692 ], [ 29.725808275862068, -1.517883692307692 ], [ 29.725808275862068, -1.517614215384615 ], [ 29.72553879310345, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10366, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.517614215384615 ], [ 29.72607775862069, -1.517883692307692 ], [ 29.726347241379312, -1.517883692307692 ], [ 29.726347241379312, -1.517614215384615 ], [ 29.72607775862069, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10367, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.517614215384615 ], [ 29.726347241379312, -1.517883692307692 ], [ 29.72661672413793, -1.517883692307692 ], [ 29.72661672413793, -1.517614215384615 ], [ 29.726347241379312, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10368, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72661672413793, -1.517614215384615 ], [ 29.72661672413793, -1.517883692307692 ], [ 29.728233620689654, -1.517883692307692 ], [ 29.728233620689654, -1.517614215384615 ], [ 29.72661672413793, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10369, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.517614215384615 ], [ 29.728233620689654, -1.517883692307692 ], [ 29.728503103448276, -1.517883692307692 ], [ 29.728503103448276, -1.517614215384615 ], [ 29.728233620689654, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10370, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.517614215384615 ], [ 29.728503103448276, -1.517883692307692 ], [ 29.728772586206897, -1.517883692307692 ], [ 29.728772586206897, -1.517614215384615 ], [ 29.728503103448276, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10371, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.517614215384615 ], [ 29.728772586206897, -1.517883692307692 ], [ 29.729042068965516, -1.517883692307692 ], [ 29.729042068965516, -1.517614215384615 ], [ 29.728772586206897, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10372, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.517614215384615 ], [ 29.729042068965516, -1.517883692307692 ], [ 29.729311551724138, -1.517883692307692 ], [ 29.729311551724138, -1.517614215384615 ], [ 29.729042068965516, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10373, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.517614215384615 ], [ 29.729311551724138, -1.517883692307692 ], [ 29.729850517241381, -1.517883692307692 ], [ 29.729850517241381, -1.517614215384615 ], [ 29.729311551724138, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10374, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.517614215384615 ], [ 29.729850517241381, -1.517883692307692 ], [ 29.73012, -1.517883692307692 ], [ 29.73012, -1.517614215384615 ], [ 29.729850517241381, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10375, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.517614215384615 ], [ 29.73012, -1.517883692307692 ], [ 29.730658965517243, -1.517883692307692 ], [ 29.730658965517243, -1.517614215384615 ], [ 29.73012, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10376, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.517614215384615 ], [ 29.730658965517243, -1.517883692307692 ], [ 29.731197931034483, -1.517883692307692 ], [ 29.731197931034483, -1.517614215384615 ], [ 29.730658965517243, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10377, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731197931034483, -1.517614215384615 ], [ 29.731197931034483, -1.517883692307692 ], [ 29.731467413793105, -1.517883692307692 ], [ 29.731467413793105, -1.517614215384615 ], [ 29.731197931034483, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10378, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731467413793105, -1.517614215384615 ], [ 29.731467413793105, -1.517883692307692 ], [ 29.731736896551723, -1.517883692307692 ], [ 29.731736896551723, -1.517614215384615 ], [ 29.731467413793105, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10379, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.517614215384615 ], [ 29.731736896551723, -1.517883692307692 ], [ 29.732006379310345, -1.517883692307692 ], [ 29.732006379310345, -1.517614215384615 ], [ 29.731736896551723, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10380, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.517614215384615 ], [ 29.732006379310345, -1.517883692307692 ], [ 29.732275862068967, -1.517883692307692 ], [ 29.732275862068967, -1.517614215384615 ], [ 29.732006379310345, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10381, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.517614215384615 ], [ 29.732275862068967, -1.517883692307692 ], [ 29.732545344827585, -1.517883692307692 ], [ 29.732545344827585, -1.517614215384615 ], [ 29.732275862068967, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10382, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.517614215384615 ], [ 29.732545344827585, -1.517883692307692 ], [ 29.732814827586207, -1.517883692307692 ], [ 29.732814827586207, -1.517614215384615 ], [ 29.732545344827585, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10383, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.517614215384615 ], [ 29.732814827586207, -1.517883692307692 ], [ 29.733084310344829, -1.517883692307692 ], [ 29.733084310344829, -1.517614215384615 ], [ 29.732814827586207, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10384, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.517614215384615 ], [ 29.733084310344829, -1.517883692307692 ], [ 29.733353793103447, -1.517883692307692 ], [ 29.733353793103447, -1.517614215384615 ], [ 29.733084310344829, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10385, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.517614215384615 ], [ 29.733353793103447, -1.517883692307692 ], [ 29.733623275862069, -1.517883692307692 ], [ 29.733623275862069, -1.517614215384615 ], [ 29.733353793103447, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10386, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.517614215384615 ], [ 29.733623275862069, -1.517883692307692 ], [ 29.733892758620691, -1.517883692307692 ], [ 29.733892758620691, -1.517614215384615 ], [ 29.733623275862069, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10387, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.517614215384615 ], [ 29.733892758620691, -1.517883692307692 ], [ 29.734162241379309, -1.517883692307692 ], [ 29.734162241379309, -1.517614215384615 ], [ 29.733892758620691, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10388, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.517614215384615 ], [ 29.734162241379309, -1.517883692307692 ], [ 29.734431724137931, -1.517883692307692 ], [ 29.734431724137931, -1.517614215384615 ], [ 29.734162241379309, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10389, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.517614215384615 ], [ 29.734431724137931, -1.517883692307692 ], [ 29.734701206896553, -1.517883692307692 ], [ 29.734701206896553, -1.517614215384615 ], [ 29.734431724137931, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10390, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.517614215384615 ], [ 29.734701206896553, -1.517883692307692 ], [ 29.734970689655171, -1.517883692307692 ], [ 29.734970689655171, -1.517614215384615 ], [ 29.734701206896553, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10391, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.517614215384615 ], [ 29.734970689655171, -1.517883692307692 ], [ 29.735240172413793, -1.517883692307692 ], [ 29.735240172413793, -1.517614215384615 ], [ 29.734970689655171, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10392, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.517614215384615 ], [ 29.735240172413793, -1.517883692307692 ], [ 29.735509655172415, -1.517883692307692 ], [ 29.735509655172415, -1.517614215384615 ], [ 29.735240172413793, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10393, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.517614215384615 ], [ 29.735509655172415, -1.517883692307692 ], [ 29.735779137931036, -1.517883692307692 ], [ 29.735779137931036, -1.517614215384615 ], [ 29.735509655172415, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10394, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.517614215384615 ], [ 29.735779137931036, -1.517883692307692 ], [ 29.736048620689655, -1.517883692307692 ], [ 29.736048620689655, -1.517614215384615 ], [ 29.735779137931036, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10395, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.517614215384615 ], [ 29.736048620689655, -1.517883692307692 ], [ 29.736318103448276, -1.517883692307692 ], [ 29.736318103448276, -1.517614215384615 ], [ 29.736048620689655, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10396, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.517614215384615 ], [ 29.736318103448276, -1.517883692307692 ], [ 29.736587586206898, -1.517883692307692 ], [ 29.736587586206898, -1.517614215384615 ], [ 29.736318103448276, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10397, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.517614215384615 ], [ 29.736587586206898, -1.517883692307692 ], [ 29.736857068965517, -1.517883692307692 ], [ 29.736857068965517, -1.517614215384615 ], [ 29.736587586206898, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10398, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.517614215384615 ], [ 29.736857068965517, -1.517883692307692 ], [ 29.737126551724138, -1.517883692307692 ], [ 29.737126551724138, -1.517614215384615 ], [ 29.736857068965517, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10399, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.517614215384615 ], [ 29.738204482758622, -1.517883692307692 ], [ 29.738743448275862, -1.517883692307692 ], [ 29.738743448275862, -1.517614215384615 ], [ 29.738204482758622, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10400, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.517614215384615 ], [ 29.738743448275862, -1.517883692307692 ], [ 29.739012931034484, -1.517883692307692 ], [ 29.739012931034484, -1.517614215384615 ], [ 29.738743448275862, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10401, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.517614215384615 ], [ 29.739012931034484, -1.517883692307692 ], [ 29.739282413793102, -1.517883692307692 ], [ 29.739282413793102, -1.517614215384615 ], [ 29.739012931034484, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10402, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.517614215384615 ], [ 29.739282413793102, -1.517883692307692 ], [ 29.739551896551724, -1.517883692307692 ], [ 29.739551896551724, -1.517614215384615 ], [ 29.739282413793102, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10403, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.517614215384615 ], [ 29.739551896551724, -1.517883692307692 ], [ 29.739821379310346, -1.517883692307692 ], [ 29.739821379310346, -1.517614215384615 ], [ 29.739551896551724, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10404, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.517614215384615 ], [ 29.739821379310346, -1.517883692307692 ], [ 29.740090862068964, -1.517883692307692 ], [ 29.740090862068964, -1.517614215384615 ], [ 29.739821379310346, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10405, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.517614215384615 ], [ 29.740090862068964, -1.517883692307692 ], [ 29.740360344827586, -1.517883692307692 ], [ 29.740360344827586, -1.517614215384615 ], [ 29.740090862068964, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10406, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.517614215384615 ], [ 29.740360344827586, -1.517883692307692 ], [ 29.740629827586208, -1.517883692307692 ], [ 29.740629827586208, -1.517614215384615 ], [ 29.740360344827586, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10407, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.517614215384615 ], [ 29.740629827586208, -1.517883692307692 ], [ 29.74089931034483, -1.517883692307692 ], [ 29.74089931034483, -1.517614215384615 ], [ 29.740629827586208, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10408, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.517614215384615 ], [ 29.74089931034483, -1.517883692307692 ], [ 29.74143827586207, -1.517883692307692 ], [ 29.74143827586207, -1.517614215384615 ], [ 29.74089931034483, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10409, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.517614215384615 ], [ 29.74143827586207, -1.517883692307692 ], [ 29.741707758620691, -1.517883692307692 ], [ 29.741707758620691, -1.517614215384615 ], [ 29.74143827586207, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10410, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.517614215384615 ], [ 29.741707758620691, -1.517883692307692 ], [ 29.74197724137931, -1.517883692307692 ], [ 29.74197724137931, -1.517614215384615 ], [ 29.741707758620691, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10411, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.517614215384615 ], [ 29.74197724137931, -1.517883692307692 ], [ 29.742246724137932, -1.517883692307692 ], [ 29.742246724137932, -1.517614215384615 ], [ 29.74197724137931, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10412, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.517614215384615 ], [ 29.755181896551726, -1.517883692307692 ], [ 29.755451379310344, -1.517883692307692 ], [ 29.755451379310344, -1.517614215384615 ], [ 29.755181896551726, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10413, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.517614215384615 ], [ 29.755451379310344, -1.517883692307692 ], [ 29.755990344827588, -1.517883692307692 ], [ 29.755990344827588, -1.517614215384615 ], [ 29.755451379310344, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10414, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.517614215384615 ], [ 29.755990344827588, -1.517883692307692 ], [ 29.757068275862068, -1.517883692307692 ], [ 29.757068275862068, -1.517614215384615 ], [ 29.755990344827588, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10415, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.517614215384615 ], [ 29.757068275862068, -1.517883692307692 ], [ 29.75733775862069, -1.517883692307692 ], [ 29.75733775862069, -1.517614215384615 ], [ 29.757068275862068, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10416, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.517614215384615 ], [ 29.75733775862069, -1.517883692307692 ], [ 29.758415689655173, -1.517883692307692 ], [ 29.758415689655173, -1.517614215384615 ], [ 29.75733775862069, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10417, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.517614215384615 ], [ 29.758415689655173, -1.517883692307692 ], [ 29.758685172413795, -1.517883692307692 ], [ 29.758685172413795, -1.517614215384615 ], [ 29.758415689655173, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10418, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.517614215384615 ], [ 29.758685172413795, -1.517883692307692 ], [ 29.758954655172413, -1.517883692307692 ], [ 29.758954655172413, -1.517614215384615 ], [ 29.758685172413795, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10419, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.517614215384615 ], [ 29.758954655172413, -1.517883692307692 ], [ 29.759224137931035, -1.517883692307692 ], [ 29.759224137931035, -1.517614215384615 ], [ 29.758954655172413, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10420, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.517614215384615 ], [ 29.759224137931035, -1.517883692307692 ], [ 29.759493620689657, -1.517883692307692 ], [ 29.759493620689657, -1.517614215384615 ], [ 29.759224137931035, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10421, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.517614215384615 ], [ 29.759493620689657, -1.517883692307692 ], [ 29.759763103448275, -1.517883692307692 ], [ 29.759763103448275, -1.517614215384615 ], [ 29.759493620689657, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10422, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.517614215384615 ], [ 29.759763103448275, -1.517883692307692 ], [ 29.760032586206897, -1.517883692307692 ], [ 29.760032586206897, -1.517614215384615 ], [ 29.759763103448275, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10423, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.517614215384615 ], [ 29.760032586206897, -1.517883692307692 ], [ 29.760302068965519, -1.517883692307692 ], [ 29.760302068965519, -1.517614215384615 ], [ 29.760032586206897, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10424, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.517614215384615 ], [ 29.760302068965519, -1.517883692307692 ], [ 29.760571551724137, -1.517883692307692 ], [ 29.760571551724137, -1.517614215384615 ], [ 29.760302068965519, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10425, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.517614215384615 ], [ 29.760571551724137, -1.517883692307692 ], [ 29.760841034482759, -1.517883692307692 ], [ 29.760841034482759, -1.517614215384615 ], [ 29.760571551724137, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10426, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.517614215384615 ], [ 29.760841034482759, -1.517883692307692 ], [ 29.761110517241381, -1.517883692307692 ], [ 29.761110517241381, -1.517614215384615 ], [ 29.760841034482759, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10427, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.517614215384615 ], [ 29.761110517241381, -1.517883692307692 ], [ 29.76138, -1.517883692307692 ], [ 29.76138, -1.517614215384615 ], [ 29.761110517241381, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10428, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.517614215384615 ], [ 29.76138, -1.517883692307692 ], [ 29.761649482758621, -1.517883692307692 ], [ 29.761649482758621, -1.517614215384615 ], [ 29.76138, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10429, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.517614215384615 ], [ 29.762188448275861, -1.517883692307692 ], [ 29.762457931034483, -1.517883692307692 ], [ 29.762457931034483, -1.517614215384615 ], [ 29.762188448275861, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10430, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.517614215384615 ], [ 29.762457931034483, -1.517883692307692 ], [ 29.762727413793105, -1.517883692307692 ], [ 29.762727413793105, -1.517614215384615 ], [ 29.762457931034483, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10431, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711525689655172, -1.5154584 ], [ 29.711525689655172, -1.519231076923077 ], [ 29.711795172413794, -1.519231076923077 ], [ 29.711795172413794, -1.5154584 ], [ 29.711525689655172, -1.5154584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10432, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712064655172412, -1.511416246153846 ], [ 29.712603620689656, -1.511416246153846 ], [ 29.712603620689656, -1.511685723076923 ], [ 29.712334137931034, -1.511685723076923 ], [ 29.712334137931034, -1.5154584 ], [ 29.712873103448278, -1.5154584 ], [ 29.712873103448278, -1.515727876923077 ], [ 29.712334137931034, -1.515727876923077 ], [ 29.712334137931034, -1.516536307692308 ], [ 29.712064655172412, -1.516536307692308 ], [ 29.712064655172412, -1.518692123076923 ], [ 29.712334137931034, -1.518692123076923 ], [ 29.712334137931034, -1.519770030769231 ], [ 29.712064655172412, -1.519770030769231 ], [ 29.712064655172412, -1.519500553846154 ], [ 29.711795172413794, -1.519500553846154 ], [ 29.711795172413794, -1.515727876923077 ], [ 29.712064655172412, -1.515727876923077 ], [ 29.712064655172412, -1.511416246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10433, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.515997353846154 ], [ 29.764074827586207, -1.518692123076923 ], [ 29.764344310344828, -1.518692123076923 ], [ 29.764344310344828, -1.515997353846154 ], [ 29.764074827586207, -1.515997353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10434, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.516266830769231 ], [ 29.764883275862069, -1.518692123076923 ], [ 29.76515275862069, -1.518692123076923 ], [ 29.76515275862069, -1.516266830769231 ], [ 29.764883275862069, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10435, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712064655172412, -1.516536307692308 ], [ 29.712064655172412, -1.518692123076923 ], [ 29.712334137931034, -1.518692123076923 ], [ 29.712334137931034, -1.517883692307692 ], [ 29.712603620689656, -1.517883692307692 ], [ 29.712603620689656, -1.517344738461538 ], [ 29.712334137931034, -1.517344738461538 ], [ 29.712334137931034, -1.516536307692308 ], [ 29.712064655172412, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10436, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.516536307692308 ], [ 29.763535862068967, -1.518422646153846 ], [ 29.763805344827588, -1.518422646153846 ], [ 29.763805344827588, -1.516536307692308 ], [ 29.763535862068967, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10437, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.516536307692308 ], [ 29.763805344827588, -1.518422646153846 ], [ 29.764074827586207, -1.518422646153846 ], [ 29.764074827586207, -1.516536307692308 ], [ 29.763805344827588, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10438, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.516536307692308 ], [ 29.76461379310345, -1.518422646153846 ], [ 29.764883275862069, -1.518422646153846 ], [ 29.764883275862069, -1.516536307692308 ], [ 29.76461379310345, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10439, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.516536307692308 ], [ 29.765422241379312, -1.518422646153846 ], [ 29.765961206896552, -1.518422646153846 ], [ 29.765961206896552, -1.516536307692308 ], [ 29.765422241379312, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10440, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.516536307692308 ], [ 29.765961206896552, -1.518422646153846 ], [ 29.766230689655174, -1.518422646153846 ], [ 29.766230689655174, -1.516536307692308 ], [ 29.765961206896552, -1.516536307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10441, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.517075261538462 ], [ 29.712873103448278, -1.518153169230769 ], [ 29.713142586206896, -1.518153169230769 ], [ 29.713142586206896, -1.517075261538462 ], [ 29.712873103448278, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10442, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724730344827588, -1.517075261538462 ], [ 29.724730344827588, -1.518153169230769 ], [ 29.724999827586206, -1.518153169230769 ], [ 29.724999827586206, -1.517075261538462 ], [ 29.724730344827588, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10443, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.517075261538462 ], [ 29.754642931034482, -1.518153169230769 ], [ 29.755181896551726, -1.518153169230769 ], [ 29.755181896551726, -1.517883692307692 ], [ 29.754912413793104, -1.517883692307692 ], [ 29.754912413793104, -1.517075261538462 ], [ 29.754642931034482, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10444, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.517075261538462 ], [ 29.764344310344828, -1.518153169230769 ], [ 29.76461379310345, -1.518153169230769 ], [ 29.76461379310345, -1.517075261538462 ], [ 29.764344310344828, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10445, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.517075261538462 ], [ 29.76515275862069, -1.518153169230769 ], [ 29.765422241379312, -1.518153169230769 ], [ 29.765422241379312, -1.517075261538462 ], [ 29.76515275862069, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10446, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.517075261538462 ], [ 29.766230689655174, -1.518153169230769 ], [ 29.766500172413792, -1.518153169230769 ], [ 29.766500172413792, -1.517075261538462 ], [ 29.766230689655174, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10447, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.517075261538462 ], [ 29.766500172413792, -1.518153169230769 ], [ 29.766769655172414, -1.518153169230769 ], [ 29.766769655172414, -1.517075261538462 ], [ 29.766500172413792, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10448, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.517075261538462 ], [ 29.766769655172414, -1.518153169230769 ], [ 29.767039137931036, -1.518153169230769 ], [ 29.767039137931036, -1.517075261538462 ], [ 29.766769655172414, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10449, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.517075261538462 ], [ 29.767308620689654, -1.518153169230769 ], [ 29.767578103448276, -1.518153169230769 ], [ 29.767578103448276, -1.517075261538462 ], [ 29.767308620689654, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10450, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.517075261538462 ], [ 29.767578103448276, -1.518153169230769 ], [ 29.767847586206898, -1.518153169230769 ], [ 29.767847586206898, -1.517075261538462 ], [ 29.767578103448276, -1.517075261538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10451, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.517344738461538 ], [ 29.754373448275864, -1.518422646153846 ], [ 29.754912413793104, -1.518422646153846 ], [ 29.754912413793104, -1.518153169230769 ], [ 29.754642931034482, -1.518153169230769 ], [ 29.754642931034482, -1.517344738461538 ], [ 29.754373448275864, -1.517344738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10452, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.517614215384615 ], [ 29.725808275862068, -1.518153169230769 ], [ 29.72607775862069, -1.518153169230769 ], [ 29.72607775862069, -1.517614215384615 ], [ 29.725808275862068, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10453, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.517614215384615 ], [ 29.737126551724138, -1.518153169230769 ], [ 29.73739603448276, -1.518153169230769 ], [ 29.73739603448276, -1.517614215384615 ], [ 29.737126551724138, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10454, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.517614215384615 ], [ 29.761649482758621, -1.518153169230769 ], [ 29.761918965517243, -1.518153169230769 ], [ 29.761918965517243, -1.517614215384615 ], [ 29.761649482758621, -1.517614215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10455, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.517883692307692 ], [ 29.712334137931034, -1.518692123076923 ], [ 29.712603620689656, -1.518692123076923 ], [ 29.712603620689656, -1.517883692307692 ], [ 29.712334137931034, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10456, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.517883692307692 ], [ 29.712603620689656, -1.518153169230769 ], [ 29.712873103448278, -1.518153169230769 ], [ 29.712873103448278, -1.517883692307692 ], [ 29.712603620689656, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10457, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.517883692307692 ], [ 29.724999827586206, -1.518153169230769 ], [ 29.725269310344828, -1.518153169230769 ], [ 29.725269310344828, -1.517883692307692 ], [ 29.724999827586206, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10458, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.517883692307692 ], [ 29.725269310344828, -1.518692123076923 ], [ 29.72553879310345, -1.518692123076923 ], [ 29.72553879310345, -1.517883692307692 ], [ 29.725269310344828, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10459, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.517883692307692 ], [ 29.72553879310345, -1.518692123076923 ], [ 29.72607775862069, -1.518692123076923 ], [ 29.72607775862069, -1.518422646153846 ], [ 29.725808275862068, -1.518422646153846 ], [ 29.725808275862068, -1.517883692307692 ], [ 29.72553879310345, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10460, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.517883692307692 ], [ 29.72607775862069, -1.518153169230769 ], [ 29.726347241379312, -1.518153169230769 ], [ 29.726347241379312, -1.517883692307692 ], [ 29.72607775862069, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10461, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.517883692307692 ], [ 29.726347241379312, -1.518153169230769 ], [ 29.728233620689654, -1.518153169230769 ], [ 29.728233620689654, -1.517883692307692 ], [ 29.726347241379312, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10462, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728233620689654, -1.517883692307692 ], [ 29.728233620689654, -1.518153169230769 ], [ 29.728503103448276, -1.518153169230769 ], [ 29.728503103448276, -1.517883692307692 ], [ 29.728233620689654, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10463, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.517883692307692 ], [ 29.728503103448276, -1.518153169230769 ], [ 29.728772586206897, -1.518153169230769 ], [ 29.728772586206897, -1.517883692307692 ], [ 29.728503103448276, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10464, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.517883692307692 ], [ 29.728772586206897, -1.518153169230769 ], [ 29.729311551724138, -1.518153169230769 ], [ 29.729311551724138, -1.517883692307692 ], [ 29.728772586206897, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10465, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.517883692307692 ], [ 29.729311551724138, -1.518153169230769 ], [ 29.729850517241381, -1.518153169230769 ], [ 29.729850517241381, -1.517883692307692 ], [ 29.729311551724138, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10466, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.517883692307692 ], [ 29.729850517241381, -1.518153169230769 ], [ 29.73012, -1.518153169230769 ], [ 29.73012, -1.517883692307692 ], [ 29.729850517241381, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10467, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.517883692307692 ], [ 29.73012, -1.518153169230769 ], [ 29.730658965517243, -1.518153169230769 ], [ 29.730658965517243, -1.517883692307692 ], [ 29.73012, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10468, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730658965517243, -1.517883692307692 ], [ 29.730658965517243, -1.518153169230769 ], [ 29.730928448275861, -1.518153169230769 ], [ 29.730928448275861, -1.517883692307692 ], [ 29.730658965517243, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10469, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730928448275861, -1.517883692307692 ], [ 29.730928448275861, -1.518153169230769 ], [ 29.731467413793105, -1.518153169230769 ], [ 29.731467413793105, -1.517883692307692 ], [ 29.730928448275861, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10470, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.731736896551723, -1.517883692307692 ], [ 29.731736896551723, -1.518153169230769 ], [ 29.732006379310345, -1.518153169230769 ], [ 29.732006379310345, -1.517883692307692 ], [ 29.731736896551723, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10471, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.517883692307692 ], [ 29.732006379310345, -1.518153169230769 ], [ 29.732275862068967, -1.518153169230769 ], [ 29.732275862068967, -1.517883692307692 ], [ 29.732006379310345, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10472, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.517883692307692 ], [ 29.732275862068967, -1.518153169230769 ], [ 29.732545344827585, -1.518153169230769 ], [ 29.732545344827585, -1.517883692307692 ], [ 29.732275862068967, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10473, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.517883692307692 ], [ 29.732545344827585, -1.518153169230769 ], [ 29.732814827586207, -1.518153169230769 ], [ 29.732814827586207, -1.517883692307692 ], [ 29.732545344827585, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10474, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.517883692307692 ], [ 29.732814827586207, -1.518153169230769 ], [ 29.733084310344829, -1.518153169230769 ], [ 29.733084310344829, -1.517883692307692 ], [ 29.732814827586207, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10475, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.517883692307692 ], [ 29.733084310344829, -1.518153169230769 ], [ 29.733353793103447, -1.518153169230769 ], [ 29.733353793103447, -1.517883692307692 ], [ 29.733084310344829, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10476, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.517883692307692 ], [ 29.733353793103447, -1.518153169230769 ], [ 29.733623275862069, -1.518153169230769 ], [ 29.733623275862069, -1.517883692307692 ], [ 29.733353793103447, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10477, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.517883692307692 ], [ 29.733623275862069, -1.518153169230769 ], [ 29.733892758620691, -1.518153169230769 ], [ 29.733892758620691, -1.517883692307692 ], [ 29.733623275862069, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10478, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.517883692307692 ], [ 29.733892758620691, -1.518153169230769 ], [ 29.734162241379309, -1.518153169230769 ], [ 29.734162241379309, -1.517883692307692 ], [ 29.733892758620691, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10479, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.517883692307692 ], [ 29.734162241379309, -1.518153169230769 ], [ 29.734431724137931, -1.518153169230769 ], [ 29.734431724137931, -1.517883692307692 ], [ 29.734162241379309, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10480, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.517883692307692 ], [ 29.734431724137931, -1.518153169230769 ], [ 29.734701206896553, -1.518153169230769 ], [ 29.734701206896553, -1.517883692307692 ], [ 29.734431724137931, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10481, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.517883692307692 ], [ 29.734701206896553, -1.518153169230769 ], [ 29.734970689655171, -1.518153169230769 ], [ 29.734970689655171, -1.517883692307692 ], [ 29.734701206896553, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10482, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.517883692307692 ], [ 29.734970689655171, -1.518153169230769 ], [ 29.735240172413793, -1.518153169230769 ], [ 29.735240172413793, -1.517883692307692 ], [ 29.734970689655171, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10483, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.517883692307692 ], [ 29.735240172413793, -1.518153169230769 ], [ 29.735509655172415, -1.518153169230769 ], [ 29.735509655172415, -1.517883692307692 ], [ 29.735240172413793, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10484, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.517883692307692 ], [ 29.735509655172415, -1.518153169230769 ], [ 29.735779137931036, -1.518153169230769 ], [ 29.735779137931036, -1.517883692307692 ], [ 29.735509655172415, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10485, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.517883692307692 ], [ 29.735779137931036, -1.518153169230769 ], [ 29.736048620689655, -1.518153169230769 ], [ 29.736048620689655, -1.517883692307692 ], [ 29.735779137931036, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10486, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.517883692307692 ], [ 29.736048620689655, -1.518153169230769 ], [ 29.736318103448276, -1.518153169230769 ], [ 29.736318103448276, -1.517883692307692 ], [ 29.736048620689655, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10487, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.517883692307692 ], [ 29.736318103448276, -1.518153169230769 ], [ 29.736587586206898, -1.518153169230769 ], [ 29.736587586206898, -1.517883692307692 ], [ 29.736318103448276, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10488, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.517883692307692 ], [ 29.736587586206898, -1.518153169230769 ], [ 29.736857068965517, -1.518153169230769 ], [ 29.736857068965517, -1.517883692307692 ], [ 29.736587586206898, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10489, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.517883692307692 ], [ 29.736857068965517, -1.518153169230769 ], [ 29.737126551724138, -1.518153169230769 ], [ 29.737126551724138, -1.517883692307692 ], [ 29.736857068965517, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10490, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.517883692307692 ], [ 29.73739603448276, -1.518422646153846 ], [ 29.737665517241378, -1.518422646153846 ], [ 29.737665517241378, -1.517883692307692 ], [ 29.73739603448276, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10491, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.517883692307692 ], [ 29.737665517241378, -1.518153169230769 ], [ 29.737935, -1.518153169230769 ], [ 29.737935, -1.517883692307692 ], [ 29.737665517241378, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10492, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.517883692307692 ], [ 29.737935, -1.518153169230769 ], [ 29.738204482758622, -1.518153169230769 ], [ 29.738204482758622, -1.517883692307692 ], [ 29.737935, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10493, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.517883692307692 ], [ 29.738204482758622, -1.518153169230769 ], [ 29.738743448275862, -1.518153169230769 ], [ 29.738743448275862, -1.517883692307692 ], [ 29.738204482758622, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10494, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.517883692307692 ], [ 29.738743448275862, -1.518153169230769 ], [ 29.739012931034484, -1.518153169230769 ], [ 29.739012931034484, -1.517883692307692 ], [ 29.738743448275862, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10495, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.517883692307692 ], [ 29.739012931034484, -1.518153169230769 ], [ 29.739282413793102, -1.518153169230769 ], [ 29.739282413793102, -1.517883692307692 ], [ 29.739012931034484, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10496, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.517883692307692 ], [ 29.739282413793102, -1.518153169230769 ], [ 29.739551896551724, -1.518153169230769 ], [ 29.739551896551724, -1.517883692307692 ], [ 29.739282413793102, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10497, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.517883692307692 ], [ 29.739551896551724, -1.518153169230769 ], [ 29.739821379310346, -1.518153169230769 ], [ 29.739821379310346, -1.517883692307692 ], [ 29.739551896551724, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10498, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.517883692307692 ], [ 29.739821379310346, -1.518153169230769 ], [ 29.740090862068964, -1.518153169230769 ], [ 29.740090862068964, -1.517883692307692 ], [ 29.739821379310346, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10499, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.517883692307692 ], [ 29.740090862068964, -1.518422646153846 ], [ 29.740360344827586, -1.518422646153846 ], [ 29.740360344827586, -1.517883692307692 ], [ 29.740090862068964, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10500, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.517883692307692 ], [ 29.740360344827586, -1.518153169230769 ], [ 29.740629827586208, -1.518153169230769 ], [ 29.740629827586208, -1.517883692307692 ], [ 29.740360344827586, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10501, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.517883692307692 ], [ 29.740629827586208, -1.518153169230769 ], [ 29.74089931034483, -1.518153169230769 ], [ 29.74089931034483, -1.517883692307692 ], [ 29.740629827586208, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10502, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.517883692307692 ], [ 29.74089931034483, -1.518153169230769 ], [ 29.74143827586207, -1.518153169230769 ], [ 29.74143827586207, -1.517883692307692 ], [ 29.74089931034483, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10503, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.517883692307692 ], [ 29.74143827586207, -1.518153169230769 ], [ 29.741707758620691, -1.518153169230769 ], [ 29.741707758620691, -1.517883692307692 ], [ 29.74143827586207, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10504, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.517883692307692 ], [ 29.741707758620691, -1.518153169230769 ], [ 29.74197724137931, -1.518153169230769 ], [ 29.74197724137931, -1.517883692307692 ], [ 29.741707758620691, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10505, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.517883692307692 ], [ 29.755181896551726, -1.518153169230769 ], [ 29.755720862068966, -1.518153169230769 ], [ 29.755720862068966, -1.517883692307692 ], [ 29.755181896551726, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10506, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.517883692307692 ], [ 29.755720862068966, -1.518153169230769 ], [ 29.755990344827588, -1.518153169230769 ], [ 29.755990344827588, -1.517883692307692 ], [ 29.755720862068966, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10507, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.517883692307692 ], [ 29.755990344827588, -1.518153169230769 ], [ 29.756798793103449, -1.518153169230769 ], [ 29.756798793103449, -1.517883692307692 ], [ 29.755990344827588, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10508, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.517883692307692 ], [ 29.756798793103449, -1.518153169230769 ], [ 29.75733775862069, -1.518153169230769 ], [ 29.75733775862069, -1.517883692307692 ], [ 29.756798793103449, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10509, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.517883692307692 ], [ 29.75733775862069, -1.518153169230769 ], [ 29.758415689655173, -1.518153169230769 ], [ 29.758415689655173, -1.517883692307692 ], [ 29.75733775862069, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10510, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.517883692307692 ], [ 29.758415689655173, -1.518153169230769 ], [ 29.758685172413795, -1.518153169230769 ], [ 29.758685172413795, -1.517883692307692 ], [ 29.758415689655173, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10511, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.517883692307692 ], [ 29.758685172413795, -1.518153169230769 ], [ 29.758954655172413, -1.518153169230769 ], [ 29.758954655172413, -1.517883692307692 ], [ 29.758685172413795, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10512, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.517883692307692 ], [ 29.758954655172413, -1.518153169230769 ], [ 29.759224137931035, -1.518153169230769 ], [ 29.759224137931035, -1.517883692307692 ], [ 29.758954655172413, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10513, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.517883692307692 ], [ 29.759224137931035, -1.518153169230769 ], [ 29.759493620689657, -1.518153169230769 ], [ 29.759493620689657, -1.517883692307692 ], [ 29.759224137931035, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10514, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.517883692307692 ], [ 29.759493620689657, -1.518153169230769 ], [ 29.759763103448275, -1.518153169230769 ], [ 29.759763103448275, -1.517883692307692 ], [ 29.759493620689657, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10515, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.517883692307692 ], [ 29.759763103448275, -1.518153169230769 ], [ 29.760032586206897, -1.518153169230769 ], [ 29.760032586206897, -1.517883692307692 ], [ 29.759763103448275, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10516, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.517883692307692 ], [ 29.760032586206897, -1.518153169230769 ], [ 29.760302068965519, -1.518153169230769 ], [ 29.760302068965519, -1.517883692307692 ], [ 29.760032586206897, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10517, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.517883692307692 ], [ 29.760302068965519, -1.518153169230769 ], [ 29.760571551724137, -1.518153169230769 ], [ 29.760571551724137, -1.517883692307692 ], [ 29.760302068965519, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10518, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.517883692307692 ], [ 29.760571551724137, -1.518153169230769 ], [ 29.760841034482759, -1.518153169230769 ], [ 29.760841034482759, -1.517883692307692 ], [ 29.760571551724137, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10519, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.517883692307692 ], [ 29.760841034482759, -1.518153169230769 ], [ 29.761110517241381, -1.518153169230769 ], [ 29.761110517241381, -1.517883692307692 ], [ 29.760841034482759, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10520, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.517883692307692 ], [ 29.761110517241381, -1.518153169230769 ], [ 29.76138, -1.518153169230769 ], [ 29.76138, -1.517883692307692 ], [ 29.761110517241381, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10521, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.517883692307692 ], [ 29.76138, -1.518422646153846 ], [ 29.761649482758621, -1.518422646153846 ], [ 29.761649482758621, -1.517883692307692 ], [ 29.76138, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10522, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.517883692307692 ], [ 29.761918965517243, -1.518153169230769 ], [ 29.762188448275861, -1.518153169230769 ], [ 29.762188448275861, -1.517883692307692 ], [ 29.761918965517243, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10523, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.517883692307692 ], [ 29.762188448275861, -1.518153169230769 ], [ 29.762457931034483, -1.518153169230769 ], [ 29.762457931034483, -1.517883692307692 ], [ 29.762188448275861, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10524, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.517883692307692 ], [ 29.762457931034483, -1.518422646153846 ], [ 29.762727413793105, -1.518422646153846 ], [ 29.762727413793105, -1.517883692307692 ], [ 29.762457931034483, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10525, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.517883692307692 ], [ 29.762727413793105, -1.518422646153846 ], [ 29.762996896551723, -1.518422646153846 ], [ 29.762996896551723, -1.517883692307692 ], [ 29.762727413793105, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10526, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.517883692307692 ], [ 29.763266379310345, -1.518692123076923 ], [ 29.763535862068967, -1.518692123076923 ], [ 29.763535862068967, -1.517883692307692 ], [ 29.763266379310345, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10527, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.517883692307692 ], [ 29.767039137931036, -1.518422646153846 ], [ 29.767308620689654, -1.518422646153846 ], [ 29.767308620689654, -1.517883692307692 ], [ 29.767039137931036, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10528, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.517883692307692 ], [ 29.767847586206898, -1.518153169230769 ], [ 29.768117068965516, -1.518153169230769 ], [ 29.768117068965516, -1.517883692307692 ], [ 29.767847586206898, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10529, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.517883692307692 ], [ 29.768117068965516, -1.518153169230769 ], [ 29.768386551724138, -1.518153169230769 ], [ 29.768386551724138, -1.517883692307692 ], [ 29.768117068965516, -1.517883692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10530, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.518153169230769 ], [ 29.712603620689656, -1.5189616 ], [ 29.712873103448278, -1.5189616 ], [ 29.712873103448278, -1.518153169230769 ], [ 29.712603620689656, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10531, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.724999827586206, -1.518153169230769 ], [ 29.724999827586206, -1.5189616 ], [ 29.725269310344828, -1.5189616 ], [ 29.725269310344828, -1.518153169230769 ], [ 29.724999827586206, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10532, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.518153169230769 ], [ 29.725808275862068, -1.518422646153846 ], [ 29.72607775862069, -1.518422646153846 ], [ 29.72607775862069, -1.518153169230769 ], [ 29.725808275862068, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10533, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.518153169230769 ], [ 29.72607775862069, -1.518422646153846 ], [ 29.726347241379312, -1.518422646153846 ], [ 29.726347241379312, -1.518153169230769 ], [ 29.72607775862069, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10534, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.518153169230769 ], [ 29.726347241379312, -1.518422646153846 ], [ 29.728503103448276, -1.518422646153846 ], [ 29.728503103448276, -1.518153169230769 ], [ 29.726347241379312, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10535, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.518153169230769 ], [ 29.728503103448276, -1.518422646153846 ], [ 29.728772586206897, -1.518422646153846 ], [ 29.728772586206897, -1.518153169230769 ], [ 29.728503103448276, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10536, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.518153169230769 ], [ 29.728772586206897, -1.518422646153846 ], [ 29.729042068965516, -1.518422646153846 ], [ 29.729042068965516, -1.518153169230769 ], [ 29.728772586206897, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10537, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.518153169230769 ], [ 29.729042068965516, -1.518422646153846 ], [ 29.729850517241381, -1.518422646153846 ], [ 29.729850517241381, -1.518153169230769 ], [ 29.729042068965516, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10538, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.518153169230769 ], [ 29.729850517241381, -1.518422646153846 ], [ 29.73012, -1.518422646153846 ], [ 29.73012, -1.518153169230769 ], [ 29.729850517241381, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10539, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.518153169230769 ], [ 29.73012, -1.518422646153846 ], [ 29.730389482758621, -1.518422646153846 ], [ 29.730389482758621, -1.518153169230769 ], [ 29.73012, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10540, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.730389482758621, -1.518153169230769 ], [ 29.730389482758621, -1.518422646153846 ], [ 29.730928448275861, -1.518422646153846 ], [ 29.730928448275861, -1.518153169230769 ], [ 29.730389482758621, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10541, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732006379310345, -1.518153169230769 ], [ 29.732006379310345, -1.518422646153846 ], [ 29.732275862068967, -1.518422646153846 ], [ 29.732275862068967, -1.518153169230769 ], [ 29.732006379310345, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10542, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.518153169230769 ], [ 29.732275862068967, -1.518422646153846 ], [ 29.732545344827585, -1.518422646153846 ], [ 29.732545344827585, -1.518153169230769 ], [ 29.732275862068967, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10543, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.518153169230769 ], [ 29.732545344827585, -1.518422646153846 ], [ 29.732814827586207, -1.518422646153846 ], [ 29.732814827586207, -1.518153169230769 ], [ 29.732545344827585, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10544, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.518153169230769 ], [ 29.732814827586207, -1.518422646153846 ], [ 29.733084310344829, -1.518422646153846 ], [ 29.733084310344829, -1.518153169230769 ], [ 29.732814827586207, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10545, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.518153169230769 ], [ 29.733084310344829, -1.518422646153846 ], [ 29.733353793103447, -1.518422646153846 ], [ 29.733353793103447, -1.518153169230769 ], [ 29.733084310344829, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10546, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.518153169230769 ], [ 29.733353793103447, -1.518422646153846 ], [ 29.733892758620691, -1.518422646153846 ], [ 29.733892758620691, -1.518153169230769 ], [ 29.733353793103447, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10547, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.518153169230769 ], [ 29.733892758620691, -1.518422646153846 ], [ 29.734162241379309, -1.518422646153846 ], [ 29.734162241379309, -1.518153169230769 ], [ 29.733892758620691, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10548, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.518153169230769 ], [ 29.734162241379309, -1.518422646153846 ], [ 29.734431724137931, -1.518422646153846 ], [ 29.734431724137931, -1.518153169230769 ], [ 29.734162241379309, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10549, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.518153169230769 ], [ 29.734431724137931, -1.518422646153846 ], [ 29.734701206896553, -1.518422646153846 ], [ 29.734701206896553, -1.518153169230769 ], [ 29.734431724137931, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10550, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.518153169230769 ], [ 29.734701206896553, -1.518422646153846 ], [ 29.734970689655171, -1.518422646153846 ], [ 29.734970689655171, -1.518153169230769 ], [ 29.734701206896553, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10551, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.518153169230769 ], [ 29.734970689655171, -1.518422646153846 ], [ 29.735240172413793, -1.518422646153846 ], [ 29.735240172413793, -1.518153169230769 ], [ 29.734970689655171, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10552, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.518153169230769 ], [ 29.735240172413793, -1.518422646153846 ], [ 29.735509655172415, -1.518422646153846 ], [ 29.735509655172415, -1.518153169230769 ], [ 29.735240172413793, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10553, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.518153169230769 ], [ 29.735509655172415, -1.518422646153846 ], [ 29.735779137931036, -1.518422646153846 ], [ 29.735779137931036, -1.518153169230769 ], [ 29.735509655172415, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10554, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.518153169230769 ], [ 29.735779137931036, -1.518422646153846 ], [ 29.736048620689655, -1.518422646153846 ], [ 29.736048620689655, -1.518153169230769 ], [ 29.735779137931036, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10555, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.518153169230769 ], [ 29.736048620689655, -1.518422646153846 ], [ 29.736318103448276, -1.518422646153846 ], [ 29.736318103448276, -1.518153169230769 ], [ 29.736048620689655, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10556, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.518153169230769 ], [ 29.736318103448276, -1.518422646153846 ], [ 29.736587586206898, -1.518422646153846 ], [ 29.736587586206898, -1.518153169230769 ], [ 29.736318103448276, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10557, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.518153169230769 ], [ 29.736587586206898, -1.518422646153846 ], [ 29.736857068965517, -1.518422646153846 ], [ 29.736857068965517, -1.518153169230769 ], [ 29.736587586206898, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10558, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.518153169230769 ], [ 29.736857068965517, -1.518422646153846 ], [ 29.737126551724138, -1.518422646153846 ], [ 29.737126551724138, -1.518153169230769 ], [ 29.736857068965517, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10559, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.518153169230769 ], [ 29.737126551724138, -1.518692123076923 ], [ 29.73739603448276, -1.518692123076923 ], [ 29.73739603448276, -1.518153169230769 ], [ 29.737126551724138, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10560, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.518153169230769 ], [ 29.737935, -1.518692123076923 ], [ 29.738204482758622, -1.518692123076923 ], [ 29.738204482758622, -1.518153169230769 ], [ 29.737935, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10561, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.518153169230769 ], [ 29.738204482758622, -1.518422646153846 ], [ 29.738743448275862, -1.518422646153846 ], [ 29.738743448275862, -1.518153169230769 ], [ 29.738204482758622, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10562, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.518153169230769 ], [ 29.738743448275862, -1.518422646153846 ], [ 29.739012931034484, -1.518422646153846 ], [ 29.739012931034484, -1.518153169230769 ], [ 29.738743448275862, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10563, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.518153169230769 ], [ 29.739012931034484, -1.518422646153846 ], [ 29.739282413793102, -1.518422646153846 ], [ 29.739282413793102, -1.518153169230769 ], [ 29.739012931034484, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10564, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.518153169230769 ], [ 29.739282413793102, -1.518422646153846 ], [ 29.739551896551724, -1.518422646153846 ], [ 29.739551896551724, -1.518153169230769 ], [ 29.739282413793102, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10565, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.518153169230769 ], [ 29.739551896551724, -1.518422646153846 ], [ 29.739821379310346, -1.518422646153846 ], [ 29.739821379310346, -1.518153169230769 ], [ 29.739551896551724, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10566, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.518153169230769 ], [ 29.739821379310346, -1.518422646153846 ], [ 29.740090862068964, -1.518422646153846 ], [ 29.740090862068964, -1.518153169230769 ], [ 29.739821379310346, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10567, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.518153169230769 ], [ 29.740360344827586, -1.518692123076923 ], [ 29.740629827586208, -1.518692123076923 ], [ 29.740629827586208, -1.518153169230769 ], [ 29.740360344827586, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10568, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.518153169230769 ], [ 29.740629827586208, -1.518692123076923 ], [ 29.74089931034483, -1.518692123076923 ], [ 29.74089931034483, -1.518153169230769 ], [ 29.740629827586208, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10569, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.518153169230769 ], [ 29.74089931034483, -1.518422646153846 ], [ 29.741168793103448, -1.518422646153846 ], [ 29.741168793103448, -1.518153169230769 ], [ 29.74089931034483, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10570, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.518153169230769 ], [ 29.741168793103448, -1.518422646153846 ], [ 29.741707758620691, -1.518422646153846 ], [ 29.741707758620691, -1.518153169230769 ], [ 29.741168793103448, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10571, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.518153169230769 ], [ 29.754912413793104, -1.518422646153846 ], [ 29.755181896551726, -1.518422646153846 ], [ 29.755181896551726, -1.518153169230769 ], [ 29.754912413793104, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10572, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.518153169230769 ], [ 29.755181896551726, -1.518422646153846 ], [ 29.755720862068966, -1.518422646153846 ], [ 29.755720862068966, -1.518153169230769 ], [ 29.755181896551726, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10573, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.518153169230769 ], [ 29.755720862068966, -1.518422646153846 ], [ 29.757068275862068, -1.518422646153846 ], [ 29.757068275862068, -1.518153169230769 ], [ 29.755720862068966, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10574, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.518153169230769 ], [ 29.757068275862068, -1.518422646153846 ], [ 29.75733775862069, -1.518422646153846 ], [ 29.75733775862069, -1.518153169230769 ], [ 29.757068275862068, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10575, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.518153169230769 ], [ 29.75733775862069, -1.518422646153846 ], [ 29.758146206896551, -1.518422646153846 ], [ 29.758146206896551, -1.518153169230769 ], [ 29.75733775862069, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10576, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.518153169230769 ], [ 29.758146206896551, -1.518422646153846 ], [ 29.758685172413795, -1.518422646153846 ], [ 29.758685172413795, -1.518153169230769 ], [ 29.758146206896551, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10577, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.518153169230769 ], [ 29.758685172413795, -1.518422646153846 ], [ 29.758954655172413, -1.518422646153846 ], [ 29.758954655172413, -1.518153169230769 ], [ 29.758685172413795, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10578, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.518153169230769 ], [ 29.758954655172413, -1.518422646153846 ], [ 29.759224137931035, -1.518422646153846 ], [ 29.759224137931035, -1.518153169230769 ], [ 29.758954655172413, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10579, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.518153169230769 ], [ 29.759224137931035, -1.518422646153846 ], [ 29.759493620689657, -1.518422646153846 ], [ 29.759493620689657, -1.518153169230769 ], [ 29.759224137931035, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10580, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.518153169230769 ], [ 29.759493620689657, -1.518422646153846 ], [ 29.759763103448275, -1.518422646153846 ], [ 29.759763103448275, -1.518153169230769 ], [ 29.759493620689657, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10581, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.518153169230769 ], [ 29.759763103448275, -1.518422646153846 ], [ 29.760032586206897, -1.518422646153846 ], [ 29.760032586206897, -1.518153169230769 ], [ 29.759763103448275, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10582, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.518153169230769 ], [ 29.760032586206897, -1.518422646153846 ], [ 29.760302068965519, -1.518422646153846 ], [ 29.760302068965519, -1.518153169230769 ], [ 29.760032586206897, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10583, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.518153169230769 ], [ 29.760302068965519, -1.518422646153846 ], [ 29.760571551724137, -1.518422646153846 ], [ 29.760571551724137, -1.518153169230769 ], [ 29.760302068965519, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10584, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.518153169230769 ], [ 29.760571551724137, -1.518422646153846 ], [ 29.760841034482759, -1.518422646153846 ], [ 29.760841034482759, -1.518153169230769 ], [ 29.760571551724137, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10585, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.518153169230769 ], [ 29.760841034482759, -1.518422646153846 ], [ 29.761110517241381, -1.518422646153846 ], [ 29.761110517241381, -1.518153169230769 ], [ 29.760841034482759, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10586, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.518153169230769 ], [ 29.761110517241381, -1.518692123076923 ], [ 29.76138, -1.518692123076923 ], [ 29.76138, -1.518153169230769 ], [ 29.761110517241381, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10587, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.518153169230769 ], [ 29.761649482758621, -1.518422646153846 ], [ 29.761918965517243, -1.518422646153846 ], [ 29.761918965517243, -1.518153169230769 ], [ 29.761649482758621, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10588, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.518153169230769 ], [ 29.761918965517243, -1.518692123076923 ], [ 29.762188448275861, -1.518692123076923 ], [ 29.762188448275861, -1.518153169230769 ], [ 29.761918965517243, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10589, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.518153169230769 ], [ 29.762188448275861, -1.518692123076923 ], [ 29.762457931034483, -1.518692123076923 ], [ 29.762457931034483, -1.518153169230769 ], [ 29.762188448275861, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10590, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.518153169230769 ], [ 29.764344310344828, -1.518692123076923 ], [ 29.76461379310345, -1.518692123076923 ], [ 29.76461379310345, -1.518153169230769 ], [ 29.764344310344828, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10591, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.518153169230769 ], [ 29.76515275862069, -1.518692123076923 ], [ 29.765422241379312, -1.518692123076923 ], [ 29.765422241379312, -1.518153169230769 ], [ 29.76515275862069, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10592, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.518153169230769 ], [ 29.766230689655174, -1.518422646153846 ], [ 29.766500172413792, -1.518422646153846 ], [ 29.766500172413792, -1.518153169230769 ], [ 29.766230689655174, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10593, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.518153169230769 ], [ 29.766500172413792, -1.518422646153846 ], [ 29.766769655172414, -1.518422646153846 ], [ 29.766769655172414, -1.518153169230769 ], [ 29.766500172413792, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10594, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.518153169230769 ], [ 29.766769655172414, -1.518422646153846 ], [ 29.767039137931036, -1.518422646153846 ], [ 29.767039137931036, -1.518153169230769 ], [ 29.766769655172414, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10595, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.518153169230769 ], [ 29.767308620689654, -1.518422646153846 ], [ 29.767578103448276, -1.518422646153846 ], [ 29.767578103448276, -1.518153169230769 ], [ 29.767308620689654, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10596, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.518153169230769 ], [ 29.767578103448276, -1.518422646153846 ], [ 29.767847586206898, -1.518422646153846 ], [ 29.767847586206898, -1.518153169230769 ], [ 29.767578103448276, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10597, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.518153169230769 ], [ 29.767847586206898, -1.518422646153846 ], [ 29.768117068965516, -1.518422646153846 ], [ 29.768117068965516, -1.518153169230769 ], [ 29.767847586206898, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10598, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.518153169230769 ], [ 29.768117068965516, -1.518422646153846 ], [ 29.768386551724138, -1.518422646153846 ], [ 29.768386551724138, -1.518153169230769 ], [ 29.768117068965516, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10599, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.518153169230769 ], [ 29.768386551724138, -1.518422646153846 ], [ 29.76865603448276, -1.518422646153846 ], [ 29.76865603448276, -1.518153169230769 ], [ 29.768386551724138, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10600, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.518422646153846 ], [ 29.72607775862069, -1.518692123076923 ], [ 29.726347241379312, -1.518692123076923 ], [ 29.726347241379312, -1.518422646153846 ], [ 29.72607775862069, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10601, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.726347241379312, -1.518422646153846 ], [ 29.726347241379312, -1.518692123076923 ], [ 29.728503103448276, -1.518692123076923 ], [ 29.728503103448276, -1.518422646153846 ], [ 29.726347241379312, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10602, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728503103448276, -1.518422646153846 ], [ 29.728503103448276, -1.518692123076923 ], [ 29.728772586206897, -1.518692123076923 ], [ 29.728772586206897, -1.518422646153846 ], [ 29.728503103448276, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10603, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.518422646153846 ], [ 29.728772586206897, -1.518692123076923 ], [ 29.729042068965516, -1.518692123076923 ], [ 29.729042068965516, -1.518422646153846 ], [ 29.728772586206897, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10604, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.518422646153846 ], [ 29.729042068965516, -1.518692123076923 ], [ 29.729850517241381, -1.518692123076923 ], [ 29.729850517241381, -1.518422646153846 ], [ 29.729042068965516, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10605, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729850517241381, -1.518422646153846 ], [ 29.729850517241381, -1.518692123076923 ], [ 29.73012, -1.518692123076923 ], [ 29.73012, -1.518422646153846 ], [ 29.729850517241381, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10606, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73012, -1.518422646153846 ], [ 29.73012, -1.518692123076923 ], [ 29.730389482758621, -1.518692123076923 ], [ 29.730389482758621, -1.518422646153846 ], [ 29.73012, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10607, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732275862068967, -1.518422646153846 ], [ 29.732275862068967, -1.518692123076923 ], [ 29.732545344827585, -1.518692123076923 ], [ 29.732545344827585, -1.518422646153846 ], [ 29.732275862068967, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10608, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.518422646153846 ], [ 29.732545344827585, -1.518692123076923 ], [ 29.732814827586207, -1.518692123076923 ], [ 29.732814827586207, -1.518422646153846 ], [ 29.732545344827585, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10609, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.518422646153846 ], [ 29.732814827586207, -1.518692123076923 ], [ 29.733084310344829, -1.518692123076923 ], [ 29.733084310344829, -1.518422646153846 ], [ 29.732814827586207, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10610, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.518422646153846 ], [ 29.733084310344829, -1.518692123076923 ], [ 29.733623275862069, -1.518692123076923 ], [ 29.733623275862069, -1.518422646153846 ], [ 29.733084310344829, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10611, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.518422646153846 ], [ 29.733623275862069, -1.518692123076923 ], [ 29.733892758620691, -1.518692123076923 ], [ 29.733892758620691, -1.518422646153846 ], [ 29.733623275862069, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10612, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.518422646153846 ], [ 29.733892758620691, -1.518692123076923 ], [ 29.734162241379309, -1.518692123076923 ], [ 29.734162241379309, -1.518422646153846 ], [ 29.733892758620691, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10613, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.518422646153846 ], [ 29.734162241379309, -1.518692123076923 ], [ 29.734431724137931, -1.518692123076923 ], [ 29.734431724137931, -1.518422646153846 ], [ 29.734162241379309, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10614, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.518422646153846 ], [ 29.734431724137931, -1.518692123076923 ], [ 29.734701206896553, -1.518692123076923 ], [ 29.734701206896553, -1.518422646153846 ], [ 29.734431724137931, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10615, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.518422646153846 ], [ 29.734701206896553, -1.518692123076923 ], [ 29.734970689655171, -1.518692123076923 ], [ 29.734970689655171, -1.518422646153846 ], [ 29.734701206896553, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10616, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.518422646153846 ], [ 29.734970689655171, -1.518692123076923 ], [ 29.735240172413793, -1.518692123076923 ], [ 29.735240172413793, -1.518422646153846 ], [ 29.734970689655171, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10617, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.518422646153846 ], [ 29.735240172413793, -1.518692123076923 ], [ 29.735509655172415, -1.518692123076923 ], [ 29.735509655172415, -1.518422646153846 ], [ 29.735240172413793, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10618, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.518422646153846 ], [ 29.735509655172415, -1.518692123076923 ], [ 29.735779137931036, -1.518692123076923 ], [ 29.735779137931036, -1.518422646153846 ], [ 29.735509655172415, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10619, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.518422646153846 ], [ 29.735779137931036, -1.518692123076923 ], [ 29.736048620689655, -1.518692123076923 ], [ 29.736048620689655, -1.518422646153846 ], [ 29.735779137931036, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10620, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.518422646153846 ], [ 29.736048620689655, -1.518692123076923 ], [ 29.736318103448276, -1.518692123076923 ], [ 29.736318103448276, -1.518422646153846 ], [ 29.736048620689655, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10621, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.518422646153846 ], [ 29.736318103448276, -1.518692123076923 ], [ 29.736587586206898, -1.518692123076923 ], [ 29.736587586206898, -1.518422646153846 ], [ 29.736318103448276, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10622, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.518422646153846 ], [ 29.736587586206898, -1.518692123076923 ], [ 29.736857068965517, -1.518692123076923 ], [ 29.736857068965517, -1.518422646153846 ], [ 29.736587586206898, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10623, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.518422646153846 ], [ 29.736857068965517, -1.518692123076923 ], [ 29.737126551724138, -1.518692123076923 ], [ 29.737126551724138, -1.518422646153846 ], [ 29.736857068965517, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10624, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.518422646153846 ], [ 29.738204482758622, -1.520039507692308 ], [ 29.738743448275862, -1.520039507692308 ], [ 29.738743448275862, -1.518422646153846 ], [ 29.738204482758622, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10625, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.518422646153846 ], [ 29.738743448275862, -1.520039507692308 ], [ 29.739012931034484, -1.520039507692308 ], [ 29.739012931034484, -1.518422646153846 ], [ 29.738743448275862, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10626, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.518422646153846 ], [ 29.739012931034484, -1.518692123076923 ], [ 29.739282413793102, -1.518692123076923 ], [ 29.739282413793102, -1.518422646153846 ], [ 29.739012931034484, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10627, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.518422646153846 ], [ 29.739282413793102, -1.518692123076923 ], [ 29.739551896551724, -1.518692123076923 ], [ 29.739551896551724, -1.518422646153846 ], [ 29.739282413793102, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10628, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.518422646153846 ], [ 29.739551896551724, -1.518692123076923 ], [ 29.739821379310346, -1.518692123076923 ], [ 29.739821379310346, -1.518422646153846 ], [ 29.739551896551724, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10629, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.518422646153846 ], [ 29.739821379310346, -1.5189616 ], [ 29.740090862068964, -1.5189616 ], [ 29.740090862068964, -1.518422646153846 ], [ 29.739821379310346, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10630, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.518422646153846 ], [ 29.740090862068964, -1.518692123076923 ], [ 29.740360344827586, -1.518692123076923 ], [ 29.740360344827586, -1.518422646153846 ], [ 29.740090862068964, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10631, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.518422646153846 ], [ 29.74089931034483, -1.5189616 ], [ 29.741168793103448, -1.5189616 ], [ 29.741168793103448, -1.518422646153846 ], [ 29.74089931034483, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10632, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.518422646153846 ], [ 29.754912413793104, -1.518692123076923 ], [ 29.755451379310344, -1.518692123076923 ], [ 29.755451379310344, -1.518422646153846 ], [ 29.754912413793104, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10633, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.518422646153846 ], [ 29.755451379310344, -1.518692123076923 ], [ 29.755720862068966, -1.518692123076923 ], [ 29.755720862068966, -1.518422646153846 ], [ 29.755451379310344, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10634, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.518422646153846 ], [ 29.755720862068966, -1.518692123076923 ], [ 29.757068275862068, -1.518692123076923 ], [ 29.757068275862068, -1.518422646153846 ], [ 29.755720862068966, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10635, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.518422646153846 ], [ 29.757068275862068, -1.518692123076923 ], [ 29.758415689655173, -1.518692123076923 ], [ 29.758415689655173, -1.518422646153846 ], [ 29.757068275862068, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10636, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.518422646153846 ], [ 29.758415689655173, -1.518692123076923 ], [ 29.758685172413795, -1.518692123076923 ], [ 29.758685172413795, -1.518422646153846 ], [ 29.758415689655173, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10637, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.518422646153846 ], [ 29.758685172413795, -1.518692123076923 ], [ 29.758954655172413, -1.518692123076923 ], [ 29.758954655172413, -1.518422646153846 ], [ 29.758685172413795, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10638, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.518422646153846 ], [ 29.758954655172413, -1.518692123076923 ], [ 29.759224137931035, -1.518692123076923 ], [ 29.759224137931035, -1.518422646153846 ], [ 29.758954655172413, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10639, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.518422646153846 ], [ 29.759224137931035, -1.518692123076923 ], [ 29.759493620689657, -1.518692123076923 ], [ 29.759493620689657, -1.518422646153846 ], [ 29.759224137931035, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10640, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.518422646153846 ], [ 29.759493620689657, -1.518692123076923 ], [ 29.759763103448275, -1.518692123076923 ], [ 29.759763103448275, -1.518422646153846 ], [ 29.759493620689657, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10641, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.518422646153846 ], [ 29.759763103448275, -1.518692123076923 ], [ 29.760032586206897, -1.518692123076923 ], [ 29.760032586206897, -1.518422646153846 ], [ 29.759763103448275, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10642, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.518422646153846 ], [ 29.760032586206897, -1.518692123076923 ], [ 29.760302068965519, -1.518692123076923 ], [ 29.760302068965519, -1.518422646153846 ], [ 29.760032586206897, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10643, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.518422646153846 ], [ 29.760302068965519, -1.518692123076923 ], [ 29.760571551724137, -1.518692123076923 ], [ 29.760571551724137, -1.518422646153846 ], [ 29.760302068965519, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10644, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.518422646153846 ], [ 29.760571551724137, -1.5189616 ], [ 29.760841034482759, -1.5189616 ], [ 29.760841034482759, -1.518422646153846 ], [ 29.760571551724137, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10645, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.518422646153846 ], [ 29.76138, -1.5189616 ], [ 29.761649482758621, -1.5189616 ], [ 29.761649482758621, -1.518422646153846 ], [ 29.76138, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10646, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.518422646153846 ], [ 29.762457931034483, -1.519231076923077 ], [ 29.762727413793105, -1.519231076923077 ], [ 29.762727413793105, -1.518422646153846 ], [ 29.762457931034483, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10647, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.518422646153846 ], [ 29.763535862068967, -1.5189616 ], [ 29.763805344827588, -1.5189616 ], [ 29.763805344827588, -1.518422646153846 ], [ 29.763535862068967, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10648, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.518422646153846 ], [ 29.763805344827588, -1.5189616 ], [ 29.764074827586207, -1.5189616 ], [ 29.764074827586207, -1.518422646153846 ], [ 29.763805344827588, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10649, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.518422646153846 ], [ 29.76461379310345, -1.5189616 ], [ 29.764883275862069, -1.5189616 ], [ 29.764883275862069, -1.518422646153846 ], [ 29.76461379310345, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10650, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.518422646153846 ], [ 29.765422241379312, -1.518692123076923 ], [ 29.765961206896552, -1.518692123076923 ], [ 29.765961206896552, -1.518422646153846 ], [ 29.765422241379312, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10651, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.518422646153846 ], [ 29.765961206896552, -1.518692123076923 ], [ 29.766230689655174, -1.518692123076923 ], [ 29.766230689655174, -1.518422646153846 ], [ 29.765961206896552, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10652, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.518422646153846 ], [ 29.766230689655174, -1.518692123076923 ], [ 29.766500172413792, -1.518692123076923 ], [ 29.766500172413792, -1.518422646153846 ], [ 29.766230689655174, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10653, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.518422646153846 ], [ 29.766500172413792, -1.518692123076923 ], [ 29.766769655172414, -1.518692123076923 ], [ 29.766769655172414, -1.518422646153846 ], [ 29.766500172413792, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10654, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.518422646153846 ], [ 29.766769655172414, -1.518692123076923 ], [ 29.767039137931036, -1.518692123076923 ], [ 29.767039137931036, -1.518422646153846 ], [ 29.766769655172414, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10655, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.518422646153846 ], [ 29.767039137931036, -1.518692123076923 ], [ 29.767308620689654, -1.518692123076923 ], [ 29.767308620689654, -1.518422646153846 ], [ 29.767039137931036, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10656, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.518422646153846 ], [ 29.767308620689654, -1.518692123076923 ], [ 29.767578103448276, -1.518692123076923 ], [ 29.767578103448276, -1.518422646153846 ], [ 29.767308620689654, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10657, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.518422646153846 ], [ 29.767578103448276, -1.518692123076923 ], [ 29.767847586206898, -1.518692123076923 ], [ 29.767847586206898, -1.518422646153846 ], [ 29.767578103448276, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10658, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.518422646153846 ], [ 29.767847586206898, -1.518692123076923 ], [ 29.768117068965516, -1.518692123076923 ], [ 29.768117068965516, -1.518422646153846 ], [ 29.767847586206898, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10659, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.518422646153846 ], [ 29.768117068965516, -1.518692123076923 ], [ 29.768386551724138, -1.518692123076923 ], [ 29.768386551724138, -1.518422646153846 ], [ 29.768117068965516, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10660, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.518422646153846 ], [ 29.768386551724138, -1.518692123076923 ], [ 29.76865603448276, -1.518692123076923 ], [ 29.76865603448276, -1.518422646153846 ], [ 29.768386551724138, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10661, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.518422646153846 ], [ 29.76865603448276, -1.518692123076923 ], [ 29.768925517241382, -1.518692123076923 ], [ 29.768925517241382, -1.518422646153846 ], [ 29.76865603448276, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10662, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.518692123076923 ], [ 29.712334137931034, -1.5189616 ], [ 29.712603620689656, -1.5189616 ], [ 29.712603620689656, -1.518692123076923 ], [ 29.712334137931034, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10663, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.518692123076923 ], [ 29.725269310344828, -1.5189616 ], [ 29.72553879310345, -1.5189616 ], [ 29.72553879310345, -1.518692123076923 ], [ 29.725269310344828, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10664, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72553879310345, -1.518692123076923 ], [ 29.72553879310345, -1.5189616 ], [ 29.72607775862069, -1.5189616 ], [ 29.72607775862069, -1.518692123076923 ], [ 29.72553879310345, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10665, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.518692123076923 ], [ 29.72607775862069, -1.5189616 ], [ 29.728772586206897, -1.5189616 ], [ 29.728772586206897, -1.518692123076923 ], [ 29.72607775862069, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10666, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.518692123076923 ], [ 29.728772586206897, -1.5189616 ], [ 29.729042068965516, -1.5189616 ], [ 29.729042068965516, -1.518692123076923 ], [ 29.728772586206897, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10667, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.518692123076923 ], [ 29.729042068965516, -1.5189616 ], [ 29.729311551724138, -1.5189616 ], [ 29.729311551724138, -1.518692123076923 ], [ 29.729042068965516, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10668, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729311551724138, -1.518692123076923 ], [ 29.729311551724138, -1.5189616 ], [ 29.73012, -1.5189616 ], [ 29.73012, -1.518692123076923 ], [ 29.729311551724138, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10669, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732545344827585, -1.518692123076923 ], [ 29.732545344827585, -1.5189616 ], [ 29.732814827586207, -1.5189616 ], [ 29.732814827586207, -1.518692123076923 ], [ 29.732545344827585, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10670, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.518692123076923 ], [ 29.732814827586207, -1.5189616 ], [ 29.733084310344829, -1.5189616 ], [ 29.733084310344829, -1.518692123076923 ], [ 29.732814827586207, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10671, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.518692123076923 ], [ 29.733084310344829, -1.5189616 ], [ 29.733623275862069, -1.5189616 ], [ 29.733623275862069, -1.518692123076923 ], [ 29.733084310344829, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10672, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.518692123076923 ], [ 29.733623275862069, -1.5189616 ], [ 29.733892758620691, -1.5189616 ], [ 29.733892758620691, -1.518692123076923 ], [ 29.733623275862069, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10673, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.518692123076923 ], [ 29.733892758620691, -1.5189616 ], [ 29.734162241379309, -1.5189616 ], [ 29.734162241379309, -1.518692123076923 ], [ 29.733892758620691, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10674, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.518692123076923 ], [ 29.734162241379309, -1.519231076923077 ], [ 29.734431724137931, -1.519231076923077 ], [ 29.734431724137931, -1.518692123076923 ], [ 29.734162241379309, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10675, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.518692123076923 ], [ 29.734431724137931, -1.5189616 ], [ 29.734701206896553, -1.5189616 ], [ 29.734701206896553, -1.518692123076923 ], [ 29.734431724137931, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10676, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.518692123076923 ], [ 29.734701206896553, -1.5189616 ], [ 29.734970689655171, -1.5189616 ], [ 29.734970689655171, -1.518692123076923 ], [ 29.734701206896553, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10677, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.518692123076923 ], [ 29.734970689655171, -1.5189616 ], [ 29.735240172413793, -1.5189616 ], [ 29.735240172413793, -1.518692123076923 ], [ 29.734970689655171, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10678, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.518692123076923 ], [ 29.735240172413793, -1.5189616 ], [ 29.735509655172415, -1.5189616 ], [ 29.735509655172415, -1.518692123076923 ], [ 29.735240172413793, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10679, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.518692123076923 ], [ 29.735509655172415, -1.5189616 ], [ 29.735779137931036, -1.5189616 ], [ 29.735779137931036, -1.518692123076923 ], [ 29.735509655172415, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10680, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.518692123076923 ], [ 29.735779137931036, -1.5189616 ], [ 29.736048620689655, -1.5189616 ], [ 29.736048620689655, -1.518692123076923 ], [ 29.735779137931036, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10681, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.518692123076923 ], [ 29.736048620689655, -1.5189616 ], [ 29.736318103448276, -1.5189616 ], [ 29.736318103448276, -1.518692123076923 ], [ 29.736048620689655, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10682, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.518692123076923 ], [ 29.736318103448276, -1.5189616 ], [ 29.736587586206898, -1.5189616 ], [ 29.736587586206898, -1.518692123076923 ], [ 29.736318103448276, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10683, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.518692123076923 ], [ 29.736587586206898, -1.5189616 ], [ 29.736857068965517, -1.5189616 ], [ 29.736857068965517, -1.518692123076923 ], [ 29.736587586206898, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10684, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.518692123076923 ], [ 29.736857068965517, -1.5189616 ], [ 29.737126551724138, -1.5189616 ], [ 29.737126551724138, -1.518692123076923 ], [ 29.736857068965517, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10685, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.518692123076923 ], [ 29.737126551724138, -1.519231076923077 ], [ 29.73739603448276, -1.519231076923077 ], [ 29.73739603448276, -1.518692123076923 ], [ 29.737126551724138, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10686, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.518692123076923 ], [ 29.737935, -1.519770030769231 ], [ 29.738204482758622, -1.519770030769231 ], [ 29.738204482758622, -1.518692123076923 ], [ 29.737935, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10687, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.518692123076923 ], [ 29.739012931034484, -1.519770030769231 ], [ 29.739282413793102, -1.519770030769231 ], [ 29.739282413793102, -1.518692123076923 ], [ 29.739012931034484, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10688, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.518692123076923 ], [ 29.739282413793102, -1.519770030769231 ], [ 29.739551896551724, -1.519770030769231 ], [ 29.739551896551724, -1.518692123076923 ], [ 29.739282413793102, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10689, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.518692123076923 ], [ 29.739551896551724, -1.519770030769231 ], [ 29.739821379310346, -1.519770030769231 ], [ 29.739821379310346, -1.518692123076923 ], [ 29.739551896551724, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10690, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.518692123076923 ], [ 29.740090862068964, -1.519770030769231 ], [ 29.740360344827586, -1.519770030769231 ], [ 29.740360344827586, -1.518692123076923 ], [ 29.740090862068964, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10691, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.518692123076923 ], [ 29.740360344827586, -1.519770030769231 ], [ 29.740629827586208, -1.519770030769231 ], [ 29.740629827586208, -1.518692123076923 ], [ 29.740360344827586, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10692, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.518692123076923 ], [ 29.740629827586208, -1.5189616 ], [ 29.74089931034483, -1.5189616 ], [ 29.74089931034483, -1.518692123076923 ], [ 29.740629827586208, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10693, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.518692123076923 ], [ 29.755451379310344, -1.5189616 ], [ 29.756798793103449, -1.5189616 ], [ 29.756798793103449, -1.518692123076923 ], [ 29.755451379310344, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10694, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.518692123076923 ], [ 29.756798793103449, -1.5189616 ], [ 29.757068275862068, -1.5189616 ], [ 29.757068275862068, -1.518692123076923 ], [ 29.756798793103449, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10695, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.518692123076923 ], [ 29.757068275862068, -1.5189616 ], [ 29.758415689655173, -1.5189616 ], [ 29.758415689655173, -1.518692123076923 ], [ 29.757068275862068, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10696, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.518692123076923 ], [ 29.758415689655173, -1.5189616 ], [ 29.758685172413795, -1.5189616 ], [ 29.758685172413795, -1.518692123076923 ], [ 29.758415689655173, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10697, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.518692123076923 ], [ 29.758685172413795, -1.5189616 ], [ 29.758954655172413, -1.5189616 ], [ 29.758954655172413, -1.518692123076923 ], [ 29.758685172413795, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10698, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.518692123076923 ], [ 29.758954655172413, -1.519231076923077 ], [ 29.759224137931035, -1.519231076923077 ], [ 29.759224137931035, -1.518692123076923 ], [ 29.758954655172413, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10699, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.518692123076923 ], [ 29.759224137931035, -1.5189616 ], [ 29.759493620689657, -1.5189616 ], [ 29.759493620689657, -1.518692123076923 ], [ 29.759224137931035, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10700, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.518692123076923 ], [ 29.759493620689657, -1.5189616 ], [ 29.759763103448275, -1.5189616 ], [ 29.759763103448275, -1.518692123076923 ], [ 29.759493620689657, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10701, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.518692123076923 ], [ 29.759763103448275, -1.5189616 ], [ 29.760032586206897, -1.5189616 ], [ 29.760032586206897, -1.518692123076923 ], [ 29.759763103448275, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10702, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.518692123076923 ], [ 29.762996896551723, -1.519231076923077 ], [ 29.763266379310345, -1.519231076923077 ], [ 29.763266379310345, -1.518692123076923 ], [ 29.762996896551723, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10703, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.518692123076923 ], [ 29.763266379310345, -1.519231076923077 ], [ 29.763535862068967, -1.519231076923077 ], [ 29.763535862068967, -1.518692123076923 ], [ 29.763266379310345, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10704, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.518692123076923 ], [ 29.764074827586207, -1.5189616 ], [ 29.764344310344828, -1.5189616 ], [ 29.764344310344828, -1.518692123076923 ], [ 29.764074827586207, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10705, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.518692123076923 ], [ 29.764344310344828, -1.519231076923077 ], [ 29.76461379310345, -1.519231076923077 ], [ 29.76461379310345, -1.518692123076923 ], [ 29.764344310344828, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10706, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.518692123076923 ], [ 29.764883275862069, -1.5189616 ], [ 29.76515275862069, -1.5189616 ], [ 29.76515275862069, -1.518692123076923 ], [ 29.764883275862069, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10707, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.518692123076923 ], [ 29.76515275862069, -1.5189616 ], [ 29.765422241379312, -1.5189616 ], [ 29.765422241379312, -1.518692123076923 ], [ 29.76515275862069, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10708, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.518692123076923 ], [ 29.765422241379312, -1.5189616 ], [ 29.765961206896552, -1.5189616 ], [ 29.765961206896552, -1.518692123076923 ], [ 29.765422241379312, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10709, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.518692123076923 ], [ 29.765961206896552, -1.5189616 ], [ 29.766230689655174, -1.5189616 ], [ 29.766230689655174, -1.518692123076923 ], [ 29.765961206896552, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10710, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.518692123076923 ], [ 29.766230689655174, -1.5189616 ], [ 29.766500172413792, -1.5189616 ], [ 29.766500172413792, -1.518692123076923 ], [ 29.766230689655174, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10711, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.518692123076923 ], [ 29.766500172413792, -1.5189616 ], [ 29.766769655172414, -1.5189616 ], [ 29.766769655172414, -1.518692123076923 ], [ 29.766500172413792, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10712, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.518692123076923 ], [ 29.766769655172414, -1.5189616 ], [ 29.767039137931036, -1.5189616 ], [ 29.767039137931036, -1.518692123076923 ], [ 29.766769655172414, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10713, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.518692123076923 ], [ 29.767039137931036, -1.5189616 ], [ 29.767308620689654, -1.5189616 ], [ 29.767308620689654, -1.518692123076923 ], [ 29.767039137931036, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10714, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.518692123076923 ], [ 29.767308620689654, -1.5189616 ], [ 29.767578103448276, -1.5189616 ], [ 29.767578103448276, -1.518692123076923 ], [ 29.767308620689654, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10715, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.518692123076923 ], [ 29.767578103448276, -1.5189616 ], [ 29.767847586206898, -1.5189616 ], [ 29.767847586206898, -1.518692123076923 ], [ 29.767578103448276, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10716, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.518692123076923 ], [ 29.767847586206898, -1.5189616 ], [ 29.768117068965516, -1.5189616 ], [ 29.768117068965516, -1.518692123076923 ], [ 29.767847586206898, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10717, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.518692123076923 ], [ 29.768117068965516, -1.5189616 ], [ 29.768386551724138, -1.5189616 ], [ 29.768386551724138, -1.518692123076923 ], [ 29.768117068965516, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10718, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.518692123076923 ], [ 29.768386551724138, -1.5189616 ], [ 29.76865603448276, -1.5189616 ], [ 29.76865603448276, -1.518692123076923 ], [ 29.768386551724138, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10719, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.518692123076923 ], [ 29.76865603448276, -1.5189616 ], [ 29.768925517241382, -1.5189616 ], [ 29.768925517241382, -1.518692123076923 ], [ 29.76865603448276, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10720, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.518692123076923 ], [ 29.768925517241382, -1.5189616 ], [ 29.769195, -1.5189616 ], [ 29.769195, -1.518692123076923 ], [ 29.768925517241382, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10721, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.5189616 ], [ 29.712334137931034, -1.519500553846154 ], [ 29.712603620689656, -1.519500553846154 ], [ 29.712603620689656, -1.5189616 ], [ 29.712334137931034, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10722, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725269310344828, -1.5189616 ], [ 29.725269310344828, -1.519231076923077 ], [ 29.725808275862068, -1.519231076923077 ], [ 29.725808275862068, -1.5189616 ], [ 29.725269310344828, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10723, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.5189616 ], [ 29.725808275862068, -1.519231076923077 ], [ 29.72607775862069, -1.519231076923077 ], [ 29.72607775862069, -1.5189616 ], [ 29.725808275862068, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10724, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.72607775862069, -1.5189616 ], [ 29.72607775862069, -1.519231076923077 ], [ 29.728772586206897, -1.519231076923077 ], [ 29.728772586206897, -1.5189616 ], [ 29.72607775862069, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10725, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.728772586206897, -1.5189616 ], [ 29.728772586206897, -1.519231076923077 ], [ 29.729042068965516, -1.519231076923077 ], [ 29.729042068965516, -1.5189616 ], [ 29.728772586206897, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10726, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.729042068965516, -1.5189616 ], [ 29.729042068965516, -1.519231076923077 ], [ 29.729311551724138, -1.519231076923077 ], [ 29.729311551724138, -1.5189616 ], [ 29.729042068965516, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10727, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.732814827586207, -1.5189616 ], [ 29.732814827586207, -1.519231076923077 ], [ 29.733353793103447, -1.519231076923077 ], [ 29.733353793103447, -1.5189616 ], [ 29.732814827586207, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10728, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.5189616 ], [ 29.733353793103447, -1.519231076923077 ], [ 29.733623275862069, -1.519231076923077 ], [ 29.733623275862069, -1.5189616 ], [ 29.733353793103447, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10729, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.5189616 ], [ 29.733623275862069, -1.519500553846154 ], [ 29.733892758620691, -1.519500553846154 ], [ 29.733892758620691, -1.5189616 ], [ 29.733623275862069, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10730, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.5189616 ], [ 29.733892758620691, -1.519500553846154 ], [ 29.734162241379309, -1.519500553846154 ], [ 29.734162241379309, -1.5189616 ], [ 29.733892758620691, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10731, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.5189616 ], [ 29.734431724137931, -1.519231076923077 ], [ 29.734701206896553, -1.519231076923077 ], [ 29.734701206896553, -1.5189616 ], [ 29.734431724137931, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10732, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.5189616 ], [ 29.734701206896553, -1.519231076923077 ], [ 29.734970689655171, -1.519231076923077 ], [ 29.734970689655171, -1.5189616 ], [ 29.734701206896553, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10733, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.5189616 ], [ 29.734970689655171, -1.519231076923077 ], [ 29.735240172413793, -1.519231076923077 ], [ 29.735240172413793, -1.5189616 ], [ 29.734970689655171, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10734, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.5189616 ], [ 29.735240172413793, -1.519231076923077 ], [ 29.735509655172415, -1.519231076923077 ], [ 29.735509655172415, -1.5189616 ], [ 29.735240172413793, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10735, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.5189616 ], [ 29.735509655172415, -1.519231076923077 ], [ 29.735779137931036, -1.519231076923077 ], [ 29.735779137931036, -1.5189616 ], [ 29.735509655172415, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10736, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.5189616 ], [ 29.735779137931036, -1.519231076923077 ], [ 29.736048620689655, -1.519231076923077 ], [ 29.736048620689655, -1.5189616 ], [ 29.735779137931036, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10737, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.5189616 ], [ 29.736048620689655, -1.519231076923077 ], [ 29.736318103448276, -1.519231076923077 ], [ 29.736318103448276, -1.5189616 ], [ 29.736048620689655, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10738, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.5189616 ], [ 29.736318103448276, -1.519231076923077 ], [ 29.736587586206898, -1.519231076923077 ], [ 29.736587586206898, -1.5189616 ], [ 29.736318103448276, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10739, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.5189616 ], [ 29.736587586206898, -1.519231076923077 ], [ 29.736857068965517, -1.519231076923077 ], [ 29.736857068965517, -1.5189616 ], [ 29.736587586206898, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10740, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.5189616 ], [ 29.736857068965517, -1.519231076923077 ], [ 29.737126551724138, -1.519231076923077 ], [ 29.737126551724138, -1.5189616 ], [ 29.736857068965517, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10741, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.5189616 ], [ 29.739821379310346, -1.519500553846154 ], [ 29.740090862068964, -1.519500553846154 ], [ 29.740090862068964, -1.5189616 ], [ 29.739821379310346, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10742, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.5189616 ], [ 29.740629827586208, -1.519500553846154 ], [ 29.74089931034483, -1.519500553846154 ], [ 29.74089931034483, -1.5189616 ], [ 29.740629827586208, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10743, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.5189616 ], [ 29.756798793103449, -1.519231076923077 ], [ 29.758146206896551, -1.519231076923077 ], [ 29.758146206896551, -1.5189616 ], [ 29.756798793103449, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10744, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.5189616 ], [ 29.758146206896551, -1.519231076923077 ], [ 29.758415689655173, -1.519231076923077 ], [ 29.758415689655173, -1.5189616 ], [ 29.758146206896551, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10745, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5189616 ], [ 29.763535862068967, -1.519231076923077 ], [ 29.763805344827588, -1.519231076923077 ], [ 29.763805344827588, -1.5189616 ], [ 29.763535862068967, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10746, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5189616 ], [ 29.763805344827588, -1.519231076923077 ], [ 29.764074827586207, -1.519231076923077 ], [ 29.764074827586207, -1.5189616 ], [ 29.763805344827588, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10747, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5189616 ], [ 29.764074827586207, -1.519500553846154 ], [ 29.764344310344828, -1.519500553846154 ], [ 29.764344310344828, -1.5189616 ], [ 29.764074827586207, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10748, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.5189616 ], [ 29.76461379310345, -1.519231076923077 ], [ 29.764883275862069, -1.519231076923077 ], [ 29.764883275862069, -1.5189616 ], [ 29.76461379310345, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10749, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.5189616 ], [ 29.764883275862069, -1.519231076923077 ], [ 29.76515275862069, -1.519231076923077 ], [ 29.76515275862069, -1.5189616 ], [ 29.764883275862069, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10750, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.5189616 ], [ 29.76515275862069, -1.519231076923077 ], [ 29.765422241379312, -1.519231076923077 ], [ 29.765422241379312, -1.5189616 ], [ 29.76515275862069, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10751, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.5189616 ], [ 29.765422241379312, -1.519231076923077 ], [ 29.765961206896552, -1.519231076923077 ], [ 29.765961206896552, -1.5189616 ], [ 29.765422241379312, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10752, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.5189616 ], [ 29.765961206896552, -1.519231076923077 ], [ 29.766230689655174, -1.519231076923077 ], [ 29.766230689655174, -1.5189616 ], [ 29.765961206896552, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10753, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.5189616 ], [ 29.766230689655174, -1.519231076923077 ], [ 29.766500172413792, -1.519231076923077 ], [ 29.766500172413792, -1.5189616 ], [ 29.766230689655174, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10754, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5189616 ], [ 29.766500172413792, -1.519231076923077 ], [ 29.766769655172414, -1.519231076923077 ], [ 29.766769655172414, -1.5189616 ], [ 29.766500172413792, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10755, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.5189616 ], [ 29.766769655172414, -1.519231076923077 ], [ 29.767039137931036, -1.519231076923077 ], [ 29.767039137931036, -1.5189616 ], [ 29.766769655172414, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10756, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.5189616 ], [ 29.767039137931036, -1.519231076923077 ], [ 29.767308620689654, -1.519231076923077 ], [ 29.767308620689654, -1.5189616 ], [ 29.767039137931036, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10757, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.5189616 ], [ 29.767308620689654, -1.519231076923077 ], [ 29.767578103448276, -1.519231076923077 ], [ 29.767578103448276, -1.5189616 ], [ 29.767308620689654, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10758, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.5189616 ], [ 29.767578103448276, -1.519231076923077 ], [ 29.767847586206898, -1.519231076923077 ], [ 29.767847586206898, -1.5189616 ], [ 29.767578103448276, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10759, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.5189616 ], [ 29.767847586206898, -1.519231076923077 ], [ 29.768117068965516, -1.519231076923077 ], [ 29.768117068965516, -1.5189616 ], [ 29.767847586206898, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10760, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.5189616 ], [ 29.768117068965516, -1.519231076923077 ], [ 29.768386551724138, -1.519231076923077 ], [ 29.768386551724138, -1.5189616 ], [ 29.768117068965516, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10761, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.5189616 ], [ 29.768386551724138, -1.519231076923077 ], [ 29.76865603448276, -1.519231076923077 ], [ 29.76865603448276, -1.5189616 ], [ 29.768386551724138, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10762, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.5189616 ], [ 29.76865603448276, -1.519231076923077 ], [ 29.768925517241382, -1.519231076923077 ], [ 29.768925517241382, -1.5189616 ], [ 29.76865603448276, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10763, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.5189616 ], [ 29.768925517241382, -1.519231076923077 ], [ 29.769195, -1.519231076923077 ], [ 29.769195, -1.5189616 ], [ 29.768925517241382, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10764, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.5189616 ], [ 29.769195, -1.519231076923077 ], [ 29.769464482758622, -1.519231076923077 ], [ 29.769464482758622, -1.5189616 ], [ 29.769195, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10765, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710986724137932, -1.519231076923077 ], [ 29.710986724137932, -1.519500553846154 ], [ 29.711525689655172, -1.519500553846154 ], [ 29.711525689655172, -1.519231076923077 ], [ 29.710986724137932, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10766, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711525689655172, -1.519231076923077 ], [ 29.711525689655172, -1.519500553846154 ], [ 29.711795172413794, -1.519500553846154 ], [ 29.711795172413794, -1.519231076923077 ], [ 29.711525689655172, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10767, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.725808275862068, -1.519231076923077 ], [ 29.725808275862068, -1.519500553846154 ], [ 29.729042068965516, -1.519500553846154 ], [ 29.729042068965516, -1.519231076923077 ], [ 29.725808275862068, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10768, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.519231076923077 ], [ 29.733353793103447, -1.519770030769231 ], [ 29.733623275862069, -1.519770030769231 ], [ 29.733623275862069, -1.519231076923077 ], [ 29.733353793103447, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10769, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.519231076923077 ], [ 29.734162241379309, -1.519500553846154 ], [ 29.734431724137931, -1.519500553846154 ], [ 29.734431724137931, -1.519231076923077 ], [ 29.734162241379309, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10770, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.519231076923077 ], [ 29.734431724137931, -1.519500553846154 ], [ 29.734701206896553, -1.519500553846154 ], [ 29.734701206896553, -1.519231076923077 ], [ 29.734431724137931, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10771, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.519231076923077 ], [ 29.734701206896553, -1.519500553846154 ], [ 29.734970689655171, -1.519500553846154 ], [ 29.734970689655171, -1.519231076923077 ], [ 29.734701206896553, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10772, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.519231076923077 ], [ 29.734970689655171, -1.519500553846154 ], [ 29.735240172413793, -1.519500553846154 ], [ 29.735240172413793, -1.519231076923077 ], [ 29.734970689655171, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10773, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.519231076923077 ], [ 29.735240172413793, -1.519500553846154 ], [ 29.735509655172415, -1.519500553846154 ], [ 29.735509655172415, -1.519231076923077 ], [ 29.735240172413793, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10774, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.519231076923077 ], [ 29.735509655172415, -1.519500553846154 ], [ 29.735779137931036, -1.519500553846154 ], [ 29.735779137931036, -1.519231076923077 ], [ 29.735509655172415, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10775, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.519231076923077 ], [ 29.735779137931036, -1.519500553846154 ], [ 29.736048620689655, -1.519500553846154 ], [ 29.736048620689655, -1.519231076923077 ], [ 29.735779137931036, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10776, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.519231076923077 ], [ 29.736048620689655, -1.519500553846154 ], [ 29.736318103448276, -1.519500553846154 ], [ 29.736318103448276, -1.519231076923077 ], [ 29.736048620689655, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10777, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.519231076923077 ], [ 29.736318103448276, -1.519500553846154 ], [ 29.736587586206898, -1.519500553846154 ], [ 29.736587586206898, -1.519231076923077 ], [ 29.736318103448276, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10778, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.519231076923077 ], [ 29.736587586206898, -1.519500553846154 ], [ 29.736857068965517, -1.519500553846154 ], [ 29.736857068965517, -1.519231076923077 ], [ 29.736587586206898, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10779, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.519231076923077 ], [ 29.736857068965517, -1.519500553846154 ], [ 29.737126551724138, -1.519500553846154 ], [ 29.737126551724138, -1.519231076923077 ], [ 29.736857068965517, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10780, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.518422646153846 ], [ 29.737665517241378, -1.518422646153846 ], [ 29.737665517241378, -1.520039507692308 ], [ 29.73739603448276, -1.520039507692308 ], [ 29.73739603448276, -1.519500553846154 ], [ 29.737126551724138, -1.519500553846154 ], [ 29.737126551724138, -1.519231076923077 ], [ 29.73739603448276, -1.519231076923077 ], [ 29.73739603448276, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10781, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.519231076923077 ], [ 29.763266379310345, -1.519500553846154 ], [ 29.763535862068967, -1.519500553846154 ], [ 29.763535862068967, -1.519231076923077 ], [ 29.763266379310345, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10782, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.519231076923077 ], [ 29.763535862068967, -1.519500553846154 ], [ 29.763805344827588, -1.519500553846154 ], [ 29.763805344827588, -1.519231076923077 ], [ 29.763535862068967, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10783, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.519231076923077 ], [ 29.763805344827588, -1.519770030769231 ], [ 29.764074827586207, -1.519770030769231 ], [ 29.764074827586207, -1.519231076923077 ], [ 29.763805344827588, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10784, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.519231076923077 ], [ 29.764344310344828, -1.519500553846154 ], [ 29.76461379310345, -1.519500553846154 ], [ 29.76461379310345, -1.519231076923077 ], [ 29.764344310344828, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10785, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.519231076923077 ], [ 29.76461379310345, -1.519500553846154 ], [ 29.764883275862069, -1.519500553846154 ], [ 29.764883275862069, -1.519231076923077 ], [ 29.76461379310345, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10786, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.519231076923077 ], [ 29.764883275862069, -1.519500553846154 ], [ 29.76515275862069, -1.519500553846154 ], [ 29.76515275862069, -1.519231076923077 ], [ 29.764883275862069, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10787, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.519231076923077 ], [ 29.76515275862069, -1.519500553846154 ], [ 29.765422241379312, -1.519500553846154 ], [ 29.765422241379312, -1.519231076923077 ], [ 29.76515275862069, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10788, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.519231076923077 ], [ 29.765422241379312, -1.519500553846154 ], [ 29.765961206896552, -1.519500553846154 ], [ 29.765961206896552, -1.519231076923077 ], [ 29.765422241379312, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10789, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.519231076923077 ], [ 29.765961206896552, -1.519500553846154 ], [ 29.766230689655174, -1.519500553846154 ], [ 29.766230689655174, -1.519231076923077 ], [ 29.765961206896552, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10790, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.519231076923077 ], [ 29.766230689655174, -1.519500553846154 ], [ 29.766500172413792, -1.519500553846154 ], [ 29.766500172413792, -1.519231076923077 ], [ 29.766230689655174, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10791, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.519231076923077 ], [ 29.766500172413792, -1.519500553846154 ], [ 29.766769655172414, -1.519500553846154 ], [ 29.766769655172414, -1.519231076923077 ], [ 29.766500172413792, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10792, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.519231076923077 ], [ 29.766769655172414, -1.519500553846154 ], [ 29.767039137931036, -1.519500553846154 ], [ 29.767039137931036, -1.519231076923077 ], [ 29.766769655172414, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10793, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.519231076923077 ], [ 29.767039137931036, -1.519500553846154 ], [ 29.767308620689654, -1.519500553846154 ], [ 29.767308620689654, -1.519231076923077 ], [ 29.767039137931036, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10794, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.519231076923077 ], [ 29.767308620689654, -1.519500553846154 ], [ 29.767578103448276, -1.519500553846154 ], [ 29.767578103448276, -1.519231076923077 ], [ 29.767308620689654, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10795, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.519231076923077 ], [ 29.767578103448276, -1.519500553846154 ], [ 29.767847586206898, -1.519500553846154 ], [ 29.767847586206898, -1.519231076923077 ], [ 29.767578103448276, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10796, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.519231076923077 ], [ 29.767847586206898, -1.519500553846154 ], [ 29.768117068965516, -1.519500553846154 ], [ 29.768117068965516, -1.519231076923077 ], [ 29.767847586206898, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10797, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.519231076923077 ], [ 29.768117068965516, -1.519500553846154 ], [ 29.768386551724138, -1.519500553846154 ], [ 29.768386551724138, -1.519231076923077 ], [ 29.768117068965516, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10798, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.519231076923077 ], [ 29.768386551724138, -1.519500553846154 ], [ 29.76865603448276, -1.519500553846154 ], [ 29.76865603448276, -1.519231076923077 ], [ 29.768386551724138, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10799, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.519231076923077 ], [ 29.76865603448276, -1.519500553846154 ], [ 29.768925517241382, -1.519500553846154 ], [ 29.768925517241382, -1.519231076923077 ], [ 29.76865603448276, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10800, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.519231076923077 ], [ 29.768925517241382, -1.519500553846154 ], [ 29.769195, -1.519500553846154 ], [ 29.769195, -1.519231076923077 ], [ 29.768925517241382, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10801, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.519231076923077 ], [ 29.769195, -1.519500553846154 ], [ 29.769464482758622, -1.519500553846154 ], [ 29.769464482758622, -1.519231076923077 ], [ 29.769195, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10802, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71071724137931, -1.519500553846154 ], [ 29.71071724137931, -1.519770030769231 ], [ 29.710986724137932, -1.519770030769231 ], [ 29.710986724137932, -1.519500553846154 ], [ 29.71071724137931, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10803, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710986724137932, -1.519500553846154 ], [ 29.710986724137932, -1.519770030769231 ], [ 29.711525689655172, -1.519770030769231 ], [ 29.711525689655172, -1.519500553846154 ], [ 29.710986724137932, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10804, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711525689655172, -1.519500553846154 ], [ 29.711525689655172, -1.519770030769231 ], [ 29.711795172413794, -1.519770030769231 ], [ 29.711795172413794, -1.519500553846154 ], [ 29.711525689655172, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10805, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711795172413794, -1.519500553846154 ], [ 29.711795172413794, -1.519770030769231 ], [ 29.712064655172412, -1.519770030769231 ], [ 29.712064655172412, -1.519500553846154 ], [ 29.711795172413794, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10806, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.519500553846154 ], [ 29.712334137931034, -1.519770030769231 ], [ 29.712603620689656, -1.519770030769231 ], [ 29.712603620689656, -1.519500553846154 ], [ 29.712334137931034, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10807, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.519500553846154 ], [ 29.712603620689656, -1.520039507692308 ], [ 29.712873103448278, -1.520039507692308 ], [ 29.712873103448278, -1.519500553846154 ], [ 29.712603620689656, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10808, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.519500553846154 ], [ 29.733623275862069, -1.519770030769231 ], [ 29.733892758620691, -1.519770030769231 ], [ 29.733892758620691, -1.519500553846154 ], [ 29.733623275862069, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10809, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.519500553846154 ], [ 29.733892758620691, -1.519770030769231 ], [ 29.734162241379309, -1.519770030769231 ], [ 29.734162241379309, -1.519500553846154 ], [ 29.733892758620691, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10810, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.519500553846154 ], [ 29.734162241379309, -1.519770030769231 ], [ 29.734431724137931, -1.519770030769231 ], [ 29.734431724137931, -1.519500553846154 ], [ 29.734162241379309, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10811, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.519500553846154 ], [ 29.734431724137931, -1.519770030769231 ], [ 29.734701206896553, -1.519770030769231 ], [ 29.734701206896553, -1.519500553846154 ], [ 29.734431724137931, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10812, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.519500553846154 ], [ 29.734701206896553, -1.519770030769231 ], [ 29.734970689655171, -1.519770030769231 ], [ 29.734970689655171, -1.519500553846154 ], [ 29.734701206896553, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10813, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.519500553846154 ], [ 29.734970689655171, -1.519770030769231 ], [ 29.735240172413793, -1.519770030769231 ], [ 29.735240172413793, -1.519500553846154 ], [ 29.734970689655171, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10814, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.519500553846154 ], [ 29.735240172413793, -1.519770030769231 ], [ 29.735509655172415, -1.519770030769231 ], [ 29.735509655172415, -1.519500553846154 ], [ 29.735240172413793, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10815, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.519500553846154 ], [ 29.735509655172415, -1.519770030769231 ], [ 29.735779137931036, -1.519770030769231 ], [ 29.735779137931036, -1.519500553846154 ], [ 29.735509655172415, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10816, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.519500553846154 ], [ 29.735779137931036, -1.519770030769231 ], [ 29.736048620689655, -1.519770030769231 ], [ 29.736048620689655, -1.519500553846154 ], [ 29.735779137931036, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10817, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.519500553846154 ], [ 29.736048620689655, -1.519770030769231 ], [ 29.736318103448276, -1.519770030769231 ], [ 29.736318103448276, -1.519500553846154 ], [ 29.736048620689655, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10818, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.519500553846154 ], [ 29.736318103448276, -1.519770030769231 ], [ 29.736587586206898, -1.519770030769231 ], [ 29.736587586206898, -1.519500553846154 ], [ 29.736318103448276, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10819, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.519500553846154 ], [ 29.736587586206898, -1.519770030769231 ], [ 29.736857068965517, -1.519770030769231 ], [ 29.736857068965517, -1.519500553846154 ], [ 29.736587586206898, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10820, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.519500553846154 ], [ 29.736857068965517, -1.519770030769231 ], [ 29.737126551724138, -1.519770030769231 ], [ 29.737126551724138, -1.519500553846154 ], [ 29.736857068965517, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10821, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.519500553846154 ], [ 29.737126551724138, -1.519770030769231 ], [ 29.73739603448276, -1.519770030769231 ], [ 29.73739603448276, -1.519500553846154 ], [ 29.737126551724138, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10822, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.519500553846154 ], [ 29.739821379310346, -1.520039507692308 ], [ 29.740090862068964, -1.520039507692308 ], [ 29.740090862068964, -1.519500553846154 ], [ 29.739821379310346, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10823, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.519500553846154 ], [ 29.740629827586208, -1.519770030769231 ], [ 29.74089931034483, -1.519770030769231 ], [ 29.74089931034483, -1.519500553846154 ], [ 29.740629827586208, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10824, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.519500553846154 ], [ 29.74089931034483, -1.519770030769231 ], [ 29.74143827586207, -1.519770030769231 ], [ 29.74143827586207, -1.519500553846154 ], [ 29.74089931034483, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10825, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.519500553846154 ], [ 29.763266379310345, -1.519770030769231 ], [ 29.763535862068967, -1.519770030769231 ], [ 29.763535862068967, -1.519500553846154 ], [ 29.763266379310345, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10826, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.519500553846154 ], [ 29.763535862068967, -1.519770030769231 ], [ 29.763805344827588, -1.519770030769231 ], [ 29.763805344827588, -1.519500553846154 ], [ 29.763535862068967, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10827, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.519500553846154 ], [ 29.764074827586207, -1.519770030769231 ], [ 29.764344310344828, -1.519770030769231 ], [ 29.764344310344828, -1.519500553846154 ], [ 29.764074827586207, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10828, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.519500553846154 ], [ 29.764344310344828, -1.519770030769231 ], [ 29.76461379310345, -1.519770030769231 ], [ 29.76461379310345, -1.519500553846154 ], [ 29.764344310344828, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10829, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.519500553846154 ], [ 29.76461379310345, -1.519770030769231 ], [ 29.764883275862069, -1.519770030769231 ], [ 29.764883275862069, -1.519500553846154 ], [ 29.76461379310345, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10830, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.519500553846154 ], [ 29.764883275862069, -1.519770030769231 ], [ 29.76515275862069, -1.519770030769231 ], [ 29.76515275862069, -1.519500553846154 ], [ 29.764883275862069, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10831, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.519500553846154 ], [ 29.76515275862069, -1.519770030769231 ], [ 29.765422241379312, -1.519770030769231 ], [ 29.765422241379312, -1.519500553846154 ], [ 29.76515275862069, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10832, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.519500553846154 ], [ 29.765422241379312, -1.519770030769231 ], [ 29.765961206896552, -1.519770030769231 ], [ 29.765961206896552, -1.519500553846154 ], [ 29.765422241379312, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10833, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.519500553846154 ], [ 29.765961206896552, -1.519770030769231 ], [ 29.766230689655174, -1.519770030769231 ], [ 29.766230689655174, -1.519500553846154 ], [ 29.765961206896552, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10834, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.519500553846154 ], [ 29.766230689655174, -1.519770030769231 ], [ 29.766500172413792, -1.519770030769231 ], [ 29.766500172413792, -1.519500553846154 ], [ 29.766230689655174, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10835, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.519500553846154 ], [ 29.766500172413792, -1.519770030769231 ], [ 29.766769655172414, -1.519770030769231 ], [ 29.766769655172414, -1.519500553846154 ], [ 29.766500172413792, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10836, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.519500553846154 ], [ 29.766769655172414, -1.519770030769231 ], [ 29.767039137931036, -1.519770030769231 ], [ 29.767039137931036, -1.519500553846154 ], [ 29.766769655172414, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10837, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.519500553846154 ], [ 29.767039137931036, -1.519770030769231 ], [ 29.767308620689654, -1.519770030769231 ], [ 29.767308620689654, -1.519500553846154 ], [ 29.767039137931036, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10838, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.519500553846154 ], [ 29.767308620689654, -1.519770030769231 ], [ 29.767578103448276, -1.519770030769231 ], [ 29.767578103448276, -1.519500553846154 ], [ 29.767308620689654, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10839, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.519500553846154 ], [ 29.767578103448276, -1.519770030769231 ], [ 29.767847586206898, -1.519770030769231 ], [ 29.767847586206898, -1.519500553846154 ], [ 29.767578103448276, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10840, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.519500553846154 ], [ 29.767847586206898, -1.519770030769231 ], [ 29.768117068965516, -1.519770030769231 ], [ 29.768117068965516, -1.519500553846154 ], [ 29.767847586206898, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10841, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.519500553846154 ], [ 29.768117068965516, -1.519770030769231 ], [ 29.768386551724138, -1.519770030769231 ], [ 29.768386551724138, -1.519500553846154 ], [ 29.768117068965516, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10842, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.519500553846154 ], [ 29.768386551724138, -1.519770030769231 ], [ 29.76865603448276, -1.519770030769231 ], [ 29.76865603448276, -1.519500553846154 ], [ 29.768386551724138, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10843, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.519500553846154 ], [ 29.76865603448276, -1.519770030769231 ], [ 29.768925517241382, -1.519770030769231 ], [ 29.768925517241382, -1.519500553846154 ], [ 29.76865603448276, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10844, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71071724137931, -1.519770030769231 ], [ 29.71071724137931, -1.520039507692308 ], [ 29.710986724137932, -1.520039507692308 ], [ 29.710986724137932, -1.519770030769231 ], [ 29.71071724137931, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10845, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710986724137932, -1.519770030769231 ], [ 29.710986724137932, -1.520039507692308 ], [ 29.711525689655172, -1.520039507692308 ], [ 29.711525689655172, -1.519770030769231 ], [ 29.710986724137932, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10846, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711525689655172, -1.519770030769231 ], [ 29.711525689655172, -1.520039507692308 ], [ 29.711795172413794, -1.520039507692308 ], [ 29.711795172413794, -1.519770030769231 ], [ 29.711525689655172, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10847, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711795172413794, -1.519770030769231 ], [ 29.711795172413794, -1.520039507692308 ], [ 29.712064655172412, -1.520039507692308 ], [ 29.712064655172412, -1.519770030769231 ], [ 29.711795172413794, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10848, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.519770030769231 ], [ 29.733623275862069, -1.520039507692308 ], [ 29.733892758620691, -1.520039507692308 ], [ 29.733892758620691, -1.519770030769231 ], [ 29.733623275862069, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10849, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.519770030769231 ], [ 29.733892758620691, -1.520039507692308 ], [ 29.734162241379309, -1.520039507692308 ], [ 29.734162241379309, -1.519770030769231 ], [ 29.733892758620691, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10850, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.519770030769231 ], [ 29.734162241379309, -1.520039507692308 ], [ 29.734431724137931, -1.520039507692308 ], [ 29.734431724137931, -1.519770030769231 ], [ 29.734162241379309, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10851, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.519770030769231 ], [ 29.734431724137931, -1.520039507692308 ], [ 29.734701206896553, -1.520039507692308 ], [ 29.734701206896553, -1.519770030769231 ], [ 29.734431724137931, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10852, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.519770030769231 ], [ 29.734701206896553, -1.520039507692308 ], [ 29.734970689655171, -1.520039507692308 ], [ 29.734970689655171, -1.519770030769231 ], [ 29.734701206896553, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10853, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.519770030769231 ], [ 29.735240172413793, -1.520039507692308 ], [ 29.735509655172415, -1.520039507692308 ], [ 29.735509655172415, -1.519770030769231 ], [ 29.735240172413793, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10854, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.519770030769231 ], [ 29.735509655172415, -1.520039507692308 ], [ 29.735779137931036, -1.520039507692308 ], [ 29.735779137931036, -1.519770030769231 ], [ 29.735509655172415, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10855, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.519770030769231 ], [ 29.735779137931036, -1.520039507692308 ], [ 29.736048620689655, -1.520039507692308 ], [ 29.736048620689655, -1.519770030769231 ], [ 29.735779137931036, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10856, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.519770030769231 ], [ 29.736048620689655, -1.520039507692308 ], [ 29.736318103448276, -1.520039507692308 ], [ 29.736318103448276, -1.519770030769231 ], [ 29.736048620689655, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10857, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.519770030769231 ], [ 29.736318103448276, -1.520039507692308 ], [ 29.736587586206898, -1.520039507692308 ], [ 29.736587586206898, -1.519770030769231 ], [ 29.736318103448276, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10858, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.519770030769231 ], [ 29.736587586206898, -1.520039507692308 ], [ 29.736857068965517, -1.520039507692308 ], [ 29.736857068965517, -1.519770030769231 ], [ 29.736587586206898, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10859, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.519770030769231 ], [ 29.736857068965517, -1.520039507692308 ], [ 29.737126551724138, -1.520039507692308 ], [ 29.737126551724138, -1.519770030769231 ], [ 29.736857068965517, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10860, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.519770030769231 ], [ 29.737126551724138, -1.520039507692308 ], [ 29.73739603448276, -1.520039507692308 ], [ 29.73739603448276, -1.519770030769231 ], [ 29.737126551724138, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10861, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.519770030769231 ], [ 29.739012931034484, -1.520039507692308 ], [ 29.739282413793102, -1.520039507692308 ], [ 29.739282413793102, -1.519770030769231 ], [ 29.739012931034484, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10862, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.519770030769231 ], [ 29.739282413793102, -1.520039507692308 ], [ 29.739551896551724, -1.520039507692308 ], [ 29.739551896551724, -1.519770030769231 ], [ 29.739282413793102, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10863, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.519770030769231 ], [ 29.739551896551724, -1.520039507692308 ], [ 29.739821379310346, -1.520039507692308 ], [ 29.739821379310346, -1.519770030769231 ], [ 29.739551896551724, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10864, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.519770030769231 ], [ 29.740090862068964, -1.520039507692308 ], [ 29.740360344827586, -1.520039507692308 ], [ 29.740360344827586, -1.519770030769231 ], [ 29.740090862068964, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10865, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.519770030769231 ], [ 29.740360344827586, -1.520039507692308 ], [ 29.740629827586208, -1.520039507692308 ], [ 29.740629827586208, -1.519770030769231 ], [ 29.740360344827586, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10866, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.519770030769231 ], [ 29.740629827586208, -1.520039507692308 ], [ 29.741168793103448, -1.520039507692308 ], [ 29.741168793103448, -1.519770030769231 ], [ 29.740629827586208, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10867, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.519770030769231 ], [ 29.741168793103448, -1.520039507692308 ], [ 29.74143827586207, -1.520039507692308 ], [ 29.74143827586207, -1.519770030769231 ], [ 29.741168793103448, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10868, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.519770030769231 ], [ 29.74143827586207, -1.520039507692308 ], [ 29.74197724137931, -1.520039507692308 ], [ 29.74197724137931, -1.519770030769231 ], [ 29.74143827586207, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10869, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.519770030769231 ], [ 29.763535862068967, -1.520039507692308 ], [ 29.763805344827588, -1.520039507692308 ], [ 29.763805344827588, -1.519770030769231 ], [ 29.763535862068967, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10870, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.519770030769231 ], [ 29.763805344827588, -1.520039507692308 ], [ 29.764074827586207, -1.520039507692308 ], [ 29.764074827586207, -1.519770030769231 ], [ 29.763805344827588, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10871, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.519770030769231 ], [ 29.764074827586207, -1.520039507692308 ], [ 29.764344310344828, -1.520039507692308 ], [ 29.764344310344828, -1.519770030769231 ], [ 29.764074827586207, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10872, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.519770030769231 ], [ 29.764344310344828, -1.520039507692308 ], [ 29.76461379310345, -1.520039507692308 ], [ 29.76461379310345, -1.519770030769231 ], [ 29.764344310344828, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10873, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.519770030769231 ], [ 29.76461379310345, -1.520039507692308 ], [ 29.764883275862069, -1.520039507692308 ], [ 29.764883275862069, -1.519770030769231 ], [ 29.76461379310345, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10874, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.519770030769231 ], [ 29.764883275862069, -1.520039507692308 ], [ 29.76515275862069, -1.520039507692308 ], [ 29.76515275862069, -1.519770030769231 ], [ 29.764883275862069, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10875, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.519770030769231 ], [ 29.76515275862069, -1.520039507692308 ], [ 29.765422241379312, -1.520039507692308 ], [ 29.765422241379312, -1.519770030769231 ], [ 29.76515275862069, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10876, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.519770030769231 ], [ 29.765422241379312, -1.520039507692308 ], [ 29.765961206896552, -1.520039507692308 ], [ 29.765961206896552, -1.519770030769231 ], [ 29.765422241379312, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10877, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.519770030769231 ], [ 29.765961206896552, -1.520039507692308 ], [ 29.766230689655174, -1.520039507692308 ], [ 29.766230689655174, -1.519770030769231 ], [ 29.765961206896552, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10878, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.519770030769231 ], [ 29.766230689655174, -1.520039507692308 ], [ 29.766500172413792, -1.520039507692308 ], [ 29.766500172413792, -1.519770030769231 ], [ 29.766230689655174, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10879, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.519770030769231 ], [ 29.766500172413792, -1.520039507692308 ], [ 29.766769655172414, -1.520039507692308 ], [ 29.766769655172414, -1.519770030769231 ], [ 29.766500172413792, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10880, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.519770030769231 ], [ 29.766769655172414, -1.520039507692308 ], [ 29.767039137931036, -1.520039507692308 ], [ 29.767039137931036, -1.519770030769231 ], [ 29.766769655172414, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10881, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.519770030769231 ], [ 29.767039137931036, -1.520039507692308 ], [ 29.767308620689654, -1.520039507692308 ], [ 29.767308620689654, -1.519770030769231 ], [ 29.767039137931036, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10882, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.519770030769231 ], [ 29.767308620689654, -1.520039507692308 ], [ 29.767578103448276, -1.520039507692308 ], [ 29.767578103448276, -1.519770030769231 ], [ 29.767308620689654, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10883, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.519770030769231 ], [ 29.767578103448276, -1.520039507692308 ], [ 29.767847586206898, -1.520039507692308 ], [ 29.767847586206898, -1.519770030769231 ], [ 29.767578103448276, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10884, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.519770030769231 ], [ 29.767847586206898, -1.520039507692308 ], [ 29.768117068965516, -1.520039507692308 ], [ 29.768117068965516, -1.519770030769231 ], [ 29.767847586206898, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10885, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.519770030769231 ], [ 29.768117068965516, -1.520039507692308 ], [ 29.768386551724138, -1.520039507692308 ], [ 29.768386551724138, -1.519770030769231 ], [ 29.768117068965516, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10886, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.518153169230769 ], [ 29.737665517241378, -1.520308984615385 ], [ 29.737935, -1.520308984615385 ], [ 29.737935, -1.518153169230769 ], [ 29.737665517241378, -1.518153169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10887, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.518422646153846 ], [ 29.760841034482759, -1.521117415384615 ], [ 29.761110517241381, -1.521117415384615 ], [ 29.761110517241381, -1.518422646153846 ], [ 29.760841034482759, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10888, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.518422646153846 ], [ 29.761649482758621, -1.520847938461538 ], [ 29.761918965517243, -1.520847938461538 ], [ 29.761918965517243, -1.518422646153846 ], [ 29.761649482758621, -1.518422646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10889, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.516266830769231 ], [ 29.763266379310345, -1.516266830769231 ], [ 29.763266379310345, -1.518692123076923 ], [ 29.762996896551723, -1.518692123076923 ], [ 29.762996896551723, -1.520847938461538 ], [ 29.762727413793105, -1.520847938461538 ], [ 29.762727413793105, -1.518422646153846 ], [ 29.762996896551723, -1.518422646153846 ], [ 29.762996896551723, -1.516266830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10890, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.518692123076923 ], [ 29.760032586206897, -1.521386892307692 ], [ 29.760302068965519, -1.521386892307692 ], [ 29.760302068965519, -1.518692123076923 ], [ 29.760032586206897, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10891, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.518692123076923 ], [ 29.760302068965519, -1.521386892307692 ], [ 29.760571551724137, -1.521386892307692 ], [ 29.760571551724137, -1.518692123076923 ], [ 29.760302068965519, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10892, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.518692123076923 ], [ 29.761110517241381, -1.521117415384615 ], [ 29.76138, -1.521117415384615 ], [ 29.76138, -1.518692123076923 ], [ 29.761110517241381, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10893, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.518692123076923 ], [ 29.761918965517243, -1.520847938461538 ], [ 29.762188448275861, -1.520847938461538 ], [ 29.762188448275861, -1.518692123076923 ], [ 29.761918965517243, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10894, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.518692123076923 ], [ 29.762188448275861, -1.520847938461538 ], [ 29.762457931034483, -1.520847938461538 ], [ 29.762457931034483, -1.518692123076923 ], [ 29.762188448275861, -1.518692123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10895, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.5189616 ], [ 29.758415689655173, -1.521117415384615 ], [ 29.758685172413795, -1.521117415384615 ], [ 29.758685172413795, -1.5189616 ], [ 29.758415689655173, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10896, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5189616 ], [ 29.758685172413795, -1.521117415384615 ], [ 29.758954655172413, -1.521117415384615 ], [ 29.758954655172413, -1.5189616 ], [ 29.758685172413795, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10897, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.5189616 ], [ 29.759224137931035, -1.521117415384615 ], [ 29.759493620689657, -1.521117415384615 ], [ 29.759493620689657, -1.5189616 ], [ 29.759224137931035, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10898, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.5189616 ], [ 29.759493620689657, -1.521117415384615 ], [ 29.759763103448275, -1.521117415384615 ], [ 29.759763103448275, -1.5189616 ], [ 29.759493620689657, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10899, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.5189616 ], [ 29.759763103448275, -1.521117415384615 ], [ 29.760032586206897, -1.521117415384615 ], [ 29.760032586206897, -1.5189616 ], [ 29.759763103448275, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10900, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.5189616 ], [ 29.760571551724137, -1.521386892307692 ], [ 29.760841034482759, -1.521386892307692 ], [ 29.760841034482759, -1.5189616 ], [ 29.760571551724137, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10901, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5189616 ], [ 29.76138, -1.521117415384615 ], [ 29.761649482758621, -1.521117415384615 ], [ 29.761649482758621, -1.5189616 ], [ 29.76138, -1.5189616 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10902, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.519231076923077 ], [ 29.758146206896551, -1.520847938461538 ], [ 29.758415689655173, -1.520847938461538 ], [ 29.758415689655173, -1.519231076923077 ], [ 29.758146206896551, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10903, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.519231076923077 ], [ 29.758954655172413, -1.520847938461538 ], [ 29.759224137931035, -1.520847938461538 ], [ 29.759224137931035, -1.519231076923077 ], [ 29.758954655172413, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10904, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.519231076923077 ], [ 29.762457931034483, -1.520847938461538 ], [ 29.762727413793105, -1.520847938461538 ], [ 29.762727413793105, -1.519231076923077 ], [ 29.762457931034483, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10905, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.519231076923077 ], [ 29.762996896551723, -1.520847938461538 ], [ 29.763266379310345, -1.520847938461538 ], [ 29.763266379310345, -1.520039507692308 ], [ 29.763535862068967, -1.520039507692308 ], [ 29.763535862068967, -1.519770030769231 ], [ 29.763266379310345, -1.519770030769231 ], [ 29.763266379310345, -1.519231076923077 ], [ 29.762996896551723, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10906, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.519231076923077 ], [ 29.769464482758622, -1.521386892307692 ], [ 29.769733965517243, -1.521386892307692 ], [ 29.769733965517243, -1.519231076923077 ], [ 29.769464482758622, -1.519231076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10907, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.519500553846154 ], [ 29.768925517241382, -1.521656369230769 ], [ 29.769195, -1.521656369230769 ], [ 29.769195, -1.519500553846154 ], [ 29.768925517241382, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10908, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.519500553846154 ], [ 29.769195, -1.521656369230769 ], [ 29.769464482758622, -1.521656369230769 ], [ 29.769464482758622, -1.519500553846154 ], [ 29.769195, -1.519500553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10909, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710447758620688, -1.519770030769231 ], [ 29.710447758620688, -1.520308984615385 ], [ 29.71071724137931, -1.520308984615385 ], [ 29.71071724137931, -1.519770030769231 ], [ 29.710447758620688, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10910, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712064655172412, -1.519770030769231 ], [ 29.712064655172412, -1.520308984615385 ], [ 29.712334137931034, -1.520308984615385 ], [ 29.712334137931034, -1.519770030769231 ], [ 29.712064655172412, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10911, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.519770030769231 ], [ 29.712334137931034, -1.520308984615385 ], [ 29.712603620689656, -1.520308984615385 ], [ 29.712603620689656, -1.519770030769231 ], [ 29.712334137931034, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10912, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.519770030769231 ], [ 29.734970689655171, -1.520308984615385 ], [ 29.735240172413793, -1.520308984615385 ], [ 29.735240172413793, -1.519770030769231 ], [ 29.734970689655171, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10913, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.519770030769231 ], [ 29.737935, -1.520308984615385 ], [ 29.738204482758622, -1.520308984615385 ], [ 29.738204482758622, -1.519770030769231 ], [ 29.737935, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10914, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.519770030769231 ], [ 29.768386551724138, -1.521925846153846 ], [ 29.76865603448276, -1.521925846153846 ], [ 29.76865603448276, -1.519770030769231 ], [ 29.768386551724138, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10915, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.519770030769231 ], [ 29.76865603448276, -1.521386892307692 ], [ 29.768925517241382, -1.521386892307692 ], [ 29.768925517241382, -1.519770030769231 ], [ 29.76865603448276, -1.519770030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10916, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71071724137931, -1.520039507692308 ], [ 29.71071724137931, -1.520578461538461 ], [ 29.710986724137932, -1.520578461538461 ], [ 29.710986724137932, -1.520039507692308 ], [ 29.71071724137931, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10917, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711525689655172, -1.520039507692308 ], [ 29.711525689655172, -1.520308984615385 ], [ 29.711795172413794, -1.520308984615385 ], [ 29.711795172413794, -1.520039507692308 ], [ 29.711525689655172, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10918, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.520039507692308 ], [ 29.712603620689656, -1.520308984615385 ], [ 29.712873103448278, -1.520308984615385 ], [ 29.712873103448278, -1.520039507692308 ], [ 29.712603620689656, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10919, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.520039507692308 ], [ 29.712873103448278, -1.520578461538461 ], [ 29.713142586206896, -1.520578461538461 ], [ 29.713142586206896, -1.520039507692308 ], [ 29.712873103448278, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10920, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.520039507692308 ], [ 29.733892758620691, -1.520308984615385 ], [ 29.734162241379309, -1.520308984615385 ], [ 29.734162241379309, -1.520039507692308 ], [ 29.733892758620691, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10921, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.520039507692308 ], [ 29.734162241379309, -1.520308984615385 ], [ 29.734431724137931, -1.520308984615385 ], [ 29.734431724137931, -1.520039507692308 ], [ 29.734162241379309, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10922, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.520039507692308 ], [ 29.734431724137931, -1.520578461538461 ], [ 29.734701206896553, -1.520578461538461 ], [ 29.734701206896553, -1.520039507692308 ], [ 29.734431724137931, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10923, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.520039507692308 ], [ 29.734701206896553, -1.520578461538461 ], [ 29.734970689655171, -1.520578461538461 ], [ 29.734970689655171, -1.520039507692308 ], [ 29.734701206896553, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10924, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.520039507692308 ], [ 29.735240172413793, -1.520308984615385 ], [ 29.735509655172415, -1.520308984615385 ], [ 29.735509655172415, -1.520039507692308 ], [ 29.735240172413793, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10925, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.520039507692308 ], [ 29.735509655172415, -1.520308984615385 ], [ 29.735779137931036, -1.520308984615385 ], [ 29.735779137931036, -1.520039507692308 ], [ 29.735509655172415, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10926, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.520039507692308 ], [ 29.735779137931036, -1.520308984615385 ], [ 29.736048620689655, -1.520308984615385 ], [ 29.736048620689655, -1.520039507692308 ], [ 29.735779137931036, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10927, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.520039507692308 ], [ 29.736048620689655, -1.520308984615385 ], [ 29.736318103448276, -1.520308984615385 ], [ 29.736318103448276, -1.520039507692308 ], [ 29.736048620689655, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10928, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.520039507692308 ], [ 29.736318103448276, -1.520308984615385 ], [ 29.736587586206898, -1.520308984615385 ], [ 29.736587586206898, -1.520039507692308 ], [ 29.736318103448276, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10929, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.520039507692308 ], [ 29.736587586206898, -1.520308984615385 ], [ 29.736857068965517, -1.520308984615385 ], [ 29.736857068965517, -1.520039507692308 ], [ 29.736587586206898, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10930, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.520039507692308 ], [ 29.736857068965517, -1.520308984615385 ], [ 29.737126551724138, -1.520308984615385 ], [ 29.737126551724138, -1.520039507692308 ], [ 29.736857068965517, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10931, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.520039507692308 ], [ 29.737126551724138, -1.520308984615385 ], [ 29.73739603448276, -1.520308984615385 ], [ 29.73739603448276, -1.520039507692308 ], [ 29.737126551724138, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10932, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.520039507692308 ], [ 29.73739603448276, -1.520308984615385 ], [ 29.737665517241378, -1.520308984615385 ], [ 29.737665517241378, -1.520039507692308 ], [ 29.73739603448276, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10933, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.520039507692308 ], [ 29.738204482758622, -1.520308984615385 ], [ 29.738743448275862, -1.520308984615385 ], [ 29.738743448275862, -1.520039507692308 ], [ 29.738204482758622, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10934, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.520039507692308 ], [ 29.738743448275862, -1.520308984615385 ], [ 29.739012931034484, -1.520308984615385 ], [ 29.739012931034484, -1.520039507692308 ], [ 29.738743448275862, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10935, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.520039507692308 ], [ 29.739012931034484, -1.520308984615385 ], [ 29.739282413793102, -1.520308984615385 ], [ 29.739282413793102, -1.520039507692308 ], [ 29.739012931034484, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10936, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.520039507692308 ], [ 29.739282413793102, -1.520308984615385 ], [ 29.739551896551724, -1.520308984615385 ], [ 29.739551896551724, -1.520039507692308 ], [ 29.739282413793102, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10937, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.520039507692308 ], [ 29.739551896551724, -1.520308984615385 ], [ 29.739821379310346, -1.520308984615385 ], [ 29.739821379310346, -1.520039507692308 ], [ 29.739551896551724, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10938, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.520039507692308 ], [ 29.739821379310346, -1.520308984615385 ], [ 29.740090862068964, -1.520308984615385 ], [ 29.740090862068964, -1.520039507692308 ], [ 29.739821379310346, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10939, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.520039507692308 ], [ 29.740090862068964, -1.520308984615385 ], [ 29.740360344827586, -1.520308984615385 ], [ 29.740360344827586, -1.520039507692308 ], [ 29.740090862068964, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10940, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.520039507692308 ], [ 29.740360344827586, -1.520308984615385 ], [ 29.740629827586208, -1.520308984615385 ], [ 29.740629827586208, -1.520039507692308 ], [ 29.740360344827586, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10941, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.520039507692308 ], [ 29.740629827586208, -1.520308984615385 ], [ 29.741168793103448, -1.520308984615385 ], [ 29.741168793103448, -1.520039507692308 ], [ 29.740629827586208, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10942, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.520039507692308 ], [ 29.741168793103448, -1.520308984615385 ], [ 29.741707758620691, -1.520308984615385 ], [ 29.741707758620691, -1.520039507692308 ], [ 29.741168793103448, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10943, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.520039507692308 ], [ 29.741707758620691, -1.520308984615385 ], [ 29.74197724137931, -1.520308984615385 ], [ 29.74197724137931, -1.520039507692308 ], [ 29.741707758620691, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10944, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.520039507692308 ], [ 29.74197724137931, -1.520308984615385 ], [ 29.742516206896553, -1.520308984615385 ], [ 29.742516206896553, -1.520039507692308 ], [ 29.74197724137931, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10945, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.520039507692308 ], [ 29.763266379310345, -1.520308984615385 ], [ 29.763535862068967, -1.520308984615385 ], [ 29.763535862068967, -1.520039507692308 ], [ 29.763266379310345, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10946, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.520039507692308 ], [ 29.763535862068967, -1.520308984615385 ], [ 29.763805344827588, -1.520308984615385 ], [ 29.763805344827588, -1.520039507692308 ], [ 29.763535862068967, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10947, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.520039507692308 ], [ 29.763805344827588, -1.520308984615385 ], [ 29.764074827586207, -1.520308984615385 ], [ 29.764074827586207, -1.520039507692308 ], [ 29.763805344827588, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10948, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.520039507692308 ], [ 29.764074827586207, -1.520308984615385 ], [ 29.764344310344828, -1.520308984615385 ], [ 29.764344310344828, -1.520039507692308 ], [ 29.764074827586207, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10949, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.520039507692308 ], [ 29.764344310344828, -1.520308984615385 ], [ 29.76461379310345, -1.520308984615385 ], [ 29.76461379310345, -1.520039507692308 ], [ 29.764344310344828, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10950, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.520039507692308 ], [ 29.76461379310345, -1.520308984615385 ], [ 29.764883275862069, -1.520308984615385 ], [ 29.764883275862069, -1.520039507692308 ], [ 29.76461379310345, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10951, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.520039507692308 ], [ 29.764883275862069, -1.520308984615385 ], [ 29.76515275862069, -1.520308984615385 ], [ 29.76515275862069, -1.520039507692308 ], [ 29.764883275862069, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10952, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.520039507692308 ], [ 29.76515275862069, -1.520308984615385 ], [ 29.765422241379312, -1.520308984615385 ], [ 29.765422241379312, -1.520039507692308 ], [ 29.76515275862069, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10953, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.520039507692308 ], [ 29.765422241379312, -1.520308984615385 ], [ 29.765961206896552, -1.520308984615385 ], [ 29.765961206896552, -1.520039507692308 ], [ 29.765422241379312, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10954, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.520039507692308 ], [ 29.765961206896552, -1.520308984615385 ], [ 29.766230689655174, -1.520308984615385 ], [ 29.766230689655174, -1.520039507692308 ], [ 29.765961206896552, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10955, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.520039507692308 ], [ 29.766230689655174, -1.520308984615385 ], [ 29.766500172413792, -1.520308984615385 ], [ 29.766500172413792, -1.520039507692308 ], [ 29.766230689655174, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10956, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.520039507692308 ], [ 29.766500172413792, -1.520308984615385 ], [ 29.766769655172414, -1.520308984615385 ], [ 29.766769655172414, -1.520039507692308 ], [ 29.766500172413792, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10957, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.520039507692308 ], [ 29.766769655172414, -1.520308984615385 ], [ 29.767039137931036, -1.520308984615385 ], [ 29.767039137931036, -1.520039507692308 ], [ 29.766769655172414, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10958, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.520039507692308 ], [ 29.767039137931036, -1.520308984615385 ], [ 29.767308620689654, -1.520308984615385 ], [ 29.767308620689654, -1.520039507692308 ], [ 29.767039137931036, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10959, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.520039507692308 ], [ 29.767308620689654, -1.520308984615385 ], [ 29.767578103448276, -1.520308984615385 ], [ 29.767578103448276, -1.520039507692308 ], [ 29.767308620689654, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10960, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.520039507692308 ], [ 29.767578103448276, -1.520308984615385 ], [ 29.767847586206898, -1.520308984615385 ], [ 29.767847586206898, -1.520039507692308 ], [ 29.767578103448276, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10961, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.520039507692308 ], [ 29.767847586206898, -1.521656369230769 ], [ 29.768117068965516, -1.521656369230769 ], [ 29.768117068965516, -1.520039507692308 ], [ 29.767847586206898, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10962, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.520039507692308 ], [ 29.768117068965516, -1.521656369230769 ], [ 29.768386551724138, -1.521656369230769 ], [ 29.768386551724138, -1.520039507692308 ], [ 29.768117068965516, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10963, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.71017827586207, -1.520308984615385 ], [ 29.71017827586207, -1.521386892307692 ], [ 29.710986724137932, -1.521386892307692 ], [ 29.710986724137932, -1.521117415384615 ], [ 29.710447758620688, -1.521117415384615 ], [ 29.710447758620688, -1.520308984615385 ], [ 29.71017827586207, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10964, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710447758620688, -1.520308984615385 ], [ 29.710447758620688, -1.520578461538461 ], [ 29.71071724137931, -1.520578461538461 ], [ 29.71071724137931, -1.520308984615385 ], [ 29.710447758620688, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10965, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711795172413794, -1.520039507692308 ], [ 29.712064655172412, -1.520039507692308 ], [ 29.712064655172412, -1.520578461538461 ], [ 29.711525689655172, -1.520578461538461 ], [ 29.711525689655172, -1.520308984615385 ], [ 29.711795172413794, -1.520308984615385 ], [ 29.711795172413794, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10966, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712064655172412, -1.520308984615385 ], [ 29.712064655172412, -1.520578461538461 ], [ 29.712334137931034, -1.520578461538461 ], [ 29.712334137931034, -1.520308984615385 ], [ 29.712064655172412, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10967, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.520308984615385 ], [ 29.712334137931034, -1.520847938461538 ], [ 29.712603620689656, -1.520847938461538 ], [ 29.712603620689656, -1.520308984615385 ], [ 29.712334137931034, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10968, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.520308984615385 ], [ 29.712603620689656, -1.520847938461538 ], [ 29.712873103448278, -1.520847938461538 ], [ 29.712873103448278, -1.520308984615385 ], [ 29.712603620689656, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10969, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.520308984615385 ], [ 29.734162241379309, -1.520847938461538 ], [ 29.734431724137931, -1.520847938461538 ], [ 29.734431724137931, -1.520308984615385 ], [ 29.734162241379309, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10970, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.520308984615385 ], [ 29.734970689655171, -1.520578461538461 ], [ 29.735240172413793, -1.520578461538461 ], [ 29.735240172413793, -1.520308984615385 ], [ 29.734970689655171, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10971, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.520308984615385 ], [ 29.735240172413793, -1.520578461538461 ], [ 29.735509655172415, -1.520578461538461 ], [ 29.735509655172415, -1.520308984615385 ], [ 29.735240172413793, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10972, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.520308984615385 ], [ 29.735509655172415, -1.520578461538461 ], [ 29.735779137931036, -1.520578461538461 ], [ 29.735779137931036, -1.520308984615385 ], [ 29.735509655172415, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10973, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.520308984615385 ], [ 29.735779137931036, -1.520578461538461 ], [ 29.736048620689655, -1.520578461538461 ], [ 29.736048620689655, -1.520308984615385 ], [ 29.735779137931036, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10974, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.520308984615385 ], [ 29.736048620689655, -1.520578461538461 ], [ 29.736318103448276, -1.520578461538461 ], [ 29.736318103448276, -1.520308984615385 ], [ 29.736048620689655, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10975, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.520308984615385 ], [ 29.736318103448276, -1.520578461538461 ], [ 29.736587586206898, -1.520578461538461 ], [ 29.736587586206898, -1.520308984615385 ], [ 29.736318103448276, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10976, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.520308984615385 ], [ 29.736587586206898, -1.520578461538461 ], [ 29.736857068965517, -1.520578461538461 ], [ 29.736857068965517, -1.520308984615385 ], [ 29.736587586206898, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10977, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.520308984615385 ], [ 29.736857068965517, -1.520578461538461 ], [ 29.737126551724138, -1.520578461538461 ], [ 29.737126551724138, -1.520308984615385 ], [ 29.736857068965517, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10978, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.520308984615385 ], [ 29.737126551724138, -1.520578461538461 ], [ 29.73739603448276, -1.520578461538461 ], [ 29.73739603448276, -1.520308984615385 ], [ 29.737126551724138, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10979, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.520308984615385 ], [ 29.73739603448276, -1.520578461538461 ], [ 29.737665517241378, -1.520578461538461 ], [ 29.737665517241378, -1.520308984615385 ], [ 29.73739603448276, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10980, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.520308984615385 ], [ 29.737665517241378, -1.520847938461538 ], [ 29.737935, -1.520847938461538 ], [ 29.737935, -1.520308984615385 ], [ 29.737665517241378, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10981, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.520308984615385 ], [ 29.737935, -1.520578461538461 ], [ 29.738204482758622, -1.520578461538461 ], [ 29.738204482758622, -1.520308984615385 ], [ 29.737935, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10982, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.520308984615385 ], [ 29.738204482758622, -1.520578461538461 ], [ 29.738743448275862, -1.520578461538461 ], [ 29.738743448275862, -1.520308984615385 ], [ 29.738204482758622, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10983, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.520308984615385 ], [ 29.738743448275862, -1.520578461538461 ], [ 29.739012931034484, -1.520578461538461 ], [ 29.739012931034484, -1.520308984615385 ], [ 29.738743448275862, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10984, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.520308984615385 ], [ 29.739012931034484, -1.520578461538461 ], [ 29.739282413793102, -1.520578461538461 ], [ 29.739282413793102, -1.520308984615385 ], [ 29.739012931034484, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10985, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.520308984615385 ], [ 29.739282413793102, -1.520578461538461 ], [ 29.739551896551724, -1.520578461538461 ], [ 29.739551896551724, -1.520308984615385 ], [ 29.739282413793102, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10986, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.520308984615385 ], [ 29.739551896551724, -1.520578461538461 ], [ 29.739821379310346, -1.520578461538461 ], [ 29.739821379310346, -1.520308984615385 ], [ 29.739551896551724, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10987, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.520308984615385 ], [ 29.739821379310346, -1.520578461538461 ], [ 29.740090862068964, -1.520578461538461 ], [ 29.740090862068964, -1.520308984615385 ], [ 29.739821379310346, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10988, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.520308984615385 ], [ 29.740090862068964, -1.520578461538461 ], [ 29.740360344827586, -1.520578461538461 ], [ 29.740360344827586, -1.520308984615385 ], [ 29.740090862068964, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10989, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.520308984615385 ], [ 29.740360344827586, -1.520578461538461 ], [ 29.74089931034483, -1.520578461538461 ], [ 29.74089931034483, -1.520308984615385 ], [ 29.740360344827586, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10990, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.520308984615385 ], [ 29.74089931034483, -1.520578461538461 ], [ 29.741168793103448, -1.520578461538461 ], [ 29.741168793103448, -1.520308984615385 ], [ 29.74089931034483, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10991, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.520308984615385 ], [ 29.741168793103448, -1.520578461538461 ], [ 29.74143827586207, -1.520578461538461 ], [ 29.74143827586207, -1.520847938461538 ], [ 29.74197724137931, -1.520847938461538 ], [ 29.74197724137931, -1.520578461538461 ], [ 29.741707758620691, -1.520578461538461 ], [ 29.741707758620691, -1.520308984615385 ], [ 29.741168793103448, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10992, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.520308984615385 ], [ 29.741707758620691, -1.520578461538461 ], [ 29.74197724137931, -1.520578461538461 ], [ 29.74197724137931, -1.520847938461538 ], [ 29.742516206896553, -1.520847938461538 ], [ 29.742516206896553, -1.520578461538461 ], [ 29.742246724137932, -1.520578461538461 ], [ 29.742246724137932, -1.520308984615385 ], [ 29.741707758620691, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10993, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.520308984615385 ], [ 29.742246724137932, -1.520578461538461 ], [ 29.742516206896553, -1.520578461538461 ], [ 29.742516206896553, -1.520308984615385 ], [ 29.742246724137932, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10994, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.520308984615385 ], [ 29.763266379310345, -1.520847938461538 ], [ 29.763535862068967, -1.520847938461538 ], [ 29.763535862068967, -1.520308984615385 ], [ 29.763266379310345, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10995, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.520308984615385 ], [ 29.763535862068967, -1.520578461538461 ], [ 29.763805344827588, -1.520578461538461 ], [ 29.763805344827588, -1.520308984615385 ], [ 29.763535862068967, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10996, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.520308984615385 ], [ 29.763805344827588, -1.520578461538461 ], [ 29.764074827586207, -1.520578461538461 ], [ 29.764074827586207, -1.520308984615385 ], [ 29.763805344827588, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10997, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.520308984615385 ], [ 29.764074827586207, -1.520578461538461 ], [ 29.764344310344828, -1.520578461538461 ], [ 29.764344310344828, -1.520308984615385 ], [ 29.764074827586207, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10998, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.520308984615385 ], [ 29.764344310344828, -1.520578461538461 ], [ 29.76461379310345, -1.520578461538461 ], [ 29.76461379310345, -1.520308984615385 ], [ 29.764344310344828, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10999, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.520308984615385 ], [ 29.76461379310345, -1.520578461538461 ], [ 29.764883275862069, -1.520578461538461 ], [ 29.764883275862069, -1.520308984615385 ], [ 29.76461379310345, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11000, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.520308984615385 ], [ 29.764883275862069, -1.520578461538461 ], [ 29.76515275862069, -1.520578461538461 ], [ 29.76515275862069, -1.520308984615385 ], [ 29.764883275862069, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11001, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.520308984615385 ], [ 29.76515275862069, -1.520578461538461 ], [ 29.765422241379312, -1.520578461538461 ], [ 29.765422241379312, -1.520308984615385 ], [ 29.76515275862069, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11002, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.520308984615385 ], [ 29.765422241379312, -1.520578461538461 ], [ 29.765961206896552, -1.520578461538461 ], [ 29.765961206896552, -1.520308984615385 ], [ 29.765422241379312, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11003, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.520308984615385 ], [ 29.765961206896552, -1.520578461538461 ], [ 29.766230689655174, -1.520578461538461 ], [ 29.766230689655174, -1.520308984615385 ], [ 29.765961206896552, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11004, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.520308984615385 ], [ 29.766230689655174, -1.520578461538461 ], [ 29.766500172413792, -1.520578461538461 ], [ 29.766500172413792, -1.520308984615385 ], [ 29.766230689655174, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11005, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.520308984615385 ], [ 29.766500172413792, -1.520578461538461 ], [ 29.766769655172414, -1.520578461538461 ], [ 29.766769655172414, -1.520308984615385 ], [ 29.766500172413792, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11006, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.520308984615385 ], [ 29.766769655172414, -1.520578461538461 ], [ 29.767039137931036, -1.520578461538461 ], [ 29.767039137931036, -1.520308984615385 ], [ 29.766769655172414, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11007, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.520308984615385 ], [ 29.767039137931036, -1.521386892307692 ], [ 29.767308620689654, -1.521386892307692 ], [ 29.767308620689654, -1.520308984615385 ], [ 29.767039137931036, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11008, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.520308984615385 ], [ 29.767308620689654, -1.521386892307692 ], [ 29.767578103448276, -1.521386892307692 ], [ 29.767578103448276, -1.520308984615385 ], [ 29.767308620689654, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11009, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.520308984615385 ], [ 29.767578103448276, -1.521386892307692 ], [ 29.767847586206898, -1.521386892307692 ], [ 29.767847586206898, -1.520308984615385 ], [ 29.767578103448276, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11010, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710447758620688, -1.520578461538461 ], [ 29.710447758620688, -1.521117415384615 ], [ 29.711525689655172, -1.521117415384615 ], [ 29.711525689655172, -1.520847938461538 ], [ 29.71071724137931, -1.520847938461538 ], [ 29.71071724137931, -1.520578461538461 ], [ 29.710447758620688, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11011, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710986724137932, -1.520039507692308 ], [ 29.711525689655172, -1.520039507692308 ], [ 29.711525689655172, -1.520847938461538 ], [ 29.71071724137931, -1.520847938461538 ], [ 29.71071724137931, -1.520578461538461 ], [ 29.710986724137932, -1.520578461538461 ], [ 29.710986724137932, -1.520039507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11012, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711525689655172, -1.520578461538461 ], [ 29.711525689655172, -1.520847938461538 ], [ 29.711795172413794, -1.520847938461538 ], [ 29.711795172413794, -1.520578461538461 ], [ 29.711525689655172, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11013, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711795172413794, -1.520578461538461 ], [ 29.711795172413794, -1.520847938461538 ], [ 29.712064655172412, -1.520847938461538 ], [ 29.712064655172412, -1.520578461538461 ], [ 29.711795172413794, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11014, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712064655172412, -1.520578461538461 ], [ 29.712064655172412, -1.520847938461538 ], [ 29.712334137931034, -1.520847938461538 ], [ 29.712334137931034, -1.520578461538461 ], [ 29.712064655172412, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11015, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.520578461538461 ], [ 29.712873103448278, -1.520847938461538 ], [ 29.713142586206896, -1.520847938461538 ], [ 29.713142586206896, -1.520578461538461 ], [ 29.712873103448278, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11016, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.520578461538461 ], [ 29.713142586206896, -1.521386892307692 ], [ 29.713412068965518, -1.521386892307692 ], [ 29.713412068965518, -1.520578461538461 ], [ 29.713142586206896, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11017, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.520578461538461 ], [ 29.734431724137931, -1.520847938461538 ], [ 29.734701206896553, -1.520847938461538 ], [ 29.734701206896553, -1.520578461538461 ], [ 29.734431724137931, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11018, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.520578461538461 ], [ 29.734701206896553, -1.520847938461538 ], [ 29.734970689655171, -1.520847938461538 ], [ 29.734970689655171, -1.520578461538461 ], [ 29.734701206896553, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11019, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.520578461538461 ], [ 29.734970689655171, -1.520847938461538 ], [ 29.735240172413793, -1.520847938461538 ], [ 29.735240172413793, -1.520578461538461 ], [ 29.734970689655171, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11020, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.520578461538461 ], [ 29.735240172413793, -1.520847938461538 ], [ 29.735509655172415, -1.520847938461538 ], [ 29.735509655172415, -1.520578461538461 ], [ 29.735240172413793, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11021, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.520578461538461 ], [ 29.735509655172415, -1.521117415384615 ], [ 29.735779137931036, -1.521117415384615 ], [ 29.735779137931036, -1.520578461538461 ], [ 29.735509655172415, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11022, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.520578461538461 ], [ 29.735779137931036, -1.520847938461538 ], [ 29.736048620689655, -1.520847938461538 ], [ 29.736048620689655, -1.520578461538461 ], [ 29.735779137931036, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11023, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.520578461538461 ], [ 29.736048620689655, -1.520847938461538 ], [ 29.736318103448276, -1.520847938461538 ], [ 29.736318103448276, -1.520578461538461 ], [ 29.736048620689655, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11024, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.520578461538461 ], [ 29.736318103448276, -1.520847938461538 ], [ 29.736587586206898, -1.520847938461538 ], [ 29.736587586206898, -1.520578461538461 ], [ 29.736318103448276, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11025, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.520578461538461 ], [ 29.736587586206898, -1.520847938461538 ], [ 29.736857068965517, -1.520847938461538 ], [ 29.736857068965517, -1.520578461538461 ], [ 29.736587586206898, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11026, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.520578461538461 ], [ 29.736857068965517, -1.520847938461538 ], [ 29.737126551724138, -1.520847938461538 ], [ 29.737126551724138, -1.520578461538461 ], [ 29.736857068965517, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11027, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.520578461538461 ], [ 29.737126551724138, -1.520847938461538 ], [ 29.73739603448276, -1.520847938461538 ], [ 29.73739603448276, -1.520578461538461 ], [ 29.737126551724138, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11028, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.520578461538461 ], [ 29.73739603448276, -1.520847938461538 ], [ 29.737665517241378, -1.520847938461538 ], [ 29.737665517241378, -1.520578461538461 ], [ 29.73739603448276, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11029, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.520578461538461 ], [ 29.737935, -1.520847938461538 ], [ 29.738204482758622, -1.520847938461538 ], [ 29.738204482758622, -1.520578461538461 ], [ 29.737935, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11030, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.520578461538461 ], [ 29.738204482758622, -1.520847938461538 ], [ 29.738743448275862, -1.520847938461538 ], [ 29.738743448275862, -1.520578461538461 ], [ 29.738204482758622, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11031, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.520578461538461 ], [ 29.738743448275862, -1.520847938461538 ], [ 29.739012931034484, -1.520847938461538 ], [ 29.739012931034484, -1.520578461538461 ], [ 29.738743448275862, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11032, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.520578461538461 ], [ 29.739012931034484, -1.520847938461538 ], [ 29.739282413793102, -1.520847938461538 ], [ 29.739282413793102, -1.520578461538461 ], [ 29.739012931034484, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11033, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.520578461538461 ], [ 29.739282413793102, -1.520847938461538 ], [ 29.739551896551724, -1.520847938461538 ], [ 29.739551896551724, -1.520578461538461 ], [ 29.739282413793102, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11034, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.520578461538461 ], [ 29.739551896551724, -1.520847938461538 ], [ 29.739821379310346, -1.520847938461538 ], [ 29.739821379310346, -1.520578461538461 ], [ 29.739551896551724, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11035, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.520578461538461 ], [ 29.739821379310346, -1.521117415384615 ], [ 29.740090862068964, -1.521117415384615 ], [ 29.740090862068964, -1.520578461538461 ], [ 29.739821379310346, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11036, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.520578461538461 ], [ 29.740090862068964, -1.520847938461538 ], [ 29.740360344827586, -1.520847938461538 ], [ 29.740360344827586, -1.520578461538461 ], [ 29.740090862068964, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11037, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.520578461538461 ], [ 29.740360344827586, -1.520847938461538 ], [ 29.740629827586208, -1.520847938461538 ], [ 29.740629827586208, -1.520578461538461 ], [ 29.740360344827586, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11038, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.520578461538461 ], [ 29.740629827586208, -1.520847938461538 ], [ 29.74089931034483, -1.520847938461538 ], [ 29.74089931034483, -1.520578461538461 ], [ 29.740629827586208, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11039, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.520578461538461 ], [ 29.74089931034483, -1.520847938461538 ], [ 29.741168793103448, -1.520847938461538 ], [ 29.741168793103448, -1.520578461538461 ], [ 29.74089931034483, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11040, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.520578461538461 ], [ 29.741168793103448, -1.520847938461538 ], [ 29.74143827586207, -1.520847938461538 ], [ 29.74143827586207, -1.520578461538461 ], [ 29.741168793103448, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11041, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.520578461538461 ], [ 29.763535862068967, -1.520847938461538 ], [ 29.763805344827588, -1.520847938461538 ], [ 29.763805344827588, -1.520578461538461 ], [ 29.763535862068967, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11042, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.520578461538461 ], [ 29.763805344827588, -1.520847938461538 ], [ 29.764074827586207, -1.520847938461538 ], [ 29.764074827586207, -1.520578461538461 ], [ 29.763805344827588, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11043, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.520578461538461 ], [ 29.764074827586207, -1.520847938461538 ], [ 29.764344310344828, -1.520847938461538 ], [ 29.764344310344828, -1.520578461538461 ], [ 29.764074827586207, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11044, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.520578461538461 ], [ 29.764344310344828, -1.520847938461538 ], [ 29.76461379310345, -1.520847938461538 ], [ 29.76461379310345, -1.520578461538461 ], [ 29.764344310344828, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11045, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.520578461538461 ], [ 29.76461379310345, -1.520847938461538 ], [ 29.764883275862069, -1.520847938461538 ], [ 29.764883275862069, -1.520578461538461 ], [ 29.76461379310345, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11046, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.520578461538461 ], [ 29.764883275862069, -1.520847938461538 ], [ 29.76515275862069, -1.520847938461538 ], [ 29.76515275862069, -1.520578461538461 ], [ 29.764883275862069, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11047, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.520578461538461 ], [ 29.76515275862069, -1.520847938461538 ], [ 29.765422241379312, -1.520847938461538 ], [ 29.765422241379312, -1.520578461538461 ], [ 29.76515275862069, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11048, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.520578461538461 ], [ 29.765422241379312, -1.520847938461538 ], [ 29.765961206896552, -1.520847938461538 ], [ 29.765961206896552, -1.520578461538461 ], [ 29.765422241379312, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11049, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.520578461538461 ], [ 29.765961206896552, -1.520847938461538 ], [ 29.766230689655174, -1.520847938461538 ], [ 29.766230689655174, -1.520578461538461 ], [ 29.765961206896552, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11050, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.520578461538461 ], [ 29.766230689655174, -1.521117415384615 ], [ 29.766500172413792, -1.521117415384615 ], [ 29.766500172413792, -1.520578461538461 ], [ 29.766230689655174, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11051, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.520578461538461 ], [ 29.766500172413792, -1.521117415384615 ], [ 29.766769655172414, -1.521117415384615 ], [ 29.766769655172414, -1.520578461538461 ], [ 29.766500172413792, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11052, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.520578461538461 ], [ 29.766769655172414, -1.521117415384615 ], [ 29.767039137931036, -1.521117415384615 ], [ 29.767039137931036, -1.520578461538461 ], [ 29.766769655172414, -1.520578461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11053, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711525689655172, -1.520847938461538 ], [ 29.711525689655172, -1.521117415384615 ], [ 29.712334137931034, -1.521117415384615 ], [ 29.712334137931034, -1.520847938461538 ], [ 29.711525689655172, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11054, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712334137931034, -1.520847938461538 ], [ 29.712334137931034, -1.521117415384615 ], [ 29.712603620689656, -1.521117415384615 ], [ 29.712603620689656, -1.520847938461538 ], [ 29.712334137931034, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11055, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.520847938461538 ], [ 29.734431724137931, -1.521117415384615 ], [ 29.734701206896553, -1.521117415384615 ], [ 29.734701206896553, -1.520847938461538 ], [ 29.734431724137931, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11056, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.520847938461538 ], [ 29.734701206896553, -1.521117415384615 ], [ 29.734970689655171, -1.521117415384615 ], [ 29.734970689655171, -1.520847938461538 ], [ 29.734701206896553, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11057, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.520847938461538 ], [ 29.734970689655171, -1.521386892307692 ], [ 29.735240172413793, -1.521386892307692 ], [ 29.735240172413793, -1.520847938461538 ], [ 29.734970689655171, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11058, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.520847938461538 ], [ 29.735240172413793, -1.521386892307692 ], [ 29.735509655172415, -1.521386892307692 ], [ 29.735509655172415, -1.520847938461538 ], [ 29.735240172413793, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11059, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.520847938461538 ], [ 29.735779137931036, -1.521117415384615 ], [ 29.736048620689655, -1.521117415384615 ], [ 29.736048620689655, -1.520847938461538 ], [ 29.735779137931036, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11060, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.520847938461538 ], [ 29.736048620689655, -1.521117415384615 ], [ 29.736318103448276, -1.521117415384615 ], [ 29.736318103448276, -1.520847938461538 ], [ 29.736048620689655, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11061, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.520847938461538 ], [ 29.736318103448276, -1.521117415384615 ], [ 29.736587586206898, -1.521117415384615 ], [ 29.736587586206898, -1.520847938461538 ], [ 29.736318103448276, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11062, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.520847938461538 ], [ 29.736587586206898, -1.521117415384615 ], [ 29.736857068965517, -1.521117415384615 ], [ 29.736857068965517, -1.520847938461538 ], [ 29.736587586206898, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11063, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.520847938461538 ], [ 29.736857068965517, -1.521117415384615 ], [ 29.737126551724138, -1.521117415384615 ], [ 29.737126551724138, -1.520847938461538 ], [ 29.736857068965517, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11064, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.520847938461538 ], [ 29.737126551724138, -1.521117415384615 ], [ 29.73739603448276, -1.521117415384615 ], [ 29.73739603448276, -1.520847938461538 ], [ 29.737126551724138, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11065, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.520847938461538 ], [ 29.73739603448276, -1.521117415384615 ], [ 29.737665517241378, -1.521117415384615 ], [ 29.737665517241378, -1.520847938461538 ], [ 29.73739603448276, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11066, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.520847938461538 ], [ 29.737665517241378, -1.521117415384615 ], [ 29.737935, -1.521117415384615 ], [ 29.737935, -1.520847938461538 ], [ 29.737665517241378, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11067, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.520847938461538 ], [ 29.737935, -1.521117415384615 ], [ 29.738204482758622, -1.521117415384615 ], [ 29.738204482758622, -1.520847938461538 ], [ 29.737935, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11068, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.520847938461538 ], [ 29.738204482758622, -1.521117415384615 ], [ 29.738743448275862, -1.521117415384615 ], [ 29.738743448275862, -1.520847938461538 ], [ 29.738204482758622, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11069, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.520847938461538 ], [ 29.738743448275862, -1.521117415384615 ], [ 29.739012931034484, -1.521117415384615 ], [ 29.739012931034484, -1.520847938461538 ], [ 29.738743448275862, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11070, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.520847938461538 ], [ 29.739012931034484, -1.521117415384615 ], [ 29.739282413793102, -1.521117415384615 ], [ 29.739282413793102, -1.520847938461538 ], [ 29.739012931034484, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11071, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.520847938461538 ], [ 29.739282413793102, -1.521117415384615 ], [ 29.739551896551724, -1.521117415384615 ], [ 29.739551896551724, -1.520847938461538 ], [ 29.739282413793102, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11072, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.520847938461538 ], [ 29.739551896551724, -1.521117415384615 ], [ 29.739821379310346, -1.521117415384615 ], [ 29.739821379310346, -1.520847938461538 ], [ 29.739551896551724, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11073, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.520847938461538 ], [ 29.740090862068964, -1.521117415384615 ], [ 29.740360344827586, -1.521117415384615 ], [ 29.740360344827586, -1.520847938461538 ], [ 29.740090862068964, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11074, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.520847938461538 ], [ 29.740360344827586, -1.521117415384615 ], [ 29.740629827586208, -1.521117415384615 ], [ 29.740629827586208, -1.520847938461538 ], [ 29.740360344827586, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11075, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.520847938461538 ], [ 29.740629827586208, -1.521117415384615 ], [ 29.74089931034483, -1.521117415384615 ], [ 29.74089931034483, -1.520847938461538 ], [ 29.740629827586208, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11076, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.520847938461538 ], [ 29.74089931034483, -1.521117415384615 ], [ 29.741168793103448, -1.521117415384615 ], [ 29.741168793103448, -1.520847938461538 ], [ 29.74089931034483, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11077, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.520847938461538 ], [ 29.741168793103448, -1.521117415384615 ], [ 29.74143827586207, -1.521117415384615 ], [ 29.74143827586207, -1.520847938461538 ], [ 29.741168793103448, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11078, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.520847938461538 ], [ 29.74143827586207, -1.521117415384615 ], [ 29.74197724137931, -1.521117415384615 ], [ 29.74197724137931, -1.520847938461538 ], [ 29.74143827586207, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11079, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.520847938461538 ], [ 29.74197724137931, -1.521117415384615 ], [ 29.742246724137932, -1.521117415384615 ], [ 29.742246724137932, -1.520847938461538 ], [ 29.74197724137931, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11080, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.520308984615385 ], [ 29.742785689655172, -1.520308984615385 ], [ 29.742785689655172, -1.521117415384615 ], [ 29.742246724137932, -1.521117415384615 ], [ 29.742246724137932, -1.520847938461538 ], [ 29.742516206896553, -1.520847938461538 ], [ 29.742516206896553, -1.520308984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11081, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.520847938461538 ], [ 29.757876724137933, -1.521925846153846 ], [ 29.758146206896551, -1.521925846153846 ], [ 29.758146206896551, -1.520847938461538 ], [ 29.757876724137933, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11082, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.520847938461538 ], [ 29.758146206896551, -1.521117415384615 ], [ 29.758415689655173, -1.521117415384615 ], [ 29.758415689655173, -1.520847938461538 ], [ 29.758146206896551, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11083, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.520847938461538 ], [ 29.758954655172413, -1.521656369230769 ], [ 29.759224137931035, -1.521656369230769 ], [ 29.759224137931035, -1.520847938461538 ], [ 29.758954655172413, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11084, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.520847938461538 ], [ 29.761649482758621, -1.521117415384615 ], [ 29.761918965517243, -1.521117415384615 ], [ 29.761918965517243, -1.520847938461538 ], [ 29.761649482758621, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11085, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.520847938461538 ], [ 29.761918965517243, -1.521117415384615 ], [ 29.762188448275861, -1.521117415384615 ], [ 29.762188448275861, -1.520847938461538 ], [ 29.761918965517243, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11086, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.520847938461538 ], [ 29.762188448275861, -1.521117415384615 ], [ 29.762457931034483, -1.521117415384615 ], [ 29.762457931034483, -1.520847938461538 ], [ 29.762188448275861, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11087, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.520847938461538 ], [ 29.762457931034483, -1.521386892307692 ], [ 29.762727413793105, -1.521386892307692 ], [ 29.762727413793105, -1.520847938461538 ], [ 29.762457931034483, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11088, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.520847938461538 ], [ 29.762727413793105, -1.521117415384615 ], [ 29.762996896551723, -1.521117415384615 ], [ 29.762996896551723, -1.520847938461538 ], [ 29.762727413793105, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11089, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.520847938461538 ], [ 29.762996896551723, -1.521117415384615 ], [ 29.763266379310345, -1.521117415384615 ], [ 29.763266379310345, -1.520847938461538 ], [ 29.762996896551723, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11090, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.520847938461538 ], [ 29.763266379310345, -1.521117415384615 ], [ 29.763535862068967, -1.521117415384615 ], [ 29.763535862068967, -1.520847938461538 ], [ 29.763266379310345, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11091, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.520847938461538 ], [ 29.763535862068967, -1.521656369230769 ], [ 29.764074827586207, -1.521656369230769 ], [ 29.764074827586207, -1.521117415384615 ], [ 29.763805344827588, -1.521117415384615 ], [ 29.763805344827588, -1.520847938461538 ], [ 29.763535862068967, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11092, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.520847938461538 ], [ 29.763805344827588, -1.521117415384615 ], [ 29.764074827586207, -1.521117415384615 ], [ 29.764074827586207, -1.520847938461538 ], [ 29.763805344827588, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11093, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.520847938461538 ], [ 29.764074827586207, -1.521117415384615 ], [ 29.764344310344828, -1.521117415384615 ], [ 29.764344310344828, -1.520847938461538 ], [ 29.764074827586207, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11094, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.520847938461538 ], [ 29.764344310344828, -1.521117415384615 ], [ 29.76461379310345, -1.521117415384615 ], [ 29.76461379310345, -1.520847938461538 ], [ 29.764344310344828, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11095, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.520847938461538 ], [ 29.76461379310345, -1.521117415384615 ], [ 29.764883275862069, -1.521117415384615 ], [ 29.764883275862069, -1.520847938461538 ], [ 29.76461379310345, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11096, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.520847938461538 ], [ 29.764883275862069, -1.521386892307692 ], [ 29.76515275862069, -1.521386892307692 ], [ 29.76515275862069, -1.520847938461538 ], [ 29.764883275862069, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11097, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.520847938461538 ], [ 29.76515275862069, -1.521117415384615 ], [ 29.765422241379312, -1.521117415384615 ], [ 29.765422241379312, -1.520847938461538 ], [ 29.76515275862069, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11098, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.520847938461538 ], [ 29.765422241379312, -1.521117415384615 ], [ 29.765961206896552, -1.521117415384615 ], [ 29.765961206896552, -1.520847938461538 ], [ 29.765422241379312, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11099, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.520847938461538 ], [ 29.765961206896552, -1.521117415384615 ], [ 29.766230689655174, -1.521117415384615 ], [ 29.766230689655174, -1.520847938461538 ], [ 29.765961206896552, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11100, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710986724137932, -1.521117415384615 ], [ 29.710986724137932, -1.521386892307692 ], [ 29.711525689655172, -1.521386892307692 ], [ 29.711525689655172, -1.521117415384615 ], [ 29.710986724137932, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11101, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.711525689655172, -1.521117415384615 ], [ 29.711525689655172, -1.521386892307692 ], [ 29.712334137931034, -1.521386892307692 ], [ 29.712334137931034, -1.521117415384615 ], [ 29.711525689655172, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11102, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.520847938461538 ], [ 29.712873103448278, -1.520847938461538 ], [ 29.712873103448278, -1.521386892307692 ], [ 29.712334137931034, -1.521386892307692 ], [ 29.712334137931034, -1.521117415384615 ], [ 29.712603620689656, -1.521117415384615 ], [ 29.712603620689656, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11103, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.521117415384615 ], [ 29.734701206896553, -1.521656369230769 ], [ 29.734970689655171, -1.521656369230769 ], [ 29.734970689655171, -1.521117415384615 ], [ 29.734701206896553, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11104, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.521117415384615 ], [ 29.735509655172415, -1.521386892307692 ], [ 29.735779137931036, -1.521386892307692 ], [ 29.735779137931036, -1.521117415384615 ], [ 29.735509655172415, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11105, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.521117415384615 ], [ 29.735779137931036, -1.521656369230769 ], [ 29.736048620689655, -1.521656369230769 ], [ 29.736048620689655, -1.521117415384615 ], [ 29.735779137931036, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11106, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.521117415384615 ], [ 29.736048620689655, -1.521386892307692 ], [ 29.736318103448276, -1.521386892307692 ], [ 29.736318103448276, -1.521117415384615 ], [ 29.736048620689655, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11107, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.521117415384615 ], [ 29.736318103448276, -1.521386892307692 ], [ 29.736587586206898, -1.521386892307692 ], [ 29.736587586206898, -1.521117415384615 ], [ 29.736318103448276, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11108, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.521117415384615 ], [ 29.736587586206898, -1.521386892307692 ], [ 29.736857068965517, -1.521386892307692 ], [ 29.736857068965517, -1.521117415384615 ], [ 29.736587586206898, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11109, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.521117415384615 ], [ 29.736857068965517, -1.521386892307692 ], [ 29.737126551724138, -1.521386892307692 ], [ 29.737126551724138, -1.521117415384615 ], [ 29.736857068965517, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11110, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.521117415384615 ], [ 29.737126551724138, -1.521386892307692 ], [ 29.73739603448276, -1.521386892307692 ], [ 29.73739603448276, -1.521117415384615 ], [ 29.737126551724138, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11111, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.521117415384615 ], [ 29.73739603448276, -1.521386892307692 ], [ 29.737665517241378, -1.521386892307692 ], [ 29.737665517241378, -1.521117415384615 ], [ 29.73739603448276, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11112, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.521117415384615 ], [ 29.737665517241378, -1.521386892307692 ], [ 29.737935, -1.521386892307692 ], [ 29.737935, -1.521117415384615 ], [ 29.737665517241378, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11113, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.521117415384615 ], [ 29.737935, -1.521386892307692 ], [ 29.738204482758622, -1.521386892307692 ], [ 29.738204482758622, -1.521117415384615 ], [ 29.737935, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11114, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.521117415384615 ], [ 29.738204482758622, -1.521386892307692 ], [ 29.738743448275862, -1.521386892307692 ], [ 29.738743448275862, -1.521117415384615 ], [ 29.738204482758622, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11115, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.521117415384615 ], [ 29.738743448275862, -1.521386892307692 ], [ 29.739012931034484, -1.521386892307692 ], [ 29.739012931034484, -1.521117415384615 ], [ 29.738743448275862, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11116, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.521117415384615 ], [ 29.739012931034484, -1.521386892307692 ], [ 29.739282413793102, -1.521386892307692 ], [ 29.739282413793102, -1.521117415384615 ], [ 29.739012931034484, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11117, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.521117415384615 ], [ 29.739282413793102, -1.521386892307692 ], [ 29.739551896551724, -1.521386892307692 ], [ 29.739551896551724, -1.521117415384615 ], [ 29.739282413793102, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11118, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.521117415384615 ], [ 29.739551896551724, -1.521386892307692 ], [ 29.739821379310346, -1.521386892307692 ], [ 29.739821379310346, -1.521117415384615 ], [ 29.739551896551724, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11119, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.521117415384615 ], [ 29.739821379310346, -1.521386892307692 ], [ 29.740090862068964, -1.521386892307692 ], [ 29.740090862068964, -1.521117415384615 ], [ 29.739821379310346, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11120, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.521117415384615 ], [ 29.740090862068964, -1.521386892307692 ], [ 29.740360344827586, -1.521386892307692 ], [ 29.740360344827586, -1.521117415384615 ], [ 29.740090862068964, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11121, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.521117415384615 ], [ 29.740360344827586, -1.521386892307692 ], [ 29.740629827586208, -1.521386892307692 ], [ 29.740629827586208, -1.521117415384615 ], [ 29.740360344827586, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11122, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.521117415384615 ], [ 29.740629827586208, -1.521386892307692 ], [ 29.74089931034483, -1.521386892307692 ], [ 29.74089931034483, -1.521117415384615 ], [ 29.740629827586208, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11123, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.521117415384615 ], [ 29.74089931034483, -1.521386892307692 ], [ 29.741168793103448, -1.521386892307692 ], [ 29.741168793103448, -1.521117415384615 ], [ 29.74089931034483, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11124, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.521117415384615 ], [ 29.741168793103448, -1.521386892307692 ], [ 29.74143827586207, -1.521386892307692 ], [ 29.74143827586207, -1.521117415384615 ], [ 29.741168793103448, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11125, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.521117415384615 ], [ 29.74143827586207, -1.521386892307692 ], [ 29.741707758620691, -1.521386892307692 ], [ 29.741707758620691, -1.521117415384615 ], [ 29.74143827586207, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11126, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.521117415384615 ], [ 29.741707758620691, -1.521386892307692 ], [ 29.742246724137932, -1.521386892307692 ], [ 29.742246724137932, -1.521117415384615 ], [ 29.741707758620691, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11127, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.521117415384615 ], [ 29.758146206896551, -1.521656369230769 ], [ 29.758415689655173, -1.521656369230769 ], [ 29.758415689655173, -1.521117415384615 ], [ 29.758146206896551, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11128, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.521117415384615 ], [ 29.758415689655173, -1.521656369230769 ], [ 29.758685172413795, -1.521656369230769 ], [ 29.758685172413795, -1.521117415384615 ], [ 29.758415689655173, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11129, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.521117415384615 ], [ 29.758685172413795, -1.521656369230769 ], [ 29.758954655172413, -1.521656369230769 ], [ 29.758954655172413, -1.521117415384615 ], [ 29.758685172413795, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11130, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.521117415384615 ], [ 29.759224137931035, -1.521386892307692 ], [ 29.759493620689657, -1.521386892307692 ], [ 29.759493620689657, -1.521117415384615 ], [ 29.759224137931035, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11131, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.521117415384615 ], [ 29.759493620689657, -1.521386892307692 ], [ 29.759763103448275, -1.521386892307692 ], [ 29.759763103448275, -1.521117415384615 ], [ 29.759493620689657, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11132, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.521117415384615 ], [ 29.759763103448275, -1.521386892307692 ], [ 29.760032586206897, -1.521386892307692 ], [ 29.760032586206897, -1.521117415384615 ], [ 29.759763103448275, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11133, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.521117415384615 ], [ 29.760841034482759, -1.521386892307692 ], [ 29.761110517241381, -1.521386892307692 ], [ 29.761110517241381, -1.521117415384615 ], [ 29.760841034482759, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11134, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.521117415384615 ], [ 29.761110517241381, -1.521386892307692 ], [ 29.76138, -1.521386892307692 ], [ 29.76138, -1.521117415384615 ], [ 29.761110517241381, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11135, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.521117415384615 ], [ 29.76138, -1.521386892307692 ], [ 29.761649482758621, -1.521386892307692 ], [ 29.761649482758621, -1.521117415384615 ], [ 29.76138, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11136, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.521117415384615 ], [ 29.761649482758621, -1.521386892307692 ], [ 29.761918965517243, -1.521386892307692 ], [ 29.761918965517243, -1.521117415384615 ], [ 29.761649482758621, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11137, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.521117415384615 ], [ 29.761918965517243, -1.521386892307692 ], [ 29.762188448275861, -1.521386892307692 ], [ 29.762188448275861, -1.521117415384615 ], [ 29.761918965517243, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11138, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.521117415384615 ], [ 29.762188448275861, -1.521656369230769 ], [ 29.762457931034483, -1.521656369230769 ], [ 29.762457931034483, -1.521117415384615 ], [ 29.762188448275861, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11139, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.521117415384615 ], [ 29.762727413793105, -1.521386892307692 ], [ 29.762996896551723, -1.521386892307692 ], [ 29.762996896551723, -1.521117415384615 ], [ 29.762727413793105, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11140, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.521117415384615 ], [ 29.762996896551723, -1.521386892307692 ], [ 29.763266379310345, -1.521386892307692 ], [ 29.763266379310345, -1.521117415384615 ], [ 29.762996896551723, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11141, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.521117415384615 ], [ 29.763266379310345, -1.521386892307692 ], [ 29.763535862068967, -1.521386892307692 ], [ 29.763535862068967, -1.521117415384615 ], [ 29.763266379310345, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11142, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.521117415384615 ], [ 29.764074827586207, -1.521386892307692 ], [ 29.764344310344828, -1.521386892307692 ], [ 29.764344310344828, -1.521117415384615 ], [ 29.764074827586207, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11143, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.521117415384615 ], [ 29.764344310344828, -1.521386892307692 ], [ 29.76461379310345, -1.521386892307692 ], [ 29.76461379310345, -1.521117415384615 ], [ 29.764344310344828, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11144, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.521117415384615 ], [ 29.76461379310345, -1.521386892307692 ], [ 29.764883275862069, -1.521386892307692 ], [ 29.764883275862069, -1.521117415384615 ], [ 29.76461379310345, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11145, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.521117415384615 ], [ 29.76515275862069, -1.521656369230769 ], [ 29.765422241379312, -1.521656369230769 ], [ 29.765422241379312, -1.521117415384615 ], [ 29.76515275862069, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11146, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.521117415384615 ], [ 29.765422241379312, -1.521386892307692 ], [ 29.765961206896552, -1.521386892307692 ], [ 29.765961206896552, -1.521117415384615 ], [ 29.765422241379312, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11147, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.521117415384615 ], [ 29.765961206896552, -1.521386892307692 ], [ 29.766230689655174, -1.521386892307692 ], [ 29.766230689655174, -1.521117415384615 ], [ 29.765961206896552, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11148, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.521117415384615 ], [ 29.766230689655174, -1.521386892307692 ], [ 29.766500172413792, -1.521386892307692 ], [ 29.766500172413792, -1.521117415384615 ], [ 29.766230689655174, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11149, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.521117415384615 ], [ 29.766500172413792, -1.521386892307692 ], [ 29.766769655172414, -1.521386892307692 ], [ 29.766769655172414, -1.521117415384615 ], [ 29.766500172413792, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11150, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.521117415384615 ], [ 29.766769655172414, -1.521386892307692 ], [ 29.767039137931036, -1.521386892307692 ], [ 29.767039137931036, -1.521117415384615 ], [ 29.766769655172414, -1.521117415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11151, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.710986724137932, -1.521386892307692 ], [ 29.710986724137932, -1.521656369230769 ], [ 29.712064655172412, -1.521656369230769 ], [ 29.712064655172412, -1.521386892307692 ], [ 29.710986724137932, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11152, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712064655172412, -1.521386892307692 ], [ 29.712064655172412, -1.521656369230769 ], [ 29.712334137931034, -1.521656369230769 ], [ 29.712334137931034, -1.521386892307692 ], [ 29.712064655172412, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11153, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.521386892307692 ], [ 29.713142586206896, -1.521656369230769 ], [ 29.713412068965518, -1.521656369230769 ], [ 29.713412068965518, -1.521386892307692 ], [ 29.713142586206896, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11154, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.521386892307692 ], [ 29.734970689655171, -1.521656369230769 ], [ 29.735240172413793, -1.521656369230769 ], [ 29.735240172413793, -1.521386892307692 ], [ 29.734970689655171, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11155, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.521386892307692 ], [ 29.735240172413793, -1.521925846153846 ], [ 29.735509655172415, -1.521925846153846 ], [ 29.735509655172415, -1.521386892307692 ], [ 29.735240172413793, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11156, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.521386892307692 ], [ 29.735509655172415, -1.521925846153846 ], [ 29.735779137931036, -1.521925846153846 ], [ 29.735779137931036, -1.521386892307692 ], [ 29.735509655172415, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11157, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.521386892307692 ], [ 29.736048620689655, -1.521656369230769 ], [ 29.736318103448276, -1.521656369230769 ], [ 29.736318103448276, -1.521386892307692 ], [ 29.736048620689655, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11158, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.521386892307692 ], [ 29.736318103448276, -1.521656369230769 ], [ 29.736587586206898, -1.521656369230769 ], [ 29.736587586206898, -1.521386892307692 ], [ 29.736318103448276, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11159, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.521386892307692 ], [ 29.736587586206898, -1.521656369230769 ], [ 29.736857068965517, -1.521656369230769 ], [ 29.736857068965517, -1.521386892307692 ], [ 29.736587586206898, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11160, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.521386892307692 ], [ 29.736857068965517, -1.521656369230769 ], [ 29.737126551724138, -1.521656369230769 ], [ 29.737126551724138, -1.521386892307692 ], [ 29.736857068965517, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11161, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.521386892307692 ], [ 29.737126551724138, -1.521656369230769 ], [ 29.73739603448276, -1.521656369230769 ], [ 29.73739603448276, -1.521386892307692 ], [ 29.737126551724138, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11162, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.521386892307692 ], [ 29.73739603448276, -1.521656369230769 ], [ 29.737665517241378, -1.521656369230769 ], [ 29.737665517241378, -1.521386892307692 ], [ 29.73739603448276, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11163, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.521386892307692 ], [ 29.737665517241378, -1.521925846153846 ], [ 29.737935, -1.521925846153846 ], [ 29.737935, -1.522195323076923 ], [ 29.738204482758622, -1.522195323076923 ], [ 29.738204482758622, -1.521656369230769 ], [ 29.737935, -1.521656369230769 ], [ 29.737935, -1.521386892307692 ], [ 29.737665517241378, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11164, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.521386892307692 ], [ 29.737935, -1.521656369230769 ], [ 29.738204482758622, -1.521656369230769 ], [ 29.738204482758622, -1.521386892307692 ], [ 29.737935, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11165, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.521386892307692 ], [ 29.738204482758622, -1.521656369230769 ], [ 29.738743448275862, -1.521656369230769 ], [ 29.738743448275862, -1.521386892307692 ], [ 29.738204482758622, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11166, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.521386892307692 ], [ 29.738743448275862, -1.521656369230769 ], [ 29.739012931034484, -1.521656369230769 ], [ 29.739012931034484, -1.521386892307692 ], [ 29.738743448275862, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11167, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.521386892307692 ], [ 29.739012931034484, -1.521656369230769 ], [ 29.739282413793102, -1.521656369230769 ], [ 29.739282413793102, -1.521386892307692 ], [ 29.739012931034484, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11168, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.521386892307692 ], [ 29.739282413793102, -1.521656369230769 ], [ 29.739551896551724, -1.521656369230769 ], [ 29.739551896551724, -1.521386892307692 ], [ 29.739282413793102, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11169, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.521386892307692 ], [ 29.739551896551724, -1.521656369230769 ], [ 29.739821379310346, -1.521656369230769 ], [ 29.739821379310346, -1.521386892307692 ], [ 29.739551896551724, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11170, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.521386892307692 ], [ 29.739821379310346, -1.521656369230769 ], [ 29.740090862068964, -1.521656369230769 ], [ 29.740090862068964, -1.521386892307692 ], [ 29.739821379310346, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11171, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.521386892307692 ], [ 29.740090862068964, -1.521925846153846 ], [ 29.740360344827586, -1.521925846153846 ], [ 29.740360344827586, -1.521386892307692 ], [ 29.740090862068964, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11172, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.521386892307692 ], [ 29.740360344827586, -1.521656369230769 ], [ 29.740629827586208, -1.521656369230769 ], [ 29.740629827586208, -1.521386892307692 ], [ 29.740360344827586, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11173, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.521386892307692 ], [ 29.740629827586208, -1.521656369230769 ], [ 29.74089931034483, -1.521656369230769 ], [ 29.74089931034483, -1.521386892307692 ], [ 29.740629827586208, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11174, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.521386892307692 ], [ 29.74089931034483, -1.521656369230769 ], [ 29.741168793103448, -1.521656369230769 ], [ 29.741168793103448, -1.521386892307692 ], [ 29.74089931034483, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11175, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.521386892307692 ], [ 29.741168793103448, -1.521656369230769 ], [ 29.74143827586207, -1.521656369230769 ], [ 29.74143827586207, -1.521386892307692 ], [ 29.741168793103448, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11176, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.521386892307692 ], [ 29.74143827586207, -1.521656369230769 ], [ 29.741707758620691, -1.521656369230769 ], [ 29.741707758620691, -1.521386892307692 ], [ 29.74143827586207, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11177, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.521386892307692 ], [ 29.759224137931035, -1.521656369230769 ], [ 29.759493620689657, -1.521656369230769 ], [ 29.759493620689657, -1.521386892307692 ], [ 29.759224137931035, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11178, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.521386892307692 ], [ 29.759493620689657, -1.521656369230769 ], [ 29.759763103448275, -1.521656369230769 ], [ 29.759763103448275, -1.521386892307692 ], [ 29.759493620689657, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11179, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.521386892307692 ], [ 29.759763103448275, -1.521656369230769 ], [ 29.760032586206897, -1.521656369230769 ], [ 29.760032586206897, -1.521386892307692 ], [ 29.759763103448275, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11180, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.521386892307692 ], [ 29.760032586206897, -1.521656369230769 ], [ 29.760302068965519, -1.521656369230769 ], [ 29.760302068965519, -1.521386892307692 ], [ 29.760032586206897, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11181, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.521386892307692 ], [ 29.760302068965519, -1.521656369230769 ], [ 29.760571551724137, -1.521656369230769 ], [ 29.760571551724137, -1.521386892307692 ], [ 29.760302068965519, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11182, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.521386892307692 ], [ 29.760571551724137, -1.521656369230769 ], [ 29.760841034482759, -1.521656369230769 ], [ 29.760841034482759, -1.521386892307692 ], [ 29.760571551724137, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11183, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.521386892307692 ], [ 29.760841034482759, -1.521656369230769 ], [ 29.761110517241381, -1.521656369230769 ], [ 29.761110517241381, -1.521386892307692 ], [ 29.760841034482759, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11184, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.521386892307692 ], [ 29.761110517241381, -1.521656369230769 ], [ 29.76138, -1.521656369230769 ], [ 29.76138, -1.521386892307692 ], [ 29.761110517241381, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11185, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.521386892307692 ], [ 29.76138, -1.521656369230769 ], [ 29.761649482758621, -1.521656369230769 ], [ 29.761649482758621, -1.521386892307692 ], [ 29.76138, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11186, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.521386892307692 ], [ 29.761649482758621, -1.521656369230769 ], [ 29.761918965517243, -1.521656369230769 ], [ 29.761918965517243, -1.521386892307692 ], [ 29.761649482758621, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11187, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.521386892307692 ], [ 29.761918965517243, -1.521925846153846 ], [ 29.762188448275861, -1.521925846153846 ], [ 29.762188448275861, -1.521386892307692 ], [ 29.761918965517243, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11188, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.521386892307692 ], [ 29.762457931034483, -1.521656369230769 ], [ 29.762727413793105, -1.521656369230769 ], [ 29.762727413793105, -1.521386892307692 ], [ 29.762457931034483, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11189, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.521386892307692 ], [ 29.762727413793105, -1.521656369230769 ], [ 29.762996896551723, -1.521656369230769 ], [ 29.762996896551723, -1.521386892307692 ], [ 29.762727413793105, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11190, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.521386892307692 ], [ 29.762996896551723, -1.521925846153846 ], [ 29.763266379310345, -1.521925846153846 ], [ 29.763266379310345, -1.521386892307692 ], [ 29.762996896551723, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11191, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.521386892307692 ], [ 29.763266379310345, -1.521925846153846 ], [ 29.763535862068967, -1.521925846153846 ], [ 29.763535862068967, -1.521386892307692 ], [ 29.763266379310345, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11192, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.521386892307692 ], [ 29.764074827586207, -1.521925846153846 ], [ 29.764344310344828, -1.521925846153846 ], [ 29.764344310344828, -1.521386892307692 ], [ 29.764074827586207, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11193, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.521386892307692 ], [ 29.764344310344828, -1.521925846153846 ], [ 29.76461379310345, -1.521925846153846 ], [ 29.76461379310345, -1.521386892307692 ], [ 29.764344310344828, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11194, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.521386892307692 ], [ 29.76461379310345, -1.521656369230769 ], [ 29.764883275862069, -1.521656369230769 ], [ 29.764883275862069, -1.521386892307692 ], [ 29.76461379310345, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11195, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.521386892307692 ], [ 29.764883275862069, -1.521656369230769 ], [ 29.76515275862069, -1.521656369230769 ], [ 29.76515275862069, -1.521386892307692 ], [ 29.764883275862069, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11196, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.521386892307692 ], [ 29.765422241379312, -1.521925846153846 ], [ 29.765961206896552, -1.521925846153846 ], [ 29.765961206896552, -1.521386892307692 ], [ 29.765422241379312, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11197, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.521386892307692 ], [ 29.765961206896552, -1.521656369230769 ], [ 29.766230689655174, -1.521656369230769 ], [ 29.766230689655174, -1.521386892307692 ], [ 29.765961206896552, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11198, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.521386892307692 ], [ 29.766230689655174, -1.521656369230769 ], [ 29.766500172413792, -1.521656369230769 ], [ 29.766500172413792, -1.521386892307692 ], [ 29.766230689655174, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11199, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.521386892307692 ], [ 29.766500172413792, -1.521656369230769 ], [ 29.766769655172414, -1.521656369230769 ], [ 29.766769655172414, -1.521386892307692 ], [ 29.766500172413792, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11200, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.521386892307692 ], [ 29.766769655172414, -1.521656369230769 ], [ 29.767039137931036, -1.521656369230769 ], [ 29.767039137931036, -1.521386892307692 ], [ 29.766769655172414, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11201, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.521386892307692 ], [ 29.767039137931036, -1.521656369230769 ], [ 29.767308620689654, -1.521656369230769 ], [ 29.767308620689654, -1.521386892307692 ], [ 29.767039137931036, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11202, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.521386892307692 ], [ 29.767308620689654, -1.521656369230769 ], [ 29.767578103448276, -1.521656369230769 ], [ 29.767578103448276, -1.521386892307692 ], [ 29.767308620689654, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11203, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.521386892307692 ], [ 29.767578103448276, -1.521656369230769 ], [ 29.767847586206898, -1.521656369230769 ], [ 29.767847586206898, -1.521386892307692 ], [ 29.767578103448276, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11204, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.521386892307692 ], [ 29.76865603448276, -1.521925846153846 ], [ 29.768925517241382, -1.521925846153846 ], [ 29.768925517241382, -1.521386892307692 ], [ 29.76865603448276, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11205, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.521386892307692 ], [ 29.769464482758622, -1.521656369230769 ], [ 29.769733965517243, -1.521656369230769 ], [ 29.769733965517243, -1.521386892307692 ], [ 29.769464482758622, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11206, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.521386892307692 ], [ 29.769733965517243, -1.522195323076923 ], [ 29.770003448275862, -1.522195323076923 ], [ 29.770003448275862, -1.521386892307692 ], [ 29.769733965517243, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11207, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712064655172412, -1.521656369230769 ], [ 29.712064655172412, -1.521925846153846 ], [ 29.712603620689656, -1.521925846153846 ], [ 29.712603620689656, -1.521656369230769 ], [ 29.712064655172412, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11208, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.521656369230769 ], [ 29.712603620689656, -1.521925846153846 ], [ 29.712873103448278, -1.521925846153846 ], [ 29.712873103448278, -1.521656369230769 ], [ 29.712603620689656, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11209, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.521656369230769 ], [ 29.734970689655171, -1.522195323076923 ], [ 29.735240172413793, -1.522195323076923 ], [ 29.735240172413793, -1.521656369230769 ], [ 29.734970689655171, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11210, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.521656369230769 ], [ 29.735779137931036, -1.521925846153846 ], [ 29.736048620689655, -1.521925846153846 ], [ 29.736048620689655, -1.521656369230769 ], [ 29.735779137931036, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11211, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.521656369230769 ], [ 29.736048620689655, -1.522195323076923 ], [ 29.736318103448276, -1.522195323076923 ], [ 29.736318103448276, -1.521656369230769 ], [ 29.736048620689655, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11212, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.521656369230769 ], [ 29.736318103448276, -1.521925846153846 ], [ 29.736587586206898, -1.521925846153846 ], [ 29.736587586206898, -1.521656369230769 ], [ 29.736318103448276, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11213, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.521656369230769 ], [ 29.736587586206898, -1.521925846153846 ], [ 29.736857068965517, -1.521925846153846 ], [ 29.736857068965517, -1.521656369230769 ], [ 29.736587586206898, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11214, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.521656369230769 ], [ 29.736857068965517, -1.521925846153846 ], [ 29.737126551724138, -1.521925846153846 ], [ 29.737126551724138, -1.521656369230769 ], [ 29.736857068965517, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11215, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.521656369230769 ], [ 29.738204482758622, -1.521925846153846 ], [ 29.738743448275862, -1.521925846153846 ], [ 29.738743448275862, -1.521656369230769 ], [ 29.738204482758622, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11216, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.521656369230769 ], [ 29.738743448275862, -1.521925846153846 ], [ 29.739012931034484, -1.521925846153846 ], [ 29.739012931034484, -1.521656369230769 ], [ 29.738743448275862, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11217, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.521656369230769 ], [ 29.739012931034484, -1.521925846153846 ], [ 29.739282413793102, -1.521925846153846 ], [ 29.739282413793102, -1.521656369230769 ], [ 29.739012931034484, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11218, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.521656369230769 ], [ 29.739282413793102, -1.521925846153846 ], [ 29.739551896551724, -1.521925846153846 ], [ 29.739551896551724, -1.521656369230769 ], [ 29.739282413793102, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11219, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.521656369230769 ], [ 29.739551896551724, -1.521925846153846 ], [ 29.739821379310346, -1.521925846153846 ], [ 29.739821379310346, -1.521656369230769 ], [ 29.739551896551724, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11220, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.521656369230769 ], [ 29.739821379310346, -1.521925846153846 ], [ 29.740090862068964, -1.521925846153846 ], [ 29.740090862068964, -1.521656369230769 ], [ 29.739821379310346, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11221, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.521656369230769 ], [ 29.740360344827586, -1.522195323076923 ], [ 29.740629827586208, -1.522195323076923 ], [ 29.740629827586208, -1.521656369230769 ], [ 29.740360344827586, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11222, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.521656369230769 ], [ 29.740629827586208, -1.522195323076923 ], [ 29.74089931034483, -1.522195323076923 ], [ 29.74089931034483, -1.521656369230769 ], [ 29.740629827586208, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11223, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.521656369230769 ], [ 29.74089931034483, -1.521925846153846 ], [ 29.741168793103448, -1.521925846153846 ], [ 29.741168793103448, -1.521656369230769 ], [ 29.74089931034483, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11224, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.521656369230769 ], [ 29.741168793103448, -1.521925846153846 ], [ 29.74143827586207, -1.521925846153846 ], [ 29.74143827586207, -1.521656369230769 ], [ 29.741168793103448, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11225, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.521656369230769 ], [ 29.758146206896551, -1.521925846153846 ], [ 29.758415689655173, -1.521925846153846 ], [ 29.758415689655173, -1.521656369230769 ], [ 29.758146206896551, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11226, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.521656369230769 ], [ 29.758415689655173, -1.521925846153846 ], [ 29.758685172413795, -1.521925846153846 ], [ 29.758685172413795, -1.521656369230769 ], [ 29.758415689655173, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11227, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.521656369230769 ], [ 29.758685172413795, -1.521925846153846 ], [ 29.758954655172413, -1.521925846153846 ], [ 29.758954655172413, -1.521656369230769 ], [ 29.758685172413795, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11228, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.521656369230769 ], [ 29.758954655172413, -1.521925846153846 ], [ 29.759224137931035, -1.521925846153846 ], [ 29.759224137931035, -1.521656369230769 ], [ 29.758954655172413, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11229, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.521656369230769 ], [ 29.759224137931035, -1.522195323076923 ], [ 29.759493620689657, -1.522195323076923 ], [ 29.759493620689657, -1.521656369230769 ], [ 29.759224137931035, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11230, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.521656369230769 ], [ 29.759493620689657, -1.521925846153846 ], [ 29.759763103448275, -1.521925846153846 ], [ 29.759763103448275, -1.521656369230769 ], [ 29.759493620689657, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11231, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.521656369230769 ], [ 29.759763103448275, -1.521925846153846 ], [ 29.760032586206897, -1.521925846153846 ], [ 29.760032586206897, -1.521656369230769 ], [ 29.759763103448275, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11232, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.521656369230769 ], [ 29.760032586206897, -1.521925846153846 ], [ 29.760302068965519, -1.521925846153846 ], [ 29.760302068965519, -1.521656369230769 ], [ 29.760032586206897, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11233, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.521656369230769 ], [ 29.760302068965519, -1.521925846153846 ], [ 29.760571551724137, -1.521925846153846 ], [ 29.760571551724137, -1.521656369230769 ], [ 29.760302068965519, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11234, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.521656369230769 ], [ 29.760571551724137, -1.521925846153846 ], [ 29.760841034482759, -1.521925846153846 ], [ 29.760841034482759, -1.521656369230769 ], [ 29.760571551724137, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11235, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.521656369230769 ], [ 29.760841034482759, -1.521925846153846 ], [ 29.761110517241381, -1.521925846153846 ], [ 29.761110517241381, -1.521656369230769 ], [ 29.760841034482759, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11236, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.521656369230769 ], [ 29.761110517241381, -1.521925846153846 ], [ 29.76138, -1.521925846153846 ], [ 29.76138, -1.521656369230769 ], [ 29.761110517241381, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11237, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.521656369230769 ], [ 29.76138, -1.521925846153846 ], [ 29.761649482758621, -1.521925846153846 ], [ 29.761649482758621, -1.521656369230769 ], [ 29.76138, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11238, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.521656369230769 ], [ 29.761649482758621, -1.522195323076923 ], [ 29.761918965517243, -1.522195323076923 ], [ 29.761918965517243, -1.521656369230769 ], [ 29.761649482758621, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11239, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.521656369230769 ], [ 29.762188448275861, -1.521925846153846 ], [ 29.762457931034483, -1.521925846153846 ], [ 29.762457931034483, -1.521656369230769 ], [ 29.762188448275861, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11240, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.521656369230769 ], [ 29.762457931034483, -1.522195323076923 ], [ 29.762727413793105, -1.522195323076923 ], [ 29.762727413793105, -1.521656369230769 ], [ 29.762457931034483, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11241, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.521656369230769 ], [ 29.762727413793105, -1.522195323076923 ], [ 29.762996896551723, -1.522195323076923 ], [ 29.762996896551723, -1.521656369230769 ], [ 29.762727413793105, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11242, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.521656369230769 ], [ 29.763535862068967, -1.522195323076923 ], [ 29.764074827586207, -1.522195323076923 ], [ 29.764074827586207, -1.521656369230769 ], [ 29.763535862068967, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11243, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.521656369230769 ], [ 29.76461379310345, -1.522195323076923 ], [ 29.764883275862069, -1.522195323076923 ], [ 29.764883275862069, -1.521656369230769 ], [ 29.76461379310345, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11244, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.521656369230769 ], [ 29.764883275862069, -1.522195323076923 ], [ 29.76515275862069, -1.522195323076923 ], [ 29.76515275862069, -1.521656369230769 ], [ 29.764883275862069, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11245, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.521656369230769 ], [ 29.76515275862069, -1.521925846153846 ], [ 29.765422241379312, -1.521925846153846 ], [ 29.765422241379312, -1.521656369230769 ], [ 29.76515275862069, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11246, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.521656369230769 ], [ 29.765961206896552, -1.522195323076923 ], [ 29.766230689655174, -1.522195323076923 ], [ 29.766230689655174, -1.521656369230769 ], [ 29.765961206896552, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11247, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.521656369230769 ], [ 29.766230689655174, -1.521925846153846 ], [ 29.766500172413792, -1.521925846153846 ], [ 29.766500172413792, -1.521656369230769 ], [ 29.766230689655174, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11248, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.521656369230769 ], [ 29.766500172413792, -1.521925846153846 ], [ 29.766769655172414, -1.521925846153846 ], [ 29.766769655172414, -1.521656369230769 ], [ 29.766500172413792, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11249, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.521656369230769 ], [ 29.766769655172414, -1.521925846153846 ], [ 29.767039137931036, -1.521925846153846 ], [ 29.767039137931036, -1.521656369230769 ], [ 29.766769655172414, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11250, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.521656369230769 ], [ 29.767039137931036, -1.521925846153846 ], [ 29.767308620689654, -1.521925846153846 ], [ 29.767308620689654, -1.521656369230769 ], [ 29.767039137931036, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11251, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.521656369230769 ], [ 29.767308620689654, -1.521925846153846 ], [ 29.767578103448276, -1.521925846153846 ], [ 29.767578103448276, -1.521656369230769 ], [ 29.767308620689654, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11252, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.521656369230769 ], [ 29.767578103448276, -1.521925846153846 ], [ 29.767847586206898, -1.521925846153846 ], [ 29.767847586206898, -1.521656369230769 ], [ 29.767578103448276, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11253, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.521656369230769 ], [ 29.767847586206898, -1.521925846153846 ], [ 29.768117068965516, -1.521925846153846 ], [ 29.768117068965516, -1.521656369230769 ], [ 29.767847586206898, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11254, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.521656369230769 ], [ 29.768117068965516, -1.521925846153846 ], [ 29.768386551724138, -1.521925846153846 ], [ 29.768386551724138, -1.521656369230769 ], [ 29.768117068965516, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11255, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.521656369230769 ], [ 29.768925517241382, -1.521925846153846 ], [ 29.769195, -1.521925846153846 ], [ 29.769195, -1.521656369230769 ], [ 29.768925517241382, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11256, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.521656369230769 ], [ 29.769195, -1.521925846153846 ], [ 29.769464482758622, -1.521925846153846 ], [ 29.769464482758622, -1.521656369230769 ], [ 29.769195, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11257, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.521656369230769 ], [ 29.769464482758622, -1.521925846153846 ], [ 29.769733965517243, -1.521925846153846 ], [ 29.769733965517243, -1.521656369230769 ], [ 29.769464482758622, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11258, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.521925846153846 ], [ 29.735240172413793, -1.522195323076923 ], [ 29.735509655172415, -1.522195323076923 ], [ 29.735509655172415, -1.521925846153846 ], [ 29.735240172413793, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11259, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.521925846153846 ], [ 29.739012931034484, -1.522195323076923 ], [ 29.739282413793102, -1.522195323076923 ], [ 29.739282413793102, -1.521925846153846 ], [ 29.739012931034484, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11260, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.521925846153846 ], [ 29.739282413793102, -1.522195323076923 ], [ 29.739551896551724, -1.522195323076923 ], [ 29.739551896551724, -1.521925846153846 ], [ 29.739282413793102, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11261, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.521925846153846 ], [ 29.739551896551724, -1.522195323076923 ], [ 29.739821379310346, -1.522195323076923 ], [ 29.739821379310346, -1.521925846153846 ], [ 29.739551896551724, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11262, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.521925846153846 ], [ 29.740090862068964, -1.522195323076923 ], [ 29.740360344827586, -1.522195323076923 ], [ 29.740360344827586, -1.521925846153846 ], [ 29.740090862068964, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11263, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.521925846153846 ], [ 29.758146206896551, -1.522195323076923 ], [ 29.758415689655173, -1.522195323076923 ], [ 29.758415689655173, -1.521925846153846 ], [ 29.758146206896551, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11264, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.521925846153846 ], [ 29.758415689655173, -1.522195323076923 ], [ 29.758685172413795, -1.522195323076923 ], [ 29.758685172413795, -1.521925846153846 ], [ 29.758415689655173, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11265, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.521925846153846 ], [ 29.759493620689657, -1.522195323076923 ], [ 29.759763103448275, -1.522195323076923 ], [ 29.759763103448275, -1.521925846153846 ], [ 29.759493620689657, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11266, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.521925846153846 ], [ 29.759763103448275, -1.522195323076923 ], [ 29.760032586206897, -1.522195323076923 ], [ 29.760032586206897, -1.521925846153846 ], [ 29.759763103448275, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11267, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.521925846153846 ], [ 29.760032586206897, -1.522195323076923 ], [ 29.760302068965519, -1.522195323076923 ], [ 29.760302068965519, -1.521925846153846 ], [ 29.760032586206897, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11268, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.521925846153846 ], [ 29.760302068965519, -1.522195323076923 ], [ 29.760571551724137, -1.522195323076923 ], [ 29.760571551724137, -1.521925846153846 ], [ 29.760302068965519, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11269, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.521925846153846 ], [ 29.760571551724137, -1.522195323076923 ], [ 29.760841034482759, -1.522195323076923 ], [ 29.760841034482759, -1.521925846153846 ], [ 29.760571551724137, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11270, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.521925846153846 ], [ 29.760841034482759, -1.522195323076923 ], [ 29.761110517241381, -1.522195323076923 ], [ 29.761110517241381, -1.521925846153846 ], [ 29.760841034482759, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11271, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.521925846153846 ], [ 29.766769655172414, -1.522195323076923 ], [ 29.767039137931036, -1.522195323076923 ], [ 29.767039137931036, -1.521925846153846 ], [ 29.766769655172414, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11272, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.521925846153846 ], [ 29.767039137931036, -1.522195323076923 ], [ 29.767308620689654, -1.522195323076923 ], [ 29.767308620689654, -1.521925846153846 ], [ 29.767039137931036, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11273, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.521925846153846 ], [ 29.767308620689654, -1.522195323076923 ], [ 29.767578103448276, -1.522195323076923 ], [ 29.767578103448276, -1.521925846153846 ], [ 29.767308620689654, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11274, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.521925846153846 ], [ 29.767578103448276, -1.522195323076923 ], [ 29.767847586206898, -1.522195323076923 ], [ 29.767847586206898, -1.521925846153846 ], [ 29.767578103448276, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11275, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.521925846153846 ], [ 29.767847586206898, -1.522195323076923 ], [ 29.768117068965516, -1.522195323076923 ], [ 29.768117068965516, -1.521925846153846 ], [ 29.767847586206898, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11276, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.521925846153846 ], [ 29.768117068965516, -1.522195323076923 ], [ 29.768386551724138, -1.522195323076923 ], [ 29.768386551724138, -1.521925846153846 ], [ 29.768117068965516, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11277, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.521925846153846 ], [ 29.768386551724138, -1.522195323076923 ], [ 29.76865603448276, -1.522195323076923 ], [ 29.76865603448276, -1.521925846153846 ], [ 29.768386551724138, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11278, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.521925846153846 ], [ 29.76865603448276, -1.522195323076923 ], [ 29.768925517241382, -1.522195323076923 ], [ 29.768925517241382, -1.521925846153846 ], [ 29.76865603448276, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11279, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.521925846153846 ], [ 29.768925517241382, -1.522195323076923 ], [ 29.769195, -1.522195323076923 ], [ 29.769195, -1.521925846153846 ], [ 29.768925517241382, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11280, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.521925846153846 ], [ 29.769195, -1.522195323076923 ], [ 29.769464482758622, -1.522195323076923 ], [ 29.769464482758622, -1.521925846153846 ], [ 29.769195, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11281, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.521925846153846 ], [ 29.769464482758622, -1.522195323076923 ], [ 29.769733965517243, -1.522195323076923 ], [ 29.769733965517243, -1.521925846153846 ], [ 29.769464482758622, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11282, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.520847938461538 ], [ 29.713142586206896, -1.520847938461538 ], [ 29.713142586206896, -1.521656369230769 ], [ 29.713412068965518, -1.521656369230769 ], [ 29.713412068965518, -1.522195323076923 ], [ 29.713142586206896, -1.522195323076923 ], [ 29.713142586206896, -1.5224648 ], [ 29.712873103448278, -1.5224648 ], [ 29.712873103448278, -1.521656369230769 ], [ 29.712334137931034, -1.521656369230769 ], [ 29.712334137931034, -1.521386892307692 ], [ 29.712873103448278, -1.521386892307692 ], [ 29.712873103448278, -1.520847938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11283, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713412068965518, -1.521386892307692 ], [ 29.713412068965518, -1.5224648 ], [ 29.713681551724139, -1.5224648 ], [ 29.713681551724139, -1.521386892307692 ], [ 29.713412068965518, -1.521386892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11284, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.521656369230769 ], [ 29.737126551724138, -1.5224648 ], [ 29.73739603448276, -1.5224648 ], [ 29.73739603448276, -1.521656369230769 ], [ 29.737126551724138, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11285, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.521656369230769 ], [ 29.73739603448276, -1.5224648 ], [ 29.737665517241378, -1.5224648 ], [ 29.737665517241378, -1.521656369230769 ], [ 29.73739603448276, -1.521656369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11286, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712603620689656, -1.521925846153846 ], [ 29.712603620689656, -1.522734276923077 ], [ 29.712873103448278, -1.522734276923077 ], [ 29.712873103448278, -1.521925846153846 ], [ 29.712603620689656, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11287, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.521925846153846 ], [ 29.735509655172415, -1.523003753846154 ], [ 29.735779137931036, -1.523003753846154 ], [ 29.735779137931036, -1.521925846153846 ], [ 29.735509655172415, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11288, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.521925846153846 ], [ 29.735779137931036, -1.523003753846154 ], [ 29.736048620689655, -1.523003753846154 ], [ 29.736048620689655, -1.521925846153846 ], [ 29.735779137931036, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11289, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.521925846153846 ], [ 29.736318103448276, -1.522734276923077 ], [ 29.736587586206898, -1.522734276923077 ], [ 29.736587586206898, -1.521925846153846 ], [ 29.736318103448276, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11290, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.521925846153846 ], [ 29.736587586206898, -1.522734276923077 ], [ 29.736857068965517, -1.522734276923077 ], [ 29.736857068965517, -1.521925846153846 ], [ 29.736587586206898, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11291, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.521925846153846 ], [ 29.736857068965517, -1.522734276923077 ], [ 29.737126551724138, -1.522734276923077 ], [ 29.737126551724138, -1.521925846153846 ], [ 29.736857068965517, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11292, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.521925846153846 ], [ 29.737665517241378, -1.5224648 ], [ 29.737935, -1.5224648 ], [ 29.737935, -1.523273230769231 ], [ 29.738204482758622, -1.523273230769231 ], [ 29.738204482758622, -1.522195323076923 ], [ 29.737935, -1.522195323076923 ], [ 29.737935, -1.521925846153846 ], [ 29.737665517241378, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11293, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.521925846153846 ], [ 29.738204482758622, -1.523273230769231 ], [ 29.738743448275862, -1.523273230769231 ], [ 29.738743448275862, -1.521925846153846 ], [ 29.738204482758622, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11294, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.521925846153846 ], [ 29.738743448275862, -1.523273230769231 ], [ 29.739012931034484, -1.523273230769231 ], [ 29.739012931034484, -1.521925846153846 ], [ 29.738743448275862, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11295, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.521925846153846 ], [ 29.739821379310346, -1.5224648 ], [ 29.740090862068964, -1.5224648 ], [ 29.740090862068964, -1.521925846153846 ], [ 29.739821379310346, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11296, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.521925846153846 ], [ 29.74089931034483, -1.5224648 ], [ 29.741168793103448, -1.5224648 ], [ 29.741168793103448, -1.521925846153846 ], [ 29.74089931034483, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11297, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.521925846153846 ], [ 29.758685172413795, -1.5224648 ], [ 29.758954655172413, -1.5224648 ], [ 29.758954655172413, -1.521925846153846 ], [ 29.758685172413795, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11298, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.521925846153846 ], [ 29.758954655172413, -1.5224648 ], [ 29.759224137931035, -1.5224648 ], [ 29.759224137931035, -1.521925846153846 ], [ 29.758954655172413, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11299, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.521925846153846 ], [ 29.761110517241381, -1.5224648 ], [ 29.76138, -1.5224648 ], [ 29.76138, -1.521925846153846 ], [ 29.761110517241381, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11300, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.521925846153846 ], [ 29.76138, -1.523812184615384 ], [ 29.761649482758621, -1.523812184615384 ], [ 29.761649482758621, -1.521925846153846 ], [ 29.76138, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11301, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.521925846153846 ], [ 29.761918965517243, -1.5224648 ], [ 29.762188448275861, -1.5224648 ], [ 29.762188448275861, -1.521925846153846 ], [ 29.761918965517243, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11302, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.521925846153846 ], [ 29.762188448275861, -1.523542707692308 ], [ 29.762457931034483, -1.523542707692308 ], [ 29.762457931034483, -1.521925846153846 ], [ 29.762188448275861, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11303, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.521925846153846 ], [ 29.762996896551723, -1.522734276923077 ], [ 29.763266379310345, -1.522734276923077 ], [ 29.763266379310345, -1.521925846153846 ], [ 29.762996896551723, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11304, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.521925846153846 ], [ 29.763266379310345, -1.523003753846154 ], [ 29.763535862068967, -1.523003753846154 ], [ 29.763535862068967, -1.521925846153846 ], [ 29.763266379310345, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11305, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.521925846153846 ], [ 29.764074827586207, -1.524081661538462 ], [ 29.764344310344828, -1.524081661538462 ], [ 29.764344310344828, -1.521925846153846 ], [ 29.764074827586207, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11306, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.521925846153846 ], [ 29.764344310344828, -1.522734276923077 ], [ 29.76461379310345, -1.522734276923077 ], [ 29.76461379310345, -1.521925846153846 ], [ 29.764344310344828, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11307, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.521925846153846 ], [ 29.76515275862069, -1.523812184615384 ], [ 29.765422241379312, -1.523812184615384 ], [ 29.765422241379312, -1.521925846153846 ], [ 29.76515275862069, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11308, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.521925846153846 ], [ 29.765422241379312, -1.5224648 ], [ 29.765961206896552, -1.5224648 ], [ 29.765961206896552, -1.521925846153846 ], [ 29.765422241379312, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11309, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.521925846153846 ], [ 29.766230689655174, -1.523812184615384 ], [ 29.766500172413792, -1.523812184615384 ], [ 29.766500172413792, -1.521925846153846 ], [ 29.766230689655174, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11310, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.521925846153846 ], [ 29.766500172413792, -1.5224648 ], [ 29.766769655172414, -1.5224648 ], [ 29.766769655172414, -1.521925846153846 ], [ 29.766500172413792, -1.521925846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11311, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.522195323076923 ], [ 29.713142586206896, -1.5224648 ], [ 29.713412068965518, -1.5224648 ], [ 29.713412068965518, -1.522195323076923 ], [ 29.713142586206896, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11312, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.522195323076923 ], [ 29.735240172413793, -1.523273230769231 ], [ 29.735509655172415, -1.523273230769231 ], [ 29.735509655172415, -1.522195323076923 ], [ 29.735240172413793, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11313, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.522195323076923 ], [ 29.736048620689655, -1.522734276923077 ], [ 29.736318103448276, -1.522734276923077 ], [ 29.736318103448276, -1.522195323076923 ], [ 29.736048620689655, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11314, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.522195323076923 ], [ 29.739012931034484, -1.523003753846154 ], [ 29.739282413793102, -1.523003753846154 ], [ 29.739282413793102, -1.522195323076923 ], [ 29.739012931034484, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11315, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.522195323076923 ], [ 29.739282413793102, -1.523003753846154 ], [ 29.739551896551724, -1.523003753846154 ], [ 29.739551896551724, -1.522195323076923 ], [ 29.739282413793102, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11316, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.522195323076923 ], [ 29.739551896551724, -1.523003753846154 ], [ 29.739821379310346, -1.523003753846154 ], [ 29.739821379310346, -1.522195323076923 ], [ 29.739551896551724, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11317, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.522195323076923 ], [ 29.740090862068964, -1.523003753846154 ], [ 29.740360344827586, -1.523003753846154 ], [ 29.740360344827586, -1.522195323076923 ], [ 29.740090862068964, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11318, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.522195323076923 ], [ 29.740360344827586, -1.523003753846154 ], [ 29.740629827586208, -1.523003753846154 ], [ 29.740629827586208, -1.522195323076923 ], [ 29.740360344827586, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11319, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.522195323076923 ], [ 29.740629827586208, -1.5224648 ], [ 29.74089931034483, -1.5224648 ], [ 29.74089931034483, -1.522195323076923 ], [ 29.740629827586208, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11320, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.522195323076923 ], [ 29.758415689655173, -1.522734276923077 ], [ 29.758685172413795, -1.522734276923077 ], [ 29.758685172413795, -1.522195323076923 ], [ 29.758415689655173, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11321, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.522195323076923 ], [ 29.759224137931035, -1.5224648 ], [ 29.759493620689657, -1.5224648 ], [ 29.759493620689657, -1.522195323076923 ], [ 29.759224137931035, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11322, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.522195323076923 ], [ 29.759493620689657, -1.522734276923077 ], [ 29.759763103448275, -1.522734276923077 ], [ 29.759763103448275, -1.522195323076923 ], [ 29.759493620689657, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11323, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.522195323076923 ], [ 29.759763103448275, -1.5224648 ], [ 29.760032586206897, -1.5224648 ], [ 29.760032586206897, -1.522195323076923 ], [ 29.759763103448275, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11324, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.522195323076923 ], [ 29.760032586206897, -1.5224648 ], [ 29.760302068965519, -1.5224648 ], [ 29.760302068965519, -1.522195323076923 ], [ 29.760032586206897, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11325, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.522195323076923 ], [ 29.760302068965519, -1.5224648 ], [ 29.760571551724137, -1.5224648 ], [ 29.760571551724137, -1.522195323076923 ], [ 29.760302068965519, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11326, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.522195323076923 ], [ 29.760571551724137, -1.524081661538462 ], [ 29.760841034482759, -1.524081661538462 ], [ 29.760841034482759, -1.522195323076923 ], [ 29.760571551724137, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11327, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.522195323076923 ], [ 29.760841034482759, -1.524081661538462 ], [ 29.761110517241381, -1.524081661538462 ], [ 29.761110517241381, -1.522195323076923 ], [ 29.760841034482759, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11328, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.522195323076923 ], [ 29.761649482758621, -1.523812184615384 ], [ 29.761918965517243, -1.523812184615384 ], [ 29.761918965517243, -1.522195323076923 ], [ 29.761649482758621, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11329, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.522195323076923 ], [ 29.762457931034483, -1.523542707692308 ], [ 29.762727413793105, -1.523542707692308 ], [ 29.762727413793105, -1.522195323076923 ], [ 29.762457931034483, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11330, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.522195323076923 ], [ 29.762727413793105, -1.523273230769231 ], [ 29.762996896551723, -1.523273230769231 ], [ 29.762996896551723, -1.522195323076923 ], [ 29.762727413793105, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11331, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.522195323076923 ], [ 29.763535862068967, -1.523003753846154 ], [ 29.763805344827588, -1.523003753846154 ], [ 29.763805344827588, -1.523273230769231 ], [ 29.764074827586207, -1.523273230769231 ], [ 29.764074827586207, -1.522195323076923 ], [ 29.763535862068967, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11332, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.522195323076923 ], [ 29.76461379310345, -1.523542707692308 ], [ 29.764883275862069, -1.523542707692308 ], [ 29.764883275862069, -1.522195323076923 ], [ 29.76461379310345, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11333, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.522195323076923 ], [ 29.764883275862069, -1.523542707692308 ], [ 29.76515275862069, -1.523542707692308 ], [ 29.76515275862069, -1.522195323076923 ], [ 29.764883275862069, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11334, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.522195323076923 ], [ 29.765961206896552, -1.523542707692308 ], [ 29.766230689655174, -1.523542707692308 ], [ 29.766230689655174, -1.522195323076923 ], [ 29.765961206896552, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11335, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.522195323076923 ], [ 29.766769655172414, -1.523542707692308 ], [ 29.767039137931036, -1.523542707692308 ], [ 29.767039137931036, -1.522195323076923 ], [ 29.766769655172414, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11336, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.522195323076923 ], [ 29.767039137931036, -1.523542707692308 ], [ 29.767308620689654, -1.523542707692308 ], [ 29.767308620689654, -1.522195323076923 ], [ 29.767039137931036, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11337, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.522195323076923 ], [ 29.767308620689654, -1.5224648 ], [ 29.767578103448276, -1.5224648 ], [ 29.767578103448276, -1.522195323076923 ], [ 29.767308620689654, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11338, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.522195323076923 ], [ 29.767578103448276, -1.5224648 ], [ 29.767847586206898, -1.5224648 ], [ 29.767847586206898, -1.522195323076923 ], [ 29.767578103448276, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11339, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.522195323076923 ], [ 29.767847586206898, -1.5224648 ], [ 29.768117068965516, -1.5224648 ], [ 29.768117068965516, -1.522195323076923 ], [ 29.767847586206898, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11340, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.522195323076923 ], [ 29.768117068965516, -1.522734276923077 ], [ 29.768386551724138, -1.522734276923077 ], [ 29.768386551724138, -1.522195323076923 ], [ 29.768117068965516, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11341, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.522195323076923 ], [ 29.768386551724138, -1.5224648 ], [ 29.76865603448276, -1.5224648 ], [ 29.76865603448276, -1.522195323076923 ], [ 29.768386551724138, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11342, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.522195323076923 ], [ 29.76865603448276, -1.5224648 ], [ 29.768925517241382, -1.5224648 ], [ 29.768925517241382, -1.522195323076923 ], [ 29.76865603448276, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11343, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.522195323076923 ], [ 29.768925517241382, -1.5224648 ], [ 29.769195, -1.5224648 ], [ 29.769195, -1.522195323076923 ], [ 29.768925517241382, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11344, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.522195323076923 ], [ 29.769195, -1.5224648 ], [ 29.769464482758622, -1.5224648 ], [ 29.769464482758622, -1.522195323076923 ], [ 29.769195, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11345, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.522195323076923 ], [ 29.769464482758622, -1.5224648 ], [ 29.769733965517243, -1.5224648 ], [ 29.769733965517243, -1.522195323076923 ], [ 29.769464482758622, -1.522195323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11346, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.712873103448278, -1.5224648 ], [ 29.712873103448278, -1.522734276923077 ], [ 29.713142586206896, -1.522734276923077 ], [ 29.713142586206896, -1.5224648 ], [ 29.712873103448278, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11347, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.5224648 ], [ 29.737126551724138, -1.522734276923077 ], [ 29.73739603448276, -1.522734276923077 ], [ 29.73739603448276, -1.5224648 ], [ 29.737126551724138, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11348, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.5224648 ], [ 29.73739603448276, -1.522734276923077 ], [ 29.737665517241378, -1.522734276923077 ], [ 29.737665517241378, -1.5224648 ], [ 29.73739603448276, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11349, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.5224648 ], [ 29.737665517241378, -1.522734276923077 ], [ 29.737935, -1.522734276923077 ], [ 29.737935, -1.5224648 ], [ 29.737665517241378, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11350, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.5224648 ], [ 29.739821379310346, -1.522734276923077 ], [ 29.740090862068964, -1.522734276923077 ], [ 29.740090862068964, -1.5224648 ], [ 29.739821379310346, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11351, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.5224648 ], [ 29.740629827586208, -1.522734276923077 ], [ 29.74089931034483, -1.522734276923077 ], [ 29.74089931034483, -1.5224648 ], [ 29.740629827586208, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11352, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5224648 ], [ 29.758685172413795, -1.522734276923077 ], [ 29.758954655172413, -1.522734276923077 ], [ 29.758954655172413, -1.5224648 ], [ 29.758685172413795, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11353, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.5224648 ], [ 29.760032586206897, -1.524351138461538 ], [ 29.760302068965519, -1.524351138461538 ], [ 29.760302068965519, -1.5224648 ], [ 29.760032586206897, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11354, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.5224648 ], [ 29.760302068965519, -1.524351138461538 ], [ 29.760571551724137, -1.524351138461538 ], [ 29.760571551724137, -1.5224648 ], [ 29.760302068965519, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11355, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5224648 ], [ 29.761110517241381, -1.524081661538462 ], [ 29.76138, -1.524081661538462 ], [ 29.76138, -1.5224648 ], [ 29.761110517241381, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11356, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5224648 ], [ 29.761918965517243, -1.523812184615384 ], [ 29.762188448275861, -1.523812184615384 ], [ 29.762188448275861, -1.5224648 ], [ 29.761918965517243, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11357, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.5224648 ], [ 29.765422241379312, -1.523273230769231 ], [ 29.765961206896552, -1.523273230769231 ], [ 29.765961206896552, -1.5224648 ], [ 29.765422241379312, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11358, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5224648 ], [ 29.766500172413792, -1.523273230769231 ], [ 29.766769655172414, -1.523273230769231 ], [ 29.766769655172414, -1.5224648 ], [ 29.766500172413792, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11359, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.5224648 ], [ 29.767308620689654, -1.523273230769231 ], [ 29.767578103448276, -1.523273230769231 ], [ 29.767578103448276, -1.5224648 ], [ 29.767308620689654, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11360, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.5224648 ], [ 29.767578103448276, -1.523273230769231 ], [ 29.767847586206898, -1.523273230769231 ], [ 29.767847586206898, -1.5224648 ], [ 29.767578103448276, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11361, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.5224648 ], [ 29.767847586206898, -1.523273230769231 ], [ 29.768117068965516, -1.523273230769231 ], [ 29.768117068965516, -1.5224648 ], [ 29.767847586206898, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11362, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.5224648 ], [ 29.768386551724138, -1.523273230769231 ], [ 29.76865603448276, -1.523273230769231 ], [ 29.76865603448276, -1.5224648 ], [ 29.768386551724138, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11363, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.5224648 ], [ 29.76865603448276, -1.523273230769231 ], [ 29.768925517241382, -1.523273230769231 ], [ 29.768925517241382, -1.5224648 ], [ 29.76865603448276, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11364, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.5224648 ], [ 29.768925517241382, -1.522734276923077 ], [ 29.769195, -1.522734276923077 ], [ 29.769195, -1.5224648 ], [ 29.768925517241382, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11365, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.5224648 ], [ 29.769195, -1.522734276923077 ], [ 29.769464482758622, -1.522734276923077 ], [ 29.769464482758622, -1.5224648 ], [ 29.769195, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11366, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.713142586206896, -1.5224648 ], [ 29.713412068965518, -1.5224648 ], [ 29.713412068965518, -1.523003753846154 ], [ 29.712873103448278, -1.523003753846154 ], [ 29.712873103448278, -1.522734276923077 ], [ 29.713142586206896, -1.522734276923077 ], [ 29.713142586206896, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11367, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.522734276923077 ], [ 29.736048620689655, -1.523003753846154 ], [ 29.736318103448276, -1.523003753846154 ], [ 29.736318103448276, -1.522734276923077 ], [ 29.736048620689655, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11368, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.522734276923077 ], [ 29.736318103448276, -1.523273230769231 ], [ 29.736587586206898, -1.523273230769231 ], [ 29.736587586206898, -1.522734276923077 ], [ 29.736318103448276, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11369, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.522734276923077 ], [ 29.736587586206898, -1.523003753846154 ], [ 29.736857068965517, -1.523003753846154 ], [ 29.736857068965517, -1.522734276923077 ], [ 29.736587586206898, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11370, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.522734276923077 ], [ 29.736857068965517, -1.523003753846154 ], [ 29.737126551724138, -1.523003753846154 ], [ 29.737126551724138, -1.522734276923077 ], [ 29.736857068965517, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11371, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.522734276923077 ], [ 29.737126551724138, -1.523003753846154 ], [ 29.73739603448276, -1.523003753846154 ], [ 29.73739603448276, -1.522734276923077 ], [ 29.737126551724138, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11372, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.522734276923077 ], [ 29.73739603448276, -1.523273230769231 ], [ 29.737665517241378, -1.523273230769231 ], [ 29.737665517241378, -1.522734276923077 ], [ 29.73739603448276, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11373, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.522734276923077 ], [ 29.737665517241378, -1.523273230769231 ], [ 29.737935, -1.523273230769231 ], [ 29.737935, -1.522734276923077 ], [ 29.737665517241378, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11374, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.522734276923077 ], [ 29.739821379310346, -1.523273230769231 ], [ 29.740090862068964, -1.523273230769231 ], [ 29.740090862068964, -1.522734276923077 ], [ 29.739821379310346, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11375, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.522734276923077 ], [ 29.740629827586208, -1.523003753846154 ], [ 29.74089931034483, -1.523003753846154 ], [ 29.74089931034483, -1.522734276923077 ], [ 29.740629827586208, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11376, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.522734276923077 ], [ 29.74089931034483, -1.523003753846154 ], [ 29.742246724137932, -1.523003753846154 ], [ 29.742246724137932, -1.522734276923077 ], [ 29.74089931034483, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11377, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.522734276923077 ], [ 29.762996896551723, -1.523273230769231 ], [ 29.763266379310345, -1.523273230769231 ], [ 29.763266379310345, -1.522734276923077 ], [ 29.762996896551723, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11378, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.522734276923077 ], [ 29.764344310344828, -1.523003753846154 ], [ 29.76461379310345, -1.523003753846154 ], [ 29.76461379310345, -1.522734276923077 ], [ 29.764344310344828, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11379, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.522734276923077 ], [ 29.768117068965516, -1.523003753846154 ], [ 29.768386551724138, -1.523003753846154 ], [ 29.768386551724138, -1.522734276923077 ], [ 29.768117068965516, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11380, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.522734276923077 ], [ 29.768925517241382, -1.523003753846154 ], [ 29.769195, -1.523003753846154 ], [ 29.769195, -1.522734276923077 ], [ 29.768925517241382, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11381, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.523003753846154 ], [ 29.735509655172415, -1.523273230769231 ], [ 29.735779137931036, -1.523273230769231 ], [ 29.735779137931036, -1.523003753846154 ], [ 29.735509655172415, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11382, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.523003753846154 ], [ 29.735779137931036, -1.524081661538462 ], [ 29.736048620689655, -1.524081661538462 ], [ 29.736048620689655, -1.523003753846154 ], [ 29.735779137931036, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11383, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.523003753846154 ], [ 29.736048620689655, -1.524081661538462 ], [ 29.736318103448276, -1.524081661538462 ], [ 29.736318103448276, -1.523003753846154 ], [ 29.736048620689655, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11384, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.523003753846154 ], [ 29.736587586206898, -1.523812184615384 ], [ 29.736857068965517, -1.523812184615384 ], [ 29.736857068965517, -1.523003753846154 ], [ 29.736587586206898, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11385, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.523003753846154 ], [ 29.736857068965517, -1.523542707692308 ], [ 29.737126551724138, -1.523542707692308 ], [ 29.737126551724138, -1.523003753846154 ], [ 29.736857068965517, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11386, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.523003753846154 ], [ 29.737126551724138, -1.523542707692308 ], [ 29.73739603448276, -1.523542707692308 ], [ 29.73739603448276, -1.523003753846154 ], [ 29.737126551724138, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11387, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.523003753846154 ], [ 29.739012931034484, -1.523273230769231 ], [ 29.739282413793102, -1.523273230769231 ], [ 29.739282413793102, -1.523003753846154 ], [ 29.739012931034484, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11388, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.523003753846154 ], [ 29.739282413793102, -1.523273230769231 ], [ 29.739551896551724, -1.523273230769231 ], [ 29.739551896551724, -1.523003753846154 ], [ 29.739282413793102, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11389, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.523003753846154 ], [ 29.739551896551724, -1.523273230769231 ], [ 29.739821379310346, -1.523273230769231 ], [ 29.739821379310346, -1.523003753846154 ], [ 29.739551896551724, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11390, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.523003753846154 ], [ 29.740090862068964, -1.523273230769231 ], [ 29.740360344827586, -1.523273230769231 ], [ 29.740360344827586, -1.523003753846154 ], [ 29.740090862068964, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11391, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.523003753846154 ], [ 29.740360344827586, -1.523273230769231 ], [ 29.740629827586208, -1.523273230769231 ], [ 29.740629827586208, -1.523003753846154 ], [ 29.740360344827586, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11392, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.523003753846154 ], [ 29.740629827586208, -1.523273230769231 ], [ 29.74197724137931, -1.523273230769231 ], [ 29.74197724137931, -1.523003753846154 ], [ 29.740629827586208, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11393, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.523003753846154 ], [ 29.74197724137931, -1.523273230769231 ], [ 29.742246724137932, -1.523273230769231 ], [ 29.742246724137932, -1.523003753846154 ], [ 29.74197724137931, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11394, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.523003753846154 ], [ 29.742246724137932, -1.523273230769231 ], [ 29.742516206896553, -1.523273230769231 ], [ 29.742516206896553, -1.523003753846154 ], [ 29.742246724137932, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11395, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.523003753846154 ], [ 29.763266379310345, -1.523273230769231 ], [ 29.763535862068967, -1.523273230769231 ], [ 29.763535862068967, -1.523003753846154 ], [ 29.763266379310345, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11396, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.523003753846154 ], [ 29.763535862068967, -1.523273230769231 ], [ 29.763805344827588, -1.523273230769231 ], [ 29.763805344827588, -1.523003753846154 ], [ 29.763535862068967, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11397, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.523003753846154 ], [ 29.764344310344828, -1.523812184615384 ], [ 29.76461379310345, -1.523812184615384 ], [ 29.76461379310345, -1.523003753846154 ], [ 29.764344310344828, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11398, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.523003753846154 ], [ 29.768117068965516, -1.523542707692308 ], [ 29.768386551724138, -1.523542707692308 ], [ 29.768386551724138, -1.523003753846154 ], [ 29.768117068965516, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11399, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.523003753846154 ], [ 29.768925517241382, -1.523273230769231 ], [ 29.769195, -1.523273230769231 ], [ 29.769195, -1.523003753846154 ], [ 29.768925517241382, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11400, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.523003753846154 ], [ 29.769195, -1.523273230769231 ], [ 29.769733965517243, -1.523273230769231 ], [ 29.769733965517243, -1.523003753846154 ], [ 29.769195, -1.523003753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11401, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.523273230769231 ], [ 29.735509655172415, -1.524351138461538 ], [ 29.735779137931036, -1.524351138461538 ], [ 29.735779137931036, -1.523273230769231 ], [ 29.735509655172415, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11402, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.523273230769231 ], [ 29.736318103448276, -1.523812184615384 ], [ 29.736587586206898, -1.523812184615384 ], [ 29.736587586206898, -1.523273230769231 ], [ 29.736318103448276, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11403, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.523273230769231 ], [ 29.73739603448276, -1.523542707692308 ], [ 29.737665517241378, -1.523542707692308 ], [ 29.737665517241378, -1.523273230769231 ], [ 29.73739603448276, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11404, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.523273230769231 ], [ 29.737665517241378, -1.523542707692308 ], [ 29.737935, -1.523542707692308 ], [ 29.737935, -1.523273230769231 ], [ 29.737665517241378, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11405, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.523273230769231 ], [ 29.737935, -1.523542707692308 ], [ 29.738204482758622, -1.523542707692308 ], [ 29.738204482758622, -1.523273230769231 ], [ 29.737935, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11406, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.523273230769231 ], [ 29.738204482758622, -1.523812184615384 ], [ 29.738743448275862, -1.523812184615384 ], [ 29.738743448275862, -1.523273230769231 ], [ 29.738204482758622, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11407, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.523273230769231 ], [ 29.738743448275862, -1.523542707692308 ], [ 29.739012931034484, -1.523542707692308 ], [ 29.739012931034484, -1.523273230769231 ], [ 29.738743448275862, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11408, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.523273230769231 ], [ 29.739012931034484, -1.523542707692308 ], [ 29.739282413793102, -1.523542707692308 ], [ 29.739282413793102, -1.523273230769231 ], [ 29.739012931034484, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11409, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.523273230769231 ], [ 29.739282413793102, -1.523542707692308 ], [ 29.739551896551724, -1.523542707692308 ], [ 29.739551896551724, -1.523273230769231 ], [ 29.739282413793102, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11410, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.523273230769231 ], [ 29.739551896551724, -1.523542707692308 ], [ 29.739821379310346, -1.523542707692308 ], [ 29.739821379310346, -1.523273230769231 ], [ 29.739551896551724, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11411, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.523273230769231 ], [ 29.739821379310346, -1.523542707692308 ], [ 29.740090862068964, -1.523542707692308 ], [ 29.740090862068964, -1.523273230769231 ], [ 29.739821379310346, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11412, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.523273230769231 ], [ 29.740090862068964, -1.523542707692308 ], [ 29.740360344827586, -1.523542707692308 ], [ 29.740360344827586, -1.523273230769231 ], [ 29.740090862068964, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11413, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.523273230769231 ], [ 29.740360344827586, -1.523542707692308 ], [ 29.740629827586208, -1.523542707692308 ], [ 29.740629827586208, -1.523273230769231 ], [ 29.740360344827586, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11414, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.523273230769231 ], [ 29.740629827586208, -1.523542707692308 ], [ 29.74197724137931, -1.523542707692308 ], [ 29.74197724137931, -1.523273230769231 ], [ 29.740629827586208, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11415, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.523273230769231 ], [ 29.74197724137931, -1.523542707692308 ], [ 29.742246724137932, -1.523542707692308 ], [ 29.742246724137932, -1.523273230769231 ], [ 29.74197724137931, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11416, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.523273230769231 ], [ 29.742246724137932, -1.523542707692308 ], [ 29.742516206896553, -1.523542707692308 ], [ 29.742516206896553, -1.523273230769231 ], [ 29.742246724137932, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11417, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.523273230769231 ], [ 29.742516206896553, -1.523542707692308 ], [ 29.743055172413793, -1.523542707692308 ], [ 29.743055172413793, -1.523273230769231 ], [ 29.742516206896553, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11418, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.523273230769231 ], [ 29.762727413793105, -1.523542707692308 ], [ 29.762996896551723, -1.523542707692308 ], [ 29.762996896551723, -1.523273230769231 ], [ 29.762727413793105, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11419, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.523273230769231 ], [ 29.762996896551723, -1.523542707692308 ], [ 29.763266379310345, -1.523542707692308 ], [ 29.763266379310345, -1.523273230769231 ], [ 29.762996896551723, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11420, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.523273230769231 ], [ 29.763266379310345, -1.523542707692308 ], [ 29.763535862068967, -1.523542707692308 ], [ 29.763535862068967, -1.523273230769231 ], [ 29.763266379310345, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11421, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.523273230769231 ], [ 29.763535862068967, -1.523542707692308 ], [ 29.763805344827588, -1.523542707692308 ], [ 29.763805344827588, -1.523273230769231 ], [ 29.763535862068967, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11422, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.523273230769231 ], [ 29.763805344827588, -1.523542707692308 ], [ 29.764074827586207, -1.523542707692308 ], [ 29.764074827586207, -1.523273230769231 ], [ 29.763805344827588, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11423, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.523273230769231 ], [ 29.765422241379312, -1.523812184615384 ], [ 29.765961206896552, -1.523812184615384 ], [ 29.765961206896552, -1.523273230769231 ], [ 29.765422241379312, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11424, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.523273230769231 ], [ 29.766500172413792, -1.523812184615384 ], [ 29.766769655172414, -1.523812184615384 ], [ 29.766769655172414, -1.523273230769231 ], [ 29.766500172413792, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11425, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.523273230769231 ], [ 29.767308620689654, -1.523542707692308 ], [ 29.767578103448276, -1.523542707692308 ], [ 29.767578103448276, -1.523273230769231 ], [ 29.767308620689654, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11426, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.523273230769231 ], [ 29.767578103448276, -1.523542707692308 ], [ 29.767847586206898, -1.523542707692308 ], [ 29.767847586206898, -1.523273230769231 ], [ 29.767578103448276, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11427, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.523273230769231 ], [ 29.767847586206898, -1.523542707692308 ], [ 29.768117068965516, -1.523542707692308 ], [ 29.768117068965516, -1.523273230769231 ], [ 29.767847586206898, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11428, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.523273230769231 ], [ 29.768386551724138, -1.523542707692308 ], [ 29.76865603448276, -1.523542707692308 ], [ 29.76865603448276, -1.523273230769231 ], [ 29.768386551724138, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11429, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.523273230769231 ], [ 29.76865603448276, -1.523542707692308 ], [ 29.768925517241382, -1.523542707692308 ], [ 29.768925517241382, -1.523273230769231 ], [ 29.76865603448276, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11430, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.523273230769231 ], [ 29.768925517241382, -1.523542707692308 ], [ 29.769464482758622, -1.523542707692308 ], [ 29.769464482758622, -1.523273230769231 ], [ 29.768925517241382, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11431, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.523273230769231 ], [ 29.769464482758622, -1.523542707692308 ], [ 29.769733965517243, -1.523542707692308 ], [ 29.769733965517243, -1.523273230769231 ], [ 29.769464482758622, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11432, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.523273230769231 ], [ 29.769733965517243, -1.523542707692308 ], [ 29.770542413793105, -1.523542707692308 ], [ 29.770542413793105, -1.523273230769231 ], [ 29.769733965517243, -1.523273230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11433, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.523542707692308 ], [ 29.736857068965517, -1.523812184615384 ], [ 29.737126551724138, -1.523812184615384 ], [ 29.737126551724138, -1.523542707692308 ], [ 29.736857068965517, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11434, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.523542707692308 ], [ 29.737126551724138, -1.523812184615384 ], [ 29.73739603448276, -1.523812184615384 ], [ 29.73739603448276, -1.523542707692308 ], [ 29.737126551724138, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11435, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.523542707692308 ], [ 29.73739603448276, -1.523812184615384 ], [ 29.737665517241378, -1.523812184615384 ], [ 29.737665517241378, -1.523542707692308 ], [ 29.73739603448276, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11436, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.523542707692308 ], [ 29.737665517241378, -1.523812184615384 ], [ 29.737935, -1.523812184615384 ], [ 29.737935, -1.523542707692308 ], [ 29.737665517241378, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11437, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.523542707692308 ], [ 29.737935, -1.523812184615384 ], [ 29.738204482758622, -1.523812184615384 ], [ 29.738204482758622, -1.523542707692308 ], [ 29.737935, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11438, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.523542707692308 ], [ 29.738743448275862, -1.523812184615384 ], [ 29.739012931034484, -1.523812184615384 ], [ 29.739012931034484, -1.523542707692308 ], [ 29.738743448275862, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11439, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.523542707692308 ], [ 29.739012931034484, -1.523812184615384 ], [ 29.739282413793102, -1.523812184615384 ], [ 29.739282413793102, -1.523542707692308 ], [ 29.739012931034484, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11440, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.523542707692308 ], [ 29.739282413793102, -1.523812184615384 ], [ 29.739551896551724, -1.523812184615384 ], [ 29.739551896551724, -1.523542707692308 ], [ 29.739282413793102, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11441, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.523542707692308 ], [ 29.739551896551724, -1.523812184615384 ], [ 29.739821379310346, -1.523812184615384 ], [ 29.739821379310346, -1.523542707692308 ], [ 29.739551896551724, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11442, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.523542707692308 ], [ 29.739821379310346, -1.523812184615384 ], [ 29.740090862068964, -1.523812184615384 ], [ 29.740090862068964, -1.523542707692308 ], [ 29.739821379310346, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11443, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.523542707692308 ], [ 29.740090862068964, -1.523812184615384 ], [ 29.740360344827586, -1.523812184615384 ], [ 29.740360344827586, -1.523542707692308 ], [ 29.740090862068964, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11444, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.523542707692308 ], [ 29.740360344827586, -1.523812184615384 ], [ 29.74089931034483, -1.523812184615384 ], [ 29.74089931034483, -1.523542707692308 ], [ 29.740360344827586, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11445, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.523542707692308 ], [ 29.74089931034483, -1.523812184615384 ], [ 29.741707758620691, -1.523812184615384 ], [ 29.741707758620691, -1.523542707692308 ], [ 29.74089931034483, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11446, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.523542707692308 ], [ 29.741707758620691, -1.523812184615384 ], [ 29.74197724137931, -1.523812184615384 ], [ 29.74197724137931, -1.523542707692308 ], [ 29.741707758620691, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11447, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.523542707692308 ], [ 29.74197724137931, -1.523812184615384 ], [ 29.742246724137932, -1.523812184615384 ], [ 29.742246724137932, -1.523542707692308 ], [ 29.74197724137931, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11448, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.523542707692308 ], [ 29.742246724137932, -1.523812184615384 ], [ 29.742785689655172, -1.523812184615384 ], [ 29.742785689655172, -1.523542707692308 ], [ 29.742246724137932, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11449, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742785689655172, -1.523542707692308 ], [ 29.742785689655172, -1.523812184615384 ], [ 29.743055172413793, -1.523812184615384 ], [ 29.743055172413793, -1.523542707692308 ], [ 29.742785689655172, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11450, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743055172413793, -1.523542707692308 ], [ 29.743055172413793, -1.523812184615384 ], [ 29.743324655172415, -1.523812184615384 ], [ 29.743324655172415, -1.523542707692308 ], [ 29.743055172413793, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11451, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.523542707692308 ], [ 29.762188448275861, -1.523812184615384 ], [ 29.762457931034483, -1.523812184615384 ], [ 29.762457931034483, -1.523542707692308 ], [ 29.762188448275861, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11452, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.523542707692308 ], [ 29.762457931034483, -1.523812184615384 ], [ 29.762727413793105, -1.523812184615384 ], [ 29.762727413793105, -1.523542707692308 ], [ 29.762457931034483, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11453, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.523542707692308 ], [ 29.762727413793105, -1.523812184615384 ], [ 29.762996896551723, -1.523812184615384 ], [ 29.762996896551723, -1.523542707692308 ], [ 29.762727413793105, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11454, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.523542707692308 ], [ 29.762996896551723, -1.523812184615384 ], [ 29.763266379310345, -1.523812184615384 ], [ 29.763266379310345, -1.523542707692308 ], [ 29.762996896551723, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11455, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.523542707692308 ], [ 29.763266379310345, -1.523812184615384 ], [ 29.763535862068967, -1.523812184615384 ], [ 29.763535862068967, -1.523542707692308 ], [ 29.763266379310345, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11456, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.523542707692308 ], [ 29.763535862068967, -1.523812184615384 ], [ 29.763805344827588, -1.523812184615384 ], [ 29.763805344827588, -1.523542707692308 ], [ 29.763535862068967, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11457, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.523542707692308 ], [ 29.763805344827588, -1.523812184615384 ], [ 29.764074827586207, -1.523812184615384 ], [ 29.764074827586207, -1.523542707692308 ], [ 29.763805344827588, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11458, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.523542707692308 ], [ 29.76461379310345, -1.524081661538462 ], [ 29.764883275862069, -1.524081661538462 ], [ 29.764883275862069, -1.523542707692308 ], [ 29.76461379310345, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11459, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.523542707692308 ], [ 29.764883275862069, -1.524081661538462 ], [ 29.76515275862069, -1.524081661538462 ], [ 29.76515275862069, -1.523542707692308 ], [ 29.764883275862069, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11460, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.523542707692308 ], [ 29.765961206896552, -1.524081661538462 ], [ 29.766230689655174, -1.524081661538462 ], [ 29.766230689655174, -1.523542707692308 ], [ 29.765961206896552, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11461, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.523542707692308 ], [ 29.766769655172414, -1.523812184615384 ], [ 29.767039137931036, -1.523812184615384 ], [ 29.767039137931036, -1.523542707692308 ], [ 29.766769655172414, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11462, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.523542707692308 ], [ 29.767039137931036, -1.523812184615384 ], [ 29.767308620689654, -1.523812184615384 ], [ 29.767308620689654, -1.523542707692308 ], [ 29.767039137931036, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11463, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.523542707692308 ], [ 29.767308620689654, -1.523812184615384 ], [ 29.767578103448276, -1.523812184615384 ], [ 29.767578103448276, -1.523542707692308 ], [ 29.767308620689654, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11464, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.523542707692308 ], [ 29.767578103448276, -1.523812184615384 ], [ 29.767847586206898, -1.523812184615384 ], [ 29.767847586206898, -1.523542707692308 ], [ 29.767578103448276, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11465, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.523542707692308 ], [ 29.767847586206898, -1.523812184615384 ], [ 29.768117068965516, -1.523812184615384 ], [ 29.768117068965516, -1.523542707692308 ], [ 29.767847586206898, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11466, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.523542707692308 ], [ 29.768117068965516, -1.523812184615384 ], [ 29.768386551724138, -1.523812184615384 ], [ 29.768386551724138, -1.523542707692308 ], [ 29.768117068965516, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11467, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.523542707692308 ], [ 29.768386551724138, -1.523812184615384 ], [ 29.76865603448276, -1.523812184615384 ], [ 29.76865603448276, -1.523542707692308 ], [ 29.768386551724138, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11468, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.523542707692308 ], [ 29.76865603448276, -1.523812184615384 ], [ 29.768925517241382, -1.523812184615384 ], [ 29.768925517241382, -1.523542707692308 ], [ 29.76865603448276, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11469, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.523542707692308 ], [ 29.768925517241382, -1.523812184615384 ], [ 29.769464482758622, -1.523812184615384 ], [ 29.769464482758622, -1.523542707692308 ], [ 29.768925517241382, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11470, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.523542707692308 ], [ 29.769464482758622, -1.523812184615384 ], [ 29.770272931034484, -1.523812184615384 ], [ 29.770272931034484, -1.523542707692308 ], [ 29.769464482758622, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11471, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.523542707692308 ], [ 29.770272931034484, -1.523812184615384 ], [ 29.770542413793105, -1.523812184615384 ], [ 29.770542413793105, -1.523542707692308 ], [ 29.770272931034484, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11472, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.523542707692308 ], [ 29.770542413793105, -1.523812184615384 ], [ 29.770811896551724, -1.523812184615384 ], [ 29.770811896551724, -1.524081661538462 ], [ 29.771081379310345, -1.524081661538462 ], [ 29.771081379310345, -1.523812184615384 ], [ 29.771350862068967, -1.523812184615384 ], [ 29.771350862068967, -1.523542707692308 ], [ 29.770542413793105, -1.523542707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11473, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.523812184615384 ], [ 29.736318103448276, -1.524081661538462 ], [ 29.736587586206898, -1.524081661538462 ], [ 29.736587586206898, -1.523812184615384 ], [ 29.736318103448276, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11474, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.523812184615384 ], [ 29.736587586206898, -1.524081661538462 ], [ 29.736857068965517, -1.524081661538462 ], [ 29.736857068965517, -1.523812184615384 ], [ 29.736587586206898, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11475, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.523812184615384 ], [ 29.736857068965517, -1.524081661538462 ], [ 29.737126551724138, -1.524081661538462 ], [ 29.737126551724138, -1.523812184615384 ], [ 29.736857068965517, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11476, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.523812184615384 ], [ 29.737126551724138, -1.524081661538462 ], [ 29.73739603448276, -1.524081661538462 ], [ 29.73739603448276, -1.523812184615384 ], [ 29.737126551724138, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11477, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.523812184615384 ], [ 29.73739603448276, -1.524081661538462 ], [ 29.737665517241378, -1.524081661538462 ], [ 29.737665517241378, -1.523812184615384 ], [ 29.73739603448276, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11478, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.523812184615384 ], [ 29.737665517241378, -1.524081661538462 ], [ 29.737935, -1.524081661538462 ], [ 29.737935, -1.523812184615384 ], [ 29.737665517241378, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11479, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.523812184615384 ], [ 29.737935, -1.524081661538462 ], [ 29.738204482758622, -1.524081661538462 ], [ 29.738204482758622, -1.523812184615384 ], [ 29.737935, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11480, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.523812184615384 ], [ 29.738204482758622, -1.524081661538462 ], [ 29.738743448275862, -1.524081661538462 ], [ 29.738743448275862, -1.523812184615384 ], [ 29.738204482758622, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11481, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.523812184615384 ], [ 29.738743448275862, -1.524351138461538 ], [ 29.739012931034484, -1.524351138461538 ], [ 29.739012931034484, -1.523812184615384 ], [ 29.738743448275862, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11482, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.523812184615384 ], [ 29.739012931034484, -1.524081661538462 ], [ 29.739282413793102, -1.524081661538462 ], [ 29.739282413793102, -1.523812184615384 ], [ 29.739012931034484, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11483, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.523812184615384 ], [ 29.739282413793102, -1.524081661538462 ], [ 29.739551896551724, -1.524081661538462 ], [ 29.739551896551724, -1.523812184615384 ], [ 29.739282413793102, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11484, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.523812184615384 ], [ 29.739551896551724, -1.524081661538462 ], [ 29.739821379310346, -1.524081661538462 ], [ 29.739821379310346, -1.523812184615384 ], [ 29.739551896551724, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11485, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.523812184615384 ], [ 29.739821379310346, -1.524081661538462 ], [ 29.740090862068964, -1.524081661538462 ], [ 29.740090862068964, -1.523812184615384 ], [ 29.739821379310346, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11486, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.523812184615384 ], [ 29.740090862068964, -1.524081661538462 ], [ 29.740360344827586, -1.524081661538462 ], [ 29.740360344827586, -1.523812184615384 ], [ 29.740090862068964, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11487, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.523812184615384 ], [ 29.740360344827586, -1.524081661538462 ], [ 29.740629827586208, -1.524081661538462 ], [ 29.740629827586208, -1.523812184615384 ], [ 29.740360344827586, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11488, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.523812184615384 ], [ 29.741168793103448, -1.524351138461538 ], [ 29.74143827586207, -1.524351138461538 ], [ 29.74143827586207, -1.523812184615384 ], [ 29.741168793103448, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11489, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.523812184615384 ], [ 29.74143827586207, -1.524081661538462 ], [ 29.741707758620691, -1.524081661538462 ], [ 29.741707758620691, -1.523812184615384 ], [ 29.74143827586207, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11490, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.523812184615384 ], [ 29.741707758620691, -1.524081661538462 ], [ 29.74197724137931, -1.524081661538462 ], [ 29.74197724137931, -1.523812184615384 ], [ 29.741707758620691, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11491, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.523812184615384 ], [ 29.74197724137931, -1.524081661538462 ], [ 29.742246724137932, -1.524081661538462 ], [ 29.742246724137932, -1.523812184615384 ], [ 29.74197724137931, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11492, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.523812184615384 ], [ 29.742246724137932, -1.524081661538462 ], [ 29.742516206896553, -1.524081661538462 ], [ 29.742516206896553, -1.523812184615384 ], [ 29.742246724137932, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11493, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742516206896553, -1.523812184615384 ], [ 29.742516206896553, -1.524081661538462 ], [ 29.743055172413793, -1.524081661538462 ], [ 29.743055172413793, -1.523812184615384 ], [ 29.742516206896553, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11494, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.523812184615384 ], [ 29.76138, -1.524081661538462 ], [ 29.761649482758621, -1.524081661538462 ], [ 29.761649482758621, -1.523812184615384 ], [ 29.76138, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11495, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.523812184615384 ], [ 29.761649482758621, -1.524081661538462 ], [ 29.761918965517243, -1.524081661538462 ], [ 29.761918965517243, -1.523812184615384 ], [ 29.761649482758621, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11496, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.523812184615384 ], [ 29.761918965517243, -1.524081661538462 ], [ 29.762188448275861, -1.524081661538462 ], [ 29.762188448275861, -1.523812184615384 ], [ 29.761918965517243, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11497, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.523812184615384 ], [ 29.762188448275861, -1.524081661538462 ], [ 29.762457931034483, -1.524081661538462 ], [ 29.762457931034483, -1.523812184615384 ], [ 29.762188448275861, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11498, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.523812184615384 ], [ 29.762457931034483, -1.524081661538462 ], [ 29.762727413793105, -1.524081661538462 ], [ 29.762727413793105, -1.523812184615384 ], [ 29.762457931034483, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11499, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.523812184615384 ], [ 29.762727413793105, -1.524081661538462 ], [ 29.762996896551723, -1.524081661538462 ], [ 29.762996896551723, -1.523812184615384 ], [ 29.762727413793105, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11500, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.523812184615384 ], [ 29.762996896551723, -1.524081661538462 ], [ 29.763266379310345, -1.524081661538462 ], [ 29.763266379310345, -1.523812184615384 ], [ 29.762996896551723, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11501, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.523812184615384 ], [ 29.763266379310345, -1.524081661538462 ], [ 29.763535862068967, -1.524081661538462 ], [ 29.763535862068967, -1.523812184615384 ], [ 29.763266379310345, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11502, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.523812184615384 ], [ 29.763535862068967, -1.524081661538462 ], [ 29.763805344827588, -1.524081661538462 ], [ 29.763805344827588, -1.523812184615384 ], [ 29.763535862068967, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11503, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.523812184615384 ], [ 29.763805344827588, -1.524081661538462 ], [ 29.764074827586207, -1.524081661538462 ], [ 29.764074827586207, -1.523812184615384 ], [ 29.763805344827588, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11504, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.523812184615384 ], [ 29.76515275862069, -1.524081661538462 ], [ 29.765422241379312, -1.524081661538462 ], [ 29.765422241379312, -1.523812184615384 ], [ 29.76515275862069, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11505, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.523812184615384 ], [ 29.765422241379312, -1.524351138461538 ], [ 29.765961206896552, -1.524351138461538 ], [ 29.765961206896552, -1.523812184615384 ], [ 29.765422241379312, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11506, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.523812184615384 ], [ 29.766230689655174, -1.524081661538462 ], [ 29.766500172413792, -1.524081661538462 ], [ 29.766500172413792, -1.523812184615384 ], [ 29.766230689655174, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11507, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.523812184615384 ], [ 29.766500172413792, -1.524081661538462 ], [ 29.766769655172414, -1.524081661538462 ], [ 29.766769655172414, -1.523812184615384 ], [ 29.766500172413792, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11508, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.523812184615384 ], [ 29.766769655172414, -1.524081661538462 ], [ 29.767039137931036, -1.524081661538462 ], [ 29.767039137931036, -1.523812184615384 ], [ 29.766769655172414, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11509, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.523812184615384 ], [ 29.767039137931036, -1.524081661538462 ], [ 29.767308620689654, -1.524081661538462 ], [ 29.767308620689654, -1.523812184615384 ], [ 29.767039137931036, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11510, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.523812184615384 ], [ 29.767308620689654, -1.524081661538462 ], [ 29.767578103448276, -1.524081661538462 ], [ 29.767578103448276, -1.523812184615384 ], [ 29.767308620689654, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11511, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.523812184615384 ], [ 29.767578103448276, -1.524081661538462 ], [ 29.767847586206898, -1.524081661538462 ], [ 29.767847586206898, -1.523812184615384 ], [ 29.767578103448276, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11512, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.523812184615384 ], [ 29.767847586206898, -1.524081661538462 ], [ 29.768117068965516, -1.524081661538462 ], [ 29.768117068965516, -1.523812184615384 ], [ 29.767847586206898, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11513, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.523812184615384 ], [ 29.768117068965516, -1.524081661538462 ], [ 29.768386551724138, -1.524081661538462 ], [ 29.768386551724138, -1.523812184615384 ], [ 29.768117068965516, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11514, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.523812184615384 ], [ 29.768386551724138, -1.524081661538462 ], [ 29.76865603448276, -1.524081661538462 ], [ 29.76865603448276, -1.523812184615384 ], [ 29.768386551724138, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11515, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.523812184615384 ], [ 29.76865603448276, -1.524081661538462 ], [ 29.769195, -1.524081661538462 ], [ 29.769195, -1.523812184615384 ], [ 29.76865603448276, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11516, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.523812184615384 ], [ 29.769195, -1.524081661538462 ], [ 29.769464482758622, -1.524081661538462 ], [ 29.769464482758622, -1.523812184615384 ], [ 29.769195, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11517, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.523812184615384 ], [ 29.769464482758622, -1.524081661538462 ], [ 29.769733965517243, -1.524081661538462 ], [ 29.769733965517243, -1.524351138461538 ], [ 29.770003448275862, -1.524351138461538 ], [ 29.770003448275862, -1.524081661538462 ], [ 29.770272931034484, -1.524081661538462 ], [ 29.770272931034484, -1.523812184615384 ], [ 29.769464482758622, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11518, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.523812184615384 ], [ 29.770272931034484, -1.524081661538462 ], [ 29.770542413793105, -1.524081661538462 ], [ 29.770542413793105, -1.523812184615384 ], [ 29.770272931034484, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11519, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.523812184615384 ], [ 29.770542413793105, -1.524081661538462 ], [ 29.770811896551724, -1.524081661538462 ], [ 29.770811896551724, -1.523812184615384 ], [ 29.770542413793105, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11520, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.524081661538462 ], [ 29.735779137931036, -1.524351138461538 ], [ 29.736048620689655, -1.524351138461538 ], [ 29.736048620689655, -1.524081661538462 ], [ 29.735779137931036, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11521, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.524081661538462 ], [ 29.736048620689655, -1.524351138461538 ], [ 29.736318103448276, -1.524351138461538 ], [ 29.736318103448276, -1.524081661538462 ], [ 29.736048620689655, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11522, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.524081661538462 ], [ 29.736318103448276, -1.524351138461538 ], [ 29.736587586206898, -1.524351138461538 ], [ 29.736587586206898, -1.524081661538462 ], [ 29.736318103448276, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11523, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.524081661538462 ], [ 29.736587586206898, -1.524351138461538 ], [ 29.736857068965517, -1.524351138461538 ], [ 29.736857068965517, -1.524081661538462 ], [ 29.736587586206898, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11524, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.524081661538462 ], [ 29.736857068965517, -1.524351138461538 ], [ 29.737126551724138, -1.524351138461538 ], [ 29.737126551724138, -1.524081661538462 ], [ 29.736857068965517, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11525, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.524081661538462 ], [ 29.737126551724138, -1.524351138461538 ], [ 29.73739603448276, -1.524351138461538 ], [ 29.73739603448276, -1.524081661538462 ], [ 29.737126551724138, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11526, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.524081661538462 ], [ 29.73739603448276, -1.524351138461538 ], [ 29.737665517241378, -1.524351138461538 ], [ 29.737665517241378, -1.524081661538462 ], [ 29.73739603448276, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11527, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.524081661538462 ], [ 29.737665517241378, -1.524351138461538 ], [ 29.737935, -1.524351138461538 ], [ 29.737935, -1.524081661538462 ], [ 29.737665517241378, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11528, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.524081661538462 ], [ 29.737935, -1.524351138461538 ], [ 29.738204482758622, -1.524351138461538 ], [ 29.738204482758622, -1.524081661538462 ], [ 29.737935, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11529, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.524081661538462 ], [ 29.738204482758622, -1.524351138461538 ], [ 29.738743448275862, -1.524351138461538 ], [ 29.738743448275862, -1.524081661538462 ], [ 29.738204482758622, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11530, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.524081661538462 ], [ 29.739012931034484, -1.524351138461538 ], [ 29.739282413793102, -1.524351138461538 ], [ 29.739282413793102, -1.524081661538462 ], [ 29.739012931034484, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11531, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.524081661538462 ], [ 29.739282413793102, -1.524351138461538 ], [ 29.739551896551724, -1.524351138461538 ], [ 29.739551896551724, -1.524081661538462 ], [ 29.739282413793102, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11532, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.524081661538462 ], [ 29.739551896551724, -1.524351138461538 ], [ 29.739821379310346, -1.524351138461538 ], [ 29.739821379310346, -1.524081661538462 ], [ 29.739551896551724, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11533, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.524081661538462 ], [ 29.739821379310346, -1.524351138461538 ], [ 29.740090862068964, -1.524351138461538 ], [ 29.740090862068964, -1.524081661538462 ], [ 29.739821379310346, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11534, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.524081661538462 ], [ 29.740090862068964, -1.524351138461538 ], [ 29.740360344827586, -1.524351138461538 ], [ 29.740360344827586, -1.524081661538462 ], [ 29.740090862068964, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11535, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.524081661538462 ], [ 29.740360344827586, -1.524351138461538 ], [ 29.740629827586208, -1.524351138461538 ], [ 29.740629827586208, -1.524081661538462 ], [ 29.740360344827586, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11536, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.524081661538462 ], [ 29.74197724137931, -1.524351138461538 ], [ 29.742246724137932, -1.524351138461538 ], [ 29.742246724137932, -1.524081661538462 ], [ 29.74197724137931, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11537, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.742246724137932, -1.524081661538462 ], [ 29.742246724137932, -1.524351138461538 ], [ 29.742516206896553, -1.524351138461538 ], [ 29.742516206896553, -1.524081661538462 ], [ 29.742246724137932, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11538, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.524081661538462 ], [ 29.760571551724137, -1.524351138461538 ], [ 29.760841034482759, -1.524351138461538 ], [ 29.760841034482759, -1.524081661538462 ], [ 29.760571551724137, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11539, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.524081661538462 ], [ 29.760841034482759, -1.524351138461538 ], [ 29.761110517241381, -1.524351138461538 ], [ 29.761110517241381, -1.524081661538462 ], [ 29.760841034482759, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11540, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.524081661538462 ], [ 29.761110517241381, -1.524351138461538 ], [ 29.76138, -1.524351138461538 ], [ 29.76138, -1.524081661538462 ], [ 29.761110517241381, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11541, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.524081661538462 ], [ 29.76138, -1.524351138461538 ], [ 29.761649482758621, -1.524351138461538 ], [ 29.761649482758621, -1.524081661538462 ], [ 29.76138, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11542, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.524081661538462 ], [ 29.761649482758621, -1.524351138461538 ], [ 29.761918965517243, -1.524351138461538 ], [ 29.761918965517243, -1.524081661538462 ], [ 29.761649482758621, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11543, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.524081661538462 ], [ 29.761918965517243, -1.524351138461538 ], [ 29.762188448275861, -1.524351138461538 ], [ 29.762188448275861, -1.524081661538462 ], [ 29.761918965517243, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11544, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.524081661538462 ], [ 29.762188448275861, -1.524351138461538 ], [ 29.762457931034483, -1.524351138461538 ], [ 29.762457931034483, -1.524081661538462 ], [ 29.762188448275861, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11545, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.524081661538462 ], [ 29.762457931034483, -1.524351138461538 ], [ 29.762727413793105, -1.524351138461538 ], [ 29.762727413793105, -1.524081661538462 ], [ 29.762457931034483, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11546, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.524081661538462 ], [ 29.762727413793105, -1.524351138461538 ], [ 29.762996896551723, -1.524351138461538 ], [ 29.762996896551723, -1.524081661538462 ], [ 29.762727413793105, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11547, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.524081661538462 ], [ 29.762996896551723, -1.524351138461538 ], [ 29.763266379310345, -1.524351138461538 ], [ 29.763266379310345, -1.524081661538462 ], [ 29.762996896551723, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11548, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.524081661538462 ], [ 29.763266379310345, -1.524351138461538 ], [ 29.763535862068967, -1.524351138461538 ], [ 29.763535862068967, -1.524081661538462 ], [ 29.763266379310345, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11549, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.524081661538462 ], [ 29.763535862068967, -1.524351138461538 ], [ 29.763805344827588, -1.524351138461538 ], [ 29.763805344827588, -1.524081661538462 ], [ 29.763535862068967, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11550, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.524081661538462 ], [ 29.763805344827588, -1.524351138461538 ], [ 29.764074827586207, -1.524351138461538 ], [ 29.764074827586207, -1.524081661538462 ], [ 29.763805344827588, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11551, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.523812184615384 ], [ 29.76461379310345, -1.523812184615384 ], [ 29.76461379310345, -1.524351138461538 ], [ 29.764074827586207, -1.524351138461538 ], [ 29.764074827586207, -1.524081661538462 ], [ 29.764344310344828, -1.524081661538462 ], [ 29.764344310344828, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11552, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.524081661538462 ], [ 29.76461379310345, -1.524351138461538 ], [ 29.764883275862069, -1.524351138461538 ], [ 29.764883275862069, -1.524081661538462 ], [ 29.76461379310345, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11553, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.524081661538462 ], [ 29.764883275862069, -1.524351138461538 ], [ 29.76515275862069, -1.524351138461538 ], [ 29.76515275862069, -1.524081661538462 ], [ 29.764883275862069, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11554, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.524081661538462 ], [ 29.765961206896552, -1.524351138461538 ], [ 29.766230689655174, -1.524351138461538 ], [ 29.766230689655174, -1.524081661538462 ], [ 29.765961206896552, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11555, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.524081661538462 ], [ 29.766230689655174, -1.524351138461538 ], [ 29.766500172413792, -1.524351138461538 ], [ 29.766500172413792, -1.524081661538462 ], [ 29.766230689655174, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11556, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.524081661538462 ], [ 29.766500172413792, -1.524351138461538 ], [ 29.766769655172414, -1.524351138461538 ], [ 29.766769655172414, -1.524081661538462 ], [ 29.766500172413792, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11557, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.524081661538462 ], [ 29.766769655172414, -1.524351138461538 ], [ 29.767039137931036, -1.524351138461538 ], [ 29.767039137931036, -1.524081661538462 ], [ 29.766769655172414, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11558, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.524081661538462 ], [ 29.767039137931036, -1.524351138461538 ], [ 29.767308620689654, -1.524351138461538 ], [ 29.767308620689654, -1.524081661538462 ], [ 29.767039137931036, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11559, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.524081661538462 ], [ 29.767308620689654, -1.524351138461538 ], [ 29.767578103448276, -1.524351138461538 ], [ 29.767578103448276, -1.524081661538462 ], [ 29.767308620689654, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11560, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.524081661538462 ], [ 29.767578103448276, -1.524351138461538 ], [ 29.767847586206898, -1.524351138461538 ], [ 29.767847586206898, -1.524081661538462 ], [ 29.767578103448276, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11561, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.524081661538462 ], [ 29.767847586206898, -1.524351138461538 ], [ 29.768117068965516, -1.524351138461538 ], [ 29.768117068965516, -1.524081661538462 ], [ 29.767847586206898, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11562, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.524081661538462 ], [ 29.768117068965516, -1.524351138461538 ], [ 29.768386551724138, -1.524351138461538 ], [ 29.768386551724138, -1.524081661538462 ], [ 29.768117068965516, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11563, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.524081661538462 ], [ 29.768386551724138, -1.524351138461538 ], [ 29.76865603448276, -1.524351138461538 ], [ 29.76865603448276, -1.524081661538462 ], [ 29.768386551724138, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11564, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.524081661538462 ], [ 29.768925517241382, -1.524351138461538 ], [ 29.769195, -1.524351138461538 ], [ 29.769195, -1.524081661538462 ], [ 29.768925517241382, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11565, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.524081661538462 ], [ 29.769195, -1.524351138461538 ], [ 29.769464482758622, -1.524351138461538 ], [ 29.769464482758622, -1.524081661538462 ], [ 29.769195, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11566, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.524081661538462 ], [ 29.769464482758622, -1.524351138461538 ], [ 29.769733965517243, -1.524351138461538 ], [ 29.769733965517243, -1.524081661538462 ], [ 29.769464482758622, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11567, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.524081661538462 ], [ 29.770003448275862, -1.524351138461538 ], [ 29.770272931034484, -1.524351138461538 ], [ 29.770272931034484, -1.524081661538462 ], [ 29.770003448275862, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11568, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.524081661538462 ], [ 29.770272931034484, -1.524351138461538 ], [ 29.770542413793105, -1.524351138461538 ], [ 29.770542413793105, -1.524081661538462 ], [ 29.770272931034484, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11569, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.524081661538462 ], [ 29.770542413793105, -1.524351138461538 ], [ 29.770811896551724, -1.524351138461538 ], [ 29.770811896551724, -1.524081661538462 ], [ 29.770542413793105, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11570, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.5224648 ], [ 29.758954655172413, -1.524890092307692 ], [ 29.759224137931035, -1.524890092307692 ], [ 29.759224137931035, -1.5224648 ], [ 29.758954655172413, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11571, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.5224648 ], [ 29.759224137931035, -1.524890092307692 ], [ 29.759493620689657, -1.524890092307692 ], [ 29.759493620689657, -1.5224648 ], [ 29.759224137931035, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11572, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.5224648 ], [ 29.759763103448275, -1.524620615384615 ], [ 29.760032586206897, -1.524620615384615 ], [ 29.760032586206897, -1.5224648 ], [ 29.759763103448275, -1.5224648 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11573, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.522734276923077 ], [ 29.758685172413795, -1.525159569230769 ], [ 29.758954655172413, -1.525159569230769 ], [ 29.758954655172413, -1.522734276923077 ], [ 29.758685172413795, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11574, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.522734276923077 ], [ 29.759493620689657, -1.524620615384615 ], [ 29.759763103448275, -1.524620615384615 ], [ 29.759763103448275, -1.522734276923077 ], [ 29.759493620689657, -1.522734276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11575, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.523812184615384 ], [ 29.740629827586208, -1.524081661538462 ], [ 29.74089931034483, -1.524081661538462 ], [ 29.74089931034483, -1.524890092307692 ], [ 29.741168793103448, -1.524890092307692 ], [ 29.741168793103448, -1.523812184615384 ], [ 29.740629827586208, -1.523812184615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11576, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.524081661538462 ], [ 29.740629827586208, -1.524890092307692 ], [ 29.74089931034483, -1.524890092307692 ], [ 29.74089931034483, -1.524081661538462 ], [ 29.740629827586208, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11577, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.524081661538462 ], [ 29.74143827586207, -1.525159569230769 ], [ 29.741707758620691, -1.525159569230769 ], [ 29.741707758620691, -1.524081661538462 ], [ 29.74143827586207, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11578, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.524081661538462 ], [ 29.741707758620691, -1.525159569230769 ], [ 29.74197724137931, -1.525159569230769 ], [ 29.74197724137931, -1.524081661538462 ], [ 29.741707758620691, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11579, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.524081661538462 ], [ 29.76515275862069, -1.524620615384615 ], [ 29.765422241379312, -1.524620615384615 ], [ 29.765422241379312, -1.524081661538462 ], [ 29.76515275862069, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11580, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.524081661538462 ], [ 29.76865603448276, -1.524620615384615 ], [ 29.768925517241382, -1.524620615384615 ], [ 29.768925517241382, -1.524081661538462 ], [ 29.76865603448276, -1.524081661538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11581, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.524351138461538 ], [ 29.735779137931036, -1.524620615384615 ], [ 29.736048620689655, -1.524620615384615 ], [ 29.736048620689655, -1.524351138461538 ], [ 29.735779137931036, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11582, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.524351138461538 ], [ 29.736048620689655, -1.524620615384615 ], [ 29.736318103448276, -1.524620615384615 ], [ 29.736318103448276, -1.524351138461538 ], [ 29.736048620689655, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11583, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.524351138461538 ], [ 29.736318103448276, -1.524620615384615 ], [ 29.736587586206898, -1.524620615384615 ], [ 29.736587586206898, -1.524351138461538 ], [ 29.736318103448276, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11584, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.524351138461538 ], [ 29.736587586206898, -1.524620615384615 ], [ 29.736857068965517, -1.524620615384615 ], [ 29.736857068965517, -1.524351138461538 ], [ 29.736587586206898, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11585, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.524351138461538 ], [ 29.736857068965517, -1.524620615384615 ], [ 29.737126551724138, -1.524620615384615 ], [ 29.737126551724138, -1.524351138461538 ], [ 29.736857068965517, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11586, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.524351138461538 ], [ 29.737126551724138, -1.524890092307692 ], [ 29.73739603448276, -1.524890092307692 ], [ 29.73739603448276, -1.524351138461538 ], [ 29.737126551724138, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11587, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.524351138461538 ], [ 29.73739603448276, -1.524620615384615 ], [ 29.737665517241378, -1.524620615384615 ], [ 29.737665517241378, -1.524351138461538 ], [ 29.73739603448276, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11588, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.524351138461538 ], [ 29.737665517241378, -1.524620615384615 ], [ 29.737935, -1.524620615384615 ], [ 29.737935, -1.524351138461538 ], [ 29.737665517241378, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11589, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.524351138461538 ], [ 29.737935, -1.524620615384615 ], [ 29.738204482758622, -1.524620615384615 ], [ 29.738204482758622, -1.524351138461538 ], [ 29.737935, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11590, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.524351138461538 ], [ 29.738204482758622, -1.525159569230769 ], [ 29.738743448275862, -1.525159569230769 ], [ 29.738743448275862, -1.524351138461538 ], [ 29.738204482758622, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11591, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.524351138461538 ], [ 29.738743448275862, -1.525159569230769 ], [ 29.739012931034484, -1.525159569230769 ], [ 29.739012931034484, -1.524351138461538 ], [ 29.738743448275862, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11592, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.524351138461538 ], [ 29.739012931034484, -1.525159569230769 ], [ 29.739282413793102, -1.525159569230769 ], [ 29.739282413793102, -1.524890092307692 ], [ 29.739551896551724, -1.524890092307692 ], [ 29.739551896551724, -1.524620615384615 ], [ 29.739282413793102, -1.524620615384615 ], [ 29.739282413793102, -1.524351138461538 ], [ 29.739012931034484, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11593, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.524351138461538 ], [ 29.739282413793102, -1.524620615384615 ], [ 29.739551896551724, -1.524620615384615 ], [ 29.739551896551724, -1.524351138461538 ], [ 29.739282413793102, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11594, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.524351138461538 ], [ 29.739551896551724, -1.524620615384615 ], [ 29.739821379310346, -1.524620615384615 ], [ 29.739821379310346, -1.524351138461538 ], [ 29.739551896551724, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11595, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.524351138461538 ], [ 29.739821379310346, -1.524620615384615 ], [ 29.740090862068964, -1.524620615384615 ], [ 29.740090862068964, -1.524351138461538 ], [ 29.739821379310346, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11596, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.524351138461538 ], [ 29.740090862068964, -1.524890092307692 ], [ 29.740360344827586, -1.524890092307692 ], [ 29.740360344827586, -1.524351138461538 ], [ 29.740090862068964, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11597, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.524351138461538 ], [ 29.740360344827586, -1.524890092307692 ], [ 29.740629827586208, -1.524890092307692 ], [ 29.740629827586208, -1.524351138461538 ], [ 29.740360344827586, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11598, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.524351138461538 ], [ 29.741168793103448, -1.524890092307692 ], [ 29.74143827586207, -1.524890092307692 ], [ 29.74143827586207, -1.524351138461538 ], [ 29.741168793103448, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11599, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74197724137931, -1.524351138461538 ], [ 29.74197724137931, -1.525429046153846 ], [ 29.742246724137932, -1.525429046153846 ], [ 29.742246724137932, -1.524351138461538 ], [ 29.74197724137931, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11600, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.524351138461538 ], [ 29.760032586206897, -1.524620615384615 ], [ 29.760302068965519, -1.524620615384615 ], [ 29.760302068965519, -1.524351138461538 ], [ 29.760032586206897, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11601, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.524351138461538 ], [ 29.760302068965519, -1.524620615384615 ], [ 29.760571551724137, -1.524620615384615 ], [ 29.760571551724137, -1.524351138461538 ], [ 29.760302068965519, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11602, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.524351138461538 ], [ 29.760571551724137, -1.524620615384615 ], [ 29.760841034482759, -1.524620615384615 ], [ 29.760841034482759, -1.524351138461538 ], [ 29.760571551724137, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11603, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.524351138461538 ], [ 29.760841034482759, -1.524620615384615 ], [ 29.761110517241381, -1.524620615384615 ], [ 29.761110517241381, -1.524351138461538 ], [ 29.760841034482759, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11604, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.524351138461538 ], [ 29.761110517241381, -1.524620615384615 ], [ 29.76138, -1.524620615384615 ], [ 29.76138, -1.524351138461538 ], [ 29.761110517241381, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11605, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.524351138461538 ], [ 29.76138, -1.524620615384615 ], [ 29.761649482758621, -1.524620615384615 ], [ 29.761649482758621, -1.524351138461538 ], [ 29.76138, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11606, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.524351138461538 ], [ 29.761649482758621, -1.524620615384615 ], [ 29.761918965517243, -1.524620615384615 ], [ 29.761918965517243, -1.524351138461538 ], [ 29.761649482758621, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11607, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.524351138461538 ], [ 29.761918965517243, -1.524620615384615 ], [ 29.762188448275861, -1.524620615384615 ], [ 29.762188448275861, -1.524351138461538 ], [ 29.761918965517243, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11608, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.524351138461538 ], [ 29.762188448275861, -1.524620615384615 ], [ 29.762457931034483, -1.524620615384615 ], [ 29.762457931034483, -1.524351138461538 ], [ 29.762188448275861, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11609, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.524351138461538 ], [ 29.762457931034483, -1.524620615384615 ], [ 29.762727413793105, -1.524620615384615 ], [ 29.762727413793105, -1.524351138461538 ], [ 29.762457931034483, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11610, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.524351138461538 ], [ 29.762727413793105, -1.524620615384615 ], [ 29.762996896551723, -1.524620615384615 ], [ 29.762996896551723, -1.524351138461538 ], [ 29.762727413793105, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11611, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.524351138461538 ], [ 29.762996896551723, -1.524620615384615 ], [ 29.763266379310345, -1.524620615384615 ], [ 29.763266379310345, -1.524351138461538 ], [ 29.762996896551723, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11612, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.524351138461538 ], [ 29.763266379310345, -1.524620615384615 ], [ 29.763535862068967, -1.524620615384615 ], [ 29.763535862068967, -1.524351138461538 ], [ 29.763266379310345, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11613, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.524351138461538 ], [ 29.763535862068967, -1.524620615384615 ], [ 29.763805344827588, -1.524620615384615 ], [ 29.763805344827588, -1.524351138461538 ], [ 29.763535862068967, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11614, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.524351138461538 ], [ 29.763805344827588, -1.524620615384615 ], [ 29.764074827586207, -1.524620615384615 ], [ 29.764074827586207, -1.524351138461538 ], [ 29.763805344827588, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11615, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.524351138461538 ], [ 29.764074827586207, -1.524620615384615 ], [ 29.764344310344828, -1.524620615384615 ], [ 29.764344310344828, -1.524351138461538 ], [ 29.764074827586207, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11616, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.524351138461538 ], [ 29.764344310344828, -1.524620615384615 ], [ 29.76461379310345, -1.524620615384615 ], [ 29.76461379310345, -1.524351138461538 ], [ 29.764344310344828, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11617, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.524351138461538 ], [ 29.76461379310345, -1.524620615384615 ], [ 29.764883275862069, -1.524620615384615 ], [ 29.764883275862069, -1.524351138461538 ], [ 29.76461379310345, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11618, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.524351138461538 ], [ 29.764883275862069, -1.524890092307692 ], [ 29.76515275862069, -1.524890092307692 ], [ 29.76515275862069, -1.524351138461538 ], [ 29.764883275862069, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11619, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.524351138461538 ], [ 29.765422241379312, -1.524620615384615 ], [ 29.765961206896552, -1.524620615384615 ], [ 29.765961206896552, -1.524351138461538 ], [ 29.765422241379312, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11620, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.524351138461538 ], [ 29.765961206896552, -1.524620615384615 ], [ 29.766230689655174, -1.524620615384615 ], [ 29.766230689655174, -1.524351138461538 ], [ 29.765961206896552, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11621, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.524351138461538 ], [ 29.766230689655174, -1.524620615384615 ], [ 29.766500172413792, -1.524620615384615 ], [ 29.766500172413792, -1.524351138461538 ], [ 29.766230689655174, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11622, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.524351138461538 ], [ 29.766500172413792, -1.524620615384615 ], [ 29.766769655172414, -1.524620615384615 ], [ 29.766769655172414, -1.524351138461538 ], [ 29.766500172413792, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11623, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.524351138461538 ], [ 29.766769655172414, -1.524620615384615 ], [ 29.767039137931036, -1.524620615384615 ], [ 29.767039137931036, -1.524351138461538 ], [ 29.766769655172414, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11624, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.524351138461538 ], [ 29.767039137931036, -1.524620615384615 ], [ 29.767308620689654, -1.524620615384615 ], [ 29.767308620689654, -1.524351138461538 ], [ 29.767039137931036, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11625, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.524351138461538 ], [ 29.767308620689654, -1.524620615384615 ], [ 29.767578103448276, -1.524620615384615 ], [ 29.767578103448276, -1.524351138461538 ], [ 29.767308620689654, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11626, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.524351138461538 ], [ 29.767578103448276, -1.524620615384615 ], [ 29.767847586206898, -1.524620615384615 ], [ 29.767847586206898, -1.524351138461538 ], [ 29.767578103448276, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11627, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.524351138461538 ], [ 29.767847586206898, -1.524620615384615 ], [ 29.768117068965516, -1.524620615384615 ], [ 29.768117068965516, -1.524351138461538 ], [ 29.767847586206898, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11628, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.524351138461538 ], [ 29.768117068965516, -1.524620615384615 ], [ 29.768386551724138, -1.524620615384615 ], [ 29.768386551724138, -1.524351138461538 ], [ 29.768117068965516, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11629, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.524351138461538 ], [ 29.768386551724138, -1.524620615384615 ], [ 29.76865603448276, -1.524620615384615 ], [ 29.76865603448276, -1.524351138461538 ], [ 29.768386551724138, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11630, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.524351138461538 ], [ 29.768925517241382, -1.524890092307692 ], [ 29.769195, -1.524890092307692 ], [ 29.769195, -1.524351138461538 ], [ 29.768925517241382, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11631, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.524351138461538 ], [ 29.769195, -1.524620615384615 ], [ 29.769464482758622, -1.524620615384615 ], [ 29.769464482758622, -1.524351138461538 ], [ 29.769195, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11632, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.524351138461538 ], [ 29.769464482758622, -1.524620615384615 ], [ 29.769733965517243, -1.524620615384615 ], [ 29.769733965517243, -1.524351138461538 ], [ 29.769464482758622, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11633, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.524351138461538 ], [ 29.769733965517243, -1.524620615384615 ], [ 29.770003448275862, -1.524620615384615 ], [ 29.770003448275862, -1.524351138461538 ], [ 29.769733965517243, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11634, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.524351138461538 ], [ 29.770003448275862, -1.524620615384615 ], [ 29.770272931034484, -1.524620615384615 ], [ 29.770272931034484, -1.524351138461538 ], [ 29.770003448275862, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11635, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.524351138461538 ], [ 29.770272931034484, -1.524620615384615 ], [ 29.770542413793105, -1.524620615384615 ], [ 29.770542413793105, -1.524351138461538 ], [ 29.770272931034484, -1.524351138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11636, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.524620615384615 ], [ 29.736048620689655, -1.524890092307692 ], [ 29.736318103448276, -1.524890092307692 ], [ 29.736318103448276, -1.524620615384615 ], [ 29.736048620689655, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11637, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.524620615384615 ], [ 29.736318103448276, -1.524890092307692 ], [ 29.736587586206898, -1.524890092307692 ], [ 29.736587586206898, -1.524620615384615 ], [ 29.736318103448276, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11638, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.524620615384615 ], [ 29.736587586206898, -1.525968 ], [ 29.736857068965517, -1.525968 ], [ 29.736857068965517, -1.524620615384615 ], [ 29.736587586206898, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11639, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.524620615384615 ], [ 29.736857068965517, -1.525968 ], [ 29.737126551724138, -1.525968 ], [ 29.737126551724138, -1.524620615384615 ], [ 29.736857068965517, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11640, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.524620615384615 ], [ 29.73739603448276, -1.525429046153846 ], [ 29.737665517241378, -1.525429046153846 ], [ 29.737665517241378, -1.524620615384615 ], [ 29.73739603448276, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11641, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.524620615384615 ], [ 29.737665517241378, -1.525429046153846 ], [ 29.737935, -1.525429046153846 ], [ 29.737935, -1.524620615384615 ], [ 29.737665517241378, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11642, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.524620615384615 ], [ 29.737935, -1.525429046153846 ], [ 29.738204482758622, -1.525429046153846 ], [ 29.738204482758622, -1.524620615384615 ], [ 29.737935, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11643, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.524620615384615 ], [ 29.739551896551724, -1.524890092307692 ], [ 29.739821379310346, -1.524890092307692 ], [ 29.739821379310346, -1.524620615384615 ], [ 29.739551896551724, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11644, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.524620615384615 ], [ 29.739821379310346, -1.524890092307692 ], [ 29.740090862068964, -1.524890092307692 ], [ 29.740090862068964, -1.524620615384615 ], [ 29.739821379310346, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11645, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.524620615384615 ], [ 29.759493620689657, -1.524890092307692 ], [ 29.759763103448275, -1.524890092307692 ], [ 29.759763103448275, -1.524620615384615 ], [ 29.759493620689657, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11646, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.524620615384615 ], [ 29.759763103448275, -1.524890092307692 ], [ 29.760032586206897, -1.524890092307692 ], [ 29.760032586206897, -1.524620615384615 ], [ 29.759763103448275, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11647, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.524620615384615 ], [ 29.760032586206897, -1.524890092307692 ], [ 29.760302068965519, -1.524890092307692 ], [ 29.760302068965519, -1.524620615384615 ], [ 29.760032586206897, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11648, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.524620615384615 ], [ 29.760302068965519, -1.524890092307692 ], [ 29.760571551724137, -1.524890092307692 ], [ 29.760571551724137, -1.524620615384615 ], [ 29.760302068965519, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11649, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.524620615384615 ], [ 29.760571551724137, -1.524890092307692 ], [ 29.760841034482759, -1.524890092307692 ], [ 29.760841034482759, -1.524620615384615 ], [ 29.760571551724137, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11650, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.524620615384615 ], [ 29.760841034482759, -1.524890092307692 ], [ 29.761110517241381, -1.524890092307692 ], [ 29.761110517241381, -1.524620615384615 ], [ 29.760841034482759, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11651, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.524620615384615 ], [ 29.761110517241381, -1.524890092307692 ], [ 29.76138, -1.524890092307692 ], [ 29.76138, -1.524620615384615 ], [ 29.761110517241381, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11652, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.524620615384615 ], [ 29.76138, -1.524890092307692 ], [ 29.761649482758621, -1.524890092307692 ], [ 29.761649482758621, -1.524620615384615 ], [ 29.76138, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11653, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.524620615384615 ], [ 29.761649482758621, -1.524890092307692 ], [ 29.761918965517243, -1.524890092307692 ], [ 29.761918965517243, -1.524620615384615 ], [ 29.761649482758621, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11654, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.524620615384615 ], [ 29.761918965517243, -1.524890092307692 ], [ 29.762188448275861, -1.524890092307692 ], [ 29.762188448275861, -1.524620615384615 ], [ 29.761918965517243, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11655, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.524620615384615 ], [ 29.762188448275861, -1.524890092307692 ], [ 29.762457931034483, -1.524890092307692 ], [ 29.762457931034483, -1.524620615384615 ], [ 29.762188448275861, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11656, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.524620615384615 ], [ 29.762457931034483, -1.524890092307692 ], [ 29.762727413793105, -1.524890092307692 ], [ 29.762727413793105, -1.524620615384615 ], [ 29.762457931034483, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11657, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.524620615384615 ], [ 29.762727413793105, -1.524890092307692 ], [ 29.762996896551723, -1.524890092307692 ], [ 29.762996896551723, -1.524620615384615 ], [ 29.762727413793105, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11658, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.524620615384615 ], [ 29.762996896551723, -1.524890092307692 ], [ 29.763266379310345, -1.524890092307692 ], [ 29.763266379310345, -1.524620615384615 ], [ 29.762996896551723, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11659, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.524620615384615 ], [ 29.763266379310345, -1.524890092307692 ], [ 29.763535862068967, -1.524890092307692 ], [ 29.763535862068967, -1.524620615384615 ], [ 29.763266379310345, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11660, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.524620615384615 ], [ 29.763535862068967, -1.524890092307692 ], [ 29.763805344827588, -1.524890092307692 ], [ 29.763805344827588, -1.524620615384615 ], [ 29.763535862068967, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11661, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.524620615384615 ], [ 29.763805344827588, -1.524890092307692 ], [ 29.764074827586207, -1.524890092307692 ], [ 29.764074827586207, -1.524620615384615 ], [ 29.763805344827588, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11662, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.524620615384615 ], [ 29.764074827586207, -1.525159569230769 ], [ 29.764344310344828, -1.525159569230769 ], [ 29.764344310344828, -1.524620615384615 ], [ 29.764074827586207, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11663, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.524620615384615 ], [ 29.764344310344828, -1.524890092307692 ], [ 29.764883275862069, -1.524890092307692 ], [ 29.764883275862069, -1.524620615384615 ], [ 29.764344310344828, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11664, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.524620615384615 ], [ 29.76515275862069, -1.524890092307692 ], [ 29.765422241379312, -1.524890092307692 ], [ 29.765422241379312, -1.524620615384615 ], [ 29.76515275862069, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11665, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.524620615384615 ], [ 29.765422241379312, -1.524890092307692 ], [ 29.765961206896552, -1.524890092307692 ], [ 29.765961206896552, -1.524620615384615 ], [ 29.765422241379312, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11666, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.524620615384615 ], [ 29.765961206896552, -1.524890092307692 ], [ 29.766230689655174, -1.524890092307692 ], [ 29.766230689655174, -1.524620615384615 ], [ 29.765961206896552, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11667, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.524620615384615 ], [ 29.766230689655174, -1.524890092307692 ], [ 29.766500172413792, -1.524890092307692 ], [ 29.766500172413792, -1.524620615384615 ], [ 29.766230689655174, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11668, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.524620615384615 ], [ 29.766500172413792, -1.524890092307692 ], [ 29.766769655172414, -1.524890092307692 ], [ 29.766769655172414, -1.524620615384615 ], [ 29.766500172413792, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11669, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.524620615384615 ], [ 29.766769655172414, -1.524890092307692 ], [ 29.767039137931036, -1.524890092307692 ], [ 29.767039137931036, -1.524620615384615 ], [ 29.766769655172414, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11670, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.524620615384615 ], [ 29.767039137931036, -1.524890092307692 ], [ 29.767308620689654, -1.524890092307692 ], [ 29.767308620689654, -1.524620615384615 ], [ 29.767039137931036, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11671, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.524620615384615 ], [ 29.767308620689654, -1.525429046153846 ], [ 29.767578103448276, -1.525429046153846 ], [ 29.767578103448276, -1.524620615384615 ], [ 29.767308620689654, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11672, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.524620615384615 ], [ 29.767578103448276, -1.525968 ], [ 29.767847586206898, -1.525968 ], [ 29.767847586206898, -1.524620615384615 ], [ 29.767578103448276, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11673, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.524620615384615 ], [ 29.767847586206898, -1.525968 ], [ 29.768117068965516, -1.525968 ], [ 29.768117068965516, -1.524620615384615 ], [ 29.767847586206898, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11674, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.524620615384615 ], [ 29.768117068965516, -1.525429046153846 ], [ 29.768386551724138, -1.525429046153846 ], [ 29.768386551724138, -1.524620615384615 ], [ 29.768117068965516, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11675, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.524620615384615 ], [ 29.768386551724138, -1.525429046153846 ], [ 29.76865603448276, -1.525429046153846 ], [ 29.76865603448276, -1.524620615384615 ], [ 29.768386551724138, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11676, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.524620615384615 ], [ 29.76865603448276, -1.525429046153846 ], [ 29.768925517241382, -1.525429046153846 ], [ 29.768925517241382, -1.524620615384615 ], [ 29.76865603448276, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11677, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.524620615384615 ], [ 29.769195, -1.525429046153846 ], [ 29.769464482758622, -1.525429046153846 ], [ 29.769464482758622, -1.524620615384615 ], [ 29.769195, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11678, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.524620615384615 ], [ 29.769464482758622, -1.525429046153846 ], [ 29.769733965517243, -1.525429046153846 ], [ 29.769733965517243, -1.524620615384615 ], [ 29.769464482758622, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11679, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.524620615384615 ], [ 29.769733965517243, -1.524890092307692 ], [ 29.770003448275862, -1.524890092307692 ], [ 29.770003448275862, -1.524620615384615 ], [ 29.769733965517243, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11680, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.524620615384615 ], [ 29.770003448275862, -1.524890092307692 ], [ 29.770272931034484, -1.524890092307692 ], [ 29.770272931034484, -1.524620615384615 ], [ 29.770003448275862, -1.524620615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11681, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.524890092307692 ], [ 29.736318103448276, -1.526237476923077 ], [ 29.736587586206898, -1.526237476923077 ], [ 29.736587586206898, -1.524890092307692 ], [ 29.736318103448276, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11682, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.524890092307692 ], [ 29.737126551724138, -1.525429046153846 ], [ 29.73739603448276, -1.525429046153846 ], [ 29.73739603448276, -1.524890092307692 ], [ 29.737126551724138, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11683, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.524890092307692 ], [ 29.739282413793102, -1.525159569230769 ], [ 29.739551896551724, -1.525159569230769 ], [ 29.739551896551724, -1.524890092307692 ], [ 29.739282413793102, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11684, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.524890092307692 ], [ 29.739551896551724, -1.526506953846154 ], [ 29.739821379310346, -1.526506953846154 ], [ 29.739821379310346, -1.524890092307692 ], [ 29.739551896551724, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11685, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.524890092307692 ], [ 29.739821379310346, -1.526506953846154 ], [ 29.740090862068964, -1.526506953846154 ], [ 29.740090862068964, -1.524890092307692 ], [ 29.739821379310346, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11686, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.524890092307692 ], [ 29.740090862068964, -1.525159569230769 ], [ 29.740360344827586, -1.525159569230769 ], [ 29.740360344827586, -1.524890092307692 ], [ 29.740090862068964, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11687, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.524890092307692 ], [ 29.740360344827586, -1.525159569230769 ], [ 29.740629827586208, -1.525159569230769 ], [ 29.740629827586208, -1.524890092307692 ], [ 29.740360344827586, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11688, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.524890092307692 ], [ 29.740629827586208, -1.525159569230769 ], [ 29.74089931034483, -1.525159569230769 ], [ 29.74089931034483, -1.524890092307692 ], [ 29.740629827586208, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11689, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.524890092307692 ], [ 29.74089931034483, -1.525429046153846 ], [ 29.741168793103448, -1.525429046153846 ], [ 29.741168793103448, -1.524890092307692 ], [ 29.74089931034483, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11690, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.524890092307692 ], [ 29.741168793103448, -1.525159569230769 ], [ 29.74143827586207, -1.525159569230769 ], [ 29.74143827586207, -1.524890092307692 ], [ 29.741168793103448, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11691, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.524890092307692 ], [ 29.758954655172413, -1.525159569230769 ], [ 29.759224137931035, -1.525159569230769 ], [ 29.759224137931035, -1.524890092307692 ], [ 29.758954655172413, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11692, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.524890092307692 ], [ 29.759224137931035, -1.525159569230769 ], [ 29.759493620689657, -1.525159569230769 ], [ 29.759493620689657, -1.524890092307692 ], [ 29.759224137931035, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11693, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.524890092307692 ], [ 29.759493620689657, -1.525159569230769 ], [ 29.759763103448275, -1.525159569230769 ], [ 29.759763103448275, -1.524890092307692 ], [ 29.759493620689657, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11694, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.524890092307692 ], [ 29.759763103448275, -1.525159569230769 ], [ 29.760032586206897, -1.525159569230769 ], [ 29.760032586206897, -1.524890092307692 ], [ 29.759763103448275, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11695, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.524890092307692 ], [ 29.760032586206897, -1.525159569230769 ], [ 29.760302068965519, -1.525159569230769 ], [ 29.760302068965519, -1.524890092307692 ], [ 29.760032586206897, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11696, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.524890092307692 ], [ 29.760302068965519, -1.525159569230769 ], [ 29.760571551724137, -1.525159569230769 ], [ 29.760571551724137, -1.524890092307692 ], [ 29.760302068965519, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11697, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.524890092307692 ], [ 29.760571551724137, -1.525159569230769 ], [ 29.760841034482759, -1.525159569230769 ], [ 29.760841034482759, -1.524890092307692 ], [ 29.760571551724137, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11698, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.524890092307692 ], [ 29.760841034482759, -1.525159569230769 ], [ 29.761110517241381, -1.525159569230769 ], [ 29.761110517241381, -1.524890092307692 ], [ 29.760841034482759, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11699, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.524890092307692 ], [ 29.761110517241381, -1.525159569230769 ], [ 29.76138, -1.525159569230769 ], [ 29.76138, -1.524890092307692 ], [ 29.761110517241381, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11700, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.524890092307692 ], [ 29.76138, -1.525159569230769 ], [ 29.761649482758621, -1.525159569230769 ], [ 29.761649482758621, -1.524890092307692 ], [ 29.76138, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11701, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.524890092307692 ], [ 29.761649482758621, -1.525159569230769 ], [ 29.761918965517243, -1.525159569230769 ], [ 29.761918965517243, -1.524890092307692 ], [ 29.761649482758621, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11702, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.524890092307692 ], [ 29.761918965517243, -1.525159569230769 ], [ 29.762188448275861, -1.525159569230769 ], [ 29.762188448275861, -1.524890092307692 ], [ 29.761918965517243, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11703, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.524890092307692 ], [ 29.762188448275861, -1.525159569230769 ], [ 29.762457931034483, -1.525159569230769 ], [ 29.762457931034483, -1.524890092307692 ], [ 29.762188448275861, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11704, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.524890092307692 ], [ 29.762457931034483, -1.525159569230769 ], [ 29.762727413793105, -1.525159569230769 ], [ 29.762727413793105, -1.524890092307692 ], [ 29.762457931034483, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11705, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.524890092307692 ], [ 29.762727413793105, -1.525159569230769 ], [ 29.762996896551723, -1.525159569230769 ], [ 29.762996896551723, -1.524890092307692 ], [ 29.762727413793105, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11706, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.524890092307692 ], [ 29.762996896551723, -1.525159569230769 ], [ 29.763266379310345, -1.525159569230769 ], [ 29.763266379310345, -1.524890092307692 ], [ 29.762996896551723, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11707, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.524890092307692 ], [ 29.763266379310345, -1.525159569230769 ], [ 29.763535862068967, -1.525159569230769 ], [ 29.763535862068967, -1.524890092307692 ], [ 29.763266379310345, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11708, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.524890092307692 ], [ 29.763535862068967, -1.525159569230769 ], [ 29.763805344827588, -1.525159569230769 ], [ 29.763805344827588, -1.524890092307692 ], [ 29.763535862068967, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11709, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.524890092307692 ], [ 29.763805344827588, -1.525429046153846 ], [ 29.764074827586207, -1.525429046153846 ], [ 29.764074827586207, -1.524890092307692 ], [ 29.763805344827588, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11710, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.524890092307692 ], [ 29.764344310344828, -1.525159569230769 ], [ 29.76461379310345, -1.525159569230769 ], [ 29.76461379310345, -1.524890092307692 ], [ 29.764344310344828, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11711, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.524890092307692 ], [ 29.76461379310345, -1.525159569230769 ], [ 29.764883275862069, -1.525159569230769 ], [ 29.764883275862069, -1.524890092307692 ], [ 29.76461379310345, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11712, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.524890092307692 ], [ 29.764883275862069, -1.525159569230769 ], [ 29.76515275862069, -1.525159569230769 ], [ 29.76515275862069, -1.524890092307692 ], [ 29.764883275862069, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11713, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.524890092307692 ], [ 29.76515275862069, -1.525159569230769 ], [ 29.765422241379312, -1.525159569230769 ], [ 29.765422241379312, -1.524890092307692 ], [ 29.76515275862069, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11714, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.524890092307692 ], [ 29.765422241379312, -1.525159569230769 ], [ 29.765961206896552, -1.525159569230769 ], [ 29.765961206896552, -1.524890092307692 ], [ 29.765422241379312, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11715, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.524890092307692 ], [ 29.765961206896552, -1.525159569230769 ], [ 29.766230689655174, -1.525159569230769 ], [ 29.766230689655174, -1.524890092307692 ], [ 29.765961206896552, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11716, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.524890092307692 ], [ 29.766230689655174, -1.525159569230769 ], [ 29.766500172413792, -1.525159569230769 ], [ 29.766500172413792, -1.524890092307692 ], [ 29.766230689655174, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11717, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.524890092307692 ], [ 29.766500172413792, -1.525429046153846 ], [ 29.766769655172414, -1.525429046153846 ], [ 29.766769655172414, -1.524890092307692 ], [ 29.766500172413792, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11718, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.524890092307692 ], [ 29.766769655172414, -1.525968 ], [ 29.767039137931036, -1.525968 ], [ 29.767039137931036, -1.524890092307692 ], [ 29.766769655172414, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11719, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.524890092307692 ], [ 29.767039137931036, -1.526237476923077 ], [ 29.767308620689654, -1.526237476923077 ], [ 29.767308620689654, -1.524890092307692 ], [ 29.767039137931036, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11720, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.524890092307692 ], [ 29.768925517241382, -1.525159569230769 ], [ 29.769195, -1.525159569230769 ], [ 29.769195, -1.524890092307692 ], [ 29.768925517241382, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11721, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.524890092307692 ], [ 29.769733965517243, -1.525159569230769 ], [ 29.770003448275862, -1.525159569230769 ], [ 29.770003448275862, -1.524890092307692 ], [ 29.769733965517243, -1.524890092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11722, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.525159569230769 ], [ 29.738204482758622, -1.525429046153846 ], [ 29.738743448275862, -1.525429046153846 ], [ 29.738743448275862, -1.525159569230769 ], [ 29.738204482758622, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11723, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.525159569230769 ], [ 29.738743448275862, -1.525429046153846 ], [ 29.739012931034484, -1.525429046153846 ], [ 29.739012931034484, -1.525159569230769 ], [ 29.738743448275862, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11724, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.525159569230769 ], [ 29.739012931034484, -1.525429046153846 ], [ 29.739282413793102, -1.525429046153846 ], [ 29.739282413793102, -1.525159569230769 ], [ 29.739012931034484, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11725, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.525159569230769 ], [ 29.739282413793102, -1.526506953846154 ], [ 29.739551896551724, -1.526506953846154 ], [ 29.739551896551724, -1.525159569230769 ], [ 29.739282413793102, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11726, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.525159569230769 ], [ 29.741707758620691, -1.525429046153846 ], [ 29.74197724137931, -1.525429046153846 ], [ 29.74197724137931, -1.525159569230769 ], [ 29.741707758620691, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11727, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.525159569230769 ], [ 29.758954655172413, -1.525429046153846 ], [ 29.759224137931035, -1.525429046153846 ], [ 29.759224137931035, -1.525159569230769 ], [ 29.758954655172413, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11728, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.525159569230769 ], [ 29.759224137931035, -1.525429046153846 ], [ 29.759493620689657, -1.525429046153846 ], [ 29.759493620689657, -1.525159569230769 ], [ 29.759224137931035, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11729, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.525159569230769 ], [ 29.759493620689657, -1.525429046153846 ], [ 29.759763103448275, -1.525429046153846 ], [ 29.759763103448275, -1.525159569230769 ], [ 29.759493620689657, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11730, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.525159569230769 ], [ 29.759763103448275, -1.525429046153846 ], [ 29.760032586206897, -1.525429046153846 ], [ 29.760032586206897, -1.525159569230769 ], [ 29.759763103448275, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11731, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.525159569230769 ], [ 29.760032586206897, -1.525429046153846 ], [ 29.760302068965519, -1.525429046153846 ], [ 29.760302068965519, -1.525159569230769 ], [ 29.760032586206897, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11732, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.525159569230769 ], [ 29.760302068965519, -1.525429046153846 ], [ 29.760571551724137, -1.525429046153846 ], [ 29.760571551724137, -1.525159569230769 ], [ 29.760302068965519, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11733, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.525159569230769 ], [ 29.760571551724137, -1.525429046153846 ], [ 29.760841034482759, -1.525429046153846 ], [ 29.760841034482759, -1.525159569230769 ], [ 29.760571551724137, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11734, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.525159569230769 ], [ 29.760841034482759, -1.525429046153846 ], [ 29.761110517241381, -1.525429046153846 ], [ 29.761110517241381, -1.525159569230769 ], [ 29.760841034482759, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11735, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.525159569230769 ], [ 29.761110517241381, -1.525429046153846 ], [ 29.76138, -1.525429046153846 ], [ 29.76138, -1.525159569230769 ], [ 29.761110517241381, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11736, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.525159569230769 ], [ 29.76138, -1.525429046153846 ], [ 29.761649482758621, -1.525429046153846 ], [ 29.761649482758621, -1.525159569230769 ], [ 29.76138, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11737, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.525159569230769 ], [ 29.761649482758621, -1.525429046153846 ], [ 29.761918965517243, -1.525429046153846 ], [ 29.761918965517243, -1.525159569230769 ], [ 29.761649482758621, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11738, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.525159569230769 ], [ 29.761918965517243, -1.525429046153846 ], [ 29.762188448275861, -1.525429046153846 ], [ 29.762188448275861, -1.525159569230769 ], [ 29.761918965517243, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11739, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.525159569230769 ], [ 29.762188448275861, -1.525429046153846 ], [ 29.762457931034483, -1.525429046153846 ], [ 29.762457931034483, -1.525159569230769 ], [ 29.762188448275861, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11740, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.525159569230769 ], [ 29.762457931034483, -1.525429046153846 ], [ 29.762727413793105, -1.525429046153846 ], [ 29.762727413793105, -1.525159569230769 ], [ 29.762457931034483, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11741, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.525159569230769 ], [ 29.762727413793105, -1.525429046153846 ], [ 29.762996896551723, -1.525429046153846 ], [ 29.762996896551723, -1.525159569230769 ], [ 29.762727413793105, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11742, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.525159569230769 ], [ 29.762996896551723, -1.525429046153846 ], [ 29.763266379310345, -1.525429046153846 ], [ 29.763266379310345, -1.525159569230769 ], [ 29.762996896551723, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11743, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.525159569230769 ], [ 29.763266379310345, -1.525429046153846 ], [ 29.763535862068967, -1.525429046153846 ], [ 29.763535862068967, -1.525159569230769 ], [ 29.763266379310345, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11744, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.525159569230769 ], [ 29.763535862068967, -1.525968 ], [ 29.763805344827588, -1.525968 ], [ 29.763805344827588, -1.525159569230769 ], [ 29.763535862068967, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11745, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.525159569230769 ], [ 29.764074827586207, -1.525429046153846 ], [ 29.764344310344828, -1.525429046153846 ], [ 29.764344310344828, -1.525159569230769 ], [ 29.764074827586207, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11746, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.525159569230769 ], [ 29.764344310344828, -1.525429046153846 ], [ 29.76461379310345, -1.525429046153846 ], [ 29.76461379310345, -1.525159569230769 ], [ 29.764344310344828, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11747, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.525159569230769 ], [ 29.76461379310345, -1.525968 ], [ 29.764883275862069, -1.525968 ], [ 29.764883275862069, -1.525429046153846 ], [ 29.76515275862069, -1.525429046153846 ], [ 29.76515275862069, -1.525159569230769 ], [ 29.76461379310345, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11748, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.525159569230769 ], [ 29.76515275862069, -1.525429046153846 ], [ 29.765422241379312, -1.525429046153846 ], [ 29.765422241379312, -1.525159569230769 ], [ 29.76515275862069, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11749, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.525159569230769 ], [ 29.765422241379312, -1.525968 ], [ 29.765961206896552, -1.525968 ], [ 29.765961206896552, -1.525159569230769 ], [ 29.765422241379312, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11750, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.525159569230769 ], [ 29.765961206896552, -1.525968 ], [ 29.766230689655174, -1.525968 ], [ 29.766230689655174, -1.525159569230769 ], [ 29.765961206896552, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11751, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.525159569230769 ], [ 29.766230689655174, -1.526237476923077 ], [ 29.766500172413792, -1.526237476923077 ], [ 29.766500172413792, -1.525159569230769 ], [ 29.766230689655174, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11752, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.525159569230769 ], [ 29.768925517241382, -1.525968 ], [ 29.769195, -1.525968 ], [ 29.769195, -1.525159569230769 ], [ 29.768925517241382, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11753, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.525159569230769 ], [ 29.769733965517243, -1.525429046153846 ], [ 29.770003448275862, -1.525429046153846 ], [ 29.770003448275862, -1.525159569230769 ], [ 29.769733965517243, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11754, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.525159569230769 ], [ 29.770003448275862, -1.525968 ], [ 29.770272931034484, -1.525968 ], [ 29.770272931034484, -1.525159569230769 ], [ 29.770003448275862, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11755, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.525429046153846 ], [ 29.737126551724138, -1.525968 ], [ 29.73739603448276, -1.525968 ], [ 29.73739603448276, -1.525429046153846 ], [ 29.737126551724138, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11756, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.525429046153846 ], [ 29.73739603448276, -1.526237476923077 ], [ 29.737665517241378, -1.526237476923077 ], [ 29.737665517241378, -1.525429046153846 ], [ 29.73739603448276, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11757, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.525429046153846 ], [ 29.737665517241378, -1.525968 ], [ 29.737935, -1.525968 ], [ 29.737935, -1.525429046153846 ], [ 29.737665517241378, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11758, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.525429046153846 ], [ 29.737935, -1.525968 ], [ 29.738204482758622, -1.525968 ], [ 29.738204482758622, -1.525429046153846 ], [ 29.737935, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11759, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.525429046153846 ], [ 29.738204482758622, -1.525968 ], [ 29.738743448275862, -1.525968 ], [ 29.738743448275862, -1.525429046153846 ], [ 29.738204482758622, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11760, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.525429046153846 ], [ 29.759224137931035, -1.525968 ], [ 29.759493620689657, -1.525968 ], [ 29.759493620689657, -1.525429046153846 ], [ 29.759224137931035, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11761, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.525429046153846 ], [ 29.759493620689657, -1.525968 ], [ 29.759763103448275, -1.525968 ], [ 29.759763103448275, -1.525429046153846 ], [ 29.759493620689657, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11762, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.525429046153846 ], [ 29.759763103448275, -1.525968 ], [ 29.760032586206897, -1.525968 ], [ 29.760032586206897, -1.525429046153846 ], [ 29.759763103448275, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11763, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.525429046153846 ], [ 29.760032586206897, -1.525968 ], [ 29.760302068965519, -1.525968 ], [ 29.760302068965519, -1.525429046153846 ], [ 29.760032586206897, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11764, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.525429046153846 ], [ 29.760302068965519, -1.525968 ], [ 29.760571551724137, -1.525968 ], [ 29.760571551724137, -1.525429046153846 ], [ 29.760302068965519, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11765, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.525429046153846 ], [ 29.760571551724137, -1.525968 ], [ 29.760841034482759, -1.525968 ], [ 29.760841034482759, -1.525429046153846 ], [ 29.760571551724137, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11766, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.525429046153846 ], [ 29.760841034482759, -1.525968 ], [ 29.761110517241381, -1.525968 ], [ 29.761110517241381, -1.525429046153846 ], [ 29.760841034482759, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11767, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.525429046153846 ], [ 29.761110517241381, -1.525968 ], [ 29.76138, -1.525968 ], [ 29.76138, -1.525429046153846 ], [ 29.761110517241381, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11768, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.525429046153846 ], [ 29.76138, -1.525968 ], [ 29.761649482758621, -1.525968 ], [ 29.761649482758621, -1.525429046153846 ], [ 29.76138, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11769, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.525429046153846 ], [ 29.761649482758621, -1.525968 ], [ 29.761918965517243, -1.525968 ], [ 29.761918965517243, -1.525429046153846 ], [ 29.761649482758621, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11770, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.525429046153846 ], [ 29.761918965517243, -1.525968 ], [ 29.762188448275861, -1.525968 ], [ 29.762188448275861, -1.525429046153846 ], [ 29.761918965517243, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11771, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.525429046153846 ], [ 29.762188448275861, -1.525968 ], [ 29.762457931034483, -1.525968 ], [ 29.762457931034483, -1.525429046153846 ], [ 29.762188448275861, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11772, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.525429046153846 ], [ 29.762457931034483, -1.525968 ], [ 29.762727413793105, -1.525968 ], [ 29.762727413793105, -1.525429046153846 ], [ 29.762457931034483, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11773, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.525429046153846 ], [ 29.762727413793105, -1.525968 ], [ 29.762996896551723, -1.525968 ], [ 29.762996896551723, -1.525429046153846 ], [ 29.762727413793105, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11774, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.525429046153846 ], [ 29.762996896551723, -1.525968 ], [ 29.763266379310345, -1.525968 ], [ 29.763266379310345, -1.525429046153846 ], [ 29.762996896551723, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11775, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.525429046153846 ], [ 29.763266379310345, -1.526237476923077 ], [ 29.763535862068967, -1.526237476923077 ], [ 29.763535862068967, -1.525429046153846 ], [ 29.763266379310345, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11776, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.525429046153846 ], [ 29.763805344827588, -1.525968 ], [ 29.764074827586207, -1.525968 ], [ 29.764074827586207, -1.525429046153846 ], [ 29.763805344827588, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11777, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.525429046153846 ], [ 29.764074827586207, -1.526237476923077 ], [ 29.764344310344828, -1.526237476923077 ], [ 29.764344310344828, -1.525429046153846 ], [ 29.764074827586207, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11778, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.525429046153846 ], [ 29.764344310344828, -1.526237476923077 ], [ 29.76461379310345, -1.526237476923077 ], [ 29.76461379310345, -1.525429046153846 ], [ 29.764344310344828, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11779, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.525429046153846 ], [ 29.76515275862069, -1.526237476923077 ], [ 29.765422241379312, -1.526237476923077 ], [ 29.765422241379312, -1.525429046153846 ], [ 29.76515275862069, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11780, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.525429046153846 ], [ 29.766500172413792, -1.526237476923077 ], [ 29.766769655172414, -1.526237476923077 ], [ 29.766769655172414, -1.525429046153846 ], [ 29.766500172413792, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11781, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.525429046153846 ], [ 29.767308620689654, -1.526237476923077 ], [ 29.767578103448276, -1.526237476923077 ], [ 29.767578103448276, -1.525429046153846 ], [ 29.767308620689654, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11782, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.525429046153846 ], [ 29.768117068965516, -1.525968 ], [ 29.768386551724138, -1.525968 ], [ 29.768386551724138, -1.525429046153846 ], [ 29.768117068965516, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11783, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.525429046153846 ], [ 29.768386551724138, -1.525968 ], [ 29.76865603448276, -1.525968 ], [ 29.76865603448276, -1.525429046153846 ], [ 29.768386551724138, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11784, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.525429046153846 ], [ 29.76865603448276, -1.525968 ], [ 29.768925517241382, -1.525968 ], [ 29.768925517241382, -1.525429046153846 ], [ 29.76865603448276, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11785, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.525429046153846 ], [ 29.769195, -1.525968 ], [ 29.769464482758622, -1.525968 ], [ 29.769464482758622, -1.525429046153846 ], [ 29.769195, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11786, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.525429046153846 ], [ 29.769464482758622, -1.526237476923077 ], [ 29.769733965517243, -1.526237476923077 ], [ 29.769733965517243, -1.525429046153846 ], [ 29.769464482758622, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11787, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.525429046153846 ], [ 29.769733965517243, -1.526237476923077 ], [ 29.770003448275862, -1.526237476923077 ], [ 29.770003448275862, -1.525429046153846 ], [ 29.769733965517243, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11788, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.525968 ], [ 29.736587586206898, -1.526237476923077 ], [ 29.736857068965517, -1.526237476923077 ], [ 29.736857068965517, -1.525968 ], [ 29.736587586206898, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11789, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.525968 ], [ 29.759493620689657, -1.526237476923077 ], [ 29.759763103448275, -1.526237476923077 ], [ 29.759763103448275, -1.525968 ], [ 29.759493620689657, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11790, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.525968 ], [ 29.759763103448275, -1.526237476923077 ], [ 29.760032586206897, -1.526237476923077 ], [ 29.760032586206897, -1.525968 ], [ 29.759763103448275, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11791, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.525968 ], [ 29.760032586206897, -1.526237476923077 ], [ 29.760302068965519, -1.526237476923077 ], [ 29.760302068965519, -1.525968 ], [ 29.760032586206897, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11792, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.525968 ], [ 29.760302068965519, -1.526237476923077 ], [ 29.760571551724137, -1.526237476923077 ], [ 29.760571551724137, -1.525968 ], [ 29.760302068965519, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11793, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.525968 ], [ 29.760571551724137, -1.526237476923077 ], [ 29.760841034482759, -1.526237476923077 ], [ 29.760841034482759, -1.525968 ], [ 29.760571551724137, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11794, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.525968 ], [ 29.760841034482759, -1.526237476923077 ], [ 29.761110517241381, -1.526237476923077 ], [ 29.761110517241381, -1.525968 ], [ 29.760841034482759, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11795, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.525968 ], [ 29.761110517241381, -1.526237476923077 ], [ 29.76138, -1.526237476923077 ], [ 29.76138, -1.525968 ], [ 29.761110517241381, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11796, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.525968 ], [ 29.76138, -1.526237476923077 ], [ 29.761649482758621, -1.526237476923077 ], [ 29.761649482758621, -1.525968 ], [ 29.76138, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11797, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.525968 ], [ 29.761649482758621, -1.526237476923077 ], [ 29.761918965517243, -1.526237476923077 ], [ 29.761918965517243, -1.525968 ], [ 29.761649482758621, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11798, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.525968 ], [ 29.761918965517243, -1.526237476923077 ], [ 29.762188448275861, -1.526237476923077 ], [ 29.762188448275861, -1.525968 ], [ 29.761918965517243, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11799, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.525968 ], [ 29.762188448275861, -1.526237476923077 ], [ 29.762457931034483, -1.526237476923077 ], [ 29.762457931034483, -1.525968 ], [ 29.762188448275861, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11800, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.525968 ], [ 29.762457931034483, -1.526237476923077 ], [ 29.762727413793105, -1.526237476923077 ], [ 29.762727413793105, -1.525968 ], [ 29.762457931034483, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11801, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.525968 ], [ 29.762727413793105, -1.526506953846154 ], [ 29.762996896551723, -1.526506953846154 ], [ 29.762996896551723, -1.525968 ], [ 29.762727413793105, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11802, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.525968 ], [ 29.763535862068967, -1.526506953846154 ], [ 29.763805344827588, -1.526506953846154 ], [ 29.763805344827588, -1.525968 ], [ 29.763535862068967, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11803, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.525968 ], [ 29.765422241379312, -1.526506953846154 ], [ 29.765961206896552, -1.526506953846154 ], [ 29.765961206896552, -1.525968 ], [ 29.765422241379312, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11804, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.525968 ], [ 29.765961206896552, -1.526506953846154 ], [ 29.766230689655174, -1.526506953846154 ], [ 29.766230689655174, -1.525968 ], [ 29.765961206896552, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11805, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.525968 ], [ 29.766769655172414, -1.526506953846154 ], [ 29.767039137931036, -1.526506953846154 ], [ 29.767039137931036, -1.525968 ], [ 29.766769655172414, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11806, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.525968 ], [ 29.767578103448276, -1.526237476923077 ], [ 29.767847586206898, -1.526237476923077 ], [ 29.767847586206898, -1.525968 ], [ 29.767578103448276, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11807, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.525968 ], [ 29.767847586206898, -1.526237476923077 ], [ 29.768117068965516, -1.526237476923077 ], [ 29.768117068965516, -1.525968 ], [ 29.767847586206898, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11808, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.525968 ], [ 29.768117068965516, -1.526237476923077 ], [ 29.768386551724138, -1.526237476923077 ], [ 29.768386551724138, -1.525968 ], [ 29.768117068965516, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11809, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.525968 ], [ 29.768386551724138, -1.526237476923077 ], [ 29.76865603448276, -1.526237476923077 ], [ 29.76865603448276, -1.525968 ], [ 29.768386551724138, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11810, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.525968 ], [ 29.76865603448276, -1.526237476923077 ], [ 29.768925517241382, -1.526237476923077 ], [ 29.768925517241382, -1.525968 ], [ 29.76865603448276, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11811, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.525968 ], [ 29.768925517241382, -1.526237476923077 ], [ 29.769195, -1.526237476923077 ], [ 29.769195, -1.525968 ], [ 29.768925517241382, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11812, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.525968 ], [ 29.769195, -1.526506953846154 ], [ 29.769464482758622, -1.526506953846154 ], [ 29.769464482758622, -1.525968 ], [ 29.769195, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11813, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.525968 ], [ 29.770003448275862, -1.526237476923077 ], [ 29.770272931034484, -1.526237476923077 ], [ 29.770272931034484, -1.525968 ], [ 29.770003448275862, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11814, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.525968 ], [ 29.770272931034484, -1.526506953846154 ], [ 29.770542413793105, -1.526506953846154 ], [ 29.770542413793105, -1.525968 ], [ 29.770272931034484, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11815, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.526237476923077 ], [ 29.759763103448275, -1.526506953846154 ], [ 29.760032586206897, -1.526506953846154 ], [ 29.760032586206897, -1.526237476923077 ], [ 29.759763103448275, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11816, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.526237476923077 ], [ 29.760032586206897, -1.526506953846154 ], [ 29.760302068965519, -1.526506953846154 ], [ 29.760302068965519, -1.526237476923077 ], [ 29.760032586206897, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11817, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.526237476923077 ], [ 29.760302068965519, -1.526506953846154 ], [ 29.760571551724137, -1.526506953846154 ], [ 29.760571551724137, -1.526237476923077 ], [ 29.760302068965519, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11818, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.526237476923077 ], [ 29.760571551724137, -1.526506953846154 ], [ 29.760841034482759, -1.526506953846154 ], [ 29.760841034482759, -1.526237476923077 ], [ 29.760571551724137, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11819, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.526237476923077 ], [ 29.760841034482759, -1.526506953846154 ], [ 29.761110517241381, -1.526506953846154 ], [ 29.761110517241381, -1.526237476923077 ], [ 29.760841034482759, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11820, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.526237476923077 ], [ 29.76138, -1.526506953846154 ], [ 29.761649482758621, -1.526506953846154 ], [ 29.761649482758621, -1.526237476923077 ], [ 29.76138, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11821, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.526237476923077 ], [ 29.761649482758621, -1.526506953846154 ], [ 29.761918965517243, -1.526506953846154 ], [ 29.761918965517243, -1.526237476923077 ], [ 29.761649482758621, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11822, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.526237476923077 ], [ 29.761918965517243, -1.526506953846154 ], [ 29.762188448275861, -1.526506953846154 ], [ 29.762188448275861, -1.526237476923077 ], [ 29.761918965517243, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11823, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.526237476923077 ], [ 29.766230689655174, -1.526506953846154 ], [ 29.766500172413792, -1.526506953846154 ], [ 29.766500172413792, -1.526237476923077 ], [ 29.766230689655174, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11824, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.526237476923077 ], [ 29.767039137931036, -1.526506953846154 ], [ 29.767308620689654, -1.526506953846154 ], [ 29.767308620689654, -1.526237476923077 ], [ 29.767039137931036, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11825, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.526237476923077 ], [ 29.767308620689654, -1.526506953846154 ], [ 29.767578103448276, -1.526506953846154 ], [ 29.767578103448276, -1.526237476923077 ], [ 29.767308620689654, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11826, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.526237476923077 ], [ 29.767578103448276, -1.526506953846154 ], [ 29.767847586206898, -1.526506953846154 ], [ 29.767847586206898, -1.526237476923077 ], [ 29.767578103448276, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11827, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.526237476923077 ], [ 29.767847586206898, -1.526506953846154 ], [ 29.768117068965516, -1.526506953846154 ], [ 29.768117068965516, -1.526237476923077 ], [ 29.767847586206898, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11828, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.526237476923077 ], [ 29.768117068965516, -1.526506953846154 ], [ 29.768386551724138, -1.526506953846154 ], [ 29.768386551724138, -1.526237476923077 ], [ 29.768117068965516, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11829, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.526237476923077 ], [ 29.768386551724138, -1.526506953846154 ], [ 29.76865603448276, -1.526506953846154 ], [ 29.76865603448276, -1.526237476923077 ], [ 29.768386551724138, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11830, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.526237476923077 ], [ 29.76865603448276, -1.526506953846154 ], [ 29.768925517241382, -1.526506953846154 ], [ 29.768925517241382, -1.526237476923077 ], [ 29.76865603448276, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11831, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.526237476923077 ], [ 29.768925517241382, -1.526506953846154 ], [ 29.769195, -1.526506953846154 ], [ 29.769195, -1.526237476923077 ], [ 29.768925517241382, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11832, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.526237476923077 ], [ 29.769464482758622, -1.526506953846154 ], [ 29.769733965517243, -1.526506953846154 ], [ 29.769733965517243, -1.526237476923077 ], [ 29.769464482758622, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11833, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.525159569230769 ], [ 29.740090862068964, -1.526776430769231 ], [ 29.740360344827586, -1.526776430769231 ], [ 29.740360344827586, -1.525159569230769 ], [ 29.740090862068964, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11834, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.525159569230769 ], [ 29.740360344827586, -1.526776430769231 ], [ 29.740629827586208, -1.526776430769231 ], [ 29.740629827586208, -1.525159569230769 ], [ 29.740360344827586, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11835, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.525159569230769 ], [ 29.740629827586208, -1.526776430769231 ], [ 29.74089931034483, -1.526776430769231 ], [ 29.74089931034483, -1.525159569230769 ], [ 29.740629827586208, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11836, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.525159569230769 ], [ 29.741168793103448, -1.527045907692308 ], [ 29.74143827586207, -1.527045907692308 ], [ 29.74143827586207, -1.525159569230769 ], [ 29.741168793103448, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11837, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.525159569230769 ], [ 29.74143827586207, -1.527045907692308 ], [ 29.741707758620691, -1.527045907692308 ], [ 29.741707758620691, -1.525159569230769 ], [ 29.74143827586207, -1.525159569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11838, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.525429046153846 ], [ 29.738743448275862, -1.526776430769231 ], [ 29.739012931034484, -1.526776430769231 ], [ 29.739012931034484, -1.525429046153846 ], [ 29.738743448275862, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11839, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.525429046153846 ], [ 29.739012931034484, -1.526776430769231 ], [ 29.739282413793102, -1.526776430769231 ], [ 29.739282413793102, -1.525429046153846 ], [ 29.739012931034484, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11840, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.525429046153846 ], [ 29.74089931034483, -1.526776430769231 ], [ 29.741168793103448, -1.526776430769231 ], [ 29.741168793103448, -1.525429046153846 ], [ 29.74089931034483, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11841, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741707758620691, -1.525429046153846 ], [ 29.741707758620691, -1.527315384615385 ], [ 29.74197724137931, -1.527315384615385 ], [ 29.74197724137931, -1.525429046153846 ], [ 29.741707758620691, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11842, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.525429046153846 ], [ 29.764883275862069, -1.528123815384615 ], [ 29.76515275862069, -1.528123815384615 ], [ 29.76515275862069, -1.527045907692308 ], [ 29.765422241379312, -1.527045907692308 ], [ 29.765422241379312, -1.526776430769231 ], [ 29.76515275862069, -1.526776430769231 ], [ 29.76515275862069, -1.525429046153846 ], [ 29.764883275862069, -1.525429046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11843, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.525968 ], [ 29.736857068965517, -1.527584861538462 ], [ 29.737126551724138, -1.527584861538462 ], [ 29.737126551724138, -1.525968 ], [ 29.736857068965517, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11844, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.525968 ], [ 29.737126551724138, -1.527584861538462 ], [ 29.73739603448276, -1.527584861538462 ], [ 29.73739603448276, -1.525968 ], [ 29.737126551724138, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11845, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.525968 ], [ 29.737665517241378, -1.527315384615385 ], [ 29.737935, -1.527315384615385 ], [ 29.737935, -1.525968 ], [ 29.737665517241378, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11846, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.525968 ], [ 29.737935, -1.527045907692308 ], [ 29.738204482758622, -1.527045907692308 ], [ 29.738204482758622, -1.525968 ], [ 29.737935, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11847, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.525968 ], [ 29.738204482758622, -1.527045907692308 ], [ 29.738743448275862, -1.527045907692308 ], [ 29.738743448275862, -1.525968 ], [ 29.738204482758622, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11848, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.525968 ], [ 29.762996896551723, -1.527854338461538 ], [ 29.763266379310345, -1.527854338461538 ], [ 29.763266379310345, -1.525968 ], [ 29.762996896551723, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11849, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.525968 ], [ 29.763805344827588, -1.528123815384615 ], [ 29.764074827586207, -1.528123815384615 ], [ 29.764074827586207, -1.525968 ], [ 29.763805344827588, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11850, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.525968 ], [ 29.76461379310345, -1.526776430769231 ], [ 29.764883275862069, -1.526776430769231 ], [ 29.764883275862069, -1.525968 ], [ 29.76461379310345, -1.525968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11851, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.526237476923077 ], [ 29.736587586206898, -1.527854338461538 ], [ 29.736857068965517, -1.527854338461538 ], [ 29.736857068965517, -1.526237476923077 ], [ 29.736587586206898, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11852, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.526237476923077 ], [ 29.73739603448276, -1.527315384615385 ], [ 29.737665517241378, -1.527315384615385 ], [ 29.737665517241378, -1.526237476923077 ], [ 29.73739603448276, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11853, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.526237476923077 ], [ 29.761110517241381, -1.526776430769231 ], [ 29.76138, -1.526776430769231 ], [ 29.76138, -1.526237476923077 ], [ 29.761110517241381, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11854, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.526237476923077 ], [ 29.762188448275861, -1.527584861538462 ], [ 29.762457931034483, -1.527584861538462 ], [ 29.762457931034483, -1.526237476923077 ], [ 29.762188448275861, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11855, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.526237476923077 ], [ 29.762457931034483, -1.527584861538462 ], [ 29.762727413793105, -1.527584861538462 ], [ 29.762727413793105, -1.526237476923077 ], [ 29.762457931034483, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11856, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.526237476923077 ], [ 29.763266379310345, -1.527584861538462 ], [ 29.763535862068967, -1.527584861538462 ], [ 29.763535862068967, -1.526237476923077 ], [ 29.763266379310345, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11857, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.526237476923077 ], [ 29.764074827586207, -1.527584861538462 ], [ 29.764344310344828, -1.527584861538462 ], [ 29.764344310344828, -1.526237476923077 ], [ 29.764074827586207, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11858, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.526237476923077 ], [ 29.764344310344828, -1.527584861538462 ], [ 29.76461379310345, -1.527584861538462 ], [ 29.76461379310345, -1.526237476923077 ], [ 29.764344310344828, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11859, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.526237476923077 ], [ 29.76515275862069, -1.526776430769231 ], [ 29.765422241379312, -1.526776430769231 ], [ 29.765422241379312, -1.526237476923077 ], [ 29.76515275862069, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11860, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.526237476923077 ], [ 29.766500172413792, -1.526776430769231 ], [ 29.766769655172414, -1.526776430769231 ], [ 29.766769655172414, -1.526237476923077 ], [ 29.766500172413792, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11861, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.526237476923077 ], [ 29.769733965517243, -1.526776430769231 ], [ 29.770003448275862, -1.526776430769231 ], [ 29.770003448275862, -1.526237476923077 ], [ 29.769733965517243, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11862, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.526237476923077 ], [ 29.770003448275862, -1.526776430769231 ], [ 29.770272931034484, -1.526776430769231 ], [ 29.770272931034484, -1.526237476923077 ], [ 29.770003448275862, -1.526237476923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11863, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.526506953846154 ], [ 29.739282413793102, -1.526776430769231 ], [ 29.739551896551724, -1.526776430769231 ], [ 29.739551896551724, -1.526506953846154 ], [ 29.739282413793102, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11864, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.526506953846154 ], [ 29.739551896551724, -1.526776430769231 ], [ 29.739821379310346, -1.526776430769231 ], [ 29.739821379310346, -1.526506953846154 ], [ 29.739551896551724, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11865, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.526506953846154 ], [ 29.739821379310346, -1.526776430769231 ], [ 29.740090862068964, -1.526776430769231 ], [ 29.740090862068964, -1.526506953846154 ], [ 29.739821379310346, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11866, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.526506953846154 ], [ 29.760032586206897, -1.526776430769231 ], [ 29.760302068965519, -1.526776430769231 ], [ 29.760302068965519, -1.526506953846154 ], [ 29.760032586206897, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11867, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.526506953846154 ], [ 29.760302068965519, -1.526776430769231 ], [ 29.760571551724137, -1.526776430769231 ], [ 29.760571551724137, -1.526506953846154 ], [ 29.760302068965519, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11868, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.526506953846154 ], [ 29.760571551724137, -1.527315384615385 ], [ 29.760841034482759, -1.527315384615385 ], [ 29.760841034482759, -1.526506953846154 ], [ 29.760571551724137, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11869, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.526506953846154 ], [ 29.760841034482759, -1.527315384615385 ], [ 29.761110517241381, -1.527315384615385 ], [ 29.761110517241381, -1.526506953846154 ], [ 29.760841034482759, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11870, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.526506953846154 ], [ 29.76138, -1.527315384615385 ], [ 29.761649482758621, -1.527315384615385 ], [ 29.761649482758621, -1.526506953846154 ], [ 29.76138, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11871, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.526506953846154 ], [ 29.761649482758621, -1.527315384615385 ], [ 29.761918965517243, -1.527315384615385 ], [ 29.761918965517243, -1.526506953846154 ], [ 29.761649482758621, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11872, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.526506953846154 ], [ 29.761918965517243, -1.527315384615385 ], [ 29.762188448275861, -1.527315384615385 ], [ 29.762188448275861, -1.526506953846154 ], [ 29.761918965517243, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11873, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.526506953846154 ], [ 29.762727413793105, -1.527315384615385 ], [ 29.762996896551723, -1.527315384615385 ], [ 29.762996896551723, -1.526506953846154 ], [ 29.762727413793105, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11874, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.526506953846154 ], [ 29.763535862068967, -1.527315384615385 ], [ 29.763805344827588, -1.527315384615385 ], [ 29.763805344827588, -1.526506953846154 ], [ 29.763535862068967, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11875, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.526506953846154 ], [ 29.765422241379312, -1.526776430769231 ], [ 29.765961206896552, -1.526776430769231 ], [ 29.765961206896552, -1.526506953846154 ], [ 29.765422241379312, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11876, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.526506953846154 ], [ 29.765961206896552, -1.526776430769231 ], [ 29.766230689655174, -1.526776430769231 ], [ 29.766230689655174, -1.526506953846154 ], [ 29.765961206896552, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11877, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.526506953846154 ], [ 29.766230689655174, -1.527045907692308 ], [ 29.766500172413792, -1.527045907692308 ], [ 29.766500172413792, -1.526506953846154 ], [ 29.766230689655174, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11878, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.526506953846154 ], [ 29.766769655172414, -1.526776430769231 ], [ 29.767039137931036, -1.526776430769231 ], [ 29.767039137931036, -1.526506953846154 ], [ 29.766769655172414, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11879, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.526506953846154 ], [ 29.767039137931036, -1.526776430769231 ], [ 29.767308620689654, -1.526776430769231 ], [ 29.767308620689654, -1.526506953846154 ], [ 29.767039137931036, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11880, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.526506953846154 ], [ 29.767308620689654, -1.526776430769231 ], [ 29.767578103448276, -1.526776430769231 ], [ 29.767578103448276, -1.526506953846154 ], [ 29.767308620689654, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11881, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.526506953846154 ], [ 29.767578103448276, -1.526776430769231 ], [ 29.767847586206898, -1.526776430769231 ], [ 29.767847586206898, -1.526506953846154 ], [ 29.767578103448276, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11882, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.526506953846154 ], [ 29.767847586206898, -1.526776430769231 ], [ 29.768117068965516, -1.526776430769231 ], [ 29.768117068965516, -1.526506953846154 ], [ 29.767847586206898, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11883, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.526506953846154 ], [ 29.768117068965516, -1.526776430769231 ], [ 29.768386551724138, -1.526776430769231 ], [ 29.768386551724138, -1.526506953846154 ], [ 29.768117068965516, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11884, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.526506953846154 ], [ 29.768386551724138, -1.526776430769231 ], [ 29.76865603448276, -1.526776430769231 ], [ 29.76865603448276, -1.526506953846154 ], [ 29.768386551724138, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11885, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.526506953846154 ], [ 29.76865603448276, -1.526776430769231 ], [ 29.768925517241382, -1.526776430769231 ], [ 29.768925517241382, -1.526506953846154 ], [ 29.76865603448276, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11886, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.526506953846154 ], [ 29.768925517241382, -1.526776430769231 ], [ 29.769195, -1.526776430769231 ], [ 29.769195, -1.526506953846154 ], [ 29.768925517241382, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11887, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.526506953846154 ], [ 29.769195, -1.526776430769231 ], [ 29.769464482758622, -1.526776430769231 ], [ 29.769464482758622, -1.526506953846154 ], [ 29.769195, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11888, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.526506953846154 ], [ 29.769464482758622, -1.527045907692308 ], [ 29.769733965517243, -1.527045907692308 ], [ 29.769733965517243, -1.526506953846154 ], [ 29.769464482758622, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11889, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.526506953846154 ], [ 29.770272931034484, -1.526776430769231 ], [ 29.770542413793105, -1.526776430769231 ], [ 29.770542413793105, -1.526506953846154 ], [ 29.770272931034484, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11890, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.526506953846154 ], [ 29.770542413793105, -1.527045907692308 ], [ 29.770811896551724, -1.527045907692308 ], [ 29.770811896551724, -1.526506953846154 ], [ 29.770542413793105, -1.526506953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11891, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.526776430769231 ], [ 29.738743448275862, -1.527045907692308 ], [ 29.739012931034484, -1.527045907692308 ], [ 29.739012931034484, -1.526776430769231 ], [ 29.738743448275862, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11892, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.526776430769231 ], [ 29.739012931034484, -1.527045907692308 ], [ 29.739282413793102, -1.527045907692308 ], [ 29.739282413793102, -1.526776430769231 ], [ 29.739012931034484, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11893, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.526776430769231 ], [ 29.739282413793102, -1.527315384615385 ], [ 29.739551896551724, -1.527315384615385 ], [ 29.739551896551724, -1.526776430769231 ], [ 29.739282413793102, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11894, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.526776430769231 ], [ 29.739821379310346, -1.527045907692308 ], [ 29.740090862068964, -1.527045907692308 ], [ 29.740090862068964, -1.526776430769231 ], [ 29.739821379310346, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11895, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.526776430769231 ], [ 29.740090862068964, -1.527045907692308 ], [ 29.740360344827586, -1.527045907692308 ], [ 29.740360344827586, -1.526776430769231 ], [ 29.740090862068964, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11896, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.526776430769231 ], [ 29.740360344827586, -1.527045907692308 ], [ 29.740629827586208, -1.527045907692308 ], [ 29.740629827586208, -1.526776430769231 ], [ 29.740360344827586, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11897, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.526776430769231 ], [ 29.740629827586208, -1.527315384615385 ], [ 29.74089931034483, -1.527315384615385 ], [ 29.74089931034483, -1.526776430769231 ], [ 29.740629827586208, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11898, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.526776430769231 ], [ 29.74089931034483, -1.527045907692308 ], [ 29.741168793103448, -1.527045907692308 ], [ 29.741168793103448, -1.526776430769231 ], [ 29.74089931034483, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11899, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.526776430769231 ], [ 29.760302068965519, -1.527045907692308 ], [ 29.760571551724137, -1.527045907692308 ], [ 29.760571551724137, -1.526776430769231 ], [ 29.760302068965519, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11900, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.526776430769231 ], [ 29.761110517241381, -1.527045907692308 ], [ 29.76138, -1.527045907692308 ], [ 29.76138, -1.526776430769231 ], [ 29.761110517241381, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11901, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.526776430769231 ], [ 29.76461379310345, -1.527045907692308 ], [ 29.764883275862069, -1.527045907692308 ], [ 29.764883275862069, -1.526776430769231 ], [ 29.76461379310345, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11902, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.526776430769231 ], [ 29.765422241379312, -1.527045907692308 ], [ 29.765961206896552, -1.527045907692308 ], [ 29.765961206896552, -1.526776430769231 ], [ 29.765422241379312, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11903, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.526776430769231 ], [ 29.765961206896552, -1.527315384615385 ], [ 29.766230689655174, -1.527315384615385 ], [ 29.766230689655174, -1.526776430769231 ], [ 29.765961206896552, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11904, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.526776430769231 ], [ 29.766500172413792, -1.527045907692308 ], [ 29.766769655172414, -1.527045907692308 ], [ 29.766769655172414, -1.526776430769231 ], [ 29.766500172413792, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11905, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.526776430769231 ], [ 29.766769655172414, -1.527045907692308 ], [ 29.767039137931036, -1.527045907692308 ], [ 29.767039137931036, -1.526776430769231 ], [ 29.766769655172414, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11906, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.526776430769231 ], [ 29.767039137931036, -1.527045907692308 ], [ 29.767308620689654, -1.527045907692308 ], [ 29.767308620689654, -1.526776430769231 ], [ 29.767039137931036, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11907, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.526776430769231 ], [ 29.767308620689654, -1.527045907692308 ], [ 29.767578103448276, -1.527045907692308 ], [ 29.767578103448276, -1.526776430769231 ], [ 29.767308620689654, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11908, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.526776430769231 ], [ 29.767578103448276, -1.527045907692308 ], [ 29.767847586206898, -1.527045907692308 ], [ 29.767847586206898, -1.526776430769231 ], [ 29.767578103448276, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11909, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.526776430769231 ], [ 29.767847586206898, -1.527045907692308 ], [ 29.768117068965516, -1.527045907692308 ], [ 29.768117068965516, -1.526776430769231 ], [ 29.767847586206898, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11910, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.526776430769231 ], [ 29.768117068965516, -1.527045907692308 ], [ 29.768386551724138, -1.527045907692308 ], [ 29.768386551724138, -1.526776430769231 ], [ 29.768117068965516, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11911, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.526776430769231 ], [ 29.768386551724138, -1.527045907692308 ], [ 29.76865603448276, -1.527045907692308 ], [ 29.76865603448276, -1.526776430769231 ], [ 29.768386551724138, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11912, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.526776430769231 ], [ 29.76865603448276, -1.527045907692308 ], [ 29.768925517241382, -1.527045907692308 ], [ 29.768925517241382, -1.526776430769231 ], [ 29.76865603448276, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11913, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.526776430769231 ], [ 29.768925517241382, -1.527045907692308 ], [ 29.769195, -1.527045907692308 ], [ 29.769195, -1.526776430769231 ], [ 29.768925517241382, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11914, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.526776430769231 ], [ 29.769195, -1.527045907692308 ], [ 29.769464482758622, -1.527045907692308 ], [ 29.769464482758622, -1.526776430769231 ], [ 29.769195, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11915, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.526776430769231 ], [ 29.769733965517243, -1.527045907692308 ], [ 29.770003448275862, -1.527045907692308 ], [ 29.770003448275862, -1.526776430769231 ], [ 29.769733965517243, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11916, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.526776430769231 ], [ 29.770003448275862, -1.527315384615385 ], [ 29.770272931034484, -1.527315384615385 ], [ 29.770272931034484, -1.526776430769231 ], [ 29.770003448275862, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11917, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.526776430769231 ], [ 29.770272931034484, -1.527315384615385 ], [ 29.770542413793105, -1.527315384615385 ], [ 29.770542413793105, -1.526776430769231 ], [ 29.770272931034484, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11918, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.526776430769231 ], [ 29.771889827586207, -1.527045907692308 ], [ 29.772967758620691, -1.527045907692308 ], [ 29.772967758620691, -1.527854338461538 ], [ 29.773237241379309, -1.527854338461538 ], [ 29.773237241379309, -1.526776430769231 ], [ 29.771889827586207, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11919, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.527045907692308 ], [ 29.737935, -1.527315384615385 ], [ 29.738204482758622, -1.527315384615385 ], [ 29.738204482758622, -1.527045907692308 ], [ 29.737935, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11920, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.527045907692308 ], [ 29.738204482758622, -1.527315384615385 ], [ 29.738743448275862, -1.527315384615385 ], [ 29.738743448275862, -1.527045907692308 ], [ 29.738204482758622, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11921, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.527045907692308 ], [ 29.738743448275862, -1.527315384615385 ], [ 29.739012931034484, -1.527315384615385 ], [ 29.739012931034484, -1.527045907692308 ], [ 29.738743448275862, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11922, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.527045907692308 ], [ 29.739012931034484, -1.527315384615385 ], [ 29.739282413793102, -1.527315384615385 ], [ 29.739282413793102, -1.527045907692308 ], [ 29.739012931034484, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11923, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.527045907692308 ], [ 29.739821379310346, -1.528393292307692 ], [ 29.740090862068964, -1.528393292307692 ], [ 29.740090862068964, -1.527045907692308 ], [ 29.739821379310346, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11924, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.527045907692308 ], [ 29.740090862068964, -1.528393292307692 ], [ 29.740360344827586, -1.528393292307692 ], [ 29.740360344827586, -1.527045907692308 ], [ 29.740090862068964, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11925, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.527045907692308 ], [ 29.740360344827586, -1.528393292307692 ], [ 29.740629827586208, -1.528393292307692 ], [ 29.740629827586208, -1.527045907692308 ], [ 29.740360344827586, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11926, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.527045907692308 ], [ 29.74089931034483, -1.528662769230769 ], [ 29.741168793103448, -1.528662769230769 ], [ 29.741168793103448, -1.527045907692308 ], [ 29.74089931034483, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11927, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.527045907692308 ], [ 29.741168793103448, -1.528662769230769 ], [ 29.74143827586207, -1.528662769230769 ], [ 29.74143827586207, -1.527045907692308 ], [ 29.741168793103448, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11928, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.527045907692308 ], [ 29.74143827586207, -1.527315384615385 ], [ 29.741707758620691, -1.527315384615385 ], [ 29.741707758620691, -1.527045907692308 ], [ 29.74143827586207, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11929, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.527045907692308 ], [ 29.760032586206897, -1.527315384615385 ], [ 29.760302068965519, -1.527315384615385 ], [ 29.760302068965519, -1.527045907692308 ], [ 29.760032586206897, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11930, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.527045907692308 ], [ 29.760302068965519, -1.527315384615385 ], [ 29.760571551724137, -1.527315384615385 ], [ 29.760571551724137, -1.527045907692308 ], [ 29.760302068965519, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11931, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.527045907692308 ], [ 29.761110517241381, -1.527584861538462 ], [ 29.76138, -1.527584861538462 ], [ 29.76138, -1.527045907692308 ], [ 29.761110517241381, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11932, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.527045907692308 ], [ 29.76461379310345, -1.528123815384615 ], [ 29.764883275862069, -1.528123815384615 ], [ 29.764883275862069, -1.527045907692308 ], [ 29.76461379310345, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11933, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.527045907692308 ], [ 29.76515275862069, -1.527315384615385 ], [ 29.765422241379312, -1.527315384615385 ], [ 29.765422241379312, -1.527045907692308 ], [ 29.76515275862069, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11934, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.527045907692308 ], [ 29.765422241379312, -1.527315384615385 ], [ 29.765961206896552, -1.527315384615385 ], [ 29.765961206896552, -1.527045907692308 ], [ 29.765422241379312, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11935, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.527045907692308 ], [ 29.766230689655174, -1.527315384615385 ], [ 29.766500172413792, -1.527315384615385 ], [ 29.766500172413792, -1.527045907692308 ], [ 29.766230689655174, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11936, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.527045907692308 ], [ 29.766500172413792, -1.527315384615385 ], [ 29.766769655172414, -1.527315384615385 ], [ 29.766769655172414, -1.527045907692308 ], [ 29.766500172413792, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11937, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.527045907692308 ], [ 29.766769655172414, -1.527315384615385 ], [ 29.767039137931036, -1.527315384615385 ], [ 29.767039137931036, -1.527045907692308 ], [ 29.766769655172414, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11938, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.527045907692308 ], [ 29.767039137931036, -1.527315384615385 ], [ 29.767308620689654, -1.527315384615385 ], [ 29.767308620689654, -1.527045907692308 ], [ 29.767039137931036, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11939, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.527045907692308 ], [ 29.767308620689654, -1.527315384615385 ], [ 29.767578103448276, -1.527315384615385 ], [ 29.767578103448276, -1.527045907692308 ], [ 29.767308620689654, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11940, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.527045907692308 ], [ 29.767578103448276, -1.527315384615385 ], [ 29.767847586206898, -1.527315384615385 ], [ 29.767847586206898, -1.527045907692308 ], [ 29.767578103448276, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11941, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.527045907692308 ], [ 29.767847586206898, -1.527315384615385 ], [ 29.768117068965516, -1.527315384615385 ], [ 29.768117068965516, -1.527045907692308 ], [ 29.767847586206898, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11942, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.527045907692308 ], [ 29.768117068965516, -1.527315384615385 ], [ 29.768386551724138, -1.527315384615385 ], [ 29.768386551724138, -1.527045907692308 ], [ 29.768117068965516, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11943, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.527045907692308 ], [ 29.768386551724138, -1.527315384615385 ], [ 29.76865603448276, -1.527315384615385 ], [ 29.76865603448276, -1.527045907692308 ], [ 29.768386551724138, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11944, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.527045907692308 ], [ 29.76865603448276, -1.527315384615385 ], [ 29.768925517241382, -1.527315384615385 ], [ 29.768925517241382, -1.527045907692308 ], [ 29.76865603448276, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11945, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.527045907692308 ], [ 29.768925517241382, -1.527315384615385 ], [ 29.769195, -1.527315384615385 ], [ 29.769195, -1.527045907692308 ], [ 29.768925517241382, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11946, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.527045907692308 ], [ 29.769195, -1.527315384615385 ], [ 29.769464482758622, -1.527315384615385 ], [ 29.769464482758622, -1.527045907692308 ], [ 29.769195, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11947, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.527045907692308 ], [ 29.769464482758622, -1.527315384615385 ], [ 29.769733965517243, -1.527315384615385 ], [ 29.769733965517243, -1.527045907692308 ], [ 29.769464482758622, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11948, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.527045907692308 ], [ 29.769733965517243, -1.527584861538462 ], [ 29.770003448275862, -1.527584861538462 ], [ 29.770003448275862, -1.527045907692308 ], [ 29.769733965517243, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11949, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.527045907692308 ], [ 29.770542413793105, -1.527315384615385 ], [ 29.770811896551724, -1.527315384615385 ], [ 29.770811896551724, -1.527045907692308 ], [ 29.770542413793105, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11950, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.527045907692308 ], [ 29.770811896551724, -1.527315384615385 ], [ 29.771889827586207, -1.527315384615385 ], [ 29.771889827586207, -1.527045907692308 ], [ 29.770811896551724, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11951, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.527045907692308 ], [ 29.771889827586207, -1.527315384615385 ], [ 29.772159310344829, -1.527315384615385 ], [ 29.772159310344829, -1.527045907692308 ], [ 29.771889827586207, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11952, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.527045907692308 ], [ 29.772159310344829, -1.527315384615385 ], [ 29.772698275862069, -1.527315384615385 ], [ 29.772698275862069, -1.528123815384615 ], [ 29.772967758620691, -1.528123815384615 ], [ 29.772967758620691, -1.527045907692308 ], [ 29.772159310344829, -1.527045907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11953, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.527315384615385 ], [ 29.73739603448276, -1.527584861538462 ], [ 29.737665517241378, -1.527584861538462 ], [ 29.737665517241378, -1.527315384615385 ], [ 29.73739603448276, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11954, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.527315384615385 ], [ 29.737665517241378, -1.527584861538462 ], [ 29.737935, -1.527584861538462 ], [ 29.737935, -1.527315384615385 ], [ 29.737665517241378, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11955, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.527315384615385 ], [ 29.737935, -1.527584861538462 ], [ 29.738204482758622, -1.527584861538462 ], [ 29.738204482758622, -1.527315384615385 ], [ 29.737935, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11956, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.527315384615385 ], [ 29.738204482758622, -1.527584861538462 ], [ 29.738743448275862, -1.527584861538462 ], [ 29.738743448275862, -1.527315384615385 ], [ 29.738204482758622, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11957, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.527315384615385 ], [ 29.738743448275862, -1.527584861538462 ], [ 29.739012931034484, -1.527584861538462 ], [ 29.739012931034484, -1.527315384615385 ], [ 29.738743448275862, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11958, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.527315384615385 ], [ 29.739012931034484, -1.527584861538462 ], [ 29.739282413793102, -1.527584861538462 ], [ 29.739282413793102, -1.527315384615385 ], [ 29.739012931034484, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11959, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.527315384615385 ], [ 29.739282413793102, -1.527584861538462 ], [ 29.739551896551724, -1.527584861538462 ], [ 29.739551896551724, -1.527315384615385 ], [ 29.739282413793102, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11960, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.527315384615385 ], [ 29.740629827586208, -1.528393292307692 ], [ 29.74089931034483, -1.528393292307692 ], [ 29.74089931034483, -1.527315384615385 ], [ 29.740629827586208, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11961, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.527315384615385 ], [ 29.759763103448275, -1.527584861538462 ], [ 29.760032586206897, -1.527584861538462 ], [ 29.760032586206897, -1.527315384615385 ], [ 29.759763103448275, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11962, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.527315384615385 ], [ 29.760032586206897, -1.527584861538462 ], [ 29.760302068965519, -1.527584861538462 ], [ 29.760302068965519, -1.527315384615385 ], [ 29.760032586206897, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11963, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.527315384615385 ], [ 29.760302068965519, -1.527584861538462 ], [ 29.760571551724137, -1.527584861538462 ], [ 29.760571551724137, -1.527315384615385 ], [ 29.760302068965519, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11964, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.527315384615385 ], [ 29.760571551724137, -1.527584861538462 ], [ 29.760841034482759, -1.527584861538462 ], [ 29.760841034482759, -1.527315384615385 ], [ 29.760571551724137, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11965, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.527315384615385 ], [ 29.760841034482759, -1.527584861538462 ], [ 29.761110517241381, -1.527584861538462 ], [ 29.761110517241381, -1.527315384615385 ], [ 29.760841034482759, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11966, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.527315384615385 ], [ 29.76138, -1.527584861538462 ], [ 29.761649482758621, -1.527584861538462 ], [ 29.761649482758621, -1.527315384615385 ], [ 29.76138, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11967, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.527315384615385 ], [ 29.761649482758621, -1.527584861538462 ], [ 29.761918965517243, -1.527584861538462 ], [ 29.761918965517243, -1.527315384615385 ], [ 29.761649482758621, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11968, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.527315384615385 ], [ 29.761918965517243, -1.527584861538462 ], [ 29.762188448275861, -1.527584861538462 ], [ 29.762188448275861, -1.527315384615385 ], [ 29.761918965517243, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11969, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.527315384615385 ], [ 29.762727413793105, -1.527854338461538 ], [ 29.762996896551723, -1.527854338461538 ], [ 29.762996896551723, -1.527315384615385 ], [ 29.762727413793105, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11970, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.527315384615385 ], [ 29.763535862068967, -1.528123815384615 ], [ 29.763805344827588, -1.528123815384615 ], [ 29.763805344827588, -1.527315384615385 ], [ 29.763535862068967, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11971, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.527315384615385 ], [ 29.76515275862069, -1.527584861538462 ], [ 29.765422241379312, -1.527584861538462 ], [ 29.765422241379312, -1.527315384615385 ], [ 29.76515275862069, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11972, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.527315384615385 ], [ 29.765422241379312, -1.527584861538462 ], [ 29.765961206896552, -1.527584861538462 ], [ 29.765961206896552, -1.527315384615385 ], [ 29.765422241379312, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11973, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.527315384615385 ], [ 29.765961206896552, -1.527584861538462 ], [ 29.766230689655174, -1.527584861538462 ], [ 29.766230689655174, -1.527315384615385 ], [ 29.765961206896552, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11974, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.527315384615385 ], [ 29.766230689655174, -1.527584861538462 ], [ 29.766500172413792, -1.527584861538462 ], [ 29.766500172413792, -1.527315384615385 ], [ 29.766230689655174, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11975, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.527315384615385 ], [ 29.766500172413792, -1.527584861538462 ], [ 29.766769655172414, -1.527584861538462 ], [ 29.766769655172414, -1.527315384615385 ], [ 29.766500172413792, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11976, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.527315384615385 ], [ 29.766769655172414, -1.527584861538462 ], [ 29.767039137931036, -1.527584861538462 ], [ 29.767039137931036, -1.527315384615385 ], [ 29.766769655172414, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11977, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.527315384615385 ], [ 29.767039137931036, -1.527584861538462 ], [ 29.767308620689654, -1.527584861538462 ], [ 29.767308620689654, -1.527315384615385 ], [ 29.767039137931036, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11978, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.527315384615385 ], [ 29.767308620689654, -1.527584861538462 ], [ 29.767578103448276, -1.527584861538462 ], [ 29.767578103448276, -1.527315384615385 ], [ 29.767308620689654, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11979, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.527315384615385 ], [ 29.767578103448276, -1.527584861538462 ], [ 29.767847586206898, -1.527584861538462 ], [ 29.767847586206898, -1.527315384615385 ], [ 29.767578103448276, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11980, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.527315384615385 ], [ 29.767847586206898, -1.527584861538462 ], [ 29.768117068965516, -1.527584861538462 ], [ 29.768117068965516, -1.527315384615385 ], [ 29.767847586206898, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11981, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.527315384615385 ], [ 29.768117068965516, -1.527584861538462 ], [ 29.768386551724138, -1.527584861538462 ], [ 29.768386551724138, -1.527315384615385 ], [ 29.768117068965516, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11982, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.527315384615385 ], [ 29.768386551724138, -1.527584861538462 ], [ 29.76865603448276, -1.527584861538462 ], [ 29.76865603448276, -1.527315384615385 ], [ 29.768386551724138, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11983, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.527315384615385 ], [ 29.76865603448276, -1.527584861538462 ], [ 29.768925517241382, -1.527584861538462 ], [ 29.768925517241382, -1.527315384615385 ], [ 29.76865603448276, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11984, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.527315384615385 ], [ 29.768925517241382, -1.527584861538462 ], [ 29.769195, -1.527584861538462 ], [ 29.769195, -1.527315384615385 ], [ 29.768925517241382, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11985, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.527315384615385 ], [ 29.769195, -1.527584861538462 ], [ 29.769464482758622, -1.527584861538462 ], [ 29.769464482758622, -1.527315384615385 ], [ 29.769195, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11986, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.527315384615385 ], [ 29.769464482758622, -1.527584861538462 ], [ 29.769733965517243, -1.527584861538462 ], [ 29.769733965517243, -1.527315384615385 ], [ 29.769464482758622, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11987, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.527315384615385 ], [ 29.770003448275862, -1.527584861538462 ], [ 29.770272931034484, -1.527584861538462 ], [ 29.770272931034484, -1.527315384615385 ], [ 29.770003448275862, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11988, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.527315384615385 ], [ 29.770272931034484, -1.527584861538462 ], [ 29.770542413793105, -1.527584861538462 ], [ 29.770542413793105, -1.527315384615385 ], [ 29.770272931034484, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11989, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.527315384615385 ], [ 29.770542413793105, -1.527584861538462 ], [ 29.772159310344829, -1.527584861538462 ], [ 29.772159310344829, -1.527315384615385 ], [ 29.770542413793105, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11990, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.527315384615385 ], [ 29.772159310344829, -1.527584861538462 ], [ 29.772428793103447, -1.527584861538462 ], [ 29.772428793103447, -1.528123815384615 ], [ 29.772698275862069, -1.528123815384615 ], [ 29.772698275862069, -1.527315384615385 ], [ 29.772159310344829, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11991, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.527584861538462 ], [ 29.736857068965517, -1.527854338461538 ], [ 29.737126551724138, -1.527854338461538 ], [ 29.737126551724138, -1.527584861538462 ], [ 29.736857068965517, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11992, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.527584861538462 ], [ 29.737126551724138, -1.527854338461538 ], [ 29.73739603448276, -1.527854338461538 ], [ 29.73739603448276, -1.527584861538462 ], [ 29.737126551724138, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11993, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.527584861538462 ], [ 29.73739603448276, -1.527854338461538 ], [ 29.737665517241378, -1.527854338461538 ], [ 29.737665517241378, -1.527584861538462 ], [ 29.73739603448276, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11994, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.527584861538462 ], [ 29.737665517241378, -1.527854338461538 ], [ 29.737935, -1.527854338461538 ], [ 29.737935, -1.527584861538462 ], [ 29.737665517241378, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11995, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.527584861538462 ], [ 29.737935, -1.528123815384615 ], [ 29.738204482758622, -1.528123815384615 ], [ 29.738204482758622, -1.527584861538462 ], [ 29.737935, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11996, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.527584861538462 ], [ 29.738204482758622, -1.527854338461538 ], [ 29.738743448275862, -1.527854338461538 ], [ 29.738743448275862, -1.527584861538462 ], [ 29.738204482758622, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11997, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.527584861538462 ], [ 29.738743448275862, -1.527854338461538 ], [ 29.739012931034484, -1.527854338461538 ], [ 29.739012931034484, -1.527584861538462 ], [ 29.738743448275862, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11998, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.527584861538462 ], [ 29.739012931034484, -1.527854338461538 ], [ 29.739282413793102, -1.527854338461538 ], [ 29.739282413793102, -1.527584861538462 ], [ 29.739012931034484, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11999, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.526776430769231 ], [ 29.739821379310346, -1.526776430769231 ], [ 29.739821379310346, -1.528123815384615 ], [ 29.739551896551724, -1.528123815384615 ], [ 29.739551896551724, -1.527854338461538 ], [ 29.739282413793102, -1.527854338461538 ], [ 29.739282413793102, -1.527584861538462 ], [ 29.739551896551724, -1.527584861538462 ], [ 29.739551896551724, -1.526776430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12000, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.527584861538462 ], [ 29.759493620689657, -1.528123815384615 ], [ 29.759763103448275, -1.528123815384615 ], [ 29.759763103448275, -1.527584861538462 ], [ 29.759493620689657, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12001, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.527584861538462 ], [ 29.759763103448275, -1.527854338461538 ], [ 29.760032586206897, -1.527854338461538 ], [ 29.760032586206897, -1.527584861538462 ], [ 29.759763103448275, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12002, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.527584861538462 ], [ 29.760032586206897, -1.527854338461538 ], [ 29.760302068965519, -1.527854338461538 ], [ 29.760302068965519, -1.527584861538462 ], [ 29.760032586206897, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12003, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.527584861538462 ], [ 29.760302068965519, -1.527854338461538 ], [ 29.760571551724137, -1.527854338461538 ], [ 29.760571551724137, -1.527584861538462 ], [ 29.760302068965519, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12004, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.527584861538462 ], [ 29.760571551724137, -1.527854338461538 ], [ 29.760841034482759, -1.527854338461538 ], [ 29.760841034482759, -1.527584861538462 ], [ 29.760571551724137, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12005, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.527584861538462 ], [ 29.760841034482759, -1.527854338461538 ], [ 29.761110517241381, -1.527854338461538 ], [ 29.761110517241381, -1.527584861538462 ], [ 29.760841034482759, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12006, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.527584861538462 ], [ 29.761110517241381, -1.527854338461538 ], [ 29.76138, -1.527854338461538 ], [ 29.76138, -1.527584861538462 ], [ 29.761110517241381, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12007, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.527584861538462 ], [ 29.76138, -1.527854338461538 ], [ 29.761649482758621, -1.527854338461538 ], [ 29.761649482758621, -1.527584861538462 ], [ 29.76138, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12008, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.527584861538462 ], [ 29.761649482758621, -1.527854338461538 ], [ 29.761918965517243, -1.527854338461538 ], [ 29.761918965517243, -1.527584861538462 ], [ 29.761649482758621, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12009, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.527584861538462 ], [ 29.761918965517243, -1.527854338461538 ], [ 29.762188448275861, -1.527854338461538 ], [ 29.762188448275861, -1.527584861538462 ], [ 29.761918965517243, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12010, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.527584861538462 ], [ 29.762188448275861, -1.527854338461538 ], [ 29.762457931034483, -1.527854338461538 ], [ 29.762457931034483, -1.527584861538462 ], [ 29.762188448275861, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12011, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.527584861538462 ], [ 29.762457931034483, -1.527854338461538 ], [ 29.762727413793105, -1.527854338461538 ], [ 29.762727413793105, -1.527584861538462 ], [ 29.762457931034483, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12012, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.527584861538462 ], [ 29.763266379310345, -1.528123815384615 ], [ 29.763535862068967, -1.528123815384615 ], [ 29.763535862068967, -1.527584861538462 ], [ 29.763266379310345, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12013, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.527584861538462 ], [ 29.764074827586207, -1.527854338461538 ], [ 29.764344310344828, -1.527854338461538 ], [ 29.764344310344828, -1.527584861538462 ], [ 29.764074827586207, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12014, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.527584861538462 ], [ 29.764344310344828, -1.528123815384615 ], [ 29.76461379310345, -1.528123815384615 ], [ 29.76461379310345, -1.527584861538462 ], [ 29.764344310344828, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12015, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.527584861538462 ], [ 29.76515275862069, -1.527854338461538 ], [ 29.765422241379312, -1.527854338461538 ], [ 29.765422241379312, -1.527584861538462 ], [ 29.76515275862069, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12016, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.527584861538462 ], [ 29.765422241379312, -1.527854338461538 ], [ 29.765961206896552, -1.527854338461538 ], [ 29.765961206896552, -1.527584861538462 ], [ 29.765422241379312, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12017, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.527584861538462 ], [ 29.765961206896552, -1.527854338461538 ], [ 29.766230689655174, -1.527854338461538 ], [ 29.766230689655174, -1.527584861538462 ], [ 29.765961206896552, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12018, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.527584861538462 ], [ 29.766230689655174, -1.527854338461538 ], [ 29.766500172413792, -1.527854338461538 ], [ 29.766500172413792, -1.527584861538462 ], [ 29.766230689655174, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12019, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.527584861538462 ], [ 29.766500172413792, -1.527854338461538 ], [ 29.766769655172414, -1.527854338461538 ], [ 29.766769655172414, -1.527584861538462 ], [ 29.766500172413792, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12020, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.527584861538462 ], [ 29.766769655172414, -1.527854338461538 ], [ 29.767039137931036, -1.527854338461538 ], [ 29.767039137931036, -1.527584861538462 ], [ 29.766769655172414, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12021, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.527584861538462 ], [ 29.767039137931036, -1.527854338461538 ], [ 29.767308620689654, -1.527854338461538 ], [ 29.767308620689654, -1.527584861538462 ], [ 29.767039137931036, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12022, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.527584861538462 ], [ 29.767308620689654, -1.527854338461538 ], [ 29.767578103448276, -1.527854338461538 ], [ 29.767578103448276, -1.527584861538462 ], [ 29.767308620689654, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12023, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.527584861538462 ], [ 29.767578103448276, -1.527854338461538 ], [ 29.767847586206898, -1.527854338461538 ], [ 29.767847586206898, -1.527584861538462 ], [ 29.767578103448276, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12024, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.527584861538462 ], [ 29.767847586206898, -1.527854338461538 ], [ 29.768117068965516, -1.527854338461538 ], [ 29.768117068965516, -1.527584861538462 ], [ 29.767847586206898, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12025, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.527584861538462 ], [ 29.768117068965516, -1.527854338461538 ], [ 29.768386551724138, -1.527854338461538 ], [ 29.768386551724138, -1.527584861538462 ], [ 29.768117068965516, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12026, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.527584861538462 ], [ 29.768386551724138, -1.527854338461538 ], [ 29.76865603448276, -1.527854338461538 ], [ 29.76865603448276, -1.527584861538462 ], [ 29.768386551724138, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12027, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.527584861538462 ], [ 29.76865603448276, -1.527854338461538 ], [ 29.768925517241382, -1.527854338461538 ], [ 29.768925517241382, -1.527584861538462 ], [ 29.76865603448276, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12028, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.527584861538462 ], [ 29.768925517241382, -1.527854338461538 ], [ 29.769195, -1.527854338461538 ], [ 29.769195, -1.527584861538462 ], [ 29.768925517241382, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12029, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.527584861538462 ], [ 29.769195, -1.527854338461538 ], [ 29.769464482758622, -1.527854338461538 ], [ 29.769464482758622, -1.527584861538462 ], [ 29.769195, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12030, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.527584861538462 ], [ 29.769464482758622, -1.527854338461538 ], [ 29.769733965517243, -1.527854338461538 ], [ 29.769733965517243, -1.527584861538462 ], [ 29.769464482758622, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12031, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.527584861538462 ], [ 29.769733965517243, -1.527854338461538 ], [ 29.770003448275862, -1.527854338461538 ], [ 29.770003448275862, -1.527584861538462 ], [ 29.769733965517243, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12032, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.527584861538462 ], [ 29.770003448275862, -1.527854338461538 ], [ 29.770272931034484, -1.527854338461538 ], [ 29.770272931034484, -1.527584861538462 ], [ 29.770003448275862, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12033, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.527584861538462 ], [ 29.770272931034484, -1.527854338461538 ], [ 29.770542413793105, -1.527854338461538 ], [ 29.770542413793105, -1.527584861538462 ], [ 29.770272931034484, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12034, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.527584861538462 ], [ 29.770542413793105, -1.527854338461538 ], [ 29.772159310344829, -1.527854338461538 ], [ 29.772159310344829, -1.527584861538462 ], [ 29.770542413793105, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12035, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.527584861538462 ], [ 29.772159310344829, -1.528123815384615 ], [ 29.772428793103447, -1.528123815384615 ], [ 29.772428793103447, -1.527584861538462 ], [ 29.772159310344829, -1.527584861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12036, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.527854338461538 ], [ 29.736857068965517, -1.528123815384615 ], [ 29.737126551724138, -1.528123815384615 ], [ 29.737126551724138, -1.527854338461538 ], [ 29.736857068965517, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12037, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.527854338461538 ], [ 29.737126551724138, -1.528123815384615 ], [ 29.73739603448276, -1.528123815384615 ], [ 29.73739603448276, -1.527854338461538 ], [ 29.737126551724138, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12038, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.527854338461538 ], [ 29.73739603448276, -1.528393292307692 ], [ 29.737665517241378, -1.528393292307692 ], [ 29.737665517241378, -1.527854338461538 ], [ 29.73739603448276, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12039, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.527854338461538 ], [ 29.737665517241378, -1.528393292307692 ], [ 29.737935, -1.528393292307692 ], [ 29.737935, -1.527854338461538 ], [ 29.737665517241378, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12040, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.527854338461538 ], [ 29.738204482758622, -1.528123815384615 ], [ 29.738743448275862, -1.528123815384615 ], [ 29.738743448275862, -1.527854338461538 ], [ 29.738204482758622, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12041, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.527854338461538 ], [ 29.738743448275862, -1.528123815384615 ], [ 29.739012931034484, -1.528123815384615 ], [ 29.739012931034484, -1.527854338461538 ], [ 29.738743448275862, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12042, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.527854338461538 ], [ 29.739012931034484, -1.528123815384615 ], [ 29.739282413793102, -1.528123815384615 ], [ 29.739282413793102, -1.527854338461538 ], [ 29.739012931034484, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12043, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.527854338461538 ], [ 29.739282413793102, -1.528123815384615 ], [ 29.739551896551724, -1.528123815384615 ], [ 29.739551896551724, -1.527854338461538 ], [ 29.739282413793102, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12044, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.527854338461538 ], [ 29.760032586206897, -1.528662769230769 ], [ 29.760302068965519, -1.528662769230769 ], [ 29.760302068965519, -1.527854338461538 ], [ 29.760032586206897, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12045, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.527854338461538 ], [ 29.760302068965519, -1.528123815384615 ], [ 29.760571551724137, -1.528123815384615 ], [ 29.760571551724137, -1.527854338461538 ], [ 29.760302068965519, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12046, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.527854338461538 ], [ 29.760571551724137, -1.528123815384615 ], [ 29.760841034482759, -1.528123815384615 ], [ 29.760841034482759, -1.527854338461538 ], [ 29.760571551724137, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12047, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.527854338461538 ], [ 29.760841034482759, -1.528123815384615 ], [ 29.761110517241381, -1.528123815384615 ], [ 29.761110517241381, -1.527854338461538 ], [ 29.760841034482759, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12048, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.527854338461538 ], [ 29.761110517241381, -1.528123815384615 ], [ 29.76138, -1.528123815384615 ], [ 29.76138, -1.527854338461538 ], [ 29.761110517241381, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12049, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.527854338461538 ], [ 29.76138, -1.528393292307692 ], [ 29.761649482758621, -1.528393292307692 ], [ 29.761649482758621, -1.527854338461538 ], [ 29.76138, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12050, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.527854338461538 ], [ 29.761649482758621, -1.528123815384615 ], [ 29.761918965517243, -1.528123815384615 ], [ 29.761918965517243, -1.527854338461538 ], [ 29.761649482758621, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12051, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.527854338461538 ], [ 29.761918965517243, -1.528123815384615 ], [ 29.762188448275861, -1.528123815384615 ], [ 29.762188448275861, -1.527854338461538 ], [ 29.761918965517243, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12052, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.527854338461538 ], [ 29.762188448275861, -1.528123815384615 ], [ 29.762457931034483, -1.528123815384615 ], [ 29.762457931034483, -1.527854338461538 ], [ 29.762188448275861, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12053, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.527854338461538 ], [ 29.762457931034483, -1.528123815384615 ], [ 29.762727413793105, -1.528123815384615 ], [ 29.762727413793105, -1.527854338461538 ], [ 29.762457931034483, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12054, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.527854338461538 ], [ 29.762727413793105, -1.528123815384615 ], [ 29.762996896551723, -1.528123815384615 ], [ 29.762996896551723, -1.527854338461538 ], [ 29.762727413793105, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12055, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.527854338461538 ], [ 29.762996896551723, -1.528123815384615 ], [ 29.763266379310345, -1.528123815384615 ], [ 29.763266379310345, -1.527854338461538 ], [ 29.762996896551723, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12056, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.527854338461538 ], [ 29.764074827586207, -1.528393292307692 ], [ 29.764344310344828, -1.528393292307692 ], [ 29.764344310344828, -1.527854338461538 ], [ 29.764074827586207, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12057, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.527854338461538 ], [ 29.76515275862069, -1.528123815384615 ], [ 29.765961206896552, -1.528123815384615 ], [ 29.765961206896552, -1.527854338461538 ], [ 29.76515275862069, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12058, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.527854338461538 ], [ 29.765961206896552, -1.528123815384615 ], [ 29.766230689655174, -1.528123815384615 ], [ 29.766230689655174, -1.527854338461538 ], [ 29.765961206896552, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12059, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.527854338461538 ], [ 29.766230689655174, -1.528123815384615 ], [ 29.766500172413792, -1.528123815384615 ], [ 29.766500172413792, -1.527854338461538 ], [ 29.766230689655174, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12060, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.527854338461538 ], [ 29.766500172413792, -1.528123815384615 ], [ 29.766769655172414, -1.528123815384615 ], [ 29.766769655172414, -1.527854338461538 ], [ 29.766500172413792, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12061, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.527854338461538 ], [ 29.766769655172414, -1.528123815384615 ], [ 29.767039137931036, -1.528123815384615 ], [ 29.767039137931036, -1.527854338461538 ], [ 29.766769655172414, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12062, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.527854338461538 ], [ 29.767039137931036, -1.528123815384615 ], [ 29.767308620689654, -1.528123815384615 ], [ 29.767308620689654, -1.527854338461538 ], [ 29.767039137931036, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12063, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.527854338461538 ], [ 29.767308620689654, -1.528123815384615 ], [ 29.767578103448276, -1.528123815384615 ], [ 29.767578103448276, -1.527854338461538 ], [ 29.767308620689654, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12064, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.527854338461538 ], [ 29.767578103448276, -1.528123815384615 ], [ 29.767847586206898, -1.528123815384615 ], [ 29.767847586206898, -1.527854338461538 ], [ 29.767578103448276, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12065, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.527854338461538 ], [ 29.767847586206898, -1.528123815384615 ], [ 29.768117068965516, -1.528123815384615 ], [ 29.768117068965516, -1.527854338461538 ], [ 29.767847586206898, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12066, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.527854338461538 ], [ 29.768117068965516, -1.528123815384615 ], [ 29.768386551724138, -1.528123815384615 ], [ 29.768386551724138, -1.527854338461538 ], [ 29.768117068965516, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12067, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.527854338461538 ], [ 29.768386551724138, -1.528123815384615 ], [ 29.76865603448276, -1.528123815384615 ], [ 29.76865603448276, -1.527854338461538 ], [ 29.768386551724138, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12068, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.527854338461538 ], [ 29.76865603448276, -1.528123815384615 ], [ 29.768925517241382, -1.528123815384615 ], [ 29.768925517241382, -1.527854338461538 ], [ 29.76865603448276, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12069, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.527854338461538 ], [ 29.768925517241382, -1.528123815384615 ], [ 29.769195, -1.528123815384615 ], [ 29.769195, -1.527854338461538 ], [ 29.768925517241382, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12070, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.527854338461538 ], [ 29.769195, -1.528123815384615 ], [ 29.769464482758622, -1.528123815384615 ], [ 29.769464482758622, -1.527854338461538 ], [ 29.769195, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12071, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.527854338461538 ], [ 29.769464482758622, -1.528123815384615 ], [ 29.769733965517243, -1.528123815384615 ], [ 29.769733965517243, -1.527854338461538 ], [ 29.769464482758622, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12072, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.527854338461538 ], [ 29.769733965517243, -1.528123815384615 ], [ 29.770003448275862, -1.528123815384615 ], [ 29.770003448275862, -1.527854338461538 ], [ 29.769733965517243, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12073, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.527854338461538 ], [ 29.770003448275862, -1.528123815384615 ], [ 29.770272931034484, -1.528123815384615 ], [ 29.770272931034484, -1.527854338461538 ], [ 29.770003448275862, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12074, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.527854338461538 ], [ 29.770272931034484, -1.528123815384615 ], [ 29.770811896551724, -1.528123815384615 ], [ 29.770811896551724, -1.527854338461538 ], [ 29.770272931034484, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12075, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.527854338461538 ], [ 29.770811896551724, -1.528123815384615 ], [ 29.771889827586207, -1.528123815384615 ], [ 29.771889827586207, -1.527854338461538 ], [ 29.770811896551724, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12076, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.527854338461538 ], [ 29.771889827586207, -1.528123815384615 ], [ 29.772159310344829, -1.528123815384615 ], [ 29.772159310344829, -1.527854338461538 ], [ 29.771889827586207, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12077, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772967758620691, -1.527854338461538 ], [ 29.772967758620691, -1.528123815384615 ], [ 29.773237241379309, -1.528123815384615 ], [ 29.773237241379309, -1.527854338461538 ], [ 29.772967758620691, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12078, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.528123815384615 ], [ 29.737126551724138, -1.528662769230769 ], [ 29.73739603448276, -1.528662769230769 ], [ 29.73739603448276, -1.528123815384615 ], [ 29.737126551724138, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12079, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.528123815384615 ], [ 29.737935, -1.528393292307692 ], [ 29.738204482758622, -1.528393292307692 ], [ 29.738204482758622, -1.528123815384615 ], [ 29.737935, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12080, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.528123815384615 ], [ 29.738204482758622, -1.528662769230769 ], [ 29.738743448275862, -1.528662769230769 ], [ 29.738743448275862, -1.528123815384615 ], [ 29.738204482758622, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12081, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.528123815384615 ], [ 29.738743448275862, -1.528393292307692 ], [ 29.739012931034484, -1.528393292307692 ], [ 29.739012931034484, -1.528123815384615 ], [ 29.738743448275862, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12082, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.528123815384615 ], [ 29.739012931034484, -1.528393292307692 ], [ 29.739282413793102, -1.528393292307692 ], [ 29.739282413793102, -1.528123815384615 ], [ 29.739012931034484, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12083, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.528123815384615 ], [ 29.739282413793102, -1.528393292307692 ], [ 29.739551896551724, -1.528393292307692 ], [ 29.739551896551724, -1.528123815384615 ], [ 29.739282413793102, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12084, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.528123815384615 ], [ 29.739551896551724, -1.528393292307692 ], [ 29.739821379310346, -1.528393292307692 ], [ 29.739821379310346, -1.528123815384615 ], [ 29.739551896551724, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12085, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.528123815384615 ], [ 29.759493620689657, -1.528393292307692 ], [ 29.759763103448275, -1.528393292307692 ], [ 29.759763103448275, -1.528123815384615 ], [ 29.759493620689657, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12086, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.528123815384615 ], [ 29.760302068965519, -1.528393292307692 ], [ 29.760571551724137, -1.528393292307692 ], [ 29.760571551724137, -1.528123815384615 ], [ 29.760302068965519, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12087, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.528123815384615 ], [ 29.760571551724137, -1.528393292307692 ], [ 29.760841034482759, -1.528393292307692 ], [ 29.760841034482759, -1.528123815384615 ], [ 29.760571551724137, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12088, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.528123815384615 ], [ 29.760841034482759, -1.528393292307692 ], [ 29.761110517241381, -1.528393292307692 ], [ 29.761110517241381, -1.528123815384615 ], [ 29.760841034482759, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12089, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.528123815384615 ], [ 29.761110517241381, -1.528393292307692 ], [ 29.76138, -1.528393292307692 ], [ 29.76138, -1.528123815384615 ], [ 29.761110517241381, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12090, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.528123815384615 ], [ 29.761649482758621, -1.528393292307692 ], [ 29.761918965517243, -1.528393292307692 ], [ 29.761918965517243, -1.528123815384615 ], [ 29.761649482758621, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12091, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.528123815384615 ], [ 29.761918965517243, -1.528393292307692 ], [ 29.762188448275861, -1.528393292307692 ], [ 29.762188448275861, -1.528123815384615 ], [ 29.761918965517243, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12092, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.528123815384615 ], [ 29.762188448275861, -1.528393292307692 ], [ 29.762457931034483, -1.528393292307692 ], [ 29.762457931034483, -1.528123815384615 ], [ 29.762188448275861, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12093, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.528123815384615 ], [ 29.762457931034483, -1.528393292307692 ], [ 29.762727413793105, -1.528393292307692 ], [ 29.762727413793105, -1.528123815384615 ], [ 29.762457931034483, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12094, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.528123815384615 ], [ 29.762727413793105, -1.528393292307692 ], [ 29.762996896551723, -1.528393292307692 ], [ 29.762996896551723, -1.528123815384615 ], [ 29.762727413793105, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12095, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.528123815384615 ], [ 29.762996896551723, -1.528393292307692 ], [ 29.763266379310345, -1.528393292307692 ], [ 29.763266379310345, -1.528123815384615 ], [ 29.762996896551723, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12096, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.528123815384615 ], [ 29.763266379310345, -1.528393292307692 ], [ 29.763535862068967, -1.528393292307692 ], [ 29.763535862068967, -1.528123815384615 ], [ 29.763266379310345, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12097, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.528123815384615 ], [ 29.763535862068967, -1.528393292307692 ], [ 29.763805344827588, -1.528393292307692 ], [ 29.763805344827588, -1.528123815384615 ], [ 29.763535862068967, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12098, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.528123815384615 ], [ 29.763805344827588, -1.528662769230769 ], [ 29.764074827586207, -1.528662769230769 ], [ 29.764074827586207, -1.528123815384615 ], [ 29.763805344827588, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12099, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.528123815384615 ], [ 29.764344310344828, -1.528393292307692 ], [ 29.76461379310345, -1.528393292307692 ], [ 29.76461379310345, -1.528123815384615 ], [ 29.764344310344828, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12100, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.528123815384615 ], [ 29.76461379310345, -1.528393292307692 ], [ 29.764883275862069, -1.528393292307692 ], [ 29.764883275862069, -1.528123815384615 ], [ 29.76461379310345, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12101, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.528123815384615 ], [ 29.764883275862069, -1.528662769230769 ], [ 29.76515275862069, -1.528662769230769 ], [ 29.76515275862069, -1.528123815384615 ], [ 29.764883275862069, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12102, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.528123815384615 ], [ 29.76515275862069, -1.528662769230769 ], [ 29.765422241379312, -1.528662769230769 ], [ 29.765422241379312, -1.528123815384615 ], [ 29.76515275862069, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12103, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.528123815384615 ], [ 29.765961206896552, -1.528393292307692 ], [ 29.766230689655174, -1.528393292307692 ], [ 29.766230689655174, -1.528123815384615 ], [ 29.765961206896552, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12104, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.528123815384615 ], [ 29.766230689655174, -1.528393292307692 ], [ 29.766500172413792, -1.528393292307692 ], [ 29.766500172413792, -1.528123815384615 ], [ 29.766230689655174, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12105, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.528123815384615 ], [ 29.766500172413792, -1.528393292307692 ], [ 29.766769655172414, -1.528393292307692 ], [ 29.766769655172414, -1.528123815384615 ], [ 29.766500172413792, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12106, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.528123815384615 ], [ 29.766769655172414, -1.528393292307692 ], [ 29.767039137931036, -1.528393292307692 ], [ 29.767039137931036, -1.528123815384615 ], [ 29.766769655172414, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12107, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.528123815384615 ], [ 29.767039137931036, -1.528393292307692 ], [ 29.767308620689654, -1.528393292307692 ], [ 29.767308620689654, -1.528123815384615 ], [ 29.767039137931036, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12108, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.528123815384615 ], [ 29.767308620689654, -1.528393292307692 ], [ 29.767578103448276, -1.528393292307692 ], [ 29.767578103448276, -1.528123815384615 ], [ 29.767308620689654, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12109, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.528123815384615 ], [ 29.767578103448276, -1.528393292307692 ], [ 29.767847586206898, -1.528393292307692 ], [ 29.767847586206898, -1.528123815384615 ], [ 29.767578103448276, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12110, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.528123815384615 ], [ 29.767847586206898, -1.528393292307692 ], [ 29.768117068965516, -1.528393292307692 ], [ 29.768117068965516, -1.528123815384615 ], [ 29.767847586206898, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12111, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.528123815384615 ], [ 29.768117068965516, -1.528393292307692 ], [ 29.768386551724138, -1.528393292307692 ], [ 29.768386551724138, -1.528123815384615 ], [ 29.768117068965516, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12112, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.528123815384615 ], [ 29.768386551724138, -1.528393292307692 ], [ 29.76865603448276, -1.528393292307692 ], [ 29.76865603448276, -1.528123815384615 ], [ 29.768386551724138, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12113, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.528123815384615 ], [ 29.76865603448276, -1.528393292307692 ], [ 29.768925517241382, -1.528393292307692 ], [ 29.768925517241382, -1.528123815384615 ], [ 29.76865603448276, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12114, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.528123815384615 ], [ 29.768925517241382, -1.528393292307692 ], [ 29.769195, -1.528393292307692 ], [ 29.769195, -1.528123815384615 ], [ 29.768925517241382, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12115, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.528123815384615 ], [ 29.769195, -1.528393292307692 ], [ 29.769464482758622, -1.528393292307692 ], [ 29.769464482758622, -1.528123815384615 ], [ 29.769195, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12116, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.528123815384615 ], [ 29.769464482758622, -1.528393292307692 ], [ 29.769733965517243, -1.528393292307692 ], [ 29.769733965517243, -1.528123815384615 ], [ 29.769464482758622, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12117, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.528123815384615 ], [ 29.769733965517243, -1.528393292307692 ], [ 29.770003448275862, -1.528393292307692 ], [ 29.770003448275862, -1.528123815384615 ], [ 29.769733965517243, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12118, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.528123815384615 ], [ 29.770003448275862, -1.528393292307692 ], [ 29.770272931034484, -1.528393292307692 ], [ 29.770272931034484, -1.528123815384615 ], [ 29.770003448275862, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12119, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.528123815384615 ], [ 29.770272931034484, -1.528393292307692 ], [ 29.770542413793105, -1.528393292307692 ], [ 29.770542413793105, -1.528123815384615 ], [ 29.770272931034484, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12120, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.528123815384615 ], [ 29.770542413793105, -1.528393292307692 ], [ 29.771081379310345, -1.528393292307692 ], [ 29.771081379310345, -1.528662769230769 ], [ 29.771350862068967, -1.528662769230769 ], [ 29.771350862068967, -1.528393292307692 ], [ 29.771620344827586, -1.528393292307692 ], [ 29.771620344827586, -1.528123815384615 ], [ 29.770542413793105, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12121, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.528123815384615 ], [ 29.771620344827586, -1.528393292307692 ], [ 29.771889827586207, -1.528393292307692 ], [ 29.771889827586207, -1.528123815384615 ], [ 29.771620344827586, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12122, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.528123815384615 ], [ 29.771889827586207, -1.528393292307692 ], [ 29.772159310344829, -1.528393292307692 ], [ 29.772159310344829, -1.528123815384615 ], [ 29.771889827586207, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12123, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.528123815384615 ], [ 29.772159310344829, -1.528393292307692 ], [ 29.772428793103447, -1.528393292307692 ], [ 29.772428793103447, -1.528123815384615 ], [ 29.772159310344829, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12124, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772428793103447, -1.528123815384615 ], [ 29.772428793103447, -1.528393292307692 ], [ 29.772698275862069, -1.528393292307692 ], [ 29.772698275862069, -1.528123815384615 ], [ 29.772428793103447, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12125, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772698275862069, -1.528123815384615 ], [ 29.772698275862069, -1.528393292307692 ], [ 29.772967758620691, -1.528393292307692 ], [ 29.772967758620691, -1.528123815384615 ], [ 29.772698275862069, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12126, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773237241379309, -1.527854338461538 ], [ 29.773506724137931, -1.527854338461538 ], [ 29.773506724137931, -1.528393292307692 ], [ 29.772967758620691, -1.528393292307692 ], [ 29.772967758620691, -1.528123815384615 ], [ 29.773237241379309, -1.528123815384615 ], [ 29.773237241379309, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12127, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.528393292307692 ], [ 29.73739603448276, -1.528662769230769 ], [ 29.737665517241378, -1.528662769230769 ], [ 29.737665517241378, -1.528393292307692 ], [ 29.73739603448276, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12128, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.528393292307692 ], [ 29.739551896551724, -1.528662769230769 ], [ 29.739821379310346, -1.528662769230769 ], [ 29.739821379310346, -1.528393292307692 ], [ 29.739551896551724, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12129, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.528393292307692 ], [ 29.739821379310346, -1.528662769230769 ], [ 29.740090862068964, -1.528662769230769 ], [ 29.740090862068964, -1.528393292307692 ], [ 29.739821379310346, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12130, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.528393292307692 ], [ 29.740090862068964, -1.528662769230769 ], [ 29.740360344827586, -1.528662769230769 ], [ 29.740360344827586, -1.528393292307692 ], [ 29.740090862068964, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12131, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.528393292307692 ], [ 29.740629827586208, -1.528662769230769 ], [ 29.74089931034483, -1.528662769230769 ], [ 29.74089931034483, -1.528393292307692 ], [ 29.740629827586208, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12132, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.527854338461538 ], [ 29.760032586206897, -1.527854338461538 ], [ 29.760032586206897, -1.528662769230769 ], [ 29.759493620689657, -1.528662769230769 ], [ 29.759493620689657, -1.528393292307692 ], [ 29.759763103448275, -1.528393292307692 ], [ 29.759763103448275, -1.527854338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12133, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.528393292307692 ], [ 29.760302068965519, -1.528662769230769 ], [ 29.760571551724137, -1.528662769230769 ], [ 29.760571551724137, -1.528393292307692 ], [ 29.760302068965519, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12134, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.528393292307692 ], [ 29.760571551724137, -1.528662769230769 ], [ 29.761110517241381, -1.528662769230769 ], [ 29.761110517241381, -1.528393292307692 ], [ 29.760571551724137, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12135, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.528393292307692 ], [ 29.761110517241381, -1.528662769230769 ], [ 29.76138, -1.528662769230769 ], [ 29.76138, -1.528393292307692 ], [ 29.761110517241381, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12136, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.528393292307692 ], [ 29.76138, -1.528662769230769 ], [ 29.761649482758621, -1.528662769230769 ], [ 29.761649482758621, -1.528393292307692 ], [ 29.76138, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12137, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.528393292307692 ], [ 29.761649482758621, -1.528662769230769 ], [ 29.761918965517243, -1.528662769230769 ], [ 29.761918965517243, -1.528393292307692 ], [ 29.761649482758621, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12138, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.528393292307692 ], [ 29.761918965517243, -1.528662769230769 ], [ 29.762188448275861, -1.528662769230769 ], [ 29.762188448275861, -1.528393292307692 ], [ 29.761918965517243, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12139, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.528393292307692 ], [ 29.762188448275861, -1.528662769230769 ], [ 29.762457931034483, -1.528662769230769 ], [ 29.762457931034483, -1.528393292307692 ], [ 29.762188448275861, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12140, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.528393292307692 ], [ 29.762457931034483, -1.528662769230769 ], [ 29.762727413793105, -1.528662769230769 ], [ 29.762727413793105, -1.528393292307692 ], [ 29.762457931034483, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12141, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.528393292307692 ], [ 29.762727413793105, -1.528662769230769 ], [ 29.762996896551723, -1.528662769230769 ], [ 29.762996896551723, -1.528393292307692 ], [ 29.762727413793105, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12142, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.528393292307692 ], [ 29.762996896551723, -1.528662769230769 ], [ 29.763266379310345, -1.528662769230769 ], [ 29.763266379310345, -1.528393292307692 ], [ 29.762996896551723, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12143, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.528393292307692 ], [ 29.763266379310345, -1.528662769230769 ], [ 29.763535862068967, -1.528662769230769 ], [ 29.763535862068967, -1.528393292307692 ], [ 29.763266379310345, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12144, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.528393292307692 ], [ 29.764074827586207, -1.528662769230769 ], [ 29.764344310344828, -1.528662769230769 ], [ 29.764344310344828, -1.528393292307692 ], [ 29.764074827586207, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12145, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.528393292307692 ], [ 29.765961206896552, -1.528662769230769 ], [ 29.766230689655174, -1.528662769230769 ], [ 29.766230689655174, -1.528393292307692 ], [ 29.765961206896552, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12146, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.528393292307692 ], [ 29.766230689655174, -1.528662769230769 ], [ 29.766500172413792, -1.528662769230769 ], [ 29.766500172413792, -1.528393292307692 ], [ 29.766230689655174, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12147, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.528393292307692 ], [ 29.766500172413792, -1.528662769230769 ], [ 29.766769655172414, -1.528662769230769 ], [ 29.766769655172414, -1.528393292307692 ], [ 29.766500172413792, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12148, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.528393292307692 ], [ 29.766769655172414, -1.528662769230769 ], [ 29.767039137931036, -1.528662769230769 ], [ 29.767039137931036, -1.528393292307692 ], [ 29.766769655172414, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12149, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.528393292307692 ], [ 29.767039137931036, -1.528662769230769 ], [ 29.767308620689654, -1.528662769230769 ], [ 29.767308620689654, -1.528393292307692 ], [ 29.767039137931036, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12150, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.528393292307692 ], [ 29.767308620689654, -1.528662769230769 ], [ 29.767578103448276, -1.528662769230769 ], [ 29.767578103448276, -1.528393292307692 ], [ 29.767308620689654, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12151, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.528393292307692 ], [ 29.767578103448276, -1.528662769230769 ], [ 29.767847586206898, -1.528662769230769 ], [ 29.767847586206898, -1.528393292307692 ], [ 29.767578103448276, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12152, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.528393292307692 ], [ 29.767847586206898, -1.528662769230769 ], [ 29.768117068965516, -1.528662769230769 ], [ 29.768117068965516, -1.528393292307692 ], [ 29.767847586206898, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12153, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.528393292307692 ], [ 29.768117068965516, -1.528662769230769 ], [ 29.768386551724138, -1.528662769230769 ], [ 29.768386551724138, -1.528393292307692 ], [ 29.768117068965516, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12154, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.528393292307692 ], [ 29.768386551724138, -1.528662769230769 ], [ 29.76865603448276, -1.528662769230769 ], [ 29.76865603448276, -1.528393292307692 ], [ 29.768386551724138, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12155, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.528393292307692 ], [ 29.76865603448276, -1.528662769230769 ], [ 29.768925517241382, -1.528662769230769 ], [ 29.768925517241382, -1.528393292307692 ], [ 29.76865603448276, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12156, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.528393292307692 ], [ 29.768925517241382, -1.528662769230769 ], [ 29.769195, -1.528662769230769 ], [ 29.769195, -1.528393292307692 ], [ 29.768925517241382, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12157, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.528393292307692 ], [ 29.769195, -1.528662769230769 ], [ 29.769464482758622, -1.528662769230769 ], [ 29.769464482758622, -1.528393292307692 ], [ 29.769195, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12158, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.528393292307692 ], [ 29.769464482758622, -1.528662769230769 ], [ 29.769733965517243, -1.528662769230769 ], [ 29.769733965517243, -1.528393292307692 ], [ 29.769464482758622, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12159, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.528393292307692 ], [ 29.769733965517243, -1.528662769230769 ], [ 29.770003448275862, -1.528662769230769 ], [ 29.770003448275862, -1.528393292307692 ], [ 29.769733965517243, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12160, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.528393292307692 ], [ 29.770542413793105, -1.528662769230769 ], [ 29.770811896551724, -1.528662769230769 ], [ 29.770811896551724, -1.528393292307692 ], [ 29.770542413793105, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12161, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.528393292307692 ], [ 29.770811896551724, -1.528662769230769 ], [ 29.771081379310345, -1.528662769230769 ], [ 29.771081379310345, -1.528393292307692 ], [ 29.770811896551724, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12162, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.528393292307692 ], [ 29.771350862068967, -1.528662769230769 ], [ 29.771620344827586, -1.528662769230769 ], [ 29.771620344827586, -1.528393292307692 ], [ 29.771350862068967, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12163, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.528393292307692 ], [ 29.771620344827586, -1.528662769230769 ], [ 29.771889827586207, -1.528662769230769 ], [ 29.771889827586207, -1.528393292307692 ], [ 29.771620344827586, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12164, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.528393292307692 ], [ 29.771889827586207, -1.528662769230769 ], [ 29.772159310344829, -1.528662769230769 ], [ 29.772159310344829, -1.528393292307692 ], [ 29.771889827586207, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12165, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.528393292307692 ], [ 29.772159310344829, -1.528662769230769 ], [ 29.772428793103447, -1.528662769230769 ], [ 29.772428793103447, -1.528393292307692 ], [ 29.772159310344829, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12166, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772428793103447, -1.528393292307692 ], [ 29.772428793103447, -1.528662769230769 ], [ 29.772698275862069, -1.528662769230769 ], [ 29.772698275862069, -1.528393292307692 ], [ 29.772428793103447, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12167, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772698275862069, -1.528393292307692 ], [ 29.772698275862069, -1.528662769230769 ], [ 29.772967758620691, -1.528662769230769 ], [ 29.772967758620691, -1.528393292307692 ], [ 29.772698275862069, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12168, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74143827586207, -1.527315384615385 ], [ 29.74143827586207, -1.528932246153846 ], [ 29.741707758620691, -1.528932246153846 ], [ 29.741707758620691, -1.527315384615385 ], [ 29.74143827586207, -1.527315384615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12169, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.528123815384615 ], [ 29.759224137931035, -1.528932246153846 ], [ 29.759763103448275, -1.528932246153846 ], [ 29.759763103448275, -1.528662769230769 ], [ 29.759493620689657, -1.528662769230769 ], [ 29.759493620689657, -1.528123815384615 ], [ 29.759224137931035, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12170, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.528123815384615 ], [ 29.765422241379312, -1.528932246153846 ], [ 29.766230689655174, -1.528932246153846 ], [ 29.766230689655174, -1.528662769230769 ], [ 29.765961206896552, -1.528662769230769 ], [ 29.765961206896552, -1.528123815384615 ], [ 29.765422241379312, -1.528123815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12171, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.528393292307692 ], [ 29.737665517241378, -1.529201723076923 ], [ 29.737935, -1.529201723076923 ], [ 29.737935, -1.528393292307692 ], [ 29.737665517241378, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12172, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.528393292307692 ], [ 29.737935, -1.529201723076923 ], [ 29.738204482758622, -1.529201723076923 ], [ 29.738204482758622, -1.528393292307692 ], [ 29.737935, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12173, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.528393292307692 ], [ 29.738743448275862, -1.529201723076923 ], [ 29.739012931034484, -1.529201723076923 ], [ 29.739012931034484, -1.528393292307692 ], [ 29.738743448275862, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12174, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.528393292307692 ], [ 29.739012931034484, -1.529201723076923 ], [ 29.739282413793102, -1.529201723076923 ], [ 29.739282413793102, -1.528393292307692 ], [ 29.739012931034484, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12175, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.528393292307692 ], [ 29.740360344827586, -1.528932246153846 ], [ 29.740629827586208, -1.528932246153846 ], [ 29.740629827586208, -1.528393292307692 ], [ 29.740360344827586, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12176, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.528393292307692 ], [ 29.763535862068967, -1.528932246153846 ], [ 29.763805344827588, -1.528932246153846 ], [ 29.763805344827588, -1.528393292307692 ], [ 29.763535862068967, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12177, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.528393292307692 ], [ 29.764344310344828, -1.528932246153846 ], [ 29.76461379310345, -1.528932246153846 ], [ 29.76461379310345, -1.528393292307692 ], [ 29.764344310344828, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12178, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.528393292307692 ], [ 29.76461379310345, -1.528932246153846 ], [ 29.764883275862069, -1.528932246153846 ], [ 29.764883275862069, -1.528393292307692 ], [ 29.76461379310345, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12179, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.528393292307692 ], [ 29.770003448275862, -1.528932246153846 ], [ 29.770272931034484, -1.528932246153846 ], [ 29.770272931034484, -1.528393292307692 ], [ 29.770003448275862, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12180, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.528393292307692 ], [ 29.770272931034484, -1.528932246153846 ], [ 29.770542413793105, -1.528932246153846 ], [ 29.770542413793105, -1.528393292307692 ], [ 29.770272931034484, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12181, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.528662769230769 ], [ 29.73739603448276, -1.528932246153846 ], [ 29.737665517241378, -1.528932246153846 ], [ 29.737665517241378, -1.528662769230769 ], [ 29.73739603448276, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12182, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.528662769230769 ], [ 29.738204482758622, -1.528932246153846 ], [ 29.738743448275862, -1.528932246153846 ], [ 29.738743448275862, -1.528662769230769 ], [ 29.738204482758622, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12183, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.528662769230769 ], [ 29.741168793103448, -1.528932246153846 ], [ 29.74143827586207, -1.528932246153846 ], [ 29.74143827586207, -1.528662769230769 ], [ 29.741168793103448, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12184, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.528662769230769 ], [ 29.759763103448275, -1.528932246153846 ], [ 29.760032586206897, -1.528932246153846 ], [ 29.760032586206897, -1.528662769230769 ], [ 29.759763103448275, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12185, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.528662769230769 ], [ 29.760032586206897, -1.528932246153846 ], [ 29.760302068965519, -1.528932246153846 ], [ 29.760302068965519, -1.528662769230769 ], [ 29.760032586206897, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12186, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.528662769230769 ], [ 29.760302068965519, -1.528932246153846 ], [ 29.760841034482759, -1.528932246153846 ], [ 29.760841034482759, -1.528662769230769 ], [ 29.760302068965519, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12187, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.528662769230769 ], [ 29.760841034482759, -1.528932246153846 ], [ 29.761110517241381, -1.528932246153846 ], [ 29.761110517241381, -1.528662769230769 ], [ 29.760841034482759, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12188, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.528662769230769 ], [ 29.761110517241381, -1.528932246153846 ], [ 29.76138, -1.528932246153846 ], [ 29.76138, -1.528662769230769 ], [ 29.761110517241381, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12189, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.528662769230769 ], [ 29.76138, -1.528932246153846 ], [ 29.761649482758621, -1.528932246153846 ], [ 29.761649482758621, -1.528662769230769 ], [ 29.76138, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12190, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.528662769230769 ], [ 29.761649482758621, -1.528932246153846 ], [ 29.761918965517243, -1.528932246153846 ], [ 29.761918965517243, -1.528662769230769 ], [ 29.761649482758621, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12191, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.528662769230769 ], [ 29.761918965517243, -1.528932246153846 ], [ 29.762188448275861, -1.528932246153846 ], [ 29.762188448275861, -1.528662769230769 ], [ 29.761918965517243, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12192, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.528662769230769 ], [ 29.762188448275861, -1.528932246153846 ], [ 29.762457931034483, -1.528932246153846 ], [ 29.762457931034483, -1.528662769230769 ], [ 29.762188448275861, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12193, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.528662769230769 ], [ 29.762457931034483, -1.528932246153846 ], [ 29.762727413793105, -1.528932246153846 ], [ 29.762727413793105, -1.528662769230769 ], [ 29.762457931034483, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12194, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.528662769230769 ], [ 29.762727413793105, -1.528932246153846 ], [ 29.762996896551723, -1.528932246153846 ], [ 29.762996896551723, -1.528662769230769 ], [ 29.762727413793105, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12195, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.528662769230769 ], [ 29.762996896551723, -1.529201723076923 ], [ 29.763266379310345, -1.529201723076923 ], [ 29.763266379310345, -1.528662769230769 ], [ 29.762996896551723, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12196, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.528662769230769 ], [ 29.763805344827588, -1.529201723076923 ], [ 29.764074827586207, -1.529201723076923 ], [ 29.764074827586207, -1.528662769230769 ], [ 29.763805344827588, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12197, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.528662769230769 ], [ 29.764883275862069, -1.5294712 ], [ 29.76515275862069, -1.5294712 ], [ 29.76515275862069, -1.528662769230769 ], [ 29.764883275862069, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12198, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.528662769230769 ], [ 29.766230689655174, -1.528932246153846 ], [ 29.766500172413792, -1.528932246153846 ], [ 29.766500172413792, -1.528662769230769 ], [ 29.766230689655174, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12199, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.528662769230769 ], [ 29.766500172413792, -1.528932246153846 ], [ 29.766769655172414, -1.528932246153846 ], [ 29.766769655172414, -1.528662769230769 ], [ 29.766500172413792, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12200, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.528662769230769 ], [ 29.766769655172414, -1.528932246153846 ], [ 29.767039137931036, -1.528932246153846 ], [ 29.767039137931036, -1.528662769230769 ], [ 29.766769655172414, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12201, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.528662769230769 ], [ 29.767039137931036, -1.528932246153846 ], [ 29.767308620689654, -1.528932246153846 ], [ 29.767308620689654, -1.528662769230769 ], [ 29.767039137931036, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12202, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.528662769230769 ], [ 29.767308620689654, -1.528932246153846 ], [ 29.767578103448276, -1.528932246153846 ], [ 29.767578103448276, -1.528662769230769 ], [ 29.767308620689654, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12203, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.528662769230769 ], [ 29.767578103448276, -1.528932246153846 ], [ 29.767847586206898, -1.528932246153846 ], [ 29.767847586206898, -1.528662769230769 ], [ 29.767578103448276, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12204, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.528662769230769 ], [ 29.767847586206898, -1.528932246153846 ], [ 29.768117068965516, -1.528932246153846 ], [ 29.768117068965516, -1.528662769230769 ], [ 29.767847586206898, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12205, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.528662769230769 ], [ 29.768117068965516, -1.528932246153846 ], [ 29.768386551724138, -1.528932246153846 ], [ 29.768386551724138, -1.528662769230769 ], [ 29.768117068965516, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12206, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.528662769230769 ], [ 29.768386551724138, -1.528932246153846 ], [ 29.76865603448276, -1.528932246153846 ], [ 29.76865603448276, -1.528662769230769 ], [ 29.768386551724138, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12207, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.528662769230769 ], [ 29.76865603448276, -1.529201723076923 ], [ 29.768925517241382, -1.529201723076923 ], [ 29.768925517241382, -1.528662769230769 ], [ 29.76865603448276, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12208, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.528662769230769 ], [ 29.768925517241382, -1.529201723076923 ], [ 29.769195, -1.529201723076923 ], [ 29.769195, -1.528662769230769 ], [ 29.768925517241382, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12209, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.528662769230769 ], [ 29.769195, -1.529201723076923 ], [ 29.769464482758622, -1.529201723076923 ], [ 29.769464482758622, -1.528662769230769 ], [ 29.769195, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12210, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.528662769230769 ], [ 29.769464482758622, -1.528932246153846 ], [ 29.769733965517243, -1.528932246153846 ], [ 29.769733965517243, -1.528662769230769 ], [ 29.769464482758622, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12211, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.528662769230769 ], [ 29.769733965517243, -1.528932246153846 ], [ 29.770003448275862, -1.528932246153846 ], [ 29.770003448275862, -1.528662769230769 ], [ 29.769733965517243, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12212, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.528662769230769 ], [ 29.770542413793105, -1.528932246153846 ], [ 29.770811896551724, -1.528932246153846 ], [ 29.770811896551724, -1.528662769230769 ], [ 29.770542413793105, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12213, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.528662769230769 ], [ 29.770811896551724, -1.528932246153846 ], [ 29.771081379310345, -1.528932246153846 ], [ 29.771081379310345, -1.528662769230769 ], [ 29.770811896551724, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12214, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.528662769230769 ], [ 29.771081379310345, -1.528932246153846 ], [ 29.771350862068967, -1.528932246153846 ], [ 29.771350862068967, -1.528662769230769 ], [ 29.771081379310345, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12215, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.528662769230769 ], [ 29.771350862068967, -1.528932246153846 ], [ 29.771620344827586, -1.528932246153846 ], [ 29.771620344827586, -1.528662769230769 ], [ 29.771350862068967, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12216, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.528662769230769 ], [ 29.771620344827586, -1.528932246153846 ], [ 29.771889827586207, -1.528932246153846 ], [ 29.771889827586207, -1.528662769230769 ], [ 29.771620344827586, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12217, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.528662769230769 ], [ 29.771889827586207, -1.528932246153846 ], [ 29.772159310344829, -1.528932246153846 ], [ 29.772159310344829, -1.528662769230769 ], [ 29.771889827586207, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12218, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.528662769230769 ], [ 29.772159310344829, -1.528932246153846 ], [ 29.772428793103447, -1.528932246153846 ], [ 29.772428793103447, -1.528662769230769 ], [ 29.772159310344829, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12219, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772428793103447, -1.528662769230769 ], [ 29.772428793103447, -1.528932246153846 ], [ 29.772698275862069, -1.528932246153846 ], [ 29.772698275862069, -1.528662769230769 ], [ 29.772428793103447, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12220, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.528932246153846 ], [ 29.737126551724138, -1.5294712 ], [ 29.73739603448276, -1.5294712 ], [ 29.73739603448276, -1.528932246153846 ], [ 29.737126551724138, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12221, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.528932246153846 ], [ 29.73739603448276, -1.529201723076923 ], [ 29.737665517241378, -1.529201723076923 ], [ 29.737665517241378, -1.528932246153846 ], [ 29.73739603448276, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12222, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.528932246153846 ], [ 29.738204482758622, -1.5294712 ], [ 29.738743448275862, -1.5294712 ], [ 29.738743448275862, -1.528932246153846 ], [ 29.738204482758622, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12223, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.528932246153846 ], [ 29.759763103448275, -1.529201723076923 ], [ 29.760032586206897, -1.529201723076923 ], [ 29.760032586206897, -1.528932246153846 ], [ 29.759763103448275, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12224, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.528932246153846 ], [ 29.760032586206897, -1.529201723076923 ], [ 29.760302068965519, -1.529201723076923 ], [ 29.760302068965519, -1.528932246153846 ], [ 29.760032586206897, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12225, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.528932246153846 ], [ 29.760302068965519, -1.529201723076923 ], [ 29.760841034482759, -1.529201723076923 ], [ 29.760841034482759, -1.528932246153846 ], [ 29.760302068965519, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12226, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.528932246153846 ], [ 29.760841034482759, -1.529201723076923 ], [ 29.761110517241381, -1.529201723076923 ], [ 29.761110517241381, -1.528932246153846 ], [ 29.760841034482759, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12227, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.528932246153846 ], [ 29.761110517241381, -1.529201723076923 ], [ 29.76138, -1.529201723076923 ], [ 29.76138, -1.528932246153846 ], [ 29.761110517241381, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12228, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.528932246153846 ], [ 29.76138, -1.5294712 ], [ 29.761649482758621, -1.5294712 ], [ 29.761649482758621, -1.528932246153846 ], [ 29.76138, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12229, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.528932246153846 ], [ 29.761649482758621, -1.529201723076923 ], [ 29.761918965517243, -1.529201723076923 ], [ 29.761918965517243, -1.528932246153846 ], [ 29.761649482758621, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12230, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.528932246153846 ], [ 29.761918965517243, -1.529201723076923 ], [ 29.762188448275861, -1.529201723076923 ], [ 29.762188448275861, -1.528932246153846 ], [ 29.761918965517243, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12231, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.528932246153846 ], [ 29.762188448275861, -1.529201723076923 ], [ 29.762457931034483, -1.529201723076923 ], [ 29.762457931034483, -1.528932246153846 ], [ 29.762188448275861, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12232, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.528932246153846 ], [ 29.762457931034483, -1.530818584615385 ], [ 29.762727413793105, -1.530818584615385 ], [ 29.762727413793105, -1.528932246153846 ], [ 29.762457931034483, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12233, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.528932246153846 ], [ 29.762727413793105, -1.530818584615385 ], [ 29.762996896551723, -1.530818584615385 ], [ 29.762996896551723, -1.528932246153846 ], [ 29.762727413793105, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12234, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.528932246153846 ], [ 29.763535862068967, -1.530818584615385 ], [ 29.763805344827588, -1.530818584615385 ], [ 29.763805344827588, -1.528932246153846 ], [ 29.763535862068967, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12235, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.528932246153846 ], [ 29.764344310344828, -1.530818584615385 ], [ 29.76461379310345, -1.530818584615385 ], [ 29.76461379310345, -1.528932246153846 ], [ 29.764344310344828, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12236, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.528932246153846 ], [ 29.76461379310345, -1.530818584615385 ], [ 29.764883275862069, -1.530818584615385 ], [ 29.764883275862069, -1.528932246153846 ], [ 29.76461379310345, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12237, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.528932246153846 ], [ 29.765422241379312, -1.530818584615385 ], [ 29.765961206896552, -1.530818584615385 ], [ 29.765961206896552, -1.528932246153846 ], [ 29.765422241379312, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12238, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.528932246153846 ], [ 29.765961206896552, -1.530818584615385 ], [ 29.766230689655174, -1.530818584615385 ], [ 29.766230689655174, -1.528932246153846 ], [ 29.765961206896552, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12239, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.528932246153846 ], [ 29.766230689655174, -1.529201723076923 ], [ 29.766500172413792, -1.529201723076923 ], [ 29.766500172413792, -1.528932246153846 ], [ 29.766230689655174, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12240, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.528932246153846 ], [ 29.766769655172414, -1.529201723076923 ], [ 29.767039137931036, -1.529201723076923 ], [ 29.767039137931036, -1.528932246153846 ], [ 29.766769655172414, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12241, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.528932246153846 ], [ 29.767039137931036, -1.529201723076923 ], [ 29.767308620689654, -1.529201723076923 ], [ 29.767308620689654, -1.528932246153846 ], [ 29.767039137931036, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12242, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.528932246153846 ], [ 29.767308620689654, -1.529201723076923 ], [ 29.767578103448276, -1.529201723076923 ], [ 29.767578103448276, -1.528932246153846 ], [ 29.767308620689654, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12243, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.528932246153846 ], [ 29.767847586206898, -1.529201723076923 ], [ 29.768117068965516, -1.529201723076923 ], [ 29.768117068965516, -1.528932246153846 ], [ 29.767847586206898, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12244, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.528932246153846 ], [ 29.768117068965516, -1.5294712 ], [ 29.768386551724138, -1.5294712 ], [ 29.768386551724138, -1.528932246153846 ], [ 29.768117068965516, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12245, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.528932246153846 ], [ 29.768386551724138, -1.529201723076923 ], [ 29.76865603448276, -1.529201723076923 ], [ 29.76865603448276, -1.528932246153846 ], [ 29.768386551724138, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12246, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.528932246153846 ], [ 29.769464482758622, -1.529201723076923 ], [ 29.769733965517243, -1.529201723076923 ], [ 29.769733965517243, -1.528932246153846 ], [ 29.769464482758622, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12247, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.528932246153846 ], [ 29.769733965517243, -1.529201723076923 ], [ 29.770003448275862, -1.529201723076923 ], [ 29.770003448275862, -1.528932246153846 ], [ 29.769733965517243, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12248, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.528932246153846 ], [ 29.770003448275862, -1.529201723076923 ], [ 29.770272931034484, -1.529201723076923 ], [ 29.770272931034484, -1.528932246153846 ], [ 29.770003448275862, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12249, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.528932246153846 ], [ 29.770272931034484, -1.529201723076923 ], [ 29.770542413793105, -1.529201723076923 ], [ 29.770542413793105, -1.528932246153846 ], [ 29.770272931034484, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12250, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.528932246153846 ], [ 29.770542413793105, -1.529201723076923 ], [ 29.770811896551724, -1.529201723076923 ], [ 29.770811896551724, -1.528932246153846 ], [ 29.770542413793105, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12251, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.528932246153846 ], [ 29.770811896551724, -1.529201723076923 ], [ 29.771081379310345, -1.529201723076923 ], [ 29.771081379310345, -1.528932246153846 ], [ 29.770811896551724, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12252, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.528932246153846 ], [ 29.771081379310345, -1.529201723076923 ], [ 29.771350862068967, -1.529201723076923 ], [ 29.771350862068967, -1.528932246153846 ], [ 29.771081379310345, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12253, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.528932246153846 ], [ 29.771350862068967, -1.529201723076923 ], [ 29.771620344827586, -1.529201723076923 ], [ 29.771620344827586, -1.528932246153846 ], [ 29.771350862068967, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12254, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.528932246153846 ], [ 29.771620344827586, -1.529201723076923 ], [ 29.771889827586207, -1.529201723076923 ], [ 29.771889827586207, -1.528932246153846 ], [ 29.771620344827586, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12255, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.528932246153846 ], [ 29.771889827586207, -1.529201723076923 ], [ 29.772159310344829, -1.529201723076923 ], [ 29.772159310344829, -1.528932246153846 ], [ 29.771889827586207, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12256, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.528932246153846 ], [ 29.772159310344829, -1.529201723076923 ], [ 29.772428793103447, -1.529201723076923 ], [ 29.772428793103447, -1.528932246153846 ], [ 29.772159310344829, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12257, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.529201723076923 ], [ 29.73739603448276, -1.529740676923077 ], [ 29.737665517241378, -1.529740676923077 ], [ 29.737665517241378, -1.529201723076923 ], [ 29.73739603448276, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12258, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.529201723076923 ], [ 29.737665517241378, -1.529740676923077 ], [ 29.737935, -1.529740676923077 ], [ 29.737935, -1.529201723076923 ], [ 29.737665517241378, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12259, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.529201723076923 ], [ 29.737935, -1.5294712 ], [ 29.738204482758622, -1.5294712 ], [ 29.738204482758622, -1.529201723076923 ], [ 29.737935, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12260, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.529201723076923 ], [ 29.738743448275862, -1.5294712 ], [ 29.739012931034484, -1.5294712 ], [ 29.739012931034484, -1.529201723076923 ], [ 29.738743448275862, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12261, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.529201723076923 ], [ 29.739012931034484, -1.5294712 ], [ 29.739282413793102, -1.5294712 ], [ 29.739282413793102, -1.529201723076923 ], [ 29.739012931034484, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12262, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.529201723076923 ], [ 29.739282413793102, -1.5294712 ], [ 29.739551896551724, -1.5294712 ], [ 29.739551896551724, -1.529201723076923 ], [ 29.739282413793102, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12263, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.529201723076923 ], [ 29.760032586206897, -1.5294712 ], [ 29.760571551724137, -1.5294712 ], [ 29.760571551724137, -1.529201723076923 ], [ 29.760032586206897, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12264, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.529201723076923 ], [ 29.760571551724137, -1.5294712 ], [ 29.760841034482759, -1.5294712 ], [ 29.760841034482759, -1.529201723076923 ], [ 29.760571551724137, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12265, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.529201723076923 ], [ 29.760841034482759, -1.530549107692308 ], [ 29.761110517241381, -1.530549107692308 ], [ 29.761110517241381, -1.529201723076923 ], [ 29.760841034482759, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12266, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.529201723076923 ], [ 29.761110517241381, -1.530549107692308 ], [ 29.76138, -1.530549107692308 ], [ 29.76138, -1.529201723076923 ], [ 29.761110517241381, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12267, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.529201723076923 ], [ 29.761649482758621, -1.530549107692308 ], [ 29.761918965517243, -1.530549107692308 ], [ 29.761918965517243, -1.529201723076923 ], [ 29.761649482758621, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12268, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.529201723076923 ], [ 29.761918965517243, -1.530549107692308 ], [ 29.762188448275861, -1.530549107692308 ], [ 29.762188448275861, -1.529201723076923 ], [ 29.761918965517243, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12269, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.529201723076923 ], [ 29.762188448275861, -1.530549107692308 ], [ 29.762457931034483, -1.530549107692308 ], [ 29.762457931034483, -1.529201723076923 ], [ 29.762188448275861, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12270, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.529201723076923 ], [ 29.762996896551723, -1.530549107692308 ], [ 29.763266379310345, -1.530549107692308 ], [ 29.763266379310345, -1.529201723076923 ], [ 29.762996896551723, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12271, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.529201723076923 ], [ 29.763805344827588, -1.530549107692308 ], [ 29.764074827586207, -1.530549107692308 ], [ 29.764074827586207, -1.529201723076923 ], [ 29.763805344827588, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12272, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.529201723076923 ], [ 29.766230689655174, -1.5294712 ], [ 29.766500172413792, -1.5294712 ], [ 29.766500172413792, -1.529201723076923 ], [ 29.766230689655174, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12273, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.529201723076923 ], [ 29.766769655172414, -1.530010153846154 ], [ 29.767039137931036, -1.530010153846154 ], [ 29.767039137931036, -1.529201723076923 ], [ 29.766769655172414, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12274, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.529201723076923 ], [ 29.767039137931036, -1.5294712 ], [ 29.767308620689654, -1.5294712 ], [ 29.767308620689654, -1.529201723076923 ], [ 29.767039137931036, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12275, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.529201723076923 ], [ 29.767308620689654, -1.5294712 ], [ 29.767578103448276, -1.5294712 ], [ 29.767578103448276, -1.529201723076923 ], [ 29.767308620689654, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12276, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.529201723076923 ], [ 29.767847586206898, -1.529740676923077 ], [ 29.768117068965516, -1.529740676923077 ], [ 29.768117068965516, -1.529201723076923 ], [ 29.767847586206898, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12277, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.529201723076923 ], [ 29.76865603448276, -1.529740676923077 ], [ 29.768925517241382, -1.529740676923077 ], [ 29.768925517241382, -1.529201723076923 ], [ 29.76865603448276, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12278, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.529201723076923 ], [ 29.768925517241382, -1.5294712 ], [ 29.769195, -1.5294712 ], [ 29.769195, -1.529201723076923 ], [ 29.768925517241382, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12279, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.529201723076923 ], [ 29.769195, -1.5294712 ], [ 29.769464482758622, -1.5294712 ], [ 29.769464482758622, -1.529201723076923 ], [ 29.769195, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12280, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.529201723076923 ], [ 29.769464482758622, -1.5294712 ], [ 29.769733965517243, -1.5294712 ], [ 29.769733965517243, -1.529201723076923 ], [ 29.769464482758622, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12281, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.529201723076923 ], [ 29.769733965517243, -1.5294712 ], [ 29.770003448275862, -1.5294712 ], [ 29.770003448275862, -1.529201723076923 ], [ 29.769733965517243, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12282, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.529201723076923 ], [ 29.770003448275862, -1.5294712 ], [ 29.770272931034484, -1.5294712 ], [ 29.770272931034484, -1.529201723076923 ], [ 29.770003448275862, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12283, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.529201723076923 ], [ 29.770272931034484, -1.5294712 ], [ 29.770542413793105, -1.5294712 ], [ 29.770542413793105, -1.529201723076923 ], [ 29.770272931034484, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12284, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.529201723076923 ], [ 29.770542413793105, -1.5294712 ], [ 29.770811896551724, -1.5294712 ], [ 29.770811896551724, -1.529201723076923 ], [ 29.770542413793105, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12285, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.529201723076923 ], [ 29.770811896551724, -1.5294712 ], [ 29.771081379310345, -1.5294712 ], [ 29.771081379310345, -1.529201723076923 ], [ 29.770811896551724, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12286, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.529201723076923 ], [ 29.771081379310345, -1.5294712 ], [ 29.771350862068967, -1.5294712 ], [ 29.771350862068967, -1.529201723076923 ], [ 29.771081379310345, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12287, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.529201723076923 ], [ 29.771350862068967, -1.5294712 ], [ 29.771620344827586, -1.5294712 ], [ 29.771620344827586, -1.529201723076923 ], [ 29.771350862068967, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12288, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.529201723076923 ], [ 29.771620344827586, -1.5294712 ], [ 29.771889827586207, -1.5294712 ], [ 29.771889827586207, -1.529201723076923 ], [ 29.771620344827586, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12289, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.529201723076923 ], [ 29.771889827586207, -1.5294712 ], [ 29.772159310344829, -1.5294712 ], [ 29.772159310344829, -1.529201723076923 ], [ 29.771889827586207, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12290, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.5294712 ], [ 29.736587586206898, -1.529740676923077 ], [ 29.737126551724138, -1.529740676923077 ], [ 29.737126551724138, -1.5294712 ], [ 29.736587586206898, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12291, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.5294712 ], [ 29.737126551724138, -1.529740676923077 ], [ 29.73739603448276, -1.529740676923077 ], [ 29.73739603448276, -1.5294712 ], [ 29.737126551724138, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12292, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.5294712 ], [ 29.737935, -1.530010153846154 ], [ 29.738204482758622, -1.530010153846154 ], [ 29.738204482758622, -1.5294712 ], [ 29.737935, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12293, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.5294712 ], [ 29.738204482758622, -1.529740676923077 ], [ 29.738743448275862, -1.529740676923077 ], [ 29.738743448275862, -1.5294712 ], [ 29.738204482758622, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12294, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.5294712 ], [ 29.738743448275862, -1.529740676923077 ], [ 29.739012931034484, -1.529740676923077 ], [ 29.739012931034484, -1.5294712 ], [ 29.738743448275862, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12295, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.5294712 ], [ 29.739012931034484, -1.529740676923077 ], [ 29.739282413793102, -1.529740676923077 ], [ 29.739282413793102, -1.5294712 ], [ 29.739012931034484, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12296, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.5294712 ], [ 29.739282413793102, -1.529740676923077 ], [ 29.739551896551724, -1.529740676923077 ], [ 29.739551896551724, -1.5294712 ], [ 29.739282413793102, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12297, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.5294712 ], [ 29.760571551724137, -1.530279630769231 ], [ 29.760841034482759, -1.530279630769231 ], [ 29.760841034482759, -1.5294712 ], [ 29.760571551724137, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12298, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5294712 ], [ 29.76138, -1.530279630769231 ], [ 29.761649482758621, -1.530279630769231 ], [ 29.761649482758621, -1.5294712 ], [ 29.76138, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12299, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.5294712 ], [ 29.764883275862069, -1.530279630769231 ], [ 29.76515275862069, -1.530279630769231 ], [ 29.76515275862069, -1.5294712 ], [ 29.764883275862069, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12300, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.5294712 ], [ 29.769464482758622, -1.529740676923077 ], [ 29.769733965517243, -1.529740676923077 ], [ 29.769733965517243, -1.5294712 ], [ 29.769464482758622, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12301, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.5294712 ], [ 29.769733965517243, -1.529740676923077 ], [ 29.770003448275862, -1.529740676923077 ], [ 29.770003448275862, -1.5294712 ], [ 29.769733965517243, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12302, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.5294712 ], [ 29.770003448275862, -1.529740676923077 ], [ 29.770272931034484, -1.529740676923077 ], [ 29.770272931034484, -1.5294712 ], [ 29.770003448275862, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12303, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.5294712 ], [ 29.770272931034484, -1.530010153846154 ], [ 29.770542413793105, -1.530010153846154 ], [ 29.770542413793105, -1.5294712 ], [ 29.770272931034484, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12304, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.5294712 ], [ 29.770542413793105, -1.529740676923077 ], [ 29.770811896551724, -1.529740676923077 ], [ 29.770811896551724, -1.5294712 ], [ 29.770542413793105, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12305, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.5294712 ], [ 29.770811896551724, -1.529740676923077 ], [ 29.771081379310345, -1.529740676923077 ], [ 29.771081379310345, -1.5294712 ], [ 29.770811896551724, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12306, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.5294712 ], [ 29.771081379310345, -1.529740676923077 ], [ 29.771350862068967, -1.529740676923077 ], [ 29.771350862068967, -1.5294712 ], [ 29.771081379310345, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12307, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.5294712 ], [ 29.771350862068967, -1.529740676923077 ], [ 29.771620344827586, -1.529740676923077 ], [ 29.771620344827586, -1.5294712 ], [ 29.771350862068967, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12308, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.5294712 ], [ 29.771620344827586, -1.529740676923077 ], [ 29.771889827586207, -1.529740676923077 ], [ 29.771889827586207, -1.5294712 ], [ 29.771620344827586, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12309, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.529740676923077 ], [ 29.736048620689655, -1.530010153846154 ], [ 29.736587586206898, -1.530010153846154 ], [ 29.736587586206898, -1.529740676923077 ], [ 29.736048620689655, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12310, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.529740676923077 ], [ 29.736587586206898, -1.530010153846154 ], [ 29.736857068965517, -1.530010153846154 ], [ 29.736857068965517, -1.529740676923077 ], [ 29.736587586206898, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12311, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.529740676923077 ], [ 29.736857068965517, -1.530010153846154 ], [ 29.73739603448276, -1.530010153846154 ], [ 29.73739603448276, -1.529740676923077 ], [ 29.736857068965517, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12312, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.529740676923077 ], [ 29.73739603448276, -1.530010153846154 ], [ 29.737665517241378, -1.530010153846154 ], [ 29.737665517241378, -1.529740676923077 ], [ 29.73739603448276, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12313, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.529740676923077 ], [ 29.737665517241378, -1.530010153846154 ], [ 29.737935, -1.530010153846154 ], [ 29.737935, -1.529740676923077 ], [ 29.737665517241378, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12314, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.529740676923077 ], [ 29.738204482758622, -1.530010153846154 ], [ 29.738743448275862, -1.530010153846154 ], [ 29.738743448275862, -1.529740676923077 ], [ 29.738204482758622, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12315, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.529740676923077 ], [ 29.738743448275862, -1.530010153846154 ], [ 29.739012931034484, -1.530010153846154 ], [ 29.739012931034484, -1.529740676923077 ], [ 29.738743448275862, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12316, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.529740676923077 ], [ 29.739012931034484, -1.530010153846154 ], [ 29.739282413793102, -1.530010153846154 ], [ 29.739282413793102, -1.529740676923077 ], [ 29.739012931034484, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12317, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.529740676923077 ], [ 29.767847586206898, -1.530818584615385 ], [ 29.768117068965516, -1.530818584615385 ], [ 29.768117068965516, -1.529740676923077 ], [ 29.767847586206898, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12318, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.529740676923077 ], [ 29.76865603448276, -1.530818584615385 ], [ 29.768925517241382, -1.530818584615385 ], [ 29.768925517241382, -1.529740676923077 ], [ 29.76865603448276, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12319, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.529740676923077 ], [ 29.769464482758622, -1.530818584615385 ], [ 29.769733965517243, -1.530818584615385 ], [ 29.769733965517243, -1.529740676923077 ], [ 29.769464482758622, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12320, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.529740676923077 ], [ 29.769733965517243, -1.530818584615385 ], [ 29.770003448275862, -1.530818584615385 ], [ 29.770003448275862, -1.529740676923077 ], [ 29.769733965517243, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12321, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.529740676923077 ], [ 29.770003448275862, -1.530818584615385 ], [ 29.770272931034484, -1.530818584615385 ], [ 29.770272931034484, -1.529740676923077 ], [ 29.770003448275862, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12322, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.529740676923077 ], [ 29.770542413793105, -1.530818584615385 ], [ 29.770811896551724, -1.530818584615385 ], [ 29.770811896551724, -1.529740676923077 ], [ 29.770542413793105, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12323, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.529740676923077 ], [ 29.770811896551724, -1.530818584615385 ], [ 29.771081379310345, -1.530818584615385 ], [ 29.771081379310345, -1.529740676923077 ], [ 29.770811896551724, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12324, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.529740676923077 ], [ 29.771081379310345, -1.530010153846154 ], [ 29.771350862068967, -1.530010153846154 ], [ 29.771350862068967, -1.529740676923077 ], [ 29.771081379310345, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12325, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.529740676923077 ], [ 29.771350862068967, -1.530010153846154 ], [ 29.771620344827586, -1.530010153846154 ], [ 29.771620344827586, -1.529740676923077 ], [ 29.771350862068967, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12326, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.530010153846154 ], [ 29.735509655172415, -1.530279630769231 ], [ 29.736048620689655, -1.530279630769231 ], [ 29.736048620689655, -1.530010153846154 ], [ 29.735509655172415, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12327, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.530010153846154 ], [ 29.736048620689655, -1.530279630769231 ], [ 29.736318103448276, -1.530279630769231 ], [ 29.736318103448276, -1.530010153846154 ], [ 29.736048620689655, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12328, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.530010153846154 ], [ 29.736318103448276, -1.530279630769231 ], [ 29.736857068965517, -1.530279630769231 ], [ 29.736857068965517, -1.530010153846154 ], [ 29.736318103448276, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12329, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.530010153846154 ], [ 29.736857068965517, -1.530279630769231 ], [ 29.73739603448276, -1.530279630769231 ], [ 29.73739603448276, -1.530010153846154 ], [ 29.736857068965517, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12330, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.530010153846154 ], [ 29.73739603448276, -1.530279630769231 ], [ 29.737665517241378, -1.530279630769231 ], [ 29.737665517241378, -1.530010153846154 ], [ 29.73739603448276, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12331, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.530010153846154 ], [ 29.737665517241378, -1.530279630769231 ], [ 29.737935, -1.530279630769231 ], [ 29.737935, -1.530010153846154 ], [ 29.737665517241378, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12332, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.530010153846154 ], [ 29.737935, -1.530279630769231 ], [ 29.738204482758622, -1.530279630769231 ], [ 29.738204482758622, -1.530010153846154 ], [ 29.737935, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12333, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.530010153846154 ], [ 29.738204482758622, -1.530279630769231 ], [ 29.738743448275862, -1.530279630769231 ], [ 29.738743448275862, -1.530010153846154 ], [ 29.738204482758622, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12334, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.530010153846154 ], [ 29.738743448275862, -1.530279630769231 ], [ 29.739012931034484, -1.530279630769231 ], [ 29.739012931034484, -1.530010153846154 ], [ 29.738743448275862, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12335, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.530010153846154 ], [ 29.739012931034484, -1.530279630769231 ], [ 29.739282413793102, -1.530279630769231 ], [ 29.739282413793102, -1.530010153846154 ], [ 29.739012931034484, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12336, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.530010153846154 ], [ 29.766769655172414, -1.530549107692308 ], [ 29.767039137931036, -1.530549107692308 ], [ 29.767039137931036, -1.530010153846154 ], [ 29.766769655172414, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12337, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.530010153846154 ], [ 29.770272931034484, -1.530549107692308 ], [ 29.770542413793105, -1.530549107692308 ], [ 29.770542413793105, -1.530010153846154 ], [ 29.770272931034484, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12338, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.530010153846154 ], [ 29.771081379310345, -1.530549107692308 ], [ 29.771350862068967, -1.530549107692308 ], [ 29.771350862068967, -1.530010153846154 ], [ 29.771081379310345, -1.530010153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12339, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.530279630769231 ], [ 29.734431724137931, -1.530549107692308 ], [ 29.735509655172415, -1.530549107692308 ], [ 29.735509655172415, -1.530279630769231 ], [ 29.734431724137931, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12340, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.530279630769231 ], [ 29.735509655172415, -1.530549107692308 ], [ 29.735779137931036, -1.530549107692308 ], [ 29.735779137931036, -1.530279630769231 ], [ 29.735509655172415, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12341, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.530279630769231 ], [ 29.735779137931036, -1.530549107692308 ], [ 29.736318103448276, -1.530549107692308 ], [ 29.736318103448276, -1.530279630769231 ], [ 29.735779137931036, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12342, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.530279630769231 ], [ 29.736318103448276, -1.530549107692308 ], [ 29.736857068965517, -1.530549107692308 ], [ 29.736857068965517, -1.530279630769231 ], [ 29.736318103448276, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12343, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.530279630769231 ], [ 29.736857068965517, -1.530549107692308 ], [ 29.737126551724138, -1.530549107692308 ], [ 29.737126551724138, -1.530279630769231 ], [ 29.736857068965517, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12344, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.530279630769231 ], [ 29.737126551724138, -1.530549107692308 ], [ 29.737665517241378, -1.530549107692308 ], [ 29.737665517241378, -1.530279630769231 ], [ 29.737126551724138, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12345, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.530279630769231 ], [ 29.737665517241378, -1.530549107692308 ], [ 29.737935, -1.530549107692308 ], [ 29.737935, -1.530279630769231 ], [ 29.737665517241378, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12346, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.530279630769231 ], [ 29.737935, -1.530549107692308 ], [ 29.738204482758622, -1.530549107692308 ], [ 29.738204482758622, -1.530279630769231 ], [ 29.737935, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12347, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.530279630769231 ], [ 29.738204482758622, -1.530549107692308 ], [ 29.738743448275862, -1.530549107692308 ], [ 29.738743448275862, -1.530279630769231 ], [ 29.738204482758622, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12348, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.530279630769231 ], [ 29.738743448275862, -1.530549107692308 ], [ 29.739012931034484, -1.530549107692308 ], [ 29.739012931034484, -1.530279630769231 ], [ 29.738743448275862, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12349, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.529740676923077 ], [ 29.739551896551724, -1.529740676923077 ], [ 29.739551896551724, -1.530818584615385 ], [ 29.739282413793102, -1.530818584615385 ], [ 29.739282413793102, -1.530549107692308 ], [ 29.739012931034484, -1.530549107692308 ], [ 29.739012931034484, -1.530279630769231 ], [ 29.739282413793102, -1.530279630769231 ], [ 29.739282413793102, -1.529740676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12350, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.530279630769231 ], [ 29.760032586206897, -1.530549107692308 ], [ 29.760571551724137, -1.530549107692308 ], [ 29.760571551724137, -1.530279630769231 ], [ 29.760032586206897, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12351, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.530279630769231 ], [ 29.760571551724137, -1.530549107692308 ], [ 29.760841034482759, -1.530549107692308 ], [ 29.760841034482759, -1.530279630769231 ], [ 29.760571551724137, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12352, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.530279630769231 ], [ 29.76138, -1.530818584615385 ], [ 29.761649482758621, -1.530818584615385 ], [ 29.761649482758621, -1.530279630769231 ], [ 29.76138, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12353, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.530549107692308 ], [ 29.734431724137931, -1.530818584615385 ], [ 29.734701206896553, -1.530818584615385 ], [ 29.734701206896553, -1.530549107692308 ], [ 29.734431724137931, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12354, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.530549107692308 ], [ 29.734701206896553, -1.530818584615385 ], [ 29.735779137931036, -1.530818584615385 ], [ 29.735779137931036, -1.530549107692308 ], [ 29.734701206896553, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12355, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.530549107692308 ], [ 29.735779137931036, -1.530818584615385 ], [ 29.736318103448276, -1.530818584615385 ], [ 29.736318103448276, -1.530549107692308 ], [ 29.735779137931036, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12356, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.530549107692308 ], [ 29.736318103448276, -1.530818584615385 ], [ 29.736587586206898, -1.530818584615385 ], [ 29.736587586206898, -1.530549107692308 ], [ 29.736318103448276, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12357, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.530549107692308 ], [ 29.736587586206898, -1.530818584615385 ], [ 29.737126551724138, -1.530818584615385 ], [ 29.737126551724138, -1.530549107692308 ], [ 29.736587586206898, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12358, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.530549107692308 ], [ 29.737126551724138, -1.530818584615385 ], [ 29.73739603448276, -1.530818584615385 ], [ 29.73739603448276, -1.530549107692308 ], [ 29.737126551724138, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12359, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.530549107692308 ], [ 29.73739603448276, -1.530818584615385 ], [ 29.737665517241378, -1.530818584615385 ], [ 29.737665517241378, -1.530549107692308 ], [ 29.73739603448276, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12360, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.530549107692308 ], [ 29.737665517241378, -1.530818584615385 ], [ 29.737935, -1.530818584615385 ], [ 29.737935, -1.530549107692308 ], [ 29.737665517241378, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12361, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.530549107692308 ], [ 29.737935, -1.530818584615385 ], [ 29.738204482758622, -1.530818584615385 ], [ 29.738204482758622, -1.530549107692308 ], [ 29.737935, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12362, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.530549107692308 ], [ 29.738204482758622, -1.530818584615385 ], [ 29.738743448275862, -1.530818584615385 ], [ 29.738743448275862, -1.530549107692308 ], [ 29.738204482758622, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12363, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.530549107692308 ], [ 29.738743448275862, -1.530818584615385 ], [ 29.739012931034484, -1.530818584615385 ], [ 29.739012931034484, -1.530549107692308 ], [ 29.738743448275862, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12364, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.530549107692308 ], [ 29.758954655172413, -1.530818584615385 ], [ 29.760032586206897, -1.530818584615385 ], [ 29.760032586206897, -1.530549107692308 ], [ 29.758954655172413, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12365, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.530549107692308 ], [ 29.760032586206897, -1.530818584615385 ], [ 29.760302068965519, -1.530818584615385 ], [ 29.760302068965519, -1.530549107692308 ], [ 29.760032586206897, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12366, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.530549107692308 ], [ 29.760302068965519, -1.530818584615385 ], [ 29.760841034482759, -1.530818584615385 ], [ 29.760841034482759, -1.530549107692308 ], [ 29.760302068965519, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12367, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.530549107692308 ], [ 29.760841034482759, -1.530818584615385 ], [ 29.761110517241381, -1.530818584615385 ], [ 29.761110517241381, -1.530549107692308 ], [ 29.760841034482759, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12368, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.530549107692308 ], [ 29.761110517241381, -1.530818584615385 ], [ 29.76138, -1.530818584615385 ], [ 29.76138, -1.530549107692308 ], [ 29.761110517241381, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12369, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.530549107692308 ], [ 29.761649482758621, -1.530818584615385 ], [ 29.761918965517243, -1.530818584615385 ], [ 29.761918965517243, -1.530549107692308 ], [ 29.761649482758621, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12370, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.530549107692308 ], [ 29.761918965517243, -1.530818584615385 ], [ 29.762188448275861, -1.530818584615385 ], [ 29.762188448275861, -1.530549107692308 ], [ 29.761918965517243, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12371, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.530549107692308 ], [ 29.762188448275861, -1.530818584615385 ], [ 29.762457931034483, -1.530818584615385 ], [ 29.762457931034483, -1.530549107692308 ], [ 29.762188448275861, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12372, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.530549107692308 ], [ 29.771081379310345, -1.530818584615385 ], [ 29.771350862068967, -1.530818584615385 ], [ 29.771350862068967, -1.530549107692308 ], [ 29.771081379310345, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12373, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.530549107692308 ], [ 29.771350862068967, -1.530818584615385 ], [ 29.771620344827586, -1.530818584615385 ], [ 29.771620344827586, -1.530549107692308 ], [ 29.771350862068967, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12374, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.528393292307692 ], [ 29.739282413793102, -1.529201723076923 ], [ 29.739551896551724, -1.529201723076923 ], [ 29.739551896551724, -1.531088061538461 ], [ 29.739821379310346, -1.531088061538461 ], [ 29.739821379310346, -1.528662769230769 ], [ 29.739551896551724, -1.528662769230769 ], [ 29.739551896551724, -1.528393292307692 ], [ 29.739282413793102, -1.528393292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12375, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.528662769230769 ], [ 29.739821379310346, -1.531088061538461 ], [ 29.740090862068964, -1.531088061538461 ], [ 29.740090862068964, -1.528662769230769 ], [ 29.739821379310346, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12376, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.528662769230769 ], [ 29.740090862068964, -1.531088061538461 ], [ 29.740360344827586, -1.531088061538461 ], [ 29.740360344827586, -1.528662769230769 ], [ 29.740090862068964, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12377, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.528662769230769 ], [ 29.740629827586208, -1.531357538461538 ], [ 29.74089931034483, -1.531357538461538 ], [ 29.74089931034483, -1.528662769230769 ], [ 29.740629827586208, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12378, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.528662769230769 ], [ 29.74089931034483, -1.531357538461538 ], [ 29.741168793103448, -1.531357538461538 ], [ 29.741168793103448, -1.528662769230769 ], [ 29.74089931034483, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12379, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.528662769230769 ], [ 29.763266379310345, -1.531088061538461 ], [ 29.763535862068967, -1.531088061538461 ], [ 29.763535862068967, -1.528662769230769 ], [ 29.763266379310345, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12380, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.528662769230769 ], [ 29.764074827586207, -1.531088061538461 ], [ 29.764344310344828, -1.531088061538461 ], [ 29.764344310344828, -1.528662769230769 ], [ 29.764074827586207, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12381, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.528662769230769 ], [ 29.76515275862069, -1.531088061538461 ], [ 29.765422241379312, -1.531088061538461 ], [ 29.765422241379312, -1.528662769230769 ], [ 29.76515275862069, -1.528662769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12382, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.528932246153846 ], [ 29.740360344827586, -1.531088061538461 ], [ 29.740629827586208, -1.531088061538461 ], [ 29.740629827586208, -1.528932246153846 ], [ 29.740360344827586, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12383, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.741168793103448, -1.528932246153846 ], [ 29.741168793103448, -1.531627015384615 ], [ 29.74143827586207, -1.531627015384615 ], [ 29.74143827586207, -1.528932246153846 ], [ 29.741168793103448, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12384, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.528932246153846 ], [ 29.766500172413792, -1.531357538461538 ], [ 29.766769655172414, -1.531357538461538 ], [ 29.766769655172414, -1.528932246153846 ], [ 29.766500172413792, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12385, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.528932246153846 ], [ 29.767578103448276, -1.531357538461538 ], [ 29.767847586206898, -1.531357538461538 ], [ 29.767847586206898, -1.528932246153846 ], [ 29.767578103448276, -1.528932246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12386, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.529201723076923 ], [ 29.768386551724138, -1.531357538461538 ], [ 29.76865603448276, -1.531357538461538 ], [ 29.76865603448276, -1.529201723076923 ], [ 29.768386551724138, -1.529201723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12387, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.5294712 ], [ 29.766230689655174, -1.531088061538461 ], [ 29.766500172413792, -1.531088061538461 ], [ 29.766500172413792, -1.5294712 ], [ 29.766230689655174, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12388, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.5294712 ], [ 29.767039137931036, -1.531088061538461 ], [ 29.767308620689654, -1.531088061538461 ], [ 29.767308620689654, -1.5294712 ], [ 29.767039137931036, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12389, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.5294712 ], [ 29.767308620689654, -1.531088061538461 ], [ 29.767578103448276, -1.531088061538461 ], [ 29.767578103448276, -1.5294712 ], [ 29.767308620689654, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12390, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.5294712 ], [ 29.768117068965516, -1.531088061538461 ], [ 29.768386551724138, -1.531088061538461 ], [ 29.768386551724138, -1.5294712 ], [ 29.768117068965516, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12391, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.5294712 ], [ 29.768925517241382, -1.531088061538461 ], [ 29.769195, -1.531088061538461 ], [ 29.769195, -1.5294712 ], [ 29.768925517241382, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12392, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.5294712 ], [ 29.769195, -1.531088061538461 ], [ 29.769464482758622, -1.531088061538461 ], [ 29.769464482758622, -1.5294712 ], [ 29.769195, -1.5294712 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12393, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.530279630769231 ], [ 29.764883275862069, -1.531088061538461 ], [ 29.76515275862069, -1.531088061538461 ], [ 29.76515275862069, -1.530279630769231 ], [ 29.764883275862069, -1.530279630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12394, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733084310344829, -1.530549107692308 ], [ 29.733084310344829, -1.531088061538461 ], [ 29.733353793103447, -1.531088061538461 ], [ 29.733353793103447, -1.530818584615385 ], [ 29.734431724137931, -1.530818584615385 ], [ 29.734431724137931, -1.530549107692308 ], [ 29.733084310344829, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12395, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.530549107692308 ], [ 29.762996896551723, -1.531088061538461 ], [ 29.763266379310345, -1.531088061538461 ], [ 29.763266379310345, -1.530549107692308 ], [ 29.762996896551723, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12396, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.530549107692308 ], [ 29.763805344827588, -1.531088061538461 ], [ 29.764074827586207, -1.531088061538461 ], [ 29.764074827586207, -1.530549107692308 ], [ 29.763805344827588, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12397, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.530549107692308 ], [ 29.766769655172414, -1.531357538461538 ], [ 29.767039137931036, -1.531357538461538 ], [ 29.767039137931036, -1.530549107692308 ], [ 29.766769655172414, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12398, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.530549107692308 ], [ 29.770272931034484, -1.531088061538461 ], [ 29.770542413793105, -1.531088061538461 ], [ 29.770542413793105, -1.530549107692308 ], [ 29.770272931034484, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12399, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.530818584615385 ], [ 29.733353793103447, -1.531088061538461 ], [ 29.733623275862069, -1.531088061538461 ], [ 29.733623275862069, -1.530818584615385 ], [ 29.733353793103447, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12400, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733623275862069, -1.530818584615385 ], [ 29.733623275862069, -1.531896492307692 ], [ 29.734162241379309, -1.531896492307692 ], [ 29.734162241379309, -1.531627015384615 ], [ 29.733892758620691, -1.531627015384615 ], [ 29.733892758620691, -1.531088061538461 ], [ 29.734701206896553, -1.531088061538461 ], [ 29.734701206896553, -1.530818584615385 ], [ 29.733623275862069, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12401, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.530818584615385 ], [ 29.734701206896553, -1.531088061538461 ], [ 29.735509655172415, -1.531088061538461 ], [ 29.735509655172415, -1.531357538461538 ], [ 29.735779137931036, -1.531357538461538 ], [ 29.735779137931036, -1.530818584615385 ], [ 29.734701206896553, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12402, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.530818584615385 ], [ 29.735779137931036, -1.531357538461538 ], [ 29.736048620689655, -1.531357538461538 ], [ 29.736048620689655, -1.530818584615385 ], [ 29.735779137931036, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12403, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.530818584615385 ], [ 29.736048620689655, -1.531627015384615 ], [ 29.736318103448276, -1.531627015384615 ], [ 29.736318103448276, -1.531088061538461 ], [ 29.736587586206898, -1.531088061538461 ], [ 29.736587586206898, -1.530818584615385 ], [ 29.736048620689655, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12404, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.530818584615385 ], [ 29.736587586206898, -1.531088061538461 ], [ 29.736857068965517, -1.531088061538461 ], [ 29.736857068965517, -1.530818584615385 ], [ 29.736587586206898, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12405, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.530818584615385 ], [ 29.736857068965517, -1.531088061538461 ], [ 29.737126551724138, -1.531088061538461 ], [ 29.737126551724138, -1.530818584615385 ], [ 29.736857068965517, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12406, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.530818584615385 ], [ 29.737126551724138, -1.531088061538461 ], [ 29.73739603448276, -1.531088061538461 ], [ 29.73739603448276, -1.530818584615385 ], [ 29.737126551724138, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12407, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.530818584615385 ], [ 29.73739603448276, -1.531088061538461 ], [ 29.737665517241378, -1.531088061538461 ], [ 29.737665517241378, -1.530818584615385 ], [ 29.73739603448276, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12408, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.530818584615385 ], [ 29.737665517241378, -1.531088061538461 ], [ 29.737935, -1.531088061538461 ], [ 29.737935, -1.530818584615385 ], [ 29.737665517241378, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12409, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.530818584615385 ], [ 29.737935, -1.531088061538461 ], [ 29.738204482758622, -1.531088061538461 ], [ 29.738204482758622, -1.530818584615385 ], [ 29.737935, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12410, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.530818584615385 ], [ 29.738204482758622, -1.531088061538461 ], [ 29.738743448275862, -1.531088061538461 ], [ 29.738743448275862, -1.530818584615385 ], [ 29.738204482758622, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12411, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.530549107692308 ], [ 29.739282413793102, -1.530549107692308 ], [ 29.739282413793102, -1.531088061538461 ], [ 29.738743448275862, -1.531088061538461 ], [ 29.738743448275862, -1.530818584615385 ], [ 29.739012931034484, -1.530818584615385 ], [ 29.739012931034484, -1.530549107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12412, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.530818584615385 ], [ 29.739282413793102, -1.531088061538461 ], [ 29.739551896551724, -1.531088061538461 ], [ 29.739551896551724, -1.530818584615385 ], [ 29.739282413793102, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12413, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.530818584615385 ], [ 29.758685172413795, -1.531088061538461 ], [ 29.758954655172413, -1.531088061538461 ], [ 29.758954655172413, -1.530818584615385 ], [ 29.758685172413795, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12414, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.530818584615385 ], [ 29.758954655172413, -1.531088061538461 ], [ 29.759224137931035, -1.531088061538461 ], [ 29.759224137931035, -1.530818584615385 ], [ 29.758954655172413, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12415, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.530818584615385 ], [ 29.759224137931035, -1.531088061538461 ], [ 29.760302068965519, -1.531088061538461 ], [ 29.760302068965519, -1.530818584615385 ], [ 29.759224137931035, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12416, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.530818584615385 ], [ 29.760302068965519, -1.531088061538461 ], [ 29.760841034482759, -1.531088061538461 ], [ 29.760841034482759, -1.530818584615385 ], [ 29.760302068965519, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12417, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.530818584615385 ], [ 29.760841034482759, -1.531088061538461 ], [ 29.761110517241381, -1.531088061538461 ], [ 29.761110517241381, -1.530818584615385 ], [ 29.760841034482759, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12418, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.530818584615385 ], [ 29.761110517241381, -1.531088061538461 ], [ 29.76138, -1.531088061538461 ], [ 29.76138, -1.530818584615385 ], [ 29.761110517241381, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12419, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.530818584615385 ], [ 29.76138, -1.531088061538461 ], [ 29.761649482758621, -1.531088061538461 ], [ 29.761649482758621, -1.530818584615385 ], [ 29.76138, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12420, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.530818584615385 ], [ 29.761649482758621, -1.531088061538461 ], [ 29.761918965517243, -1.531088061538461 ], [ 29.761918965517243, -1.530818584615385 ], [ 29.761649482758621, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12421, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.530818584615385 ], [ 29.761918965517243, -1.531088061538461 ], [ 29.762188448275861, -1.531088061538461 ], [ 29.762188448275861, -1.530818584615385 ], [ 29.761918965517243, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12422, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.530818584615385 ], [ 29.762188448275861, -1.531088061538461 ], [ 29.762457931034483, -1.531088061538461 ], [ 29.762457931034483, -1.530818584615385 ], [ 29.762188448275861, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12423, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.530818584615385 ], [ 29.762457931034483, -1.531088061538461 ], [ 29.762727413793105, -1.531088061538461 ], [ 29.762727413793105, -1.530818584615385 ], [ 29.762457931034483, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12424, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.530818584615385 ], [ 29.762727413793105, -1.531088061538461 ], [ 29.762996896551723, -1.531088061538461 ], [ 29.762996896551723, -1.530818584615385 ], [ 29.762727413793105, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12425, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.530818584615385 ], [ 29.763535862068967, -1.531357538461538 ], [ 29.763805344827588, -1.531357538461538 ], [ 29.763805344827588, -1.530818584615385 ], [ 29.763535862068967, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12426, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.530818584615385 ], [ 29.764344310344828, -1.531357538461538 ], [ 29.76461379310345, -1.531357538461538 ], [ 29.76461379310345, -1.530818584615385 ], [ 29.764344310344828, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12427, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.530818584615385 ], [ 29.76461379310345, -1.531357538461538 ], [ 29.764883275862069, -1.531357538461538 ], [ 29.764883275862069, -1.530818584615385 ], [ 29.76461379310345, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12428, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.530818584615385 ], [ 29.765422241379312, -1.531357538461538 ], [ 29.765961206896552, -1.531357538461538 ], [ 29.765961206896552, -1.530818584615385 ], [ 29.765422241379312, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12429, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.530818584615385 ], [ 29.765961206896552, -1.531627015384615 ], [ 29.766230689655174, -1.531627015384615 ], [ 29.766230689655174, -1.530818584615385 ], [ 29.765961206896552, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12430, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.530818584615385 ], [ 29.767847586206898, -1.531357538461538 ], [ 29.768117068965516, -1.531357538461538 ], [ 29.768117068965516, -1.530818584615385 ], [ 29.767847586206898, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12431, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.530818584615385 ], [ 29.76865603448276, -1.531357538461538 ], [ 29.768925517241382, -1.531357538461538 ], [ 29.768925517241382, -1.530818584615385 ], [ 29.76865603448276, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12432, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.530818584615385 ], [ 29.769464482758622, -1.531088061538461 ], [ 29.769733965517243, -1.531088061538461 ], [ 29.769733965517243, -1.530818584615385 ], [ 29.769464482758622, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12433, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.530818584615385 ], [ 29.769733965517243, -1.531088061538461 ], [ 29.770003448275862, -1.531088061538461 ], [ 29.770003448275862, -1.530818584615385 ], [ 29.769733965517243, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12434, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.530818584615385 ], [ 29.770003448275862, -1.531088061538461 ], [ 29.770272931034484, -1.531088061538461 ], [ 29.770272931034484, -1.530818584615385 ], [ 29.770003448275862, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12435, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.530818584615385 ], [ 29.770542413793105, -1.531088061538461 ], [ 29.770811896551724, -1.531088061538461 ], [ 29.770811896551724, -1.530818584615385 ], [ 29.770542413793105, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12436, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.530818584615385 ], [ 29.770811896551724, -1.531088061538461 ], [ 29.771081379310345, -1.531088061538461 ], [ 29.771081379310345, -1.530818584615385 ], [ 29.770811896551724, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12437, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.530818584615385 ], [ 29.771081379310345, -1.531088061538461 ], [ 29.771350862068967, -1.531088061538461 ], [ 29.771350862068967, -1.530818584615385 ], [ 29.771081379310345, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12438, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.530818584615385 ], [ 29.771350862068967, -1.531088061538461 ], [ 29.771620344827586, -1.531088061538461 ], [ 29.771620344827586, -1.530818584615385 ], [ 29.771350862068967, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12439, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.530818584615385 ], [ 29.771620344827586, -1.531357538461538 ], [ 29.771889827586207, -1.531357538461538 ], [ 29.771889827586207, -1.530818584615385 ], [ 29.771620344827586, -1.530818584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12440, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733353793103447, -1.531088061538461 ], [ 29.733353793103447, -1.532165969230769 ], [ 29.734431724137931, -1.532165969230769 ], [ 29.734431724137931, -1.531896492307692 ], [ 29.733623275862069, -1.531896492307692 ], [ 29.733623275862069, -1.531088061538461 ], [ 29.733353793103447, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12441, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.733892758620691, -1.531088061538461 ], [ 29.733892758620691, -1.531627015384615 ], [ 29.734162241379309, -1.531627015384615 ], [ 29.734162241379309, -1.531357538461538 ], [ 29.734701206896553, -1.531357538461538 ], [ 29.734701206896553, -1.531088061538461 ], [ 29.733892758620691, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12442, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.531088061538461 ], [ 29.734701206896553, -1.531357538461538 ], [ 29.735509655172415, -1.531357538461538 ], [ 29.735509655172415, -1.531088061538461 ], [ 29.734701206896553, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12443, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.531088061538461 ], [ 29.736318103448276, -1.531357538461538 ], [ 29.736587586206898, -1.531357538461538 ], [ 29.736587586206898, -1.531088061538461 ], [ 29.736318103448276, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12444, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.531088061538461 ], [ 29.736587586206898, -1.531627015384615 ], [ 29.737126551724138, -1.531627015384615 ], [ 29.737126551724138, -1.531357538461538 ], [ 29.736857068965517, -1.531357538461538 ], [ 29.736857068965517, -1.531088061538461 ], [ 29.736587586206898, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12445, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.531088061538461 ], [ 29.736857068965517, -1.531357538461538 ], [ 29.737126551724138, -1.531357538461538 ], [ 29.737126551724138, -1.531088061538461 ], [ 29.736857068965517, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12446, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.531088061538461 ], [ 29.737126551724138, -1.531357538461538 ], [ 29.73739603448276, -1.531357538461538 ], [ 29.73739603448276, -1.531088061538461 ], [ 29.737126551724138, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12447, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.531088061538461 ], [ 29.73739603448276, -1.531357538461538 ], [ 29.737665517241378, -1.531357538461538 ], [ 29.737665517241378, -1.531088061538461 ], [ 29.73739603448276, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12448, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.531088061538461 ], [ 29.737665517241378, -1.531357538461538 ], [ 29.737935, -1.531357538461538 ], [ 29.737935, -1.531088061538461 ], [ 29.737665517241378, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12449, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.531088061538461 ], [ 29.737935, -1.531357538461538 ], [ 29.738204482758622, -1.531357538461538 ], [ 29.738204482758622, -1.531088061538461 ], [ 29.737935, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12450, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.531088061538461 ], [ 29.738204482758622, -1.531357538461538 ], [ 29.738743448275862, -1.531357538461538 ], [ 29.738743448275862, -1.531088061538461 ], [ 29.738204482758622, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12451, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.531088061538461 ], [ 29.738743448275862, -1.531357538461538 ], [ 29.739012931034484, -1.531357538461538 ], [ 29.739012931034484, -1.531088061538461 ], [ 29.738743448275862, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12452, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.531088061538461 ], [ 29.739012931034484, -1.531896492307692 ], [ 29.739282413793102, -1.531896492307692 ], [ 29.739282413793102, -1.531088061538461 ], [ 29.739012931034484, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12453, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.531088061538461 ], [ 29.739282413793102, -1.531357538461538 ], [ 29.739551896551724, -1.531357538461538 ], [ 29.739551896551724, -1.531088061538461 ], [ 29.739282413793102, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12454, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.531088061538461 ], [ 29.739551896551724, -1.531357538461538 ], [ 29.739821379310346, -1.531357538461538 ], [ 29.739821379310346, -1.531088061538461 ], [ 29.739551896551724, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12455, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.531088061538461 ], [ 29.739821379310346, -1.531357538461538 ], [ 29.740090862068964, -1.531357538461538 ], [ 29.740090862068964, -1.531088061538461 ], [ 29.739821379310346, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12456, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.531088061538461 ], [ 29.740090862068964, -1.531627015384615 ], [ 29.740360344827586, -1.531627015384615 ], [ 29.740360344827586, -1.531088061538461 ], [ 29.740090862068964, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12457, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.531088061538461 ], [ 29.740360344827586, -1.531357538461538 ], [ 29.740629827586208, -1.531357538461538 ], [ 29.740629827586208, -1.531088061538461 ], [ 29.740360344827586, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12458, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.531088061538461 ], [ 29.758146206896551, -1.531357538461538 ], [ 29.758685172413795, -1.531357538461538 ], [ 29.758685172413795, -1.531088061538461 ], [ 29.758146206896551, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12459, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.531088061538461 ], [ 29.758685172413795, -1.531357538461538 ], [ 29.758954655172413, -1.531357538461538 ], [ 29.758954655172413, -1.531088061538461 ], [ 29.758685172413795, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12460, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.531088061538461 ], [ 29.758954655172413, -1.531357538461538 ], [ 29.759224137931035, -1.531357538461538 ], [ 29.759224137931035, -1.531088061538461 ], [ 29.758954655172413, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12461, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.531088061538461 ], [ 29.759224137931035, -1.531357538461538 ], [ 29.760302068965519, -1.531357538461538 ], [ 29.760302068965519, -1.531088061538461 ], [ 29.759224137931035, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12462, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.531088061538461 ], [ 29.760302068965519, -1.531357538461538 ], [ 29.760571551724137, -1.531357538461538 ], [ 29.760571551724137, -1.531088061538461 ], [ 29.760302068965519, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12463, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.531088061538461 ], [ 29.760571551724137, -1.531357538461538 ], [ 29.761110517241381, -1.531357538461538 ], [ 29.761110517241381, -1.531088061538461 ], [ 29.760571551724137, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12464, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.531088061538461 ], [ 29.761110517241381, -1.531357538461538 ], [ 29.76138, -1.531357538461538 ], [ 29.76138, -1.531088061538461 ], [ 29.761110517241381, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12465, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.531088061538461 ], [ 29.76138, -1.531357538461538 ], [ 29.761649482758621, -1.531357538461538 ], [ 29.761649482758621, -1.531088061538461 ], [ 29.76138, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12466, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.531088061538461 ], [ 29.761649482758621, -1.531357538461538 ], [ 29.761918965517243, -1.531357538461538 ], [ 29.761918965517243, -1.531088061538461 ], [ 29.761649482758621, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12467, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.531088061538461 ], [ 29.761918965517243, -1.531357538461538 ], [ 29.762188448275861, -1.531357538461538 ], [ 29.762188448275861, -1.531088061538461 ], [ 29.761918965517243, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12468, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.531088061538461 ], [ 29.762188448275861, -1.531357538461538 ], [ 29.762457931034483, -1.531357538461538 ], [ 29.762457931034483, -1.531088061538461 ], [ 29.762188448275861, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12469, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.531088061538461 ], [ 29.762457931034483, -1.531357538461538 ], [ 29.762727413793105, -1.531357538461538 ], [ 29.762727413793105, -1.531088061538461 ], [ 29.762457931034483, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12470, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.531088061538461 ], [ 29.762727413793105, -1.531357538461538 ], [ 29.762996896551723, -1.531357538461538 ], [ 29.762996896551723, -1.531088061538461 ], [ 29.762727413793105, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12471, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.531088061538461 ], [ 29.762996896551723, -1.531357538461538 ], [ 29.763266379310345, -1.531357538461538 ], [ 29.763266379310345, -1.531088061538461 ], [ 29.762996896551723, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12472, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.531088061538461 ], [ 29.763266379310345, -1.531357538461538 ], [ 29.763535862068967, -1.531357538461538 ], [ 29.763535862068967, -1.531088061538461 ], [ 29.763266379310345, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12473, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.531088061538461 ], [ 29.763805344827588, -1.531627015384615 ], [ 29.764074827586207, -1.531627015384615 ], [ 29.764074827586207, -1.531088061538461 ], [ 29.763805344827588, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12474, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.531088061538461 ], [ 29.764074827586207, -1.531357538461538 ], [ 29.764344310344828, -1.531357538461538 ], [ 29.764344310344828, -1.531088061538461 ], [ 29.764074827586207, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12475, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.531088061538461 ], [ 29.764883275862069, -1.531627015384615 ], [ 29.76515275862069, -1.531627015384615 ], [ 29.76515275862069, -1.531088061538461 ], [ 29.764883275862069, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12476, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.531088061538461 ], [ 29.76515275862069, -1.531627015384615 ], [ 29.765422241379312, -1.531627015384615 ], [ 29.765422241379312, -1.531088061538461 ], [ 29.76515275862069, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12477, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.531088061538461 ], [ 29.766230689655174, -1.531627015384615 ], [ 29.766500172413792, -1.531627015384615 ], [ 29.766500172413792, -1.531088061538461 ], [ 29.766230689655174, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12478, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.531088061538461 ], [ 29.767039137931036, -1.531627015384615 ], [ 29.767308620689654, -1.531627015384615 ], [ 29.767308620689654, -1.531088061538461 ], [ 29.767039137931036, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12479, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.531088061538461 ], [ 29.767308620689654, -1.531627015384615 ], [ 29.767578103448276, -1.531627015384615 ], [ 29.767578103448276, -1.531088061538461 ], [ 29.767308620689654, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12480, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.531088061538461 ], [ 29.768117068965516, -1.531627015384615 ], [ 29.768386551724138, -1.531627015384615 ], [ 29.768386551724138, -1.531088061538461 ], [ 29.768117068965516, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12481, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.531088061538461 ], [ 29.768925517241382, -1.531357538461538 ], [ 29.769195, -1.531357538461538 ], [ 29.769195, -1.531088061538461 ], [ 29.768925517241382, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12482, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.531088061538461 ], [ 29.769195, -1.531357538461538 ], [ 29.769464482758622, -1.531357538461538 ], [ 29.769464482758622, -1.531088061538461 ], [ 29.769195, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12483, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.531088061538461 ], [ 29.769464482758622, -1.531357538461538 ], [ 29.769733965517243, -1.531357538461538 ], [ 29.769733965517243, -1.531088061538461 ], [ 29.769464482758622, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12484, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.531088061538461 ], [ 29.769733965517243, -1.531357538461538 ], [ 29.770003448275862, -1.531357538461538 ], [ 29.770003448275862, -1.531088061538461 ], [ 29.769733965517243, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12485, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.531088061538461 ], [ 29.770003448275862, -1.531357538461538 ], [ 29.770272931034484, -1.531357538461538 ], [ 29.770272931034484, -1.531088061538461 ], [ 29.770003448275862, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12486, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.531088061538461 ], [ 29.770272931034484, -1.531357538461538 ], [ 29.770542413793105, -1.531357538461538 ], [ 29.770542413793105, -1.531088061538461 ], [ 29.770272931034484, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12487, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.531088061538461 ], [ 29.770542413793105, -1.531357538461538 ], [ 29.770811896551724, -1.531357538461538 ], [ 29.770811896551724, -1.531088061538461 ], [ 29.770542413793105, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12488, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.531088061538461 ], [ 29.770811896551724, -1.531357538461538 ], [ 29.771081379310345, -1.531357538461538 ], [ 29.771081379310345, -1.531088061538461 ], [ 29.770811896551724, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12489, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.531088061538461 ], [ 29.771081379310345, -1.531627015384615 ], [ 29.771350862068967, -1.531627015384615 ], [ 29.771350862068967, -1.531088061538461 ], [ 29.771081379310345, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12490, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.531088061538461 ], [ 29.771350862068967, -1.531627015384615 ], [ 29.771620344827586, -1.531627015384615 ], [ 29.771620344827586, -1.531088061538461 ], [ 29.771350862068967, -1.531088061538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12491, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.531357538461538 ], [ 29.734162241379309, -1.531627015384615 ], [ 29.734701206896553, -1.531627015384615 ], [ 29.734701206896553, -1.531357538461538 ], [ 29.734162241379309, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12492, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734701206896553, -1.531357538461538 ], [ 29.734701206896553, -1.531627015384615 ], [ 29.734970689655171, -1.531627015384615 ], [ 29.734970689655171, -1.531357538461538 ], [ 29.734701206896553, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12493, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734970689655171, -1.531357538461538 ], [ 29.734970689655171, -1.531627015384615 ], [ 29.735240172413793, -1.531627015384615 ], [ 29.735240172413793, -1.531357538461538 ], [ 29.734970689655171, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12494, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.531357538461538 ], [ 29.735240172413793, -1.531627015384615 ], [ 29.735509655172415, -1.531627015384615 ], [ 29.735509655172415, -1.531357538461538 ], [ 29.735240172413793, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12495, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.531357538461538 ], [ 29.735509655172415, -1.532435446153846 ], [ 29.735779137931036, -1.532435446153846 ], [ 29.735779137931036, -1.531357538461538 ], [ 29.735509655172415, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12496, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.531357538461538 ], [ 29.735779137931036, -1.532435446153846 ], [ 29.736048620689655, -1.532435446153846 ], [ 29.736048620689655, -1.531357538461538 ], [ 29.735779137931036, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12497, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.531357538461538 ], [ 29.736318103448276, -1.531896492307692 ], [ 29.737126551724138, -1.531896492307692 ], [ 29.737126551724138, -1.531627015384615 ], [ 29.736587586206898, -1.531627015384615 ], [ 29.736587586206898, -1.531357538461538 ], [ 29.736318103448276, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12498, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.531357538461538 ], [ 29.737126551724138, -1.531627015384615 ], [ 29.73739603448276, -1.531627015384615 ], [ 29.73739603448276, -1.531357538461538 ], [ 29.737126551724138, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12499, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.531357538461538 ], [ 29.73739603448276, -1.531627015384615 ], [ 29.737665517241378, -1.531627015384615 ], [ 29.737665517241378, -1.531357538461538 ], [ 29.73739603448276, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12500, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.531357538461538 ], [ 29.737665517241378, -1.531627015384615 ], [ 29.737935, -1.531627015384615 ], [ 29.737935, -1.531357538461538 ], [ 29.737665517241378, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12501, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.531357538461538 ], [ 29.737935, -1.531627015384615 ], [ 29.738204482758622, -1.531627015384615 ], [ 29.738204482758622, -1.531357538461538 ], [ 29.737935, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12502, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.531357538461538 ], [ 29.738204482758622, -1.531627015384615 ], [ 29.738743448275862, -1.531627015384615 ], [ 29.738743448275862, -1.531357538461538 ], [ 29.738204482758622, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12503, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.531357538461538 ], [ 29.738743448275862, -1.531627015384615 ], [ 29.739012931034484, -1.531627015384615 ], [ 29.739012931034484, -1.531357538461538 ], [ 29.738743448275862, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12504, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.531357538461538 ], [ 29.74089931034483, -1.531627015384615 ], [ 29.741168793103448, -1.531627015384615 ], [ 29.741168793103448, -1.531357538461538 ], [ 29.74089931034483, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12505, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.531357538461538 ], [ 29.757876724137933, -1.531627015384615 ], [ 29.758146206896551, -1.531627015384615 ], [ 29.758146206896551, -1.531357538461538 ], [ 29.757876724137933, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12506, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.531357538461538 ], [ 29.758146206896551, -1.531627015384615 ], [ 29.758415689655173, -1.531627015384615 ], [ 29.758415689655173, -1.531357538461538 ], [ 29.758146206896551, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12507, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.531357538461538 ], [ 29.758415689655173, -1.531627015384615 ], [ 29.758954655172413, -1.531627015384615 ], [ 29.758954655172413, -1.531357538461538 ], [ 29.758415689655173, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12508, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.531357538461538 ], [ 29.758954655172413, -1.531627015384615 ], [ 29.759224137931035, -1.531627015384615 ], [ 29.759224137931035, -1.531357538461538 ], [ 29.758954655172413, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12509, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.531357538461538 ], [ 29.759224137931035, -1.531627015384615 ], [ 29.759493620689657, -1.531627015384615 ], [ 29.759493620689657, -1.531357538461538 ], [ 29.759224137931035, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12510, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.531357538461538 ], [ 29.759493620689657, -1.531627015384615 ], [ 29.760032586206897, -1.531627015384615 ], [ 29.760032586206897, -1.531357538461538 ], [ 29.759493620689657, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12511, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.531357538461538 ], [ 29.760032586206897, -1.531627015384615 ], [ 29.760571551724137, -1.531627015384615 ], [ 29.760571551724137, -1.531357538461538 ], [ 29.760032586206897, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12512, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.531357538461538 ], [ 29.760571551724137, -1.531627015384615 ], [ 29.760841034482759, -1.531627015384615 ], [ 29.760841034482759, -1.531357538461538 ], [ 29.760571551724137, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12513, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.531357538461538 ], [ 29.760841034482759, -1.531627015384615 ], [ 29.761110517241381, -1.531627015384615 ], [ 29.761110517241381, -1.531357538461538 ], [ 29.760841034482759, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12514, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.531357538461538 ], [ 29.761110517241381, -1.531627015384615 ], [ 29.76138, -1.531627015384615 ], [ 29.76138, -1.531357538461538 ], [ 29.761110517241381, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12515, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.531357538461538 ], [ 29.76138, -1.531627015384615 ], [ 29.761649482758621, -1.531627015384615 ], [ 29.761649482758621, -1.531357538461538 ], [ 29.76138, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12516, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.531357538461538 ], [ 29.761649482758621, -1.531627015384615 ], [ 29.761918965517243, -1.531627015384615 ], [ 29.761918965517243, -1.531357538461538 ], [ 29.761649482758621, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12517, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.531357538461538 ], [ 29.761918965517243, -1.531627015384615 ], [ 29.762188448275861, -1.531627015384615 ], [ 29.762188448275861, -1.531357538461538 ], [ 29.761918965517243, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12518, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.531357538461538 ], [ 29.762188448275861, -1.531627015384615 ], [ 29.762457931034483, -1.531627015384615 ], [ 29.762457931034483, -1.531357538461538 ], [ 29.762188448275861, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12519, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.531357538461538 ], [ 29.762457931034483, -1.531627015384615 ], [ 29.762727413793105, -1.531627015384615 ], [ 29.762727413793105, -1.531357538461538 ], [ 29.762457931034483, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12520, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.531357538461538 ], [ 29.762727413793105, -1.531627015384615 ], [ 29.762996896551723, -1.531627015384615 ], [ 29.762996896551723, -1.531357538461538 ], [ 29.762727413793105, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12521, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.531357538461538 ], [ 29.762996896551723, -1.531627015384615 ], [ 29.763266379310345, -1.531627015384615 ], [ 29.763266379310345, -1.531357538461538 ], [ 29.762996896551723, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12522, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.531357538461538 ], [ 29.763266379310345, -1.531627015384615 ], [ 29.763535862068967, -1.531627015384615 ], [ 29.763535862068967, -1.531357538461538 ], [ 29.763266379310345, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12523, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.531357538461538 ], [ 29.763535862068967, -1.531627015384615 ], [ 29.763805344827588, -1.531627015384615 ], [ 29.763805344827588, -1.531357538461538 ], [ 29.763535862068967, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12524, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.531357538461538 ], [ 29.764074827586207, -1.531896492307692 ], [ 29.764344310344828, -1.531896492307692 ], [ 29.764344310344828, -1.531357538461538 ], [ 29.764074827586207, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12525, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.531357538461538 ], [ 29.764344310344828, -1.531627015384615 ], [ 29.76461379310345, -1.531627015384615 ], [ 29.76461379310345, -1.531357538461538 ], [ 29.764344310344828, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12526, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.531357538461538 ], [ 29.76461379310345, -1.531627015384615 ], [ 29.764883275862069, -1.531627015384615 ], [ 29.764883275862069, -1.531357538461538 ], [ 29.76461379310345, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12527, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.531357538461538 ], [ 29.765422241379312, -1.531896492307692 ], [ 29.765961206896552, -1.531896492307692 ], [ 29.765961206896552, -1.531357538461538 ], [ 29.765422241379312, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12528, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.531357538461538 ], [ 29.766500172413792, -1.531896492307692 ], [ 29.766769655172414, -1.531896492307692 ], [ 29.766769655172414, -1.531357538461538 ], [ 29.766500172413792, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12529, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.531357538461538 ], [ 29.766769655172414, -1.531896492307692 ], [ 29.767039137931036, -1.531896492307692 ], [ 29.767039137931036, -1.531357538461538 ], [ 29.766769655172414, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12530, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.531357538461538 ], [ 29.767578103448276, -1.531627015384615 ], [ 29.767847586206898, -1.531627015384615 ], [ 29.767847586206898, -1.531357538461538 ], [ 29.767578103448276, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12531, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.531357538461538 ], [ 29.767847586206898, -1.531896492307692 ], [ 29.768117068965516, -1.531896492307692 ], [ 29.768117068965516, -1.531357538461538 ], [ 29.767847586206898, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12532, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.531357538461538 ], [ 29.768386551724138, -1.531627015384615 ], [ 29.76865603448276, -1.531627015384615 ], [ 29.76865603448276, -1.531357538461538 ], [ 29.768386551724138, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12533, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.531357538461538 ], [ 29.76865603448276, -1.531627015384615 ], [ 29.768925517241382, -1.531627015384615 ], [ 29.768925517241382, -1.531357538461538 ], [ 29.76865603448276, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12534, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.531357538461538 ], [ 29.768925517241382, -1.531627015384615 ], [ 29.769195, -1.531627015384615 ], [ 29.769195, -1.531357538461538 ], [ 29.768925517241382, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12535, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.531357538461538 ], [ 29.769195, -1.531627015384615 ], [ 29.769464482758622, -1.531627015384615 ], [ 29.769464482758622, -1.531357538461538 ], [ 29.769195, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12536, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.531357538461538 ], [ 29.769464482758622, -1.531627015384615 ], [ 29.769733965517243, -1.531627015384615 ], [ 29.769733965517243, -1.531357538461538 ], [ 29.769464482758622, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12537, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.531357538461538 ], [ 29.769733965517243, -1.531627015384615 ], [ 29.770003448275862, -1.531627015384615 ], [ 29.770003448275862, -1.531357538461538 ], [ 29.769733965517243, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12538, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.531357538461538 ], [ 29.770003448275862, -1.531627015384615 ], [ 29.770272931034484, -1.531627015384615 ], [ 29.770272931034484, -1.531357538461538 ], [ 29.770003448275862, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12539, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.531357538461538 ], [ 29.770272931034484, -1.531627015384615 ], [ 29.770542413793105, -1.531627015384615 ], [ 29.770542413793105, -1.531357538461538 ], [ 29.770272931034484, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12540, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.531357538461538 ], [ 29.770542413793105, -1.531627015384615 ], [ 29.770811896551724, -1.531627015384615 ], [ 29.770811896551724, -1.531357538461538 ], [ 29.770542413793105, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12541, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.531357538461538 ], [ 29.770811896551724, -1.531896492307692 ], [ 29.771081379310345, -1.531896492307692 ], [ 29.771081379310345, -1.531357538461538 ], [ 29.770811896551724, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12542, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.531357538461538 ], [ 29.771620344827586, -1.531627015384615 ], [ 29.771889827586207, -1.531627015384615 ], [ 29.771889827586207, -1.531357538461538 ], [ 29.771620344827586, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12543, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.531357538461538 ], [ 29.771889827586207, -1.531627015384615 ], [ 29.772159310344829, -1.531627015384615 ], [ 29.772159310344829, -1.531357538461538 ], [ 29.771889827586207, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12544, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734162241379309, -1.531627015384615 ], [ 29.734162241379309, -1.531896492307692 ], [ 29.734431724137931, -1.531896492307692 ], [ 29.734431724137931, -1.531627015384615 ], [ 29.734162241379309, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12545, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.734431724137931, -1.531627015384615 ], [ 29.734431724137931, -1.531896492307692 ], [ 29.734970689655171, -1.531896492307692 ], [ 29.734970689655171, -1.531627015384615 ], [ 29.734431724137931, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12546, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735240172413793, -1.531627015384615 ], [ 29.735240172413793, -1.532704923076923 ], [ 29.735509655172415, -1.532704923076923 ], [ 29.735509655172415, -1.531627015384615 ], [ 29.735240172413793, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12547, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.531627015384615 ], [ 29.736048620689655, -1.532165969230769 ], [ 29.736318103448276, -1.532165969230769 ], [ 29.736318103448276, -1.531627015384615 ], [ 29.736048620689655, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12548, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.531627015384615 ], [ 29.737126551724138, -1.531896492307692 ], [ 29.73739603448276, -1.531896492307692 ], [ 29.73739603448276, -1.531627015384615 ], [ 29.737126551724138, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12549, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.531627015384615 ], [ 29.73739603448276, -1.531896492307692 ], [ 29.737665517241378, -1.531896492307692 ], [ 29.737665517241378, -1.531627015384615 ], [ 29.73739603448276, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12550, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.531627015384615 ], [ 29.737665517241378, -1.531896492307692 ], [ 29.737935, -1.531896492307692 ], [ 29.737935, -1.531627015384615 ], [ 29.737665517241378, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12551, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.531627015384615 ], [ 29.737935, -1.531896492307692 ], [ 29.738204482758622, -1.531896492307692 ], [ 29.738204482758622, -1.531627015384615 ], [ 29.737935, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12552, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.531627015384615 ], [ 29.738204482758622, -1.531896492307692 ], [ 29.738743448275862, -1.531896492307692 ], [ 29.738743448275862, -1.531627015384615 ], [ 29.738204482758622, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12553, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.531627015384615 ], [ 29.738743448275862, -1.531896492307692 ], [ 29.739012931034484, -1.531896492307692 ], [ 29.739012931034484, -1.531627015384615 ], [ 29.738743448275862, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12554, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.531627015384615 ], [ 29.757607241379311, -1.532165969230769 ], [ 29.757876724137933, -1.532165969230769 ], [ 29.757876724137933, -1.531627015384615 ], [ 29.757607241379311, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12555, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.531627015384615 ], [ 29.757876724137933, -1.531896492307692 ], [ 29.758146206896551, -1.531896492307692 ], [ 29.758146206896551, -1.531627015384615 ], [ 29.757876724137933, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12556, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.531627015384615 ], [ 29.758146206896551, -1.531896492307692 ], [ 29.758415689655173, -1.531896492307692 ], [ 29.758415689655173, -1.531627015384615 ], [ 29.758146206896551, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12557, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.531627015384615 ], [ 29.758415689655173, -1.531896492307692 ], [ 29.758954655172413, -1.531896492307692 ], [ 29.758954655172413, -1.531627015384615 ], [ 29.758415689655173, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12558, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.531627015384615 ], [ 29.758954655172413, -1.531896492307692 ], [ 29.759224137931035, -1.531896492307692 ], [ 29.759224137931035, -1.531627015384615 ], [ 29.758954655172413, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12559, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.531627015384615 ], [ 29.759224137931035, -1.531896492307692 ], [ 29.759493620689657, -1.531896492307692 ], [ 29.759493620689657, -1.531627015384615 ], [ 29.759224137931035, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12560, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.531627015384615 ], [ 29.759493620689657, -1.531896492307692 ], [ 29.760302068965519, -1.531896492307692 ], [ 29.760302068965519, -1.531627015384615 ], [ 29.759493620689657, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12561, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.531627015384615 ], [ 29.760302068965519, -1.531896492307692 ], [ 29.760571551724137, -1.531896492307692 ], [ 29.760571551724137, -1.531627015384615 ], [ 29.760302068965519, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12562, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.531627015384615 ], [ 29.760571551724137, -1.531896492307692 ], [ 29.760841034482759, -1.531896492307692 ], [ 29.760841034482759, -1.531627015384615 ], [ 29.760571551724137, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12563, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.531627015384615 ], [ 29.760841034482759, -1.531896492307692 ], [ 29.761110517241381, -1.531896492307692 ], [ 29.761110517241381, -1.531627015384615 ], [ 29.760841034482759, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12564, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.531627015384615 ], [ 29.761110517241381, -1.531896492307692 ], [ 29.76138, -1.531896492307692 ], [ 29.76138, -1.531627015384615 ], [ 29.761110517241381, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12565, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.531627015384615 ], [ 29.76138, -1.531896492307692 ], [ 29.761649482758621, -1.531896492307692 ], [ 29.761649482758621, -1.531627015384615 ], [ 29.76138, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12566, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.531627015384615 ], [ 29.761649482758621, -1.531896492307692 ], [ 29.761918965517243, -1.531896492307692 ], [ 29.761918965517243, -1.531627015384615 ], [ 29.761649482758621, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12567, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.531627015384615 ], [ 29.761918965517243, -1.531896492307692 ], [ 29.762188448275861, -1.531896492307692 ], [ 29.762188448275861, -1.531627015384615 ], [ 29.761918965517243, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12568, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.531627015384615 ], [ 29.762188448275861, -1.531896492307692 ], [ 29.762457931034483, -1.531896492307692 ], [ 29.762457931034483, -1.531627015384615 ], [ 29.762188448275861, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12569, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.531627015384615 ], [ 29.762457931034483, -1.531896492307692 ], [ 29.762727413793105, -1.531896492307692 ], [ 29.762727413793105, -1.531627015384615 ], [ 29.762457931034483, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12570, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.531627015384615 ], [ 29.762727413793105, -1.531896492307692 ], [ 29.762996896551723, -1.531896492307692 ], [ 29.762996896551723, -1.531627015384615 ], [ 29.762727413793105, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12571, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.531627015384615 ], [ 29.762996896551723, -1.531896492307692 ], [ 29.763266379310345, -1.531896492307692 ], [ 29.763266379310345, -1.531627015384615 ], [ 29.762996896551723, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12572, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.531627015384615 ], [ 29.763266379310345, -1.531896492307692 ], [ 29.763535862068967, -1.531896492307692 ], [ 29.763535862068967, -1.531627015384615 ], [ 29.763266379310345, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12573, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.531627015384615 ], [ 29.763535862068967, -1.531896492307692 ], [ 29.763805344827588, -1.531896492307692 ], [ 29.763805344827588, -1.531627015384615 ], [ 29.763535862068967, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12574, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.531627015384615 ], [ 29.763805344827588, -1.531896492307692 ], [ 29.764074827586207, -1.531896492307692 ], [ 29.764074827586207, -1.531627015384615 ], [ 29.763805344827588, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12575, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.531627015384615 ], [ 29.764344310344828, -1.532165969230769 ], [ 29.76461379310345, -1.532165969230769 ], [ 29.76461379310345, -1.531627015384615 ], [ 29.764344310344828, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12576, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.531627015384615 ], [ 29.76461379310345, -1.531896492307692 ], [ 29.764883275862069, -1.531896492307692 ], [ 29.764883275862069, -1.531627015384615 ], [ 29.76461379310345, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12577, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.531627015384615 ], [ 29.764883275862069, -1.531896492307692 ], [ 29.76515275862069, -1.531896492307692 ], [ 29.76515275862069, -1.531627015384615 ], [ 29.764883275862069, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12578, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.531627015384615 ], [ 29.76515275862069, -1.531896492307692 ], [ 29.765422241379312, -1.531896492307692 ], [ 29.765422241379312, -1.531627015384615 ], [ 29.76515275862069, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12579, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.531627015384615 ], [ 29.765961206896552, -1.532165969230769 ], [ 29.766230689655174, -1.532165969230769 ], [ 29.766230689655174, -1.531627015384615 ], [ 29.765961206896552, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12580, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.531627015384615 ], [ 29.766230689655174, -1.532165969230769 ], [ 29.766500172413792, -1.532165969230769 ], [ 29.766500172413792, -1.531627015384615 ], [ 29.766230689655174, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12581, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.531627015384615 ], [ 29.767039137931036, -1.531896492307692 ], [ 29.767308620689654, -1.531896492307692 ], [ 29.767308620689654, -1.531627015384615 ], [ 29.767039137931036, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12582, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.531627015384615 ], [ 29.767308620689654, -1.531896492307692 ], [ 29.767578103448276, -1.531896492307692 ], [ 29.767578103448276, -1.531627015384615 ], [ 29.767308620689654, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12583, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.531627015384615 ], [ 29.767578103448276, -1.532165969230769 ], [ 29.767847586206898, -1.532165969230769 ], [ 29.767847586206898, -1.531627015384615 ], [ 29.767578103448276, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12584, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.531627015384615 ], [ 29.768117068965516, -1.531896492307692 ], [ 29.768386551724138, -1.531896492307692 ], [ 29.768386551724138, -1.531627015384615 ], [ 29.768117068965516, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12585, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.531627015384615 ], [ 29.768386551724138, -1.531896492307692 ], [ 29.76865603448276, -1.531896492307692 ], [ 29.76865603448276, -1.531627015384615 ], [ 29.768386551724138, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12586, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.531627015384615 ], [ 29.76865603448276, -1.531896492307692 ], [ 29.768925517241382, -1.531896492307692 ], [ 29.768925517241382, -1.531627015384615 ], [ 29.76865603448276, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12587, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.531627015384615 ], [ 29.768925517241382, -1.531896492307692 ], [ 29.769195, -1.531896492307692 ], [ 29.769195, -1.531627015384615 ], [ 29.768925517241382, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12588, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.531627015384615 ], [ 29.769195, -1.531896492307692 ], [ 29.769464482758622, -1.531896492307692 ], [ 29.769464482758622, -1.531627015384615 ], [ 29.769195, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12589, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.531627015384615 ], [ 29.769464482758622, -1.531896492307692 ], [ 29.769733965517243, -1.531896492307692 ], [ 29.769733965517243, -1.531627015384615 ], [ 29.769464482758622, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12590, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.531627015384615 ], [ 29.769733965517243, -1.531896492307692 ], [ 29.770003448275862, -1.531896492307692 ], [ 29.770003448275862, -1.531627015384615 ], [ 29.769733965517243, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12591, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.531627015384615 ], [ 29.770003448275862, -1.531896492307692 ], [ 29.770272931034484, -1.531896492307692 ], [ 29.770272931034484, -1.531627015384615 ], [ 29.770003448275862, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12592, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.531627015384615 ], [ 29.770272931034484, -1.531896492307692 ], [ 29.770542413793105, -1.531896492307692 ], [ 29.770542413793105, -1.531627015384615 ], [ 29.770272931034484, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12593, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.531627015384615 ], [ 29.770542413793105, -1.531896492307692 ], [ 29.770811896551724, -1.531896492307692 ], [ 29.770811896551724, -1.531627015384615 ], [ 29.770542413793105, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12594, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.531627015384615 ], [ 29.771081379310345, -1.531896492307692 ], [ 29.771350862068967, -1.531896492307692 ], [ 29.771350862068967, -1.531627015384615 ], [ 29.771081379310345, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12595, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.531627015384615 ], [ 29.771350862068967, -1.531896492307692 ], [ 29.771620344827586, -1.531896492307692 ], [ 29.771620344827586, -1.531627015384615 ], [ 29.771350862068967, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12596, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.531627015384615 ], [ 29.771620344827586, -1.531896492307692 ], [ 29.771889827586207, -1.531896492307692 ], [ 29.771889827586207, -1.531627015384615 ], [ 29.771620344827586, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12597, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.531627015384615 ], [ 29.771889827586207, -1.531896492307692 ], [ 29.772159310344829, -1.531896492307692 ], [ 29.772159310344829, -1.531627015384615 ], [ 29.771889827586207, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12598, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.531896492307692 ], [ 29.736318103448276, -1.532165969230769 ], [ 29.736587586206898, -1.532165969230769 ], [ 29.736587586206898, -1.531896492307692 ], [ 29.736318103448276, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12599, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.531896492307692 ], [ 29.736587586206898, -1.532165969230769 ], [ 29.736857068965517, -1.532165969230769 ], [ 29.736857068965517, -1.531896492307692 ], [ 29.736587586206898, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12600, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.531896492307692 ], [ 29.736857068965517, -1.532165969230769 ], [ 29.73739603448276, -1.532165969230769 ], [ 29.73739603448276, -1.531896492307692 ], [ 29.736857068965517, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12601, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.531896492307692 ], [ 29.73739603448276, -1.532165969230769 ], [ 29.737665517241378, -1.532165969230769 ], [ 29.737665517241378, -1.531896492307692 ], [ 29.73739603448276, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12602, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.531896492307692 ], [ 29.737665517241378, -1.532165969230769 ], [ 29.737935, -1.532165969230769 ], [ 29.737935, -1.531896492307692 ], [ 29.737665517241378, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12603, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.531896492307692 ], [ 29.737935, -1.532165969230769 ], [ 29.738204482758622, -1.532165969230769 ], [ 29.738204482758622, -1.531896492307692 ], [ 29.737935, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12604, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.531896492307692 ], [ 29.738204482758622, -1.532165969230769 ], [ 29.738743448275862, -1.532165969230769 ], [ 29.738743448275862, -1.531896492307692 ], [ 29.738204482758622, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12605, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.531896492307692 ], [ 29.738743448275862, -1.532165969230769 ], [ 29.739012931034484, -1.532165969230769 ], [ 29.739012931034484, -1.531896492307692 ], [ 29.738743448275862, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12606, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.531896492307692 ], [ 29.739012931034484, -1.532165969230769 ], [ 29.739282413793102, -1.532165969230769 ], [ 29.739282413793102, -1.531896492307692 ], [ 29.739012931034484, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12607, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.531896492307692 ], [ 29.757876724137933, -1.532435446153846 ], [ 29.758146206896551, -1.532435446153846 ], [ 29.758146206896551, -1.531896492307692 ], [ 29.757876724137933, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12608, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.531896492307692 ], [ 29.758146206896551, -1.532435446153846 ], [ 29.758415689655173, -1.532435446153846 ], [ 29.758415689655173, -1.531896492307692 ], [ 29.758146206896551, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12609, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.531896492307692 ], [ 29.758415689655173, -1.532165969230769 ], [ 29.758685172413795, -1.532165969230769 ], [ 29.758685172413795, -1.531896492307692 ], [ 29.758415689655173, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12610, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.531896492307692 ], [ 29.758685172413795, -1.532165969230769 ], [ 29.759224137931035, -1.532165969230769 ], [ 29.759224137931035, -1.531896492307692 ], [ 29.758685172413795, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12611, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.531896492307692 ], [ 29.759224137931035, -1.532165969230769 ], [ 29.759493620689657, -1.532165969230769 ], [ 29.759493620689657, -1.531896492307692 ], [ 29.759224137931035, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12612, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.531896492307692 ], [ 29.759493620689657, -1.532165969230769 ], [ 29.759763103448275, -1.532165969230769 ], [ 29.759763103448275, -1.531896492307692 ], [ 29.759493620689657, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12613, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.531896492307692 ], [ 29.759763103448275, -1.532165969230769 ], [ 29.760302068965519, -1.532165969230769 ], [ 29.760302068965519, -1.531896492307692 ], [ 29.759763103448275, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12614, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.531896492307692 ], [ 29.760302068965519, -1.532165969230769 ], [ 29.760571551724137, -1.532165969230769 ], [ 29.760571551724137, -1.531896492307692 ], [ 29.760302068965519, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12615, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.531896492307692 ], [ 29.760571551724137, -1.532165969230769 ], [ 29.760841034482759, -1.532165969230769 ], [ 29.760841034482759, -1.531896492307692 ], [ 29.760571551724137, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12616, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.531896492307692 ], [ 29.760841034482759, -1.532165969230769 ], [ 29.761110517241381, -1.532165969230769 ], [ 29.761110517241381, -1.531896492307692 ], [ 29.760841034482759, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12617, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.531896492307692 ], [ 29.761110517241381, -1.532165969230769 ], [ 29.76138, -1.532165969230769 ], [ 29.76138, -1.531896492307692 ], [ 29.761110517241381, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12618, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.531896492307692 ], [ 29.76138, -1.532165969230769 ], [ 29.761649482758621, -1.532165969230769 ], [ 29.761649482758621, -1.531896492307692 ], [ 29.76138, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12619, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.531896492307692 ], [ 29.761649482758621, -1.532165969230769 ], [ 29.761918965517243, -1.532165969230769 ], [ 29.761918965517243, -1.531896492307692 ], [ 29.761649482758621, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12620, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.531896492307692 ], [ 29.761918965517243, -1.532165969230769 ], [ 29.762188448275861, -1.532165969230769 ], [ 29.762188448275861, -1.531896492307692 ], [ 29.761918965517243, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12621, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.531896492307692 ], [ 29.762188448275861, -1.532165969230769 ], [ 29.762457931034483, -1.532165969230769 ], [ 29.762457931034483, -1.531896492307692 ], [ 29.762188448275861, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12622, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.531896492307692 ], [ 29.762457931034483, -1.532165969230769 ], [ 29.762727413793105, -1.532165969230769 ], [ 29.762727413793105, -1.531896492307692 ], [ 29.762457931034483, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12623, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.531896492307692 ], [ 29.762727413793105, -1.532165969230769 ], [ 29.762996896551723, -1.532165969230769 ], [ 29.762996896551723, -1.531896492307692 ], [ 29.762727413793105, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12624, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.531896492307692 ], [ 29.762996896551723, -1.532165969230769 ], [ 29.763266379310345, -1.532165969230769 ], [ 29.763266379310345, -1.531896492307692 ], [ 29.762996896551723, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12625, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.531896492307692 ], [ 29.763266379310345, -1.532165969230769 ], [ 29.763535862068967, -1.532165969230769 ], [ 29.763535862068967, -1.531896492307692 ], [ 29.763266379310345, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12626, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.531896492307692 ], [ 29.763535862068967, -1.532165969230769 ], [ 29.763805344827588, -1.532165969230769 ], [ 29.763805344827588, -1.531896492307692 ], [ 29.763535862068967, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12627, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.531896492307692 ], [ 29.763805344827588, -1.532165969230769 ], [ 29.764074827586207, -1.532165969230769 ], [ 29.764074827586207, -1.531896492307692 ], [ 29.763805344827588, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12628, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.531896492307692 ], [ 29.764074827586207, -1.532165969230769 ], [ 29.764344310344828, -1.532165969230769 ], [ 29.764344310344828, -1.531896492307692 ], [ 29.764074827586207, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12629, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.531896492307692 ], [ 29.76461379310345, -1.532165969230769 ], [ 29.764883275862069, -1.532165969230769 ], [ 29.764883275862069, -1.531896492307692 ], [ 29.76461379310345, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12630, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.531896492307692 ], [ 29.764883275862069, -1.532165969230769 ], [ 29.76515275862069, -1.532165969230769 ], [ 29.76515275862069, -1.531896492307692 ], [ 29.764883275862069, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12631, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.531896492307692 ], [ 29.76515275862069, -1.532165969230769 ], [ 29.765422241379312, -1.532165969230769 ], [ 29.765422241379312, -1.531896492307692 ], [ 29.76515275862069, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12632, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.531896492307692 ], [ 29.765422241379312, -1.532165969230769 ], [ 29.765961206896552, -1.532165969230769 ], [ 29.765961206896552, -1.531896492307692 ], [ 29.765422241379312, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12633, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.531896492307692 ], [ 29.766500172413792, -1.532165969230769 ], [ 29.766769655172414, -1.532165969230769 ], [ 29.766769655172414, -1.531896492307692 ], [ 29.766500172413792, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12634, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.531896492307692 ], [ 29.766769655172414, -1.532165969230769 ], [ 29.767039137931036, -1.532165969230769 ], [ 29.767039137931036, -1.531896492307692 ], [ 29.766769655172414, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12635, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.531896492307692 ], [ 29.767039137931036, -1.532165969230769 ], [ 29.767308620689654, -1.532165969230769 ], [ 29.767308620689654, -1.531896492307692 ], [ 29.767039137931036, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12636, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.531896492307692 ], [ 29.767308620689654, -1.532435446153846 ], [ 29.767578103448276, -1.532435446153846 ], [ 29.767578103448276, -1.531896492307692 ], [ 29.767308620689654, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12637, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.531896492307692 ], [ 29.767847586206898, -1.532165969230769 ], [ 29.768117068965516, -1.532165969230769 ], [ 29.768117068965516, -1.531896492307692 ], [ 29.767847586206898, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12638, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.531896492307692 ], [ 29.768117068965516, -1.532165969230769 ], [ 29.768386551724138, -1.532165969230769 ], [ 29.768386551724138, -1.531896492307692 ], [ 29.768117068965516, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12639, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.531896492307692 ], [ 29.768386551724138, -1.532165969230769 ], [ 29.76865603448276, -1.532165969230769 ], [ 29.76865603448276, -1.531896492307692 ], [ 29.768386551724138, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12640, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.531896492307692 ], [ 29.76865603448276, -1.532165969230769 ], [ 29.768925517241382, -1.532165969230769 ], [ 29.768925517241382, -1.531896492307692 ], [ 29.76865603448276, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12641, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.531896492307692 ], [ 29.768925517241382, -1.532165969230769 ], [ 29.769195, -1.532165969230769 ], [ 29.769195, -1.531896492307692 ], [ 29.768925517241382, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12642, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.531896492307692 ], [ 29.769195, -1.532165969230769 ], [ 29.769464482758622, -1.532165969230769 ], [ 29.769464482758622, -1.531896492307692 ], [ 29.769195, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12643, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.531896492307692 ], [ 29.769464482758622, -1.532165969230769 ], [ 29.769733965517243, -1.532165969230769 ], [ 29.769733965517243, -1.531896492307692 ], [ 29.769464482758622, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12644, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.531896492307692 ], [ 29.769733965517243, -1.532165969230769 ], [ 29.770003448275862, -1.532165969230769 ], [ 29.770003448275862, -1.531896492307692 ], [ 29.769733965517243, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12645, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.531896492307692 ], [ 29.770003448275862, -1.532165969230769 ], [ 29.770272931034484, -1.532165969230769 ], [ 29.770272931034484, -1.531896492307692 ], [ 29.770003448275862, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12646, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.531896492307692 ], [ 29.770272931034484, -1.532165969230769 ], [ 29.770542413793105, -1.532165969230769 ], [ 29.770542413793105, -1.531896492307692 ], [ 29.770272931034484, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12647, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.531896492307692 ], [ 29.770542413793105, -1.532165969230769 ], [ 29.770811896551724, -1.532165969230769 ], [ 29.770811896551724, -1.531896492307692 ], [ 29.770542413793105, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12648, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.531896492307692 ], [ 29.770811896551724, -1.532165969230769 ], [ 29.771081379310345, -1.532165969230769 ], [ 29.771081379310345, -1.531896492307692 ], [ 29.770811896551724, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12649, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.531896492307692 ], [ 29.771081379310345, -1.532165969230769 ], [ 29.771350862068967, -1.532165969230769 ], [ 29.771350862068967, -1.531896492307692 ], [ 29.771081379310345, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12650, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.531896492307692 ], [ 29.771350862068967, -1.532165969230769 ], [ 29.771620344827586, -1.532165969230769 ], [ 29.771620344827586, -1.531896492307692 ], [ 29.771350862068967, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12651, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.532165969230769 ], [ 29.736048620689655, -1.532435446153846 ], [ 29.736318103448276, -1.532435446153846 ], [ 29.736318103448276, -1.532165969230769 ], [ 29.736048620689655, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12652, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.532165969230769 ], [ 29.736318103448276, -1.532435446153846 ], [ 29.737126551724138, -1.532435446153846 ], [ 29.737126551724138, -1.532165969230769 ], [ 29.736318103448276, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12653, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.532165969230769 ], [ 29.737126551724138, -1.532435446153846 ], [ 29.73739603448276, -1.532435446153846 ], [ 29.73739603448276, -1.532165969230769 ], [ 29.737126551724138, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12654, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.532165969230769 ], [ 29.73739603448276, -1.532435446153846 ], [ 29.737935, -1.532435446153846 ], [ 29.737935, -1.532165969230769 ], [ 29.73739603448276, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12655, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.532165969230769 ], [ 29.737935, -1.532435446153846 ], [ 29.738204482758622, -1.532435446153846 ], [ 29.738204482758622, -1.532165969230769 ], [ 29.737935, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12656, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.532165969230769 ], [ 29.738204482758622, -1.532435446153846 ], [ 29.738743448275862, -1.532435446153846 ], [ 29.738743448275862, -1.532165969230769 ], [ 29.738204482758622, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12657, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.532165969230769 ], [ 29.738743448275862, -1.532435446153846 ], [ 29.739012931034484, -1.532435446153846 ], [ 29.739012931034484, -1.532165969230769 ], [ 29.738743448275862, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12658, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.531357538461538 ], [ 29.739551896551724, -1.531357538461538 ], [ 29.739551896551724, -1.5329744 ], [ 29.739282413793102, -1.5329744 ], [ 29.739282413793102, -1.532435446153846 ], [ 29.739012931034484, -1.532435446153846 ], [ 29.739012931034484, -1.532165969230769 ], [ 29.739282413793102, -1.532165969230769 ], [ 29.739282413793102, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12659, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.532165969230769 ], [ 29.75733775862069, -1.532704923076923 ], [ 29.757607241379311, -1.532704923076923 ], [ 29.757607241379311, -1.532165969230769 ], [ 29.75733775862069, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12660, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.532165969230769 ], [ 29.757607241379311, -1.532435446153846 ], [ 29.757876724137933, -1.532435446153846 ], [ 29.757876724137933, -1.532165969230769 ], [ 29.757607241379311, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12661, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.532165969230769 ], [ 29.758415689655173, -1.532704923076923 ], [ 29.758685172413795, -1.532704923076923 ], [ 29.758685172413795, -1.532165969230769 ], [ 29.758415689655173, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12662, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.532165969230769 ], [ 29.758685172413795, -1.532435446153846 ], [ 29.758954655172413, -1.532435446153846 ], [ 29.758954655172413, -1.532165969230769 ], [ 29.758685172413795, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12663, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.532165969230769 ], [ 29.758954655172413, -1.532435446153846 ], [ 29.759224137931035, -1.532435446153846 ], [ 29.759224137931035, -1.532165969230769 ], [ 29.758954655172413, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12664, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.532165969230769 ], [ 29.759224137931035, -1.532435446153846 ], [ 29.759493620689657, -1.532435446153846 ], [ 29.759493620689657, -1.532165969230769 ], [ 29.759224137931035, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12665, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.532165969230769 ], [ 29.759493620689657, -1.532435446153846 ], [ 29.759763103448275, -1.532435446153846 ], [ 29.759763103448275, -1.532165969230769 ], [ 29.759493620689657, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12666, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.532165969230769 ], [ 29.759763103448275, -1.532435446153846 ], [ 29.760302068965519, -1.532435446153846 ], [ 29.760302068965519, -1.532165969230769 ], [ 29.759763103448275, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12667, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.532165969230769 ], [ 29.760302068965519, -1.532435446153846 ], [ 29.760571551724137, -1.532435446153846 ], [ 29.760571551724137, -1.532165969230769 ], [ 29.760302068965519, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12668, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.532165969230769 ], [ 29.760571551724137, -1.532435446153846 ], [ 29.760841034482759, -1.532435446153846 ], [ 29.760841034482759, -1.532165969230769 ], [ 29.760571551724137, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12669, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.532165969230769 ], [ 29.760841034482759, -1.532435446153846 ], [ 29.761110517241381, -1.532435446153846 ], [ 29.761110517241381, -1.532165969230769 ], [ 29.760841034482759, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12670, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.532165969230769 ], [ 29.761110517241381, -1.532435446153846 ], [ 29.76138, -1.532435446153846 ], [ 29.76138, -1.532165969230769 ], [ 29.761110517241381, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12671, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.532165969230769 ], [ 29.76138, -1.532435446153846 ], [ 29.761649482758621, -1.532435446153846 ], [ 29.761649482758621, -1.532165969230769 ], [ 29.76138, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12672, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.532165969230769 ], [ 29.761649482758621, -1.532435446153846 ], [ 29.761918965517243, -1.532435446153846 ], [ 29.761918965517243, -1.532165969230769 ], [ 29.761649482758621, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12673, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.532165969230769 ], [ 29.761918965517243, -1.532435446153846 ], [ 29.762188448275861, -1.532435446153846 ], [ 29.762188448275861, -1.532165969230769 ], [ 29.761918965517243, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12674, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.532165969230769 ], [ 29.762188448275861, -1.532435446153846 ], [ 29.762457931034483, -1.532435446153846 ], [ 29.762457931034483, -1.532165969230769 ], [ 29.762188448275861, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12675, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.532165969230769 ], [ 29.762457931034483, -1.532435446153846 ], [ 29.762727413793105, -1.532435446153846 ], [ 29.762727413793105, -1.532165969230769 ], [ 29.762457931034483, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12676, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.532165969230769 ], [ 29.762727413793105, -1.532435446153846 ], [ 29.762996896551723, -1.532435446153846 ], [ 29.762996896551723, -1.532165969230769 ], [ 29.762727413793105, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12677, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.532165969230769 ], [ 29.762996896551723, -1.532435446153846 ], [ 29.763266379310345, -1.532435446153846 ], [ 29.763266379310345, -1.532165969230769 ], [ 29.762996896551723, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12678, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.532165969230769 ], [ 29.763266379310345, -1.532435446153846 ], [ 29.763535862068967, -1.532435446153846 ], [ 29.763535862068967, -1.532165969230769 ], [ 29.763266379310345, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12679, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.532165969230769 ], [ 29.763535862068967, -1.532435446153846 ], [ 29.763805344827588, -1.532435446153846 ], [ 29.763805344827588, -1.532165969230769 ], [ 29.763535862068967, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12680, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.532165969230769 ], [ 29.763805344827588, -1.532435446153846 ], [ 29.764074827586207, -1.532435446153846 ], [ 29.764074827586207, -1.532165969230769 ], [ 29.763805344827588, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12681, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.532165969230769 ], [ 29.764074827586207, -1.532435446153846 ], [ 29.764344310344828, -1.532435446153846 ], [ 29.764344310344828, -1.532165969230769 ], [ 29.764074827586207, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12682, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.532165969230769 ], [ 29.764344310344828, -1.532435446153846 ], [ 29.76461379310345, -1.532435446153846 ], [ 29.76461379310345, -1.532165969230769 ], [ 29.764344310344828, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12683, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.532165969230769 ], [ 29.76461379310345, -1.532435446153846 ], [ 29.764883275862069, -1.532435446153846 ], [ 29.764883275862069, -1.532165969230769 ], [ 29.76461379310345, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12684, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.532165969230769 ], [ 29.764883275862069, -1.532435446153846 ], [ 29.76515275862069, -1.532435446153846 ], [ 29.76515275862069, -1.532165969230769 ], [ 29.764883275862069, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12685, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.532165969230769 ], [ 29.76515275862069, -1.532435446153846 ], [ 29.765422241379312, -1.532435446153846 ], [ 29.765422241379312, -1.532165969230769 ], [ 29.76515275862069, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12686, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.532165969230769 ], [ 29.765422241379312, -1.532435446153846 ], [ 29.765961206896552, -1.532435446153846 ], [ 29.765961206896552, -1.532165969230769 ], [ 29.765422241379312, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12687, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.532165969230769 ], [ 29.765961206896552, -1.532435446153846 ], [ 29.766230689655174, -1.532435446153846 ], [ 29.766230689655174, -1.532165969230769 ], [ 29.765961206896552, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12688, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.532165969230769 ], [ 29.766230689655174, -1.532435446153846 ], [ 29.766500172413792, -1.532435446153846 ], [ 29.766500172413792, -1.532165969230769 ], [ 29.766230689655174, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12689, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.532165969230769 ], [ 29.766500172413792, -1.532435446153846 ], [ 29.766769655172414, -1.532435446153846 ], [ 29.766769655172414, -1.532165969230769 ], [ 29.766500172413792, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12690, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.532165969230769 ], [ 29.766769655172414, -1.532435446153846 ], [ 29.767039137931036, -1.532435446153846 ], [ 29.767039137931036, -1.532165969230769 ], [ 29.766769655172414, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12691, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.532165969230769 ], [ 29.767039137931036, -1.532435446153846 ], [ 29.767308620689654, -1.532435446153846 ], [ 29.767308620689654, -1.532165969230769 ], [ 29.767039137931036, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12692, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.532165969230769 ], [ 29.767578103448276, -1.532435446153846 ], [ 29.767847586206898, -1.532435446153846 ], [ 29.767847586206898, -1.532165969230769 ], [ 29.767578103448276, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12693, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.532165969230769 ], [ 29.767847586206898, -1.532435446153846 ], [ 29.768117068965516, -1.532435446153846 ], [ 29.768117068965516, -1.532165969230769 ], [ 29.767847586206898, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12694, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.532165969230769 ], [ 29.768117068965516, -1.532435446153846 ], [ 29.768386551724138, -1.532435446153846 ], [ 29.768386551724138, -1.532165969230769 ], [ 29.768117068965516, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12695, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.532165969230769 ], [ 29.768386551724138, -1.532435446153846 ], [ 29.76865603448276, -1.532435446153846 ], [ 29.76865603448276, -1.532165969230769 ], [ 29.768386551724138, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12696, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.532165969230769 ], [ 29.76865603448276, -1.532435446153846 ], [ 29.768925517241382, -1.532435446153846 ], [ 29.768925517241382, -1.532165969230769 ], [ 29.76865603448276, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12697, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.532165969230769 ], [ 29.768925517241382, -1.532435446153846 ], [ 29.769195, -1.532435446153846 ], [ 29.769195, -1.532165969230769 ], [ 29.768925517241382, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12698, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.532165969230769 ], [ 29.769195, -1.532435446153846 ], [ 29.769464482758622, -1.532435446153846 ], [ 29.769464482758622, -1.532165969230769 ], [ 29.769195, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12699, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.532165969230769 ], [ 29.769464482758622, -1.532435446153846 ], [ 29.769733965517243, -1.532435446153846 ], [ 29.769733965517243, -1.532165969230769 ], [ 29.769464482758622, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12700, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.532165969230769 ], [ 29.769733965517243, -1.532435446153846 ], [ 29.770003448275862, -1.532435446153846 ], [ 29.770003448275862, -1.532165969230769 ], [ 29.769733965517243, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12701, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.532165969230769 ], [ 29.770003448275862, -1.532435446153846 ], [ 29.770272931034484, -1.532435446153846 ], [ 29.770272931034484, -1.532165969230769 ], [ 29.770003448275862, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12702, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.532165969230769 ], [ 29.770272931034484, -1.532435446153846 ], [ 29.770542413793105, -1.532435446153846 ], [ 29.770542413793105, -1.532165969230769 ], [ 29.770272931034484, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12703, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.532165969230769 ], [ 29.770542413793105, -1.532435446153846 ], [ 29.770811896551724, -1.532435446153846 ], [ 29.770811896551724, -1.532165969230769 ], [ 29.770542413793105, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12704, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.532165969230769 ], [ 29.770811896551724, -1.532435446153846 ], [ 29.771081379310345, -1.532435446153846 ], [ 29.771081379310345, -1.532165969230769 ], [ 29.770811896551724, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12705, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.532435446153846 ], [ 29.735509655172415, -1.532704923076923 ], [ 29.735779137931036, -1.532704923076923 ], [ 29.735779137931036, -1.532435446153846 ], [ 29.735509655172415, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12706, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.532435446153846 ], [ 29.735779137931036, -1.5329744 ], [ 29.736318103448276, -1.5329744 ], [ 29.736318103448276, -1.532704923076923 ], [ 29.736048620689655, -1.532704923076923 ], [ 29.736048620689655, -1.532435446153846 ], [ 29.735779137931036, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12707, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.532435446153846 ], [ 29.736048620689655, -1.532704923076923 ], [ 29.736318103448276, -1.532704923076923 ], [ 29.736318103448276, -1.532435446153846 ], [ 29.736048620689655, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12708, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.532435446153846 ], [ 29.736318103448276, -1.532704923076923 ], [ 29.737126551724138, -1.532704923076923 ], [ 29.737126551724138, -1.532435446153846 ], [ 29.736318103448276, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12709, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.532435446153846 ], [ 29.737126551724138, -1.532704923076923 ], [ 29.737665517241378, -1.532704923076923 ], [ 29.737665517241378, -1.532435446153846 ], [ 29.737126551724138, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12710, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.532435446153846 ], [ 29.737665517241378, -1.532704923076923 ], [ 29.737935, -1.532704923076923 ], [ 29.737935, -1.532435446153846 ], [ 29.737665517241378, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12711, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.532435446153846 ], [ 29.737935, -1.532704923076923 ], [ 29.738204482758622, -1.532704923076923 ], [ 29.738204482758622, -1.532435446153846 ], [ 29.737935, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12712, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.532435446153846 ], [ 29.738204482758622, -1.532704923076923 ], [ 29.738743448275862, -1.532704923076923 ], [ 29.738743448275862, -1.532435446153846 ], [ 29.738204482758622, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12713, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.532435446153846 ], [ 29.738743448275862, -1.532704923076923 ], [ 29.739012931034484, -1.532704923076923 ], [ 29.739012931034484, -1.532435446153846 ], [ 29.738743448275862, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12714, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.532435446153846 ], [ 29.739012931034484, -1.532704923076923 ], [ 29.739282413793102, -1.532704923076923 ], [ 29.739282413793102, -1.532435446153846 ], [ 29.739012931034484, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12715, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.532435446153846 ], [ 29.757607241379311, -1.5329744 ], [ 29.757876724137933, -1.5329744 ], [ 29.757876724137933, -1.532435446153846 ], [ 29.757607241379311, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12716, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.532435446153846 ], [ 29.757876724137933, -1.5329744 ], [ 29.758146206896551, -1.5329744 ], [ 29.758146206896551, -1.532435446153846 ], [ 29.757876724137933, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12717, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.532435446153846 ], [ 29.758146206896551, -1.532704923076923 ], [ 29.758415689655173, -1.532704923076923 ], [ 29.758415689655173, -1.532435446153846 ], [ 29.758146206896551, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12718, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.532435446153846 ], [ 29.758685172413795, -1.532704923076923 ], [ 29.758954655172413, -1.532704923076923 ], [ 29.758954655172413, -1.532435446153846 ], [ 29.758685172413795, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12719, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.532435446153846 ], [ 29.758954655172413, -1.532704923076923 ], [ 29.759224137931035, -1.532704923076923 ], [ 29.759224137931035, -1.532435446153846 ], [ 29.758954655172413, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12720, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.532435446153846 ], [ 29.759224137931035, -1.532704923076923 ], [ 29.759493620689657, -1.532704923076923 ], [ 29.759493620689657, -1.532435446153846 ], [ 29.759224137931035, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12721, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.532435446153846 ], [ 29.759493620689657, -1.532704923076923 ], [ 29.759763103448275, -1.532704923076923 ], [ 29.759763103448275, -1.532435446153846 ], [ 29.759493620689657, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12722, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.532435446153846 ], [ 29.759763103448275, -1.532704923076923 ], [ 29.760032586206897, -1.532704923076923 ], [ 29.760032586206897, -1.532435446153846 ], [ 29.759763103448275, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12723, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.532435446153846 ], [ 29.760032586206897, -1.532704923076923 ], [ 29.760571551724137, -1.532704923076923 ], [ 29.760571551724137, -1.532435446153846 ], [ 29.760032586206897, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12724, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.532435446153846 ], [ 29.760571551724137, -1.532704923076923 ], [ 29.760841034482759, -1.532704923076923 ], [ 29.760841034482759, -1.532435446153846 ], [ 29.760571551724137, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12725, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.532435446153846 ], [ 29.760841034482759, -1.532704923076923 ], [ 29.761110517241381, -1.532704923076923 ], [ 29.761110517241381, -1.532435446153846 ], [ 29.760841034482759, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12726, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.532435446153846 ], [ 29.761110517241381, -1.532704923076923 ], [ 29.76138, -1.532704923076923 ], [ 29.76138, -1.532435446153846 ], [ 29.761110517241381, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12727, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.532435446153846 ], [ 29.76138, -1.532704923076923 ], [ 29.761649482758621, -1.532704923076923 ], [ 29.761649482758621, -1.532435446153846 ], [ 29.76138, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12728, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.532435446153846 ], [ 29.761649482758621, -1.532704923076923 ], [ 29.761918965517243, -1.532704923076923 ], [ 29.761918965517243, -1.532435446153846 ], [ 29.761649482758621, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12729, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.532435446153846 ], [ 29.761918965517243, -1.532704923076923 ], [ 29.762188448275861, -1.532704923076923 ], [ 29.762188448275861, -1.532435446153846 ], [ 29.761918965517243, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12730, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.532435446153846 ], [ 29.762188448275861, -1.532704923076923 ], [ 29.762457931034483, -1.532704923076923 ], [ 29.762457931034483, -1.532435446153846 ], [ 29.762188448275861, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12731, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.532435446153846 ], [ 29.762457931034483, -1.532704923076923 ], [ 29.762727413793105, -1.532704923076923 ], [ 29.762727413793105, -1.532435446153846 ], [ 29.762457931034483, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12732, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.532435446153846 ], [ 29.762727413793105, -1.532704923076923 ], [ 29.762996896551723, -1.532704923076923 ], [ 29.762996896551723, -1.532435446153846 ], [ 29.762727413793105, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12733, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.532435446153846 ], [ 29.762996896551723, -1.532704923076923 ], [ 29.763266379310345, -1.532704923076923 ], [ 29.763266379310345, -1.532435446153846 ], [ 29.762996896551723, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12734, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.532435446153846 ], [ 29.763266379310345, -1.532704923076923 ], [ 29.763535862068967, -1.532704923076923 ], [ 29.763535862068967, -1.532435446153846 ], [ 29.763266379310345, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12735, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.532435446153846 ], [ 29.763535862068967, -1.532704923076923 ], [ 29.763805344827588, -1.532704923076923 ], [ 29.763805344827588, -1.532435446153846 ], [ 29.763535862068967, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12736, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.532435446153846 ], [ 29.763805344827588, -1.532704923076923 ], [ 29.764074827586207, -1.532704923076923 ], [ 29.764074827586207, -1.532435446153846 ], [ 29.763805344827588, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12737, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.532435446153846 ], [ 29.764074827586207, -1.532704923076923 ], [ 29.764344310344828, -1.532704923076923 ], [ 29.764344310344828, -1.532435446153846 ], [ 29.764074827586207, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12738, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.532435446153846 ], [ 29.764344310344828, -1.532704923076923 ], [ 29.76461379310345, -1.532704923076923 ], [ 29.76461379310345, -1.532435446153846 ], [ 29.764344310344828, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12739, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.532435446153846 ], [ 29.76461379310345, -1.532704923076923 ], [ 29.764883275862069, -1.532704923076923 ], [ 29.764883275862069, -1.532435446153846 ], [ 29.76461379310345, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12740, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.532435446153846 ], [ 29.764883275862069, -1.532704923076923 ], [ 29.76515275862069, -1.532704923076923 ], [ 29.76515275862069, -1.532435446153846 ], [ 29.764883275862069, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12741, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.532435446153846 ], [ 29.76515275862069, -1.532704923076923 ], [ 29.765422241379312, -1.532704923076923 ], [ 29.765422241379312, -1.532435446153846 ], [ 29.76515275862069, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12742, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.532435446153846 ], [ 29.765422241379312, -1.532704923076923 ], [ 29.765961206896552, -1.532704923076923 ], [ 29.765961206896552, -1.532435446153846 ], [ 29.765422241379312, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12743, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.532435446153846 ], [ 29.765961206896552, -1.532704923076923 ], [ 29.766230689655174, -1.532704923076923 ], [ 29.766230689655174, -1.532435446153846 ], [ 29.765961206896552, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12744, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.532435446153846 ], [ 29.766230689655174, -1.532704923076923 ], [ 29.766500172413792, -1.532704923076923 ], [ 29.766500172413792, -1.532435446153846 ], [ 29.766230689655174, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12745, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.532435446153846 ], [ 29.766500172413792, -1.532704923076923 ], [ 29.766769655172414, -1.532704923076923 ], [ 29.766769655172414, -1.532435446153846 ], [ 29.766500172413792, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12746, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.532435446153846 ], [ 29.766769655172414, -1.532704923076923 ], [ 29.767039137931036, -1.532704923076923 ], [ 29.767039137931036, -1.532435446153846 ], [ 29.766769655172414, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12747, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.532435446153846 ], [ 29.767039137931036, -1.532704923076923 ], [ 29.767308620689654, -1.532704923076923 ], [ 29.767308620689654, -1.532435446153846 ], [ 29.767039137931036, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12748, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.532435446153846 ], [ 29.767308620689654, -1.532704923076923 ], [ 29.767578103448276, -1.532704923076923 ], [ 29.767578103448276, -1.532435446153846 ], [ 29.767308620689654, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12749, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.532435446153846 ], [ 29.767578103448276, -1.532704923076923 ], [ 29.767847586206898, -1.532704923076923 ], [ 29.767847586206898, -1.532435446153846 ], [ 29.767578103448276, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12750, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.532435446153846 ], [ 29.767847586206898, -1.532704923076923 ], [ 29.768117068965516, -1.532704923076923 ], [ 29.768117068965516, -1.532435446153846 ], [ 29.767847586206898, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12751, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.532435446153846 ], [ 29.768117068965516, -1.532704923076923 ], [ 29.768386551724138, -1.532704923076923 ], [ 29.768386551724138, -1.532435446153846 ], [ 29.768117068965516, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12752, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.532435446153846 ], [ 29.768386551724138, -1.532704923076923 ], [ 29.76865603448276, -1.532704923076923 ], [ 29.76865603448276, -1.532435446153846 ], [ 29.768386551724138, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12753, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.532435446153846 ], [ 29.76865603448276, -1.532704923076923 ], [ 29.768925517241382, -1.532704923076923 ], [ 29.768925517241382, -1.532435446153846 ], [ 29.76865603448276, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12754, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.532435446153846 ], [ 29.768925517241382, -1.532704923076923 ], [ 29.769195, -1.532704923076923 ], [ 29.769195, -1.532435446153846 ], [ 29.768925517241382, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12755, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.532435446153846 ], [ 29.769195, -1.532704923076923 ], [ 29.769464482758622, -1.532704923076923 ], [ 29.769464482758622, -1.532435446153846 ], [ 29.769195, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12756, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.532435446153846 ], [ 29.769464482758622, -1.532704923076923 ], [ 29.769733965517243, -1.532704923076923 ], [ 29.769733965517243, -1.532435446153846 ], [ 29.769464482758622, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12757, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.532435446153846 ], [ 29.769733965517243, -1.532704923076923 ], [ 29.770003448275862, -1.532704923076923 ], [ 29.770003448275862, -1.532435446153846 ], [ 29.769733965517243, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12758, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.532435446153846 ], [ 29.770003448275862, -1.532704923076923 ], [ 29.770272931034484, -1.532704923076923 ], [ 29.770272931034484, -1.532435446153846 ], [ 29.770003448275862, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12759, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.532435446153846 ], [ 29.770272931034484, -1.532704923076923 ], [ 29.770542413793105, -1.532704923076923 ], [ 29.770542413793105, -1.532435446153846 ], [ 29.770272931034484, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12760, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736318103448276, -1.532704923076923 ], [ 29.736318103448276, -1.5329744 ], [ 29.736587586206898, -1.5329744 ], [ 29.736587586206898, -1.532704923076923 ], [ 29.736318103448276, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12761, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736587586206898, -1.532704923076923 ], [ 29.736587586206898, -1.5329744 ], [ 29.736857068965517, -1.5329744 ], [ 29.736857068965517, -1.532704923076923 ], [ 29.736587586206898, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12762, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.532704923076923 ], [ 29.736857068965517, -1.5329744 ], [ 29.737126551724138, -1.5329744 ], [ 29.737126551724138, -1.532704923076923 ], [ 29.736857068965517, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12763, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737126551724138, -1.532704923076923 ], [ 29.737126551724138, -1.5329744 ], [ 29.737665517241378, -1.5329744 ], [ 29.737665517241378, -1.532704923076923 ], [ 29.737126551724138, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12764, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.532704923076923 ], [ 29.737665517241378, -1.5329744 ], [ 29.737935, -1.5329744 ], [ 29.737935, -1.532704923076923 ], [ 29.737665517241378, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12765, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.532704923076923 ], [ 29.737935, -1.5329744 ], [ 29.738204482758622, -1.5329744 ], [ 29.738204482758622, -1.532704923076923 ], [ 29.737935, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12766, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.532704923076923 ], [ 29.738204482758622, -1.5329744 ], [ 29.738743448275862, -1.5329744 ], [ 29.738743448275862, -1.532704923076923 ], [ 29.738204482758622, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12767, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.532704923076923 ], [ 29.738743448275862, -1.5329744 ], [ 29.739012931034484, -1.5329744 ], [ 29.739012931034484, -1.532704923076923 ], [ 29.738743448275862, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12768, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.532704923076923 ], [ 29.739012931034484, -1.5329744 ], [ 29.739282413793102, -1.5329744 ], [ 29.739282413793102, -1.532704923076923 ], [ 29.739012931034484, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12769, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.532704923076923 ], [ 29.75733775862069, -1.5329744 ], [ 29.757607241379311, -1.5329744 ], [ 29.757607241379311, -1.532704923076923 ], [ 29.75733775862069, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12770, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.532704923076923 ], [ 29.758415689655173, -1.5329744 ], [ 29.758685172413795, -1.5329744 ], [ 29.758685172413795, -1.532704923076923 ], [ 29.758415689655173, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12771, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.532704923076923 ], [ 29.758685172413795, -1.5329744 ], [ 29.758954655172413, -1.5329744 ], [ 29.758954655172413, -1.532704923076923 ], [ 29.758685172413795, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12772, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.532704923076923 ], [ 29.758954655172413, -1.5329744 ], [ 29.759224137931035, -1.5329744 ], [ 29.759224137931035, -1.532704923076923 ], [ 29.758954655172413, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12773, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.532704923076923 ], [ 29.759224137931035, -1.5329744 ], [ 29.759493620689657, -1.5329744 ], [ 29.759493620689657, -1.532704923076923 ], [ 29.759224137931035, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12774, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.532704923076923 ], [ 29.759493620689657, -1.5329744 ], [ 29.759763103448275, -1.5329744 ], [ 29.759763103448275, -1.532704923076923 ], [ 29.759493620689657, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12775, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.532704923076923 ], [ 29.759763103448275, -1.5329744 ], [ 29.760032586206897, -1.5329744 ], [ 29.760032586206897, -1.532704923076923 ], [ 29.759763103448275, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12776, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.532704923076923 ], [ 29.760032586206897, -1.5329744 ], [ 29.760571551724137, -1.5329744 ], [ 29.760571551724137, -1.532704923076923 ], [ 29.760032586206897, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12777, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.532704923076923 ], [ 29.760571551724137, -1.5329744 ], [ 29.760841034482759, -1.5329744 ], [ 29.760841034482759, -1.532704923076923 ], [ 29.760571551724137, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12778, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.532704923076923 ], [ 29.760841034482759, -1.5329744 ], [ 29.761110517241381, -1.5329744 ], [ 29.761110517241381, -1.532704923076923 ], [ 29.760841034482759, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12779, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.532704923076923 ], [ 29.761110517241381, -1.5329744 ], [ 29.76138, -1.5329744 ], [ 29.76138, -1.532704923076923 ], [ 29.761110517241381, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12780, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.532704923076923 ], [ 29.76138, -1.5329744 ], [ 29.761649482758621, -1.5329744 ], [ 29.761649482758621, -1.532704923076923 ], [ 29.76138, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12781, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.532704923076923 ], [ 29.761649482758621, -1.5329744 ], [ 29.761918965517243, -1.5329744 ], [ 29.761918965517243, -1.532704923076923 ], [ 29.761649482758621, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12782, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.532704923076923 ], [ 29.761918965517243, -1.5329744 ], [ 29.762188448275861, -1.5329744 ], [ 29.762188448275861, -1.532704923076923 ], [ 29.761918965517243, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12783, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.532704923076923 ], [ 29.762188448275861, -1.5329744 ], [ 29.762457931034483, -1.5329744 ], [ 29.762457931034483, -1.532704923076923 ], [ 29.762188448275861, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12784, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.532704923076923 ], [ 29.762457931034483, -1.5329744 ], [ 29.762727413793105, -1.5329744 ], [ 29.762727413793105, -1.532704923076923 ], [ 29.762457931034483, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12785, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.532704923076923 ], [ 29.762727413793105, -1.5329744 ], [ 29.762996896551723, -1.5329744 ], [ 29.762996896551723, -1.532704923076923 ], [ 29.762727413793105, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12786, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.532704923076923 ], [ 29.762996896551723, -1.5329744 ], [ 29.763266379310345, -1.5329744 ], [ 29.763266379310345, -1.532704923076923 ], [ 29.762996896551723, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12787, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.532704923076923 ], [ 29.763266379310345, -1.5329744 ], [ 29.763535862068967, -1.5329744 ], [ 29.763535862068967, -1.532704923076923 ], [ 29.763266379310345, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12788, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.532704923076923 ], [ 29.763535862068967, -1.5329744 ], [ 29.763805344827588, -1.5329744 ], [ 29.763805344827588, -1.532704923076923 ], [ 29.763535862068967, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12789, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.532704923076923 ], [ 29.763805344827588, -1.5329744 ], [ 29.764074827586207, -1.5329744 ], [ 29.764074827586207, -1.532704923076923 ], [ 29.763805344827588, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12790, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.532704923076923 ], [ 29.764074827586207, -1.5329744 ], [ 29.764344310344828, -1.5329744 ], [ 29.764344310344828, -1.532704923076923 ], [ 29.764074827586207, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12791, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.532704923076923 ], [ 29.764344310344828, -1.5329744 ], [ 29.76461379310345, -1.5329744 ], [ 29.76461379310345, -1.532704923076923 ], [ 29.764344310344828, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12792, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.532704923076923 ], [ 29.76461379310345, -1.5329744 ], [ 29.764883275862069, -1.5329744 ], [ 29.764883275862069, -1.532704923076923 ], [ 29.76461379310345, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12793, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.532704923076923 ], [ 29.764883275862069, -1.5329744 ], [ 29.76515275862069, -1.5329744 ], [ 29.76515275862069, -1.532704923076923 ], [ 29.764883275862069, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12794, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.532704923076923 ], [ 29.76515275862069, -1.5329744 ], [ 29.765422241379312, -1.5329744 ], [ 29.765422241379312, -1.532704923076923 ], [ 29.76515275862069, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12795, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.532704923076923 ], [ 29.765422241379312, -1.5329744 ], [ 29.765961206896552, -1.5329744 ], [ 29.765961206896552, -1.532704923076923 ], [ 29.765422241379312, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12796, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.532704923076923 ], [ 29.765961206896552, -1.5329744 ], [ 29.766230689655174, -1.5329744 ], [ 29.766230689655174, -1.532704923076923 ], [ 29.765961206896552, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12797, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.532704923076923 ], [ 29.766230689655174, -1.5329744 ], [ 29.766500172413792, -1.5329744 ], [ 29.766500172413792, -1.532704923076923 ], [ 29.766230689655174, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12798, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.532704923076923 ], [ 29.766500172413792, -1.5329744 ], [ 29.766769655172414, -1.5329744 ], [ 29.766769655172414, -1.532704923076923 ], [ 29.766500172413792, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12799, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.532704923076923 ], [ 29.766769655172414, -1.5329744 ], [ 29.767039137931036, -1.5329744 ], [ 29.767039137931036, -1.532704923076923 ], [ 29.766769655172414, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12800, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.532704923076923 ], [ 29.767039137931036, -1.5329744 ], [ 29.767308620689654, -1.5329744 ], [ 29.767308620689654, -1.532704923076923 ], [ 29.767039137931036, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12801, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.532704923076923 ], [ 29.767308620689654, -1.5329744 ], [ 29.767578103448276, -1.5329744 ], [ 29.767578103448276, -1.532704923076923 ], [ 29.767308620689654, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12802, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.532704923076923 ], [ 29.767578103448276, -1.5329744 ], [ 29.767847586206898, -1.5329744 ], [ 29.767847586206898, -1.532704923076923 ], [ 29.767578103448276, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12803, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.532704923076923 ], [ 29.767847586206898, -1.5329744 ], [ 29.768117068965516, -1.5329744 ], [ 29.768117068965516, -1.532704923076923 ], [ 29.767847586206898, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12804, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.532704923076923 ], [ 29.768117068965516, -1.5329744 ], [ 29.768386551724138, -1.5329744 ], [ 29.768386551724138, -1.532704923076923 ], [ 29.768117068965516, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12805, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.532704923076923 ], [ 29.768386551724138, -1.5329744 ], [ 29.76865603448276, -1.5329744 ], [ 29.76865603448276, -1.532704923076923 ], [ 29.768386551724138, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12806, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.532704923076923 ], [ 29.76865603448276, -1.5329744 ], [ 29.768925517241382, -1.5329744 ], [ 29.768925517241382, -1.532704923076923 ], [ 29.76865603448276, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12807, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.532704923076923 ], [ 29.768925517241382, -1.5329744 ], [ 29.769195, -1.5329744 ], [ 29.769195, -1.532704923076923 ], [ 29.768925517241382, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12808, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.532704923076923 ], [ 29.769195, -1.5329744 ], [ 29.769464482758622, -1.5329744 ], [ 29.769464482758622, -1.532704923076923 ], [ 29.769195, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12809, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.532704923076923 ], [ 29.769464482758622, -1.5329744 ], [ 29.769733965517243, -1.5329744 ], [ 29.769733965517243, -1.532704923076923 ], [ 29.769464482758622, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12810, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.531357538461538 ], [ 29.739821379310346, -1.533513353846154 ], [ 29.740090862068964, -1.533513353846154 ], [ 29.740090862068964, -1.531357538461538 ], [ 29.739821379310346, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12811, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.531357538461538 ], [ 29.740360344827586, -1.533782830769231 ], [ 29.740629827586208, -1.533782830769231 ], [ 29.740629827586208, -1.531357538461538 ], [ 29.740360344827586, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12812, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.531357538461538 ], [ 29.740629827586208, -1.533782830769231 ], [ 29.74089931034483, -1.533782830769231 ], [ 29.74089931034483, -1.531357538461538 ], [ 29.740629827586208, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12813, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.531627015384615 ], [ 29.740090862068964, -1.533513353846154 ], [ 29.740360344827586, -1.533513353846154 ], [ 29.740360344827586, -1.531627015384615 ], [ 29.740090862068964, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12814, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74089931034483, -1.531627015384615 ], [ 29.74089931034483, -1.534052307692308 ], [ 29.741168793103448, -1.534052307692308 ], [ 29.741168793103448, -1.531627015384615 ], [ 29.74089931034483, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12815, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.531627015384615 ], [ 29.772159310344829, -1.533782830769231 ], [ 29.772428793103447, -1.533782830769231 ], [ 29.772428793103447, -1.531627015384615 ], [ 29.772159310344829, -1.531627015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12816, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.531896492307692 ], [ 29.771620344827586, -1.534052307692308 ], [ 29.771889827586207, -1.534052307692308 ], [ 29.771889827586207, -1.531896492307692 ], [ 29.771620344827586, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12817, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.531896492307692 ], [ 29.771889827586207, -1.534052307692308 ], [ 29.772159310344829, -1.534052307692308 ], [ 29.772159310344829, -1.531896492307692 ], [ 29.771889827586207, -1.531896492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12818, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.532165969230769 ], [ 29.771081379310345, -1.534052307692308 ], [ 29.771350862068967, -1.534052307692308 ], [ 29.771350862068967, -1.532165969230769 ], [ 29.771081379310345, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12819, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.532165969230769 ], [ 29.771350862068967, -1.533782830769231 ], [ 29.771620344827586, -1.533782830769231 ], [ 29.771620344827586, -1.532165969230769 ], [ 29.771350862068967, -1.532165969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12820, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.532435446153846 ], [ 29.770542413793105, -1.534052307692308 ], [ 29.770811896551724, -1.534052307692308 ], [ 29.770811896551724, -1.532435446153846 ], [ 29.770542413793105, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12821, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.532435446153846 ], [ 29.770811896551724, -1.534052307692308 ], [ 29.771081379310345, -1.534052307692308 ], [ 29.771081379310345, -1.532435446153846 ], [ 29.770811896551724, -1.532435446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12822, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735509655172415, -1.532704923076923 ], [ 29.735509655172415, -1.533513353846154 ], [ 29.736048620689655, -1.533513353846154 ], [ 29.736048620689655, -1.533243876923077 ], [ 29.735779137931036, -1.533243876923077 ], [ 29.735779137931036, -1.532704923076923 ], [ 29.735509655172415, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12823, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.532704923076923 ], [ 29.757068275862068, -1.533243876923077 ], [ 29.75733775862069, -1.533243876923077 ], [ 29.75733775862069, -1.532704923076923 ], [ 29.757068275862068, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12824, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.532704923076923 ], [ 29.758146206896551, -1.533243876923077 ], [ 29.758415689655173, -1.533243876923077 ], [ 29.758415689655173, -1.532704923076923 ], [ 29.758146206896551, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12825, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.532704923076923 ], [ 29.769733965517243, -1.534052307692308 ], [ 29.770003448275862, -1.534052307692308 ], [ 29.770003448275862, -1.532704923076923 ], [ 29.769733965517243, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12826, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.532704923076923 ], [ 29.770003448275862, -1.534321784615384 ], [ 29.770272931034484, -1.534321784615384 ], [ 29.770272931034484, -1.532704923076923 ], [ 29.770003448275862, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12827, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.532704923076923 ], [ 29.770272931034484, -1.534321784615384 ], [ 29.770542413793105, -1.534321784615384 ], [ 29.770542413793105, -1.532704923076923 ], [ 29.770272931034484, -1.532704923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12828, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.735779137931036, -1.5329744 ], [ 29.735779137931036, -1.533243876923077 ], [ 29.736048620689655, -1.533243876923077 ], [ 29.736048620689655, -1.5329744 ], [ 29.735779137931036, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12829, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736048620689655, -1.5329744 ], [ 29.736048620689655, -1.533243876923077 ], [ 29.736587586206898, -1.533243876923077 ], [ 29.736587586206898, -1.5329744 ], [ 29.736048620689655, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12830, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.736857068965517, -1.5329744 ], [ 29.736857068965517, -1.533243876923077 ], [ 29.73739603448276, -1.533243876923077 ], [ 29.73739603448276, -1.5329744 ], [ 29.736857068965517, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12831, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.5329744 ], [ 29.73739603448276, -1.533243876923077 ], [ 29.737665517241378, -1.533243876923077 ], [ 29.737665517241378, -1.5329744 ], [ 29.73739603448276, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12832, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.5329744 ], [ 29.737665517241378, -1.533243876923077 ], [ 29.737935, -1.533243876923077 ], [ 29.737935, -1.5329744 ], [ 29.737665517241378, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12833, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.5329744 ], [ 29.737935, -1.533243876923077 ], [ 29.738204482758622, -1.533243876923077 ], [ 29.738204482758622, -1.5329744 ], [ 29.737935, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12834, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.5329744 ], [ 29.738204482758622, -1.533243876923077 ], [ 29.738743448275862, -1.533243876923077 ], [ 29.738743448275862, -1.5329744 ], [ 29.738204482758622, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12835, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.5329744 ], [ 29.738743448275862, -1.533513353846154 ], [ 29.739012931034484, -1.533513353846154 ], [ 29.739012931034484, -1.5329744 ], [ 29.738743448275862, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12836, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.5329744 ], [ 29.739012931034484, -1.533243876923077 ], [ 29.739282413793102, -1.533243876923077 ], [ 29.739282413793102, -1.5329744 ], [ 29.739012931034484, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12837, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.5329744 ], [ 29.739282413793102, -1.533243876923077 ], [ 29.739551896551724, -1.533243876923077 ], [ 29.739551896551724, -1.5329744 ], [ 29.739282413793102, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12838, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.5329744 ], [ 29.75733775862069, -1.533513353846154 ], [ 29.757607241379311, -1.533513353846154 ], [ 29.757607241379311, -1.5329744 ], [ 29.75733775862069, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12839, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.5329744 ], [ 29.757607241379311, -1.533513353846154 ], [ 29.757876724137933, -1.533513353846154 ], [ 29.757876724137933, -1.5329744 ], [ 29.757607241379311, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12840, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.5329744 ], [ 29.757876724137933, -1.533243876923077 ], [ 29.758146206896551, -1.533243876923077 ], [ 29.758146206896551, -1.5329744 ], [ 29.757876724137933, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12841, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.5329744 ], [ 29.758415689655173, -1.533243876923077 ], [ 29.758685172413795, -1.533243876923077 ], [ 29.758685172413795, -1.5329744 ], [ 29.758415689655173, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12842, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5329744 ], [ 29.758685172413795, -1.533243876923077 ], [ 29.758954655172413, -1.533243876923077 ], [ 29.758954655172413, -1.5329744 ], [ 29.758685172413795, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12843, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.5329744 ], [ 29.758954655172413, -1.533243876923077 ], [ 29.759224137931035, -1.533243876923077 ], [ 29.759224137931035, -1.5329744 ], [ 29.758954655172413, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12844, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.5329744 ], [ 29.759224137931035, -1.533243876923077 ], [ 29.759493620689657, -1.533243876923077 ], [ 29.759493620689657, -1.5329744 ], [ 29.759224137931035, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12845, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.5329744 ], [ 29.759493620689657, -1.533243876923077 ], [ 29.759763103448275, -1.533243876923077 ], [ 29.759763103448275, -1.5329744 ], [ 29.759493620689657, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12846, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.5329744 ], [ 29.759763103448275, -1.533243876923077 ], [ 29.760032586206897, -1.533243876923077 ], [ 29.760032586206897, -1.5329744 ], [ 29.759763103448275, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12847, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.5329744 ], [ 29.760032586206897, -1.533243876923077 ], [ 29.760302068965519, -1.533243876923077 ], [ 29.760302068965519, -1.5329744 ], [ 29.760032586206897, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12848, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.5329744 ], [ 29.760302068965519, -1.533243876923077 ], [ 29.760841034482759, -1.533243876923077 ], [ 29.760841034482759, -1.5329744 ], [ 29.760302068965519, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12849, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5329744 ], [ 29.760841034482759, -1.533243876923077 ], [ 29.761110517241381, -1.533243876923077 ], [ 29.761110517241381, -1.5329744 ], [ 29.760841034482759, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12850, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5329744 ], [ 29.761110517241381, -1.533243876923077 ], [ 29.76138, -1.533243876923077 ], [ 29.76138, -1.5329744 ], [ 29.761110517241381, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12851, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5329744 ], [ 29.76138, -1.533243876923077 ], [ 29.761649482758621, -1.533243876923077 ], [ 29.761649482758621, -1.5329744 ], [ 29.76138, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12852, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.5329744 ], [ 29.761649482758621, -1.533243876923077 ], [ 29.761918965517243, -1.533243876923077 ], [ 29.761918965517243, -1.5329744 ], [ 29.761649482758621, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12853, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5329744 ], [ 29.761918965517243, -1.533243876923077 ], [ 29.762188448275861, -1.533243876923077 ], [ 29.762188448275861, -1.5329744 ], [ 29.761918965517243, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12854, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5329744 ], [ 29.762188448275861, -1.533243876923077 ], [ 29.762457931034483, -1.533243876923077 ], [ 29.762457931034483, -1.5329744 ], [ 29.762188448275861, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12855, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5329744 ], [ 29.762457931034483, -1.533243876923077 ], [ 29.762727413793105, -1.533243876923077 ], [ 29.762727413793105, -1.5329744 ], [ 29.762457931034483, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12856, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5329744 ], [ 29.762727413793105, -1.533243876923077 ], [ 29.762996896551723, -1.533243876923077 ], [ 29.762996896551723, -1.5329744 ], [ 29.762727413793105, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12857, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.5329744 ], [ 29.762996896551723, -1.533243876923077 ], [ 29.763266379310345, -1.533243876923077 ], [ 29.763266379310345, -1.5329744 ], [ 29.762996896551723, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12858, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5329744 ], [ 29.763266379310345, -1.533243876923077 ], [ 29.763535862068967, -1.533243876923077 ], [ 29.763535862068967, -1.5329744 ], [ 29.763266379310345, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12859, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5329744 ], [ 29.763535862068967, -1.533243876923077 ], [ 29.763805344827588, -1.533243876923077 ], [ 29.763805344827588, -1.5329744 ], [ 29.763535862068967, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12860, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5329744 ], [ 29.763805344827588, -1.533243876923077 ], [ 29.764074827586207, -1.533243876923077 ], [ 29.764074827586207, -1.5329744 ], [ 29.763805344827588, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12861, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5329744 ], [ 29.764074827586207, -1.533243876923077 ], [ 29.764344310344828, -1.533243876923077 ], [ 29.764344310344828, -1.5329744 ], [ 29.764074827586207, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12862, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.5329744 ], [ 29.764344310344828, -1.533243876923077 ], [ 29.76461379310345, -1.533243876923077 ], [ 29.76461379310345, -1.5329744 ], [ 29.764344310344828, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12863, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.5329744 ], [ 29.76461379310345, -1.533243876923077 ], [ 29.764883275862069, -1.533243876923077 ], [ 29.764883275862069, -1.5329744 ], [ 29.76461379310345, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12864, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.5329744 ], [ 29.764883275862069, -1.533243876923077 ], [ 29.76515275862069, -1.533243876923077 ], [ 29.76515275862069, -1.5329744 ], [ 29.764883275862069, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12865, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.5329744 ], [ 29.76515275862069, -1.533243876923077 ], [ 29.765422241379312, -1.533243876923077 ], [ 29.765422241379312, -1.5329744 ], [ 29.76515275862069, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12866, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.5329744 ], [ 29.765422241379312, -1.533243876923077 ], [ 29.765961206896552, -1.533243876923077 ], [ 29.765961206896552, -1.5329744 ], [ 29.765422241379312, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12867, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.5329744 ], [ 29.765961206896552, -1.533243876923077 ], [ 29.766230689655174, -1.533243876923077 ], [ 29.766230689655174, -1.5329744 ], [ 29.765961206896552, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12868, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.5329744 ], [ 29.766230689655174, -1.533243876923077 ], [ 29.766500172413792, -1.533243876923077 ], [ 29.766500172413792, -1.5329744 ], [ 29.766230689655174, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12869, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5329744 ], [ 29.766500172413792, -1.533243876923077 ], [ 29.766769655172414, -1.533243876923077 ], [ 29.766769655172414, -1.5329744 ], [ 29.766500172413792, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12870, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.5329744 ], [ 29.766769655172414, -1.533243876923077 ], [ 29.767039137931036, -1.533243876923077 ], [ 29.767039137931036, -1.5329744 ], [ 29.766769655172414, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12871, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.5329744 ], [ 29.767039137931036, -1.533243876923077 ], [ 29.767308620689654, -1.533243876923077 ], [ 29.767308620689654, -1.5329744 ], [ 29.767039137931036, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12872, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.5329744 ], [ 29.767308620689654, -1.533243876923077 ], [ 29.767578103448276, -1.533243876923077 ], [ 29.767578103448276, -1.5329744 ], [ 29.767308620689654, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12873, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.5329744 ], [ 29.767578103448276, -1.533243876923077 ], [ 29.767847586206898, -1.533243876923077 ], [ 29.767847586206898, -1.5329744 ], [ 29.767578103448276, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12874, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.5329744 ], [ 29.767847586206898, -1.533243876923077 ], [ 29.768117068965516, -1.533243876923077 ], [ 29.768117068965516, -1.5329744 ], [ 29.767847586206898, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12875, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.5329744 ], [ 29.768117068965516, -1.533243876923077 ], [ 29.768386551724138, -1.533243876923077 ], [ 29.768386551724138, -1.5329744 ], [ 29.768117068965516, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12876, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.5329744 ], [ 29.768386551724138, -1.533243876923077 ], [ 29.76865603448276, -1.533243876923077 ], [ 29.76865603448276, -1.5329744 ], [ 29.768386551724138, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12877, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.5329744 ], [ 29.76865603448276, -1.533243876923077 ], [ 29.768925517241382, -1.533243876923077 ], [ 29.768925517241382, -1.5329744 ], [ 29.76865603448276, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12878, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.5329744 ], [ 29.768925517241382, -1.534052307692308 ], [ 29.769195, -1.534052307692308 ], [ 29.769195, -1.5329744 ], [ 29.768925517241382, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12879, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.5329744 ], [ 29.769195, -1.534321784615384 ], [ 29.769464482758622, -1.534321784615384 ], [ 29.769464482758622, -1.5329744 ], [ 29.769195, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12880, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.5329744 ], [ 29.769464482758622, -1.535130215384615 ], [ 29.769733965517243, -1.535130215384615 ], [ 29.769733965517243, -1.5329744 ], [ 29.769464482758622, -1.5329744 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12881, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.73739603448276, -1.533243876923077 ], [ 29.73739603448276, -1.533513353846154 ], [ 29.737665517241378, -1.533513353846154 ], [ 29.737665517241378, -1.533243876923077 ], [ 29.73739603448276, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12882, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.533243876923077 ], [ 29.737665517241378, -1.533513353846154 ], [ 29.737935, -1.533513353846154 ], [ 29.737935, -1.533243876923077 ], [ 29.737665517241378, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12883, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.533243876923077 ], [ 29.737935, -1.534052307692308 ], [ 29.738204482758622, -1.534052307692308 ], [ 29.738204482758622, -1.533243876923077 ], [ 29.737935, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12884, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.533243876923077 ], [ 29.738204482758622, -1.534052307692308 ], [ 29.738743448275862, -1.534052307692308 ], [ 29.738743448275862, -1.533243876923077 ], [ 29.738204482758622, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12885, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.533243876923077 ], [ 29.739012931034484, -1.533782830769231 ], [ 29.739282413793102, -1.533782830769231 ], [ 29.739282413793102, -1.533243876923077 ], [ 29.739012931034484, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12886, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.531357538461538 ], [ 29.739821379310346, -1.531357538461538 ], [ 29.739821379310346, -1.533513353846154 ], [ 29.739551896551724, -1.533513353846154 ], [ 29.739551896551724, -1.533782830769231 ], [ 29.739282413793102, -1.533782830769231 ], [ 29.739282413793102, -1.533243876923077 ], [ 29.739551896551724, -1.533243876923077 ], [ 29.739551896551724, -1.531357538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12887, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.533243876923077 ], [ 29.756798793103449, -1.533782830769231 ], [ 29.757068275862068, -1.533782830769231 ], [ 29.757068275862068, -1.533243876923077 ], [ 29.756798793103449, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12888, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.533243876923077 ], [ 29.757068275862068, -1.533513353846154 ], [ 29.75733775862069, -1.533513353846154 ], [ 29.75733775862069, -1.533243876923077 ], [ 29.757068275862068, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12889, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.533243876923077 ], [ 29.757876724137933, -1.533782830769231 ], [ 29.758146206896551, -1.533782830769231 ], [ 29.758146206896551, -1.533243876923077 ], [ 29.757876724137933, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12890, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.533243876923077 ], [ 29.758146206896551, -1.533513353846154 ], [ 29.758415689655173, -1.533513353846154 ], [ 29.758415689655173, -1.533243876923077 ], [ 29.758146206896551, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12891, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.533243876923077 ], [ 29.758415689655173, -1.533513353846154 ], [ 29.758685172413795, -1.533513353846154 ], [ 29.758685172413795, -1.533243876923077 ], [ 29.758415689655173, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12892, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.533243876923077 ], [ 29.758685172413795, -1.533513353846154 ], [ 29.758954655172413, -1.533513353846154 ], [ 29.758954655172413, -1.533243876923077 ], [ 29.758685172413795, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12893, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.533243876923077 ], [ 29.758954655172413, -1.533513353846154 ], [ 29.759224137931035, -1.533513353846154 ], [ 29.759224137931035, -1.533243876923077 ], [ 29.758954655172413, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12894, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.533243876923077 ], [ 29.759224137931035, -1.533513353846154 ], [ 29.759493620689657, -1.533513353846154 ], [ 29.759493620689657, -1.533243876923077 ], [ 29.759224137931035, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12895, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.533243876923077 ], [ 29.759493620689657, -1.533513353846154 ], [ 29.759763103448275, -1.533513353846154 ], [ 29.759763103448275, -1.533243876923077 ], [ 29.759493620689657, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12896, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.533243876923077 ], [ 29.759763103448275, -1.533513353846154 ], [ 29.760032586206897, -1.533513353846154 ], [ 29.760032586206897, -1.533243876923077 ], [ 29.759763103448275, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12897, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.533243876923077 ], [ 29.760032586206897, -1.533513353846154 ], [ 29.760302068965519, -1.533513353846154 ], [ 29.760302068965519, -1.533243876923077 ], [ 29.760032586206897, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12898, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.533243876923077 ], [ 29.760302068965519, -1.533513353846154 ], [ 29.760841034482759, -1.533513353846154 ], [ 29.760841034482759, -1.533243876923077 ], [ 29.760302068965519, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12899, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.533243876923077 ], [ 29.760841034482759, -1.533513353846154 ], [ 29.761110517241381, -1.533513353846154 ], [ 29.761110517241381, -1.533243876923077 ], [ 29.760841034482759, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12900, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.533243876923077 ], [ 29.761110517241381, -1.533513353846154 ], [ 29.76138, -1.533513353846154 ], [ 29.76138, -1.533243876923077 ], [ 29.761110517241381, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12901, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.533243876923077 ], [ 29.76138, -1.533513353846154 ], [ 29.761649482758621, -1.533513353846154 ], [ 29.761649482758621, -1.533243876923077 ], [ 29.76138, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12902, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.533243876923077 ], [ 29.761649482758621, -1.533513353846154 ], [ 29.761918965517243, -1.533513353846154 ], [ 29.761918965517243, -1.533243876923077 ], [ 29.761649482758621, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12903, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.533243876923077 ], [ 29.761918965517243, -1.533513353846154 ], [ 29.762188448275861, -1.533513353846154 ], [ 29.762188448275861, -1.533243876923077 ], [ 29.761918965517243, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12904, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.533243876923077 ], [ 29.762188448275861, -1.533513353846154 ], [ 29.762457931034483, -1.533513353846154 ], [ 29.762457931034483, -1.533243876923077 ], [ 29.762188448275861, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12905, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.533243876923077 ], [ 29.762457931034483, -1.533513353846154 ], [ 29.762727413793105, -1.533513353846154 ], [ 29.762727413793105, -1.533243876923077 ], [ 29.762457931034483, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12906, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.533243876923077 ], [ 29.762727413793105, -1.533513353846154 ], [ 29.762996896551723, -1.533513353846154 ], [ 29.762996896551723, -1.533243876923077 ], [ 29.762727413793105, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12907, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.533243876923077 ], [ 29.762996896551723, -1.533513353846154 ], [ 29.763266379310345, -1.533513353846154 ], [ 29.763266379310345, -1.533243876923077 ], [ 29.762996896551723, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12908, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.533243876923077 ], [ 29.763266379310345, -1.533513353846154 ], [ 29.763535862068967, -1.533513353846154 ], [ 29.763535862068967, -1.533243876923077 ], [ 29.763266379310345, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12909, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.533243876923077 ], [ 29.763535862068967, -1.533513353846154 ], [ 29.763805344827588, -1.533513353846154 ], [ 29.763805344827588, -1.533243876923077 ], [ 29.763535862068967, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12910, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.533243876923077 ], [ 29.763805344827588, -1.533513353846154 ], [ 29.764074827586207, -1.533513353846154 ], [ 29.764074827586207, -1.533243876923077 ], [ 29.763805344827588, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12911, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.533243876923077 ], [ 29.764074827586207, -1.533513353846154 ], [ 29.764344310344828, -1.533513353846154 ], [ 29.764344310344828, -1.533243876923077 ], [ 29.764074827586207, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12912, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.533243876923077 ], [ 29.764344310344828, -1.533513353846154 ], [ 29.76461379310345, -1.533513353846154 ], [ 29.76461379310345, -1.533243876923077 ], [ 29.764344310344828, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12913, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.533243876923077 ], [ 29.76461379310345, -1.533513353846154 ], [ 29.764883275862069, -1.533513353846154 ], [ 29.764883275862069, -1.533243876923077 ], [ 29.76461379310345, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12914, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.533243876923077 ], [ 29.764883275862069, -1.533513353846154 ], [ 29.76515275862069, -1.533513353846154 ], [ 29.76515275862069, -1.533243876923077 ], [ 29.764883275862069, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12915, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.533243876923077 ], [ 29.76515275862069, -1.533513353846154 ], [ 29.765422241379312, -1.533513353846154 ], [ 29.765422241379312, -1.533243876923077 ], [ 29.76515275862069, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12916, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.533243876923077 ], [ 29.765422241379312, -1.533513353846154 ], [ 29.765961206896552, -1.533513353846154 ], [ 29.765961206896552, -1.533243876923077 ], [ 29.765422241379312, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12917, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.533243876923077 ], [ 29.765961206896552, -1.533513353846154 ], [ 29.766230689655174, -1.533513353846154 ], [ 29.766230689655174, -1.533243876923077 ], [ 29.765961206896552, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12918, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.533243876923077 ], [ 29.766230689655174, -1.533513353846154 ], [ 29.766500172413792, -1.533513353846154 ], [ 29.766500172413792, -1.533243876923077 ], [ 29.766230689655174, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12919, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.533243876923077 ], [ 29.766500172413792, -1.533513353846154 ], [ 29.766769655172414, -1.533513353846154 ], [ 29.766769655172414, -1.533243876923077 ], [ 29.766500172413792, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12920, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.533243876923077 ], [ 29.766769655172414, -1.533513353846154 ], [ 29.767039137931036, -1.533513353846154 ], [ 29.767039137931036, -1.533243876923077 ], [ 29.766769655172414, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12921, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.533243876923077 ], [ 29.767039137931036, -1.533513353846154 ], [ 29.767308620689654, -1.533513353846154 ], [ 29.767308620689654, -1.533243876923077 ], [ 29.767039137931036, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12922, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.533243876923077 ], [ 29.767308620689654, -1.533513353846154 ], [ 29.767578103448276, -1.533513353846154 ], [ 29.767578103448276, -1.533243876923077 ], [ 29.767308620689654, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12923, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.533243876923077 ], [ 29.767578103448276, -1.533513353846154 ], [ 29.767847586206898, -1.533513353846154 ], [ 29.767847586206898, -1.533243876923077 ], [ 29.767578103448276, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12924, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.533243876923077 ], [ 29.767847586206898, -1.533782830769231 ], [ 29.768117068965516, -1.533782830769231 ], [ 29.768117068965516, -1.533243876923077 ], [ 29.767847586206898, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12925, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.533243876923077 ], [ 29.768117068965516, -1.534591261538461 ], [ 29.768386551724138, -1.534591261538461 ], [ 29.768386551724138, -1.533243876923077 ], [ 29.768117068965516, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12926, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.533243876923077 ], [ 29.768386551724138, -1.534591261538461 ], [ 29.76865603448276, -1.534591261538461 ], [ 29.76865603448276, -1.533243876923077 ], [ 29.768386551724138, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12927, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.533243876923077 ], [ 29.76865603448276, -1.534591261538461 ], [ 29.768925517241382, -1.534591261538461 ], [ 29.768925517241382, -1.533243876923077 ], [ 29.76865603448276, -1.533243876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12928, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737665517241378, -1.533513353846154 ], [ 29.737665517241378, -1.534321784615384 ], [ 29.737935, -1.534321784615384 ], [ 29.737935, -1.533513353846154 ], [ 29.737665517241378, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12929, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.533513353846154 ], [ 29.738743448275862, -1.533782830769231 ], [ 29.739012931034484, -1.533782830769231 ], [ 29.739012931034484, -1.533513353846154 ], [ 29.738743448275862, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12930, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.533513353846154 ], [ 29.739551896551724, -1.533782830769231 ], [ 29.739821379310346, -1.533782830769231 ], [ 29.739821379310346, -1.533513353846154 ], [ 29.739551896551724, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12931, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.533513353846154 ], [ 29.739821379310346, -1.534052307692308 ], [ 29.740090862068964, -1.534052307692308 ], [ 29.740090862068964, -1.533513353846154 ], [ 29.739821379310346, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12932, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.533513353846154 ], [ 29.740090862068964, -1.533782830769231 ], [ 29.740360344827586, -1.533782830769231 ], [ 29.740360344827586, -1.533513353846154 ], [ 29.740090862068964, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12933, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.533513353846154 ], [ 29.757068275862068, -1.534052307692308 ], [ 29.75733775862069, -1.534052307692308 ], [ 29.75733775862069, -1.533513353846154 ], [ 29.757068275862068, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12934, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.533513353846154 ], [ 29.75733775862069, -1.534052307692308 ], [ 29.757607241379311, -1.534052307692308 ], [ 29.757607241379311, -1.533513353846154 ], [ 29.75733775862069, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12935, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.533513353846154 ], [ 29.757607241379311, -1.533782830769231 ], [ 29.757876724137933, -1.533782830769231 ], [ 29.757876724137933, -1.533513353846154 ], [ 29.757607241379311, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12936, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.533513353846154 ], [ 29.758146206896551, -1.533782830769231 ], [ 29.758415689655173, -1.533782830769231 ], [ 29.758415689655173, -1.533513353846154 ], [ 29.758146206896551, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12937, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.533513353846154 ], [ 29.758415689655173, -1.533782830769231 ], [ 29.758685172413795, -1.533782830769231 ], [ 29.758685172413795, -1.533513353846154 ], [ 29.758415689655173, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12938, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.533513353846154 ], [ 29.758685172413795, -1.533782830769231 ], [ 29.758954655172413, -1.533782830769231 ], [ 29.758954655172413, -1.533513353846154 ], [ 29.758685172413795, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12939, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.533513353846154 ], [ 29.758954655172413, -1.533782830769231 ], [ 29.759224137931035, -1.533782830769231 ], [ 29.759224137931035, -1.533513353846154 ], [ 29.758954655172413, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12940, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.533513353846154 ], [ 29.759224137931035, -1.533782830769231 ], [ 29.759493620689657, -1.533782830769231 ], [ 29.759493620689657, -1.533513353846154 ], [ 29.759224137931035, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12941, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.533513353846154 ], [ 29.759493620689657, -1.533782830769231 ], [ 29.759763103448275, -1.533782830769231 ], [ 29.759763103448275, -1.533513353846154 ], [ 29.759493620689657, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12942, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.533513353846154 ], [ 29.759763103448275, -1.533782830769231 ], [ 29.760032586206897, -1.533782830769231 ], [ 29.760032586206897, -1.533513353846154 ], [ 29.759763103448275, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12943, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.533513353846154 ], [ 29.760032586206897, -1.533782830769231 ], [ 29.760302068965519, -1.533782830769231 ], [ 29.760302068965519, -1.533513353846154 ], [ 29.760032586206897, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12944, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.533513353846154 ], [ 29.760302068965519, -1.533782830769231 ], [ 29.760571551724137, -1.533782830769231 ], [ 29.760571551724137, -1.533513353846154 ], [ 29.760302068965519, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12945, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.533513353846154 ], [ 29.760571551724137, -1.533782830769231 ], [ 29.761110517241381, -1.533782830769231 ], [ 29.761110517241381, -1.533513353846154 ], [ 29.760571551724137, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12946, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.533513353846154 ], [ 29.761110517241381, -1.533782830769231 ], [ 29.76138, -1.533782830769231 ], [ 29.76138, -1.533513353846154 ], [ 29.761110517241381, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12947, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.533513353846154 ], [ 29.76138, -1.533782830769231 ], [ 29.761649482758621, -1.533782830769231 ], [ 29.761649482758621, -1.533513353846154 ], [ 29.76138, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12948, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.533513353846154 ], [ 29.761649482758621, -1.533782830769231 ], [ 29.761918965517243, -1.533782830769231 ], [ 29.761918965517243, -1.533513353846154 ], [ 29.761649482758621, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12949, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.533513353846154 ], [ 29.761918965517243, -1.533782830769231 ], [ 29.762188448275861, -1.533782830769231 ], [ 29.762188448275861, -1.533513353846154 ], [ 29.761918965517243, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12950, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.533513353846154 ], [ 29.762188448275861, -1.533782830769231 ], [ 29.762457931034483, -1.533782830769231 ], [ 29.762457931034483, -1.533513353846154 ], [ 29.762188448275861, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12951, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.533513353846154 ], [ 29.762457931034483, -1.533782830769231 ], [ 29.762727413793105, -1.533782830769231 ], [ 29.762727413793105, -1.533513353846154 ], [ 29.762457931034483, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12952, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.533513353846154 ], [ 29.762727413793105, -1.533782830769231 ], [ 29.762996896551723, -1.533782830769231 ], [ 29.762996896551723, -1.533513353846154 ], [ 29.762727413793105, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12953, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.533513353846154 ], [ 29.762996896551723, -1.533782830769231 ], [ 29.763266379310345, -1.533782830769231 ], [ 29.763266379310345, -1.533513353846154 ], [ 29.762996896551723, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12954, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.533513353846154 ], [ 29.763266379310345, -1.533782830769231 ], [ 29.763535862068967, -1.533782830769231 ], [ 29.763535862068967, -1.533513353846154 ], [ 29.763266379310345, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12955, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.533513353846154 ], [ 29.763535862068967, -1.533782830769231 ], [ 29.763805344827588, -1.533782830769231 ], [ 29.763805344827588, -1.533513353846154 ], [ 29.763535862068967, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12956, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.533513353846154 ], [ 29.763805344827588, -1.533782830769231 ], [ 29.764074827586207, -1.533782830769231 ], [ 29.764074827586207, -1.533513353846154 ], [ 29.763805344827588, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12957, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.533513353846154 ], [ 29.764074827586207, -1.533782830769231 ], [ 29.764344310344828, -1.533782830769231 ], [ 29.764344310344828, -1.533513353846154 ], [ 29.764074827586207, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12958, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.533513353846154 ], [ 29.764344310344828, -1.533782830769231 ], [ 29.76461379310345, -1.533782830769231 ], [ 29.76461379310345, -1.533513353846154 ], [ 29.764344310344828, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12959, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.533513353846154 ], [ 29.76461379310345, -1.533782830769231 ], [ 29.764883275862069, -1.533782830769231 ], [ 29.764883275862069, -1.533513353846154 ], [ 29.76461379310345, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12960, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.533513353846154 ], [ 29.764883275862069, -1.533782830769231 ], [ 29.76515275862069, -1.533782830769231 ], [ 29.76515275862069, -1.533513353846154 ], [ 29.764883275862069, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12961, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.533513353846154 ], [ 29.76515275862069, -1.533782830769231 ], [ 29.765422241379312, -1.533782830769231 ], [ 29.765422241379312, -1.533513353846154 ], [ 29.76515275862069, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12962, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.533513353846154 ], [ 29.765422241379312, -1.533782830769231 ], [ 29.765961206896552, -1.533782830769231 ], [ 29.765961206896552, -1.533513353846154 ], [ 29.765422241379312, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12963, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.533513353846154 ], [ 29.765961206896552, -1.533782830769231 ], [ 29.766230689655174, -1.533782830769231 ], [ 29.766230689655174, -1.533513353846154 ], [ 29.765961206896552, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12964, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.533513353846154 ], [ 29.766230689655174, -1.533782830769231 ], [ 29.766500172413792, -1.533782830769231 ], [ 29.766500172413792, -1.533513353846154 ], [ 29.766230689655174, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12965, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.533513353846154 ], [ 29.766500172413792, -1.533782830769231 ], [ 29.766769655172414, -1.533782830769231 ], [ 29.766769655172414, -1.533513353846154 ], [ 29.766500172413792, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12966, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.533513353846154 ], [ 29.766769655172414, -1.533782830769231 ], [ 29.767039137931036, -1.533782830769231 ], [ 29.767039137931036, -1.533513353846154 ], [ 29.766769655172414, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12967, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.533513353846154 ], [ 29.767039137931036, -1.534321784615384 ], [ 29.767308620689654, -1.534321784615384 ], [ 29.767308620689654, -1.533513353846154 ], [ 29.767039137931036, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12968, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.533513353846154 ], [ 29.767308620689654, -1.534321784615384 ], [ 29.767578103448276, -1.534321784615384 ], [ 29.767578103448276, -1.533513353846154 ], [ 29.767308620689654, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12969, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.533513353846154 ], [ 29.767578103448276, -1.534321784615384 ], [ 29.767847586206898, -1.534321784615384 ], [ 29.767847586206898, -1.533513353846154 ], [ 29.767578103448276, -1.533513353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12970, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.533782830769231 ], [ 29.738743448275862, -1.534052307692308 ], [ 29.739012931034484, -1.534052307692308 ], [ 29.739012931034484, -1.533782830769231 ], [ 29.738743448275862, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12971, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.533782830769231 ], [ 29.739012931034484, -1.534321784615384 ], [ 29.739282413793102, -1.534321784615384 ], [ 29.739282413793102, -1.533782830769231 ], [ 29.739012931034484, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12972, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.533782830769231 ], [ 29.739282413793102, -1.534052307692308 ], [ 29.739551896551724, -1.534052307692308 ], [ 29.739551896551724, -1.533782830769231 ], [ 29.739282413793102, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12973, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.533782830769231 ], [ 29.740090862068964, -1.535130215384615 ], [ 29.740360344827586, -1.535130215384615 ], [ 29.740360344827586, -1.533782830769231 ], [ 29.740090862068964, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12974, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.533782830769231 ], [ 29.740360344827586, -1.535130215384615 ], [ 29.740629827586208, -1.535130215384615 ], [ 29.740629827586208, -1.533782830769231 ], [ 29.740360344827586, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12975, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.533782830769231 ], [ 29.740629827586208, -1.534052307692308 ], [ 29.74089931034483, -1.534052307692308 ], [ 29.74089931034483, -1.533782830769231 ], [ 29.740629827586208, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12976, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.533782830769231 ], [ 29.756259827586206, -1.534321784615384 ], [ 29.756798793103449, -1.534321784615384 ], [ 29.756798793103449, -1.533782830769231 ], [ 29.756259827586206, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12977, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.533782830769231 ], [ 29.756798793103449, -1.534052307692308 ], [ 29.757068275862068, -1.534052307692308 ], [ 29.757068275862068, -1.533782830769231 ], [ 29.756798793103449, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12978, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.533782830769231 ], [ 29.757607241379311, -1.534321784615384 ], [ 29.757876724137933, -1.534321784615384 ], [ 29.757876724137933, -1.533782830769231 ], [ 29.757607241379311, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12979, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.533782830769231 ], [ 29.757876724137933, -1.534052307692308 ], [ 29.758146206896551, -1.534052307692308 ], [ 29.758146206896551, -1.533782830769231 ], [ 29.757876724137933, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12980, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.533782830769231 ], [ 29.758146206896551, -1.534052307692308 ], [ 29.758415689655173, -1.534052307692308 ], [ 29.758415689655173, -1.533782830769231 ], [ 29.758146206896551, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12981, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.533782830769231 ], [ 29.758415689655173, -1.534052307692308 ], [ 29.758685172413795, -1.534052307692308 ], [ 29.758685172413795, -1.533782830769231 ], [ 29.758415689655173, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12982, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.533782830769231 ], [ 29.758685172413795, -1.534052307692308 ], [ 29.758954655172413, -1.534052307692308 ], [ 29.758954655172413, -1.533782830769231 ], [ 29.758685172413795, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12983, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.533782830769231 ], [ 29.758954655172413, -1.534052307692308 ], [ 29.759224137931035, -1.534052307692308 ], [ 29.759224137931035, -1.533782830769231 ], [ 29.758954655172413, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12984, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.533782830769231 ], [ 29.759224137931035, -1.534052307692308 ], [ 29.759493620689657, -1.534052307692308 ], [ 29.759493620689657, -1.533782830769231 ], [ 29.759224137931035, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12985, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.533782830769231 ], [ 29.759493620689657, -1.534052307692308 ], [ 29.759763103448275, -1.534052307692308 ], [ 29.759763103448275, -1.533782830769231 ], [ 29.759493620689657, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12986, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.533782830769231 ], [ 29.759763103448275, -1.534052307692308 ], [ 29.760032586206897, -1.534052307692308 ], [ 29.760032586206897, -1.533782830769231 ], [ 29.759763103448275, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12987, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.533782830769231 ], [ 29.760032586206897, -1.534052307692308 ], [ 29.760302068965519, -1.534052307692308 ], [ 29.760302068965519, -1.533782830769231 ], [ 29.760032586206897, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12988, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.533782830769231 ], [ 29.760302068965519, -1.534052307692308 ], [ 29.760571551724137, -1.534052307692308 ], [ 29.760571551724137, -1.533782830769231 ], [ 29.760302068965519, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12989, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.533782830769231 ], [ 29.760571551724137, -1.534052307692308 ], [ 29.760841034482759, -1.534052307692308 ], [ 29.760841034482759, -1.533782830769231 ], [ 29.760571551724137, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12990, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.533782830769231 ], [ 29.760841034482759, -1.534052307692308 ], [ 29.76138, -1.534052307692308 ], [ 29.76138, -1.533782830769231 ], [ 29.760841034482759, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12991, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.533782830769231 ], [ 29.76138, -1.534052307692308 ], [ 29.761649482758621, -1.534052307692308 ], [ 29.761649482758621, -1.533782830769231 ], [ 29.76138, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12992, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.533782830769231 ], [ 29.761649482758621, -1.534052307692308 ], [ 29.761918965517243, -1.534052307692308 ], [ 29.761918965517243, -1.533782830769231 ], [ 29.761649482758621, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12993, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.533782830769231 ], [ 29.761918965517243, -1.534052307692308 ], [ 29.762188448275861, -1.534052307692308 ], [ 29.762188448275861, -1.533782830769231 ], [ 29.761918965517243, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12994, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.533782830769231 ], [ 29.762188448275861, -1.534052307692308 ], [ 29.762457931034483, -1.534052307692308 ], [ 29.762457931034483, -1.533782830769231 ], [ 29.762188448275861, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12995, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.533782830769231 ], [ 29.762457931034483, -1.534052307692308 ], [ 29.762727413793105, -1.534052307692308 ], [ 29.762727413793105, -1.533782830769231 ], [ 29.762457931034483, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12996, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.533782830769231 ], [ 29.762727413793105, -1.534052307692308 ], [ 29.762996896551723, -1.534052307692308 ], [ 29.762996896551723, -1.533782830769231 ], [ 29.762727413793105, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12997, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.533782830769231 ], [ 29.762996896551723, -1.534052307692308 ], [ 29.763266379310345, -1.534052307692308 ], [ 29.763266379310345, -1.533782830769231 ], [ 29.762996896551723, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12998, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.533782830769231 ], [ 29.763266379310345, -1.534052307692308 ], [ 29.763535862068967, -1.534052307692308 ], [ 29.763535862068967, -1.533782830769231 ], [ 29.763266379310345, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12999, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.533782830769231 ], [ 29.763535862068967, -1.534052307692308 ], [ 29.763805344827588, -1.534052307692308 ], [ 29.763805344827588, -1.533782830769231 ], [ 29.763535862068967, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13000, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.533782830769231 ], [ 29.763805344827588, -1.534052307692308 ], [ 29.764074827586207, -1.534052307692308 ], [ 29.764074827586207, -1.533782830769231 ], [ 29.763805344827588, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13001, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.533782830769231 ], [ 29.764074827586207, -1.534052307692308 ], [ 29.764344310344828, -1.534052307692308 ], [ 29.764344310344828, -1.533782830769231 ], [ 29.764074827586207, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13002, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.533782830769231 ], [ 29.764344310344828, -1.534052307692308 ], [ 29.76461379310345, -1.534052307692308 ], [ 29.76461379310345, -1.533782830769231 ], [ 29.764344310344828, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13003, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.533782830769231 ], [ 29.76461379310345, -1.534052307692308 ], [ 29.764883275862069, -1.534052307692308 ], [ 29.764883275862069, -1.533782830769231 ], [ 29.76461379310345, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13004, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.533782830769231 ], [ 29.764883275862069, -1.534052307692308 ], [ 29.76515275862069, -1.534052307692308 ], [ 29.76515275862069, -1.533782830769231 ], [ 29.764883275862069, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13005, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.533782830769231 ], [ 29.76515275862069, -1.534052307692308 ], [ 29.765422241379312, -1.534052307692308 ], [ 29.765422241379312, -1.533782830769231 ], [ 29.76515275862069, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13006, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.533782830769231 ], [ 29.765422241379312, -1.534052307692308 ], [ 29.766230689655174, -1.534052307692308 ], [ 29.766230689655174, -1.533782830769231 ], [ 29.765422241379312, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13007, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.533782830769231 ], [ 29.766230689655174, -1.534052307692308 ], [ 29.766500172413792, -1.534052307692308 ], [ 29.766500172413792, -1.533782830769231 ], [ 29.766230689655174, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13008, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.533782830769231 ], [ 29.766500172413792, -1.534052307692308 ], [ 29.766769655172414, -1.534052307692308 ], [ 29.766769655172414, -1.533782830769231 ], [ 29.766500172413792, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13009, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.533782830769231 ], [ 29.766769655172414, -1.534052307692308 ], [ 29.767039137931036, -1.534052307692308 ], [ 29.767039137931036, -1.533782830769231 ], [ 29.766769655172414, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13010, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.533782830769231 ], [ 29.767847586206898, -1.534321784615384 ], [ 29.768117068965516, -1.534321784615384 ], [ 29.768117068965516, -1.533782830769231 ], [ 29.767847586206898, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13011, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.533782830769231 ], [ 29.771350862068967, -1.534321784615384 ], [ 29.771620344827586, -1.534321784615384 ], [ 29.771620344827586, -1.533782830769231 ], [ 29.771350862068967, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13012, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.533782830769231 ], [ 29.772159310344829, -1.534052307692308 ], [ 29.772428793103447, -1.534052307692308 ], [ 29.772428793103447, -1.533782830769231 ], [ 29.772159310344829, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13013, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.534052307692308 ], [ 29.737935, -1.534321784615384 ], [ 29.738204482758622, -1.534321784615384 ], [ 29.738204482758622, -1.534052307692308 ], [ 29.737935, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13014, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.534052307692308 ], [ 29.739821379310346, -1.534591261538461 ], [ 29.740090862068964, -1.534591261538461 ], [ 29.740090862068964, -1.534052307692308 ], [ 29.739821379310346, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13015, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.534052307692308 ], [ 29.756798793103449, -1.534591261538461 ], [ 29.757068275862068, -1.534591261538461 ], [ 29.757068275862068, -1.534052307692308 ], [ 29.756798793103449, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13016, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.534052307692308 ], [ 29.757068275862068, -1.534591261538461 ], [ 29.75733775862069, -1.534591261538461 ], [ 29.75733775862069, -1.534052307692308 ], [ 29.757068275862068, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13017, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.534052307692308 ], [ 29.75733775862069, -1.534321784615384 ], [ 29.757607241379311, -1.534321784615384 ], [ 29.757607241379311, -1.534052307692308 ], [ 29.75733775862069, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13018, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.534052307692308 ], [ 29.757876724137933, -1.534321784615384 ], [ 29.758146206896551, -1.534321784615384 ], [ 29.758146206896551, -1.534052307692308 ], [ 29.757876724137933, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13019, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.534052307692308 ], [ 29.758146206896551, -1.534321784615384 ], [ 29.758415689655173, -1.534321784615384 ], [ 29.758415689655173, -1.534052307692308 ], [ 29.758146206896551, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13020, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.534052307692308 ], [ 29.758415689655173, -1.534321784615384 ], [ 29.758685172413795, -1.534321784615384 ], [ 29.758685172413795, -1.534052307692308 ], [ 29.758415689655173, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13021, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.534052307692308 ], [ 29.758685172413795, -1.534321784615384 ], [ 29.758954655172413, -1.534321784615384 ], [ 29.758954655172413, -1.534052307692308 ], [ 29.758685172413795, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13022, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.534052307692308 ], [ 29.758954655172413, -1.534321784615384 ], [ 29.759224137931035, -1.534321784615384 ], [ 29.759224137931035, -1.534052307692308 ], [ 29.758954655172413, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13023, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.534052307692308 ], [ 29.759224137931035, -1.534321784615384 ], [ 29.759493620689657, -1.534321784615384 ], [ 29.759493620689657, -1.534052307692308 ], [ 29.759224137931035, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13024, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.534052307692308 ], [ 29.759493620689657, -1.534321784615384 ], [ 29.759763103448275, -1.534321784615384 ], [ 29.759763103448275, -1.534052307692308 ], [ 29.759493620689657, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13025, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.534052307692308 ], [ 29.759763103448275, -1.534321784615384 ], [ 29.760032586206897, -1.534321784615384 ], [ 29.760032586206897, -1.534052307692308 ], [ 29.759763103448275, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13026, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.534052307692308 ], [ 29.760032586206897, -1.534321784615384 ], [ 29.760302068965519, -1.534321784615384 ], [ 29.760302068965519, -1.534052307692308 ], [ 29.760032586206897, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13027, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.534052307692308 ], [ 29.760302068965519, -1.534321784615384 ], [ 29.760571551724137, -1.534321784615384 ], [ 29.760571551724137, -1.534052307692308 ], [ 29.760302068965519, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13028, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.534052307692308 ], [ 29.760571551724137, -1.534321784615384 ], [ 29.760841034482759, -1.534321784615384 ], [ 29.760841034482759, -1.534052307692308 ], [ 29.760571551724137, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13029, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.534052307692308 ], [ 29.760841034482759, -1.534321784615384 ], [ 29.761110517241381, -1.534321784615384 ], [ 29.761110517241381, -1.534052307692308 ], [ 29.760841034482759, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13030, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.534052307692308 ], [ 29.761110517241381, -1.534321784615384 ], [ 29.761649482758621, -1.534321784615384 ], [ 29.761649482758621, -1.534052307692308 ], [ 29.761110517241381, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13031, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.534052307692308 ], [ 29.761649482758621, -1.534321784615384 ], [ 29.761918965517243, -1.534321784615384 ], [ 29.761918965517243, -1.534052307692308 ], [ 29.761649482758621, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13032, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.534052307692308 ], [ 29.761918965517243, -1.534321784615384 ], [ 29.762188448275861, -1.534321784615384 ], [ 29.762188448275861, -1.534052307692308 ], [ 29.761918965517243, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13033, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.534052307692308 ], [ 29.762188448275861, -1.534321784615384 ], [ 29.762457931034483, -1.534321784615384 ], [ 29.762457931034483, -1.534052307692308 ], [ 29.762188448275861, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13034, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.534052307692308 ], [ 29.762457931034483, -1.534321784615384 ], [ 29.762727413793105, -1.534321784615384 ], [ 29.762727413793105, -1.534052307692308 ], [ 29.762457931034483, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13035, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.534052307692308 ], [ 29.762727413793105, -1.534321784615384 ], [ 29.762996896551723, -1.534321784615384 ], [ 29.762996896551723, -1.534052307692308 ], [ 29.762727413793105, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13036, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.534052307692308 ], [ 29.762996896551723, -1.534321784615384 ], [ 29.763266379310345, -1.534321784615384 ], [ 29.763266379310345, -1.534052307692308 ], [ 29.762996896551723, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13037, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.534052307692308 ], [ 29.763266379310345, -1.534321784615384 ], [ 29.763535862068967, -1.534321784615384 ], [ 29.763535862068967, -1.534052307692308 ], [ 29.763266379310345, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13038, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.534052307692308 ], [ 29.763535862068967, -1.534321784615384 ], [ 29.763805344827588, -1.534321784615384 ], [ 29.763805344827588, -1.534052307692308 ], [ 29.763535862068967, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13039, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.534052307692308 ], [ 29.763805344827588, -1.534321784615384 ], [ 29.764074827586207, -1.534321784615384 ], [ 29.764074827586207, -1.534052307692308 ], [ 29.763805344827588, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13040, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.534052307692308 ], [ 29.764074827586207, -1.534321784615384 ], [ 29.764344310344828, -1.534321784615384 ], [ 29.764344310344828, -1.534052307692308 ], [ 29.764074827586207, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13041, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.534052307692308 ], [ 29.764344310344828, -1.534321784615384 ], [ 29.76461379310345, -1.534321784615384 ], [ 29.76461379310345, -1.534052307692308 ], [ 29.764344310344828, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13042, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.534052307692308 ], [ 29.76461379310345, -1.534321784615384 ], [ 29.764883275862069, -1.534321784615384 ], [ 29.764883275862069, -1.534052307692308 ], [ 29.76461379310345, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13043, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.534052307692308 ], [ 29.764883275862069, -1.534321784615384 ], [ 29.76515275862069, -1.534321784615384 ], [ 29.76515275862069, -1.534052307692308 ], [ 29.764883275862069, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13044, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.534052307692308 ], [ 29.76515275862069, -1.534321784615384 ], [ 29.765422241379312, -1.534321784615384 ], [ 29.765422241379312, -1.534052307692308 ], [ 29.76515275862069, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13045, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.534052307692308 ], [ 29.765422241379312, -1.534321784615384 ], [ 29.765961206896552, -1.534321784615384 ], [ 29.765961206896552, -1.534052307692308 ], [ 29.765422241379312, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13046, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.534052307692308 ], [ 29.765961206896552, -1.534321784615384 ], [ 29.766230689655174, -1.534321784615384 ], [ 29.766230689655174, -1.534052307692308 ], [ 29.765961206896552, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13047, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.534052307692308 ], [ 29.766230689655174, -1.534321784615384 ], [ 29.766500172413792, -1.534321784615384 ], [ 29.766500172413792, -1.534052307692308 ], [ 29.766230689655174, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13048, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.534052307692308 ], [ 29.766500172413792, -1.534321784615384 ], [ 29.766769655172414, -1.534321784615384 ], [ 29.766769655172414, -1.534052307692308 ], [ 29.766500172413792, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13049, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.534052307692308 ], [ 29.766769655172414, -1.534321784615384 ], [ 29.767039137931036, -1.534321784615384 ], [ 29.767039137931036, -1.534052307692308 ], [ 29.766769655172414, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13050, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.534052307692308 ], [ 29.768925517241382, -1.534591261538461 ], [ 29.769195, -1.534591261538461 ], [ 29.769195, -1.534052307692308 ], [ 29.768925517241382, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13051, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.534052307692308 ], [ 29.769733965517243, -1.535130215384615 ], [ 29.770003448275862, -1.535130215384615 ], [ 29.770003448275862, -1.534052307692308 ], [ 29.769733965517243, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13052, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.534052307692308 ], [ 29.770542413793105, -1.534321784615384 ], [ 29.770811896551724, -1.534321784615384 ], [ 29.770811896551724, -1.534052307692308 ], [ 29.770542413793105, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13053, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.534052307692308 ], [ 29.770811896551724, -1.534321784615384 ], [ 29.771081379310345, -1.534321784615384 ], [ 29.771081379310345, -1.534052307692308 ], [ 29.770811896551724, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13054, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.534052307692308 ], [ 29.771081379310345, -1.534321784615384 ], [ 29.771350862068967, -1.534321784615384 ], [ 29.771350862068967, -1.534052307692308 ], [ 29.771081379310345, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13055, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.534052307692308 ], [ 29.771620344827586, -1.534321784615384 ], [ 29.771889827586207, -1.534321784615384 ], [ 29.771889827586207, -1.534052307692308 ], [ 29.771620344827586, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13056, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.534321784615384 ], [ 29.755990344827588, -1.535130215384615 ], [ 29.756259827586206, -1.535130215384615 ], [ 29.756259827586206, -1.534321784615384 ], [ 29.755990344827588, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13057, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.534321784615384 ], [ 29.756259827586206, -1.534591261538461 ], [ 29.756798793103449, -1.534591261538461 ], [ 29.756798793103449, -1.534321784615384 ], [ 29.756259827586206, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13058, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.534321784615384 ], [ 29.75733775862069, -1.535130215384615 ], [ 29.757607241379311, -1.535130215384615 ], [ 29.757607241379311, -1.534321784615384 ], [ 29.75733775862069, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13059, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.534321784615384 ], [ 29.757607241379311, -1.534591261538461 ], [ 29.757876724137933, -1.534591261538461 ], [ 29.757876724137933, -1.534321784615384 ], [ 29.757607241379311, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13060, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.534321784615384 ], [ 29.757876724137933, -1.534591261538461 ], [ 29.758146206896551, -1.534591261538461 ], [ 29.758146206896551, -1.534321784615384 ], [ 29.757876724137933, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13061, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.534321784615384 ], [ 29.758146206896551, -1.534591261538461 ], [ 29.758415689655173, -1.534591261538461 ], [ 29.758415689655173, -1.534321784615384 ], [ 29.758146206896551, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13062, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.534321784615384 ], [ 29.758415689655173, -1.534591261538461 ], [ 29.758685172413795, -1.534591261538461 ], [ 29.758685172413795, -1.534321784615384 ], [ 29.758415689655173, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13063, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.534321784615384 ], [ 29.758685172413795, -1.534591261538461 ], [ 29.758954655172413, -1.534591261538461 ], [ 29.758954655172413, -1.534321784615384 ], [ 29.758685172413795, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13064, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.534321784615384 ], [ 29.758954655172413, -1.534591261538461 ], [ 29.759224137931035, -1.534591261538461 ], [ 29.759224137931035, -1.534321784615384 ], [ 29.758954655172413, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13065, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.534321784615384 ], [ 29.759224137931035, -1.534591261538461 ], [ 29.759493620689657, -1.534591261538461 ], [ 29.759493620689657, -1.534321784615384 ], [ 29.759224137931035, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13066, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.534321784615384 ], [ 29.759493620689657, -1.534591261538461 ], [ 29.759763103448275, -1.534591261538461 ], [ 29.759763103448275, -1.534321784615384 ], [ 29.759493620689657, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13067, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.534321784615384 ], [ 29.759763103448275, -1.534591261538461 ], [ 29.760032586206897, -1.534591261538461 ], [ 29.760032586206897, -1.534321784615384 ], [ 29.759763103448275, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13068, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.534321784615384 ], [ 29.760032586206897, -1.534591261538461 ], [ 29.760302068965519, -1.534591261538461 ], [ 29.760302068965519, -1.534321784615384 ], [ 29.760032586206897, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13069, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.534321784615384 ], [ 29.760302068965519, -1.534591261538461 ], [ 29.760571551724137, -1.534591261538461 ], [ 29.760571551724137, -1.534321784615384 ], [ 29.760302068965519, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13070, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.534321784615384 ], [ 29.760571551724137, -1.534591261538461 ], [ 29.760841034482759, -1.534591261538461 ], [ 29.760841034482759, -1.534321784615384 ], [ 29.760571551724137, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13071, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.534321784615384 ], [ 29.760841034482759, -1.534591261538461 ], [ 29.761110517241381, -1.534591261538461 ], [ 29.761110517241381, -1.534321784615384 ], [ 29.760841034482759, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13072, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.534321784615384 ], [ 29.761110517241381, -1.534591261538461 ], [ 29.76138, -1.534591261538461 ], [ 29.76138, -1.534321784615384 ], [ 29.761110517241381, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13073, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.534321784615384 ], [ 29.76138, -1.534591261538461 ], [ 29.761918965517243, -1.534591261538461 ], [ 29.761918965517243, -1.534321784615384 ], [ 29.76138, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13074, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.534321784615384 ], [ 29.761918965517243, -1.534591261538461 ], [ 29.762188448275861, -1.534591261538461 ], [ 29.762188448275861, -1.534321784615384 ], [ 29.761918965517243, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13075, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.534321784615384 ], [ 29.762188448275861, -1.534591261538461 ], [ 29.762457931034483, -1.534591261538461 ], [ 29.762457931034483, -1.534321784615384 ], [ 29.762188448275861, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13076, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.534321784615384 ], [ 29.762457931034483, -1.534591261538461 ], [ 29.762727413793105, -1.534591261538461 ], [ 29.762727413793105, -1.534321784615384 ], [ 29.762457931034483, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13077, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.534321784615384 ], [ 29.762727413793105, -1.534591261538461 ], [ 29.762996896551723, -1.534591261538461 ], [ 29.762996896551723, -1.534321784615384 ], [ 29.762727413793105, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13078, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.534321784615384 ], [ 29.762996896551723, -1.534591261538461 ], [ 29.763266379310345, -1.534591261538461 ], [ 29.763266379310345, -1.534321784615384 ], [ 29.762996896551723, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13079, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.534321784615384 ], [ 29.763266379310345, -1.534591261538461 ], [ 29.763535862068967, -1.534591261538461 ], [ 29.763535862068967, -1.534321784615384 ], [ 29.763266379310345, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13080, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.534321784615384 ], [ 29.763535862068967, -1.534591261538461 ], [ 29.763805344827588, -1.534591261538461 ], [ 29.763805344827588, -1.534321784615384 ], [ 29.763535862068967, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13081, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.534321784615384 ], [ 29.763805344827588, -1.534591261538461 ], [ 29.764074827586207, -1.534591261538461 ], [ 29.764074827586207, -1.534321784615384 ], [ 29.763805344827588, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13082, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.534321784615384 ], [ 29.764074827586207, -1.534591261538461 ], [ 29.764344310344828, -1.534591261538461 ], [ 29.764344310344828, -1.534321784615384 ], [ 29.764074827586207, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13083, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.534321784615384 ], [ 29.764344310344828, -1.534591261538461 ], [ 29.76461379310345, -1.534591261538461 ], [ 29.76461379310345, -1.534321784615384 ], [ 29.764344310344828, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13084, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.534321784615384 ], [ 29.76461379310345, -1.534591261538461 ], [ 29.764883275862069, -1.534591261538461 ], [ 29.764883275862069, -1.534321784615384 ], [ 29.76461379310345, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13085, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.534321784615384 ], [ 29.764883275862069, -1.534591261538461 ], [ 29.76515275862069, -1.534591261538461 ], [ 29.76515275862069, -1.534321784615384 ], [ 29.764883275862069, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13086, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.534321784615384 ], [ 29.76515275862069, -1.534591261538461 ], [ 29.765422241379312, -1.534591261538461 ], [ 29.765422241379312, -1.534321784615384 ], [ 29.76515275862069, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13087, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.534321784615384 ], [ 29.765422241379312, -1.534591261538461 ], [ 29.765961206896552, -1.534591261538461 ], [ 29.765961206896552, -1.534321784615384 ], [ 29.765422241379312, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13088, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.534321784615384 ], [ 29.765961206896552, -1.534591261538461 ], [ 29.766230689655174, -1.534591261538461 ], [ 29.766230689655174, -1.534321784615384 ], [ 29.765961206896552, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13089, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.534321784615384 ], [ 29.766230689655174, -1.534591261538461 ], [ 29.766500172413792, -1.534591261538461 ], [ 29.766500172413792, -1.534321784615384 ], [ 29.766230689655174, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13090, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.534321784615384 ], [ 29.766500172413792, -1.534591261538461 ], [ 29.766769655172414, -1.534591261538461 ], [ 29.766769655172414, -1.534321784615384 ], [ 29.766500172413792, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13091, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.534321784615384 ], [ 29.766769655172414, -1.534591261538461 ], [ 29.767039137931036, -1.534591261538461 ], [ 29.767039137931036, -1.534321784615384 ], [ 29.766769655172414, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13092, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.534321784615384 ], [ 29.767039137931036, -1.534591261538461 ], [ 29.767308620689654, -1.534591261538461 ], [ 29.767308620689654, -1.534321784615384 ], [ 29.767039137931036, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13093, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.534321784615384 ], [ 29.767308620689654, -1.534591261538461 ], [ 29.767578103448276, -1.534591261538461 ], [ 29.767578103448276, -1.534321784615384 ], [ 29.767308620689654, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13094, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.534321784615384 ], [ 29.767578103448276, -1.534591261538461 ], [ 29.767847586206898, -1.534591261538461 ], [ 29.767847586206898, -1.534321784615384 ], [ 29.767578103448276, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13095, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.534321784615384 ], [ 29.767847586206898, -1.534591261538461 ], [ 29.768117068965516, -1.534591261538461 ], [ 29.768117068965516, -1.534321784615384 ], [ 29.767847586206898, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13096, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.534321784615384 ], [ 29.769195, -1.534591261538461 ], [ 29.769464482758622, -1.534591261538461 ], [ 29.769464482758622, -1.534321784615384 ], [ 29.769195, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13097, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.534321784615384 ], [ 29.770003448275862, -1.535130215384615 ], [ 29.770272931034484, -1.535130215384615 ], [ 29.770272931034484, -1.534321784615384 ], [ 29.770003448275862, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13098, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.534321784615384 ], [ 29.770272931034484, -1.535130215384615 ], [ 29.770542413793105, -1.535130215384615 ], [ 29.770542413793105, -1.534321784615384 ], [ 29.770272931034484, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13099, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.534321784615384 ], [ 29.770542413793105, -1.535130215384615 ], [ 29.770811896551724, -1.535130215384615 ], [ 29.770811896551724, -1.534321784615384 ], [ 29.770542413793105, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13100, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.534321784615384 ], [ 29.770811896551724, -1.535130215384615 ], [ 29.771081379310345, -1.535130215384615 ], [ 29.771081379310345, -1.534321784615384 ], [ 29.770811896551724, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13101, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.534321784615384 ], [ 29.771081379310345, -1.535130215384615 ], [ 29.771350862068967, -1.535130215384615 ], [ 29.771350862068967, -1.534321784615384 ], [ 29.771081379310345, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13102, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.534591261538461 ], [ 29.739821379310346, -1.535130215384615 ], [ 29.740090862068964, -1.535130215384615 ], [ 29.740090862068964, -1.534591261538461 ], [ 29.739821379310346, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13103, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.534591261538461 ], [ 29.757068275862068, -1.535130215384615 ], [ 29.75733775862069, -1.535130215384615 ], [ 29.75733775862069, -1.534591261538461 ], [ 29.757068275862068, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13104, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.534591261538461 ], [ 29.757607241379311, -1.535130215384615 ], [ 29.757876724137933, -1.535130215384615 ], [ 29.757876724137933, -1.534591261538461 ], [ 29.757607241379311, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13105, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.534591261538461 ], [ 29.757876724137933, -1.535130215384615 ], [ 29.758146206896551, -1.535130215384615 ], [ 29.758146206896551, -1.534591261538461 ], [ 29.757876724137933, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13106, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.534591261538461 ], [ 29.758146206896551, -1.535130215384615 ], [ 29.758415689655173, -1.535130215384615 ], [ 29.758415689655173, -1.534591261538461 ], [ 29.758146206896551, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13107, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.534591261538461 ], [ 29.758415689655173, -1.535130215384615 ], [ 29.758685172413795, -1.535130215384615 ], [ 29.758685172413795, -1.534591261538461 ], [ 29.758415689655173, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13108, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.534591261538461 ], [ 29.758685172413795, -1.535130215384615 ], [ 29.758954655172413, -1.535130215384615 ], [ 29.758954655172413, -1.534591261538461 ], [ 29.758685172413795, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13109, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.534591261538461 ], [ 29.758954655172413, -1.535130215384615 ], [ 29.759224137931035, -1.535130215384615 ], [ 29.759224137931035, -1.534591261538461 ], [ 29.758954655172413, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13110, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.534591261538461 ], [ 29.759224137931035, -1.535130215384615 ], [ 29.759493620689657, -1.535130215384615 ], [ 29.759493620689657, -1.534591261538461 ], [ 29.759224137931035, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13111, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.534591261538461 ], [ 29.759493620689657, -1.535130215384615 ], [ 29.759763103448275, -1.535130215384615 ], [ 29.759763103448275, -1.534591261538461 ], [ 29.759493620689657, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13112, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.534591261538461 ], [ 29.759763103448275, -1.535130215384615 ], [ 29.760032586206897, -1.535130215384615 ], [ 29.760032586206897, -1.534591261538461 ], [ 29.759763103448275, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13113, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.534591261538461 ], [ 29.760032586206897, -1.535130215384615 ], [ 29.760302068965519, -1.535130215384615 ], [ 29.760302068965519, -1.534591261538461 ], [ 29.760032586206897, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13114, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.534591261538461 ], [ 29.760302068965519, -1.535130215384615 ], [ 29.760571551724137, -1.535130215384615 ], [ 29.760571551724137, -1.534591261538461 ], [ 29.760302068965519, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13115, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.534591261538461 ], [ 29.760571551724137, -1.535130215384615 ], [ 29.760841034482759, -1.535130215384615 ], [ 29.760841034482759, -1.534591261538461 ], [ 29.760571551724137, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13116, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.534591261538461 ], [ 29.761110517241381, -1.535130215384615 ], [ 29.76138, -1.535130215384615 ], [ 29.76138, -1.534591261538461 ], [ 29.761110517241381, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13117, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.534591261538461 ], [ 29.76138, -1.535130215384615 ], [ 29.761649482758621, -1.535130215384615 ], [ 29.761649482758621, -1.534591261538461 ], [ 29.76138, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13118, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.534591261538461 ], [ 29.761649482758621, -1.535130215384615 ], [ 29.761918965517243, -1.535130215384615 ], [ 29.761918965517243, -1.534591261538461 ], [ 29.761649482758621, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13119, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.534591261538461 ], [ 29.761918965517243, -1.535130215384615 ], [ 29.762188448275861, -1.535130215384615 ], [ 29.762188448275861, -1.534591261538461 ], [ 29.761918965517243, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13120, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.534591261538461 ], [ 29.762188448275861, -1.535130215384615 ], [ 29.762457931034483, -1.535130215384615 ], [ 29.762457931034483, -1.534591261538461 ], [ 29.762188448275861, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13121, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.534591261538461 ], [ 29.762457931034483, -1.535130215384615 ], [ 29.762727413793105, -1.535130215384615 ], [ 29.762727413793105, -1.534591261538461 ], [ 29.762457931034483, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13122, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.534591261538461 ], [ 29.762727413793105, -1.535130215384615 ], [ 29.762996896551723, -1.535130215384615 ], [ 29.762996896551723, -1.534591261538461 ], [ 29.762727413793105, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13123, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.534591261538461 ], [ 29.762996896551723, -1.535130215384615 ], [ 29.763266379310345, -1.535130215384615 ], [ 29.763266379310345, -1.534591261538461 ], [ 29.762996896551723, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13124, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.534591261538461 ], [ 29.763266379310345, -1.535130215384615 ], [ 29.763535862068967, -1.535130215384615 ], [ 29.763535862068967, -1.534591261538461 ], [ 29.763266379310345, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13125, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.534591261538461 ], [ 29.763535862068967, -1.535130215384615 ], [ 29.763805344827588, -1.535130215384615 ], [ 29.763805344827588, -1.534591261538461 ], [ 29.763535862068967, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13126, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.534591261538461 ], [ 29.763805344827588, -1.535130215384615 ], [ 29.764074827586207, -1.535130215384615 ], [ 29.764074827586207, -1.534591261538461 ], [ 29.763805344827588, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13127, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.534591261538461 ], [ 29.764074827586207, -1.535130215384615 ], [ 29.764344310344828, -1.535130215384615 ], [ 29.764344310344828, -1.534591261538461 ], [ 29.764074827586207, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13128, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.534591261538461 ], [ 29.764344310344828, -1.535130215384615 ], [ 29.76461379310345, -1.535130215384615 ], [ 29.76461379310345, -1.534591261538461 ], [ 29.764344310344828, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13129, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.534591261538461 ], [ 29.76461379310345, -1.535130215384615 ], [ 29.764883275862069, -1.535130215384615 ], [ 29.764883275862069, -1.534591261538461 ], [ 29.76461379310345, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13130, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.534591261538461 ], [ 29.764883275862069, -1.535130215384615 ], [ 29.76515275862069, -1.535130215384615 ], [ 29.76515275862069, -1.534591261538461 ], [ 29.764883275862069, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13131, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.534591261538461 ], [ 29.76515275862069, -1.535130215384615 ], [ 29.765422241379312, -1.535130215384615 ], [ 29.765422241379312, -1.534591261538461 ], [ 29.76515275862069, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13132, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.534591261538461 ], [ 29.765961206896552, -1.535130215384615 ], [ 29.766230689655174, -1.535130215384615 ], [ 29.766230689655174, -1.534591261538461 ], [ 29.765961206896552, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13133, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.534591261538461 ], [ 29.766230689655174, -1.535130215384615 ], [ 29.766500172413792, -1.535130215384615 ], [ 29.766500172413792, -1.534591261538461 ], [ 29.766230689655174, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13134, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.534591261538461 ], [ 29.766500172413792, -1.535130215384615 ], [ 29.766769655172414, -1.535130215384615 ], [ 29.766769655172414, -1.534591261538461 ], [ 29.766500172413792, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13135, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.534591261538461 ], [ 29.766769655172414, -1.535130215384615 ], [ 29.767039137931036, -1.535130215384615 ], [ 29.767039137931036, -1.534591261538461 ], [ 29.766769655172414, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13136, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.534591261538461 ], [ 29.767039137931036, -1.535130215384615 ], [ 29.767308620689654, -1.535130215384615 ], [ 29.767308620689654, -1.534591261538461 ], [ 29.767039137931036, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13137, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.534591261538461 ], [ 29.767308620689654, -1.535130215384615 ], [ 29.767578103448276, -1.535130215384615 ], [ 29.767578103448276, -1.534591261538461 ], [ 29.767308620689654, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13138, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.534591261538461 ], [ 29.767578103448276, -1.535130215384615 ], [ 29.767847586206898, -1.535130215384615 ], [ 29.767847586206898, -1.534591261538461 ], [ 29.767578103448276, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13139, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.534591261538461 ], [ 29.767847586206898, -1.535130215384615 ], [ 29.768117068965516, -1.535130215384615 ], [ 29.768117068965516, -1.534591261538461 ], [ 29.767847586206898, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13140, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.534591261538461 ], [ 29.768117068965516, -1.535130215384615 ], [ 29.768386551724138, -1.535130215384615 ], [ 29.768386551724138, -1.534591261538461 ], [ 29.768117068965516, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13141, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.534591261538461 ], [ 29.768386551724138, -1.535130215384615 ], [ 29.76865603448276, -1.535130215384615 ], [ 29.76865603448276, -1.534591261538461 ], [ 29.768386551724138, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13142, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.534591261538461 ], [ 29.76865603448276, -1.535130215384615 ], [ 29.768925517241382, -1.535130215384615 ], [ 29.768925517241382, -1.534591261538461 ], [ 29.76865603448276, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13143, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.534591261538461 ], [ 29.768925517241382, -1.535130215384615 ], [ 29.769195, -1.535130215384615 ], [ 29.769195, -1.534591261538461 ], [ 29.768925517241382, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13144, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.534591261538461 ], [ 29.769195, -1.535130215384615 ], [ 29.769464482758622, -1.535130215384615 ], [ 29.769464482758622, -1.534591261538461 ], [ 29.769195, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13145, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772428793103447, -1.533782830769231 ], [ 29.772428793103447, -1.535938646153846 ], [ 29.772698275862069, -1.535938646153846 ], [ 29.772698275862069, -1.533782830769231 ], [ 29.772428793103447, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13146, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.534052307692308 ], [ 29.738204482758622, -1.5364776 ], [ 29.738743448275862, -1.5364776 ], [ 29.738743448275862, -1.534052307692308 ], [ 29.738204482758622, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13147, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.534052307692308 ], [ 29.738743448275862, -1.5364776 ], [ 29.739012931034484, -1.5364776 ], [ 29.739012931034484, -1.534052307692308 ], [ 29.738743448275862, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13148, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.533782830769231 ], [ 29.739821379310346, -1.533782830769231 ], [ 29.739821379310346, -1.534591261538461 ], [ 29.739551896551724, -1.534591261538461 ], [ 29.739551896551724, -1.535669169230769 ], [ 29.739282413793102, -1.535669169230769 ], [ 29.739282413793102, -1.534052307692308 ], [ 29.739551896551724, -1.534052307692308 ], [ 29.739551896551724, -1.533782830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13149, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740629827586208, -1.534052307692308 ], [ 29.740629827586208, -1.535399692307692 ], [ 29.74089931034483, -1.535399692307692 ], [ 29.74089931034483, -1.534052307692308 ], [ 29.740629827586208, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13150, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.534052307692308 ], [ 29.771889827586207, -1.535669169230769 ], [ 29.772159310344829, -1.535669169230769 ], [ 29.772159310344829, -1.534052307692308 ], [ 29.771889827586207, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13151, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.534052307692308 ], [ 29.772159310344829, -1.535669169230769 ], [ 29.772428793103447, -1.535669169230769 ], [ 29.772428793103447, -1.534052307692308 ], [ 29.772159310344829, -1.534052307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13152, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.737935, -1.534321784615384 ], [ 29.737935, -1.536747076923077 ], [ 29.738204482758622, -1.536747076923077 ], [ 29.738204482758622, -1.534321784615384 ], [ 29.737935, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13153, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.534321784615384 ], [ 29.739012931034484, -1.536208123076923 ], [ 29.739282413793102, -1.536208123076923 ], [ 29.739282413793102, -1.534321784615384 ], [ 29.739012931034484, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13154, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.534321784615384 ], [ 29.771350862068967, -1.535399692307692 ], [ 29.771620344827586, -1.535399692307692 ], [ 29.771620344827586, -1.534321784615384 ], [ 29.771350862068967, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13155, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.534321784615384 ], [ 29.771620344827586, -1.535399692307692 ], [ 29.771889827586207, -1.535399692307692 ], [ 29.771889827586207, -1.534321784615384 ], [ 29.771620344827586, -1.534321784615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13156, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.534591261538461 ], [ 29.739551896551724, -1.535399692307692 ], [ 29.739821379310346, -1.535399692307692 ], [ 29.739821379310346, -1.534591261538461 ], [ 29.739551896551724, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13157, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.534591261538461 ], [ 29.756259827586206, -1.535399692307692 ], [ 29.756798793103449, -1.535399692307692 ], [ 29.756798793103449, -1.534591261538461 ], [ 29.756259827586206, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13158, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.534591261538461 ], [ 29.756798793103449, -1.535399692307692 ], [ 29.757068275862068, -1.535399692307692 ], [ 29.757068275862068, -1.534591261538461 ], [ 29.756798793103449, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13159, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.534591261538461 ], [ 29.760841034482759, -1.535399692307692 ], [ 29.761110517241381, -1.535399692307692 ], [ 29.761110517241381, -1.534591261538461 ], [ 29.760841034482759, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13160, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.534591261538461 ], [ 29.765422241379312, -1.535399692307692 ], [ 29.765961206896552, -1.535399692307692 ], [ 29.765961206896552, -1.534591261538461 ], [ 29.765422241379312, -1.534591261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13161, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.535130215384615 ], [ 29.739821379310346, -1.535938646153846 ], [ 29.740090862068964, -1.535938646153846 ], [ 29.740090862068964, -1.535130215384615 ], [ 29.739821379310346, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13162, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.535130215384615 ], [ 29.740090862068964, -1.535938646153846 ], [ 29.740360344827586, -1.535938646153846 ], [ 29.740360344827586, -1.535130215384615 ], [ 29.740090862068964, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13163, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.535130215384615 ], [ 29.740360344827586, -1.535399692307692 ], [ 29.740629827586208, -1.535399692307692 ], [ 29.740629827586208, -1.535130215384615 ], [ 29.740360344827586, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13164, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.535130215384615 ], [ 29.755720862068966, -1.536208123076923 ], [ 29.755990344827588, -1.536208123076923 ], [ 29.755990344827588, -1.535130215384615 ], [ 29.755720862068966, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13165, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.535130215384615 ], [ 29.755990344827588, -1.535399692307692 ], [ 29.756259827586206, -1.535399692307692 ], [ 29.756259827586206, -1.535130215384615 ], [ 29.755990344827588, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13166, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.535130215384615 ], [ 29.757068275862068, -1.536208123076923 ], [ 29.75733775862069, -1.536208123076923 ], [ 29.75733775862069, -1.535130215384615 ], [ 29.757068275862068, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13167, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.535130215384615 ], [ 29.75733775862069, -1.535399692307692 ], [ 29.757607241379311, -1.535399692307692 ], [ 29.757607241379311, -1.535130215384615 ], [ 29.75733775862069, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13168, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.535130215384615 ], [ 29.757607241379311, -1.535399692307692 ], [ 29.757876724137933, -1.535399692307692 ], [ 29.757876724137933, -1.535130215384615 ], [ 29.757607241379311, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13169, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.535130215384615 ], [ 29.757876724137933, -1.535399692307692 ], [ 29.758146206896551, -1.535399692307692 ], [ 29.758146206896551, -1.535130215384615 ], [ 29.757876724137933, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13170, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.535130215384615 ], [ 29.758146206896551, -1.535399692307692 ], [ 29.758415689655173, -1.535399692307692 ], [ 29.758415689655173, -1.535130215384615 ], [ 29.758146206896551, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13171, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.535130215384615 ], [ 29.758415689655173, -1.535399692307692 ], [ 29.758685172413795, -1.535399692307692 ], [ 29.758685172413795, -1.535130215384615 ], [ 29.758415689655173, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13172, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.535130215384615 ], [ 29.758685172413795, -1.535399692307692 ], [ 29.758954655172413, -1.535399692307692 ], [ 29.758954655172413, -1.535130215384615 ], [ 29.758685172413795, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13173, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.535130215384615 ], [ 29.758954655172413, -1.535399692307692 ], [ 29.759224137931035, -1.535399692307692 ], [ 29.759224137931035, -1.535130215384615 ], [ 29.758954655172413, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13174, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.535130215384615 ], [ 29.759224137931035, -1.535399692307692 ], [ 29.759493620689657, -1.535399692307692 ], [ 29.759493620689657, -1.535130215384615 ], [ 29.759224137931035, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13175, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.535130215384615 ], [ 29.759493620689657, -1.535399692307692 ], [ 29.759763103448275, -1.535399692307692 ], [ 29.759763103448275, -1.535130215384615 ], [ 29.759493620689657, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13176, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.535130215384615 ], [ 29.759763103448275, -1.535399692307692 ], [ 29.760032586206897, -1.535399692307692 ], [ 29.760032586206897, -1.535130215384615 ], [ 29.759763103448275, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13177, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.535130215384615 ], [ 29.760032586206897, -1.535399692307692 ], [ 29.760302068965519, -1.535399692307692 ], [ 29.760302068965519, -1.535130215384615 ], [ 29.760032586206897, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13178, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.535130215384615 ], [ 29.760302068965519, -1.535399692307692 ], [ 29.760571551724137, -1.535399692307692 ], [ 29.760571551724137, -1.535130215384615 ], [ 29.760302068965519, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13179, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.535130215384615 ], [ 29.760571551724137, -1.535399692307692 ], [ 29.760841034482759, -1.535399692307692 ], [ 29.760841034482759, -1.535130215384615 ], [ 29.760571551724137, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13180, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.535130215384615 ], [ 29.761110517241381, -1.535399692307692 ], [ 29.76138, -1.535399692307692 ], [ 29.76138, -1.535130215384615 ], [ 29.761110517241381, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13181, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.535130215384615 ], [ 29.76138, -1.535399692307692 ], [ 29.761649482758621, -1.535399692307692 ], [ 29.761649482758621, -1.535130215384615 ], [ 29.76138, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13182, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.535130215384615 ], [ 29.761649482758621, -1.535399692307692 ], [ 29.761918965517243, -1.535399692307692 ], [ 29.761918965517243, -1.535130215384615 ], [ 29.761649482758621, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13183, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.535130215384615 ], [ 29.761918965517243, -1.535399692307692 ], [ 29.762188448275861, -1.535399692307692 ], [ 29.762188448275861, -1.535130215384615 ], [ 29.761918965517243, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13184, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.535130215384615 ], [ 29.762188448275861, -1.535399692307692 ], [ 29.762457931034483, -1.535399692307692 ], [ 29.762457931034483, -1.535130215384615 ], [ 29.762188448275861, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13185, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.535130215384615 ], [ 29.762457931034483, -1.535399692307692 ], [ 29.762727413793105, -1.535399692307692 ], [ 29.762727413793105, -1.535130215384615 ], [ 29.762457931034483, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13186, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.535130215384615 ], [ 29.762727413793105, -1.535399692307692 ], [ 29.762996896551723, -1.535399692307692 ], [ 29.762996896551723, -1.535130215384615 ], [ 29.762727413793105, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13187, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.535130215384615 ], [ 29.762996896551723, -1.535399692307692 ], [ 29.763266379310345, -1.535399692307692 ], [ 29.763266379310345, -1.535130215384615 ], [ 29.762996896551723, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13188, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.535130215384615 ], [ 29.763266379310345, -1.535399692307692 ], [ 29.763535862068967, -1.535399692307692 ], [ 29.763535862068967, -1.535130215384615 ], [ 29.763266379310345, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13189, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.535130215384615 ], [ 29.763535862068967, -1.535399692307692 ], [ 29.763805344827588, -1.535399692307692 ], [ 29.763805344827588, -1.535130215384615 ], [ 29.763535862068967, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13190, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.535130215384615 ], [ 29.763805344827588, -1.535399692307692 ], [ 29.764074827586207, -1.535399692307692 ], [ 29.764074827586207, -1.535130215384615 ], [ 29.763805344827588, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13191, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.535130215384615 ], [ 29.764074827586207, -1.535399692307692 ], [ 29.764344310344828, -1.535399692307692 ], [ 29.764344310344828, -1.535130215384615 ], [ 29.764074827586207, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13192, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.535130215384615 ], [ 29.764344310344828, -1.535399692307692 ], [ 29.76461379310345, -1.535399692307692 ], [ 29.76461379310345, -1.535130215384615 ], [ 29.764344310344828, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13193, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.535130215384615 ], [ 29.76461379310345, -1.535399692307692 ], [ 29.764883275862069, -1.535399692307692 ], [ 29.764883275862069, -1.535130215384615 ], [ 29.76461379310345, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13194, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.535130215384615 ], [ 29.764883275862069, -1.535938646153846 ], [ 29.76515275862069, -1.535938646153846 ], [ 29.76515275862069, -1.535130215384615 ], [ 29.764883275862069, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13195, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.535130215384615 ], [ 29.76515275862069, -1.535399692307692 ], [ 29.765422241379312, -1.535399692307692 ], [ 29.765422241379312, -1.535130215384615 ], [ 29.76515275862069, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13196, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.535130215384615 ], [ 29.765961206896552, -1.535669169230769 ], [ 29.766230689655174, -1.535669169230769 ], [ 29.766230689655174, -1.535130215384615 ], [ 29.765961206896552, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13197, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.535130215384615 ], [ 29.766230689655174, -1.535399692307692 ], [ 29.766500172413792, -1.535399692307692 ], [ 29.766500172413792, -1.535130215384615 ], [ 29.766230689655174, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13198, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.535130215384615 ], [ 29.766500172413792, -1.535399692307692 ], [ 29.766769655172414, -1.535399692307692 ], [ 29.766769655172414, -1.535130215384615 ], [ 29.766500172413792, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13199, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.535130215384615 ], [ 29.766769655172414, -1.535399692307692 ], [ 29.767039137931036, -1.535399692307692 ], [ 29.767039137931036, -1.535130215384615 ], [ 29.766769655172414, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13200, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.535130215384615 ], [ 29.767039137931036, -1.535399692307692 ], [ 29.767308620689654, -1.535399692307692 ], [ 29.767308620689654, -1.535130215384615 ], [ 29.767039137931036, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13201, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.535130215384615 ], [ 29.767308620689654, -1.535399692307692 ], [ 29.767578103448276, -1.535399692307692 ], [ 29.767578103448276, -1.535130215384615 ], [ 29.767308620689654, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13202, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.535130215384615 ], [ 29.767578103448276, -1.535669169230769 ], [ 29.767847586206898, -1.535669169230769 ], [ 29.767847586206898, -1.535130215384615 ], [ 29.767578103448276, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13203, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.535130215384615 ], [ 29.767847586206898, -1.535399692307692 ], [ 29.768117068965516, -1.535399692307692 ], [ 29.768117068965516, -1.535130215384615 ], [ 29.767847586206898, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13204, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.535130215384615 ], [ 29.768117068965516, -1.535399692307692 ], [ 29.768386551724138, -1.535399692307692 ], [ 29.768386551724138, -1.535130215384615 ], [ 29.768117068965516, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13205, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.535130215384615 ], [ 29.768386551724138, -1.535399692307692 ], [ 29.76865603448276, -1.535399692307692 ], [ 29.76865603448276, -1.535130215384615 ], [ 29.768386551724138, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13206, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.535130215384615 ], [ 29.76865603448276, -1.535399692307692 ], [ 29.768925517241382, -1.535399692307692 ], [ 29.768925517241382, -1.535130215384615 ], [ 29.76865603448276, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13207, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.535130215384615 ], [ 29.768925517241382, -1.535399692307692 ], [ 29.769195, -1.535399692307692 ], [ 29.769195, -1.535130215384615 ], [ 29.768925517241382, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13208, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.535130215384615 ], [ 29.769195, -1.535399692307692 ], [ 29.769464482758622, -1.535399692307692 ], [ 29.769464482758622, -1.535130215384615 ], [ 29.769195, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13209, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.535130215384615 ], [ 29.769464482758622, -1.535399692307692 ], [ 29.769733965517243, -1.535399692307692 ], [ 29.769733965517243, -1.535130215384615 ], [ 29.769464482758622, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13210, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.535130215384615 ], [ 29.769733965517243, -1.535399692307692 ], [ 29.770003448275862, -1.535399692307692 ], [ 29.770003448275862, -1.535130215384615 ], [ 29.769733965517243, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13211, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.535130215384615 ], [ 29.770003448275862, -1.535399692307692 ], [ 29.770272931034484, -1.535399692307692 ], [ 29.770272931034484, -1.535130215384615 ], [ 29.770003448275862, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13212, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.535130215384615 ], [ 29.770272931034484, -1.535399692307692 ], [ 29.770542413793105, -1.535399692307692 ], [ 29.770542413793105, -1.535130215384615 ], [ 29.770272931034484, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13213, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.535130215384615 ], [ 29.770542413793105, -1.535399692307692 ], [ 29.770811896551724, -1.535399692307692 ], [ 29.770811896551724, -1.535130215384615 ], [ 29.770542413793105, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13214, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.535130215384615 ], [ 29.770811896551724, -1.535399692307692 ], [ 29.771081379310345, -1.535399692307692 ], [ 29.771081379310345, -1.535130215384615 ], [ 29.770811896551724, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13215, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.535130215384615 ], [ 29.771081379310345, -1.535399692307692 ], [ 29.771350862068967, -1.535399692307692 ], [ 29.771350862068967, -1.535130215384615 ], [ 29.771081379310345, -1.535130215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13216, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.535399692307692 ], [ 29.739551896551724, -1.535669169230769 ], [ 29.739821379310346, -1.535669169230769 ], [ 29.739821379310346, -1.535399692307692 ], [ 29.739551896551724, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13217, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740360344827586, -1.535399692307692 ], [ 29.740360344827586, -1.536208123076923 ], [ 29.740629827586208, -1.536208123076923 ], [ 29.740629827586208, -1.535399692307692 ], [ 29.740360344827586, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13218, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.535399692307692 ], [ 29.755990344827588, -1.535938646153846 ], [ 29.756259827586206, -1.535938646153846 ], [ 29.756259827586206, -1.535399692307692 ], [ 29.755990344827588, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13219, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.535399692307692 ], [ 29.756259827586206, -1.535938646153846 ], [ 29.756798793103449, -1.535938646153846 ], [ 29.756798793103449, -1.535399692307692 ], [ 29.756259827586206, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13220, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.535399692307692 ], [ 29.756798793103449, -1.535938646153846 ], [ 29.757068275862068, -1.535938646153846 ], [ 29.757068275862068, -1.535399692307692 ], [ 29.756798793103449, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13221, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.535399692307692 ], [ 29.75733775862069, -1.535938646153846 ], [ 29.757607241379311, -1.535938646153846 ], [ 29.757607241379311, -1.535399692307692 ], [ 29.75733775862069, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13222, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.535399692307692 ], [ 29.757607241379311, -1.535938646153846 ], [ 29.757876724137933, -1.535938646153846 ], [ 29.757876724137933, -1.535399692307692 ], [ 29.757607241379311, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13223, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.535399692307692 ], [ 29.757876724137933, -1.535938646153846 ], [ 29.758146206896551, -1.535938646153846 ], [ 29.758146206896551, -1.535399692307692 ], [ 29.757876724137933, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13224, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.535399692307692 ], [ 29.758685172413795, -1.535669169230769 ], [ 29.758954655172413, -1.535669169230769 ], [ 29.758954655172413, -1.535399692307692 ], [ 29.758685172413795, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13225, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.535399692307692 ], [ 29.758954655172413, -1.535669169230769 ], [ 29.759224137931035, -1.535669169230769 ], [ 29.759224137931035, -1.535399692307692 ], [ 29.758954655172413, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13226, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.535399692307692 ], [ 29.759224137931035, -1.535669169230769 ], [ 29.759493620689657, -1.535669169230769 ], [ 29.759493620689657, -1.535399692307692 ], [ 29.759224137931035, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13227, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.535399692307692 ], [ 29.759493620689657, -1.535669169230769 ], [ 29.759763103448275, -1.535669169230769 ], [ 29.759763103448275, -1.535399692307692 ], [ 29.759493620689657, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13228, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.535399692307692 ], [ 29.759763103448275, -1.535669169230769 ], [ 29.760032586206897, -1.535669169230769 ], [ 29.760032586206897, -1.535399692307692 ], [ 29.759763103448275, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13229, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.535399692307692 ], [ 29.760032586206897, -1.535669169230769 ], [ 29.760302068965519, -1.535669169230769 ], [ 29.760302068965519, -1.535399692307692 ], [ 29.760032586206897, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13230, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.535399692307692 ], [ 29.760302068965519, -1.535669169230769 ], [ 29.760571551724137, -1.535669169230769 ], [ 29.760571551724137, -1.535399692307692 ], [ 29.760302068965519, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13231, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.535399692307692 ], [ 29.760571551724137, -1.535669169230769 ], [ 29.760841034482759, -1.535669169230769 ], [ 29.760841034482759, -1.535399692307692 ], [ 29.760571551724137, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13232, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.535399692307692 ], [ 29.760841034482759, -1.535669169230769 ], [ 29.761110517241381, -1.535669169230769 ], [ 29.761110517241381, -1.535399692307692 ], [ 29.760841034482759, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13233, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.535399692307692 ], [ 29.761110517241381, -1.535669169230769 ], [ 29.76138, -1.535669169230769 ], [ 29.76138, -1.535399692307692 ], [ 29.761110517241381, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13234, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.535399692307692 ], [ 29.76138, -1.535669169230769 ], [ 29.761649482758621, -1.535669169230769 ], [ 29.761649482758621, -1.535399692307692 ], [ 29.76138, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13235, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.535399692307692 ], [ 29.761649482758621, -1.535669169230769 ], [ 29.761918965517243, -1.535669169230769 ], [ 29.761918965517243, -1.535399692307692 ], [ 29.761649482758621, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13236, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.535399692307692 ], [ 29.761918965517243, -1.535669169230769 ], [ 29.762188448275861, -1.535669169230769 ], [ 29.762188448275861, -1.535399692307692 ], [ 29.761918965517243, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13237, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.535399692307692 ], [ 29.762188448275861, -1.535669169230769 ], [ 29.762457931034483, -1.535669169230769 ], [ 29.762457931034483, -1.535399692307692 ], [ 29.762188448275861, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13238, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.535399692307692 ], [ 29.762457931034483, -1.535669169230769 ], [ 29.762727413793105, -1.535669169230769 ], [ 29.762727413793105, -1.535399692307692 ], [ 29.762457931034483, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13239, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.535399692307692 ], [ 29.762727413793105, -1.535669169230769 ], [ 29.762996896551723, -1.535669169230769 ], [ 29.762996896551723, -1.535399692307692 ], [ 29.762727413793105, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13240, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.535399692307692 ], [ 29.762996896551723, -1.535669169230769 ], [ 29.763266379310345, -1.535669169230769 ], [ 29.763266379310345, -1.535399692307692 ], [ 29.762996896551723, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13241, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.535399692307692 ], [ 29.763266379310345, -1.535669169230769 ], [ 29.763535862068967, -1.535669169230769 ], [ 29.763535862068967, -1.535399692307692 ], [ 29.763266379310345, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13242, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.535399692307692 ], [ 29.763535862068967, -1.535669169230769 ], [ 29.763805344827588, -1.535669169230769 ], [ 29.763805344827588, -1.535399692307692 ], [ 29.763535862068967, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13243, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.535399692307692 ], [ 29.763805344827588, -1.535669169230769 ], [ 29.764074827586207, -1.535669169230769 ], [ 29.764074827586207, -1.535399692307692 ], [ 29.763805344827588, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13244, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.535399692307692 ], [ 29.764074827586207, -1.535669169230769 ], [ 29.764344310344828, -1.535669169230769 ], [ 29.764344310344828, -1.535399692307692 ], [ 29.764074827586207, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13245, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.535399692307692 ], [ 29.764344310344828, -1.535669169230769 ], [ 29.76461379310345, -1.535669169230769 ], [ 29.76461379310345, -1.535399692307692 ], [ 29.764344310344828, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13246, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.535399692307692 ], [ 29.76461379310345, -1.535669169230769 ], [ 29.764883275862069, -1.535669169230769 ], [ 29.764883275862069, -1.535399692307692 ], [ 29.76461379310345, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13247, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.535399692307692 ], [ 29.76515275862069, -1.535938646153846 ], [ 29.765422241379312, -1.535938646153846 ], [ 29.765422241379312, -1.535399692307692 ], [ 29.76515275862069, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13248, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.535399692307692 ], [ 29.765422241379312, -1.535669169230769 ], [ 29.765961206896552, -1.535669169230769 ], [ 29.765961206896552, -1.535399692307692 ], [ 29.765422241379312, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13249, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.535399692307692 ], [ 29.766230689655174, -1.535938646153846 ], [ 29.766500172413792, -1.535938646153846 ], [ 29.766500172413792, -1.535399692307692 ], [ 29.766230689655174, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13250, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.535399692307692 ], [ 29.766500172413792, -1.535938646153846 ], [ 29.766769655172414, -1.535938646153846 ], [ 29.766769655172414, -1.535399692307692 ], [ 29.766500172413792, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13251, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.535399692307692 ], [ 29.766769655172414, -1.535669169230769 ], [ 29.767039137931036, -1.535669169230769 ], [ 29.767039137931036, -1.535399692307692 ], [ 29.766769655172414, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13252, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.535399692307692 ], [ 29.767039137931036, -1.535669169230769 ], [ 29.767308620689654, -1.535669169230769 ], [ 29.767308620689654, -1.535399692307692 ], [ 29.767039137931036, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13253, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.535399692307692 ], [ 29.767308620689654, -1.535669169230769 ], [ 29.767578103448276, -1.535669169230769 ], [ 29.767578103448276, -1.535399692307692 ], [ 29.767308620689654, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13254, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.535399692307692 ], [ 29.767847586206898, -1.535938646153846 ], [ 29.768117068965516, -1.535938646153846 ], [ 29.768117068965516, -1.535399692307692 ], [ 29.767847586206898, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13255, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.535399692307692 ], [ 29.768117068965516, -1.535669169230769 ], [ 29.768386551724138, -1.535669169230769 ], [ 29.768386551724138, -1.535399692307692 ], [ 29.768117068965516, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13256, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.535399692307692 ], [ 29.768386551724138, -1.535669169230769 ], [ 29.76865603448276, -1.535669169230769 ], [ 29.76865603448276, -1.535399692307692 ], [ 29.768386551724138, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13257, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.535399692307692 ], [ 29.76865603448276, -1.535669169230769 ], [ 29.768925517241382, -1.535669169230769 ], [ 29.768925517241382, -1.535399692307692 ], [ 29.76865603448276, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13258, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.535399692307692 ], [ 29.768925517241382, -1.535669169230769 ], [ 29.769195, -1.535669169230769 ], [ 29.769195, -1.535399692307692 ], [ 29.768925517241382, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13259, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.535399692307692 ], [ 29.769195, -1.535669169230769 ], [ 29.769464482758622, -1.535669169230769 ], [ 29.769464482758622, -1.535399692307692 ], [ 29.769195, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13260, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.535399692307692 ], [ 29.769464482758622, -1.535669169230769 ], [ 29.769733965517243, -1.535669169230769 ], [ 29.769733965517243, -1.535399692307692 ], [ 29.769464482758622, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13261, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.535399692307692 ], [ 29.769733965517243, -1.535669169230769 ], [ 29.770003448275862, -1.535669169230769 ], [ 29.770003448275862, -1.535399692307692 ], [ 29.769733965517243, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13262, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.535399692307692 ], [ 29.770003448275862, -1.535669169230769 ], [ 29.770272931034484, -1.535669169230769 ], [ 29.770272931034484, -1.535399692307692 ], [ 29.770003448275862, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13263, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.535399692307692 ], [ 29.770272931034484, -1.535669169230769 ], [ 29.770542413793105, -1.535669169230769 ], [ 29.770542413793105, -1.535399692307692 ], [ 29.770272931034484, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13264, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.535399692307692 ], [ 29.770542413793105, -1.535669169230769 ], [ 29.770811896551724, -1.535669169230769 ], [ 29.770811896551724, -1.535399692307692 ], [ 29.770542413793105, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13265, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.535399692307692 ], [ 29.770811896551724, -1.535669169230769 ], [ 29.771081379310345, -1.535669169230769 ], [ 29.771081379310345, -1.535399692307692 ], [ 29.770811896551724, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13266, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.535399692307692 ], [ 29.771081379310345, -1.535669169230769 ], [ 29.771350862068967, -1.535669169230769 ], [ 29.771350862068967, -1.535399692307692 ], [ 29.771081379310345, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13267, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.535399692307692 ], [ 29.771350862068967, -1.535669169230769 ], [ 29.771620344827586, -1.535669169230769 ], [ 29.771620344827586, -1.535399692307692 ], [ 29.771350862068967, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13268, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.535399692307692 ], [ 29.771620344827586, -1.535669169230769 ], [ 29.771889827586207, -1.535669169230769 ], [ 29.771889827586207, -1.535399692307692 ], [ 29.771620344827586, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13269, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739282413793102, -1.535669169230769 ], [ 29.739282413793102, -1.536208123076923 ], [ 29.739551896551724, -1.536208123076923 ], [ 29.739551896551724, -1.535669169230769 ], [ 29.739282413793102, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13270, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.535669169230769 ], [ 29.739551896551724, -1.535938646153846 ], [ 29.739821379310346, -1.535938646153846 ], [ 29.739821379310346, -1.535669169230769 ], [ 29.739551896551724, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13271, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.535669169230769 ], [ 29.758685172413795, -1.535938646153846 ], [ 29.758954655172413, -1.535938646153846 ], [ 29.758954655172413, -1.535669169230769 ], [ 29.758685172413795, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13272, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.535669169230769 ], [ 29.759763103448275, -1.535938646153846 ], [ 29.760032586206897, -1.535938646153846 ], [ 29.760032586206897, -1.535669169230769 ], [ 29.759763103448275, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13273, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.535669169230769 ], [ 29.760032586206897, -1.535938646153846 ], [ 29.760302068965519, -1.535938646153846 ], [ 29.760302068965519, -1.535669169230769 ], [ 29.760032586206897, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13274, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.535669169230769 ], [ 29.760302068965519, -1.535938646153846 ], [ 29.760571551724137, -1.535938646153846 ], [ 29.760571551724137, -1.535669169230769 ], [ 29.760302068965519, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13275, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.535669169230769 ], [ 29.760571551724137, -1.535938646153846 ], [ 29.760841034482759, -1.535938646153846 ], [ 29.760841034482759, -1.535669169230769 ], [ 29.760571551724137, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13276, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.535669169230769 ], [ 29.760841034482759, -1.535938646153846 ], [ 29.761110517241381, -1.535938646153846 ], [ 29.761110517241381, -1.535669169230769 ], [ 29.760841034482759, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13277, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.535669169230769 ], [ 29.761110517241381, -1.535938646153846 ], [ 29.76138, -1.535938646153846 ], [ 29.76138, -1.535669169230769 ], [ 29.761110517241381, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13278, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.535669169230769 ], [ 29.76138, -1.535938646153846 ], [ 29.761649482758621, -1.535938646153846 ], [ 29.761649482758621, -1.535669169230769 ], [ 29.76138, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13279, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.535669169230769 ], [ 29.761649482758621, -1.535938646153846 ], [ 29.761918965517243, -1.535938646153846 ], [ 29.761918965517243, -1.535669169230769 ], [ 29.761649482758621, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13280, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.535669169230769 ], [ 29.761918965517243, -1.535938646153846 ], [ 29.762188448275861, -1.535938646153846 ], [ 29.762188448275861, -1.535669169230769 ], [ 29.761918965517243, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13281, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.535669169230769 ], [ 29.762188448275861, -1.535938646153846 ], [ 29.762457931034483, -1.535938646153846 ], [ 29.762457931034483, -1.535669169230769 ], [ 29.762188448275861, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13282, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.535669169230769 ], [ 29.762457931034483, -1.535938646153846 ], [ 29.762727413793105, -1.535938646153846 ], [ 29.762727413793105, -1.535669169230769 ], [ 29.762457931034483, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13283, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.535669169230769 ], [ 29.762727413793105, -1.535938646153846 ], [ 29.762996896551723, -1.535938646153846 ], [ 29.762996896551723, -1.535669169230769 ], [ 29.762727413793105, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13284, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.535669169230769 ], [ 29.762996896551723, -1.535938646153846 ], [ 29.763266379310345, -1.535938646153846 ], [ 29.763266379310345, -1.535669169230769 ], [ 29.762996896551723, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13285, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.535669169230769 ], [ 29.763266379310345, -1.535938646153846 ], [ 29.763535862068967, -1.535938646153846 ], [ 29.763535862068967, -1.535669169230769 ], [ 29.763266379310345, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13286, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.535669169230769 ], [ 29.763535862068967, -1.535938646153846 ], [ 29.763805344827588, -1.535938646153846 ], [ 29.763805344827588, -1.535669169230769 ], [ 29.763535862068967, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13287, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.535669169230769 ], [ 29.763805344827588, -1.535938646153846 ], [ 29.764074827586207, -1.535938646153846 ], [ 29.764074827586207, -1.535669169230769 ], [ 29.763805344827588, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13288, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.535669169230769 ], [ 29.764074827586207, -1.535938646153846 ], [ 29.764344310344828, -1.535938646153846 ], [ 29.764344310344828, -1.535669169230769 ], [ 29.764074827586207, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13289, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.535669169230769 ], [ 29.764344310344828, -1.535938646153846 ], [ 29.76461379310345, -1.535938646153846 ], [ 29.76461379310345, -1.535669169230769 ], [ 29.764344310344828, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13290, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.535669169230769 ], [ 29.76461379310345, -1.536208123076923 ], [ 29.764883275862069, -1.536208123076923 ], [ 29.764883275862069, -1.535669169230769 ], [ 29.76461379310345, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13291, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.535669169230769 ], [ 29.765422241379312, -1.5364776 ], [ 29.765961206896552, -1.5364776 ], [ 29.765961206896552, -1.535669169230769 ], [ 29.765422241379312, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13292, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.535669169230769 ], [ 29.765961206896552, -1.536208123076923 ], [ 29.766230689655174, -1.536208123076923 ], [ 29.766230689655174, -1.535669169230769 ], [ 29.765961206896552, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13293, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.535669169230769 ], [ 29.766769655172414, -1.536208123076923 ], [ 29.767039137931036, -1.536208123076923 ], [ 29.767039137931036, -1.535669169230769 ], [ 29.766769655172414, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13294, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.535669169230769 ], [ 29.767039137931036, -1.536208123076923 ], [ 29.767308620689654, -1.536208123076923 ], [ 29.767308620689654, -1.535669169230769 ], [ 29.767039137931036, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13295, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.535669169230769 ], [ 29.767308620689654, -1.535938646153846 ], [ 29.767578103448276, -1.535938646153846 ], [ 29.767578103448276, -1.535669169230769 ], [ 29.767308620689654, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13296, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.535669169230769 ], [ 29.767578103448276, -1.535938646153846 ], [ 29.767847586206898, -1.535938646153846 ], [ 29.767847586206898, -1.535669169230769 ], [ 29.767578103448276, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13297, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.535669169230769 ], [ 29.768117068965516, -1.536208123076923 ], [ 29.768386551724138, -1.536208123076923 ], [ 29.768386551724138, -1.535669169230769 ], [ 29.768117068965516, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13298, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.535669169230769 ], [ 29.768386551724138, -1.535938646153846 ], [ 29.76865603448276, -1.535938646153846 ], [ 29.76865603448276, -1.535669169230769 ], [ 29.768386551724138, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13299, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.535669169230769 ], [ 29.76865603448276, -1.535938646153846 ], [ 29.768925517241382, -1.535938646153846 ], [ 29.768925517241382, -1.535669169230769 ], [ 29.76865603448276, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13300, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.535669169230769 ], [ 29.768925517241382, -1.535938646153846 ], [ 29.769195, -1.535938646153846 ], [ 29.769195, -1.535669169230769 ], [ 29.768925517241382, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13301, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.535669169230769 ], [ 29.769195, -1.535938646153846 ], [ 29.769464482758622, -1.535938646153846 ], [ 29.769464482758622, -1.535669169230769 ], [ 29.769195, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13302, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.535669169230769 ], [ 29.769464482758622, -1.535938646153846 ], [ 29.769733965517243, -1.535938646153846 ], [ 29.769733965517243, -1.535669169230769 ], [ 29.769464482758622, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13303, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.535669169230769 ], [ 29.769733965517243, -1.535938646153846 ], [ 29.770003448275862, -1.535938646153846 ], [ 29.770003448275862, -1.535669169230769 ], [ 29.769733965517243, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13304, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.535669169230769 ], [ 29.770003448275862, -1.535938646153846 ], [ 29.770272931034484, -1.535938646153846 ], [ 29.770272931034484, -1.535669169230769 ], [ 29.770003448275862, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13305, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.535669169230769 ], [ 29.770272931034484, -1.535938646153846 ], [ 29.770542413793105, -1.535938646153846 ], [ 29.770542413793105, -1.535669169230769 ], [ 29.770272931034484, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13306, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.535669169230769 ], [ 29.770542413793105, -1.535938646153846 ], [ 29.770811896551724, -1.535938646153846 ], [ 29.770811896551724, -1.535669169230769 ], [ 29.770542413793105, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13307, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.535669169230769 ], [ 29.770811896551724, -1.535938646153846 ], [ 29.771081379310345, -1.535938646153846 ], [ 29.771081379310345, -1.535669169230769 ], [ 29.770811896551724, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13308, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.535669169230769 ], [ 29.771081379310345, -1.536208123076923 ], [ 29.771350862068967, -1.536208123076923 ], [ 29.771350862068967, -1.535669169230769 ], [ 29.771081379310345, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13309, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.535669169230769 ], [ 29.771350862068967, -1.535938646153846 ], [ 29.771620344827586, -1.535938646153846 ], [ 29.771620344827586, -1.535669169230769 ], [ 29.771350862068967, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13310, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.535669169230769 ], [ 29.771620344827586, -1.535938646153846 ], [ 29.771889827586207, -1.535938646153846 ], [ 29.771889827586207, -1.535669169230769 ], [ 29.771620344827586, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13311, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.535669169230769 ], [ 29.771889827586207, -1.535938646153846 ], [ 29.772159310344829, -1.535938646153846 ], [ 29.772159310344829, -1.535669169230769 ], [ 29.771889827586207, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13312, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.535669169230769 ], [ 29.772159310344829, -1.535938646153846 ], [ 29.772428793103447, -1.535938646153846 ], [ 29.772428793103447, -1.535669169230769 ], [ 29.772159310344829, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13313, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.535938646153846 ], [ 29.739551896551724, -1.5364776 ], [ 29.739821379310346, -1.5364776 ], [ 29.739821379310346, -1.535938646153846 ], [ 29.739551896551724, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13314, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.535938646153846 ], [ 29.739821379310346, -1.5364776 ], [ 29.740090862068964, -1.5364776 ], [ 29.740090862068964, -1.535938646153846 ], [ 29.739821379310346, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13315, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.535938646153846 ], [ 29.740090862068964, -1.536208123076923 ], [ 29.740360344827586, -1.536208123076923 ], [ 29.740360344827586, -1.535938646153846 ], [ 29.740090862068964, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13316, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.535938646153846 ], [ 29.755990344827588, -1.536208123076923 ], [ 29.756259827586206, -1.536208123076923 ], [ 29.756259827586206, -1.535938646153846 ], [ 29.755990344827588, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13317, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.535938646153846 ], [ 29.761649482758621, -1.536208123076923 ], [ 29.761918965517243, -1.536208123076923 ], [ 29.761918965517243, -1.535938646153846 ], [ 29.761649482758621, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13318, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.535938646153846 ], [ 29.761918965517243, -1.536208123076923 ], [ 29.762188448275861, -1.536208123076923 ], [ 29.762188448275861, -1.535938646153846 ], [ 29.761918965517243, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13319, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.535938646153846 ], [ 29.762188448275861, -1.536208123076923 ], [ 29.762457931034483, -1.536208123076923 ], [ 29.762457931034483, -1.535938646153846 ], [ 29.762188448275861, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13320, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.535938646153846 ], [ 29.762457931034483, -1.536208123076923 ], [ 29.762727413793105, -1.536208123076923 ], [ 29.762727413793105, -1.535938646153846 ], [ 29.762457931034483, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13321, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.535938646153846 ], [ 29.762727413793105, -1.536208123076923 ], [ 29.762996896551723, -1.536208123076923 ], [ 29.762996896551723, -1.535938646153846 ], [ 29.762727413793105, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13322, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.535938646153846 ], [ 29.762996896551723, -1.536208123076923 ], [ 29.763266379310345, -1.536208123076923 ], [ 29.763266379310345, -1.535938646153846 ], [ 29.762996896551723, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13323, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.535938646153846 ], [ 29.763266379310345, -1.536208123076923 ], [ 29.763535862068967, -1.536208123076923 ], [ 29.763535862068967, -1.535938646153846 ], [ 29.763266379310345, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13324, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.535938646153846 ], [ 29.763535862068967, -1.536208123076923 ], [ 29.763805344827588, -1.536208123076923 ], [ 29.763805344827588, -1.535938646153846 ], [ 29.763535862068967, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13325, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.535938646153846 ], [ 29.763805344827588, -1.536208123076923 ], [ 29.764074827586207, -1.536208123076923 ], [ 29.764074827586207, -1.535938646153846 ], [ 29.763805344827588, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13326, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.535938646153846 ], [ 29.764074827586207, -1.536208123076923 ], [ 29.764344310344828, -1.536208123076923 ], [ 29.764344310344828, -1.535938646153846 ], [ 29.764074827586207, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13327, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.535938646153846 ], [ 29.764344310344828, -1.536208123076923 ], [ 29.76461379310345, -1.536208123076923 ], [ 29.76461379310345, -1.535938646153846 ], [ 29.764344310344828, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13328, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.535938646153846 ], [ 29.76515275862069, -1.536747076923077 ], [ 29.765422241379312, -1.536747076923077 ], [ 29.765422241379312, -1.535938646153846 ], [ 29.76515275862069, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13329, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.535938646153846 ], [ 29.766230689655174, -1.536747076923077 ], [ 29.766500172413792, -1.536747076923077 ], [ 29.766500172413792, -1.535938646153846 ], [ 29.766230689655174, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13330, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.535938646153846 ], [ 29.766500172413792, -1.5364776 ], [ 29.766769655172414, -1.5364776 ], [ 29.766769655172414, -1.535938646153846 ], [ 29.766500172413792, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13331, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.535938646153846 ], [ 29.767308620689654, -1.5364776 ], [ 29.767578103448276, -1.5364776 ], [ 29.767578103448276, -1.535938646153846 ], [ 29.767308620689654, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13332, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.535938646153846 ], [ 29.767578103448276, -1.5364776 ], [ 29.767847586206898, -1.5364776 ], [ 29.767847586206898, -1.535938646153846 ], [ 29.767578103448276, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13333, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.535938646153846 ], [ 29.767847586206898, -1.536208123076923 ], [ 29.768117068965516, -1.536208123076923 ], [ 29.768117068965516, -1.535938646153846 ], [ 29.767847586206898, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13334, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.535938646153846 ], [ 29.768386551724138, -1.5364776 ], [ 29.76865603448276, -1.5364776 ], [ 29.76865603448276, -1.535938646153846 ], [ 29.768386551724138, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13335, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.535938646153846 ], [ 29.76865603448276, -1.536208123076923 ], [ 29.768925517241382, -1.536208123076923 ], [ 29.768925517241382, -1.535938646153846 ], [ 29.76865603448276, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13336, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.535938646153846 ], [ 29.768925517241382, -1.536208123076923 ], [ 29.769195, -1.536208123076923 ], [ 29.769195, -1.535938646153846 ], [ 29.768925517241382, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13337, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.535938646153846 ], [ 29.769195, -1.536208123076923 ], [ 29.769464482758622, -1.536208123076923 ], [ 29.769464482758622, -1.535938646153846 ], [ 29.769195, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13338, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.535938646153846 ], [ 29.769464482758622, -1.536208123076923 ], [ 29.769733965517243, -1.536208123076923 ], [ 29.769733965517243, -1.535938646153846 ], [ 29.769464482758622, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13339, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.535938646153846 ], [ 29.769733965517243, -1.536208123076923 ], [ 29.770003448275862, -1.536208123076923 ], [ 29.770003448275862, -1.535938646153846 ], [ 29.769733965517243, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13340, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.535938646153846 ], [ 29.770003448275862, -1.536208123076923 ], [ 29.770272931034484, -1.536208123076923 ], [ 29.770272931034484, -1.535938646153846 ], [ 29.770003448275862, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13341, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.535938646153846 ], [ 29.770272931034484, -1.536208123076923 ], [ 29.770542413793105, -1.536208123076923 ], [ 29.770542413793105, -1.535938646153846 ], [ 29.770272931034484, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13342, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.535938646153846 ], [ 29.770542413793105, -1.536208123076923 ], [ 29.770811896551724, -1.536208123076923 ], [ 29.770811896551724, -1.535938646153846 ], [ 29.770542413793105, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13343, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.535938646153846 ], [ 29.770811896551724, -1.536208123076923 ], [ 29.771081379310345, -1.536208123076923 ], [ 29.771081379310345, -1.535938646153846 ], [ 29.770811896551724, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13344, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.535938646153846 ], [ 29.771350862068967, -1.5364776 ], [ 29.771620344827586, -1.5364776 ], [ 29.771620344827586, -1.535938646153846 ], [ 29.771350862068967, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13345, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.535938646153846 ], [ 29.771620344827586, -1.5364776 ], [ 29.771889827586207, -1.5364776 ], [ 29.771889827586207, -1.535938646153846 ], [ 29.771620344827586, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13346, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.535938646153846 ], [ 29.771889827586207, -1.536208123076923 ], [ 29.772159310344829, -1.536208123076923 ], [ 29.772159310344829, -1.535938646153846 ], [ 29.771889827586207, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13347, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772159310344829, -1.535938646153846 ], [ 29.772159310344829, -1.536208123076923 ], [ 29.772428793103447, -1.536208123076923 ], [ 29.772428793103447, -1.535938646153846 ], [ 29.772159310344829, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13348, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.536208123076923 ], [ 29.739012931034484, -1.5364776 ], [ 29.739551896551724, -1.5364776 ], [ 29.739551896551724, -1.536208123076923 ], [ 29.739012931034484, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13349, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.740090862068964, -1.536208123076923 ], [ 29.740090862068964, -1.536747076923077 ], [ 29.740360344827586, -1.536747076923077 ], [ 29.740360344827586, -1.536208123076923 ], [ 29.740090862068964, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13350, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.536208123076923 ], [ 29.762457931034483, -1.5364776 ], [ 29.762727413793105, -1.5364776 ], [ 29.762727413793105, -1.536208123076923 ], [ 29.762457931034483, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13351, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.536208123076923 ], [ 29.762727413793105, -1.5364776 ], [ 29.762996896551723, -1.5364776 ], [ 29.762996896551723, -1.536208123076923 ], [ 29.762727413793105, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13352, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.536208123076923 ], [ 29.762996896551723, -1.5364776 ], [ 29.763266379310345, -1.5364776 ], [ 29.763266379310345, -1.536208123076923 ], [ 29.762996896551723, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13353, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.536208123076923 ], [ 29.763266379310345, -1.5364776 ], [ 29.763535862068967, -1.5364776 ], [ 29.763535862068967, -1.536208123076923 ], [ 29.763266379310345, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13354, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.536208123076923 ], [ 29.763535862068967, -1.5364776 ], [ 29.763805344827588, -1.5364776 ], [ 29.763805344827588, -1.536208123076923 ], [ 29.763535862068967, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13355, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.536208123076923 ], [ 29.763805344827588, -1.5364776 ], [ 29.764074827586207, -1.5364776 ], [ 29.764074827586207, -1.536208123076923 ], [ 29.763805344827588, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13356, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.536208123076923 ], [ 29.764074827586207, -1.5364776 ], [ 29.764344310344828, -1.5364776 ], [ 29.764344310344828, -1.536747076923077 ], [ 29.76461379310345, -1.536747076923077 ], [ 29.76461379310345, -1.536208123076923 ], [ 29.764074827586207, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13357, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.536208123076923 ], [ 29.767039137931036, -1.537016553846154 ], [ 29.767308620689654, -1.537016553846154 ], [ 29.767308620689654, -1.536208123076923 ], [ 29.767039137931036, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13358, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.536208123076923 ], [ 29.768117068965516, -1.536747076923077 ], [ 29.768386551724138, -1.536747076923077 ], [ 29.768386551724138, -1.536208123076923 ], [ 29.768117068965516, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13359, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.536208123076923 ], [ 29.768925517241382, -1.536747076923077 ], [ 29.769195, -1.536747076923077 ], [ 29.769195, -1.536208123076923 ], [ 29.768925517241382, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13360, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.536208123076923 ], [ 29.769195, -1.5364776 ], [ 29.769464482758622, -1.5364776 ], [ 29.769464482758622, -1.536208123076923 ], [ 29.769195, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13361, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.536208123076923 ], [ 29.769464482758622, -1.5364776 ], [ 29.769733965517243, -1.5364776 ], [ 29.769733965517243, -1.536208123076923 ], [ 29.769464482758622, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13362, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.536208123076923 ], [ 29.769733965517243, -1.5364776 ], [ 29.770003448275862, -1.5364776 ], [ 29.770003448275862, -1.536208123076923 ], [ 29.769733965517243, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13363, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.536208123076923 ], [ 29.770003448275862, -1.5364776 ], [ 29.770272931034484, -1.5364776 ], [ 29.770272931034484, -1.536208123076923 ], [ 29.770003448275862, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13364, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.536208123076923 ], [ 29.770272931034484, -1.5364776 ], [ 29.770542413793105, -1.5364776 ], [ 29.770542413793105, -1.536208123076923 ], [ 29.770272931034484, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13365, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.536208123076923 ], [ 29.770542413793105, -1.5364776 ], [ 29.770811896551724, -1.5364776 ], [ 29.770811896551724, -1.536208123076923 ], [ 29.770542413793105, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13366, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.536208123076923 ], [ 29.770811896551724, -1.5364776 ], [ 29.771081379310345, -1.5364776 ], [ 29.771081379310345, -1.536208123076923 ], [ 29.770811896551724, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13367, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.536208123076923 ], [ 29.771081379310345, -1.5364776 ], [ 29.771350862068967, -1.5364776 ], [ 29.771350862068967, -1.536208123076923 ], [ 29.771081379310345, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13368, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771889827586207, -1.536208123076923 ], [ 29.771889827586207, -1.536747076923077 ], [ 29.772159310344829, -1.536747076923077 ], [ 29.772159310344829, -1.536208123076923 ], [ 29.771889827586207, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13369, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.5364776 ], [ 29.738204482758622, -1.536747076923077 ], [ 29.738743448275862, -1.536747076923077 ], [ 29.738743448275862, -1.5364776 ], [ 29.738204482758622, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13370, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.5364776 ], [ 29.739012931034484, -1.537286030769231 ], [ 29.739282413793102, -1.537286030769231 ], [ 29.739282413793102, -1.537016553846154 ], [ 29.739551896551724, -1.537016553846154 ], [ 29.739551896551724, -1.5364776 ], [ 29.739012931034484, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13371, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.5364776 ], [ 29.739551896551724, -1.537016553846154 ], [ 29.739821379310346, -1.537016553846154 ], [ 29.739821379310346, -1.5364776 ], [ 29.739551896551724, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13372, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.5364776 ], [ 29.739821379310346, -1.536747076923077 ], [ 29.740090862068964, -1.536747076923077 ], [ 29.740090862068964, -1.5364776 ], [ 29.739821379310346, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13373, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5364776 ], [ 29.762457931034483, -1.537286030769231 ], [ 29.762727413793105, -1.537286030769231 ], [ 29.762727413793105, -1.5364776 ], [ 29.762457931034483, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13374, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5364776 ], [ 29.762727413793105, -1.536747076923077 ], [ 29.762996896551723, -1.536747076923077 ], [ 29.762996896551723, -1.5364776 ], [ 29.762727413793105, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13375, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.5364776 ], [ 29.762996896551723, -1.536747076923077 ], [ 29.763266379310345, -1.536747076923077 ], [ 29.763266379310345, -1.5364776 ], [ 29.762996896551723, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13376, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5364776 ], [ 29.763266379310345, -1.536747076923077 ], [ 29.763535862068967, -1.536747076923077 ], [ 29.763535862068967, -1.5364776 ], [ 29.763266379310345, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13377, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5364776 ], [ 29.763535862068967, -1.536747076923077 ], [ 29.763805344827588, -1.536747076923077 ], [ 29.763805344827588, -1.5364776 ], [ 29.763535862068967, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13378, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5364776 ], [ 29.763805344827588, -1.536747076923077 ], [ 29.764074827586207, -1.536747076923077 ], [ 29.764074827586207, -1.5364776 ], [ 29.763805344827588, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13379, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.5364776 ], [ 29.769733965517243, -1.536747076923077 ], [ 29.770003448275862, -1.536747076923077 ], [ 29.770003448275862, -1.5364776 ], [ 29.769733965517243, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13380, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.5364776 ], [ 29.770003448275862, -1.536747076923077 ], [ 29.770272931034484, -1.536747076923077 ], [ 29.770272931034484, -1.5364776 ], [ 29.770003448275862, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13381, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.5364776 ], [ 29.770272931034484, -1.536747076923077 ], [ 29.770542413793105, -1.536747076923077 ], [ 29.770542413793105, -1.5364776 ], [ 29.770272931034484, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13382, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.5364776 ], [ 29.770542413793105, -1.537016553846154 ], [ 29.770811896551724, -1.537016553846154 ], [ 29.770811896551724, -1.5364776 ], [ 29.770542413793105, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13383, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.5364776 ], [ 29.770811896551724, -1.536747076923077 ], [ 29.771081379310345, -1.536747076923077 ], [ 29.771081379310345, -1.5364776 ], [ 29.770811896551724, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13384, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.5364776 ], [ 29.771081379310345, -1.536747076923077 ], [ 29.771350862068967, -1.536747076923077 ], [ 29.771350862068967, -1.5364776 ], [ 29.771081379310345, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13385, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.5364776 ], [ 29.771350862068967, -1.536747076923077 ], [ 29.771620344827586, -1.536747076923077 ], [ 29.771620344827586, -1.5364776 ], [ 29.771350862068967, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13386, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.5364776 ], [ 29.771620344827586, -1.536747076923077 ], [ 29.771889827586207, -1.536747076923077 ], [ 29.771889827586207, -1.5364776 ], [ 29.771620344827586, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13387, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739821379310346, -1.536747076923077 ], [ 29.739821379310346, -1.537286030769231 ], [ 29.740090862068964, -1.537286030769231 ], [ 29.740090862068964, -1.536747076923077 ], [ 29.739821379310346, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13388, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.536747076923077 ], [ 29.762727413793105, -1.537286030769231 ], [ 29.762996896551723, -1.537286030769231 ], [ 29.762996896551723, -1.536747076923077 ], [ 29.762727413793105, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13389, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.536747076923077 ], [ 29.762996896551723, -1.537286030769231 ], [ 29.763266379310345, -1.537286030769231 ], [ 29.763266379310345, -1.536747076923077 ], [ 29.762996896551723, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13390, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.536747076923077 ], [ 29.763266379310345, -1.537016553846154 ], [ 29.763535862068967, -1.537016553846154 ], [ 29.763535862068967, -1.536747076923077 ], [ 29.763266379310345, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13391, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.536747076923077 ], [ 29.763535862068967, -1.537016553846154 ], [ 29.763805344827588, -1.537016553846154 ], [ 29.763805344827588, -1.536747076923077 ], [ 29.763535862068967, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13392, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.536747076923077 ], [ 29.771350862068967, -1.537016553846154 ], [ 29.771620344827586, -1.537016553846154 ], [ 29.771620344827586, -1.536747076923077 ], [ 29.771350862068967, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13393, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771620344827586, -1.536747076923077 ], [ 29.771620344827586, -1.537016553846154 ], [ 29.771889827586207, -1.537016553846154 ], [ 29.771889827586207, -1.536747076923077 ], [ 29.771620344827586, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13394, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.537016553846154 ], [ 29.739551896551724, -1.537286030769231 ], [ 29.739821379310346, -1.537286030769231 ], [ 29.739821379310346, -1.537016553846154 ], [ 29.739551896551724, -1.537016553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13395, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.537016553846154 ], [ 29.763266379310345, -1.537286030769231 ], [ 29.763535862068967, -1.537286030769231 ], [ 29.763535862068967, -1.537016553846154 ], [ 29.763266379310345, -1.537016553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13396, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.537016553846154 ], [ 29.763535862068967, -1.537286030769231 ], [ 29.763805344827588, -1.537286030769231 ], [ 29.763805344827588, -1.537016553846154 ], [ 29.763535862068967, -1.537016553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13397, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.535399692307692 ], [ 29.758146206896551, -1.538363938461538 ], [ 29.758415689655173, -1.538363938461538 ], [ 29.758415689655173, -1.535399692307692 ], [ 29.758146206896551, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13398, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.535399692307692 ], [ 29.758415689655173, -1.538363938461538 ], [ 29.758685172413795, -1.538363938461538 ], [ 29.758685172413795, -1.535399692307692 ], [ 29.758415689655173, -1.535399692307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13399, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.535669169230769 ], [ 29.758954655172413, -1.538094461538462 ], [ 29.759224137931035, -1.538094461538462 ], [ 29.759224137931035, -1.535669169230769 ], [ 29.758954655172413, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13400, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.535669169230769 ], [ 29.759224137931035, -1.538094461538462 ], [ 29.759493620689657, -1.538094461538462 ], [ 29.759493620689657, -1.535669169230769 ], [ 29.759224137931035, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13401, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.535669169230769 ], [ 29.759493620689657, -1.538094461538462 ], [ 29.759763103448275, -1.538094461538462 ], [ 29.759763103448275, -1.535669169230769 ], [ 29.759493620689657, -1.535669169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13402, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.535938646153846 ], [ 29.756259827586206, -1.538902892307692 ], [ 29.756798793103449, -1.538902892307692 ], [ 29.756798793103449, -1.535938646153846 ], [ 29.756259827586206, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13403, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.535938646153846 ], [ 29.756798793103449, -1.538902892307692 ], [ 29.757068275862068, -1.538902892307692 ], [ 29.757068275862068, -1.535938646153846 ], [ 29.756798793103449, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13404, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.535938646153846 ], [ 29.75733775862069, -1.538633415384615 ], [ 29.757607241379311, -1.538633415384615 ], [ 29.757607241379311, -1.535938646153846 ], [ 29.75733775862069, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13405, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.535938646153846 ], [ 29.757607241379311, -1.538633415384615 ], [ 29.757876724137933, -1.538633415384615 ], [ 29.757876724137933, -1.535938646153846 ], [ 29.757607241379311, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13406, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.535938646153846 ], [ 29.757876724137933, -1.538633415384615 ], [ 29.758146206896551, -1.538633415384615 ], [ 29.758146206896551, -1.535938646153846 ], [ 29.757876724137933, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13407, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.535938646153846 ], [ 29.758685172413795, -1.538363938461538 ], [ 29.758954655172413, -1.538363938461538 ], [ 29.758954655172413, -1.535938646153846 ], [ 29.758685172413795, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13408, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.535938646153846 ], [ 29.759763103448275, -1.537824984615385 ], [ 29.760032586206897, -1.537824984615385 ], [ 29.760032586206897, -1.535938646153846 ], [ 29.759763103448275, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13409, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.535938646153846 ], [ 29.760032586206897, -1.537824984615385 ], [ 29.760302068965519, -1.537824984615385 ], [ 29.760302068965519, -1.535938646153846 ], [ 29.760032586206897, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13410, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.535938646153846 ], [ 29.760302068965519, -1.537824984615385 ], [ 29.760571551724137, -1.537824984615385 ], [ 29.760571551724137, -1.535938646153846 ], [ 29.760302068965519, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13411, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.535938646153846 ], [ 29.760571551724137, -1.537824984615385 ], [ 29.760841034482759, -1.537824984615385 ], [ 29.760841034482759, -1.535938646153846 ], [ 29.760571551724137, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13412, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.535938646153846 ], [ 29.760841034482759, -1.537824984615385 ], [ 29.761110517241381, -1.537824984615385 ], [ 29.761110517241381, -1.535938646153846 ], [ 29.760841034482759, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13413, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.535938646153846 ], [ 29.761110517241381, -1.537824984615385 ], [ 29.76138, -1.537824984615385 ], [ 29.76138, -1.535938646153846 ], [ 29.761110517241381, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13414, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.535938646153846 ], [ 29.76138, -1.537824984615385 ], [ 29.761649482758621, -1.537824984615385 ], [ 29.761649482758621, -1.535938646153846 ], [ 29.76138, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13415, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.535938646153846 ], [ 29.764883275862069, -1.537555507692308 ], [ 29.76515275862069, -1.537555507692308 ], [ 29.76515275862069, -1.535938646153846 ], [ 29.764883275862069, -1.535938646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13416, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.536208123076923 ], [ 29.755990344827588, -1.539172369230769 ], [ 29.756259827586206, -1.539172369230769 ], [ 29.756259827586206, -1.536208123076923 ], [ 29.755990344827588, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13417, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.536208123076923 ], [ 29.757068275862068, -1.538633415384615 ], [ 29.75733775862069, -1.538633415384615 ], [ 29.75733775862069, -1.536208123076923 ], [ 29.757068275862068, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13418, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.536208123076923 ], [ 29.761649482758621, -1.537555507692308 ], [ 29.761918965517243, -1.537555507692308 ], [ 29.761918965517243, -1.536208123076923 ], [ 29.761649482758621, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13419, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.536208123076923 ], [ 29.761918965517243, -1.537555507692308 ], [ 29.762188448275861, -1.537555507692308 ], [ 29.762188448275861, -1.536208123076923 ], [ 29.761918965517243, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13420, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.536208123076923 ], [ 29.762188448275861, -1.537555507692308 ], [ 29.762457931034483, -1.537555507692308 ], [ 29.762457931034483, -1.536208123076923 ], [ 29.762188448275861, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13421, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.536208123076923 ], [ 29.76461379310345, -1.537555507692308 ], [ 29.764883275862069, -1.537555507692308 ], [ 29.764883275862069, -1.536208123076923 ], [ 29.76461379310345, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13422, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.536208123076923 ], [ 29.765961206896552, -1.537555507692308 ], [ 29.766230689655174, -1.537555507692308 ], [ 29.766230689655174, -1.536208123076923 ], [ 29.765961206896552, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13423, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.536208123076923 ], [ 29.766769655172414, -1.537824984615385 ], [ 29.767039137931036, -1.537824984615385 ], [ 29.767039137931036, -1.536208123076923 ], [ 29.766769655172414, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13424, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.536208123076923 ], [ 29.767847586206898, -1.537824984615385 ], [ 29.768117068965516, -1.537824984615385 ], [ 29.768117068965516, -1.536208123076923 ], [ 29.767847586206898, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13425, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.536208123076923 ], [ 29.76865603448276, -1.538094461538462 ], [ 29.768925517241382, -1.538094461538462 ], [ 29.768925517241382, -1.536208123076923 ], [ 29.76865603448276, -1.536208123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13426, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738743448275862, -1.5364776 ], [ 29.738743448275862, -1.537824984615385 ], [ 29.739012931034484, -1.537824984615385 ], [ 29.739012931034484, -1.537555507692308 ], [ 29.739551896551724, -1.537555507692308 ], [ 29.739551896551724, -1.537016553846154 ], [ 29.739282413793102, -1.537016553846154 ], [ 29.739282413793102, -1.537286030769231 ], [ 29.739012931034484, -1.537286030769231 ], [ 29.739012931034484, -1.5364776 ], [ 29.738743448275862, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13427, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.5364776 ], [ 29.765422241379312, -1.537824984615385 ], [ 29.765961206896552, -1.537824984615385 ], [ 29.765961206896552, -1.5364776 ], [ 29.765422241379312, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13428, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5364776 ], [ 29.766500172413792, -1.537824984615385 ], [ 29.766769655172414, -1.537824984615385 ], [ 29.766769655172414, -1.5364776 ], [ 29.766500172413792, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13429, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.5364776 ], [ 29.767308620689654, -1.538094461538462 ], [ 29.767578103448276, -1.538094461538462 ], [ 29.767578103448276, -1.5364776 ], [ 29.767308620689654, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13430, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.5364776 ], [ 29.767578103448276, -1.538094461538462 ], [ 29.767847586206898, -1.538094461538462 ], [ 29.767847586206898, -1.5364776 ], [ 29.767578103448276, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13431, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.5364776 ], [ 29.768386551724138, -1.538094461538462 ], [ 29.76865603448276, -1.538094461538462 ], [ 29.76865603448276, -1.5364776 ], [ 29.768386551724138, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13432, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.5364776 ], [ 29.769195, -1.538363938461538 ], [ 29.769464482758622, -1.538363938461538 ], [ 29.769464482758622, -1.5364776 ], [ 29.769195, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13433, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.5364776 ], [ 29.769464482758622, -1.538363938461538 ], [ 29.769733965517243, -1.538363938461538 ], [ 29.769733965517243, -1.5364776 ], [ 29.769464482758622, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13434, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.738204482758622, -1.536747076923077 ], [ 29.738204482758622, -1.538363938461538 ], [ 29.738743448275862, -1.538363938461538 ], [ 29.738743448275862, -1.538094461538462 ], [ 29.739282413793102, -1.538094461538462 ], [ 29.739282413793102, -1.537824984615385 ], [ 29.738743448275862, -1.537824984615385 ], [ 29.738743448275862, -1.536747076923077 ], [ 29.738204482758622, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13435, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5364776 ], [ 29.764344310344828, -1.5364776 ], [ 29.764344310344828, -1.537555507692308 ], [ 29.764074827586207, -1.537555507692308 ], [ 29.764074827586207, -1.537016553846154 ], [ 29.763805344827588, -1.537016553846154 ], [ 29.763805344827588, -1.536747076923077 ], [ 29.764074827586207, -1.536747076923077 ], [ 29.764074827586207, -1.5364776 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13436, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.536747076923077 ], [ 29.764344310344828, -1.537824984615385 ], [ 29.76461379310345, -1.537824984615385 ], [ 29.76461379310345, -1.536747076923077 ], [ 29.764344310344828, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13437, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.536747076923077 ], [ 29.76515275862069, -1.537824984615385 ], [ 29.765422241379312, -1.537824984615385 ], [ 29.765422241379312, -1.536747076923077 ], [ 29.76515275862069, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13438, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.536747076923077 ], [ 29.766230689655174, -1.538094461538462 ], [ 29.766500172413792, -1.538094461538462 ], [ 29.766500172413792, -1.536747076923077 ], [ 29.766230689655174, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13439, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.536747076923077 ], [ 29.768117068965516, -1.538094461538462 ], [ 29.768386551724138, -1.538094461538462 ], [ 29.768386551724138, -1.536747076923077 ], [ 29.768117068965516, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13440, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.536747076923077 ], [ 29.768925517241382, -1.538363938461538 ], [ 29.769195, -1.538363938461538 ], [ 29.769195, -1.536747076923077 ], [ 29.768925517241382, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13441, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.536747076923077 ], [ 29.769733965517243, -1.538633415384615 ], [ 29.770003448275862, -1.538633415384615 ], [ 29.770003448275862, -1.536747076923077 ], [ 29.769733965517243, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13442, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.536747076923077 ], [ 29.770003448275862, -1.538633415384615 ], [ 29.770272931034484, -1.538633415384615 ], [ 29.770272931034484, -1.536747076923077 ], [ 29.770003448275862, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13443, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.536747076923077 ], [ 29.770272931034484, -1.538633415384615 ], [ 29.770542413793105, -1.538633415384615 ], [ 29.770542413793105, -1.536747076923077 ], [ 29.770272931034484, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13444, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.536747076923077 ], [ 29.770811896551724, -1.538902892307692 ], [ 29.771081379310345, -1.538902892307692 ], [ 29.771081379310345, -1.536747076923077 ], [ 29.770811896551724, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13445, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.536747076923077 ], [ 29.771081379310345, -1.538902892307692 ], [ 29.771350862068967, -1.538902892307692 ], [ 29.771350862068967, -1.536747076923077 ], [ 29.771081379310345, -1.536747076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13446, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.537016553846154 ], [ 29.763805344827588, -1.537555507692308 ], [ 29.764074827586207, -1.537555507692308 ], [ 29.764074827586207, -1.537016553846154 ], [ 29.763805344827588, -1.537016553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13447, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.537016553846154 ], [ 29.767039137931036, -1.538094461538462 ], [ 29.767308620689654, -1.538094461538462 ], [ 29.767308620689654, -1.537016553846154 ], [ 29.767039137931036, -1.537016553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13448, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.537016553846154 ], [ 29.770542413793105, -1.538633415384615 ], [ 29.770811896551724, -1.538633415384615 ], [ 29.770811896551724, -1.537016553846154 ], [ 29.770542413793105, -1.537016553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13449, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771350862068967, -1.537016553846154 ], [ 29.771350862068967, -1.539172369230769 ], [ 29.771620344827586, -1.539172369230769 ], [ 29.771620344827586, -1.537016553846154 ], [ 29.771350862068967, -1.537016553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13450, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.537286030769231 ], [ 29.762457931034483, -1.537555507692308 ], [ 29.762727413793105, -1.537555507692308 ], [ 29.762727413793105, -1.537286030769231 ], [ 29.762457931034483, -1.537286030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13451, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.537286030769231 ], [ 29.762727413793105, -1.537555507692308 ], [ 29.762996896551723, -1.537555507692308 ], [ 29.762996896551723, -1.537286030769231 ], [ 29.762727413793105, -1.537286030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13452, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.537286030769231 ], [ 29.762996896551723, -1.537555507692308 ], [ 29.763266379310345, -1.537555507692308 ], [ 29.763266379310345, -1.537286030769231 ], [ 29.762996896551723, -1.537286030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13453, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.537286030769231 ], [ 29.763266379310345, -1.537555507692308 ], [ 29.763535862068967, -1.537555507692308 ], [ 29.763535862068967, -1.537286030769231 ], [ 29.763266379310345, -1.537286030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13454, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.537286030769231 ], [ 29.763535862068967, -1.537824984615385 ], [ 29.763805344827588, -1.537824984615385 ], [ 29.763805344827588, -1.537286030769231 ], [ 29.763535862068967, -1.537286030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13455, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739012931034484, -1.537555507692308 ], [ 29.739012931034484, -1.537824984615385 ], [ 29.739282413793102, -1.537824984615385 ], [ 29.739282413793102, -1.537555507692308 ], [ 29.739012931034484, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13456, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.739551896551724, -1.537286030769231 ], [ 29.739821379310346, -1.537286030769231 ], [ 29.739821379310346, -1.537824984615385 ], [ 29.739282413793102, -1.537824984615385 ], [ 29.739282413793102, -1.537555507692308 ], [ 29.739551896551724, -1.537555507692308 ], [ 29.739551896551724, -1.537286030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13457, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.537555507692308 ], [ 29.761649482758621, -1.537824984615385 ], [ 29.761918965517243, -1.537824984615385 ], [ 29.761918965517243, -1.537555507692308 ], [ 29.761649482758621, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13458, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.537555507692308 ], [ 29.761918965517243, -1.537824984615385 ], [ 29.762188448275861, -1.537824984615385 ], [ 29.762188448275861, -1.537555507692308 ], [ 29.761918965517243, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13459, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.537555507692308 ], [ 29.762188448275861, -1.537824984615385 ], [ 29.762457931034483, -1.537824984615385 ], [ 29.762457931034483, -1.537555507692308 ], [ 29.762188448275861, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13460, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.537555507692308 ], [ 29.762457931034483, -1.537824984615385 ], [ 29.762727413793105, -1.537824984615385 ], [ 29.762727413793105, -1.537555507692308 ], [ 29.762457931034483, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13461, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.537555507692308 ], [ 29.762727413793105, -1.537824984615385 ], [ 29.762996896551723, -1.537824984615385 ], [ 29.762996896551723, -1.537555507692308 ], [ 29.762727413793105, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13462, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.537555507692308 ], [ 29.762996896551723, -1.537824984615385 ], [ 29.763266379310345, -1.537824984615385 ], [ 29.763266379310345, -1.537555507692308 ], [ 29.762996896551723, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13463, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.537555507692308 ], [ 29.763266379310345, -1.538094461538462 ], [ 29.763535862068967, -1.538094461538462 ], [ 29.763535862068967, -1.537555507692308 ], [ 29.763266379310345, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13464, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.537555507692308 ], [ 29.763805344827588, -1.538094461538462 ], [ 29.764074827586207, -1.538094461538462 ], [ 29.764074827586207, -1.537555507692308 ], [ 29.763805344827588, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13465, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.537555507692308 ], [ 29.764074827586207, -1.537824984615385 ], [ 29.764344310344828, -1.537824984615385 ], [ 29.764344310344828, -1.537555507692308 ], [ 29.764074827586207, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13466, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.537555507692308 ], [ 29.76461379310345, -1.538094461538462 ], [ 29.764883275862069, -1.538094461538462 ], [ 29.764883275862069, -1.537555507692308 ], [ 29.76461379310345, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13467, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.537555507692308 ], [ 29.764883275862069, -1.538094461538462 ], [ 29.76515275862069, -1.538094461538462 ], [ 29.76515275862069, -1.537555507692308 ], [ 29.764883275862069, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13468, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.537555507692308 ], [ 29.765961206896552, -1.538094461538462 ], [ 29.766230689655174, -1.538094461538462 ], [ 29.766230689655174, -1.537555507692308 ], [ 29.765961206896552, -1.537555507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13469, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.537824984615385 ], [ 29.759763103448275, -1.538094461538462 ], [ 29.760032586206897, -1.538094461538462 ], [ 29.760032586206897, -1.537824984615385 ], [ 29.759763103448275, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13470, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.537824984615385 ], [ 29.760032586206897, -1.538094461538462 ], [ 29.760302068965519, -1.538094461538462 ], [ 29.760302068965519, -1.537824984615385 ], [ 29.760032586206897, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13471, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.537824984615385 ], [ 29.760302068965519, -1.538094461538462 ], [ 29.760571551724137, -1.538094461538462 ], [ 29.760571551724137, -1.537824984615385 ], [ 29.760302068965519, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13472, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.537824984615385 ], [ 29.760571551724137, -1.538094461538462 ], [ 29.760841034482759, -1.538094461538462 ], [ 29.760841034482759, -1.537824984615385 ], [ 29.760571551724137, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13473, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.537824984615385 ], [ 29.760841034482759, -1.538094461538462 ], [ 29.761110517241381, -1.538094461538462 ], [ 29.761110517241381, -1.537824984615385 ], [ 29.760841034482759, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13474, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.537824984615385 ], [ 29.761110517241381, -1.538094461538462 ], [ 29.76138, -1.538094461538462 ], [ 29.76138, -1.537824984615385 ], [ 29.761110517241381, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13475, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.537824984615385 ], [ 29.76138, -1.538094461538462 ], [ 29.761649482758621, -1.538094461538462 ], [ 29.761649482758621, -1.537824984615385 ], [ 29.76138, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13476, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.537824984615385 ], [ 29.761649482758621, -1.538094461538462 ], [ 29.761918965517243, -1.538094461538462 ], [ 29.761918965517243, -1.537824984615385 ], [ 29.761649482758621, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13477, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.537824984615385 ], [ 29.761918965517243, -1.538094461538462 ], [ 29.762188448275861, -1.538094461538462 ], [ 29.762188448275861, -1.537824984615385 ], [ 29.761918965517243, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13478, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.537824984615385 ], [ 29.762188448275861, -1.538094461538462 ], [ 29.762457931034483, -1.538094461538462 ], [ 29.762457931034483, -1.537824984615385 ], [ 29.762188448275861, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13479, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.537824984615385 ], [ 29.762457931034483, -1.538094461538462 ], [ 29.762727413793105, -1.538094461538462 ], [ 29.762727413793105, -1.537824984615385 ], [ 29.762457931034483, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13480, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.537824984615385 ], [ 29.762727413793105, -1.538094461538462 ], [ 29.762996896551723, -1.538094461538462 ], [ 29.762996896551723, -1.537824984615385 ], [ 29.762727413793105, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13481, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.537824984615385 ], [ 29.762996896551723, -1.538363938461538 ], [ 29.763266379310345, -1.538363938461538 ], [ 29.763266379310345, -1.537824984615385 ], [ 29.762996896551723, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13482, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.537824984615385 ], [ 29.763535862068967, -1.538094461538462 ], [ 29.763805344827588, -1.538094461538462 ], [ 29.763805344827588, -1.537824984615385 ], [ 29.763535862068967, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13483, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.537824984615385 ], [ 29.764344310344828, -1.538363938461538 ], [ 29.76461379310345, -1.538363938461538 ], [ 29.76461379310345, -1.537824984615385 ], [ 29.764344310344828, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13484, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.537824984615385 ], [ 29.76515275862069, -1.538633415384615 ], [ 29.765422241379312, -1.538633415384615 ], [ 29.765422241379312, -1.537824984615385 ], [ 29.76515275862069, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13485, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.537824984615385 ], [ 29.765422241379312, -1.538363938461538 ], [ 29.765961206896552, -1.538363938461538 ], [ 29.765961206896552, -1.537824984615385 ], [ 29.765422241379312, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13486, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.537824984615385 ], [ 29.766500172413792, -1.538363938461538 ], [ 29.766769655172414, -1.538363938461538 ], [ 29.766769655172414, -1.537824984615385 ], [ 29.766500172413792, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13487, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.537824984615385 ], [ 29.766769655172414, -1.538363938461538 ], [ 29.767039137931036, -1.538363938461538 ], [ 29.767039137931036, -1.537824984615385 ], [ 29.766769655172414, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13488, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.537824984615385 ], [ 29.767847586206898, -1.538363938461538 ], [ 29.768117068965516, -1.538363938461538 ], [ 29.768117068965516, -1.537824984615385 ], [ 29.767847586206898, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13489, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.538094461538462 ], [ 29.758954655172413, -1.538363938461538 ], [ 29.759224137931035, -1.538363938461538 ], [ 29.759224137931035, -1.538094461538462 ], [ 29.758954655172413, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13490, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.538094461538462 ], [ 29.759224137931035, -1.538363938461538 ], [ 29.759493620689657, -1.538363938461538 ], [ 29.759493620689657, -1.538094461538462 ], [ 29.759224137931035, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13491, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.538094461538462 ], [ 29.759493620689657, -1.538363938461538 ], [ 29.759763103448275, -1.538363938461538 ], [ 29.759763103448275, -1.538094461538462 ], [ 29.759493620689657, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13492, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.538094461538462 ], [ 29.759763103448275, -1.538363938461538 ], [ 29.760032586206897, -1.538363938461538 ], [ 29.760032586206897, -1.538094461538462 ], [ 29.759763103448275, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13493, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.538094461538462 ], [ 29.760032586206897, -1.538363938461538 ], [ 29.760302068965519, -1.538363938461538 ], [ 29.760302068965519, -1.538094461538462 ], [ 29.760032586206897, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13494, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.538094461538462 ], [ 29.760302068965519, -1.538363938461538 ], [ 29.760571551724137, -1.538363938461538 ], [ 29.760571551724137, -1.538094461538462 ], [ 29.760302068965519, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13495, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.538094461538462 ], [ 29.760571551724137, -1.538363938461538 ], [ 29.760841034482759, -1.538363938461538 ], [ 29.760841034482759, -1.538094461538462 ], [ 29.760571551724137, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13496, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.538094461538462 ], [ 29.760841034482759, -1.538363938461538 ], [ 29.761110517241381, -1.538363938461538 ], [ 29.761110517241381, -1.538094461538462 ], [ 29.760841034482759, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13497, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.538094461538462 ], [ 29.761110517241381, -1.538363938461538 ], [ 29.76138, -1.538363938461538 ], [ 29.76138, -1.538094461538462 ], [ 29.761110517241381, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13498, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.538094461538462 ], [ 29.76138, -1.538363938461538 ], [ 29.761649482758621, -1.538363938461538 ], [ 29.761649482758621, -1.538094461538462 ], [ 29.76138, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13499, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.538094461538462 ], [ 29.761649482758621, -1.538363938461538 ], [ 29.761918965517243, -1.538363938461538 ], [ 29.761918965517243, -1.538094461538462 ], [ 29.761649482758621, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13500, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.538094461538462 ], [ 29.761918965517243, -1.538363938461538 ], [ 29.762188448275861, -1.538363938461538 ], [ 29.762188448275861, -1.538094461538462 ], [ 29.761918965517243, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13501, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.538094461538462 ], [ 29.762188448275861, -1.538363938461538 ], [ 29.762457931034483, -1.538363938461538 ], [ 29.762457931034483, -1.538094461538462 ], [ 29.762188448275861, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13502, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.538094461538462 ], [ 29.762457931034483, -1.538363938461538 ], [ 29.762727413793105, -1.538363938461538 ], [ 29.762727413793105, -1.538094461538462 ], [ 29.762457931034483, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13503, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.538094461538462 ], [ 29.762727413793105, -1.538633415384615 ], [ 29.762996896551723, -1.538633415384615 ], [ 29.762996896551723, -1.538094461538462 ], [ 29.762727413793105, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13504, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.538094461538462 ], [ 29.763266379310345, -1.538363938461538 ], [ 29.763535862068967, -1.538363938461538 ], [ 29.763535862068967, -1.538094461538462 ], [ 29.763266379310345, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13505, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.538094461538462 ], [ 29.763535862068967, -1.538363938461538 ], [ 29.763805344827588, -1.538363938461538 ], [ 29.763805344827588, -1.538094461538462 ], [ 29.763535862068967, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13506, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.538094461538462 ], [ 29.763805344827588, -1.538363938461538 ], [ 29.764074827586207, -1.538363938461538 ], [ 29.764074827586207, -1.538094461538462 ], [ 29.763805344827588, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13507, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.538094461538462 ], [ 29.764883275862069, -1.538902892307692 ], [ 29.76515275862069, -1.538902892307692 ], [ 29.76515275862069, -1.538094461538462 ], [ 29.764883275862069, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13508, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.538094461538462 ], [ 29.765961206896552, -1.538902892307692 ], [ 29.766230689655174, -1.538902892307692 ], [ 29.766230689655174, -1.538094461538462 ], [ 29.765961206896552, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13509, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.538094461538462 ], [ 29.766230689655174, -1.538633415384615 ], [ 29.766500172413792, -1.538633415384615 ], [ 29.766500172413792, -1.538094461538462 ], [ 29.766230689655174, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13510, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.538094461538462 ], [ 29.767039137931036, -1.538633415384615 ], [ 29.767308620689654, -1.538633415384615 ], [ 29.767308620689654, -1.538094461538462 ], [ 29.767039137931036, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13511, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.538094461538462 ], [ 29.767308620689654, -1.538633415384615 ], [ 29.767578103448276, -1.538633415384615 ], [ 29.767578103448276, -1.538094461538462 ], [ 29.767308620689654, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13512, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.538094461538462 ], [ 29.767578103448276, -1.538363938461538 ], [ 29.767847586206898, -1.538363938461538 ], [ 29.767847586206898, -1.538094461538462 ], [ 29.767578103448276, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13513, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.538094461538462 ], [ 29.768117068965516, -1.538633415384615 ], [ 29.768386551724138, -1.538633415384615 ], [ 29.768386551724138, -1.538094461538462 ], [ 29.768117068965516, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13514, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.538094461538462 ], [ 29.768386551724138, -1.538363938461538 ], [ 29.76865603448276, -1.538363938461538 ], [ 29.76865603448276, -1.538094461538462 ], [ 29.768386551724138, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13515, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.538094461538462 ], [ 29.76865603448276, -1.538363938461538 ], [ 29.768925517241382, -1.538363938461538 ], [ 29.768925517241382, -1.538094461538462 ], [ 29.76865603448276, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13516, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.538363938461538 ], [ 29.758146206896551, -1.538633415384615 ], [ 29.758415689655173, -1.538633415384615 ], [ 29.758415689655173, -1.538363938461538 ], [ 29.758146206896551, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13517, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.538363938461538 ], [ 29.758415689655173, -1.538633415384615 ], [ 29.758685172413795, -1.538633415384615 ], [ 29.758685172413795, -1.538363938461538 ], [ 29.758415689655173, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13518, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.538363938461538 ], [ 29.758685172413795, -1.538633415384615 ], [ 29.758954655172413, -1.538633415384615 ], [ 29.758954655172413, -1.538363938461538 ], [ 29.758685172413795, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13519, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.538363938461538 ], [ 29.758954655172413, -1.538633415384615 ], [ 29.759224137931035, -1.538633415384615 ], [ 29.759224137931035, -1.538363938461538 ], [ 29.758954655172413, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13520, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.538363938461538 ], [ 29.759224137931035, -1.538633415384615 ], [ 29.759493620689657, -1.538633415384615 ], [ 29.759493620689657, -1.538363938461538 ], [ 29.759224137931035, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13521, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.538363938461538 ], [ 29.759493620689657, -1.538633415384615 ], [ 29.759763103448275, -1.538633415384615 ], [ 29.759763103448275, -1.538363938461538 ], [ 29.759493620689657, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13522, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.538363938461538 ], [ 29.759763103448275, -1.538633415384615 ], [ 29.760032586206897, -1.538633415384615 ], [ 29.760032586206897, -1.538363938461538 ], [ 29.759763103448275, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13523, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.538363938461538 ], [ 29.760032586206897, -1.538633415384615 ], [ 29.760302068965519, -1.538633415384615 ], [ 29.760302068965519, -1.538363938461538 ], [ 29.760032586206897, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13524, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.538363938461538 ], [ 29.760302068965519, -1.538633415384615 ], [ 29.760571551724137, -1.538633415384615 ], [ 29.760571551724137, -1.538363938461538 ], [ 29.760302068965519, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13525, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.538363938461538 ], [ 29.760571551724137, -1.538633415384615 ], [ 29.760841034482759, -1.538633415384615 ], [ 29.760841034482759, -1.538363938461538 ], [ 29.760571551724137, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13526, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.538363938461538 ], [ 29.760841034482759, -1.538902892307692 ], [ 29.761110517241381, -1.538902892307692 ], [ 29.761110517241381, -1.538363938461538 ], [ 29.760841034482759, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13527, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.538363938461538 ], [ 29.761110517241381, -1.538633415384615 ], [ 29.76138, -1.538633415384615 ], [ 29.76138, -1.538363938461538 ], [ 29.761110517241381, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13528, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.538363938461538 ], [ 29.76138, -1.538633415384615 ], [ 29.761649482758621, -1.538633415384615 ], [ 29.761649482758621, -1.538363938461538 ], [ 29.76138, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13529, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.538363938461538 ], [ 29.761649482758621, -1.538633415384615 ], [ 29.761918965517243, -1.538633415384615 ], [ 29.761918965517243, -1.538363938461538 ], [ 29.761649482758621, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13530, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.538363938461538 ], [ 29.761918965517243, -1.538633415384615 ], [ 29.762188448275861, -1.538633415384615 ], [ 29.762188448275861, -1.538363938461538 ], [ 29.761918965517243, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13531, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.538363938461538 ], [ 29.762188448275861, -1.538633415384615 ], [ 29.762457931034483, -1.538633415384615 ], [ 29.762457931034483, -1.538363938461538 ], [ 29.762188448275861, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13532, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.538363938461538 ], [ 29.762457931034483, -1.538902892307692 ], [ 29.762727413793105, -1.538902892307692 ], [ 29.762727413793105, -1.538363938461538 ], [ 29.762457931034483, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13533, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.538363938461538 ], [ 29.762996896551723, -1.538633415384615 ], [ 29.763266379310345, -1.538633415384615 ], [ 29.763266379310345, -1.538363938461538 ], [ 29.762996896551723, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13534, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.538363938461538 ], [ 29.763266379310345, -1.538633415384615 ], [ 29.763535862068967, -1.538633415384615 ], [ 29.763535862068967, -1.538363938461538 ], [ 29.763266379310345, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13535, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.538363938461538 ], [ 29.763535862068967, -1.538633415384615 ], [ 29.763805344827588, -1.538633415384615 ], [ 29.763805344827588, -1.538363938461538 ], [ 29.763535862068967, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13536, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.538363938461538 ], [ 29.763805344827588, -1.538633415384615 ], [ 29.764074827586207, -1.538633415384615 ], [ 29.764074827586207, -1.538363938461538 ], [ 29.763805344827588, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13537, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.538363938461538 ], [ 29.766769655172414, -1.539172369230769 ], [ 29.767039137931036, -1.539172369230769 ], [ 29.767039137931036, -1.538363938461538 ], [ 29.766769655172414, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13538, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.538363938461538 ], [ 29.767847586206898, -1.538902892307692 ], [ 29.768117068965516, -1.538902892307692 ], [ 29.768117068965516, -1.538363938461538 ], [ 29.767847586206898, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13539, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.538363938461538 ], [ 29.76865603448276, -1.538902892307692 ], [ 29.768925517241382, -1.538902892307692 ], [ 29.768925517241382, -1.538363938461538 ], [ 29.76865603448276, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13540, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.538363938461538 ], [ 29.768925517241382, -1.538633415384615 ], [ 29.769195, -1.538633415384615 ], [ 29.769195, -1.538363938461538 ], [ 29.768925517241382, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13541, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.538363938461538 ], [ 29.769195, -1.538633415384615 ], [ 29.769464482758622, -1.538633415384615 ], [ 29.769464482758622, -1.538363938461538 ], [ 29.769195, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13542, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.538363938461538 ], [ 29.769464482758622, -1.538633415384615 ], [ 29.769733965517243, -1.538633415384615 ], [ 29.769733965517243, -1.538363938461538 ], [ 29.769464482758622, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13543, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.538633415384615 ], [ 29.757068275862068, -1.538902892307692 ], [ 29.75733775862069, -1.538902892307692 ], [ 29.75733775862069, -1.538633415384615 ], [ 29.757068275862068, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13544, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.538633415384615 ], [ 29.75733775862069, -1.539172369230769 ], [ 29.757607241379311, -1.539172369230769 ], [ 29.757607241379311, -1.538633415384615 ], [ 29.75733775862069, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13545, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.538633415384615 ], [ 29.757607241379311, -1.538902892307692 ], [ 29.757876724137933, -1.538902892307692 ], [ 29.757876724137933, -1.538633415384615 ], [ 29.757607241379311, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13546, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.538633415384615 ], [ 29.757876724137933, -1.538902892307692 ], [ 29.758146206896551, -1.538902892307692 ], [ 29.758146206896551, -1.538633415384615 ], [ 29.757876724137933, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13547, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.538633415384615 ], [ 29.758146206896551, -1.538902892307692 ], [ 29.758415689655173, -1.538902892307692 ], [ 29.758415689655173, -1.538633415384615 ], [ 29.758146206896551, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13548, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.538633415384615 ], [ 29.758415689655173, -1.538902892307692 ], [ 29.758685172413795, -1.538902892307692 ], [ 29.758685172413795, -1.538633415384615 ], [ 29.758415689655173, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13549, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.538633415384615 ], [ 29.758685172413795, -1.538902892307692 ], [ 29.758954655172413, -1.538902892307692 ], [ 29.758954655172413, -1.538633415384615 ], [ 29.758685172413795, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13550, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.538633415384615 ], [ 29.758954655172413, -1.538902892307692 ], [ 29.759224137931035, -1.538902892307692 ], [ 29.759224137931035, -1.538633415384615 ], [ 29.758954655172413, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13551, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.538633415384615 ], [ 29.759224137931035, -1.538902892307692 ], [ 29.759493620689657, -1.538902892307692 ], [ 29.759493620689657, -1.538633415384615 ], [ 29.759224137931035, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13552, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.538633415384615 ], [ 29.759493620689657, -1.538902892307692 ], [ 29.759763103448275, -1.538902892307692 ], [ 29.759763103448275, -1.538633415384615 ], [ 29.759493620689657, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13553, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.538633415384615 ], [ 29.759763103448275, -1.538902892307692 ], [ 29.760032586206897, -1.538902892307692 ], [ 29.760032586206897, -1.538633415384615 ], [ 29.759763103448275, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13554, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.538633415384615 ], [ 29.760032586206897, -1.538902892307692 ], [ 29.760302068965519, -1.538902892307692 ], [ 29.760302068965519, -1.538633415384615 ], [ 29.760032586206897, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13555, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.538633415384615 ], [ 29.760302068965519, -1.538902892307692 ], [ 29.760571551724137, -1.538902892307692 ], [ 29.760571551724137, -1.538633415384615 ], [ 29.760302068965519, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13556, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.538633415384615 ], [ 29.760571551724137, -1.539172369230769 ], [ 29.760841034482759, -1.539172369230769 ], [ 29.760841034482759, -1.538633415384615 ], [ 29.760571551724137, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13557, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.538633415384615 ], [ 29.761110517241381, -1.538902892307692 ], [ 29.76138, -1.538902892307692 ], [ 29.76138, -1.538633415384615 ], [ 29.761110517241381, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13558, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.538633415384615 ], [ 29.76138, -1.538902892307692 ], [ 29.761649482758621, -1.538902892307692 ], [ 29.761649482758621, -1.538633415384615 ], [ 29.76138, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13559, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.538633415384615 ], [ 29.761649482758621, -1.538902892307692 ], [ 29.761918965517243, -1.538902892307692 ], [ 29.761918965517243, -1.538633415384615 ], [ 29.761649482758621, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13560, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.538633415384615 ], [ 29.761918965517243, -1.539172369230769 ], [ 29.762188448275861, -1.539172369230769 ], [ 29.762188448275861, -1.538633415384615 ], [ 29.761918965517243, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13561, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.538633415384615 ], [ 29.762188448275861, -1.539172369230769 ], [ 29.762457931034483, -1.539172369230769 ], [ 29.762457931034483, -1.538633415384615 ], [ 29.762188448275861, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13562, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.538633415384615 ], [ 29.762727413793105, -1.538902892307692 ], [ 29.762996896551723, -1.538902892307692 ], [ 29.762996896551723, -1.538633415384615 ], [ 29.762727413793105, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13563, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.538633415384615 ], [ 29.762996896551723, -1.538902892307692 ], [ 29.763266379310345, -1.538902892307692 ], [ 29.763266379310345, -1.538633415384615 ], [ 29.762996896551723, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13564, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.538633415384615 ], [ 29.763266379310345, -1.538902892307692 ], [ 29.763535862068967, -1.538902892307692 ], [ 29.763535862068967, -1.538633415384615 ], [ 29.763266379310345, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13565, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.538633415384615 ], [ 29.763535862068967, -1.538902892307692 ], [ 29.763805344827588, -1.538902892307692 ], [ 29.763805344827588, -1.538633415384615 ], [ 29.763535862068967, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13566, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.537824984615385 ], [ 29.764344310344828, -1.537824984615385 ], [ 29.764344310344828, -1.538902892307692 ], [ 29.763805344827588, -1.538902892307692 ], [ 29.763805344827588, -1.538633415384615 ], [ 29.764074827586207, -1.538633415384615 ], [ 29.764074827586207, -1.537824984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13567, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.538633415384615 ], [ 29.769464482758622, -1.538902892307692 ], [ 29.769733965517243, -1.538902892307692 ], [ 29.769733965517243, -1.538633415384615 ], [ 29.769464482758622, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13568, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.538633415384615 ], [ 29.769733965517243, -1.538902892307692 ], [ 29.770003448275862, -1.538902892307692 ], [ 29.770003448275862, -1.538633415384615 ], [ 29.769733965517243, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13569, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.538633415384615 ], [ 29.770003448275862, -1.538902892307692 ], [ 29.770272931034484, -1.538902892307692 ], [ 29.770272931034484, -1.538633415384615 ], [ 29.770003448275862, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13570, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.538633415384615 ], [ 29.770272931034484, -1.539172369230769 ], [ 29.770542413793105, -1.539172369230769 ], [ 29.770542413793105, -1.538633415384615 ], [ 29.770272931034484, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13571, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.538633415384615 ], [ 29.770542413793105, -1.538902892307692 ], [ 29.770811896551724, -1.538902892307692 ], [ 29.770811896551724, -1.538633415384615 ], [ 29.770542413793105, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13572, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.538902892307692 ], [ 29.756259827586206, -1.539172369230769 ], [ 29.756798793103449, -1.539172369230769 ], [ 29.756798793103449, -1.538902892307692 ], [ 29.756259827586206, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13573, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.538902892307692 ], [ 29.756798793103449, -1.539441846153846 ], [ 29.757068275862068, -1.539441846153846 ], [ 29.757068275862068, -1.538902892307692 ], [ 29.756798793103449, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13574, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.538902892307692 ], [ 29.757068275862068, -1.539441846153846 ], [ 29.75733775862069, -1.539441846153846 ], [ 29.75733775862069, -1.538902892307692 ], [ 29.757068275862068, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13575, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.538902892307692 ], [ 29.757607241379311, -1.539172369230769 ], [ 29.757876724137933, -1.539172369230769 ], [ 29.757876724137933, -1.538902892307692 ], [ 29.757607241379311, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13576, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.538902892307692 ], [ 29.757876724137933, -1.539172369230769 ], [ 29.758146206896551, -1.539172369230769 ], [ 29.758146206896551, -1.538902892307692 ], [ 29.757876724137933, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13577, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.538902892307692 ], [ 29.758146206896551, -1.539172369230769 ], [ 29.758415689655173, -1.539172369230769 ], [ 29.758415689655173, -1.538902892307692 ], [ 29.758146206896551, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13578, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.538902892307692 ], [ 29.758415689655173, -1.539172369230769 ], [ 29.758685172413795, -1.539172369230769 ], [ 29.758685172413795, -1.538902892307692 ], [ 29.758415689655173, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13579, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.538902892307692 ], [ 29.758685172413795, -1.539172369230769 ], [ 29.758954655172413, -1.539172369230769 ], [ 29.758954655172413, -1.538902892307692 ], [ 29.758685172413795, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13580, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.538902892307692 ], [ 29.758954655172413, -1.539172369230769 ], [ 29.759224137931035, -1.539172369230769 ], [ 29.759224137931035, -1.538902892307692 ], [ 29.758954655172413, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13581, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.538902892307692 ], [ 29.759224137931035, -1.539172369230769 ], [ 29.759493620689657, -1.539172369230769 ], [ 29.759493620689657, -1.538902892307692 ], [ 29.759224137931035, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13582, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.538902892307692 ], [ 29.759493620689657, -1.539172369230769 ], [ 29.759763103448275, -1.539172369230769 ], [ 29.759763103448275, -1.538902892307692 ], [ 29.759493620689657, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13583, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.538902892307692 ], [ 29.759763103448275, -1.539172369230769 ], [ 29.760032586206897, -1.539172369230769 ], [ 29.760032586206897, -1.538902892307692 ], [ 29.759763103448275, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13584, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.538902892307692 ], [ 29.760032586206897, -1.539172369230769 ], [ 29.760302068965519, -1.539172369230769 ], [ 29.760302068965519, -1.538902892307692 ], [ 29.760032586206897, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13585, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.538902892307692 ], [ 29.760302068965519, -1.539441846153846 ], [ 29.760571551724137, -1.539441846153846 ], [ 29.760571551724137, -1.538902892307692 ], [ 29.760302068965519, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13586, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.538902892307692 ], [ 29.760841034482759, -1.539172369230769 ], [ 29.761110517241381, -1.539172369230769 ], [ 29.761110517241381, -1.538902892307692 ], [ 29.760841034482759, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13587, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.538902892307692 ], [ 29.761110517241381, -1.539172369230769 ], [ 29.76138, -1.539172369230769 ], [ 29.76138, -1.538902892307692 ], [ 29.761110517241381, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13588, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.538902892307692 ], [ 29.76138, -1.539441846153846 ], [ 29.761649482758621, -1.539441846153846 ], [ 29.761649482758621, -1.538902892307692 ], [ 29.76138, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13589, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.538902892307692 ], [ 29.761649482758621, -1.539441846153846 ], [ 29.761918965517243, -1.539441846153846 ], [ 29.761918965517243, -1.538902892307692 ], [ 29.761649482758621, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13590, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.538902892307692 ], [ 29.762457931034483, -1.539172369230769 ], [ 29.762727413793105, -1.539172369230769 ], [ 29.762727413793105, -1.538902892307692 ], [ 29.762457931034483, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13591, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.538902892307692 ], [ 29.762727413793105, -1.539172369230769 ], [ 29.762996896551723, -1.539172369230769 ], [ 29.762996896551723, -1.538902892307692 ], [ 29.762727413793105, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13592, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.538902892307692 ], [ 29.762996896551723, -1.539172369230769 ], [ 29.763266379310345, -1.539172369230769 ], [ 29.763266379310345, -1.538902892307692 ], [ 29.762996896551723, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13593, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.538902892307692 ], [ 29.763266379310345, -1.539172369230769 ], [ 29.763535862068967, -1.539172369230769 ], [ 29.763535862068967, -1.538902892307692 ], [ 29.763266379310345, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13594, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.538902892307692 ], [ 29.763535862068967, -1.539172369230769 ], [ 29.763805344827588, -1.539172369230769 ], [ 29.763805344827588, -1.538902892307692 ], [ 29.763535862068967, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13595, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.538902892307692 ], [ 29.771081379310345, -1.539172369230769 ], [ 29.771350862068967, -1.539172369230769 ], [ 29.771350862068967, -1.538902892307692 ], [ 29.771081379310345, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13596, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.539172369230769 ], [ 29.75733775862069, -1.539441846153846 ], [ 29.757607241379311, -1.539441846153846 ], [ 29.757607241379311, -1.539172369230769 ], [ 29.75733775862069, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13597, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.539172369230769 ], [ 29.757876724137933, -1.539441846153846 ], [ 29.758146206896551, -1.539441846153846 ], [ 29.758146206896551, -1.539172369230769 ], [ 29.757876724137933, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13598, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.539172369230769 ], [ 29.758146206896551, -1.539441846153846 ], [ 29.758415689655173, -1.539441846153846 ], [ 29.758415689655173, -1.539172369230769 ], [ 29.758146206896551, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13599, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.539172369230769 ], [ 29.758415689655173, -1.539441846153846 ], [ 29.758685172413795, -1.539441846153846 ], [ 29.758685172413795, -1.539172369230769 ], [ 29.758415689655173, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13600, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.539172369230769 ], [ 29.758685172413795, -1.539441846153846 ], [ 29.758954655172413, -1.539441846153846 ], [ 29.758954655172413, -1.539172369230769 ], [ 29.758685172413795, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13601, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.539172369230769 ], [ 29.758954655172413, -1.539441846153846 ], [ 29.759224137931035, -1.539441846153846 ], [ 29.759224137931035, -1.539172369230769 ], [ 29.758954655172413, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13602, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.539172369230769 ], [ 29.759224137931035, -1.539441846153846 ], [ 29.759493620689657, -1.539441846153846 ], [ 29.759493620689657, -1.539172369230769 ], [ 29.759224137931035, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13603, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.539172369230769 ], [ 29.759493620689657, -1.539441846153846 ], [ 29.759763103448275, -1.539441846153846 ], [ 29.759763103448275, -1.539172369230769 ], [ 29.759493620689657, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13604, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.539172369230769 ], [ 29.759763103448275, -1.539441846153846 ], [ 29.760032586206897, -1.539441846153846 ], [ 29.760032586206897, -1.539172369230769 ], [ 29.759763103448275, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13605, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.539172369230769 ], [ 29.760571551724137, -1.539441846153846 ], [ 29.760841034482759, -1.539441846153846 ], [ 29.760841034482759, -1.539172369230769 ], [ 29.760571551724137, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13606, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.539172369230769 ], [ 29.760841034482759, -1.539441846153846 ], [ 29.761110517241381, -1.539441846153846 ], [ 29.761110517241381, -1.539172369230769 ], [ 29.760841034482759, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13607, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.539172369230769 ], [ 29.761110517241381, -1.539441846153846 ], [ 29.76138, -1.539441846153846 ], [ 29.76138, -1.539172369230769 ], [ 29.761110517241381, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13608, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.539172369230769 ], [ 29.761918965517243, -1.539441846153846 ], [ 29.762188448275861, -1.539441846153846 ], [ 29.762188448275861, -1.539172369230769 ], [ 29.761918965517243, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13609, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.539172369230769 ], [ 29.762188448275861, -1.539441846153846 ], [ 29.762457931034483, -1.539441846153846 ], [ 29.762457931034483, -1.539172369230769 ], [ 29.762188448275861, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13610, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.539172369230769 ], [ 29.762457931034483, -1.539441846153846 ], [ 29.762727413793105, -1.539441846153846 ], [ 29.762727413793105, -1.539172369230769 ], [ 29.762457931034483, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13611, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.539172369230769 ], [ 29.762727413793105, -1.539441846153846 ], [ 29.762996896551723, -1.539441846153846 ], [ 29.762996896551723, -1.539172369230769 ], [ 29.762727413793105, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13612, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.539172369230769 ], [ 29.762996896551723, -1.539441846153846 ], [ 29.763266379310345, -1.539441846153846 ], [ 29.763266379310345, -1.539172369230769 ], [ 29.762996896551723, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13613, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.539172369230769 ], [ 29.763266379310345, -1.539441846153846 ], [ 29.763535862068967, -1.539441846153846 ], [ 29.763535862068967, -1.539172369230769 ], [ 29.763266379310345, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13614, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.538094461538462 ], [ 29.76461379310345, -1.539711323076923 ], [ 29.764883275862069, -1.539711323076923 ], [ 29.764883275862069, -1.538094461538462 ], [ 29.76461379310345, -1.538094461538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13615, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.538363938461538 ], [ 29.764344310344828, -1.539711323076923 ], [ 29.76461379310345, -1.539711323076923 ], [ 29.76461379310345, -1.538363938461538 ], [ 29.764344310344828, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13616, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.538363938461538 ], [ 29.765422241379312, -1.539711323076923 ], [ 29.765961206896552, -1.539711323076923 ], [ 29.765961206896552, -1.538363938461538 ], [ 29.765422241379312, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13617, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.538363938461538 ], [ 29.766500172413792, -1.5399808 ], [ 29.766769655172414, -1.5399808 ], [ 29.766769655172414, -1.538363938461538 ], [ 29.766500172413792, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13618, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.538363938461538 ], [ 29.767578103448276, -1.5399808 ], [ 29.767847586206898, -1.5399808 ], [ 29.767847586206898, -1.538363938461538 ], [ 29.767578103448276, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13619, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.538363938461538 ], [ 29.768386551724138, -1.540250276923077 ], [ 29.76865603448276, -1.540250276923077 ], [ 29.76865603448276, -1.538363938461538 ], [ 29.768386551724138, -1.538363938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13620, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.538633415384615 ], [ 29.76515275862069, -1.5399808 ], [ 29.765422241379312, -1.5399808 ], [ 29.765422241379312, -1.538633415384615 ], [ 29.76515275862069, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13621, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.538633415384615 ], [ 29.766230689655174, -1.5399808 ], [ 29.766500172413792, -1.5399808 ], [ 29.766500172413792, -1.538633415384615 ], [ 29.766230689655174, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13622, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.538633415384615 ], [ 29.767039137931036, -1.540250276923077 ], [ 29.767308620689654, -1.540250276923077 ], [ 29.767308620689654, -1.538633415384615 ], [ 29.767039137931036, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13623, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.538633415384615 ], [ 29.767308620689654, -1.540250276923077 ], [ 29.767578103448276, -1.540250276923077 ], [ 29.767578103448276, -1.538633415384615 ], [ 29.767308620689654, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13624, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.538633415384615 ], [ 29.768117068965516, -1.540250276923077 ], [ 29.768386551724138, -1.540250276923077 ], [ 29.768386551724138, -1.538633415384615 ], [ 29.768117068965516, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13625, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.538633415384615 ], [ 29.768925517241382, -1.540519753846154 ], [ 29.769195, -1.540519753846154 ], [ 29.769195, -1.538633415384615 ], [ 29.768925517241382, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13626, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.538633415384615 ], [ 29.769195, -1.540519753846154 ], [ 29.769464482758622, -1.540519753846154 ], [ 29.769464482758622, -1.538633415384615 ], [ 29.769195, -1.538633415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13627, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.538902892307692 ], [ 29.763805344827588, -1.539441846153846 ], [ 29.764074827586207, -1.539441846153846 ], [ 29.764074827586207, -1.5399808 ], [ 29.764344310344828, -1.5399808 ], [ 29.764344310344828, -1.538902892307692 ], [ 29.763805344827588, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13628, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.538902892307692 ], [ 29.764883275862069, -1.5399808 ], [ 29.76515275862069, -1.5399808 ], [ 29.76515275862069, -1.538902892307692 ], [ 29.764883275862069, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13629, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.538902892307692 ], [ 29.765961206896552, -1.540250276923077 ], [ 29.766230689655174, -1.540250276923077 ], [ 29.766230689655174, -1.538902892307692 ], [ 29.765961206896552, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13630, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.538902892307692 ], [ 29.767847586206898, -1.540250276923077 ], [ 29.768117068965516, -1.540250276923077 ], [ 29.768117068965516, -1.538902892307692 ], [ 29.767847586206898, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13631, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.538902892307692 ], [ 29.76865603448276, -1.540519753846154 ], [ 29.768925517241382, -1.540519753846154 ], [ 29.768925517241382, -1.538902892307692 ], [ 29.76865603448276, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13632, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.538902892307692 ], [ 29.769464482758622, -1.540789230769231 ], [ 29.769733965517243, -1.540789230769231 ], [ 29.769733965517243, -1.538902892307692 ], [ 29.769464482758622, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13633, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.538902892307692 ], [ 29.769733965517243, -1.540789230769231 ], [ 29.770003448275862, -1.540789230769231 ], [ 29.770003448275862, -1.538902892307692 ], [ 29.769733965517243, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13634, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.538902892307692 ], [ 29.770003448275862, -1.540789230769231 ], [ 29.770272931034484, -1.540789230769231 ], [ 29.770272931034484, -1.538902892307692 ], [ 29.770003448275862, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13635, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.538902892307692 ], [ 29.770542413793105, -1.541058707692308 ], [ 29.770811896551724, -1.541058707692308 ], [ 29.770811896551724, -1.538902892307692 ], [ 29.770542413793105, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13636, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.538902892307692 ], [ 29.770811896551724, -1.541058707692308 ], [ 29.771081379310345, -1.541058707692308 ], [ 29.771081379310345, -1.538902892307692 ], [ 29.770811896551724, -1.538902892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13637, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.539172369230769 ], [ 29.756259827586206, -1.539711323076923 ], [ 29.756798793103449, -1.539711323076923 ], [ 29.756798793103449, -1.539172369230769 ], [ 29.756259827586206, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13638, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.539172369230769 ], [ 29.757607241379311, -1.539711323076923 ], [ 29.757876724137933, -1.539711323076923 ], [ 29.757876724137933, -1.539172369230769 ], [ 29.757607241379311, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13639, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.539172369230769 ], [ 29.760032586206897, -1.539711323076923 ], [ 29.760302068965519, -1.539711323076923 ], [ 29.760302068965519, -1.539172369230769 ], [ 29.760032586206897, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13640, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.539172369230769 ], [ 29.763535862068967, -1.539711323076923 ], [ 29.763805344827588, -1.539711323076923 ], [ 29.763805344827588, -1.539172369230769 ], [ 29.763535862068967, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13641, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.539172369230769 ], [ 29.766769655172414, -1.540250276923077 ], [ 29.767039137931036, -1.540250276923077 ], [ 29.767039137931036, -1.539172369230769 ], [ 29.766769655172414, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13642, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.539172369230769 ], [ 29.770272931034484, -1.540789230769231 ], [ 29.770542413793105, -1.540789230769231 ], [ 29.770542413793105, -1.539172369230769 ], [ 29.770272931034484, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13643, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771081379310345, -1.539172369230769 ], [ 29.771081379310345, -1.541328184615385 ], [ 29.771350862068967, -1.541328184615385 ], [ 29.771350862068967, -1.539172369230769 ], [ 29.771081379310345, -1.539172369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13644, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.539441846153846 ], [ 29.756798793103449, -1.539711323076923 ], [ 29.757068275862068, -1.539711323076923 ], [ 29.757068275862068, -1.539441846153846 ], [ 29.756798793103449, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13645, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.539441846153846 ], [ 29.757068275862068, -1.5399808 ], [ 29.75733775862069, -1.5399808 ], [ 29.75733775862069, -1.539441846153846 ], [ 29.757068275862068, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13646, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.539441846153846 ], [ 29.75733775862069, -1.5399808 ], [ 29.757607241379311, -1.5399808 ], [ 29.757607241379311, -1.539441846153846 ], [ 29.75733775862069, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13647, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.539441846153846 ], [ 29.757876724137933, -1.539711323076923 ], [ 29.758146206896551, -1.539711323076923 ], [ 29.758146206896551, -1.539441846153846 ], [ 29.757876724137933, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13648, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.539441846153846 ], [ 29.758146206896551, -1.539711323076923 ], [ 29.758415689655173, -1.539711323076923 ], [ 29.758415689655173, -1.539441846153846 ], [ 29.758146206896551, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13649, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.539441846153846 ], [ 29.758415689655173, -1.539711323076923 ], [ 29.758685172413795, -1.539711323076923 ], [ 29.758685172413795, -1.539441846153846 ], [ 29.758415689655173, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13650, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.539441846153846 ], [ 29.758685172413795, -1.539711323076923 ], [ 29.758954655172413, -1.539711323076923 ], [ 29.758954655172413, -1.539441846153846 ], [ 29.758685172413795, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13651, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.539441846153846 ], [ 29.758954655172413, -1.539711323076923 ], [ 29.759224137931035, -1.539711323076923 ], [ 29.759224137931035, -1.539441846153846 ], [ 29.758954655172413, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13652, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.539441846153846 ], [ 29.759224137931035, -1.539711323076923 ], [ 29.759493620689657, -1.539711323076923 ], [ 29.759493620689657, -1.539441846153846 ], [ 29.759224137931035, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13653, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.539441846153846 ], [ 29.759493620689657, -1.5399808 ], [ 29.759763103448275, -1.5399808 ], [ 29.759763103448275, -1.539441846153846 ], [ 29.759493620689657, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13654, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.539441846153846 ], [ 29.759763103448275, -1.5399808 ], [ 29.760032586206897, -1.5399808 ], [ 29.760032586206897, -1.539441846153846 ], [ 29.759763103448275, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13655, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.539441846153846 ], [ 29.760302068965519, -1.539711323076923 ], [ 29.760571551724137, -1.539711323076923 ], [ 29.760571551724137, -1.539441846153846 ], [ 29.760302068965519, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13656, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.539441846153846 ], [ 29.760571551724137, -1.539711323076923 ], [ 29.760841034482759, -1.539711323076923 ], [ 29.760841034482759, -1.539441846153846 ], [ 29.760571551724137, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13657, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.539441846153846 ], [ 29.760841034482759, -1.539711323076923 ], [ 29.761110517241381, -1.539711323076923 ], [ 29.761110517241381, -1.539441846153846 ], [ 29.760841034482759, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13658, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.539441846153846 ], [ 29.761110517241381, -1.539711323076923 ], [ 29.76138, -1.539711323076923 ], [ 29.76138, -1.539441846153846 ], [ 29.761110517241381, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13659, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.539441846153846 ], [ 29.76138, -1.539711323076923 ], [ 29.761649482758621, -1.539711323076923 ], [ 29.761649482758621, -1.539441846153846 ], [ 29.76138, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13660, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.539441846153846 ], [ 29.761649482758621, -1.539711323076923 ], [ 29.761918965517243, -1.539711323076923 ], [ 29.761918965517243, -1.539441846153846 ], [ 29.761649482758621, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13661, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.539441846153846 ], [ 29.761918965517243, -1.539711323076923 ], [ 29.762188448275861, -1.539711323076923 ], [ 29.762188448275861, -1.539441846153846 ], [ 29.761918965517243, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13662, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.539441846153846 ], [ 29.762188448275861, -1.539711323076923 ], [ 29.762457931034483, -1.539711323076923 ], [ 29.762457931034483, -1.539441846153846 ], [ 29.762188448275861, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13663, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.539441846153846 ], [ 29.762457931034483, -1.539711323076923 ], [ 29.762727413793105, -1.539711323076923 ], [ 29.762727413793105, -1.539441846153846 ], [ 29.762457931034483, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13664, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.539441846153846 ], [ 29.762727413793105, -1.539711323076923 ], [ 29.762996896551723, -1.539711323076923 ], [ 29.762996896551723, -1.539441846153846 ], [ 29.762727413793105, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13665, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.539441846153846 ], [ 29.762996896551723, -1.539711323076923 ], [ 29.763266379310345, -1.539711323076923 ], [ 29.763266379310345, -1.539441846153846 ], [ 29.762996896551723, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13666, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.539441846153846 ], [ 29.763266379310345, -1.5399808 ], [ 29.763535862068967, -1.5399808 ], [ 29.763535862068967, -1.539441846153846 ], [ 29.763266379310345, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13667, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.539441846153846 ], [ 29.763805344827588, -1.539711323076923 ], [ 29.764074827586207, -1.539711323076923 ], [ 29.764074827586207, -1.539441846153846 ], [ 29.763805344827588, -1.539441846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13668, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.539711323076923 ], [ 29.756798793103449, -1.540250276923077 ], [ 29.757068275862068, -1.540250276923077 ], [ 29.757068275862068, -1.539711323076923 ], [ 29.756798793103449, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13669, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.539711323076923 ], [ 29.757607241379311, -1.5399808 ], [ 29.757876724137933, -1.5399808 ], [ 29.757876724137933, -1.539711323076923 ], [ 29.757607241379311, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13670, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.539711323076923 ], [ 29.757876724137933, -1.540250276923077 ], [ 29.758146206896551, -1.540250276923077 ], [ 29.758146206896551, -1.539711323076923 ], [ 29.757876724137933, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13671, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.539711323076923 ], [ 29.758146206896551, -1.5399808 ], [ 29.758415689655173, -1.5399808 ], [ 29.758415689655173, -1.539711323076923 ], [ 29.758146206896551, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13672, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.539711323076923 ], [ 29.758415689655173, -1.5399808 ], [ 29.758685172413795, -1.5399808 ], [ 29.758685172413795, -1.539711323076923 ], [ 29.758415689655173, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13673, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.539711323076923 ], [ 29.758685172413795, -1.5399808 ], [ 29.758954655172413, -1.5399808 ], [ 29.758954655172413, -1.539711323076923 ], [ 29.758685172413795, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13674, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.539711323076923 ], [ 29.758954655172413, -1.540250276923077 ], [ 29.759224137931035, -1.540250276923077 ], [ 29.759224137931035, -1.539711323076923 ], [ 29.758954655172413, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13675, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.539711323076923 ], [ 29.759224137931035, -1.540250276923077 ], [ 29.759493620689657, -1.540250276923077 ], [ 29.759493620689657, -1.539711323076923 ], [ 29.759224137931035, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13676, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.539711323076923 ], [ 29.760032586206897, -1.5399808 ], [ 29.760302068965519, -1.5399808 ], [ 29.760302068965519, -1.539711323076923 ], [ 29.760032586206897, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13677, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.539711323076923 ], [ 29.760302068965519, -1.5399808 ], [ 29.760571551724137, -1.5399808 ], [ 29.760571551724137, -1.539711323076923 ], [ 29.760302068965519, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13678, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.539711323076923 ], [ 29.760571551724137, -1.5399808 ], [ 29.760841034482759, -1.5399808 ], [ 29.760841034482759, -1.539711323076923 ], [ 29.760571551724137, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13679, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.539711323076923 ], [ 29.760841034482759, -1.5399808 ], [ 29.761110517241381, -1.5399808 ], [ 29.761110517241381, -1.539711323076923 ], [ 29.760841034482759, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13680, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.539711323076923 ], [ 29.761110517241381, -1.5399808 ], [ 29.76138, -1.5399808 ], [ 29.76138, -1.539711323076923 ], [ 29.761110517241381, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13681, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.539711323076923 ], [ 29.76138, -1.540250276923077 ], [ 29.761649482758621, -1.540250276923077 ], [ 29.761649482758621, -1.539711323076923 ], [ 29.76138, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13682, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.539711323076923 ], [ 29.761649482758621, -1.5399808 ], [ 29.761918965517243, -1.5399808 ], [ 29.761918965517243, -1.539711323076923 ], [ 29.761649482758621, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13683, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.539711323076923 ], [ 29.761918965517243, -1.5399808 ], [ 29.762188448275861, -1.5399808 ], [ 29.762188448275861, -1.539711323076923 ], [ 29.761918965517243, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13684, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.539711323076923 ], [ 29.762188448275861, -1.5399808 ], [ 29.762457931034483, -1.5399808 ], [ 29.762457931034483, -1.539711323076923 ], [ 29.762188448275861, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13685, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.539711323076923 ], [ 29.762457931034483, -1.5399808 ], [ 29.762727413793105, -1.5399808 ], [ 29.762727413793105, -1.539711323076923 ], [ 29.762457931034483, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13686, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.539711323076923 ], [ 29.762727413793105, -1.5399808 ], [ 29.762996896551723, -1.5399808 ], [ 29.762996896551723, -1.539711323076923 ], [ 29.762727413793105, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13687, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.539711323076923 ], [ 29.762996896551723, -1.540250276923077 ], [ 29.763266379310345, -1.540250276923077 ], [ 29.763266379310345, -1.539711323076923 ], [ 29.762996896551723, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13688, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.539711323076923 ], [ 29.763535862068967, -1.5399808 ], [ 29.763805344827588, -1.5399808 ], [ 29.763805344827588, -1.539711323076923 ], [ 29.763535862068967, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13689, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.539711323076923 ], [ 29.763805344827588, -1.5399808 ], [ 29.764074827586207, -1.5399808 ], [ 29.764074827586207, -1.539711323076923 ], [ 29.763805344827588, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13690, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.539711323076923 ], [ 29.764344310344828, -1.540250276923077 ], [ 29.76461379310345, -1.540250276923077 ], [ 29.76461379310345, -1.539711323076923 ], [ 29.764344310344828, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13691, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.539711323076923 ], [ 29.76461379310345, -1.540250276923077 ], [ 29.764883275862069, -1.540250276923077 ], [ 29.764883275862069, -1.539711323076923 ], [ 29.76461379310345, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13692, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.539711323076923 ], [ 29.765422241379312, -1.540250276923077 ], [ 29.765961206896552, -1.540250276923077 ], [ 29.765961206896552, -1.539711323076923 ], [ 29.765422241379312, -1.539711323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13693, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.5399808 ], [ 29.757068275862068, -1.540250276923077 ], [ 29.75733775862069, -1.540250276923077 ], [ 29.75733775862069, -1.5399808 ], [ 29.757068275862068, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13694, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.5399808 ], [ 29.75733775862069, -1.540789230769231 ], [ 29.757607241379311, -1.540789230769231 ], [ 29.757607241379311, -1.5399808 ], [ 29.75733775862069, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13695, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.5399808 ], [ 29.757607241379311, -1.540789230769231 ], [ 29.757876724137933, -1.540789230769231 ], [ 29.757876724137933, -1.5399808 ], [ 29.757607241379311, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13696, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.5399808 ], [ 29.758146206896551, -1.540519753846154 ], [ 29.758415689655173, -1.540519753846154 ], [ 29.758415689655173, -1.5399808 ], [ 29.758146206896551, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13697, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.5399808 ], [ 29.758415689655173, -1.540519753846154 ], [ 29.758685172413795, -1.540519753846154 ], [ 29.758685172413795, -1.5399808 ], [ 29.758415689655173, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13698, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5399808 ], [ 29.758685172413795, -1.540519753846154 ], [ 29.758954655172413, -1.540519753846154 ], [ 29.758954655172413, -1.5399808 ], [ 29.758685172413795, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13699, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.5399808 ], [ 29.759493620689657, -1.540250276923077 ], [ 29.759763103448275, -1.540250276923077 ], [ 29.759763103448275, -1.5399808 ], [ 29.759493620689657, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13700, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.5399808 ], [ 29.759763103448275, -1.540250276923077 ], [ 29.760032586206897, -1.540250276923077 ], [ 29.760032586206897, -1.5399808 ], [ 29.759763103448275, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13701, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.5399808 ], [ 29.760032586206897, -1.540250276923077 ], [ 29.760302068965519, -1.540250276923077 ], [ 29.760302068965519, -1.5399808 ], [ 29.760032586206897, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13702, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.5399808 ], [ 29.760302068965519, -1.540250276923077 ], [ 29.760571551724137, -1.540250276923077 ], [ 29.760571551724137, -1.5399808 ], [ 29.760302068965519, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13703, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.5399808 ], [ 29.760571551724137, -1.540250276923077 ], [ 29.760841034482759, -1.540250276923077 ], [ 29.760841034482759, -1.5399808 ], [ 29.760571551724137, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13704, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5399808 ], [ 29.760841034482759, -1.540250276923077 ], [ 29.761110517241381, -1.540250276923077 ], [ 29.761110517241381, -1.5399808 ], [ 29.760841034482759, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13705, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5399808 ], [ 29.761110517241381, -1.540519753846154 ], [ 29.76138, -1.540519753846154 ], [ 29.76138, -1.5399808 ], [ 29.761110517241381, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13706, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.5399808 ], [ 29.761649482758621, -1.540250276923077 ], [ 29.761918965517243, -1.540250276923077 ], [ 29.761918965517243, -1.5399808 ], [ 29.761649482758621, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13707, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5399808 ], [ 29.761918965517243, -1.540250276923077 ], [ 29.762188448275861, -1.540250276923077 ], [ 29.762188448275861, -1.5399808 ], [ 29.761918965517243, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13708, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5399808 ], [ 29.762188448275861, -1.540250276923077 ], [ 29.762457931034483, -1.540250276923077 ], [ 29.762457931034483, -1.5399808 ], [ 29.762188448275861, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13709, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5399808 ], [ 29.762457931034483, -1.540519753846154 ], [ 29.762727413793105, -1.540519753846154 ], [ 29.762727413793105, -1.5399808 ], [ 29.762457931034483, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13710, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5399808 ], [ 29.762727413793105, -1.540519753846154 ], [ 29.762996896551723, -1.540519753846154 ], [ 29.762996896551723, -1.5399808 ], [ 29.762727413793105, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13711, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5399808 ], [ 29.763266379310345, -1.540250276923077 ], [ 29.763535862068967, -1.540250276923077 ], [ 29.763535862068967, -1.5399808 ], [ 29.763266379310345, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13712, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5399808 ], [ 29.763535862068967, -1.540519753846154 ], [ 29.763805344827588, -1.540519753846154 ], [ 29.763805344827588, -1.5399808 ], [ 29.763535862068967, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13713, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5399808 ], [ 29.763805344827588, -1.540519753846154 ], [ 29.764074827586207, -1.540519753846154 ], [ 29.764074827586207, -1.5399808 ], [ 29.763805344827588, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13714, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5399808 ], [ 29.764074827586207, -1.540519753846154 ], [ 29.764344310344828, -1.540519753846154 ], [ 29.764344310344828, -1.5399808 ], [ 29.764074827586207, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13715, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.5399808 ], [ 29.764883275862069, -1.540789230769231 ], [ 29.76515275862069, -1.540789230769231 ], [ 29.76515275862069, -1.5399808 ], [ 29.764883275862069, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13716, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.5399808 ], [ 29.76515275862069, -1.540519753846154 ], [ 29.765422241379312, -1.540519753846154 ], [ 29.765422241379312, -1.5399808 ], [ 29.76515275862069, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13717, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.5399808 ], [ 29.766230689655174, -1.540519753846154 ], [ 29.766500172413792, -1.540519753846154 ], [ 29.766500172413792, -1.5399808 ], [ 29.766230689655174, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13718, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5399808 ], [ 29.766500172413792, -1.540519753846154 ], [ 29.766769655172414, -1.540519753846154 ], [ 29.766769655172414, -1.5399808 ], [ 29.766500172413792, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13719, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.5399808 ], [ 29.767578103448276, -1.540519753846154 ], [ 29.767847586206898, -1.540519753846154 ], [ 29.767847586206898, -1.5399808 ], [ 29.767578103448276, -1.5399808 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13720, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.540250276923077 ], [ 29.757068275862068, -1.541058707692308 ], [ 29.75733775862069, -1.541058707692308 ], [ 29.75733775862069, -1.540250276923077 ], [ 29.757068275862068, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13721, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.540250276923077 ], [ 29.757876724137933, -1.540519753846154 ], [ 29.758146206896551, -1.540519753846154 ], [ 29.758146206896551, -1.540250276923077 ], [ 29.757876724137933, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13722, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.540250276923077 ], [ 29.758954655172413, -1.540519753846154 ], [ 29.759224137931035, -1.540519753846154 ], [ 29.759224137931035, -1.540250276923077 ], [ 29.758954655172413, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13723, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.540250276923077 ], [ 29.759224137931035, -1.540519753846154 ], [ 29.759493620689657, -1.540519753846154 ], [ 29.759493620689657, -1.540250276923077 ], [ 29.759224137931035, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13724, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.540250276923077 ], [ 29.759493620689657, -1.540519753846154 ], [ 29.759763103448275, -1.540519753846154 ], [ 29.759763103448275, -1.540250276923077 ], [ 29.759493620689657, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13725, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.540250276923077 ], [ 29.759763103448275, -1.540519753846154 ], [ 29.760032586206897, -1.540519753846154 ], [ 29.760032586206897, -1.540250276923077 ], [ 29.759763103448275, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13726, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.540250276923077 ], [ 29.760032586206897, -1.540519753846154 ], [ 29.760302068965519, -1.540519753846154 ], [ 29.760302068965519, -1.540250276923077 ], [ 29.760032586206897, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13727, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.540250276923077 ], [ 29.760302068965519, -1.540519753846154 ], [ 29.760571551724137, -1.540519753846154 ], [ 29.760571551724137, -1.540250276923077 ], [ 29.760302068965519, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13728, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.540250276923077 ], [ 29.760571551724137, -1.540519753846154 ], [ 29.760841034482759, -1.540519753846154 ], [ 29.760841034482759, -1.540250276923077 ], [ 29.760571551724137, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13729, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.540250276923077 ], [ 29.760841034482759, -1.540789230769231 ], [ 29.761110517241381, -1.540789230769231 ], [ 29.761110517241381, -1.540250276923077 ], [ 29.760841034482759, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13730, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.540250276923077 ], [ 29.76138, -1.540519753846154 ], [ 29.761649482758621, -1.540519753846154 ], [ 29.761649482758621, -1.540250276923077 ], [ 29.76138, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13731, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.540250276923077 ], [ 29.761649482758621, -1.540519753846154 ], [ 29.761918965517243, -1.540519753846154 ], [ 29.761918965517243, -1.540250276923077 ], [ 29.761649482758621, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13732, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.540250276923077 ], [ 29.761918965517243, -1.540789230769231 ], [ 29.762188448275861, -1.540789230769231 ], [ 29.762188448275861, -1.540250276923077 ], [ 29.761918965517243, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13733, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.540250276923077 ], [ 29.762188448275861, -1.540789230769231 ], [ 29.762457931034483, -1.540789230769231 ], [ 29.762457931034483, -1.540250276923077 ], [ 29.762188448275861, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13734, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.540250276923077 ], [ 29.762996896551723, -1.540789230769231 ], [ 29.763266379310345, -1.540789230769231 ], [ 29.763266379310345, -1.540250276923077 ], [ 29.762996896551723, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13735, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.540250276923077 ], [ 29.763266379310345, -1.541058707692308 ], [ 29.763535862068967, -1.541058707692308 ], [ 29.763535862068967, -1.540250276923077 ], [ 29.763266379310345, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13736, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.540250276923077 ], [ 29.764344310344828, -1.541328184615385 ], [ 29.76461379310345, -1.541328184615385 ], [ 29.76461379310345, -1.540250276923077 ], [ 29.764344310344828, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13737, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.540250276923077 ], [ 29.76461379310345, -1.541058707692308 ], [ 29.764883275862069, -1.541058707692308 ], [ 29.764883275862069, -1.540250276923077 ], [ 29.76461379310345, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13738, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.540250276923077 ], [ 29.765422241379312, -1.541058707692308 ], [ 29.765961206896552, -1.541058707692308 ], [ 29.765961206896552, -1.540250276923077 ], [ 29.765422241379312, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13739, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.540250276923077 ], [ 29.765961206896552, -1.540789230769231 ], [ 29.766230689655174, -1.540789230769231 ], [ 29.766230689655174, -1.540250276923077 ], [ 29.765961206896552, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13740, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.540250276923077 ], [ 29.766769655172414, -1.540789230769231 ], [ 29.767039137931036, -1.540789230769231 ], [ 29.767039137931036, -1.540250276923077 ], [ 29.766769655172414, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13741, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.540250276923077 ], [ 29.767039137931036, -1.540789230769231 ], [ 29.767308620689654, -1.540789230769231 ], [ 29.767308620689654, -1.540250276923077 ], [ 29.767039137931036, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13742, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.540250276923077 ], [ 29.767308620689654, -1.540519753846154 ], [ 29.767578103448276, -1.540519753846154 ], [ 29.767578103448276, -1.540250276923077 ], [ 29.767308620689654, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13743, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.540250276923077 ], [ 29.767847586206898, -1.540789230769231 ], [ 29.768117068965516, -1.540789230769231 ], [ 29.768117068965516, -1.540250276923077 ], [ 29.767847586206898, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13744, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.540250276923077 ], [ 29.768117068965516, -1.540519753846154 ], [ 29.768386551724138, -1.540519753846154 ], [ 29.768386551724138, -1.540250276923077 ], [ 29.768117068965516, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13745, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.540250276923077 ], [ 29.768386551724138, -1.540519753846154 ], [ 29.76865603448276, -1.540519753846154 ], [ 29.76865603448276, -1.540250276923077 ], [ 29.768386551724138, -1.540250276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13746, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.540519753846154 ], [ 29.757876724137933, -1.540789230769231 ], [ 29.758146206896551, -1.540789230769231 ], [ 29.758146206896551, -1.540519753846154 ], [ 29.757876724137933, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13747, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.540519753846154 ], [ 29.758146206896551, -1.541058707692308 ], [ 29.758415689655173, -1.541058707692308 ], [ 29.758415689655173, -1.540519753846154 ], [ 29.758146206896551, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13748, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.540519753846154 ], [ 29.758415689655173, -1.540789230769231 ], [ 29.758685172413795, -1.540789230769231 ], [ 29.758685172413795, -1.540519753846154 ], [ 29.758415689655173, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13749, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.540519753846154 ], [ 29.758685172413795, -1.540789230769231 ], [ 29.758954655172413, -1.540789230769231 ], [ 29.758954655172413, -1.540519753846154 ], [ 29.758685172413795, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13750, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.540519753846154 ], [ 29.758954655172413, -1.540789230769231 ], [ 29.759224137931035, -1.540789230769231 ], [ 29.759224137931035, -1.540519753846154 ], [ 29.758954655172413, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13751, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.540519753846154 ], [ 29.759224137931035, -1.540789230769231 ], [ 29.759493620689657, -1.540789230769231 ], [ 29.759493620689657, -1.540519753846154 ], [ 29.759224137931035, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13752, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.540519753846154 ], [ 29.759493620689657, -1.540789230769231 ], [ 29.759763103448275, -1.540789230769231 ], [ 29.759763103448275, -1.540519753846154 ], [ 29.759493620689657, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13753, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.540519753846154 ], [ 29.759763103448275, -1.540789230769231 ], [ 29.760032586206897, -1.540789230769231 ], [ 29.760032586206897, -1.540519753846154 ], [ 29.759763103448275, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13754, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.540519753846154 ], [ 29.760032586206897, -1.540789230769231 ], [ 29.760302068965519, -1.540789230769231 ], [ 29.760302068965519, -1.540519753846154 ], [ 29.760032586206897, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13755, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.540519753846154 ], [ 29.760302068965519, -1.540789230769231 ], [ 29.760571551724137, -1.540789230769231 ], [ 29.760571551724137, -1.540519753846154 ], [ 29.760302068965519, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13756, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.540519753846154 ], [ 29.760571551724137, -1.541058707692308 ], [ 29.760841034482759, -1.541058707692308 ], [ 29.760841034482759, -1.540519753846154 ], [ 29.760571551724137, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13757, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.540519753846154 ], [ 29.761110517241381, -1.540789230769231 ], [ 29.76138, -1.540789230769231 ], [ 29.76138, -1.540519753846154 ], [ 29.761110517241381, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13758, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.540519753846154 ], [ 29.76138, -1.541058707692308 ], [ 29.761649482758621, -1.541058707692308 ], [ 29.761649482758621, -1.540519753846154 ], [ 29.76138, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13759, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.540519753846154 ], [ 29.761649482758621, -1.541058707692308 ], [ 29.761918965517243, -1.541058707692308 ], [ 29.761918965517243, -1.540519753846154 ], [ 29.761649482758621, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13760, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.540519753846154 ], [ 29.762457931034483, -1.541058707692308 ], [ 29.762727413793105, -1.541058707692308 ], [ 29.762727413793105, -1.540519753846154 ], [ 29.762457931034483, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13761, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.540519753846154 ], [ 29.762727413793105, -1.541328184615385 ], [ 29.762996896551723, -1.541328184615385 ], [ 29.762996896551723, -1.540519753846154 ], [ 29.762727413793105, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13762, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.540519753846154 ], [ 29.763535862068967, -1.541328184615385 ], [ 29.763805344827588, -1.541328184615385 ], [ 29.763805344827588, -1.540519753846154 ], [ 29.763535862068967, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13763, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.540519753846154 ], [ 29.764074827586207, -1.541328184615385 ], [ 29.764344310344828, -1.541328184615385 ], [ 29.764344310344828, -1.540519753846154 ], [ 29.764074827586207, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13764, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.540519753846154 ], [ 29.76515275862069, -1.541328184615385 ], [ 29.765422241379312, -1.541328184615385 ], [ 29.765422241379312, -1.540519753846154 ], [ 29.76515275862069, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13765, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.540519753846154 ], [ 29.766230689655174, -1.541597661538461 ], [ 29.766500172413792, -1.541597661538461 ], [ 29.766500172413792, -1.540519753846154 ], [ 29.766230689655174, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13766, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.540519753846154 ], [ 29.766500172413792, -1.541328184615385 ], [ 29.766769655172414, -1.541328184615385 ], [ 29.766769655172414, -1.540519753846154 ], [ 29.766500172413792, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13767, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.540519753846154 ], [ 29.767308620689654, -1.541597661538461 ], [ 29.767578103448276, -1.541597661538461 ], [ 29.767578103448276, -1.540519753846154 ], [ 29.767308620689654, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13768, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.540519753846154 ], [ 29.767578103448276, -1.541058707692308 ], [ 29.767847586206898, -1.541058707692308 ], [ 29.767847586206898, -1.540519753846154 ], [ 29.767578103448276, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13769, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.540519753846154 ], [ 29.768386551724138, -1.541058707692308 ], [ 29.76865603448276, -1.541058707692308 ], [ 29.76865603448276, -1.540519753846154 ], [ 29.768386551724138, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13770, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.540519753846154 ], [ 29.76865603448276, -1.540789230769231 ], [ 29.768925517241382, -1.540789230769231 ], [ 29.768925517241382, -1.540519753846154 ], [ 29.76865603448276, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13771, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.540519753846154 ], [ 29.768925517241382, -1.540789230769231 ], [ 29.769195, -1.540789230769231 ], [ 29.769195, -1.540519753846154 ], [ 29.768925517241382, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13772, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.540519753846154 ], [ 29.769195, -1.540789230769231 ], [ 29.769464482758622, -1.540789230769231 ], [ 29.769464482758622, -1.540519753846154 ], [ 29.769195, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13773, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.540789230769231 ], [ 29.75733775862069, -1.541058707692308 ], [ 29.757607241379311, -1.541058707692308 ], [ 29.757607241379311, -1.540789230769231 ], [ 29.75733775862069, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13774, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.540789230769231 ], [ 29.757607241379311, -1.541328184615385 ], [ 29.757876724137933, -1.541328184615385 ], [ 29.757876724137933, -1.540789230769231 ], [ 29.757607241379311, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13775, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.540789230769231 ], [ 29.757876724137933, -1.541328184615385 ], [ 29.758146206896551, -1.541328184615385 ], [ 29.758146206896551, -1.540789230769231 ], [ 29.757876724137933, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13776, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.540789230769231 ], [ 29.758415689655173, -1.541058707692308 ], [ 29.758685172413795, -1.541058707692308 ], [ 29.758685172413795, -1.540789230769231 ], [ 29.758415689655173, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13777, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.540789230769231 ], [ 29.758685172413795, -1.541058707692308 ], [ 29.758954655172413, -1.541058707692308 ], [ 29.758954655172413, -1.540789230769231 ], [ 29.758685172413795, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13778, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.540789230769231 ], [ 29.758954655172413, -1.541058707692308 ], [ 29.759224137931035, -1.541058707692308 ], [ 29.759224137931035, -1.540789230769231 ], [ 29.758954655172413, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13779, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.540789230769231 ], [ 29.759224137931035, -1.541058707692308 ], [ 29.759493620689657, -1.541058707692308 ], [ 29.759493620689657, -1.540789230769231 ], [ 29.759224137931035, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13780, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.540789230769231 ], [ 29.759493620689657, -1.541058707692308 ], [ 29.759763103448275, -1.541058707692308 ], [ 29.759763103448275, -1.540789230769231 ], [ 29.759493620689657, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13781, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.540789230769231 ], [ 29.759763103448275, -1.541058707692308 ], [ 29.760032586206897, -1.541058707692308 ], [ 29.760032586206897, -1.540789230769231 ], [ 29.759763103448275, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13782, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.540789230769231 ], [ 29.760032586206897, -1.541328184615385 ], [ 29.760302068965519, -1.541328184615385 ], [ 29.760302068965519, -1.540789230769231 ], [ 29.760032586206897, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13783, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.540789230769231 ], [ 29.760841034482759, -1.541328184615385 ], [ 29.761110517241381, -1.541328184615385 ], [ 29.761110517241381, -1.540789230769231 ], [ 29.760841034482759, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13784, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.540789230769231 ], [ 29.761918965517243, -1.541597661538461 ], [ 29.762188448275861, -1.541597661538461 ], [ 29.762188448275861, -1.540789230769231 ], [ 29.761918965517243, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13785, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.540789230769231 ], [ 29.764883275862069, -1.541597661538461 ], [ 29.76515275862069, -1.541597661538461 ], [ 29.76515275862069, -1.540789230769231 ], [ 29.764883275862069, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13786, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.540789230769231 ], [ 29.765961206896552, -1.541597661538461 ], [ 29.766230689655174, -1.541597661538461 ], [ 29.766230689655174, -1.540789230769231 ], [ 29.765961206896552, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13787, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.540789230769231 ], [ 29.769195, -1.541058707692308 ], [ 29.769464482758622, -1.541058707692308 ], [ 29.769464482758622, -1.540789230769231 ], [ 29.769195, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13788, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.540789230769231 ], [ 29.769464482758622, -1.541058707692308 ], [ 29.769733965517243, -1.541058707692308 ], [ 29.769733965517243, -1.540789230769231 ], [ 29.769464482758622, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13789, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.540789230769231 ], [ 29.769733965517243, -1.541058707692308 ], [ 29.770003448275862, -1.541058707692308 ], [ 29.770003448275862, -1.540789230769231 ], [ 29.769733965517243, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13790, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.540789230769231 ], [ 29.770003448275862, -1.541328184615385 ], [ 29.770272931034484, -1.541328184615385 ], [ 29.770272931034484, -1.540789230769231 ], [ 29.770003448275862, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13791, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.540789230769231 ], [ 29.770272931034484, -1.541058707692308 ], [ 29.770542413793105, -1.541058707692308 ], [ 29.770542413793105, -1.540789230769231 ], [ 29.770272931034484, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13792, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.541058707692308 ], [ 29.75733775862069, -1.541597661538461 ], [ 29.757607241379311, -1.541597661538461 ], [ 29.757607241379311, -1.541058707692308 ], [ 29.75733775862069, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13793, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.541058707692308 ], [ 29.758146206896551, -1.541328184615385 ], [ 29.758415689655173, -1.541328184615385 ], [ 29.758415689655173, -1.541058707692308 ], [ 29.758146206896551, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13794, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.541058707692308 ], [ 29.758415689655173, -1.541597661538461 ], [ 29.758685172413795, -1.541597661538461 ], [ 29.758685172413795, -1.541058707692308 ], [ 29.758415689655173, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13795, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.541058707692308 ], [ 29.758685172413795, -1.541328184615385 ], [ 29.758954655172413, -1.541328184615385 ], [ 29.758954655172413, -1.541058707692308 ], [ 29.758685172413795, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13796, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.541058707692308 ], [ 29.758954655172413, -1.541328184615385 ], [ 29.759224137931035, -1.541328184615385 ], [ 29.759224137931035, -1.541058707692308 ], [ 29.758954655172413, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13797, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.541058707692308 ], [ 29.759224137931035, -1.541328184615385 ], [ 29.759493620689657, -1.541328184615385 ], [ 29.759493620689657, -1.541058707692308 ], [ 29.759224137931035, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13798, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.541058707692308 ], [ 29.76461379310345, -1.541597661538461 ], [ 29.764883275862069, -1.541597661538461 ], [ 29.764883275862069, -1.541058707692308 ], [ 29.76461379310345, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13799, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.541058707692308 ], [ 29.770811896551724, -1.541328184615385 ], [ 29.771081379310345, -1.541328184615385 ], [ 29.771081379310345, -1.541058707692308 ], [ 29.770811896551724, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13800, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.541328184615385 ], [ 29.757607241379311, -1.541597661538461 ], [ 29.757876724137933, -1.541597661538461 ], [ 29.757876724137933, -1.541328184615385 ], [ 29.757607241379311, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13801, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.540519753846154 ], [ 29.763805344827588, -1.542675569230769 ], [ 29.764074827586207, -1.542675569230769 ], [ 29.764074827586207, -1.542406092307692 ], [ 29.764344310344828, -1.542406092307692 ], [ 29.764344310344828, -1.541867138461538 ], [ 29.764074827586207, -1.541867138461538 ], [ 29.764074827586207, -1.540519753846154 ], [ 29.763805344827588, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13802, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.540519753846154 ], [ 29.768117068965516, -1.541867138461538 ], [ 29.768386551724138, -1.541867138461538 ], [ 29.768386551724138, -1.540519753846154 ], [ 29.768117068965516, -1.540519753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13803, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.540789230769231 ], [ 29.760302068965519, -1.542675569230769 ], [ 29.760571551724137, -1.542675569230769 ], [ 29.760571551724137, -1.540789230769231 ], [ 29.760302068965519, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13804, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.540789230769231 ], [ 29.761110517241381, -1.542675569230769 ], [ 29.76138, -1.542675569230769 ], [ 29.76138, -1.540789230769231 ], [ 29.761110517241381, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13805, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.540789230769231 ], [ 29.762188448275861, -1.542675569230769 ], [ 29.762457931034483, -1.542675569230769 ], [ 29.762457931034483, -1.540789230769231 ], [ 29.762188448275861, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13806, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.540789230769231 ], [ 29.762996896551723, -1.542675569230769 ], [ 29.763266379310345, -1.542675569230769 ], [ 29.763266379310345, -1.540789230769231 ], [ 29.762996896551723, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13807, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.540789230769231 ], [ 29.766769655172414, -1.541867138461538 ], [ 29.767039137931036, -1.541867138461538 ], [ 29.767039137931036, -1.540789230769231 ], [ 29.766769655172414, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13808, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.540789230769231 ], [ 29.767039137931036, -1.541867138461538 ], [ 29.767308620689654, -1.541867138461538 ], [ 29.767308620689654, -1.540789230769231 ], [ 29.767039137931036, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13809, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.540789230769231 ], [ 29.767847586206898, -1.541867138461538 ], [ 29.768117068965516, -1.541867138461538 ], [ 29.768117068965516, -1.540789230769231 ], [ 29.767847586206898, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13810, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.540789230769231 ], [ 29.76865603448276, -1.542136615384615 ], [ 29.768925517241382, -1.542136615384615 ], [ 29.768925517241382, -1.540789230769231 ], [ 29.76865603448276, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13811, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.540789230769231 ], [ 29.768925517241382, -1.542136615384615 ], [ 29.769195, -1.542136615384615 ], [ 29.769195, -1.540789230769231 ], [ 29.768925517241382, -1.540789230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13812, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.541058707692308 ], [ 29.759493620689657, -1.542406092307692 ], [ 29.759763103448275, -1.542406092307692 ], [ 29.759763103448275, -1.541058707692308 ], [ 29.759493620689657, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13813, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.541058707692308 ], [ 29.759763103448275, -1.542406092307692 ], [ 29.760032586206897, -1.542406092307692 ], [ 29.760032586206897, -1.541058707692308 ], [ 29.759763103448275, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13814, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.541058707692308 ], [ 29.760571551724137, -1.542406092307692 ], [ 29.760841034482759, -1.542406092307692 ], [ 29.760841034482759, -1.541058707692308 ], [ 29.760571551724137, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13815, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.541058707692308 ], [ 29.76138, -1.542406092307692 ], [ 29.761649482758621, -1.542406092307692 ], [ 29.761649482758621, -1.541058707692308 ], [ 29.76138, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13816, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.541058707692308 ], [ 29.761649482758621, -1.542406092307692 ], [ 29.761918965517243, -1.542406092307692 ], [ 29.761918965517243, -1.541058707692308 ], [ 29.761649482758621, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13817, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.541058707692308 ], [ 29.762457931034483, -1.542406092307692 ], [ 29.762727413793105, -1.542406092307692 ], [ 29.762727413793105, -1.541058707692308 ], [ 29.762457931034483, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13818, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.541058707692308 ], [ 29.763266379310345, -1.542406092307692 ], [ 29.763535862068967, -1.542406092307692 ], [ 29.763535862068967, -1.541058707692308 ], [ 29.763266379310345, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13819, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.541058707692308 ], [ 29.765422241379312, -1.541867138461538 ], [ 29.765961206896552, -1.541867138461538 ], [ 29.765961206896552, -1.541058707692308 ], [ 29.765422241379312, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13820, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.541058707692308 ], [ 29.767578103448276, -1.541867138461538 ], [ 29.767847586206898, -1.541867138461538 ], [ 29.767847586206898, -1.541058707692308 ], [ 29.767578103448276, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13821, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.541058707692308 ], [ 29.768386551724138, -1.542136615384615 ], [ 29.76865603448276, -1.542136615384615 ], [ 29.76865603448276, -1.541058707692308 ], [ 29.768386551724138, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13822, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.541058707692308 ], [ 29.769195, -1.542406092307692 ], [ 29.769464482758622, -1.542406092307692 ], [ 29.769464482758622, -1.541058707692308 ], [ 29.769195, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13823, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.541058707692308 ], [ 29.769464482758622, -1.542406092307692 ], [ 29.769733965517243, -1.542406092307692 ], [ 29.769733965517243, -1.541058707692308 ], [ 29.769464482758622, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13824, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.541058707692308 ], [ 29.769733965517243, -1.542406092307692 ], [ 29.770003448275862, -1.542406092307692 ], [ 29.770003448275862, -1.541058707692308 ], [ 29.769733965517243, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13825, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.541058707692308 ], [ 29.770272931034484, -1.542675569230769 ], [ 29.770542413793105, -1.542675569230769 ], [ 29.770542413793105, -1.541058707692308 ], [ 29.770272931034484, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13826, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.541058707692308 ], [ 29.770542413793105, -1.542675569230769 ], [ 29.770811896551724, -1.542675569230769 ], [ 29.770811896551724, -1.541058707692308 ], [ 29.770542413793105, -1.541058707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13827, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.541328184615385 ], [ 29.757876724137933, -1.542136615384615 ], [ 29.758146206896551, -1.542136615384615 ], [ 29.758146206896551, -1.541328184615385 ], [ 29.757876724137933, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13828, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.541328184615385 ], [ 29.758146206896551, -1.542136615384615 ], [ 29.758415689655173, -1.542136615384615 ], [ 29.758415689655173, -1.541328184615385 ], [ 29.758146206896551, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13829, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.541328184615385 ], [ 29.758685172413795, -1.542136615384615 ], [ 29.758954655172413, -1.542136615384615 ], [ 29.758954655172413, -1.541328184615385 ], [ 29.758685172413795, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13830, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.541328184615385 ], [ 29.758954655172413, -1.542136615384615 ], [ 29.759224137931035, -1.542136615384615 ], [ 29.759224137931035, -1.541328184615385 ], [ 29.758954655172413, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13831, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.541328184615385 ], [ 29.759224137931035, -1.542136615384615 ], [ 29.759493620689657, -1.542136615384615 ], [ 29.759493620689657, -1.541328184615385 ], [ 29.759224137931035, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13832, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.541328184615385 ], [ 29.760032586206897, -1.542136615384615 ], [ 29.760302068965519, -1.542136615384615 ], [ 29.760302068965519, -1.541328184615385 ], [ 29.760032586206897, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13833, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.541328184615385 ], [ 29.760841034482759, -1.542136615384615 ], [ 29.761110517241381, -1.542136615384615 ], [ 29.761110517241381, -1.541328184615385 ], [ 29.760841034482759, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13834, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.541328184615385 ], [ 29.762727413793105, -1.542136615384615 ], [ 29.762996896551723, -1.542136615384615 ], [ 29.762996896551723, -1.541328184615385 ], [ 29.762727413793105, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13835, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.541328184615385 ], [ 29.763535862068967, -1.542136615384615 ], [ 29.763805344827588, -1.542136615384615 ], [ 29.763805344827588, -1.541328184615385 ], [ 29.763535862068967, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13836, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.541328184615385 ], [ 29.764074827586207, -1.541867138461538 ], [ 29.764344310344828, -1.541867138461538 ], [ 29.764344310344828, -1.541328184615385 ], [ 29.764074827586207, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13837, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.541328184615385 ], [ 29.764344310344828, -1.541867138461538 ], [ 29.76461379310345, -1.541867138461538 ], [ 29.76461379310345, -1.541328184615385 ], [ 29.764344310344828, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13838, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.541328184615385 ], [ 29.76515275862069, -1.541867138461538 ], [ 29.765422241379312, -1.541867138461538 ], [ 29.765422241379312, -1.541328184615385 ], [ 29.76515275862069, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13839, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.541328184615385 ], [ 29.766500172413792, -1.541867138461538 ], [ 29.766769655172414, -1.541867138461538 ], [ 29.766769655172414, -1.541328184615385 ], [ 29.766500172413792, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13840, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.541328184615385 ], [ 29.770003448275862, -1.542406092307692 ], [ 29.770272931034484, -1.542406092307692 ], [ 29.770272931034484, -1.541328184615385 ], [ 29.770003448275862, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13841, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770811896551724, -1.541328184615385 ], [ 29.770811896551724, -1.542945046153846 ], [ 29.771081379310345, -1.542945046153846 ], [ 29.771081379310345, -1.541328184615385 ], [ 29.770811896551724, -1.541328184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13842, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.541597661538461 ], [ 29.757607241379311, -1.541867138461538 ], [ 29.757876724137933, -1.541867138461538 ], [ 29.757876724137933, -1.541597661538461 ], [ 29.757607241379311, -1.541597661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13843, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.541597661538461 ], [ 29.758415689655173, -1.541867138461538 ], [ 29.758685172413795, -1.541867138461538 ], [ 29.758685172413795, -1.541597661538461 ], [ 29.758415689655173, -1.541597661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13844, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.541597661538461 ], [ 29.761918965517243, -1.541867138461538 ], [ 29.762188448275861, -1.541867138461538 ], [ 29.762188448275861, -1.541597661538461 ], [ 29.761918965517243, -1.541597661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13845, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.541597661538461 ], [ 29.76461379310345, -1.542406092307692 ], [ 29.764883275862069, -1.542406092307692 ], [ 29.764883275862069, -1.541597661538461 ], [ 29.76461379310345, -1.541597661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13846, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.541597661538461 ], [ 29.764883275862069, -1.542136615384615 ], [ 29.76515275862069, -1.542136615384615 ], [ 29.76515275862069, -1.541597661538461 ], [ 29.764883275862069, -1.541597661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13847, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.541597661538461 ], [ 29.765961206896552, -1.542136615384615 ], [ 29.766230689655174, -1.542136615384615 ], [ 29.766230689655174, -1.541597661538461 ], [ 29.765961206896552, -1.541597661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13848, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.541597661538461 ], [ 29.766230689655174, -1.542136615384615 ], [ 29.766500172413792, -1.542136615384615 ], [ 29.766500172413792, -1.541597661538461 ], [ 29.766230689655174, -1.541597661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13849, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.541597661538461 ], [ 29.767308620689654, -1.542136615384615 ], [ 29.767578103448276, -1.542136615384615 ], [ 29.767578103448276, -1.541597661538461 ], [ 29.767308620689654, -1.541597661538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13850, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.541867138461538 ], [ 29.757068275862068, -1.542136615384615 ], [ 29.757607241379311, -1.542136615384615 ], [ 29.757607241379311, -1.541867138461538 ], [ 29.757068275862068, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13851, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.541867138461538 ], [ 29.757607241379311, -1.542136615384615 ], [ 29.757876724137933, -1.542136615384615 ], [ 29.757876724137933, -1.541867138461538 ], [ 29.757607241379311, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13852, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.541867138461538 ], [ 29.758415689655173, -1.542406092307692 ], [ 29.758685172413795, -1.542406092307692 ], [ 29.758685172413795, -1.541867138461538 ], [ 29.758415689655173, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13853, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.541867138461538 ], [ 29.761918965517243, -1.542675569230769 ], [ 29.762188448275861, -1.542675569230769 ], [ 29.762188448275861, -1.541867138461538 ], [ 29.761918965517243, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13854, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.541867138461538 ], [ 29.764344310344828, -1.542406092307692 ], [ 29.76461379310345, -1.542406092307692 ], [ 29.76461379310345, -1.541867138461538 ], [ 29.764344310344828, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13855, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.541867138461538 ], [ 29.76515275862069, -1.542675569230769 ], [ 29.765422241379312, -1.542675569230769 ], [ 29.765422241379312, -1.541867138461538 ], [ 29.76515275862069, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13856, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.541867138461538 ], [ 29.765422241379312, -1.542406092307692 ], [ 29.765961206896552, -1.542406092307692 ], [ 29.765961206896552, -1.541867138461538 ], [ 29.765422241379312, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13857, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.541867138461538 ], [ 29.766500172413792, -1.542406092307692 ], [ 29.766769655172414, -1.542406092307692 ], [ 29.766769655172414, -1.541867138461538 ], [ 29.766500172413792, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13858, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.541867138461538 ], [ 29.766769655172414, -1.542406092307692 ], [ 29.767039137931036, -1.542406092307692 ], [ 29.767039137931036, -1.541867138461538 ], [ 29.766769655172414, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13859, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.541867138461538 ], [ 29.767039137931036, -1.542136615384615 ], [ 29.767308620689654, -1.542136615384615 ], [ 29.767308620689654, -1.541867138461538 ], [ 29.767039137931036, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13860, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.541867138461538 ], [ 29.767578103448276, -1.542406092307692 ], [ 29.767847586206898, -1.542406092307692 ], [ 29.767847586206898, -1.541867138461538 ], [ 29.767578103448276, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13861, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.541867138461538 ], [ 29.767847586206898, -1.542136615384615 ], [ 29.768117068965516, -1.542136615384615 ], [ 29.768117068965516, -1.541867138461538 ], [ 29.767847586206898, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13862, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.541867138461538 ], [ 29.768117068965516, -1.542136615384615 ], [ 29.768386551724138, -1.542136615384615 ], [ 29.768386551724138, -1.541867138461538 ], [ 29.768117068965516, -1.541867138461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13863, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.542136615384615 ], [ 29.756259827586206, -1.542406092307692 ], [ 29.757068275862068, -1.542406092307692 ], [ 29.757068275862068, -1.542136615384615 ], [ 29.756259827586206, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13864, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.542136615384615 ], [ 29.757068275862068, -1.542406092307692 ], [ 29.75733775862069, -1.542406092307692 ], [ 29.75733775862069, -1.542136615384615 ], [ 29.757068275862068, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13865, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.542136615384615 ], [ 29.75733775862069, -1.542406092307692 ], [ 29.757876724137933, -1.542406092307692 ], [ 29.757876724137933, -1.542136615384615 ], [ 29.75733775862069, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13866, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.542136615384615 ], [ 29.757876724137933, -1.542406092307692 ], [ 29.758146206896551, -1.542406092307692 ], [ 29.758146206896551, -1.542136615384615 ], [ 29.757876724137933, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13867, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.542136615384615 ], [ 29.758146206896551, -1.542406092307692 ], [ 29.758415689655173, -1.542406092307692 ], [ 29.758415689655173, -1.542136615384615 ], [ 29.758146206896551, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13868, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.542136615384615 ], [ 29.758685172413795, -1.542406092307692 ], [ 29.758954655172413, -1.542406092307692 ], [ 29.758954655172413, -1.542136615384615 ], [ 29.758685172413795, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13869, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.542136615384615 ], [ 29.758954655172413, -1.542406092307692 ], [ 29.759224137931035, -1.542406092307692 ], [ 29.759224137931035, -1.542136615384615 ], [ 29.758954655172413, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13870, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.542136615384615 ], [ 29.759224137931035, -1.542406092307692 ], [ 29.759493620689657, -1.542406092307692 ], [ 29.759493620689657, -1.542136615384615 ], [ 29.759224137931035, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13871, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.542136615384615 ], [ 29.760032586206897, -1.542675569230769 ], [ 29.760302068965519, -1.542675569230769 ], [ 29.760302068965519, -1.542136615384615 ], [ 29.760032586206897, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13872, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.542136615384615 ], [ 29.760841034482759, -1.542675569230769 ], [ 29.761110517241381, -1.542675569230769 ], [ 29.761110517241381, -1.542136615384615 ], [ 29.760841034482759, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13873, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.542136615384615 ], [ 29.762727413793105, -1.542945046153846 ], [ 29.762996896551723, -1.542945046153846 ], [ 29.762996896551723, -1.542136615384615 ], [ 29.762727413793105, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13874, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.542136615384615 ], [ 29.763535862068967, -1.542945046153846 ], [ 29.763805344827588, -1.542945046153846 ], [ 29.763805344827588, -1.542136615384615 ], [ 29.763535862068967, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13875, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.542136615384615 ], [ 29.764883275862069, -1.542675569230769 ], [ 29.76515275862069, -1.542675569230769 ], [ 29.76515275862069, -1.542136615384615 ], [ 29.764883275862069, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13876, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.542136615384615 ], [ 29.765961206896552, -1.542945046153846 ], [ 29.766230689655174, -1.542945046153846 ], [ 29.766230689655174, -1.542136615384615 ], [ 29.765961206896552, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13877, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.542136615384615 ], [ 29.766230689655174, -1.542945046153846 ], [ 29.766500172413792, -1.542945046153846 ], [ 29.766500172413792, -1.542136615384615 ], [ 29.766230689655174, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13878, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.542136615384615 ], [ 29.767039137931036, -1.543214523076923 ], [ 29.767308620689654, -1.543214523076923 ], [ 29.767308620689654, -1.542136615384615 ], [ 29.767039137931036, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13879, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.542136615384615 ], [ 29.767308620689654, -1.542675569230769 ], [ 29.767578103448276, -1.542675569230769 ], [ 29.767578103448276, -1.542136615384615 ], [ 29.767308620689654, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13880, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.542136615384615 ], [ 29.767847586206898, -1.543484 ], [ 29.768117068965516, -1.543484 ], [ 29.768117068965516, -1.542136615384615 ], [ 29.767847586206898, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13881, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.542136615384615 ], [ 29.768117068965516, -1.542675569230769 ], [ 29.768386551724138, -1.542675569230769 ], [ 29.768386551724138, -1.542136615384615 ], [ 29.768117068965516, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13882, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.542136615384615 ], [ 29.768386551724138, -1.542406092307692 ], [ 29.76865603448276, -1.542406092307692 ], [ 29.76865603448276, -1.542136615384615 ], [ 29.768386551724138, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13883, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.542136615384615 ], [ 29.76865603448276, -1.542406092307692 ], [ 29.768925517241382, -1.542406092307692 ], [ 29.768925517241382, -1.542136615384615 ], [ 29.76865603448276, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13884, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.542136615384615 ], [ 29.768925517241382, -1.542406092307692 ], [ 29.769195, -1.542406092307692 ], [ 29.769195, -1.542136615384615 ], [ 29.768925517241382, -1.542136615384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13885, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.542406092307692 ], [ 29.755451379310344, -1.542675569230769 ], [ 29.756259827586206, -1.542675569230769 ], [ 29.756259827586206, -1.542406092307692 ], [ 29.755451379310344, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13886, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.542406092307692 ], [ 29.756259827586206, -1.542675569230769 ], [ 29.756798793103449, -1.542675569230769 ], [ 29.756798793103449, -1.542406092307692 ], [ 29.756259827586206, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13887, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.542406092307692 ], [ 29.756798793103449, -1.542675569230769 ], [ 29.75733775862069, -1.542675569230769 ], [ 29.75733775862069, -1.542406092307692 ], [ 29.756798793103449, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13888, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.542406092307692 ], [ 29.75733775862069, -1.542675569230769 ], [ 29.757876724137933, -1.542675569230769 ], [ 29.757876724137933, -1.542406092307692 ], [ 29.75733775862069, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13889, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.542406092307692 ], [ 29.757876724137933, -1.542675569230769 ], [ 29.758146206896551, -1.542675569230769 ], [ 29.758146206896551, -1.542406092307692 ], [ 29.757876724137933, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13890, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.542406092307692 ], [ 29.758146206896551, -1.542675569230769 ], [ 29.758415689655173, -1.542675569230769 ], [ 29.758415689655173, -1.542406092307692 ], [ 29.758146206896551, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13891, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.542406092307692 ], [ 29.758415689655173, -1.542675569230769 ], [ 29.758685172413795, -1.542675569230769 ], [ 29.758685172413795, -1.542406092307692 ], [ 29.758415689655173, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13892, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.542406092307692 ], [ 29.758685172413795, -1.542675569230769 ], [ 29.758954655172413, -1.542675569230769 ], [ 29.758954655172413, -1.542406092307692 ], [ 29.758685172413795, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13893, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.542406092307692 ], [ 29.758954655172413, -1.542675569230769 ], [ 29.759224137931035, -1.542675569230769 ], [ 29.759224137931035, -1.542406092307692 ], [ 29.758954655172413, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13894, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.542406092307692 ], [ 29.759224137931035, -1.542675569230769 ], [ 29.759493620689657, -1.542675569230769 ], [ 29.759493620689657, -1.542406092307692 ], [ 29.759224137931035, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13895, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.542406092307692 ], [ 29.759493620689657, -1.542675569230769 ], [ 29.759763103448275, -1.542675569230769 ], [ 29.759763103448275, -1.542406092307692 ], [ 29.759493620689657, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13896, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.542406092307692 ], [ 29.759763103448275, -1.542675569230769 ], [ 29.760032586206897, -1.542675569230769 ], [ 29.760032586206897, -1.542406092307692 ], [ 29.759763103448275, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13897, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.542406092307692 ], [ 29.760571551724137, -1.542945046153846 ], [ 29.760841034482759, -1.542945046153846 ], [ 29.760841034482759, -1.542406092307692 ], [ 29.760571551724137, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13898, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.542406092307692 ], [ 29.76138, -1.542945046153846 ], [ 29.761649482758621, -1.542945046153846 ], [ 29.761649482758621, -1.542406092307692 ], [ 29.76138, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13899, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.542406092307692 ], [ 29.761649482758621, -1.542945046153846 ], [ 29.761918965517243, -1.542945046153846 ], [ 29.761918965517243, -1.542406092307692 ], [ 29.761649482758621, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13900, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.542406092307692 ], [ 29.762457931034483, -1.542945046153846 ], [ 29.762727413793105, -1.542945046153846 ], [ 29.762727413793105, -1.542406092307692 ], [ 29.762457931034483, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13901, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.542406092307692 ], [ 29.763266379310345, -1.543214523076923 ], [ 29.763535862068967, -1.543214523076923 ], [ 29.763535862068967, -1.542406092307692 ], [ 29.763266379310345, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13902, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.542406092307692 ], [ 29.764074827586207, -1.542675569230769 ], [ 29.764344310344828, -1.542675569230769 ], [ 29.764344310344828, -1.542406092307692 ], [ 29.764074827586207, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13903, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.542406092307692 ], [ 29.764344310344828, -1.542675569230769 ], [ 29.76461379310345, -1.542675569230769 ], [ 29.76461379310345, -1.542406092307692 ], [ 29.764344310344828, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13904, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.542406092307692 ], [ 29.76461379310345, -1.542675569230769 ], [ 29.764883275862069, -1.542675569230769 ], [ 29.764883275862069, -1.542406092307692 ], [ 29.76461379310345, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13905, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.542406092307692 ], [ 29.765422241379312, -1.542945046153846 ], [ 29.765961206896552, -1.542945046153846 ], [ 29.765961206896552, -1.542406092307692 ], [ 29.765422241379312, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13906, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.542406092307692 ], [ 29.766500172413792, -1.543214523076923 ], [ 29.766769655172414, -1.543214523076923 ], [ 29.766769655172414, -1.542406092307692 ], [ 29.766500172413792, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13907, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.542406092307692 ], [ 29.766769655172414, -1.543214523076923 ], [ 29.767039137931036, -1.543214523076923 ], [ 29.767039137931036, -1.542406092307692 ], [ 29.766769655172414, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13908, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.542406092307692 ], [ 29.767578103448276, -1.543484 ], [ 29.767847586206898, -1.543484 ], [ 29.767847586206898, -1.542406092307692 ], [ 29.767578103448276, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13909, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.542406092307692 ], [ 29.768925517241382, -1.542675569230769 ], [ 29.769195, -1.542675569230769 ], [ 29.769195, -1.542406092307692 ], [ 29.768925517241382, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13910, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.542406092307692 ], [ 29.769195, -1.542675569230769 ], [ 29.769464482758622, -1.542675569230769 ], [ 29.769464482758622, -1.542406092307692 ], [ 29.769195, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13911, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.542406092307692 ], [ 29.769464482758622, -1.542675569230769 ], [ 29.769733965517243, -1.542675569230769 ], [ 29.769733965517243, -1.542406092307692 ], [ 29.769464482758622, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13912, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.542406092307692 ], [ 29.769733965517243, -1.542945046153846 ], [ 29.770003448275862, -1.542945046153846 ], [ 29.770003448275862, -1.542406092307692 ], [ 29.769733965517243, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13913, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.542406092307692 ], [ 29.770003448275862, -1.542675569230769 ], [ 29.770272931034484, -1.542675569230769 ], [ 29.770272931034484, -1.542406092307692 ], [ 29.770003448275862, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13914, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.542675569230769 ], [ 29.75329551724138, -1.542945046153846 ], [ 29.755451379310344, -1.542945046153846 ], [ 29.755451379310344, -1.542675569230769 ], [ 29.75329551724138, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13915, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.542675569230769 ], [ 29.755451379310344, -1.542945046153846 ], [ 29.755720862068966, -1.542945046153846 ], [ 29.755720862068966, -1.542675569230769 ], [ 29.755451379310344, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13916, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.542675569230769 ], [ 29.755720862068966, -1.542945046153846 ], [ 29.756798793103449, -1.542945046153846 ], [ 29.756798793103449, -1.542675569230769 ], [ 29.755720862068966, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13917, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.542675569230769 ], [ 29.756798793103449, -1.542945046153846 ], [ 29.75733775862069, -1.542945046153846 ], [ 29.75733775862069, -1.542675569230769 ], [ 29.756798793103449, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13918, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.542675569230769 ], [ 29.75733775862069, -1.542945046153846 ], [ 29.757607241379311, -1.542945046153846 ], [ 29.757607241379311, -1.542675569230769 ], [ 29.75733775862069, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13919, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.542675569230769 ], [ 29.757607241379311, -1.542945046153846 ], [ 29.758146206896551, -1.542945046153846 ], [ 29.758146206896551, -1.542675569230769 ], [ 29.757607241379311, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13920, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.542675569230769 ], [ 29.758146206896551, -1.542945046153846 ], [ 29.758415689655173, -1.542945046153846 ], [ 29.758415689655173, -1.542675569230769 ], [ 29.758146206896551, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13921, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.542675569230769 ], [ 29.758415689655173, -1.542945046153846 ], [ 29.758685172413795, -1.542945046153846 ], [ 29.758685172413795, -1.542675569230769 ], [ 29.758415689655173, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13922, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.542675569230769 ], [ 29.758685172413795, -1.542945046153846 ], [ 29.758954655172413, -1.542945046153846 ], [ 29.758954655172413, -1.542675569230769 ], [ 29.758685172413795, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13923, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.542675569230769 ], [ 29.758954655172413, -1.542945046153846 ], [ 29.759224137931035, -1.542945046153846 ], [ 29.759224137931035, -1.542675569230769 ], [ 29.758954655172413, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13924, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.542675569230769 ], [ 29.759224137931035, -1.542945046153846 ], [ 29.759493620689657, -1.542945046153846 ], [ 29.759493620689657, -1.542675569230769 ], [ 29.759224137931035, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13925, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.542675569230769 ], [ 29.759493620689657, -1.542945046153846 ], [ 29.759763103448275, -1.542945046153846 ], [ 29.759763103448275, -1.542675569230769 ], [ 29.759493620689657, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13926, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.542675569230769 ], [ 29.759763103448275, -1.542945046153846 ], [ 29.760032586206897, -1.542945046153846 ], [ 29.760032586206897, -1.542675569230769 ], [ 29.759763103448275, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13927, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.542675569230769 ], [ 29.760032586206897, -1.542945046153846 ], [ 29.760302068965519, -1.542945046153846 ], [ 29.760302068965519, -1.542675569230769 ], [ 29.760032586206897, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13928, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.542675569230769 ], [ 29.760302068965519, -1.542945046153846 ], [ 29.760571551724137, -1.542945046153846 ], [ 29.760571551724137, -1.542675569230769 ], [ 29.760302068965519, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13929, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.542675569230769 ], [ 29.760841034482759, -1.543214523076923 ], [ 29.761110517241381, -1.543214523076923 ], [ 29.761110517241381, -1.542675569230769 ], [ 29.760841034482759, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13930, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.542675569230769 ], [ 29.761110517241381, -1.542945046153846 ], [ 29.76138, -1.542945046153846 ], [ 29.76138, -1.542675569230769 ], [ 29.761110517241381, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13931, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.542675569230769 ], [ 29.761918965517243, -1.543214523076923 ], [ 29.762188448275861, -1.543214523076923 ], [ 29.762188448275861, -1.542675569230769 ], [ 29.761918965517243, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13932, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.542675569230769 ], [ 29.762188448275861, -1.543214523076923 ], [ 29.762457931034483, -1.543214523076923 ], [ 29.762457931034483, -1.542675569230769 ], [ 29.762188448275861, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13933, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.542675569230769 ], [ 29.762996896551723, -1.543214523076923 ], [ 29.763266379310345, -1.543214523076923 ], [ 29.763266379310345, -1.542675569230769 ], [ 29.762996896551723, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13934, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.542675569230769 ], [ 29.763805344827588, -1.542945046153846 ], [ 29.764074827586207, -1.542945046153846 ], [ 29.764074827586207, -1.542675569230769 ], [ 29.763805344827588, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13935, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.542675569230769 ], [ 29.764074827586207, -1.542945046153846 ], [ 29.764344310344828, -1.542945046153846 ], [ 29.764344310344828, -1.542675569230769 ], [ 29.764074827586207, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13936, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.542675569230769 ], [ 29.764344310344828, -1.542945046153846 ], [ 29.76461379310345, -1.542945046153846 ], [ 29.76461379310345, -1.542675569230769 ], [ 29.764344310344828, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13937, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.542675569230769 ], [ 29.76461379310345, -1.542945046153846 ], [ 29.764883275862069, -1.542945046153846 ], [ 29.764883275862069, -1.542675569230769 ], [ 29.76461379310345, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13938, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.542675569230769 ], [ 29.764883275862069, -1.542945046153846 ], [ 29.76515275862069, -1.542945046153846 ], [ 29.76515275862069, -1.542675569230769 ], [ 29.764883275862069, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13939, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.542675569230769 ], [ 29.76515275862069, -1.542945046153846 ], [ 29.765422241379312, -1.542945046153846 ], [ 29.765422241379312, -1.542675569230769 ], [ 29.76515275862069, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13940, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.542675569230769 ], [ 29.767308620689654, -1.543484 ], [ 29.767578103448276, -1.543484 ], [ 29.767578103448276, -1.542675569230769 ], [ 29.767308620689654, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13941, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.542675569230769 ], [ 29.770542413793105, -1.542945046153846 ], [ 29.770811896551724, -1.542945046153846 ], [ 29.770811896551724, -1.542675569230769 ], [ 29.770542413793105, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13942, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.542945046153846 ], [ 29.75329551724138, -1.543214523076923 ], [ 29.753565, -1.543214523076923 ], [ 29.753565, -1.542945046153846 ], [ 29.75329551724138, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13943, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.542945046153846 ], [ 29.753565, -1.543214523076923 ], [ 29.755720862068966, -1.543214523076923 ], [ 29.755720862068966, -1.542945046153846 ], [ 29.753565, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13944, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.542945046153846 ], [ 29.755720862068966, -1.543214523076923 ], [ 29.756798793103449, -1.543214523076923 ], [ 29.756798793103449, -1.542945046153846 ], [ 29.755720862068966, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13945, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.542945046153846 ], [ 29.756798793103449, -1.543214523076923 ], [ 29.757068275862068, -1.543214523076923 ], [ 29.757068275862068, -1.542945046153846 ], [ 29.756798793103449, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13946, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.542945046153846 ], [ 29.757068275862068, -1.543214523076923 ], [ 29.757607241379311, -1.543214523076923 ], [ 29.757607241379311, -1.542945046153846 ], [ 29.757068275862068, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13947, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.542945046153846 ], [ 29.757607241379311, -1.543214523076923 ], [ 29.757876724137933, -1.543214523076923 ], [ 29.757876724137933, -1.542945046153846 ], [ 29.757607241379311, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13948, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.542945046153846 ], [ 29.757876724137933, -1.543214523076923 ], [ 29.758146206896551, -1.543214523076923 ], [ 29.758146206896551, -1.542945046153846 ], [ 29.757876724137933, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13949, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.542945046153846 ], [ 29.758146206896551, -1.543214523076923 ], [ 29.758415689655173, -1.543214523076923 ], [ 29.758415689655173, -1.542945046153846 ], [ 29.758146206896551, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13950, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.542945046153846 ], [ 29.758415689655173, -1.543214523076923 ], [ 29.758685172413795, -1.543214523076923 ], [ 29.758685172413795, -1.542945046153846 ], [ 29.758415689655173, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13951, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.542945046153846 ], [ 29.758685172413795, -1.543214523076923 ], [ 29.758954655172413, -1.543214523076923 ], [ 29.758954655172413, -1.542945046153846 ], [ 29.758685172413795, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13952, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.542945046153846 ], [ 29.758954655172413, -1.543214523076923 ], [ 29.759224137931035, -1.543214523076923 ], [ 29.759224137931035, -1.542945046153846 ], [ 29.758954655172413, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13953, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.542945046153846 ], [ 29.759224137931035, -1.543214523076923 ], [ 29.759493620689657, -1.543214523076923 ], [ 29.759493620689657, -1.542945046153846 ], [ 29.759224137931035, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13954, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.542945046153846 ], [ 29.759493620689657, -1.543214523076923 ], [ 29.759763103448275, -1.543214523076923 ], [ 29.759763103448275, -1.542945046153846 ], [ 29.759493620689657, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13955, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.542945046153846 ], [ 29.759763103448275, -1.543214523076923 ], [ 29.760032586206897, -1.543214523076923 ], [ 29.760032586206897, -1.542945046153846 ], [ 29.759763103448275, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13956, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.542945046153846 ], [ 29.760032586206897, -1.543214523076923 ], [ 29.760302068965519, -1.543214523076923 ], [ 29.760302068965519, -1.542945046153846 ], [ 29.760032586206897, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13957, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.542945046153846 ], [ 29.760302068965519, -1.543214523076923 ], [ 29.760571551724137, -1.543214523076923 ], [ 29.760571551724137, -1.542945046153846 ], [ 29.760302068965519, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13958, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.542945046153846 ], [ 29.760571551724137, -1.543214523076923 ], [ 29.760841034482759, -1.543214523076923 ], [ 29.760841034482759, -1.542945046153846 ], [ 29.760571551724137, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13959, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.542945046153846 ], [ 29.761110517241381, -1.543484 ], [ 29.76138, -1.543484 ], [ 29.76138, -1.542945046153846 ], [ 29.761110517241381, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13960, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.542945046153846 ], [ 29.76138, -1.543214523076923 ], [ 29.761649482758621, -1.543214523076923 ], [ 29.761649482758621, -1.542945046153846 ], [ 29.76138, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13961, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.542945046153846 ], [ 29.761649482758621, -1.543214523076923 ], [ 29.761918965517243, -1.543214523076923 ], [ 29.761918965517243, -1.542945046153846 ], [ 29.761649482758621, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13962, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.542945046153846 ], [ 29.762457931034483, -1.543484 ], [ 29.762727413793105, -1.543484 ], [ 29.762727413793105, -1.542945046153846 ], [ 29.762457931034483, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13963, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.542945046153846 ], [ 29.762727413793105, -1.543484 ], [ 29.762996896551723, -1.543484 ], [ 29.762996896551723, -1.542945046153846 ], [ 29.762727413793105, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13964, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.542945046153846 ], [ 29.763535862068967, -1.543484 ], [ 29.763805344827588, -1.543484 ], [ 29.763805344827588, -1.542945046153846 ], [ 29.763535862068967, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13965, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.542945046153846 ], [ 29.763805344827588, -1.543214523076923 ], [ 29.764074827586207, -1.543214523076923 ], [ 29.764074827586207, -1.542945046153846 ], [ 29.763805344827588, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13966, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.542945046153846 ], [ 29.764074827586207, -1.543214523076923 ], [ 29.764344310344828, -1.543214523076923 ], [ 29.764344310344828, -1.542945046153846 ], [ 29.764074827586207, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13967, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.542945046153846 ], [ 29.764344310344828, -1.543214523076923 ], [ 29.76461379310345, -1.543214523076923 ], [ 29.76461379310345, -1.542945046153846 ], [ 29.764344310344828, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13968, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.542945046153846 ], [ 29.76461379310345, -1.543214523076923 ], [ 29.764883275862069, -1.543214523076923 ], [ 29.764883275862069, -1.542945046153846 ], [ 29.76461379310345, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13969, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.542945046153846 ], [ 29.764883275862069, -1.543214523076923 ], [ 29.76515275862069, -1.543214523076923 ], [ 29.76515275862069, -1.542945046153846 ], [ 29.764883275862069, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13970, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.542945046153846 ], [ 29.76515275862069, -1.543214523076923 ], [ 29.765422241379312, -1.543214523076923 ], [ 29.765422241379312, -1.542945046153846 ], [ 29.76515275862069, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13971, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.542945046153846 ], [ 29.765422241379312, -1.543214523076923 ], [ 29.765961206896552, -1.543214523076923 ], [ 29.765961206896552, -1.542945046153846 ], [ 29.765422241379312, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13972, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.542945046153846 ], [ 29.765961206896552, -1.543214523076923 ], [ 29.766230689655174, -1.543214523076923 ], [ 29.766230689655174, -1.542945046153846 ], [ 29.765961206896552, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13973, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.542945046153846 ], [ 29.766230689655174, -1.543214523076923 ], [ 29.766500172413792, -1.543214523076923 ], [ 29.766500172413792, -1.542945046153846 ], [ 29.766230689655174, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13974, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.543214523076923 ], [ 29.753565, -1.543484 ], [ 29.755720862068966, -1.543484 ], [ 29.755720862068966, -1.543214523076923 ], [ 29.753565, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13975, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.543214523076923 ], [ 29.755720862068966, -1.543484 ], [ 29.755990344827588, -1.543484 ], [ 29.755990344827588, -1.543214523076923 ], [ 29.755720862068966, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13976, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.543214523076923 ], [ 29.755990344827588, -1.543484 ], [ 29.756259827586206, -1.543484 ], [ 29.756259827586206, -1.543214523076923 ], [ 29.755990344827588, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13977, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.543214523076923 ], [ 29.756259827586206, -1.543484 ], [ 29.757068275862068, -1.543484 ], [ 29.757068275862068, -1.543214523076923 ], [ 29.756259827586206, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13978, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.543214523076923 ], [ 29.757068275862068, -1.543484 ], [ 29.75733775862069, -1.543484 ], [ 29.75733775862069, -1.543214523076923 ], [ 29.757068275862068, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13979, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.543214523076923 ], [ 29.75733775862069, -1.543484 ], [ 29.757607241379311, -1.543484 ], [ 29.757607241379311, -1.543214523076923 ], [ 29.75733775862069, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13980, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.543214523076923 ], [ 29.757607241379311, -1.543484 ], [ 29.757876724137933, -1.543484 ], [ 29.757876724137933, -1.543214523076923 ], [ 29.757607241379311, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13981, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.543214523076923 ], [ 29.757876724137933, -1.543484 ], [ 29.758146206896551, -1.543484 ], [ 29.758146206896551, -1.543214523076923 ], [ 29.757876724137933, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13982, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.543214523076923 ], [ 29.758146206896551, -1.543484 ], [ 29.758415689655173, -1.543484 ], [ 29.758415689655173, -1.543214523076923 ], [ 29.758146206896551, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13983, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.543214523076923 ], [ 29.758415689655173, -1.543484 ], [ 29.758685172413795, -1.543484 ], [ 29.758685172413795, -1.543214523076923 ], [ 29.758415689655173, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13984, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.543214523076923 ], [ 29.758685172413795, -1.543484 ], [ 29.758954655172413, -1.543484 ], [ 29.758954655172413, -1.543214523076923 ], [ 29.758685172413795, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13985, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.543214523076923 ], [ 29.758954655172413, -1.543484 ], [ 29.759224137931035, -1.543484 ], [ 29.759224137931035, -1.543214523076923 ], [ 29.758954655172413, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13986, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.543214523076923 ], [ 29.759224137931035, -1.543484 ], [ 29.759493620689657, -1.543484 ], [ 29.759493620689657, -1.543214523076923 ], [ 29.759224137931035, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13987, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.543214523076923 ], [ 29.759493620689657, -1.543484 ], [ 29.759763103448275, -1.543484 ], [ 29.759763103448275, -1.543214523076923 ], [ 29.759493620689657, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13988, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.543214523076923 ], [ 29.759763103448275, -1.543484 ], [ 29.760032586206897, -1.543484 ], [ 29.760032586206897, -1.543214523076923 ], [ 29.759763103448275, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13989, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.543214523076923 ], [ 29.760032586206897, -1.543484 ], [ 29.760302068965519, -1.543484 ], [ 29.760302068965519, -1.543214523076923 ], [ 29.760032586206897, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13990, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.543214523076923 ], [ 29.760302068965519, -1.543484 ], [ 29.760571551724137, -1.543484 ], [ 29.760571551724137, -1.543214523076923 ], [ 29.760302068965519, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13991, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.543214523076923 ], [ 29.760571551724137, -1.543484 ], [ 29.760841034482759, -1.543484 ], [ 29.760841034482759, -1.543214523076923 ], [ 29.760571551724137, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13992, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.543214523076923 ], [ 29.760841034482759, -1.543484 ], [ 29.761110517241381, -1.543484 ], [ 29.761110517241381, -1.543214523076923 ], [ 29.760841034482759, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13993, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.543214523076923 ], [ 29.761649482758621, -1.543484 ], [ 29.761918965517243, -1.543484 ], [ 29.761918965517243, -1.543214523076923 ], [ 29.761649482758621, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13994, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.543214523076923 ], [ 29.761918965517243, -1.543484 ], [ 29.762188448275861, -1.543484 ], [ 29.762188448275861, -1.543214523076923 ], [ 29.761918965517243, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13995, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.543214523076923 ], [ 29.762188448275861, -1.543484 ], [ 29.762457931034483, -1.543484 ], [ 29.762457931034483, -1.543214523076923 ], [ 29.762188448275861, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13996, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.543214523076923 ], [ 29.763266379310345, -1.543484 ], [ 29.763535862068967, -1.543484 ], [ 29.763535862068967, -1.543214523076923 ], [ 29.763266379310345, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13997, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.543214523076923 ], [ 29.763805344827588, -1.543484 ], [ 29.764074827586207, -1.543484 ], [ 29.764074827586207, -1.543214523076923 ], [ 29.763805344827588, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13998, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.543214523076923 ], [ 29.764074827586207, -1.543484 ], [ 29.764344310344828, -1.543484 ], [ 29.764344310344828, -1.543214523076923 ], [ 29.764074827586207, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13999, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.543214523076923 ], [ 29.764344310344828, -1.543484 ], [ 29.76461379310345, -1.543484 ], [ 29.76461379310345, -1.543214523076923 ], [ 29.764344310344828, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14000, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.543214523076923 ], [ 29.76461379310345, -1.543484 ], [ 29.764883275862069, -1.543484 ], [ 29.764883275862069, -1.543214523076923 ], [ 29.76461379310345, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14001, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.543214523076923 ], [ 29.764883275862069, -1.543484 ], [ 29.76515275862069, -1.543484 ], [ 29.76515275862069, -1.543214523076923 ], [ 29.764883275862069, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14002, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.543214523076923 ], [ 29.76515275862069, -1.543484 ], [ 29.765422241379312, -1.543484 ], [ 29.765422241379312, -1.543214523076923 ], [ 29.76515275862069, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14003, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.543214523076923 ], [ 29.765422241379312, -1.543484 ], [ 29.765961206896552, -1.543484 ], [ 29.765961206896552, -1.543214523076923 ], [ 29.765422241379312, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14004, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.543214523076923 ], [ 29.765961206896552, -1.543484 ], [ 29.766230689655174, -1.543484 ], [ 29.766230689655174, -1.543214523076923 ], [ 29.765961206896552, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14005, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.543214523076923 ], [ 29.766230689655174, -1.543484 ], [ 29.766500172413792, -1.543484 ], [ 29.766500172413792, -1.543214523076923 ], [ 29.766230689655174, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14006, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.543214523076923 ], [ 29.766500172413792, -1.543484 ], [ 29.766769655172414, -1.543484 ], [ 29.766769655172414, -1.543214523076923 ], [ 29.766500172413792, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14007, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.543214523076923 ], [ 29.766769655172414, -1.543484 ], [ 29.767039137931036, -1.543484 ], [ 29.767039137931036, -1.543214523076923 ], [ 29.766769655172414, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14008, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.543214523076923 ], [ 29.767039137931036, -1.543484 ], [ 29.767308620689654, -1.543484 ], [ 29.767308620689654, -1.543214523076923 ], [ 29.767039137931036, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14009, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.542406092307692 ], [ 29.768386551724138, -1.544022953846154 ], [ 29.76865603448276, -1.544022953846154 ], [ 29.76865603448276, -1.542406092307692 ], [ 29.768386551724138, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14010, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.542406092307692 ], [ 29.76865603448276, -1.544022953846154 ], [ 29.768925517241382, -1.544022953846154 ], [ 29.768925517241382, -1.542406092307692 ], [ 29.76865603448276, -1.542406092307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14011, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.542675569230769 ], [ 29.768117068965516, -1.544022953846154 ], [ 29.768386551724138, -1.544022953846154 ], [ 29.768386551724138, -1.542675569230769 ], [ 29.768117068965516, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14012, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.542675569230769 ], [ 29.768925517241382, -1.544292430769231 ], [ 29.769195, -1.544292430769231 ], [ 29.769195, -1.542675569230769 ], [ 29.768925517241382, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14013, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.542675569230769 ], [ 29.769195, -1.544292430769231 ], [ 29.769464482758622, -1.544292430769231 ], [ 29.769464482758622, -1.542675569230769 ], [ 29.769195, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14014, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.542675569230769 ], [ 29.769464482758622, -1.544292430769231 ], [ 29.769733965517243, -1.544292430769231 ], [ 29.769733965517243, -1.542675569230769 ], [ 29.769464482758622, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14015, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.542675569230769 ], [ 29.770003448275862, -1.544561907692308 ], [ 29.770272931034484, -1.544561907692308 ], [ 29.770272931034484, -1.542675569230769 ], [ 29.770003448275862, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14016, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.542675569230769 ], [ 29.770272931034484, -1.544561907692308 ], [ 29.770542413793105, -1.544561907692308 ], [ 29.770542413793105, -1.542675569230769 ], [ 29.770272931034484, -1.542675569230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14017, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75275655172414, -1.542945046153846 ], [ 29.75275655172414, -1.544831384615384 ], [ 29.753026034482758, -1.544831384615384 ], [ 29.753026034482758, -1.543214523076923 ], [ 29.75329551724138, -1.543214523076923 ], [ 29.75329551724138, -1.542945046153846 ], [ 29.75275655172414, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14018, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.542945046153846 ], [ 29.769733965517243, -1.544292430769231 ], [ 29.770003448275862, -1.544292430769231 ], [ 29.770003448275862, -1.542945046153846 ], [ 29.769733965517243, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14019, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770542413793105, -1.542945046153846 ], [ 29.770542413793105, -1.544831384615384 ], [ 29.770811896551724, -1.544831384615384 ], [ 29.770811896551724, -1.542945046153846 ], [ 29.770542413793105, -1.542945046153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14020, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.543214523076923 ], [ 29.753026034482758, -1.544561907692308 ], [ 29.75329551724138, -1.544561907692308 ], [ 29.75329551724138, -1.543484 ], [ 29.753565, -1.543484 ], [ 29.753565, -1.543214523076923 ], [ 29.753026034482758, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14021, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.543214523076923 ], [ 29.76138, -1.544022953846154 ], [ 29.761649482758621, -1.544022953846154 ], [ 29.761649482758621, -1.543214523076923 ], [ 29.76138, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14022, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.543214523076923 ], [ 29.762996896551723, -1.544022953846154 ], [ 29.763266379310345, -1.544022953846154 ], [ 29.763266379310345, -1.543214523076923 ], [ 29.762996896551723, -1.543214523076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14023, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.543484 ], [ 29.75329551724138, -1.544561907692308 ], [ 29.753565, -1.544561907692308 ], [ 29.753565, -1.543484 ], [ 29.75329551724138, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14024, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.543484 ], [ 29.753565, -1.544022953846154 ], [ 29.75383448275862, -1.544022953846154 ], [ 29.75383448275862, -1.543484 ], [ 29.753565, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14025, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.543484 ], [ 29.75383448275862, -1.544022953846154 ], [ 29.755451379310344, -1.544022953846154 ], [ 29.755451379310344, -1.543484 ], [ 29.75383448275862, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14026, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.543484 ], [ 29.755451379310344, -1.544022953846154 ], [ 29.755990344827588, -1.544022953846154 ], [ 29.755990344827588, -1.543484 ], [ 29.755451379310344, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14027, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.543484 ], [ 29.755990344827588, -1.544022953846154 ], [ 29.756798793103449, -1.544022953846154 ], [ 29.756798793103449, -1.543484 ], [ 29.755990344827588, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14028, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.543484 ], [ 29.756798793103449, -1.544022953846154 ], [ 29.757068275862068, -1.544022953846154 ], [ 29.757068275862068, -1.543484 ], [ 29.756798793103449, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14029, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.543484 ], [ 29.757068275862068, -1.544022953846154 ], [ 29.75733775862069, -1.544022953846154 ], [ 29.75733775862069, -1.543484 ], [ 29.757068275862068, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14030, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.543484 ], [ 29.75733775862069, -1.544022953846154 ], [ 29.757607241379311, -1.544022953846154 ], [ 29.757607241379311, -1.543484 ], [ 29.75733775862069, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14031, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.543484 ], [ 29.757607241379311, -1.544022953846154 ], [ 29.757876724137933, -1.544022953846154 ], [ 29.757876724137933, -1.543484 ], [ 29.757607241379311, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14032, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.543484 ], [ 29.757876724137933, -1.544022953846154 ], [ 29.758146206896551, -1.544022953846154 ], [ 29.758146206896551, -1.543484 ], [ 29.757876724137933, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14033, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.543484 ], [ 29.758146206896551, -1.544022953846154 ], [ 29.758415689655173, -1.544022953846154 ], [ 29.758415689655173, -1.543484 ], [ 29.758146206896551, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14034, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.543484 ], [ 29.758415689655173, -1.544022953846154 ], [ 29.758685172413795, -1.544022953846154 ], [ 29.758685172413795, -1.543484 ], [ 29.758415689655173, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14035, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.543484 ], [ 29.758685172413795, -1.544022953846154 ], [ 29.758954655172413, -1.544022953846154 ], [ 29.758954655172413, -1.543484 ], [ 29.758685172413795, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14036, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.543484 ], [ 29.758954655172413, -1.544022953846154 ], [ 29.759224137931035, -1.544022953846154 ], [ 29.759224137931035, -1.543484 ], [ 29.758954655172413, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14037, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.543484 ], [ 29.759224137931035, -1.544022953846154 ], [ 29.759493620689657, -1.544022953846154 ], [ 29.759493620689657, -1.543484 ], [ 29.759224137931035, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14038, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.543484 ], [ 29.759493620689657, -1.544022953846154 ], [ 29.759763103448275, -1.544022953846154 ], [ 29.759763103448275, -1.543484 ], [ 29.759493620689657, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14039, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.543484 ], [ 29.759763103448275, -1.544022953846154 ], [ 29.760032586206897, -1.544022953846154 ], [ 29.760032586206897, -1.543484 ], [ 29.759763103448275, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14040, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.543484 ], [ 29.760032586206897, -1.544022953846154 ], [ 29.760302068965519, -1.544022953846154 ], [ 29.760302068965519, -1.543484 ], [ 29.760032586206897, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14041, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.543484 ], [ 29.760302068965519, -1.544022953846154 ], [ 29.760571551724137, -1.544022953846154 ], [ 29.760571551724137, -1.543484 ], [ 29.760302068965519, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14042, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.543484 ], [ 29.760571551724137, -1.544022953846154 ], [ 29.760841034482759, -1.544022953846154 ], [ 29.760841034482759, -1.543484 ], [ 29.760571551724137, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14043, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.543484 ], [ 29.760841034482759, -1.544022953846154 ], [ 29.761110517241381, -1.544022953846154 ], [ 29.761110517241381, -1.543484 ], [ 29.760841034482759, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14044, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.543484 ], [ 29.761110517241381, -1.544022953846154 ], [ 29.76138, -1.544022953846154 ], [ 29.76138, -1.543484 ], [ 29.761110517241381, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14045, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.543484 ], [ 29.761649482758621, -1.544022953846154 ], [ 29.761918965517243, -1.544022953846154 ], [ 29.761918965517243, -1.543484 ], [ 29.761649482758621, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14046, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.543484 ], [ 29.761918965517243, -1.544022953846154 ], [ 29.762188448275861, -1.544022953846154 ], [ 29.762188448275861, -1.543484 ], [ 29.761918965517243, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14047, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.543484 ], [ 29.762188448275861, -1.544022953846154 ], [ 29.762457931034483, -1.544022953846154 ], [ 29.762457931034483, -1.543484 ], [ 29.762188448275861, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14048, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.543484 ], [ 29.762457931034483, -1.544022953846154 ], [ 29.762727413793105, -1.544022953846154 ], [ 29.762727413793105, -1.543484 ], [ 29.762457931034483, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14049, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.543484 ], [ 29.762727413793105, -1.544022953846154 ], [ 29.762996896551723, -1.544022953846154 ], [ 29.762996896551723, -1.543484 ], [ 29.762727413793105, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14050, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.543484 ], [ 29.763805344827588, -1.544022953846154 ], [ 29.764074827586207, -1.544022953846154 ], [ 29.764074827586207, -1.543484 ], [ 29.763805344827588, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14051, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.543484 ], [ 29.764074827586207, -1.544022953846154 ], [ 29.764344310344828, -1.544022953846154 ], [ 29.764344310344828, -1.543484 ], [ 29.764074827586207, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14052, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.543484 ], [ 29.764344310344828, -1.544022953846154 ], [ 29.76461379310345, -1.544022953846154 ], [ 29.76461379310345, -1.543484 ], [ 29.764344310344828, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14053, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.543484 ], [ 29.76461379310345, -1.544022953846154 ], [ 29.764883275862069, -1.544022953846154 ], [ 29.764883275862069, -1.543484 ], [ 29.76461379310345, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14054, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.543484 ], [ 29.764883275862069, -1.544022953846154 ], [ 29.76515275862069, -1.544022953846154 ], [ 29.76515275862069, -1.543484 ], [ 29.764883275862069, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14055, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.543484 ], [ 29.76515275862069, -1.544022953846154 ], [ 29.765422241379312, -1.544022953846154 ], [ 29.765422241379312, -1.543484 ], [ 29.76515275862069, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14056, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.543484 ], [ 29.765422241379312, -1.544022953846154 ], [ 29.765961206896552, -1.544022953846154 ], [ 29.765961206896552, -1.543484 ], [ 29.765422241379312, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14057, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.543484 ], [ 29.765961206896552, -1.544022953846154 ], [ 29.766230689655174, -1.544022953846154 ], [ 29.766230689655174, -1.543484 ], [ 29.765961206896552, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14058, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.543484 ], [ 29.766230689655174, -1.544022953846154 ], [ 29.766500172413792, -1.544022953846154 ], [ 29.766500172413792, -1.543484 ], [ 29.766230689655174, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14059, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.543484 ], [ 29.766500172413792, -1.544022953846154 ], [ 29.766769655172414, -1.544022953846154 ], [ 29.766769655172414, -1.543484 ], [ 29.766500172413792, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14060, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.543484 ], [ 29.766769655172414, -1.544022953846154 ], [ 29.767039137931036, -1.544022953846154 ], [ 29.767039137931036, -1.543484 ], [ 29.766769655172414, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14061, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.543484 ], [ 29.767039137931036, -1.544022953846154 ], [ 29.767308620689654, -1.544022953846154 ], [ 29.767308620689654, -1.543484 ], [ 29.767039137931036, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14062, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.543484 ], [ 29.767308620689654, -1.544022953846154 ], [ 29.767578103448276, -1.544022953846154 ], [ 29.767578103448276, -1.543484 ], [ 29.767308620689654, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14063, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.543484 ], [ 29.767578103448276, -1.544022953846154 ], [ 29.767847586206898, -1.544022953846154 ], [ 29.767847586206898, -1.543484 ], [ 29.767578103448276, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14064, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.543484 ], [ 29.767847586206898, -1.544022953846154 ], [ 29.768117068965516, -1.544022953846154 ], [ 29.768117068965516, -1.543484 ], [ 29.767847586206898, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14065, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.544022953846154 ], [ 29.753565, -1.544292430769231 ], [ 29.75383448275862, -1.544292430769231 ], [ 29.75383448275862, -1.544022953846154 ], [ 29.753565, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14066, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.544022953846154 ], [ 29.75383448275862, -1.544292430769231 ], [ 29.755720862068966, -1.544292430769231 ], [ 29.755720862068966, -1.544022953846154 ], [ 29.75383448275862, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14067, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.544022953846154 ], [ 29.755720862068966, -1.544292430769231 ], [ 29.755990344827588, -1.544292430769231 ], [ 29.755990344827588, -1.544022953846154 ], [ 29.755720862068966, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14068, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.544022953846154 ], [ 29.755990344827588, -1.544292430769231 ], [ 29.756798793103449, -1.544292430769231 ], [ 29.756798793103449, -1.544022953846154 ], [ 29.755990344827588, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14069, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.544022953846154 ], [ 29.756798793103449, -1.544292430769231 ], [ 29.757068275862068, -1.544292430769231 ], [ 29.757068275862068, -1.544022953846154 ], [ 29.756798793103449, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14070, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.544022953846154 ], [ 29.757068275862068, -1.544292430769231 ], [ 29.75733775862069, -1.544292430769231 ], [ 29.75733775862069, -1.544022953846154 ], [ 29.757068275862068, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14071, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.544022953846154 ], [ 29.75733775862069, -1.544292430769231 ], [ 29.757607241379311, -1.544292430769231 ], [ 29.757607241379311, -1.544022953846154 ], [ 29.75733775862069, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14072, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.544022953846154 ], [ 29.757607241379311, -1.544292430769231 ], [ 29.757876724137933, -1.544292430769231 ], [ 29.757876724137933, -1.544022953846154 ], [ 29.757607241379311, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14073, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.544022953846154 ], [ 29.757876724137933, -1.544292430769231 ], [ 29.758146206896551, -1.544292430769231 ], [ 29.758146206896551, -1.544022953846154 ], [ 29.757876724137933, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14074, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.544022953846154 ], [ 29.758146206896551, -1.544292430769231 ], [ 29.758415689655173, -1.544292430769231 ], [ 29.758415689655173, -1.544022953846154 ], [ 29.758146206896551, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14075, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.544022953846154 ], [ 29.758415689655173, -1.544292430769231 ], [ 29.758685172413795, -1.544292430769231 ], [ 29.758685172413795, -1.544022953846154 ], [ 29.758415689655173, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14076, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.544022953846154 ], [ 29.758685172413795, -1.544292430769231 ], [ 29.758954655172413, -1.544292430769231 ], [ 29.758954655172413, -1.544022953846154 ], [ 29.758685172413795, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14077, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.544022953846154 ], [ 29.758954655172413, -1.544292430769231 ], [ 29.759224137931035, -1.544292430769231 ], [ 29.759224137931035, -1.544022953846154 ], [ 29.758954655172413, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14078, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.544022953846154 ], [ 29.759224137931035, -1.544292430769231 ], [ 29.759493620689657, -1.544292430769231 ], [ 29.759493620689657, -1.544022953846154 ], [ 29.759224137931035, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14079, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.544022953846154 ], [ 29.759493620689657, -1.544292430769231 ], [ 29.759763103448275, -1.544292430769231 ], [ 29.759763103448275, -1.544022953846154 ], [ 29.759493620689657, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14080, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.544022953846154 ], [ 29.759763103448275, -1.544292430769231 ], [ 29.760032586206897, -1.544292430769231 ], [ 29.760032586206897, -1.544022953846154 ], [ 29.759763103448275, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14081, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.544022953846154 ], [ 29.760032586206897, -1.544292430769231 ], [ 29.760302068965519, -1.544292430769231 ], [ 29.760302068965519, -1.544022953846154 ], [ 29.760032586206897, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14082, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.544022953846154 ], [ 29.760302068965519, -1.544292430769231 ], [ 29.760571551724137, -1.544292430769231 ], [ 29.760571551724137, -1.544022953846154 ], [ 29.760302068965519, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14083, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.544022953846154 ], [ 29.760571551724137, -1.544292430769231 ], [ 29.760841034482759, -1.544292430769231 ], [ 29.760841034482759, -1.544022953846154 ], [ 29.760571551724137, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14084, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.544022953846154 ], [ 29.760841034482759, -1.544292430769231 ], [ 29.761110517241381, -1.544292430769231 ], [ 29.761110517241381, -1.544022953846154 ], [ 29.760841034482759, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14085, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.544022953846154 ], [ 29.761110517241381, -1.544292430769231 ], [ 29.76138, -1.544292430769231 ], [ 29.76138, -1.544022953846154 ], [ 29.761110517241381, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14086, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.544022953846154 ], [ 29.76138, -1.544292430769231 ], [ 29.761649482758621, -1.544292430769231 ], [ 29.761649482758621, -1.544022953846154 ], [ 29.76138, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14087, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.544022953846154 ], [ 29.761649482758621, -1.544292430769231 ], [ 29.761918965517243, -1.544292430769231 ], [ 29.761918965517243, -1.544022953846154 ], [ 29.761649482758621, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14088, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.544022953846154 ], [ 29.761918965517243, -1.544292430769231 ], [ 29.762188448275861, -1.544292430769231 ], [ 29.762188448275861, -1.544022953846154 ], [ 29.761918965517243, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14089, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.544022953846154 ], [ 29.762188448275861, -1.544292430769231 ], [ 29.762457931034483, -1.544292430769231 ], [ 29.762457931034483, -1.544022953846154 ], [ 29.762188448275861, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14090, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.544022953846154 ], [ 29.762457931034483, -1.544292430769231 ], [ 29.762727413793105, -1.544292430769231 ], [ 29.762727413793105, -1.544022953846154 ], [ 29.762457931034483, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14091, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.544022953846154 ], [ 29.762727413793105, -1.544292430769231 ], [ 29.762996896551723, -1.544292430769231 ], [ 29.762996896551723, -1.544022953846154 ], [ 29.762727413793105, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14092, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.544022953846154 ], [ 29.762996896551723, -1.544292430769231 ], [ 29.763266379310345, -1.544292430769231 ], [ 29.763266379310345, -1.544022953846154 ], [ 29.762996896551723, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14093, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.544022953846154 ], [ 29.763535862068967, -1.544292430769231 ], [ 29.763805344827588, -1.544292430769231 ], [ 29.763805344827588, -1.544022953846154 ], [ 29.763535862068967, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14094, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.544022953846154 ], [ 29.763805344827588, -1.544292430769231 ], [ 29.764074827586207, -1.544292430769231 ], [ 29.764074827586207, -1.544022953846154 ], [ 29.763805344827588, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14095, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.544022953846154 ], [ 29.764074827586207, -1.544292430769231 ], [ 29.764344310344828, -1.544292430769231 ], [ 29.764344310344828, -1.544022953846154 ], [ 29.764074827586207, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14096, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.544022953846154 ], [ 29.764344310344828, -1.544292430769231 ], [ 29.76461379310345, -1.544292430769231 ], [ 29.76461379310345, -1.544022953846154 ], [ 29.764344310344828, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14097, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.544022953846154 ], [ 29.76461379310345, -1.544292430769231 ], [ 29.764883275862069, -1.544292430769231 ], [ 29.764883275862069, -1.544022953846154 ], [ 29.76461379310345, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14098, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.544022953846154 ], [ 29.764883275862069, -1.544292430769231 ], [ 29.76515275862069, -1.544292430769231 ], [ 29.76515275862069, -1.544022953846154 ], [ 29.764883275862069, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14099, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.544022953846154 ], [ 29.76515275862069, -1.544292430769231 ], [ 29.765422241379312, -1.544292430769231 ], [ 29.765422241379312, -1.544022953846154 ], [ 29.76515275862069, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14100, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.544022953846154 ], [ 29.765422241379312, -1.544292430769231 ], [ 29.765961206896552, -1.544292430769231 ], [ 29.765961206896552, -1.544022953846154 ], [ 29.765422241379312, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14101, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.544022953846154 ], [ 29.765961206896552, -1.544292430769231 ], [ 29.766230689655174, -1.544292430769231 ], [ 29.766230689655174, -1.544022953846154 ], [ 29.765961206896552, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14102, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.544022953846154 ], [ 29.766230689655174, -1.544292430769231 ], [ 29.766500172413792, -1.544292430769231 ], [ 29.766500172413792, -1.544022953846154 ], [ 29.766230689655174, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14103, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.544022953846154 ], [ 29.766500172413792, -1.544292430769231 ], [ 29.766769655172414, -1.544292430769231 ], [ 29.766769655172414, -1.544022953846154 ], [ 29.766500172413792, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14104, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.544022953846154 ], [ 29.766769655172414, -1.544292430769231 ], [ 29.767039137931036, -1.544292430769231 ], [ 29.767039137931036, -1.544022953846154 ], [ 29.766769655172414, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14105, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.544022953846154 ], [ 29.767039137931036, -1.544292430769231 ], [ 29.767308620689654, -1.544292430769231 ], [ 29.767308620689654, -1.544022953846154 ], [ 29.767039137931036, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14106, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.544022953846154 ], [ 29.767308620689654, -1.544292430769231 ], [ 29.767578103448276, -1.544292430769231 ], [ 29.767578103448276, -1.544022953846154 ], [ 29.767308620689654, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14107, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.544022953846154 ], [ 29.767578103448276, -1.544292430769231 ], [ 29.767847586206898, -1.544292430769231 ], [ 29.767847586206898, -1.544022953846154 ], [ 29.767578103448276, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14108, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.544022953846154 ], [ 29.767847586206898, -1.544292430769231 ], [ 29.768117068965516, -1.544292430769231 ], [ 29.768117068965516, -1.544022953846154 ], [ 29.767847586206898, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14109, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.544022953846154 ], [ 29.768117068965516, -1.544292430769231 ], [ 29.768386551724138, -1.544292430769231 ], [ 29.768386551724138, -1.544022953846154 ], [ 29.768117068965516, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14110, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.544022953846154 ], [ 29.768386551724138, -1.544292430769231 ], [ 29.76865603448276, -1.544292430769231 ], [ 29.76865603448276, -1.544022953846154 ], [ 29.768386551724138, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14111, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.544022953846154 ], [ 29.76865603448276, -1.544292430769231 ], [ 29.768925517241382, -1.544292430769231 ], [ 29.768925517241382, -1.544022953846154 ], [ 29.76865603448276, -1.544022953846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14112, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.544292430769231 ], [ 29.753565, -1.544561907692308 ], [ 29.75383448275862, -1.544561907692308 ], [ 29.75383448275862, -1.544292430769231 ], [ 29.753565, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14113, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.544292430769231 ], [ 29.75383448275862, -1.544831384615384 ], [ 29.754103965517242, -1.544831384615384 ], [ 29.754103965517242, -1.544292430769231 ], [ 29.75383448275862, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14114, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.544292430769231 ], [ 29.754103965517242, -1.544561907692308 ], [ 29.754373448275864, -1.544561907692308 ], [ 29.754373448275864, -1.544292430769231 ], [ 29.754103965517242, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14115, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.544292430769231 ], [ 29.754373448275864, -1.544831384615384 ], [ 29.754642931034482, -1.544831384615384 ], [ 29.754642931034482, -1.544561907692308 ], [ 29.755720862068966, -1.544561907692308 ], [ 29.755720862068966, -1.544292430769231 ], [ 29.754373448275864, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14116, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.544292430769231 ], [ 29.755720862068966, -1.544561907692308 ], [ 29.755990344827588, -1.544561907692308 ], [ 29.755990344827588, -1.544292430769231 ], [ 29.755720862068966, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14117, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.544292430769231 ], [ 29.755990344827588, -1.544561907692308 ], [ 29.756798793103449, -1.544561907692308 ], [ 29.756798793103449, -1.544292430769231 ], [ 29.755990344827588, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14118, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.544292430769231 ], [ 29.756798793103449, -1.544561907692308 ], [ 29.757068275862068, -1.544561907692308 ], [ 29.757068275862068, -1.544292430769231 ], [ 29.756798793103449, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14119, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.544292430769231 ], [ 29.757068275862068, -1.544561907692308 ], [ 29.75733775862069, -1.544561907692308 ], [ 29.75733775862069, -1.544292430769231 ], [ 29.757068275862068, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14120, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.544292430769231 ], [ 29.75733775862069, -1.544561907692308 ], [ 29.757607241379311, -1.544561907692308 ], [ 29.757607241379311, -1.544292430769231 ], [ 29.75733775862069, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14121, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.544292430769231 ], [ 29.757607241379311, -1.544561907692308 ], [ 29.757876724137933, -1.544561907692308 ], [ 29.757876724137933, -1.544292430769231 ], [ 29.757607241379311, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14122, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.544292430769231 ], [ 29.757876724137933, -1.544561907692308 ], [ 29.758146206896551, -1.544561907692308 ], [ 29.758146206896551, -1.544292430769231 ], [ 29.757876724137933, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14123, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.544292430769231 ], [ 29.758146206896551, -1.544561907692308 ], [ 29.758415689655173, -1.544561907692308 ], [ 29.758415689655173, -1.544292430769231 ], [ 29.758146206896551, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14124, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.544292430769231 ], [ 29.758415689655173, -1.544561907692308 ], [ 29.758685172413795, -1.544561907692308 ], [ 29.758685172413795, -1.544292430769231 ], [ 29.758415689655173, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14125, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.544292430769231 ], [ 29.758685172413795, -1.544561907692308 ], [ 29.758954655172413, -1.544561907692308 ], [ 29.758954655172413, -1.544292430769231 ], [ 29.758685172413795, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14126, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.544292430769231 ], [ 29.758954655172413, -1.544561907692308 ], [ 29.759224137931035, -1.544561907692308 ], [ 29.759224137931035, -1.544292430769231 ], [ 29.758954655172413, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14127, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.544292430769231 ], [ 29.759224137931035, -1.544561907692308 ], [ 29.759493620689657, -1.544561907692308 ], [ 29.759493620689657, -1.544292430769231 ], [ 29.759224137931035, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14128, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.544292430769231 ], [ 29.759493620689657, -1.544561907692308 ], [ 29.759763103448275, -1.544561907692308 ], [ 29.759763103448275, -1.544292430769231 ], [ 29.759493620689657, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14129, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.544292430769231 ], [ 29.759763103448275, -1.544561907692308 ], [ 29.760032586206897, -1.544561907692308 ], [ 29.760032586206897, -1.544292430769231 ], [ 29.759763103448275, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14130, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.544292430769231 ], [ 29.760032586206897, -1.544561907692308 ], [ 29.760302068965519, -1.544561907692308 ], [ 29.760302068965519, -1.544292430769231 ], [ 29.760032586206897, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14131, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.544292430769231 ], [ 29.760302068965519, -1.544561907692308 ], [ 29.760571551724137, -1.544561907692308 ], [ 29.760571551724137, -1.544292430769231 ], [ 29.760302068965519, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14132, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.544292430769231 ], [ 29.760571551724137, -1.544561907692308 ], [ 29.760841034482759, -1.544561907692308 ], [ 29.760841034482759, -1.544292430769231 ], [ 29.760571551724137, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14133, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.544292430769231 ], [ 29.760841034482759, -1.544561907692308 ], [ 29.761110517241381, -1.544561907692308 ], [ 29.761110517241381, -1.544292430769231 ], [ 29.760841034482759, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14134, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.544292430769231 ], [ 29.761110517241381, -1.544561907692308 ], [ 29.76138, -1.544561907692308 ], [ 29.76138, -1.544292430769231 ], [ 29.761110517241381, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14135, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.544292430769231 ], [ 29.76138, -1.544561907692308 ], [ 29.761649482758621, -1.544561907692308 ], [ 29.761649482758621, -1.544292430769231 ], [ 29.76138, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14136, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.544292430769231 ], [ 29.761649482758621, -1.544561907692308 ], [ 29.761918965517243, -1.544561907692308 ], [ 29.761918965517243, -1.544292430769231 ], [ 29.761649482758621, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14137, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.544292430769231 ], [ 29.761918965517243, -1.544561907692308 ], [ 29.762188448275861, -1.544561907692308 ], [ 29.762188448275861, -1.544292430769231 ], [ 29.761918965517243, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14138, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.544292430769231 ], [ 29.762188448275861, -1.544561907692308 ], [ 29.762457931034483, -1.544561907692308 ], [ 29.762457931034483, -1.544292430769231 ], [ 29.762188448275861, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14139, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.544292430769231 ], [ 29.762457931034483, -1.544561907692308 ], [ 29.762727413793105, -1.544561907692308 ], [ 29.762727413793105, -1.544292430769231 ], [ 29.762457931034483, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14140, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.544292430769231 ], [ 29.762727413793105, -1.544561907692308 ], [ 29.762996896551723, -1.544561907692308 ], [ 29.762996896551723, -1.544292430769231 ], [ 29.762727413793105, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14141, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.544292430769231 ], [ 29.762996896551723, -1.544561907692308 ], [ 29.763266379310345, -1.544561907692308 ], [ 29.763266379310345, -1.544292430769231 ], [ 29.762996896551723, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14142, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.544292430769231 ], [ 29.763535862068967, -1.544561907692308 ], [ 29.763805344827588, -1.544561907692308 ], [ 29.763805344827588, -1.544292430769231 ], [ 29.763535862068967, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14143, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.544292430769231 ], [ 29.763805344827588, -1.544561907692308 ], [ 29.764074827586207, -1.544561907692308 ], [ 29.764074827586207, -1.544292430769231 ], [ 29.763805344827588, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14144, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.544292430769231 ], [ 29.764074827586207, -1.544561907692308 ], [ 29.764344310344828, -1.544561907692308 ], [ 29.764344310344828, -1.544292430769231 ], [ 29.764074827586207, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14145, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.544292430769231 ], [ 29.764344310344828, -1.544561907692308 ], [ 29.76461379310345, -1.544561907692308 ], [ 29.76461379310345, -1.544292430769231 ], [ 29.764344310344828, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14146, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.544292430769231 ], [ 29.76461379310345, -1.544561907692308 ], [ 29.764883275862069, -1.544561907692308 ], [ 29.764883275862069, -1.544292430769231 ], [ 29.76461379310345, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14147, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.544292430769231 ], [ 29.764883275862069, -1.544561907692308 ], [ 29.76515275862069, -1.544561907692308 ], [ 29.76515275862069, -1.544292430769231 ], [ 29.764883275862069, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14148, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.544292430769231 ], [ 29.76515275862069, -1.544561907692308 ], [ 29.765422241379312, -1.544561907692308 ], [ 29.765422241379312, -1.544292430769231 ], [ 29.76515275862069, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14149, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.544292430769231 ], [ 29.765422241379312, -1.544561907692308 ], [ 29.765961206896552, -1.544561907692308 ], [ 29.765961206896552, -1.544292430769231 ], [ 29.765422241379312, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14150, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.544292430769231 ], [ 29.765961206896552, -1.544561907692308 ], [ 29.766230689655174, -1.544561907692308 ], [ 29.766230689655174, -1.544292430769231 ], [ 29.765961206896552, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14151, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.544292430769231 ], [ 29.766230689655174, -1.544561907692308 ], [ 29.766500172413792, -1.544561907692308 ], [ 29.766500172413792, -1.544292430769231 ], [ 29.766230689655174, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14152, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.544292430769231 ], [ 29.766500172413792, -1.544561907692308 ], [ 29.766769655172414, -1.544561907692308 ], [ 29.766769655172414, -1.544292430769231 ], [ 29.766500172413792, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14153, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.544292430769231 ], [ 29.766769655172414, -1.544561907692308 ], [ 29.767039137931036, -1.544561907692308 ], [ 29.767039137931036, -1.544292430769231 ], [ 29.766769655172414, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14154, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.544292430769231 ], [ 29.767039137931036, -1.544561907692308 ], [ 29.767308620689654, -1.544561907692308 ], [ 29.767308620689654, -1.544292430769231 ], [ 29.767039137931036, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14155, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.544292430769231 ], [ 29.767308620689654, -1.544561907692308 ], [ 29.767578103448276, -1.544561907692308 ], [ 29.767578103448276, -1.544292430769231 ], [ 29.767308620689654, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14156, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.544292430769231 ], [ 29.767578103448276, -1.544561907692308 ], [ 29.767847586206898, -1.544561907692308 ], [ 29.767847586206898, -1.544292430769231 ], [ 29.767578103448276, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14157, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.544292430769231 ], [ 29.767847586206898, -1.544561907692308 ], [ 29.768117068965516, -1.544561907692308 ], [ 29.768117068965516, -1.544292430769231 ], [ 29.767847586206898, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14158, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.544292430769231 ], [ 29.768117068965516, -1.544561907692308 ], [ 29.768386551724138, -1.544561907692308 ], [ 29.768386551724138, -1.544292430769231 ], [ 29.768117068965516, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14159, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.544292430769231 ], [ 29.768386551724138, -1.544561907692308 ], [ 29.76865603448276, -1.544561907692308 ], [ 29.76865603448276, -1.544292430769231 ], [ 29.768386551724138, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14160, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.544292430769231 ], [ 29.76865603448276, -1.544561907692308 ], [ 29.768925517241382, -1.544561907692308 ], [ 29.768925517241382, -1.544292430769231 ], [ 29.76865603448276, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14161, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.544292430769231 ], [ 29.768925517241382, -1.544561907692308 ], [ 29.769195, -1.544561907692308 ], [ 29.769195, -1.544292430769231 ], [ 29.768925517241382, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14162, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.544292430769231 ], [ 29.769195, -1.544561907692308 ], [ 29.769464482758622, -1.544561907692308 ], [ 29.769464482758622, -1.544292430769231 ], [ 29.769195, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14163, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.544292430769231 ], [ 29.769464482758622, -1.544831384615384 ], [ 29.769733965517243, -1.544831384615384 ], [ 29.769733965517243, -1.544292430769231 ], [ 29.769464482758622, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14164, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.544292430769231 ], [ 29.769733965517243, -1.544561907692308 ], [ 29.770003448275862, -1.544561907692308 ], [ 29.770003448275862, -1.544292430769231 ], [ 29.769733965517243, -1.544292430769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14165, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.544561907692308 ], [ 29.753026034482758, -1.544831384615384 ], [ 29.75329551724138, -1.544831384615384 ], [ 29.75329551724138, -1.544561907692308 ], [ 29.753026034482758, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14166, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.544561907692308 ], [ 29.75329551724138, -1.545100861538462 ], [ 29.753565, -1.545100861538462 ], [ 29.753565, -1.544561907692308 ], [ 29.75329551724138, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14167, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.544561907692308 ], [ 29.753565, -1.545100861538462 ], [ 29.75383448275862, -1.545100861538462 ], [ 29.75383448275862, -1.544561907692308 ], [ 29.753565, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14168, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.544561907692308 ], [ 29.754103965517242, -1.544831384615384 ], [ 29.754373448275864, -1.544831384615384 ], [ 29.754373448275864, -1.544561907692308 ], [ 29.754103965517242, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14169, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.544561907692308 ], [ 29.754642931034482, -1.544831384615384 ], [ 29.755181896551726, -1.544831384615384 ], [ 29.755181896551726, -1.545100861538462 ], [ 29.755451379310344, -1.545100861538462 ], [ 29.755451379310344, -1.544831384615384 ], [ 29.755720862068966, -1.544831384615384 ], [ 29.755720862068966, -1.544561907692308 ], [ 29.754642931034482, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14170, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.544561907692308 ], [ 29.755720862068966, -1.544831384615384 ], [ 29.755990344827588, -1.544831384615384 ], [ 29.755990344827588, -1.544561907692308 ], [ 29.755720862068966, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14171, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.544561907692308 ], [ 29.755990344827588, -1.544831384615384 ], [ 29.756259827586206, -1.544831384615384 ], [ 29.756259827586206, -1.544561907692308 ], [ 29.755990344827588, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14172, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.544561907692308 ], [ 29.756259827586206, -1.544831384615384 ], [ 29.757068275862068, -1.544831384615384 ], [ 29.757068275862068, -1.544561907692308 ], [ 29.756259827586206, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14173, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.544561907692308 ], [ 29.757068275862068, -1.544831384615384 ], [ 29.75733775862069, -1.544831384615384 ], [ 29.75733775862069, -1.544561907692308 ], [ 29.757068275862068, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14174, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.544561907692308 ], [ 29.75733775862069, -1.544831384615384 ], [ 29.757607241379311, -1.544831384615384 ], [ 29.757607241379311, -1.544561907692308 ], [ 29.75733775862069, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14175, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.544561907692308 ], [ 29.757607241379311, -1.544831384615384 ], [ 29.757876724137933, -1.544831384615384 ], [ 29.757876724137933, -1.544561907692308 ], [ 29.757607241379311, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14176, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.544561907692308 ], [ 29.757876724137933, -1.544831384615384 ], [ 29.758146206896551, -1.544831384615384 ], [ 29.758146206896551, -1.544561907692308 ], [ 29.757876724137933, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14177, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.544561907692308 ], [ 29.758146206896551, -1.544831384615384 ], [ 29.758415689655173, -1.544831384615384 ], [ 29.758415689655173, -1.544561907692308 ], [ 29.758146206896551, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14178, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.544561907692308 ], [ 29.758415689655173, -1.544831384615384 ], [ 29.758685172413795, -1.544831384615384 ], [ 29.758685172413795, -1.544561907692308 ], [ 29.758415689655173, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14179, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.544561907692308 ], [ 29.758685172413795, -1.544831384615384 ], [ 29.758954655172413, -1.544831384615384 ], [ 29.758954655172413, -1.544561907692308 ], [ 29.758685172413795, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14180, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.544561907692308 ], [ 29.758954655172413, -1.544831384615384 ], [ 29.759224137931035, -1.544831384615384 ], [ 29.759224137931035, -1.544561907692308 ], [ 29.758954655172413, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14181, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.544561907692308 ], [ 29.759224137931035, -1.544831384615384 ], [ 29.759493620689657, -1.544831384615384 ], [ 29.759493620689657, -1.544561907692308 ], [ 29.759224137931035, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14182, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.544561907692308 ], [ 29.759493620689657, -1.544831384615384 ], [ 29.759763103448275, -1.544831384615384 ], [ 29.759763103448275, -1.544561907692308 ], [ 29.759493620689657, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14183, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.544561907692308 ], [ 29.759763103448275, -1.544831384615384 ], [ 29.760032586206897, -1.544831384615384 ], [ 29.760032586206897, -1.544561907692308 ], [ 29.759763103448275, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14184, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.544561907692308 ], [ 29.760032586206897, -1.544831384615384 ], [ 29.760302068965519, -1.544831384615384 ], [ 29.760302068965519, -1.544561907692308 ], [ 29.760032586206897, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14185, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.544561907692308 ], [ 29.760302068965519, -1.544831384615384 ], [ 29.760571551724137, -1.544831384615384 ], [ 29.760571551724137, -1.544561907692308 ], [ 29.760302068965519, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14186, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.544561907692308 ], [ 29.760571551724137, -1.544831384615384 ], [ 29.760841034482759, -1.544831384615384 ], [ 29.760841034482759, -1.544561907692308 ], [ 29.760571551724137, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14187, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.544561907692308 ], [ 29.760841034482759, -1.544831384615384 ], [ 29.761110517241381, -1.544831384615384 ], [ 29.761110517241381, -1.544561907692308 ], [ 29.760841034482759, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14188, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.544561907692308 ], [ 29.761110517241381, -1.544831384615384 ], [ 29.76138, -1.544831384615384 ], [ 29.76138, -1.544561907692308 ], [ 29.761110517241381, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14189, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.544561907692308 ], [ 29.76138, -1.544831384615384 ], [ 29.761649482758621, -1.544831384615384 ], [ 29.761649482758621, -1.544561907692308 ], [ 29.76138, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14190, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.544561907692308 ], [ 29.761649482758621, -1.544831384615384 ], [ 29.761918965517243, -1.544831384615384 ], [ 29.761918965517243, -1.544561907692308 ], [ 29.761649482758621, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14191, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.544561907692308 ], [ 29.761918965517243, -1.544831384615384 ], [ 29.762188448275861, -1.544831384615384 ], [ 29.762188448275861, -1.544561907692308 ], [ 29.761918965517243, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14192, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.544561907692308 ], [ 29.762188448275861, -1.544831384615384 ], [ 29.762457931034483, -1.544831384615384 ], [ 29.762457931034483, -1.544561907692308 ], [ 29.762188448275861, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14193, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.544561907692308 ], [ 29.762457931034483, -1.544831384615384 ], [ 29.762727413793105, -1.544831384615384 ], [ 29.762727413793105, -1.544561907692308 ], [ 29.762457931034483, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14194, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.544561907692308 ], [ 29.762727413793105, -1.544831384615384 ], [ 29.762996896551723, -1.544831384615384 ], [ 29.762996896551723, -1.544561907692308 ], [ 29.762727413793105, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14195, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.543484 ], [ 29.763805344827588, -1.543484 ], [ 29.763805344827588, -1.544022953846154 ], [ 29.763535862068967, -1.544022953846154 ], [ 29.763535862068967, -1.544831384615384 ], [ 29.762996896551723, -1.544831384615384 ], [ 29.762996896551723, -1.544561907692308 ], [ 29.763266379310345, -1.544561907692308 ], [ 29.763266379310345, -1.543484 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14196, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.544561907692308 ], [ 29.763535862068967, -1.545100861538462 ], [ 29.763805344827588, -1.545100861538462 ], [ 29.763805344827588, -1.544561907692308 ], [ 29.763535862068967, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14197, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.544561907692308 ], [ 29.763805344827588, -1.544831384615384 ], [ 29.764074827586207, -1.544831384615384 ], [ 29.764074827586207, -1.544561907692308 ], [ 29.763805344827588, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14198, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.544561907692308 ], [ 29.764074827586207, -1.544831384615384 ], [ 29.764344310344828, -1.544831384615384 ], [ 29.764344310344828, -1.544561907692308 ], [ 29.764074827586207, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14199, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.544561907692308 ], [ 29.764344310344828, -1.544831384615384 ], [ 29.76461379310345, -1.544831384615384 ], [ 29.76461379310345, -1.544561907692308 ], [ 29.764344310344828, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14200, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.544561907692308 ], [ 29.76461379310345, -1.544831384615384 ], [ 29.764883275862069, -1.544831384615384 ], [ 29.764883275862069, -1.544561907692308 ], [ 29.76461379310345, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14201, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.544561907692308 ], [ 29.764883275862069, -1.544831384615384 ], [ 29.76515275862069, -1.544831384615384 ], [ 29.76515275862069, -1.544561907692308 ], [ 29.764883275862069, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14202, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.544561907692308 ], [ 29.76515275862069, -1.545100861538462 ], [ 29.765422241379312, -1.545100861538462 ], [ 29.765422241379312, -1.544561907692308 ], [ 29.76515275862069, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14203, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.544561907692308 ], [ 29.765422241379312, -1.544831384615384 ], [ 29.765961206896552, -1.544831384615384 ], [ 29.765961206896552, -1.544561907692308 ], [ 29.765422241379312, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14204, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.544561907692308 ], [ 29.765961206896552, -1.544831384615384 ], [ 29.766230689655174, -1.544831384615384 ], [ 29.766230689655174, -1.544561907692308 ], [ 29.765961206896552, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14205, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.544561907692308 ], [ 29.766230689655174, -1.544831384615384 ], [ 29.766500172413792, -1.544831384615384 ], [ 29.766500172413792, -1.544561907692308 ], [ 29.766230689655174, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14206, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.544561907692308 ], [ 29.766500172413792, -1.544831384615384 ], [ 29.766769655172414, -1.544831384615384 ], [ 29.766769655172414, -1.544561907692308 ], [ 29.766500172413792, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14207, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.544561907692308 ], [ 29.766769655172414, -1.544831384615384 ], [ 29.767039137931036, -1.544831384615384 ], [ 29.767039137931036, -1.544561907692308 ], [ 29.766769655172414, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14208, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.544561907692308 ], [ 29.767039137931036, -1.544831384615384 ], [ 29.767308620689654, -1.544831384615384 ], [ 29.767308620689654, -1.544561907692308 ], [ 29.767039137931036, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14209, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.544561907692308 ], [ 29.767308620689654, -1.544831384615384 ], [ 29.767578103448276, -1.544831384615384 ], [ 29.767578103448276, -1.544561907692308 ], [ 29.767308620689654, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14210, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.544561907692308 ], [ 29.767578103448276, -1.544831384615384 ], [ 29.767847586206898, -1.544831384615384 ], [ 29.767847586206898, -1.544561907692308 ], [ 29.767578103448276, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14211, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.544561907692308 ], [ 29.767847586206898, -1.544831384615384 ], [ 29.768117068965516, -1.544831384615384 ], [ 29.768117068965516, -1.544561907692308 ], [ 29.767847586206898, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14212, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.544561907692308 ], [ 29.768117068965516, -1.544831384615384 ], [ 29.768386551724138, -1.544831384615384 ], [ 29.768386551724138, -1.544561907692308 ], [ 29.768117068965516, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14213, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.544561907692308 ], [ 29.768386551724138, -1.544831384615384 ], [ 29.76865603448276, -1.544831384615384 ], [ 29.76865603448276, -1.544561907692308 ], [ 29.768386551724138, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14214, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.544561907692308 ], [ 29.76865603448276, -1.544831384615384 ], [ 29.768925517241382, -1.544831384615384 ], [ 29.768925517241382, -1.544561907692308 ], [ 29.76865603448276, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14215, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.544561907692308 ], [ 29.768925517241382, -1.544831384615384 ], [ 29.769195, -1.544831384615384 ], [ 29.769195, -1.544561907692308 ], [ 29.768925517241382, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14216, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.544561907692308 ], [ 29.769195, -1.544831384615384 ], [ 29.769464482758622, -1.544831384615384 ], [ 29.769464482758622, -1.544561907692308 ], [ 29.769195, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14217, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.544561907692308 ], [ 29.769733965517243, -1.545100861538462 ], [ 29.770003448275862, -1.545100861538462 ], [ 29.770003448275862, -1.544561907692308 ], [ 29.769733965517243, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14218, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.544561907692308 ], [ 29.770003448275862, -1.545100861538462 ], [ 29.770272931034484, -1.545100861538462 ], [ 29.770272931034484, -1.544561907692308 ], [ 29.770003448275862, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14219, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.544561907692308 ], [ 29.770272931034484, -1.544831384615384 ], [ 29.770542413793105, -1.544831384615384 ], [ 29.770542413793105, -1.544561907692308 ], [ 29.770272931034484, -1.544561907692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14220, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753026034482758, -1.544831384615384 ], [ 29.753026034482758, -1.545370338461538 ], [ 29.75329551724138, -1.545370338461538 ], [ 29.75329551724138, -1.544831384615384 ], [ 29.753026034482758, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14221, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.544831384615384 ], [ 29.75383448275862, -1.545100861538462 ], [ 29.754103965517242, -1.545100861538462 ], [ 29.754103965517242, -1.544831384615384 ], [ 29.75383448275862, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14222, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.544831384615384 ], [ 29.754103965517242, -1.545370338461538 ], [ 29.754642931034482, -1.545370338461538 ], [ 29.754642931034482, -1.545100861538462 ], [ 29.754373448275864, -1.545100861538462 ], [ 29.754373448275864, -1.544831384615384 ], [ 29.754103965517242, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14223, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.544831384615384 ], [ 29.754373448275864, -1.545100861538462 ], [ 29.754642931034482, -1.545100861538462 ], [ 29.754642931034482, -1.544831384615384 ], [ 29.754373448275864, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14224, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.544831384615384 ], [ 29.754642931034482, -1.545100861538462 ], [ 29.754912413793104, -1.545100861538462 ], [ 29.754912413793104, -1.544831384615384 ], [ 29.754642931034482, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14225, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.544831384615384 ], [ 29.754912413793104, -1.545100861538462 ], [ 29.755181896551726, -1.545100861538462 ], [ 29.755181896551726, -1.544831384615384 ], [ 29.754912413793104, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14226, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.544831384615384 ], [ 29.755451379310344, -1.545100861538462 ], [ 29.755720862068966, -1.545100861538462 ], [ 29.755720862068966, -1.544831384615384 ], [ 29.755451379310344, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14227, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.544831384615384 ], [ 29.755720862068966, -1.545100861538462 ], [ 29.755990344827588, -1.545100861538462 ], [ 29.755990344827588, -1.544831384615384 ], [ 29.755720862068966, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14228, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.544831384615384 ], [ 29.755990344827588, -1.545370338461538 ], [ 29.756259827586206, -1.545370338461538 ], [ 29.756259827586206, -1.544831384615384 ], [ 29.755990344827588, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14229, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.544831384615384 ], [ 29.756259827586206, -1.545370338461538 ], [ 29.756798793103449, -1.545370338461538 ], [ 29.756798793103449, -1.545100861538462 ], [ 29.757068275862068, -1.545100861538462 ], [ 29.757068275862068, -1.544831384615384 ], [ 29.756259827586206, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14230, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.544831384615384 ], [ 29.757068275862068, -1.545100861538462 ], [ 29.75733775862069, -1.545100861538462 ], [ 29.75733775862069, -1.544831384615384 ], [ 29.757068275862068, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14231, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.544831384615384 ], [ 29.75733775862069, -1.545100861538462 ], [ 29.757607241379311, -1.545100861538462 ], [ 29.757607241379311, -1.544831384615384 ], [ 29.75733775862069, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14232, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.544831384615384 ], [ 29.757607241379311, -1.545100861538462 ], [ 29.757876724137933, -1.545100861538462 ], [ 29.757876724137933, -1.544831384615384 ], [ 29.757607241379311, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14233, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.544831384615384 ], [ 29.757876724137933, -1.545100861538462 ], [ 29.758146206896551, -1.545100861538462 ], [ 29.758146206896551, -1.544831384615384 ], [ 29.757876724137933, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14234, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.544831384615384 ], [ 29.758146206896551, -1.545100861538462 ], [ 29.758415689655173, -1.545100861538462 ], [ 29.758415689655173, -1.544831384615384 ], [ 29.758146206896551, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14235, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.544831384615384 ], [ 29.758415689655173, -1.545100861538462 ], [ 29.758685172413795, -1.545100861538462 ], [ 29.758685172413795, -1.544831384615384 ], [ 29.758415689655173, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14236, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.544831384615384 ], [ 29.758685172413795, -1.545100861538462 ], [ 29.758954655172413, -1.545100861538462 ], [ 29.758954655172413, -1.544831384615384 ], [ 29.758685172413795, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14237, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.544831384615384 ], [ 29.758954655172413, -1.545100861538462 ], [ 29.759224137931035, -1.545100861538462 ], [ 29.759224137931035, -1.544831384615384 ], [ 29.758954655172413, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14238, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.544831384615384 ], [ 29.759224137931035, -1.545100861538462 ], [ 29.759493620689657, -1.545100861538462 ], [ 29.759493620689657, -1.544831384615384 ], [ 29.759224137931035, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14239, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.544831384615384 ], [ 29.759493620689657, -1.545100861538462 ], [ 29.759763103448275, -1.545100861538462 ], [ 29.759763103448275, -1.544831384615384 ], [ 29.759493620689657, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14240, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.544831384615384 ], [ 29.759763103448275, -1.545100861538462 ], [ 29.760032586206897, -1.545100861538462 ], [ 29.760032586206897, -1.544831384615384 ], [ 29.759763103448275, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14241, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.544831384615384 ], [ 29.760032586206897, -1.545100861538462 ], [ 29.760302068965519, -1.545100861538462 ], [ 29.760302068965519, -1.544831384615384 ], [ 29.760032586206897, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14242, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.544831384615384 ], [ 29.760302068965519, -1.545100861538462 ], [ 29.760571551724137, -1.545100861538462 ], [ 29.760571551724137, -1.544831384615384 ], [ 29.760302068965519, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14243, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.544831384615384 ], [ 29.760571551724137, -1.545100861538462 ], [ 29.760841034482759, -1.545100861538462 ], [ 29.760841034482759, -1.544831384615384 ], [ 29.760571551724137, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14244, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.544831384615384 ], [ 29.760841034482759, -1.545100861538462 ], [ 29.761110517241381, -1.545100861538462 ], [ 29.761110517241381, -1.544831384615384 ], [ 29.760841034482759, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14245, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.544831384615384 ], [ 29.761110517241381, -1.545100861538462 ], [ 29.76138, -1.545100861538462 ], [ 29.76138, -1.544831384615384 ], [ 29.761110517241381, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14246, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.544831384615384 ], [ 29.76138, -1.545100861538462 ], [ 29.761649482758621, -1.545100861538462 ], [ 29.761649482758621, -1.544831384615384 ], [ 29.76138, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14247, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.544831384615384 ], [ 29.761649482758621, -1.545100861538462 ], [ 29.761918965517243, -1.545100861538462 ], [ 29.761918965517243, -1.544831384615384 ], [ 29.761649482758621, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14248, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.544831384615384 ], [ 29.761918965517243, -1.545100861538462 ], [ 29.762188448275861, -1.545100861538462 ], [ 29.762188448275861, -1.544831384615384 ], [ 29.761918965517243, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14249, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.544831384615384 ], [ 29.762188448275861, -1.545100861538462 ], [ 29.762457931034483, -1.545100861538462 ], [ 29.762457931034483, -1.544831384615384 ], [ 29.762188448275861, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14250, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.544831384615384 ], [ 29.762457931034483, -1.545100861538462 ], [ 29.762727413793105, -1.545100861538462 ], [ 29.762727413793105, -1.544831384615384 ], [ 29.762457931034483, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14251, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.544831384615384 ], [ 29.762727413793105, -1.545100861538462 ], [ 29.762996896551723, -1.545100861538462 ], [ 29.762996896551723, -1.544831384615384 ], [ 29.762727413793105, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14252, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.544831384615384 ], [ 29.762996896551723, -1.545370338461538 ], [ 29.763266379310345, -1.545370338461538 ], [ 29.763266379310345, -1.544831384615384 ], [ 29.762996896551723, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14253, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.544831384615384 ], [ 29.763266379310345, -1.545100861538462 ], [ 29.763535862068967, -1.545100861538462 ], [ 29.763535862068967, -1.544831384615384 ], [ 29.763266379310345, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14254, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.544831384615384 ], [ 29.763805344827588, -1.545370338461538 ], [ 29.764074827586207, -1.545370338461538 ], [ 29.764074827586207, -1.544831384615384 ], [ 29.763805344827588, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14255, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.544831384615384 ], [ 29.764074827586207, -1.545370338461538 ], [ 29.764344310344828, -1.545370338461538 ], [ 29.764344310344828, -1.544831384615384 ], [ 29.764074827586207, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14256, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.544831384615384 ], [ 29.764344310344828, -1.545100861538462 ], [ 29.76461379310345, -1.545100861538462 ], [ 29.76461379310345, -1.544831384615384 ], [ 29.764344310344828, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14257, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.544831384615384 ], [ 29.76461379310345, -1.545100861538462 ], [ 29.764883275862069, -1.545100861538462 ], [ 29.764883275862069, -1.544831384615384 ], [ 29.76461379310345, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14258, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.544831384615384 ], [ 29.764883275862069, -1.545100861538462 ], [ 29.76515275862069, -1.545100861538462 ], [ 29.76515275862069, -1.544831384615384 ], [ 29.764883275862069, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14259, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.544831384615384 ], [ 29.765422241379312, -1.545370338461538 ], [ 29.765961206896552, -1.545370338461538 ], [ 29.765961206896552, -1.544831384615384 ], [ 29.765422241379312, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14260, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.544831384615384 ], [ 29.765961206896552, -1.545100861538462 ], [ 29.766230689655174, -1.545100861538462 ], [ 29.766230689655174, -1.544831384615384 ], [ 29.765961206896552, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14261, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.544831384615384 ], [ 29.766230689655174, -1.545100861538462 ], [ 29.766500172413792, -1.545100861538462 ], [ 29.766500172413792, -1.544831384615384 ], [ 29.766230689655174, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14262, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.544831384615384 ], [ 29.766500172413792, -1.545100861538462 ], [ 29.766769655172414, -1.545100861538462 ], [ 29.766769655172414, -1.544831384615384 ], [ 29.766500172413792, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14263, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.544831384615384 ], [ 29.766769655172414, -1.545100861538462 ], [ 29.767039137931036, -1.545100861538462 ], [ 29.767039137931036, -1.544831384615384 ], [ 29.766769655172414, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14264, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.544831384615384 ], [ 29.767039137931036, -1.545100861538462 ], [ 29.767308620689654, -1.545100861538462 ], [ 29.767308620689654, -1.544831384615384 ], [ 29.767039137931036, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14265, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.544831384615384 ], [ 29.767308620689654, -1.545100861538462 ], [ 29.767578103448276, -1.545100861538462 ], [ 29.767578103448276, -1.544831384615384 ], [ 29.767308620689654, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14266, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.544831384615384 ], [ 29.767578103448276, -1.545100861538462 ], [ 29.767847586206898, -1.545100861538462 ], [ 29.767847586206898, -1.544831384615384 ], [ 29.767578103448276, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14267, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.544831384615384 ], [ 29.767847586206898, -1.545100861538462 ], [ 29.768117068965516, -1.545100861538462 ], [ 29.768117068965516, -1.544831384615384 ], [ 29.767847586206898, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14268, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.544831384615384 ], [ 29.768117068965516, -1.545100861538462 ], [ 29.768386551724138, -1.545100861538462 ], [ 29.768386551724138, -1.544831384615384 ], [ 29.768117068965516, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14269, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.544831384615384 ], [ 29.768386551724138, -1.545100861538462 ], [ 29.76865603448276, -1.545100861538462 ], [ 29.76865603448276, -1.544831384615384 ], [ 29.768386551724138, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14270, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.544831384615384 ], [ 29.76865603448276, -1.545100861538462 ], [ 29.768925517241382, -1.545100861538462 ], [ 29.768925517241382, -1.544831384615384 ], [ 29.76865603448276, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14271, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.544831384615384 ], [ 29.768925517241382, -1.545100861538462 ], [ 29.769195, -1.545100861538462 ], [ 29.769195, -1.544831384615384 ], [ 29.768925517241382, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14272, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.544831384615384 ], [ 29.769195, -1.545100861538462 ], [ 29.769464482758622, -1.545100861538462 ], [ 29.769464482758622, -1.544831384615384 ], [ 29.769195, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14273, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.544831384615384 ], [ 29.769464482758622, -1.545100861538462 ], [ 29.769733965517243, -1.545100861538462 ], [ 29.769733965517243, -1.544831384615384 ], [ 29.769464482758622, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14274, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770272931034484, -1.544831384615384 ], [ 29.770272931034484, -1.545370338461538 ], [ 29.770542413793105, -1.545370338461538 ], [ 29.770542413793105, -1.544831384615384 ], [ 29.770272931034484, -1.544831384615384 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14275, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.545100861538462 ], [ 29.75329551724138, -1.545370338461538 ], [ 29.753565, -1.545370338461538 ], [ 29.753565, -1.545100861538462 ], [ 29.75329551724138, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14276, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.545100861538462 ], [ 29.753565, -1.545639815384615 ], [ 29.75383448275862, -1.545639815384615 ], [ 29.75383448275862, -1.545100861538462 ], [ 29.753565, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14277, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.545100861538462 ], [ 29.75383448275862, -1.545639815384615 ], [ 29.754373448275864, -1.545639815384615 ], [ 29.754373448275864, -1.545370338461538 ], [ 29.754103965517242, -1.545370338461538 ], [ 29.754103965517242, -1.545100861538462 ], [ 29.75383448275862, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14278, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.545100861538462 ], [ 29.754642931034482, -1.545370338461538 ], [ 29.754912413793104, -1.545370338461538 ], [ 29.754912413793104, -1.545100861538462 ], [ 29.754642931034482, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14279, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.545100861538462 ], [ 29.754912413793104, -1.545370338461538 ], [ 29.755181896551726, -1.545370338461538 ], [ 29.755181896551726, -1.545100861538462 ], [ 29.754912413793104, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14280, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.545100861538462 ], [ 29.755181896551726, -1.545370338461538 ], [ 29.755451379310344, -1.545370338461538 ], [ 29.755451379310344, -1.545100861538462 ], [ 29.755181896551726, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14281, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.545100861538462 ], [ 29.755451379310344, -1.545370338461538 ], [ 29.755720862068966, -1.545370338461538 ], [ 29.755720862068966, -1.545100861538462 ], [ 29.755451379310344, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14282, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.545100861538462 ], [ 29.755720862068966, -1.545370338461538 ], [ 29.755990344827588, -1.545370338461538 ], [ 29.755990344827588, -1.545100861538462 ], [ 29.755720862068966, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14283, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.545100861538462 ], [ 29.756798793103449, -1.545370338461538 ], [ 29.757068275862068, -1.545370338461538 ], [ 29.757068275862068, -1.545100861538462 ], [ 29.756798793103449, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14284, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.545100861538462 ], [ 29.757068275862068, -1.545370338461538 ], [ 29.75733775862069, -1.545370338461538 ], [ 29.75733775862069, -1.545100861538462 ], [ 29.757068275862068, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14285, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.545100861538462 ], [ 29.75733775862069, -1.545639815384615 ], [ 29.757607241379311, -1.545639815384615 ], [ 29.757607241379311, -1.545100861538462 ], [ 29.75733775862069, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14286, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.545100861538462 ], [ 29.757607241379311, -1.545639815384615 ], [ 29.757876724137933, -1.545639815384615 ], [ 29.757876724137933, -1.545100861538462 ], [ 29.757607241379311, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14287, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.545100861538462 ], [ 29.757876724137933, -1.545370338461538 ], [ 29.758146206896551, -1.545370338461538 ], [ 29.758146206896551, -1.545100861538462 ], [ 29.757876724137933, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14288, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.545100861538462 ], [ 29.758146206896551, -1.545370338461538 ], [ 29.758415689655173, -1.545370338461538 ], [ 29.758415689655173, -1.545100861538462 ], [ 29.758146206896551, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14289, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.545100861538462 ], [ 29.758415689655173, -1.545370338461538 ], [ 29.758685172413795, -1.545370338461538 ], [ 29.758685172413795, -1.545100861538462 ], [ 29.758415689655173, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14290, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.545100861538462 ], [ 29.758685172413795, -1.545370338461538 ], [ 29.758954655172413, -1.545370338461538 ], [ 29.758954655172413, -1.545100861538462 ], [ 29.758685172413795, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14291, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.545100861538462 ], [ 29.758954655172413, -1.545370338461538 ], [ 29.759224137931035, -1.545370338461538 ], [ 29.759224137931035, -1.545100861538462 ], [ 29.758954655172413, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14292, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.545100861538462 ], [ 29.759224137931035, -1.545370338461538 ], [ 29.759493620689657, -1.545370338461538 ], [ 29.759493620689657, -1.545100861538462 ], [ 29.759224137931035, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14293, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.545100861538462 ], [ 29.759493620689657, -1.545370338461538 ], [ 29.759763103448275, -1.545370338461538 ], [ 29.759763103448275, -1.545100861538462 ], [ 29.759493620689657, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14294, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.545100861538462 ], [ 29.759763103448275, -1.545370338461538 ], [ 29.760032586206897, -1.545370338461538 ], [ 29.760032586206897, -1.545100861538462 ], [ 29.759763103448275, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14295, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.545100861538462 ], [ 29.760032586206897, -1.545370338461538 ], [ 29.760302068965519, -1.545370338461538 ], [ 29.760302068965519, -1.545100861538462 ], [ 29.760032586206897, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14296, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.545100861538462 ], [ 29.760302068965519, -1.545370338461538 ], [ 29.760571551724137, -1.545370338461538 ], [ 29.760571551724137, -1.545100861538462 ], [ 29.760302068965519, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14297, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.545100861538462 ], [ 29.760571551724137, -1.545370338461538 ], [ 29.760841034482759, -1.545370338461538 ], [ 29.760841034482759, -1.545100861538462 ], [ 29.760571551724137, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14298, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.545100861538462 ], [ 29.760841034482759, -1.545370338461538 ], [ 29.761110517241381, -1.545370338461538 ], [ 29.761110517241381, -1.545100861538462 ], [ 29.760841034482759, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14299, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.545100861538462 ], [ 29.761110517241381, -1.545370338461538 ], [ 29.76138, -1.545370338461538 ], [ 29.76138, -1.545100861538462 ], [ 29.761110517241381, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14300, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.545100861538462 ], [ 29.76138, -1.545370338461538 ], [ 29.761649482758621, -1.545370338461538 ], [ 29.761649482758621, -1.545100861538462 ], [ 29.76138, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14301, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.545100861538462 ], [ 29.761649482758621, -1.545370338461538 ], [ 29.761918965517243, -1.545370338461538 ], [ 29.761918965517243, -1.545100861538462 ], [ 29.761649482758621, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14302, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.545100861538462 ], [ 29.761918965517243, -1.545370338461538 ], [ 29.762188448275861, -1.545370338461538 ], [ 29.762188448275861, -1.545100861538462 ], [ 29.761918965517243, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14303, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.545100861538462 ], [ 29.762188448275861, -1.545370338461538 ], [ 29.762457931034483, -1.545370338461538 ], [ 29.762457931034483, -1.545100861538462 ], [ 29.762188448275861, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14304, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.545100861538462 ], [ 29.762457931034483, -1.545370338461538 ], [ 29.762727413793105, -1.545370338461538 ], [ 29.762727413793105, -1.545100861538462 ], [ 29.762457931034483, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14305, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.545100861538462 ], [ 29.762727413793105, -1.545370338461538 ], [ 29.762996896551723, -1.545370338461538 ], [ 29.762996896551723, -1.545100861538462 ], [ 29.762727413793105, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14306, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.545100861538462 ], [ 29.763266379310345, -1.545909292307692 ], [ 29.763535862068967, -1.545909292307692 ], [ 29.763535862068967, -1.545100861538462 ], [ 29.763266379310345, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14307, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.545100861538462 ], [ 29.763535862068967, -1.545639815384615 ], [ 29.763805344827588, -1.545639815384615 ], [ 29.763805344827588, -1.545100861538462 ], [ 29.763535862068967, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14308, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.545100861538462 ], [ 29.764344310344828, -1.545639815384615 ], [ 29.76461379310345, -1.545639815384615 ], [ 29.76461379310345, -1.545100861538462 ], [ 29.764344310344828, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14309, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.545100861538462 ], [ 29.76461379310345, -1.545639815384615 ], [ 29.764883275862069, -1.545639815384615 ], [ 29.764883275862069, -1.545100861538462 ], [ 29.76461379310345, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14310, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.545100861538462 ], [ 29.764883275862069, -1.545370338461538 ], [ 29.76515275862069, -1.545370338461538 ], [ 29.76515275862069, -1.545100861538462 ], [ 29.764883275862069, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14311, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.545100861538462 ], [ 29.76515275862069, -1.545370338461538 ], [ 29.765422241379312, -1.545370338461538 ], [ 29.765422241379312, -1.545100861538462 ], [ 29.76515275862069, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14312, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.545100861538462 ], [ 29.765961206896552, -1.545639815384615 ], [ 29.766230689655174, -1.545639815384615 ], [ 29.766230689655174, -1.545100861538462 ], [ 29.765961206896552, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14313, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.545100861538462 ], [ 29.766230689655174, -1.545370338461538 ], [ 29.766500172413792, -1.545370338461538 ], [ 29.766500172413792, -1.545100861538462 ], [ 29.766230689655174, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14314, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.545100861538462 ], [ 29.766500172413792, -1.545370338461538 ], [ 29.766769655172414, -1.545370338461538 ], [ 29.766769655172414, -1.545100861538462 ], [ 29.766500172413792, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14315, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.545100861538462 ], [ 29.766769655172414, -1.545370338461538 ], [ 29.767039137931036, -1.545370338461538 ], [ 29.767039137931036, -1.545100861538462 ], [ 29.766769655172414, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14316, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.545100861538462 ], [ 29.767039137931036, -1.545370338461538 ], [ 29.767308620689654, -1.545370338461538 ], [ 29.767308620689654, -1.545100861538462 ], [ 29.767039137931036, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14317, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.545100861538462 ], [ 29.767308620689654, -1.545370338461538 ], [ 29.767578103448276, -1.545370338461538 ], [ 29.767578103448276, -1.545100861538462 ], [ 29.767308620689654, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14318, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.545100861538462 ], [ 29.767578103448276, -1.545370338461538 ], [ 29.767847586206898, -1.545370338461538 ], [ 29.767847586206898, -1.545100861538462 ], [ 29.767578103448276, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14319, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.545100861538462 ], [ 29.767847586206898, -1.545370338461538 ], [ 29.768117068965516, -1.545370338461538 ], [ 29.768117068965516, -1.545100861538462 ], [ 29.767847586206898, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14320, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.545100861538462 ], [ 29.768117068965516, -1.545370338461538 ], [ 29.768386551724138, -1.545370338461538 ], [ 29.768386551724138, -1.545100861538462 ], [ 29.768117068965516, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14321, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.545100861538462 ], [ 29.768386551724138, -1.545370338461538 ], [ 29.76865603448276, -1.545370338461538 ], [ 29.76865603448276, -1.545100861538462 ], [ 29.768386551724138, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14322, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.545100861538462 ], [ 29.76865603448276, -1.545370338461538 ], [ 29.768925517241382, -1.545370338461538 ], [ 29.768925517241382, -1.545100861538462 ], [ 29.76865603448276, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14323, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.545100861538462 ], [ 29.768925517241382, -1.545639815384615 ], [ 29.769195, -1.545639815384615 ], [ 29.769195, -1.545100861538462 ], [ 29.768925517241382, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14324, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.545100861538462 ], [ 29.769195, -1.545370338461538 ], [ 29.769464482758622, -1.545370338461538 ], [ 29.769464482758622, -1.545100861538462 ], [ 29.769195, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14325, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.545100861538462 ], [ 29.769464482758622, -1.545370338461538 ], [ 29.769733965517243, -1.545370338461538 ], [ 29.769733965517243, -1.545100861538462 ], [ 29.769464482758622, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14326, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.545100861538462 ], [ 29.769733965517243, -1.545370338461538 ], [ 29.770003448275862, -1.545370338461538 ], [ 29.770003448275862, -1.545100861538462 ], [ 29.769733965517243, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14327, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.545100861538462 ], [ 29.770003448275862, -1.545370338461538 ], [ 29.770272931034484, -1.545370338461538 ], [ 29.770272931034484, -1.545100861538462 ], [ 29.770003448275862, -1.545100861538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14328, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.545370338461538 ], [ 29.75329551724138, -1.545909292307692 ], [ 29.753565, -1.545909292307692 ], [ 29.753565, -1.545370338461538 ], [ 29.75329551724138, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14329, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.545370338461538 ], [ 29.754373448275864, -1.545639815384615 ], [ 29.754642931034482, -1.545639815384615 ], [ 29.754642931034482, -1.545370338461538 ], [ 29.754373448275864, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14330, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.545370338461538 ], [ 29.754642931034482, -1.545639815384615 ], [ 29.754912413793104, -1.545639815384615 ], [ 29.754912413793104, -1.545370338461538 ], [ 29.754642931034482, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14331, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.545370338461538 ], [ 29.754912413793104, -1.545639815384615 ], [ 29.755181896551726, -1.545639815384615 ], [ 29.755181896551726, -1.545370338461538 ], [ 29.754912413793104, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14332, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.545370338461538 ], [ 29.755181896551726, -1.545639815384615 ], [ 29.755451379310344, -1.545639815384615 ], [ 29.755451379310344, -1.545370338461538 ], [ 29.755181896551726, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14333, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.545370338461538 ], [ 29.755451379310344, -1.545639815384615 ], [ 29.755720862068966, -1.545639815384615 ], [ 29.755720862068966, -1.545370338461538 ], [ 29.755451379310344, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14334, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.545370338461538 ], [ 29.755720862068966, -1.545639815384615 ], [ 29.755990344827588, -1.545639815384615 ], [ 29.755990344827588, -1.545370338461538 ], [ 29.755720862068966, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14335, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.545370338461538 ], [ 29.755990344827588, -1.545639815384615 ], [ 29.756259827586206, -1.545639815384615 ], [ 29.756259827586206, -1.545370338461538 ], [ 29.755990344827588, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14336, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.545370338461538 ], [ 29.756259827586206, -1.545639815384615 ], [ 29.756798793103449, -1.545639815384615 ], [ 29.756798793103449, -1.545370338461538 ], [ 29.756259827586206, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14337, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.545370338461538 ], [ 29.756798793103449, -1.545639815384615 ], [ 29.757068275862068, -1.545639815384615 ], [ 29.757068275862068, -1.545370338461538 ], [ 29.756798793103449, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14338, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.545370338461538 ], [ 29.757068275862068, -1.545639815384615 ], [ 29.75733775862069, -1.545639815384615 ], [ 29.75733775862069, -1.545370338461538 ], [ 29.757068275862068, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14339, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.545370338461538 ], [ 29.757876724137933, -1.545639815384615 ], [ 29.758146206896551, -1.545639815384615 ], [ 29.758146206896551, -1.545370338461538 ], [ 29.757876724137933, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14340, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.545370338461538 ], [ 29.758146206896551, -1.545639815384615 ], [ 29.758415689655173, -1.545639815384615 ], [ 29.758415689655173, -1.545370338461538 ], [ 29.758146206896551, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14341, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.545370338461538 ], [ 29.758415689655173, -1.545909292307692 ], [ 29.758685172413795, -1.545909292307692 ], [ 29.758685172413795, -1.545370338461538 ], [ 29.758415689655173, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14342, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.545370338461538 ], [ 29.758685172413795, -1.545639815384615 ], [ 29.758954655172413, -1.545639815384615 ], [ 29.758954655172413, -1.545370338461538 ], [ 29.758685172413795, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14343, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.545370338461538 ], [ 29.758954655172413, -1.545639815384615 ], [ 29.759224137931035, -1.545639815384615 ], [ 29.759224137931035, -1.545370338461538 ], [ 29.758954655172413, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14344, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.545370338461538 ], [ 29.759224137931035, -1.545639815384615 ], [ 29.759493620689657, -1.545639815384615 ], [ 29.759493620689657, -1.545370338461538 ], [ 29.759224137931035, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14345, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.545370338461538 ], [ 29.759493620689657, -1.545639815384615 ], [ 29.759763103448275, -1.545639815384615 ], [ 29.759763103448275, -1.545370338461538 ], [ 29.759493620689657, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14346, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.545370338461538 ], [ 29.759763103448275, -1.545639815384615 ], [ 29.760032586206897, -1.545639815384615 ], [ 29.760032586206897, -1.545370338461538 ], [ 29.759763103448275, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14347, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.545370338461538 ], [ 29.760032586206897, -1.545639815384615 ], [ 29.760302068965519, -1.545639815384615 ], [ 29.760302068965519, -1.545370338461538 ], [ 29.760032586206897, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14348, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.545370338461538 ], [ 29.760302068965519, -1.545639815384615 ], [ 29.760571551724137, -1.545639815384615 ], [ 29.760571551724137, -1.545370338461538 ], [ 29.760302068965519, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14349, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.545370338461538 ], [ 29.760571551724137, -1.545639815384615 ], [ 29.760841034482759, -1.545639815384615 ], [ 29.760841034482759, -1.545370338461538 ], [ 29.760571551724137, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14350, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.545370338461538 ], [ 29.760841034482759, -1.545639815384615 ], [ 29.761110517241381, -1.545639815384615 ], [ 29.761110517241381, -1.545370338461538 ], [ 29.760841034482759, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14351, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.545370338461538 ], [ 29.761110517241381, -1.545639815384615 ], [ 29.76138, -1.545639815384615 ], [ 29.76138, -1.545370338461538 ], [ 29.761110517241381, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14352, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.545370338461538 ], [ 29.76138, -1.545639815384615 ], [ 29.761649482758621, -1.545639815384615 ], [ 29.761649482758621, -1.545370338461538 ], [ 29.76138, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14353, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.545370338461538 ], [ 29.761649482758621, -1.545639815384615 ], [ 29.761918965517243, -1.545639815384615 ], [ 29.761918965517243, -1.545370338461538 ], [ 29.761649482758621, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14354, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.545370338461538 ], [ 29.761918965517243, -1.545639815384615 ], [ 29.762188448275861, -1.545639815384615 ], [ 29.762188448275861, -1.545370338461538 ], [ 29.761918965517243, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14355, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.545370338461538 ], [ 29.762188448275861, -1.545639815384615 ], [ 29.762457931034483, -1.545639815384615 ], [ 29.762457931034483, -1.545370338461538 ], [ 29.762188448275861, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14356, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.545370338461538 ], [ 29.762457931034483, -1.545639815384615 ], [ 29.762727413793105, -1.545639815384615 ], [ 29.762727413793105, -1.545370338461538 ], [ 29.762457931034483, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14357, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.545370338461538 ], [ 29.762727413793105, -1.545639815384615 ], [ 29.762996896551723, -1.545639815384615 ], [ 29.762996896551723, -1.545370338461538 ], [ 29.762727413793105, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14358, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.545370338461538 ], [ 29.762996896551723, -1.545639815384615 ], [ 29.763266379310345, -1.545639815384615 ], [ 29.763266379310345, -1.545370338461538 ], [ 29.762996896551723, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14359, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.545370338461538 ], [ 29.764074827586207, -1.545909292307692 ], [ 29.764344310344828, -1.545909292307692 ], [ 29.764344310344828, -1.545370338461538 ], [ 29.764074827586207, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14360, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.545370338461538 ], [ 29.764883275862069, -1.545909292307692 ], [ 29.76515275862069, -1.545909292307692 ], [ 29.76515275862069, -1.545370338461538 ], [ 29.764883275862069, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14361, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.545370338461538 ], [ 29.76515275862069, -1.545909292307692 ], [ 29.765422241379312, -1.545909292307692 ], [ 29.765422241379312, -1.545370338461538 ], [ 29.76515275862069, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14362, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.545370338461538 ], [ 29.765422241379312, -1.545639815384615 ], [ 29.765961206896552, -1.545639815384615 ], [ 29.765961206896552, -1.545370338461538 ], [ 29.765422241379312, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14363, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.545370338461538 ], [ 29.766230689655174, -1.545909292307692 ], [ 29.766500172413792, -1.545909292307692 ], [ 29.766500172413792, -1.545370338461538 ], [ 29.766230689655174, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14364, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.545370338461538 ], [ 29.766500172413792, -1.545639815384615 ], [ 29.766769655172414, -1.545639815384615 ], [ 29.766769655172414, -1.545370338461538 ], [ 29.766500172413792, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14365, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.545370338461538 ], [ 29.766769655172414, -1.545639815384615 ], [ 29.767039137931036, -1.545639815384615 ], [ 29.767039137931036, -1.545370338461538 ], [ 29.766769655172414, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14366, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.545370338461538 ], [ 29.767039137931036, -1.545639815384615 ], [ 29.767308620689654, -1.545639815384615 ], [ 29.767308620689654, -1.545370338461538 ], [ 29.767039137931036, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14367, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.545370338461538 ], [ 29.767308620689654, -1.545639815384615 ], [ 29.767578103448276, -1.545639815384615 ], [ 29.767578103448276, -1.545370338461538 ], [ 29.767308620689654, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14368, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.545370338461538 ], [ 29.767578103448276, -1.545639815384615 ], [ 29.767847586206898, -1.545639815384615 ], [ 29.767847586206898, -1.545370338461538 ], [ 29.767578103448276, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14369, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.545370338461538 ], [ 29.767847586206898, -1.545639815384615 ], [ 29.768117068965516, -1.545639815384615 ], [ 29.768117068965516, -1.545370338461538 ], [ 29.767847586206898, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14370, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.545370338461538 ], [ 29.768117068965516, -1.545639815384615 ], [ 29.768386551724138, -1.545639815384615 ], [ 29.768386551724138, -1.545370338461538 ], [ 29.768117068965516, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14371, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.545370338461538 ], [ 29.768386551724138, -1.545639815384615 ], [ 29.76865603448276, -1.545639815384615 ], [ 29.76865603448276, -1.545370338461538 ], [ 29.768386551724138, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14372, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.545370338461538 ], [ 29.76865603448276, -1.545639815384615 ], [ 29.768925517241382, -1.545639815384615 ], [ 29.768925517241382, -1.545370338461538 ], [ 29.76865603448276, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14373, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.545370338461538 ], [ 29.769195, -1.545909292307692 ], [ 29.769464482758622, -1.545909292307692 ], [ 29.769464482758622, -1.545370338461538 ], [ 29.769195, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14374, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.545370338461538 ], [ 29.769464482758622, -1.545909292307692 ], [ 29.769733965517243, -1.545909292307692 ], [ 29.769733965517243, -1.545370338461538 ], [ 29.769464482758622, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14375, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.545370338461538 ], [ 29.769733965517243, -1.545639815384615 ], [ 29.770003448275862, -1.545639815384615 ], [ 29.770003448275862, -1.545370338461538 ], [ 29.769733965517243, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14376, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770003448275862, -1.545370338461538 ], [ 29.770003448275862, -1.545639815384615 ], [ 29.770272931034484, -1.545639815384615 ], [ 29.770272931034484, -1.545370338461538 ], [ 29.770003448275862, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14377, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.545639815384615 ], [ 29.753565, -1.545909292307692 ], [ 29.75383448275862, -1.545909292307692 ], [ 29.75383448275862, -1.545639815384615 ], [ 29.753565, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14378, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.545639815384615 ], [ 29.75383448275862, -1.545909292307692 ], [ 29.754373448275864, -1.545909292307692 ], [ 29.754373448275864, -1.545639815384615 ], [ 29.75383448275862, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14379, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.545639815384615 ], [ 29.754373448275864, -1.545909292307692 ], [ 29.754642931034482, -1.545909292307692 ], [ 29.754642931034482, -1.545639815384615 ], [ 29.754373448275864, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14380, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.545639815384615 ], [ 29.754642931034482, -1.545909292307692 ], [ 29.754912413793104, -1.545909292307692 ], [ 29.754912413793104, -1.545639815384615 ], [ 29.754642931034482, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14381, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.545639815384615 ], [ 29.754912413793104, -1.545909292307692 ], [ 29.755181896551726, -1.545909292307692 ], [ 29.755181896551726, -1.545639815384615 ], [ 29.754912413793104, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14382, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.545639815384615 ], [ 29.755181896551726, -1.545909292307692 ], [ 29.755451379310344, -1.545909292307692 ], [ 29.755451379310344, -1.545639815384615 ], [ 29.755181896551726, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14383, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.545639815384615 ], [ 29.755451379310344, -1.545909292307692 ], [ 29.755720862068966, -1.545909292307692 ], [ 29.755720862068966, -1.545639815384615 ], [ 29.755451379310344, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14384, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.545639815384615 ], [ 29.755720862068966, -1.545909292307692 ], [ 29.755990344827588, -1.545909292307692 ], [ 29.755990344827588, -1.545639815384615 ], [ 29.755720862068966, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14385, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.545639815384615 ], [ 29.755990344827588, -1.545909292307692 ], [ 29.756259827586206, -1.545909292307692 ], [ 29.756259827586206, -1.545639815384615 ], [ 29.755990344827588, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14386, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.545639815384615 ], [ 29.756259827586206, -1.545909292307692 ], [ 29.756798793103449, -1.545909292307692 ], [ 29.756798793103449, -1.545639815384615 ], [ 29.756259827586206, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14387, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.545639815384615 ], [ 29.756798793103449, -1.545909292307692 ], [ 29.757068275862068, -1.545909292307692 ], [ 29.757068275862068, -1.545639815384615 ], [ 29.756798793103449, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14388, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.545639815384615 ], [ 29.757068275862068, -1.545909292307692 ], [ 29.75733775862069, -1.545909292307692 ], [ 29.75733775862069, -1.545639815384615 ], [ 29.757068275862068, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14389, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.545639815384615 ], [ 29.75733775862069, -1.545909292307692 ], [ 29.757607241379311, -1.545909292307692 ], [ 29.757607241379311, -1.545639815384615 ], [ 29.75733775862069, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14390, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.545639815384615 ], [ 29.757607241379311, -1.545909292307692 ], [ 29.757876724137933, -1.545909292307692 ], [ 29.757876724137933, -1.545639815384615 ], [ 29.757607241379311, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14391, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.545639815384615 ], [ 29.757876724137933, -1.545909292307692 ], [ 29.758146206896551, -1.545909292307692 ], [ 29.758146206896551, -1.545639815384615 ], [ 29.757876724137933, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14392, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.545639815384615 ], [ 29.758146206896551, -1.545909292307692 ], [ 29.758415689655173, -1.545909292307692 ], [ 29.758415689655173, -1.545639815384615 ], [ 29.758146206896551, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14393, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.545639815384615 ], [ 29.758685172413795, -1.545909292307692 ], [ 29.758954655172413, -1.545909292307692 ], [ 29.758954655172413, -1.545639815384615 ], [ 29.758685172413795, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14394, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.545639815384615 ], [ 29.759224137931035, -1.545909292307692 ], [ 29.759493620689657, -1.545909292307692 ], [ 29.759493620689657, -1.545639815384615 ], [ 29.759224137931035, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14395, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.545639815384615 ], [ 29.759493620689657, -1.545909292307692 ], [ 29.759763103448275, -1.545909292307692 ], [ 29.759763103448275, -1.545639815384615 ], [ 29.759493620689657, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14396, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.545639815384615 ], [ 29.759763103448275, -1.545909292307692 ], [ 29.760032586206897, -1.545909292307692 ], [ 29.760032586206897, -1.545639815384615 ], [ 29.759763103448275, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14397, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.545639815384615 ], [ 29.760032586206897, -1.545909292307692 ], [ 29.760302068965519, -1.545909292307692 ], [ 29.760302068965519, -1.545639815384615 ], [ 29.760032586206897, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14398, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.545639815384615 ], [ 29.760302068965519, -1.545909292307692 ], [ 29.760571551724137, -1.545909292307692 ], [ 29.760571551724137, -1.545639815384615 ], [ 29.760302068965519, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14399, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.545639815384615 ], [ 29.760571551724137, -1.545909292307692 ], [ 29.760841034482759, -1.545909292307692 ], [ 29.760841034482759, -1.545639815384615 ], [ 29.760571551724137, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14400, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.545639815384615 ], [ 29.760841034482759, -1.545909292307692 ], [ 29.761110517241381, -1.545909292307692 ], [ 29.761110517241381, -1.545639815384615 ], [ 29.760841034482759, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14401, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.545639815384615 ], [ 29.761110517241381, -1.545909292307692 ], [ 29.76138, -1.545909292307692 ], [ 29.76138, -1.545639815384615 ], [ 29.761110517241381, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14402, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.545639815384615 ], [ 29.76138, -1.545909292307692 ], [ 29.761649482758621, -1.545909292307692 ], [ 29.761649482758621, -1.545639815384615 ], [ 29.76138, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14403, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.545639815384615 ], [ 29.761649482758621, -1.545909292307692 ], [ 29.761918965517243, -1.545909292307692 ], [ 29.761918965517243, -1.545639815384615 ], [ 29.761649482758621, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14404, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.545639815384615 ], [ 29.761918965517243, -1.545909292307692 ], [ 29.762188448275861, -1.545909292307692 ], [ 29.762188448275861, -1.545639815384615 ], [ 29.761918965517243, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14405, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.545639815384615 ], [ 29.762188448275861, -1.545909292307692 ], [ 29.762457931034483, -1.545909292307692 ], [ 29.762457931034483, -1.545639815384615 ], [ 29.762188448275861, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14406, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.545639815384615 ], [ 29.762457931034483, -1.545909292307692 ], [ 29.762727413793105, -1.545909292307692 ], [ 29.762727413793105, -1.545639815384615 ], [ 29.762457931034483, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14407, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.545639815384615 ], [ 29.762727413793105, -1.545909292307692 ], [ 29.762996896551723, -1.545909292307692 ], [ 29.762996896551723, -1.545639815384615 ], [ 29.762727413793105, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14408, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.545639815384615 ], [ 29.762996896551723, -1.545909292307692 ], [ 29.763266379310345, -1.545909292307692 ], [ 29.763266379310345, -1.545639815384615 ], [ 29.762996896551723, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14409, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.545639815384615 ], [ 29.763535862068967, -1.545909292307692 ], [ 29.763805344827588, -1.545909292307692 ], [ 29.763805344827588, -1.545639815384615 ], [ 29.763535862068967, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14410, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.545639815384615 ], [ 29.767039137931036, -1.545909292307692 ], [ 29.767308620689654, -1.545909292307692 ], [ 29.767308620689654, -1.545639815384615 ], [ 29.767039137931036, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14411, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.545639815384615 ], [ 29.767308620689654, -1.545909292307692 ], [ 29.767578103448276, -1.545909292307692 ], [ 29.767578103448276, -1.545639815384615 ], [ 29.767308620689654, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14412, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.545639815384615 ], [ 29.767578103448276, -1.545909292307692 ], [ 29.767847586206898, -1.545909292307692 ], [ 29.767847586206898, -1.545639815384615 ], [ 29.767578103448276, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14413, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.545639815384615 ], [ 29.767847586206898, -1.545909292307692 ], [ 29.768117068965516, -1.545909292307692 ], [ 29.768117068965516, -1.545639815384615 ], [ 29.767847586206898, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14414, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.545639815384615 ], [ 29.768117068965516, -1.545909292307692 ], [ 29.768386551724138, -1.545909292307692 ], [ 29.768386551724138, -1.545639815384615 ], [ 29.768117068965516, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14415, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.545639815384615 ], [ 29.768386551724138, -1.545909292307692 ], [ 29.76865603448276, -1.545909292307692 ], [ 29.76865603448276, -1.545639815384615 ], [ 29.768386551724138, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14416, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.545639815384615 ], [ 29.76865603448276, -1.545909292307692 ], [ 29.768925517241382, -1.545909292307692 ], [ 29.768925517241382, -1.545639815384615 ], [ 29.76865603448276, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14417, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.545639815384615 ], [ 29.768925517241382, -1.545909292307692 ], [ 29.769195, -1.545909292307692 ], [ 29.769195, -1.545639815384615 ], [ 29.768925517241382, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14418, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.545370338461538 ], [ 29.763805344827588, -1.546178769230769 ], [ 29.764074827586207, -1.546178769230769 ], [ 29.764074827586207, -1.545370338461538 ], [ 29.763805344827588, -1.545370338461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14419, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.545639815384615 ], [ 29.758954655172413, -1.546178769230769 ], [ 29.759224137931035, -1.546178769230769 ], [ 29.759224137931035, -1.545639815384615 ], [ 29.758954655172413, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14420, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.545639815384615 ], [ 29.764344310344828, -1.546448246153846 ], [ 29.76461379310345, -1.546448246153846 ], [ 29.76461379310345, -1.545639815384615 ], [ 29.764344310344828, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14421, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.545639815384615 ], [ 29.76461379310345, -1.546448246153846 ], [ 29.764883275862069, -1.546448246153846 ], [ 29.764883275862069, -1.545639815384615 ], [ 29.76461379310345, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14422, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.545639815384615 ], [ 29.765422241379312, -1.546717723076923 ], [ 29.765961206896552, -1.546717723076923 ], [ 29.765961206896552, -1.545639815384615 ], [ 29.765422241379312, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14423, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.545639815384615 ], [ 29.765961206896552, -1.546178769230769 ], [ 29.766230689655174, -1.546178769230769 ], [ 29.766230689655174, -1.545639815384615 ], [ 29.765961206896552, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14424, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.545639815384615 ], [ 29.766500172413792, -1.5469872 ], [ 29.766769655172414, -1.5469872 ], [ 29.766769655172414, -1.545639815384615 ], [ 29.766500172413792, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14425, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.545639815384615 ], [ 29.766769655172414, -1.546178769230769 ], [ 29.767039137931036, -1.546178769230769 ], [ 29.767039137931036, -1.545639815384615 ], [ 29.766769655172414, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14426, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769733965517243, -1.545639815384615 ], [ 29.769733965517243, -1.546178769230769 ], [ 29.770003448275862, -1.546178769230769 ], [ 29.770003448275862, -1.545639815384615 ], [ 29.769733965517243, -1.545639815384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14427, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.545909292307692 ], [ 29.753565, -1.546178769230769 ], [ 29.754103965517242, -1.546178769230769 ], [ 29.754103965517242, -1.545909292307692 ], [ 29.753565, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14428, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.545909292307692 ], [ 29.754103965517242, -1.546178769230769 ], [ 29.754373448275864, -1.546178769230769 ], [ 29.754373448275864, -1.545909292307692 ], [ 29.754103965517242, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14429, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.545909292307692 ], [ 29.754373448275864, -1.546178769230769 ], [ 29.754642931034482, -1.546178769230769 ], [ 29.754642931034482, -1.545909292307692 ], [ 29.754373448275864, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14430, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.545909292307692 ], [ 29.754642931034482, -1.546178769230769 ], [ 29.754912413793104, -1.546178769230769 ], [ 29.754912413793104, -1.545909292307692 ], [ 29.754642931034482, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14431, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.545909292307692 ], [ 29.754912413793104, -1.546178769230769 ], [ 29.755181896551726, -1.546178769230769 ], [ 29.755181896551726, -1.545909292307692 ], [ 29.754912413793104, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14432, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.545909292307692 ], [ 29.755181896551726, -1.546178769230769 ], [ 29.755451379310344, -1.546178769230769 ], [ 29.755451379310344, -1.545909292307692 ], [ 29.755181896551726, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14433, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.545909292307692 ], [ 29.755451379310344, -1.546178769230769 ], [ 29.755720862068966, -1.546178769230769 ], [ 29.755720862068966, -1.545909292307692 ], [ 29.755451379310344, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14434, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.545909292307692 ], [ 29.755720862068966, -1.546178769230769 ], [ 29.755990344827588, -1.546178769230769 ], [ 29.755990344827588, -1.545909292307692 ], [ 29.755720862068966, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14435, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.545909292307692 ], [ 29.755990344827588, -1.546178769230769 ], [ 29.756259827586206, -1.546178769230769 ], [ 29.756259827586206, -1.545909292307692 ], [ 29.755990344827588, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14436, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.545909292307692 ], [ 29.756259827586206, -1.546178769230769 ], [ 29.756798793103449, -1.546178769230769 ], [ 29.756798793103449, -1.545909292307692 ], [ 29.756259827586206, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14437, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.545909292307692 ], [ 29.756798793103449, -1.546178769230769 ], [ 29.757068275862068, -1.546178769230769 ], [ 29.757068275862068, -1.545909292307692 ], [ 29.756798793103449, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14438, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.545909292307692 ], [ 29.757068275862068, -1.546178769230769 ], [ 29.75733775862069, -1.546178769230769 ], [ 29.75733775862069, -1.545909292307692 ], [ 29.757068275862068, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14439, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.545909292307692 ], [ 29.75733775862069, -1.546178769230769 ], [ 29.757607241379311, -1.546178769230769 ], [ 29.757607241379311, -1.545909292307692 ], [ 29.75733775862069, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14440, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.545909292307692 ], [ 29.757607241379311, -1.546178769230769 ], [ 29.757876724137933, -1.546178769230769 ], [ 29.757876724137933, -1.545909292307692 ], [ 29.757607241379311, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14441, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.545909292307692 ], [ 29.757876724137933, -1.546178769230769 ], [ 29.758146206896551, -1.546178769230769 ], [ 29.758146206896551, -1.545909292307692 ], [ 29.757876724137933, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14442, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.545909292307692 ], [ 29.758146206896551, -1.546178769230769 ], [ 29.758415689655173, -1.546178769230769 ], [ 29.758415689655173, -1.545909292307692 ], [ 29.758146206896551, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14443, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.545909292307692 ], [ 29.758415689655173, -1.546178769230769 ], [ 29.758685172413795, -1.546178769230769 ], [ 29.758685172413795, -1.545909292307692 ], [ 29.758415689655173, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14444, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.545909292307692 ], [ 29.758685172413795, -1.546448246153846 ], [ 29.758954655172413, -1.546448246153846 ], [ 29.758954655172413, -1.545909292307692 ], [ 29.758685172413795, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14445, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.545909292307692 ], [ 29.759224137931035, -1.546178769230769 ], [ 29.759493620689657, -1.546178769230769 ], [ 29.759493620689657, -1.545909292307692 ], [ 29.759224137931035, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14446, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.545909292307692 ], [ 29.759493620689657, -1.546178769230769 ], [ 29.759763103448275, -1.546178769230769 ], [ 29.759763103448275, -1.545909292307692 ], [ 29.759493620689657, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14447, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.545909292307692 ], [ 29.759763103448275, -1.546448246153846 ], [ 29.760032586206897, -1.546448246153846 ], [ 29.760032586206897, -1.545909292307692 ], [ 29.759763103448275, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14448, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.545909292307692 ], [ 29.760032586206897, -1.546178769230769 ], [ 29.760302068965519, -1.546178769230769 ], [ 29.760302068965519, -1.545909292307692 ], [ 29.760032586206897, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14449, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.545909292307692 ], [ 29.760302068965519, -1.546178769230769 ], [ 29.760571551724137, -1.546178769230769 ], [ 29.760571551724137, -1.545909292307692 ], [ 29.760302068965519, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14450, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.545909292307692 ], [ 29.760571551724137, -1.546178769230769 ], [ 29.760841034482759, -1.546178769230769 ], [ 29.760841034482759, -1.545909292307692 ], [ 29.760571551724137, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14451, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.545909292307692 ], [ 29.760841034482759, -1.546178769230769 ], [ 29.761110517241381, -1.546178769230769 ], [ 29.761110517241381, -1.545909292307692 ], [ 29.760841034482759, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14452, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.545909292307692 ], [ 29.761110517241381, -1.546178769230769 ], [ 29.76138, -1.546178769230769 ], [ 29.76138, -1.545909292307692 ], [ 29.761110517241381, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14453, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.545909292307692 ], [ 29.76138, -1.546178769230769 ], [ 29.761649482758621, -1.546178769230769 ], [ 29.761649482758621, -1.545909292307692 ], [ 29.76138, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14454, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.545909292307692 ], [ 29.761649482758621, -1.546178769230769 ], [ 29.761918965517243, -1.546178769230769 ], [ 29.761918965517243, -1.545909292307692 ], [ 29.761649482758621, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14455, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.545909292307692 ], [ 29.761918965517243, -1.546178769230769 ], [ 29.762188448275861, -1.546178769230769 ], [ 29.762188448275861, -1.545909292307692 ], [ 29.761918965517243, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14456, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.545909292307692 ], [ 29.762727413793105, -1.546178769230769 ], [ 29.762996896551723, -1.546178769230769 ], [ 29.762996896551723, -1.545909292307692 ], [ 29.762727413793105, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14457, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.545909292307692 ], [ 29.762996896551723, -1.546178769230769 ], [ 29.763266379310345, -1.546178769230769 ], [ 29.763266379310345, -1.545909292307692 ], [ 29.762996896551723, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14458, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.545909292307692 ], [ 29.763266379310345, -1.546178769230769 ], [ 29.763535862068967, -1.546178769230769 ], [ 29.763535862068967, -1.545909292307692 ], [ 29.763266379310345, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14459, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.545909292307692 ], [ 29.763535862068967, -1.546178769230769 ], [ 29.763805344827588, -1.546178769230769 ], [ 29.763805344827588, -1.545909292307692 ], [ 29.763535862068967, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14460, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.545909292307692 ], [ 29.764074827586207, -1.546448246153846 ], [ 29.764344310344828, -1.546448246153846 ], [ 29.764344310344828, -1.545909292307692 ], [ 29.764074827586207, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14461, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.545909292307692 ], [ 29.764883275862069, -1.546717723076923 ], [ 29.76515275862069, -1.546717723076923 ], [ 29.76515275862069, -1.545909292307692 ], [ 29.764883275862069, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14462, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.545909292307692 ], [ 29.76515275862069, -1.5469872 ], [ 29.765422241379312, -1.5469872 ], [ 29.765422241379312, -1.545909292307692 ], [ 29.76515275862069, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14463, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.545909292307692 ], [ 29.766230689655174, -1.5469872 ], [ 29.766500172413792, -1.5469872 ], [ 29.766500172413792, -1.545909292307692 ], [ 29.766230689655174, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14464, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.545909292307692 ], [ 29.767039137931036, -1.547256676923077 ], [ 29.767308620689654, -1.547256676923077 ], [ 29.767308620689654, -1.545909292307692 ], [ 29.767039137931036, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14465, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.545909292307692 ], [ 29.767308620689654, -1.547256676923077 ], [ 29.767578103448276, -1.547256676923077 ], [ 29.767578103448276, -1.545909292307692 ], [ 29.767308620689654, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14466, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.545909292307692 ], [ 29.767578103448276, -1.546178769230769 ], [ 29.767847586206898, -1.546178769230769 ], [ 29.767847586206898, -1.545909292307692 ], [ 29.767578103448276, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14467, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.545909292307692 ], [ 29.767847586206898, -1.546178769230769 ], [ 29.768117068965516, -1.546178769230769 ], [ 29.768117068965516, -1.545909292307692 ], [ 29.767847586206898, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14468, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.545909292307692 ], [ 29.768117068965516, -1.546178769230769 ], [ 29.768386551724138, -1.546178769230769 ], [ 29.768386551724138, -1.545909292307692 ], [ 29.768117068965516, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14469, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.545909292307692 ], [ 29.768386551724138, -1.546448246153846 ], [ 29.76865603448276, -1.546448246153846 ], [ 29.76865603448276, -1.545909292307692 ], [ 29.768386551724138, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14470, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.545909292307692 ], [ 29.76865603448276, -1.546178769230769 ], [ 29.768925517241382, -1.546178769230769 ], [ 29.768925517241382, -1.545909292307692 ], [ 29.76865603448276, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14471, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.545909292307692 ], [ 29.768925517241382, -1.546178769230769 ], [ 29.769195, -1.546178769230769 ], [ 29.769195, -1.545909292307692 ], [ 29.768925517241382, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14472, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.545909292307692 ], [ 29.769195, -1.546178769230769 ], [ 29.769464482758622, -1.546178769230769 ], [ 29.769464482758622, -1.545909292307692 ], [ 29.769195, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14473, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.545909292307692 ], [ 29.769464482758622, -1.546178769230769 ], [ 29.769733965517243, -1.546178769230769 ], [ 29.769733965517243, -1.545909292307692 ], [ 29.769464482758622, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14474, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.546178769230769 ], [ 29.754103965517242, -1.546448246153846 ], [ 29.754373448275864, -1.546448246153846 ], [ 29.754373448275864, -1.546178769230769 ], [ 29.754103965517242, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14475, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.546178769230769 ], [ 29.754373448275864, -1.546448246153846 ], [ 29.754642931034482, -1.546448246153846 ], [ 29.754642931034482, -1.546178769230769 ], [ 29.754373448275864, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14476, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.546178769230769 ], [ 29.754642931034482, -1.546448246153846 ], [ 29.754912413793104, -1.546448246153846 ], [ 29.754912413793104, -1.546178769230769 ], [ 29.754642931034482, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14477, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.546178769230769 ], [ 29.754912413793104, -1.546448246153846 ], [ 29.755181896551726, -1.546448246153846 ], [ 29.755181896551726, -1.546178769230769 ], [ 29.754912413793104, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14478, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.546178769230769 ], [ 29.755181896551726, -1.546448246153846 ], [ 29.755451379310344, -1.546448246153846 ], [ 29.755451379310344, -1.546178769230769 ], [ 29.755181896551726, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14479, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.546178769230769 ], [ 29.755451379310344, -1.546448246153846 ], [ 29.755720862068966, -1.546448246153846 ], [ 29.755720862068966, -1.546178769230769 ], [ 29.755451379310344, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14480, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.546178769230769 ], [ 29.755720862068966, -1.546448246153846 ], [ 29.755990344827588, -1.546448246153846 ], [ 29.755990344827588, -1.546178769230769 ], [ 29.755720862068966, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14481, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.546178769230769 ], [ 29.755990344827588, -1.546448246153846 ], [ 29.756259827586206, -1.546448246153846 ], [ 29.756259827586206, -1.546178769230769 ], [ 29.755990344827588, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14482, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.546178769230769 ], [ 29.756259827586206, -1.546448246153846 ], [ 29.756798793103449, -1.546448246153846 ], [ 29.756798793103449, -1.546178769230769 ], [ 29.756259827586206, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14483, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.546178769230769 ], [ 29.756798793103449, -1.546448246153846 ], [ 29.757068275862068, -1.546448246153846 ], [ 29.757068275862068, -1.546178769230769 ], [ 29.756798793103449, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14484, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.546178769230769 ], [ 29.757068275862068, -1.546448246153846 ], [ 29.75733775862069, -1.546448246153846 ], [ 29.75733775862069, -1.546178769230769 ], [ 29.757068275862068, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14485, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.546178769230769 ], [ 29.75733775862069, -1.546448246153846 ], [ 29.757607241379311, -1.546448246153846 ], [ 29.757607241379311, -1.546178769230769 ], [ 29.75733775862069, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14486, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.546178769230769 ], [ 29.757607241379311, -1.546448246153846 ], [ 29.757876724137933, -1.546448246153846 ], [ 29.757876724137933, -1.546178769230769 ], [ 29.757607241379311, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14487, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.546178769230769 ], [ 29.757876724137933, -1.546448246153846 ], [ 29.758146206896551, -1.546448246153846 ], [ 29.758146206896551, -1.546178769230769 ], [ 29.757876724137933, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14488, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.546178769230769 ], [ 29.758146206896551, -1.546448246153846 ], [ 29.758415689655173, -1.546448246153846 ], [ 29.758415689655173, -1.546178769230769 ], [ 29.758146206896551, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14489, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.546178769230769 ], [ 29.758415689655173, -1.546717723076923 ], [ 29.758685172413795, -1.546717723076923 ], [ 29.758685172413795, -1.546178769230769 ], [ 29.758415689655173, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14490, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.546178769230769 ], [ 29.758954655172413, -1.546448246153846 ], [ 29.759224137931035, -1.546448246153846 ], [ 29.759224137931035, -1.546178769230769 ], [ 29.758954655172413, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14491, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.546178769230769 ], [ 29.759224137931035, -1.546717723076923 ], [ 29.759493620689657, -1.546717723076923 ], [ 29.759493620689657, -1.546178769230769 ], [ 29.759224137931035, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14492, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.546178769230769 ], [ 29.759493620689657, -1.546717723076923 ], [ 29.759763103448275, -1.546717723076923 ], [ 29.759763103448275, -1.546178769230769 ], [ 29.759493620689657, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14493, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.546178769230769 ], [ 29.760032586206897, -1.546448246153846 ], [ 29.760302068965519, -1.546448246153846 ], [ 29.760302068965519, -1.546178769230769 ], [ 29.760032586206897, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14494, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.546178769230769 ], [ 29.760302068965519, -1.546717723076923 ], [ 29.760571551724137, -1.546717723076923 ], [ 29.760571551724137, -1.546178769230769 ], [ 29.760302068965519, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14495, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.546178769230769 ], [ 29.760571551724137, -1.546448246153846 ], [ 29.760841034482759, -1.546448246153846 ], [ 29.760841034482759, -1.546178769230769 ], [ 29.760571551724137, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14496, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.546178769230769 ], [ 29.760841034482759, -1.546448246153846 ], [ 29.761110517241381, -1.546448246153846 ], [ 29.761110517241381, -1.546178769230769 ], [ 29.760841034482759, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14497, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.546178769230769 ], [ 29.761110517241381, -1.546448246153846 ], [ 29.76138, -1.546448246153846 ], [ 29.76138, -1.546178769230769 ], [ 29.761110517241381, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14498, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.546178769230769 ], [ 29.76138, -1.546448246153846 ], [ 29.761649482758621, -1.546448246153846 ], [ 29.761649482758621, -1.546178769230769 ], [ 29.76138, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14499, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.546178769230769 ], [ 29.761649482758621, -1.546448246153846 ], [ 29.761918965517243, -1.546448246153846 ], [ 29.761918965517243, -1.546178769230769 ], [ 29.761649482758621, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14500, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.546178769230769 ], [ 29.762457931034483, -1.546448246153846 ], [ 29.762727413793105, -1.546448246153846 ], [ 29.762727413793105, -1.546178769230769 ], [ 29.762457931034483, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14501, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.546178769230769 ], [ 29.762727413793105, -1.546448246153846 ], [ 29.762996896551723, -1.546448246153846 ], [ 29.762996896551723, -1.546178769230769 ], [ 29.762727413793105, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14502, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.546178769230769 ], [ 29.762996896551723, -1.546448246153846 ], [ 29.763266379310345, -1.546448246153846 ], [ 29.763266379310345, -1.546178769230769 ], [ 29.762996896551723, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14503, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.546178769230769 ], [ 29.763266379310345, -1.546448246153846 ], [ 29.763535862068967, -1.546448246153846 ], [ 29.763535862068967, -1.546178769230769 ], [ 29.763266379310345, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14504, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.546178769230769 ], [ 29.763535862068967, -1.546448246153846 ], [ 29.763805344827588, -1.546448246153846 ], [ 29.763805344827588, -1.546178769230769 ], [ 29.763535862068967, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14505, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.546178769230769 ], [ 29.763805344827588, -1.546448246153846 ], [ 29.764074827586207, -1.546448246153846 ], [ 29.764074827586207, -1.546178769230769 ], [ 29.763805344827588, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14506, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.546178769230769 ], [ 29.765961206896552, -1.5469872 ], [ 29.766230689655174, -1.5469872 ], [ 29.766230689655174, -1.546178769230769 ], [ 29.765961206896552, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14507, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.546178769230769 ], [ 29.766769655172414, -1.547256676923077 ], [ 29.767039137931036, -1.547256676923077 ], [ 29.767039137931036, -1.546178769230769 ], [ 29.766769655172414, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14508, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.546178769230769 ], [ 29.767578103448276, -1.547526153846154 ], [ 29.767847586206898, -1.547526153846154 ], [ 29.767847586206898, -1.546178769230769 ], [ 29.767578103448276, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14509, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.546178769230769 ], [ 29.767847586206898, -1.547526153846154 ], [ 29.768117068965516, -1.547526153846154 ], [ 29.768117068965516, -1.546178769230769 ], [ 29.767847586206898, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14510, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.546178769230769 ], [ 29.768117068965516, -1.547526153846154 ], [ 29.768386551724138, -1.547526153846154 ], [ 29.768386551724138, -1.546178769230769 ], [ 29.768117068965516, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14511, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.546178769230769 ], [ 29.76865603448276, -1.547795630769231 ], [ 29.768925517241382, -1.547795630769231 ], [ 29.768925517241382, -1.546178769230769 ], [ 29.76865603448276, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14512, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.546178769230769 ], [ 29.768925517241382, -1.547795630769231 ], [ 29.769195, -1.547795630769231 ], [ 29.769195, -1.546178769230769 ], [ 29.768925517241382, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14513, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.546178769230769 ], [ 29.769195, -1.546448246153846 ], [ 29.769464482758622, -1.546448246153846 ], [ 29.769464482758622, -1.546178769230769 ], [ 29.769195, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14514, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769464482758622, -1.546178769230769 ], [ 29.769464482758622, -1.546448246153846 ], [ 29.769733965517243, -1.546448246153846 ], [ 29.769733965517243, -1.546178769230769 ], [ 29.769464482758622, -1.546178769230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14515, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.546448246153846 ], [ 29.754373448275864, -1.546717723076923 ], [ 29.754642931034482, -1.546717723076923 ], [ 29.754642931034482, -1.546448246153846 ], [ 29.754373448275864, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14516, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.546448246153846 ], [ 29.754642931034482, -1.546717723076923 ], [ 29.754912413793104, -1.546717723076923 ], [ 29.754912413793104, -1.546448246153846 ], [ 29.754642931034482, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14517, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.546448246153846 ], [ 29.754912413793104, -1.546717723076923 ], [ 29.755181896551726, -1.546717723076923 ], [ 29.755181896551726, -1.546448246153846 ], [ 29.754912413793104, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14518, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.546448246153846 ], [ 29.755181896551726, -1.546717723076923 ], [ 29.755451379310344, -1.546717723076923 ], [ 29.755451379310344, -1.546448246153846 ], [ 29.755181896551726, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14519, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.546448246153846 ], [ 29.755451379310344, -1.546717723076923 ], [ 29.755720862068966, -1.546717723076923 ], [ 29.755720862068966, -1.546448246153846 ], [ 29.755451379310344, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14520, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.546448246153846 ], [ 29.755720862068966, -1.546717723076923 ], [ 29.755990344827588, -1.546717723076923 ], [ 29.755990344827588, -1.546448246153846 ], [ 29.755720862068966, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14521, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.546448246153846 ], [ 29.755990344827588, -1.546717723076923 ], [ 29.756259827586206, -1.546717723076923 ], [ 29.756259827586206, -1.546448246153846 ], [ 29.755990344827588, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14522, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.546448246153846 ], [ 29.756259827586206, -1.546717723076923 ], [ 29.756798793103449, -1.546717723076923 ], [ 29.756798793103449, -1.546448246153846 ], [ 29.756259827586206, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14523, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.546448246153846 ], [ 29.756798793103449, -1.546717723076923 ], [ 29.757068275862068, -1.546717723076923 ], [ 29.757068275862068, -1.546448246153846 ], [ 29.756798793103449, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14524, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.546448246153846 ], [ 29.757068275862068, -1.546717723076923 ], [ 29.75733775862069, -1.546717723076923 ], [ 29.75733775862069, -1.546448246153846 ], [ 29.757068275862068, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14525, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.546448246153846 ], [ 29.75733775862069, -1.546717723076923 ], [ 29.757607241379311, -1.546717723076923 ], [ 29.757607241379311, -1.546448246153846 ], [ 29.75733775862069, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14526, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.546448246153846 ], [ 29.757607241379311, -1.546717723076923 ], [ 29.757876724137933, -1.546717723076923 ], [ 29.757876724137933, -1.546448246153846 ], [ 29.757607241379311, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14527, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.546448246153846 ], [ 29.757876724137933, -1.5469872 ], [ 29.758146206896551, -1.5469872 ], [ 29.758146206896551, -1.546448246153846 ], [ 29.757876724137933, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14528, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.546448246153846 ], [ 29.758685172413795, -1.5469872 ], [ 29.758954655172413, -1.5469872 ], [ 29.758954655172413, -1.546448246153846 ], [ 29.758685172413795, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14529, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.546448246153846 ], [ 29.759763103448275, -1.547256676923077 ], [ 29.760032586206897, -1.547256676923077 ], [ 29.760032586206897, -1.546448246153846 ], [ 29.759763103448275, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14530, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.546448246153846 ], [ 29.760571551724137, -1.5469872 ], [ 29.760841034482759, -1.5469872 ], [ 29.760841034482759, -1.546448246153846 ], [ 29.760571551724137, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14531, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.546448246153846 ], [ 29.76138, -1.546717723076923 ], [ 29.761649482758621, -1.546717723076923 ], [ 29.761649482758621, -1.546448246153846 ], [ 29.76138, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14532, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.546448246153846 ], [ 29.762188448275861, -1.546717723076923 ], [ 29.762457931034483, -1.546717723076923 ], [ 29.762457931034483, -1.546448246153846 ], [ 29.762188448275861, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14533, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.546448246153846 ], [ 29.762457931034483, -1.546717723076923 ], [ 29.762727413793105, -1.546717723076923 ], [ 29.762727413793105, -1.546448246153846 ], [ 29.762457931034483, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14534, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.546448246153846 ], [ 29.762727413793105, -1.546717723076923 ], [ 29.762996896551723, -1.546717723076923 ], [ 29.762996896551723, -1.546448246153846 ], [ 29.762727413793105, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14535, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.546448246153846 ], [ 29.762996896551723, -1.546717723076923 ], [ 29.763266379310345, -1.546717723076923 ], [ 29.763266379310345, -1.546448246153846 ], [ 29.762996896551723, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14536, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.546448246153846 ], [ 29.763266379310345, -1.546717723076923 ], [ 29.763535862068967, -1.546717723076923 ], [ 29.763535862068967, -1.546448246153846 ], [ 29.763266379310345, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14537, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.546448246153846 ], [ 29.763535862068967, -1.546717723076923 ], [ 29.763805344827588, -1.546717723076923 ], [ 29.763805344827588, -1.546448246153846 ], [ 29.763535862068967, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14538, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.546448246153846 ], [ 29.763805344827588, -1.546717723076923 ], [ 29.764074827586207, -1.546717723076923 ], [ 29.764074827586207, -1.546448246153846 ], [ 29.763805344827588, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14539, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.546448246153846 ], [ 29.764074827586207, -1.546717723076923 ], [ 29.764344310344828, -1.546717723076923 ], [ 29.764344310344828, -1.546448246153846 ], [ 29.764074827586207, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14540, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.546448246153846 ], [ 29.764344310344828, -1.546717723076923 ], [ 29.76461379310345, -1.546717723076923 ], [ 29.76461379310345, -1.546448246153846 ], [ 29.764344310344828, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14541, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.546448246153846 ], [ 29.76461379310345, -1.546717723076923 ], [ 29.764883275862069, -1.546717723076923 ], [ 29.764883275862069, -1.546448246153846 ], [ 29.76461379310345, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14542, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.546448246153846 ], [ 29.768386551724138, -1.547526153846154 ], [ 29.76865603448276, -1.547526153846154 ], [ 29.76865603448276, -1.546448246153846 ], [ 29.768386551724138, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14543, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769195, -1.546448246153846 ], [ 29.769195, -1.548065107692308 ], [ 29.769464482758622, -1.548065107692308 ], [ 29.769464482758622, -1.546448246153846 ], [ 29.769195, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14544, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.546717723076923 ], [ 29.754642931034482, -1.5469872 ], [ 29.754912413793104, -1.5469872 ], [ 29.754912413793104, -1.546717723076923 ], [ 29.754642931034482, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14545, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.546717723076923 ], [ 29.754912413793104, -1.5469872 ], [ 29.755181896551726, -1.5469872 ], [ 29.755181896551726, -1.546717723076923 ], [ 29.754912413793104, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14546, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.546717723076923 ], [ 29.755181896551726, -1.5469872 ], [ 29.755451379310344, -1.5469872 ], [ 29.755451379310344, -1.546717723076923 ], [ 29.755181896551726, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14547, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.546717723076923 ], [ 29.755451379310344, -1.5469872 ], [ 29.755720862068966, -1.5469872 ], [ 29.755720862068966, -1.546717723076923 ], [ 29.755451379310344, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14548, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.546717723076923 ], [ 29.755720862068966, -1.5469872 ], [ 29.755990344827588, -1.5469872 ], [ 29.755990344827588, -1.546717723076923 ], [ 29.755720862068966, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14549, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.546717723076923 ], [ 29.755990344827588, -1.547256676923077 ], [ 29.756259827586206, -1.547256676923077 ], [ 29.756259827586206, -1.546717723076923 ], [ 29.755990344827588, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14550, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.546717723076923 ], [ 29.756259827586206, -1.5469872 ], [ 29.756798793103449, -1.5469872 ], [ 29.756798793103449, -1.546717723076923 ], [ 29.756259827586206, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14551, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.546717723076923 ], [ 29.756798793103449, -1.5469872 ], [ 29.757068275862068, -1.5469872 ], [ 29.757068275862068, -1.546717723076923 ], [ 29.756798793103449, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14552, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.546717723076923 ], [ 29.757068275862068, -1.5469872 ], [ 29.75733775862069, -1.5469872 ], [ 29.75733775862069, -1.546717723076923 ], [ 29.757068275862068, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14553, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.545909292307692 ], [ 29.762727413793105, -1.545909292307692 ], [ 29.762727413793105, -1.546178769230769 ], [ 29.762457931034483, -1.546178769230769 ], [ 29.762457931034483, -1.546448246153846 ], [ 29.762188448275861, -1.546448246153846 ], [ 29.762188448275861, -1.546717723076923 ], [ 29.761918965517243, -1.546717723076923 ], [ 29.761918965517243, -1.5469872 ], [ 29.76138, -1.5469872 ], [ 29.76138, -1.546717723076923 ], [ 29.761649482758621, -1.546717723076923 ], [ 29.761649482758621, -1.546448246153846 ], [ 29.761918965517243, -1.546448246153846 ], [ 29.761918965517243, -1.546178769230769 ], [ 29.762188448275861, -1.546178769230769 ], [ 29.762188448275861, -1.545909292307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14554, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.546717723076923 ], [ 29.761918965517243, -1.5469872 ], [ 29.762188448275861, -1.5469872 ], [ 29.762188448275861, -1.546717723076923 ], [ 29.761918965517243, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14555, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.546717723076923 ], [ 29.762188448275861, -1.5469872 ], [ 29.762457931034483, -1.5469872 ], [ 29.762457931034483, -1.546717723076923 ], [ 29.762188448275861, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14556, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.546717723076923 ], [ 29.762457931034483, -1.5469872 ], [ 29.762727413793105, -1.5469872 ], [ 29.762727413793105, -1.546717723076923 ], [ 29.762457931034483, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14557, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.546717723076923 ], [ 29.762727413793105, -1.5469872 ], [ 29.762996896551723, -1.5469872 ], [ 29.762996896551723, -1.546717723076923 ], [ 29.762727413793105, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14558, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.546717723076923 ], [ 29.762996896551723, -1.5469872 ], [ 29.763266379310345, -1.5469872 ], [ 29.763266379310345, -1.546717723076923 ], [ 29.762996896551723, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14559, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.546717723076923 ], [ 29.763266379310345, -1.5469872 ], [ 29.763535862068967, -1.5469872 ], [ 29.763535862068967, -1.546717723076923 ], [ 29.763266379310345, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14560, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.546717723076923 ], [ 29.763535862068967, -1.5469872 ], [ 29.763805344827588, -1.5469872 ], [ 29.763805344827588, -1.546717723076923 ], [ 29.763535862068967, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14561, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.546717723076923 ], [ 29.763805344827588, -1.5469872 ], [ 29.764074827586207, -1.5469872 ], [ 29.764074827586207, -1.546717723076923 ], [ 29.763805344827588, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14562, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.546717723076923 ], [ 29.764074827586207, -1.5469872 ], [ 29.764344310344828, -1.5469872 ], [ 29.764344310344828, -1.546717723076923 ], [ 29.764074827586207, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14563, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.546717723076923 ], [ 29.764344310344828, -1.5469872 ], [ 29.76461379310345, -1.5469872 ], [ 29.76461379310345, -1.546717723076923 ], [ 29.764344310344828, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14564, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.546717723076923 ], [ 29.76461379310345, -1.5469872 ], [ 29.764883275862069, -1.5469872 ], [ 29.764883275862069, -1.546717723076923 ], [ 29.76461379310345, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14565, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.546717723076923 ], [ 29.764883275862069, -1.5469872 ], [ 29.76515275862069, -1.5469872 ], [ 29.76515275862069, -1.546717723076923 ], [ 29.764883275862069, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14566, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.546717723076923 ], [ 29.765422241379312, -1.547256676923077 ], [ 29.765961206896552, -1.547256676923077 ], [ 29.765961206896552, -1.546717723076923 ], [ 29.765422241379312, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14567, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.5469872 ], [ 29.754912413793104, -1.547256676923077 ], [ 29.755181896551726, -1.547256676923077 ], [ 29.755181896551726, -1.5469872 ], [ 29.754912413793104, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14568, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.5469872 ], [ 29.755181896551726, -1.547256676923077 ], [ 29.755451379310344, -1.547256676923077 ], [ 29.755451379310344, -1.5469872 ], [ 29.755181896551726, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14569, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.5469872 ], [ 29.755451379310344, -1.548065107692308 ], [ 29.755720862068966, -1.548065107692308 ], [ 29.755720862068966, -1.5469872 ], [ 29.755451379310344, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14570, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.5469872 ], [ 29.755720862068966, -1.548065107692308 ], [ 29.755990344827588, -1.548065107692308 ], [ 29.755990344827588, -1.5469872 ], [ 29.755720862068966, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14571, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.5469872 ], [ 29.756259827586206, -1.548065107692308 ], [ 29.756798793103449, -1.548065107692308 ], [ 29.756798793103449, -1.5469872 ], [ 29.756259827586206, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14572, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.5469872 ], [ 29.756798793103449, -1.548065107692308 ], [ 29.757068275862068, -1.548065107692308 ], [ 29.757068275862068, -1.5469872 ], [ 29.756798793103449, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14573, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.5469872 ], [ 29.757068275862068, -1.548065107692308 ], [ 29.75733775862069, -1.548065107692308 ], [ 29.75733775862069, -1.5469872 ], [ 29.757068275862068, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14574, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.5469872 ], [ 29.757876724137933, -1.548065107692308 ], [ 29.758146206896551, -1.548065107692308 ], [ 29.758146206896551, -1.5469872 ], [ 29.757876724137933, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14575, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5469872 ], [ 29.758685172413795, -1.548065107692308 ], [ 29.758954655172413, -1.548065107692308 ], [ 29.758954655172413, -1.5469872 ], [ 29.758685172413795, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14576, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.5469872 ], [ 29.760571551724137, -1.548065107692308 ], [ 29.760841034482759, -1.548065107692308 ], [ 29.760841034482759, -1.5469872 ], [ 29.760571551724137, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14577, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5469872 ], [ 29.76138, -1.547526153846154 ], [ 29.761649482758621, -1.547526153846154 ], [ 29.761649482758621, -1.547256676923077 ], [ 29.761918965517243, -1.547256676923077 ], [ 29.761918965517243, -1.5469872 ], [ 29.76138, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14578, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5469872 ], [ 29.761918965517243, -1.547256676923077 ], [ 29.762188448275861, -1.547256676923077 ], [ 29.762188448275861, -1.5469872 ], [ 29.761918965517243, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14579, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5469872 ], [ 29.762188448275861, -1.547256676923077 ], [ 29.762457931034483, -1.547256676923077 ], [ 29.762457931034483, -1.5469872 ], [ 29.762188448275861, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14580, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5469872 ], [ 29.762457931034483, -1.547256676923077 ], [ 29.762727413793105, -1.547256676923077 ], [ 29.762727413793105, -1.5469872 ], [ 29.762457931034483, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14581, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5469872 ], [ 29.762727413793105, -1.547256676923077 ], [ 29.762996896551723, -1.547256676923077 ], [ 29.762996896551723, -1.5469872 ], [ 29.762727413793105, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14582, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.5469872 ], [ 29.762996896551723, -1.547256676923077 ], [ 29.763266379310345, -1.547256676923077 ], [ 29.763266379310345, -1.5469872 ], [ 29.762996896551723, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14583, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5469872 ], [ 29.763266379310345, -1.547256676923077 ], [ 29.763535862068967, -1.547256676923077 ], [ 29.763535862068967, -1.5469872 ], [ 29.763266379310345, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14584, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5469872 ], [ 29.763535862068967, -1.547256676923077 ], [ 29.763805344827588, -1.547256676923077 ], [ 29.763805344827588, -1.5469872 ], [ 29.763535862068967, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14585, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5469872 ], [ 29.763805344827588, -1.547256676923077 ], [ 29.764074827586207, -1.547256676923077 ], [ 29.764074827586207, -1.5469872 ], [ 29.763805344827588, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14586, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5469872 ], [ 29.764074827586207, -1.547256676923077 ], [ 29.764344310344828, -1.547256676923077 ], [ 29.764344310344828, -1.5469872 ], [ 29.764074827586207, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14587, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.5469872 ], [ 29.764344310344828, -1.547256676923077 ], [ 29.76461379310345, -1.547256676923077 ], [ 29.76461379310345, -1.5469872 ], [ 29.764344310344828, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14588, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.5469872 ], [ 29.76461379310345, -1.547256676923077 ], [ 29.764883275862069, -1.547256676923077 ], [ 29.764883275862069, -1.5469872 ], [ 29.76461379310345, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14589, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.5469872 ], [ 29.764883275862069, -1.547256676923077 ], [ 29.76515275862069, -1.547256676923077 ], [ 29.76515275862069, -1.5469872 ], [ 29.764883275862069, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14590, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.5469872 ], [ 29.76515275862069, -1.547256676923077 ], [ 29.765422241379312, -1.547256676923077 ], [ 29.765422241379312, -1.5469872 ], [ 29.76515275862069, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14591, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.5469872 ], [ 29.765961206896552, -1.547526153846154 ], [ 29.766230689655174, -1.547526153846154 ], [ 29.766230689655174, -1.5469872 ], [ 29.765961206896552, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14592, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.5469872 ], [ 29.766230689655174, -1.547256676923077 ], [ 29.766500172413792, -1.547256676923077 ], [ 29.766500172413792, -1.5469872 ], [ 29.766230689655174, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14593, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5469872 ], [ 29.766500172413792, -1.547256676923077 ], [ 29.766769655172414, -1.547256676923077 ], [ 29.766769655172414, -1.5469872 ], [ 29.766500172413792, -1.5469872 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14594, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.547256676923077 ], [ 29.755181896551726, -1.547795630769231 ], [ 29.755451379310344, -1.547795630769231 ], [ 29.755451379310344, -1.547256676923077 ], [ 29.755181896551726, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14595, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.547256676923077 ], [ 29.755990344827588, -1.547795630769231 ], [ 29.756259827586206, -1.547795630769231 ], [ 29.756259827586206, -1.547256676923077 ], [ 29.755990344827588, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14596, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.547256676923077 ], [ 29.759763103448275, -1.547795630769231 ], [ 29.760032586206897, -1.547795630769231 ], [ 29.760032586206897, -1.547256676923077 ], [ 29.759763103448275, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14597, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.547256676923077 ], [ 29.761649482758621, -1.547526153846154 ], [ 29.761918965517243, -1.547526153846154 ], [ 29.761918965517243, -1.547256676923077 ], [ 29.761649482758621, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14598, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.547256676923077 ], [ 29.761918965517243, -1.547526153846154 ], [ 29.762188448275861, -1.547526153846154 ], [ 29.762188448275861, -1.547256676923077 ], [ 29.761918965517243, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14599, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.547256676923077 ], [ 29.762188448275861, -1.547526153846154 ], [ 29.762457931034483, -1.547526153846154 ], [ 29.762457931034483, -1.547256676923077 ], [ 29.762188448275861, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14600, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.547256676923077 ], [ 29.762457931034483, -1.547526153846154 ], [ 29.762727413793105, -1.547526153846154 ], [ 29.762727413793105, -1.547256676923077 ], [ 29.762457931034483, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14601, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.547256676923077 ], [ 29.762727413793105, -1.547526153846154 ], [ 29.762996896551723, -1.547526153846154 ], [ 29.762996896551723, -1.547256676923077 ], [ 29.762727413793105, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14602, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.547256676923077 ], [ 29.762996896551723, -1.547526153846154 ], [ 29.763266379310345, -1.547526153846154 ], [ 29.763266379310345, -1.547256676923077 ], [ 29.762996896551723, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14603, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.547256676923077 ], [ 29.763266379310345, -1.547526153846154 ], [ 29.763535862068967, -1.547526153846154 ], [ 29.763535862068967, -1.547256676923077 ], [ 29.763266379310345, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14604, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.547256676923077 ], [ 29.763535862068967, -1.547526153846154 ], [ 29.763805344827588, -1.547526153846154 ], [ 29.763805344827588, -1.547256676923077 ], [ 29.763535862068967, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14605, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.547256676923077 ], [ 29.763805344827588, -1.547526153846154 ], [ 29.764074827586207, -1.547526153846154 ], [ 29.764074827586207, -1.547256676923077 ], [ 29.763805344827588, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14606, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.547256676923077 ], [ 29.764074827586207, -1.547526153846154 ], [ 29.764344310344828, -1.547526153846154 ], [ 29.764344310344828, -1.547256676923077 ], [ 29.764074827586207, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14607, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.547256676923077 ], [ 29.764344310344828, -1.547526153846154 ], [ 29.76461379310345, -1.547526153846154 ], [ 29.76461379310345, -1.547256676923077 ], [ 29.764344310344828, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14608, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.547256676923077 ], [ 29.76461379310345, -1.547526153846154 ], [ 29.764883275862069, -1.547526153846154 ], [ 29.764883275862069, -1.547256676923077 ], [ 29.76461379310345, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14609, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.547256676923077 ], [ 29.764883275862069, -1.547526153846154 ], [ 29.76515275862069, -1.547526153846154 ], [ 29.76515275862069, -1.547256676923077 ], [ 29.764883275862069, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14610, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.547256676923077 ], [ 29.76515275862069, -1.547526153846154 ], [ 29.765422241379312, -1.547526153846154 ], [ 29.765422241379312, -1.547256676923077 ], [ 29.76515275862069, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14611, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.547256676923077 ], [ 29.765422241379312, -1.547526153846154 ], [ 29.765961206896552, -1.547526153846154 ], [ 29.765961206896552, -1.547256676923077 ], [ 29.765422241379312, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14612, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.547256676923077 ], [ 29.766230689655174, -1.547795630769231 ], [ 29.766500172413792, -1.547795630769231 ], [ 29.766500172413792, -1.547256676923077 ], [ 29.766230689655174, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14613, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.547256676923077 ], [ 29.766500172413792, -1.547795630769231 ], [ 29.766769655172414, -1.547795630769231 ], [ 29.766769655172414, -1.547256676923077 ], [ 29.766500172413792, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14614, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.547256676923077 ], [ 29.766769655172414, -1.547526153846154 ], [ 29.767039137931036, -1.547526153846154 ], [ 29.767039137931036, -1.547256676923077 ], [ 29.766769655172414, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14615, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.547256676923077 ], [ 29.767039137931036, -1.547526153846154 ], [ 29.767308620689654, -1.547526153846154 ], [ 29.767308620689654, -1.547256676923077 ], [ 29.767039137931036, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14616, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.547256676923077 ], [ 29.767308620689654, -1.547526153846154 ], [ 29.767578103448276, -1.547526153846154 ], [ 29.767578103448276, -1.547256676923077 ], [ 29.767308620689654, -1.547256676923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14617, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.547526153846154 ], [ 29.76138, -1.547795630769231 ], [ 29.761649482758621, -1.547795630769231 ], [ 29.761649482758621, -1.547526153846154 ], [ 29.76138, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14618, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.547526153846154 ], [ 29.761649482758621, -1.547795630769231 ], [ 29.761918965517243, -1.547795630769231 ], [ 29.761918965517243, -1.547526153846154 ], [ 29.761649482758621, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14619, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.547526153846154 ], [ 29.761918965517243, -1.547795630769231 ], [ 29.762188448275861, -1.547795630769231 ], [ 29.762188448275861, -1.547526153846154 ], [ 29.761918965517243, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14620, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.547526153846154 ], [ 29.762188448275861, -1.547795630769231 ], [ 29.762457931034483, -1.547795630769231 ], [ 29.762457931034483, -1.547526153846154 ], [ 29.762188448275861, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14621, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.547526153846154 ], [ 29.762457931034483, -1.547795630769231 ], [ 29.762727413793105, -1.547795630769231 ], [ 29.762727413793105, -1.547526153846154 ], [ 29.762457931034483, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14622, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.547526153846154 ], [ 29.762727413793105, -1.547795630769231 ], [ 29.762996896551723, -1.547795630769231 ], [ 29.762996896551723, -1.547526153846154 ], [ 29.762727413793105, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14623, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.547526153846154 ], [ 29.762996896551723, -1.547795630769231 ], [ 29.763266379310345, -1.547795630769231 ], [ 29.763266379310345, -1.547526153846154 ], [ 29.762996896551723, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14624, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.547526153846154 ], [ 29.763266379310345, -1.547795630769231 ], [ 29.763535862068967, -1.547795630769231 ], [ 29.763535862068967, -1.547526153846154 ], [ 29.763266379310345, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14625, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.547526153846154 ], [ 29.763535862068967, -1.547795630769231 ], [ 29.763805344827588, -1.547795630769231 ], [ 29.763805344827588, -1.547526153846154 ], [ 29.763535862068967, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14626, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.547526153846154 ], [ 29.763805344827588, -1.547795630769231 ], [ 29.764074827586207, -1.547795630769231 ], [ 29.764074827586207, -1.547526153846154 ], [ 29.763805344827588, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14627, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.547526153846154 ], [ 29.764074827586207, -1.547795630769231 ], [ 29.764344310344828, -1.547795630769231 ], [ 29.764344310344828, -1.547526153846154 ], [ 29.764074827586207, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14628, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.547526153846154 ], [ 29.764344310344828, -1.547795630769231 ], [ 29.76461379310345, -1.547795630769231 ], [ 29.76461379310345, -1.547526153846154 ], [ 29.764344310344828, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14629, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.547526153846154 ], [ 29.76461379310345, -1.547795630769231 ], [ 29.764883275862069, -1.547795630769231 ], [ 29.764883275862069, -1.547526153846154 ], [ 29.76461379310345, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14630, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.547526153846154 ], [ 29.764883275862069, -1.547795630769231 ], [ 29.76515275862069, -1.547795630769231 ], [ 29.76515275862069, -1.547526153846154 ], [ 29.764883275862069, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14631, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.547526153846154 ], [ 29.76515275862069, -1.547795630769231 ], [ 29.765422241379312, -1.547795630769231 ], [ 29.765422241379312, -1.547526153846154 ], [ 29.76515275862069, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14632, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.547526153846154 ], [ 29.765422241379312, -1.547795630769231 ], [ 29.765961206896552, -1.547795630769231 ], [ 29.765961206896552, -1.547526153846154 ], [ 29.765422241379312, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14633, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.547526153846154 ], [ 29.765961206896552, -1.547795630769231 ], [ 29.766230689655174, -1.547795630769231 ], [ 29.766230689655174, -1.547526153846154 ], [ 29.765961206896552, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14634, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.547526153846154 ], [ 29.767308620689654, -1.547795630769231 ], [ 29.767578103448276, -1.547795630769231 ], [ 29.767578103448276, -1.547526153846154 ], [ 29.767308620689654, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14635, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.547526153846154 ], [ 29.767578103448276, -1.547795630769231 ], [ 29.767847586206898, -1.547795630769231 ], [ 29.767847586206898, -1.547526153846154 ], [ 29.767578103448276, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14636, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.547526153846154 ], [ 29.767847586206898, -1.547795630769231 ], [ 29.768117068965516, -1.547795630769231 ], [ 29.768117068965516, -1.547526153846154 ], [ 29.767847586206898, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14637, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.547526153846154 ], [ 29.768117068965516, -1.548065107692308 ], [ 29.768386551724138, -1.548065107692308 ], [ 29.768386551724138, -1.547526153846154 ], [ 29.768117068965516, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14638, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.547526153846154 ], [ 29.768386551724138, -1.547795630769231 ], [ 29.76865603448276, -1.547795630769231 ], [ 29.76865603448276, -1.547526153846154 ], [ 29.768386551724138, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14639, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.547795630769231 ], [ 29.754912413793104, -1.548065107692308 ], [ 29.755181896551726, -1.548065107692308 ], [ 29.755181896551726, -1.547795630769231 ], [ 29.754912413793104, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14640, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.547795630769231 ], [ 29.755181896551726, -1.548065107692308 ], [ 29.755451379310344, -1.548065107692308 ], [ 29.755451379310344, -1.547795630769231 ], [ 29.755181896551726, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14641, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.547795630769231 ], [ 29.761649482758621, -1.548065107692308 ], [ 29.761918965517243, -1.548065107692308 ], [ 29.761918965517243, -1.547795630769231 ], [ 29.761649482758621, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14642, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.547795630769231 ], [ 29.761918965517243, -1.548065107692308 ], [ 29.762188448275861, -1.548065107692308 ], [ 29.762188448275861, -1.547795630769231 ], [ 29.761918965517243, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14643, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.547795630769231 ], [ 29.762188448275861, -1.548065107692308 ], [ 29.762457931034483, -1.548065107692308 ], [ 29.762457931034483, -1.547795630769231 ], [ 29.762188448275861, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14644, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.547795630769231 ], [ 29.762457931034483, -1.548065107692308 ], [ 29.762727413793105, -1.548065107692308 ], [ 29.762727413793105, -1.547795630769231 ], [ 29.762457931034483, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14645, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.547795630769231 ], [ 29.762727413793105, -1.548065107692308 ], [ 29.762996896551723, -1.548065107692308 ], [ 29.762996896551723, -1.547795630769231 ], [ 29.762727413793105, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14646, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.547795630769231 ], [ 29.762996896551723, -1.548065107692308 ], [ 29.763266379310345, -1.548065107692308 ], [ 29.763266379310345, -1.547795630769231 ], [ 29.762996896551723, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14647, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.547795630769231 ], [ 29.763266379310345, -1.548065107692308 ], [ 29.763535862068967, -1.548065107692308 ], [ 29.763535862068967, -1.547795630769231 ], [ 29.763266379310345, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14648, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.547795630769231 ], [ 29.763535862068967, -1.548065107692308 ], [ 29.763805344827588, -1.548065107692308 ], [ 29.763805344827588, -1.547795630769231 ], [ 29.763535862068967, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14649, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.547795630769231 ], [ 29.763805344827588, -1.548065107692308 ], [ 29.764074827586207, -1.548065107692308 ], [ 29.764074827586207, -1.547795630769231 ], [ 29.763805344827588, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14650, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.547795630769231 ], [ 29.764074827586207, -1.548065107692308 ], [ 29.764344310344828, -1.548065107692308 ], [ 29.764344310344828, -1.547795630769231 ], [ 29.764074827586207, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14651, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.547795630769231 ], [ 29.764344310344828, -1.548065107692308 ], [ 29.76461379310345, -1.548065107692308 ], [ 29.76461379310345, -1.547795630769231 ], [ 29.764344310344828, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14652, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.547795630769231 ], [ 29.76461379310345, -1.548065107692308 ], [ 29.764883275862069, -1.548065107692308 ], [ 29.764883275862069, -1.547795630769231 ], [ 29.76461379310345, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14653, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.547795630769231 ], [ 29.764883275862069, -1.548065107692308 ], [ 29.76515275862069, -1.548065107692308 ], [ 29.76515275862069, -1.547795630769231 ], [ 29.764883275862069, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14654, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.547795630769231 ], [ 29.76515275862069, -1.548065107692308 ], [ 29.765422241379312, -1.548065107692308 ], [ 29.765422241379312, -1.547795630769231 ], [ 29.76515275862069, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14655, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.547795630769231 ], [ 29.765422241379312, -1.548065107692308 ], [ 29.765961206896552, -1.548065107692308 ], [ 29.765961206896552, -1.547795630769231 ], [ 29.765422241379312, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14656, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.547795630769231 ], [ 29.765961206896552, -1.548065107692308 ], [ 29.766230689655174, -1.548065107692308 ], [ 29.766230689655174, -1.547795630769231 ], [ 29.765961206896552, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14657, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.547795630769231 ], [ 29.766230689655174, -1.548065107692308 ], [ 29.766500172413792, -1.548065107692308 ], [ 29.766500172413792, -1.547795630769231 ], [ 29.766230689655174, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14658, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.547795630769231 ], [ 29.766500172413792, -1.548065107692308 ], [ 29.766769655172414, -1.548065107692308 ], [ 29.766769655172414, -1.547795630769231 ], [ 29.766500172413792, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14659, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.547795630769231 ], [ 29.768925517241382, -1.548065107692308 ], [ 29.769195, -1.548065107692308 ], [ 29.769195, -1.547795630769231 ], [ 29.768925517241382, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14660, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.546448246153846 ], [ 29.758146206896551, -1.548604061538462 ], [ 29.758415689655173, -1.548604061538462 ], [ 29.758415689655173, -1.546448246153846 ], [ 29.758146206896551, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14661, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.546448246153846 ], [ 29.758954655172413, -1.548604061538462 ], [ 29.759224137931035, -1.548604061538462 ], [ 29.759224137931035, -1.546448246153846 ], [ 29.758954655172413, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14662, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.546448246153846 ], [ 29.760032586206897, -1.548604061538462 ], [ 29.760302068965519, -1.548604061538462 ], [ 29.760302068965519, -1.546448246153846 ], [ 29.760032586206897, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14663, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.546448246153846 ], [ 29.760841034482759, -1.548604061538462 ], [ 29.761110517241381, -1.548604061538462 ], [ 29.761110517241381, -1.546448246153846 ], [ 29.760841034482759, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14664, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.546448246153846 ], [ 29.761110517241381, -1.548604061538462 ], [ 29.76138, -1.548604061538462 ], [ 29.76138, -1.548065107692308 ], [ 29.761649482758621, -1.548065107692308 ], [ 29.761649482758621, -1.547795630769231 ], [ 29.76138, -1.547795630769231 ], [ 29.76138, -1.546448246153846 ], [ 29.761110517241381, -1.546448246153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14665, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.546717723076923 ], [ 29.75733775862069, -1.548334584615385 ], [ 29.757607241379311, -1.548334584615385 ], [ 29.757607241379311, -1.546717723076923 ], [ 29.75733775862069, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14666, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.546717723076923 ], [ 29.757607241379311, -1.548334584615385 ], [ 29.757876724137933, -1.548334584615385 ], [ 29.757876724137933, -1.546717723076923 ], [ 29.757607241379311, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14667, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.546717723076923 ], [ 29.758415689655173, -1.548334584615385 ], [ 29.758685172413795, -1.548334584615385 ], [ 29.758685172413795, -1.546717723076923 ], [ 29.758415689655173, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14668, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.546717723076923 ], [ 29.759224137931035, -1.548334584615385 ], [ 29.759493620689657, -1.548334584615385 ], [ 29.759493620689657, -1.546717723076923 ], [ 29.759224137931035, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14669, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.546717723076923 ], [ 29.759493620689657, -1.548334584615385 ], [ 29.759763103448275, -1.548334584615385 ], [ 29.759763103448275, -1.546717723076923 ], [ 29.759493620689657, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14670, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.546717723076923 ], [ 29.760302068965519, -1.548334584615385 ], [ 29.760571551724137, -1.548334584615385 ], [ 29.760571551724137, -1.546717723076923 ], [ 29.760302068965519, -1.546717723076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14671, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.547526153846154 ], [ 29.767039137931036, -1.548334584615385 ], [ 29.767308620689654, -1.548334584615385 ], [ 29.767308620689654, -1.547526153846154 ], [ 29.767039137931036, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14672, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.547795630769231 ], [ 29.755990344827588, -1.548334584615385 ], [ 29.756259827586206, -1.548334584615385 ], [ 29.756259827586206, -1.547795630769231 ], [ 29.755990344827588, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14673, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.547795630769231 ], [ 29.759763103448275, -1.548604061538462 ], [ 29.760032586206897, -1.548604061538462 ], [ 29.760032586206897, -1.547795630769231 ], [ 29.759763103448275, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14674, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.547795630769231 ], [ 29.767308620689654, -1.548604061538462 ], [ 29.767578103448276, -1.548604061538462 ], [ 29.767578103448276, -1.547795630769231 ], [ 29.767308620689654, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14675, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.547795630769231 ], [ 29.767578103448276, -1.548873538461538 ], [ 29.767847586206898, -1.548873538461538 ], [ 29.767847586206898, -1.547795630769231 ], [ 29.767578103448276, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14676, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.547795630769231 ], [ 29.768386551724138, -1.549412492307692 ], [ 29.76865603448276, -1.549412492307692 ], [ 29.76865603448276, -1.547795630769231 ], [ 29.768386551724138, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14677, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.547795630769231 ], [ 29.76865603448276, -1.549412492307692 ], [ 29.768925517241382, -1.549412492307692 ], [ 29.768925517241382, -1.547795630769231 ], [ 29.76865603448276, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14678, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.548065107692308 ], [ 29.754642931034482, -1.548334584615385 ], [ 29.754912413793104, -1.548334584615385 ], [ 29.754912413793104, -1.548065107692308 ], [ 29.754642931034482, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14679, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.548065107692308 ], [ 29.754912413793104, -1.548334584615385 ], [ 29.755181896551726, -1.548334584615385 ], [ 29.755181896551726, -1.548065107692308 ], [ 29.754912413793104, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14680, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.548065107692308 ], [ 29.755181896551726, -1.548334584615385 ], [ 29.755451379310344, -1.548334584615385 ], [ 29.755451379310344, -1.548065107692308 ], [ 29.755181896551726, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14681, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.548065107692308 ], [ 29.755451379310344, -1.548334584615385 ], [ 29.755720862068966, -1.548334584615385 ], [ 29.755720862068966, -1.548065107692308 ], [ 29.755451379310344, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14682, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.548065107692308 ], [ 29.755720862068966, -1.548334584615385 ], [ 29.755990344827588, -1.548334584615385 ], [ 29.755990344827588, -1.548065107692308 ], [ 29.755720862068966, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14683, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.548065107692308 ], [ 29.756259827586206, -1.548334584615385 ], [ 29.756798793103449, -1.548334584615385 ], [ 29.756798793103449, -1.548065107692308 ], [ 29.756259827586206, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14684, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.548065107692308 ], [ 29.756798793103449, -1.548334584615385 ], [ 29.757068275862068, -1.548334584615385 ], [ 29.757068275862068, -1.548065107692308 ], [ 29.756798793103449, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14685, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.548065107692308 ], [ 29.757068275862068, -1.548334584615385 ], [ 29.75733775862069, -1.548334584615385 ], [ 29.75733775862069, -1.548065107692308 ], [ 29.757068275862068, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14686, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.548065107692308 ], [ 29.757876724137933, -1.548604061538462 ], [ 29.758146206896551, -1.548604061538462 ], [ 29.758146206896551, -1.548065107692308 ], [ 29.757876724137933, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14687, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.548065107692308 ], [ 29.758685172413795, -1.548604061538462 ], [ 29.758954655172413, -1.548604061538462 ], [ 29.758954655172413, -1.548065107692308 ], [ 29.758685172413795, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14688, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.548065107692308 ], [ 29.760571551724137, -1.548873538461538 ], [ 29.760841034482759, -1.548873538461538 ], [ 29.760841034482759, -1.548065107692308 ], [ 29.760571551724137, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14689, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.548065107692308 ], [ 29.76138, -1.548334584615385 ], [ 29.761649482758621, -1.548334584615385 ], [ 29.761649482758621, -1.548065107692308 ], [ 29.76138, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14690, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.548065107692308 ], [ 29.761649482758621, -1.548334584615385 ], [ 29.761918965517243, -1.548334584615385 ], [ 29.761918965517243, -1.548065107692308 ], [ 29.761649482758621, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14691, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.548065107692308 ], [ 29.761918965517243, -1.548334584615385 ], [ 29.762188448275861, -1.548334584615385 ], [ 29.762188448275861, -1.548065107692308 ], [ 29.761918965517243, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14692, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.548065107692308 ], [ 29.762188448275861, -1.548334584615385 ], [ 29.762457931034483, -1.548334584615385 ], [ 29.762457931034483, -1.548065107692308 ], [ 29.762188448275861, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14693, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.548065107692308 ], [ 29.762457931034483, -1.548334584615385 ], [ 29.762727413793105, -1.548334584615385 ], [ 29.762727413793105, -1.548065107692308 ], [ 29.762457931034483, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14694, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.548065107692308 ], [ 29.762727413793105, -1.548334584615385 ], [ 29.762996896551723, -1.548334584615385 ], [ 29.762996896551723, -1.548065107692308 ], [ 29.762727413793105, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14695, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.548065107692308 ], [ 29.762996896551723, -1.548334584615385 ], [ 29.763266379310345, -1.548334584615385 ], [ 29.763266379310345, -1.548065107692308 ], [ 29.762996896551723, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14696, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.548065107692308 ], [ 29.763266379310345, -1.548334584615385 ], [ 29.763535862068967, -1.548334584615385 ], [ 29.763535862068967, -1.548065107692308 ], [ 29.763266379310345, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14697, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.548065107692308 ], [ 29.763535862068967, -1.548334584615385 ], [ 29.763805344827588, -1.548334584615385 ], [ 29.763805344827588, -1.548065107692308 ], [ 29.763535862068967, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14698, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.548065107692308 ], [ 29.763805344827588, -1.548334584615385 ], [ 29.764074827586207, -1.548334584615385 ], [ 29.764074827586207, -1.548065107692308 ], [ 29.763805344827588, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14699, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.548065107692308 ], [ 29.764074827586207, -1.548334584615385 ], [ 29.764344310344828, -1.548334584615385 ], [ 29.764344310344828, -1.548065107692308 ], [ 29.764074827586207, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14700, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.548065107692308 ], [ 29.764344310344828, -1.548334584615385 ], [ 29.76461379310345, -1.548334584615385 ], [ 29.76461379310345, -1.548065107692308 ], [ 29.764344310344828, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14701, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.548065107692308 ], [ 29.76461379310345, -1.548334584615385 ], [ 29.764883275862069, -1.548334584615385 ], [ 29.764883275862069, -1.548065107692308 ], [ 29.76461379310345, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14702, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.548065107692308 ], [ 29.764883275862069, -1.548334584615385 ], [ 29.76515275862069, -1.548334584615385 ], [ 29.76515275862069, -1.548065107692308 ], [ 29.764883275862069, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14703, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.548065107692308 ], [ 29.76515275862069, -1.548334584615385 ], [ 29.765422241379312, -1.548334584615385 ], [ 29.765422241379312, -1.548065107692308 ], [ 29.76515275862069, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14704, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.548065107692308 ], [ 29.765422241379312, -1.548334584615385 ], [ 29.765961206896552, -1.548334584615385 ], [ 29.765961206896552, -1.548065107692308 ], [ 29.765422241379312, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14705, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.548065107692308 ], [ 29.765961206896552, -1.548334584615385 ], [ 29.766230689655174, -1.548334584615385 ], [ 29.766230689655174, -1.548065107692308 ], [ 29.765961206896552, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14706, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.548065107692308 ], [ 29.766230689655174, -1.548334584615385 ], [ 29.766500172413792, -1.548334584615385 ], [ 29.766500172413792, -1.548065107692308 ], [ 29.766230689655174, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14707, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.547526153846154 ], [ 29.767039137931036, -1.547526153846154 ], [ 29.767039137931036, -1.548334584615385 ], [ 29.766500172413792, -1.548334584615385 ], [ 29.766500172413792, -1.548065107692308 ], [ 29.766769655172414, -1.548065107692308 ], [ 29.766769655172414, -1.547526153846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14708, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.548065107692308 ], [ 29.768117068965516, -1.549143015384615 ], [ 29.768386551724138, -1.549143015384615 ], [ 29.768386551724138, -1.548065107692308 ], [ 29.768117068965516, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14709, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768925517241382, -1.548065107692308 ], [ 29.768925517241382, -1.549681969230769 ], [ 29.769195, -1.549681969230769 ], [ 29.769195, -1.548065107692308 ], [ 29.768925517241382, -1.548065107692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14710, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.548334584615385 ], [ 29.754373448275864, -1.548604061538462 ], [ 29.754642931034482, -1.548604061538462 ], [ 29.754642931034482, -1.548334584615385 ], [ 29.754373448275864, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14711, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.548334584615385 ], [ 29.754642931034482, -1.548604061538462 ], [ 29.754912413793104, -1.548604061538462 ], [ 29.754912413793104, -1.548334584615385 ], [ 29.754642931034482, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14712, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.548334584615385 ], [ 29.754912413793104, -1.548604061538462 ], [ 29.755181896551726, -1.548604061538462 ], [ 29.755181896551726, -1.548334584615385 ], [ 29.754912413793104, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14713, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.548334584615385 ], [ 29.755181896551726, -1.548604061538462 ], [ 29.755451379310344, -1.548604061538462 ], [ 29.755451379310344, -1.548334584615385 ], [ 29.755181896551726, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14714, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.548334584615385 ], [ 29.755451379310344, -1.548604061538462 ], [ 29.755720862068966, -1.548604061538462 ], [ 29.755720862068966, -1.548334584615385 ], [ 29.755451379310344, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14715, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.548334584615385 ], [ 29.755720862068966, -1.548604061538462 ], [ 29.755990344827588, -1.548604061538462 ], [ 29.755990344827588, -1.548334584615385 ], [ 29.755720862068966, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14716, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.548334584615385 ], [ 29.755990344827588, -1.548604061538462 ], [ 29.756259827586206, -1.548604061538462 ], [ 29.756259827586206, -1.548334584615385 ], [ 29.755990344827588, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14717, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.548334584615385 ], [ 29.756259827586206, -1.548604061538462 ], [ 29.756798793103449, -1.548604061538462 ], [ 29.756798793103449, -1.548334584615385 ], [ 29.756259827586206, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14718, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.548334584615385 ], [ 29.756798793103449, -1.548604061538462 ], [ 29.757068275862068, -1.548604061538462 ], [ 29.757068275862068, -1.548334584615385 ], [ 29.756798793103449, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14719, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.548334584615385 ], [ 29.757068275862068, -1.548604061538462 ], [ 29.75733775862069, -1.548604061538462 ], [ 29.75733775862069, -1.548334584615385 ], [ 29.757068275862068, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14720, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.548334584615385 ], [ 29.75733775862069, -1.548604061538462 ], [ 29.757607241379311, -1.548604061538462 ], [ 29.757607241379311, -1.548334584615385 ], [ 29.75733775862069, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14721, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.548334584615385 ], [ 29.757607241379311, -1.548604061538462 ], [ 29.757876724137933, -1.548604061538462 ], [ 29.757876724137933, -1.548334584615385 ], [ 29.757607241379311, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14722, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.548334584615385 ], [ 29.758415689655173, -1.548873538461538 ], [ 29.758685172413795, -1.548873538461538 ], [ 29.758685172413795, -1.548334584615385 ], [ 29.758415689655173, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14723, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.548334584615385 ], [ 29.759224137931035, -1.548873538461538 ], [ 29.759493620689657, -1.548873538461538 ], [ 29.759493620689657, -1.548334584615385 ], [ 29.759224137931035, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14724, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.548334584615385 ], [ 29.759493620689657, -1.548873538461538 ], [ 29.759763103448275, -1.548873538461538 ], [ 29.759763103448275, -1.548334584615385 ], [ 29.759493620689657, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14725, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.548334584615385 ], [ 29.760302068965519, -1.548873538461538 ], [ 29.760571551724137, -1.548873538461538 ], [ 29.760571551724137, -1.548334584615385 ], [ 29.760302068965519, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14726, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.548334584615385 ], [ 29.76138, -1.548604061538462 ], [ 29.761649482758621, -1.548604061538462 ], [ 29.761649482758621, -1.548334584615385 ], [ 29.76138, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14727, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.548334584615385 ], [ 29.761649482758621, -1.548604061538462 ], [ 29.761918965517243, -1.548604061538462 ], [ 29.761918965517243, -1.548334584615385 ], [ 29.761649482758621, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14728, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.548334584615385 ], [ 29.761918965517243, -1.548604061538462 ], [ 29.762188448275861, -1.548604061538462 ], [ 29.762188448275861, -1.548334584615385 ], [ 29.761918965517243, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14729, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.548334584615385 ], [ 29.762188448275861, -1.548604061538462 ], [ 29.762457931034483, -1.548604061538462 ], [ 29.762457931034483, -1.548334584615385 ], [ 29.762188448275861, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14730, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.548334584615385 ], [ 29.762457931034483, -1.548604061538462 ], [ 29.762727413793105, -1.548604061538462 ], [ 29.762727413793105, -1.548334584615385 ], [ 29.762457931034483, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14731, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.548334584615385 ], [ 29.762727413793105, -1.548604061538462 ], [ 29.762996896551723, -1.548604061538462 ], [ 29.762996896551723, -1.548334584615385 ], [ 29.762727413793105, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14732, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.548334584615385 ], [ 29.762996896551723, -1.548604061538462 ], [ 29.763266379310345, -1.548604061538462 ], [ 29.763266379310345, -1.548334584615385 ], [ 29.762996896551723, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14733, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.548334584615385 ], [ 29.763266379310345, -1.548604061538462 ], [ 29.763535862068967, -1.548604061538462 ], [ 29.763535862068967, -1.548334584615385 ], [ 29.763266379310345, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14734, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.548334584615385 ], [ 29.763535862068967, -1.548604061538462 ], [ 29.763805344827588, -1.548604061538462 ], [ 29.763805344827588, -1.548334584615385 ], [ 29.763535862068967, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14735, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.548334584615385 ], [ 29.763805344827588, -1.548604061538462 ], [ 29.764074827586207, -1.548604061538462 ], [ 29.764074827586207, -1.548334584615385 ], [ 29.763805344827588, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14736, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.548334584615385 ], [ 29.764074827586207, -1.548604061538462 ], [ 29.764344310344828, -1.548604061538462 ], [ 29.764344310344828, -1.548334584615385 ], [ 29.764074827586207, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14737, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.548334584615385 ], [ 29.764344310344828, -1.548604061538462 ], [ 29.76461379310345, -1.548604061538462 ], [ 29.76461379310345, -1.548334584615385 ], [ 29.764344310344828, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14738, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.548334584615385 ], [ 29.76461379310345, -1.548604061538462 ], [ 29.764883275862069, -1.548604061538462 ], [ 29.764883275862069, -1.548334584615385 ], [ 29.76461379310345, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14739, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.548334584615385 ], [ 29.764883275862069, -1.548604061538462 ], [ 29.76515275862069, -1.548604061538462 ], [ 29.76515275862069, -1.548334584615385 ], [ 29.764883275862069, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14740, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.548334584615385 ], [ 29.76515275862069, -1.548604061538462 ], [ 29.765422241379312, -1.548604061538462 ], [ 29.765422241379312, -1.548334584615385 ], [ 29.76515275862069, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14741, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.548334584615385 ], [ 29.765422241379312, -1.548604061538462 ], [ 29.765961206896552, -1.548604061538462 ], [ 29.765961206896552, -1.548334584615385 ], [ 29.765422241379312, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14742, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.548334584615385 ], [ 29.765961206896552, -1.548604061538462 ], [ 29.766230689655174, -1.548604061538462 ], [ 29.766230689655174, -1.548334584615385 ], [ 29.765961206896552, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14743, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.548334584615385 ], [ 29.766230689655174, -1.548604061538462 ], [ 29.766500172413792, -1.548604061538462 ], [ 29.766500172413792, -1.548334584615385 ], [ 29.766230689655174, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14744, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.548334584615385 ], [ 29.766500172413792, -1.548604061538462 ], [ 29.767039137931036, -1.548604061538462 ], [ 29.767039137931036, -1.548334584615385 ], [ 29.766500172413792, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14745, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.548334584615385 ], [ 29.767039137931036, -1.548604061538462 ], [ 29.767308620689654, -1.548604061538462 ], [ 29.767308620689654, -1.548334584615385 ], [ 29.767039137931036, -1.548334584615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14746, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.548604061538462 ], [ 29.754103965517242, -1.549143015384615 ], [ 29.754373448275864, -1.549143015384615 ], [ 29.754373448275864, -1.548604061538462 ], [ 29.754103965517242, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14747, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.548604061538462 ], [ 29.754373448275864, -1.548873538461538 ], [ 29.754642931034482, -1.548873538461538 ], [ 29.754642931034482, -1.548604061538462 ], [ 29.754373448275864, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14748, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.548604061538462 ], [ 29.754642931034482, -1.548873538461538 ], [ 29.754912413793104, -1.548873538461538 ], [ 29.754912413793104, -1.548604061538462 ], [ 29.754642931034482, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14749, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.548604061538462 ], [ 29.754912413793104, -1.548873538461538 ], [ 29.755181896551726, -1.548873538461538 ], [ 29.755181896551726, -1.548604061538462 ], [ 29.754912413793104, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14750, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.548604061538462 ], [ 29.755181896551726, -1.548873538461538 ], [ 29.755451379310344, -1.548873538461538 ], [ 29.755451379310344, -1.548604061538462 ], [ 29.755181896551726, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14751, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.548604061538462 ], [ 29.755451379310344, -1.548873538461538 ], [ 29.755720862068966, -1.548873538461538 ], [ 29.755720862068966, -1.548604061538462 ], [ 29.755451379310344, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14752, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.548604061538462 ], [ 29.755720862068966, -1.548873538461538 ], [ 29.755990344827588, -1.548873538461538 ], [ 29.755990344827588, -1.548604061538462 ], [ 29.755720862068966, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14753, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.548604061538462 ], [ 29.755990344827588, -1.548873538461538 ], [ 29.756259827586206, -1.548873538461538 ], [ 29.756259827586206, -1.548604061538462 ], [ 29.755990344827588, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14754, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.548604061538462 ], [ 29.756259827586206, -1.548873538461538 ], [ 29.756798793103449, -1.548873538461538 ], [ 29.756798793103449, -1.548604061538462 ], [ 29.756259827586206, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14755, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.548604061538462 ], [ 29.756798793103449, -1.548873538461538 ], [ 29.757068275862068, -1.548873538461538 ], [ 29.757068275862068, -1.548604061538462 ], [ 29.756798793103449, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14756, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.548604061538462 ], [ 29.757068275862068, -1.548873538461538 ], [ 29.75733775862069, -1.548873538461538 ], [ 29.75733775862069, -1.548604061538462 ], [ 29.757068275862068, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14757, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.548604061538462 ], [ 29.75733775862069, -1.548873538461538 ], [ 29.757607241379311, -1.548873538461538 ], [ 29.757607241379311, -1.548604061538462 ], [ 29.75733775862069, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14758, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.548604061538462 ], [ 29.757607241379311, -1.548873538461538 ], [ 29.757876724137933, -1.548873538461538 ], [ 29.757876724137933, -1.548604061538462 ], [ 29.757607241379311, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14759, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.548604061538462 ], [ 29.757876724137933, -1.548873538461538 ], [ 29.758146206896551, -1.548873538461538 ], [ 29.758146206896551, -1.548604061538462 ], [ 29.757876724137933, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14760, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.548604061538462 ], [ 29.758146206896551, -1.548873538461538 ], [ 29.758415689655173, -1.548873538461538 ], [ 29.758415689655173, -1.548604061538462 ], [ 29.758146206896551, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14761, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.548604061538462 ], [ 29.758685172413795, -1.549143015384615 ], [ 29.758954655172413, -1.549143015384615 ], [ 29.758954655172413, -1.548604061538462 ], [ 29.758685172413795, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14762, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.548604061538462 ], [ 29.758954655172413, -1.548873538461538 ], [ 29.759224137931035, -1.548873538461538 ], [ 29.759224137931035, -1.548604061538462 ], [ 29.758954655172413, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14763, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.548604061538462 ], [ 29.759763103448275, -1.549143015384615 ], [ 29.760032586206897, -1.549143015384615 ], [ 29.760032586206897, -1.548604061538462 ], [ 29.759763103448275, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14764, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.548604061538462 ], [ 29.760032586206897, -1.549143015384615 ], [ 29.760302068965519, -1.549143015384615 ], [ 29.760302068965519, -1.548604061538462 ], [ 29.760032586206897, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14765, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.548604061538462 ], [ 29.760841034482759, -1.549412492307692 ], [ 29.761110517241381, -1.549412492307692 ], [ 29.761110517241381, -1.548873538461538 ], [ 29.76138, -1.548873538461538 ], [ 29.76138, -1.548604061538462 ], [ 29.760841034482759, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14766, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.548604061538462 ], [ 29.76138, -1.548873538461538 ], [ 29.761649482758621, -1.548873538461538 ], [ 29.761649482758621, -1.548604061538462 ], [ 29.76138, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14767, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.548604061538462 ], [ 29.761649482758621, -1.548873538461538 ], [ 29.761918965517243, -1.548873538461538 ], [ 29.761918965517243, -1.548604061538462 ], [ 29.761649482758621, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14768, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.548604061538462 ], [ 29.761918965517243, -1.548873538461538 ], [ 29.762188448275861, -1.548873538461538 ], [ 29.762188448275861, -1.548604061538462 ], [ 29.761918965517243, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14769, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.548604061538462 ], [ 29.762188448275861, -1.548873538461538 ], [ 29.762457931034483, -1.548873538461538 ], [ 29.762457931034483, -1.548604061538462 ], [ 29.762188448275861, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14770, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.548604061538462 ], [ 29.762457931034483, -1.548873538461538 ], [ 29.762727413793105, -1.548873538461538 ], [ 29.762727413793105, -1.548604061538462 ], [ 29.762457931034483, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14771, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.548604061538462 ], [ 29.762727413793105, -1.548873538461538 ], [ 29.762996896551723, -1.548873538461538 ], [ 29.762996896551723, -1.548604061538462 ], [ 29.762727413793105, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14772, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.548604061538462 ], [ 29.762996896551723, -1.548873538461538 ], [ 29.763266379310345, -1.548873538461538 ], [ 29.763266379310345, -1.548604061538462 ], [ 29.762996896551723, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14773, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.548604061538462 ], [ 29.763266379310345, -1.548873538461538 ], [ 29.763535862068967, -1.548873538461538 ], [ 29.763535862068967, -1.548604061538462 ], [ 29.763266379310345, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14774, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.548604061538462 ], [ 29.763535862068967, -1.548873538461538 ], [ 29.763805344827588, -1.548873538461538 ], [ 29.763805344827588, -1.548604061538462 ], [ 29.763535862068967, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14775, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.548604061538462 ], [ 29.763805344827588, -1.548873538461538 ], [ 29.764074827586207, -1.548873538461538 ], [ 29.764074827586207, -1.548604061538462 ], [ 29.763805344827588, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14776, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.548604061538462 ], [ 29.764074827586207, -1.548873538461538 ], [ 29.764344310344828, -1.548873538461538 ], [ 29.764344310344828, -1.548604061538462 ], [ 29.764074827586207, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14777, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.548604061538462 ], [ 29.764344310344828, -1.548873538461538 ], [ 29.76461379310345, -1.548873538461538 ], [ 29.76461379310345, -1.548604061538462 ], [ 29.764344310344828, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14778, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.548604061538462 ], [ 29.76461379310345, -1.548873538461538 ], [ 29.764883275862069, -1.548873538461538 ], [ 29.764883275862069, -1.548604061538462 ], [ 29.76461379310345, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14779, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.548604061538462 ], [ 29.764883275862069, -1.548873538461538 ], [ 29.76515275862069, -1.548873538461538 ], [ 29.76515275862069, -1.548604061538462 ], [ 29.764883275862069, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14780, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.548604061538462 ], [ 29.76515275862069, -1.548873538461538 ], [ 29.765422241379312, -1.548873538461538 ], [ 29.765422241379312, -1.548604061538462 ], [ 29.76515275862069, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14781, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.548604061538462 ], [ 29.765422241379312, -1.548873538461538 ], [ 29.765961206896552, -1.548873538461538 ], [ 29.765961206896552, -1.548604061538462 ], [ 29.765422241379312, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14782, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.548604061538462 ], [ 29.765961206896552, -1.548873538461538 ], [ 29.766230689655174, -1.548873538461538 ], [ 29.766230689655174, -1.548604061538462 ], [ 29.765961206896552, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14783, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.548604061538462 ], [ 29.766230689655174, -1.548873538461538 ], [ 29.766500172413792, -1.548873538461538 ], [ 29.766500172413792, -1.548604061538462 ], [ 29.766230689655174, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14784, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.548604061538462 ], [ 29.766500172413792, -1.548873538461538 ], [ 29.766769655172414, -1.548873538461538 ], [ 29.766769655172414, -1.548604061538462 ], [ 29.766500172413792, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14785, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.548604061538462 ], [ 29.766769655172414, -1.548873538461538 ], [ 29.767308620689654, -1.548873538461538 ], [ 29.767308620689654, -1.548604061538462 ], [ 29.766769655172414, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14786, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.548604061538462 ], [ 29.767308620689654, -1.548873538461538 ], [ 29.767578103448276, -1.548873538461538 ], [ 29.767578103448276, -1.548604061538462 ], [ 29.767308620689654, -1.548604061538462 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14787, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.548873538461538 ], [ 29.754373448275864, -1.549412492307692 ], [ 29.754642931034482, -1.549412492307692 ], [ 29.754642931034482, -1.548873538461538 ], [ 29.754373448275864, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14788, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.548873538461538 ], [ 29.754642931034482, -1.549412492307692 ], [ 29.754912413793104, -1.549412492307692 ], [ 29.754912413793104, -1.548873538461538 ], [ 29.754642931034482, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14789, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.548873538461538 ], [ 29.754912413793104, -1.549143015384615 ], [ 29.755181896551726, -1.549143015384615 ], [ 29.755181896551726, -1.548873538461538 ], [ 29.754912413793104, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14790, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.548873538461538 ], [ 29.755181896551726, -1.549143015384615 ], [ 29.755451379310344, -1.549143015384615 ], [ 29.755451379310344, -1.548873538461538 ], [ 29.755181896551726, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14791, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.548873538461538 ], [ 29.755451379310344, -1.549143015384615 ], [ 29.755720862068966, -1.549143015384615 ], [ 29.755720862068966, -1.548873538461538 ], [ 29.755451379310344, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14792, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.548873538461538 ], [ 29.755720862068966, -1.549143015384615 ], [ 29.755990344827588, -1.549143015384615 ], [ 29.755990344827588, -1.548873538461538 ], [ 29.755720862068966, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14793, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.548873538461538 ], [ 29.755990344827588, -1.549143015384615 ], [ 29.756259827586206, -1.549143015384615 ], [ 29.756259827586206, -1.548873538461538 ], [ 29.755990344827588, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14794, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.548873538461538 ], [ 29.756259827586206, -1.549143015384615 ], [ 29.756798793103449, -1.549143015384615 ], [ 29.756798793103449, -1.548873538461538 ], [ 29.756259827586206, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14795, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.548873538461538 ], [ 29.756798793103449, -1.549143015384615 ], [ 29.757068275862068, -1.549143015384615 ], [ 29.757068275862068, -1.548873538461538 ], [ 29.756798793103449, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14796, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.548873538461538 ], [ 29.757068275862068, -1.549143015384615 ], [ 29.75733775862069, -1.549143015384615 ], [ 29.75733775862069, -1.548873538461538 ], [ 29.757068275862068, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14797, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.548873538461538 ], [ 29.75733775862069, -1.549143015384615 ], [ 29.757607241379311, -1.549143015384615 ], [ 29.757607241379311, -1.548873538461538 ], [ 29.75733775862069, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14798, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.548873538461538 ], [ 29.757607241379311, -1.549143015384615 ], [ 29.757876724137933, -1.549143015384615 ], [ 29.757876724137933, -1.548873538461538 ], [ 29.757607241379311, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14799, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.548873538461538 ], [ 29.757876724137933, -1.549143015384615 ], [ 29.758146206896551, -1.549143015384615 ], [ 29.758146206896551, -1.548873538461538 ], [ 29.757876724137933, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14800, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.548873538461538 ], [ 29.758146206896551, -1.549143015384615 ], [ 29.758415689655173, -1.549143015384615 ], [ 29.758415689655173, -1.548873538461538 ], [ 29.758146206896551, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14801, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.548873538461538 ], [ 29.758415689655173, -1.549143015384615 ], [ 29.758685172413795, -1.549143015384615 ], [ 29.758685172413795, -1.548873538461538 ], [ 29.758415689655173, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14802, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.548873538461538 ], [ 29.758954655172413, -1.549412492307692 ], [ 29.759224137931035, -1.549412492307692 ], [ 29.759224137931035, -1.548873538461538 ], [ 29.758954655172413, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14803, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.548873538461538 ], [ 29.759224137931035, -1.549143015384615 ], [ 29.759493620689657, -1.549143015384615 ], [ 29.759493620689657, -1.548873538461538 ], [ 29.759224137931035, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14804, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.548873538461538 ], [ 29.759493620689657, -1.549143015384615 ], [ 29.759763103448275, -1.549143015384615 ], [ 29.759763103448275, -1.548873538461538 ], [ 29.759493620689657, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14805, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.548873538461538 ], [ 29.760302068965519, -1.549412492307692 ], [ 29.760571551724137, -1.549412492307692 ], [ 29.760571551724137, -1.548873538461538 ], [ 29.760302068965519, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14806, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.548873538461538 ], [ 29.760571551724137, -1.549412492307692 ], [ 29.760841034482759, -1.549412492307692 ], [ 29.760841034482759, -1.548873538461538 ], [ 29.760571551724137, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14807, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.548873538461538 ], [ 29.761110517241381, -1.549143015384615 ], [ 29.76138, -1.549143015384615 ], [ 29.76138, -1.548873538461538 ], [ 29.761110517241381, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14808, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.548873538461538 ], [ 29.76138, -1.549143015384615 ], [ 29.761649482758621, -1.549143015384615 ], [ 29.761649482758621, -1.548873538461538 ], [ 29.76138, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14809, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.548873538461538 ], [ 29.761649482758621, -1.549143015384615 ], [ 29.761918965517243, -1.549143015384615 ], [ 29.761918965517243, -1.548873538461538 ], [ 29.761649482758621, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14810, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.548873538461538 ], [ 29.761918965517243, -1.549143015384615 ], [ 29.762188448275861, -1.549143015384615 ], [ 29.762188448275861, -1.548873538461538 ], [ 29.761918965517243, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14811, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.548873538461538 ], [ 29.762188448275861, -1.549143015384615 ], [ 29.762457931034483, -1.549143015384615 ], [ 29.762457931034483, -1.548873538461538 ], [ 29.762188448275861, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14812, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.548873538461538 ], [ 29.762457931034483, -1.549143015384615 ], [ 29.762727413793105, -1.549143015384615 ], [ 29.762727413793105, -1.548873538461538 ], [ 29.762457931034483, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14813, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.548873538461538 ], [ 29.762727413793105, -1.549143015384615 ], [ 29.762996896551723, -1.549143015384615 ], [ 29.762996896551723, -1.548873538461538 ], [ 29.762727413793105, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14814, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.548873538461538 ], [ 29.762996896551723, -1.549143015384615 ], [ 29.763266379310345, -1.549143015384615 ], [ 29.763266379310345, -1.548873538461538 ], [ 29.762996896551723, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14815, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.548873538461538 ], [ 29.763266379310345, -1.549143015384615 ], [ 29.763535862068967, -1.549143015384615 ], [ 29.763535862068967, -1.548873538461538 ], [ 29.763266379310345, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14816, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.548873538461538 ], [ 29.763535862068967, -1.549143015384615 ], [ 29.763805344827588, -1.549143015384615 ], [ 29.763805344827588, -1.548873538461538 ], [ 29.763535862068967, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14817, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.548873538461538 ], [ 29.763805344827588, -1.549143015384615 ], [ 29.764074827586207, -1.549143015384615 ], [ 29.764074827586207, -1.548873538461538 ], [ 29.763805344827588, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14818, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.548873538461538 ], [ 29.764074827586207, -1.549143015384615 ], [ 29.764344310344828, -1.549143015384615 ], [ 29.764344310344828, -1.548873538461538 ], [ 29.764074827586207, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14819, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.548873538461538 ], [ 29.764344310344828, -1.549143015384615 ], [ 29.76461379310345, -1.549143015384615 ], [ 29.76461379310345, -1.548873538461538 ], [ 29.764344310344828, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14820, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.548873538461538 ], [ 29.76461379310345, -1.549143015384615 ], [ 29.764883275862069, -1.549143015384615 ], [ 29.764883275862069, -1.548873538461538 ], [ 29.76461379310345, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14821, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.548873538461538 ], [ 29.764883275862069, -1.549143015384615 ], [ 29.76515275862069, -1.549143015384615 ], [ 29.76515275862069, -1.548873538461538 ], [ 29.764883275862069, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14822, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.548873538461538 ], [ 29.76515275862069, -1.549143015384615 ], [ 29.765422241379312, -1.549143015384615 ], [ 29.765422241379312, -1.548873538461538 ], [ 29.76515275862069, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14823, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.548873538461538 ], [ 29.765422241379312, -1.549143015384615 ], [ 29.765961206896552, -1.549143015384615 ], [ 29.765961206896552, -1.548873538461538 ], [ 29.765422241379312, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14824, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.548873538461538 ], [ 29.765961206896552, -1.549143015384615 ], [ 29.766230689655174, -1.549143015384615 ], [ 29.766230689655174, -1.548873538461538 ], [ 29.765961206896552, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14825, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.548873538461538 ], [ 29.766230689655174, -1.549143015384615 ], [ 29.766500172413792, -1.549143015384615 ], [ 29.766500172413792, -1.548873538461538 ], [ 29.766230689655174, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14826, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.548873538461538 ], [ 29.766500172413792, -1.549143015384615 ], [ 29.766769655172414, -1.549143015384615 ], [ 29.766769655172414, -1.548873538461538 ], [ 29.766500172413792, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14827, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.548873538461538 ], [ 29.766769655172414, -1.549143015384615 ], [ 29.767308620689654, -1.549143015384615 ], [ 29.767308620689654, -1.548873538461538 ], [ 29.766769655172414, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14828, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.548873538461538 ], [ 29.767308620689654, -1.549143015384615 ], [ 29.767578103448276, -1.549143015384615 ], [ 29.767578103448276, -1.548873538461538 ], [ 29.767308620689654, -1.548873538461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14829, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.547795630769231 ], [ 29.768117068965516, -1.547795630769231 ], [ 29.768117068965516, -1.549143015384615 ], [ 29.767578103448276, -1.549143015384615 ], [ 29.767578103448276, -1.548873538461538 ], [ 29.767847586206898, -1.548873538461538 ], [ 29.767847586206898, -1.547795630769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14830, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.549143015384615 ], [ 29.75383448275862, -1.549681969230769 ], [ 29.754103965517242, -1.549681969230769 ], [ 29.754103965517242, -1.549143015384615 ], [ 29.75383448275862, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14831, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.549143015384615 ], [ 29.754103965517242, -1.549412492307692 ], [ 29.754373448275864, -1.549412492307692 ], [ 29.754373448275864, -1.549143015384615 ], [ 29.754103965517242, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14832, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.549143015384615 ], [ 29.754912413793104, -1.549681969230769 ], [ 29.755181896551726, -1.549681969230769 ], [ 29.755181896551726, -1.549143015384615 ], [ 29.754912413793104, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14833, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.549143015384615 ], [ 29.755181896551726, -1.549412492307692 ], [ 29.755451379310344, -1.549412492307692 ], [ 29.755451379310344, -1.549143015384615 ], [ 29.755181896551726, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14834, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.549143015384615 ], [ 29.755451379310344, -1.549412492307692 ], [ 29.755720862068966, -1.549412492307692 ], [ 29.755720862068966, -1.549143015384615 ], [ 29.755451379310344, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14835, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.549143015384615 ], [ 29.755720862068966, -1.549412492307692 ], [ 29.755990344827588, -1.549412492307692 ], [ 29.755990344827588, -1.549143015384615 ], [ 29.755720862068966, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14836, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.549143015384615 ], [ 29.755990344827588, -1.549412492307692 ], [ 29.756259827586206, -1.549412492307692 ], [ 29.756259827586206, -1.549143015384615 ], [ 29.755990344827588, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14837, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.549143015384615 ], [ 29.756259827586206, -1.549412492307692 ], [ 29.756798793103449, -1.549412492307692 ], [ 29.756798793103449, -1.549143015384615 ], [ 29.756259827586206, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14838, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.549143015384615 ], [ 29.756798793103449, -1.549412492307692 ], [ 29.757068275862068, -1.549412492307692 ], [ 29.757068275862068, -1.549143015384615 ], [ 29.756798793103449, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14839, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.549143015384615 ], [ 29.757068275862068, -1.549412492307692 ], [ 29.75733775862069, -1.549412492307692 ], [ 29.75733775862069, -1.549143015384615 ], [ 29.757068275862068, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14840, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.549143015384615 ], [ 29.75733775862069, -1.549412492307692 ], [ 29.757607241379311, -1.549412492307692 ], [ 29.757607241379311, -1.549143015384615 ], [ 29.75733775862069, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14841, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.549143015384615 ], [ 29.757607241379311, -1.549412492307692 ], [ 29.757876724137933, -1.549412492307692 ], [ 29.757876724137933, -1.549143015384615 ], [ 29.757607241379311, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14842, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.549143015384615 ], [ 29.757876724137933, -1.549412492307692 ], [ 29.758146206896551, -1.549412492307692 ], [ 29.758146206896551, -1.549143015384615 ], [ 29.757876724137933, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14843, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.549143015384615 ], [ 29.758146206896551, -1.549412492307692 ], [ 29.758415689655173, -1.549412492307692 ], [ 29.758415689655173, -1.549143015384615 ], [ 29.758146206896551, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14844, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.549143015384615 ], [ 29.758415689655173, -1.549412492307692 ], [ 29.758685172413795, -1.549412492307692 ], [ 29.758685172413795, -1.549143015384615 ], [ 29.758415689655173, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14845, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.549143015384615 ], [ 29.758685172413795, -1.549412492307692 ], [ 29.758954655172413, -1.549412492307692 ], [ 29.758954655172413, -1.549143015384615 ], [ 29.758685172413795, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14846, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.549143015384615 ], [ 29.759224137931035, -1.549681969230769 ], [ 29.759493620689657, -1.549681969230769 ], [ 29.759493620689657, -1.549143015384615 ], [ 29.759224137931035, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14847, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.549143015384615 ], [ 29.759493620689657, -1.549412492307692 ], [ 29.759763103448275, -1.549412492307692 ], [ 29.759763103448275, -1.549143015384615 ], [ 29.759493620689657, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14848, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.549143015384615 ], [ 29.759763103448275, -1.549412492307692 ], [ 29.760032586206897, -1.549412492307692 ], [ 29.760032586206897, -1.549143015384615 ], [ 29.759763103448275, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14849, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.549143015384615 ], [ 29.760032586206897, -1.549412492307692 ], [ 29.760302068965519, -1.549412492307692 ], [ 29.760302068965519, -1.549143015384615 ], [ 29.760032586206897, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14850, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.549143015384615 ], [ 29.761110517241381, -1.549412492307692 ], [ 29.76138, -1.549412492307692 ], [ 29.76138, -1.549143015384615 ], [ 29.761110517241381, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14851, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.549143015384615 ], [ 29.76138, -1.549412492307692 ], [ 29.761649482758621, -1.549412492307692 ], [ 29.761649482758621, -1.549143015384615 ], [ 29.76138, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14852, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.549143015384615 ], [ 29.761649482758621, -1.549412492307692 ], [ 29.761918965517243, -1.549412492307692 ], [ 29.761918965517243, -1.549143015384615 ], [ 29.761649482758621, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14853, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.549143015384615 ], [ 29.761918965517243, -1.549412492307692 ], [ 29.762188448275861, -1.549412492307692 ], [ 29.762188448275861, -1.549143015384615 ], [ 29.761918965517243, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14854, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.549143015384615 ], [ 29.762188448275861, -1.549412492307692 ], [ 29.762457931034483, -1.549412492307692 ], [ 29.762457931034483, -1.549143015384615 ], [ 29.762188448275861, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14855, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.549143015384615 ], [ 29.762457931034483, -1.549412492307692 ], [ 29.762727413793105, -1.549412492307692 ], [ 29.762727413793105, -1.549143015384615 ], [ 29.762457931034483, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14856, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.549143015384615 ], [ 29.762727413793105, -1.549412492307692 ], [ 29.762996896551723, -1.549412492307692 ], [ 29.762996896551723, -1.549143015384615 ], [ 29.762727413793105, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14857, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.549143015384615 ], [ 29.762996896551723, -1.549412492307692 ], [ 29.763266379310345, -1.549412492307692 ], [ 29.763266379310345, -1.549143015384615 ], [ 29.762996896551723, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14858, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.549143015384615 ], [ 29.763266379310345, -1.549412492307692 ], [ 29.763535862068967, -1.549412492307692 ], [ 29.763535862068967, -1.549143015384615 ], [ 29.763266379310345, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14859, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.549143015384615 ], [ 29.763535862068967, -1.549412492307692 ], [ 29.763805344827588, -1.549412492307692 ], [ 29.763805344827588, -1.549143015384615 ], [ 29.763535862068967, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14860, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.549143015384615 ], [ 29.763805344827588, -1.549412492307692 ], [ 29.764074827586207, -1.549412492307692 ], [ 29.764074827586207, -1.549143015384615 ], [ 29.763805344827588, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14861, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.549143015384615 ], [ 29.764074827586207, -1.549412492307692 ], [ 29.764344310344828, -1.549412492307692 ], [ 29.764344310344828, -1.549143015384615 ], [ 29.764074827586207, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14862, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.549143015384615 ], [ 29.764344310344828, -1.549412492307692 ], [ 29.76461379310345, -1.549412492307692 ], [ 29.76461379310345, -1.549143015384615 ], [ 29.764344310344828, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14863, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.549143015384615 ], [ 29.76461379310345, -1.549412492307692 ], [ 29.764883275862069, -1.549412492307692 ], [ 29.764883275862069, -1.549143015384615 ], [ 29.76461379310345, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14864, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.549143015384615 ], [ 29.764883275862069, -1.549412492307692 ], [ 29.76515275862069, -1.549412492307692 ], [ 29.76515275862069, -1.549143015384615 ], [ 29.764883275862069, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14865, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.549143015384615 ], [ 29.76515275862069, -1.549412492307692 ], [ 29.765422241379312, -1.549412492307692 ], [ 29.765422241379312, -1.549143015384615 ], [ 29.76515275862069, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14866, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.549143015384615 ], [ 29.765422241379312, -1.549412492307692 ], [ 29.765961206896552, -1.549412492307692 ], [ 29.765961206896552, -1.549143015384615 ], [ 29.765422241379312, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14867, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.549143015384615 ], [ 29.765961206896552, -1.549412492307692 ], [ 29.766230689655174, -1.549412492307692 ], [ 29.766230689655174, -1.549143015384615 ], [ 29.765961206896552, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14868, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.549143015384615 ], [ 29.766230689655174, -1.549412492307692 ], [ 29.766500172413792, -1.549412492307692 ], [ 29.766500172413792, -1.549143015384615 ], [ 29.766230689655174, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14869, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.549143015384615 ], [ 29.766500172413792, -1.549412492307692 ], [ 29.766769655172414, -1.549412492307692 ], [ 29.766769655172414, -1.549143015384615 ], [ 29.766500172413792, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14870, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.549143015384615 ], [ 29.766769655172414, -1.549412492307692 ], [ 29.767308620689654, -1.549412492307692 ], [ 29.767308620689654, -1.549143015384615 ], [ 29.766769655172414, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14871, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.549143015384615 ], [ 29.767308620689654, -1.549412492307692 ], [ 29.767847586206898, -1.549412492307692 ], [ 29.767847586206898, -1.549143015384615 ], [ 29.767308620689654, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14872, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.549143015384615 ], [ 29.767847586206898, -1.549412492307692 ], [ 29.768117068965516, -1.549412492307692 ], [ 29.768117068965516, -1.549143015384615 ], [ 29.767847586206898, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14873, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768117068965516, -1.549143015384615 ], [ 29.768117068965516, -1.549412492307692 ], [ 29.768386551724138, -1.549412492307692 ], [ 29.768386551724138, -1.549143015384615 ], [ 29.768117068965516, -1.549143015384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14874, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.549412492307692 ], [ 29.754103965517242, -1.549951446153846 ], [ 29.754373448275864, -1.549951446153846 ], [ 29.754373448275864, -1.549412492307692 ], [ 29.754103965517242, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14875, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.549412492307692 ], [ 29.754373448275864, -1.549951446153846 ], [ 29.754642931034482, -1.549951446153846 ], [ 29.754642931034482, -1.549412492307692 ], [ 29.754373448275864, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14876, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.549412492307692 ], [ 29.754642931034482, -1.549681969230769 ], [ 29.754912413793104, -1.549681969230769 ], [ 29.754912413793104, -1.549412492307692 ], [ 29.754642931034482, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14877, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.549412492307692 ], [ 29.755181896551726, -1.549681969230769 ], [ 29.755451379310344, -1.549681969230769 ], [ 29.755451379310344, -1.549412492307692 ], [ 29.755181896551726, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14878, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.549412492307692 ], [ 29.755451379310344, -1.549681969230769 ], [ 29.755720862068966, -1.549681969230769 ], [ 29.755720862068966, -1.549412492307692 ], [ 29.755451379310344, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14879, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.549412492307692 ], [ 29.755720862068966, -1.549681969230769 ], [ 29.755990344827588, -1.549681969230769 ], [ 29.755990344827588, -1.549412492307692 ], [ 29.755720862068966, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14880, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.549412492307692 ], [ 29.755990344827588, -1.549681969230769 ], [ 29.756259827586206, -1.549681969230769 ], [ 29.756259827586206, -1.549412492307692 ], [ 29.755990344827588, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14881, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.549412492307692 ], [ 29.756259827586206, -1.549681969230769 ], [ 29.756798793103449, -1.549681969230769 ], [ 29.756798793103449, -1.549412492307692 ], [ 29.756259827586206, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14882, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.549412492307692 ], [ 29.756798793103449, -1.549681969230769 ], [ 29.757068275862068, -1.549681969230769 ], [ 29.757068275862068, -1.549412492307692 ], [ 29.756798793103449, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14883, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.549412492307692 ], [ 29.757068275862068, -1.549681969230769 ], [ 29.75733775862069, -1.549681969230769 ], [ 29.75733775862069, -1.549412492307692 ], [ 29.757068275862068, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14884, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.549412492307692 ], [ 29.75733775862069, -1.549681969230769 ], [ 29.757607241379311, -1.549681969230769 ], [ 29.757607241379311, -1.549412492307692 ], [ 29.75733775862069, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14885, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.549412492307692 ], [ 29.757607241379311, -1.549681969230769 ], [ 29.757876724137933, -1.549681969230769 ], [ 29.757876724137933, -1.549412492307692 ], [ 29.757607241379311, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14886, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.549412492307692 ], [ 29.757876724137933, -1.549681969230769 ], [ 29.758146206896551, -1.549681969230769 ], [ 29.758146206896551, -1.549412492307692 ], [ 29.757876724137933, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14887, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.549412492307692 ], [ 29.758146206896551, -1.549681969230769 ], [ 29.758415689655173, -1.549681969230769 ], [ 29.758415689655173, -1.549412492307692 ], [ 29.758146206896551, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14888, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.549412492307692 ], [ 29.758415689655173, -1.549681969230769 ], [ 29.758685172413795, -1.549681969230769 ], [ 29.758685172413795, -1.549412492307692 ], [ 29.758415689655173, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14889, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.549412492307692 ], [ 29.758685172413795, -1.549681969230769 ], [ 29.758954655172413, -1.549681969230769 ], [ 29.758954655172413, -1.549412492307692 ], [ 29.758685172413795, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14890, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.549412492307692 ], [ 29.758954655172413, -1.549681969230769 ], [ 29.759224137931035, -1.549681969230769 ], [ 29.759224137931035, -1.549412492307692 ], [ 29.758954655172413, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14891, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.549412492307692 ], [ 29.759493620689657, -1.549681969230769 ], [ 29.759763103448275, -1.549681969230769 ], [ 29.759763103448275, -1.549412492307692 ], [ 29.759493620689657, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14892, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.549412492307692 ], [ 29.759763103448275, -1.549681969230769 ], [ 29.760032586206897, -1.549681969230769 ], [ 29.760032586206897, -1.549412492307692 ], [ 29.759763103448275, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14893, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.549412492307692 ], [ 29.760032586206897, -1.549681969230769 ], [ 29.760302068965519, -1.549681969230769 ], [ 29.760302068965519, -1.549412492307692 ], [ 29.760032586206897, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14894, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.549412492307692 ], [ 29.760302068965519, -1.549681969230769 ], [ 29.760571551724137, -1.549681969230769 ], [ 29.760571551724137, -1.549412492307692 ], [ 29.760302068965519, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14895, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.549412492307692 ], [ 29.760571551724137, -1.549681969230769 ], [ 29.760841034482759, -1.549681969230769 ], [ 29.760841034482759, -1.549412492307692 ], [ 29.760571551724137, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14896, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.549412492307692 ], [ 29.760841034482759, -1.549681969230769 ], [ 29.761110517241381, -1.549681969230769 ], [ 29.761110517241381, -1.549412492307692 ], [ 29.760841034482759, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14897, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.549412492307692 ], [ 29.761110517241381, -1.549681969230769 ], [ 29.76138, -1.549681969230769 ], [ 29.76138, -1.549412492307692 ], [ 29.761110517241381, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14898, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.549412492307692 ], [ 29.76138, -1.549681969230769 ], [ 29.761649482758621, -1.549681969230769 ], [ 29.761649482758621, -1.549412492307692 ], [ 29.76138, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14899, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.549412492307692 ], [ 29.761649482758621, -1.549681969230769 ], [ 29.761918965517243, -1.549681969230769 ], [ 29.761918965517243, -1.549412492307692 ], [ 29.761649482758621, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14900, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.549412492307692 ], [ 29.761918965517243, -1.549681969230769 ], [ 29.762188448275861, -1.549681969230769 ], [ 29.762188448275861, -1.549412492307692 ], [ 29.761918965517243, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14901, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.549412492307692 ], [ 29.762188448275861, -1.549681969230769 ], [ 29.762457931034483, -1.549681969230769 ], [ 29.762457931034483, -1.549412492307692 ], [ 29.762188448275861, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14902, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.549412492307692 ], [ 29.762457931034483, -1.549681969230769 ], [ 29.762727413793105, -1.549681969230769 ], [ 29.762727413793105, -1.549412492307692 ], [ 29.762457931034483, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14903, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.549412492307692 ], [ 29.762727413793105, -1.549681969230769 ], [ 29.762996896551723, -1.549681969230769 ], [ 29.762996896551723, -1.549412492307692 ], [ 29.762727413793105, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14904, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.549412492307692 ], [ 29.762996896551723, -1.549681969230769 ], [ 29.763266379310345, -1.549681969230769 ], [ 29.763266379310345, -1.549412492307692 ], [ 29.762996896551723, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14905, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.549412492307692 ], [ 29.763266379310345, -1.549681969230769 ], [ 29.763535862068967, -1.549681969230769 ], [ 29.763535862068967, -1.549412492307692 ], [ 29.763266379310345, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14906, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.549412492307692 ], [ 29.763535862068967, -1.549681969230769 ], [ 29.763805344827588, -1.549681969230769 ], [ 29.763805344827588, -1.549412492307692 ], [ 29.763535862068967, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14907, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.549412492307692 ], [ 29.763805344827588, -1.549681969230769 ], [ 29.764074827586207, -1.549681969230769 ], [ 29.764074827586207, -1.549412492307692 ], [ 29.763805344827588, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14908, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.549412492307692 ], [ 29.764074827586207, -1.549681969230769 ], [ 29.764344310344828, -1.549681969230769 ], [ 29.764344310344828, -1.549412492307692 ], [ 29.764074827586207, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14909, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.549412492307692 ], [ 29.764344310344828, -1.549681969230769 ], [ 29.76461379310345, -1.549681969230769 ], [ 29.76461379310345, -1.549412492307692 ], [ 29.764344310344828, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14910, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.549412492307692 ], [ 29.76461379310345, -1.549681969230769 ], [ 29.764883275862069, -1.549681969230769 ], [ 29.764883275862069, -1.549412492307692 ], [ 29.76461379310345, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14911, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.549412492307692 ], [ 29.764883275862069, -1.549681969230769 ], [ 29.76515275862069, -1.549681969230769 ], [ 29.76515275862069, -1.549412492307692 ], [ 29.764883275862069, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14912, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.549412492307692 ], [ 29.76515275862069, -1.549681969230769 ], [ 29.765422241379312, -1.549681969230769 ], [ 29.765422241379312, -1.549412492307692 ], [ 29.76515275862069, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14913, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.549412492307692 ], [ 29.765422241379312, -1.549681969230769 ], [ 29.765961206896552, -1.549681969230769 ], [ 29.765961206896552, -1.549412492307692 ], [ 29.765422241379312, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14914, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.549412492307692 ], [ 29.765961206896552, -1.549681969230769 ], [ 29.766230689655174, -1.549681969230769 ], [ 29.766230689655174, -1.549412492307692 ], [ 29.765961206896552, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14915, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.549412492307692 ], [ 29.766230689655174, -1.549681969230769 ], [ 29.766500172413792, -1.549681969230769 ], [ 29.766500172413792, -1.549412492307692 ], [ 29.766230689655174, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14916, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.549412492307692 ], [ 29.766500172413792, -1.549681969230769 ], [ 29.767039137931036, -1.549681969230769 ], [ 29.767039137931036, -1.549412492307692 ], [ 29.766500172413792, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14917, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.549412492307692 ], [ 29.767039137931036, -1.549681969230769 ], [ 29.767308620689654, -1.549681969230769 ], [ 29.767308620689654, -1.549412492307692 ], [ 29.767039137931036, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14918, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767308620689654, -1.549412492307692 ], [ 29.767308620689654, -1.549681969230769 ], [ 29.767578103448276, -1.549681969230769 ], [ 29.767578103448276, -1.549412492307692 ], [ 29.767308620689654, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14919, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.549412492307692 ], [ 29.767578103448276, -1.549681969230769 ], [ 29.768386551724138, -1.549681969230769 ], [ 29.768386551724138, -1.549412492307692 ], [ 29.767578103448276, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14920, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.549412492307692 ], [ 29.768386551724138, -1.549681969230769 ], [ 29.76865603448276, -1.549681969230769 ], [ 29.76865603448276, -1.549412492307692 ], [ 29.768386551724138, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14921, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.549412492307692 ], [ 29.76865603448276, -1.549681969230769 ], [ 29.768925517241382, -1.549681969230769 ], [ 29.768925517241382, -1.549412492307692 ], [ 29.76865603448276, -1.549412492307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14922, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.549681969230769 ], [ 29.753565, -1.550220923076923 ], [ 29.75383448275862, -1.550220923076923 ], [ 29.75383448275862, -1.549681969230769 ], [ 29.753565, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14923, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.549681969230769 ], [ 29.75383448275862, -1.549951446153846 ], [ 29.754103965517242, -1.549951446153846 ], [ 29.754103965517242, -1.549681969230769 ], [ 29.75383448275862, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14924, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.549681969230769 ], [ 29.754642931034482, -1.550220923076923 ], [ 29.754912413793104, -1.550220923076923 ], [ 29.754912413793104, -1.549681969230769 ], [ 29.754642931034482, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14925, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.549681969230769 ], [ 29.754912413793104, -1.549951446153846 ], [ 29.755181896551726, -1.549951446153846 ], [ 29.755181896551726, -1.549681969230769 ], [ 29.754912413793104, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14926, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.549681969230769 ], [ 29.755181896551726, -1.549951446153846 ], [ 29.755451379310344, -1.549951446153846 ], [ 29.755451379310344, -1.549681969230769 ], [ 29.755181896551726, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14927, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.549681969230769 ], [ 29.755451379310344, -1.549951446153846 ], [ 29.755720862068966, -1.549951446153846 ], [ 29.755720862068966, -1.549681969230769 ], [ 29.755451379310344, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14928, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.549681969230769 ], [ 29.755720862068966, -1.549951446153846 ], [ 29.755990344827588, -1.549951446153846 ], [ 29.755990344827588, -1.549681969230769 ], [ 29.755720862068966, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14929, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.549681969230769 ], [ 29.755990344827588, -1.549951446153846 ], [ 29.756259827586206, -1.549951446153846 ], [ 29.756259827586206, -1.549681969230769 ], [ 29.755990344827588, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14930, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.549681969230769 ], [ 29.756259827586206, -1.549951446153846 ], [ 29.756798793103449, -1.549951446153846 ], [ 29.756798793103449, -1.549681969230769 ], [ 29.756259827586206, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14931, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.549681969230769 ], [ 29.756798793103449, -1.549951446153846 ], [ 29.757068275862068, -1.549951446153846 ], [ 29.757068275862068, -1.549681969230769 ], [ 29.756798793103449, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14932, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.549681969230769 ], [ 29.757068275862068, -1.549951446153846 ], [ 29.75733775862069, -1.549951446153846 ], [ 29.75733775862069, -1.549681969230769 ], [ 29.757068275862068, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14933, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.549681969230769 ], [ 29.75733775862069, -1.549951446153846 ], [ 29.757607241379311, -1.549951446153846 ], [ 29.757607241379311, -1.549681969230769 ], [ 29.75733775862069, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14934, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.549681969230769 ], [ 29.757607241379311, -1.549951446153846 ], [ 29.757876724137933, -1.549951446153846 ], [ 29.757876724137933, -1.549681969230769 ], [ 29.757607241379311, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14935, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.549681969230769 ], [ 29.757876724137933, -1.549951446153846 ], [ 29.758146206896551, -1.549951446153846 ], [ 29.758146206896551, -1.549681969230769 ], [ 29.757876724137933, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14936, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.549681969230769 ], [ 29.758146206896551, -1.549951446153846 ], [ 29.758415689655173, -1.549951446153846 ], [ 29.758415689655173, -1.549681969230769 ], [ 29.758146206896551, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14937, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.549681969230769 ], [ 29.758415689655173, -1.549951446153846 ], [ 29.758685172413795, -1.549951446153846 ], [ 29.758685172413795, -1.549681969230769 ], [ 29.758415689655173, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14938, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.549681969230769 ], [ 29.758685172413795, -1.549951446153846 ], [ 29.758954655172413, -1.549951446153846 ], [ 29.758954655172413, -1.549681969230769 ], [ 29.758685172413795, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14939, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.549681969230769 ], [ 29.758954655172413, -1.549951446153846 ], [ 29.759224137931035, -1.549951446153846 ], [ 29.759224137931035, -1.549681969230769 ], [ 29.758954655172413, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14940, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.549681969230769 ], [ 29.759224137931035, -1.549951446153846 ], [ 29.759493620689657, -1.549951446153846 ], [ 29.759493620689657, -1.549681969230769 ], [ 29.759224137931035, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14941, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.549681969230769 ], [ 29.759493620689657, -1.549951446153846 ], [ 29.759763103448275, -1.549951446153846 ], [ 29.759763103448275, -1.549681969230769 ], [ 29.759493620689657, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14942, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.549681969230769 ], [ 29.759763103448275, -1.549951446153846 ], [ 29.760032586206897, -1.549951446153846 ], [ 29.760032586206897, -1.549681969230769 ], [ 29.759763103448275, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14943, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.549681969230769 ], [ 29.760032586206897, -1.549951446153846 ], [ 29.760302068965519, -1.549951446153846 ], [ 29.760302068965519, -1.549681969230769 ], [ 29.760032586206897, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14944, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.549681969230769 ], [ 29.760302068965519, -1.550220923076923 ], [ 29.760571551724137, -1.550220923076923 ], [ 29.760571551724137, -1.549951446153846 ], [ 29.760841034482759, -1.549951446153846 ], [ 29.760841034482759, -1.549681969230769 ], [ 29.760302068965519, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14945, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.549681969230769 ], [ 29.760841034482759, -1.549951446153846 ], [ 29.761110517241381, -1.549951446153846 ], [ 29.761110517241381, -1.549681969230769 ], [ 29.760841034482759, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14946, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.549681969230769 ], [ 29.761110517241381, -1.549951446153846 ], [ 29.76138, -1.549951446153846 ], [ 29.76138, -1.549681969230769 ], [ 29.761110517241381, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14947, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.549681969230769 ], [ 29.76138, -1.549951446153846 ], [ 29.761649482758621, -1.549951446153846 ], [ 29.761649482758621, -1.549681969230769 ], [ 29.76138, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14948, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.549681969230769 ], [ 29.761649482758621, -1.549951446153846 ], [ 29.761918965517243, -1.549951446153846 ], [ 29.761918965517243, -1.549681969230769 ], [ 29.761649482758621, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14949, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.549681969230769 ], [ 29.761918965517243, -1.549951446153846 ], [ 29.762188448275861, -1.549951446153846 ], [ 29.762188448275861, -1.549681969230769 ], [ 29.761918965517243, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14950, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.549681969230769 ], [ 29.762188448275861, -1.549951446153846 ], [ 29.762457931034483, -1.549951446153846 ], [ 29.762457931034483, -1.549681969230769 ], [ 29.762188448275861, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14951, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.549681969230769 ], [ 29.762457931034483, -1.549951446153846 ], [ 29.762727413793105, -1.549951446153846 ], [ 29.762727413793105, -1.549681969230769 ], [ 29.762457931034483, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14952, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.549681969230769 ], [ 29.762727413793105, -1.549951446153846 ], [ 29.762996896551723, -1.549951446153846 ], [ 29.762996896551723, -1.549681969230769 ], [ 29.762727413793105, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14953, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.549681969230769 ], [ 29.762996896551723, -1.549951446153846 ], [ 29.763266379310345, -1.549951446153846 ], [ 29.763266379310345, -1.549681969230769 ], [ 29.762996896551723, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14954, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.549681969230769 ], [ 29.763266379310345, -1.549951446153846 ], [ 29.763535862068967, -1.549951446153846 ], [ 29.763535862068967, -1.549681969230769 ], [ 29.763266379310345, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14955, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.549681969230769 ], [ 29.763535862068967, -1.549951446153846 ], [ 29.763805344827588, -1.549951446153846 ], [ 29.763805344827588, -1.549681969230769 ], [ 29.763535862068967, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14956, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.549681969230769 ], [ 29.763805344827588, -1.549951446153846 ], [ 29.764074827586207, -1.549951446153846 ], [ 29.764074827586207, -1.549681969230769 ], [ 29.763805344827588, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14957, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.549681969230769 ], [ 29.764074827586207, -1.549951446153846 ], [ 29.764344310344828, -1.549951446153846 ], [ 29.764344310344828, -1.549681969230769 ], [ 29.764074827586207, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14958, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.549681969230769 ], [ 29.764344310344828, -1.549951446153846 ], [ 29.76461379310345, -1.549951446153846 ], [ 29.76461379310345, -1.549681969230769 ], [ 29.764344310344828, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14959, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.549681969230769 ], [ 29.76461379310345, -1.549951446153846 ], [ 29.764883275862069, -1.549951446153846 ], [ 29.764883275862069, -1.549681969230769 ], [ 29.76461379310345, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14960, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.549681969230769 ], [ 29.764883275862069, -1.549951446153846 ], [ 29.76515275862069, -1.549951446153846 ], [ 29.76515275862069, -1.549681969230769 ], [ 29.764883275862069, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14961, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.549681969230769 ], [ 29.76515275862069, -1.549951446153846 ], [ 29.765422241379312, -1.549951446153846 ], [ 29.765422241379312, -1.549681969230769 ], [ 29.76515275862069, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14962, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.549681969230769 ], [ 29.765422241379312, -1.549951446153846 ], [ 29.765961206896552, -1.549951446153846 ], [ 29.765961206896552, -1.549681969230769 ], [ 29.765422241379312, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14963, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.549681969230769 ], [ 29.765961206896552, -1.549951446153846 ], [ 29.766230689655174, -1.549951446153846 ], [ 29.766230689655174, -1.549681969230769 ], [ 29.765961206896552, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14964, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.549681969230769 ], [ 29.766230689655174, -1.549951446153846 ], [ 29.766500172413792, -1.549951446153846 ], [ 29.766500172413792, -1.549681969230769 ], [ 29.766230689655174, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14965, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.549681969230769 ], [ 29.766500172413792, -1.549951446153846 ], [ 29.766769655172414, -1.549951446153846 ], [ 29.766769655172414, -1.549681969230769 ], [ 29.766500172413792, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14966, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.549681969230769 ], [ 29.766769655172414, -1.549951446153846 ], [ 29.767578103448276, -1.549951446153846 ], [ 29.767578103448276, -1.549681969230769 ], [ 29.766769655172414, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14967, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.549681969230769 ], [ 29.767578103448276, -1.549951446153846 ], [ 29.768386551724138, -1.549951446153846 ], [ 29.768386551724138, -1.549681969230769 ], [ 29.767578103448276, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14968, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768386551724138, -1.549681969230769 ], [ 29.768386551724138, -1.549951446153846 ], [ 29.76865603448276, -1.549951446153846 ], [ 29.76865603448276, -1.549681969230769 ], [ 29.768386551724138, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14969, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76865603448276, -1.549681969230769 ], [ 29.76865603448276, -1.549951446153846 ], [ 29.768925517241382, -1.549951446153846 ], [ 29.768925517241382, -1.549681969230769 ], [ 29.76865603448276, -1.549681969230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14970, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.549951446153846 ], [ 29.754373448275864, -1.550220923076923 ], [ 29.754642931034482, -1.550220923076923 ], [ 29.754642931034482, -1.549951446153846 ], [ 29.754373448275864, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14971, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.549951446153846 ], [ 29.754912413793104, -1.550220923076923 ], [ 29.755181896551726, -1.550220923076923 ], [ 29.755181896551726, -1.549951446153846 ], [ 29.754912413793104, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14972, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.549951446153846 ], [ 29.755181896551726, -1.550220923076923 ], [ 29.755451379310344, -1.550220923076923 ], [ 29.755451379310344, -1.549951446153846 ], [ 29.755181896551726, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14973, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.549951446153846 ], [ 29.755451379310344, -1.550220923076923 ], [ 29.755720862068966, -1.550220923076923 ], [ 29.755720862068966, -1.549951446153846 ], [ 29.755451379310344, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14974, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.549951446153846 ], [ 29.755720862068966, -1.550220923076923 ], [ 29.755990344827588, -1.550220923076923 ], [ 29.755990344827588, -1.549951446153846 ], [ 29.755720862068966, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14975, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.549951446153846 ], [ 29.755990344827588, -1.550220923076923 ], [ 29.756259827586206, -1.550220923076923 ], [ 29.756259827586206, -1.549951446153846 ], [ 29.755990344827588, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14976, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.549951446153846 ], [ 29.756259827586206, -1.550220923076923 ], [ 29.756798793103449, -1.550220923076923 ], [ 29.756798793103449, -1.549951446153846 ], [ 29.756259827586206, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14977, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.549951446153846 ], [ 29.756798793103449, -1.550220923076923 ], [ 29.757068275862068, -1.550220923076923 ], [ 29.757068275862068, -1.549951446153846 ], [ 29.756798793103449, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14978, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.549951446153846 ], [ 29.757068275862068, -1.550220923076923 ], [ 29.75733775862069, -1.550220923076923 ], [ 29.75733775862069, -1.549951446153846 ], [ 29.757068275862068, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14979, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.549951446153846 ], [ 29.75733775862069, -1.550220923076923 ], [ 29.757607241379311, -1.550220923076923 ], [ 29.757607241379311, -1.549951446153846 ], [ 29.75733775862069, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14980, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.549951446153846 ], [ 29.757607241379311, -1.550220923076923 ], [ 29.757876724137933, -1.550220923076923 ], [ 29.757876724137933, -1.549951446153846 ], [ 29.757607241379311, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14981, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.549951446153846 ], [ 29.757876724137933, -1.550220923076923 ], [ 29.758146206896551, -1.550220923076923 ], [ 29.758146206896551, -1.549951446153846 ], [ 29.757876724137933, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14982, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.549951446153846 ], [ 29.758146206896551, -1.550220923076923 ], [ 29.758415689655173, -1.550220923076923 ], [ 29.758415689655173, -1.549951446153846 ], [ 29.758146206896551, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14983, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.549951446153846 ], [ 29.758415689655173, -1.550220923076923 ], [ 29.758685172413795, -1.550220923076923 ], [ 29.758685172413795, -1.549951446153846 ], [ 29.758415689655173, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14984, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.549951446153846 ], [ 29.758685172413795, -1.550220923076923 ], [ 29.758954655172413, -1.550220923076923 ], [ 29.758954655172413, -1.549951446153846 ], [ 29.758685172413795, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14985, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.549951446153846 ], [ 29.758954655172413, -1.550220923076923 ], [ 29.759224137931035, -1.550220923076923 ], [ 29.759224137931035, -1.549951446153846 ], [ 29.758954655172413, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14986, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.549951446153846 ], [ 29.759224137931035, -1.550220923076923 ], [ 29.759493620689657, -1.550220923076923 ], [ 29.759493620689657, -1.549951446153846 ], [ 29.759224137931035, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14987, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.549951446153846 ], [ 29.759493620689657, -1.550220923076923 ], [ 29.759763103448275, -1.550220923076923 ], [ 29.759763103448275, -1.549951446153846 ], [ 29.759493620689657, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14988, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.549951446153846 ], [ 29.759763103448275, -1.550220923076923 ], [ 29.760032586206897, -1.550220923076923 ], [ 29.760032586206897, -1.549951446153846 ], [ 29.759763103448275, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14989, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.549951446153846 ], [ 29.760032586206897, -1.550220923076923 ], [ 29.760302068965519, -1.550220923076923 ], [ 29.760302068965519, -1.549951446153846 ], [ 29.760032586206897, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14990, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.549951446153846 ], [ 29.760571551724137, -1.550220923076923 ], [ 29.760841034482759, -1.550220923076923 ], [ 29.760841034482759, -1.549951446153846 ], [ 29.760571551724137, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14991, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.549951446153846 ], [ 29.760841034482759, -1.550220923076923 ], [ 29.761110517241381, -1.550220923076923 ], [ 29.761110517241381, -1.549951446153846 ], [ 29.760841034482759, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14992, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.549951446153846 ], [ 29.761110517241381, -1.550220923076923 ], [ 29.76138, -1.550220923076923 ], [ 29.76138, -1.549951446153846 ], [ 29.761110517241381, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14993, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.549951446153846 ], [ 29.76138, -1.550220923076923 ], [ 29.761649482758621, -1.550220923076923 ], [ 29.761649482758621, -1.549951446153846 ], [ 29.76138, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14994, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.549951446153846 ], [ 29.761649482758621, -1.550220923076923 ], [ 29.761918965517243, -1.550220923076923 ], [ 29.761918965517243, -1.549951446153846 ], [ 29.761649482758621, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14995, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.549951446153846 ], [ 29.761918965517243, -1.550220923076923 ], [ 29.762188448275861, -1.550220923076923 ], [ 29.762188448275861, -1.549951446153846 ], [ 29.761918965517243, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14996, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.549951446153846 ], [ 29.762188448275861, -1.550220923076923 ], [ 29.762457931034483, -1.550220923076923 ], [ 29.762457931034483, -1.549951446153846 ], [ 29.762188448275861, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14997, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.549951446153846 ], [ 29.762457931034483, -1.550220923076923 ], [ 29.762727413793105, -1.550220923076923 ], [ 29.762727413793105, -1.549951446153846 ], [ 29.762457931034483, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14998, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.549951446153846 ], [ 29.762727413793105, -1.550220923076923 ], [ 29.762996896551723, -1.550220923076923 ], [ 29.762996896551723, -1.549951446153846 ], [ 29.762727413793105, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14999, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.549951446153846 ], [ 29.762996896551723, -1.550220923076923 ], [ 29.763266379310345, -1.550220923076923 ], [ 29.763266379310345, -1.549951446153846 ], [ 29.762996896551723, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15000, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.549951446153846 ], [ 29.763266379310345, -1.550220923076923 ], [ 29.763535862068967, -1.550220923076923 ], [ 29.763535862068967, -1.549951446153846 ], [ 29.763266379310345, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15001, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.549951446153846 ], [ 29.763535862068967, -1.550220923076923 ], [ 29.763805344827588, -1.550220923076923 ], [ 29.763805344827588, -1.549951446153846 ], [ 29.763535862068967, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15002, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.549951446153846 ], [ 29.763805344827588, -1.550220923076923 ], [ 29.764074827586207, -1.550220923076923 ], [ 29.764074827586207, -1.549951446153846 ], [ 29.763805344827588, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15003, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.549951446153846 ], [ 29.764074827586207, -1.550220923076923 ], [ 29.764344310344828, -1.550220923076923 ], [ 29.764344310344828, -1.549951446153846 ], [ 29.764074827586207, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15004, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.549951446153846 ], [ 29.764344310344828, -1.550220923076923 ], [ 29.76461379310345, -1.550220923076923 ], [ 29.76461379310345, -1.549951446153846 ], [ 29.764344310344828, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15005, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.549951446153846 ], [ 29.76461379310345, -1.550220923076923 ], [ 29.764883275862069, -1.550220923076923 ], [ 29.764883275862069, -1.549951446153846 ], [ 29.76461379310345, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15006, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.549951446153846 ], [ 29.764883275862069, -1.550220923076923 ], [ 29.76515275862069, -1.550220923076923 ], [ 29.76515275862069, -1.549951446153846 ], [ 29.764883275862069, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15007, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.549951446153846 ], [ 29.76515275862069, -1.550220923076923 ], [ 29.765422241379312, -1.550220923076923 ], [ 29.765422241379312, -1.549951446153846 ], [ 29.76515275862069, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15008, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.549951446153846 ], [ 29.765422241379312, -1.550220923076923 ], [ 29.765961206896552, -1.550220923076923 ], [ 29.765961206896552, -1.549951446153846 ], [ 29.765422241379312, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15009, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.549951446153846 ], [ 29.765961206896552, -1.550220923076923 ], [ 29.766230689655174, -1.550220923076923 ], [ 29.766230689655174, -1.549951446153846 ], [ 29.765961206896552, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15010, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.549951446153846 ], [ 29.766230689655174, -1.550220923076923 ], [ 29.766500172413792, -1.550220923076923 ], [ 29.766500172413792, -1.549951446153846 ], [ 29.766230689655174, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15011, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.549951446153846 ], [ 29.766500172413792, -1.550220923076923 ], [ 29.766769655172414, -1.550220923076923 ], [ 29.766769655172414, -1.549951446153846 ], [ 29.766500172413792, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15012, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.549951446153846 ], [ 29.766769655172414, -1.550220923076923 ], [ 29.767578103448276, -1.550220923076923 ], [ 29.767578103448276, -1.549951446153846 ], [ 29.766769655172414, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15013, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767578103448276, -1.549951446153846 ], [ 29.767578103448276, -1.550220923076923 ], [ 29.767847586206898, -1.550220923076923 ], [ 29.767847586206898, -1.549951446153846 ], [ 29.767578103448276, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15014, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767847586206898, -1.549951446153846 ], [ 29.767847586206898, -1.550220923076923 ], [ 29.76865603448276, -1.550220923076923 ], [ 29.76865603448276, -1.549951446153846 ], [ 29.767847586206898, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15015, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.549951446153846 ], [ 29.75383448275862, -1.5504904 ], [ 29.754103965517242, -1.5504904 ], [ 29.754103965517242, -1.549951446153846 ], [ 29.75383448275862, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15016, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.549951446153846 ], [ 29.754103965517242, -1.5504904 ], [ 29.754373448275864, -1.5504904 ], [ 29.754373448275864, -1.549951446153846 ], [ 29.754103965517242, -1.549951446153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15017, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.550220923076923 ], [ 29.753565, -1.5504904 ], [ 29.75383448275862, -1.5504904 ], [ 29.75383448275862, -1.550220923076923 ], [ 29.753565, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15018, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.550220923076923 ], [ 29.754373448275864, -1.550759876923077 ], [ 29.754642931034482, -1.550759876923077 ], [ 29.754642931034482, -1.550220923076923 ], [ 29.754373448275864, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15019, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.550220923076923 ], [ 29.754642931034482, -1.5504904 ], [ 29.754912413793104, -1.5504904 ], [ 29.754912413793104, -1.550220923076923 ], [ 29.754642931034482, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15020, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.550220923076923 ], [ 29.754912413793104, -1.5504904 ], [ 29.755181896551726, -1.5504904 ], [ 29.755181896551726, -1.550220923076923 ], [ 29.754912413793104, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15021, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.550220923076923 ], [ 29.755181896551726, -1.5504904 ], [ 29.755451379310344, -1.5504904 ], [ 29.755451379310344, -1.550220923076923 ], [ 29.755181896551726, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15022, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.550220923076923 ], [ 29.755451379310344, -1.5504904 ], [ 29.755720862068966, -1.5504904 ], [ 29.755720862068966, -1.550220923076923 ], [ 29.755451379310344, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15023, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.550220923076923 ], [ 29.755720862068966, -1.5504904 ], [ 29.755990344827588, -1.5504904 ], [ 29.755990344827588, -1.550220923076923 ], [ 29.755720862068966, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15024, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.550220923076923 ], [ 29.755990344827588, -1.5504904 ], [ 29.756259827586206, -1.5504904 ], [ 29.756259827586206, -1.550220923076923 ], [ 29.755990344827588, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15025, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.550220923076923 ], [ 29.756259827586206, -1.5504904 ], [ 29.756798793103449, -1.5504904 ], [ 29.756798793103449, -1.550220923076923 ], [ 29.756259827586206, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15026, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.550220923076923 ], [ 29.756798793103449, -1.5504904 ], [ 29.757068275862068, -1.5504904 ], [ 29.757068275862068, -1.550220923076923 ], [ 29.756798793103449, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15027, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.550220923076923 ], [ 29.757068275862068, -1.5504904 ], [ 29.75733775862069, -1.5504904 ], [ 29.75733775862069, -1.550220923076923 ], [ 29.757068275862068, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15028, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.550220923076923 ], [ 29.75733775862069, -1.5504904 ], [ 29.757607241379311, -1.5504904 ], [ 29.757607241379311, -1.550220923076923 ], [ 29.75733775862069, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15029, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.550220923076923 ], [ 29.757607241379311, -1.5504904 ], [ 29.757876724137933, -1.5504904 ], [ 29.757876724137933, -1.550220923076923 ], [ 29.757607241379311, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15030, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.550220923076923 ], [ 29.757876724137933, -1.5504904 ], [ 29.758146206896551, -1.5504904 ], [ 29.758146206896551, -1.550220923076923 ], [ 29.757876724137933, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15031, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.550220923076923 ], [ 29.758146206896551, -1.5504904 ], [ 29.758415689655173, -1.5504904 ], [ 29.758415689655173, -1.550220923076923 ], [ 29.758146206896551, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15032, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.550220923076923 ], [ 29.758415689655173, -1.5504904 ], [ 29.758685172413795, -1.5504904 ], [ 29.758685172413795, -1.550220923076923 ], [ 29.758415689655173, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15033, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.550220923076923 ], [ 29.758685172413795, -1.5504904 ], [ 29.758954655172413, -1.5504904 ], [ 29.758954655172413, -1.550220923076923 ], [ 29.758685172413795, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15034, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.550220923076923 ], [ 29.758954655172413, -1.5504904 ], [ 29.759224137931035, -1.5504904 ], [ 29.759224137931035, -1.550220923076923 ], [ 29.758954655172413, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15035, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.550220923076923 ], [ 29.759224137931035, -1.5504904 ], [ 29.759493620689657, -1.5504904 ], [ 29.759493620689657, -1.550220923076923 ], [ 29.759224137931035, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15036, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.550220923076923 ], [ 29.759493620689657, -1.5504904 ], [ 29.759763103448275, -1.5504904 ], [ 29.759763103448275, -1.550220923076923 ], [ 29.759493620689657, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15037, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.550220923076923 ], [ 29.759763103448275, -1.550759876923077 ], [ 29.760302068965519, -1.550759876923077 ], [ 29.760302068965519, -1.5504904 ], [ 29.760032586206897, -1.5504904 ], [ 29.760032586206897, -1.550220923076923 ], [ 29.759763103448275, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15038, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.550220923076923 ], [ 29.760032586206897, -1.5504904 ], [ 29.760302068965519, -1.5504904 ], [ 29.760302068965519, -1.550220923076923 ], [ 29.760032586206897, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15039, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.550220923076923 ], [ 29.760302068965519, -1.5504904 ], [ 29.760571551724137, -1.5504904 ], [ 29.760571551724137, -1.550220923076923 ], [ 29.760302068965519, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15040, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.550220923076923 ], [ 29.760571551724137, -1.5504904 ], [ 29.760841034482759, -1.5504904 ], [ 29.760841034482759, -1.550220923076923 ], [ 29.760571551724137, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15041, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.550220923076923 ], [ 29.760841034482759, -1.5504904 ], [ 29.761110517241381, -1.5504904 ], [ 29.761110517241381, -1.550220923076923 ], [ 29.760841034482759, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15042, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.550220923076923 ], [ 29.761110517241381, -1.5504904 ], [ 29.76138, -1.5504904 ], [ 29.76138, -1.550220923076923 ], [ 29.761110517241381, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15043, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.550220923076923 ], [ 29.76138, -1.5504904 ], [ 29.761649482758621, -1.5504904 ], [ 29.761649482758621, -1.550220923076923 ], [ 29.76138, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15044, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.550220923076923 ], [ 29.761649482758621, -1.5504904 ], [ 29.761918965517243, -1.5504904 ], [ 29.761918965517243, -1.550220923076923 ], [ 29.761649482758621, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15045, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.550220923076923 ], [ 29.761918965517243, -1.5504904 ], [ 29.762188448275861, -1.5504904 ], [ 29.762188448275861, -1.550220923076923 ], [ 29.761918965517243, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15046, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.550220923076923 ], [ 29.762188448275861, -1.5504904 ], [ 29.762457931034483, -1.5504904 ], [ 29.762457931034483, -1.550220923076923 ], [ 29.762188448275861, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15047, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.550220923076923 ], [ 29.762457931034483, -1.5504904 ], [ 29.762727413793105, -1.5504904 ], [ 29.762727413793105, -1.550220923076923 ], [ 29.762457931034483, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15048, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.550220923076923 ], [ 29.762727413793105, -1.5504904 ], [ 29.762996896551723, -1.5504904 ], [ 29.762996896551723, -1.550220923076923 ], [ 29.762727413793105, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15049, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.550220923076923 ], [ 29.762996896551723, -1.5504904 ], [ 29.763266379310345, -1.5504904 ], [ 29.763266379310345, -1.550220923076923 ], [ 29.762996896551723, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15050, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.550220923076923 ], [ 29.763266379310345, -1.5504904 ], [ 29.763535862068967, -1.5504904 ], [ 29.763535862068967, -1.550220923076923 ], [ 29.763266379310345, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15051, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.550220923076923 ], [ 29.763535862068967, -1.5504904 ], [ 29.763805344827588, -1.5504904 ], [ 29.763805344827588, -1.550220923076923 ], [ 29.763535862068967, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15052, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.550220923076923 ], [ 29.763805344827588, -1.5504904 ], [ 29.764074827586207, -1.5504904 ], [ 29.764074827586207, -1.550220923076923 ], [ 29.763805344827588, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15053, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.550220923076923 ], [ 29.764074827586207, -1.5504904 ], [ 29.764344310344828, -1.5504904 ], [ 29.764344310344828, -1.550220923076923 ], [ 29.764074827586207, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15054, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.550220923076923 ], [ 29.764344310344828, -1.5504904 ], [ 29.76461379310345, -1.5504904 ], [ 29.76461379310345, -1.550220923076923 ], [ 29.764344310344828, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15055, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.550220923076923 ], [ 29.76461379310345, -1.5504904 ], [ 29.764883275862069, -1.5504904 ], [ 29.764883275862069, -1.550220923076923 ], [ 29.76461379310345, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15056, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.550220923076923 ], [ 29.764883275862069, -1.5504904 ], [ 29.76515275862069, -1.5504904 ], [ 29.76515275862069, -1.550220923076923 ], [ 29.764883275862069, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15057, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.550220923076923 ], [ 29.76515275862069, -1.5504904 ], [ 29.765422241379312, -1.5504904 ], [ 29.765422241379312, -1.550220923076923 ], [ 29.76515275862069, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15058, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.550220923076923 ], [ 29.765422241379312, -1.550759876923077 ], [ 29.765961206896552, -1.550759876923077 ], [ 29.765961206896552, -1.550220923076923 ], [ 29.765422241379312, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15059, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.550220923076923 ], [ 29.765961206896552, -1.5504904 ], [ 29.766230689655174, -1.5504904 ], [ 29.766230689655174, -1.550220923076923 ], [ 29.765961206896552, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15060, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.550220923076923 ], [ 29.766230689655174, -1.5504904 ], [ 29.766500172413792, -1.5504904 ], [ 29.766500172413792, -1.550220923076923 ], [ 29.766230689655174, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15061, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.550220923076923 ], [ 29.766500172413792, -1.5504904 ], [ 29.766769655172414, -1.5504904 ], [ 29.766769655172414, -1.550220923076923 ], [ 29.766500172413792, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15062, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.550220923076923 ], [ 29.766769655172414, -1.5504904 ], [ 29.767039137931036, -1.5504904 ], [ 29.767039137931036, -1.550220923076923 ], [ 29.766769655172414, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15063, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767039137931036, -1.550220923076923 ], [ 29.767039137931036, -1.5504904 ], [ 29.767847586206898, -1.5504904 ], [ 29.767847586206898, -1.550220923076923 ], [ 29.767039137931036, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15064, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.5504904 ], [ 29.753565, -1.552376738461538 ], [ 29.75383448275862, -1.552376738461538 ], [ 29.75383448275862, -1.5504904 ], [ 29.753565, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15065, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.5504904 ], [ 29.75383448275862, -1.552376738461538 ], [ 29.754103965517242, -1.552376738461538 ], [ 29.754103965517242, -1.5504904 ], [ 29.75383448275862, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15066, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.5504904 ], [ 29.754103965517242, -1.550759876923077 ], [ 29.754373448275864, -1.550759876923077 ], [ 29.754373448275864, -1.5504904 ], [ 29.754103965517242, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15067, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.5504904 ], [ 29.754642931034482, -1.550759876923077 ], [ 29.754912413793104, -1.550759876923077 ], [ 29.754912413793104, -1.5504904 ], [ 29.754642931034482, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15068, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.5504904 ], [ 29.754912413793104, -1.550759876923077 ], [ 29.755181896551726, -1.550759876923077 ], [ 29.755181896551726, -1.5504904 ], [ 29.754912413793104, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15069, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.5504904 ], [ 29.755181896551726, -1.550759876923077 ], [ 29.755451379310344, -1.550759876923077 ], [ 29.755451379310344, -1.5504904 ], [ 29.755181896551726, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15070, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.5504904 ], [ 29.755451379310344, -1.550759876923077 ], [ 29.755720862068966, -1.550759876923077 ], [ 29.755720862068966, -1.5504904 ], [ 29.755451379310344, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15071, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.5504904 ], [ 29.755720862068966, -1.550759876923077 ], [ 29.755990344827588, -1.550759876923077 ], [ 29.755990344827588, -1.5504904 ], [ 29.755720862068966, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15072, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.5504904 ], [ 29.755990344827588, -1.550759876923077 ], [ 29.756259827586206, -1.550759876923077 ], [ 29.756259827586206, -1.5504904 ], [ 29.755990344827588, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15073, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.5504904 ], [ 29.756259827586206, -1.550759876923077 ], [ 29.756798793103449, -1.550759876923077 ], [ 29.756798793103449, -1.5504904 ], [ 29.756259827586206, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15074, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.5504904 ], [ 29.756798793103449, -1.550759876923077 ], [ 29.757068275862068, -1.550759876923077 ], [ 29.757068275862068, -1.5504904 ], [ 29.756798793103449, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15075, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.5504904 ], [ 29.757068275862068, -1.550759876923077 ], [ 29.75733775862069, -1.550759876923077 ], [ 29.75733775862069, -1.5504904 ], [ 29.757068275862068, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15076, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.5504904 ], [ 29.75733775862069, -1.550759876923077 ], [ 29.757607241379311, -1.550759876923077 ], [ 29.757607241379311, -1.5504904 ], [ 29.75733775862069, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15077, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.5504904 ], [ 29.757607241379311, -1.550759876923077 ], [ 29.757876724137933, -1.550759876923077 ], [ 29.757876724137933, -1.5504904 ], [ 29.757607241379311, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15078, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.5504904 ], [ 29.757876724137933, -1.550759876923077 ], [ 29.758146206896551, -1.550759876923077 ], [ 29.758146206896551, -1.5504904 ], [ 29.757876724137933, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15079, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.5504904 ], [ 29.758146206896551, -1.550759876923077 ], [ 29.758415689655173, -1.550759876923077 ], [ 29.758415689655173, -1.5504904 ], [ 29.758146206896551, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15080, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.5504904 ], [ 29.758415689655173, -1.550759876923077 ], [ 29.758685172413795, -1.550759876923077 ], [ 29.758685172413795, -1.5504904 ], [ 29.758415689655173, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15081, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5504904 ], [ 29.758685172413795, -1.550759876923077 ], [ 29.758954655172413, -1.550759876923077 ], [ 29.758954655172413, -1.5504904 ], [ 29.758685172413795, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15082, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.5504904 ], [ 29.758954655172413, -1.550759876923077 ], [ 29.759224137931035, -1.550759876923077 ], [ 29.759224137931035, -1.5504904 ], [ 29.758954655172413, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15083, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.5504904 ], [ 29.759224137931035, -1.550759876923077 ], [ 29.759493620689657, -1.550759876923077 ], [ 29.759493620689657, -1.5504904 ], [ 29.759224137931035, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15084, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.5504904 ], [ 29.759493620689657, -1.550759876923077 ], [ 29.759763103448275, -1.550759876923077 ], [ 29.759763103448275, -1.5504904 ], [ 29.759493620689657, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15085, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.5504904 ], [ 29.760302068965519, -1.550759876923077 ], [ 29.760571551724137, -1.550759876923077 ], [ 29.760571551724137, -1.5504904 ], [ 29.760302068965519, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15086, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.5504904 ], [ 29.760571551724137, -1.550759876923077 ], [ 29.760841034482759, -1.550759876923077 ], [ 29.760841034482759, -1.5504904 ], [ 29.760571551724137, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15087, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5504904 ], [ 29.760841034482759, -1.550759876923077 ], [ 29.761110517241381, -1.550759876923077 ], [ 29.761110517241381, -1.5504904 ], [ 29.760841034482759, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15088, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5504904 ], [ 29.761110517241381, -1.550759876923077 ], [ 29.76138, -1.550759876923077 ], [ 29.76138, -1.5504904 ], [ 29.761110517241381, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15089, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.5504904 ], [ 29.76138, -1.550759876923077 ], [ 29.761649482758621, -1.550759876923077 ], [ 29.761649482758621, -1.5504904 ], [ 29.76138, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15090, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.5504904 ], [ 29.761649482758621, -1.550759876923077 ], [ 29.761918965517243, -1.550759876923077 ], [ 29.761918965517243, -1.5504904 ], [ 29.761649482758621, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15091, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5504904 ], [ 29.761918965517243, -1.550759876923077 ], [ 29.762188448275861, -1.550759876923077 ], [ 29.762188448275861, -1.5504904 ], [ 29.761918965517243, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15092, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5504904 ], [ 29.762188448275861, -1.550759876923077 ], [ 29.762457931034483, -1.550759876923077 ], [ 29.762457931034483, -1.5504904 ], [ 29.762188448275861, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15093, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5504904 ], [ 29.762457931034483, -1.550759876923077 ], [ 29.762727413793105, -1.550759876923077 ], [ 29.762727413793105, -1.5504904 ], [ 29.762457931034483, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15094, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5504904 ], [ 29.762727413793105, -1.550759876923077 ], [ 29.762996896551723, -1.550759876923077 ], [ 29.762996896551723, -1.5504904 ], [ 29.762727413793105, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15095, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.5504904 ], [ 29.762996896551723, -1.550759876923077 ], [ 29.763266379310345, -1.550759876923077 ], [ 29.763266379310345, -1.5504904 ], [ 29.762996896551723, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15096, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5504904 ], [ 29.763266379310345, -1.550759876923077 ], [ 29.763535862068967, -1.550759876923077 ], [ 29.763535862068967, -1.5504904 ], [ 29.763266379310345, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15097, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5504904 ], [ 29.763535862068967, -1.550759876923077 ], [ 29.763805344827588, -1.550759876923077 ], [ 29.763805344827588, -1.5504904 ], [ 29.763535862068967, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15098, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5504904 ], [ 29.763805344827588, -1.550759876923077 ], [ 29.764074827586207, -1.550759876923077 ], [ 29.764074827586207, -1.5504904 ], [ 29.763805344827588, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15099, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5504904 ], [ 29.764074827586207, -1.550759876923077 ], [ 29.764344310344828, -1.550759876923077 ], [ 29.764344310344828, -1.5504904 ], [ 29.764074827586207, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15100, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.5504904 ], [ 29.764344310344828, -1.550759876923077 ], [ 29.76461379310345, -1.550759876923077 ], [ 29.76461379310345, -1.5504904 ], [ 29.764344310344828, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15101, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.5504904 ], [ 29.76461379310345, -1.550759876923077 ], [ 29.764883275862069, -1.550759876923077 ], [ 29.764883275862069, -1.5504904 ], [ 29.76461379310345, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15102, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.5504904 ], [ 29.764883275862069, -1.550759876923077 ], [ 29.76515275862069, -1.550759876923077 ], [ 29.76515275862069, -1.5504904 ], [ 29.764883275862069, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15103, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.5504904 ], [ 29.76515275862069, -1.550759876923077 ], [ 29.765422241379312, -1.550759876923077 ], [ 29.765422241379312, -1.5504904 ], [ 29.76515275862069, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15104, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.5504904 ], [ 29.765961206896552, -1.551029353846154 ], [ 29.766230689655174, -1.551029353846154 ], [ 29.766230689655174, -1.5504904 ], [ 29.765961206896552, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15105, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.5504904 ], [ 29.766230689655174, -1.551029353846154 ], [ 29.766500172413792, -1.551029353846154 ], [ 29.766500172413792, -1.5504904 ], [ 29.766230689655174, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15106, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.5504904 ], [ 29.766500172413792, -1.550759876923077 ], [ 29.766769655172414, -1.550759876923077 ], [ 29.766769655172414, -1.5504904 ], [ 29.766500172413792, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15107, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766769655172414, -1.5504904 ], [ 29.766769655172414, -1.550759876923077 ], [ 29.767039137931036, -1.550759876923077 ], [ 29.767039137931036, -1.5504904 ], [ 29.766769655172414, -1.5504904 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15108, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.550759876923077 ], [ 29.754103965517242, -1.552107261538461 ], [ 29.754373448275864, -1.552107261538461 ], [ 29.754373448275864, -1.550759876923077 ], [ 29.754103965517242, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15109, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.550759876923077 ], [ 29.754373448275864, -1.552107261538461 ], [ 29.754642931034482, -1.552107261538461 ], [ 29.754642931034482, -1.550759876923077 ], [ 29.754373448275864, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15110, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.550759876923077 ], [ 29.754642931034482, -1.552107261538461 ], [ 29.754912413793104, -1.552107261538461 ], [ 29.754912413793104, -1.550759876923077 ], [ 29.754642931034482, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15111, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.550759876923077 ], [ 29.754912413793104, -1.552107261538461 ], [ 29.755181896551726, -1.552107261538461 ], [ 29.755181896551726, -1.550759876923077 ], [ 29.754912413793104, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15112, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.550759876923077 ], [ 29.755181896551726, -1.551029353846154 ], [ 29.755451379310344, -1.551029353846154 ], [ 29.755451379310344, -1.550759876923077 ], [ 29.755181896551726, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15113, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.550759876923077 ], [ 29.755451379310344, -1.551029353846154 ], [ 29.755720862068966, -1.551029353846154 ], [ 29.755720862068966, -1.550759876923077 ], [ 29.755451379310344, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15114, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.550759876923077 ], [ 29.755720862068966, -1.551029353846154 ], [ 29.755990344827588, -1.551029353846154 ], [ 29.755990344827588, -1.550759876923077 ], [ 29.755720862068966, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15115, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.550759876923077 ], [ 29.755990344827588, -1.551029353846154 ], [ 29.756259827586206, -1.551029353846154 ], [ 29.756259827586206, -1.550759876923077 ], [ 29.755990344827588, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15116, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.550759876923077 ], [ 29.756259827586206, -1.551029353846154 ], [ 29.756798793103449, -1.551029353846154 ], [ 29.756798793103449, -1.550759876923077 ], [ 29.756259827586206, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15117, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.550759876923077 ], [ 29.756798793103449, -1.551029353846154 ], [ 29.757068275862068, -1.551029353846154 ], [ 29.757068275862068, -1.550759876923077 ], [ 29.756798793103449, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15118, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.550759876923077 ], [ 29.757068275862068, -1.551029353846154 ], [ 29.75733775862069, -1.551029353846154 ], [ 29.75733775862069, -1.550759876923077 ], [ 29.757068275862068, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15119, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.550759876923077 ], [ 29.75733775862069, -1.551029353846154 ], [ 29.757607241379311, -1.551029353846154 ], [ 29.757607241379311, -1.550759876923077 ], [ 29.75733775862069, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15120, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.550759876923077 ], [ 29.757607241379311, -1.551029353846154 ], [ 29.757876724137933, -1.551029353846154 ], [ 29.757876724137933, -1.550759876923077 ], [ 29.757607241379311, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15121, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.550759876923077 ], [ 29.757876724137933, -1.551029353846154 ], [ 29.758146206896551, -1.551029353846154 ], [ 29.758146206896551, -1.550759876923077 ], [ 29.757876724137933, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15122, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.550759876923077 ], [ 29.758146206896551, -1.551029353846154 ], [ 29.758415689655173, -1.551029353846154 ], [ 29.758415689655173, -1.550759876923077 ], [ 29.758146206896551, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15123, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.550759876923077 ], [ 29.758415689655173, -1.551029353846154 ], [ 29.758685172413795, -1.551029353846154 ], [ 29.758685172413795, -1.550759876923077 ], [ 29.758415689655173, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15124, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.550759876923077 ], [ 29.758685172413795, -1.551029353846154 ], [ 29.758954655172413, -1.551029353846154 ], [ 29.758954655172413, -1.550759876923077 ], [ 29.758685172413795, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15125, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.550759876923077 ], [ 29.758954655172413, -1.551029353846154 ], [ 29.759224137931035, -1.551029353846154 ], [ 29.759224137931035, -1.550759876923077 ], [ 29.758954655172413, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15126, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.550759876923077 ], [ 29.759224137931035, -1.551029353846154 ], [ 29.760302068965519, -1.551029353846154 ], [ 29.760302068965519, -1.550759876923077 ], [ 29.759224137931035, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15127, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.550759876923077 ], [ 29.760302068965519, -1.551029353846154 ], [ 29.760571551724137, -1.551029353846154 ], [ 29.760571551724137, -1.550759876923077 ], [ 29.760302068965519, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15128, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.550759876923077 ], [ 29.760571551724137, -1.551029353846154 ], [ 29.760841034482759, -1.551029353846154 ], [ 29.760841034482759, -1.550759876923077 ], [ 29.760571551724137, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15129, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.550759876923077 ], [ 29.760841034482759, -1.551029353846154 ], [ 29.761110517241381, -1.551029353846154 ], [ 29.761110517241381, -1.550759876923077 ], [ 29.760841034482759, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15130, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.550759876923077 ], [ 29.761110517241381, -1.551029353846154 ], [ 29.76138, -1.551029353846154 ], [ 29.76138, -1.550759876923077 ], [ 29.761110517241381, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15131, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.550759876923077 ], [ 29.76138, -1.551029353846154 ], [ 29.761649482758621, -1.551029353846154 ], [ 29.761649482758621, -1.550759876923077 ], [ 29.76138, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15132, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.550759876923077 ], [ 29.761649482758621, -1.551029353846154 ], [ 29.761918965517243, -1.551029353846154 ], [ 29.761918965517243, -1.550759876923077 ], [ 29.761649482758621, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15133, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.550759876923077 ], [ 29.761918965517243, -1.551029353846154 ], [ 29.762188448275861, -1.551029353846154 ], [ 29.762188448275861, -1.550759876923077 ], [ 29.761918965517243, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15134, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.550759876923077 ], [ 29.762188448275861, -1.551029353846154 ], [ 29.762457931034483, -1.551029353846154 ], [ 29.762457931034483, -1.550759876923077 ], [ 29.762188448275861, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15135, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.550759876923077 ], [ 29.762457931034483, -1.551029353846154 ], [ 29.762727413793105, -1.551029353846154 ], [ 29.762727413793105, -1.550759876923077 ], [ 29.762457931034483, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15136, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.550759876923077 ], [ 29.762727413793105, -1.551029353846154 ], [ 29.762996896551723, -1.551029353846154 ], [ 29.762996896551723, -1.550759876923077 ], [ 29.762727413793105, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15137, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.550759876923077 ], [ 29.762996896551723, -1.551029353846154 ], [ 29.763266379310345, -1.551029353846154 ], [ 29.763266379310345, -1.550759876923077 ], [ 29.762996896551723, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15138, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.550759876923077 ], [ 29.763266379310345, -1.551029353846154 ], [ 29.763535862068967, -1.551029353846154 ], [ 29.763535862068967, -1.550759876923077 ], [ 29.763266379310345, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15139, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.550759876923077 ], [ 29.763535862068967, -1.551029353846154 ], [ 29.763805344827588, -1.551029353846154 ], [ 29.763805344827588, -1.550759876923077 ], [ 29.763535862068967, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15140, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.550759876923077 ], [ 29.763805344827588, -1.551029353846154 ], [ 29.764074827586207, -1.551029353846154 ], [ 29.764074827586207, -1.550759876923077 ], [ 29.763805344827588, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15141, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.550759876923077 ], [ 29.764074827586207, -1.551029353846154 ], [ 29.764344310344828, -1.551029353846154 ], [ 29.764344310344828, -1.550759876923077 ], [ 29.764074827586207, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15142, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.550759876923077 ], [ 29.764344310344828, -1.551029353846154 ], [ 29.76461379310345, -1.551029353846154 ], [ 29.76461379310345, -1.550759876923077 ], [ 29.764344310344828, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15143, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.550759876923077 ], [ 29.76461379310345, -1.551029353846154 ], [ 29.764883275862069, -1.551029353846154 ], [ 29.764883275862069, -1.550759876923077 ], [ 29.76461379310345, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15144, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.550759876923077 ], [ 29.764883275862069, -1.551029353846154 ], [ 29.76515275862069, -1.551029353846154 ], [ 29.76515275862069, -1.550759876923077 ], [ 29.764883275862069, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15145, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.550759876923077 ], [ 29.76515275862069, -1.551298830769231 ], [ 29.765422241379312, -1.551298830769231 ], [ 29.765422241379312, -1.550759876923077 ], [ 29.76515275862069, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15146, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.550759876923077 ], [ 29.765422241379312, -1.551029353846154 ], [ 29.765961206896552, -1.551029353846154 ], [ 29.765961206896552, -1.550759876923077 ], [ 29.765422241379312, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15147, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766500172413792, -1.550759876923077 ], [ 29.766500172413792, -1.551298830769231 ], [ 29.766769655172414, -1.551298830769231 ], [ 29.766769655172414, -1.550759876923077 ], [ 29.766500172413792, -1.550759876923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15148, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.551029353846154 ], [ 29.755181896551726, -1.551837784615385 ], [ 29.755451379310344, -1.551837784615385 ], [ 29.755451379310344, -1.551029353846154 ], [ 29.755181896551726, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15149, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.551029353846154 ], [ 29.755451379310344, -1.551837784615385 ], [ 29.755720862068966, -1.551837784615385 ], [ 29.755720862068966, -1.551029353846154 ], [ 29.755451379310344, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15150, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.551029353846154 ], [ 29.755720862068966, -1.551837784615385 ], [ 29.755990344827588, -1.551837784615385 ], [ 29.755990344827588, -1.551029353846154 ], [ 29.755720862068966, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15151, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.551029353846154 ], [ 29.755990344827588, -1.551298830769231 ], [ 29.756259827586206, -1.551298830769231 ], [ 29.756259827586206, -1.551029353846154 ], [ 29.755990344827588, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15152, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.551029353846154 ], [ 29.756259827586206, -1.551298830769231 ], [ 29.756798793103449, -1.551298830769231 ], [ 29.756798793103449, -1.551029353846154 ], [ 29.756259827586206, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15153, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.551029353846154 ], [ 29.756798793103449, -1.551298830769231 ], [ 29.757068275862068, -1.551298830769231 ], [ 29.757068275862068, -1.551029353846154 ], [ 29.756798793103449, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15154, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.551029353846154 ], [ 29.757068275862068, -1.551298830769231 ], [ 29.75733775862069, -1.551298830769231 ], [ 29.75733775862069, -1.551029353846154 ], [ 29.757068275862068, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15155, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.551029353846154 ], [ 29.75733775862069, -1.551298830769231 ], [ 29.757607241379311, -1.551298830769231 ], [ 29.757607241379311, -1.551029353846154 ], [ 29.75733775862069, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15156, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.551029353846154 ], [ 29.757607241379311, -1.551298830769231 ], [ 29.757876724137933, -1.551298830769231 ], [ 29.757876724137933, -1.551029353846154 ], [ 29.757607241379311, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15157, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.551029353846154 ], [ 29.757876724137933, -1.552107261538461 ], [ 29.758146206896551, -1.552107261538461 ], [ 29.758146206896551, -1.551029353846154 ], [ 29.757876724137933, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15158, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.551029353846154 ], [ 29.758146206896551, -1.551298830769231 ], [ 29.758415689655173, -1.551298830769231 ], [ 29.758415689655173, -1.551029353846154 ], [ 29.758146206896551, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15159, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.551029353846154 ], [ 29.758685172413795, -1.551298830769231 ], [ 29.760302068965519, -1.551298830769231 ], [ 29.760302068965519, -1.551029353846154 ], [ 29.758685172413795, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15160, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.551029353846154 ], [ 29.760302068965519, -1.551298830769231 ], [ 29.760571551724137, -1.551298830769231 ], [ 29.760571551724137, -1.551029353846154 ], [ 29.760302068965519, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15161, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.551029353846154 ], [ 29.760571551724137, -1.551298830769231 ], [ 29.760841034482759, -1.551298830769231 ], [ 29.760841034482759, -1.551029353846154 ], [ 29.760571551724137, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15162, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.551029353846154 ], [ 29.760841034482759, -1.551298830769231 ], [ 29.761110517241381, -1.551298830769231 ], [ 29.761110517241381, -1.551029353846154 ], [ 29.760841034482759, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15163, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.551029353846154 ], [ 29.761110517241381, -1.551298830769231 ], [ 29.76138, -1.551298830769231 ], [ 29.76138, -1.551029353846154 ], [ 29.761110517241381, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15164, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.551029353846154 ], [ 29.76138, -1.551298830769231 ], [ 29.761649482758621, -1.551298830769231 ], [ 29.761649482758621, -1.551029353846154 ], [ 29.76138, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15165, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.551029353846154 ], [ 29.761649482758621, -1.551298830769231 ], [ 29.761918965517243, -1.551298830769231 ], [ 29.761918965517243, -1.551029353846154 ], [ 29.761649482758621, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15166, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.551029353846154 ], [ 29.761918965517243, -1.551298830769231 ], [ 29.762188448275861, -1.551298830769231 ], [ 29.762188448275861, -1.551029353846154 ], [ 29.761918965517243, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15167, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.551029353846154 ], [ 29.762188448275861, -1.551298830769231 ], [ 29.762457931034483, -1.551298830769231 ], [ 29.762457931034483, -1.551029353846154 ], [ 29.762188448275861, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15168, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.551029353846154 ], [ 29.762457931034483, -1.551298830769231 ], [ 29.762727413793105, -1.551298830769231 ], [ 29.762727413793105, -1.551029353846154 ], [ 29.762457931034483, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15169, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.551029353846154 ], [ 29.762727413793105, -1.551298830769231 ], [ 29.762996896551723, -1.551298830769231 ], [ 29.762996896551723, -1.551029353846154 ], [ 29.762727413793105, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15170, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.551029353846154 ], [ 29.762996896551723, -1.551298830769231 ], [ 29.763266379310345, -1.551298830769231 ], [ 29.763266379310345, -1.551029353846154 ], [ 29.762996896551723, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15171, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.551029353846154 ], [ 29.763266379310345, -1.551298830769231 ], [ 29.763535862068967, -1.551298830769231 ], [ 29.763535862068967, -1.551029353846154 ], [ 29.763266379310345, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15172, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.551029353846154 ], [ 29.763535862068967, -1.551298830769231 ], [ 29.763805344827588, -1.551298830769231 ], [ 29.763805344827588, -1.551029353846154 ], [ 29.763535862068967, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15173, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.551029353846154 ], [ 29.763805344827588, -1.551298830769231 ], [ 29.764074827586207, -1.551298830769231 ], [ 29.764074827586207, -1.551029353846154 ], [ 29.763805344827588, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15174, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.551029353846154 ], [ 29.764074827586207, -1.551298830769231 ], [ 29.764344310344828, -1.551298830769231 ], [ 29.764344310344828, -1.551029353846154 ], [ 29.764074827586207, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15175, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.551029353846154 ], [ 29.764344310344828, -1.551298830769231 ], [ 29.76461379310345, -1.551298830769231 ], [ 29.76461379310345, -1.551029353846154 ], [ 29.764344310344828, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15176, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.551029353846154 ], [ 29.76461379310345, -1.551298830769231 ], [ 29.764883275862069, -1.551298830769231 ], [ 29.764883275862069, -1.551029353846154 ], [ 29.76461379310345, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15177, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.551029353846154 ], [ 29.764883275862069, -1.551298830769231 ], [ 29.76515275862069, -1.551298830769231 ], [ 29.76515275862069, -1.551029353846154 ], [ 29.764883275862069, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15178, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.551029353846154 ], [ 29.765422241379312, -1.551568307692308 ], [ 29.765961206896552, -1.551568307692308 ], [ 29.765961206896552, -1.551029353846154 ], [ 29.765422241379312, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15179, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.551029353846154 ], [ 29.765961206896552, -1.551568307692308 ], [ 29.766230689655174, -1.551568307692308 ], [ 29.766230689655174, -1.551029353846154 ], [ 29.765961206896552, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15180, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.551029353846154 ], [ 29.766230689655174, -1.551298830769231 ], [ 29.766500172413792, -1.551298830769231 ], [ 29.766500172413792, -1.551029353846154 ], [ 29.766230689655174, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15181, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.551298830769231 ], [ 29.755990344827588, -1.551568307692308 ], [ 29.756259827586206, -1.551568307692308 ], [ 29.756259827586206, -1.551298830769231 ], [ 29.755990344827588, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15182, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.551298830769231 ], [ 29.756259827586206, -1.551568307692308 ], [ 29.756798793103449, -1.551568307692308 ], [ 29.756798793103449, -1.551298830769231 ], [ 29.756259827586206, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15183, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.551298830769231 ], [ 29.756798793103449, -1.551568307692308 ], [ 29.757068275862068, -1.551568307692308 ], [ 29.757068275862068, -1.551298830769231 ], [ 29.756798793103449, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15184, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.551298830769231 ], [ 29.757068275862068, -1.551837784615385 ], [ 29.75733775862069, -1.551837784615385 ], [ 29.75733775862069, -1.551298830769231 ], [ 29.757068275862068, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15185, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.551298830769231 ], [ 29.75733775862069, -1.551837784615385 ], [ 29.757607241379311, -1.551837784615385 ], [ 29.757607241379311, -1.551298830769231 ], [ 29.75733775862069, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15186, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.551298830769231 ], [ 29.757607241379311, -1.552107261538461 ], [ 29.757876724137933, -1.552107261538461 ], [ 29.757876724137933, -1.551298830769231 ], [ 29.757607241379311, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15187, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.551029353846154 ], [ 29.758685172413795, -1.551029353846154 ], [ 29.758685172413795, -1.551568307692308 ], [ 29.758146206896551, -1.551568307692308 ], [ 29.758146206896551, -1.551298830769231 ], [ 29.758415689655173, -1.551298830769231 ], [ 29.758415689655173, -1.551029353846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15188, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.551298830769231 ], [ 29.758685172413795, -1.551568307692308 ], [ 29.759493620689657, -1.551568307692308 ], [ 29.759493620689657, -1.551298830769231 ], [ 29.758685172413795, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15189, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.551298830769231 ], [ 29.759493620689657, -1.551568307692308 ], [ 29.760302068965519, -1.551568307692308 ], [ 29.760302068965519, -1.551298830769231 ], [ 29.759493620689657, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15190, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.551298830769231 ], [ 29.760302068965519, -1.551568307692308 ], [ 29.760571551724137, -1.551568307692308 ], [ 29.760571551724137, -1.551298830769231 ], [ 29.760302068965519, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15191, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.551298830769231 ], [ 29.760571551724137, -1.551568307692308 ], [ 29.760841034482759, -1.551568307692308 ], [ 29.760841034482759, -1.551298830769231 ], [ 29.760571551724137, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15192, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.551298830769231 ], [ 29.760841034482759, -1.551568307692308 ], [ 29.761110517241381, -1.551568307692308 ], [ 29.761110517241381, -1.551298830769231 ], [ 29.760841034482759, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15193, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.551298830769231 ], [ 29.761110517241381, -1.551568307692308 ], [ 29.76138, -1.551568307692308 ], [ 29.76138, -1.551298830769231 ], [ 29.761110517241381, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15194, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.551298830769231 ], [ 29.76138, -1.551568307692308 ], [ 29.761649482758621, -1.551568307692308 ], [ 29.761649482758621, -1.551298830769231 ], [ 29.76138, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15195, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.551298830769231 ], [ 29.761649482758621, -1.551568307692308 ], [ 29.761918965517243, -1.551568307692308 ], [ 29.761918965517243, -1.551298830769231 ], [ 29.761649482758621, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15196, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.551298830769231 ], [ 29.761918965517243, -1.551568307692308 ], [ 29.762188448275861, -1.551568307692308 ], [ 29.762188448275861, -1.551298830769231 ], [ 29.761918965517243, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15197, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.551298830769231 ], [ 29.762188448275861, -1.551568307692308 ], [ 29.762457931034483, -1.551568307692308 ], [ 29.762457931034483, -1.551298830769231 ], [ 29.762188448275861, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15198, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.551298830769231 ], [ 29.762457931034483, -1.551568307692308 ], [ 29.762727413793105, -1.551568307692308 ], [ 29.762727413793105, -1.551298830769231 ], [ 29.762457931034483, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15199, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.551298830769231 ], [ 29.762727413793105, -1.551568307692308 ], [ 29.762996896551723, -1.551568307692308 ], [ 29.762996896551723, -1.551298830769231 ], [ 29.762727413793105, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15200, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.551298830769231 ], [ 29.762996896551723, -1.551568307692308 ], [ 29.763266379310345, -1.551568307692308 ], [ 29.763266379310345, -1.551298830769231 ], [ 29.762996896551723, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15201, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.551298830769231 ], [ 29.763266379310345, -1.551568307692308 ], [ 29.763535862068967, -1.551568307692308 ], [ 29.763535862068967, -1.551298830769231 ], [ 29.763266379310345, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15202, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.551298830769231 ], [ 29.763535862068967, -1.551568307692308 ], [ 29.763805344827588, -1.551568307692308 ], [ 29.763805344827588, -1.551298830769231 ], [ 29.763535862068967, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15203, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.551298830769231 ], [ 29.763805344827588, -1.551568307692308 ], [ 29.764074827586207, -1.551568307692308 ], [ 29.764074827586207, -1.551298830769231 ], [ 29.763805344827588, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15204, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.551298830769231 ], [ 29.764074827586207, -1.551568307692308 ], [ 29.764344310344828, -1.551568307692308 ], [ 29.764344310344828, -1.551298830769231 ], [ 29.764074827586207, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15205, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.551298830769231 ], [ 29.764344310344828, -1.551568307692308 ], [ 29.76461379310345, -1.551568307692308 ], [ 29.76461379310345, -1.551298830769231 ], [ 29.764344310344828, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15206, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.551298830769231 ], [ 29.76461379310345, -1.551568307692308 ], [ 29.764883275862069, -1.551568307692308 ], [ 29.764883275862069, -1.551298830769231 ], [ 29.76461379310345, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15207, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.551298830769231 ], [ 29.764883275862069, -1.551837784615385 ], [ 29.76515275862069, -1.551837784615385 ], [ 29.76515275862069, -1.551298830769231 ], [ 29.764883275862069, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15208, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.551298830769231 ], [ 29.76515275862069, -1.551568307692308 ], [ 29.765422241379312, -1.551568307692308 ], [ 29.765422241379312, -1.551298830769231 ], [ 29.76515275862069, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15209, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766230689655174, -1.551298830769231 ], [ 29.766230689655174, -1.551837784615385 ], [ 29.766500172413792, -1.551837784615385 ], [ 29.766500172413792, -1.551298830769231 ], [ 29.766230689655174, -1.551298830769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15210, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.551568307692308 ], [ 29.755990344827588, -1.551837784615385 ], [ 29.756259827586206, -1.551837784615385 ], [ 29.756259827586206, -1.551568307692308 ], [ 29.755990344827588, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15211, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.551568307692308 ], [ 29.756259827586206, -1.551837784615385 ], [ 29.756798793103449, -1.551837784615385 ], [ 29.756798793103449, -1.551568307692308 ], [ 29.756259827586206, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15212, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.551568307692308 ], [ 29.756798793103449, -1.552107261538461 ], [ 29.757068275862068, -1.552107261538461 ], [ 29.757068275862068, -1.551568307692308 ], [ 29.756798793103449, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15213, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.551568307692308 ], [ 29.758146206896551, -1.551837784615385 ], [ 29.758415689655173, -1.551837784615385 ], [ 29.758415689655173, -1.551568307692308 ], [ 29.758146206896551, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15214, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.551568307692308 ], [ 29.758415689655173, -1.551837784615385 ], [ 29.758954655172413, -1.551837784615385 ], [ 29.758954655172413, -1.551568307692308 ], [ 29.758415689655173, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15215, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.551568307692308 ], [ 29.758954655172413, -1.551837784615385 ], [ 29.760841034482759, -1.551837784615385 ], [ 29.760841034482759, -1.551568307692308 ], [ 29.758954655172413, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15216, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.551568307692308 ], [ 29.760841034482759, -1.551837784615385 ], [ 29.761110517241381, -1.551837784615385 ], [ 29.761110517241381, -1.551568307692308 ], [ 29.760841034482759, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15217, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.551568307692308 ], [ 29.761110517241381, -1.551837784615385 ], [ 29.76138, -1.551837784615385 ], [ 29.76138, -1.551568307692308 ], [ 29.761110517241381, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15218, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.551568307692308 ], [ 29.76138, -1.551837784615385 ], [ 29.761649482758621, -1.551837784615385 ], [ 29.761649482758621, -1.551568307692308 ], [ 29.76138, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15219, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.551568307692308 ], [ 29.761649482758621, -1.551837784615385 ], [ 29.761918965517243, -1.551837784615385 ], [ 29.761918965517243, -1.551568307692308 ], [ 29.761649482758621, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15220, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.551568307692308 ], [ 29.761918965517243, -1.551837784615385 ], [ 29.762188448275861, -1.551837784615385 ], [ 29.762188448275861, -1.551568307692308 ], [ 29.761918965517243, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15221, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.551568307692308 ], [ 29.762188448275861, -1.551837784615385 ], [ 29.762457931034483, -1.551837784615385 ], [ 29.762457931034483, -1.551568307692308 ], [ 29.762188448275861, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15222, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.551568307692308 ], [ 29.762457931034483, -1.551837784615385 ], [ 29.762727413793105, -1.551837784615385 ], [ 29.762727413793105, -1.551568307692308 ], [ 29.762457931034483, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15223, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.551568307692308 ], [ 29.762727413793105, -1.551837784615385 ], [ 29.762996896551723, -1.551837784615385 ], [ 29.762996896551723, -1.551568307692308 ], [ 29.762727413793105, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15224, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.551568307692308 ], [ 29.762996896551723, -1.551837784615385 ], [ 29.763266379310345, -1.551837784615385 ], [ 29.763266379310345, -1.551568307692308 ], [ 29.762996896551723, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15225, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.551568307692308 ], [ 29.763266379310345, -1.551837784615385 ], [ 29.763535862068967, -1.551837784615385 ], [ 29.763535862068967, -1.551568307692308 ], [ 29.763266379310345, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15226, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.551568307692308 ], [ 29.763535862068967, -1.551837784615385 ], [ 29.763805344827588, -1.551837784615385 ], [ 29.763805344827588, -1.551568307692308 ], [ 29.763535862068967, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15227, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.551568307692308 ], [ 29.763805344827588, -1.551837784615385 ], [ 29.764074827586207, -1.551837784615385 ], [ 29.764074827586207, -1.551568307692308 ], [ 29.763805344827588, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15228, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.551568307692308 ], [ 29.764074827586207, -1.551837784615385 ], [ 29.764344310344828, -1.551837784615385 ], [ 29.764344310344828, -1.551568307692308 ], [ 29.764074827586207, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15229, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.551568307692308 ], [ 29.764344310344828, -1.551837784615385 ], [ 29.76461379310345, -1.551837784615385 ], [ 29.76461379310345, -1.551568307692308 ], [ 29.764344310344828, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15230, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.551568307692308 ], [ 29.76461379310345, -1.551837784615385 ], [ 29.764883275862069, -1.551837784615385 ], [ 29.764883275862069, -1.551568307692308 ], [ 29.76461379310345, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15231, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.551568307692308 ], [ 29.76515275862069, -1.552107261538461 ], [ 29.765422241379312, -1.552107261538461 ], [ 29.765422241379312, -1.551568307692308 ], [ 29.76515275862069, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15232, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.551568307692308 ], [ 29.765422241379312, -1.552107261538461 ], [ 29.765961206896552, -1.552107261538461 ], [ 29.765961206896552, -1.551568307692308 ], [ 29.765422241379312, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15233, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.551568307692308 ], [ 29.765961206896552, -1.551837784615385 ], [ 29.766230689655174, -1.551837784615385 ], [ 29.766230689655174, -1.551568307692308 ], [ 29.765961206896552, -1.551568307692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15234, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.551837784615385 ], [ 29.755181896551726, -1.552107261538461 ], [ 29.755451379310344, -1.552107261538461 ], [ 29.755451379310344, -1.551837784615385 ], [ 29.755181896551726, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15235, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.551837784615385 ], [ 29.755451379310344, -1.552107261538461 ], [ 29.755720862068966, -1.552107261538461 ], [ 29.755720862068966, -1.551837784615385 ], [ 29.755451379310344, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15236, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.551837784615385 ], [ 29.755720862068966, -1.552107261538461 ], [ 29.755990344827588, -1.552107261538461 ], [ 29.755990344827588, -1.551837784615385 ], [ 29.755720862068966, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15237, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.551837784615385 ], [ 29.755990344827588, -1.552376738461538 ], [ 29.756259827586206, -1.552376738461538 ], [ 29.756259827586206, -1.551837784615385 ], [ 29.755990344827588, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15238, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.551837784615385 ], [ 29.757068275862068, -1.552376738461538 ], [ 29.75733775862069, -1.552376738461538 ], [ 29.75733775862069, -1.551837784615385 ], [ 29.757068275862068, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15239, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.551837784615385 ], [ 29.758146206896551, -1.552107261538461 ], [ 29.758415689655173, -1.552107261538461 ], [ 29.758415689655173, -1.551837784615385 ], [ 29.758146206896551, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15240, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.551837784615385 ], [ 29.758415689655173, -1.552107261538461 ], [ 29.758954655172413, -1.552107261538461 ], [ 29.758954655172413, -1.551837784615385 ], [ 29.758415689655173, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15241, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.551837784615385 ], [ 29.758954655172413, -1.552107261538461 ], [ 29.760841034482759, -1.552107261538461 ], [ 29.760841034482759, -1.551837784615385 ], [ 29.758954655172413, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15242, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.551837784615385 ], [ 29.760841034482759, -1.552107261538461 ], [ 29.761110517241381, -1.552107261538461 ], [ 29.761110517241381, -1.551837784615385 ], [ 29.760841034482759, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15243, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.551837784615385 ], [ 29.761110517241381, -1.552107261538461 ], [ 29.76138, -1.552107261538461 ], [ 29.76138, -1.551837784615385 ], [ 29.761110517241381, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15244, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.551837784615385 ], [ 29.76138, -1.552107261538461 ], [ 29.761649482758621, -1.552107261538461 ], [ 29.761649482758621, -1.551837784615385 ], [ 29.76138, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15245, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.551837784615385 ], [ 29.761649482758621, -1.552107261538461 ], [ 29.761918965517243, -1.552107261538461 ], [ 29.761918965517243, -1.551837784615385 ], [ 29.761649482758621, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15246, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.551837784615385 ], [ 29.761918965517243, -1.552107261538461 ], [ 29.762188448275861, -1.552107261538461 ], [ 29.762188448275861, -1.551837784615385 ], [ 29.761918965517243, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15247, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.551837784615385 ], [ 29.762188448275861, -1.552107261538461 ], [ 29.762457931034483, -1.552107261538461 ], [ 29.762457931034483, -1.551837784615385 ], [ 29.762188448275861, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15248, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.551837784615385 ], [ 29.762457931034483, -1.552107261538461 ], [ 29.762727413793105, -1.552107261538461 ], [ 29.762727413793105, -1.551837784615385 ], [ 29.762457931034483, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15249, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.551837784615385 ], [ 29.762727413793105, -1.552107261538461 ], [ 29.762996896551723, -1.552107261538461 ], [ 29.762996896551723, -1.551837784615385 ], [ 29.762727413793105, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15250, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.551837784615385 ], [ 29.762996896551723, -1.552107261538461 ], [ 29.763266379310345, -1.552107261538461 ], [ 29.763266379310345, -1.551837784615385 ], [ 29.762996896551723, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15251, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.551837784615385 ], [ 29.763266379310345, -1.552107261538461 ], [ 29.763535862068967, -1.552107261538461 ], [ 29.763535862068967, -1.551837784615385 ], [ 29.763266379310345, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15252, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.551837784615385 ], [ 29.763535862068967, -1.552107261538461 ], [ 29.763805344827588, -1.552107261538461 ], [ 29.763805344827588, -1.551837784615385 ], [ 29.763535862068967, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15253, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.551837784615385 ], [ 29.763805344827588, -1.552107261538461 ], [ 29.764074827586207, -1.552107261538461 ], [ 29.764074827586207, -1.551837784615385 ], [ 29.763805344827588, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15254, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.551837784615385 ], [ 29.764074827586207, -1.552107261538461 ], [ 29.764344310344828, -1.552107261538461 ], [ 29.764344310344828, -1.551837784615385 ], [ 29.764074827586207, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15255, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.551837784615385 ], [ 29.764344310344828, -1.552107261538461 ], [ 29.76461379310345, -1.552107261538461 ], [ 29.76461379310345, -1.551837784615385 ], [ 29.764344310344828, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15256, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.551837784615385 ], [ 29.76461379310345, -1.552376738461538 ], [ 29.764883275862069, -1.552376738461538 ], [ 29.764883275862069, -1.551837784615385 ], [ 29.76461379310345, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15257, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.551837784615385 ], [ 29.764883275862069, -1.552107261538461 ], [ 29.76515275862069, -1.552107261538461 ], [ 29.76515275862069, -1.551837784615385 ], [ 29.764883275862069, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15258, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765961206896552, -1.551837784615385 ], [ 29.765961206896552, -1.552376738461538 ], [ 29.766230689655174, -1.552376738461538 ], [ 29.766230689655174, -1.551837784615385 ], [ 29.765961206896552, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15259, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.552107261538461 ], [ 29.754103965517242, -1.552376738461538 ], [ 29.754373448275864, -1.552376738461538 ], [ 29.754373448275864, -1.552107261538461 ], [ 29.754103965517242, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15260, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.552107261538461 ], [ 29.754642931034482, -1.552376738461538 ], [ 29.754912413793104, -1.552376738461538 ], [ 29.754912413793104, -1.552107261538461 ], [ 29.754642931034482, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15261, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.552107261538461 ], [ 29.754912413793104, -1.552376738461538 ], [ 29.755181896551726, -1.552376738461538 ], [ 29.755181896551726, -1.552107261538461 ], [ 29.754912413793104, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15262, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.552107261538461 ], [ 29.755181896551726, -1.552376738461538 ], [ 29.755451379310344, -1.552376738461538 ], [ 29.755451379310344, -1.552107261538461 ], [ 29.755181896551726, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15263, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.552107261538461 ], [ 29.757607241379311, -1.552376738461538 ], [ 29.758415689655173, -1.552376738461538 ], [ 29.758415689655173, -1.552107261538461 ], [ 29.757607241379311, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15264, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.552107261538461 ], [ 29.758415689655173, -1.552376738461538 ], [ 29.758685172413795, -1.552376738461538 ], [ 29.758685172413795, -1.552107261538461 ], [ 29.758415689655173, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15265, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.552107261538461 ], [ 29.758685172413795, -1.552376738461538 ], [ 29.760841034482759, -1.552376738461538 ], [ 29.760841034482759, -1.552107261538461 ], [ 29.758685172413795, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15266, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.552107261538461 ], [ 29.760841034482759, -1.552376738461538 ], [ 29.761110517241381, -1.552376738461538 ], [ 29.761110517241381, -1.552107261538461 ], [ 29.760841034482759, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15267, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.552107261538461 ], [ 29.761110517241381, -1.552376738461538 ], [ 29.76138, -1.552376738461538 ], [ 29.76138, -1.552107261538461 ], [ 29.761110517241381, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15268, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.552107261538461 ], [ 29.76138, -1.552376738461538 ], [ 29.761649482758621, -1.552376738461538 ], [ 29.761649482758621, -1.552107261538461 ], [ 29.76138, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15269, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.552107261538461 ], [ 29.761649482758621, -1.552376738461538 ], [ 29.761918965517243, -1.552376738461538 ], [ 29.761918965517243, -1.552107261538461 ], [ 29.761649482758621, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15270, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.552107261538461 ], [ 29.761918965517243, -1.552376738461538 ], [ 29.762188448275861, -1.552376738461538 ], [ 29.762188448275861, -1.552107261538461 ], [ 29.761918965517243, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15271, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.552107261538461 ], [ 29.762188448275861, -1.552376738461538 ], [ 29.762457931034483, -1.552376738461538 ], [ 29.762457931034483, -1.552107261538461 ], [ 29.762188448275861, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15272, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.552107261538461 ], [ 29.762457931034483, -1.552376738461538 ], [ 29.762727413793105, -1.552376738461538 ], [ 29.762727413793105, -1.552107261538461 ], [ 29.762457931034483, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15273, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.552107261538461 ], [ 29.762727413793105, -1.552376738461538 ], [ 29.762996896551723, -1.552376738461538 ], [ 29.762996896551723, -1.552107261538461 ], [ 29.762727413793105, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15274, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.552107261538461 ], [ 29.762996896551723, -1.552376738461538 ], [ 29.763266379310345, -1.552376738461538 ], [ 29.763266379310345, -1.552107261538461 ], [ 29.762996896551723, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15275, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.552107261538461 ], [ 29.763266379310345, -1.552376738461538 ], [ 29.763535862068967, -1.552376738461538 ], [ 29.763535862068967, -1.552107261538461 ], [ 29.763266379310345, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15276, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.552107261538461 ], [ 29.763535862068967, -1.552376738461538 ], [ 29.763805344827588, -1.552376738461538 ], [ 29.763805344827588, -1.552107261538461 ], [ 29.763535862068967, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15277, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.552107261538461 ], [ 29.763805344827588, -1.552376738461538 ], [ 29.764074827586207, -1.552376738461538 ], [ 29.764074827586207, -1.552107261538461 ], [ 29.763805344827588, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15278, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.552107261538461 ], [ 29.764074827586207, -1.552376738461538 ], [ 29.764344310344828, -1.552376738461538 ], [ 29.764344310344828, -1.552107261538461 ], [ 29.764074827586207, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15279, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.552107261538461 ], [ 29.764344310344828, -1.552376738461538 ], [ 29.76461379310345, -1.552376738461538 ], [ 29.76461379310345, -1.552107261538461 ], [ 29.764344310344828, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15280, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.552107261538461 ], [ 29.765422241379312, -1.552376738461538 ], [ 29.765961206896552, -1.552376738461538 ], [ 29.765961206896552, -1.552107261538461 ], [ 29.765422241379312, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15281, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75329551724138, -1.550220923076923 ], [ 29.75329551724138, -1.552646215384615 ], [ 29.753565, -1.552646215384615 ], [ 29.753565, -1.550220923076923 ], [ 29.75329551724138, -1.550220923076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15282, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.551837784615385 ], [ 29.756259827586206, -1.553724123076923 ], [ 29.756798793103449, -1.553724123076923 ], [ 29.756798793103449, -1.551837784615385 ], [ 29.756259827586206, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15283, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.551837784615385 ], [ 29.75733775862069, -1.553185169230769 ], [ 29.757607241379311, -1.553185169230769 ], [ 29.757607241379311, -1.551837784615385 ], [ 29.75733775862069, -1.551837784615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15284, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.552107261538461 ], [ 29.754373448275864, -1.552646215384615 ], [ 29.754642931034482, -1.552646215384615 ], [ 29.754642931034482, -1.552107261538461 ], [ 29.754373448275864, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15285, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.552107261538461 ], [ 29.755451379310344, -1.5539936 ], [ 29.755720862068966, -1.5539936 ], [ 29.755720862068966, -1.552107261538461 ], [ 29.755451379310344, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15286, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.552107261538461 ], [ 29.755720862068966, -1.5539936 ], [ 29.755990344827588, -1.5539936 ], [ 29.755990344827588, -1.552107261538461 ], [ 29.755720862068966, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15287, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.552107261538461 ], [ 29.756798793103449, -1.553724123076923 ], [ 29.757068275862068, -1.553724123076923 ], [ 29.757068275862068, -1.552107261538461 ], [ 29.756798793103449, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15288, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.552107261538461 ], [ 29.764883275862069, -1.552646215384615 ], [ 29.76515275862069, -1.552646215384615 ], [ 29.76515275862069, -1.552107261538461 ], [ 29.764883275862069, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15289, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.552107261538461 ], [ 29.76515275862069, -1.552646215384615 ], [ 29.765422241379312, -1.552646215384615 ], [ 29.765422241379312, -1.552107261538461 ], [ 29.76515275862069, -1.552107261538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15290, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.552376738461538 ], [ 29.753565, -1.552646215384615 ], [ 29.75383448275862, -1.552646215384615 ], [ 29.75383448275862, -1.552376738461538 ], [ 29.753565, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15291, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.552376738461538 ], [ 29.75383448275862, -1.554532553846154 ], [ 29.754103965517242, -1.554532553846154 ], [ 29.754103965517242, -1.552376738461538 ], [ 29.75383448275862, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15292, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.552376738461538 ], [ 29.754103965517242, -1.554532553846154 ], [ 29.754373448275864, -1.554532553846154 ], [ 29.754373448275864, -1.552376738461538 ], [ 29.754103965517242, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15293, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.552376738461538 ], [ 29.754642931034482, -1.554263076923077 ], [ 29.754912413793104, -1.554263076923077 ], [ 29.754912413793104, -1.552376738461538 ], [ 29.754642931034482, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15294, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.552376738461538 ], [ 29.754912413793104, -1.554263076923077 ], [ 29.755181896551726, -1.554263076923077 ], [ 29.755181896551726, -1.552376738461538 ], [ 29.754912413793104, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15295, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.552376738461538 ], [ 29.755181896551726, -1.554263076923077 ], [ 29.755451379310344, -1.554263076923077 ], [ 29.755451379310344, -1.552376738461538 ], [ 29.755181896551726, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15296, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.552376738461538 ], [ 29.755990344827588, -1.5539936 ], [ 29.756259827586206, -1.5539936 ], [ 29.756259827586206, -1.552376738461538 ], [ 29.755990344827588, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15297, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.552376738461538 ], [ 29.757068275862068, -1.553724123076923 ], [ 29.757607241379311, -1.553724123076923 ], [ 29.757607241379311, -1.553454646153846 ], [ 29.75733775862069, -1.553454646153846 ], [ 29.75733775862069, -1.552376738461538 ], [ 29.757068275862068, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15298, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.552376738461538 ], [ 29.757607241379311, -1.552646215384615 ], [ 29.757876724137933, -1.552646215384615 ], [ 29.757876724137933, -1.552376738461538 ], [ 29.757607241379311, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15299, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.552376738461538 ], [ 29.757876724137933, -1.552646215384615 ], [ 29.758146206896551, -1.552646215384615 ], [ 29.758146206896551, -1.552376738461538 ], [ 29.757876724137933, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15300, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.552376738461538 ], [ 29.758146206896551, -1.552646215384615 ], [ 29.758685172413795, -1.552646215384615 ], [ 29.758685172413795, -1.552376738461538 ], [ 29.758146206896551, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15301, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.552376738461538 ], [ 29.758685172413795, -1.552646215384615 ], [ 29.759224137931035, -1.552646215384615 ], [ 29.759224137931035, -1.552376738461538 ], [ 29.758685172413795, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15302, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.552376738461538 ], [ 29.759224137931035, -1.552646215384615 ], [ 29.760571551724137, -1.552646215384615 ], [ 29.760571551724137, -1.552376738461538 ], [ 29.759224137931035, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15303, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.552376738461538 ], [ 29.760571551724137, -1.552646215384615 ], [ 29.761110517241381, -1.552646215384615 ], [ 29.761110517241381, -1.552376738461538 ], [ 29.760571551724137, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15304, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.552376738461538 ], [ 29.761110517241381, -1.552646215384615 ], [ 29.76138, -1.552646215384615 ], [ 29.76138, -1.552376738461538 ], [ 29.761110517241381, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15305, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.552376738461538 ], [ 29.76138, -1.552646215384615 ], [ 29.761649482758621, -1.552646215384615 ], [ 29.761649482758621, -1.552376738461538 ], [ 29.76138, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15306, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.552376738461538 ], [ 29.761649482758621, -1.552646215384615 ], [ 29.761918965517243, -1.552646215384615 ], [ 29.761918965517243, -1.552376738461538 ], [ 29.761649482758621, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15307, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.552376738461538 ], [ 29.761918965517243, -1.552646215384615 ], [ 29.762188448275861, -1.552646215384615 ], [ 29.762188448275861, -1.552376738461538 ], [ 29.761918965517243, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15308, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.552376738461538 ], [ 29.762188448275861, -1.552646215384615 ], [ 29.762457931034483, -1.552646215384615 ], [ 29.762457931034483, -1.552376738461538 ], [ 29.762188448275861, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15309, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.552376738461538 ], [ 29.762457931034483, -1.552646215384615 ], [ 29.762727413793105, -1.552646215384615 ], [ 29.762727413793105, -1.552376738461538 ], [ 29.762457931034483, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15310, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.552376738461538 ], [ 29.762727413793105, -1.552646215384615 ], [ 29.762996896551723, -1.552646215384615 ], [ 29.762996896551723, -1.552376738461538 ], [ 29.762727413793105, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15311, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.552376738461538 ], [ 29.762996896551723, -1.552646215384615 ], [ 29.763266379310345, -1.552646215384615 ], [ 29.763266379310345, -1.552376738461538 ], [ 29.762996896551723, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15312, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.552376738461538 ], [ 29.763266379310345, -1.552646215384615 ], [ 29.763535862068967, -1.552646215384615 ], [ 29.763535862068967, -1.552376738461538 ], [ 29.763266379310345, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15313, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.552376738461538 ], [ 29.763535862068967, -1.552646215384615 ], [ 29.763805344827588, -1.552646215384615 ], [ 29.763805344827588, -1.552376738461538 ], [ 29.763535862068967, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15314, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.552376738461538 ], [ 29.763805344827588, -1.552646215384615 ], [ 29.764074827586207, -1.552646215384615 ], [ 29.764074827586207, -1.552376738461538 ], [ 29.763805344827588, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15315, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.552376738461538 ], [ 29.764074827586207, -1.552646215384615 ], [ 29.764344310344828, -1.552646215384615 ], [ 29.764344310344828, -1.552376738461538 ], [ 29.764074827586207, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15316, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.552376738461538 ], [ 29.764344310344828, -1.552646215384615 ], [ 29.76461379310345, -1.552646215384615 ], [ 29.76461379310345, -1.552376738461538 ], [ 29.764344310344828, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15317, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.552376738461538 ], [ 29.76461379310345, -1.552646215384615 ], [ 29.764883275862069, -1.552646215384615 ], [ 29.764883275862069, -1.552376738461538 ], [ 29.76461379310345, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15318, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765422241379312, -1.552376738461538 ], [ 29.765422241379312, -1.553185169230769 ], [ 29.765961206896552, -1.553185169230769 ], [ 29.765961206896552, -1.552376738461538 ], [ 29.765422241379312, -1.552376738461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15319, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.552646215384615 ], [ 29.754373448275864, -1.554263076923077 ], [ 29.754642931034482, -1.554263076923077 ], [ 29.754642931034482, -1.552646215384615 ], [ 29.754373448275864, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15320, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.552646215384615 ], [ 29.757607241379311, -1.553185169230769 ], [ 29.757876724137933, -1.553185169230769 ], [ 29.757876724137933, -1.552646215384615 ], [ 29.757607241379311, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15321, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.552646215384615 ], [ 29.757876724137933, -1.553185169230769 ], [ 29.758146206896551, -1.553185169230769 ], [ 29.758146206896551, -1.552646215384615 ], [ 29.757876724137933, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15322, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.552646215384615 ], [ 29.758146206896551, -1.553185169230769 ], [ 29.758415689655173, -1.553185169230769 ], [ 29.758415689655173, -1.552646215384615 ], [ 29.758146206896551, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15323, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.552646215384615 ], [ 29.758415689655173, -1.553185169230769 ], [ 29.758954655172413, -1.553185169230769 ], [ 29.758954655172413, -1.552646215384615 ], [ 29.758415689655173, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15324, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.552646215384615 ], [ 29.758954655172413, -1.553185169230769 ], [ 29.760841034482759, -1.553185169230769 ], [ 29.760841034482759, -1.552646215384615 ], [ 29.758954655172413, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15325, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.552646215384615 ], [ 29.760841034482759, -1.553185169230769 ], [ 29.761110517241381, -1.553185169230769 ], [ 29.761110517241381, -1.552646215384615 ], [ 29.760841034482759, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15326, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.552646215384615 ], [ 29.761110517241381, -1.553185169230769 ], [ 29.76138, -1.553185169230769 ], [ 29.76138, -1.552646215384615 ], [ 29.761110517241381, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15327, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.552646215384615 ], [ 29.76138, -1.553185169230769 ], [ 29.761649482758621, -1.553185169230769 ], [ 29.761649482758621, -1.552646215384615 ], [ 29.76138, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15328, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.552646215384615 ], [ 29.761649482758621, -1.553185169230769 ], [ 29.761918965517243, -1.553185169230769 ], [ 29.761918965517243, -1.552646215384615 ], [ 29.761649482758621, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15329, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.552646215384615 ], [ 29.761918965517243, -1.553185169230769 ], [ 29.762188448275861, -1.553185169230769 ], [ 29.762188448275861, -1.552646215384615 ], [ 29.761918965517243, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15330, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.552646215384615 ], [ 29.762188448275861, -1.553185169230769 ], [ 29.762457931034483, -1.553185169230769 ], [ 29.762457931034483, -1.552646215384615 ], [ 29.762188448275861, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15331, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.552646215384615 ], [ 29.762457931034483, -1.553185169230769 ], [ 29.762727413793105, -1.553185169230769 ], [ 29.762727413793105, -1.552646215384615 ], [ 29.762457931034483, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15332, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.552646215384615 ], [ 29.762727413793105, -1.553185169230769 ], [ 29.762996896551723, -1.553185169230769 ], [ 29.762996896551723, -1.552646215384615 ], [ 29.762727413793105, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15333, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.552646215384615 ], [ 29.762996896551723, -1.553185169230769 ], [ 29.763266379310345, -1.553185169230769 ], [ 29.763266379310345, -1.552646215384615 ], [ 29.762996896551723, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15334, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.552646215384615 ], [ 29.763266379310345, -1.553185169230769 ], [ 29.763535862068967, -1.553185169230769 ], [ 29.763535862068967, -1.552646215384615 ], [ 29.763266379310345, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15335, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.552646215384615 ], [ 29.763535862068967, -1.553185169230769 ], [ 29.763805344827588, -1.553185169230769 ], [ 29.763805344827588, -1.552646215384615 ], [ 29.763535862068967, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15336, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.552646215384615 ], [ 29.763805344827588, -1.553185169230769 ], [ 29.764074827586207, -1.553185169230769 ], [ 29.764074827586207, -1.552646215384615 ], [ 29.763805344827588, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15337, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.552646215384615 ], [ 29.764074827586207, -1.553185169230769 ], [ 29.764344310344828, -1.553185169230769 ], [ 29.764344310344828, -1.552646215384615 ], [ 29.764074827586207, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15338, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.552646215384615 ], [ 29.764344310344828, -1.553185169230769 ], [ 29.76461379310345, -1.553185169230769 ], [ 29.76461379310345, -1.552646215384615 ], [ 29.764344310344828, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15339, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.552646215384615 ], [ 29.76461379310345, -1.553185169230769 ], [ 29.764883275862069, -1.553185169230769 ], [ 29.764883275862069, -1.552646215384615 ], [ 29.76461379310345, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15340, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.552646215384615 ], [ 29.764883275862069, -1.553185169230769 ], [ 29.76515275862069, -1.553185169230769 ], [ 29.76515275862069, -1.552646215384615 ], [ 29.764883275862069, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15341, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.552646215384615 ], [ 29.76515275862069, -1.553185169230769 ], [ 29.765422241379312, -1.553185169230769 ], [ 29.765422241379312, -1.552646215384615 ], [ 29.76515275862069, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15342, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.553185169230769 ], [ 29.75733775862069, -1.553454646153846 ], [ 29.757607241379311, -1.553454646153846 ], [ 29.757607241379311, -1.553185169230769 ], [ 29.75733775862069, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15343, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.553185169230769 ], [ 29.757607241379311, -1.553454646153846 ], [ 29.757876724137933, -1.553454646153846 ], [ 29.757876724137933, -1.553185169230769 ], [ 29.757607241379311, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15344, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.553185169230769 ], [ 29.757876724137933, -1.553454646153846 ], [ 29.758146206896551, -1.553454646153846 ], [ 29.758146206896551, -1.553185169230769 ], [ 29.757876724137933, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15345, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.553185169230769 ], [ 29.758146206896551, -1.553454646153846 ], [ 29.758415689655173, -1.553454646153846 ], [ 29.758415689655173, -1.553185169230769 ], [ 29.758146206896551, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15346, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.553185169230769 ], [ 29.758415689655173, -1.553454646153846 ], [ 29.758685172413795, -1.553454646153846 ], [ 29.758685172413795, -1.553185169230769 ], [ 29.758415689655173, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15347, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.553185169230769 ], [ 29.758685172413795, -1.553454646153846 ], [ 29.761110517241381, -1.553454646153846 ], [ 29.761110517241381, -1.553185169230769 ], [ 29.758685172413795, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15348, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.553185169230769 ], [ 29.761110517241381, -1.553454646153846 ], [ 29.76138, -1.553454646153846 ], [ 29.76138, -1.553185169230769 ], [ 29.761110517241381, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15349, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.553185169230769 ], [ 29.76138, -1.553454646153846 ], [ 29.761649482758621, -1.553454646153846 ], [ 29.761649482758621, -1.553185169230769 ], [ 29.76138, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15350, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.553185169230769 ], [ 29.761649482758621, -1.553454646153846 ], [ 29.761918965517243, -1.553454646153846 ], [ 29.761918965517243, -1.553185169230769 ], [ 29.761649482758621, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15351, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.553185169230769 ], [ 29.761918965517243, -1.553454646153846 ], [ 29.762188448275861, -1.553454646153846 ], [ 29.762188448275861, -1.553185169230769 ], [ 29.761918965517243, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15352, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.553185169230769 ], [ 29.762188448275861, -1.553454646153846 ], [ 29.762457931034483, -1.553454646153846 ], [ 29.762457931034483, -1.553185169230769 ], [ 29.762188448275861, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15353, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.553185169230769 ], [ 29.762457931034483, -1.553454646153846 ], [ 29.762727413793105, -1.553454646153846 ], [ 29.762727413793105, -1.553185169230769 ], [ 29.762457931034483, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15354, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.553185169230769 ], [ 29.762727413793105, -1.553454646153846 ], [ 29.762996896551723, -1.553454646153846 ], [ 29.762996896551723, -1.553185169230769 ], [ 29.762727413793105, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15355, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.553185169230769 ], [ 29.762996896551723, -1.553454646153846 ], [ 29.763266379310345, -1.553454646153846 ], [ 29.763266379310345, -1.553185169230769 ], [ 29.762996896551723, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15356, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.553185169230769 ], [ 29.763266379310345, -1.553454646153846 ], [ 29.763535862068967, -1.553454646153846 ], [ 29.763535862068967, -1.553185169230769 ], [ 29.763266379310345, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15357, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.553185169230769 ], [ 29.763535862068967, -1.553454646153846 ], [ 29.764074827586207, -1.553454646153846 ], [ 29.764074827586207, -1.553185169230769 ], [ 29.763535862068967, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15358, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.553185169230769 ], [ 29.764074827586207, -1.553454646153846 ], [ 29.764344310344828, -1.553454646153846 ], [ 29.764344310344828, -1.553185169230769 ], [ 29.764074827586207, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15359, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.553185169230769 ], [ 29.764344310344828, -1.553454646153846 ], [ 29.76461379310345, -1.553454646153846 ], [ 29.76461379310345, -1.553185169230769 ], [ 29.764344310344828, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15360, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.553185169230769 ], [ 29.76461379310345, -1.553454646153846 ], [ 29.764883275862069, -1.553454646153846 ], [ 29.764883275862069, -1.553185169230769 ], [ 29.76461379310345, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15361, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.553185169230769 ], [ 29.764883275862069, -1.553454646153846 ], [ 29.76515275862069, -1.553454646153846 ], [ 29.76515275862069, -1.553185169230769 ], [ 29.764883275862069, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15362, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76515275862069, -1.553185169230769 ], [ 29.76515275862069, -1.553454646153846 ], [ 29.765422241379312, -1.553454646153846 ], [ 29.765422241379312, -1.553185169230769 ], [ 29.76515275862069, -1.553185169230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15363, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.553454646153846 ], [ 29.757607241379311, -1.553724123076923 ], [ 29.757876724137933, -1.553724123076923 ], [ 29.757876724137933, -1.553454646153846 ], [ 29.757607241379311, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15364, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.553454646153846 ], [ 29.757876724137933, -1.553724123076923 ], [ 29.758146206896551, -1.553724123076923 ], [ 29.758146206896551, -1.553454646153846 ], [ 29.757876724137933, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15365, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.553454646153846 ], [ 29.758146206896551, -1.553724123076923 ], [ 29.758415689655173, -1.553724123076923 ], [ 29.758415689655173, -1.553454646153846 ], [ 29.758146206896551, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15366, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.553454646153846 ], [ 29.758415689655173, -1.553724123076923 ], [ 29.758685172413795, -1.553724123076923 ], [ 29.758685172413795, -1.553454646153846 ], [ 29.758415689655173, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15367, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.553454646153846 ], [ 29.758685172413795, -1.553724123076923 ], [ 29.759224137931035, -1.553724123076923 ], [ 29.759224137931035, -1.553454646153846 ], [ 29.758685172413795, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15368, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.553454646153846 ], [ 29.759224137931035, -1.553724123076923 ], [ 29.760571551724137, -1.553724123076923 ], [ 29.760571551724137, -1.553454646153846 ], [ 29.759224137931035, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15369, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.553454646153846 ], [ 29.760571551724137, -1.553724123076923 ], [ 29.761110517241381, -1.553724123076923 ], [ 29.761110517241381, -1.553454646153846 ], [ 29.760571551724137, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15370, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.553454646153846 ], [ 29.761110517241381, -1.553724123076923 ], [ 29.76138, -1.553724123076923 ], [ 29.76138, -1.553454646153846 ], [ 29.761110517241381, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15371, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.553454646153846 ], [ 29.76138, -1.553724123076923 ], [ 29.761649482758621, -1.553724123076923 ], [ 29.761649482758621, -1.553454646153846 ], [ 29.76138, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15372, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.553454646153846 ], [ 29.761649482758621, -1.553724123076923 ], [ 29.761918965517243, -1.553724123076923 ], [ 29.761918965517243, -1.553454646153846 ], [ 29.761649482758621, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15373, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.553454646153846 ], [ 29.761918965517243, -1.553724123076923 ], [ 29.762188448275861, -1.553724123076923 ], [ 29.762188448275861, -1.553454646153846 ], [ 29.761918965517243, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15374, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.553454646153846 ], [ 29.762188448275861, -1.553724123076923 ], [ 29.762457931034483, -1.553724123076923 ], [ 29.762457931034483, -1.553454646153846 ], [ 29.762188448275861, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15375, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.553454646153846 ], [ 29.762457931034483, -1.553724123076923 ], [ 29.762727413793105, -1.553724123076923 ], [ 29.762727413793105, -1.553454646153846 ], [ 29.762457931034483, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15376, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.553454646153846 ], [ 29.762727413793105, -1.553724123076923 ], [ 29.762996896551723, -1.553724123076923 ], [ 29.762996896551723, -1.553454646153846 ], [ 29.762727413793105, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15377, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.553454646153846 ], [ 29.762996896551723, -1.553724123076923 ], [ 29.763266379310345, -1.553724123076923 ], [ 29.763266379310345, -1.553454646153846 ], [ 29.762996896551723, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15378, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.553454646153846 ], [ 29.763266379310345, -1.553724123076923 ], [ 29.763535862068967, -1.553724123076923 ], [ 29.763535862068967, -1.553454646153846 ], [ 29.763266379310345, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15379, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.553454646153846 ], [ 29.763535862068967, -1.553724123076923 ], [ 29.763805344827588, -1.553724123076923 ], [ 29.763805344827588, -1.553454646153846 ], [ 29.763535862068967, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15380, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.553454646153846 ], [ 29.763805344827588, -1.553724123076923 ], [ 29.764344310344828, -1.553724123076923 ], [ 29.764344310344828, -1.553454646153846 ], [ 29.763805344827588, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15381, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.553454646153846 ], [ 29.764344310344828, -1.553724123076923 ], [ 29.76461379310345, -1.553724123076923 ], [ 29.76461379310345, -1.553454646153846 ], [ 29.764344310344828, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15382, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.553454646153846 ], [ 29.76461379310345, -1.553724123076923 ], [ 29.764883275862069, -1.553724123076923 ], [ 29.764883275862069, -1.553454646153846 ], [ 29.76461379310345, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15383, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764883275862069, -1.553454646153846 ], [ 29.764883275862069, -1.553724123076923 ], [ 29.76515275862069, -1.553724123076923 ], [ 29.76515275862069, -1.553454646153846 ], [ 29.764883275862069, -1.553454646153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15384, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.553724123076923 ], [ 29.756259827586206, -1.5539936 ], [ 29.756798793103449, -1.5539936 ], [ 29.756798793103449, -1.553724123076923 ], [ 29.756259827586206, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15385, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.553724123076923 ], [ 29.756798793103449, -1.5539936 ], [ 29.757068275862068, -1.5539936 ], [ 29.757068275862068, -1.553724123076923 ], [ 29.756798793103449, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15386, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.553724123076923 ], [ 29.757068275862068, -1.5539936 ], [ 29.75733775862069, -1.5539936 ], [ 29.75733775862069, -1.553724123076923 ], [ 29.757068275862068, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15387, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.553724123076923 ], [ 29.75733775862069, -1.5539936 ], [ 29.757607241379311, -1.5539936 ], [ 29.757607241379311, -1.553724123076923 ], [ 29.75733775862069, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15388, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.553724123076923 ], [ 29.757607241379311, -1.5539936 ], [ 29.757876724137933, -1.5539936 ], [ 29.757876724137933, -1.553724123076923 ], [ 29.757607241379311, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15389, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.553724123076923 ], [ 29.757876724137933, -1.5539936 ], [ 29.758146206896551, -1.5539936 ], [ 29.758146206896551, -1.553724123076923 ], [ 29.757876724137933, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15390, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.553724123076923 ], [ 29.758146206896551, -1.5539936 ], [ 29.758415689655173, -1.5539936 ], [ 29.758415689655173, -1.553724123076923 ], [ 29.758146206896551, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15391, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.553724123076923 ], [ 29.758415689655173, -1.5539936 ], [ 29.758685172413795, -1.5539936 ], [ 29.758685172413795, -1.553724123076923 ], [ 29.758415689655173, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15392, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.553724123076923 ], [ 29.758685172413795, -1.5539936 ], [ 29.758954655172413, -1.5539936 ], [ 29.758954655172413, -1.553724123076923 ], [ 29.758685172413795, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15393, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.553724123076923 ], [ 29.758954655172413, -1.5539936 ], [ 29.760841034482759, -1.5539936 ], [ 29.760841034482759, -1.553724123076923 ], [ 29.758954655172413, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15394, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.553724123076923 ], [ 29.760841034482759, -1.5539936 ], [ 29.761110517241381, -1.5539936 ], [ 29.761110517241381, -1.553724123076923 ], [ 29.760841034482759, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15395, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.553724123076923 ], [ 29.761110517241381, -1.5539936 ], [ 29.761649482758621, -1.5539936 ], [ 29.761649482758621, -1.553724123076923 ], [ 29.761110517241381, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15396, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.553724123076923 ], [ 29.761649482758621, -1.5539936 ], [ 29.761918965517243, -1.5539936 ], [ 29.761918965517243, -1.553724123076923 ], [ 29.761649482758621, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15397, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.553724123076923 ], [ 29.761918965517243, -1.5539936 ], [ 29.762188448275861, -1.5539936 ], [ 29.762188448275861, -1.553724123076923 ], [ 29.761918965517243, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15398, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.553724123076923 ], [ 29.762188448275861, -1.5539936 ], [ 29.762457931034483, -1.5539936 ], [ 29.762457931034483, -1.553724123076923 ], [ 29.762188448275861, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15399, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.553724123076923 ], [ 29.762457931034483, -1.5539936 ], [ 29.762727413793105, -1.5539936 ], [ 29.762727413793105, -1.553724123076923 ], [ 29.762457931034483, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15400, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.553724123076923 ], [ 29.762727413793105, -1.5539936 ], [ 29.762996896551723, -1.5539936 ], [ 29.762996896551723, -1.553724123076923 ], [ 29.762727413793105, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15401, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.553724123076923 ], [ 29.762996896551723, -1.554263076923077 ], [ 29.763266379310345, -1.554263076923077 ], [ 29.763266379310345, -1.553724123076923 ], [ 29.762996896551723, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15402, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.553724123076923 ], [ 29.763266379310345, -1.5539936 ], [ 29.763535862068967, -1.5539936 ], [ 29.763535862068967, -1.553724123076923 ], [ 29.763266379310345, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15403, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.553724123076923 ], [ 29.763535862068967, -1.5539936 ], [ 29.763805344827588, -1.5539936 ], [ 29.763805344827588, -1.553724123076923 ], [ 29.763535862068967, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15404, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.553724123076923 ], [ 29.763805344827588, -1.5539936 ], [ 29.764344310344828, -1.5539936 ], [ 29.764344310344828, -1.553724123076923 ], [ 29.763805344827588, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15405, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764344310344828, -1.553724123076923 ], [ 29.764344310344828, -1.5539936 ], [ 29.76461379310345, -1.5539936 ], [ 29.76461379310345, -1.553724123076923 ], [ 29.764344310344828, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15406, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76461379310345, -1.553724123076923 ], [ 29.76461379310345, -1.5539936 ], [ 29.764883275862069, -1.5539936 ], [ 29.764883275862069, -1.553724123076923 ], [ 29.76461379310345, -1.553724123076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15407, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.5539936 ], [ 29.755451379310344, -1.554263076923077 ], [ 29.755720862068966, -1.554263076923077 ], [ 29.755720862068966, -1.5539936 ], [ 29.755451379310344, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15408, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.5539936 ], [ 29.755720862068966, -1.554263076923077 ], [ 29.755990344827588, -1.554263076923077 ], [ 29.755990344827588, -1.5539936 ], [ 29.755720862068966, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15409, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.5539936 ], [ 29.755990344827588, -1.554263076923077 ], [ 29.756259827586206, -1.554263076923077 ], [ 29.756259827586206, -1.5539936 ], [ 29.755990344827588, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15410, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.5539936 ], [ 29.756259827586206, -1.554532553846154 ], [ 29.756798793103449, -1.554532553846154 ], [ 29.756798793103449, -1.5539936 ], [ 29.756259827586206, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15411, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.5539936 ], [ 29.757068275862068, -1.554263076923077 ], [ 29.75733775862069, -1.554263076923077 ], [ 29.75733775862069, -1.5539936 ], [ 29.757068275862068, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15412, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.5539936 ], [ 29.75733775862069, -1.554263076923077 ], [ 29.757607241379311, -1.554263076923077 ], [ 29.757607241379311, -1.5539936 ], [ 29.75733775862069, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15413, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.5539936 ], [ 29.757607241379311, -1.554263076923077 ], [ 29.757876724137933, -1.554263076923077 ], [ 29.757876724137933, -1.5539936 ], [ 29.757607241379311, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15414, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.5539936 ], [ 29.757876724137933, -1.554263076923077 ], [ 29.758146206896551, -1.554263076923077 ], [ 29.758146206896551, -1.5539936 ], [ 29.757876724137933, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15415, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.5539936 ], [ 29.758146206896551, -1.554263076923077 ], [ 29.758415689655173, -1.554263076923077 ], [ 29.758415689655173, -1.5539936 ], [ 29.758146206896551, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15416, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.5539936 ], [ 29.758415689655173, -1.554263076923077 ], [ 29.758685172413795, -1.554263076923077 ], [ 29.758685172413795, -1.5539936 ], [ 29.758415689655173, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15417, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.5539936 ], [ 29.758685172413795, -1.554263076923077 ], [ 29.758954655172413, -1.554263076923077 ], [ 29.758954655172413, -1.5539936 ], [ 29.758685172413795, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15418, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.5539936 ], [ 29.758954655172413, -1.554263076923077 ], [ 29.760841034482759, -1.554263076923077 ], [ 29.760841034482759, -1.5539936 ], [ 29.758954655172413, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15419, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5539936 ], [ 29.760841034482759, -1.554263076923077 ], [ 29.761110517241381, -1.554263076923077 ], [ 29.761110517241381, -1.5539936 ], [ 29.760841034482759, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15420, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5539936 ], [ 29.761110517241381, -1.554263076923077 ], [ 29.761649482758621, -1.554263076923077 ], [ 29.761649482758621, -1.5539936 ], [ 29.761110517241381, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15421, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.5539936 ], [ 29.761649482758621, -1.554263076923077 ], [ 29.761918965517243, -1.554263076923077 ], [ 29.761918965517243, -1.5539936 ], [ 29.761649482758621, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15422, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.5539936 ], [ 29.761918965517243, -1.554263076923077 ], [ 29.762188448275861, -1.554263076923077 ], [ 29.762188448275861, -1.5539936 ], [ 29.761918965517243, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15423, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.5539936 ], [ 29.762188448275861, -1.554263076923077 ], [ 29.762457931034483, -1.554263076923077 ], [ 29.762457931034483, -1.5539936 ], [ 29.762188448275861, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15424, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.5539936 ], [ 29.762457931034483, -1.554263076923077 ], [ 29.762727413793105, -1.554263076923077 ], [ 29.762727413793105, -1.5539936 ], [ 29.762457931034483, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15425, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.5539936 ], [ 29.762727413793105, -1.554263076923077 ], [ 29.762996896551723, -1.554263076923077 ], [ 29.762996896551723, -1.5539936 ], [ 29.762727413793105, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15426, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.5539936 ], [ 29.763266379310345, -1.554532553846154 ], [ 29.763535862068967, -1.554532553846154 ], [ 29.763535862068967, -1.5539936 ], [ 29.763266379310345, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15427, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.5539936 ], [ 29.763535862068967, -1.554532553846154 ], [ 29.763805344827588, -1.554532553846154 ], [ 29.763805344827588, -1.5539936 ], [ 29.763535862068967, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15428, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.5539936 ], [ 29.763805344827588, -1.554263076923077 ], [ 29.764074827586207, -1.554263076923077 ], [ 29.764074827586207, -1.5539936 ], [ 29.763805344827588, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15429, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764074827586207, -1.5539936 ], [ 29.764074827586207, -1.554263076923077 ], [ 29.76461379310345, -1.554263076923077 ], [ 29.76461379310345, -1.5539936 ], [ 29.764074827586207, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15430, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.554263076923077 ], [ 29.754373448275864, -1.554532553846154 ], [ 29.754642931034482, -1.554532553846154 ], [ 29.754642931034482, -1.554263076923077 ], [ 29.754373448275864, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15431, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.554263076923077 ], [ 29.754912413793104, -1.554532553846154 ], [ 29.755181896551726, -1.554532553846154 ], [ 29.755181896551726, -1.554263076923077 ], [ 29.754912413793104, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15432, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.554263076923077 ], [ 29.755181896551726, -1.554532553846154 ], [ 29.755451379310344, -1.554532553846154 ], [ 29.755451379310344, -1.554263076923077 ], [ 29.755181896551726, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15433, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.554263076923077 ], [ 29.755451379310344, -1.554532553846154 ], [ 29.755720862068966, -1.554532553846154 ], [ 29.755720862068966, -1.554263076923077 ], [ 29.755451379310344, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15434, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.554263076923077 ], [ 29.75733775862069, -1.554532553846154 ], [ 29.757607241379311, -1.554532553846154 ], [ 29.757607241379311, -1.554263076923077 ], [ 29.75733775862069, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15435, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.554263076923077 ], [ 29.757607241379311, -1.554532553846154 ], [ 29.757876724137933, -1.554532553846154 ], [ 29.757876724137933, -1.554263076923077 ], [ 29.757607241379311, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15436, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.554263076923077 ], [ 29.757876724137933, -1.554532553846154 ], [ 29.758146206896551, -1.554532553846154 ], [ 29.758146206896551, -1.554263076923077 ], [ 29.757876724137933, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15437, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.554263076923077 ], [ 29.758146206896551, -1.554532553846154 ], [ 29.758415689655173, -1.554532553846154 ], [ 29.758415689655173, -1.554263076923077 ], [ 29.758146206896551, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15438, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.554263076923077 ], [ 29.758415689655173, -1.554532553846154 ], [ 29.758685172413795, -1.554532553846154 ], [ 29.758685172413795, -1.554263076923077 ], [ 29.758415689655173, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15439, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.554263076923077 ], [ 29.758685172413795, -1.554532553846154 ], [ 29.758954655172413, -1.554532553846154 ], [ 29.758954655172413, -1.554263076923077 ], [ 29.758685172413795, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15440, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.554263076923077 ], [ 29.758954655172413, -1.554532553846154 ], [ 29.759224137931035, -1.554532553846154 ], [ 29.759224137931035, -1.554263076923077 ], [ 29.758954655172413, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15441, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.554263076923077 ], [ 29.759224137931035, -1.554532553846154 ], [ 29.760571551724137, -1.554532553846154 ], [ 29.760571551724137, -1.554263076923077 ], [ 29.759224137931035, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15442, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.554263076923077 ], [ 29.760571551724137, -1.554532553846154 ], [ 29.760841034482759, -1.554532553846154 ], [ 29.760841034482759, -1.554263076923077 ], [ 29.760571551724137, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15443, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.554263076923077 ], [ 29.760841034482759, -1.554532553846154 ], [ 29.761110517241381, -1.554532553846154 ], [ 29.761110517241381, -1.554263076923077 ], [ 29.760841034482759, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15444, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.554263076923077 ], [ 29.761110517241381, -1.554532553846154 ], [ 29.76138, -1.554532553846154 ], [ 29.76138, -1.554263076923077 ], [ 29.761110517241381, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15445, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.554263076923077 ], [ 29.76138, -1.554532553846154 ], [ 29.761649482758621, -1.554532553846154 ], [ 29.761649482758621, -1.554263076923077 ], [ 29.76138, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15446, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.554263076923077 ], [ 29.761649482758621, -1.554532553846154 ], [ 29.761918965517243, -1.554532553846154 ], [ 29.761918965517243, -1.554263076923077 ], [ 29.761649482758621, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15447, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.554263076923077 ], [ 29.761918965517243, -1.554532553846154 ], [ 29.762188448275861, -1.554532553846154 ], [ 29.762188448275861, -1.554263076923077 ], [ 29.761918965517243, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15448, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.554263076923077 ], [ 29.762188448275861, -1.554532553846154 ], [ 29.762457931034483, -1.554532553846154 ], [ 29.762457931034483, -1.554263076923077 ], [ 29.762188448275861, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15449, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.554263076923077 ], [ 29.762457931034483, -1.554532553846154 ], [ 29.762727413793105, -1.554532553846154 ], [ 29.762727413793105, -1.554263076923077 ], [ 29.762457931034483, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15450, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.554263076923077 ], [ 29.762996896551723, -1.554532553846154 ], [ 29.763266379310345, -1.554532553846154 ], [ 29.763266379310345, -1.554263076923077 ], [ 29.762996896551723, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15451, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753565, -1.552646215384615 ], [ 29.753565, -1.554802030769231 ], [ 29.75383448275862, -1.554802030769231 ], [ 29.75383448275862, -1.552646215384615 ], [ 29.753565, -1.552646215384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15452, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.5539936 ], [ 29.756798793103449, -1.554802030769231 ], [ 29.757068275862068, -1.554802030769231 ], [ 29.757068275862068, -1.554532553846154 ], [ 29.75733775862069, -1.554532553846154 ], [ 29.75733775862069, -1.554263076923077 ], [ 29.757068275862068, -1.554263076923077 ], [ 29.757068275862068, -1.5539936 ], [ 29.756798793103449, -1.5539936 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15453, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.554263076923077 ], [ 29.754642931034482, -1.554802030769231 ], [ 29.754912413793104, -1.554802030769231 ], [ 29.754912413793104, -1.554263076923077 ], [ 29.754642931034482, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15454, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.554263076923077 ], [ 29.755720862068966, -1.556688369230769 ], [ 29.755990344827588, -1.556688369230769 ], [ 29.755990344827588, -1.554263076923077 ], [ 29.755720862068966, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15455, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.554263076923077 ], [ 29.755990344827588, -1.556149415384615 ], [ 29.756259827586206, -1.556149415384615 ], [ 29.756259827586206, -1.555879938461538 ], [ 29.756798793103449, -1.555879938461538 ], [ 29.756798793103449, -1.555610461538461 ], [ 29.756259827586206, -1.555610461538461 ], [ 29.756259827586206, -1.554263076923077 ], [ 29.755990344827588, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15456, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.554263076923077 ], [ 29.762727413793105, -1.554802030769231 ], [ 29.762996896551723, -1.554802030769231 ], [ 29.762996896551723, -1.554263076923077 ], [ 29.762727413793105, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15457, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763805344827588, -1.554263076923077 ], [ 29.763805344827588, -1.554802030769231 ], [ 29.764074827586207, -1.554802030769231 ], [ 29.764074827586207, -1.554263076923077 ], [ 29.763805344827588, -1.554263076923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15458, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.554532553846154 ], [ 29.75383448275862, -1.554802030769231 ], [ 29.754103965517242, -1.554802030769231 ], [ 29.754103965517242, -1.554532553846154 ], [ 29.75383448275862, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15459, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.554532553846154 ], [ 29.756259827586206, -1.555610461538461 ], [ 29.756798793103449, -1.555610461538461 ], [ 29.756798793103449, -1.555340984615385 ], [ 29.757068275862068, -1.555340984615385 ], [ 29.757068275862068, -1.555071507692308 ], [ 29.756798793103449, -1.555071507692308 ], [ 29.756798793103449, -1.554532553846154 ], [ 29.756259827586206, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15460, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.554532553846154 ], [ 29.757068275862068, -1.554802030769231 ], [ 29.75733775862069, -1.554802030769231 ], [ 29.75733775862069, -1.554532553846154 ], [ 29.757068275862068, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15461, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.554532553846154 ], [ 29.75733775862069, -1.554802030769231 ], [ 29.757607241379311, -1.554802030769231 ], [ 29.757607241379311, -1.554532553846154 ], [ 29.75733775862069, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15462, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.554532553846154 ], [ 29.757607241379311, -1.554802030769231 ], [ 29.757876724137933, -1.554802030769231 ], [ 29.757876724137933, -1.554532553846154 ], [ 29.757607241379311, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15463, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.554532553846154 ], [ 29.757876724137933, -1.554802030769231 ], [ 29.758146206896551, -1.554802030769231 ], [ 29.758146206896551, -1.554532553846154 ], [ 29.757876724137933, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15464, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.554532553846154 ], [ 29.758146206896551, -1.554802030769231 ], [ 29.758415689655173, -1.554802030769231 ], [ 29.758415689655173, -1.554532553846154 ], [ 29.758146206896551, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15465, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.554532553846154 ], [ 29.758415689655173, -1.554802030769231 ], [ 29.758685172413795, -1.554802030769231 ], [ 29.758685172413795, -1.554532553846154 ], [ 29.758415689655173, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15466, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.554532553846154 ], [ 29.758685172413795, -1.554802030769231 ], [ 29.758954655172413, -1.554802030769231 ], [ 29.758954655172413, -1.554532553846154 ], [ 29.758685172413795, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15467, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.554532553846154 ], [ 29.758954655172413, -1.554802030769231 ], [ 29.759224137931035, -1.554802030769231 ], [ 29.759224137931035, -1.554532553846154 ], [ 29.758954655172413, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15468, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.554532553846154 ], [ 29.759224137931035, -1.554802030769231 ], [ 29.760571551724137, -1.554802030769231 ], [ 29.760571551724137, -1.554532553846154 ], [ 29.759224137931035, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15469, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.554532553846154 ], [ 29.760571551724137, -1.554802030769231 ], [ 29.760841034482759, -1.554802030769231 ], [ 29.760841034482759, -1.554532553846154 ], [ 29.760571551724137, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15470, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.554532553846154 ], [ 29.760841034482759, -1.554802030769231 ], [ 29.761110517241381, -1.554802030769231 ], [ 29.761110517241381, -1.554532553846154 ], [ 29.760841034482759, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15471, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.554532553846154 ], [ 29.761110517241381, -1.554802030769231 ], [ 29.76138, -1.554802030769231 ], [ 29.76138, -1.554532553846154 ], [ 29.761110517241381, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15472, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.554532553846154 ], [ 29.76138, -1.554802030769231 ], [ 29.761649482758621, -1.554802030769231 ], [ 29.761649482758621, -1.554532553846154 ], [ 29.76138, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15473, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.554532553846154 ], [ 29.761649482758621, -1.554802030769231 ], [ 29.761918965517243, -1.554802030769231 ], [ 29.761918965517243, -1.554532553846154 ], [ 29.761649482758621, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15474, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.554532553846154 ], [ 29.761918965517243, -1.554802030769231 ], [ 29.762188448275861, -1.554802030769231 ], [ 29.762188448275861, -1.554532553846154 ], [ 29.761918965517243, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15475, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.554532553846154 ], [ 29.762188448275861, -1.554802030769231 ], [ 29.762457931034483, -1.554802030769231 ], [ 29.762457931034483, -1.554532553846154 ], [ 29.762188448275861, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15476, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.554532553846154 ], [ 29.762457931034483, -1.554802030769231 ], [ 29.762727413793105, -1.554802030769231 ], [ 29.762727413793105, -1.554532553846154 ], [ 29.762457931034483, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15477, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.554532553846154 ], [ 29.762996896551723, -1.555071507692308 ], [ 29.763266379310345, -1.555071507692308 ], [ 29.763266379310345, -1.554532553846154 ], [ 29.762996896551723, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15478, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.554532553846154 ], [ 29.763266379310345, -1.555071507692308 ], [ 29.763535862068967, -1.555071507692308 ], [ 29.763535862068967, -1.554532553846154 ], [ 29.763266379310345, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15479, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.554532553846154 ], [ 29.763535862068967, -1.554802030769231 ], [ 29.763805344827588, -1.554802030769231 ], [ 29.763805344827588, -1.554532553846154 ], [ 29.763535862068967, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15480, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.554802030769231 ], [ 29.756798793103449, -1.555071507692308 ], [ 29.757068275862068, -1.555071507692308 ], [ 29.757068275862068, -1.554802030769231 ], [ 29.756798793103449, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15481, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.554802030769231 ], [ 29.757068275862068, -1.555071507692308 ], [ 29.75733775862069, -1.555071507692308 ], [ 29.75733775862069, -1.554802030769231 ], [ 29.757068275862068, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15482, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.554802030769231 ], [ 29.75733775862069, -1.555071507692308 ], [ 29.757607241379311, -1.555071507692308 ], [ 29.757607241379311, -1.554802030769231 ], [ 29.75733775862069, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15483, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.554802030769231 ], [ 29.757607241379311, -1.555071507692308 ], [ 29.757876724137933, -1.555071507692308 ], [ 29.757876724137933, -1.554802030769231 ], [ 29.757607241379311, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15484, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.554802030769231 ], [ 29.757876724137933, -1.555071507692308 ], [ 29.758146206896551, -1.555071507692308 ], [ 29.758146206896551, -1.554802030769231 ], [ 29.757876724137933, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15485, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.554802030769231 ], [ 29.758146206896551, -1.555071507692308 ], [ 29.758415689655173, -1.555071507692308 ], [ 29.758415689655173, -1.554802030769231 ], [ 29.758146206896551, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15486, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.554802030769231 ], [ 29.758415689655173, -1.555071507692308 ], [ 29.758685172413795, -1.555071507692308 ], [ 29.758685172413795, -1.554802030769231 ], [ 29.758415689655173, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15487, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.554802030769231 ], [ 29.758685172413795, -1.555071507692308 ], [ 29.758954655172413, -1.555071507692308 ], [ 29.758954655172413, -1.554802030769231 ], [ 29.758685172413795, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15488, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.554802030769231 ], [ 29.758954655172413, -1.555071507692308 ], [ 29.759224137931035, -1.555071507692308 ], [ 29.759224137931035, -1.554802030769231 ], [ 29.758954655172413, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15489, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.554802030769231 ], [ 29.759224137931035, -1.555071507692308 ], [ 29.760571551724137, -1.555071507692308 ], [ 29.760571551724137, -1.554802030769231 ], [ 29.759224137931035, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15490, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.554802030769231 ], [ 29.760571551724137, -1.555071507692308 ], [ 29.760841034482759, -1.555071507692308 ], [ 29.760841034482759, -1.554802030769231 ], [ 29.760571551724137, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15491, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.554802030769231 ], [ 29.760841034482759, -1.555071507692308 ], [ 29.761110517241381, -1.555071507692308 ], [ 29.761110517241381, -1.554802030769231 ], [ 29.760841034482759, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15492, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.554802030769231 ], [ 29.761110517241381, -1.555071507692308 ], [ 29.76138, -1.555071507692308 ], [ 29.76138, -1.554802030769231 ], [ 29.761110517241381, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15493, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.554802030769231 ], [ 29.76138, -1.555071507692308 ], [ 29.761918965517243, -1.555071507692308 ], [ 29.761918965517243, -1.554802030769231 ], [ 29.76138, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15494, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.554802030769231 ], [ 29.761918965517243, -1.555071507692308 ], [ 29.762188448275861, -1.555071507692308 ], [ 29.762188448275861, -1.554802030769231 ], [ 29.761918965517243, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15495, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.554802030769231 ], [ 29.762188448275861, -1.555071507692308 ], [ 29.762457931034483, -1.555071507692308 ], [ 29.762457931034483, -1.554802030769231 ], [ 29.762188448275861, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15496, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.554802030769231 ], [ 29.762457931034483, -1.555071507692308 ], [ 29.762727413793105, -1.555071507692308 ], [ 29.762727413793105, -1.554802030769231 ], [ 29.762457931034483, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15497, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.554802030769231 ], [ 29.762727413793105, -1.555071507692308 ], [ 29.762996896551723, -1.555071507692308 ], [ 29.762996896551723, -1.554802030769231 ], [ 29.762727413793105, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15498, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763535862068967, -1.554802030769231 ], [ 29.763535862068967, -1.555340984615385 ], [ 29.763805344827588, -1.555340984615385 ], [ 29.763805344827588, -1.554802030769231 ], [ 29.763535862068967, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15499, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.555071507692308 ], [ 29.757068275862068, -1.555340984615385 ], [ 29.75733775862069, -1.555340984615385 ], [ 29.75733775862069, -1.555071507692308 ], [ 29.757068275862068, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15500, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.555071507692308 ], [ 29.75733775862069, -1.555340984615385 ], [ 29.757607241379311, -1.555340984615385 ], [ 29.757607241379311, -1.555071507692308 ], [ 29.75733775862069, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15501, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.555071507692308 ], [ 29.757607241379311, -1.555340984615385 ], [ 29.757876724137933, -1.555340984615385 ], [ 29.757876724137933, -1.555071507692308 ], [ 29.757607241379311, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15502, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.555071507692308 ], [ 29.757876724137933, -1.555340984615385 ], [ 29.758146206896551, -1.555340984615385 ], [ 29.758146206896551, -1.555071507692308 ], [ 29.757876724137933, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15503, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.555071507692308 ], [ 29.758146206896551, -1.555340984615385 ], [ 29.758415689655173, -1.555340984615385 ], [ 29.758415689655173, -1.555071507692308 ], [ 29.758146206896551, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15504, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.555071507692308 ], [ 29.758415689655173, -1.555340984615385 ], [ 29.758685172413795, -1.555340984615385 ], [ 29.758685172413795, -1.555071507692308 ], [ 29.758415689655173, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15505, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.555071507692308 ], [ 29.758685172413795, -1.555340984615385 ], [ 29.758954655172413, -1.555340984615385 ], [ 29.758954655172413, -1.555071507692308 ], [ 29.758685172413795, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15506, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.555071507692308 ], [ 29.758954655172413, -1.555340984615385 ], [ 29.759493620689657, -1.555340984615385 ], [ 29.759493620689657, -1.555071507692308 ], [ 29.758954655172413, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15507, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.555071507692308 ], [ 29.759493620689657, -1.555340984615385 ], [ 29.760302068965519, -1.555340984615385 ], [ 29.760302068965519, -1.555071507692308 ], [ 29.759493620689657, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15508, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.555071507692308 ], [ 29.760302068965519, -1.555340984615385 ], [ 29.760841034482759, -1.555340984615385 ], [ 29.760841034482759, -1.555071507692308 ], [ 29.760302068965519, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15509, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.555071507692308 ], [ 29.760841034482759, -1.555340984615385 ], [ 29.761110517241381, -1.555340984615385 ], [ 29.761110517241381, -1.555071507692308 ], [ 29.760841034482759, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15510, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.555071507692308 ], [ 29.761110517241381, -1.555340984615385 ], [ 29.76138, -1.555340984615385 ], [ 29.76138, -1.555071507692308 ], [ 29.761110517241381, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15511, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.555071507692308 ], [ 29.76138, -1.555340984615385 ], [ 29.761918965517243, -1.555340984615385 ], [ 29.761918965517243, -1.555071507692308 ], [ 29.76138, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15512, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.555071507692308 ], [ 29.761918965517243, -1.555340984615385 ], [ 29.762188448275861, -1.555340984615385 ], [ 29.762188448275861, -1.555071507692308 ], [ 29.761918965517243, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15513, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.555071507692308 ], [ 29.762188448275861, -1.555610461538461 ], [ 29.762457931034483, -1.555610461538461 ], [ 29.762457931034483, -1.555071507692308 ], [ 29.762188448275861, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15514, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.555071507692308 ], [ 29.762457931034483, -1.555340984615385 ], [ 29.762727413793105, -1.555340984615385 ], [ 29.762727413793105, -1.555071507692308 ], [ 29.762457931034483, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15515, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.555071507692308 ], [ 29.762727413793105, -1.555340984615385 ], [ 29.762996896551723, -1.555340984615385 ], [ 29.762996896551723, -1.555071507692308 ], [ 29.762727413793105, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15516, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.555071507692308 ], [ 29.762996896551723, -1.555340984615385 ], [ 29.763266379310345, -1.555340984615385 ], [ 29.763266379310345, -1.555071507692308 ], [ 29.762996896551723, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15517, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.555071507692308 ], [ 29.763266379310345, -1.555340984615385 ], [ 29.763535862068967, -1.555340984615385 ], [ 29.763535862068967, -1.555071507692308 ], [ 29.763266379310345, -1.555071507692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15518, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.555340984615385 ], [ 29.756798793103449, -1.555610461538461 ], [ 29.757068275862068, -1.555610461538461 ], [ 29.757068275862068, -1.555340984615385 ], [ 29.756798793103449, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15519, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.555340984615385 ], [ 29.757068275862068, -1.555610461538461 ], [ 29.75733775862069, -1.555610461538461 ], [ 29.75733775862069, -1.555340984615385 ], [ 29.757068275862068, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15520, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.555340984615385 ], [ 29.75733775862069, -1.555610461538461 ], [ 29.757607241379311, -1.555610461538461 ], [ 29.757607241379311, -1.555340984615385 ], [ 29.75733775862069, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15521, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.555340984615385 ], [ 29.757607241379311, -1.555610461538461 ], [ 29.757876724137933, -1.555610461538461 ], [ 29.757876724137933, -1.555340984615385 ], [ 29.757607241379311, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15522, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.555340984615385 ], [ 29.757876724137933, -1.555610461538461 ], [ 29.758146206896551, -1.555610461538461 ], [ 29.758146206896551, -1.555340984615385 ], [ 29.757876724137933, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15523, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.555340984615385 ], [ 29.758146206896551, -1.555610461538461 ], [ 29.758415689655173, -1.555610461538461 ], [ 29.758415689655173, -1.555340984615385 ], [ 29.758146206896551, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15524, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.555340984615385 ], [ 29.758415689655173, -1.555610461538461 ], [ 29.758685172413795, -1.555610461538461 ], [ 29.758685172413795, -1.555340984615385 ], [ 29.758415689655173, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15525, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.555340984615385 ], [ 29.758685172413795, -1.555610461538461 ], [ 29.758954655172413, -1.555610461538461 ], [ 29.758954655172413, -1.555340984615385 ], [ 29.758685172413795, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15526, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.555340984615385 ], [ 29.758954655172413, -1.555610461538461 ], [ 29.759224137931035, -1.555610461538461 ], [ 29.759224137931035, -1.555340984615385 ], [ 29.758954655172413, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15527, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.555340984615385 ], [ 29.759224137931035, -1.555610461538461 ], [ 29.760571551724137, -1.555610461538461 ], [ 29.760571551724137, -1.555340984615385 ], [ 29.759224137931035, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15528, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.555340984615385 ], [ 29.760571551724137, -1.555610461538461 ], [ 29.760841034482759, -1.555610461538461 ], [ 29.760841034482759, -1.555340984615385 ], [ 29.760571551724137, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15529, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.555340984615385 ], [ 29.760841034482759, -1.555610461538461 ], [ 29.761110517241381, -1.555610461538461 ], [ 29.761110517241381, -1.555340984615385 ], [ 29.760841034482759, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15530, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.555340984615385 ], [ 29.761110517241381, -1.555610461538461 ], [ 29.76138, -1.555610461538461 ], [ 29.76138, -1.555340984615385 ], [ 29.761110517241381, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15531, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.555340984615385 ], [ 29.76138, -1.555610461538461 ], [ 29.761918965517243, -1.555610461538461 ], [ 29.761918965517243, -1.555340984615385 ], [ 29.76138, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15532, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.555340984615385 ], [ 29.761918965517243, -1.555610461538461 ], [ 29.762188448275861, -1.555610461538461 ], [ 29.762188448275861, -1.555340984615385 ], [ 29.761918965517243, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15533, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.555340984615385 ], [ 29.762457931034483, -1.555879938461538 ], [ 29.762727413793105, -1.555879938461538 ], [ 29.762727413793105, -1.555340984615385 ], [ 29.762457931034483, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15534, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.555340984615385 ], [ 29.762727413793105, -1.555879938461538 ], [ 29.762996896551723, -1.555879938461538 ], [ 29.762996896551723, -1.555340984615385 ], [ 29.762727413793105, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15535, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.555340984615385 ], [ 29.762996896551723, -1.555610461538461 ], [ 29.763266379310345, -1.555610461538461 ], [ 29.763266379310345, -1.555340984615385 ], [ 29.762996896551723, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15536, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763266379310345, -1.555340984615385 ], [ 29.763266379310345, -1.555610461538461 ], [ 29.763535862068967, -1.555610461538461 ], [ 29.763535862068967, -1.555340984615385 ], [ 29.763266379310345, -1.555340984615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15537, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.555610461538461 ], [ 29.756798793103449, -1.555879938461538 ], [ 29.757068275862068, -1.555879938461538 ], [ 29.757068275862068, -1.555610461538461 ], [ 29.756798793103449, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15538, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.555610461538461 ], [ 29.757068275862068, -1.555879938461538 ], [ 29.75733775862069, -1.555879938461538 ], [ 29.75733775862069, -1.555610461538461 ], [ 29.757068275862068, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15539, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.555610461538461 ], [ 29.75733775862069, -1.555879938461538 ], [ 29.757607241379311, -1.555879938461538 ], [ 29.757607241379311, -1.555610461538461 ], [ 29.75733775862069, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15540, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.555610461538461 ], [ 29.757607241379311, -1.555879938461538 ], [ 29.757876724137933, -1.555879938461538 ], [ 29.757876724137933, -1.555610461538461 ], [ 29.757607241379311, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15541, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.555610461538461 ], [ 29.757876724137933, -1.555879938461538 ], [ 29.758146206896551, -1.555879938461538 ], [ 29.758146206896551, -1.555610461538461 ], [ 29.757876724137933, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15542, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.555610461538461 ], [ 29.758146206896551, -1.555879938461538 ], [ 29.758415689655173, -1.555879938461538 ], [ 29.758415689655173, -1.555610461538461 ], [ 29.758146206896551, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15543, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.555610461538461 ], [ 29.758415689655173, -1.555879938461538 ], [ 29.758685172413795, -1.555879938461538 ], [ 29.758685172413795, -1.555610461538461 ], [ 29.758415689655173, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15544, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.555610461538461 ], [ 29.758685172413795, -1.555879938461538 ], [ 29.758954655172413, -1.555879938461538 ], [ 29.758954655172413, -1.555610461538461 ], [ 29.758685172413795, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15545, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.555610461538461 ], [ 29.758954655172413, -1.555879938461538 ], [ 29.759224137931035, -1.555879938461538 ], [ 29.759224137931035, -1.555610461538461 ], [ 29.758954655172413, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15546, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.555610461538461 ], [ 29.759224137931035, -1.555879938461538 ], [ 29.760571551724137, -1.555879938461538 ], [ 29.760571551724137, -1.555610461538461 ], [ 29.759224137931035, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15547, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.555610461538461 ], [ 29.760571551724137, -1.555879938461538 ], [ 29.760841034482759, -1.555879938461538 ], [ 29.760841034482759, -1.555610461538461 ], [ 29.760571551724137, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15548, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.555610461538461 ], [ 29.760841034482759, -1.555879938461538 ], [ 29.761110517241381, -1.555879938461538 ], [ 29.761110517241381, -1.555610461538461 ], [ 29.760841034482759, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15549, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.555610461538461 ], [ 29.761110517241381, -1.556149415384615 ], [ 29.76138, -1.556149415384615 ], [ 29.76138, -1.555610461538461 ], [ 29.761110517241381, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15550, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.555610461538461 ], [ 29.76138, -1.555879938461538 ], [ 29.761918965517243, -1.555879938461538 ], [ 29.761918965517243, -1.555610461538461 ], [ 29.76138, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15551, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.555610461538461 ], [ 29.762188448275861, -1.555879938461538 ], [ 29.762457931034483, -1.555879938461538 ], [ 29.762457931034483, -1.555610461538461 ], [ 29.762188448275861, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15552, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762996896551723, -1.555610461538461 ], [ 29.762996896551723, -1.556149415384615 ], [ 29.763266379310345, -1.556149415384615 ], [ 29.763266379310345, -1.555610461538461 ], [ 29.762996896551723, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15553, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.555879938461538 ], [ 29.756259827586206, -1.556149415384615 ], [ 29.756798793103449, -1.556149415384615 ], [ 29.756798793103449, -1.555879938461538 ], [ 29.756259827586206, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15554, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.555879938461538 ], [ 29.756798793103449, -1.556149415384615 ], [ 29.757068275862068, -1.556149415384615 ], [ 29.757068275862068, -1.555879938461538 ], [ 29.756798793103449, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15555, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.555879938461538 ], [ 29.757068275862068, -1.556149415384615 ], [ 29.75733775862069, -1.556149415384615 ], [ 29.75733775862069, -1.555879938461538 ], [ 29.757068275862068, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15556, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.555879938461538 ], [ 29.75733775862069, -1.556149415384615 ], [ 29.757607241379311, -1.556149415384615 ], [ 29.757607241379311, -1.555879938461538 ], [ 29.75733775862069, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15557, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.555879938461538 ], [ 29.757607241379311, -1.556149415384615 ], [ 29.757876724137933, -1.556149415384615 ], [ 29.757876724137933, -1.555879938461538 ], [ 29.757607241379311, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15558, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.555879938461538 ], [ 29.757876724137933, -1.556149415384615 ], [ 29.758146206896551, -1.556149415384615 ], [ 29.758146206896551, -1.555879938461538 ], [ 29.757876724137933, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15559, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.555879938461538 ], [ 29.758146206896551, -1.556149415384615 ], [ 29.758415689655173, -1.556149415384615 ], [ 29.758415689655173, -1.555879938461538 ], [ 29.758146206896551, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15560, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.555879938461538 ], [ 29.758415689655173, -1.556149415384615 ], [ 29.758685172413795, -1.556149415384615 ], [ 29.758685172413795, -1.555879938461538 ], [ 29.758415689655173, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15561, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.555879938461538 ], [ 29.758685172413795, -1.556149415384615 ], [ 29.758954655172413, -1.556149415384615 ], [ 29.758954655172413, -1.555879938461538 ], [ 29.758685172413795, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15562, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.555879938461538 ], [ 29.758954655172413, -1.556149415384615 ], [ 29.759224137931035, -1.556149415384615 ], [ 29.759224137931035, -1.555879938461538 ], [ 29.758954655172413, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15563, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.555879938461538 ], [ 29.759224137931035, -1.556149415384615 ], [ 29.759493620689657, -1.556149415384615 ], [ 29.759493620689657, -1.555879938461538 ], [ 29.759224137931035, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15564, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.555879938461538 ], [ 29.759493620689657, -1.556149415384615 ], [ 29.760302068965519, -1.556149415384615 ], [ 29.760302068965519, -1.555879938461538 ], [ 29.759493620689657, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15565, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.555879938461538 ], [ 29.760302068965519, -1.556149415384615 ], [ 29.760571551724137, -1.556149415384615 ], [ 29.760571551724137, -1.555879938461538 ], [ 29.760302068965519, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15566, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.555879938461538 ], [ 29.76138, -1.556149415384615 ], [ 29.761649482758621, -1.556149415384615 ], [ 29.761649482758621, -1.555879938461538 ], [ 29.76138, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15567, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.555610461538461 ], [ 29.762188448275861, -1.555610461538461 ], [ 29.762188448275861, -1.556149415384615 ], [ 29.761649482758621, -1.556149415384615 ], [ 29.761649482758621, -1.555879938461538 ], [ 29.761918965517243, -1.555879938461538 ], [ 29.761918965517243, -1.555610461538461 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15568, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.555879938461538 ], [ 29.762457931034483, -1.556418892307692 ], [ 29.762727413793105, -1.556418892307692 ], [ 29.762727413793105, -1.555879938461538 ], [ 29.762457931034483, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15569, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.555879938461538 ], [ 29.762727413793105, -1.556149415384615 ], [ 29.762996896551723, -1.556149415384615 ], [ 29.762996896551723, -1.555879938461538 ], [ 29.762727413793105, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15570, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.556149415384615 ], [ 29.755990344827588, -1.556418892307692 ], [ 29.756259827586206, -1.556418892307692 ], [ 29.756259827586206, -1.556149415384615 ], [ 29.755990344827588, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15571, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.556149415384615 ], [ 29.756259827586206, -1.556418892307692 ], [ 29.756798793103449, -1.556418892307692 ], [ 29.756798793103449, -1.556149415384615 ], [ 29.756259827586206, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15572, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.556149415384615 ], [ 29.756798793103449, -1.556418892307692 ], [ 29.757068275862068, -1.556418892307692 ], [ 29.757068275862068, -1.556149415384615 ], [ 29.756798793103449, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15573, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.556149415384615 ], [ 29.757068275862068, -1.556418892307692 ], [ 29.75733775862069, -1.556418892307692 ], [ 29.75733775862069, -1.556149415384615 ], [ 29.757068275862068, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15574, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.556149415384615 ], [ 29.75733775862069, -1.556418892307692 ], [ 29.757607241379311, -1.556418892307692 ], [ 29.757607241379311, -1.556149415384615 ], [ 29.75733775862069, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15575, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.556149415384615 ], [ 29.757607241379311, -1.556418892307692 ], [ 29.757876724137933, -1.556418892307692 ], [ 29.757876724137933, -1.556149415384615 ], [ 29.757607241379311, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15576, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.556149415384615 ], [ 29.757876724137933, -1.556418892307692 ], [ 29.758146206896551, -1.556418892307692 ], [ 29.758146206896551, -1.556149415384615 ], [ 29.757876724137933, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15577, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.556149415384615 ], [ 29.758146206896551, -1.556418892307692 ], [ 29.758415689655173, -1.556418892307692 ], [ 29.758415689655173, -1.556149415384615 ], [ 29.758146206896551, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15578, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.556149415384615 ], [ 29.758415689655173, -1.556418892307692 ], [ 29.758685172413795, -1.556418892307692 ], [ 29.758685172413795, -1.556149415384615 ], [ 29.758415689655173, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15579, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.556149415384615 ], [ 29.758685172413795, -1.556418892307692 ], [ 29.758954655172413, -1.556418892307692 ], [ 29.758954655172413, -1.556149415384615 ], [ 29.758685172413795, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15580, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.556149415384615 ], [ 29.758954655172413, -1.556418892307692 ], [ 29.759224137931035, -1.556418892307692 ], [ 29.759224137931035, -1.556149415384615 ], [ 29.758954655172413, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15581, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.556149415384615 ], [ 29.759224137931035, -1.556418892307692 ], [ 29.759493620689657, -1.556418892307692 ], [ 29.759493620689657, -1.556149415384615 ], [ 29.759224137931035, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15582, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.556149415384615 ], [ 29.760302068965519, -1.556688369230769 ], [ 29.760571551724137, -1.556688369230769 ], [ 29.760571551724137, -1.556149415384615 ], [ 29.760302068965519, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15583, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.556149415384615 ], [ 29.761110517241381, -1.556688369230769 ], [ 29.76138, -1.556688369230769 ], [ 29.76138, -1.556149415384615 ], [ 29.761110517241381, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15584, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.556149415384615 ], [ 29.76138, -1.556418892307692 ], [ 29.761649482758621, -1.556418892307692 ], [ 29.761649482758621, -1.556149415384615 ], [ 29.76138, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15585, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.556149415384615 ], [ 29.761649482758621, -1.556418892307692 ], [ 29.761918965517243, -1.556418892307692 ], [ 29.761918965517243, -1.556149415384615 ], [ 29.761649482758621, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15586, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.555879938461538 ], [ 29.762457931034483, -1.555879938461538 ], [ 29.762457931034483, -1.556418892307692 ], [ 29.761918965517243, -1.556418892307692 ], [ 29.761918965517243, -1.556149415384615 ], [ 29.762188448275861, -1.556149415384615 ], [ 29.762188448275861, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15587, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762727413793105, -1.556149415384615 ], [ 29.762727413793105, -1.556688369230769 ], [ 29.762996896551723, -1.556688369230769 ], [ 29.762996896551723, -1.556149415384615 ], [ 29.762727413793105, -1.556149415384615 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15588, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.556418892307692 ], [ 29.755990344827588, -1.556688369230769 ], [ 29.756259827586206, -1.556688369230769 ], [ 29.756259827586206, -1.556418892307692 ], [ 29.755990344827588, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15589, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.556418892307692 ], [ 29.756259827586206, -1.556688369230769 ], [ 29.756798793103449, -1.556688369230769 ], [ 29.756798793103449, -1.556418892307692 ], [ 29.756259827586206, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15590, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.556418892307692 ], [ 29.756798793103449, -1.556688369230769 ], [ 29.757068275862068, -1.556688369230769 ], [ 29.757068275862068, -1.556418892307692 ], [ 29.756798793103449, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15591, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.556418892307692 ], [ 29.757068275862068, -1.556688369230769 ], [ 29.75733775862069, -1.556688369230769 ], [ 29.75733775862069, -1.556418892307692 ], [ 29.757068275862068, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15592, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.556418892307692 ], [ 29.75733775862069, -1.556688369230769 ], [ 29.757607241379311, -1.556688369230769 ], [ 29.757607241379311, -1.556418892307692 ], [ 29.75733775862069, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15593, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.556418892307692 ], [ 29.757607241379311, -1.556688369230769 ], [ 29.757876724137933, -1.556688369230769 ], [ 29.757876724137933, -1.556418892307692 ], [ 29.757607241379311, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15594, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.556418892307692 ], [ 29.757876724137933, -1.556688369230769 ], [ 29.758146206896551, -1.556688369230769 ], [ 29.758146206896551, -1.556418892307692 ], [ 29.757876724137933, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15595, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.556418892307692 ], [ 29.758146206896551, -1.556688369230769 ], [ 29.758415689655173, -1.556688369230769 ], [ 29.758415689655173, -1.556418892307692 ], [ 29.758146206896551, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15596, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.556418892307692 ], [ 29.758415689655173, -1.556688369230769 ], [ 29.758685172413795, -1.556688369230769 ], [ 29.758685172413795, -1.556418892307692 ], [ 29.758415689655173, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15597, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.556418892307692 ], [ 29.758685172413795, -1.556688369230769 ], [ 29.758954655172413, -1.556688369230769 ], [ 29.758954655172413, -1.556418892307692 ], [ 29.758685172413795, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15598, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.556418892307692 ], [ 29.758954655172413, -1.556688369230769 ], [ 29.759224137931035, -1.556688369230769 ], [ 29.759224137931035, -1.556418892307692 ], [ 29.758954655172413, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15599, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.556418892307692 ], [ 29.76138, -1.556688369230769 ], [ 29.761649482758621, -1.556688369230769 ], [ 29.761649482758621, -1.556418892307692 ], [ 29.76138, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15600, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.556418892307692 ], [ 29.761649482758621, -1.556688369230769 ], [ 29.761918965517243, -1.556688369230769 ], [ 29.761918965517243, -1.556418892307692 ], [ 29.761649482758621, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15601, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.556418892307692 ], [ 29.761918965517243, -1.556688369230769 ], [ 29.762457931034483, -1.556688369230769 ], [ 29.762457931034483, -1.556418892307692 ], [ 29.761918965517243, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15602, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762457931034483, -1.556418892307692 ], [ 29.762457931034483, -1.556688369230769 ], [ 29.762727413793105, -1.556688369230769 ], [ 29.762727413793105, -1.556418892307692 ], [ 29.762457931034483, -1.556418892307692 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15603, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.554532553846154 ], [ 29.754103965517242, -1.557766276923077 ], [ 29.754373448275864, -1.557766276923077 ], [ 29.754373448275864, -1.554532553846154 ], [ 29.754103965517242, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15604, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.554532553846154 ], [ 29.754373448275864, -1.557766276923077 ], [ 29.754642931034482, -1.557766276923077 ], [ 29.754642931034482, -1.554532553846154 ], [ 29.754373448275864, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15605, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.554532553846154 ], [ 29.754912413793104, -1.5574968 ], [ 29.755181896551726, -1.5574968 ], [ 29.755181896551726, -1.554532553846154 ], [ 29.754912413793104, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15606, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.554532553846154 ], [ 29.755181896551726, -1.5574968 ], [ 29.755451379310344, -1.5574968 ], [ 29.755451379310344, -1.554532553846154 ], [ 29.755181896551726, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15607, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.554532553846154 ], [ 29.755451379310344, -1.5574968 ], [ 29.755720862068966, -1.5574968 ], [ 29.755720862068966, -1.557227323076923 ], [ 29.755990344827588, -1.557227323076923 ], [ 29.755990344827588, -1.556957846153846 ], [ 29.755720862068966, -1.556957846153846 ], [ 29.755720862068966, -1.554532553846154 ], [ 29.755451379310344, -1.554532553846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15608, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75383448275862, -1.554802030769231 ], [ 29.75383448275862, -1.558035753846154 ], [ 29.754103965517242, -1.558035753846154 ], [ 29.754103965517242, -1.554802030769231 ], [ 29.75383448275862, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15609, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.554802030769231 ], [ 29.754642931034482, -1.5574968 ], [ 29.754912413793104, -1.5574968 ], [ 29.754912413793104, -1.554802030769231 ], [ 29.754642931034482, -1.554802030769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15610, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.555879938461538 ], [ 29.760571551724137, -1.556957846153846 ], [ 29.760841034482759, -1.556957846153846 ], [ 29.760841034482759, -1.555879938461538 ], [ 29.760571551724137, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15611, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.556688369230769 ], [ 29.755720862068966, -1.556957846153846 ], [ 29.755990344827588, -1.556957846153846 ], [ 29.755990344827588, -1.556688369230769 ], [ 29.755720862068966, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15612, "El": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.556688369230769 ], [ 29.755990344827588, -1.556957846153846 ], [ 29.756259827586206, -1.556957846153846 ], [ 29.756259827586206, -1.556688369230769 ], [ 29.755990344827588, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15613, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.556688369230769 ], [ 29.756259827586206, -1.556957846153846 ], [ 29.756798793103449, -1.556957846153846 ], [ 29.756798793103449, -1.556688369230769 ], [ 29.756259827586206, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15614, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.556688369230769 ], [ 29.756798793103449, -1.556957846153846 ], [ 29.757068275862068, -1.556957846153846 ], [ 29.757068275862068, -1.556688369230769 ], [ 29.756798793103449, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15615, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.556688369230769 ], [ 29.757068275862068, -1.556957846153846 ], [ 29.75733775862069, -1.556957846153846 ], [ 29.75733775862069, -1.556688369230769 ], [ 29.757068275862068, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15616, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.556688369230769 ], [ 29.75733775862069, -1.556957846153846 ], [ 29.757607241379311, -1.556957846153846 ], [ 29.757607241379311, -1.556688369230769 ], [ 29.75733775862069, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15617, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.556688369230769 ], [ 29.757607241379311, -1.556957846153846 ], [ 29.757876724137933, -1.556957846153846 ], [ 29.757876724137933, -1.556688369230769 ], [ 29.757607241379311, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15618, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.556688369230769 ], [ 29.757876724137933, -1.556957846153846 ], [ 29.758146206896551, -1.556957846153846 ], [ 29.758146206896551, -1.556688369230769 ], [ 29.757876724137933, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15619, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.556688369230769 ], [ 29.758146206896551, -1.556957846153846 ], [ 29.758415689655173, -1.556957846153846 ], [ 29.758415689655173, -1.556688369230769 ], [ 29.758146206896551, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15620, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.556688369230769 ], [ 29.758415689655173, -1.556957846153846 ], [ 29.758685172413795, -1.556957846153846 ], [ 29.758685172413795, -1.556688369230769 ], [ 29.758415689655173, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15621, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.556688369230769 ], [ 29.758685172413795, -1.556957846153846 ], [ 29.758954655172413, -1.556957846153846 ], [ 29.758954655172413, -1.556688369230769 ], [ 29.758685172413795, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15622, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.556688369230769 ], [ 29.760032586206897, -1.556957846153846 ], [ 29.760302068965519, -1.556957846153846 ], [ 29.760302068965519, -1.556688369230769 ], [ 29.760032586206897, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15623, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.556688369230769 ], [ 29.760302068965519, -1.556957846153846 ], [ 29.760571551724137, -1.556957846153846 ], [ 29.760571551724137, -1.556688369230769 ], [ 29.760302068965519, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15624, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.556688369230769 ], [ 29.761110517241381, -1.556957846153846 ], [ 29.76138, -1.556957846153846 ], [ 29.76138, -1.556688369230769 ], [ 29.761110517241381, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15625, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.556688369230769 ], [ 29.76138, -1.556957846153846 ], [ 29.761649482758621, -1.556957846153846 ], [ 29.761649482758621, -1.556688369230769 ], [ 29.76138, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15626, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.556688369230769 ], [ 29.761649482758621, -1.556957846153846 ], [ 29.761918965517243, -1.556957846153846 ], [ 29.761918965517243, -1.556688369230769 ], [ 29.761649482758621, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15627, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.556688369230769 ], [ 29.761918965517243, -1.556957846153846 ], [ 29.762188448275861, -1.556957846153846 ], [ 29.762188448275861, -1.556688369230769 ], [ 29.761918965517243, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15628, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762188448275861, -1.556688369230769 ], [ 29.762188448275861, -1.556957846153846 ], [ 29.762727413793105, -1.556957846153846 ], [ 29.762727413793105, -1.556688369230769 ], [ 29.762188448275861, -1.556688369230769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15629, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.556957846153846 ], [ 29.755990344827588, -1.557227323076923 ], [ 29.756259827586206, -1.557227323076923 ], [ 29.756259827586206, -1.556957846153846 ], [ 29.755990344827588, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15630, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.556957846153846 ], [ 29.756259827586206, -1.557227323076923 ], [ 29.756798793103449, -1.557227323076923 ], [ 29.756798793103449, -1.556957846153846 ], [ 29.756259827586206, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15631, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.556957846153846 ], [ 29.756798793103449, -1.557227323076923 ], [ 29.757068275862068, -1.557227323076923 ], [ 29.757068275862068, -1.556957846153846 ], [ 29.756798793103449, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15632, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.556957846153846 ], [ 29.757068275862068, -1.557227323076923 ], [ 29.75733775862069, -1.557227323076923 ], [ 29.75733775862069, -1.556957846153846 ], [ 29.757068275862068, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15633, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.556957846153846 ], [ 29.75733775862069, -1.557227323076923 ], [ 29.757607241379311, -1.557227323076923 ], [ 29.757607241379311, -1.556957846153846 ], [ 29.75733775862069, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15634, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.556957846153846 ], [ 29.757607241379311, -1.557227323076923 ], [ 29.757876724137933, -1.557227323076923 ], [ 29.757876724137933, -1.556957846153846 ], [ 29.757607241379311, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15635, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.556957846153846 ], [ 29.757876724137933, -1.557227323076923 ], [ 29.758146206896551, -1.557227323076923 ], [ 29.758146206896551, -1.556957846153846 ], [ 29.757876724137933, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15636, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.556957846153846 ], [ 29.758146206896551, -1.557227323076923 ], [ 29.758415689655173, -1.557227323076923 ], [ 29.758415689655173, -1.556957846153846 ], [ 29.758146206896551, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15637, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758415689655173, -1.556957846153846 ], [ 29.758415689655173, -1.557227323076923 ], [ 29.758685172413795, -1.557227323076923 ], [ 29.758685172413795, -1.556957846153846 ], [ 29.758415689655173, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15638, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.556957846153846 ], [ 29.759763103448275, -1.557227323076923 ], [ 29.760032586206897, -1.557227323076923 ], [ 29.760032586206897, -1.556957846153846 ], [ 29.759763103448275, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15639, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.556957846153846 ], [ 29.760032586206897, -1.557227323076923 ], [ 29.760302068965519, -1.557227323076923 ], [ 29.760302068965519, -1.556957846153846 ], [ 29.760032586206897, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15640, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.556957846153846 ], [ 29.760302068965519, -1.557227323076923 ], [ 29.760571551724137, -1.557227323076923 ], [ 29.760571551724137, -1.556957846153846 ], [ 29.760302068965519, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15641, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.556957846153846 ], [ 29.76138, -1.557227323076923 ], [ 29.761649482758621, -1.557227323076923 ], [ 29.761649482758621, -1.556957846153846 ], [ 29.76138, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15642, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.556957846153846 ], [ 29.761649482758621, -1.557227323076923 ], [ 29.761918965517243, -1.557227323076923 ], [ 29.761918965517243, -1.556957846153846 ], [ 29.761649482758621, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15643, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761918965517243, -1.556957846153846 ], [ 29.761918965517243, -1.557227323076923 ], [ 29.762188448275861, -1.557227323076923 ], [ 29.762188448275861, -1.556957846153846 ], [ 29.761918965517243, -1.556957846153846 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15644, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.557227323076923 ], [ 29.755720862068966, -1.5574968 ], [ 29.755990344827588, -1.5574968 ], [ 29.755990344827588, -1.557227323076923 ], [ 29.755720862068966, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15645, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.557227323076923 ], [ 29.755990344827588, -1.5574968 ], [ 29.756259827586206, -1.5574968 ], [ 29.756259827586206, -1.557227323076923 ], [ 29.755990344827588, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15646, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.557227323076923 ], [ 29.756259827586206, -1.5574968 ], [ 29.756798793103449, -1.5574968 ], [ 29.756798793103449, -1.557227323076923 ], [ 29.756259827586206, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15647, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.557227323076923 ], [ 29.756798793103449, -1.5574968 ], [ 29.757068275862068, -1.5574968 ], [ 29.757068275862068, -1.557227323076923 ], [ 29.756798793103449, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15648, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.557227323076923 ], [ 29.757068275862068, -1.5574968 ], [ 29.75733775862069, -1.5574968 ], [ 29.75733775862069, -1.557227323076923 ], [ 29.757068275862068, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15649, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.557227323076923 ], [ 29.75733775862069, -1.5574968 ], [ 29.757607241379311, -1.5574968 ], [ 29.757607241379311, -1.557227323076923 ], [ 29.75733775862069, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15650, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.557227323076923 ], [ 29.757607241379311, -1.5574968 ], [ 29.757876724137933, -1.5574968 ], [ 29.757876724137933, -1.557227323076923 ], [ 29.757607241379311, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15651, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.557227323076923 ], [ 29.757876724137933, -1.5574968 ], [ 29.758146206896551, -1.5574968 ], [ 29.758146206896551, -1.557227323076923 ], [ 29.757876724137933, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15652, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758146206896551, -1.557227323076923 ], [ 29.758146206896551, -1.5574968 ], [ 29.758415689655173, -1.5574968 ], [ 29.758415689655173, -1.557227323076923 ], [ 29.758146206896551, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15653, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.557227323076923 ], [ 29.759493620689657, -1.557766276923077 ], [ 29.759763103448275, -1.557766276923077 ], [ 29.759763103448275, -1.557227323076923 ], [ 29.759493620689657, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15654, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.557227323076923 ], [ 29.759763103448275, -1.5574968 ], [ 29.760032586206897, -1.5574968 ], [ 29.760032586206897, -1.557227323076923 ], [ 29.759763103448275, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15655, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.557227323076923 ], [ 29.760032586206897, -1.5574968 ], [ 29.760302068965519, -1.5574968 ], [ 29.760302068965519, -1.557227323076923 ], [ 29.760032586206897, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15656, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.555879938461538 ], [ 29.761110517241381, -1.555879938461538 ], [ 29.761110517241381, -1.556957846153846 ], [ 29.76138, -1.556957846153846 ], [ 29.76138, -1.557227323076923 ], [ 29.760841034482759, -1.557227323076923 ], [ 29.760841034482759, -1.5574968 ], [ 29.760302068965519, -1.5574968 ], [ 29.760302068965519, -1.557227323076923 ], [ 29.760571551724137, -1.557227323076923 ], [ 29.760571551724137, -1.556957846153846 ], [ 29.760841034482759, -1.556957846153846 ], [ 29.760841034482759, -1.555879938461538 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15657, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.557227323076923 ], [ 29.760841034482759, -1.5574968 ], [ 29.76138, -1.5574968 ], [ 29.76138, -1.557227323076923 ], [ 29.760841034482759, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15658, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76138, -1.557227323076923 ], [ 29.76138, -1.5574968 ], [ 29.761649482758621, -1.5574968 ], [ 29.761649482758621, -1.557227323076923 ], [ 29.76138, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15659, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761649482758621, -1.557227323076923 ], [ 29.761649482758621, -1.5574968 ], [ 29.761918965517243, -1.5574968 ], [ 29.761918965517243, -1.557227323076923 ], [ 29.761649482758621, -1.557227323076923 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15660, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.5574968 ], [ 29.754642931034482, -1.557766276923077 ], [ 29.754912413793104, -1.557766276923077 ], [ 29.754912413793104, -1.5574968 ], [ 29.754642931034482, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15661, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.5574968 ], [ 29.754912413793104, -1.558035753846154 ], [ 29.755181896551726, -1.558035753846154 ], [ 29.755181896551726, -1.5574968 ], [ 29.754912413793104, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15662, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.5574968 ], [ 29.755181896551726, -1.557766276923077 ], [ 29.755451379310344, -1.557766276923077 ], [ 29.755451379310344, -1.5574968 ], [ 29.755181896551726, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15663, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755451379310344, -1.5574968 ], [ 29.755451379310344, -1.557766276923077 ], [ 29.755720862068966, -1.557766276923077 ], [ 29.755720862068966, -1.5574968 ], [ 29.755451379310344, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15664, "El": 13 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.5574968 ], [ 29.755720862068966, -1.557766276923077 ], [ 29.755990344827588, -1.557766276923077 ], [ 29.755990344827588, -1.5574968 ], [ 29.755720862068966, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15665, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.5574968 ], [ 29.755990344827588, -1.557766276923077 ], [ 29.756259827586206, -1.557766276923077 ], [ 29.756259827586206, -1.5574968 ], [ 29.755990344827588, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15666, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.5574968 ], [ 29.756259827586206, -1.557766276923077 ], [ 29.756798793103449, -1.557766276923077 ], [ 29.756798793103449, -1.5574968 ], [ 29.756259827586206, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15667, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.5574968 ], [ 29.756798793103449, -1.557766276923077 ], [ 29.757068275862068, -1.557766276923077 ], [ 29.757068275862068, -1.5574968 ], [ 29.756798793103449, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15668, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.5574968 ], [ 29.757068275862068, -1.557766276923077 ], [ 29.75733775862069, -1.557766276923077 ], [ 29.75733775862069, -1.5574968 ], [ 29.757068275862068, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15669, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.5574968 ], [ 29.75733775862069, -1.557766276923077 ], [ 29.757607241379311, -1.557766276923077 ], [ 29.757607241379311, -1.5574968 ], [ 29.75733775862069, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15670, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.5574968 ], [ 29.757607241379311, -1.557766276923077 ], [ 29.757876724137933, -1.557766276923077 ], [ 29.757876724137933, -1.5574968 ], [ 29.757607241379311, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15671, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757876724137933, -1.5574968 ], [ 29.757876724137933, -1.557766276923077 ], [ 29.758146206896551, -1.557766276923077 ], [ 29.758146206896551, -1.5574968 ], [ 29.757876724137933, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15672, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.5574968 ], [ 29.760032586206897, -1.557766276923077 ], [ 29.760302068965519, -1.557766276923077 ], [ 29.760302068965519, -1.5574968 ], [ 29.760032586206897, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15673, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.5574968 ], [ 29.760302068965519, -1.557766276923077 ], [ 29.760841034482759, -1.557766276923077 ], [ 29.760841034482759, -1.5574968 ], [ 29.760302068965519, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15674, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760841034482759, -1.5574968 ], [ 29.760841034482759, -1.557766276923077 ], [ 29.761110517241381, -1.557766276923077 ], [ 29.761110517241381, -1.5574968 ], [ 29.760841034482759, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15675, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761110517241381, -1.5574968 ], [ 29.761110517241381, -1.557766276923077 ], [ 29.761649482758621, -1.557766276923077 ], [ 29.761649482758621, -1.5574968 ], [ 29.761110517241381, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15676, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.557766276923077 ], [ 29.754103965517242, -1.558035753846154 ], [ 29.754373448275864, -1.558035753846154 ], [ 29.754373448275864, -1.557766276923077 ], [ 29.754103965517242, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15677, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.557766276923077 ], [ 29.754373448275864, -1.558305230769231 ], [ 29.754642931034482, -1.558305230769231 ], [ 29.754642931034482, -1.557766276923077 ], [ 29.754373448275864, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15678, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.557766276923077 ], [ 29.754642931034482, -1.558305230769231 ], [ 29.754912413793104, -1.558305230769231 ], [ 29.754912413793104, -1.557766276923077 ], [ 29.754642931034482, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15679, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.557766276923077 ], [ 29.755181896551726, -1.558035753846154 ], [ 29.755720862068966, -1.558035753846154 ], [ 29.755720862068966, -1.557766276923077 ], [ 29.755181896551726, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15680, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.557766276923077 ], [ 29.755720862068966, -1.558035753846154 ], [ 29.756259827586206, -1.558035753846154 ], [ 29.756259827586206, -1.557766276923077 ], [ 29.755720862068966, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15681, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.557766276923077 ], [ 29.756259827586206, -1.558035753846154 ], [ 29.756798793103449, -1.558035753846154 ], [ 29.756798793103449, -1.557766276923077 ], [ 29.756259827586206, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15682, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.557766276923077 ], [ 29.756798793103449, -1.558035753846154 ], [ 29.757068275862068, -1.558035753846154 ], [ 29.757068275862068, -1.557766276923077 ], [ 29.756798793103449, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15683, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.557766276923077 ], [ 29.757068275862068, -1.558035753846154 ], [ 29.75733775862069, -1.558035753846154 ], [ 29.75733775862069, -1.557766276923077 ], [ 29.757068275862068, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15684, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.557766276923077 ], [ 29.75733775862069, -1.558035753846154 ], [ 29.757607241379311, -1.558035753846154 ], [ 29.757607241379311, -1.557766276923077 ], [ 29.75733775862069, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15685, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757607241379311, -1.557766276923077 ], [ 29.757607241379311, -1.558035753846154 ], [ 29.757876724137933, -1.558035753846154 ], [ 29.757876724137933, -1.557766276923077 ], [ 29.757607241379311, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15686, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.557766276923077 ], [ 29.759224137931035, -1.558035753846154 ], [ 29.759493620689657, -1.558035753846154 ], [ 29.759493620689657, -1.557766276923077 ], [ 29.759224137931035, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15687, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.557766276923077 ], [ 29.759493620689657, -1.558035753846154 ], [ 29.759763103448275, -1.558035753846154 ], [ 29.759763103448275, -1.557766276923077 ], [ 29.759493620689657, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15688, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.557766276923077 ], [ 29.760302068965519, -1.558035753846154 ], [ 29.760571551724137, -1.558035753846154 ], [ 29.760571551724137, -1.557766276923077 ], [ 29.760302068965519, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15689, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760571551724137, -1.557766276923077 ], [ 29.760571551724137, -1.558035753846154 ], [ 29.761110517241381, -1.558035753846154 ], [ 29.761110517241381, -1.557766276923077 ], [ 29.760571551724137, -1.557766276923077 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15690, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754103965517242, -1.558035753846154 ], [ 29.754103965517242, -1.558574707692308 ], [ 29.754373448275864, -1.558574707692308 ], [ 29.754373448275864, -1.558035753846154 ], [ 29.754103965517242, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15691, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.558035753846154 ], [ 29.754912413793104, -1.558305230769231 ], [ 29.755181896551726, -1.558305230769231 ], [ 29.755181896551726, -1.558035753846154 ], [ 29.754912413793104, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15692, "El": 9 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.558035753846154 ], [ 29.755181896551726, -1.558305230769231 ], [ 29.755720862068966, -1.558305230769231 ], [ 29.755720862068966, -1.558035753846154 ], [ 29.755181896551726, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15693, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755720862068966, -1.558035753846154 ], [ 29.755720862068966, -1.558305230769231 ], [ 29.755990344827588, -1.558305230769231 ], [ 29.755990344827588, -1.558035753846154 ], [ 29.755720862068966, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15694, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.558035753846154 ], [ 29.755990344827588, -1.558305230769231 ], [ 29.756798793103449, -1.558305230769231 ], [ 29.756798793103449, -1.558035753846154 ], [ 29.755990344827588, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15695, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.558035753846154 ], [ 29.756798793103449, -1.558305230769231 ], [ 29.757068275862068, -1.558305230769231 ], [ 29.757068275862068, -1.558035753846154 ], [ 29.756798793103449, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15696, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.558035753846154 ], [ 29.757068275862068, -1.558305230769231 ], [ 29.75733775862069, -1.558305230769231 ], [ 29.75733775862069, -1.558035753846154 ], [ 29.757068275862068, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15697, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75733775862069, -1.558035753846154 ], [ 29.75733775862069, -1.558305230769231 ], [ 29.757607241379311, -1.558305230769231 ], [ 29.757607241379311, -1.558035753846154 ], [ 29.75733775862069, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15698, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.558035753846154 ], [ 29.758954655172413, -1.558305230769231 ], [ 29.759224137931035, -1.558305230769231 ], [ 29.759224137931035, -1.558035753846154 ], [ 29.758954655172413, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15699, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759224137931035, -1.558035753846154 ], [ 29.759224137931035, -1.558305230769231 ], [ 29.759493620689657, -1.558305230769231 ], [ 29.759493620689657, -1.558035753846154 ], [ 29.759224137931035, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15700, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.558035753846154 ], [ 29.760032586206897, -1.558305230769231 ], [ 29.760302068965519, -1.558305230769231 ], [ 29.760302068965519, -1.558035753846154 ], [ 29.760032586206897, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15701, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760302068965519, -1.558035753846154 ], [ 29.760302068965519, -1.558305230769231 ], [ 29.760571551724137, -1.558305230769231 ], [ 29.760571551724137, -1.558035753846154 ], [ 29.760302068965519, -1.558035753846154 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15702, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.558305230769231 ], [ 29.754373448275864, -1.558574707692308 ], [ 29.754642931034482, -1.558574707692308 ], [ 29.754642931034482, -1.558305230769231 ], [ 29.754373448275864, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15703, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.558305230769231 ], [ 29.754642931034482, -1.558844184615385 ], [ 29.755990344827588, -1.558844184615385 ], [ 29.755990344827588, -1.558574707692308 ], [ 29.754912413793104, -1.558574707692308 ], [ 29.754912413793104, -1.558305230769231 ], [ 29.754642931034482, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15704, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754912413793104, -1.558305230769231 ], [ 29.754912413793104, -1.558574707692308 ], [ 29.755990344827588, -1.558574707692308 ], [ 29.755990344827588, -1.558305230769231 ], [ 29.754912413793104, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15705, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.558305230769231 ], [ 29.755990344827588, -1.558574707692308 ], [ 29.756798793103449, -1.558574707692308 ], [ 29.756798793103449, -1.558305230769231 ], [ 29.755990344827588, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15706, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756798793103449, -1.558305230769231 ], [ 29.756798793103449, -1.558574707692308 ], [ 29.757068275862068, -1.558574707692308 ], [ 29.757068275862068, -1.558305230769231 ], [ 29.756798793103449, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15707, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757068275862068, -1.558305230769231 ], [ 29.757068275862068, -1.558574707692308 ], [ 29.75733775862069, -1.558574707692308 ], [ 29.75733775862069, -1.558305230769231 ], [ 29.757068275862068, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15708, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758954655172413, -1.558305230769231 ], [ 29.758954655172413, -1.558574707692308 ], [ 29.759224137931035, -1.558574707692308 ], [ 29.759224137931035, -1.558305230769231 ], [ 29.758954655172413, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15709, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.558305230769231 ], [ 29.759763103448275, -1.558574707692308 ], [ 29.760032586206897, -1.558574707692308 ], [ 29.760032586206897, -1.558305230769231 ], [ 29.759763103448275, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15710, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760032586206897, -1.558305230769231 ], [ 29.760032586206897, -1.558574707692308 ], [ 29.760302068965519, -1.558574707692308 ], [ 29.760302068965519, -1.558305230769231 ], [ 29.760032586206897, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15711, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755990344827588, -1.558574707692308 ], [ 29.755990344827588, -1.558844184615385 ], [ 29.756259827586206, -1.558844184615385 ], [ 29.756259827586206, -1.558574707692308 ], [ 29.755990344827588, -1.558574707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15712, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756259827586206, -1.558574707692308 ], [ 29.756259827586206, -1.558844184615385 ], [ 29.757068275862068, -1.558844184615385 ], [ 29.757068275862068, -1.558574707692308 ], [ 29.756259827586206, -1.558574707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15713, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.5574968 ], [ 29.760032586206897, -1.5574968 ], [ 29.760032586206897, -1.557766276923077 ], [ 29.760302068965519, -1.557766276923077 ], [ 29.760302068965519, -1.558035753846154 ], [ 29.760032586206897, -1.558035753846154 ], [ 29.760032586206897, -1.558305230769231 ], [ 29.759763103448275, -1.558305230769231 ], [ 29.759763103448275, -1.558574707692308 ], [ 29.759493620689657, -1.558574707692308 ], [ 29.759493620689657, -1.558844184615385 ], [ 29.758954655172413, -1.558844184615385 ], [ 29.758954655172413, -1.558574707692308 ], [ 29.759224137931035, -1.558574707692308 ], [ 29.759224137931035, -1.558305230769231 ], [ 29.759493620689657, -1.558305230769231 ], [ 29.759493620689657, -1.558035753846154 ], [ 29.759763103448275, -1.558035753846154 ], [ 29.759763103448275, -1.5574968 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15714, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759493620689657, -1.558574707692308 ], [ 29.759493620689657, -1.558844184615385 ], [ 29.759763103448275, -1.558844184615385 ], [ 29.759763103448275, -1.558574707692308 ], [ 29.759493620689657, -1.558574707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15715, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759763103448275, -1.558574707692308 ], [ 29.759763103448275, -1.558844184615385 ], [ 29.760032586206897, -1.558844184615385 ], [ 29.760032586206897, -1.558574707692308 ], [ 29.759763103448275, -1.558574707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15716, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758685172413795, -1.558305230769231 ], [ 29.758685172413795, -1.559113661538462 ], [ 29.759763103448275, -1.559113661538462 ], [ 29.759763103448275, -1.558844184615385 ], [ 29.758954655172413, -1.558844184615385 ], [ 29.758954655172413, -1.558305230769231 ], [ 29.758685172413795, -1.558305230769231 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15717, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754373448275864, -1.558574707692308 ], [ 29.754373448275864, -1.559113661538462 ], [ 29.754642931034482, -1.559113661538462 ], [ 29.754642931034482, -1.558574707692308 ], [ 29.754373448275864, -1.558574707692308 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15718, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.558844184615385 ], [ 29.754642931034482, -1.559113661538462 ], [ 29.755181896551726, -1.559113661538462 ], [ 29.755181896551726, -1.558844184615385 ], [ 29.754642931034482, -1.558844184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15719, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755181896551726, -1.558844184615385 ], [ 29.755181896551726, -1.559113661538462 ], [ 29.756259827586206, -1.559113661538462 ], [ 29.756259827586206, -1.558844184615385 ], [ 29.755181896551726, -1.558844184615385 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15720, "El": 2 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754642931034482, -1.559113661538462 ], [ 29.754642931034482, -1.559383138461538 ], [ 29.755181896551726, -1.559383138461538 ], [ 29.755181896551726, -1.559113661538462 ], [ 29.754642931034482, -1.559113661538462 ] ] ] } }
]
}

export default ruhondo_bathymetry;