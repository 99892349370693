import { Button, Divider, Grid, Group, MediaQuery, NavLink, Paper, PasswordInput, Space, Text, TextInput, Title } from "@mantine/core";
import { useContext, useState } from "react";
import { AuthContext } from "../../../App";
import { Helmet } from "react-helmet";

export default function Settings(){
    const { state, dispatch } = useContext(AuthContext);
    const [step, setStep] = useState("0")
    const [name, setName] = useState(state.userData.name)
    const [email, setEmail] = useState(state.userData.username)
    return (
        <>
        <Helmet>
            <title>Settings | {state.userData.name}</title>
        </Helmet>
        <Grid>
            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <Grid.Col span={3}>
                <NavLink onClick={() => {setStep("0")}} active={step === "0"} mb={10} variant="subtle" label="Profile" />
                <NavLink onClick={() => {setStep("1")}} active={step === "1"} mb={10} variant="subtle" label="Change Password" />
            </Grid.Col>
            </MediaQuery>
            {step === "0" ? (
                <>
                            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <Grid.Col span={9}>
                <Paper p="lg">
                    <Title mt={30} mb={30}>Profile</Title>
                    <TextInput value={name} onChange={(e) => {setName(e.currentTarget.value)}} label="Name" mb={10} />
                    <TextInput value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} label="Email/Username" mb={30} />

                    <Button style={{backgroundColor: "#1864AB"}}  radius={28}>Save</Button>

                    <Space h="xl" />
                    <Divider />
                    <Space h="xl" />
                    <Group noWrap>
                        <Button style={{backgroundColor: "#1864AB"}}  radius={28}>Delete Account</Button>
                        <Text size="sm">By deleting this account, you will lose access to all of your datasets. You will not be able to create a new account with the username {state.userData.username}. 
                        Are you sure you want to do this?</Text>
                    </Group>
                </Paper>
            </Grid.Col>
            </MediaQuery>

            <MediaQuery largerThan={"md"} styles={{display: "none"}}>
            <Grid.Col span={12}>
                <Paper p="lg">
                    <Title mt={30} mb={30}>Profile</Title>
                    <TextInput value={name} onChange={(e) => {setName(e.currentTarget.value)}} label="Name" mb={10} />
                    <TextInput value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} label="Email/Username" mb={30} />

                    <Button style={{backgroundColor: "#1864AB"}}  radius={28}>Save</Button>

                    <Space h="xl" />
                    <Divider />
                    <Space h="xl" />
                    <Group noWrap>
                        <Button style={{backgroundColor: "#1864AB"}}  radius={28}>Delete Account</Button>
                        <Text size="sm">By deleting this account, you will lose access to all of your dataset. You will not be able to create a new account with the username {state.userData.username}. 
                        Are you sure you want to do this?</Text>
                    </Group>
                </Paper>
            </Grid.Col>
            </MediaQuery>
                </>
            ) : (
                <>
            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <Grid.Col span={9}>
                <Paper p="lg">
                    <Title mt={30} mb={30}>Change Password</Title>
                    <PasswordInput label="New Password" mb={10} />
                    <PasswordInput label="Confirm New Password" mb={30} />

                    <Button style={{backgroundColor: "#1864AB"}}  radius={28}>Save</Button>

                </Paper>
            </Grid.Col>
            </MediaQuery>

            <MediaQuery largerThan={"md"} styles={{display: "none"}}>
            <Grid.Col span={12}>
                <Paper p="lg">
                    <Title mt={30} mb={30}>Change Password</Title>
                    <PasswordInput label="New Password" mb={10} />
                    <PasswordInput label="Confirm New Password" mb={30} />

                    <Button style={{backgroundColor: "#1864AB"}}  radius={28}>Save</Button>

                </Paper>
            </Grid.Col>
            </MediaQuery>
                </>
            )}
        </Grid>
        </>
    )
}