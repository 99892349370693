import {  Badge, Table, ScrollArea, Center, Pagination, Group } from '@mantine/core';


export function EnumeratorsTable({ data=[] }) {
  const rows = data.map((item) => (
    <tr key={item.name}>
      <td>
      {item.name}
      </td>

      <td>
      {item.username}
      </td>
      <td>{new Date(item.createdAt).toLocaleDateString()}</td>
      <td>
          <Badge color={item.deleted ? "red" : "green"}>{item.deleted ? "Inactive" : "Active"}</Badge>
        </td>
    </tr>
  ));

  return (
    <ScrollArea>
      <Table fontSize={"xs"}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Created On</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
        {data.length === 0 ? (
                    <tr>
                    <td colSpan={4}>
                      <Center>No data was found</Center>
                    </td>
                  </tr>
        ) : rows}
        </tbody>
      </Table>

      <Group mt={30} position="right">
            <Pagination size="sm" page={1} total={1} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>
    </ScrollArea>
  );
}