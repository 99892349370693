const hotels = {
"type": "FeatureCollection",
"name": "Hotels",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.304150764706932, -1.844942377863737, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.491785486186544, -1.863431031245387, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Marina", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.231331062752286, -1.843033609114058, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.474059443256238, -1.843435757215929, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.478597905454553, -1.848309024002644, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Realm", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.304271197359391, -1.844912656586999, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.427391403684261, -1.895107265359036, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.444447837437728, -1.855507322653251, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Blanche", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.429498285132041, -1.820929064912664, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.43393869341298, -1.887704363904408, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.437026335728245, -1.861747708175935, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.426545092085828, -1.861719807793569, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.334738883908273, -1.868546101345954, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.346112939786423, -1.877339371855187, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Tree House", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.74290326119538, -1.494957752167819, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Amico Holiday Inn", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.743693534835192, -1.496157634130052, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Hotel", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 30.316792653268994, -2.095844518854296, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Elevate Suites Hotel", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.246212870967721, -1.697176374607243, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Ubumwe Hotel", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.247050633215078, -1.69581142318756, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Stip Hotel", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.251976510865951, -1.696717887022196, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "La Corniche", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.251508918266651, -1.695678894616086, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Gorillas Lake Kivu", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.25495603709459, -1.699021371138085, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Hill view Hotel", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.257230830782088, -1.701404265018653, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Lake Kivu Serena Hotel", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.260259139725399, -1.704209896163229, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Cleo Lake Kivu", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.34698449780295, -2.048542158377053, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Karongi Lake View", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.347306438183761, -2.050076011473661, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Bethany Hotel", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.33784353891329, -2.059002170641668, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.260130775192877, -1.703421898324216, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.235998096069519, -1.69628491552583, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.285601923323011, -1.916514450058262, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.337674885148566, -2.058826768706023, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 28.899210467625348, -2.478072071097501, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Frama Adventures", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.7692081588707, -1.463820079969944, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Umusozi 360 viewpoint", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.7440186725586, -1.454165539483087, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Twin lakes Tours Rwanda", "Type": "Hotel" }, "geometry": { "type": "Point", "coordinates": [ 29.767512378737749, -1.473417193688447, 0.0 ] } }
]
}

export default hotels;