const existing_fish_farms = {
"type": "FeatureCollection",
"name": "Existing fish farms",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.12748416848407, -2.296049467299946, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.137700645389479, -2.314269921959998, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.161432980512561, -2.286744024154523, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.30333930044424, -2.083588573028804, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.319733999773991, -2.076880272992903, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.31966256585217, -2.077546754986537, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.31910864040114, -2.072540333130061, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.351138534369291, -2.058558067620291, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.352170352622391, -2.063171397112173, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.353932120242678, -2.068612154844276, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.356311469118051, -2.0716206210432, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.35637271431894, -2.072036904308117, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.289346591719731, -1.82650890377039, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.288635856707049, -1.822908898176807, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.280921958775139, -1.811953956561741, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.27613344492346, -1.807185501421168, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.272585410926759, -1.775382218450893, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.277284891249501, -1.775497768111544, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.27835091577068, -1.776876595763873, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.279698888988889, -1.777674548667266, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.284124692796329, -1.774246085054745, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.27954623561666, -1.753587491213974, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.280096125268962, -1.746725312684801, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.279264925278419, -1.746160801143533, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.279689512562872, -1.745973376706341, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.279876556297111, -1.746402692248982, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.280675100722739, -1.746339181474366, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.28055649378113, -1.746939109642499, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.280623940263009, -1.747469548049964, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.28011467229749, -1.747362627834568, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.161598553768879, -2.286496492779653, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.161400732716, -2.286988368825628, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.161816664321741, -2.28668059844445, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.161799872406942, -2.287058993283091, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Existing Fish farm" }, "geometry": { "type": "Point", "coordinates": [ 29.137551806625549, -2.314406961930452, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Fish Farms", "Type": null }, "geometry": { "type": "Point", "coordinates": [ 29.74093483053057, -1.480591995866152, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Fish Farms", "Type": null }, "geometry": { "type": "Point", "coordinates": [ 29.736025251897534, -1.481721970526709, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Fish Farms", "Type": null }, "geometry": { "type": "Point", "coordinates": [ 29.712717100910758, -1.521632326591848, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Fish Farms", "Type": null }, "geometry": { "type": "Point", "coordinates": [ 29.770062554811531, -1.391620642373863, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Fishing" }, "geometry": { "type": "Point", "coordinates": [ 30.3757233830889, -1.871708144680835, 0.0 ] } }
]
}

export default existing_fish_farms;