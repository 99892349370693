import {useContext, useCallback } from 'react';
import { Container, Indicator, Group, MediaQuery, Burger, Title, Button, Menu, Autocomplete } from '@mantine/core';
import useStyles from './header.style';
import { SidebarContext } from '../context/sidebar/sidebar.context';
import { UserInfo } from '../userInfo';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useViewportSize } from '@mantine/hooks';
import { Logout, Bell } from 'tabler-icons-react';
import { AuthContext } from '../../../../App';
import swal from 'sweetalert';
import { ToggleTheme } from '../../../theme';

export function Header() {
  const { classes, theme} = useStyles();
  const { state, dispatch } = useContext(SidebarContext);
  const { state: authState, dispatch: authDispatch} = useContext(AuthContext);
  const location = useLocation();
  const { height, width } = useViewportSize();

  useEffect(() => {
    if(!state.opened){
      dispatch({
        type: 'TOGGLE',
      });
    }
  }, [location.pathname])

  const toggleHandler = useCallback(() => {
    dispatch({
      type: 'TOGGLE',
    });
  }, [dispatch]);

  const askLogout = () => {
    swal({
      title: "Warning!",
      text: "Are you sure you want to logout? Your session data will be cleared, and credentials will be required next time when you come back.",
      icon: "warning",
      buttons: {
        cancel: "No, Cancel",
        continue: {
          text: "Yes, Sign Me Out",
          value: "continue"
        }
      }
    }).then(async (val) => {
      if(val === "continue"){
        authDispatch({type:"SIGN_OUT"})
      }
    })
  }

  const navigate = useNavigate();

  const navigateFunc = (value) => {
    if(value !== ""){
      switch(value){
        case "Zoning":
          navigate('/dashboard/zoning/muhazi');
          break;
        case "Bathymetry":
          navigate('/dashboard/bathymetry/muhazi');
          break;
        case "Water Use":
          navigate("/dashboard/water-use");
          break;
        case "Sampling Points":
          navigate("/dashboard/sampling_points");
          break;
        default:
          // do nothing
  
      }
    }
  }

  return (
    <div className={classes.header} >
      <Container size={width} px="md" className={classes.inner}>
        <Group noWrap style={{marginRight: 'auto',}} >
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={!state.opened}
                onClick={toggleHandler}
                size="sm"
                color="#ffffff"
                mr="xl"
              />
            </MediaQuery>

            <MediaQuery largerThan={"md"} styles={{display: "none"}}>
              <Title color='white' order={4} style={{fontWeight: "normal"}}>MIS</Title>
              </MediaQuery>
            <MediaQuery smallerThan={"lg"} styles={{ display: 'none' }}>
            <Group position='apart' noWrap sx={(theme) => ({marginRight: 15})} >
            <Title color='white' order={5}>WATER USE MASTER PLAN - MIS</Title>
          </Group>
          </MediaQuery>
          <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <Autocomplete placeholder="Navigate to..." data={["Bathymetry", "Zoning", "Water Use", "Sampling Points"]} dropdownPosition="flip" onChange={(val) => {navigateFunc(val)}} style={{minWidth: 300, marginRight: 20}} />
          </MediaQuery>
        </Group>

        <Group style={{marginLeft: 'auto',}} noWrap spacing="xs" >
          <Menu>
            <Menu.Target>
            <Indicator title="Notifications" label={0} mt={10} inline size={17}>
                  <Bell color='white' strokeWidth={1} size={30} />
                </Indicator>
            </Menu.Target>
            <Menu.Dropdown>
              <div style={{maxHeight: height -200, overflowY: 'auto'}} className='scroll'>
              <Menu.Label>You have 0 notifications</Menu.Label>
              </div>
            </Menu.Dropdown>
          </Menu>
            <UserInfo/>
            <Button radius={20} color='red' onClick={() => {askLogout()}} rightIcon={<Logout />}>Sign Out</Button>
                  </Group>
      </Container>
    </div>
  );
}