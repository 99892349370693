import { useContext, useEffect, useState } from "react"
import { EnumeratorsCard } from "./card";
import { Button, Checkbox, Drawer, Group, Paper, PasswordInput, SimpleGrid, Stack, Text, TextInput, Title } from "@mantine/core";
import { IconCards, IconLayout2, IconList } from "@tabler/icons-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { nanoid } from "nanoid";
import { toast } from "react-hot-toast";
import { useViewportSize } from "@mantine/hooks";
import { EnumeratorsTable } from "./table";

export default function Enumerators(){
    const [display, setDisplay] = useState("list");
    const { state, dispatch } = useContext(AuthContext);
    const [opened, setOpened] = useState(false);
    const { height, width } = useViewportSize();
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [staff, setStaff] = useState([]);

    const fetchStaff = () => {
        axios.get("/enumerators/all", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
          if(res.status === 200){
            setStaff(res.data.data);
          }
        }).catch(function(err){
          console.log(err);
        })
    }

    useEffect(() => {
        fetchStaff();
    }, [refresh]);


    const createStaff = () => {
        if(name.trim() === ""){
            return false;
        }

        if(username.trim() === ""){
            return false;
        }

        setLoading(true);

        axios.post("/enumerators/create", {
            name: name,
            username: username,
            password: password
        }, {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
            if(res.status === 200){
                toast.success("Staff has been created successfully", {
                    position: "top-right",
                    duration: 10000
                });
                setRefresh(!refresh);
                setLoading(false);
                setOpened(false);
            }
        }).catch(function(error){
            console.log(error);
            toast.error("Something wrong happened", {
                position: "top-right",
                duration: 10000
            });
            setLoading(false);
        })
    }

    return (
        <Paper p="md">
        <Group mb={"md"} position="apart">
        <Title order={5}>Enumerators</Title>
        
        <Group  position="right">
        <Button>Export all as CSV</Button>
        <Button onClick={() => {setOpened(true)}} pr={12}>
          Add New Enumerator
        </Button>
        </Group>
        </Group>

        <TextInput mb={20} radius={"md"} placeholder="search..." />
        <EnumeratorsTable data={staff} />

<Drawer padding={"md"} position="right" withOverlay={false} opened={opened} onClose={() => {setOpened(false)}} withCloseButton={false}>
        <Stack h={height * 0.9} justify="space-between">
        <div>
        <Title order={5} mb={10}>Add New Enumerator</Title>
        <Text mb={20}>Field collaborators helps you to collect data in the field.</Text>

        <TextInput value={name} onChange={(e) => {setName(e.currentTarget.value)}} label="Name" description="Name of the staff" mb={"md"} />
        <TextInput value={username} onChange={(e) => {setUsername(e.currentTarget.value)}} label="Username" description="Email address of the staff." mb={"md"} />
        <PasswordInput value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} label="Password" description="This value will be required while signing into the mobile application" />

        </div>

        <Group>
            <Button onClick={() => {setOpened(false)}} variant="default">Discard</Button>
            <Button loading={loading} onClick={() => {createStaff()}} radius={28}>Add Enumerator</Button>
        </Group>
        </Stack>
    </Drawer>
        </Paper>
    )
}