import { Badge, Button, Center, Drawer, FileInput, Group, Loader, Menu, NumberInput, Pagination, Paper, Select, Stack, Table, Text, TextInput, Title } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "../../../utils/axios"
import { AuthContext } from "../../../App";
import { Refresh } from "tabler-icons-react";

export default function WaterUse(){
    const { state, dispatch } = useContext(AuthContext)
    const [data, setData] = useState([]);
    const [size, setSize] = useState(20);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("all");

    const [refreshing, setRefreshing] = useState(false);

    const [r, setR] = useState(false);

    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const [water_use, setWaterUse] = useState("");
    const [specific_use, setSpecificUse] = useState("");
    const [lake, setLake] = useState("");
    const [file, setFile] = useState(null);

    const [count, setCount] = useState(0);

    const [pagination, setPagination] = useState(0);

    const { height, width } = useViewportSize();

    const fetchData = () => {
        setRefreshing(true);

        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            },
            params: {
                size: size,
                page: page,
                filter: filter
            }
        }

        axios.get("/water-use/paginated", config).then(function(res){
            if(res.status === 200){
                setPagination(Math.ceil(res.data.count / size));
                setData(res.data.data)
                setRefreshing(false);
            }
        }).catch(function(err){
            console.log(err);
            setRefreshing(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, [r, size, page, filter])

    const uploadGeojson = () => {

        let chunks = file.name.split(".");

        let ext = chunks[chunks.length - 1];

        if(ext !== "geojson"){
            toast.error("The file does not appear to be a valid GeoJSON.");
            return;
        }

        setLoading(true);

        const fr = new FileReader();

        fr.onload = function(e) { 
              var result = JSON.parse(e.target.result);
            
              let features = result?.features;

              if(features.length > 0){
                // insert element one by one
                var success = 0;
                var failed = 0;

                let id = toast.loading("Inserting data to the database...");

                for(let i=0; i<features.length; i++){
                    let item = features[i];

                    if(item.geometry.type !== "Point"){
                        failed += 1;
                        continue;
                    }

                    const body = {
                        water_use: water_use,
                        name: specific_use,
                        properties: item?.properties,
                        lake: lake,
                        type: water_use === "Navigation" ? item?.geometry?.type : "Point",
                        coordinates: item?.geometry?.coordinates,
                        submittedBy: "daviskitavi98@gmail.com",
                        approved: true
                    }

                    const config = {
                        headers: {
                            'Authorization': `Bearer ${state.userToken}`
                        }
                    }
                    axios.post("/water-use/create", body, config).then(function(res){
                        if(res.status === 200){
                            // point was uploaded successfully.
                            success += 1;

                            if(i === features.length - 1){
                                toast.success(`${success} points were loaded successfully. ${failed} points failed to insert.`, {
                                    id: id
                                });

                                setR(!r);
                            }
                        }
                    }).catch(function(error){
                        failed += 1;
                        toast.error("The point layer could not be uploaded.")
                    })
                }
              }
        }
          
        fr.readAsText(file);

        setLoading(false);
    }
    return (
        <Paper p="md" withBorder>
            <Group position="apart">
                <div>
                    <Title mb="xs" order={3}>Water Use</Title>
                    <Text>Explore water use data points in real-time. Any change on this dataset is mapped to other tabs.</Text>
                </div>
            
                <Group>
                <Button>Export Dataset</Button>
            <Button onClick={() => {setOpened(true)}}>Upload From GeoJSON</Button>
                </Group>
            </Group>
                <Group mb={20} mt={10} position="apart">
                <Group position="left">
                <Button variant="default" onClick={() => {setR(!r)}} leftIcon={<Refresh />}>Refresh</Button>
                <TextInput  placeholder="Search data" />
                </Group>
                <Group position="right">
                    <Select data={[
                        {label: "All", value: "all"},
                        {label: "Approved", value: "yes"},
                        {label: "Not Approved", value: "no"}
                    ]} value={"all"} />
                <NumberInput value={size} onChange={(val) => {setSize(val)}} min={1} step={20} />
                </Group>
                </Group>
                <div style={{overflowX: "auto"}}>
                <Table fontSize={"xs"}>
                <thead>
                    <tr>
                        <th>Water Use</th>
                        <th>Name</th>
                        <th>Lake</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Approved</th>
                        <th>Enumerator</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length === 0 && !refreshing ? (
                                            <tr>
                                            <td colSpan={8}>
                                                <Center>
                                                    <Text size={"xs"}>No data was found</Text>
                                                </Center>
                                            </td>
                                        </tr>
                    ) : data.length > 0 && !refreshing ? (
                        data.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                <td >
                                {item.water_use}
                                </td>
                                <td >
                                {item.name}
                                </td>
                                <td >
                                {item.lake}
                                </td>
                                <td >
                                {item.coordinates[0]}
                                </td>
                                <td >
                                {item.coordinates[1]}
                                </td>
                                <td >
                                <Badge color={item.approved ? "green" : "red"}>{item.approved ? "Yes" : "No"}</Badge>
                                </td>
                                <td>
                                {item.submittedBy}
                                </td>
                                <td>
                                    <Menu>
                                        <Menu.Target>
                                            <Button variant="light">Actions</Button>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item>Update</Menu.Item>
                                            <Menu.Item>Delete</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </td>
                            </tr>
                            )
                        })
                    ) : (
                        <tr>
                            <td colSpan={8}>
                            <Center mt={"5%"}>
                            <Loader size={"xs"} variant="bars" />
                        </Center>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
                </div>

            <Group mt={30} position="right">
            <Pagination size="sm" page={page} onChange={setPage} total={pagination} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>

            <Drawer padding={"md"} position="right" withOverlay={false} opened={opened} onClose={() => {setOpened(false)}} withCloseButton={false}>
            <Stack h={height * 0.9} justify="space-between">
                <div>
                <Title order={5} mb={10}>Upload from GeoJSON</Title>
                <Text mb={20}>A standard GeoJSON file should include type of features, name of the layer, crs and a list of features. The acceptable crs is WGS84, denoted by urn:ogc:def:crs:OGC:1.3:CRS84.</Text>

                <Select value={water_use} onChange={(val) => {setWaterUse(val)}} mb={"md"} label="Water Use Category" description="Select the specific water use category that fits your dataset" data={[
                    {label: "Navigation", value: "Navigation"},
                    {label: "Tourism/Recreation", value: "Tourism/Recreation"},
                    {label: "Fishing", value: "Fishing"},
                    {label: "Gas Extraction", value: "Gas Extraction"},
                    {label: "Water Supply", value: "Water Supply"},
                    {label: "Irrigation", value: "Irrigation"},
                    {label: "Mining", value: "Mining"},
                    {label: "Hydropower", value: "Hydropower"},
                    {label: "Others", value: "Others"}
                ]} />

                <TextInput value={specific_use} onChange={(e) => {setSpecificUse(e.currentTarget.value)}} label="Specific use" description="e.g in tourism, we might have hotels, lodges etc" mb={"md"} />

                <Select value={lake} onChange={(val) => {setLake(val)}} label="Lake" description="Specific lake in which this water use is found" data={[
                    {label: "Kivu", value: "Kivu"},
                    {label: "Ruhondo", value: "Ruhondo"},
                    {label: "Burera", value: "Burera"},
                    {label: "Muhazi", value: "Muhazi"},
                    {label: "Mugesera", value: "Mugesera"},
                    {label: "Mixed", value: "Mixed"}
                ]} mb="md" />

                <FileInput value={file} onChange={setFile} label="File" description="Upload the geojson file. Please note that features without point layers will be dropped except if the water use category is navigation." mb="md" />
                </div>

                <Group>
                    <Button onClick={() => {setOpened(false)}} variant="default">Discard</Button>
                    <Button disabled={water_use === "" || specific_use.trim() === "" || lake === "" || file === null} loading={loading} onClick={() => {uploadGeojson()}} radius={28} >Upload GeoJSON</Button>
                </Group>
            </Stack>
            </Drawer>
        </Paper>
    )
}