import { Button, Group, Paper, Title, Table, Text, TextInput, Pagination } from "@mantine/core";

export default function DataRequests(){
    return (
        <Paper p="md" withBorder>
            <Group mb="md" position="apart">
                <div>
                    <Title mb="xs" order={3}>Data Requests</Title>
                    <Text>This panel displays data requests made from the public domain.</Text>
                </div>

                <Button>Export as CSV</Button>
            </Group>
            <TextInput mb={20} radius={"md"} placeholder="search..." />
            <div style={{overflowX: "auto"}}>
                <Table fontSize={"xs"}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Institution</th>
                            <th>Email Address</th>
                            <th>Approved</th>
                            <th>Requested On</th>
                            <th>Purpose</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={7} style={{textAlign: "center"}}>No data requests were found.</td>
                        </tr>
                    </tbody>
                </Table>

                <Group mt={30} position="right">
            <Pagination size="sm" page={1} total={1} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>
            </div>
        </Paper>
    )
}