import {
  TextInput,
  PasswordInput,
  Anchor,
  Group,
  Button,
  Center,
  Paper,
  Text,
  MediaQuery,
  BackgroundImage,
  Title,
  SimpleGrid,
  Space,
  Divider,
  Container,
  Overlay,
  Stack,
  Menu,
  Grid,
  Checkbox
} from '@mantine/core';
import {  useContext, useState, useMemo, useEffect} from 'react';
import toast from 'react-hot-toast';
import { useLocation, Link } from 'react-router-dom';
import axios from '../../utils/axios';
import { AuthContext } from '../../App';
import { TOKEN_NAME } from '../../constants';
import { useForm } from "@mantine/form";
import { Helmet } from 'react-helmet';
import { useViewportSize } from '@mantine/hooks';
import { World } from 'tabler-icons-react';
import Kivu from "../../assets/kivu.jpg";

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Login = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { height, width} = useViewportSize();
  const [loading, setLoading] = useState(false);
  const [remember_me, setRememberMe] = useState(true);

  const form = useForm({
    initialValues: { email: "", password: ""},
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });


  const onSubmit = async (obj) => {
    setLoading(true);
      try {
        const body = {
          username: obj.email,
          password: obj.password
        };
  
        axios.post("/admin/login", body).then(({
          data: {
            data: accountData,
            token: accessToken
          },
        }) => {
          localStorage.setItem(TOKEN_NAME, accessToken);
          dispatch({type: "SIGN_IN", token: accessToken, data: accountData});
          setLoading(false);
        }).catch((error) => {
          toast.error(error?.response?.data?.message || error.message)
          setLoading(false);
        })
      } catch(error){
        toast.error(error?.response?.data?.message || error.message);
        setLoading(false);
      }
  }

  return (
    <>
      <Helmet>
      <meta charSet="utf-8" />
      <title>Sign In</title>
      <meta name='description' content='Get access to your account.' />
      </Helmet>

      <div style={{overflowX: "hidden", overflowY: "hidden"}}>
      <Grid>
            <Grid.Col lg={5} md={12} style={{overflowX: "hidden", overflowY: "hidden"}}>
              <Container size={500}>
            <Paper p="md" mt={'10%'}>
                    <Title order={5} align='left' mb={20}>
                    A WATER USE MASTER PLAN FOR IDENTIFIED 5 KEY LAKES: KIVU, MUHAZI, BURERA, RUHONDO, AND MUGESERA
                    </Title>
                    <Text align="left" mb={30} inline>MIS Admin Login</Text>
                    <form onSubmit={form.onSubmit((values) => {onSubmit(values)})}>
                    <TextInput  styles={{input: {'::placeholder': {fontWeight: 300}}}} height={40} withAsterisk {...form.getInputProps("email")} placeholder="" label="Email"/>
                    <PasswordInput height={40} withAsterisk {...form.getInputProps("password")} label="Password" mt="md" />
                    <Group position="apart" mt="md">
                    <Checkbox checked={remember_me} onChange={(e) => {setRememberMe(e.currentTarget.checked)}} label="Remember me in this device" />
                    </Group>
      
                    <Button height={45} loading={loading} mb={40} type='submit' fullWidth mt="xl">
                      Sign In
                    </Button>
                    <Text><Anchor href='#'>Don't have credentials?</Anchor> These are restricted areas and only admins with valid credentials are allowed to access them.</Text>
                    </form>
                </Paper>
                </Container>
            </Grid.Col>
            
          <MediaQuery smallerThan={"sm"} styles={{display: "none"}}>
            <Grid.Col lg={7} md={12} >
            <BackgroundImage src={Kivu} h={height * 0.99} style={{overflowX: "hidden", overflowY: "hidden"}}>
              </BackgroundImage>
          </Grid.Col>
          </MediaQuery>
      </Grid>
    </div>
</>
  );
};

export default Login;
