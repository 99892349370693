import { GeoJSON } from "react-leaflet"
import { Box, Container, Group, Text, Title } from "@mantine/core";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";
import { useViewportSize } from "@mantine/hooks";
import burera_bathymetry from "../../../assets/bathymetry/burera";

function getColor(d) {
    return d > 135 ? '#d7191c' :
            d > 101  ? '#fdae61' :
            d > 67  ? '#ffffbf' :
            d > 33  ? '#abdda4' :
                    '#2b83ba';
}

export default function BureraBathymetry(){
    return (
        <GeoJSON data={burera_bathymetry} style={(feature) => {
            return {
                color: getColor(feature.properties.El),
                fillColor: getColor(feature.properties.El), 
                fillOpacity: 1
            }
      }} />
    )
}

export function BureraBathymetricAnalysis(){
    const { height, width } = useViewportSize();
    return (
        <div style={{height: `90vh`}}>
        <MapContainer center={[-1.4482944228942056, 29.774064050188446]} style={{background: "transparent", zIndex: 1, height: '100%', width: '100%', padding: 0}} zoom={13}>
        <LayersControl position="topright">
        <LayersControl.BaseLayer checked name='CartoDB'>
        <TileLayer
        attribution='&copy; HydroIntel Africa Limited, &copy; Emita, &copy; Hydrosys Limited, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
        url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
        />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name='Satellite'>
        <TileLayer
        attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
        </LayersControl.BaseLayer>
        </LayersControl>

        <BureraBathymetry />

        <Container sx={(theme) => ({marginTop: -10, marginLeft: -10, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
            display: "none"
          }})} className='leaflet-bottom leaflet-left'>
        <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
        backgroundColor: "#ffff",
        width: width * 0.15,
        border: "none",
        height: 250,
        marginBottom: 30,
        [theme.fn.smallerThan('md')]: {
            display: "none"
          }
        })} p="md" >
            <Title mb="md" order={5}>Depth(Metres)</Title>
            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#2b83ba"}} />
                </Group>
                <Group position="right">
                    <Text>0 - 33</Text>
                </Group>
            </Group>

            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#abdda4"}} />
                </Group>
                <Group position="right">
                    <Text>34 - 67</Text>
                </Group>
            </Group>

            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#ffffbf"}} />
                </Group>
                <Group position="right">
                    <Text>68 - 101</Text>
                </Group>
            </Group>

            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#fdae61"}} />
                </Group>
                <Group position="right">
                    <Text>102 - 135</Text>
                </Group>
            </Group>

            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#d7191c"}} />
                </Group>
                <Group position="right">
                    <Text>136 - 169</Text>
                </Group>
            </Group>
        </Container>
        </Container>
    </MapContainer>
    </div>
    )
}