const lodges = {
"type": "FeatureCollection",
"name": "Lodges",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Name": "Eco", "Type": "lodge" }, "geometry": { "type": "Point", "coordinates": [ 30.3152813, -2.0988152, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Beach Garden Lodge", "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.262986533131819, -1.706231687226288, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Maddy's lodge", "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.266606065442531, -1.71137615509868, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Inzu Lodge", "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.265370837121981, -1.730479662958041, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Dangou Itoia lodge", "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 28.909063634884738, -2.395063767513878, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "The Capanne Huts Lodge", "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 28.933889699657641, -2.374861182209555, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.265664961006877, -1.729852927840275, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.287276357690928, -1.914100149341728, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.343282293264384, -2.051564396389352, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.256710494453944, -2.145719547254393, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "My hill eco lodge", "Type": "Eco lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.740481294904821, -1.497065146773132, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Byiza lodge", "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.75684396329217, -1.478456658155313, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Virunga Lodge", "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 29.741336209839741, -1.446987085047599, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 30.242855949679278, -1.852531281867494, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 30.439025863131185, -1.862333616205636, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "S & Friends Game Lodge", "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 30.439196372848059, -1.862215388511174, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Lodge" }, "geometry": { "type": "Point", "coordinates": [ 30.331902345034322, -1.868787904659799, 0.0 ] } }
]
}

export default lodges;
