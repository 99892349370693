import { useContext, useCallback } from 'react';
import {AppShell,Drawer,MediaQuery,useMantineTheme,} from '@mantine/core';
import { Header } from './header/header';
import { NavbarSmall } from './nav/navbar';
import { SidebarContext } from './context/sidebar/sidebar.context';
import DrawerNavbar from './nav/DrawerNavbar';

export default function AppShellLayoutLayout({children, img}) {
    const { state, dispatch } = useContext(SidebarContext);

    const toggleHandler = useCallback(() => {
      dispatch({
        type: 'TOGGLE',
      });
    }, [dispatch]);

    const theme = useMantineTheme();

    return (
      <AppShell
        styles={{
          main: {
            background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0]
          },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          <MediaQuery smallerThan="md" styles={{display: 'none'}}>
            <NavbarSmall />
          </MediaQuery>
        }
        header={
           <Header img={img} />
        }
      >
        <Drawer size="xs" onClose={toggleHandler} opened={!state.opened}>
          <DrawerNavbar />
        </Drawer>
        {children}
      </AppShell>
    );
  }