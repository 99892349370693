import { Badge, Button, Center, Drawer, FileInput, Group, Loader, Menu, NumberInput, Pagination, Paper, Select, Stack, Table, Text, TextInput, Title } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "../../../utils/axios"
import { AuthContext } from "../../../App";
import { Refresh } from "tabler-icons-react";

export default function SamplingPoints(){
    const { state, dispatch } = useContext(AuthContext)
    const [data, setData] = useState([]);
    const [size, setSize] = useState(20);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("all");

    const [refreshing, setRefreshing] = useState(false);

    const [r, setR] = useState(false);

    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const [water_use, setWaterUse] = useState("");
    const [specific_use, setSpecificUse] = useState("");
    const [lake, setLake] = useState("");
    const [file, setFile] = useState(null);

    const [count, setCount] = useState(0);

    const [pagination, setPagination] = useState(0);

    const { height, width } = useViewportSize();

    const fetchData = () => {
        setRefreshing(true);

        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            },
            params: {
                size: size,
                page: page,
                filter: filter
            }
        }

        axios.get("/water-use/paginated", config).then(function(res){
            if(res.status === 200){
                setPagination(Math.ceil(res.data.count / size));
                setData(res.data.data)
                setRefreshing(false);
            }
        }).catch(function(err){
            console.log(err);
            setRefreshing(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, [r, size, page, filter])

    const uploadGeojson = () => {

        let chunks = file.name.split(".");

        let ext = chunks[chunks.length - 1];

        if(ext !== "geojson"){
            toast.error("The file does not appear to be a valid GeoJSON.");
            return;
        }

        setLoading(true);

        const fr = new FileReader();

        fr.onload = function(e) { 
              var result = JSON.parse(e.target.result);
            
              let features = result?.features;

              if(features.length > 0){
                // insert element one by one
                var success = 0;
                var failed = 0;

                let id = toast.loading("Inserting data to the database...");

                for(let i=0; i<features.length; i++){
                    let item = features[i];

                    if(item.geometry.type !== "Point"){
                        failed += 1;
                        continue;
                    }

                    const body = {
                        water_use: water_use,
                        name: specific_use,
                        properties: item?.properties,
                        lake: lake,
                        type: water_use === "Navigation" ? item?.geometry?.type : "Point",
                        coordinates: item?.geometry?.coordinates,
                        submittedBy: "daviskitavi98@gmail.com",
                        approved: true
                    }

                    const config = {
                        headers: {
                            'Authorization': `Bearer ${state.userToken}`
                        }
                    }
                    axios.post("/water-use/create", body, config).then(function(res){
                        if(res.status === 200){
                            // point was uploaded successfully.
                            success += 1;

                            if(i === features.length - 1){
                                toast.success(`${success} points were loaded successfully. ${failed} points failed to insert.`, {
                                    id: id
                                });

                                setR(!r);
                            }
                        }
                    }).catch(function(error){
                        failed += 1;
                        toast.error("The point layer could not be uploaded.")
                    })
                }
              }
        }
          
        fr.readAsText(file);

        setLoading(false);
    }
    return (
        <Paper p="md" withBorder>
            <Group position="apart">
                <div>
                    <Title mb="xs" order={3}>Sampling Points</Title>
                    <Text>Explore sampling point data as collected in the field.</Text>
                </div>
            
                <Group>
                <Button>Export dataset as CSV</Button>
                </Group>
            </Group>
                <div style={{overflowX: "auto"}}>
                <Table fontSize={"xs"}>
                <thead>
                    <tr>
                        <th>Water Use</th>
                        <th>Name</th>
                        <th>Lake</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Approved</th>
                        <th>Enumerator</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                                            <td colSpan={8}>
                                                <Center>
                                                    <Text size={"xs"}>No data was found</Text>
                                                </Center>
                                            </td>
                                        </tr>
                </tbody>
            </Table>
                </div>

            <Group mt={30} position="right">
            <Pagination size="sm" page={page} onChange={setPage} total={pagination} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>

        </Paper>
    )
}