import { Center, ColorSchemeProvider, Loader, MantineProvider, Stack, createEmotionCache} from '@mantine/core';
import { useColorScheme, useViewportSize } from '@mantine/hooks';
import { createContext, useEffect, useReducer, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { TOKEN_NAME } from './constants';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import Login from './components/Login';
import { AppLayout } from './components/dashboard/layout/layout';
import Home from './components/dashboard/home';
import axios from './utils/axios';
import Dashboard from './components/Dashboard';
import Enumerators from './components/dashboard/enumerators';
import Form from './components/dashboard/form';
import Support from './components/dashboard/support';
import Account from './components/dashboard/account';
import Settings from './components/dashboard/settings';
import WaterUse from './components/dashboard/tabular-dataset';
import Admins from './components/dashboard/admins';
import { MuhaziBathymetricAnalysis } from './components/dashboard/bathymetric-analysis/muhazi';
import { MugeseraBathymetricAnalysis } from './components/dashboard/bathymetric-analysis/mugesera';
import { RuhondoBathymetricAnalysis } from './components/dashboard/bathymetric-analysis/ruhondo';
import { BureraBathymetricAnalysis } from './components/dashboard/bathymetric-analysis/burera';
import { KivuBathymetricAnalysis } from './components/dashboard/bathymetric-analysis/kivu';
import { MuhaziZoning } from './components/dashboard/zoning/muhazi';
import { MugeseraZoning } from './components/dashboard/zoning/mugesera';
import { RuhondoZoning } from './components/dashboard/zoning/ruhondo';
import { BureraZoning } from './components/dashboard/zoning/burera';
import { KivuZoning } from './components/dashboard/zoning/kivu';
import DataRequests from './components/dashboard/data-requests';
import SamplingPoints from './components/dashboard/sampling-points';


export const THEME_KEY = "_rwanda_theme_key";

const initialState = {
  isLoading: true,
  isSignout: false,
  userToken: null,
  userData: null
};

export const AuthContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AuthReducer(state, action){
  switch(action.type){
    case "RESTORE_TOKEN":
      return {
        ...state,
        isLoading: false,
        userToken: action.token,
        userData: action.data
      };

    case "SIGN_IN":
      return {
        ...state,
        isSignout: false,
        userToken: action.token,
        userData: action.data
      };

    case "SIGN_OUT":
      localStorage.removeItem(TOKEN_NAME)
      return {
        ...state,
        isSignout: true,
        userToken: null,
        userData: null
      };

    default:
      return state;
  }
}


export default function App() {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [session, ] = useState(localStorage.getItem(TOKEN_NAME));
  const [colorscheme, setColorscheme] = useState("light");

  const bootstrapAsync = async () => {
    let userToken;
    let data;

    try{
      userToken = session;
      if(userToken){
        try{
          const {
            data: {
              data: accountData,
              token: accessToken
            },
          } = await axios.get("/admin/login-with-token", {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          });

          userToken = accessToken;
          data = accountData;

        } catch(error){
          userToken = null;
          data = null;
        }
      }
    } catch(error){
      userToken = null;
      data = null;
    }

    dispatch({type: "RESTORE_TOKEN", token: userToken, data: data});
  }

  useEffect(() => {
    bootstrapAsync();
  }, [session])

  const preferredColorSchema = useColorScheme();

  const AuthPage = () => {
    return (
      <Navigate to="/dashboard/" />
    )
  }

  const UnauthPage = () => {
    return (
      <Navigate to="/" />
    )
  }

  useEffect(() => {
    setColorscheme(preferredColorSchema);
  }, [preferredColorSchema])

  const { height, width } = useViewportSize();

  const LoaderPage = () => {
    return (
      <Stack justify="center" style={{height:height}}>
        <Center>
          <Loader />
        </Center>
      </Stack>
    )
  }

  const myCache = createEmotionCache({key: "rwanda-mis"});

  return (
    
    <AuthContext.Provider value={{state, dispatch}}>
    <ColorSchemeProvider colorScheme={preferredColorSchema}>
      <MantineProvider theme={{
        colorScheme: colorscheme,
        primaryColor: "indigo",
        primaryShade: 9,
        white: "white",
        black: "black",
        cursorType: "pointer",
      }} withGlobalStyles withNormalizeCSS emotionCache={myCache}>
          {state.isLoading ? (
            <LoaderPage />
          ) : (
            <BrowserRouter>
              <Routes>
                <Route exact path='/' element={state.userToken === null ? <Layout noFooter /> : <AuthPage />} >
                  <Route index element={<Login />} />
                  <Route path='/login' element={<Login />} />
                </Route>
                <Route path='/dashboard' element={state.userToken === null ? <UnauthPage /> : <AppLayout img={state?.userData?.logo} />}>
                  <Route index element={<Home />} />
                  <Route path='/dashboard/' element={<Home />} />
                  <Route path='/dashboard/enumerators' element={<Enumerators />} />
                  <Route path='/dashboard/form' element={<Form />} />
                  <Route path='/dashboard/support' element={<Support />} />
                  <Route path='/dashboard/water-use' element={<WaterUse />} />
                  <Route path='/dashboard/admins' element={<Admins />} />
                  <Route path='/dashboard/account' element={<Account />} />
                  <Route path='/dashboard/settings' element={<Settings />} />
                  <Route path='/dashboard/bathymetry/muhazi' element={<MuhaziBathymetricAnalysis />} />
                  <Route path='/dashboard/bathymetry/mugesera' element={<MugeseraBathymetricAnalysis />} />
                  <Route path='/dashboard/bathymetry/ruhondo' element={<RuhondoBathymetricAnalysis />} />
                  <Route path='/dashboard/bathymetry/burera' element={<BureraBathymetricAnalysis />} />
                  <Route path='/dashboard/bathymetry/kivu' element={<KivuBathymetricAnalysis />} />
                  <Route path='/dashboard/zoning/muhazi' element={<MuhaziZoning />} />
                  <Route path='/dashboard/zoning/mugesera' element={<MugeseraZoning />} />
                  <Route path='/dashboard/zoning/ruhondo' element={<RuhondoZoning />} />
                  <Route path='/dashboard/zoning/burera' element={<BureraZoning />} />
                  <Route path='/dashboard/zoning/kivu' element={<KivuZoning />} />
                  <Route path="/dashboard/data-requests" element={<DataRequests />} />
                  <Route path="/dashboard/sampling_points" element={<SamplingPoints />} />
                </Route>
                <Route path='/dashboard/map-dashboard' element={state.userToken === null ? <UnauthPage /> : <Dashboard />} />
              </Routes>
            </BrowserRouter>
          )}
          <Toaster />
      </MantineProvider>
    </ColorSchemeProvider>
    </AuthContext.Provider>
  );
}