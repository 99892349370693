export function convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr)
  
    return array.map(it => {
      return Object.values(it).toString()
    }).join('\n')
  }
/**
 * This function converts an array of objects to CSV.
 * @param {[]} data - an array of items to download as csv
 * @param {string} name - the name of the csv to be downloaded.
 */
export const downloadCSV = (data, name) => {
    let csv = convertToCSV(data);

    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = name+".csv";
    downloadLink.click();
    downloadLink.remove();
}