import {  BackgroundImage, Button, Center, Container, Divider, Group, Image, Paper, Select, SimpleGrid, Space, Stack, Text, Title, createStyles, useMantineTheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ArrowRight, ShieldCheck, User } from "tabler-icons-react";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";
import { Icon3dCubeSphere, IconArrowDownLeft, IconArrowUpRight, IconMapPinPlus, IconPlaneInflight, IconShare, IconUser, IconUsers } from "@tabler/icons-react";
import Kivu from "../../../assets/kivu.jpg";
import { useViewportSize } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    root: {
      padding: 0,
      marginBottom: 20,
      marginTop: 10
    },

    card: {

    },

    value: {
      fontWeight: 700,
      lineHeight: 1,
    },
  
    diff: {
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
    },
  
    icon: {
      color: theme.colors.gray[4],
    },
  
    title: {
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  }));

export default function Home(){
    const { state, dispatch } = useContext(AuthContext);
    const { classes } = useStyles();
    const [dataset, setData] = useState([]);
    const [water_use, setWaterUse] = useState(0);
    const [admins, setAdmins] = useState([]);
    const [enumerators, setEnumerators] = useState([]);

    useEffect(() => {
        fetch(`https://emita.herokuapp.com/wuda/data?key=_eGRIp4IU9x1sBdZx2jRNwCcy8l88AmYjvbShGWiWTtfaNvCwA2kDCYVwreS8Wk0GR9zv1byqa028LkP-Thk62809a4DiIPzfsHUeQCNbE3XbLCyD4kXEkSmbGOB2IZ_`).then(async function(res){
          if(res.status === 200){
            res = await res.json();
            setData(res.data);
          }
        }).catch(function(error){
          console.log(error);
        })
      }, [])

      const fetchRecords = () => {
        axios.get("/water-use/count", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
          if(res.status === 200){
            setWaterUse(res.data.data);
          }
        }).catch(function(err){
          console.log(err);
        })
    }

    const fetchAdmins = () => {
      axios.get("/admin/all", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
        if(res.status === 200){
          setAdmins(res.data.data);
        }
      }).catch(function(err){
        console.log(err);
      })
  }

  const fetchEnumerators = () => {
    axios.get("/enumerators/all", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
      if(res.status === 200){
        setEnumerators(res.data.data);
      }
    }).catch(function(err){
      console.log(err);
    })
}
    useEffect(() => {
      fetchRecords();
      fetchAdmins();
      fetchEnumerators();
    }, [])

    const navigate = useNavigate();

    const navigateTo = (url) => {
        navigate(url);
    }

    const data = [
        {
          title: "Sampling Points",
          icon: IconMapPinPlus,
          value: dataset.length,
          diff: 0,
          href: "sampling_points"
        },
        {
          title: "Water Uses",
          icon: Icon3dCubeSphere,
          value: water_use,
          diff: 0,
          href: "water-use"
        },
        {
          title: "Admins",
          icon: IconUser,
          value: admins.length,
          diff: 0,
          href: "admins"
        },
        {
          title: "Enumerators",
          icon: IconUsers,
          value: enumerators.length,
          diff: 0,
          href: "enumerators"
        }
      ]
  
  
      const stats = data.map((stat) => {
        const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownLeft;
        return (
          <Paper withBorder p="sm" key={stat.title} component={Link} to={stat.href} >
            <Group position="apart">
              <Text size={"xs"} className={classes.title}>
                {stat.title}
              </Text>
              <stat.icon className={classes.icon} size={18} stroke={1} />
            </Group>
            <Group align="flex-end" spacing="xs" mt={25}>
            <Text className={classes.value}>{stat.value}</Text>
            <Text
            color={stat.diff > 0 ? "blue" : 'red'}
            size="sm"
            weight={500}
            className={classes.diff}
          >
            <span>{stat.diff.toFixed(1)}%</span>
            <DiffIcon size={16} stroke={1.5} />
          </Text>
          </Group>

          <Text size="xs" mt={7}>
          Compared to previous month
        </Text>
          </Paper>
        )
      })

      const { height, width } = useViewportSize();

    return (
        <>
        <Helmet>
            <title>Dashboard | {state.userData.name}</title>
        </Helmet>
        <BackgroundImage h={height * 0.15} src={Kivu} radius={10} p={"xl"} style={{justifyItems: "center"}} >
        </BackgroundImage>
        <Title order={5} p="md" >Welcome, {state.userData.name}</Title>
<div className={classes.root}>
      <SimpleGrid
        cols={4}
        spacing="xs"
        breakpoints={[
          { maxWidth: 'md', cols: 2 },
          { maxWidth: 'xs', cols: 1 },
        ]}
      >
        {stats}
      </SimpleGrid>
    </div>

        <SimpleGrid cols={3} breakpoints={[
            {maxWidth: 980, cols: 1}
        ]}>
        <Paper p="md">
            <div style={{height: 200}}>
            <Title order={5} mt={10} mb={20}>Admins</Title>
            <Text size={"sm"} mb={20}>Manage administrative roles and responsibilities with ease. Effortlessly extend administrative rights to your co-admins.</Text>
            </div>
            <Button variant="outline" leftIcon={<ShieldCheck />} onClick={() => {navigateTo("admins")}} radius={28}>Create an Admin</Button>
 
        </Paper>

        <Paper p="md">
        <div style={{height: 200}}>
        <Title order={5} mt={10} mb={20}>Enumerators</Title>
        <Text size="sm" mb={20}>Manage feild enumerators. Create a staff, update his/her details.</Text>
        </div>
        <Button variant="outline" leftIcon={<User />} onClick={() => {navigateTo("enumerators")}} radius={28}>Add a Field Enumerators</Button>
        </Paper>

        <Paper p="md">
        <div style={{height: 200}}>
        <Title order={5} mt={10} mb={20}>Water Uses</Title>
        <Text size="sm" mb={20}>Effortlessly access all the data points that were captured in the field concerning water use. </Text>
        </div>
        <Button variant="outline" rightIcon={<ArrowRight />} onClick={() => {navigateTo("water-use")}} radius={28}>Analyze Dataset</Button>
        </Paper>

        </SimpleGrid>
        </>
    )
}