import { useState, useEffect, useContext } from 'react';
import {
  AppShell,
  useMantineTheme,
  Title,
  createStyles,
  Group,
  ActionIcon,
  Container,
  Button,
  Center,
  TextInput,
  Checkbox,
  ScrollArea,
  Divider,
  Paper,
  Drawer,
  Stack as Stack2,
  Select,
  MultiSelect,
  Accordion,
  Radio,
  Text
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { MapContainer, GeoJSON, LayersControl, TileLayer, ZoomControl, FeatureGroup} from "react-leaflet";
import L from "leaflet"
import RwandaBoundary from "../data/lakes/Rwanda-boundary.js";
import Kivu from "../data/lakes/Kivu.js";
import Burera from '../data/lakes/Burera.js';
import Mugesera from '../data/lakes/Mugesera.js';
import Muhazi from '../data/lakes/Muhazi.js';
import Ruhondo from '../data/lakes/Ruhondo.js';
import JSZip from 'jszip';
import { downloadCSV } from '../utils/downloadCSV.js';
import EditControl from '../utils/EditControl.js';
import ReactDOMServer from "react-dom/server";
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios.js';
import { AuthContext } from '../App.js';
import ruhondo_bathymetry from '../assets/bathymetry/ruhondo.js';
import muhazi_bathymetry from '../assets/bathymetry/muhazi.js';
import mugesera_bathymetry from '../assets/bathymetry/mugesera.js';
import fish from '../assets/zones/fish.js';
import tourism from '../assets/zones/tourism.js';
import Burera1 from '../assets/bathymetry/burera1.js';
import Burera2 from '../assets/bathymetry/burera2.js';
import Burera3 from '../assets/bathymetry/burera3.js';
import Burera4 from '../assets/bathymetry/burera4.js';
import Burera5 from '../assets/bathymetry/burera5.js';
import Burera6 from '../assets/bathymetry/burera6.js';
import Burera7 from '../assets/bathymetry/burera7.js';
import Burera8 from '../assets/bathymetry/burera8.js';
import Burera9 from '../assets/bathymetry/burera9.js';
import Burera10 from '../assets/bathymetry/burera10.js';
import Burera11 from '../assets/bathymetry/burera11.js';
import Burera12 from '../assets/bathymetry/burera12.js';
import Burera13 from '../assets/bathymetry/burera13.js';
import Burera14 from '../assets/bathymetry/burera13.js';
import fishing_areas from '../assets/zones/fishing_areas.js';
import gas_extraction from '../assets/zones/gas_extraction.js';
import restricted_areas from '../assets/zones/restricted_areas.js';
import islands from '../assets/water_uses/tourism/Islands.js';
import hotspring from '../assets/water_uses/tourism/hotspring.js';
import hotels from '../assets/water_uses/tourism/Hotels.js';
import lodges from '../assets/water_uses/tourism/Lodges.js';
import resorts from '../assets/water_uses/tourism/Resorts.js';
import golf from '../assets/water_uses/tourism/Golf.js';
import boat_areas from '../assets/water_uses/navigation/boatareas.js';
import existing_fish_farms from '../assets/water_uses/fishing/fishfarms.js';
import gas_extraction_points from '../assets/water_uses/gas-extraction/gasextraction.js';

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    backgroundColor: theme.colors.blue[9],
  },

  inner: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  search: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  navbar: {
      paddingTop: 0,
    },
  
    section: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
      marginBottom: theme.spacing.md,
  
    },
  
    searchCode: {
      fontWeight: 700,
      fontSize: 10,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
      }`,
    },
  
    mainLinks: {
      paddingLeft: theme.spacing.md - theme.spacing.xs,
      paddingRight: theme.spacing.md - theme.spacing.xs,
      paddingBottom: theme.spacing.md,
    },
  
    mainLink: {
      display: 'flex',
      cursor: 'text',
      alignItems: 'center',
      width: '100%',
      fontSize: theme.fontSizes.xs,
      padding: `8px ${theme.spacing.xs}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  
    mainLinkInner: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
  
    mainLinkIcon: {
      marginRight: theme.spacing.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    },
  
    mainLinkBadge: {
      padding: 0,
      width: 20,
      height: 20,
      pointerEvents: 'none',
    },
  
    collections: {
      paddingLeft: theme.spacing.md - 6,
      paddingRight: theme.spacing.md - 6,
      paddingBottom: theme.spacing.md,
    },
  
    collectionsHeader: {
      paddingLeft: theme.spacing.md + 2,
      paddingRight: theme.spacing.md,
      marginBottom: 5,
    },

    root: {
      position: 'relative',
      '& *': {
        cursor: 'pointer',
      },
    },
  
    collectionLink: {
      display: 'block',
      padding: `8px ${theme.spacing.xs}px`,
      textDecoration: 'none',
      cursor: 'text',
      borderRadius: theme.radius.sm,
      fontSize: theme.fontSizes.xs,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
      lineHeight: 1,
      fontWeight: 500,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },

    animalBody: {
      paddingLeft: 54,
      paddingTop: theme.spacing.sm,
    },
}));

export default function Dashboard() {
  const { state, dispatch } = useContext(AuthContext);
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const [basemap, setBasemap] = useState("CartoDB");
  const [boundary, setBoundary] = useState(true);
  const [lakes, setLakes] = useState(false);
  const [polylines, setPolylines] = useState(null);
  const [polygon, setPolygon] = useState(null)
  const [points, setPoints] = useState([]);
  const [raw_points, setRawPoints] = useState([]);
  const [zoom, setZoom] = useState(9);
  const [show_table, setShowTable] = useState(false);
  const [query, setQuery] = useState(false);
  const [query_text, setQueryText] = useState("");
  const [filter, setFilter] = useState(false);
  const [lake, setLake] = useState("All");
  const [category, setCategory] = useState("All");
  const [coords, setCoords] = useState(null);
  const [opened, setOpened] = useState(false);
  const [select_options, setSelectOptions] = useState([]);
  const [format, setFormat] = useState("csv");
  const [other_shapefiles, setOtherShapefiles] = useState(false);
  const [tourist, setTourist] = useState("");
  const [bathymetry_code, setBathymetricCode] = useState("none");
  const [map, setMap] = useState(null);

  const [zones_fish, setZonesFish] = useState(true);
  const [zones_tourism, setZonesTourism] = useState(true);
  const [zones_fishing_areas, setZonesFishingArea] = useState(true);
  const [zones_gas_extraction, setZonesGasExtraction] = useState(true);
  const [zones_restricted_areas, setZonesRestrictedAreas] = useState(true);

  const [water_use_tourism_island, setWaterUseTourismIsland] = useState(true);
  const [water_use_tourism_hotspring, setWaterUseTourismHotspring] = useState(true);
  const [water_use_tourism_hotels, setWaterUseTourismHotels] = useState(true);
  const [water_use_tourism_lodges, setWaterUseTourismLodges] = useState(true);
  const [water_use_tourism_resorts, setWaterUseTourismResorts] = useState(true);
  const [water_use_tourism_golf, setWaterUseTourismGolf] = useState(true);
  const [water_use_tourism_campsite, setWaterUseTourismCampsite] = useState(true);

  const [water_use_navigation_boatareas, setWaterUseNavigationBoatAreas] = useState(true);
  const [water_use_fishing_fishfarms, setWaterUseFishingFishfarms] = useState(true);
  const [water_use_gas_extraction_points, setWaterUseGasExtractionPoints] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const id = toast.loading("fetching datasets...");

    axios.get("/water-use/all", { headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
      if(res.status === 200){
        setRawPoints(res.data.data)
        toast.dismiss(id);
      } else {
        toast.error("Something wrong happened.", {
          id: IdleDeadline
        });
      }
    }).catch(function(error){
      console.log(error);
      toast.error(error.message, {
        id: id
      });
    })
  }, [])

  function filterData(data, lakeFilter, categoryFilter) {
    // If both filters are "All," return the entire data as is
    if (lakeFilter === "All" && categoryFilter === "All") {
      return data;
    }
  
    // Filter based on the lake and category options
    const filteredData = data.filter((item) => {
      // Check if the lake filter is "All" or if the lake matches the filter option
      const lakeMatch = lakeFilter === "All" || item.wuda["Name of Source"] === lakeFilter;
  
      // Check if the category filter is "All" or if the category matches the filter option
      const categoryMatch = categoryFilter === "All" || item.wuda["Category"] === categoryFilter;
  
      // Return true if both conditions are met, meaning the item should be included in the filtered data
      return lakeMatch && categoryMatch;
    });
  
    return filteredData;
  }
  

  useEffect(() => {
    if(raw_points.length > 0){
      const filteredPoints = filterData(raw_points, lake, category)
      setPoints(filteredPoints);
    }
  }, [raw_points, lake, category])

  useEffect(() => {
    let arr = [];
    let arr2 = [];
    let arr3 = [];


    for(let i=0; i<points.length; i++){
        let item = points[i];
        if(item?.type === "Point"){;
          let x = { "type": "Feature", "properties": item.properties, "geometry": { "type": "Point", "coordinates": item.coordinates } };
    
          arr.push(x)
        } else if(item?.type === "MultiLineString"){
          let x = { "type": "Feature", "properties": item.properties, "geometry": { "type": "MultiLineString", "coordinates": item.coordinates } };
    
          arr2.push(x)
        } else {
          let x = { "type": "Feature", "properties": item.properties, "geometry": { "type": "MultiPolygon", "coordinates": item.coordinates } };
    
          arr3.push(x)
        }
    }
  
  
    let geoData = {
        "type": "FeatureCollection",
        "name": "Water Use Points",
        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        "features": arr
    }
  
    let geoData2 = {
      "type": "FeatureCollection",
      "name": "Water Use Points",
      "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      "features": arr2
  }

  let geoData3 = {
    "type": "FeatureCollection",
    "name": "Water Use Points",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": arr3
}


    setCoords(geoData);
    setPolylines(geoData2);
    setPolygon(geoData3);
  
  }, [points]);

  const PopupTable = ({ feature }) => {
    return (
        <Paper p="md">
          <Text>{feature.properties.Name}</Text>
        </Paper>
  
    )
  }

const MarkerLayer = () => {
  return (
    <GeoJSON data={coords} pointToLayer={(f, latLng) => {
      return new L.CircleMarker(latLng, {
        opacity: 1,
        weight: 2,
        fillOpacity: 1,
        color: "red",
        fillColor:"red",
        radius: 4,
        //className: f.properties.disconnected ? "marker-pulse" : ""
      })
    }} onEachFeature={(f, l) => {
      l.bindPopup(ReactDOMServer.renderToString(<PopupTable feature={f} />))
        //l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>First Name</strong></td><td>"+f.properties.FirstName+"</td></tr><tr scope='row'><td><strong>Last Name</strong></td><td>"+f.properties.LastName+"</td></tr><tr scope='row'><td><strong>Mobile Number</strong></td><td>"+f.properties.MobileNumber+"</td></tr><tr scope='row'><td><strong>Account Number</strong></td><td>"+f.properties.account+"</td></tr><tr scope='row'><td><strong>Meter Number</strong></td><td>"+f.properties.meter_number+"</td></tr></tbody><table>")
      }}/>
  )
}

const PolylineLayers = () => {
  return (
    <GeoJSON data={polylines} style={(f, l) => {
      return {
        opacity: 1,
        weight: 2,
        fillOpacity: 1,
        color: "red",
        fillColor:"red",
        radius: 4,
      }
    }} />
  )
}

const PolygonLayers = () => {
  return (
    <GeoJSON data={polygon} style={(f, l) => {
      return {
        opacity: 1,
        weight: 2,
        fillOpacity: 1,
        color: "red",
        fillColor:"red",
        radius: 4,
      }
    }} />
  )
}

function createAndDownloadZip(arrayOfObjects, geojsonFiles, withOthers) {
  const zip = new JSZip();

  const jsonContent = JSON.stringify(arrayOfObjects);
  zip.file('water_use_data.json', jsonContent);

  if(withOthers){
      alert("creating others")
      geojsonFiles.forEach((geojson, index) => {
        zip.file(`geojson_${index + 1}.geojson`, geojson);
      });

    zip.generateAsync({ type: 'blob' })
    .then(function (content) {

      const url = URL.createObjectURL(content);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'dataset.zip';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(function (error) {
      console.error('Error while creating the zipped file:', error);
    });
  } else {
      zip.generateAsync({ type: 'blob' })
    .then(function (content) {

      const url = URL.createObjectURL(content);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'dataset.zip';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(function (error) {
      console.error('Error while creating the zipped file:', error);
    });
  }

}


const geojsonFiles = [`${Kivu}`, `${Burera}`, `${Mugesera}`, `${Muhazi}`, `${Ruhondo}`, `${RwandaBoundary}`];

const parseWUDA = () => {
  let arr = [];
  for(let i=0; i<points.length; i++){
    arr.push(points[i].wuda)
  }

  return arr;
}

const recursiveFilter = (arr, filter) => {
  if(select_options.includes("Water Use")){
    if(category === "All"){
      arr = arr.filter((item) => {
        if(item.Topic === "Water Use"){
          item.Quality = JSON.stringify(item.Quality);
        }
        return (
          item?.Topic === filter
        )
      });
    } else {
      arr = arr.filter((item) => {
        if(item.Topic === "Water Use"){
          item.Quality = JSON.stringify(item.Quality);
        }
        return (
          item?.Topic === filter && item.Category === category
        )
      });
    }
  } else {
    arr = arr.filter((item) => {
      return (
        item?.Topic === filter
      )
    });
  }

  if(select_options.includes("Tourist Questionnaire")){
    if(tourist !== ""){
      arr = arr.filter((item) => {
        return (
          item?.Category === tourist
      )
      })
    }
  }
  return arr;
}

const downloadData = () => {
  let data = parseWUDA();
  
  for(let i=0; i<select_options.length; i++){
      let topic = select_options[i];
      data = recursiveFilter(data, topic);
  }

  if(data.length === 0){
    toast.error("No records were found matching the category!", {
      position: "bottom-right"
    });
    return;
  }

  if(format !== "CSV"){
    createAndDownloadZip(data, geojsonFiles, other_shapefiles);
  } else {
    downloadCSV(data, "water-abstraction-data");
  }

  setOpened(false);
  setCategory("All");
}

function getColor(d) {
  return d > 300 ? '#d7191c' :
         d > 150  ? '#fdae61' :
         d > 75  ? '#ffffbf' :
         d > 30  ? '#abdda4' :
                   '#2b83ba';
}

const grades = [0, 30, 75, 150, 300]

const legendDivs = grades.map((item, idx) => {
  return (
    <div className='info legend'>
      <i style={{backgroundColor: `${getColor(grades[idx] + 1)}`}}></i>
      </div>
  )
})


function getColorRuhondo(d) {
  return d > 52 ? '#d7191c' :
         d > 39  ? '#fdae61' :
         d > 26  ? '#ffffbf' :
         d > 13  ? '#abdda4' :
                   '#2b83ba';
}

const gradesRuhondo = [0, 13, 26, 39, 52]

const legendDivsRuhondo = gradesRuhondo.map((item, idx) => {
  return (
    <div className='info legend'>
      <i style={{backgroundColor: `${getColorRuhondo(gradesRuhondo[idx] + 1)}`}}></i>
      </div>
  )
})

function getColorMuhazi(d) {
  return d > 10 ? '#d7191c' :
         d > 7  ? '#fdae61' :
         d > 4  ? '#ffffbf' :
         d > 2  ? '#abdda4' :
                   '#2b83ba';
}

const gradesMuhazi = [0, 2, 4, 7, 10]

const legendDivsMuhazi = gradesMuhazi.map((item, idx) => {
  return (
    <div className='info legend'>
      <i style={{backgroundColor: `${getColorMuhazi(gradesMuhazi[idx] + 1)}`}}></i>
      </div>
  )
})

function getColorMugesera(d) {
  return d > 4 ? '#d7191c' :
         d > 3  ? '#fdae61' :
         d > 2  ? '#ffffbf' :
         d > 1  ? '#abdda4' :
                   '#2b83ba';
}

const gradesMugesera = [0, 1, 2, 3, 4]

const legendDivsMugesera = gradesMugesera.map((item, idx) => {
  return (
    <div className='info legend'>
      <i style={{backgroundColor: `${getColorMugesera(gradesMugesera[idx] + 1)}`}}></i>
      </div>
  )
});

function getColorBurera(d) {
  return d > 135 ? '#d7191c' :
         d > 101  ? '#fdae61' :
         d > 67  ? '#ffffbf' :
         d > 33  ? '#abdda4' :
                   '#2b83ba';
}

const gradesBurera = [0, 33, 67, 101, 135]

const legendDivsBurera = gradesBurera.map((item, idx) => {
  return (
    <div className='info legend'>
      <i style={{backgroundColor: `${getColorBurera(gradesBurera[idx] + 1)}`}}></i>
      </div>
  )
})

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : "white",
          padding: 0,
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
    >
        <MapContainer zoomControl={false} center={[-1.9955756020580968, 29.43748415532596]} style={{background: "transparent", zIndex: 1, height: "100%", width: '100%', padding: 0}} zoom={zoom} whenCreated={setMap}>
        <LayersControl position='topright' vis >

      <LayersControl.BaseLayer checked={basemap === "CartoDB"} name='CartoDB'>
    <TileLayer
      attribution='&copy; HydroIntel Africa Limited, &copy; Emita, &copy; Hydrosys Limited, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
      url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
    />
    </LayersControl.BaseLayer>
    <LayersControl.BaseLayer checked={basemap === "ESRI"} name='Satellite'>
    <TileLayer
      attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    />
    </LayersControl.BaseLayer>

<LayersControl.Overlay name="Rwanda Boundary" checked={boundary}>
        <GeoJSON data={RwandaBoundary} style={(feature) => {
        return {
          fillOpacity: 1, opacity: 1, weight: 2, color: "black", fillColor: 'transparent'
      }
        }} />
    </LayersControl.Overlay>

      </LayersControl>

        {bathymetry_code === "all" || bathymetry_code === "ruhondo" ? (
                <GeoJSON data={ruhondo_bathymetry} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorRuhondo(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorRuhondo(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />
        ) : null}

{bathymetry_code === "all" || bathymetry_code === "muhazi" ? (
                <GeoJSON data={muhazi_bathymetry} style={(feature) => {
                  return {
                      color:  bathymetry_code === "all" ? getColor(feature.properties.El) : getColorMuhazi(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorMuhazi(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />
        ) : null}


{bathymetry_code === "all" || bathymetry_code === "mugesera" ? (
                <GeoJSON data={mugesera_bathymetry} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorMugesera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorMugesera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />
        ) :  null}

{bathymetry_code === "all" || bathymetry_code === "burera" ? (
  <>
                <GeoJSON data={Burera1} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera2} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera3} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera4} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera5} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera6} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera7} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera8} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera9} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera10} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera11} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera12} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera13} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />

<GeoJSON data={Burera14} style={(feature) => {
                  return {
                      color: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillColor: bathymetry_code === "all" ? getColor(feature.properties.El) : getColorBurera(feature.properties.El),
                      fillOpacity: 1
                  }
            }} />
    </>
        ) :  null}


    {zones_fish ? (
                      <GeoJSON data={fish} style={(f, l) => {
                        return {
                          color: "#3498db",
                          fillColor: "#3498db",
                          strokeWidth: 0.5,
                          weight: 2,
                          fillOpacity: 1
                        }
                      }} onEachFeature={(f, l) => {
                        l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Zone</strong></td><td>Fish Farming</td></tr></tbody><table>")
                      }} />
    ) : null}


    {zones_tourism ? (
                            <GeoJSON data={tourism} style={(f, l) => {
                              return {
                                color: "#FFD700",
                                fillColor: "#FFD700",
                                strokeWidth: 0.5,
                                weight: 2,
                                fillOpacity: 1
                              }
                            }} onEachFeature={(f, l) => {
                              l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Zone</strong></td><td>Tourism</td></tr></tbody><table>")
                            }} />
    ) : null}

{zones_fishing_areas ? (
                            <GeoJSON data={fishing_areas} style={(f, l) => {
                          return {
                            color: "green",
                            fillColor: "green",
                            strokeWidth: 0.5,
                            weight: 2,
                            fillOpacity: 1
                          }
                        }} onEachFeature={(f, l) => {
                          l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Zone</strong></td><td>Fishing Areas</td></tr></tbody><table>")
                        }} />
    ) : null}

{zones_gas_extraction ? (
                            <GeoJSON data={gas_extraction} style={(feature) => {
                              return {
                                  color: "#b30000",
                                  fillColor: "#b30000",
                                  fillOpacity: 1
                              }
                        }} onEachFeature={(f, l) => {
                          l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Zone</strong></td><td>Gas Extraction</td></tr></tbody><table>")
                        }} />
    ) : null}

{zones_restricted_areas ? (
                            <GeoJSON data={restricted_areas} style={(feature) => {
                              return {
                                  color: "purple",
                                  fillColor: "purple",
                                  fillOpacity: 1
                              }
                        }} onEachFeature={(f, l) => {
                          l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Zone</strong></td><td>Restricted Area</td></tr></tbody><table>")
                        }} />
    ) : null}

    {water_use_tourism_island ? (
                            <GeoJSON data={islands} pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "#3498db",
                                fillColor: "#3498db",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Island</td></tr></tbody><table>")
                              }}/>
    ) : null}

    {water_use_tourism_hotspring && (<GeoJSON data={hotspring} pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "#FF5733",
                                fillColor: "#FF5733",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Hot Spring</td></tr></tbody><table>")
                              }} />)}

    {water_use_tourism_hotels && (<GeoJSON data={hotels} pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "#FF6B6B",
                                fillColor: "#FF6B6B",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Hotels</td></tr></tbody><table>")
                              }}/>)}

    {water_use_tourism_lodges && (<GeoJSON data={lodges} pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "#FFA500",
                                fillColor: "#FFA500",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Lodges</td></tr></tbody><table>")
                              }}/>)}

    {water_use_tourism_resorts && (<GeoJSON data={resorts}  pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "#FF7F50",
                                fillColor: "#FF7F50",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Resorts</td></tr></tbody><table>")
                              }}/>)}

    {water_use_tourism_golf && (<GeoJSON data={golf} pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "#4B996D",
                                fillColor: "#4B996D",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Golf</td></tr></tbody><table>")
                              }}/>)}

{water_use_navigation_boatareas && (<GeoJSON data={boat_areas} pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "#000080",
                                fillColor: "#000080",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Boat Dock Areas</td></tr></tbody><table>")
                              }}/>)}

{water_use_fishing_fishfarms && (<GeoJSON data={existing_fish_farms} pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "black",
                                fillColor: "black",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Existing Fish Farms</td></tr></tbody><table>")
                              }}/>)}

{water_use_gas_extraction_points && (<GeoJSON data={gas_extraction_points} pointToLayer={(f, latLng) => {
                              return new L.CircleMarker(latLng, {
                                opacity: 1,
                                weight: 2,
                                fillOpacity: 1,
                                color: "#808080",
                                fillColor: "#808080",
                                radius: 4
                              })
                            }} onEachFeature={(f, l) => {
                                l.bindPopup("<table class='table' ><tbody><tr scope='row'><td><strong>Water Use</strong></td><td>Gas Extraction Points</td></tr></tbody><table>")
                              }}/>)}
          <MarkerLayer />
          <PolylineLayers />
          <PolygonLayers />

            {bathymetry_code !== "none" ? (
                        <div className='leaflet-bottom leaflet-right'>
                        <div className="leaflet-control leaflet-bar">
                            {legendDivs}
                        </div>
                        </div>
            ) : null}

              <Container sx={(theme) => ({marginTop: -10, marginLeft: -10, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
                display: "none"
              }})} className='leaflet-top leaflet-left'>
            <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
            backgroundColor: "#ffff",
            width: width * 0.25,
            border: "none",
            height: height,
            marginBottom: 30,
            [theme.fn.smallerThan('md')]: {
                display: "none"
              }
            })} p="md" >
              <ScrollArea>
              <Accordion defaultValue={"water_use"} chevronPosition="right" variant="contained">
                <Accordion.Item value='bathymetry'>
                  <Accordion.Control>Bathymetry</Accordion.Control>
                  <Accordion.Panel>
                    <Radio.Group label="" value={bathymetry_code} onChange={(val) => {setBathymetricCode(val)}}>
                    <Radio value="all"  m="xs" label="All Lakes" />
                    <Radio value="none"  m="xs" label="None" />
                    <Radio value="kivu"  m="xs" label="L.Kivu" />
                  <Radio value="mugesera" m="xs" label="L.Mugesera" />
                  <Radio value="ruhondo"  m="xs" label="L.Ruhondo" />
                  <Radio value="muhazi" m="xs" label="L.Muhazi" />
                  <Radio value="burera" m="xs" label="L.Burera" />
                    </Radio.Group>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value='zones'>
                  <Accordion.Control>Zones</Accordion.Control>
                  <Accordion.Panel>
                  <Checkbox checked={zones_fish} onChange={(e) => {setZonesFish(e.currentTarget.checked)}} m="xs" label="Fish Farming" />
                  <Checkbox checked={zones_tourism} onChange={(e) => {setZonesTourism(e.currentTarget.checked)}}  m="xs" label="Tourism" />
                  <Checkbox checked={zones_gas_extraction} onChange={(e) => {setZonesGasExtraction(e.currentTarget.checked)}}  m="xs" label="Gas Extraction" />
                  <Checkbox checked={zones_fishing_areas} onChange={(e) => {setZonesFishingArea(e.currentTarget.checked)}}  m="xs" label="Fishing Areas" />
                  <Checkbox value="bathymetric_burera"  m="xs" label="Mining" />
                  <Checkbox value="bathymetric_burera"  m="xs" label="Water Supply" />
                  <Checkbox value="bathymetric_burera"  m="xs" label="Irrigation" />
                  <Checkbox value="bathymetric_burera"  m="xs" label="Hydropower" />
                  <Checkbox checked={zones_restricted_areas} onChange={(e) => {setZonesRestrictedAreas(e.currentTarget.checked)}}  m="xs" label="Restricted Areas" />
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value='water_use'>
                  <Accordion.Control>Water Use</Accordion.Control>
                  <Accordion.Panel>

                    <Accordion>
                      <Accordion.Item value="navigation">
                        <Accordion.Control>Navigation</Accordion.Control>
                        <Accordion.Panel>
                          <Checkbox value={""} m="xs" label="Navigation Route" />
                          <Checkbox value={""}  m="xs" label="Port" />
                          <Checkbox checked={water_use_navigation_boatareas} onChange={(e) => {setWaterUseNavigationBoatAreas(e.currentTarget.checked)}}  m="xs" label="Boat Dock Areas" />
                        </Accordion.Panel>
                      </Accordion.Item>

                      <Accordion.Item value="tourism">
                        <Accordion.Control>Tourism/Recreation</Accordion.Control>
                        <Accordion.Panel>
                          <Checkbox checked={water_use_tourism_island} onChange={(e) => {setWaterUseTourismIsland(e.currentTarget.checked)}} m="xs" label="Islands" />
                          <Checkbox checked={water_use_tourism_hotspring} onChange={(e) => {setWaterUseTourismHotspring(e.currentTarget.checked)}} m="xs" label="Hotsprings" />
                          <Checkbox checked={water_use_tourism_hotels} onChange={(e) => {setWaterUseTourismHotels(e.currentTarget.checked)}} m="xs" label="Hotels" />
                          <Checkbox checked={water_use_tourism_lodges} onChange={(e) => {setWaterUseTourismLodges(e.currentTarget.checked)}} m="xs" label="Lodges" />
                          <Checkbox checked={water_use_tourism_resorts} onChange={(e) => {setWaterUseTourismResorts(e.currentTarget.checked)}} m="xs" label="Resorts" />
                          <Checkbox checked={water_use_tourism_golf} onChange={(e) => {setWaterUseTourismGolf(e.currentTarget.checked)}} m="xs" label="Golf Clubs" />
                          <Checkbox checked={water_use_tourism_campsite} onChange={(e) => {setWaterUseTourismCampsite(e.currentTarget.checked)}} m="xs" label="Camp Sites" />
                        </Accordion.Panel>
                      </Accordion.Item>

                      <Accordion.Item value="fishing">
                        <Accordion.Control>Fishing</Accordion.Control>
                        <Accordion.Panel>
                          <Checkbox value={""} m="xs" label="Recreational Fishing" />
                          <Checkbox checked={water_use_fishing_fishfarms} onChange={(e) => {setWaterUseFishingFishfarms(e.currentTarget.checked)}}  m="xs" label="Existing Fish Farms" />
                          <Checkbox value={""}  m="xs" label="Capture Fisheries" />
                          <Checkbox value={""}  m="xs" label="Landing Sites" />
                        </Accordion.Panel>
                      </Accordion.Item>

                      <Accordion.Item value="gas_extraction">
                        <Accordion.Control>Gas Extraction</Accordion.Control>
                        <Accordion.Panel>
                          <Checkbox checked={water_use_gas_extraction_points} onChange={(e) => {setWaterUseGasExtractionPoints(e.currentTarget.checked)}} m="xs" label="Gas Plants" />
                          <Checkbox value={""} m="xs" label="Oil Explore Sites" />
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Checkbox value={""} m="md" label="Water Supply" />
                    <Checkbox value={""} m="md" label="Irrigation" />
                    <Checkbox value={""} m="md" label="Mining" />
                    <Checkbox value={""} m="md" label="Hydropower" />
                    </Accordion>

                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
              </ScrollArea>
              </Container>
      </Container>

        <ZoomControl position='topright' />
        <FeatureGroup>
            <EditControl               
              position='topright'
              onEdited={() => {console.log("feature edited")}}
              onCreated={() => {console.log("feature created")}}
              onDeleted={() => {console.log("feature deleted")}}
              draw={{
                rectangle: true,
                circle: true,
                polyline: true,
                polygon: true,
              }} />
        </FeatureGroup>

        </MapContainer>
    </AppShell>
  );
}