import { GeoJSON } from "react-leaflet"
import { Box, Container, Group, Tabs, Text, Title } from "@mantine/core";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";
import { useViewportSize } from "@mantine/hooks";
import ruhondo_bathymetry from "../../../assets/bathymetry/ruhondo";

function getColor(d) {
    return d > 52 ? '#d7191c' :
            d > 39  ? '#fdae61' :
            d > 26  ? '#ffffbf' :
            d > 13  ? '#abdda4' :
                    '#2b83ba';
}

export default function RuhondoBathymetry(){
    return (
        <GeoJSON data={ruhondo_bathymetry} style={(feature) => {
            return {
                color: getColor(feature.properties.El),
                fillColor: getColor(feature.properties.El), 
                fillOpacity: 1
            }
      }} />
    )
}

export function RuhondoBathymetricAnalysis(){
    const { height, width } = useViewportSize();
    return (
        <div style={{height: `90vh`}}>
        <MapContainer center={[-1.515047887541111, 29.742307553759456]} style={{background: "transparent", zIndex: 1, height: '100%', width: '100%', padding: 0}} zoom={13}>
        <LayersControl position="topright">
        <LayersControl.BaseLayer checked name='CartoDB'>
        <TileLayer
        attribution='&copy; HydroIntel Africa Limited, &copy; Emita, &copy; Hydrosys Limited, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
        url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
        />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name='Satellite'>
        <TileLayer
        attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
        </LayersControl.BaseLayer>
        </LayersControl>

        <RuhondoBathymetry />

        <Container sx={(theme) => ({marginTop: -10, marginLeft: -10, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
            display: "none"
          }})} className='leaflet-bottom leaflet-left'>
        <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
        backgroundColor: "#ffff",
        width: width * 0.15,
        border: "none",
        height: 250,
        marginBottom: 30,
        [theme.fn.smallerThan('md')]: {
            display: "none"
          }
        })} p="md" >
            <Title mb="md" order={5}>Depth(Metres)</Title>
            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#2b83ba"}} />
                </Group>
                <Group position="right">
                    <Text>0 - 13</Text>
                </Group>
            </Group>

            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#abdda4"}} />
                </Group>
                <Group position="right">
                    <Text>14 - 26</Text>
                </Group>
            </Group>

            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#ffffbf"}} />
                </Group>
                <Group position="right">
                    <Text>27 - 39</Text>
                </Group>
            </Group>

            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#fdae61"}} />
                </Group>
                <Group position="right">
                    <Text>40 - 52</Text>
                </Group>
            </Group>

            <Group mb={"md"} position="apart" noWrap>
                <Group position="left">
                    <Box style={{height: 20, width: 100, backgroundColor:"#d7191c"}} />
                </Group>
                <Group position="right">
                    <Text>53 - 65</Text>
                </Group>
            </Group>
        </Container>
        </Container>
    </MapContainer>
    </div>
    )
}