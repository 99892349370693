const resorts = {
"type": "FeatureCollection",
"name": "Resorts",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Name": "Twin Lakes Recreational Island", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.787154948805622, -1.481849779207607, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "La Paillotte Cyuza Island", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.759602812867811, -1.438100235599875, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Burera Beach Resort", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.7677755, -1.3956419, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Ruhondo Beach Resort", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.742209797519099, -1.494351415624241, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Ruhondo Island Retreat", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.716091759995528, -1.513583398655343, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Palm Beach Resort", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.26645412841318, -1.731332363109597, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Kibuye Waterfront house", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.336405134240021, -2.060081352436063, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Moran Hill Resort", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.33197576702775, -2.059207177584931, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.277701059116016, -1.752564258694462, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.279455063363077, -1.756707337691831, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.266753653298164, -1.730760171416341, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.283908018788022, -1.905435924996105, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.331853305194986, -2.059079880877918, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 29.068092170816712, -2.336774851727658, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.1789753, -1.8047063, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "KingFisher", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.179704541350208, -1.806309539251876, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "The Fish Pub", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.34628084594063, -1.877303100665854, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Gihinga Resort", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.352914824190002, -1.880068004897411, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.389409683155773, -1.888201920723282, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.352818331681885, -1.879836456077006, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Recreational Resort", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.398602440345691, -2.153041921247425, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Recreational", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.382825721603631, -2.163270660346143, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Resort", "Type": "Resort" }, "geometry": { "type": "Point", "coordinates": [ 30.329423576861419, -2.140615348000681, 0.0 ] } }
]
}

export default resorts;
