const boat_areas = {
"type": "FeatureCollection",
"name": "Boat Area",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_1\\boat area.shp" }, "geometry": { "type": "Point", "coordinates": [ 29.258280593552701, -1.703321297998205, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_1\\boat area.shp" }, "geometry": { "type": "Point", "coordinates": [ 29.2744869769699, -1.734211313415147, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_1\\boat area.shp" }, "geometry": { "type": "Point", "coordinates": [ 29.274438416519182, -1.73922764722186, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_3\\Boat.shp" }, "geometry": { "type": "Point", "coordinates": [ 29.14863565443574, -2.277947161633756, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_3\\Boat.shp" }, "geometry": { "type": "Point", "coordinates": [ 29.191104076199149, -2.251401040948149, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_4\\boat area.shp" }, "geometry": { "type": "Point", "coordinates": [ 29.237666872348932, -2.162842501957559, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_4\\boat area.shp" }, "geometry": { "type": "Point", "coordinates": [ 29.257589713854511, -2.158970429363928, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_4\\boat area.shp" }, "geometry": { "type": "Point", "coordinates": [ 29.271188533999961, -2.110816595152944, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.92931380771892, -2.392435129244438, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.936089907290128, -2.383950644404966, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.934424103370269, -2.383698930717197, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.931750098458469, -2.39317281148355, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.93265126668755, -2.392808124284325, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.934464826742541, -2.390613657616314, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.931844116449319, -2.373836815985881, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.9016645644287, -2.40977289431848, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.89941626461081, -2.412861841480294, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.89134434842342, -2.417445622487236, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.880308318626039, -2.429011762871154, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.891859818979999, -2.43612444225102, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.91828180737469, -2.37832067522686, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.91240380943599, -2.382369829823453, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Boat Area", "Type": "Boat Area", "path": "D:\\gachuhi\\hydrointel_work\\kivu_all_points_zones\\zone_5\\boats.shp" }, "geometry": { "type": "Point", "coordinates": [ 28.9064395901047, -2.405683658748385, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "Type": "Boat Area", "path": null }, "geometry": { "type": "Point", "coordinates": [ 29.278865245517196, -1.900860435734933, 0.0 ] } }
]
}

export default boat_areas;