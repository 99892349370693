const gas_extraction_points = {
"type": "FeatureCollection",
"name": "Gas Extraction Points",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Name": "KP1 Gas Methan Extraction Platform", "descriptio": null, "Type": "Gas Extraction Plant" }, "geometry": { "type": "Point", "coordinates": [ 29.242935737433097, -1.732075798744982, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null, "descriptio": null, "Type": "Gas Extraction Plant" }, "geometry": { "type": "Point", "coordinates": [ 29.202571395858019, -2.08680539878392, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Symbion Power Plant", "descriptio": null, "Type": "Gas Extraction Plant" }, "geometry": { "type": "Point", "coordinates": [ 29.24150874901601, -1.784707269697027, 0.0 ] } }
]
}

export default gas_extraction_points;